import { Injectable } from "@angular/core"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import { ApiService } from "@services/api.service"

@Injectable()
export class AccompanimentDesignerHttpService {
    constructor(private api: ApiService) {}

    public get socket() {
        return this.api.socket
    }

    public async deleteUnusedPacks() {
        try {
            const res = await this.api.authRequest(
                "/accompanimentPack/deleteUnused",
                {},
                "primary",
                false
            )

            if (res.result === 0) {
                throw Error(res.message)
            }
        } catch (e) {
            this.api.handleError(e)
        }
    }

    public async retrySavingPack(
        packID: string,
        gpID: string
    ): Promise<string> {
        try {
            const res = await this.api.authRequest(
                "/accompanimentPack/retry",
                {
                    packID: packID,
                    gpID: gpID,
                },
                "primary",
                false
            )

            if (res.result === 0) {
                throw Error(res.message)
            }

            return res.newPackID
        } catch (e) {
            this.api.handleError(e)
        }
    }

    public async createTemplateAccompanimentPack(packID: string, name: string) {
        const res = await this.api.authRequest(
            "/accompanimentPack/createTemplate",
            {
                packID: packID,
                name: name,
            },
            "primary",
            false
        )

        if (res.result === 0) {
            throw Error(res.message)
        }
    }

    public async getPackByID(packID: string) {
        try {
            const res = await this.api.authRequest(
                "/accompanimentPack/getByID",
                {
                    packID: packID,
                },
                "primary",
                true
            )

            if (res.pack === undefined) {
                throw Error("The requested accompaniment pack does not exist.")
            }

            const pack = AccompanimentPack.fromJSON(
                res.pack
            ) as AccompanimentPack

            return pack
        } catch (err) {
            this.api.handleError(err)

            return undefined
        }
    }

    public async savePack(
        accompanimentPack: AccompanimentPack,
        generationProfileID: string
    ) {
        try {
            const res = await this.api.authRequest(
                "/accompanimentPack/create",
                {
                    accompanimentPack: accompanimentPack,
                    generationProfileID: generationProfileID,
                },
                "primary",
                false
            )

            if (res.result === 0) {
                throw Error(res.message)
            }

            return res.newPackID
        } catch (e) {
            this.api.handleError(e)
        }
    }
}
