import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Output,
    EventEmitter,
} from "@angular/core"
import { DesignService } from "@services/design.service"
import { TinyColor } from "@ctrl/tinycolor"

@Component({
    selector: "a-button",
    templateUrl: "button.component.html",
    styleUrls: ["button.component.scss"],
})
export class ButtonComponent implements OnInit {
    @Input() type: string // button type -> this is responsible for design and behavior of the button
    @Input() title: string
    @Input() label: string
    @Input() class: string
    @Input() icon: string
    @Input() iconPos: "left" | "right" = "left"
    @Input() disabled: boolean = false
    @Input() width: string
    @Input() iconWidth: string // will be used to define the height as well
    @Input() backgroundColor: string
    @Input() backgroundColorOnHover: string
    @Input() padding: string
    @Input() loading: boolean = false
    @Input() tag: number = null
    @Input() avData: string

    @Output() onClick: EventEmitter<any> = new EventEmitter()
    @Output() onFocus: EventEmitter<any> = new EventEmitter()
    @Output() onBlur: EventEmitter<any> = new EventEmitter()
    @Output() public onScroll$ = new EventEmitter<WheelEvent>()

    constructor(
        private elementRef: ElementRef,
        private designService: DesignService
    ) {}

    ngOnInit() {
        if (this.type == null || this.type == "") {
            this.type = "custom"
        }

        if (this.class == null || this.class == "") {
            this.class = ""
        }

        this.setupButtonIcon()
    }

    ngAfterViewInit() {
        this.setupButtonStyle()
    }

    @HostListener("mouseover")
    @HostListener("focus")
    onMouseOver() {
        if (this.ignoreHoverStyle()) {
            return
        }

        this.setStyle({
            name: "backgroundColor",
            value: this.backgroundColorOnHover,
        })
    }

    @HostListener("mousewheel", ["$event"])
    public onScroll($event: WheelEvent): void {
        this.onScroll$.emit($event)
    }

    @HostListener("mouseout")
    @HostListener("blur")
    onMouseOut() {
        if (this.ignoreHoverStyle()) {
            return
        }

        this.setStyle({ name: "backgroundColor", value: this.backgroundColor })
    }

    setupButtonStyle() {
        this.backgroundColor = this.getBackgroundColor()
        this.backgroundColorOnHover = this.getBackgroundColorOnHover()

        let stylePropertiesToApply = ["width", "padding"]

        stylePropertiesToApply.forEach(s => {
            this.setStyle({ name: s, value: this[s] })
        })
    }

    setupButtonIcon() {
        if (this.type.includes("round")) {
            this.iconWidth = this.iconWidth || "6px"
        } else {
            this.iconWidth = this.iconWidth || "12px"
        }
    }

    setStyle(style: { name; value }) {
        if (
            !this.elementRef.nativeElement ||
            !this.elementRef.nativeElement.getElementsByClassName("a-button")
        ) {
            return
        }

        let buttonElement =
            this.elementRef.nativeElement.getElementsByClassName("a-button")[0]
        let validStyle =
            this[style.name] != null &&
            this[style.name] != "" &&
            style.value != null &&
            style.value != ""

        if (!buttonElement || !validStyle) {
            return
        }

        buttonElement.style[style.name] = style.value

        if (this.type == "round" && style.name == "width") {
            buttonElement.style["height"] = style.value
        }
    }

    getBackgroundColor() {
        let bgColor = window
            .getComputedStyle(
                this.elementRef.nativeElement.getElementsByTagName("button")[0]
            )
            .getPropertyValue("background-color")

        if (this.type == "custom" && new TinyColor(bgColor).isValid) {
            return bgColor
        }

        if (
            new TinyColor(
                this.designService.getCSSProperty(`${this.type}-color`)
            ).isValid
        ) {
            return this.designService.getCSSProperty(`${this.type}-color`)
        } else if (this.type == "opacity") {
            return "rgba(255, 255, 255, 0.4)"
        } else if (this.type == "text") {
            return "rgba(255, 255, 255, 0.0)"
        } else if (new TinyColor(bgColor).isValid) {
            return bgColor
        }
    }

    getBackgroundColorOnHover() {
        let newBgColor
        let bgColor = this.getBackgroundColor()

        let color = new TinyColor(bgColor)
        let alpha = color.getAlpha()

        // depending on the opacity (alpha level) we either darken the button or increase the alpha
        if (alpha < 0.5 && !["text", "opacity"].includes(this.type)) {
            let newAlpha = alpha + 0.2

            newBgColor = color.lighten(20).setAlpha(newAlpha).toString()
        } else {
            newBgColor = color.darken(5).setAlpha(alpha).toString()
        }

        return newBgColor
    }

    ignoreHoverStyle() {
        if (
            this.type == "text" ||
            this.type == "round" ||
            this.type == "round-icon-and-text"
        ) {
            return true
        }

        return false
    }

    ngOnDestroy() {}
}
