<div class="step-1" *ngIf="step === 1">
    <div class="modal-title">Create a template pack</div>
    <div class="modal-subtitle">
        The template will be added to your personal library of packs, and will
        be reusable in different styles.
    </div>

    <div class="modal-close">
        <img src="assets/img/close.svg" (click)="close.emit()" />
    </div>

    <input
        type="text"
        class="modal-input template-pack-name-input"
        #packName
        placeholder="Enter a template pack name"
        [(ngModel)]="parameters.sourcePack.name" />

    <div class="error-message" *ngIf="error">{{ error }}</div>

    <div class="button-container">
        <a-button
            (click)="createTemplate()"
            type="secondary"
            label="Apply"
            width="100%"
            *ngIf="createTemplateLoading === false"></a-button>

        <div class="loader" *ngIf="createTemplateLoading"></div>
    </div>
</div>

<div class="step-2" *ngIf="step === 2">
    <div class="modal-title">Success!</div>
    <div class="modal-subtitle">
        Your template was successfully created.
    </div>

    <div class="modal-close">
        <img src="assets/img/close.svg" (click)="close.emit()" />
    </div>
    <svg
        class="checkmark-green"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path
            class="checkmark__check"
            fill="transparent"
            d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>

    <a-button
        type="secondary"
        label="Go to library of template packs"
        width="100%"
        (click)="goToTemplateLibrary()"></a-button>
</div>
