import { Injectable } from "@angular/core"
import {
    DesktopAppAPI,
    GeneralAPI,
    GetMetaInfoResponse,
    PotionAPI,
    RealtimeSamplerAPI,
} from "../../../../common-lib/general/interfaces/api/desktop-app.api"

/**
 * Wrapper class for the global window object,
 * don't rename the variable below, it is being used in sentry build script
 */

const VERSION_NUMBER = "5.5.0"

@Injectable()
export class WindowService {
    public readonly versionNumber = VERSION_NUMBER

    public window: Window = window
    public desktopAppInfo: GetMetaInfoResponse | undefined = undefined

    constructor() {
        this.initialize()
    }

    private initialize(): void {
        this.initRequestAnimationFrame()
    }

    public async isOutdatedApp(): Promise<boolean> {
        if (this.desktopAppAPI === undefined) {
            return false
        }

        if (this.desktopAppAPI && this.samplerAPI === undefined) {
            return true
        }

        const info = await this.generalAPI.getMetaInfo()
        const version = info.versions.app.split("-")[0].split(".")

        if (
            info.versions.app.split("-").length > 1 &&
            info.versions.app.split("-")[1].includes("local")
        ) {
            return false
        }

        if (version.length !== 3) {
            return true
        }

        // The version number above which the app is not outdated. This value is included
        const thresholdVersion = {
            major: 2,
            minor: 4,
            patch: 1,
        }

        const major = parseInt(version[0])
        const minor = parseInt(version[1])
        const patch = parseInt(version[2])

        const majorAhead = major > thresholdVersion.major
        const minorAhead =
            major >= thresholdVersion.major && minor > thresholdVersion.minor
        const patchAhead =
            major >= thresholdVersion.major &&
            minor >= thresholdVersion.minor &&
            patch >= thresholdVersion.patch

        return !majorAhead && !minorAhead && !patchAhead
    }

    public get desktopAppAPI(): DesktopAppAPI | undefined {
        return window["electron"]
    }

    public get samplerAPI(): RealtimeSamplerAPI | undefined {
        return this.desktopAppAPI?.samplerAPI
    }

    public get generalAPI(): GeneralAPI | undefined {
        return this.desktopAppAPI?.generalAPI
    }

    public get potionAPI(): PotionAPI | undefined {
        return this.desktopAppAPI?.potionAPI
    }

    private initRequestAnimationFrame(): void {
        window["requestAnimFrame"] = callback => {
            let requestAnimFrame

            if (window.requestAnimationFrame) {
                requestAnimFrame = window.requestAnimationFrame
            } else if (window["webkitRequestAnimationFrame"]) {
                requestAnimFrame = window["webkitRequestAnimationFrame"]
            } else if (window["mozRequestAnimationFrame"]) {
                requestAnimFrame = window["mozRequestAnimationFrame"]
            } else if (window["oRequestAnimationFrame"]) {
                requestAnimFrame = window["oRequestAnimationFrame"]
            } else if (window["msRequestAnimationFrame"]) {
                requestAnimFrame = window["msRequestAnimationFrame"]
            } else {
                return window.setTimeout(callback, 1000 / 60)
            }

            return requestAnimFrame(callback)
        }
    }
}
