import { Note } from "./note"
import { Pattern } from "./pattern"
import PatternRegion from "./patternregion"
import Layer from "./layer"
import { Time } from "../../modules/time"
import { AUDIO_REFRESH_RATE, TIMESTEP_RES } from "../../constants/constants"
import { TemplateLayer } from "../../interfaces/score/templateScore"
import { NotesObject } from "./notesObject"
import { FractionString, TimeSignature } from "../../types/score"
import Tempo from "./tempo"
import Section from "./section"
import Channel from "./channel"
import { cloneDeep } from "lodash"
import TrackBus from "./trackbus"
import { ScoreManipulation } from "../../modules/scoremanipulation"
import { v4 as uuidv4 } from "uuid"

export interface manipulatePatternRegion {
    (region: PatternRegion): void
}

export default class PercussionLayer extends Layer {
    patternRegions: Array<PatternRegion> = []
    patterns: Array<Pattern> = []
    selectedPattern: Pattern

    constructor(
        type,
        value,
        name,
        color,
        effectsFromScore,
        timeSignature,
        patternRegions,
        patterns,
        selectedPattern,
        gainBias
    ) {
        super(
            type,
            value,
            name,
            color,
            effectsFromScore,
            timeSignature,
            gainBias
        )

        this.patternRegions = patternRegions
    }

    decode(): TemplateLayer {
        const result = super.decode()

        result.patterns = this.patterns.map(p => p.decode())
        result.pattern_regions = this.patternRegions.map(pr => pr.decode())

        return result
    }

    applyLayerState(layer: PercussionLayer) {
        super.applyLayerState(layer)

        this.patterns = layer.patterns
        this.patternRegions = layer.patternRegions
        this.selectedPattern = layer.selectedPattern
    }

    setNotesObjectAndTBRegions(
        object: { [trackBusID: string]: NotesObject },
        timeSignature: TimeSignature,
        sections: Section[]
    ) {
        this.notesObject = new NotesObject()

        for (const tb of this.trackBuses) {
            const exists =
                Object.keys(object).find(tbID => tbID === tb.id) !== undefined

            if (!exists) {
                tb.blocks = []
                continue
            }

            tb.blocks = []

            object[tb.id].manipulateNoteGroups(noteGroup => {
                const start = Time.fractionToTimesteps(
                    TIMESTEP_RES,
                    noteGroup[0].start
                )

                tb.blocks.push({
                    id: uuidv4(),
                    start,
                    end: start + 1,
                })

                this.notesObject.addNotesToGroup(
                    noteGroup,
                    timeSignature,
                    sections
                )

                return true
            })

            tb.blocks = ScoreManipulation.mergeTrackBusRegions(
                tb.blocks
            ).regions
        }
    }

    /**
     * @deprecated Consider using ScoreManipulation.convertPatternRegionsToNotes instead
     * @param args
     * @returns
     */
    computeNotesObject(args: {
        sections: Section[]
        tempoMap: Tempo[]
        timeSignature: TimeSignature
        realTimeSampler: boolean
        timeSlice?: FractionString
    }): NotesObject {
        const notesObject: NotesObject = new NotesObject()

        for (let patternRegion of this.patternRegions) {
            if (patternRegion.pattern === undefined) {
                continue
            }

            const channels = patternRegion.pattern.channels

            const patternOnsets = {}

            const duration = Time.multiplyFractionWithNumber(
                patternRegion.duration,
                patternRegion.loop + 1
            )

            const checkTime =
                args.realTimeSampler && args.timeSlice !== undefined

            if (
                checkTime &&
                !(
                    Time.compareTwoFractions(
                        patternRegion.start,
                        args.timeSlice
                    ) !== "gt" &&
                    Time.compareTwoFractions(
                        Time.addTwoFractions(duration, patternRegion.start),
                        args.timeSlice
                    ) === "gt"
                )
            ) {
                continue
            }

            let solo = false

            for (let channel of channels) {
                if (channel.solo) {
                    solo = true

                    break
                }
            }

            for (let channel of channels) {
                if (channel.mute || (solo && !channel.solo)) {
                    continue
                }

                if (channel.name == "Unassigned" && args.realTimeSampler) {
                    continue
                }

                if (patternOnsets[channel.pitches[0]] == null) {
                    patternOnsets[channel.pitches[0]] = []
                }

                for (const onset of channel.onsets) {
                    if (
                        Time.compareTwoFractions(
                            onset.start,
                            patternRegion.onset
                        ) == "lt" ||
                        Time.compareTwoFractions(
                            Time.addTwoFractions(
                                onset.start,
                                patternRegion.onset,
                                true
                            ),
                            patternRegion.duration
                        ) != "lt"
                    ) {
                        continue
                    }

                    let add = true

                    for (const patternOnset of patternOnsets[
                        channel.pitches[0]
                    ]) {
                        if (
                            Time.compareTwoFractions(
                                patternOnset.start,
                                onset.start
                            ) == "eq"
                        ) {
                            add = false

                            patternOnset.trackBuses.push(channel.trackBus)

                            break
                        }
                    }

                    if (add) {
                        patternOnsets[channel.pitches[0]].push({
                            start: onset.start,
                            trackBuses: [channel.trackBus],
                        })
                    }
                }
            }

            for (let i = 0; i < patternRegion.loop + 1; i++) {
                for (const channelPitch in patternOnsets) {
                    for (const onset of patternOnsets[channelPitch]) {
                        if (isNaN(parseInt(channelPitch))) {
                            continue // Unassigned channels
                        }

                        const start = Time.addTwoFractions(
                            Time.addTwoFractions(
                                Time.addTwoFractions(
                                    onset.start,
                                    patternRegion.onset,
                                    true
                                ),
                                patternRegion.start
                            ),
                            Time.multiplyFractionWithNumber(
                                patternRegion.duration,
                                i
                            )
                        )

                        const note = new Note({
                            start: start,
                            duration: "1/" + TIMESTEP_RES,
                            pitch: parseInt(channelPitch),
                            meta: {
                                layer: this.value,
                                section: null,
                                pattern: {
                                    id: patternRegion.pattern.id,
                                    onset: onset.start,
                                },
                            },
                            beat: Time.fractionToBeat(
                                start,
                                args.timeSignature
                            ),
                        })

                        if (
                            args.realTimeSampler &&
                            args.timeSlice !== undefined
                        ) {
                            const breakRealtime =
                                Time.compareTwoFractions(
                                    args.timeSlice,
                                    note.start
                                ) === "lt"

                            if (breakRealtime) {
                                break
                            }

                            const continueRealtime =
                                Time.compareTwoFractions(
                                    args.timeSlice,
                                    Time.addTwoFractions(
                                        note.start,
                                        note.duration
                                    )
                                ) === "gt"

                            if (continueRealtime) {
                                continue
                            }
                        }

                        notesObject.addNoteToGroup(
                            note,
                            args.timeSignature,
                            args.sections
                        )
                    }
                }
            }
        }

        return notesObject
    }

    sortPatternRegions() {
        this.patternRegions.sort(function (a, b) {
            var aStart: any = Time.fractionToDictionary(a.start)
            aStart = aStart.numerator / aStart.denominator

            var bStart: any = Time.fractionToDictionary(b.start)
            bStart = bStart.numerator / bStart.denominator

            return aStart - bStart
        })
    }

    deletePatternRegionsWithinTimeRange(
        start: string,
        duration: string,
        timeSignature: number[]
    ) {
        const boundary = {
            start: start,
            duration: duration,
            end: Time.addTwoFractions(start, duration),
        }

        for (let pr = this.patternRegions.length - 1; pr >= 0; pr--) {
            const patternRegion = this.patternRegions[pr]

            const patternRegionLoopedDuration =
                patternRegion.getLoopedDuration()
            const patternRegionLoopedEnd = patternRegion.getLoopedEnd()
            const patternRegionEnd = patternRegion.getEndWithoutLoop()

            // Pattern region start is in time range
            if (Time.fractionIsInBoundaries(boundary, patternRegion.start)) {
                if (
                    Time.fractionIsInBoundaries(
                        boundary,
                        patternRegionLoopedEnd,
                        true,
                        true
                    )
                ) {
                    this.patternRegions.splice(pr, 1)
                } else {
                    this.truncatePatternRegionContinuingAfterTimeRange(
                        boundary,
                        patternRegionLoopedEnd,
                        patternRegionLoopedDuration,
                        patternRegion,
                        timeSignature
                    )

                    this.patternRegions.splice(pr, 1)
                }
            }

            // Pattern region looped end is in time range
            else if (
                Time.fractionIsInBoundaries(
                    boundary,
                    patternRegionLoopedEnd,
                    true,
                    true
                )
            ) {
                this.truncatePatternRegionsInTimeRange(
                    boundary,
                    patternRegion,
                    patternRegionLoopedEnd,
                    patternRegionLoopedDuration
                )

                this.patternRegions.splice(pr, 1)
            }

            // Pattern Region start and looped end are outside of the time range, but cross it
            else if (
                Time.compareTwoFractions(patternRegion.start, boundary.start) ==
                    "lt" &&
                Time.compareTwoFractions(
                    patternRegionLoopedEnd,
                    boundary.end
                ) == "gt"
            ) {
                this.truncatePatternRegionsInTimeRange(
                    boundary,
                    patternRegion,
                    patternRegionLoopedEnd,
                    patternRegionLoopedDuration
                )

                this.truncatePatternRegionContinuingAfterTimeRange(
                    boundary,
                    patternRegionLoopedEnd,
                    patternRegionLoopedDuration,
                    patternRegion,
                    timeSignature
                )

                this.patternRegions.splice(pr, 1)
            }
        }

        this.sortPatternRegions()
    }

    truncatePatternRegionContinuingAfterTimeRange(
        boundary,
        patternRegionLoopedEnd,
        patternRegionLoopedDuration,
        patternRegion,
        timeSignature
    ) {
        if (
            Time.compareTwoFractions(patternRegionLoopedEnd, boundary.end) !=
            "gt"
        ) {
            return
        }

        var shortenedPR = new PatternRegion(
            patternRegion,
            patternRegion.pattern
        )

        var durationToRemove = Time.addTwoFractions(
            boundary.end,
            shortenedPR.start,
            true
        )
        var offsetPRDuration = Time.addTwoFractions(
            patternRegionLoopedDuration,
            durationToRemove,
            true
        )

        var loopAmount = Math.floor(
            Time.divideTwoFractions(offsetPRDuration, shortenedPR.duration)
        )
        var offsetDuration = Time.addTwoFractions(
            offsetPRDuration,
            Time.multiplyFractionWithNumber(shortenedPR.duration, loopAmount),
            true
        )

        if (Time.compareTwoFractions(offsetDuration, "0") == "gt") {
            var patternDuration = Time.multiplyFractionWithNumber(
                timeSignature[0] + "/" + timeSignature[1],
                shortenedPR.pattern.bars
            )

            var offsetPR = new PatternRegion(shortenedPR, shortenedPR.pattern)
            offsetPR.duration = offsetDuration
            offsetPR.onset = Time.addTwoFractions(
                patternDuration,
                offsetDuration,
                true
            )
            offsetPR.start = boundary.end
            offsetPR.loop = 0

            this.patternRegions.push(offsetPR)
        }

        shortenedPR.start = Time.addTwoFractions(boundary.end, offsetDuration)
        shortenedPR.loop = loopAmount - 1

        if (shortenedPR.loop >= 0) {
            this.patternRegions.push(shortenedPR)
        }
    }

    truncatePatternRegionsInTimeRange(
        boundary,
        patternRegion,
        patternRegionLoopedEnd,
        patternRegionLoopedDuration
    ) {
        var shortenedPR = new PatternRegion(
            patternRegion,
            patternRegion.pattern
        )

        if (Time.fractionIsInBoundaries(boundary, patternRegionEnd)) {
            shortenedPR.loop = 0

            var patternRegionEnd = Time.addTwoFractions(
                shortenedPR.start,
                shortenedPR.duration
            )
            var offset = Time.addTwoFractions(
                patternRegionEnd,
                boundary.start,
                true
            )

            shortenedPR.duration = offset
        } else {
            var durationToRemove = Time.addTwoFractions(
                patternRegionLoopedEnd,
                boundary.start,
                true
            )
            var offsetPRDuration = Time.addTwoFractions(
                patternRegionLoopedDuration,
                durationToRemove,
                true
            )

            var loopAmount = Time.divideTwoFractions(
                offsetPRDuration,
                shortenedPR.duration
            )

            shortenedPR.loop = Math.floor(loopAmount) - 1

            if (loopAmount > Math.floor(loopAmount)) {
                offsetPRDuration = Time.multiplyFractionWithNumber(
                    shortenedPR.duration,
                    shortenedPR.loop + 1
                )
                var offsetPRLoopEnd = Time.addTwoFractions(
                    shortenedPR.start,
                    offsetPRDuration
                )

                var offset = Time.addTwoFractions(
                    boundary.start,
                    offsetPRLoopEnd,
                    true
                )

                var offsetPR = new PatternRegion(
                    shortenedPR,
                    shortenedPR.pattern
                )
                offsetPR.loop = 0
                offsetPR.start = offsetPRLoopEnd
                offsetPR.duration = offset

                this.patternRegions.push(offsetPR)
            }
        }

        this.patternRegions.push(shortenedPR)
    }

    static deletePatternRegion(
        patternRegions: PatternRegion[],
        regionId: string
    ): PatternRegion[] {
        patternRegions = patternRegions.filter(pr => pr.id !== regionId)
        return patternRegions
    }

    static getPatternRegionsInRange({
        regions,
        timestepRange,
        patternsToInclude,
    }: {
        regions: PatternRegion[]
        timestepRange?: {
            start: number
            end: number
        }
        patternsToInclude?: Pattern[]
    }): PatternRegion[] {
        const patternRegions = []

        regions.sort((a, b) => {
            return Time.compareTwoFractions(a.start, b.start) === "lt" ? -1 : 1
        })

        for (const region of regions) {
            if (
                patternsToInclude !== undefined &&
                !patternsToInclude.includes(region.pattern)
            ) {
                continue
            }

            if (timestepRange) {
                const regionStart = Time.fractionToTimesteps(
                    TIMESTEP_RES,
                    region.start
                )
                const regionEnd =
                    regionStart +
                    Time.fractionToTimesteps(TIMESTEP_RES, region.duration) *
                        (region.loop + 1)
                const shouldSkip =
                    regionEnd < timestepRange.start ||
                    regionStart > timestepRange.end

                if (shouldSkip) {
                    if (regionStart > timestepRange.end) {
                        break
                    }

                    continue
                }
            }

            patternRegions.push(region)
        }

        return patternRegions
    }

    public convertPatternRegionsToNotes(
        timeSignature: TimeSignature,
        includeMutedNotes: boolean,
        timestepRange?: {
            start: number
            end: number
        },
        higherOrderFunction?: {
            (notes: NotesObject, region: PatternRegion): void
        }
    ): { [trackBusID: string]: { notes: NotesObject; trackBus: TrackBus } } {
        const result = {}

        const patternRegions = PercussionLayer.getPatternRegionsInRange({
            regions: this.patternRegions,
            timestepRange: timestepRange,
        })
        for (const region of patternRegions) {
            const regionNotes = this.getNotesForCoreRegion(
                region,
                includeMutedNotes,
                timeSignature
            )

            const regionNotesObject = new NotesObject()
            for (const trackBusID in regionNotes) {
                if (!result[trackBusID]) {
                    result[trackBusID] = {
                        notes: new NotesObject(),
                        trackBus: regionNotes[trackBusID].trackBus,
                    }
                }
                PercussionLayer.loopNotesForRegion(
                    region,
                    regionNotes[trackBusID].notes,
                    timeSignature,
                    higherOrderFunction
                        ? regionNotesObject
                        : result[trackBusID].notes,
                    timestepRange
                )
            }
            if (higherOrderFunction) {
                higherOrderFunction(regionNotesObject, region)
            }
        }
        return result
    }

    public getNotesForCoreRegion(
        region: PatternRegion,
        includeMutedNotes: boolean,
        timeSignature: TimeSignature
    ): { [trackBusID: string]: { notes: Note[]; trackBus: TrackBus } } {
        if (region.pattern === undefined) {
            return {}
        }

        const channels: Channel[] = region.pattern.channels
        const hasSoloedChannel: boolean =
            PercussionLayer.hasSoloedChannel(channels)

        const result = {}

        for (const channel of channels) {
            const isMuted =
                channel.mute ||
                (hasSoloedChannel && !channel.solo) ||
                channel.name === "Unassigned"

            if (isMuted && !includeMutedNotes) {
                continue
            }

            if (!result[channel.trackBus.id]) {
                result[channel.trackBus.id] = {
                    notes: [],
                    trackBus: channel.trackBus,
                }
            }

            for (const onset of channel.onsets) {
                const onsetIsBeforeRegionStart =
                    Time.compareTwoFractions(onset.start, region.onset) === "lt"

                const onsetIsAfterRegionEnd =
                    Time.compareTwoFractions(
                        Time.addTwoFractions(onset.start, region.onset, true),
                        region.duration
                    ) !== "lt"

                if (onsetIsBeforeRegionStart || onsetIsAfterRegionEnd) {
                    continue
                }

                const fraction = region.getAbsoluteStart(onset)

                result[channel.trackBus.id].notes.push(
                    new Note({
                        start: fraction,
                        duration: "1/" + TIMESTEP_RES,
                        pitch: channel.pitches[0],
                        meta: {
                            layer: this.value,
                            section: null,
                            pattern: {
                                id: region.pattern.id,
                                onset: onset.start,
                            },
                        },
                        beat: Time.fractionToBeat(fraction, timeSignature),
                    })
                )
            }
        }

        return result
    }

    static isOutOfRange(
        value: number,
        timestepRange?: {
            start: number
            end: number
        }
    ): boolean {
        return (
            timestepRange !== undefined &&
            (value < timestepRange.start || value >= timestepRange.end)
        )
    }

    static loopNotesForRegion(
        region: PatternRegion,
        notes: Note[],
        timeSignature: TimeSignature,
        notesObject: NotesObject,
        timestepRange?: {
            start: number
            end: number
        }
    ): NotesObject {
        for (let l = 0; l <= region.loop; l++) {
            const loopStart = Time.fractionToTimesteps(
                TIMESTEP_RES,
                Time.addTwoFractions(
                    Time.multiplyFractionWithNumber(region.duration, l),
                    region.start
                )
            )

            const loopDuration = Time.fractionToTimesteps(
                TIMESTEP_RES,
                region.duration
            )
            const loopEnd = loopStart + loopDuration

            if (
                PercussionLayer.isOutOfRange(loopStart, timestepRange) &&
                PercussionLayer.isOutOfRange(loopEnd, timestepRange)
            ) {
                continue
            }

            const loopDurationFraction = Time.timestepToFraction(
                loopDuration,
                TIMESTEP_RES
            )

            for (const note of notes) {
                const newNoteStart = Time.addTwoFractions(
                    Time.multiplyFractionWithNumber(loopDurationFraction, l),
                    note.start
                )

                const newNoteTimesteps = Time.fractionToTimesteps(
                    TIMESTEP_RES,
                    newNoteStart
                )

                if (
                    PercussionLayer.isOutOfRange(
                        newNoteTimesteps,
                        timestepRange
                    )
                ) {
                    continue
                }

                const newNote = cloneDeep(note)
                newNote.start = newNoteStart

                notesObject.addNoteToGroup(newNote, timeSignature, [])
            }
        }

        return notesObject
    }

    trimOverlappingPatternRegions(
        selectedRegions: PatternRegion[],
        timeSignature: number[],
        timestepRes: number
    ): PatternRegion[] {
        let patternRegions = this.patternRegions.filter(pr =>
            selectedRegions.find(sr => sr.id !== pr.id)
        )

        const beatLength = TIMESTEP_RES / timeSignature[1]
        const beatLengthInFractions = Time.timestepToFraction(
            beatLength,
            timestepRes
        )

        selectedRegions.forEach(sr => {
            const start = sr.start
            const end = sr.getLoopedEnd()

            patternRegions.forEach(pr => {
                // region start after the selected region end
                if (
                    Time.compareTwoFractions(pr.start, end) === "gt" ||
                    Time.compareTwoFractions(pr.start, end) === "eq"
                ) {
                    return
                }

                const prEnd = pr.getLoopedEnd()

                // region ends before the selected region starts
                if (
                    Time.compareTwoFractions(start, prEnd) === "gt" ||
                    Time.compareTwoFractions(start, prEnd) === "eq"
                ) {
                    return
                }

                let shouldRemoveRegion = false

                /**
                 * checks if the region should be removed,
                 * ie is completely covered by the selected region
                 */
                if (
                    (Time.compareTwoFractions(start, pr.start) === "lt" ||
                        Time.compareTwoFractions(start, pr.start) === "eq") &&
                    (Time.compareTwoFractions(end, prEnd) === "gt" ||
                        Time.compareTwoFractions(end, prEnd) === "eq")
                ) {
                    shouldRemoveRegion = true
                }

                if (
                    (Time.compareTwoFractions(start, pr.start) === "lt" ||
                        Time.compareTwoFractions(start, pr.start) === "eq") &&
                    (Time.compareTwoFractions(end, pr.start) === "gt" ||
                        Time.compareTwoFractions(end, pr.start) === "eq") &&
                    !shouldRemoveRegion
                ) {
                    if (
                        Time.compareTwoFractions(prEnd, end) === "lt" ||
                        Time.compareTwoFractions(prEnd, end) === "eq"
                    ) {
                        shouldRemoveRegion = true
                    } else {
                        pr.trimFromStart(end)

                        // remove region if the duration is less than 1 beat
                        if (
                            Time.compareTwoFractions(
                                pr.duration,
                                beatLengthInFractions
                            ) === "lt"
                        ) {
                            shouldRemoveRegion = true
                        }
                    }
                }

                // Selected region overlaps with a region on the left
                if (
                    (Time.compareTwoFractions(start, pr.start) === "gt" ||
                        Time.compareTwoFractions(start, pr.start) === "eq") &&
                    (Time.compareTwoFractions(end, pr.start) === "gt" ||
                        Time.compareTwoFractions(end, pr.start) === "eq") &&
                    !shouldRemoveRegion
                ) {
                    pr.trimFromEnd(start)

                    // remove region if the duration is less than 1 beat
                    if (
                        Time.compareTwoFractions(
                            pr.duration,
                            beatLengthInFractions
                        ) === "lt"
                    ) {
                        shouldRemoveRegion = true
                    }
                }

                if (shouldRemoveRegion) {
                    patternRegions = PercussionLayer.deletePatternRegion(
                        patternRegions,
                        pr.id
                    )
                }
            })
        })

        patternRegions.push(...selectedRegions)
        this.patternRegions = patternRegions

        return patternRegions
    }

    getMaxPatternID(): number {
        if (!this.patterns) {
            return 0
        }

        const patterns = this.patterns

        if (patterns.length === 0) {
            return 0
        }

        let maxPatternID = Math.max(...this.patterns.map(p => p.id))

        if (maxPatternID === -Infinity) {
            maxPatternID = 0
        }

        return maxPatternID
    }

    static hasSoloedChannel(channels: Channel[]): boolean {
        for (const channel of channels) {
            if (channel.solo) {
                return true
            }
        }

        return false
    }
}
