<div
    class="layer-effect-container layer-preview-labels"
    *ngFor="let effect of getLayerEffects(); let i = index"
    [ngStyle]="{
        'margin-right': i % 2 === 0 ? '4px' : '0px'
    }">
    <div>
        <div
            class="layer-effect-button layer-effect-toggle-button"
            (click)="editor.engine.toggleEffect(effect)"
            [ngClass]="{
                'layer-effect-button-enabled': effect.active
            }"
            *ngIf="effect.name !== 'auto_phrasing'">
            <img src="assets/img/pianoroll/toggle_effect.svg" />
        </div>

        <!--<div
            class="layer-effect-button layer-effect-button-eye"
            (click)="toggleAutoPhrasing(effect)"
            *ngIf="effect.name === 'auto_phrasing'"
            [ngClass]="{
                'layer-effect-button-enabled': effect.view
            }">
            <img src="assets/img/eye.svg" />
        </div>-->

        <div
            class="layer-effect-title"
            *ngIf="effect.name === 'auto_staccato'"
            [tooltip]="'Auto Staccato'"
            [tooltip-type]="'onmouseenter'"
            [tooltip-position]="'left-bottom'"
            [tooltip-marginLeft]="-10"
            [tooltip-marginTop]="5"
            [tooltip-width]="250"
            [tooltip-description]="
                'If enabled, AIVA will automatically choose the best articulation for each note.'
            ">
            {{ effect.title }}
        </div>

        <!--<div class="layer-effect-title" *ngIf="effect.name === 'auto_phrasing'">
            {{ effect.title }}
        </div>-->

        <div
            class="layer-effect-title"
            *ngIf="
                effect.name !== 'auto_staccato' &&
                effect.name !== 'auto_phrasing'
            ">
            {{ effect.title }}
        </div>
    </div>

    <div
        class="layer-effect-button layer-effect-duplicate"
        (click)="duplicateAutomation(effect)"
        *ngIf="
            effect.name !== 'auto_staccato' && effect.name !== 'auto_phrasing'
        "
        [tooltip]="'Copy automation to all other layers'"
        [tooltip-width]="185"
        [tooltip-type]="'onmouseenter'">
        <img src="assets/img/menu/duplicate.svg" />
    </div>

    <div
        class="layer-effect-button"
        (click)="toggleAutomation(effect)"
        *ngIf="
            effect.name !== 'auto_staccato' && effect.name !== 'auto_phrasing'
        "
        [ngStyle]="{
            'background-color': getToggledAutomationBackgroundColor(effect)
        }">
        <img src="assets/img/graph.svg" />
    </div>
</div>

<div class="button-wrapper">
    <a-button
        class="editor-button"
        label="Add instrument"
        icon="assets/img/add.svg"
        width="100%" (click)="editor.engine.addTrackBus(instruments.instruments)"></a-button>
</div>
