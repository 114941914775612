<div
    class="left-label"
    [ngClass]="{
        'selected-trackbus': engine.isSelectedTB(trackBus)
    }">
    <div class="instrument-and-loudness-meter">
        <div
            class="instrument-selection-button"
            (click)="openInstrumentSelectionModal()"
            [tooltip]="trackBus.getPatch(instruments.instruments)"
            [tooltip-width]="153"
            [tooltip-type]="'onmouseenter'"
            [tooltip-position]="'middle-bottom'"
            [tooltip-marginTop]="5">
            <div class="instrument-selection-button-title">
                {{ trackBus.getPatch(instruments.instruments) }}
            </div>
            <img src="assets/img/pianoroll/toggled.svg" />
        </div>

        <div class="loudness-meter-wrapper">
            <loudness-meter
                [trackBusses]="[trackBus]"
                [engine]="engine" [maxGain]="30"></loudness-meter>
        </div>
    </div>

    <div
        class="instrument-controls"
        (contextmenu)="toggleSettingsMenu($event, false)">
        <div class="loader track-bus-loader" *ngIf="trackBus.loading"></div>

        <dial
            *ngIf="!trackBus.loading"
            [trackBus]="trackBus"
            [layerObject]="layer"
            [unitLeft]="'% Left'"
            [unitRight]="'% Right'"
            [value]="trackBus.panning"
            [color]="'--primary-color'"
            [variableControlled]="'panning'"
            (valueOutput)="setPanning($event)"
            [type]="'pan'"
            [minLabel]="'L'"
            [maxLabel]="'R'"
            [min]="-1"
            [max]="1"></dial>

        <img
            src="assets/img/pianoroll/settings.svg"
            class="layer-list-settings"
            (mousedown)="toggleSettingsMenu($event, false)" />

        <mute-and-solo
            [engine]="engine"
            [solo]="solo.bind(this)"
            [mute]="mute.bind(this)"
            [trackBusses]="[trackBus]"></mute-and-solo>
    </div>
</div>

<menu-modifiers
    [modifiers]="trackBusSettings"
    *ngIf="trackBusSettings !== undefined"
    (close)="toggleSettingsMenu($event, true)"></menu-modifiers>
