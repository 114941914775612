import { Component, EventEmitter, Input, Output } from "@angular/core"
import Section from "@common-lib/classes/score/section"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import { SRActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"

@Component({
    templateUrl: "./section-copy-modal.component.html",
    styleUrls: ["./section-copy-modal.component.scss"],
    selector: "section-copy-modal",
})
export class SectionCopyModal {
    @Input() parameters: {
        section: Section
        sections: Section[]
        engine: ScoreRenderingEngine
    }
    @Output() close: EventEmitter<any> = new EventEmitter()

    public sectionOptions: { name: string; value: string }[]
    public sectionFrom: Section

    public get sectionTo() {
        return this.parameters.section
    }

    public closeModal() {
        this.close.emit()
    }

    ngOnInit() {
        this.sectionOptions = this.parameters.sections
            .filter(section => section.title !== this.sectionTo.title)
            .map(section => {
                return {
                    name: section.title,
                    value: section.title,
                }
            })
        this.selectSectionFrom(this.sectionOptions[0].value)
    }

    public selectSectionFrom(sectionTitle: string) {
        this.sectionFrom = this.parameters.sections.find(
            section => section.title === sectionTitle
        )
    }

    public copySection() {
        this.parameters.engine.srEmitter$.next({
            type: SRActionTypes.copySectionChords,
            data: {
                from: this.sectionFrom,
                to: this.sectionTo,
            },
            options: {
                isUndoable: true,
            },
        })
        this.closeModal()
    }
}
