import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import { DeviceInfo } from "@common-lib/interfaces/api/desktop-app.api"
import { ApiService } from "@services/api.service"
import { ModalService, SettingsModalInput } from "@services/modal.service"
import { UserService } from "@services/user.service"
import { WindowService } from "@services/window.service"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../../types/dropdownItemType"
import { AudioService } from "@services/audio/audio.service"

@Component({
    selector: "settings",
    templateUrl: "settings.component.html",
    styleUrls: ["./settings.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsModalComponent implements OnInit {
    emailSettings
    selectedNetworkConnection

    @Input() input: SettingsModalInput
    @Output() close: EventEmitter<any> = new EventEmitter()
    settingsContainer:
        | "about"
        | "changePassword"
        | "email"
        | "network"
        | "audio" = "about"

    instrumentPath = "Test/Path/here"

    public audioLoading: boolean = true

    public audioDevices: DropdownItemType<string>[] = []
    public selectedAudioDevice: DropdownItemType<string> | undefined = undefined

    instrumentPathLoading: boolean = false
    instrumentPathLoadingMessage: string = ""
    instrumentsPathError: string = ""

    resetCacheLoading: boolean = false

    networkConnections = [
        {
            value: "auto",
            label: "Auto",
            description: "Let AIVA automatically choose the best connection.",
        },
        {
            value: "default",
            label: "Europe",
            description: "Connect to a European server.",
        },
        {
            value: "nyc",
            label: "United States",
            description: "Connect to a US server.",
        },
    ]

    constructor(
        private api: ApiService,
        private modalService: ModalService,
        private userService: UserService,
        public windowService: WindowService,
        public audio: AudioService,
        private ref: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        this.api
            .authRequest("/user/getEmailSettings", {}, "primary", true)
            .then(res => {
                this.emailSettings = res.emailSettings
            })

        if (
            localStorage.getItem("geolocation") &&
            localStorage.getItem("geolocation") != ""
        ) {
            this.selectedNetworkConnection = localStorage.getItem("geolocation")
        } else {
            this.selectedNetworkConnection = "auto"
        }

        if (this.windowService.desktopAppAPI !== undefined) {
            this.instrumentPathLoading = true
            this.instrumentPath =
                await this.windowService.samplerAPI.getInstrumentDirectory()
            this.instrumentPathLoading = false
        }

        if (this.input.type === "audio") {
            await this.openAudioSettings()
        }
    }

    getYear() {
        return new Date().getFullYear()
    }

    async openAudioSettings() {
        this.settingsContainer = "audio"
        this.audioLoading = true

        this.audio.refreshDevices()

        this.audioDevices = await this.audio.getAudioOutputDevicesDropdownList()

        this.selectedAudioDevice = await this.audio.getSelectedDevice()

        this.audioLoading = false
        this.ref.detectChanges()
    }

    changeNetworkConnection(value) {
        if (this.networkConnections.map(el => el.value).includes(value.value)) {
            this.selectedNetworkConnection = value.value
        }
    }

    async selectAudioDevice(event: DropdownSelection<string>) {
        this.selectedAudioDevice = event.new

        await this.audio.selectAudioDevice(event)
    }

    updateEmailSettings() {
        const updates = this.emailSettings.updates
        const downloads = this.emailSettings.downloads

        this.close.emit()

        this.api
            .authRequest(
                "/user/setEmailSettings",
                { updates: updates, downloads: downloads },
                "primary",
                false
            )

            .catch(err => {
                this.api.handleError(err)
            })
    }

    isDesktopApp() {
        return this.windowService.desktopAppAPI !== undefined
    }

    updateNetworkSettings() {
        localStorage.setItem("geolocation", this.selectedNetworkConnection)

        this.close.emit()

        window.location.reload()
    }

    openChangePasswordModal() {
        if (this.userService.userCanChangePassword()) {
            this.close.emit()
            this.modalService.changePassword.next(true)
        }
    }

    canChangePassword() {
        return this.userService.userCanChangePassword
    }

    resetCache(retry = 0) {
        this.resetCacheLoading = true

        localStorage.setItem("realTimeSamplerVersion", null)

        this.resetCacheLoading = false
        location.reload()
    }

    async setInstrumentsDirectory() {
        this.instrumentPathLoading = true
        this.instrumentPathLoadingMessage =
            "Copying instruments - this can take a few minutes."

        const result =
            await this.windowService.samplerAPI.setInstrumentDirectory()

        if (!result.success) {
            this.instrumentsPathError = result.message
        } else {
            window.location.reload()

            this.instrumentsPathError = ""
        }

        this.instrumentPath =
            await this.windowService.samplerAPI.getInstrumentDirectory()

        this.instrumentPathLoadingMessage = ""
        this.instrumentPathLoading = false

        this.ref.detectChanges()
    }
}
