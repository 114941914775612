import { ContextMenuItem } from "../components/reusable/context-menus/local-context-menu/local-context-menu.component"

export class ContextMenu {
    x: number
    y: number
    data: any
    contextMenuItems: Array<ContextMenuItem> = []
    selectFunction

    constructor(x, y, data, items, selectFunction) {
        this.x = x
        this.y = y
        this.data = data
        this.contextMenuItems = items
        this.selectFunction = selectFunction
    }
}