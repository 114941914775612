<div class="component-container" id="editor-container">
    <div *ngIf="editor.loading$.getValue().status !== 'loaded'">
        <div
            class="loading"
            *ngIf="editor.loading$.getValue().status === 'none'">
            <div class="loading-title">No composition selected to load</div>
        </div>

        <div
            class="loading"
            *ngIf="editor.loading$.getValue().status === 'scoreLoading'">
            <div class="loading-title">Loading your score</div>
            <div class="loader"></div>
        </div>

        <div
            class="loading"
            *ngIf="editor.loading$.getValue().status === 'compositionLoading'">
            <div class="loading-title">Rendering your composition</div>
            <progressbar
                [progress]="editor.loading$.getValue().progress"
                [total]="100">
            </progressbar>
        </div>

        <div
            class="loading"
            *ngIf="editor.loading$.getValue().status === 'failed'">
            <svg
                class="cross-red"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52">
                <circle
                    class="cross__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none" />
                <path
                    class="cross__crossed"
                    fill="none"
                    d="M16 16 36 36 M36 16 16 36" />
            </svg>

            <div class="loading-title">
                {{ editor.getScoreLoadingErrorMessage() }}
            </div>

            <div
                class="loader loader-small"
                *ngIf="loadingRetryRendering"></div>

            <a-button
                type="error"
                label="Retry rendering"
                (click)="retryRendering()"
                *ngIf="
                    !loadingRetryRendering &&
                    editor.getScoreLoadingErrorMessage() ===
                        'Your composition has failed to render'
                "></a-button>
        </div>
    </div>

    <div
        *ngIf="editor.loading$.getValue().status === 'loaded'"
        id="pianoroll-container"
        (onPinch)="onPinch($event)">
        <div class="top-wrapper">
            <div class="toolbar-wrapper">
                <div
                    id="toolbar"
                    [ngClass]="{
                        'toolbar-with-save-button':
                            editor.engine.scoreWasEdited$ | async,
                        'toolbar-with-reduced-buttons': !shouldShowMenuTitles()
                    }">
                    <a-button
                        type="secondary"
                        width="100%"
                        label="Save"
                        icon="assets/img/save_and_render.svg"
                        class="editor-button editor-button-large pulsing-button"
                        *ngIf="editor.engine.scoreWasEdited$ | async"
                        (click)="onSave()">
                    </a-button>

                    <a-button
                        type="secondary"
                        width="100%"
                        label="Discard"
                        icon="assets/img/delete.svg"
                        class="editor-button editor-button-large red"
                        (click)="discardChanges()"
                        *ngIf="editor.engine.scoreWasEdited$ | async">
                    </a-button>

                    <a-button
                        type="secondary"
                        [class]="buttonClass"
                        width="100%"
                        [label]="pencilMenuTitle"
                        (click)="setCursorType()"
                        [icon]="
                            'assets/img/editmodes/' +
                            (editor.engine.cursorType === 'pencil'
                                ? '1'
                                : '0') +
                            '.svg'
                        "
                        [tooltip]="
                            editor.engine.cursorType === 'pencil'
                                ? 'Pencil mode'
                                : 'Select mode'
                        "
                        [tooltip-description]="
                            'Enable pencil mode to draw notes in your composition. Shortcut: Ctrl E'
                        "
                        [tooltip-position]="'middle-bottom'"
                        tooltip-width="200"></a-button>
                    <a-button
                        type="secondary"
                        [class]="
                            'editor-button editor-button-large editor-button-large-without-text'
                        "
                        width="100%"
                        (click)="addLayer()"
                        [icon]="'assets/img/add.svg'"
                        [tooltip]="'New Layer'"
                        [tooltip-description]="
                            'Add a new layer to your composition'
                        "
                        [tooltip-position]="'middle-bottom'"
                        tooltip-width="200"></a-button>

                    <a-button
                        type="secondary"
                        [class]="
                            'editor-button editor-button-large editor-button-large-without-text'
                        "
                        [icon]="'assets/img/pianoroll/mixing.svg'"
                        width="100%"
                        [tooltip]="'Mixing'"
                        [tooltip-description]="
                            'Opens a panel to customize mixing values per layer'
                        "
                        [tooltip-position]="'middle-bottom'"
                        tooltip-width="200"
                        (click)="openAutoMixing()"></a-button>
                    <a-button
                        type="secondary"
                        [class]="
                            'editor-button editor-button-large editor-button-large-without-text'
                        "
                        [icon]="'assets/img/effects.svg'"
                        width="100%"
                        [tooltip]="'Effects'"
                        [tooltip-description]="
                            'Opens a panel to edit global audio effects'
                        "
                        [tooltip-position]="'middle-bottom'"
                        tooltip-width="200"
                        (click)="openCustomizeFX()"></a-button>

                    <a-button
                        type="secondary"
                        [class]="
                            'editor-button editor-button-large editor-button-large-without-text'
                        "
                        [tooltip]="'Shortcuts'"
                        [tooltip-position]="'middle-bottom'"
                        [icon]="'assets/img/shortcut2.svg'"
                        width="100%"
                        (click)="
                            modalService.shortcutModal.next(true)
                        "></a-button>

                    <a-button
                        type="secondary"
                        [class]="
                            'editor-button editor-button-large editor-button-large-without-text'
                        "
                        [tooltip]="
                            editor.engine.queries.editorView
                                .followTimelineCursor
                                ? 'Unfollow playhead'
                                : 'Follow playhead'
                        "
                        [tooltip-description]="
                            'Toggles whether you want the editor to automatically follow the playhead\'s position when it moves out of view during playback.'
                        "
                        [tooltip-position]="'middle-bottom'"
                        [icon]="'assets/img/menu/link.svg'"
                        tooltip-width="200"
                        width="100%"
                        (click)="
                            editor.engine.actions.editorView.toggleFollowTimelineCursor()
                        "></a-button>

                    <a-button
                        type="secondary"
                        [class]="
                            'editor-button editor-button-large editor-button-large-without-text'
                        "
                        [tooltip]="'Tutorial'"
                        [tooltip-description]="
                            'What a walkthrough video of the editor to learn how to use its features.'
                        "
                        [tooltip-position]="'middle-bottom'"
                        [icon]="'assets/img/info.svg'"
                        tooltip-width="200"
                        width="100%"
                        (click)="goToTutorial()"></a-button>
                    <div
                        *ngIf="featureFlags.enableChordsEditingInEditor"
                        class="tool-container">
                        <span class="tool-title">Harmony Lock</span>
                        <slide-toggle
                            [toggled]="getHarmonyLock()"
                            (onChange)="
                                toggleHarmonyLock($event)
                            "></slide-toggle>
                    </div>
                    <!--<dropdown
                    type="light"
                    *ngIf="isLocal()"
                    [showArrow]="true"
                    [value]="getTimeSignature()"
                    [items]="getSupportedTimeSignatures()"
                    (selectedItem)="selectedTimeSignature($event)"
                    [showTooltip]="false">
                </dropdown>-->
                    <ng-container
                        *ngIf="
                            instrumentsLoadingPercentage$
                                | async as instrumentsLoadingPercentage
                        ">
                        <ng-container
                            *ngIf="
                                instrumentsLoadingPercentage > 0 &&
                                instrumentsLoadingPercentage < 100
                            ">
                            <div class="progress-container">
                                <span class="progress-container__text">
                                    <ng-container
                                        *ngIf="
                                            instrumentsLoadingPercentage < 50
                                        "
                                        >Downloading your
                                        instruments</ng-container
                                    >
                                    <ng-container
                                        *ngIf="
                                            instrumentsLoadingPercentage >= 50
                                        "
                                        >Loading instruments in
                                        memory</ng-container
                                    >
                                </span>
                                <progressbar
                                    [progress]="instrumentsLoadingPercentage"
                                    [total]="100"
                                    width="195px"
                                    class="progress-container__progress">
                                </progressbar>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="audio-output" *ngIf="showAudioOutput()">
                <a-button
                    type="secondary"
                    [class]="buttonClass"
                    (click)="openAudioSettings()"
                    width="140px"
                    label="Audio Settings"
                    [icon]="'assets/img/pianoroll/settings.svg'"></a-button>
            </div>
        </div>

        <div class="play-head-wrapper">
            <play-head
                id="play-head-position"
                class="play-head-position"
                [engine]="editor.engine$ | async"></play-head>

            <div id="grid-row" class="row">
                <div class="grid-row-left">
                    <div class="grid-row-left-cell"></div>
                    <div class="grid-row-left-cell">
                        <div class="grid-row-cell-title">Zoom</div>
                        <div class="slider-container">
                            <resizer [engine]="editor.engine"></resizer>
                        </div>
                    </div>
                </div>

                <div id="pianoroll-grid" class="canvas-container"></div>
            </div>

            <div
                class="pianoroll-scrollable-element"
                [perfectScrollbar]="scrollConfig">
                <top-container [editor]="editor"></top-container>

                <layer-inspector [editor]="editor"></layer-inspector>
                <trackbus [editor]="editor"></trackbus>

                <div class="empty-background">
                    <sequencer [engine]="editor.engine"></sequencer>

                    <div class="pianoroll-empty-element"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<menu-options
    *ngIf="sectionOptions !== undefined"
    [menuOptions]="sectionOptions"
    (close)="closeSectionOptions($event)"></menu-options>
