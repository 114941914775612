import { GPMixingSchema } from "../../interfaces/db-schemas/generationprofile"
import { LayerMixingMusicEngine } from "../../interfaces/music-engine/generationprofile"
import GPValidation from "./gpvalidation"

export class GPReverb {
    ir: string = "None"
    wetness: number = 0
}

export class GPDelay {
    amount = 0
    leftTime: string = "1/48"
    rightTime: string = "1/48"
}

export class GPMixing {
    gainBias = 0
    reverb: GPReverb = new GPReverb()
    delay: GPDelay = new GPDelay()
    lfc = 20
    hfc = 20000

    static fromJSON(object) {
        let mixing = Object.assign(new GPMixing(), object)

        if (object.reverb == null) {
            mixing.reverb = new GPReverb()
        }

        if (object.delay == null) {
            mixing.delay = new GPDelay()
        }

        return mixing
    }

    static dBFSToPower(dB: number) {
        return Math.pow(10, dB / 10)
    }

    static powerToDBFS(power: number) {
        return 10 * Math.log10(power)
    }

    /**
     * https://en.wikipedia.org/wiki/Decade_(log_scale)
     */
    static hzToDecade(value, type) {
        if (type == "lfc") {
            return Math.log10(value / 20)
        } else {
            return 3 - Math.log10(value / 20)
        }
    }

    validateFilterValue(value) {
        value = Math.max(20, value)
        value = Math.min(value, 20000)

        return value
    }

    encodeForDB(): GPMixingSchema {
        return this
    }

    encodeForME(): LayerMixingMusicEngine {
        return {
            lfc: GPMixing.hzToDecade(this.validateFilterValue(this.lfc), "lfc"),
            hfc: GPMixing.hzToDecade(this.validateFilterValue(this.hfc), "hfc"),
            gain_bias: this.gainBias,
            reverb: this.reverb,
            delay: {
                left_time: this.delay.leftTime,
                right_time: this.delay.rightTime,
                amount: this.delay.amount,
            },
        }
    }

    validate(validateForUI = false) {
        let validation = new GPValidation(true, "mixing", this)

        if (validateForUI == false) {
            if (this.lfc > this.hfc - 200) {
                validation.valid = false
                validation.issue = "frequenciesOverlap"
                validation.message =
                    "The frequencies of the low- and highpass filter may not overlap or else this leads to silence."
            }
        }

        return validation
    }
}
