import Pack from "./pack"
import { Misc } from "../../modules/misc"
import AccompanimentPack from "./accompaniment/accompanimentpack"
import MelodyPack from "./melody/melodypack"
import HarmonyPack from "./harmony/harmonypack"
import GPValidation from "./gpvalidation"
import { GPMixing } from "./gpmixing"
import GPInfluenceLoading from "./influences/gpinfluenceloading"
import InstrumentPatch from "../score/instrumentpatch"

export default class GPLayer {
    name: string = ""
    type: string = "pitched"
    packs: Array<Pack | AccompanimentPack | MelodyPack | HarmonyPack> = []
    mixing: GPMixing = new GPMixing()
    fixedInstrumentation = false
    gpInfluenceLoading: GPInfluenceLoading = new GPInfluenceLoading(0, "")

    constructor(name: string) {
        this.name = name

        if (this.name.includes("Percussion")) {
            this.type = "percussion"
            this.fixedInstrumentation = true
        }
    }

    getName() {
        return Misc.replaceAll(this.name, "_", " ")
    }

    addPack(pack: MelodyPack | HarmonyPack | AccompanimentPack) {
        if (pack == null) {
            return
        }

        let newPack = pack.copy()
        this.packs.unshift(newPack)

        return newPack
    }

    validate(validateForUI = false, type = "layer") {
        let validation = new GPValidation(true, type, this)
        let packType = "accompanimentPack"

        if (type == "melodyLayer" && validateForUI == false) {
            validation.target.id = this["packID"]
            packType = "melodyPack"

            if (this.packs.length == 0) {
                validation.message = "No melody packID found."
                validation.issue = "packIDMissing"
                validation.valid = false
            }

            if (this.packs[0].instruments.length == 0) {
                validation.message = "Please add at least one instrument."
                validation.issue = "instrumentsMissing"
                validation.valid = false
            }
        } else if (
            this.packs == null ||
            !Array.isArray(this.packs) ||
            this.packs["length"] == 0
        ) {
            validation.message = "Please add at least one accompaniment pack."
            validation.issue = "packsMissing"
            validation.valid = false
        } else {
            if (type == "melodyLayer") {
                validation.target.id = this.packs[0].packID
                packType = "melodyPack"
            }

            for (let pack of this.packs) {
                let packValidation = pack.validate(validateForUI, packType)

                validation.validations.push(packValidation)

                if (packValidation.valid == false) {
                    validation.valid = false
                    validation.message =
                        "There are some issues with the added accompaniment packs."
                    validation.issue = "packValidation"
                }
            }
        }

        if (this.mixing != null && Object.keys(this.mixing).length > 0) {
            validation.validations.push(this.mixing.validate(validateForUI))
        }

        if (type != "melodyLayer") {
            let packLimitValidation = GPLayer.validatePackLimit(this.packs)

            validation.validations.push(packLimitValidation)

            if (packLimitValidation.valid == false) {
                validation.valid = false
                validation.issue = packLimitValidation.issue
                validation.message = packLimitValidation.message
            }
        }

        return validation
    }

    static validatePackLimit(packs, limit = 6) {
        let validation = new GPValidation(true, "packLimit", this)

        if (packs != null && packs.length > limit) {
            validation.valid = false
            validation.message =
                "You've reached the maximum number of packs to add (" +
                limit +
                " packs)."
            validation.issue = "packLimit"
        }

        return validation
    }

    /**
     * Returns an distinct array of instruments from all packs in the layer
     * @param layer 
     * @returns InstrumentPatch[]
     */
    static getAllInstruments(layer: GPLayer) {
        let instruments: InstrumentPatch[] = []

        for (let pack of layer.packs) {
            if (pack?.instruments?.length) {
                for (let inst of pack.instruments) {
                    if (!instruments.find(i => i.patchID === inst.patchID)) {
                        instruments.push(inst)
                    }
                }
            }
        }

        return instruments
    }
}
