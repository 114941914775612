import { TemplatePattern } from "../../interfaces/score/templateScore"
import { BarCount, FractionString } from "../../types/score"
import AIVAObject from "../general/aivaobject"
import Channel from "./channel"
import { Fraction } from "./fraction"
import TrackBus from "./trackbus"

export class Pattern extends AIVAObject {
    id: number
    name: string = "Pattern"
    bars: BarCount = 2
    _resolution: Fraction = new Fraction("1/8")
    mute: boolean = false
    solo: boolean = false
    channels: Array<Channel> = []

    public get resolution(): FractionString {
        return this._resolution.toString()
    }

    public set resolution(res: string) {
        this._resolution = new Fraction(res)
    }

    public get resolutionFraction(): Fraction {
        return this._resolution
    }

    constructor(id) {
        super()

        this.id = id
        this.name = "Pattern " + this.id
    }

    get noteRes() {
        return parseInt(this.resolution.split("/")[1])
    }

    decode(): TemplatePattern {
        return {
            id: this.id,
            name: this.name,
            bars: this.bars,
            resolution: this.resolution,
            mute: this.mute,
            solo: this.solo,
            channels: this.channels
                .filter(c => c.trackBus !== undefined)
                .map(c => c.decode()),
        }
    }

    isEqual(other: Pattern): boolean {
        if (this.bars !== other.bars) {
            return false
        }

        for (const c of this.channels) {
            if (!other.channels.some(oc => c.isEqual(oc))) {
                return false
            }
        }

        return true
    }

    getUniqueChannelsWithOnsets(): Channel[] {
        const channels: Channel[] = []

        const seenPitches = {}

        const sortedChannels = this.sortChannels()

        for (const c of sortedChannels) {
            let add = false

            for (const pitch of c.pitches) {
                if (!seenPitches[pitch]) {
                    add = true
                }
            }

            if (!add || c.onsets.length === 0) {
                continue
            }

            channels.push(c)
        }

        return channels
    }

    getChannelsForTrackbus(tb: TrackBus): Channel[] {
        return this.channels.filter(c => c?.trackBus?.id === tb?.id)
    }

    copy(): Pattern {
        var newPattern: Pattern = new Pattern(this.id)
        var keys = Object.keys(this)

        for (var k = 0; k < keys.length; k++) {
            const key = keys[k]

            if (key == "channels") {
                let channels = []

                for (let c = 0; c < this[key].length; c++) {
                    channels.push(this[key][c].copy())
                }

                newPattern[key] = channels
            } else {
                newPattern[key] = this[key]
            }
        }

        return newPattern
    }

    sortChannels() {
        this.channels.sort((a, b) => {
            if (a.trackBus == null && b.trackBus == null) {
                return 0
            }

            if (a.trackBus == null && b.trackBus != null) {
                return -1
            }

            if (a.trackBus != null && b.trackBus == null) {
                return 1
            }

            if (a.trackBus.id < b.trackBus.id) {
                return -1
            }

            if (a.trackBus.id > b.trackBus.id) {
                return 1
            }

            return 0
        })

        return this.channels
    }

    get timestepRes() {
        return parseInt(this.resolution.split("/")[1])
    }

    static fromTemplatePattern(tp: TemplatePattern, tb: TrackBus): Pattern {
        const pattern = new Pattern(tp.id)

        for (const key in tp) {
            if (key === "channels") {
                for (let c = 0; c < tp.channels.length; c++) {
                    if (tp.channels[c].track == null) {
                        continue
                    }

                    const channel = new Channel(
                        tp.channels[c].name,
                        tp.channels[c].pitches,
                        tp.channels[c].onsets,
                        tp.channels[c].mute,
                        tp.channels[c].solo,
                        tb
                    )

                    pattern.channels.push(channel)
                }
            } else {
                pattern[key] = tp[key]
            }
        }

        return pattern
    }
}
