import { Subject, filter } from "rxjs"
import {
    ClearPatchFromMemoryRequest,
    PlayNotesResponse,
    RealtimeSamplerAPI,
} from "../../../../general/interfaces/api/desktop-app.api"
import {
    ActionsManager,
    EmitterType,
} from "../../../../general/classes/actionsManager"
import { ScoreRenderingQuery } from "../score-rendering/score-rendering.query"
import { ScoreDecoding } from "../../../../general/modules/score-transformers/scoreDecoding"
import { Time } from "../../../../general/modules/time"
import {
    AUDIO_REFRESH_RATE,
    AUTOMATION_TIMESTEP_RES,
    TIMESTEP_RES,
    VIDEO_REFRESH_RATE,
} from "../../../../general/constants/constants"
import Patch from "../../../../general/classes/score/patch"
import TrackBus from "../../../../general/classes/score/trackbus"
import { ScoreManipulation } from "../../../../general/modules/scoremanipulation"
import { InstrumentsJSON } from "../../../../general/interfaces/score/general"
import { Misc } from "../../../../general/modules/misc"
import PercussionLayer from "../../../../general/classes/score/percussionlayer"
import Layer from "../../../../general/classes/score/layer"
import { ScoreRenderingStore } from "../score-rendering/score-rendering.store"
import Controller from "../../../../general/classes/score/controller"
import EffectPlugin from "../../../../general/classes/score/effectplugin"
import { Delay, Effect, Reverb } from "../../../../general/classes/score/effect"
import { Note } from "../../../../general/classes/score/note"
import {
    TemplateNote,
    TemplateScore,
} from "../../../../general/interfaces/score/templateScore"
import { NotesObject } from "../../../../general/classes/score/notesObject"
import { cloneDeep } from "lodash"
import { FractionString } from "../../../../general/types/score"
import {
    PlayerState,
    playerActions,
    playerQuery,
    playerStore,
} from "../../../general/classes/playerStateManagement"

const promiseLimit = require("promise-limit")
const loadInMemoryLimit = promiseLimit(1)

export enum RTSamplerActionTypes {
    start = "start",
    end = "end",
    play = "play",
    pause = "pause",
    setVolumeControl = "setVolumeControl",
    setTime = "setTime",
    setTrackBusLoading = "setTrackBusLoading",
    loadTrackBusses = "loadTrackBusses",
    liveEditEverythingExceptNotes = "liveEditEverythingExceptNotes",
    liveAutomation = "liveAutomation",
    liveEditNotes = "liveEditNotes",
    pauseNotesByIDs = "pauseNotesByIDs",
    playNotePreview = "playNotePreview",
    clearPreviousNotePreviews = "clearPreviousNotePreviews",
    playNoteSelectionPreviews = "playNoteSelectionPreviews",
    updateScore = "updateScore",
    unloadTrackBusses = "unloadTrackBusses",
    pauseTrackBusses = "pauseTrackBusses",
    loadAudioSamplesInMemory = "loadAudioSamplesInMemory",
}

export class RealtimeSamplerActions {
    // In seconds, this value represents how much time before the timeElapsed the composition will start playing, in audio refresh rate cycles
    private readonly PLAYTIME_BUFFER = 10

    // this is the starting time in number of milliseconds since unix epoch time
    private startEpoch: number = 0

    // this is the starting time in seconds, and relative to the composition time
    private startTime: number = 0

    private previousTimeSlice: number | undefined = 0
    private previousFraction: FractionString | undefined = undefined
    private previousUITimeSlice: number | undefined = 0
    private playingLoop: boolean = false

    private previousNotePreviews: {
        // the value represents the last time the note was played, in milliseconds since unix epoch
        [pitchAndTrackBusID: string]: number
    } = {}

    private samplerAPI: RealtimeSamplerAPI | undefined = window["electron"]
        ? window["electron"].samplerAPI
        : undefined

    private readonly actionTypeToMethodMap: {
        [key: string]: (...args) => any
    } = {
        [RTSamplerActionTypes.loadTrackBusses]: this.loadTrackBusses.bind(this),
        [RTSamplerActionTypes.play]: this.play.bind(this),
        [RTSamplerActionTypes.pause]: this.pause.bind(this),
        [RTSamplerActionTypes.setVolumeControl]:
            this.setVolumeControl.bind(this),
        [RTSamplerActionTypes.setTime]: this.setTime.bind(this),
        [RTSamplerActionTypes.start]: this.start.bind(this),
        [RTSamplerActionTypes.end]: this.end.bind(this),
        [RTSamplerActionTypes.liveAutomation]: this.liveAutomation.bind(this),
        [RTSamplerActionTypes.liveEditNotes]: this.liveEditNotes.bind(this),
        [RTSamplerActionTypes.playNotePreview]: this.playNotePreview.bind(this),
        [RTSamplerActionTypes.clearPreviousNotePreviews]:
            this.clearPreviousNotePreviews.bind(this),
        [RTSamplerActionTypes.liveEditEverythingExceptNotes]:
            this.liveEditEverythingExceptNotes.bind(this),
        [RTSamplerActionTypes.playNoteSelectionPreviews]:
            this.playNoteSelectionPreviews.bind(this),
        [RTSamplerActionTypes.setTrackBusLoading]:
            this.setTrackBusLoading.bind(this),
        [RTSamplerActionTypes.unloadTrackBusses]:
            this.unloadTrackBusses.bind(this),
        [RTSamplerActionTypes.pauseTrackBusses]:
            this.pauseTrackBusses.bind(this),

        [RTSamplerActionTypes.loadAudioSamplesInMemory]:
            this.loadAudioSamplesInMemory.bind(this),

        [RTSamplerActionTypes.pauseNotesByIDs]: this.pauseNotesByIDs.bind(this),
    }

    public readonly manager: ActionsManager<
        RTSamplerActionTypes,
        PlayerState,
        PlayerState
    >

    public get emitter$() {
        return this.manager.emitter$
    }

    constructor(
        private query: ScoreRenderingQuery,
        private store: ScoreRenderingStore
    ) {
        this.manager = new ActionsManager(
            playerStore,
            this.actionTypeToMethodMap,
            {
                pipePrefix: this.filterActions.bind(this),
            }
        )
    }

    private filterActions(subject: Subject<any>) {
        return subject.pipe(
            filter((action: EmitterType<RTSamplerActionTypes>) => {
                if (this.samplerAPI !== undefined) {
                    return true
                }

                if (action.resolve) {
                    action.resolve()
                }

                return false
            })
        )
    }

    private pauseNotesByIDs({ noteIDs }: { noteIDs: string[] }) {
        return this.samplerAPI.pauseNotesByIDs({
            noteIDs,
        })
    }

    private async unloadTrackBusses({ tbs }: { tbs: TrackBus[] }) {
        for (const tb of tbs) {
            const currentPatch: Patch = new Patch(
                tb.name.split(".")[0],
                tb.name.split(".")[1],
                tb.name.split(".")[2],
                tb.name.split(".")[3],
                ""
            )
            const otherPatches: Patch[] = []

            this.query.score.trackBusses.forEach(t => {
                const patch = new Patch(
                    t.name.split(".")[0],
                    t.name.split(".")[1],
                    t.name.split(".")[2],
                    t.name.split(".")[3],
                    ""
                )

                if (patch.sameInstrument(currentPatch)) {
                    otherPatches.push(patch)
                }
            })

            await this.samplerAPI.clearPatchFromMemory(<
                ClearPatchFromMemoryRequest
            >{
                patchToUnload: currentPatch,
                otherPatches,
            })
        }
    }

    private pauseTrackBusses({ tbs }: { tbs: TrackBus[] }) {
        return this.samplerAPI.stopPlayingTrackBusses(tbs.map(tb => tb.id))
    }

    private async start({
        settings,
        userID,
        instruments,
    }: {
        settings: string
        userID: string
        instruments: InstrumentsJSON
    }) {
        await this.end({
            origin: "start",
        })

        this.setTrackBusLoading({
            value: 0,
        })

        if (!this.contentIDsMatch("start 1")) {
            return
        }

        await this.samplerAPI.startSampler({ settings, userID })

        if (!this.contentIDsMatch("start 2")) {
            return
        }

        await this.loadTrackBusses({
            instruments,
        })
    }

    private async end({ origin }: { origin: string }) {
        try {
            await this.pause({ origin: "end" })

            return this.samplerAPI.stopSampler()
        } catch (e) {
            console.error(e)
        }
    }

    private async play() {
        await this.clearPreviousNotePreviews()

        this.startTime =
            (this.getTimeSlice(playerQuery.timeElapsed) -
                this.PLAYTIME_BUFFER * 2) /
            AUDIO_REFRESH_RATE

        this.startEpoch = Date.now()

        await this.pause({ origin: "play" })

        let kicksObject = {}

        for (const layer in this.query.score.layers) {
            const layerObj = this.query.score.layers[layer]

            if (layerObj.type !== "percussion") {
                continue
            }

            for (const pr of (layerObj as PercussionLayer).patternRegions) {
                kicksObject = pr.getKicks(kicksObject)
            }
        }

        const kicks = Object.keys(kicksObject)
            .sort((a, b) => {
                if (Time.compareTwoFractions(a, b) === "gt") {
                    return 1
                }

                if (Time.compareTwoFractions(a, b) === "lt") {
                    return -1
                }

                return -1
            })
            .map(k => {
                return Time.fractionToSeconds(
                    TIMESTEP_RES,
                    this.query.score.tempoMap,
                    k
                )
            })

        await this.samplerAPI.startRealTimeContext({
            startTime: this.startTime,
            kicks: kicks,
        })

        playerActions.setStatus("RealtimeSamplerActions.play()", "playing")

        this.playingLoop = true

        await this.playingLoopFunction()
    }

    private async liveEditEverythingExceptNotes() {
        const template = ScoreDecoding.toTemplateScore({
            score: this.query.score,
            realTimeSampler: true,
            timeSlice: "0",
            alwaysIncludeEmptyTrackBusses: true,
        }).templateScore

        const data: PlayNotesResponse = await this.samplerAPI.playNotes({
            score: template,
            layer: this.query.toggledLayer
                ? this.query.toggledLayer.value
                : undefined,
            offset: 0,
            volumeLevel: playerQuery.volumeLevel,
        })

        if (this.query.getValue().levelsMeasurement === "layer") {
            await this.updateLayersVolume()
        } else if (
            this.query.getValue().levelsMeasurement === "trackbus" &&
            this.query.toggledLayer !== undefined
        ) {
            this.updateTrackVolume(
                data,
                this.query.toggledLayer.trackBuses,
                true
            )
        }
    }

    private async sendPlayNotesToSampler(fraction: FractionString) {
        const template = ScoreDecoding.toTemplateScore({
            score: this.query.score,
            realTimeSampler: true,
            timeSlice: fraction,
        }).templateScore

        if (template.tracks.length > 0) {
            const data: PlayNotesResponse = await this.samplerAPI.playNotes({
                score: template,
                layer: this.query.toggledLayer
                    ? this.query.toggledLayer.value
                    : undefined,
                offset: 0,
                volumeLevel: playerQuery.volumeLevel,
            })

            this.updateTrackVolume(
                data,
                this.query.toggledLayer?.trackBuses,
                true
            )

            return true
        }

        return false
    }

    private getTimeSlice(time: number) {
        return Math.floor(time * AUDIO_REFRESH_RATE)
    }

    private async playingLoopFunction() {
        if (!this.playingLoop) {
            return
        }

        try {
            const currentTimeSlice =
                this.getTimeSlice(this.currentTime) + this.PLAYTIME_BUFFER

            playerActions.setTimeElapsed(
                "RealtimeSamplerActions.playingLoopFunction()",
                this.currentTime
            )

            let updatedTrackBusLevels = false

            const timers = {
                sendPlayNotesToSampler: performance.now(),
                getTrackBussesLevels: 0,
                total: 0,
            }

            if (
                this.previousTimeSlice === undefined ||
                currentTimeSlice > this.previousTimeSlice
            ) {
                this.previousTimeSlice = currentTimeSlice

                const fraction = Time.quantizeFractionToString(
                    Time.secondsToFraction(
                        currentTimeSlice / AUDIO_REFRESH_RATE,
                        false,
                        TIMESTEP_RES,
                        this.query.score.tempoMap
                    ),
                    "floor",
                    TIMESTEP_RES
                )

                if (
                    this.previousFraction === undefined ||
                    Time.compareTwoFractions(
                        fraction,
                        this.previousFraction
                    ) !== "eq"
                ) {
                    this.previousFraction = fraction

                    updatedTrackBusLevels = await this.sendPlayNotesToSampler(
                        fraction
                    )
                }
            }

            timers.sendPlayNotesToSampler =
                performance.now() - timers.sendPlayNotesToSampler
            timers.getTrackBussesLevels = performance.now()

            // Do not await for updateTrackBusVolume and updateLayersVolume to complete here
            // because we dont want to block sending notes to the sampler in case those
            // functions take too long to run
            if (
                this.query.getValue().levelsMeasurement === "trackbus" &&
                !updatedTrackBusLevels
            ) {
                this.updateTrackBusVolume()
            } else if (this.query.getValue().levelsMeasurement === "layer") {
                this.updateLayersVolume()
            }

            timers.getTrackBussesLevels =
                performance.now() - timers.getTrackBussesLevels

            timers.total =
                timers.sendPlayNotesToSampler + timers.getTrackBussesLevels

            if (timers.total >= (1 / AUDIO_REFRESH_RATE) * 1000) {
                console.warn(
                    "RealtimeSamplerActions.playingLoopFunction() is taking too long to run. Timers: ",
                    timers
                )
            }

            if (
                !playerQuery.loopType &&
                this.currentTime > Math.ceil(playerQuery.duration) + 0.5
            ) {
                await this.pause({ origin: "playingLoopFunction" })

                return this.setTime({ seconds: 0 })
            } else if (
                playerQuery.loopType === "score" &&
                this.currentTime >= Math.ceil(playerQuery.duration)
            ) {
                await this.setTime({ seconds: 0 })
            }
        } catch (e) {
            console.error(e)

            playerActions.setPlaybackError({
                origin: "playingLoopFunction",
                error: e,
            })
        }

        window["requestAnimFrame"](this.playingLoopFunction.bind(this))
    }

    private async updateLayersVolume() {
        const uiTimeSlice = Math.floor(this.currentTime * VIDEO_REFRESH_RATE)

        if (
            this.previousUITimeSlice !== undefined &&
            uiTimeSlice <= this.previousUITimeSlice
        ) {
            return
        }

        this.previousUITimeSlice = uiTimeSlice

        const keys = Object.keys(this.query.score.layers)

        for (let k = 0; k < keys.length; k++) {
            const layer = keys[k]
            const layerObj = this.query.score.layers[layer]

            const data = await this.samplerAPI.getTrackBussesLevels(layer)

            this.updateTrackVolume(
                data,
                layerObj.trackBuses,
                k === keys.length - 1
            )
        }
    }

    private async updateTrackBusVolume() {
        if (this.query.toggledLayer === undefined) {
            return
        }

        const uiTimeSlice = Math.floor(this.currentTime * VIDEO_REFRESH_RATE)

        if (
            this.previousUITimeSlice === undefined ||
            uiTimeSlice > this.previousUITimeSlice
        ) {
            this.previousUITimeSlice = uiTimeSlice

            const data = await this.samplerAPI.getTrackBussesLevels(
                this.query.toggledLayer.value
            )

            this.updateTrackVolume(
                data,
                this.query.toggledLayer.trackBuses,
                true
            )
        }
    }

    private async playNoteSelectionPreviews({
        selectedNotes,
        previousNotes,
    }: {
        selectedNotes: NotesObject
        previousNotes: NotesObject
    }) {
        if (this.playingLoop) {
            return
        }

        const difference = ScoreManipulation.getNoteDifference({
            previousNotes: previousNotes,
            selectedNotes: selectedNotes,
            timeSignature: this.query.score.firstTimeSignature,
            sections: this.query.score.sections,
        })

        const uniquePitches = {}

        difference.forEach(n => {
            for (const tb of this.query.toggledLayer.trackBuses) {
                const start = Time.fractionToTimesteps(TIMESTEP_RES, n.start)

                const shouldContinue =
                    !tb.isPlayedAt(start) ||
                    uniquePitches[tb.id + "_" + n.pitch] !== undefined

                if (shouldContinue) {
                    continue
                }

                this.playNotePreview({
                    pitch: n.pitch,
                    clearPreviews: false,
                    absoluteNoteStart: n.start,
                    stepSequencerTrackBus: undefined,
                    alwaysPlay: false,
                })

                uniquePitches[tb.id + "_" + n.pitch] = true
            }
        })
    }

    private async playNotePreview({
        pitch: pitch,
        clearPreviews,
        absoluteNoteStart,
        stepSequencerTrackBus,
        alwaysPlay,
    }: {
        pitch: number
        clearPreviews: boolean
        absoluteNoteStart: FractionString
        stepSequencerTrackBus: TrackBus | undefined
        alwaysPlay: boolean
    }) {
        if (clearPreviews) {
            await this.clearPreviousNotePreviews()
        }

        const layer = this.query.toggledLayer

        if (layer === undefined) {
            return
        }

        const startTimesteps = Time.fractionToTimesteps(
            TIMESTEP_RES,
            absoluteNoteStart
        )

        const soloActivated = this.query.score.trackBusses.some(t => t.solo)

        const fraction = "1/8"

        const filteredTBs = layer.trackBuses.filter(tb => {
            const now = Date.now()
            const lastPlayedThreshold =
                now -
                Time.fractionToSeconds(
                    TIMESTEP_RES,
                    this.query.score.tempoMap,
                    Time.multiplyFractionWithNumber(fraction, 4)
                ) *
                    1000

            const tbIsPlayable =
                !tb.loading &&
                !tb.mute &&
                (!soloActivated || tb.solo) &&
                (this.previousNotePreviews[tb.id + "_" + pitch] === undefined ||
                    this.previousNotePreviews[tb.id + "_" + pitch] <
                        lastPlayedThreshold)
            const noteIsPlayable =
                alwaysPlay ||
                tb.isPlayedAt(startTimesteps) ||
                stepSequencerTrackBus?.id === tb.id

            const play = tbIsPlayable && noteIsPlayable

            if (play && layer.type !== "percussion") {
                this.previousNotePreviews[tb.id + "_" + pitch] = now
            }

            return play
        })

        const score: TemplateScore = this.query.score.buildDecodedTemplate(true)

        const note = new Note({
            pitch: pitch,
            start: absoluteNoteStart,
            duration: fraction,
            meta: {
                layer: layer.value,
                section: Note.getSectionForNoteStart(
                    this.query.score.sections,
                    absoluteNoteStart
                ),
                phrase: 0,
            },
        })

        const decodedNote = Note.decode(layer, note)
        decodedNote.start = "0"

        score.tracks = filteredTBs.map(tb => {
            const track = tb.decode(0, layer.value)
            track.track.push(decodedNote)

            return track
        })

        score["preprocessedTempoMap"] = this.query.score.tempoMap.map(t => {
            return {
                fraction: t.fraction,
                bpm: t.bpm,
                seconds: t.seconds,
                timesteps: t.timesteps,
            }
        })

        this.samplerAPI.playNotePreview(score)
    }

    private clearPreviousNotePreviews() {
        this.previousNotePreviews = {}

        return this.samplerAPI.clearPreviousNotePreviews()
    }

    private async pause({ origin }: { origin: string }) {
        this.startTime = this.currentTime
        this.playingLoop = false
        this.previousTimeSlice = undefined
        this.previousUITimeSlice = undefined
        this.previousFraction = undefined

        await Misc.promiseWithTimeout(this.samplerAPI.pause(), 4)

        if (playerQuery.playbackType === "realtime") {
            playerActions.setStatus("RealtimeSamplerActions.pause()", "paused")
        }

        this.resetTrackbusVolumes()
    }

    private setVolumeControl({ volume }: { volume: number }) {
        this.samplerAPI.setGlobalVolumeControl({
            volumeLevel: volume,
        })
    }

    private async setTime({ seconds }: { seconds: number }) {
        const status = playerQuery.status

        await this.pause({ origin: "setTime" })

        playerActions.setTimeElapsed(
            "RealtimeSamplerActions.setTime()",
            seconds
        )

        if (status === "playing") {
            await this.play()
        }
    }

    /**
     * Gets the current time in seconds, relative to the composition time.
     * E.g. if the playback started at 10 seconds, since 5 seconds ago, the currentTime
     * will be 15 seconds
     */
    private get currentTime(): number {
        const currentTime = (Date.now() - this.startEpoch) / 1000

        return currentTime + this.startTime
    }

    private async loadTrackBusses({
        tbs,
        instruments,
        retries = 4,
    }: {
        tbs?: TrackBus[]
        instruments: InstrumentsJSON
        retries?: number
    }) {
        try {
            if (tbs === undefined) {
                tbs = this.query.score.trackBusses
            }

            this.setTrackBussesAsLoading({
                tbs: tbs,
                isLoading: true,
            })

            this.setTrackBusLoading({ value: 10 })

            await this.downloadTrackbusses(tbs, instruments)

            this.setTrackBusLoading({ value: 50 })

            const loadIncrement = Math.floor((100 - 50) / tbs.length)

            const layers = ScoreManipulation.getLayersForTrackBusses({
                tbs,
                score: this.query.score,
            })
            const promises = []

            for (const layer of layers.keys()) {
                const value = layers.get(layer)
                promises.push(
                    loadInMemoryLimit(() =>
                        this.loadAudioSamplesInMemory({
                            layer,
                            tbs: value,
                            loadIncrementPerTrackBus: loadIncrement,
                            showLoading: true,
                        })
                    )
                )
            }

            await Promise.all(promises)

            this.setTrackBusLoading({ value: 100 })
        } catch (e) {
            console.error(e)
            retries = retries - 1

            if (retries > 0) {
                await Misc.wait(Math.pow(2, 4 - retries))

                return this.loadTrackBusses({
                    tbs,
                    instruments,
                    retries,
                })
            }

            throw "An error occurred while setting up the instrument sampler"
        }
    }

    private async loadAudioSamplesInMemory({
        layer,
        tbs,
        loadIncrementPerTrackBus,
        showLoading,
        callback,
    }: {
        layer: PercussionLayer | Layer
        tbs: TrackBus[]
        loadIncrementPerTrackBus: number
        showLoading: boolean
        callback?: Function
    }) {
        if (
            !this.contentIDsMatch("loadAudioSamplesInMemory") ||
            this.samplerAPI === undefined
        ) {
            if (callback) {
                callback()
            }

            return
        }

        const result = ScoreDecoding.toTemplateScore({
            score: this.query.score,
            realTimeSampler: true,
            layersToSelect: [layer],
            trackBussesToSelect: tbs,
            ignoreLoadingTrackbusses: false,
            alwaysIncludeEmptyTrackBusses: true,
        })

        const args = {
            sentAt: Date.now(),
            score: result.templateScore,
            id: layer.value,
        }

        if (showLoading) {
            this.setTrackBussesAsLoading({
                tbs: tbs,
                isLoading: true,
            })
        }

        await this.samplerAPI.loadAudioSamplesInMemory(args)

        if (showLoading) {
            const loading = playerQuery.getValue().trackBusLoadingPercentage

            this.setTrackBusLoading({
                value: loading + loadIncrementPerTrackBus * tbs.length,
            })

            this.setTrackBussesAsLoading({
                tbs: tbs,
                isLoading: false,
            })
        }

        if (callback) {
            callback()
        }
    }

    private async downloadTrackbusses(
        tbs: TrackBus[],
        instruments: InstrumentsJSON
    ) {
        const patches = tbs.map(trackBus => {
            return ScoreManipulation.createPatch(trackBus, instruments)
        })

        const filteredTrackBusses = tbs.filter(
            t =>
                t.name.split(".")[1] === "silent-kit" ||
                t.name.split(".")[1] === "silent-instrument"
        )

        const patchesAlreadyRequested = {}
        const promises = []

        const downloadIncrement = Math.floor((50 - 10) / patches.length)

        // downloads the patches onto disk
        for (const patch of patches) {
            if (patchesAlreadyRequested[patch.getFullName()] == null) {
                patchesAlreadyRequested[patch.getFullName()] = 1

                promises.push(
                    this.requestPatch({
                        patch,
                        tbs: filteredTrackBusses,
                        downloadIncrement,
                    })
                )
            }
        }

        const results = await Promise.all(promises)

        const error = results.find(
            obj => obj != null && obj["level"] != null && obj["level"]
        ) // check if any of the Promises returned an error

        // an error occurred during any of the multiple requestPatch
        if (error != null) {
            throw error
        }
    }

    private async liveAutomation({
        timestepRange,
        layer,
    }: {
        timestepRange: [number, number]
        layer: Layer
    }) {
        if (!this.playingLoop) {
            return
        }

        const secondsRange = [
            Time.convertTimestepsInSeconds(
                TIMESTEP_RES,
                this.query.score.tempoMap,
                timestepRange[0],
                false
            ),
            Time.convertTimestepsInSeconds(
                TIMESTEP_RES,
                this.query.score.tempoMap,
                timestepRange[1],
                false
            ),
        ]

        if (playerQuery.timeElapsed > secondsRange[1]) {
            return
        }

        const effect: Effect = this.query.selectedAutomation
        const tempoMap = this.query.score.tempoMap
        const currentTimestep = Math.round(
            (Time.convertSecondsInTimesteps(
                playerQuery.timeElapsed,
                false,
                TIMESTEP_RES,
                tempoMap,
                "liveUpdatePlugin"
            ) *
                AUTOMATION_TIMESTEP_RES) /
                TIMESTEP_RES
        )

        if (effect.name == "dynamic") {
            return this.liveEditNotes({
                selectedNotes: layer.notesObject,
                layer,
                getSelectedNoteGroups: true,
                previewWhenPaused: false,
                origin: "liveAutomation",
            })
        }

        var controllers: Array<Controller> = []

        for (
            let d = Math.max(currentTimestep, timestepRange[0]);
            d <= Math.min(effect.values.length, timestepRange[1]);
            d++
        ) {
            const time =
                Time.convertTimestepsInSeconds(
                    TIMESTEP_RES,
                    tempoMap,
                    (d * TIMESTEP_RES) / AUTOMATION_TIMESTEP_RES,
                    false
                ) - this.startTime
            const value = EffectPlugin.convertValue(
                effect.name,
                effect.values[d]
            )

            controllers.push(new Controller(time, value))
        }

        const parameters = {}

        if (effect.name == EffectPlugin.TYPE_REVERB) {
            parameters["ir"] = effect["ir"]
        } else if (effect.name == EffectPlugin.TYPE_DELAY) {
            parameters["leftDelay"] = effect["left"]["delay_time"]
            parameters["rightDelay"] = effect["right"]["delay_time"]
        }

        const effectPlugin = new EffectPlugin(
            effect.name,
            "-1",
            layer.value,
            controllers,
            parameters
        )

        await this.samplerAPI.liveEditEffectPlugin(effectPlugin)
    }

    private liveEditNotes({
        selectedNotes,
        layer,
        getSelectedNoteGroups,
        previewWhenPaused,
        selectedNoteID,
        origin,
    }: {
        selectedNotes: NotesObject
        layer: Layer
        getSelectedNoteGroups: boolean
        previewWhenPaused: boolean
        selectedNoteID?: string
        origin: string
    }) {
        if (!this.playingLoop) {
            if (previewWhenPaused && selectedNoteID !== undefined) {
                const noteGroup =
                    selectedNotes.getNoteByIDWithoutNoteStart(
                        selectedNoteID
                    ).noteGroup

                if (noteGroup === undefined) {
                    return
                }

                return Promise.all(
                    noteGroup.map(n => {
                        return this.playNotePreview({
                            pitch: n.pitch,
                            clearPreviews: true,
                            absoluteNoteStart: n.start,
                            stepSequencerTrackBus: undefined,
                            alwaysPlay: false,
                        })
                    })
                )
            }

            return
        }

        const notes: NotesObject = ScoreManipulation.getSelectedNoteGroups({
            selectedNotes,
            layer,
            timeElapsed: playerQuery.timeElapsed,
            getSelectedNoteGroups,
            score: this.query.score,
        })

        if (notes.length === 0) {
            return
        }

        const score: TemplateScore = this.query.score.buildDecodedTemplate(true)

        notes.manipulateNoteGroups((noteGroup: Note[]) => {
            const start: any = noteGroup[0].start
            const end: any = Time.addTwoFractions(start, noteGroup[0].duration)

            const result = layer.notesObject.getNoteGroupAndSurroundings(start)

            const hasAdjacentBefore =
                result.previousNoteGroup !== undefined &&
                Time.compareTwoFractions(
                    Time.addTwoFractions(
                        result.previousNoteGroup[0].start,
                        result.previousNoteGroup[0].duration
                    ),
                    start
                ) === "eq"

            const hasAdjacentAfter =
                result.nextNoteGroup !== undefined &&
                Time.compareTwoFractions(end, result.nextNoteGroup[0].start) ===
                    "eq"

            const note: TemplateNote = Note.decodeNoteGroup(
                layer,
                noteGroup,
                true
            )

            const previousNote = hasAdjacentBefore
                ? Note.decodeNoteGroup(layer, result.previousNoteGroup, false)
                : undefined
            const nextNote = hasAdjacentAfter
                ? Note.decodeNoteGroup(layer, result.nextNoteGroup, false)
                : undefined

            for (const tb of layer.trackBuses) {
                const playedAt = !tb.isPlayedAt(
                    Time.fractionToTimesteps(TIMESTEP_RES, start)
                )

                if (playedAt) {
                    continue
                }

                const track = tb.decode(0, layer.value)

                if (previousNote) {
                    track.track.push(previousNote)
                }

                track.track.push(note)

                if (nextNote) {
                    track.track.push(nextNote)
                }

                score.tracks.push(track)
            }

            return true
        })

        return this.samplerAPI.liveEditNotes({
            score: score,
        })
    }

    private async requestPatch({
        patch,
        tbs,
        downloadIncrement,
    }: {
        patch: Patch
        tbs: TrackBus[]
        downloadIncrement: number
    }) {
        try {
            const res = await this.samplerAPI.requestPatch({
                usedInstruments: tbs,
                patch: patch,
            })

            const loading = playerQuery.getValue().trackBusLoadingPercentage

            this.setTrackBusLoading({ value: loading + downloadIncrement })

            return res
        } catch (err) {
            console.log(err)
        }
    }

    private contentIDsMatch(origin: string) {
        console.assert(
            playerQuery.contentID === this.query.score.compositionID,
            "Content ID dont match. Origin: ",
            origin
        )

        return playerQuery.contentID === this.query.score.compositionID
    }

    private setTrackBusLoading({ value }: { value: number }) {
        if (
            !this.contentIDsMatch(
                "setTrackBusLoading: " +
                    value +
                    "% contentID: " +
                    playerQuery.contentID
            )
        ) {
            return
        }

        playerActions.setTrackBusLoadingPercentage(value)
    }

    private setTrackBussesAsLoading({
        tbs,
        isLoading,
    }: {
        tbs: TrackBus[]
        isLoading: boolean
    }) {
        tbs.forEach(tb => {
            tb.loading = isLoading
        })

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["TrackBusMetadata"],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private updateTrackVolume(
        data: PlayNotesResponse,
        trackBusses: TrackBus[],
        sendUpdate: boolean
    ) {
        for (const loudness of data.loudnesses) {
            const track = trackBusses.find(t => t.id === loudness.id)

            if (track === undefined) {
                continue
            }

            if (loudness.left != null && loudness.right != null) {
                const left = 10 * Math.log10(loudness.left)
                const right = 10 * Math.log10(loudness.right)

                track.volume = {
                    left: left,
                    right: right,
                    stereo: (left + right) / 2,
                }
            }
        }

        if (sendUpdate) {
            this.store.updateStore({
                partial: {
                    renderingType: ["None"],
                    scoreUpdate: ["TrackBusMetadata"],
                },
                scoreWasEdited: false,
                updateScoreLength: false,
            })
        }
    }

    private resetTrackbusVolumes() {
        if (
            this.query.score === undefined ||
            this.query.score.trackBusses === undefined
        ) {
            return
        }

        const tracks = this.query.score.trackBusses

        for (const track of tracks) {
            track.volume = {
                left: -Infinity,
                right: -Infinity,
                stereo: -Infinity,
            }
        }

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["TrackBusMetadata"],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }
}
