export default class GPLibraryFilters {
    type = "category"

    similarity = {
        min: 0,
        max: 99
    }

    styles:Array<string> = []
    createdBy:Array<string> = []
    admin:Array<string> = []

    constructor(type) {
        this.type = type
    }
}