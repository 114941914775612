<div class="modal-title">Filters</div>
<div class="modal-close"><img src="assets/img/close.svg" (click)="closeModal()"></div>

<div class="filter-columns" *ngIf="filters.type == 'category'">
    <div class="filter-column">
        <div class="gp-library-filters-subtitle">Style</div>

        <div *ngFor="let style of getAllStyles()" class="style-filter-input">
            <radio-button [class]="'gp-library-filters'" [type]="'checkbox'" [id]="style" [value]="style" [label]="style" (onSelect)="changeSelectedFilters($event, 'styles')" [selectedValue]="isSelectedFilter(style, 'style')"></radio-button>
        </div>
    </div>

    <div class="filter-column">
        <div class="gp-library-filters-subtitle" *ngIf="isAIVAAccount()">Admin filters</div>
        <div class="style-filter-input">
            <radio-button *ngIf="isAIVAAccount()" [class]="'gp-library-filters'" [type]="'checkbox'" [id]="'published'" [value]="'published'" [label]="'published'" (onSelect)="changeSelectedFilters($event, 'admin')" [selectedValue]="isSelectedFilter('published', 'admin')"></radio-button>
        </div>

        <div class="gp-library-filters-subtitle">Created by</div>

        <div class="style-filter-input">
            <radio-button [class]="'gp-library-filters'" [type]="'checkbox'" [id]="'AIVA'" [value]="'AIVA'" [label]="'AIVA'" (onSelect)="changeSelectedFilters($event, 'createdBy')" [selectedValue]="isSelectedFilter(style, 'createdBy')"></radio-button>
        </div>

        <div class="style-filter-input">
            <radio-button [class]="'gp-library-filters'" [type]="'checkbox'" [id]="'Other users'" [value]="'Other users'" [label]="'Other users'" (onSelect)="changeSelectedFilters($event, 'createdBy')" [selectedValue]="isSelectedFilter(style, 'createdBy')"></radio-button>
        </div>
    </div>
</div>

<div class="filter-columns" *ngIf="filters.type == 'gp'">
    <div class="settings-row">
        <div class="settings-row-title">
            Similarity range
        </div>
        <div class="settings-row-value">
            <range (valueChanges)="changedSimilarityRange($event)" [class]="'light'" [type]="'number'" [minLimit]="0" [maxLimit]="99" [min]="filters.similarity.min" [max]="filters.similarity.max" [minDifference]="0"></range>
        </div>
    </div>
</div>