import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core"
import { Misc } from "@common-lib/modules/misc"

@Component({
    selector: "like-btn",
    templateUrl: "like-btn.component.html",
    styleUrls: ["like-btn.component.scss"],
})
export class LikeBtnComponent {
    @Input() color: string
    @Input() rotate: boolean = false
    @Input() shouldAnimate: boolean = false
    @Input() size: "small" | "medium" | "large" = "medium"
    @Input() avData: string

    @Input()
    public set dissapearAnimation(value: boolean) {
        if (!value) return
        this._disappearAnimation = value
        this.dissapear()
    }

    public get dissapearAnimation(): boolean {
        return this._disappearAnimation
    }

    private _disappearAnimation: boolean = false

    @Output() onClick: EventEmitter<void> = new EventEmitter<void>()

    public handleClick(): void {
        if (this._disappearAnimation) return
        this.onClick.emit()
    }

    private async dissapear(): Promise<void> {
        this._disappearAnimation = true
        await Misc.wait(0.5)
        this._disappearAnimation = false
        this.onClick.emit()
    }
}
