<div class="playback-buttons">
    <div
        class="playback-button-mute"
        (click)="mute(trackBusses)"
        [ngClass]="{ 'mute-selected': trackBusses[0]?.mute }"
        tooltip="Mute"
        *ngIf="!configs?.hideMute">
        M
    </div>

    <div
        class="playback-button-solo"
        (click)="solo(trackBusses)"
        [ngClass]="{ 'solo-selected': trackBusses[0]?.solo }"
        tooltip="Solo"
        *ngIf="!configs?.hideSolo">
        S
    </div>
</div>
