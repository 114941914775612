import { Injectable } from "@angular/core"
import { ApiService } from "@services/api.service"
import {
    CWCreateComposition,
    CWGenerateLayerBody,
    CWGetChordProgressionRequest,
    CWSaveCompositionWorkflow,
} from "../../../../../common-lib/general/interfaces/api/composition-workflow.api"
import {
    CompositionWorkflowNote,
    CompositionWorkflowSchema,
    CWKeyMode,
} from "@common-lib/interfaces/composition-workflow.interface"
import { TemplateChord } from "@common-lib/interfaces/score/templateScore"
import { KeySignatureModule } from "@common-lib/modules/keysignature.module"
import { CompositionWorkflowType } from "@common-lib/types/general"
import { KeySignature } from "@common-lib/interfaces/score/keySignature"
import { DropdownItemType } from "../../types/dropdownItemType"
import { Misc } from "@common-lib/modules/misc"
import { CWState } from "@services/composition-workflow/cw-store/cw.store"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import { LayerType } from "@common-lib/constants/constants"
import { featureFlags } from "@common-lib/utils/feature-flags"
import {
    SegmentedScoreInstrument,
    SegmentedScoreLayer,
    SegmentedScoreNote,
    SegmentedScoreSection,
} from "@common-lib/interfaces/score/segmentedscore"
import { InstrumentsJSON } from "@common-lib/interfaces/score/general"
import { SegmentedScoreManipulationModule } from "@common-lib/modules/score-transformers/segmented-score-manipulation.module"

type IChordProgressionResponse = {
    res: string[][]
}

@Injectable()
export class CompositionWorkflowHTTPService {
    constructor(private http: ApiService) {}

    public async getModelLists(): Promise<DropdownItemType<string>[]> {
        const res = await this.http.authRequest(
            "/getPretrainedModels/harmony",
            {},
            "primary",
            true,
            "get"
        )

        return res.models
    }

    public async rateGeneration(
        rating: "liked" | "disliked",
        harmonyPromptId: string
    ): Promise<void> {
        console.log({ harmonyPromptId, rating })
        await this.http.authRequest(
            "/compositionWorkflow/rateGeneration",
            {
                rating,
                harmonyPromptId,
            },
            "primary",
            true,
            "post"
        )
    }

    public async getSavedCWs(type: CompositionWorkflowType): Promise<
        {
            _id: string
            name: string
            numerals: TemplateChord[]
        }[]
    > {
        const res = await this.http.authRequest(
            "/compositionWorkflow/getSavedCWs",
            {
                type,
            },
            "primary",
            true
        )

        return res.compositionWorkflows
    }

    public async renameCW(cwID: string, newName: string): Promise<boolean> {
        try {
            await this.http.authRequest(
                "/compositionWorkflow/renameCW",
                {
                    cwID,
                    newName,
                },
                "primary",
                true
            )

            return true
        } catch (e) {
            this.http.handleError(e)

            return false
        }
    }

    public async deleteCW(cwID: string): Promise<boolean> {
        try {
            await this.http.authRequest(
                "/compositionWorkflow/deleteCW",
                {
                    cwID,
                },
                "primary",
                true
            )

            return true
        } catch (e) {
            this.http.handleError(e)

            return false
        }
    }

    public async getCWByID(cwID: string): Promise<CompositionWorkflowSchema> {
        const res = await this.http.authRequest(
            "/compositionWorkflow/getByID",
            {
                cwID,
            },
            "primary",
            true
        )

        return res.compositionWorkflow
    }

    public async getGeneratedChordProgression(
        request: CWGetChordProgressionRequest
    ): Promise<{
        chordProgression: TemplateChord[]
        keySignature: KeySignature
        harmonyPromptId: string | undefined
    }> {
        let { pitchClass, keyMode } = request.keySignature

        if (keyMode !== "major" && keyMode !== "minor") {
            request.harmonyPackID = "diatonic_1_0"
        }

        const res = await this.http.authRequest(
            "/compositionWorkflow/getChordProgression",
            {
                harmonyPackId: request.harmonyPackID,
                keySignature: request.keySignature.pitchClass + " " + keyMode,
                timeSignature: request.timeSignature,
                tempo: request.tempo,
                prompt: request.prompt,
                toggleLocalLLM: request.toggleLocalLLM,
            },
            "primary",
            true
        )

        if (res.result === 0) {
            throw Error(res.message)
        }

        return {
            chordProgression: res.chordProgression,
            keySignature: res.keySignature,
            harmonyPromptId: res.harmonyPromptId,
        }
    }

    public async generateLayer(
        state: CWState,
        notes: CompositionWorkflowNote[],
        packID: string,
        lowestNote: number,
        instrument: InstrumentPatch,
        layer: string,
        instruments: InstrumentsJSON
    ): Promise<CompositionWorkflowNote[]> {
        function articulationToPatchType(art: string) {
            let patchType: "long" | "short" | "legato" = "long"

            if (art === "legato") {
                patchType = "legato"
            }

            if (art.includes("stac")) {
                patchType = "short"
            }

            return patchType
        }

        function articulationToCompressedPatchType(art: string) {
            if (art === "legato") {
                return "slur"
            }

            if (art.includes("stac")) {
                return "s"
            }

            return "l"
        }

        const useLLM =
            state.step2.useLLM === undefined ||
            state.step1.timeSignature[0] !== 4 ||
            state.step1.timeSignature[1] !== 4
                ? false
                : state.step2.useLLM && featureFlags.llmBasedLayerGeneration

        if (useLLM) {
            const layers: SegmentedScoreLayer[] = Object.keys(
                state.engine.score.layers
            )
                .filter(l => {
                    console.log(l)
                    return l !== layer
                })
                .map(l => {
                    const layerObject = state.engine.score.layers[l]
                    let notes: SegmentedScoreNote[] = []

                    layerObject.notesObject.manipulateNoteGroups(noteGroup => {
                        notes = notes.concat({
                            start: noteGroup[0].start,
                            duration: noteGroup[0].duration,
                            pitches: noteGroup.map(n => n.pitch),
                        })

                        return true
                    })

                    const insts: SegmentedScoreInstrument[] =
                        layerObject.trackBuses.map(bus => {
                            const category =
                                SegmentedScoreManipulationModule.getTrackBusCategory(
                                    bus,
                                    instruments
                                )

                            if (category === undefined) {
                                throw Error("Category not found")
                            }

                            const articulation = bus.name.split(".")[3]

                            return {
                                gain: bus.gainOffset,
                                name: bus.name,
                                category,
                                patch_type:
                                    articulationToPatchType(articulation),
                                pitch_range: {
                                    lowest: bus.reference.lowest_note,
                                    highest: bus.reference.highest_note,
                                },
                                octave: bus.octave,
                                playback_time: bus.blocks.map(blocks => {
                                    return {
                                        start: "0",
                                        end: "8",
                                    }
                                }),
                            }
                        })

                    return {
                        value: l,
                        name: l,
                        type: "pitched",
                        dynamic: [
                            {
                                time: "0",
                                value: 60,
                            },
                        ],
                        reverb: {
                            values: [
                                {
                                    time: "0",
                                    value: 60,
                                },
                            ],
                            ir: "None",
                        },
                        gainBias: 0,
                        notes,
                        instruments: insts,
                    }
                })

            const section: SegmentedScoreSection = {
                index: 0,
                start: "0",
                end: "8",
                name: "Theme",
                key_signatures: [
                    {
                        ks: KeySignatureModule.convertKeySignatureToMERepresentation(
                            state.step1.harmony.keySignature
                        ),
                        start: "0",
                        end: "8",
                    },
                ],
                time_signature: [4, 4],
                tempo: state.step1.tempo,
                chords: state.engine.score.chords,
                layers,
            }

            const request = {
                section,
                prompt: "",
                layerToGenerate: layer,
                style: "", //state.gp.name,
                type: "compositionWorkflow",
                instrument: state.engine.score.layers[layer].trackBuses
                    .map(bus => {
                        if (bus.name.split(".")[0] === "p") {
                            return bus.name.split(".")[1]
                        }

                        const category =
                            SegmentedScoreManipulationModule.getTrackBusCategory(
                                bus,
                                instruments
                            )
                        const patchType = articulationToCompressedPatchType(
                            bus.name.split(".")[3]
                        )

                        return (
                            category + " " + patchType + " 0-384 " + bus.octave
                        )
                    })
                    .join(", "),
            }

            const res = await this.http.authRequest(
                "/compositionWorkflow/generateLayerWithLLM",
                request,
                "primary",
                true
            )

            return res.score
        } else {
            const request: CWGenerateLayerBody = {
                keySignature: state.step1.harmony.keySignature,
                timeSignature: state.step1.timeSignature,
                chordProgression: state.engine.score.romanNumerals,
                layer,
                packID,
                notes,
                tempo: state.step1.tempo,
                instrument: instrument.patchID,
                lowestNote,
                swing: state.gp.melodyLayer?.swing ?? false,
            }

            const res = await this.http.authRequest(
                "/compositionWorkflow/generateLayer",
                request,
                "primary",
                true
            )

            return res.score
        }
    }

    public async generateComposition(request: CWCreateComposition): Promise<{
        result: 1
        message?: string
    }> {
        const res = await this.http.authRequest(
            "/compositionWorkflow/createComposition",
            request,
            "primary",
            true
        )

        if (res.result === 0) {
            throw Error(res.message)
        }

        return res
    }

    public async updateWorkflow(
        request: CWSaveCompositionWorkflow
    ): Promise<string> {
        const res = await this.http.authRequest(
            "/compositionWorkflow/updateWorkflow",
            request,
            "primary",
            true
        )

        if (res.result === 0) {
            throw Error(res.message)
        }

        return res.compositionWorkflowID
    }
}
