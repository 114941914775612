<div id="playlists-component">
    <div class="playlists grid-container" [perfectScrollbar]="scrollConfig">
        <div class="playlists-title-container grid-100">
            <div class="playlists-component-title">
                Playlists
            </div>

            <div class="playlists-component-subtitle" *ngIf="selectedPlaylist == null && !isLoading && !emptyPlaylists">
                Select a playlist below and listen to your songs with the highest individual ranking!
            </div>

            <div class="empty-playlists-container playlist-items-container" *ngIf="emptyPlaylists && !isLoading">
                <div class="title">
                    Oh no... There are no personal playlists for you yet
                </div>
                <div class="text">
                    We could not find enough compositions you might enjoy to create personalized playlists. You can change that by spending more time creating, editing and listening to your compositions.   
                </div>
            </div>

            <div class="playlists-component-subtitle" [hidden]="selectedPlaylist == null || !selectedPlaylist.isPlaying" *ngIf="selectedPlaylist != null && selectedPlaylist.isPlaying">
                <div class="playing-anim-container">
                    <div class="rectangle-1"></div>
                    <div class="rectangle-5"></div>
                    <div class="rectangle-3"></div>
                    <div class="rectangle-1"></div>
                </div>

                <div class="currently-playing" *ngIf="selectedPlaylist">
                    <b>Playing now:</b> {{ selectedPlaylist.name }}<span class="hide-on-mobile"> Playlist</span>
                </div>
            </div>
        </div>

        <div class="playlist-items-container" *ngIf="!isLoading && !emptyPlaylists">
            <div class="desktop-grid-16 grid-20 mobile-grid-25 mobile-grid-30 mobile-grid-50" *ngFor="let playlist of playlists;">
                
                <div class="playlist-item" [ngStyle]="{'background-color': playlist.backgroundColor }" (mouseover)="playlist.hover = true" (mouseleave)="playlist.hover = false" (click)="setPlaylist(playlist)">
                    <div class="playlist-item-info">
                        <div class="playlist-item-name">{{ playlist.name }}</div>
                        
                        <div class="playlist-item-image-background" [ngStyle]="{'background-image': 'url(' + getBackgroundImage(playlist) + ')'}"></div>
                        <div class="playlist-item-image-background ripples" [ngStyle]="{'background-image': 'url(\'assets/img/radio_background_alternative.svg\')'}"></div>

                        <div class="playlist-item-play" *ngIf="playlist.hover">
                            <img src="assets/img/play-circle.svg" class="animate-0_1s" *ngIf="selectedPlaylist == null || selectedPlaylist.id != playlist.id || !selectedPlaylist.isPlaying" (click)="play(playlist.id)">
                            <img src="assets/img/player/pause-circle.svg" class="animate-0_1s" *ngIf="selectedPlaylist != null && selectedPlaylist.id == playlist.id && selectedPlaylist.isPlaying" (click)="pause(playlist.id)">
                        </div>

                        <div class="playlist-item-play" *ngIf="!playlist.hover && selectedPlaylist != null && selectedPlaylist.id == playlist.id && selectedPlaylist.isPlaying" (click)="pause(playlist.id)">
                            <img src="assets/img/player/volume_with_black_circle.svg" class="animate-0_1s playlist-item-play-volume">
                        </div>

                    </div>
                </div>
                
                <div class="playlist-item-songs">
                    <!-- <div class="number-of-songs">{{ playlist.compositions.length }} songs</div>
                    <div class="duration">{{ playlist.duration.h }} hr {{ playlist.duration.m }} min</div> -->

                    {{ playlist.compositions.length }} songs, {{ playlist.duration.h }} hr {{ playlist.duration.m }} min
                </div>

            </div>
        </div>

        <div class="playlist-loader-container"  *ngIf="isLoading">
            <div class="loader" ></div>    
        </div>
    </div>
</div>