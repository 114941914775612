import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core'
import { GenerationProfileService } from '@services/generation-profile/generationprofile.service'
import { DesignService } from '@services/design.service'
import GenerationProfile from '@common-lib/classes/generationprofiles/generationprofile'
import { environment } from '@environments/environment'


@Component({
 	selector: 'share-gp',
  	templateUrl: 'share-gp.component.html',
  	styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ShareGPComponent implements OnInit {
    @Input() generationProfile:GenerationProfile

    @Output() close: EventEmitter<any> = new EventEmitter()

    linkCopied:boolean

    constructor(private designService:DesignService, private gpService:GenerationProfileService, private ref:ChangeDetectorRef) {

    }

    ngOnInit(): void {

    }

    closeModal() {
        this.close.emit()
    }

    getSharedLink() {
		const subDomain = environment.production ? "creators" : "dev"

		if (this.generationProfile != null && this.generationProfile.shared) {
			return 'https://' + subDomain + '.aiva.ai/generation-profile-editor/' + this.generationProfile._id
		}

		return ''
	}

    toggleLinkSharing(value) {
        this.generationProfile.shared = value
        
		this.gpService.toggleLinkSharing(this.generationProfile._id, value)
	}
}