import AIVAObject from "../general/aivaobject"
import { Time } from "../../modules/time"
import { Pattern } from "./pattern"
import { FractionString } from "../../types/score"
import {
    PercussionOnset,
    TemplatePatternRegion,
} from "../../interfaces/score/templateScore"
import { TIMESTEP_RES } from "../../constants/constants"
import Channel from "./channel"
import { Note } from "./note"
import { v4 as uuidv4 } from "uuid"
import { Fraction } from "./fraction"

export default class PatternRegion extends AIVAObject {
    id: string // unique identifier
    loop: number // number of times the pattern region is looping (repeating itself)
    pattern: Pattern // the pattern associated with the pattern region
    // The start of the pattern region
    private _start: Fraction
    // regions content. E.g. when we have a 2 bar pattern in the pattern region and move the pattern region by 1/1
    // from the start, it will have an onset of 1/1 and a total duration of 1/1 (originally 2/1 - 1/1 onset)
    // when resizing a pattern region from the start, the onset is the fraction that we "skipped" the pattern
    private _onset: Fraction

    // total duration of the pattern region
    private _duration: Fraction

    public get start(): FractionString {
        return this._start.toString()
    }

    public set start(val: FractionString) {
        this._start = new Fraction(val)
    }

    public get startFraction(): Fraction {
        return this._start
    }

    public get duration(): FractionString {
        return this._duration.toString()
    }

    public set duration(val: FractionString) {
        this._duration = new Fraction(val)
    }

    public get durationFraction(): Fraction {
        return this._duration
    }

    public get onset(): FractionString {
        return this._onset.toString()
    }

    public set onset(val: FractionString) {
        this._onset = new Fraction(val)
    }

    public get onsetFraction(): Fraction {
        return this._onset
    }

    constructor(
        patternRegionObject: {
            start: FractionString
            onset: FractionString
            duration: FractionString
            loop: number
        },
        pattern: Pattern
    ) {
        super()

        this.id = uuidv4()
        this._start = new Fraction(patternRegionObject.start)
        this._onset = new Fraction(patternRegionObject.onset)
        this._duration = new Fraction(patternRegionObject.duration)
        this.loop = patternRegionObject.loop
        this.pattern = pattern
    }

    decode(): TemplatePatternRegion {
        return {
            start: this.start,
            onset: this.onset,
            duration: this.duration,
            loop: this.loop,
            pattern: this.pattern?.id,
        }
    }

    getEnd() {
        const durationWithLoops = Time.multiplyFractionWithNumber(
            this.duration,
            this.loop + 1
        )

        return Time.addTwoFractions(this.start, durationWithLoops)
    }

    static fromTemplateTrack(
        pr: TemplatePatternRegion,
        patterns: Pattern[]
    ): PatternRegion | undefined {
        const selectedPattern: Pattern = <Pattern>(
            patterns.find(p => p.id === pr.pattern)
        )

        if (selectedPattern !== undefined) {
            return new PatternRegion(pr, selectedPattern)
        }

        return undefined
    }

    copy(): PatternRegion {
        const newPatternRegion: PatternRegion = new PatternRegion(
            {
                start: this.start,
                onset: this.onset,
                duration: this.duration,
                loop: this.loop,
            },
            this.pattern.copy()
        )
        const keys = Object.keys(this)

        for (let k = 0; k < keys.length; k++) {
            const key = keys[k]

            if (key == "pattern") {
                newPatternRegion[key] = this[key].copy()
            }

            newPatternRegion[key] = this[key]
        }

        return newPatternRegion
    }

    public getKicks(kicksWithLoop: { [onset: string]: 1 }): {
        [onset: string]: 1
    } {
        const kicks: FractionString[] = []

        for (const ch of this.pattern.channels) {
            if (ch.name !== "Kick") {
                continue
            }

            for (const onset of ch.onsets) {
                if (
                    Time.fractionIsInBoundaries(
                        {
                            start: this.onset,
                            duration: this.duration,
                        },
                        onset.start,
                        true,
                        false
                    )
                ) {
                    const kickStartInScoreTime = Time.addTwoFractions(
                        Time.addTwoFractions(onset.start, this.onset, true),
                        this.start
                    )

                    kicks.push(kickStartInScoreTime)
                }
            }
        }

        for (let i = 0; i <= this.loop + 1; i++) {
            for (const kick of kicks) {
                kicksWithLoop[
                    Time.addTwoFractions(
                        kick,
                        Time.multiplyFractionWithNumber(this.duration, i)
                    )
                ] = 1
            }
        }

        return kicksWithLoop
    }

    public trimFromEnd(newEnd: string): void {
        let loopedEnd = this.getLoopedEnd()

        while (
            Time.compareTwoFractions(loopedEnd, newEnd) === "gt" &&
            this.loop !== 0
        ) {
            this.loop--
            loopedEnd = this.getLoopedEnd()
        }

        if (Time.compareTwoFractions(newEnd, loopedEnd) === ("gt" || "eq")) {
            return
        }

        const durationDiff = Time.addTwoFractions(newEnd, loopedEnd, true)
        const newDuration = Time.addTwoFractions(this.duration, durationDiff)

        this.duration = newDuration
    }

    public trimFromStart(newStart: string): void {
        while (
            this.loop !== 0 &&
            Time.compareTwoFractions(newStart, this.start) === "gt"
        ) {
            this.start = Time.addTwoFractions(this.start, this.duration)
            this.loop--
        }
        if (Time.compareTwoFractions(newStart, this.start) === ("lt" || "eq"))
            return

        const durationDiff = Time.addTwoFractions(newStart, this.start, true)
        const newOnset = Time.addTwoFractions(this.onset, durationDiff)

        this.start = newStart
        this.onset = newOnset
        this.duration = Time.addTwoFractions(this.duration, durationDiff, true)
    }

    getLoopedDuration() {
        return Time.multiplyFractionWithNumber(this.duration, this.loop + 1)
    }

    getEndWithoutLoop() {
        return Time.addTwoFractions(this.start, this.duration)
    }

    getDurationLimit(timeSignature: number[]) {
        const barCount = this.pattern.bars
        const barLength = barCount * timeSignature[1]
        return `${barLength}/${timeSignature[1]}`
    }

    getLoopedEnd() {
        const patternRegionLoopedDuration = Time.multiplyFractionWithNumber(
            this.duration,
            this.loop + 1
        )
        const patternRegionLoopedEnd = Time.addTwoFractions(
            this.start,
            patternRegionLoopedDuration
        )

        return patternRegionLoopedEnd
    }

    getAbsoluteStart(onset: PercussionOnset): FractionString {
        return Time.addTwoFractions(
            Time.addTwoFractions(onset.start, this.start),
            this.onset,
            true
        )
    }

    getAbsoluteTimestepStart(onset, timestepRes = TIMESTEP_RES) {
        const fraction = this.getAbsoluteStart(onset)

        return Time.fractionToTimesteps(timestepRes, fraction)
    }

    getChannelIndexForNote(channels: Channel[], note: Note): number {
        for (let c = 0; c < channels.length; c++) {
            const ch = channels[c]

            if (ch.pitches.includes(note.pitch)) {
                return c
            }
        }

        return -1
    }
}
