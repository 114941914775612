<img
    src="assets/img/pianoroll/resize_indicator.svg"
    class="small-resize-indicator" />

<input
    [(ngModel)]="engine.resizeFactor"
    (mouseup)="engine.setResizeFactor(engine.resizeFactor)"
    type="range"
    min="1"
    max="150"
    class="slider"
    [style.width.px]="width" />

<img
    src="assets/img/pianoroll/resize_indicator.svg"
    class="large-resize-indicator" />
