import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core"
import { CustomizePianorollEffect } from "@services/modal.service"

@Component({
    selector: "customize-pianoroll-fx",
    templateUrl: "customize-pianoroll-fx.component.html",
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizePianorollFXModalComponent {
    @Input() effects: CustomizePianorollEffect

    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor() {}

    toggleBassBoost() {
        this.effects.bassBoost = !this.effects.bassBoost
        this.effects.toggleBassBoost(this.effects.bassBoost)
    }

    toggleVinyl() {
        this.effects.vinyl = !this.effects.vinyl
        this.effects.toggleVinyl(this.effects.vinyl)
    }
}
