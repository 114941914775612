<div class="component-container" oncontextmenu="return false">
    <div class="container" *ngIf="!loading" [perfectScrollbar]="scrollConfig">
        <div class="header">
            <div class="button-row">
                <a-button
                    label="Back to Style Designer"
                    type="round-icon-and-text" class="rounded-square" [icon]="'assets/img/back.svg'"
                    (onClick)="goToGP()"
                ></a-button>
            </div>

            <div class="header-title">
                Development designer
            </div>
        </div>

        <div class="sub-header">
            <div
                class="subheader-switch"
                [ngClass]="{'selected-subheader-switch': selectedTab === 'structure'}"
                (click)="selectedTab = 'structure'"
            >
                <img src="assets/img/structure.svg">
                Structure
            </div>

            <div
                class="subheader-switch"
                [ngClass]="{'selected-subheader-switch': selectedTab === 'envelopes'}"
                (click)="selectedTab = 'envelopes'"
            >
                <img src="assets/img/graph-icons/sine.svg">
                Envelopes
            </div>

            <div
                class="subheader-switch"
                [ngClass]="{'selected-subheader-switch': selectedTab === 'variations'}"
                (click)="selectedTab = 'variations'"
            >
                <img src="assets/img/loop.svg">
                Variations
            </div>
        </div>
        
        <div class="content" *ngIf="selectedTab === 'structure'">
            <div class="settings-row">
                <div class="settings-row-title">
                    Minimum layers
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.minLayers"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <input class="light-input min-layers" type="number" min="1" max="getMaxMinLayer()" (change)="handleMinLayerInput()" [(ngModel)]="getSettings().minLayers">
                </div>
            </div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Layer Priorities
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.layerPriorities"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <div class="light-dropdown" (click)="selectLayersOrder()">
                        <div class="light-dropdown-title">{{ formatLayersOrder() }}</div>
                        <img src="assets/img/pianoroll/toggled.svg">
                    </div>
                </div>
            </div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Subsequence length
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.subsequenceLength"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <ngx-slider 
                        class="subsequence-length-slider light-ngx-slider light-ngx-slider-development-designer" 
                        [options]="getSubsequenceLengthOptions()"
                        [(value)]="this.getFormDescription().structureSettings.subsequenceLength"
                        (userChangeEnd)="changeSubsequenceLength($event)"
                    >
                    </ngx-slider>
                </div>
            </div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Allowed section types
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.allowedSectionTypes"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <dropdown 
                        type="light" 
                        class="allowed-section-types" 
                        [showTooltip]="false" 
                        [items]="getSectionTypeOptions()"
                        [value]="getSectionTypes()"
                        (selectedItem)="selectSectionType($event)"
                    >
                    </dropdown>
                </div>
            </div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Allow partial sections
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.allowPartialSections"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <slide-toggle [(toggled)]="getStructureSettings().partialSections" (onChange)="setGPAsUpdated('structurePartialSections')"></slide-toggle>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="selectedTab === 'envelopes'">
            <div class="settings-row">
                <div class="settings-row-title">
                    Dynamics
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.dynamics"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <dropdown 
                        type="light" 
                        class="allowed-section-types" 
                        [showTooltip]="false" 
                        [items]="getEnvelopes('dynamicsStrategy')"
                        [value]="getSelectedEnvelope('dynamicsStrategy')"
                        (selectedItem)="selectEnvelope($event, 'dynamicsStrategy')"
                    >
                    </dropdown>
                </div>
            </div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Tension
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.tension"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <dropdown 
                        type="light" 
                        class="allowed-section-types" 
                        [showTooltip]="false" 
                        [items]="getEnvelopes('developmentTension')"
                        [value]="getSelectedEnvelope('developmentTension')"
                        (selectedItem)="selectEnvelope($event, 'developmentTension')"
                    >
                    </dropdown>
                </div>
            </div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Layering
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.layering"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <dropdown 
                        type="light" 
                        class="allowed-section-types" 
                        [showTooltip]="false" 
                        [items]="getEnvelopes('layeringStrategy')"
                        [value]="getSelectedEnvelope('layeringStrategy')"
                        (selectedItem)="selectEnvelope($event, 'layeringStrategy')"
                    >
                    </dropdown>
                </div>
            </div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Filters
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.filters"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <dropdown 
                        type="light" 
                        class="allowed-section-types" 
                        [showTooltip]="false" 
                        [items]="getEnvelopes('filteringStrategy')"
                        [value]="getSelectedEnvelope('filteringStrategy')"
                        (selectedItem)="selectEnvelope($event, 'filteringStrategy')"
                    >
                    </dropdown>
                </div>
            </div>
        </div>

        <div class="content" *ngIf="selectedTab === 'variations'">
            <div class="settings-row">
                <div class="settings-row-title">
                    Material
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.materialVariations"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <a-button type="round-icon-and-text" class="rounded-square development-designer" [icon]="'assets/img/graph-icons/variations.svg'" width="210px" label="Material variations settings" (onClick)="openMaterialVariationSettings()"></a-button>
                </div>
            </div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Harmonic
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.harmonicVariation"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <slide-toggle [(toggled)]="getVariationSettings().harmonicVariation" (onChange)="setGPAsUpdated('harmonicVariation')"></slide-toggle>
                </div>
            </div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Key Shift
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.keyShift"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <slide-toggle [(toggled)]="getVariationSettings().keyShift" (onChange)="setGPAsUpdated('variationsKeyShift')"></slide-toggle>
                </div>
            </div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Orchestration
                    <img 
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="tooltips.orchestrationVariation"
                        [tooltip-marginTop]="10"
                        [tooltip-width]="250"
                    >
                </div>
                <div class="settings-row-value">
                    <slide-toggle [(toggled)]="getVariationSettings().orchestrationVariation" (onChange)="setGPAsUpdated('orchestrationVariation')"></slide-toggle>
                </div>
            </div>
        </div>

        <div class="tile preview">
            <div class="title-bar">
                <div class="title">Preview</div>
            </div>

            <div class="tile-content">
                <div class="loader-container preview-loading" *ngIf="previewLoading">
                    <div class="loader"></div>
                </div>

                <development-preview [preview]="preview" *ngIf="!previewLoading"></development-preview>
            </div>
        </div>
    </div>

    <div class="loader-container designer-loader-container" *ngIf="loading">
        <div class="loader"></div>
    </div>
</div>