<div class="settings-element {{ class }}" *ngIf="type == 'default'">
    <div *ngIf="!hideBtns" class="increment-button" (click)="decrement()">
        -
    </div>

    <input
        placeholder="0"
        [(ngModel)]="value"
        type="number"
        (change)="verify()" />

    <div *ngIf="!hideBtns" class="increment-button" (click)="increment()">
        +
    </div>

    <div class="integer-input-field-unit" *ngIf="unit != null">{{ unit }}</div>
</div>

<div class="settings-element {{ class }}" *ngIf="type == 'noIncrement'">
    <input
        placeholder="0"
        [(ngModel)]="value"
        type="number"
        (change)="verify()" />

    <div class="integer-input-field-unit" *ngIf="unit != null">{{ unit }}</div>
</div>
