import {
    ScoreRenderingActions,
    SRActionTypes,
} from "./states/score-rendering/score-rendering.actions"
import { RealtimeSamplerActions } from "./states/realtime-sampler/realtime-sampler.actions"
import { ScoreRenderingQuery } from "./states/score-rendering/score-rendering.query"
import {
    ScoreRendering,
    ScoreRenderingStore,
} from "./states/score-rendering/score-rendering.store"
import { EditorViewActions } from "./states/editor-view/editor-view.actions"
import { EditorViewQuery } from "./states/editor-view/editor-view.query"
import {
    EditorViewState,
    EditorViewStore,
} from "./states/editor-view/editor-view.store"
import {
    PitchStepDomain,
    ScoreCanvasContext,
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    GridDimensions,
    TimelineCanvasContext,
    CanvasType,
    GridDimensionsDictionary,
    GridType,
    AccompanimentDesignerCanvasContext,
    DrumSequencerCanvasContext,
    LayerPreviewCanvasContext,
    TrackbusRegionsCanvasContext,
    PatternHorizontalScrollbarCanvasContext,
    KeyboardEventActions,
    ScoreUpdateType,
} from "./types"
import PianoRollNotesCanvas from "./canvas/piano-roll-notes-canvas"
import ScoreCanvas from "./canvas/score-canvas"
import { PianoCanvas } from "./canvas/piano"
import Score from "../../general/classes/score/score"
import { map, Observable, ReplaySubject, Subject, throttleTime } from "rxjs"
import TimelineCanvas from "./canvas/timeline"
import { AccompanimentDesignerCanvas } from "./canvas/accompaniment-designer"
import AutomationCanvas from "./canvas/automation"
import { Effect } from "../../general/classes/score/effect"
import TempoCanvas from "./canvas/tempo"
import PianorollGridCanvas from "./canvas/pianoroll-grid"
import PatternRegionsCanvas from "./canvas/pattern-regions"
import ChordsLabelCanvas from "./canvas/chords-label.canvas"
import ChordsEditingCanvas from "./canvas/chords-editing.canvas"
import KeySignatureEditingCanvas from "./canvas/key-signature-editing.canvas"
import DrumSequencerCanvas from "./canvas/drum-sequencer"
import PatternHorizontalScrollbarCanvas from "./canvas/pattern-horizontal-scrollbar"
import { Time } from "../../general/modules/time"
import { cloneDeep, isEqual } from "lodash"
import {
    BarCount,
    FractionString,
    LayerFunctionType,
    TimeSignature,
} from "../../general/types/score"
import { Pattern } from "../../general/classes/score/pattern"
import PatternRegion from "../../general/classes/score/patternregion"
import { LayerPreviewCanvas } from "./canvas/layer-preview"
import PercussionLayer from "../../general/classes/score/percussionlayer"
import Layer from "../../general/classes/score/layer"
import TrackBus from "../../general/classes/score/trackbus"
import { TrackbusRegionsCanvas } from "./canvas/trackbus-regions"
import { CursorType } from "../../general/types/note-editing"
import {
    IKeyboardEvents,
    InstrumentJSON,
    InstrumentsJSON,
    PitchToChannelMapping,
} from "../../general/interfaces/score/general"
import { Colors } from "../../general/types/layer"
import { VerticalScrollingCanvas } from "./canvas/vertical-scrollbar"
import Channel from "../../general/classes/score/channel"
import { EmitterType } from "../../general/classes/actionsManager"
import {
    DEFAULT_PERCUSSION_INSTRUMENT,
    DEFAULT_PITCHED_INSTRUMENT,
    LayerType,
    SHOW_BAR_GUTTERS_THRESHOLD,
    SHOW_BEAT_GUTTERS_THRESHOLD,
    SHOW_HALF_BEAT_GUTTERS_THRESHOLD,
    SHOW_TIMESTEP_GUTTERS_THRESHOLD,
} from "../../general/constants/constants"
import { NotesObject } from "../../general/classes/score/notesObject"
import Section from "../../general/classes/score/section"
import {
    playerActions,
    playerQuery,
} from "../general/classes/playerStateManagement"
import { ScoreManipulation } from "../../general/modules/scoremanipulation"
import { KeyboardHandler } from "../../general/modules/keyboard.module"
import { Misc } from "../../general/modules/misc"

export default class ScoreRenderingEngine implements IKeyboardEvents {
    private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)

    protected canvases: { [key: string]: ScoreCanvas } = {}
    public queries: ScoreRenderingQueriesObject
    public srEmitter$: Subject<EmitterType<SRActionTypes>>
    public srIllegalAction$: Subject<"undo" | "redo">

    public actions: ScoreRenderingActionsObject
    private previousGrid: GridDimensionsDictionary
    private scoreRenderingStore: ScoreRenderingStore
    private editorViewStore: EditorViewStore
    private readonly defaultZoomFactor = 5

    private tbLoadingStates: { [tbID: string]: boolean } = {}

    constructor(
        private environment: "production" | "staging",
        private templateBeforeActionEffect?: Function
    ) {
        this.scoreRenderingStore = new ScoreRenderingStore()
        this.editorViewStore = new EditorViewStore()
        const scoreQuery: ScoreRenderingQuery = new ScoreRenderingQuery(
            this.scoreRenderingStore
        )

        this.queries = {
            editorView: new EditorViewQuery(this.editorViewStore, scoreQuery),
            scoreRendering: scoreQuery,
        }

        const rtSamplerActions = new RealtimeSamplerActions(
            scoreQuery,
            this.scoreRenderingStore
        )

        this.actions = {
            editorView: new EditorViewActions(
                this.editorViewStore,
                this.queries.editorView
            ),
            realtimeSampler: rtSamplerActions,
            scoreRendering: new ScoreRenderingActions(
                this.scoreRenderingStore,
                scoreQuery,
                this.queries.editorView,
                this.editorViewStore,
                rtSamplerActions,
                templateBeforeActionEffect
            ),
        }

        this.srEmitter$ = this.actions.scoreRendering.manager.emitter$
        this.srIllegalAction$ =
            this.actions.scoreRendering.manager.illegalAction$

        this.initialiseSubscriptions()
    }

    public get scoreRenderingActionTypes() {
        return this.actions.scoreRendering.actionTypeToMethodMap
    }

    private initialiseSubscriptions() {
        // This pipe listens for changes made to the state, and automatically runs the render function then
        this.queries.scoreRendering.allScoreRenderingStates$.subscribe(
            state => {
                if (!state.score) {
                    return
                }

                if (state.scoreUpdate.includes("TrackBusMetadata")) {
                    for (const tb of state.score.trackBusses) {
                        if (tb.loading === false) {
                            this.tbLoadingStates[tb.id] = false
                        }
                    }
                }

                this.render(state, this.queries.editorView.getValue())
            }
        )

        playerQuery
            .select("timeElapsed")
            .pipe(throttleTime(1000))
            .subscribe(this.followTimelineCursor.bind(this))
    }

    private followTimelineCursor() {
        if (
            !this.queries.editorView.followTimelineCursor ||
            this.queries.editorView.timelineCursorElement === undefined
        ) {
            return
        }

        this.srEmitter$.next({
            type: SRActionTypes.followTimelineCursor,
            data: {
                position: "right",
            },
        })
    }

    public get realtimeSampler$() {
        return this.actions.realtimeSampler.manager.emitter$
    }

    public get scrollToTimestep$() {
        return this.queries.scoreRendering.select("scrollToTimestep")
    }

    public isSelectedTB(tb: TrackBus) {
        return this.queries.scoreRendering.selectedTrackBusses.some(
            t => t.id === tb.id
        )
    }

    public setAutoPedal(autoPedal: boolean, tb: TrackBus) {
        this.srEmitter$.next({
            type: SRActionTypes.setAutoPedal,
            data: {
                autoPedal,
                tb,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public setOctave(octave: number, tb: TrackBus) {
        this.srEmitter$.next({
            type: SRActionTypes.setOctave,
            data: {
                octave,
                tb,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public setDynamic(dynamic: number, tb: TrackBus) {
        this.srEmitter$.next({
            type: SRActionTypes.setDynamic,
            data: {
                dynamic,
                tb,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public setGain(gain: number, tb: TrackBus) {
        this.srEmitter$.next({
            type: SRActionTypes.setGain,
            data: {
                gain,
                tb,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public setPan(
        pan: { value: number; type: "start" | "continue" | "end" },
        tb: TrackBus
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.setPan,
            data: {
                pan: pan.value,
                tb,
            },
            options: {
                isUndoable: pan.type === "end",
            },
        })
    }

    public setBreathingGain(breathingGain: number, tb: TrackBus) {
        this.srEmitter$.next({
            type: SRActionTypes.setBreathingGain,
            data: {
                breathingGain,
                tb,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public layerHasToggledTrackbus(
        layer: Layer,
        type: "mute" | "solo"
    ): boolean {
        let toggled = false

        for (const trackBus of layer.trackBuses) {
            toggled = trackBus[type] || toggled
        }

        return toggled
    }

    public undo() {
        this.srEmitter$.next({
            type: SRActionTypes.undo,
            data: {
                trackBusLoadingState: this.tbLoadingStates,
            },
        })
    }

    public redo() {
        this.srEmitter$.next({
            type: SRActionTypes.redo,
            data: {
                trackBusLoadingState: this.tbLoadingStates,
            },
        })
    }

    public move(direction) {
        this.srEmitter$.next({
            type: SRActionTypes.moveNotes,
            data: {
                pitchDelta: 0,
                timestepDelta: 0,
                enableQuantization: true,
            },
        })
    }

    public layerIsToggled(layer: Layer, type: "mute" | "solo"): boolean {
        let toggled = true

        if (layer.trackBuses.length === 0) {
            toggled = false
        }

        for (const trackBus of layer.trackBuses) {
            toggled = trackBus[type] && toggled
        }

        return toggled
    }

    public deleteLayer(layer: Layer) {
        this.srEmitter$.next({
            type: SRActionTypes.deleteLayer,
            data: {
                layer,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public mute(layer: Layer) {
        this.srEmitter$.next({
            type: SRActionTypes.mute,
            data: {
                layer,
            },
        })
    }

    public keyboardMute() {
        this.srEmitter$.next({
            type: SRActionTypes.toggleTrackBussesMute,
            data: {
                layer: this.toggledLayer,
                trackBusses: this.queries.scoreRendering.selectedTrackBusses,
                type: "mute",
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public keyboardSolo() {
        this.srEmitter$.next({
            type: SRActionTypes.toggleTrackBussesMute,
            data: {
                layer: this.toggledLayer,
                trackBusses: this.queries.scoreRendering.selectedTrackBusses,
                type: "solo",
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public keyboardDuplicate() {
        this.srEmitter$.next({
            type: SRActionTypes.duplicateTrackBusses,
            data: {
                layer: this.toggledLayer,
                toDuplicate: this.queries.scoreRendering.selectedTrackBusses,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public keyboardSelect(event: KeyboardEvent): void {
        if (this.queries.scoreRendering.selectedData.type === "TrackBus") {
            this.selectTrackBusWithKeyboard(event)
        } else if (this.queries.scoreRendering.selectedData.type === "Note") {
            this.moveNotesWithKeyboard(event)
        }
    }

    public zoomIn(factor: number): void {
        this.setResizeFactor(this.resizeFactor + factor)
    }

    public zoomOut(factor: number): void {
        this.setResizeFactor(this.resizeFactor - factor)
    }

    public toggleCursorType(): void {
        let cursorType = this.cursorType

        if (cursorType === "pencil") {
            cursorType = "select"
        } else {
            cursorType = "pencil"
        }

        this.cursorType = cursorType
    }

    public resetZoom(): void {
        this.setResizeFactor(this.defaultZoomFactor)
    }

    private selectTrackBusWithKeyboard(event: KeyboardEvent) {
        const indices = this.queries.scoreRendering.selectedTrackBusses.map(
            t1 => this.toggledLayer.trackBuses.findIndex(t2 => t2.id === t1.id)
        )

        const smallestIndex = indices.reduce((a, b) => Math.min(a, b)) - 1
        const largestIndex = indices.reduce((a, b) => Math.max(a, b)) + 1

        const isInValidRange =
            Misc.isInRange(smallestIndex, [
                -1,
                this.toggledLayer.trackBuses.length,
            ]) &&
            Misc.isInRange(largestIndex, [
                -1,
                this.toggledLayer.trackBuses.length,
            ])

        let tb = undefined

        if (event.key === "ArrowUp" && isInValidRange) {
            tb = this.toggledLayer.trackBuses[smallestIndex]
        } else if (event.key === "ArrowDown" && isInValidRange) {
            tb = this.toggledLayer.trackBuses[largestIndex]
        }

        if (tb === undefined) {
            return
        }

        this.srEmitter$.next({
            type: SRActionTypes.selectTrackBus,
            data: {
                tb,
                keepPreviousSelection: false,
            },
        })
    }

    private moveNotesWithKeyboard(event: KeyboardEvent) {
        this.srEmitter$.next({
            type: SRActionTypes.moveNotesWithKeyboard,
            data: {
                direction: event.key,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public solo(layer: Layer) {
        this.srEmitter$.next({
            type: SRActionTypes.solo,
            data: { layer },
        })
    }

    public setTimeSignature(timeSignature: TimeSignature) {
        this.srEmitter$.next({
            type: SRActionTypes.setTimeSignature,
            data: {
                timeSignature,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public toggleTrackBussesMute(
        layer: Layer,
        trackBusses: TrackBus[],
        type: "mute" | "solo"
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.toggleTrackBussesMute,
            data: {
                layer,
                trackBusses,
                type,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public get scoreWasEdited() {
        return this.queries.scoreRendering.getValue().scoreWasEdited
    }

    public get scoreWasEdited$() {
        return this.queries.scoreRendering.scoreWasEdited$
    }

    public get temporaryRomanNumerals$() {
        return this.queries.scoreRendering.temporaryRomanNumerals$
    }

    public get selectedData$() {
        return this.queries.scoreRendering.selectedData$
    }

    public get selectedNotes$() {
        return this.queries.scoreRendering.selectedNotes$
    }

    public get selectedPattern$() {
        return this.queries.scoreRendering.selectedPattern$
    }

    public get selectedPattern(): Pattern | undefined {
        return this.queries.scoreRendering.selectedPattern
    }

    public get timeSignature(): TimeSignature {
        return this.queries.scoreRendering.score.timeSignatures[0][1]
    }

    public get selectedAutomation() {
        return this.queries.scoreRendering.all.selectedAutomation
    }

    public get selectedAutomation$() {
        return this.queries.scoreRendering.selectedAutomation$
    }

    public get seekTime$() {
        return this.queries.scoreRendering.seekTime$
    }

    public get resizeFactor$() {
        return this.queries.scoreRendering.resizeFactor$
    }

    public get scoreUpdate$(): Observable<ScoreUpdateType[]> {
        return this.queries.scoreRendering.scoreUpdate$
    }

    public set resizeFactor(resizeFactor: number) {
        this.srEmitter$.next({
            type: SRActionTypes.setResizeFactor,
            data: {
                resizeFactor,
            },
        })
    }

    public get resizeFactor() {
        return this.queries.scoreRendering.resizeFactor
    }

    public get selectedNotes() {
        return this.queries.scoreRendering.selectedNotes
    }

    public get score(): Score | undefined {
        return this.queries.scoreRendering.score
    }

    public get score$() {
        return this.queries.scoreRendering.select("score")
    }

    public get allowPolyphony(): boolean {
        return this.queries.scoreRendering.getValue().allowPolyphony
    }

    public get resizeFactorRange() {
        return this.queries.scoreRendering.getValue().resizeFactorRange
    }

    public get renderChordsType() {
        return this.queries.scoreRendering.getValue().renderChordsType
    }

    // Exposes the grid so components like the play-head can use it
    public get grid$() {
        return this.queries.editorView.select("grid")
    }

    public get grid() {
        return this.queries.editorView.getValue().grid
    }

    public duplicateLayer(layer: Layer, name: string) {
        this.srEmitter$.next({
            type: SRActionTypes.duplicateLayer,
            data: {
                layer,
                name
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public setLayerColor(color: Colors, layer: Layer) {
        this.srEmitter$.next({
            type: SRActionTypes.setLayerColor,
            data: {
                color,
                layer,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public setLayerName(name: string, layer: Layer) {
        this.srEmitter$.next({
            type: SRActionTypes.setLayerName,
            data: {
                name,
                layer,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public setConstantAutomation(value: number) {
        this.srEmitter$.next({
            type: SRActionTypes.setConstantAutomation,
            data: {
                value,
            },
        })
    }

    public unselectAll() {
        this.srEmitter$.next({
            type: SRActionTypes.unselectAll,
        })
    }

    public copyAutomationToOtherLayers(effect: Effect, layer: Layer) {
        this.srEmitter$.next({
            type: SRActionTypes.copyAutomationToOtherLayers,
            data: {
                effect,
                layer,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public toggleEffect(effect: Effect) {
        this.srEmitter$.next({
            type: SRActionTypes.toggleEffect,
            data: {
                effect,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public get scrollToTimestep() {
        return this.queries.scoreRendering.scrollToTimestep
    }

    public get selectedSection$() {
        return this.queries.scoreRendering.selectedSection$
    }

    public get visiblePitchedLayers(): Layer[] {
        return this.queries.scoreRendering.visiblePitchedLayers
    }

    public get toggledLayer(): PercussionLayer | Layer | undefined {
        return this.queries.scoreRendering.toggledLayer
    }

    public get toggledLayer$() {
        return this.queries.scoreRendering.toggledLayer$
    }

    public getLayers() {
        return this.queries.scoreRendering.score?.layers
    }

    public getToggledLayer(): Layer | PercussionLayer | undefined {
        return this.queries.scoreRendering.toggledLayer
    }

    public isVisibleLayer(layer: string): boolean {
        const layers = this.queries.scoreRendering.visiblePitchedLayers

        if (this.queries.scoreRendering.toggledLayer?.value === layer) {
            return true
        }

        return layers.find(l => l.value === layer) !== undefined
    }

    public toggleLayerAsVisible(layer: string) {
        const score: Score | undefined = this.queries.scoreRendering.score

        if (!score || score.layers[layer] === undefined) {
            return
        }

        if (this.queries.scoreRendering.toggledLayer?.value === layer) {
            this.srEmitter$.next({
                type: SRActionTypes.toggleLayer,
                data: {
                    toggledLayer: undefined,
                },
            })
            return
        }

        this.srEmitter$.next({
            type: SRActionTypes.toggleLayerAsVisible,
            data: {
                layer: score.layers[layer],
            },
        })
    }

    public toggleLayer(layer: string) {
        const score: Score | undefined = this.queries.scoreRendering.score

        if (!score) {
            return
        }

        let returnAfterReset = false

        if (this.queries.scoreRendering.toggledLayer?.value === layer) {
            returnAfterReset = true
        }

        // by always resetting the toggled layer, we don't end up rendering
        // the accompaniment designer twice in case we are switching between canvases
        this.srEmitter$.next({
            type: SRActionTypes.toggleLayer,
            data: {
                toggledLayer: undefined,
            },
        })

        if (returnAfterReset) {
            return
        }

        this.srEmitter$.next({
            type: SRActionTypes.toggleLayer,
            data: {
                toggledLayer: score.layers[layer],
            },
        })
    }

    public clearSelectedSection() {
        this.srEmitter$.next({
            type: SRActionTypes.selectSection,
            data: {
                section: undefined,
                coordinates: undefined,
            },
        })
    }

    public setAccompanimentDesignerScoreLength(
        scoreLength: FractionString,
        notesToRemove: NotesObject
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.setAccompanimentDesignerScoreLength,
            data: {
                scoreLength: scoreLength,
                notesToRemove: notesToRemove,
            },
        })
    }

    /**
     * Used to resize each ScoreCanvas instance's HTMLCanvasElements width and height values to
     * match that of their container. This is useful to use upon window resize in the component that
     * contains the canvas container div element for example
     */
    public async resizeCanvases() {
        for (const canvasType in this.canvases) {
            const canvas: ScoreCanvas = this.canvases[canvasType]

            const height =
                canvas.context.canvasContainer.getBoundingClientRect().height
            const width =
                canvas.context.canvasContainer.getBoundingClientRect().width

            canvas.adjustCanvasDimensions({
                height,
                width,
            })
        }

        this.srEmitter$.next({
            type: SRActionTypes.setRenderingType,
            data: {
                type: "All",
            },
        })
    }

    /**
     * Initialises a canvas object and returns its ID
     * @param type
     * @param context
     * @returns
     */
    public initCanvas(
        type: CanvasType,
        context: ScoreCanvasContext
    ): string | undefined {
        // initialise the right child class of ScoreCanvas here
        if (!context.canvasContainer || !type || !context) {
            return undefined
        }

        let CanvasClass

        if (type === "PianoRollNotesCanvas") {
            CanvasClass = PianoRollNotesCanvas
        } else if (type === "TimelineCanvas") {
            CanvasClass = TimelineCanvas
        } else if (type === "PatternHorizontalScrollbarCanvas") {
            CanvasClass = PatternHorizontalScrollbarCanvas
        } else if (type === "AccompanimentDesignerCanvas") {
            CanvasClass = AccompanimentDesignerCanvas

            if (
                (<AccompanimentDesignerCanvasContext>context).canvases ===
                undefined ||
                (<AccompanimentDesignerCanvasContext>context).canvases
                    .length === 0
            ) {
                throw "initCanvas AccompanimentDesignerCanvas failed: include a canvases argument in your context"
            }
        } else if (type === "AutomationCanvas") {
            CanvasClass = AutomationCanvas
        } else if (type === "TempoCanvas") {
            CanvasClass = TempoCanvas
        } else if (type === "PianorollGridCanvas") {
            CanvasClass = PianorollGridCanvas
        } else if (type === "VerticalScrollingCanvas") {
            CanvasClass = VerticalScrollingCanvas
        } else if (type === "PatternRegionsCanvas") {
            CanvasClass = PatternRegionsCanvas
        } else if (type === "DrumSequencerCanvas") {
            type += "_" + (<DrumSequencerCanvasContext>context).trackBusID
            CanvasClass = DrumSequencerCanvas
        } else if (type === "TrackbusRegionsCanvas") {
            type +=
                "_" +
                (<TrackbusRegionsCanvasContext>context).layer.value +
                "_" +
                (<TrackbusRegionsCanvasContext>context).trackBus.id
            CanvasClass = TrackbusRegionsCanvas
        } else if (type === "PianoCanvas") {
            CanvasClass = PianoCanvas
        } else if (type === "LayerPreviewCanvas") {
            type += "_" + (<LayerPreviewCanvasContext>context).layer.value
            CanvasClass = LayerPreviewCanvas
        } else if (type === "ChordsLabelCanvas") {
            CanvasClass = ChordsLabelCanvas
        } else if (type === "ChordsEditingCanvas") {
            CanvasClass = ChordsEditingCanvas
        } else if (type === "KeySignatureEditingCanvas") {
            CanvasClass = KeySignatureEditingCanvas
        } else {
            throw "Invalid type:CanvasType"
        }

        const newCanvasObject = new CanvasClass(
            context,
            this.queries,
            this.actions,
            type
        )

        if (newCanvasObject) {
            if (this.canvases[type]) {
                this.deleteCanvas(type)
            }

            this.canvases[type] = newCanvasObject

            this.scoreRenderingStore.updateStore({
                partial: {
                    renderingType: [type],
                },
                scoreWasEdited: false,
                updateScoreLength: false,
            })
        }

        return type
    }

    public deleteAllCanvases(destroyEngine: boolean = true) {
        for (const canvasType in this.canvases) {
            this.deleteCanvas(canvasType as CanvasType)
        }

        if (destroyEngine) {
            this.destroy$.next(true)
            this.destroy$.complete()
        }
    }

    /**
     * There can be multiple canvases attached to a certain canvas
     * type. To be able to remove one specific canvas, we can pass
     * the score canvas context of the canvas we want to delete
     * @param type CanvasType
     * @returns
     */
    public deleteCanvas(type: string) {
        for (const canvasType in this.canvases) {
            if (canvasType.includes("LayerPreviewCanvas_")) {
                if (canvasType === type) {
                    this.canvases[canvasType].cleanupCanvas()
                    delete this.canvases[canvasType]
                }
            } else if (canvasType.includes(type)) {
                this.canvases[canvasType].cleanupCanvas()
                delete this.canvases[canvasType]
            }
        }

        return
    }

    public setResizeFactor(resize: number) {
        this.srEmitter$.next({
            type: SRActionTypes.setResizeFactor,
            data: {
                resizeFactor: resize,
            },
        })
    }

    /**
     * This function should always be called in order to create an instance of
     * the ScoreRenderingEngine class
     * @param score The score that the engine will render
     * @param environment
     * @param additionalData timelineCursorElement refers to the div element in the DOM that contains a timeline
     * cursor. This function expects this div element to be passed here as argument, as it is necessary for some
     * features to operate, including the follow timeline cursor feature.
     * @param autoExtendScore if true, the score will be extended automatically when doing actions like drawing
     * a note at the end of the score. Always set to true for the editor component and to false for the accompaniment
     * designer componentn
     * @param maxBarLength This parameter describes the maximum number of bars a section can be. This is used for section
     * editing operations (like insert a new section). Typically, this value should come from the music engine, but a fairly
     * safe value to provide for this is 8
     * @returns
     */
    static initScore(
        score: Score,
        environment: "production" | "staging",
        additionalData: {
            settings: string
            userID: string
            instruments: InstrumentsJSON
            autoExtendScore: boolean
            maxBarLength: number
            resizeFactor: {
                min: number
                max: number
            }
            levelsMeasurement?: "trackbus" | "layer"
            sustainPedalFromChords: boolean
        },
        templateBeforeActionEffect?: Function
    ): ScoreRenderingEngine {
        if (additionalData.levelsMeasurement === undefined) {
            additionalData.levelsMeasurement = "trackbus"
        }

        const engine: ScoreRenderingEngine = new ScoreRenderingEngine(
            environment,
            templateBeforeActionEffect
        )
        engine.srEmitter$.next({
            type: SRActionTypes.initScore,
            data: {
                score,
                additionalData,
            },
        })

        playerActions.setLoopType("ScoreRenderingEngine.initScore", "score")
        return engine
    }

    public get cursorType(): CursorType {
        return this.queries.editorView.cursorType
    }

    public get allowZoom(): boolean {
        return this.queries.editorView.grid.pitched.allowZoom
    }

    public set cursorType(cursorType: CursorType) {
        this.actions.editorView.setCursorType(cursorType)
    }

    public get timestepRes(): number {
        return this.queries.scoreRendering.timestepRes
    }

    public get cursorType$() {
        return this.queries.editorView.cursorType$
    }

    public setTimestepRes(timestepRes: number) {
        this.srEmitter$.next({
            type: SRActionTypes.setTimestepRes,
            data: {
                timestepRes,
            },
        })
    }

    /**
     * this method also handles removing notes if necessary
     * @param timestepRes
     * @param notesToRemove
     */
    public setAccompanimentDesignerTimestepRes(
        timestepRes: number,
        notesToRemove: NotesObject
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.setAccompanimentDesignerTimestepRes,
            data: {
                timestepRes,
                notesToRemove,
            },
        })
    }

    public addTrackBus(instruments: InstrumentsJSON) {
        let instrument = instruments["k"].find(
            i => i.name == DEFAULT_PITCHED_INSTRUMENT
        )

        if (this.toggledLayer?.type === "percussion") {
            instrument = instruments["p"].find(
                i => i.name == DEFAULT_PERCUSSION_INSTRUMENT
            )
        }

        const layer: Layer | undefined =
            this.queries.scoreRendering.toggledLayer

        if (layer === undefined) {
            return
        }

        this.srEmitter$.next({
            type: SRActionTypes.addTrackBus,
            data: {
                layer,
                instrument,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public addPattern(layer: PercussionLayer) {
        this.srEmitter$.next({
            type: SRActionTypes.addPattern,
            data: {
                layer,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public renamePattern(
        layer: PercussionLayer,
        pattern: Pattern,
        name: string
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.renamePattern,
            data: {
                layer,
                pattern,
                name,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public deletePattern(layer: PercussionLayer, pattern: Pattern) {
        this.srEmitter$.next({
            type: SRActionTypes.deletePattern,
            data: {
                layer,
                pattern,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public drawPercussionNote(
        tb: TrackBus,
        timesteps: number,
        ysteps: number,
        strategy: string
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.drawPercussionNote,
            data: {
                tb,
                timesteps,
                ysteps,
                strategy,
            },
            options: {
                isUndoable: false,
            },
        })
    }

    public duplicatePattern(layer: PercussionLayer, pattern: Pattern) {
        this.srEmitter$.next({
            type: SRActionTypes.addPattern,
            data: {
                layer,
                pattern,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public setPatternBars(
        layer: PercussionLayer,
        pattern: Pattern,
        bars: BarCount
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.setPatternBars,
            data: {
                layer,
                pattern,
                bars,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public setPatternChannel(
        layer: PercussionLayer,
        pattern: Pattern,
        currentChannelID: string,
        newChannelName: string,
        pitchToChannelMapping: PitchToChannelMapping
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.setPatternChannel,
            data: {
                layer,
                pattern,
                currentChannelID,
                newChannelName,
                pitchToChannelMapping,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public setPatternResolution(
        layer: PercussionLayer,
        pattern: Pattern,
        resolution: string
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.setPatternResolution,
            data: {
                layer,
                pattern,
                resolution,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public addChannel(layer: PercussionLayer, pattern: Pattern, tb: TrackBus) {
        this.srEmitter$.next({
            type: SRActionTypes.addChannel,
            data: {
                layer,
                pattern,
                trackBus: tb,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public toggleChannelPlayback(
        layer: PercussionLayer,
        pattern: Pattern,
        channel: Channel,
        playback: "mute" | "solo"
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.toggleChannelPlayback,
            data: {
                layer,
                pattern,
                channel,
                playback,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public deleteChannel(
        layer: PercussionLayer,
        pattern: Pattern,
        channelID: string
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.deleteChannel,
            data: {
                layer,
                pattern,
                channelID,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public addCustomLayer(
        layerType: LayerFunctionType,
        defaultInstrument: InstrumentJSON
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.addCustomLayer,
            data: {
                layerType,
                defaultInstrument,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    /**
     *
     * @param effect
     * @param initCanvasFunction This function is used to initialise the canvas based on a div element specified
     * in the component that uses the canvas and the context. It does not need to be provided if setting the effect
     * to undefined
     */
    public selectAutomation(effect: Effect | undefined) {
        this.srEmitter$.next({
            type: SRActionTypes.setAutomation,
            data: {
                effect,
            },
            options: {
                isUndoable: true,
            },
        })

        if (!effect) {
            this.deleteCanvas("AutomationCanvas")
        }
    }

    public resetScroll({
        timesteps,
        pitchsteps,
    }: {
        timesteps: boolean
        pitchsteps: boolean
    }) {
        this.srEmitter$.next({
            type: SRActionTypes.resetScroll,
            data: {
                timesteps,
                pitchsteps,
            },
        })
    }

    public setPitchStepDomain(domain: PitchStepDomain) {
        this.srEmitter$.next({
            type: SRActionTypes.setPitchStepDomain,
            data: {
                domain,
            },
        })
    }

    public setSelectedPattern(pattern: Pattern) {
        this.srEmitter$.next({
            type: SRActionTypes.setSelectedPattern,
            data: {
                pattern,
                scoreWasEdited: false,
            },
        })
    }

    public setSelectedPatternRegion(patternRegion: PatternRegion) {
        this.srEmitter$.next({
            type: SRActionTypes.setSelectedPatternRegion,
            data: {
                patternRegion,
            },
        })
    }

    public selectAllNotes() {
        this.srEmitter$.next({
            type: SRActionTypes.selectAllNotes,
        })
    }

    public copy() {
        this.srEmitter$.next({
            type: SRActionTypes.copy,
        })
    }

    public cut() {
        this.srEmitter$.next({
            type: SRActionTypes.cut,
            options: {
                isUndoable: true,
            },
        })
    }

    public paste() {
        this.srEmitter$.next({
            type: SRActionTypes.paste,
            options: {
                isUndoable: true,
            },
        })
    }

    public get seekTime() {
        return this.queries.scoreRendering.getValue().seekTime
    }

    public set seekTime(seekTime: number) {
        this.srEmitter$.next({
            type: SRActionTypes.seek,
            data: {
                timesteps: seekTime,
            },
        })
    }

    public deleteSelectedData() {
        this.srEmitter$.next({
            type: SRActionTypes.deleteSelectedData,
            options: {
                isUndoable: true,
            },
        })
    }

    public deleteTrackBusses(layer: Layer, trackBusses: TrackBus[]) {
        this.srEmitter$.next({
            type: SRActionTypes.deleteTrackBusses,
            data: {
                layer: layer,
                trackBusses: trackBusses,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public updateLayerType(currentType: LayerType, newType: LayerType) {
        this.srEmitter$.next({
            type: SRActionTypes.updateLayerType,
            data: {
                currentType,
                newType,
            },
        })
    }

    public updateKeySignature(
        keySignature: string,
        updatePitches?: boolean,
        startOctave?: number
    ) {
        this.srEmitter$.next({
            type: SRActionTypes.updateKeySignature,
            data: {
                keySignature,
                updatePitches,
                startOctave,
            },
        })
    }

    private isSyncedCanvas(canvas: string) {
        return (
            <CanvasType>canvas === "TimelineCanvas" ||
            <CanvasType>canvas === "PatternHorizontalScrollbarCanvas"
        )
    }

    private getCanvasWidth(gridType: GridType): number | undefined {
        for (let c in this.canvases) {
            const canvas: ScoreCanvas = this.canvases[c]

            if (canvas.gridType === gridType) {
                return canvas.width
            }
        }

        return undefined
    }

    private render(state: ScoreRendering, editorView: EditorViewState) {
        const grid: Readonly<GridDimensionsDictionary> = this.getGridDimensions(
            this.previousGrid
        )
        this.previousGrid = grid

        // First, render unsynced canvases
        for (const canvas in this.canvases) {
            if (this.isSyncedCanvas(canvas)) {
                continue
            }

            const start = performance.now()

            this.canvases[canvas].render(state, editorView, grid)

            const end = performance.now()

            if (end - start > 10 && this.environment !== "production") {
                console.warn(canvas + " took " + (end - start) + "ms to render")
            }
        }

        // Then, render synced canvases. The order is there to make sure that the synced canvases have up to date data to pull from the canvas they are synchronising to
        for (const canvas in this.canvases) {
            if (!this.isSyncedCanvas(canvas)) {
                continue
            }

            const timelineCanvas = this.canvases[canvas] as TimelineCanvas
            const context = <
                TimelineCanvasContext | PatternHorizontalScrollbarCanvasContext
                >timelineCanvas.context
            const syncedCanvas = this.canvases[context.syncToCanvas]

            const start = performance.now()

            timelineCanvas.render(state, editorView, grid, syncedCanvas)

            const end = performance.now()

            if (end - start > 10 && this.environment === "staging") {
                console.warn(canvas + " took " + (end - start) + "ms to render")
            }
        }
    }

    public getGridDimensions(
        previousGrid: GridDimensionsDictionary | undefined,
        width?: number
    ): Readonly<GridDimensionsDictionary> {
        const scoreState = this.queries.scoreRendering.all

        if (!scoreState.score) {
            throw "ScoreRenderingEngine.getGridDimensions - Score is undefined"
        }

        const timeSignature: TimeSignature =
            scoreState.score.timeSignatures[0][1]

        const pattern: Pattern | undefined = scoreState.selectedPattern

        const patternLength = !pattern
            ? timeSignature[0] + "/" + timeSignature[1]
            : pattern.bars * timeSignature[0] + "/" + timeSignature[1]

        const pitchedCanvasWidth = this.getCanvasWidth("pitched")
        const drumSequencerCanvasWidth = this.getCanvasWidth("drumSequencer")

        const grid = {
            pitched: ScoreRenderingEngine.calculateGridDimensions(
                previousGrid?.pitched,
                scoreState,
                scoreState.pitchStepDomain,
                scoreState.score.scoreLength,
                "pitched",
                pitchedCanvasWidth
            ),
            drumSequencer: ScoreRenderingEngine.calculateGridDimensions(
                previousGrid?.drumSequencer,
                scoreState,
                "scale",
                patternLength,
                "drumSequencer",
                drumSequencerCanvasWidth
            ),
        }

        this.editorViewStore.update(state => ({
            ...state,
            grid,
        }))

        return grid
    }

    public removeNotesForSection(section: Section) {
        this.srEmitter$.next({
            type: SRActionTypes.clearNotesFromSection,
            data: {
                section,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public resetScoreWasEdited() {
        this.srEmitter$.next({
            type: SRActionTypes.resetScoreWasEdited,
            data: {},
            options: {
                isUndoable: false,
            },
        })
    }

    public setAllowPolyphony(allowPolyphony: boolean) {
        this.srEmitter$.next({
            type: SRActionTypes.setAllowPolyphony,
            data: {
                allowPolyphony,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    static calculateGridDimensions(
        previousGrid: GridDimensions,
        scoreState: ScoreRendering,
        pitchStepDomain: PitchStepDomain,
        scoreLength: FractionString,
        gridType: GridType = "pitched",
        width?: number
    ): GridDimensions {
        if (!scoreState.score) {
            throw "ScoreRenderingEngine.calculateGridDimensions - Score is undefined"
        }

        const score: Score = scoreState.score
        const MIN_PX_PER_TIMESTEP = 0.1
        const noteWidthIncrement = 0.1
        const resizeFactor = scoreState.resizeFactor
        const timeSignature: TimeSignature = score.timeSignatures[0][1]

        let noteBorderRadius = pitchStepDomain === "scale" ? 3 : 1
        let pxPerBeatGutter = pitchStepDomain === "scale" ? 4 : 3
        let pxPerHalfBeatGutter = pitchStepDomain === "scale" ? 2 : 1
        let pxPerTimestepGutter = pitchStepDomain === "scale" ? 2 : 1
        let pxPerBarGutter = pitchStepDomain === "scale" ? 6 : 6
        let timestepRes = scoreState.userSelectedTimestepRes

        const quantizeRes =
            gridType === "drumSequencer"
                ? scoreState.selectedPattern?.noteRes
                : ScoreRenderingEngine.getQuantizeRes({
                    resizeFactor: resizeFactor,
                    timestepRes: timestepRes,
                    timeSignature: timeSignature,
                })

        if (gridType !== "drumSequencer") {
            if (resizeFactor < SHOW_HALF_BEAT_GUTTERS_THRESHOLD) {
                pxPerTimestepGutter = 0
            }

            if (
                resizeFactor < SHOW_HALF_BEAT_GUTTERS_THRESHOLD ||
                resizeFactor >= SHOW_TIMESTEP_GUTTERS_THRESHOLD
            ) {
                pxPerHalfBeatGutter = 0
            }

            if (resizeFactor < SHOW_BEAT_GUTTERS_THRESHOLD) {
                pxPerBeatGutter = 0
            }

            if (resizeFactor < SHOW_BAR_GUTTERS_THRESHOLD) {
                pxPerBarGutter = 3
            }
        }

        if (
            gridType === "drumSequencer" &&
            scoreState?.selectedPattern?.resolution
        ) {
            const patternRes = scoreState?.selectedPattern?.resolution

            timestepRes = parseInt(patternRes.split("/")[1])
        }

        const notesInOneBeat = Math.floor(timestepRes / timeSignature[1])

        const scoreLengthTimesteps = Time.fractionToTimesteps(
            timestepRes,
            scoreLength
        )

        const barsInPattern = Time.timestepsToBar(
            scoreLengthTimesteps,
            timeSignature,
            timestepRes
        )

        const beatsInOneBar = timeSignature[0]
        const halfBeatsInOneBar = timeSignature[0] * 2
        const nbOfBeats = barsInPattern * beatsInOneBar

        const pxPerBeatsGutterInBar = pxPerBeatGutter * (beatsInOneBar - 1)
        const pxPerHalfBeatsGutterInBar = pxPerHalfBeatGutter * beatsInOneBar
        const pxPerTimestepGutterInBeat =
            pxPerTimestepGutter * (notesInOneBeat - 1)
        const pxPerTimestepGutterInBar = pxPerTimestepGutterInBeat * nbOfBeats

        const pxLengthForGutters =
            pxPerBarGutter * (barsInPattern - 1) +
            pxPerBeatsGutterInBar * barsInPattern +
            pxPerHalfBeatsGutterInBar *
            barsInPattern *
            (gridType === "pitched" ? 1 : 0) +
            pxPerTimestepGutterInBar

        let pxPerTimestepWithoutGutters = Math.max(
            resizeFactor * noteWidthIncrement,
            MIN_PX_PER_TIMESTEP
        )

        const maxPxPerTimestepWithoutGutters = 150 * noteWidthIncrement
        const DRUM_SEQUENCER_NOTE_WIDTH = 15

        if (gridType === "drumSequencer") {
            pxPerTimestepWithoutGutters = DRUM_SEQUENCER_NOTE_WIDTH
        }

        let pxLength =
            scoreLengthTimesteps * pxPerTimestepWithoutGutters +
            pxLengthForGutters

        // spread the grid across the whole width if necessary
        if (width && pxLength < width) {
            pxPerTimestepWithoutGutters = Math.max(
                (width - pxLengthForGutters) / scoreLengthTimesteps,
                MIN_PX_PER_TIMESTEP
            )
        }

        // define if zooming should be enabled
        let allowZoom = true

        if (gridType === "pitched" && pitchStepDomain === "scale") {
            allowZoom = false

            const currentPxLength =
                scoreLengthTimesteps * pxPerTimestepWithoutGutters +
                pxLengthForGutters

            const maxPxLength =
                scoreLengthTimesteps * maxPxPerTimestepWithoutGutters +
                pxLengthForGutters

            if (currentPxLength < maxPxLength || resizeFactor === 150) {
                allowZoom = true
            }
        }

        const pxPerBeat =
            pxPerTimestepWithoutGutters * notesInOneBeat +
            pxPerTimestepGutterInBeat

        const pxPerHalfBeat = pxPerBeat / 2

        const pxPerBar = pxPerBeat * beatsInOneBar + pxPerBeatsGutterInBar

        const newGrid: GridDimensions = {
            hasChanged: false,
            timeSignature: timeSignature,
            timestepRes: timestepRes,
            pxLengthForGutters: pxLengthForGutters,
            scoreLengthTimesteps: scoreLengthTimesteps,
            pitchStepDomain: pitchStepDomain,
            noteWidthIncrement: noteWidthIncrement,
            pxPerTimestepGutter: pxPerTimestepGutter,
            noteBorderRadius: noteBorderRadius,
            pxPerBeat: pxPerBeat,
            pxPerHalfBeat: pxPerHalfBeat,
            pxPerBeatGutter: pxPerBeatGutter,
            pxPerHalfBeatGutter: pxPerHalfBeatGutter,
            pxPerBar: pxPerBar,
            pxPerBarGutter: pxPerBarGutter,
            notesInOneBeat: notesInOneBeat,
            beatsInOneBar: beatsInOneBar,
            halfBeatsInOneBar: halfBeatsInOneBar,
            barsInPattern: barsInPattern,
            pxPerBeatsGutterInBar: pxPerBeatsGutterInBar,
            pxPerTimestepGutterInBeat: pxPerTimestepGutterInBeat,
            pxPerTimestepGutterInBar: pxPerTimestepGutterInBar,
            pxPerTimestepWithoutGutters: pxPerTimestepWithoutGutters,
            pxPerPitchGutter: pitchStepDomain === "continuous" ? 2 : 4,
            quantizeRes: quantizeRes,
            pxLength: pxLength,
            allowZoom: allowZoom,
            type: gridType,
        }

        newGrid.hasChanged = previousGrid
            ? !ScoreRenderingEngine.gridsAreEqual(newGrid, previousGrid)
            : true

        return newGrid
    }

    static gridsAreEqual(
        grid1: GridDimensions,
        grid2: GridDimensions
    ): boolean {
        const temp1: GridDimensions = cloneDeep(grid1)
        const temp2: GridDimensions = cloneDeep(grid2)

        temp1.hasChanged = false
        temp2.hasChanged = false

        return isEqual(temp1, temp2)
    }

    static contextsAreEqual(
        context1: ScoreCanvasContext,
        context2: ScoreCanvasContext
    ) {
        const temp1 = cloneDeep(context1)
        const temp2 = cloneDeep(context2)

        return isEqual(temp1, temp2)
    }

    static getQuantizeRes({
        resizeFactor,
        timestepRes,
        timeSignature,
    }: {
        resizeFactor: number
        timestepRes: number
        timeSignature: TimeSignature
    }) {
        let quantizeRes = timestepRes

        if (resizeFactor < SHOW_TIMESTEP_GUTTERS_THRESHOLD) {
            quantizeRes = timeSignature[1]
        }

        if (resizeFactor < SHOW_BEAT_GUTTERS_THRESHOLD) {
            quantizeRes = 1
        }

        return quantizeRes
    }

    static isPolyphonicLayer({
        layer,
        selectedNotes,
    }: {
        layer: Layer
        selectedNotes: NotesObject
    }) {
        return ScoreManipulation.isPolyphonicLayer(layer, selectedNotes)
    }

    public resetStores() {
        this.editorViewStore.reset()
        this.scoreRenderingStore.reset()
    }
}
