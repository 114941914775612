import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core'

@Component({
 	selector: 'progress-circle',
  	templateUrl: 'progress-circle.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
  	styleUrls: ['progress-circle.component.scss']
})

export class ProgressCircleComponent implements OnInit {
    @Input() class
    @Input() width:number

    @Input() progress:number
    @Input() total:number

    constructor() {
        
    }

    ngOnInit(): void {
        
    }

    getStyleForDimensions(type=null) {
        if (type == "label") {
            return {
                'width': this.width + "px", 'height': (this.width - 10) + "px"
            }
        }

        return {
            'width': this.width + "px", 'height': this.width + "px"
        }
    }

    getPercentage() {
        return this.progress * 100 / this.total
    }
}