import {
    BrowserModule,
    HAMMER_GESTURE_CONFIG,
    HammerGestureConfig,
    HammerModule,
} from "@angular/platform-browser"
import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { HttpClientModule } from "@angular/common/http"
import { RecaptchaModule, RECAPTCHA_BASE_URL } from "ng-recaptcha"
import { Injectable } from "@angular/core"
import { ColorPickerModule } from "@iplab/ngx-color-picker"
import { NgxSliderModule } from "@angular-slider/ngx-slider"
import * as Hammer from "hammerjs"
import { MatListModule } from "@angular/material/list"
import { SimplebarAngularModule } from "simplebar-angular"
import { ClipboardModule } from "ngx-clipboard"
import { AuthGuard, NegAuthGuard } from "./auth.gard"
import { PlayerService } from "./services/audio/player/player.service"
import { ModalService } from "./services/modal.service"
import { MatInputModule } from "@angular/material/input"
import { MatSelectModule } from "@angular/material/select"
import { MatFormFieldModule } from "@angular/material/form-field"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { DragDropModule } from "@angular/cdk/drag-drop"
import { OverlayModule } from "@angular/cdk/overlay"
import { FileSaverModule } from "ngx-filesaver"
import { PianorollScrollService } from "./services/pianoroll-scroll.service"
import { AnalyticsService } from "./services/analytics.service"
import { CompositionService } from "./services/composition.service"
import { ApiService } from "./services/api.service"
import { DoublesService } from "@services/doubles.service"
import { PinchToZoomDirective } from "./directives/pinchtozoom/pinchtozoom.directive"
import { TooltipDirective } from "./directives/tooltip/tooltip.directive"
import { ModalDirective } from "./directives/modal/modal.directive"
import { TooltipComponent } from "./directives/tooltip/tooltip.component"
import { FolderService } from "./services/folder.service"
import { GeneralService } from "./services/general/general.service"
import { InfluenceService } from "./services/influence.service"
import { BillingService } from "./services/billing.service"
import { GenerationProfileService } from "./services/generation-profile/generationprofile.service"
import { UserService } from "./services/user.service"
import { TokenService } from "./services/token.service"
import { SearchInstrumentPreviewService } from "@services/searchinstrumentpreview.service"
import { TemplateService } from "./services/template.service"
import { AppComponent } from "./components/app.component"
import { ProgressBarComponent } from "./components/reusable/progressbar/progressbar.component"
import { InfluenceComponent } from "./components/create/influence/influence.component"
import { GPLibraryComponent } from "./components/create/gp-library/gp-library.component"
import { GPListItemComponent } from "./components/create/gp-library/gp-list-item/gp-list-item.component"
import { NavbarComponent } from "./components/navbar/navbar.component"
import { PlayerComponent } from "./components/player/player.component"
import { TutorialsComponent } from "./components/tutorials/tutorials.component"
import { PublicPlayerComponent } from "./components/publicplayer/publicplayer.component"
import { LoginComponent } from "./components/login/login.component"
import { WaitingOnBrowserLoginComponent } from "./components/waitingonbrowserlogin/waitingonbrowserlogin.component"
import { NotFoundComponent } from "./components/notfound/notfound.component"
import { BillingComponent } from "./components/billing/billing.component"
import { MyTracksComponent } from "./components/mytracks/mytracks.component"
import { PresetComponent } from "./components/create/preset/preset.component"
import { InstrumentPaletteComponent } from "./components/modals/search/instrument-palette/instrumentpalette.component"
import { ModalsComponent } from "./components/modals/modals.component"
import { EditDoubleModalComponent } from "./components/modals/edit-double/edit-double.component"
import { CreateWithInfluenceComponent } from "./components/modals/create-with-influence/create-with-influence.component"
import { TrackslistComponent } from "./components/trackslist/trackslist.component"
import { DropdownComponent } from "./components/reusable/dropdown/dropdown.component"
import { PackDatasetModalComponent } from "./components/modals/pack-dataset-modal/pack-dataset-modal.component"
import { DropdownWindowComponent } from "./components/reusable/dropdown/dropdown-window/dropdown-window.component"
import { DialComponent } from "./components/reusable/dial/dial.component"
import { CreateWithGPComponent } from "./components/modals/createwithgp/createwithgp.component"
import { ResetComponent } from "./components/reset/reset.component"
import { RadioComponent } from "./components/radio/radio.component"
import { JSONUploaderComponent } from "./components/jsonuploader/jsonuploader.component"
import { GPLayerMixingModalComponent } from "./components/modals/gp-layer-mixing/gp-layer-mixing.component"
import { StepsComponent } from "./components/reusable/steps/steps.component"
import { InstrumentSearchComponent } from "./components/modals/search/instrumentsearch/instrumentsearch.component"
import { DoubleSearchComponent } from "./components/modals/search/doublesearch/doublesearch.component"
import { SamplerSettingsComponent } from "./components/modals/sampler-settings/sampler-settings.component"
import { LayerPrioritiesComponent } from "./components/modals/layer-priorities/layer-priorities.component"
import { RadioButtonComponent } from "./components/reusable/radio-button/radio-button.component"
import { PackComponent } from "./components/reusable/pack/pack.component"
import { IntegerInputFieldComponent } from "./components/reusable/integer-input-field/integer-input-field.component"
import { GPLibraryFiltersComponent } from "./components/modals/gp-library-filters/gp-library-filters.component"
import { TopMenuComponent } from "./components/reusable/topmenu/topmenu.component"
import { ClickOutsideModule } from "ng-click-outside"
import { HTTP_INTERCEPTORS } from "@angular/common/http"
import { TokenInterceptor } from "./services/token.interceptor"

import { RoundProgressModule } from "angular-svg-round-progressbar"

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar"
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar"
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar"

import { DeviceDetectorService } from "ngx-device-detector"
import { NgxFileDropModule } from "ngx-file-drop"

import { CreateAccountComponent } from "./components/createaccount/createaccount.component"
import { MessageService } from "./services/message.service"
import { SurveyService } from "./services/survey.service"
import { StreamingService } from "./services/audio/streaming.service"
import { HelperService } from "@helpers/helper.service"
import { PlaylistsComponent } from "./components/playlists/playlists.component"
import { TracksService } from "./services/tracks.service"
import { PlaylistService } from "./services/playlist.service"
import { GestureService } from "./services/gesture.service"
import { DesignService } from "./services/design.service"
import { AnimationLoopService } from "./services/animationloop.service"
import { FilterPipe } from "@pipes/filter.pipe"
import { ExcludePipe } from "@pipes/exclude.pipe"
import { SlideToggleComponent } from "./components/reusable/slide-toggle/slide-toggle.component"
import { environment } from "../environments/environment"
import { EmotionSelectComponent } from "./components/reusable/emotion-select/emotion-select.component"
import { GenerationProfileComponent } from "./components/generation-profile/generation-profile.component"
import { RecommendationsService } from "./services/recommendations.service"
import { ButtonComponent } from "./components/reusable/button/button.component"
import { RangeComponent } from "./components/reusable/range/range.component"
import { AButtonDirective } from "./directives/a-button/a-button.directive"
import { PackSynchronisationModalComponent } from "./components/modals/pack-synchronisation/pack-synchronisation.component"
import { AutoMixingModalComponent } from "./components/modals/auto-mixing/auto-mixing.component"
import { EditDoubleFolderModalComponent } from "./components/modals/edit-double-folder/edit-double-folder.component"
import { FormTagsSelectionModalComponent } from "./components/modals/form-tags-selection/form-tags-selection.component"
import { ShareGPComponent } from "./components/modals/share-gp/share-gp.component"
import { AccompanimentLayerComponent } from "./components/generation-profile/accompaniment-layer/accompaniment-layer.component"
import { GPDevelopmentComponent } from "./components/modals/gpdevelopment/gpdevelopment.component"
import { GPEmotionSelectionComponent } from "./components/modals/gp-emotion-selection/gp-emotion-selection.component"
import { CreateGPComponent } from "./components/modals/create-gp/create-gp.component"
import { CreateComponent } from "./components/create/create.component"
import { DownloadAllInstrumentsComponent } from "./components/modals/download-all-instruments/download-all-instruments.component"
import { ResumeDownloadAllInstrumentsComponent } from "./components/modals/resume-download-all-instruments/resume-download-all-instruments.component"
import { PublishGPComponent } from "./components/modals/publish-gp/publish-gp.component"
import { PitchRangeControlComponent } from "./components/modals/pitch-range-control/pitch-range-control.component"
import { ContextMenusComponent } from "./components/reusable/context-menus/context-menus.component"
import { AddDoublesComponent } from "./components/reusable/context-menus/add-doubles/add-doubles.component"
import { DoublesSelectionComponent } from "./components/reusable/context-menus/doubles-selection/doubles-selection.component"
import { InstrumentRecommendationsComponent } from "./components/reusable/context-menus/instrument-recommendations/instrument-recommendations.component"
import { CompareTwoGPsComponent } from "./components/modals/compare-two-gps/compare-two-gps.component"
import { RenameProfileCategoryComponent } from "./components/modals/rename-profile-category/rename-profile-category.component"
import { ProgressCircleComponent } from "./components/reusable/progress-circle/progress-circle.component"
import { NotificationsService } from "@services/notifications.service"
import { NotificationsComponent } from "./components/reusable/notifications/notifications.component"
import { PotionComponent } from "./components/potion/potion.component"
import { SampleMapperComponent } from "./components/sample-mapper/sample-mapper.component"
import { AccompanimentFilterComponent } from "./components/reusable/context-menus/accompaniment-filter/accompaniment-filter.component"
import { RewardClaimedComponent } from "./components/modals/reward-claimed/reward-claimed.component"
import { AudioTrimmerComponent } from "./components/reusable/audio-trimmer/audio-trimmer.component"
import { TestComponent } from "./components/test/test.component"
import { AutofillComponent } from "./components/modals/autofill/autofill.component"
import { FindSimilarInstrumentsComponent } from "./components/modals/find-similar-instruments/find-similar-instruments.component"
import { PotionService } from "@services/potion.service"
import { InfluenceDragoverComponent } from "./components/generation-profile/influence-dragover/influence-dragover.component"
import { AudioService } from "@services/audio/audio.service"
import { SelectGPInfluenceComponent } from "./components/modals/select-gp-influence/select-gp-influence.component"
import { ListComponent } from "./components/reusable/list/list.component"
import { AddInstrumentsComponent } from "./components/reusable/context-menus/add-instruments/add-instruments.component"
import { LocalContextMenuComponent } from "./components/reusable/context-menus/local-context-menu/local-context-menu.component"
import { DevelopmentDesignerComponent } from "./components/development-designer/development-designer.component"
import { DevelopmentPreviewComponent } from "./components/development-designer/development-preview/development-preview.component"
import { IconSelectorComponent } from "./components/reusable/icon-selector/icon-selector.component"
import { MaterialVariationsModalComponent } from "./components/modals/material-variations/material-variations.component"
import { AccompanimentDesignerComponent } from "./components/accompaniment-designer/accompaniment-designer.component"
import { FindSimilarInstrumentsFilterComponent } from "./components/reusable/context-menus/find-similar-instruments-filter/find-similar-instruments-filter.component"
import { ScoreCorruptedComponent } from "./components/modals/score-corrupted/score-corrupted.component"
import { RefreshGPSourcePacksComponent } from "./components/modals/refresh-gp-source-packs/refresh-gp-source-packs.component"
import { LoadingStatusService } from "@services/loadingstatus.service"
import { SupportChatService } from "@services/supportchat.service"
import { CreateService } from "./services/create.service"
import { WindowService } from "./services/window.service"
import { EditorComponent } from "./components/editor/editor.component"
import { EditorHttpService } from "@services/editor/editor.http"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { InstrumentsHttpService } from "@services/instruments/instruments.http"
import { TrackBusComponent } from "./components/editor/trackbus/trackbus.component"
import { EditorTopContainerComponent } from "./components/editor/top-container/top-container.component"
import { LayerInspectorComponent } from "./components/editor/layer-inspector/layer-inspector.component"
import { LayerEffectsPanelComponent } from "./components/editor/layer-effects-panel/layer-effects-panel.component"
import { SimpleModalComponent } from "./components/modals/simple-modal/simple-modal.component"
import { AddCustomLayerComponent } from "./components/modals/add-custom-layer/add-custom-layer.component"
import { MenuOptionsComponent } from "./components/reusable/menu-options/menu-options.component"
import { ChangeLayerColorComponent } from "./components/modals/change-layer-color/change-layer-color.component"
import { TrackBusPanelComponent } from "./components/editor/trackbus/trackbus-panel/trackbus-panel.component"
import { SequencerComponent } from "./components/editor/sequencer/sequencer.component"
import { DrumChannelsPanelComponent } from "./components/editor/drum-channels-panel/drum-channels-panel.component"
import { SetConstantAutomationComponent } from "./components/editor/set-constant-automation/set-constant-automation.component"
import { AutomationScaleComponent } from "./components/editor/automation-scale/automation-scale.component"
import { RenameComponent } from "./components/modals/rename/rename.component"
import { MenuModifiersComponent } from "./components/reusable/menu-modifiers/menu-modifiers.component"
import { MixingOptionsComponent } from "./components/modals/mixing-options/mixing-options.component"
import { TrainingsetLabelsComponent } from "./components/modals/trainingset-labels/trainingset-labels.component"
import { UnsupportedOSComponent as UnsupportedOSModal } from "./components/modals/unsupported-os/unsupported-os.component"
import { PreviewsService } from "@services/audio/previews.service"
import { PlayerHttpService } from "@services/audio/player/player.http.service"
import { AccompanimentDesignerHttpService } from "@services/accompaniment-designer/accompaniment-designer.http"
import { PlayHeadComponent } from "./components/reusable/play-head/play-head.component"

import { enableAkitaProdMode } from "@datorama/akita"
import { SourcePackHttpService } from "@services/source-packs/sourcepacks.http"
import { SourcePackService } from "@services/source-packs/sourcepacks.service"
import { ChordDatasetsComponent } from "./components/modals/chord-datasets/chord-datasets.component"
import { GenerationProfileHTTPService } from "@services/generation-profile/generationprofile.http"
import { InstrumentsPreviewService } from "@services/instruments/instrumentsPreview.service"
import { InstrumentsDownloadService } from "@services/instruments/instrumentsDownload.service"
import { CustomizePianorollFXModalComponent } from "./components/modals/customize-pianoroll-fx/customize-pianoroll-fx.component"
import { ShortcutsService } from "@services/shortcuts.service"
import { SettingsModalComponent } from "./components/modals/settings/settings.component"
import { ConfirmDiscardChanges } from "./guards/confirm-discard-changes.guard"
import { InsertSectionComponent } from "./components/modals/insert-section/insert-section.component"
import { SearchBarComponent } from "./components/modals/search/search-bar/search-bar.component"
import { ChangelogModal } from "./components/modals/changelog/changelog.modal"
import { CreateTemplateAccompanimentPackModal } from "./components/modals/create-template-accompaniment-pack/create-template-accompaniment-pack.modal"
import { DoActionBeforeModal } from "./components/modals/do-action-before/do-action-before.modal"
import { ConfirmDiscardChangesService } from "@services/confirm-discard-changes.service"
import { TopMenuWithArrowsComponent } from "./components/reusable/topmenu-with-arrows/top-menu-with-arrows.component"
import { CompositionWorkflowComponent } from "./components/composition-workflow/composition-workflow.component"
import { BtnSelectComponent } from "./components/reusable/btn-select/btn-select.component"
import { CompositionWorkflowStep1Component } from "./components/composition-workflow/composition-workflow-step-1/composition-workflow-step-1.component"
import { CompositionWorkflowStep2Component } from "./components/composition-workflow/composition-workflow-step-2/composition-workflow-step-2.component"
import { CompositionWorkflowStep3Component } from "./components/composition-workflow/composition-workflow-step-3/composition-workflow-step-3.component"
import { CompositionWorkflowLayerComponent } from "./components/composition-workflow/composition-workflow-step-2/composition-workflow-layer/composition-workflow-layer.component"
import { BigPlaybackButton } from "./components/reusable/big-playback-button/big-playback-button.component"
import { SelectLayerModalComponent } from "./components/modals/select-layer-modal/select-layer-modal.component"
import { CWStep2Part1Component } from "./components/composition-workflow/composition-workflow-step-2/step2-part1/cw-step2-part1.component"
import { CWStep2Part2Component } from "./components/composition-workflow/composition-workflow-step-2/step2-part2/cw-step2-part2.component"
import { InstrumentSelectionCompositionWorkflowsModal } from "./components/modals/instrument-selection-composition-workflows/instrument-selection-composition-workflows.component"
import { GPInstrumentSelection } from "./components/modals/gp-instrument-selection/gp-instrument-selection.component"
import { InstrumentDownloadingComponent } from "./components/modals/instrument-downloading/instrument-downloading.component"
import { ResizerComponent } from "./components/reusable/resizer/resizer.component"
import { DropdownButtonComponent } from "./components/reusable/dropdown-button/dropdown-button.component"
import { LoudnessMeterComponent } from "./components/editor/trackbus/trackbus-panel/loudness-meter/loudness-meter.component"
import { MuteAndSoloComponent } from "./components/editor/trackbus/trackbus-panel/mute-and-solo/mute-and-solo.component"
import { SlideToggleWithTitlesComponent } from "./components/reusable/slide-toggle-with-titles/slide-toggle-with-titles.component"
import { CreateWithChordProgression } from "./components/create/create-with-chord-progression/create-with-chord-progression.component"
import { CreateStepbyStepComponent } from "./components/create/create-step-by-step/create-step-by-step.component"
import { GPListComponent } from "./components/create/gp-library/gp-list/gp-list.component"
import { ListItemsComponent } from "./components/reusable/list-items/list-items.component"
import { SearchComponent } from "./components/reusable/search/search.component"
import { PaginationComponent } from "./components/reusable/pagination/pagination.component"
import { SelectGPForCWComponent } from "./components/create/select-gp-for-cw/select-gp-for-cw.component"
import { CWCreateCompositionModal } from "./components/modals/cw-create-composition/cw-create-composition.modal"
import { ContextMenuComponent } from "./components/reusable/context-menu/context-menu.component"
import { MagicButtonComponent } from "./components/reusable/magic-button/magic-button.component"
import { SelectCompositionWorkflowModal } from "./components/modals/select-composition-workflow/select-composition-workflow.component"
import { SuccessModalCopmonent } from "./components/modals/success-modal/success-modal.component"
import { MagicPromptComponent } from "./components/reusable/magic-prompt/magic-prompt.component"
import { LikeBtnComponent } from "./components/reusable/like-btn/like-btn.component"
import { ChordsEditingComponent } from "./components/reusable/chords-editing-component/chords-editing.component"
import { SectionCopyModal } from "./components/modals/section-copy-modal/section-copy-modal.component"
import { SectionResizeModalComponent } from "./components/modals/section-resize-modal/section-resize-modal.component"
import { SetKeySignatureModalComponent } from "./components/modals/set-key-signature/set-key-signature.modal"
import { DesktopAppBugReportModalComponent } from "./components/modals/desktop-app-bug-report-modal/desktop-app-bug-report-modal.component"
import { DesktopAppBugReportService } from "@services/desktop-app-bug-report/desktop-app-bug-report.service"
import { DesktopAppBugReportHttpService } from "@services/desktop-app-bug-report/desktop-app-bug-report.http"
import { SectionInpaintingModal } from "./components/modals/section-inpainting/section-inpainting.component"
import { LayerGenerationModal } from "./components/modals/layer-generation/layer-generation.component"
import { UploadMidiComponent } from "./components/upload-midi/upload-midi.component"

enableAkitaProdMode()

// const obstructed = require('obstructed')

if (!environment.production) {
    // const timer = obstructed(function(ms) {
    // 	// console.log('Main thread was blocked for about ' + ms + 'ms')
    // })
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
    overrides = <any>{
        swipe: { direction: Hammer.DIRECTION_ALL },
    }
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
}

let appRoutes: Routes = [
    {
        path: "login",
        component: LoginComponent,
        canActivate: [NegAuthGuard],
        data: { title: "Login" },
    },

    {
        path: "editor",
        component: EditorComponent,
        canActivate: [AuthGuard],
        data: { title: "Piano Roll Editor" },
    },

    {
        path: "reset/:token",
        component: ResetComponent,
        canActivate: [NegAuthGuard],
        data: { title: "Reset your password" },
    },

    {
        path: "signup/:signup",
        redirectTo: "login/:signup",
    },

    {
        path: "login/:signup",
        component: LoginComponent,
        canActivate: [NegAuthGuard],
        data: { title: "Login" },
    },

    {
        path: "create/:type",
        component: CreateComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "createaccount",
        component: CreateAccountComponent,
        canActivate: [NegAuthGuard],
        data: { title: "Create account" },
    },

    {
        path: "waitingonbrowserlogin/:type",
        component: WaitingOnBrowserLoginComponent,
        canActivate: [NegAuthGuard],
    },

    {
        path: "test",
        component: TestComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "",
        component: MyTracksComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "radio",
        component: RadioComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "mytracks/:type",
        component: MyTracksComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "mytracks/:contentType/:folderID/:compositionID/:view",
        component: MyTracksComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "publicPlayer",
        component: PublicPlayerComponent,
    },

    {
        path: "billing",
        component: BillingComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "tutorials",
        component: TutorialsComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "editor/:compositionID/:contentType",
        component: EditorComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ConfirmDiscardChanges],
    },

    {
        path: "generation-profile-editor/:generationProfileID",
        component: GenerationProfileComponent,
        canActivate: [AuthGuard],
    },

    {
        path: "accompaniment-designer/:generationProfileID/:packID/:layer/:msMode/:createTemplatePack",
        component: AccompanimentDesignerComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ConfirmDiscardChanges],
    },
    {
        path: "composition-workflow/:type/:generationProfileID",
        component: CompositionWorkflowComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ConfirmDiscardChanges],
    },

    {
        path: "composition-workflow/:compositionWorkflowID",
        component: CompositionWorkflowComponent,
        canActivate: [AuthGuard],
        canDeactivate: [ConfirmDiscardChanges],
    },
]

if (!environment.production) {
    appRoutes.push({
        path: "importjson",
        component: JSONUploaderComponent,
        canActivate: [AuthGuard],
        data: { title: "Import JSON" },
    })

    appRoutes.push({
        path: "potion",
        component: PotionComponent,
        canActivate: [AuthGuard],
        data: { title: "Potion" },
    })

    appRoutes.push({
        path: "sample-mapper",
        component: SampleMapperComponent,
        canActivate: [AuthGuard],
        data: { title: "Sample Mapper" },
    })

    appRoutes.push({
        path: "development-designer/:generationProfileID",
        component: DevelopmentDesignerComponent,
        canActivate: [AuthGuard],
    })
}

appRoutes.push({
    path: "**",
    redirectTo: "",
})

@NgModule({
    declarations: [
        AppComponent,
        BtnSelectComponent,
        NavbarComponent,
        PlayerComponent,
        LoginComponent,
        TooltipComponent,
        AutofillComponent,
        ResizerComponent,
        InstrumentSelectionCompositionWorkflowsModal,
        TooltipDirective,
        ModalDirective,
        SettingsModalComponent,
        PinchToZoomDirective,
        NotFoundComponent,
        MyTracksComponent,
        SelectGPForCWComponent,
        ModalsComponent,
        SimpleModalComponent,
        PackSynchronisationModalComponent,
        AddCustomLayerComponent,
        PresetComponent,
        InstrumentPaletteComponent,
        RadioComponent,
        InfluenceComponent,
        JSONUploaderComponent,
        UploadMidiComponent,
        TrackslistComponent,
        ProgressBarComponent,
        CWCreateCompositionModal,
        MenuOptionsComponent,
        SetKeySignatureModalComponent,
        GPLibraryFiltersComponent,
        PackDatasetModalComponent,
        InsertSectionComponent,
        ChangeLayerColorComponent,
        ResetComponent,
        PotionComponent,
        LayerInspectorComponent,
        EditorTopContainerComponent,
        BillingComponent,
        DropdownComponent,
        PublicPlayerComponent,
        TutorialsComponent,
        PackComponent,
        LayerEffectsPanelComponent,
        GPInstrumentSelection,
        CreateWithInfluenceComponent,
        CustomizePianorollFXModalComponent,
        GPLayerMixingModalComponent,
        TrackBusPanelComponent,
        ContextMenusComponent,
        InstrumentDownloadingComponent,
        ContextMenuComponent,
        DropdownWindowComponent,
        RenameProfileCategoryComponent,
        StepsComponent,
        DoActionBeforeModal,
        SectionInpaintingModal,
        DevelopmentPreviewComponent,
        AudioTrimmerComponent,
        MixingOptionsComponent,
        DrumChannelsPanelComponent,
        DropdownButtonComponent,
        AccompanimentLayerComponent,
        DialComponent,
        NotificationsComponent,
        CreateGPComponent,
        RewardClaimedComponent,
        CreateAccountComponent,
        PublishGPComponent,
        ShareGPComponent,
        InfluenceDragoverComponent,
        SearchBarComponent,
        WaitingOnBrowserLoginComponent,
        PlaylistsComponent,
        DoublesSelectionComponent,
        MagicPromptComponent,
        SequencerComponent,
        BigPlaybackButton,
        IntegerInputFieldComponent,
        GPListComponent,
        GPListItemComponent,
        CreateWithChordProgression,
        CreateStepbyStepComponent,
        GPEmotionSelectionComponent,
        SamplerSettingsComponent,
        LayerPrioritiesComponent,
        AutomationScaleComponent,
        DevelopmentDesignerComponent,
        GPDevelopmentComponent,
        SlideToggleWithTitlesComponent,
        SearchComponent,
        ListItemsComponent,
        RadioButtonComponent,
        SlideToggleComponent,
        EditDoubleModalComponent,
        FilterPipe,
        ExcludePipe,
        AButtonDirective,
        ScoreCorruptedComponent,
        FormTagsSelectionModalComponent,
        AutoMixingModalComponent,
        CompareTwoGPsComponent,
        GenerationProfileComponent,
        EditorComponent,
        CreateComponent,
        TopMenuComponent,
        IconSelectorComponent,
        MenuModifiersComponent,
        MuteAndSoloComponent,
        EmotionSelectComponent,
        PaginationComponent,
        CreateTemplateAccompanimentPackModal,
        MagicButtonComponent,
        ChordDatasetsComponent,
        ButtonComponent,
        RangeComponent,
        InstrumentSearchComponent,
        TestComponent,
        SelectGPInfluenceComponent,
        LoudnessMeterComponent,
        ListComponent,
        DoubleSearchComponent,
        GPLibraryComponent,
        RenameComponent,
        LocalContextMenuComponent,
        SelectCompositionWorkflowModal,
        SampleMapperComponent,
        CreateWithGPComponent,
        EditDoubleFolderModalComponent,
        ProgressCircleComponent,
        SelectLayerModalComponent,
        DownloadAllInstrumentsComponent,
        ResumeDownloadAllInstrumentsComponent,
        PitchRangeControlComponent,
        AddDoublesComponent,
        RefreshGPSourcePacksComponent,
        InstrumentRecommendationsComponent,
        MaterialVariationsModalComponent,
        TrackBusComponent,
        AccompanimentFilterComponent,
        ChangelogModal,
        FindSimilarInstrumentsComponent,
        AddInstrumentsComponent,
        SetConstantAutomationComponent,
        AccompanimentDesignerComponent,
        FindSimilarInstrumentsFilterComponent,
        PlayHeadComponent,
        TopMenuWithArrowsComponent,
        CompositionWorkflowStep1Component,
        CompositionWorkflowStep2Component,
        CompositionWorkflowStep3Component,
        CompositionWorkflowComponent,
        CompositionWorkflowLayerComponent,
        CWStep2Part1Component,
        CWStep2Part2Component,
        SuccessModalCopmonent,
        LikeBtnComponent,
        ChordsEditingComponent,
        SectionCopyModal,
        SectionResizeModalComponent,
        DesktopAppBugReportModalComponent,
        TrainingsetLabelsComponent,
        LayerGenerationModal,
        UnsupportedOSModal,
    ],
    imports: [
        ColorPickerModule,
        PerfectScrollbarModule,
        FileSaverModule,
        NgxFileDropModule,
        OverlayModule,
        DragDropModule,
        SimplebarAngularModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        ClickOutsideModule,
        MatListModule,
        NgxSliderModule,
        FormsModule,
        ClipboardModule,
        BrowserModule,
        ReactiveFormsModule,
        RoundProgressModule,
        RecaptchaModule,
        MatInputModule,
        MatSelectModule,

        RouterModule.forRoot(
            appRoutes
            //{ enableTracing: true } // <-- debugging purposes only
        ),

        HttpClientModule,
        HammerModule,
    ],

    providers: [
        AuthGuard,
        NegAuthGuard,
        PlayerService,
        ModalService,
        PotionService,
        SourcePackService,
        SourcePackHttpService,
        ShortcutsService,
        InstrumentsService,
        InstrumentsHttpService,
        GeneralService,
        DoublesService,
        ApiService,
        ConfirmDiscardChangesService,
        InstrumentsDownloadService,
        CreateService,
        SearchInstrumentPreviewService,
        CompositionService,
        NotificationsService,
        FolderService,
        PianorollScrollService,
        InstrumentsPreviewService,
        PreviewsService,
        BillingService,
        UserService,
        HelperService,
        TokenService,
        TemplateService,
        InfluenceService,
        AnalyticsService,
        GenerationProfileHTTPService,
        AnimationLoopService,
        LoadingStatusService,
        AudioService,
        PlayerHttpService,
        SupportChatService,
        MessageService,
        SurveyService,
        StreamingService,
        TracksService,
        PlaylistService,
        GestureService,
        DesignService,
        EditorHttpService,
        AccompanimentDesignerHttpService,
        DeviceDetectorService,
        GenerationProfileService,
        RecommendationsService,
        WindowService,
        DesktopAppBugReportHttpService,
        DesktopAppBugReportService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },

        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
