import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"

import { SimpleModal } from "@services/modal.service"

@Component({
    selector: "simple-modal",
    templateUrl: "simple-modal.component.html",
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleModalComponent implements OnInit {
    @Input() simpleModal:SimpleModal
    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor() {}

    ngOnInit(): void {}

    closeModal() {
        this.close.emit()
    }
}
