<div class="component-container" *ngIf="loading?.finished; else loader">
    <button class="tutorial-button" (click)="initTutorial()">Help?</button>
    <div
        class="error-banner tooltip-error"
        *ngIf="error?.message"
        av-data="cw-error">
        <span av-data="cw-error-text">{{ error?.message }}</span>
        <div class="close-banner" (click)="closeBanner()">x</div>
    </div>
    <div class="top-menu-container">
        <top-menu-with-arrows
            [title]="stepData.title"
            [subtitle]="stepData.subtitle"
            [numberOfSteps]="numberOfSteps"
            [skipIndex]="skipStep2 ? 1 : null"
            [alwaysShowPrevious]="false"
            *ngIf="displayHeader()"
            [currentStepIndex]="stepData.index"
            (currentStep)="setStep($event)"
            [previousFunction]="initStepData.bind(this)"
            [nextFunction]="initStepData.bind(this)">
        </top-menu-with-arrows>
    </div>
    <ng-container *ngIf="showStepComponents; else loader">
        <ng-container *ngIf="stepData.index === 0">
            <composition-workflow-step-1></composition-workflow-step-1>
        </ng-container>

        <ng-container *ngIf="stepData.index === 1">
            <composition-workflow-step-2
                [service]="cwService"></composition-workflow-step-2>
        </ng-container>

        <ng-container *ngIf="stepData.index === 2">
            <composition-workflow-step-3
                [attr.av-data]="'cw-step3'"
                [service]="cwService"></composition-workflow-step-3>
        </ng-container>
    </ng-container>
</div>
<ng-template #loader>
    <ng-container *ngIf="!loading?.finished && !loading?.error">
        <div class="loading">
            <div class="loading-title">Loading your score</div>
            <div class="loader"></div>
        </div>
    </ng-container>
    <ng-container *ngIf="loading?.error">
        <div class="loading">
            <div class="loading-title">
                There was an error loading the composition workflow
            </div>
        </div>
    </ng-container>
</ng-template>
