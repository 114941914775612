import { Injectable } from "@angular/core"
import { ModalService } from "./modal.service"
import SearchItem from "@common-lib/classes/searchitem"
import { Note } from "../../../../common-lib/general/classes/score/note"
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject"
import { Router } from "@angular/router"
import { ContextMenu } from "../models/contextmenu"
import Patch from "@common-lib/classes/score/patch"
import PatchCompatibility from "@common-lib/classes/generationprofiles/patchCompatibility"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import { InstrumentsService } from "./instruments/instruments.service"
import { LayerFunctionType } from "@common-lib/types/score"
import AccompanimentLayer from "@common-lib/classes/generationprofiles/accompaniment/accompanimentlayer"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import Layer from "@common-lib/classes/score/layer"
import Pack from "@common-lib/classes/generationprofiles/pack"

@Injectable()
export class RecommendationsService {
    static PITCH_COMPATIBILITY_VARIANCE = 3

    recommendationsSettings
    recommendationsToApply

    defaultRecommendationsSettings = {
        all: true,
        range: true,
        patch: true,
        percussionFunction: true,
    }

    bassRange = {
        min: 24,
        max: 40,
    }

    accompanimentRange = {
        min: 40,
        max: 72,
    }

    recommendationsChanged: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    )

    constructor(
        private router: Router,
        private instruments: InstrumentsService,
        private modalService: ModalService
    ) {
        this.recommendationsSettings = this.getRecommendationsSettings()
        this.recommendationsToApply = this.getRecommendationsToApply()

        this.saveRecommendationsSettings(this.recommendationsSettings)
    }

    isRecommended(searchItem: SearchItem) {
        if (
            this.recommendationsSettings == null ||
            (this.recommendationsSettings.all != null &&
                this.recommendationsSettings.all == false)
        ) {
            return true
        }

        let allSettingsAreDisabled = true
        let allSettingsRecommended = true

        for (let key in this.recommendationsSettings) {
            if (key == "all") {
                continue
            }

            if (searchItem.recommendations[key] == false) {
                allSettingsRecommended = false
            }

            if (this.recommendationsSettings[key] == true) {
                allSettingsAreDisabled = false
            }
        }

        if (allSettingsAreDisabled || allSettingsRecommended) {
            return true
        }

        return false
    }

    private getTaggedHierarchy({
        data = null,
        hierarchy,
        recommendationsToApply = this.getRecommendationsToApply(),
        pack,
        layer,
    }: {
        data
        hierarchy: SearchItem
        recommendationsToApply
        pack: Pack
        layer: GPLayer
    }) {
        if (hierarchy.values != null && hierarchy.values.length > 0) {
            hierarchy.recommendations = {}
            hierarchy.pitchRange = []

            for (let item of hierarchy.values) {
                let result = this.getTaggedHierarchy({
                    data,
                    hierarchy: item,
                    recommendationsToApply,
                    pack,
                    layer,
                })

                if (hierarchy.pitchRange.length == 0) {
                    hierarchy.pitchRange = result.pitchRange
                } else {
                    hierarchy.pitchRange[0] = Math.min(
                        hierarchy.pitchRange[0],
                        result.pitchRange[0]
                    )
                    hierarchy.pitchRange[1] = Math.max(
                        hierarchy.pitchRange[1],
                        result.pitchRange[1]
                    )
                }

                for (let recommendation in item.recommendations) {
                    if (hierarchy.recommendations[recommendation] == null) {
                        hierarchy.recommendations[recommendation] =
                            item.recommendations[recommendation]
                    } else {
                        hierarchy.recommendations[recommendation] =
                            hierarchy.recommendations[recommendation] ||
                            item.recommendations[recommendation]
                    }
                }
            }

            hierarchy.recommended = this.isRecommended(hierarchy)
        } else {
            hierarchy = this.applyRecommendations({
                data,
                hierarchy,
                recommendationsToApply,
                pack,
                layer,
            })
        }

        return {
            pitchRange: hierarchy.pitchRange,
            recommendations: hierarchy.recommendations,
        }
    }

    getNumberOfRecommendationsApplied(layerType = "pitched") {
        let settings = this.getRecommendationsSettings()

        let nbOfRecommendations = 0

        for (let setting in settings) {
            if (setting == "all") {
                if (!settings.all) {
                    return 0
                }
            } else if (settings[setting]) {
                if (this.router.url.includes("/editor") && setting == "range") {
                    continue
                }

                if (
                    (layerType == "pitched" &&
                        setting == "percussionFunction") ||
                    (layerType != "pitched" && setting != "percussionFunction")
                ) {
                    continue
                }

                nbOfRecommendations += 1
            }
        }

        return nbOfRecommendations
    }

    showRecommendations(event, layer, recommendationsToApply = null) {
        // This is important, to avoid the context menu closing straight away due to clickOutside listening
        event.stopPropagation()

        this.modalService.contextMenus.instrumentRecommendations.next(
            new ContextMenu(
                event.x,
                event.y,
                {
                    layer: layer,
                    recommendationsToApply,
                },
                [],
                null
            )
        )
    }

    public getTaggedInstruments({
        type,
        instruments,
        recommendationsToApply = this.recommendationsToApply,
        layer,
        pack,
    }: {
        type: LayerFunctionType
        instruments: Readonly<SearchItem[]>
        recommendationsToApply?
        layer: GPLayer
        pack?: Pack
    }) {
        const pitchRange = this.getPitchRange(layer, pack)
        const percussionFunction = this.getAccompanimentPercussionFunction(pack)

        for (let i = 0; i < instruments.length; i++) {
            this.getTaggedHierarchy({
                data: { range: pitchRange, type, percussionFunction },
                hierarchy: instruments[i],
                recommendationsToApply,
                pack,
                layer,
            })
        }

        return instruments
    }

    public getTaggedDoubles({
        type,
        doubles,
        recommendationsToApply = this.recommendationsToApply,
        layer,
        pack,
    }: {
        type: "pitched" | "percussion"
        doubles
        recommendationsToApply?
        layer: GPLayer
        pack?: Pack
    }) {
        const pitchRange = this.getPitchRange(layer, pack)
        const percussionFunction = this.getAccompanimentPercussionFunction(pack)

        for (let i = 0; i < doubles.length; i++) {
            this.getTaggedHierarchy({
                data: { range: pitchRange, type, percussionFunction },
                hierarchy: doubles[i],
                recommendationsToApply,
                pack,
                layer,
            })
        }
    }

    private getPitchRange(layer: GPLayer, pack?: Pack) {
        let defaultPitchRange = [Note.lowestNote, Note.highestNote]
        let pitchRange = defaultPitchRange

        if (layer.name === "Melody") {
            pitchRange = this.getMelodyPackPitchRange()
        } else {
            pitchRange = this.getAccompanimentPackPitchRange(pack as AccompanimentPack)
        }

        if (pitchRange == null) {
            pitchRange = defaultPitchRange
        }

        return pitchRange
    }

    private getMelodyPackPitchRange() {
        let lowestNote = 52
        let octaves = 3

        return [lowestNote, lowestNote + 12 * octaves]
    }

    private getAccompanimentPackPitchRange(pack: AccompanimentPack) {
        let packPitchRange

        let lowestNote = pack?.lowestNote
        let octaves = pack?.octaves

        if (lowestNote != null && octaves != null) {
            packPitchRange = [lowestNote, lowestNote + 12 * octaves]
        }

        return packPitchRange
    }

    private getAccompanimentPercussionFunction(pack: Pack) {
        let packPercussionFunction

        if (pack != null && pack instanceof AccompanimentPack && pack.percussionFunction != null) {
            packPercussionFunction = pack.percussionFunction
        }

        return packPercussionFunction
    }

    private getRecommendationsSettings() {
        let recommendationsSettings
        let savedSettings = localStorage.getItem("recommendations")

        if (savedSettings != null && savedSettings != "") {
            recommendationsSettings = Object.assign(
                {},
                JSON.parse(savedSettings)
            )
        }

        this.recommendationsSettings =
            recommendationsSettings || this.defaultRecommendationsSettings

        for (let key in this.defaultRecommendationsSettings) {
            if (this.recommendationsSettings[key] == null) {
                this.recommendationsSettings[key] = true
            }
        }

        return this.recommendationsSettings
    }

    private getRecommendationsToApply() {
        if (this.getRecommendationsSettings() == null) {
            this.recommendationsToApply = []

            return this.recommendationsToApply
        }

        this.recommendationsToApply = Object.keys(
            this.getRecommendationsSettings()
        )

        return this.recommendationsToApply
    }

    saveRecommendationsSettings(settings) {
        localStorage.setItem("recommendations", JSON.stringify(settings))

        this.recommendationsSettings = settings
        this.recommendationsToApply = this.getRecommendationsToApply()

        return settings
    }

    private applyRecommendations({
        data = null,
        hierarchy,
        recommendationsToApply = this.getRecommendationsToApply(),
        pack,
        layer,
    }: {
        data
        hierarchy: SearchItem
        recommendationsToApply
        pack: Pack
        layer: GPLayer
    }) {
        for (let recommendation of recommendationsToApply) {
            if (recommendation == "range") {
                hierarchy = this.applyRangeRecommendation(
                    data,
                    hierarchy,
                    layer
                )
            } else if (recommendation == "patch") {
                hierarchy = this.applyPatchRecommendation(data, hierarchy, pack)
            } else if (recommendation == "percussionFunction") {
                hierarchy = this.applyPercussionFunctionRecommendation({
                    data,
                    hierarchy,
                    pack,
                })
            }
        }

        hierarchy.recommended = this.isRecommended(hierarchy)

        return hierarchy
    }

    private applyRangeRecommendation(
        data = null,
        hierarchy: SearchItem,
        layer: GPLayer
    ) {
        if (layer != null && ["Melody", "Percussion"].includes(layer.name)) {
            hierarchy.recommendations.range = true

            return hierarchy
        }

        if (
            data == null ||
            data.range == null ||
            this.recommendationsSettings.range == null ||
            this.recommendationsSettings.range == false ||
            hierarchy.pitchRange == null ||
            hierarchy.pitchRange.length == 0
        ) {
            hierarchy.recommendations.range = true

            return hierarchy
        }

        hierarchy.recommendations.range =
            data.range[0] >=
                hierarchy.pitchRange[0] -
                    RecommendationsService.PITCH_COMPATIBILITY_VARIANCE &&
            data.range[1] <=
                hierarchy.pitchRange[1] +
                    RecommendationsService.PITCH_COMPATIBILITY_VARIANCE // pack pitch range is a perfect subset of instrument pitch range

        return hierarchy
    }

    private applyPatchRecommendation(
        data,
        hierarchy: SearchItem,
        pack: Pack
    ) {
        if (hierarchy.type != "patch" || data.type != "pitched") {
            hierarchy.recommendations.patch = true

            return hierarchy
        }

        if (
            this.recommendationsSettings.patch == null ||
            this.recommendationsSettings.patch == false
        ) {
            hierarchy.recommendations.patch = true

            if (hierarchy.values != null) {
                for (let searchItem of hierarchy.values) {
                    searchItem.recommendations.patch = true
                }
            }

            return hierarchy
        }

        const patch = hierarchy.item.patches[0].patch

        let result = this.patchCompatbilityWithPack(patch, pack)

        hierarchy.recommendations.patch =
            result.isCompatible && result.slurRecommendation

        return hierarchy
    }

    private applyPercussionFunctionRecommendation({
        data,
        hierarchy,
        pack,
    }: {
        data
        hierarchy: SearchItem
        pack: Pack
    }) {
        if (
            hierarchy.type != "patch" ||
            data.type == "pitched" ||
            this.recommendationsSettings.percussionFunction == null ||
            this.recommendationsSettings.percussionFunction == false
        ) {
            hierarchy.recommendations.percussionFunction = true

            return hierarchy
        }

        const packPercussionFunction =
            this.getAccompanimentPercussionFunction(pack)

        hierarchy.recommendations.percussionFunction =
            packPercussionFunction == null ||
            hierarchy.percussionFunction == null
                ? true
                : packPercussionFunction.some(el =>
                      hierarchy.percussionFunction.includes(el)
                  )

        return hierarchy
    }

    public patchCompatbilityWithPack(patch, pack: Pack) {
        let instrumentObject

        for (let instrument of this.instruments.instruments[patch.section]) {
            if (instrument.name != patch.instrument) {
                continue
            }

            instrumentObject = instrument

            break
        }

        let hasSlur = false
        let compatibleArticulations = []

        for (let p of instrumentObject.patches) {
            if (p.articulation == "slur") {
                hasSlur = true
            }

            if (
                patch.articulation == p.articulation &&
                patch.playing_style == p.playing_style
            ) {
                if (p.compatible_articulation_function == null) {
                    continue
                }

                compatibleArticulations = p.compatible_articulation_function
            }
        }

        let isCompatible = false

        if (pack instanceof AccompanimentPack && pack?.compatibleArticulations) {
            let packCompatibleArticulations = pack.compatibleArticulations

            for (let p of instrumentObject.patches) {
                if (p.articulation == "slur") {
                    hasSlur = true
                }

                if (
                    patch.articulation == p.articulation &&
                    patch.playing_style == p.playing_style
                ) {
                    if (p.compatible_articulation_function == null) {
                        console.warn(
                            "No compatible_articulation_function found for",
                            instrumentObject
                        )
                        continue
                    }

                    compatibleArticulations = p.compatible_articulation_function
                }
            }

            for (let art of compatibleArticulations) {
                if (packCompatibleArticulations.includes(art)) {
                    isCompatible = true
                    break
                }
            }
        } else {
            isCompatible = true
        }

        return {
            isCompatible: isCompatible,
            slurRecommendation:
                patch.playing_style == "slur" ||
                !hasSlur ||
                (hasSlur && !patch.articulation.includes("sus")),
        }
    }

    public recommendationsAreApplied() {
        let apppliedRecommendations = 0
        let recommendationsSettings = this.getRecommendationsSettings()

        if (!recommendationsSettings["all"]) {
            return false
        }

        for (let recommendation in recommendationsSettings) {
            if (recommendationsSettings[recommendation] == true) {
                apppliedRecommendations += 1
            }
        }

        return apppliedRecommendations > 1
    }

    private getValidInstrumentsToCompareSimilarity(
        instruments: SearchItem[]
    ): SearchItem[] {
        let validInstruments = []

        if (
            !instruments?.length ||
            instruments[0]?.item?.patches[0]?.patch == null
        ) {
            return validInstruments
        }

        for (let i = 0; i < instruments.length; i++) {
            let instrument: SearchItem = instruments[i]

            for (let p of instruments[i].item.patches) {
                let patch = new Patch(
                    p.patch.section,
                    p.patch.instrument,
                    p.patch.playing_style,
                    p.patch.articulation,
                    p.patch.granulationEngine
                )

                if (this.getValidPatchToCompareSimilarity(patch)) {
                    validInstruments.push(instrument)
                }
            }
        }

        return validInstruments
    }

    private getInstrumentsMedianSimilarity(
        instruments: SearchItem[],
        sortAsc = true
    ) {
        for (let instrument of instruments) {
            if (
                instrument?.similarityScore != null &&
                Array.isArray(instrument?.similarityScore) &&
                instrument.similarityScore.length
            ) {
                instrument.similarityScore =
                    Math.round(
                        this.getMedian(instrument.similarityScore) * 100
                    ) / 100
            }
        }

        if (sortAsc && instruments.length > 1) {
            // we sort from lowest to highest because lowest means strongest similarity in this case
            instruments = instruments.sort(
                (a, b) => a.similarityScore - b.similarityScore
            )
        }

        return instruments
    }

    private getMedian(numbers: number[]) {
        if (numbers.length == 0) {
            return
        }

        if (numbers.length == 1) {
            return numbers[0]
        }

        const sorted = Array.from(numbers).sort((a, b) => a - b)
        const middle = Math.floor(sorted.length / 2)

        if (sorted.length % 2 === 0) {
            return (sorted[middle - 1] + sorted[middle]) / 2
        }

        return sorted[middle]
    }

    public getCompatibleInstruments({
        pack,
        layer,
        instruments,
    }: {
        pack: Pack
        layer: GPLayer
        instruments: SearchItem[]
    }): SearchItem[] {
        // console.time("getCompatibleInstruments")
        instruments = this.getValidInstrumentsToCompareSimilarity(instruments)

        let similarInstruments = []

        if (!instruments.length) {
            return similarInstruments
        }

        // we set the similarity to null so we always start from scratch when calculating the similarity score median
        let recommendedInstruments = this.getFlattenedListOfTaggedInstruments({
            type: "pitched",
            instruments: this.instruments.instrumentsOrganizedByPath,
            layer,
            pack,
        })

        recommendedInstruments = recommendedInstruments.filter(
            i => i.recommended == true
        )

        const instrumentsWithSimilarityScoreNull =
            this.getInstrumentsWithNullifiedSimilarityScore(
                recommendedInstruments.slice()
            )

        for (let p = 0; p < instruments.length; p++) {
            if (p == 0) {
                similarInstruments = this.getSimilarInstruments(
                    instruments[p],
                    instrumentsWithSimilarityScoreNull
                )
            }

            const instrumentsSimilarToCurrentInstrument =
                this.getSimilarInstruments(instruments[p], similarInstruments)

            similarInstruments = instrumentsSimilarToCurrentInstrument
        }

        similarInstruments =
            this.getInstrumentsMedianSimilarity(similarInstruments)

        similarInstruments =
            this.getInstrumentsCompatibleWithCompiledTechnique(
                similarInstruments,
                pack
            )

        similarInstruments = this.getUniqueInstruments(
            similarInstruments,
            instruments.map(i => i.item.patchID)
        )

        // console.timeEnd("getCompatibleInstruments")

        return similarInstruments
    }

    /**
     * removes duplicate instruments based on the patchID
     * @param instruments SearchItem[]
     * @param patchIDs string[], e.g. [sy.layered2.nat.stac]
     * @returns SearchItem[] that does not contain any duplicate patches
     */
    getUniqueInstruments(instruments: SearchItem[], patchIDs: string[]) {
        let uniqueCompatiblePatches: SearchItem[] = []
        let existingPatches: string[] = []

        for (let i of instruments) {
            let patchID = i.item.patchID

            if (
                !patchIDs.includes(patchID) &&
                !existingPatches.includes(patchID)
            ) {
                uniqueCompatiblePatches.push(i)
                existingPatches.push(patchID)
            }
        }

        return uniqueCompatiblePatches
    }

    getInstrumentsCompatibleWithCompiledTechnique(
        instruments: SearchItem[],
        pack: Pack
    ) {
        const options = {
            exactCT: false,
            echo: true,
            strum: true,
            sideChain: true,
        }
        const compiledTechniques =
            this.getCompatiblePlayingstyleArticulationForPack(pack)

        const packCompatibleCTs = compiledTechniques
        const packCompatiblePlayingStyles = compiledTechniques.map(
            ct => ct.split(".")[0]
        )

        let compatibleInstrumentsByCTs = []

        for (let inst of instruments) {
            const patch = inst.item.patches[0].patch

            let playingStyle = patch.playing_style
            let articulation = patch.articulation
            let ct = playingStyle + "." + articulation

            if (options?.exactCT) {
                if (
                    packCompatibleCTs.includes(ct) ||
                    packCompatibleCTs
                        .map(
                            compiledTechnique => compiledTechnique.split(".")[1]
                        )
                        .includes(articulation)
                ) {
                    compatibleInstrumentsByCTs.push(inst)
                }
            } else {
                if (playingStyle.includes("echo") && options?.echo) {
                    if (packCompatiblePlayingStyles.includes(playingStyle)) {
                        compatibleInstrumentsByCTs.push(inst)
                    }
                } else if (playingStyle.includes("strum") && options?.strum) {
                    if (packCompatiblePlayingStyles.includes(playingStyle)) {
                        compatibleInstrumentsByCTs.push(inst)
                    }
                } else if (playingStyle == "sc-comp" && options?.sideChain) {
                    if (packCompatiblePlayingStyles.includes(playingStyle)) {
                        compatibleInstrumentsByCTs.push(inst)
                    }
                } else {
                    compatibleInstrumentsByCTs.push(inst)
                }
            }
        }

        return compatibleInstrumentsByCTs
    }

    getInstrumentsWithNullifiedSimilarityScore(instruments: SearchItem[]) {
        const instrumentsWithSimilarityScoreNull = []

        for (let inst of instruments) {
            inst.similarityScore = null
            instrumentsWithSimilarityScoreNull.push(inst)
        }

        return instrumentsWithSimilarityScoreNull
    }

    getCompatiblePlayingstyleArticulationForPack(pack: Pack) {
        let total: string[] = []

        for (let instrument of pack.instruments) {
            for (let p of instrument.patches) {
                let patch = new Patch(
                    p.patch.section,
                    p.patch.instrument,
                    p.patch.playing_style,
                    p.patch.articulation,
                    p.patch.granulationEngine
                )
                let ct = patch.playing_style + "." + patch.articulation

                if (!total.includes(ct)) {
                    total.push(ct)
                }
            }
        }

        return total
    }

    /**
     *
     * @param flattenedSearchItems SearchItem[] that includes SearchItems for each instrument patch
     * @returns SearchItem[]
     */
    getCompatibleInstrumentParentFoldersForPack(
        flattenedSearchItems: SearchItem[],
        pack: AccompanimentPack
    ) {
        let packInstruments = pack.instruments

        let folders: string[] = []

        for (let instrument of packInstruments) {
            for (let inst of flattenedSearchItems) {
                if (inst?.item?.patchID == instrument.patchID) {
                    const parentFolder = inst?.path.split("/")[0]

                    if (parentFolder && !folders.includes(parentFolder)) {
                        folders.push(parentFolder)
                    }
                }
            }
        }

        return folders
    }

    getSimilarInstruments(
        instrument: SearchItem,
        instrumentsToCompare: SearchItem[]
    ): Array<{ instrument: SearchItem; score: number }> {
        let similarInstruments = []

        if (!instrument || !instrumentsToCompare?.length) {
            return similarInstruments
        }

        for (let instToCompare of instrumentsToCompare) {
            if (instrument?.item?.patchID == instToCompare?.item?.patchID) {
                continue
            }

            /**
             * For doubles, do this for each patch combination
             *  - top loop is instrument patches, nested loop is instToCompare patches
             *  - for each patch comparison, put the score value in an array so we can have the median on all of them in the last step as the final score
             *  - How to deal with compatibility? One solution could be to only allow patches when every combination is compatible, another could be to only allow if half of them are compatible
             */

            let compatible = true
            let score

            for (let ip = 0; ip < instrument.item.patches.length; ip++) {
                if (compatible == false) {
                    break
                }

                const instPatch = instrument.item.patches[ip].patch

                for (
                    let itc = 0;
                    itc < instToCompare.item.patches.length;
                    itc++
                ) {
                    const instToComparePatch =
                        instToCompare.item.patches[itc].patch
                    const patchCompatibility = this.calculatePatchCompatbility(
                        instPatch,
                        instToComparePatch
                    )

                    if (patchCompatibility.compatible) {
                        if (score == null || !Array.isArray(score)) {
                            score = [patchCompatibility.value]
                        } else {
                            score.push(patchCompatibility.value)
                        }
                    } else {
                        compatible = false
                        break
                    }
                }
            }

            if (compatible) {
                if (Array.isArray(score)) {
                    score = this.getMedian(score)
                }

                if (
                    instToCompare.similarityScore == null ||
                    !Array.isArray(instToCompare.similarityScore)
                ) {
                    instToCompare.similarityScore = [score]
                } else {
                    instToCompare.similarityScore.push(score)
                }

                similarInstruments.push(instToCompare)
            }
        }

        return similarInstruments
    }

    getValidPatchToCompareSimilarity(patch: Patch): {
        instrument: any
        patch: any
    } {
        if (!patch) {
            return
        }

        const section = patch?.section
        const instrumentList = this.instruments.instruments
        const instrument = instrumentList[patch.section]?.find(
            i => i.name == patch.instrument
        )
        const instrumentPatches = instrument?.patches

        if (!instrumentPatches) {
            return
        }

        // First, search for (playing_style, articulation) matches
        for (let ip of instrumentPatches) {
            let p = new Patch(
                section,
                instrument.name,
                ip.playing_style,
                ip.articulation,
                ip.granulationEngine
            )

            let playingStyle = p.playing_style
            let articulation = p.articulation

            if (ip.target_playing_style != null) {
                playingStyle = ip.target_playing_style
            }

            if (
                patch.playing_style == playingStyle &&
                patch.articulation == articulation
            ) {
                return { instrument, patch: ip }
            }
        }

        // If no (playing_style, articulation) match found, traverse patch_keys
        for (let ip of instrumentPatches) {
            let p = new Patch(
                section,
                instrument.name,
                ip.playing_style,
                ip.articulation,
                ip.granulationEngine
            )

            if (
                ip.patch_keys?.length &&
                ip.patch_keys?.includes(p.getFullName())
            ) {
                return { instrument, patch: ip }
            }
        }

        return
    }

    calculatePatchCompatbility(
        patch1: Patch,
        patch2: Patch
    ): PatchCompatibility {
        // console.log(patch1.getFullName(), patch2.getFullName())

        const defaultResult = {
            basePatch: patch1,
            comparedPatch: patch2,
            compatible: false,
            strength: null,
            value: 5,
        }

        if (!patch1 || !patch2) {
            return defaultResult
        }

        let instruments = []

        for (let patch of [patch1, patch2]) {
            let validPatch = this.getValidPatchToCompareSimilarity(patch)

            if (validPatch) {
                instruments.push(validPatch)
            }
        }

        if (instruments.length < 2) {
            return defaultResult
        }

        let patchesMetaData: {
            pcaX: number
            pcaY: number
            pcaZ: number
            dynRange: Array<number>
            pitchRange: Array<number>
        }[] = []

        for (let i of instruments) {
            const instrumentObj = i?.instrument
            const patch = i?.patch

            const pcaX = patch?.pca_X
            const pcaY = patch?.pca_Y
            const pcaZ = patch?.pca_Z
            const dynRange = patch?.dynamic_range
            const pitchRange = [
                instrumentObj?.lowest_note,
                instrumentObj?.highest_note,
            ]

            // console.log(patch.path, patch.playing_style, patch.articulation, {pcaX, pcaY, pcaZ})

            if (
                pcaX != null &&
                pcaY != null &&
                pcaZ != null &&
                dynRange &&
                pitchRange
            ) {
                patchesMetaData.push({
                    pcaX,
                    pcaY,
                    pcaZ,
                    dynRange,
                    pitchRange,
                })
            }
        }

        if (patchesMetaData.length < 2) {
            return defaultResult
        }

        const xDiff = patchesMetaData[1].pcaX - patchesMetaData[0].pcaX
        const yDiff = patchesMetaData[1].pcaY - patchesMetaData[0].pcaY
        const zDiff = patchesMetaData[1].pcaZ - patchesMetaData[0].pcaZ
        const totalDist = Math.sqrt(
            Math.pow(xDiff, 2) + Math.pow(yDiff, 2) + Math.pow(zDiff, 2)
        )

        const orderedSectionLst = [
            "l",
            "sy",
            "e-guitar",
            "ac-guitar",
            "pp",
            "k",
            "s",
            "w",
            "v",
            "b",
        ]
        const sectionRawScore = Math.abs(
            orderedSectionLst.findIndex(s => s == patch1.section) -
                orderedSectionLst.findIndex(s => s == patch2.section)
        )
        const sectionScore =
            Math.floor((sectionRawScore / orderedSectionLst.length) * 100) / 100

        const dynScore = this.calculateRangeScore(
            patchesMetaData[0].dynRange,
            patchesMetaData[1].dynRange
        )
        const dynCompat = this.isRangeCompatible(
            patchesMetaData[0].dynRange,
            patchesMetaData[1].dynRange
        )
        const pitchCompat = this.isRangeCompatible(
            patchesMetaData[0].pitchRange,
            patchesMetaData[1].pitchRange
        )

        // console.log({xDiff, yDiff, zDiff, totalDist, dynScore, dynCompat, pitchCompat})

        const aggScore =
            sectionScore + Math.floor((totalDist / 5) * 100) / 100 + dynScore

        // If the dynamic ranges and pitch ranges are compatible, and the
        // agg_score is < 1.0, the compatibility test is passed:
        const compatible = dynCompat && pitchCompat && aggScore <= 1.0

        // Compatibility strength ~total_dist:
        let strength = null

        if (compatible) {
            strength = aggScore <= 0.5 ? "strong" : "mild"
        }

        const finalScore = Math.floor(aggScore * 100) / 100

        // console.log("calculatePatchCompatbility", patch1, patch2, compatible, strength, finalScore)

        return {
            basePatch: patch1,
            comparedPatch: patch2,
            compatible,
            strength,
            value: finalScore,
        }
    }

    isRangeCompatible(range1: Array<number>, range2: Array<number>) {
        if (!range1 || !range2 || range1.length != 2 || range2.length != 2) {
            return false
        }

        let low = range2
        let high = range1

        if (range1[1] && range2[1] && range1[1] < range2[1]) {
            low = range1
            high = range2
        }

        return low[1] >= high[0]
    }

    calculateRangeScore(range1: number[], range2: number[]) {
        if (!range1 || !range2 || range1.length != 2 || range2.length != 2) {
            return 0
        }

        let low = range2
        let high = range1

        if (range1[1] && range2[1] && range1[1] < range2[1]) {
            low = range1
            high = range2
        }

        const minLevel = Math.min(low[0], high[0])
        const minDiff = Math.abs(high[0] - low[0])
        const maxDiff = Math.abs(high[1] - low[1])

        let result =
            Math.floor((minDiff + maxDiff) / 2) /
            Math.max(high[1] - minLevel, 1)

        return Math.floor(result * 100) / 100
    }

    /**
     * flattens a list of searchItems, so we can iterate over them in a non recursive manner
     * @param type 'pitched' | 'percussion'
     * @param instruments SearchItem[] (with category SearchItems on the top level)
     * @param recommendationsToApply recommendations Object
     * @returns SearchItem[]
     */
    getFlattenedListOfTaggedInstruments({
        type,
        instruments,
        recommendationsToApply = this.recommendationsToApply,
        layer,
        pack,
    }: {
        type: LayerFunctionType
        instruments: Readonly<SearchItem[]>
        recommendationsToApply?
        layer: GPLayer
        pack: Pack
    }): SearchItem[] {
        const taggedInstruments = this.getTaggedInstruments({
            type,
            instruments,
            recommendationsToApply,
            pack,
            layer,
        })

        let flattenedListOfTaggedInstruments: SearchItem[] = []

        for (let instrumentsByCategory of taggedInstruments) {
            flattenedListOfTaggedInstruments =
                flattenedListOfTaggedInstruments.concat(
                    this.getAllSearchItems(instrumentsByCategory)
                )
        }

        return flattenedListOfTaggedInstruments
    }

    getAllSearchItems(searchItem: SearchItem): SearchItem[] {
        let searchItems = []

        if (
            searchItem.item != null &&
            Object.keys(searchItem.item).length > 0 &&
            searchItem.type != "folder"
        ) {
            searchItems = searchItems.concat([searchItem])
        }

        if (searchItem.values != null && searchItem.values.length > 0) {
            for (let item of searchItem.values) {
                searchItems = searchItems.concat(this.getAllSearchItems(item))
            }
        }

        return searchItems
    }
}
