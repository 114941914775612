import { Component, EventEmitter, Input, Output } from "@angular/core"
import { SectionResizeType } from "@common-lib/interfaces/music-engine/general"
import { SectionResizeParameters } from "@services/modal.service"
import { SRActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"

@Component({
    selector: "section-resize-modal",
    templateUrl: "./section-resize-modal.component.html",
    styleUrls: ["./section-resize-modal.component.scss"],
})
export class SectionResizeModalComponent {
    @Output() close: EventEmitter<any> = new EventEmitter()
    @Input() parameters: SectionResizeParameters

    sectionLengthOptions: {
        floor: number
        ceil: any
        step: number
        animate: boolean
    }

    sectionLength: number = 0
    resizeLength = 0

    closeModal() {
        this.close.emit()
    }

    public resizeSection() {
        this.parameters.engine.srEmitter$.next({
            type: SRActionTypes.resizeSection,
            data: {
                section: this.parameters.section,
                length: this.resizeLength,
                type: this.parameters.type,
            },
            options: {
                isUndoable: true,
            },
        })

        this.closeModal()
    }

    ngOnInit() {
        this.sectionLength = Number(
            this.parameters.section.duration.split("/")[0]
        )
        const floor =
            this.parameters.type === SectionResizeType.LEFT
                ? Number(this.parameters.section.start.split("/")[0])
                : this.sectionLength - 2
        const ceil = Number(this.parameters.section.duration.split("/")[0])
        this.sectionLengthOptions = {
            floor: -floor,
            ceil,
            step: 1,
            animate: false,
        }
    }
}
