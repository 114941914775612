import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Host,
    Input,
    Output,
    ViewChild,
} from "@angular/core"
import { ApiService } from "@services/api.service"
import { NgxFileDropEntry } from "ngx-file-drop"
import { InfluenceService } from "@services/influence.service"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { ModalService } from "@services/modal.service"
import { GenerationProfileComponent } from "../generation-profile.component"
import { FileSystemFileEntry } from "ngx-file-drop"
import { AudioTrimmerComponent } from "../../reusable/audio-trimmer/audio-trimmer.component"
import GPInfluenceLoading from "@common-lib/classes/generationprofiles/influences/gpinfluenceloading"
import GPSettings from "@common-lib/classes/generationprofiles/gpsettings"
import { AudioService } from "@services/audio/audio.service"
import { AudioTrimmerConfig } from "../../../types/audioTrimmer"
@Component({
    selector: "influence-dragover",
    templateUrl: "influence-dragover.component.html",
    styleUrls: ["influence-dragover.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfluenceDragoverComponent implements AfterViewInit {
    analysisProgress = 15
    isTrimming = false

    @ViewChild(AudioTrimmerComponent) audioTrimmer: AudioTrimmerComponent

    @ViewChild("dragoverTitle") dragoverTitle: ElementRef
    @ViewChild("dropzone") dropzone: ElementRef
    @ViewChild("dragover") dragover: ElementRef

    @Input() gpComponent: string
    @Output() onDropped = new EventEmitter<boolean>()
    @Output() onDragOrDrop = new EventEmitter<{
        dragOver?: boolean
        dropped?: boolean
        component: string
    }>()

    gpInfluenceLoading: GPInfluenceLoading

    progressMessage: string =
        "Uploading your influence, please don't close your window."

    currentStepIndex = 0

    file: File
    selectedKeySignature
    selectedTimeSignature

    steps = [
        {
            index: 1,
            subtitle: "Select parameters",
        },

        {
            index: 2,
            subtitle: "Trim",
        },

        {
            index: 3,
            subtitle: "Done!",
        },
    ]

    tooltips = {
        keySignature:
            "Our key detection algorithms are 80% accurate, so we only recommend specifying a key signature if you are sure of it. An incorrectly selected key signature can have negative effects.",
    }

    audioTrimmerConfig: AudioTrimmerConfig = {
        minDuration: 60,
        maxDuration: 330,
    }

    constructor(
        @Host() private parent: GenerationProfileComponent,
        private ref: ChangeDetectorRef,
        private modalService: ModalService,
        private apiService: ApiService,
        private influenceService: InfluenceService,
        private gpService: GenerationProfileService
    ) {
        this.apiService.socket.subscribe(socket => {
            socket.on("gpInfluenceUpdate", data => {
                if (data.gpID != this.gpService.generationProfile._id) {
                    return
                }

                console.log(data)

                this.handleGPInfluenceLoadingUpdate(data.gpInfluenceLoading)
            })
        })
    }

    async ngAfterViewInit() {
        /*this.gpService.refreshGenerationProfileUI.subscribe(value => {
			if (value) {
				this.init()
			}
		})*/

        this.gpService.refreshInfluenceDragover.subscribe((value: boolean) => {
            if (value) {
                this.handleGPInfluenceLoadingUpdate(
                    this.gpService.generationProfile.getGPInfluenceLoading(
                        this.gpComponent
                    )
                )

                this.ref.detectChanges()
            }
        })

        this.init()
    }

    handleGPInfluenceLoadingUpdate(data) {
        this.gpInfluenceLoading = GPInfluenceLoading.fromJSON(data)

        if (this.gpInfluenceLoading.error != null) {
            this.currentStepIndex = 4
            this.ref.detectChanges()
        } else if (this.gpInfluenceLoading.progress >= 100) {
            this.cancel()

            if (this.gpComponent.includes("Melody")) {
                this.gpService.getMelodyVisualPreview()
            }
        } else {
            this.setProgress(this.gpInfluenceLoading.progress, false)
        }
    }

    init() {
        this.gpInfluenceLoading =
            this.gpService.generationProfile.getGPInfluenceLoading(
                this.gpComponent
            )

        if (
            this.gpInfluenceLoading.error != null &&
            this.gpInfluenceLoading.error
        ) {
            this.currentStepIndex = 4
        } else if (
            this.gpInfluenceLoading.influenceID &&
            this.gpInfluenceLoading.progress >= this.analysisProgress
        ) {
            this.currentStepIndex = 3
            this.setProgress(this.gpInfluenceLoading.progress, true)
        } else if (this.gpInfluenceLoading.influenceID) {
            this.currentStepIndex = 1

            if (this.gpComponent != "Harmony" || this.file == null) {
                this.uploadGPInfluence()
            }
        } else if (this.gpService.cachedFiles[this.gpComponent] != null) {
            this.currentStepIndex = 1
            this.file = this.gpService.cachedFiles[this.gpComponent]
        } else {
            this.currentStepIndex = 0
            const height =
                this.dragover.nativeElement.offsetHeight / 2 -
                this.dragoverTitle.nativeElement.offsetHeight / 2
            this.dropzone.nativeElement.setAttribute(
                "style",
                "height: " + this.dragover.nativeElement.offsetHeight + "px"
            )
            this.dragoverTitle.nativeElement.setAttribute(
                "style",
                "margin-top: " + height + "px"
            )
        }

        this.ref.detectChanges()
    }

    setProgress(value, force) {
        if (value >= this.analysisProgress) {
            this.progressMessage =
                "Analysing your influence. It's safe to navigate away from this page."
        }

        if (value < this.gpInfluenceLoading && !force) {
            return
        }

        this.gpInfluenceLoading.progress = value

        this.ref.detectChanges()
    }

    getTimeSignatureOptions() {
        let defaultValue = "Automatic detection"

        let options: any = [{ value: null, name: defaultValue }]
        options = options.concat(GPSettings.TIME_SIGNATURE)

        return options
    }

    async uploadGPInfluence() {
        try {
            this.currentStepIndex = 3
            this.setProgress(5, true)

            if (this.file != null) {
                const formData = new FormData()
                formData.append(
                    "influenceSourceFile",
                    this.file,
                    AudioService.removeExtraDotsFromFileName(this.file.name)
                ) // this field will be read by the upload middleware in the creators api
                formData.append(
                    "generationProfileID",
                    this.gpService.generationProfile._id
                )
                formData.append("gpComponent", this.gpComponent)

                if (this.selectedKeySignature) {
                    formData.append("keySignature", this.selectedKeySignature)
                }

                if (this.selectedTimeSignature) {
                    formData.append("timeSignature", this.selectedTimeSignature)
                }

                let response = await this.apiService.authFormRequest(
                    "/gpInfluence/upload",
                    formData
                )

                this.gpInfluenceLoading.influenceID = response.influenceID
                this.gpService.generationProfile.getComponent(
                    this.gpComponent
                ).influenceID = this.gpInfluenceLoading.influenceID

                this.setProgress(this.analysisProgress, false)
            } else {
                await this.apiService.authRequest(
                    "/gpInfluence/selectExisting",
                    {
                        generationProfileID:
                            this.gpService.generationProfile._id,
                        influenceID: this.gpInfluenceLoading.influenceID,
                        gpComponent: this.gpComponent,
                        keySignature: this.selectedKeySignature,
                    },
                    "primary",
                    true
                )

                this.setProgress(33, true)
            }
        } catch (e) {
            this.apiService.handleError(e)
        }

        this.ref.detectChanges()
    }

    next() {
        this.currentStepIndex = 2
        this.ref.detectChanges()
    }

    cancel() {
        this.gpService.generationProfile.resetInfluenceLoading(this.gpComponent)

        this.gpService.cachedFiles[this.gpComponent] = null

        this.gpService.refreshGenerationProfileUI.next(true)
    }

    changeTimeSignature(event) {
        this.selectedTimeSignature = event.new.value
    }

    changeKeySignature(event) {
        this.selectedKeySignature = event.new.value
    }

    async cancelAnalysis() {
        this.cancel()

        try {
            await this.apiService.authRequest(
                "/gpInfluence/cancelAnalysis",
                {
                    influenceID: this.gpInfluenceLoading.influenceID,
                    generationProfileID: this.gpService.generationProfile._id,
                    gpComponent: this.gpComponent,
                },
                "primary",
                true
            )
        } catch (e) {
            this.apiService.handleError(e)
        }
    }

    async trimAndEncode() {
        this.isTrimming = true
        this.ref.detectChanges()

        this.file = await this.audioTrimmer.trimAndEncode()

        this.uploadGPInfluence()
    }

    getKeySignatureOptions() {
        return this.influenceService.getKeySignatureOptions()
    }

    async dropped(files: NgxFileDropEntry[]) {
        // this.onDragOrDrop.emit({ dropped: true, component: this.gpComponent })
        let loading = this.gpService.generationProfile.getGPInfluenceLoading(
            this.gpComponent
        )

        loading.hasDroppedFile = true
        loading.isDraggedOver = false

        try {
            let droppedFile: NgxFileDropEntry = files[0]
            let fileEntry = droppedFile.fileEntry as FileSystemFileEntry

            if (files.length > 1 && files.length < 1) {
                throw new Error("You can only upload one file at a time.")
            }

            let fileType = droppedFile.fileEntry.name.split(".").pop()

            if (!fileEntry.isFile || !this.validFileType(fileType)) {
                throw new Error(
                    "You can only upload an Audio file in mp3 or wav format."
                )
            }

            this.file = await this.influenceService.getFileFromFileEntry(
                fileEntry
            )

            this.currentStepIndex = 1

            this.ref.detectChanges()
        } catch (e) {
            console.log(e)
            this.cancel()

            return this.apiService.handleError(e)
        }
    }

    validFileType(type) {
        let validTypes = ["mp3", "wav"]

        return validTypes.includes(type)
    }

    onAudioTrimmerError(error) {
        this.gpInfluenceLoading.error = error
        this.currentStepIndex = 4
        this.ref.detectChanges()
    }

    onFileOver(event) {
        // this.gpInfluenceLoading.isDraggedOver = true
        // this.onDragOrDrop.emit({ dragOver: true, component: this.gpComponent })
        // this.ref.detectChanges()
    }

    onFileLeave(event) {
        // this.gpInfluenceLoading.isDraggedOver = false
        // this.onDragOrDrop.emit({dragOver: false, component: this.gpComponent})
        // this.ref.detectChanges()
    }

    ngOnDestroy() {}
}
