<div id="tutorials-component">
    <div class="title tutorials-title">Manuals</div>

    <div class="guidelines">
        <div class="tutorial-container">
            <div
                class="thumbnail-container"
                (click)="
                    goTo(
                        'https://aiva.crisp.help/en/article/general-user-manual-44klp4/'
                    )
                ">
                <img src="assets/img/tutorials/usermanual.jpg" />
            </div>
        </div>

        <div class="tutorial-container">
            <div
                class="thumbnail-container"
                (click)="
                    goTo(
                        'https://aiva.crisp.help/en/article/upload-audio-guidelines-lsozs3/'
                    )
                ">
                <img src="assets/img/tutorials/uploadaudioinfluence.jpg" />
            </div>
        </div>

        <div class="tutorial-container">
            <div
                class="thumbnail-container"
                (click)="
                    goTo(
                        'https://aiva.crisp.help/en/article/upload-midi-guidelines-z8bzub/'
                    )
                ">
                <img src="assets/img/tutorials/uploadmidiinfluence.jpg" />
            </div>
        </div>
    </div>

    <div class="title tutorials-title">Video Tutorials</div>

    <div class="video-tutorials">
        <div
            class="tutorial-container"
            (click)="
                goTo(
                    'https://www.youtube.com/watch?v=TNjfxnQRotI&list=PLv7BOfa4CxsHp4uDdsmZgpdclrwkdMpOe'
                )
            ">
            <div class="thumbnail-container">
                <img src="assets/img/tutorials/cw.png" />
            </div>
            <div class="tutorial-subtitle">
                Chord progression generation with Composition Workflows
            </div>
        </div>

        <div
            class="tutorial-container"
            (click)="
                goTo(
                    'https://www.youtube.com/watch?v=M6WhsnWIhcM&list=PLv7BOfa4CxsHp4uDdsmZgpdclrwkdMpOe'
                )
            ">
            <div class="thumbnail-container">
                <img src="assets/img/tutorials/gp_tutorial_1.jpg" />
            </div>
            <div class="tutorial-subtitle">
                How to create a Drum & Bass generation profile
            </div>
        </div>

        <div
            class="tutorial-container"
            (click)="
                goTo(
                    'https://www.youtube.com/watch?v=LbKB4Tr2kHw&list=PLv7BOfa4CxsHp4uDdsmZgpdclrwkdMpOe'
                )
            ">
            <div class="thumbnail-container">
                <img src="assets/img/tutorials/gp_tutorial_2.jpg" />
            </div>
            <div class="tutorial-subtitle">
                How to create a Piano Solo generation profile
            </div>
        </div>

        <div
            class="tutorial-container"
            (click)="goTo('https://www.youtube.com/watch?v=5_eHtnGl6xs')">
            <div class="thumbnail-container">
                <img src="https://cdn.aiva.ai/newsletters/new_pr.jpg" />
            </div>
            <div class="tutorial-subtitle">
                How to use the Piano Roll editor
            </div>
        </div>

        <div
            class="tutorial-container"
            (click)="
                goTo(
                    'https://www.youtube.com/watch?v=HDWvR-Z0jJo&list=PLv7BOfa4CxsHp4uDdsmZgpdclrwkdMpOe&index=2'
                )
            ">
            <div class="thumbnail-container">
                <img src="assets/img/tutorials/uploadinfluence.png" />
            </div>
            <div class="tutorial-subtitle">Upload Influence</div>
        </div>
    </div>
</div>
