<div class="tile layer">
    <div class="title-bar">
        <div class="title">
            {{ layer.getName() }}
            <img
                *ngIf="
                    gpValidation != null &&
                    gpValidation.accompanimentLayers != null &&
                    gpValidation.accompanimentLayers[layer.name] != null
                "
                class="tooltip-img"
                src="assets/img/warning_red.svg"
                [tooltip]="gpValidation.accompanimentLayers[layer.name].message"
                [tooltip-class]="'tooltip-error'"
                [tooltip-marginTop]="10"
                [tooltip-width]="250" />
        </div>
        <div
            class="button-row"
            *ngIf="
                tileHovered == layer.name ||
                (layerOptions != null && layerOptions.layer == layer.name)
            ">
            <a-button
                type="round-icon-and-text"
                class="rounded-square upload-gp-influence"
                [icon]="'assets/img/menu/influences.svg'"
                label="Upload influence"
                (onClick)="selectGPInfluence(layer.name)"></a-button>
            <a-button
                type="round-icon-and-text"
                class="rounded-square layer-options"
                [icon]="'assets/img/more.svg'"
                (onClick)="openLayerOptions($event, layer)"></a-button>
        </div>
    </div>
    <div class="tile-content">
        <div
            class="accompaniment-packs-container"
            [perfectScrollbar]="scrollConfigLayer"
            cdkDropListOrientation="vertical"
            cdkDropList
            (cdkDropListDropped)="drop(layer, layer.packs, $event)">
            <div class="no-pack-container" *ngIf="layer.packs.length == 0">
                <div class="no-pack-title">
                    Such a lonely layer shouldn't exist 😥
                </div>
                <a-button
                    type="text"
                    class="text-button"
                    label="Add accompaniment pack"
                    (onClick)="addPack(layer)"></a-button>
            </div>

            <pack
                class="accompaniment-pack"
                type="accompaniment"
                *ngFor="
                    let pack of layer.packs;
                    let p = index;
                    trackBy: trackByPack
                "
                [pack]="pack"
                [layer]="layer"
                [index]="p"
                (onEditPack)="onEditPack.emit(asAccompanimentPack($event))"
                cdkDrag></pack>
            <div class="smaller-empty-space"></div>
        </div>
    </div>
</div>
