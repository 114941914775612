<div
    class="list-items-wrapper"
    #listItemsWrapper
    [ngClass]="{
        'list-items-one-column': numberOfColumns === 1,
        'list-items-two-columns': numberOfColumns === 2
    }">
    <div
        class="list-item-block"
        *ngFor="let item of items"
        [ngStyle]="{ 'background-color': backgroundColor }"
        [ngClass]="{
            'list-item-block-buttons-larger': shouldShowButton(item),
            'no-play-and-no-primary-button':
                item.play === undefined && item.primaryButton === undefined
        }"
        (mouseenter)="hoverItem(item)"
        (mouseleave)="hoverItem(undefined)">
        <div
            class="list-item-column list-item-icon-column"
            *ngIf="item.play !== undefined">
            <div class="left-icon" (click)="play(item)">
                <div
                    class="small-loader"
                    *ngIf="item.play.status.getValue() === 'loading'"></div>

                <img
                    src="assets/img/player/play-circle-white.svg"
                    *ngIf="item.play.status.getValue() === 'paused'" />

                <img
                    src="assets/img/player/pause-circle.svg"
                    *ngIf="item.play.status.getValue() === 'playing'" />
            </div>
        </div>

        <div class="list-item-column list-item-column-title">
            <input
                class="rename-input"
                *ngIf="itemRename !== undefined && itemRename === item"
                [(ngModel)]="item.name"
                (keydown.enter)="confirmRename(item)"
                (clickOutside)="confirmRename(item)"
                #renameInput />

            <div
                class="title"
                *ngIf="itemRename === undefined || itemRename !== item">
                {{ item.name }}
            </div>

            <div class="list-item-subtitle" *ngIf="item.subtitle !== undefined">
                {{ item.subtitle }}
            </div>
        </div>

        <div class="list-item-column list-item-column-buttons">
            <div
                class="label-item label-data"
                *ngIf="
                    !shouldShowButton(item) &&
                    item.informationPill !== undefined
                "
                [tooltip]="item.informationPill.tooltipText"
                [tooltip-position]="'middle-bottom'"
                [tooltip-marginTop]="10"
                [tooltip-timeout]="1000"
                [attr.av-data]="'select-gp-' + item.name">
                {{ item.informationPill.text }}
                <img
                    *ngIf="item.informationPill.iconPath !== undefined"
                    [src]="item.informationPill.iconPath" />
            </div>

            <div
                *ngIf="shouldShowButton(item)"
                [class]="
                    isTutorialInProgress
                        ? 'label-item create-label bounce'
                        : 'label-item create-label'
                "
                [ngClass]="{
                    blue: item.primaryButton.color === 'blue',
                    green: item.primaryButton.color === 'green'
                }"
                [attr.av-data]="'select-gp-' + item.name"
                (click)="item.primaryButton.onClick(item.data)">
                <img
                    *ngIf="item.primaryButton.iconPath !== undefined"
                    [src]="item.primaryButton.iconPath" />
                {{ item.primaryButton.text }}
            </div>

            <div
                class="label-item more-label"
                (click)="toggleItem($event, item)"
                *ngIf="item.menu !== undefined">
                <img src="assets/img/more.svg" />
            </div>
        </div>
    </div>
</div>
