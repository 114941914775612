import {
    Component,
    OnInit,
    HostListener,
    ChangeDetectorRef,
} from "@angular/core"
import { PlayerService } from "../../services/audio/player/player.service"
import { ModalService } from "../../services/modal.service"
import { Router } from "@angular/router"
import { CompositionService } from "../../services/composition.service"
import { ApiService } from "../../services/api.service"
import { FolderService } from "../../services/folder.service"
import { ShortcutsService } from "../../services/shortcuts.service"
import { ConfirmDiscardChangesService } from "../../services/confirm-discard-changes.service"
import { playerQuery } from "../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { Misc } from "../../../../../common-lib/general/modules/misc"

@Component({
    selector: "app-player",
    templateUrl: "player.component.html",
    styles: [],
})
export class PlayerComponent implements OnInit {
    composition

    durationText: string = "0:00"
    timeElapsedText: string = "0:00"

    volumeControl = 100

    trackName: string = ""

    isPlaying: boolean = false
    isLoading: boolean = false

    progressHovered = false

    progress: number = 0

    isLoggedIn = false
    publicPlayer = false

    loadingProgress: number = 0

    constructor(
        private confirmService: ConfirmDiscardChangesService,
        private playerService: PlayerService,
        private shortcutSevice: ShortcutsService,
        private ref: ChangeDetectorRef,
        private folderService: FolderService,
        private apiService: ApiService,
        private compositionService: CompositionService,
        private router: Router,
        private modalService: ModalService
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        playerQuery.select("offlineAudioLoaded").subscribe(durationLoaded => {
            this.loadingProgress = Math.min(
                100,
                (durationLoaded / playerQuery.duration) * 100
            )
        })

        playerQuery.select("timeElapsed").subscribe(timeElapsed => {
            if (timeElapsed < 0) {
                timeElapsed = 0
            }

            this.timeElapsedText = Misc.convertSecondsToString(timeElapsed)

            if (playerQuery.content) {
                this.progress = (timeElapsed * 100) / playerQuery.duration
            }
        })

        playerQuery.allState$.subscribe(store => {
            if (store.content !== undefined) {
                this.trackName = store.content.name

                var timeElapsed = playerQuery.timeElapsed

                if (timeElapsed < 0) {
                    timeElapsed = 0
                }

                this.durationText = Misc.convertSecondsToString(
                    playerQuery.duration
                )
                this.timeElapsedText = Misc.convertSecondsToString(timeElapsed)

                this.progress = (timeElapsed * 100) / playerQuery.duration

                this.isPlaying = store.status === "playing"
                this.isLoading = store.status === "loading"

                this.composition = store.content
            }
        })

        this.apiService.isLoggedIn.subscribe(value => {
            this.isLoggedIn = value

            this.ref.detectChanges()
        })
    }

    updateVolume(event) {
        this.playerService.setVolumeControl(this.volumeControl)
    }

    showPlayer() {
        return !this.playerService.publicPlayerMode && this.isLoggedIn
    }

    @HostListener("document:keydown", ["$event"])
    async handleKeyboardEvent(event: KeyboardEvent) {
        const tagName = document.activeElement.tagName
        const editorIsLoaded =
            window.location.pathname.includes("editor") ||
            window.location.pathname.includes("accompaniment-designer") ||
            window.location.pathname.includes("composition-workflow")

        if (
            tagName == "INPUT" ||
            tagName == "TEXTAREA" ||
            this.playerService.publicPlayerMode
        ) {
            // DO NOTHING

            return
        }

        // Keyboard shortcuts that are incompatible with the Piano Roll editor
        if (!editorIsLoaded) {
            if (
                event.key == "Home" &&
                this.compositionService.getFirstComposition() != null
            ) {
                var composition = this.compositionService.getFirstComposition()

                this.playerService.loadNewTrack(
                    composition._id,
                    composition.contentType,
                    true,
                    false
                )
            } else if (
                event.key == "End" &&
                this.compositionService.getLastComposition() != null
            ) {
                var composition = this.compositionService.getLastComposition()

                this.playerService.loadNewTrack(
                    composition._id,
                    composition.contentType,
                    true,
                    false
                )
            } else if (event.key == "ArrowDown") {
                this.next()
            } else if (event.key == "ArrowUp") {
                this.previous()
            }
        }

        if (event.key == " " && !this.shortcutSevice.shortcutPrevention()) {
            if (!this.isPlaying && !this.isLoading) {
                await this.play()
            } else {
                await this.pause()
            }

            event.preventDefault()
        }
    }

    like() {
        if (this.composition == null) {
            return
        }

        if (this.composition.liked) {
            this.composition.liked = null
        } else {
            this.composition.liked = true
        }

        this.compositionService.like(
            this.composition._id,
            this.composition.contentType,
            this.composition.liked
        )
    }

    dislike() {
        if (this.composition == null) {
            return
        }

        if (this.composition.liked || this.composition.liked == null) {
            this.composition.liked = false
        } else {
            this.composition.liked = null
        }

        var liked = this.composition.liked

        this.compositionService
            .like(
                this.composition._id,
                this.composition.contentType,
                this.composition.liked
            )
            .then(() => {
                if (liked == false) {
                    this.next()
                }
            })
    }

    play() {
        return this.playerService.play()
    }

    pause() {
        return this.playerService.pause()
    }

    next() {
        return this.playerService.loadNextTrack({})
    }

    previous() {
        return this.playerService.loadPreviousTrack()
    }

    setProgress(event): void {
        this.playerService.setTime(event["offsetX"] / 400)

        this.playerService.autoScrollPianoRoll.next(true)
    }

    switchToMyTracks(): void {
        this.router.navigate(["/"])
    }

    openChat() {
        if (window["$crisp"].is("chat:opened")) {
            window["$crisp"].push(["do", "chat:close"])
        } else {
            window["$crisp"].push(["do", "chat:open"])
        }
    }

    showSwitchToPianoRoll(): boolean {
        var path = window.location.pathname.split("/")
        const composition = playerQuery.content

        if (composition != null && composition.contentType === "radio") {
            return false
        }

        if (
            this.folderService.getContentLength() == 0 ||
            (path != null && path.length > 0 && path[1] == "editor")
        ) {
            return false
        }

        return true
    }

    showSwitchToMyTracks(): boolean {
        const composition = playerQuery.content

        if (composition != null && composition.contentType == "radio") {
            return false
        }

        if (
            this.folderService.getContentLength() == 0 ||
            this.showSwitchToPianoRoll()
        ) {
            return false
        }

        return true
    }

    dissapprove() {
        this.modalService.listeningTestsFeedback.next({
            approve: false,
            composition: this.composition,
        })
    }

    approve() {
        this.modalService.listeningTestsFeedback.next({
            approve: true,
            composition: this.composition,
        })
    }

    deleteCurrentComposition(): void {
        if (playerQuery.content === undefined) {
            return
        }

        var type =
            this.folderService.getContentType() == "Influences"
                ? "influence"
                : "composition"

        this.folderService
            .getContentByID(playerQuery.contentID, type)
            .then(composition => {
                this.modalService.deleteComposition.next(composition)
            })
    }

    listeningTests() {
        return this.apiService.user.getValue() == "listeningtests@aiva.ai"
    }
}
