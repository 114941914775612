<div class="modal-title">Compare 2 GPs</div>

<div class="settings-row">
    <div class="settings-row-title">
        First GP _id
    </div>
    
    <div class="settings-row-value">
        <input placeholder="GP _id" [(ngModel)]="firstGPID">
    </div>
</div>

<div class="settings-row">
    <div class="settings-row-title">
        Second GP _id
    </div>
    
    <div class="settings-row-value">
        <input placeholder="GP _id" [(ngModel)]="secondGPID">
    </div>
</div>

<a-button type="secondary" label="Compare" width="100%" (onClick)="compare()"></a-button>

<div class="report" *ngIf="report != null">
    <div class="settings-row">
        <div class="settings-row-title">
            Final score
        </div>
        
        <div class="settings-row-value">
            {{ report.score }} %
        </div>
    </div>

    <div class="settings-row">
        <div class="settings-row-title">
            Melody
        </div>
        
        <div class="settings-row-value">
            <div *ngFor="let key of dictionaryToArray(report.melody)">{{ key }}: {{ round(report.melody[key] * 100) }} %</div>
        </div>
    </div>

    <div class="settings-row">
        <div class="settings-row-title">
            Harmony
        </div>
        
        <div class="settings-row-value">
            <div *ngFor="let key of dictionaryToArray(report.harmony)">{{ key }}: {{ round(report.harmony[key] * 100) }} %</div>
        </div>
    </div>

    <div class="settings-row">
        <div class="settings-row-title">
            Settings
        </div>
        
        <div class="settings-row-value">
            <div *ngFor="let key of dictionaryToArray(report.settings)">{{ key }}: {{ round(report.settings[key] * 100) }} %</div>
        </div>
    </div>

    <div class="settings-row" *ngFor="let rep of report.accompaniments">
        <div class="settings-row-title">
            {{ rep.layer }} layer
        </div>
        
        <div class="settings-row-value">
            <div>Pack: {{ round(rep.packScore * 100) }} %</div>
            <div>Instruments: {{ round(rep.instrumentScore * 100) }} %</div>
        </div>
    </div>
</div>