<instrument-recommendations
    *ngIf="contextMenus['instrumentRecommendations'] != null"
    [data]="contextMenus['instrumentRecommendations'].data"
    (close)="
        closeContextMenu('instrumentRecommendations')
    "></instrument-recommendations>

<doubles-selection
    *ngIf="contextMenus['doublesSelection'] != null"
    [data]="contextMenus['doublesSelection'].data"
    (close)="closeContextMenu('doublesSelection')"></doubles-selection>

<add-doubles
    *ngIf="contextMenus['addDoubles'] != null"
    [data]="contextMenus['addDoubles'].data"
    (close)="closeContextMenu('addDoubles')"></add-doubles>

<accompaniment-filter
    *ngIf="contextMenus['accompanimentFilter'] != null"
    [data]="contextMenus['accompanimentFilter'].data"
    (close)="closeContextMenu('accompanimentFilter')"></accompaniment-filter>

<find-similar-instruments-filter
    *ngIf="contextMenus['findSimilarInstrumentsFilter'] != null"
    [data]="contextMenus['findSimilarInstrumentsFilter'].data"
    (close)="
        closeContextMenu('findSimilarInstrumentsFilter')
    "></find-similar-instruments-filter>

<add-instruments
    *ngIf="contextMenus['addInstruments'] != null"
    [data]="contextMenus['addInstruments'].data"
    (close)="closeContextMenu('addInstruments')"></add-instruments>

<local-context-menu
    *ngIf="contextMenus['localContextMenu'] != null"
    [data]="contextMenus['localContextMenu']"
    (close)="closeContextMenu('localContextMenu')"></local-context-menu>
