import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { Composition } from "../../../../common-lib/general/classes/general/composition"
import { ApiService } from "./api.service"
import { AnalyticsService } from "./analytics.service"
import { ActivityMetric } from "@common-lib/classes/activitymetric"
import { FolderService } from "./folder.service"
import { FileSaverService } from "ngx-filesaver"
import { GeneralService } from "./general/general.service"
import {
    CompositionDoneCreating,
    CompositionUpdateLoadingStatus,
} from "@common-lib/interfaces/api/sockets"
import { MixingOption } from "@common-lib/types/score"

@Injectable()
export class CompositionService {
    parameters = {}
    influenceParameters = {}
    validDurations = []
    redirectTo = "trackslist"

    currentBlob

    doneCreating$: BehaviorSubject<string> = new BehaviorSubject("")

    constructor(
        protected apiService: ApiService,
        private fileSaverService: FileSaverService,
        private generalService: GeneralService,
        private analyticsService: AnalyticsService,
        private folderService: FolderService
    ) {
        this.apiService.socket.subscribe(socket => {
            if (socket == null) {
                return
            }

            var _this = this

            socket.on(
                "updateLoadingStatus",
                (data: CompositionUpdateLoadingStatus) => {
                    _this.updateLoadingStatus(data)
                }
            )

            socket.on("updatePreset", data => {
                _this.updatePreset(
                    data.compositionID,
                    data.preset,
                    data.ensemble
                )
            })

            socket.on("doneCreating", (data: CompositionDoneCreating) => {
                _this.setCompositionAsFinished(data)
            })
        })
    }

    updateLoadingStatus(data: CompositionUpdateLoadingStatus) {
        var index = this.folderService.getContentIndex(data.compositionID)
        var compositions = this.folderService.content.getValue()

        if (index != -1 && compositions[index] != null) {
            compositions[index].loadingStatus = data.loadingStatus
            compositions[index].failed = data.failed

            this.folderService.content.next(compositions)
        }
    }

    updatePreset(compositionID, preset, ensemble) {
        var index = this.folderService.getContentIndex(compositionID)
        var compositions = this.folderService.content.getValue()

        if (index != -1 && compositions[index] != null) {
            compositions[index].preset = preset
            compositions[index].ensemble = ensemble

            this.folderService.content.next(compositions)
        }
    }

    like(compositionID, contentType, liked) {
        return this.apiService
            .authRequest(
                "/composition/like",
                {
                    compositionID: compositionID,
                    type: contentType,
                    liked: liked,
                },
                "primary",
                true
            )
            .then(res => {
                this.analyticsService.addActivity(ActivityMetric.LIKE, {
                    compositionID: compositionID,
                    type: contentType,
                })
            })
    }

    updateComment(compositionID, comment, bar) {
        return this.folderService
            .getContentByID(compositionID, "composition")
            .then(composition => {
                if (composition.comments == null) {
                    composition.comments = {}
                }

                composition.comments[bar] = comment

                return this.apiService.authRequest(
                    "/composition/updateComment",
                    {
                        compositionID: compositionID,
                        comment: comment,
                        bar: bar,
                    },
                    "primary",
                    true
                )
            })

            .then(res => {
                return Promise.resolve()
            })

            .catch(err => {
                console.error(err)
            })
    }

    updateComposition(compositionID) {
        this.apiService
            .authRequest(
                "/composition/original/update",
                { compositionID: compositionID },
                "primary",
                true
            )
            .then(res => {
                if (res["failed"]) {
                    var index =
                        this.folderService.getContentIndex(compositionID)
                    var compositions = this.folderService.content.getValue()

                    compositions[index]["failed"] = true
                    this.folderService.content.next(compositions)

                    //clearInterval(this.intervals[compositionID])
                } else if (res["isFinished"]) {
                    var index =
                        this.folderService.getContentIndex(compositionID)
                    var compositions = this.folderService.content.getValue()

                    compositions[index].tempo = res.parameters.tempo
                    compositions[index].duration = res.parameters.duration
                    compositions[index].ensemble = res.parameters.ensemble
                    compositions[index].keySignature =
                        res.parameters.keySignature
                    compositions[index].timeSignature =
                        res.parameters.timeSignature
                    compositions[index].isFinished = true
                    compositions[index].wasPlayed = res.wasPlayed

                    this.folderService.content.next(compositions)

                    //clearInterval(this.intervals[compositionID])

                    //this.playNotification()
                } else {
                    var index =
                        this.folderService.getContentIndex(compositionID)
                    var compositions = this.folderService.content.getValue()

                    compositions[index].loadingStatus = res.loadingStatus

                    this.folderService.content.next(compositions)
                }
            })

            .catch(err => {})
    }

    async rename(newName: string, type: string, contentID: string) {
        this.renameComposition(contentID, newName)

        try {
            return this.apiService.authRequest(
                "/content/rename",
                { _id: contentID, newName: newName, type: type },
                "primary",
                true
            )
        } catch (err) {
            this.apiService.handleError(err)
        }
    }

    setCompositionAsFinished(data: CompositionDoneCreating) {
        var index = this.folderService.getContentIndex(data.compositionID)

        if (index == -1) {
            return
        }

        let compositions = this.folderService.content.getValue()

        for (let key in data.parameters) {
            compositions[index][key] = data.parameters[key]
        }

        compositions[index].isFinished = true

        this.folderService.content.next(compositions)
    }

    downloadComposition(compositionID, type, addParamsToFilename) {
        var url = "/composition/download"

        var params = {
            contentID: compositionID,
            addParamsToFilename: addParamsToFilename,
            type: type,
        }

        var extension = ".zip"
        var contentType = "application/zip"

        if (type.includes("midi")) {
            extension = ".mid"
            contentType = "audio/midi"
        } else if (type == "mp3") {
            extension = ".mp3"
            contentType = "audio/mpeg"
        } else if (type == "wav") {
            extension = ".wav"
            contentType = "audio/wav"
        } else if (type == "json") {
            extension = ".json"
            contentType = "application/json"
        }

        const index = this.folderService.getContentIndex(compositionID)
        const composition = this.folderService.getContentAtIndex(index)
        let name = this.folderService.getContentAtIndex(index).name

        if (addParamsToFilename) {
            name +=
                " - " +
                composition.ensemble +
                " - " +
                composition.timeSignature[0] +
                "'" +
                composition.timeSignature[1] +
                " - " +
                composition.keySignature +
                " - " +
                composition.tempo +
                "BPM"
        }

        if (type == "reduced-midi") {
            name += " - Reduced"
        }

        if (type == "orchestrated-midi") {
            name += " - Orchestrated"
        }

        var promise = Promise.resolve()

        if (type == "stems" || type == "wav") {
            promise = this.apiService
                .authRequest(
                    "/composition/downloadWAVFiles",
                    params,
                    "primary",
                    true
                )
                .then(res => {
                    window.location = res.link

                    return Promise.resolve()
                })
        } else {
            promise = this.apiService.authDownload(url, params).then(res => {
                var blob = new Blob([res], { type: contentType })
                this.fileSaverService.save(blob, name + extension)

                return Promise.resolve()
            })
        }

        return promise
            .then(() => {
                return this.folderService.getContentByID(
                    compositionID,
                    "composition"
                )
            })

            .then(composition => {
                if (composition.firstDownload == null) {
                    composition.firstDownload = Date.now()
                }

                return this.generalService.getDownloadsLeft()
            })

            .catch(err => {
                if (err.result == -2) {
                    return Promise.resolve(0)
                } else {
                    this.apiService.handleError(err)
                }
            })
    }

    downloadBugReport(compositionID) {
        var url = "/composition/bugReportDownload"

        var params = {
            compositionID: compositionID,
        }

        var extension = ".zip"
        var contentType = "application/zip"

        return this.apiService
            .authDownload(url, params)
            .then(res => {
                var blob = new Blob([res], { type: contentType })
                console.log(res)
                this.fileSaverService.save(
                    blob,
                    "Report - " + compositionID + extension
                )

                return Promise.resolve()
            })

            .catch(err => {
                if (err.result == -2) {
                    return Promise.resolve(0)
                } else {
                    this.apiService.handleError(err)
                }
            })
    }

    deleteMany(compositionIDs, type) {
        return this.apiService
            .authRequest(
                "/content/deleteMany",
                { contentIDs: compositionIDs, type: type },
                "primary",
                true
            )
            .then(res => {
                this.folderService.removeContentFromMemory(compositionIDs)

                return Promise.resolve()
            })

            .catch(err => {
                this.apiService.handleError(err)
            })
    }

    deleteComposition(compositionID): void {
        this.apiService
            .authRequest(
                "/composition/delete",
                { compositionID: compositionID },
                "primary",
                true
            )
            .then(res => {
                this.folderService.removeContentFromMemory([compositionID])
            })

            .catch(err => {
                this.apiService.handleError(err)
            })
    }

    setCompositionAsLoading(
        compositionID,
        args: {
            exportWAV?
            stemsCreation?
            mixingOption?: MixingOption
        }
    ): Composition | undefined {
        const compositions = this.folderService.content.getValue()
        const comp: Composition | undefined = compositions.find(
            c => c._id === compositionID
        )

        if (!comp) {
            return undefined
        }

        comp.isFinished = false
        comp.failed = false

        for (const arg in args) {
            comp[arg] = args[arg]
        }

        comp.loadingStatus = 5

        this.folderService.content.next(compositions)

        return comp
    }

    renameComposition(compositionID, newName): void {
        var compositions: Composition[] = this.folderService.content.getValue()

        for (var i = 0; i < compositions.length; i++) {
            if (compositions[i]._id == compositionID) {
                compositions[i].name = newName

                break
            }
        }

        this.folderService.content.next(compositions)
    }

    getCompositionName(compositionID, addParamsToFilename): string {
        var name = ""
        var compositions: Composition[] = this.folderService.content.getValue()

        for (var i = 0; i < compositions.length; i++) {
            if (compositions[i]._id == compositionID) {
                if (addParamsToFilename) {
                    name =
                        compositions[i].name +
                        " - " +
                        compositions[i]["ensemble"] +
                        ", " +
                        compositions[i]["timeSignature"][0] +
                        "|" +
                        compositions[i]["timeSignature"][1] +
                        ", " +
                        compositions[i]["keySignature"].substring(0, 255)
                } else {
                    name = compositions[i].name.substring(0, 255)
                }

                break
            }
        }

        return name
    }

    // updates the observable of compositions (is an array) eg. to be correctly displayed in tracklist
    updateCompositionValue(composition) {
        const compositionIndex = this.folderService.getContentIndex(
            composition._id
        )

        var compositions = this.folderService.content.getValue()
        compositions[compositionIndex] = composition

        this.folderService.content.next(compositions)
    }

    removeCollaborator(composition, collaboratorID) {
        for (var i = 0; i < composition.collaborators.length; i++) {
            var collaborator = composition.collaborators[i]

            if (collaborator.userID == collaboratorID) {
                composition = composition.collaborators.splice(i, 1)

                break
            }
        }

        this.updateCompositionValue(composition)
    }

    toggleLinkSharing(shared, composition): Promise<any> {
        return this.apiService
            .authRequest(
                "/composition/share/linkSharing",
                { compositionID: composition._id, shared: shared },
                "primary",
                true
            )
            .then(res => {
                this.updateCompositionValue(composition)

                return Promise.resolve(true)
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve(false)
            })
    }

    checkFeatureCompatibility(compositionID, feature) {
        return this.apiService
            .authRequest(
                "/composition/checkFeatureCompatibility",
                { compositionID: compositionID, feature: feature },
                "primary",
                true
            )
            .then(res => {
                return Promise.resolve(res["isCompatible"])
            })
    }

    regenerateSection(type, sectionIndex, sectionName, compositionID) {
        return this.apiService
            .authRequest(
                "/composition/regenerate",
                {
                    compositionID: compositionID,
                    sectionIndex: sectionIndex,
                    sectionName: sectionName,
                    type: type,
                },
                "primary",
                true
            )

            .then(() => {
                this.setCompositionAsLoading(compositionID, {})

                return Promise.resolve()
            })
    }

    retry(compositionID): Promise<any> {
        const currentComposition =
            this.folderService.getContentInMemoryByID(compositionID)

        return this.apiService
            .authRequest(
                "/composition/original/retry",
                { compositionID: compositionID },
                "primary",
                true
            )
            .then(res => {
                if (res.delete) {
                    this.folderService.removeContentFromMemory([compositionID])

                    var compositions = this.folderService.content.getValue()

                    const composition: Composition = res[
                        "composition"
                    ] as Composition
                    composition.contentType = "composition"

                    compositions.push(composition)

                    this.folderService.content.next(compositions)

                    return Promise.resolve({
                        composition,
                        deleted: true,
                    })
                } else {
                    this.setCompositionAsLoading(compositionID, {})

                    return Promise.resolve({
                        composition:
                            this.folderService.getContentInMemoryByID(
                                compositionID
                            ),
                        deleted: false,
                    })
                }
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve({
                    composition: currentComposition,
                    deleted: false,
                })
            })
    }

    export(type, composition, stemsType = null) {
        return this.apiService
            .authRequest(
                "/composition/export",
                {
                    compositionID: composition._id,
                    type: type,
                    stemsType: stemsType,
                },
                "primary",
                true
            )
            .then(res => {
                if (res.result == -1) {
                    return Promise.resolve({
                        success: true,
                        message: res.message,
                    })
                }

                this.setCompositionAsLoading(composition._id, {
                    exportWAV: res.exportWAV,
                    stemsCreation: res.stemsCreation,
                })

                return Promise.resolve({ success: true })
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve({ success: true })
            })
    }

    getLastComposition() {
        if (
            this.folderService.content.getValue() != null &&
            this.folderService.content.getValue().length > 0
        ) {
            return this.folderService.content.getValue()[
                this.folderService.content.getValue().length - 1
            ]
        }

        return null
    }

    getFirstComposition() {
        if (
            this.folderService.content.getValue() != null &&
            this.folderService.content.getValue().length > 0
        ) {
            return this.folderService.content.getValue()[0]
        }

        return null
    }

    duplicate(compositionIndex) {
        var composition = this.folderService.getContentAtIndex(compositionIndex)

        this.apiService
            .authRequest(
                "/composition/duplicate",
                { compositionID: composition._id },
                "primary",
                true
            )
            .then(res => {
                var compositions = this.folderService.content.getValue()

                res.composition.contentType = "composition"
                compositions.splice(compositionIndex, 0, res.composition)

                this.folderService.content.next(compositions)
            })

            .catch(err => {
                this.apiService.handleError(err)
            })
    }

    downloadChords(compositionID) {
        return this.apiService
            .authLargeDownload(
                "/composition/downloadChords",
                { compositionID: compositionID },
                this.getCompositionName(compositionID, false) + ".txt",
                "chords"
            )

            .then(couldntDownload => {
                if (couldntDownload) {
                    return Promise.resolve(0)
                }

                return this.folderService
                    .getContentByID(compositionID, "composition")
                    .then(composition => {
                        var decrementDownloadsLeft = 0

                        if (composition.firstDownload == null) {
                            composition.firstDownload = Date.now()
                            decrementDownloadsLeft = -1
                        }

                        return Promise.resolve(decrementDownloadsLeft)
                    })
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve(0)
            })
    }

    static getValidDurations(subscription) {
        const freeDurations = [
            "auto",
            "< 0'30",
            "0'30 - 1'00",
            "1'00 - 1'30",
            "1'30 - 2'00",
            "2'00 - 2'30",
            "2'30 - 3'00",
        ]
        const standardDurations = freeDurations.concat([
            "auto",
            "3'00 - 3'30",
            "3'30 - 4'00",
            "4'00 - 4'30",
            "4'30 - 5'00",
        ])
        const proDurations = standardDurations.concat([
            "auto",
            "5'00 - 5'30",
            "5'30 - 6'00",
            "6'00 - 6'30",
            "6'30 - 7'00",
            "7'00 - 7'30",
            "7'30 - 8'00",
        ])

        var durationsCoveredByPlan = freeDurations

        if (
            subscription.plan.includes("standard_monthly") ||
            (subscription.plan.includes("standard_annually") &&
                !subscription.legacyPlan)
        ) {
            durationsCoveredByPlan = standardDurations
        } else if (subscription.plan != "free") {
            durationsCoveredByPlan = proDurations
        }

        return durationsCoveredByPlan
    }
}
