<div class="modal-title" *ngIf="spaceIsAvailable">Download all instruments</div>
<div class="modal-title" *ngIf="!spaceIsAvailable">Not enough space available</div>
<div class="modal-close"><img src="assets/img/close.svg" (click)="closeModal()"></div>

<div class="loading-info" *ngIf="loading && error == null">
    <div class="loader"></div>
    <div>Preparing instrument download. This may take up to a minute.</div>
</div>

<div class="confirm-download" *ngIf="!loading && error == null && spaceIsAvailable && instrumentsStats">
    <img src="assets/img/menu/download_alt.svg">
    <div class="text">
        <div>To download all AIVA instruments, additional <b>{{ instrumentsStats.additionalSpaceNecessary }}GB</b> of space will be needed.</div>
    </div>
    <a-button width="50%" label="Cancel" type="text" class="text-button" (onClick)="closeModal()"></a-button>
    <a-button class="secondary" width="50%" label="Confirm download" (onClick)="confirmDownload()"></a-button>
</div>

<div class="not-enough-space" *ngIf="!loading && error == null && !spaceIsAvailable && instrumentsStats">
    <img src="assets/img/warning.svg">
    <div class="text">
        <div>We are sorry, but there is not enough space left on your machine.</div>
        <div class="continue">Please free up at least <b>{{ instrumentsStats.additionalSpaceNecessary }}GB</b> and try again later.</div>
    </div>
    <a-button class="secondary" width="100%" label="Cancel" (onClick)="closeModal()"></a-button>
</div>

<div class="error" *ngIf="error != null">
    {{ error }}
</div>