import AIVAObject from '../general/aivaobject'
import { Time }from '../../modules/time'
import { AUTOMATION_TIMESTEP_RES } from '../../constants/constants'

export type EffectType = "dynamic" | "high_frequency_cut" | "low_frequency_cut" | "delay" | "reverb" | "auto_staccato" | "auto_phrasing"

export class Effect extends AIVAObject {
    static dynamicCorrespondence = {
        8: "Softest",
        24: "Very Soft",
        40: "Soft",
        56: "Quiet",
        72: "Average", 
        88: "Loud",
        104: "Very Loud",
        120: "Loudest"
    }

    active = true
    automated = true

    name: EffectType
    title = ''
    description = ''

    min = 0
    max = 127
    step = 1

    default = 0

    values:number[] = [] // stores the automation values

    constructor(name:EffectType) {
        super()
        this.name = name

        if (this.name == "dynamic") {
            this.title = "Dynamics"
            this.description = "The Dynamic of a piece is the variation in loudness and timbre of notes played by an accoustic instrument."
            this.min = 0
            this.max = 127
            this.step = 1
            this.default = 72
            this.automated = true
        }

        else if (this.name == "high_frequency_cut") {
            this.title = "High Frequency Cut"
            this.description = "Use to create an intensity build over one, or many sections"
            this.min = 0
            this.max = 3
            this.step = 0.03
            this.automated = true
        }

        else if (this.name == "low_frequency_cut") {
            this.title = "Low Frequency Cut"
            this.description = "Use before a section's end to create anticipation before a drop"
            this.min = 0
            this.max = 3
            this.step = 0.03
            this.automated = true
        }

        else if (this.name == "delay") {
            this.title = "Delay"
            this.min = 0
            this.max = 100
            this.step = 1
            this.automated = true
        }

        else if (this.name == "reverb") {
            this.title = "Reverb"
            this.min = 0
            this.max = 100
            this.step = 1
            this.automated = true
        }

        else if (this.name == "auto_staccato") {
            this.title = "Auto Staccato"
            this.automated = false
        }

        else if (this.name == "auto_phrasing") {
            this.title = "Auto Phrasing"
            this.automated = false
        }
    }

    getValues(scoreLength) {
        const timesteps = Time.fractionToTimesteps(AUTOMATION_TIMESTEP_RES, scoreLength)

        var values = Object.assign([], this.values)
        var lastValue = values[values.length - 1]

        if (values.length - 1 < timesteps) {
            var length = values.length

            for (var i = length; i < timesteps; i++) {
                values.push(lastValue)
            }
        }

        else if (values.length - 1 > timesteps) {
            while (values.length - 1 > timesteps && values.length > 0) {
                values.pop()
            }
        }

        return values
    }

    copyValuesTo(effect:Effect) {
        effect.values = this.values
        effect.active = this.active
    }

    updateAutomation(x, y) {
        if (y == null) {
            if (this.values[x[0]] != x[1]) {
                this.values[x[0]] = x[1]
            
                return true
            }
            
            return false
        }

        var upperLimit = y[0] + 1
        var length = this.values.length

        if (upperLimit > length) {
            upperLimit = length
        }

        var returnValue = false

        for (var d = x[0]; d < upperLimit; d++) {
            const temp = Effect.interpolate(x, y, d)

            if (this.values[d] != temp) {
                returnValue = true
            }

            this.values[d] = temp
        }

        return returnValue
    }

    copy():Effect {
        var newEffect:any = new Effect(this.name)
        var keys = Object.keys(this)

        for (var k = 0; k < keys.length; k++) {
            const key = keys[k]

            if (key == "values") {
                var values = []

                for (var v = 0; v < this.values.length; v++) {
                    values.push(this.values[v])
                }

                newEffect.values = values
            }

            else {
                newEffect[key] = this[key]
            }
        }

        return newEffect
    }

    convertAutomationToPoints() {
		if (this.values == null) {
			return []
		}

		var result = []

		var length = this.values.length

		for (var d = 0; d < length; d++) {
			const x = d

			result.push({
				x: x,
				y: this.values[d]
			})
		}

		return result
    }

    isActive(values, preview) {
        const reducer = (accumulator, currentValue) => accumulator + currentValue

        if (preview || !this.active) {
            return false
        }

        if (this.name == "reverb") {
            return this['ir'] != "None" && values.reduce(reducer) != 0
        }

        if (this.name == "delay") {
            return this['left']['delay_time'] != 0 && this['right']['delay_time'] != 0 && values.reduce(reducer) != 0
        }

        if (this.name == "low_frequency_cut") {
            var active = false

            for (var v = 0; v < values.length; v++) {
                if (values[v].value > 20) {
                    active = true

                    break
                }
            }

            return active
        }

        if (this.name == "high_frequency_cut") {
            var active = false

            for (var v = 0; v < values.length; v++) {
                if (values[v].value < 15000) {
                    active = true

                    break
                }
            }

            return active
        }

        return true
    }

    getAutomationLabel(value) {
        if (this.name == "dynamic" || this.name == "reverb" || this.name == "delay") {
            return Math.round(value) + ''
        }

        else if (this.name == "low_frequency_cut") {
            value = Math.round(20 * Math.pow(10, value)) // 20 refers to 20kHz, the bottom of the audible range.
            
            if (value > 1000) {
                var hundreds:any = value % 1000

                if (hundreds < 10) {
                    hundreds = "00" + hundreds
                }

                else if (hundreds < 100) {
                    hundreds = "0" + hundreds
                }

                return Math.floor(value / 1000) + "." + hundreds + " Hz"
            }

            return value + " Hz"
        }

        else if (this.name == "high_frequency_cut") {
            value = Math.round(20 * Math.pow(10, 3 - value)) // 20 refers to 20kHz, the bottom of the audible range.

            if (value > 1000) {
                var hundreds:any = value % 1000

                if (hundreds < 10) {
                    hundreds = "00" + hundreds
                }

                else if (hundreds < 100) {
                    hundreds = "0" + hundreds
                }

                return Math.floor(value / 1000) + "." + hundreds + " Hz"
            }

            return value + " Hz"
        }

        return ""
    }

    static interpolate(first, second, note) {
        const ya = first[1]
        const yb = second[1]

        if (yb - ya == 0) {
            return ya
        }

        const xa = first[0]
        const xb = second[0]

        return (ya * (xb - note) + yb * (note - xa)) / (xb - xa)
    }

    encode(effectFromScore) {
        this.active = effectFromScore.active
    }

    decode() {
        return {
            active: this.active,
        }
    }
}

export class AutoPhrasing extends Effect {
    view = false

    constructor(name:EffectType) {
        super(name)
    }
}

export class Delay extends Effect {
    left = {
        delay_time: 0
    }

    right = {
        delay_time: 0
    }

    constructor(name:EffectType) {
        super(name)
    }

    encode(effectFromScore) {
        this.active = effectFromScore.active
        this.left.delay_time = effectFromScore.left != null && effectFromScore.left.delay_time != null ? effectFromScore.left.delay_time : 0
        this.right.delay_time = effectFromScore.right != null && effectFromScore.right.delay_time != null ? effectFromScore.right.delay_time : 0
    }

    decode() {
        return {
            active: this.active,
            left: this.left,
            right: this.right
        }
    }

    copyValuesTo(effect:Delay) {
        effect.values = this.values
        effect.active = this.active
        effect.left.delay_time = this.left.delay_time
        effect.right.delay_time = this.right.delay_time
    }
}

export class Reverb extends Effect {
    ir = 'None'
    
    constructor(name:EffectType) {
        super(name)
    }

    encode(effectFromScore) {
        this.active = effectFromScore.active
        this.ir = effectFromScore.ir
    }
    
    decode() {
        return {
            active: this.active,
            ir: this.ir
        }
    }

    copyValuesTo(effect:Reverb) {
        effect.values = this.values
        effect.active = this.active
        effect.ir = this.ir
    }
}