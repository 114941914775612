<div
    class="list-container {{ class }}"
    [style.maxHeight]="maxHeight"
    [perfectScrollbar]="scrollConfig">
    <div
        class="item-container {{ listItem?.type | lowercase }}"
        *ngFor="let listItem of listItems"
        (click)="onItemClick($event, listItem)"
        (mouseenter)="onHover(listItem)"
        (mouseleave)="onHover(null)">
        
        <ng-container [ngSwitch]="listItem?.type">
            <div class="category" *ngSwitchCase="'category'">
                {{ listItem?.name }}
            </div>

            <div class="item" *ngSwitchDefault>
                <span *ngIf="type !== 'mini-pack'" class="loader-container">
                    <div
                        *ngIf="itemLoader && !listItem.isFinished"
                        class="loader"></div>
        
                    <img
                        *ngIf="!itemLoader || (itemLoader && listItem.isFinished)"
                        [src]="listItem.image"
                        class="item-image"
                        (click)="selectImage(listItem)" />
                </span>

                <canvas *ngIf="type === 'mini-pack'" class="mini-pack-canvas" id="canvas-mini-pack-{{ listItem.index }}"></canvas>
        
                <span
                    class="item-title"
                    [ngClass]="{ 'smaller-item-title': listItem === hoveredItem }"
                    *ngIf="renamingItem !== listItem"
                    (dblclick)="renameListItem(listItem)"
                    >{{ listItem.name }}</span
                >
                <input
                    class="rename-input"
                    [(ngModel)]="listItem.name"
                    *ngIf="renamingItem === listItem"
                    #renameInput
                    (keydown.enter)="confirmRename()"
                    (clickOutside)="confirmRename()" />
        
                <div class="menu-button">
                    <a-button
                        *ngIf="listItem === hoveredItem && showMenu(listItem)"
                        type="round-icon-and-text"
                        class="list-item"
                        [icon]="'assets/img/more.svg'"
                        (onClick)="openContextMenu($event, listItem)"></a-button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
