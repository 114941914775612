<div id="radio-component">
    <div class="radio-services grid-container" [perfectScrollbar]="scrollConfig"> 
        <div class="radio-header grid-container">
            <div class="radio-background-header"></div>
        </div>

        <div class="radio-title-container grid-100">
            <div class="radio-component-title">
                <!--<img class="radio-component-title-icon" src="assets/img/menu/radio.svg">-->
                Radio Stations
            </div>

            <div class="radio-component-subtitle" *ngIf="selectedRadio == null">
                Select a station below to listen to long playlists of music.
            </div>

            <div class="radio-component-subtitle" *ngIf="selectedRadio != null">
                <div class="playing-anim-container">
                    <div class="rectangle-1"></div>
                    <div class="rectangle-5"></div>
                    <div class="rectangle-3"></div>
                    <div class="rectangle-1"></div> 
                </div>
                
                <div class="currently-playing">
                    <b>Tuned in to:</b> {{ selectedRadio.name }}<span class="hide-on-mobile"> Radio</span>
                </div>
            </div>
        </div>

        <div class="radio-services-container">
            <div class="desktop-grid-16 grid-20 mobile-grid-25 mobile-grid-30 mobile-grid-50" *ngFor="let radio of radios;">
                <div class="radio-service">
                    <div class="radio-image" [ngStyle]="{'background-color': radio.backgroundColor }" (mouseover)="radio.hover = true" (mouseleave)="radio.hover = false" (click)="playRadio(radio)">
                        <div class="radio-image-background">
                        </div>
                            <div class="radio-service-play" *ngIf="radio.hover">
                                <img src="assets/img/play-circle.svg" class="animate-0_1s" *ngIf="selectedRadio == null || selectedRadio.name != radio.name || !selectedRadio.playing">
                                <img src="assets/img/player/pause-circle.svg" class="animate-0_1s" *ngIf="selectedRadio != null && selectedRadio.name == radio.name && selectedRadio.playing">
                            </div>

                            <div class="radio-service-play" *ngIf="!radio.hover && selectedRadio != null && selectedRadio.name == radio.name && selectedRadio.playing">
                                <img src="assets/img/player/volume_with_black_circle.svg" class="animate-0_1s radio-service-play-volume">
                            </div>

                            <div class="radio-image-text">{{ radio.name }}</div>
                        
                    </div>
                    <div class="radio-title">{{ radio.name }}</div>

                    <div class="playing-anim-container playing-anim-container-radio" *ngIf="radio.playing">
                        <div class="rectangle-1"></div>
                        <div class="rectangle-5"></div>
                        <div class="rectangle-3"></div>
                        <div class="rectangle-1"></div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>