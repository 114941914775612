import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core"
import { DesignService } from "@services/design.service"
import { DropdownItemType } from "../../../types/dropdownItemType"
import { Item } from "../dropdown/dropdown.component"
import { MenuOption } from "../menu-options/menu-options.component"
import { Helpers } from "../../../modules/helpers.module"
import { Misc } from "@common-lib/modules/misc"
import { Option } from "../btn-select/btn-select.component"

export type Modifiers = (
    | DialModifier
    | IncrementModifier
    | CheckboxModifier
    | DropdownModifier
    | DropdownButtonModifier
    | SlideToggleModifier
    | NumberInputModifier
    | BtnSelectModifier
)[]
export interface MenuModifiers {
    options: (
        | DialModifier
        | IncrementModifier
        | CheckboxModifier
        | DropdownModifier
        | DropdownButtonModifier
        | SlideToggleModifier
        | BtnSelectModifier
        | NumberInputModifier
        | MenuOption<any>
    )[]
    coordinates: {
        x: number
        y: number
    }
    buttons?: MenuOption<any>[]
    title?: string

    width?: string
}

export interface NumberInputModifier {
    text: string
    data: number
    type: "number-input"
    dataSetter: (data: number) => number

    min: number
    max: number

    unit: string
    avData: string
}
export interface DropdownButtonModifier {
    text: string
    data: string
    type: "dropdown-button"
    lineHeight: string
    width: string
    dataSetter: (event: MouseEvent) => void
}
export interface SlideToggleModifier {
    text: string
    data: boolean
    type: "slide-toggle"
    dataSetter: (data: boolean) => boolean
    avData?: string
}

export interface BtnSelectModifier {
    text: string
    data: Option
    type: "btn-select"
    dataSetter: (data: Option) => Option
    options: Option[]
    widthPerCell: number
}
export interface IncrementModifier {
    text: string
    data: number
    min: number
    max: number
    type: "increment"
    dataSetter: (data: number) => number
    avData?: string
}

export interface CheckboxModifier {
    text: string
    data: boolean
    type: "checkbox"
    dataSetter: (data: boolean) => boolean
    avData?: string
}

export interface DialModifier {
    text: string
    type: "dial"
    data: number
    dataSetter: (data: number) => number
    avData?: string
}

export interface DropdownModifier {
    text: string
    type: "dropdown"
    data: Item
    options: Item[]
    dataSetter: (item: DropdownItemType<any>) => {
        current: DropdownItemType<any>
        new: DropdownItemType<any>
    }
    subtitle?: string
    tooltip?: string
}

@Component({
    selector: "menu-modifiers",
    templateUrl: "menu-modifiers.component.html",
    styleUrls: ["menu-modifiers.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuModifiersComponent {
    @Output() close: EventEmitter<any> = new EventEmitter()
    @ViewChild("menuModifiers") menuModifiers
    @Input() modifiers: MenuModifiers
    @Input() avData: string

    private finishedInitialising = false

    constructor(
        private design: DesignService,
        private ref: ChangeDetectorRef
    ) {}

    async ngAfterViewInit() {
        const playerBarHeight =
            parseInt(
                this.design
                    .getCSSProperty("player-bar-height")
                    .replace("px", "")
            ) + 2

        await Helpers.waitForElementByID("menu-bgrd")

        const height = (<HTMLDivElement>(
            this.menuModifiers.nativeElement
        )).getBoundingClientRect().height

        const width = (<HTMLDivElement>(
            this.menuModifiers.nativeElement
        )).getBoundingClientRect().width

        if (
            window.innerHeight - playerBarHeight <
            this.modifiers.coordinates.y + height
        ) {
            this.modifiers.coordinates.y =
                window.innerHeight - playerBarHeight - height
        }

        if (window.innerWidth < this.modifiers.coordinates.x + width) {
            this.modifiers.coordinates.x -= width
        }

        this.ref.detectChanges()

        await Misc.wait(0.1)

        this.finishedInitialising = true
    }

    verify(option: IncrementModifier, data) {
        option.data = Math.min(option.max, Math.max(option.min, data))

        option.dataSetter(option.data)
    }

    decrement(option: IncrementModifier) {
        const data = option.data - 1

        this.verify(option, data)
    }

    handleClickOutside(event: MouseEvent) {
        if (this.finishedInitialising === false) {
            return
        }

        this.close.emit()
    }

    increment(option: IncrementModifier) {
        const data = option.data + 1

        this.verify(option, data)
    }

    toggle(option: CheckboxModifier) {
        option.data = !option.data
        option.dataSetter(option.data)
    }

    slideToggle(option: SlideToggleModifier, value: boolean) {
        option.dataSetter(value)
    }

    selectOption(option: DropdownModifier, selectedOption) {
        option.data = selectedOption.new
        option.dataSetter(selectedOption)
    }

    updateValue(event, option: DialModifier) {
        console.log(event)
    }

    btnSelection(option: BtnSelectModifier, selectedOption: Option) {
        option.data = selectedOption
        option.dataSetter(selectedOption)
    }
}
