import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core'
import { GenerationProfileService } from '@services/generation-profile/generationprofile.service'
import GPLayer from '@common-lib/classes/generationprofiles/gplayer'
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar'
import { GeneralService } from '@services/general/general.service'

@Component({
 	selector: 'gp-layer-mixing',
  	templateUrl: 'gp-layer-mixing.component.html',
    styleUrls: ['gp-layer-mixing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class GPLayerMixingModalComponent implements OnInit {
    @Input() layer:GPLayer
    @Output() close: EventEmitter<any> = new EventEmitter()

    delayGrades = [
        { value: "1/48", index: 0, name: "48th note" },
        { value: "1/32", index: 1, name: "32nd note" },
        { value: "1/24", index: 2, name: "24th note" },
        { value: "1/16", index: 3, name: "16th note" },
        { value: "3/32", index: 4, name: "Dotted 16th note" },
        { value: "1/12", index: 5, name: "12th note" },
        { value: "1/8", index: 6, name: "8th note" },
        { value: "3/16", index: 7, name: "Dotted 8th note" },
        { value: "1/6", index: 8, name: "6th note" },
        { value: "1/4", index: 9, name: "4th note" },
        { value: "3/8", index: 10, name: "Dotted 4th note" },
        { value: "1/3", index: 11, name: "3rd note" },
        { value: "1/2", index: 12, name: "Half note" },
        { value: "3/4", index: 13, name: "Dotted half note" },
        { value: "1/1", index: 14, name: "Whole note" }
    ]

    show = "none"

    tooltips = {
        lfc: "",
        hfc: "",
        fixedOrchestration: "Enable to keep the instrumentation constant throughout a composition. Disable to allow AIVA to change the instrumentation throughout a composition."
    }

    allowPitchRangeControl = false

    scrollChange = { useBothWheelAxes: false, suppressScrollX: true }
    @ViewChild(PerfectScrollbarDirective, { static: false }) perfectscroll: PerfectScrollbarDirective

    constructor(private general:GeneralService, private gpService:GenerationProfileService, private ref:ChangeDetectorRef) {

    }

    ngOnInit(): void {
        this.validateFilterValues("lfc", this.layer.mixing.lfc)
        this.validateFilterValues("hfx", this.layer.mixing.hfc)
        
        this.allowPitchRangeControl = this.shouldAllowPitchRangeControl()
    }

    setGPAsUpdated(updateType) {
        this.gpService.setAsUpdated(updateType)
    }

    getImpulseResponses() {
        let IRs = this.general.getImpulseResponses()

        let result = []

        for (let ir of IRs) {
            result.push({
                value: ir,
                name: ir
            })
        }

        return result
    }

    trackByPack(pack) {
        return pack.id
    }

    selectIR(event) {
        this.layer.mixing.reverb.ir = event.new.value

        this.ref.detectChanges()
        this.gpService.setAsUpdated("selectIR")
    }

    closeModal() {
        this.close.emit()
    }

    getIRValue() {
        return {
            value: this.layer.mixing.reverb.ir,
            name: this.layer.mixing.reverb.ir
        }
    }

    getDelayIndex(type) {
        for (let grade of this.delayGrades) {
            if (grade.value == this.layer.mixing.delay[type]) {
                return grade.index
            }
        }

        return 0
    }

    updateDialControlledVariable(event, direction) {
        if (direction == "delayLeft") {
            this.layer.mixing.delay.leftTime = this.delayGrades[event.value].value
        }

        if (direction == "delayRight") {
            this.layer.mixing.delay.rightTime = this.delayGrades[event.value].value
        }

        this.gpService.setAsUpdated("updateDial")
    }

    updateGain(event) {
        this.layer.mixing.gainBias = event
        this.setGPAsUpdated("updateGain")
    }

    updateReverb(event) {
        this.layer.mixing.reverb.wetness = event
        this.setGPAsUpdated("updateReverb")
    }

    updateDelay(event) {
        this.layer.mixing.delay.amount = event
        this.setGPAsUpdated("updateDelay")
    }

    validateFilterValues(type, event) {
        if (type == "lfc") {
            this.layer.mixing.lfc = event

            if (this.layer.mixing.hfc - 200 < this.layer.mixing.lfc) {
                this.layer.mixing.hfc = Math.min(20000, this.layer.mixing.lfc + 200)
            }
        }

        else if (type == "hfc") {
            this.layer.mixing.hfc = event

            if (this.layer.mixing.lfc > this.layer.mixing.hfc - 200) {
                this.layer.mixing.lfc = Math.max(0, this.layer.mixing.hfc - 200)
            }
        }


        if (this.layer.mixing.lfc >= 1000) {
            let preprocessedLFC = Math.floor(this.layer.mixing.lfc / 100) / 10

            this.tooltips.lfc = "Filters out frequencies below " + preprocessedLFC + " kHz"
        }

        else {
            this.tooltips.lfc = "Filters out frequencies below " + this.layer.mixing.lfc + " Hz"
        }

        if (this.layer.mixing.hfc >= 1000) {
            let preprocessedHFC = Math.floor(this.layer.mixing.hfc / 100) / 10

            this.tooltips.hfc = "Filters out frequencies above " + preprocessedHFC + " kHz"
        }

        else {
            this.tooltips.hfc = "Filters out frequencies above " + this.layer.mixing.hfc + " Hz"
        }
     
        this.gpService.setAsUpdated("updateFilter")
    }

    toggleSettingsRow(type) {
        if (this.show == type) {
            this.show = "none"
        }

        else {
            this.show = type
        }
    }

    shouldAllowPitchRangeControl(){
        if(this.layer == null){
            return
        }

        const layerTypeIsValid = this.layer.name.includes("Chords") || this.layer.name.includes("Extra") || this.layer.name.includes("Ornaments") || this.layer.name.includes("Bass")
        const layerHasValidPacks = this.layer.packs.find(p => p.octaves != null) != null 

        return layerTypeIsValid && layerHasValidPacks
    }
}