import { ImmutableNote, Note } from "../classes/score/note"
import Score from "../classes/score/score"
import { Time } from "../modules/time"
import { Time as Time2 } from "../modules/time2"
import {
    FractionString,
    LayerFunctionType,
    TimeSignature,
} from "../types/score"
import EditingState from "../classes/score/editingstate"
import { Quantization } from "../types/general"
import {
    ALLOWED_NOTE_RESOLUTIONS,
    AUTOMATION_TIMESTEP_RES,
    DEFAULT_PERCUSSION_INSTRUMENT,
    HIGHEST_PIANO_PITCH,
    LayerType,
    LOWEST_PIANO_PITCH,
    NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS,
    SECTION_EDITING,
    TIMESTEP_RES,
} from "../constants/constants"
import { Pattern } from "../classes/score/pattern"
import Layer from "../classes/score/layer"
import PercussionLayer from "../classes/score/percussionlayer"
import { ChordManipulation } from "./chord-manipulation.module"
import {
    TemplateChord,
    TemplateKeySignature,
    TemplateNote,
    TemplateScore,
    TemplateSections,
    TemplateSustainPedal,
    TemplateTempoMap,
} from "../interfaces/score/templateScore"
import { cloneDeep } from "lodash"
import {
    NoteGroupAndSurroundings,
    NotesObject,
} from "../classes/score/notesObject"
import NotesClipboard from "../classes/score/notesclipboard"
import { Effect, EffectType } from "../classes/score/effect"
import { QuantizationThresholds, RangeWithID } from "../interfaces/general"
import TrackBus from "../classes/score/trackbus"
import {
    HoveringType,
    HoveringTypeEnum,
    PatternHoveringType,
} from "../types/general"
import { NoteEditingClipboard } from "../types/note-editing"
import { v4 as uuidv4 } from "uuid"
import { InstrumentJSON, InstrumentsJSON } from "../interfaces/score/general"
import Patch from "../classes/score/patch"
import Section from "../classes/score/section"
import PatternRegion from "../classes/score/patternregion"
import SectionOperation from "../classes/score/operation"
import Tempo from "../classes/score/tempo"
import { ScoreEncoding } from "./score-transformers/scoreEncoding"
import { KeySignatureModule } from "./keysignature.module"
import { Misc } from "./misc"
import { SHORT_SCALES_TO_SCALES_MAP } from "../utils/composition-workflow.util"
import { SectionResizeType } from "../interfaces/music-engine/general"
import {
    CompositionWorkflowLayer,
    CompositionWorkflowNote,
} from "../interfaces/composition-workflow.interface"
import { CWKeyMode } from "../interfaces/composition-workflow.interface"
import { Fraction } from "../classes/score/fraction"
import { featureFlags } from "../utils/feature-flags"
import {
    SegmentedScore,
    SegmentedScorePercussionChannel,
    SegmentedScorePercussionLayer,
    SegmentedScoreSection,
} from "../interfaces/score/segmentedscore"
import { SegmentedScoreManipulationModule } from "./score-transformers/segmented-score-manipulation.module"
import SamplesMap from "../interfaces/score/samplesMap"

export module ScoreManipulation {
    /**
     * This function mutates the provided score and removes all notes and pattern regions
     * for a given section, but doesn't delete the section itself.
     * @param section
     * @param score
     * @returns a list of note ids that were deleted
     */
    export function removeNotesForSection(
        section: Section,
        score: Score
    ): string[] {
        const percussionLayers = Object.keys(score.layers)
            .filter(l => score.layers[l].type === "percussion")
            .map(l => {
                return score.layers[l]
            })

        const pitchedLayers = Object.keys(score.layers)
            .filter(l => score.layers[l].type === "pitched")
            .map(l => {
                return score.layers[l]
            })

        const notesToRemove = deleteNotesWithinTimeRange({
            score,
            layers: pitchedLayers,
            start: section.start,
            duration: section.duration,
        })

        percussionLayers.forEach((layer: PercussionLayer) => {
            layer.deletePatternRegionsWithinTimeRange(
                section.start,
                section.duration,
                score.firstTimeSignature
            )
        })

        return notesToRemove
    }

    export function copySectionChords(
        score: Score,
        from: Section,
        to: Section
    ) {
        // Find the start/end indexes for the sections
        const chordsFromIndexRange = findChordsIndexRangeForSection(score, from)
        const chordsToIndexRange = findChordsIndexRangeForSection(score, to)

        // find the chords/romanNumerals in the range
        const chordsFrom = score.chords
            .slice(chordsFromIndexRange[0], chordsFromIndexRange[1])
            .map(chord => [chord[0], chord[1]])

        const romanNumeralsFrom = score.romanNumerals
            .slice(chordsFromIndexRange[0], chordsFromIndexRange[1])
            .map(rn => [rn[0], rn[1]])

        const chordsTo = score.chords.slice(
            chordsToIndexRange[0],
            chordsToIndexRange[1]
        )

        const romanNumeralsTo = score.romanNumerals.slice(
            chordsToIndexRange[0],
            chordsToIndexRange[1]
        )

        const durationComparisonResult = Time.compareTwoFractions(
            from.duration,
            to.duration
        )
        // mapping to create new instances of the chords and romanNumerals
        let chordsToInsert = chordsFrom.map(c => [c[0], c[1]])
        let romanNumeralsToInsert = romanNumeralsFrom.map(rn => [rn[0], rn[1]])

        if (durationComparisonResult === "lt") {
            let durationSum = from.duration
            while (
                Time.compareTwoFractions(durationSum, to.duration) !== "eq"
            ) {
                for (let i = 0; i < chordsFrom.length; i++) {
                    chordsToInsert.push([chordsFrom[i][0], chordsFrom[i][1]])
                    romanNumeralsToInsert.push([
                        romanNumeralsFrom[i][0],
                        romanNumeralsFrom[i][1],
                    ])
                    durationSum = Time.addTwoFractions(
                        durationSum,
                        chordsFrom[i][0]
                    )
                    if (
                        Time.compareTwoFractions(durationSum, to.duration) ===
                        "eq"
                    ) {
                        break
                    }
                }
            }
        } else if (durationComparisonResult === "gt") {
            let durationSum = "0"
            chordsToInsert = []
            romanNumeralsToInsert = []
            for (let i = 0; i < chordsFrom.length; i++) {
                durationSum = Time.addTwoFractions(
                    durationSum,
                    chordsFrom[i][0]
                )
                chordsToInsert.push([chordsFrom[i][0], chordsFrom[i][1]])
                romanNumeralsToInsert.push([
                    romanNumeralsFrom[i][0],
                    romanNumeralsFrom[i][1],
                ])
                if (
                    Time.compareTwoFractions(durationSum, to.duration) === "eq"
                ) {
                    break
                }
            }
        }

        score.chords.splice(
            chordsToIndexRange[0],
            chordsTo.length,
            ...chordsToInsert
        )
        score.romanNumerals.splice(
            chordsToIndexRange[0],
            romanNumeralsTo.length,
            ...(romanNumeralsToInsert as any)
        )

        return score
    }

    export function resizeSection(
        score: Score,
        section: Section,
        length: number,
        type: SectionResizeType
    ): Score {
        const timeSignature = `${score.timeSignatures[0][1][0]}/${score.timeSignatures[0][1][1]}`
        let deltaLengthInFractions = Time.multiplyFractionWithNumber(
            timeSignature,
            Math.abs(length)
        )

        deltaLengthInFractions = Time.quantizeFractionToBar(
            score.timeSignatures[0][1],
            deltaLengthInFractions
        )

        let sectionIndex = score.sections.findIndex(s => s === section)
        if (type === SectionResizeType.LEFT) {
            section.duration = Time.addTwoFractions(
                section.duration,
                deltaLengthInFractions,
                length > 0
            )
            section.start = Time.addTwoFractions(
                section.start,
                deltaLengthInFractions,
                length < 0
            )
            for (let i = sectionIndex - 1; i >= 0; --i) {
                const sectionToTheLeft = score.sections[i]
                if (
                    Time.compareTwoFractions(
                        sectionToTheLeft.start,
                        section.start
                    ) !== "lt"
                ) {
                    score.sections.splice(sectionIndex - 1, 1)
                    sectionIndex--
                } else {
                    sectionToTheLeft.end = section.start
                    sectionToTheLeft.duration = Time.addTwoFractions(
                        sectionToTheLeft.end,
                        sectionToTheLeft.start,
                        true
                    )
                    break
                }
            }
        } else {
            section.duration = Time.addTwoFractions(
                section.duration,
                deltaLengthInFractions,
                length < 0
            )
            section.end = Time.addTwoFractions(
                section.end,
                deltaLengthInFractions,
                length < 0
            )

            for (let i = sectionIndex + 1; i < score.sections.length; ++i) {
                const sectionToTheRight = score.sections[i]
                if (
                    Time.compareTwoFractions(
                        sectionToTheRight.end,
                        section.end
                    ) !== "gt"
                ) {
                    score.sections.splice(sectionIndex + 1, 1)
                    --i
                } else {
                    sectionToTheRight.start = section.end
                    sectionToTheRight.duration = Time.addTwoFractions(
                        sectionToTheRight.end,
                        sectionToTheRight.start,
                        true
                    )
                    break
                }
            }
        }

        ScoreManipulation.applyBoundariesToSections(
            score.sections,
            score.timeSignatures[0][1]
        )

        return score
    }

    export function applyBoundariesToSections(
        sections: Section[],
        timeSignature: TimeSignature
    ) {
        sections.forEach((s, i) => {
            // Make sure that every section has an incremental index
            s.index = i

            //make sure that every section has a length of a bar multiplied by a whole number
            const newDuration = Time.quantizeFractionToBar(
                timeSignature,
                s.duration
            )
            const newStart = Time.quantizeFractionToBar(timeSignature, s.start)
            s.duration = newDuration
            s.start = newStart
            s.end = Time.addTwoFractions(s.start, s.duration)
        })
    }

    export function splitSection(section: Section, score: Score): Score {
        if (Time.compareTwoFractions(section.duration, "2/1") === "eq") {
            return score
        }
        const section1 = section.copy()
        const section2 = section.copy()

        section1.title = section.title + " (1)"
        section2.title = section.title + " (2)"

        const splitDuration = Time.multiplyFractionWithNumber(
            section.duration,
            0.5
        )
        section1.duration = splitDuration
        section2.duration = splitDuration

        section1.end = Time.addTwoFractions(section1.start, section1.duration)
        section2.start = section1.end

        score.sections.splice(section.index, 1, section1, section2)

        ScoreManipulation.applyBoundariesToSections(
            score.sections,
            score.timeSignatures[0][1]
        )
        return score
    }

    export function findChordsIndexRangeForSection(
        score: Score,
        section: Section
    ) {
        let start = "0"
        const startIndex = score.chords.findIndex(chord => {
            if (Time.compareTwoFractions(start, section.start) === "eq") {
                return true
            }
            start = Time.addTwoFractions(start, chord[0])
            return false
        })

        let end = "0"

        const endIndex = score.chords.findIndex(chord => {
            if (Time.compareTwoFractions(end, section.end) === "eq") {
                return true
            }
            end = Time.addTwoFractions(end, chord[0])
            return false
        })
        return [startIndex, endIndex]
    }

    export function getSectionTitleToDisplay(
        section: Section,
        sections: Section[]
    ): string {
        if (section.operation === undefined) {
            return section.title
        } else if (section.operation.type === SECTION_EDITING.DELETE) {
            return "Delete " + section.title
        } else if (section.operation.type === SECTION_EDITING.REPLACE) {
            return "Replace " + section.title
        } else if (section.operation.type === SECTION_EDITING.REGENERATE) {
            return "Regenerate " + section.title
        } else if (
            section.operation.type === SECTION_EDITING.REGENERATE_WITH_SOURCE
        ) {
            let title = "Regenerate " + section.title

            const source = sections.find(
                s => s.index === section.operation.args.source_idx
            ).title

            if (source !== undefined) {
                title += " based on " + source
            }

            return title
        } else if (section.operation.type === SECTION_EDITING.INSERT_NEW) {
            return "Insert new section"
        } else if (section.operation.type === SECTION_EDITING.INSERT_COPY) {
            let title = "Insert copy"

            const source = sections.find(
                s => s.index === section.operation.args.source_idx
            ).title

            if (source !== undefined) {
                title += " of " + source
            }

            return title
        } else if (section.operation.type === SECTION_EDITING.INSERT_BLANK) {
            return "Insert blank section"
        } else if (
            section.operation.type === SECTION_EDITING.INSERT_VARIATION
        ) {
            let title = "Insert variation"

            const source = sections.find(
                s => s.index === section.operation.args.source_idx
            ).title

            if (source !== undefined) {
                title += " of " + source
            }

            return title
        }

        return section.title
    }

    export function getTrackBussesToUnload(tbs: TrackBus[], score: Score) {
        const tbsToUnload = []

        for (const tb of tbs) {
            const occurences = score.trackBusses.filter(
                t1 => t1.name === tb.name
            ).length

            if (occurences === 0) {
                tbsToUnload.push(tb)
            }
        }

        return tbsToUnload
    }

    export function isKeyboard(
        trackBusName: string,
        reference: InstrumentJSON
    ) {
        const patch = reference.patches.find(p => {
            return (
                p.playing_style === trackBusName.split(".")[2] &&
                p.articulation === trackBusName.split(".")[3]
            )
        })

        return patch !== undefined ? patch.auto_pedal : false
    }

    /**
     * For a given chord progression, this function generates a notes object with pitch values that are
     * optimally voiced for the best preview listening experience possible
     */
    export function voiceLeadChordProgression({
        chords,
    }: {
        chords: TemplateChord[]
    }): NotesObject {
        const notesObject = new NotesObject()

        return notesObject
    }

    export function removeSectionForInstruments(
        layerObject: Layer | PercussionLayer,
        insertedSection: Section
    ) {
        const start = Time.fractionToTimesteps(
            TIMESTEP_RES,
            insertedSection.start
        )

        const end = Time.fractionToTimesteps(TIMESTEP_RES, insertedSection.end)

        const duration = end - start

        for (const trackBus of layerObject.trackBuses) {
            trackBus.blocks = Score.modifyTrackBusRegions(
                start,
                end,
                trackBus.blocks,
                "splice"
            )

            trackBus.blocks.forEach(b => {
                if (b.start > start) {
                    b.start -= duration
                    b.end -= duration
                }
            })
        }
    }

    export function removeSectionForAutomation(
        insertedSection: Section,
        layerObject
    ) {
        for (const effectLabel in layerObject.effects) {
            const effect = layerObject.effects[effectLabel]

            if (!effect.automated) {
                continue
            }

            const timestep = Math.floor(
                Time.fractionToTimesteps(
                    AUTOMATION_TIMESTEP_RES,
                    insertedSection.start
                )
            )

            const durationInTimesteps = Math.floor(
                Time.fractionToTimesteps(
                    AUTOMATION_TIMESTEP_RES,
                    insertedSection.duration
                )
            )

            effect.values.splice(timestep, durationInTimesteps)
        }
    }

    export function getQuantizationType(
        resizeFactor: number,
        thresholds: QuantizationThresholds
    ): Quantization {
        if (resizeFactor <= thresholds.bar) {
            return "bar"
        } else if (resizeFactor < thresholds.beat) {
            return "beat"
        } else if (resizeFactor < thresholds.halfBeat) {
            return "halfBeat"
        }

        return "timestep"
    }

    export function getQuantizationTypeAsResolution(
        type: Quantization,
        timeSignature: TimeSignature,
        timestepRes: number
    ) {
        if (type === "bar") {
            return timeSignature[0] / timeSignature[1]
        } else if (type === "beat") {
            return timeSignature[1]
        } else if (type === "halfBeat") {
            return timeSignature[1] * 2
        }

        return timestepRes
    }

    export function getGridColorFillStyles() {
        const fillStyleNormal = "rgba(43, 36, 86, 1)"
        const fillStyleBlackKey = "rgba(255, 255, 255, 0.03)"

        return {
            normal: fillStyleNormal,
            blackKey: fillStyleBlackKey,
        }
    }

    export function getGridColor(
        harmonyLock: boolean,
        chordPitches: number[],
        absolutePitch: number,
        pitchStepDomain: "continuous" | "scale"
    ): undefined | string {
        const result = Note.getNoteTypeAndOctave(absolutePitch)
        const pitchType = result.type
        const colors = getGridColorFillStyles()

        if (harmonyLock) {
            const pitch = KeySignatureModule.getNotePitchByName(pitchType)

            const isInChord = chordPitches.includes(pitch)

            if (!isInChord) {
                return
            }

            return isInChord && !result.outsideOfLimits
                ? colors.normal
                : colors.blackKey
        } else {
            return pitchType.includes("#") && pitchStepDomain === "continuous"
                ? colors.blackKey
                : colors.normal
        }
    }

    /**
     * A function to create a new layer. Use the source parameter in order to create a new layer based on an existing layer's data
     * for duplication of various datastructures
     */
    export function createNewLayer(
        score: Score,
        layerType: "pitched" | "percussion",
        source?: {
            layer: Layer | PercussionLayer
            copyType: "automation" | "all"
        }
    ): Layer | PercussionLayer {
        const indices = ScoreManipulation.getCustomLayerIndices(score)

        let newLayer: PercussionLayer | Layer

        if (layerType === "percussion") {
            newLayer = ScoreManipulation.createCustomPercussionLayer(
                indices.percussion,
                score.timeSignatures[0][1]
            )
        } else {
            newLayer = ScoreManipulation.createCustomLayer(
                indices.pitched,
                score.timeSignatures[0][1]
            )
        }

        newLayer.initAutomatedEffects(score.scoreLength)

        if (source !== undefined) {
            if (source.copyType === "all" || source.copyType === "automation") {
                for (const effect in source.layer.effects) {
                    if (newLayer.effects[effect] === undefined) {
                        newLayer.effects[effect] = cloneDeep(
                            source.layer.effects[effect].copy()
                        )
                    }

                    source.layer.effects[effect].copyValuesTo(
                        newLayer.effects[effect]
                    )
                }
            }

            if (source.copyType === "all") {
                if (newLayer instanceof PercussionLayer) {
                    newLayer.patterns = (
                        source.layer as PercussionLayer
                    ).patterns.map(p => p.copy())
                    newLayer.patternRegions = (
                        source.layer as PercussionLayer
                    ).patternRegions.map(p => p.copy())
                } else {
                    newLayer.notesObject = source.layer.notesObject.copy(
                        score.firstTimeSignature,
                        score.sections,
                        newLayer.value
                    )
                }

                newLayer.trackBuses = source.layer.copyTrackBuses()
                newLayer.color = cloneDeep(source.layer.color)
            }
        }

        return newLayer
    }

    export function addLayerData({
        layer,
        trackBuses,
        score,
    }: {
        layer: Layer | PercussionLayer
        trackBuses: TrackBus[]
        score: Score
    }) {
        score.layers[layer.value] = layer
        score.layers[layer.value].trackBuses = trackBuses

        return score
    }

    /**
     * This function can be used when we are adding a delta timestep to another
     * timestep value. E.g. a note start, a pattern region start, etc.
     * It is responsible for not only quantizing the delta, but also the result
     * of the addition, and returning the delta that leads to that quantized value.
     * @returns
     */
    export function quantizeDeltaTimesteps({
        resizeFactor,
        deltaTimesteps,
        timesteps,
        timestepRes,
        timeSignature,
        thresholds,
    }: {
        resizeFactor: number
        deltaTimesteps: number
        timesteps: number
        timestepRes: number
        timeSignature: TimeSignature
        thresholds: QuantizationThresholds
    }) {
        const after = quantizeTimesteps({
            resizeFactor,
            timesteps,
            timestepRes,
            timeSignature,
            thresholds,
        })

        return after - timesteps + deltaTimesteps
    }

    export function quantizeTimesteps({
        resizeFactor,
        timesteps,
        timestepRes,
        timeSignature,
        thresholds,
    }: {
        resizeFactor: number
        timesteps: number
        timestepRes: number
        timeSignature: TimeSignature
        thresholds: QuantizationThresholds
    }): number {
        const quantization = ScoreManipulation.getQuantizationType(
            resizeFactor,
            thresholds
        )

        const resolution = getQuantizationTypeAsResolution(
            quantization,
            timeSignature,
            timestepRes
        )

        timesteps = Time.quantizeTimesteps(
            timesteps,
            "round",
            resolution,
            timestepRes
        )
        return timesteps
    }

    export function getInstrumentReference(
        instrument: string,
        instruments: InstrumentsJSON
    ): InstrumentJSON {
        try {
            const section = instrument.split(".")[0]
            const instrumentName = instrument.split(".")[1]

            const instrumentObject = instruments[section].find(
                i => i.name === instrumentName
            )

            return instrumentObject
        } catch (e) {
            console.error(e, instrument)
        }
    }

    export function createTrackBus(
        patchID: string,
        instrument: InstrumentJSON,
        scoreLengthTimesteps: number
    ): TrackBus {
        const trackBus = new TrackBus(
            patchID,
            0,
            instrument,
            [{ start: 0, end: scoreLengthTimesteps, id: uuidv4() }],
            0,
            0,
            0,
            0,
            false,
            false
        )

        trackBus.autoPedal = Score.setAutoPedal(
            instrument.name,
            instrument.section
        )

        return trackBus
    }

    export function createDefaultTrackBus(
        layer: Layer,
        instrument: InstrumentJSON,
        scoreLengthTimesteps: number
    ): TrackBus {
        const defaultInstrument =
            layer.type === "percussion"
                ? "p.drumkit-rock-1.nat.stac"
                : "k.piano.nat.stac"

        if (instrument.name !== defaultInstrument.split(".")[1]) {
            throw (
                "Invalid instrument reference. Please provide an instrument reference for " +
                defaultInstrument
            )
        }

        const trackBus = new TrackBus(
            defaultInstrument,
            0,
            instrument,
            [{ start: 0, end: scoreLengthTimesteps, id: uuidv4() }],
            0,
            0,
            0,
            0,
            false,
            false
        )

        trackBus.autoPedal = Score.setAutoPedal(
            instrument.name,
            instrument.section
        )

        return trackBus
    }

    export function insertSectionInAutomation(
        layerObject: Layer | PercussionLayer,
        newlyAddedSection: Section
    ) {
        for (var effectLabel in layerObject.effects) {
            var effect = layerObject.effects[effectLabel]

            if (!effect.automated) {
                continue
            }

            var timestep = Math.floor(
                Time.fractionToTimesteps(
                    AUTOMATION_TIMESTEP_RES,
                    newlyAddedSection.start
                )
            )
            var durationInTimesteps = Math.floor(
                Time.fractionToTimesteps(
                    AUTOMATION_TIMESTEP_RES,
                    newlyAddedSection.duration
                )
            )
            var valueAtTimestep = effect.values[timestep]

            var arrayToInsert = new Array(durationInTimesteps).fill(
                valueAtTimestep
            )

            effect.values.splice(timestep, 0, ...arrayToInsert)
        }
    }

    export function removeAllInsertedSections(score: Score) {
        let removedSections = 0

        const sections = cloneDeep(score.sections)

        for (let s = sections.length - 1; s >= 0; s--) {
            const section = sections[s]

            if (section.title.includes("Insert section")) {
                removedSections++

                const nbOfSections = score.sections.length

                ScoreManipulation.removeSection(score, section)

                console.assert(score.sections.length === nbOfSections - 1)
            }
        }

        return {
            removedSections,
            score,
        }
    }

    export function removeSectionFromPercussionLayer(
        layerValue: string,
        score: Score,
        section: Section
    ): void {
        const layer = score.layers[layerValue] as PercussionLayer

        layer.deletePatternRegionsWithinTimeRange(
            section.start,
            section.duration,
            score.timeSignatures[0][1]
        )
    }

    export function removeSection(score: Score, insertedSection: Section) {
        for (var s = score.sections.length - 1; s >= 0; s--) {
            const currentSection = score.sections[s]

            if (
                currentSection.index == insertedSection.index &&
                Time.compareTwoFractions(
                    currentSection.start,
                    insertedSection.start
                ) === "eq"
            ) {
                score.sections.splice(s, 1)
            } else if (
                Time.compareTwoFractions(
                    currentSection.start,
                    insertedSection.end
                ) != "lt"
            ) {
                score.sections[s].start = Time.addTwoFractions(
                    score.sections[s].start,
                    insertedSection.duration,
                    true
                )
                score.sections[s].setEndFromDuration(score.sections[s].duration)
            }
        }

        score.chords = ScoreManipulation.removeSectionForChordProgressions(
            score.chords,
            insertedSection
        )

        ScoreManipulation.removeSectionInTempoMap(score, insertedSection)

        for (const layer in score.layers) {
            const layerObject = score.layers[layer]

            ScoreManipulation.removeSectionForInstruments(
                layerObject,
                insertedSection
            )

            if (
                layerObject.type === "percussion" &&
                layerObject instanceof PercussionLayer
            ) {
                const timeSignature = score.firstTimeSignature

                layerObject.deletePatternRegionsWithinTimeRange(
                    insertedSection.start,
                    insertedSection.duration,
                    timeSignature
                )

                for (const patternRegion of layerObject.patternRegions) {
                    if (
                        Time.compareTwoFractions(
                            patternRegion.start,
                            insertedSection.end
                        ) != "lt"
                    ) {
                        patternRegion.start = Time.addTwoFractions(
                            patternRegion.start,
                            insertedSection.duration,
                            true
                        )
                    }
                }
            } else {
                ScoreManipulation.removeSectionForNotes(
                    score,
                    insertedSection,
                    layerObject
                )

                ScoreManipulation.removeSectionForAutomation(
                    insertedSection,
                    layerObject
                )
            }
        }

        ScoreManipulation.applyBoundariesToSections(
            score.sections,
            score.timeSignatures[0][1]
        )
    }

    export function removeSectionInTempoMap(
        score: Score,
        insertedSection: Section
    ) {
        // remove tempo maps that fell under the section
        let temposToRemove = []
        for (let i = 0; i < score.tempoMap.length; ++i) {
            let prevBpm = score.tempoMap[i - 1]
            if (
                prevBpm !== undefined &&
                Time.compareTwoFractions(
                    insertedSection.start,
                    prevBpm.fraction
                ) !== "gt" &&
                Time.compareTwoFractions(
                    insertedSection.end,
                    prevBpm.endInFractions
                ) !== "lt"
            ) {
                temposToRemove.push(prevBpm)
            }
        }

        //adjust the tempo map

        temposToRemove.forEach(t => {
            score.tempoMap.splice(score.tempoMap.indexOf(t), 1)
        })

        const templateTempoMap = score.postprocessTempoMap()

        for (let i = 0; i < templateTempoMap.length; ++i) {
            let bpm = templateTempoMap[i]

            if (
                Time.compareTwoFractions(bpm[0], insertedSection.start) !== "lt"
            ) {
                let fraction = Time.addTwoFractions(
                    bpm[0],
                    insertedSection.duration,
                    true
                )
                if (fraction.indexOf("-") !== -1) {
                    fraction = "0"
                }
                bpm[0] = fraction
            }
        }

        score.tempoMap = ScoreEncoding.encodeTempoMap(templateTempoMap)
    }

    export function applyMeasureBoundariesToCompositionWorkflows(
        layers: {
            [layerName: string]: CompositionWorkflowLayer
        },
        timeSignature: TimeSignature
    ) {
        Object.keys(layers).forEach(layerName => {
            const layer = layers[layerName]

            const notes = layer.score

            const limitInFractions = Time.multiplyFractionWithNumber(
                `${timeSignature[0]}/${timeSignature[1]}`,
                NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS
            )

            notes.forEach((n, i) => {
                if (
                    Time.compareTwoFractions(n.start, limitInFractions) !== "lt"
                ) {
                    notes.splice(i, 1)
                    return
                }
                const noteEnd = Time.addTwoFractions(n.start, n.duration)
                if (
                    Time.compareTwoFractions(noteEnd, limitInFractions) === "gt"
                ) {
                    n.duration = Time.addTwoFractions(
                        limitInFractions,
                        n.start,
                        true
                    )
                }
            })
        })
    }
    export function addChordAtTheEndOfComposition(score: Score): Score {
        const chords = score.chords

        chords.push([
            `${score.timeSignatures[0][1][0]}/${score.timeSignatures[0][1][1]}`,
            score.getKeySignature().pitchClass,
        ] as TemplateChord)

        const romanNumerals =
            ChordManipulation.convertChordSymbolsToRomanNumerals(
                chords,
                score.keySignatures
            )

        score.chords = chords
        score.romanNumerals = romanNumerals as TemplateChord[]

        return score
    }

    export function removeSectionForChordProgressions(
        chords: TemplateChord[],
        insertedSection: Section
    ) {
        const newChords = []

        let absoluteTime = "0"

        for (const chord of chords) {
            const start = absoluteTime
            const inInsertedSection = Time.fractionIsInBoundaries(
                insertedSection,
                start
            )

            absoluteTime = Time.addTwoFractions(absoluteTime, chord[0])

            if (inInsertedSection) {
                continue
            }

            newChords.push(chord)
        }

        return newChords
    }

    export function getChordsForTimeRange(
        chords: TemplateChord[],
        start: FractionString,
        duration: FractionString
    ): TemplateChord[] {
        const newChords: TemplateChord[] = []

        let absoluteTime = "0"

        for (const chord of chords) {
            const time = absoluteTime
            const inInsertedSection = Time.fractionIsInBoundaries(
                {
                    start,
                    duration,
                },
                time
            )

            absoluteTime = Time.addTwoFractions(absoluteTime, chord[0])

            if (inInsertedSection) {
                newChords.push(chord)
            }
        }

        return newChords
    }

    export function removeSectionForNotes(
        score: Score,
        insertedSection: Section,
        layer: Layer
    ) {
        const newLayerNotes = new NotesObject()

        layer.notesObject.manipulateNoteGroups((noteGroup: Note[]) => {
            const note = noteGroup[0]
            const noteEnd = Time.addTwoFractions(note.start, note.duration)

            if (Time.fractionIsInBoundaries(insertedSection, note.start)) {
                return true
            } else if (
                Time.compareTwoFractions(note.start, insertedSection.end) !==
                "lt"
            ) {
                const notes: Note[] = cloneDeep(noteGroup)

                const start = Time.addTwoFractions(
                    note.start,
                    insertedSection.duration,
                    true
                )

                notes.forEach(n => {
                    n.start = start
                })

                newLayerNotes.addNotesToGroup(
                    notes,
                    score.firstTimeSignature,
                    score.sections
                )
            } else if (Time.fractionIsInBoundaries(insertedSection, noteEnd)) {
                const notes: Note[] = cloneDeep(noteGroup)

                const offset = Time.addTwoFractions(
                    noteEnd,
                    insertedSection.start,
                    true
                )
                const duration = Time.addTwoFractions(
                    note.duration,
                    offset,
                    true
                )

                notes.forEach(n => {
                    n.duration = duration
                })

                newLayerNotes.addNotesToGroup(
                    notes,
                    score.firstTimeSignature,
                    score.sections
                )
            } else {
                newLayerNotes.addNotesToGroup(
                    noteGroup,
                    score.firstTimeSignature,
                    score.sections
                )
            }

            return true
        })

        layer.notesObject = newLayerNotes
    }

    /**
     * Creates a new region at the specified timestep
     */
    export function createTrackBusRegion({
        timesteps,
        timeSignature,
    }: {
        timesteps: number
        timeSignature: TimeSignature
    }): RangeWithID {
        const newTimesteps = Time.roundTimestepsToRes(
            timesteps,
            TIMESTEP_RES,
            timeSignature[1]
        )

        const duration = Time.convertTimestepsToAnotherRes(
            1,
            timeSignature[1],
            TIMESTEP_RES
        )

        const trackBusRegion = {
            start: newTimesteps,
            end: newTimesteps + duration,
            id: uuidv4(),
        }

        return trackBusRegion
    }

    export function findNextNonInsertedSection({
        sectionIndex,
        sections,
    }: {
        sectionIndex: number
        sections: TemplateSections[]
    }): TemplateSections | undefined {
        for (let i = sectionIndex + 1; i < sections.length; i++) {
            if (!sections[i][1].includes("Insert")) {
                return sections[i]
            }
        }

        return undefined
    }

    export function insertSection(
        score: Score,
        selectedSection: Section,
        operation: SectionOperation,
        sectionLength: number,
        insertPosition: "after" | "before",
        sectionName = "Insert section",
        options?: {
            skipTrackbusses: boolean
        }
    ) {
        var start = "0"
        var indexToInsertSectionAt = 0

        const timeSignature =
            score.timeSignatures[0][1][0] + "/" + score.timeSignatures[0][1][1]

        var sectionLengthInMeasures = Time.multiplyFractionWithNumber(
            timeSignature,
            sectionLength
        )

        for (var s = 0; s < score.sections.length; s++) {
            if (selectedSection.index == score.sections[s].index) {
                indexToInsertSectionAt = s

                if (insertPosition === "after") {
                    indexToInsertSectionAt += 1
                }

                break
            }
        }

        var newlyAddedSection

        for (var s = 0; s < score.sections.length; s++) {
            if (indexToInsertSectionAt == s) {
                const newSection = new Section(start, sectionName, null)
                newSection.setEndFromDuration(sectionLengthInMeasures)

                if (!featureFlags.sectionInpainting) {
                    newSection.inserted = true
                    newSection.operation = operation
                }

                newlyAddedSection = newSection
                score.sections.splice(s, 0, newSection)
            } else if (indexToInsertSectionAt < s) {
                score.sections[s].start = start
                score.sections[s].setEndFromDuration(score.sections[s].duration)
            }

            var section = score.sections[s]

            start = section.end
        }

        if (newlyAddedSection == null) {
            if (
                Time.sectionHasOddNumberOfBars(
                    selectedSection.duration,
                    timeSignature
                )
            ) {
                selectedSection.duration = Time.quantizeFractionToString(
                    selectedSection.duration,
                    "floor",
                    "1"
                )
                selectedSection.setEndFromDuration(selectedSection.duration)
            }

            const newSection = new Section(
                selectedSection.end,
                sectionName,
                null
            )
            newSection.setEndFromDuration(sectionLengthInMeasures)
            if (!featureFlags.sectionInpainting) {
                newSection.inserted = true
                newSection.operation = operation
            }

            newlyAddedSection = newSection
            score.sections.push(newSection)
        }

        ScoreManipulation.insertSectionsInChordProgressions(
            score,
            newlyAddedSection
        )

        const newTempoMap = ScoreManipulation.insertSectionsInTempoMap(
            score.postprocessTempoMap(),
            newlyAddedSection
        )

        score.tempoMap = ScoreEncoding.encodeTempoMap(newTempoMap)

        for (var layer in score.layers) {
            var layerObject = score.layers[layer]

            if (layerObject.type == "percussion") {
                ScoreManipulation.insertSectionInPatternRegions(
                    <PercussionLayer>layerObject,
                    newlyAddedSection
                )
            } else {
                if (!options?.skipTrackbusses) {
                    ScoreManipulation.insertSectionInInstrument(
                        layerObject,
                        newlyAddedSection
                    )
                }

                ScoreManipulation.insertSectionInNotesForLayer(
                    score,
                    layerObject,
                    newlyAddedSection,
                    indexToInsertSectionAt
                )
            }

            ScoreManipulation.insertSectionInAutomation(
                layerObject,
                newlyAddedSection
            )
        }

        ScoreManipulation.applyBoundariesToSections(
            score.sections,
            score.timeSignatures[0][1]
        )
    }

    // @todo add replaceSectionLayer method
    // This should be used to replace the content of a section but only for a specifc layer
    // Once that is done, we have to check if we can re-use the mergeSection method to update the section
    // in the source score.

    export function insertSectionsInChordProgressions(
        score: Score,
        newlyAddedSection: Section
    ) {
        let absoluteTime = "0"
        let index = 0

        try {
            const keySignature = score.getKeySignature()
            keySignature.keyMode =
                keySignature.keyMode.length < 5
                    ? SHORT_SCALES_TO_SCALES_MAP[keySignature.keyMode]
                    : keySignature.keyMode

            let insertedChord = false
            const sectionLength = Number(
                newlyAddedSection.duration.split("/")[0]
            )
            const chordArray = new Array(sectionLength).fill([
                "1",
                keySignature.pitchClass,
            ])

            const romanNumerals: TemplateChord[] =
                ChordManipulation.convertChordSymbolsToRomanNumerals(
                    chordArray,
                    score.keySignatures
                ) as any

            for (const chord of score.chords) {
                const start = absoluteTime
                const end = Time.addTwoFractions(absoluteTime, chord[0])

                if (
                    Time.compareTwoFractions(start, newlyAddedSection.start) !=
                    "lt"
                ) {
                    insertedChord = true
                    score.chords.splice(index, 0, ...chordArray)
                    score.romanNumerals.splice(index, 0, ...romanNumerals)
                    break
                }

                absoluteTime = end
                index += 1
            }

            if (!insertedChord) {
                score.chords.push(...chordArray)
                score.romanNumerals.splice(index, 0, ...romanNumerals)
            }
        } catch (error) {
            console.error(error)
        }
    }

    export function insertSectionsInTempoMap(
        tempo: TemplateTempoMap[],
        newlyAddedSection: Section
    ): TemplateTempoMap[] {
        const newTempoMap: TemplateTempoMap[] = tempo.map(bpm => {
            const newBPM = [bpm[0], bpm[1]] as TemplateTempoMap

            if (
                Time.compareTwoFractions(bpm[0], newlyAddedSection.start) !==
                "lt"
            ) {
                newBPM[0] = Time.addTwoFractions(
                    bpm[0],
                    newlyAddedSection.duration
                )
            }

            return newBPM
        })

        return newTempoMap
    }

    export function insertSectionInPatternRegions(
        layerObject: PercussionLayer,
        newlyAddedSection: Section
    ) {
        for (const patternRegion of layerObject.patternRegions) {
            if (
                Time.compareTwoFractions(
                    patternRegion.start,
                    newlyAddedSection.start
                ) != "lt"
            ) {
                patternRegion.start = Time.addTwoFractions(
                    patternRegion.start,
                    newlyAddedSection.duration
                )

                continue
            }

            const patternRegionLoopedDuration =
                patternRegion.getLoopedDuration()
            const patternRegionLoopedEnd = patternRegion.getLoopedEnd()
            const patternRegionEnd = patternRegion.getEndWithoutLoop()

            if (
                Time.compareTwoFractions(
                    patternRegionEnd,
                    newlyAddedSection.start
                ) == "gt"
            ) {
                const newPatternRegion = new PatternRegion(
                    patternRegion,
                    patternRegion.pattern
                )
                const newOffsetPatternRegion = new PatternRegion(
                    patternRegion,
                    patternRegion.pattern
                )

                // First, clip duration of unlooped pattern region
                const offset = Time.addTwoFractions(
                    patternRegionEnd,
                    newlyAddedSection.start,
                    true
                )
                const newDuration = Time.addTwoFractions(
                    patternRegion.duration,
                    offset,
                    true
                )
                patternRegion.duration = newDuration

                // Second, unloop pattern region
                patternRegion.loop = 0

                // Third, create a copy / copies of the pattern region for section that is after the inserted one
                newPatternRegion.start = Time.addTwoFractions(
                    newlyAddedSection.start,
                    offset
                )

                const newPatternRegionLoopDuration = Time.addTwoFractions(
                    Time.addTwoFractions(
                        patternRegionLoopedEnd,
                        newlyAddedSection.start,
                        true
                    ),
                    offset,
                    true
                )
                newPatternRegion.loop =
                    Time.divideTwoFractions(
                        newPatternRegionLoopDuration,
                        newPatternRegion.duration
                    ) - 1

                layerObject.patternRegions.push(newPatternRegion)

                if (Time.compareTwoFractions(offset, "0") == "gt") {
                    newOffsetPatternRegion.duration = offset
                    newOffsetPatternRegion.loop = 0
                    newOffsetPatternRegion.start = newlyAddedSection.start

                    layerObject.patternRegions.push(newOffsetPatternRegion)
                }
            } else if (
                Time.compareTwoFractions(
                    patternRegionLoopedEnd,
                    newlyAddedSection.start
                ) == "gt"
            ) {
                const targetDuration = Time.addTwoFractions(
                    newlyAddedSection.start,
                    patternRegion.start,
                    true
                )
                const newPatternRegion = new PatternRegion(
                    patternRegion,
                    patternRegion.pattern
                )

                patternRegion.loop =
                    Math.floor(
                        Time.divideTwoFractions(
                            targetDuration,
                            patternRegion.duration
                        )
                    ) - 1

                newPatternRegion.start = newlyAddedSection.start
                newPatternRegion.loop =
                    Math.floor(
                        Time.divideTwoFractions(
                            Time.addTwoFractions(
                                patternRegionLoopedDuration,
                                targetDuration,
                                true
                            ),
                            newPatternRegion.duration
                        )
                    ) - 1

                layerObject.patternRegions.push(newPatternRegion)
            }
        }

        layerObject.sortPatternRegions()
    }

    export function insertSectionInInstrument(
        layerObject: Layer | PercussionLayer,
        newlyAddedSection: Section
    ) {
        const start = Time.fractionToTimesteps(
            TIMESTEP_RES,
            newlyAddedSection.start
        )
        const duration = Time.fractionToTimesteps(
            TIMESTEP_RES,
            newlyAddedSection.duration
        )

        for (const trackBus of layerObject.trackBuses) {
            for (const block of trackBus.blocks) {
                if (block.start >= start) {
                    block.start += duration
                    block.end += duration
                } else if (block.end >= start && block.start < start) {
                    block.end += duration
                }
            }

            trackBus.blocks.push({
                id: uuidv4(),
                start: start,
                end: start + duration,
            })

            trackBus.blocks.sort((a, b) => {
                return a.start - b.start < 0 ? -1 : 1
            })

            trackBus.blocks = mergeTrackBusRegions(trackBus.blocks).regions
        }
    }

    /**
     * This function inserts a new section by moving note objects' start and duration values appropriately
     * @param score
     * @param layer
     * @param newlyAddedSection
     * @param indexToInsertSectionAt
     */
    export function insertSectionInNotesForLayer(
        score: Score,
        layer: Layer,
        newlyAddedSection: Section,
        indexToInsertSectionAt: number
    ) {
        const notesObject = new NotesObject()

        layer.notesObject.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
            const notes: Note[] = cloneDeep(noteGroup)
            const note: Note = notes[0]
            note.meta.section = Note.getSectionForNoteStart(
                score.sections,
                note.start
            )

            const timeSignature = score.firstTimeSignature

            const melodyCondition =
                layer.value === "Melody" &&
                note.meta.section >= indexToInsertSectionAt

            const otherLayerCondition =
                layer.value !== "Melody" &&
                Time.compareTwoFractions(
                    note.start,
                    newlyAddedSection.start
                ) !== "lt"

            if (melodyCondition || otherLayerCondition) {
                const start = Time.addTwoFractions(
                    note.start,
                    newlyAddedSection.duration
                )

                notes.forEach(n => {
                    n.start = start
                })
            } else {
                const noteEnd = Time.addTwoFractions(note.start, note.duration)
                const duration = Time.addTwoFractions(
                    newlyAddedSection.start,
                    note.start,
                    true
                )

                if (
                    Time.compareTwoFractions(noteEnd, newlyAddedSection.end) !==
                    "lt"
                ) {
                    notes.forEach(n => {
                        n.duration = duration
                    })
                }
            }

            notesObject.addNotesToGroup(notes, timeSignature, score.sections)

            return true
        })

        layer.notesObject = notesObject
    }

    /**
     * Mutates the trackbus in place by breaking up an existing region
     * into two regions at the specified timestep
     */
    export function divideTrackBusRegion({
        timesteps,
        trackBus,
        timeSignature,
    }: {
        timesteps: number
        trackBus: TrackBus
        timeSignature: TimeSignature
    }) {
        const newTimesteps = Time.roundTimestepsToRes(
            timesteps,
            TIMESTEP_RES,
            timeSignature[1]
        )

        const regionIndex = trackBus.blocks.findIndex(r => {
            return r.start <= newTimesteps && r.end >= newTimesteps
        })

        if (regionIndex === -1) {
            return
        }

        const region = trackBus.blocks[regionIndex]
        const timestepsToRemove = Time.convertTimestepsToAnotherRes(
            1,
            timeSignature[1],
            TIMESTEP_RES
        )

        if (region.end - region.start <= timestepsToRemove * 2) {
            trackBus.blocks.splice(regionIndex, 1)
        } else if (newTimesteps === region.start) {
            region.start = newTimesteps + timestepsToRemove
        } else {
            const tempEnd = region.end

            region.end = newTimesteps

            const newRegion = {
                start: newTimesteps + timestepsToRemove,
                end: tempEnd,
                id: uuidv4(),
            }

            if (newTimesteps + 1 <= tempEnd) {
                trackBus.blocks.splice(regionIndex + 1, 0, newRegion)
            }

            if (region.start + 1 > region.end) {
                trackBus.blocks.splice(regionIndex, 1)
            }
        }
    }

    export function getTrackBussesWithSelectedRegions({
        layer,
        selectedRegions,
    }: {
        layer: Layer
        selectedRegions: RangeWithID[]
    }): TrackBus[] {
        const trackBusses = []

        for (const region of selectedRegions) {
            const trackBus = layer.trackBuses.find(t => {
                return t.blocks.some(r => r.id === region.id)
            })

            if (trackBus) {
                trackBusses.push(trackBus)
            }
        }

        return trackBusses
    }

    export function iterateThroughKeySignatures(
        keySignatures: TemplateKeySignature[],
        scoreLength: FractionString,
        functionBinding: {
            (start: string, end: string, ks: string, index: number): boolean
        }
    ) {
        let index = 0

        for (const data of keySignatures) {
            const start = data[0]
            const ks = data[1]

            let end

            if (index + 1 >= keySignatures.length) {
                end = scoreLength
            } else {
                end = keySignatures[index + 1][0]
            }

            if (!functionBinding(start, end, ks, index)) {
                break
            }

            index += 1
        }
    }

    export function cleanupEmptyTrackBusRegions(regions: RangeWithID[]) {
        return regions.filter(r => r.start !== r.end)
    }

    /**
     * Merges track regions that are overlapping. Returns a new array of regions.
     * This function is pure, it doesn't mutate the original array.
     */
    export function mergeTrackBusRegions(regions: RangeWithID[]): {
        regions: RangeWithID[]
        replacedWith: { [replacedID: string]: string }
    } {
        const temp: RangeWithID[] = cloneDeep(regions)
        const newRegions: RangeWithID[] = []

        temp.sort((a, b) => {
            return a.start - b.start
        })

        const replacedWith = {}

        for (let i = 0; i < temp.length; i++) {
            let skipIndices = 0
            const newRegion = {
                start: temp[i].start,
                end: temp[i].end,
                id: temp[i].id,
            }

            for (let j = i + 1; j < temp.length; j++) {
                if (temp[i].end < temp[j].start) {
                    break
                }

                replacedWith[temp[j].id] = temp[i].id
                newRegion.end = Math.max(temp[i].end, temp[j].end)

                skipIndices++
            }

            newRegions.push(newRegion)

            i += skipIndices
        }

        return {
            regions: newRegions,
            replacedWith,
        }
    }

    export function getLayersForTrackBusses({
        tbs,
        score,
    }: {
        tbs: TrackBus[]
        score: Score
    }): Map<Layer, TrackBus[]> {
        const layers = new Map<Layer, TrackBus[]>()

        for (const tb of tbs) {
            const layerKey = Object.keys(score.layers).find(l => {
                return score.layers[l].trackBuses.some(t => t.id === tb.id)
            })

            if (layerKey === undefined) {
                continue
            }

            const layer = score.layers[layerKey]

            if (!layers.has(layer)) {
                layers.set(layer, [])
            }

            layers.get(layer).push(tb)
        }

        return layers
    }

    export function createPatch(
        trackBus: TrackBus,
        instruments: InstrumentsJSON
    ): Patch {
        const section = trackBus.name.split(".")[0]
        const instrument = trackBus.name.split(".")[1]
        let playingStyle = trackBus.name.split(".")[2]
        const articulation = trackBus.name.split(".")[3]

        let granulationEngine = ""

        const instrumentObject = instruments[section].find(
            i => i.name === instrument
        )

        const patch = instrumentObject.patches.find(
            p =>
                p.articulation === articulation &&
                p.playing_style === playingStyle
        )

        if (patch.target_playing_style != null) {
            playingStyle = patch.target_playing_style
        }

        granulationEngine = patch.granulationEngine

        return new Patch(
            section,
            instrument,
            playingStyle,
            articulation,
            granulationEngine
        )
    }

    /**
     * This function returns a new instance of NotesObject that contains notes that are in the selectedNotes
     * but not in the previousNotes
     * @param
     * @returns
     */
    export function getNoteDifference({
        previousNotes,
        selectedNotes,
        timeSignature,
        sections,
    }: {
        previousNotes: NotesObject
        selectedNotes: NotesObject
        timeSignature: TimeSignature
        sections: Section[]
    }): NotesObject {
        const notes = new NotesObject()

        const groups = [
            ...new Set(
                []
                    .concat(previousNotes.getNoteGroupStarts())
                    .concat(selectedNotes.getNoteGroupStarts())
            ),
        ]

        for (const group of groups) {
            const previous = previousNotes.getNoteGroup(group)
            const selected = selectedNotes.getNoteGroup(group)

            if (previous !== undefined && selected !== undefined) {
                const pPrevious = previous.map(n => n.pitch)
                const pSelected = selected.map(n => n.pitch)

                const pitches = [...new Set([...pPrevious, ...pSelected])]

                for (const pitch of pitches) {
                    if (
                        pSelected.includes(pitch) &&
                        !pPrevious.includes(pitch)
                    ) {
                        notes.addNoteToGroup(
                            selected[pSelected.indexOf(pitch)],
                            timeSignature,
                            sections
                        )
                    }
                }
            }

            if (previous === undefined && selected !== undefined) {
                notes.addNotesToGroup(selected, timeSignature, sections)
            }
        }

        return notes
    }

    export function deleteLayer(score: Score, layer: Layer) {
        const layerKey = Object.keys(score.layers).find(l => l === layer.value)

        if (layerKey === undefined) {
            return
        }

        delete score.layers[layerKey]
    }

    export function getSelectedNoteGroups({
        selectedNotes,
        layer,
        timeElapsed,
        getSelectedNoteGroups,
        score,
    }: {
        selectedNotes: NotesObject
        layer: Layer
        timeElapsed: number
        getSelectedNoteGroups: boolean
        score: Score
    }): NotesObject {
        const notes = new NotesObject()
        const ts = Time.convertSecondsInTimesteps(
            timeElapsed,
            false,
            TIMESTEP_RES,
            score.tempoMap,
            "liveEditNotes"
        )
        const fraction = Time.timestepsToFraction(TIMESTEP_RES, ts)

        selectedNotes.manipulateNoteGroups(
            (noteGroup: Note[]) => {
                let notesToAdd = []

                if (getSelectedNoteGroups) {
                    notesToAdd = layer.notesObject.getNoteGroup(
                        noteGroup[0].start
                    )
                } else {
                    for (const n of noteGroup) {
                        const toAdd = layer.notesObject.getNoteByID(
                            n.start,
                            n.noteID
                        )

                        if (toAdd !== undefined) {
                            notesToAdd.push(toAdd)
                        }
                    }
                }

                notes.addNotesToGroup(
                    notesToAdd,
                    score.timeSignatures[0][1],
                    score.sections
                )

                return true
            },
            [fraction, fraction]
        )

        return notes
    }

    export function createCustomPercussionLayer(
        index: number,
        ts: TimeSignature
    ): PercussionLayer {
        const layerName = "Custom Percussion " + index

        const layer = new PercussionLayer(
            "percussion",
            layerName,
            null,
            null,
            {},
            ts,
            [],
            [],
            null,
            null
        )

        return layer
    }

    export function createCustomLayer(index: number, ts: TimeSignature): Layer {
        const layerName = getLayerName(index, "pitched")
        return new Layer("pitched", layerName, null, null, {}, ts, null)
    }

    export function getLayerName(
        index: number,
        type: "percussion" | "pitched"
    ): string {
        if (type === "percussion") {
            return "Custom Percussion " + index
        }

        return "Custom Pitched " + index
    }

    export function getCustomLayerIndices(score: Score): {
        percussion: number
        pitched: number
    } {
        let numberOfCustomPercussionLayers = 0
        let numberOfCustomPitchedLayers = 0

        for (const layer in score.layers) {
            const layerName = score.layers[layer].value

            if (layerName.includes("Custom Percussion")) {
                const int = parseInt(
                    layerName.replace("Custom Percussion ", "")
                )

                numberOfCustomPercussionLayers = Math.max(
                    numberOfCustomPercussionLayers,
                    int
                )
            } else if (layerName.includes("Custom Pitched")) {
                const int = parseInt(layerName.replace("Custom Pitched ", ""))

                numberOfCustomPitchedLayers = Math.max(
                    numberOfCustomPitchedLayers,
                    int
                )
            }
        }

        numberOfCustomPercussionLayers += 1
        numberOfCustomPitchedLayers += 1

        return {
            percussion: numberOfCustomPercussionLayers,
            pitched: numberOfCustomPitchedLayers,
        }
    }

    /** Duplicate the automation value for a given EffectType and Layer, to all other layers of a Score
     * Mutates the Score passed as argument
     * @param score
     * @param type
     * @param layer
     */
    export function copyAutomationToOtherLayers(
        score: Score,
        type: EffectType,
        layer: Layer
    ) {
        for (const l in score.layers) {
            const otherLayer = score.layers[l]

            if (otherLayer.value === layer.value) {
                continue
            }

            layer.effects[type].copyValuesTo(otherLayer.effects[type])
        }
    }

    /**
     *
     * @param effect
     * @param timestepRange assumes the timestep resolution is AUTOMATION_TIMESTEP_RES
     * @param automationStepRange
     * @returns an array of values ready for insertion in the Effect object
     */
    export function setAutomationValue(
        effect: Effect,
        timestepRange: [number, number],
        automationStepRange: [number, number],
        round: boolean
    ): number[] {
        const values: number[] = [...effect.values]

        if (timestepRange[0] === timestepRange[1]) {
            values[timestepRange[0]] = automationStepRange[0]
        } else {
            // Reorder timestep / automation values
            if (timestepRange[0] > timestepRange[1]) {
                const tempTimestep = timestepRange[1]
                const tempAutomation = automationStepRange[1]

                timestepRange[1] = timestepRange[0]
                timestepRange[0] = tempTimestep

                automationStepRange[1] = automationStepRange[0]
                automationStepRange[0] = tempAutomation
            }

            for (let t = timestepRange[0]; t <= timestepRange[1]; t++) {
                values[t] = Time.interpolate(
                    [timestepRange[0], automationStepRange[0]],
                    [timestepRange[1], automationStepRange[1]],
                    t,
                    round
                )
            }
        }

        return values
    }

    /**
     * Set mute value for all of the TrackBus instances in a given layer
     * @param layer
     * @returns
     */
    export function muteLayer(layer: Layer): Layer {
        const trackBuses = layer.trackBuses

        let mute = true

        for (const tb of trackBuses) {
            mute = mute && tb.mute
        }

        mute = !mute

        for (const tb of trackBuses) {
            tb.mute = mute

            if (tb.mute) {
                tb.solo = false
            }
        }

        return layer
    }

    /**
     * Set solo value for all of the TrackBus instances in a given layer
     * @param layer
     * @returns
     */
    export function soloLayer(layer: Layer): Layer {
        const trackBuses = layer.trackBuses

        let solo = true

        for (const tb of trackBuses) {
            solo = solo && tb.solo
        }

        solo = !solo

        for (const tb of trackBuses) {
            tb.solo = solo

            if (tb.solo) {
                tb.mute = false
            }
        }

        return layer
    }

    /**
     * Sets the notes that are within a certain range to be selected
     *
     * @param { Score } score
     *
     * @param { number } timestepResolution defines the number of timesteps that make up for a whole fraction length
     * 										(e.g. 48 timesteps are equal to "1/1", so the resolution is 48)
     *
     * @param { LayerType } layerType the layer that holds the notes to be selected
     *
     * @param { number[] } timestepRange array that holds two timestep values (start and end) that represents
     * 						the currently selected area
     *
     * @param { number[] } pitchRange array that holds two pitch values (start and end) that represents
     * 						the currently selected area
     *
     * @param { number[] } appendToSelection if true, the given note selection will add to the already selected notes.
     *                                       if false, the curently selected notes will be set to false if they are not part of the
     *                                       current selection.
     *
     * @returns { Note[] } notes that are now selected
     */

    export function selectNotes({
        score,
        layerType,
        timestepRange,
        pitchRange,
        timeSignature,
        timestepResolution,
        prevSelection,
    }: {
        score: Score
        layerType: LayerType
        timestepRange: number[]
        pitchRange: [number, number]
        timestepResolution: number
        timeSignature: TimeSignature
        prevSelection?: NotesObject
    }): NotesObject {
        /**
         * 
        the goal of this method is to set the selected property of a given set of notes to true based on the given timestep and pitch offset ranges
    	
        - references to all notes that have been selected prior (note.selected == true) 
        and each note that will be now set to selected = true should be returned as a notesObject. It is important
        to keep the references, so we can change the selected notes later on and rely on the fact that the notes in the score
        are changed accordingly as well

        - the range for timestep and pitch might not be sorted (e.g. it could be a pitch range of [70,60], so end, start) and that needs to be handled here as well
        - timesteps need to be converted into fractions
        - notes that are not fully part of the range, but their start value is less than the timestep range end value will be considered as selected too
         (you can think of it as a rectangular shape that selects notes. Once the end reaches the start of the next note, the rectangle touches the note and therefore selects it)
        - the total runtime of this method should be < 10ms (less is better) on a large test score
        - inspiration can be taken from NoteEditorService.selectNotes()
         */

        let notes: NotesObject = new NotesObject()

        // If there are already selected notes, add them to the group
        if (prevSelection) {
            prevSelection.forEach(note =>
                notes.addNoteToGroup(note, timeSignature, [])
            )
        }

        // convert timesteps to fractions to use in manipulateNoteGroups
        let fractions = timestepRange.map(ts =>
            Time.timestepsToFraction(timestepResolution, ts)
        ) as [string, string]

        const layer = score.layers[layerType]

        layer.notesObject.manipulateNoteGroups(
            (noteGroup, nextNoteGroup) => {
                noteGroup.forEach((note, i) => {
                    if (
                        note.pitch >= pitchRange[0] &&
                        note.pitch <= pitchRange[1]
                    ) {
                        notes.addNoteToGroup(note, timeSignature, [])
                    }
                })
                return true
            },
            [...fractions]
        )

        return notes
    }

    /**
     * Use this function if the fraction delta would make certain notes fall out of the upper
     * and lower bounds of the score length
     */
    export function shouldResetFractionDelta(
        fractionDelta: FractionString,
        start: FractionString,
        end: FractionString,
        scoreLength: FractionString,
        isMovingLeft: boolean
    ) {
        const newFirstStart = Time.addTwoFractions(
            start,
            fractionDelta,
            isMovingLeft
        )

        const newLastEnd = Time.addTwoFractions(
            end,
            fractionDelta,
            isMovingLeft
        )

        if (
            Time.compareTwoFractions(newFirstStart, "0") === "lt" ||
            Time.compareTwoFractions(newLastEnd, scoreLength) === "gt"
        ) {
            fractionDelta = "0"
        }

        return fractionDelta
    }

    export function shouldResetFractionDelta2(
        fractionDelta: Fraction,
        start: Fraction,
        end: Fraction,
        scoreLength: Fraction,
        isMovingLeft: boolean
    ): Fraction {
        const newFirstStart = Time2.addTwoFractions(
            start,
            fractionDelta,
            isMovingLeft
        )

        const newLastEnd = Time2.addTwoFractions(
            end,
            fractionDelta,
            isMovingLeft
        )

        if (
            Time2.compareTwoFractions(newFirstStart, Fraction.ZERO) === "lt" ||
            Time2.compareTwoFractions(newLastEnd, scoreLength) === "gt"
        ) {
            fractionDelta = new Fraction("0")
        }

        return fractionDelta
    }

    export function moveGroupOnXAxis(
        note: Note,
        chord: {
            start: FractionString
            duration: FractionString
            chord: string
        },
        fractionDelta: FractionString,
        isMovingLeft: boolean
    ) {
        const start = Time.addTwoFractions(
            note.start,
            fractionDelta,
            isMovingLeft
        )

        const temp = {
            start,
            end: Time.addTwoFractions(note.duration, start),
        }

        return temp.start
    }

    export function moveGroupOnXAxis2(
        note: Note,
        chord: {
            start: Fraction
            duration: Fraction
            chord: string
        },
        fractionDelta: Fraction,
        isMovingLeft: boolean
    ): Fraction {
        const start = Time2.addTwoFractions(
            note.startFraction,
            fractionDelta,
            isMovingLeft
        )

        const temp = {
            start,
            end: Time2.addTwoFractions(note.durationFraction, start),
        }

        return temp.start
    }

    export function isInChordBoundary(
        chord: {
            start: string
            duration: string
        },
        temp: { start: string; end: string }
    ) {
        const inChordBoundary =
            Time.fractionIsInBoundaries(chord, temp.start, true, false) &&
            Time.fractionIsInBoundaries(chord, temp.end, true, true)

        return inChordBoundary
    }

    // check for the min and max pitches while moving
    // move a single note group and deselect groups that aren't in the same group with the hovered note

    /**
     * moves a given set of Score.layer note references by the pitch or note start
     *
     * @param { Score } score
     *
     * @param { Note[] } selectedNotes notes array that holds the references
     * 								to the notes that will be moved
     *
     * @param { number } timestepResolution defines the number of timesteps that make up for a whole fraction length
     * 										(e.g. 48 timesteps are equal to "1/1", so the resolution is 48)
     *
     * @param { number } pitchOffset the number of pitches by which the note
     * 								 pitches will be moved
     *
     * @param { number } timestepOffset the number of timesteps by which the note
     * 								 start be moved (we need to compute timestep to fraction first)
     *
     * @param { boolean } deleteOverlappingNotes defines if notes that are overlapping should be either deleted or marked as overlapping (note.overlap = true)
     *
     * @returns { Score } updated score
     */
    export function moveNotes({
        selectedNoteID,
        selectedNotes,
        timeSignature,
        pitchDelta,
        timestepDelta,
        score,
        type,
        timestepRes,
        scale,
    }: {
        selectedNoteID: string
        selectedNotes: NotesObject
        timeSignature: TimeSignature
        pitchDelta: number
        timestepDelta: number
        score: Score
        type?: Quantization
        timestepRes: number
        scale?: number[] | undefined
    }): NotesObject {
        const selectedNotesCount = selectedNotes.length

        if (selectedNotesCount === 0) {
            return selectedNotes
        }

        const isMovingLeft = timestepDelta < 0

        timestepDelta = isMovingLeft ? -timestepDelta : timestepDelta

        let fractionDelta: string | Fraction

        if (featureFlags.useFractionClass) {
            fractionDelta = shouldResetFractionDelta2(
                Time2.timestepsToFraction(timestepRes, timestepDelta),
                selectedNotes.getFirstGroup()[0].startFraction,
                selectedNotes.getLastGroup()[0].getEndFraction(),
                new Fraction(score.scoreLength),
                isMovingLeft
            )
        } else {
            fractionDelta = shouldResetFractionDelta(
                Time.timestepsToFraction(timestepRes, timestepDelta),
                selectedNotes.getFirstGroup()[0].start,
                selectedNotes.getLastGroup()[0].getEnd(),
                score.scoreLength,
                isMovingLeft
            )
        }

        const newSelectedNotes = new NotesObject()

        if (featureFlags.useFractionClass) {
            selectedNotes.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
                const note = noteGroup[0]

                const chord = ChordManipulation.getChordAtTime2(
                    note.startFraction,
                    score.chords
                )

                const newStart = moveGroupOnXAxis2(
                    note as Note,
                    chord,
                    fractionDelta as Fraction,
                    isMovingLeft
                )

                const newNoteGroup = noteGroup.map(n => {
                    const changeNoteStart =
                        newStart &&
                        Time2.compareTwoFractions(n.startFraction, newStart) !==
                            "eq"

                    const newNote: Note = cloneDeep(n)

                    if (changeNoteStart) {
                        newNote.startFraction = newStart
                    }

                    let pitch

                    pitch = moveNotesOnPitchAxis({
                        pitchRange: selectedNotes.getPitchRange(),
                        pitchDelta,
                        scale,
                        newNote,
                    })

                    selectedNotes.setNotePitch(newNote, pitch)

                    return newNote
                })

                newSelectedNotes.addNotesToGroup(
                    newNoteGroup,
                    timeSignature,
                    score.sections
                )

                return true
            })
        } else {
            selectedNotes.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
                const note = noteGroup[0]

                const chord = ChordManipulation.getChordAtTime(
                    note.start,
                    score.chords
                )

                const newStart = moveGroupOnXAxis(
                    note as Note,
                    chord,
                    fractionDelta as FractionString,
                    isMovingLeft
                )

                const newNoteGroup = noteGroup.map(n => {
                    const changeNoteStart =
                        newStart &&
                        Time.compareTwoFractions(n.start, newStart) !== "eq"

                    const newNote: Note = cloneDeep(n)

                    if (changeNoteStart) {
                        newNote.start = newStart
                    }

                    let pitch

                    pitch = moveNotesOnPitchAxis({
                        pitchRange: selectedNotes.getPitchRange(),
                        pitchDelta,
                        scale,
                        newNote,
                    })

                    selectedNotes.setNotePitch(newNote, pitch)

                    return newNote
                })

                newSelectedNotes.addNotesToGroup(
                    newNoteGroup,
                    timeSignature,
                    score.sections
                )

                return true
            })
        }

        return newSelectedNotes
    }

    export function getVoiceDelta({
        selectedNoteID,
        selectedNotes,
        pitchDelta,
        chord,
        start,
    }: {
        selectedNoteID: string
        selectedNotes: NotesObject
        pitchDelta: number
        chord: string
        start: string
    }): number {
        const sourcePitch =
            selectedNotes.getNoteByIDWithoutNoteStart(selectedNoteID).note.pitch

        const direction = pitchDelta > 0 ? "next" : "previous"

        if (pitchDelta === 0) {
            return 0
        }

        let voiceDelta = 0
        let previousPitch = sourcePitch

        while (true) {
            const temp = KeySignatureModule.moveAbsolutePitchInChord(
                previousPitch,
                chord,
                direction
            )

            if (Math.abs(pitchDelta) < Math.abs(temp - sourcePitch)) {
                break
            }

            voiceDelta += direction === "next" ? 1 : -1
            previousPitch = temp
        }

        return voiceDelta
    }

    export function splitNotesAtChordBoundaries({
        selectedNotes,
        score,
    }: {
        selectedNotes: NotesObject
        score: Score
    }) {
        let needsSplitting = false
        selectedNotes.manipulateNoteGroups(noteGroup => {
            const chord = ChordManipulation.getChordAtTime(
                noteGroup[0].start,
                score.chords
            )
            const notesEnd = Time.addTwoFractions(
                noteGroup[0].start,
                noteGroup[0].duration
            )

            const chordEnd = Time.addTwoFractions(chord.start, chord.duration)
            if (Time.compareTwoFractions(notesEnd, chordEnd) === "gt") {
                needsSplitting = true
                const firstGroupDuration = Time.addTwoFractions(
                    chordEnd,
                    noteGroup[0].start,
                    true
                )
                noteGroup.forEach(n => {
                    selectedNotes.changeNoteDuration(n, firstGroupDuration)

                    let newNote = new Note({
                        pitch: n.pitch,
                        start: chordEnd,
                        duration: Time.addTwoFractions(
                            notesEnd,
                            chordEnd,
                            true
                        ),
                        meta: { ...n.meta },
                    })

                    selectedNotes.addNoteToGroup(
                        newNote,
                        score.timeSignatures[0][1],
                        score.sections
                    )
                })
            }
            return true
        })
        return needsSplitting
            ? splitNotesAtChordBoundaries({ selectedNotes, score })
            : true
    }

    export function voiceNotesInTheChord({
        selectedNotes,
        chords,
    }: {
        selectedNotes: NotesObject
        chords: TemplateChord[]
    }): NotesObject {
        selectedNotes.manipulateNoteGroups(noteGroup => {
            const chord = ChordManipulation.getChordAtTime(
                noteGroup[0].start,
                chords
            )

            const pitchGrid = KeySignatureModule.getPitchGridForChord(
                chord.chord
            )

            const notes = noteGroup
                .map(n => n)
                .sort((a, b) => a.pitch - b.pitch)

            let lastValue = null

            notes.forEach(n => {
                const closestValueIndex = pitchGrid.indexOf(
                    Misc.findClosestValue(pitchGrid, n.pitch, {
                        greaterThan: lastValue,
                    })
                )

                if (
                    closestValueIndex < 0 ||
                    closestValueIndex > pitchGrid.length - 1
                ) {
                    return
                }

                lastValue = pitchGrid[closestValueIndex]

                selectedNotes.setNotePitch(n, pitchGrid[closestValueIndex])
            })

            return true
        })

        return selectedNotes
    }

    export function moveNotesOnPitchAxisWithHarmonyLock({
        voiceDelta,
        newNote,
        chord,
    }: {
        voiceDelta: number
        newNote: Note
        chord: {
            start: FractionString
            duration: FractionString
            chord: string
        }
    }): number {
        let newPitch = newNote.pitch

        for (let i = 0; i < Math.abs(voiceDelta); i++) {
            const direction = voiceDelta > 0 ? "next" : "previous"

            newPitch = KeySignatureModule.moveAbsolutePitchInChord(
                newPitch,
                chord.chord,
                direction
            )
        }

        return newPitch
    }

    export function moveNotesOnPitchAxis({
        pitchRange,
        pitchDelta,
        scale,
        newNote,
    }: {
        pitchRange: {
            lowestNote: number
            highestNote: number
        }
        pitchDelta: number
        scale?: number[]
        newNote: Note
    }): number {
        const allowScalePitchToChange =
            scale?.length !== 0
                ? true
                : ScoreManipulation.allowScalePitchToChange(
                      pitchRange,
                      pitchDelta > 0,
                      scale
                  )

        let pitch = newNote.pitch

        if (pitchDelta && allowScalePitchToChange) {
            pitchDelta = ScoreManipulation.getPitchDeltaForScale(
                scale,
                newNote.pitch,
                pitchDelta
            )

            pitch = newNote.pitch + pitchDelta
        }

        return Math.min(
            Math.max(LOWEST_PIANO_PITCH, pitch),
            HIGHEST_PIANO_PITCH
        )
    }

    export function allowScalePitchToChange(
        pitchRange: {
            lowestNote: number
            highestNote: number
        },
        increasePitch: boolean,
        scale: number[] | undefined
    ) {
        if (!scale.length) {
            return true
        }

        if (
            (!increasePitch && scale[0] === pitchRange.lowestNote) ||
            (increasePitch &&
                scale[scale.length - 1] === pitchRange.highestNote)
        ) {
            return false
        }

        return true
    }

    export function getPitchDeltaForScale(
        scale: number[],
        pitch: number,
        pitchDelta: number
    ) {
        if (!scale?.length || !pitchDelta) {
            return pitchDelta
        }

        let currentIndex = scale.findIndex(p => p === pitch)
        let newIndex = currentIndex

        // see if we should move up or down the index
        let increasePitch = pitchDelta > 0

        // can't increase / decrease pitch any further
        if (
            (increasePitch && currentIndex === scale.length - 1) ||
            (!increasePitch && currentIndex === 0)
        ) {
            return 0
        }

        newIndex = increasePitch ? newIndex + 1 : newIndex - 1

        return scale[newIndex] - scale[currentIndex]
    }

    export function removeNotesFromOtherGroups({
        selectedNoteID,
        notes,
        timeSignature,
        sections,
    }: {
        selectedNoteID: string
        notes: NotesObject
        timeSignature: TimeSignature
        sections: Section[]
    }): NotesObject {
        const start = notes.getNoteByIDWithoutNoteStart(selectedNoteID)

        const selectedNotes = new NotesObject()

        if (start.noteGroup !== undefined) {
            selectedNotes.addNotesToGroup(
                start.noteGroup,
                timeSignature,
                sections
            )
        }

        return selectedNotes
    }

    /**
     * moves a given set of Score.layer note references by the pitch or note start
     *
     * @returns { NotesObject } updated selectedNotes
     */
    export function moveSingleNote({
        selectedNotes,
        pitchDelta,
        timesteps,
        timestepDelta,
        timeSignature,
        score,
        layer,
        timestepRes,
        timestepRange,
        isFirstMove,
        type,
        scale,
        chords,
    }: {
        selectedNotes: NotesObject
        timeSignature: TimeSignature
        pitchDelta: number
        timesteps: number
        timestepDelta: number
        score: Score
        layer: Layer
        timestepRes: number
        timestepRange?: [number, number]
        isFirstMove?: boolean
        type?: Quantization
        scale?: number[] | undefined
        chords: TemplateChord[]
    }): NotesObject {
        const selectedNotesCount = selectedNotes.length

        if (selectedNotesCount === 0) {
            return selectedNotes
        }

        const newSelectedNotes = new NotesObject()
        const selectedNoteGroup = selectedNotes.getFirstGroup()
        const selectedNote = selectedNoteGroup[0]
        const moveDirection = timestepDelta < 0 ? "left" : "right"

        timestepDelta =
            moveDirection === "left" ? -timestepDelta : timestepDelta

        const fractionDelta = Time.timestepsToFraction(
            timestepRes,
            timestepDelta
        )

        const newStart = Time.addTwoFractions(
            selectedNote.start,
            fractionDelta,
            moveDirection === "left"
        )

        let newDuration: string

        const hoveringNoteGroup: Readonly<Note>[] | undefined =
            layer.notesObject.getNoteGroupAtCoordinates(
                Time.convertTimestepsToAnotherRes(
                    timesteps,
                    timestepRes,
                    TIMESTEP_RES
                ),
                timestepRange
            )

        const selectedIsHovered =
            hoveringNoteGroup &&
            hoveringNoteGroup.length === 1 &&
            hoveringNoteGroup[0].noteID === selectedNote.noteID

        const { previousNoteGroup, noteGroup, nextNoteGroup } =
            layer.notesObject.getNoteGroupAndSurroundings(selectedNote.start)
        const nextBoundary = nextNoteGroup
            ? nextNoteGroup[0].getBoundary(timestepRes)
            : undefined
        const previousBoundary = previousNoteGroup
            ? previousNoteGroup[0].getBoundary(timestepRes)
            : undefined
        const noteGroupIsThreshold = noteGroup?.length >= 1
        const noteBoundary = selectedNote.getBoundary(timestepRes)
        const snapToHoveredGroup =
            hoveringNoteGroup?.length && !selectedIsHovered

        let shouldMoveNote = true
        let start

        // moving a single note from inside a note group
        if (noteGroupIsThreshold && !snapToHoveredGroup) {
            if (
                moveDirection === "right" &&
                timesteps >= noteBoundary.end.timesteps
            ) {
                start = noteBoundary.end.fraction

                // adjust duration if necessary
                if (nextBoundary?.start?.fraction) {
                    // use the next notegroup if the new start matches it's start
                    if (
                        Time.compareTwoFractions(
                            start,
                            nextBoundary?.start?.fraction
                        ) == "eq"
                    ) {
                        newDuration = nextBoundary.duration.fraction
                    } else {
                        newDuration = Time.min(
                            noteBoundary.duration.fraction,
                            Time.addTwoFractions(
                                nextBoundary.start.fraction,
                                start,
                                true
                            )
                        )
                    }
                } else {
                    newDuration = Time.min(
                        noteBoundary.duration.fraction,
                        Time.addTwoFractions(score.scoreLength, start, true)
                    )

                    if (Time.compareTwoFractions(newDuration, "0/1") === "eq") {
                        shouldMoveNote = false
                    }
                }
            } else if (
                moveDirection === "left" &&
                timesteps < noteBoundary.start.timesteps
            ) {
                start = Time.addTwoFractions(
                    noteBoundary.start.fraction,
                    noteBoundary.duration.fraction,
                    true
                )

                if (Time.compareTwoFractions(start, "0") === "lt") {
                    start = "0"
                }

                // adjust start and duration if necessary
                if (
                    previousBoundary?.end?.fraction &&
                    Time.compareTwoFractions(
                        previousBoundary?.end?.fraction,
                        start
                    ) === "gt"
                ) {
                    start = previousBoundary?.end?.fraction

                    // use the previous notegroup if the new start matches it's start
                    if (
                        Time.compareTwoFractions(
                            start,
                            previousBoundary?.start?.fraction
                        ) == "eq"
                    ) {
                        newDuration = previousBoundary.duration.fraction
                    } else {
                        newDuration = Time.addTwoFractions(
                            noteBoundary.start.fraction,
                            previousBoundary.end.fraction,
                            true
                        )
                    }
                }

                // corner case for moving single notes to the start
                // now we have to respect the original noteboundary in order
                // to trim the duration
                else if (
                    start === "0" &&
                    noteBoundary?.start?.fraction &&
                    Time.compareTwoFractions(
                        Time.addTwoFractions(
                            start,
                            noteBoundary.duration.fraction
                        ),
                        noteBoundary?.start?.fraction
                    ) === "gt"
                ) {
                    newDuration = Time.addTwoFractions(
                        noteBoundary.start.fraction,
                        start,
                        true
                    )
                }
            }
        }

        // detect if moving the note is respecting the boundaries when moved freely
        else {
            if (moveDirection === "right") {
                const maxStart = nextBoundary?.start?.fraction
                    ? Time.addTwoFractions(
                          nextBoundary.start.fraction,
                          noteBoundary.duration.fraction,
                          true
                      )
                    : Time.addTwoFractions(
                          score.scoreLength,
                          noteBoundary.duration.fraction,
                          true
                      )

                if (Time.compareTwoFractions(newStart, maxStart) === "gt") {
                    shouldMoveNote = false
                }
            } else {
                let maxStart = previousBoundary?.end?.fraction

                if (Time.compareTwoFractions(newStart, maxStart) === "lt") {
                    shouldMoveNote = false
                }
            }
        }

        // whenever we hover a notegroup while moving, snap to the group
        if (snapToHoveredGroup) {
            start = hoveringNoteGroup[0].start
            shouldMoveNote = true
        }

        if (noteGroupIsThreshold) {
            timestepDelta =
                start === undefined
                    ? 0
                    : Math.abs(
                          Time.fractionToTimesteps(timestepRes, start) -
                              Time.fractionToTimesteps(
                                  timestepRes,
                                  selectedNote.start
                              )
                      )

            if (moveDirection === "left") {
                timestepDelta = -timestepDelta
            }
        }

        // move the note freely
        else if (!noteGroupIsThreshold && !start && shouldMoveNote) {
            start = newStart

            if (type !== undefined) {
                start = Time.quantizeFractionToString(
                    start,
                    "round",
                    getQuantizationTypeAsResolution(
                        type,
                        score.firstTimeSignature,
                        timestepRes
                    )
                )
            }
        }

        if (!shouldMoveNote) {
            newSelectedNotes.addNotesToGroup(
                selectedNoteGroup,
                timeSignature,
                score.sections
            )

            return newSelectedNotes
        }

        const newNote: Note = cloneDeep(selectedNote)
        newNote.start = start

        if (hoveringNoteGroup?.length) {
            newDuration = hoveringNoteGroup[0].duration
        }

        if (!newDuration) {
            newDuration = selectedNote.duration
        }

        selectedNotes.changeNoteDuration(newNote, newDuration, undefined)
        let absolutePitch = 0

        const allowScalePitchToChange = !scale?.length
            ? true
            : scale.includes(newNote.pitch + pitchDelta)

        // depending on the mode, we need to change the pitch to something in
        // scale instead of selecting the absolute pitch
        if ((pitchDelta || absolutePitch) && allowScalePitchToChange) {
            const pitch = absolutePitch
                ? absolutePitch
                : newNote.pitch + pitchDelta
            selectedNotes.setNotePitch(newNote, pitch)
        }

        newSelectedNotes.addNotesToGroup(
            [newNote],
            timeSignature,
            score.sections
        )

        return newSelectedNotes
    }

    export function findNearestAvailableNote(
        absolutePitch: number,
        chord
    ): number {
        const { type, octave } = Note.getNoteTypeAndOctave(absolutePitch)

        const chordNoteTypes = KeySignatureModule.getNotesForChord(chord.chord)

        let p = 0

        if (!chordNoteTypes.includes(type)) {
            const chordNotesInPitched = KeySignatureModule.getPitchesForNotes(
                chordNoteTypes,
                octave
            )
            // find closes pitch
            let pitchDiff = Math.abs(chordNotesInPitched[0] - absolutePitch)
            let closestPitch = chordNotesInPitched[0]

            for (const pitch of chordNotesInPitched) {
                if (Math.abs(pitch - absolutePitch) < pitchDiff) {
                    pitchDiff = Math.abs(pitch - absolutePitch)
                    closestPitch = pitch
                }
            }
            p = closestPitch
        }

        return p
    }

    export function findNearestTypedNote(
        selectedNotePitch: number,
        chord,
        neighbourType: "next" | "previous",
        usedPitches: number[]
    ): number {
        let { octave } = Note.getNoteTypeAndOctave(selectedNotePitch)

        // we need to put everything below inside of a function to be called recursively
        // if we don't find an appropriate pitch inside one octave, we need to switch up/down in order
        // to get the right pitch

        const chordNoteTypes = KeySignatureModule.getNotesForChord(chord.chord)

        let newPitch = 0

        const findPitch = (
            octave: number,
            neighbourType: "next" | "previous"
        ) => {
            let chordNotesInPitched = KeySignatureModule.getPitchesForNotes(
                chordNoteTypes,
                octave
            )

            // find closes pitch
            for (const pitch of chordNotesInPitched) {
                if (neighbourType === "next") {
                    if (
                        pitch > selectedNotePitch &&
                        !usedPitches.find(p => p === pitch)
                    ) {
                        newPitch = pitch
                        break
                    }
                } else if (neighbourType === "previous") {
                    if (
                        pitch < selectedNotePitch &&
                        !usedPitches.find(p => p === pitch)
                    ) {
                        newPitch = pitch
                        break
                    }
                }
            }

            if (newPitch === 0)
                return findPitch(
                    neighbourType === "previous" ? octave - 1 : octave + 1,
                    neighbourType
                )
            return newPitch
        }

        newPitch = findPitch(octave, neighbourType)

        if (newPitch) {
            usedPitches.push(newPitch)
        }

        return newPitch
    }

    export function movePatternRegion({
        patternRegion,
        timestepDelta,
        timestepRes,
        type,
        timeSignature,
    }: {
        patternRegion: PatternRegion
        timestepDelta: number
        timestepRes: number
        type: Quantization
        timeSignature: TimeSignature
    }) {
        const moveDirection = timestepDelta < 0 ? "left" : "right"

        timestepDelta =
            moveDirection === "left" ? -timestepDelta : timestepDelta

        const fractionDelta = Time.timestepToFraction(
            timestepDelta,
            timestepRes
        )

        const newStart = Time.max(
            "0",
            Time.addTwoFractions(
                patternRegion.start,
                fractionDelta,
                moveDirection === "left"
            )
        )

        const quantizedResult = quantizeResizedDuration({
            newStart,
            type,
            resizedElement: patternRegion,
            timeSignature,
            timestepRes,
        })

        patternRegion.start = quantizedResult.newStart

        return patternRegion
    }

    export function loopPatternRegion({
        patternRegion,
        timestepDelta,
        timestepRes,
        timeSignature,
    }: {
        patternRegion: PatternRegion
        timestepDelta: number
        timestepRes: number
        timeSignature: number[]
    }) {
        const moveDirection = timestepDelta < 0 ? "left" : "right"

        timestepDelta =
            moveDirection === "left" ? -timestepDelta : timestepDelta

        if (moveDirection === "left" && patternRegion.loop !== 0) {
            patternRegion.loop -= 1
        } else {
            patternRegion.loop += 1
        }

        return patternRegion
    }

    export function resizePatternRegion({
        patternRegion,
        timestepDelta,
        timestepRes,
        timeSignature,
        resizeType,
        quantization,
    }: {
        patternRegion: PatternRegion
        timestepDelta: number
        timestepRes: number
        timeSignature: number[]
        resizeType: PatternHoveringType
        quantization: Quantization
    }) {
        const moveDirection = timestepDelta < 0 ? "left" : "right"

        timestepDelta =
            moveDirection === "left" ? -timestepDelta : timestepDelta

        const beatLength = TIMESTEP_RES / timeSignature[1]

        const beatLengthInFractions = Time.timestepToFraction(
            beatLength,
            timestepRes
        )

        const fractionDelta = Time.timestepToFraction(
            timestepDelta,
            timestepRes
        )
        const durationLimit = patternRegion.getDurationLimit(timeSignature)

        // make sure we allow the minimum duration of 1 beat
        // and enforce it in case we are below this min duration
        const minDuration = beatLengthInFractions

        let newStart, newDuration, newOnset

        if (resizeType === PatternHoveringType.LEFT) {
            newStart = Time.addTwoFractions(
                patternRegion.start,
                fractionDelta,
                moveDirection === "left"
            )
            newDuration = Time.addTwoFractions(
                patternRegion.duration,
                fractionDelta,
                moveDirection !== "left"
            )
            newOnset = Time.addTwoFractions(
                patternRegion.onset,
                fractionDelta,
                moveDirection === "left"
            )

            // do not change the start or duration when we already reached
            // an onset of 0 and we are moving further to the left
            if (
                Time.compareTwoFractions(patternRegion.onset, "0") === "eq" &&
                moveDirection === "left"
            ) {
                return patternRegion
            }
        } else if (resizeType === PatternHoveringType.BOTTOM_RIGHT) {
            newStart = patternRegion.start
            newDuration = Time.addTwoFractions(
                patternRegion.duration,
                fractionDelta,
                moveDirection === "left"
            )
            newOnset = patternRegion.onset

            // The sum of the onset + duration should never
            // be able to be greater than the pattern length
            const onsetAndDurationExceedsPatternLength =
                Time.compareTwoFractions(
                    Time.addTwoFractions(patternRegion.onset, newDuration),
                    durationLimit
                ) === "gt"

            if (onsetAndDurationExceedsPatternLength) {
                const maxDuration = Time.addTwoFractions(
                    durationLimit,
                    patternRegion.onset,
                    true
                )

                newDuration = maxDuration
            }
        }

        // min duration check that may affect start, duration and onset
        if (
            Time.compareTwoFractions(newDuration, minDuration) === "lt" ||
            Time.compareTwoFractions(newDuration, minDuration) === "eq" ||
            Time.compareTwoFractions(newDuration, "0") === "eq"
        ) {
            if (resizeType === PatternHoveringType.LEFT) {
                // the maximum space we can add to the current onset
                const onsetGap = Time.addTwoFractions(
                    patternRegion.duration,
                    minDuration,
                    true
                )

                // the absolut max onset we can set according
                // to the space left computed above
                const maxOnset = Time.addTwoFractions(
                    patternRegion.onset,
                    onsetGap
                )

                patternRegion.start = Time.addTwoFractions(
                    patternRegion.start,
                    onsetGap
                )

                patternRegion.onset = maxOnset
            }

            patternRegion.duration = minDuration

            return patternRegion
        }

        if (Time.compareTwoFractions(newDuration, durationLimit) === "gt") {
            return patternRegion
        }

        if (Time.compareTwoFractions(newOnset, "0") === "lt") {
            newOnset = "0"
        }

        const quantized = quantizeResizedDuration({
            newStart,
            newDuration,
            type: quantization,
            resizedElement: patternRegion,
            timeSignature,
            timestepRes,
        })

        patternRegion.start = quantized.newStart
        patternRegion.duration = quantized.newDuration
        patternRegion.onset = newOnset

        return patternRegion
    }

    export function endManipulatingPatterns(
        selectedPatterns: PatternRegion[],
        layer: PercussionLayer,
        timeSignature: number[],
        timestepRes: number
    ) {
        const patternRegions = layer.trimOverlappingPatternRegions(
            selectedPatterns,
            timeSignature,
            timestepRes
        )

        // truncate overlapping patterns

        return patternRegions
    }

    export function drawPatternRegion(
        timestep: number,
        timestepRes: number,
        timeSignature: number[],
        pattern: Pattern,
        patternRegions: PatternRegion[]
    ) {
        const newRegion = new PatternRegion(
            {
                start: Time.timestepToFraction(timestep, timestepRes),
                onset: "0",
                duration: Time.measuresToFraction(pattern.bars, timeSignature),
                loop: 0,
            },
            pattern
        )
        patternRegions.push(newRegion)
        return newRegion
    }

    export function resizeTrackBusRegion(
        trackBus: TrackBus,
        id: string,
        side: HoveringType,
        offset: number,
        scoreLengthInTS: number,
        type: Quantization,
        timeSignature: TimeSignature
    ): { region: RangeWithID; side: HoveringType } | undefined {
        for (let b = 0; b < trackBus.blocks.length; b++) {
            const block = trackBus.blocks[b]

            if (block.id !== id) {
                continue
            }

            const newRegion: RangeWithID = cloneDeep(block)

            let newStart = newRegion.start + offset
            let newEnd = newRegion.end + offset

            if (side === HoveringTypeEnum.LEFT) {
                newRegion.start = newStart
            } else if (side === HoveringTypeEnum.RIGHT) {
                newRegion.end = newEnd
            }

            if (newRegion.start > newRegion.end) {
                const temp = newRegion.start

                newRegion.start = newRegion.end
                newRegion.end = temp

                side =
                    side === HoveringTypeEnum.LEFT
                        ? HoveringTypeEnum.RIGHT
                        : HoveringTypeEnum.LEFT
            }

            newRegion.end = Math.min(newRegion.end, scoreLengthInTS)

            trackBus.blocks[b] = quantizeTrackBusRegion(
                newRegion,
                type,
                timeSignature
            )

            return {
                region: newRegion,
                side: side,
            }
        }

        return undefined
    }

    export function moveTrackBusRegion(
        trackBus: TrackBus,
        id: string,
        offset: number,
        timeSignature: TimeSignature,
        type?: Quantization
    ): RangeWithID | undefined {
        for (let b = 0; b < trackBus.blocks.length; b++) {
            const block = trackBus.blocks[b]

            if (block.id === id) {
                if (block.start + offset <= 0) {
                    trackBus.blocks[b] = {
                        id: id,
                        start: 0,
                        end: block.end - block.start,
                    }
                } else {
                    trackBus.blocks[b] = {
                        id: id,
                        start: block.start + offset,
                        end: block.end + offset,
                    }
                }

                trackBus.blocks[b] = quantizeTrackBusRegion(
                    trackBus.blocks[b],
                    type,
                    timeSignature
                )

                return trackBus.blocks[b]
            }
        }

        return undefined
    }

    function quantizeTrackBusRegion(
        block: RangeWithID,
        type: Quantization,
        timeSignature: TimeSignature
    ) {
        if (type === undefined) {
            return block
        }

        const quantizationRes = getQuantizationTypeAsResolution(
            type,
            timeSignature,
            TIMESTEP_RES
        )

        const quantize = value => {
            return Time.quantizeTimesteps(
                value,
                "round",
                quantizationRes,
                TIMESTEP_RES
            )
        }

        block.start = quantize(block.start)
        block.end = quantize(block.end)

        return block
    }

    function detectOverlappingNotesWithoutPitchDetection({
        consideredNoteGroup,
        selectedNoteGroup,
    }: {
        consideredNoteGroup: Note[]
        selectedNoteGroup: Note[]
    }): Note[] {
        const consideredNote = consideredNoteGroup[0]
        const selectedNote = selectedNoteGroup[0]

        const matchingPositions =
            Time.compareTwoFractions(
                consideredNote.start,
                selectedNote.start
            ) === "eq" &&
            Time.compareTwoFractions(
                consideredNote.duration,
                selectedNote.duration
            ) === "eq"

        const isOverlapping =
            (Time.fractionIsInBoundaries(consideredNote, selectedNote.start) ||
                Time.fractionIsInBoundaries(
                    selectedNote,
                    consideredNote.start
                )) &&
            !matchingPositions

        return isOverlapping ? consideredNoteGroup : []
    }

    function detectOverlappingNotesWithPitchDetection({
        pitches,
        noteIDs,
        consideredNoteGroup,
        selectedNoteGroup,
    }: {
        pitches: number[]
        noteIDs: string[]
        consideredNoteGroup: Note[]
        selectedNoteGroup: Note[]
    }): Note[] {
        const consideredNote = consideredNoteGroup[0]
        const selectedNote = selectedNoteGroup[0]

        const matchingPositions =
            Time.compareTwoFractions(
                consideredNote.start,
                selectedNote.start
            ) === "eq" &&
            Time.compareTwoFractions(
                consideredNote.duration,
                selectedNote.duration
            ) === "eq"

        if (matchingPositions) {
            // we return notes that have the same pitch and are not identical to any of the selected notes
            return consideredNoteGroup.filter(
                n => pitches.includes(n.pitch) && !noteIDs.includes(n.noteID)
            )
        }

        const isOverlapping =
            Time.fractionIsInBoundaries(consideredNote, selectedNote.start) ||
            Time.fractionIsInBoundaries(selectedNote, consideredNote.start)

        return isOverlapping ? consideredNoteGroup : []
    }

    function detectOverlappingNotesWithPitchDetection2({
        pitches,
        noteIDs,
        consideredNoteGroup,
        selectedNoteGroup,
    }: {
        pitches: number[]
        noteIDs: string[]
        consideredNoteGroup: Note[]
        selectedNoteGroup: Note[]
    }): Note[] {
        const consideredNote = consideredNoteGroup[0]
        const selectedNote = selectedNoteGroup[0]

        const matchingPositions =
            Time2.compareTwoFractions(
                consideredNote.startFraction,
                selectedNote.startFraction
            ) === "eq" &&
            Time2.compareTwoFractions(
                consideredNote.durationFraction,
                selectedNote.durationFraction
            ) === "eq"

        if (matchingPositions) {
            // we return notes that have the same pitch and are not identical to any of the selected notes
            return consideredNoteGroup.filter(
                n => pitches.includes(n.pitch) && !noteIDs.includes(n.noteID)
            )
        }

        const isOverlapping =
            Time2.fractionIsInBoundaries(
                {
                    start: consideredNote.startFraction,
                    duration: consideredNote.durationFraction,
                },
                selectedNote.startFraction
            ) ||
            Time2.fractionIsInBoundaries(
                {
                    start: selectedNote.startFraction,
                    duration: selectedNote.durationFraction,
                },
                consideredNote.startFraction
            )

        return isOverlapping ? consideredNoteGroup : []
    }

    function detectOverlappingNotesWithoutPitchDetection2({
        consideredNoteGroup,
        selectedNoteGroup,
    }: {
        consideredNoteGroup: Note[]
        selectedNoteGroup: Note[]
    }): Note[] {
        const consideredNote = consideredNoteGroup[0]
        const selectedNote = selectedNoteGroup[0]

        const matchingPositions =
            Time2.compareTwoFractions(
                consideredNote.startFraction,
                selectedNote.startFraction
            ) === "eq" &&
            Time2.compareTwoFractions(
                consideredNote.durationFraction,
                selectedNote.durationFraction
            ) === "eq"

        const isOverlapping =
            (Time2.fractionIsInBoundaries(
                {
                    start: consideredNote.startFraction,
                    duration: consideredNote.durationFraction,
                },
                selectedNote.startFraction
            ) ||
                Time2.fractionIsInBoundaries(
                    {
                        start: selectedNote.startFraction,
                        duration: selectedNote.durationFraction,
                    },
                    consideredNote.startFraction
                )) &&
            !matchingPositions

        return isOverlapping ? consideredNoteGroup : []
    }

    export function detectOverlappingNotes({
        selectedNotes,
        layer,
        visibleTimeStepRange,
        visibleFractionRange,
        timeSignature,
        score,
        includePitchDetection,
    }: {
        selectedNotes: NotesObject
        layer: Layer
        visibleTimeStepRange?: [number, number]
        visibleFractionRange?: [string, string]
        timeSignature: TimeSignature
        score: Score
        includePitchDetection: boolean
    }): NotesObject {
        let notesToConsider: NotesObject = new NotesObject()
        const overlappingNotes: NotesObject = new NotesObject()

        // define notes to consider
        if (visibleTimeStepRange || visibleFractionRange) {
            const fractionRange: [string, string] = visibleTimeStepRange
                ? (visibleTimeStepRange.map(ts =>
                      Time.timestepToFraction(ts, TIMESTEP_RES)
                  ) as [string, string])
                : visibleFractionRange

            layer.notesObject.manipulateNoteGroups(group => {
                notesToConsider.addNotesToGroup(
                    group,
                    timeSignature,
                    score.sections
                )
                return true
            }, fractionRange)
        } else {
            notesToConsider = layer.notesObject
        }

        selectedNotes.manipulateNoteGroups(selectedNoteGroup => {
            const selectedNote = selectedNoteGroup[0]

            let pitches = []
            let noteIDs = []

            if (includePitchDetection) {
                pitches = selectedNoteGroup.map(note => note.pitch)
                noteIDs = selectedNoteGroup.map(note => note.noteID)
            }

            notesToConsider.manipulateNoteGroups(consideredNoteGroup => {
                const consideredNote = consideredNoteGroup[0]

                // selected notes can never be overlapping
                if (consideredNote.noteID === selectedNote.noteID) {
                    return true
                }

                const notesToAdd = includePitchDetection
                    ? detectOverlappingNotesWithPitchDetection({
                          pitches,
                          noteIDs,
                          consideredNoteGroup,
                          selectedNoteGroup,
                      })
                    : detectOverlappingNotesWithoutPitchDetection({
                          consideredNoteGroup,
                          selectedNoteGroup,
                      })

                if (notesToAdd.length > 0) {
                    overlappingNotes.addNotesToGroup(
                        notesToAdd,
                        timeSignature,
                        score.sections
                    )
                }

                return true
            })

            return true
        })

        return overlappingNotes
    }

    export function detectOverlappingNotes2({
        selectedNotes,
        layer,
        visibleTimeStepRange,
        visibleFractionRange,
        timeSignature,
        score,
        includePitchDetection,
    }: {
        selectedNotes: NotesObject
        layer: Layer
        visibleTimeStepRange?: [number, number]
        visibleFractionRange?: [Fraction, Fraction]
        timeSignature: TimeSignature
        score: Score
        includePitchDetection: boolean
    }): NotesObject {
        let notesToConsider: NotesObject = new NotesObject()
        const overlappingNotes: NotesObject = new NotesObject()

        // define notes to consider
        if (visibleTimeStepRange || visibleFractionRange) {
            const fractionRange: [Fraction, Fraction] = visibleTimeStepRange
                ? (visibleTimeStepRange.map(ts =>
                      Time2.timestepToFraction(ts, TIMESTEP_RES)
                  ) as [Fraction, Fraction])
                : visibleFractionRange

            layer.notesObject.manipulateNoteGroups2(group => {
                notesToConsider.addNotesToGroup(
                    group,
                    timeSignature,
                    score.sections
                )
                return true
            }, fractionRange)
        } else {
            notesToConsider = layer.notesObject
        }

        selectedNotes.manipulateNoteGroups(selectedNoteGroup => {
            const selectedNote = selectedNoteGroup[0]

            let pitches = []
            let noteIDs = []

            if (includePitchDetection) {
                pitches = selectedNoteGroup.map(note => note.pitch)
                noteIDs = selectedNoteGroup.map(note => note.noteID)
            }

            notesToConsider.manipulateNoteGroups2(consideredNoteGroup => {
                const consideredNote = consideredNoteGroup[0]

                // selected notes can never be overlapping
                if (consideredNote.noteID === selectedNote.noteID) {
                    return true
                }

                const notesToAdd = includePitchDetection
                    ? detectOverlappingNotesWithPitchDetection2({
                          pitches,
                          noteIDs,
                          consideredNoteGroup,
                          selectedNoteGroup,
                      })
                    : detectOverlappingNotesWithoutPitchDetection2({
                          consideredNoteGroup,
                          selectedNoteGroup,
                      })

                if (notesToAdd.length > 0) {
                    overlappingNotes.addNotesToGroup(
                        notesToAdd,
                        timeSignature,
                        score.sections
                    )
                }

                return true
            })

            return true
        })

        return overlappingNotes
    }

    export function removeOverlappingNotes({
        overlappingNotes,
        layer,
        selectedNotes,
    }: {
        overlappingNotes: NotesObject
        layer: Layer
        selectedNotes: NotesObject
    }) {
        overlappingNotes.forEach(note => {
            layer.notesObject.removeNoteFromGroup(note.start, note.noteID)
        })
    }

    export function calculateChordsLength(
        chords: TemplateChord[]
    ): FractionString {
        let duration = "0"
        chords.forEach(chord => {
            duration = Time.addTwoFractions(duration, chord[0])
        })
        return duration
    }

    /**
     * updates the duration and the start of a given set of Score.layer note references
     *
     * @param { Score } score
     * @param { Note } resizedNote the note that has been clicked to resize (serves as a reference point)
     * @param { Layer } layer
     * @param { number } timestepResolution defines the number of timesteps that make up for a whole fraction length
     * 										(e.g. 48 timesteps are equal to "1/1", so the resolution is 48)
     * @param { number } timestepOffset the number of timesteps by which the note
     * 								 start be moved (we need to compute timestep to fraction first)
     * @param { boolean } deleteOverlappingNotes defines if notes that are overlapping should be either deleted or marked as overlapping (note.overlap = true)
     */
    export function resizeNotes({
        selectedNotes,
        resizedNote,
        layer,
        timestepRes,
        timestepOffset,
        resizeType,
        timeSignature,
        score,
        isFirstResize,
        maxScoreLength,
        type,
    }: {
        selectedNotes: NotesObject
        resizedNote: Note
        layer: Layer
        timestepRes: number
        timestepOffset: number
        resizeType: HoveringType
        timeSignature: TimeSignature
        score: Score
        isFirstResize: boolean
        maxScoreLength?: FractionString
        type?: Quantization
    }): [NotesObject, HoveringType] {
        const areNotesInTheSameGroup = selectedNotes.noteGroupsLength === 1
        const noteGroup: Note[] =
            isFirstResize && !areNotesInTheSameGroup
                ? layer.notesObject.getNoteGroup(resizedNote.start)
                : selectedNotes.getFirstGroup()

        const isLeft = timestepOffset < 0
        const fractionOffset = Time.timestepToFraction(
            isLeft ? -timestepOffset : timestepOffset,
            timestepRes
        )

        if (areNotesInTheSameGroup)
            return resizeSingleNoteGroup({
                noteGroup,
                fractionOffset,
                resizeType,
                isLeftDirection: isLeft,
                resizedNote,
                layer,
                timeSignature,
                timestepRes,
                score,
                type,
                maxScoreLength,
            })

        // resizing multiple note groups is not possible with the harmony lock feature since
        // we always deselect notes that are not in the same group as the first selected one
        // in the score-rendering.actions.ts file

        return resizeMultipleNoteGroups({
            selectedNotes,
            resizedNote,
            fractionOffset,
            resizeType,
            layer,
            timeSignature,
            timestepRes,
            isLeft,
            score,
            type,
            maxScoreLength,
        })
    }

    function resizeSingleNoteGroup({
        noteGroup,
        fractionOffset,
        resizeType,
        isLeftDirection,
        resizedNote,
        layer,
        timeSignature,
        timestepRes,
        score,
        type,
        maxScoreLength,
    }: {
        noteGroup: Note[]
        fractionOffset: string
        resizeType: HoveringType
        isLeftDirection: boolean
        resizedNote: Note
        layer: Layer
        timeSignature: TimeSignature
        timestepRes: number
        score: Score
        type: Quantization
        maxScoreLength?: FractionString
    }): [NotesObject, HoveringType] {
        const newSelectedNotes = new NotesObject()
        let newResizeType = resizeType
        let newStart: string
        let newDuration: string

        if (resizeType === "left") {
            if (
                !isLeftDirection &&
                Time.compareTwoFractions(
                    fractionOffset,
                    resizedNote.duration
                ) === ("gt" || "eq")
            ) {
                newDuration = Time.addTwoFractions(
                    fractionOffset,
                    resizedNote.duration,
                    true
                )
                newStart = Time.addTwoFractions(
                    resizedNote.start,
                    resizedNote.duration
                )
                newResizeType = flipResizeType(resizeType)
            } else {
                newStart = Time.addTwoFractions(
                    resizedNote.start,
                    fractionOffset,
                    isLeftDirection
                )
                newDuration = Time.addTwoFractions(
                    resizedNote.duration,
                    fractionOffset,
                    !isLeftDirection
                )
            }
        } else {
            if (
                isLeftDirection &&
                Time.compareTwoFractions(
                    fractionOffset,
                    resizedNote.duration
                ) === ("gt" || "eq")
            ) {
                newDuration = Time.addTwoFractions(
                    fractionOffset,
                    resizedNote.duration,
                    true
                )
                newStart = Time.addTwoFractions(
                    resizedNote.start,
                    newDuration,
                    true
                )
                newResizeType = flipResizeType(resizeType)
            } else {
                newStart = resizedNote.start
                newDuration = Time.addTwoFractions(
                    resizedNote.duration,
                    fractionOffset,
                    isLeftDirection
                )

                if (maxScoreLength) {
                    const maxDuration = Time.addTwoFractions(
                        maxScoreLength,
                        newStart,
                        true
                    )

                    if (
                        Time.compareTwoFractions(newDuration, maxDuration) ===
                        "gt"
                    ) {
                        newDuration = maxDuration
                    }
                }
            }
        }

        let modifyTimeValues = true

        // If harmony lock is enabled, we basically want to make sure that the resizing of the
        // note isn't changing the start or end value of the note to be outside of the chord's boundaries

        if (modifyTimeValues) {
            const quantizedResult = quantizeResizedDuration({
                newStart,
                newDuration,
                type,
                resizedElement: resizedNote,
                timeSignature: score.firstTimeSignature,
                timestepRes,
            })

            const result = zeroDurationResizeNoteProtection(
                timestepRes,
                quantizedResult.newStart,
                quantizedResult.newDuration,
                newResizeType,
                isLeftDirection,
                score.firstTimeSignature,
                type
            )

            newResizeType = result.newResizeType

            noteGroup.forEach(n => {
                n.duration = result.newDuration
                n.start = result.newStart
            })
        }

        newSelectedNotes.addNotesToGroup(
            noteGroup,
            timeSignature,
            score.sections
        )

        return [newSelectedNotes, newResizeType]
    }

    function quantizeResizedDuration(args: {
        newStart?: FractionString
        newDuration?: FractionString
        type: Quantization
        resizedElement: Note | PatternRegion
        timeSignature: TimeSignature
        timestepRes: number
    }) {
        if (args.type === undefined) {
            return {
                newStart: args.newStart,
                newDuration: args.newDuration,
            }
        }

        const quantizeStart =
            Time.compareTwoFractions(
                args.newStart,
                args.resizedElement.start
            ) !== "eq" && args.newStart !== undefined

        const quantizeDuration =
            Time.compareTwoFractions(
                args.newDuration,
                args.resizedElement.duration
            ) !== "eq" && args.newDuration !== undefined

        if (quantizeStart) {
            const temp = args.newStart
            args.newStart = Time.quantizeFractionToString(
                args.newStart,
                "round",
                getQuantizationTypeAsResolution(
                    args.type,
                    args.timeSignature,
                    args.timestepRes
                )
            )
        }

        if (quantizeDuration) {
            const end = Time.addTwoFractions(args.newDuration, args.newStart)

            args.newDuration = Time.addTwoFractions(
                Time.quantizeFractionToString(
                    end,
                    "round",
                    getQuantizationTypeAsResolution(
                        args.type,
                        args.timeSignature,
                        args.timestepRes
                    )
                ),
                args.newStart,
                true
            )
        }

        return {
            newStart: args.newStart,
            newDuration: args.newDuration,
        }
    }

    /**
     * This function is used to protect against the case where a note is resized to 0 duration.
     * @param timestepRes
     * @param newStart
     * @param newDuration
     * @param newResizeType
     * @param isLeftDirection
     * @returns
     */
    function zeroDurationResizeNoteProtection(
        timestepRes: number,
        newStart,
        newDuration,
        newResizeType,
        isLeftDirection,
        timeSignature,
        type
    ) {
        const lowestOffset = "1/" + timestepRes

        const offset = Time.quantizeFractionToString(
            lowestOffset,
            "ceil",
            getQuantizationTypeAsResolution(type, timeSignature, timestepRes)
        )

        if (Time.compareTwoFractions(newDuration, "0/1") === "eq") {
            if (newResizeType === "right" && isLeftDirection) {
                if (Time.compareTwoFractions(newStart, "0") !== "eq") {
                    newResizeType = flipResizeType(newResizeType)
                }
                newDuration = offset
            }

            if (newResizeType === "left" && isLeftDirection) {
                newStart = Time.addTwoFractions(newStart, offset, true)

                newDuration = offset
            }

            if (newResizeType === "left" && !isLeftDirection) {
                newResizeType = flipResizeType(newResizeType)
            }

            if (newResizeType === "right" && !isLeftDirection) {
                newDuration = offset
            }
        }

        return {
            newDuration,
            newStart,
            newResizeType,
        }
    }

    function flipResizeType(resizeType: HoveringTypeEnum): HoveringTypeEnum {
        return resizeType === HoveringTypeEnum.LEFT
            ? HoveringTypeEnum.RIGHT
            : HoveringTypeEnum.LEFT
    }

    function resizeMultipleNoteGroups({
        selectedNotes,
        fractionOffset,
        resizeType,
        layer,
        isLeft,
        timeSignature,
        timestepRes,
        score,
        type,
        maxScoreLength,
    }: {
        selectedNotes: NotesObject
        resizedNote: Note
        fractionOffset: string
        resizeType: HoveringType
        layer: Layer
        timeSignature: TimeSignature
        timestepRes: number
        isLeft: boolean
        score: Score
        type: Quantization
        maxScoreLength?: FractionString
    }): [NotesObject, HoveringType] {
        const newSelectedNotes = new NotesObject()

        selectedNotes.manipulateNoteGroups((group: Note[]) => {
            const n = group[0]

            const willOverlap = checkForCollisionWithSurroundings(
                layer.notesObject.getNoteGroupAndSurroundings(n.start),
                resizeType,
                isLeft,
                fractionOffset,
                n
            )

            let newNoteGroup: Note[] = group
            let willNoteReachItsMinimum = false

            let [newStart, newDuration] = calculateNoteDuration(
                n,
                isLeft,
                resizeType,
                fractionOffset
            )

            if (maxScoreLength) {
                const maxDuration = Time.addTwoFractions(
                    maxScoreLength,
                    newStart,
                    true
                )

                if (
                    Time.compareTwoFractions(newDuration, maxDuration) === "gt"
                ) {
                    newDuration = maxDuration
                }
            }

            if (
                Time.compareTwoFractions(newDuration, "0/1") === "eq" ||
                Time.compareTwoFractions(newDuration, `1/${timestepRes}`) ===
                    "lt" ||
                Time.compareTwoFractions(newStart, "0/1") === "lt"
            ) {
                willNoteReachItsMinimum = true
            }

            const quantized = quantizeResizedDuration({
                newStart,
                newDuration,
                type,
                resizedElement: n,
                timeSignature: score.firstTimeSignature,
                timestepRes,
            })

            if (!willOverlap && !willNoteReachItsMinimum) {
                newNoteGroup = layer.notesObject.changeNotesPosition({
                    notesToMove: group,
                    timeSignature,
                    sections: score.sections,
                    newStart: quantized.newStart,
                    newDuration: quantized.newDuration,
                })
            }

            newSelectedNotes.addNotesToGroup(
                newNoteGroup,
                timeSignature,
                score.sections
            )

            return true
        })

        return [newSelectedNotes, resizeType]
    }

    function checkForCollisionWithSurroundings(
        noteGroupAndSurroundings: NoteGroupAndSurroundings,
        resizeType: HoveringTypeEnum,
        isLeft: boolean,
        fractionOffset: string,
        n: Note
    ) {
        const previousGroup = noteGroupAndSurroundings.previousNoteGroup
        const nextGroup = noteGroupAndSurroundings.nextNoteGroup
        const noteInPreviousGroup = previousGroup ? previousGroup[0] : null
        const noteInNextGroup = nextGroup ? nextGroup[0] : null
        const noteGroup = noteGroupAndSurroundings.noteGroup
        if (!noteGroup) return false
        const noteInGroup = noteGroup[0]

        const collisionWithPreviousGroup =
            resizeType === "left" &&
            isLeft &&
            !!noteGroupAndSurroundings.previousNoteGroup &&
            Time.compareTwoFractions(
                Time.addTwoFractions(
                    noteInPreviousGroup.start,
                    noteInPreviousGroup.duration
                ),
                Time.addTwoFractions(noteInGroup.start, fractionOffset, true)
            ) === "gt"

        const collisionWithNextGroup =
            resizeType === "right" &&
            !isLeft &&
            !!noteGroupAndSurroundings.nextNoteGroup &&
            Time.compareTwoFractions(
                Time.addTwoFractions(
                    fractionOffset,
                    Time.addTwoFractions(n.start, n.duration)
                ),
                noteInNextGroup.start
            ) === "gt"
        return collisionWithNextGroup || collisionWithPreviousGroup
    }

    function calculateNoteDuration(
        note: Note,
        isLeft: boolean,
        resizeType: HoveringType,
        fractionOffset: string
    ): [string, string] {
        let newStart: string = note.start
        let newDuration: string = note.duration
        if (resizeType === "left") {
            newStart = Time.addTwoFractions(note.start, fractionOffset, isLeft)
            newDuration = Time.addTwoFractions(
                note.duration,
                fractionOffset,
                !isLeft
            )
        } else {
            newDuration = Time.addTwoFractions(
                note.duration,
                fractionOffset,
                isLeft
            )
        }
        return [newStart, newDuration]
    }

    export function deleteTrackBusses({
        layer,
        trackBusses,
    }: {
        layer: Layer
        trackBusses: TrackBus[]
    }) {
        const tbs = [...trackBusses]

        for (let l = layer.trackBuses.length - 1; l >= 0; l--) {
            const index = tbs.findIndex(tb => tb.id === layer.trackBuses[l].id)

            if (index !== -1) {
                layer.trackBuses.splice(l, 1)
                tbs.splice(index, 1)
            }
        }
    }

    /**
     * deletes either all notes of a layer or a set of notes that
     * was passed as an argument
     * @param { Score } score the score to be manipulated
     *
     * @param { Layer } layer
     *
     * @param { NotesObject } notes the notes to be removed. All notes of the given layer will be removed
     * 						   		if no notes are given
     */
    export function deleteNotes({
        score,
        layer,
        notes,
    }: {
        score: Score
        layer: Layer
        notes?: NotesObject
    }): NotesObject {
        if (!score || !layer) {
            return notes
        }

        if (!notes) {
            notes = layer.notesObject
        }

        notes.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
            for (let note of noteGroup) {
                layer.notesObject.deleteNoteIDsFromNoteGroup(note.start, [
                    note.noteID,
                ])
            }

            return true
        })

        return notes
    }

    export function deleteTrackBusRegions({
        layer,
        trackBusRegions,
    }: {
        layer: Layer
        trackBusRegions: RangeWithID[]
    }) {
        const regions = [...trackBusRegions]

        // TrackBusRegions don't apply to Percussion layers
        if (layer.type === "percussion") {
            return
        }

        for (const tb of layer.trackBuses) {
            for (let i = tb.blocks.length - 1; i >= 0; i--) {
                const region: RangeWithID = tb.blocks[i]

                const index = regions.findIndex(tbr => tbr.id === region.id)

                if (index !== -1) {
                    tb.blocks.splice(i, 1)
                    regions.splice(index, 1)
                }
            }
        }
    }

    export function deletePatternRegions({
        layer,
        patternRegions,
    }: {
        layer: Layer
        patternRegions: PatternRegion[]
    }) {
        if (layer.type !== "percussion") {
            return
        }

        for (
            let i = (<PercussionLayer>layer).patternRegions.length - 1;
            i >= 0;
            i--
        ) {
            const patternRegion = (<PercussionLayer>layer).patternRegions[i]

            if (patternRegions.some(pr => pr.id === patternRegion.id)) {
                ;(<PercussionLayer>layer).patternRegions.splice(i, 1)
            }
        }
    }

    export function drawNote({
        score,
        layer,
        timesteps,
        ysteps,
        defaultDuration,
        quantizeRes,
        timestepRes,
        timeSignature,
        maxScoreLength,
        harmonyLock,
        chords,
    }: {
        score: Score
        layer: Layer | PercussionLayer
        timesteps: number
        ysteps: number
        defaultDuration: FractionString
        quantizeRes: number
        timestepRes: number
        timeSignature: TimeSignature
        maxScoreLength?: FractionString
        harmonyLock: boolean
        chords: TemplateChord[]
    }): Note {
        let start = Time.timestepToFraction(timesteps, timestepRes)
        let duration = defaultDuration

        const adjusted = getNoteStartAndDurationBasedOnClosestGroup({
            start,
            pitch: ysteps,
            chords,
            harmonyLock,
            layer,
            quantizeRes,
            duration: defaultDuration,
            timeSignature,
            maxScoreLength,
        })

        start = adjusted.start
        duration = adjusted.duration
        const pitch = Math.min(
            Math.max(LOWEST_PIANO_PITCH, adjusted.pitch),
            HIGHEST_PIANO_PITCH
        )

        const note = new Note({
            pitch,
            start,
            duration,
            meta: {
                layer: layer.value,
                section: Note.getSectionForNoteStart(score?.sections, start),
                phrase: null,
            },
        })

        note.beat = Time.fractionToBeat(note.start, timeSignature)
        note.meta = {
            layer: layer.value,
            section: Note.getSectionForNoteStart(score?.sections, note.start),
            phrase: null,
        }

        layer.notesObject.addNoteToGroup(note, timeSignature, score?.sections)

        return note
    }

    export function removeNote({
        layer,
        timesteps,
        ysteps,
        defaultDuration,
        quantizeRes,
        timestepRes,
        timeSignature,
        maxScoreLength,
        harmonyLock,
        chords,
    }: {
        layer: Layer | PercussionLayer
        timesteps: number
        ysteps: number
        defaultDuration: FractionString
        quantizeRes: number
        timestepRes: number
        timeSignature: TimeSignature
        maxScoreLength?: FractionString
        harmonyLock: boolean
        chords: TemplateChord[]
    }): void {
        let start = Time.timestepToFraction(timesteps, timestepRes)

        const adjusted = getNoteStartAndDurationBasedOnClosestGroup({
            start,
            pitch: ysteps,
            chords,
            harmonyLock,
            layer,
            quantizeRes,
            duration: defaultDuration,
            timeSignature,
            maxScoreLength,
        })

        start = adjusted.start

        const noteGroup = layer.notesObject.getNoteGroup(start)

        const noteInGroup = noteGroup.findIndex(n => n.pitch === ysteps)

        if (noteInGroup !== -1) {
            noteGroup.splice(noteInGroup, 1)
        }
    }
    // really long function name, so there are no questions left here :P
    function getNoteStartAndDurationBasedOnClosestGroup({
        start,
        pitch,
        chords,
        harmonyLock,
        layer,
        quantizeRes,
        duration,
        timeSignature,
        maxScoreLength,
    }: {
        start: FractionString
        pitch: number
        layer: Layer
        quantizeRes: number
        duration: FractionString
        timeSignature: number[]
        maxScoreLength: FractionString
        chords?: TemplateChord[]
        harmonyLock?: boolean
    }) {
        const { previousNoteGroup, nextNoteGroup, noteGroup } =
            layer.notesObject.getNoteGroupAndSurroundings(start)

        const quantizedStart = Time.quantizeFractionToString(
            start,
            "floor",
            quantizeRes
        )
        const quantizedDuration =
            quantizeRes === 1
                ? Time.simplifyFraction(timeSignature[0], timeSignature[1])
                : "1/" + quantizeRes
        if (harmonyLock) {
            const absolutePitch = findNearestAvailableNote(
                pitch,
                ChordManipulation.getChordAtTime(start, chords)
            )

            if (absolutePitch) {
                pitch = absolutePitch
            }
        }

        const previousNoteEnd = !previousNoteGroup?.length
            ? "0"
            : Time.addTwoFractions(
                  previousNoteGroup[0].start,
                  previousNoteGroup[0].duration
              )
        const nextNoteStart = !nextNoteGroup
            ? "9999999999"
            : nextNoteGroup[0].start

        start = quantizedStart

        // draw a note to an existing group
        if (noteGroup?.length) {
            start = noteGroup[0].start
            duration = noteGroup[0].duration
        }

        // draw a note and create a new note group
        // here we need to define the new start and duration based on previous and next note groups
        else if (
            Time.compareTwoFractions(previousNoteEnd, quantizedStart) ===
                "gt" ||
            Time.compareTwoFractions(
                Time.addTwoFractions(start, duration),
                nextNoteStart
            ) === "gt"
        ) {
            // note start is in the range of the previous note
            if (
                Time.compareTwoFractions(previousNoteEnd, quantizedStart) ===
                "gt"
            ) {
                start = previousNoteEnd
            }

            const currentNoteEnd = Time.addTwoFractions(start, duration)

            // note end is in the range of the next note
            if (
                Time.compareTwoFractions(currentNoteEnd, nextNoteStart) === "gt"
            ) {
                duration = Time.addTwoFractions(nextNoteStart, start, true)
            }
        } else {
            duration =
                Time.compareTwoFractions(duration, quantizedDuration) === "lt"
                    ? quantizedDuration
                    : duration
        }

        if (maxScoreLength) {
            const maxDuration = Time.addTwoFractions(
                maxScoreLength,
                start,
                true
            )

            if (Time.compareTwoFractions(duration, maxDuration) === "gt") {
                duration = maxDuration
            }
        }
        return { start, duration, pitch }
    }

    export function setNoteAsSelected({
        score,
        note,
        timeSignature,
        add,
        currentSelectedNotes,
    }: {
        score: Score
        note: Note
        timeSignature: TimeSignature
        add?: boolean
        currentSelectedNotes?: NotesObject
    }) {
        if (add && !currentSelectedNotes?.length) {
            console.log(
                "You need to pass the currently selected notes in order to add a note to it."
            )
            return
        }

        const selectedNotes: NotesObject = new NotesObject()

        // If there are already selected notes, add them to the group
        if (currentSelectedNotes && add) {
            currentSelectedNotes.forEach(selectedNote =>
                selectedNotes.addNoteToGroup(
                    selectedNote,
                    timeSignature,
                    score?.sections
                )
            )
        }

        selectedNotes.addNoteToGroup(note, timeSignature, score?.sections)

        return selectedNotes
    }

    export function getNotesWithinTimeRange(args: {
        timeSignature: TimeSignature
        sections: Section[]
        layers: Layer[]
        start: string
        duration: string
    }): NotesObject[] {
        const newNotesObjects: NotesObject[] = []

        for (let layer of args.layers) {
            const newNotesObject = new NotesObject()

            layer.notesObject.manipulateNoteGroups((notes: Note[]) => {
                const groupKey: string = notes[0].start

                if (
                    Time.fractionIsInBoundaries(
                        { start: args.start, duration: args.duration },
                        groupKey
                    )
                ) {
                    newNotesObject.addNotesToGroup(
                        notes,
                        args.timeSignature,
                        args.sections
                    )
                }

                return true
            })

            newNotesObjects.push(newNotesObject)
        }

        return newNotesObjects
    }

    export function deleteNotesWithinTimeRange(args: {
        score: Score
        layers: Layer[]
        start: string
        duration: string
    }) {
        const noteIDs: string[] = []

        for (let layer of args.layers) {
            layer.notesObject.manipulateNoteGroups((notes: ImmutableNote[]) => {
                const groupKey: string = notes[0].start

                if (
                    Time.fractionIsInBoundaries(
                        { start: args.start, duration: args.duration },
                        groupKey
                    )
                ) {
                    notes.forEach(n => {
                        noteIDs.push(n.noteID)
                    })

                    layer.notesObject.deleteNoteGroup(groupKey)
                }

                return true
            })
        }

        return noteIDs
    }

    /**
     * The method makes sure that every note in the score has a given minimum duration
     * Notes with durations smaller than the minimum duration will be increased in duration,
     * notes with bigger duration will stay the same.
     *
     * ! Use it with caution! This method does not care about overlapping notes due to increasing note durations
     * @param args
     */
    export function applyMinimumDuration(args: {
        score: Score
        notesObject: NotesObject
        minDuration: string
    }) {
        const minDuration = args.minDuration
        const newNotesObject = new NotesObject()

        args.notesObject.manipulateNoteGroups((notes: ImmutableNote[]) => {
            for (let note of notes) {
                const newNote = new Note({
                    pitch: note.pitch,
                    start: note.start,
                    duration: note.duration,
                    meta: note.meta,
                    beat: note.beat,
                })

                // enlarge smaller duration to at least be minimum duration
                if (
                    Time.compareTwoFractions(
                        note.duration,
                        args.minDuration
                    ) === "lt"
                ) {
                    newNote.duration = minDuration
                }

                // reduce durations that are between min duration and twice min duration
                // this makes sure they only take up one cell and not more
                else if (
                    Time.compareTwoFractions(note.duration, minDuration) ===
                        "gt" &&
                    Time.compareTwoFractions(
                        note.duration,
                        Time.multiplyFractionWithNumber(minDuration, 2)
                    ) === "lt"
                ) {
                    newNote.duration = minDuration
                }

                newNotesObject.addNoteToGroup(
                    newNote,
                    args.score.firstTimeSignature,
                    args.score.sections
                )
            }

            return true
        })

        return newNotesObject
    }

    export function applyMinimumDurationToScore(args: {
        score: Score
        layers: Layer[]
        minDuration: string
    }) {
        for (let layer of args.layers) {
            args.score.layers[layer.value].notesObject = applyMinimumDuration({
                score: args.score,
                notesObject: layer.notesObject,
                minDuration: args.minDuration,
            })
        }
    }

    export function sortNotes(
        notes: (Note | TemplateNote)[]
    ): (Note | TemplateNote)[] {
        const sorted = [...notes].sort(function (a, b) {
            let aStart: any = Time.fractionToDictionary(a.start)
            aStart = aStart.numerator / aStart.denominator

            let bStart: any = Time.fractionToDictionary(b.start)
            bStart = bStart.numerator / bStart.denominator

            return aStart - bStart
        })

        return sorted
    }

    export function getMaxPercussionPatternID(
        layerObject: PercussionLayer
    ): number {
        let maxPatternID = 0

        for (let p = 0; p < layerObject.patterns.length; p++) {
            const id = layerObject.patterns[p].id

            if (id > maxPatternID) {
                maxPatternID = id
            }
        }

        maxPatternID += 1

        return maxPatternID
    }

    export function createEmptyPercussionPattern(
        layer: PercussionLayer
    ): Pattern {
        const maxPatternID = ScoreManipulation.getMaxPercussionPatternID(layer)
        const newPattern = new Pattern(maxPatternID)

        return newPattern
    }

    export function copyPercussionPattern(layer: PercussionLayer): Pattern {
        const maxPatternID = getMaxPercussionPatternID(layer)

        const selectedPattern: Pattern = layer.selectedPattern
        const copiedPattern = selectedPattern.copy()
        copiedPattern.id = maxPatternID
        copiedPattern.name = "Pattern " + maxPatternID

        return copiedPattern
    }

    export function calculatePatternResolution(pattern: Pattern): string {
        if (!pattern) {
            throw "pattern is undefined"
        }

        let resolution = 4

        for (const channel of pattern.channels) {
            for (let i = channel.onsets.length - 1; i >= 0; i--) {
                const onset = channel.onsets[i]
                const r = Time.fractionToDictionary(onset.start)
                let simplifiedStart = Time.simplifyFraction(
                    r.numerator,
                    r.denominator
                )

                const newDenominator =
                    Time.fractionToDictionary(simplifiedStart).denominator
                let newResolution = 4

                for (const allowedResolution of ALLOWED_NOTE_RESOLUTIONS) {
                    const newResDenominator =
                        Time.fractionToDictionary(allowedResolution).denominator

                    if (
                        Time.aIsDivisibleByB(newDenominator, newResDenominator)
                    ) {
                        newResolution = Math.max(
                            newResolution,
                            newResDenominator
                        )
                    }
                }

                resolution = Math.max(resolution, newResolution)
            }
        }

        /**
         * If the user-selected resolution is higher than the calculated resolution,
         * we want to keep the user-selected resolution. Otherwise, we want to use the
         * calculated resolution
         */

        const prevResolution = Time.fractionToDictionary(
            pattern.resolution
        ).denominator

        if (prevResolution > resolution) {
            return pattern.resolution
        }

        const resolutionString = `1/${resolution}`

        if (!ALLOWED_NOTE_RESOLUTIONS.includes(resolutionString)) {
            throw "Invalid resolution calculated for pattern."
        }

        return resolutionString
    }

    export function checkIfPatternResolutionWillRemoveNotes(
        pattern: Pattern,
        resolution: string
    ): boolean {
        if (!pattern) {
            throw "pattern is undefined"
        }

        const newResolutionDenominator = Number(resolution.split("/")[1])

        if (!newResolutionDenominator) {
            throw "Invalid resolution."
        }

        let willRemoveNotes = false

        pattern.channels.forEach(channel => {
            channel.onsets.forEach(onset => {
                const [nominator, denominator] = onset.start.split("/")
                let simplifiedStart = Time.simplifyFraction(
                    Number(nominator),
                    Number(denominator)
                )
                const newDenominator = Number(simplifiedStart.split("/")[1])
                if (newDenominator > newResolutionDenominator) {
                    willRemoveNotes = true
                }
            })
        })

        return willRemoveNotes
    }

    export function removePatternNotesWithHigherResolution(
        pattern: Pattern,
        resolution: string
    ): void {
        if (!pattern) {
            throw "pattern is undefined"
        }

        const newResolutionDenominator = Number(resolution.split("/")[1])

        if (!newResolutionDenominator) {
            throw "Invalid resolution."
        }

        for (const channel of pattern.channels) {
            for (let i = channel.onsets.length - 1; i >= 0; i--) {
                const onset = channel.onsets[i]
                const r = Time.fractionToDictionary(onset.start)

                let simplifiedStart = Time.simplifyFraction(
                    r.numerator,
                    r.denominator
                )

                const newDenominator =
                    Time.fractionToDictionary(simplifiedStart).denominator

                if (
                    !Time.aIsDivisibleByB(
                        newResolutionDenominator,
                        newDenominator
                    )
                ) {
                    channel.onsets.splice(i, 1)
                }
            }
        }
    }

    export function removeNotesInPasteRegion({
        score,
        layer,
        notes,
    }: {
        score: Score
        notes: NotesObject
        layer: Layer
    }): NotesObject {
        let notesToDelete: NotesObject
        if (featureFlags.useFractionClass) {
            notesToDelete = ScoreManipulation.detectOverlappingNotes2({
                selectedNotes: notes,
                layer: layer,
                score: score,
                timeSignature: layer.timeSignature,
                includePitchDetection: false,
                visibleFractionRange: [
                    new Fraction(notes.getFirstGroup()[0].start),
                    new Fraction(notes.getLastGroup()[0].getEnd()),
                ],
            })
        } else {
            notesToDelete = ScoreManipulation.detectOverlappingNotes({
                selectedNotes: notes,
                layer: layer,
                score: score,
                timeSignature: layer.timeSignature,
                includePitchDetection: false,
                visibleFractionRange: [
                    notes.getFirstGroup()[0].start,
                    notes.getLastGroup()[0].getEnd(),
                ],
            })
        }

        ScoreManipulation.deleteNotes({
            score: score,
            layer: layer,
            notes: notesToDelete,
        })

        return notesToDelete
    }

    export function computeSustainPedal(
        score: Score,
        sustainPedalFromChords: boolean
    ) {
        if (sustainPedalFromChords) {
            score.sustainPedal = computeSustainPedalFromChords(score.chords)
        } else {
            score.sustainPedal = computeSustainPedalWithHeuristic(score, false)
        }
    }

    export function computeSustainPedalWithHeuristic(score, isInfluence) {
        var uniquePitchesThreshold = 4
        var measureThreshold = isInfluence ? 1 : 2

        var pedal = []
        var timestepsSinceLastPedal = 0
        var uniquePitches = {}

        const timeSignature = score.timeSignatures[0][1]
        const measureInTimesteps =
            (timeSignature[0] / timeSignature[1]) * TIMESTEP_RES
        const barInTimesteps = 1 / timeSignature[1]

        var notes =
            score instanceof Score
                ? getGroupedNotesForPedalForScore(score)
                : getGroupedNotesForPedalForTemplateScore(score, isInfluence)

        var newNotes = Object.keys(notes)

        newNotes.sort(function (a, b) {
            var aStart: any = Time.fractionToDictionary(a)
            aStart = aStart.numerator / aStart.denominator

            var bStart: any = Time.fractionToDictionary(b)
            bStart = bStart.numerator / bStart.denominator

            return aStart - bStart
        })

        var lastNoteStart

        pedal.push(["0", 1])

        for (var n = 0; n < newNotes.length; n++) {
            const start = newNotes[n]
            var timestepsSinceLastNote = Time.fractionToTimesteps(
                TIMESTEP_RES,
                start
            )

            if (lastNoteStart != null) {
                timestepsSinceLastNote -= Time.fractionToTimesteps(
                    TIMESTEP_RES,
                    lastNoteStart
                )
            }

            var uniquePitchesForTimeslice = {}

            for (var m = 0; m < notes[newNotes[n]].length; m++) {
                const note = notes[newNotes[n]][m]
                const pitch = note % 12

                uniquePitches[pitch] = true
                uniquePitchesForTimeslice[pitch] = true
            }

            var timestepsBetweenNowAndNextNote = measureInTimesteps

            if (n + 1 <= newNotes.length) {
                timestepsBetweenNowAndNextNote = Time.fractionToTimesteps(
                    TIMESTEP_RES,
                    Time.addTwoFractions(newNotes[n + 1], start, true)
                )
            }

            if (
                timestepsBetweenNowAndNextNote + timestepsSinceLastPedal >
                measureThreshold * measureInTimesteps
            ) {
                pedal.push([newNotes[n + 1], 1])
                timestepsSinceLastPedal = 0
                uniquePitches = {}
            } else if (newNotes.length <= n + 1) {
                pedal.push([newNotes[n], 1])
                timestepsSinceLastPedal = 0
                uniquePitches = {}
            } else if (
                Object.keys(uniquePitches).length > uniquePitchesThreshold
            ) {
                pedal.push([newNotes[n], 1])

                timestepsSinceLastPedal = 0
                uniquePitches = uniquePitchesForTimeslice
            } else {
                timestepsSinceLastPedal += timestepsSinceLastNote
            }

            lastNoteStart = newNotes[n]
        }

        if (pedal.length > 0) {
            pedal.push([
                Time.addTwoFractions(pedal[pedal.length - 1][0], "2"),
                0,
            ])
        }

        return pedal
    }

    function getGroupedNotesForPedalForTemplateScore(
        score: TemplateScore,
        isInfluence
    ) {
        const notes = {}

        for (var t = 0; t < score.tracks.length; t++) {
            var track = score.tracks[t].track

            for (var n = 0; n < track.length; n++) {
                const note = track[n]

                if (!isInfluence) {
                    if (note.meta == null) {
                        continue
                    }

                    const compatibleLayer =
                        note.meta.layer != "Melody" &&
                        !note.meta.layer.includes("Percussion")

                    if (!compatibleLayer) {
                        continue
                    }
                }

                if (notes[note.start] == null) {
                    notes[note.start] = []
                }

                for (var p = 0; p < note.pitch.length; p++) {
                    notes[note.start].push(note.pitch[p])
                }
            }
        }

        return notes
    }

    function getGroupedNotesForPedalForScore(score: Score) {
        const notes = {}

        for (const l in score.layers) {
            const layer = score.layers[l]

            if (
                layer.value.includes("Melody") ||
                layer.value.includes("Percussion")
            ) {
                continue
            }

            layer.notesObject.manipulateNoteGroups(noteGroup => {
                if (notes[noteGroup[0].start] == null) {
                    notes[noteGroup[0].start] = []
                }

                for (const note of noteGroup) {
                    notes[note.start].push(note.pitch)
                }

                return true
            })
        }

        return notes
    }

    export function computeAdditionalMetadata(
        score: Score,
        metadataType: LayerFunctionType,
        layer: Layer | undefined,
        options: {
            sustainPedalFromChords: boolean
        }
    ) {
        if (layer === undefined) {
            return
        }

        if (metadataType === "pitched" && !layer.value.includes("Melody")) {
            computeSustainPedal(score, options.sustainPedalFromChords)
        }

        if (metadataType === "pitched" && layer.value.includes("Melody")) {
            score.computePhrases(layer)
        }
    }

    export function computeSustainPedalFromChords(
        inputChords: TemplateChord[]
    ): TemplateSustainPedal[] {
        const chords =
            ChordManipulation.getChordsWithAbsoluteTiming(inputChords)

        const processedChords = []

        for (let c = 0; c < chords.length; c++) {
            const chord = chords[c]

            if (c + 1 === chords.length) {
                processedChords.push(chord)
                continue
            }

            const nextChord = chords[c + 1]

            if (chord.chord === nextChord.chord) {
                chord.duration = Time.addTwoFractions(
                    chord.duration,
                    nextChord.duration
                )

                chords.splice(c + 1, 1)

                c--
            } else {
                processedChords.push(chord)
            }
        }

        const result = processedChords.map(
            c => [c.start, 1] as TemplateSustainPedal
        )

        if (result.length) {
            const end = Time.addTwoFractions(
                result[result.length - 1][0],
                processedChords[processedChords.length - 1].duration
            )

            result.push([end, 0] as TemplateSustainPedal)
        }

        return result
    }

    export function instrumentIsElectricGuitar(instrument) {
        if (
            instrumentIsCleanGuitar(instrument) ||
            instrumentIsDistGuitar(instrument)
        ) {
            return true
        }

        return false
    }

    export function instrumentIsAcousticGuitar(instrument) {
        if (instrument.search("s.ac-guitar") != -1) {
            return true
        }

        return false
    }

    export function instrumentIsDistGuitar(instrument) {
        if (instrument.search("s.e-guitar-dist") != -1) {
            return true
        }

        return false
    }

    export function instrumentIsCleanGuitar(instrument) {
        if (instrument.search("s.e-guitar-clean") != -1) {
            return true
        }

        return false
    }

    export function applyNoteStateToLayer(state: EditingState, layer: Layer) {
        const currentNotes: NotesObject = state.valuesAfterEditing

        let notesCopy: NotesObject = new NotesObject()

        for (let key in currentNotes) {
            if (!notesCopy[key]) {
                notesCopy[key] = []
            }

            for (let note of currentNotes[key]) {
                const copiedNote: Note = cloneDeep(note)

                notesCopy[key].push(copiedNote)
            }
        }

        layer.setNotesObject(notesCopy)
    }

    export function copy(args: {
        layer: Layer
        notes: NotesObject
        cut?: boolean
    }): NoteEditingClipboard {
        if (!args.notes?.length) {
            return
        }

        const notesArray = args.notes.getFlatArray()
        const layerType = args.layer.type
        const type = args?.cut ? "cut" : "copy"

        const notesclipboard = new NotesClipboard(type, "note", notesArray)

        const noteEditingClipboard: NoteEditingClipboard = {
            [layerType]: notesclipboard,
        }

        return noteEditingClipboard
    }

    export function getPasteStartOffset({
        seekTime,
        noteRegionStart,
        hasStartOffset,
        tempoMap,
    }: {
        seekTime: number // in seconds
        noteRegionStart: string
        hasStartOffset: boolean
        tempoMap: Tempo[]
    }): FractionString {
        if (!noteRegionStart) {
            return "0/1"
        }

        let seekTimeFraction = Time.secondsToFraction(
            seekTime,
            hasStartOffset,
            TIMESTEP_RES,
            tempoMap
        )

        const offset = Time.addTwoFractions(
            seekTimeFraction,
            noteRegionStart,
            true
        )

        return offset
    }

    export function paste(args: {
        score: Score
        layer: Layer
        clipboard: Note[]
        timestepRes: number
        startOffset: string // the offset of the notes to be pasted based on the first note start and the seek position
        maxScoreLength?: FractionString
    }): {
        notesToDelete: NotesObject
        selectedNotes: NotesObject
    } {
        if (!args?.clipboard?.length) {
            return
        }

        const timeSignature = args.layer.timeSignature
        const sections = args.score?.sections

        let notesToBePasted: NotesObject = new NotesObject()
        let notesToDelete: NotesObject = new NotesObject()

        // detect pasted notes
        for (let clipboardNote of args?.clipboard) {
            const note = cloneDeep(clipboardNote)

            note.meta.layer = args.layer.value
            note.start = Time.simplifyFractionFromString(
                Time.quantizeFractionToString(
                    Time.addTwoFractions(note.start, args.startOffset),
                    "round",
                    args.timestepRes
                )
            )
            note.meta.section = Note.getSectionForNoteStart(
                sections,
                note.start
            )
            note.beat = Time.fractionToBeat(note.start, timeSignature)

            if (args.maxScoreLength) {
                const maxDuration = Time.addTwoFractions(
                    args.maxScoreLength,
                    note.start,
                    true
                )

                if (
                    Time.compareTwoFractions(note.duration, maxDuration) ===
                    "gt"
                ) {
                    note.duration = maxDuration
                }
            }

            const startIsValid =
                Time.compareTwoFractions(note.start, "0") === "gt" ||
                Time.compareTwoFractions(note.start, "0") === "eq"
            const durationIsValid =
                Time.compareTwoFractions(note.duration, "0") === "gt"

            if (startIsValid && durationIsValid) {
                notesToBePasted.addNoteToGroup(note, timeSignature, sections)
            }
        }

        // delete unwanted notes
        if (args?.layer?.type !== "percussion") {
            notesToDelete = ScoreManipulation.removeNotesInPasteRegion({
                score: args.score,
                layer: args.layer,
                notes: notesToBePasted,
            })
        }

        const selectedNotes = new NotesObject()

        // paste notes from clipboard but make them unique by ID
        notesToBePasted.manipulateNoteGroups(noteGroup => {
            for (let note of noteGroup) {
                ;(note as any).noteID = uuidv4()
                args.layer.notesObject.addNoteToGroup(
                    note,
                    timeSignature,
                    sections
                )
                selectedNotes.addNoteToGroup(note, timeSignature, sections)
            }
            return true
        })

        return {
            notesToDelete: notesToDelete,
            selectedNotes: selectedNotes,
        }
    }

    export function updateLayerType({
        score,
        currentType,
        newType,
    }: {
        score: Score
        currentType: LayerType
        newType: LayerType
    }): Score {
        if (
            score === undefined ||
            currentType === undefined ||
            newType === undefined ||
            score.layers[currentType] === undefined
        ) {
            return
        }

        const layer = score.layers[currentType]
        const colors = Layer.getLayerColor(newType)

        // update layer
        layer.value = newType
        layer.defaultColor = colors.defaultColor
        layer.oppositeColor = colors.oppositeColor

        // update score
        delete score.layers[currentType]
        score.layers[newType] = layer

        return score
    }

    /**
     * updates the score's key signature as well as the note pitches
     * of all layer pitched layer objects
     * @param param0
     * @returns
     */
    export function updateKeySignature({
        score,
        keySignature,
        startOctave,
        updatePitches, // updates the note pitches so they have the same
    }: // interval position in the new key signature
    {
        score: Score
        keySignature: string
        startOctave?: number
        updatePitches?: boolean
    }): Score {
        const currentKeySignature = Score.getKeySignatureObject(
            score.keySignatures[0][1]
        )
        const newKeySignature = Score.getKeySignatureObject(keySignature)

        score.keySignatures[0][1] =
            newKeySignature.pitchClass + " " + newKeySignature.keyMode

        if (!updatePitches) {
            return score
        }

        // here we change the current note pitches in a scale to the new note pitches in another scale
        // note pitches outside of the current scale won't be taken into account!
        if (!startOctave) {
            startOctave = 2
        }

        const currentScale = KeySignatureModule.getTriadScalePitches(
            currentKeySignature,
            startOctave
        )
        const newScale = KeySignatureModule.getTriadScalePitches(
            newKeySignature,
            startOctave
        )
        const timeSignature = score.firstTimeSignature

        for (let l in score.layers) {
            const layer = score.layers[l]

            if (layer.type === "percussion") {
                continue
            }

            const notesObject = new NotesObject()

            layer.notesObject.manipulateNoteGroups((noteGroup: Note[]) => {
                for (let note of noteGroup) {
                    const index = currentScale.indexOf(note.pitch)

                    if (index === -1) {
                        continue
                    }

                    note.setPitch(newScale[index])

                    notesObject.addNoteToGroup(
                        note,
                        timeSignature,
                        score.sections
                    )
                }

                return true
            })

            layer.notesObject = notesObject
        }

        return score
    }

    export function getPhrasesForNotes(notes: NotesObject) {
        const phrases = []
        let currentPhrase: number | undefined

        notes.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
            if (currentPhrase !== noteGroup[0].meta.phrase) {
                phrases.push({
                    start: noteGroup[0].start,
                    end: noteGroup[0].getEnd(),
                    index: noteGroup[0].meta.phrase,
                })

                currentPhrase = noteGroup[0].meta.phrase
            } else {
                phrases[phrases.length - 1].end = noteGroup[0].getEnd()
            }

            return true
        })

        return phrases
    }

    export function addNotes(
        score: Score,
        layer: Layer,
        notes: Note[],
        changePitchRange?: boolean
    ) {
        score.editedChords = true

        layer.addNotes(
            score.timeSignatures[0][1],
            score.sections,
            notes,
            changePitchRange
        )
    }

    export function getSectionLength(sections, index) {
        var section = sections[index]

        if (sections.length > index + 1) {
            var nextSection = sections[index + 1]

            return Time.addTwoFractions(nextSection[0], section[0], true)
        } else {
            return "8/1"
        }
    }

    export function addTrackID(score) {
        for (var track of score.tracks) {
            if (track.id == null) {
                track.id = uuidv4()
            }
        }
    }

    function collapsePitchedLayers(layers: Layer[]): NotesObject {
        const notes: NotesObject = new NotesObject()

        for (let layer of layers) {
            layer.notesObject.manipulateNoteGroups(
                (noteGroup: ImmutableNote[]) => {
                    const start = noteGroup[0].start

                    if (!notes[start]) {
                        notes[start] = []
                    }

                    notes[start] = notes[start].concat(noteGroup)

                    return true
                }
            )
        }

        return notes
    }

    export function getModifiedSectionsInTimeRange(
        sections: Section[],
        start: FractionString,
        end: FractionString
    ): Section[] {
        const duration = Time.addTwoFractions(end, start, true)

        const selectedSections = []

        for (const section of sections) {
            if (
                section.operation === undefined ||
                section.operation.type === SECTION_EDITING.INSERT_BLANK ||
                Time.compareTwoFractions(section.end, start) !== "gt"
            ) {
                continue
            } else if (
                Time.fractionIsInBoundaries(
                    {
                        start,
                        duration,
                    },
                    section.start
                ) ||
                Time.fractionIsInBoundaries(
                    {
                        start: section.start,
                        duration: section.duration,
                    },
                    start
                )
            ) {
                selectedSections.push(section)

                continue
            }

            break
        }

        return selectedSections
    }

    export function getModifiedSectionsInTimeRange2(
        sections: Section[],
        start: Fraction,
        end: Fraction
    ): Section[] {
        const duration = Time2.addTwoFractions(end, start, true)

        const selectedSections = []

        for (const section of sections) {
            const sectionStart = new Fraction(section.start)
            const sectionEnd = new Fraction(section.end)
            const sectionDuration = new Fraction(section.duration)
            if (
                section.operation === undefined ||
                section.operation.type === SECTION_EDITING.INSERT_BLANK ||
                Time2.compareTwoFractions(sectionEnd, start) !== "gt"
            ) {
                continue
            } else if (
                Time2.fractionIsInBoundaries(
                    {
                        start,
                        duration,
                    },
                    sectionStart
                ) ||
                Time2.fractionIsInBoundaries(
                    {
                        start: sectionStart,
                        duration: sectionDuration,
                    },
                    start
                )
            ) {
                selectedSections.push(section)

                continue
            }

            break
        }

        return selectedSections
    }

    /**
     * Given two sections, check if they identical. This function will filter out inserted sections
     * @param sections1
     * @param sections2
     */
    export function sectionsAreEqualWithoutInsertedSections(
        sections1: TemplateSections[],
        sections2: TemplateSections[]
    ) {
        const filteredSections1 = removeInsertedSections(sections1)
        const filteredSections2 = removeInsertedSections(sections2)

        if (filteredSections1.length !== filteredSections2.length) {
            return false
        }

        for (let s = 0; s < filteredSections1.length; s++) {
            const section1 = filteredSections1[s]
            const section2 = filteredSections2[s]

            const onsetsAreDifferent =
                Time.compareTwoFractions(section1[0], section2[0]) !== "eq"
            const titlesAreDifferent = section1[1] !== section2[1]

            if (onsetsAreDifferent || titlesAreDifferent) {
                return false
            }
        }

        return true
    }

    export function removeInsertedSections(sections: TemplateSections[]) {
        const newSections = []

        let offset = "0"

        let counter = 0

        for (const section of sections) {
            if (section[1].includes("Insert")) {
                if (counter + 1 >= sections.length) {
                    break
                }

                offset = Time.addTwoFractions(
                    offset,
                    Time.addTwoFractions(
                        sections[counter + 1][0],
                        section[0],
                        true
                    )
                )
            } else {
                newSections.push([
                    Time.addTwoFractions(section[0], offset, true),
                    section[1],
                ])
            }

            counter++
        }

        return newSections
    }

    export function fractionIsInSections(
        fraction: FractionString,
        sections: Section[]
    ) {
        for (const section of sections) {
            if (
                Time.fractionIsInBoundaries(
                    {
                        start: section.start,
                        duration: section.duration,
                    },
                    fraction
                )
            ) {
                return true
            }
        }

        return false
    }

    /**
     * Converts each layer of a score to be monophonic.
     * The heuristic for this is to only keep the lowest note per
     * notegroup and remove the rest.
     * @param score
     */
    export function convertToMonophonicScore(score: Score) {
        for (let layerName in score.layers) {
            const layer = score.layers[layerName]
            const newNotesObject = new NotesObject()

            if (layer.type === "percussion") {
                continue
            }

            layer.notesObject.manipulateNoteGroups(noteGroup => {
                const lowestPitch = Math.min(...noteGroup.map(n => n.pitch))

                for (let note of noteGroup) {
                    if (note.pitch === lowestPitch) {
                        newNotesObject.addNoteToGroup(
                            note,
                            score.firstTimeSignature,
                            score.sections
                        )
                    }
                }

                return true
            })

            layer.notesObject = newNotesObject
        }
    }

    /**
     * Detects if a layer is polyphonic and returns a boolean value.
     * @param layer
     * @param selectedNotes
     * @returns
     */
    export function isPolyphonicLayer(
        layer: Layer,
        selectedNotes: NotesObject = new NotesObject()
    ) {
        let isPolyphonic = false

        if (layer.type === "percussion") {
            return false
        }

        if (selectedNotes?.length) {
            selectedNotes.manipulateNoteGroups(noteGroup => {
                if (noteGroup?.length > 1) {
                    isPolyphonic = true
                    return false
                }
                return true
            })
        }

        // return here, so we don't search in the layer noteobject as well
        if (isPolyphonic) {
            return isPolyphonic
        }

        layer.notesObject.manipulateNoteGroups(noteGroup => {
            if (!noteGroup?.length) {
                return true
            }

            // There is polyphony if either there is more
            // than one note in the notegroup, or if there
            // is at least one note in the layer notegroup and
            // at least one additional note in the selected notes
            // in the same group as well.
            const selectedNotesGroup =
                selectedNotes?.getNoteGroup &&
                selectedNotes.getNoteGroup(noteGroup[0].start)
                    ? selectedNotes.getNoteGroup(noteGroup[0].start)
                    : []

            if (noteGroup.length > 1 || selectedNotesGroup.length > 1) {
                isPolyphonic = true
                return false
            } else if (noteGroup?.length && selectedNotesGroup?.length) {
                const uniqueArray = [
                    ...new Set(noteGroup.concat(selectedNotesGroup)),
                ]

                if (uniqueArray.length > 1) {
                    isPolyphonic = true
                    return false
                }
            }

            return true
        })

        return isPolyphonic
    }

    export function getEffectsFromLayerInRange(
        layer: Layer,
        start: string,
        end: string
    ): { [effect: string]: number[] } {
        const effects = {}

        for (const e in layer.effects) {
            const fx = layer.effects[e]
            const range = {
                start: Time.fractionToTimesteps(AUTOMATION_TIMESTEP_RES, start),
                end: Time.fractionToTimesteps(AUTOMATION_TIMESTEP_RES, end),
            }

            effects[e] = fx.values.slice(range.start, range.end + 1)
        }

        return effects
    }

    export function convertCWNotesToNotes(
        cwNotes: CompositionWorkflowNote[]
    ): Note[] {
        if (!cwNotes?.length) {
            return []
        }

        const notes = []

        for (const cwNote of cwNotes) {
            for (let pitch of cwNote.pitch) {
                let meta = {
                    layer: undefined,
                    section: undefined,
                }

                const note = new Note({
                    pitch: pitch,
                    start: cwNote.start,
                    duration: cwNote.duration,
                    meta: meta,
                })

                notes.push(note)
            }
        }

        return notes
    }

    export function replaceNotesInLayerRange(
        score: Score,
        layer: Layer,
        notes: Note[],
        range: [FractionString, FractionString]
    ): Layer | PercussionLayer {
        const deletedNoteIDs = ScoreManipulation.deleteNotesWithinTimeRange({
            score: score,
            layers: [layer],
            start: range[0],
            duration: Time.addTwoFractions(range[1], range[0], true),
        })

        const newNotes = []

        // Make sure we stay in range
        for (let note of notes) {
            if (
                !Time.fractionIsInBoundaries(
                    {
                        start: range[0],
                        duration: Time.addTwoFractions(range[1], range[0]),
                    },
                    note.start,
                    false,
                    false
                )
            ) {
                continue
            }

            newNotes.push(note)
        }

        layer.notesObject.addNotesToGroup(
            notes,
            score.firstTimeSignature,
            score.sections
        )

        return layer
    }

    export function replaceLayerNotesInTimeRangeWithCWNotes(
        score: Score,
        layer: Layer | PercussionLayer,
        range: [FractionString, FractionString],
        cwNotes: CompositionWorkflowNote[]
    ) {
        const notes = convertCWNotesToNotes(cwNotes)

        // Add the section start to each note start, so the
        // new notes will start with the section and not at "0/1".
        for (let note of notes) {
            note.start = Time.addTwoFractions(note.start, range[0])
            note.meta.section = Note.getSectionForNoteStart(
                score.sections,
                note.start
            )
            note.meta.layer = layer.value
        }

        const newLayer = replaceNotesInLayerRange(score, layer, notes, [
            range[0],
            range[1],
        ])

        return newLayer
    }

    export function updatePercussionLayerSectionWithRegeneratedContent(
        score: Score,
        targetLayer: PercussionLayer,
        originalSection: Section,
        segmentedSection: SegmentedScoreSection,
        newLayerNotes: CompositionWorkflowNote[],
        instruments: InstrumentsJSON,
        samplesMap: SamplesMap,
        type: "layerInpainting" | "sectionInpainting"
    ) {
        ScoreManipulation.removeSectionFromPercussionLayer(
            targetLayer.value,
            score,
            originalSection
        )

        let newLayer =
            ScoreManipulation.replaceLayerNotesInTimeRangeWithCWNotes(
                score,
                targetLayer,
                [originalSection.start, originalSection.end],
                newLayerNotes
            )

        // A new Segmented Score Section Channel is created for each trackBus.
        // The 'notes' array will store the notes that have been converted from CW Note to Section Percussion Note.
        const segPercLayer: SegmentedScorePercussionLayer =
            segmentedSection.layers.find(
                l => l.value === targetLayer.value
            ) as SegmentedScorePercussionLayer

        segPercLayer.channels = []

        const trackbusWithMostChannels =
            ScoreManipulation.getTrackBusWithMostPercussionChannels(
                targetLayer,
                instruments
            )

        let newChannel: SegmentedScorePercussionChannel = {
            name: DEFAULT_PERCUSSION_INSTRUMENT,
            notes: [],
            gain: 0,
        }

        if (trackbusWithMostChannels) {
            newChannel = {
                name: trackbusWithMostChannels.name.split(".")[1],
                notes: [],
                gain: trackbusWithMostChannels.gainOffset,
            }
        }

        for (let note of newLayerNotes) {
            newChannel.notes.push(
                SegmentedScoreManipulationModule.convertCWNoteToSegmentedScorePercussionNote(
                    note
                )
            )
        }

        segPercLayer.channels = [newChannel]

        // Replace the updated layer in the Segmented Score Section
        for (let l = 0; l < segmentedSection.layers.length; l++) {
            if (segmentedSection.layers[l].value === targetLayer.value) {
                segmentedSection.layers = [segPercLayer]
                break
            }
        }

        // Since we only want to "merge" one section for one specific layer,
        // we can use the mergeSectionIntoScore function and pass the segmented
        // section to it that by now only contains one percussion layer.
        SegmentedScoreManipulationModule.mergeSectionIntoScore(
            segmentedSection,
            score,
            instruments,
            samplesMap,
            type,
            targetLayer
        )

        newLayer = score.layers[targetLayer.value]

        return newLayer
    }

    export function addTrackBusRegionsFromSectionV2(
        sourceSection: Section,
        newSectionIndex,
        score: Score
    ) {
        const sectionStartInTs = Time.fractionToTimesteps(
            TIMESTEP_RES,
            sourceSection.start
        )
        const sectionEndInTs = Time.fractionToTimesteps(
            TIMESTEP_RES,
            sourceSection.end
        )

        const newSectionStartInTs = Time.fractionToTimesteps(
            TIMESTEP_RES,
            score.sections[newSectionIndex].start
        )

        const newSectionEndInTs = Time.fractionToTimesteps(
            TIMESTEP_RES,
            score.sections[newSectionIndex].end
        )

        const sectionOffset = newSectionStartInTs - sectionStartInTs

        for (const layerName in score.layers) {
            const layerTrackBusses = score.layers[layerName].trackBuses

            if (score.layers[layerName].type === "percussion") {
                // Pattern regions handle which percussion instruments plays, so we skip percussion layers
                continue
            }

            for (const tb of layerTrackBusses) {
                // split track bus region to make space for the new section
                // At this stage, there should be no trackbus under the new section
                tb.blocks = Score.modifyTrackBusRegions(
                    newSectionStartInTs,
                    newSectionEndInTs,
                    tb.blocks,
                    "splice",
                    {
                        preciseCut: true,
                    }
                )

                // Extract the blocks from the source section individually
                // and offset them by the distance between the new section start and the source section start
                // to make sure that they are positioned under the new section
                const newBlocks = Score.getTrackBusRegionsAtBoundaries(
                    sectionStartInTs,
                    sectionEndInTs,
                    tb.blocks
                ).map(block => {
                    return {
                        id: uuidv4(),
                        start: block.start + sectionOffset,
                        end: block.end + sectionOffset,
                    }
                })

                // If there are no need blocks to add, there is no point in doing the extra merge computation
                if (newBlocks.length === 0) {
                    continue
                }

                // Add the extracted blocks to the new section for this trackbus
                tb.blocks = tb.blocks.concat(newBlocks)

                // Merge the blocks to avoid overlapping regions, and merge regions that are perfectly adjacent
                tb.blocks = mergeTrackBusRegions(tb.blocks).regions

                // Cleanup empty regions (e.g. regions where start == end)
                tb.blocks = cleanupEmptyTrackBusRegions(tb.blocks)
            }
        }
    }

    /**
     * @deprecated
     * @param newSection
     * @param sourceSection
     * @param newSectionIndex
     * @param score
     */
    export function addTrackBusRegionsFromSection(
        newSection,
        sourceSection,
        newSectionIndex,
        score: Score
    ) {
        const sectionStartInTs = Time.fractionToTimesteps(
            TIMESTEP_RES,
            newSection.start
        )
        const sectionEndInTs = Time.fractionToTimesteps(
            TIMESTEP_RES,
            newSection.end
        )
        for (const layerName in this.query.score.layers) {
            const layerTrackBusses =
                this.query.score.layers[layerName].trackBuses

            layerTrackBusses.forEach(tb => {
                tb.blocks = Score.modifyTrackBusRegions(
                    sectionStartInTs,
                    sectionEndInTs,
                    tb.blocks,
                    "splice",
                    {
                        preciseCut: true,
                    }
                )
            })
        }

        let sourceTbBlocks: {
            [layerName: string]: {
                [tbId: string]: RangeWithID[]
            }
        } = {}

        const sourceStartInTs = Time.fractionToTimesteps(
            TIMESTEP_RES,
            sourceSection.start
        )
        const sourceEndInTs = Time.fractionToTimesteps(
            TIMESTEP_RES,
            sourceSection.end
        )

        for (const layerName in this.query.score.layers) {
            const layerTrackBusses =
                this.query.score.layers[layerName].trackBuses

            layerTrackBusses.forEach(tb => {
                tb.blocks.forEach(block => {
                    if (block.start === block.end) return
                    if (
                        block.start >= sourceStartInTs &&
                        block.start < sourceEndInTs
                    ) {
                        if (!sourceTbBlocks[layerName]?.length) {
                            sourceTbBlocks[layerName] = {
                                [tb.id]: [],
                            }
                        }
                        // relative position
                        const newBlock: RangeWithID = {
                            id: uuidv4(),
                            start: Math.round(
                                Math.abs(sourceStartInTs - block.start)
                            ),
                            end:
                                block.end > sourceEndInTs
                                    ? Math.round(sourceEndInTs)
                                    : Math.round(block.end - sourceStartInTs),
                        }

                        sourceTbBlocks[layerName][tb.id].push(newBlock)
                    }
                })
            })
        }

        for (const layer in sourceTbBlocks) {
            for (const tb in sourceTbBlocks[layer]) {
                sourceTbBlocks[layer][tb].forEach(block => {
                    block.start += Math.round(
                        Time.fractionToTimesteps(
                            TIMESTEP_RES,
                            this.query.score.sections[newSectionIndex].start
                        )
                    )
                    block.end += Math.round(
                        Time.fractionToTimesteps(
                            TIMESTEP_RES,
                            this.query.score.sections[newSectionIndex].start
                        )
                    )
                })
            }
        }

        for (const layerName in this.query.score.layers) {
            const layerTrackBusses =
                this.query.score.layers[layerName].trackBuses

            if (sourceTbBlocks[layerName]) {
                layerTrackBusses.forEach(tb => {
                    if (sourceTbBlocks[layerName][tb.id]) {
                        tb.blocks.push(...sourceTbBlocks[layerName][tb.id])
                    }
                })
            }
        }
    }

    export function getTrackBusWithMostPercussionChannels(
        targetLayer: PercussionLayer,
        instruments: InstrumentsJSON
    ) {
        let trackBusWithMostChannels: TrackBus = undefined
        let trackBuses = {}

        for (let pattern of targetLayer.patterns) {
            for (let c of pattern.channels) {
                if (c.name === "Unassigned") {
                    continue
                }

                const name = c.trackBus?.name.split(".")[1]

                if (!trackBuses[name]) {
                    trackBuses[name] = 0
                }

                trackBuses[name] += 1
            }
        }

        // Define the trackbus with the most channels and then look it up in the layer trackbusses,
        //  then pass it to the segmented score layer
        let maxKey = Object.keys(trackBuses).reduce((a, b) =>
            trackBuses[a] > trackBuses[b] ? a : b
        )

        trackBusWithMostChannels = targetLayer.trackBuses.find(
            tb => tb?.name?.split(".")[1] === maxKey
        )

        // Fallback to the first trackbus if no trackbus has been found
        if (!trackBusWithMostChannels && targetLayer?.trackBuses[0]) {
            trackBusWithMostChannels = targetLayer.trackBuses[0]
        }

        // Fall back to a newly created trackBus in case no sufficient trackBus has been found
        if (!trackBusWithMostChannels) {
            trackBusWithMostChannels = new TrackBus(
                DEFAULT_PERCUSSION_INSTRUMENT,
                0,
                instruments["p"].find(
                    i => i.name === DEFAULT_PERCUSSION_INSTRUMENT
                ) as InstrumentJSON,
                [],
                0,
                0,
                0,
                0,
                false,
                false
            )
        }

        return trackBusWithMostChannels
    }

    /**
     * Returns a section name that is not already used in the given score sections.
     * It uses the current score sections in order to know which section names are already used and
     * which section name can be taken to alphabetically follow up on the last section name.
     * @param scoreSections SegmentedScoreSection[] the current score sections.
     * @returns string the new section name.
     */
    export function getNewSectionName(scoreSections: Section[]): string {
        let filteredArr = scoreSections
            .map(s => s.title.replace(/\d+$/, "")) // Remove trailing numbers
            .filter(item => /^[a-zA-Z]{1,3}$/.test(item)) // Filter items with only 1-3 letters

        filteredArr.sort((a, b) => a.localeCompare(b))

        let highestFirstLetterItem = filteredArr[filteredArr.length - 1]

        let nextLetter

        // If the filtered sections array is empty, start from 'A'
        // This can happen when there are no sections following
        // the naming scheme of one to three letters.
        if (!highestFirstLetterItem) {
            return "A"
        }
        // If the highest section name is 'Z', start from 'AA'
        else if (highestFirstLetterItem === "Z") {
            nextLetter = "AA"
        }
        // If the highest section name is two letters ending with 'Z' (like 'AZ'), increment the first letter and reset the second to 'A' (to 'BA')
        else if (
            highestFirstLetterItem.length === 2 &&
            highestFirstLetterItem[1] === "Z"
        ) {
            nextLetter =
                String.fromCharCode(highestFirstLetterItem.charCodeAt(0) + 1) +
                "A"
        }
        // If the highest section name is two letters not ending with 'Z' (like 'AB'), just increment the second letter (to 'AC')
        else if (highestFirstLetterItem.length === 2) {
            nextLetter =
                highestFirstLetterItem[0] +
                String.fromCharCode(highestFirstLetterItem.charCodeAt(1) + 1)
        }
        // If the highest section name is a single letter not 'Z', just increment it
        else {
            nextLetter = String.fromCharCode(
                highestFirstLetterItem.charCodeAt(0) + 1
            )
        }

        return nextLetter
    }
}
