<div class="modal-title">Downloading instruments</div>
<div class="modal-close">
    <img
        src="assets/img/close.svg"
        (click)="close.emit()" />
</div>

<img src="assets/img/download_desktop_app.svg" class="download-app-icon" />

<progressbar
    class="instruments-downloading-modal-progress-bar"
    [progress]="getTrackBusLoadingPercentage()"
    [total]="100"></progressbar>

<div class="modal-subtitle">
    We're downloading some virtual instruments that are necessary to playback
    your composition in real-time. This shouldn't take more than a few minutes,
    depending on your internet connection.
</div>
