import { Injectable } from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import {
    fromEvent,
    Observable,
    ReplaySubject,
    takeUntil,
    takeWhile,
    throttleTime,
} from "rxjs"
import { KeyboardEventActions } from "../../../common-lib/client-only/score-rendering-engine/types"
import { KeyboardHandler } from "../../../common-lib/general/modules/keyboard.module"
import { CompositionWorkflowService } from "@services/composition-workflow/composition-workflow.service"
import { IKeyboardEvents } from "@common-lib/interfaces/score/general"

@Injectable()
export class ParentClass {
    protected destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)

    constructor() {}

    protected subscribe(observable, higherOrderFunction) {
        observable.pipe(takeUntil(this.destroy$)).subscribe(higherOrderFunction)
    }

    protected registerEventListener(type: string, debounceTime: number) {
        return fromEvent<MouseEvent>(document, type).pipe(
            throttleTime(debounceTime),
            takeUntil(this.destroy$)
        )
    }

    static waitUntilTrueForObservable(
        observable$: Observable<any>,
        isTrue: (value) => boolean
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            observable$
                .pipe(takeWhile(value => !isTrue(value), true))
                .subscribe(value => {
                    if (isTrue(value)) {
                        resolve()
                    }
                })
        })
    }

    static handleScoreEditingKeyboardEvents({
        engine,
        event,
        allowedActions,
    }: {
        engine: IKeyboardEvents
        event: KeyboardEvent | any // todo: remove any when a solution for testing is found
        allowedActions: KeyboardEventActions[]
    }): KeyboardEventActions {
        if (event?.type === "keyup") {
            return
        }

        let action = KeyboardEventActions.NONE

        const functions = [
            KeyboardHandler.arrowKeys,
            KeyboardHandler.zoomIn,
            KeyboardHandler.zoomOut,
            KeyboardHandler.deleteSelectedData,
            KeyboardHandler.toggleCursorType,
            KeyboardHandler.cut,
            KeyboardHandler.copy,
            KeyboardHandler.paste,
            KeyboardHandler.selectAll,
            KeyboardHandler.resetZoom,
            KeyboardHandler.undo,
            KeyboardHandler.redo,
            KeyboardHandler.mute,
            KeyboardHandler.solo,
            KeyboardHandler.duplicate,
            KeyboardHandler.resetPlayback,
        ]

        while (action === KeyboardEventActions.NONE && functions.length > 0) {
            const f = functions.shift()
            action = f(event, allowedActions, engine)
        }

        return action
    }

    static handleCWKeyboardEvents({
        event,
        allowedActions,
        service,
    }: {
        event: KeyboardEvent | any
        allowedActions: KeyboardEventActions[]
        service: CompositionWorkflowService
    }): KeyboardEventActions {
        if (event?.type === "keyup") {
            return
        }

        let action = KeyboardEventActions.NONE

        const functions = [KeyboardHandler.undo, KeyboardHandler.redo]

        while (action === KeyboardEventActions.NONE && functions.length > 0) {
            const f = functions.shift()
            action = f(event, allowedActions, service)
        }

        return action
    }

    protected async blinkUI() {
        const toDelete = document.getElementById("blink")

        if (toDelete) {
            const parent = toDelete?.parentElement

            if (parent) {
                parent.removeChild(toDelete)
            }
        }

        const element = document.createElement("div")

        const elementToAppendTo = document.getElementById("blink-container")
        element.classList.add("blink-element", "blink-element-animation")

        elementToAppendTo.appendChild(element)

        await Misc.wait(0.35)

        try {
            elementToAppendTo.removeChild(element)
        } catch (e) {
            // this should happen in case a call to this function was made multiple times
            // Before the first call was finished
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true)
        this.destroy$.complete()
    }
}
