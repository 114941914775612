export class Queue<V> {
    private readonly maxLen = 50
    private val: Array<V> = new Array(this.maxLen)

    public get length() {
        return this.val.length
    }

    public push(value: V) {
        if (this.val.length === this.maxLen) {
            this.val.shift()
        }
        this.val.push(value)
    }

    public pop(): V {
        return this.val.pop()
    }

    public peek(): V | undefined {
        return this.val[this.val.length - 1]
    }
}
