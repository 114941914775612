import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
    ViewChild,
} from "@angular/core"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import { ModalService, SelectPackModal } from "@services/modal.service"
import HarmonyPack from "@common-lib/classes/generationprofiles/harmony/harmonypack"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import MelodyPack from "@common-lib/classes/generationprofiles/melody/melodypack"
import Pack from "@common-lib/classes/generationprofiles/pack"
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar"
import AccompanimentLayer from "@common-lib/classes/generationprofiles/accompaniment/accompanimentlayer"
import { ContextMenu } from "@clientmodels/contextmenu"
import { Router } from "@angular/router"
import { SourcePackService } from "../../../services/source-packs/sourcepacks.service"
import { LayerType } from "@common-lib/constants/constants"
import { LayersValue, LayerTypeValue } from "@common-lib/types/general"
import { GenerationProfileManipulation } from "@common-lib/modules/generation-profile-manipulation"
import { AccompanimentManipulation } from "@common-lib/modules/accompaniment-pack/accompaniment-manipulation"
import { environment } from "@environments/environment"
import { MenuOptions } from "../../reusable/menu-options/menu-options.component"
import { featureFlags } from "@common-lib/utils/feature-flags"

@Component({
    selector: "pack-dataset-modal",
    templateUrl: "pack-dataset-modal.component.html",
    styleUrls: ["./pack-dataset-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackDatasetModalComponent {
    @Input() input: SelectPackModal
    @Output() close: EventEmitter<any> = new EventEmitter()
    @Output() onEditPack = new EventEmitter<AccompanimentPack>()

    createPackLoading: boolean = false

    filter
    filterList = []
    filterButtonCoordinates = { x: 0, y: 0 } // we save them so we can click on filter badges/pills and still open the filter context menu from the filter button position
    scrollChange = { useBothWheelAxes: false, suppressScrollX: true }
    @ViewChild(PerfectScrollbarDirective, { static: false }) scrollbar
    @ViewChild("filters") filters: ElementRef
    perfectscroll: PerfectScrollbarDirective

    filteredPacks: { [key: string]: AccompanimentPack } = {}
    unfilteredPacks: AccompanimentPack[] = []

    loadingPacks: boolean = true
    selectedType: "aiva" | "otherUsers" | "currentUser" = "aiva"
    menuOptions: MenuOptions<AccompanimentPack>
    listItems: Array<any> = []

    get listMaxHeight() {
        const filtersHeight = this.filters?.nativeElement?.offsetHeight || 0
        const maxHeight = 510 - filtersHeight
        return maxHeight + "px"
    }

    constructor(
        private ref: ChangeDetectorRef,
        private modalService: ModalService,
        private gpService: GenerationProfileService,
        private sourcePacks: SourcePackService,
        private router: Router
    ) {}

    async ngOnInit() {
        this.loadingPacks = true

        if (this.input.openMenu !== undefined) {
            this.selectedType = this.input.openMenu
        }

        this.setAccompanimentPacks(
            await this.getAccompanimentPackDatasets(this.selectedType)
        )

        this.loadingPacks = false
        this.ref.detectChanges()

        this.filter = this.getFilterObject()

        this.modalService.contextMenus.accompanimentFilter.subscribe(res => {
            if (res != null && res.data != null) {
                this.changeFilter(res.data.changes)
            }
        })
    }

    closeModal() {
        this.close.emit()
    }

    sortCategory(values) {
        values.sort((a, b) => a.name - b.name)

        return values
    }

    selectPack(pack: MelodyPack | HarmonyPack | AccompanimentPack) {
        if (
            this.input.type == "change" &&
            this.input.pack != null &&
            pack instanceof AccompanimentPack
        ) {
            AccompanimentPack.changePack(this.input.pack, pack)

            this.gpService.checkForSyncIssues()
        } else {
            let newPack = this.input.layer.addPack(pack)

            if (!this.input.layer.name.includes("Melody")) {
                let maxIndex = 0

                for (let pack of this.input.layer.packs) {
                    maxIndex = Math.max(maxIndex, pack["idx"])
                }

                newPack["idx"] = maxIndex + 1
            }

            this.gpService.scrollTopForLayer.next(this.input.layer.name)
        }

        this.gpService.setAsUpdated("selectPack")
        this.modalService.modals.selectPack.next(undefined)
    }

    async getAccompanimentPackDatasets(
        userType: "aiva" | "otherUsers" | "currentUser"
    ) {
        const type = AccompanimentPack.getAccompanimentPackTypeByLayerType(
            this.input.layer.name as LayerType
        )

        return this.sourcePacks.getAccompanimentPacks({
            from: userType,
            layerType: type,
        })
    }

    getAccompanimentPackKeys() {
        return Object.keys(this.filteredPacks)
    }

    enableCreatePack() {
        return (
            !this.input.layer.name.includes("Ornaments") &&
            !this.input.layer.name.includes("Percussion")
        )
    }

    getFilteredAccompanimentPackDatasets() {
        let filteredDataset = {}

        let p = 0

        for (let pack of this.unfilteredPacks) {
            if (pack.deprecated) {
                continue
            }

            pack.lowestNote = AccompanimentManipulation.getLowestNoteByLayer(
                this.input.layer.name
            )
            pack["layerName"] = this.input.layer.name
            pack["index"] = p

            if (this.isValidAccordingToFilter(pack)) {
                if (filteredDataset[pack.category] == null) {
                    filteredDataset[pack.category] = []
                }

                filteredDataset[pack.category].push(pack)
                p++
            }
        }

        return filteredDataset
    }

    changeFilter(changes) {
        this.updateFilterList(changes)
        this.updateAccompanimentPacks()
        this.ref.detectChanges()
    }

    public async getPacks(type: "aiva" | "otherUsers" | "currentUser") {
        this.loadingPacks = true

        this.selectedType = type

        this.setAccompanimentPacks(
            await this.getAccompanimentPackDatasets(type)
        )

        this.loadingPacks = false

        this.ref.detectChanges()
    }

    private setAccompanimentPacks(packs) {
        this.unfilteredPacks = packs
        this.filteredPacks = this.getFilteredAccompanimentPackDatasets()
        this.setListItems()
    }

    private setListItems() {
        this.listItems = []

        for (let cat of this.getAccompanimentPackKeys()) {
            this.listItems.push({
                name: cat,
                type: "category",
            })

            this.listItems = this.listItems.concat(
                this.sortCategory(this.filteredPacks[cat])
            )
        }
    }

    getFilterOptionsByProperty(propertyName, sort = true) {
        let options = {}

        for (let pack of this.unfilteredPacks) {
            if (pack[propertyName] == null) {
                continue
            }

            for (let key of pack[propertyName]) {
                if (options[key] != null) {
                    continue
                }

                options[key] = false
            }
        }

        if (sort) {
            return this.sortObject(options)
        }

        return options
    }

    getFilterObject() {
        let filter = {
            tags: {
                name: "Styles",
                data: this.getFilterOptionsByProperty("tags"),
            },
            pacing: {
                name: "Pacing",
                data: this.getFilterOptionsByProperty("pacing"),
            },
        }

        if (this.input.layer.type != "pitched") {
            filter["percussionFunction"] = {
                name: "Percussion function",
                data: this.getFilterOptionsByProperty("percussionFunction"),
            }
        }

        return filter
    }

    showFilters(event) {
        if (event != null) {
            this.filterButtonCoordinates.x = event.x
            this.filterButtonCoordinates.y = event.y
        }

        this.modalService.contextMenus.accompanimentFilter.next(
            new ContextMenu(
                this.filterButtonCoordinates.x,
                this.filterButtonCoordinates.y,
                { filter: this.filter },
                [],
                null
            )
        )
    }

    sortObject(obj) {
        return Object.keys(obj)
            .sort()
            .reduce(function (result, key) {
                result[key] = obj[key]
                return result
            }, {})
    }

    isFiltering() {
        for (let filterCategory in this.filter) {
            for (let key in this.filter[filterCategory].data) {
                if (this.filter[filterCategory].data[key] == true) {
                    return true
                }
            }
        }

        return false
    }

    isValidAccordingToFilter(pack: AccompanimentPack) {
        let isFiltering = this.isFiltering()

        if (!isFiltering) {
            return true
        }

        let filteredItems = this.getFilteredItems()

        let isValidTags = true
        isValidTags = this.hasArrayElements(pack["tags"])

        if (filteredItems.tags.length > 0) {
            isValidTags = pack.tags.some(tag => {
                return filteredItems.tags.includes(tag)
            })
        }

        let isValidPacing = true
        isValidPacing = this.hasArrayElements(pack["pacing"])

        if (filteredItems.pacing.length > 0) {
            isValidPacing = pack.pacing.some(pacing => {
                return filteredItems.pacing.includes(pacing)
            })
        }

        let isValidPercussionFunction = true

        if (
            this.input.layer.type != "pitched" &&
            filteredItems.percussionFunction.length
        ) {
            isValidPercussionFunction = this.hasArrayElements(
                pack["percussionFunction"]
            )

            isValidPercussionFunction = pack.percussionFunction.some(pf => {
                return filteredItems.percussionFunction.includes(pf)
            })
        }

        return isValidTags && isValidPacing && isValidPercussionFunction
    }

    hasArrayElements(array) {
        return array != null && array.length > 0
    }

    getFilteredItems() {
        let filteredTags = []

        for (let key in this.filter["tags"].data) {
            if (this.filter["tags"].data[key] == true) {
                filteredTags.push(key)
            }
        }

        let filteredPacing = []

        for (let key in this.filter["pacing"].data) {
            if (this.filter["pacing"].data[key] == true) {
                filteredPacing.push(key)
            }
        }

        let filteredPercussionFunction = []

        if (
            this.filter["percussionFunction"] &&
            this.filter["percussionFunction"].data
        ) {
            for (let key in this.filter["percussionFunction"].data) {
                if (this.filter["percussionFunction"].data[key] == true) {
                    filteredPercussionFunction.push(key)
                }
            }
        }

        return {
            tags: filteredTags,
            pacing: filteredPacing,
            percussionFunction: filteredPercussionFunction,
        }
    }

    updateFilterList(changes: { key; value; checked }) {
        if (this.isFiltering() == false) {
            this.filterList = []
            return
        }

        if (changes == null) {
            return
        }

        if (changes.checked == true) {
            this.filterList.push({ key: changes.key, value: changes.value })
        } else {
            let index = this.filterList.findIndex(
                f => f.key == changes.key && f.value == changes.value
            )

            if (index != -1) {
                this.filterList.splice(index, 1)
            }
        }
    }

    async updateAccompanimentPacks() {
        this.filteredPacks = this.getFilteredAccompanimentPackDatasets()
        this.setListItems()
    }

    deleteFilter(filter, index, event) {
        if (this.filter[filter.key].data[filter.value] == true) {
            this.filter[filter.key].data[filter.value] = false
            this.filterList.splice(index, 1)
            this.updateAccompanimentPacks()
            this.ref.detectChanges()
        }
    }

    public async createAccompanimentPack() {
        this.createPackLoading = true

        if (this.input.pack instanceof AccompanimentPack) {
            await this.gpService.createAccompanimentPack(
                this.input.layer,
                this.input.pack
            )
        } else if (this.input.pack === undefined) {
            await this.gpService.createAccompanimentPack(this.input.layer)
        }

        this.createPackLoading = false

        this.closeModal()
    }

    editPack(pack: AccompanimentPack) {
        this.gpService.openAccompanimentDesigner(pack, this.input?.layer?.name)
        this.closeModal()
    }

    getTemplatePackMenuOptions() {
        const options = {
            coordinates: { x: 0, y: 0 },
            options: [
                {
                    icon: "assets/img/menu/open.svg",
                    text: "Select",
                    loading: false,
                    data: undefined,
                    onClick: this.selectPack.bind(this),
                },
                {
                    icon: "assets/img/menu/rename.svg",
                    text: "Rename",
                    buttonClass: "rename",
                    loading: false,
                    data: undefined,
                    onClick: undefined,
                    onConfirm: this.renameSelectedTemplatePack.bind(this),
                },
                {
                    icon: "assets/img/menu/delete.svg",
                    text: "Delete",
                    loading: false,
                    data: undefined,
                    buttonClass: "delete",
                    onClick: this.deleteSelectedTemplatePack.bind(this),
                },
            ],
        }

        return options
    }

    async deleteSelectedTemplatePack(pack: AccompanimentPack) {
        const res = await this.gpService.http.deleteTemplatePack(pack.packID)
        const itemWasDeleted =
            res["deletedPackIDs"]?.length && res["deletedPackIDs"]?.length > 0

        if (itemWasDeleted) {
            // Delete pack from unfiltered packs, so it does not show up
            // when we filter the packs again.
            const packID = res["deletedPackIDs"][0]
            const index = this.unfilteredPacks.findIndex(
                p => p.packID === packID
            )

            this.unfilteredPacks.splice(index, 1)

            this.gpService.pausePreviewForLayer(
                this.input.layer.name,
                pack.packID,
                null
            )

            this.gpService.removeSynchronisationForPacks([pack])
            this.gpService.setAsUpdated("deletePack")
        }

        return itemWasDeleted
    }

    async renameSelectedTemplatePack(pack: AccompanimentPack) {
        await this.gpService.http.renameTemplatePack(pack)
        this.gpService.setAsUpdated("renameTemplatePack")
    }

    showMenu(pack: AccompanimentPack) {
        return pack.template
    }
}
