import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { BehaviorSubject } from "rxjs"
import { ApiService } from "./api.service"
import { CompositionService } from "./composition.service"
import { FolderService } from "./folder.service"
import { InfluenceService } from "./influence.service"
import { Composition } from "../../../../common-lib/general/classes/general/composition"
import { ModalService } from "./modal.service"
import { GenerationProfileService } from "./generation-profile/generationprofile.service"
import { Misc } from "@common-lib/modules/misc"

@Injectable()
export class TracksService {
    trackView: BehaviorSubject<string> = new BehaviorSubject<string>(
        "trackslist"
    )

    constructor(
        private gpService: GenerationProfileService,
        private folderService: FolderService,
        private modalService: ModalService,
        private router: Router,
        private apiService: ApiService,
        private compositionService: CompositionService,
        private influenceService: InfluenceService
    ) {}

    /**
     *
     * @param viewName String view to change to
     * @param fetchFolderContent boolean defines if the folder content should be fetched after switching views
     */
    setTrackView(viewName) {
        this.trackView.next(viewName)

        if (viewName == "single-playlist") {
            this.folderService.sortValue = {
                type: 1,
                category: "rank",
            }

            this.folderService.sortContent(
                this.folderService.sortValue.category,
                this.folderService.sortValue.type
            )
        }
    }

    async redirectToMyTracks() {
        let folderID = ""

        if (this.folderService.contentType.getValue() === "Compositions") {
            folderID = this.folderService.getSelectedFolderID()
        }

        await this.folderService.setContentType(
            "redirectToMyTracks",
            "Compositions",
            folderID,
            true
        )

        this.modalService.justCreatedComposition.next(true)
    }

    async createComposition(parameters, type, influenceID = null) {
        if (type == "influence" && influenceID != null) {
            await this.createCompositionFromInfluence(influenceID, parameters)

            this.modalService.createWithInfluence.next("")
        } else if (type && type == "generationProfiles") {
            await this.createCompositionFromGP(parameters)

            this.modalService.compositionCreationWithGP.next(null)
        } else if (
            type &&
            (type === "presetStyle" || type === "presetUsecase")
        ) {
            await this.createCompositionFromParameters(parameters, type)

            this.modalService.presetUsecase.next(null)
        }
    }

    async createCompositionFromGP(parameters): Promise<any> {
        const apiCallParams = {
            generationProfileID: parameters.generationProfileID,
            folderID: parameters.folderID,
            parameters: parameters.generationParams,
            captchaResponse: parameters.captchaResponse,
            sourceGPFolder: parameters.sourceGPFolder,
            sourceGPCategory: parameters.sourceGPCategory,
        }

        try {
            await this.apiService.authRequest(
                "/generationprofile/createComposition",
                apiCallParams,
                "primary",
                true
            )

            this.folderService.selectedPlaylistID.next("")
            this.folderService.contentType.next("Compositions")
            this.folderService.selectedFolderID.next(parameters.folderID)

            if (
                this.modalService.compositionCreationWithGP.getValue()
                    .redirectTo === "home"
            ) {
                return this.redirectToMyTracks()
            } else {
                this.gpService.redirectToView.next("compositions")
            }

            return true
        } catch (err) {
            this.apiService.handleError(err)

            if (err === "Invalid generation profile") {
                this.gpService.openGenerationProfile(
                    parameters.generationProfileID
                )

                await Misc.wait(0.001)

                this.gpService.validateGenerationProfile(
                    this.gpService.generationProfile,
                    true
                )
            }

            return false
        }
    }

    createCompositionFromParameters(parameters, type) {
        var promise

        if (type == "presetStyle") {
            promise = this.apiService.authRequest(
                "/composition/original/createFromPreset",
                parameters,
                "primary",
                true
            )
        } else {
            promise = this.apiService.authRequest(
                "/composition/original/createFromPresetUsecase",
                parameters,
                "primary",
                true
            )
        }

        return promise
            .then(res => {
                if (res.result == -1) {
                    return Promise.resolve(false)
                }

                var compositions = this.folderService.content.getValue()

                for (let c = 0; c < res["compositions"].length; c++) {
                    res["compositions"][c].contentType = "composition"

                    const composition: Composition = res["compositions"][
                        c
                    ] as Composition

                    compositions.push(composition)

                    this.folderService.content.next(compositions)
                }

                return this.redirectToMyTracks()
            })

            .then(() => {
                return Promise.resolve(true)
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve()
            })
    }

    createCompositionFromInfluence(influenceID, parameters): Promise<any> {
        let authRequestRes

        return this.apiService
            .authRequest(
                "/composition/original/createFromInfluence",
                {
                    influenceID: influenceID,
                    duration: parameters.duration,
                    numberOfCompositions: parameters.numberOfCompositions,
                    ensemble: parameters.ensemble,
                    keySignature: parameters.keySignature,
                    saveChanges: parameters.saveChanges,
                    folderID: this.influenceService.createInCompositionFolder,
                    emotion: parameters.emotion,
                },
                "primary",
                true
            )
            .then(res => {
                authRequestRes = res

                return this.redirectToMyTracks()
            })

            .then(() => {
                return Promise.resolve(authRequestRes)
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve()
            })
    }
}
