import {
    Component,
    OnInit,
    HostListener,
    ViewChild,
    NgZone,
} from "@angular/core"
import { ApiService } from "../../services/api.service"
import { TokenService } from "../../services/token.service"
import { Router, ActivatedRoute } from "@angular/router"
import { environment } from "../../../environments/environment"
import { HelperService } from "../../helpers/helper.service"
import { accounts } from "google-one-tap"
import { Misc } from "@common-lib/modules/misc"
import { WindowService } from "@services/window.service"
import { UIUser } from "@common-lib/interfaces/general"

declare var google: any

@Component({
    selector: "app-createaccount",
    templateUrl: "createaccount.component.html",
    styles: [],
})
export class CreateAccountComponent implements OnInit {
    createAccountType = 0
    user: UIUser = { email: "", tnc: false, referralID: "", idToken: "" }
    loading: boolean = false
    captchaResponse: string = ""
    captcha = false
    title: string = "Create an account"
    message: { type?: string; text?: string; show?: boolean } = {
        type: "",
        text: "",
        show: false,
    }

    @ViewChild("captchaRef", { static: false }) captchaRef

    constructor(
        private apiService: ApiService,
        private route: ActivatedRoute,
        private router: Router,
        private tokenService: TokenService,
        private helperService: HelperService,
        private ngZone: NgZone,
        private windowService: WindowService
    ) {
        this.route.queryParams.subscribe(queryParams => {
            this.user.referralID = queryParams["r"]
        })

        this.apiService.captcha.subscribe(value => {
            this.captcha = value
        })
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            const tnc = params["tnc"]
            const service = params["service"]
            const email = params["email"]
            const idToken = params["idToken"]

            if (tnc == "true" && service != null && idToken != null) {
                this.user["email"] = email
                this.user["idToken"] = idToken

                this.goToTNC()
            }

            if (params["redirectToApp"] == "true") {
                localStorage.setItem("redirectToApp", "true")
            }
        })
    }

    ngAfterViewInit() {
        this.initGoogleSignIn()

        setTimeout(() => {
            const gAccounts: accounts = google.accounts

            this.renderGoogleSignInButton()
        }, 0)
    }

    initGoogleSignIn() {
        const gAccounts: accounts = google.accounts

        gAccounts.id.initialize({
            client_id: environment.googleClientID,
            ux_mode: "popup",
            cancel_on_tap_outside: true,
            callback: res => {
                this.ngZone.run(() => {
                    this.signInWithGoogle(res.credential)
                })
            },
        })

        setTimeout(() => {
            this.renderGoogleSignInButton()
        }, 0)
    }

    renderGoogleSignInButton() {
        const gAccounts: accounts = google.accounts

        gAccounts.id.renderButton(
            document.getElementById("google-btn") as HTMLElement,
            {
                size: "large",
                text: "continue_with",
                width: 330,
                locale: "en_US",
                // theme: "filled_blue",
            }
        )
    }

    goToLogin() {
        this.router.navigate(["/"])
    }

    goToTNC() {
        this.createAccountType = 1
        this.title = "Finalize account creation"
        this.hideMessage()
    }

    goToCreateAccount() {
        this.createAccountType = 0
        this.title = "Create an account"
    }

    getCaptchaKey() {
        return environment.recaptchaSiteKey
    }

    createAccount(captchaResponse) {
        this.apiService
            .oneAccountPerPerson(this.user.email)
            .then(() => {
                this.apiService.updateError("")
                this.user["captchaResponse"] = captchaResponse
                this.loading = true
                this.hideMessage()

                return this.loginLoadingAnimation()
            })

            .then(() => {
                return this.apiService.createAccount(this.user)
            })

            .then(result => {
                this.loading = false
                this.showMessage("success", result)
                this.goToCreateAccount()
            })

            .catch(err => {
                this.loading = false
                this.showMessage("error", err)
            })
    }

    async createAccountWithGoogle(captchaResponse) {
        await this.apiService.oneAccountPerPerson(this.user.email)

        this.user["captchaResponse"] = captchaResponse
        this.loading = true
        this.hideMessage()

        // check if Google Sign in was succesful
        if (this.user.email != "" && this.user.idToken == "") {
            this.showMessage(
                "error",
                "An error occured. Please cancel and try again later."
            )
            return Promise.resolve()
        }

        if (this.user.tnc == true) {
            // call creaton method
            await this.loginLoadingAnimation()

            const tokens = await this.apiService.createAccountWithGoogle(
                this.user
            )

            this.loading = false

            // Login with tokens
            this.tokenService.setToken(tokens.token)
            this.tokenService.setRefreshToken(tokens.refreshToken)
            await this.tokenService.generateToken()

            await this.apiService.setUser(this.user.email, "google")

            this.hideMessage()
            this.loading = false
            this.router.navigate(["/"])

            this.apiService.isLoggedIn.next(true)
        } else {
            this.loading = false
            this.showMessage(
                "error",
                "You must accept the Terms & Conditions to create an account."
            )
        }

        return
    }
    catch(err) {
        this.loading = false
        this.showMessage("error", err)
    }

    /**
     * Hides login hint as soon as the user starts typing in the email input field
     * @param value Input value of email input field
     */
    emailInput(value) {
        if (this.message.show) {
            if (value != "") {
                this.hideMessage()
            }
        }
    }

    tncChange(value) {
        this.hideMessage()
    }

    showMessage(type: string, text: string) {
        if (type && text) this.message = { type: type, text: text, show: true }
    }
    hideMessage() {
        this.message = { type: "", text: "", show: false }
    }

    signInWithGoogle(credential): void {
        try {
            if (this.windowService.desktopAppAPI) {
                window.open(
                    environment.domain + "/createaccount?redirectToApp=true",
                    "_blank"
                )
                this.router.navigate(["/waitingonbrowserlogin", "create"])
                return
            }

            this.hideMessage()
            this.loading = true

            if (credential) {
                const decodedJWT = this.helperService.decodeJwt(credential)

                this.user["email"] = this.helperService.replaceAll(
                    decodedJWT["email"],
                    " ",
                    ""
                )
                this.user["idToken"] = credential
            }

            this.loading = false

            this.goToTNC()
        } catch (error) {
            this.loading = false
            this.showMessage(
                "error",
                "Log in or authorization failed. Please try again."
            )
        }
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key == "Enter") {
            if (this.captcha) {
                this.captchaRef.execute()
            }
        }
    }

    // The timeout is here to see the loading animation and provide a visual cue, in case connection is fast
    loginLoadingAnimation() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true)
            }, 200)
        })
    }
}
