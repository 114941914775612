import HarmonyPack from "@common-lib/classes/generationprofiles/harmony/harmonypack"
import MelodyPack from "@common-lib/classes/generationprofiles/melody/melodypack"
import { FormDescriptionPacks } from "@common-lib/interfaces/music-engine/generationprofile"
import { DrumSamplesMap, InstrumentsJSON, PitchToChannelMapping } from "@common-lib/interfaces/score/general"
import { Store, StoreConfig } from "@datorama/akita"

export interface SourcePackState {
    melody: MelodyPack[],
    harmony: HarmonyPack[]
    formDescriptionPacks: FormDescriptionPacks | undefined
}

const initialState: SourcePackState = {
    melody: [],
    harmony: [],
    formDescriptionPacks: undefined
}

@StoreConfig({ name: "SourcePacks" })
export class SourcePackStore extends Store<SourcePackState> {
    constructor() {
        super(initialState)
    }
}
