<div class="modal-title" *ngIf="!loading">
    Inpaint {{ input.position }}
    {{ input.section.title }}
</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div *ngIf="loading" class="loading-wrapper">
    <div class="particles">
        <div class="wrap">
            <div class="c" *ngFor="let _ of dotsArray"></div>
        </div>
    </div>

    <div class="loading-text">Composing your section, please wait</div>
</div>

<div *ngIf="!loading">
    <div class="settings-row" *ngIf="!enableSectionInpainting">
        {{ disableSectionInpaintingMessage }}
    </div>
    <div class="settings-row">
        <div class="settings-row-title">Empty section</div>
        <div class="settings-row-value settings-row-section-length">
            <slide-toggle
                [(toggled)]="emptySection"
                (onChange)="setEmptySection($event)"
                [disabled]="!enableSectionInpainting"></slide-toggle>
        </div>
    </div>

    <div class="settings-row" *ngIf="copySection === false">
        <div class="settings-row-title">Number of measures</div>
        <div class="settings-row-value settings-row-section-length">
            <ngx-slider
                class="insert-section-length"
                [(value)]="sectionLength"
                [options]="getLengthOptions()"></ngx-slider>
        </div>
    </div>

    <div class="settings-row" *ngIf="!emptySection">
        <div
            class="settings-row-title"
            [tooltip]="tooltips.sourceSection"
            [tooltip-marginTop]="10"
            [tooltip-marginRight]="20"
            [tooltip-position]="'middle-bottom'"
            [tooltip-width]="250">
            Source section
        </div>
        <div class="settings-row-value">
            <dropdown
                class="insert-section-modal"
                type="light"
                [showArrow]="true"
                [description]="''"
                [showTooltip]="false"
                [value]="sourceSectionIndex"
                [items]="filteredSections"
                (selectedItem)="selectSourceSection($event)"></dropdown>
        </div>
    </div>

    <div
        class="settings-row"
        *ngIf="!emptySection && sourceSectionIndex !== undefined">
        <div class="settings-row-title">
            Copy section
            <img
                class="tooltip-img"
                src="assets/img/info.svg"
                [tooltip]="
                    'Toggle this option to copy without changing the notes of the source section into the newly inserted section'
                "
                [tooltip-marginTop]="10"
                [tooltip-width]="250" />
        </div>
        <div class="settings-row-value settings-row-section-length">
            <slide-toggle
                [(toggled)]="copySection"
                (onChange)="setCopySection($event)"></slide-toggle>
        </div>
    </div>

    <div class="button-wrapper">
        <a-button
            type="secondary"
            label="Confirm"
            (click)="apply()"
            width="100%"></a-button>
    </div>
</div>
