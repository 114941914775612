<!-- <div id="success-message" *ngIf="successMessage != ''">{{ successMessage }}</div> -->

<div id="login-component" class="component-container">
    <!-- Main Login window -->
    <div id="login-window-wrapper">
        <div class="aiva-logo">
            <img src="assets/img/navbar/aiva_white.svg">
            <div class="aiva-logo-text">AIVA</div>
        </div>
        
        <div id="login-window" class="window">
            <div id="title">{{ title }}</div>

            <div class="message-box" *ngIf="message.show" [ngClass]="message.type">
                <div class="close-button" (click)="hideMessage()">
                    <img src="assets/img/close.svg">
                </div>
                <div class="text"> {{ message.text }} </div>
            </div>

            <!-- Login first step -->
            <div class="login-first-step" *ngIf="loginType == 0">

                <div class="service-login-container">
                    <div class="button-container">
                        <div id="google-btn"></div>
                    </div>
                </div>
                
                <div class="or-seperator">
                    <hr class="horizontal-left"> <span>OR</span>
                    <hr class="horizontal-right">
                </div>
                
                <input placeholder="Email address" [(ngModel)]="user.email" (keydown)="onKeyDown($event)" (ngModelChange)="emailInput($event)" name="email" type="email">
                
                <div id="button-container">
                    <div class="loader" *ngIf="loading"></div>
                    <div class="round-button" (click)="goToLoginFinal()" *ngIf="!loading">Continue</div>
                </div>
                
                <div class="additional-options">
                    <div class="text-button" (click)="goToCreateAccount()"><u>Create an account</u></div>
                    <div id="forgot-password" (click)="goToForgotPassword()"><u>Reset my password</u></div>
                </div>

            </div>

            <!-- Login final step -->
            <div class="login-final-step" *ngIf="loginType == 1">
                <input placeholder="Email address" [(ngModel)]="user.email" (keydown)="onKeyDown($event)" name="email" (ngModelChange)="emailInput($event)">
                <input placeholder="Password" type="password" #loginPasswordField [(ngModel)]="user.password" (keydown)="onKeyDown($event)" name="password">
                <!--<re-captcha (resolved)="resolved($event)" siteKey="6Leuzq4UAAAAAIawqiYpAbeHIDvh4b0eLhdimOSW" class="recaptcha"></re-captcha>-->
                <div id="button-container" *ngIf="captcha">
                    <re-captcha #captchaRef="reCaptcha" siteKey="{{ getCaptchaKey() }}" size="invisible" (resolved)="login($event)">
                    </re-captcha>
                
                    <div class="loader" *ngIf="loading"></div>
                    <div class="round-button" (click)="captchaRef.execute()" *ngIf="!loading">Sign in</div>
                    <!-- <div class="round-button" (click)="login()" *ngIf="!loading">Sign in</div> -->
                </div>
                
                <div id="button-container" *ngIf="!captcha">
                    <div class="loader" *ngIf="loading"></div>
                    <div class="round-button" (click)="login('test')" *ngIf="!loading">Sign in</div>
                </div>
                
                <div class="text-button" (click)="goToLogin()">Cancel</div>

                <div class="additional-options">
                    <div class="text-button" (click)="goToCreateAccount()"><u>Create an account</u></div>
                    <div id="forgot-password" (click)="goToForgotPassword()"><u>Reset my password</u></div>
                </div>

            </div>

            <!-- Reset password window -->
            <div id="reset-password" *ngIf="loginType == 2">

                <input placeholder="Email address" [(ngModel)]="user.email" name="email" type="email" (ngModelChange)="emailInput($event)">
            
                <div id="button-container">
                    <div class="round-button" (click)="resetPassword()">Reset</div>
                </div>
            
                <div class="text-button" (click)="goToLogin()">Cancel</div>
            </div>
            
            <small class="re-captcha mobile-only">This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </small>
        </div>
    </div>
</div>

<small class="re-captcha desktop-only">This site is protected by reCAPTCHA and the Google 
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
</small>