<div class="modal-title">Unsupported Operating System</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<p>
    We're sorry, but the version of your operating system is not supported. We currently only support macOS 13 (Ventura) and above for Apple silicon Macs and macOS 11 (Big Sur) for Intel Macs.
<p>
    Please upgrade your version of macOS to use the AIVA desktop app.
</p>
