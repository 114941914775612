import Layer from "../../../general/classes/score/layer"
import {
    ImmutableNote,
    Note,
    NoteLabel,
} from "../../../general/classes/score/note"
import Score from "../../../general/classes/score/score"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    AccompanimentDesignerCanvasContext,
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    GridDimensions,
    ScoreCanvasContext,
    GridDimensionsDictionary,
    CanvasType,
    PianoCanvasContext,
} from "../types"
import ScoreCanvas from "./score-canvas"
import { cloneDeep } from "lodash"
import { AccompanimentDesignerCanvas } from "./accompaniment-designer"
import {
    PITCH_CONTINOUS_COUNT,
    PITCH_SCALE_COUNT,
} from "../../../general/constants/constants"

export class PianoCanvas extends ScoreCanvas {
    private layer: Layer

    constructor(
        public context: PianoCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)
    }

    private getPitchCount(grid) {
        const pitchCount = this.context.pitchCount
            ? this.context.pitchCount
            : grid[this.gridType].pitchStepDomain === "scale"
            ? PITCH_SCALE_COUNT
            : PITCH_CONTINOUS_COUNT

        return pitchCount
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        if (
            !scoreState.score ||
            !scoreState.toggledLayer ||
            !this.shouldRenderCanvas(scoreState.renderingType)
        ) {
            return
        }

        const score: Score = scoreState.score
        this.layer = this.queries.scoreRendering.toggledLayer

        const copiedGrid: GridDimensionsDictionary = cloneDeep(grid)

        const pxLength =
            copiedGrid[this.gridType].scoreLengthTimesteps *
                copiedGrid[this.gridType].pxPerTimestepWithoutGutters +
            copiedGrid[this.gridType].pxLengthForGutters

        if (pxLength < this.width) {
            copiedGrid[this.gridType].pxPerTimestepWithoutGutters =
                (this.width - copiedGrid[this.gridType].pxLengthForGutters) /
                copiedGrid[this.gridType].scoreLengthTimesteps
        }

        const nbOfYValues = this.getPitchCount(copiedGrid)

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: copiedGrid,
            noteRes: copiedGrid[this.gridType].timestepRes,
            nbOfYValues: nbOfYValues,
        })

        if (this.grid.pitchStepDomain === "scale") {
            this.renderScale(score.keySignatures[0][1])
        } else if (this.grid.pitchStepDomain === "continuous") {
            this.renderPiano()
        }
    }

    private renderScale(key: string) {
        const ctx = this.getContext("canvas")
        const fontSizePercentage = 55
        const forceFlat = key.charAt(1) === "b"

        AccompanimentDesignerCanvas.traverseGridScale(
            this.nbOfYValues,
            key,
            this.pxPerYStep,
            (y, pitch) => {
                ScoreCanvas.generateNoteRect(
                    ctx,
                    0,
                    y,
                    "rgba(255,255,255,0.1)",
                    4,
                    this.width,
                    this.pxPerYStep - this.grid.pxPerPitchGutter
                )

                ScoreCanvas.drawText(
                    ctx,
                    this.width / 2 + 1,
                    y + this.pxPerYStep / 2 + 1,
                    Note.getNoteString(pitch, forceFlat),
                    fontSizePercentage,
                    "bold",
                    "white"
                )
            }
        )
    }

    private renderPiano() {
        const ctx = this.getContext("canvas")
        const fontSizePercentage = 55
        const forceFlat = this.queries?.scoreRendering?.score?.keySignatures
            ?.length
            ? this.queries?.scoreRendering?.score?.keySignatures[0][1].charAt(
                  1
              ) === "b"
            : false

        // draw white background
        ScoreCanvas.generateNoteRect(
            ctx,
            0,
            0,
            "white",
            0,
            this.width,
            this.height - this.grid.pxPerPitchGutter
        )

        this.traversePitchGrid(
            (
                pixelsY: number,
                absolutePitch: number,
                relativePitch: number,
                pitchType: string
            ) => {
                if (relativePitch === 0) {
                    // draw note border at octave
                    ScoreCanvas.drawHorizontalLine(
                        ctx,
                        0,
                        this.width,
                        pixelsY + this.pxPerYStep,
                        "rgba(0,0,0,0.5)"
                    )

                    ScoreCanvas.drawText(
                        ctx,
                        this.width / 2 + 1,
                        pixelsY + this.pxPerYStep / 2 + 1,
                        Note.getNoteString(absolutePitch, forceFlat),
                        fontSizePercentage,
                        "bold",
                        "black"
                    )
                } else if (relativePitch === 4) {
                    // draw note border at 4th interval
                    ScoreCanvas.drawHorizontalLine(
                        ctx,
                        0,
                        this.width,
                        pixelsY,
                        "rgba(0,0,0,0.5)"
                    )
                } else if (pitchType.includes("#")) {
                    const noteHeight =
                        this.pxPerYStep - this.grid.pxPerPitchGutter

                    const offsetHack = -2 // this offset hack is used to hide the 2px radius of the black keys

                    // draw black key
                    ScoreCanvas.generateNoteRect(
                        ctx,
                        offsetHack,
                        pixelsY,
                        "black",
                        2,
                        this.width / 2 - offsetHack,
                        noteHeight
                    )

                    // draw note border between black key
                    ScoreCanvas.drawHorizontalLine(
                        ctx,
                        0,
                        this.width,
                        pixelsY + noteHeight / 2,
                        "rgba(0,0,0,0.5)"
                    )
                }

                return true
            }
        )
    }
}
