import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
} from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import { DesignService } from "@services/design.service"
import { ModalService } from "@services/modal.service"
import { DeviceDetectorService } from "ngx-device-detector"
import { ContextMenu } from "../../../models/contextmenu"

@Component({
    selector: "context-menus",
    templateUrl: "context-menus.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["context-menus.component.scss"],
})
export class ContextMenusComponent {
    contextMenus = {}

    constructor(
        private designService: DesignService,
        private ref: ChangeDetectorRef,
        private elementRef: ElementRef,
        private modalService: ModalService,
        private device: DeviceDetectorService
    ) {}

    detectChanges() {
        this.ref.detectChanges()
    }

    ngOnInit() {
        for (let contextMenu in this.modalService.contextMenus) {
            this.modalService.contextMenus[contextMenu].subscribe(
                async (value: ContextMenu) => {
                    try {
                        // First, wait so that any event that was used to trigger a context menu is propagated through the app, and doesnt conflict with clickOutside listeners on the context menus
                        await Misc.wait(0.001)

                        this.contextMenus[contextMenu] = value

                        this.detectChanges()

                        if (value == null) {
                            return
                        }

                        // Then, wait for the context menu to actually appear in the html before computing the style
                        await Misc.wait(0.001)

                        if (contextMenu === "localContextMenu") {
                            return
                        }

                        this.designService.computeContextMenuStyle(
                            this.elementRef,
                            value
                        )
                    } catch (e) {
                        console.error(e)
                    }
                }
            )
        }
    }

    closeContextMenu(type) {
        if (this.contextMenus[type] != null) {
            this.modalService.contextMenus[type].next(null)
        }
    }
}
