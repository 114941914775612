import Layer from "../../classes/score/layer"
import PercussionLayer from "../../classes/score/percussionlayer"
import Score from "../../classes/score/score"
import TrackBus from "../../classes/score/trackbus"
import {
    SILENT_INSTRUMENT,
    SILENT_INSTRUMENT_TRACK,
    SILENT_KIT_INSTRUMENT,
    SILENT_KIT_TRACK,
    TIMESTEP_RES,
} from "../../constants/constants"
import {
    TemplateLayers,
    TemplateNote,
    TemplateScore,
    TemplateTrack,
} from "../../interfaces/score/templateScore"
import { ScoreManipulation } from "../scoremanipulation"
import { Time } from "../time"
import { cloneDeep } from "lodash"
import { Note } from "../../classes/score/note"
import { v4 as uuidv4 } from "uuid"
import { FractionString } from "../../types/score"
export module ScoreDecoding {
    /**
     * A function to decode a Score object into a TemplateScore
     * @param args only set layersToSelect and timeSlice if realTimeSampler is true
     * @returns
     */
    export function toTemplateScore(args: {
        score: Score
        realTimeSampler: boolean
        layersToSelect?: (Layer | PercussionLayer)[]
        trackBussesToSelect?: TrackBus[]
        timeSlice?: FractionString
        ignoreLoadingTrackbusses?: boolean
        alwaysIncludeEmptyTrackBusses?: boolean
    }) {
        const score: Score = args.score
        const realTimeSampler: boolean = args.realTimeSampler
        const ignoreLoadingTrackbusses =
            args.ignoreLoadingTrackbusses !== undefined
                ? args.ignoreLoadingTrackbusses
                : true

        const alwaysIncludeEmptyTrackBusses =
            args.alwaysIncludeEmptyTrackBusses !== undefined
                ? args.alwaysIncludeEmptyTrackBusses
                : false

        const timeSliceFraction: FractionString | undefined =
            args.timeSlice !== undefined ? args.timeSlice : undefined

        const layersToSelect: (Layer | PercussionLayer)[] =
            realTimeSampler && args.layersToSelect !== undefined
                ? args.layersToSelect
                : score.getLayersArray()

        const tbToSelect: TrackBus[] =
            args.trackBussesToSelect !== undefined
                ? args.trackBussesToSelect
                : score.trackBusses

        const templateScore: TemplateScore = score.buildDecodedTemplate(false)

        prepareLayers(score, layersToSelect, realTimeSampler, timeSliceFraction)

        const groupedNotes: TemplateNote[] = decodeAllNotes(
            score,
            layersToSelect,
            timeSliceFraction
        )

        const result = decodeTracksAndLayers(
            score,
            layersToSelect,
            realTimeSampler,
            tbToSelect,
            groupedNotes,
            ignoreLoadingTrackbusses,
            alwaysIncludeEmptyTrackBusses
        )

        templateScore.layers = result.layers

        if (!realTimeSampler) {
            // cleans up unecessary data
            result.tracks.forEach(t => {
                t.track = t.track.map(n => {
                    delete n.enabled
                    return n
                })
            })
        }

        templateScore.tracks = result.tracks

        return {
            templateScore: templateScore,
            addedTrackWithNotes: result.addedTrackWithNotes,
            isExportingSilence: result.isExportingSilence,
        }
    }

    function decodeTracksAndLayers(
        score: Score,
        layersToSelect: (PercussionLayer | Layer)[],
        realTimeSampler: boolean,
        tbToSelect: TrackBus[],
        groupedNotes: TemplateNote[],
        ignoreLoadingTrackbusses: boolean,
        alwaysIncludeEmptyTrackBusses: boolean
    ): {
        tracks: TemplateTrack[]
        addedTrackWithNotes: boolean
        isExportingSilence: boolean
        layers: TemplateLayers
    } {
        const hasSoloedTB = score.hasSoloedTrackBus()

        // We don't want to send silence to the sampler,
        // especially in an offline context. This variable
        // keeps track of that
        let isExportingSilence: boolean = true
        let channelIndex: number = 1
        let addedTrackWithNotes: boolean = false

        let tracks: TemplateTrack[] = []
        let templateLayers: TemplateLayers = {}

        for (let layer of layersToSelect) {
            for (let tb of layer.trackBuses) {
                const tbIsLoading =
                    realTimeSampler && tb.loading && ignoreLoadingTrackbusses

                if (tbIsLoading || !shouldSelectTrackBus(tb, tbToSelect)) {
                    // We don't want to send notes for trackBusses
                    // that are still loading samples in memory or
                    // that aren't in the inclusion list
                    continue
                }

                const tbIsNotSilent =
                    tb.name !== SILENT_KIT_TRACK &&
                    tb.name !== SILENT_INSTRUMENT_TRACK &&
                    (realTimeSampler || !tb.mute)

                if (tbIsNotSilent) {
                    isExportingSilence = false
                }

                const track: TemplateTrack = tb.decode(
                    channelIndex,
                    layer.value
                )

                if (realTimeSampler) {
                    track.mute = tb.mute || (hasSoloedTB && !tb.solo)
                }

                let counter = 0

                for (let note of groupedNotes) {
                    const differentLayer = note.meta?.layer !== layer.value
                    const disabledNote =
                        realTimeSampler &&
                        note.enabled === false &&
                        !tb.name.includes("slur")

                    if (differentLayer || disabledNote) {
                        continue
                    }

                    counter += 1

                    note.meta.section = Note.getSectionForNoteStart(
                        score?.sections,
                        note.start
                    )

                    if (layer.type === "pitched") {
                        addPitchedNoteToTrack(score, track, note, tb)
                    } else if (layer.type === "percussion") {
                        addPercussionNoteToTrack(
                            score,
                            track,
                            <PercussionLayer>layer,
                            note
                        )
                    } else {
                        throw "Unexpected layer type"
                    }
                }

                if (track.track.length > 0 || alwaysIncludeEmptyTrackBusses) {
                    tracks.push(track)

                    if (layer.type != "percussion") {
                        channelIndex = incrementChannelIndex(channelIndex)
                    }

                    addedTrackWithNotes = true
                }
            }

            const silentInstrument = newTrackForSilentNotes(
                layer,
                groupedNotes,
                0
            )

            if (silentInstrument.track.length > 0 && !realTimeSampler) {
                tracks.push(silentInstrument)
            }
        }

        for (let layer of layersToSelect) {
            templateLayers[layer.value] = layer.decode()
        }

        return {
            tracks: tracks,
            layers: templateLayers,
            addedTrackWithNotes: addedTrackWithNotes,
            isExportingSilence: isExportingSilence,
        }
    }

    function incrementChannelIndex(channelIndex) {
        channelIndex += 1

        if (channelIndex > 15) {
            channelIndex = 15 // MIDI doesn't support more than 15 channels
        } else if (channelIndex == 9) {
            channelIndex = 10
        }

        return channelIndex
    }

    /**
     * Modifies the state of track by adding a note if this note is assigned
     * to the tb object
     * @param score
     * @param track
     * @param note
     * @param tb
     */
    function addPercussionNoteToTrack(
        score: Score,
        track: TemplateTrack,
        layer: PercussionLayer,
        note: TemplateNote
    ) {
        note.meta.section = Note.getSectionForNoteStart(
            score?.sections,
            note.start
        )

        for (const patternRegion of layer.patternRegions) {
            const isInBoundaries = !Time.fractionIsInBoundaries(
                {
                    start: patternRegion.start,
                    duration: Time.multiplyFractionWithNumber(
                        patternRegion.duration,
                        patternRegion.loop + 1
                    ),
                },
                note.start
            )

            if (isInBoundaries) {
                continue
            }

            for (let channel of patternRegion.pattern.channels) {
                let skip = true

                if (track.id !== channel.trackBus.id) {
                    continue
                }

                for (let pitch of channel.pitches) {
                    if (note.pitch.includes(pitch)) {
                        skip = false

                        break
                    }
                }

                if (skip) {
                    continue
                }

                addNoteToTrack(note, track)

                break
            }
        }
    }

    /**
     * Modifies the state of track by adding a note if this note is assigned
     * to the tb object
     * @param score
     * @param track
     * @param note
     * @param tb
     */
    function addPitchedNoteToTrack(
        score: Score,
        track: TemplateTrack,
        note: TemplateNote,
        tb: TrackBus
    ) {
        const sectionTS = Time.fractionToTimesteps(
            TIMESTEP_RES,
            score.sections[note.meta.section].start
        )

        const noteTS = Time.fractionToTimesteps(TIMESTEP_RES, note.start)

        const isPlayedAtSection =
            note.meta.layer.includes("Melody") &&
            tb.isPlayedAt(sectionTS) &&
            Note.getSectionForNoteStart(score?.sections, note.start) ===
            note.meta.section - 1

        if (isPlayedAtSection || tb.isPlayedAt(noteTS)) {
            addNoteToTrack(note, track)
        }
    }

    function addNoteToTrack(note: TemplateNote, track: TemplateTrack) {
        note.was_added = true

        note.start = Time.simplifyFractionFromString(note.start)
        note.duration = Time.simplifyFractionFromString(note.duration)

        const newNote: TemplateNote = cloneDeep(note)
        delete newNote.was_added
        delete newNote.trackBusses

        track.track.push(newNote)
    }

    function shouldSelectTrackBus(
        trackBus: TrackBus,
        trackBussesToSelect: TrackBus[]
    ) {
        for (let tb of trackBussesToSelect) {
            if (tb.name === trackBus.name) {
                return true
            }
        }

        return false
    }

    function decodeAllNotes(
        score: Score,
        layersToSelect: (PercussionLayer | Layer)[],
        timeSliceFraction: string | undefined
    ): TemplateNote[] {
        let notes: TemplateNote[] = []

        for (let layer of layersToSelect) {
            const previouslySeenNoteGroup =
                score.previouslySeenNoteGroups[layer.value]

            const templateNotes = layer.decodeNotesObject({
                previouslySeenNoteGroup: previouslySeenNoteGroup,
                timeSliceFraction: timeSliceFraction,
            })

            if (templateNotes.length > 0) {
                score.previouslySeenNoteGroups[layer.value] = timeSliceFraction
                notes = notes.concat(templateNotes)
            }
        }

        return <TemplateNote[]>ScoreManipulation.sortNotes(notes)
    }

    function prepareLayers(
        score: Score,
        layersToSelect: (PercussionLayer | Layer)[],
        realTimeSampler: boolean,
        timeSlice?: FractionString
    ) {
        for (let layer of layersToSelect) {
            if (layer.type === "percussion") {
                const percLayer: PercussionLayer = <PercussionLayer>layer

                const notesObject = percLayer.computeNotesObject({
                    sections: score.sections,
                    tempoMap: score.tempoMap,
                    timeSignature: score.timeSignatures[0][1],
                    realTimeSampler,
                    timeSlice,
                })
                percLayer.setNotesObject(notesObject)
            }

            if (!realTimeSampler) {
                score.computePhrases(layer)
            }
        }
    }

    function newTrackForSilentNotes(
        layer: Layer | PercussionLayer,
        notes: TemplateNote[],
        channelIndex: number
    ) {
        const track = newTrackFromLayerType(layer, channelIndex)

        for (let note of notes) {
            if (!note.was_added && note.meta.layer === layer.value) {
                addNoteToTrack(note, track)
            }
        }

        return track
    }

    function newTrackFromLayerType(
        layer: Layer,
        channelIndex: number
    ): TemplateTrack {
        const trackName =
            layer.type === "percussion"
                ? SILENT_KIT_TRACK
                : SILENT_INSTRUMENT_TRACK

        const instrumentName =
            layer.type === "percussion"
                ? SILENT_KIT_INSTRUMENT
                : SILENT_INSTRUMENT

        return {
            id: uuidv4(),
            breathing_gain: 0,
            layer: layer.value,
            track_layer: layer.value,
            name: trackName,
            instrument: instrumentName,

            use_velocity: trackName.split(".")[3] === "stac",
            use_expression: trackName.split(".")[3] !== "stac",
            auto_pedal: false,
            mute: false,
            solo: false,
            octave: 0,
            dynamic_offset: 0,
            gain_offset: 0,
            panning: 0,
            gm: 0,
            channel: layer.type === "percussion" ? 9 : channelIndex,
            track: [],
        }
    }
}
