import { Component, OnInit, ElementRef, ViewChild } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { Location } from "@angular/common"
import { ApiService } from "../../services/api.service"
import { ModalService } from "../../services/modal.service"
import { PlayerService } from "../../services/audio/player/player.service"
import { BillingService } from "../../services/billing.service"
import { TokenService } from "../../services/token.service"
import { environment } from "../../../environments/environment"
import { UserService } from "../../services/user.service"
import { FolderService } from "../../services/folder.service"
import { TracksService } from "../../services/tracks.service"
import { DesignService } from "../../services/design.service"
import { DeviceDetectorService } from "ngx-device-detector"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { GeneralService } from "@services/general/general.service"
import { WindowService } from "@services/window.service"
import { playerQuery } from "../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { MenuModifiers } from "../reusable/menu-modifiers/menu-modifiers.component"
import { CreateTrackFrom } from "@common-lib/types/general"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { Helpers } from "../../modules/helpers.module"
import { TutorialService } from "@services/tutorial.service"
import { AudioService } from "@services/audio/audio.service"

@Component({
    selector: "app-navbar",
    templateUrl: "navbar.component.html",
    styles: [],
})
export class NavbarComponent implements OnInit {
    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    public createTrackMenu: MenuModifiers | undefined

    addProject = false
    addProjectLoader = false
    projects = []
    projectName
    email = ""
    locationPath = ""
    userMenuOpen = false
    feedbackMenu = false
    feedbackMessage = ""
    midiWasEdited = false
    unplayedComposition = false
    mobileMenuItems = false

    isLoggedIn = false
    userCanChangePassword = true

    isElectron = false

    downloadsLeft = null

    isMacElectronApp = false

    isCollapsed = false
    isDesktop = false

    showCommunity = false

    @ViewChild("feedbackForm", { static: false }) feedbackFormField: ElementRef

    constructor(
        private windowService: WindowService,
        protected gpService: GenerationProfileService,
        protected route: ActivatedRoute,
        private folderService: FolderService,
        protected billingService: BillingService,
        protected tokenService: TokenService,
        protected playerService: PlayerService,
        protected modalService: ModalService,
        private router: Router,
        private apiService: ApiService,
        protected location: Location,
        private generalService: GeneralService,
        private userService: UserService,
        private tracksService: TracksService,
        private designService: DesignService,
        private device: DeviceDetectorService,
        private tutorialService: TutorialService,
        public audio: AudioService
    ) {
        this.isDesktop = this.device.isDesktop()
    }

    detectChanges() {
        //this.ref.detectChanges()
    }

    ngOnInit() {
        this.showCommunity = this.folderService.content.getValue().length > 0

        this.isElectron = this.windowService.desktopAppAPI != null

        if (this.isElectron) {
            this.windowService.generalAPI.getMetaInfo().then(result => {
                this.isMacElectronApp =
                    result["platform"] == "darwin" ||
                    result["platform"] == "mac"
            })
        }

        this.folderService.content.subscribe(compositions => {
            if (compositions == null) {
                return
            }

            for (var c = 0; c < compositions.length; c++) {
                if (
                    compositions[c].isFinished &&
                    !compositions[c].failed &&
                    compositions[c].wasPlayed == false
                ) {
                    if (!this.unplayedComposition) {
                        this.unplayedComposition = true

                        this.detectChanges()
                    }

                    return
                }
            }

            if (this.unplayedComposition) {
                this.unplayedComposition = false

                this.detectChanges()
            }
        })

        this.apiService.user.subscribe(user => {
            if (user != null) {
                this.email = user
                this.userCanChangePassword =
                    this.userService.userCanChangePassword()
            } else {
                this.userMenuOpen = false
                this.userCanChangePassword = false
            }

            this.detectChanges()
        })

        this.apiService.isLoggedIn.subscribe(value => {
            this.isLoggedIn = value

            this.detectChanges()
        })

        this.router.events.subscribe(val => {
            if (this.router.url.split("?")[0].includes("/publicPlayer")) {
                this.userMenuOpen = false
                this.isLoggedIn = false
            }

            this.locationPath = this.location.path().split("?")[0]

            this.detectChanges()
        })

        this.generalService.downloadsLeft.subscribe(value => {
            if (this.billingService.subscription.getValue().legacyPlan) {
                this.downloadsLeft = null

                this.detectChanges()

                return
            }

            this.downloadsLeft = value

            this.detectChanges()
        })

        this.locationPath = this.location.path().split("?")[0]

        if (this.locationPath === "/publicPlayer") {
            this.userMenuOpen = false
            this.isLoggedIn = false

            this.detectChanges()
        }

        this.designService.sidebarCollapsed.subscribe(isCollapsed => {
            this.isCollapsed = isCollapsed
            this.designService.getCSSProperty("melody-track-color")
        })

        this.tutorialService.collapseNavigation$.subscribe(shouldCollapse => {
            if (shouldCollapse) {
                this.isCollapsed = true
                this.toggleNavbar()
            }
        })

        this.tutorialService.isTutorialInProgress$.subscribe(val => {
            this.detectChanges()
        })
    }

    openGenerationProfile() {
        let id = ""

        if (this.gpService.generationProfile != null) {
            id = this.gpService.generationProfile._id
        }

        this.gpService.openGenerationProfile(id)
    }

    getDownloads() {
        if (this.downloadsLeft == null) {
            return null
        }

        var quota = this.getDownloadsQuota()

        return quota - this.downloadsLeft
    }

    getDownloadsQuota() {
        const subscription = this.billingService.subscription.getValue()

        if (
            subscription == null ||
            subscription.plan == null ||
            subscription.plan == "free"
        ) {
            return 3
        } else if (subscription.plan.includes("standard")) {
            return 15
        } else if (subscription.plan.includes("pro")) {
            return 300
        }
    }

    sendFeedback(): void {
        this.apiService
            .authRequest(
                "/user/feedback",
                { message: this.feedbackMessage },
                "primary",
                true
            )
            .then(res => {
                this.feedbackMenu = false
                this.feedbackMessage = ""
            })

            .catch(err => {
                this.apiService.handleError(err)
            })
    }

    goToDiscord() {
        window.open("https://discord.gg/ypDnFXN", "_blank")
    }

    billing(): void {
        this.router.navigate(["billing"])

        this.mobileMenuItems = false
    }

    tutorials(): void {
        this.router.navigate(["tutorials"])
    }

    radio(): void {
        this.router.navigate(["radio"])

        this.mobileMenuItems = false
    }

    showRadio() {
        return 1617738203000 < Date.now()
    }

    playlists() {
        this.tracksService.setTrackView("playlists")

        this.router.navigate(["/"])

        this.mobileMenuItems = false
    }

    getFeatureFlags() {
        return featureFlags
    }

    showPlaylists() {
        return true
    }

    settings(): void {
        this.modalService.modals.settings.next({
            type: "about",
        })
    }

    async myTracks() {
        if (
            this.router.url === "/radio" ||
            (this.router.url.includes("generation-profile-editor") &&
                this.folderService.getContentType() === "Compositions")
        ) {
            await this.folderService.setContentType(
                "myTracks",
                "Compositions",
                "",
                true,
                null
            )
        } else {
            this.router.navigate([""])
        }

        this.mobileMenuItems = false

        this.tracksService.setTrackView("trackslist")
    }

    editorIsHighlighted() {
        return this.locationPath.includes("/editor")
    }

    createNewTrack(type: CreateTrackFrom): void {
        this.router.navigate(["create", type])

        this.mobileMenuItems = false

        this.closeCreateTrackMenu()

        if (
            this.tutorialService.isTutorialInProgress$.value &&
            this.router.url === "/"
        ) {
            setTimeout(() => {
                this.tutorialService.tutorialNext$.next(true)
            })
        }
    }

    toggleMobileMenuItems() {
        this.mobileMenuItems = !this.mobileMenuItems
    }

    resetAddProject(): void {
        this.addProjectLoader = false
        this.addProject = false
        this.projectName = ""
    }

    logout(): void {
        this.apiService.logout(true)
    }

    /*
		Once modalService indicates a project was deleted, we proceed to deleting it from the navbar's project list
	*/
    afterProjectDeletion(val): void {
        this.projects.some((project, i) => {
            if (project._id == val) {
                this.projects.splice(i, 1)
                this.apiService.projects = this.projects
                return true
            }
        })
    }

    switchToPianoRoll() {
        var type =
            this.folderService.getContentType() == "Influences"
                ? "influence"
                : "composition"

        const composition = playerQuery.content

        if (composition != null) {
            type = composition.contentType
        }

        this.playerService.switchToPianoRoll(
            this.playerService.getCompositionID(),
            type,
            false
        )

        this.mobileMenuItems = false
    }

    importJSON() {
        this.router.navigate(["/importjson"])
    }

    openChangelog() {
        this.modalService.modals.changelog.next(true)
    }

    changeMyPassword() {
        if (this.userService.userCanChangePassword())
            this.modalService.changePassword.next(true)
    }

    downloadApp() {
        window.open("https://www.aiva.ai/download", "_blank")
    }

    support() {
        window.open(
            "https://aiva.crisp.help/en/category/frequently-asked-questions-1hnn93r/",
            "_blank"
        )

        this.mobileMenuItems = false
    }

    createBugReport() {
        if (!this.isElectron) {
            return
        }

        this.modalService.modals.desktopAppBugReport$.next(true)
    }

    shareFeedback() {
        window.open("https://airtable.com/shrrPu18emIyKfaNK", "_blank")
    }

    showImportJSON() {
        return !environment.production
    }

    isStagingEnvironment() {
        return !environment.production
    }

    toggleNavbar() {
        this.designService.setSidebarCollapsed(!this.isCollapsed)
    }

    openUserMenu() {
        if (this.isCollapsed) {
            this.toggleNavbar()
        }

        setTimeout(() => {
            this.userMenuOpen = true
        }, 10)
    }

    legacyBilling() {
        var subscription = this.billingService.subscription.getValue()

        return subscription != null && subscription.legacyPlan
    }

    getBonusDownloadsQuota() {
        return this.generalService.bonusDownloadsLeft.getValue()
    }

    getDownloadPercentage() {
        return (this.getDownloads() * 100) / this.getDownloadsQuota()
    }

    openCompositionByID(type: "composition" | "training") {
        this.modalService.openCompositionModal.next(type)
    }

    public openCreateTrackMenu(event: MouseEvent) {
        this.createTrackMenu = {
            options: [],
            coordinates: {
                x: event.x,
                y: event.y,
            },
            title: "Create a track",
            buttons: [
                {
                    data: "test",
                    icon: undefined,
                    text: "From a Style",
                    loading: false,
                    onClick: ((data: string) => {
                        this.createNewTrack("Styles")
                    }).bind(this),
                    avData: "create-from-style",
                },

                {
                    data: "test",
                    icon: undefined,
                    text: "From a Chord progression",
                    loading: false,
                    onClick: ((data: string) => {
                        this.createNewTrack("Chord progression")
                    }).bind(this),
                    avData: "create-from-cp",
                },
            ],
        }

        this.createTrackMenu.buttons.push({
            data: "test",
            icon: undefined,
            text: "Step by step",
            loading: false,
            onClick: ((data: string) => {
                this.createNewTrack("Step by step")
            }).bind(this),
            avData: "create-step-by-step",
        })

        this.createTrackMenu.buttons.push({
            data: "test",
            icon: undefined,
            text: "From an Influence",
            loading: false,
            onClick: ((data: string) => {
                this.createNewTrack("Upload influence")
            }).bind(this),
            avData: "create-from-influence",
        })

        if (featureFlags.enableMidiUpload) {
            this.createTrackMenu.buttons.push({
                data: "test",
                icon: undefined,
                text: "Import MIDI",
                loading: false,
                onClick: ((data: string) => {
                    this.createNewTrack("Import MIDI")
                }).bind(this),
                avData: "create-from-influence",
            })
        }

        if (Helpers.showPreset(this.userService.accountCreationDate)) {
            this.createTrackMenu.buttons.push({
                data: "test",
                icon: undefined,
                text: "Presets (legacy)",
                loading: false,
                onClick: ((data: string) => {
                    this.createNewTrack("Presets")
                }).bind(this),
            })
        }

        if (
            this.tutorialService.isTutorialInProgress$.value &&
            this.router.url === "/"
        ) {
            setTimeout(() => {
                this.tutorialService.tutorialNext$.next(true)
            })
        }
    }

    public closeCreateTrackMenu() {
        this.createTrackMenu = undefined
    }
}
