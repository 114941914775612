import TrackBus from "@common-lib/classes/score/trackbus"
import {
    DrumSamplesMap,
    InstrumentsJSON,
    PitchToChannelMapping,
} from "@common-lib/interfaces/score/general"
import { TemplateScore } from "@common-lib/interfaces/score/templateScore"
import { Store, StoreConfig } from "@datorama/akita"

export interface InstrumentsState {
    drumSamples: DrumSamplesMap
    instruments: InstrumentsJSON
    pitchToChannelMapping: PitchToChannelMapping

    instrumentsOrganizedByPath
    percussionOrganizedByPath
    numberOfPatches: number
    newPatchSelected:
        | undefined
        | {
              layer
              trackBus: TrackBus
              newPatch
              type
              instrumentPatches: Array<any>
          }
    refreshSearchUI: number
    templateScore: TemplateScore | undefined
    isDownloadingAllInstruments: boolean

    downloadAllStatus: {
        completed: number
        total: number
    }

    allInstrumentsAreDownloaded: boolean
}

const initialState: InstrumentsState = {
    drumSamples: undefined,
    instruments: undefined,
    pitchToChannelMapping: undefined,

    instrumentsOrganizedByPath: undefined,
    percussionOrganizedByPath: undefined,
    numberOfPatches: 0,
    newPatchSelected: undefined,
    refreshSearchUI: Date.now(),
    templateScore: undefined,
    isDownloadingAllInstruments: false,

    downloadAllStatus: {
        completed: 0,
        total: 0,
    },

    allInstrumentsAreDownloaded: false,
}

@StoreConfig({ name: "Instruments" })
export class InstrumentsStore extends Store<InstrumentsState> {
    constructor() {
        super(initialState)
    }
}
