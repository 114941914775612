<div id="play-head-element-wrapper">
    <div
        id="play-head-element"
        (mousedown)="onMouseDown($event)"
        *ngIf="leftOffset >= 0"
        [style.left.px]="leftOffset">
        <div class="play-head-line">
            <div class="play-head-indicator"></div>
        </div>
    </div>
</div>
