import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core'
import { Misc } from '@common-lib/modules/misc'
import { GeneralService } from '@services/general/general.service'

@Component({
    selector: 'emotion-select',
    templateUrl: 'emotion-select.component.html',
    styleUrls: ['emotion-select.component.scss']
})

export class EmotionSelectComponent implements OnInit {

    @Input() emotion: string                                  // two-way binding variable to indicate the selected emotion
    @Input() emotions: string[]                               // list of emotions
    @Input() ignoreEmotions: string[]                         // list of emotions that should not be listed
    @Output() emotionChange = new EventEmitter<string>()      // two-way binding EventEmitter to emit the emotion selected on change
    @Output() onChange = new EventEmitter<any>()              // EventEmitter that emits the selected emotion as a number value on emotion changes
    @Input() title = "Select an emotion"                      // The width of the element. By changing the width, the element will scale proportionally (so it always looks the same for different sizes)
    @Input() showWarning = false
    @Input() type = "modal"

    constructor(private generalService:GeneralService) { }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        for (let change in changes) {
            if (this[change] != null) {
                this[change] = changes[change].currentValue
            }

            if (change == 'emotions' && this.type == "modal") {
                this[change] = [null].concat(changes[change].currentValue)
            }
        }

        // remove any emotions that are on our ignore list
        if (this.emotions && this.emotions.length > 0 && this.ignoreEmotions && this.ignoreEmotions.length > 0) {
            this.emotions = this.emotions.filter((emotion) => {
                return this.ignoreEmotions.indexOf(emotion) === -1
            })
        }
    }

    setEmotion(emotion) {
        if (this.type != "gp") {
            this.emotion = emotion
        }
        
        this.emotionChange.emit(emotion)
        this.onChange.emit({ value: emotion, name: this.getEmojiNameByEmotionID(emotion) })
    }

    getEmojiNameByEmotionID(emotion, capitalize=false) {
        return this.generalService.getEmojiNameByEmotionID(emotion, capitalize)
    }

    getEmojiSVG(emotion) {
        let emojiSVG = null
        let base = "assets/img/emojis/"

        emojiSVG = base + this.getEmojiNameByEmotionID(emotion) + ".svg"

        return emojiSVG
    }
}
