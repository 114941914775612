import {
    OnInit,
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
} from "@angular/core"

@Component({
    selector: "top-menu-with-arrows",
    templateUrl: "top-menu-with-arrows.component.html",
    styleUrls: ["top-menu-with-arrows.component.scss"],
})
export class TopMenuWithArrowsComponent implements OnInit {
    @Input() title: string = ""
    @Input() subtitle: string = ""
    @Input() numberOfSteps: number = 2
    @Input() alwaysShowPrevious: boolean = false
    @Input() nextFunction:
        | ((direction: "next") => Promise<{
              success: boolean
              error?: string
          }>)
        | undefined
    @Input() previousFunction:
        | ((direction: "previous") => Promise<{
              success: boolean
              cancel: boolean
              error?: string
          }>)
        | undefined
    @Input()
    skipIndex: number

    @Input()
    public currentStepIndex: number = 0

    @Output() currentStep: EventEmitter<number> = new EventEmitter<number>()

    public loading: boolean = false
    public error: string | undefined

    constructor(private ref: ChangeDetectorRef) {}

    ngOnInit(): void {}

    public async next() {
        this.loading = true
        this.ref.detectChanges()

        if (this.nextFunction !== undefined) {
            const result = await this.nextFunction("next")

            if (result.error !== undefined) {
                this.error = result.error
            } else {
                this.currentStepIndex++
                if (
                    this.skipIndex !== undefined &&
                    this.currentStepIndex === this.skipIndex
                ) {
                    this.currentStepIndex++
                }
                this.error = undefined

                this.currentStep.emit(this.currentStepIndex)
            }
        }

        this.loading = false
        this.ref.detectChanges()
    }

    public async previous() {
        this.loading = true
        this.ref.detectChanges()

        if (this.previousFunction !== undefined) {
            const result = await this.previousFunction("previous")

            if (result.error !== undefined) {
                this.error = result.error
            } else if (result.cancel === false) {
                this.currentStepIndex = Math.max(0, this.currentStepIndex - 1)
                if (
                    this.skipIndex !== undefined &&
                    this.currentStepIndex === this.skipIndex
                ) {
                    this.currentStepIndex = Math.max(
                        0,
                        this.currentStepIndex - 1
                    )
                }
                this.error = undefined

                this.currentStep.emit(this.currentStepIndex)
            }
        }

        this.loading = false
        this.ref.detectChanges()
    }

    public showNext() {
        return this.currentStepIndex < this.numberOfSteps - 1
    }

    public showPrevious() {
        return this.currentStepIndex > 0 || this.alwaysShowPrevious
    }

    public getArrayOfSteps() {
        return Array.from("x".repeat(this.numberOfSteps))
    }

    public async onStepClick(index: number) {
        if (index < this.currentStepIndex) {
            await this.previous()
        } else if (index > this.currentStepIndex) {
            await this.next()
        }
    }
}
