import AIVAObject from "../general/aivaobject"

export default class Bus extends AIVAObject {
    gainOffset
    dynamicOffset

    gainNode

    range = []
    normalizationValue = 0

    lastLowCut = { time: -1, value: -1 }
    lastHighCut = { time: -1, value: -1 }

    alreadyProcessedLowCut = []
    alreadyProcessedHighCut = []

    constructor(dynamicOffset, gainOffset) {
        super() 
        this.dynamicOffset = dynamicOffset
        this.gainOffset = gainOffset
    }

    dBToAmplitude(dB) {
        return Math.pow(10, dB / 20)
    }

    amplitudeToDB(amplitude) {
        return 20 * Math.log10(amplitude)
    }

    removeDuplicates(type, values) {
        var newValues = []

        for (var v = 0; v < values.length; v++) {
            var add = true

            if (type == "highFrequencyCut") {
                if (this.eq(values[v].time, this.lastHighCut.time) || this.eq(values[v].value, this.lastHighCut.value)) {
                    continue
                }
                
                var shouldContinue = false

                for (var a = 0; a < this.alreadyProcessedHighCut.length; a++) {
                    const value = this.alreadyProcessedHighCut[a]

                    if (this.eq(values[v].time, value.time) || this.eq(values[v].value, value.value)) {
                        shouldContinue = true

                        break
                    }
                }

                if (shouldContinue) {
                    continue
                }
            }

            if (type == "lowFrequencyCut") {
                if (this.eq(values[v].time, this.lastLowCut.time) || this.eq(values[v].value, this.lastLowCut.value)) {
                    continue
                }  

                var shouldContinue = false

                for (var a = 0; a < this.alreadyProcessedLowCut.length; a++) {
                    const value = this.alreadyProcessedLowCut[a]

                    if (this.eq(values[v].time, value.time) || this.eq(values[v].value, value.value)) {
                        shouldContinue = true

                        break
                    }
                }

                if (shouldContinue) {
                    continue
                }
            }

            for (var w = v + 1; w < values.length; w++) {
                if (this.eq(values[v].time, values[w].time) || this.eq(values[v].value, values[w].value)) {
                    add = false

                    break
                }
            }

            if (add) {
                newValues.push(values[v])
            }
        }

        newValues.sort((a, b) => {
            if (a.time < b.time) return -1
            if (a.time > b.time) return 1

            return 0
        })

        return newValues
    }

    lte(a, b, precision=5) {
        var aBis = Math.round(a * precision) / precision
        var bBis = Math.round(b * precision) / precision

        return aBis <= bBis
    }

    eq(a, b, precision=5) {
        var aBis = Math.round(a * precision) / precision
        var bBis = Math.round(b * precision) / precision

        return aBis == bBis
    }

    setAudioParam(param, value, time, type, paramType=null) {
        var newValue = Math.floor(value * 10000) / 10000
        var newTime = this.formatTime(time)

        if (!isFinite(newValue) || !isFinite(newTime) || isNaN(newValue) || isNaN(newTime)) {
            return
        }

        if (type == "lin") {
            param.linearRampToValueAtTime(newValue, newTime)
        }

        else if (type == "exp") {
            param.exponentialRampToValueAtTime(newValue, newTime)
        }

        else {
            param.setValueAtTime(newValue, newTime)
        }
    }

    formatTime(time) {
        return Math.max(time, 0)
    }
}