export default class Notification {
    // these static variables define possible achievement types
    static ACHIEVEMENT_UNLOCKED = "achievementUnlocked"
    static TROPHY_UNLOCKED = "trophyUnlocked"

    title:string
    description:string
    icon:string
    type:string
    meta:any = {}

    constructor(title, description, icon, type, meta) {
        this.title = title
        this.description = description
        this.icon = icon
        this.type = type
        this.meta = meta
    }

    static fromJSON(object):Notification {
        return Object.assign(new Notification("", "", "", "", {}), object)
    }

    static groupType(type) {
        if (type == Notification.TROPHY_UNLOCKED) {
            return false
        }

        return true
    }

    static groupedTitlesForType(type, numberOfNotifications, firstNotification:Notification) {
        if (type == Notification.ACHIEVEMENT_UNLOCKED) {
            return {
                title: "Achievements unlocked!",
                description: firstNotification.description + " + " + (numberOfNotifications - 1) + " others"
            }
        }

        return {
            title: "",
            description: ""
        }
    }
}