<div class="component-container" oncontextmenu="return true">
    <div
        class="container"
        [perfectScrollbar]="{ useBothWheelAxes: false, suppressScrollX: true }"
        *ngIf="adService.loading$.getValue().status === 'loaded'">
        <div class="header">
            <div class="button-row">
                <a-button
                    label="Back to Style Designer"
                    type="round-icon-and-text"
                    class="rounded-square"
                    [icon]="'assets/img/back.svg'"
                    (click)="goToGenerationProfile()">
                </a-button>
            </div>

            <div class="header-title" (dblclick)="renamePack()">
                {{ pack?.name }}
            </div>

            <div class="button-row float-right">
                <a-button
                    id="ms-mode"
                    [label]="msMode ? 'Disable MS Mode' : 'Enable MS Mode'"
                    class="rounded-square"
                    *ngIf="!isProd()"
                    (click)="toggleMSMode()">
                </a-button>

                <a-button
                    label="Save pack"
                    type="primary"
                    *ngIf="
                        (adService.savePackLoading | async) === false &&
                        adService.packWasEdited &&
                        !polyphonyWarning
                    "
                    class="editor-button pulsing-button"
                    (click)="savePack(false)">
                </a-button>

                <img
                    *ngIf="polyphonyWarning"
                    class="tooltip-img"
                    src="assets/img/warning_red.svg"
                    [tooltip]="polyphonyWarning"
                    [tooltip-class]="'tooltip-error'"
                    [tooltip-marginTop]="10"
                    [tooltip-marginRight]="20"
                    [tooltip-position]="'middle-bottom'"
                    [tooltip-width]="250" />

                <div
                    class="loader"
                    *ngIf="adService.savePackLoading | async"></div>
            </div>
        </div>

        <div class="settings">
            <div class="settings-item">
                <div class="settings-item-title">
                    Input Mode
                    <img
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="
                            'Enable pencil mode to draw notes in your pack. Shortcut: Ctrl E'
                        "
                        [tooltip-marginTop]="10"
                        [tooltip-marginRight]="20"
                        [tooltip-position]="'middle-bottom'"
                        [tooltip-width]="250" />
                </div>
                <a-button
                    id="ms-mode"
                    [label]="
                        adService.engine.cursorType === 'pencil'
                            ? 'Enable Pointer'
                            : 'Enable Pencil'
                    "
                    type="round-icon-and-text"
                    class="rounded-square"
                    [icon]="
                        'assets/img/editmodes/' +
                        (adService.engine.cursorType === 'pencil' ? 0 : 1) +
                        '.svg'
                    "
                    (click)="setCursorType()">
                </a-button>
            </div>

            <div class="settings-item">
                <div class="settings-item-title">
                    Save to library
                    <img
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="
                            'In order to reuse this pack in other styles, use this button to create a template ' +
                            'pack and save it to your personal library of packs.'
                        "
                        [tooltip-marginTop]="10"
                        [tooltip-marginRight]="20"
                        [tooltip-position]="'middle-bottom'"
                        [tooltip-width]="250" />
                </div>
                <a-button
                    *ngIf="!polyphonyWarning"
                    id="ms-mode"
                    [label]="'Create pack template'"
                    class="rounded-square"
                    (click)="createTemplatePack()">
                </a-button>
            </div>

            <div class="settings-item">
                <div class="settings-item-title">
                    Resolution
                    <img
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="
                            'The resolution affects the granularity of the note grid of the accompaniment designer.'
                        "
                        [tooltip-marginTop]="10"
                        [tooltip-marginRight]="20"
                        [tooltip-position]="'middle-bottom'"
                        [tooltip-width]="250" />
                </div>
                <dropdown
                    type="light"
                    [showArrow]="true"
                    class="note-resolution"
                    [value]="noteResolution"
                    [showTooltip]="false"
                    [items]="noteResolutionOptions"
                    (selectedItem)="selectNoteResolution($event.new.value)">
                </dropdown>
            </div>

            <div class="settings-item">
                <div class="settings-item-title">
                    Number of bars
                    <img
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="
                            'Defines the number of bars the patterns in your pack will have.'
                        "
                        [tooltip-marginTop]="10"
                        [tooltip-marginRight]="20"
                        [tooltip-position]="'middle-bottom'"
                        [tooltip-width]="250" />
                </div>
                <dropdown
                    type="light"
                    [showArrow]="true"
                    class="pattern-length"
                    [value]="selectedPatternLength"
                    [showTooltip]="false"
                    [items]="patternLengthOptions"
                    (selectedItem)="selectPatternLength($event.new.value)">
                </dropdown>
            </div>

            <div class="settings-item">
                <div class="settings-item-title">
                    Key Signature
                    <img
                        class="tooltip-img"
                        src="assets/img/info.svg"
                        [tooltip]="
                            'Defines the key signature used to play your pack in the accompaniment designer.'
                        "
                        [tooltip-marginTop]="10"
                        [tooltip-marginRight]="20"
                        [tooltip-position]="'middle-bottom'"
                        [tooltip-width]="250" />
                </div>
                <dropdown
                    type="light"
                    [showArrow]="true"
                    [value]="selectedKeySignature"
                    [showTooltip]="false"
                    [items]="keySignatureOptions"
                    (selectedItem)="selectKeySignature($event.new.value)">
                </dropdown>
            </div>

            <div
                class="settings-item"
                [tooltip]="'You cannot zoom in any further'"
                [tooltip-class]="'tooltip-warning'"
                [tooltip-marginTop]="-30"
                [tooltip-marginRight]="20"
                [tooltip-position]="'middle-bottom'"
                [tooltip-width]="250"
                [tooltip-disabled]="adService.allowZoom">
                <div class="settings-item-title">Zoom</div>
                <div>
                    <img
                        src="assets/img/pianoroll/resize_indicator.svg"
                        class="small-size-indicator" />

                    <input
                        [(ngModel)]="resizeFactor"
                        type="range"
                        [min]="adService.engine.resizeFactorRange.min"
                        [max]="adService.engine.resizeFactorRange.max"
                        [value]="adService.engine.resizeFactorRange.min"
                        class="slider"
                        [disabled]="!adService.allowZoom"
                        (mouseup)="
                            adService.engine.setResizeFactor(resizeFactor)
                        " />
                    <img
                        src="assets/img/pianoroll/resize_indicator.svg"
                        class="large-size-indicator" />
                </div>
            </div>
        </div>

        <div id="editor">
            <div id="piano" class="canvas-container"></div>

            <div id="playhead-wrapper">
                <play-head
                    id="play-head-position"
                    class="play-head-position"
                    [engine]="adService.engine"></play-head>
            </div>

            <div id="editor-grid">
                <div id="pianoroll-grid" class="canvas-container"></div>
                <div id="accompaniment-designer" class="canvas-container"></div>
            </div>
        </div>
    </div>

    <div
        class="container"
        *ngIf="adService.loading$.getValue().status !== 'loaded'">
        <div
            class="loading"
            *ngIf="adService.loading$.getValue().status === 'none'">
            <div class="loading-title">
                No accompaniment pack selected to load
            </div>
        </div>

        <div
            class="loading"
            *ngIf="adService.loading$.getValue().status === 'packLoading'">
            <div class="loading-title">Loading your accompaniment pack</div>
            <progressbar
                [progress]="adService.loading$.getValue().progress"
                [total]="100">
            </progressbar>

            <div class="loading-button-container">
                <a-button
                    label="Back to Style Designer"
                    type="round-icon-and-text"
                    class="rounded-square"
                    [icon]="'assets/img/back.svg'"
                    (click)="goToGenerationProfile()">
                </a-button>
            </div>
        </div>

        <div
            class="loading"
            *ngIf="adService.loading$.getValue().status === 'scoreLoading'">
            <div class="loading-title">Loading accompaniment designer</div>
            <div class="loader"></div>
        </div>

        <div
            class="header"
            *ngIf="adService.loading$.getValue().status === 'failed'">
            <div class="button-row">
                <a-button
                    label="Back to Style Designer"
                    type="round-icon-and-text"
                    class="rounded-square"
                    [icon]="'assets/img/back.svg'"
                    (click)="goToGenerationProfile()">
                </a-button>
            </div>
        </div>

        <div
            class="loading"
            *ngIf="adService.loading$.getValue().status === 'failed'">
            <div class="loading-title">Your pack has failed to load</div>
        </div>

        <div class="loading" *ngIf="adService.savePackLoading | async">
            <div class="loading-title">Saving your accompaniment pack ...</div>
        </div>
    </div>
</div>
