export default class PotionPatch {
    playingStyle:string
    articulation:string
    path:string = ""
    name:string = ""
    maxTempo:number = 180
    percussionFunction:Array<string> = ['electronic']
    hasRelease:boolean = false
    addSidechain:boolean
    addEcho:boolean
    granulationFunction:string = "poly"
    autoSustainPedal:boolean = false
    addAutoStaccato:boolean = false

    constructor(playingStyle, articulation) {
        this.playingStyle = playingStyle
        this.articulation = articulation
    }

    encode(dbLevel) {
        let object:any = {
            name: this.name,
            path: this.path,
            playing_style: this.playingStyle,
            articulation: this.articulation,
            granulationEngine: this.granulationFunction,
            max_tempo: this.maxTempo,
            auto_pedal: this.autoSustainPedal,
            layers: [],
            db_level: dbLevel,
            use_expression: this.articulation != "stac",
            use_velocity: this.articulation == "stac",
            percussion_function: this.percussionFunction,
            compatible_articulation_function: this.computeArticulationFunction(),
        }
        
        return object
    }

    computeArticulationFunction() {
        if (this.articulation == "stac") {
            if (this.hasRelease) {
                return ['legato', 'staccato']
            }

            return ['staccato']
        }

        else if (this.articulation = "sus") {
            return ['sustain']
        }

        return ['legato']
    }

    static fromJSON(object):PotionPatch {
        return Object.assign(new PotionPatch("", ""), object)
    }
}