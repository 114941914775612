import { Component, ElementRef, AfterViewInit, ChangeDetectorRef } from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import PotionPatch from "@common-lib/classes/potion/potionpatch"
import PotionMetadata from "@common-lib/classes/potion/potionmetadata"
import { ApiService } from "@services/api.service"
import { ModalService } from "@services/modal.service"
import { PotionService } from "@services/potion.service"
import { WindowService } from "@services/window.service"
@Component({
    selector: 'potion',
    templateUrl: 'potion.component.html',
    styleUrls: ['potion.component.scss']
})
export class PotionComponent implements AfterViewInit {
    dryRun = false
    pathLoading = false
    instrumentName = ''
    instrumentPath = ''

    tooltips = {
        dryRun: "Only creates JSON files + Tar archive locally, but doesn't upload the instrument. Useful for debugging.",
        autofill: "WARNING: Only use this if you know what you're doing. If you don't, skip this step entirely. DO NOT use this in case you changed subsantially the samples. This is only to be used for minor revisions."
    }

    steps = [
        {
            index: 1,
            subtitle: "Directory Init"
        },

        {
            index: 2,
            subtitle: "Metadata creation"
        },

        {
            index: 3,
            subtitle: "Patch creation"
        },

        {
            index: 4,
            subtitle: "Instrument naming"
        },

        {
            index: 5,
            subtitle: "Upload to servers"
        },

        {
            index: 6,
            subtitle: "Restart deployments"
        }
    ]

    granulationFunctions = [
        {
            value: 'poly',
            name: 'poly'
        },

        {
            value: 'legato',
            name: 'legato'
        },

        {
            value: 'pitchedPercussion',
            name: 'pitchedPercussion'
        },

        {
            value: 'guitarSolo',
            name: 'guitarSolo'
        },

        {
            value: 'vocalPerformanceLegato',
            name: 'vocalPerformanceLegato'
        },

        {
            value: 'percussion',
            name: 'percussion'
        },
    ]

    uploadProgress = 5

    currentStepIndex = 1

    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    error = ""
    instrumentDisplayName = ""
    uploadingStep = ""

    metadata:Array<PotionMetadata> = [
    ]

    patches:Array<PotionPatch> = [
    ]

    keydownTimeout

    isDrumkit = false
    isGuitar = false
    isPad = false
    addStrumming = false
    randomizedLoopPoints = false

    constructor(
        private potionService:PotionService, 
        private modalService:ModalService, 
        private apiService:ApiService, 
        private ref:ChangeDetectorRef,
        private windowService:WindowService
    ) {

    }

    ngOnInit() {
        
    }

    async checkTimeInput(event, group, type, pitch=null) {
        if (this.keydownTimeout != null) {
            clearTimeout(this.keydownTimeout)
            this.keydownTimeout = null
        }

        this.keydownTimeout = setTimeout(() => {
            if (pitch == null) {
                group[type] = Math.min(group.fileDuration, Math.max(0, group[type]))
            }

            else {
                group[type][pitch] = Math.min(group.fileDuration, Math.max(0, group[type][pitch]))
            }

            this.ref.detectChanges()
        }, 1000)
    }   

    getKeys(value) {
        return Object.keys(value)
    }

    checkPatches() {
        for (let patch of this.patches) {
            if (patch.name == "") {
                this.error = "Enter a valid name"
                break
            }

            if (patch.path == "") {
                this.error = "Enter a valid path"
                break
            }
        }
    }

    async goToStep(index) {
        this.error = ""

        try {
            if (index == 2) {
                let result = await this.apiService.authRequest('/potion/checkInstrument', { instrumentName: this.instrumentName }, "primary", true)

                if (result.production) {
                    this.error = "WARNING: this instrument exists in production. Do NOT proceed!"
                    return
                }

                else if (result.staging) {
                    alert("FYI: this instrument is already in staging")
                }

                let metadata = []

                for (let meta of this.metadata) {
                    if (meta.articulation != "stac" && this.isDrumkit) {
                        continue
                    }

                    metadata.push(meta)
                }

                this.metadata = metadata
        
                if (this.metadata.length == 0) {
                    this.error = "No patch detected. Please check that you have added supported samples in your tree folder."

                    return
                }

                if (this.potionService.autofill) {
                    return await this.publishInstrument()
                }
            }

            if (index == 3) {
                this.metadataValidation()
            }

            if (index == 4) {
                this.checkPatches()
            }

            if (this.error == "") {
                this.currentStepIndex = index
            }

            if (index == 5) {
                await this.publishInstrument()
            }
            
            else {
                this.ref.detectChanges()
            }
        }

        catch(e) {
            console.error(e)
            this.error = e
        }
    }

    async publishInstrument() {
        let data = null

        if (this.potionService.autofill) {
            data = {
                samples: this.potionService.samples,
                instrumentJSON: this.potionService.instrumentJSON
            }
        }

        await this.createJSONFiles(data)
        await this.tarSamples()

        if (!this.dryRun) {
            await this.uploadInstrument()
            await this.mergingInstrumentsData()
        }

        this.goToStep(6)
    }

    autoFill() {
        this.modalService.modals.autofill.next(true)
    }

    async mergingInstrumentsData() {
        this.uploadingStep = "Merging instruments data..."

        await this.apiService.authRequest('/potion/mergeInstrumentData', { instrumentName: this.instrumentName }, "primary", true)

        this.uploadProgress = 90

        this.uploadingStep = "Waiting ~1 minute to let samples get compiled and deployments get restarted... You can restart the page at this stage if you want to add a new instrument but make sure to wait a few minutes between uploads."

        await Misc.wait(40)

        this.uploadProgress = 95

        await Misc.wait(40)
    }

    async createJSONFiles(data) {
        this.uploadingStep = "Create JSON files..."
        
        if (this.windowService.desktopAppAPI === undefined) {
            alert("You must use the desktop app")

            return
        }
        
        for (let patch of this.patches) {
            if (this.isDrumkit) {
                patch.granulationFunction = "percussion"
                patch.path += "/" + patch.name
            }
        }  

        if (!this.isPad) {
            this.randomizedLoopPoints = false
        }

        await this.windowService.potionAPI.getPotionData({
            instrumentDisplayName: this.instrumentDisplayName,
            metadata: this.metadata,
            patches: this.patches,
            isDrumkit: this.isDrumkit,
            isGuitar: this.isGuitar,
            addStrumming: this.addStrumming,
            existingData: data,
            randomizedLoopPoints: this.randomizedLoopPoints,
            gmNumber: 0
        })

        this.uploadProgress = 10
    }

    async tarSamples() {
        this.uploadingStep = "Archiving samples..."

        await this.windowService.potionAPI.potionTarSamples()
        
        this.uploadProgress = 25
    }

    async uploadInstrument() {
        this.uploadingStep = "Uploading instruments to bucket..."
        
        let result = await this.apiService.authRequest('/potion/getAccess', {}, "primary", true)

        await this.windowService.potionAPI.potionUploadInstrument(result)

        this.uploadProgress = 80
    
    }

    createAnotherInstrument() {
        location.reload()
    }

    async selectPath() {
        if (this.windowService.desktopAppAPI === undefined) {
            alert("You must use the desktop app")

            return
        }

        this.error = ''
        this.instrumentPath = ''
        this.pathLoading = true
        let result = await this.windowService.potionAPI.potionCheckFilesOnDisk()

        if (result.error != null) {
            this.error = result.error
            this.pathLoading = false

            return
        }

        if (!this.isUsingKebabCase(result.name)) {
            this.error = "Please use kebab case formatting on the selected folder"
            this.pathLoading = false

            return
        }

        this.instrumentPath = result.path
        this.instrumentName = result.name

        this.patches = []
        this.metadata = []

        for (let patch of result.patches) {
            if (patch.playingStyle == "deadnote" || patch.articulation == "bend" || patch.articulation == "slide" || patch.articulation == "vib") {
                continue
            }

            this.patches.push(PotionPatch.fromJSON(patch))
        }

        for (let metadata of result.metadata) {
            if (metadata.articulation == "sus") {
                metadata.hasRelease = true
            }
            
            this.metadata.push(PotionMetadata.fromJSON(metadata))
        }

        this.pathLoading = false
    }

    ngAfterViewInit(): void {
    }

    isUsingKebabCase(value:string) {
        let lowerCasedValue = value.toLowerCase()

        if (lowerCasedValue != value || value.replace(" ", "") != value || value.replace(".", "") != value) {
            return false
        }

        return true
    }

    metadataValidation() {
        for (let object of this.metadata) {
            object.sampleStart = Math.max(0, object.onset - 0.3)

            if (object.articulation == "stac") {
                if (object.hasRelease && object.relIsInSameFile) {
                    object.releaseStart = object.oneShotAfter
                    object.releaseOnset = object.oneShotAfter + 0.3
                }
            }

            if (object.articulation == "sus") {
                object.releaseStart = Math.max(0, object.releaseOnset - 0.3)

                if (object.loopStart < object.onset + 0.3) {
                    this.error = "Set the loop start at least 0.3s after the onset"
                    break
                }

                if (object.loopEnd - object.loopStart < 2) {
                    this.error = "The distance between loop start / end needs to be at least 2s"
                    break
                }

                if (object.releaseOnset - object.loopEnd < 0.3 && object.hasRelease) {
                    this.error = "Set the loop end at least 0.3s before the release onset"
                    break
                }
            }
        }
    }

    selectGranulationFunction(event, patch) {
        patch.granulationFunction = event.new.value
    }

    getGranulationFunction(patch) {
        return patch.granulationFunction
    }
}
