import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import { ApiService } from "@services/api.service"
import { SourcePackService } from "../../../services/source-packs/sourcepacks.service"

@Component({
    selector: "refresh-gp-source-packs",
    templateUrl: "refresh-gp-source-packs.component.html",
    styleUrls: ["refresh-gp-source-packs.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RefreshGPSourcePacksComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter()

    loading:boolean = true

    constructor(private apiService:ApiService, private ref:ChangeDetectorRef, private sourcePacks:SourcePackService) {

    }

    async ngOnInit() {
        try {
            await this.apiService.authRequest("/refreshGPSourcePacks", {}, "primary", true)
        
            // Now, we wait, since it may take a bit of time for this call to fully propagate accross all of the pods of our kubernetes setup
            await Misc.wait(15)

			this.sourcePacks.resetPacks()

            this.loading = false
            this.detectChanges()
        }
    
        catch(e) {
            this.apiService.handleError(e)
        }
    }

    closeModal() {
        this.close.emit()
    }

    detectChanges() {
        return this.ref.detectChanges()
    }
}