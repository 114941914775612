import Layer from "@common-lib/classes/score/layer"
import PercussionLayer from "@common-lib/classes/score/percussionlayer"
import TrackBus from "@common-lib/classes/score/trackbus"
import {
    AccompanimentDesignerCanvasContext,
    AutomationCanvasContext,
    ChordsEditingButtonsFunction,
    ChordsEditingContext,
    DrumSequencerCanvasContext,
    EditChordFunction,
    HoverChordFunction,
    KeySignatureEditingContext,
    LayerPreviewCanvasContext,
    MouseAction,
    PatternHorizontalScrollbarCanvasContext,
    PatternRegionsCanvasContext,
    PianoCanvasContext,
    PianorollGridCanvasContext,
    ScoreCanvasContext,
    TempoCanvasContext,
    TimelineCanvasContext,
    TrackbusRegionsCanvasContext,
} from "../../../../common-lib/client-only/score-rendering-engine"
import ScoreRenderingEngine from "../../../../common-lib/client-only/score-rendering-engine/engine"
import { Helpers } from "./helpers.module"
import { EditorService } from "@services/editor/editor.service"
import { CWQuery } from "@services/composition-workflow/cw-store/cw.query"
import { Misc } from "@common-lib/modules/misc"

export module InitCanvases {
    export async function initPatternHorizontalScrollbarCanvas(
        engine: ScoreRenderingEngine,
        allowTimestepClick: boolean
    ) {
        if (
            !engine.toggledLayer ||
            engine.toggledLayer.trackBuses.length === 0
        ) {
            return
        }

        const mouseAction: MouseAction = {
            move: false,
            resize: false,
            remove: false,
            draw: false,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "pattern-horizontal-scrollbar"
        )) as HTMLDivElement

        const context: PatternHorizontalScrollbarCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                pencil: mouseAction,
                select: mouseAction,
            },
            gridType: "drumSequencer",
            syncToCanvas:
                "DrumSequencerCanvas_" + engine.toggledLayer.trackBuses[0].id,
            allowTimestepClick,
        }

        engine.initCanvas("PatternHorizontalScrollbarCanvas", context)
    }

    export async function initPianoRollGridCanvas(
        engine: ScoreRenderingEngine,
        hideSections: boolean
    ) {
        const mouseAction: MouseAction = {
            move: false,
            resize: false,
            remove: false,
            draw: false,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "pianoroll-grid"
        )) as HTMLDivElement

        const context: PianorollGridCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                pencil: mouseAction,
                select: mouseAction,
            },
            hideSections: hideSections,
        }

        engine.initCanvas("PianorollGridCanvas", context)
    }

    export async function initCompositionWorkflowCanvas(
        engine: ScoreRenderingEngine
    ): Promise<void> {
        let mouseAction: MouseAction = {
            move: true,
            resize: true,
            remove: true,
            draw: false,
            selection: true,
        }

        let element = (await Helpers.waitForElementByID(
            "pianoroll-grid"
        )) as HTMLDivElement

        let context: PianorollGridCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                pencil: mouseAction,
                select: mouseAction,
            },
            hideSections: true,
        }

        engine.initCanvas("PianorollGridCanvas", context)

        mouseAction = {
            move: false,
            resize: false,
            remove: false,
            draw: false,
            selection: false,
        }

        const layer = engine.getLayers()["Chords"]

        element = (await Helpers.waitForElementByID(
            "layer-preview-chords"
        )) as HTMLDivElement

        const layerPreivewContext: LayerPreviewCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                pencil: mouseAction,
                select: mouseAction,
            },
            gutterPxSize: EditorService.LAYER_PREVIEW_GUTTER_PX,
            layer: layer,
        }

        engine.initCanvas("LayerPreviewCanvas", layerPreivewContext)
    }

    export async function initAutomationCanvas(engine: ScoreRenderingEngine) {
        const mouseAction: MouseAction = {
            move: false,
            resize: false,
            remove: false,
            draw: true,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "automation"
        )) as HTMLDivElement

        const context: AutomationCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                pencil: mouseAction,
                select: mouseAction,
            },
            canvases: [
                {
                    idSuffix: "canvas",
                    zIndex: 0,
                },

                {
                    idSuffix: "canvas-inverted",
                    zIndex: -1,
                },
            ],
        }

        engine.initCanvas("AutomationCanvas", context)
    }

    export async function initKeySignatureEditingCanvas(
        engine: ScoreRenderingEngine,
        editKeySignature: (index: number) => void
    ) {
        const action: MouseAction = {
            move: false,
            resize: true,
            remove: false,
            draw: false,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "key-signature-editing-canvas"
        )) as HTMLDivElement

        const context: KeySignatureEditingContext = {
            canvasContainer: element,
            mouseActions: {
                select: action,
                pencil: action,
            },
            editKeySignature
        }

        engine.initCanvas("KeySignatureEditingCanvas", context)
    }

    export async function initChordsEditingCanvas(
        query: CWQuery,
        allowChordsEditing: boolean,
        editChord: EditChordFunction,
        chordsEditingButtons: ChordsEditingButtonsFunction,
        engine?: ScoreRenderingEngine
    ) {
        const action: MouseAction = {
            move: false,
            resize: true,
            remove: false,
            draw: false,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "chords-editing-canvas"
        )) as HTMLDivElement

        const context: ChordsEditingContext = {
            canvasContainer: element,
            mouseActions: {
                select: action,
                pencil: action,
            },
            allowChordsEditing,
            editChord,
            chordsEditingButtons,
        }

        if (query) {
            query.engine.initCanvas("ChordsEditingCanvas", context)
        } else if (engine) {
            engine.initCanvas("ChordsEditingCanvas", context)
        }
    }

    export async function initChordsLabelCanvas(engine: ScoreRenderingEngine) {
        const action: MouseAction = {
            move: false,
            resize: false,
            remove: false,
            draw: false,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "chords-canvas"
        )) as HTMLDivElement

        const context: ScoreCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                select: action,
                pencil: action,
            },
        }

        engine.initCanvas("ChordsLabelCanvas", context)
    }

    export async function initVerticalScrollingCanvas(
        engine: ScoreRenderingEngine
    ) {
        const action: MouseAction = {
            move: true,
            resize: false,
            remove: false,
            draw: false,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "vertical-scrolling"
        )) as HTMLDivElement

        const context: ScoreCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                select: action,
                pencil: action,
            },
        }

        engine.initCanvas("VerticalScrollingCanvas", context)
    }

    export async function initDrumSequencerCanvas(
        trackBus: TrackBus,
        engine: ScoreRenderingEngine
    ) {
        const pencil: MouseAction = {
            move: false,
            resize: true,
            remove: true,
            draw: true,
            selection: false,
        }

        const select: MouseAction = {
            move: true,
            resize: true,
            remove: true,
            draw: false,
            selection: true,
        }

        const elementID = "drum-sequencer-" + trackBus.id

        await Helpers.waitForElementByID(elementID)

        const context: DrumSequencerCanvasContext = {
            canvasContainer: document.getElementById(
                elementID
            ) as HTMLDivElement,
            mouseActions: {
                pencil: pencil,
                select: select,
            },
            gridType: "drumSequencer",
            canvases: [
                {
                    idSuffix: "canvas-" + trackBus.id,
                    zIndex: 0,
                },
                {
                    idSuffix: "canvas-grid-" + trackBus.id,
                    zIndex: -1,
                },
            ],
            trackBusID: trackBus.id,
        }

        engine.initCanvas("DrumSequencerCanvas", context)
    }

    export async function initAccompanimentDesignerCanvas(
        engine: ScoreRenderingEngine,
        pitchCount?: number
    ) {
        const pencil: MouseAction = {
            move: true,
            resize: true,
            remove: true,
            draw: true,
            selection: false,
        }

        const select: MouseAction = {
            move: true,
            resize: true,
            remove: true,
            draw: false,
            selection: true,
        }

        const element = (await Helpers.waitForElementByID(
            "accompaniment-designer"
        )) as HTMLDivElement

        const context: AccompanimentDesignerCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                select: select,
                pencil: pencil,
            },
            canvases: [
                {
                    idSuffix: "canvas-grid",
                    zIndex: 0,
                },
                {
                    idSuffix: "canvas",
                    zIndex: 0,
                },
                {
                    idSuffix: "canvas-pitchstep-sidebar",
                    zIndex: 1,
                },
            ],
            pitchCount,
        }

        engine.initCanvas("AccompanimentDesignerCanvas", context)
    }

    export async function initDrumSequencerTimelineCanvas(
        engine: ScoreRenderingEngine
    ) {
        if (
            engine.toggledLayer === undefined ||
            engine.toggledLayer.trackBuses.length === 0
        ) {
            return
        }

        const mouseAction: MouseAction = {
            move: false,
            resize: false,
            remove: true,
            draw: true,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "drum-sequencer-timeline"
        )) as HTMLDivElement

        const context: TimelineCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                pencil: mouseAction,
                select: mouseAction,
            },
            syncToCanvas:
                "DrumSequencerCanvas_" + engine.toggledLayer.trackBuses[0].id,
        }

        engine.initCanvas("TimelineCanvas", context)
    }

    export async function initPatternRegionCanvas(
        engine: ScoreRenderingEngine
    ) {
        const pencil: MouseAction = {
            move: true,
            resize: true,
            remove: true,
            draw: false,
            selection: true,
        }

        const select: MouseAction = {
            move: true,
            resize: true,
            remove: true,
            draw: false,
            selection: true,
        }

        const elementID = "pattern-regions"

        await Helpers.waitForElementByID(elementID)

        const context: PatternRegionsCanvasContext = {
            noteBorderWidth: 2,
            canvasContainer: document.getElementById(
                "pattern-regions"
            ) as HTMLDivElement,
            mouseActions: {
                pencil,
                select,
            },
            layerInstrumentListItemHeight: 44,
            patternNameHeight: 15,
            borderRadius: 8,
            canvases: [
                {
                    idSuffix: "canvas",
                    zIndex: 0,
                },

                {
                    idSuffix: "canvas-overlay",
                    zIndex: 1,
                },
            ],
        }

        engine.initCanvas("PatternRegionsCanvas", context)
    }

    export async function initTrackBusRegionsCanvas(
        layer: Layer,
        trackBus: TrackBus,
        engine: ScoreRenderingEngine
    ) {
        const elementID = "trackbus-regions-" + layer.value + "-" + trackBus.id

        const elem = await Helpers.waitForElementByID(elementID)

        const context: TrackbusRegionsCanvasContext = {
            canvasContainer: elem as HTMLDivElement,
            mouseActions: {
                pencil: {
                    move: false,
                    resize: true,
                    remove: true,
                    draw: true,
                    selection: false,
                },

                select: {
                    move: true,
                    resize: true,
                    remove: true,
                    draw: false,
                    selection: true,
                },
            },
            trackBus: trackBus,
            layer: layer,
        }

        engine.initCanvas("TrackbusRegionsCanvas", context)
    }

    export async function initTimelineCanvas(engine: ScoreRenderingEngine) {
        const mouseAction: MouseAction = {
            move: true,
            resize: false,
            remove: false,
            draw: true,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "timeline"
        )) as HTMLDivElement

        const context: TimelineCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                pencil: mouseAction,
                select: mouseAction,
            },
            syncToCanvas: "AccompanimentDesignerCanvas",
        }

        engine.initCanvas("TimelineCanvas", context)
    }

    export async function initPianoCanvas(
        engine: ScoreRenderingEngine,
        pitchCount?: number
    ) {
        const mouseAction: MouseAction = {
            move: false,
            resize: false,
            remove: false,
            draw: false,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "piano"
        )) as HTMLDivElement

        const context: PianoCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                pencil: mouseAction,
                select: mouseAction,
            },
            pitchCount,
        }

        engine.initCanvas("PianoCanvas", context)
    }

    export async function initTempoCanvas(engine: ScoreRenderingEngine) {
        const mouseAction: MouseAction = {
            move: false,
            resize: false,
            remove: false,
            draw: true,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "tempo-canvas"
        )) as HTMLDivElement

        const context: TempoCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                pencil: mouseAction,
                select: mouseAction,
            },
        }

        engine.initCanvas("TempoCanvas", context)
    }

    export async function initLayerPreviewCanvas(
        layer: Layer | PercussionLayer,
        gutterPx: number,
        engine: ScoreRenderingEngine
    ) {
        const mouseAction: MouseAction = {
            move: false,
            resize: false,
            remove: false,
            draw: false,
            selection: false,
        }

        const element = (await Helpers.waitForElementByID(
            "layer-preview-" + layer.value
        )) as HTMLDivElement

        const context: LayerPreviewCanvasContext = {
            canvasContainer: element,
            mouseActions: {
                pencil: mouseAction,
                select: mouseAction,
            },
            gutterPxSize: gutterPx,
            layer: layer,
        }

        engine.initCanvas("LayerPreviewCanvas", context)
    }
}
