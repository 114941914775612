import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core"

import { ParentClass } from "../../../parent"
import { TrainingsetLabelsModal } from "@services/modal.service"
import { Misc } from "@common-lib/modules/misc"
import {
    ScoresMetadataSchema,
    Trust,
} from "@common-lib/interfaces/db-schemas/trainingsets/scoresMetadata"
import { EditorHttpService } from "@services/editor/editor.http"

type Checkbox = {
    id: string
    name: string
    value: boolean
}

@Component({
    selector: "trainingset-labels",
    templateUrl: "trainingset-labels.component.html",
    styleUrls: ["trainingset-labels.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingsetLabelsComponent extends ParentClass {
    public loading: boolean = false

    @Input() data: TrainingsetLabelsModal
    @Output() close = new EventEmitter<any>()

    trainingset: ScoresMetadataSchema | undefined
    trustLabels: Trust | undefined
    message: string | undefined
    checkboxes: Checkbox[] = []

    descriptions = {}

    constructor(
        private ref: ChangeDetectorRef,
        private http: EditorHttpService
    ) {
        super()
    }

    async ngOnInit() {
        await this.loadTrainingset()
    }

    toggleCheckbox(key: string) {
        this.trustLabels[key] = !this.trustLabels[key]
        this.generateCheckboxes()
        this.ref.detectChanges()
    }

    async loadTrainingset() {
        try {
            this.loading = true
            this.trainingset = await this.http.getTrainingset(
                this.data?.trainingsetID
            )

            if (
                this.trainingset === undefined ||
                this.trainingset?.metadata?.trust === undefined
            ) {
                throw new Error(
                    "No trusted labels found for this trainingset: " +
                        this.data?.trainingsetID
                )
            }

            this.trustLabels = this.trainingset?.metadata?.trust

            this.generateCheckboxes()

            this.loading = false

            this.ref.detectChanges()
        } catch (error) {
            console.error(error)
            this.message = error
            this.loading = false
        }
    }

    getTrustLabels() {
        return this.trainingset?.metadata?.trust
    }

    generateCheckboxes() {
        this.checkboxes = Object.keys(this.trustLabels).map(key => {
            return {
                id: key,
                name: this.getNameFromKey(key),
                value: this.trustLabels[key],
            }
        })
    }

    getNameFromKey(key: string) {
        return key
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
    }

    async clickedButton() {
        this.loading = true
        this.message = undefined

        this.ref.detectChanges()

        await Misc.wait(0.01)

        this.trustLabels["music_supervisor_modified"] = true

        this.message = await this.data.onComplete(this.trustLabels)

        console.log(
            "saveTrainingsetLabels from within trainingset-labels",
            this.trustLabels,
            this.message
        )

        this.loading = false

        this.ref.detectChanges()
    }
}
