<div
    class="modal-background"
    (click)="closeModal()"
    av-data="do-action-before-modal"></div>
<div class="modal-container">
    <div class="modal-title" av-data="do-action-before-modal-tittle">
        {{ input.title }}
    </div>
    <div class="modal-subtitle" av-data="do-action-before-modal-subtitle">
        {{ input.description }}
    </div>

    <div class="modal-close">
        <img src="assets/img/close.svg" (click)="closeModal()" />
    </div>

    <div class="error-message" *ngIf="!loading && error !== undefined">
        {{ error }}
    </div>
    <div class="message-container" *ngIf="input.modalId">
        <input type="checkbox" name="message" [(ngModel)]="dontShowAgain" />
        <label for="message">Don't show this again</label>
    </div>
    <div class="buttons-container">
        <div class="loader" *ngIf="loading"></div>

        <a-button
            label="Cancel"
            (click)="closeModal()"
            width="50%"
            *ngIf="!loading"
            type="text"
            av-data="do-action-before-modal-cancel"></a-button>

        <a-button
            [label]="input.continueButtonText"
            width="50%"
            type="secondary"
            (click)="continue()"
            *ngIf="!loading && input.color === undefined"
            av-data="do-action-before-modal-confirm"></a-button>

        <a-button
            [label]="input.continueButtonText"
            width="50%"
            type="secondary"
            class="red"
            (click)="continue()"
            *ngIf="!loading && input.color === 'delete'"></a-button>
    </div>
</div>
