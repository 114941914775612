<div id="login-component" class="component-container">    

    <div id="login-window-wrapper">
        <div class="aiva-logo">
            <img src="assets/img/navbar/aiva_white.svg">
            <div class="aiva-logo-text">AIVA</div>
        </div>
        <div id="reset-password-window" class="window">
            <div id="title">{{ title }}</div>
            
            <div class="message-box" *ngIf="message.show" [ngClass]="message.type">
                <div class="close-button" (click)="hideMessage()">
                    <img src="assets/img/close.svg">
                </div>
                <div class="text"> {{ message.text }} </div>
            </div>

            <input type="password" placeholder="New password" [(ngModel)]="newPassword" (ngModelChange)="onInput($event)">
            <input type="password" placeholder="Confirm new password" [(ngModel)]="confirmNewPassword" (ngModelChange)="onInput($event)">

            <!--<re-captcha (resolved)="resolved($event)" siteKey="6Leuzq4UAAAAAIawqiYpAbeHIDvh4b0eLhdimOSW" class="recaptcha"></re-captcha>-->

            <div id="terms-and-conditions" *ngIf="tncMandatory">
                <input type="checkbox" [(ngModel)]="tnc" placeholder="I have read the T&C + Privacy Policy">
                <span>
                    I agree to the <a href='https://www.aiva.ai/legal/1'>EULA</a> and <a href='https://www.aiva.ai/legal/0'>Privacy Policy</a>
                </span>
            </div>
            <div class="loader" *ngIf="loading"></div>
            <div class="round-button" (click)="setupNewPassword()" *ngIf="!loading">{{ buttonText }}</div>
        
            <div class="text-button" (click)="goToLogin()">Cancel</div>
        </div>
    </div>
</div>