<div
    class="modal-background"
    (click)="close.emit()"
    av-data="do-action-before-modal"></div>
<div class="modal-container">
    <div class="modal-title" av-data="do-action-before-modal-tittle">
        Create new composition(s)
    </div>

    <div class="modal-close">
        <img src="assets/img/close.svg" (click)="close.emit()" />
    </div>

    <div class="settings-row-wrapper">
        <div class="settings-row">
            <div class="settings-row-title">Duration</div>
            <div class="settings-row-value">
                <dropdown
                    [value]="getSelectedDuration()"
                    [showTooltip]="false"
                    [items]="getDurationOptions()"
                    (selectedItem)="setDuration($event)"></dropdown>
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">Number of Compositions</div>
            <div class="settings-row-value">
                <dropdown
                    [value]="{
                    value: nbOfCompositions,
                    name: nbOfCompositions
                }"
                    [showTooltip]="false"
                    [items]="getNbOfCompositionsOptions()"
                    (selectedItem)="setNbOfCompositions($event)"></dropdown>
            </div>
        </div>

        <div class="settings-row" *ngIf="getFeatureFlags().newThematicMaterialToggle && !getFeatureFlags().enableAutomaticVariationImprovements">
            <div class="settings-row-title">New thematic material</div>
            <div class="settings-row-value">
                <slide-toggle
                    [(toggled)]="newThematicMaterial"
                    (onChange)="setThematicMaterial($event)"></slide-toggle>
            </div>
        </div>
    </div>

    <div class="error-message" *ngIf="!loading && error !== undefined">
        {{ error }}
    </div>

    <div class="buttons-container">
        <div class="loader" *ngIf="loading"></div>

        <a-button
            [label]="'Create'"
            width="100%"
            type="secondary"
            (click)="continue()"
            *ngIf="!loading"
            av-data="do-action-before-modal-confirm"></a-button>
    </div>
</div>
