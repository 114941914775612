<div class="modal-title">Share</div>
<div class="modal-close"><img src="assets/img/close.svg" (click)="closeModal()"></div>

<div class="share-header">
    <div class="link-sharing-subheader">Link Sharing - </div>
    <div class="link-sharing-subheader link-sharing-button" *ngIf="generationProfile.shared"
        (click)="toggleLinkSharing(false)">Disable</div>
    <div class="link-sharing-subheader link-sharing-button" *ngIf="!generationProfile.shared"
        (click)="toggleLinkSharing(true)">Enable</div>
</div>

<div class="link-sharing-input" ngxClipboard [cbContent]="getSharedLink()" (click)="linkCopied = true">{{ getSharedLink() }}</div>

<div *ngIf="linkCopied" class="link-copied">
    <img src="assets/img/menu/link.svg">
    Link copied to your clipboard!
</div>