<div class="create-with-influence-outter-container" [perfectScrollbar]="scrollConfig">
    <div class="modal-title" *ngIf="parameters.view == 'parameters'">
        Create from an influence
    </div>
    <div class="modal-title" *ngIf="parameters.view == 'ensemble-selection'">
        Select an ensemble
    </div>
    <!-- <div class="modal-title" *ngIf="parameters.view == 'mode-change'">Warning</div> -->

    <div class="modal-close">
        <img src="assets/img/close.svg" (click)="cancelCreateWithInfluence()" />
    </div>

    <div class="create-with-influence-container" *ngIf="!loading">
        <!-- Parameters view -->
        <div
            class="parameters-view"
            *ngIf="
                parameters == null ||
                (parameters.view != 'ensemble-selection' &&
                    parameters.view != 'mode-change')
            ">
            <div class="ensemble-and-key-signature" *ngIf="error == ''">
                <div class="parameter-wrapper ensemble">
                    <div class="modal-subtitle parameter-wrapper-title">
                        Ensemble
                    </div>

                    <!-- <select class="ensemble-selection-button" [(ngModel)]="parameters.ensemble" [class.custom-select]="changeSelectStyle">
                    <optgroup *ngFor="let category of categoriesAndEnsembles" label="{{ category.name }}">
                        <option *ngFor="let ensemble of category['ensembles'].sort(); let i = index" [ngValue]="ensemble" [selected]="parameters.ensemble != null && ensemble == parameters.ensemble"><span *ngIf="isTopEnsemble(ensemble)">(Recommended) </span>{{ ensemble }}</option>
                    </optgroup>
                </select> -->

                    <dropdown
                        type="button-like-dropdown"
                        placeholder="Please select an ensemble"
                        [sort]="true"
                        [value]="selectedEnsembleDropdownOption"
                        [showTooltip]="false"
                        [items]="ensemblesDropdownItems"
                        (selectedItem)="
                            onSelectEnsembleOption($event)
                        "></dropdown>
                </div>

                <div
                    class="parameter-wrapper key-signature"
                    *ngIf="keyItems.length > 0">
                    <div class="modal-subtitle parameter-wrapper-title">
                        Key Signature
                    </div>

                    <div
                        class="key-signature-selection-button-wrapper"
                        [ngClass]="
                            parameters.emotion != null
                                ? 'disable-key-signature-hover'
                                : 'enable-key-signature-hover'
                        "
                        tooltip-marginLeft="-8"
                        tooltip-width="150"
                        [tooltip]="'The key depends on the selected emotion.'"
                        [tooltip-type]="'onmouseenter'"
                        [tooltip-ignore]="'.enable-key-signature-hover'"
                        [tooltip-position]="'middle-bottom'">
                        <select
                            class="key-signature-selection-button"
                            (change)="selectKey(selectedKey)"
                            [(ngModel)]="selectedKey"
                            [ngClass]="{
                                'disable-key-signature':
                                    parameters.emotion != null
                            }"
                            (mouseenter)="
                                parameters.emotion == null
                                    ? (keyList = !keyList)
                                    : return
                            "
                            (mouseleave)="
                                parameters.emotion == null
                                    ? (keyList = !keyList)
                                    : return
                            "
                            [class.custom-select]="changeSelectStyle">
                            <optgroup label="Major">
                                <option
                                    *ngFor="
                                        let key of getKeyItemsByMode('major');
                                        let i = index
                                    "
                                    [ngValue]="key"
                                    [selected]="
                                        parameters.keySignature != null &&
                                        key == parameters.keySignature
                                    ">
                                    <span *ngIf="originalKeySignature == key"
                                        >(Originally detected) </span
                                    >{{ key }}
                                </option>
                            </optgroup>

                            <optgroup label="Minor">
                                <option
                                    *ngFor="
                                        let key of getKeyItemsByMode('minor');
                                        let i = index
                                    "
                                    [ngValue]="key"
                                    [selected]="
                                        parameters.keySignature != null &&
                                        key == parameters.keySignature
                                    ">
                                    <span *ngIf="originalKeySignature == key"
                                        >(Originally detected) </span
                                    >{{ key }}
                                </option>
                            </optgroup>
                        </select>
                    </div>
                </div>

                <div class="save-ensemble-and-key-signature-changes">
                    <div id="boolean-parameters">
                        <div class="boolean-parameter">
                            <input
                                type="checkbox"
                                [(ngModel)]="parameters.saveChanges" />
                            <span
                                class="boolean-parameter-title"
                                (click)="
                                    parameters.saveChanges =
                                        !parameters.saveChanges
                                ">
                                Remember changes to the influence ensemble and
                                key signature</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <emotion-select
                [emotions]="emotions"
                [showWarning]="true"
                [emotion]="parameters.emotion"
                (onChange)="onEmotionChange($event)"
                *ngIf="error == ''"></emotion-select>

            <div class="parameter-wrapper" *ngIf="error == ''">
                <div class="modal-subtitle parameter-wrapper-title">
                    Duration
                </div>

                <div
                    class="parameter-item"
                    *ngFor="
                        let duration of getInfluenceDurations();
                        let i = index
                    "
                    (click)="setDuration(duration)"
                    [ngClass]="{
                        'disabled-duration': !showDuration(duration),
                        tooltip: !showDuration(duration),
                        'first-item': i == 0,
                        'last-item': i == getInfluenceDurations().length - 1,
                        'selected-item': duration == parameters.duration
                    }">
                    {{ duration }}

                    <span class="tooltiptext" *ngIf="!showDuration(duration)"
                        >Subscribe to access more duration options.</span
                    >
                </div>
            </div>

            <div class="parameter-wrapper" *ngIf="error == ''">
                <div class="modal-subtitle parameter-wrapper-title">
                    Number of Compositions
                </div>

                <div class="parameter-wrapper">
                    <div
                        class="parameter-item"
                        *ngFor="
                            let compNumber of getInfluenceCompositionNumber();
                            let i = index
                        "
                        (click)="parameters.numberOfCompositions = compNumber"
                        [ngClass]="{
                            'first-item': i == 0,
                            'last-item':
                                i == getInfluenceCompositionNumber().length - 1,
                            'selected-item':
                                compNumber == parameters.numberOfCompositions
                        }">
                        {{ compNumber }}
                    </div>
                </div>
            </div>

            <div class="error-message" *ngIf="error != ''">
                {{ error }}
            </div>

            <div class="modal-button-wrapper" *ngIf="!loading && error == ''">
                <a-button
                    type="secondary"
                    label="Create tracks"
                    width="100%"
                    (click)="createTracks()"></a-button>
            </div>
        </div>

        <!-- Ensemble selection view -->
        <div
            class="ensemble-selection-view"
            *ngIf="parameters.view == 'ensemble-selection'">
            <ngx-simplebar
                class="ensemble-selection-middle-container"
                id="ensemble-selection-middle-scrollbar"
                [options]="scrollOptionsMainContainer">
                <div class="ensembles-row">
                    <div
                        class="ensemble-category"
                        *ngFor="
                            let category of categoriesAndEnsembles;
                            let c = index
                        ">
                        <div class="ensemble-title">
                            <img [src]="category.icon" /> {{ category.name }}
                        </div>

                        <div class="ensembles">
                            <div
                                class="ensemble-pill"
                                *ngFor="
                                    let ensemble of categoriesAndEnsembles[c][
                                        'ensembles'
                                    ].sort();
                                    let u = index
                                "
                                [ngClass]="{
                                    'selected-item':
                                        ensemble === selectedEnsemble,
                                    'selected-ensemble-gold':
                                        ensemble === selectedEnsemble &&
                                        getTopEnsembleType(ensemble) == 'gold',
                                    'selected-ensemble-silver':
                                        ensemble === selectedEnsemble &&
                                        getTopEnsembleType(ensemble) ==
                                            'silver',
                                    'selected-ensemble-bronze':
                                        ensemble === selectedEnsemble &&
                                        getTopEnsembleType(ensemble) == 'bronze'
                                }"
                                (click)="selectEnsemble(ensemble)">
                                <div
                                    class="top-ensemble"
                                    *ngIf="isTopEnsemble(ensemble)"
                                    [tooltip]="'Recommended choice'"
                                    [tooltip-marginTop]="-10">
                                    <img
                                        [src]="getTopEnsembleIcon(ensemble)"
                                        *ngIf="ensemble != selectedEnsemble"
                                        alt="ensemble" />
                                    <img
                                        src="assets/img/medal_selected.svg"
                                        *ngIf="ensemble === selectedEnsemble"
                                        alt="ensemble" />
                                </div>
                                {{ ensemble }}
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-simplebar>

            <div class="modal-button-wrapper" *ngIf="!loading">
                <div
                    class="modal-button modal-text-button"
                    (click)="cancelEnsembleSelection()">
                    Cancel
                </div>
                <div
                    class="modal-button modal-blue-button"
                    (click)="applyEnsemble()">
                    Apply selection
                </div>
            </div>
        </div>

        <!-- Mode change view -->
        <div
            class="mode-change-view"
            *ngIf="parameters.view == 'mode-change' && influence != null">
            <div class="warning-icon">
                <img src="assets/img/warning.svg" />
            </div>
            <div class="warning-text">
                <h3>Changing the mode may lead to unwanted results!</h3>
                <span class="description"
                    >You are about to change the key from
                    <b
                        >{{ influence.parameters.pitchClass }}
                        {{ influence.parameters.keyMode }}</b
                    >
                    to <b>{{ selectedKey }}</b
                    >. It is <u>not</u> recommended to change the mode unless
                    you are confident that it was incorrectly detected during
                    the analysis.</span
                >
            </div>

            <div class="modal-button-wrapper" *ngIf="!loading">
                <div
                    class="modal-button modal-text-button"
                    (click)="discardModeChange()">
                    Discard changes
                </div>
                <div
                    class="modal-button modal-blue-button"
                    (click)="keepModeChange()">
                    Keep changes
                </div>
            </div>
        </div>
    </div>

    <div class="loader-container" *ngIf="loading">
        <div class="loader create-with-influence-loader"></div>
    </div>
</div>
