import { Injectable } from '@angular/core'
import Template from '../models/template'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class TemplateService {
    draggedTemplate:Template = null
    isDragging:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    constructor() {
        
    }
}