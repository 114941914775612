import Layer from "../../../general/classes/score/layer"
import { ImmutableNote, Note } from "../../../general/classes/score/note"
import { Time } from "../../../general/modules/time"
import {
    TIMESTEP_RES,
    TIMESTEP_WIDTH,
} from "../../../general/constants/constants"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    CanvasType,
    GridDimensions,
    HoveredElement,
    PianoRollNotesCanvasContext,
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
} from "../types"
import ScoreCanvas from "./score-canvas"
import { NotesObject } from "../../../general/classes/score/notesObject"
import { TimeSignature } from "../../../general/types/score"
import { Coordinates } from "../../../general/modules/event-handlers"

export default class PianoRollNotesCanvas extends ScoreCanvas {
    get renderingState() {
        return this.queries.scoreRendering.getValue()
    }

    constructor(
        public context: PianoRollNotesCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)

        this.mouseDown$.subscribe(event => {
            this.onMouseDown(event)
        })

        this.mouseUp$.subscribe(event => {
            console.log("mouseup")
        })

        this.mouseMove$.subscribe(event => {
            this.onMouseMove(event)
        })

        this.selection$.subscribe(event => {
            //console.log("selection", event)
        })

        this.resize$.subscribe(event => {
            //console.log("resize", event)
        })

        this.move$.subscribe(event => {
            //console.log("move", event)
        })
    }

    // protected setHoveredElement(hoveredElement: HoveredElement) {
    //     // super.setHoveredElement(hoveredElement)
    // }

    protected shouldSelect(event: Coordinates) {
        /*if(!event || !this.stateOnMouseDown?.mouseCoordinates) {
            return false
        }

        const coordinates = this.stateOnMouseDown?.mouseCoordinates
        
        return (
            Math.abs(coordinates.absolute.x - event.x) >=
                MOUSE_MOVE_THRESHOLD_X &&
            Math.abs(coordinates.absolute.y - event.y) >= 
                MOUSE_MOVE_THRESHOLD_Y
        )*/
    }

    // !! WARNING - THIS CODE IS NOT WORKING AS INTENDED (wrong note width and pxPerTimestep + issues with note steps) - USE AT OWN RISK !!
    public render(state: ScoreRendering, editorViewState: EditorViewState) {
        // const layer: Layer = state?.toggledLayer
        // // render code here
        // this.initRender({
        //     scoreState: state,
        //     editorViewState: editorViewState,
        //     grid: grid,
        //     noteRes: TIMESTEP_RES,
        //     computePxPerTimestep: this.computePxPerTimestep.bind(this),
        //     nbOfYValues: layer.getHighestPitchRange() - layer.getLowestPitchRange()
        // })
        // const score: Score = state?.score
        // if (!score) {
        //     return
        // }
        // // render based on the layers pitch range and add an offset according to the pitch range too
        // const start = performance.now()
        // this.renderNoteSteps(layer.pitchRange)
        // this.renderNotes(layer)
        //console.log(`Rendering took ${performance.now() - start}ms`)
    }

    private renderNotes(layer: Layer) {
        const ctx = this.getContext("canvas")
        const timestepRange = [
            this.scrollToTimestep,
            this.scrollToTimestep + this.getTimestepRange(),
        ]
        const notesToRender = new NotesObject()

        ctx.lineWidth = 0.5
        ctx.globalCompositeOperation = "destination-over" // overlapping notes will be drawn behind the selected note
        ctx.imageSmoothingEnabled = false

        notesToRender.manipulateNoteGroups((notes: Note[]) => {
            for (let n = 0; n < notes.length; n++) {
                const note = notes[n]
                const color = this.getNoteColor(layer, "normal")

                this.renderNote(ctx, layer, note, color)

                // From here onwards, the code handles phrase related graphics
                if (
                    !this.shouldRenderPhrases(layer) ||
                    note?.meta?.phrase == null
                ) {
                    continue
                }

                // const phrases = {}
                // const x = this.computeNoteXPosition(note.start, fullFractionWidth, leftOffset)
                // const xEnd = this.computeNoteXPosition(Time.addTwoFractions(note.start, note.duration), fullFractionWidth, leftOffset)
                // const y = this.computeNoteYPosition(note, highestPitch, topOffset)

                // if (phrases[note.meta.section + " " + note.meta.phrase] == null) {
                // 	phrases[note.meta.section + " " + note.meta.phrase] = {
                // 		points: [ { x: x, y: y }, { x: xEnd, y: y } ],
                // 		phrase: note.meta.phrase,
                // 		section: note.meta.section
                // 	}
                // }

                // else {
                // 	if (phrases[note.meta.section + " " + note.meta.phrase].points[0].y < y) {
                // 		phrases[note.meta.section + " " + note.meta.phrase].points[0].y = y
                // 	}

                // 	phrases[note.meta.section + " " + note.meta.phrase].points[1] = {
                // 		x: xEnd,
                // 		y: y
                // 	}
                // }
            }

            return true
        })

        // if (this.shouldRenderPhrases(layer)) {
        // 	this.drawCanvasPhrases(ctx, phrases, 0.1, 1)
        // }
    }

    private getNotesByRange(
        layer: Layer,
        timestepRange: number[],
        pitchRange?: number[]
    ) {
        const notes: NotesObject = new NotesObject()
        const timeSignature: TimeSignature = this.grid.timeSignature

        // convert timesteps to fractions to use in manipulateNoteGroups
        let range = timestepRange.map(ts =>
            Time.timestepsToFraction(TIMESTEP_RES, ts)
        ) as [string, string]

        layer.notesObject.manipulateNoteGroups(
            (noteGroup, nextNoteGroup) => {
                noteGroup.forEach((note, i) => {
                    const hasValidPitch =
                        pitchRange?.length === 2 &&
                        note.pitch >= pitchRange[0] &&
                        note.pitch <= pitchRange[1]

                    if (hasValidPitch || pitchRange === undefined) {
                        notes.addNoteToGroup(note, timeSignature, [])
                    }
                })
                return true
            },
            [...range]
        )

        return notes
    }

    private computeNoteXPosition(note: Note) {
        const pxPerTimestep = this.pxPerTimestep
        const scrollXOffset = pxPerTimestep * this.scrollToTimestep

        return Math.ceil(
            Time.fractionToTimesteps(TIMESTEP_RES, note.start) *
                this.pxPerTimestep -
                this.context.noteBorderWidth -
                scrollXOffset
        )
    }

    private computeNoteYPosition(layer: Layer, note: Note) {
        const highestPitch = layer.getHighestNotePitch()

        return (highestPitch - note.pitch) * this.context.noteHeight
    }

    private renderNote(
        ctx: CanvasRenderingContext2D,
        layer: Layer,
        note: Note,
        color
    ) {
        const noteWidth = this.computeNoteWidth(note)
        const noteHeight = this.context?.noteHeight
        const x = this.computeNoteXPosition(note)
        const y = this.computeNoteYPosition(layer, note)

        ctx.fillStyle = color
        ctx.strokeStyle = "#000000"

        // @todo: detect note overlap

        ctx.fillRect(x, y, noteWidth, noteHeight)
        ctx.strokeRect(x, y, noteWidth, noteHeight)
    }

    private renderNoteStep(
        ctx: CanvasRenderingContext2D,
        pitch: number,
        y: number
    ) {
        const noteStepWidth = this.width
        const noteStepHeight = this.context?.noteHeight
        const color =
            pitch % 2 === 0 ? "rgba(0,0,0,0.05)" : "rgba(225,225,225,0.05)"

        // The following is only used for debugging purposes
        // ctx.font = '10px serif'
        // ctx.fillStyle = "red";
        // ctx.fillText("pitch: " + pitch, 5, y-10)

        ctx.fillStyle = color
        ctx.fillRect(0, y, noteStepWidth, noteStepHeight)
    }

    private renderNoteSteps(pitchRange: number[]) {
        const ctx = this.getContext("canvas")

        for (let s = 0; s < pitchRange[1] - pitchRange[0]; s++) {
            const pitch = pitchRange[1] - s
            const y = s * this.context.noteHeight

            this.renderNoteStep(ctx, pitch, y)
        }
    }

    private renderCanvasPhrases(phrases, f, t) {
        const ctx = this.getContext("canvas")

        ctx.strokeStyle = "#00ffff"
        ctx.lineWidth = 1

        for (let phrase in phrases) {
            const points = phrases[phrase].points

            if (points.length == 1) {
                points.push(points[0])
            }

            points[0].y += this.context.noteHeight * 2
            points[1].y += this.context.noteHeight * 2

            if (points[0].y < points[1].y) {
                points[0].y = points[1].y
            }

            if (points[0].y > points[1].y) {
                points[1].y = points[0].y
            }

            ctx.moveTo(points[0].x, points[0].y)
            ctx.quadraticCurveTo(
                points[0].x + (points[1].x - points[0].x) / 2,
                points[0].y + 25,
                points[1].x,
                points[1].y
            )
            ctx.stroke()
        }
    }

    private computePxPerTimestep(): number {
        return Math.max(
            this.resizeFactor * TIMESTEP_WIDTH.PIANO_ROLL,
            TIMESTEP_WIDTH.PIANO_ROLL
        )
    }

    private computeNoteWidth(note: Note) {
        return Math.max(
            Time.fractionToTimesteps(TIMESTEP_RES, note.duration) *
                this.pxPerTimestep -
                this.context.noteBorderWidth,
            this.context.noteBorderWidth
        )
    }

    // Do nothing in the parent class, implement a method override in the child class. This serves as an interface
    public onMouseDown(event: Coordinates): void {
        console.log("click", event, this.hoveredElement)
    }

    public onMouseUp(event: Coordinates): void {}

    public onMouseMove(event: Coordinates): void {
        // TODO: Add hovered note detection here
        // this.setHoveredElement(null)
        //this.setIsSelecting(this.shouldSelect(event))
    }

    public onDoubleClick(event: Coordinates): void {}

    public onScroll(event: Event): void {
        console.log("onScroll: ", event)
    }

    public onKeyDown(event: Event): void {
        console.log("keyDown", event as KeyboardEvent)
    }
}
