export default class PotionMetadata {
    playingStyle:string
    articulation:string
    hasRelease:boolean = false
    relIsInSameFile:boolean = false
    sampleStart:number = 0
    onset:number = 0
    onsetPerPitch = {}
    oneShotAfter:number = 0
    releaseStart:number = 0
    releaseOnset:number = 0
    loopStart:number = 0
    loopEnd:number = 0
    end:number = 0
    fileDuration:number = 0

    constructor(playingStyle, articulation, fileDuration) {
        this.playingStyle = playingStyle
        this.articulation = articulation
        this.fileDuration = fileDuration
        this.end = fileDuration
    }

    static fromJSON(object):PotionMetadata {
        return Object.assign(new PotionMetadata("", "", 0), object)
    }
}