<div class="modal-title" *ngIf="input.type === 'add'">
    Add new pack on {{ input.layer.name }}
</div>

<div class="modal-title" *ngIf="input.type === 'change'">
    Replace {{ input.pack.name }}
</div>

<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<div class="accompaniment-pack-header">
    <div class="accompaniment-pack-types">
        <a-button
            [class]="selectedType === 'aiva' ? 'pack-selected' : 'pack-text'"
            label="Packs by AIVA"
            (click)="getPacks('aiva')"></a-button>
        <a-button
            [class]="
                selectedType === 'currentUser' ? 'pack-selected' : 'pack-text'
            "
            label="My packs"
            (click)="getPacks('currentUser')"
            *ngIf="enableCreatePack()"></a-button>
    </div>
    <div class="accompaniment-pack-options">
        <div class="accompaniment-pack-new">
            <div
                class="loader new-accompaniment-pack-loader"
                *ngIf="createPackLoading"></div>
            <a-button
                *ngIf="!createPackLoading && enableCreatePack()"
                icon="assets/img/add.svg"
                label="Create pack"
                (click)="createAccompanimentPack()"></a-button>
        </div>

        <div
            class="chord-dataset-filter"
            [ngClass]="{ 'is-filtering': isFiltering() }">
            <a-button
                icon="assets/img/filters.svg"
                label="Filters"
                (click)="showFilters($event)"
                #filterButton></a-button>
        </div>
    </div>

    <div class="filters" #filters>
        <ng-container *ngIf="filterList?.length">
            <div
                class="filter-tag"
                *ngFor="let filter of filterList; let f = index"
                (click)="showFilters(null)">
                {{ filter.value }}
                <img
                    src="assets/img/delete.svg"
                    (click)="deleteFilter(filter, f, $event)" />
            </div>
        </ng-container>
    </div>
</div>

<div
    class="accompaniment-pack-list"
    [ngClass]="{ 'filter-list-is-shown': filterList.length !== 0 }"
    *ngIf="!loadingPacks">
    <list
        type="mini-pack"
        class="accompaniment-pack-list"
        [maxHeight]="listMaxHeight"
        [listItems]="listItems"
        [itemLoader]="false"
        [onClick]="selectPack.bind(this)"
        [showMenu]="showMenu.bind(this)"
        [menuOptions]="getTemplatePackMenuOptions()">
    </list>
</div>

<div class="loader-container" *ngIf="loadingPacks">
    <div class="loader"></div>
</div>
