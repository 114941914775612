import { LAYERS, MATERIAL_VARIATIONS_CATEGORIES } from "../../../constants/constants"
import { Misc } from "../../../modules/misc"
import { MaterialVariationsSchema } from "../../../interfaces/db-schemas/generationprofile"
import { FormDescriptionPacks } from "../../../interfaces/music-engine/generationprofile"

export default class MaterialVariations {
    likelihood:number = 0.5
    categories:Array<{ name: string, value:boolean }> = MaterialVariations.defaultCategories()
    layers:Array<{ name: string, value:boolean }> = MaterialVariations.defaultLayers()
    strategy:{ name:string, value:string } = { name: "None", value: "none" }

    constructor() {
        
    }

    decode(encoded:MaterialVariationsSchema, packs:FormDescriptionPacks) {
        this.likelihood = encoded.likelihood

        for (let pack of packs.materialVariationStrategy) {
            if (pack.pack_id === encoded.strategy) {
                this.strategy = {
                    name: pack.name,
                    value: pack.pack_id
                }

                break
            }
        }

        for (let layer of this.layers) {
            layer.value = encoded.layers.includes(layer.name)
        }

        for (let category of this.categories) {
            category.value = encoded.categories.includes(category.name)
        }
    }

    static fromJSON(object) {
        let variationSettings:MaterialVariations = Object.assign(new MaterialVariations(), object)

        return variationSettings
    }

    static defaultCategories() {
        let result:Array<{ name: string, value: boolean }> = []

        for (let category of MATERIAL_VARIATIONS_CATEGORIES) {
            result.push({
                name: category,
                value: false
            })
        }

        return result
    }

    static defaultLayers() {
        let result:Array<{ name: string, value: boolean }> = []

        for (let layer of LAYERS) {
            result.push({
                name: layer,
                value: false
            })
        }

        return result
    }

    static formatCategoryName(value) {
        return Misc.capitalizeFirstLetter(
            Misc.replaceAll(value, "_", " ")
        )
    }

    static formatLayerName(layer) {
        return layer.replace("_", " ")
    }

    encodeCategories() {
        let categories = []

        for (let category of this.categories) {
            if (category.value) {
                categories.push(category.name)
            }
        }

        return categories
    }

    encodeLayers() {
        let layers = []

        for (let layer of this.layers) {
            if (layer.value) {
                layers.push(layer.name)
            }
        }

        return layers
    }
}