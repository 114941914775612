import {
    Component,
    ChangeDetectorRef,
    HostListener,
    AfterViewInit,
} from "@angular/core"
import { ApiService } from "@services/api.service"
import { NavigationEnd, Router, RouterEvent } from "@angular/router"
import { CompositionService } from "../services/composition.service"
import { UserService } from "../services/user.service"
import { ModalService } from "../services/modal.service"
import { TokenService } from "../services/token.service"
import { BillingService } from "../services/billing.service"
import { FolderService } from "../services/folder.service"
import { InfluenceService } from "../services/influence.service"
import { GeneralService } from "../services/general/general.service"
import { environment } from "@environments/environment"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { DoublesService } from "@services/doubles.service"
import { DeviceDetectorService } from "ngx-device-detector"
import { AnimationLoopService } from "@services/animationloop.service"
import { LoadingStatusService } from "@services/loadingstatus.service"
import { SupportChatService } from "@services/supportchat.service"
import { WindowService } from "@services/window.service"
import { enableAkitaProdMode } from "@datorama/akita"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { InstrumentsDownloadService } from "@services/instruments/instrumentsDownload.service"
import { playerQuery } from "../../../../common-lib/client-only/general/classes/playerStateManagement"
import { TutorialService } from "@services/tutorial.service"
import { featureFlags } from "../../../../common-lib/general/utils/feature-flags"
import { ConfirmDiscardChangesService } from "@services/confirm-discard-changes.service"

const countriesList = require("countries-list").countries
@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styles: [],
})
export class AppComponent implements AfterViewInit {
    errorMessage = ""
    isLoggedIn = false
    errorTimeout
    isUpdating = false

    paypalLoad: boolean = false

    tokenInterval = null

    isInitialized = false

    constructionMode = null

    appSetupTimeout = null

    isMacElectronApp = false
    showDragAndDropUI = false
    editorIsOpen = false

    dragLeaveListener

    showChat = true

    deviceSpecificClass = ""

    constructor(
        private loadingStatusService: LoadingStatusService,
        private device: DeviceDetectorService,
        private animationLoopService: AnimationLoopService,
        private doublesService: DoublesService,
        private instruments: InstrumentsService,
        private generalService: GeneralService,
        private userService: UserService,
        private folderService: FolderService,
        private billingService: BillingService,
        private apiService: ApiService,
        private tokenService: TokenService,
        private compositionService: CompositionService,
        private modalService: ModalService,
        private router: Router,
        private influenceService: InfluenceService,
        private gpService: GenerationProfileService,
        private supportChatService: SupportChatService,
        private windowService: WindowService,
        private instrumentsDownload: InstrumentsDownloadService,
        private tutorialService: TutorialService,
        private readonly confirmDiscard: ConfirmDiscardChangesService
    ) {
        this.supportChatService.setupCrisp()

        var user

        this.deviceSpecificClass = this.generalService.getDeviceSpecificClass()

        // to test our assumptions, I let it behave as if it is in prod
        // if (environment.production) {
        enableAkitaProdMode()
        // }

        this.apiService
            .request(
                "/geolocation",
                { geolocation: localStorage.getItem("geolocation") },
                "primary",
                "post",
                true
            )
            .then(result => {
                if (environment.production) {
                    this.apiService.apiURL = result.api
                    this.apiService.socketURL = result.websocket
                    this.apiService.backupAPIURL = result.backupAPI
                }

                this.tokenService.isInitialized.subscribe(value => {
                    if (!value) {
                        return
                    }

                    this.isInitialized = value
                })

                this.modalService.constructionMode.subscribe(value => {
                    if (this.constructionMode == true && value == false) {
                        location.reload()
                    }

                    this.constructionMode = value
                })

                this.userService.showChat.subscribe(value => {
                    this.showChat = value
                })

                this.router.events.subscribe(val => {
                    if (val instanceof NavigationEnd) {
                        this.editorIsOpen = val.url.includes("/editor")
                    }
                })

                if (
                    this.windowService.window.location.href.includes(
                        "redirectToApp=true"
                    )
                ) {
                    localStorage.setItem("redirectToApp", "true")
                }

                user = localStorage.getItem("currentUser")

                if (user != null && user != "") {
                    this.apiService.redirectToApp(user)
                }

                this.appSetupTimeout = setTimeout(() => {
                    location.reload()
                }, 180000)

                this.animationLoopService.addFunctionToLoop(
                    "getVersion",
                    this.modalService.getVersion.bind(this.modalService),
                    2 * 60 * 1000
                )

                return this.apiService.request(
                    "/getStartupData",
                    {},
                    "primary",
                    "post",
                    true
                )
            })

            .then(async res => {
                this.gpService.gpLibraryStyles = res.gpLibraryStyles
                this.apiService.realTimeSamplerVersion =
                    res.realTimeSamplerVersion

                this.compositionService.parameters = res.compositionParameters
                this.compositionService.influenceParameters =
                    res.influenceParameters
                this.influenceService.validInfluenceSourceTypes =
                    res.validInfluenceSourceTypes

                this.billingService.countries.next(
                    AppComponent.processCountries()
                )

                var promise = Promise.resolve()

                if (user) {
                    this.apiService.isLoggedIn.next(true)
                    this.apiService.user.next(user)

                    promise = this.tokenService.generateToken()
                }

                return promise
            })

            .then(async () => {
                if (this.windowService.desktopAppAPI !== undefined) {
                    // Loading instruments first before finishing the app setup is necessary to
                    // prevent a race condition with the real time sampler
                    await this.instruments.loadInstruments()

                    // fetch meta info for the desktop app ahead of time, so it can be used throughout the app
                    windowService.desktopAppInfo =
                        await windowService.generalAPI.getMetaInfo()
                }

                await this.appSetup()

                if (this.appSetupTimeout) {
                    clearTimeout(this.appSetupTimeout)
                    this.appSetupTimeout = null
                }

                this.tokenService.isInitialized.next(true)

                this.apiService.initSocketReconnectionHandler()

                if (this.windowService.desktopAppAPI === undefined) {
                    await this.instruments.loadInstruments()
                }

                this.instrumentsDownload.checkIfAllInstrumentsAreDownloaded()

                this.resumeDownloadAllInstruments(
                    localStorage.getItem("resumeDownloadAllInstruments")
                )

                await this.checkIfAppAndOsArchMatch()

                this.loadingStatusService.init()
            })

            .catch(err => {
                console.error(err)
            })
    }

    static processCountries() {
        let billableCountries: any[] = []

        for (var country in countriesList) {
            countriesList[country].countryCode = country
            billableCountries.push(countriesList[country])
        }

        billableCountries.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }

            return 0
        })

        billableCountries.unshift({
            countryCode: "",
            name: "Select a country",
            emoji: "",
        })

        return billableCountries
    }

    preventDropDefault(event) {
        event.preventDefault()
    }

    ngOnInit() {
        if (this.apiService.captcha.getValue() == null) {
            this.apiService.setupCaptcha()
        }
    }

    async ngAfterViewInit() {
        if (await this.windowService.isOutdatedApp()) {
            this.modalService.modals.outdatedDesktopApp.next(true)
        }

        if (this.windowService.desktopAppAPI !== undefined) {
            this.windowService.generalAPI.getMetaInfo().then(result => {
                this.isMacElectronApp =
                    result["platform"] == "darwin" ||
                    result["platform"] == "mac"
            })
        }

        window["$crisp"].push(["do", "chat:close"])
    }

    /**
     * This method checks if the architecture of the desktop app matches the OS architecture.
     * In case it does not, it will show a modal to the user that holds information
     * on where the user can download a matching version of the app.
     * @returns {Promise<void>}
     */
    async checkIfAppAndOsArchMatch() {
        if (this.windowService?.desktopAppAPI === undefined) {
            return
        }

        const metaInfo = await this.windowService?.generalAPI?.getMetaInfo()
        const osInfo = await this.windowService?.generalAPI?.getOSInfo()
        const majorOsVersion = parseInt(osInfo?.os?.version?.split(".")[0])

        if (!metaInfo || !osInfo) {
            return
        }

        // We generally do not support MacOS versions below 11 (Darwin 20)
        const isUnsupportedOS =
            majorOsVersion &&
            metaInfo?.platform === "darwin" &&
            majorOsVersion < 20

        // We do not support MacOS versions below 13 (Darwin 22) when using ARM architecture
        const isUnsupportedArmOS =
            majorOsVersion &&
            metaInfo?.platform === "darwin" &&
            metaInfo?.osArch === "arm64" &&
            metaInfo?.appArch === "arm64" &&
            majorOsVersion < 22

        if (isUnsupportedOS || isUnsupportedArmOS) {
            this.showUnsupportedOsModal({
                isUnsupportedOS,
                isUnsupportedArmOS,
            })
        } else if (
            metaInfo?.platform === "darwin" &&
            metaInfo?.appArch !== undefined &&
            metaInfo?.osArch !== undefined &&
            metaInfo?.appArch !== metaInfo?.osArch &&
            majorOsVersion > 22
        ) {
            await this.promptMacArmAppDownload()
        }
    }

    showUnsupportedOsModal(args: {
        isUnsupportedOS: boolean
        isUnsupportedArmOS: boolean
    }) {
        this.modalService.modals.unsupportedOS.next({
            isUnsupportedOS: args.isUnsupportedOS,
            isUnsupportedArmOS: args.isUnsupportedArmOS,
        })
    }

    async initLoggedInUser(user: string) {
        this.initChatForLoggedInUser(user)

        this.isLoggedIn = this.userIsLoggedIn(user)

        if (this.tokenService.renewalInterval == null) {
            await this.tokenService.generateToken()
        }

        await this.apiService.socketSetup()

        let deviceType = "desktopBrowser"

        if (this.device.isMobile()) {
            deviceType = "mobile"
        } else if (this.windowService.desktopAppAPI !== undefined) {
            deviceType = "desktopApp"
        }

        const result = await this.apiService.authRequest(
            "/user/init",
            {
                deviceType,
                browserVersion: this.device.browser,
                operatingSystem: this.device.os,
            },
            "primary",
            true
        )

        this.userService.accountCreationDate = result.accountCreationDate

        this.doublesService.initData(result.doubles, result.doublesFolders)
        this.folderService.setFolderContent({
            path: [],
            selectedFolderID: "",
            contentType: "Compositions",
            content: result.compositions,
            subFolders: result.folders,
        })

        this.userService.setProfileSettings(
            result.mixingOption,
            result.userSettings,
            result.modalSettings
        )

        // We check whether to set up the first time tutorial only if there is not content
        // if (this.folderService.content.getValue().length === 0) {
        //     this.checkInitTutorial()
        // }
    }

    private checkInitTutorial() {
        try {
            if (this.router.url === "/" || this.router.url === "") {
                return this.tutorialService.initHomeTutorial()
            }

            const subscription = this.router.events.subscribe(
                (e: RouterEvent) => {
                    if (e.url === "/" || e.url === "") {
                        this.tutorialService.initHomeTutorial()

                        subscription.unsubscribe()
                    }
                }
            )
        } catch (e) {
            console.warn(e)
        }
    }

    async initLoggedOutUser(user: string) {
        if (this.supportChatService.chatExists()) {
            this.supportChatService.showChatBubble()
        }

        this.userService.settings = null
        this.folderService.lastContentQuery = null

        localStorage.removeItem("mixingOption")
        localStorage.removeItem("token")
        localStorage.removeItem("refreshToken")

        this.tokenService.clearTokenRenewal()

        await this.apiService.socketSetup()
    }

    appSetup() {
        this.apiService.error.subscribe(err => {
            this.errorMessageBanner(err)
        })

        this.apiService.isUpdating.subscribe(isUpdating => {
            this.isUpdating = isUpdating
        })

        return new Promise((resolve, reject) => {
            // This is where we subscribe to get changes made to  the user object, which happens when
            // The user initializes the dashboard and the user logs in / creates an account
            // Also fires when logging out

            this.apiService.user.subscribe(async user => {
                try {
                    if (user != null) {
                        await this.initLoggedInUser(user)
                    } else {
                        await this.initLoggedOutUser(user)
                    }
                } catch (e) {
                    console.log("Error when setting up user: ")
                    console.error(e)
                }

                resolve(null)
            })
        })
    }

    initChatForLoggedInUser(user: string) {
        if (this.supportChatService.chatExists()) {
            if (this.supportChatService.chatIsOpen()) {
                this.supportChatService.showChatBubble()
            } else {
                this.supportChatService.hideChatBubble()
            }

            this.supportChatService.setEmailOnSupportChat(user)
        }
    }

    /*
		When apiService's error parameter is set to a new value, display the error message banner that lives in the navbar
	*/
    errorMessageBanner(err): void {
        if (err != "") {
            this.errorMessage = err

            clearTimeout(this.errorTimeout)
            this.errorTimeout = null

            this.errorTimeout = setTimeout(() => {
                this.errorMessage = ""
            }, 5000)
        }
    }

    cancelError(): void {
        this.errorMessage = ""
    }

    userIsLoggedIn(user): boolean {
        if (user != null && user != "") {
            //this.router.navigate(['/'])

            return true
        }

        return false
    }

    @HostListener("window:beforeunload", ["$event"])
    beforeUnloadEvent(event: KeyboardEvent) {
        if (this.windowService.desktopAppAPI === undefined) {
            return
        }

        this.windowService?.samplerAPI?.stopSampler()
    }

    @HostListener("document:keyup", ["$event"])
    @HostListener("document:keydown", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        const tagName = document.activeElement.tagName

        if (tagName == "INPUT" || tagName == "TEXTAREA") {
            return
        }

        if (
            !environment.production &&
            event.key == "p" &&
            (event.metaKey || event.ctrlKey)
        ) {
            this.router.navigate(["potion"])
        } else if (
            !environment.production &&
            event.key == "d" &&
            event.ctrlKey
        ) {
            this.apiService.socket.getValue().disconnect()
        } else if (
            !environment.production &&
            event.key == "m" &&
            (event.metaKey || event.ctrlKey)
        ) {
            this.router.navigate(["sample-mapper"])
        } else if (
            featureFlags.enableDesktopAppBugReport &&
            this.windowService?.desktopAppAPI !== undefined &&
            event.key.toLowerCase() === "b" &&
            (event.ctrlKey || event.metaKey) &&
            event.shiftKey
        ) {
            this.modalService.modals.desktopAppBugReport$.next(true)
        } else if (
            !this.router.url.includes("/editor") ||
            this.modalService.comment.getValue()
        ) {
            // DO NOTHING

            if ((event.metaKey || event.ctrlKey) && event.key == "z") {
                event.preventDefault()
                event.stopPropagation()
            }
        } else if (this.router.url.includes("/editor")) {
            var state

            if (
                event != null &&
                event.key != null &&
                event.key.includes("Arrow")
            ) {
                event.preventDefault()
                event.stopPropagation()
            }

            if (event != null && event.type == "keyup") {
                return
            }

            if (event.key == "Escape") {
                var value = this.modalService.shortcutModal.getValue()
                this.modalService.shortcutModal.next(!value)
            } else {
                return
            }

            event.preventDefault()
            event.stopPropagation()

            return
        } else if (
            event.key === "d" &&
            event.ctrlKey &&
            this.compositionService.getLastComposition() != null
        ) {
            if (playerQuery.content === undefined) {
                return
            }

            const type =
                this.folderService.getContentType() == "Influences"
                    ? "influence"
                    : "composition"

            this.folderService
                .getContentByID(playerQuery.contentID, type)
                .then(composition => {
                    this.modalService.downloadComposition.next(composition)
                })
        }
    }

    dragOverHandler(event) {
        event.preventDefault()

        if (
            this.apiService.user.getValue() == null ||
            this.router.url.split("?")[0] == "/potion" ||
            this.router.url.split("?")[0] == "/sample-mapper" ||
            this.router.url.includes("generation-profile-editor") ||
            this.router.url.split("?")[0] == "/importjson" ||
            !this.generalService.containsFiles(event)
        ) {
            return
        }

        // this.toggleUploadInfluenceWindow()
    }

    toggleUploadInfluenceWindow() {
        this.router.navigate(["/create", "Upload influence"])
        this.userService.createTrackMenu.next("Upload influence")
    }

    refreshPage() {
        location.reload()
    }

    getDeviceSpecificClass() {
        const browser =
            this.device.browser == null ? "" : this.device.browser.toLowerCase()
        const os = this.device.os == null ? "" : this.device.os.toLowerCase()
        const device = this.device.isMobile() ? "mobile" : "desktop"

        return device + " " + os + " " + browser
    }

    resumeDownloadAllInstruments(resume) {
        if (
            this.windowService?.desktopAppAPI === undefined ||
            resume == null ||
            resume.toString() == "false"
        ) {
            return
        }

        setTimeout(() => {
            this.modalService.modals.resumeDownloadAllInstruments.next(true)
        }, 5000)
    }

    downloadApp() {
        window.open("https://www.aiva.ai/download", "_blank")
    }

    async promptMacArmAppDownload() {
        console.log("promptMacArmAppDownload", this.userService.modalSettings)

        const modalId = "macArmAppDownload"
        if (
            this.userService.modalSettings === undefined ||
            this.userService.modalSettings[modalId]
        ) {
            return false
        }

        const title = "New macOS app is out now!"
        const description =
            "There now is a new version specifically for macOS with M series chips that comes with performance improvements. To benefit from this, please install the latest version of the desktop app. "
        const result = await new Promise(resolve => {
            this.confirmDiscard.openDoActionsBeforeModal({
                title,
                description,
                continueButtonText: "Download",
                modalId,
                action: async dontShowAgain => {
                    this.downloadApp()
                    return { success: true }
                },
                cancel: async dontShowAgain => {
                    return { success: true }
                },
            })
        })
        await result
    }
}
