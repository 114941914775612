import { Time } from "../../modules/time"
import { Time as Time2 } from "../../modules/time2"
import AIVAObject from "../general/aivaobject"
import { Effect } from "./effect"
import { v4 as uuidv4 } from "uuid"
import { circularGetFromArray } from "../../utils/circularGetFromArray"
import { NoteMeta, TemplateNote } from "../../interfaces/score/templateScore"
import Layer from "./layer"
import Section from "./section"
import { FractionString, TimeSignature } from "../../types/score"
import PercussionLayer from "./percussionlayer"
import {
    AUTOMATION_TIMESTEP_RES,
    TIMESTEP_RES,
} from "../../constants/constants"
import { Fraction } from "./fraction"

export type NoteBoundary = {
    start: {
        fraction: FractionString
        timesteps: number
    }
    duration: {
        fraction: FractionString
        timesteps: number
    }
    end: {
        fraction: FractionString
        timesteps: number
    }
}

export type NoteLabel =
    | "C"
    | "C#/Db"
    | "D"
    | "D#/Eb"
    | "E"
    | "F"
    | "F#/Gb"
    | "G"
    | "G#/Ab"
    | "A"
    | "A#/Bb"
    | "B"
    | "C#"
    | "D#"
    | "F#"
    | "G#"
    | "A#"
    | "Db"
    | "Eb"
    | "Gb"
    | "Ab"
    | "Bb"

export type ImmutableNote = Readonly<Note>

export class Note extends AIVAObject {
    static LOWEST_OCTAVE = -2

    static highestNote = 109
    static lowestNote = 9

    static notesInOctave = [
        "A",
        "A#/Bb",
        "B/Cb",
        "C",
        "C#/Db",
        "D",
        "D#/Eb",
        "E",
        "F",
        "F#/Gb",
        "G",
        "G#/Ab",
    ]
    static notesInOctaveForIndexing = [
        "C",
        "C#/Db",
        "D",
        "D#/Eb",
        "E",
        "F",
        "F#/Gb",
        "G",
        "G#/Ab",
        "A",
        "A#/Bb",
        "B/Cb",
    ]
    static noteIsWhite = [
        true,
        false,
        true,
        true,
        false,
        true,
        false,
        true,
        true,
        false,
        true,
        false,
    ]

    static MAJOR_SCALE_INTERVAL: number[] = [0, 4, 7]
    static MINOR_SCALE_INTERVAL: number[] = [0, 3, 7]

    static MAJOR_SCALES: NoteLabel[][] = Note.generateScaleForEveryNote(
        Note.MAJOR_SCALE_INTERVAL
    )
    static MINOR_SCALES: NoteLabel[][] = Note.generateScaleForEveryNote(
        Note.MINOR_SCALE_INTERVAL
    )

    pitch: number

    private _start: Fraction

    public get start(): string {
        return this._start.toString()
    }

    public set start(val: string) {
        this._start = new Fraction(val)
    }

    private _duration: Fraction

    public get duration(): string {
        return this._duration.toString()
    }

    public set duration(val: string) {
        this._duration = new Fraction(val)
    }

    public get startFraction(): Fraction {
        return this._start
    }

    public set startFraction(val: Fraction) {
        this._start = val
    }

    public get durationFraction(): Fraction {
        return this._duration
    }

    meta: NoteMeta

    // other attributes
    color: string
    oppositeColor: string
    beat: number

    enabled: boolean = true

    readonly noteID: string

    constructor(args: {
        pitch: number
        start: string
        duration: string
        meta: NoteMeta
        beat?: number
    }) {
        super()

        this.noteID = uuidv4()
        this.pitch = args.pitch
        this._start = new Fraction(args.start)
        // this.start = args.start
        this.duration = args.duration
        this.meta = args.meta

        this.getNoteColor()
    }

    static encode(
        timeSignature: TimeSignature,
        sections: Section[],
        layer: Layer,
        templateNote: TemplateNote
    ) {
        const notes: Note[] = templateNote.pitch.map((pitch: number) => {
            const note = new Note({
                pitch: pitch,
                start: Time.simplifyFractionFromString(templateNote.start),
                duration: Time.simplifyFractionFromString(
                    templateNote.duration
                ),
                meta: templateNote.meta,
            })

            return note
        })

        layer.addNotes(timeSignature, sections, notes, true)
    }

    static getSectionForNoteStart(
        sections: Section[],
        noteStart: string
    ): number {
        let section = 0

        for (let s = 0; s < sections.length; s++) {
            section = s

            if (
                Time.fractionIsInBoundaries(sections[s], noteStart, true, false)
            ) {
                break
            }
        }

        return section
    }

    static areEqual(note1: Note, note2: Note): boolean {
        return (
            note1 === note2 ||
            (note1.start === note2.start && note1.pitch === note2.pitch)
        )
    }

    static decode(layer: Layer, note: Note): TemplateNote {
        const automation = Note.decodeAutomation(layer, note)
        const result = Note.getNoteIDsAndPitch([note])

        const templateNote: TemplateNote = {
            start: note.start,
            duration: note.duration,
            pitch: result.pitch,
            meta: {
                section: note.meta.section,
                layer: note.meta.layer,
            },
            low_frequency_cut: automation.low_frequency_cut,
            high_frequency_cut: automation.high_frequency_cut,
            reverb: automation.reverb,
            delay: automation.delay,
            dynamic: automation.dynamic,
            note_ids: result.note_ids,
        }

        if (layer.type === "percussion") {
            Note.getTracksForNote(
                templateNote,
                Time.fractionToTimesteps(TIMESTEP_RES, note.start),
                <PercussionLayer>layer
            )

            templateNote.meta.pattern = note.meta.pattern
            templateNote.meta.phrase = note.meta.phrase
        }

        return templateNote
    }

    static getNoteIDsAndPitch(notes: Note[]) {
        const note_ids = {}
        const pitch = []

        for (let note of notes) {
            pitch.push(note.pitch)
            note_ids[note.pitch] = note.noteID
        }

        return {
            note_ids: note_ids,
            pitch: pitch,
        }
    }

    static decodeNoteGroup(
        layer: Layer,
        notes: Note[],
        enabled: boolean
    ): TemplateNote {
        if (notes.length === 0) {
            throw "decodeNoteGroup - Couldn't decode empty note group"
        }

        const firstNote: Note = notes[0]
        const automation = Note.decodeAutomation(layer, firstNote)

        const result = Note.getNoteIDsAndPitch(notes)

        const templateNote: TemplateNote = {
            start: firstNote.start,
            duration: firstNote.duration,
            pitch: result.pitch,
            meta: {
                section: firstNote.meta.section,
                layer: firstNote.meta.layer,
            },
            low_frequency_cut: automation.low_frequency_cut,
            high_frequency_cut: automation.high_frequency_cut,
            reverb: automation.reverb,
            delay: automation.delay,
            dynamic: automation.dynamic,
            note_ids: result.note_ids,
        }

        templateNote.enabled = enabled

        if (layer.type === "percussion") {
            Note.getTracksForNote(
                templateNote,
                Time.fractionToTimesteps(TIMESTEP_RES, firstNote.start),
                <PercussionLayer>layer
            )

            templateNote.meta.pattern = notes[0].meta.pattern
            templateNote.meta.phrase = notes[0].meta.phrase
        }

        return templateNote
    }

    static getTracksForNote(
        note: TemplateNote,
        timestep: number,
        layer: PercussionLayer
    ) {
        if (!note.trackBusses) {
            note.trackBusses = []
        }

        for (let trackBus of layer.trackBuses) {
            if (trackBus.isPlayedAt(timestep)) {
                note.trackBusses.push(trackBus)
            }
        }
    }

    static decodeAutomation(
        layer: Layer,
        note: Note
    ): { [effectType: string]: [string, number][] } {
        const data = {}

        for (let fx in layer.effects) {
            if (fx === "auto_staccato") {
                continue
            }

            data[fx] = Note.getAutomationForNote(
                note,
                fx,
                AUTOMATION_TIMESTEP_RES,
                layer.effects[fx].values
            )
        }

        return data
    }

    static getAutomationForNote(
        note: Note,
        type,
        res,
        data
    ): [string, number][] {
        const formatAutomation = (value: number) => {
            return value && value > 0 ? value : 0
        }

        let newData: [string, number][] = []

        const duration = Time.addTwoFractions(note.start, note.duration)
        const timesteps =
            data != null && data.length > 0
                ? Math.floor(Time.fractionToTimesteps(res, note.start))
                : 0

        for (let d = timesteps; d < data.length; d++) {
            let value = Math.round(data[d])
            let nextValue = data.length - 1 > d ? Math.round(data[d + 1]) : null

            if (type == "low_frequency_cut" || type == "high_frequency_cut") {
                value = data[d]
                nextValue = data.length - 1 > d ? data[d + 1] : null
            }

            let time = d + "/" + AUTOMATION_TIMESTEP_RES
            let nextTime = d + 1 + "/" + AUTOMATION_TIMESTEP_RES

            let firstComparison = Time.compareTwoFractions(time, note.start)

            if (firstComparison == "eq") {
                newData = [["0", formatAutomation(value)]]
            } else if (firstComparison == "gt") {
                if (nextValue == null) {
                    continue
                }

                let secondComparison = Time.compareTwoFractions(time, duration)

                if (secondComparison != "gt") {
                    newData.push([
                        Time.addTwoFractions(time, note.start, true),
                        formatAutomation(value),
                    ])
                } else {
                    break
                }
            } else if (nextValue != null) {
                let secondComparison = Time.compareTwoFractions(
                    nextTime,
                    note.start
                )

                if (secondComparison == "gt") {
                    const first = [d, value]
                    const second = [d + 1, nextValue]

                    const automationValue = Effect.interpolate(
                        first,
                        second,
                        Time.quantizeFraction(note.start, "round", res)
                    )

                    newData = [["0", formatAutomation(value)]]
                }
            }
        }

        if (newData.length == 0 && data.length > 0) {
            newData = [["0", formatAutomation(data[0])]]
        }

        return newData
    }

    static generateScaleForEveryNote(steps: number[]): NoteLabel[][] {
        return this.notesInOctaveForIndexing.map((note, index) =>
            steps.map(step =>
                circularGetFromArray(
                    this.notesInOctaveForIndexing,
                    index + step
                )
            )
        )
    }

    static getOctave(pitch: number): number {
        return Math.floor(pitch / 12) + this.LOWEST_OCTAVE
    }

    /**
     * returns the note name as a string
     * @param pitch the pitch of the note
     * @param forceFlat when set to true, this will use the flat notation
     *                  instead of the sharp notation if possible
     *                  e.g. returns Db instead of C#
     * @returns
     */
    static getNoteString(pitch, forceFlat: boolean = false): string {
        pitch -= 3
        pitch = Math.max(Note.lowestNote, pitch)
        pitch = Math.min(Note.highestNote, pitch)
        pitch = pitch - Note.lowestNote

        const noteIndex = pitch % Note.notesInOctaveForIndexing.length

        let noteName = Note.notesInOctaveForIndexing[noteIndex].split("/")[0]

        if (noteName.includes("#") && forceFlat === true) {
            noteName = Note.notesInOctaveForIndexing[noteIndex].split("/")[1]
        }

        const octave = Math.floor(pitch / 12) - 1

        return noteName + "" + octave
    }

    /**
     * returns the pitch of a note as a number value
     * @param noteName note names as it is set in Note.NOTES, e.g. 'D#/Eb'
     * @param octave
     */
    static getNotePitchByName(noteName: string, octave: number = 2) {
        const octaveMultiplier = Math.abs(this.LOWEST_OCTAVE - octave)
        const noteIndex = Note.notesInOctaveForIndexing.findIndex(
            name => name === noteName
        )

        return noteIndex + octaveMultiplier * 12
    }

    static getNoteTypeAndOctave(pitch, useFlat: boolean = false) {
        pitch = Math.max(Note.lowestNote, pitch)
        pitch = Math.min(Note.highestNote, pitch)
        pitch = pitch - Note.lowestNote
        pitch -= 3

        let noteIndex = pitch % Note.notesInOctaveForIndexing.length

        if (noteIndex < 0) {
            noteIndex = Note.notesInOctaveForIndexing.length - 1 + noteIndex
        }

        return {
            type:
                useFlat &&
                Note.notesInOctaveForIndexing[noteIndex].split("/").length > 1
                    ? Note.notesInOctaveForIndexing[noteIndex].split("/")[1]
                    : Note.notesInOctaveForIndexing[noteIndex].split("/")[0],
            octave: Math.floor(pitch / 12) - 1,
            outsideOfLimits: pitch < 0,
            newPitch: pitch,
        }
    }

    static getPercussionType(pitch) {
        if (pitch == 36 || pitch == 35) {
            return "kick"
        } else if (
            pitch == 40 ||
            pitch == 38 ||
            pitch == 37 ||
            pitch == 30 ||
            pitch == 27 ||
            pitch == 25 ||
            pitch == 24 ||
            pitch == 23 ||
            pitch == 22 ||
            pitch == 21 ||
            pitch == 19 ||
            pitch == 18 ||
            pitch == 17
        ) {
            return "snare"
        } else if (
            pitch == 50 ||
            pitch == 48 ||
            pitch == 47 ||
            pitch == 45 ||
            pitch == 43 ||
            pitch == 41
        ) {
            return "toms"
        } else if (
            pitch == 59 ||
            pitch == 57 ||
            pitch == 53 ||
            pitch == 52 ||
            pitch == 51 ||
            pitch == 49 ||
            pitch == 29 ||
            pitch == 28
        ) {
            return "cymbals"
        } else if (
            pitch == 46 ||
            pitch == 44 ||
            pitch == 42 ||
            pitch == 33 ||
            pitch == 31
        ) {
            return "hihat"
        } else if (pitch == 39) {
            return "claps"
        }

        return "other"
    }

    setPitch(pitch) {
        if (
            this.meta != null &&
            this.meta.layer != null &&
            !this.meta.layer.includes("Percussion")
        ) {
            pitch = Math.max(Note.lowestNote, pitch)
            pitch = Math.min(Note.highestNote, pitch)
        }

        this.pitch = pitch

        return this.pitch
    }

    getNoteColor() {
        if (!this.meta.layer) {
            return
        }

        const colors = Layer.getLayerColor(this.meta.layer)

        this.color = colors.defaultColor
        this.oppositeColor = colors.oppositeColor
    }

    isEqualTo(note: Note, includePitch): boolean {
        let equality =
            this.start == note.start &&
            this.duration == note.duration &&
            this.meta.layer == note.meta.layer &&
            this.meta.section == note.meta.section

        if (includePitch) {
            equality = equality && this.pitch == note.pitch
        }

        return equality
    }

    getEnd() {
        const noteEnd = Time.addTwoFractions(this.start, this.duration)

        return noteEnd
    }

    getEndFraction(): Fraction {
        return Time2.addTwoFractions(this.startFraction, this.durationFraction)
    }

    getBoundary(timestepRes: number = TIMESTEP_RES): NoteBoundary {
        const end = this.getEnd()

        return {
            start: {
                fraction: this.start,
                timesteps: Time.fractionToTimesteps(timestepRes, this.start),
            },
            duration: {
                fraction: this.duration,
                timesteps: Time.fractionToTimesteps(timestepRes, this.duration),
            },
            end: {
                fraction: end,
                timesteps: Time.fractionToTimesteps(timestepRes, end),
            },
        }
    }
}
