import { Effect } from "../../../general/classes/score/effect"
import Tempo from "../../../general/classes/score/tempo"
import Layer from "../../../general/classes/score/layer"
import { ImmutableNote } from "../../../general/classes/score/note"
import PercussionLayer from "../../../general/classes/score/percussionlayer"
import Score from "../../../general/classes/score/score"
import {
    AUTOMATION_TIMESTEP_RES,
    NOTE_QUANTIZATION_THRESHOLDS,
    TIMESTEP_RES,
} from "../../../general/constants/constants"
import { Time } from "../../../general/modules/time"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    AutomationCanvasContext,
    GridDimensions,
    MouseDownStart,
    GridDimensionsDictionary,
    GridCoordinates,
    CanvasType,
} from "../types"
import ScoreCanvas from "./score-canvas"
import { cloneDeep } from "lodash"
import { SRActionTypes } from "../states/score-rendering/score-rendering.actions"
import { Coordinates } from "../../../general/modules/event-handlers"

export default class AutomationCanvas extends ScoreCanvas {
    private automationStepsScale = {
        min: 0,
        max: 127,
    }

    private lastAutomation: GridCoordinates | undefined

    constructor(
        public context: AutomationCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)

        this.initListeners()
    }

    private initListeners() {
        this.mouseDown$.subscribe((event: Coordinates) => {
            this.actions.scoreRendering.manager.emitter$.next({
                type: SRActionTypes.startNoteManipulation,
                options: {
                    isUndoable: true,
                },
            })

            this.mouseDownHandler(event)

            if (event.shiftKey && this.mouseDownStart && this.lastAutomation) {
                this.drawAutomation(
                    this.lastAutomation,
                    this.mouseDownStart.start.grid
                )
            }

            this.lastAutomation = undefined
        })

        this.mouseMove$.subscribe(this.mouseMoveHandler.bind(this))
    }

    protected mouseUpHandler(event: Coordinates) {
        if (this.mouseDownStart) {
            this.lastAutomation = {
                timesteps: this.mouseDownStart.last.grid.timesteps,
                ysteps: this.mouseDownStart.last.grid.ysteps,
            }
        }

        super.mouseUpHandler(event)
    }

    private mouseMoveHandler(event: Coordinates) {
        const r = this.moveHandler(event, {
            type: "exact",
            thresholds: NOTE_QUANTIZATION_THRESHOLDS,
            quantize: false
        })

        if (!r) {
            return
        }

        this.drawAutomation(r.previous, r.now)
    }

    protected drawAutomation(previous: GridCoordinates, now: GridCoordinates) {
        const effect = this.queries.scoreRendering.selectedAutomation

        if (!effect) {
            return
        }

        const previousX = Math.round(
            Time.convertTimestepsToAnotherRes(
                previous.timesteps,
                this.noteRes,
                AUTOMATION_TIMESTEP_RES
            )
        )

        const nowX = Math.round(
            Time.convertTimestepsToAnotherRes(
                now.timesteps,
                this.noteRes,
                AUTOMATION_TIMESTEP_RES
            )
        )

        const previousY =
            effect.name === "high_frequency_cut"
                ? effect.max - previous.ysteps
                : previous.ysteps
        const nowY =
            effect.name === "high_frequency_cut"
                ? effect.max - now.ysteps
                : now.ysteps

        this.srEmitter$.next({
            type: SRActionTypes.setAutomationValue,
            data: {
                effect,
                timestepRange: [previousX, nowX],
                automationStepRange: [previousY, nowY],
            },
            options: {
                isUndoable: false,
            },
        })
    }

    protected getGridCoordinates(event: Coordinates): GridCoordinates {
        const result = this.getStepsForPixels({
            coordinates: event,
        })

        if (result.timesteps === undefined) {
            throw "AutomationCanvas.getGridCoordinates: timesteps is undefined"
        }

        const scaleLength =
            this.automationStepsScale.max - this.automationStepsScale.min

        return {
            timesteps: result.timesteps,
            ysteps:
                scaleLength -
                (event.y * scaleLength) / this.height +
                this.automationStepsScale.min,
        }
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        if (
            !scoreState.score ||
            !scoreState.selectedAutomation ||
            !scoreState.toggledLayer ||
            !this.shouldRenderCanvas(scoreState.renderingType)
        ) {
            return
        }

        const score: Score = scoreState.score

        const effect: Effect = scoreState.selectedAutomation
        this.automationStepsScale = {
            min: effect.min,
            max: effect.max,
        }

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: grid,

            // While the canvas is rendered using the user selected timestep res, the values in the Effect
            // object are in AUTOMATION_TIMESTEP_RES. So we'll need to convert scrollToTimestep and
            // getTimestepRange() to AUTOMATION_TIMESTEP_RES when rendering / drawing on the canvas
            noteRes: scoreState.userSelectedTimestepRes,
            nbOfYValues: effect.max - effect.min,
        })

        const layer: Layer = this.queries.scoreRendering.toggledLayer
        const lfc = effect.name === "low_frequency_cut"
        const hfc = effect.name === "high_frequency_cut"

        if (effect.name === "high_frequency_cut") {
            this.canvases["canvas"].style.zIndex = "-1"
            this.canvases["canvas-inverted"].style.zIndex = "0"
        } else {
            this.canvases["canvas"].style.zIndex = "0"
            this.canvases["canvas-inverted"].style.zIndex = "-1"
        }

        this.renderCanvas(effect, false)

        if (lfc) {
            this.renderCanvas(layer.effects.high_frequency_cut, true)
        } else if (hfc) {
            this.renderCanvas(layer.effects.low_frequency_cut, true)
        }
    }

    protected renderOverlay() {
        const ctx = this.getContext("canvas")

        ScoreCanvas.roundRect(
            ctx,
            3 - this.scrollXOffset,
            3,
            335,
            26,
            4,
            "rgba(255,255,255,0.2)"
        )
        ctx.fill()

        ScoreCanvas.drawText(
            ctx,
            170 - this.scrollXOffset,
            20,
            "Customize automation by clicking in the area below",
            85,
            "",
            "white"
        )
    }

    private renderCanvas(effect: Effect, secondaryGradient: boolean) {
        const ctx = this.getContext(
            effect.name === "high_frequency_cut" ? "canvas-inverted" : "canvas"
        )

        ctx.beginPath()

        const timestepRange = Time.convertTimestepsToAnotherRes(
            this.getTimestepRange(),
            this.noteRes,
            AUTOMATION_TIMESTEP_RES
        )

        const values = effect.values

        const scrollToTimestep = Time.convertTimestepsToAnotherRes(
            this.scrollToTimestep,
            this.noteRes,
            AUTOMATION_TIMESTEP_RES
        )

        let lastHeight: number | undefined = undefined

        for (
            let v = Math.floor(scrollToTimestep);
            v <= Math.ceil(scrollToTimestep + timestepRange);
            v++
        ) {
            const y = values[v]

            if (y === undefined) {
                continue
            }

            const ts = Time.convertTimestepsToAnotherRes(
                v,
                AUTOMATION_TIMESTEP_RES,
                this.noteRes
            )

            const x = ScoreCanvas.getPixelsForTimesteps({
                timesteps: ts,
                removeLastGutterPx: "none",
                grid: this.grid,
                timestepRes: this.noteRes,
            })

            const eventHeight =
                this.height -
                ((y - effect.min) / (effect.max - effect.min)) * this.height

            lastHeight = eventHeight

            ctx.lineTo(x - this.scrollXOffset, eventHeight)
        }

        if (lastHeight) {
            ctx.lineTo(this.width, lastHeight)
        }

        ctx.lineTo(this.width, this.height)
        ctx.lineTo(0, this.height)

        ctx.lineWidth = 0
        ctx.fillStyle = this.getEffectGradient(ctx, secondaryGradient, effect)

        ctx.stroke()
        ctx.fill("evenodd")

        ctx.closePath()
    }

    private getEffectGradient(
        ctx: CanvasRenderingContext2D,
        secondaryGradient: boolean,
        effect: Effect
    ): CanvasGradient {
        const gradient = ctx.createLinearGradient(0, 0, 0, 320)
        const type = secondaryGradient
            ? effect.name + "_transparent"
            : effect.name

        const primaryColor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--" + type + "-primary-color")
        const secondaryColor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--" + type + "-secondary-color")

        gradient.addColorStop(0, primaryColor)
        gradient.addColorStop(1, secondaryColor)

        return gradient
    }
}
