import { Misc } from "./misc"
import { KeyboardEventActions } from "../../client-only/score-rendering-engine"
import ScoreRenderingEngine from "../../client-only/score-rendering-engine/engine"
import { SRActionTypes } from "../../client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import {
    playerActions,
    playerQuery,
} from "../../client-only/general/classes/playerStateManagement"
import { IKeyboardEvents } from "../interfaces/score/general"

export module KeyboardHandler {
    export function keyboardActionIsAllowed(
        action: KeyboardEventActions,
        allowedActions: KeyboardEventActions[]
    ) {
        if (allowedActions.includes(KeyboardEventActions.NONE)) {
            return false
        } else if (allowedActions.includes(KeyboardEventActions.ALL)) {
            return true
        } else if (allowedActions.includes(action)) {
            return true
        }
    }

    export function resetPlayback(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[]
    ) {
        const correctKey = event.key.toLowerCase() === "enter"

        const actionAllowed = KeyboardHandler.keyboardActionIsAllowed(
            KeyboardEventActions.RESET_PLAYBACK,
            allowedActions
        )

        if (actionAllowed && correctKey && (event.ctrlKey || event.metaKey)) {
            event.preventDefault()

            const isPlaying = playerQuery.status == "playing"

            if (isPlaying) {
                playerActions.pause("KeyboardHandler.resetPlayback")
            }

            playerActions.setTimeElapsed("KeyboardHandler.resetPlayback", 0)

            if (isPlaying) {
                playerActions.play("KeyboardHandler.resetPlayback")
            }

            return KeyboardEventActions.RESET_PLAYBACK
        }

        return KeyboardEventActions.NONE
    }

    export function mute(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        const correctKey = event.key.toLowerCase() === "m"

        const actionAllowed = KeyboardHandler.keyboardActionIsAllowed(
            KeyboardEventActions.MUTE,
            allowedActions
        )

        if (actionAllowed && correctKey) {
            event.preventDefault()

            engine.keyboardMute()

            return KeyboardEventActions.MUTE
        }

        return KeyboardEventActions.NONE
    }

    export function solo(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        const correctKey = event.key.toLowerCase() === "s"

        const actionAllowed = KeyboardHandler.keyboardActionIsAllowed(
            KeyboardEventActions.SOLO,
            allowedActions
        )

        if (actionAllowed && correctKey) {
            event.preventDefault()

            engine.keyboardSolo()

            return KeyboardEventActions.SOLO
        }

        return KeyboardEventActions.NONE
    }

    export function duplicate(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        const correctKey =
            event.key.toLowerCase() === "d" && (event.ctrlKey || event.metaKey)

        const actionAllowed = KeyboardHandler.keyboardActionIsAllowed(
            KeyboardEventActions.DUPLICATE,
            allowedActions
        )

        if (actionAllowed && correctKey) {
            event.preventDefault()

            engine.keyboardDuplicate()

            return KeyboardEventActions.DUPLICATE
        }

        return KeyboardEventActions.NONE
    }

    export function arrowKeys(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        const correctKey =
            event.key === "ArrowUp" ||
            event.key === "ArrowDown" ||
            event.key === "ArrowLeft" ||
            event.key === "ArrowRight"

        const actionAllowed = KeyboardHandler.keyboardActionIsAllowed(
            KeyboardEventActions.MOVE,
            allowedActions
        )

        if (actionAllowed && correctKey) {
            event.preventDefault()

            engine.keyboardSelect(event)

            return KeyboardEventActions.MOVE
        }

        return KeyboardEventActions.NONE
    }

    export function zoomIn(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        if (
            KeyboardHandler.keyboardActionIsAllowed(
                KeyboardEventActions.INCREMENT_RESIZE_FACTOR,
                allowedActions
            ) &&
            (event.metaKey || event.ctrlKey) &&
            (event.key === "=" || event.key === "+")
        ) {
            event.preventDefault()

            const defaultResizeModifier = 8
            engine.zoomIn(defaultResizeModifier)

            return KeyboardEventActions.INCREMENT_RESIZE_FACTOR
        }

        return KeyboardEventActions.NONE
    }

    export function deleteSelectedData(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        if (
            KeyboardHandler.keyboardActionIsAllowed(
                KeyboardEventActions.DELETE,
                allowedActions
            ) &&
            (event.key === "Backspace" || event.key === "Delete")
        ) {
            event.preventDefault()

            engine.deleteSelectedData()

            return KeyboardEventActions.DELETE
        }

        return KeyboardEventActions.NONE
    }

    export function toggleCursorType(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        if (
            KeyboardHandler.keyboardActionIsAllowed(
                KeyboardEventActions.TOGGLE_CURSOR_TYPE,
                allowedActions
            ) &&
            (event.metaKey || event.ctrlKey) &&
            event.key === "e"
        ) {
            event.preventDefault()

            engine.toggleCursorType()

            return KeyboardEventActions.TOGGLE_CURSOR_TYPE
        }

        return KeyboardEventActions.NONE
    }

    export function zoomOut(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        if (
            KeyboardHandler.keyboardActionIsAllowed(
                KeyboardEventActions.DECREMENT_RESIZE_FACTOR,
                allowedActions
            ) &&
            (event.metaKey || event.ctrlKey) &&
            event.key === "-"
        ) {
            event.preventDefault()

            const defaultResizeModifier = 8

            engine.zoomOut(defaultResizeModifier)

            return KeyboardEventActions.DECREMENT_RESIZE_FACTOR
        }

        return KeyboardEventActions.NONE
    }

    export function copy(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        if (
            KeyboardHandler.keyboardActionIsAllowed(
                KeyboardEventActions.COPY,
                allowedActions
            ) &&
            (event.metaKey || event.ctrlKey) &&
            event.key.toLowerCase() === "c"
        ) {
            event.preventDefault()

            engine.copy()

            return KeyboardEventActions.COPY
        }

        return KeyboardEventActions.NONE
    }

    export function cut(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        if (
            KeyboardHandler.keyboardActionIsAllowed(
                KeyboardEventActions.CUT,
                allowedActions
            ) &&
            (event.metaKey || event.ctrlKey) &&
            event.key === "x"
        ) {
            event.preventDefault()

            engine.cut()

            return KeyboardEventActions.CUT
        }

        return KeyboardEventActions.NONE
    }

    export function paste(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        if (
            KeyboardHandler.keyboardActionIsAllowed(
                KeyboardEventActions.PASTE,
                allowedActions
            ) &&
            (event.metaKey || event.ctrlKey) &&
            event.key === "v"
        ) {
            event.preventDefault()

            engine.paste()

            return KeyboardEventActions.PASTE
        }

        return KeyboardEventActions.NONE
    }

    export function selectAll(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        if (
            KeyboardHandler.keyboardActionIsAllowed(
                KeyboardEventActions.SELECT_ALL,
                allowedActions
            ) &&
            (event.metaKey || event.ctrlKey) &&
            event.key === "a"
        ) {
            event.preventDefault()

            engine.selectAllNotes()

            return KeyboardEventActions.SELECT_ALL
        }

        return KeyboardEventActions.NONE
    }

    export function resetZoom(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        if (
            KeyboardHandler.keyboardActionIsAllowed(
                KeyboardEventActions.RESET_RESIZE_FACTOR,
                allowedActions
            ) &&
            (event.metaKey || event.ctrlKey) &&
            event.key === "0"
        ) {
            event.preventDefault()

            engine.resetZoom()

            return KeyboardEventActions.RESET_RESIZE_FACTOR
        }

        return KeyboardEventActions.NONE
    }

    export function undo(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        if (
            KeyboardHandler.keyboardActionIsAllowed(
                KeyboardEventActions.UNDO,
                allowedActions
            ) &&
            (event.metaKey || event.ctrlKey) &&
            !event.shiftKey &&
            event.key == "z"
        ) {
            event.preventDefault()

            engine.undo()

            return KeyboardEventActions.UNDO
        }

        return KeyboardEventActions.NONE
    }

    export function redo(
        event: KeyboardEvent,
        allowedActions: KeyboardEventActions[],
        engine: IKeyboardEvents
    ) {
        if (
            KeyboardHandler.keyboardActionIsAllowed(
                KeyboardEventActions.REDO,
                allowedActions
            ) &&
            (event.metaKey || event.ctrlKey) &&
            event.shiftKey &&
            event.key == "z"
        ) {
            event.preventDefault()

            engine.redo()

            return KeyboardEventActions.REDO
        }

        return KeyboardEventActions.NONE
    }
}
