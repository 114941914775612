export default class Patch {
    section:string
    instrument:string
    playing_style:string
    articulation:string
    granulationEngine:string

    constructor(section, instrument, playing_style, articulation, granulationEngine){
        this.section = section
        this.instrument = instrument
        this.playing_style = playing_style
        this.articulation = articulation
        this.granulationEngine = granulationEngine
    }

    getFullName() {
        return this.section + "." + this.instrument + "." + this.playing_style + "." + this.articulation
    }

    isEqualTo(patch:Patch) {
        if (Object.keys(this).length != Object.keys(patch).length) {
            return false
        }

        for (var elem of Object.keys(this)) {
            if (this[elem] != patch[elem]) {
                return false
            }
        }

        return true
    }

    static fromJSON(patch) {
        return Object.assign(new Patch("", "", "", "", ""), patch)
    }

    static fromString(input:string) {
        let section = input.split(".")[0]
        let instrument = input.split(".")[1]
        let ps = input.split(".")[2]
        let articulation = input.split(".")[3]

        return new Patch(section, instrument, ps, articulation, "")
    }

    sameInstrument(patch:Patch) {
        return this.section == patch.section && this.instrument == patch.instrument
    }
}