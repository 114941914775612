import { Note } from "../classes/score/note"
import { NotesObject } from "../classes/score/notesObject"
import Section from "../classes/score/section"
import { TemplateChord } from "../interfaces/score/templateScore"
import { LayersValue } from "../types/general"
import { TimeSignature } from "../types/score"
import { LOWEST_INTERVALS } from "../utils/composition-workflow.util"
import { KeySignatureModule } from "./keysignature.module"
import { Misc } from "./misc"
import { Time } from "./time"

export module VoiceLeading {
    /**
     * Generate a list of pitch lists from a chord progression using voice leading.
     * @param chords List of chords in the form of [duration, chord_name]. e.g [[1, 'C'], [1, 'A'], ...]
     * @param lowestNote Absolute MIDI pitch to determine base pitch class to count from.
     */
    export function generateVoiceLeading(
        chords: TemplateChord[],
        lowestNote: number,
        layer: LayersValue,
        sections: Section[],
        timeSignature: TimeSignature
    ): NotesObject {
        let lastChord: number[] = []
        const notes = new NotesObject()

        const OCTAVES = 3

        let start = "0"

        for (const c of chords) {
            const chord = c[1]
            const duration = c[0]

            const pitches = KeySignatureModule.getOptimalVoicedPitchesForChord(
                chord
            ).map(p => p % 24)

            const form = Misc.createArray(pitches.length).map((v, i) => i)

            const newChord = getBestVoice({
                last: lastChord,
                current: pitches,
                form,
                octaves: OCTAVES,
            })

            lastChord = newChord

            const noteInstances = newChord.map(p => {
                const pitch = p + lowestNote

                return new Note({
                    pitch,
                    start,
                    duration,
                    meta: {
                        section: 0,
                        layer,
                    },
                })
            })

            notes.addNotesToGroup(noteInstances, timeSignature, sections)

            start = Time.addTwoFractions(start, duration)
        }

        return notes
    }

    /**
     * Calculates the best inversion of a chord, given the last inversion, limits
     * and other form parameters.
     * @param last List of pitches of the last chord
     * @param current List of pitches of the current chord
     * @param form List of voice indices
     * @param offset Absolute MIDI pitch corresponding to pitch value 0
     * @param octaves Number of octaves to consider
     */
    export function getBestVoice({
        last,
        current,
        form,
        octaves,
    }: {
        last: number[]
        current: number[]
        form: number[]
        octaves: number
    }) {
        let chords = getAllInversions(current, form.length, octaves)

        // For now, we comment this line because there's no reason to filter chords
        // according to the defined heuristic, which was intended for the ME
        // let chords = filterChords(inversions, form, offset)

        if (last.length > 0) {
            // Get voice distances to last chord
            const distances = chords.map(ch => {
                const temp1 = [...last].sort((a, b) => a - b)
                const temp2 = [...ch].sort((a, b) => a - b)

                const distance = voiceDistance(temp1, temp2)

                return distance
            })

            const minDistance = Math.min(...distances)

            // Keep chords with minimum distance
            chords = chords.filter((item, i) => distances[i] === minDistance)
        }

        // Keep chords with most chord tones
        const lengths = chords.map(c => c.length)
        const maxLength = Math.max(...lengths)

        chords = chords.filter((c, i) => lengths[i] === maxLength)

        // Preference for lower voiced chords
        const mins = chords.map(c => Math.min(...c))
        const bestMin = Math.min(...mins)

        chords = chords.filter((c, i) => mins[i] === bestMin)

        // Return the most compact chords
        const ranges = chords.map(ch => Math.min(...ch) - Math.max(...ch))
        const chord = chords[ranges.indexOf(Math.max(...ranges))]

        return chord.sort((a, b) => a - b)
    }

    /**
        Computes and returns the minimum total distance in semitones between
        two lists of pitches. E.g [0,1,2,4], [1,3,5] = 2
    **/
    export function voiceDistance(A: number[], B: number[]): number {
        if (A.length > B.length) {
            const subsets = Misc.combinations(A, B.length)
            const d = subsets.map(subset =>
                subset.reduce(
                    (sum, pitch, index) => sum + Math.abs(pitch - B[index]),
                    0
                )
            )
            return Math.min(...d)
        } else if (A.length < B.length) {
            const subsets = Misc.combinations(B, A.length)
            const d = subsets.map(subset =>
                subset.reduce(
                    (sum, pitch, index) => sum + Math.abs(A[index] - pitch),
                    0
                )
            )
            return Math.min(...d)
        } else {
            return A.reduce(
                (sum, pitch, index) => sum + Math.abs(pitch - B[index]),
                0
            )
        }
    }

    /**
     * Return False if any of the intervals in the chord break the lowest interval
     * rules.
     */
    export function checkLimits(
        chord: number[],
        form: number[],
        offset: number
    ) {
        const sortedChord = [...chord].sort()

        const pitches = Misc.createArray(form.length).map(
            (v, i) => sortedChord[form[i]]
        )

        const intervals = Misc.createArray(pitches.length - 1).map(
            (v, i) => pitches[i + 1] - pitches[i]
        )

        for (let i = 0; i < intervals.length; i++) {
            const shouldContinue =
                intervals[i] < 12 &&
                pitches[i] < LOWEST_INTERVALS[intervals[i]] - offset

            if (shouldContinue) {
                return false
            }
        }

        return true
    }

    /**
     * Function that filters a list of chord inversions
     *
     * @param pitchList A list of pitch lists, one for each inversion of a chord across 2 octaves.
     * @param form Rhythmic form in nested lists split by beats where appropriate.
     * @param offset Absolute pitch value that corresponds to the lowest chord pitch value 0.
     *
     * @returns List of chords which passed the filters.
     */
    export function filterChords(
        pitchList: number[][],
        form: number[],
        offset: number
    ) {
        const filtered: number[][] = []

        for (const pitches of pitchList) {
            if (!checkLimits(pitches, form, offset)) {
                continue
            }

            filtered.push(pitches)
        }

        return filtered
    }

    export function getAllInversions(
        chord: number[],
        leadVoices: number,
        octaves: number
    ): number[][] {
        if (leadVoices / chord.length > octaves) {
            console.warn(
                "lead voices was truncated to match number of provided octaves"
            )
            leadVoices = octaves * chord.length
        }

        if (chord.length >= leadVoices) {
            /**
             * When we are trying to get the inversion for less lead voices
             * than we have pitches in the chord, we then have to consider
             * all possible subsets of that chord, and find all of the inversions
             * for those
             */

            const allChords = Misc.combinations(chord, leadVoices)
            let allInversions: number[][] = []

            for (const c of allChords) {
                allInversions = allInversions.concat(
                    getInversionsForChordWithoutRepetition(c, octaves)
                )
            }

            return allInversions
        }

        const extraPitches: number[][] = Misc.combinations(
            generateAllExtraPitches(leadVoices, chord),
            leadVoices - chord.length
        )

        let allInversions: number[][] = []

        for (const extra of extraPitches) {
            const newChord: number[] = [...chord].concat(extra)

            allInversions = allInversions.concat(
                getInversionsForChordWithoutRepetition(newChord, octaves)
            )
        }

        return allInversions
    }

    export function generateAllExtraPitches(
        leadVoices: number,
        chord: number[]
    ): number[] {
        if (leadVoices <= chord.length) {
            return chord
        }

        const numberOfOctaves = Math.ceil(
            (leadVoices - chord.length) / chord.length
        )
        const extraPitches: number[] = []

        for (let o = 1; o < numberOfOctaves + 1; o++) {
            for (const pitch of chord) {
                extraPitches.push(pitch + 12 * o)
            }
        }

        return extraPitches
    }

    export function createChordCombinationsWithRepeatedNotes(
        leadVoices: number,
        chord: number[],
        nbOfOctaves: number,
        inversion: number[]
    ): number[][] {
        const diff = leadVoices - chord.length

        const octaves = Misc.createArray(nbOfOctaves).splice(0, 1)
        let combinations: number[] = []

        octaves.forEach((value, index) => {
            const octave = index + 1

            combinations = combinations.concat(
                inversion.map(
                    (pitch: number) => (pitch + octave * 12) % (nbOfOctaves * 2)
                )
            )
        })

        return Misc.combinations(combinations, diff)
    }

    export function getPitchCombinationsForChord(
        chord: number[],
        octaves: number
    ) {
        const pitchCombinations: number[][] = []

        for (const pitch of chord) {
            const intermediate: number[] = []
            for (let o = 0; o < octaves; ++o) {
                intermediate.push((pitch + o * 12) % (octaves * 12))
            }

            pitchCombinations.push(intermediate)
        }

        return pitchCombinations
    }

    /**
     * Gets all possible voicing of this chord while considering the same pitches
     * accross different octaves
     *
     * E.g. for chord [1, 2, 3] and octaves 2, returns:
     * [
     *  [ 1, 2, 3 ],
     *  [ 1, 2, 15 ],
     *  [ 1, 14, 3 ],
     *  [ 1, 14, 15 ],
     *  [ 13, 2, 3 ],
     *  [ 13, 2, 15 ],
     *  [ 13, 14, 3 ],
     *  [ 13, 14, 15 ]
     * ]
     */
    export function getInversionsForChordWithoutRepetition(
        chord: number[],
        octaves: number
    ): number[][] {
        let pitchCombinations = getPitchCombinationsForChord(chord, octaves)

        if (pitchCombinations.length === 0) {
            return []
        }

        return Misc.combos(pitchCombinations)
    }
}
