import PercussionLayer from "../../../general/classes/score/percussionlayer"
import { Time } from "../../../general/modules/time"
import { HoveringTypeEnum } from "../../../general/types/general"
import { TimeSignature } from "../../../general/types/score"
import ScoreRenderingEngine from "../engine"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { SRActionTypes } from "../states/score-rendering/score-rendering.actions"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    ScoreCanvasContext,
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    CanvasType,
    GridDimensionsDictionary,
    PatternHorizontalScrollbarCanvasContext,
    Scrollbar,
} from "../types"
import ScoreCanvas from "./score-canvas"
import { cloneDeep } from "lodash"
import { Coordinates } from "../../../general/modules/event-handlers"
import {
    NOTE_QUANTIZATION_THRESHOLDS,
    PIANOROLL_GRID_SEPARATOR_TOP,
    SHOW_BAR_GUTTERS_THRESHOLD,
    SHOW_BEAT_GUTTERS_THRESHOLD,
    SHOW_TIMESTEP_GUTTERS_THRESHOLD,
    TIMESTEP_RES,
} from "../../../general/constants/constants"
import Section from "../../../general/classes/score/section"
import Score from "../../../general/classes/score/score"
import { ScoreManipulation } from "../../../general/modules/scoremanipulation"

interface Separator {
    margin: number
    barNumber?: number
    type: string
}

export default class PatternHorizontalScrollbarCanvas extends ScoreCanvas {
    constructor(
        public context: PatternHorizontalScrollbarCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)

        this.initListeners()
    }

    private getScrollbar(): Scrollbar {
        const scrollXOffset = this.patternScrollToTimestep * this.pxPerTimestep
        const width = this.getTimestepRange() * this.pxPerTimestep

        return {
            start: scrollXOffset,
            end: scrollXOffset + width,
        }
    }

    private isInScrollbarBoundariesX(scrollbar: Scrollbar, event: Coordinates) {
        return scrollbar.start <= event.x && event.x <= scrollbar.end
    }

    private isInScrollbarBoundariesY(event: Coordinates) {
        return event.y <= 10
    }

    protected scroll(event: WheelEvent) {
        return
    }

    protected getTimestepsForPixels(args: {
        pixels: number
    }): number | undefined {
        const result = this.getStepsForPixels({
            coordinates: {
                x: args.pixels,
                y: 0,
                shiftKey: false,
            },
        })

        return result.timesteps
    }

    protected initListeners() {
        this.mouseDown$.subscribe((event: Coordinates) => {
            const scrollbar = this.getScrollbar()

            if (this.isInScrollbarBoundariesY(event)) {
                if (!this.isInScrollbarBoundariesX(scrollbar, event)) {
                    this.srEmitter$.next({
                        type: SRActionTypes.setPatternScroll,
                        data: {
                            patternScrollToTimestep:
                                event.x / this.pxPerTimestep,
                        },
                    })
                }

                const now = {
                    scrollToTimestep: this.patternScrollToTimestep,
                    pixels: event,
                    grid: {
                        timesteps: event.x / this.pxPerTimestep,
                        ysteps: 0,
                    },
                }

                this._mouseDownStart = {
                    start: now,
                    last: cloneDeep(now),
                    mouseDownLocation: HoveringTypeEnum.CENTER,
                }
            } else if (this.context.allowTimestepClick) {
                this.handleTimestepClick(event)
            }
        })

        this.mouseMove$.subscribe((event: Coordinates) => {
            if (!this._mouseDownStart) {
                return
            }

            const newTimesteps =
                event.x / (this.width / (this.getPatternTimestepsLength() * 4))

            const patternScrollToTimestep =
                this.mouseDownStart.start.scrollToTimestep +
                newTimesteps -
                this.mouseDownStart.start.grid.timesteps

            this.srEmitter$.next({
                type: SRActionTypes.setPatternScroll,
                data: {
                    patternScrollToTimestep: patternScrollToTimestep,
                },
            })

            this.mouseDownStart.last = {
                scrollToTimestep: this.patternScrollToTimestep,
                pixels: event,
                grid: {
                    timesteps: newTimesteps,
                    ysteps: 0,
                },
            }
        })
    }

    private handleTimestepClick(event: Coordinates) {
        const start = this.patternScrollToTimestep
        const end = start + this.getPatternTimestepsLength()

        for (let t = 0; t <= end; t++) {
            const x =
                ScoreCanvas.getPixelsForTimesteps({
                    grid: this.grid,
                    timesteps: t,
                    timestepRes: this.noteRes,
                    removeLastGutterPx: "none",
                })

            if (x > event.x) {
                this.srEmitter$.next({
                    type: SRActionTypes.seek,
                    data: {
                        timesteps: Time.convertTimestepsToAnotherRes(
                            Math.max(0, t - 1) + start,
                            this.noteRes,
                            TIMESTEP_RES
                        )
                    },
                })

                break
            }
        }
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary,
        syncedCanvas?: ScoreCanvas
    ) {
        const shouldRenderCanvas = this.shouldRenderCanvas(
            scoreState.renderingType
        )

        if (
            !scoreState.score ||
            !scoreState.toggledLayer ||
            !scoreState.selectedPattern ||
            !shouldRenderCanvas ||
            !(
                this.queries.scoreRendering.toggledLayer instanceof
                PercussionLayer
            ) ||
            !syncedCanvas
        ) {
            return
        }

        this.syncedCanvas = syncedCanvas

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: grid,
            noteRes: scoreState.selectedPattern.noteRes,
            nbOfYValues: 0,
            computePxPerTimestep: (() => {
                return this.width / this.getPatternTimestepsLength()
            }).bind(this),
        })

        this.renderScrollbar()

        this.renderComputeSeparators()
    }

    private getPatternTimestepsLength() {
        const ts: TimeSignature =
            this.queries.scoreRendering.score.timeSignatures[0][1]

        const barLengthInTimesteps =
            this.queries.scoreRendering.selectedPattern.bars *
            Time.fractionToTimesteps(this.noteRes, ts[0] + "/" + ts[1])

        return barLengthInTimesteps
    }

    public getTimestepRange() {
        return this.syncedCanvas.getTimestepRange()
    }

    private renderScrollbar() {
        const ctx = this.getContext("canvas")

        const scrollbar = this.getScrollbar()
        const width = scrollbar.end - scrollbar.start

        if (width >= this.width) {
            return
        }

        ScoreCanvas.drawHorizontalLine(
            ctx,
            0,
            this.width,
            5,
            "rgba(255,255,255,0.1)"
        )

        ScoreCanvas.roundRect(
            ctx,
            scrollbar.start,
            3,
            width,
            5,
            2,
            "rgba(255,255,255,0.9)"
        )

        ctx.fill("evenodd")
    }

    private renderComputeSeparators() {
        const ctx = this.getContext("canvas")

        const measureRes = Time.fractionToTimesteps(
            this.noteRes,
            this.grid.timeSignature[0] + "/" + this.grid.timeSignature[1]
        )
        const beatRes = Time.fractionToTimesteps(
            this.noteRes,
            "1/" + this.grid.timeSignature[1]
        )

        const scrollToTimestep = Math.round(this.patternScrollToTimestep)
        const scrollToTimestepEnd = Math.round(
            this.patternScrollToTimestep + this.getPatternTimestepsLength()
        )

        for (let t = scrollToTimestep; t < scrollToTimestepEnd; t++) {
            this.computeSingleSeparator(
                ctx,
                t,
                measureRes,
                beatRes,
                this.patternScrollToTimestep
            )
        }
    }

    private computeSingleSeparator(
        ctx: CanvasRenderingContext2D,
        i: number,
        measureRes: number,
        beatRes: number,
        start: number
    ) {
        const score: Score | undefined = this.queries.scoreRendering.score

        if (score === undefined) {
            return
        }

        const marginIncrement = this.pxPerTimestep * i

        const isBar = i % measureRes == 0
        const isBeat = i % beatRes == 0

        const separator: Separator = {
            margin: marginIncrement,
            barNumber: Math.floor(i / measureRes),
            type: "",
        }

        const x =
            ScoreCanvas.getPixelsForTimesteps({
                grid: this.grid,
                timesteps: i,
                timestepRes: this.noteRes,
                removeLastGutterPx: "none",
            }) -
            ScoreCanvas.getPixelsForTimesteps({
                grid: this.grid,
                timesteps: start,
                timestepRes: this.noteRes,
                removeLastGutterPx: "none",
            })

        if (isBar) {
            separator.type = "bar"

            const sections = score.sections
            let selectedSection: Section | undefined

            for (const section of sections) {
                const bar = Time.timestepToFraction(
                    Time.barToTimestep(
                        separator.barNumber,
                        score.timeSignatures[0][1],
                        measureRes
                    ),
                    measureRes
                )

                if (Time.compareTwoFractions(section.start, bar) === "eq") {
                    selectedSection = section

                    break
                }
            }

            const drawNonSectionBars =
                !selectedSection &&
                separator.barNumber != null &&
                (this.resizeFactor >= SHOW_BAR_GUTTERS_THRESHOLD ||
                    sections.length === 1)

            const drawSectionBars = selectedSection !== undefined

            if (
                separator.barNumber &&
                (drawSectionBars || drawNonSectionBars)
            ) {
                ScoreCanvas.drawText(
                    ctx,
                    x,
                    24,
                    separator.barNumber + 1 + "",
                    PIANOROLL_GRID_SEPARATOR_TOP.text.fontSize,
                    "bold",
                    "white"
                )
            }
        } else if (
            (this.resizeFactor >= SHOW_BEAT_GUTTERS_THRESHOLD ||
                score.sections.length === 1) &&
            isBeat
        ) {
            separator.type = "beat"

            ScoreCanvas.drawVerticalLine(
                ctx,
                x,
                17,
                24,
                "rgba(255,255,255,0.3)"
            )
        }
    }
}
