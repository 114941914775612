import { Time } from "../../modules/time"
import { Note } from "./note"
import { ScoreManipulation } from "../../modules/scoremanipulation"
import { cloneDeep } from "lodash"
export default class NotesClipboard {
    operationType: string // "cut" or "copy"
    valuesType: string
    values: Array<Note>

    noteRegionStart
    noteRegionDuration
    noteRegionEnd

    constructor(operationType, valuesType, values) {
        this.operationType = operationType
        this.valuesType = valuesType
        this.values = []

        for (let v = 0; v < values.length; v++) {
            const newNote: Note = cloneDeep(values[v])

            const end = Time.addTwoFractions(newNote.start, newNote.duration)

            if (
                this.noteRegionStart == null ||
                Time.compareTwoFractions(newNote.start, this.noteRegionStart) ==
                    "lt"
            ) {
                this.noteRegionStart = newNote.start
            }

            if (
                this.noteRegionEnd == null ||
                Time.compareTwoFractions(end, this.noteRegionEnd) == "gt"
            ) {
                this.noteRegionEnd = end
            }

            this.values.push(newNote)

            this.values = ScoreManipulation.sortNotes(this.values) as Note[]
        }

        if (this.noteRegionStart != null && this.noteRegionEnd != null) {
            this.noteRegionDuration = Time.addTwoFractions(
                this.noteRegionEnd,
                this.noteRegionStart,
                true
            )
        }
    }
}
