import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
} from "@angular/core"
import { ModalService } from "../../../services/modal.service"
import { FolderService } from "../../../services/folder.service"
import { ApiService } from "../../../services/api.service"
import { InfluenceService } from "../../../services/influence.service"
import { Router } from "@angular/router"
import { NgxFileDropEntry, FileSystemFileEntry } from "ngx-file-drop"
import { AudioContext } from "standardized-audio-context" // this AudioContext is used to make copyToChannel work in Safari
import { HelperService } from "../../../helpers/helper.service"
import { AnimationLoopService } from "../../../services/animationloop.service"
import { PlayerService } from "../../../services/audio/player/player.service"
import { DesignService } from "../../../services/design.service"
import { GeneralService } from "@services/general/general.service"
import { AudioService } from "@services/audio/audio.service"

const { detect } = require("detect-browser")

@Component({
    selector: "influence-component",
    templateUrl: "influence.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [],
})
export class InfluenceComponent implements OnInit {
    public files: NgxFileDropEntry[] = []

    selectedKeySignature = ""
    uploadedFileMetadata
    uploadedFile

    acceptString = ""

    step = 1
    influenceType = "midi"
    influenceAudio

    loader = {
        loading: false,
        type: "analyse",
    }

    uploadReady = true
    audioNeedsTrimming = false

    fileUploadError = ""
    selectedInfluence = ""

    fileMetaData = {
        name: "",
        duration: 0,
        isPlaying: false,
        currentTime: 0,
    }

    seekPosition = 0

    dragAudioTrimAreaListener
    dragAudioTrimAreaMouseUpListener

    maximumAudioDuration = 330 // 5:30min
    minimumAudioDuration = 60 //  1:00min

    trimAudioArea = {
        startX: 0,
        endX: 0,
        lastX: 0,
        startTime: 0,
        endTime: this.minimumAudioDuration,
        duration: this.minimumAudioDuration,
        dragType: "middle",
        width: 0,
    }

    audioFileType = "mp3"

    audioEditingPercentage = 0

    showTrimmingWarning = false
    audioHasPartsBelowAmplitudeThreshold = false

    leftMaskWidth = 0
    rightMaskWidth = 0

    audioBuffer
    arrayBuffer
    audioCtx
    audioFile
    audioWorker: Worker

    amplitudeThreshold = -35

    @ViewChild("influenceAudioCanvas") influenceAudioCanvas: ElementRef
    @ViewChild("belowAmpThresholdCanvas") belowAmpThresholdCanvas: ElementRef
    @ViewChild("influenceAudioWaveform") influenceAudioWaveform: ElementRef
    @ViewChild("dragHandleStart") dragHandleStart: ElementRef
    @ViewChild("dragHandleEnd") dragHandleEnd: ElementRef

    numberOfWaves = 150
    displayBelowAmpThresholdCanvas = true
    dragHandleWidth = 6
    seekBarWidth = 2

    // the parameters we care about for the creation of the track
    parameters = {
        key: "auto",
        ensemble: "auto",
        duration: "auto",
        timeSignature: "auto",
        pacing: "auto",
        numberOfCompositions: "1",
    }

    constructor(
        protected generalService: GeneralService,
        protected ref: ChangeDetectorRef,
        protected influenceService: InfluenceService,
        protected folderService: FolderService,
        protected apiService: ApiService,
        protected router: Router,
        protected modalService: ModalService,
        protected audioService: AudioService,
        private helper: HelperService,
        private animationLoopService: AnimationLoopService,
        private playerService: PlayerService,
        private designService: DesignService
    ) {
        this.acceptString = this.getAcceptedFileTypes()
    }

    ngOnInit() {
        this.step = 1
        this.animationLoopService.addFunctionToLoop(
            "setSeekCursorPosition",
            this.setSeekCursorPosition.bind(this)
        )
        this.modalService.displayCreateModalMenus.next(true)
        this.dragHandleWidth = parseInt(
            this.designService.getCSSProperty("drag-handle-width")
        )
        this.seekBarWidth = parseInt(
            this.designService.getCSSProperty("seek-bar-width")
        )
    }

    public async dropped(files: NgxFileDropEntry[]) {
        this.files = files
        this.fileUploadError = ""
        let file, fileType
        let droppedFile = files[0]
        let fileEntry = droppedFile.fileEntry as FileSystemFileEntry

        if (this.files.length > 1 && this.files.length < 1) {
            this.fileUploadError = "You can only upload one file at a time."

            return
        }

        if (!fileEntry.isFile) {
            return (this.fileUploadError =
                "You can only upload a MIDI or Audio file.")
        }

        this.uploadedFile = fileEntry

        return this.influenceService
            .getFileFromFileEntry(fileEntry)
            .then(f => {
                fileType = droppedFile.fileEntry.name.split(".").pop()
                file = f

                if (!this.influenceService.isValidFileType(fileType)) {
                    return Promise.reject(
                        "You can only upload a MIDI or Audio file."
                    )
                }

                this.uploadedFileMetadata = file

                this.setLoader(true)
                this.step = 2
                this.fileMetaData.name = droppedFile.fileEntry.name
                this.influenceType = this.influenceService.isMIDIFile(fileType)
                    ? "midi"
                    : "audio"

                this.detectChanges()
            })

            .catch(err => {
                this.handleUploadError(err)
            })
    }

    public fileOver(event) {
        // Do nothing for now
    }

    public fileLeave(event) {
        // Do nothing for now
    }

    detectChanges() {
        this.ref.detectChanges()
    }

    createWithInfluence() {
        return this.modalService.createWithInfluence.getValue()
    }

    getKeySignatureOptions() {
        return this.influenceService.getKeySignatureOptions()
    }

    startCreateWithInfluenceMode() {
        this.apiService
            .authRequest(
                "/influence/createWithInfluenceMode",
                {},
                "primary",
                true
            )
            .then(result => {
                if (result.createWithInfluenceMode) {
                    this.influenceService.createWithInfluenceMode = true
                    this.folderService.setContentType(
                        "createWithInfluenceMode.subscribe",
                        "Influences",
                        "",
                        true
                    )
                    this.influenceService.createInCompositionFolder = ""
                } else {
                    return Promise.reject("You must first upload an influence")
                }
            })

            .catch(err => {
                return this.apiService.handleError(err)
            })
    }

    influenceFAQ() {
        window.open(
            "https://aiva.crisp.help/en/article/upload-influence-faq-1485kpl/",
            "_blank"
        )
    }

    audioInfluenceGuide() {
        window.open(
            "https://aiva.crisp.help/en/article/upload-audio-guidelines-lsozs3/",
            "_blank"
        )
    }

    midiInfluenceGuide() {
        window.open(
            "https://aiva.crisp.help/en/article/upload-midi-guidelines-z8bzub/",
            "_blank"
        )
    }

    getCreateType() {
        return this.modalService.createType
    }

    isMIDICreateType() {
        this.modalService.createType.includes("midi")
    }

    getDragAndDropText() {
        if (this.modalService.createType == "influence-audio") {
            return "Drag & Drop an Audio file here"
        }

        return "Drag & Drop a MIDI or Audio file here"
    }

    goBackToStepOne() {
        this.step = 1
        this.detectChanges()
    }

    goToStepThree() {
        var fileType = this.uploadedFileMetadata.name.split(".").pop()

        let promise = Promise.resolve(true) // by default we assume it is a MIDI file

        this.step = 3

        if (this.influenceType == "audio") {
            this.audioFileType = fileType.toLowerCase()

            promise = this.handleAudioUpload()
        }

        return promise
            .then(uploadReady => {
                let parameters = null
                this.setLoader(false)

                if (this.influenceType == "audio") {
                    parameters = {
                        duration: Math.round(this.fileMetaData.duration),
                    }
                }

                var promise = Promise.resolve()

                this.detectChanges()

                if (uploadReady) {
                    promise = this.influenceService.upload(
                        this.uploadedFileMetadata,
                        this.selectedKeySignature,
                        parameters
                    )
                }

                return promise
            })

            .catch(err => {
                this.handleUploadError(err)
            })
    }

    changeKeySignature(event) {
        this.selectedKeySignature = event.new.value
    }

    isMobile() {
        return this.generalService.isMobile()
    }

    /**
     * devides the raw audio buffer into a given number of divisions and creates an array
     * out of the computed average absolute amplitude value for each division
     * @param audioBuffer source of the raw audio data
     * @param samples number of divisions
     * @returns Array of avg abs amplitude value for each division
     */
    getFilteredDataFromAudioBuffer(audioBuffer, samples = 150) {
        const rawData = audioBuffer.getChannelData(0)
        const blockSize = Math.floor(rawData.length / samples) // Number of samples in each subdivision
        const filteredData = []

        for (let i = 0; i < samples; i++) {
            let blockStart = blockSize * i // the location of the first sample in the block
            let sum = 0

            for (let j = 0; j < blockSize; j++) {
                let absoluteAmpValue = Math.abs(rawData[blockStart + j])
                sum += absoluteAmpValue
            }

            filteredData.push(sum / blockSize) // get the average amplitude value for each block
        }

        return filteredData
    }

    /**
     * This function finds the largest data point in the array with Math.max(), takes its inverse with Math.pow(n, -1)
     * and multiplies each value in the array by that number.
     * This guarantees that the largest data point will be set to 1, and the rest of the data will scale proportionally.
     * @param filteredData Array
     * @returns
     */
    normalizeFilteredData(filteredData) {
        // the multiplier is what we multiply the biggest value out of filteredData by to get 1
        let multiplier = Math.pow(Math.max.apply(null, filteredData), -1)

        // make sure that really quiet songs don't get boosted by too much and actually appear to be silent
        if (multiplier > 100) {
            multiplier = 1
        }

        return filteredData.map(n => n * multiplier)
    }

    getPartsBelowAmplitudeThreshold(
        audioChunks,
        numberOfConsecutiveValues = 30
    ): Array<any> {
        let partsBelowAmpThreshold = []
        let tempConsecutiveIndices = []
        let correctionForAverage = 2
        numberOfConsecutiveValues -= correctionForAverage

        for (let i = 0; i < audioChunks.length; i++) {
            let chunk = audioChunks[i]
            let dbfs = 20 * Math.log10(chunk)

            if (dbfs < this.amplitudeThreshold) {
                tempConsecutiveIndices.push(i)
            } else {
                // save what has been found so far if the limit is reached
                if (
                    tempConsecutiveIndices.length >= numberOfConsecutiveValues
                ) {
                    for (
                        let tci = 0;
                        tci < tempConsecutiveIndices.length;
                        tci++
                    ) {
                        partsBelowAmpThreshold.push(tempConsecutiveIndices[tci])
                    }
                }

                tempConsecutiveIndices = [] // reset
            }
        }

        return partsBelowAmpThreshold
    }

    drawWaveForm(audioBuffer, numberOfLines = null) {
        const canvas = this.influenceAudioCanvas.nativeElement
        const dpi = window.devicePixelRatio || 1

        if (numberOfLines == null) {
            numberOfLines = canvas.clientWidth * dpi
        }

        let filteredData = this.getFilteredDataFromAudioBuffer(
            audioBuffer,
            numberOfLines
        )
        let normalizedData = this.normalizeFilteredData(filteredData)

        // Set up the canvas
        const padding = 20
        canvas.width = this.getAudioInfluenceTotalWidth() * dpi
        canvas.height = (canvas.offsetHeight + padding * 2) * dpi
        const ctx = canvas.getContext("2d")
        ctx.canvas.width = canvas.width
        ctx.canvas.height = canvas.height
        ctx.scale(dpi, dpi)
        ctx.translate(0, canvas.offsetHeight / 2 + padding) // Set Y = 0 to be in the middle of the canvas

        // draw the line segments
        const width = canvas.offsetWidth / normalizedData.length

        for (let i = 0; i < normalizedData.length; i++) {
            const x = width * i
            let height = normalizedData[i] * canvas.offsetHeight - padding

            if (height < 0) {
                height = 0
            } else if (height > canvas.offsetHeight / 2) {
                height = canvas.offsetHeight / 2
            }

            this.drawLineSegment(ctx, x, height)
        }
    }

    drawLineSegment(ctx, x, y, strokeStyle = "#fff") {
        if (y == 0) {
            y = 1 // make silent parts visible
        }

        ctx.lineWidth = 1 // how thick the line is
        ctx.strokeStyle = strokeStyle // what color our line is
        ctx.beginPath()
        ctx.moveTo(x, -y)
        ctx.lineTo(x, y)
        ctx.stroke()
    }

    findConsecutiveBooleanValues(
        array,
        booleanValueToSearchFor = true,
        numberOfConsecutiveValues = 30
    ) {
        let consecutiveIndices = []
        let tempConsecutiveIndices = []

        for (let i = 0; i < array.length; i++) {
            let value = array[i]

            if (value === booleanValueToSearchFor) {
                tempConsecutiveIndices.push(i) // add to list
            } else {
                // save what has been found so far if the limit is reached
                if (
                    tempConsecutiveIndices.length >= numberOfConsecutiveValues
                ) {
                    for (let index of tempConsecutiveIndices) {
                        consecutiveIndices.push(index)
                    }
                }

                tempConsecutiveIndices = [] // reset
            }
        }

        return consecutiveIndices
    }

    drawBelowAmpThresholdAreas(audioBuffer, numberOfLines = null) {
        this.audioHasPartsBelowAmplitudeThreshold = false

        const duration = audioBuffer.duration // duration in seconds
        const filteredData = this.getFilteredDataFromAudioBuffer(
            audioBuffer,
            Math.round(duration)
        ) // get one array entry per second of the audio
        const partsBelowAmpThreshold =
            this.getPartsBelowAmplitudeThreshold(filteredData)
        const canvas = this.belowAmpThresholdCanvas.nativeElement
        const dpi = window.devicePixelRatio || 1

        if (partsBelowAmpThreshold.length == 0) {
            return
        }

        this.audioHasPartsBelowAmplitudeThreshold = true

        if (numberOfLines == null) {
            numberOfLines = canvas.clientWidth * dpi
        }

        // Set up the canvas
        canvas.width = this.getAudioInfluenceTotalWidth() * dpi
        canvas.height = canvas.offsetHeight * dpi
        const ctx = canvas.getContext("2d")
        ctx.canvas.width = canvas.width
        ctx.canvas.height = canvas.height
        ctx.scale(dpi, dpi)

        // draw the line segments
        const pxCorrection = 1 // without it the area is not smoothed out
        const width = canvas.width / filteredData.length

        for (let i = 0; i < partsBelowAmpThreshold.length; i++) {
            const x = width * partsBelowAmpThreshold[i] + pxCorrection
            const y = canvas.offsetHeight

            ctx.lineWidth = width + pxCorrection // how thick the line is
            ctx.strokeStyle = "#f00" // what color our line is
            ctx.beginPath()
            ctx.moveTo(x, 0)
            ctx.lineTo(x, y)
            ctx.stroke()
        }
    }

    getAcceptedFileTypes() {
        let validInfluenceSourceTypes =
            this.influenceService.getValidInfluenceSourceTypes()
        let acceptString = "." + validInfluenceSourceTypes.join(",.")

        acceptString += "," + acceptString.toUpperCase()

        return acceptString
    }

    play() {
        if (this.influenceAudio != null && this.influenceAudio.src != null) {
            this.influenceAudio.play()
            this.fileMetaData.isPlaying = true
        }
    }

    pause() {
        if (this.influenceAudio != null && this.influenceAudio.src != null) {
            this.influenceAudio.pause()
            this.fileMetaData.isPlaying = false
        }
    }

    seek(event) {
        let x = event.offsetX + this.seekBarWidth

        if (x == null) {
            return
        }

        if (this.influenceAudio != null && this.influenceAudioCanvas != null) {
            let currentTime = this.getTimeFromX(x)

            this.influenceAudio.currentTime = currentTime

            setTimeout(() => {
                this.setSeekCursorPosition(false)
            }, 0)
        }
    }

    setSeekCursorPosition(onlyWhenPlaying = true): Promise<any> {
        if (
            !this.influenceAudio ||
            (this.fileMetaData.isPlaying != true && onlyWhenPlaying == true)
        ) {
            return
        }

        this.seekPosition = this.getSeekCursorPosition()

        return Promise.resolve()
    }

    getSeekCursorPosition() {
        let offset = 0

        if (this.influenceAudio != null && this.influenceAudioCanvas != null) {
            let totalDuration = this.influenceAudio.duration
            let currentTime = this.influenceAudio.currentTime

            let percentage = (currentTime / totalDuration) * 100

            offset = percentage

            if (currentTime >= totalDuration) {
                this.fileMetaData.isPlaying = false
                offset = 0
            }
        }

        return offset
    }

    /**
     * returns the time of the track depending on the x position inside the waveform canvas
     * @param x number that defines where in the canvas the mouse is moving
     * @param correction time correction
     * @returns number the time that represents the x value
     */
    getTimeFromX(x = 0, includeDragHandles = false) {
        let time = 0

        if (this.influenceAudio != null && this.influenceAudioCanvas != null) {
            let totalWidth =
                this.getAudioInfluenceTotalWidth(includeDragHandles)
            let totalDuration = this.influenceAudio.duration

            let percentage = x / totalWidth

            time = totalDuration * percentage

            if (time < 0) {
                time = 0
            }
        }

        return time
    }

    getXFromTime(time, includeDragHandles = false) {
        let x = 0

        if (this.influenceAudio != null && this.influenceAudioCanvas != null) {
            let totalWidth =
                this.getAudioInfluenceTotalWidth(includeDragHandles)
            let totalDuration = this.influenceAudio.duration

            let percentage = time / totalDuration

            x = totalWidth * percentage
        }

        return x
    }

    getTrimAudioAreaWidth(totalDuration, includeDragHandles = true) {
        return (
            (this.trimAudioArea.duration / totalDuration) *
            this.getAudioInfluenceTotalWidth(includeDragHandles)
        )
    }

    getMaximumAudioDuration() {
        return this.maximumAudioDuration
    }

    getMinimumAudioDuration() {
        return this.minimumAudioDuration
    }

    startDraggingTrimArea(event, type = "middle") {
        event.stopImmediatePropagation()

        this.trimAudioArea.dragType = type
        this.trimAudioArea.startX = this.getXFromTime(
            this.trimAudioArea.startTime,
            true
        )
        this.trimAudioArea.endX = this.getXFromTime(
            this.trimAudioArea.endTime,
            true
        )
        this.trimAudioArea.lastX = event.x

        if (this.dragAudioTrimAreaListener != null) {
            window.document.removeEventListener(
                "mousemove",
                this.dragAudioTrimAreaListener
            )
        }

        if (this.dragAudioTrimAreaMouseUpListener != null) {
            window.document.removeEventListener(
                "mouseup",
                this.dragAudioTrimAreaMouseUpListener
            )
        }

        this.dragAudioTrimAreaListener =
            this.continueDraggingTrimAudioArea.bind(this)
        this.dragAudioTrimAreaMouseUpListener =
            this.stopDraggingTrimAudioArea.bind(this)

        window.document.addEventListener(
            "mousemove",
            this.dragAudioTrimAreaListener
        )
        window.document.addEventListener(
            "mouseup",
            this.dragAudioTrimAreaMouseUpListener
        )
    }

    continueDraggingTrimAudioArea(event) {
        if (event == null) {
            this.stopDraggingTrimAudioArea(event)

            return
        }

        let type = this.trimAudioArea.dragType

        let totalDuration = this.influenceAudio.duration
        let totalWidth = this.getAudioInfluenceTotalWidth(true)
        let difference = event.x - this.trimAudioArea.lastX

        if (type == "middle") {
            let newStartX = this.trimAudioArea.startX + difference

            if (newStartX + this.trimAudioArea.width > totalWidth) {
                newStartX = totalWidth - this.trimAudioArea.width
            }

            if (newStartX < 0) {
                newStartX = 0
            }

            this.trimAudioArea.startX = newStartX
            this.trimAudioArea.startTime =
                (this.trimAudioArea.startX / totalWidth) * totalDuration
            this.trimAudioArea.endTime =
                this.trimAudioArea.startTime + this.trimAudioArea.duration
            this.trimAudioArea.lastX = event.x
        } else if (type == "start") {
            this.showTrimmingWarning = false

            let startX = this.trimAudioArea.startX + difference
            let startTime = this.getTimeFromX(startX, true)

            if (startX <= 0) {
                startX = 0
                startTime = 0
            }

            if (
                this.trimAudioArea.endTime - startTime >=
                this.getMaximumAudioDuration()
            ) {
                startTime =
                    this.trimAudioArea.endTime - this.getMaximumAudioDuration()
                startX = this.getXFromTime(startTime, true)
                this.showTrimmingWarning = true
            } else if (
                this.trimAudioArea.endTime - startTime <=
                this.getMinimumAudioDuration()
            ) {
                startTime =
                    this.trimAudioArea.endTime - this.getMinimumAudioDuration()
                startX = this.getXFromTime(startTime, true)
                this.showTrimmingWarning = true
            } else {
                this.trimAudioArea.lastX = event.x
            }

            let duration = this.trimAudioArea.endTime - startTime

            this.trimAudioArea.startX = startX
            this.trimAudioArea.startTime = startTime
            this.trimAudioArea.duration = duration
            this.trimAudioArea.startX = this.trimAudioArea.startX
            this.trimAudioArea.width = this.getTrimAudioAreaWidth(
                this.fileMetaData.duration
            )
        } else if (type == "end") {
            this.showTrimmingWarning = false

            let endX = this.trimAudioArea.endX + difference
            let endTime = this.trimAudioArea.endTime

            endTime = this.getTimeFromX(endX, true)

            if (endTime >= totalDuration) {
                endTime = totalDuration
            }

            if (
                endTime - this.trimAudioArea.startTime >=
                this.getMaximumAudioDuration()
            ) {
                endTime =
                    this.trimAudioArea.startTime +
                    this.getMaximumAudioDuration()
                endX = this.getXFromTime(endTime, true)
                this.showTrimmingWarning = true
            } else if (
                endTime - this.trimAudioArea.startTime <=
                this.getMinimumAudioDuration()
            ) {
                endTime =
                    this.trimAudioArea.startTime +
                    this.getMinimumAudioDuration()
                endX = this.getXFromTime(endTime, true)
                this.showTrimmingWarning = true
            } else {
                this.trimAudioArea.lastX = event.x
            }

            let duration = endTime - this.trimAudioArea.startTime

            this.trimAudioArea.endX = endX
            this.trimAudioArea.endTime = endTime
            this.trimAudioArea.duration = duration
            this.trimAudioArea.width = this.getTrimAudioAreaWidth(
                this.fileMetaData.duration
            )
        }

        this.computeMaskWidth()

        setTimeout(() => {
            this.computeMaskWidth() // this makes sure that the masks will be computed correctly even when dragging very fast
        }, 0)

        event.stopPropagation()

        this.detectChanges()
    }

    stopDraggingTrimAudioArea(event) {
        event.stopPropagation()

        this.showTrimmingWarning = false

        window.document.removeEventListener(
            "mousemove",
            this.dragAudioTrimAreaListener
        )
        window.document.removeEventListener(
            "mouseup",
            this.dragAudioTrimAreaMouseUpListener
        )
    }

    resetTrimAudioArea(resetTime = false) {
        let start = resetTime ? 0 : this.trimAudioArea.startTime
        let end = resetTime
            ? this.getMinimumAudioDuration()
            : this.trimAudioArea.endTime
        let width = resetTime ? 0 : this.trimAudioArea.width

        this.trimAudioArea = {
            startX: 0,
            endX: this.trimAudioArea.width,
            lastX: 0,
            startTime: start,
            endTime: end,
            duration: end - start,
            dragType: "middle",
            width: width,
        }
    }

    cancelAudioTrimming() {
        if (this.influenceAudio != null && this.influenceAudio.src != null) {
            this.influenceAudio.pause()
            this.influenceAudio = null
            this.step = 1
            this.fileMetaData.isPlaying = false
            this.trimAudioArea.width = 0
            this.trimAudioArea.startX = 0
            this.audioBuffer = null
            this.arrayBuffer = null
            this.audioCtx = null
            this.resetTrimAudioArea(true)
            this.modalService.displayCreateModalMenus.next(true)
            this.resetLoader()
            this.seekPosition = 0
            this.audioHasPartsBelowAmplitudeThreshold = false
            this.detectChanges()
        }
    }

    getFormattedTime(seconds) {
        let formattedTime = new Date(seconds * 1000).toISOString().substr(14, 5)

        if (seconds > 3600) {
            formattedTime =
                new Date(seconds * 1000).toISOString().substr(11, 8) + " h"
        }

        return formattedTime
    }

    getAudioMetaData(audioElement = this.influenceAudio) {
        return new Promise((resolve, reject) => {
            audioElement.onloadedmetadata = () => {
                resolve(audioElement)
            }
        })
    }

    computeAudioCanvas(audioFile, audioCtx): Promise<boolean> {
        return this.decodeAudioBuffer(audioFile, audioCtx).then(audioBuf => {
            this.audioBuffer = audioBuf

            this.numberOfWaves = this.getNumberOfWaves()
            this.drawWaveForm(this.audioBuffer, this.numberOfWaves)
            this.drawBelowAmpThresholdAreas(
                this.audioBuffer,
                this.numberOfWaves
            )
            this.setLoader(false)
            this.computeMaskWidth()

            return Promise.resolve(false) // don't upload
        })
    }

    decodeAudioBuffer(audioFile, audioCtx) {
        return audioFile.arrayBuffer().then(arrayBuf => {
            this.arrayBuffer = arrayBuf

            return audioCtx.decodeAudioData(
                this.arrayBuffer,
                audioBuf => {
                    return Promise.resolve(audioBuf)
                },
                err => {
                    return Promise.reject("Error while decoding audio file")
                }
            )
        })
    }

    handleAudioUpload() {
        this.setLoader(true, "analyse")
        this.modalService.displayCreateModalMenus.next(false)
        this.audioNeedsTrimming = false

        return Promise.all([
            AudioService.initInfluenceAudio(this.uploadedFileMetadata),
            AudioService.initInfluenceAudio(this.uploadedFileMetadata),
        ])
            .then(audioElements => {
                this.influenceAudio = audioElements[0]

                var browser = detect()

                if (browser.name == "safari") {
                    this.audioCtx = new AudioContext({})
                } else {
                    this.audioCtx = new AudioContext({
                        sampleRate: 44100,
                    })
                }

                this.audioCtx.createMediaElementSource(audioElements[1])

                return this.getAudioMetaData()
            })

            .then(() => {
                let totalDuration = this.influenceAudio.duration

                this.fileMetaData.duration = totalDuration

                if (totalDuration > 20 * 60) {
                    return Promise.reject(
                        "Your audio file needs to be less than 20 minutes longer"
                    )
                }

                if (totalDuration < 60) {
                    return Promise.reject(
                        "Your audio file needs to be at least 60 seconds long"
                    )
                }

                this.trimAudioArea.endTime = Math.min(
                    this.fileMetaData.duration,
                    this.getMaximumAudioDuration()
                )
                this.trimAudioArea.duration = this.trimAudioArea.endTime
                this.trimAudioArea.width =
                    this.getTrimAudioAreaWidth(totalDuration)

                // Here we have the following options...
                // 1   the file has valid duration and is mp3 -> just upload
                // 2   the file has valid duration and is wav -> encode and upload
                // 3   the file is longer than allowed and is either mp3 or something else -> show audio trimming tool

                // option 3
                if (
                    Math.round(totalDuration) > this.getMinimumAudioDuration()
                ) {
                    this.audioNeedsTrimming = true
                    this.modalService.displayCreateModalMenus.next(false)
                    this.playerService.pause()

                    return this.computeAudioCanvas(
                        this.uploadedFileMetadata,
                        this.audioCtx
                    )
                }

                // option 2
                if (this.audioFileType != "mp3") {
                    return this.decodeAudioBuffer(
                        this.uploadedFileMetadata,
                        this.audioCtx
                    ).then(buf => {
                        this.audioBuffer = buf

                        return this.encodeAndUpload(false)
                    })
                }

                // option 1
                return Promise.resolve(true) // upload file
            })
    }

    showTrimmingAudioEditor() {
        return this.loader.loading == false && this.audioNeedsTrimming == true
    }

    handleUploadError(err) {
        if (typeof err != "string") {
            err =
                "There was an error while trying to upload your file. Please try again later or contact the support."
        }

        this.step = 1
        this.fileUploadError =
            err ||
            "There was an error while trying to upload your file. Please try again later or contact the support."
        this.influenceType = "midi"
        this.uploadReady = true
        this.audioNeedsTrimming = false
        this.fileMetaData.duration = 0
        this.modalService.displayCreateModalMenus.next(true)
        this.resetLoader()
        this.detectChanges()
    }

    async encodeAndUpload(trim = true) {
        let progressInterval = null

        this.audioEditingPercentage = 10
        this.setLoader(true, "trimming")

        this.pause()

        try {
            if (trim == false) {
                return Promise.resolve(this.audioBuffer)
            }

            const trimmedBuffer = await this.influenceService.trimAudioBuffer(
                this.audioBuffer,
                this.audioCtx,
                this.trimAudioArea.startTime,
                this.trimAudioArea.endTime
            )

            progressInterval = setInterval(() => {
                if (this.audioEditingPercentage >= 90) {
                    clearInterval(progressInterval)
                } else {
                    this.audioEditingPercentage += 15

                    this.detectChanges()
                }
            }, 3000)

            const value = await this.encodeAudioBufferAndUploadMP3(
                trimmedBuffer,
                new Worker("./assets/lib/audio.worker.js")
            )

            if (progressInterval != null) {
                clearInterval(progressInterval)
            }
            this.audioEditingPercentage = 100
            this.step = 1
            this.influenceType = "midi"
            this.uploadReady = true
            this.audioNeedsTrimming = false
            this.fileMetaData.duration = 0
            this.modalService.displayCreateModalMenus.next(true)
            this.resetLoader()

            this.detectChanges()

            return Promise.resolve(false)
        } catch (err) {
            this.handleUploadError(err)

            return Promise.resolve(false)
        }
    }

    encodeAudioBufferAndUploadMP3(audioBuffer, worker: Worker) {
        return new Promise((resolve, reject) => {
            let wavObject = this.influenceService.audioBufferToWavBlob(
                audioBuffer,
                audioBuffer.length
            )

            if (!wavObject || !wavObject.left) {
                return reject("Error while encoding MP3")
            }

            let left = wavObject.left
            let right = wavObject.right != null ? wavObject.right : left // only stereo has right channel as well

            worker.onmessage = event => {
                if (event.data.res == "end" && event.data.mp3Buffer) {
                    let mp3Blob = new Blob([event.data.mp3Buffer], {
                        type: "audio/mpeg",
                    })
                    let mp3File = this.influenceService.blobToFile(
                        mp3Blob,
                        this.fileMetaData.name.replace(".wav", ".mp3")
                    )

                    let trimmedAudioElement = new Audio(
                        window.URL.createObjectURL(mp3Blob)
                    )

                    return this.getAudioMetaData(trimmedAudioElement)
                        .then(() => {
                            this.audioEditingPercentage = 100

                            return this.influenceService.upload(
                                mp3File,
                                this.selectedKeySignature,
                                {
                                    duration: Math.round(
                                        trimmedAudioElement.duration
                                    ),
                                }
                            )
                        })

                        .then(() => {
                            return resolve(true)
                        })

                        .catch(err => {
                            return reject(err)
                        })
                }
            }

            worker.onerror = error => {
                return reject("Error while encoding MP3")
            }

            if (audioBuffer.channels == 2) {
                worker.postMessage(
                    {
                        channels: wavObject.channels,
                        sampleRate: wavObject.sampleRate,
                        left: left,
                        right: right,
                        mode: 0,
                    },
                    [left, right]
                )
            } else {
                worker.postMessage(
                    {
                        channels: wavObject.channels,
                        sampleRate: wavObject.sampleRate,
                        left: left,
                        right: right,
                        mode: 0,
                    },
                    [left]
                )
            }
        })
    }

    downloadBlob(blob, fileName) {
        const a = document.createElement("a")
        document.body.appendChild(a)
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()

        setTimeout(() => {
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
        }, 0)
    }

    setLoader(loading, type = null) {
        this.loader = {
            loading: loading == true,
            type: type,
        }
    }

    resetLoader() {
        this.setLoader(false, null)
    }

    getAudioInfluenceTotalWidth(includeDragHandles = false) {
        if (this.influenceAudioCanvas == null) {
            return 600
        }

        let canvasElem = this.influenceAudioCanvas.nativeElement
        let totalWidth = parseFloat(
            window.getComputedStyle(canvasElem, null).width
        )

        if (includeDragHandles == true) {
            totalWidth += 2 * this.dragHandleWidth
        }

        return totalWidth
    }

    togglePlayback() {
        if (this.fileMetaData.isPlaying == true) {
            this.pause()
        } else {
            this.play()
        }
    }

    getNumberOfWaves() {
        let numberOfWaves = 150

        if (this.influenceAudioCanvas != null) {
            numberOfWaves = Math.round(this.getAudioInfluenceTotalWidth() / 4)
        }

        return numberOfWaves
    }

    updateTrimAudioAreaOnResize() {
        if (
            this.influenceAudio == null ||
            this.influenceAudio.duration == null
        ) {
            return
        }

        this.trimAudioArea.startX = this.getXFromTime(
            this.trimAudioArea.startTime,
            true
        )
        this.trimAudioArea.endX = this.getXFromTime(
            this.trimAudioArea.endTime,
            true
        )
        this.trimAudioArea.width = this.getTrimAudioAreaWidth(
            this.influenceAudio.duration
        )

        setTimeout(() => {
            this.computeMaskWidth()
        }, 0)
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.updateTrimAudioAreaOnResize()
    }

    computeMaskWidth() {
        let leftMaskWidth = this.trimAudioArea.startX + this.dragHandleWidth
        let rightMaskWidth =
            this.getAudioInfluenceTotalWidth(true) -
            this.trimAudioArea.startX -
            this.trimAudioArea.width

        if (leftMaskWidth < 0.5) {
            leftMaskWidth = 0
        }

        if (rightMaskWidth < 0.5) {
            rightMaskWidth = 0
        }

        this.leftMaskWidth = leftMaskWidth
        this.rightMaskWidth = rightMaskWidth
    }

    // getMaskWidth(){
    //     // right
    //     let dragHandleX = this.dragHandleEnd.nativeElement.getBoundingClientRect().x + this.dragHandleEnd.nativeElement.getBoundingClientRect().width
    //     let influenceAudioWaveformX = this.influenceAudioWaveform.nativeElement.getBoundingClientRect().x + this.influenceAudioWaveform.nativeElement.getBoundingClientRect().width
    //     this.rightMaskWidth = influenceAudioWaveformX - dragHandleX
    //     // left
    //     this.leftMaskWidth = this.dragHandleStart.nativeElement.getBoundingClientRect().x - this.influenceAudioWaveform.nativeElement.getBoundingClientRect().x
    // }

    ngOnDestroy() {
        this.cancelAudioTrimming()
        this.animationLoopService.deleteFunctionFromLoop(
            "setSeekCursorPosition"
        )
        this.modalService.displayCreateModalMenus.next(true)
    }
}
