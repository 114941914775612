import { map, pairwise, startWith, takeUntil } from "rxjs";

export module SubscriptionHelpers {
    /**
     * Returns both the previous and current value of an observable
     * @param observable
     * @param higherOrderFunction
     */
    export function subscribeWithPrevious(observable, higherOrderFunction, destroy$) {
        observable
            .pipe(
                startWith(undefined),
                pairwise(),
                map(([previous, current]) => ({ previous, current })),
                takeUntil(destroy$)
            )
            .subscribe(higherOrderFunction)
    }
}