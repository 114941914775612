import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
} from "@angular/core"
import { GeneralService } from "@services/general/general.service"

@Component({
    selector: "search",
    templateUrl: "./search.component.html",
    styleUrls: ["search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {
    @Input() searchDefault: string = "Search"
    @Input() width: string = "300px"
    @Output() searchCommand: EventEmitter<string> = new EventEmitter()

    public search: string = ""

    constructor(private general: GeneralService) {}

    ngOnInit(): void {}

    public deviceSpecificClass() {
        return this.general.getDeviceSpecificClass()
    }

    public clearSearch() {
        this.search = ""

        return this.searchCommand.emit(this.search)
    }

    public startSearch() {
        this.searchCommand.emit(this.search)
    }
}
