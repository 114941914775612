import TrackBus from "./trackbus"
import { Time } from "../../modules/time"
import {
    PercussionOnset,
    TemplateChannel,
} from "../../interfaces/score/templateScore"
import { cloneDeep } from "lodash"
import { v4 as uuiv4 } from "uuid"
import { FractionString } from "../../types/score"
import { Misc } from "../../modules/misc"
import { isEqual } from "lodash"
export default class Channel {
    name: string
    pitches: Array<number>
    onsets: Array<PercussionOnset>
    mute: boolean
    solo: boolean
    trackBus: TrackBus
    id: string

    constructor(
        name: string,
        pitches: Array<number>,
        onsets: Array<any>,
        mute: boolean,
        solo: boolean,
        trackBus: TrackBus
    ) {
        this.id = uuiv4()
        this.name = name
        this.pitches = pitches
        this.onsets = onsets
        this.mute = mute
        this.solo = solo
        this.trackBus = trackBus
    }

    sort() {
        this.onsets.sort((a: PercussionOnset, b: PercussionOnset) => {
            return Time.compareTwoFractions(a.start, b.start) === "lt" ? -1 : 1
        })
    }

    isEqual(other: Channel): boolean {
        if (!other.trackBus || other.trackBus.name !== this.trackBus.name) {
            return false
        }

        this.pitches.sort()
        other.pitches.sort()

        const pitchesAreEqual = isEqual(this.pitches, other.pitches)

        if (!pitchesAreEqual) {
            return false
        }

        const onsetsAreEqual = isEqual(
            this.onsets.map(o => Time.simplifyFractionFromString(o.start)),
            other.onsets.map(o => Time.simplifyFractionFromString(o.start))
        )

        return onsetsAreEqual
    }

    /**
     * Add an onset to a channel and returns whether it was added, deleted or no
     * modification was made
     * @param onset
     * @param strategy
     */
    addOnset(
        onset: FractionString,
        strategy: "toggleOn" | "toggleOff" | "inverse"
    ): "added" | "deleted" | "none" {
        const index = this.onsets.findIndex(
            o => Time.compareTwoFractions(o.start, onset) === "eq"
        )

        if (index === -1 && strategy !== "toggleOff") {
            this.onsets.push({ start: onset })
            this.sort()

            return "added"
        } else if (index !== -1 && strategy !== "toggleOn") {
            this.onsets.splice(index, 1)

            return "deleted"
        }

        return "none"
    }

    decode(): TemplateChannel {
        return {
            name: this.name,
            pitches: this.pitches,
            onsets: this.onsets,
            mute: this.mute,
            solo: this.solo,
            track: this.trackBus.id,
        }
    }
    copy(): Channel {
        const newChannel: Channel = cloneDeep(this)
        newChannel.id = uuiv4()

        return newChannel
    }

    removeDuplicateOnsets() {
        for (var o = this.onsets.length - 1; o >= 0; o--) {
            var onset = this.onsets[o]

            for (var p = o - 1; p >= 0; p--) {
                var newOnset = this.onsets[p]

                if (
                    Time.compareTwoFractions(onset.start, newOnset.start) ==
                    "eq"
                ) {
                    this.onsets.splice(o, 1)

                    break
                }
            }
        }
    }
}
