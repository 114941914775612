import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core"

import { ParentClass } from "../../../parent"
import { UnsupportedOsModal } from "@services/modal.service"
import { EditorHttpService } from "@services/editor/editor.http"

@Component({
    selector: "unsupported-os",
    templateUrl: "unsupported-os.component.html",
    styleUrls: ["unsupported-os.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsupportedOSComponent extends ParentClass {
    @Input() data: UnsupportedOsModal
    @Output() close = new EventEmitter<any>()

    constructor(private ref: ChangeDetectorRef) {
        super()
    }

    async ngOnInit() {}
}
