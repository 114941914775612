<div class="modal-title">Select a layer type</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div class="loader" *ngIf="loading"></div>

<div class="select-layer-container" *ngIf="!loading">
    <dropdown
        type="light"
        [showArrow]="true"
        [value]="getSelectedDropDownItem()"
        [showTooltip]="false"
        [items]="getDropdownItems()"
        (selectedItem)="selectLayer($event)">
    </dropdown>

    <div class="error" *ngIf="error">{{ error }}</div>

    <div class="button-container">
        <a-button
            width="50%"
            type="text"
            label="Cancel"
            (onClick)="close.emit()"></a-button>

        <a-button
            width="50%"
            type="secondary"
            label="Add layer"
            (click)="confirm()"></a-button>
    </div>
</div>
