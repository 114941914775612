import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Output,
    ViewChild,
    EventEmitter,
    SimpleChanges,
} from "@angular/core"

@Component({
    selector: "slide-toggle",
    templateUrl: "slide-toggle.component.html",
    styleUrls: ["slide-toggle.component.scss"],
})
export class SlideToggleComponent implements OnInit {
    @Input() class: string

    @Input() toggled: boolean // two-way binding variable to indicate the toggle state

    @Input() disabled: boolean = false

    @Output() toggledChange = new EventEmitter<boolean>() // two-way binding EventEmitter to emit the toggle state on change

    @Output() onChange = new EventEmitter<boolean>() // EventEmitter that emits the toggle state as a boolean value on toggle changes

    @Input() width = 30 + "px" // The width of the element. By changing the width, the element will scale proportionally (so it always looks the same for different sizes)

    @Input() avData: string

    @ViewChild("slideToggle") slideToggle: ElementRef // ElementRef of this components slide-toggle HTML element

    /**
     * Example
     * <slide-toggle width="30px" [(toggled)]="difficultyToggled" (onChange)="changeSlider($event)"></slide-toggle>
     */

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.setToggleSize(this.width)
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let change in changes) {
            if (this[change] != null) {
                this[change] = changes[change].currentValue
            }

            if (change == "width") {
                this.setToggleSize(this.width)
            }
        }
    }

    toggle() {
        if (this.disabled) {
            return
        }

        this.toggled = !this.toggled
        this.toggledChange.emit(this.toggled)
        this.onChange.emit(this.toggled)
    }

    setToggleSize(width) {
        if (
            width == null ||
            this.slideToggle == null ||
            this.slideToggle.nativeElement == null
        ) {
            return
        }

        if (!width.includes("px")) {
            width = width + "px"
        }

        this.slideToggle.nativeElement.style.setProperty("--width", width)
    }
}
