import TrackBus from "./trackbus"
import { Time } from "../../modules/time"
import { Time as Time2 } from "../../modules/time2"
import Section from "./section"
import { ImmutableNote, Note } from "./note"
import EditingState from "./editingstate"
import Tempo from "./tempo"
import Layer from "./layer"
import { Effect } from "./effect"
import { Pattern } from "./pattern"
import Channel from "./channel"
import PercussionLayer from "./percussionlayer"
import PatternRegion from "./patternregion"
import Operation from "./operation"
import { BarCount, TimeSignature } from "../../types/score"
import {
    TemplateChord,
    TemplateEffects,
    TemplateLayers,
    TemplateScore,
    TemplateSustainPedal,
    TemplateTrack,
} from "../../interfaces/score/templateScore"
import { LayersValue } from "../../types/general"
import { ScoreType } from "../../types/score"
import {
    AUTOMATION_TIMESTEP_RES,
    TIMESTEP_RES,
} from "../../constants/constants"
import AccompanimentPack from "../generationprofiles/accompaniment/accompanimentpack"
import { LayerType } from "../../constants/constants"
import { ScoreEncoding } from "../../modules/score-transformers/scoreEncoding"
import SamplesMap from "../../interfaces/score/samplesMap"
import { FractionString } from "../../types/score"
import {
    AccompanimentPackME,
    PatternNoteMusicEngine,
    ReducedAccompanimentPatternME,
} from "../../interfaces/music-engine/accompanimentPackData"
import { RangeWithID } from "../../interfaces/general"
import { v4 as uuidv4 } from "uuid"
import { cloneDeep } from "lodash"
import { InstrumentsJSON } from "../../interfaces/score/general"
import { KeySignature } from "../../interfaces/score/keySignature"
import SectionOperation from "./operation"
import { ScoreManipulation } from "../../modules/scoremanipulation"
import { NotesObject } from "./notesObject"
import { KeySignatureModule } from "../../modules/keysignature.module"
import { CWKeyMode } from "../../interfaces/composition-workflow.interface"
import { Fraction } from "./fraction"
import ScoreCanvas from "../../../client-only/score-rendering-engine/canvas/score-canvas"

export default class Score {
    static SCORE_LENGTH_PADDING = "5"

    static MAX_GAIN_OFFSET = 30
    static MIN_GAIN_OFFSET = -40
    static MAX_OCTAVE = 5
    static MAX_DYNAMIC_OFFSET = 120

    EDITOR_END_BUFFER = 64

    compositionID: string

    type: ScoreType = "composition"

    effects: TemplateEffects = {
        bass_boost: false,
        vinyl: false,
    }

    editedChords = false

    previouslySeenNoteGroups: {
        [layerKey: string]: FractionString
    } = {}

    hasStartOffset = false
    keySignatures: Array<any> = []
    timeSignatures: [string, TimeSignature][] = []
    tempoMap: Tempo[] = []
    sections: Section[] = []

    chords: Array<any> = []
    romanNumerals?: TemplateChord[]

    sustainPedal: TemplateSustainPedal[] = []

    scoreLength: FractionString = "1"

    layers: {
        [layerName in string]: Layer | PercussionLayer
    } = {}

    constructor({
        templateScore,
        instrumentReferences,
        samplesMap,
        scoreLength, // to force the default scoreLength
        lastSectionDuration,
        encodeArgs,
    }: {
        templateScore: TemplateScore
        instrumentReferences: InstrumentsJSON
        samplesMap: SamplesMap
        scoreLength?: FractionString // to force the default scoreLength
        lastSectionDuration?: string
        encodeArgs?: {
            tieTrackBusRegions: boolean
            computePhrases: boolean
            computeSustainPedal: boolean
        }
    }) {
        if (templateScore) {
            ScoreEncoding.fromTemplateScore(
                this,
                templateScore,
                samplesMap,
                instrumentReferences,
                lastSectionDuration,
                encodeArgs
            )

            if (
                scoreLength &&
                Time.compareTwoFractions(this.scoreLength, scoreLength) === "gt"
            ) {
                throw Error(
                    `The given scoreLength of ${scoreLength} is shorter than the computed scoreLength of ${this.scoreLength}`
                )
            } else if (scoreLength) {
                this.scoreLength = scoreLength
            }

            this.scoreLength = Time.addTwoFractions(
                this.scoreLength,
                Score.SCORE_LENGTH_PADDING
            )
        }
    }

    get firstTimeSignature(): TimeSignature {
        return this.timeSignatures[0][1]
    }

    get trackBusses(): Array<TrackBus> {
        let trackBusses: Array<TrackBus> = []

        for (var layer in this.layers) {
            trackBusses = trackBusses.concat(this.layers[layer].trackBuses)
        }

        return trackBusses
    }

    getLayersArray(): (Layer | PercussionLayer)[] {
        let layers: (Layer | PercussionLayer)[] = []

        for (let key in this.layers) {
            layers.push(this.layers[key])
        }

        return layers
    }

    public getScoreLengthWithoutPadding() {
        return Time.max(
            "0",
            Time.addTwoFractions(
                this.scoreLength,
                Score.SCORE_LENGTH_PADDING,
                true
            )
        )
    }

    static createEmptyLayer(layer: LayersValue) {
        return {
            type: layer.includes("Percussion") ? "percussion" : "pitched",
            value: layer,
            effects: {
                dynamic: {
                    active: true,
                },
                low_frequency_cut: {
                    active: true,
                },
                high_frequency_cut: {
                    active: true,
                },
                reverb: {
                    active: true,
                    ir: "",
                },
                delay: {
                    active: true,
                    left: {
                        delay_time: 0,
                    },
                    right: {
                        delay_time: 0,
                    },
                },
                auto_staccato: {
                    active: true,
                },
            },
            gain_bias: 0,
        }
    }

    static createEmptyScore(
        timeSignature: TimeSignature,
        tempo: number,
        type: ScoreType,
        keySignature: string,
        layer: LayersValue,
        samplesMap: SamplesMap
    ) {
        let layers: TemplateLayers = {}
        layers[layer] = Score.createEmptyLayer(layer)

        const instrument = "k.piano"
        const trackName = instrument + ".nat.stac"

        const tracks: TemplateTrack[] = [
            {
                id: uuidv4(),
                layer: layer,
                track_layer: layer,
                name: trackName,
                instrument: instrument,
                use_velocity: true,
                use_expression: false,
                auto_pedal: true,
                octave: 0,
                mute: false,
                solo: false,
                dynamic_offset: 0,
                gain_offset: 0,
                panning: 0,
                breathing_gain: 0,
                gm: 0,
                channel: 0,
                track: [],
            },
        ]

        const templateScore: TemplateScore = {
            type: "composition",
            compositionID: "",
            chords: [],
            keySignatures: [["0", keySignature]],
            timeSignatures: [["0", timeSignature]],
            tempoMap: [["0", tempo]],
            sections: [["0", "A"]],
            sustainPedal: [],
            effects: {
                vinyl: false,
                bass_boost: false,
            },
            tracks: tracks,
            layers: layers,
        }

        const score = new Score({
            templateScore: templateScore,
            instrumentReferences: {},
            samplesMap,
        })
        score.type = type

        return score
    }

    getPatternByID(id, layer: PercussionLayer): Pattern | undefined {
        for (var p = 0; p < layer.patterns.length; p++) {
            if (layer.patterns[p].id == id) {
                return layer.patterns[p]
            }
        }

        return undefined
    }

    /**
     * Returns a new blocks array of blocks found within the specified start / end boundaries
     * @param start
     * @param end
     * @param trackbus
     * @param type
     * @returns
     */
    static getTrackBusRegionsAtBoundaries(
        start: number,
        end: number,
        trackbusBlocks: RangeWithID[]
    ): RangeWithID[] {
        const blocks: RangeWithID[] = []

        let added = false

        for (const block of trackbusBlocks) {
            const outsideBlock = block.start > end || block.end < start

            if (outsideBlock) {
                continue
            }

            // |start .... [block] ... end|
            const outsideOverlapBlock = block.start >= start && block.end <= end

            if (outsideOverlapBlock) {
                blocks.push({
                    start: block.start,
                    end: block.end,
                    id: uuidv4(),
                })

                added = true

                continue
            }

            // [block... | start ... end | ...block]
            const insideBlock = block.start < start && block.end >= end

            if (insideBlock) {
                blocks.push({
                    start: start,
                    end,
                    id: uuidv4(),
                })

                continue
            }

            // | start ... [block... end ...block] |
            const partiallyOutLeft = block.start < end && block.end >= end

            // | [block... start ...block] ... end |
            const partiallyOutRight = block.start <= start && block.end > start

            if (partiallyOutLeft) {
                blocks.push({
                    start: block.start,
                    end: end,
                    id: uuidv4(),
                })

                added = true
            } else if (partiallyOutRight) {
                blocks.push({
                    start: start,
                    end: block.end,
                    id: uuidv4(),
                })

                added = true
            }
        }

        return blocks
    }

    /**
     * Returns a new blocks array either with or without any blocks within the specified boundaries
     * This function does not mutate the state of the trackbus object in any way.
     * The end is not included when splicing
     * @param start
     * @param end
     * @param trackbus
     * @param type
     * @returns
     */
    static modifyTrackBusRegions(
        start: number,
        end: number,
        trackbusBlocks: RangeWithID[],
        type: "splice" | "add",
        options: {
            preciseCut: boolean
        } = {
            preciseCut: false,
        }
    ): RangeWithID[] {
        const blocks: RangeWithID[] = []

        let added = false

        for (const block of trackbusBlocks) {
            const outsideBlock = block.start > end || block.end < start

            if (outsideBlock) {
                blocks.push(block)

                continue
            }

            // |start .... [block] ... end|
            const outsideOverlapBlock = block.start >= start && block.end <= end

            if (outsideOverlapBlock) {
                if (type === "add") {
                    blocks.push({
                        start: start,
                        end: end,
                        id: uuidv4(),
                    })

                    added = true
                }

                continue
            }

            // [block... | start ... end | ...block]
            const insideBlock = block.start < start && block.end >= end

            if (insideBlock) {
                if (type === "splice") {
                    blocks.push({
                        start: block.start,
                        end: options.preciseCut ? start : start - 1,
                        id: uuidv4(),
                    })

                    blocks.push({
                        start: end,
                        end: block.end,
                        id: uuidv4(),
                    })
                } else {
                    blocks.push(block)

                    added = true
                }

                continue
            }

            // | start ... [block... end ...block] |
            const partiallyOutLeft = block.start <= end && block.end >= end

            // | [block... start ...block] ... end |
            const partiallyOutRight = block.start <= start && block.end >= start

            if (partiallyOutLeft) {
                blocks.push({
                    start: type === "splice" ? end : start,
                    end: block.end,
                    id: uuidv4(),
                })

                added = true
            } else if (partiallyOutRight) {
                blocks.push({
                    start: block.start,
                    end: type === "splice" ? start : end,
                    id: uuidv4(),
                })

                added = true
            }
        }

        if (!added && type === "add") {
            blocks.push({
                start: start,
                end: end,
                id: uuidv4(),
            })

            blocks.sort((a, b) => {
                return a.start - b.start
            })
        }

        return blocks
    }

    public getSectionInRangeAtTimestep(
        timestep: number,
        noteRes: number
    ): {
        start: number
        end: number
        section: Section
    } {
        const mouseFraction = Time2.timestepToFraction(timestep, noteRes)

        for (let section of this.sections) {
            const startFraction = new Fraction(section.start)
            const endFraction = new Fraction(section.end)

            if (
                mouseFraction.toDecimal() >= startFraction.toDecimal() &&
                mouseFraction.toDecimal() <= endFraction.toDecimal()
            ) {
                return {
                    start: Time2.fractionToTimesteps(
                        TIMESTEP_RES,
                        startFraction
                    ),
                    end: Time2.fractionToTimesteps(TIMESTEP_RES, endFraction),
                    section,
                }
            }
        }
        return null
    }

    cleanupAutomation(automation) {
        for (var layer in automation) {
            for (var d = 0; d < automation[layer].length; d++) {
                if (
                    automation[layer][d] == null ||
                    isNaN(automation[layer][d])
                ) {
                    automation[layer][d] = 0
                }
            }
        }

        return automation
    }

    gatherAutomationsData(tracks) {
        var layerStarts = {}
        var layers = Object.keys(this.layers)

        var automations = {}

        for (let effect in this.layers[layers[layers.length - 1]]?.effects) {
            const effectObject =
                this.layers[layers[layers.length - 1]].effects[effect]

            if (!effectObject.automated) {
                continue
            }

            automations[effect] = {}

            for (var l = 0; l < layers.length; l++) {
                automations[effect][layers[l]] = []
            }
        }

        for (var t = 0; t < tracks.length; t++) {
            var trackLayer = tracks[t].layer

            for (var e = 0; e < tracks[t].track.length; e++) {
                var note = tracks[t].track[e]

                note.meta = this.handleEmptyMeta(note.meta, trackLayer)

                if (layerStarts[note.meta.layer] == null) {
                    layerStarts[note.meta.layer] = note.start
                } else if (
                    Time.compareTwoFractions(
                        layerStarts[note.meta.layer],
                        note.start
                    ) == "gt"
                ) {
                    layerStarts[note.meta.layer] = note.start
                }

                automations = this.gatherAutomationData(note, automations)
            }
        }

        for (var layer in layerStarts) {
            var start = Time.quantizeFraction(
                layerStarts[layer],
                "round",
                AUTOMATION_TIMESTEP_RES
            )

            for (var s = 0; s < start; s++) {
                automations["dynamic"][layer][s] =
                    automations["dynamic"][layer][start]
                automations["reverb"][layer][s] =
                    automations["reverb"][layer][start]
                automations["delay"][layer][s] =
                    automations["delay"][layer][start]
            }
        }

        return automations
    }

    gatherAutomationData(note, automations) {
        for (var effect in automations) {
            var effectObject = this.layers[note.meta.layer].effects[effect]

            if (note[effect] == null || note[effect].length == 0) {
                note[effect] = [["0", effectObject.default]]
            }

            for (var d = 0; d < note[effect].length; d++) {
                var dynTime: any = Time.addTwoFractions(
                    note.start,
                    note[effect][d][0]
                )
                dynTime = Time.quantizeFraction(
                    dynTime,
                    "round",
                    AUTOMATION_TIMESTEP_RES
                )

                if (automations[effect][note.meta.layer] == null) {
                    automations[effect][note.meta.layer] = []
                }

                if (
                    automations[effect][note.meta.layer][parseInt(dynTime)] ==
                    null
                ) {
                    automations[effect][note.meta.layer][parseInt(dynTime)] =
                        note[effect][d][1]
                }
            }
        }

        return automations
    }

    interpolateAutomationForLayers(data) {
        for (var layer in data) {
            var previous
            var next

            for (var i = 0; i < data[layer].length; i++) {
                if (data[layer][i] != null) {
                    previous = i
                    next = null

                    continue
                }

                let indexes: number[] = []

                for (var j = i; j < data[layer].length; j++) {
                    if (data[layer][j] == null) {
                        indexes.push(j)
                    } else {
                        next = j

                        break
                    }
                }

                for (var k = 0; k < indexes.length; k++) {
                    if (previous == null) {
                        data[layer][indexes[k]] = data[layer][next]
                    }

                    if (next == null) {
                        data[layer][indexes[k]] = data[layer][previous]
                    } else {
                        const x = [previous, data[layer][previous]]
                        const y = [next, data[layer][next]]

                        data[layer][indexes[k]] =
                            Math.floor(
                                Effect.interpolate(x, y, indexes[k]) * 500
                            ) / 500
                    }
                }

                previous = i
                next = null
            }
        }

        return data
    }

    handleEmptyMeta(meta, trackLayer) {
        if (meta == null) {
            meta = { layer: "Chords", section: 0 }
        }

        if (meta.layer == null) {
            meta.layer = "Chords"
        }

        if (trackLayer != null) {
            meta.layer = trackLayer
        }

        return meta
    }

    sameBlockZone(note, nextNote): boolean {
        const noteEnd = Time.addTwoFractions(note.start, note.end)
        const fraction = Time.addTwoFractions(
            nextNote.start,
            noteEnd,
            true
        ).split("/")

        var difference

        if (fraction.length > 1) {
            difference = parseInt(fraction[0]) / parseInt(fraction[1])
        } else {
            difference = parseInt(fraction[0])
        }

        const timeSignature = this.timeSignatures[0][1]

        difference = (timeSignature[0] * difference) / timeSignature[1]

        if (difference <= 1) {
            return true
        }

        return false
    }

    addInstrumentReference(
        instrument: string,
        instrumentReferences: InstrumentsJSON
    ) {
        const instrumentName = instrument.split(".")[1]
        const section = instrument.split(".")[0]

        return instrumentReferences[section].find(
            i => i.name === instrumentName
        )
    }

    updateTrackBusses(newTrackBuses, layerObject) {
        var trackBusUpdated = 0

        for (var t = 0; t < layerObject.trackBuses.length; t++) {
            for (var trackBusToUpdate of newTrackBuses) {
                if (layerObject.trackBuses[t].id == trackBusToUpdate.id) {
                    layerObject.trackBuses[t] = trackBusToUpdate
                    trackBusUpdated += 1

                    if (newTrackBuses.length == trackBusUpdated) {
                        return
                    }

                    break
                }
            }
        }
    }

    /**
     * Tie block that are less than a bar apart together
     * @param blocks
     * @param ts
     * @returns a new copy of the blocks array
     */
    static tieCloseBlocks(
        notes: NotesObject,
        blocks: RangeWithID[],
        ts: TimeSignature
    ): RangeWithID[] {
        const newBeats: RangeWithID[] = []

        if (blocks.length === 0) {
            return newBeats
        }

        let b = 0

        let currentBlock: RangeWithID = cloneDeep(blocks[0])

        while (b < blocks.length) {
            const nextBlock = blocks.length > b + 1 ? blocks[b + 1] : undefined
            const shouldMerge =
                currentBlock.end + TIMESTEP_RES * 4 >= nextBlock?.start
            const hasNotesInRange = nextBlock
                ? notes.hasNoteInRange([
                      Time.timestepToFraction(currentBlock.end, TIMESTEP_RES),
                      Time.timestepToFraction(nextBlock.start, TIMESTEP_RES),
                  ])
                : false

            if (shouldMerge && !hasNotesInRange) {
                currentBlock.end = nextBlock.end

                if (b + 1 >= blocks.length) {
                    newBeats.push(currentBlock)
                }

                blocks.splice(b + 1, 1)
            } else {
                newBeats.push(currentBlock)
                b++
                currentBlock = cloneDeep(blocks[b])
            }
        }

        return newBeats
    }

    addTrackBus(
        id: string | undefined,
        instrumentName: string,
        layer: Layer | PercussionLayer,
        octave: number,
        instrumentReferences: InstrumentsJSON,
        blocks: RangeWithID[],
        dynamicOffset: number,
        gainOffset: number,
        breathingGain: number,
        panning: number,
        mute: boolean,
        solo: boolean,
        autoPedal: boolean,
        packDBColumns
    ) {
        if (instrumentName.includes("silent-") && layer.type == "pitched") {
            return null
        }

        if (id === undefined) {
            id = uuidv4()
        }

        for (var i = 0; i < layer.trackBuses.length; i++) {
            const trackBus = layer.trackBuses[i]

            if (
                trackBus.octave == octave &&
                trackBus.name == instrumentName &&
                trackBus.dynamicOffset == dynamicOffset &&
                trackBus.gainOffset == gainOffset &&
                trackBus.panning == panning &&
                packDBColumns == trackBus.packDBColumns
            ) {
                for (const block of blocks) {
                    trackBus.blocks = Score.modifyTrackBusRegions(
                        block.start,
                        block.end,
                        trackBus.blocks,
                        "add"
                    )
                }

                trackBus.trackIDs.push(id)

                return trackBus
            }
        }

        const reference = this.addInstrumentReference(
            instrumentName,
            instrumentReferences
        )

        if (autoPedal == null) {
            autoPedal = false
        }

        const audioTrack = new TrackBus(
            instrumentName,
            octave,
            reference,
            blocks,
            dynamicOffset,
            gainOffset,
            breathingGain,
            panning,
            mute,
            solo
        )
        audioTrack.packDBColumns = packDBColumns
        audioTrack.autoPedal = autoPedal
        audioTrack.trackIDs.push(id)

        layer.trackBuses.push(audioTrack)

        return audioTrack
    }

    duplicateChannels(
        layer: PercussionLayer,
        targetTrackBus: TrackBus,
        trackBusToDuplicate: TrackBus,
        drumkitSamplesMap,
        drumkitPitchToChannelMapping
    ) {
        var newInstrumentName = targetTrackBus.name.split(".")[1]
        for (var p of layer.patterns) {
            var channelsToAdd = {}

            for (var channel of p.channels) {
                if (
                    trackBusToDuplicate != null &&
                    channel.trackBus.id != trackBusToDuplicate.id
                ) {
                    continue
                }

                var hasChannel = this.instrumentDrumkitIncludesPitches(
                    channel.pitches,
                    newInstrumentName,
                    drumkitSamplesMap
                )

                if (!hasChannel) {
                    continue
                }

                var newChannel = channel.copy()
                newChannel.name = this.getChannelNameByPitchesAndTrackBus(
                    newChannel.pitches,
                    newInstrumentName,
                    drumkitSamplesMap,
                    drumkitPitchToChannelMapping
                )

                if (channelsToAdd[newChannel.name] == null) {
                    newChannel.trackBus = targetTrackBus

                    channelsToAdd[newChannel.name] = newChannel
                } else {
                    channelsToAdd[newChannel.name].onsets.concat(
                        newChannel.onsets
                    )
                }
            }

            for (var channelName in channelsToAdd) {
                channelsToAdd[channelName].removeDuplicateOnsets()
                p.channels.push(channelsToAdd[channelName])
            }
        }
    }

    getChannelNameByPitches(pitches, drumkitPitchToChannelMapping) {
        let channelName = "Unassigned"

        for (let pitch of pitches) {
            for (let samplePitch in drumkitPitchToChannelMapping) {
                if (pitch == samplePitch) {
                    return drumkitPitchToChannelMapping[samplePitch]
                }
            }
        }

        return channelName
    }

    getChannelNameByPitchesAndTrackBus(
        pitches,
        trackBusReferenceName,
        drumkitSamplesMap,
        drumkitPitchToChannelMapping
    ) {
        let channelName = "Unassigned"

        if (trackBusReferenceName == null || trackBusReferenceName == "") {
            return channelName
        }

        for (let pitch of pitches) {
            if (!drumkitSamplesMap[trackBusReferenceName].includes(pitch)) {
                continue
            }

            for (let samplePitch in drumkitPitchToChannelMapping) {
                if (pitch == samplePitch) {
                    return drumkitPitchToChannelMapping[samplePitch]
                }
            }
        }

        return channelName
    }

    instrumentDrumkitIncludesPitches(
        pitches,
        trackBusReferenceName,
        drumkitSamplesMap
    ) {
        if (drumkitSamplesMap[trackBusReferenceName] == null) {
            return false
        }

        return pitches.some(p =>
            drumkitSamplesMap[trackBusReferenceName].includes(p)
        )
    }

    removeTrackBus(index, layerObject) {
        let removeIndex = -1

        for (const trackBus of layerObject.trackBuses) {
            removeIndex++

            if (trackBus.id == index) {
                break
            }
        }

        if (removeIndex != -1) {
            layerObject.trackBuses.splice(removeIndex, 1)
        }
    }

    hasSoloedTrackBus() {
        for (var trackBus of this.trackBusses) {
            if (trackBus.solo) {
                return true
            }
        }

        return false
    }

    buildDecodedTemplate(addDecodedLayers: boolean): TemplateScore {
        const layers = {}

        if (addDecodedLayers) {
            for (const l in this.layers) {
                layers[l] = this.layers[l].decode()
            }
        }

        return {
            compositionID: this.compositionID,
            lastSectionDuration:
                this.sections.length > 0
                    ? this.sections[this.sections.length - 1].duration
                    : "0",
            tempoMap: this.postprocessTempoMap(),
            layers: layers,
            timeSignatures: this.timeSignatures,
            keySignatures: this.keySignatures,
            sections: this.sections.map(section => {
                return [section.start, section.title]
            }),
            chords: this.chords,
            effects: this.effects,
            sustainPedal: this.sustainPedal,
            tracks: [],
            type: this.type,
        }
    }

    static setAutoPedal(instrument, section) {
        return (
            section == "k" &&
            !(
                instrument == "harmonium" ||
                instrument == "edm-piano" ||
                instrument == "harpsichord"
            )
        )
    }

    copyChords(chords) {
        var newChords = []

        for (var chord of chords) {
            newChords.push([chord[0], chord[1]])
        }

        return newChords
    }

    setInstrumentWithIndex(trackBus, layerObject) {
        for (var trackBusFromLayer of layerObject.trackBuses) {
            if (trackBusFromLayer.id == trackBus.id) {
                trackBusFromLayer = trackBus

                break
            }
        }
    }

    computePhrases(layer: Layer) {
        if (!layer.value.includes("Melody")) {
            return
        }

        let currentPhraseStart: FractionString | undefined = undefined
        let currentPhraseIndex: number = 0

        const oneAndHalfMeasure = Time.measuresToFraction(
            1.5,
            this.firstTimeSignature
        )
        const twoMeasures = Time.measuresToFraction(2, this.firstTimeSignature)

        layer.notesObject.forEachGroupAndRests((previous, current, next) => {
            const isNote = current[0] instanceof Note
            const end = Time.addTwoFractions(
                current[0].start,
                current[0].duration
            )

            // If we have a rest, we can't start a phrase
            if (currentPhraseStart === undefined && !isNote) {
                return
            } else if (currentPhraseStart === undefined) {
                currentPhraseStart = current[0].start
                currentPhraseIndex = 0
            } else if (!isNote) {
                const offsetSincePhraseStart = Time.addTwoFractions(
                    end,
                    currentPhraseStart,
                    true
                )

                if (
                    Time.compareTwoFractions(
                        offsetSincePhraseStart,
                        oneAndHalfMeasure
                    ) !== "lt" &&
                    next !== undefined
                ) {
                    currentPhraseStart = next[0].start
                    currentPhraseIndex += 1
                }

                return
            } else if (next !== undefined && next.length > 0) {
                const offsetSincePhraseStart = Time.addTwoFractions(
                    end,
                    currentPhraseStart,
                    true
                )

                if (
                    Time.compareTwoFractions(
                        offsetSincePhraseStart,
                        twoMeasures
                    ) !== "lt" &&
                    next !== undefined
                ) {
                    currentPhraseStart = next[0].start
                    currentPhraseIndex += 1
                }
            }

            for (const n of current) {
                const note = n as Note

                NotesObject.setPhraseAndSection(
                    note,
                    currentPhraseIndex,
                    this.sections
                )
            }
        })
    }

    getPitchedLayersList(): Layer[] {
        let layers: Layer[] = []

        for (let key in this.layers) {
            if (this.layers[key] instanceof PercussionLayer) {
                continue
            }

            layers.push(this.layers[key])
        }

        return layers
    }

    postprocessTempoMap() {
        var tempoMap = []

        for (var t = 0; t < this.tempoMap.length; t++) {
            tempoMap.push([
                this.tempoMap[t].fraction,
                Math.round(this.tempoMap[t].bpm),
            ])
        }

        return tempoMap
    }

    applyAutomationState(state: EditingState) {
        if (state.operation == EditingState.UPDATE) {
            for (var layer in state.valuesAfterEditing) {
                this.layers[layer].effects[state.type] =
                    state.valuesAfterEditing[layer].copy()
            }
        }
    }

    findLayerLastNote(layer = null) {
        if (!layer == null || layer.notes == null || layer.notes.length == 0) {
            return
        }

        let lastNote = layer.notes[0]

        for (let note of layer.notes) {
            let noteEnd = Time.fractionToNumber(
                Time.addTwoFractions(note.start, note.duration)
            )
            let lastNoteEnd = Time.fractionToNumber(
                Time.addTwoFractions(lastNote.start, lastNote.duration)
            )

            if (noteEnd > lastNoteEnd) {
                lastNote = note
            }
        }

        return lastNote
    }

    initPercussionLayerAfterAddingFirstInstrument(layerName, newInstrument) {
        let newPattern = new Pattern("1")

        newPattern.channels = [
            new Channel("Kick", [35, 36], [], false, false, newInstrument),
            new Channel("Snare Center", [38], [], false, false, newInstrument),
            new Channel("Hi-Hat Closed", [42], [], false, false, newInstrument),
            new Channel("Mid Tom", [47], [], false, false, newInstrument),
        ]

        this.layers[layerName]["patterns"] = [newPattern]

        let newPatternRegion = new PatternRegion(
            {
                start: "0",
                onset: "0",
                duration: "2/1",
                loop: 0,
            },
            this.layers[layerName]["patterns"][0]
        )

        this.layers[layerName]["patternRegions"] = [newPatternRegion]
    }

    updateChannels(
        layerObject: Layer | PercussionLayer,
        oldTrackBus,
        newTrackBus,
        drumkitSamplesMap,
        drumkitPitchToChannelMapping
    ) {
        if (layerObject.type != "percussion" || newTrackBus == null) {
            return
        }

        let newInstrumentName = newTrackBus.reference.name

        for (var p of (<PercussionLayer>layerObject).patterns) {
            for (var channel of p.channels) {
                if (channel.trackBus == null) {
                    continue
                }

                if (channel.trackBus.id == oldTrackBus.id) {
                    channel.trackBus = newTrackBus
                    channel.name = this.getChannelNameByPitchesAndTrackBus(
                        channel.pitches,
                        newInstrumentName,
                        drumkitSamplesMap,
                        drumkitPitchToChannelMapping
                    )
                }
            }
        }
    }

    cloneDeep() {
        return cloneDeep(this)
    }

    /**
     * converts a pack and its patterns to a Score
     * @param accompanimentPack
     * @param samplesMap
     * @param keySignature          when given, the notes of the pack will be adjusted to a certain key signature scale
     * @param concatenatePatterns   when true, patterns will be concatenated instead of being placed in the same area
     * @returns
     */
    static convertPackToScore({
        accompanimentPack,
        samplesMap,
        keySignature,
        concatenatePatterns,
        instruments,
        layer,
    }: {
        accompanimentPack: AccompanimentPack
        samplesMap: SamplesMap
        instruments: InstrumentsJSON
        concatenatePatterns?: boolean
        keySignature?: KeySignature
        layer: string
    }): Score {
        const score: Score = Score.createEmptyScore(
            [4, 4],
            120,
            "pack",
            keySignature
                ? keySignature.pitchClass + " " + keySignature.keyMode
                : "C major",
            layer,
            samplesMap
        )
        score.compositionID = accompanimentPack.packID

        let lastPatternEnd = "0"

        if (keySignature === undefined) {
            keySignature = {
                pitchClass: "C",
                keyMode: "major",
            }
        }

        accompanimentPack.patterns =
            AccompanimentPack.normalizePitchForPatterns(
                accompanimentPack.patterns,
                keySignature
            )

        for (let pattern of accompanimentPack.patterns) {
            const patternEnd = Time.measuresToFraction(
                pattern.bars,
                score.firstTimeSignature
            )

            for (let note of pattern.pattern) {
                if (Time.compareTwoFractions(note.onset, patternEnd) !== "lt") {
                    continue
                }

                for (let pitch of note.pitch) {
                    const start = concatenatePatterns
                        ? Time.addTwoFractions(note.onset, lastPatternEnd)
                        : note.onset

                    const n = new Note({
                        pitch: pitch,
                        start: start,
                        duration: note.duration,
                        meta: {
                            section: 0,
                            layer: layer,
                        },
                    })

                    score.layers[layer].addNotes(
                        score.timeSignatures[0][1],
                        score.sections,
                        [n],
                        true
                    )
                }
            }
            lastPatternEnd = Time.addTwoFractions(
                lastPatternEnd,
                `${pattern.bars}/1`
            )
        }

        score.addTrackBus(
            uuidv4(),
            "k.piano.nat.stac",
            score.layers[layer],
            0,
            instruments,
            [
                {
                    id: uuidv4(),
                    start: 0,
                    end: 99999999999,
                },
            ],
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            null
        )

        return score
    }

    static convertScoreToPatterns(
        score: Score,
        type: LayerType,
        patternLength: BarCount,
        allowCrossingBarBoundaries: boolean = true // if set to false, note duration will
        // be trimmed to fit the current bar
    ): ReducedAccompanimentPatternME[] {
        const layer: Layer = score?.layers[type]

        if (!layer) {
            return undefined
        }

        const timeSignature = score.firstTimeSignature
        const patternSizeInWholeNotes = Time.simplifyFraction(
            patternLength * timeSignature[0],
            timeSignature[1]
        )

        let currentPatternStart = "0"
        let nextPatternStart = "0"

        let patterns: ReducedAccompanimentPatternME[] = []

        layer.notesObject.manipulateNoteGroups((notes: ImmutableNote[]) => {
            const noteStart = notes[0].start

            // create a new pattern for notes that start after the current pattern
            if (Time.compareTwoFractions(noteStart, nextPatternStart) != "lt") {
                currentPatternStart = nextPatternStart
                nextPatternStart = Time.addTwoFractions(
                    nextPatternStart,
                    patternSizeInWholeNotes
                )

                patterns.push({
                    pattern: [],
                    bars: patternLength,
                    sections: ["Intro", "Theme", "Bridge", "Outro", "Cadence"],
                    pre_filter: [],
                    spread: 0,
                    upper_range: 1,
                })
            }

            const pitches = notes.map((note: Note) => {
                return note.pitch
            })

            const onset = Time.addTwoFractions(
                noteStart,
                currentPatternStart,
                true
            )

            let duration = notes[0].duration

            // trim duration if it exists, the note exceeds the bar boundary otherwise
            if (!allowCrossingBarBoundaries) {
                const startBar = Math.floor(
                    Time.fractionToBeat(onset, timeSignature, "floor") /
                        timeSignature[0]
                ) // bar in which the note starts
                const maxDuration = Time.addTwoFractions(
                    Time.simplifyFraction(
                        (startBar + 1) * timeSignature[0],
                        timeSignature[1]
                    ),
                    onset,
                    true
                )

                if (Time.compareTwoFractions(duration, maxDuration) === "gt") {
                    duration = maxDuration
                }
            }

            patterns[patterns.length - 1].pattern.push({
                onset: Time.addTwoFractions(
                    noteStart,
                    currentPatternStart,
                    true
                ),
                duration: duration,
                pitch: pitches,
                dynamic: 0,
            })

            return true
        })

        return patterns
    }

    static getMEPatternsWithoutNotesCrossingBars(args: {
        pack: AccompanimentPackME
        reducedPatterns: ReducedAccompanimentPatternME[]
    }): ReducedAccompanimentPatternME[] {
        const timeSignature = args.pack.time_signature

        for (let pattern of args.reducedPatterns) {
            for (let p of pattern.pattern) {
                const onset = (p as PatternNoteMusicEngine).onset
                let duration = (p as PatternNoteMusicEngine).duration

                // bar in which the note starts
                const startBar = Math.floor(
                    Time.fractionToBeat(onset, timeSignature, "floor") /
                        timeSignature[0]
                )

                const maxDuration = Time.addTwoFractions(
                    Time.simplifyFraction(
                        (startBar + 1) * timeSignature[0],
                        timeSignature[1]
                    ),
                    onset,
                    true
                )

                if (Time.compareTwoFractions(duration, maxDuration) === "gt") {
                    p.duration = maxDuration
                }
            }
        }

        return args.reducedPatterns
    }

    public getKeySignature() {
        return Score.getKeySignatureObject(this.keySignatures[0][1])
    }

    /**
     * returns a given key signature string as a KeySignature object
     * @param keySignature string, e.g. "C minor"
     * @returns KeySignature, e.g. { pitchClass: "C", keyMode: "minor" }
     */
    static getKeySignatureObject(keySignature: string): KeySignature {
        if (
            !keySignature ||
            keySignature === "" ||
            keySignature.split(" ").length < 2
        ) {
            return
        }

        return {
            pitchClass: keySignature.split(" ")[0],
            keyMode: keySignature.split(" ")[1] as CWKeyMode,
        }
    }

    /**
     * returns the last note of a score
     * @param score
     * @returns
     */
    static getLastNote(score: Score) {
        let lastNote: Note = undefined

        for (let layer in score.layers) {
            const lastLayerNote =
                score.layers[layer].notesObject.getLastGroup()[0]
            const lastLayerNoteEnd = Time.addTwoFractions(
                lastLayerNote.start,
                lastLayerNote.duration
            )
            const lastNoteEnd = !lastNote
                ? "0"
                : Time.addTwoFractions(lastNote.start, lastNote.duration)

            if (
                !lastNote ||
                Time.compareTwoFractions(lastLayerNoteEnd, lastNoteEnd) === "gt"
            ) {
                lastNote = lastLayerNote
            }
        }

        return lastNote
    }

    // !! untested and WIP
    static getKeySignatureFromPattern(pattern: ReducedAccompanimentPatternME) {
        let key
        let mode
        let rootPitch
        let pitches: number[] = []
        let rootPitches: number[] = []

        for (let p of pattern.pattern) {
            pitches = pitches.concat(p.pitch)
        }

        pitches = [...new Set(pitches)].sort()

        if (pitches.length === 0) {
            return "C major"
        }

        if (pitches.length === 1) {
            key = Note.getNoteString(pitches[0]).replace(/[0-9]/g, "")
            mode = "major"

            return key + " " + mode
        }

        // Detect the mode
        for (let p = 0; p < pitches.length; p++) {
            if (p + 1 < pitches.length) {
                let pitchGap = pitches[p + 1] - pitches[p]

                if (pitchGap === 12) {
                    continue
                } else if (pitchGap % 4 === 0) {
                    mode = "major"
                    break
                } else if (pitchGap % 3 === 0) {
                    mode = "minor"
                    break
                }
            }
        }

        // Detect the root note if possible
        for (let p = 0; p < pitches.length; p++) {
            const rootNoteIndex = pitches.findIndex(p => p === pitches[p] + 12)

            if (rootNoteIndex !== -1) {
                rootPitch = rootPitches.push(pitches[rootNoteIndex])
            }
        }

        rootPitches = [...new Set(rootPitches)]

        // Default to first pitch as root
        if (!rootPitches?.length) {
            key = Note.getNoteString(pitches[0])
        } else {
            key = Note.getNoteString(Math.min(...rootPitches))
        }

        if (!mode) {
            mode = "major"
        }

        return key.replace(/[0-9]/g, "") + " " + mode
    }
}
