import { PercussionPatternController } from "../percussionPattern"
import Channel from "../../classes/score/channel"
import Layer from "../../classes/score/layer"
import { Note } from "../../classes/score/note"
import { Pattern } from "../../classes/score/pattern"
import PatternRegion from "../../classes/score/patternregion"
import PercussionLayer from "../../classes/score/percussionlayer"
import Score from "../../classes/score/score"
import Section from "../../classes/score/section"
import Tempo from "../../classes/score/tempo"
import {
    AUTOMATION_TIMESTEP_RES,
    MIN_TEMPO,
    TIMESTEP_RES,
} from "../../constants/constants"
import { InstrumentsJSON } from "../../interfaces/score/general"
import SamplesMap from "../../interfaces/score/samplesMap"
import {
    TemplateChord,
    TemplateKeySignature,
    TemplateLayer,
    TemplateNote,
    TemplateScore,
    TemplateSections,
    TemplateTempoMap,
    TemplateTrack,
} from "../../interfaces/score/templateScore"
import { FractionString, TimeSignature } from "../../types/score"
import { ScoreManipulation } from "../scoremanipulation"
import { Time } from "../time"
import { RangeWithID } from "../../interfaces/general"
import { ChordManipulation } from "../chord-manipulation.module"
import { KeySignature } from "../../interfaces/score/keySignature"
import { SHORT_SCALES_TO_SCALES_MAP } from "../../utils/composition-workflow.util"
import { CWKeyMode } from "../../interfaces/composition-workflow.interface"
import { KeySignatureModule } from "../keysignature.module"

export module ScoreEncoding {
    export function fromTemplateScore(
        score: Score,
        ts: TemplateScore,
        samplesMap: SamplesMap,
        instrumentReferences: InstrumentsJSON,
        lastSectionDuration: string | undefined,
        args?: {
            tieTrackBusRegions: boolean
            computePhrases: boolean
            computeSustainPedal: boolean
        }
    ) {
        // Fixed for incoming template score, which might containing formatting problems
        const newKs: TemplateKeySignature[] = []

        for (let k = 0; k < ts.keySignatures.length; k++) {
            if (
                k === 0 ||
                Time.compareTwoFractions(ts.keySignatures[k][0], "0") !== "eq"
            ) {
                newKs.push(ts.keySignatures[k])
            }
        }

        ts.keySignatures = newKs

        if (ts.tempoMap.some(t => t[0].includes("NaN"))) {
            ts.tempoMap = [["0", ts.tempoMap[0][1]]]
        }

        if (ts.tempoMap.length === 0) {
            ts.tempoMap.push(["0", 120])
        }

        // end fix

        try {
            score.type = ts.type
            score.hasStartOffset = ts.hasStartOffset
            score.keySignatures = ts.keySignatures
            score.timeSignatures = ts.timeSignatures

            encodeLayers(score, ts)

            score.tempoMap = encodeTempoMap(ts.tempoMap)
            score.sections = encodeSections(ts.sections)

            setSectionEnds(
                score.scoreLength,
                score.sections,
                score.firstTimeSignature,
                lastSectionDuration
            )

            score.compositionID = ts.compositionID
            score.sustainPedal = ts.sustainPedal
            score.effects = ts.effects

            getNotes(score, ts, instrumentReferences) // handles adding Layers and TrackBus as well

            if (args === undefined || args.tieTrackBusRegions === true) {
                tieTrackBusRegions(score)
            }

            autoEnableSustain(score)

            getAutomation(score, ts.tracks)

            getLayerDrumChannels(score)

            computeAutomationUntilTrackEnd(score)

            getPatterns(score, ts, samplesMap)

            score.scoreLength = getScoreLengthFromPatternRegions(score)

            const [chords, romanNumerals] = encodeChords(
                ts.chords,
                score.timeSignatures[0][1],
                score.keySignatures,
                score.scoreLength
            )

            score.chords = chords
            score.romanNumerals = romanNumerals as TemplateChord[]

            setSectionEnds(
                score.scoreLength,
                score.sections,
                score.firstTimeSignature,
                lastSectionDuration
            )

            if (args === undefined || args.computePhrases === true) {
                for (var layer in score.layers) {
                    score.computePhrases(score.layers[layer])
                }
            }

            if (args === undefined || args.computeSustainPedal === true) {
                score.sustainPedal =
                    ScoreManipulation.computeSustainPedalWithHeuristic(
                        score,
                        false
                    )
            }
        } catch (error) {
            console.error("Error while initialising MIDI")
            console.error(error)
        }
    }

    function tieTrackBusRegions(score: Score) {
        for (const layer in score.layers) {
            const layerObject = score.layers[layer]

            for (const tb of layerObject.trackBuses) {
                tb.blocks = Score.tieCloseBlocks(
                    layerObject.notesObject,
                    tb.blocks,
                    score.timeSignatures[0][1]
                )
            }
        }
    }

    function autoEnableSustain(score: Score) {
        for (const layer in score.layers) {
            const layerObject = score.layers[layer]

            for (const tb of layerObject.trackBuses) {
                if (ScoreManipulation.isKeyboard(tb.name, tb.reference)) {
                    tb.autoPedal = true
                }
            }
        }
    }

    function getScoreLengthFromPatternRegions(score: Score): FractionString {
        let scoreLength = score.scoreLength

        for (const layer in score.layers) {
            if (score.layers[layer].type !== "percussion") {
                continue
            }

            const pr = (<PercussionLayer>(
                score.layers[layer]
            )).patternRegions.sort((a, b) => {
                return Time.compareTwoFractions(a.start, b.start) === "gt"
                    ? 1
                    : -1
            })

            if (pr.length === 0) {
                continue
            }

            const prLength = pr[pr.length - 1].getEnd()

            scoreLength = Time.max(prLength, scoreLength)
        }

        return scoreLength
    }

    function getPatterns(
        score: Score,
        templateScore: TemplateScore,
        samplesMap: SamplesMap
    ) {
        for (var layer in templateScore.layers) {
            if (!layer.includes("Percussion")) {
                continue
            }

            if (templateScore.layers[layer].patterns == null) {
                templateScore =
                    PercussionPatternController.createPatternsFromScore(
                        templateScore,
                        samplesMap
                    )
            }

            for (
                var p = 0;
                p < templateScore.layers[layer].patterns.length;
                p++
            ) {
                var object = templateScore.layers[layer].patterns[p]
                var pattern = new Pattern(object.id)

                for (var key in object) {
                    if (key == "channels") {
                        for (var c = 0; c < object["channels"].length; c++) {
                            let selectedTrackBus: any = undefined

                            if (object["channels"][c]["track"] == null) {
                                continue
                            }

                            for (var trackBus of score.trackBusses) {
                                if (
                                    trackBus.trackIDs.includes(
                                        object["channels"][c]["track"] + ""
                                    )
                                ) {
                                    selectedTrackBus = trackBus

                                    break
                                }
                            }

                            if (selectedTrackBus === undefined) {
                                const silentTrackBus = score.layers[
                                    layer
                                ].trackBuses.find(t =>
                                    t.name.includes("silent-kit")
                                )

                                if (!silentTrackBus) {
                                    continue
                                }

                                selectedTrackBus = silentTrackBus
                            }

                            let channel = new Channel(
                                object["channels"][c]["name"],
                                object["channels"][c]["pitches"],
                                object["channels"][c]["onsets"],
                                object["channels"][c]["mute"],
                                object["channels"][c]["solo"],
                                selectedTrackBus
                            )

                            pattern.channels.push(channel)
                        }
                    } else {
                        pattern[key] = object[key]
                    }
                }

                ;(<PercussionLayer>score.layers[layer]).patterns.push(pattern)
            }

            for (const patternRegionObject of templateScore.layers[layer]
                .pattern_regions) {
                const percussionLayer = score.layers[layer]

                if (!(percussionLayer instanceof PercussionLayer)) {
                    continue
                }

                const region = PatternRegion.fromTemplateTrack(
                    patternRegionObject,
                    percussionLayer.patterns
                )

                if (region !== undefined) {
                    percussionLayer.patternRegions.push(region)
                }
            }
        }
    }

    function computeAutomationUntilTrackEnd(score: Score) {
        for (var layer in score.layers) {
            for (var effect in score.layers[layer].effects) {
                const effectObject = score.layers[layer].effects[effect]

                const endOfTrack = Time.quantizeFraction(
                    score.scoreLength,
                    "round",
                    AUTOMATION_TIMESTEP_RES
                )

                for (
                    var s = effectObject.values.length - 1;
                    s <= endOfTrack;
                    s++
                ) {
                    if (
                        effectObject.name == "dynamic" ||
                        effectObject.name == "reverb" ||
                        effectObject.name == "delay"
                    ) {
                        effectObject.values[s] =
                            effectObject.values[effectObject.values.length - 1]
                    } else {
                        effectObject.values[s] = 0
                    }
                }
            }
        }
    }

    function getLayerDrumChannels(score: Score) {
        for (let layer in score.layers) {
            score.layers[layer].initDrumChannels()
        }
    }

    function getAutomation(score: Score, tracks: TemplateTrack[]) {
        for (var layer in score.layers) {
            score.layers[layer].initAutomation(score.scoreLength)
        }

        var automations = score.gatherAutomationsData(tracks)

        for (var automation in automations) {
            automations[automation] = score.interpolateAutomationForLayers(
                automations[automation]
            )
            automations[automation] = score.cleanupAutomation(
                automations[automation]
            )
        }

        for (var label in automations) {
            for (var layer in automations[label]) {
                score.layers[layer].effects[label].values =
                    automations[label][layer]
            }
        }
    }

    function getNotes(
        score: Score,
        templateScore: TemplateScore,
        instrumentReferences
    ) {
        for (let track of templateScore.tracks) {
            const gainOffset = track.gain_offset != null ? track.gain_offset : 0
            const panning = track.panning != null ? track.panning : 0
            const mute = track.mute != null ? track.mute : false
            const solo = track.solo != null ? track.solo : false
            const section = track.instrument.split(".")[0]
            const dynamicOffset =
                track.dynamic_offset != null ? track.dynamic_offset : 0

            let blocks: RangeWithID[] = []
            let scoreLength = score.scoreLength

            const orderedNotes: TemplateNote[] = <TemplateNote[]>(
                ScoreManipulation.sortNotes(track.track)
            )

            if (orderedNotes.length == 0) {
                continue
            }

            let layer =
                track.layer != null ? track.layer : track.track[0].meta.layer

            if (layer.includes("Percussion")) {
                if (track.track.length > 0) {
                    score.addTrackBus(
                        track.id + "",
                        track.name,
                        score.layers[layer],
                        track.octave,
                        instrumentReferences,
                        blocks,
                        dynamicOffset,
                        gainOffset,
                        track.breathing_gain,
                        panning,
                        mute,
                        solo,
                        track.auto_pedal,
                        track.pack_db_columns
                    )
                }

                continue
            }

            for (let note of orderedNotes) {
                note.meta = score.handleEmptyMeta(note.meta, track.layer)

                let noteTS = Time.fractionToTimesteps(TIMESTEP_RES, note.start)
                const endTS = Time.fractionToTimesteps(
                    TIMESTEP_RES,
                    Time.addTwoFractions(note.start, note.duration)
                )

                note.meta.section = Note.getSectionForNoteStart(
                    score.sections,
                    note.start
                )

                const sectionTS = Time.fractionToTimesteps(
                    TIMESTEP_RES,
                    score.sections[note.meta.section].start
                )

                if (sectionTS > noteTS) {
                    noteTS = sectionTS
                }

                if (layer != note.meta.layer) {
                    score.addTrackBus(
                        track.id,
                        track.name,
                        score.layers[layer],
                        track.octave,
                        instrumentReferences,
                        blocks,
                        dynamicOffset,
                        gainOffset,
                        track.breathing_gain,
                        panning,
                        mute,
                        solo,
                        track.auto_pedal,
                        track.pack_db_columns
                    )

                    blocks = []
                }

                Note.encode(
                    score.timeSignatures[0][1],
                    score.sections,
                    score.layers[layer],
                    note
                )

                const noteEnd = Time.addTwoFractions(note.start, note.duration)

                if (
                    Time.compareTwoFractions(score.scoreLength, noteEnd) ===
                    "lt"
                ) {
                    scoreLength = noteEnd
                }

                if (endTS - noteTS <= 0) {
                    blocks = Score.modifyTrackBusRegions(
                        noteTS,
                        noteTS,
                        blocks,
                        "add"
                    )
                } else {
                    blocks = Score.modifyTrackBusRegions(
                        noteTS,
                        endTS,
                        blocks,
                        "add"
                    )
                }
            }

            var autoStaccato = true

            if (score.layers[layer].effects.auto_staccato.active != null) {
                autoStaccato = score.layers[layer].effects.auto_staccato.active
            } else {
                autoStaccato =
                    track.auto_staccato || track.auto_staccato == null
            }

            score.layers[layer].effects.auto_staccato.active = autoStaccato

            const res = score.addTrackBus(
                track.id,
                track.name,
                score.layers[layer],
                track.octave,
                instrumentReferences,
                blocks,
                dynamicOffset,
                gainOffset,
                track.breathing_gain,
                panning,
                mute,
                solo,
                track.auto_pedal,
                track.pack_db_columns
            )
            score.scoreLength = scoreLength
        }
    }

    function setSectionEnds(
        scoreLength: FractionString,
        sections: Section[],
        timeSignature: TimeSignature,
        lastSectionDuration: string | undefined
    ) {
        for (var s = 0; s < sections.length; s++) {
            if (s + 1 < sections.length) {
                sections[s].setEnd(sections[s + 1].start)
            } else {
                let end = Time.max(
                    Time.addTwoFractions(
                        sections[s].start,
                        Time.measuresToFraction(4, timeSignature)
                    ),
                    Time.quantizeFractionToString(
                        Time.max(sections[s].start, scoreLength),
                        "ceil",
                        1
                    )
                )

                if (lastSectionDuration !== undefined) {
                    end = Time.addTwoFractions(
                        sections[s].start,
                        lastSectionDuration
                    )
                }

                sections[s].setEnd(end)
            }
        }
    }

    function encodeLastNote(templateScore: TemplateScore) {
        let lastNote = { start: "0", duration: "0" }

        for (let track of templateScore.tracks) {
            const notes: TemplateNote[] = track.track

            if (!notes.length) {
                continue
            }

            const lastNoteFromTrack = notes[notes.length - 1]
            if (
                Time.compareTwoFractions(
                    lastNote.start,
                    lastNoteFromTrack.start
                ) === "lt"
            ) {
                lastNote = lastNoteFromTrack
            }
        }

        return lastNote
    }

    function encodeSections(sections: TemplateSections[]): Section[] {
        if (sections.length == 0) {
            sections = [["0", "A"]]
        }

        let newSections: Section[] = []

        sections.sort((a, b) => {
            return Time.compareTwoFractions(a[0], b[0]) === "gt" ? 1 : -1
        })

        for (let i = 0; i < sections.length; i++) {
            const section = new Section(sections[i][0], sections[i][1], i)

            newSections.push(section)
        }

        return newSections
    }

    function encodeLayers(score: Score, ts: TemplateScore) {
        for (let layerKey in ts.layers) {
            const layer: TemplateLayer = ts.layers[layerKey]

            const newLayerObject = handleMissingLayer(score, layer, ts)

            if (newLayerObject) {
                score.layers[layerKey] = newLayerObject
            }
        }
    }

    function encodeChords(
        chords: TemplateChord[],
        timeSignature: TimeSignature,
        keySignatures: TemplateKeySignature[],
        scoreLength: string
    ) {
        if (chords == null) {
            return []
        }

        let newChords: TemplateChord[] = []

        for (let c = 0; c < chords.length; c++) {
            if (c == 0) {
                newChords.push(chords[c])
            } else if (chords[c][1] != "NC") {
                newChords.push(chords[c])
            }
        }

        let start = "0"
        const intermediateKS =
            KeySignatureModule.fromKeySignaturesToIntermediateRepresentation(
                scoreLength,
                keySignatures
            )

        newChords.forEach(chord => {
            const end = Time.addTwoFractions(start, chord[0])

            const ksForChord = ChordManipulation.getKeySignaturesForChord(
                {
                    start: start,
                    end: end,
                    data: chord[1],
                },
                intermediateKS
            )

            start = end

            if (chord[1] === "" || chord[1] === "NC") {
                const root = ksForChord[0].ks.split(" ")[0]
                const mode = ksForChord[0].ks.split(" ")[1]

                chord[1] = mode.includes("min") ? root + "m" : root
            }
        })

        newChords = ChordManipulation.applyMusicEngineChordRestrictions(
            newChords,
            timeSignature,
            "editor"
        )

        const romanNumerals =
            ChordManipulation.convertChordSymbolsToRomanNumerals(
                newChords,
                keySignatures
            )

        newChords.forEach(chord => {
            const duration = chord[0] === "1/1" ? "1" : chord[0]
            chord[0] = duration
        })

        return [newChords, romanNumerals]
    }

    function handleMissingLayer(
        score: Score,
        layer: TemplateLayer,
        templateScore: TemplateScore
    ): Layer | PercussionLayer | undefined {
        if (!layer) {
            return undefined
        }

        const type = (templateScore.layers != null ? layer.type : "pitched") as
            | "pitched"
            | "percussion"

        if (type === "percussion") {
            return new PercussionLayer(
                "percussion",
                layer.value,
                layer.name,
                layer.color,
                layer.effects,
                score.timeSignatures[0][1],
                [],
                [],
                null,
                layer.gain_bias
            )
        } else {
            return new Layer(
                type,
                layer.value,
                layer.name,
                layer.color,
                layer.effects,
                score.timeSignatures[0][1],
                layer.gain_bias
            )
        }
    }

    export function encodeTempoMap(templateTempo: TemplateTempoMap[]): Tempo[] {
        templateTempo = templateTempo.filter(
            t =>
                Time.compareTwoFractions(t[0], "0") !== "lt" &&
                t[1] >= MIN_TEMPO
        )

        // Number of timesteps in one beat (since BPM in midi means # of quarter notes per minute,
        // we use 4 as the beat resolution)
        const numberOfTimestepsPerBeat = TIMESTEP_RES / 4

        let seconds = 0

        const tempoMap = [new Tempo("0", 0, 0, templateTempo[0][1])]

        for (let b = 0; b < templateTempo.length; b++) {
            const bpm = templateTempo[b]

            if (bpm[0] == "0") {
                continue
            }

            const previousBPM = tempoMap[tempoMap.length - 1]

            const beatsPerSecond = previousBPM.bpm / 60
            const bpmTimesteps = Time.fractionToTimesteps(TIMESTEP_RES, bpm[0])

            let beatIncrement = bpmTimesteps

            if (b > 0) {
                beatIncrement = bpmTimesteps - previousBPM.timesteps
                seconds = previousBPM.seconds
            }

            seconds =
                seconds +
                beatIncrement / numberOfTimestepsPerBeat / beatsPerSecond

            const tempo = new Tempo(bpm[0], bpmTimesteps, seconds, bpm[1])

            previousBPM.endInFractions = tempo.fraction

            tempoMap.push(tempo)
        }

        return tempoMap
    }
}
