import {
    fromEvent,
    Observable,
    filter,
    Subject,
    takeUntil,
    throttleTime,
    debounceTime,
    map,
} from "rxjs"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    Coordinates,
    EventHandlers,
} from "../../../general/modules/event-handlers"
import { ScoreRenderingEngineMisc } from "../score-rendering-engine-misc.module"
import {
    HoveredElement,
    ScoreCanvasContext,
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    GridDimensions,
    RemoveLastGutter,
    CanvasType,
    GridDimensionsDictionary,
    MouseDownStart,
    GridCoordinates,
    AllCanvasCoordinates,
    MouseAction,
    NoteDescriptor,
    Chord,
    Scrollbar,
} from "../types"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { cloneDeep } from "lodash"
import Layer from "../../../general/classes/score/layer"
import { Color } from "../../../general/modules/color"
import { GridPosition, Time } from "../../../general/modules/time"
import {
    PIANOROLL_GRID_SECTION_HEIGHT,
    PIANOROLL_GRID_SECTION_TOP_OFFSET,
    TIMESTEP_RES,
    SECTION_EDITING,
} from "../../../general/constants/constants"
import { Note } from "../../../general/classes/score/note"
import {
    QuantizationThresholds,
    RangeWithID,
} from "../../../general/interfaces/general"
import {
    HoveringTypeEnum,
    HoveringType,
    PatternHoveringType,
} from "../../../general/types/general"
import { FractionString } from "../../../general/types/score"
import { SRActionTypes } from "../states/score-rendering/score-rendering.actions"
import { EmitterType } from "../../../general/classes/actionsManager"
import PatternRegion from "../../../general/classes/score/patternregion"
import { ScoreManipulation } from "../../../general/modules/scoremanipulation"
import Section from "../../../general/classes/score/section"
import Score from "../../../general/classes/score/score"
import { featureFlags } from "../../../general/utils/feature-flags"
import { Fraction } from "../../../general/classes/score/fraction"

export const CHORD_FONT_SIZE_PERCENTAGE = 80
interface IterateThroughTimesteps {
    barTimesteps: number
    halfBeatTimesteps: number // timesteps for a single half beat
    beat: number
    halfBeat: number
    bar: number
    functionBinding
    skipTimesteps: boolean
    skipBeatsteps: boolean
    skipHalfBeatsteps: boolean
    boundaries: {
        start: number
        end: number
        offsetX: number
    }
    previousTimesteps: number
}
export interface ScoreCanvasElement {
    idSuffix: string
    zIndex: number
}

export interface GetHoveringType {
    (grid: GridCoordinates):
        | {
              element: Note | RangeWithID | PatternRegion | Chord | Scrollbar
              type: HoveringType | PatternHoveringType
          }
        | undefined
}
export default class ScoreCanvas {
    static DEFAULT_THROTTLE_TIME = 10

    protected _grid: GridDimensionsDictionary

    protected get grid(): GridDimensions {
        return this._grid[this.gridType]
    }

    public get gridType() {
        return this.context?.gridType || "pitched"
    }

    protected get cursorType() {
        return this.queries?.editorView.cursorType
    }

    protected scrollXOffset: number = 0

    protected canvas: HTMLCanvasElement
    protected canvases: {
        [canvasId: string]: HTMLCanvasElement
    } = {}
    protected destroyObservable = new Subject()
    protected syncedCanvas: ScoreCanvas

    protected mouseDown$: Observable<Coordinates>
    protected click$: Observable<Coordinates>
    protected mouseUp$: Observable<Coordinates>
    protected mouseMove$: Observable<Coordinates>
    protected doubleClick$: Observable<Coordinates>
    protected contextMenu$: Observable<Coordinates>
    protected hoveredDetection$: Observable<Coordinates>
    protected scroll$: Observable<Event>
    protected resizeWindow$: Observable<Event>

    protected resize$: Subject<Coordinates> = new Subject()
    protected move$: Subject<Coordinates> = new Subject()
    protected selection$: Subject<Coordinates> = new Subject()
    protected loop$: Subject<Coordinates> = new Subject()

    protected hoveredElement: HoveredElement | undefined = undefined
    protected srEmitter$: Subject<EmitterType<SRActionTypes>>

    /**
     * keeps track of the initial state of the mouse coordinates and other data that
     * is needed
     */

    protected _mouseDownStart: MouseDownStart | undefined
    protected drawNoteDuration: FractionString

    protected _pxPerYStep: number = 0
    protected _pxPerTimestep: number = 0
    protected scrollToTimestep: number = 0
    protected scrollToPitchsteps: number = 0
    protected patternScrollToTimestep: number = 0
    protected _noteRes: number
    protected nbOfYValues: number = 0
    protected highestPitch: number = Note.highestNote

    public resizeFactor: number = 5

    private _width: number = 0
    private _height: number = 0

    private initialWidth: number = 0
    private initialHeight: number = 0

    private readonly noteSelectionArea: HTMLDivElement
    private readonly noteDescriptor: HTMLSpanElement

    private browserWindowWidth: number = 0

    private get scoreRenderingState() {
        return this.queries["scoreRendering"]?.getValue()
    }

    public get mouseDownStart() {
        return this._mouseDownStart
    }

    // controls the visible note selection rectangle
    protected get noteSelection$() {
        return this.queries["editorView"].allChanges$.pipe(
            throttleTime(0),
            filter(
                states =>
                    states[1].toggledLayer !== undefined &&
                    (!states[0].noteSelection ||
                        (states[0].noteSelection &&
                            states[0].noteSelection?.type === this.type))
            ),
            map(states => {
                return states[0]?.noteSelection
            }),
            takeUntil(this.destroyObservable)
        )
    }

    // controls the visible note metadata that is shown when interacting with a note
    protected get noteDescriptor$() {
        return this.queries["editorView"].allChanges$.pipe(
            throttleTime(0),
            filter(
                states =>
                    states[1].toggledLayer !== undefined &&
                    (!states[0].noteDescriptor ||
                        (states[0].noteDescriptor &&
                            states[0].noteDescriptor?.type === this.type))
            ),
            map(states => {
                return states[0]?.noteDescriptor
            }),
            takeUntil(this.destroyObservable)
        )
    }

    protected get cursorType$() {
        return this.queries["editorView"].cursorType$.pipe(
            throttleTime(0),
            takeUntil(this.destroyObservable)
        )
    }

    constructor(
        public context: ScoreCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string // type is a string only in the case of a DrumSequencerCanvas
    ) {
        this.context = cloneDeep(context)

        this.createCanvases()
        this.noteSelectionArea = this.createNoteSelection()
        this.noteDescriptor = this.createNoteDescriptor()

        this.adjustCanvasDimensions()

        this.initEventFlow()
        this.srEmitter$ = actions.scoreRendering.manager.emitter$
    }

    protected createCanvases() {
        const canvases = (this.context as any).canvases

        const mainCanvasIdSuffix = canvases?.length
            ? canvases[0].idSuffix
            : "canvas"

        this.canvas = this.createCanvas("-" + mainCanvasIdSuffix, 0)
        this.context.canvasContainer.appendChild(this.canvas)
        this.canvases[mainCanvasIdSuffix] = this.canvas

        this.initialWidth = this.canvas.width
        this.initialHeight = this.canvas.height

        if (!canvases) {
            return
        }

        for (let c = 0; c < canvases.length; c++) {
            if (c === 0) {
                continue
            }

            const suffix = canvases[c].idSuffix
            const zIndex = canvases[c].zIndex

            const canvas = this.createCanvas("-" + suffix, zIndex)
            this.canvases[suffix] = canvas
            this.context.canvasContainer.appendChild(canvas)
        }
    }

    protected shouldRenderCanvas(renderingTypes: (CanvasType | string)[]) {
        const hasType = renderingTypes.find((type: CanvasType | string) =>
            this.type.includes(type)
        )

        return (
            renderingTypes.length === 0 ||
            renderingTypes.includes("All") ||
            (!renderingTypes.includes("None") && hasType !== undefined)
        )
    }

    protected createCanvas(
        idSuffix: string,
        zIndex: number
    ): HTMLCanvasElement {
        this.context.canvasContainer.style.position = "relative"

        const canvas = document.createElement("canvas")
        canvas.id = this.context.canvasContainer.id + idSuffix
        canvas.width = this.context.canvasContainer.offsetWidth
        canvas.height = this.context.canvasContainer.offsetHeight
        canvas.style.position = "absolute"
        canvas.style.left = "0px"
        canvas.style.zIndex = zIndex + ""

        return canvas
    }

    protected createNoteSelection() {
        this.context.canvasContainer.style.position = "relative"

        const noteSelection = document.createElement("div")
        noteSelection.style.display = "none"
        noteSelection.id =
            "note-selection-area " + this.context.canvasContainer.id
        noteSelection.className = "note-selection-area"
        noteSelection.style.position = "fixed"
        noteSelection.style.backgroundColor = "#73d1ff75"
        noteSelection.style.border = "1px solid #dbf2ff57"
        noteSelection.style.zIndex = "9999999999999"

        this.context.canvasContainer.appendChild(noteSelection)

        return noteSelection
    }

    protected updateNoteSelection(args?: {
        display: "block" | "none"
        top: number
        left: number
        width: number
        height: number
    }) {
        const noteSelection = document.getElementById(
            "note-selection-area " + this.context.canvasContainer.id
        )

        if (!noteSelection) {
            return
        }

        if (!args) {
            noteSelection.style.display = "none"
        }

        noteSelection.style.display = args?.display
        noteSelection.style.top = args?.top + "px"
        noteSelection.style.left = args?.left + "px"
        noteSelection.style.width = args?.width + "px"
        noteSelection.style.height = args?.height + "px"
    }

    protected createNoteDescriptor() {
        this.context.canvasContainer.style.position = "relative"

        const noteDescriptor = this.createElement({
            type: "div",
            id: "note-descriptor " + this.context.canvasContainer.id,
            className: "note-descriptor",
        })

        noteDescriptor.style.display = "none"
        noteDescriptor.style.position = "fixed"

        const pitch = this.createElement({
            type: "span",
            innerHTML: "Pitch: <br>",
            id: "note-descriptor-pitch",
            className: "note-descriptor-pitch",
        })

        const duration = this.createElement({
            type: "span",
            innerHTML: "Note duration: <br>",
            id: "note-descriptor-duration",
            className: "note-descriptor-duration",
        })

        noteDescriptor.appendChild(pitch)
        noteDescriptor.appendChild(duration)

        if (this.context.debugMode === true) {
            const section = this.createElement({
                type: "span",
                innerHTML: "<br>Section: ",
                id: "note-descriptor-section",
                className: "note-descriptor-section",
            })

            noteDescriptor.appendChild(section)
        }

        this.context.canvasContainer.appendChild(noteDescriptor)

        return noteDescriptor
    }

    protected updateNoteDescriptor(args?: {
        display: "block" | "none"
        noteDescriptor: NoteDescriptor
    }) {
        const noteDescriptor = document.getElementById(
            "note-descriptor " + this.context.canvasContainer.id
        )

        if (!noteDescriptor) {
            return
        }

        if (!args) {
            noteDescriptor.style.display = "none"
        }

        noteDescriptor.style.display = args?.display
        noteDescriptor.style.top = args?.noteDescriptor.coordinates?.y + "px"
        noteDescriptor.style.left = args?.noteDescriptor.coordinates.x + "px"

        const pitch = noteDescriptor.getElementsByClassName(
            "note-descriptor-pitch"
        )[0]
        const duration = noteDescriptor.getElementsByClassName(
            "note-descriptor-duration"
        )[0]

        pitch.innerHTML = `Pitch: ${args?.noteDescriptor?.pitch.name}<br>`
        duration.innerHTML = `Note duration: ${args?.noteDescriptor?.duration}<br>`

        if (this.context.debugMode === true) {
            const section = noteDescriptor.getElementsByClassName(
                "note-descriptor-section"
            )[0]
            section.innerHTML = `<br>Section: ${args?.noteDescriptor?.section}<br>`
        }
    }

    protected setHoveredElement(hoveredElement: HoveredElement | undefined) {
        this.hoveredElement = hoveredElement
    }

    private initEventFlow() {
        this.mouseDown$ = EventHandlers.getMouseEventObservable(
            "mousedown",
            this.context.canvasContainer,
            0,
            this.destroyObservable,
            this.context.canvasContainer
        )

        this.mouseUp$ = EventHandlers.getMouseEventObservable(
            "mouseup",
            document,
            0,
            this.destroyObservable,
            this.context.canvasContainer
        )

        this.mouseMove$ = EventHandlers.getMouseEventObservable(
            "mousemove",
            document,
            ScoreCanvas.DEFAULT_THROTTLE_TIME,
            this.destroyObservable,
            this.context.canvasContainer
        )

        this.hoveredDetection$ = EventHandlers.getMouseEventObservable(
            "mousemove",
            this.context.canvasContainer,
            ScoreCanvas.DEFAULT_THROTTLE_TIME,
            this.destroyObservable,
            this.context.canvasContainer
        )

        this.click$ = EventHandlers.getMouseEventObservable(
            "click",
            this.context.canvasContainer,
            0,
            this.destroyObservable,
            this.context.canvasContainer
        )

        this.contextMenu$ = EventHandlers.getMouseEventObservable(
            "contextmenu",
            this.context.canvasContainer,
            0,
            this.destroyObservable,
            this.context.canvasContainer
        )

        this.doubleClick$ = EventHandlers.getMouseEventObservable(
            "dblclick",
            this.context.canvasContainer,
            0,
            this.destroyObservable,
            this.context.canvasContainer
        )

        this.scroll$ = fromEvent<WheelEvent>(
            this.context.canvasContainer,
            "wheel",
            { capture: true }
        ).pipe(
            map(event => {
                if (event.ctrlKey) {
                    event.preventDefault()
                    event.stopPropagation()
                }

                if (!this.yScrollingIsEnabled()) {
                    return event
                }

                event.preventDefault()
                event.stopPropagation()

                return event
            }),
            throttleTime(ScoreCanvas.DEFAULT_THROTTLE_TIME),
            takeUntil(this.destroyObservable)
        )

        this.scroll$.subscribe(this.scroll.bind(this))

        this.mouseMove$.subscribe((event: Coordinates) => {
            const editMode = this.getEditMode()

            // console.log("mouseMove", editMode)

            const mouseAction: MouseAction =
                this.context.mouseActions[this.cursorType]

            if (mouseAction.draw && mouseAction.selection) {
                console.warn(
                    "Mouse action has both draw and select set to true. This is not recommended."
                )
            }

            if (editMode === "move" && mouseAction.move) {
                this.move$.next(event)
            } else if (editMode === "resize" && mouseAction.resize) {
                this.resize$.next(event)
            } else if (editMode === "selection" && mouseAction.selection) {
                this.selection$.next(event)
            } else if (editMode === "loop" && mouseAction.resize) {
                this.loop$.next(event)
            }
        })

        this.resizeWindow$ = fromEvent(window, "resize").pipe(
            debounceTime(150),
            takeUntil(this.destroyObservable)
        )

        this.mouseUp$.subscribe(this.mouseUpHandler.bind(this))

        this.noteSelection$.subscribe(noteSelection => {
            if (!noteSelection || noteSelection?.type !== this.type) {
                return this.updateNoteSelection()
            }

            this.updateNoteSelection({
                display: "block",
                top: noteSelection.dimensions.top,
                left: noteSelection.dimensions.left,
                width: noteSelection.dimensions.width,
                height: noteSelection.dimensions.height,
            })
        })

        this.cursorType$.subscribe(cursorType => {
            const cursorStyle = cursorType === "pencil" ? "pencil" : "default"

            this.setCanvasCursor(cursorStyle)
        })
    }

    protected subscribeToNoteDescriptor() {
        this.noteDescriptor$.subscribe(noteDescriptor => {
            if (!noteDescriptor || noteDescriptor?.type !== this.type) {
                return this.updateNoteDescriptor()
            }

            this.updateNoteDescriptor({
                display: "block",
                noteDescriptor: noteDescriptor,
            })
        })
    }

    protected mouseUpHandler(event: Coordinates) {
        this._mouseDownStart = undefined
        this.setHoveredElement(undefined)
        this.actions.editorView.setNoteSelection(undefined)
        this.actions.editorView.setNoteDescriptor(undefined)
    }

    protected getEditMode() {
        if (
            this.hoveredElement?.draggingType === HoveringTypeEnum.CENTER ||
            this.hoveredElement?.draggingType === PatternHoveringType.CENTER
        ) {
            return "move"
        } else if (
            this.hoveredElement?.draggingType === HoveringTypeEnum.LEFT ||
            this.hoveredElement?.draggingType === HoveringTypeEnum.RIGHT ||
            this.hoveredElement?.draggingType ===
                PatternHoveringType.BOTTOM_RIGHT
        ) {
            return "resize"
        } else if (
            this.hoveredElement?.draggingType === PatternHoveringType.TOP_RIGHT
        ) {
            return "loop"
        } else if (!this.hoveredElement) {
            return "selection"
        }

        return undefined
    }

    static convertPxValueForTSRes(
        pxValue: number,
        originalTSRes: number,
        newTSRes: number
    ): number {
        return (pxValue * (1 / newTSRes)) / (1 / originalTSRes)
    }

    protected yScrollingIsEnabled() {
        return (
            (this.type === "AccompanimentDesignerCanvas" ||
                this.type === "VerticalScrollingCanvas") &&
            this.queries.editorView.accompanimentDesignerIsFocused
        )
    }

    protected scroll(event: WheelEvent) {
        if (event.ctrlKey || event.metaKey) {
            return this.srEmitter$.next({
                type: SRActionTypes.setResizeFactor,
                data: {
                    resizeFactor:
                        this.scoreRenderingState.resizeFactor - event.deltaY,
                },
            })
        }

        const deltaX = event.deltaX
        const deltaY = this.yScrollingIsEnabled() ? event.deltaY : 0

        if (!this.yScrollingIsEnabled() && event.deltaX === 0) {
            return
        }

        const scrollToTimestep: number =
            this.queries.scoreRendering.scrollToTimestep + deltaX

        const scrollToPitchsteps: number =
            this.queries.scoreRendering.scrollToPitchsteps +
            Math.max(-6, Math.min(6, (1 * deltaY) / 10))

        this.srEmitter$.next({
            type: SRActionTypes.setScroll,
            data: {
                scrollToTimestep,
                scrollToPitchsteps,
                width: this.width,
            },
        })
    }

    protected toggledLayerExists() {
        return this.scoreRenderingState?.toggledLayer !== undefined
    }

    public cleanupCanvas() {
        this.destroyObservable.next(undefined)
        this.destroyObservable.complete()

        for (const canvas in this.canvases) {
            this.context.canvasContainer.removeChild(this.canvases[canvas])
        }

        this.context.canvasContainer.removeChild(this.noteSelectionArea)
    }

    /**
     * we only allow certain keys to be passed to the observable
     * this method is used to filter all the key events that are not of our interest
     * and should be handled elsewhere
     * @param key key value
     * @returns
     */
    private isValidKey(key: string) {
        const keyboardKeysToListenTo = [
            "backspace",
            "delete",
            "arrowUp",
            "arrowDown",
            "x",
            "c",
            "v",
            "z",
        ]

        return keyboardKeysToListenTo.includes(key.toLowerCase())
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {}

    static sectionToCoordinates(
        section: Section,
        grid: GridDimensions,
        scrollToTimestep: number,
        pxPerTimestep,
        timestepRes: number
    ) {
        const start = Time.fractionToTimesteps(grid.timestepRes, section.start)

        const end = Time.fractionToTimesteps(grid.timestepRes, section.end)
        const x =
            ScoreCanvas.getPixelsForTimesteps({
                timesteps: start,
                removeLastGutterPx: "none",
                timestepRes: grid.timestepRes,
                grid: grid,
            }) -
            ScoreCanvas.getScrollXOffset({
                scrollToTimestep: scrollToTimestep,
                grid: grid,
                pxPerTimestep: pxPerTimestep,
                noteRes: timestepRes,
            })

        const y = PIANOROLL_GRID_SECTION_TOP_OFFSET

        const width = ScoreCanvas.getPixelsForTimesteps({
            timesteps: end - start,
            removeLastGutterPx: "fully",
            timestepRes: grid.timestepRes,
            grid: grid,
        })

        return {
            x,
            y,
            width,
            height: PIANOROLL_GRID_SECTION_HEIGHT,
        }
    }

    public static getSectionCoordinatesAfter(
        section: Section,
        grid: GridDimensions,
        scrollToTimestep: number,
        pxPerTimestep,
        timestepRes: number
    ) {
        const start = Time.fractionToTimesteps(grid.timestepRes, section.end)
        const width = ScoreCanvas.getPixelsForTimesteps({
            timesteps: TIMESTEP_RES,
            removeLastGutterPx: "fully",
            timestepRes: grid.timestepRes,
            grid: grid,
        })

        const x =
            ScoreCanvas.getPixelsForTimesteps({
                timesteps: start,
                removeLastGutterPx: "none",
                timestepRes: grid.timestepRes,
                grid: grid,
            }) -
            ScoreCanvas.getScrollXOffset({
                scrollToTimestep: scrollToTimestep,
                grid: grid,
                pxPerTimestep: pxPerTimestep,
                noteRes: timestepRes,
            })

        const y = PIANOROLL_GRID_SECTION_TOP_OFFSET

        return {
            x,
            y,
            width,
            height: PIANOROLL_GRID_SECTION_HEIGHT,
        }
    }

    /**
     * These are constant values for section editing that are declared here for re-use
     */

    private static readonly sectionDeleteColor = "rgba(255,0,0,0.1)"
    private static readonly sectionDeleteText = "This section will be deleted"

    private static readonly sectionReplaceColor = "rgba(255,165,0,0.1)"
    private static readonly sectionReplaceText = "This section will be replaced"

    private static readonly sectionRegenerateColor = "rgba(255,0,151,0.1)"
    private static readonly sectionRegenrateText =
        "This section will be regenerated"

    private static readonly sectionNewColor = "rgba(0,255,34,.1)"
    private static readonly sectionNewText = "New content will be inserted here"

    private static readonly defaultSectionColor = "rgba(255,255,255,0.1)"

    /**
     * This function is responsible for rendering warning messages about the content of section that will be overriden when saving changes.
     * This is part of the section editing feature made available in the piano roll editor component
     */
    static generateSectionOverlays({
        ctx,
        score,
        start,
        end,
        pxPerTimestep,
        grid,
        height,
        scrollToTimestep,
        timestepRes,
    }: {
        ctx: CanvasRenderingContext2D
        score: Score
        start: FractionString
        end: FractionString
        pxPerTimestep: number
        grid: GridDimensions
        height: number
        scrollToTimestep: number
        timestepRes: number
    }): Section[] {
        const sections = ScoreManipulation.getModifiedSectionsInTimeRange(
            score.sections,
            start,
            end
        )

        for (const section of sections) {
            const coordinates = ScoreCanvas.sectionToCoordinates(
                section,
                grid,
                scrollToTimestep,
                pxPerTimestep,
                timestepRes
            )

            let text = ""
            let color = ScoreCanvas.defaultSectionColor

            if (section.operation.type === SECTION_EDITING.DELETE) {
                color = ScoreCanvas.sectionDeleteColor
                text = ScoreCanvas.sectionDeleteText
            } else if (section.operation.type === SECTION_EDITING.REPLACE) {
                color = ScoreCanvas.sectionReplaceColor
                text = ScoreCanvas.sectionReplaceText
            } else if (
                section.operation.type ===
                    SECTION_EDITING.REGENERATE_WITH_SOURCE ||
                section.operation.type === SECTION_EDITING.REGENERATE
            ) {
                text = ScoreCanvas.sectionRegenrateText
                color = ScoreCanvas.sectionRegenerateColor
            } else if (
                section.operation.type === SECTION_EDITING.INSERT_NEW ||
                section.operation.type === SECTION_EDITING.INSERT_COPY ||
                section.operation.type === SECTION_EDITING.INSERT_VARIATION
            ) {
                text = ScoreCanvas.sectionNewText
                color = ScoreCanvas.sectionNewColor
            }

            text = ScoreCanvas.fittingString(ctx, text, coordinates.width)

            ScoreCanvas.generateNoteRect(
                ctx,
                coordinates.x,
                0,
                color,
                0,
                coordinates.width,
                height
            )

            ScoreCanvas.drawText(
                ctx,
                coordinates.x + coordinates.width / 2,
                height / 2,
                text,
                90,
                "",
                "white"
            )
        }

        return sections
    }

    static generateSectionOverlays2({
        ctx,
        score,
        start,
        end,
        pxPerTimestep,
        grid,
        height,
        scrollToTimestep,
        timestepRes,
    }: {
        ctx: CanvasRenderingContext2D
        score: Score
        start: Fraction
        end: Fraction
        pxPerTimestep: number
        grid: GridDimensions
        height: number
        scrollToTimestep: number
        timestepRes: number
    }): Section[] {
        const sections = ScoreManipulation.getModifiedSectionsInTimeRange2(
            score.sections,
            start,
            end
        )

        for (const section of sections) {
            const coordinates = ScoreCanvas.sectionToCoordinates(
                section,
                grid,
                scrollToTimestep,
                pxPerTimestep,
                timestepRes
            )

            let text = ""
            let color = ScoreCanvas.defaultSectionColor

            if (section.operation.type === SECTION_EDITING.DELETE) {
                color = ScoreCanvas.sectionDeleteColor
                text = ScoreCanvas.sectionDeleteText
            } else if (section.operation.type === SECTION_EDITING.REPLACE) {
                color = ScoreCanvas.sectionReplaceColor
                text = ScoreCanvas.sectionReplaceText
            } else if (
                section.operation.type ===
                    SECTION_EDITING.REGENERATE_WITH_SOURCE ||
                section.operation.type === SECTION_EDITING.REGENERATE
            ) {
                text = ScoreCanvas.sectionRegenrateText
                color = ScoreCanvas.sectionRegenerateColor
            } else if (
                section.operation.type === SECTION_EDITING.INSERT_NEW ||
                section.operation.type === SECTION_EDITING.INSERT_COPY ||
                section.operation.type === SECTION_EDITING.INSERT_VARIATION
            ) {
                text = ScoreCanvas.sectionNewText
                color = ScoreCanvas.sectionNewColor
            }

            text = ScoreCanvas.fittingString(ctx, text, coordinates.width)

            ScoreCanvas.generateNoteRect(
                ctx,
                coordinates.x,
                0,
                color,
                0,
                coordinates.width,
                height
            )

            ScoreCanvas.drawText(
                ctx,
                coordinates.x + coordinates.width / 2,
                height / 2,
                text,
                90,
                "",
                "white"
            )
        }

        return sections
    }

    static generateNoteRect(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        fillStyle: string,
        borderRadius: number,
        width: number,
        height: number
    ) {
        ctx.beginPath()
        ScoreCanvas.roundRect(ctx, x, y, width, height, borderRadius)
        ctx.fillStyle = fillStyle
        ctx.fill("evenodd")
        ctx.closePath()
    }

    protected generateUnfilledRect(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        color: string,
        borderRadius: number,
        width: number,
        height: number
    ) {
        ctx.beginPath()
        ScoreCanvas.roundRect(ctx, x, y, width, height, borderRadius)
        ctx.strokeStyle = color
        ctx.stroke()
        ctx.closePath()
    }

    public initRender(args: {
        canvases?: string[]
        scoreState: ScoreRendering
        editorViewState: EditorViewState
        grid: GridDimensionsDictionary
        noteRes: number
        nbOfYValues: number
        computePxPerTimestep?: { (): number }
        canvasHeight?: number
        highestPitch?: number
    }): {
        shouldRenderGrid: boolean
        shouldPreRenderGrid: boolean // used to prerender the note grid so we can reuse it later on (for performance reasons)
        shouldPreRender: boolean
    } {
        this._grid = args.grid

        if (!args.scoreState.score) {
            return {
                shouldRenderGrid: false,
                shouldPreRenderGrid: false,
                shouldPreRender: false,
            }
        }

        let shouldRenderGrid = this.grid.hasChanged
        let shouldPreRenderGrid = false
        let shouldPreRender = false

        if (args.highestPitch !== undefined) {
            this.highestPitch = args.highestPitch
        }

        if (args.canvasHeight) {
            this.adjustCanvasDimensions({
                height: args.canvasHeight,
            })
        }

        if (
            args.canvasHeight !== undefined &&
            args.canvasHeight !== this.height
        ) {
            shouldRenderGrid = true
        }

        if (window.innerWidth !== this.browserWindowWidth) {
            this.browserWindowWidth = window.innerWidth
            shouldRenderGrid = true
            shouldPreRenderGrid = true
            shouldPreRender = true
        }

        if (this._noteRes !== args.noteRes) {
            shouldRenderGrid = true
            shouldPreRenderGrid = true
            shouldPreRender = true
            this._noteRes = args.noteRes

            if (!this.drawNoteDuration) {
                this.drawNoteDuration = "1/" + this.noteRes
            }

            // use smallest possible duration as default duration when in scale mode
            // this prevents drawing notes in durations that don't match the grid
            if (this._grid?.pitched?.pitchStepDomain === "scale") {
                this.drawNoteDuration = "1/" + this.noteRes
            }
        }

        if (this.resizeFactor != args.scoreState.resizeFactor) {
            shouldRenderGrid = true
            shouldPreRenderGrid = true
            shouldPreRender = true
            this.resizeFactor = args.scoreState.resizeFactor
        }

        let newPxPerTimesteps

        if (args.computePxPerTimestep) {
            newPxPerTimesteps = args.computePxPerTimestep()
        } else {
            newPxPerTimesteps =
                ScoreRenderingEngineMisc.computePxPerTimestepsForGrid(
                    this.grid,
                    this.noteRes
                )
        }

        if (this._pxPerTimestep !== newPxPerTimesteps) {
            shouldRenderGrid = true
            shouldPreRender = true
            shouldPreRenderGrid = true
            this._pxPerTimestep = newPxPerTimesteps
        }

        this.nbOfYValues = args.nbOfYValues
        const newPxPerYStep = this.height / args.nbOfYValues

        if (this._pxPerYStep !== newPxPerYStep) {
            shouldRenderGrid = true
            this._pxPerYStep = newPxPerYStep
        }

        const timestepRange = this.getTimestepRange()

        const newScrollToTimestep =
            (args.scoreState.scrollToTimestep * this.noteRes) / TIMESTEP_RES

        if (this.scrollToTimestep !== newScrollToTimestep) {
            shouldRenderGrid = true
            this.scrollToTimestep = newScrollToTimestep
        }

        const newScrollToPitchsteps = Math.max(
            0,
            Math.min(
                this.highestPitch - this.nbOfYValues,
                args.scoreState.scrollToPitchsteps
            )
        )

        const scoreLengthInTS = Time.convertTimestepsToAnotherRes(
            this.grid.scoreLengthTimesteps,
            this.grid.timestepRes,
            this.noteRes
        )

        if (this.scrollToPitchsteps !== newScrollToPitchsteps) {
            shouldRenderGrid = true
            shouldPreRenderGrid = true // todo: remove this once the performance is optimized
            this.scrollToPitchsteps = newScrollToPitchsteps
        }
        /**
         * This line was intended to solve the issue of scrolling outside of boundaries of the canvas.
         * However it was causing issues in composition workflow, as it was not scrolling the last part of layer preview
         * Disabling this did not have obvious drawbacks, but keeping this here just in case we need it in the future.
         * The issue is probably in the calculation of the timestepRange or scoreLengthInTS
         */

        // if (this.scrollToTimestep + timestepRange > scoreLengthInTS) {
        //     shouldRenderGrid = true
        //     this.scrollToTimestep = scoreLengthInTS - timestepRange
        // }

        const newPatternScrollToTimestep = Math.round(
            (args.scoreState.patternScrollToTimestep * this.noteRes) /
                TIMESTEP_RES
        )

        if (this.patternScrollToTimestep !== newPatternScrollToTimestep) {
            shouldRenderGrid = true
            this.patternScrollToTimestep = newPatternScrollToTimestep
        }

        if (this.patternScrollToTimestep + timestepRange > scoreLengthInTS) {
            shouldRenderGrid = true
            this.patternScrollToTimestep = scoreLengthInTS - timestepRange
        }

        for (let c in this.canvases) {
            if (c === "canvas-grid" && !shouldRenderGrid) {
                continue
            }

            this.getContext(c).clearRect(0, 0, this.width, this.height)
        }

        this.scrollXOffset = ScoreCanvas.getScrollXOffset({
            scrollToTimestep:
                this.gridType === "drumSequencer"
                    ? this.patternScrollToTimestep
                    : this.scrollToTimestep,
            grid: this.grid,
            pxPerTimestep: this.pxPerTimestep,
            noteRes: this.noteRes,
        })

        if (featureFlags.debugRendering) {
            console.log("Rendering: ", {
                type: this.type,
                shouldRenderGrid,
                store: this.queries.scoreRendering.__store__,
                shouldRenderCanvas: this.shouldRenderCanvas(
                    this.queries.scoreRendering.__store__.getValue()
                        .renderingType
                ),
                time: Date.now(),
                layerType: this.queries.scoreRendering?.toggledLayer?.value,
            })
        }

        if (this.scoreRenderingState.skipCachedCanvas) {
            shouldPreRender = true
            shouldPreRenderGrid = true
        }

        return {
            shouldRenderGrid,
            shouldPreRenderGrid,
            shouldPreRender,
        }
    }

    /**
     * This method is responsible for adjusting the canvas' pixel density to match that of
     * window.devicePixelRatio. Otherwise, retina displays on high DPI / 4k displays will render
     * pixelated content on the screen, which will look ugly
     */
    public adjustCanvasDimensions(args?: { height?: number; width?: number }) {
        for (let key in this.canvases) {
            const canvas = this.canvases[key]
            const ctx = this.getContext(key)

            let width = this.initialWidth
            let height = this.initialHeight

            if (args?.height) {
                height = args.height
            }

            if (args?.width) {
                width = args.width
            }

            const dpi = window.devicePixelRatio || 1

            canvas.width = width
            canvas.height = height

            this._width = width
            this._height = height

            ctx.canvas.width = width * dpi
            ctx.canvas.height = height * dpi

            if (this.type === "AutomationCanvas" && key.includes("inverted")) {
                ctx.translate(0, height * dpi)
                ctx.scale(dpi, -dpi)
            } else if (dpi !== 1) {
                ctx.scale(dpi, dpi)
            }

            ctx.canvas.style.width = width + "px"
            ctx.canvas.style.height = height + "px"
        }
    }

    protected getContext(canvasType): CanvasRenderingContext2D {
        const ctx = this.canvases[canvasType].getContext("2d")

        if (!ctx) {
            throw "Unexpected error - Context is undefined"
        }

        return ctx
    }

    protected getStepsForPixels(args: { coordinates: Coordinates }): {
        timesteps: number | undefined
        ysteps: number | undefined
    } {
        let selectedTs
        let selectedYstep

        const coordinates = { ...args.coordinates }
        coordinates.x = Math.max(0, coordinates.x)
        coordinates.y = Math.max(0, coordinates.y)

        this.traverseTimeGrid(
            (x: number, timesteps, currentGutterSize, previousGutterSize) => {
                const start = Math.max(
                    0,
                    Math.floor(x - previousGutterSize - 1)
                )
                let end = Math.ceil(
                    x + this.grid.pxPerTimestepWithoutGutters + 1
                )

                selectedTs = timesteps

                if (coordinates.x < start) {
                    return false
                }

                if (coordinates.x > end) {
                    return true
                }

                this.traversePitchGrid((y, absolutePitch) => {
                    const height = this.pxPerYStep

                    if (coordinates.y < y) {
                        return false
                    }

                    if (coordinates.y >= y && coordinates.y < y + height) {
                        selectedYstep = absolutePitch

                        return false
                    }

                    return true
                })

                return selectedTs === undefined
            },
            false,
            false,
            false
        )

        return {
            timesteps: selectedTs,
            ysteps: selectedYstep,
        }
    }

    protected mouseDownHandler(
        event: Coordinates,
        getHoveringType?: GetHoveringType
    ) {
        try {
            const grid = this.getGridCoordinates(event, false)

            let result

            if (getHoveringType) {
                result = getHoveringType(grid)
            }

            const start: AllCanvasCoordinates = {
                scrollToTimestep: this.scrollToTimestep,
                pixels: event,
                grid: grid,
            }

            this._mouseDownStart = {
                start: start,
                last: cloneDeep(start),
                mouseDownLocation: result ? result.type : "center",
            }
        } catch (e) {
            console.log("mouseDownHandler: ", e)
        }
    }

    protected drawNote(gridCoordinates: GridCoordinates) {
        if (this.cursorType !== "pencil" || this.hoveredElement) {
            return
        }

        let maxScoreLength = undefined

        if (this.grid.pitchStepDomain === "scale") {
            maxScoreLength =
                this.queries.scoreRendering.getValue().score?.scoreLength
        }

        this.srEmitter$.next({
            type: SRActionTypes.drawNote,
            data: {
                timesteps: gridCoordinates.timesteps,
                ysteps: gridCoordinates.ysteps,
                defaultDuration: this.drawNoteDuration,
                maxScoreLength: maxScoreLength,
            },
            options: {
                isUndoable: true,
            },
        })

        const drawnNote =
            this.queries.scoreRendering.selectedNotes.getFirstGroup()[0]

        const msToWaitBeforeResizing = 150

        if (drawnNote?.duration) {
            this.drawNoteDuration = drawnNote.duration
            this._mouseDownStart.mouseDownLocation = HoveringTypeEnum.RIGHT

            // set last grid timesteps to be the note end of the drawn note
            // so the note end will be the cursor position when resizing
            this.mouseDownStart.last.grid.timesteps =
                Time.fractionToTimesteps(
                    this.noteRes,
                    Time.addTwoFractions(drawnNote.start, drawnNote.duration)
                ) - 1

            setTimeout(() => {
                this.setHoveredElement({
                    draggingType: HoveringTypeEnum.RIGHT,
                    element: drawnNote,
                    type: "note",
                })
            }, msToWaitBeforeResizing)

            return drawnNote
        }
    }

    protected removeNote(gridCoordinates: GridCoordinates) {
        if (this.cursorType !== "pencil" || this.hoveredElement) {
            return
        }

        let maxScoreLength = undefined

        if (this.grid.pitchStepDomain === "scale") {
            maxScoreLength =
                this.queries.scoreRendering.getValue().score?.scoreLength
        }

        this.srEmitter$.next({
            type: SRActionTypes.removeNote,
            data: {
                timesteps: gridCoordinates.timesteps,
                ysteps: gridCoordinates.ysteps,
                defaultDuration: this.drawNoteDuration,
                maxScoreLength: maxScoreLength,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    private getGridCoordinatesForMoveHandler(
        event: Coordinates,
        thresholds: QuantizationThresholds,
        quantize: boolean
    ) {
        const now = this.getGridCoordinates(event, false)

        if (quantize) {
            now.timesteps = ScoreManipulation.quantizeTimesteps({
                resizeFactor: this.resizeFactor,
                timesteps: now.timesteps,
                timestepRes: this.noteRes,
                timeSignature:
                    this.queries.scoreRendering.score.firstTimeSignature,
                thresholds,
            })
        }

        const previous = cloneDeep(this.mouseDownStart.last.grid)

        if (quantize) {
            previous.timesteps = ScoreManipulation.quantizeTimesteps({
                resizeFactor: this.resizeFactor,
                timesteps: previous.timesteps,
                timestepRes: this.noteRes,
                timeSignature:
                    this.queries.scoreRendering.score.firstTimeSignature,
                thresholds,
            })
        }

        return {
            now,
            previous,
        }
    }

    protected moveHandler(
        event: Coordinates,
        options: {
            timestepResLimit?: number
            type: "move" | "resize" | "exact" | "loop"
            noNegativeOffsetBeyondThreshold?: number
            maxScoreLength?: FractionString
            thresholds: QuantizationThresholds
            quantize: boolean
            selectedNote?: Note
        }
    ):
        | {
              mouseDownStart: MouseDownStart
              diffTimesteps
              diffPitchsteps
              now: GridCoordinates
              previous: GridCoordinates
          }
        | undefined {
        if (this.mouseDownStart === undefined) {
            return undefined
        }

        try {
            const { now, previous } = this.getGridCoordinatesForMoveHandler(
                event,
                options.thresholds,
                options.quantize
            )

            const diffTimesteps = Math.trunc(now.timesteps - previous.timesteps)
            const diffPitchsteps =
                ScoreRenderingEngineMisc.preprocessDiffPitchsteps(
                    now,
                    previous,
                    options.type
                )

            now.ysteps = previous.ysteps + diffPitchsteps

            const mouseDownStart: MouseDownStart = cloneDeep(
                this.mouseDownStart
            )

            mouseDownStart.last = {
                scrollToTimestep: this.scrollToTimestep,
                grid: {
                    timesteps: now.timesteps,
                    ysteps: now.ysteps,
                },
                pixels: event,
            }

            if (
                !this.passMouseMoveEvent(
                    diffTimesteps,
                    diffPitchsteps,
                    options,
                    now
                )
            ) {
                return undefined
            }

            this._mouseDownStart = mouseDownStart

            return {
                mouseDownStart,
                diffTimesteps,
                diffPitchsteps,
                now,
                previous,
            }
        } catch (e) {
            console.log(e)

            return undefined
        }
    }

    private passMouseMoveEvent(
        diffTimesteps: number,
        diffPitchsteps: number,
        options: {
            timestepResLimit?: number
            type: "move" | "resize" | "exact" | "loop"
            noNegativeOffsetBeyondThreshold?: number
            maxScoreLength?: FractionString
        },
        now: GridCoordinates
    ) {
        const selectedPRs = this.queries.scoreRendering.selectedPatternRegions
        const selectedPR = selectedPRs.length > 0 ? selectedPRs[0] : undefined

        const zeroOffsets = diffTimesteps === 0 && diffPitchsteps === 0
        const cantCrossNegativeThreshold =
            diffTimesteps < 0 &&
            options.noNegativeOffsetBeyondThreshold !== undefined &&
            options.noNegativeOffsetBeyondThreshold > now.timesteps &&
            (selectedPR === undefined || selectedPR.loop === 0)

        const belowTimestepLimit =
            !!options?.timestepResLimit &&
            Math.abs(diffTimesteps) < options?.timestepResLimit

        return !(
            belowTimestepLimit ||
            zeroOffsets ||
            cantCrossNegativeThreshold
        )
    }

    /**
     * Only use for canvases that react to scrollToPitchsteps
     */
    protected traversePitchGrid(
        functionBinding: {
            (
                y: number,
                absolutePitch: number,
                relativePitch: number,
                pitchType: string,
                nextY: number
            ): boolean
        },
        boundaries?: {
            start: number
            end: number
        }
    ) {
        if (boundaries === undefined) {
            boundaries = {
                start: Math.floor(this.scrollToPitchsteps),
                end: Math.ceil(this.scrollToPitchsteps + this.nbOfYValues),
            }
        }

        for (let y = boundaries.start; y < boundaries.end; y++) {
            const pixelsY = this.pxPerYStep * (y - this.scrollToPitchsteps)
            const nextPixelsY =
                this.pxPerYStep * (y + 1 - this.scrollToPitchsteps)
            const absolutePitch = this.highestPitch - y
            const relativePitch = absolutePitch % 12
            const pitchType = Note.notesInOctaveForIndexing[relativePitch]

            if (
                !functionBinding(
                    pixelsY,
                    absolutePitch,
                    relativePitch,
                    pitchType,
                    nextPixelsY
                )
            ) {
                break
            }
        }
    }

    protected traverseTimeGrid(
        functionBinding: {
            (
                x,
                timesteps,
                currentGutterSize,
                previousGutterSize,
                width
            ): boolean
        },
        skipTimesteps: boolean,
        skipBeatsteps: boolean,
        skipHalfBeatsteps: boolean,
        boundaries?: {
            start: number
            end: number
            offsetX: number
        }
    ) {
        if (this.queries.scoreRendering.score === undefined) {
            return
        }

        if (boundaries === undefined) {
            boundaries = this.createDefaultBoundaries()
        }

        let previousTimesteps = -1

        const startBar = Math.floor(boundaries.start)
        const endBar = boundaries.end

        const beatLengthInFractions = "1/" + this.grid.timeSignature[1]
        const halfBeatTimesteps =
            Time.fractionToTimesteps(this.noteRes, beatLengthInFractions) / 2

        for (let bar = startBar; bar <= endBar; bar++) {
            const barTimesteps = Time.barToTimestep(
                bar,
                this.queries.scoreRendering.score.timeSignatures[0][1],
                this.noteRes
            )

            for (
                let halfBeat = 0;
                halfBeat < this.grid.halfBeatsInOneBar;
                halfBeat++
            ) {
                let beat = Math.max(0, halfBeat - 1)

                // we want to deal with beats instead of halfbeats
                // whenever we are either skipping half beats or
                // dealing with timesteps
                if ((skipHalfBeatsteps || !skipTimesteps) && halfBeat !== 0) {
                    halfBeat = halfBeat + 1
                    beat = halfBeat / 2

                    if (halfBeat >= this.grid.halfBeatsInOneBar) {
                        break
                    }
                }

                const result = this.iterateThroughTimesteps({
                    barTimesteps,
                    halfBeatTimesteps,
                    beat: beat,
                    halfBeat: halfBeat,
                    bar,
                    skipTimesteps,
                    skipBeatsteps,
                    skipHalfBeatsteps,
                    boundaries,
                    functionBinding,
                    previousTimesteps,
                })

                previousTimesteps = result.previousTimesteps

                if (!result.keepGoing) {
                    return
                }

                if (skipBeatsteps) {
                    break
                }
            }
        }
    }

    private createDefaultBoundaries() {
        let scrollToTimestep = this.scrollToTimestep

        if (this.gridType === "drumSequencer") {
            scrollToTimestep = this.patternScrollToTimestep
        }

        return {
            start: Time.timestepsToBar(
                scrollToTimestep,
                this.queries.scoreRendering.score.timeSignatures[0][1],
                this.noteRes
            ),

            end: Math.min(
                this.grid.barsInPattern,
                Math.ceil(
                    Time.timestepsToBar(
                        scrollToTimestep + this.getTimestepRange(),
                        this.queries.scoreRendering.score.timeSignatures[0][1],
                        this.noteRes
                    )
                )
            ),
            offsetX: this.scrollXOffset,
        }
    }

    public getTimestepRange() {
        return ScoreRenderingEngineMisc.getTimestepRange({
            grid: this.grid,
            timestepRes: this.noteRes,
            width: this.width,
            pxPerTimestep: this.pxPerTimestep,
        })
    }

    private iterateThroughTimesteps(args: IterateThroughTimesteps) {
        const halfBeatTimesteps = args.halfBeatTimesteps * args.halfBeat

        let keepGoing = true
        let width

        for (let note = 0; note < this.grid.notesInOneBeat; note++) {
            const timesteps = args.barTimesteps + halfBeatTimesteps + note

            const x = ScoreCanvas.getPixelsForTimesteps({
                timesteps: timesteps,
                removeLastGutterPx: "none",
                timestepRes: this.noteRes,
                grid: this.grid,
            })

            // define previous and current gutter size
            let previousGutterSize = this.grid.pxPerTimestepGutter

            // this is the first note in a new bar
            if (note === 0 && args.beat === 0) {
                previousGutterSize =
                    args.bar !== 0 ? this.grid.pxPerBarGutter : 0
            }

            // this is the first note in a new half beat
            else if (note % (this.grid.notesInOneBeat / 2) === 0) {
                previousGutterSize = this.grid.pxPerHalfBeatGutter
            }

            // this is the first note in a new beat
            else if (note === 0) {
                previousGutterSize = this.grid.pxPerBeatGutter
            }

            let currentGutterSize = this.grid.pxPerTimestepGutter

            // next beat
            if (note + 1 >= this.grid.notesInOneBeat) {
                if (args.beat + 1 >= this.grid.beatsInOneBar) {
                    currentGutterSize = this.grid.pxPerBeatGutter
                } else {
                    currentGutterSize = this.grid.pxPerBarGutter
                }
            }

            // next halfbeat
            if (note + 1 >= this.grid.notesInOneBeat / 2) {
                currentGutterSize = this.grid.pxPerHalfBeatGutter
            }

            // define the width
            width = this.grid.pxPerTimestepWithoutGutters

            if (
                args.skipTimesteps &&
                args.skipHalfBeatsteps &&
                !args.skipBeatsteps
            ) {
                width += this.grid.pxPerBeat - this.grid.pxPerBeatGutter
            }

            if (args.skipTimesteps && !args.skipHalfBeatsteps) {
                width =
                    this.grid.pxPerHalfBeat - this.grid.pxPerHalfBeatGutter / 2
            }

            if (args.skipBeatsteps) {
                width += this.grid.pxPerBar
            }

            const keepIterating = args.functionBinding(
                x - args.boundaries.offsetX,
                timesteps,
                currentGutterSize,
                previousGutterSize,
                width
            )

            args.previousTimesteps = timesteps

            if (!keepIterating) {
                keepGoing = false

                break
            }

            if (args.skipTimesteps) {
                break
            }
        }

        return {
            keepGoing,
            previousTimesteps: args.previousTimesteps,
        }
    }

    static getPixelsForTimesteps(args: {
        grid: GridDimensions
        timesteps: number
        timestepRes: number
        removeLastGutterPx: RemoveLastGutter
    }): number {
        if (!args.grid) {
            return 0
        }

        const timesteps = Time.convertTimestepsToAnotherRes(
            args.timesteps,
            args.timestepRes,
            args.grid.timestepRes
        )

        const gridPosition: GridPosition = Time.timestepsToGridPosition(
            timesteps,
            args.grid.timeSignature,
            args.grid.timestepRes
        )

        const x =
            gridPosition.notePosition * args.grid.pxPerTimestepWithoutGutters +
            gridPosition.notePosition * args.grid.pxPerTimestepGutter +
            gridPosition.beat * args.grid.pxPerBeat +
            gridPosition.beat * args.grid.pxPerBeatGutter +
            gridPosition.bar * args.grid.pxPerBar +
            gridPosition.bar * args.grid.pxPerBarGutter

        let value = x

        let penalty = 0

        const shouldDisplayHalfBeatGrid = args.grid.pxPerHalfBeatGutter !== 0

        // half beat
        if (
            gridPosition.notePosition !== 0 &&
            gridPosition.notePosition % (args.grid.notesInOneBeat / 2) === 0 &&
            shouldDisplayHalfBeatGrid
        ) {
            // undo the change above
            let newvalue =
                value -
                (gridPosition.notePosition *
                    args.grid.pxPerTimestepWithoutGutters +
                    gridPosition.notePosition * args.grid.pxPerTimestepGutter)

            // add halfbeat dimensions instead
            newvalue +=
                args.grid.pxPerHalfBeat + args.grid.pxPerHalfBeatGutter / 2

            value = newvalue
        }

        if (args.removeLastGutterPx !== "none") {
            const previousGridPosition: GridPosition =
                Time.timestepsToGridPosition(
                    timesteps - 1,
                    args.grid.timeSignature,
                    args.grid.timestepRes
                )

            if (gridPosition.bar === previousGridPosition.bar + 1) {
                penalty = args.grid.pxPerBarGutter
            }

            if (gridPosition.beat === previousGridPosition.beat + 1) {
                penalty = args.grid.pxPerBeatGutter
            }

            if (
                gridPosition.notePosition ===
                previousGridPosition.notePosition + 1
            ) {
                // half beats
                if (
                    gridPosition.notePosition !== 0 &&
                    gridPosition.notePosition %
                        (args.grid.notesInOneBeat / 2) ===
                        0 &&
                    args.grid.pxPerHalfBeatGutter !== 0
                ) {
                    penalty = args.grid.pxPerHalfBeatGutter
                }
                // timesteps
                else {
                    penalty = args.grid.pxPerTimestepGutter
                }
            }

            if (args.removeLastGutterPx === "half") {
                penalty = penalty / 2
            }

            value = value - penalty
        }

        return value
    }

    static getScrollXOffset(args: {
        scrollToTimestep: number
        grid: GridDimensions
        pxPerTimestep: number
        noteRes: number
    }) {
        // Why not using the commented out implementation you ask?
        // Well, it turns out that the dumb implementation is less mathematically correct
        // but leads to a better user experience with less stutters while scrolling
        return args.scrollToTimestep * args.pxPerTimestep

        // return ScoreCanvas.getPixelsForTimesteps({
        //     grid: args.grid,
        //     timesteps: args.scrollToTimestep,
        //     timestepRes: args.noteRes,
        //     removeLastGutterPx: "none",
        // })
    }

    protected getTimestepsForPixels(args: {
        pixels: number
    }): number | undefined {
        return args.pixels / this.pxPerTimestep
    }

    public get pxPerTimestep(): number {
        return this._pxPerTimestep
    }

    public get pxPerYStep(): number {
        return this._pxPerYStep
    }

    public get width(): number {
        return this._width
    }

    public get noteRes(): number {
        return this._noteRes
    }

    protected get height(): number {
        return this._height
    }

    protected round(value: number): number {
        return Math.max(Math.round(value), 1)
    }

    protected shouldRenderPhrases(layer: Layer) {
        return (
            layer?.value === "Melody" && layer?.effects["auto_phrasing"]["view"]
        )
    }

    protected getNoteColor(
        layer: Layer,
        type: "selected" | "overlap" | "secondary" | "normal"
    ) {
        let color = layer?.getColor()

        if (type === "selected") {
            return layer.getOppositeColor()
        } else if (type === "overlap") {
            return Color.changeColorOpacity(color, "0.25")
        } else if (type === "secondary") {
            return Color.changeColorOpacity(color, "0.45")
        }

        return color
    }

    /**
     * this method returns a truncated string with ellipsis at the end in case
     * the size of the string exceeds the maximum width
     * @param string text to render
     * @param maxWidth max width the text is allowed to use
     * @returns fitting string
     */
    protected getFittingString(
        ctx: CanvasRenderingContext2D,
        string: string,
        maxWidth: number
    ): string {
        const ellipsis = "..."
        const ellipsisWidth = ctx.measureText(ellipsis).width
        let width = ctx.measureText(string).width

        // render no text if not even
        // the elipsis are fitting
        if (ellipsisWidth > maxWidth) {
            return ""
        }

        if (width <= maxWidth || width <= ellipsisWidth) {
            return string
        } else {
            let len = string.length

            while (width >= maxWidth - ellipsisWidth && len-- > 0) {
                string = string.substring(0, len)
                width = ctx.measureText(string).width
            }

            return string + ellipsis
        }
    }

    protected setCanvasCursor(cursorStyle = "default") {
        let canvasContainer: HTMLElement = this.context.canvasContainer

        if (canvasContainer === undefined) {
            return
        }

        if (cursorStyle !== "pencil") {
            canvasContainer.style.cursor = cursorStyle
        } else if (cursorStyle === "pencil") {
            let pencilCursorPath = "assets/img/editmodes/1_cursor.png"

            canvasContainer.style.cursor = `url('${pencilCursorPath}'), auto`
        }
    }

    /**
     * the position is helpful to handle events
     * using the offsetX and offsetY can be misleading because
     * those values are relative to the event target (which could
     * also be the selection area e.g.)
     * @returns DOMRect
     */
    protected getCanvasPosition() {
        return this.canvas?.getBoundingClientRect()
    }

    static drawText(
        ctx,
        x: number,
        y: number,
        text: string,
        fontSizePercentage: number,
        fontStyle: string,
        color: string,
        textAlign = "center"
    ) {
        ctx.font = fontStyle + " " + fontSizePercentage + "% Arial"
        ctx.fillStyle = color
        ctx.textAlign = textAlign
        ctx.fillText(text, x, y)
    }

    static drawVerticalLine(ctx, x, startY, endY, strokeStyle) {
        if (x < 0) {
            return
        }

        ctx.lineWidth = 1 // how thick the line is
        ctx.strokeStyle = strokeStyle // what color our line is
        ctx.beginPath()
        ctx.moveTo(x, startY)
        ctx.lineTo(x, endY)
        ctx.stroke()
    }

    static renderLabelWithSuperscript({
        ctx,
        text,
        x,
        y,
    }: {
        ctx
        text: string
        x: { start: number; end: number }
        y: number
    }) {
        let ellipsis = ScoreCanvas.fittingString(ctx, text, x.end - x.start)

        if (ellipsis.includes("…") && ellipsis.split("(").length > 1) {
            ellipsis = ellipsis.split("(")[0] + "…"
        }

        const mainText = ellipsis.split("(")[0]

        ScoreCanvas.drawText(
            ctx,
            x.start,
            y,
            mainText,
            CHORD_FONT_SIZE_PERCENTAGE,
            "normal",
            "rgba(255,255,255,0.8)",
            "left"
        )

        if (ellipsis.split("(").length === 2) {
            const superscript = ellipsis.split("(")[1].replace(")", "")

            ScoreCanvas.drawText(
                ctx,
                x.start + ctx.measureText(mainText).width,
                y - 5,
                superscript,
                60,
                "normal",
                "rgba(255,255,255,0.8)",
                "left"
            )
        }
    }

    static drawHorizontalLine(ctx, startX, endX, y, strokeStyle) {
        if (startX < 0 || endX < 0) {
            return
        }

        ctx.lineWidth = 1 // how thick the line is
        ctx.strokeStyle = strokeStyle // what color our line is
        ctx.beginPath()
        ctx.moveTo(startX, y)
        ctx.lineTo(endX, y)
        ctx.stroke()
    }

    static roundRect(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        w: number,
        h: number,
        r: number,
        fillStyle?: string
    ) {
        // Commented out these lines because they cause the radius to be negative (which is an invalid argument)
        // Not actually sure what these lines are about though
        //if (w < 2 * r) r = w / 2
        //if (h < 2 * r) r = h / 2

        if (!fillStyle) {
            fillStyle = "#fff"
        }

        ctx.fillStyle = fillStyle

        ctx.beginPath()
        ctx.moveTo(x + r, y)
        ctx.arcTo(x + w, y, x + w, y + h, r)
        ctx.arcTo(x + w, y + h, x, y + h, r)
        ctx.arcTo(x, y + h, x, y, r)
        ctx.arcTo(x, y, x + w, y, r)
        ctx.closePath()

        return ctx
    }

    // todo
    /**
     * add branching logic so it can be used to scroll the drum sequencer
     * fix issues with moving beyond boundaries
     */
    protected scrollWithMouseMovement(event: Coordinates) {
        if (!this._mouseDownStart) {
            return
        }

        const gridType = this.syncedCanvas?.context?.gridType || "pitched"

        const grid: GridDimensions =
            gridType === "pitched"
                ? this.grid
                : this.syncedCanvas._grid.drumSequencer

        const scoreLengthTimesteps = Time.convertTimestepsToAnotherRes(
            grid.scoreLengthTimesteps,
            grid.timestepRes,
            TIMESTEP_RES
        )

        const scrollToTimestep = (event.x * scoreLengthTimesteps) / this.width

        this.srEmitter$.next({
            type: SRActionTypes.setScroll,
            data: {
                scrollToPitchsteps:
                    this.queries.scoreRendering.scrollToPitchsteps,
                scrollToTimestep: scrollToTimestep,
                width: this.width,
            },
        })
    }

    /**
     * This function is responsible for converting x / y coordinates into
     * timesteps and y steps. Given that different child of ScoreCanvas may have
     * slightly different way of computing timesteps / ysteps, you may want to override
     * this method in child classes
     * @param event
     * @param yCount
     * @param removeGutter
     * @param invertYAxis
     * @returns
     */
    protected getGridCoordinates(
        event: Coordinates,
        invertYAxis: boolean
    ): GridCoordinates {
        const result = this.getStepsForPixels({
            coordinates: cloneDeep(event),
        })

        if (this.nbOfYValues > 0 && result.ysteps === undefined) {
            throw "ScoreCanvas.getGridCoordinates: ysteps is undefined"
        }

        if (result.timesteps === undefined) {
            throw "ScoreCanvas.getGridCoordinates: timesteps is undefined"
        }

        return {
            timesteps: result.timesteps,
            ysteps: result.ysteps ? (invertYAxis ? -1 : 1) * result.ysteps : 0,
        }
    }

    protected getGridDictionary() {
        return this._grid
    }

    protected getDrumSequencerGrid() {
        return this._grid.drumSequencer
    }

    static fittingString(c, str: string, maxWidth: number) {
        let width = c.measureText(str).width
        const ellipsis = "…"
        const ellipsisWidth = c.measureText(ellipsis).width

        if (width <= maxWidth || width <= ellipsisWidth) {
            return str
        } else {
            let len = str.length

            while (width >= maxWidth - ellipsisWidth && len-- > 0) {
                str = str.substring(0, len)
                width = c.measureText(str).width
            }

            return str + ellipsis
        }
    }

    protected createElement(args: {
        type: string
        innerHTML?: string
        id?: string
        className?: string
        position?: string
    }) {
        const element = document.createElement(args.type)

        if (args.id) {
            element.id = args.id
        }

        if (args.className) {
            element.className = args.className
        }

        if (args.innerHTML) {
            element.innerHTML = args.innerHTML
        }

        if (args.position) {
            element.style.position = args.position
        }

        return element
    }

    protected createButtonElement(args: {
        innerHTML?: string
        id?: string
        className?: string
        destroy?: Subject<any>
        onClick?: Function
        position?: string
    }) {
        const element = this.createElement({
            type: "button",
            innerHTML: args.innerHTML,
            id: args.id,
            className: args.className,
            position: args.position,
        })

        const observable = EventHandlers.getMouseEventObservable(
            "click",
            element as HTMLButtonElement,
            0,
            args.destroy || this.destroyObservable,
            this.context.canvasContainer
        )

        if (args.onClick) {
            element.onclick = () => {
                args.onClick
            }
        }

        return {
            element,
            observable,
        }
    }

    protected loadImg(src: string, width: number): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            let img = new Image(width, width)
            img.onload = () => resolve(img)
            img.onerror = reject
            img.src = src
        })
    }
}
