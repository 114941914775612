import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    ViewChild,
    ElementRef,
    ViewEncapsulation,
    SimpleChanges,
} from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import { ModalService } from "@services/modal.service"
import { PlayerService } from "@services/audio/player/player.service"
import { MenuOptions } from "../menu-options/menu-options.component"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"

/**
 * @deprecated
 * This list component is deprecated because it is too hardcoded and not reusable enough.
 */
@Component({
    selector: "list",
    templateUrl: "./list.component.html",
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ListComponent implements OnInit {
    @Input() listItems: Array<any> = []
    @Input() maxHeight: string
    @Input() itemLoader = false
    @Input() class: string = ""
    @Input() type: "gpPreviews" | "canvas" | "mini-pack"

    @Input() menuOptions: MenuOptions<any>
    @Input() onPlay?: (item) => Promise<void>
    @Input() onClick?: (item) => Promise<void> = item => {
        return Promise.resolve()
    }
    @Input() showMenu?: (item) => boolean = item => {
        return true
    }

    public openedMenuOptions: MenuOptions<any> | undefined

    renamingItem = null
    hoveredItem = null
    preview

    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    @ViewChild("renameInput") renameInput: ElementRef

    constructor(
        private ref: ChangeDetectorRef,
        private playerService: PlayerService,
        private gpService: GenerationProfileService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        if (this.type === "gpPreviews") {
            this.playerService.gpPreviews$.subscribe(value => {
                this.preview = value

                for (let item of this.listItems) {
                    if (item._id == this.preview.id) {
                        item["image"] = "assets/img/player/pause-circle.svg"
                    } else {
                        item["image"] = "assets/img/play-circle.svg"
                    }
                }

                this.ref.detectChanges()
            })
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            this.type === "mini-pack" &&
            changes.listItems?.currentValue &&
            changes.listItems?.previousValue &&
            changes.listItems?.previousValue?.length !=
                changes.listItems?.currentValue?.length
        ) {
            setTimeout(() => {
                this.drawPacksPreview()
            }, 0)
        }
    }

    ngAfterViewInit() {
        this.drawPacksPreview()
    }

    drawPacksPreview() {
        if (this.type !== "mini-pack") {
            return
        }

        for (let item of this.listItems) {
            if (item?.type === "category") {
                continue
            }

            this.gpService.drawPacksPreview(
                item,
                item.layerName,
                "mini-pack",
                item.index
            )
        }

        this.ref.detectChanges()
    }

    onHover(item) {
        if (item?.type === "category") {
            return
        }

        this.hoveredItem = item
    }

    openContextMenu(event: MouseEvent, listItem) {
        event.stopPropagation()

        this.modalService.contextMenus.localContextMenu.next({
            options: this.menuOptions.options.map(o => {
                const newOption = {
                    ...o,
                    data: listItem,
                }

                if (o.buttonClass === "rename") {
                    newOption.onClick = this.rename.bind(this)
                } else if (o.buttonClass === "delete") {
                    newOption.onClick = async (data, event) => {
                        const itemWasDeleted = await o.onClick(data, event)

                        if (itemWasDeleted) {
                            this.deleteListItem(data)
                        }

                        this.openedMenuOptions = undefined

                        this.ref.detectChanges()

                        this.modalService.contextMenus.localContextMenu.next(
                            undefined
                        )
                    }
                } else {
                    newOption.onClick = async (data, event) => {
                        await o.onClick(data, event)

                        this.openedMenuOptions = undefined

                        this.ref.detectChanges()

                        this.modalService.contextMenus.localContextMenu.next(
                            undefined
                        )
                    }
                }

                return newOption
            }),

            coordinates: {
                x: event.clientX,
                y: event.clientY,
            },
        })

        console.log({
            test: (<Element>event.target).getBoundingClientRect(),
            x: event.x,
            y: event.y,
        })

        this.ref.detectChanges()
    }

    async confirmRename() {
        let renamingItem = this.renamingItem

        this.renamingItem = null
        this.ref.detectChanges()

        const option = this.menuOptions.options.find(
            o => o.buttonClass === "rename"
        )

        if (option === undefined) {
            console.error("Rename option not found")
        }

        await option.onConfirm(renamingItem)
    }

    deleteListItem(item) {
        for (let i = 0; i < this.listItems.length; i++) {
            if (this.listItems[i] === item) {
                this.listItems.splice(i, 1)

                return
            }
        }
    }

    async rename(data, event: MouseEvent) {
        event.stopPropagation()

        this.renamingItem = data

        this.openedMenuOptions = undefined

        this.ref.detectChanges()

        let counter = 1

        await Misc.wait(0.05)

        // The time needed to wait to refresh the UI is not something we can accurately predict, so instead we retry several times for a 1s period
        while (counter > 0) {
            if (
                this.renameInput == null ||
                this.renameInput.nativeElement == null ||
                this.renameInput.nativeElement.focus == null
            ) {
                counter -= 0.05
                await Misc.wait(0.05)
            } else {
                break
            }
        }

        this.renameInput.nativeElement.focus()
        this.renameInput.nativeElement.select()

        this.modalService.contextMenus.localContextMenu.next(undefined)
    }

    async selectImage(listItem) {
        if (this.type === "gpPreviews") {
            // await this.playerService.loadGPPreview(listItem._id, this.type)
        }
    }

    onItemClick(event, item) {
        // While renaming is active, we don't use the onClick method
        const renameInputActive = this.renameInput?.nativeElement?.focus != null
        const isCategory = item?.type === "category"

        if (renameInputActive || isCategory) {
            return
        }

        this.onClick(item)
    }
}
