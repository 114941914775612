import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core"
import {
    GP_DURATION_OPTIONS_DROPDOWN,
    NUMBER_OF_COMPOSITIONS_OPTIONS,
} from "@common-lib/constants/constants"
import { DurationType } from "@common-lib/types/general"
import { CompositionWorkflowHTTPService } from "@services/composition-workflow/composition-workflow-http.service"
import { CWCreateCompositionModalParameters } from "@services/modal.service"
import { TracksService } from "@services/tracks.service"
import { DropdownSelection } from "../../../types/dropdownItemType"
import { Misc } from "@common-lib/modules/misc"
import { featureFlags } from "@common-lib/utils/feature-flags"

@Component({
    selector: "cw-create-composition",
    templateUrl: "./cw-create-composition.modal.html",
    styleUrls: ["cw-create-composition.modal.scss"],
    providers: [CompositionWorkflowHTTPService],
})
export class CWCreateCompositionModal {
    @Output() close: EventEmitter<any> = new EventEmitter()
    @Input() input: CWCreateCompositionModalParameters

    public loading: boolean = false
    public error: string | undefined = undefined

    public duration: DurationType = "auto"
    public nbOfCompositions: number = 1

    public newThematicMaterial: boolean = false

    constructor(
        private cwHttpService: CompositionWorkflowHTTPService,
        private tracks: TracksService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {}

    public getNbOfCompositionsOptions() {
        return NUMBER_OF_COMPOSITIONS_OPTIONS
    }

    public getDurationOptions() {
        return GP_DURATION_OPTIONS_DROPDOWN
    }

    public getSelectedDuration() {
        return {
            value: this.duration,
            name: Misc.capitalizeFirstLetter(this.duration),
        }
    }

    public setThematicMaterial(value) {
        this.newThematicMaterial = value
    }

    public getFeatureFlags() {
        return featureFlags
    }

    public async continue() {
        this.loading = true

        try {
            await this.cwHttpService.generateComposition({
                folderID: this.input.folderID,
                compositionWorkflowID: this.input.cwID,
                duration: this.duration,
                nbOfCompositions: this.nbOfCompositions,
                newThematicMaterial: this.newThematicMaterial,
                allowInstrumentationChanges: {}
            })

            await this.tracks.redirectToMyTracks()

            this.close.emit()
        } catch (e) {
            this.error = e
        }

        this.loading = false

        this.cd.detectChanges()
    }

    public setNbOfCompositions(value: DropdownSelection<number>) {
        this.nbOfCompositions = value.new.value
    }

    public setDuration(value: DropdownSelection<DurationType>) {
        this.duration = value.new.value
    }
}
