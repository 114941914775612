import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import {
    CanDeactivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router"
import { EditorComponent } from "../components/editor/editor.component"
import { PlayerService } from "@services/audio/player/player.service"
import { AccompanimentDesignerComponent } from "../components/accompaniment-designer/accompaniment-designer.component"
import {
    DoActionBeforeModalParameters,
    ModalService,
} from "@services/modal.service"
import { ConfirmDiscardChangesService } from "@services/confirm-discard-changes.service"
import { CompositionWorkflowComponent } from "../components/composition-workflow/composition-workflow.component"
import ScoreRenderingEngine from "../../../../common-lib/client-only/score-rendering-engine/engine"
import { UserService } from "@services/user.service"
import { CacheService } from "@services/cache.service"

@Injectable({ providedIn: "root" })
export class ConfirmDiscardChanges
    implements
        CanDeactivate<
            | EditorComponent
            | AccompanimentDesignerComponent
            | CompositionWorkflowComponent
        >
{
    constructor(
        private modalService: ModalService,
        private playerService: PlayerService,
        private confirmService: ConfirmDiscardChangesService,
        private cache: CacheService
    ) {}

    async canDeactivate(
        component: EditorComponent | AccompanimentDesignerComponent,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        if (
            component instanceof EditorComponent &&
            component.navigatedThroughPlayer
        ) {
            return true
        }

        if (this.modalService.modals.doActionsBefore.getValue() !== undefined) {
            return false
        }

        let engine: ScoreRenderingEngine

        if (component instanceof AccompanimentDesignerComponent) {
            engine = component.adService?.engine
        } else if (component instanceof CompositionWorkflowComponent) {
            engine = (component as CompositionWorkflowComponent).cwService
                ?.engine
        } else {
            engine = component.editor?.engine
        }

        // If the pack is marked as saved, this means the user has intentionally triggered a navigation
        const hasSaved =
            (component instanceof AccompanimentDesignerComponent &&
                component.hasSavedPack) ||
            (component instanceof CompositionWorkflowComponent &&
                component.cwService.query.getValue().savedWorkflow)

        if (engine === undefined || !engine.scoreWasEdited || hasSaved) {
            return true
        }

        if (
            component instanceof CompositionWorkflowComponent &&
            this.cache.get("chordProgressionCache") !== null
        ) {
            return true
        }

        await this.playerService.pause()

        let dataType = "composition"

        if (component instanceof AccompanimentDesignerComponent) {
            dataType = "pack"
        } else if (component instanceof CompositionWorkflowComponent) {
            dataType = undefined
        }

        let componentType = "editor"

        if (component instanceof AccompanimentDesignerComponent) {
            componentType = "accompaniment designer"
        } else if (component instanceof CompositionWorkflowComponent) {
            componentType = "composition workflow"
        }

        const title = "Leaving " + componentType

        let description =
            "Leaving the " + componentType + " will discard any changes made"

        if (dataType !== undefined) {
            description += " to your " + dataType
        }

        description += ". Are you sure you want to do that?"

        const result = await new Promise<boolean>(resolve => {
            this.confirmService.openDoActionsBeforeModal({
                title,
                description,
                cancel: () => {
                    resolve(false)
                },
                action: async () => {
                    resolve(true)

                    return {
                        success: true,
                    }
                },
            })
        })

        // only set offline playback when we are actually discarding the chnanges
        if (result) {
            await this.playerService.setOfflinePlayback("canDeactivate")
        }

        return result
    }
}
