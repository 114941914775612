<div class="automation-submenu-setting">
    <div class="automation-submenu-setting-title">Set Constant Value</div>

    <div class="aiva-slider">
        <input
            type="range"
            min="{{ editor.engine.selectedAutomation?.min }}"
            max="{{ editor.engine.selectedAutomation?.max }}"
            step="{{ editor.engine.selectedAutomation?.step }}"
            [(ngModel)]="constantValue" />
        <div class="aiva-slider-value-label">
            {{ format(constantValue) }}
        </div>
    </div>

    <div
        class="apply-automation"
        (click)="editor.engine.setConstantAutomation(constantValue)">
        Apply
    </div>
</div>

<div
    class="automation-submenu-setting-title"
    *ngIf="editor.engine?.selectedAutomation?.name === 'reverb'">
    Room type
</div>

<dropdown
    *ngIf="editor.engine?.selectedAutomation?.name === 'reverb'"
    type="light"
    width="180px"
    [showTooltip]="false"
    [value]="getReverbRoom()"
    [items]="getReverbRoomOptions()"
    (selectedItem)="selectReverbRoom($event)"></dropdown>

<div
    class="automation-submenu-setting"
    *ngIf="editor.engine?.selectedAutomation?.name === 'delay'">
    <dial
        (valueOutput)="updateDialControlledVariable($event, 'delayLeft')"
        [turningSpeedModifier]="0.1"
        [unitLeft]="'ms'"
        [unitRight]="'ms'"
        [value]="editor.engine?.selectedAutomation?.left.delay_time"
        [color]="'--delay-primary-color'"
        [title]="'Delay Left'"
        [variableControlled]="'delayLeft'"
        [type]="'normal'"
        [minLabel]="'0s'"
        [maxLabel]="'2s'"
        [min]="0"
        [max]="2000"></dial>

    <!--        (valueOutput)="updateDialControlledVariable($event, 'delayLeft')"
-->

    <dial
        (valueOutput)="updateDialControlledVariable($event, 'delayRight')"
        [turningSpeedModifier]="0.1"
        [unitLeft]="'ms'"
        [unitRight]="'ms'"
        [value]="editor.engine?.selectedAutomation?.right.delay_time"
        [color]="'--delay-primary-color'"
        [title]="'Delay Right'"
        [variableControlled]="'delayRight'"
        [type]="'normal'"
        [minLabel]="'0s'"
        [maxLabel]="'2s'"
        [min]="0"
        [max]="2000"></dial>

    <!--(valueOutput)="updateDialControlledVariable($event, 'delayRight')"-->
</div>
