<div class="top-menu-with-arrows">
    <div class="component-title">{{ title }}</div>
    <div class="component-subtitle">{{ subtitle }}</div>

    <div class="navigation-container" *ngIf="!loading">
        <div class="previous" (click)="previous()" av-data="tmwa-previous">
            <img src="assets/img/hub/left_chevron.svg" />

            <div class="navigation-button-title">Previous</div>
        </div>

        <div class="steps">
            <div
                class="step"
                *ngFor="let step of getArrayOfSteps(); let s = index"
                [ngClass]="{ 'current-step': currentStepIndex === s }"
                (click)="onStepClick(s)"></div>
        </div>

        <div
            class="next"
            (click)="next()"
            *ngIf="showNext()"
            av-data="tmwa-next">
            <div class="navigation-button-title">Next</div>

            <img src="assets/img/hub/right_chevron.svg" />
        </div>

        <div class="next" *ngIf="!showNext()"></div>
    </div>

    <div class="loader" *ngIf="loading"></div>
</div>
