<div
    class="platform-component-container component-container"
    oncontextmenu="return false">
    <div
        class="gp-container"
        *ngIf="!gpIsLoading && error === undefined && gpView == 'edit'">
        <div
            class="error-banner tooltip-error"
            *ngIf="
                gpService.banner.getValue() != null &&
                gpService.banner.getValue().type == 'error' &&
                gpService.banner.getValue().show == true
            ">
            {{ gpService.banner.getValue().message }}
            <div class="close-banner" (click)="closeBanner()">x</div>
        </div>
        <div class="header">
            <div class="header-title" (dblclick)="renameGenerationProfile()">
                {{ getGenerationProfile().name }}
                <img
                    *ngIf="
                        gpValidation != null &&
                        (gpValidation.hasLayers == false ||
                            gpValidation.hasMainLayer == false)
                    "
                    class="tooltip-img"
                    src="assets/img/warning_red.svg"
                    [tooltip]="tooltips.noLayers"
                    [tooltip-class]="'tooltip-error'"
                    [tooltip-marginTop]="10"
                    [tooltip-width]="250" />
            </div>
            <div class="button-row" *ngIf="gpBelongsToUser()">
                <div
                    class="download-progress"
                    *ngIf="getDownloadProgress() < 100">
                    <div class="download-progress-title">
                        Downloading instruments
                    </div>
                    <progressbar
                        [progress]="getDownloadProgress()"
                        [total]="100"></progressbar>
                </div>

                <a-button
                    type="round-icon-and-text"
                    class="rounded-square primary-color"
                    label="Create"
                    (onClick)="openCompositionCreation()"
                    [icon]="'assets/img/add.svg'"></a-button>

                <a-button
                    [tooltip]="'Open in folder'"
                    [tooltip-position]="'middle-bottom'"
                    [tooltip-marginTop]="10"
                    type="round-icon-and-text"
                    class="rounded-square"
                    [icon]="'assets/img/back.svg'"
                    (click)="openInFolder($event)"></a-button>

                <a-button
                    [tooltip]="'View generated compositions'"
                    [tooltip-position]="'middle-bottom'"
                    [tooltip-marginTop]="10"
                    type="round-icon-and-text"
                    class="rounded-square"
                    [icon]="'assets/img/navbar/projects.svg'"
                    (click)="openGeneratedCompositions($event)"></a-button>

                <a-button
                    [tooltip]="'Publish to the Library'"
                    [tooltip-position]="'middle-bottom'"
                    [tooltip-marginTop]="10"
                    type="round-icon-and-text"
                    class="rounded-square"
                    [icon]="'assets/img/publish.svg'"
                    (click)="publishGP()"></a-button>

                <a-button
                    [tooltip]="'Options'"
                    [tooltip-position]="'middle-bottom'"
                    [tooltip-marginTop]="10"
                    type="round-icon-and-text"
                    class="rounded-square"
                    [icon]="'assets/img/more.svg'"
                    (click)="selectMoreOptions($event)"></a-button>
            </div>

            <div class="button-row" *ngIf="!gpBelongsToUser()">
                <a-button
                    type="round-icon-and-text"
                    class="add-to-library"
                    label="Import to my styles"
                    [icon]="'assets/img/add.svg'"
                    (onClick)="addToGPLibrary($event)"></a-button>
            </div>
        </div>

        <div
            class="scrollable-content-wrapper"
            [perfectScrollbar]="scrollConfig">
            <div
                class="tile-wrapper"
                (dragover)="dragOverHandler('Settings', $event)"
                (dragleave)="dragLeaveHandler('Settings', $event)"
                data-test="settings-tile">
                <div
                    class="tile general-settings"
                    *ngIf="!displayInfluenceDragover('Settings')"
                    (mouseenter)="hoveredTile(true, 'Settings', $event)"
                    (mouseleave)="hoveredTile(false, 'Settings', $event)">
                    <div class="title-bar">
                        <div class="title">Settings</div>
                        <div
                            class="button-row"
                            *ngIf="tileHovered == 'Settings'">
                            <a-button
                                type="round-icon-and-text"
                                class="rounded-square upload-gp-influence"
                                [icon]="'assets/img/menu/influences.svg'"
                                label="Upload influence"
                                (onClick)="
                                    selectGPInfluence('Settings')
                                "></a-button>
                        </div>
                    </div>
                    <div class="tile-content settings-content">
                        <div class="settings-row">
                            <div class="settings-row-title">
                                Dynamic Range
                                <img
                                    *ngIf="getTutorialMode()"
                                    class="tooltip-img"
                                    src="assets/img/info.svg"
                                    [tooltip]="tooltips.dynamicRange"
                                    [tooltip-marginTop]="10"
                                    [tooltip-width]="250" />
                            </div>
                            <div class="settings-row-value">
                                <range
                                    [className]="'range-dropdown-menu'"
                                    (valueChanges)="changedDynamicRange($event)"
                                    [class]="'light'"
                                    [type]="'dropdown'"
                                    [minLimit]="0"
                                    [maxLimit]="127"
                                    [minDifference]="0"
                                    [min]="getSettings().dynamicRange.min"
                                    [max]="getSettings().dynamicRange.max"
                                    [dropdownOptions]="dynamicOptions"></range>
                            </div>
                        </div>

                        <div class="settings-row">
                            <div class="settings-row-title">
                                Tempo Range
                                <img
                                    *ngIf="getTutorialMode()"
                                    class="tooltip-img"
                                    src="assets/img/info.svg"
                                    [tooltip]="tooltips.tempoRange"
                                    [tooltip-marginTop]="10"
                                    [tooltip-width]="250" />
                            </div>
                            <div class="settings-row-value">
                                <range
                                    (valueChanges)="changedTempoRange($event)"
                                    [class]="'light'"
                                    [type]="'number'"
                                    [minLimit]="getMinTempo()"
                                    [maxLimit]="getMaxTempo()"
                                    [minMax]="40"
                                    [minDifference]="5"
                                    [min]="getSettings().tempoRange.min"
                                    [max]="
                                        getSettings().tempoRange.max
                                    "></range>
                            </div>
                        </div>

                        <div class="settings-row">
                            <div class="settings-row-title">
                                Expressive performance
                                <img
                                    *ngIf="getTutorialMode()"
                                    class="tooltip-img"
                                    src="assets/img/info.svg"
                                    [tooltip]="tooltips.expressivePerformance"
                                    [tooltip-marginTop]="10"
                                    [tooltip-width]="250" />
                            </div>

                            <div class="settings-row-value">
                                <dropdown
                                    class="general-settings-dropdown"
                                    [sort]="true"
                                    [value]="getExpressivePerformance()"
                                    [showTooltip]="false"
                                    [items]="expressivePerformanceOptions"
                                    (selectedItem)="
                                        selectExpressivePerformance($event)
                                    "></dropdown>
                            </div>
                        </div>

                        <div class="settings-row">
                            <div class="settings-row-title">
                                Development
                                <img
                                    *ngIf="getTutorialMode()"
                                    class="tooltip-img"
                                    src="assets/img/info.svg"
                                    [tooltip]="tooltips.development"
                                    [tooltip-marginTop]="10"
                                    [tooltip-width]="250" />
                            </div>
                            <div class="settings-row-value">
                                <div class="settings-row-value">
                                    <div
                                        class="light-dropdown"
                                        (click)="selectDevelopment()">
                                        <div class="light-dropdown-title">
                                            {{ getFormTags() }}
                                        </div>
                                        <img
                                            src="assets/img/pianoroll/toggled.svg" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="settings-row">
                            <div class="settings-row-title">
                                Auto-mixing
                                <img
                                    *ngIf="getTutorialMode()"
                                    class="tooltip-img"
                                    src="assets/img/info.svg"
                                    [tooltip]="tooltips.automixing"
                                    [tooltip-marginTop]="10"
                                    [tooltip-width]="250" />
                            </div>

                            <div class="settings-row-value">
                                <div
                                    class="light-dropdown"
                                    (click)="openAutoMixingSettings()">
                                    <div class="light-dropdown-title">
                                        {{ getAutoMixingTitle() }}
                                    </div>
                                    <img
                                        src="assets/img/pianoroll/toggled.svg" />
                                </div>
                            </div>
                        </div>

                        <div class="settings-row">
                            <div class="settings-row-title">
                                Emotion
                                <img
                                    *ngIf="getTutorialMode()"
                                    class="tooltip-img"
                                    src="assets/img/info.svg"
                                    [tooltip]="tooltips.emotion"
                                    [tooltip-marginTop]="10"
                                    [tooltip-width]="250" />
                            </div>

                            <div class="settings-row-value">
                                <emotion-select
                                    [type]="'gp'"
                                    [emotion]="selectedEmotions()"
                                    [emotions]="emotions"
                                    [showWarning]="true"
                                    (onChange)="
                                        onEmotionChange($event)
                                    "></emotion-select>
                            </div>
                        </div>

                        <div class="settings-row">
                            <div class="settings-row-title">Time Signature</div>
                            <div class="settings-row-value">
                                <dropdown
                                    [sort]="true"
                                    [value]="getSelectedTimeSignature()"
                                    *ngIf="!timeSignatureLoading"
                                    [showTooltip]="false"
                                    [items]="timeSignatureOptions"
                                    (selectedItem)="selectTimeSignature($event)"
                                    class="gp-time-signature"></dropdown>

                                <div
                                    class="loader time-signature-loader"
                                    *ngIf="timeSignatureLoading"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <influence-dragover
                    [gpComponent]="'Settings'"
                    class="tile general-settings"
                    *ngIf="
                        displayInfluenceDragover('Settings')
                    "></influence-dragover>
            </div>

            <div class="content" *ngIf="getAccompanimentLayers() != null">
                <div
                    class="tile-wrapper"
                    (dragover)="dragOverHandler('Melody', $event)"
                    (dragleave)="dragLeaveHandler('Melody', $event)"
                    *ngIf="getMelody() != null && getMelody().packs.length > 0"
                    data-test="melody-tile">
                    <div
                        class="tile melody layer"
                        *ngIf="!displayInfluenceDragover('Melody')"
                        (mouseenter)="hoveredTile(true, 'Melody', $event)"
                        (mouseleave)="hoveredTile(false, 'Melody', $event)">
                        <div class="title-bar">
                            <div class="title">
                                {{ getMelody().name }}
                                <img
                                    *ngIf="
                                        gpValidation != null &&
                                        gpValidation.melodyLayer != null
                                    "
                                    class="tooltip-img"
                                    src="assets/img/warning_red.svg"
                                    [tooltip]="gpValidation.melodyLayer"
                                    [tooltip-class]="'tooltip-error'"
                                    [tooltip-marginTop]="10"
                                    [tooltip-width]="250" />
                            </div>
                            <div
                                class="button-row"
                                *ngIf="
                                    tileHovered == 'Melody' ||
                                    (layerOptions != null &&
                                        layerOptions.layer == 'Melody')
                                ">
                                <a-button
                                    type="round-icon-and-text"
                                    class="rounded-square upload-gp-influence"
                                    [icon]="'assets/img/menu/influences.svg'"
                                    (onClick)="selectGPInfluence('Melody')"
                                    label="Upload influence"></a-button>
                                <a-button
                                    type="round-icon-and-text"
                                    class="rounded-square layer-options"
                                    [icon]="'assets/img/more.svg'"
                                    (onClick)="
                                        openLayerOptions($event, getMelody())
                                    "></a-button>
                            </div>
                        </div>
                        <div class="tile-content">
                            <div class="melody-tile-content-block">
                                <pack
                                    type="melody"
                                    [layer]="getMelody()"
                                    [pack]="getMelody().packs[0]"></pack>
                            </div>

                            <div class="melody-tile-content-block">
                                <div class="settings-content">
                                    <div class="settings-row">
                                        <div class="settings-row-title">
                                            Phrase length
                                        </div>
                                        <div class="settings-row-value">
                                            <dropdown
                                                [sort]="true"
                                                [value]="
                                                    getSelectedPhraseLength()
                                                "
                                                [showTooltip]="false"
                                                [items]="phraseLengthOptions"
                                                (selectedItem)="
                                                    selectPhraseLength($event)
                                                "></dropdown>
                                        </div>
                                    </div>

                                    <div class="settings-row">
                                        <div class="settings-row-title">
                                            Complexity
                                        </div>
                                        <div class="settings-row-value">
                                            <ngx-slider
                                                (userChangeEnd)="
                                                    changeMelodyComplexity(
                                                        $event
                                                    )
                                                "
                                                class="light-ngx-slider"
                                                [(value)]="
                                                    getMelody().packs[0]
                                                        .complexity
                                                "
                                                [options]="
                                                    getMelodicComplexityOptions()
                                                "></ngx-slider>
                                        </div>
                                    </div>

                                    <div class="settings-row">
                                        <div class="settings-row-title">
                                            Add swing
                                        </div>
                                        <div class="settings-row-value">
                                            <slide-toggle
                                                (onChange)="addSwing($event)"
                                                [(toggled)]="
                                                    getMelody().swing
                                                "></slide-toggle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <influence-dragover
                        [gpComponent]="'Melody'"
                        class="tile melody layer"
                        *ngIf="
                            displayInfluenceDragover('Melody')
                        "></influence-dragover>
                </div>

                <div
                    class="tile-wrapper"
                    (dragover)="dragOverHandler('Harmony', $event)"
                    (dragleave)="dragLeaveHandler('Harmony', $event)"
                    data-test="harmony-tile">
                    <div
                        class="tile harmony layer"
                        *ngIf="!displayInfluenceDragover('Harmony')"
                        (mouseenter)="hoveredTile(true, 'Harmony', $event)"
                        (mouseleave)="hoveredTile(false, 'Harmony', $event)">
                        <div class="title-bar">
                            <div class="title">
                                Harmony
                                <img
                                    *ngIf="
                                        gpValidation != null &&
                                        gpValidation.harmony != null
                                    "
                                    class="tooltip-img"
                                    src="assets/img/warning_red.svg"
                                    [tooltip]="gpValidation.harmony"
                                    [tooltip-class]="'tooltip-error'"
                                    [tooltip-marginTop]="10"
                                    [tooltip-width]="250" />
                            </div>
                            <div class="button-row">
                                <a-button
                                    type="round-icon-and-text"
                                    class="rounded-square upload-gp-influence"
                                    [icon]="'assets/img/menu/influences.svg'"
                                    label="Upload influence"
                                    (onClick)="selectGPInfluence('Harmony')"
                                    *ngIf="
                                        tileHovered == 'Harmony' ||
                                        (layerOptions != null &&
                                            layerOptions.layer == 'Harmony')
                                    "></a-button>
                                <a-button
                                    type="round-icon-and-text"
                                    [loading]="isHarmonyPreviewLoading()"
                                    width="100%"
                                    [label]="'Preview'"
                                    [icon]="getPlaybackIcon('Harmony')"
                                    (onClick)="preview('Harmony')"
                                    [disabled]="
                                        gpValidation != null &&
                                        gpValidation.harmony != null
                                    "></a-button>
                            </div>
                        </div>

                        <div class="tile-content">
                            <div class="settings-row">
                                <div class="settings-row-title">
                                    Strategy
                                    <img
                                        *ngIf="getTutorialMode()"
                                        class="tooltip-img"
                                        src="assets/img/info.svg"
                                        [tooltip]="tooltips.harmonicStrategy"
                                        [tooltip-marginTop]="10"
                                        [tooltip-width]="250" />
                                </div>

                                <div class="settings-row-value">
                                    <dropdown
                                        type="light"
                                        [value]="getHarmonicStrategy()"
                                        [showTooltip]="false"
                                        [items]="harmonyTypes"
                                        (selectedItem)="
                                            selectHarmonicStrategy($event)
                                        ">
                                    </dropdown>
                                </div>
                            </div>
                            <div
                                class="settings-row"
                                *ngIf="
                                    getHarmonicStrategy().value === 'Functional'
                                ">
                                <div class="settings-row-title">
                                    Dataset
                                    <img
                                        *ngIf="getTutorialMode()"
                                        class="tooltip-img"
                                        src="assets/img/info.svg"
                                        [tooltip]="tooltips.harmonicDataset"
                                        [tooltip-marginTop]="10"
                                        [tooltip-width]="250" />
                                </div>

                                <div class="settings-row-value">
                                    <div
                                        class="light-dropdown chord-dataset"
                                        (click)="changeChordDataset()">
                                        <div
                                            class="light-dropdown-title populated-chord-pack-title"
                                            *ngIf="
                                                getHarmony().packs != null &&
                                                getHarmony().packs.length > 1
                                            ">
                                            <div class="chord-pack-title">
                                                {{ getHarmony().packs[0].name }}
                                            </div>
                                            <div class="chord-pack-styles">
                                                +
                                                {{
                                                    getHarmony().packs.length -
                                                        1
                                                }}
                                                more
                                            </div>
                                        </div>

                                        <div
                                            class="light-dropdown-title populated-chord-pack-title"
                                            *ngIf="
                                                getHarmony().packs != null &&
                                                getHarmony().packs.length == 1
                                            ">
                                            <div class="chord-pack-title">
                                                {{ getHarmony().packs[0].name }}
                                            </div>
                                            <div class="chord-pack-styles">
                                                <div
                                                    class="chord-pack-style"
                                                    *ngFor="
                                                        let style of getHarmony()
                                                            .packs[0]
                                                            .recommendedStyles;
                                                        let s = index
                                                    "
                                                    [ngClass]="{
                                                        'chord-pack-style-margin-right':
                                                            s <
                                                            getHarmony()
                                                                .packs[0]
                                                                .recommendedStyles
                                                                .length -
                                                                1
                                                    }">
                                                    {{ style }}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="light-dropdown-title"
                                            *ngIf="
                                                getHarmony().packs == null ||
                                                getHarmony().packs.length == 0
                                            ">
                                            <div class="chord-pack-title">
                                                No dataset selected
                                            </div>
                                        </div>

                                        <img
                                            src="assets/img/pianoroll/toggled.svg" />
                                    </div>
                                </div>
                            </div>

                            <div
                                class="settings-row"
                                *ngIf="getHarmony() != null">
                                <div class="settings-row-title">
                                    Harmonic repetition
                                    <img
                                        *ngIf="getTutorialMode()"
                                        class="tooltip-img"
                                        src="assets/img/info.svg"
                                        [tooltip]="tooltips.harmonicRepetition"
                                        [tooltip-marginTop]="10"
                                        [tooltip-width]="250" />
                                </div>
                                <div class="settings-row-value">
                                    <ngx-slider
                                        (userChangeEnd)="
                                            changeHarmonicRepetition($event)
                                        "
                                        class="harmonic-repetition-slider light-ngx-slider"
                                        [(value)]="
                                            getAggregatedHarmonicRepetition()
                                                .min
                                        "
                                        [(highValue)]="
                                            getAggregatedHarmonicRepetition()
                                                .max
                                        "
                                        [options]="
                                            getHarmonicRepetitionOptions()
                                        ">
                                    </ngx-slider>
                                </div>
                            </div>

                            <div
                                class="settings-row"
                                *ngIf="getHarmony() != null">
                                <div class="settings-row-title">
                                    Harmonic pacing
                                    <img
                                        *ngIf="getTutorialMode()"
                                        class="tooltip-img"
                                        src="assets/img/info.svg"
                                        [tooltip]="tooltips.harmonicPacing"
                                        [tooltip-marginTop]="10"
                                        [tooltip-width]="250" />
                                </div>
                                <div class="settings-row-value">
                                    <ngx-slider
                                        (userChangeEnd)="
                                            changeHarmonicRhythm($event)
                                        "
                                        class="harmonic-repetition-slider light-ngx-slider"
                                        [(value)]="
                                            getAggregatedHarmonicRhythm().min
                                        "
                                        [(highValue)]="
                                            getAggregatedHarmonicRhythm().max
                                        "
                                        [options]="getHarmonicRhythmOptions()">
                                    </ngx-slider>
                                </div>
                            </div>

                            <div
                                class="settings-row"
                                *ngIf="
                                    getHarmonicStrategy().value === 'Functional'
                                ">
                                <div class="settings-row-title">
                                    Key Signature
                                </div>
                                <div class="settings-row-value">
                                    <div
                                        class="loader ks-loader"
                                        *ngIf="
                                            keySignatureOptions == null
                                        "></div>

                                    <dropdown
                                        *ngIf="keySignatureOptions != null"
                                        type="light"
                                        [value]="getKeySignature()"
                                        [showTooltip]="false"
                                        [items]="keySignatureOptions"
                                        (selectedItem)="
                                            selectKeySignature($event)
                                        "></dropdown>
                                </div>
                            </div>

                            <div
                                class="settings-row"
                                *ngIf="getHarmonicStrategy().value === 'Modal'">
                                <div class="settings-row-title">Mode</div>
                                <div class="settings-row-value">
                                    <dropdown
                                        type="light"
                                        [value]="getKeyMode()"
                                        [showTooltip]="false"
                                        [items]="keyModeOptions"
                                        (selectedItem)="
                                            selectKeyMode($event)
                                        "></dropdown>
                                </div>
                            </div>

                            <div
                                class="settings-row"
                                *ngIf="getHarmonicStrategy().value === 'Modal'">
                                <div class="settings-row-title">
                                    Pitch class
                                </div>
                                <div class="settings-row-value">
                                    <dropdown
                                        type="light"
                                        [value]="getPitchClass()"
                                        [showTooltip]="false"
                                        [items]="pitchClassOptions"
                                        (selectedItem)="
                                            selectPitchClass($event)
                                        "></dropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                    <influence-dragover
                        [gpComponent]="'Harmony'"
                        class="tile harmony layer"
                        *ngIf="
                            displayInfluenceDragover('Harmony')
                        "></influence-dragover>
                </div>

                <div
                    class="tile-wrapper"
                    *ngFor="
                        let layer of getAccompanimentLayers();
                        trackBy: trackByAccompanimentLayer
                    "
                    (dragover)="dragOverHandler(layer.name, $event)"
                    (dragleave)="dragLeaveHandler(layer.name, $event)"
                    [attr.data-test]="layer.name + '-tile'">
                    <accompaniment-layer
                        *ngIf="!displayInfluenceDragover(layer.name)"
                        (mouseenter)="hoveredTile(true, layer.name, $event)"
                        (mouseleave)="hoveredTile(false, layer.name, $event)"
                        (triggerLayerOptions)="
                            openLayerOptions($event.event, $event.layer)
                        "
                        [layer]="layer"
                        [gpValidation]="gpValidation"
                        [tileHovered]="tileHovered"
                        [layerOptions]="layerOptions"
                        (onEditPack)="
                            gpService.openAccompanimentDesigner(
                                $event,
                                layer?.name
                            )
                        ">
                    </accompaniment-layer>

                    <!-- <influence-dragover [gpComponent]="layer.name" class="tile layer influence-dragover" (onDragOrDrop)="onInfluenceDragOrDrop($event)" *ngIf="displayInfluenceDragover(layer.name)"></influence-dragover> -->
                    <influence-dragover
                        [gpComponent]="layer.name"
                        class="tile layer"
                        *ngIf="
                            displayInfluenceDragover(layer.name)
                        "></influence-dragover>
                </div>

                <div
                    class="tile layer add-layer-tile"
                    *ngIf="getNbOfCards() % 2 == 1">
                    <a-button
                        class="add-layer-tile-button"
                        [label]="'Add new layer'"
                        [icon]="'assets/img/add.svg'"
                        type="round-icon-and-text"
                        (onClick)="addLayer()"></a-button>
                </div>
            </div>

            <div
                class="tile layer add-layer-tile-small"
                *ngIf="getNbOfCards() % 2 == 0 && canAddLayers()">
                <a-button
                    class="add-layer-tile-small-button"
                    [label]="'Add new layer'"
                    [icon]="'assets/img/add.svg'"
                    type="round-icon-and-text"
                    (onClick)="addLayer()"></a-button>
            </div>

            <div class="empty-space"></div>
        </div>
    </div>

    <div
        class="gp-container gp-container-compositions"
        *ngIf="!gpIsLoading && error === undefined && gpView == 'compositions'">
        <div class="header">
            <div class="header-title" (dblclick)="renameGenerationProfile()">
                {{ getGenerationProfile().name }}
                <img
                    *ngIf="
                        gpValidation != null && gpValidation.hasLayers == false
                    "
                    class="tooltip-img"
                    src="assets/img/warning_red.svg"
                    [tooltip]="tooltips.noLayers"
                    [tooltip-class]="'tooltip-error'"
                    [tooltip-marginTop]="10"
                    [tooltip-width]="250" />
            </div>
            <div class="button-row">
                <a-button
                    type="round-icon-and-text"
                    class="rounded-square primary-color"
                    label="Create"
                    (onClick)="openCompositionCreation()"
                    [icon]="'assets/img/add.svg'"></a-button>

                <a-button
                    type="round-icon-and-text"
                    class="rounded-square"
                    label="Back to editing"
                    [icon]="'assets/img/back.svg'"
                    (click)="backToEditMode($event)"></a-button>
            </div>
        </div>

        <div class="tracks-body mytracks-body">
            <div id="track-view">
                <tracks-list
                    [allowMoving]="false"
                    [allowFolders]="false"></tracks-list>
            </div>
        </div>
    </div>

    <div class="gp-loading-container" *ngIf="gpIsLoading">
        <div class="loader"></div>
    </div>

    <div
        class="gp-loading-container message-container"
        *ngIf="error !== undefined">
        <div>{{ error }}</div>

        <div
            class="message-container-button"
            *ngIf="error.includes('isn\'t yours')">
            <a-button
                (onClick)="addToGPLibrary($event)"
                label="Import profile to my library"
                type="primary"></a-button>
        </div>
    </div>
</div>
<menu-options
    *ngIf="menuOptions !== undefined"
    [menuOptions]="menuOptions.options"
    (close)="unselectMenu()"></menu-options>
