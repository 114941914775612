import Tempo from "../classes/score/tempo"
import { TIMESTEP_RES } from "../constants/constants"
import { cloneDeep } from "lodash"
import { FractionString, TimeSignature } from "../types/score"

export interface GridPosition {
    bar: number
    beat: number
    notePosition: number
}

export module Time {
    /**
     * This function is useful to extract ranges with a start and end position, and
     * make sure that those ranges are trimed to the boundaries of the start and end
     * value. This can be useful to extract key signature or automation data from a
     * section's boundaries, for example.
     */
    export function getRangesInRegion({
        ranges,
        start,
        end,
    }: {
        ranges: { start: string; end: string; data?: any }[]
        start: string
        end: string
    }): { start: string; end: string; data: any }[] {
        const results = []

        for (const range of ranges) {
            if (Time.compareTwoFractions(range.start, end) === "gt") {
                break
            }

            const overlap = Time.rangesAreOverlapping(range, {
                start,
                end,
            })

            if (overlap.overlap) {
                const newRange: { start: string; end: string; data?: any } = {
                    start: overlap.overlapRange.start,
                    end: overlap.overlapRange.end,
                }

                if (range.data) {
                    newRange.data = range.data
                }

                results.push(newRange)
            }
        }

        return results
    }

    /**
     * Returns whether two ranges of fractions are overlapping,
     * as well as the overlap range if applicable
     */
    export function rangesAreOverlapping(
        range1: { start: string; end: string },
        range2: { start: string; end: string }
    ): {
        overlap: boolean
        overlapRange?: {
            start: string
            end: string
        }
        isFullOverlap?: boolean
    } {
        if (
            Time.compareTwoFractions(range1.start, range2.end) === "lt" &&
            Time.compareTwoFractions(range2.start, range1.end) === "lt"
        ) {
            const overlapStart = Time.max(range1.start, range2.start)
            const overlapEnd = Time.min(range1.end, range2.end)
            const isFullOverlap =
                (Time.compareTwoFractions(overlapStart, range1.start) ===
                    "eq" &&
                    Time.compareTwoFractions(overlapEnd, range1.end) ===
                        "eq") ||
                (Time.compareTwoFractions(overlapStart, range2.start) ===
                    "eq" &&
                    Time.compareTwoFractions(overlapEnd, range2.end) === "eq")

            return {
                overlap: true,
                overlapRange: {
                    start: overlapStart,
                    end: overlapEnd,
                },
                isFullOverlap,
            }
        }

        return {
            overlap: false,
        }
    }

    /**
     *
     * @param first x/y coordinates
     * @param second x/y coordinates
     * @param newXValue
     * @param round
     * @returns
     */
    export function interpolate(
        first: [number, number],
        second: [number, number],
        newXValue: number,
        round: boolean
    ) {
        const ya = first[1]
        const yb = second[1]

        if (yb - ya == 0) {
            return ya
        }

        const xa = first[0]
        const xb = second[0]

        let result = (ya * (xb - newXValue) + yb * (newXValue - xa)) / (xb - xa)

        if (round) {
            result = Math.round(result)
        }

        return result
    }

    /**
     * Given a fraction, split it into parts of a specific length.
     * E.g. given 9/4 and 1/2, return [1/2, 1/2, 1/2, 1/2, 1/4]
     */
    export function splitFraction(
        toSplit: FractionString,
        splitLength: FractionString
    ) {
        const result = []

        let fraction = toSplit

        while (Time.compareTwoFractions(fraction, "0") === "gt") {
            const split = Time.min(splitLength, fraction)

            result.push(split)

            fraction = Time.addTwoFractions(fraction, split, true)
        }

        return result
    }

    export function aIsDivisibleByB(a: number, b: number) {
        return a >= b && a % b === 0
    }

    export function mergeOverlappingIntervalsDescending(
        intervals: {
            start: FractionString
            end: FractionString
            data?: any
        }[]
    ) {
        const result = []

        const intervalsCopy = cloneDeep(intervals)

        intervalsCopy.sort((a, b) => {
            return Time.compareTwoFractions(a.end, b.end) === "lt" ? -1 : 1
        })

        for (let i = intervalsCopy.length - 1; i >= 0; i--) {
            if (i === 0) {
                result.unshift(intervalsCopy[i])

                continue
            }

            const current = intervalsCopy[i]
            const previous = intervalsCopy[i - 1]

            // If there is no overlap
            if (
                Time.compareTwoFractions(current.start, previous.end) !== "lt"
            ) {
                result.unshift(current)
            }

            // If the overlap is partial
            else if (
                Time.compareTwoFractions(current.start, previous.start) === "gt"
            ) {
                previous.end = current.start

                i++
            }

            // If the overlap is complete
            else {
                intervalsCopy.splice(i - 1, 1)
            }
        }

        return result
    }

    /**
     * Converts Fraction duration into milliseconds given time signature and tempo.
    ​
        Parameters
        ----------
        duration
            Duration in fractions of a whole note.
        tempo
            Beats or quarter notes per minute. Tempo units controlled by `tempo_resolution`.
    ​
        >>> fraction_to_milliseconds(Fraction(1,16), 60)
        250
        >>> fraction_to_milliseconds(Fraction(1,8), 60, [6,8], 'bpm')
        333
        >>> fraction_to_milliseconds(Fraction(1,8), 60, [6,8], 'qpm')
        500
     */
    export function fractionToMilliseconds({
        duration,
        tempo,
    }: {
        duration: FractionString
        tempo: number
    }): number {
        const beatDuration = "1/4"
        const bps = tempo / 60.0
        const beats = Time.divideTwoFractions(duration, beatDuration)

        return Math.round((beats / bps) * 1000)
    }

    /**
     * Given a list of intervals, merge as follows:
     * if two intervals overlap partially, increase the start of the next one to end of previous one
     * if two intervals overlap completely, remove the second one and increase the size of the previous one
     *
     * @param intervals
     */
    export function mergeOverlappingIntervalsAscending(
        intervals: {
            start: FractionString
            end: FractionString
            data?: any
        }[]
    ) {
        const result = []
        const intervalsCopy = cloneDeep(intervals)

        intervalsCopy.sort((a, b) => {
            return Time.compareTwoFractions(a.start, b.start) === "lt" ? -1 : 1
        })

        for (let i = 0; i < intervalsCopy.length; i++) {
            if (i === intervalsCopy.length - 1) {
                result.push(intervalsCopy[i])

                continue
            }

            const current = intervalsCopy[i]
            const next = intervalsCopy[i + 1]

            // If there is no overlap
            if (Time.compareTwoFractions(current.end, next.start) !== "gt") {
                result.push(current)
            }

            // If the overlap is partial
            else if (Time.compareTwoFractions(current.end, next.end) === "lt") {
                next.start = current.end

                i--
            }

            // If the overlap is complete
            else {
                intervalsCopy.splice(i + 1, 1)

                i--
            }
        }

        return result
    }

    // Get the number of beats, given a fraction in dictionary format
    export function fractionToBeats(fraction, resolution) {
        const f = fractionToDictionary(fraction)

        return (f.numerator / f.denominator) * resolution
    }

    export function noteFractionsToSeconds(note, timestepRes, tempo: Tempo[]) {
        return {
            start: fractionToSeconds(timestepRes, tempo, note.start),
            duration: fractionToSeconds(
                timestepRes,
                tempo,
                addTwoFractions(note.duration, note.start)
            ),
        }
    }

    export function abs(fraction: FractionString): FractionString {
        const result = Time.fractionToDictionary(fraction)

        return Math.abs(result.numerator) + "/" + Math.abs(result.denominator)
    }

    export function fractionToNumber(fraction) {
        const result = fractionToDictionary(fraction)

        return result.numerator / result.denominator
    }

    export function min(first, second) {
        if (compareTwoFractions(first, second) == "lt") {
            return first
        }

        return second
    }

    export function max(first, second) {
        if (compareTwoFractions(first, second) == "gt") {
            return first
        }

        return second
    }

    export function maxInArray(array: FractionString[]) {
        let max = "0"

        array.forEach(fraction => {
            max = Time.max(max, fraction)
        })

        return max
    }

    export function roundToSignificantDigit(value, digits) {
        value = Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits)

        return value
    }

    export function measuresToFraction(
        measures: number,
        timeSignature: TimeSignature
    ) {
        const wholeNotesInMeasure = timeSignature[0] / timeSignature[1]

        const fraction = Math.floor(
            measures * wholeNotesInMeasure * TIMESTEP_RES
        )

        return simplifyFraction(fraction, TIMESTEP_RES)
    }

    export function measureToTimesteps(
        measures: number,
        timeSignature: TimeSignature,
        timestepRes = TIMESTEP_RES
    ) {
        const wholeNotesInMeasure = timeSignature[0] / timeSignature[1]

        return measures * wholeNotesInMeasure * timestepRes
    }

    export function fractionToMeasures(
        fraction: FractionString,
        timeSignature: TimeSignature
    ) {
        const wholeNotesInMeasure = timeSignature[0] / timeSignature[1]

        return fractionToNumber(fraction) / wholeNotesInMeasure
    }

    export function fractionIsInBoundaries(
        boundary: { start: string; duration: string },
        fraction: string,
        includeStart = true,
        includeEnd = false
    ) {
        const end = addTwoFractions(boundary.start, boundary.duration)

        const temp1 =
            (includeStart &&
                compareTwoFractions(boundary.start, fraction) !== "gt") ||
            (!includeStart &&
                compareTwoFractions(boundary.start, fraction) === "lt")

        const temp2 =
            (!includeEnd && compareTwoFractions(end, fraction) === "gt") ||
            (includeEnd && compareTwoFractions(end, fraction) !== "lt")

        return temp1 && temp2
    }

    export function boundariesOverlap(boundary1, boundary2) {
        const boundary2End = addTwoFractions(
            boundary2.start,
            boundary2.duration
        )
        const boundary1End = addTwoFractions(
            boundary1.start,
            boundary1.duration
        )

        const boundary2IsInBoundary1 =
            fractionIsInBoundaries(boundary1, boundary2.start, false, false) ||
            fractionIsInBoundaries(boundary1, boundary2End, false, false)
        const boundary1IsInBoundary2 =
            fractionIsInBoundaries(boundary2, boundary1.start, false, false) ||
            fractionIsInBoundaries(boundary2, boundary1End, false, false)

        return (
            boundary2IsInBoundary1 ||
            boundary1IsInBoundary2 ||
            compareTwoFractions(boundary1.start, boundary2.start) == "eq"
        )
    }

    export function beatToTimesteps(
        beats,
        timeSignature: TimeSignature,
        timestepRes = TIMESTEP_RES
    ) {
        return (beats * timestepRes) / timeSignature[1]
    }

    export function barToTimestep(
        bar,
        timeSignature: TimeSignature,
        timestepRes = TIMESTEP_RES
    ) {
        return ((bar * timeSignature[0]) / timeSignature[1]) * timestepRes
    }

    export function timestepsToBar(
        timestep,
        timeSignature: TimeSignature,
        timestepRes = TIMESTEP_RES
    ) {
        return (timestep * timeSignature[1]) / (timestepRes * timeSignature[0])
    }

    export function timestepsToGridPosition(
        timesteps: number,
        timeSignature: TimeSignature,
        timestepRes = TIMESTEP_RES
    ): GridPosition {
        const bar = Math.floor(
            Time.timestepsToBar(timesteps, timeSignature, timestepRes)
        )
        const barTimesteps = Time.barToTimestep(bar, timeSignature, timestepRes)

        const beat = Time.timestepsToBeat(
            timesteps - barTimesteps,
            timeSignature,
            timestepRes,
            "floor"
        )

        const notePosition =
            timesteps -
            Time.beatToTimesteps(beat, timeSignature, timestepRes) -
            barTimesteps

        return {
            bar: Math.floor(bar),
            beat,
            notePosition,
        }
    }

    export function timestepToFraction(timesteps: number, timestepRes: number) {
        return simplifyFractionFromString(timesteps + "/" + timestepRes)
    }

    export function beatToFraction(beat, timestepRes = TIMESTEP_RES) {
        return timestepsToFraction(timestepRes, beat * timestepRes)
    }

    export function timestepsToBeat(
        timesteps: number,
        timeSignature: TimeSignature,
        timestepRes = TIMESTEP_RES,
        round: "ceil" | "round" | "floor" | "none" = "round"
    ) {
        const result = timesteps / (timestepRes / timeSignature[1])

        if (round === "round") {
            return Math.round(result)
        } else if (round === "ceil") {
            return Math.ceil(result)
        } else if (round === "floor") {
            return Math.floor(result)
        }

        return result
    }

    export function timestepsToHalfBeat(
        timesteps: number,
        timeSignature: TimeSignature,
        timestepRes = TIMESTEP_RES,
        round: "ceil" | "round" | "floor" | "none" = "round"
    ) {
        const result = timesteps / ((timestepRes / timeSignature[1]) * 2)

        if (round === "round") {
            return Math.round(result)
        } else if (round === "ceil") {
            return Math.ceil(result)
        } else if (round === "floor") {
            return Math.floor(result)
        }

        return result
    }

    export function fractionToBeat(
        fraction,
        timeSignature: TimeSignature,
        round = "round"
    ) {
        const result =
            timestepsToBar(
                fractionToTimesteps(TIMESTEP_RES, fraction),
                timeSignature
            ) * timeSignature[0]

        if (round === "round") {
            return Math.round(result)
        } else if (round === "ceil") {
            return Math.ceil(result)
        }

        return Math.floor(result)
    }

    export function multiplyFractionWithNumber(fraction, number) {
        if (number == 0) {
            return "0"
        }

        const dictionary = fractionToDictionary(fraction)

        return simplifyFractionFromString(
            dictionary.numerator * number + "/" + dictionary.denominator
        )
    }

    export function quantizeFractionToTSBeat(
        fraction: FractionString,
        timeSignature: TimeSignature
    ): FractionString {
        const number = Time.fractionToNumber(fraction)
        const tsBeat = Time.fractionToNumber(Time.getTSBeat(timeSignature))

        const result = Math.floor(number / tsBeat) * tsBeat

        return simplifyFractionFromString(
            result * TIMESTEP_RES + "/" + TIMESTEP_RES
        )
    }

    export function quantizeFractionToBar(timeSignature, fraction) {
        const fractionInWholeNotes = fractionToNumber(fraction)
        const timeSignatureInWholeNotes = timeSignature[0] / timeSignature[1]

        const result =
            Math.floor(fractionInWholeNotes / timeSignatureInWholeNotes) *
            timeSignatureInWholeNotes

        const fractionResult = result * TIMESTEP_RES + "/" + TIMESTEP_RES

        return simplifyFractionFromString(fractionResult)
    }

    export function divideFractionWithNumber(fraction, number) {
        const dictionary = fractionToDictionary(fraction)

        return simplifyFractionFromString(
            dictionary.numerator + "/" + dictionary.denominator * number
        )
    }

    export function modulo(
        fraction1: FractionString,
        fraction2: FractionString
    ): number {
        // Parse the input fractions
        const one = Time.fractionToNumber(fraction1)
        const two = Time.fractionToNumber(fraction2)

        return one % two
    }

    export function divideTwoFractions(fraction1, fraction2) {
        const dictionary1 = fractionToDictionary(fraction1)
        const dictionary2 = fractionToDictionary(fraction2)

        const newNumerator = dictionary1.numerator * dictionary2.denominator
        const newDenominator = dictionary1.denominator * dictionary2.numerator

        return newNumerator / newDenominator
    }

    export function isDivisibleBy(one: string, two: string) {
        return (
            Time.modulo(one, two) === 0 &&
            Time.compareTwoFractions(one, two) === "gt"
        )
    }

    /**
     * The time signature beat is a little bit different from what we call beat elsewhere in the application.
     * E.g. for 6/8 or 12/8, the ts beat is 3/8, and for 4/4 it is 1/4. So it doesnt always match the
     * denominator of the time signature
     */
    export function getTSBeat(ts: TimeSignature): FractionString {
        if (Time.isCompound(ts)) {
            return "3/" + ts[1]
        }

        return "1/" + ts[1]
    }

    export function isCompound(ts: TimeSignature) {
        return ts[0] % 3 === 0 && ts[1] === 8
    }

    export function breakUpCrossingElement(
        element: {
            start: FractionString
            end: FractionString
        },
        timeSignature: TimeSignature
    ): {
        start: FractionString
        end: FractionString
    }[] {
        const oneBar = timeSignature[0] + "/" + timeSignature[1]
        const inclusiveEnd = Time.addTwoFractions(element.end, "1/48", true)

        const startMeasure = Math.floor(
            Time.divideTwoFractions(element.start, oneBar)
        )
        const endMeasure = Math.floor(
            Time.divideTwoFractions(inclusiveEnd, oneBar)
        )

        if (startMeasure < endMeasure) {
            const nextMeasureStart = Time.multiplyFractionWithNumber(
                oneBar,
                startMeasure + 1
            )

            const newElement = {
                start: element.start,
                end: nextMeasureStart,
            }

            const nextElements = Time.breakUpCrossingElement(
                {
                    start: nextMeasureStart,
                    end: element.end,
                },
                timeSignature
            )

            nextElements.unshift(newElement)

            return nextElements
        } else if (endMeasure < startMeasure) {
            console.warn("breakUpCrossingElement - Data not as expected")
        }

        return [cloneDeep(element)]
    }

    export function adjustNoteToClosestGrid(
        timestepRes,
        dividers,
        timesteps,
        returnType = "string"
    ): any {
        const beatStart = Math.floor(
            (Math.floor((timesteps / timestepRes) * dividers.beatDenominator) /
                dividers.beatDenominator) *
                timestepRes
        )
        const tripletStart = Math.floor(
            (Math.floor(
                (timesteps / timestepRes) * dividers.tripletDenominator
            ) /
                dividers.tripletDenominator) *
                timestepRes
        )
        const regularStart = Math.floor(
            (Math.floor(
                (timesteps / timestepRes) * dividers.regularDenominator
            ) /
                dividers.regularDenominator) *
                timestepRes
        )

        const beatDifference = {
            divider: dividers.beatDenominator,
            start: beatStart,
            distance: Math.abs(beatStart - timesteps),
            positive: beatStart - timesteps >= 0,
        }
        const tripletDifference = {
            divider: dividers.tripletDenominator,
            start: tripletStart,
            distance: Math.abs(tripletStart - timesteps),
            positive: tripletStart - timesteps >= 0,
        }
        const regularDifference = {
            divider: dividers.regularDenominator,
            start: regularStart,
            distance: Math.abs(regularStart - timesteps),
            positive: regularStart - timesteps >= 0,
        }

        let result

        if (beatDifference.distance < tripletDifference.distance) {
            result = beatDifference
        } else if (beatDifference.distance == tripletDifference.distance) {
            result = !beatDifference.positive
                ? beatDifference
                : tripletDifference
        } else {
            result = tripletDifference
        }

        if (
            regularDifference.distance < result.distance ||
            (regularDifference.distance == result.distance &&
                !regularDifference.positive)
        ) {
            result = regularDifference
        }

        if (returnType == "string") {
            return quantizeFractionToString(
                timestepsToFraction(timestepRes, timesteps),
                "floor",
                result.divider
            )
        } else {
            return quantizeFraction(
                timestepsToFraction(timestepRes, timesteps),
                "floor",
                result.divider
            )
        }
    }

    export function fractionToDictionary(fraction) {
        const dict = {
            numerator: 0,
            denominator: 1,
        }

        if (fraction != null) {
            const temp = fraction.split("/")

            if (temp.length > 1) {
                dict.numerator = parseInt(temp[0])
                dict.denominator = parseInt(temp[1])
            } else {
                dict.numerator = parseInt(temp[0])
            }
        }

        return dict
    }

    export function compareTwoFractions(f: FractionString, s: FractionString) {
        const first = fractionToNumber(f)
        const second = fractionToNumber(s)

        if (first === second) {
            return "eq"
        } else if (first > second) {
            return "gt"
        } else {
            return "lt"
        }
    }

    export function addTwoFractions(f, s, subtract = false) {
        var result = scaleFractions(f, s)

        var first = result.first
        var second = result.second

        var newNumerator = first.numerator + second.numerator
        var newDenominator = first.denominator

        if (subtract) {
            newNumerator = first.numerator - second.numerator
        }

        return simplifyFraction(newNumerator, newDenominator)
    }

    export function scaleFractions(f: FractionString, s: FractionString) {
        var first = fractionToDictionary(f)
        var second = fractionToDictionary(s)

        var newDenominator = first.denominator

        if (first.denominator != second.denominator) {
            newDenominator = first.denominator * second.denominator
        }

        const newFirst = {
            numerator:
                first.denominator != second.denominator
                    ? first.numerator * second.denominator
                    : first.numerator,
            denominator: newDenominator,
        }

        const newSecond = {
            numerator:
                first.denominator != second.denominator
                    ? second.numerator * first.denominator
                    : second.numerator,
            denominator: newDenominator,
        }

        return { first: newFirst, second: newSecond }
    }

    export function gcd(a, b) {
        if (!b) {
            return a
        }

        return Time.gcd(b, a % b)
    }

    export function addTwoFractionsAndConvert(f, s, subtract) {
        var result: any = Time.addTwoFractions(f, s, subtract)
        result = fractionToDictionary(result)

        return result.numerator / result.denominator
    }

    export function simplifyFraction(numerator: number, denominator: number) {
        const gcdResult = Time.gcd(numerator, denominator)

        return numerator / gcdResult + "/" + denominator / gcdResult
    }

    export function simplifyFractionFromString(fraction: string) {
        const dict = fractionToDictionary(fraction)

        return simplifyFraction(dict.numerator, dict.denominator)
    }

    export function quantizeFractionToString(
        fraction,
        type: "ceil" | "floor" | "round",
        res
    ) {
        return simplifyFraction(quantizeFraction(fraction, type, res), res)
    }

    export function quantizeTimesteps(
        timesteps,
        type: "ceil" | "floor" | "round",
        res,
        timestepRes
    ) {
        var f = (timesteps / timestepRes) * res

        if (type == "ceil") {
            f = Math.ceil(f)
        } else if (type == "round") {
            f = Math.round(f)
        } else if (type == "floor") {
            f = Math.floor(f)
        }

        f = (f / res) * timestepRes

        return f
    }

    export function sectionHasOddNumberOfBars(sectionDuration, timeSignature) {
        var result = Math.ceil(
            divideTwoFractions(sectionDuration, timeSignature)
        )

        return result % 2 == 1
    }

    export function quantizeFraction(
        fraction: string,
        type: "ceil" | "round" | "floor",
        res: number
    ): number {
        var f: any = fractionToDictionary(fraction)
        f = (f.numerator / f.denominator) * res

        if (type == "ceil") {
            f = Math.ceil(f)
        } else if (type == "round") {
            f = Math.round(f)
        } else if (type == "floor") {
            f = Math.floor(f)
        }

        return f
    }

    export function secondsToFraction(
        seconds: number,
        hasStartOffset: boolean,
        timestepRes,
        tempoMap
    ): string {
        const timesteps = Time.convertSecondsInTimesteps(
            seconds,
            hasStartOffset,
            timestepRes,
            tempoMap,
            "secondsToFraction"
        )

        const fraction = Time.timestepsToFraction(timestepRes, timesteps)

        return fraction
    }

    export function convertSecondsInTimesteps(
        seconds,
        hasStartOffset,
        timestepRes,
        tempoMap: Tempo[],
        where
    ) {
        if (hasStartOffset) {
            seconds -= 0.5

            if (seconds <= 0) {
                return 0
            }
        }

        // Number of timesteps in one beat (since BPM in midi means # of quarter notes per minute, we use 4 as the beat resolution)
        const beat = timestepRes / 4

        var timesteps = 0
        var interrupted = false

        for (var b = 0; b < tempoMap.length; b++) {
            const currentBPM = tempoMap[b]
            timesteps = currentBPM.timesteps

            if (b + 1 < tempoMap.length && tempoMap[b + 1].seconds > seconds) {
                const nextBPM = tempoMap[b + 1]

                const numberOfTimesteps =
                    nextBPM.timesteps - currentBPM.timesteps
                const numberOfSeconds = nextBPM.seconds - currentBPM.seconds
                const increment =
                    ((seconds - currentBPM.seconds) * numberOfTimesteps) /
                    numberOfSeconds

                timesteps += increment

                interrupted = true

                break
            }
        }

        if (!interrupted && tempoMap.length > 0) {
            const lastBPM = tempoMap[tempoMap.length - 1]
            const beatsPerSecond = lastBPM.bpm / 60
            const remainingSeconds = seconds - lastBPM.seconds

            timesteps += remainingSeconds * beatsPerSecond * beat
        }

        return timesteps
    }

    export function convertTimestepsInSeconds(
        res,
        tempoMap: Tempo[],
        timesteps,
        hasStartOffset
    ) {
        // Number of timesteps in one beat (since BPM in midi means # of quarter notes per minute, we use 4 as the beat resolution)
        const beat = res / 4

        var seconds = 0
        var interrupted = false

        for (var b = 0; b < tempoMap.length; b++) {
            const currentBPM = tempoMap[b]
            seconds = currentBPM.seconds

            if (
                b + 1 < tempoMap.length &&
                tempoMap[b + 1].timesteps > timesteps
            ) {
                const nextBPM = tempoMap[b + 1]

                const numberOfTimesteps =
                    nextBPM.timesteps - currentBPM.timesteps
                const numberOfSeconds = nextBPM.seconds - currentBPM.seconds

                const increment =
                    ((timesteps - currentBPM.timesteps) * numberOfSeconds) /
                    numberOfTimesteps

                seconds += increment

                interrupted = true

                break
            }
        }

        if (!interrupted && tempoMap.length > 0) {
            const lastBPM = tempoMap[tempoMap.length - 1]
            const beatsPerSecond = lastBPM.bpm / 60
            const remainingTimesteps = timesteps - lastBPM.timesteps

            seconds += remainingTimesteps / beat / beatsPerSecond
        }

        if (hasStartOffset) {
            seconds += 0.5
        }

        return seconds
    }

    export function multiplyFractions(fraction1, fraction2) {
        var one = fractionToDictionary(fraction1)
        var two = fractionToDictionary(fraction2)

        var newFraction = {
            numerator: one.numerator * two.numerator,
            denominator: one.denominator * two.denominator,
        }

        return simplifyFraction(newFraction.numerator, newFraction.denominator)
    }

    export function fractionToSeconds(
        res: number,
        tempoMap: Tempo[],
        fraction: string
    ) {
        return convertTimestepsInSeconds(
            res,
            tempoMap,
            fractionToTimesteps(res, fraction),
            0
        )
    }

    export function timestepsToFraction(timestepRes, timesteps) {
        return simplifyFraction(Math.round(timesteps), timestepRes)
    }

    export function fractionToTimesteps(
        timestepRes: number,
        string: FractionString
    ) {
        if (typeof string != "string") {
            return string
        }

        const temp = string.split("/")
        let result = parseInt(temp[0])

        if (temp.length > 1) {
            result = parseInt(temp[0]) / parseInt(temp[1])
        }

        return Math.floor(result / (1 / timestepRes))
    }

    export function linearInterpolation(a, b, y) {
        const m = (b.y - a.y) / (b.x - a.x)
        const p = -m * a.x + a.y

        return m * y + p
    }

    export function getBeatIndex(
        noteStart: string,
        timeSignatures,
        ceil = false
    ) {
        let splitFraction: any = noteStart.split("/")
        let numberOfWholeNotes: number

        if (splitFraction.length > 1) {
            numberOfWholeNotes =
                parseInt(splitFraction[0]) / parseInt(splitFraction[1])
        } else {
            numberOfWholeNotes = parseInt(splitFraction)
        }

        const result = numberOfWholeNotes * timeSignatures[0][1][1]

        if (ceil) {
            return Math.ceil(result)
        }

        return Math.floor(result)
    }

    export function convertTimestepsToAnotherRes(
        tsValue: number,
        originalTSRes: number,
        newTSRes: number
    ) {
        return (tsValue * newTSRes) / originalTSRes
    }

    /**
     * This function is for rounding timesteps to a specified resolution
     */
    export function roundTimestepsToRes(
        tsValue: number,
        originalTSRes: number,
        newTSRes: number
    ): number {
        const converted = Time.convertTimestepsToAnotherRes(
            tsValue,
            originalTSRes,
            newTSRes
        )

        return Time.convertTimestepsToAnotherRes(
            Math.floor(converted),
            newTSRes,
            originalTSRes
        )
    }
}
