import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    Input,
    ViewChild,
} from "@angular/core"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import { DoublesService } from "@services/doubles.service"
import {
    DoublingInstrumentSelectionModalParameters,
    EditDoubleFolderModalParameters,
    ModalService,
} from "@services/modal.service"

@Component({
    selector: "edit-double-folder",
    templateUrl: "./edit-double-folder.component.html",
    styleUrls: ["edit-double-folder.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDoubleFolderModalComponent implements OnInit {
    @Input() parameters: EditDoubleFolderModalParameters
    @Output() close = new EventEmitter<any>()

    scrollChange = { useBothWheelAxes: false, suppressScrollX: true }

    renameDoubleFolder = ""

    constructor(private doublesService: DoublesService) {}

    ngOnInit() {
        this.renameDoubleFolder = this.parameters.folder.name
    }

    async updateDoubleFolder() {
        this.closeModal()

        this.parameters.folder.name = this.renameDoubleFolder
        this.parameters.folder = await this.doublesService.updateDoubleFolder(
            this.parameters.folder,
            this.parameters.layer
        )

        this.parameters.onClose()
    }

    closeModal() {
        this.close.emit(2)
    }
}
