import AIVAObject from "../general/aivaobject"
import { Time }from '../../modules/time'
import Operation from './operation'
import SectionOperation from "./operation"

export default class Section extends AIVAObject {
    index:number
    
    start:string = "0" // fraction
    end:string = "0"
    duration:string = "0"

    title:string = ""

    inserted:boolean = false

    operation:SectionOperation

    constructor(start:string, title:string, index:number) {
        super()
        
        this.start = start
        this.title = title
        this.index = index
    }

    setEnd(end:string) {
        this.end = end
        this.duration = Time.addTwoFractions(this.end, this.start, true)
    }

    setEndFromDuration(duration:string) {
        this.duration = duration
        this.end = Time.addTwoFractions(this.start, this.duration)
    }

    copy() {
        var op = new Section("", "", 0)
        
        for (var key of Object.keys(this)) {
            if (key == "operation" && this[key] != null) {
                op[key] = this[key].copy()
            }

            else {
                op[key] = this[key]
            }
        }

        return op
    }
}