import { SECTION_EDITING } from "../../constants/constants"
import { SectionEditingType } from "../activitymetric"
import AIVAObject from "../general/aivaobject"
import Section from "./section"
export default class SectionOperation extends AIVAObject {

    type:SectionEditingType // regenerate, replace, insert (in different flavors) or delete
    args:
        | {
              section_idx: number
              insert_type?: "copy" | "variation" | "new" | "blank"
              number_of_bars?: number
              source_idx?: number
          }
        | undefined

    constructor() {
        super()
    }

    copy() {
        const op = new SectionOperation()

        for (const key of Object.keys(this)) {
            op[key] = this[key]
        }

        return op
    }

    static insertNewSection(
        sectionIndex: number,
        insertType: string,
        sourceIndex: number | undefined,
        sectionLength: number
    ) {
        const operation = new SectionOperation()

        operation.type = "insert_" + insertType as SectionEditingType

        insertType = insertType.replace("insert_", "")

        if (
            insertType !== "copy" &&
            insertType !== "variation" &&
            insertType !== "blank" &&
            insertType !== "new"
        ) {
            throw "Invalid insert type"
        }

        operation.args = {
            section_idx: sectionIndex,
            source_idx: sourceIndex,
            insert_type: insertType,
            number_of_bars: sectionLength,
        }

        return operation
    }

    static deleteSection(sectionIndex) {
        const operation = new SectionOperation()

        operation.type = SECTION_EDITING.DELETE
        operation.args = {
            section_idx: sectionIndex,
        }

        return operation
    }

    static replaceSection(sectionIndex) {
        const operation = new SectionOperation()

        operation.type = SECTION_EDITING.REPLACE
        operation.args = {
            section_idx: sectionIndex,
        }

        return operation
    }

    static regenerateSection(sectionIndex, sourceIndex = null) {
        const operation = new SectionOperation()

        operation.type = SECTION_EDITING.REGENERATE
        operation.args = {
            section_idx: sectionIndex,
        }

        if (sourceIndex != null) {
            operation.type = SECTION_EDITING.REGENERATE_WITH_SOURCE
            operation.args.source_idx = sourceIndex
        }

        return operation
    }

    getSectionIndex() {
        return this.args.section_idx
    }
}
