import { QuantizationThresholds } from "../interfaces/general"

export enum LayerType {
    ACCOMPANIMENT = "Accompaniment",
    BASS = "Bass",
    MELODY = "Melody",
    CHORDS = "Chords",
    PERCUSSION = "Percussion",
    ORNAMENTS = "Ornament",
    EXTRA = "Extra",
}

export const ALLOWED_NOTE_RESOLUTIONS: string[] = [
    "1/4",
    "1/8",
    "1/12",
    "1/16",
    "1/24",
    "1/48",
]

export const PACING_CATEGORIES = ["slow", "medium", "fast"]

export const COMPATIBLE_ARTICULATIONS = ["staccato", "legato", "sustain"]

export const MATERIAL_VARIATIONS_CATEGORIES = [
    "allow_rhythmic_changes",
    "allow_new_tuplets",
    "allow_new_rests",
    "allow_new_chords",
    "keep_pitch_order",
    "require_pitch_changes",
]

export const DEFAULT_PERCUSSION_INSTRUMENT = "drumkit-rock-1"
export const DEFAULT_PITCHED_INSTRUMENT = "piano"

export const NUMBER_OF_COMPOSITIONS = [1, 2, 3, 4, 5]

export const NUMBER_OF_COMPOSITIONS_OPTIONS = NUMBER_OF_COMPOSITIONS.map(v => {
    return {
        value: v,
        name: v,
    }
})

export const MIN_GP_TEMPO = 30

export const PITCH_CONTINOUS_COUNT = 28
export const PITCH_SCALE_COUNT = 9

export const LAYERS = [
    "Melody",
    "Bass",
    "Chords",
    "Extra",
    "Extra_1",
    "Extra_2",
    "Percussion",
    "Ornaments",
    "Ornaments_1",
    "Ornaments_2",
]

export const SECTION_TYPES = [
    {
        value: 0,
        name: "A",
    },

    {
        value: 1,
        name: "A, B",
    },

    {
        value: 2,
        name: "A, B, C",
    },
]

export const PERCUSSION_FUNCTIONS = ["electronic", "acoustic"]

export const ACCOMPANIMENT_PACK_SECTIONS = [
    "Intro",
    "Theme",
    "Bridge",
    "Outro",
    "Cadence",
]

export const SUPPORTED_TIME_SIGNATURES = [
    [2, 4],
    [4, 4],
    [3, 4],
    [6, 8],
    [12, 8],
]

export const TIMESTEP_RES = 48

export const AUTOMATION_TIMESTEP_RES = 16

export const ACCOMPANIMENT_PACK_UPDATE_TYPES = [
    "formatting",
    "ts_conversion",
    "validation",
    "db_addition",
    "creation",
]

export const GP_HARMONY_STRATEGY = ["Functional", "Modal"]

export const HARMONY_MODES = {
    Functional: ["major", "minor"],
    Modal: ["dorian", "phrygian", "lydian", "mixolydian", "locrian"],
}

/**
 * In functional harmony, major and minor have a different set of pitch classes compatible.
 * In modal harmony, the same thing applies. To avoid repeating ourselves, we map a modal mode to a functional mode
 * so that they can handle pitch classes in the same way
 * */
export const MODES_TO_MODAL_TYPE_CORRESPONDENCE = {
    major: ["lydian", "mixolydian"],

    minor: ["dorian", "phrygian", "locrian"],
}

export const PITCHES = ["C", "D", "E", "F", "G", "A", "B"]

export const PITCH_CLASSES_FOR_MODES = {
    major: [
        "C",
        "F",
        "Bb",
        "Eb",
        "Ab",
        "Db",
        "G",
        "Cb",
        "Gb",
        "D",
        "A",
        "E",
        "B",
        "F#",
        "C#",
    ],

    minor: [
        "A",
        "D",
        "G",
        "C",
        "F",
        "Bb",
        "Eb",
        "Ab",
        "E",
        "B",
        "F#",
        "C#",
        "G#",
        "D#",
        "A#",
    ],
}

export const ME_MODES_TO_CREATORS_MODES_MAPPING = {
    min: "minor",
    maj: "major",
    dor: "dorian",
    phr: "phrygian",
    lyd: "lydian",
    mix: "mixolydian",
    loc: "locrian",
}

export const DEFAULT_MODAL_SETTINGS = {
    cwnotesmanipulation: false,
}

export const SUSTAIN_PEDAL_UNIQUE_PITCHES_THRESHOLD = 4
export const SUSTAIN_PEDAL_MEASURE_LENGTH = 2

export const AUDIO_REFRESH_RATE = 60
export const VIDEO_REFRESH_RATE = 25

export const SILENT_KIT_INSTRUMENT = "p.silent-kit"
export const SILENT_INSTRUMENT = "s.silent-instrument"
export const SILENT_KIT_TRACK = SILENT_KIT_INSTRUMENT + ".nat.stac"
export const SILENT_INSTRUMENT_TRACK = SILENT_INSTRUMENT + ".nat.stac"

export const HIGHEST_PITCH = 127
export const LOWEST_PITCH = 0
export const HIGHEST_PIANO_PITCH = 108
export const LOWEST_PIANO_PITCH = 21

export enum TIMESTEP_WIDTH {
    PIANO_ROLL = 7,
}

export const DEFAULT_LAYER_RANGE = {
    MELODY: { min: 52, max: 88 },
    ACCOMPANIMENT: { min: 47, max: 75 },
    BASS: { min: 28, max: 56 },
}

export const NB_OF_OCTAVES = 2
export const SCALE_START_OCTAVE = 2
export const SCALE_START_OCTAVE_WITH_INDEXING =
    SCALE_START_OCTAVE + NB_OF_OCTAVES // + 2 is because the displayed MIDI octave is offset by 2 octave to the left

export const MIN_TEMPO = 10
export const MAX_TEMPO = 210

export const NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS = 8

export const SHOW_BAR_GUTTERS_THRESHOLD = 8
export const SHOW_BEAT_GUTTERS_THRESHOLD = 20
export const SHOW_HALF_BEAT_GUTTERS_THRESHOLD = 30
export const SHOW_TIMESTEP_GUTTERS_THRESHOLD = 65

export const HALF_BEAT_QUANTIZATION_THRESHOLD = SHOW_HALF_BEAT_GUTTERS_THRESHOLD
export const BEAT_QUANTIZATION_THRESHOLD = SHOW_BEAT_GUTTERS_THRESHOLD
export const BAR_QUANTIZATION_THRESHOLD = 1

export const NOTE_QUANTIZATION_THRESHOLDS: QuantizationThresholds = {
    halfBeat: HALF_BEAT_QUANTIZATION_THRESHOLD,
    beat: BEAT_QUANTIZATION_THRESHOLD,
    bar: BAR_QUANTIZATION_THRESHOLD,
}

export const PATTERN_REGIONS_QUANTIZATION_THRESHOLDS: QuantizationThresholds = {
    halfBeat: HALF_BEAT_QUANTIZATION_THRESHOLD,
    beat: SHOW_BEAT_GUTTERS_THRESHOLD,
    bar: SHOW_BAR_GUTTERS_THRESHOLD,
}

export const CHORDS_QUANTIZATION_THRESHOLDS: QuantizationThresholds = {
    halfBeat: BEAT_QUANTIZATION_THRESHOLD,
    beat: BEAT_QUANTIZATION_THRESHOLD,
    bar: BEAT_QUANTIZATION_THRESHOLD,
}

export const MAX_RESIZE_FACTOR = 150

export const VALID_SECTION_OPERATIONS = [
    "insert_copy",
    "insert_variation",
    "insert_new",
    "insert_blank",
]

export const PIANOROLL_GRID_SCROLLBAR_HEIGHT = 6
export const PIANOROLL_GRID_SCROLLBAR_RADIUS = 3
export const PIANOROLL_GRID_SEPARATOR_TOP = {
    margin: 15 + PIANOROLL_GRID_SCROLLBAR_HEIGHT,
    separatorClickableHeight: 10,

    beat: {
        start: 5,
        end: 14,
    },

    halfBeat: {
        start: 8,
        end: 12,
    },

    text: {
        topOffset: 14,
        fontSize: 70,
    },
}

export const PIANOROLL_GRID_SECTION_TOP_OFFSET =
    PIANOROLL_GRID_SEPARATOR_TOP.margin +
    PIANOROLL_GRID_SEPARATOR_TOP.beat.end +
    5
export const PIANOROLL_GRID_SECTION_HEIGHT = 37

export const SHOW_PATTERN_REGION_RESIZE_EDGES = SHOW_BEAT_GUTTERS_THRESHOLD
export enum SECTION_EDITING {
    CANCEL = "cancel",
    DELETE = "delete",
    REPLACE = "replace",
    REGENERATE = "regenerate",
    REGENERATE_WITH_SOURCE = "regenerate_with_source",
    INSERT_NEW = "insert_new",
    INSERT_VARIATION = "insert_variation",
    INSERT_COPY = "insert_copy",
    INSERT_BLANK = "insert_blank",
}

export const PRERENDER_BUFFER_SIZE_IN_PX = 350

export const BAR_COUNTS = [1, 2, 4]

export const GP_DURATION_OPTIONS = [
    "auto",
    "< 0'30",
    "0'30 - 1'00",
    "1'00 - 1'30",
    "1'30 - 2'00",
    "2'00 - 2'30",
    "2'30 - 3'00",
    "3'00 - 3'30",
    "3'30 - 4'00",
    "4'00 - 4'30",
    "4'30 - 5'00",
    "5'00 - 5'30",
]

export const GP_DURATION_OPTIONS_DROPDOWN = GP_DURATION_OPTIONS.map(v => {
    return {
        value: v,
        name: v === "auto" ? "Auto" : v,
    }
})
