import { Options } from "@angular-slider/ngx-slider"
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core"
import Section from "@common-lib/classes/score/section"
import { Misc } from "@common-lib/modules/misc"
import { SectionEditingModal } from "@services/modal.service"
import { DeviceDetectorService } from "ngx-device-detector"
import { SRActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../../types/dropdownItemType"
import { Time } from "@common-lib/modules/time"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { InstrumentsService } from "@services/instruments/instruments.service"

@Component({
    selector: "insert-section",
    templateUrl: "insert-section.component.html",
    styleUrls: ["insert-section.component.scss"],
})
export class InsertSectionComponent implements OnInit {
    @Input() sectionEditing: SectionEditingModal
    @Output() close: EventEmitter<any> = new EventEmitter()

    public sectionLengthOptions: Options

    public insertTypes: DropdownItemType<
        "new" | "copy" | "blank" | "variation"
    >[] = [
        {
            name: "Insert a new section",
            value: "new",
        },
        {
            name: "Insert an empty section",
            value: "blank",
        },
        {
            name: "Insert a copy of an existing section",
            value: "copy",
        },
        {
            name: "Insert a variation of another section",
            value: "variation",
        },
    ]

    public filteredSections: DropdownItemType<number>[] = []

    public changeSelectStyle: boolean = false

    public sectionType: DropdownItemType<string> | undefined
    public sourceSectionIndex: DropdownItemType<number> | undefined

    public sectionLength: number = 0

    constructor(
        private device: DeviceDetectorService,
        private instruments: InstrumentsService
    ) {
        // select options were invisible because of a lack of contrast
        this.changeSelectStyle =
            this.device.os == "Linux" || this.device.os == "Windows"

        if (featureFlags.sectionModificationForCWCompositions) {
            this.insertTypes = [
                {
                    name: "Insert an empty section",
                    value: "blank",
                },
            ]
        }
    }

    ngOnInit() {
        this.filteredSections = this.filterSectionsForSectionInsert(
            this.sectionEditing.engine.score.sections.filter(s => !s.inserted)
        )

        this.setSectionLengthOptions()
    }

    public selectSectionType(event: DropdownSelection<string>) {
        this.sectionType = event.new
    }

    public selectSourceSection(event: DropdownSelection<number>) {
        this.sourceSectionIndex = event.new
    }

    getEditSectionSource() {
        if (this.sectionEditing.section.index == null) {
            return "Which section would you like to use as a source?"
        }

        return this.sectionEditing.section.index
    }

    selectEditSectionSourceIndex(event) {
        this.sectionEditing.section.index = event.new
    }

    showSectionLengthSlider() {
        return (
            this.sectionEditing.type != null &&
            (this.sectionEditing.type.includes("new") ||
                this.sectionEditing.type.includes("empty"))
        )
    }

    showDropdownSectionSourceIndex() {
        return (
            this.sectionEditing.type != null &&
            (this.sectionEditing.type.includes("copy") ||
                this.sectionEditing.type.includes("variation"))
        )
    }

    showInsertSectionApplyButton() {
        return (
            this.sectionEditing.type != null &&
            (!this.showDropdownSectionSourceIndex() ||
                this.sectionEditing.type != null)
        )
    }

    setSectionLengthOptions() {
        this.sectionLength =
            Math.floor(
                this.sectionEditing.engine.queries.scoreRendering.maxBarLength /
                    2
            ) * 2

        this.sectionLengthOptions = {
            floor: 2,
            ceil: this.sectionLength,
            step: 2,
            animate: false,
        }
    }

    filterSectionsForSectionInsert(
        nonFilteredSections: Section[]
    ): DropdownItemType<number>[] {
        let sections = []

        if (!this.sectionEditing.type.includes("variation")) {
            sections = nonFilteredSections.map(s => {
                return {
                    value: s.index,
                    name: s.title,
                }
            })
        } else {
            for (const section of nonFilteredSections) {
                if (
                    section.title.includes("Intro") ||
                    section.title.includes("Outro") ||
                    section.title.includes("Bridge")
                ) {
                    continue
                }

                sections.push({
                    value: section.index,
                    name: section.title,
                })
            }
        }

        return sections
    }

    async apply() {
        console.log({
            type: this.sectionType.value,
            section: this.sectionEditing.section,
            insertPosition: this.sectionEditing.position,
            sectionLength: this.sectionLength,
            sourceSectionIndex: this.sourceSectionIndex?.value,
        })

        if (
            this.sectionType.value == "variation" ||
            this.sectionType.value == "copy"
        ) {
            this.sectionLength = Time.fractionToMeasures(
                this.sectionEditing.engine.score.sections.find(
                    s => s.index == this.sourceSectionIndex.value
                ).duration,
                this.sectionEditing.engine.score.firstTimeSignature
            )
        }

        await new Promise(resolve => {
            this.sectionEditing.engine.srEmitter$.next({
                type: SRActionTypes.addSection,
                data: {
                    type: this.sectionType.value,
                    section: this.sectionEditing.section,
                    insertPosition: this.sectionEditing.position,
                    sectionLength: this.sectionLength,
                    sourceSectionIndex: this.sourceSectionIndex?.value,
                    resolve,
                },
                options: {
                    isUndoable: true,
                },
            })
        })

        this.sectionEditing.onComplete()
    }
}
