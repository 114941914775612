import { Fraction } from "../classes/score/fraction"

export module Time {
    export function fractionIsInBoundaries(
        boundary: { start: Fraction; duration: Fraction },
        fraction: Fraction,
        includeStart = true,
        includeEnd = false
    ) {
        const end = addTwoFractions(boundary.start, boundary.duration)

        const temp1 =
            (includeStart &&
                compareTwoFractions(boundary.start, fraction) !== "gt") ||
            (!includeStart &&
                compareTwoFractions(boundary.start, fraction) === "lt")

        const temp2 =
            (!includeEnd && compareTwoFractions(end, fraction) === "gt") ||
            (includeEnd && compareTwoFractions(end, fraction) !== "lt")

        return temp1 && temp2
    }

    export function multiplyFractionWithNumber(
        fraction: Fraction,
        num: number
    ) {
        if (num === 0 || isNaN(num)) {
            return new Fraction("0")
        }

        const numerator = fraction.numerator * num

        return simplifyFraction(numerator, fraction.denominator)
    }

    export function compareTwoFractions(fr1: Fraction, fr2: Fraction) {
        const first = fr1.numerator / fr1.denominator
        const second = fr2.numerator / fr2.denominator

        if (first === second) {
            return "eq"
        } else if (first > second) {
            return "gt"
        } else {
            return "lt"
        }
    }

    export function addTwoFractions(
        f: Fraction,
        s: Fraction,
        subtract = false
    ) {
        const result = scaleFractions(f, s)

        const first = result.first
        const second = result.second

        let newNumerator = first.numerator + second.numerator
        const newDenominator = first.denominator

        if (subtract) {
            newNumerator = first.numerator - second.numerator
        }

        return simplifyFraction(newNumerator, newDenominator)
    }

    export function quantizeFraction(
        fraction: Fraction,
        type: "ceil" | "round" | "floor",
        res: number
    ): number {
        let quantizedResult = fraction.toDecimal() * res

        switch (type) {
            case "ceil": {
                return Math.ceil(quantizedResult)
            }
            case "round": {
                return Math.round(quantizedResult)
            }
            case "floor": {
                return Math.floor(quantizedResult)
            }
            default: {
                return Math.round(quantizedResult)
            }
        }
    }

    export function scaleFractions(
        f: Fraction,
        s: Fraction
    ): { first: Fraction; second: Fraction } {
        if (f.denominator !== s.denominator) {
            const newDenominator = f.denominator * s.denominator

            const newFirst = Fraction.fromFraction(
                f.numerator * s.denominator,
                newDenominator
            )

            const newSecond = Fraction.fromFraction(
                s.numerator * f.denominator,
                newDenominator
            )

            return { first: newFirst, second: newSecond }
        } else {
            return { first: f, second: s }
        }
    }

    export function simplifyFraction(
        numerator: number,
        denominator: number
    ): Fraction {
        const gcdResult = gcd(numerator, denominator)

        return new Fraction(
            numerator / gcdResult + "/" + denominator / gcdResult
        )
    }

    export function gcd(a: number, b: number): number {
        if (!b) {
            return a
        }

        return Time.gcd(b, a % b)
    }

    export function timestepsToFraction(
        timestepRes: number,
        timesteps: number
    ): Fraction {
        if (timestepRes <= 0) {
            throw new Error("Timestep resolution must be greater than 0")
        }
        return simplifyFraction(Math.round(timesteps), timestepRes)
    }

    export function fractionToTimesteps(
        timestepRes: number,
        fraction: Fraction
    ) {
        return fraction.numerator / fraction.denominator / (1 / timestepRes) // Times timestepRes ???
    }

    export function timestepToFraction(timesteps: number, timestepRes: number) {
        return simplifyFractionFromString(timesteps + "/" + timestepRes)
    }

    export function simplifyFractionFromString(fraction: string) {
        const fractionObj = new Fraction(fraction)

        return simplifyFraction(fractionObj.numerator, fractionObj.denominator)
    }
}
