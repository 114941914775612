import { Injectable } from "@angular/core"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import HarmonyPack from "@common-lib/classes/generationprofiles/harmony/harmonypack"
import Pack from "@common-lib/classes/generationprofiles/pack"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import { BehaviorSubject } from "rxjs"
import { ApiService } from "../api.service"
import { ModalService } from "../modal.service"
import { FolderService } from "../folder.service"
import { AnimationLoopService } from "../animationloop.service"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import { FileSaverService } from "ngx-filesaver"
import LayerPreview from "@common-lib/classes/generationprofiles/layerpreview"
import Harmony from "@common-lib/classes/generationprofiles/harmony/harmony"
import { Router } from "@angular/router"
import { TokenService } from "../token.service"
import { UserService } from "../user.service"
import Patch from "@common-lib/classes/score/patch"
import { Time } from "@common-lib/modules/time"
import { PlayerService } from "../audio/player/player.service"
import { GPInfluenceData } from "@common-lib/interfaces/api/sockets"
import { TIMESTEP_RES } from "@common-lib/constants/constants"
import { WindowService } from "../window.service"
import { playerQuery } from "../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { SourcePackService } from "../source-packs/sourcepacks.service"
import { cloneDeep } from "lodash"
import { CreateService } from "../create.service"
import { TemplateScore } from "@common-lib/interfaces/score/templateScore"
import { ScoreEncoding } from "@common-lib/modules/score-transformers/scoreEncoding"
import { GenerationProfileHTTPService } from "./generationprofile.http"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import { DesignService } from "@services/design.service"
import Channel from "@common-lib/classes/score/channel"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { Misc } from "@common-lib/modules/misc"

interface Banner {
    type?: "error" | "succes"
    message?: string
    show?: boolean
}

@Injectable()
export class GenerationProfileService {
    playbackIcon = {
        play: "assets/img/player/play.svg",
        pause: "assets/img/player/pause.svg",
    }

    gpLibraryStyles = []

    melodyPreviewLoading = false

    currentPreviews: Array<LayerPreview> = []

    static UPDATE_LOOP_FREQUENCY = 5 // in seconds

    previewEndTimeout

    downloadProgress = 0
    downloadQueue: Promise<any> = Promise.resolve()

    developmentPreviewGraphOption

    redirectToView: BehaviorSubject<string> = new BehaviorSubject<string>(null)
    justCreatedComposition: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false)

    scrollTopForLayer: BehaviorSubject<string> = new BehaviorSubject<string>("")

    tutorialMode = false

    refreshGPLibraryUI: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )
    refreshGenerationProfileUI: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false)
    refreshInfluenceDragover: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false)
    generationProfile: GenerationProfile

    banner: BehaviorSubject<Banner> = new BehaviorSubject<Banner>(null)

    cachedFiles = {}

    gpValidation = null

    constructor(
        private sourcePacks: SourcePackService,
        private windowService: WindowService,
        protected fileSaverService: FileSaverService,
        protected router: Router,
        protected playerService: PlayerService,
        protected userService: UserService,
        protected tokenService: TokenService,
        protected animationLoopService: AnimationLoopService,
        protected apiService: ApiService,
        private modalService: ModalService,
        private folderService: FolderService,
        private createService: CreateService,
        public http: GenerationProfileHTTPService,
        private designService: DesignService,
        private instruments: InstrumentsService
    ) {
        this.apiService.socket.subscribe(socket => {})

        playerQuery.status$.subscribe(value => {
            if (
                value === "playing" &&
                this.router.url.includes("/generation-profile-editor")
            ) {
                this.currentPreviews = []
                this.pausePreview()
            }
        })

        this.apiService.socket.subscribe(socket => {
            if (socket == null) {
                return
            }

            socket.on("gpInfluenceData", (data: GPInfluenceData) => {
                this.listenForGPInfluenceData(data)
            })
        })
    }

    checkForSyncIssues() {
        for (let layer of this.generationProfile.accompanimentLayers) {
            for (let pack of layer.packs) {
                if (
                    pack.synchronisation == null ||
                    pack.synchronisation.target == null
                ) {
                    continue
                }

                const synchedPack = this.getPackByIndex(
                    pack.synchronisation.target.idx,
                    pack.synchronisation.target.layer
                )

                if (synchedPack) {
                    // @todo: fix this
                    // for (let otherPack of this.accompanimentPacks[synchedPack['type']]) {
                    // 	if (otherPack.packID == synchedPack.packID) {
                    // 		const syncType = this.getSyncForType(otherPack, pack.synchronisation.type)
                    // 		if (!syncType.includes(pack.packID)) {
                    // 			pack.synchronisation = null
                    // 		}
                    // 		break
                    // 	}
                    // }
                } else {
                    pack.synchronisation = null
                }
            }
        }
    }

    public async computeGPSyncs() {
        const gpID = this.generationProfile._id

        let hasSyncedPack = false

        for (const layer of this.generationProfile.accompanimentLayers) {
            for (const pack of layer.packs) {
                if (pack.synchronisation.target !== undefined) {
                    hasSyncedPack = true
                    break
                }
            }
        }

        if (hasSyncedPack) {
            return this.http.computeGPSyncs(gpID)
        }

        return false
    }

    getPackByIndex(idx: number, layerName: string) {
        for (let layer of this.generationProfile.accompanimentLayers) {
            if (layer.name != layerName) {
                continue
            }

            for (let pack of layer.packs) {
                if (pack["idx"] === idx) {
                    return pack
                }
            }
        }

        return undefined
    }

    findPackOnLayer(layer: string, idx) {
        for (let otherLayer of this.generationProfile.accompanimentLayers) {
            if (layer != otherLayer.name) {
                continue
            }

            for (let pack of otherLayer.packs) {
                if (pack["idx"] == idx) {
                    return pack
                }
            }
        }

        return null
    }

    toggleLinkSharing(gpID, share: boolean) {
        this.apiService
            .authRequest(
                "/generationprofile/share",
                { share: share, generationProfileID: gpID },
                "primary",
                true
            )

            .catch(err => {
                return this.apiService.handleError(err)
            })
    }

    listenForGPInfluenceData(data: GPInfluenceData) {
        if (data.gpID != this.generationProfile?._id) {
            return
        }

        let gp: GenerationProfile = GenerationProfile.fromJSON(
            data.generationProfile
        )

        this.generationProfile.addComponentsFromOtherGP(
            gp,
            data.gpComponents,
            null
        )

        this.validateGenerationProfile(this.generationProfile, false)

        this.refreshGenerationProfileUI.next(true)
        this.refreshInfluenceDragover.next(true)
    }

    async uploadGPInfluence(file) {
        return
    }

    async getHarmonyStrategy(args: {
        sourceGPFolder?: string
        sourceGPCategory?: string
    }): Promise<{
        strategy: string
        keyMode: string
    }> {
        try {
            const result = await this.apiService.authRequest(
                "/generationProfile/harmony/getStrategy",
                {
                    sourceGPFolder: args.sourceGPFolder,
                    sourceGPCategory: args.sourceGPCategory,
                    publishedRevisionOnly:
                        !this.createService.gpStates.filters.admin,
                },
                "primary",
                true
            )

            return {
                strategy: result.strategy,
                keyMode: result.keyMode,
            }
        } catch (e) {
            this.apiService.handleError(e)

            return undefined
        }
    }

    public async createAccompanimentPack(
        layer,
        packToReplace?: AccompanimentPack
    ) {
        const newPack = await this.http.createNewAccompanimentPack({
            generationProfileID: this.generationProfile._id,
            type: packToReplace ? "change" : "add",
            layer: layer.name,
            currentPackID: packToReplace?.packID,
        })

        if (packToReplace) {
            AccompanimentPack.changePack(packToReplace, newPack)

            this.setAsUpdated("createAccompanimentPack", {
                packID: newPack.packID,
            })
        }

        this.refreshGenerationProfileUI.next(true)

        this.router.navigate([
            "accompaniment-designer",
            this.generationProfile._id,
            newPack.packID,
            layer.name,
            false + "",
            false + "",
        ])
    }

    encodeSyncAndTransformForPack(accompanimentLayers, pack) {
        const nullSynch =
            pack.synchronisation == null || pack.synchronisation.target == null
        const nullTransform =
            pack.transform == null || pack.transform.target == null

        if (nullSynch && nullTransform) {
            return
        }

        for (let layer of accompanimentLayers) {
            const syncCheck =
                !nullSynch && layer.name == pack.synchronisation.target.layer
            const transformCheck =
                !nullTransform && layer.name == pack.transform.target.layer

            if (!syncCheck && !transformCheck) {
                continue
            }

            for (let p = 0; p < layer.packs.length; p++) {
                const packSynchCheck =
                    !nullSynch &&
                    layer.packs[p].idx == pack.synchronisation.target.idx
                const packTransformCheck =
                    !nullTransform &&
                    layer.packs[p].idx == pack.transform.target.idx

                if (packSynchCheck) {
                    pack.synchronisation.target.idx = p

                    return
                }

                if (packTransformCheck) {
                    pack.transform.target.idx = p

                    return
                }
            }
        }

        return
    }

    getLayers(): Array<GPLayer> {
        let layers = []

        if (this.generationProfile.melodyLayer != null) {
            layers.push(this.generationProfile.melodyLayer)
        }

        layers = layers.concat(this.generationProfile.accompanimentLayers)

        return layers
    }

    getLayer(layer: string): GPLayer | undefined {
        const layerObject = this.getLayers().find(l => l.name === layer)

        return layerObject
    }

    preprocessGPForUpdate(): GenerationProfile {
        if (this.generationProfile == null) {
            return null
        }

        let gp = cloneDeep(this.generationProfile)

        for (let layer of gp.accompanimentLayers) {
            for (let pack of layer.packs) {
                this.encodeSyncAndTransformForPack(gp.accompanimentLayers, pack)
            }
        }

        return gp
    }

    async update(updateType) {
        const gp = this.preprocessGPForUpdate()

        try {
            const res = await this.apiService.authRequest(
                "/generationprofile/update",
                {
                    generationProfile: gp,
                    updateType: updateType,
                },
                "primary",
                true
            )

            this.validateGenerationProfile(this.generationProfile, false)
        } catch (err) {
            this.apiService.handleError(err)
        }
    }

    // @todo: make sure that the api call returns a GenerationProfileSchema object rather than GenerationProfile object
    duplicateGenerationProfile(gp: GenerationProfile) {
        return this.apiService
            .authRequest(
                "/generationprofile/duplicate",
                { generationProfileID: gp._id },
                "primary",
                true
            )
            .then(res => {
                let content = this.folderService.content.getValue()
                let newGP = GenerationProfile.fromJSON(res.generationProfile)

                for (let g = 0; g < content.length; g++) {
                    let gpObject = content[g]

                    if (gpObject._id == gp._id) {
                        content.splice(g, 0, newGP)

                        break
                    }
                }

                this.folderService.setContent({
                    content: content,
                })

                return Promise.resolve()
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve()
            })
    }

    async init(id: string, force: boolean) {
        if (
            this.generationProfile == null ||
            this.generationProfile._id != id ||
            force
        ) {
            let result = await this.getGenerationProfileByID(id)
            this.generationProfile = GenerationProfile.fromJSON(result.gp)

            this.validateGenerationProfile(this.generationProfile, false)

            this.refreshGenerationProfileUI.next(true)

            // We don't wait for this to finish on purpose, in order to avoid blocking GP loading
            this.getMelodyVisualPreview()
        }

        return true
    }

    isPreviewLoading(instrument: InstrumentPatch, layer: GPLayer) {
        for (let preview of this.currentPreviews) {
            if (
                preview.layer == layer.name &&
                preview.status == "loading" &&
                instrument.patchID == preview.patchID
            ) {
                return true
            }
        }

        return false
    }

    async getMelodyVisualPreview(preview = null) {
        if (this.generationProfile.melodyLayer != null) {
            this.melodyPreviewLoading = true
            this.refreshGenerationProfileUI.next(true)

            if (preview == null) {
                preview = await this.getPreview([
                    {
                        layer: "Melody",
                        patchID: "k.piano.nat.stac",
                    },
                ])
            }

            preview = preview.tracks[0].track

            for (let note of preview) {
                note.onset = note.start

                delete note.start
            }

            this.generationProfile.melodyLayer.packs[0].preview = preview

            this.melodyPreviewLoading = false
            this.refreshGenerationProfileUI.next(true)
        }
    }

    publishGP(gp: GenerationProfile) {
        this.modalService.modals.publishGP.next(gp)
    }

    getFormTags() {
        let formTags = ""
        let addComma = false

        for (let tag of this.generationProfile.settings.formTags) {
            if (addComma) {
                formTags += ", " + tag.name
            } else {
                formTags += tag.name
                addComma = true
            }
        }

        return formTags
    }

    async selectHarmonyPack(harmonyPack: HarmonyPack) {
        const harmony = await this.sourcePacks.getHarmonyPacks()

        this.generationProfile.selectHarmonyPack(harmonyPack, harmony)
        this.refreshGenerationProfileUI.next(true)
    }

    getPlayingPreviewForLayer(layer, packID = null) {
        for (let preview of this.currentPreviews) {
            if (
                preview.layer == layer &&
                (packID == null || preview.packID == packID)
            ) {
                return preview
            }
        }

        return null
    }

    async pausePreviewForLayer(layer, packID, patchID) {
        for (let preview of this.currentPreviews) {
            if (
                preview.layer == layer &&
                (packID == null || preview.packID == packID) &&
                (patchID == null || preview.patchID == patchID)
            ) {
                this.pausePreview()

                this.currentPreviews = []

                this.refreshGenerationProfileUI.next(true)

                return
            }
        }
    }

    async setAsUpdated(updateType, options?: { layer?; packID?; patchID? }) {
        await this.update(updateType)

        this.selectedEmotions()

        this.refreshGenerationProfileUI.next(true)

        if (options == null || options.layer == null) {
            return
        }

        if (options.layer == "Settings") {
            // @todo: implement this
            return
        }

        let p = 0

        for (let preview of this.currentPreviews) {
            if (
                preview.layer == options.layer &&
                options.packID == preview.packID &&
                options.patchID == preview.patchID
            ) {
                this.pausePreview()

                if (this.hasPlayableElement(options)) {
                    this.currentPreviews.splice(p, 1)
                    this.preview(preview.layer, preview.packID, preview.patchID)
                }

                return
            }

            p += 1
        }
    }

    selectedEmotions() {
        let emotions = this.generationProfile.selectedEmotions()

        if (!emotions.includes(this.generationProfile.settings.emotion)) {
            this.generationProfile.settings.emotion = null
            return null
        }

        return this.generationProfile.settings.emotion
    }

    pausePreview() {
        if (this.windowService.desktopAppAPI !== undefined) {
            this.windowService.samplerAPI.pause()
        }

        if (this.previewEndTimeout != null) {
            clearTimeout(this.previewEndTimeout)
            this.previewEndTimeout = null
        }
    }

    private isNotPublished() {
        if (this.createService.gpStates.filters.admin.includes("published")) {
            return true
        }

        return false
    }

    public async publishCategory(gp) {
        try {
            const res = await this.apiService.authRequest(
                "/generationprofile/categoryExists",
                { categoryID: gp._id },
                "primary",
                true
            )

            if (res.exists) {
                let result = window.confirm(
                    "This category already exists in production. Click Ok to update it, or cancel."
                )

                if (!result) {
                    return Promise.resolve()
                }
            }

            await this.apiService.authRequest(
                "/generationprofile/publishCategory",
                { categoryID: gp._id },
                "primary",
                false
            )

            alert(
                "Published to production! Yikes... Make sure to check everything went well :)"
            )
        } catch (err) {
            return this.apiService.handleError(err)
        }
    }

    public async addGPToMyLibrary(gp, type: "regular" | "forked") {
        let selectedID = gp._id
        const latestPublishedRevision = !this.isNotPublished()

        let res

        if (type === "regular") {
            res = await this.apiService.authRequest(
                "/generationprofile/addGPCategoryToLibrary",
                {
                    type: "category",
                    categoryID: selectedID,
                    latestPublishedRevision: latestPublishedRevision,
                },
                "primary",
                false
            )
        } else {
            res = await this.apiService.authRequest(
                "/generationprofile/addToMyLibrary",
                { gpID: selectedID },
                "primary",
                false
            )
        }

        try {
            if (type === "regular") {
                this.folderService.setContentType(
                    "addGPToMyLibrary",
                    "Styles",
                    res.newFolderID,
                    true
                )
            } else {
                this.openGenerationProfile(res.gpID)
            }
        } catch (err) {
            return this.apiService.handleError(err)
        }
    }

    hasPlayableElement(options: { layer?; packID?; patchID? }): boolean {
        if (options.layer == "Harmony") {
            return true
        }

        if (options.layer == "Melody") {
            if (
                this.generationProfile.melodyLayer == null ||
                this.generationProfile.melodyLayer.packs[0].packID !=
                    options.packID
            ) {
                return false
            }

            for (let patch of this.generationProfile.melodyLayer.packs[0]
                .instruments) {
                if (patch.patchID == options.patchID) {
                    return true
                }
            }

            return false
        }

        let layerObject = null

        for (let layer of this.generationProfile.accompanimentLayers) {
            if (layer.name == options.layer) {
                layerObject = layer

                break
            }
        }

        if (layerObject == null) {
            return false
        }

        for (let pack of layerObject.packs) {
            if (pack.packID != options.packID) {
                continue
            }

            for (let patch of pack.instruments) {
                if (patch.patchID == options.patchID) {
                    return true
                }
            }
        }

        return false
    }

    async startRTSampler() {
        if (this.windowService.desktopAppAPI === undefined) {
            this.setDownloadProgress(100)
            return
        }

        let patches = [
            new Patch("k", "piano", "nat", "stac", "pitchedPercussion"),
        ]

        var input = {
            settings: this.userService.settings,
            userID: this.tokenService.userID,
        }

        await this.windowService.samplerAPI.startSampler(input)

        patches = patches.concat(this.generationProfile.getAllPatches())
        // console.log("startRTSampler", patches)

        return this.requestPatches(patches, patches)
    }

    requestPatches(patches: Array<Patch>, existingPatches) {
        // console.log("requestPatches", {patches, existingPatches})
        if (this.windowService.desktopAppAPI === undefined) {
            this.setDownloadProgress(100)
            return
        }

        this.setDownloadProgress(10)

        patches = patches.filter(
            (value, index, self) =>
                index ===
                self.findIndex(
                    t =>
                        t.instrument === value.instrument &&
                        t.playing_style === value.playing_style &&
                        t.articulation === value.articulation
                )
        )

        let promises = []

        let increment = 90 / patches.length

        for (let patch of patches) {
            promises.push(this.requestPatch(patch, existingPatches, increment))
        }

        this.downloadQueue = Promise.all(promises)

        return this.downloadProgress
    }

    // @todo: switch pack to packID + layer to avoid issues with references not being maintained
    public async addInstrumentPatchesToPack(
        pack: Pack,
        layer: GPLayer,
        patches: InstrumentPatch[]
    ) {
        for (const patch of patches) {
            await this.addInstrumentPatchToPack(pack["idx"], layer.name, patch)
        }

        await this.setAsUpdated("instrument")
    }

    addInstrumentPatchToPack(
        packIndex: number,
        layer: string,
        patch: InstrumentPatch
    ): Promise<any> {
        if (layer === "Melody") {
            this.getLayer(layer).packs[0].instruments.push(patch)
        } else {
            this.getPackByIndex(packIndex, layer).instruments.push(patch)
        }

        if (this.windowService.desktopAppAPI === undefined) {
            return Promise.resolve()
        }

        let promise = Promise.resolve()

        if (this.downloadProgress < 100) {
            promise = this.downloadQueue
        }

        return promise.then(() => {
            let patches = []

            for (let p of patch.patches) {
                patches.push(p.patch)
            }

            return this.requestPatches(
                patches,
                this.generationProfile.getAllPatches()
            )
        })
    }

    async requestPatch(patch: Patch, usedInstruments, increment) {
        await this.windowService.samplerAPI.requestPatch({
            usedInstruments: usedInstruments,
            patch: patch,
        })

        this.setDownloadProgress(this.downloadProgress + increment)
    }

    setDownloadProgress(value) {
        this.downloadProgress = value
        this.refreshGenerationProfileUI.next(true)
    }

    async getGenerationProfileByID(generationProfileID) {
        const res = await this.http.getGPByID(generationProfileID)

        if (res.generationProfile == null) {
            return Promise.resolve(null)
        }

        this.startRTSampler()

        return {
            gp: res.generationProfile,
            layersLoading: res.layersLoading,
        }
    }

    openCompositionCreationModal(data) {
        this.modalService.compositionCreationWithGP.next(data)
    }

    renameGenerationProfile(gp: GenerationProfile) {
        return this.apiService
            .authRequest(
                "/generationprofile/rename",
                { generationProfileID: gp._id, name: gp.name },
                "primary",
                true
            )
            .then(res => {
                this.refreshGenerationProfileUI.next(true)
                return Promise.resolve(true)
            })

            .catch(err => {
                return Promise.resolve(this.apiService.handleError(err))
            })
    }

    downloadBugReport(gp) {
        var url = "/generationprofile/bugReportDownload"

        var params = {
            generationProfileID: gp._id,
        }

        var extension = ".zip"
        var contentType = "application/zip"

        return this.apiService
            .authDownload(url, params)
            .then(res => {
                var blob = new Blob([res], { type: contentType })
                this.fileSaverService.save(
                    blob,
                    "Report - " + gp._id + extension
                )

                return Promise.resolve()
            })

            .catch(err => {
                if (err.result == -2) {
                    return Promise.resolve(0)
                } else {
                    this.apiService.handleError(err)
                }
            })
    }

    removeSynchronisationForPacks(packs: Array<Pack>) {
        for (let layer of this.generationProfile.accompanimentLayers) {
            if (layer.name.includes("Ornament")) {
                continue
            }

            for (let otherPack of layer.packs) {
                for (let pack of packs) {
                    if (
                        otherPack.synchronisation != null &&
                        otherPack.synchronisation.target != null &&
                        pack["idx"] == otherPack.synchronisation.target.idx &&
                        layer.name != otherPack.synchronisation.target.layer
                    ) {
                        otherPack.synchronisation = null
                    }

                    if (
                        otherPack.transform != null &&
                        otherPack.transform.target != null &&
                        pack["idx"] == otherPack.transform.target.idx &&
                        layer.name != otherPack.transform.target.layer
                    ) {
                        otherPack.transform = null
                    }
                }
            }
        }
    }

    openCreateGenerationProfile() {
        this.modalService.modals.createGP.next(true)
    }

    // @todo: make sure that the api call returns a GenerationProfileSchema object rather than GenerationProfile object
    createGenerationProfile(emotion) {
        return this.apiService
            .authRequest(
                "/generationprofile/create",
                {
                    folderID: this.folderService.getSelectedFolderID(),
                    emotion: emotion,
                },
                "primary",
                true
            )
            .then(res => {
                let contentItems = this.folderService.content.getValue()
                contentItems.push(res.generationProfile)

                this.openGenerationProfile(res.generationProfile._id)

                return Promise.resolve(res.generationProfile._id)
            })

            .catch(err => {
                return Promise.resolve(this.apiService.handleError(err))
            })
    }

    openGenerationProfile(generationProfileID: string) {
        this.router.navigate([
            "/generation-profile-editor",
            generationProfileID,
        ])
        this.redirectToView.next("edit")
    }

    deleteGenerationProfile(gp: GenerationProfile) {
        return this.apiService
            .authRequest(
                "/generationprofile/delete",
                { generationProfileID: gp._id },
                "primary",
                true
            )
            .then(res => {
                let contentItems = this.folderService.content.getValue()

                for (let c = 0; c < contentItems.length; c++) {
                    if (contentItems[c] == gp) {
                        contentItems.splice(c, 1)

                        break
                    }
                }

                return Promise.resolve(true)
            })

            .catch(err => {
                return Promise.resolve(this.apiService.handleError(err))
            })
    }

    validateGenerationProfile(
        gp: GenerationProfile,
        showBannerIfInvalid,
        type = "generation"
    ) {
        if (gp == null) {
            return
        }

        this.gpValidation = GenerationProfile.fromJSON(gp).validate(true)

        if (this.gpValidation == null || this.gpValidation.valid == false) {
            if (showBannerIfInvalid) {
                let errorMessageSuffix =
                    "Please resolve them before generating a composition."

                if (type == "publish") {
                    errorMessageSuffix =
                        "You need to resolve them before you can publish."
                }

                if (type == "compositionWorkflow") {
                    errorMessageSuffix =
                        "You need to resolve them before creating a new composition workflow."
                }

                this.showBanner(
                    "error",
                    "There are some errors with this generation profile. " +
                        errorMessageSuffix
                )
            }
        }

        return {
            ui: this.gpValidation,
            data: GenerationProfile.fromJSON(gp).validate(false),
        }
    }

    getValidation() {
        return this.gpValidation
    }

    updateUIValidation() {
        if (this.generationProfile == null || this.getValidation() == null) {
            return
        }

        let gpUIValidation = {}

        gpUIValidation["settings"] = null

        let settingsValidation = this.generationProfile.settings.validate(true)

        if (settingsValidation.valid == false) {
            gpUIValidation["settings"] = settingsValidation.message
        }

        gpUIValidation["hasLayers"] = this.getValidation().hasLayers.valid
        gpUIValidation["hasMainLayer"] = this.getValidation().hasMainLayer.valid
        gpUIValidation["melodyLayer"] = null

        if (this.getValidation().melodyLayer != null) {
            let invalids = this.getValidation().melodyLayer.validations.filter(
                v => v.valid == false
            )

            let validations = [...new Set(invalids.map(inv => inv.issue))]

            if (validations.length == 1) {
                gpUIValidation["melodyLayer"] = invalids[0]["message"]
            } else if (validations.length > 1) {
                gpUIValidation["melodyLayer"] =
                    "There are multiple issues with the melody. Please check the instruments as well as the mixing."
            }
        }

        gpUIValidation["accompanimentLayers"] = {}

        for (let layer of this.generationProfile.accompanimentLayers) {
            if (layer == null || layer.name == null) {
                continue
            }

            let layerValidation = this.getValidationByLayerName(layer.name)

            if (layerValidation != null) {
                gpUIValidation["accompanimentLayers"][layer.name] =
                    layerValidation
            }
        }

        gpUIValidation["harmony"] = null

        let harmonyModeValidation = Harmony.validateHarmonyPackMode(
            this.generationProfile.harmony.packs,
            this.generationProfile.harmony.keySignature.keyMode,
            this.generationProfile.harmony.strategy
        )

        if (harmonyModeValidation.valid == false) {
            gpUIValidation["harmony"] = harmonyModeValidation.message
        }

        let harmonyPacksValidation = Harmony.validateHarmonyPacks(
            this.generationProfile.harmony.packs
        )

        if (harmonyPacksValidation.valid == false) {
            gpUIValidation["harmony"] = harmonyPacksValidation.message
        }

        return gpUIValidation
    }

    getValidationByLayerName(layerName: string) {
        if (
            this.getValidation() != null &&
            this.getValidation().accompanimentLayers.valid == false
        ) {
            for (let layerValidation of this.getValidation().accompanimentLayers
                .validations) {
                if (
                    layerValidation.valid == false &&
                    layerName == layerValidation.target.value.name
                ) {
                    return layerValidation
                }
            }
        }

        return null
    }

    getPreview(previews = null) {
        let parameters = {
            generationProfileID: this.generationProfile._id,
            layers: previews != null ? previews : this.currentPreviews,
        }

        return this.apiService
            .authRequest(
                "/generationprofile/preview",
                parameters,
                "primary",
                false
            )

            .then(res => {
                return Promise.resolve(res.preview)
            })

            .catch(err => {
                return Promise.resolve(this.apiService.handleError(err))
            })
    }

    isPlayingLayerPreview(layer, packID, patchID) {
        for (let preview of this.currentPreviews) {
            if (
                preview.layer == layer &&
                preview.packID == packID &&
                preview.patchID == patchID
            ) {
                return true
            }
        }

        return false
    }

    async preview(layer, packID, patchID, previewScore = null) {
        if (this.windowService.desktopAppAPI === undefined) {
            this.modalService.downloadDesktopAppModal.next(true)

            return
        }

        this.pausePreview()

        if (this.isPlayingLayerPreview(layer, packID, patchID)) {
            this.currentPreviews = []
        } else {
            let layerPreview = LayerPreview.fromJSON({
                layer: layer,
                packID: packID,
                patchID: patchID,
                status: "loading",
            })

            this.resetCurrentPreviews(layerPreview)

            if (!previewScore) {
                previewScore = await this.getPreview()
            }

            await this.playPreviewOnSampler(previewScore, layer)

            this.setCurrentPreviewsAsPlaying()

            if (layer == "Melody") {
                this.getMelodyVisualPreview(previewScore)
            }
        }

        this.refreshGenerationProfileUI.next(true)
    }

    compareNoteEnds(note1, note2) {
        let note1End = Time.addTwoFractions(note1.start, note1.duration)
        let note2End = Time.addTwoFractions(note2.start, note2.duration)

        return Time.compareTwoFractions(note1End, note2End)
    }

    async playPreviewOnSampler(previewScore: TemplateScore, layer) {
        let lastNote

        if (!previewScore) {
            return
        }

        this.playerService.pause()

        for (let track of previewScore.tracks) {
            for (let note of track.track) {
                note.enabled = true
            }

            track.gain_offset = -1 // for some reason, this is necessary to be non 0 for initialisation. Cant remember why, but it works for now
            track.panning = 0

            if (layer == "Harmony") {
                track.name = "k.piano.nat.stac"
                track.instrument = "k.piano"
                track.auto_pedal = false
            }

            let lastTrackNote = track.track[track.track.length - 1]

            if (
                lastNote == null ||
                this.compareNoteEnds(lastNote, lastTrackNote)
            ) {
                lastNote = lastTrackNote
            }
        }

        if (lastNote == null) {
            return
        }

        let seconds = Time.fractionToSeconds(
            TIMESTEP_RES,
            ScoreEncoding.encodeTempoMap(previewScore.tempoMap),
            Time.addTwoFractions(lastNote.start, lastNote.duration)
        )

        this.setEndTimeout(seconds)

        const args = {
            sentAt: Date.now(),
            score: previewScore,
            id: previewScore.tracks[0].name,
        }

        await this.windowService.samplerAPI.loadAudioSamplesInMemory(args)
        await this.windowService.samplerAPI.startRealTimeContext({
            startTime: 0,
            kicks: [],
        })

        previewScore["processingStartTime"] = Date.now()

        this.windowService.samplerAPI.playNotes({
            score: previewScore,
            layer: null,
            volumeLevel: 1,
            offset: 0,
        })
    }

    setCurrentPreviewsAsPlaying() {
        for (let preview of this.currentPreviews) {
            preview.status = "playing"
        }
    }

    setEndTimeout(end: number) {
        if (this.previewEndTimeout != null) {
            clearTimeout(this.previewEndTimeout)
            this.previewEndTimeout = null
        }

        this.previewEndTimeout = setTimeout(() => {
            this.currentPreviews = []
            this.previewEndTimeout = null

            this.refreshGenerationProfileUI.next(true)
        }, (end + 1) * 1000)
    }

    resetCurrentPreviews(layerPreview: LayerPreview) {
        let previewsToKeep = []

        if (layerPreview.layer != "Melody" && layerPreview.layer != "Harmony") {
            for (let preview of this.currentPreviews) {
                if (
                    preview.layer == layerPreview.layer ||
                    preview.layer == "Melody" ||
                    preview.layer == "Harmony"
                ) {
                    continue
                }

                preview.status = "loading"

                previewsToKeep.push(preview)
            }
        }

        previewsToKeep.push(layerPreview)

        this.currentPreviews = previewsToKeep
    }

    getPlaybackIcon(layer, patchID) {
        for (let preview of this.currentPreviews) {
            if (layer == "Harmony" && preview.layer == "Harmony") {
                return this.playbackIcon.pause
            }

            if (preview.layer == layer && preview.patchID == patchID) {
                return this.playbackIcon.pause
            }
        }

        return this.playbackIcon.play
    }

    showBanner(type, message, autoHideAfterSeconds = 6) {
        this.banner.next({
            type,
            message,
            show: true,
        })

        this.refreshGenerationProfileUI.next(true)

        if (autoHideAfterSeconds != null && autoHideAfterSeconds != 0) {
            setTimeout(() => {
                this.banner.next({
                    show: false,
                })

                this.refreshGenerationProfileUI.next(true)
            }, autoHideAfterSeconds * 1000)
        }
    }

    deleteGenerationProfileLayer(layer) {
        if (layer.name != "Melody") {
            this.generationProfile.deleteLayer(layer)
            this.removeSynchronisationForPacks(layer.packs)
        } else {
            this.generationProfile.deleteMelody()
        }

        if (this.generationProfile.settings.minLayers > 1) {
            this.generationProfile.settings.minLayers -= 1
        }

        this.pausePreviewForLayer(layer.name, null, null)

        this.setAsUpdated("deleteLayer")
    }

    setLowestPackNote(pitch, packID, packIDX) {
        if (pitch < 40 || pitch > 72) {
            return
        }

        let updated = false

        for (
            let l = 0;
            l < this.generationProfile.accompanimentLayers.length;
            l++
        ) {
            for (
                let p = 0;
                p < this.generationProfile.accompanimentLayers[l].packs.length;
                p++
            ) {
                if (
                    this.generationProfile.accompanimentLayers[l].packs[p]
                        .packID == packID &&
                    this.generationProfile.accompanimentLayers[l].packs[p][
                        "idx"
                    ] == packIDX &&
                    this.generationProfile.accompanimentLayers[l].packs[p]
                        .lowestNote != pitch
                ) {
                    this.generationProfile.accompanimentLayers[l].packs[
                        p
                    ].lowestNote = pitch

                    updated = true
                }
            }
        }

        if (updated) {
            this.setAsUpdated("packLowestNote")
        }
    }

    openAccompanimentDesigner(pack: AccompanimentPack, layer: string) {
        this.router.navigate([
            "accompaniment-designer",
            this.generationProfile._id,
            pack.packID,
            layer,
            false + "",
            false + "",
        ])
    }

    drawPacksPreview(
        pack: AccompanimentPack | Pack,
        layerName: string,
        type: string,
        index: number
    ) {
        let ctx: CanvasRenderingContext2D

        if (type == "accompaniment") {
            ctx = this.designService.getContext(
                "canvas-" + layerName + "-" + index
            )
        } else if (type == "melody") {
            ctx = this.designService.getContext("canvas-melody")
        } else if (type == "mini-pack") {
            ctx = this.designService.getContext("canvas-mini-pack-" + index)
        }

        if (ctx == null) {
            return
        }

        this.generateCanvasPreview(ctx, pack, layerName, type)
    }

    generateCanvasPreview(
        ctx,
        pack: Pack | AccompanimentPack,
        layerName: string,
        type: string
    ) {
        if (pack == null) {
            return
        }

        let dpi = window.devicePixelRatio || 1 // for HiDPI displays

        let width = type == "mini-pack" ? 60 : 130
        let height = type == "mini-pack" ? 30 : 70

        ctx.canvas.width = width * dpi
        ctx.canvas.height = height * dpi

        if (dpi != 1) {
            ctx.scale(dpi, dpi)

            ctx.canvas.style.width = width + "px"
            ctx.canvas.style.height = height + "px"
        }

        if (layerName != "Percussion") {
            let durationInFractions =
                pack instanceof AccompanimentPack
                    ? pack.previewLength
                    : this.calculatePreviewDuration(pack)

            this.generatePitchedPreview(
                ctx,
                pack,
                height,
                width,
                durationInFractions
            )
        } else {
            this.generatePercussionPreview(ctx, pack, layerName, height, width)
        }

        ctx.strokeStyle = "transparent"
        ctx.fillStyle = this.designService.getColorForGPLayer(layerName)

        ctx.lineWidth = 0

        ctx.fill("evenodd")

        ctx.closePath()

        ctx.globalCompositeOperation = "destination-over" // draw behind
        ctx.globalCompositeOperation = "source-over" // normal behavior
    }

    calculatePreviewDuration(pack) {
        let lastElement = pack.preview[pack.preview?.length - 1]

        return Time.addTwoFractions(lastElement?.onset, lastElement?.duration)
    }

    generatePitchedPreview(
        ctx,
        pack: Pack,
        height,
        width,
        durationInFractions
    ) {
        const previewLength = Time.fractionToTimesteps(
            TIMESTEP_RES,
            durationInFractions
        )
        const minMaxPitches = pack.getMinAndMaxPitchInPreviewNotes()

        const pxPerTimestep = width / previewLength

        const pitchRange = minMaxPitches.max - minMaxPitches.min + 1
        const noteHeight = Math.min(
            Math.round(height / 15),
            height / pitchRange
        )

        const isMonophonic = pack.isMonophonicPreview()

        for (let previewNote of pack.preview) {
            const x = this.round(
                Time.fractionToTimesteps(TIMESTEP_RES, previewNote.onset) *
                    pxPerTimestep
            )
            const duration = this.round(
                Time.fractionToTimesteps(TIMESTEP_RES, previewNote.duration) *
                    pxPerTimestep -
                    2
            )

            for (let pitch of previewNote.pitch) {
                let y = this.round(
                    height - ((pitch - minMaxPitches.min) / pitchRange) * height
                )

                y = this.round(y - (noteHeight / 2) * previewNote.pitch.length)

                if (previewNote.pitch.length == 1) {
                    y = this.round(y - noteHeight)
                }

                if (isMonophonic) {
                    y = this.round(height / 2 - noteHeight)
                }

                if (y < 0) {
                    y = 0
                }

                ctx.rect(x, y, duration, noteHeight)
            }
        }
    }

    round(value) {
        return Math.max(Math.round(value), 1)
    }

    getChannelsForPercussionPreview(pack: Pack) {
        let channels: Channel[] = []

        for (let preview of pack.preview) {
            for (let pitch of preview.pitch) {
                if (this.instruments.pitchToChannelMapping[pitch] == null) {
                    continue
                }

                let channelName = this.instruments.pitchToChannelMapping[pitch]
                let channel = channels.find(c => c.name == channelName)

                if (channel == null) {
                    channels.push(
                        new Channel(
                            channelName,
                            [pitch],
                            [{ start: preview.onset }],
                            true,
                            false,
                            null
                        )
                    )
                } else {
                    if (!channel.pitches.includes(pitch)) {
                        channel.pitches.push(pitch)
                    }

                    channel.onsets.push({
                        start: preview.onset,
                    })
                }
            }
        }

        return channels
    }

    generatePercussionPreview(
        ctx,
        pack: Pack,
        layerName: string,
        height,
        width
    ) {
        let channels = this.getChannelsForPercussionPreview(pack)

        const heightPerChannel = height / channels.length
        const patternResolution = this.getResolutionForPercussionPreview(
            pack,
            layerName
        )
        const resolution = Time.fractionToTimesteps(
            TIMESTEP_RES,
            patternResolution
        )

        const widthPerStep = width / parseInt(patternResolution.split("/")[1])
        const borderWidth = widthPerStep / 12
        const borderHeight = heightPerChannel / 12
        const isMonophonic = pack.isMonophonicPreview()

        for (var c = 0; c < channels.length; c++) {
            var channel = channels[c]

            if (channel.onsets.length == 0) {
                continue
            }

            for (let onset of channel.onsets) {
                let onsetInNumber =
                    Time.fractionToTimesteps(
                        TIMESTEP_RES,
                        Time.addTwoFractions(onset.start, "0", true)
                    ) / resolution
                let x = onsetInNumber * widthPerStep

                let y = c * heightPerChannel + borderHeight
                let stepWidth = widthPerStep - borderWidth
                let stepHeight = heightPerChannel - borderHeight

                if (stepHeight > height / 2) {
                    stepHeight = 20
                }

                if (isMonophonic) {
                    y = Math.round(height / 2 - stepHeight / 2)
                }

                ctx.rect(x, y, stepWidth, stepHeight)
            }
        }
    }

    getResolutionForPercussionPreview(pack: Pack, layerName: string) {
        if (layerName != "Percussion") {
            return
        }

        let channels = this.getChannelsForPercussionPreview(pack)
        let resolution = "1/16"
        let minDiff
        let onsetsAsTimesteps = []

        for (let c = 0; c < channels.length; c++) {
            let channel = channels[c]

            onsetsAsTimesteps = onsetsAsTimesteps.concat(
                channel.onsets.map(o =>
                    Time.fractionToTimesteps(TIMESTEP_RES, o.start)
                )
            )
        }

        onsetsAsTimesteps = [...new Set(onsetsAsTimesteps)]

        onsetsAsTimesteps.sort((a, b) => {
            if (a < b) {
                return -1
            }
            if (a > b) {
                return 1
            }
            return 0
        })

        minDiff = this.getMinDifference(onsetsAsTimesteps)

        if (minDiff == 0 || minDiff == null) {
            return resolution
        }

        resolution = Time.timestepsToFraction(TIMESTEP_RES, minDiff)

        return resolution
    }

    getMinDifference(arr) {
        let minDifference

        if (arr.length < 2) {
            return minDifference
        }

        for (let o = 0; o < arr.length; o++) {
            if (arr[o + 1] != null) {
                let localDiff = arr[o + 1] - arr[o]

                if (minDifference == null || localDiff < minDifference) {
                    minDifference = localDiff
                }
            }
        }

        return minDifference
    }
}
