<search-bar
    (close)="closeSearchModal()"
    (searchResults)="setSearchResults($event)"
    type="doubles"
    [parameters]="parameters"></search-bar>

<ngx-simplebar
    class="instrument-selection-modal-middle-container"
    id="instrument-selection-modal-middle-scrollbar"
*ngIf="searchResults.length === 0">
    <instrumentpalette
        *ngIf="showInstrumentPalette"
        [data]="data"
        [type]="'doubles'"
        (valueOutput)="selectedDouble($event, scrollbar)"
        [parameters]="parameters"
        [parentElement]="''"
        [selectedSearchResult]="selectedSearchResult"
        [recursionLevel]="0"></instrumentpalette>
</ngx-simplebar>

<div
    class="instrument-selection-modal-middle-container"
    *ngIf="searchResults.length > 0"
    [perfectScrollbar]="scrollConfig">
    <div
        *ngFor="let result of searchResults"
        [ngClass]="{
            'instrument-selection-search-result-hover': result === hoveredResult
        }">
        <a-button
            class="instrument-search-preview"
            type="round-icon-and-text"
            width="35px"
            *ngIf="result.type === 'patch' && parameters.layer.type !== 'percussion'"
            [loading]="isDesktopApp() && previewIsLoading()"
            [icon]="getPreviewIcon(result)"
            (onClick)="playPreview($event, result.value)">
        </a-button>

        <div
            class="instrument-search-left-empty-space"
            *ngIf="result.type !== 'patch' || parameters.layer.type === 'percussion'"></div>

        <div
            (click)="selectSearchResult(result)"
            class="instrument-selection-search-result"
            (mouseenter)="hoveredResult = result"
            (mouseleave)="hoveredResult = null">
            {{ printSearchResultPath(result) }}
        </div>
    </div>
</div>
