<!-- <div id="success-message" *ngIf="successMessage != ''">{{ successMessage }}</div> -->

<div id="login-component" class="component-container">
    <!-- Main Login window -->
    <div id="login-window-wrapper">
        <div class="aiva-logo">
            <img src="assets/img/navbar/aiva_white.svg">
            <div class="aiva-logo-text">AIVA</div>
        </div>
        
        <div class="waiting-on-browser-login">
            <div class="loader"></div>

            <div>Opening a new browser window, please <span *ngIf="type == 'login'">log in</span><span *ngIf="type == 'create'">create</span> your account there.</div>

            <div class="waiting-on-browser-login-italic">Window didn't open? <span class="waiting-on-browser-login-link" (click)="toURL()">Try with this link</span></div>
        </div>
    </div>
</div>

<small class="re-captcha desktop-only">This site is protected by reCAPTCHA and the Google 
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
</small>