import { Query } from "@datorama/akita"
import { GeneralState, GeneralStore } from "./general.store"

export class GeneralQuery extends Query<GeneralState> {
    public allState$ = this.select()

    constructor(protected store: GeneralStore) {
        super(store)
    }

    public get reverbs() {
        return this.getValue().reverbs
    }

    public get presetUseCases() {
        return this.getValue().presetUseCases
    }
}
