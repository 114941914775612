import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
    ViewChild,
} from "@angular/core"
import { DeviceDetectorService } from "ngx-device-detector"
import { isEqual } from "lodash"

export type Item = {
    name: string
    value: any
    optgroup?: string
}

@Component({
    selector: "dropdown",
    templateUrl: "dropdown.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["dropdown.component.scss"],
})
export class DropdownComponent {
    @Input() type: string = "light"
    @Input() prefix: string = ""
    @Input() description: string
    @Input() placeholder: string
    @Input() value: Item
    @Input() showTooltip: boolean
    @Input() items: Array<Item>
    @Input() class: string
    @Input() sort: boolean
    @Input() disabled: boolean = false
    @Input() showArrow: boolean = true

    hoveredElement: any

    @Output() selectedItem = new EventEmitter<any>()
    @Output() menuToggled = new EventEmitter<any>()

    @ViewChild("selectElement") selectElement: ElementRef

    toggleMenu: boolean = false
    changeSelectStyle = false
    hasOptGroups = false
    optGroups = []
    itemsGroupedByOptGroup = {}
    styleClassesToUse = ""

    constructor(
        private ref: ChangeDetectorRef,
        private device: DeviceDetectorService
    ) {}

    detectChanges() {
        this.ref.detectChanges()
    }

    /*ngOnChanges(changes:SimpleChanges) {
        const itemsHaveChanged = changes.items && (
            changes.items.firstChange || 
            !this.itemsAreEqual(changes.items.previousValue, changes.items.currentValue)
        )

        const valueHasChanged = changes.value && (
            changes.value.firstChange ||
            this.value.name !== this.value.name
        )

        if (itemsHaveChanged || valueHasChanged) {
            this.ref.detectChanges()
        }
    }

    itemsAreEqual(items1:Item[], items2:Item[]) {
        if (items1.length !== items2.length) {
            return false
        }

        for (let item1 of items1) {
            let hasEqual = false

            for (let item2 of items2) {
                if (item2.name === item1.name) {
                    hasEqual = true
                    break
                }
            }

            if (!hasEqual) {
                return false
            }
        }

        return true
    }*/

    ngOnInit() {
        this.initDropdown()
    }

    initDropdown() {
        if (this.class == null) {
            this.class = ""
        }

        this.setStyleClassesToUseByType()
        this.optGroups = this.getOptGroups()
        this.hasOptGroups = this.optGroups.length > 0
        this.itemsGroupedByOptGroup = this.getItemsGroupedByOptGroup()
        this.changeSelectStyle =
            (this.device.browser == "Chrome" && this.device.os == "Linux") ||
            this.device.os == "Windows" ||
            (this.device.browser == "Firefox" && this.device.os == "Mac") // select options were invisible because of a lack of contrast
    }

    setStyleClassesToUseByType() {
        if (this.type == null || this.type == "light") {
            this.styleClassesToUse = "dropdown-menu light-dropdown-menu"
        } else if (this.type == "button-like-dropdown") {
            this.styleClassesToUse = "button-like-dropdown"
        }
    }

    getOptGroups() {
        let items = this.items?.filter(item => item.hasOwnProperty("optgroup"))

        if (!items || items.length === 0) {
            return []
        }

        let optGroups = [...new Set(items.map(item => item.optgroup))]

        if (this.sort) {
            optGroups.sort()
        }

        return optGroups
    }

    getItemsGroupedByOptGroup() {
        let itemsGroupedByOptGroup = {}

        if (!this.hasOptGroups) {
            return itemsGroupedByOptGroup
        }

        for (let optGroup of this.optGroups) {
            let items = this.items.filter(item => item.optgroup == optGroup)

            if (items.length == 0) {
                itemsGroupedByOptGroup[optGroup] = []
                continue
            }

            if (this.sort == true) {
                items.sort((a, b) => a.name.localeCompare(b.name))
            }

            itemsGroupedByOptGroup[optGroup] = items
        }

        return itemsGroupedByOptGroup
    }

    selectItem(event) {
        const item = JSON.parse(event.target.value)

        // get the selected item from the original list of dropdown items so we don't loose the reference (we don't copy by value here!)
        let selectedItem = this.items.find(i => {
            if (i.value instanceof Array) {
                return (
                    i.value[0] === item.value[0] && i.value[1] === item.value[1]
                )
            } else if (i.value instanceof Object) {
                return isEqual(i.value, item.value)
            }

            return i.value === item.value
        })

        if (selectedItem != null) {
            this.selectedItem.emit({ current: this.value, new: selectedItem })

            this.setToggleMenu(false)

            // we have to do this to make sure an option is only selected when it is reflected by the value given as an input
            // e.g. selecting something but the parent component does not agree on the selection -> no selection is made
            this.setOptionAsSelected()
        }

        this.detectChanges()
    }

    setOptionAsSelected() {
        if (this.value == null) {
            return
        }

        const options = Array.from(this.selectElement.nativeElement.options)
        const index = options.findIndex(
            option => option["innerText"].trim() == this.value.name
        )

        if (index != -1) {
            options[index]["selected"] = true
        }
    }

    setToggleMenu(boolean: boolean) {
        if (boolean == null) {
            boolean = !this.toggleMenu
        }

        this.toggleMenu = boolean

        if (this.toggleMenu == false) {
            this.menuToggled.emit({ toggled: false, dropdownMenu: this })
        } else {
            setTimeout(() => {
                this.menuToggled.emit({ toggled: true, dropdownMenu: this }) // clickOutside triggers with a delay, so we have to delay this emitter as well
            }, 100)
        }
    }
}
