import GPLayer from "../gplayer"
import AccompanimentPack from "./accompanimentpack"
import { GPMixing } from "../gpmixing"
import InstrumentPatch from "../../score/instrumentpatch"
import GPInfluenceLoading from "../influences/gpinfluenceloading"
import { AccompanimentLayersSchema } from "../../../interfaces/db-schemas/generationprofile"
import {
    AccompanimentPackMusicEngine,
    LayersMusicEngine,
} from "../../../interfaces/music-engine/generationprofile"
import { AccompanimentManipulation } from "../../../modules/accompaniment-pack/accompaniment-manipulation"

export default class AccompanimentLayer extends GPLayer {
    constructor(name: string) {
        super(name)
    }

    static removeInstrumentsDuplicate(instruments) {
        let results = []
        let nonDoublingInstrumentsMap = {} // this is here to avoid the inner loop for non doubling instruments, and therefore optimize the function's execution time

        for (let i = 0; i < instruments.length; i++) {
            if (instruments[i].patchID.split(".")[0] == "d") {
                let add = true

                for (let j = i + 1; j < instruments.length; j++) {
                    let samePatchID =
                        instruments[i].patchID == instruments[j].patchID
                    let bothDoubles =
                        instruments[i].patchID.split(".")[0] == "d" &&
                        instruments[j].patchID.split(".")[0] == "d"

                    if (
                        samePatchID ||
                        (bothDoubles &&
                            InstrumentPatch.computeSimilarity(
                                instruments[i],
                                instruments[j]
                            ) == 1)
                    ) {
                        add = false
                        break
                    }
                }

                if (add) {
                    results.push(instruments[i])
                }
            } else if (
                nonDoublingInstrumentsMap[instruments[i].patchID] == null
            ) {
                nonDoublingInstrumentsMap[instruments[i].patchID] = 1
                results.push(instruments[i])
            }
        }

        return results
    }

    static computeInstrumentScore(layer1, layer2) {
        let instruments1 = []
        let instruments2 = []

        for (let pack1 of layer1.packs) {
            instruments1 = instruments1.concat(pack1.instruments)

            for (let pack2 of layer2.packs) {
                instruments2 = instruments2.concat(pack2.instruments)

                if (pack1.packID == pack2.packID) {
                    break
                }
            }
        }

        // There are some instances in production of GPs with a high amount of instruments which cause some issues. Ignore those for now
        if (instruments1.length > 2000 || instruments2.length > 2000) {
            return 0
        }

        instruments1 =
            AccompanimentLayer.removeInstrumentsDuplicate(instruments1)
        instruments2 =
            AccompanimentLayer.removeInstrumentsDuplicate(instruments2)

        let instrumentScore = 0

        for (let instrument1 of instruments1) {
            let instrument1Score = 0

            for (let instrument2 of instruments2) {
                instrument1Score = Math.max(
                    InstrumentPatch.computeSimilarity(instrument1, instrument2),
                    instrument1Score
                )
            }

            instrumentScore += instrument1Score
        }

        instrumentScore =
            instrumentScore / Math.max(instruments1.length, instruments2.length)

        return instrumentScore
    }

    static computeSimilarity(
        layer1: AccompanimentLayer,
        layer2: AccompanimentLayer
    ) {
        let score = 0
        let packScore = 0

        for (let pack1 of layer1.packs) {
            for (let pack2 of layer2.packs) {
                if (pack1.packID == pack2.packID) {
                    packScore += 1
                    break
                }
            }
        }

        let instrumentScore = AccompanimentLayer.computeInstrumentScore(
            layer1,
            layer2
        )

        packScore =
            packScore / Math.max(layer1.packs.length, layer2.packs.length)
        score += packScore * 0.4
        score += instrumentScore * 0.6

        return {
            score: score,
            report: {
                packScore: packScore,
                instrumentScore: instrumentScore,
                layer: layer1.name,
            },
        }
    }

    encodeForDB(): AccompanimentLayersSchema {
        return {
            mixing: this.mixing.encodeForDB(),
            name: this.name,
            packs: this.packs.map(p => p.encodeForDB()),
            fixedInstrumentation: this.fixedInstrumentation,
            gpInfluenceLoading:
                this.gpInfluenceLoading != null
                    ? this.gpInfluenceLoading.encode()
                    : null,
        }
    }

    encodeForME(): LayersMusicEngine {
        return {
            mixing: this.mixing.encodeForME(),
            accompaniment_packs: this.packs.map(p => p.encodeForME()),
            fixed_instrumentation: this.fixedInstrumentation,
        }
    }

    static fromJSON(object) {
        let accompanimentLayer = Object.assign(
            new AccompanimentLayer(""),
            object
        )

        for (let i = 0; i < accompanimentLayer.packs.length; i++) {
            let pack = accompanimentLayer.packs[i]
            pack.idx = i

            accompanimentLayer.packs[i] = AccompanimentPack.fromJSON(pack)
            accompanimentLayer.packs[i].lowestNote =
                pack["lowestNote"] ||
                AccompanimentManipulation.getLowestNoteByLayer(
                    accompanimentLayer.name
                )
        }

        accompanimentLayer.mixing = GPMixing.fromJSON(object.mixing)
        accompanimentLayer.gpInfluenceLoading = GPInfluenceLoading.fromJSON(
            object.gpInfluenceLoading
        )

        return accompanimentLayer
    }

    static getAllInstruments(layer: AccompanimentLayer) {
        const instruments = []

        for (let pack of layer.packs) {
            instruments.concat(this.removeInstrumentsDuplicate(pack.instruments))
        }

        console.log("getAllInstruments", instruments)

        return instruments
    }
}
