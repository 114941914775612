<div class="modal-title" *ngIf="parameters.double.patchID === ''">
    Add doubling instrument
</div>
<div class="modal-title" *ngIf="parameters.double.patchID !== ''">
    Edit doubling instrument
</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>
<input
    placeholder="Name of doubling instrument"
    type="text"
    class="modal-input edit-double-name-input"
    [(ngModel)]="parameters.double.name" />

<div class="instrument-edition-container" [perfectScrollbar]="scrollChange">
    <div
        class="no-instrument-assigned"
        *ngIf="parameters.double.patches.length === 0">
        No instrument was assigned.
    </div>
    <div
        class="octave-header"
        *ngIf="
            parameters.double.patches.length > 0 &&
            parameters.double.type === 'pitched'
        ">
        Octave
        <img
            src="assets/img/info.svg"
            class="tooltip-img"
            tooltip="Defines the number of octaves an instrument will be transposed up or down."
            [tooltip-marginTop]="10"
            [tooltip-width]="250" />
    </div>
    <div
        class="double-patch-entry"
        *ngFor="let patchEntry of parameters.double.patches; let i = index">
        <img
            class="delete-instrument-icon"
            src="assets/img/close.svg"
            (click)="deletePatchFromPack(i)" />

        <div class="double-patch-entry-title">
            {{ getInstrumentNameForPatch(patchEntry.patch) }}
        </div>

        <div
            class="settings-element"
            *ngIf="parameters.double.type === 'pitched'">
            <div
                class="increment-button"
                (click)="changeOctave(patchEntry, -1)">
                -
            </div>
            <input
                placeholder="0"
                [(ngModel)]="patchEntry.octave"
                type="number"
                (change)="verifyOctave(patchEntry)" />
            <div class="increment-button" (click)="changeOctave(patchEntry, 1)">
                +
            </div>
        </div>
    </div>
</div>

<a-button
    class="add-instrument-to-double"
    width="50%"
    label="Add instrument"
    [icon]="'assets/img/add.svg'"
    type="round-icon-and-text"
    (onClick)="addPatch()"></a-button>

<a-button
    class="secondary"
    width="50%"
    label="Apply changes"
    (onClick)="updateDouble()"
    *ngIf="parameters.double.patchID !== ''"></a-button>
<a-button
    class="secondary"
    width="50%"
    label="Create"
    (onClick)="updateDouble()"
    *ngIf="parameters.double.patchID === ''"></a-button>
