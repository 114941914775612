import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
    ViewChild,
} from "@angular/core"
import SearchItem from "@common-lib/classes/searchitem"
import { DoublesService } from "@services/doubles.service"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import { SearchInstrumentPreviewService } from "@services/searchinstrumentpreview.service"
import { RecommendationsService } from "@services/recommendations.service"
import { WindowService } from "@services/window.service"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { InstrumentsDownloadService } from "@services/instruments/instrumentsDownload.service"
import { DoublingInstrumentSelectionModalParameters } from "@services/modal.service"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"

@Component({
    selector: "doublesearch",
    templateUrl: "doublesearch.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoubleSearchComponent {
    @ViewChild("scrollbar", { static: false }) scrollbar

    @Input() parameters: DoublingInstrumentSelectionModalParameters

    @Output() close: EventEmitter<any> = new EventEmitter()

    searchKeyword = ""
    searchResults = []
    selectedSearchResult = null

    searchTimeout = null

    data: Array<SearchItem> = []

    showInstrumentPalette = true

    hoveredResult = null

    constructor(
        private windowService: WindowService,
        private instruments: InstrumentsService,
        private searchInstrumentPreviewService: SearchInstrumentPreviewService,
        private doublesService: DoublesService,
        private ref: ChangeDetectorRef,
        private recommendationsService: RecommendationsService,
        public instrumentsDownload: InstrumentsDownloadService
    ) {}

    ngOnInit() {
        this.instruments.query.select("refreshSearchUI").subscribe(value => {
            if (value) {
                this.ref.detectChanges()
            }
        })

        this.init()

        this.doublesService.refreshDoubleSearchUI.subscribe(value => {
            if (!value) {
                return
            }

            this.init()
        })

        this.initRecommendationSubscribers()
    }

    private initRecommendationSubscribers() {
        if (
            this.parameters.allowRecommendations === false ||
            this.parameters.layer instanceof GPLayer
        ) {
            return
        }

        this.recommendationsService.recommendationsChanged.subscribe(value => {
            if (
                !value ||
                value.type == null ||
                this.doublesService.doublesSearchItems == null ||
                this.doublesService.doublesSearchItems.length == 0
            ) {
                return
            }

            this.recommendationsService.getTaggedDoubles({
                type: this.parameters.layer.type as "pitched" | "percussion",
                doubles: this.doublesService.doublesSearchItems,
                layer: this.parameters.layer as GPLayer,
                pack: this.parameters.pack,
            })

            this.instruments.setRefreshSearchUI()
        })
    }

    init() {
        this.selectedSearchResult =
            this.doublesService.selectedSearchItem.getValue()

        this.getDoubles()
        this.scrollLeft()
    }

    isDownloadingAllInstruments() {
        return this.instruments.query.isDownloadingAllInstruments
    }

    getDownloadedPatches() {
        return this.instruments.query.downloadAllStatus.completed
    }

    getTotalToDownload() {
        return this.instruments.query.downloadAllStatus.total
    }

    isDesktopApp() {
        return this.windowService.desktopAppAPI !== undefined
    }

    previewIsLoading() {
        return this.searchInstrumentPreviewService.loading
    }

    setShowInstrumentPalette(show: boolean) {
        this.showInstrumentPalette = show
        this.ref.detectChanges()
    }

    getPreviewIcon(data) {
        let item: SearchItem = data.value

        if (
            this.searchInstrumentPreviewService.currentlyPlayingPatchID ==
            item.item.patchID
        ) {
            return "assets/img/player/pause.svg"
        }

        return "assets/img/player/play.svg"
    }

    getDoubles() {
        this.setShowInstrumentPalette(false)

        this.data = this.doublesService.doublesSearchItems

        if (
            this.data == null ||
            this.data.length == 0 ||
            (this.doublesService.doubleType != null &&
                this.doublesService.doubleType != this.parameters.layer.type)
        ) {
            this.data = this.doublesService.getDoublesSearchItems({
                layer: this.parameters.layer,
                pack: this.parameters.pack,
                allowRecommendations: this.parameters.allowRecommendations,
            })
        }

        if (this.parameters.double != null) {
            this.selectedSearchResult =
                this.convertDoubleToSelectedSearchResult(
                    this.parameters.double,
                    this.data
                )
        }

        this.setShowInstrumentPalette(true)
    }

    convertDoubleToSelectedSearchResult(
        double: InstrumentPatch,
        values: Array<SearchItem>
    ) {
        for (let item of values) {
            if (item.type != "folder") {
                continue
            }

            if (
                double.folderID == "" &&
                item.createdByAiva == double.createdByAiva
            ) {
                return item
            }

            if (double.folderID == item.item._id) {
                return item
            }

            let result = this.convertDoubleToSelectedSearchResult(
                double,
                item.values
            )

            if (result != null) {
                return result
            }
        }

        return null
    }

    selectSearchResult(result) {
        this.selectedSearchResult = result.value
        this.searchResults = []

        this.scrollLeft()

        if (result == null) {
            return
        }

        let patches = this.getPatchesFromSearchItem(result.value)

        if (result.value != null && result.value.values == null) {
            const shouldClose = this.parameters.selectNewInstrumentPatches(patches)

            if (shouldClose) {
                this.closeSearchModal()
            }
        }
    }

    playPreview(event, searchItem) {
        this.searchInstrumentPreviewService.playPreview(
            searchItem,
            event,
            searchItem
        )
    }

    getNumberOfRecommendationsApplied() {
        return this.recommendationsService.getNumberOfRecommendationsApplied(
            this.parameters.layer.type
        )
    }

    search(event) {
        if (this.searchTimeout != null) {
            clearTimeout(this.searchTimeout)
            this.searchTimeout = null
        }

        // The timeout is necessary to ensure that the instrumentSelectionSearchKeyword variable is up to date
        this.searchTimeout = setTimeout(() => {
            if (this.searchKeyword == "") {
                this.searchResults = []
                return
            }

            var result = this.instruments.searchForPatches(
                this.searchKeyword,
                this.parameters.layer,
                this.data
            )

            result = result.sort((x, y) => {
                var xLength = x.value.path.split("/").length
                var yLength = y.value.path.split("/").length

                var n = y.exact / yLength - x.exact / xLength

                if (n !== 0) {
                    return n
                }

                return y.partial / yLength - x.partial / xLength
            })

            this.searchResults = result

            this.ref.detectChanges()
        }, 200)
    }

    selectedDouble(event, element) {
        this.selectedSearchResult = event.element

        this.scrollLeft()

        if (event.element == null) {
            return
        }

        let patches = this.getPatchesFromSearchItem(event.element)

        if (event.element.values == null || event.bulkSelection) {
            const result = this.parameters.selectNewInstrumentPatches(patches)

            if (result) {
                this.closeSearchModal()
            }
        }
    }

    getPatchesFromSearchItem(searchItem: SearchItem): Array<InstrumentPatch> {
        let result = []

        if (searchItem.values == null) {
            result.push(searchItem.item)
        } else {
            for (let item of searchItem.values) {
                result = result.concat(this.getPatchesFromSearchItem(item))
            }
        }

        return result
    }

    printSearchResultPath(result) {
        if (result.type == "patch" && result.value.path != null) {
            return result.value.path + "/" + result.value.name
        }

        return result.value.name
    }

    closeSearchModal() {        
        this.close.emit(0)

        this.parameters.onClose()
    }

    scrollLeft() {
        setTimeout(() => {
            if (this.scrollbar != null && this.scrollbar.SimpleBar != null) {
                this.scrollbar.SimpleBar.getScrollElement().scrollLeft =
                    this.scrollbar.SimpleBar.getScrollElement().scrollWidth +
                    1500
            }
        }, 1)
    }
    public setSearchResults(event) {
        this.searchResults = event
    }
}
