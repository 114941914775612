import { Injectable } from "@angular/core"
import { AnimationLoopService } from "./animationloop.service"
import { ApiService } from "./api.service"
import { CompositionService } from "./composition.service"
import { GenerationProfileService } from "./generation-profile/generationprofile.service"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import GPInfluenceLoading from "@common-lib/classes/generationprofiles/influences/gpinfluenceloading"
import { FolderService } from "./folder.service"
import { SocketLoadingStatusInput } from "@common-lib/interfaces/api/sockets"

@Injectable()
export class LoadingStatusService {
    readonly waitTimeInSeconds = 25

    private firstTimeLoading = true

    constructor(
        protected animationLoopService: AnimationLoopService,
        protected apiService: ApiService,
        protected compositionService: CompositionService,
        protected gpService: GenerationProfileService,
        protected folderService: FolderService
    ) {}

    ngOnDestroy() {
        this.animationLoopService.deleteFunctionFromLoop(
            "loadingStatusRefresher"
        )
    }

    init() {
        this.animationLoopService.addFunctionToLoop(
            "loadingStatusRefresher",
            this.loadingStatusRefresher.bind(this),
            this.waitTimeInSeconds * 1000
        )
    }

    async loadingStatusRefresher() {
        try {
            if (this.firstTimeLoading) {
                this.firstTimeLoading = false
                return
            }

            const eventTypes: SocketLoadingStatusInput = {
                generationProfile: this.getLoadingInfluencesFromGP(),
                compositions:
                    this.folderService.getContentType() === "Compositions"
                        ? this.getLoadingContent()
                        : [],
                influences:
                    this.folderService.getContentType() === "Influences"
                        ? this.getLoadingContent()
                        : [],
            }

            if (
                eventTypes.generationProfile !== undefined ||
                eventTypes.compositions.length > 0 ||
                eventTypes.influences.length > 0
            ) {
                console.log("Loading status refresher")

                await this.apiService.authRequest(
                    "/socket/loadingStatus",
                    {
                        eventTypes: eventTypes,
                    },
                    "primary",
                    false
                )
            }
        } catch (e) {
            console.error(e)
        }
    }

    getLoadingInfluencesFromGP() {
        if (!this.gpService.generationProfile) {
            return undefined
        }

        const gp: GenerationProfile = this.gpService.generationProfile
        const components = gp.getComponents()

        let loadingComponents = []

        for (let component of components) {
            let gpInfluenceLoading: GPInfluenceLoading =
                gp.getGPInfluenceLoading(component.name)

            if (
                gpInfluenceLoading.influenceID !== "" &&
                !gpInfluenceLoading.error
            ) {
                loadingComponents.push(component.name)
            }
        }

        if (loadingComponents.length > 0) {
            return {
                id: gp._id,
                components: loadingComponents,
            }
        }

        return undefined
    }

    getLoadingContent() {
        let loadingContent = []
        const contentEntries = this.folderService.content.getValue()

        for (let content of contentEntries) {
            if (content?.type === "training") {
                continue
            }

            if (!content.isFinished) {
                loadingContent.push(content._id)
            }
        }

        return loadingContent
    }
}
