<div class="modal-title">Material Variations</div>
<div class="modal-close"><img src="assets/img/close.svg" (click)="closeModal()"></div>

<div class="settings-row">
    <div class="settings-row-title">
        Strategy
    </div>
    <div class="settings-row-value">
        <dropdown 
            type="light" width="170px" 
            [value]="getStrategy()" 
            [showTooltip]="false" 
            [items]="getStrategyOptions()" 
            (selectedItem)="selectStrategy($event)"
        >

        </dropdown>
    </div>
</div>

<div class="settings-row">
    <div class="settings-row-title">
        Variation likelihood
    </div>
    <div class="settings-row-value">
        <ngx-slider 
            class="light-ngx-slider light-ngx-slider-development-designer" 
            [options]="getLikelihoodOptions()" 
            [value]="getLikelihood()"
            (userChangeEnd)="setVariationLikelihood($event)"
        >
        </ngx-slider>
    </div>
</div>

<div class="settings-row settings-row-toggle" (click)="toggleSettingsRow('categories')">
    <div class="settings-row-title">
        Categories
    </div>

    <div class="settings-row-value">
        <img class="settings-enable-image" src="assets/img/chevron_down.svg" *ngIf="show != 'categories'">
        <img class="settings-enable-image" src="assets/img/chevron_up.svg" *ngIf="show == 'categories'">
    </div>
</div>

<div *ngIf="show == 'categories'" class="nested-settings-row">
    <div class="settings-row settings-row-nested" *ngFor="let category of getCategories()">
        <div class="settings-row-title">
            {{ formatTitle(category.name) }}
        </div>
        
        <div class="settings-row-value">
            <slide-toggle [(toggled)]="category.value" (onChange)="setGPAsUpdated('materialVariationsCategories')"></slide-toggle>
        </div>
    </div>
</div>

<div class="settings-row settings-row-toggle" (click)="toggleSettingsRow('layers')">
    <div class="settings-row-title">
        Enable variations for layers
    </div>
    
    <div class="settings-row-value">
        <img class="settings-enable-image" src="assets/img/chevron_down.svg" *ngIf="show != 'layers'">
        <img class="settings-enable-image" src="assets/img/chevron_up.svg" *ngIf="show == 'layers'">
    </div>
</div>

<div *ngIf="show == 'layers'" class="nested-settings-row">
    <div class="settings-row settings-row-nested" *ngFor="let layer of getLayers()">
        <div class="settings-row-title">
            {{ formatTitle(layer.name) }}
        </div>

        <div class="settings-row-value">
            <slide-toggle [(toggled)]="layer.value" (onChange)="setGPAsUpdated('materialVariationsLayers')"></slide-toggle>
        </div>
    </div>
</div>