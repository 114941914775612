<div class="display-chord {{ class }}">
    <div
        class="display-left"
        [ngClass]="{
            'low-opacity': toggled
        }">
        {{ left }}
    </div>

    <div class="display-center">
        <slide-toggle
            [toggled]="toggled"
            [disabled]="disabled"
            (onChange)="onToggle()">
        </slide-toggle>
    </div>

    <div
        class="display-right"
        [ngClass]="{
            'low-opacity': !toggled
        }">
        {{ right }}
    </div>
</div>
