import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from "@angular/core"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { ActivatedRoute, Router } from "@angular/router"
import { ReplaySubject } from "rxjs/internal/ReplaySubject"
import { ApiService } from "@services/api.service"
import MelodyLayer from "@common-lib/classes/generationprofiles/melody/melodylayer"
import { ModalService } from "@services/modal.service"
import { DesignService } from "@services/design.service"
import { LabelType } from "@angular-slider/ngx-slider"
import { SECTION_TYPES } from "@common-lib/constants/constants"
import FormPreview from "@common-lib/classes/generationprofiles/formpreview"
import { SourcePackService } from "../../services/source-packs/sourcepacks.service"
@Component({
    selector: "development-designer",
    templateUrl: "development-designer.component.html",
    styleUrls: ["development-designer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevelopmentDesignerComponent implements AfterViewInit {
    tensionIcons: Array<any> = [
        {
            path: "assets/img/graph-icons/square.svg",
            selected: true,
        },

        {
            path: "assets/img/graph-icons/sine.svg",
            selected: false,
        },

        {
            path: "assets/img/graph-icons/waveform.svg",
            selected: false,
        },
    ]

    selectedTab = "structure"
    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    preview: FormPreview
    previewLoading: boolean = true
    loading: boolean = true

    tooltips = {}

    private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)

    constructor(
        private sourcePacks:SourcePackService,
        private designService: DesignService,
        private modalService: ModalService,
        private apiService: ApiService,
        private route: ActivatedRoute,
        private gpService: GenerationProfileService,
        private ref: ChangeDetectorRef,
        private router: Router
    ) {}

    async ngAfterViewInit() {
        let gpID = this.route.snapshot.paramMap.get("generationProfileID")

        await this.gpService.init(gpID, false)

        this.loading = false

        this.ref.detectChanges()

        this.modalService.modals.materialVariations.subscribe(
            async (value: boolean) => {
                if (!value) {
                    await this.loadPreview()
                }
            }
        )
    }

    ngOnDestroy() {
        this.destroy$.next(true)
        this.destroy$.complete()
    }

    getFormDescription() {
        return this.gpService.generationProfile.formDescription
    }

    getStructureSettings() {
        return this.getFormDescription().structureSettings
    }

    async setGPAsUpdated(type) {
        this.previewLoading = true

        await this.gpService.setAsUpdated(type)

        await this.loadPreview()
    }

    async loadPreview() {
        this.setPreviewLoading(true)

        let result = await this.apiService.authRequest(
            "/generationprofile/formDesigner/preview",
            {
                generationProfileID: this.gpService.generationProfile._id,
            },
            "primary",
            true
        )

        this.preview = FormPreview.fromJSON(result.preview)

        this.setPreviewLoading(false)
    }

    setPreviewLoading(value: boolean) {
        this.previewLoading = value
        this.ref.detectChanges()
    }

    async getEnvelopes(type) {
        let envelopes = []

        const formDescriptionPacks =
            await this.sourcePacks.getFormDescriptionPacks()

        for (let env of formDescriptionPacks[type]) {
            envelopes.push({
                name: env.name,
                value: env.pack_id,
            })
        }

        return envelopes
    }

    async changeSubsequenceLength(event) {
        this.getFormDescription().structureSettings.subsequenceLength =
            event.value

        await this.setGPAsUpdated("subsequenceLength")
    }

    getVariationSettings() {
        return this.getFormDescription().variationSettings
    }

    getSelectedEnvelope(type) {
        return this.getFormDescription()[type]
    }

    async selectEnvelope(event, type) {
        this.getFormDescription()[type] = event.new

        await this.setGPAsUpdated(type)
    }

    getSubsequenceLengthOptions() {
        let options = {
            floor: 0,
            ceil: 2,
            animate: false,
            translate: (value: number, label: LabelType): string => {
                if (label == LabelType.Floor) {
                    return "Short"
                } else if (label == LabelType.Ceil) {
                    return "Long"
                }

                return ""
            },
        }

        return options
    }

    selectSectionType(event) {
        this.getFormDescription().structureSettings.maxThemes = event.new

        this.setGPAsUpdated("sectionType")
    }

    getSectionTypeOptions() {
        return SECTION_TYPES
    }

    getSectionTypes() {
        return this.getFormDescription().structureSettings.maxThemes
    }

    getTensionColor() {
        return this.designService.getCSSProperty("tension-primary-color")
    }

    getDynamicsColor() {
        return this.designService.getCSSProperty("dynamic-primary-color")
    }

    getFilterColor() {
        return this.designService.getCSSProperty(
            "low_frequency_cut-primary-color"
        )
    }

    getLayeringColor() {
        return this.designService.getCSSProperty("layering-primary-color")
    }

    openMaterialVariationSettings() {
        this.modalService.modals.materialVariations.next(true)
    }

    goToGP() {
        this.router.navigate(["/generation-profile-editor", this.getGP()._id])
    }

    getGP() {
        return this.gpService.generationProfile
    }

    async handleMinLayerInput() {
        let gp = this.getGenerationProfile()

        let value: number = parseInt(gp.settings.minLayers + "")

        if (isNaN(value)) {
            value = 1
        }

        let maxMinLayer = this.getMaxMinLayer()

        gp.settings.minLayers = Math.max(Math.min(value, maxMinLayer), 1)

        await this.setGPAsUpdated("minLayer")
    }

    selectLayersOrder() {
        this.modalService.modals.layersOrder.next(
            this.gpService.generationProfile.settings
        )
    }

    getMaxMinLayer() {
        let maxMinLayer = this.getAccompanimentLayers().length

        if (this.getMelody() != null) {
            maxMinLayer += 1
        }

        return maxMinLayer
    }

    getGenerationProfile() {
        return this.gpService.generationProfile
    }

    getMelody(): MelodyLayer {
        return this.gpService.generationProfile.melodyLayer
    }

    getAccompanimentLayers() {
        return this.gpService.generationProfile.accompanimentLayers
    }

    getSettings() {
        return this.gpService.generationProfile.settings
    }

    getHarmony() {
        return this.gpService.generationProfile.harmony
    }

    formatLayersOrder() {
        let str = ""

        for (let option of this.getSettings().layersOrder) {
            str = option.join(", ")

            break // we only want to preview one layer order on the main GP dashboard
        }

        return str
    }
}
