import Score from "../../../general/classes/score/score"
import { Time } from "../../../general/modules/time"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    GridDimensionsDictionary,
    CanvasType,
    KeySignatureEditingContext,
    KeySignatureWithTime,
    AllCanvasCoordinates,
} from "../types"
import ScoreCanvas, { CHORD_FONT_SIZE_PERCENTAGE } from "./score-canvas"
import {
    Coordinates,
    CoordinatesAbs,
    EventHandlers,
} from "../../../general/modules/event-handlers"
import { Observable } from "rxjs"
import { HoveringType, HoveringTypeEnum } from "../../../general/types/general"
import {
    ME_MODES_TO_CREATORS_MODES_MAPPING,
    TIMESTEP_RES,
} from "../../../general/constants/constants"
import { SRActionTypes } from "../states/score-rendering/score-rendering.actions"
import { ScoreManipulation } from "../../../general/modules/scoremanipulation"
import { cloneDeep } from "lodash"

export default class KeySignatureEditingCanvas extends ScoreCanvas {
    private leaveDetection$: Observable<Coordinates>

    constructor(
        public context: KeySignatureEditingContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)

        this.leaveDetection$ = EventHandlers.getMouseEventObservable(
            "mouseleave",
            this.context.canvasContainer,
            0,
            this.destroyObservable,
            this.context.canvasContainer
        )

        this.initListeners()
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        if (
            !scoreState.score ||
            !this.shouldRenderCanvas(scoreState.renderingType)
        ) {
            return
        }

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: grid,
            noteRes: grid["pitched"].timestepRes,
            nbOfYValues: 0,
        })

        this.renderKeySignature()
    }

    private iterateThroughKeySignaturesWithCoordinates(functionBinding: {
        ({
            start,
            end,
            ks,
            x,
            y,
            index,
        }: {
            start: string
            end: string
            ks: string
            x: {
                start: number
                end: number
            }
            y: number
            index: number
        }): boolean
    }) {
        const ctx = this.getContext("canvas")

        const timestepRange = {
            start: this.scrollToTimestep,
            end: this.scrollToTimestep + this.getTimestepRange(),
        }

        const keySignatures = this.queries.scoreRendering.score.keySignatures
        const scoreLength = Time.addTwoFractions(
            this.queries.scoreRendering.score.scoreLength,
            Score.SCORE_LENGTH_PADDING,
            true
        )

        const scrollXOffset = ScoreCanvas.getScrollXOffset({
            scrollToTimestep: this.scrollToTimestep,
            grid: this.grid,
            pxPerTimestep: this.pxPerTimestep,
            noteRes: this.noteRes,
        })

        ScoreManipulation.iterateThroughKeySignatures(
            keySignatures,
            scoreLength,
            (start, end, ks, index) => {
                const tsStart = Time.fractionToTimesteps(this.noteRes, start)
                const tsEnd = Time.fractionToTimesteps(this.noteRes, end)

                if (
                    tsStart < timestepRange.start &&
                    tsEnd < timestepRange.start
                ) {
                    return true
                } else if (tsStart > timestepRange.end) {
                    return false
                }

                const x = {
                    start:
                        ScoreCanvas.getPixelsForTimesteps({
                            timesteps: tsStart,
                            removeLastGutterPx: "none",
                            timestepRes: this.grid.timestepRes,
                            grid: this.grid,
                        }) - scrollXOffset,

                    end:
                        ScoreCanvas.getPixelsForTimesteps({
                            timesteps: tsEnd,
                            removeLastGutterPx: "none",
                            timestepRes: this.grid.timestepRes,
                            grid: this.grid,
                        }) - scrollXOffset,
                }

                const newLocal = CHORD_FONT_SIZE_PERCENTAGE / 100
                const mainTextHeight =
                    ctx.measureText(ks).actualBoundingBoxAscent * newLocal

                const totalTextHeight = mainTextHeight

                const y = this.height / 2 + totalTextHeight / 2

                return functionBinding({
                    start,
                    end,
                    ks,
                    x,
                    y,
                    index,
                })
            }
        )
    }

    private renderKeySignature() {
        const ctx = this.getContext("canvas")

        this.iterateThroughKeySignaturesWithCoordinates(
            ({ start, end, ks, x, y, index }) => {
                const backgroundColor =
                    (
                        this.hoveredElement?.element !== undefined &&
                        (this.hoveredElement.element as KeySignatureWithTime)
                    ).index === index
                        ? "rgba(255,255,255,0.2)"
                        : "rgba(255,255,255,0.1)"

                const chordGap = 1

                ScoreCanvas.roundRect(
                    ctx,
                    x.start,
                    0,
                    x.end - x.start - 4 * chordGap,
                    this.height,
                    4,
                    backgroundColor
                )
                ctx.fill()

                if (ks === undefined) {
                    return true
                }

                const copyX = {
                    start: x.start + 15,
                    end: x.end - 15,
                }

                const pitchClass = ks.split(" ")[0]
                const keyMode =
                    ME_MODES_TO_CREATORS_MODES_MAPPING[ks.split(" ")[1]] !==
                    undefined
                        ? ME_MODES_TO_CREATORS_MODES_MAPPING[ks.split(" ")[1]]
                        : ks.split(" ")[1]

                ScoreCanvas.renderLabelWithSuperscript({
                    ctx,
                    text: pitchClass + " " + keyMode,
                    x: copyX,
                    y,
                })

                return true
            }
        )
    }

    private initListeners() {
        this.mouseDown$.subscribe(this.mouseDownHandler.bind(this))

        this.hoveredDetection$.subscribe((coordinates: Coordinates) => {
            if (this.queries?.scoreRendering?.score === undefined) {
                return
            }

            if (this.mouseDownStart !== undefined) {
                return
            }

            const result = this.getKeySignatureByCoordinates({
                coordinates,
                setHoveredElement: true,
            })
        })

        this.resize$.subscribe(event => {
            this.mouseMoveHandler(event, "resize")
        })

        this.leaveDetection$.subscribe((event: Coordinates) => {
            if (this.mouseDownStart !== undefined) {
                return
            }

            this.setHoveredElement(undefined)
            this.triggerReRender()
        })
    }

    protected mouseDownHandler(event: CoordinatesAbs) {
        const result = this.getKeySignatureByCoordinates({
            coordinates: event,
            setHoveredElement: true,
        })

        if (result?.element === undefined) {
            return
        }

        const start: AllCanvasCoordinates = {
            scrollToTimestep: this.scrollToTimestep,
            pixels: event,
            grid: {
                timesteps: Time.fractionToTimesteps(
                    this.noteRes,
                    result.element.start
                ),
                ysteps: 0,
            },
        }

        this._mouseDownStart = {
            start: start,
            last: cloneDeep(start),
            mouseDownLocation: result ? result.type : "center",
        }

        if (this._mouseDownStart.mouseDownLocation === "center") {
            this.actions.scoreRendering.manager.emitter$.next({
                type: SRActionTypes.startResizeKeySignature,
                data: {},

                options: {
                    isUndoable: true,
                },
            })
        }
    }

    protected mouseUpHandler(event: Coordinates) {
        if (
            this._mouseDownStart !== undefined &&
            this.hoveredElement !== undefined &&
            this._mouseDownStart.mouseDownLocation === "center"
        ) {
            if (event.shiftKey) {
                this.actions.scoreRendering.manager.emitter$.next({
                    type: SRActionTypes.splitKeySignature,
                    data: {
                        index: (
                            this.hoveredElement.element as KeySignatureWithTime
                        ).index,
                    },
                    options: {
                        isUndoable: true,
                    },
                })
            } else {
                this.context.editKeySignature(
                    (this.hoveredElement.element as KeySignatureWithTime).index
                )
            }
        }

        super.mouseUpHandler(event)
    }

    private getKeySignatureByCoordinates({
        coordinates,
        setHoveredElement = true,
    }: {
        coordinates: Coordinates | CoordinatesAbs
        setHoveredElement: boolean
    }) {
        if (setHoveredElement) {
            this.setCanvasCursor("default")
            this.triggerReRender()
        }

        let draggingType
        let result = undefined

        this.iterateThroughKeySignaturesWithCoordinates(
            ({ start, end, ks, x, y, index }) => {
                if (coordinates.x >= x.start && coordinates.x <= x.end) {
                    let hoverType = HoveringTypeEnum.CENTER

                    const leftEdgeEnd = x.start + 8
                    const rightEdgeEnd = x.end - 8

                    if (coordinates.x <= leftEdgeEnd) {
                        hoverType = HoveringTypeEnum.LEFT
                    } else if (coordinates.x >= rightEdgeEnd) {
                        hoverType = HoveringTypeEnum.RIGHT
                    }

                    draggingType = hoverType

                    const element: KeySignatureWithTime = {
                        ks,
                        start: start,
                        end: end,
                        index,
                    }

                    result = {
                        element,
                        type: draggingType,
                    }

                    return false
                }

                if (coordinates.x < x.start) {
                    return false
                }

                return true
            }
        )

        if (!result?.element) {
            return undefined
        }

        if (setHoveredElement && result?.element) {
            const hoveredElement = {
                element: result.element,
                draggingType: draggingType,
                type: "keySignature" as const,
            }

            this.setHoveredElement(hoveredElement)

            if (draggingType && draggingType !== HoveringTypeEnum.CENTER) {
                this.setCanvasCursor("col-resize")

                this.renderResizeIndicator(result.element.index, draggingType)
            }
        }

        return result
    }

    private triggerReRender() {
        this.actions.scoreRendering.manager.emitter$.next({
            type: SRActionTypes.setRenderingType,
            data: {
                type: ["KeySignatureEditingCanvas"],
            },
        })
    }

    private getKSDimensions({ i }: { i: number }):
        | {
              start: number
              width: number
              end: number
              index: number
              ks: string
          }
        | undefined {
        let result

        this.iterateThroughKeySignaturesWithCoordinates(
            ({ start, end, ks, x, y, index }) => {
                if (index !== i) {
                    return true
                }

                result = {
                    start: x.start,
                    end: x.end,
                    width: x.end - x.start,
                    index,
                    ks: ks,
                }

                return false
            }
        )

        return result
    }

    private renderResizeIndicator(index: number, edge: any) {
        if (
            !this.hoveredElement ||
            this.hoveredElement?.draggingType === "center"
        ) {
            return
        }

        const dimensions = this.getKSDimensions({
            i: index,
        })
        const ctx = this.getContext("canvas")
        const text = "|  |"
        const textHeight = ctx.measureText(text).actualBoundingBoxAscent

        const y = this.height / 2 + textHeight / 2
        const x = edge === "left" ? dimensions.start - 2 : dimensions.end - 2

        ScoreCanvas.drawText(
            ctx,
            x,
            y,
            text,
            80,
            "normal",
            "rgba(255,255,255,0.8)",
            "center"
        )
    }

    protected mouseMoveHandler(event: Coordinates, type: "resize") {
        if (!this.mouseDownStart?.start) return

        this.mouseDownStart.last.pixels.x = event.x

        const ts = this.queries.scoreRendering.score.firstTimeSignature

        const barTS = Time.fractionToTimesteps(
            TIMESTEP_RES,
            ts[0] + "/" + ts[1]
        )

        let diffTimesteps =
            this.getTimestepsForPixels({
                pixels: this.mouseDownStart.last.pixels.x,
            }) -
            this.getTimestepsForPixels({
                pixels: this.mouseDownStart.start.pixels.x,
            })

        diffTimesteps =
            Math.trunc(
                Time.convertTimestepsToAnotherRes(
                    diffTimesteps,
                    this.noteRes,
                    TIMESTEP_RES
                ) / barTS
            ) * barTS

        if (diffTimesteps === 0) {
            return
        }

        const offset = Time.timestepToFraction(diffTimesteps, TIMESTEP_RES)

        this.mouseDownStart.start.pixels.x = event.x

        this.srEmitter$.next({
            type: SRActionTypes.resizeKeySignature,
            data: {
                index: (this.hoveredElement?.element as KeySignatureWithTime)
                    .index,
                offset: offset,
                side: this.mouseDownStart.mouseDownLocation as HoveringType,
            },
            options: {
                isUndoable: false,
            },
        })
    }
}
