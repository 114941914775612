import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core"
import { AutoMixingModal } from "@services/modal.service"

@Component({
    selector: "auto-mixing",
    templateUrl: "auto-mixing.component.html",
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoMixingModalComponent {
    @Input() autoMixing: AutoMixingModal

    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor() {}

    toggleAutoMixing(event) {
        this.autoMixing.autoMix = event
        this.autoMixing.onToggleAutoMixing(event)
    }

    closeModal() {
        this.close.emit()
    }
}
