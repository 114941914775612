import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ElementRef,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Host,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { ModalService } from "@services/modal.service"
import { moveItemInArray } from "@angular/cdk/drag-drop"
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar"
import { GenerationProfileComponent } from "../generation-profile.component"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import Pack from "@common-lib/classes/generationprofiles/pack"

@Component({
    selector: "accompaniment-layer",
    templateUrl: "accompaniment-layer.component.html",
    styleUrls: ["../generation-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccompanimentLayerComponent {
    scrollConfigLayer = { useBothWheelAxes: false, suppressScrollX: true }
    @Input() layer
    @Input() gpValidation
    @Input() tileHovered
    @Input() layerOptions

    @Output() triggerLayerOptions: EventEmitter<any> = new EventEmitter()
    @ViewChild(PerfectScrollbarDirective, { static: false })
    perfectscroll: PerfectScrollbarDirective

    @ViewChild("file") file: ElementRef

    asAccompanimentPack(pack: Pack): AccompanimentPack {
        return pack as AccompanimentPack
    }
    @Output() onEditPack = new EventEmitter<AccompanimentPack>()

    constructor(
        @Host() private parent: GenerationProfileComponent,
        private modalService: ModalService,
        private gpService: GenerationProfileService
    ) {
        this.gpService.scrollTopForLayer.subscribe(value => {
            if (this.layer != null && value == this.layer.name) {
                this.perfectscroll.scrollToTop()
            }
        })
    }

    selectGPInfluence(gpComponent) {
        let component =
            this.gpService.generationProfile.getComponent(gpComponent)
        this.modalService.modals.selectGPInfluence.next(component)
    }

    openLayerOptions(event, layer) {
        this.triggerLayerOptions.emit({ event: event, layer: layer })
    }

    drop(layer, packs, event) {
        moveItemInArray(packs, event.previousIndex, event.currentIndex)

        this.gpService.setAsUpdated("accompanimentPackOrder")
    }

    trackByPack(index) {
        return index
    }

    addPack(layer) {
        this.modalService.modals.selectPack.next({
            type: "add",
            layer: layer,
        })
    }
}
