<div class="modal-title">Edit Key Signature</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div class="select-ks">
    <div class="settings-row">
        <div class="settings-row-title">Pitch class</div>
        <div class="settings-row-value">
            <dropdown
                class="create-with-gp-dropdown"
                [value]="pitchClass"
                [items]="getPitchClassOptions()"
                [showTooltip]="false"
                (selectedItem)="selectPitchClass($event)"></dropdown>
        </div>
    </div>

    <div class="settings-row">
        <div class="settings-row-title">Key Mode</div>
        <div class="settings-row-value">
            <dropdown
                class="create-with-gp-dropdown"
                [value]="keyMode"
                [items]="getKeyModeOptions()"
                [showTooltip]="false"
                (selectedItem)="selectKeyMode($event)"></dropdown>
        </div>
    </div>
</div>

<div class="button-container">
    <a-button
        width="50%"
        type="text"
        label="Cancel"
        (onClick)="close.emit()"></a-button>

    <a-button
        width="50%"
        type="secondary"
        label="Confirm"
        (click)="confirm()"></a-button>
</div>
