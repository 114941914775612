import { VariationSettingsSchema } from "../../../interfaces/db-schemas/generationprofile"
import { FormDescriptionPack, FormDescriptionPacks } from "../../../interfaces/music-engine/generationprofile"
import MaterialVariations from "./materialvariations"

export class VariationSettings {
    materialVariations:MaterialVariations = new MaterialVariations()
    harmonicVariation:boolean = false
    keyShift:boolean = false
    orchestrationVariation:boolean = false

    decode(encoded:VariationSettingsSchema, packs:FormDescriptionPacks) {
        this.harmonicVariation = encoded.harmonicVariation != "none"
        this.keyShift = encoded.keyShift != "none"
        this.orchestrationVariation = encoded.orchestrationVariation != "none"

        this.materialVariations.decode(encoded.materialVariations, packs)
    }

    static fromJSON(object) {
        let variationSettings:VariationSettings = Object.assign(new VariationSettings(), object)

        variationSettings.materialVariations = MaterialVariations.fromJSON(object['materialVariations'])

        return variationSettings
    }
}