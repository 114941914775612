<div class="modal-title">Confirm emotion selection</div>
<div class="modal-subtitle">The following parameters will be changed with new values:</div>

<div class="modal-close"><img src="assets/img/close.svg" (click)="closeModal()"></div>

<div class="settings">
    <div class="settings-row" *ngFor="let change of changes">
        <div class="settings-row-title">
            {{ change.name }}
        </div>
        <div class="settings-row-value">
            {{ change.newValueDisplay }}
        </div>
    </div>
</div>

<a-button type="secondary" label="Apply emotion selection" width="100%" (onClick)="applyEmotionSelection()"></a-button>