import { Store, StoreConfig } from "@datorama/akita"
import { presetUseCases } from "@common-lib/constants/presetUseCases"

const reverbs = require("../../../../../common-lib/assets/ir/ir.json")

export interface GeneralState {
    presetUseCases
    reverbs: {
        name: string
    }[]
}

const initialState: GeneralState = {
    presetUseCases,
    reverbs,
}

@StoreConfig({ name: "GeneralStore" })
export class GeneralStore extends Store<GeneralState> {
    constructor() {
        super(initialState)
    }
}
