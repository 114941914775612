import Section from "../../../general/classes/score/section"
import { Observable } from "rxjs"
import Score from "../../../general/classes/score/score"
import {
    PIANOROLL_GRID_SCROLLBAR_HEIGHT,
    PIANOROLL_GRID_SCROLLBAR_RADIUS,
    PIANOROLL_GRID_SECTION_HEIGHT,
    PIANOROLL_GRID_SECTION_TOP_OFFSET,
    PIANOROLL_GRID_SEPARATOR_TOP,
    TIMESTEP_RES,
    SHOW_BAR_GUTTERS_THRESHOLD,
    SHOW_BEAT_GUTTERS_THRESHOLD,
    SHOW_TIMESTEP_GUTTERS_THRESHOLD,
    NOTE_QUANTIZATION_THRESHOLDS,
    SECTION_EDITING,
} from "../../../general/constants/constants"
import { Time } from "../../../general/modules/time"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    PianorollGridCanvasContext,
    GridDimensions,
    RemoveLastGutter,
    GridDimensionsDictionary,
    GridCoordinates,
    CanvasType,
} from "../types"
import ScoreCanvas from "./score-canvas"
import { cloneDeep } from "lodash"
import { SRActionTypes } from "../states/score-rendering/score-rendering.actions"
import {
    Coordinates,
    EventHandlers,
} from "../../../general/modules/event-handlers"
import { ScoreManipulation } from "../../../general/modules/scoremanipulation"
import { sep } from "path"
import { featureFlags } from "../../../general/utils/feature-flags"

interface Separator {
    margin: number
    barNumber?: number
    type: string
}
export default class PianorollGridCanvas extends ScoreCanvas {
    private hoveredSection: Readonly<Section | undefined>
    private leaveDetection$: Observable<Coordinates>

    private startPosition: "separators" | "scrollbar" | "section" | undefined

    private readonly borderRadius = 4
    private readonly fontSizePercentage = 75
    private readonly titleTopOffset = 5

    constructor(
        public context: PianorollGridCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)

        this.leaveDetection$ = EventHandlers.getMouseEventObservable(
            "mouseleave",
            this.context.canvasContainer,
            0,
            this.destroyObservable,
            this.context.canvasContainer
        )

        this.initListeners()
    }

    private initListeners() {
        this.leaveDetection$.subscribe((event: Coordinates) => {
            this.setHoveredSection(undefined)
        })

        this.hoveredDetection$.subscribe((event: Coordinates) => {
            const score: Score | undefined = this.queries.scoreRendering.score

            if (score === undefined || this.context.hideSections === true) {
                return
            }

            const hoveredSection = this.getSectionAtMouseLocation(
                event,
                score.sections
            )

            this.setHoveredSection(hoveredSection)
        })

        this.mouseMove$.subscribe(event => {
            if (this.mouseDownStart === undefined) {
                return
            }

            if (this.isClickingOnSeparators(this.mouseDownStart.start.pixels)) {
                this.handleSeparatorClick(event)
            } else if (!this.context.hideScrollbar) {
                this.scrollWithMouseMovement(event)
            }
        })

        this.mouseDown$.subscribe(event => {
            if (
                event.y < PIANOROLL_GRID_SEPARATOR_TOP.separatorClickableHeight
            ) {
                this.mouseDownHandler(event)

                if (!this.context.hideScrollbar) {
                    this.scrollWithMouseMovement(event)
                }

                this.startPosition = "scrollbar"
            } else if (this.isClickingOnSeparators(event)) {
                this.mouseDownHandler(event)

                this.handleSeparatorClick(event)

                this.startPosition = "separators"
            } else {
                this.startPosition = "section"
            }
        })

        this.click$.subscribe(event => {
            if (
                event.y >= PIANOROLL_GRID_SEPARATOR_TOP.margin &&
                !this.isClickingOnSeparators(event) &&
                !this.context.hideSections &&
                this.startPosition === "section"
            ) {
                const sections = this.addPlusSection(
                    this.queries.scoreRendering.score.sections
                )
                this.handleSectionClick(event, sections)
            }
        })
    }

    private isClickingOnSeparators(event: Coordinates) {
        return (
            event.y >= PIANOROLL_GRID_SEPARATOR_TOP.separatorClickableHeight &&
            event.y < PIANOROLL_GRID_SECTION_TOP_OFFSET
        )
    }

    protected getGridCoordinates(
        event: Coordinates,
        invertYAxis: boolean
    ): GridCoordinates {
        const result = this.getStepsForPixels({
            coordinates: cloneDeep(event),
        })

        if (result.timesteps === undefined) {
            throw "PianorollGridCanvas.getGridCoordinates - timesteps is undefined"
        }

        return {
            timesteps: result.timesteps,
            ysteps: 0,
        }
    }

    private handleSeparatorClick(event: Coordinates) {
        let timesteps = Time.convertTimestepsToAnotherRes(
            this.getTimestepsForPixels({
                pixels: event.x + this.scrollXOffset,
            }),

            this.noteRes,

            TIMESTEP_RES
        )

        timesteps = ScoreManipulation.quantizeTimesteps({
            resizeFactor: this.resizeFactor,
            timesteps,
            timestepRes: TIMESTEP_RES,
            timeSignature: this.queries.scoreRendering.score.firstTimeSignature,
            thresholds: NOTE_QUANTIZATION_THRESHOLDS,
        })

        this.srEmitter$.next({
            type: SRActionTypes.seek,
            data: {
                timesteps,
            },
        })
    }

    private handleSectionClick(event: Coordinates, sections: Section[]) {
        if (this.context.hideSections === true) {
            return
        }

        const hoveredSection = this.getSectionAtMouseLocation(event, sections)

        this.srEmitter$.next({
            type: SRActionTypes.selectSection,
            data: {
                section: hoveredSection,
                coordinates: {
                    x:
                        event.x +
                        this.context.canvasContainer.getBoundingClientRect()
                            .left,
                    y:
                        event.y +
                        this.context.canvasContainer.getBoundingClientRect()
                            .top,
                    shiftKey: event.shiftKey,
                },
            },
        })
    }

    private setHoveredSection(section: Readonly<Section> | undefined) {
        if (this.context.hideSections === true) {
            return
        }

        if (this.hoveredSection !== section) {
            this.hoveredSection = section

            this.render(
                this.queries.scoreRendering.all,
                this.queries.editorView.getValue(),
                this.getGridDictionary()
            )
        }
    }

    private getSectionAtMouseLocation(
        event: Coordinates,
        sections: Section[]
    ): Section | undefined {
        if (
            event.y < PIANOROLL_GRID_SECTION_TOP_OFFSET ||
            event.y >
                PIANOROLL_GRID_SECTION_TOP_OFFSET +
                    PIANOROLL_GRID_SECTION_HEIGHT
        ) {
            return undefined
        }

        const timesteps = this.getTimestepsForPixels({
            pixels: event.x + this.scrollXOffset,
        })

        for (const section of sections) {
            const start = Time.fractionToTimesteps(this.noteRes, section.start)

            const end = Time.fractionToTimesteps(this.noteRes, section.end)

            if (timesteps >= start && timesteps < end) {
                return section
            }
        }

        return undefined
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        if (
            !scoreState.score ||
            !this.shouldRenderCanvas(scoreState.renderingType)
        ) {
            return
        }

        const score: Score = scoreState.score

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: grid,
            noteRes: grid[this.gridType].timestepRes,
            nbOfYValues: 0,
        })

        if (!this.context.hideScrollbar) {
            this.computeScrollbar()
        }

        this.computeSeparators()

        if (!this.context.hideSections) {
            const sections = this.addPlusSection(score.sections)
            this.computeSections(sections)
        }
    }

    protected getTimestepsForPixels(args: {
        pixels: number
        removeGutter?: RemoveLastGutter
    }): number {
        return Math.round(args.pixels / this.pxPerTimestep)
    }

    private computeScrollbar() {
        const ctx = this.getContext("canvas")

        const pxPerTimestep = this.width / this.grid.scoreLengthTimesteps
        const scrollXOffset = this.scrollToTimestep * pxPerTimestep
        const scrollBarWidth = this.getTimestepRange() * pxPerTimestep

        ScoreCanvas.drawHorizontalLine(
            ctx,
            0,
            this.width,
            PIANOROLL_GRID_SCROLLBAR_HEIGHT / 2,
            "rgba(255,255,255,0.1)"
        )

        if (this.getTimestepRange() === this.grid.scoreLengthTimesteps) {
            return
        }

        ScoreCanvas.roundRect(
            ctx,
            scrollXOffset,
            0,
            scrollBarWidth,
            PIANOROLL_GRID_SCROLLBAR_HEIGHT,
            PIANOROLL_GRID_SCROLLBAR_RADIUS,
            "rgba(255,255,255,0.9)"
        )

        ctx.fill("evenodd")
    }

    private computeSections(sections: Section[]) {
        const ctx = this.getContext("canvas")

        const timestepRange = {
            start: this.scrollToTimestep,
            end: this.scrollToTimestep + this.getTimestepRange(),
        }

        for (const section of sections) {
            const start = Time.fractionToTimesteps(this.noteRes, section.start)

            const end =
                Time.fractionToTimesteps(this.noteRes, section.duration) + start

            if (start < timestepRange.start && end < timestepRange.start) {
                continue
            } else if (start > timestepRange.end) {
                break
            }

            const coordinates = ScoreCanvas.sectionToCoordinates(
                section,
                this.grid,
                this.scrollToTimestep,
                this.pxPerTimestep,
                this.noteRes
            )

            ScoreCanvas.roundRect(
                ctx,
                coordinates.x,
                coordinates.y,
                coordinates.width,
                coordinates.height,
                this.borderRadius
            )

            let opacity = 0.2

            if (
                this.hoveredSection &&
                Time.compareTwoFractions(
                    this.hoveredSection.start,
                    section.start
                ) === "eq"
            ) {
                opacity += 0.15
            }

            if (
                section.operation === undefined ||
                section.operation.type === SECTION_EDITING.INSERT_BLANK
            ) {
                ctx.fillStyle = "rgba(255,255,255," + opacity + ")"
            } else if (section.operation.type === SECTION_EDITING.DELETE) {
                ctx.fillStyle = "red"
            } else if (
                section.operation.type === SECTION_EDITING.REGENERATE ||
                section.operation.type ===
                    SECTION_EDITING.REGENERATE_WITH_SOURCE
            ) {
                ctx.fillStyle = "#ff0097"
            } else if (section.operation.type === SECTION_EDITING.REPLACE) {
                ctx.fillStyle = "orange"
            } else if (
                section.operation.type === SECTION_EDITING.INSERT_NEW ||
                section.operation.type === SECTION_EDITING.INSERT_VARIATION ||
                section.operation.type === SECTION_EDITING.INSERT_COPY
            ) {
                ctx.fillStyle = "rgba(0,255,34,.72)"
            }

            ctx.fill("evenodd")

            ScoreCanvas.drawText(
                ctx,
                coordinates.x + coordinates.width / 2,
                PIANOROLL_GRID_SECTION_TOP_OFFSET +
                    PIANOROLL_GRID_SECTION_HEIGHT / 2 +
                    this.titleTopOffset,
                ScoreCanvas.fittingString(
                    ctx,
                    ScoreManipulation.getSectionTitleToDisplay(
                        section,
                        this.queries.scoreRendering.score.sections
                    ),
                    coordinates.width
                ),
                this.fontSizePercentage,
                "bold",
                "white"
            )
        }
    }

    private computeSeparators() {
        const ctx = this.getContext("canvas")

        const measureRes = Time.fractionToTimesteps(
            this.noteRes,
            this.grid.timeSignature[0] + "/" + this.grid.timeSignature[1]
        )
        const beatRes = Time.fractionToTimesteps(
            this.noteRes,
            "1/" + this.grid.timeSignature[1]
        )
        const quarterBeatRes = Time.fractionToTimesteps(
            this.noteRes,
            "1/" + this.grid.timeSignature[1] * 4
        )

        const scrollToTimestep = Math.round(this.scrollToTimestep)
        const scrollToTimestepEnd = Math.round(
            this.scrollToTimestep + this.getTimestepRange()
        )

        for (let t = scrollToTimestep; t < scrollToTimestepEnd; t++) {
            this.computeSingleSeparator(
                ctx,
                t,
                measureRes,
                beatRes,
                quarterBeatRes,
                this.scrollToTimestep
            )
        }
    }

    private computeSingleSeparator(
        ctx: CanvasRenderingContext2D,
        i: number,
        measureRes: number,
        beatRes: number,
        quarterBeatRes: number,
        start: number
    ) {
        const score: Score | undefined = this.queries.scoreRendering.score

        if (score === undefined) {
            return
        }

        const marginIncrement = this.pxPerTimestep * i

        const isBar = i % measureRes == 0
        const isBeat = i % beatRes == 0
        const isQuarterBeat = i % quarterBeatRes == 0

        const separator: Separator = {
            margin: marginIncrement,
            barNumber: Math.floor(i / measureRes),
            type: "",
        }

        const x = Math.floor(this.pxPerTimestep * (i - start))

        if (isBar) {
            separator.type = "bar"

            const sections = score.sections
            let selectedSection: Section | undefined

            for (const section of sections) {
                const bar = Time.timestepToFraction(
                    Time.barToTimestep(
                        separator.barNumber,
                        score.timeSignatures[0][1],
                        measureRes
                    ),
                    measureRes
                )

                if (Time.compareTwoFractions(section.start, bar) === "eq") {
                    selectedSection = section

                    break
                }
            }

            const drawNonSectionBars =
                !selectedSection &&
                separator.barNumber != null &&
                (this.resizeFactor >= SHOW_BAR_GUTTERS_THRESHOLD ||
                    sections.length === 1)
            const drawSectionBars = selectedSection !== undefined

            let showSeparatorTextOutsideSections =
                !selectedSection && (separator.barNumber + 1) % 10 === 0

            if (
                separator.barNumber &&
                (drawSectionBars ||
                    drawNonSectionBars ||
                    showSeparatorTextOutsideSections)
            ) {
                ScoreCanvas.drawText(
                    ctx,
                    x,
                    PIANOROLL_GRID_SEPARATOR_TOP.margin +
                        PIANOROLL_GRID_SEPARATOR_TOP.text.topOffset,
                    separator.barNumber + 1 + "",
                    PIANOROLL_GRID_SEPARATOR_TOP.text.fontSize,
                    "bold",
                    "white"
                )
            }
        } else if (
            (this.resizeFactor >= SHOW_BEAT_GUTTERS_THRESHOLD ||
                score.sections.length === 1) &&
            isBeat
        ) {
            separator.type = "beat"

            ScoreCanvas.drawVerticalLine(
                ctx,
                x,
                PIANOROLL_GRID_SEPARATOR_TOP.margin +
                    PIANOROLL_GRID_SEPARATOR_TOP.beat.start,
                PIANOROLL_GRID_SEPARATOR_TOP.margin +
                    PIANOROLL_GRID_SEPARATOR_TOP.beat.end,
                "rgba(255,255,255,0.3)"
            )
        } else if (
            this.resizeFactor >= SHOW_TIMESTEP_GUTTERS_THRESHOLD &&
            isQuarterBeat
        ) {
            separator.type = "half-beat"

            ScoreCanvas.drawVerticalLine(
                ctx,
                x,
                PIANOROLL_GRID_SEPARATOR_TOP.margin +
                    PIANOROLL_GRID_SEPARATOR_TOP.halfBeat.start,
                PIANOROLL_GRID_SEPARATOR_TOP.margin +
                    PIANOROLL_GRID_SEPARATOR_TOP.halfBeat.end,
                "rgba(255,255,255,0.20)"
            )
        }
    }

    private addPlusSection(sections: Section[]): Section[] {
        if (featureFlags.enableSectionModificationInEditor) {
            sections = [...sections]
            const lastSection = sections[sections.length - 1]
            const addSection = new Section(
                lastSection.end,
                "+",
                sections.length
            )
            addSection.setEndFromDuration("2")

            sections.push(addSection)
        }
        return sections
    }
}
