<div *ngIf="constructionMode" class="construction-mode">
    <div class="construction-mode-title">We'll be back soon!</div>
    <div class="construction-mode-subtitle">
        Our servers are currently undergoing some planned maintenance, which
        should take only a few minutes to complete. Thank you for your patience!
    </div>

    <div class="construction-mode-community">
        In the meantime, checkout our
        <a href="https://discord.com/invite/ypDnFXN"
            >Discord community server</a
        >
    </div>
</div>

<div
    class="mac-electron-dragger"
    [ngClass]="{ 'mac-electron-dragger-short': editorIsOpen }"
    *ngIf="isMacElectronApp"></div>

<div *ngIf="!constructionMode" [className]="deviceSpecificClass">
    <div class="drag-and-drop-overlay" *ngIf="showDragAndDropUI"></div>

    <div *ngIf="isInitialized">
        <div id="error-message-band" *ngIf="errorMessage != ''">
            {{ errorMessage }}
            <img
                (click)="cancelError()"
                class="close-icon"
                src="../assets/img/close.svg" />
        </div>

        <div id="blink-container"></div>

        <app-navbar></app-navbar>

        <notifications></notifications>

        <context-menus *ngIf="isLoggedIn"></context-menus>

        <app-modals *ngIf="isLoggedIn"></app-modals>

        <div
            id="app-body"
            (dragover)="dragOverHandler($event)"
            (drop)="preventDropDefault($event)">
            <router-outlet></router-outlet>
        </div>

        <div class="update-banner" *ngIf="isUpdating">
            <img src="assets/img/menu/changelog.svg" /> AIVA is being updated,
            some services might be interrupted for the next few minutes.
        </div>

        <app-player *ngIf="isLoggedIn"></app-player>
    </div>

    <div class="aiva-loading-screen" *ngIf="!isInitialized">
        <div class="aiva-loading-screen-title">
            Loading your dashboard, please wait...
        </div>
        <div class="loader"></div>
    </div>
</div>
