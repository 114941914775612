import { Injectable } from "@angular/core"
import { KeyboardEventActions } from "../../../../common-lib/client-only/score-rendering-engine"
import ScoreRenderingEngine from "../../../../common-lib/client-only/score-rendering-engine/engine"
import { ParentClass } from "../parent"
import { CompositionWorkflowService } from "./composition-workflow/composition-workflow.service"
import { ModalService } from "./modal.service"
import { SupportChatService } from "./supportchat.service"
import { IKeyboardEvents } from "@common-lib/interfaces/score/general"

@Injectable()
export class ShortcutsService extends ParentClass {
    constructor(
        private modalService: ModalService,
        private supportChat: SupportChatService
    ) {
        super()
    }

    public triggerShortcuts(
        event: KeyboardEvent,
        engine: IKeyboardEvents
    ) {
        if (this.shortcutPrevention()) {
            return
        }

        return ParentClass.handleScoreEditingKeyboardEvents({
            engine,
            event: event,
            allowedActions: [KeyboardEventActions.ALL],
        })
    }

    public shortcutPrevention(): boolean {
        const tagName = document.activeElement.tagName

        if (
            tagName == "INPUT" ||
            tagName == "TEXTAREA" ||
            this.supportChat.chatIsOpen() ||
            this.preventShortcutForOldModals()
        ) {
            return true
        }

        // If a modal is open, we dont trigger any shorcuts
        for (const modal in this.modalService.modals) {
            // The != is very important here because the subjects can be null or undefined
            if (this.modalService.modals[modal].getValue()) {
                return true
            }
        }

        return false
    }

    private preventShortcutForOldModals() {
        return this.modalService.renamedFolder.getValue()
    }
}
