import { Injectable } from "@angular/core"
import {
    BAR_COUNTS,
    LayerType,
    TIMESTEP_RES,
} from "@common-lib/constants/constants"
import { ModalService } from "@services/modal.service"
import Score from "@common-lib/classes/score/score"
import {
    EditorLoadingStatus,
    FractionString,
    TimeSignature,
} from "@common-lib/types/score"
import { BarCount } from "@common-lib/types/score"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import { Time } from "@common-lib/modules/time"
import {
    BehaviorSubject,
    combineLatest,
    filter,
    firstValueFrom,
    map,
    Observable,
    pairwise,
    takeWhile,
} from "rxjs"
import { cloneDeep } from "lodash"
import { AccompanimentDesignerHttpService } from "./accompaniment-designer.http"
import ScoreRenderingEngine from "../../../../../common-lib/client-only/score-rendering-engine/engine"
import { environment } from "@environments/environment"
import { ParentClass } from "../../../app/parent"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { PlayerService } from "@services/audio/player/player.service"
import { TokenService } from "@services/token.service"
import { UserService } from "@services/user.service"
import { AccompanimentDesignerStore } from "./state/accompaniment-designer.store"
import { AccompanimentDesignerQuery } from "./state/accompaniment-designer.query"
import { isEqual } from "lodash"
import Layer from "@common-lib/classes/score/layer"
import { NotesObject } from "@common-lib/classes/score/notesObject"
import { AccompanimentPackLoadingStatus } from "@common-lib/interfaces/db-schemas/accompanimentPackData"
import { ActivatedRoute, PRIMARY_OUTLET, Route, Router } from "@angular/router"
import { Location } from "@angular/common"
import { InitCanvases } from "../../modules/init-canvases.module"
import {
    playerActions,
    playerQuery,
} from "../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { Misc } from "@common-lib/modules/misc"
import { WindowService } from "@services/window.service"

export interface AccompanimentDesignerLoading {
    progress: number
    status: EditorLoadingStatus
    packID: string | undefined
}

@Injectable()
export class AccompanimentDesignerService extends ParentClass {
    savePackLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )

    private readonly store: AccompanimentDesignerStore =
        new AccompanimentDesignerStore()

    private readonly query: AccompanimentDesignerQuery =
        new AccompanimentDesignerQuery(this.store)

    public loading$ = new BehaviorSubject({
        progress: 0,
        status: <EditorLoadingStatus>"none",
        packID: undefined,
    })

    public isPolyphonic$ = new BehaviorSubject(undefined)

    public engine$ = this.query.select("engine")
    public pack$ = this.query.select("pack")
    public allStates$ = this.query.allStates$
    public msMode$ = this.query.msMode$
    public _packWasEdited: boolean = false // currently keeps track of the pack name changes

    public get msMode() {
        return this.query.getValue().msMode
    }

    public get pack(): AccompanimentPack | undefined {
        return this.query.pack
    }

    public get patternLength(): BarCount {
        return this.query.patternLength
    }

    public get allowZoom() {
        return this.query.getValue().engine?.allowZoom
    }

    public get packLoading() {
        return this.query.getValue().packLoading
    }

    public get packWasEdited() {
        return (
            this.query.getValue().engine?.scoreWasEdited || this._packWasEdited
        )
    }

    public set packWasEdited(packWasEdited: boolean) {
        this._packWasEdited = packWasEdited
    }

    get layer() {
        return this.route.snapshot.paramMap.get("layer")
    }

    onPackRenamed: (newPackName) => void | null

    isPlaying: boolean = false

    constructor(
        private modalService: ModalService,
        private adHttp: AccompanimentDesignerHttpService,
        private instruments: InstrumentsService,
        private player: PlayerService,
        private token: TokenService,
        private user: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private gpService: GenerationProfileService,
        private windowService: WindowService
    ) {
        super()

        this.subscribe(this.loading$, async value => {
            if (value.status === "scoreLoading") {
                return this.loadScore(this.query.pack, false)
            }
        })

        this.modalService.modals.renamePack.subscribe(
            (newPackName: string | null) => {
                if (
                    this.query.pack &&
                    newPackName &&
                    newPackName !== this.query.pack.name
                ) {
                    this.renamePack(newPackName)
                }
            }
        )

        this.subscribe(this.layers$, layers => {
            if (layers?.length) {
                const layerName = layers[0].value
                this.toggleLayer(layerName)
            }
        })

        this.subscribe(playerQuery.select("content"), value => {
            if (value === "playing") {
                this.isPlaying = true
            } else {
                this.isPlaying = false
            }
        })

        const loadingObservable: Observable<AccompanimentDesignerLoading> =
            combineLatest(
                [this.query.packLoading$, this.query.scoreLoading$],
                (packLoading, scoreLoading) => {
                    let status: EditorLoadingStatus = "none"

                    if (packLoading.failed) {
                        status = "failed"
                    } else if (
                        packLoading.progress >= 0 &&
                        packLoading.progress < 100 &&
                        !packLoading.finished
                    ) {
                        status = "packLoading"
                    } else if (packLoading.finished) {
                        status = scoreLoading.finished
                            ? "loaded"
                            : "scoreLoading"
                    }

                    return {
                        progress: packLoading.progress,
                        status: status,
                        packID: packLoading.packID,
                    }
                }
            ).pipe(
                pairwise(),
                filter(([previous, current]) => {
                    return (
                        previous.status === "none" ||
                        !isEqual(previous, current)
                    )
                }),
                map(([previous, current]) => {
                    return current
                })
            )

        loadingObservable.subscribe(this.loading$)

        this.subscribeToSocket()
    }

    public get engine(): ScoreRenderingEngine {
        return this.query.engine
    }

    /**
     * This method is responsible for updating the loading status of a pack, and trigger the
     * procedure to load the corresponding score whenever the pack reaches the right state
     * @param status
     * @returns void
     */
    public loadPack({
        pack,
        status,
    }:
        | {
              pack?: AccompanimentPack
              status?: AccompanimentPackLoadingStatus | undefined
          }
        | undefined): void {
        let scoreLoading = {
            started: false,
            finished: false,
            packID: undefined,
        }

        let packLoading = {
            finished: false,
            failed: false,
            progress: 0,
            packID: this.query.loadedPackID || undefined,
            generationProfileID: this.query.generationProfileID,
        }

        if (status) {
            packLoading = {
                finished:
                    status.type === "finished" && pack?.packID !== undefined,
                failed: status.error !== undefined,
                progress: status.loadingStatus,
                packID: this.query.loadedPackID,
                generationProfileID: this.query.generationProfileID,
            }
        }

        let patternLength = 1

        if (pack !== undefined && pack.patterns.length > 0) {
            patternLength = pack.patterns[0].bars
        }

        this.store.updateStore({
            state: {
                packLoading,
                scoreLoading,
                pack: pack,
                patternLength: patternLength as BarCount,
            },
            origin: "loadPack",
        })
    }

    async init({
        packID,
        generationProfileID,
        msMode,
    }: {
        packID: string
        generationProfileID: string
        msMode: boolean
    }) {
        try {
            this.store.updateStore({
                state: {
                    msMode,
                },
                origin: "init",
            })

            await this.initIDs({
                packID,
                generationProfileID,
            })

            this.loadPack({
                status: {
                    type: "loading",
                    loadingStatus: 10,
                },
            })

            await this.fetchPack(packID)
        } catch (error) {
            console.error(error)
            this.setLoadingToFailed(packID, generationProfileID)
        }
    }

    async initIDs({
        packID,
        generationProfileID,
    }: {
        packID: string
        generationProfileID: string
    }) {
        // init packID and gpID and rely on them being updated
        // in the store from here on
        this.store.updateStore({
            state: {
                packLoading: {
                    ...this.query.packLoading,
                    packID: packID,
                    generationProfileID: generationProfileID,
                },
            },
            origin: "initIDs",
        })
    }

    public async fetchPack(packID: string): Promise<AccompanimentPack> {
        const pack: AccompanimentPack = await this.adHttp.getPackByID(packID)
        const packStatus = AccompanimentPack.stepsToLoadingStatus(pack.steps)

        this.loadPack({
            status: packStatus,
            pack: pack,
        })

        return pack
    }

    public async fetchPackAfterSaving(
        status: AccompanimentPackLoadingStatus
    ): Promise<AccompanimentPack> {
        let pack: AccompanimentPack

        if (status.type === "finished" && this.query.pack === undefined) {
            pack = await this.fetchPack(this.query.loadedPackID)
        }

        this.loadPack({
            status: status,
            pack: pack,
        })

        return pack
    }

    subscribeToSocket() {
        this.subscribe(this.adHttp.socket, socket => {
            if (socket == null) {
                return
            }

            socket.on("accompanimentPackUpdate", async data => {
                await this.handleAccompanimentPackUpdate(data)
            })
        })
    }

    async handleAccompanimentPackUpdate(data: { _id; steps }) {
        const status = AccompanimentPack.stepsToLoadingStatus(data.steps)

        if (status.type === "error") {
            this.loadPack({
                status: status,
            })

            return
        }

        // make sure it actually is a new packID that we fetch data for
        // also in case we already fetched the new pack and updated the pack in the store,
        // don't fetch the pack again and ignore the data as we are successfully done with the procedure!
        if (
            this.query.loadedPackID !== data._id ||
            this.query.pack?.packID === data._id
        ) {
            return
        }

        await this.fetchPackAfterSaving(status)
    }

    public deleteEngine() {
        this.query.engine.deleteAllCanvases()
    }

    public toggleLayer(layer: string) {
        this.query.engine.toggleLayer(layer)
    }

    public async onDestroy() {
        this.deleteEngine()

        await this.player.pause()

        // we switch to offline playback here, so users can't play back the pattern
        // from the global player bar after closing the AD component and service
        this.player.setOfflinePlayback("AccompanimentDesignerService.onDestroy")
    }

    renamePack(newPackName: string) {
        if (!this.query.pack) {
            return
        }

        const pack = this.query.pack
        pack.name = newPackName

        this.store.updateStore({
            state: {
                pack: pack,
                packLoading: {
                    ...this.query.packLoading,
                    packID: pack.packID,
                },
            },
            origin: "renamePack",
        })

        this.onPackRenamed?.(newPackName)
    }

    get noteResolution(): number {
        return Number(this.query.engine.timestepRes)
    }

    updatePack(pack: AccompanimentPack | undefined) {
        this.store.updateStore({
            state: {
                pack,
            },
            origin: "updatePack",
        })
    }

    setPatternLength(patternLength: BarCount, notesToRemove: NotesObject) {
        if (!this.query.pack || !this.engine?.score) {
            return
        }

        if (!this.msMode) {
            this.query.engine.setAccompanimentDesignerScoreLength(
                `${patternLength}/1`,
                notesToRemove
            )
        }

        this.store.updateStore({
            state: {
                patternLength,
            },
            origin: "setPatternLength",
        })
    }

    setKeySignature(keySignature: string) {
        if (!this.query.pack) {
            return
        }

        this.query.engine.updateKeySignature(keySignature, true, 2)
    }

    async savePack() {
        if (!this.query.pack || !this.query.engine?.score) {
            return {
                success: false,
                message: "Pack or Score is undefined",
            }
        }

        await this.player.pause()

        this.savePackLoading.next(true)

        const pack: AccompanimentPack = cloneDeep(this.query.pack)
        const score: Score = this.query.engine.score
        const layerType: LayerType = this.layer as LayerType

        /**
         * we send two different packs to the API
         * - the one for the creators api allows notes to cross bar boundaries
         * - the one on the ME does not allow notes to cross bar boundaries
         *
         * the reason why this separation is done here instead of on the api level are
         * - the api does not know about the score and can't easily create a score object from the score (but from a template score)
         *   and the api is missing the information about the pattern length
         */
        pack.patterns = Score.convertScoreToPatterns(
            score,
            layerType,
            this.patternLength as BarCount,
            true
        )

        const newPackID = await this.adHttp.savePack(
            pack,
            this.query.generationProfileID
        )

        this.savePackLoading.next(false)

        if (newPackID === undefined) {
            this.loadPack({
                status: {
                    type: "error",
                    loadingStatus: 20,
                    error: "An error occured while saving your accompaniment pack",
                },
            })

            return {
                success: false,
            }
        }

        this.engine.deleteAllCanvases()

        this.store.updateStore({
            state: {
                packLoading: {
                    ...this.query.packLoading,
                    packID: newPackID,
                },
            },
            origin: "savePack",
        })

        this.updateURL({
            packID: newPackID,
        })

        this.loadPack({
            status: {
                type: "loading",
                loadingStatus: 20,
            },
        })

        return {
            success: true,
            newPackID,
        }
    }

    async initGP() {
        if (!this.query?.generationProfileID) {
            return
        }

        await this.gpService.init(this.query.generationProfileID, true)
    }

    /**
     * updates the current url packID or layerType
     * @param
     * @returns
     */
    public updateURL({
        packID,
        createTemplatePack,
    }: {
        packID?: string
        createTemplatePack?: boolean
    }) {
        const urlSegments = this.router
            .createUrlTree([], {})
            .root.children[PRIMARY_OUTLET].segments.map(segment => segment.path)

        if (packID) {
            urlSegments[2] = packID
        }

        if (createTemplatePack !== undefined) {
            urlSegments[5] = createTemplatePack + ""
        }

        this.location.replaceState(urlSegments.join("/"))
    }

    public async initialiseEditor() {
        const layerName = Object.keys(this.query?.engine?.score?.layers)[0]
        const timestepRes = this.getSupportedNoteResolution(
            this.query?.engine?.score?.layers[layerName]?.notesObject
        )
        this.query.engine.setPitchStepDomain("scale")
        this.query.engine.resizeFactor = 70
        this.query.engine.resetScroll({
            timesteps: false,
            pitchsteps: true,
        })
        this.toggleLayer(layerName)

        this.query.engine.deleteCanvas("PianorollGridCanvas")
        this.query.engine.deleteCanvas("PianoCanvas")
        this.query.engine.deleteCanvas("AccompanimentDesignerCanvas")

        await InitCanvases.initAccompanimentDesignerCanvas(this.query.engine)

        await InitCanvases.initPianoRollGridCanvas(this.query.engine, true)

        await InitCanvases.initPianoCanvas(this.query.engine)

        this.engine.setAccompanimentDesignerScoreLength(
            Time.measuresToFraction(
                this.query.patternLength,
                this.engine.score.firstTimeSignature
            ),
            new NotesObject()
        )

        this.query.engine.setTimestepRes(timestepRes)

        // since all of the above are no actual user changes
        // but changes necessary for initialization, we will
        // set the scoreWasEdited to false afterwards, so users
        // are able to start fresh
        this.query.engine.resetScoreWasEdited()
    }

    play() {
        if (!this.query.engine) {
            return
        }

        return this.player.play()
    }

    pause() {
        if (!this.query.engine) {
            return
        }

        return this.player.pause()
    }

    togglePlayback() {
        if (this.isPlaying) {
            this.pause()
        } else {
            this.play()
        }
    }

    getPlaybackIcon() {
        if (this.isPlaying) {
            return "assets/img/player/pause.svg"
        }

        return "assets/img/player/play.svg"
    }

    private setLoadingToFailed(packID: string, generationProfileID: string) {
        this.store.updateStore({
            state: {
                scoreLoading: {
                    started: false,
                    finished: false,
                    packID: undefined,
                },

                packLoading: {
                    finished: true,
                    failed: true,
                    progress: 0,
                    packID: packID,
                    generationProfileID: generationProfileID,
                },
            },
            origin: "setLoadingToFailed",
        })
    }

    public async loadScore(
        accompanimentPack: AccompanimentPack,
        force: boolean
    ) {
        try {
            if (!this.query.loadedPackID || !accompanimentPack) {
                throw "Undefined pack"
            }

            const scoreLoading = this.query.scoreLoading

            if (
                scoreLoading.started &&
                scoreLoading.packID === this.query.loadedPackID &&
                !force
            ) {
                return
            }

            this.store.updateStore({
                state: {
                    scoreLoading: {
                        started: true,
                        finished: false,
                        packID: this.query.loadedPackID,
                    },
                },
                origin: "loadScore1",
            })

            this.fixPatternNumberOfBars(accompanimentPack)

            const score = await this.getScoreFromPack(accompanimentPack)

            this.deleteEngine()

            playerActions.setTrackBusLoadingPercentage(0)

            const engine = ScoreRenderingEngine.initScore(
                score,
                environment.production ? "production" : "staging",
                {
                    userID: this.token.userID,
                    settings: this.user.settings,
                    instruments: this.instruments.instruments,
                    autoExtendScore: this.query.msMode,
                    maxBarLength: 8,
                    resizeFactor: {
                        min: 70,
                        max: 150,
                    },
                    levelsMeasurement: "trackbus",
                    sustainPedalFromChords: false
                }
            )

            await this.player.initialisePlayerWithPreview(
                engine,
                this.query.pack.packID,
                this.query.pack.name
            )

            // We don't care about any safety mechanisms of the player when using the app in
            // the browser (e.g. making sure the samples are loaded into memory before playing),
            // so we return after updating the store.
            if (!this.isDesktopApp()) {
                this.store.updateStore({
                    state: {
                        engine: engine,
                        scoreLoading: {
                            started: true,
                            finished: true,
                            packID: this.query.loadedPackID,
                        },
                    },
                    origin: "loadScore2",
                })

                return
            }

            playerQuery
                .select("trackBusLoadingPercentage")
                .pipe(takeWhile(value => value < 100, true))
                .subscribe(value => {
                    if (value === 100) {
                        this.store.updateStore({
                            state: {
                                engine: engine,
                                scoreLoading: {
                                    started: true,
                                    finished: true,
                                    packID: this.query.loadedPackID,
                                },
                            },
                            origin: "loadScore2",
                        })
                    }
                })
        } catch (e) {
            console.error(e)
            this.setLoadingToFailed(
                this.query.loadedPackID,
                this.query.generationProfileID
            )
        }
    }

    private fixPatternNumberOfBars(pack: AccompanimentPack) {
        if (
            pack.patterns.length > 0 &&
            !BAR_COUNTS.includes(pack.patterns[0].bars)
        ) {
            for (const pattern of pack.patterns) {
                pattern.bars = 1
            }
        }
    }

    public async getScoreFromPack(accompanimentPack: AccompanimentPack) {
        const msMode = this.msMode
        const pack = cloneDeep(accompanimentPack) as AccompanimentPack

        let keySignature = "C major"

        if (pack?.keySignature) {
            keySignature = pack.keySignature
        } else if (this.query?.engine?.score?.keySignatures[0][1]) {
            keySignature = this.query?.engine?.score?.keySignatures[0][1]
        }

        pack.keySignature = keySignature

        if (!msMode && pack.patterns.length > 0) {
            pack.patterns = [accompanimentPack.patterns[0]]
        }

        const score = Score.convertPackToScore({
            accompanimentPack: pack,
            samplesMap: this.instruments.drumSamples,
            concatenatePatterns: true,
            keySignature: Score.getKeySignatureObject(pack.keySignature),
            instruments: this.instruments.instruments,
            layer: this.layer,
        })

        const currentLayerName = Object.keys(score.layers)[0]

        // Adjust the layer of the score only if the layer name differs.
        // This is useful when we want to create a pattern in the Extra layers e.g.
        if (this.layer && this.layer !== currentLayerName) {
            const layer = score.layers[Object.keys(score.layers)[0]]
            const colors = Layer.getLayerColor(this.layer)

            layer.name = this.layer
            layer.value = this.layer
            layer.defaultColor = colors.defaultColor
            layer.oppositeColor = colors.oppositeColor
            score.layers = {}
            score.layers[this.layer] = layer
        }

        return score
    }

    /**
     * returns the onsets of the grid as fractions based on the given params
     * @returns Array<FractionString>, e.g. ["0/1", "1/4", "2/4", "3/4"]
     *          for 4/4 timesignature timestepRes of 4 and patternLength of 1
     */
    private getGridOnsets({
        timeSignature,
        noteResolution,
        patternLength,
    }): FractionString[] {
        if (this.msMode && this.engine?.score?.scoreLength) {
            patternLength = Math.ceil(
                Time.fractionToNumber(this.engine?.score?.scoreLength)
            )
        }

        const numberOfSteps =
            Math.floor(noteResolution / timeSignature[1]) *
            patternLength *
            timeSignature[0]

        return [...Array(numberOfSteps).keys()].map(step =>
            step === 0
                ? "0/1"
                : Time.simplifyFractionFromString(step + "/" + noteResolution)
        )
    }

    /**
     * returns a note object that contains all notes that don't fit the current
     * grid criteria (e.g. notes that start after 1/1 when the pattern length is changed to 1)
     * @returns NotesObject
     */
    public notesToRemove(params: {
        timeSignature: TimeSignature
        noteResolution: number
        patternLength: BarCount
    }): NotesObject {
        const newOnsets = this.getGridOnsets({ ...params })

        // the end of the current pattern
        const newOnsetsEnd = Time.addTwoFractions(
            newOnsets[newOnsets.length - 1],
            "1/" + params.noteResolution
        )
        const notes = this.query.engine.toggledLayer.notesObject.getFlatArray()
        const notesToRemove = new NotesObject()

        for (let note of notes) {
            const start = note.start
            const end = Time.addTwoFractions(start, note.duration)

            if (
                !newOnsets.includes(start) ||
                (!newOnsets.includes(end) &&
                    Time.compareTwoFractions(
                        note.duration,
                        `1/${this.noteResolution}`
                    ) === "gt" &&
                    Time.compareTwoFractions(end, newOnsetsEnd) === "gt") ||
                Time.compareTwoFractions(end, newOnsetsEnd) === "gt"
            ) {
                notesToRemove.addNoteToGroup(
                    note,
                    params.timeSignature,
                    this.query?.engine?.score?.sections
                )
            }
        }

        return notesToRemove
    }

    /**
     * this method returns the smallest supported note resolution based on the notes of a layer
     */
    public getSupportedNoteResolution(notesObject: NotesObject) {
        const defaultNoteResolution = 16

        if (!notesObject.length) {
            return defaultNoteResolution
        }

        const flatNotes = notesObject.getFlatArray()
        const allRes = flatNotes.map(n => parseInt(n.duration.split("/")[1]))
        const res = Math.max(...allRes)

        // don't allow res below 1/4 notes
        if (res < 4) {
            return defaultNoteResolution
        }

        // don't allow res above 1/48 notes
        else if (res > 48) {
            return 48
        }

        return res
    }

    public get layers$(): Observable<Layer[] | undefined> {
        return this.query.engine.score$.pipe(
            map((score: Score | undefined) => {
                if (score === undefined) {
                    return undefined
                }

                return Object.keys(score.layers).map(key => {
                    return score.layers[key]
                })
            })
        )
    }

    isDesktopApp() {
        return this.windowService.desktopAppAPI !== undefined
    }
}
