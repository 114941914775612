import InstrumentPatch from "./score/instrumentpatch"

export default class SearchItem {
    id: string
    deleted: boolean
    values: Array<SearchItem> | any = null // either an array or null
    name: string = ""
    path: string = ""
    type: string = "patch" // either "folder" or "patch"
    createdByAiva = false
    item // a double, patch, instrument, or anything else
    pitchRange
    recommendations: {
        range?
        patch?
        percussionFunction?
    }
    recommended
    percussionFunction
    similarityScore

    constructor(
        id,
        values,
        name,
        path,
        type,
        item,
        createdByAiva,
        deleted = false,
        pitchRange = [],
        recommendations = {},
        recommended = true,
        percussionFunction = null,
        similarityScore = null
    ) {
        this.id = id
        this.values = values
        this.name = name
        this.path = path
        this.type = type
        this.item = item
        this.createdByAiva = createdByAiva
        this.deleted = deleted
        this.pitchRange = pitchRange
        this.recommendations = recommendations
        this.recommended = recommended
        this.percussionFunction = percussionFunction
        this.similarityScore = similarityScore
    }

    static getAllValues(searchItem: SearchItem):InstrumentPatch[] {
        let values = []

        for (const value of searchItem.values) {
            if (value.type === "folder") {
                values.concat(SearchItem.getAllValues(value))
            } else {
                values.push(value.item)
            }
        }

        return values
    }
}
