import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Input,
} from "@angular/core"
import { CompositionWorkflowService } from "@services/composition-workflow/composition-workflow.service"
import { firstValueFrom } from "rxjs"
import Layer from "@common-lib/classes/score/layer"
import { ParentClass } from "../../../parent"
import { TutorialService } from "@services/tutorial.service"
import { featureFlags } from "@common-lib/utils/feature-flags"

@Component({
    selector: "composition-workflow-step-2",
    templateUrl: "./composition-workflow-step-2.component.html",
    styleUrls: ["./composition-workflow-step-2.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositionWorkflowStep2Component extends ParentClass {
    @Input() service: CompositionWorkflowService

    public loading: boolean = true
    public part: "part1" | "part2" = "part1"

    constructor(
        private ref: ChangeDetectorRef,
        private tutorialService: TutorialService
    ) {
        super()
    }

    async ngAfterViewInit() {
        await this.initializeComponent()

        this.initListeners()

        await this.service.createInitialLayers()
    }

    private initListeners() {
        this.subscribe(
            this.service.engine.toggledLayer$,
            (layer: Layer | undefined) => {
                this.part = layer === undefined ? "part1" : "part2"

                this.ref.detectChanges()
            }
        )

        this.subscribe(this.tutorialService.initTutorial$, val => {
            if (val) {
                if (this.part === "part1") {
                    this.tutorialService.initCWStep2Part1Tutorial(true)
                } else if (this.part === "part2") {
                    this.tutorialService.initStep2Part2Tutorial(true)
                }
            }
        })
    }

    private async initializeComponent() {
        this.setLoading(true)

        await firstValueFrom(this.service.engine$)

        await this.service.initialisePlayer(
            this.service.query.engine.score.compositionID,
            true
        )

        this.setLoading(false)
    }

    private setLoading(value: boolean) {
        this.loading = value
        this.ref.detectChanges()
    }
}
