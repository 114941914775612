<div class="modal-title">
    Select an existing
    {{
        input.type === "chordProgression"
            ? "chord
    progression"
            : "composition workflow"
    }}
</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div
    class="list-container"
    *ngIf="!loading && items.length > 0"
    [perfectScrollbar]="scrollConfig">
    <list-items [items]="items" [numberOfColumns]="2"></list-items>
</div>

<div
    class="list-container no-workflow-yet"
    *ngIf="!loading && items.length === 0">
    You don't have any saved
    {{
        input.type === "chordProgression"
            ? "chord
    progression"
            : "composition workflow"
    }}
    yet.
</div>

<div class="loader-container" *ngIf="loading">
    <div class="small-loader"></div>
</div>
