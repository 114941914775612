import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { BehaviorSubject } from 'rxjs'
import Notification from '@common-lib/classes/notification'
import { Misc } from '@common-lib/modules/misc'
import { AnimationLoopService } from './animationloop.service'

@Injectable()
export class NotificationsService {
    notificationQueue:Array<Notification> = []
    showNotification:BehaviorSubject<Notification> = new BehaviorSubject<Notification>(null)
    socket
    showTime = 5 // number of seconds to show the notification for

	constructor(protected apiService:ApiService, private animationService:AnimationLoopService) {
        this.apiService.socket.subscribe((socket) => {
            socket.on('notification', (data) => {
                this.notificationQueue.push(Notification.fromJSON(data))
            })  
        })

        this.animationService.addFunctionToLoop("notificationLoop", this.notificationLoop.bind(this), this.showTime * 1000)
	}

    async notificationLoop() {
        if (this.notificationQueue.length == 0) {
            return
        }

        let notification:Notification = this.notificationQueue.shift()

        if (Notification.groupType(notification.type)) {
            let otherNotificationsOfSameType = []

            for (let n = this.notificationQueue.length - 1; n >= 0; n--) {
                let otherNotification = this.notificationQueue[n]

                if (otherNotification.type == notification.type) {
                    otherNotificationsOfSameType.push(otherNotification)

                    this.notificationQueue.splice(n, 1)
                }
            }

            if (otherNotificationsOfSameType.length > 0) {
                let result = Notification.groupedTitlesForType(notification.type, otherNotificationsOfSameType.length + 1, notification)
                notification.title = result.title
                notification.description = result.description
            }
        }
        
        this.showNotification.next(notification)

        await Misc.wait(this.showTime)

        this.showNotification.next(null)

        await Misc.wait(0.5)
    }
}