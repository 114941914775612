import {
    OnInit,
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
} from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { CreateTrackFrom, UIContentType } from "@common-lib/types/general"
import { FolderService } from "@services/folder.service"
import { UserService } from "@services/user.service"

@Component({
    selector: "topmenu",
    templateUrl: "topmenu.component.html",
    styleUrls: ["topmenu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopMenuComponent implements OnInit {
    @Input() options: Array<{
        value: string
        name: string
        avData: string
    }> = []
    @Input() type: "trackslist" | "createTrack"
    @Input() class: string = ""
    @Output() menuSelected = new EventEmitter<string>()

    selectedMenu = ""

    get createType(): CreateTrackFrom {
        return this.activatedRoute.snapshot.paramMap.get(
            "type"
        ) as CreateTrackFrom
    }

    constructor(
        private folderService: FolderService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute
    ) {}

    selectMenu(menu: UIContentType) {
        if (this.type === "trackslist") {
            this.folderService.selectedFolderID.next("")
        }

        this.selectedMenu = menu
        this.menuSelected.emit(this.selectedMenu)
    }

    ngOnInit(): void {
        if (this.type == "trackslist") {
            this.folderService.contentType.subscribe(value => {
                this.selectedMenu = value
            })
        } else if (this.type == "createTrack") {
            this.userService.createTrackMenu.subscribe(value => {
                this.selectedMenu = value
            })
        }

        if (this.selectedMenu == "" && this.options.length > 0) {
            this.selectedMenu = this.options[0].value
        }

        this.activatedRoute.paramMap.subscribe(params => {
            if (params.get("type")) {
                this.selectMenu(params.get("type") as UIContentType)
            }
        })
    }
}
