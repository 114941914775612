<div class="wrapper" [ngClass]="{ 'wrapper--zeroed': zeroedPosition }">
    <div class="dummy"></div>
    <div
        id="chords-editing-canvas"
        [ngClass]="{ 'position-zero': zeroedPosition }"
        av-data="chords-editing-canvas">
        <div
            id="chords-editing-button-row"
            *ngIf="
                chordsEditingButtons && !showCollapsedMenu(chordsEditingButtons)
            "
            [style.width]="chordsEditingButtons?.width + 'px' || '0px'"
            [style.left]="chordsEditingButtons?.start + 'px' || '0px'">
            <div
                class="chords-editing-button-row-wrapper"
                [ngClass]="{
                    'one-column':
                        nbOfButtonsShown(chordsEditingButtons?.chord) === 1,
                    'two-columns':
                        nbOfButtonsShown(chordsEditingButtons?.chord) === 2,
                    'three-columns':
                        nbOfButtonsShown(chordsEditingButtons?.chord) === 3
                }">
                <button
                    (click)="editChord($event, chordsEditingButtons?.chord)">
                    <span class="icon"
                        ><img src="assets/img/thin_pencil.svg"
                    /></span>
                </button>

                <button
                    *ngIf="showSplitChordButton(chordsEditingButtons.chord)"
                    (click)="insertChord(chordsEditingButtons.chord?.index)">
                    <span class="icon"><img src="assets/img/add.svg" /></span>
                </button>

                <button
                    id="delete-chord"
                    *ngIf="showDeleteChordButton(chordsEditingButtons.chord)"
                    (click)="deleteChord(chordsEditingButtons?.chord?.index)">
                    <span class="icon"
                        ><img src="assets/img/menu/delete.svg"
                    /></span>
                </button>
            </div>
        </div>

        <div
            id="chords-editing-button-row"
            *ngIf="
                chordsEditingButtons && showCollapsedMenu(chordsEditingButtons)
            "
            [style.width]="chordsEditingButtons?.width + 'px' || '0px'"
            [style.left]="chordsEditingButtons?.start + 'px' || '0px'">
            <div class="chords-editing-button-row-wrapper">
                <button
                    (click)="
                        toggleChordsEditingMenu(
                            chordsEditingButtons?.chord,
                            $event,
                            false
                        )
                    ">
                    <span class="icon"><img src="assets/img/more.svg" /></span>
                </button>
            </div>
        </div>
    </div>
</div>

<context-menu
    *ngIf="editChordSettings !== undefined"
    (close)="closeChordEditSettings()"
    [coordinates]="{
        x: editChordSettings.coordinates.xAbs,
        y: editChordSettings.coordinates.yAbs
    }">
    <div contentFromParent class="chord-edit-container">
        <div class="chord-edit-item">
            <div
                class="chord-edit-title"
                *ngIf="engine.renderChordsType !== 'roman-numeral'">
                Symbol
            </div>
            <div
                class="chord-edit-title"
                *ngIf="engine.renderChordsType === 'roman-numeral'">
                Numeral
            </div>

            <div class="chord-edit-value">
                <dropdown
                    class="empty large-empty chord-symbol"
                    type="light"
                    [showTooltip]="false"
                    [items]="getChordPrefixes(editChordSettings)"
                    [value]="getSelectedChordPrefix(editChordSettings)"
                    (selectedItem)="setChordOption(editChordSettings, $event)">
                </dropdown>
            </div>
        </div>

        <div class="chord-edit-item">
            <div class="chord-edit-title">Extension</div>
            <div class="chord-edit-value">
                <dropdown
                    class="empty large-empty chord-extension"
                    type="light"
                    [showTooltip]="false"
                    [items]="getChordSuffixes(editChordSettings)"
                    [value]="getSelectedChordSuffix(editChordSettings)"
                    (selectedItem)="setChordSuffix(editChordSettings, $event)">
                </dropdown>
            </div>
        </div>
    </div>
</context-menu>

<menu-options
    *ngIf="chordsEditingMenu !== undefined"
    [menuOptions]="chordsEditingMenu"
    (close)="toggleChordsEditingMenu(undefined, $event, true)"></menu-options>
