import {
    Component,
    Output,
    EventEmitter,
    Input,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from "@angular/core"
import {
    LayerGenerationModalInput,
    ModalService,
} from "@services/modal.service"
import { DeviceDetectorService } from "ngx-device-detector"
import { SRActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { ParentClass } from "../../../parent"

@Component({
    selector: "layer-generation",
    templateUrl: "layer-generation.component.html",
    styleUrls: ["layer-generation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerGenerationModal extends ParentClass {
    @Input() input: LayerGenerationModalInput
    @Output() close: EventEmitter<any> = new EventEmitter()

    public changeSelectStyle: boolean = false
    public sectionLength: number = 8
    public emptySection: boolean = false

    public loading: boolean = false

    private controller: AbortController | undefined

    constructor(
        private modalService: ModalService,
        private device: DeviceDetectorService,
        private instruments: InstrumentsService,
        private ref: ChangeDetectorRef
    ) {
        super()

        this.subscribe(this.modalService.modals.layerGeneration, data => {
            if (data === undefined && this.controller !== undefined) {
                this.controller.abort()
            }
        })

        // select options were invisible because of a lack of contrast
        this.changeSelectStyle =
            this.device.os == "Linux" || this.device.os == "Windows"
    }

    async ngOnInit() {
        await this.apply()
    }

    public async apply() {
        this.setLoading(true)

        this.controller = new AbortController()

        const result = await new Promise(resolve => {
            this.input.engine.srEmitter$.next({
                type: SRActionTypes.layerGeneration,
                data: {
                    section: this.input.section,
                    layerName: this.input.layerName,
                    sectionLength: this.sectionLength,
                    instruments: this.instruments.instruments,
                    samplesMap: this.instruments.drumSamples,
                    generateLayer: this.input.generateLayer.bind(this),
                    abort: this.controller,
                    resolve,
                },
                options: {
                    isUndoable: true,
                },
            })
        })

        this.controller = undefined

        this.setLoading(false)

        this.input.onComplete()
    }

    private setLoading(value: boolean) {
        this.loading = value
        this.ref.detectChanges()
    }

    public getNumberOfDots() {
        return new Array(400)
    }
}
