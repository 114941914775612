import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from "@angular/core"
import { ApiService } from "@services/api.service"

@Component({
    selector: "changelog",
    templateUrl: "changelog.modal.html",
    styleUrls: ["changelog.modal.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangelogModal implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter()

    public loading: boolean = true
    public changelog: any[] = []
    public options = { autoHide: false }

    constructor(
        private apiService: ApiService,
        private ref: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        this.loading = true

        const result = await this.apiService.authRequest(
            "/data/getChangelog",
            {},
            "primary",
            true
        )

        this.changelog = result.changelog

        this.loading = false

        this.ref.detectChanges()
    }

    closeModal() {
        this.close.emit()
    }
}
