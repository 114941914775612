import { gpOptions } from "../../constants/gp_options"
import GPValidation from "./gpvalidation"
import GPInfluenceLoading from "./influences/gpinfluenceloading"
import { GPSettingsSchema } from "../../interfaces/db-schemas/generationprofile"
import { GlobalParametersMusicEngine } from "../../interfaces/music-engine/generationprofile"

const options = gpOptions['settings']
export default class GPSettings {
    static BALANCE_PRESET_OPTIONS:Array<any> = options.balancePreset
    static DEVELOPMENT_TYPE_OPTIONS:Array<{ id, name, description, ids }> = options.formTag
    static EXPRESSIVE_PERFORMANCE_OPTIONS = options.expressivePerformance
    static MOOD_OPTIONS = options.mood
    static TIME_SIGNATURE = options.timeSignatures

    autoMix:boolean = null

    dynamicRange = {
        min: 8,
        max: 120
    }

    tempoRange = {
        min: 100,
        max: 120
    }

    expressivePerformance = 0

    minLayers = 1

    formTags = [{ id: 'song', name: 'Song', description: '' }]

    timeSignature = [4,4]

    emotion:number = null

    gpInfluenceLoading:GPInfluenceLoading = new GPInfluenceLoading(0, "")

    name:string = "Settings"

    layersOrder:Array<Array<string>> = [
        [ "Percussion", "Bass", "Chords", "Melody", "Extra", "Extra_1", "Extra_2" ],
        [ "Percussion", "Chords", "Bass", "Melody", "Extra", "Extra_1", "Extra_2" ]
    ]

    setSettings(){
        
    }

    static computeSimilarity(settings1:GPSettings, settings2:GPSettings) {
        let compatibleAttributes = ['autoMix', 'dynamicRange', 'tempoRange', 'expressivePerformance', 'minLayers', 'formTags', 'timeSignature', 'emotion', 'layersOrder' ]

        let scorePerAttribute = 1 / compatibleAttributes.length

        let score = 0

        let report = {}

        for (let key in settings1) {
            if (!compatibleAttributes.includes(key)) {
                continue
            }

            let scoreToAdd = 1

            if (Array.isArray(settings1[key])) {
                if (settings1[key].length != settings2[key].length) {
                    continue
                }

                for (let setting1 of settings1[key]) {
                    let temp1 = JSON.stringify(setting1)

                    let hasEqual = false

                    for (let setting2 of settings2[key]) {
                        let temp2 = JSON.stringify(setting2)
                    
                        if (temp1 == temp2) {
                            hasEqual = true

                            break
                        }
                    }

                    if (!hasEqual) {
                        scoreToAdd = 0
                        break
                    }
                }
            }

            else if (typeof settings1[key] == "object") {
                let temp1 = JSON.stringify(settings1[key])
                let temp2 = JSON.stringify(settings2[key])

                if (temp1 != temp2) {
                    scoreToAdd = 0
                }
            }

            else if (settings1[key] != settings2[key]) {
                scoreToAdd = 0
            }

            report[key] = scoreToAdd

            score += scoreToAdd * scorePerAttribute
        }

        return {
            score: Math.round(score),
            report: report
        }
    }

    encodeFormTags() {
        let encodedFormTags = []

        for (let ft of this.formTags) {
            encodedFormTags.push(typeof ft == "string" ? ft : ft.id)
        }

        return encodedFormTags
    }

    encodeForDB():GPSettingsSchema {
        return {
            gpInfluenceLoading: this.gpInfluenceLoading ? this.gpInfluenceLoading.encode() : null,
            dynamicRange: { min: this.dynamicRange.min, max: this.dynamicRange.max },
            tempoRange: { min: this.tempoRange.min, max: this.tempoRange.max },
            timeSignature: this.timeSignature,
            minLayers: this.minLayers,
            formTags: this.encodeFormTags(),
            layersOrder: this.layersOrder,
            autoMix: this.autoMix,
            expressivePerformance: this.expressivePerformance,
            emotion: this.emotion
        }
    }

    encodeForME():GlobalParametersMusicEngine {
        let meFormTags = []

        for (let encodedFormTag of this.encodeFormTags()) {
            for (let tag of GPSettings.DEVELOPMENT_TYPE_OPTIONS) {
                if (tag.id == encodedFormTag) {
                    meFormTags = meFormTags.concat(tag.ids)

                    break
                }
            }
        }

        meFormTags = [... new Set(meFormTags)]

        return {
            dynamic_range: [this.dynamicRange.min, this.dynamicRange.max],
            tempo_range: [this.tempoRange.min, this.tempoRange.max],
            time_signature: this.timeSignature,
            min_layers: this.minLayers,
            form_tags: meFormTags,
            layers_order: this.layersOrder,
            expressive_performance: this.expressivePerformance
        }
    }

    static fromJSON(object) {
        let settings = Object.assign(new GPSettings(), object)
        settings.gpInfluenceLoading = GPInfluenceLoading.fromJSON(object.gpInfluenceLoading)
        
        return settings
    }

    validate(validateForUI = false, numberOfLayers = 0){
        let validation = new GPValidation(true, "settings", this)
        let formTagsValidation = new GPValidation(this.formTags != null && this.formTags.length > 0, "formTags", this.formTags, null)

        if(formTagsValidation.valid == false){
            formTagsValidation.message = "Please select at least one structure development type in your development settings."
            validation.valid = false
        }

        validation.validations.push(formTagsValidation)

        if(validateForUI == false){
            validation.validations.push(GPValidation.validateRange(this.dynamicRange, "dynamicRange"))
            validation.validations.push(GPValidation.validateRange(this.tempoRange, "tempoRange"))
            validation.validations.push(this.validateMinLayers(numberOfLayers))

            let timeSignatureValidation = new GPValidation(this.timeSignature != null, "timeSignature", this.timeSignature, null)
            let layersOrderValidation = new GPValidation(this.layersOrder != null && this.layersOrder.length > 0, "layersOrder", this.layersOrder, null)

            if(this.timeSignature == null){
                timeSignatureValidation.valid = false
                timeSignatureValidation.issue = "invalidTimeSignature"
                timeSignatureValidation.message = "The time signature is null or invalid"
            }

            validation.validations.push(timeSignatureValidation)
            validation.validations.push(layersOrderValidation)
        }

        for(let v of validation.validations){
            if(v.valid == false){
                validation.valid = false
                validation.message = v.message

                break
            }
        }

        return validation
    }

    validateMinLayers(numberOfLayers = 0){
        return new GPValidation(this.minLayers <= numberOfLayers, "minLayers", this.minLayers, "", "minLayersHigherThanActualLayers", "The number of minimum layers to use is higher than the number of layers in this style.")
    }

    static validateEmotion(emotion, mode){
        let validation = new GPValidation(true, "emotion", this)

        let otherMode = "major"

        if (mode == "major") {
            otherMode = "minor"
        }

        if (emotion != null && mode != null && mode != GPSettings.MOOD_OPTIONS.keyMode[emotion]){
            validation.valid = false
            validation.message = "A key signature with " + mode + " mode cannot be used with the mood selected inside this generation profile. Please change the key to any with " + otherMode + " mode."
        }

        return validation
    }
}