import { Injectable } from '@angular/core'


@Injectable()
export class GestureService {
    constructor() {

    }

    getSwipeDirection(event){
		if(event.type == null || event.type != 'swipe'){
			return undefined
		}

		if(event.direction == 8){
			return "up"
		}
		if(event.direction == 16){
			return "down"
		}
		if(event.direction == 2){
			return "left"
		}
		if(event.direction == 4){
			return "right"
		}

		return undefined
	}
}