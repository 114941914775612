import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core"

import { ParentClass } from "../../../parent"
import { MixingOptionsModal } from "@services/modal.service"
import { MixingOption } from "@common-lib/types/score"
import { Misc } from "@common-lib/modules/misc"
@Component({
    selector: "mixing-options",
    templateUrl: "mixing-options.component.html",
    styleUrls: ["mixing-options.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MixingOptionsComponent extends ParentClass {
    public loading: boolean = false

    @Input() mixingOptions: MixingOptionsModal
    @Output() close = new EventEmitter<any>()

    selectedOption: MixingOption = "none"
    message: string | undefined

    descriptions = {
        autoMixAndMaster:
            "AIVA will balance how loud each instrument should be in relation to each other. Warning: any volume adjustements made will be overriden.",
        autoMaster:
            "Relative levels between instruments won't be changed, but the overall loudness of your composition may be boosted to meet industry standards.",
        none: "No mixing or mastering will be applied, but peak protection will still be applied to prevent audio clipping.",
    }

    constructor(private ref: ChangeDetectorRef) {
        super()
    }

    async clickedButton() {
        this.loading = true
        this.message = undefined

        this.ref.detectChanges()

        await Misc.wait(0.01)

        this.message = await this.mixingOptions.onComplete(this.selectedOption)

        this.loading = false

        this.ref.detectChanges()
    }
}
