<div class="hub-search {{ deviceSpecificClass() }}" [style.width]="width">
    <img class="hub-search-icon" src="assets/img/search.svg" />
    <input
        class="hub-seach-input"
        [placeholder]="searchDefault"
        [(ngModel)]="search"
        (keyup)="startSearch()" />

    <img
        class="hub-search-icon"
        src="assets/img/delete.svg"
        *ngIf="search !== ''"
        (click)="clearSearch()" />
</div>
