import { LAYERS } from "../../../constants/constants"
import { LayersValue } from "../../../types/general"
import { FormDescriptionSchema } from "../../../interfaces/db-schemas/generationprofile"
import { StructureSettings } from './structuresettings'
import { VariationSettings } from './variationsettings'
import { FormDescriptionMusicEngine } from "../../../interfaces/music-engine/generationprofile"

export default class FormDescription {
    structureSettings:StructureSettings = new StructureSettings()
    variationSettings:VariationSettings = new VariationSettings()

    percussionDevelopment:{ name:string, value:string } = { name: "", value: "" }
    developmentTension:{ name:string, value:string } = { name: "", value: "" }
    layeringStrategy:{ name:string, value:string } = { name: "", value: "" }
    dynamicsStrategy:{ name:string, value:string } = { name: "", value: "" }
    filteringStrategy:{ name:string, value:string } = { name: "", value: "" }

    constructor(args?) {
        if (args == null) {
            return
        }

        for (let key in args) {
            this[key] = args
        }
    }

    static fromJSON(object) {
        let formDescription:FormDescription = Object.assign(new FormDescription(), object)

        formDescription.structureSettings = StructureSettings.fromJSON(object['structureSettings'])
        formDescription.variationSettings = VariationSettings.fromJSON(object['variationSettings'])

        return formDescription
    }

    static getLayerTypes(excludeList) {
        let results:Array<LayersValue> = []

        for (let layer of LAYERS) {
            let add = true

            for (let exclude of excludeList) {
                if (layer.includes(exclude)) {
                    add = false
                    break
                }
            }

            if (add) {
                results.push(layer)
            }
        }

        return results
    }

    encodeForME():FormDescriptionMusicEngine {
        const materialVariations = this.variationSettings.materialVariations

        return {
            structure_settings: {
                subsequence_length: this.encodeSubsequenceLength(),
                partial_sections: this.structureSettings.partialSections ?
                    "basic" : "none",
                max_themes: this.structureSettings.maxThemes.value
            },

            development_tension: this.developmentTension.value,
            layering_strategy: this.layeringStrategy.value,
            dynamics_strategy: this.dynamicsStrategy.value,
            filtering_strategy: this.filteringStrategy.value,
            variation_settings: {
                material_variations: {
                    likelihood: materialVariations.likelihood,
                    categories: materialVariations.encodeCategories(),
                    layers: materialVariations.encodeLayers(),
                    strategy: materialVariations.strategy.value
                },

                harmonic_variation: this.variationSettings.harmonicVariation ?
                    "basic" : "none",

                key_shift: this.variationSettings.keyShift ?
                    "basic" : "none",

                orchestration_variation: this.variationSettings.orchestrationVariation ?
                    "basic" : "none"
            },
            percussion_development: this.percussionDevelopment ?
                    "basic" : "none"
        }
    }

    encodeSubsequenceLength() {
        const length = this.structureSettings.subsequenceLength

        if (length == 0) {
            return "short"
        }

        else if (length == 1) {
            return "medium"
        }

        return "long"
    }

    encodeForDB():FormDescriptionSchema {
        const materialVariations = this.variationSettings.materialVariations

        return {
            structureSettings: {
                subsequenceLength: this.encodeSubsequenceLength(),
                partialSections: this.structureSettings.partialSections ?
                    "basic" : "none",
                maxThemes: this.structureSettings.maxThemes.value
            },
        
            variationSettings: {
                materialVariations: {
                    likelihood: materialVariations.likelihood,
                    categories: materialVariations.encodeCategories(),
                    layers: materialVariations.encodeLayers(),
                    strategy: materialVariations.strategy.value
                },
                harmonicVariation: this.variationSettings.harmonicVariation ?
                    "basic" : "none",
                keyShift: this.variationSettings.keyShift ?
                    "basic" : "none",
                orchestrationVariation: this.variationSettings.orchestrationVariation ?
                    "basic": "none"
            },
        
            percussionDevelopment: this.percussionDevelopment.value,
            developmentTension: this.developmentTension.value,
            layeringStrategy: this.layeringStrategy.value,
            dynamicsStrategy: this.dynamicsStrategy.value,
            filteringStrategy: this.filteringStrategy.value
        }
    }
}