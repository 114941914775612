import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
@Component({
    selector: "reward-claimed",
    templateUrl: "./reward-claimed.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardClaimedComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter()
    @Input() reward: string

    constructor(protected ref: ChangeDetectorRef) {}

    ngOnInit() {}

    closeModal() {
        this.close.emit()
    }
}
