import {
    GP_HARMONY_STRATEGY,
    HARMONY_MODES,
    MODES_TO_MODAL_TYPE_CORRESPONDENCE,
    PITCH_CLASSES_FOR_MODES,
} from "../constants/constants"
import {
    GPHarmonyStrategy,
    ModalMode,
    FunctionalMode,
} from "../types/generationProfiles"
import { isEqual, reduce } from "lodash"

const snakeCase = require("snakecase-keys")
const camelCase = require("camelcase-keys")
import * as yieldableJSON from "yieldable-json"
import { FractionString } from "../types/score"
import { Time } from "./time"
import { cloneDeep } from "lodash"
interface Boundary {
    start: FractionString
    end: FractionString
}
export module Misc {
    /**
     * Turn [[1, 2, 3], [4, 5, 6]] into [[1, 4], [2, 5], [3, 6]]
     * similarly to the Python zip function
     * @param rows
     * @returns
     */
    export function zip<A, B>(rows: [A[], B[]]): [A, B][] {
        return rows[0].map((_, c) => rows.map(row => row[c]) as [A, B])
    }

    /**
     * Given an array, split it into chunks of a specific size.
     * Example [1,2,3,4,5], 2 -> [[1,2], [3,4], [5]]
     */
    export function arrayToChunks<T>(arr: T[], size: number): T[][] {
        return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
            arr.slice(i * size, i * size + size)
        )
    }

    export function clamp(value, min, max) {
        return Math.min(Math.max(value, min), max)
    }

    export function sum(values: number[]): number {
        return values.reduce((partialSum, a) => partialSum + a, 0)
    }

    export function isTrueForAll(array, functionBinding) {
        for (const elem of array) {
            if (!functionBinding(elem)) {
                return false
            }
        }

        return true
    }

    export function promiseWithTimeout(promise: Promise<any>, seconds: number) {
        return Promise.race([promise, Misc.wait(seconds).then(() => "timeout")])
    }

    export function isFloat(n: number) {
        return n - Math.floor(n) !== 0
    }

    export function levenshteinDistance(s: string, t: string) {
        if (!s.length) return t.length
        if (!t.length) return s.length

        const arr = []

        for (let i = 0; i <= t.length; i++) {
            arr[i] = [i]

            for (let j = 1; j <= s.length; j++) {
                arr[i][j] =
                    i === 0
                        ? j
                        : Math.min(
                              arr[i - 1][j] + 1,
                              arr[i][j - 1] + 1,
                              arr[i - 1][j - 1] +
                                  (s[j - 1] === t[i - 1] ? 0 : 1)
                          )
            }
        }

        return arr[t.length][s.length]
    }

    export function shuffle(array) {
        let currentIndex = array.length,
            randomIndex

        // While there remain elements to shuffle.
        while (currentIndex > 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex)
            currentIndex--

            // And swap it with the current element.
            ;[array[currentIndex], array[randomIndex]] = [
                array[randomIndex],
                array[currentIndex],
            ]
        }

        return array
    }

    export async function retry({
        functionToRetry,
        retries,
        waitBetweenRetries,
        doAfterError,
    }: {
        functionToRetry: Function
        retries: number
        waitBetweenRetries: number
        doAfterError?: Function
    }) {
        const originalRetries = retries

        while (retries > 0) {
            try {
                const result = await functionToRetry(retries)
                return result
            } catch (e) {
                console.error(
                    "Function failed to execute: ",
                    functionToRetry.name,
                    e
                )
            }

            retries--

            await Misc.wait(waitBetweenRetries)
        }

        if (doAfterError) {
            await doAfterError()
        }

        throw new Error(
            "Function failed after " + originalRetries + " retries. "
        )
    }

    export function getAllMethodNames(obj) {
        let methods = new Set()

        while ((obj = Reflect.getPrototypeOf(obj))) {
            let keys = Reflect.ownKeys(obj)
            keys.forEach(k => methods.add(k))
        }

        return methods
    }

    /**
     * Converts a number of seconds in integer format to string format
     * like so: 120 -> 2:00
     */
    export function convertSecondsToString(seconds: number): string {
        if (seconds == null) {
            seconds = 0
        }

        let minutes = Math.floor(seconds / 60)
        seconds = Math.floor(seconds % 60)

        let duration = minutes + ":"

        if (seconds < 10) {
            duration = duration + "0"
        }

        duration = duration + seconds

        return duration
    }

    export function getObjectDiff(a, b) {
        reduce(
            a,
            (result, value, key) => {
                return isEqual(value, b[key]) ? result : result.concat(key)
            },
            []
        )
    }

    export function getAllKeySignatures() {
        let keySignatures = []

        for (let strategy of GP_HARMONY_STRATEGY) {
            const ksObject = getKeySignatureOptions(strategy)

            for (let mode in ksObject) {
                keySignatures = keySignatures.concat(ksObject[mode])
            }
        }

        return keySignatures
    }

    export function inRange(
        value: number,
        range: [number, number],
        inclusive: boolean = true
    ): boolean {
        const newRange = [
            Math.min(range[0], range[1]),
            Math.max(range[0], range[1]),
        ]

        if (inclusive) {
            return value >= newRange[0] && value <= newRange[1]
        }

        return value > newRange[0] && value < newRange[1]
    }

    export async function asyncForLoop(array, functionBinding) {
        for (let elem of array) {
            await functionBinding(elem)
            await Misc.wait(0)
        }
    }

    export function arrayHasDuplicate(array) {
        const set = new Set(array)

        return array.length !== set.size
    }

    /**
     * For a given array, get all possible combinations of a given length
     * e.g. combinations('ABCD', 2) --> AB AC AD BC BD CD
     */
    export function combinations<T>(iterable: T[], r: number): T[][] {
        if (r > iterable.length) {
            r = iterable.length
        }

        function helper(source: T[], current: T[], startIndex: number): T[][] {
            if (current.length === r) {
                return [current]
            }

            let result: T[][] = []
            for (let i = startIndex; i < source.length; i++) {
                const newCurrent = [...current, source[i]]
                result = result.concat(helper(source, newCurrent, i + 1))
            }
            return result
        }

        return helper(iterable, [], 0)
    }

    export function createArray(length: number) {
        return Array.from(Array(length))
    }

    export function combos(list, n = 0, result = [], current = []) {
        if (n === list.length) result.push(current)
        else
            list[n].forEach(item =>
                combos(list, n + 1, result, [...current, item])
            )

        return result.filter(value => !Misc.arrayHasDuplicate(value))
    }

    export function getKeySignatureOptions(
        harmonicStrategy: GPHarmonyStrategy
    ) {
        if (!harmonicStrategy) {
            harmonicStrategy = "Functional"
        }

        const modes = HARMONY_MODES[harmonicStrategy]
        let keySignatures = {}

        for (let mode of modes) {
            const pitchClasses = getPitchClassOptions(mode, harmonicStrategy)

            keySignatures[mode] = pitchClasses.map(pitchClass => {
                return pitchClass + " " + mode
            })
        }

        return keySignatures
    }

    export function getKeyModeOptions(
        harmonicStrategy: GPHarmonyStrategy
    ): (FunctionalMode | ModalMode)[] {
        return HARMONY_MODES[harmonicStrategy]
    }

    export function getPitchClassOptions(
        mode: ModalMode | FunctionalMode,
        harmonicStrategy: GPHarmonyStrategy
    ): string[] {
        let modeType

        if (harmonicStrategy === "Modal") {
            modeType = MODES_TO_MODAL_TYPE_CORRESPONDENCE.minor.includes(mode)
                ? "minor"
                : "major"
        } else {
            modeType = mode
        }

        return PITCH_CLASSES_FOR_MODES[modeType].sort()
    }

    export function pythonMod(n, base) {
        return n - Math.floor(n / base) * base
    }

    export async function bufferToJSON(buffer, parse, convertToCamelCase) {
        var json = buffer.toString("utf8")

        if (parse) {
            json = JSON.parse(json)
        }

        let temp = json

        if (convertToCamelCase) {
            temp = Misc.toCamelCase(json)
        }

        return temp
    }

    export function stringIsNull(string) {
        if (string == "undefined") {
            return true
        } else if (string == null) {
            return true
        }

        return false
    }

    export function wait(seconds) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(null)
            }, seconds * 1000)
        })
    }

    export function parse(value: string) {
        return new Promise((resolve, reject) => {
            yieldableJSON.parseAsync(value, (err, data) => {
                if (err) {
                    return reject(err)
                }

                return resolve(data)
            })
        })
    }

    export function stringify(value) {
        return new Promise((resolve, reject) => {
            yieldableJSON.stringifyAsync(value, (err, data) => {
                if (err) {
                    console.error(err)
                    return reject(err)
                }

                return resolve(data)
            })
        })
    }

    export function formatDate(d, useSlash = true) {
        if (d == null) {
            return "-"
        }

        d = new Date(d)

        var result = ""

        if (d.getDate() < 10) {
            result += "0"
        }

        let separator = "/"

        if (!useSlash) {
            separator = "-"
        }

        result += d.getDate() + separator

        if (d.getMonth() < 9) {
            result += "0"
        }

        result += d.getMonth() + 1 + separator + d.getFullYear() + " - "

        if (d.getHours() < 10) {
            result += "0"
        }

        result += d.getHours() + "h"

        if (d.getMinutes() < 10) {
            result += "0"
        }

        result += d.getMinutes()

        return result
    }

    export function roundDecimal(number, numberOfDecimals) {
        number = Math.floor(number * Math.pow(10, numberOfDecimals))
        number = number / Math.pow(10, numberOfDecimals)

        return number
    }

    /**
     *
     * @param array1 an array of primitives
     * @param array2 another array of primitives
     */
    export function arraysSimilarity(array1: Array<any>, array2: Array<any>) {
        let numberOfValuesEqual = 0

        if (array1.length == 0 && array2.length == 0) {
            return 1
        }

        for (let value1 of array1) {
            let exists = false

            for (let value2 of array2) {
                if (value1 == value2) {
                    exists = true
                    break
                }
            }

            if (exists) {
                numberOfValuesEqual += 1
            }
        }

        return numberOfValuesEqual / Math.max(array1.length, array2.length)
    }

    /**
     *
     * @param value
     * @param range:Array<number> an array containing two number values
     */
    export function isInRange(value, range) {
        if (range.length != 2) {
            return false
        }

        return value >= range[0] && value <= range[1]
    }

    /**
     *
     * @param value
     * @param range:Object: {min: number, max: number} an Object containing two number values
     */
    export function isInMinMaxRange(value, range: { min; max }) {
        if (Object.keys(range).length != 2) {
            return false
        }

        return value >= range.min && value <= range.max
    }

    export function arraysAreEqual(array1, array2) {
        if (array1.length === array2.length) {
            return array1.every(element => {
                if (array2.includes(element)) {
                    return true
                }

                return false
            })
        }

        return false
    }

    export function getRandomItem<T>(arr: T[]): T {
        if (!arr) {
            return undefined
        }

        // get random index value
        const randomIndex = getRandomIntInclusive(0, arr.length - 1)

        // get random item
        const item = arr[randomIndex]

        return item
    }

    export function getSubarray(array, fromIndex, toIndex) {
        return array.slice(fromIndex, toIndex + 1)
    }

    /**
     * This function can be used to evaluate whether to objects (dictionaries) are equal or not
     * @param object1
     * @param object2
     */
    export function objectsAreEqual(object1, object2) {
        var keys1 = Object.keys(object1)
        var keys2 = Object.keys(object2)

        if (keys1.length != keys2.length) {
            return false
        }

        for (var k = 0; k < keys1.length; k++) {
            var value1 = object1[keys1[k]]
            var value2 = object2[keys1[k]]

            if (Misc.isDictionary(value1)) {
                if (
                    !Misc.isDictionary(value2) ||
                    !objectsAreEqual(value1, value2)
                ) {
                    return false
                }
            } else if (Array.isArray(value1)) {
                if (!Array.isArray(value2) || value1.length != value2.length) {
                    return false
                }

                for (var a = 0; a < value1.length; a++) {
                    if (!objectsAreEqual(value1[a], value2[a])) {
                        return false
                    }
                }
            } else if (value1 != value2) {
                return false
            }
        }

        return true
    }

    export function isDictionary(variable) {
        return (
            typeof variable === "object" &&
            !Array.isArray(variable) &&
            variable !== null
        )
    }

    export function getAirtableBase(base, table, query) {
        var result = []

        return new Promise((resolve, reject) => {
            base(table)
                .select(query)

                .eachPage(
                    function page(records, fetchNextPage) {
                        records.forEach(record => {
                            var object = {}

                            for (var field in record.fields) {
                                object[field] = record.get(field)
                            }

                            object["id"] = record.id

                            result.push(object)
                        })

                        fetchNextPage()
                    },
                    function done(err) {
                        if (err) {
                            reject(err)
                        } else {
                            resolve(result)
                        }
                    }
                )
        })
    }

    export function toCamelCase(object) {
        object = camelCase(object)

        if (object.layers != null) {
            var layers = {}

            for (var layer in object.layers) {
                layers[object.layers[layer].value] = object.layers[layer]
            }

            object.layers = layers
        }

        return object
    }

    export function toSnakeCase(object, deep = true) {
        object = snakeCase(object, { deep: deep })

        if (object.layers != null) {
            var layers = {}

            for (var layer in object.layers) {
                layers[object.layers[layer].value] = object.layers[layer]
            }

            object.layers = layers
        }

        return object
    }

    export function getRandomIntInclusive(min, max, excludeList = []) {
        min = Math.ceil(min)
        max = Math.floor(max)

        var value = Math.random() * (max - min + 1)

        if (excludeList.length > 0) {
            let maxTries = 100000

            while (excludeList.includes(value) && maxTries >= 0) {
                value = getRandomIntInclusive(min, max, [])

                maxTries -= 1
            }
        }

        return Math.floor(value) + min // The maximum is inclusive and the minimum is inclusive
    }

    export function replaceAll(str, find, replace) {
        return str.replace(new RegExp(escapeRegExp(find), "g"), replace)
    }

    export function escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
    }

    export function replaceMultipleWhitespaces(value: string): string {
        return value.replace(/ +(?= )/g, "")
    }

    export function capitalizeFirstLetter(string): string {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    export function arrayToJSONL(data: any[]) {
        return data.map(x => JSON.stringify(x)).join("\n")
    }

    /**
     * Returns the closest number from an array
     * @param array the array used to find the closest value
     * @param value the function returns the closest possible value to this value
     * @param greaterThan the return value has to be greater than this value
     * @returns found value or undefined if no value could be found
     */
    export function findClosestValue(
        array,
        value,
        options?: {
            greaterThan?: number
            lessThan?: number
        }
    ) {
        if (value == null || array == null || Array.isArray(array) == false) {
            return undefined
        }

        if (options?.lessThan) {
            array = array.filter(p => {
                return p < options.lessThan
            })
        }

        if (options?.greaterThan) {
            array = array.filter(p => {
                return p > options.greaterThan
            })
        }

        if (array.length == 0) {
            return undefined
        }

        // This returns the greatest, nearest value to the value given
        // for more info, check out: https://www.gavsblog.com/blog/find-closest-number-in-array-javascript
        return array.reduce((prev, curr) => {
            let prevDiff = Math.abs(prev - value)
            let currDiff = Math.abs(curr - value)

            if (prevDiff == currDiff) {
                return prev > curr ? prev : curr
            } else {
                return currDiff < prevDiff ? curr : prev
            }
        })
    }

    export function shuffleArray<T>(array: T[]): T[] {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1))
            var temp = array[i]
            array[i] = array[j]
            array[j] = temp
        }

        return array
    }

    export function insertWithNoOverlap<T extends Boundary>(
        array: T[],
        newObj: T
    ): T[] {
        // Sort the array by the start attribute
        array.sort((a, b) =>
            Time.compareTwoFractions(a.start, b.start) == "lt" ? -1 : 1
        )

        for (let a = array.length - 1; a >= 0; a--) {
            const value = array[a]
            const result = Time.rangesAreOverlapping(value, newObj)

            if (!result.overlap) {
                continue
            }

            if (result.isFullOverlap) {
                // if the value is fully overlapping the new object, we get rid of the value
                const valueIsFullyOverlapping =
                    Time.compareTwoFractions(
                        value.start,
                        result.overlapRange.start
                    ) === "eq" &&
                    Time.compareTwoFractions(
                        value.end,
                        result.overlapRange.end
                    ) === "eq"

                if (valueIsFullyOverlapping) {
                    array.splice(a, 1)
                    continue
                }

                if (
                    Time.compareTwoFractions(value.start, newObj.start) == "eq"
                ) {
                    value.start = newObj.end
                } else if (
                    Time.compareTwoFractions(value.end, newObj.end) == "eq"
                ) {
                    value.end = newObj.start
                } else {
                    const newValue = cloneDeep(value)
                    newValue.end = result.overlapRange.start
                    array.splice(a, 0, newValue)

                    value.start = result.overlapRange.end
                }
            } else if (
                Time.fractionIsInBoundaries(
                    {
                        start: value.start,
                        duration: Time.addTwoFractions(
                            value.end,
                            value.start,
                            true
                        ),
                    },
                    newObj.end,
                    false,
                    true
                )
            ) {
                value.start = newObj.end
            } else if (
                Time.fractionIsInBoundaries(
                    {
                        start: value.start,
                        duration: Time.addTwoFractions(
                            value.end,
                            value.start,
                            true
                        ),
                    },
                    newObj.start,
                    true,
                    false
                )
            ) {
                value.end = newObj.start
            }
        }

        array.push(newObj)

        // Sort the array by the start attribute
        array.sort((a, b) =>
            Time.compareTwoFractions(a.start, b.start) == "lt" ? -1 : 1
        )

        return array
    }

    /**
     * filters an array of objects and returns an array that contains unique values by the given key
     * e.g. an array of compositions with the compositionID existing multiple times, could be filtered
     * to only return each composition once, filtered by the key "_id"
     * @param arr
     * @param key
     * @returns
     */
    export function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    /**
     * returns all elements of array1 that are also included in array2
     * @param array1
     * @param array2
     */
    export function intersection(array1, array2) {
        let intersection = array1.filter(value => array2.includes(value))

        return [...new Set(intersection)]
    }

    /**
     * returns all elements of array1 that are not included in array2
     * @param array1
     * @param array2
     */
    export function difference(array1, array2) {
        let difference = array1.filter(value => !array2.includes(value))

        return [...new Set(difference)]
    }

    /**
     * function to wait for a certain amount of time before continuing
     * @param ms time to wait before resolving
     * @returns Promise (true)
     */
    export function sleep(ms) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                return resolve(true)
            }, ms)
        })
    }

    export function getTimeDifferenceInSeconds(oldTimeStamp, newTimeStamp) {
        return (parseInt(newTimeStamp) - parseInt(oldTimeStamp)) / 1000
    }

    export function renameObjectKey(object, oldKey, newKey) {
        if (
            object != null &&
            oldKey !== newKey &&
            object[oldKey] &&
            !object[newKey]
        ) {
            Object.defineProperty(
                object,
                newKey,
                Object.getOwnPropertyDescriptor(object, oldKey)
            )
            delete object[oldKey]
        }

        return object
    }

    export function getUniqueArray(array) {
        if (array == null || array.length == 0) {
            return
        }

        return [...new Set(array)]
    }

    /**
     * checks for a given sequence of nested keys if there is a null or undefined
     * @param obj Object to check for null keys
     * @param key string of a nested key
     * @returns boolean
     * @info https://stackoverflow.com/a/23809123
     */
    export function checkNestedObject(obj, key) {
        return key.split(".").every(x => {
            if (typeof obj != "object" || obj === null || !(x in obj)) {
                return false
            }

            obj = obj[x]

            return true
        })
    }

    export function formatBytes(bytes, targetFormat = "MB", decimals = 3) {
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
        const index = sizes.findIndex(s => s === targetFormat)

        if (index == -1) {
            throw new Error(
                "Please enter a valid format. Valid formats are: " +
                    sizes.join(", ")
            )
        }

        return parseFloat((bytes / Math.pow(1024, index)).toFixed(decimals))
    }
}
