import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    ChangeDetectionStrategy,
} from "@angular/core"
import { Colors } from "@common-lib/types/layer"
import { Color, ColorPickerControl, ColorsTable } from "@iplab/ngx-color-picker"
import { LayerModal } from "@services/modal.service"

@Component({
    selector: "change-layer-color",
    templateUrl: "change-layer-color.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLayerColorComponent implements OnInit {
    @Input() layerModal: LayerModal
    @Output() close: EventEmitter<any> = new EventEmitter()

    public chromeControl = new ColorPickerControl()

    constructor() {}

    ngOnInit() {
        this.chromeControl
            .setValueFrom(ColorsTable.aquamarine)
            .hidePresets()
            .hideAlphaChannel()
            .setValueFrom(this.layerModal.layer.getColor())
    }

    closeModal() {
        this.close.emit()
    }

    changeLayerColor() {
        const color:Colors = {
            red: this.chromeControl.value.getRgba().red.toString(),
            green: this.chromeControl.value.getRgba().green.toString(),
            blue: this.chromeControl.value.getRgba().blue.toString()
        }

        this.layerModal.onComplete(color, this.layerModal.layer)

        this.close.emit()
    }
}
