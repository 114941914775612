<div
    class="loudness-meter"
    [style.width]="width"
    [tooltip]="trackBusses[0].gainOffset"
    [tooltip-type]="'onmousedown'"
    [tooltip-position]="'middle-bottom'"
    [tooltip-marginTop]="7">
    <div class="overlay"></div>
    <input
        type="range"
        [min]="minGain"
        [max]="maxGain"
        [(ngModel)]="trackBusses[0].gainOffset"
        [attr.av-data]="'loudness-meter-' + trackBusses[0].id"
        step="1"
        class="slider"
        #input
        (input)="updateRealtimeSamplerGain(trackBusses)"
        (dblclick)="updateGain(0, trackBusses)"
        (change)="updateGain(trackBusses[0].gainOffset, trackBusses)"
        (mouseup)="looseFocus()"
        (mouseleave)="looseFocus()" />

    <div class="levels">
        <div class="left">
            <div class="meter">
                <div
                    class="fill"
                    [ngStyle]="{
                        width:
                            'calc(100% - ' +
                            getInstrumentVolumePercentage(trackBusses, 'left') +
                            '%)'
                    }"></div>
            </div>
        </div>

        <div class="right">
            <div class="meter">
                <div
                    class="fill"
                    [ngStyle]="{
                        width:
                            'calc(100% - ' +
                            getInstrumentVolumePercentage(trackBusses, 'right') +
                            '%)'
                    }"></div>
            </div>
        </div>
    </div>
</div>
