<div class="modal-title">Create a style</div>
<div class="modal-subtitle">
    Select a mood to use as template for your generation profile
</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<div class="create-gp-content">
    <emotion-select
        [type]="'create-gp'"
        [emotions]="emotions"
        [showWarning]="true"
        (onChange)="onEmotionChange($event)"></emotion-select>
</div>
