import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
    ViewChild,
} from "@angular/core"
import SearchItem from "@common-lib/classes/searchitem"
import TrackBus from "../../../../../../../common-lib/general/classes/score/trackbus"
import { GeneralService } from "@services/general/general.service"
import { SearchInstrumentPreviewService } from "@services/searchinstrumentpreview.service"
import { RecommendationsService } from "@services/recommendations.service"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { WindowService } from "@services/window.service"
import { InstrumentsDownloadService } from "@services/instruments/instrumentsDownload.service"
import { ParentClass } from "../../../../parent"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import { InstrumentSelectionModalParameters } from "@services/modal.service"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import Layer from "@common-lib/classes/score/layer"

@Component({
    selector: "instrumentsearch",
    templateUrl: "instrumentsearch.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstrumentSearchComponent extends ParentClass {
    @ViewChild("scrollbar", { static: false }) scrollbar

    @Input() parameters: InstrumentSelectionModalParameters

    @Output() close: EventEmitter<any> = new EventEmitter()

    searchResults = []
    selectedSearchResult: SearchItem = null

    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    options = { autoHide: false }

    selectedInstruments = null

    hoveredResult = null

    allInstrumentsAreDownloaded = false

    constructor(
        private instruments: InstrumentsService,
        private searchInstrumentPreviewService: SearchInstrumentPreviewService,
        private ref: ChangeDetectorRef,
        private windowService: WindowService,
        private recommendationsService: RecommendationsService
    ) {
        super()
    }

    async ngOnInit() {
        this.subscribe(
            this.instruments.query.select("refreshSearchUI"),
            value => {
                this.ref.detectChanges()
            }
        )

        this.initRecommendationsSubscriptions()

        this.selectedInstruments = this.getInstrumentSelectionPalette()
        this.selectedSearchResult = this.setSelectedSearchResult()

        this.scrollLeft()

        this.ref.detectChanges()
    }

    private initRecommendationsSubscriptions() {
        if (
            this.parameters.allowRecommendations === false ||
            this.parameters.layer instanceof Layer
        ) {
            return
        }

        this.recommendationsService.recommendationsChanged.subscribe(value => {
            if (!value) {
                return
            }

            if (this.parameters.layer.type === "pitched") {
                this.recommendationsService.getTaggedInstruments({
                    type: "pitched",
                    instruments: this.instruments.instrumentsOrganizedByPath,
                    layer: this.parameters.layer as GPLayer,
                    pack: this.parameters.pack,
                })
            } else {
                this.recommendationsService.getTaggedInstruments({
                    type: "percussion",
                    instruments: this.instruments.percussionOrganizedByPath,
                    layer: this.parameters.layer as GPLayer,
                    pack: this.parameters.pack,
                })
            }

            this.instruments.setRefreshSearchUI()
        })
    }

    getPreviewIcon(data) {
        let item: SearchItem = data.value

        if (
            this.searchInstrumentPreviewService.currentlyPlayingPatchID ==
            item.item.patchID
        ) {
            return "assets/img/player/pause.svg"
        }

        return "assets/img/player/play.svg"
    }

    previewIsLoading() {
        return this.searchInstrumentPreviewService.loading
    }

    printSearchResultPath(result) {
        if (result.value.path != null) {
            return result.value.path + "/" + result.value.name
        }

        return result.value.name
    }

    closeSearchModal() {
        this.close.emit(0)

        this.parameters.onClose()
    }

    getInstrumentSelectionPalette() {
        if (this.parameters.layer.type === "percussion") {
            if (
                this.parameters.allowRecommendations &&
                this.parameters.layer instanceof GPLayer
            ) {
                this.recommendationsService.getTaggedInstruments({
                    type: "percussion",
                    instruments: this.instruments.percussionOrganizedByPath,
                    layer: this.parameters.layer,
                    pack: this.parameters.pack,
                })
            }

            return this.instruments.percussionOrganizedByPath
        }

        if (
            this.parameters.allowRecommendations &&
            this.parameters.layer instanceof GPLayer
        ) {
            this.recommendationsService.getTaggedInstruments({
                type: "pitched",
                instruments: this.instruments.instrumentsOrganizedByPath,
                layer: this.parameters.layer,
                pack: this.parameters.pack,
            })
        }

        return this.instruments.instrumentsOrganizedByPath
    }

    private setSelectedSearchResultCore(
        instruments: Readonly<SearchItem[]>,
        selectedItem
    ) {
        if (selectedItem == null) {
            return null
        }

        for (var i = 0; i < instruments.length; i++) {
            if (instruments[i].values != null) {
                var result = this.setSelectedSearchResultCore(
                    instruments[i].values,
                    selectedItem
                )

                if (result != null) {
                    return result
                }
            } else if (selectedItem.name == instruments[i].item.patchID) {
                return instruments[i]
            }
        }

        return null
    }

    setSelectedSearchResult(
        instruments = this.getInstrumentSelectionPalette()
    ) {
        return this.setSelectedSearchResultCore(
            instruments,
            this.parameters.selectedTrackBus
        )
    }

    selectedInstrument(event, element) {
        this.selectedSearchResult = event.element

        this.scrollLeft()

        if (event.element != null && event.element.values == null) {
            const result = this.parameters.selectNewInstrumentPatch(
                event.element.item
            )

            if (result) {
                this.closeSearchModal()
            }
        }

        this.ref.detectChanges()
    }

    selectSearchResult(result) {
        this.selectedSearchResult = result.value
        this.searchResults = []

        this.scrollLeft()

        if (
            result != null &&
            result.value != null &&
            result.value.values == null
        ) {
            const shouldClose = this.parameters.selectNewInstrumentPatch(
                result.value.item
            )

            if (shouldClose) {
                this.closeSearchModal()
            }
        }
    }

    scrollLeft() {
        setTimeout(() => {
            if (this.scrollbar != null && this.scrollbar.SimpleBar != null) {
                this.scrollbar.SimpleBar.getScrollElement().scrollLeft =
                    this.scrollbar.SimpleBar.getScrollElement().scrollWidth +
                    1500
            }
        }, 1)
    }

    isDesktopApp() {
        return this.windowService.desktopAppAPI !== undefined
    }

    playPreview(event, searchItem) {
        this.searchInstrumentPreviewService.playPreview(
            searchItem,
            event,
            searchItem
        )
    }

    public setSearchResults(event) {
        this.searchResults = event
    }
}
