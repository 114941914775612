import HarmonyPack from "./harmonypack"
import { gpOptions } from "../../../constants/gp_options"
const options = gpOptions["harmony"]
import GPValidation from "../gpvalidation"
import { Misc } from "../../../modules/misc"
import GPInfluenceLoading from "../influences/gpinfluenceloading"
import { HarmonyPackMusicEngine } from "../../../interfaces/music-engine/generationprofile"
import { HarmonySchema } from "../../../interfaces/db-schemas/generationprofile"
import { GPHarmonyStrategy } from "../../../types/generationProfiles"
import { HARMONY_MODES } from "../../../constants/constants"
import { cloneDeep } from "lodash"
import { KeySignature } from "../../../interfaces/score/keySignature"
export default class Harmony {
    static HARMONIC_REPETITION_OPTIONS = options.harmonicRepetition
    static HARMONIC_RHYTHM_OPTIONS = options.harmonicRhythm
    static HARMONIC_REPETITION_DISPLAY_VALUES =
        options.harmonicRepetitionDisplayValues
    static HARMONIC_RHYTHM_DISPLAY_VALUES = options.harmonicRhythmDisplayValues

    gpInfluenceLoading: GPInfluenceLoading = new GPInfluenceLoading(0, "")
    name: string = "Harmony"
    packs: HarmonyPack[]

    public strategy: GPHarmonyStrategy = "Functional"
    public keySignature: KeySignature = {
        pitchClass: "C",
        keyMode: "major",
    }

    constructor() {}

    setStrategy(sourcePacks: HarmonyPack[], strategy: GPHarmonyStrategy) {
        this.strategy = strategy
        this.selectCompatibleKeySignature()

        const harmonicRepetition = Harmony.getAggregatedHarmonicRepetition(
            this.packs
        )
        const harmonicRhythm = Harmony.getAggregatedHarmonicRhythm(this.packs)

        for (let pack of sourcePacks) {
            if (pack.packID.includes("diatonic")) {
                const newPack = HarmonyPack.fromJSON(cloneDeep(pack))

                newPack.harmonicRepetition = harmonicRepetition
                newPack.harmonicRhythm = harmonicRhythm                

                this.packs = [newPack]

                this.encodePackID(this.packs)

                break
            }
        }
    }

    static fromJSON(object) {
        let harmony = Object.assign(new Harmony(), object)
        harmony.gpInfluenceLoading = GPInfluenceLoading.fromJSON(
            object?.gpInfluenceLoading
        )

        if (object?.pack) {
            harmony.packs = [object.pack]
        }

        return harmony
    }

    /**
     * modifies the Harmony.packs and sets up the current harmony packs harmonic repetition and rhythm for each pack
     * @returns
     */
    public updatePacksHarmonicRhythmAndRepetition(
        harmonicRhythm,
        harmonicRepetition
    ): boolean {
        let packs = this.packs
        let updated = false

        for (let pack of packs) {
            if (harmonicRepetition) {
                if (
                    harmonicRepetition.min != pack.harmonicRepetition.min ||
                    harmonicRepetition.max != pack.harmonicRepetition.max
                ) {
                    updated = true
                    pack.harmonicRepetition = harmonicRepetition
                }
            }

            if (harmonicRhythm) {
                let value = harmonicRhythm

                if (pack.packID.includes("7th_chords_1")) {
                    value = {
                        min: 0,
                        max: 0,
                    }
                }

                if (
                    harmonicRhythm.min != pack.harmonicRhythm.min ||
                    harmonicRhythm.max != pack.harmonicRhythm.max
                ) {
                    updated = true
                    pack.harmonicRhythm = harmonicRhythm
                }
            }
        }

        return updated
    }

    /**
     * When doing an action that may affect the compatibility between selected mode
     * and selected harmonic dataset (e.g. when switching harmony strategy or selecting a new dataset),
     * we should run this function to make sure that the key signature is always compatible
     */
    selectCompatibleKeySignature() {
        const ks = { ...this.keySignature }
        if (this.strategy === "Functional") {
            const modes: any[] = Harmony.getAllModes(this.packs, this.strategy)

            if (!modes.includes(this.keySignature.keyMode)) {
                const mode = Misc.getRandomItem(modes)

                this.setKeySignatureFromMode(mode)
            }
        } else {
            this.setKeySignatureFromMode(HARMONY_MODES[this.strategy][0])
        }
    }

    selectCompatiblePitchClass() {
        const options = Misc.getPitchClassOptions(
            this.keySignature.keyMode,
            this.strategy
        )

        this.keySignature.pitchClass = options[0]
    }

    setKeySignatureFromMode(mode) {
        const options = Misc.getPitchClassOptions(mode, this.strategy)

        const keySignature = Misc.getKeySignatureOptions(this.strategy)[mode][0]

        if (!keySignature) {
            return this.keySignature
        }

        this.keySignature.keyMode = mode

        if (options.includes(this.keySignature.pitchClass)) {
            return this.keySignature
        }

        this.keySignature.pitchClass = keySignature.split(" ")[0]

        return this.keySignature
    }

    getKeySignatureTitle(keySignature) {
        if (keySignature == null) {
            keySignature = this.keySignature
        }

        return keySignature.pitchClass + " " + keySignature.keyMode
    }

    static computeSimilarity(harmony1: Harmony, harmony2: Harmony) {
        let name = Harmony.computeNameSimilarity(harmony1, harmony2)
        let repetition = Harmony.computeHarmonicRepetitionSimilarity(
            harmony1,
            harmony2
        )
        let rhythm = Harmony.computeHarmonicRhythmSimilarity(harmony1, harmony2)
        let score = (name + repetition + rhythm) / 3

        return {
            score: score,
            report: {
                packName: name,
                repetition: repetition,
                rhythm: rhythm,
            },
        }
    }

    static computeHarmonicRepetitionSimilarity(
        harmony1: Harmony,
        harmony2: Harmony
    ) {
        let score = 0

        for (let pack1 of harmony1.packs) {
            for (let pack2 of harmony2.packs) {
                if (
                    pack1.harmonicRepetition.min ==
                        pack2.harmonicRepetition.min &&
                    pack1.harmonicRepetition.max == pack2.harmonicRepetition.max
                ) {
                    score += 1
                    break
                }
            }
        }

        score = score / Math.max(harmony1.packs.length, harmony2.packs.length)

        return score
    }

    static computeHarmonicRhythmSimilarity(
        harmony1: Harmony,
        harmony2: Harmony
    ) {
        let score = 0

        for (let pack1 of harmony1.packs) {
            for (let pack2 of harmony2.packs) {
                if (
                    pack1.harmonicRhythm.min == pack2.harmonicRhythm.min &&
                    pack1.harmonicRhythm.max == pack2.harmonicRhythm.max
                ) {
                    score += 1
                    break
                }
            }
        }

        score = score / Math.max(harmony1.packs.length, harmony2.packs.length)

        return score
    }

    static computeNameSimilarity(harmony1: Harmony, harmony2: Harmony) {
        let score = 0

        for (let pack1 of harmony1.packs) {
            for (let pack2 of harmony2.packs) {
                if (pack1.name == pack2.name) {
                    score += 1
                    break
                }
            }
        }

        score = score / Math.max(harmony1.packs.length, harmony2.packs.length)

        return score
    }

    encodeForME(): HarmonyPackMusicEngine {
        return {
            pack_id: this.getPackID(),
            key_mode: this.keySignature.keyMode.toLowerCase(),
            pitch_class: this.keySignature.pitchClass,
        }
    }

    encodeForDB(): HarmonySchema {
        return {
            packID: this.getPackID(),
            keySignature: this.keySignature,
            strategy: this.strategy,
            gpInfluenceLoading: this.gpInfluenceLoading
                ? this.gpInfluenceLoading.encode()
                : undefined,
        }
    }

    getPackID() {
        let packID

        if (this.packs != null) {
            packID = this.packs.map(p => p.packID)
        } else if (this["packID"] != null) {
            packID = this["packID"]
        }

        if (typeof packID == "string") {
            packID = [packID]
        }

        return packID
    }

    validate(validateForUI = false) {
        let validation = new GPValidation(true, "harmony", this)
        let packsValidation = Harmony.validateHarmonyPacks(this.packs)

        validation.validations.push(packsValidation)

        if (packsValidation.valid == false) {
            validation.valid = false
        }

        if (validateForUI == false) {
            let keySignatureValidation = new GPValidation(
                true,
                "keySignature",
                this,
                "keySignature"
            )

            if (
                this.keySignature == null ||
                this.keySignature.keyMode == null ||
                this.keySignature.pitchClass == null
            ) {
                keySignatureValidation.valid = false
                keySignatureValidation.issue = "noKeySignature"
                keySignatureValidation.message =
                    "The whole key signature or parts of it are missing"
            }

            validation.validations.push(keySignatureValidation)

            if (keySignatureValidation.valid == false) {
                validation.valid = false
            }

            return validation
        }

        return validation
    }

    /**
     * checks all harmony packs of a GP Harmony and validates if the given mode is supported by at least one harmony pack
     * @param harmonyPacks HarmonyPack[]
     * @param mode string that is either major or minor
     * @returns
     */
    static validateHarmonyPackMode(
        harmonyPacks,
        mode,
        strategy: GPHarmonyStrategy
    ) {
        let validation = new GPValidation(
            true,
            "harmonyPackMode",
            this,
            "harmonyPackMode"
        )

        if (harmonyPacks == null || mode == null) {
            validation.issue = "invalidMode"
            validation.message = "No harmonic datasets or mode given."
            validation.valid = false

            return validation
        }

        let modes = Harmony.getAllModes(harmonyPacks, strategy)

        mode = mode.toLowerCase()

        let invertedMode = mode == "minor" ? "major" : "minor"

        if (!modes.includes(mode)) {
            let invalidMsg =
                "A key signature with the " +
                mode +
                " mode cannot be used in combination with the selected harmonic dataset. Please change the key signature to any with " +
                invertedMode +
                " mode."
            validation.issue = "invalidMode"
            validation.message = invalidMsg
            validation.valid = false
        }

        return validation
    }

    static validateHarmonyPacks(harmonyPacks) {
        let validation = new GPValidation(
            true,
            "harmonyPacks",
            harmonyPacks,
            "harmonyPacks"
        )

        if (harmonyPacks == null || harmonyPacks.length == 0) {
            validation.issue = "noPacks"
            validation.message =
                "No harmonic dataset selected. Please select at least one harmonic dataset."
            validation.valid = false
        }

        return validation
    }

    /**
     * encodes the packID based on the selected harmonic repetition and pacing
     * packs with a chords_group_id == 7th_chords_1 have to be handled differently
     * @param sourcePacks HarmonyPack[]
     */
    encodePackID(sourcePacks: HarmonyPack[]) {
        let harmony = this

        if (
            this["pack"] != null &&
            (this.packs == null || this.packs.length == 0)
        ) {
            harmony.packs = [this["pack"]]
        }

        for (let p = 0; p < this.packs.length; p++) {
            let packID = this.selectPackIDFromSourcePacks(
                this.packs[p],
                sourcePacks
            )

            if (packID != null) {
                harmony.packs[p].packID = packID
            } else {
                console.error(
                    "Couldnt find packID for: ",
                    harmony.packs[p].name,
                    " - ",
                    harmony.packs[p].harmonicRhythm,
                    " - ",
                    harmony.packs[p].harmonicRepetition
                )
            }
        }

        harmony["packID"] = [...new Set(harmony.packs.map(p => p.packID))]
    }

    selectPackIDFromSourcePacks(
        selectedPack: HarmonyPack,
        sourcePacks: HarmonyPack[]
    ): string | null {
        for (let pack of sourcePacks) {
            let equalHarmonicRepetition =
                pack.harmonicRepetition.min ===
                    selectedPack.harmonicRepetition.min &&
                pack.harmonicRepetition.max ===
                    selectedPack.harmonicRepetition.max
            let equalHarmonicRhythm =
                pack.harmonicRhythm.min === selectedPack.harmonicRhythm.min &&
                pack.harmonicRhythm.max === selectedPack.harmonicRhythm.max

            if (
                pack.chordsGroupID[0] == selectedPack.chordsGroupID[0] &&
                equalHarmonicRepetition &&
                equalHarmonicRhythm
            ) {
                return pack.packID
            }
        }

        return null
    }

    getKeyModeOptions(useAllStrategies?: boolean): string[] {
        let ksOptions = Misc.getKeySignatureOptions(this.strategy)

        if (useAllStrategies) {
            ksOptions = {
                ...Misc.getKeySignatureOptions("Functional"),
                ...Misc.getKeySignatureOptions("Modal"),
            }
        }

        return Object.keys(ksOptions)
    }

    getKeySignatureOptions(useAllStrategies?: boolean) {
        let options = []

        let ksOptions = Misc.getKeySignatureOptions(this.strategy)

        if (useAllStrategies) {
            ksOptions = {
                ...Misc.getKeySignatureOptions("Functional"),
                ...Misc.getKeySignatureOptions("Modal"),
            }
        }

        for (let opt in ksOptions) {
            for (let ks of ksOptions[opt]) {
                let object = {
                    pitchClass: ks.split(" ")[0],
                    keyMode: ks.split(" ")[1],
                }

                let modes = Harmony.getAllModes(this.packs, this.strategy)

                if (useAllStrategies) {
                    modes = Harmony.getAllModes(this.packs, "Functional")
                    modes = modes.concat(
                        Harmony.getAllModes(this.packs, "Modal")
                    )
                }

                options.push({
                    value: object,
                    name: ks,
                    optgroup: Misc.capitalizeFirstLetter(opt),
                    disabled: !modes.includes(object.keyMode.toLowerCase()),
                })
            }
        }

        return options
    }

    getPitchClassOptions() {
        const options = Misc.getPitchClassOptions(
            this.keySignature.keyMode.toLowerCase(),
            this.strategy
        )

        const pitchClassOptions = options.map(pitchClass => {
            return {
                value: pitchClass,
                name: pitchClass,
            }
        })

        return pitchClassOptions
    }

    static getPackVariantByPackID(packID) {
        return packID.split("_")[packID.split("_").length - 1]
    }

    static getPackVariantByHarmonicRhythmAndRepetition(
        harmonicRhythm,
        harmonicRepetition
    ) {
        let variant = 1

        if (harmonicRepetition == null || harmonicRhythm == null) {
            return variant
        }

        for (let p = 0; p < gpOptions.harmony.packs.length; p++) {
            let pack = gpOptions.harmony.packs[p]

            if (
                pack.harmonicRepetition.min == harmonicRepetition.min &&
                pack.harmonicRepetition.max == harmonicRepetition.max &&
                pack.harmonicRhythm.min == harmonicRhythm.min &&
                pack.harmonicRhythm.max == harmonicRhythm.max
            ) {
                variant = Harmony.getPackVariantByPackID(pack.id)

                break
            }
        }

        return variant
    }

    static getAllModes(
        harmonyPacks: HarmonyPack[],
        strategy: GPHarmonyStrategy
    ) {
        let allModes: string[] = []

        for (let pack of harmonyPacks) {
            let modes: string[] = []

            for (let mode of pack.mode) {
                if (
                    strategy === "Functional" &&
                    (mode === "minor" || mode === "major")
                ) {
                    modes.push(mode)
                }

                if (
                    strategy === "Modal" &&
                    mode !== "minor" &&
                    mode !== "major"
                ) {
                    modes.push(mode)
                }
            }

            allModes = allModes.concat(modes)
        }

        allModes = [...new Set(allModes)]

        return allModes
    }

    static getAggregatedHarmonicRepetition(harmonyPacks: HarmonyPack[]) {
        let defaultHarmonicRepetition = { min: 0, max: 0 }

        if (harmonyPacks == null || harmonyPacks.length == 0) {
            return defaultHarmonicRepetition
        }

        return {
            min: harmonyPacks[0].harmonicRepetition.min,
            max: harmonyPacks[0].harmonicRepetition.max,
        }
    }

    static getAggregatedHarmonicRhythm(harmonyPacks: HarmonyPack[]) {
        let defaultHarmonicRhythm = { min: 0, max: 2 }

        if (harmonyPacks == null || harmonyPacks.length == 0) {
            return defaultHarmonicRhythm
        } else if (
            harmonyPacks.length == 1 &&
            harmonyPacks[0].packID.includes("7th_chords_1")
        ) {
            return { min: 0, max: 0 }
        } else {
            for (let p = 0; p < harmonyPacks.length; p++) {
                if (!harmonyPacks[p].packID.includes("7th_chords_1")) {
                    const value = harmonyPacks[p].harmonicRhythm
                    return { min: value.min, max: value.max }
                }
            }
        }
    }

    static packNamesAreEqual(harmony1: Harmony, harmony2: Harmony) {
        if (
            !harmony1 ||
            !harmony2 ||
            !harmony1.packs ||
            !harmony2.packs ||
            harmony1.packs.length == 0 ||
            harmony2.packs.length == 0
        ) {
            return false
        }

        let packNames = harmony2.packs.map(p => p.name)

        for (let pack1 of harmony1.packs) {
            if (!packNames.includes(pack1.name)) {
                return false
            }
        }

        return true
    }
}
