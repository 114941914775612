import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef,
} from "@angular/core"
import { EventHandlers, Coordinates } from "@common-lib/modules/event-handlers"
import { Time } from "@common-lib/modules/time"
import { PlayerService } from "@services/audio/player/player.service"
import { GridDimensions } from "../../../../../../common-lib/client-only/score-rendering-engine"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import { ParentClass } from "../../../parent"
import { cloneDeep } from "lodash"
import {
    NOTE_QUANTIZATION_THRESHOLDS,
    TIMESTEP_RES,
} from "@common-lib/constants/constants"
import { Helpers } from "../../../modules/helpers.module"
import { ScoreManipulation } from "@common-lib/modules/scoremanipulation"
import { playerQuery } from "../../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { DesignService } from "@services/design.service"
import { Misc } from "@common-lib/modules/misc"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { fromEvent } from "rxjs/internal/observable/fromEvent"
import { debounceTime } from "rxjs/internal/operators/debounceTime"
@Component({
    selector: "play-head",
    templateUrl: "play-head.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["play-head.component.scss"],
})
export class PlayHeadComponent extends ParentClass {
    @Input() engine: ScoreRenderingEngine

    public leftOffset = 0
    private mousedown$
    private mousemove$
    private mouseup$
    private mouseDownStart: Coordinates | undefined

    constructor(
        private ref: ChangeDetectorRef,
        private playerService: PlayerService,
    ) {
        super()
    }

    async ngAfterViewInit() {
        this.initListeners()
    }

    private async initListeners() {
        this.mousemove$ = EventHandlers.getMouseEventObservable(
            "mousemove",
            document,
            10,
            this.destroy$,
            document.body
        )

        this.mouseup$ = EventHandlers.getMouseEventObservable(
            "mouseup",
            document,
            0,
            this.destroy$,
            document.body
        )

        this.subscribe(this.mousemove$, this.onMouseMove.bind(this))
        this.subscribe(this.mouseup$, this.onMouseUp.bind(this))

        this.subscribe(this.engine.scrollToTimestep$, async () => {
            if (playerQuery.status !== "playing") {
                await this.updateLeftOffset(playerQuery.timeElapsed)
            }
        })

        this.subscribe(this.engine.resizeFactor$, async () => {
            await this.updateLeftOffset(playerQuery.timeElapsed)
        })

        if (featureFlags?.enablePlayheadPerformanceImprovement) {
            this.subscribe(fromEvent(window, "resize").pipe(debounceTime(100)), async () => {
                await this.updateLeftOffset(playerQuery.timeElapsed)
            })
        }

        this.subscribe(
            playerQuery.select("timeElapsed"),
            this.updateLeftOffset.bind(this)
        )
    }

    public async onMouseDown(e: MouseEvent) {
        const elem = await Helpers.waitForElementByID("play-head-element")

        const event = EventHandlers.getContainerCoordinates(
            e,
            elem.parentElement
        )

        this.mouseDownStart = cloneDeep(event)
    }

    private async onMouseMove(event: Coordinates) {
        if (this.mouseDownStart === undefined) {
            return
        }

        const element = await Helpers.waitForElementByID(
            "play-head-element-wrapper"
        )

        event.x -= element.getBoundingClientRect().x

        let grid = this.engine?.grid

        if (
            !featureFlags?.enablePlayheadPerformanceImprovement ||
            !this.engine?.grid
        ) {
            grid = this.engine.getGridDimensions(
                undefined,
                element.parentElement.getBoundingClientRect().width
            )
        }

        const scrollToTimestep = Time.convertTimestepsToAnotherRes(
            this.engine.scrollToTimestep,
            TIMESTEP_RES,
            grid.pitched.timestepRes
        )

        let timesteps =
            event.x / this.computePxPerTimesteps(grid.pitched) +
            scrollToTimestep

        timesteps = ScoreManipulation.quantizeTimesteps({
            resizeFactor: this.engine.resizeFactor,
            timesteps,
            timestepRes: grid.pitched.timestepRes,
            timeSignature: grid.pitched.timeSignature,
            thresholds: NOTE_QUANTIZATION_THRESHOLDS,
        })

        timesteps = Math.max(0, timesteps)

        const fraction = Time.timestepsToFraction(
            grid.pitched.timestepRes,
            timesteps
        )
        const seconds = Time.fractionToSeconds(
            TIMESTEP_RES,
            this.engine.score.tempoMap,
            fraction
        )

        this.playerService.setTimeFromSeconds(seconds)
    }

    private onMouseUp(event: Coordinates) {
        this.mouseDownStart = undefined
    }

    private async updateLeftOffset(seconds: number) {
        let grid = this.engine?.grid

        if (
            !featureFlags?.enablePlayheadPerformanceImprovement ||
            !this.engine?.grid
        ) {
            const element = await Helpers.waitForElementByID(
                "play-head-element-wrapper"
            )
            grid = this.engine.getGridDimensions(
                undefined,
                element.parentElement.getBoundingClientRect().width
            )
        }

        const fraction = Time.secondsToFraction(
            seconds,
            this.playerService.hasStartOffset(),
            grid.pitched.timestepRes,
            this.engine.score.tempoMap
        )

        const timesteps = Time.fractionToTimesteps(
            grid.pitched.timestepRes,
            fraction
        )

        const scrollToTimestep = Time.convertTimestepsToAnotherRes(
            this.engine.scrollToTimestep,
            TIMESTEP_RES,
            grid.pitched.timestepRes
        )

        this.leftOffset =
            (timesteps - scrollToTimestep) *
            this.computePxPerTimesteps(grid.pitched)

        this.ref.detectChanges()
    }

    private computePxPerTimesteps(grid: GridDimensions): number {
        const pxLengthForGutters =
            grid.pxPerBarGutter * (grid.barsInPattern - 1) +
            grid.pxPerBeatsGutterInBar * grid.barsInPattern +
            grid.pxPerTimestepGutterInBar

        const pxLength =
            grid.scoreLengthTimesteps * grid.pxPerTimestepWithoutGutters +
            pxLengthForGutters

        return pxLength / grid.scoreLengthTimesteps
    }
}
