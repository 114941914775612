<div class="modal-title">Add Custom Layer</div>
<div class="modal-subtitle" *ngIf="!isLoading">
    Please select the type of layer you'd like to add:
</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<div class="add-layer-type" (click)="addLayer('pitched')" *ngIf="!isLoading">
    <img src="assets/img/presets/piano.svg" />
    <div class="layer-type-title">Pitched layer</div>
</div>

<div class="add-layer-type" (click)="addLayer('percussion')" *ngIf="!isLoading">
    <img src="assets/img/presets/drums.svg" />
    <div class="layer-type-title">Percussion layer</div>
</div>


<div class="loader" *ngIf="isLoading"></div>

