const juneRelease = {
    enableChordsEditingInEditor: false,
    editKeySignature: false,
    harmonyAndKeyDetection: false,
    dataEditingTools: false,
    enableSectionModificationInEditor: false,
}

export const featureFlags = {
    // Debug flags, to enable logging at will
    autoPhrasing: false,
    debugRendering: false,
    debugActions: false,
    debugUndoRedo: false,
    debugStreamingService: false,

    llmBasedLayerGeneration: true,

    newThematicMaterialToggle: true,

    enableLocalLLM: false,

    // if set to true, the user will be able to edit sections in compositions that are
    // generated from a composition workflow. Else a modal will be shown to the user that editing is not supported.
    sectionModificationForCWCompositions: true,

    enableChordsEditingInEditor: true,

    // this controls the visibility of the "Allow instrumentation changes"
    // and "New thematic material" toggles. If set to false, the toggles will be visible
    // and the user can directly control both of them.
    enableAutomaticVariationImprovements: true,

    // if set to true, the automation that sets the fixed
    // instrumentation will differentiate for each layer based on the layer's similarity.
    allowInstrumentationChangesOnPerLayerBasis: true,

    // if set to true, we will always generate new material when generating compositions
    // from a composition workflow
    alwaysGenerateNewThematicMaterial: true,

    editKeySignature: true,

    useMinimumAudioBufferCacheSize: false,

    // if set to false, chords editor will not have any constraints in the editor
    applyMusicEngineChordRestrictions: false,

    // If set to true, the playhead will be updated in a more performant way
    enablePlayheadPerformanceImprovement: true,
    // If set to true, the Notes and notesObject will do calculations using the new Fraction class
    useFractionClass: true,
    // If set to true, users will be able to trigger the creation of a bug report from the desktop app
    enableDesktopAppBugReport: true,

    harmonyAndKeyDetection: false,
    sectionInpainting: true,

    // If set to true, the user will be able to edit the annotations and the metadata of
    // a composition taken from the training data set
    dataEditingTools: true,

    // If set to true, the section inpainting will also inpaint the percussion layer
    inpaintSectionInPercussionLayer: true,

    // If set to true, the user will be able to re-generate sections of a layer in the editor
    generateLayerWithLLMInEditor: true,

    // If set to true, the user will be able to edit the section name, copy chords, split and resize sections in the editor
    enableSectionModificationInEditor: true,

    // If true, the midi upload will show up as an option in creation menus
    enableMidiUpload: true,

    // If true, users will have the option to collapse layers with similar musical content (notes, instrumentation) when importing midi
    collapseLayersWhenImportingMidi: true,

    ...juneRelease,
}
