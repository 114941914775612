import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exclude'
})
export class ExcludePipe implements PipeTransform {
  transform(data: any, key: string, value: string): any {
    if(!data || data.length === 0) {
      return []
    }

    return data.filter(item => item[key] !== value)
  }
}