<div class="context-menu accompaniment-filter-window" (clickOutside)="closeContextMenu()" [clickOutsideEvents]="'click,contextmenu,mousedown'">
    <div class="context-menu-background"></div>

    <div class="accompaniment-filter-container">
        <div class="title-row">
            <div class="title">Filters</div>
            <div class="reset-filter">
                <a-button [icon]="'/assets/img/menu/variations.svg'" iconWidth="10px" iconPos="right" (click)="resetFilter()" label="Reset filter"></a-button>
            </div>
        </div>

        <hr>

        <div class="filter-options">
            <div class="left" *ngIf="data && data.filter && data.filter.tags && data.filter.tags.data">
                <div class="styles">
                    <div class="title">
                        {{ data.filter.tags.name }}
                    </div>
                    <div class="style" *ngFor="let item of data.filter.tags.data | keyvalue">
                        <radio-button [class]="'gp-library-filters'" [type]="'checkbox'" [id]="item.key" [value]="item.key" [label]="item.key" (onSelect)="changeSelectedFilters($event, 'tags', item.key)" [selectedValue]="item.value ? item.key : null"></radio-button>
                    </div>
                </div>
            </div>
            <div class="right" *ngIf="data && data.filter && data.filter.pacing && data.filter.pacing.data">
                <div class="pacing">
                    <div class="title">
                        {{ data.filter.pacing.name }}
                    </div>
                    <div class="style" *ngFor="let item of data.filter.pacing.data | keyvalue">
                        <radio-button [class]="'gp-library-filters'" [type]="'checkbox'" [id]="item.key" [value]="item.key" [label]="item.key" (onSelect)="changeSelectedFilters($event, 'pacing', item.key)" [selectedValue]="item.value ? item.key : null"></radio-button>
                    </div>
                </div>
                <div class="percussion-functions" *ngIf="data.filter.percussionFunction && data.filter.percussionFunction.data">
                    <div class="title">
                        {{ data.filter.percussionFunction.name }}
                    </div>
                    <div class="style" *ngFor="let item of data.filter.percussionFunction.data | keyvalue">
                        <radio-button [class]="'gp-library-filters'" [type]="'checkbox'" [id]="item.key" [value]="item.key" [label]="item.key" (onSelect)="changeSelectedFilters($event, 'percussionFunction', item.key)" [selectedValue]="item.value ? item.key : null"></radio-button>
                    </div>
                </div>
            </div>
        </div>   

    </div>
</div>