import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    AfterViewInit,
} from "@angular/core"
import { LayerModal, RenameModal } from "@services/modal.service"

@Component({
    selector: "rename",
    templateUrl: "rename.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenameComponent implements AfterViewInit {
    public loading: boolean = false
    public rename: string = ""
    public title:string = "Rename"

    @Input() renameModal: RenameModal
    @Output() close: EventEmitter<any> = new EventEmitter()

    @ViewChild("renameField", { static: false }) renameField: ElementRef

    constructor() {

    }

    ngAfterViewInit() {
        if (this.renameModal.title) {
            this.title = this.renameModal.title
        }

        this.rename = this.renameModal.name
        this.renameField.nativeElement.focus()
    }

    closeModal() {
        this.close.emit()
    }

    setName() {
        this.renameModal.onComplete(this.rename)
        this.closeModal()
    }
}
