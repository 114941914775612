import { OverlayRef, Overlay, OverlayPositionBuilder, ConnectedPosition } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChange } from "@angular/core";
import { TooltipComponent } from "./tooltip.component"

@Directive({ selector: '[tooltip]' })
export class TooltipDirective implements OnChanges, OnInit {
    private overlayRef: OverlayRef

    private mouseUpListener
    private mouseMoveListener

    constructor(private overlayPositionBuilder: OverlayPositionBuilder, private elementRef: ElementRef,  private overlay: Overlay) {}

    tooltipTimeout

    // Required fields
    @Input('tooltip') text:string
    @Input('tooltip-type') type:string = "onmouseenter"

    // Optional fields
    @Input('tooltip-description') description:string
    @Input('tooltip-position') position:string
    @Input('tooltip-width') width:string
    @Input('tooltip-class') class:string = ""
    @Input('tooltip-marginLeft') marginLeft:number = 0
    @Input('tooltip-marginRight') marginRight:number = 0
    @Input('tooltip-marginBottom') marginBottom:number = 0
    @Input('tooltip-marginTop') marginTop:number = 0
    @Input('tooltip-textAlign') textAlign:string = "left"
    @Input('tooltip-ignore') ignore:string = ""
    @Input('tooltip-timeout') timeout:number = 200
    @Input('tooltip-disabled') disabled:boolean = false // we don't show the tooltip in case it is disabled


    tooltipRef:ComponentRef<TooltipComponent>


    ngOnChanges(changes) {
        for (var key in changes) {
            const change:SimpleChange = changes[key]

            this[key] = change.currentValue

            if (this.tooltipRef != null && this.tooltipRef.instance != null) {
                this.tooltipRef.instance[key] = this[key]
            }
        }
    }

    @HostListener('mouseenter', ['$event'])
    @HostListener('mousedown', ['$event'])
    show(event:MouseEvent) {
        if (event.type == "mouseenter" && this.type == "onmouseenter") {
            this.showTooltip()

            if(this.ignore != ""){
                this.mouseMoveListener = this.mouseMoveObserver.bind(this)

                window.document.addEventListener('mousemove', this.mouseMoveListener)
            }
        }        

        else if (event.type == "mousedown" && this.type == "onmousedown" && this.mouseUpListener == null) {
            this.showTooltip()

            this.mouseUpListener = this.hideTooltip.bind(this)
    
            window.document.addEventListener('mouseup', this.mouseUpListener)
        }
    }

    @HostListener('mouseleave', ['$event'])
    hide(event:MouseEvent) {
        if (this.type == "onmouseenter") {
            this.hideTooltip()
        }
    }

    hideTooltip() {
        if (this.tooltipTimeout != null) {
            clearTimeout(this.tooltipTimeout)
            this.tooltipTimeout = null
        }

        this.overlayRef.detach() 

        if (this.mouseUpListener != null) {
            window.document.removeEventListener('mouseup', this.mouseUpListener)
            this.mouseUpListener = null
        }
        
        if (this.mouseMoveListener != null) {
            window.document.removeEventListener('mousemove', this.mouseMoveListener)
            this.mouseMoveListener = null
        }
    }

    targetHasClass(event, className){
        if(!event || !event.target){
            return false
        }

        className = className.replace('.','')

        let match = event.target.classList.contains(className)

        return match 
    }

    targetHasID(event, idName){
        if(!event || !event.target || !event.target.hasAttribute('id')){
            return false
        }

        let match = event.target.id == idName.replace('#','')
        
        return match
    }

    mouseMoveObserver(event){
        if(this.ignore != null && typeof this.ignore == "string" && this.ignore != ""){
            let ignore = this.ignore.replace(" ", "")
            
            let ignoreList = ignore.split(',')
            let match = false

            for(let i = 0; i < ignoreList.length; i++){
                let shouldIgnore = ignoreList[i]

                if((shouldIgnore.includes('.') && this.targetHasClass(event, shouldIgnore)) || (shouldIgnore.includes('#') && this.targetHasID(event, shouldIgnore))){
                    match = true
                    break
                }
            }

            if(match){
                this.hideTooltip()
            }
        }
    }

    showTooltip() {
        if(this.disabled){
            return
        }
        
        this.tooltipTimeout = setTimeout(() => {
            // First detach any portal currently attached
            this.overlayRef.detach() 

            // Create tooltip portal
            const tooltipPortal = new ComponentPortal(TooltipComponent)

            // Attach tooltip portal to overlay
            this.tooltipRef = this.overlayRef.attach(tooltipPortal)
            
            // Pass content to tooltip component instance
            this.tooltipRef.instance.text = this.text
            this.tooltipRef.instance.description = this.description
            this.tooltipRef.instance.width = this.width
            this.tooltipRef.instance.marginTop = this.marginTop
            this.tooltipRef.instance.marginBottom = this.marginBottom
            this.tooltipRef.instance.marginRight = this.marginRight
            this.tooltipRef.instance.marginLeft = this.marginLeft
            this.tooltipRef.instance.textAlign = this.textAlign
            this.tooltipRef.instance.class = this.class
        }, this.timeout)
    }

    ngOnInit() {
        var position: ConnectedPosition = {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
        }

        if (this.position == "middle-bottom") {
            position = {
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
            }
        }

        else if (this.position == "right-middle") {
            position = {
                originX: 'end',
                originY: 'center',
                overlayX: 'center',
                overlayY: 'top',
            }
        }

        else if (this.position == "left-bottom") {
            position = {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
            }
        }

        const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([position])
        
        // Connect position strategy
        this.overlayRef = this.overlay.create({ positionStrategy })
    }

    ngOnDestroy(){
        this.hideTooltip()
    }
}
    