import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from "@angular/core"
import { ChordsEditingService } from "@services/chords-editing.service"
import { ChordsEditingButtons } from "../../../types/chordsEditing"
import { MenuOption, MenuOptions } from "../menu-options/menu-options.component"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import {
    Chord,
    EditChordData,
} from "../../../../../../common-lib/client-only/score-rendering-engine/types"
import { Time } from "@common-lib/modules/time"
import { ParentClass } from "../../../parent"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../../types/dropdownItemType"
import { SRActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { InitCanvases } from "../../../modules/init-canvases.module"
import { featureFlags } from "@common-lib/utils/feature-flags"

@Component({
    selector: "chords-editing",
    templateUrl: "./chords-editing.component.html",
    styleUrls: ["./chords-editing.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ChordsEditingService],
})
export class ChordsEditingComponent extends ParentClass {
    @Input() public engine: ScoreRenderingEngine
    @Input() editorType: "editor" | "composition-workflow"

    @Input() public zeroedPosition: boolean = false

    public chordsEditingButtons: ChordsEditingButtons
    public chordsEditingMenu: MenuOptions<any> | undefined
    public editChordSettings: EditChordData | undefined

    constructor(
        private cd: ChangeDetectorRef,
        private chordsEditingService: ChordsEditingService
    ) {
        super()
    }

    ngOnInit() {
        this.chordsEditingService.setEngine(this.engine)
        this.chordsEditingService.setEditorType(this.editorType)
        this.initSubscriptions()
    }

    async ngAfterViewInit() {
        this.initCanvas()
    }

    public getChordPrefixes(data: EditChordData): DropdownItemType<string>[] {
        return this.chordsEditingService.getChordPrefixes(data)
    }

    public getSelectedChordPrefix(
        data: EditChordData
    ): DropdownItemType<string> {
        return this.chordsEditingService.getSelectedChordPrefix(data)
    }

    public getChordSuffixes(data: EditChordData): DropdownItemType<{
        quality: "maj" | "min"
        value: string
    }>[] {
        return this.chordsEditingService.getChordSuffixes(data)
    }

    public getSelectedChordSuffix(data: EditChordData): DropdownItemType<{
        quality: "maj" | "min"
        value: string
        suffix: string
    }> {
        return this.chordsEditingService.getSelectedChordSuffix(data)
    }

    public setChordSuffix(
        data: EditChordData,
        item: DropdownSelection<{
            quality: "maj" | "min"
            value: string
            suffix: string
        }>
    ): void {
        this.chordsEditingService.setChordSuffix(data, item)
    }

    public setChordOption(
        data: EditChordData,
        item: DropdownSelection<string>
    ) {
        this.chordsEditingService.setChordOption(data, item)
    }

    public showCollapsedMenu(menu: ChordsEditingButtons) {
        const nbOfButtons = this.nbOfButtonsShown(menu.chord)
        const width = menu.width
        const marginLeftAndRight = 7 + 7

        const minWidth = 27 // minimum width of the chord editing buttons
        const gapWidth = (3 - 1) * nbOfButtons

        return width < marginLeftAndRight + gapWidth + minWidth * nbOfButtons
    }

    public nbOfButtonsShown(chord: Chord) {
        const deleteButton = this.showDeleteChordButton(chord)
        const splitButton = this.showSplitChordButton(chord)

        return deleteButton && splitButton
            ? 3
            : deleteButton || splitButton
            ? 2
            : 1
    }

    public showDeleteChordButton(chord: Chord) {
        const measure = this.timeSignature[0] + "/" + this.timeSignature[1]

        if (
            !featureFlags.applyMusicEngineChordRestrictions &&
            this.editorType === "editor"
        ) {
            return Time.compareTwoFractions(chord.duration, measure) === "lt"
        }

        let shouldShow =
            Time.compareTwoFractions(chord.duration, measure) === "lt"
        if (this.editorType === "editor") {
            const chordsArr = this.engine.score.chords
            if (chordsArr.length === chord.index + 1) {
                shouldShow = true
            }
        }
        return shouldShow
    }

    public showSplitChordButton(chord: Chord) {
        const beat = Time.getTSBeat(this.timeSignature)

        if (
            !featureFlags.applyMusicEngineChordRestrictions &&
            this.editorType === "editor"
        ) {
            return Time.compareTwoFractions(chord.duration, beat) === "gt"
        }

        return Time.isDivisibleBy(chord.duration, beat)
    }

    public toggleChordsEditingMenu(
        chord: Chord,
        event: MouseEvent,
        close: boolean
    ) {
        if (close) {
            this.chordsEditingMenu = undefined
        } else {
            const options: MenuOption<any>[] = [
                {
                    data: undefined,
                    text: "Edit chord",
                    icon: "assets/img/thin_pencil.svg",
                    loading: false,
                    onClick: ((data: any, event: MouseEvent) => {
                        this.toggleChordsEditingMenu(chord, event, true)

                        this.editChord(event, chord)

                        return
                    }).bind(this),
                },
            ]

            if (this.showSplitChordButton(chord)) {
                options.push({
                    data: undefined,
                    text: "Split in half",
                    icon: "assets/img/add.svg",
                    loading: false,
                    onClick: (async (data: any, event: MouseEvent) => {
                        this.toggleChordsEditingMenu(chord, event, true)

                        await this.insertChord(chord?.index)

                        return
                    }).bind(this),
                })
            }

            if (this.showDeleteChordButton(chord)) {
                options.push({
                    data: undefined,
                    text: "Delete",
                    buttonClass: "delete",
                    icon: "assets/img/menu/delete.svg",
                    loading: false,
                    onClick: (async (data: any, event: MouseEvent) => {
                        await this.deleteChord(chord.index)

                        this.toggleChordsEditingMenu(chord, event, true)

                        return
                    }).bind(this),
                })
            }

            this.chordsEditingMenu = {
                options,
                coordinates: {
                    x: event.x,
                    y: event.y,
                },
            }
        }

        this.cd.detectChanges()
    }

    public closeChordEditSettings() {
        this.chordsEditingService.editChordSettings$.next(undefined)

        return
    }

    private get timeSignature() {
        return this.engine.score.timeSignatures[0][1]
    }

    private editChord(event: MouseEvent, chord: Chord) {
        if (!chord) {
            return
        }

        this.chordsEditingService.editChord(event, chord)

        this.setChordsEditingButtons(undefined)
    }

    private async deleteChord(index: number) {
        await this.chordsEditingService.deleteChord({
            index: index,
        })

        this.setChordsEditingButtons(undefined)
    }

    private async insertChord(index: number) {
        await this.chordsEditingService.insertChord({
            index: index,
        })
        this.setChordsEditingButtons(undefined)
    }

    private setChordsEditingButtons(
        data:
            | {
                  chord: Chord
                  start: number
                  width: number
              }
            | undefined
    ) {
        if (!data) {
            this.chordsEditingButtons = undefined
            this.cd.detectChanges()
            return
        }

        const buttonWidth = 20
        const buttonMarginTotal = 10

        this.chordsEditingButtons = {
            chord: data.chord,
            left: {
                insertLeft: -buttonWidth / 2,
                insertRight: data.width - buttonWidth / 2,
                editChord:
                    data.width / 2 - (buttonWidth + buttonMarginTotal / 2),
                deleteChord: data.width / 2 + buttonMarginTotal / 2,
            },
            start: data.start,
            width: data.width,
            showInsertButtons:
                data.width - 3 * buttonWidth - buttonMarginTotal > 0,
        }

        this.cd.detectChanges()
    }

    private initSubscriptions() {
        this.subscribe(this.chordsEditingService.editChordSettings$, val => {
            this.editChordSettings = val
        })
    }

    private async initCanvas() {
        this.engine.deleteCanvas("ChordsEditingCanvas")

        this.engine.srEmitter$.next({
            type: SRActionTypes.convertChordsToRomanNumerals,
        })
        await InitCanvases.initChordsEditingCanvas(
            null,
            true,
            this.toggleChordsEditingMenu.bind(this),
            (data => {
                this.setChordsEditingButtons(data)
            }).bind(this),
            this.engine
        )
    }
}
