<div class="parameters-view create-with-gp" av-data="create-with-gp-modal">
    <div class="error-banner-createwithgp tooltip-error" *ngIf="error != null">
        {{ error }}
    </div>

    <div class="settings-row" *ngIf="!loading">
        <div class="settings-row-title">Key Signature</div>

        <div class="settings-row-value">
            <div
                class="key-signature-selection-button-wrapper"
                [ngClass]="'enable-key-signature-hover'">
                <dropdown
                    class="create-with-gp-dropdown"
                    [value]="getSelectedKeySignature()"
                    [showTooltip]="false"
                    [items]="getKeySignatureOptions()"
                    (selectedItem)="selectKeySignature($event)"></dropdown>
            </div>
        </div>
    </div>

    <div class="settings-row" *ngIf="!loading">
        <div class="settings-row-title">Duration</div>

        <div class="settings-row-value">
            <div class="key-signature-selection-button-wrapper">
                <dropdown
                    class="create-with-gp-dropdown"
                    [value]="getSelectedDuration()"
                    [showTooltip]="false"
                    [items]="getGenerationProfileDurations()"
                    (selectedItem)="setDuration($event)"></dropdown>
            </div>
        </div>
    </div>

    <div class="settings-row" *ngIf="!loading">
        <div class="settings-row-title">Number of Compositions</div>

        <div class="settings-row-value">
            <div class="key-signature-selection-button-wrapper">
                <dropdown
                    class="create-with-gp-dropdown"
                    [value]="getSelectedNumberOfCompositions()"
                    [showTooltip]="false"
                    [items]="getGPCompositionNumber()"
                    (selectedItem)="
                        selectNumberOfCompositions($event)
                    "></dropdown>
            </div>
        </div>
    </div>

    <div class="modal-button-wrapper" *ngIf="!loading">
        <a-button
            width="100%"
            type="secondary"
            label="Create tracks"
            (onClick)="createWithGPCaptcha($event)"
            [disabled]="error != null"></a-button>
    </div>

    <div class="loader-container" *ngIf="loading">
        <div class="loader create-with-influence-loader"></div>
    </div>
</div>

<re-captcha
    #captchaRef="reCaptcha"
    siteKey="{{ getCaptchaKey() }}"
    size="invisible"
    (resolved)="createWithGP($event)"></re-captcha>
