<div class="modal-title">Loading newest version of GP packs</div>
<div class="modal-close"><img src="assets/img/close.svg" (click)="closeModal()"></div>

<div class="loading-container" *ngIf="loading">
    <div class="loader"></div>

    <div>Loading, please wait. This can take up to ~30 seconds</div>
</div>

<div class="done-container" *ngIf="!loading">
    Done!
</div>