<div class="modal-title">Your score is corrupted</div>

<div>
    In order to avoid issues with it, we automatically fixed the errors. You can
    apply this fix by clicking on "Save changes".
</div>

<div class="buttons-container">
    <div class="loader" *ngIf="isLoading"></div>

    <a-button
        *ngIf="!isLoading"
        label="Save changes"
        (click)="saveChanges()"
        width="100%"
        type="secondary"></a-button>
</div>
