<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>
<div class="modal-title">Updates Changelog</div>

<div class="changelog-loader-container" *ngIf="loading">
    <div class="loader"></div>
</div>

<ngx-simplebar
    [options]="options"
    class="changelog-versions-container"
    *ngIf="!loading">
    <div class="changelog-version-container" *ngFor="let version of changelog">
        <div class="changelog-version">{{ version.Name }}</div>
        <ul>
            <li *ngFor="let feature of version['Feature list']">
                <b
                    ><span
                        *ngIf="feature.Name != null"
                        [innerHTML]="feature.Name"></span
                ></b>
                <span
                    class="feature-notes"
                    *ngIf="feature.Notes != null"
                    [innerHTML]="' ' + feature.Notes"></span>
            </li>

            <li>Various bug fixes and usability improvements</li>

            <ul>
                <li *ngFor="let bugFix of version['Bug Fixes']">
                    {{ bugFix.Name }}
                </li>
            </ul>
        </ul>
    </div>
</ngx-simplebar>
