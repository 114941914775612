<div
    class="layer-inspector-wrapper"
    *ngIf="(editor.engine.toggledLayer$ | async)?.type === 'pitched'">
    <div id="layer-inspector-container">
        <div>
            <div class="layer-inspector-side-left">
                <layer-effects-panel [editor]="editor"></layer-effects-panel>
            </div>
            <div id="piano" class="canvas-container"></div>
        </div>

        <div>
            <div id="accompaniment-designer" class="canvas-container"></div>
            <div id="vertical-scrolling"></div>
        </div>
    </div>
</div>

<div
    id="layer-inspector-container"
    class="layer-inspector-container-percussion"
    *ngIf="(editor.engine.toggledLayer$ | async)?.type === 'percussion'">
    <div>
        <div
            class="layer-inspector-side-left layer-inspector-side-left-percussion">
            <layer-effects-panel [editor]="editor"></layer-effects-panel>
        </div>
    </div>
    <div
        (mousemove)="handleInspectorHover($event)"
        #layerInspectorPercussionEmpty></div>
</div>

<div class="automation-row" *ngIf="editor.engine.selectedAutomation$ | async">
    <div class="automation-left-menu">
        <set-constant-automation [editor]="editor"></set-constant-automation>
        <automation-scale [engine]="editor.engine"></automation-scale>
    </div>
    <div id="automation" class="canvas-container"></div>
</div>
