export const presetUseCases = [
    {
        category: "Cinematic",
        subcategories: [
            {
                name: "Epic Orchestral",
                image: "drums.svg",
                style: "epic_orchestral",
                presetAndEnsemble: [
                    {
                        preset: "modern_cinematic",
                        ensemble: "Epic Orchestra",
                    },

                    {
                        preset: "sea_shanties",
                        ensemble: "Epic Orchestra",
                    },

                    {
                        preset: "fantasy",
                        ensemble: "Dragonborn Ensemble",
                    },
                ],
                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1, 2, 4, 5, 3] },
                ],
                backgroundColor: "rgb(0, 0, 152)",
            },

            {
                name: "Ambient Orchestral",
                image: "ambient.svg",
                style: "ambient_orchestral",
                presetAndEnsemble: [
                    {
                        preset: "ambient",
                        ensemble: "Acoustic Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1, 4] },
                ],
                backgroundColor: "rgb(93, 41, 0)",
            },

            {
                name: "Orchestral",
                image: "modern_cinematic.svg",
                style: "orchestral",
                presetAndEnsemble: [
                    {
                        preset: "modern_cinematic",
                        ensemble: "World Symphonic Orchestra",
                    },

                    {
                        preset: "modern_cinematic",
                        ensemble: "Mallets & Orchestra",
                    },

                    {
                        preset: "modern_cinematic",
                        ensemble: "Solo Strings",
                    },

                    {
                        preset: "modern_cinematic",
                        ensemble: "Symphonic Orchestra",
                    },

                    {
                        preset: "modern_cinematic",
                        ensemble: "Strings & Brass",
                    },

                    {
                        preset: "modern_cinematic",
                        ensemble: "Piano & Strings",
                    },

                    {
                        preset: "modern_cinematic",
                        ensemble: "String Ensemble",
                    },

                    {
                        preset: "fantasy",
                        ensemble: "Symphonic Orchestra",
                    },

                    {
                        preset: "fantasy",
                        ensemble: "Strings & Woodwinds",
                    },

                    {
                        preset: "fantasy",
                        ensemble: "Solo Strings",
                    },

                    {
                        preset: "fantasy",
                        ensemble: "String Ensemble",
                    },

                    {
                        preset: "fantasy",
                        ensemble: "Strings & Chorus",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1, 2, 4, 5, 3] },
                ],
                backgroundColor: "rgb(0, 155, 155)",
            },

            {
                name: "World music",
                image: "world.svg",
                style: "world_music",
                presetAndEnsemble: [
                    {
                        preset: "chinese",
                        ensemble: "Small Chinese Ensemble",
                    },

                    {
                        preset: "chinese",
                        ensemble: "Solo Strings",
                    },

                    {
                        preset: "chinese",
                        ensemble: "Symphonic Orchestra",
                    },

                    {
                        preset: "fantasy",
                        ensemble: "Small Tavern Band",
                    },

                    {
                        preset: "tango",
                        ensemble: "Small Tango Band",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1, 2, 4, 5, 3] },
                ],
                backgroundColor: "rgb(198, 141, 0)",
            },
        ],
    },

    {
        category: "Produced",
        subcategories: [
            {
                name: "EDM",
                image: "edm.svg",
                style: "EDM",
                presetAndEnsemble: [
                    {
                        preset: "electronic",
                        ensemble: "EDM Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [2, 5] },
                ],
                backgroundColor: "rgb(0, 78, 94)",
            },

            {
                name: "Old School Rap",
                image: "cap.svg",
                style: "old_school_rap",
                presetAndEnsemble: [
                    {
                        preset: "hip_hop",
                        ensemble: "Old School Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [2, 4, 5] },
                ],
                backgroundColor: "rgb(0, 78, 94)",
            },

            {
                name: "Trap",
                image: "dollar2.svg",
                style: "trap",
                presetAndEnsemble: [
                    {
                        preset: "electronic",
                        ensemble: "The Grande Ensemble",
                    },

                    {
                        preset: "hip_hop",
                        ensemble: "Trap Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [2, 4, 5] },
                ],
                backgroundColor: "#004a77",
            },

            {
                name: "Cyberpunk",
                image: "cyberpunk.svg",
                style: "cyberpunk",
                presetAndEnsemble: [
                    {
                        preset: "modern_cinematic",
                        ensemble: "Cyberpunk Ensemble",
                    },

                    {
                        preset: "electronic",
                        ensemble: "Cyberpunk Ensemble",
                    },

                    {
                        preset: "ambient",
                        ensemble: "Cyberpunk Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [3, 5] },
                ],
                backgroundColor: "rgb(64, 171, 153)",
            },

            {
                name: "House",
                image: "house.svg",
                style: "house",
                presetAndEnsemble: [
                    {
                        preset: "electronic",
                        ensemble: "French House Ensemble",
                    },

                    {
                        preset: "electronic",
                        ensemble: "Deep House Ensemble",
                    },

                    {
                        preset: "electronic",
                        ensemble: "Organic House Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [2, 5] },
                ],
                backgroundColor: "rgb(56, 42, 111)",
            },

            {
                name: "Techno",
                image: "techno.svg",
                style: "techno",
                presetAndEnsemble: [
                    {
                        preset: "electronic",
                        ensemble: "Techno Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [2, 5] },
                ],
                backgroundColor: "rgb(56, 42, 111)",
            },

            {
                name: "Big Room",
                image: "sampler.svg",
                style: "big_room",
                presetAndEnsemble: [
                    {
                        preset: "electronic",
                        ensemble: "Big Room Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [2] },
                ],
                backgroundColor: "rgb(56, 42, 111)",
            },

            {
                name: "Synthwave",
                image: "synthwave.svg",
                style: "synthwave",
                presetAndEnsemble: [
                    {
                        preset: "modern_cinematic",
                        ensemble: "Synthwave Ensemble",
                    },

                    {
                        preset: "electronic",
                        ensemble: "Synthwave Ensemble",
                    },

                    {
                        preset: "ambient",
                        ensemble: "Synthwave Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [3, 5] },
                ],
                backgroundColor: "rgb(133, 0, 191)",
            },
        ],
    },

    {
        category: "Easy Listening",
        subcategories: [
            {
                name: "Lo-Fi",
                image: "vinyl.svg",
                style: "lo_fi",
                presetAndEnsemble: [
                    {
                        preset: "ambient",
                        ensemble: "Lo-Fi Ensemble",
                    },

                    {
                        preset: "hip_hop",
                        ensemble: "Lo-Fi Ensemble",
                    },

                    {
                        preset: "jazz",
                        ensemble: "Lo-Fi Ensemble",
                    },

                    {
                        preset: "chinese",
                        ensemble: "Lo-Fi Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1, 4] },
                ],
                backgroundColor: "rgb(130, 0, 0)",
            },

            {
                name: "Ambient Electronic",
                image: "ambient.svg",
                style: "ambient_electronic",
                presetAndEnsemble: [
                    {
                        preset: "ambient",
                        ensemble: "Hybrid Ensemble",
                    },

                    {
                        preset: "ambient",
                        ensemble: "Electronic Ensemble",
                    },

                    {
                        preset: "electronic",
                        ensemble: "Downtempo Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1, 4] },
                ],
                backgroundColor: "rgb(212, 104, 21)",
            },

            {
                name: "Meditation",
                image: "meditation.svg",
                style: "meditation",
                presetAndEnsemble: [
                    {
                        preset: "ambient",
                        ensemble: "Meditation Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1] },
                ],
                backgroundColor: "rgb(130, 0, 0)",
            },

            {
                name: "Jazz",
                image: "jazz.svg",
                style: "jazz",
                presetAndEnsemble: [
                    {
                        preset: "jazz",
                        ensemble: "auto",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1, 2, 4, 5] },
                ],
                backgroundColor: "rgb(61, 104, 246)",
            },

            {
                name: "Songwriter",
                image: "songwriter.svg",
                style: "songwriter",
                presetAndEnsemble: [
                    {
                        preset: "pop",
                        ensemble: "Acoustic Ensemble",
                    },

                    {
                        preset: "pop",
                        ensemble: "Bedroom Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1, 2, 4] },
                ],
                backgroundColor: "rgb(0, 123, 71)",
            },

            {
                name: "Piano",
                image: "piano.svg",
                style: "piano",
                presetAndEnsemble: [
                    {
                        preset: "pop",
                        ensemble: "Piano Solo",
                    },

                    {
                        preset: "pop",
                        ensemble: "Soft Piano Solo",
                    },

                    {
                        preset: "modern_cinematic",
                        ensemble: "Piano Solo",
                    },

                    {
                        preset: "modern_cinematic",
                        ensemble: "Soft Piano Solo",
                    },

                    {
                        preset: "fantasy",
                        ensemble: "Piano Solo",
                    },

                    {
                        preset: "fantasy",
                        ensemble: "Soft Piano Solo",
                    },

                    {
                        preset: "jazz",
                        ensemble: "Piano Solo",
                    },

                    {
                        preset: "jazz",
                        ensemble: "Soft Piano Solo",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1, 2, 4, 5, 3] },
                ],
                backgroundColor: "rgb(29, 29, 29)",
            },
        ],
    },

    {
        category: "Pop / Rock",
        subcategories: [
            {
                name: "Pop",
                image: "pop.svg",
                style: "pop",
                presetAndEnsemble: [
                    {
                        preset: "pop",
                        ensemble: "Bedroom Ensemble",
                    },

                    {
                        preset: "pop",
                        ensemble: "Acoustic Ensemble",
                    },

                    {
                        preset: "pop",
                        ensemble: "Upbeat Ensemble",
                    },

                    {
                        preset: "pop",
                        ensemble: "Epic Emotional Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1, 2, 4] },
                ],
                backgroundColor: "rgb(155, 0, 174)",
            },

            {
                name: "Heavy Rock",
                image: "metal.svg",
                style: "heavy_rock",
                presetAndEnsemble: [
                    {
                        preset: "rock",
                        ensemble: "Punk Rock Ensemble",
                    },

                    {
                        preset: "rock",
                        ensemble: "Heavy Metal Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [5, 2] },
                ],
                backgroundColor: "rgb(74, 27, 179)",
            },

            {
                name: "Alternative",
                image: "rock.svg",
                style: "alternative",
                presetAndEnsemble: [
                    {
                        preset: "rock",
                        ensemble: "Clean Rock Ensemble",
                    },
                ],

                parameters: [
                    {
                        name: "duration",
                        values: [
                            "auto",
                            "< 0'30",
                            "0'30 - 1'00",
                            "1'00 - 1'30",
                            "1'30 - 2'00",
                            "2'00 - 2'30",
                            "2'30 - 3'00",
                            "3'00 - 3'30",
                            "3'30 - 4'00",
                            "4'00 - 4'30",
                            "4'30 - 5'00",
                            "5'00 - 5'30",
                        ],
                    },
                    {
                        name: "numberOfCompositions",
                        values: ["1", "2", "3", "4", "5"],
                    },
                    { name: "hypermoods", values: [1, 2] },
                ],
                backgroundColor: "rgb(129, 131, 0)",
            },
        ],
    },
]
