import { Store, StoreConfig } from "@datorama/akita"
import { TIMESTEP_RES } from "../../../../general/constants/constants"
import { NotesObject } from "../../../../general/classes/score/notesObject"

import Section from "../../../../general/classes/score/section"
import { Effect } from "../../../../general/classes/score/effect"
import Layer from "../../../../general/classes/score/layer"
import { Note } from "../../../../general/classes/score/note"
import Score from "../../../../general/classes/score/score"
import { ActivityMetric } from "../../../../general/classes/activitymetric"
import {
    EditorPitch,
    KeySignatureString,
    NoteEditingClipboard,
} from "../../../../general/types/note-editing"
import { FractionString, ScoreType } from "../../../../general/types/score"
import {
    CanvasType,
    Chord,
    PitchStepDomain,
    RenderChordsType,
    ScoreUpdateType,
} from "../../types"
import PatternRegion from "../../../../general/classes/score/patternregion"
import { Pattern } from "../../../../general/classes/score/pattern"
import { RangeWithID } from "../../../../general/interfaces/general"
import { HoveringType } from "../../../../general/types/general"
import TrackBus from "../../../../general/classes/score/trackbus"
import PercussionLayer from "../../../../general/classes/score/percussionlayer"
import { Time } from "../../../../general/modules/time"
import { Coordinates } from "../../../../general/modules/event-handlers"
import { ScoreManipulation } from "../../../../general/modules/scoremanipulation"
import {
    playerActions,
    playerQuery,
} from "../../../general/classes/playerStateManagement"
import { TemplateChord } from "../../../../general/interfaces/score/templateScore"
import { cloneDeep } from "lodash"

export type ScoreRenderingSelectedDataType =
    | {
          type: "None"
          data: undefined
      }
    | {
          type: "Note"
          data: NotesObject
      }
    | {
          type: "PatternRegion"
          data: PatternRegion[]
      }
    | {
          type: "TrackBus"
          data: TrackBus[]
      }
    | {
          type: "TrackBusRegion"
          data: { region: RangeWithID; side: HoveringType }[]
      }
    | {
          type: "Chord"
          data: Chord
      }

export type NoteManipulationType = "resizeSingle" | "resizeMultiple" | "move"

export type ScoreRendering = {
    lastUpdateTimestamp: number
    scoreWasEdited: boolean
    score: Score | undefined
    scoreUpdate: ScoreUpdateType[]
    type: ScoreType | undefined
    overlappingNotes: NotesObject
    pointerType: string
    notesToRender: Note[]
    validPitches: EditorPitch[]

    noteResolution: FractionString | undefined
    keySignature: KeySignatureString | undefined
    noteManipulationStarted: NoteManipulationType | undefined

    renderChordsType: RenderChordsType

    // we might use Akita's active entitiy for this
    toggledLayer: string
    visiblePitchedLayers: Layer[]

    // both should never trigger rendering
    lastSelectedNote: Note | undefined
    clipboard: NoteEditingClipboard | undefined

    // For performance reasons, we want to make sure that we only render what has changed. This is what this attribute allows us to do
    renderingType: (CanvasType | string)[]
    resizeFactor: number
    minWidth: number
    scrollToTimestep: number
    scrollToPitchsteps: number
    selectedAutomation: Effect | undefined
    pitchStepDomain: PitchStepDomain
    selectedSection: {
        coordinates: Coordinates | undefined
        section: Section | undefined
    }
    seekTime: number // in timesteps with a resolution of TIMESTEP_RES
    selectedPattern: Pattern | undefined
    resizeFactorRange: {
        min: number
        max: number
    }

    selectedData: ScoreRenderingSelectedDataType

    patternScrollToTimestep: number
    userSelectedTimestepRes: number

    maxBarLength: number

    skipCachedCanvas: boolean

    // this boolean is here to indicate whether the user has drawn automation during this current session
    drewAutomation: boolean

    percussionDrawingStrategy: "toggleOn" | "toggleOff"
    resizeType: HoveringType
    isInitialPRManipulation: boolean
    autoExtendScore: boolean

    metricToAdd: ActivityMetric | undefined
    allowPolyphony: boolean

    harmonyLock: boolean
    layerPreviewConfigs: {
        skipAdjustments: boolean
        fillNotesWithLayerColor: boolean
    }
    // this is used for rendering chords while resizing
    temporaryRomanNumerals: TemplateChord[] | undefined
    temporaryChords: TemplateChord[] | undefined

    chordsWereEdited: boolean

    levelsMeasurement: "trackbus" | "layer"

    sustainPedalFromChords: boolean
    editorType: "editor" | "composition-workflow"
    forcePrerender: boolean
}

const initialState: ScoreRendering = {
    lastUpdateTimestamp: 0,
    scoreWasEdited: false,
    score: undefined,
    type: undefined,
    scoreUpdate: ["None"],
    selectedData: {
        type: "None",
        data: undefined,
    },
    forcePrerender: false,
    editorType: "composition-workflow",
    renderChordsType: "chord-symbol",
    overlappingNotes: new NotesObject(),
    pointerType: "select",
    notesToRender: [],
    validPitches: [],
    noteResolution: undefined,
    keySignature: undefined,
    toggledLayer: "",
    visiblePitchedLayers: [],
    lastSelectedNote: undefined,
    clipboard: undefined,
    renderingType: ["All"],
    resizeFactor: 5,
    minWidth: 500,
    scrollToTimestep: 0,
    patternScrollToTimestep: 0,
    scrollToPitchsteps: 0,
    selectedAutomation: undefined,
    userSelectedTimestepRes: TIMESTEP_RES,
    pitchStepDomain: "continuous",
    selectedSection: {
        section: undefined,
        coordinates: undefined,
    },
    seekTime: 0,
    selectedPattern: undefined,
    drewAutomation: false,
    noteManipulationStarted: undefined,
    percussionDrawingStrategy: "toggleOn",
    resizeType: undefined,
    isInitialPRManipulation: true,
    autoExtendScore: true,
    maxBarLength: 4,
    metricToAdd: undefined,
    resizeFactorRange: {
        min: 0,
        max: 150,
    },
    skipCachedCanvas: false,
    allowPolyphony: true,
    harmonyLock: false,
    layerPreviewConfigs: {
        skipAdjustments: true,
        fillNotesWithLayerColor: false,
    },
    temporaryRomanNumerals: undefined,
    temporaryChords: undefined,
    levelsMeasurement: "trackbus",
    chordsWereEdited: false,
    sustainPedalFromChords: false,
}

@StoreConfig({ name: "scoreRendering", resettable: true })
export class ScoreRenderingStore extends Store<ScoreRendering> {
    constructor() {
        super(cloneDeep(initialState))
    }

    /**
     * @deprecated
     * @param callback
     */
    public update(callback) {
        super.update(callback)
    }

    /**
     * This function should always be used to update the store.
     * When modifying data in a way that may increase the score length, set updateScoreLength to true.
     * When modifyin score related data, set scoreWasEdited to true. This will ensure that the editor knows
     * that the score was edited, and react approprietely (for example, by displaying a save button and indicate
     * to the user that a saveable change occurred)
     *
     * @param computeAdditionalMetadata this parameter is for computing the sustain pedal and phrases. This is important
     * to set to true when changing things like the pitch, onset and duration of notes in any way
     */
    public updateStore({
        partial,
        scoreWasEdited,
        updateScoreLength,
        computeAdditionalMetadata,
        metric,
    }: {
        partial: Partial<ScoreRendering>
        scoreWasEdited: boolean
        updateScoreLength: boolean
        computeAdditionalMetadata?: boolean
        metric?: ActivityMetric
    }) {
        const store = this.getValue()

        const toggledLayer = partial.toggledLayer
            ? partial.toggledLayer
            : store.toggledLayer

        const score = partial.score ? partial.score : store.score

        const newScoreWasEdited = scoreWasEdited
            ? scoreWasEdited
            : store.scoreWasEdited

        const autoExtendScore = partial.autoExtendScore
            ? partial.autoExtendScore
            : store.autoExtendScore

        if (computeAdditionalMetadata === undefined) {
            computeAdditionalMetadata = false
        }

        if (computeAdditionalMetadata) {
            ScoreManipulation.computeAdditionalMetadata(
                score,
                "pitched",
                score.layers[toggledLayer],
                {
                    sustainPedalFromChords:
                        this.getValue().sustainPedalFromChords,
                }
            )
        }

        if (autoExtendScore && updateScoreLength) {
            partial = this.autoExtendScore(partial, score, toggledLayer)

            const scoreLengthWithoutPadding = Time.addTwoFractions(
                score.scoreLength,
                Score.SCORE_LENGTH_PADDING,
                true
            )

            this.changeDurationOfComposition(score, scoreLengthWithoutPadding)
        }

        if (!autoExtendScore && updateScoreLength) {
            this.changeDurationOfComposition(score, score.scoreLength)
        }

        if (partial.renderingType === undefined) {
            partial.renderingType = ["None"]
        }

        partial.scoreWasEdited = newScoreWasEdited
        partial.lastUpdateTimestamp = Date.now()
        partial.metricToAdd = metric

        partial.skipCachedCanvas = partial.skipCachedCanvas ? true : false

        this.update(partial)
    }

    public resetScoreWasEdited() {
        this.update({
            scoreWasEdited: false,
        })
    }

    private autoExtendScore(
        partial: Partial<ScoreRendering>,
        score: Score,
        toggledLayer: string
    ) {
        const previousScoreLength = score.scoreLength

        if (score.layers[toggledLayer] !== undefined) {
            score.scoreLength = this.getToggledLayerEnd(score, toggledLayer)
        }

        score.scoreLength = this.getSectionEnd(score)

        let chordsLength = ScoreManipulation.calculateChordsLength(score.chords)
        /**
         * this is to add a padding on the right side of the composition
         * to make sure that everything is visible for the user while editing
         */
        chordsLength = Time.addTwoFractions(
            chordsLength,
            Score.SCORE_LENGTH_PADDING
        )

        if (
            Time.compareTwoFractions(score.scoreLength, chordsLength) === "lt"
        ) {
            score.scoreLength = chordsLength
        }

        if (
            Time.compareTwoFractions(previousScoreLength, score.scoreLength) ===
            "lt"
        ) {
            // todo: check if that really needs to be all or if we can update everything but piano, piano grid?
            partial.renderingType = ["All"]
        }

        partial.score = score

        return partial
    }

    private getSectionEnd(score: Score) {
        return Time.max(
            score.scoreLength,
            Time.addTwoFractions(
                score.sections[score.sections.length - 1].end,
                Score.SCORE_LENGTH_PADDING
            )
        )
    }

    private changeDurationOfComposition(score: Score, scoreLength: string) {
        playerActions.setRealtimeDuration(
            "updateStore",
            Time.convertTimestepsInSeconds(
                TIMESTEP_RES,
                score.tempoMap,
                Time.fractionToTimesteps(TIMESTEP_RES, scoreLength),
                playerActions.hasStartOffset()
            )
        )
    }

    private getToggledLayerEnd(score: Score, toggledLayer: string) {
        const layer: Layer | PercussionLayer = score.layers[toggledLayer]

        let toggledLayerEnd: FractionString = "0"

        if (layer.type === "pitched") {
            toggledLayerEnd = layer.notesObject.getEnd()
        } else if (
            layer.type === "percussion" &&
            (layer as PercussionLayer).patternRegions.length > 0
        ) {
            const pr = (layer as PercussionLayer).patternRegions

            toggledLayerEnd = pr[pr.length - 1].getEnd()
        }

        return Time.max(
            score.scoreLength,
            Time.addTwoFractions(toggledLayerEnd, Score.SCORE_LENGTH_PADDING)
        )
    }
}
