<span class="{{ deviceSpecificClass() }}" av-data="gp-library">
    <div class="content-header">
        <div class="left">
            <div class="gp-hub-title">
                <span *ngIf="showForkedProfilesFor == null"
                    >Styles Library</span
                >
                <span *ngIf="showForkedProfilesFor != null"
                    >Forks of '{{ showForkedProfilesFor.gp.name }}'</span
                >
            </div>
        </div>

        <div class="right">
            <div
                class="what-are-forks"
                *ngIf="showForkedProfilesFor != null"
                [tooltip]="
                    'Forks are styles that have been imported from the library and modified by a user. AIVA displays forks based on relevance and difference from the original styles they were sourced from.'
                "
                [tooltip-position]="'middle-bottom'"
                [tooltip-marginTop]="10"
                [tooltip-width]="285"
                [tooltip-timeout]="0">
                <img src="assets/img/info.svg" />What are style forks?
            </div>
        </div>

        <div class="left">
            <a-button
                class="light"
                label="Back to Library"
                icon="assets/img/back.svg"
                (onClick)="resetForkedProfiles()"
                *ngIf="showForkedProfilesFor != null"></a-button>

            <search
                *ngIf="showForkedProfilesFor == null"
                searchDefault="Search for a style"
                (searchCommand)="initiateSearch($event)"></search>
        </div>

        <div class="right" *ngIf="showForkedProfilesFor == null">
            <a-button
                label="New styles from AIVA!"
                width="210px"
                type="pulsating-icon"
                class="pulsating-button"
                (onClick)="showNewestGPsFromAIVA()"
                *ngIf="showNewAIVAProfiles"></a-button>

            <div class="light-dropdown" (click)="openFiltersModal()">
                <img src="assets/img/filters.svg" />

                <div class="light-dropdown-title">
                    <div class="chord-pack-title">Filters</div>
                </div>
            </div>

            <dropdown
                type="light"
                class="light-create-page"
                width="170px"
                [value]="getSortCategory()"
                [showTooltip]="false"
                [items]="sortOptions"
                (selectedItem)="selectSortCategory($event)"></dropdown>
        </div>

        <div class="right" *ngIf="showForkedProfilesFor != null">
            <div class="light-dropdown" (click)="openFiltersModal()">
                <img src="assets/img/filters.svg" />

                <div class="light-dropdown-title">
                    <div class="chord-pack-title">Filters</div>
                </div>
            </div>

            <dropdown
                type="light"
                class="light-create-page"
                width="170px"
                [value]="getSortCategory()"
                [showTooltip]="false"
                [items]="forkSortOptions"
                (selectedItem)="selectSortCategory($event)"></dropdown>
        </div>

        <div class="filters" *ngIf="getFiltersList().length !== 0">
            <div
                class="filter-tag"
                *ngFor="let filter of getFiltersList(); let f = index"
                (click)="openFiltersModal()">
                {{ filter.name }}
                <img
                    src="assets/img/delete.svg"
                    (click)="
                        deleteFilter(
                            filter.name,
                            filter.index,
                            filter.type,
                            $event
                        )
                    " />
            </div>
        </div>
    </div>

    <gp-list
        [gpType]="showForkedProfilesFor !== null ? 'forked' : 'regular'"
        [interactionType]="'create'"></gp-list>
</span>
