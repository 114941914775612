import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import Layer from "@common-lib/classes/score/layer"
import {
    CWKeyMode,
    CompositionWorkflowSection,
} from "@common-lib/interfaces/composition-workflow.interface"
import {
    CompositionWorkflowType,
    LayersValue,
    LoadingType,
} from "@common-lib/types/general"
import { PlayerService } from "@services/audio/player/player.service"
import { GenerationProfileHTTPService } from "@services/generation-profile/generationprofile.http"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { TokenService } from "@services/token.service"
import { UserService } from "@services/user.service"
import { WindowService } from "@services/window.service"
import { playerQuery } from "../../../../../common-lib/client-only/general/classes/playerStateManagement"
import ScoreRenderingEngine from "../../../../../common-lib/client-only/score-rendering-engine/engine"
import { ParentClass } from "../../parent"
import { CompositionWorkflowHTTPService } from "./composition-workflow-http.service"
import { CWActions, CWActionsType } from "./cw-store/cw.actions"
import { CWQuery } from "./cw-store/cw.query"
import { CWState, CWStore } from "./cw-store/cw.store"
import { TimeSignature } from "@common-lib/types/score"
import { cloneDeep } from "lodash"
import { LAYERS, TIMESTEP_RES } from "@common-lib/constants/constants"
import { Validators } from "@common-lib/modules/composition-workflow/validators"
import { TemplateChord } from "@common-lib/interfaces/score/templateScore"
import { KeySignature } from "@common-lib/interfaces/score/keySignature"
import { SRActionTypes } from "../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import {
    DoublingInstrumentSelectionModalParameters,
    InstrumentSelectionModalParameters,
    ModalService,
} from "@services/modal.service"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import { GenerationProfileManipulation } from "@common-lib/modules/generation-profile-manipulation"
import { GPHarmonyStrategy } from "@common-lib/types/generationProfiles"
import { SourcePackService } from "@services/source-packs/sourcepacks.service"
import Harmony from "@common-lib/classes/generationprofiles/harmony/harmony"
import { KeySignatureModule } from "@common-lib/modules/keysignature.module"
import { ChordManipulation } from "@common-lib/modules/chord-manipulation.module"
import { ConfirmDiscardChangesService } from "@services/confirm-discard-changes.service"
import {
    EditChordData,
    RenderChordsType,
} from "../../../../../common-lib/client-only/score-rendering-engine"
import { BehaviorSubject } from "rxjs"
import { API_ERRORS, CLIENT_ERRORS } from "@common-lib/constants/errors"
import { InitCanvases } from "../../modules/init-canvases.module"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import { CompositionService } from "@services/composition.service"
import { BillingService } from "@services/billing.service"
import { FolderService } from "@services/folder.service"
import { TracksService } from "@services/tracks.service"
import { CompositionWorkflowManipulation } from "@common-lib/modules/composition-workflow-manipulation.module"
import { Time } from "@common-lib/modules/time"
import { IKeyboardEvents } from "@common-lib/interfaces/score/general"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { CacheService } from "@services/cache.service"
import { Similarity } from "@common-lib/modules/similarity.module"
import { ScoreManipulation } from "@common-lib/modules/scoremanipulation"

@Injectable()
export class CompositionWorkflowService
    extends ParentClass
    implements IKeyboardEvents
{
    skipStep2: boolean

    public get gp$() {
        return this.query.select("gp")
    }

    public get lastUndoRedo$() {
        return this.query.select("lastUndoRedo")
    }

    public get step1() {
        return this.query.getValue().step1
    }

    public get harmony(): Harmony {
        return this.step1.harmony
    }

    public step3() {
        return this.query.step3
    }

    public step2() {
        return this.query.step2
    }

    public get engine(): ScoreRenderingEngine {
        return this.query.getValue().engine
    }

    public get gp() {
        return this.query.gp
    }

    public get romanNumerals(): TemplateChord[] | undefined {
        if (!this.engine?.score?.romanNumerals?.length) {
            return []
        }
        return this.engine?.score?.romanNumerals
    }

    public get progressionWasEdited() {
        return this.query.getValue().engine.queries.scoreRendering.getValue()
            .chordsWereEdited
    }

    public get progressionWasEdited$() {
        return this.query.progressionWasEdited$
    }

    public get loading() {
        return this.query.getValue().loading
    }

    public setLoading(loading: boolean) {
        this.actions.emitter$.next({
            type: CWActionsType.setLoading,
            data: {
                loading,
            },
        })
    }

    public get tempo(): number {
        return this.query.tempo
    }

    public get timeSignature(): TimeSignature {
        return this.query.step1.timeSignature
    }

    public get keySignature(): KeySignature {
        return this.harmony.keySignature
    }

    public get pitchClass(): string {
        return this.harmony.keySignature.pitchClass
    }

    public get keyMode(): string {
        return this.harmony.keySignature.keyMode
    }

    public get strategy(): GPHarmonyStrategy {
        return this.query.step1.harmony.strategy
    }

    public get renderChordsType(): RenderChordsType {
        return this.engine?.renderChordsType
    }

    public get allowInstrumentationChanges(): boolean {
        return this.query.getValue().step3.allowInstrumentationChanges
    }

    public get newThematicMaterial(): boolean {
        return this.query.getValue().step3.newThematicMaterial
    }

    public errors$ = new BehaviorSubject<{ message: string }>(null)

    private readonly store: CWStore = new CWStore()
    public readonly query: CWQuery = new CWQuery(this.store)
    public readonly actions: CWActions = new CWActions(this.query, this.store)

    public engine$ = this.query.select("engine")
    public lastUpdate$ = this.query.select("lastUpdate")

    public getHistoryCount(): number {
        return this.actions.manager.getUndoQueueLength()
    }

    constructor(
        private gpHttpService: GenerationProfileHTTPService,
        private router: Router,
        private cwHttpService: CompositionWorkflowHTTPService,
        public instruments: InstrumentsService,
        public token: TokenService,
        public user: UserService,
        public windowService: WindowService,
        private player: PlayerService,
        private modal: ModalService,
        private sourcePacks: SourcePackService,
        private confirmDiscard: ConfirmDiscardChangesService,
        private billingService: BillingService,
        private folder: FolderService,
        private tracks: TracksService,
        private cache: CacheService
    ) {
        super()

        if (this.folder.contentType.getValue() !== "Compositions") {
            this.folder.changeSelectedFolder("")
        }
    }

    public clearHistory() {
        this.actions.emitter$.next({
            type: CWActionsType.clearHistory,
        })
    }

    public play() {
        if (this.player.isPlaying()) {
            this.player.pause()
        } else {
            this.player.play()
        }
    }

    public async rateGeneration(
        rating: "liked" | "disliked",
        harmonyPromptId: string
    ) {
        await this.cwHttpService.rateGeneration(rating, harmonyPromptId)
        this.actions.emitter$.next({
            type: CWActionsType.resetHarmonyPromptId,
        })
    }

    public getGenerationProfileDurations() {
        return GenerationProfileManipulation.getGenerationProfileDurations(
            CompositionService.getValidDurations(
                this.billingService.subscription.getValue()
            )
        )
    }

    public setAllowInstrumentationChanges(value: boolean) {
        this.actions.emitter$.next({
            type: CWActionsType.allowInstrumentationChanges,
            data: {
                value,
            },
            options: {
                isUndoable: false,
            },
        })
    }

    public setSaveWorkflowToAccount(value: boolean) {
        this.actions.emitter$.next({
            type: CWActionsType.saveWorkflowToAccount,
            data: {
                value,
            },
            options: {
                isUndoable: false,
            },
        })
    }

    public setPrompt(prompt: string) {
        this.actions.emitter$.next({
            type: CWActionsType.setPrompt,
            data: {
                value: prompt,
            },
        })
    }

    public selectRandomGP(gp): string {
        const revision = GenerationProfile.getLatestPublishedRevision(
            gp.revisions
        )

        const gpIDs = revision.gpIDs

        return gpIDs[Math.floor(Math.random() * gpIDs.length)]
    }

    public async initializeService(data: {
        cwID?: string
        gpID?: string
        type?: CompositionWorkflowType
    }) {
        await new Promise(resolve => {
            this.actions.emitter$.next({
                type: CWActionsType.initialize,
                data: {
                    cwID: data.cwID,
                    gpID: data.gpID,
                    type: data.type,
                    getCWByID: this.cwHttpService.getCWByID.bind(
                        this.cwHttpService
                    ),
                    getGPByID: this.gpHttpService.getGPByID.bind(
                        this.gpHttpService
                    ),
                    initialisePlayer: this.initialisePlayer.bind(this),
                    instruments: this.instruments.instruments,
                    pitchToChannelMapping:
                        this.instruments.pitchToChannelMapping,
                    drumSamples: this.instruments.drumSamples,
                    userID: this.token.userID,
                    settings: this.user.settings,
                    onComplete: resolve,
                    chordProgression: this.cache.get("chordProgressionCache"),
                },
                options: {
                    isUndoable: false,
                },
            })
        })

        this.cleanCache()

        if (this.query.engine) {
            this.subscribe(this.engine.seekTime$, time => {
                const seconds = Time.convertTimestepsInSeconds(
                    TIMESTEP_RES,
                    this.engine.score.tempoMap,
                    time,
                    this.player.hasStartOffset()
                )

                this.player.setTimeFromSeconds(seconds)
            })
        }
    }

    private cleanCache() {
        this.cache.remove("chordProgressionCache")
    }

    public emptyAction() {
        this.actions.emitter$.next({
            type: CWActionsType.emptyAction,
            data: {},
            options: {
                isUndoable: true,
            },
        })
    }

    public setStep(step: number) {
        this.actions.emitter$.next({
            type: CWActionsType.setStep,
            data: {
                step,
            },
            options: {
                isUndoable: false,
            },
        })
    }

    public async initialisePlayer(id: string, resetTime: boolean) {
        if (resetTime) {
            if (this.player.isPlaying()) {
                await this.player.pause()
            }

            this.player.setTime(0)
        }

        await this.player.initialisePlayerWithPreview(
            this.engine,
            id,
            "Composition Workflow"
        )

        // Wait for instruments to load if this is the desktop app
        if (this.windowService.desktopAppAPI === undefined) {
            return
        }

        await ParentClass.waitUntilTrueForObservable(
            playerQuery.trackBusLoadingPercentage$,
            value => value === 100
        )
    }

    public async regenerateChordProgression(isUndoable) {
        const wasPlaying = playerQuery.status === "playing"
        const result = await new Promise(async resolve => {
            this.actions.emitter$.next({
                type: CWActionsType.regenerateChordProgression,
                data: {
                    onComplete: resolve,
                    chordProgressionGenerator:
                        this.cwHttpService.getGeneratedChordProgression.bind(
                            this.cwHttpService
                        ),
                    sourcePacks: await this.sourcePacks.getHarmonyPacks(),
                },
                options: {
                    isUndoable,
                },
            })
        })

        this.player.setTime(0)

        if (wasPlaying) {
            await this.player.play()
        }

        return result
    }

    public replaceTrackBus(
        layer: Layer,
        instrumentType: "instrument" | "double"
    ) {
        if (instrumentType === "instrument") {
            const params: InstrumentSelectionModalParameters = {
                layer: layer,
                selectedTrackBus:
                    layer.trackBuses.length > 1
                        ? undefined
                        : layer.trackBuses[0],

                pack: undefined,
                allowRecommendations: false,

                onClose: () => {},

                selectNewInstrumentPatch: ((patch: InstrumentPatch) => {
                    this.actions.emitter$.next({
                        type: CWActionsType.changeTrackBus,
                        data: {
                            layerKey: layer.value,
                            patch,
                            selectedTrackBusID: layer.trackBuses[0].id,
                            instruments: this.instruments.instruments,
                        },
                        options: {
                            isUndoable: true,
                        },
                    })

                    return true
                }).bind(this),
            }

            this.modal.modals.instrumentSelection.next(params)
        } else {
            const params: DoublingInstrumentSelectionModalParameters = {
                layer,
                allowRecommendations: false,
                double: undefined,
                pack: undefined,

                selectNewInstrumentPatches: ((
                    newPatches: InstrumentPatch[]
                ) => {
                    this.actions.emitter$.next({
                        type: CWActionsType.addDouble,
                        data: {
                            layerKey: layer.value,
                            patch: newPatches[0],
                            instruments: this.instruments.instruments,
                        },
                        options: {
                            isUndoable: true,
                        },
                    })

                    return true
                }).bind(this),

                onClose: () => {},
            }

            this.modal.modals.doublingInstrumentSelection.next(params)
        }
    }

    private async validateStepData(
        stepToValidate: number,
        skipStep2: boolean = false
    ): Promise<boolean> {
        if (stepToValidate === 0) {
            let result = this.validateFirstStep()

            if (skipStep2 && result) {
                this.setStep(2)
            }

            return result
        } else if (stepToValidate === 1) {
            if (skipStep2) return true
            return this.validateSecondStep()
        }
    }
    /**
     * This function is called whenever user changes the style of the composition workflow
     * we de-coupled this function from the "onDestroy" function, so we don't have to parametrize the onDestroy function
     * to accomodate the edge case of the player service
     */

    public async resetService(): Promise<void> {
        this.query.engine.deleteAllCanvases()

        await this.player.pause()
    }

    public async onDestroy(toOfflinePlayback: boolean = true) {
        this.query.engine.deleteAllCanvases()

        await this.player.pause()

        if (toOfflinePlayback) {
            // we switch to offline playback here, so users can't play back the pattern
            // from the global player bar after closing the AD component and service
            this.player.setOfflinePlayback(
                "CompositionWorkflowService.onDestroy"
            )

            await this.player.loadNextTrack({})
        }
    }

    private validateFirstStep(): boolean {
        const stepData = this.step1

        const loadingValidations = Validators.validateStep1Loading(
            stepData.chordProgressionLoading
        )

        if (loadingValidations.hasError) {
            this.errors$.next(CLIENT_ERRORS.chordProgressionLoadingError)
            return false
        } else if (loadingValidations.isLoading) {
            this.errors$.next(CLIENT_ERRORS.chordProgressionLoading)
            return false
        }

        const cpError = Validators.validateChordProgression(
            this.romanNumerals,
            stepData.timeSignature
        )
        let message = ""

        if (Object.keys(cpError).length > 0) {
            message = CLIENT_ERRORS[Object.keys(cpError)[0]].message

            this.errors$.next({ message })

            return false
        }

        const isTimeSignatureValid = Validators.validateTimeSignature(
            stepData.timeSignature
        )

        if (!isTimeSignatureValid) {
            this.errors$.next({
                message: API_ERRORS.invalidTimeSignature.message,
            })
            return false
        }

        const isTempoValid = Validators.validateTempo(stepData.tempo)

        if (!isTempoValid) {
            this.errors$.next({
                message: CLIENT_ERRORS.invalidTempo.message,
            })
            return false
        }

        return true
    }

    private validateSecondStep(): boolean {
        const stepData = this.step2()
        const layerErrors = Validators.validateLayers(stepData.layers)

        if (Object.keys(layerErrors).length !== 0) {
            Object.keys(layerErrors).forEach(error => {
                this.errors$.next({
                    message: CLIENT_ERRORS[error].message,
                })
            })
            return false
        }

        let isValid = Validators.validateStep2LayersLoading(stepData)

        if (!isValid) {
            this.errors$.next({
                message: CLIENT_ERRORS["layerErrorOrLoading"].message,
            })
            return false
        }

        isValid = Validators.validateStep2LayerDuration(
            stepData.layers,
            this.step1.timeSignature
        )

        if (!isValid) {
            this.actions.emitter$.next({
                type: CWActionsType.resizeNotesToCWMeasureLength,
                data: {
                    layers: stepData.layers,
                    timeSignature: this.step1.timeSignature,
                },
                options: {
                    isUndoable: false,
                },
            })

            return false
        }

        return true
    }

    private async confirmDiscardChanges(
        stepData: {
            index: number
            title: string
            subtitle: string
        },
        numberOfSteps: number
    ): Promise<boolean> {
        let title = "Leaving composition workflow"
        let description =
            "Leaving this page will discard any changes made. Are you sure you want to do that? "

        if (stepData.index === 1) {
            title = "Leaving step 2"
            description =
                "Leaving this step will discard any changes made to the layers. " +
                "Are you sure you want to do that? "
        }

        const promise: Promise<boolean> = new Promise(resolve => {
            this.confirmDiscard.openDoActionsBeforeModal({
                title,
                description,
                continueButtonText: "Continue",
                action: async () => {
                    resolve(true)

                    return {
                        success: true,
                    }
                },
                cancel: () => {
                    resolve(false)
                },
            })
        })

        const result = await promise

        return result
    }

    public async initializeCanvases({
        step,
    }: {
        step: "step1" | "step2-part1" | "step2-part2" | "step3"
    }) {
        let allowChordsEditing = false

        if (step === "step1") {
            allowChordsEditing = true
            await InitCanvases.initCompositionWorkflowCanvas(this.query.engine)
        } else if (step === "step2-part1") {
            let promises = [
                InitCanvases.initChordsEditingCanvas(
                    this.query,
                    allowChordsEditing,
                    (() => {}).bind(this),
                    (() => {}).bind(this)
                ),

                InitCanvases.initPianoRollGridCanvas(this.query.engine, true),
            ]

            for (const layer in this.query.engine.score.layers) {
                promises.push(
                    InitCanvases.initLayerPreviewCanvas(
                        this.query.engine.score.layers[layer],
                        0,
                        this.query.engine
                    )
                )
            }

            await Promise.all(promises)
        } else if (step === "step2-part2") {
            const promises = [
                InitCanvases.initPianoRollGridCanvas(this.query.engine, true),

                InitCanvases.initVerticalScrollingCanvas(this.query.engine),

                InitCanvases.initChordsEditingCanvas(
                    this.query,
                    allowChordsEditing,
                    (() => {}).bind(this),
                    (() => {}).bind(this)
                ),
            ]

            if (this.engine.toggledLayer.type === "pitched") {
                promises.push(
                    InitCanvases.initAccompanimentDesignerCanvas(
                        this.query.engine,
                        34
                    )
                )
                promises.push(
                    InitCanvases.initPianoCanvas(this.query.engine, 34)
                )
            } else {
                for (const trackBus of this.engine.toggledLayer.trackBuses) {
                    promises.push(
                        InitCanvases.initDrumSequencerCanvas(
                            trackBus,
                            this.query.engine
                        )
                    )
                }
            }

            await Promise.all(promises)
        }
    }

    public async initStepData(
        direction: "previous" | "next",
        stepData: {
            index: number
            title: string
            subtitle: string
        },
        numberOfSteps: number,
        skipStep2: boolean = false
    ) {
        let result = true
        this.skipStep2 = skipStep2

        if (direction === "next") {
            // Do validation to ensure that the step is correctly completed before
            // moving on to the next step

            result = await this.validateStepData(stepData.index, skipStep2)
        } else if (direction === "previous") {
            if (stepData.index === 0) {
                this.router.navigate([
                    "create",
                    this.query.getValue().type === "chordProgression"
                        ? "Chord progression"
                        : "Step by step",
                ])
            } else if (stepData.index < 2) {
                // Ask user whether they are ok discarding their changes when switching
                // from step 2 to step 1, or from step 1 to previous component / page

                result = await this.confirmDiscardChanges(
                    stepData,
                    numberOfSteps
                )

                if (result) {
                    await this.resetStep2()
                }
            }
        }

        return direction === "next"
            ? {
                  success: result,
                  error: result ? undefined : true,
              }
            : {
                  success: true,
                  cancel: !result,
              }
    }

    public async resetStep2() {
        return new Promise(resolve => {
            this.actions.emitter$.next({
                type: CWActionsType.resetStep2,
                data: {
                    drumSamples: this.instruments.drumSamples,
                    instruments: this.instruments.instruments,
                    resolve,
                },
                options: {
                    isUndoable: false,
                },
            })
        })
    }

    public async createInitialLayers() {
        if (Object.keys(this.query.step2.layers).length > 0) {
            return
        }

        this.query.engine.srEmitter$.next({
            type: SRActionTypes.deleteLayer,
            data: {
                layer: this.query.engine.score.layers.Chords,
            },
        })

        const gp: GenerationProfile = this.query.getValue().gp
        const layers: GPLayer[] = gp.getAllLayers()

        let promises = []

        for (const layer of layers) {
            promises.push(
                this.generateLayer({
                    layer: layer.name,
                    isUndoable: false,
                    prompt: "",
                })
            )
        }

        return Promise.all(promises)
    }

    public setUseLLM(value: boolean) {
        this.actions.emitter$.next({
            type: CWActionsType.setUseLLM,
            data: {
                value,
            },
            options: {
                isUndoable: false,
            },
        })
    }

    public async generateLayer({
        layer,
        instrument,
        isUndoable,
        prompt,
    }: {
        layer: LayersValue
        instrument?: InstrumentPatch
        isUndoable: boolean
        prompt: string
    }) {
        const allowedLayers = [...LAYERS].filter(l => !l.includes("Ornament"))

        if (!allowedLayers.includes(layer)) {
            return {
                success: false,
                error: "Layer not supported",
            }
        }

        this.actions.emitter$.next({
            type: CWActionsType.generateLayer,
            data: {
                layer,
                instrument,
                prompt,
                instruments: this.instruments.instruments,
                pitchToChannelMapping: this.instruments.pitchToChannelMapping,
                httpCallToAPI: this.cwHttpService.generateLayer.bind(
                    this.cwHttpService
                ),
            },
            options: {
                isUndoable,
            },
        })

        await ParentClass.waitUntilTrueForObservable(
            this.query.select(),
            ((state: CWState) =>
                state.step2.layerLoading[layer]?.finished === true).bind(this)
        )

        const result = this.query.step2.layerLoading[layer]

        return {
            success: result.finished && result.error === undefined,
            error: result.error,
        }
    }

    public async setStrategy(
        strategy: GPHarmonyStrategy,
        withModalCheck: boolean
    ) {
        if (withModalCheck) {
            const shouldContinue = await this.confirmedTSChange("strategy")

            if (!shouldContinue) {
                return
            }
        }

        const sourcePacks = await this.sourcePacks.getHarmonyPacks()

        return new Promise(resolve => {
            this.actions.emitter$.next({
                type: CWActionsType.setStrategy,
                data: {
                    chordProgressionGenerator:
                        this.cwHttpService.getGeneratedChordProgression.bind(
                            this.cwHttpService
                        ),
                    strategy: strategy,
                    sourcePacks: sourcePacks,
                    onComplete: resolve,
                },
                options: {
                    isUndoable: true,
                },
            })
        })
    }

    public async setKeyMode(keyMode: string, withModalCheck: boolean) {
        if (withModalCheck) {
            const shouldContinue = await this.confirmedTSChange("keyMode")

            if (!shouldContinue) {
                return
            }
        }

        return new Promise(async resolve => {
            this.actions.emitter$.next({
                type: CWActionsType.setKeyMode,
                data: {
                    keyMode: keyMode as CWKeyMode,
                    chordProgressionGenerator:
                        this.cwHttpService.getGeneratedChordProgression.bind(
                            this.cwHttpService
                        ),
                    onComplete: resolve,
                    sourcePacks: await this.sourcePacks.getHarmonyPacks(),
                },
                options: {
                    isUndoable: true,
                },
            })
        })
    }

    private async confirmedTSChange(
        type: "timeSignature" | "keyMode" | "strategy"
    ) {
        const data = {
            timeSignature: {
                title: "Changing time signature",
                description:
                    "Changing the time signature will discard any changes made to the chord progression. Are you sure you want to do that?",
            },

            keyMode: {
                title: "Changing key mode",
                description:
                    "Changing the key mode will discard any changes made to the chord progression. Are you sure you want to do that?",
            },

            strategy: {
                title: "Changing harmonic strategy",
                description:
                    "Changing the harmonic strategy will discard any changes made to the chord progression. Are you sure you want to do that?",
            },
        }

        const shouldContinue = await new Promise((resolve, reject) => {
            this.modal.modals.doActionsBefore.next({
                title: data[type].title,
                description: data[type].description,
                continueButtonText: "Continue",
                action: async () => {
                    return {
                        success: true,
                    }
                },

                afterAction: async () => {
                    resolve(true)
                },

                cancel: async () => {
                    resolve(false)
                },
            })
        })

        return shouldContinue
    }

    public async setTimeSignature(
        timeSignature: string,
        withModalCheck: boolean
    ) {
        if (withModalCheck) {
            const shouldContinue = await this.confirmedTSChange("timeSignature")

            if (!shouldContinue) {
                return
            }
        }

        const timeSignatureArr = [
            parseInt(timeSignature.split("/")[0]),
            parseInt(timeSignature.split("/")[1]),
        ]

        if (
            this.timeSignature[0] === timeSignatureArr[0] &&
            this.timeSignature[1] === timeSignatureArr[1]
        ) {
            return
        }

        return new Promise(async resolve => {
            // set the time signature
            this.actions.emitter$.next({
                type: CWActionsType.setTimeSignature,
                data: {
                    timeSignature: timeSignatureArr,
                    chordProgressionGenerator:
                        this.cwHttpService.getGeneratedChordProgression.bind(
                            this.cwHttpService
                        ),
                    onComplete: resolve,
                    sourcePacks: await this.sourcePacks.getHarmonyPacks(),
                },
                options: {
                    isUndoable: true,
                },
            })
        })
    }

    public async setTempo(tempo: number) {
        if (this.query.step1.tempo === tempo) {
            return tempo
        }

        // set the CW and score tempo
        this.actions.emitter$.next({
            type: CWActionsType.setTempo,
            data: {
                tempo: tempo,
            },
            options: {
                isUndoable: true,
            },
        })

        return tempo
    }

    public setPitchClass(pitchClass: string) {
        if (this.query.keySignature.pitchClass === pitchClass) {
            return
        }

        this.actions.emitter$.next({
            type: CWActionsType.setPitchClass,
            data: {
                pitchClass,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public getPitchClassOptions() {
        return this.harmony.getPitchClassOptions()
    }

    public getKeyModeOptions() {
        const keyModes = this.harmony.getKeyModeOptions().map(str => {
            return {
                name: str,
                value: str,
            }
        })

        return keyModes
    }

    public toggleRenderChordsType() {
        const renderChordsType: RenderChordsType =
            this.renderChordsType === "chord-symbol"
                ? "roman-numeral"
                : "chord-symbol"

        this.engine.srEmitter$.next({
            type: SRActionTypes.setRenderChordsType,
            data: {
                type: renderChordsType,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public toggleLocalLLM() {
        this.actions.emitter$.next({
            type: CWActionsType.toggleLocalLLM,
            data: {},
            options: {
                isUndoable: true,
            },
        })
    }

    public async updateWorkflow() {
        const newCWLayers = this.skipStep2
            ? {}
            : await CompositionWorkflowManipulation.updateCompositionWorkflowLayersFromScore(
                  {
                      scoreLayers: this.query.engine.score.layers,
                      cwLayers: Object.keys(this.query.step2.layers).map(
                          (k: string) => this.query.step2.layers[k]
                      ),
                      timeSignature: [4, 4],
                  }
              )

        const section: CompositionWorkflowSection = {
            key_signature:
                KeySignatureModule.convertKeySignatureToMERepresentation(
                    this.query.keySignature
                ),
            time_signature: this.query.timeSignature,
            tempo: this.query.tempo,
            chord_progression: this.romanNumerals,
            layers: Object.keys(newCWLayers).map(k => newCWLayers[k]),
            prompt: this.query.step1.prompt,
        }

        return this.cwHttpService.updateWorkflow({
            section,
            generationProfileID: this.query.gp._id,
            type: this.query.getValue().type,
            name: this.query.step3.name,
            progressionWasEdited:
                this.query.engine.queries.scoreRendering.getValue()
                    .chordsWereEdited,
            saveWorkflowToAccount: this.query.step3.saveWorkflowToAccount,
        })
    }

    private setStep3Loading(loading: { finished: boolean; error?: string }) {
        this.actions.emitter$.next({
            type: CWActionsType.setStep3Loading,
            data: {
                loading,
            },
            options: {
                isUndoable: false,
            },
        })
    }

    public getAllInstruments() {
        const instruments = []

        for (let l in this.query.step2.layers) {
            const layer = this.query.step2.layers[l]
            instruments.concat(layer.instrument)
        }

        return instruments
    }

    public setNewThematicMaterial(newThematicMaterial: boolean) {
        this.actions.emitter$.next({
            type: CWActionsType.setNewThematicMaterial,
            data: {
                value: newThematicMaterial,
            },
            options: {
                isUndoable: false,
            },
        })
    }

    public async generateComposition() {
        this.setStep3Loading({
            finished: false,
        })

        const isValid = Validators.validateCompositionWorkflowThirdStep(
            this.query.step3,
            this.getGenerationProfileDurations().map(d => d.value)
        )

        if (!isValid.isValid) {
            return this.setStep3Loading({
                finished: true,
                error: isValid.error,
            })
        }

        const loading: LoadingType = {
            finished: true,
        }

        try {
            const compositionWorkflowID = await this.updateWorkflow()

            await new Promise(resolve => {
                this.actions.emitter$.next({
                    type: CWActionsType.setSavedWorkflow,
                    data: {
                        resolve,
                    },
                })
            })

            let newThematicMaterial = this.query.step3.newThematicMaterial
            let allowInstrumentationChanges: { [key: string]: boolean } = {}

            for (let layer in this.query.step2.layers) {
                allowInstrumentationChanges[layer] =
                    this.query.step3.allowInstrumentationChanges
            }

            const result = await this.cwHttpService.generateComposition({
                folderID: this.folder.getSelectedFolderID(),
                duration: this.query.step3.duration,
                compositionWorkflowID,
                nbOfCompositions: this.query.step3.nbOfCompositions,
                newThematicMaterial: newThematicMaterial,
                allowInstrumentationChanges: allowInstrumentationChanges,
            })

            await this.tracks.redirectToMyTracks()
        } catch (e) {
            console.error(e)
            loading.error = e
        }

        this.setStep3Loading(loading)
    }

    public triggerAction() {}

    /**
     * IKeyboardEvents implementations
     */

    public keyboardSelect(event: KeyboardEvent) {
        if (!this.query.isStep2Part2) {
            return
        }
        this.query.engine.keyboardSelect(event)
    }

    public zoomIn(factor: number) {
        this.query.engine.zoomIn(factor)
    }

    public zoomOut(factor: number) {
        this.query.engine.zoomOut(factor)
    }

    public deleteSelectedData() {
        if (!this.query.isStep2Part2) {
            return
        }
        this.query.engine.deleteSelectedData()
    }

    public toggleCursorType() {
        if (!this.query.isStep2Part2) {
            return
        }
        this.query.engine.toggleCursorType()
    }

    public cut(): void {
        if (!this.query.isStep2Part2) {
            return
        }
        /**
         * This method should be modified to be applied to the chords as well
         */
        this.query.engine.cut()
    }

    public copy() {
        if (!this.query.isStep2Part2) {
            return
        }
        /**
         * This method should be modified to be applied to the chords as well
         */
        this.query.engine.copy()
    }

    public paste() {
        if (!this.query.isStep2Part2) {
            return
        }
        /**
         * This method should be modified to be applied to the chords as well
         */
        this.query.engine.paste()
    }

    public selectAllNotes() {
        if (!this.query.isStep2Part2) {
            return
        }
        this.query.engine.selectAllNotes()
    }

    public resetZoom() {
        this.query.engine.resetZoom()
    }

    public keyboardMute() {
        if (this.query.stepData.index !== 2) {
            return
        }
        this.query.engine.keyboardMute()
    }
    public keyboardSolo() {
        if (this.query.stepData.index !== 2) {
            return
        }
        this.query.engine.keyboardSolo()
    }
    public keyboardDuplicate() {
        if (this.query.stepData.index !== 2) {
            return
        }
        this.query.engine.keyboardDuplicate()
    }

    public undo() {
        this.actions.emitter$.next({
            type: CWActionsType.undo,
            data: {
                instruments: this.instruments.instruments,
                pitchToChannelMapping: this.instruments.pitchToChannelMapping,
            },
            options: {
                isUndoable: false,
            },
        })
    }

    public redo() {
        this.actions.emitter$.next({
            type: CWActionsType.redo,
            data: {},
            options: {
                isUndoable: false,
            },
        })
    }
}
