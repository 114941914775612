import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import { InstrumentSelectionCompositionWorkflowsParameters } from "@services/modal.service"

@Component({
    selector: "instrument-selection-composition-workflows",
    templateUrl: "instrument-selection-composition-workflows.component.html",
    styles: [],
    styleUrls: ["./instrument-selection-composition-workflows.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstrumentSelectionCompositionWorkflowsModal implements OnInit {
    @Input() parameters: InstrumentSelectionCompositionWorkflowsParameters
    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor(private ref: ChangeDetectorRef) {}

    ngOnInit(): void {}
}
