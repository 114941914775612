import { Component } from '@angular/core'

@Component({
 	selector: 'tutorials',
  	templateUrl: 'tutorials.component.html',
  	styles: []
})
export class TutorialsComponent {

    constructor() {
        
    }

    goTo(url) {
        window.open(url, '_blank')
    }
}