import { Injectable } from "@angular/core"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import { BehaviorSubject } from "rxjs"
import { ApiService } from "./api.service"
import { ModalService } from "./modal.service"
import { AnimationLoopService } from "./animationloop.service"
import SearchItem from "@common-lib/classes/searchitem"
import { RecommendationsService } from "./recommendations.service"
import { InstrumentsService } from "./instruments/instruments.service"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import Layer from "@common-lib/classes/score/layer"
import Pack from "@common-lib/classes/generationprofiles/pack"

@Injectable()
export class DoublesService {
    userGeneratedDoubles = { pitched: [], percussion: [] }
    aivaGeneratedDoubles = { pitched: [], percussion: [] }
    userGeneratedDoublesFolders = {}
    doublesFoldersCreatedByAiva = {}

    refreshDoubleSearchUI: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false)
    selectedSearchItem: BehaviorSubject<SearchItem> =
        new BehaviorSubject<SearchItem>(null)
    draggedSearchItem: BehaviorSubject<SearchItem> =
        new BehaviorSubject<SearchItem>(null)
    selectElement: BehaviorSubject<any> = new BehaviorSubject<any>(null)

    doublesSearchItems = []
    doubleType = "pitched"

    constructor(
        protected animationLoopService: AnimationLoopService,
        protected apiService: ApiService,
        private modalService: ModalService,
        private instruments: InstrumentsService,
        private recommendationsService: RecommendationsService
    ) {
        
    }

    initData(doubles, doublesFolders) {
        this.userGeneratedDoubles = this.preprocessUserDoubles(doubles.user)
        this.aivaGeneratedDoubles = this.preprocessAIVADoubles(doubles.aiva)

        this.userGeneratedDoublesFolders = doublesFolders.userGenerated
        this.doublesFoldersCreatedByAiva = doublesFolders.createdByAiva
    }

    triggerDoublesSearchRendering({
        layer,
        pack,
        allowRecommendations,
    }: {
        layer: GPLayer | Layer
        pack: Pack
        allowRecommendations: boolean
    }) {
        this.getDoublesSearchItems({
            layer,
            pack,
            allowRecommendations,
        })

        this.refreshDoubleSearchUI.next(true)
    }

    updateDouble(double: InstrumentPatch) {
        let createdByAiva =
            double.createdByAiva == null || double.createdByAiva == false
                ? false
                : true

        return this.apiService
            .authRequest("/double/update", { double: double }, "primary", true)
            .then(res => {
                if (double.patchID == "") {
                    res.double.createdByAiva = createdByAiva
                    double = InstrumentPatch.fromJSON(res.double)

                    if (createdByAiva == true) {
                        this.aivaGeneratedDoubles[double.type].push(double)
                    } else {
                        this.userGeneratedDoubles[double.type].push(double)
                    }
                } else {
                    if (
                        double.createdByAiva != null &&
                        double.createdByAiva == true
                    ) {
                        for (let d of this.aivaGeneratedDoubles[double.type]) {
                            if (d.patchID == double.patchID) {
                                d.name = double.name
                                d.patches = double.patches

                                break
                            }
                        }
                    } else {
                        for (let d of this.userGeneratedDoubles[double.type]) {
                            if (d.patchID == double.patchID) {
                                d.name = double.name
                                d.patches = double.patches

                                break
                            }
                        }
                    }
                }

                return Promise.resolve(double)
            })

            .catch(err => {
                console.log(err)
                this.apiService.handleError(err)

                return undefined
            })
    }

    updateDoubleFolder(
        folder: SearchItem,
        layer: GPLayer | Layer,
    ) {
        return this.apiService
            .authRequest(
                "/double/updateFolder",
                {
                    folderID: folder.id,
                    parentID: folder.item.parentID,
                    name: folder.name,
                },
                "primary",
                true
            )
            .then(res => {
                let folders = this.userGeneratedDoublesFolders[layer.type]

                if (
                    folder.createdByAiva != null &&
                    folder.createdByAiva == true
                ) {
                    folders = this.doublesFoldersCreatedByAiva[layer.type]
                }

                for (let f of folders) {
                    if (f._id == folder.id) {
                        f.name = folder.name

                        break
                    }
                }

                // this.triggerDoublesSearchRendering({
                //     layer,
                //     pack,
                //     allowRecommendations,
                // })

                return Promise.resolve(folder)
            })

            .catch(err => {
                console.log(err)
                this.apiService.handleError(err)

                return Promise.resolve(undefined)
            })
    }

    deleteDoubleFolder(folderID) {
        return this.apiService
            .authRequest(
                "/double/deleteFolder",
                { folderID: folderID },
                "primary",
                true
            )

            .catch(err => {
                return Promise.resolve(this.apiService.handleError(err))
            })
    }

    deleteDouble(patchID) {
        return this.apiService
            .authRequest(
                "/double/remove",
                { doubleID: patchID },
                "primary",
                true
            )

            .catch(err => {
                return Promise.resolve(this.apiService.handleError(err))
            })
    }

    duplicateDouble(doubleObject: InstrumentPatch) {
        return this.apiService
            .authRequest(
                "/double/duplicate",
                { double: doubleObject },
                "primary",
                true
            )
            .then(res => {
                const double = InstrumentPatch.fromJSON(res.double)

                this.userGeneratedDoubles[double.type].push(double)

                this.selectedSearchItem.next(
                    this.doublesSearchItems.find(i => i.createdByAiva == false)
                )

                this.triggerDoublesSearchRendering(double.type)
            })

            .catch(err => {
                return Promise.resolve(this.apiService.handleError(err))
            })
    }

    preprocessUserDoubles(doubles) {
        let result = { pitched: [], percussion: [] }

        for (let type in doubles) {
            result[type] = []

            for (let double of doubles[type]) {
                let instPatch = InstrumentPatch.decode(double)

                if (instPatch.deleted) {
                    continue
                }

                if (result[type] == null) {
                    result[type] = []
                }

                result[type].push(instPatch)
            }
        }

        return result
    }

    preprocessAIVADoubles(doubles) {
        let result = { pitched: [], percussion: [] }

        for (let type in doubles) {
            result[type] = []

            for (let doubleID in doubles[type]) {
                const double = doubles[type][doubleID]

                let instPatch = InstrumentPatch.decode(double)

                if (instPatch.deleted) {
                    continue
                }

                if (result[type] == null) {
                    result[type] = []
                }

                result[type].push(instPatch)
            }
        }

        return result
    }

    /**
     * searches the item in the current doubles collections and removes it from there, so it will not pop up again
     * when closing and re-opening the doubles search
     * @param searchItem SearchItem
     */
    deleteItemFromDoublesCollection(
        searchItem: SearchItem,
        layer: GPLayer,
        pack: AccompanimentPack,
        allowRecommendations: boolean
    ) {
        const id = searchItem.id
        const createdByAiva = searchItem.createdByAiva

        if (createdByAiva) {
            if (searchItem.type == "folder") {
                let index = this.doublesFoldersCreatedByAiva[
                    layer.type
                ].findIndex(i => i._id == id)

                if (index != -1) {
                    this.doublesFoldersCreatedByAiva[layer.type].splice(
                        index,
                        1
                    )
                }
            } else {
                let index = this.aivaGeneratedDoubles[layer.type].findIndex(
                    i => i.patchID == id
                )

                if (index != -1) {
                    this.aivaGeneratedDoubles[layer.type].splice(index, 1)
                }
            }
        } else {
            if (searchItem.type == "folder") {
                let index = this.userGeneratedDoublesFolders[
                    layer.type
                ].findIndex(i => i._id == id)

                if (index != -1) {
                    this.userGeneratedDoublesFolders[layer.type].splice(
                        index,
                        1
                    )
                }
            } else {
                let index = this.userGeneratedDoubles[layer.type].findIndex(
                    i => i.patchID == id
                )

                if (index != -1) {
                    this.userGeneratedDoubles[layer.type].splice(index, 1)
                }
            }
        }

        let parentID =
            searchItem.item == null || Object.keys(searchItem.item).length == 0
                ? ""
                : searchItem.item.parentID
        let parentElement = this.findInDoublesSearchItems(
            parentID,
            this.getDoublesSearchItems({
                layer,
                pack,
                allowRecommendations,
            }),
            searchItem.createdByAiva == true
        )

        if (parentElement) {
            this.selectedSearchItem.next(parentElement)
        }

        this.triggerDoublesSearchRendering({
            layer,
            pack,
            allowRecommendations,
        })
    }

    getSearchSubFolders(
        parentID,
        path,
        createdByAiva,
        type
    ): Array<SearchItem> {
        let folders = this.userGeneratedDoublesFolders[type]
        let doubles = this.userGeneratedDoubles[type]

        if (createdByAiva) {
            folders = this.doublesFoldersCreatedByAiva[type]
            doubles = this.aivaGeneratedDoubles[type]
        }

        let subFolders = []
        let subDoubles = []

        for (var i = 0; i < folders.length; i++) {
            if (parentID == folders[i].parentID) {
                let newPath = path + "/" + folders[i].name

                if (folders[i].deleted) {
                    continue
                }

                if (path == "") {
                    newPath = folders[i].name
                }

                let recursion = this.getSearchSubFolders(
                    folders[i]._id,
                    newPath,
                    createdByAiva,
                    type
                )
                let searchItem = new SearchItem(
                    folders[i]._id,
                    recursion,
                    folders[i].name,
                    newPath,
                    "folder",
                    folders[i],
                    createdByAiva
                )

                subFolders.push(searchItem)
            }
        }

        for (let double of doubles) {
            if (double.deleted != null && double.deleted == true) {
                continue
            }

            if (double.folderID == parentID) {
                let searchItem = new SearchItem(
                    double.patchID,
                    null,
                    double.name,
                    path,
                    "patch",
                    double,
                    createdByAiva,
                    false
                )

                if (type == "pitched") {
                    this.setDoublePitchRange(searchItem)
                } else {
                    this.setDoublePercussionFunction(searchItem)
                }

                subDoubles.push(searchItem)
            }
        }

        return subFolders.concat(subDoubles)
    }

    getDoublesSearchItems({
        layer,
        pack,
        allowRecommendations,
    }: {
        layer: GPLayer | Layer
        pack: Pack
        allowRecommendations: boolean
    }) {
        let searchItems = [
            new SearchItem(
                "",
                this.getSearchSubFolders(
                    "",
                    "Created by AIVA",
                    true,
                    layer.type
                ),
                "Created by AIVA",
                "Created by AIVA",
                "folder",
                {},
                true
            ),
            new SearchItem(
                "",
                this.getSearchSubFolders(
                    "",
                    "My doubling instruments",
                    false,
                    layer.type
                ),
                "My doubling instruments",
                "My doubling instruments",
                "folder",
                {},
                false
            ),
        ]

        this.doublesSearchItems = searchItems
        this.doubleType = layer.type

        if (allowRecommendations && layer instanceof GPLayer) {
            this.recommendationsService.getTaggedDoubles({
                type: layer.type as "pitched" | "percussion",
                doubles: this.doublesSearchItems,
                layer,
                pack,
            })
        }

        return this.doublesSearchItems
    }

    findInDoublesSearchItems(id, array, createdByAiva) {
        if (id == null || array == null) {
            return
        }

        for (let i of array) {
            if (i.id == id && i.createdByAiva == createdByAiva) {
                return i
            }

            let found = this.findInDoublesSearchItems(
                id,
                i.values,
                createdByAiva
            )

            if (found) {
                return found
            }
        }

        return
    }

    getPathAsIDs(searchItem: SearchItem) {
        // TODO: note to myself for whatever reason reversing the path again leads to a wrong order, hence we reverse the path
        // at the part of the code where it is used, which is bad design. Please Niclas find a way to fix it
        const pathAsIDs = Object.assign(
            [],
            this.getPathAsIDsFromBottomToTop(searchItem)
        )
        const reversedPathAsIDs = Object.assign([], pathAsIDs).slice().reverse()

        return pathAsIDs
    }

    getPathAsIDsFromBottomToTop(searchItem: SearchItem) {
        let ids = []

        ids = ids.concat(searchItem.id)

        if (
            searchItem.item == null ||
            Object.keys(searchItem.item).length == 0 ||
            searchItem.item["parentID"] == ""
        ) {
            ids = ids.concat("")
        } else {
            let array = this.doublesSearchItems.find(
                i => i.createdByAiva == searchItem.createdByAiva
            ).values
            let parentElement = this.findInDoublesSearchItems(
                searchItem.item["parentID"],
                array,
                searchItem.createdByAiva == true
            )

            if (parentElement != null) {
                ids = ids.concat(this.getPathAsIDs(parentElement))
            }
        }

        return ids
    }

    moveDoubleContentToFolder(
        searchItem: SearchItem,
        newFolderID,
        layer: GPLayer,
        pack: Pack,
        allowRecommendations: boolean
    ) {
        if (searchItem == null || newFolderID == null) {
            return
        }

        let id = searchItem.id
        let contentType = "DoublesFolders"

        if (searchItem.type != "folder") {
            contentType = "InstrumentDoubles"
        }

        if (searchItem.createdByAiva == true) {
            if (searchItem.type == "folder") {
                let index = this.doublesFoldersCreatedByAiva[
                    layer.type
                ].findIndex(i => i._id == id)

                if (index != -1) {
                    this.doublesFoldersCreatedByAiva[layer.type][
                        index
                    ].parentID = newFolderID
                }
            } else {
                let index = this.aivaGeneratedDoubles[layer.type].findIndex(
                    i => i.patchID == id
                )

                if (index != -1) {
                    this.aivaGeneratedDoubles[layer.type][index].folderID =
                        newFolderID
                }
            }
        } else {
            if (searchItem.type == "folder") {
                let index = this.userGeneratedDoublesFolders[
                    layer.type
                ].findIndex(i => i._id == id)

                if (index != -1) {
                    this.userGeneratedDoublesFolders[layer.type][
                        index
                    ].parentID = newFolderID
                }
            } else {
                let index = this.userGeneratedDoubles[layer.type].findIndex(
                    i => i.patchID == id
                )

                if (index != -1) {
                    this.userGeneratedDoubles[layer.type][index].folderID =
                        newFolderID
                }
            }
        }

        this.triggerDoublesSearchRendering({
            layer,
            pack,
            allowRecommendations,
        })

        return this.apiService.authRequest(
            "/content/moveToFolder",
            { contentID: id, folderID: newFolderID, type: contentType },
            "primary",
            true
        )
    }

    /**
     * sets the pitchRange of a double by the pitch ranges of the patches the double holds
     * @param searchItem SearchItem
     * @returns SearchItem
     */
    setDoublePitchRange(searchItem: SearchItem) {
        if (searchItem.type != "patch") {
            return searchItem
        }

        if (
            searchItem == null ||
            searchItem.item == null ||
            searchItem.item["patches"] == null ||
            searchItem.item["patches"].length == 0
        ) {
            return searchItem
        }

        let patches = searchItem.item["patches"]
        let lowestNote = 0
        let highestNote = 127

        for (let patch of patches) {
            const instrument = this.getInstrumentObject(patch.patch.instrument)

            if (instrument == null) {
                continue
            }

            lowestNote = Math.max(
                lowestNote,
                instrument.lowest_note + 12 * patch.octave
            )
            highestNote = Math.min(
                highestNote,
                instrument.highest_note + 12 * patch.octave
            )
        }

        let doublePitchRange = [lowestNote, highestNote]
        let differenceOfPitches = doublePitchRange[1] - doublePitchRange[0]
        let minimumRange = 18 // 1.5 ocatves of difference should exist

        if (differenceOfPitches < minimumRange) {
            if (differenceOfPitches % 2 != 0) {
                differenceOfPitches += 1
            }

            let differenceToMinimumRange = minimumRange - differenceOfPitches

            doublePitchRange[0] -= differenceToMinimumRange / 2
            doublePitchRange[1] += differenceToMinimumRange / 2
        }

        searchItem.pitchRange = doublePitchRange

        return searchItem
    }

    /**
     * sets the percussionFunction of a double by the percussionFunctions of the patches the double holds
     * @param searchItem SearchItem
     * @returns SearchItem
     */
    setDoublePercussionFunction(searchItem: SearchItem) {
        if (searchItem.type != "patch") {
            return searchItem
        }

        if (
            searchItem == null ||
            searchItem.item == null ||
            searchItem.item["patches"] == null ||
            searchItem.item["patches"].length == 0
        ) {
            return searchItem
        }

        let patches = searchItem.item["patches"]
        let percussionFunction = []

        for (let patch of patches) {
            const instrument = this.getInstrumentObject(patch.patch.instrument)

            if (
                instrument == null ||
                instrument.patches == null ||
                instrument.patches[0] == null ||
                instrument.patches[0].percussion_function == null
            ) {
                continue
            }

            const patchPercussionFunction =
                instrument.patches[0].percussion_function

            for (let pf of patchPercussionFunction) {
                if (percussionFunction.includes(pf)) {
                    continue
                }

                percussionFunction.push(pf)
            }
        }

        searchItem.percussionFunction = percussionFunction

        return searchItem
    }

    getInstrumentObject(instrumentName) {
        const instruments = this.instruments.instruments

        let instrumentObject

        for (let section in instruments) {
            for (let i = 0; i < instruments[section].length; i++) {
                if (instruments[section][i].name == instrumentName) {
                    instrumentObject = instruments[section][i]

                    break
                }
            }
        }

        return instrumentObject
    }
}
