import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    Input,
    ViewChild,
} from "@angular/core"
import Patch from "@common-lib/classes/score/patch"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import {
    FindSimilarInstrumentsModalParameters,
    ModalService,
} from "@services/modal.service"
import { RecommendationsService } from "@services/recommendations.service"
import { LabelType } from "@angular-slider/ngx-slider"
import { gpOptions } from "@common-lib/constants/gp_options"
import { Options } from "@angular-slider/ngx-slider"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import SearchItem from "@common-lib/classes/searchitem"
import { SearchInstrumentPreviewService } from "@services/searchinstrumentpreview.service"
import LayerPreview from "@common-lib/classes/generationprofiles/layerpreview"
import { AnalyticsService } from "@services/analytics.service"
import { ActivityMetric } from "../../../../../../common-lib/general/classes/activitymetric"
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar"
import { ContextMenu } from "@clientmodels/contextmenu"
import { WindowService } from "@services/window.service"
import { InstrumentsService } from "@services/instruments/instruments.service"

@Component({
    selector: "find-similar-instruments",
    templateUrl: "./find-similar-instruments.component.html",
    styleUrls: ["find-similar-instruments.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindSimilarInstrumentsComponent implements OnInit {
    @Input() parameters: FindSimilarInstrumentsModalParameters
    @Output() close = new EventEmitter<any>()

    @ViewChild(PerfectScrollbarDirective, { static: false })
    perfectScrollbarDirectiveRef?: PerfectScrollbarDirective

    loading: boolean = true
    perfectScrollbarOptions = { useBothWheelAxes: false, suppressScrollX: true }
    gpInstrumentHoveredIndex

    similarInstruments: {
        total: SearchItem[]
        toDisplay: SearchItem[]
    } = {
        total: [],
        toDisplay: [],
    }

    similarity: { min: number; max: number } = { min: 2, max: 2 }
    numberOfInstruments: number = null
    removedPatches = []
    currentPreview

    filter
    filterList = []
    filterButtonCoordinates = { x: 0, y: 0 } // we save them so we can click on filter badges/pills and still open the filter context menu from the filter button position
    filterIsActive

    constructor(
        private windowService: WindowService,
        private modalService: ModalService,
        private instrumentsService: InstrumentsService,
        private searchInstrumentPreviewService: SearchInstrumentPreviewService,
        private recommendationService: RecommendationsService,
        private gpService: GenerationProfileService,
        private ref: ChangeDetectorRef,
        private analyticsService: AnalyticsService
    ) {}

    async ngOnInit() {
        this.loading = true

        this.recommendationService.saveRecommendationsSettings(
            this.recommendationService.defaultRecommendationsSettings
        )

        setTimeout(() => {
            this.updateSimilarInstruments()
        }, 0)

        this.modalService.contextMenus.findSimilarInstrumentsFilter.subscribe(
            res => {
                this.changeFilter(res?.data?.changes)
            }
        )

        this.instrumentsService.query
            .select("refreshSearchUI")
            .subscribe(res => {
                this.ref.detectChanges()
            })
    }

    getSimilarityOptions() {
        let similarityOptions = gpOptions.instrumentSimilarity
        let options: Options = similarityOptions.similarity

        options["animate"] = false
        options["disabled"] = this.shouldDisplayAllInstruments()

        options["translate"] = (value: number, label: LabelType): string => {
            if (label == LabelType.Floor) {
                return similarityOptions.similarityDisplayValues[options.floor]
            } else if (label == LabelType.Ceil) {
                return similarityOptions.similarityDisplayValues[options.ceil]
            }

            return ""
        }

        options = Object.assign({}, options) // needed for change detecting the new options

        return options
    }

    changeSimilarity(event) {
        this.similarity = { min: event.value, max: event.highValue }

        this.updateInstrumentsToDisplay()

        this.ref.detectChanges()

        this.perfectScrollbarDirectiveRef?.update()
    }

    updateSimilarInstruments() {
        this.loading = true

        if (
            this.similarInstruments.total?.length == null ||
            this.similarInstruments.total?.length == 0
        ) {
            const instrumentsSearchItems = this.parameters.instruments
                .slice()
                .map(i => this.getSearchItemFromInstrumentPatch(i))
            this.similarInstruments.total =
                this.recommendationService.getCompatibleInstruments({
                    layer: this.parameters.layer,
                    pack: this.parameters.pack,
                    instruments: instrumentsSearchItems,
                })
            this.removedPatches = []

            if (this.filter == null) {
                this.filter = this.getFilterObject()

                this.filterList = []

                for (let key in this.filter.categories.data) {
                    if (this.filter.categories.data[key] == true) {
                        this.filterList.push({ key: "categories", value: key })
                    }
                }

                this.ref.detectChanges()
            }
        }

        this.updateInstrumentsToDisplay()
    }

    updateInstrumentsToDisplay() {
        this.getInstrumentsToDisplay()

        this.numberOfInstruments = Math.min(
            this.similarInstruments.toDisplay.length,
            25
        )

        this.loading = false

        this.ref.detectChanges()
    }

    shouldDisplayAllInstruments() {
        return (
            this.similarInstruments?.total?.length <=
            gpOptions?.instrumentSimilarity?.maxNumberOfInstruments
        )
    }

    getInstrumentsToDisplay() {
        this.similarInstruments.toDisplay = []

        for (let inst of this.similarInstruments.total) {
            if (this.isValidAccordingToFilter(inst)) {
                this.similarInstruments.toDisplay.push(inst)
            }
        }

        this.similarity = { min: 0, max: 2 }

        return this.similarInstruments.toDisplay
    }

    getNumberOfInstrumentsOptions() {
        let options = gpOptions.instrumentSimilarity.numberOfInstruments
        options["animate"] = false
        options.ceil = this.similarInstruments.toDisplay.length
        options["disable"] = options.ceil == 0

        return Object.assign({}, options)
    }

    changeNumberOfInstruments(event) {
        if (
            event.value >
                gpOptions.instrumentSimilarity.maxNumberOfInstruments ||
            event.value > this.similarInstruments.toDisplay.length
        ) {
            return
        }

        this.numberOfInstruments = event.value
        this.perfectScrollbarDirectiveRef?.update()

        this.ref.detectChanges()
    }

    removeInstrumentPatch(instrumentPatch: InstrumentPatch) {
        const index = this.similarInstruments.toDisplay.findIndex(
            i => i.item.patchID == instrumentPatch.patchID
        )

        if (index != -1) {
            let tempArr = this.similarInstruments.toDisplay.slice()

            tempArr.splice(index, 1)

            this.similarInstruments.toDisplay = tempArr

            this.removedPatches.push(instrumentPatch.patchID)

            this.getInstrumentsToDisplay()

            const scrollThreshold = Math.min(
                this.numberOfInstruments,
                this.similarInstruments.toDisplay.length -
                    this.removedPatches.length
            )

            if (scrollThreshold <= 10) {
                this.perfectScrollbarDirectiveRef?.scrollToTop(0, 1)
                this.perfectScrollbarDirectiveRef?.update()
            }
        }

        this.ref.detectChanges()
    }

    preview(instrumentPatch: InstrumentPatch, event) {
        if (!instrumentPatch) {
            return
        }

        if (this.windowService.desktopAppAPI === undefined) {
            return this.modalService.downloadDesktopAppModal.next(true)
        }

        this.gpService.pausePreview()

        this.currentPreview = instrumentPatch.patchID

        let promise = Promise.resolve()

        if (this.shouldPreviewInGPContext(instrumentPatch)) {
            promise = this.previewInGPContext(instrumentPatch)
        } else {
            promise = this.previewWithoutGPContext(instrumentPatch)
        }

        promise.then(() => {
            this.ref.detectChanges()

            // we don't have any more direct way to check to update the UI from the outside
            setTimeout(() => {
                // this.searchInstrumentPreviewService.currentlyPlayingPatchID = null
                // this.currentPreview = null
                this.ref.detectChanges()
            }, 10000)
        })
    }

    previewWithoutGPContext(instrumentPatch: InstrumentPatch) {
        let searchItem = this.getSearchItemFromInstrumentPatch(instrumentPatch)

        return this.searchInstrumentPreviewService.playPreview(
            searchItem,
            event,
            searchItem
        )
    }

    async previewInGPContext(instrumentPatch: InstrumentPatch) {
        const patchID = this.getInstrumentToBuildGPPreviewFrom(instrumentPatch)
        const preview = await this.getGPPreview(instrumentPatch)

        return this.gpService.preview(
            this.parameters.layer.name,
            this.parameters.pack["id"],
            patchID,
            preview
        )
    }

    shouldPreviewInGPContext(instrumentPatch: InstrumentPatch) {
        return false

        if (this.getInstrumentToBuildGPPreviewFrom(instrumentPatch) == null) {
            return false
        }

        return true
    }

    getInstrumentToBuildGPPreviewFrom(
        instrumentPatch: InstrumentPatch
    ): string | null {
        for (let instrument of this.parameters.pack.instruments) {
            for (let p of instrument.patches) {
                const instPatch = instrumentPatch.patches[0].patch
                const patch = p.patch

                // if (patch.playing_style == instPatch.playing_style && patch.articulation == instPatch.articulation){
                return instrument.patchID
                // }
            }
        }

        return
    }

    getSearchItemFromInstrumentPatch(instrumentPatch: InstrumentPatch) {
        if (!instrumentPatch) {
            return
        }

        const type =
            instrumentPatch.patchID.split(".")[0] == "d" ? "double" : "patch"

        return new SearchItem(
            instrumentPatch.patchID,
            null,
            instrumentPatch.name,
            "",
            type,
            instrumentPatch,
            true
        )
    }

    getPlaybackIcon(instrumentPatch: InstrumentPatch, type = "noGPContext") {
        if (this.shouldPreviewInGPContext(instrumentPatch)) {
            return this.gpService.getPlaybackIcon(
                this.parameters.layer.name,
                instrumentPatch
            )
        }

        let icon = "assets/img/player/play.svg"

        if (
            this.searchInstrumentPreviewService.currentlyPlayingPatchID ==
            instrumentPatch?.patchID
        ) {
            icon = "assets/img/player/pause.svg"
        }

        return icon
    }

    isPreviewLoading(instrumentPatch: InstrumentPatch) {
        if (this.shouldPreviewInGPContext(instrumentPatch)) {
            for (let preview of this.gpService.currentPreviews) {
                if (
                    preview.layer == this.parameters.layer.name &&
                    preview.status == "loading" &&
                    this.currentPreview == instrumentPatch.patchID
                ) {
                    return true
                }
            }
        } else if (
            this.searchInstrumentPreviewService.loading &&
            this.searchInstrumentPreviewService.currentlyPlayingPatchID ==
                instrumentPatch.patchID
        ) {
            return true
        }

        return false
    }

    addInstrumentsToPack() {
        if (!this.similarInstruments.toDisplay?.length) {
            return
        }

        for (let instrument of this.similarInstruments.toDisplay.slice(
            0,
            this.numberOfInstruments
        )) {
            if (this.removedPatches.includes(instrument.item.patchID)) {
                continue
            }

            this.gpService
                .addInstrumentPatchToPack(
                    this.parameters.pack["idx"],
                    this.parameters.layer.name,
                    instrument.item
                )
                .then(() => {
                    this.ref.detectChanges()
                })
        }

        this.gpService.setAsUpdated("instrument")

        this.analyticsService.addActivity(
            ActivityMetric.ADD_SIMILAR_INSTRUMENTS,
            {
                layer: this.parameters.layer.name,
                generationProfileID: this.gpService.generationProfile._id,
                similarity: this.similarity,
                numberOfInstruments: this.numberOfInstruments,
            }
        )

        this.closeModal()
    }

    async getGPPreview(instrumentPatch: InstrumentPatch) {
        const patchID = this.getInstrumentToBuildGPPreviewFrom(instrumentPatch)

        const layerPreview = LayerPreview.fromJSON({
            layer: this.parameters.layer.name,
            packID: this.parameters.pack.packID,
            patchID: patchID,
            status: "loading",
        })

        let preview = await this.gpService.getPreview([layerPreview])

        for (let track of preview.tracks) {
            track.name = instrumentPatch.patchID
            track.instrument =
                instrumentPatch.patchID.split(".")[0] +
                "." +
                instrumentPatch.patchID.split(".")[1]
        }

        return preview
    }

    changeFilter(changes) {
        this.updateFilterList(changes)

        if (!this.loading) {
            this.updateInstrumentsToDisplay()
        }

        setTimeout(() => {
            this.ref.detectChanges()

            this.perfectScrollbarDirectiveRef?.update()
        }, 0)
    }

    getDefaultInstrumentCategoriesFilter() {
        let options = {}

        if (this.similarInstruments?.total == null) {
            return options
        }

        let flattenedInstruments =
            this.recommendationService.getFlattenedListOfTaggedInstruments({
                type: "pitched",
                instruments: this.instrumentsService.instrumentsOrganizedByPath,
                layer: this.parameters.layer,
                pack: this.parameters.pack,
            })
        let currentPackParentFolders = []
        let patchIDs = []

        for (let inst of this.parameters.instruments) {
            for (let p of inst.patches) {
                const patch = new Patch(
                    p.patch.section,
                    p.patch.instrument,
                    p.patch.playing_style,
                    p.patch.articulation,
                    p.patch.granulationEngine
                )

                if (!patchIDs.includes(patch.getFullName())) {
                    patchIDs.push(patch.getFullName())
                }
            }
        }

        for (let inst of flattenedInstruments) {
            const parentFolder = inst?.path?.split("/")[0]

            if (patchIDs.includes(inst.item.patchID)) {
                currentPackParentFolders.push(parentFolder)
            }
        }

        for (let inst of this.similarInstruments.total) {
            const parentFolder = inst?.path?.split("/")[0]

            if (parentFolder && options[parentFolder] == null) {
                options[parentFolder] =
                    currentPackParentFolders.includes(parentFolder)
            }
        }

        return options
    }

    getFilterObject() {
        let filter = {
            categories: {
                name: "Categories",
                data: this.getDefaultInstrumentCategoriesFilter(),
            },
        }

        return filter
    }

    showFilters(event) {
        if (event != null) {
            this.filterButtonCoordinates.x = event.x
            this.filterButtonCoordinates.y = event.y
        }

        this.modalService.contextMenus.findSimilarInstrumentsFilter.next(
            new ContextMenu(
                this.filterButtonCoordinates.x,
                this.filterButtonCoordinates.y,
                { filter: this.filter },
                [],
                null
            )
        )
    }

    isFiltering() {
        for (let filterCategory in this.filter) {
            for (let key in this.filter[filterCategory].data) {
                if (this.filter[filterCategory].data[key] == true) {
                    this.filterIsActive = true
                    return true
                }
            }
        }

        this.filterIsActive = false

        return false
    }

    isValidAccordingToFilter(instrument: SearchItem) {
        let isFiltering = this.isFiltering()

        if (!isFiltering) {
            return true
        }

        let filteredItems = this.getFilteredItems()

        let isValidTags = true

        if (filteredItems.categories.length > 0) {
            isValidTags = filteredItems.categories.includes(
                instrument.path.split("/")[0]
            )
        }

        return isValidTags
    }

    hasArrayElements(array) {
        return array != null && array.length > 0
    }

    getFilteredItems() {
        let filteredCategories = []

        for (let key in this.filter["categories"].data) {
            if (this.filter["categories"].data[key] == true) {
                filteredCategories.push(key)
            }
        }

        return { categories: filteredCategories }
    }

    updateFilterList(changes: { key; value; checked }) {
        if (this.isFiltering() == false) {
            this.filterList = []
            return
        }

        if (changes == null) {
            return
        }

        if (changes.checked == true) {
            this.filterList.push({ key: changes.key, value: changes.value })
        } else {
            let index = this.filterList.findIndex(
                f => f.key == changes.key && f.value == changes.value
            )

            if (index != -1) {
                this.filterList.splice(index, 1)
            }
        }
    }

    deleteFilter(filter, index, event) {
        if (this.filter[filter.key].data[filter.value] == true) {
            this.filter[filter.key].data[filter.value] = false
            this.filterList.splice(index, 1)
            this.updateInstrumentsToDisplay()
            this.ref.detectChanges()
        }
    }

    closeModal(step = 0) {
        this.parameters.onClose()

        this.gpService.pausePreview()
        this.close.emit(step)
    }
}
