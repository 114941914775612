import { v4 as uuidv4 } from "uuid"
import { LayerType } from "../../constants/constants"

export type AccompanimentType = LayerType.ACCOMPANIMENT | LayerType.BASS | LayerType.ORNAMENTS | LayerType.PERCUSSION

export module AccompanimentManipulation {
    export function createPackID(env: string) {
        return env != "production" ? "staging_" + uuidv4() : uuidv4()
    }

    export function getLowestNoteByAccompanimentType(type:AccompanimentType) {
        if (type === LayerType.ACCOMPANIMENT) {
            return getLowestNoteByLayer(LayerType.CHORDS)
        }

        return getLowestNoteByLayer(type)
    }

    export function getLowestNoteByLayer(layerName:string){
        let lowestNote

        if(layerName == "Chords"){
            lowestNote = 47
        }
        
        else if (layerName == "Extra"){
            lowestNote = 50
        }
        
        else if (layerName == "Extra_1"){
            lowestNote = 53
        }
        
        else if (layerName == "Extra_2"){
            lowestNote = 56
        }

        else if (layerName.toLowerCase().includes("ornaments")){
            lowestNote = 47
        }

        else if (layerName == "Bass") {
            lowestNote = 28
        }

        return lowestNote
    }
}
