<div class="top-container">
    <div class="top-container-left">
        <a-button
            label="Back to workflow"
            type="round-icon-and-text"
            class="rounded-square"
            [icon]="'assets/img/back.svg'"
            (click)="
                service.engine.toggleLayer(service.engine.toggledLayer.value)
            ">
        </a-button>
    </div>

    <div class="top-container-center">
        Edit {{ service.engine.toggledLayer.getName() }} layer
    </div>

    <div class="top-container-right"></div>
</div>

<div
    class="container"
    [ngClass]="{ 'container-percussion': layerType === 'percussion' }"
    av-data="cw-step2-p2">
    <div class="top-button-container" av-data="cw-step2-p2-controls">
        <div class="top-button-container-left">
            <div class="column" *ngIf="layerType === 'pitched'">
                <span class="title">Zoom</span>
                <resizer
                    [engine]="service.engine"
                    [attr.av-data]="'cw-step2-p2-zoom'"></resizer>
            </div>
        </div>
        <div
            class="top-button-container-right"
            [ngClass]="{
                'top-button-container-right-percussion':
                    layerType === 'percussion'
            }">
            <div class="column" *ngIf="layerType === 'pitched'">
                <span class="title">Note Editing</span>

                <slide-toggle-with-titles
                    left="Selection"
                    right="Drawing"
                    class="cw-step2-part2"
                    [toggled]="service?.engine?.cursorType === 'pencil'"
                    (onChange)="toggledCursorType()"
                    [attr.av-data]="
                        'cw-step2-p2-pointer-type'
                    "></slide-toggle-with-titles>
            </div>

            <div class="column">
                <span class="title">Solo/Mute</span>
                <mute-and-solo
                    [engine]="service.engine"
                    [mute]="mute.bind(this)"
                    [solo]="solo.bind(this)"
                    [trackBusses]="layer.trackBuses"
                    [configs]="muteSoloConfigs"
                    [attr.av-data]="'cw-step2-p2-mute-solo'"></mute-and-solo>
            </div>

            <div class="column" *ngIf="layerType === 'percussion'">
                <span class="title">Note resolution</span>
                <dropdown
                    type="light"
                    [showArrow]="true"
                    class="cw-step2-part2-dropdown"
                    [value]="noteRes"
                    [description]="''"
                    [showTooltip]="false"
                    [items]="noteResolutions"
                    tooltip="Change the resolution of the drum pattern"
                    (selectedItem)="selectResolution($event)"
                    tooltip-width="200"
                    tooltip-position="middle-bottom"
                    #noteResolutionDropdown>
                </dropdown>
            </div>

            <div class="column">
                <span class="title">Instruments</span>

                <dropdown-button
                    [title]="instrument.name"
                    [width]="'200px'"
                    (click)="changeInstrument($event)"
                    [lineHeight]="'29px'"
                    [attr.av-data]="
                        'cw-step2-p2-instruments'
                    "></dropdown-button>
            </div>
        </div>
    </div>

    <div class="play-head-wrapper" *ngIf="layerType === 'pitched'">
        <play-head
            id="play-head-position"
            class="play-head-position"
            [engine]="service.engine"></play-head>
    </div>

    <div class="wrapper-container">
        <div
            id="pianoroll-grid"
            class="canvas-container"
            *ngIf="layerType === 'pitched'"></div>
    </div>

    <div class="wrapper-container" *ngIf="layerType === 'pitched'">
        <div id="chords-editing-canvas" class="canvas-container"></div>
    </div>

    <div
        class="accompaniment-designer-container"
        *ngIf="layerType === 'pitched'"
        av-data="cw-step2-p2-editor">
        <div
            id="piano"
            class="canvas-container"
            [ngClass]="{ crosshatch: harmonyLocked }"></div>

        <div
            id="accompaniment-designer"
            class="canvas-container"
            [ngClass]="{ crosshatch: harmonyLocked }"></div>

        <div
            id="vertical-scrolling"
            class="canvas-container"
            [ngClass]="{ crosshatch: harmonyLocked }"></div>
    </div>

    <div
        class="drum-sequencers-container"
        *ngIf="layerType === 'percussion'"
        [perfectScrollbar]="scrollConfig">
        <sequencer
            [engine]="service.engine"
            [showPatternSelectionBar]="false"
            [allowTimestepClick]="true"
            [isCW]="true"></sequencer>
    </div>
</div>

<menu-modifiers
    [modifiers]="trackBusSettings"
    *ngIf="trackBusSettings !== undefined"
    (close)="changeInstrument($event, true)"></menu-modifiers>
