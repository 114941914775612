<span class="{{ class }}">
    <div
        class="progress-bar-container"
        [ngClass]="{ label: label }"
        [ngStyle]="{ width: width ? width : 'auto' }">
        <div class="progress" [style.width.%]="getPercentage()"></div>
    </div>

    <div class="progress-number" *ngIf="label">
        {{ progress }} / {{ total }}
    </div>
</span>
