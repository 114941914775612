import { Injectable } from "@angular/core"
import { ParentClass } from "../../parent"
import { WindowService } from "@services/window.service"
import { ScoreDecoding } from "@common-lib/modules/score-transformers/scoreDecoding"
import { InstrumentsService } from "./instruments.service"
import { v4 as uuidv4 } from "uuid"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import { TemplateScore } from "@common-lib/interfaces/score/templateScore"
import Score from "@common-lib/classes/score/score"
import { playerQuery } from "../../../../../common-lib/client-only/general/classes/playerStateManagement"

@Injectable()
export class InstrumentsPreviewService extends ParentClass {
    constructor(
        private instruments: InstrumentsService,
        private windowService: WindowService
    ) {
        super()
    }

    private async generatePreviewScore(
        pitch,
        instrumentPatch: InstrumentPatch
    ): Promise<TemplateScore> {
        if (this.windowService.desktopAppAPI === undefined) {
            return
        }

        const template: TemplateScore =
            await this.instruments.getTemplateScore()

        const note = template.tracks[0].track[0]
        note.pitch = [pitch - 5]

        const secondNote = template.tracks[0].track[1]
        secondNote.pitch = [pitch + 3]

        const score: Score = new Score({
            templateScore: template,
            instrumentReferences: this.instruments.instruments,
            samplesMap: this.instruments.drumSamples,
        })
        let selectedLayer = score.layers["Chords"]

        score.removeTrackBus(0, selectedLayer)

        for (let patch of instrumentPatch.patches) {
            let patchID = patch.patch.getFullName()

            const beats = [
                {
                    start: 0,
                    end: 50,
                    id: uuidv4(),
                },
            ]

            score.addTrackBus(
                undefined,
                patchID,
                selectedLayer,
                patch.octave,
                this.instruments.instruments,
                beats,
                0,
                0,
                0,
                0,
                false,
                false,
                false,
                null
            )
        }

        return ScoreDecoding.toTemplateScore({
            score: score,
            realTimeSampler: true,
            layersToSelect: [selectedLayer],
            trackBussesToSelect: selectedLayer.trackBuses,
        }).templateScore
    }

    public async playPreviewOnRTSampler(
        pitch: number,
        instrumentPatch: InstrumentPatch,
        offset: number
    ) {
        if (this.windowService.desktopAppAPI === undefined) {
            return
        }

        const previewScore = await this.generatePreviewScore(
            pitch,
            instrumentPatch
        )

        const args = { sentAt: Date.now(), score: previewScore, id: "Chords" }

        await this.windowService.samplerAPI.loadAudioSamplesInMemory(args)
        await this.windowService.samplerAPI.startRealTimeContext({
            startTime: 0,
            kicks: []
        })

        previewScore["processingStartTime"] = Date.now()

        await this.windowService.samplerAPI.playNotes({
            score: previewScore,
            layer: null,
            volumeLevel: playerQuery.volumeLevel,
            offset: offset,
        })
    }
}
