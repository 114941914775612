import { Injectable } from "@angular/core"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import HarmonyPack from "@common-lib/classes/generationprofiles/harmony/harmonypack"
import MelodyPack from "@common-lib/classes/generationprofiles/melody/melodypack"
import { GetAccompanimentPacksBody } from "@common-lib/interfaces/api/accompaniment.api"
import { FormDescriptionPacks } from "@common-lib/interfaces/music-engine/generationprofile"
import { ApiService } from "@services/api.service"

@Injectable()
export class SourcePackHttpService {
    constructor(private api: ApiService) {}

    public async getHarmonyPacks(): Promise<HarmonyPack[]> {
        try {
            const res = await this.api.authRequest(
                "/data/getHarmonyPacks",
                {},
                "primary",
                true
            )

            return res.packs.map(pack => {
                return HarmonyPack.fromJSON(pack)
            })
        } catch (err) {
            this.api.handleError(err)

            return undefined
        }
    }

    public async getMelodyPacks(): Promise<MelodyPack[]> {
        try {
            const res = await this.api.authRequest(
                "/data/getMelodyPacks",
                {},
                "primary",
                true
            )

            return res.packs.map(pack => {
                return MelodyPack.fromJSON(pack)
            })
        } catch (err) {
            this.api.handleError(err)

            return undefined
        }
    }

    public async getFormDescriptionsPacks(): Promise<FormDescriptionPacks> {
        try {
            const res = await this.api.authRequest(
                "/data/getFormDescriptionsPacks",
                {},
                "primary",
                true
            )

            return res.packs as FormDescriptionPacks
        } catch (err) {
            this.api.handleError(err)

            return undefined
        }
    }

    public async getAccompanimentPacks(
        parameters: GetAccompanimentPacksBody
    ): Promise<AccompanimentPack[]> {
        try {
            const res = await this.api.authRequest(
                "/data/getAccompanimentPacks",
                parameters,
                "primary",
                true
            )

            return res.packs.map(p => {
                return AccompanimentPack.fromJSON(p)
            })
        } catch (err) {
            this.api.handleError(err)

            return undefined
        }
    }
}
