<div class="modal-title">Resume downloading all instruments</div>
<div class="modal-close"><img src="assets/img/close.svg" (click)="closeModal()"></div>

<div class="loading-info" *ngIf="loading && error == null">
    <div class="loader"></div>
    <div>Preparing instrument download, this may take a minute.</div>
</div>

<div class="confirm-resume-download" *ngIf="!loading && error == null && spaceIsAvailable">
    <div class="text">
        <div class="continue">The app was closed while downloading instruments. Do you want to resume downloading all instruments?</div>
    </div>
    <a-button width="50%" label="Cancel" type="text" class="text-button" (onClick)="closeModal(false)"></a-button>
    <a-button class="secondary" width="50%" label="Resume download" (onClick)="confirmResumeDownload()"></a-button>
</div>

<div class="not-enough-space" *ngIf="loading == false && error == null && spaceIsAvailable == false">
    <img src="assets/img/warning.svg">
    <div class="text">
        <div>There is not enough space left on your machine.</div>
        <div class="continue">Please free up at least <b>{{ additionalSpaceNecessary }}GB</b> and try again.</div>
    </div>
    <a-button class="secondary" width="100%" label="Close" (onClick)="closeModal()"></a-button>
</div>

<div class="error" *ngIf="error != null">
    {{ error }}
</div>