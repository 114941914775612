import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from "@angular/core"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import {
    GP_HARMONY_STRATEGY,
    MAX_TEMPO,
    MIN_GP_TEMPO,
    SUPPORTED_TIME_SIGNATURES,
} from "@common-lib/constants/constants"
import { CompositionWorkflowService } from "@services/composition-workflow/composition-workflow.service"
import { Subject, takeUntil } from "rxjs"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../../types/dropdownItemType"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import { Option } from "../../reusable/btn-select/btn-select.component"
import { CWActionsType } from "@services/composition-workflow/cw-store/cw.actions"
import {
    BtnSelectModifier,
    DropdownButtonModifier,
    DropdownModifier,
    MenuModifiers,
    NumberInputModifier,
    SlideToggleModifier,
} from "../../reusable/menu-modifiers/menu-modifiers.component"
import { GPHarmonyStrategy } from "@common-lib/types/generationProfiles"
import { CWKeyMode } from "@common-lib/interfaces/composition-workflow.interface"
import { playerQuery } from "../../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { ParentClass } from "../../../parent"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { ModalService } from "@services/modal.service"
import { TutorialService } from "@services/tutorial.service"
import { environment } from "@environments/environment"

@Component({
    selector: "composition-workflow-step-1",
    templateUrl: "./composition-workflow-step-1.component.html",
    styleUrls: ["./composition-workflow-step-1.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositionWorkflowStep1Component
    extends ParentClass
    implements OnInit
{
    public gp: GenerationProfile
    public harmonyPromptId: string

    public get tempo(): number {
        return this.service.tempo
    }

    public get loading(): { finished: boolean; error?: string } {
        return this.service.step1.chordProgressionLoading
    }

    public get selectedPitchClass(): DropdownItemType<string> {
        const pitchClass = this.service.pitchClass

        return {
            name: pitchClass,
            value: pitchClass,
        }
    }

    public get isDesktopApp(): boolean {
        return this.service.windowService.desktopAppAPI !== undefined
    }

    public get selectedKeyMode(): DropdownItemType<string> {
        const keyMode = this.service.keyMode as CWKeyMode

        return {
            name: keyMode,
            value: keyMode,
        }
    }

    public prompt: string = ""

    public get selectedStrategy(): DropdownItemType<string> {
        const strategy = this.service.strategy as GPHarmonyStrategy

        return {
            name: strategy,
            value: strategy,
        }
    }

    public get instrumentsLoadingPercentage() {
        return playerQuery.trackBusLoadingPercentage
    }

    public openStyleMenu() {
        this.modalService.modals.styleModalForCW.next({
            selectGPForCW: async gp => {
                const gpID = this.service.selectRandomGP(gp)
                this.modalService.modals.styleModalForCW.next({ loading: true })
                await this.service.resetService()
                await this.service.initializeService({
                    gpID: gpID,
                    type: this.service.query.type,
                })
                await this.initialize("openStyleMenu")
                this.modalService.modals.styleModalForCW.next(undefined)
                this.modalService.modals.successModal.next({
                    title: "Style Change",
                    subtitle:
                        "The style for this composition workflow has been changed",
                    note: `Please note: \n Changing the style preserves the chord progression. To view the results create a composition or move to step 2`,
                })
            },
            type: this.service.query.type,
            subtitle: "Select a style for your Composition Workflow",
        })
    }

    public setTempo(value): void {
        value = Math.max(MIN_GP_TEMPO, value)
        value = Math.min(
            GenerationProfile.getMaxTempo(
                this.service.query.getValue().gp.settings
            ),
            value
        )

        this.service.setTempo(value)
    }

    public get engine(): ScoreRenderingEngine {
        return this.service.engine
    }

    public timeSignatures: Option[] = [
        { value: "2/4" },
        { value: "3/4" },
        { value: "4/4" },
        { value: "6/8" },
        { value: "12/8" },
    ]
    public keyModes: DropdownItemType<string>[] = []
    public pitchClasses: DropdownItemType<string>[] = []
    public strategies: DropdownItemType<string>[] = GP_HARMONY_STRATEGY.map(
        strategy => {
            return {
                name: strategy,
                value: strategy,
            }
        }
    )

    private unsubscribe$ = new Subject<null>()

    public keySignatureMenu: MenuModifiers | undefined

    public chordProgressionSettings: MenuModifiers | undefined
    public hideRateButtons = false

    constructor(
        public service: CompositionWorkflowService,
        private cd: ChangeDetectorRef,
        private modalService: ModalService,
        private tutorialService: TutorialService
    ) {
        super()
    }

    async ngOnInit() {
        this.subscribe(
            this.service.gp$.pipe(takeUntil(this.unsubscribe$)),
            gp => {
                this.gp = gp
                this.setKeyModeOptions()
                this.setPitchClassOptions()
            }
        )

        this.subscribe(playerQuery.trackBusLoadingPercentage$, percentage => {
            this.cd.detectChanges()
        })

        this.subscribe(this.service.lastUndoRedo$, async () => {
            await this.initialize("lastUndoRedo")
        })

        this.service.resetZoom()

        this.subscribe(this.service.query.select("step1"), step1 => {
            this.prompt = step1.prompt
            this.harmonyPromptId = step1.harmonyPromptId
            this.hideRateButtons = false
            this.cd.detectChanges()
        })

        this.subscribe(this.tutorialService.initTutorial$, val => {
            if (val) {
                this.tutorialService.initChordProgressionTutorial(true)
            }
        })
    }

    public bestPractises() {
        window.open(
            "https://aiva.crisp.help/en/article/best-practises-for-text-prompts-in-composition-workflows-1b2djll/?bust=1705572271974",
            "_blank"
        )
    }

    public async selectKeyMode(keyMode) {
        try {
            await this.service.setKeyMode(
                keyMode,
                this.service.progressionWasEdited
            )
            this.setPitchClassOptions()

            await this.initCanvases()
        } catch (error) {
            console.log(error)
        }
    }

    public async selectStrategy(strategy: GPHarmonyStrategy) {
        try {
            await this.service.setStrategy(
                strategy,
                this.service.progressionWasEdited
            )
            this.setKeyModeOptions()
            this.setPitchClassOptions()

            await this.initCanvases()
        } catch (error) {
            console.log(error)
        }
    }

    public toggleChordProgressionSettings(event: MouseEvent, close: boolean) {
        if (close) {
            this.chordProgressionSettings = undefined

            return
        }

        const style: DropdownButtonModifier = {
            text: "Style",
            type: "dropdown-button",
            width: "208px",
            lineHeight: "30px",
            data: this.gp.name,
            dataSetter: (() => {
                this.openStyleMenu()

                this.toggleChordProgressionSettings(undefined, true)
            }).bind(this),
        }

        const displayNumerals: SlideToggleModifier = {
            text: "Display numerals",
            type: "slide-toggle",
            data: this.service.renderChordsType === "roman-numeral",
            dataSetter: ((data: boolean) => {
                this.toggleRenderChordsType()

                return this.service.renderChordsType === "roman-numeral"
            }).bind(this),
            avData: "slide-toggle-slider",
        }

        const ks: DropdownButtonModifier = {
            text: "Key Signature",
            type: "dropdown-button",
            width: "120px",
            lineHeight: "30px",
            data:
                this.service.keySignature.pitchClass +
                " " +
                this.service.keySignature.keyMode,
            dataSetter: (() => {
                this.toggleKeySignatureMenu(
                    {
                        x: this.chordProgressionSettings.coordinates.x,
                        y: this.chordProgressionSettings.coordinates.y,
                    },
                    this.chordProgressionSettings.width
                )

                this.chordProgressionSettings = undefined
            }).bind(this),
        }

        const ts: BtnSelectModifier = {
            text: "Time signature",
            type: "btn-select",
            data: {
                value:
                    this.service.timeSignature[0] +
                    "/" +
                    this.service.timeSignature[1],
            },
            options: SUPPORTED_TIME_SIGNATURES.map(ts => {
                return { value: ts[0] + "/" + ts[1] }
            }),
            widthPerCell: 37,
            dataSetter: ((data: Option) => {
                this.selectTimeSignature(data.value)

                return data
            }).bind(this),
        }

        const tempo: NumberInputModifier = {
            text: "Tempo",
            type: "number-input",
            min: MIN_GP_TEMPO,
            max: MAX_TEMPO,
            data: this.service.tempo,
            unit: "QPM",
            dataSetter: (data: number) => {
                this.setTempo(data)

                return data
            },
            avData: "tempo",
        }

        const options = [style, displayNumerals, ks, ts, tempo]

        if (featureFlags.enableLocalLLM && environment.production === false) {
            const localLLM: SlideToggleModifier = {
                text: "Local LLM",
                type: "slide-toggle",
                data: this.service.query.getValue().toggleLocalLLM,
                dataSetter: ((data: boolean) => {
                    this.service.toggleLocalLLM()

                    return this.service.query.getValue().toggleLocalLLM
                }).bind(this),
                avData: "slide-toggle-slider",
            }

            options.unshift(localLLM)
        }

        this.chordProgressionSettings = {
            options,
            coordinates: {
                x: event.x,
                y: event.y,
            },
            width: "400px",
            title: "Settings",
        }

        this.cd.detectChanges()
    }

    public getFeatureFlags() {
        return featureFlags
    }

    public toggleKeySignatureMenu(
        event,
        width?: string,
        clickedOutside = false
    ) {
        if (clickedOutside) {
            this.keySignatureMenu = undefined

            return
        }

        const strategyDropdown: DropdownModifier = {
            text: "Harmonic strategy",
            type: "dropdown",
            data: this.selectedStrategy,
            options: this.strategies,
            dataSetter: ((data: DropdownSelection<string>) => {
                this.selectStrategy(data.new.value)
            }).bind(this),
        }

        const pitchClassDropdown: DropdownModifier = {
            text: "Pitch class",
            type: "dropdown",
            data: this.selectedPitchClass,
            options: this.pitchClasses,
            dataSetter: ((data: DropdownSelection<string>) => {
                this.selectPitchClass(data.new.value)
            }).bind(this),
        }

        const modeDropdown: DropdownModifier = {
            text: "Key mode",
            type: "dropdown",
            data: this.selectedKeyMode,
            options: this.keyModes,
            dataSetter: ((data: DropdownSelection<string>) => {
                this.selectKeyMode(data.new.value)
            }).bind(this),
        }

        this.keySignatureMenu = {
            options: [strategyDropdown, pitchClassDropdown, modeDropdown],
            coordinates: {
                x: event.x,
                y: event.y,
            },
            width,
            title: "Edit Key Signature",
            buttons: [],
        }
    }

    public async selectTimeSignature(timeSignature: string) {
        try {
            await this.service.setTimeSignature(
                timeSignature,
                this.service.progressionWasEdited
            )

            await this.initCanvases()
        } catch (error) {
            console.log(error)
        }
    }

    private initCanvases() {
        this.cd.detectChanges()

        return this.service.initializeCanvases({
            step: "step1",
        })
    }

    public selectPitchClass(pitchClass: string): void {
        this.service.setPitchClass(pitchClass)
    }

    public async rateGeneration(action: "liked" | "disliked") {
        this.hideRateButtons = true
        await this.service.rateGeneration(action, this.harmonyPromptId)
    }

    async ngAfterViewInit() {
        if (
            this.engine.score.romanNumerals === undefined ||
            this.engine.score.romanNumerals.length === 0
        ) {
            await this.regenerateChordProgression(undefined, true, false, false)
        } else {
            await this.initialize("ngAfterViewInit")
        }
    }

    public async regenerateChordProgression(
        event: MouseEvent | undefined,
        force: boolean,
        isUndoable: boolean,
        animation: boolean
    ) {
        if (
            !force &&
            this.service.query.getValue().step1.chordProgressionLoading
                .finished === false &&
            this.service.query.getValue().step1.chordProgressionLoading
                .error === undefined
        ) {
            return
        }

        const result = await this.service.regenerateChordProgression(isUndoable)

        this.cd.detectChanges()

        if (result !== undefined) {
            await this.initialize("regenerateChordProgression")
        }
    }

    async initialize(origin: string) {
        if (this.loading.error) return
        this.cd.detectChanges()

        await this.initCanvases()
    }

    private setKeyModeOptions(): void {
        this.keyModes = this.service.getKeyModeOptions()

        if (this.keySignatureMenu !== undefined) {
            this.toggleKeySignatureMenu(
                {
                    x: this.keySignatureMenu.coordinates.x,
                    y: this.keySignatureMenu.coordinates.y,
                },
                this.keySignatureMenu.width
            )
        }
    }

    private setPitchClassOptions(): void {
        this.pitchClasses = this.service.getPitchClassOptions()

        if (this.keySignatureMenu !== undefined) {
            this.toggleKeySignatureMenu(
                {
                    x: this.keySignatureMenu.coordinates.x,
                    y: this.keySignatureMenu.coordinates.y,
                },
                this.keySignatureMenu.width
            )
        }
    }

    public toggleRenderChordsType() {
        this.service.toggleRenderChordsType()
        this.cd.detectChanges()
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()

        this.unsubscribe$.next(null)

        this.service.actions.emitter$.next({
            type: CWActionsType.clearCanvases,
            data: {
                step: "step1",
            },
        })
    }
}
