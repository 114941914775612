import { ChangeDetectionStrategy, Component } from "@angular/core"
import { ParentClass } from "../../../parent"
import { Router } from "@angular/router"
import GenerationProfile from "../../../../../../common-lib/general/classes/generationprofiles/generationprofile"

@Component({
    selector: "create-with-chord-progression",
    templateUrl: "create-with-chord-progression.component.html",
    styleUrls: ["create-with-chord-progression.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateWithChordProgression extends ParentClass {
    constructor(private router: Router) {
        super()
    }

    public selectGPForCW(gp) {
        const gpID = this.selectRandomGP(gp)
        this.router.navigate([
            "composition-workflow/",
            "chordProgression",
            gpID,
        ])
    }

    private selectRandomGP(gp): string {
        const revision = GenerationProfile.getLatestPublishedRevision(
            gp.revisions
        )

        const gpIDs = revision.gpIDs

        return gpIDs[Math.floor(Math.random() * gpIDs.length)]
    }
}
