<div class="playlists-content" *ngIf="trackView === 'playlists'">
    <playlists></playlists>
</div>

<div class="trackslist-content" *ngIf="trackView !== 'playlists'">
    <div
        id="track-view-titles"
        class="trackslist-header"
        [ngClass]="{
            'trackslist-single-playlist': trackView === 'single-playlist',
            'track-view-list-gp': embedType === 'Styles',
            'track-view-list-compositions': embedType === 'Compositions'
        }">
        <div class="track-icon"></div>
        <!-- <div *ngFor="let header of tableHeaders" [hidden]="embedType == 'Influences'" [ngClass]="{ 'track-title-influence': header.id == 'title' && embedType == 'Influences', 'track-title-mobile' : header.mobile === true }" class="track-title-wrap {{ header.cssClass }}" (click)="sort(header.sortBy)">
            {{ header.name }}
            <img class="sort-icon" *ngIf="getSortValue().category == header.sortBy && getSortValue().type == 1" src="assets/img/toggle_up.svg">
            <img class="sort-icon" *ngIf="getSortValue().category == header.sortBy && getSortValue().type == -1" src="assets/img/toggle_down.svg">
        </div> -->
        <div
            class="track-title-wrap track-title track-title-mobile"
            [ngClass]="{ 'track-title-influence': embedType === 'Influences' }"
            (click)="sort('name')">
            Title
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'name' &&
                    getSortValue().type === 1
                "
                src="assets/img/toggle_up.svg" />
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'name' &&
                    getSortValue().type === -1
                "
                src="assets/img/toggle_down.svg" />
        </div>

        <div
            class="track-title-wrap track-from track-title-mobile"
            *ngIf="embedType === 'Compositions'"
            (click)="sort('preset')">
            Source
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'preset' &&
                    getSortValue().type === 1
                "
                src="assets/img/toggle_up.svg" />
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'preset' &&
                    getSortValue().type === -1
                "
                src="assets/img/toggle_down.svg" />
        </div>

        <div
            class="track-title-wrap track-instrumentation track-title-mobile"
            *ngIf="embedType !== 'Styles'"
            (click)="sort('ensemble')">
            Instrumentation
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'ensemble' &&
                    getSortValue().type === 1
                "
                src="assets/img/toggle_up.svg" />
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'ensemble' &&
                    getSortValue().type === -1
                "
                src="assets/img/toggle_down.svg" />
        </div>

        <div
            class="track-title-wrap track-key track-title-mobile track-parameter"
            (click)="sort('keySignature')">
            Key
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'keySignature' &&
                    getSortValue().type === 1
                "
                src="assets/img/toggle_up.svg" />
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'keySignature' &&
                    getSortValue().type === -1
                "
                src="assets/img/toggle_down.svg" />
        </div>

        <div
            class="track-title-wrap track-bpm track-parameter track-title-mobile"
            (click)="sort('tempo')">
            Tempo
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'tempo' &&
                    getSortValue().type === 1
                "
                src="assets/img/toggle_up.svg" />
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'tempo' &&
                    getSortValue().type === -1
                "
                src="assets/img/toggle_down.svg" />
        </div>

        <!-- <div class="track-title-wrap track-timesignature track-title-mobile track-parameter" *ngIf="trackView !== 'single-playlist'" (click)="sort('timeSignature')">Meter  -->
        <div
            class="track-title-wrap track-timesignature track-title-mobile track-parameter"
            *ngIf="trackView !== 'single-playlist'"
            (click)="sort('timeSignature')">
            Meter
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'timeSignature' &&
                    getSortValue().type === 1
                "
                src="assets/img/toggle_up.svg" />
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'timeSignature' &&
                    getSortValue().type === -1
                "
                src="assets/img/toggle_down.svg" />
        </div>

        <div
            class="track-title-wrap track-creationdate track-parameter"
            (click)="sort('creationDate')">
            Created
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'creationDate' &&
                    getSortValue().type === 1
                "
                src="assets/img/toggle_up.svg" />
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'creationDate' &&
                    getSortValue().type === -1
                "
                src="assets/img/toggle_down.svg" />
        </div>

        <div
            class="track-title-wrap track-modificationdate track-parameter"
            *ngIf="embedType === 'Compositions'"
            (click)="sort('lastGeneration')">
            Modified
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'lastGeneration' &&
                    getSortValue().type === 1
                "
                src="assets/img/toggle_up.svg" />
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'lastGeneration' &&
                    getSortValue().type === -1
                "
                src="assets/img/toggle_down.svg" />
        </div>

        <div
            class="track-title-wrap track-duration track-parameter"
            *ngIf="embedType !== 'Styles'"
            (click)="sort('duration')">
            Duration
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'duration' &&
                    getSortValue().type === 1
                "
                src="assets/img/toggle_up.svg" />
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'duration' &&
                    getSortValue().type === -1
                "
                src="assets/img/toggle_down.svg" />
        </div>

        <div
            class="track-title-wrap track-rank track-parameter"
            (click)="sort('rank')"
            *ngIf="trackView === 'single-playlist'">
            Rank
            <div class="tooltip">
                Your compositions are ranked based on your listening time and
                other criteria
            </div>

            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'rank' &&
                    getSortValue().type === 1
                "
                src="assets/img/toggle_up.svg" />
            <img
                class="sort-icon"
                *ngIf="
                    getSortValue().category === 'rank' &&
                    getSortValue().type === -1
                "
                src="assets/img/toggle_down.svg" />
        </div>
    </div>

    <div
        class="tracks-list-body"
        id="track-view-list"
        [perfectScrollbar]="scrollConfig"
        (psScrollY)="onScrollYEvent($event)"
        (mouseleave)="releaseDrag()"
        (clickOutside)="resetSelection()"
        *ngIf="!compositionsLoading"
        [ngClass]="{
            'track-view-list-subfolder':
                getPreviousFolder() != null && !compositionsLoading,
            'track-view-list-gp': embedType === 'Styles',
            'track-view-list-compositions': embedType === 'Compositions'
        }">
        <!-- Parent Folder Block -->
        <div
            class="list-item-composition list-item-folder list-item-back"
            *ngIf="
                getPreviousFolder() != null &&
                !compositionsLoading &&
                trackView !== 'single-playlist'
            "
            (mouseenter)="parentFolderIsHovered = true"
            (mouseleave)="parentFolderIsHovered = false"
            (mouseup)="releaseDragFolder(getPreviousFolder().folderID)"
            (dblclick)="openFolderByID(getPreviousFolder().folderID, $event)"
            [ngClass]="{
                'list-item-right-clicked': parentFolderIsHovered,
                'list-item-drag-hovered':
                    parentFolderIsHovered && dragBlockContent != null
            }">
            <div class="folder-icon">
                <img src="assets/img/back.svg" />
            </div>

            <div
                class="track-title track-title-mobile"
                (dblclick)="
                    openFolderByID(getPreviousFolder().folderID, $event)
                ">
                Back to <i>{{ getPreviousFolder().name }}</i>
            </div>
        </div>

        <div
            class="track-view-list-container"
            [style.height.px]="trackViewContainerHeight">
            <!-- Drag block -->
            <div
                class="drag-block animate-transform-0_1s"
                *ngIf="
                    dragBlockContent != null && trackView !== 'single-playlist'
                "
                [style.left.px]="cursorPosition.x"
                [style.top.px]="cursorPosition.y">
                <div
                    class="folder-icon"
                    *ngIf="
                        dragBlockContent != null &&
                        (dragBlockContent.type === 'folder' ||
                            dragBlockContent.type === 'many')
                    ">
                    <img src="assets/img/folder.svg" />
                </div>
                <div
                    class="track-icon"
                    *ngIf="
                        dragBlockContent != null &&
                        dragBlockContent.type === 'composition'
                    ">
                    <img src="assets/img/note.svg" />
                </div>
                {{
                    dragBlockContent.type === "many"
                        ? dragBlockContent.folders.length +
                          dragBlockContent.compositions.length +
                          " items"
                        : dragBlockContent.name
                }}
            </div>

            <div
                class="list-item"
                *ngFor="
                    let subFolder of subFolders;
                    let i = index;
                    trackBy: trackByFolder
                "
                [style.top.px]="45 * i + getTopTrackViewList()">
                <!-- Folder Block -->
                <div
                    class="list-item-composition list-item-folder"
                    *ngIf="shouldDisplayElement(i, subFolder, 'folder')"
                    (mousedown)="clickOnFolderListItem($event, i)"
                    (mouseup)="releaseDragFolder(subFolder._id)"
                    (mouseenter)="mouseOnSubFolder(i, true)"
                    [ngClass]="{
                        'list-item-multi-selection': partOfMultiSelection(
                            i,
                            'folder'
                        ),
                        'list-item-right-clicked': subFolder.isHovered,
                        'list-item-drag-hovered':
                            subFolder.isHovered &&
                            dragBlockContent != null &&
                            dragBlockContent._id !== subFolder._id
                    }"
                    (dblclick)="openFolder(i, $event)">
                    <div class="folder-icon">
                        <img src="assets/img/folder.svg" />
                    </div>

                    <div
                        class="track-title-wrap track-title track-title-mobile"
                        [ngClass]="{
                            'track-title-influence': embedType === 'Influences'
                        }">
                        <span
                            class="track-title-container"
                            (dblclick)="renameFolder($event, i)"
                            >{{ subFolder.name }}</span
                        >
                    </div>
                    <div
                        class="track-title-wrap track-from"
                        *ngIf="embedType === 'Compositions'"></div>
                    <div
                        class="track-title-wrap track-instrumentation track-title-mobile"
                        *ngIf="embedType !== 'Styles'"></div>
                    <div
                        class="track-title-wrap track-key track-title-mobile"></div>
                    <div
                        class="track-title-wrap track-bpm track-title-mobile"></div>
                    <div
                        class="track-title-wrap track-timesignature track-title-mobile"></div>
                    <div class="track-title-wrap track-creationdate">
                        {{ subFolder.creationDate | date }}
                    </div>
                    <div
                        class="track-title-wrap track-modificationdate"
                        *ngIf="embedType !== 'Styles'"></div>
                    <div
                        class="track-title-wrap track-duration"
                        *ngIf="embedType !== 'Styles'"></div>

                    <div
                        class="track-more track-showmore"
                        (click)="
                            moreOptions($event, i, -1, 'button', 'subfolder')
                        "
                        (touchstart)="
                            moreOptions($event, i, -1, 'button', 'subfolder')
                        ">
                        <img src="../assets/img/more.svg" />
                    </div>

                    <div
                        class="track-more track-title-mobile track-more-download"
                        (click)="downloadFolder(i)"
                        *ngIf="embedType === 'Compositions'">
                        <img src="../assets/img/menu/download_alt.svg" />
                    </div>
                </div>
            </div>

            <div *ngIf="embedType === 'Influences'">
                <div
                    class="list-item"
                    *ngFor="
                        let influence of content;
                        let i = index;
                        trackBy: trackByComposition
                    "
                    [style.top.px]="
                        45 * i + subFolders.length * 45 + getTopTrackViewList()
                    ">
                    <!-- Influences Block -->
                    <div
                        class="list-item-composition list-item-influence"
                        *ngIf="shouldDisplayElement(i, influence, 'influence')"
                        (mousedown)="clickOnInfluenceListItem($event, i)"
                        (mouseup)="releaseDrag()"
                        (mouseenter)="mouseOnInfluence(i, true)"
                        [ngClass]="{
                            'list-item-multi-selection': partOfMultiSelection(
                                i,
                                'influence'
                            ),
                            'list-item-right-clicked': influence.isHovered,
                            'select-influence':
                                selectInfluence() && influence.isHovered
                        }"
                        (dblclick)="play(i)">
                        <div
                            id="new-composition-badge"
                            [ngClass]="{
                                'new-composition-badge-hidden': true
                            }"></div>

                        <div
                            class="track-icon track-icon-influences"
                            *ngIf="
                                !influence.failed &&
                                isComposed(influence.isFinished) &&
                                !influence.isHovered &&
                                !isPlaying(influence)
                            ">
                            <img src="assets/img/menu/influences.svg" />
                        </div>

                        <div
                            class="track-icon track-loading"
                            *ngIf="
                                !influence.failed &&
                                !isComposed(influence.isFinished)
                            ">
                            <round-progress
                                class="track-icon-loading"
                                [current]="
                                    influence.loadingStatus.analysis * 0.8 +
                                    influence.loadingStatus.sampling * 0.2
                                "
                                [max]="100"
                                [stroke]="28"
                                [color]="'#00A2FF'"
                                [background]="
                                    'rgba(240, 240, 255, 0.2)'
                                "></round-progress>
                        </div>

                        <div
                            class="track-icon track-icon-play"
                            *ngIf="
                                !influence.failed &&
                                isComposed(influence.isFinished) &&
                                influence.isHovered &&
                                !isPlaying(influence)
                            "
                            (click)="play(i)">
                            <img src="assets/img/play-circle.svg" />
                        </div>

                        <div
                            class="track-icon track-icon-play"
                            *ngIf="
                                !influence.failed &&
                                isComposed(influence.isFinished) &&
                                isPlaying(influence)
                            "
                            (click)="pause()">
                            <img src="assets/img/player/pause-circle.svg" />
                        </div>

                        <div
                            class="track-icon track-icon-play"
                            *ngIf="influence.failed"
                            (click)="retry(influence)">
                            <img src="assets/img/player/retry.svg" />
                        </div>

                        <div
                            class="track-title track-title-influence track-title-mobile">
                            <span
                                class="track-title-container"
                                (dblclick)="renameTrack($event, i)"
                                >{{ influence.name }}</span
                            >
                        </div>

                        <div
                            class="track-title-wrap track-instrumentation track-title-mobile"
                            *ngIf="influence.failed">
                            Generation has failed, please try again
                        </div>

                        <div
                            class="track-title-wrap track-instrumentation track-title-mobile"
                            *ngIf="!influence.failed">
                            {{ getInfluenceEnsemble(influence) }}
                        </div>

                        <div
                            class="track-title-wrap track-key track-title-mobile track-parameter"
                            *ngIf="!influence.failed">
                            {{ getInfluenceKeySignature(influence) }}
                        </div>

                        <div
                            class="track-title-wrap track-bpm track-parameter"
                            *ngIf="!influence.failed">
                            {{ getInfluenceTempo(influence) }}
                        </div>

                        <div
                            class="track-title-wrap track-title-mobile track-timesignature track-parameter"
                            *ngIf="!influence.failed">
                            {{ getInfluenceTimeSignature(influence) }}
                        </div>

                        <div
                            class="track-title-wrap track-creationdate track-parameter"
                            *ngIf="!influence.failed">
                            {{ influence.creationDate | date }}
                        </div>

                        <div class="track-duration" *ngIf="!influence.failed">
                            {{ convertSecondsToString(influence.duration) }}
                        </div>

                        <div
                            class="track-more track-showmore"
                            (click)="
                                moreOptions(
                                    $event,
                                    -1,
                                    i,
                                    'button',
                                    'influence'
                                )
                            "
                            (touchstart)="
                                moreOptions(
                                    $event,
                                    -1,
                                    i,
                                    'button',
                                    'influence'
                                )
                            ">
                            <img src="../assets/img/more.svg" />
                        </div>

                        <div
                            class="track-more track-create"
                            *ngIf="
                                (influence.isHovered || influence.liked) &&
                                isFinished(i)
                            "
                            (click)="createWithInfluence(influence._id)">
                            <img src="../assets/img/menu/create.svg" />
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="embedType === 'Styles'">
                <div
                    class="list-item"
                    *ngFor="
                        let gp of content;
                        let i = index;
                        trackBy: trackByComposition
                    "
                    [style.top.px]="
                        45 * i + subFolders.length * 45 + getTopTrackViewList()
                    ">
                    <!-- Generation Profiles Block -->
                    <div
                        class="list-item-composition list-item-influence"
                        *ngIf="
                            shouldDisplayElement(i, gp, 'generationProfiles')
                        "
                        (mousedown)="
                            clickOnListItem($event, i, 'generationProfiles')
                        "
                        (mouseup)="releaseDrag()"
                        (mouseenter)="mouseOnInfluence(i, true)"
                        [ngClass]="{
                            'list-item-multi-selection': partOfMultiSelection(
                                i,
                                'generationProfiles'
                            ),
                            'list-item-right-clicked': gp.isHovered,
                            'select-influence':
                                selectInfluence() && gp.isHovered
                        }"
                        (dblclick)="openGenerationProfile(gp._id)">
                        <div class="track-icon">
                            <img src="assets/img/generation_profile.svg" />
                        </div>

                        <div class="track-title track-title-mobile">
                            <span
                                class="track-title-container"
                                (dblclick)="renameGP($event, gp)"
                                >{{ gp.name }}</span
                            >
                        </div>

                        <div
                            class="track-title-wrap track-key track-title-mobile track-parameter">
                            {{ getGPKeySignature(gp) }}
                        </div>

                        <div class="track-title-wrap track-bpm track-parameter">
                            {{ getGPTempo(gp) }}
                        </div>

                        <div
                            class="track-title-wrap track-title-mobile track-timesignature track-parameter">
                            {{ getGPTimeSignature(gp) }}
                        </div>

                        <div
                            class="track-title-wrap track-creationdate track-parameter">
                            {{ gp.creationDate | date }}
                        </div>

                        <div
                            class="track-more track-showmore"
                            (click)="
                                moreOptions(
                                    $event,
                                    -1,
                                    i,
                                    'button',
                                    'generationProfiles'
                                )
                            "
                            (touchstart)="
                                moreOptions(
                                    $event,
                                    -1,
                                    i,
                                    'button',
                                    'generationProfiles'
                                )
                            ">
                            <img src="../assets/img/more.svg" />
                        </div>
                    </div>
                </div>

                <div
                    class="no-list-item"
                    *ngIf="
                        content != null &&
                        content.length === 0 &&
                        subFolders.length === 0
                    ">
                    Start creating styles using the 'Create Style' button below.
                </div>
            </div>

            <div *ngIf="embedType === 'Compositions'">
                <div
                    class="list-item"
                    *ngFor="
                        let composition of content;
                        let i = index;
                        trackBy: trackByComposition
                    "
                    [style.top.px]="
                        45 * i + subFolders.length * 45 + getTopTrackViewList()
                    "
                    [attr.av-data]="'composition' + i">
                    <!-- Composition Block -->
                    <div
                        class="list-item-composition"
                        *ngIf="
                            shouldDisplayElement(i, composition, 'composition')
                        "
                        (mousedown)="clickOnListItem($event, i, 'composition')"
                        (mouseup)="releaseDragComposition(composition._id)"
                        (mouseenter)="mouseOnComposition(i, true)"
                        [ngClass]="{
                            'list-item-multi-selection': partOfMultiSelection(
                                i,
                                'composition'
                            ),
                            'list-item-right-clicked': composition.isHovered
                        }"
                        (dblclick)="play(i)">
                        <div
                            id="new-composition-badge"
                            [ngClass]="{
                                'new-composition-badge-hidden':
                                    composition.wasPlayed ||
                                    composition.failed ||
                                    !composition.isFinished
                            }"></div>

                        <div
                            class="track-icon"
                            *ngIf="
                                !composition.failed &&
                                isComposed(composition.isFinished) &&
                                !composition.isHovered &&
                                !isPlaying(composition) &&
                                !playerIsLoading(composition._id)
                            ">
                            <img src="assets/img/note.svg" />
                        </div>

                        <div
                            class="track-icon track-loading"
                            *ngIf="
                                !composition.failed &&
                                !isComposed(composition.isFinished)
                            ">
                            <round-progress
                                class="track-icon-loading"
                                [current]="composition.loadingStatus"
                                [max]="100"
                                [stroke]="28"
                                [color]="'#00A2FF'"
                                [background]="
                                    'rgba(240, 240, 255, 0.2)'
                                "></round-progress>
                        </div>

                        <div
                            class="track-icon track-loading"
                            *ngIf="
                                playerIsLoading(composition._id) &&
                                !composition.failed &&
                                isComposed(composition.isFinished)
                            ">
                            <round-progress
                                class="track-icon-loading"
                                [current]="20"
                                [max]="100"
                                [stroke]="28"
                                [color]="'#00A2FF'"
                                [background]="
                                    'rgba(240, 240, 255, 0.2)'
                                "></round-progress>
                        </div>

                        <div
                            class="track-icon track-icon-play"
                            *ngIf="
                                !composition.failed &&
                                isComposed(composition.isFinished) &&
                                composition.isHovered &&
                                !isPlaying(composition) &&
                                !playerIsLoading(composition._id)
                            "
                            (click)="play(i)">
                            <img src="assets/img/play-circle.svg" />
                        </div>

                        <div
                            class="track-icon track-icon-play"
                            *ngIf="
                                !composition.failed &&
                                isComposed(composition.isFinished) &&
                                isPlaying(composition) &&
                                !playerIsLoading(composition._id)
                            "
                            (click)="pause()">
                            <img src="assets/img/player/pause-circle.svg" />
                        </div>

                        <div
                            class="track-icon track-icon-play"
                            *ngIf="composition.failed"
                            (click)="retry(composition)">
                            <img src="assets/img/player/retry.svg" />
                        </div>

                        <div class="track-title track-title-mobile">
                            <span
                                class="track-title-container"
                                (dblclick)="renameTrack($event, i)"
                                >{{ composition.name }}</span
                            >
                        </div>

                        <div
                            class="track-title-wrap track-from"
                            *ngIf="
                                !composition.failed &&
                                composition.preset != null
                            ">
                            <span class="track-title-container">{{
                                formatPresetName(composition.preset)
                            }}</span>
                        </div>

                        <div
                            class="track-title-wrap track-from"
                            *ngIf="
                                !composition.failed &&
                                composition.generationProfileID != null
                            ">
                            <span
                                class="track-title-container track-title-gp"
                                (click)="
                                    openGenerationProfile(
                                        composition.generationProfileID
                                    )
                                "
                                >{{ composition.generationProfileName }}</span
                            >
                        </div>

                        <div
                            class="track-title-wrap track-from"
                            *ngIf="
                                !composition.failed &&
                                composition.compositionWorkflowID !== undefined
                            ">
                            <span
                                class="track-title-container track-title-gp"
                                (click)="
                                    openCWSettings(
                                        $event,
                                        composition.compositionWorkflowID
                                    )
                                "
                                >{{ composition.cwName }}</span
                            >
                        </div>

                        <div
                            *ngIf="
                                !composition.failed &&
                                composition.influenceID != null &&
                                composition.influenceID !== ''
                            "
                            class="track-from tooltip influence">
                            <div
                                class="overflow-ellipsis track-parameters-interactable"
                                (click)="
                                    createWithInfluence(composition.influenceID)
                                ">
                                <img
                                    src="assets/img/menu/influences.svg"
                                    *ngIf="composition.emotion == null" />
                                {{ composition.influenceName }}
                            </div>
                            <div
                                class="track-parameters-tooltip-wrapper"
                                *ngIf="
                                    !composition.failed &&
                                    composition.isFinished &&
                                    !mobileUse
                                ">
                                <span class="tooltiptext">
                                    <p>
                                        <span
                                            class="parameter-title parameters">
                                            <b><u>Parameters</u></b
                                            ><br
                                        /></span>
                                    </p>
                                    <p>
                                        <span class="parameter-title title"
                                            >Title</span
                                        >
                                        <span class="parameter title">{{
                                            composition.influenceName
                                        }}</span>
                                    </p>
                                    <p>
                                        <span
                                            class="parameter-title key-signature"
                                            >Key Signature</span
                                        >
                                        <span class="parameter key-signature">{{
                                            composition.keySignature
                                        }}</span>
                                    </p>
                                    <p>
                                        <span class="parameter-title ensemble"
                                            >Ensemble</span
                                        >
                                        <span class="parameter ensemble">{{
                                            composition.ensemble
                                        }}</span>
                                    </p>
                                    <p>
                                        <span
                                            class="parameter-title time-signature"
                                            >Time Signature</span
                                        >
                                        <span
                                            class="parameter time-signature"
                                            >{{
                                                getTimeSignature(composition)
                                            }}</span
                                        >
                                    </p>
                                    <p>
                                        <span class="parameter-title tempo"
                                            >Tempo</span
                                        >
                                        <span class="parameter tempo">{{
                                            composition.tempo
                                        }}</span>
                                    </p>
                                </span>
                            </div>
                        </div>

                        <div
                            class="track-title-wrap track-from"
                            *ngIf="composition.failed">
                            Generation has failed, please try again
                        </div>

                        <div
                            class="track-title-wrap track-instrumentation track-title-mobile"
                            *ngIf="!composition.failed">
                            {{ composition.ensemble }}
                        </div>

                        <div
                            class="track-title-wrap track-key track-title-mobile track-parameter"
                            *ngIf="!composition.failed">
                            {{ composition.keySignature }}
                        </div>

                        <div
                            class="track-title-wrap track-bpm track-title-mobile track-parameter"
                            *ngIf="!composition.failed">
                            {{ composition.tempo }}
                        </div>

                        <div
                            class="track-title-wrap track-title-mobile track-timesignature track-parameter"
                            *ngIf="
                                !composition.failed &&
                                trackView !== 'single-playlist'
                            ">
                            {{
                                composition.timeSignature != null
                                    ? composition.timeSignature[0] +
                                      "/" +
                                      composition.timeSignature[1]
                                    : ""
                            }}
                        </div>

                        <div
                            class="track-title-wrap track-creationdate track-parameter"
                            *ngIf="!composition.failed">
                            {{ composition.creationDate | date }}
                        </div>

                        <div
                            class="track-title-wrap track-modificationdate track-parameter"
                            *ngIf="!composition.failed">
                            {{ composition.lastGeneration | date }}
                        </div>

                        <div
                            class="track-title-wrap track-duration"
                            *ngIf="!composition.failed">
                            {{ convertSecondsToString(composition.duration) }}
                        </div>

                        <div
                            class="track-rank"
                            *ngIf="
                                !composition.failed &&
                                trackView === 'single-playlist'
                            ">
                            <div class="rank-levels">
                                <div class="level level-1"></div>
                                <div
                                    class="level level-2"
                                    [ngClass]="{
                                        transparent:
                                            composition.level == null ||
                                            composition.level < 2
                                    }"></div>
                                <div
                                    class="level level-3"
                                    [ngClass]="{
                                        transparent:
                                            composition.level == null ||
                                            composition.level < 3
                                    }"></div>
                                <div
                                    class="level level-4"
                                    [ngClass]="{
                                        transparent:
                                            composition.level == null ||
                                            composition.level < 4
                                    }"></div>
                            </div>
                        </div>

                        <div
                            class="track-more track-showmore"
                            (click)="
                                moreOptions(
                                    $event,
                                    -1,
                                    i,
                                    'button',
                                    'composition'
                                )
                            "
                            (touchstart)="
                                moreOptions(
                                    $event,
                                    -1,
                                    i,
                                    'button',
                                    'composition'
                                )
                            "
                            [attr.av-data]="'compositionMore' + i">
                            <img src="../assets/img/more.svg" />
                        </div>

                        <div
                            class="track-more track-title-mobile track-more-delete"
                            *ngIf="mobileUse"
                            (click)="deleteComposition(i)">
                            <img src="assets/img/menu/delete.svg" />
                        </div>

                        <div
                            class="track-more track-title-mobile track-more-download"
                            (click)="downloadComposition(i)"
                            *ngIf="isFinished(i) && !composition.failed">
                            <img src="../assets/img/menu/download_alt.svg" />
                            <img
                                class="download-checkmark"
                                alt="composition already downloaded"
                                *ngIf="composition.firstDownload != null"
                                src="../assets/img/checkmark_green_download.svg" />
                        </div>

                        <div
                            class="track-more track-title-mobile track-love"
                            *ngIf="
                                ((composition.isHovered || composition.liked) &&
                                    isFinished(i)) ||
                                mobileUse
                            "
                            (click)="toggleLike(i)">
                            <img
                                *ngIf="composition.liked"
                                src="../assets/img/loved.svg" />
                            <img
                                *ngIf="!composition.liked"
                                src="../assets/img/love.svg" />
                        </div>
                    </div>
                </div>

                <div
                    class="no-list-item"
                    *ngIf="
                        content != null &&
                        content.length === 0 &&
                        subFolders.length === 0 &&
                        embedType === 'Compositions'
                    ">
                    You don't have any tracks yet.

                    <div class="trackslist-create-track-container">
                        <a-button
                            (click)="openCreateTrack.emit($event)"
                            type="primary"
                            label="Create a track"></a-button>
                    </div>
                </div>
                <div
                    class="no-list-item"
                    *ngIf="
                        content != null &&
                        content.length === 0 &&
                        subFolders.length === 0 &&
                        embedType === 'Influences'
                    ">
                    Start uploading influences using the 'Create Track' button.
                </div>
            </div>
        </div>
    </div>

    <div class="trackslist-loader-container" *ngIf="compositionsLoading">
        <div class="loader trackslist-loader"></div>
    </div>
</div>

<!-- Right click composition menu block -->
<div
    class="right-click-menu right-click-menu-mobile composition"
    #rightClickMenuComposition
    [ngClass]="{
        'trackslist-single-playlist': trackView === 'single-playlist'
    }"
    [style.left.px]="rightClickMenuX"
    [style.top.px]="rightClickMenuY"
    (clickOutside)="cancelMoreOptions()"
    *ngIf="contentIndex !== -1 && embedType === 'Compositions'"
    av-data="compositionContextMenu">
    <!-- <div class="right-click-menu right-click-menu-mobile composition" #rightClickMenuComposition [ngClass]="{ 'trackslist-single-playlist': trackView === 'single-playlist' }" [style.left.px]="rightClickMenuX" [style.top.px]="rightClickMenuY" (clickOutside)="cancelMoreOptions()"> -->
    <div class="right-click-menu-background"></div>
    <div class="right-click-menu-container">
        <div
            class="right-click-menu-item download-button animate-0_1s"
            (mouseenter)="hoveredMenu('download', $event, 'composition')"
            (click)="downloadComposition(contentIndex)"
            *ngIf="isFinished(contentIndex) || !isProduction()">
            <img src="assets/img/menu/download.svg" /> Download
        </div>

        <div
            class="right-click-menu-item edit-pianoroll-button hide-on-mobile"
            (mouseenter)="hoveredMenu('openInEditor', $event, 'composition')"
            *ngIf="isFinished(contentIndex)"
            (click)="editInPianoRoll()">
            <img src="assets/img/menu/piano.svg" /> Open in Editor
        </div>

        <div
            class="right-click-menu-item edit-pianoroll-button"
            (mouseenter)="hoveredMenu('useAsInfluence', $event, 'composition')"
            (click)="useAsInfluence(contentIndex)"
            *ngIf="isFinished(contentIndex)">
            <span *ngIf="!useAsInfluenceLoading"
                ><img src="assets/img/menu/influences.svg" /> Use as
                Influence</span
            >
            <div class="loader" *ngIf="useAsInfluenceLoading"></div>
        </div>

        <div
            class="right-click-menu-item share-button animate-0_1s"
            (mouseenter)="hoveredMenu('share', $event, 'composition')"
            (click)="shareTrack()">
            <img src="assets/img/menu/share.svg" /> Share
        </div>

        <div
            class="right-click-menu-item edit-pianoroll-button"
            (mouseenter)="hoveredMenu('like', $event, 'composition')"
            (click)="toggleLike(contentIndex)"
            *ngIf="isFinished(contentIndex)">
            <img src="assets/img/love.svg" *ngIf="!isLiked(contentIndex)" />
            <img src="assets/img/loved.svg" *ngIf="isLiked(contentIndex)" />
            Like
        </div>
        <div
            class="right-click-menu-item rename-button animate-0_1s"
            (mouseenter)="hoveredMenu('rename', $event, 'composition')"
            (click)="renameTrack($event, contentIndex)"
            av-data="compositionRenameBtn">
            <img src="assets/img/menu/rename.svg" /> Rename
        </div>

        <div
            class="right-click-menu-item edit-pianoroll-button"
            (click)="duplicateComposition(contentIndex)"
            (mouseenter)="hoveredMenu('duplicate', $event, 'composition')"
            *ngIf="isFinished(contentIndex) && trackView !== 'single-playlist'">
            <img src="assets/img/menu/duplicate.svg" /> Duplicate
        </div>

        <div
            class="right-click-menu-item copy-composition-id-button animate-0_1s"
            (mouseenter)="
                hoveredMenu('copyCompositionID', $event, 'composition')
            "
            (click)="copyCompositionID($event, contentIndex)"
            *ngIf="isStagingEnvironment()">
            <img src="assets/img/menu/duplicate.svg" /> Copy composition ID
        </div>

        <div
            class="right-click-menu-item hide-on-mobile move-button animate-0_1s"
            (mouseenter)="hoveredMenu('moveTo', $event, 'composition')"
            *ngIf="trackView !== 'single-playlist' && allowMoving">
            <img src="assets/img/navbar/projects.svg" />
            Move to...
            <img id="additional-img" src="assets/img/right-chevron.png" />

            <!-- Right click move to menu block -->
            <div
                class="right-click-menu move-menu right-click-sub-menu"
                [ngClass]="{ 'move-menu-right': moveMenuOnTheRight }"
                #rightClickMoveMenu
                *ngIf="
                    showMoveMenu &&
                    (contentIndex !== -1 ||
                        subFolderIndex !== -1 ||
                        multiSelectionMenu)
                ">
                <!-- -->
                <div class="right-click-menu-background"></div>

                <div class="right-click-menu-scroll">
                    <div
                        class="right-click-menu-item"
                        *ngIf="getPreviousFolder() != null"
                        (click)="moveToFolder(getPreviousFolder().folderID)">
                        ... {{ getPreviousFolder().name }}
                    </div>

                    <div
                        class="right-click-menu-item"
                        *ngFor="let subFolder of subFolders; let i = index"
                        (click)="moveToFolder(subFolder._id)">
                        {{ subFolder.name }}
                    </div>
                </div>
            </div>
        </div>

        <div
            class="right-click-menu-item delete-button animate-0_1s"
            (click)="deleteComposition()"
            (mouseenter)="hoveredMenu('delete', $event, 'composition')"
            *ngIf="trackView !== 'single-playlist'">
            <img src="assets/img/menu/delete.svg" /> Delete
        </div>

        <div
            class="right-click-menu-item animate-0_1s"
            (click)="showCompositionInTracksView(contentIndex)"
            (mouseenter)="hoveredMenu('showInGridView', $event, 'composition')"
            *ngIf="trackView === 'single-playlist'">
            <img src="assets/img/navbar/projects.svg" /> Show in grid view
        </div>

        <div
            class="right-click-menu-item hide-on-desktop"
            (click)="cancelMoreOptions()"
            (mouseenter)="hoveredMenu('cancel', $event, 'composition')">
            <img src="assets/img/close.svg" /> Cancel
        </div>
    </div>
</div>

<!-- Right click influence menu block -->
<div
    class="right-click-menu right-click-menu-mobile influence"
    #rightClickMenuInfluence
    [style.left.px]="rightClickMenuX"
    [style.top.px]="rightClickMenuY"
    (clickOutside)="cancelMoreOptions()"
    *ngIf="contentIndex !== -1 && embedType === 'Influences'">
    <div class="right-click-menu-background"></div>

    <div class="right-click-menu-container">
        <div
            class="right-click-menu-item"
            *ngIf="isFinished(contentIndex)"
            (mouseenter)="hoveredMenu('newComposition', $event, 'influence')"
            (click)="createWithInfluenceWithIndex(contentIndex, $event)">
            <img src="assets/img/menu/create.svg" /> New Composition
        </div>

        <div
            class="right-click-menu-item edit-pianoroll-button hide-on-mobile"
            (click)="editInPianoRoll()"
            *ngIf="isFinished(contentIndex)"
            (mouseenter)="hoveredMenu('openInEditor', $event, 'influence')">
            <img src="assets/img/menu/piano.svg" /> Open in Editor
        </div>

        <div
            class="right-click-menu-item download-button animate-0_1s"
            (click)="downloadInfluence(contentIndex)"
            (mouseenter)="hoveredMenu('download', $event, 'influence')"
            *ngIf="isFinished(contentIndex)">
            <img src="assets/img/menu/download.svg" /> Download MP3
        </div>

        <div
            class="right-click-menu-item download-button animate-0_1s"
            (click)="downloadBugReport(contentIndex)"
            (mouseenter)="hoveredMenu('download', $event, 'influence')"
            *ngIf="showBugReportOption()">
            <img src="assets/img/menu/download.svg" /> Download Bug Report
        </div>

        <div
            class="right-click-menu-item rename-button animate-0_1s"
            (click)="renameTrack($event, contentIndex)"
            (mouseenter)="hoveredMenu('rename', $event, 'influence')">
            <img src="assets/img/menu/rename.svg" /> Rename
        </div>

        <div
            class="right-click-menu-item hide-on-mobile move-button animate-0_1s"
            (mouseenter)="hoveredMenu('moveTo', $event, 'influence')">
            <img src="assets/img/navbar/projects.svg" />
            Move to...
            <img id="additional-img" src="assets/img/right-chevron.png" />

            <!-- Right click move to menu block -->
            <div
                class="right-click-menu move-menu right-click-sub-menu"
                [ngClass]="{ 'move-menu-right': moveMenuOnTheRight }"
                #rightClickMoveMenu
                *ngIf="
                    showMoveMenu &&
                    (contentIndex !== -1 ||
                        subFolderIndex !== -1 ||
                        multiSelectionMenu)
                ">
                <!-- -->
                <div class="right-click-menu-background"></div>

                <div class="right-click-menu-scroll">
                    <div
                        class="right-click-menu-item"
                        *ngIf="getPreviousFolder() != null"
                        (click)="moveToFolder(getPreviousFolder().folderID)">
                        ... {{ getPreviousFolder().name }}
                    </div>

                    <div
                        class="right-click-menu-item"
                        *ngFor="let subFolder of subFolders; let i = index"
                        (click)="moveToFolder(subFolder._id)">
                        {{ subFolder.name }}
                    </div>
                </div>
            </div>
        </div>

        <div
            class="right-click-menu-item delete-button animate-0_1s"
            (click)="deleteInfluence(contentIndex)"
            (mouseenter)="hoveredMenu('delete', $event, 'influence')">
            <img src="assets/img/menu/delete.svg" /> Delete
        </div>

        <div
            class="right-click-menu-item hide-on-desktop"
            (click)="cancelMoreOptions()"
            (mouseenter)="hoveredMenu('cancel', $event, 'influence')">
            <img src="assets/img/close.svg" /> Cancel
        </div>
    </div>
</div>

<!-- Right click GP menu block -->
<div
    class="right-click-menu right-click-menu-mobile gp-right-click-menu"
    #rightClickMenuGP
    [style.left.px]="rightClickMenuX"
    [style.top.px]="rightClickMenuY"
    (clickOutside)="cancelMoreOptions()"
    *ngIf="contentIndex !== -1 && embedType === 'Styles'">
    <div class="right-click-menu-background"></div>

    <div class="right-click-menu-container">
        <div
            class="right-click-menu-item edit-pianoroll-button hide-on-mobile"
            (click)="openGenerationProfile(content[contentIndex]._id)"
            (mouseenter)="
                hoveredMenu('openInEditor', $event, 'generationProfiles')
            ">
            <img src="assets/img/menu/open.svg" /> Open
        </div>

        <div
            class="right-click-menu-item"
            (click)="generationWithGP()"
            (mouseenter)="
                hoveredMenu('newComposition', $event, 'generationProfiles')
            ">
            <img src="assets/img/menu/create.svg" /> New Composition
        </div>
        
        <div
            class="right-click-menu-item"
            (click)="initiateCompositionWorkflow(content[contentIndex]._id)"
            (mouseenter)="
                hoveredMenu(
                    'newCompositionWorkflow',
                    $event,
                    'generationProfiles'
                )
            ">
            <img src="assets/img/menu/create.svg" /> New Composition
            Workflow
        </div>

        <div
            class="right-click-menu-item edit-pianoroll-button"
            (click)="duplicateGenerationProfile(content[contentIndex])"
            (mouseenter)="
                hoveredMenu('duplicate', $event, 'generationProfiles')
            ">
            <img src="assets/img/menu/duplicate.svg" /> Duplicate
        </div>

        <div
            class="right-click-menu-item download-button animate-0_1s"
            (click)="downloadGPBugReport(contentIndex)"
            (mouseenter)="hoveredMenu('download', $event, 'generationProfiles')"
            *ngIf="showBugReportOption()">
            <img src="assets/img/menu/download.svg" /> Download Bug Report
        </div>

        <div
            class="right-click-menu-item share-button animate-0_1s"
            (mouseenter)="hoveredMenu('share', $event, 'generationProfiles')"
            (click)="shareGP()">
            <img src="assets/img/menu/share.svg" /> Share
        </div>

        <div
            class="right-click-menu-item rename-button animate-0_1s"
            (click)="renameGP($event, content[contentIndex])"
            (mouseenter)="hoveredMenu('rename', $event, 'generationProfiles')">
            <img src="assets/img/menu/rename.svg" /> Rename
        </div>

        <div
            class="right-click-menu-item hide-on-mobile move-button animate-0_1s"
            (mouseenter)="hoveredMenu('moveTo', $event, 'generationProfiles')">
            <img src="assets/img/navbar/projects.svg" />
            Move to...
            <img id="additional-img" src="assets/img/right-chevron.png" />

            <!-- Right click move to menu block -->
            <div
                class="right-click-menu move-menu right-click-sub-menu"
                [ngClass]="{ 'move-menu-right': moveMenuOnTheRight }"
                #rightClickMoveMenu
                *ngIf="
                    showMoveMenu &&
                    (contentIndex !== -1 ||
                        subFolderIndex !== -1 ||
                        multiSelectionMenu)
                ">
                <!-- -->
                <div class="right-click-menu-background"></div>

                <div class="right-click-menu-scroll">
                    <div
                        class="right-click-menu-item"
                        *ngIf="getPreviousFolder() != null"
                        (click)="moveToFolder(getPreviousFolder().folderID)">
                        ... {{ getPreviousFolder().name }}
                    </div>

                    <div
                        class="right-click-menu-item"
                        *ngFor="let subFolder of subFolders; let i = index"
                        (click)="moveToFolder(subFolder._id)">
                        {{ subFolder.name }}
                    </div>
                </div>
            </div>
        </div>

        <div
            class="right-click-menu-item delete-button animate-0_1s"
            (click)="deleteGP(contentIndex)"
            (mouseenter)="hoveredMenu('delete', $event, 'generationProfiles')">
            <img src="assets/img/menu/delete.svg" /> Delete
        </div>

        <div
            class="right-click-menu-item hide-on-desktop"
            (click)="cancelMoreOptions()"
            (mouseenter)="hoveredMenu('cancel', $event, 'generationProfiles')">
            <img src="assets/img/close.svg" /> Cancel
        </div>
    </div>
</div>

<!-- Right click subfolder menu block -->
<div
    class="right-click-menu right-click-menu-mobile subfolder"
    #rightClickMenuSubfolder
    [style.left.px]="rightClickMenuX"
    [style.top.px]="rightClickMenuY"
    (clickOutside)="cancelMoreOptions()"
    *ngIf="subFolderIndex !== -1">
    <div class="right-click-menu-background"></div>

    <div
        class="right-click-menu-item open-button animate-0_1s"
        (click)="openFolder(subFolderIndex, $event)"
        (mouseenter)="hoveredMenu('open', $event, 'subfolder')">
        <img src="assets/img/menu/open.svg" /> Open
    </div>
    <div
        class="right-click-menu-item download-button animate-0_1s"
        *ngIf="embedType === 'Styles'"
        (click)="generationWithGPFolder()"
        (mouseenter)="hoveredMenu('download', $event, 'subfolder')">
        <img src="assets/img/menu/create.svg" /> New composition
    </div>
    <div
        class="right-click-menu-item download-button animate-0_1s"
        *ngIf="embedType === 'Compositions'"
        (click)="downloadFolder(subFolderIndex)"
        (mouseenter)="hoveredMenu('download', $event, 'subfolder')">
        <img src="assets/img/menu/download.svg" /> Download Folder
    </div>
    <div
        class="right-click-menu-item rename-button animate-0_1s"
        (click)="renameFolder($event, subFolderIndex)"
        (mouseenter)="hoveredMenu('rename', $event, 'subfolder')">
        <img src="assets/img/menu/rename.svg" /> Rename
    </div>

    <div
        class="right-click-menu-item share-button animate-0_1s"
        *ngIf="embedType === 'Styles' && isLocal()"
        (click)="createGPCategory($event, subFolderIndex)"
        (mouseenter)="hoveredMenu('gpCategory', $event, 'subfolder')">
        <img src="assets/img/menu/share.svg" /> For Pierre: GP category creation
    </div>

    <div
        class="right-click-menu-item share-button animate-0_1s"
        *ngIf="embedType !== 'Styles'"
        (click)="shareFolder($event, subFolderIndex)"
        (mouseenter)="hoveredMenu('share', $event, 'subfolder')">
        <img src="assets/img/menu/share.svg" /> Share as playlist
    </div>

    <div
        class="right-click-menu-item hide-on-mobile move-button animate-0_1s"
        (mouseenter)="hoveredMenu('moveTo', $event, 'subfolder')">
        <img src="assets/img/navbar/projects.svg" />
        Move to...
        <img id="additional-img" src="assets/img/right-chevron.png" />

        <!-- Right click move to menu block -->
        <div
            class="right-click-menu move-menu right-click-sub-menu"
            [ngClass]="{ 'move-menu-right': moveMenuOnTheRight }"
            #rightClickMoveMenu
            *ngIf="
                showMoveMenu &&
                (contentIndex !== -1 ||
                    subFolderIndex !== -1 ||
                    multiSelectionMenu)
            ">
            <!-- -->
            <div class="right-click-menu-background"></div>

            <div class="right-click-menu-scroll">
                <div
                    class="right-click-menu-item"
                    *ngIf="getPreviousFolder() != null"
                    (click)="moveToFolder(getPreviousFolder().folderID)">
                    ... {{ getPreviousFolder().name }}
                </div>

                <div
                    class="right-click-menu-item"
                    *ngFor="
                        let subFolder of subFolders
                            | exclude
                                : '_id'
                                : getSubfolderIdByIndex(subFolderIndex);
                        let i = index
                    "
                    (click)="moveToFolder(subFolder._id)">
                    {{ subFolder.name }}
                </div>
            </div>
        </div>
    </div>

    <div
        class="right-click-menu-item delete-button animate-0_1s"
        (click)="deleteFolder()"
        (mouseenter)="hoveredMenu('delete', $event, 'subfolder')">
        <img src="assets/img/menu/delete.svg" /> Delete
    </div>

    <div
        class="right-click-menu-item hide-on-desktop"
        (click)="cancelMoreOptions()"
        (mouseenter)="hoveredMenu('cancel', $event, 'subfolder')">
        <img src="assets/img/close.svg" /> Cancel
    </div>
</div>

<!-- Right click multi selection menu block -->
<div
    class="right-click-menu hide-on-mobile right-click-menu-mobile selection"
    #rightClickMenuMultiSelection
    [ngClass]="{
        'trackslist-single-playlist': trackView === 'single-playlist'
    }"
    [style.left.px]="rightClickMenuX"
    [style.top.px]="rightClickMenuY"
    (clickOutside)="cancelMoreOptions()"
    *ngIf="multiSelectionMenu && trackView !== 'single-playlist'">
    <div class="right-click-menu-background"></div>

    <div
        class="right-click-menu-item animate-0_1s"
        *ngIf="allowFolders"
        (click)="createAndMoveMany()"
        (mouseenter)="hoveredMenu('newFolder', $event, 'multiselection')">
        <img src="assets/img/folder.svg" /> New Folder with
        {{ getMultiSelectionNumber() }} items
    </div>

    <div
        *ngIf="allowMoving"
        class="right-click-menu-item move-button animate-0_1s"
        (mouseenter)="hoveredMenu('moveTo', $event, 'multiselection')">
        <img src="assets/img/navbar/projects.svg" />
        Move to...
        <img id="additional-img" src="assets/img/right-chevron.png" />

        <!-- Right click move to menu block -->
        <div
            class="right-click-menu move-menu right-click-sub-menu"
            [ngClass]="{ 'move-menu-right': moveMenuOnTheRight }"
            #rightClickMoveMenu
            *ngIf="
                showMoveMenu &&
                (contentIndex !== -1 ||
                    subFolderIndex !== -1 ||
                    multiSelectionMenu)
            ">
            <!-- -->
            <div class="right-click-menu-background"></div>

            <div class="right-click-menu-scroll">
                <div
                    class="right-click-menu-item"
                    *ngIf="getPreviousFolder() != null"
                    (click)="moveToFolder(getPreviousFolder().folderID)">
                    ... {{ getPreviousFolder().name }}
                </div>

                <div
                    class="right-click-menu-item"
                    *ngFor="let subFolder of subFolders; let i = index"
                    (click)="moveToFolder(subFolder._id)">
                    {{ subFolder.name }}
                </div>
            </div>
        </div>
    </div>

    <div
        class="right-click-menu-item delete-button animate-0_1s"
        (click)="multiDelete()"
        (mouseenter)="hoveredMenu('delete', $event, 'multiselection')">
        <img src="assets/img/menu/delete.svg" /> Delete
        {{ getMultiSelectionNumber() }} items
    </div>
</div>

<menu-options
    *ngIf="cwSettings !== undefined"
    [menuOptions]="cwSettings"
    (close)="openCWSettings($event, undefined)"></menu-options>
