import { Injectable } from "@angular/core"
import { ParentClass } from "../../parent"
import { playerQuery } from "../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { WindowService } from "@services/window.service"
import { BugReport } from "@common-lib/interfaces/api/desktop-app.api"
import { AudioService } from "@services/audio/audio.service"
import { UserService } from "@services/user.service"
import { TokenService } from "@services/token.service"
import { DesktopAppBugReportHttpService } from "./desktop-app-bug-report.http"
import { Misc } from "@common-lib/modules/misc"
import { featureFlags } from "@common-lib/utils/feature-flags"

@Injectable()
export class DesktopAppBugReportService extends ParentClass {
    isElectron = false

    constructor(
        private windowService: WindowService,
        private audio: AudioService,
        private userService: UserService,
        private tokenService: TokenService,
        private desktopAppBugReportHttpService: DesktopAppBugReportHttpService
    ) {
        super()
        this.isElectron = this.windowService?.desktopAppAPI !== undefined
    }

    async getBugReport() {
        if (
            !this.isElectron ||
            this.windowService?.generalAPI === undefined ||
            this.windowService?.samplerAPI === undefined ||
            featureFlags.enableDesktopAppBugReport === false
        ) {
            return
        }

        const promiseResult = (await Promise.allSettled([
            this.getAllDevices(),
            this.getDiskStatistics(),
            this.windowService?.generalAPI?.getOSInfo(),
            this.windowService?.generalAPI?.getMetaInfo(),
            this.windowService?.samplerAPI?.getInstrumentDirectory(),
            this.windowService?.generalAPI?.getUpdateLinks(),
            this.windowService?.generalAPI?.getLogFilePaths(),
            this.windowService?.generalAPI?.getLogFiles(),
        ])) as { status: "fulfilled" | "rejected"; value: any; reason? }[]

        const [
            audioDevicesRes,
            stats,
            os,
            appMetaInfo,
            instrumentsDirectory,
            updateLinks,
            logPaths,
            logs,
        ] = promiseResult.map(res => (res?.value ? res.value : null))

        const bugReport: BugReport = {
            userID: null,
            instrumentsDirectory: instrumentsDirectory,
            updateLinks: updateLinks,
            instrumentsSize: stats?.aivaSize,
            freeSpaceLeft: stats?.free,
            audioDeviceList: audioDevicesRes?.audioDevices,
            selectedAudioDevice: audioDevicesRes?.selectedDeviceID,
            compositionID: playerQuery?.content?._id,
            compositionName: playerQuery?.content?.name,
            logs: logs,
            logPaths: logPaths,
            os: os,
            app: {
                versions: appMetaInfo?.versions,
                name: appMetaInfo?.name,
                arch: appMetaInfo?.appArch,
            },
        } as BugReport

        return bugReport
    }

    async saveBugReport(bugReport: BugReport = {} as BugReport) {
        if (
            !this.isElectron ||
            this.windowService?.generalAPI === undefined ||
            featureFlags.enableDesktopAppBugReport === false
        ) {
            return
        }

        try {
            bugReport = await this.getBugReport()
        } catch (error) {
            console.error("Error getting bug report", error)
        }

        const res =
            await this.desktopAppBugReportHttpService.saveDesktopAppBugReport(
                bugReport as BugReport
            )
        return res
    }

    /**
     * This method is a failsafe way to get audio devices. In case the sampler does not respond,
     * we will return a default result.
     *
     * @returns {Promise<{audioDevices: any[], selectedDeviceID: string}>}
     */
    async getAllDevices() {
        const defaultResult = {
            audioDevices: [],
            selectedDeviceID: "-1",
        }
        const timeout = 3000

        try {
            if (featureFlags?.enableDesktopAppBugReport === false) {
                return defaultResult
            }

            const allDevices = await Promise.race([
                this.audio.getAllDevices(),
                Misc.sleep(timeout),
            ])

            if (typeof allDevices === "object") {
                return allDevices
            }

            return defaultResult
        } catch (error) {
            console.error("getAllDevices", error)
            return defaultResult
        }
    }

    async getDiskStatistics() {
        const defaultResult = {
            free: 0,
            aivaSize: 0,
        }
        const timeout = 30000

        try {
            if (featureFlags?.enableDesktopAppBugReport === false) {
                return defaultResult
            }

            const res = await Promise.race([
                this.windowService?.generalAPI?.getDiskStatistics(),
                Misc.sleep(timeout),
            ])

            if (typeof res === "object") {
                return res
            }

            return defaultResult
        } catch (error) {
            console.error("getDiskStatistics", error)
            return defaultResult
        }
    }
}
