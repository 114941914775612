import { ChangeDetectionStrategy, Component, Input } from "@angular/core"

@Component({
    selector: "pagination",
    templateUrl: "pagination.component.html",
    styleUrls: ["./pagination.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
    @Input() numberOfPages: number = 1
    @Input() currentPage: number = 1
    @Input() selectNewPage: (page: number) => Promise<void>

    public previous() {
        const previousPage = Math.max(1, this.currentPage - 1)

        this.selectNewPage(previousPage)
    }

    public next() {
        const nextPage = Math.min(this.numberOfPages, this.currentPage + 1)

        this.selectNewPage(nextPage)
    }
}
