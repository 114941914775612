import {
    Component,
    OnInit,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
    HostListener,
} from "@angular/core"
import { FormControl } from "@angular/forms"
import { ModalService, ModalsValueWarning } from "../../services/modal.service"
import { PlayerService } from "../../services/audio/player/player.service"
import { ApiService } from "../../services/api.service"
import { CompositionService } from "../../services/composition.service"
import { FolderService } from "../../services/folder.service"
import { BillingService } from "../../services/billing.service"
import { DatePipe } from "@angular/common"
import { Router } from "@angular/router"
import { UserService } from "../../services/user.service"
import { AnalyticsService } from "../../services/analytics.service"
import { InfluenceService } from "../../services/influence.service"
import { environment } from "../../../environments/environment"
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar"
import { SurveyService } from "../../services/survey.service"
import { Effect } from "@common-lib/classes/score/effect"
import { ActivityMetric } from "../../../../../common-lib/general/classes/activitymetric"
import { DeviceDetectorService } from "ngx-device-detector"
import { Misc } from "@common-lib/modules/misc"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import { GeneralService } from "@services/general/general.service"
import { BehaviorSubject } from "rxjs"
import { playerQuery } from "../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { WindowService } from "@services/window.service"

@Component({
    selector: "app-modals",
    templateUrl: "modals.component.html",
    styles: [],
})
export class ModalsComponent implements OnInit {
    addLayerToGPError = ""

    settingsContainer = "about"

    options = { autoHide: false }

    addLayerType = {
        value: "Chords",
        name: "Chords (max 1)",
    }

    emailSettings = {
        updates: false,
        downloads: false,
    }

    issues = new FormControl()
    severity = new FormControl()

    playbackIcon = "assets/img/player/play.svg"

    modals: any = {}

    compositionCreationWithGP: GenerationProfile
    chordDatasetSelectionModal = null
    selectedHarmonyPacks: any = null
    gpInstrumentSelection = null
    copyAutomationAllLayersModal = false
    shortcutModal = false
    watchTutorial = false
    tutorialModal = false
    changeDrumsModal = false
    renameModal = false
    renameFolderModal = false
    commentModal = false
    requestPatchErrorModal = false
    shareModal = false
    shareFolderModal = false
    shareLoading: boolean = false
    effectsSettings: Effect = null
    deleteComposition = false
    createWithInfluenceModal = false
    deleteFolder = false
    downloadModal = false
    downloadFolderModal = false
    subscriptionModal = false
    changePasswordModal = false
    billingPreviewModal = false
    downgradeSubscriptionSurveyModal = false
    discardInstrumentation = {}
    incompatibleComposition = false
    updateModal = false
    deleteMulti = false
    addParamsToFilename = false
    addParamsToFilenameFolder = false
    downloadLoading = false
    compatibilityModal = false
    listeningTestFeedback = false
    displayTemplatesModal = false
    drumSequencerValueWarning = false
    accompanimentDesignerValueWarning = false
    compositionWorkflowValueWarning = false
    openCompositionModal = undefined
    downloadDesktopAppModal = false

    listeningTestFeedbackObject = null

    selectedFolderType = null
    numberOfUndownloadedCompositionsInFolder = 0

    durationList = false

    billingPreview
    billingPreviewLoading

    downloadFolderModalLoading = false
    subscriptionStatusInfoModal = false
    subscriptionStatus = "success"
    subscribedToPlan = ""
    subscriptionIsDowngrade = false
    subscriptionEnd = ""

    customizePreset = false

    sectionLength: number

    createMenu = "preset"

    commentValue

    version

    renamePattern = ""
    influenceEnsemble = ""
    newFolderName = ""
    newName = ""
    newCollaboratorEmail = ""
    shareFolderEmail = ""
    suggestion = ""
    openCompositionID
    openCompositionIDError = false

    collaborators = []

    subscriptionModalDescription
    subscriptionEmbed
    subscriptionEmbedLink

    scrollConfig = { useBothWheelAxes: false }

    passwords = {
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
    }

    sections = []

    detectedKeySignatures = []
    relativeKeySignatures = []

    selectStemsType = false
    message = { type: "", text: "", show: false }

    listOfIssues = {}

    listeningFeedback = {
        issues: [],
        comments: "",
        severity: "",
        addedBy: "",
        featureRelease: "",
    }

    addIssue = false
    issueToAdd = {
        index: 0,
        name: "",
        criticalLevel: "",
    }

    samplerSettingsError
    requestPatchError

    selectedNetworkConnection = "auto"

    gpLayerOptions = []

    get folderLinkSharingIsEnabled(): boolean {
        const folder = this.modalService.shareFolder.getValue()
        if (folder != null) {
            return folder["shared"]
        }
        return false
    }

    get sharedFolderLink(): string {
        const folder = this.modalService.shareFolder.getValue()
        const subDomain = environment.production ? "creators" : "dev"
        if (folder != null && folder["shared"]) {
            return (
                "https://" +
                subDomain +
                ".aiva.ai/publicPlayer?f=" +
                folder["_id"]
            )
        }
        return ""
    }

    downgradeSurvey = {
        originalPlan: "",
        newPlan: "",
        reason: "",
        explanation: "",
    }

    searchInstrumentTimeout = null

    downgradeSurveyReasons = [
        { value: "missing_features", label: "Missing features" },
        { value: "too_expensive", label: "Too expensive" },
        { value: "technical_issues", label: "Technical issues" },
        { value: "other", label: "Other" },
    ]

    renameGP = ""
    newPackName = ""

    @ViewChild("renameGPField", { static: false }) renameGPField: ElementRef
    @ViewChild("renameField", { static: false }) renameField: ElementRef
    @ViewChild("renameFolderField", { static: false })
    renameFolderField: ElementRef
    @ViewChild("influenceEnsembles", { static: false })
    perfectscrollGridVertical: PerfectScrollbarDirective

    presetDifficulty

    constructor(
        protected gpService: GenerationProfileService,
        protected playerService: PlayerService,
        protected userService: UserService,
        protected router: Router,
        protected influenceService: InfluenceService,
        protected modalService: ModalService,
        protected billingService: BillingService,
        protected folderService: FolderService,
        protected compositionService: CompositionService,
        protected apiService: ApiService,
        protected chRef: ChangeDetectorRef,
        protected surveyService: SurveyService,
        protected analyticsService: AnalyticsService,
        private device: DeviceDetectorService,
        private generalService: GeneralService,
        private windowService: WindowService
    ) {
        for (let modal in this.modalService.modals) {
            this.modalService.modals[modal].subscribe(value => {
                this.modals[modal] = this.modalService.modals[modal].getValue()

                if (this.modals[modal] == null) {
                    return
                }

                if (modal == "addLayerToGP") {
                    this.addLayerToGPError = ""
                }

                if (modal == "renameGenerationProfile") {
                    this.renameGP = value.name
                    this.focusOnRenameGPField()
                }

                if (modal === "renamePack") {
                    this.newPackName = value
                    this.focusOnRenameField()
                }
            })
        }

        this.apiService.subscriptionError.subscribe(value => {
            if (value != null && value != "") {
                this.subscriptionModalDescription = value
                this.subscriptionModal = true
            } else {
                this.subscriptionModal = false
            }
        })

        this.modalService.compositionCreationWithGP.subscribe(value => {
            this.compositionCreationWithGP = value
        })

        this.modalService.downloadDesktopAppModal.subscribe(value => {
            this.downloadDesktopAppModal = value
        })

        this.modalService.effectsSettings.subscribe()

        this.modalService.shortcutModal.subscribe(value => {
            this.shortcutModal = value
        })

        this.version = window.version

        this.modalService.copyAutomationAllLayers.subscribe(value => {
            this.copyAutomationAllLayersModal = value
        })

        this.modalService.listeningTestsFeedback.subscribe(value => {
            if (
                environment.production ||
                this.apiService.user.getValue() != "listeningtests@aiva.ai"
            ) {
                return
            }

            this.apiService
                .authRequest("/getListeningTestIssues", {}, "primary", false)
                .then(res => {
                    this.listOfIssues = res.issues

                    this.listeningFeedback.issues = []
                    this.listeningFeedback.comments = ""
                    this.listeningFeedback.severity = ""

                    this.listeningTestFeedbackObject = value
                    this.listeningTestFeedback = value != null
                })

                .catch(err => {})
        })

        this.modalService.updateModal.subscribe(value => {
            this.updateModal = value
        })

        this.modalService.changeDrumsModal.subscribe(value => {
            this.changeDrumsModal = value
        })

        this.modalService.discardInstrumentation.subscribe(value => {
            if (value != null && value["index"] != null) {
                this.discardInstrumentation = value
            }
        })

        this.modalService.comment.subscribe(value => {
            if (value != null) {
                this.commentModal = true
                this.commentValue = value.comment
            } else {
                this.commentModal = false
            }
        })

        this.modalService.createWithInfluence.subscribe(value => {
            if (value == "") {
                this.createWithInfluenceModal = false
            } else {
                this.createWithInfluenceModal = true
            }
        })

        this.modalService.incompatibleComposition.subscribe(value => {
            this.incompatibleComposition = value
        })

        this.modalService.changePassword.subscribe(value => {
            this.changePasswordModal = value
        })

        this.modalService.compatibilityModal.subscribe(value => {
            this.compatibilityModal = value
        })

        this.modalService.downloadFolder.subscribe(folder => {
            if (folder != null) {
                this.apiService
                    .authRequest(
                        "/folder/countUndownloadedCompositions",
                        { folderID: folder._id },
                        "primary",
                        true
                    )
                    .then(res => {
                        this.numberOfUndownloadedCompositionsInFolder =
                            res.compositions
                        this.downloadFolderModal = true
                        this.selectedFolderType = null
                    })
            } else {
                this.downloadFolderModal = false
            }
        })

        this.modalService.subscriptionDescription.subscribe(value => {
            this.subscriptionModalDescription = value
            this.subscriptionEmbed = this.modalService.subscriptionEmbed
            this.subscriptionEmbedLink = this.modalService.subscriptionEmbedLink
        })

        this.modalService.renamedComposition.subscribe(composition => {
            if (composition != null) {
                this.newName = composition.name
            }
        })

        this.modalService.shareComposition.subscribe(value => {
            if (value != null) {
                this.shareModal = true
                this.collaborators = value.collaborators
            } else {
                this.shareModal = false
                this.collaborators = []
            }
        })

        this.modalService.shareFolder.subscribe(value => {
            if (value != null) {
                this.shareFolderModal = true
            } else {
                this.shareFolderModal = false
            }
        })

        this.modalService.deleteMulti.subscribe(value => {
            if (value != null) {
                this.deleteMulti = true
            }
        })

        this.modalService.renamedFolder.subscribe(value => {
            this.focusOnRenameFolderField()

            if (value != null) {
                this.renameFolderModal = true
                this.newFolderName = value.name
            } else {
                this.renameFolderModal = false
            }
        })

        this.modalService.deleteComposition.subscribe(value => {
            if (value == null) {
                this.deleteComposition = false
            } else {
                this.deleteComposition = true
            }
        })

        this.modalService.downloadComposition.subscribe(value => {
            if (value == null) {
                this.downloadModal = false
            } else {
                this.downloadModal = true
            }
        })

        this.modalService.deleteFolder.subscribe(value => {
            if (value == null) {
                this.deleteFolder = false
            } else {
                this.deleteFolder = true
            }
        })

        this.modalService.billingPreview.subscribe(value => {
            if (value != null) {
                this.billingPreviewModal = true
            }

            this.billingPreview = value
        })

        this.modalService.subscriptionStatusInfo.subscribe(value => {
            if (value != null) {
                this.subscriptionStatusInfoModal = true
                this.subscriptionStatus = value.status
                this.subscribedToPlan = this.billingService.formatPlanName(
                    value.plan
                )
                this.subscriptionEnd = value.subscriptionEnd
                this.subscriptionIsDowngrade = value.isDowngrade
            }
        })

        this.modalService.drumSequencerValueWarning.subscribe(res => {
            if (res != null) {
                this.drumSequencerValueWarning = res.showModal
            }
        })

        this.modalService.accompanimentDesignerValueWarning.subscribe(res => {
            if (res != null) {
                this.accompanimentDesignerValueWarning = res.showModal
            }
        })

        this.modalService.compositionWorkflowValueWarning.subscribe(res => {
            if (res != null) {
                this.compositionWorkflowValueWarning = res.showModal
            }
        })

        this.modalService.requestPatchError.subscribe(res => {
            if (res != null) {
                this.requestPatchErrorModal = res.showModal
                this.requestPatchError = res.error // errorCode
            }
        })

        this.modalService.openCompositionModal.subscribe(value => {
            this.openCompositionModal = value

            if (!value) {
                this.openCompositionIDError = false
                this.openCompositionID = null
            }
        })

        // set this once and don't call getLayerOptions in the template because it looks like this is causing the Mac dropdown glitch. Change detection triggers a lot -> return value is used to update dropdown many times even when dropdown is open
        this.gpLayerOptions = this.getLayerOptions()
    }

    ngOnInit() {}

    closePackSelectionModal() {
        this.modalService.modals.selectPack.next(undefined)
    }

    cancelCopyAutomationAllLayers() {
        this.modalService.copyAutomationAllLayers.next(null)
    }

    getLayerOptions() {
        return [
            {
                value: "Melody",
                name: "Melody (max 1)",
            },

            {
                value: "Chords",
                name: "Chords (max 1)",
            },

            {
                value: "Bass",
                name: "Bass (max 1)",
            },

            {
                value: "Percussion",
                name: "Percussion (max 1)",
            },

            {
                value: "Extra",
                name: "Extra (max 3)",
            },

            {
                value: "Ornaments",
                name: "Ornaments (max 3)",
            },
        ]
    }

    changeLayerType(event) {
        this.addLayerType = event.new
    }

    async confirmLayerAddition() {
        let gp = this.gpService.generationProfile

        let tooManyOrnaments =
            this.addLayerType["value"] == "Ornaments" &&
            gp.hasLayer(this.addLayerType["value"]) > 2
        let tooManyExtras =
            this.addLayerType["value"].includes("Extra") &&
            gp.hasLayer(this.addLayerType["value"]) > 2
        let tooManyLayers =
            this.addLayerType["value"] != "Ornaments" &&
            !this.addLayerType["value"].includes("Extra") &&
            gp.hasLayer(this.addLayerType["value"]) > 0

        if (tooManyOrnaments) {
            this.addLayerToGPError =
                "You can't add more than 3 Ornament layers."

            return
        } else if (tooManyExtras) {
            this.addLayerToGPError = "You can't add more than 3 Extra layers."

            return
        } else if (tooManyLayers) {
            this.addLayerToGPError =
                "You can't add more than 1 " +
                this.addLayerType["value"] +
                " layer."

            return
        }

        this.gpService.generationProfile.addLayer(this.addLayerType["value"])

        this.closeModal("addLayerToGP")

        await this.gpService.setAsUpdated("addLayer")

        if (this.addLayerType["value"] == "Melody") {
            this.gpService.getMelodyVisualPreview()
        }
    }

    async setNameForGP() {
        this.modals.renameGenerationProfile.name = this.renameGP

        await this.gpService.renameGenerationProfile(
            this.modals.renameGenerationProfile
        )

        this.closeModal("renameGenerationProfile")
    }

    async setNameForPack() {
        this.modalService.modals.renamePack.next(this.newPackName)
        this.closeModal("renamePack")
    }

    confirmDeleteGenerationProfile() {
        this.gpService.deleteGenerationProfile(
            this.modals.deleteGenerationProfile
        )
        this.closeModal("deleteGenerationProfile")

        if (this.router.url.includes("generation-profile-editor")) {
            this.folderService.setContentType(
                "confirmDeleteGenerationProfile",
                "Styles",
                "",
                true
            )
        }
    }

    confirmDeleteGenerationProfileLayer() {
        this.gpService.deleteGenerationProfileLayer(
            this.modals.deleteGenerationProfileLayer
        )
        this.closeModal("deleteGenerationProfileLayer")
    }

    downloadApp() {
        window.open("https://www.aiva.ai/download", "_blank")
    }

    closeDownloadDesktopAppModal() {
        this.modalService.downloadDesktopAppModal.next(false)
    }

    async downloadBinaries() {
        const result = await this.windowService.generalAPI.getMetaInfo()

        result.platform = "win32"

        // if (
        //     result.platform === "darwin" ||
        //     result.platform === "mac" ||
        //     result.platform === "win32" ||
        //     result.platform === "win"
        // ) {
        //     try {
        //         const res = await this.apiService.request(
        //             "/desktopapp/getDownloadLink",
        //             {
        //                 os: result.platform,
        //             },
        //             "primary",
        //             "post",
        //             10
        //         )

        //         window.open(res.link, "_blank")
        //     } catch (e) {
        //         return this.apiService.handleError(e)
        //     }
        // }

        // else {
        return this.openDownloadPage()
        //}
    }

    openDownloadPage() {
        window.open("https://www.aiva.ai/download", "_blank")
    }

    toggleLinkSharing(value) {
        var composition = this.modalService.shareComposition.getValue()
        composition["shared"] = value

        this.compositionService
            .toggleLinkSharing(value, composition)
            .then(success => {
                if (!success) {
                    return
                }

                this.modalService.shareComposition.next(composition)
            })
    }

    toggleFolderLinkSharing(value) {
        this.hideMessage()
        let folder = this.modalService.shareFolder.getValue()
        folder["shared"] = value // update folder shared value

        this.folderService
            .toggleFolderLinkSharing(value, folder)
            .then(success => {
                if (!success) {
                    return
                }

                this.modalService.shareFolder.next(folder)
            })
    }

    closeChordDatasetSelectionModal() {
        this.modalService.modals.chordDatasetSelectionModal.next(undefined)
        this.gpService.refreshGenerationProfileUI.next(true)
    }

    addCompositionFeedback() {
        this.apiService
            .authRequest(
                "/listeningTestsFeedback",
                {
                    compositionID:
                        this.listeningTestFeedbackObject.composition._id,
                    approved: this.listeningTestFeedbackObject.approve,
                    comments: this.listeningFeedback.comments,
                    severity: this.listeningFeedback.severity,
                    featureRelease: this.listeningFeedback.featureRelease,
                    issues: this.listeningFeedback.issues,
                    addedBy: this.listeningFeedback.addedBy,
                },
                "primary",
                false
            )

            .then(res => {
                this.closeListeningTestsFeedback()
            })

            .catch(err => {
                this.apiService.handleError(err)
            })
    }

    linkSharingIsEnabled(): boolean {
        const composition = this.modalService.shareComposition.getValue()

        if (composition != null) {
            return composition["shared"]
        }

        return false
    }

    getSharedLink() {
        const composition = this.modalService.shareComposition.getValue()
        const subDomain = environment.production ? "creators" : "dev"

        if (composition != null && composition["shared"]) {
            return (
                "https://" +
                subDomain +
                ".aiva.ai/publicPlayer?c=" +
                composition["_id"]
            )
        }

        return ""
    }

    renameFolder(): void {
        this.folderService.renameFolder(
            this.newFolderName,
            this.modalService.renamedFolder.getValue()._id
        )
        this.modalService.renamedFolder.next(null)

        this.analyticsService.addActivity(ActivityMetric.RENAME, {
            type: "folder",
        })
    }

    confirmDeleteFolder(): void {
        const type =
            this.folderService.contentType.getValue() == "Influences"
                ? "Influences"
                : "Compositions"
        this.folderService.deleteFolder(
            this.modalService.deleteFolder.getValue()._id
        )

        this.deleteFolder = false

        this.analyticsService.addActivity(ActivityMetric.DELETE_FOLDER, {
            type: type,
        })
    }

    closeListeningTestsFeedback() {
        this.listeningTestFeedback = false
    }

    getListOfIssuesCategories() {
        return Object.keys(this.listOfIssues)
    }

    confirmDeleteMulti(): void {
        var itemsToDelete = this.modalService.deleteMulti.getValue()
        let type = "Compositions"

        if (this.folderService.contentType.getValue() === "Influences") {
            type = "Influences"
        } else if (this.folderService.contentType.getValue() === "Styles") {
            type = "Styles"
        }

        this.analyticsService.addActivity(ActivityMetric.DELETE_MULTIPLE, {
            type: type,
        })

        this.folderService.deleteMany(itemsToDelete["folders"])
        this.compositionService
            .deleteMany(itemsToDelete["compositions"], type)

            .then(() => {
                for (var c = 0; c < itemsToDelete["compositions"].length; c++) {
                    if (
                        playerQuery.content !== undefined &&
                        playerQuery.contentID ==
                            itemsToDelete["compositions"][c]
                    ) {
                        this.playerService.pause()
                        this.playerService.loadNextTrack({})

                        break
                    }
                }
            })

        this.closeDeleteMulti()
    }

    confirmDeleteComposition(): void {
        const type =
            this.folderService.contentType.getValue() == "Influences"
                ? "Influences"
                : "Compositions"
        const compositionID = this.modalService.deleteComposition.getValue()._id

        this.compositionService.deleteComposition(compositionID)

        if (
            playerQuery.content !== undefined &&
            playerQuery.contentID === compositionID
        ) {
            this.playerService.pause()
            this.playerService.loadNextTrack({})
        }

        this.deleteComposition = false

        this.analyticsService.addActivity(ActivityMetric.DELETE_TRACK, {
            type: type,
        })

        //this.analyticsService.addActivity(ActivityMetric.RENAME, {
        //	type: type == "influences" ? "influence" : "composition"
        //})
    }

    recommendedStems() {
        const preset = this.getDownloadComposition().preset

        if (
            preset == "rock" ||
            preset == "electronic" ||
            preset == "ambient" ||
            preset == "pop" ||
            preset == "hip_hop"
        ) {
            return "layer"
        }

        return "section"
    }

    getDownloadComposition() {
        return this.modalService.downloadComposition.getValue()
    }

    dummyFunction() {}

    closeModal(type) {
        if (this.modals[type] == null) {
            this.modalService[type].next(null)
        } else {
            this.modalService.modals[type].next(undefined)
        }
    }

    showJSONOption() {
        return !environment.production
    }

    getMarginLeft(modal) {
        return -Math.round(modal.clientWidth / 2)
    }

    downloadBugReport() {
        this.downloadLoading = true

        var composition = this.modalService.downloadComposition.getValue()

        const compositionID = composition["_id"]

        this.compositionService
            .downloadBugReport(compositionID)
            .then((decrement: any) => {
                this.downloadModal = false
                this.downloadLoading = false
            })
    }

    downloadFile(type) {
        this.downloadLoading = true

        var composition = this.modalService.downloadComposition.getValue()

        const compositionID = composition["_id"]

        var promise: any = Promise.resolve(0)

        if (type == "wav" && composition.exportWAV == null) {
            // Do nothing
        } else if (type == "chords") {
            promise = this.compositionService.downloadChords(compositionID)
        } else {
            promise = this.compositionService.downloadComposition(
                compositionID,
                type,
                this.addParamsToFilename
            )
        }

        promise.then((decrement: any) => {
            this.downloadModal = false
            this.downloadLoading = false

            this.analyticsService.addActivity(
                ActivityMetric.DOWNLOAD_COMPOSITION,
                {
                    fileType: type,
                }
            )
        })
    }

    downloadFolder() {
        this.downloadFolderModalLoading = true

        this.analyticsService.addActivity(ActivityMetric.DOWNLOAD_FOLDER, {
            fileType: this.selectedFolderType,
        })

        this.folderService
            .downloadFolder(
                this.numberOfUndownloadedCompositionsInFolder,
                this.modalService.downloadFolder.getValue()._id,
                this.selectedFolderType,
                this.addParamsToFilenameFolder
            )
            .then(() => {
                this.modalService.downloadFolder.next(null)

                this.downloadFolderModalLoading = false
            })
    }

    setSelectFolderType(value) {
        this.selectedFolderType = value

        if (this.numberOfUndownloadedCompositionsInFolder == 0) {
            this.downloadFolder()
        }
    }

    cancelComment() {
        this.modalService.comment.next(null)
    }

    getDownloadsLeft() {
        return this.generalService.downloadsLeft.getValue()
    }

    getDownloadQuotaRemaining() {
        const subscription = this.billingService.subscription.getValue()
        var subscriptionEnd = subscription.end

        if (subscription.plan != "free") {
            subscriptionEnd = subscription.end * 1000
        }

        var datePipe = new DatePipe("en-EU").transform(
            subscriptionEnd,
            "mediumDate"
        )

        if (
            subscription.plan != "free" &&
            subscription.plan != "standard_monthly"
        ) {
            return ""
        }

        return (
            "You have " +
            this.generalService.downloadsLeft.getValue() +
            " downloads remaining. Your quota will reset on " +
            datePipe +
            "."
        )
    }

    alreadyDownloadedComposition() {
        const composition = this.modalService.downloadComposition.getValue()

        return composition.firstDownload != null
    }

    closeSubscriptionModal() {
        this.subscriptionModal = false
        this.subscriptionModal = false
        this.subscriptionEmbed = null
        this.subscriptionEmbedLink = null
    }

    closeTutorialModal() {
        this.tutorialModal = false
    }

    viewSubscriptionPlans() {
        this.analyticsService.addActivity(
            ActivityMetric.VIEW_SUBSCRIPTION_PLANS
        )

        this.router.navigate(["/billing"])
        this.closeSubscriptionModal()
        this.downloadModal = false
    }

    getDownloadLicense() {
        var license = this.modalService.downloadComposition
            .getValue()
            .license.replace("_", " ")

        return license
    }

    showUpgradeSubscription() {
        if (
            this.modalService.downloadComposition.getValue().license ==
            "full_copyright"
        ) {
            return false
        }

        return true
    }

    changePassword() {
        this.userService.changePassword(this.passwords).then(succeeded => {
            if (succeeded) {
                this.changePasswordModal = false
            }
        })
    }

    formatAmount(amount) {
        return Math.abs(amount) / 100
    }

    closeCompatibilityModal() {
        this.modalService.compatibilityModal.next(false)
    }

    getCustomizePreset(): boolean {
        const value = this.modalService.customizePreset.getValue()

        if (value != null) {
            return value
        } else {
            return false
        }
    }

    getPreviewTotal() {
        var total = this.billingPreview.preview.startingBalance

        for (
            var i = 0;
            i < this.billingPreview.preview.previewLines.length;
            i++
        ) {
            total += this.billingPreview.preview.previewLines[i].amount
        }

        return total
    }

    setUserType(userType) {
        this.userService.updateSurvey({ userType: userType }).then(() => {
            this.tutorialModal = true
        })
    }

    clickEmbeddedLink() {
        window.open(this.subscriptionEmbedLink, "_blank")
    }

    exportWAV() {
        this.downloadLoading = true

        this.compositionService
            .export("wav", this.modalService.downloadComposition.getValue())

            .then(result => {
                this.downloadLoading = false
                this.downloadModal = false

                if (!result.success) {
                    this.modalService.subscriptionEmbed = ""
                    this.modalService.subscriptionEmbedLink = ""
                    this.modalService.subscriptionDescription.next(
                        result["message"]
                    )
                }

                this.analyticsService.addActivity(ActivityMetric.EXPORT_WAV)
            })
    }

    getDiscardInstrumentation() {
        if (
            this.discardInstrumentation == null ||
            this.discardInstrumentation["index"] == null
        ) {
            return false
        }

        return true
    }

    closeDiscardInstrumentationModal() {
        this.discardInstrumentation = false
        this.modalService.discardInstrumentation.next(null)
    }

    showExportStemsOption() {
        return !this.modalService.downloadComposition.getValue().stemsCreation
    }

    showExportWAVOption() {
        return (
            this.modalService.downloadComposition.getValue().exportWAV == null
        )
    }

    updateComment() {
        const comment = this.modalService.comment.getValue()

        this.compositionService
            .updateComment(comment.id, this.commentValue, comment.bar)
            .then(() => {
                this.modalService.comment.next(null)
            })

        this.cancelComment()
    }

    focusOnRenameField() {
        setTimeout(() => {
            if (this.renameField == null) {
                return
            }

            this.renameField.nativeElement.focus()
        }, 100)
    }

    focusOnRenameGPField() {
        setTimeout(() => {
            if (this.renameGPField == null) {
                return
            }

            this.renameGPField.nativeElement.focus()
        }, 100)
    }

    getCreateWithInfluence() {
        return this.modalService.createWithInfluence.getValue()
    }

    focusOnRenameFolderField() {
        setTimeout(() => {
            if (this.renameFolderField != null) {
                this.renameFolderField.nativeElement.focus()
            }
        }, 1)
    }

    closeDeleteMulti() {
        this.deleteMulti = false
        this.modalService.deleteMulti.next(null)
    }

    changeSubscription() {
        this.billingPreviewLoading = true
        this.billingPreviewModal = true
        let plan = this.billingPreview.plan
        let paymentMethod = this.billingService.defaultPaymentMethod

        let promise = Promise.resolve(null)
        let isDowngrade = this.billingPreview.preview.isDowngrade
        let datePipe = new DatePipe("en-EU").transform(
            this.billingPreview.preview.subscriptionEnd,
            "mediumDate"
        )

        // in case we have a downgrade, we will save our survey first
        if (this.billingPreview.preview.isDowngrade) {
            this.downgradeSubscriptionSurveyModal = false
            promise = this.surveyService.createDowngradeSurvey(
                this.downgradeSurvey
            )
        }

        return promise
            .then(surveyID => {
                if (
                    this.billingPreview.preview.isDowngrade == true &&
                    surveyID == null
                ) {
                    return Promise.reject()
                }

                return this.billingService.subscribe(
                    this.billingPreview.plan,
                    paymentMethod
                )
            })
            .then(result => {
                this.billingPreviewLoading = false
                this.billingPreview = null
                this.billingPreviewModal = false
                this.modalService.subscriptionStatusInfo.next({
                    plan,
                    status: "success",
                    isDowngrade,
                    subscriptionEnd: datePipe,
                })
            })
            .catch(err => {
                this.billingPreviewLoading = false
                this.billingPreview = null
                this.billingPreviewModal = false
                this.modalService.subscriptionStatusInfo.next({
                    plan,
                    status: "error",
                })
            })
    }

    showDowngradeSubscriptionSurvey() {
        this.resetDowngradeSurvey()

        this.downgradeSurvey.newPlan = this.billingPreview.plan
        this.downgradeSurvey.originalPlan = this.billingPreview.originalPlan

        this.downgradeSubscriptionSurveyModal = true
        this.billingPreviewModal = false
    }

    formatPlanName(name) {
        var nameTemp = name.split("_")
        var planType = Misc.capitalizeFirstLetter(nameTemp[0])
        var period = Misc.capitalizeFirstLetter(nameTemp[1])

        return planType + " " + period
    }

    watchTutorialView() {
        this.closeTutorialModal()
        this.watchTutorial = true
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        const tagName = document.activeElement.tagName

        if (tagName == "TEXTAREA") {
            // DO NOTHING
        } else if (event.key == "Enter") {
            const renameFolder = this.modalService.renamedFolder.getValue()
            const confirmDelete = this.modalService.deleteComposition.getValue()
            const confirmDeleteMulti = this.modalService.deleteMulti.getValue()

            if (renameFolder != null) {
                this.renameFolder()
            } else if (confirmDelete != null) {
                this.confirmDeleteComposition()
            } else if (confirmDeleteMulti != null) {
                this.confirmDeleteMulti()
            }
        }
    }

    formatMeasures(measures) {
        var result = measures.split("/")

        if (result.length == 0) {
            return 0
        }

        if (result.length == 1) {
            return parseInt(result[0])
        } else {
            return parseInt(result[0]) / parseInt(result[1])
        }
    }

    getCreateType() {
        return this.modalService.createType
    }

    closeDownloadModal() {
        this.downloadModal = false
        this.selectStemsType = false
    }

    async stemsTypeSelection(type) {
        this.downloadLoading = true

        const composition = this.modalService.downloadComposition.getValue()

        this.compositionService.export("stems", composition, type).then(() => {
            this.downloadLoading = false
            this.closeDownloadModal()

            this.analyticsService.addActivity(ActivityMetric.EXPORT_STEMS, {
                type: type,
            })
        })
    }

    showMessage(type: string, text: string) {
        if (type && text) this.message = { type: type, text: text, show: true }
    }
    hideMessage() {
        this.message = { type: "", text: "", show: false }
    }

    closeShareModals() {
        this.shareModal = false
        this.shareFolderModal = false
        this.hideMessage()
        this.shareFolderEmail = ""
    }

    resetDowngradeSurvey() {
        this.downgradeSurvey = {
            originalPlan: "",
            newPlan: "",
            reason: "",
            explanation: "",
        }
    }

    downgradeSurveyReasonChanged() {
        setTimeout(() => {}, 50)
    }

    isInfluenceCreateType() {
        return this.modalService.isInfluenceCreateType()
    }

    displayCreateModalMenus() {
        return this.modalService.displayCreateModalMenus.getValue()
    }

    private cancelValueWarning(subject: BehaviorSubject<ModalsValueWarning>) {
        const obj = subject.getValue()
        obj.showModal = false
        obj.boolean = false

        subject.next(obj)
    }

    cancelDrumSequencerValueWarning() {
        this.cancelValueWarning(this.modalService.drumSequencerValueWarning)
    }

    cancelAccompanimentDesignerValueWarning() {
        this.cancelValueWarning(
            this.modalService.accompanimentDesignerValueWarning
        )
    }

    cancelCompositionWorkflowValueWarning() {
        this.cancelValueWarning(
            this.modalService.compositionWorkflowValueWarning
        )
    }

    private confirmValueWarning(subject: BehaviorSubject<ModalsValueWarning>) {
        const obj = subject.getValue()
        obj.showModal = false
        obj.boolean = true

        subject.next(obj)
    }

    confirmDrumSequencerValueWarning() {
        this.confirmValueWarning(this.modalService.drumSequencerValueWarning)
    }

    confirmAccompanimentDesignerValueWarning() {
        this.confirmValueWarning(
            this.modalService.accompanimentDesignerValueWarning
        )
    }

    confirmCompositionWorkflowValueWarning() {
        this.confirmValueWarning(
            this.modalService.compositionWorkflowValueWarning
        )
    }

    getCreateWithInfluenceView() {
        const parameters =
            this.modalService.createWithInfluenceParameters.getValue()

        if (parameters == null) {
            return "parameters"
        }

        return this.modalService.createWithInfluenceParameters.getValue().view
    }

    closeRequestPatchError() {
        this.modalService.requestPatchError.next({ showModal: false })
    }

    onPresetDifficultyChanged(value) {
        this.presetDifficulty = value
    }

    getCreateFromPlaylist() {
        return this.modalService.createFromPlaylist.getValue()
    }

    emotionViewIsActive() {
        return (
            (!this.displayCreateModalMenus() &&
                this.getCustomizePreset() &&
                this.presetDifficulty != "advanced") ||
            this.getCreateFromPlaylist()
        )
    }

    cancelOpenComposition() {
        this.modalService.openCompositionModal.next(undefined)
    }

    openCompositionByID() {
        this.openCompositionIDError = false

        if (this.playerService.getCompositionID() == this.openCompositionID) {
            this.modalService.openCompositionModal.next(undefined)

            return
        }

        const type = this.openCompositionModal

        this.folderService
            .getContentByID(this.openCompositionID, type)

            .then(c => {
                this.playerService.switchToPianoRoll(
                    this.openCompositionID,
                    type,
                    false
                )

                this.modalService.openCompositionModal.next(undefined)
            })

            .catch(err => {
                console.error(
                    "Error opening composition",
                    this.openCompositionModal,
                    this.openCompositionID,
                    err
                )
                this.openCompositionIDError = true
            })
    }

    /**
     * some browser have issues with backdrop-filter CSS. This issue will crash position fixed
     * to counter this, we will add a specific class to them and style differently
     * @returns
     */
    doesNotSupportBlur() {
        if (
            this.device != null &&
            ["Chrome", "Opera"].includes(this.device.browser)
        ) {
            return true
        }

        return false
    }
}
