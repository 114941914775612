import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { ApiService } from "./api.service"
import { TokenService } from "./token.service"
import { ModalService } from "./modal.service"

@Injectable()
export class UserService {
    changelog
    showChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
    settings
    accountCreationDate: number | undefined

    createTrackMenu: BehaviorSubject<string> = new BehaviorSubject<string>(
        "Styles"
    )

    modalSettings: { [key: string]: boolean } = {}

    constructor(
        private modalService: ModalService,
        private apiService: ApiService,
        private tokenService: TokenService
    ) {
        this.apiService.user.subscribe(user => {
            if (user == null) {
                this.modalSettings = {}
            }
        })
    }

    changePassword(passwords): Promise<any> {
        passwords["refreshToken"] = this.tokenService.getRefreshToken()

        return this.apiService
            .authRequest("/user/changePassword", passwords, "primary", true)
            .then(res => {
                return Promise.resolve(true)
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve(false)
            })
    }

    setProfileSettings(
        mixingOption,
        userSettings,
        modalSettings: { [key: string]: boolean }
    ) {
        this.modalSettings = modalSettings
        if (this.apiService.user.value === "first-timer@aiva.ai") {
            this.modalSettings = {}
        }

        if (
            this.settings != null &&
            localStorage.getItem("mixingOption") != null
        ) {
            return
        }

        localStorage.setItem("mixingOption", mixingOption)
        this.settings = userSettings
    }

    setModalSettings(modalId: string, value: boolean) {
        if (!this.modalSettings) {
            this.modalSettings = {}
        }
        this.modalSettings[modalId] = value
    }

    updateSurvey(params): Promise<any> {
        return this.apiService
            .authRequest("/user/updateSurvey", params, "primary", true)
            .then(res => {
                return Promise.resolve()
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve(false)
            })
    }

    /**
     * @description checks for the method users during login
     * @returns true if user has logged in with password, else returns false
     */
    userCanChangePassword(): boolean {
        let loginMethod = localStorage.getItem("loggedInWith")
        return loginMethod == "password"
    }
}
