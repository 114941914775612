import Score from "../classes/score/score"
import {
    DrumSamplesMap,
    InstrumentJSON,
    InstrumentsJSON,
} from "../interfaces/score/general"
import { KeySignature } from "../interfaces/score/keySignature"
import {
    TemplateChord,
    TemplateKeySignature,
} from "../interfaces/score/templateScore"
import { TimeSignature } from "../types/score"
import ScoreRenderingEngine from "../../client-only/score-rendering-engine/engine"
import { SRActionTypes } from "../../client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { NotesObject } from "../classes/score/notesObject"

import { VoiceLeading } from "./voice-leading.module"
import { ScoreManipulation } from "./scoremanipulation"
import { Time } from "./time"
import {
    NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS,
    TIMESTEP_RES,
} from "../constants/constants"
import { KeySignatureModule } from "./keysignature.module"
import { CWKeyMode } from "../interfaces/composition-workflow.interface"
import { ChordManipulation } from "./chord-manipulation.module"
export module CompositionWorkflowModule {
    export function defaultScoreInitialization({
        keySignature,
        timeSignature,
        romanNumerals,
        tempo,
        drumSamples,
        lowestNote,
        instruments,
    }: {
        keySignature: KeySignature
        timeSignature: TimeSignature
        romanNumerals: TemplateChord[]
        tempo: number
        drumSamples: DrumSamplesMap
        lowestNote: number
        instruments: InstrumentsJSON
    }): Score {
        const score = Score.createEmptyScore(
            timeSignature,
            tempo,
            "composition",
            keySignature.pitchClass + " " + keySignature.keyMode,
            "Chords",
            drumSamples
        )

        const scoreLength = Time.multiplyFractionWithNumber(
            timeSignature[0] + "/" + timeSignature[1],
            8
        )

        const ks: TemplateKeySignature[] = [
            ["0", keySignature.pitchClass + " " + keySignature.keyMode],
        ]

        // Add notes to score
        const result = initNotes({
            keySignatures: ks,
            chordProgression: romanNumerals,
            timeSignature,
            lowestNote,
            tieNotes: true,
        })

        score.chords = result.chords
        score.romanNumerals = romanNumerals
        score.layers.Chords.notesObject = result.notes

        score.scoreLength = scoreLength

        // Add a track bus to the Chords layer
        const instrument = instruments["k"].find(i => i.name === "piano")
        const scoreLengthTimesteps = Time.fractionToTimesteps(
            TIMESTEP_RES,
            score.scoreLength
        )

        const trackBus = ScoreManipulation.createDefaultTrackBus(
            score.layers.Chords,
            instrument,
            scoreLengthTimesteps * 2
        )
        trackBus.autoPedal = false

        score.layers.Chords.trackBuses.push(trackBus)

        return score
    }

    /**
     * This function does two things:
     * (1) It converts a chord progression in roman numeral into chord symbol notation
     * (2) It generates the voice leading for the chord progression, and returns a corresponding NotesObject
     *
     * Chord progressions need to be provided as roman numerals, e.g. [["1", "V"], ["1/2", "III"], etc.]
     *
     * @returns
     *  {NotesObject} The notes object based on the voiced led chord progression
     *  {TemplateChord[]} The chord progression in chord symbols (e.g. [["1", "C"], etc. ])
     */
    export function initNotes({
        keySignatures,
        chordProgression,
        timeSignature,
        lowestNote,
        tieNotes,
    }: {
        keySignatures: TemplateKeySignature[]
        chordProgression: TemplateChord[]
        timeSignature: TimeSignature
        lowestNote: number
        tieNotes: boolean
    }): {
        notes: NotesObject
        chords: TemplateChord[]
    } {
        const chords = ChordManipulation.convertRomanNumeralsToChordSymbols(
            chordProgression,
            keySignatures
        )

        let notes = VoiceLeading.generateVoiceLeading(
            chords,
            lowestNote,
            "Chords",
            [],
            timeSignature
        )

        if (tieNotes) {
            notes = NotesObject.tieNotes(notes, timeSignature, [])
        }

        return {
            notes,
            chords: chords,
        }
    }

    export function defaultScoreRenderingEngineInitialisation({
        score,
        userID,
        settings,
        instruments,
        environment,
        templateBeforeActionEffect,
    }: {
        score: Score
        userID: string
        settings: string
        instruments: InstrumentsJSON
        environment: "production" | "staging"
        templateBeforeActionEffect?: Function
    }): ScoreRenderingEngine {
        const engine = ScoreRenderingEngine.initScore(
            score,
            environment,
            {
                userID,
                settings,
                instruments,
                autoExtendScore: false,
                maxBarLength: 8,
                resizeFactor: {
                    min: 20,
                    max: 150,
                },
                levelsMeasurement: "layer",
                sustainPedalFromChords: true,
            },
            templateBeforeActionEffect
        )

        engine.srEmitter$.next({
            type: SRActionTypes.setResizeFactor,
            data: {
                resizeFactor: 20,
            },
        })

        engine.srEmitter$.next({
            type: SRActionTypes.setLayerPreviewConfigs,
            data: {
                skipAdjustments: true,
                fillNotesWithLayerColor: true,
            },
        })

        engine.srEmitter$.next({
            type: SRActionTypes.setScoreWasEdited,
            data: {},
        })

        // This step is necessary in order to make sure that we only display 8 bars
        engine.setAccompanimentDesignerScoreLength(
            Time.multiplyFractionWithNumber(
                score.firstTimeSignature[0] + "/" + score.firstTimeSignature[1],
                NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS
            ),
            new NotesObject()
        )

        return engine
    }
}
