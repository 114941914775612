import Score from "../../../general/classes/score/score"
import { Time } from "../../../general/modules/time"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    GridDimensionsDictionary,
    CanvasType,
    ChordsEditingContext,
    GridCoordinates,
    RemoveLastGutter,
    Chord,
    RenderChordsType,
    HoveredElement,
} from "../types"
import ScoreCanvas, { CHORD_FONT_SIZE_PERCENTAGE } from "./score-canvas"
import {
    Coordinates,
    CoordinatesAbs,
    EventHandlers,
} from "../../../general/modules/event-handlers"
import { ChordManipulation } from "../../../general/modules/chord-manipulation.module"
import {
    convertNumeralToDisplay,
    getMainAndSuperscriptTextFromChordSymbol,
    getPitchClassAndChordTypeFromChordSymbol,
} from "../../../general/utils/composition-workflow.util"
import { Observable } from "rxjs"
import { HoveringTypeEnum } from "../../../general/types/general"
import {
    CHORDS_QUANTIZATION_THRESHOLDS,
    TIMESTEP_RES,
} from "../../../general/constants/constants"
import { SRActionTypes } from "../states/score-rendering/score-rendering.actions"
import { TemplateChord } from "../../../general/interfaces/score/templateScore"
import { featureFlags } from "../../../general/utils/feature-flags"

export default class ChordsEditingCanvas extends ScoreCanvas {
    private leaveDetection$: Observable<Coordinates>
    private edgeWidth = 20
    private iconWidth = 14
    private borderRadius = 4
    private insertLeftIcon
    private insertRightIcon

    private plusButtonWidth = 50
    private isResizing = false

    constructor(
        public context: ChordsEditingContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)

        this.leaveDetection$ = EventHandlers.getMouseEventObservable(
            "mouseleave",
            this.context.canvasContainer,
            0,
            this.destroyObservable,
            this.context.canvasContainer
        )

        this.initListeners()
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        if (
            !scoreState.score ||
            !this.shouldRenderCanvas(scoreState.renderingType)
        ) {
            return
        }

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: grid,
            noteRes: grid["pitched"].timestepRes,
            nbOfYValues: 0,
        })

        let chords = scoreState.score.chords
        let romanNumerals = scoreState.score.romanNumerals

        if (scoreState.temporaryChords && scoreState.temporaryRomanNumerals) {
            chords = scoreState.temporaryChords
            romanNumerals = scoreState.temporaryRomanNumerals
        }

        this.renderChordLabels({
            chords,
            romanNumerals,
        })
    }

    /**
     * This method identifies the chord by a given coordinates object.
     * In case there is a chord based on the coordinates, it will be set as the hovered element
     * @param coordinates
     * @returns
     */
    private getChordByCoordinates({
        coordinates,
        setHoveredElement = true,
    }: {
        coordinates: Coordinates | CoordinatesAbs
        setHoveredElement: boolean
    }) {
        const romanNumerals = this.queries.scoreRendering.score.romanNumerals
        const chords = this.queries.scoreRendering.score.chords

        if (!this.context.allowChordsEditing) {
            return undefined
        }

        if (setHoveredElement && this.mouseDownStart !== undefined) {
            return {
                element: this.hoveredElement?.element,
                type: this.hoveredElement?.draggingType,
            }
        }

        if (setHoveredElement) {
            this.setCanvasCursor("default")
            this.triggerReRender()
        }

        let draggingType

        const timestepRange = {
            start: this.scrollToTimestep,
            end: this.scrollToTimestep + this.getTimestepRange(),
        }

        let result = undefined

        ChordManipulation.iterateThroughChords(
            chords,
            ({ index, boundary, start, duration, chord }) => {
                if (result) {
                    return false
                }

                const startTimesteps = Time.fractionToTimesteps(
                    this.noteRes,
                    start
                )
                const romanNumeral = romanNumerals[index]
                const endTimesteps = Time.fractionToTimesteps(
                    this.noteRes,
                    Time.addTwoFractions(duration, start)
                )

                if (
                    startTimesteps < timestepRange.start &&
                    endTimesteps < timestepRange.start
                ) {
                    return true
                } else if (startTimesteps > timestepRange.end) {
                    return false
                }

                if (romanNumeral === undefined) {
                    return true
                }

                const chordDimensions = this.getChordDimensions({
                    selectedChordIndex: index,
                })

                if (!chordDimensions) {
                    return true
                }

                const edgeWidth = this.getEdgeWidth(chordDimensions.width)
                if (
                    coordinates.x >= chordDimensions.start - 1 &&
                    coordinates.x <= chordDimensions.end + 1
                ) {
                    let hoverType = HoveringTypeEnum.CENTER

                    const leftEdgeEnd = chordDimensions.start + edgeWidth
                    const rightEdgeEnd = chordDimensions.end - edgeWidth

                    if (coordinates.x <= leftEdgeEnd) {
                        hoverType = HoveringTypeEnum.LEFT
                    } else if (coordinates.x >= rightEdgeEnd) {
                        hoverType = HoveringTypeEnum.RIGHT
                    }

                    draggingType = hoverType

                    const element: Chord = {
                        numeral: romanNumeral[1],
                        start: start,
                        duration: duration,
                        index: index,
                    }

                    result = {
                        element,
                        type: draggingType,
                    }

                    return false
                }

                return true
            }
        )

        if (!result?.element) {
            this.updateChordsEditingButtonRow()
            return undefined
        }

        if (setHoveredElement && result?.element) {
            this.setHoveredChord(result.element, draggingType, coordinates)

            if (draggingType && draggingType !== HoveringTypeEnum.CENTER) {
                this.setCanvasCursor("col-resize")

                this.renderResizeIndicator(result.element.index, draggingType)
            }
        }

        return result
    }

    private triggerReRender() {
        this.actions.scoreRendering.manager.emitter$.next({
            type: SRActionTypes.setRenderingType,
            data: {
                type: ["ChordsEditingCanvas"],
            },
        })
    }

    private getChordDimensions({
        selectedChordIndex,
    }: {
        selectedChordIndex: number
    }):
        | {
              start: number
              width: number
              end: number
              index: number
              chord: string
          }
        | undefined {
        const romanNumerals = this.queries.scoreRendering.score.romanNumerals

        if (!romanNumerals?.length || !romanNumerals[selectedChordIndex]) {
            return
        }

        const timestepRange = {
            start: this.scrollToTimestep,
            end: this.scrollToTimestep + this.getTimestepRange(),
        }

        const scrollXOffset = ScoreCanvas.getScrollXOffset({
            scrollToTimestep: this.scrollToTimestep,
            grid: this.grid,
            pxPerTimestep: this.pxPerTimestep,
            noteRes: this.noteRes,
        })

        let result

        ChordManipulation.iterateThroughChords(
            romanNumerals,
            ({ index, boundary, start, duration, chord }) => {
                if (result) {
                    return false
                }

                if (index !== selectedChordIndex) {
                    return true
                }

                const romanNumeral = romanNumerals[index]

                if (romanNumeral === undefined) {
                    return true
                }

                const startTimesteps = Time.fractionToTimesteps(
                    this.noteRes,
                    start
                )
                const endTimesteps = Time.fractionToTimesteps(
                    this.noteRes,
                    Time.addTwoFractions(duration, start)
                )

                if (
                    startTimesteps < timestepRange.start &&
                    endTimesteps < timestepRange.start
                ) {
                    return true
                } else if (startTimesteps > timestepRange.end) {
                    return false
                }

                const x = {
                    start:
                        ScoreCanvas.getPixelsForTimesteps({
                            timesteps: startTimesteps,
                            removeLastGutterPx: "none",
                            timestepRes: this.grid.timestepRes,
                            grid: this.grid,
                        }) - scrollXOffset,

                    end:
                        ScoreCanvas.getPixelsForTimesteps({
                            timesteps: endTimesteps,
                            removeLastGutterPx: "none",
                            timestepRes: this.grid.timestepRes,
                            grid: this.grid,
                        }) - scrollXOffset,
                }

                const chordGap = 1
                const chordStart = x.start + chordGap
                const chordWidth = x.end - x.start - 2 * chordGap
                const chordEnd = chordStart + chordWidth

                result = {
                    start: chordStart,
                    width: chordWidth,
                    end: chordEnd,
                    index: index,
                    chord: chord,
                }

                return false
            }
        )

        return result
    }

    private initListeners() {
        if (!this.context.allowChordsEditing) {
            return
        }

        this.hoveredDetection$.subscribe((coordinates: Coordinates) => {
            if (this.queries?.scoreRendering?.score === undefined) {
                return
            }

            this.getChordByCoordinates({
                coordinates,
                setHoveredElement: true,
            })

            if (
                this.mouseDownStart ||
                !this.hoveredElement?.draggingType ||
                !this.hoveredElement?.element
            ) {
                return
            }

            const chord = this.hoveredElement.element as Chord

            const dimensions = this.getChordDimensions({
                selectedChordIndex: chord.index,
            })
        })

        this.mouseDown$.subscribe(this.mouseDownHandler.bind(this))
        this.resize$.subscribe(event => this.mouseMoveHandler(event, "resize"))
        this.mouseUp$.subscribe(event => this.mouseUpHandler(event))

        this.scroll$.subscribe((event: WheelEvent) => {
            this.setHoveredElement(undefined)
            this.updateChordsEditingButtonRow()
            this.triggerReRender()
        })

        this.leaveDetection$.subscribe((event: Coordinates) => {
            if (this.isResizing) {
                this.mouseMoveHandler(event, "resize")
            } else {
                this.setHoveredElement(undefined)
                this.updateChordsEditingButtonRow()
                this.triggerReRender()
            }
        })
    }

    protected mouseDownHandler(event: CoordinatesAbs) {
        super.mouseDownHandler(event, (grid: GridCoordinates) => {
            return this.getChordByCoordinates({
                coordinates: event,
                setHoveredElement: true,
            })
        })
        const chords = this.queries.scoreRendering.score.chords
        const chordDimensions = this.getChordDimensions({
            selectedChordIndex: chords.length - 1,
        })

        if (
            chordDimensions &&
            featureFlags.enableChordsEditingInEditor &&
            event.x > chordDimensions.end + 1 &&
            event.x <= chordDimensions.end + this.plusButtonWidth
        ) {
            this.actions.scoreRendering.manager.emitter$.next({
                type: SRActionTypes.chordsPlusButtonClicked,
                data: {},
                options: {
                    isUndoable: true,
                },
            })
        } else {
            this.srEmitter$.next({
                type: SRActionTypes.emptyAction,
                data: {},
                options: {
                    isUndoable: true,
                },
            })
        }
    }

    protected mouseUpHandler(event: Coordinates) {
        super.mouseUpHandler(event)
        this.isResizing = false
        if (this.queries?.scoreRendering?.all?.temporaryRomanNumerals) {
            // add end manipulation action call here
            this.srEmitter$.next({
                type: SRActionTypes.endResizeChord,
                data: {},
            })
        }
    }

    private getEdgeWidth(elementWidth: number) {
        const borderRadius = this.borderRadius

        let edgeWidth = this.edgeWidth

        if ((edgeWidth + 3) * 2 >= elementWidth) {
            edgeWidth = elementWidth / 2 - 3
        }

        // adjust the edge width to assure there is
        // enough space to drag the element
        if (edgeWidth < borderRadius + 4 || elementWidth < 2 * edgeWidth + 10) {
            return elementWidth / 3
        }

        return edgeWidth
    }

    private setHoveredChord(
        chord: Chord,
        draggingType: HoveringTypeEnum,
        coordinates: CoordinatesAbs | Coordinates
    ) {
        if (
            (this.hoveredElement?.element as Chord)?.index !== chord?.index ||
            (this.hoveredElement?.draggingType !== draggingType &&
                !this.mouseDownStart)
        ) {
            const hoveredElement = {
                element: chord,
                draggingType: draggingType,
                type: "chord" as const,
            }

            this.setHoveredElement(hoveredElement)

            this.updateChordsEditingButtonRow({
                hoveredElement,
                coordinates: coordinates as CoordinatesAbs,
            })

            this.triggerReRender()
        }
    }

    /**
     * renders the chord labels including the text
     * @param args Obj containing chords with chord symbols and roman numerals
     */
    private renderChordLabels(args: {
        chords: TemplateChord[] | undefined
        romanNumerals: TemplateChord[] | undefined
    }) {
        if (!args?.chords || !args?.romanNumerals) {
            return
        }

        const chords = args.chords
        const ctx = this.getContext("canvas")
        const romanNumerals = args.romanNumerals

        const timestepRange = {
            start: this.scrollToTimestep,
            end: this.scrollToTimestep + this.getTimestepRange(),
        }

        const scrollXOffset = ScoreCanvas.getScrollXOffset({
            scrollToTimestep: this.scrollToTimestep,
            grid: this.grid,
            pxPerTimestep: this.pxPerTimestep,
            noteRes: this.noteRes,
        })

        let startFraction = "0"

        chords.every((chord, index) => {
            const start = Time.fractionToTimesteps(this.noteRes, startFraction)
            const romanNumeral = romanNumerals[index]

            startFraction = Time.addTwoFractions(chord[0], startFraction)

            const end = Time.fractionToTimesteps(this.noteRes, startFraction)

            if (start < timestepRange.start && end < timestepRange.start) {
                return true
            } else if (start > timestepRange.end) {
                return false
            }

            const x = {
                start:
                    ScoreCanvas.getPixelsForTimesteps({
                        timesteps: start,
                        removeLastGutterPx: "none",
                        timestepRes: this.grid.timestepRes,
                        grid: this.grid,
                    }) - scrollXOffset,

                end:
                    ScoreCanvas.getPixelsForTimesteps({
                        timesteps: end,
                        removeLastGutterPx: "none",
                        timestepRes: this.grid.timestepRes,
                        grid: this.grid,
                    }) - scrollXOffset,
            }

            const backgroundColor = "rgba(255,255,255,0.1)"
            const chordGap = 1

            ScoreCanvas.roundRect(
                ctx,
                x.start + chordGap,
                0,
                x.end - x.start - 2 * chordGap,
                this.height,
                4,
                backgroundColor
            )
            ctx.fill()

            if (romanNumeral === undefined) {
                return true
            }

            const copyX = {
                start: x.start + 15,
                end: x.end - 15,
            }

            let chordText = chord[1]

            if (
                chordText.split(" ").length > 1 &&
                chordText.split(" ")[1] === "("
            ) {
                chordText = chordText.replace(" (", "(")
            }

            // get main text dimensions
            const newLocal = CHORD_FONT_SIZE_PERCENTAGE / 100
            const mainTextHeight =
                ctx.measureText(chordText).actualBoundingBoxAscent * newLocal

            const totalTextHeight = mainTextHeight

            let y = this.height / 2 + totalTextHeight / 2

            if (this.hoveredElement !== undefined) {
                const element = this.hoveredElement.element as Chord

                if (element.index === index) {
                    y -= 10
                }
            }

            const displayNumeral = convertNumeralToDisplay(romanNumeral[1])

            ScoreCanvas.renderLabelWithSuperscript({
                ctx,
                text:
                    this.queries.scoreRendering.getValue().renderChordsType ===
                    "roman-numeral"
                        ? displayNumeral
                        : chord[1],
                x: copyX,
                y,
            })

            if (
                index === chords.length - 1 &&
                featureFlags.enableChordsEditingInEditor &&
                this.queries["scoreRendering"].editorType === "editor"
            ) {
                ScoreCanvas.roundRect(
                    ctx,
                    x.end,
                    0,
                    this.plusButtonWidth,
                    this.height,
                    4,
                    backgroundColor
                )
                ctx.fill()

                const offsets = { x: 4, y: 5 }

                ScoreCanvas.drawText(
                    ctx,
                    x.end + this.plusButtonWidth / 2 - offsets.x,
                    offsets.y + this.height / 2,
                    "+",
                    CHORD_FONT_SIZE_PERCENTAGE,
                    "normal",
                    "rgba(255,255,255,0.8)",
                    "left"
                )
            }

            return true
        })
    }

    protected updateChordsEditingButtonRow(args?: {
        hoveredElement: HoveredElement | undefined
        coordinates: CoordinatesAbs
    }) {
        if (!args?.hoveredElement) {
            this.context.chordsEditingButtons(undefined)
            return
        }

        const dimensions = this.getChordDimensions({
            selectedChordIndex: (args.hoveredElement.element as Chord)?.index,
        })

        this.context.chordsEditingButtons({
            chord: this.hoveredElement?.element as Chord,
            start: dimensions.start - 2,
            width: dimensions.width + 2,
        })
    }

    private renderResizeIndicator(index: number, edge: any) {
        if (
            !this.hoveredElement ||
            this.hoveredElement?.draggingType === "center"
        ) {
            return
        }

        const dimensions = this.getChordDimensions({
            selectedChordIndex: index,
        })
        const ctx = this.getContext("canvas")
        const text = "|  |"
        const textHeight = ctx.measureText(text).actualBoundingBoxAscent

        const y = this.height / 2 + textHeight / 2
        const x = edge === "left" ? dimensions.start - 1 : dimensions.end + 1

        ScoreCanvas.drawText(
            ctx,
            x,
            y,
            text,
            80,
            "normal",
            "rgba(255,255,255,0.8)",
            "center"
        )
    }

    protected mouseMoveHandler(event: Coordinates, type: "resize") {
        const r = this.moveHandler(event, {
            type,
            thresholds: CHORDS_QUANTIZATION_THRESHOLDS,
            quantize: false,
        })

        if (!r || !this.mouseDownStart?.start) return

        this.isResizing = true

        let diffTimesteps =
            this.mouseDownStart.last.grid.timesteps -
            this.mouseDownStart.start.grid.timesteps

        diffTimesteps = Time.convertTimestepsToAnotherRes(
            diffTimesteps,
            this.noteRes,
            TIMESTEP_RES
        )

        const beatRes = this.queries.scoreRendering.score.firstTimeSignature[1]

        let offset = Time.timestepToFraction(diffTimesteps, this.noteRes)
        if (
            featureFlags.applyMusicEngineChordRestrictions &&
            this.queries.scoreRendering.editorType === "editor"
        ) {
            offset = Time.quantizeFractionToString(
                offset,
                this.mouseDownStart.mouseDownLocation === HoveringTypeEnum.LEFT
                    ? "floor"
                    : "ceil",
                beatRes
            )
        }

        this.updateChordsEditingButtonRow()

        this.srEmitter$.next({
            type: SRActionTypes.resizeChord,
            data: {
                index: (this.hoveredElement?.element as Chord).index,
                offset: offset,
                hoveringType: this.mouseDownStart.mouseDownLocation,
            },
            options: {
                isUndoable: false,
            },
        })
    }
}
