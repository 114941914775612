<div class="modal-title">Publish generation profile</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<div class="publish-gp">
    <div class="step-container step-1" *ngIf="step == 1">
        <div class="settings-row">
            <div class="settings-row-title">
                Profile name
                <img
                    class="tooltip-img"
                    src="assets/img/info.svg"
                    [tooltip]="tooltips.name"
                    [tooltip-marginTop]="10"
                    [tooltip-width]="250" />
            </div>

            <div class="settings-row-value">
                <input
                    class="light-input publish-gp-input"
                    placeholder="Generation profile name"
                    [(ngModel)]="metadata.name" />
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">
                Style metadata
                <img
                    class="tooltip-img"
                    src="assets/img/info.svg"
                    [tooltip]="tooltips.styleMetadata"
                    [tooltip-marginTop]="10"
                    [tooltip-width]="250" />
            </div>

            <div class="settings-row-value">
                <dropdown
                    class="general-settings-dropdown"
                    [sort]="true"
                    [value]="getSelectedStyle()"
                    [showTooltip]="false"
                    [items]="getStyles()"
                    (selectedItem)="selectStyle($event)"></dropdown>
            </div>
        </div>

        <div class="error-message" *ngIf="error != ''">
            {{ error }}
        </div>

        <div class="publish-gp-buttons-container" *ngIf="!publishLoading">
            <a-button
                type="secondary"
                width="100%"
                label="Next"
                (onClick)="goToStep(2)"></a-button>
        </div>

        <div class="loader-container" *ngIf="publishLoading">
            <div class="loader"></div>
        </div>
    </div>

    <div class="step-container step-3" *ngIf="step == 2">
        <div class="checkmark-icon">
            <svg
                class="checkmark-green"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52">
                <circle
                    class="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none" />
                <path
                    class="checkmark__check"
                    fill="transparent"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>

            Your generation profile was published for review!

            <div class="note-about-publication">
                Please note: we created a copy of this profile, so any further
                revisions made to this profile would need to be published again.
            </div>
        </div>
    </div>
</div>
