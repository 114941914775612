import { NoteResolution } from "@common-lib/types/score"
import { DropdownItemType } from "../types/dropdownItemType"
import { ScoreRendering } from "../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.store"
import ScoreRenderingEngine from "../../../../common-lib/client-only/score-rendering-engine/engine"
import PercussionLayer from "@common-lib/classes/score/percussionlayer"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { CreateTrackFrom } from "@common-lib/types/general"

export module Helpers {
    export function showPreset(cutOffDate: number | undefined) {
        return (
            cutOffDate !== undefined &&
            // Corresponds to cut off date for showing presets, Nov 20th 2023
            cutOffDate <= 1700488170000
        )
    }

    export function getCreationMethods(
        accountCreationDate: number | undefined
    ): { value: CreateTrackFrom; name: string }[] {
        const opts: { value: CreateTrackFrom; name: string; avData: string }[] =
            [
                { name: "Styles", value: "Styles", avData: "cc-styles" },
                {
                    name: "Chord progression",
                    value: "Chord progression",
                    avData: "cc-chord-progression",
                },
            ]

        opts.push({
            name: "Step by step",
            value: "Step by step",
            avData: "cc-step-by-step",
        })

        opts.push({
            name: "Upload influence",
            value: "Upload influence",
            avData: "cc-upload-influence",
        })

        if (featureFlags.enableMidiUpload) {
            opts.push({
                name: "Import MIDI",
                value: "Import MIDI",
                avData: "cc-upload-midi",
            })
        }

        if (showPreset(accountCreationDate)) {
            opts.push({
                name: "Presets (legacy)",
                value: "Presets",
                avData: "cc-presets",
            })
        }

        return opts
    }

    /**
     * Wait for a DOM element to become available in the DOM
     * @param id
     * @returns
     */
    export function waitForElementByID(id: string): Promise<HTMLElement> {
        return new Promise(resolve => {
            const elem = document.getElementById(id)

            if (elem) {
                return resolve(elem)
            }

            const observer = new MutationObserver(mutations => {
                if (document.getElementById(id)) {
                    resolve(document.getElementById(id))
                    observer.disconnect()
                }
            })

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            })
        })
    }

    export function waitForElementByClassName(className: string) {
        return new Promise(resolve => {
            const elem = document.getElementsByClassName(className)

            if (elem) {
                return resolve(elem)
            }

            const observer = new MutationObserver(mutations => {
                if (document.getElementsByClassName(className)) {
                    resolve(document.getElementsByClassName(className))
                    observer.disconnect()
                }
            })

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            })
        })
    }

    export function getSelectedPatternNoteRes(
        engine: ScoreRenderingEngine,
        options: DropdownItemType<NoteResolution>[]
    ) {
        let option: DropdownItemType<NoteResolution>
        if (
            !options.find(
                option => option.value === engine.selectedPattern?.resolution
            )
        ) {
            option = options[0]
            engine.setPatternResolution(
                <PercussionLayer>engine.toggledLayer,
                engine.selectedPattern,
                option.value
            )
        } else {
            option = {
                value: engine.selectedPattern?.resolution as any,
                name: engine.selectedPattern?.noteRes + "th note",
            }
        }
        return option
    }
}
