<div class="pages-selector">
    <div class="image-wrapper">
        <img
            src="assets/img/pages/left.svg"
            (click)="previous()"
            *ngIf="currentPage > 1" />
    </div>

    <div class="current-page-number">
        {{ currentPage }} / {{ numberOfPages }}
    </div>

    <div class="image-wrapper">
        <img
            src="assets/img/pages/right.svg"
            (click)="next()"
            *ngIf="currentPage < numberOfPages" />
    </div>
</div>
