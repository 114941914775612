import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
} from "@angular/core"
import { CompositionWorkflowService } from "@services/composition-workflow/composition-workflow.service"
import { Subject, filter, takeUntil, tap } from "rxjs"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import { CompositionWorkflowHTTPService } from "@services/composition-workflow/composition-workflow-http.service"
import {
    CWState,
    steps,
} from "@services/composition-workflow/cw-store/cw.store"
import { CWActionsType } from "@services/composition-workflow/cw-store/cw.actions"
import { ParentClass } from "../../parent"
import { ShortcutsService } from "@services/shortcuts.service"
import { ActivatedRoute } from "@angular/router"
import { CompositionWorkflowType } from "@common-lib/types/general"
import { TutorialService } from "@services/tutorial.service"
import { UserService } from "@services/user.service"
import { CacheService } from "@services/cache.service"

@Component({
    selector: "composition-workflow",
    templateUrl: "composition-workflow.component.html",
    styleUrls: ["composition-workflow.component.scss"],
    providers: [CompositionWorkflowHTTPService, CompositionWorkflowService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositionWorkflowComponent extends ParentClass {
    public gp: GenerationProfile

    private _error: { message: string }

    private readonly errorMessageHideDuration = 3000

    public get error(): { message: string } {
        return this._error
    }

    public get steps() {
        return steps
    }

    public get skipStep2() {
        return this.cwService.query.getValue().type === "chordProgression"
    }

    public get numberOfSteps() {
        return this.skipStep2 ? 2 : this.steps.length
    }

    public get stepData() {
        return this.cwService.query.getValue().stepData
    }

    public loading: { finished: boolean; error?: string }

    public async setStep(step: number) {
        this.cwService.actions.emitter$.next({
            type: CWActionsType.setStep,
            data: {
                step,
            },
        })
    }

    private unsubscribe$ = new Subject<null>()

    public showStepComponents: boolean = true

    constructor(
        public cwService: CompositionWorkflowService,
        private shortcuts: ShortcutsService,
        private cd: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private tutorialService: TutorialService,
        private cache: CacheService
    ) {
        super()

        this.init()
    }

    private async init() {
        const workflowID = this.activatedRoute.snapshot.paramMap.get(
            "compositionWorkflowID"
        )

        if (workflowID !== null && workflowID !== undefined) {
            await this.cwService.initializeService({
                cwID: workflowID,
            })
        } else {
            await this.cwService.initializeService({
                gpID: this.activatedRoute.snapshot.paramMap.get(
                    "generationProfileID"
                ),
                type: this.activatedRoute.snapshot.paramMap.get(
                    "type"
                ) as CompositionWorkflowType,
            })
        }

        this.initListeners()
    }

    private initListeners(): void {
        this.cwService.gp$
            .pipe(
                filter(val => !!val),
                takeUntil(this.unsubscribe$),
                tap(gp => this.initGPData(gp))
            )
            .subscribe()

        this.cwService.errors$
            .pipe(
                filter(val => !!val),
                takeUntil(this.unsubscribe$),
                tap(error => (this._error = error)),
                tap(() => {
                    setTimeout(() => {
                        this._error = null
                    }, this.errorMessageHideDuration)
                })
            )
            .subscribe()

        this.cwService.query
            .select("loading")
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(loading => {
                    this.loading = loading
                    this.cd.markForCheck()
                })
            )
            .subscribe()
    }

    public closeBanner() {
        this.cwService.errors$.next({ message: null })
    }

    async ngOnInit() {}

    ngOnDestroy() {
        super.ngOnDestroy()
        this.unsubscribe$.next(null)
        this.unsubscribe$.complete()

        this.cwService.onDestroy()
    }

    async ngAfterViewInit() {
        await ParentClass.waitUntilTrueForObservable(
            this.cwService.query.select(),
            (value: CWState) => {
                return value.loading.finished
            }
        )

        this.cd.detectChanges()
    }

    private initGPData(gp: GenerationProfile): void {
        this.gp = gp
    }

    public displayHeader() {
        return (
            this.cwService.query.getValue().stepData.index !== 1 ||
            (this.cwService.query.getValue().stepData.index === 1 &&
                this.cwService.query.engine.toggledLayer === undefined)
        )
    }

    public async initStepData(direction: "previous" | "next") {
        return this.cwService.initStepData(
            direction,
            this.cwService.query.getValue().stepData,
            this.steps.length,
            this.skipStep2
        )
    }

    public initTutorial() {
        this.tutorialService.initTutorial$.next(true)
    }

    @HostListener("window:resize", ["$event"])
    async onResize() {
        if (this.cwService.engine === undefined) {
            return
        }

        await this.cwService.engine.resizeCanvases()
    }

    @HostListener("document:keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        if (this.cwService?.engine === undefined) {
            return
        }

        this.shortcuts.triggerShortcuts(event, this.cwService)
    }
}
