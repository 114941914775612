import Layer from "../../../general/classes/score/layer"
import Score from "../../../general/classes/score/score"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    LayerPreviewCanvasContext,
    GridDimensionsDictionary,
    CanvasType,
} from "../types"
import ScoreCanvas from "./score-canvas"
import { cloneDeep } from "lodash"
import PercussionLayer from "../../../general/classes/score/percussionlayer"
import { NotesObject } from "../../../general/classes/score/notesObject"
import { Time } from "../../../general/modules/time"
import { Time as Time2 } from "../../../general/modules/time2"
import { Note } from "../../../general/classes/score/note"
import { PRERENDER_BUFFER_SIZE_IN_PX } from "../../../general/constants/constants"
import PatternRegion from "../../../general/classes/score/patternregion"
import { FractionString } from "../../../general/types/score"
import { Fraction } from "../../../general/classes/score/fraction"
import { featureFlags } from "../../../general/utils/feature-flags"

export class LayerPreviewCanvas extends ScoreCanvas {
    private prerenderedCanvas: HTMLCanvasElement
    private cachedPositions: { end: number; start: number }

    constructor(
        public context: LayerPreviewCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        if (
            !scoreState.score ||
            !this.shouldRenderCanvas(scoreState.renderingType)
        ) {
            return
        }

        const score: Score = scoreState.score
        const copiedGrid: GridDimensionsDictionary = cloneDeep(grid)

        const pxLength =
            copiedGrid.pitched.scoreLengthTimesteps *
                copiedGrid.pitched.pxPerTimestepWithoutGutters +
            copiedGrid.pitched.pxLengthForGutters

        if (pxLength < this.width) {
            copiedGrid.pitched.pxPerTimestepWithoutGutters =
                (this.width - copiedGrid.pitched.pxLengthForGutters) /
                copiedGrid.pitched.scoreLengthTimesteps
        }

        const result = this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: copiedGrid,
            noteRes: copiedGrid.pitched.timestepRes,
            nbOfYValues: Object.keys(score.layers).length,
        })

        this.renderLayerPreview(result.shouldPreRender)
    }

    private async renderLayerPreview(shouldPreRender) {
        const boundaries = this.getTimestepBoundaries("viewport")

        const boundaryLeftExceeded =
            this.cachedPositions?.start > this.scrollToTimestep

        const boundaryRightExceeded =
            this.cachedPositions?.end < boundaries.endInTimesteps

        if (
            !this.cachedPositions ||
            boundaryRightExceeded ||
            boundaryLeftExceeded ||
            shouldPreRender ||
            this.scrollToTimestep < 0
        ) {
            const score = this.queries.scoreRendering.score
            // console.time(`${this.type}`)

            this.prerenderedCanvas = featureFlags.useFractionClass
                ? this.prerenderLayerPreview2(score, this.context.layer)
                : this.prerenderLayerPreview(score, this.context.layer)
            // console.timeEnd(`${this.type}`)
        }

        const offset =
            (this.cachedPositions.start +
                this.getBufferInTimesteps() -
                this.scrollToTimestep) *
                this.pxPerTimestep -
            PRERENDER_BUFFER_SIZE_IN_PX

        this.renderFromPrerenderedCanvas(offset)
    }

    private renderFromPrerenderedCanvas(offset: number) {
        try {
            const ctx = this.getContext("canvas")

            if (
                this.prerenderedCanvas.width === 0 ||
                this.prerenderedCanvas.height === 0
            ) {
                return
            }

            ctx.drawImage(this.prerenderedCanvas, offset, 0)
        } catch (e) {
            console.warn(e)
        }
    }

    private getBufferInTimesteps() {
        return PRERENDER_BUFFER_SIZE_IN_PX / this.pxPerTimestep
    }

    private getTimestepBoundaries(type: "viewport" | "prerender") {
        if (type === "prerender") {
            return {
                startInTimesteps:
                    this.scrollToTimestep - this.getBufferInTimesteps(),
                endInTimesteps:
                    this.scrollToTimestep +
                    this.getTimestepRange() +
                    this.getBufferInTimesteps(),
            }
        }

        return {
            startInTimesteps: this.scrollToTimestep,
            endInTimesteps: this.scrollToTimestep + this.getTimestepRange(),
        }
    }

    private prerenderLayerPreview(score, layer: Layer | PercussionLayer) {
        let { startInTimesteps, endInTimesteps } =
            this.getTimestepBoundaries("prerender")

        const prerenderedCanvas = document.createElement(
            "canvas"
        ) as HTMLCanvasElement

        const ctx = prerenderedCanvas.getContext("2d")

        ctx.globalCompositeOperation = "copy"

        const startInFractions = Time.timestepToFraction(
            startInTimesteps,
            this.noteRes
        )

        const endInFractions = Time.timestepToFraction(
            endInTimesteps,
            this.noteRes
        )

        prerenderedCanvas.width =
            ScoreCanvas.getPixelsForTimesteps({
                grid: this.grid,
                timesteps: endInTimesteps,
                timestepRes: this.noteRes,
                removeLastGutterPx: "none",
            }) -
            ScoreCanvas.getPixelsForTimesteps({
                grid: this.grid,
                timesteps: startInTimesteps,
                timestepRes: this.noteRes,
                removeLastGutterPx: "none",
            })

        prerenderedCanvas.height = this.height

        this.cachedPositions = {
            start: startInTimesteps,
            end: endInTimesteps,
        }

        if (layer.type === "percussion") {
            this.renderPercussionPreview(
                score,
                <PercussionLayer>layer,
                startInFractions,
                endInFractions,
                ctx
            )
        } else {
            this.renderPitchedPreview(
                layer,
                startInFractions,
                endInFractions,
                ctx
            )
        }

        return prerenderedCanvas
    }

    private prerenderLayerPreview2(score, layer: Layer | PercussionLayer) {
        let { startInTimesteps, endInTimesteps } =
            this.getTimestepBoundaries("prerender")

        const prerenderedCanvas = document.createElement(
            "canvas"
        ) as HTMLCanvasElement

        const ctx = prerenderedCanvas.getContext("2d")

        ctx.globalCompositeOperation = "copy"

        const startInFractions = Time2.timestepToFraction(
            startInTimesteps,
            this.noteRes
        )

        const endInFractions = Time2.timestepToFraction(
            endInTimesteps,
            this.noteRes
        )

        prerenderedCanvas.width =
            ScoreCanvas.getPixelsForTimesteps({
                grid: this.grid,
                timesteps: endInTimesteps,
                timestepRes: this.noteRes,
                removeLastGutterPx: "none",
            }) -
            ScoreCanvas.getPixelsForTimesteps({
                grid: this.grid,
                timesteps: startInTimesteps,
                timestepRes: this.noteRes,
                removeLastGutterPx: "none",
            })

        prerenderedCanvas.height = this.height

        this.cachedPositions = {
            start: startInTimesteps,
            end: endInTimesteps,
        }

        if (layer.type === "percussion") {
            this.renderPercussionPreview2(
                score,
                <PercussionLayer>layer,
                startInFractions,
                endInFractions,
                ctx
            )
        } else {
            this.renderPitchedPreview2(
                layer,
                startInFractions,
                endInFractions,
                ctx
            )
        }

        return prerenderedCanvas
    }

    private renderPitchedPreview(
        layer: Layer,
        start: FractionString,
        end: FractionString,
        ctx?: CanvasRenderingContext2D
    ) {
        const drawNoteGroup = (noteGroup: Note[]) => {
            const range = layer.notesObject.getPitchRange()

            this.drawNoteGroup(
                noteGroup,
                note => {
                    const d = this.getNoteDimensions(
                        note,
                        range.highestNote - range.lowestNote,
                        this.noteRes,
                        range.highestNote,
                        note.pitch,
                        "pitched"
                    )

                    return d
                },
                ctx
            )

            return true
        }

        layer.notesObject.manipulateNoteGroups(
            drawNoteGroup.bind(this),
            [start, end],
            true
        )
    }

    private renderPitchedPreview2(
        layer: Layer,
        start: Fraction,
        end: Fraction,
        ctx?: CanvasRenderingContext2D
    ) {
        layer.notesObject.manipulateNoteGroups2(
            (noteGroup: Note[]) =>
                this.drawNoteGroupWithRange(layer, noteGroup, ctx),
            [start, end],
            true
        )
    }

    private drawNoteGroupWithRange(
        layer: Layer,
        noteGroup: Note[],
        ctx?: CanvasRenderingContext2D
    ) {
        const range = layer.notesObject.getPitchRange()

        this.drawNoteGroup(
            noteGroup,
            note => {
                const d = this.getNoteDimensions(
                    note,
                    range.highestNote - range.lowestNote,
                    this.noteRes,
                    range.highestNote,
                    note.pitch,
                    "pitched"
                )

                return d
            },
            ctx
        )

        return true
    }

    private renderPercussionPreview(
        score: Score,
        layer: PercussionLayer,
        start: FractionString,
        end: FractionString,
        ctx?: CanvasRenderingContext2D
    ): void {
        const timestepRange = {
            start: Time.fractionToTimesteps(this.noteRes, start),
            end: Time.fractionToTimesteps(this.noteRes, end),
        }

        const timeSignature = score.timeSignatures[0][1]

        const patternRegions = PercussionLayer.getPatternRegionsInRange({
            regions: layer.patternRegions,
            timestepRange: timestepRange,
        })

        const result = {}

        for (const region of patternRegions) {
            const notesObject = new NotesObject()
            const regionNotes = layer.getNotesForCoreRegion(
                region,
                true,
                timeSignature
            )

            for (const trackBusID in regionNotes) {
                if (!result[trackBusID]) {
                    result[trackBusID] = {
                        notes: new NotesObject(),
                        trackBus: regionNotes[trackBusID].trackBus,
                    }
                }
                const notes = regionNotes[trackBusID].notes
                notes.forEach(n =>
                    notesObject.addNoteToGroup(n, timeSignature, score.sections)
                )
            }

            const cachedCanvas = this.drawPatternRegionNotes(
                notesObject,
                region,
                ctx
            )

            if (cachedCanvas === undefined) {
                continue
            }

            for (let i = 0; i <= region.loop; i++) {
                const startFraction = Time.addTwoFractions(
                    region.start,
                    Time.multiplyFractionWithNumber(region.duration, i)
                )

                const newStartInTimesteps = Time.fractionToTimesteps(
                    this.noteRes,
                    startFraction
                )
                const dx =
                    newStartInTimesteps * this.pxPerTimestep -
                    this.scrollXOffset

                ctx.drawImage(cachedCanvas, dx, 0)
            }
        }
    }

    private renderPercussionPreview2(
        score: Score,
        layer: PercussionLayer,
        start: Fraction,
        end: Fraction,
        ctx?: CanvasRenderingContext2D
    ): void {
        const timestepRange = {
            start: Time2.fractionToTimesteps(this.noteRes, start),
            end: Time2.fractionToTimesteps(this.noteRes, end),
        }

        const timeSignature = score.timeSignatures[0][1]

        const patternRegions = PercussionLayer.getPatternRegionsInRange({
            regions: layer.patternRegions,
            timestepRange: timestepRange,
        })

        const result = {}

        for (const region of patternRegions) {
            const notesObject = new NotesObject()
            const regionNotes = layer.getNotesForCoreRegion(
                region,
                true,
                timeSignature
            )

            for (const trackBusID in regionNotes) {
                if (!result[trackBusID]) {
                    result[trackBusID] = {
                        notes: new NotesObject(),
                        trackBus: regionNotes[trackBusID].trackBus,
                    }
                }
                const notes = regionNotes[trackBusID].notes
                notes.forEach(n =>
                    notesObject.addNoteToGroup(n, timeSignature, score.sections)
                )
            }

            const cachedCanvas = this.drawPatternRegionNotes2(
                notesObject,
                region,
                ctx
            )

            if (cachedCanvas === undefined) {
                continue
            }

            for (let i = 0; i <= region.loop; i++) {
                const startFraction = Time2.addTwoFractions(
                    region.startFraction,
                    Time2.multiplyFractionWithNumber(region.durationFraction, i)
                )

                const newStartInTimesteps = Time2.fractionToTimesteps(
                    this.noteRes,
                    startFraction
                )
                const dx =
                    newStartInTimesteps * this.pxPerTimestep -
                    this.scrollXOffset

                ctx.drawImage(cachedCanvas, dx, 0)
            }
        }
    }

    private drawPatternRegionNotes(
        notes: NotesObject,
        region: PatternRegion,
        parentCtx: CanvasRenderingContext2D
    ): HTMLCanvasElement | undefined {
        if (region.pattern === undefined) {
            return undefined
        }

        const channels = region.pattern.getUniqueChannelsWithOnsets()
        const nbOfYValues = channels.length - 1
        const noteRes = Time.fractionToDictionary(
            region.pattern.resolution
        ).denominator
        const prerenderedCanvas = document.createElement(
            "canvas"
        ) as HTMLCanvasElement

        const ctx = prerenderedCanvas.getContext("2d")
        ctx.globalCompositeOperation = "copy"

        prerenderedCanvas.height = parentCtx.canvas.height

        prerenderedCanvas.width = Math.max(
            parentCtx.canvas.width,
            ScoreCanvas.getPixelsForTimesteps({
                grid: this.grid,
                timesteps: Time.fractionToTimesteps(
                    this.noteRes,
                    region.duration
                ),
                timestepRes: this.noteRes,
                removeLastGutterPx: "none",
            }) -
                ScoreCanvas.getPixelsForTimesteps({
                    grid: this.grid,
                    timesteps: Time.fractionToTimesteps(
                        this.noteRes,
                        region.start
                    ),
                    timestepRes: this.noteRes,
                    removeLastGutterPx: "none",
                }) +
                PRERENDER_BUFFER_SIZE_IN_PX
        )

        notes.manipulateNoteGroups((noteGroup: Note[]) => {
            const newStart = Time.addTwoFractions(
                noteGroup[0].start,
                region.start,
                true
            )

            this.drawNoteGroup(
                noteGroup,
                note => {
                    return this.getNoteDimensions(
                        {
                            start: newStart,
                            duration: note.duration,
                        },
                        nbOfYValues,
                        noteRes,
                        nbOfYValues,
                        region.getChannelIndexForNote(channels, note),
                        "percussion"
                    )
                },
                ctx
            )

            return true
        })

        return prerenderedCanvas
    }

    private drawPatternRegionNotes2(
        notes: NotesObject,
        region: PatternRegion,
        parentCtx: CanvasRenderingContext2D
    ): HTMLCanvasElement | undefined {
        if (region.pattern === undefined) {
            return undefined
        }

        const channels = region.pattern.getUniqueChannelsWithOnsets()
        const nbOfYValues = channels.length - 1
        const noteRes = region.pattern.resolutionFraction.denominator

        const prerenderedCanvas = document.createElement(
            "canvas"
        ) as HTMLCanvasElement

        const ctx = prerenderedCanvas.getContext("2d")
        ctx.globalCompositeOperation = "copy"

        prerenderedCanvas.height = parentCtx.canvas.height

        prerenderedCanvas.width = Math.max(
            parentCtx.canvas.width,
            ScoreCanvas.getPixelsForTimesteps({
                grid: this.grid,
                timesteps: Time2.fractionToTimesteps(
                    this.noteRes,
                    region.durationFraction
                ),
                timestepRes: this.noteRes,
                removeLastGutterPx: "none",
            }) -
                ScoreCanvas.getPixelsForTimesteps({
                    grid: this.grid,
                    timesteps: Time2.fractionToTimesteps(
                        this.noteRes,
                        region.startFraction
                    ),
                    timestepRes: this.noteRes,
                    removeLastGutterPx: "none",
                }) +
                PRERENDER_BUFFER_SIZE_IN_PX
        )

        notes.manipulateNoteGroups2((noteGroup: Note[]) => {
            const newStart = Time2.addTwoFractions(
                noteGroup[0].startFraction,
                region.startFraction,
                true
            )

            this.drawNoteGroup(
                noteGroup,
                note => {
                    return this.getNoteDimensions2(
                        {
                            start: newStart,
                            duration: note.duration,
                        },
                        nbOfYValues,
                        noteRes,
                        nbOfYValues,
                        region.getChannelIndexForNote(channels, note),
                        "percussion"
                    )
                },
                ctx
            )

            return true
        })

        return prerenderedCanvas
    }

    private drawNoteGroup(
        noteGroup: Note[],
        higherOrderFunction,
        prerenderedCanvasContext: CanvasRenderingContext2D
    ) {
        const configs = this.queries.scoreRendering.layerPreviewConfigs
        const ctx = prerenderedCanvasContext || this.getContext("canvas")
        ctx.beginPath()

        for (const note of noteGroup) {
            const d = higherOrderFunction(note)

            ctx.rect(d.x, d.y, d.width, d.height)
        }

        ctx.strokeStyle = "transparent"

        if (configs?.fillNotesWithLayerColor) {
            ctx.fillStyle = this.context.layer.getColor()
        } else {
            ctx.fillStyle = "white"
        }

        ctx.lineWidth = 0

        ctx.fill()
        ctx.closePath()
    }

    private getNoteDimensions(
        note: {
            start: FractionString
            duration: FractionString
        },
        nbOfYValues: number,
        timestepRes: number,
        highestPosition,
        notePosition,
        type: "pitched" | "percussion"
    ) {
        const invisibleBorderSize = 5
        const configs = this.queries.scoreRendering.layerPreviewConfigs

        if (configs.skipAdjustments) {
            nbOfYValues += 10
            highestPosition += 5
        }

        const start = Time.fractionToTimesteps(timestepRes, note.start)
        const duration = Time.fractionToTimesteps(
            timestepRes,
            type === "pitched" ? note.duration : "1/" + timestepRes
        )

        if (nbOfYValues <= 3) {
            nbOfYValues = 4
            highestPosition = notePosition + nbOfYValues / 2
        }

        let noteHeight = (this.height - invisibleBorderSize * 2) / nbOfYValues

        let x = ScoreCanvas.getPixelsForTimesteps({
            timesteps: start,
            timestepRes: timestepRes,
            grid: this.grid,
            removeLastGutterPx: "none",
        })

        if (type === "pitched") {
            x -= this.scrollXOffset
        }

        x += PRERENDER_BUFFER_SIZE_IN_PX

        const width = Math.ceil(
            ScoreCanvas.getPixelsForTimesteps({
                timesteps: duration,
                timestepRes: timestepRes,
                grid: this.grid,
                removeLastGutterPx: "none",
            })
        )

        if (!configs?.skipAdjustments) {
            noteHeight = Math.min(2, Math.max(1, noteHeight))
        } else {
            noteHeight = this.height / nbOfYValues
        }

        const y =
            this.round((highestPosition - notePosition - 1) * noteHeight) +
            invisibleBorderSize

        return {
            height: Math.max(1, noteHeight),
            width: width,
            x: x,
            y: y,
        }
    }

    private getNoteDimensions2(
        note: {
            start: Fraction
            duration: Fraction
        },
        nbOfYValues: number,
        timestepRes: number,
        highestPosition,
        notePosition,
        type: "pitched" | "percussion"
    ) {
        const invisibleBorderSize = 5
        const configs = this.queries.scoreRendering.layerPreviewConfigs

        if (configs.skipAdjustments) {
            nbOfYValues += 10
            highestPosition += 5
        }

        const start = Time2.fractionToTimesteps(timestepRes, note.start)
        const duration = Time2.fractionToTimesteps(
            timestepRes,
            type === "pitched"
                ? note.duration
                : new Fraction("1/" + timestepRes)
        )

        if (nbOfYValues <= 3) {
            nbOfYValues = 4
            highestPosition = notePosition + nbOfYValues / 2
        }

        let noteHeight = (this.height - invisibleBorderSize * 2) / nbOfYValues

        let x = ScoreCanvas.getPixelsForTimesteps({
            timesteps: start,
            timestepRes: timestepRes,
            grid: this.grid,
            removeLastGutterPx: "none",
        })

        if (type === "pitched") {
            x -= this.scrollXOffset
        }

        x += PRERENDER_BUFFER_SIZE_IN_PX

        const width = Math.ceil(
            ScoreCanvas.getPixelsForTimesteps({
                timesteps: duration,
                timestepRes: timestepRes,
                grid: this.grid,
                removeLastGutterPx: "none",
            })
        )

        if (!configs?.skipAdjustments) {
            noteHeight = Math.min(2, Math.max(1, noteHeight))
        } else {
            noteHeight = this.height / nbOfYValues
        }

        const y =
            this.round((highestPosition - notePosition - 1) * noteHeight) +
            invisibleBorderSize

        return {
            height: Math.max(1, noteHeight),
            width: width,
            x: x,
            y: y,
        }
    }
}
