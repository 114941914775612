import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core'
import { GenerationProfileService } from '@services/generation-profile/generationprofile.service'
import { ActivatedRoute, Router } from '@angular/router'
import { ReplaySubject } from 'rxjs/internal/ReplaySubject'
import { ApiService } from '@services/api.service'
import { ModalService } from '@services/modal.service'
import FormPreview from '@common-lib/classes/generationprofiles/formpreview'
import { Time }from '@common-lib/modules/time'
import { DesignService } from '@services/design.service'
import { ScoreManipulation } from "@common-lib/modules/scoremanipulation"
import { Misc } from '@common-lib/modules/misc'

@Component({
  	selector: 'development-preview',
  	templateUrl: 'development-preview.component.html',
  	styleUrls: ['development-preview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevelopmentPreviewComponent {
	@Input() preview:FormPreview

    @ViewChild('previewElement') previewElement:ElementRef
	@ViewChild('envelopeCanvas') envelopeCanvas:ElementRef

	previewGraphOptions = [
		{
			value: "tension",
			name: "Tension"
		},

		{
			value: "dynamics",
			name: "Dynamics"
		},

		{
			value: "hfc",
			name: "High Cut Filter"
		},

		{
			value: "lfc",
			name: "Low Cut Filter"
		}
	]

	loading = true

	previewWidth
	previewHeight = 100

	private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)

	border = "0px"

  	constructor(private designService:DesignService, private gpService:GenerationProfileService, private ref:ChangeDetectorRef, private router:Router) {		
		
	}

	ngOnDestroy() {
		this.destroy$.next(true)
		this.destroy$.complete()
	}

	ngOnInit() {
		if (!this.gpService.developmentPreviewGraphOption) {
			this.gpService.developmentPreviewGraphOption = this.previewGraphOptions[0]
		}

		this.border = this.designService.getCSSProperty("empty-development-preview-border")
	}

	async ngAfterViewInit() {
		this.previewWidth = this.previewElement.nativeElement.offsetWidth

		this.drawEnvelope()
	}

	@HostListener('window:resize', ['$event'])
    onResize(event) {
        this.drawEnvelope()
    }

	getSubsequenceWidth(subsequence, index) {
		let totalDuration = "0"

		for (let segment of this.preview.segments) {
			if (segment.subsequence !== subsequence) {
				continue
			}

			totalDuration = Time.addTwoFractions(segment.duration, totalDuration)
		}

		let value = this.computeWidthForDuration(totalDuration)

		if (index > 0) {
			value -= 5
		}

		return value - 2
	}

	selectPreviewGraph(event) {
		this.gpService.developmentPreviewGraphOption = event.new

		this.drawEnvelope()
	}

	getPreviewGraphOption() {
		return this.gpService.developmentPreviewGraphOption
	}

	drawEnvelope() {
		this.previewWidth = this.previewElement.nativeElement.offsetWidth

		const canvas = this.envelopeCanvas.nativeElement
		let dpi = window.devicePixelRatio || 1	// for HiDPI displays
        let ctx = canvas.getContext('2d')

        var width = this.previewWidth
        var height = this.previewHeight

        canvas.style.width = "100%"
        canvas.style.height = height + "px"

        ctx.canvas.width = width * dpi
        ctx.canvas.height = height * dpi

		let data
		let color

		if (this.gpService.developmentPreviewGraphOption.value == "tension") {
			data = this.preview.tension
			color = "tension"
		}

		else if (this.gpService.developmentPreviewGraphOption.value == "lfc") {
			data = this.preview.lfc
			color = "low_frequency_cut"
		}

		else if (this.gpService.developmentPreviewGraphOption.value == "hfc") {
			data = this.preview.hfc
			color = "high_frequency_cut"
		}

		else if (this.gpService.developmentPreviewGraphOption.value == "dynamics") {
			data = this.preview.dynamics
			color = "dynamic"
		}
        
        ctx.strokeStyle = this.designService.getCSSProperty(color + "-primary-color")
		ctx.fillStyle = this.designService.getCSSProperty(color + "-primary-color")
        ctx.lineWidth = 1.5

        ctx.scale(dpi, dpi)

        ctx.canvas.style.width = ctx.canvas.width
        ctx.canvas.style.height = ctx.canvas.height

        ctx.beginPath()

        for (var v = 0; v < data.length; v++) {
			const ratio = Time.divideTwoFractions(data[v][0], this.preview.duration)
            ctx.lineTo(ratio * width, (1 - data[v][1]) * height)
        }

		ctx.lineTo(canvas.width, canvas.height)
		ctx.lineTo(0, canvas.height)

        ctx.stroke()
		ctx.fill("evenodd")
        
        ctx.closePath()

        ctx.globalCompositeOperation = "destination-over" // draw behind
        ctx.globalCompositeOperation = "source-over" // normal behavior

		this.ref.detectChanges()
	}

	getSubsequences() {
		let subsequences = []

		for (let segment of this.preview.segments) {
			if (!subsequences.includes(segment.subsequence)) {
				subsequences.push(segment.subsequence)
			}
		}

		return subsequences
	} 

	formatTitle(value) {
		return Misc.capitalizeFirstLetter(Misc.replaceAll(value, "_", " "))
	}

	getSegmentWidth(segment, index) {
		let value = this.computeWidthForDuration(segment.duration)
		
		if (index > 0) {
			value -= 5
		}

		return value - 2 // -2 is to subtract the 1px border
	}
	
	computeWidthForDuration(duration) {
		return Time.divideTwoFractions(duration, this.preview.duration) * this.previewWidth
	}

	getLayerBlockWidth(block) {
		let duration = Time.addTwoFractions(block.end, block.start, true)
		let value = Time.divideTwoFractions(duration, this.preview.duration) * 100

		return 'calc(' + value + '% - ' + this.border + ')'
	}

	getColorForLayer(layer) {
		return this.designService.getColorForLayer(layer)
	}
}

