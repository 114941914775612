import { Query } from "@datorama/akita"
import { EditorState, EditorStore } from "./editor.store"
import { ScoreType } from "@common-lib/types/score"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"

export class EditorQuery extends Query<EditorState> {
    public allState$ = this.select()
    public compositionLoading$ = this.select("compositionLoading")
    public scoreLoading$ = this.select("scoreLoading")
    public loading$ = this.select(["compositionLoading", "scoreLoading"])

    constructor(protected store: EditorStore) {
        super(store)
    }

    public get engine(): ScoreRenderingEngine {
        return this.getValue().engine
    }

    public get loadedCompositionID(): string | undefined {
        return this.getValue().compositionLoading.compositionID
    }

    public get contentType(): ScoreType {
        return this.getValue().compositionLoading.contentType
    }

    public get compositionLoading() {
        return this.getValue().compositionLoading
    }

    public get scoreLoading() {
        return this.getValue().scoreLoading
    }
}
