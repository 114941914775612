import { Injectable } from "@angular/core"
import { KeySignature } from "@common-lib/interfaces/score/keySignature"
import {
    TemplateChord,
    TemplateKeySignature,
} from "@common-lib/interfaces/score/templateScore"
import { ChordManipulation } from "@common-lib/modules/chord-manipulation.module"
import { CompositionWorkflowModule } from "@common-lib/modules/composition-workflow.module"
import { TimeSignature } from "@common-lib/types/score"
import {
    Chord,
    EditChordData,
} from "../../../../common-lib/client-only/score-rendering-engine"
import { SRActionTypes } from "../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { ParentClass } from "../parent"

import { cloneDeep } from "lodash"
import ScoreRenderingEngine from "../../../../common-lib/client-only/score-rendering-engine/engine"
import {
    CHORD_TYPE_MAPPING,
    SHORT_SCALES_TO_SCALES_MAP,
    convertNumeralToChord,
    formatChordSuffixForDisplay,
    getChordDegreesForQuality,
    getChordQuality,
    getChordSymbolsOptions,
    getSuffixFromChord,
    transformChordDegree,
} from "@common-lib/utils/composition-workflow.util"
import { BehaviorSubject } from "rxjs"
import { DropdownItemType, DropdownSelection } from "../types/dropdownItemType"
import { CWKeyMode } from "@common-lib/interfaces/composition-workflow.interface"
import { Time } from "@common-lib/modules/time"
import { KeySignatureModule } from "@common-lib/modules/keysignature.module"

@Injectable()
export class ChordsEditingService {
    public editChordSettings$ = new BehaviorSubject<EditChordData | undefined>(
        undefined
    )

    public setEditorType(editorType: "editor" | "composition-workflow") {
        this.engine.srEmitter$.next({
            type: SRActionTypes.setEditorType,
            data: {
                editorType,
            },
            options: {
                isUndoable: false,
            },
        })
    }

    private engine: ScoreRenderingEngine

    constructor() {}

    public setEngine(engine: ScoreRenderingEngine) {
        this.engine = engine
    }

    private get timeSignature() {
        return this.engine.score.timeSignatures[0][1]
    }

    private get keySignatures(): TemplateKeySignature[] {
        const result = []

        for (const ks of this.engine.score.keySignatures) {
            const pitchClass = ks[1].split(" ")[0]
            let mode = ks[1].split(" ")[1]
            if (mode.length < 5) {
                mode = SHORT_SCALES_TO_SCALES_MAP[mode]
            }

            ks[1] = pitchClass + " " + mode

            result.push(ks)
        }

        return result
    }

    /**
     * This function returns the list of chord prefixes. Here are some examples of chord prefixes:
     * - For roman numerals: I, bII, etc.
     * - For chord symbols: C, Db, etc.
     * @param data
     * @returns
     */

    public getChordPrefixes(data: EditChordData): DropdownItemType<string>[] {
        const ks = this.getKeySignatureForChord(data)

        const chordDegreeOptions = getChordDegreesForQuality(
            "min",
            ks.mode
        ).concat(getChordDegreesForQuality("maj", ks.mode))

        const chordSymbolOptions = getChordSymbolsOptions(
            ks.pitchClass,
            ks.mode
        )

        return this.engine.renderChordsType === "roman-numeral"
            ? chordDegreeOptions
            : chordSymbolOptions
    }

    private getKeySignatureForChord(data: EditChordData) {
        const keySignatures =
            KeySignatureModule.fromKeySignaturesToIntermediateRepresentation(
                this.engine.score.scoreLength,
                this.engine.score.keySignatures
            )

        const ks = ChordManipulation.getKeySignaturesForChord(
            {
                start: data.start,
                end: Time.addTwoFractions(data.start, data.duration),
                data: data.numeral,
            },
            keySignatures
        )

        const pitchClass = ks[0].ks.split(" ")[0]
        let mode = ks[0].ks.split(" ")[1] as CWKeyMode

        if (mode.length < 5) {
            mode = SHORT_SCALES_TO_SCALES_MAP[mode]
        }

        return {
            pitchClass,
            mode,
        }
    }

    public getSelectedChordPrefix(
        data: EditChordData
    ): DropdownItemType<string> {
        const ks = this.getKeySignatureForChord(data)

        const chordDegreeOptions = getChordDegreesForQuality(
            "maj",
            ks.mode
        ).concat(getChordDegreesForQuality("min", ks.mode))

        const selectedRomanNumeral =
            chordDegreeOptions.find(
                option => option.value === data.numeral.split(" ")[0]
            ) || chordDegreeOptions[0]

        const chord = convertNumeralToChord(
            ks.pitchClass,
            ks.mode,
            selectedRomanNumeral.name
        ).chord

        const selectedChordSymbol = {
            name: chord,
            value: selectedRomanNumeral.value,
        }

        return this.engine.renderChordsType === "roman-numeral"
            ? selectedRomanNumeral
            : selectedChordSymbol
    }

    public setChordOption(
        data: EditChordData,
        item: DropdownSelection<string>
    ) {
        const degree = item.new.value
        let suffix = data.numeral.split(" ")[1]
        const quality = getChordQuality(degree)

        if (CHORD_TYPE_MAPPING[quality][suffix] === undefined) {
            suffix = undefined
        }

        const newData: EditChordData = cloneDeep(data)
        newData.numeral =
            suffix !== undefined && suffix.length
                ? degree + " " + suffix
                : degree

        this.editChordSettings$.next(newData)

        this.changeRomanNumeral(newData)
    }

    /**
     * This function returns all possible chord suffixes, for all qualities
     * @param data
     * @returns
     */

    public getChordSuffixes(data: EditChordData): DropdownItemType<{
        quality: "maj" | "min"
        value: string
    }>[] {
        const results: {
            name: string
            value: { quality: "maj" | "min"; value: string; suffix: string }
        }[] = []

        const numeral = data.numeral.split(" ")[0]
        const q = getChordQuality(numeral)
        const qualities = q === "min" ? ["min", "maj"] : ["maj", "min"]

        for (const quality of qualities) {
            for (const value in CHORD_TYPE_MAPPING[quality]) {
                const data = CHORD_TYPE_MAPPING[quality][value]
                const name =
                    this.engine.renderChordsType === "roman-numeral"
                        ? value
                        : data.suffix === ""
                        ? "No extension"
                        : formatChordSuffixForDisplay(data.suffix)

                const hasOption = results.find(option => option.name === name)

                if (hasOption) {
                    continue
                }

                results.push({
                    name,
                    value: {
                        quality: quality as "maj" | "min",
                        value,
                        suffix: data.suffix,
                    },
                })
            }
        }

        results.sort((a, b) => {
            if (a.name === "No extension") {
                return -1
            }

            if (b.name === "No extension") {
                return 1
            }

            return a.name > b.name ? 1 : -1
        })

        return results
    }

    public getSelectedChordSuffix(data: EditChordData): DropdownItemType<{
        quality: "maj" | "min"
        value: string
        suffix: string
    }> {
        const suffix: DropdownItemType<{
            quality: "maj" | "min"
            value: string
            suffix: string
        }> = getSuffixFromChord(
            data.numeral,
            this.engine.renderChordsType !== "roman-numeral"
        )
        suffix.name = formatChordSuffixForDisplay(suffix.name)

        return suffix
    }

    public setChordSuffix(
        data: EditChordData,
        item: DropdownSelection<{
            quality: "maj" | "min"
            value: string
            suffix: string
        }>
    ) {
        const suffix = item.new.value.value

        const degree = this.getSelectedChordPrefix(data).value.split(" ")[0]

        const newData: EditChordData = cloneDeep(data)
        newData.numeral = transformChordDegree(degree, item.new.value.quality)

        if (suffix !== "") {
            newData.numeral += " " + suffix
        }

        this.editChordSettings$.next(newData)

        this.changeRomanNumeral(newData)
    }

    public async changeRomanNumeral(data: EditChordData) {
        const romanNumerals = ChordManipulation.changeRomanNumeral({
            chords: cloneDeep(this.engine.score.romanNumerals),
            onset: data.start,
            newRomanNumeral: data.numeral,
        })

        this.updateChords({
            romanNumerals: romanNumerals,
            keySignatures: this.keySignatures,
            timeSignature: this.timeSignature,
            updateRomanNumerals: true,
            progressionWasEdited: true,
            isUndoable: true,
        })
    }

    public editChord(event: MouseEvent, chord: Chord) {
        const coordinates = {
            x: event.x,
            y: event.y,
            xAbs: event.x,
            yAbs: event.y,
            shiftKey: event.shiftKey,
        }

        const data: EditChordData = {
            ...chord,
            page: "change",
            coordinates,
        }

        this.toggleEditChordSettingsMenu(data)
    }

    public async deleteChord({ index }: { index: number }) {
        const romanNumerals = ChordManipulation.deleteChordAtIndex(
            index,
            cloneDeep(this.engine.score.romanNumerals),
            this.timeSignature,
            this.engine.queries.scoreRendering.editorType
        )

        await this.updateChords({
            romanNumerals: romanNumerals,
            keySignatures: this.keySignatures,
            timeSignature: this.timeSignature,
            updateRomanNumerals: true,
            progressionWasEdited: true,
            isUndoable: true,
        })
    }

    public async insertChord({ index }: { index: number }) {
        const res = ChordManipulation.insertNewChordSymbol({
            chords: cloneDeep(this.engine.score.romanNumerals),
            timeSignature: this.timeSignature,
            index: index + 1,
        })

        if (!res.success) {
            console.error("Insert chord failed")
            return
        }

        const romanNumerals = res.chords

        await this.updateChords({
            romanNumerals: romanNumerals,
            keySignatures: this.keySignatures,
            timeSignature: this.timeSignature,
            updateRomanNumerals: true,
            progressionWasEdited: true,
            isUndoable: true,
        })
    }

    /**
     * Updates both the score chords as well as the notes that live in the Chords layer
     * If updateRomanNumerals is true, it will also update the roman numerals in the CW store
     * @param chordProgression      need to be provided as roman numerals, e.g. [["1", "V"], ["1/2", "III"], etc.]
     * @param keySignature
     * @param timeSignature
     * @param updateRomanNumerals   defines if the romanNumerals in the CW store is updated (default is true)
     * @param progressionWasEdited  defines the status of the progressionWasEdited flag in the CW store (default is false)
     *                              this will be set to true only when a user made an edit, and should be re-set to false
     *                              when the chord progression is updated using an ME call. That allows us to prevent user
     *                              edits from being overridden without confirmation
     */

    private async updateChords({
        romanNumerals,
        keySignatures,
        timeSignature,
        updateRomanNumerals = true,
        progressionWasEdited = false,
        isUndoable = true,
    }: {
        romanNumerals: TemplateChord[]
        keySignatures: TemplateKeySignature[]
        timeSignature: TimeSignature
        updateRomanNumerals?: boolean
        progressionWasEdited?: boolean
        isUndoable?: boolean
    }) {
        if (updateRomanNumerals) {
            this.engine.srEmitter$.next({
                type: SRActionTypes.setChordsAsEdited,
                data: {
                    value: progressionWasEdited,
                },
                options: {
                    isUndoable,
                },
            })
        }

        const result = CompositionWorkflowModule.initNotes({
            keySignatures,
            chordProgression: romanNumerals,
            timeSignature: timeSignature,
            lowestNote: 60,
            tieNotes: true,
        })

        this.engine.srEmitter$.next({
            type: SRActionTypes.updateChords,
            data: {
                chords: result.chords,
                notes: result.notes,
                romanNumerals,
            },
            options: {
                isUndoable: false,
            },
        })

        // make sure that the update to the Chords layer notes took place
        await ParentClass.waitUntilTrueForObservable(
            this.engine.scoreUpdate$,
            scoreUpdate => {
                return (
                    scoreUpdate.includes("Note") || scoreUpdate.includes("All")
                )
            }
        )
    }

    private toggleEditChordSettingsMenu(data: EditChordData | undefined) {
        if (!data) {
            this.editChordSettings$.next(undefined)
            return
        }

        if (this.editChordSettings$.getValue() !== undefined) {
            this.editChordSettings$.next(undefined)
        }

        data.coordinates.xAbs += 20

        this.editChordSettings$.next(data)
    }
}
