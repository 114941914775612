import { Injectable } from "@angular/core"
import Score from "@common-lib/classes/score/score"
import { ParentClass } from "../../parent"
import ScoreRenderingEngine from "../../../../../common-lib/client-only/score-rendering-engine/engine"
import { EditorHttpService } from "./editor.http"
import { EditorQuery } from "./state/editor.query"
import { EditorStore } from "./state/editor.store"
import {
    BehaviorSubject,
    combineLatest,
    filter,
    map,
    Observable,
    pairwise,
} from "rxjs"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { Composition } from "@common-lib/classes/general/composition"
import {
    AnnotationFields,
    EditorLoadingStatus,
    MixingOption,
    ScoreType,
} from "@common-lib/types/score"
import PercussionLayer from "@common-lib/classes/score/percussionlayer"
import Layer from "@common-lib/classes/score/layer"
import { environment } from "@environments/environment"
import { ScoreDecoding } from "@common-lib/modules/score-transformers/scoreDecoding"
import { isEqual } from "lodash"
import { TokenService } from "@services/token.service"
import { UserService } from "@services/user.service"
import {
    DEFAULT_PERCUSSION_INSTRUMENT,
    DEFAULT_PITCHED_INSTRUMENT,
} from "@common-lib/constants/constants"
import { SRActionTypes } from "../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import SearchItem from "@common-lib/classes/searchitem"
import { Helpers } from "../../modules/helpers.module"
import {
    CompositionDoneCreating,
    CompositionUpdateLoadingStatus,
} from "@common-lib/interfaces/api/sockets"
import SectionOperation from "@common-lib/classes/score/operation"
import { AnalyticsService } from "@services/analytics.service"
import { Misc } from "@common-lib/modules/misc"
import Section from "@common-lib/classes/score/section"
import { featureFlags } from "@common-lib/utils/feature-flags"

export interface EditorLoading {
    progress: number
    status: EditorLoadingStatus
    compositionID: string | undefined
}

@Injectable()
export class EditorService extends ParentClass {
    static readonly LAYER_PREVIEW_GUTTER_PX = 5

    public wasDestroyed: boolean = false

    private readonly store: EditorStore = new EditorStore()
    public readonly query: EditorQuery = new EditorQuery(this.store)

    public loading$ = new BehaviorSubject({
        progress: 0,
        status: <EditorLoadingStatus>"none",
        compositionID: undefined,
    })

    public engine$ = this.query.select("engine")

    public scrollYBy: BehaviorSubject<number> = new BehaviorSubject(0)

    constructor(
        public editorHttp: EditorHttpService,
        private instruments: InstrumentsService,
        private token: TokenService,
        private user: UserService,
        private analytics: AnalyticsService
    ) {
        super()

        this.subscribe(this.editorHttp.api.socket, socket => {
            if (!socket) {
                return
            }

            socket.on(
                "updateLoadingStatus",
                ((data: CompositionUpdateLoadingStatus) => {
                    const contentType = <ScoreType>this.query.contentType
                    if (data.compositionID !== this.query.loadedCompositionID) {
                        return
                    } else if (data.failed) {
                        this.setLoadingToFailed(
                            data.compositionID,
                            "Your composition has failed to render"
                        )
                    } else {
                        this.store.update(state => ({
                            ...state,
                            compositionLoading: {
                                finished:
                                    this.query.compositionLoading.finished,
                                failed: this.query.compositionLoading.failed,
                                progress: data.loadingStatus,
                                compositionID: data.compositionID,
                                contentType: contentType
                                    ? contentType
                                    : "composition",
                            },
                        }))
                    }
                }).bind(this)
            )

            socket.on(
                "doneCreating",
                ((data: CompositionDoneCreating) => {
                    if (data.compositionID !== this.query.loadedCompositionID) {
                        return
                    }

                    const contentType = <ScoreType>this.query.contentType

                    this.store.update(state => ({
                        ...state,
                        compositionLoading: {
                            finished: true,
                            failed: false,
                            progress: 100,
                            compositionID: data.compositionID,
                            contentType: contentType
                                ? contentType
                                : "composition",
                        },
                    }))
                }).bind(this)
            )
        })

        this.subscribe(this.loading$, async value => {
            if (value.status === "scoreLoading") {
                return this.loadScore(false)
            }
        })

        this.subscribe(
            this.instruments.query.select("newPatchSelected"),
            value => {
                if (value === undefined || this.engine === undefined) {
                    return
                }

                this.engine.srEmitter$.next({
                    type: SRActionTypes.replaceTrackBus,
                    data: {
                        patchID: (value.newPatch as SearchItem).item.patchID,
                        instruments: this.instruments.instruments,
                        layer: this.engine.toggledLayer,
                        tb: value.trackBus,
                    },
                    options: {
                        isUndoable: true,
                    },
                })
            }
        )

        const loadingObservable: Observable<EditorLoading> = combineLatest(
            [this.query.compositionLoading$, this.query.scoreLoading$],
            (compositionLoading, scoreLoading) => {
                let status: EditorLoadingStatus = "compositionLoading"

                if (compositionLoading.compositionID === undefined) {
                    status = "none"
                } else if (compositionLoading.failed) {
                    status = "failed"
                } else if (compositionLoading.finished) {
                    status = scoreLoading.finished ? "loaded" : "scoreLoading"
                }

                return {
                    progress: compositionLoading.progress,
                    status: status,
                    compositionID: compositionLoading.compositionID,
                }
            }
        ).pipe(
            pairwise(),
            filter(([previous, current]) => {
                return previous.status === "none" || !isEqual(previous, current)
            }),
            map(([previous, current]) => {
                return current
            })
        )

        loadingObservable.subscribe(this.loading$)
    }

    public get engine(): ScoreRenderingEngine {
        return this.query.engine
    }

    public get compositionID(): string | undefined {
        return this.query.loadedCompositionID
    }

    public get layers$(): Observable<(Layer | PercussionLayer)[] | undefined> {
        return this.query.engine.score$.pipe(
            map((score: Score | undefined) => {
                if (score === undefined) {
                    return undefined
                }

                return Object.keys(score.layers).map(key => {
                    return score.layers[key]
                })
            })
        )
    }

    public getScoreLoadingErrorMessage(): string {
        return (
            this.query.scoreLoading.error ||
            "Your composition has failed to render"
        )
    }

    private trackEngineMetrics() {
        this.subscribe(
            this.engine.queries.scoreRendering.metricToAdd$,
            metric => {
                if (metric === undefined) {
                    return
                }

                return this.analytics.addActivity(metric.type, metric.meta)
            }
        )
    }

    /**
     * This method is responsible for updating the loading status of a composition, and trigger the
     * procedure to load the corresponding score whenever the composition reaches the right state
     * @param composition
     * @returns void
     */
    public loadComposition(
        composition: Composition | undefined,
        origin?: string
    ): void {
        const scoreLoading = {
            started: false,
            finished: false,
            compositionID: undefined,
            error: "",
        }

        const contentType =
            composition !== undefined && composition.contentType !== undefined
                ? <ScoreType>composition.contentType
                : "composition"

        let compositionLoading = {
            finished: false,
            failed: false,
            progress: 0,
            compositionID: undefined,
            contentType: contentType,
        }

        if (composition) {
            compositionLoading = {
                finished: composition.isFinished,
                failed: composition.failed,
                progress: composition["loadingStatus"],
                compositionID: composition._id,
                contentType: contentType,
            }
        }

        this.store.update(state => ({
            ...state,
            compositionLoading,
            scoreLoading,
        }))
    }

    public async harmonicAnalysis(section: Section) {
        return new Promise(resolve => {
            this.engine.srEmitter$.next({
                type: SRActionTypes.harmonyAnalysis,
                data: {
                    selectedSectionIndex: section.index,
                    instruments: this.instruments.instruments,
                    samplesMap: this.instruments.drumSamples,
                    http: this.editorHttp.harmonicAnalysis.bind(
                        this.editorHttp
                    ),
                    resolve,
                },
                options: {
                    isUndoable: true,
                },
            })
        })
    }

    public async saveScoreChanges(
        option: MixingOption
    ): Promise<{ success: boolean; compositionID: string; message?: string }> {
        if (this.query.engine?.score === undefined) {
            return {
                success: false,
                compositionID: this.query.loadedCompositionID,
                message: "Score is undefined",
            }
        }

        const result = ScoreDecoding.toTemplateScore({
            score: this.query.engine.score,
            realTimeSampler: false,
        })

        if (result.isExportingSilence) {
            return {
                success: false,
                compositionID: this.query.loadedCompositionID,
                message:
                    "Cannot save a composition that does not contain any audio",
            }
        }

        if (!result.addedTrackWithNotes) {
            return {
                success: false,
                compositionID: this.query.loadedCompositionID,
                message:
                    "Cannot export an empty composition. Please add notes before saving.",
            }
        }

        try {
            const isTrainingsetComposition =
                featureFlags.dataEditingTools &&
                !environment.production &&
                this.query.contentType === "training"

            if (isTrainingsetComposition) {
                await this.editorHttp.saveTrainingsetTemplateScore(
                    result.templateScore
                )

                return {
                    success: true,
                    compositionID: this.query.loadedCompositionID,
                }
            }

            await this.editorHttp.saveTemplateScore(
                result.templateScore,
                option,
                true,
                this.getMusicEngineOperations()
            )

            return {
                success: true,
                compositionID: this.query.loadedCompositionID,
            }
        } catch (e) {
            return {
                success: false,
                message: e,
                compositionID: this.query.loadedCompositionID,
            }
        }
    }

    private getMusicEngineOperations(): SectionOperation[] {
        const operations = []

        for (var section of this.query.engine.score.sections) {
            if (section.operation == null) {
                continue
            }

            if (
                section.operation.type.includes("insert") &&
                (section.operation.args.insert_type == "blank" ||
                    section.operation.args.insert_type == "new")
            ) {
                delete section.operation.args.source_idx
            } else {
                delete section.operation.args.number_of_bars
            }

            operations.push(section.operation)
        }

        return operations
    }

    public toggleLayer(layer: string) {
        this.query.engine.toggleLayer(layer)
    }

    private setLoadingToFailed(compositionID: string, error?: string) {
        this.store.update(state => ({
            ...state,
            scoreLoading: {
                started: false,
                finished: false,
                compositionID: undefined,
                error,
            },

            compositionLoading: {
                finished: true,
                failed: true,
                progress: 0,
                compositionID: compositionID,
                contentType: "composition",
            },
        }))
    }

    /**
     * TOOD: use this to load the training data score
     */

    private async loadScore(force: boolean) {
        try {
            if (!this.query.loadedCompositionID) {
                throw "Undefined composition"
            }

            const scoreLoading = this.query.scoreLoading

            if (
                scoreLoading.started &&
                scoreLoading.compositionID === this.query.loadedCompositionID &&
                !force
            ) {
                return
            }

            this.store.update(state => ({
                ...state,
                scoreLoading: {
                    started: true,
                    finished: false,
                    compositionID: this.query.loadedCompositionID,
                },
            }))

            const loadedCompositionID = this.query.loadedCompositionID

            await Misc.wait(1)

            if (this.wasDestroyed) {
                return
            }

            /**
             * Get the template score from the server if type of the editor is composition,
             * otherwise get the template score from the training dataset bucket if the type of the editor is training.
             * Only enable this option behind a feature flag and a check for environment !== production.
             */
            const result = await this.editorHttp.getTemplateScore(
                this.query.loadedCompositionID,
                this.query.contentType
            )

            if (result.template === undefined) {
                throw result.error
            }

            if (this.query.loadedCompositionID !== loadedCompositionID) {
                return
            }

            const score: Score = new Score({
                templateScore: result.template,
                instrumentReferences: this.instruments.instruments,
                samplesMap: this.instruments.drumSamples,
                lastSectionDuration: result.lastSectionDuration,
            })

            if (this.wasDestroyed) {
                return
            }

            this.store.update(state => ({
                ...state,
                engine: ScoreRenderingEngine.initScore(
                    score,
                    environment.production ? "production" : "staging",
                    {
                        userID: this.token.userID,
                        settings: this.user.settings,
                        instruments: this.instruments.instruments,
                        autoExtendScore: true,
                        maxBarLength: result.maxBarLength,
                        resizeFactor: {
                            min: 0,
                            max: 150,
                        },
                        levelsMeasurement: "trackbus",
                        sustainPedalFromChords: false,
                    }
                ),
                isCompatibleWithRegeneration:
                    result.isCompatibleWithRegeneration,
                scoreLoading: {
                    started: true,
                    finished: true,
                    compositionID: this.query.loadedCompositionID,
                },
            }))

            this.trackEngineMetrics()
        } catch (e) {
            console.error(e)
            this.setLoadingToFailed(this.query.loadedCompositionID, e)
        }
    }

    public ngOnDestroy(): void {
        if (this.engine != null) {
            this.engine.resetStores()
        }

        super.ngOnDestroy()
        this.wasDestroyed = true
    }
}
