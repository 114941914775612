import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from "@angular/core"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import { ParentClass } from "../../../parent"

@Component({
    selector: "resizer",
    templateUrl: "resizer.component.html",
    styleUrls: ["resizer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResizerComponent extends ParentClass implements OnInit {
    @Input() engine: ScoreRenderingEngine
    @Input() width: string = "109px"

    constructor(private ref: ChangeDetectorRef) {
        super()
    }

    public ngOnInit(): void {
        this.subscribe(this.engine.resizeFactor$, () => {
            this.ref.detectChanges()
        })
    }
}
