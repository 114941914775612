import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from "@angular/core"
import {
    InstrumentSelectionModalParameters,
    ModalService,
} from "@services/modal.service"
import { ParentClass } from "../../../../parent"
import TrackBus from "@common-lib/classes/score/trackbus"
import Layer from "@common-lib/classes/score/layer"
import {
    CheckboxModifier,
    DialModifier,
    IncrementModifier,
    MenuModifiers,
} from "../../../reusable/menu-modifiers/menu-modifiers.component"
import Score from "@common-lib/classes/score/score"
import { ScoreUpdateType } from "../../../../../../../common-lib/client-only/score-rendering-engine"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { MenuOption } from "../../../reusable/menu-options/menu-options.component"
import { SRActionTypes } from "../../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { ScoreManipulation } from "@common-lib/modules/scoremanipulation"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import ScoreRenderingEngine from "../../../../../../../common-lib/client-only/score-rendering-engine/engine"

@Component({
    selector: "trackbus-panel",
    templateUrl: "trackbus-panel.component.html",
    styleUrls: ["trackbus-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackBusPanelComponent
    extends ParentClass
    implements AfterViewInit
{
    @Input() engine: ScoreRenderingEngine

    trackBusSettings: undefined | MenuModifiers
    settingsMenu: boolean = false

    @Input() trackBus: TrackBus
    @Input() layer: Layer

    constructor(
        public modal: ModalService,
        public instruments: InstrumentsService,
        private ref: ChangeDetectorRef
    ) {
        super()
    }

    ngAfterViewInit() {
        this.subscribe(this.engine.scoreUpdate$, (update: ScoreUpdateType) => {
            if (update.includes("All") || update.includes("TrackBusMetadata")) {
                this.ref.detectChanges()
            }
        })
    }

    public openInstrumentSelectionModal() {
        const parameters: InstrumentSelectionModalParameters = {
            layer: this.layer,
            selectedTrackBus: this.trackBus,
            allowRecommendations: false,
            pack: undefined,
            selectNewInstrumentPatch: ((patch: InstrumentPatch) => {
                this.engine.srEmitter$.next({
                    type: SRActionTypes.replaceTrackBus,
                    data: {
                        patchID: patch.patchID,
                        instruments: this.instruments.instruments,
                        layer: this.engine.toggledLayer,
                        tb: this.trackBus,
                    },
                    options: {
                        isUndoable: true,
                    },
                })

                return false
            }).bind(this),

            onClose: () => {},
        }

        this.modal.modals.instrumentSelection.next(parameters)
    }

    public setPanning(event) {
        this.engine.setPan(event, this.trackBus)
    }

    private setOctave(data) {
        this.engine.setOctave(data, this.trackBus)
    }

    private setDynamic(data) {
        this.engine.setDynamic(data, this.trackBus)
    }

    private setAutoPedal(data) {
        this.engine.setAutoPedal(data, this.trackBus)
    }

    private setBreathingGain(data) {
        this.engine.setBreathingGain(data, this.trackBus)
    }

    public toggleSettingsMenu(
        event: MouseEvent | undefined,
        clickedOutside = false
    ) {
        if (clickedOutside || this.trackBusSettings) {
            this.trackBusSettings = undefined
            return
        }

        const octave: IncrementModifier = {
            text: "Octave",
            type: "increment",
            data: this.trackBus.octave,
            min: -Score.MAX_OCTAVE,
            max: Score.MAX_OCTAVE,
            dataSetter: this.setOctave.bind(this),
        }

        const dynamics: IncrementModifier = {
            text: "Dynamics",
            type: "increment",
            data: this.trackBus.dynamicOffset,
            min: -Score.MAX_DYNAMIC_OFFSET,
            max: Score.MAX_DYNAMIC_OFFSET,
            dataSetter: this.setDynamic.bind(this),
        }

        const sustainPedal: CheckboxModifier = {
            text: "Sustain Pedal",
            type: "checkbox",
            data: this.trackBus.autoPedal,
            dataSetter: this.setAutoPedal.bind(this),
        }

        const breathingGain: DialModifier = {
            text: "Breathing gain",
            type: "dial",
            data: this.trackBus.breathingGain,
            dataSetter: this.setBreathingGain.bind(this),
        }

        const options: (
            | DialModifier
            | IncrementModifier
            | CheckboxModifier
            | MenuOption<any>
        )[] = []

        if (this.layer.type === "pitched") {
            options.push(octave)
        }

        if (this.trackBus.reference.dynamic_offset) {
            options.push(dynamics)
        }

        if (
            ScoreManipulation.isKeyboard(
                this.trackBus.name,
                this.trackBus.reference
            )
        ) {
            options.push(sustainPedal)
        }

        if (this.trackBus.reference.breathing_gain != null) {
            options.push(breathingGain)
        }

        const deleteButton = {
            icon: "assets/img/menu/delete.svg",
            text: "Delete",
            buttonClass: "delete" as "delete",
            data: this.trackBus,
            onClick: this.deleteTrackBus.bind(this),
            loading: false,
        }

        const duplicateButton = {
            icon: "assets/img/menu/duplicate.svg",
            text: "Duplicate",
            data: this.trackBus,
            onClick: this.duplicateTrackBus.bind(this),
            loading: false,
        }

        this.trackBusSettings = {
            options: options,
            coordinates: {
                x: event.clientX,
                y: event.clientY,
            },
            buttons: [duplicateButton, deleteButton],
        }

        this.ref.detectChanges()
    }

    public solo(trackBusses: TrackBus[]) {
        const tbs =
            this.engine.queries.scoreRendering.selectedTrackBusses.length > 0
                ? this.engine.queries.scoreRendering.selectedTrackBusses
                : trackBusses

        return this.engine.toggleTrackBussesMute(
            this.engine.toggledLayer,
            tbs,
            "solo"
        )
    }

    public mute(trackBusses: TrackBus[]) {
        const tbs =
            this.engine.queries.scoreRendering.selectedTrackBusses.length > 0
                ? this.engine.queries.scoreRendering.selectedTrackBusses
                : trackBusses

        return this.engine.toggleTrackBussesMute(
            this.engine.toggledLayer,
            tbs,
            "mute"
        )
    }

    private duplicateTrackBus() {
        this.engine.srEmitter$.next({
            type: SRActionTypes.duplicateTrackBusses,
            data: {
                layer: this.engine.toggledLayer,
                toDuplicate: [this.trackBus],
            },
            options: {
                isUndoable: true,
            },
        })

        this.trackBusSettings = undefined
        this.ref.detectChanges()
    }

    public deleteTrackBus() {
        this.engine.deleteTrackBusses(this.engine.toggledLayer, [this.trackBus])

        this.trackBusSettings = undefined

        this.ref.detectChanges()
    }
}
