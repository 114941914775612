import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from "@angular/core"
import { CreateService } from "@services/create.service"
import { ListItem } from "../../../../components/reusable/list-items/list-items.component"
import { PlayerStatus } from "../../../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { PlayerService } from "@services/audio/player/player.service"
import { Misc } from "@common-lib/modules/misc"
import { BehaviorSubject } from "rxjs"
import { MenuOption } from "../../../reusable/menu-options/menu-options.component"
import { ApiService } from "@services/api.service"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { DeviceDetectorService } from "ngx-device-detector"
import { TutorialService } from "@services/tutorial.service"

@Component({
    selector: "gp-list",
    templateUrl: "gp-list.component.html",
    styleUrls: ["./gp-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GPListComponent {
    @Input() gpType: "forked" | "regular" = "regular"
    @Input() interactionType: "select" | "create"
    @Input() selectGPForCW?: (gp) => void

    get currentPage() {
        return this.createService.gpStates.currentPage
    }

    get numberOfPages() {
        return this.createService.gpStates.numberOfPages
    }

    get pages() {
        return this.createService.gpStates.pages
    }

    get filters() {
        return this.createService.gpStates.filters
    }

    public scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    public gps: ListItem[] = []
    public libraryLoading = false

    constructor(
        private createService: CreateService,
        private ref: ChangeDetectorRef,
        private playerService: PlayerService,
        private apiService: ApiService,
        private gpService: GenerationProfileService,
        private device: DeviceDetectorService,
        private tutorialService: TutorialService
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.createService.gpStates.libraryLoading.subscribe(
            (value: boolean) => {
                this.libraryLoading = value
                this.gps = this.getGPListItems()
                this.ref.detectChanges()
                if (this.tutorialService.isTutorialInProgress$.value) {
                    setTimeout(() => {
                        this.tutorialService.tutorialNext$.next(true)
                    })
                }
            }
        )
    }

    showFirst() {
        return this.createService.getLowestDisplayedPage() > 0
    }

    showLast() {
        return (
            this.createService.getLowestDisplayedPage() + 5 < this.numberOfPages
        )
    }

    private isAdmin() {
        return this.apiService.isAdmin()
    }

    public selectNewPageBinding() {
        return ((value: number) => {
            return this.createService.setCurrentPage(value)
        }).bind(this)
    }

    private importToMyProfilesOption(gp) {
        return {
            data: gp,
            icon: "assets/img/added_to_library.svg",
            text: "Import to my styles",
            loading: false,
            onClick: (async (button: MenuOption<any>) => {
                button.loading = true

                await this.gpService.addGPToMyLibrary(gp, this.gpType)

                button.loading = false
            }).bind(this),
        }
    }

    private getForkedProfilesOption(gp) {
        return {
            data: gp,
            icon: "assets/img/forks_2.svg",
            text: "See forked styles",
            loading: false,
            onClick: (async () => {
                await this.createService.showForkedProfiles(gp)

                this.ref.detectChanges()
            }).bind(this),
        }
    }

    private getPublishGPOption(gp) {
        return {
            data: gp,
            icon: "assets/img/publish.svg",
            text: "Publish to production",
            loading: false,
            onClick: this.gpService.publishCategory.bind(this),
        }
    }

    private getPlayObject() {
        return {
            status: new BehaviorSubject<PlayerStatus>("paused"),
            onClick: (async (
                item: ListItem,
                statusBeforeClicking: PlayerStatus
            ) => {
                if (
                    item.data?.previewID == null &&
                    item.data?.preview == null
                ) {
                    return
                }

                const shouldPlay = statusBeforeClicking === "paused"

                for (const gpData of this.gps) {
                    if (
                        gpData === item ||
                        gpData.play.status.getValue() === "paused"
                    ) {
                        continue
                    }

                    gpData.play.status.next("paused")
                }

                await this.playerService.loadGPPreview(
                    item.data._id,
                    this.gpType === "regular" ? "category" : "gp"
                )

                if (!shouldPlay) {
                    return "paused"
                }

                return "playing"
            }).bind(this),
        }
    }

    public getGPListItems(): ListItem[] {
        if (this.interactionType === "create") {
            return this.getCreateGPListItems()
        }

        return this.getSelectGPListItems()
    }

    private getSelectGPListItems(): ListItem[] {
        return this.createService.gpStates.gps.map(gp => {
            const item: ListItem = {
                data: gp,

                name: gp.name,
                subtitle: gp.username,

                width: "100%",
                menu: undefined,

                primaryButton: {
                    text: "Select",
                    iconPath: undefined,
                    onClick: (data => {
                        return this.selectGPForCW(data)
                    }).bind(this),
                    alwaysVisible: true,
                    color: "blue",
                },

                play: this.getPlayObject(),
            }

            return item
        })
    }

    private getCreateGPListItems() {
        return this.createService.gpStates.gps.map(gp => {
            const buttons: MenuOption<any>[] = [
                this.importToMyProfilesOption(gp),
            ]

            let informationPill: {
                text: string
                iconPath?: string
                tooltipText?: string
            } = {
                text: this.getNumberOfCompositions(gp),
                iconPath: "assets/img/note.svg",
                tooltipText:
                    "Number of compositions generated with this generation profile category",
            }

            if (this.gpType === "regular") {
                buttons.push(this.getForkedProfilesOption(gp))

                if (this.isAdmin()) {
                    buttons.push(this.getPublishGPOption(gp))
                }
            } else {
                informationPill = {
                    text: gp.similarity + "% similar",
                }
            }

            const item: ListItem = {
                data: gp,

                name: gp.name,
                subtitle: gp.username,

                width: "100%",
                menu: {
                    options: buttons,
                    coordinates: { x: 0, y: 0 },
                },

                informationPill,

                primaryButton: {
                    text: "Create",
                    iconPath: "assets/img/add.svg",
                    onClick: (data => {
                        this.createTrack(data)
                    }).bind(this),
                    alwaysVisible: false,
                    color: "green",
                },

                play: this.getPlayObject(),
            }

            if (this.device.isMobile()) {
                item.primaryButton.alwaysVisible = true
                delete item.informationPill
                delete item.menu
            }

            return item
        })
    }

    public isMobile() {
        return this.device.isMobile()
    }

    private createTrack(data) {
        if (this.gpType == "regular") {
            this.gpService.openCompositionCreationModal({
                gp: null,
                redirectTo: "home",
                sourceGPFolder: null,
                sourceGPCategory: data._id,
            })
        } else {
            this.gpService.openCompositionCreationModal({
                gp: data,
                redirectTo: "home",
                sourceGPFolder: null,
                sourceGPCategory: null,
            })
        }
    }

    private getNumberOfCompositions(gp) {
        if (gp.compositions == null) {
            return 0
        }

        if (gp.compositions > 999) {
            return Misc.roundDecimal(gp.compositions / 1000, 1) + "k"
        }

        return gp.compositions
    }

    public async playPreview(data) {}
}
