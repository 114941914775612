<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchDefault>
        <span
            class="tooltiptext"
            *ngIf="
                showTooltip == true && description != null && description !== ''
            "
            >{{ description }}</span
        >
        <select
            #selectElement
            class="{{ styleClassesToUse }} {{ class }}"
            [class.custom-select]="changeSelectStyle"
            [class.show-arrow]="showArrow"
            [class.tooltip]="showTooltip"
            [disabled]="disabled"
            (change)="selectItem($event)">
            <ng-container *ngIf="!hasOptGroups">
                <option
                    *ngIf="placeholder != null"
                    [selected]="value == null"
                    disabled>
                    {{ placeholder }}
                </option>
                <option
                    *ngFor="let item of items"
                    [selected]="value != null && value.name === item.name"
                    [value]="item | json"
                    [disabled]="item.disabled">
                    {{ item.name }}
                </option>
            </ng-container>

            <ng-container *ngIf="hasOptGroups">
                <option
                    *ngIf="placeholder != null"
                    [selected]="value == null"
                    disabled>
                    {{ placeholder }}
                </option>
                <optgroup *ngFor="let optGroup of optGroups" [label]="optGroup">
                    <option
                        *ngFor="let item of itemsGroupedByOptGroup[optGroup]"
                        [selected]="value != null && value.name === item.name"
                        [value]="item | json"
                        [disabled]="item.disabled">
                        {{ item.name }}
                    </option>
                </optgroup>
            </ng-container>
        </select>
    </ng-container>
</ng-container>
