<div class="modal-title">Trainingset Labels</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div class="trainingset-labels" *ngIf="!loading">
    <div class="description">
        After making your changes to the score, here you define which parts of the 
        trainingset score are "clean" and ready to be used for training.
    </div>
    <ng-container *ngFor="let checkbox of checkboxes">
        <div class="checkbox" *ngIf="checkbox?.id !== 'music_supervisor_modified'" (click)="toggleCheckbox(checkbox?.id)">
            <input
                type="checkbox"
                [id]="checkbox?.id"
                [checked]="trustLabels[checkbox?.id]"/>
            <label [for]="checkbox?.id">{{ checkbox?.name }}</label><br>
        </div>
    </ng-container>
</div>

<div class="message" *ngIf="message">{{ message }}</div>

<a-button
    *ngIf="!loading"
    type="secondary"
    width="100%"
    (click)="clickedButton()"
    [label]="data.buttonTitle">
</a-button>

<div class="loading" *ngIf="loading">
    <div class="loader"></div>
</div>