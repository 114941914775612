import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import { ApiService } from "@services/api.service"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { ContextMenuItem } from "../../reusable/context-menus/local-context-menu/local-context-menu.component"
import { MenuOptions } from "../../reusable/menu-options/menu-options.component"
import GPInfluence from "../../../../../../common-lib/general/classes/generationprofiles/influences/gpinfluence"
import GPInfluenceLoading from "@common-lib/classes/generationprofiles/influences/gpinfluenceloading"
import { ModalService } from "@services/modal.service"

@Component({
    selector: "select-gp-influence",
    templateUrl: "./select-gp-influence.component.html",
    styleUrls: ["./select-gp-influence.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectGPInfluenceComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter()
    @Input() data

    templateMenuOptions: MenuOptions<GPInfluence>

    gpInfluences: GPInfluence[] = []

    isLoading = true

    constructor(
        protected ref: ChangeDetectorRef,
        private apiService: ApiService,
        private gpService: GenerationProfileService,
        private modalService: ModalService
    ) {
        this.getGPInfluences()
    }

    async ngOnInit() {}

    closeModal() {
        this.close.emit()
    }

    async selectGPInfluence(data) {
        let loading: GPInfluenceLoading =
            this.gpService.generationProfile.getGPInfluenceLoading(
                this.modalService.modals.selectGPInfluence.getValue().name
            )

        loading.influenceID = data._id
        loading.isDraggedOver = true
        loading.hasDroppedFile = true
        loading.progress = 0
        loading.error = ""

        this.modalService.modals.selectGPInfluence.next(null)
        this.gpService.refreshGenerationProfileUI.next(true)
    }

    async getGPInfluences() {
        try {
            let result = await this.apiService.authRequest(
                "/gpInfluence/getAllForUser",
                {},
                "primary",
                true
            )

            this.gpInfluences = result.gpInfluences.map(gpInf => {
                return GPInfluence.fromJSON(gpInf)
            })

            this.templateMenuOptions = {
                coordinates: { x: 0, y: 0 },
                options: [
                    {
                        data: undefined,
                        icon: "assets/img/menu/open.svg",
                        text: "Select",
                        loading: false,
                        onClick: this.selectGPInfluence.bind(this),
                    },

                    {
                        data: undefined,
                        icon: "assets/img/menu/rename.svg",
                        text: "Rename",
                        loading: false,
                        buttonClass: "rename",
                        onClick: undefined,
                        onConfirm: this.renameGPInfluence.bind(this),
                    },

                    {
                        data: undefined,
                        icon: "assets/img/menu/delete.svg",
                        text: "Delete",
                        loading: false,
                        buttonClass: "delete",
                        onClick: this.deleteGPInfluence.bind(this),
                    },
                ],
            }

            this.isLoading = false

            this.ref.detectChanges()
        } catch (e) {
            this.apiService.handleError(e)
        }
    }

    async deleteGPInfluence(gpInfluence:GPInfluence) {
        const res = await this.apiService.authRequest(
            "/gpInfluence/delete",
            { influenceID: gpInfluence._id },
            "primary",
            true
        )        

        const itemWasDeleted = res?.result === 1

        return itemWasDeleted
    }

    async renameGPInfluence(gpInfluence: GPInfluence) {
        try {
            await this.apiService.authRequest(
                "/gpInfluence/rename",
                { influenceID: gpInfluence._id, name: gpInfluence.name },
                "primary",
                true
            )
        } catch (e) {
            this.apiService.handleError(e)
        }
    }

    async uploadInfluence(event) {
        let loading = this.gpService.generationProfile.getGPInfluenceLoading(
            this.data.name
        )
        loading.isDraggedOver = true
        loading.hasDroppedFile = true
        loading.influenceID = ""

        this.gpService.cachedFiles[this.data.name] = event.target.files[0]

        this.gpService.refreshGenerationProfileUI.next(true)
        this.closeModal()
    }
}
