import Score from "../../../general/classes/score/score"
import { Time } from "../../../general/modules/time"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    PianorollGridCanvasContext,
    GridDimensionsDictionary,
    CanvasType,
} from "../types"
import ScoreCanvas from "./score-canvas"

export default class ChordsLabelCanvas extends ScoreCanvas {
    constructor(
        public context: PianorollGridCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        if (
            !scoreState.score ||
            !this.shouldRenderCanvas(scoreState.renderingType)
        ) {
            return
        }

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: grid,
            noteRes: grid[this.gridType].timestepRes,
            nbOfYValues: 0,
        })

        this.renderChordLabels(scoreState.score)
    }

    private renderChordLabels(score: Score) {
        const ctx = this.getContext("canvas")

        const timestepRange = {
            start: this.scrollToTimestep,
            end: this.scrollToTimestep + this.getTimestepRange(),
        }

        const scrollXOffset = ScoreCanvas.getScrollXOffset({
            scrollToTimestep: this.scrollToTimestep,
            grid: this.grid,
            pxPerTimestep: this.pxPerTimestep,
            noteRes: this.noteRes,
        })

        let startFraction = "0"

        score.chords.every((chord, index) => {
            const start = Time.fractionToTimesteps(this.noteRes, startFraction)

            startFraction = Time.addTwoFractions(chord[0], startFraction)

            const end =
                index + 1 >= score.chords.length
                    ? this.noteRes
                    : Time.fractionToTimesteps(this.noteRes, startFraction)

            if (start < timestepRange.start && end < timestepRange.start) {
                return true
            } else if (start > timestepRange.end) {
                return false
            }

            const x = {
                start:
                    ScoreCanvas.getPixelsForTimesteps({
                        timesteps: start,
                        removeLastGutterPx: "none",
                        timestepRes: this.grid.timestepRes,
                        grid: this.grid,
                    }) - scrollXOffset,

                end:
                    ScoreCanvas.getPixelsForTimesteps({
                        timesteps: end,
                        removeLastGutterPx: "none",
                        timestepRes: this.grid.timestepRes,
                        grid: this.grid,
                    }) - scrollXOffset,
            }

            ScoreCanvas.renderLabelWithSuperscript({
                ctx,
                text: chord[1],
                x,
                y: 15
            })

            return true
        })
    }
}
