import { Note } from "../../general/classes/score/note"
import { NotesObject } from "../../general/classes/score/notesObject"
import { TemplateChord } from "../../general/interfaces/score/templateScore"
import { ChordManipulation } from "../../general/modules/chord-manipulation.module"
import { KeySignatureModule } from "../../general/modules/keysignature.module"
import { Time } from "../../general/modules/time"
import { GridCoordinates, GridDimensions } from "./types"

export module ScoreRenderingEngineMisc {
    /**
     * This function returns the number of pitchsteps that are necessary to pass before firing a moveNotes action
     */
    export function preprocessDiffPitchsteps(
        now: GridCoordinates,
        previous: GridCoordinates,
        type: "exact" | "move" | "resize" | "loop"
    ) {
        let diffPitchsteps = now.ysteps - previous.ysteps

        if (type !== "exact") {
            diffPitchsteps = Math.trunc(diffPitchsteps)
        }

        return diffPitchsteps
    }

    /**
     * Use the return value of this function in combination with scrollToTimestep from Akita's EditorViewState,
     * passed as argument to the render function, in order to compute the start and end position of the range
     * of notes to render on this canvas
     * @returns the number of timesteps that the canvas is currently displaying.
     */
    export function getTimestepRange({
        grid,
        timestepRes,
        width,
        pxPerTimestep,
    }: {
        grid: GridDimensions
        timestepRes: number
        width: number
        pxPerTimestep: number
    }): number {
        const scoreLengthInTS = Time.convertTimestepsToAnotherRes(
            grid.scoreLengthTimesteps,
            grid.timestepRes,
            timestepRes
        )

        const timestepRange = Math.min(width / pxPerTimestep, scoreLengthInTS)

        return timestepRange
    }

    export function computePxPerTimestepsForGrid(
        grid: GridDimensions,
        timestepRes: number
    ) {
        const scoreLengthInTS = Time.convertTimestepsToAnotherRes(
            grid.scoreLengthTimesteps,
            grid.timestepRes,
            timestepRes
        )

        const pxLengthForGutters =
            grid.pxPerBarGutter * (grid.barsInPattern - 1) +
            grid.pxPerBeatsGutterInBar * grid.barsInPattern +
            grid.pxPerTimestepGutterInBar

        const pxLength =
            scoreLengthInTS * grid.pxPerTimestepWithoutGutters +
            pxLengthForGutters

        return pxLength / scoreLengthInTS
    }
}
