import { ImmutableNote, Note } from "./note"
import { Time } from "../../modules/time"
import { Effect, Delay, Reverb, AutoPhrasing } from "./effect"
import TrackBus from "./trackbus"
import {
    FractionString,
    LayerFunctionType,
    TimeSignature,
} from "../../types/score"
import {
    AUTOMATION_TIMESTEP_RES,
    TIMESTEP_RES,
} from "../../constants/constants"
import { ScoreManipulation } from "../../modules/scoremanipulation"
import {
    TemplateLayer,
    TemplateLayerEffect,
    TemplateNote,
} from "../../interfaces/score/templateScore"
import { Colors } from "../../types/layer"
import { NotesObject } from "./notesObject"
import { cloneDeep } from "lodash"
import Section from "./section"

const complementaryColors = require("complementary-colors")
export default class Layer {
    notesObject: NotesObject = new NotesObject()

    static MELODY_COLOR = {
        primary: "rgb(255, 58, 5)",
        secondary: "rgb(255, 0, 230)",
    }

    static CHORDS_COLOR = {
        primary: "rgb(235, 235, 0)",
        secondary: "rgb(255, 130, 5)",
    }

    static BASS_COLOR = {
        primary: "rgb(0, 163, 255)",
        secondary: "rgb(0, 255, 221)",
    }

    static EXTRA_COLOR = {
        primary: "rgb(118, 5, 255)",
        secondary: "rgb(243, 5, 255)",
    }

    static PERCUSSION_COLOR = {
        primary: "rgb(0, 211, 0)",
        secondary: "rgb(223, 147, 255)",
    }

    gainBias: number = 0

    settingsMenu = false

    type: LayerFunctionType = "pitched"
    value = ""
    order: number

    name: string = ""
    color: Colors = {
        red: "0",
        green: "0",
        blue: "0",
    }

    drumChannels: number[] = []

    timeSignature

    defaultColor: string
    oppositeColor: string

    trackBuses: Array<TrackBus> = []

    effects: {
        [key: string]: Effect
    } = {
        dynamic: new Effect("dynamic"),
        low_frequency_cut: new Effect("low_frequency_cut"),
        high_frequency_cut: new Effect("high_frequency_cut"),
        reverb: new Reverb("reverb"),
        delay: new Delay("delay"),
        auto_staccato: new Effect("auto_staccato"),
    }

    constructor(
        type: "pitched" | "percussion",
        value,
        name,
        color,
        effectsFromScore,
        timeSignature,
        gainBias
    ) {
        this.type = type
        this.value = value
        this.timeSignature = timeSignature
        this.name = name
        this.color = color

        if (value == "Melody") {
            this.effects["auto_phrasing"] = new AutoPhrasing("auto_phrasing")
        }

        for (var effect in this.effects) {
            if (effectsFromScore[effect] != null) {
                this.effects[effect].encode(effectsFromScore[effect])
            }
        }

        if (gainBias != null) {
            this.gainBias = gainBias
        } else if (
            value.includes("Custom Pitched") ||
            value.includes("Extra") ||
            value.includes("Melody_")
        ) {
            this.gainBias = -4
        }

        this.order = Layer.getLayerOrder(this.value)
        const colors = Layer.getLayerColor(this.value)

        this.defaultColor = colors.defaultColor
        this.oppositeColor = colors.oppositeColor
    }

    getTrackBusWithIndex(index) {
        for (var trackBus of this.trackBuses) {
            if (trackBus.id == index) {
                return trackBus
            }
        }

        return null
    }

    initAutomation(scoreLength: FractionString, defaultValue = null) {
        for (const effect in this.effects) {
            if (!this.effects[effect].automated) {
                continue
            }

            let effectEnd =
                Time.quantizeFraction(
                    scoreLength,
                    "ceil",
                    AUTOMATION_TIMESTEP_RES
                ) + 1
            effectEnd = effectEnd > 0 ? effectEnd : 0

            this.effects[effect].values = new Array(effectEnd).fill(
                defaultValue
            )
        }
    }

    initAutomatedEffects(scoreLength: FractionString) {
        for (const effect in this.effects) {
            const effectObject = this.effects[effect]

            if (!effectObject.automated) {
                continue
            }

            let effectEnd =
                Time.quantizeFraction(
                    scoreLength,
                    "ceil",
                    AUTOMATION_TIMESTEP_RES
                ) + 1

            effectEnd = effectEnd > 0 ? effectEnd : 0

            effectObject.values = new Array(effectEnd).fill(
                effectObject.default
            )
        }
    }

    getEnvelopes(sections) {
        var envs = {
            sections: sections,
            layer: this.value,
            controls: {},
        }

        for (var fx in this.effects) {
            const effect = this.effects[fx]

            if (!effect.automated) {
                continue
            }

            envs.controls[fx] = effect.decode()
            envs.controls[fx]["values"] = effect.values
            envs.controls[fx]["res"] = AUTOMATION_TIMESTEP_RES
        }

        return envs
    }

    static getOrderedNoteKeys(notesObject: NotesObject): string[] {
        return Object.keys(notesObject).sort()
    }

    getFirstNoteStart(): string {
        const keys = Layer.getOrderedNoteKeys(this.notesObject)

        if (keys.length === 0) {
            return "0"
        }

        return keys[0]
    }

    getName() {
        if (this.name == null) {
            return this.value.replace("_", " ")
        }

        return this.name
    }

    setNotesObject(noteObject: NotesObject) {
        this.notesObject = noteObject
    }

    clearNotes() {
        this.notesObject.clearNotes()
    }

    getDefaultInstrument() {
        var result = this.getDefaultTrack()

        return result.split(".")[0] + "." + result.split(".")[1]
    }

    getDefaultTrack() {
        var result = "s.silent-instrument.nat.stac"

        if (this.type == "percussion") {
            result = "p.silent-kit.nat.stac"
        }

        return result
    }

    updateDrumChannel(currentChannel, newChannel) {
        var currentChannelIndex = 0
        var addNewChannel = true

        for (var c = 0; c < this.drumChannels.length; c++) {
            if (this.drumChannels[c] == currentChannel) {
                currentChannelIndex = c
            } else if (this.drumChannels[c] == newChannel) {
                addNewChannel = false
            }
        }

        if (addNewChannel) {
            this.drumChannels[currentChannelIndex] = newChannel
        } else {
            this.drumChannels.splice(currentChannelIndex, 1)
        }
    }

    initDrumChannels() {
        if (this.type != "percussion") {
            return
        }

        let keys = {}

        this.notesObject.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
            for (let note of noteGroup) {
                keys[note.pitch] = 1
            }

            return true
        })

        let keysArray = Object.keys(keys)

        for (var k = 0; k < keysArray.length; k++) {
            this.drumChannels.push(parseInt(keysArray[k]))
        }
    }

    copyTrackBuses() {
        var newTrackBuses: TrackBus[] = []

        for (var trackBus of this.trackBuses) {
            newTrackBuses.push(trackBus.copy())
        }

        return newTrackBuses
    }

    decode(): TemplateLayer {
        const effects: TemplateLayerEffect = {
            dynamic: this.effects.dynamic.decode(),
            low_frequency_cut: this.effects.low_frequency_cut.decode(),
            high_frequency_cut: this.effects.high_frequency_cut.decode(),
            reverb: <Reverb>this.effects.reverb.decode(),
            delay: <Delay>this.effects.delay.decode(),
            auto_staccato: this.effects.auto_staccato.decode(),
        }

        return {
            type: this.type,
            value: this.value,
            effects: effects,
            name: this.name,
            color: this.color,
            gain_bias: this.gainBias,
        }
    }

    decodeNotesObject(args: {
        previouslySeenNoteGroup: string | undefined
        timeSliceFraction: string | undefined
    }): TemplateNote[] {
        const templateNotes: TemplateNote[] = []

        const groupRange: [string, string] | undefined =
            args.timeSliceFraction !== undefined
                ? [args.timeSliceFraction, args.timeSliceFraction]
                : undefined

        this.notesObject.manipulateNoteGroups(
            (noteGroup: Note[], nextNoteGroup: Note[]) => {
                if (args.timeSliceFraction !== undefined) {
                    const previousNoteGroup = this.notesObject.getNoteGroup(
                        args.previouslySeenNoteGroup
                    )

                    if (
                        this.notesObject.isAdjacentNoteGroup(
                            noteGroup,
                            previousNoteGroup
                        ) === "right"
                    ) {
                        templateNotes.push(
                            Note.decodeNoteGroup(this, previousNoteGroup, false)
                        )
                    }
                }

                templateNotes.push(Note.decodeNoteGroup(this, noteGroup, true))

                if (
                    args.timeSliceFraction !== undefined &&
                    nextNoteGroup &&
                    nextNoteGroup.length > 0
                ) {
                    templateNotes.push(
                        Note.decodeNoteGroup(this, nextNoteGroup, false)
                    )

                    let prevNoteGroupEnd = nextNoteGroup[0].getEnd()

                    while (
                        this.notesObject.getNoteGroup(prevNoteGroupEnd) !==
                        undefined
                    ) {
                        const noteGroup =
                            this.notesObject.getNoteGroup(prevNoteGroupEnd)

                        templateNotes.push(
                            Note.decodeNoteGroup(this, noteGroup, false)
                        )

                        prevNoteGroupEnd = noteGroup[0].getEnd()
                    }
                }

                return args.timeSliceFraction === undefined
            },

            groupRange,

            args.timeSliceFraction === undefined
        )

        return templateNotes
    }

    static getLayerOrder(value) {
        let order = 8

        if (value == "Melody") {
            order = 0
        } else if (value.includes("Melody")) {
            order = 0.5
        } else if (value == "Chords" || value == "Accompaniment") {
            order = 1
        } else if (value == "Bass") {
            order = 2
        } else if (value.includes("Extra")) {
            if (value == "Extra") {
                order = 3
            } else {
                order = 3.1
            }
        } else if (value.includes("Ornaments")) {
            if (value == "Ornaments") {
                order = 3.5
            } else {
                order = 3.6
            }
        } else if (value.includes("Custom Pitched")) {
            order = 4
            order += 0.001 * parseInt(value.replace("Custom Pitched ", ""))
        } else if (value == "Percussion") {
            order = 5
        } else if (value.includes("Custom Percussion")) {
            order = 6
            order += 0.001 * parseInt(value.replace("Custom Percussion ", ""))
        } else {
            order = 7
        }

        return order
    }

    applyLayerState(layer: Layer) {
        this.trackBuses = layer.trackBuses
        this.name = layer.name
        this.color = layer.color
        this.notesObject = layer.notesObject
    }

    getColor() {
        if (this.color == null) {
            return this.defaultColor
        }

        return (
            "rgb(" +
            parseInt(this.color.red) +
            ", " +
            parseInt(this.color.green) +
            ", " +
            parseInt(this.color.blue) +
            ")"
        )
    }

    getOppositeColor() {
        if (this.color == null) {
            return this.oppositeColor
        }

        const hex = this.rgb2hex(
            "rgb(" +
                parseInt(this.color.red) +
                ", " +
                parseInt(this.color.green) +
                ", " +
                parseInt(this.color.blue) +
                ")"
        )
        const myColor = new complementaryColors(hex)
        const rgbComplementary = myColor.complementary()[1]

        return (
            "rgb(" +
            parseInt(rgbComplementary.r) +
            ", " +
            parseInt(rgbComplementary.g) +
            ", " +
            parseInt(rgbComplementary.b) +
            ")"
        )
    }

    getColorRange() {
        var color = this.getColor()
            .replace("rgb(", "")
            .replace(")", "")
            .split(", ")

        var r = Math.round(parseInt(color[0]))
        var g = Math.round(parseInt(color[1]))
        var b = Math.round(parseInt(color[2]))

        var rMin = Math.min(255, Math.max(0, r * 0.7))
        var gMin = Math.min(255, Math.max(0, g * 0.7))
        var bMin = Math.min(255, Math.max(0, b * 0.7))

        return [
            { r: r, g: g, b: b },
            { r: rMin, g: gMin, b: bMin },
        ]
    }

    rgb2hex(rgb) {
        rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)

        function hex(x) {
            return ("0" + parseInt(x).toString(16)).slice(-2)
        }

        return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3])
    }

    static getLayerColor(layer: string) {
        let defaultColor = "rgb(118, 5, 255)"
        let oppositeColor = "rgb(243, 5, 255)"

        if (layer === "Melody") {
            defaultColor = "rgb(255, 58, 5)" // red
            oppositeColor = "rgb(255, 0, 230)" // pink-ish
        } else if (layer.includes("Melody")) {
            defaultColor = "rgb(250, 96, 136)" // red
            oppositeColor = "rgb(255, 0, 230)" // pink-ish
        } else if (layer === "Chords" || layer == "Accompaniment") {
            defaultColor = "rgb(232, 209, 6)"
            oppositeColor = "rgb(255, 130, 5)"
        } else if (layer === "Bass") {
            defaultColor = "rgb(0, 163, 255)"
            oppositeColor = "rgb(0, 255, 221)"
        } else if (layer.includes("Extra")) {
            defaultColor = "rgb(118, 5, 255)"
            oppositeColor = "rgb(243, 5, 255)"
        } else if (layer.includes("Custom Pitched")) {
            defaultColor = "rgb(139, 46, 251)"
            oppositeColor = "rgb(243, 5, 255)"
        } else if (layer === "Percussion") {
            defaultColor = "rgb(0, 211, 0)"
            oppositeColor = "rgb(223, 147, 255)"
        } else if (layer.includes("Custom Percussion")) {
            defaultColor = "rgb(0, 211, 0)"
            oppositeColor = "rgb(223, 147, 255)"
        } else {
            defaultColor = "rgb(118, 5, 255)"
            oppositeColor = "rgb(243, 5, 255)"
        }

        return {
            defaultColor,
            oppositeColor,
        }
    }

    getLowestNotePitch() {
        let lowestPitch = Note.highestNote

        this.notesObject.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
            for (let note of noteGroup) {
                lowestPitch = Math.min(lowestPitch, note.pitch)
            }

            return true
        })

        if (lowestPitch === Note.highestNote) {
            return Note.lowestNote
        }

        return lowestPitch
    }

    getHighestNotePitch() {
        let highestPitch: undefined | number = undefined

        this.notesObject.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
            for (let note of noteGroup) {
                highestPitch =
                    highestPitch === undefined || highestPitch < note.pitch
                        ? note.pitch
                        : highestPitch
            }

            return true
        })

        if (highestPitch === Note.lowestNote) {
            return Note.highestNote
        }

        return highestPitch
    }

    /**
     * @param timeSignature
     * @param sections
     * @param note
     * @param sort Set to true if you want to sort the notes array, false otherwise.
     * If set to true, will consume more CPU cycles
     * @param changePitchRange Only set to true for UI operations
     * @returns
     */
    addNotes(
        timeSignature: TimeSignature,
        sections: Section[],
        notes: Note[],
        changePitchRange = true
    ) {
        for (let note of notes) {
            if (Time.compareTwoFractions("0", note.start) === "gt") {
                continue
            }

            this.notesObject.addNoteToGroup(note, timeSignature, sections)
        }
    }
}
