<div
    class="preview-container"
    *ngIf="
        (editor.engine$ | async) !== undefined &&
        (editor.engine$ | async)?.score?.layers !== undefined
    ">
    <ng-container *ngIf="enableChordsEditingInEditor; else chordsRow">
        <chords-editing [engine]="engine" editorType="editor"></chords-editing>
    </ng-container>

    <ng-template #chordsRow>
        <div id="chords-row">
            <div class="chords-label"></div>
            <div id="chords-canvas"></div>
        </div>
    </ng-template>

    <ng-container *ngIf="editKeySignature">
        <div id="keysignature-row">
            <div class="ks-label"></div>
            <div id="key-signature-editing-canvas"></div>
        </div>
    </ng-container>

    <div id="tempo-row">
        <div class="tempo-label">
            <div class="tempo-label-text">
                Tempo:
                <span
                    *ngIf="!isEditingTempo"
                    class="tempo-label-text-fixed"
                    (dblclick)="startEditingTempo()"
                    >{{ (editor.engine$ | async).score.tempoMap[0].bpm }}
                </span>
                <input
                    class="tempo-input"
                    type="number"
                    #tempoInput
                    min="20"
                    max="210"
                    *ngIf="isEditingTempo"
                    (keyup)="onTempoKeyup($event)"
                    [(ngModel)]="tempo" />
            </div>

            <div class="tempo-label-button-wrapper">
                <a-button
                    label="Edit"
                    *ngIf="!isEditingTempo"
                    (click)="startEditingTempo()"
                    class="editor-tempo-edit"
                    icon="assets/img/edit.svg"></a-button>
                <a-button
                    *ngIf="isEditingTempo"
                    class="editor-tempo-edit editor-tempo-edit-apply"
                    type="secondary"
                    label="Apply"
                    (click)="editTempo(tempo)"></a-button>
            </div>
        </div>
        <div id="tempo-canvas"></div>
    </div>

    <div
        id="layer-preview-row"
        *ngFor="let layer of getOrderedLayers(); let i = index">
        <div class="layer-preview-labels">
            <div
                [attr.av-data]="'layer-button' + i"
                class="layer-button"
                (click)="editor.engine.toggleLayer(layer.value)"
                [tooltip]="layer.getName()"
                tooltip-position="middle-bottom"
                [ngStyle]="{
                    'background-color':
                        layer.value ===
                        (editor.engine.toggledLayer$ | async)?.value
                            ? layer.getColor()
                            : 'transparent'
                }">
                {{ layer.getName() }}
            </div>

            <div
                class="layer-visibility"
                (click)="editor.engine.toggleLayerAsVisible(layer.value)"
                tooltip="View multiple layers in the same piano roll by toggling this visibility button"
                tooltip-width="200">
                <img
                    src="assets/img/pianoroll/visible.svg"
                    *ngIf="
                        layer.type === 'pitched' &&
                        editor.engine.isVisibleLayer(layer.value)
                    " />
                <img
                    src="assets/img/pianoroll/not_visible.svg"
                    *ngIf="
                        layer.type === 'pitched' &&
                        !editor.engine.isVisibleLayer(layer.value)
                    " />
            </div>

            <div
                class="layer-settings"
                tooltip="Layer settings"
                (click)="openSettings($event, layer)">
                <img src="assets/img/pianoroll/settings.svg" />
            </div>

            <div class="layer-toggles">
                <div
                    class="layer-toggles-mute"
                    [ngClass]="{
                        'muted-layer': editor.engine.layerIsToggled(
                            layer,
                            'mute'
                        ),
                        'muted-trackbus':
                            !editor.engine.layerIsToggled(layer, 'mute') &&
                            editor.engine.layerHasToggledTrackbus(layer, 'mute')
                    }"
                    (click)="mute(layer)"
                    tooltip="Mute">
                    M
                </div>
                <div
                    class="layer-toggles-solo"
                    [ngClass]="{
                        'soloed-layer': editor.engine.layerIsToggled(
                            layer,
                            'solo'
                        ),
                        'soloed-trackbus':
                            !editor.engine.layerIsToggled(layer, 'solo') &&
                            editor.engine.layerHasToggledTrackbus(layer, 'solo')
                    }"
                    (click)="solo(layer)"
                    tooltip="Solo">
                    S
                </div>
            </div>
        </div>

        <div
            id="layer-preview-{{ layer.value }}"
            class="canvas-container layer-preview"
            (click)="editor.engine.toggleLayer(layer.value)"
            [ngStyle]="{ 'background-color': layer.getColor() }"
            [ngClass]="{
                'layer-preview-selected':
                    editor.engine.toggleLayer?.value === layer.value
            }"></div>
    </div>
</div>

<menu-options
    *ngIf="menuOptions !== undefined"
    [menuOptions]="menuOptions"
    (close)="closeLayerSettings()"></menu-options>
