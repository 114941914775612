import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
} from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import {
    DoActionBeforeModalParameters,
    ModalService,
    ModalsValueWarning,
} from "@services/modal.service"
import { LayerType, TIMESTEP_RES } from "@common-lib/constants/constants"
import { DropdownItemType } from "../../types/dropdownItemType"
import { EditorLoadingStatus, NoteResolution } from "@common-lib/types/score"
import { LAYER_TYPES, NOTE_RESOLUTIONS, PATTERN_LENGTHS } from "../../constants"
import { BarCount } from "@common-lib/types/score"
import { AccompanimentDesignerService } from "@services/accompaniment-designer/accompaniment-designer.service"
import { TimeSignature } from "@common-lib/types/score"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import { NoteLabel } from "@common-lib/classes/score/note"
import { ParentClass } from "../../parent"
import { PlayerService } from "@services/audio/player/player.service"
import { Misc } from "@common-lib/modules/misc"
import Score from "@common-lib/classes/score/score"
import { ScoreUpdateType } from "../../../../../common-lib/client-only/score-rendering-engine"
import ScoreRenderingEngine from "../../../../../common-lib/client-only/score-rendering-engine/engine"
import { Time } from "@common-lib/modules/time"
import { environment } from "@environments/environment"
import { NotesObject } from "@common-lib/classes/score/notesObject"
import { ShortcutsService } from "@services/shortcuts.service"
import { cloneDeep } from "lodash"
import Layer from "@common-lib/classes/score/layer"
import { takeWhile } from "rxjs"

@Component({
    selector: "accompaniment-designer",
    templateUrl: "accompaniment-designer.component.html",
    styleUrls: ["accompaniment-designer.component.scss"],
    providers: [AccompanimentDesignerService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccompanimentDesignerComponent
    extends ParentClass
    implements OnInit
{
    patternLengthOptions: DropdownItemType<BarCount>[] = PATTERN_LENGTHS
    layerTypeOptions: DropdownItemType<LayerType>[] = LAYER_TYPES

    public keySignatureOptions
    public subscriptions = []

    public hasSavedPack = false
    public polyphonyWarning

    get pack(): AccompanimentPack | undefined {
        return this.adService.pack
    }

    get selectedTimeSignature(): DropdownItemType<TimeSignature> | undefined {
        return undefined
    }

    get noteResolutionOptions(): DropdownItemType<NoteResolution>[] {
        const noteResOptions = NOTE_RESOLUTIONS.filter(
            option =>
                Number(option.value.split("/")[1]) %
                    this.adService?.engine?.score?.firstTimeSignature[1] ===
                0
        )

        return noteResOptions
    }

    public get noteResolution(): DropdownItemType<string> {
        const noteResolution = {
            name: this.adService?.engine?.timestepRes + "th note",
            value: "1/" + this.adService?.engine?.timestepRes,
        }

        return noteResolution
    }

    get selectedPatternLength(): DropdownItemType<BarCount> | undefined {
        return this.patternLengthOptions.find(
            item => item.value === this.adService.patternLength
        )
    }

    get selectedLayerType(): DropdownItemType<LayerType> | undefined {
        return this.layerTypeOptions.find(
            item => item.value === this.pack?.type
        )
    }

    get selectedKeySignature(): DropdownItemType<NoteLabel[]> | undefined {
        return this.keySignatureOptions.find(
            item =>
                item.name === this.adService?.engine?.score?.keySignatures[0][1]
        )
    }

    get packID() {
        return this.route.snapshot.paramMap.get("packID")
    }

    get layer() {
        return this.route.snapshot.paramMap.get("layer")
    }

    get gpID() {
        return this.route.snapshot.paramMap.get("generationProfileID")
    }

    get msMode() {
        return (
            this.route.snapshot.paramMap.get("msMode") === "true" &&
            !this.isProd()
        )
    }

    get createTemplateAtStartup() {
        return this.route.snapshot.paramMap.get("createTemplatePack") === "true"
    }

    public resizeFactor: number = 70
    public scoreWasEdited: boolean = false

    constructor(
        public adService: AccompanimentDesignerService,
        private modalService: ModalService,
        private ref: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,
        private player: PlayerService,
        private shortcutsService: ShortcutsService
    ) {
        super()

        this.router.routeReuseStrategy.shouldReuseRoute = () => false
        this.router.onSameUrlNavigation = "reload"

        this.keySignatureOptions = this.getKeySignatureOptions()
    }

    async ngOnInit() {
        this.handleSubscriptions()

        await this.adService.init({
            packID: this.packID,
            generationProfileID: this.gpID,
            msMode: this.msMode,
        })

        if (
            this.createTemplateAtStartup &&
            this.adService.packLoading.finished
        ) {
            this.createTemplatePackWithoutModalGuard()
        }
    }

    public setCursorType() {
        if (this.adService.engine.cursorType === "pencil") {
            this.adService.engine.cursorType = "select"
        } else {
            this.adService.engine.cursorType = "pencil"
        }
    }

    handleSubscriptions() {
        this.subscribeToEngine()
        this.subscribeToScoreUpdates()
        this.subscribeToScoreChanges()
        this.subscribeToAccompanimentDesignerUpdates()
        this.subscribeToModals()

        this.adService.onPackRenamed = res => {
            if (res && res != "") {
                this.adService.packWasEdited = true
            }

            this.ref.detectChanges()
        }
    }

    public async savePack(createTemplatePack: boolean) {
        const result = await this.adService.savePack()

        if (result.success) {
            this.hasSavedPack = true

            this.adService.loading$
                .pipe(takeWhile(value => value.progress < 100, true))
                .subscribe(async value => {
                    if (value.progress === 100) {
                        this.router.navigate([
                            "accompaniment-designer",
                            this.gpID,
                            value.packID,
                            this.layer,
                            this.msMode + "",
                            createTemplatePack + "",
                        ])
                    }
                })
        }

        return result
    }

    private createTemplatePackWithoutModalGuard() {
        this.modalService.modals.createTemplateAccompanimentPack.next({
            sourcePack: cloneDeep(this.pack),
            generationProfileID: this.gpID,
            layer: this.layer,
            preCompletion: (() => {
                this.hasSavedPack = true
            }).bind(this),
        })

        this.adService.updateURL({
            createTemplatePack: false,
        })
    }

    public async createTemplatePack() {
        if (this.adService.engine.scoreWasEdited) {
            await new Promise((resolve, reject) => {
                const data = {
                    action: (() => {
                        resolve(null)

                        return {
                            success: true,
                        }
                    }).bind(this),

                    description:
                        "To continue, you must first save your changes made to your pack.",

                    continueButtonText: "Save & Continue",
                } as DoActionBeforeModalParameters

                this.modalService.modals.doActionsBefore.next(data)
            })

            await this.savePack(true)
        } else {
            this.createTemplatePackWithoutModalGuard()
        }
    }

    getKeySignatureOptions() {
        let options = []

        const ksOptions = Misc.getKeySignatureOptions(undefined)

        for (let opt in ksOptions) {
            for (let ks of ksOptions[opt]) {
                options.push({
                    value: ks,
                    name: ks,
                    optgroup: Misc.capitalizeFirstLetter(opt),
                    disabled: false,
                })
            }
        }

        return options
    }

    subscribeToScoreChanges() {
        this.subscribe(this.adService.engine.score$, (score: Score) => {
            this.ref.detectChanges()
        })
    }

    subscribeToScoreUpdates() {
        this.subscribe(
            this.adService.engine.scoreUpdate$,
            (update: ScoreUpdateType) => {
                if (
                    update.includes("All") ||
                    update.includes("ScoreMetadata")
                ) {
                    this.ref.detectChanges()
                }
            }
        )

        // Notify users about polyphony in monophonic layers
        this.subscribe(this.adService.isPolyphonic$, isPolyphonic => {
            if (this.adService.engine.allowPolyphony === false) {
                this.polyphonyWarning = isPolyphonic
                    ? "Bass patterns must be monophonic. There should be no chords in a Bass pattern."
                    : undefined
                this.ref.detectChanges()
            }
        })
    }

    subscribeToAccompanimentDesignerUpdates() {
        this.subscribe(this.adService.allStates$, state => {
            this.ref.detectChanges()
        })

        this.subscribe(
            this.adService.loading$,
            async (state: {
                progress: number
                status: EditorLoadingStatus
                packID
            }) => {
                if (this.hasSavedPack) {
                    return
                }

                if (state.status === "loaded") {
                    await this.adService.initialiseEditor()
                }

                this.ref.detectChanges()
            }
        )
    }

    subscribeToEngine() {
        this.subscribe(
            this.adService.engine$,
            async (engine: ScoreRenderingEngine) => {
                if (engine === undefined || engine.score === undefined) {
                    return
                }

                for (const sub of this.subscriptions) {
                    if (sub !== undefined) {
                        sub.unsubscribe()
                    }
                }

                const resizeFactor = this.subscribe(
                    this.adService.engine.resizeFactor$,
                    (value: number) => {
                        this.resizeFactor = value
                    }
                )

                this.subscriptions.push(resizeFactor)

                const scoreWasEdited = this.subscribe(
                    this.adService.engine.scoreWasEdited$,
                    (value: boolean) => {
                        if (this.scoreWasEdited && value) {
                            return
                        }

                        this.scoreWasEdited = value

                        this.ref.detectChanges()
                    }
                )

                this.subscriptions.push(scoreWasEdited)

                const seekTime = this.subscribe(
                    this.adService.engine.seekTime$,
                    (timesteps: number) => {
                        if (timesteps === undefined || isNaN(timesteps)) {
                            return
                        }

                        const seconds = Time.convertTimestepsInSeconds(
                            TIMESTEP_RES,
                            this.adService.engine.score.tempoMap,
                            timesteps,
                            this.player.hasStartOffset()
                        )

                        this.player.setTimeFromSeconds(seconds)
                    }
                )

                this.subscriptions.push(seekTime)

                // Notify users about polyphony in monophonic layers
                if (this.adService.engine.allowPolyphony === false) {
                    const selectedData = this.subscribe(
                        this.adService.engine.selectedNotes$,
                        (notes: NotesObject | undefined) => {
                            const isPolyphonic =
                                ScoreRenderingEngine.isPolyphonicLayer({
                                    layer: this.adService.engine
                                        .toggledLayer as Layer,
                                    selectedNotes: notes,
                                })

                            this.adService.isPolyphonic$.next(isPolyphonic)
                        }
                    )

                    this.subscriptions.push(selectedData)
                }

                this.ref.detectChanges()
            }
        )
    }

    subscribeToModals() {
        this.subscribe(
            this.modalService.accompanimentDesignerValueWarning,
            (valueWarning: ModalsValueWarning) => {
                if (
                    !valueWarning ||
                    valueWarning.showModal ||
                    !valueWarning.boolean
                ) {
                    return
                }

                switch (valueWarning.type) {
                    case "bars":
                        return this.selectPatternLength(
                            valueWarning.value,
                            false
                        )
                    case "resolution":
                        return this.selectNoteResolution(
                            valueWarning.value,
                            false
                        )
                    default:
                        return
                }
            }
        )
    }

    async goToGenerationProfile() {
        const navigation = await this.router.navigate([
            "/generation-profile-editor",
            this.gpID,
        ])

        if (navigation) {
            // we on purpose don't use await and block the view here and
            // accept the user encountering some loading time instead
            this.adService.initGP()
        }
    }

    renamePack() {
        this.modalService.modals.renamePack.next(this.pack?.name)
    }

    public toggleMSMode() {
        this.router.navigate([
            "accompaniment-designer",
            this.gpID,
            this.packID,
            this.layer,
            !this.msMode + "",
            false + "",
        ])
    }

    public selectPatternLength(
        patternLength: BarCount,
        withModalCheck: boolean = true
    ) {
        const notesToRemove = this.adService.notesToRemove({
            patternLength,
            noteResolution: this.adService.engine.timestepRes,
            timeSignature: this.adService.engine.score.firstTimeSignature,
        })

        if (
            withModalCheck &&
            notesToRemove.length !== 0 &&
            !this.adService.msMode
        ) {
            return this.modalService.accompanimentDesignerValueWarning.next({
                value: patternLength,
                type: "bars",
                boolean: false,
                showModal: true,
            })
        }

        this.adService.setPatternLength(patternLength, notesToRemove)

        this.ref.detectChanges()
    }

    public selectKeySignature(keySignature: string) {
        this.adService.setKeySignature(keySignature)

        this.ref.detectChanges()
    }

    public getPlaybackIcon() {
        return this.adService.getPlaybackIcon()
    }

    public togglePlayback() {
        this.adService.togglePlayback()
    }

    public selectNoteResolution(
        noteRes: string,
        withModalCheck: boolean = true
    ) {
        const noteResolution =
            typeof noteRes === "number"
                ? noteRes
                : parseInt(noteRes.split("/")[1])

        const notesToRemove = !this.adService?.engine?.score
            ? new NotesObject()
            : this.adService.notesToRemove({
                  noteResolution: noteResolution,
                  timeSignature: this.adService.engine.score.firstTimeSignature,
                  patternLength: this.adService.patternLength,
              })

        if (withModalCheck && notesToRemove.length !== 0) {
            return this.modalService.accompanimentDesignerValueWarning.next({
                value: noteResolution,
                type: "resolution",
                boolean: false,
                showModal: true,
            })
        }

        this.adService?.engine?.setAccompanimentDesignerTimestepRes(
            noteResolution,
            notesToRemove
        )

        this.ref.detectChanges()
    }

    public isProd() {
        return environment.production
    }

    @HostListener("window:resize", ["$event"])
    onResize() {
        this.adService.engine.resizeCanvases()
    }

    @HostListener("document:keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        return this.shortcutsService.triggerShortcuts(
            event,
            this.adService.engine
        )
    }

    ngOnDestroy() {
        super.ngOnDestroy()
        this.adService.onDestroy()
    }
}
