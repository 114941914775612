<span
    (mouseleave)="hoveredOption = undefined"
    (clickOutside)="close.emit()"
    [clickOutsideEvents]="'mousedown,touchstart'">
    <div
        class="menu-background"
        id="menu-options"
        #menuOptionsContainer
        [ngStyle]="{ width: width ? width + 'px' : 'auto' }"
        [style.left.px]="menuOptions.coordinates.x - 3"
        [style.top.px]="menuOptions.coordinates.y - 3">
        <div class="menu-options-title" *ngIf="title !== undefined">
            {{ title }}
        </div>
        <div
            [class]="'button-container'"
            *ngFor="let option of menuOptions.options"
            (mouseenter)="hoverOption($event, option)">
            <a-button
                width="100%"
                [type]="isMenuOption(option.data) ? 'with-right-chevron' : ''"
                [class]="
                    'menu-option-button ' +
                    (option.buttonClass
                        ? 'menu-option-button-' + option.buttonClass
                        : '')
                "
                *ngIf="!option.loading"
                [label]="option.text"
                [icon]="option.icon"
                (click)="clickOnOption(option, $event)"></a-button>

            <div class="loader" *ngIf="option.loading"></div>
        </div>
    </div>

    <menu-options
        *ngIf="
            hoveredOption !== undefined &&
            isMenuOption(hoveredOption.option.data)
        "
        [menuOptions]="createMenuOptions()"
        [width]="getWidth()"
        [title]="hoveredOption.option.subtitle"></menu-options>
</span>
