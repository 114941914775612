import PercussionLayer from "../../../general/classes/score/percussionlayer"
import { Time } from "../../../general/modules/time"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    DrumSequencerCanvasContext,
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    GridDimensionsDictionary,
    CanvasType,
    GridCoordinates,
} from "../types"
import ScoreCanvas from "./score-canvas"
import { cloneDeep } from "lodash"
import { Pattern } from "../../../general/classes/score/pattern"
import Channel from "../../../general/classes/score/channel"
import TrackBus from "../../../general/classes/score/trackbus"
import { SRActionTypes } from "../states/score-rendering/score-rendering.actions"
import { Coordinates } from "../../../general/modules/event-handlers"

import { throttleTime } from "rxjs"
import Layer from "../../../general/classes/score/layer"
import PatternRegion from "../../../general/classes/score/patternregion"
import {
    playerActions,
    playerQuery,
} from "../../general/classes/playerStateManagement"

export default class DrumSequencerCanvas extends ScoreCanvas {
    private layer: PercussionLayer
    private pattern: Pattern
    private channels: Channel[]

    private holdingClick = false
    private lastTimesteps
    private lastPlayingPatternRegion: PatternRegion | undefined = undefined
    private readonly prerenderedGridCanvas = document.createElement(
        "canvas"
    ) as HTMLCanvasElement
    private readonly prerenderedOnsetCanvas = document.createElement(
        "canvas"
    ) as HTMLCanvasElement

    constructor(
        public context: DrumSequencerCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)
        this.initListeners()
    }

    protected initListeners() {
        playerQuery
            .select("timeElapsed")
            .pipe(throttleTime(16))
            .subscribe((timeElapsed: number) => {
                const layer: PercussionLayer | Layer =
                    this.queries.scoreRendering.toggledLayer

                if (
                    !(layer instanceof PercussionLayer) ||
                    this.queries.scoreRendering.selectedPattern === undefined
                ) {
                    return
                }

                const timesteps = Math.round(
                    Time.convertSecondsInTimesteps(
                        timeElapsed,
                        playerActions.hasStartOffset(),
                        this.noteRes || 8,
                        this.queries.scoreRendering.score.tempoMap,
                        "DrumSequencerCanvas.initListeners"
                    )
                )

                if (timesteps === this.lastTimesteps) {
                    return
                }

                const scoreTS = Time.convertTimestepsToAnotherRes(
                    timesteps,
                    this.noteRes,
                    this.queries.scoreRendering.timestepRes
                )

                let patternRegions = PercussionLayer.getPatternRegionsInRange(
                    {
                        regions: layer.patternRegions,
                        timestepRange: {
                            start: scoreTS,
                            end: scoreTS,
                        },
                        patternsToInclude: [
                            this.queries.scoreRendering.selectedPattern,
                        ],
                    }
                )

                if (patternRegions.length === 0) {
                    this.lastPlayingPatternRegion = undefined
                    return
                }

                this.lastTimesteps = timesteps
                this.lastPlayingPatternRegion = patternRegions[0]

                this.actions.scoreRendering.manager.emitter$.next({
                    type: SRActionTypes.setRenderingType,
                    data: {
                        type: "DrumSequencerCanvas",
                    },
                })
            })

        this.mouseDown$.subscribe((event: Coordinates) => {
            this.holdingClick = true

            this.actions.scoreRendering.manager.emitter$.next({
                type: SRActionTypes.startNoteManipulation,
                options: {
                    isUndoable: true,
                },
            })

            const grid = this.getGridCoordinates(event, true)

            this.drawPercussionNote(grid, "inverse")
        })

        this.mouseMove$.subscribe((event: Coordinates) => {
            if (!this.holdingClick) {
                return
            }

            const grid = this.getGridCoordinates(event, true)

            this.drawPercussionNote(
                grid,
                this.queries.scoreRendering.getValue().percussionDrawingStrategy
            )
        })

        this.mouseUp$.subscribe((event: Coordinates) => {
            this.holdingClick = false

            this.actions.scoreRendering.manager.emitter$.next({
                type: SRActionTypes.endDrawingPercussionNotes,
                data: {},
                options: {
                    isUndoable: false,
                },
            })
        })
    }

    private drawPercussionNote(
        grid: GridCoordinates,
        strategy: "toggleOn" | "toggleOff" | "inverse"
    ) {
        this.actions.scoreRendering.manager.emitter$.next({
            type: SRActionTypes.drawPercussionNote,
            data: {
                tb: this.getTrackBus(),
                timesteps: grid.timesteps,
                ysteps: grid.ysteps,
                strategy: strategy,
            },
            options: {
                isUndoable: false,
            },
        })
    }

    private getTrackBus(): TrackBus {
        return this.queries.scoreRendering.toggledLayer.trackBuses.find(
            tb => tb.id === this.context.trackBusID
        )
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        const shouldRenderCanvas = this.shouldRenderCanvas(
            scoreState.renderingType
        )

        if (
            !scoreState.score ||
            !scoreState.toggledLayer ||
            !scoreState.selectedPattern ||
            !shouldRenderCanvas ||
            !(
                this.queries.scoreRendering.toggledLayer instanceof
                PercussionLayer
            )
        ) {
            return
        }

        if (!this.queries.scoreRendering.toggledLayer?.patternRegions) {
            throw "Drum sequencer only supports Percussion Layers"
        }

        this.layer = this.queries.scoreRendering
            ?.toggledLayer as PercussionLayer
        this.pattern = scoreState.selectedPattern
        this.channels = this.getChannelsByTrackBusID(this.context?.trackBusID)

        const copiedGrid: GridDimensionsDictionary = cloneDeep(grid)
        const copiedDrumSequencerGrid = copiedGrid[this.gridType]

        const shouldRender = this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: copiedGrid,
            noteRes: copiedDrumSequencerGrid.timestepRes,
            nbOfYValues: this.getChannelsByTrackBusID(this.context?.trackBusID)
                .length,
            highestPitch: 0,
        })
        
        /**
         * we have to render the grid always when we render the onsets, otherwise the grid
         * that serves as a background is blank
         */
        // let start = performance.now()
        if (shouldRender.shouldRenderGrid || shouldRender) {
            this.generateGrid()
        }
        // let end = performance.now()

        // console.log(`Generating the grid took: ${end - start}ms`)
        // start = performance.now()
        this.renderOnsets()

        // end = performance.now()

        // console.log(`Generating this onsets took: ${end - start}`)

        this.renderPlaybackOutline()
    }

    private renderPlaybackOutline() {
        //console.log(this.queries.scoreRendering.score.layers)
        const ctx: CanvasRenderingContext2D = this.getContext(
            "canvas-" + this.context?.trackBusID
        ) as CanvasRenderingContext2D

        if (this.lastPlayingPatternRegion === undefined) {
            return
        }

        const patternRegionStart = Time.fractionToTimesteps(
            this.noteRes,
            this.lastPlayingPatternRegion.start
        )
        const patternRegionEnd = Time.fractionToTimesteps(
            this.noteRes,
            this.lastPlayingPatternRegion.getLoopedEnd()
        )
        const patternRegionOnset = Time.fractionToTimesteps(
            this.noteRes,
            this.lastPlayingPatternRegion.onset
        )

        const oneLoopDuration =
            (patternRegionEnd - patternRegionStart) /
            (this.lastPlayingPatternRegion.loop + 1)

        const tsRelativeToPatternRegionLoops =
            this.lastTimesteps - patternRegionStart

        const tsRelativeToPatternRegion =
            (tsRelativeToPatternRegionLoops % oneLoopDuration) +
            patternRegionOnset

        const x =
            ScoreCanvas.getPixelsForTimesteps({
                grid: this.grid,
                timesteps: tsRelativeToPatternRegion,
                timestepRes: this.noteRes,
                removeLastGutterPx: "none",
            }) -
            ScoreCanvas.getScrollXOffset({
                scrollToTimestep: this.patternScrollToTimestep,
                grid: this.grid,
                pxPerTimestep: this.pxPerTimestep,
                noteRes: this.noteRes,
            })

        this.traversePitchGrid((y: number) => {
            const width = this.grid.pxPerTimestepWithoutGutters

            this.generateUnfilledRect(
                ctx,
                x,
                y + this.grid.pxPerPitchGutter / 2,
                "white",
                this.grid.noteBorderRadius,
                width,
                this.pxPerYStep - this.grid.pxPerPitchGutter
            )

            return true
        })
    }

    private generateGrid() {
        const ctx: CanvasRenderingContext2D = this.getContext(
            "canvas-grid-" + this.context?.trackBusID
        ) as CanvasRenderingContext2D
        const fillStyleNormal = "rgba(255, 255, 255, 0.1)"
        const skipHalfBeatsteps = true

        ctx.globalCompositeOperation = "copy"
        let first = true
        this.traverseTimeGrid(
            (x: number) => {
                if (first) {
                    this.prerenderedGridCanvas
                        .getContext("2d")
                        .clearRect(
                            0,
                            0,
                            this.prerenderedOnsetCanvas.width,
                            this.prerenderedOnsetCanvas.height
                        )

                    this.prerenderedGridCanvas.width =
                        this.grid.pxPerTimestepWithoutGutters

                    this.prerenderedGridCanvas.height =
                        this.pxPerYStep - this.grid.pxPerPitchGutter

                    ScoreCanvas.generateNoteRect(
                        this.prerenderedGridCanvas.getContext("2d"),
                        0,
                        0,
                        fillStyleNormal,
                        this.grid.noteBorderRadius,
                        this.grid.pxPerTimestepWithoutGutters,
                        this.pxPerYStep - this.grid.pxPerPitchGutter
                    )
                    first = false
                }

                this.traversePitchGrid((y: number) => {
                    ctx.drawImage(
                        this.prerenderedGridCanvas,
                        x,
                        y + this.grid.pxPerPitchGutter / 2
                    )

                    ctx.globalCompositeOperation = "source-over"

                    return true
                })

                return true
            },
            false,
            false,
            skipHalfBeatsteps
        )
    }

    protected scroll(event: WheelEvent) {
        const deltaX = event.deltaX

        if (event.deltaX === 0) {
            return
        }

        const patternScrollToTimestep: number =
            this.queries.scoreRendering.patternScrollToTimestep + deltaX
        this.srEmitter$.next({
            type: SRActionTypes.setPatternScroll,
            data: {
                patternScrollToTimestep,
            },
        })
    }

    private renderOnsets() {
        const ctx: CanvasRenderingContext2D = this.getContext(
            "canvas-" + this.context?.trackBusID
        ) as CanvasRenderingContext2D

        let first = true

        for (let c = 0; c < this.channels.length; c++) {
            const y = c * this.pxPerYStep
            const channel = this.channels[c]

            for (let o = 0; o < channel.onsets.length; o++) {
                const onset = channel.onsets[o]

                const x =
                    ScoreCanvas.getPixelsForTimesteps({
                        grid: this.grid,
                        timesteps: Time.fractionToTimesteps(
                            this.noteRes,
                            onset.start
                        ),
                        timestepRes: this.noteRes,
                        removeLastGutterPx: "none",
                    }) -
                    ScoreCanvas.getScrollXOffset({
                        scrollToTimestep: this.patternScrollToTimestep,
                        grid: this.grid,
                        pxPerTimestep: this.pxPerTimestep,
                        noteRes: this.noteRes,
                    })

                const width = this.grid.pxPerTimestepWithoutGutters
                const color = this.getNoteColor(this.layer, "normal")

                if (first) {
                    this.prerenderedOnsetCanvas
                        .getContext("2d")
                        .clearRect(
                            0,
                            0,
                            this.prerenderedOnsetCanvas.width,
                            this.prerenderedOnsetCanvas.height
                        )
                    this.prerenderedOnsetCanvas.width = width
                    this.prerenderedOnsetCanvas.height =
                        this.pxPerYStep - this.grid.pxPerPitchGutter

                    ScoreCanvas.generateNoteRect(
                        this.prerenderedOnsetCanvas.getContext("2d"),
                        0,
                        0,
                        color,
                        this.grid.noteBorderRadius,
                        width,
                        this.pxPerYStep - this.grid.pxPerPitchGutter
                    )
                    first = false
                }

                ctx.drawImage(
                    this.prerenderedOnsetCanvas,
                    x,
                    y + this.grid.pxPerPitchGutter / 2
                )
            }
        }
    }

    private getChannelsByTrackBusID(trackBusID: string | undefined) {
        if (trackBusID === undefined) {
            return []
        }

        return this.pattern.channels.filter(
            channel => channel.trackBus.id === trackBusID
        )
    }

    protected getContext(canvasType): CanvasRenderingContext2D {
        const ctx = this.canvases[canvasType].getContext("2d")

        if (!ctx) {
            throw "Unexpected error - Context is undefined"
        }

        return ctx
    }
}
