<div class="modal-title">
    Instruments for
    {{
        layer.name.includes("Melody")
            ? "Melody"
            : pack.name
    }}
</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div
    class="gp-instrument-edition-container"
    [perfectScrollbar]="scrollChangeInstrumentation" *ngIf="hasInitialised">
    <div
        class="no-instrument-assigned-to-pack"
        *ngIf="pack.instruments.length === 0">
        No instrument was assigned.
    </div>
    <div
        *ngFor="
            let instrumentPatch of pack.instruments;
            let i = index
        "
        (mouseenter)="hoveredIndex = i"
        (mouseleave)="hoveredIndex = -1">
        <a-button
            class="gp-instrument-edition-button"
            [ngClass]="{
                'gp-instrument-edition-button-percussion':
                    layer.name.includes('Percussion')
            }"
            type="round-icon-and-text"
            width="100%"
            [label]="instrumentPatch.name"
            [icon]="getPlaybackIcon(instrumentPatch)"
            [loading]="isPreviewLoading(instrumentPatch)"
            (onClick)="preview(instrumentPatch)"></a-button>

        <div
            class="note-tie-instrument similar-instruments"
            *ngIf="
                !layer.name.includes('Percussion') &&
                hoveredIndex === i
            "
            (click)="findSimilarInstrumentPatches(instrumentPatch)">
            <img src="assets/img/search.svg" />
            Similar instruments
        </div>

        <div
            class="note-tie-instrument"
            *ngIf="
                !layer.name.includes('Percussion') &&
                hoveredIndex === i &&
                !instrumentPatch.noteTie
            "
            (click)="toggleNoteTie($event, instrumentPatch, true)"
            [tooltip]="tooltips.noteTie"
            [tooltip-marginTop]="10"
            [tooltip-width]="225">
            Enable note tie
        </div>

        <div
            class="note-tie-instrument"
            *ngIf="
                !layer.name.includes('Percussion') &&
                hoveredIndex === i &&
                instrumentPatch.noteTie
            "
            (click)="toggleNoteTie($event, instrumentPatch, false)"
            [ngStyle]="{
                'background-color': 'rgba(255,255,255,0.05)'
            }"
            [tooltip]="tooltips.noteTie"
            [tooltip-marginTop]="10"
            [tooltip-width]="225">
            Disable note tie
        </div>

        <div
            class="note-tie-instrument note-tie-instrument-empty"
            *ngIf="layer.name.includes('Percussion')"></div>

        <div
            class="note-tie-instrument note-tied-instrument"
            *ngIf="
                !layer.name.includes('Percussion') &&
                hoveredIndex !== i &&
                instrumentPatch.noteTie
            "
            [ngStyle]="{ 'background-color': 'transparent' }">
            <img src="assets/img/menu/link.svg" />
        </div>

        <img
            class="delete-pack-instrument"
            *ngIf="hoveredIndex === i"
            src="assets/img/delete.svg"
            (click)="deleteInstrumentPatchFromPack(i)" />
    </div>
</div>

<div class="gp-instrument-edition-buttons">
    <a-button
        width="100%"
        icon="assets/img/add.svg"
        [label]="'Add instruments'"
        (onClick)="selectMoreAddingInstrumentOptions($event)"
        [class]="'secondary'"></a-button>
</div>
