<div class="potion-component">
    <div class="header">
        <div class="title">Potion (Instrument creator)</div>

        <steps [steps]="steps" [currentStepIndex]="currentStepIndex"></steps>
    </div>

    <div class="step step-1" *ngIf="currentStepIndex == 1">
        <div class="settings-row">
            <div class="settings-row-title">
                Dry run

                <img 
                    class="tooltip-img"
                    src="assets/img/info.svg"
                    [tooltip]="tooltips.dryRun"
                    [tooltip-marginTop]="10"
                    [tooltip-width]="250"
                >
            </div>

            <div class="settings-row-value">
                <slide-toggle [(toggled)]="dryRun"></slide-toggle>
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">
                Is your instrument a drumkit?
            </div>

            <div class="settings-row-value">
                <slide-toggle [(toggled)]="isDrumkit"></slide-toggle>
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">
                Is your instrument a guitar?
            </div>

            <div class="settings-row-value">
                <slide-toggle [(toggled)]="isGuitar"></slide-toggle>
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">
                Is your instrument a pad?
            </div>

            <div class="settings-row-value">
                <slide-toggle [(toggled)]="isPad"></slide-toggle>
            </div>
        </div>

        <div class="settings-row" *ngIf="isPad">
            <div class="settings-row-title">
                Randomize loop points (only use for pads used in a very slow BPM context)
            </div>

            <div class="settings-row-value">
                <slide-toggle [(toggled)]="randomizedLoopPoints"></slide-toggle>
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">
                Instrument's folder (must contain a tree folder)
            </div>
            
            <div class="settings-row-value">
                <a-button label="Select path" class="light" (onClick)="selectPath()" *ngIf="!pathLoading && instrumentPath == ''"></a-button>
                <span *ngIf="!pathLoading && instrumentPath != ''">{{ instrumentPath }}</span>
                <div class="loader" *ngIf="pathLoading"></div>
            </div>
        </div>

        <div class="settings-row" *ngIf="instrumentPath != ''">
            <div class="settings-row-title">
                Auto-fill from existing instrument

                <img 
                    class="tooltip-img"
                    src="assets/img/info.svg"
                    [tooltip]="tooltips.autofill"
                    [tooltip-marginTop]="10"
                    [tooltip-width]="250"
                >
            </div>
            
            <div class="settings-row-value">
                <a-button label="Select instrument" class="light" (onClick)="autoFill()"></a-button>
            </div>
        </div>

        <div class="error-message" *ngIf="error != ''">
            {{ error }}
        </div>

        <div class="buttons-container" *ngIf="instrumentPath != ''">
            <a-button width="100%" label="Next" type="primary" (onClick)="goToStep(2)"></a-button>
        </div>

        <div class="empty-item"></div>
    </div>

    <div class="step step-2" *ngIf="currentStepIndex == 2" [perfectScrollbar]="scrollConfig">
        <div class="sample-group" *ngFor="let group of metadata">
            <div class="sample-group-title">Metadata for {{ group.playingStyle }}.{{ group.articulation }}</div>

            <span *ngIf="isDrumkit">
                <div class="settings-row" *ngFor="let pitch of getKeys(group.onsetPerPitch)">
                    <div class="settings-row-title">
                        <span>Onset for pitch {{ pitch }}</span>
                    </div>
    
                    <div class="settings-row-value">
                        <input (keydown)="checkTimeInput($event, group, 'onset', pitch)" type="number" [(ngModel)]="group.onsetPerPitch[pitch]" class="light-input potion-input">
                    </div>
                </div>
            </span>

            <span *ngIf="!isDrumkit">
                <div class="settings-row">
                    <div class="settings-row-title">
                        <span *ngIf="group.articulation == 'slur'">Middle of the legato transition (in seconds)</span>
                        <span *ngIf="group.articulation != 'slur'">Onset (in seconds)</span>
                    </div>

                    <div class="settings-row-value">
                        <input (keydown)="checkTimeInput($event, group, 'onset')" type="number" [(ngModel)]="group.onset" class="light-input potion-input">
                    </div>
                </div>

                <div class="settings-row" *ngIf="(group.articulation == 'stac' || group.articulation == 'sus') && !isDrumkit">
                    <div class="settings-row-title">
                        Does it have a release?
                    </div>

                    <div class="settings-row-value">
                        <slide-toggle [(toggled)]="group.hasRelease"></slide-toggle>
                    </div>
                </div>

                <div class="settings-row" *ngIf="(group.articulation == 'stac' || (group.articulation == 'vib' && isGuitar)) && group.hasRelease">
                    <div class="settings-row-title">
                        Ignores release after (in seconds)
                    </div>

                    <div class="settings-row-value">
                        <input (keydown)="checkTimeInput($event, group, 'oneShotAfter')" type="number" [(ngModel)]="group.oneShotAfter" class="light-input potion-input">
                    </div>
                </div>

                <div class="settings-row" *ngIf="group.articulation == 'stac' && group.hasRelease">
                    <div class="settings-row-title">
                        Is the release in the same file?
                    </div>

                    <div class="settings-row-value">
                        <slide-toggle [(toggled)]="group.relIsInSameFile"></slide-toggle>
                    </div>
                </div>

                <div class="settings-row" *ngIf="group.articulation == 'sus' && group.hasRelease">
                    <div class="settings-row-title">
                        Release onset
                    </div>

                    <div class="settings-row-value">
                        <input (keydown)="checkTimeInput($event, group, 'releaseOnset')" type="number" [(ngModel)]="group.releaseOnset" class="light-input potion-input">
                    </div>
                </div>

                <div class="settings-row" *ngIf="group.articulation == 'sus'">
                    <div class="settings-row-title">
                        Loop start
                    </div>

                    <div class="settings-row-value">
                        <input (keydown)="checkTimeInput($event, group, 'loopStart')" type="number" [(ngModel)]="group.loopStart" class="light-input potion-input">
                    </div>
                </div>

                <div class="settings-row" *ngIf="group.articulation == 'sus'">
                    <div class="settings-row-title">
                        Loop end
                    </div>

                    <div class="settings-row-value">
                        <input (keydown)="checkTimeInput($event, group, 'loopEnd')" type="number" [(ngModel)]="group.loopEnd" class="light-input potion-input">
                    </div>
                </div>
            </span>
        </div>

        <div class="error-message" *ngIf="error != ''">
            {{ error }}
        </div>

        <div class="buttons-container">
            <a-button width="50%" label="Previous" type="text" (onClick)="goToStep(1)"></a-button>
            <a-button width="50%" label="Next" type="primary" (onClick)="goToStep(3)"></a-button>
        </div>

        <div class="empty-item"></div>
    </div>

    <div class="step step-3" *ngIf="currentStepIndex == 3" [perfectScrollbar]="scrollConfig">
        <div class="settings-row" *ngIf="!isDrumkit && isGuitar">
            <div class="settings-row-title">
                Add strumming (⚠️ you need nat.stac, mute.stac, deadnote.stac samples)
            </div>

            <div class="settings-row-value">
                <slide-toggle [(toggled)]="addStrumming"></slide-toggle>
            </div>
        </div>

        <div class="sample-group" *ngFor="let patch of patches">
            <div class="sample-group-title">Patch definition for {{ patch.playingStyle }}.{{ patch.articulation }}</div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Name
                </div>

                <div class="settings-row-value">
                    <input type="text" [(ngModel)]="patch.name" placeholder="E.g. Legato" class="light-input potion-input large-potion-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="settings-row-title">
                    Path
                </div>

                <div class="settings-row-value">
                    <input type="text" [(ngModel)]="patch.path" placeholder="E.g. Synthesizers/Arp/Arp 11" class="light-input potion-input large-potion-input">
                </div>
            </div>
            
            <div class="settings-row">
                <div class="settings-row-title">
                    Preview of the hierarchy:
                </div>

                <div class="settings-row-value">
                    {{ patch.path + "/" + patch.name }}
                </div>
            </div>

            <div class="settings-row" *ngIf="!isDrumkit">
                <div class="settings-row-title">
                    Granulation function
                </div>

                <div class="settings-row-value">
                    <dropdown [value]="getGranulationFunction(patch)" [sort]="true" [showTooltip]="false" [items]="granulationFunctions" (selectedItem)="selectGranulationFunction($event, patch)"></dropdown> <!--   -->
                </div>
            </div>

            <div class="settings-row" *ngIf="!isDrumkit && !isGuitar">
                <div class="settings-row-title">
                    Auto sustain pedal (only include for pianos)
                </div>

                <div class="settings-row-value">
                    <slide-toggle [(toggled)]="patch.autoSustainPedal"></slide-toggle>
                </div>
            </div>

            <div class="settings-row" *ngIf="!isDrumkit && !isGuitar">
                <div class="settings-row-title">
                    Add sidechain equivalent
                </div>

                <div class="settings-row-value">
                    <slide-toggle [(toggled)]="patch.addSidechain"></slide-toggle>
                </div>
            </div>

            <div class="settings-row" *ngIf="!isDrumkit && !isGuitar && patch.articulation == 'slur'">
                <div class="settings-row-title">
                    Add auto-staccato
                </div>

                <div class="settings-row-value">
                    <slide-toggle [(toggled)]="patch.addAutoStaccato"></slide-toggle>
                </div>
            </div>

            <div class="settings-row" *ngIf="!isDrumkit && patch.articulation == 'stac'">
                <div class="settings-row-title">
                    Add echo equivalents
                </div>

                <div class="settings-row-value">
                    <slide-toggle [(toggled)]="patch.addEcho"></slide-toggle>
                </div>
            </div>
        </div>

        <div class="error-message" *ngIf="error != ''">
            {{ error }}
        </div>
        
        <div class="buttons-container">
            <a-button width="50%" label="Previous" type="text" (onClick)="goToStep(2)"></a-button>
            <a-button width="50%" label="Next" type="primary" (onClick)="goToStep(4)"></a-button>
        </div>

        <div class="empty-item"></div>
    </div>

    <div class="step step-4" *ngIf="currentStepIndex == 4" [perfectScrollbar]="scrollConfig">
        <div class="settings-row">
            <div class="settings-row-title">
                Instrument display name
            </div>

            <div class="settings-row-value">
                <input type="text" [(ngModel)]="instrumentDisplayName" class="light-input large-potion-input">
            </div>
        </div>

        <div class="error-message" *ngIf="error != ''">
            {{ error }}
        </div>
        
        <div class="buttons-container">
            <a-button width="50%" label="Previous" type="text" (onClick)="goToStep(3)"></a-button>
            <a-button width="50%" label="Upload instrument" type="primary" (onClick)="goToStep(5)"></a-button>
        </div>
    </div>

    <div class="step step-5" *ngIf="currentStepIndex == 5" [perfectScrollbar]="scrollConfig">
        <div class="upload-title">{{ uploadingStep }}</div>
        <progressbar [progress]="uploadProgress" [total]="100"></progressbar>
    </div>

    <div class="step step-6" *ngIf="currentStepIndex == 6" [perfectScrollbar]="scrollConfig">
        You're done! 🥳<br><br>Ask Denis & Pierre to restart the deployments: Music Engine, Sampler and Creators API. 

        <div class="buttons-container">
            <a-button width="100%" label="Another one!" type="primary" (onClick)="createAnotherInstrument()"></a-button>
        </div>
    </div>
</div>