<div id="createaccount-component" class="component-container">

    <div id="login-window-wrapper">
        <div class="aiva-logo">
            <img src="assets/img/navbar/aiva_white.svg">
            <div class="aiva-logo-text">AIVA</div>
        </div>
        <div id="createaccount-window" class="window">
            <div id="title">{{ title }}</div>

            <div class="message-box" *ngIf="message.show" [ngClass]="message.type">
                <div class="close-button" (click)="hideMessage()">
                    <img src="assets/img/close.svg">
                </div>
                <div class="text"> {{ message.text }} </div>
            </div>

            <!-- Createaccount first step -->
            <div class="createaccount-first-step" *ngIf="createAccountType == 0">

                <div class="service-login-container">
                    <div class="button-container">
                        <div id="google-btn"></div>
                    </div>
                </div>

                <div class="or-seperator">
                    <hr class="horizontal-left"> <span>OR</span>
                    <hr class="horizontal-right">
                </div>

                <input placeholder="Email address" [(ngModel)]="user.email" (ngModelChange)="emailInput($event)" name="email" type="email">

                <div id="button-container" *ngIf="captcha">
                    <re-captcha #captchaRef="reCaptcha" siteKey="{{ getCaptchaKey() }}" size="invisible" (resolved)="createAccount($event)">
                    </re-captcha>
                
                    <div class="loader" *ngIf="loading"></div>
                    <div class="round-button" (click)="captchaRef.execute()" *ngIf="!loading">Continue</div>
                </div>
                
                <div id="button-container" *ngIf="!captcha">
                    <div class="loader" *ngIf="loading"></div>
                    <div class="round-button" (click)="createAccount('test')" *ngIf="!loading">Continue</div>
                </div>

                <div class="additional-options">
                    <div class="go-to-login" (click)="goToLogin()"><u>Sign in with an existing account</u></div>
                </div>

            </div>

            <!-- TNC in case you used Google -->
            <div class="createaccount-final-step" *ngIf="createAccountType == 1">

                <div id="terms-and-conditions">
                    <input type="checkbox" [(ngModel)]="user.tnc" placeholder="I have read the T&C + Privacy Policy" (change)="tncChange(user.tnc)">
                    <span>
                        I agree to the <a href='https://www.aiva.ai/legal/1'>EULA</a> and <a href='https://www.aiva.ai/legal/0'>Privacy
                        Policy</a>
                    </span>
                </div>
                <!--<re-captcha (resolved)="resolved($event)" siteKey="6Leuzq4UAAAAAIawqiYpAbeHIDvh4b0eLhdimOSW" class="recaptcha"></re-captcha>-->
                <div id="button-container" *ngIf="captcha">
                    <re-captcha #captchaRef="reCaptcha" siteKey="{{ getCaptchaKey() }}" size="invisible"
                        (resolved)="createAccountWithGoogle($event)">
                    </re-captcha>

                    <div class="loader" *ngIf="loading"></div>
                    <div class="round-button" (click)="captchaRef.execute()" *ngIf="!loading">Create account</div>
                </div>

                <div id="button-container" *ngIf="!captcha">
                    <div class="loader" *ngIf="loading"></div>
                    <div class="round-button" (click)="createAccountWithGoogle('test')" *ngIf="!loading">Create account</div>
                </div>

                <div class="text-button" (click)="goToCreateAccount()">Cancel</div>
            </div>

            <small class="re-captcha mobile-only">This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </small>
        </div>
    </div>
</div>

<small class="re-captcha desktop-only">This site is protected by reCAPTCHA and the Google
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
</small>