<div class="melody-tile-content-block {{ class }}" *ngIf="type == 'melody'">
    <div class="loader" *ngIf="melodyPackPreviewIsLoading()"></div>

    <canvas
        class="pack-preview"
        id="canvas-melody"
        *ngIf="!melodyPackPreviewIsLoading()"></canvas>

    <div class="pack-instruments">
        <div
            *ngIf="pack.instruments.length == 0"
            class="no-instruments-assigned">
            No instrument assigned to the Melody.
        </div>

        <div
            class="instrument"
            *ngFor="let instrument of getTop3Instruments(pack.instruments)">
            <a-button
                type="round-icon-and-text"
                width="100%"
                [label]="instrument.name"
                [loading]="isPreviewLoading(instrument)"
                [icon]="getPlaybackIcon(instrument)"
                (onClick)="preview(instrument)"></a-button>
        </div>

        <div class="additional-instruments" (click)="editInstruments(0)">
            <span class="additional-instruments-title">
                <span *ngIf="pack.instruments.length > 0">Edit</span
                ><span *ngIf="pack.instruments.length == 0">Add</span>
                instruments<span *ngIf="pack.instruments.length > 3">
                    (+ {{ pack.instruments.length - 3 }} more)</span
                >
            </span>
        </div>
    </div>
</div>

<div
    *ngIf="type == 'accompaniment'"
    class="accompaniment-pack-card {{ class }}">
    <div class="accompaniment-pack-title-container">
        <div class="accompaniment-pack-title">
            {{ pack.name }}
            <div class="loader" *ngIf="showLoading()"></div>
            <img
                src="assets/img/player/retry.svg"
                class="retry-saving-pack"
                *ngIf="showRetry()"
                (click)="retrySavingPack()" />

            <div
                class="accompaniment-synchronisation-subtitle"
                *ngIf="isSynced()">
                <img src="assets/img/menu/link.svg" />
            </div>
            <img
                *ngIf="validation != null && validation.valid == false"
                class="tooltip-img"
                src="assets/img/warning_red.svg"
                [tooltip]="validation.message"
                [tooltip-class]="'tooltip-error'"
                [tooltip-marginTop]="10"
                [tooltip-width]="250" />
        </div>

        <img
            class="accompaniment-pack-menu"
            (click)="selectPack($event)"
            src="assets/img/pianoroll/settings.svg" />
    </div>

    <canvas
        class="pack-preview"
        id="canvas-{{ layer.name }}-{{ index }}"></canvas>

    <div class="pack-instruments">
        <div
            *ngIf="pack.instruments.length == 0"
            class="no-instruments-assigned">
            No instrument assigned to pack.
        </div>

        <div
            class="instrument"
            *ngFor="let instrument of getTop3Instruments(pack.instruments)">
            <a-button
                type="round-icon-and-text"
                width="100%"
                [label]="instrument.name"
                [loading]="isPreviewLoading(instrument)"
                [icon]="getPlaybackIcon(instrument)"
                (onClick)="preview(instrument)"></a-button>
        </div>

        <div class="additional-instruments" (click)="editInstruments(0)">
            <span class="additional-instruments-title">
                <span *ngIf="pack.instruments.length > 0">Edit</span
                ><span *ngIf="pack.instruments.length == 0">Add</span>
                instruments<span *ngIf="pack.instruments.length > 3">
                    (+ {{ pack.instruments.length - 3 }} more)</span
                >
            </span>
        </div>
    </div>
</div>

<div *ngIf="type == 'mini-pack'" class="mini-pack-canvas-container {{ class }}">
    <canvas class="mini-pack-canvas" id="canvas-mini-pack-{{ index }}"></canvas>

    <div class="mini-pack-title">
        {{ pack.name }}
    </div>

    <a-button
        *ngIf="enableAccompanimentDesigner() && pack?.template"
        type="round-icon-and-text"
        class="rounded-square layer-options"
        [icon]="'assets/img/more.svg'"
        (click)="selectTemplatePack($event)">
    </a-button>
    <!-- <img 
        *ngIf="enableAccompanimentDesigner() && pack?.template"
        class="template-pack-menu-img"
        src="assets/img/more.svg"
        (click)="selectTemplatePack($event)"
    > -->
</div>

<menu-options
    *ngIf="menuOptions !== undefined"
    [menuOptions]="menuOptions"
    (close)="unselectPack()"></menu-options>
