<div
    id="menu-bgrd"
    class="menu-background"
    (clickOutside)="handleClickOutside($event)"
    [clickOutsideEvents]="'mousedown,touchstart'"
    #contextMenu
    [style.left.px]="coordinates.x"
    [style.top.px]="coordinates.y"
    [style.width]="width">
    <ng-content select="[contentFromParent]"></ng-content>
</div>
