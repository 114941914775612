<div class="wrapper" [ngStyle]="{ 'line-height': lineHeight }">
    <ng-container *ngFor="let option of options; let o = index">
        <button
            class="option"
            [ngClass]="{
                'option--selected': option.value === selectedOption.value,
                'left-round-corner': leftRoundCorner(o),
                'right-round-corner': rightRoundCorner(o)
            }"
            [attr.av-data]="avData + o"
            [ngStyle]="{
                'line-height': lineHeight,
                width: widthPerCell + 'px'
            }"
            (click)="setSelected(option)">
            {{ option.value }}
        </button>
    </ng-container>
</div>
