<div
    *ngIf="
        (engine.toggledLayer$ | async)?.type === 'percussion' &&
        (engine.selectedPattern$ | async)
    "
    class="sequencer-container">
    <div class="top-bar" *ngIf="showPatternSelectionBar">
        <div class="top-bar-left">
            <dropdown
                type="light"
                [showArrow]="true"
                class="percussion-pattern"
                [value]="getSelectedPattern()"
                [description]="''"
                [showTooltip]="false"
                [items]="getAllPatterns()"
                (selectedItem)="selectPattern($event)">
            </dropdown>

            <img
                class="top-bar-left-settings settings"
                src="assets/img/pianoroll/settings.svg"
                (click)="openPatternSettings($event, false)" />
        </div>
        <div class="top-bar-right">
            <dropdown
                type="light"
                [showArrow]="true"
                class="note-resolution"
                [value]="getNoteRes()"
                [description]="''"
                [showTooltip]="false"
                [items]="noteResolutionOptions"
                (selectedItem)="selectResolution($event)"
                tooltip="Change the resolution of the selected pattern"
                tooltip-width="200"
                #noteResolutionDropdown>
            </dropdown>
            <dropdown
                type="light"
                [showArrow]="true"
                class="note-resolution"
                [value]="getNumberOfBars()"
                [description]="''"
                [showTooltip]="false"
                [items]="getNumberOfBarsOptions()"
                (selectedItem)="selectNumberOfBars($event)"
                tooltip="Change the number of bars of the selected pattern"
                tooltip-width="200">
            </dropdown>
        </div>
    </div>

    <div id="pattern-horizontal-scrollbar"></div>

    <div>
        <div
            class="channel-container"
            *ngFor="let tb of (engine.toggledLayer$ | async).trackBuses">
            <div class="channel-top" (click)="toggleTrackBus(tb)">
                <img
                    class="channel-toggle"
                    src="assets/img/pianoroll/toggled.svg"
                    *ngIf="!hiddenTrackBuses.includes(tb)" />
                <img
                    class="channel-toggle"
                    src="assets/img/pianoroll/toggle.svg"
                    *ngIf="hiddenTrackBuses.includes(tb)" />
                <div class="channel-title">{{ tb.reference.full_name }}</div>
                <div class="channel-button-wrapper">
                    <a-button
                        label="New Channel"
                        class="editor-button editor-button-small"
                        icon="assets/img/add.svg"
                        (click)="addChannel($event, tb)"
                        tooltip="Create a new channel for {{
                            tb.reference.full_name
                        }}"
                        tooltip-width="150"></a-button>
                </div>
            </div>
            <div class="channel-row" *ngIf="!hiddenTrackBuses.includes(tb)">
                <div>
                    <div
                        *ngFor="
                            let channel of (
                                engine.selectedPattern$ | async
                            ).getChannelsForTrackbus(tb)
                        "
                        class="channel-object-row-container">
                        <dropdown
                            type="light"
                            [showArrow]="true"
                            class="percussion-pattern"
                            [value]="getChannelName(channel, tb)"
                            [description]="''"
                            [showTooltip]="false"
                            [items]="getChannelOptions(tb)"
                            (selectedItem)="selectChannel(channel, $event)">
                        </dropdown>

                        <img
                            class="settings"
                            src="assets/img/pianoroll/settings.svg"
                            (click)="
                                openChannelSettings(channel, $event, false)
                            "
                            tooltip="Channel settings" />

                        <div class="playback-buttons">
                            <div
                                class="playback-button-mute"
                                [ngClass]="{ 'mute-selected': channel.mute }"
                                (click)="muteChannel(channel)"
                                tooltip="Mute">
                                M
                            </div>

                            <div
                                class="playback-button-solo"
                                [ngClass]="{ 'solo-selected': channel.solo }"
                                (click)="soloChannel(channel)"
                                tooltip="Solo">
                                S
                            </div>
                        </div>
                    </div>

                    <div
                        class="start-add-channel"
                        *ngIf="
                            (
                                engine.selectedPattern$ | async
                            ).getChannelsForTrackbus(tb).length === 0
                        ">
                        Start by adding a new channel to your pattern
                    </div>
                </div>

                <div
                    class="drum-sequencer-canvas"
                    id="drum-sequencer-{{ tb.id }}"></div>
            </div>
        </div>
    </div>
</div>

<menu-options
    *ngIf="patternSettings !== undefined"
    [menuOptions]="patternSettings"
    (close)="openPatternSettings($event, true)"></menu-options>

<menu-options
    *ngIf="channelSettings !== undefined"
    [menuOptions]="channelSettings"
    (close)="openChannelSettings(undefined, $event, true)"></menu-options>
