import { Query, combineQueries } from '@datorama/akita'
import { Observable } from 'rxjs'
import { CursorType } from '../../../../general/types/note-editing'
import { GridDimensions, GridDimensionsDictionary } from '../../types'
import { ScoreRenderingQuery } from '../score-rendering/score-rendering.query'
import { ScoreRendering } from '../score-rendering/score-rendering.store'
import { EditorViewStore, EditorViewState } from './editor-view.store'

export class EditorViewQuery extends Query<EditorViewState> {

    /**
     * Combines all changes to the editor view state as well as the score rendering state
     * The reason for combining the queries is that some parts of the score rendering state
     * are necessary for components
     */
    get allChanges$ (): Observable<[EditorViewState, ScoreRendering]> {
        return combineQueries([
            this.editorViewChanges$, 
            this.scoreRenderingQuery.allScoreRenderingStates$ 
           ]
        )
    }

    get grid(): GridDimensionsDictionary | undefined {
        return this.getValue().grid
    }

    get followTimelineCursor(): boolean {
        return this.getValue().followTimelineCursor
    }

    get timelineCursorElement(): HTMLDivElement | undefined {
        return this.getValue().timelineCursorElement
    }

    get editorViewChanges$ (): Observable<EditorViewState> {
        return this.select()
    }

    get cursorType$(): Observable<CursorType> {
        return this.select("cursorType")
    }

    get cursorType() : CursorType {
        return this.getValue().cursorType
    }

    get accompanimentDesignerIsFocused() {
        return this.getValue().accompanimentDesignerIsFocused
    }

    constructor(protected store: EditorViewStore, private scoreRenderingQuery: ScoreRenderingQuery) {
        super(store)
    }
}