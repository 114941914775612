import { Query, Store, StoreConfig } from "@datorama/akita"
import { Note } from "../../../general/classes/score/note"

export type IClipboardData =
    | {
          [key in IClipboardDataType]: IClipboardValue
      }
    | {}

export enum IClipboardDataType {
    NOTES = "notes",
}

export type IClipboardDataOptions = {
    noteRegionDuration: string
    noteRegionStart: string
    noteRegionEnd: string
}

export type IClipboardValue = Note[] | {}

export interface ClipboardState {
    clipboard: IClipboardData
    options:
        | {
              [key in IClipboardDataType]: IClipboardDataOptions
          }
        | {}
}

export const createInitialState = (): ClipboardState => ({
    clipboard: {},
    options: {},
})

@StoreConfig({ name: "Clipboard", resettable: false })
export class ClipboardStore extends Store<ClipboardState> {
    constructor() {
        super(createInitialState())
    }
}

export class ClipboardQuery extends Query<ClipboardState> {
    public allState$ = this.select()
    public clipboard$ = this.select("clipboard")
    constructor(protected store: ClipboardStore) {
        super(store)
    }

    public get clipboard() {
        return this.getValue().clipboard
    }

    public getClipboardFor(type: IClipboardDataType): IClipboardValue {
        return this.getValue().clipboard[type]
    }

    public get clipboardOptions() {
        return this.getValue().options
    }

    public getOptionsFor(type: IClipboardDataType): IClipboardDataOptions {
        return this.getValue().options[type]
    }
}

export class ClipboardActions {
    constructor(private store: ClipboardStore, private query: ClipboardQuery) {}

    public copy(
        type: IClipboardDataType,
        value: IClipboardValue,
        options?: IClipboardDataOptions
    ) {
        this.store.update(state => ({
            ...state,
            clipboard: {
                ...state.clipboard,
                [type]: value,
            },
            options: {
                ...state.options,
                [type]: options,
            },
        }))
    }
}

export const clipboardStore = new ClipboardStore()
export const clipboardQuery = new ClipboardQuery(clipboardStore)
export const clipboardActions = new ClipboardActions(
    clipboardStore,
    clipboardQuery
)
