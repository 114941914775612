<div
    class="context-menu instrument-palette-more-options"
    (clickOutside)="closeContextMenu()"
    [clickOutsideEvents]="'click,contextmenu'">
    <div class="context-menu-background"></div>

    <div
        class="right-click-menu-item download-button animate-0_1s"
        (click)="addDouble()">
        Add doubling instrument
    </div>

    <div
        class="right-click-menu-item download-button animate-0_1s"
        (click)="addFolder()">
        Add folder
    </div>
</div>
