import { Component, OnInit } from '@angular/core'
import { ApiService } from '../../services/api.service'
import { TokenService } from '../../services/token.service'
import { FolderService } from '../../services/folder.service'
import { Router, ActivatedRoute } from '@angular/router'
import { HelperService } from '../../helpers/helper.service'
import { Misc } from '@common-lib/modules/misc'

@Component({
  	selector: 'app-reset',
  	templateUrl: 'reset.component.html',
  	styles: []
})
export class ResetComponent implements OnInit {
	confirmNewPassword = ''
    newPassword = ''
	resetToken = ''
	captchaResponse:string = ''
	resetType = 0;	// 0 is user forgot password and resets, 1 is user creates new password for new account
	tncMandatory:boolean = true;
	tnc:boolean = false;
	title: string = "Set up new password"
	buttonText:string = "Reset password"
	loading:boolean = false;
	message: { type?: string, text?: string, show?: boolean } = { type: "", text: "", show: false }

  	constructor(private apiService:ApiService, private folderService:FolderService, private route:ActivatedRoute, private tokenService:TokenService, private router:Router, private helper: HelperService) { }

  	ngOnInit() {
		this.route.params.subscribe(params => {

			this.resetToken = params['token']
			let decodedJWT = this.helper.decodeJwt(this.resetToken)

			if (!this.helper.isObject(decodedJWT)){
				this.goToLogin()
			} else {
				// Check if user creates new password or if he resets it
				if (decodedJWT.hasOwnProperty('onboarded')){
					this.initNewUser()
				} else {
					this.initExistingUser()
				}
			}
			
		})
	}

	setupNewPassword():void {
		this.hideMessage()
		this.loading = true;
		
		if(this.tncMandatory) {
			if (!this.tnc) {
				this.showMessage("error", "You must accept the Terms & Conditions to create an account.")
				this.loading = false;
				return
			}
		}
		
        const params = { token: this.resetToken, newPassword: this.newPassword, confirmNewPassword: this.confirmNewPassword, captchaResponse: this.captchaResponse, tnc: this.tnc }
        this.apiService.request('/user/resetPassword', params, "primary", "post", true).then(async (res) => {
			this.loading = false;
			this.tokenService.setToken(res.token)
			this.tokenService.setRefreshToken(res.refreshToken)

			await this.tokenService.generateToken()

			this.apiService.setUser(res["email"], "password")

			await Misc.wait(1)
		
			this.router.navigate(['/'])

			this.apiService.isLoggedIn.next(true)
		})

        .catch((err) => {
			// this.apiService.handleError(err)
			this.loading = false;
			this.showMessage("error", err)
		})
	}

	onKeyDown(event:any):void {
		if (event.keyCode == 13) {
			
		}
	}

	onInput(value){
		if (value && value != "") this.hideMessage()
	}

	showMessage(type: string, text: string) {
		if (type && text) this.message = { type: type, text: text, show: true }
	}
	hideMessage() {
		this.message = { type: "", text: "", show: false }
	}

	initNewUser(){
		this.resetType = 1
		this.tncMandatory = true;
		this.buttonText = "Create account"
	}

	initExistingUser(){
		this.resetType = 0
		this.tncMandatory = false;
		this.buttonText = "Reset password"
	}

    goToLogin() {
        this.router.navigate(['/login'])
	}
	
	resolved(captchaResponse: string) {
		this.captchaResponse = captchaResponse
	}
}