import { RangeWithID } from "../../../general/interfaces/general"
import {
    NOTE_QUANTIZATION_THRESHOLDS,
    TIMESTEP_RES,
} from "../../../general/constants/constants"
import { HoveringType, HoveringTypeEnum } from "../../../general/types/general"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { SRActionTypes } from "../states/score-rendering/score-rendering.actions"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    CanvasType,
    TrackbusRegionsCanvasContext,
    GridDimensionsDictionary,
    HoveredElement,
} from "../types"
import ScoreCanvas from "./score-canvas"
import { Coordinates } from "../../../general/modules/event-handlers"
import { ScoreManipulation } from "../../../general/modules/scoremanipulation"
import { Time } from "../../../general/modules/time"
import { firstValueFrom } from "rxjs"
interface Range {
    start: number
    end: number
}

interface TrackbusRegionsManipulationFunction {
    (px: Range, ts: RangeWithID): boolean
}
export class TrackbusRegionsCanvas extends ScoreCanvas {
    static HANDLES_WIDTH = 2
    static HANDLES_OFFSET_X = 4
    static HANDLES_OFFSET_Y = 14
    static INSTRUMENT_HEIGHT = 50

    constructor(
        public context: TrackbusRegionsCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)

        this.initListeners()
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        const shouldRenderCanvas = this.shouldRenderCanvas(
            scoreState.renderingType
        )

        if (!scoreState.score || !shouldRenderCanvas) {
            return
        }

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: grid,
            noteRes: grid.pitched.timestepRes,
            nbOfYValues: 0,
        })

        let firstStart: number | undefined = undefined

        const selectedTBRegions: { px: Range; ts: RangeWithID }[] = []
        const unselectedTBRegions: { px: Range; ts: RangeWithID }[] = []

        this.iterateOverBlocks((px: Range, ts: RangeWithID) => {
            if (firstStart === undefined || firstStart > px.start) {
                firstStart = px.start
            }

            if (this.isSelectedRegion(ts.id)) {
                selectedTBRegions.push({
                    px,
                    ts,
                })
            } else {
                unselectedTBRegions.push({
                    px,
                    ts,
                })
            }

            return true
        })

        const regions = unselectedTBRegions.concat(selectedTBRegions)

        for (const tb of regions) {
            this.drawBlock(tb.px.start, tb.px.end, tb.ts.id)
            this.drawHandles(tb.px.start, tb.px.end)
        }

        if (this.context.trackBus.blocks.length === 0 || firstStart > 525) {
            this.renderOverlay()
        }
    }

    protected renderOverlay() {
        const ctx = this.getContext("canvas")

        ScoreCanvas.drawText(
            ctx,
            255 - this.scrollXOffset,
            30,
            "Enable 'Pencil Mode' and click in this area to assign this instrument to play notes.",
            85,
            "",
            "white"
        )
    }

    private initListeners() {
        this.mouseDown$.subscribe(this.mouseDownHandler.bind(this))

        this.move$.subscribe(event => {
            this.mouseMoveHandler(event, "move")
        })

        this.resize$.subscribe(event => {
            this.mouseMoveHandler(event, "resize")
        })

        this.hoveredDetection$.subscribe((event: Coordinates) => {
            if (this.mouseDownStart) {
                return
            }

            const hovered = this.hoveredDetectionHandler(event)
            this.setHoveredElement(hovered)
        })
    }

    protected async mouseDownHandler(event: Coordinates) {
        const pencilMode = this.queries.editorView.cursorType === "pencil"

        let hovered = this.hoveredDetectionHandler(event)
        this.setHoveredElement(hovered)

        if (!hovered && !pencilMode) {
            return this.unselectTrackBusRegion()
        }

        super.mouseDownHandler(event, this.getHoveringType.bind(this))

        if (this.mouseDownStart === undefined) {
            return
        }

        this.srEmitter$.next({
            type: SRActionTypes.startNoteManipulation,
            options: {
                isUndoable: true,
            },
        })

        if (!hovered && !pencilMode) {
            return
        } else if (!hovered && pencilMode) {
            hovered = await this.startAddingTrackBusRegion()
        }

        this.mouseDownStart.mouseDownLocation = hovered.draggingType
        this.srEmitter$.next({
            type: SRActionTypes.selectTrackBusRegion,
            data: {
                region: <RangeWithID>hovered?.element,
            },
        })
    }

    protected mouseUpHandler(event: Coordinates) {
        if (this.mouseDownStart === undefined) {
            return
        }

        const trackBusses = ScoreManipulation.getTrackBussesWithSelectedRegions(
            {
                layer: this.queries.scoreRendering.toggledLayer,
                selectedRegions:
                    this.queries.scoreRendering.selectedTrackBusRegion.map(
                        r => {
                            return r.region
                        }
                    ),
            }
        )

        this.srEmitter$.next({
            type: SRActionTypes.stopResizingTrackBusRegions,
            data: {
                tbs: trackBusses,
            },
        })

        const hoveringType = this.getHoveringType(event)

        if (
            this.queries.editorView.cursorType === "pencil" &&
            hoveringType?.type === HoveringTypeEnum.CENTER &&
            this.mouseDownStart !== undefined &&
            this.mouseDownStart.start.grid.timesteps ===
                this.mouseDownStart.last.grid.timesteps
        ) {
            this.breakUpTrackBusRegions(event)
        }

        super.mouseUpHandler(event)
    }

    private unselectTrackBusRegion() {
        this.srEmitter$.next({
            type: SRActionTypes.selectTrackBusRegion,
            data: {
                region: undefined,
            },
        })
    }

    private async startAddingTrackBusRegion() {
        this.mouseDownStart.mouseDownLocation = HoveringTypeEnum.RIGHT

        this.srEmitter$.next({
            type: SRActionTypes.createTrackBusRegion,
            data: {
                timesteps: this.mouseDownStart.last.grid.timesteps,
                trackBus: this.context.trackBus,
            },
        })

        await firstValueFrom(this.queries.scoreRendering.select("selectedData"))

        const newRegion = this.queries.scoreRendering.selectedTrackBusRegion[0]
        const hovered = {
            element: newRegion.region,
            draggingType: HoveringTypeEnum.RIGHT,
            type: "rangeWithID",
        } as HoveredElement

        this.mouseDownStart.last.grid.timesteps =
            Time.convertTimestepsToAnotherRes(
                newRegion.region.end,
                TIMESTEP_RES,
                this.noteRes
            )

        this.setHoveredElement(hovered)

        return hovered
    }

    private breakUpTrackBusRegions(event: Coordinates) {
        const grid = this.getGridCoordinates(event, false)

        this.srEmitter$.next({
            type: SRActionTypes.breakUpTrackBusRegions,
            data: {
                timesteps: grid.timesteps,
                trackBus: this.context.trackBus,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    private hoveredDetectionHandler(event: Coordinates) {
        try {
            let hoveredElement: HoveredElement | undefined = undefined

            const cursorStyle =
                this.queries.editorView.cursorType === "pencil"
                    ? "pencil"
                    : "default"

            this.setCanvasCursor(cursorStyle)

            const result = this.getHoveringType(event)

            if (!result) {
                return undefined
            }

            if (result.type !== HoveringTypeEnum.CENTER) {
                this.setCanvasCursor("col-resize")
            }

            hoveredElement = {
                element: result.element,
                draggingType: result.type,
                type: "rangeWithID",
            } as HoveredElement

            return hoveredElement
        } catch (e) {
            return this.hoveredElement
        }
    }

    protected getHoveringType(event: Coordinates):
        | {
              element: RangeWithID
              type: HoveringType
          }
        | undefined {
        const x = event.x

        let block
        let type: HoveringType = HoveringTypeEnum.CENTER

        this.iterateOverBlocks((px: Range, ts: Range) => {
            if (x >= px.start && x < px.end) {
                block = ts

                const handles = this.getHandles(px.start, px.end)

                const typeLeft =
                    x < px.start + handles.width + handles.offset * 2

                const typeRight =
                    x > px.end - handles.width - handles.offset * 2

                if (typeLeft) {
                    type = HoveringTypeEnum.LEFT
                } else if (typeRight) {
                    type = HoveringTypeEnum.RIGHT
                }

                return false
            }

            return true
        })

        if (block) {
            return {
                element: block,
                type: type,
            }
        }

        return undefined
    }

    private mouseMoveHandler(event: Coordinates, type: "move" | "resize") {
        const r = this.moveHandler(event, {
            type,
            thresholds: NOTE_QUANTIZATION_THRESHOLDS,
            quantize: true,
        })

        if (
            !r ||
            this.hoveredElement === undefined ||
            this.mouseDownStart === undefined
        ) {
            return
        }

        const element: RangeWithID = <RangeWithID>this.hoveredElement.element

        if (type === "move") {
            this.srEmitter$.next({
                type: SRActionTypes.moveTrackBusRegion,
                data: {
                    id: element.id,
                    timestepOffset: Time.convertTimestepsToAnotherRes(
                        r.diffTimesteps,
                        this.noteRes,
                        TIMESTEP_RES
                    ),
                    trackBus: this.context.trackBus,
                },
                options: {
                    isUndoable: false,
                },
            })
        } else if (
            type === "resize" &&
            this.mouseDownStart.mouseDownLocation !== "center"
        ) {
            this.srEmitter$.next({
                type: SRActionTypes.resizeTrackBusRegion,
                data: {
                    side: this.mouseDownStart.mouseDownLocation,
                    timestepOffset: Time.convertTimestepsToAnotherRes(
                        r.diffTimesteps,
                        this.noteRes,
                        TIMESTEP_RES
                    ),
                    trackBus: this.context.trackBus,
                },
                options: {
                    isUndoable: false,
                },
            })
        }

        if (this.queries.scoreRendering.selectedTrackBusRegion.length === 0) {
            return
        }

        this.hoveredElement.element =
            this.queries.scoreRendering.selectedTrackBusRegion[0].region
        this.mouseDownStart.mouseDownLocation =
            this.queries.scoreRendering.selectedTrackBusRegion[0].side
    }

    private iterateOverBlocks(
        manipulateFunction: TrackbusRegionsManipulationFunction
    ) {
        for (const b of this.context.trackBus.blocks) {
            const block: RangeWithID = {
                id: b.id,
                start: Time.convertTimestepsToAnotherRes(
                    b.start,
                    TIMESTEP_RES,
                    this.noteRes
                ),
                end: Time.convertTimestepsToAnotherRes(
                    b.end,
                    TIMESTEP_RES,
                    this.noteRes
                ),
            }

            if (block.end <= this.scrollToTimestep) {
                continue
            }

            if (
                block.start >=
                this.scrollToTimestep + this.getTimestepRange()
            ) {
                break
            }

            const start =
                ScoreCanvas.getPixelsForTimesteps({
                    timesteps: block.start,
                    timestepRes: this.noteRes,
                    grid: this.grid,
                    removeLastGutterPx: "none",
                }) - this.scrollXOffset

            const end =
                ScoreCanvas.getPixelsForTimesteps({
                    timesteps: block.end,
                    timestepRes: this.noteRes,
                    grid: this.grid,
                    removeLastGutterPx: "none",
                }) - this.scrollXOffset

            if (!manipulateFunction({ start, end }, block)) {
                return
            }
        }
    }

    private getHandles(start: number, end: number) {
        let handlesWidth = TrackbusRegionsCanvas.HANDLES_WIDTH
        let handlesOffset = TrackbusRegionsCanvas.HANDLES_OFFSET_X

        if (end - start < (handlesWidth + handlesOffset) * 2) {
            handlesWidth = 1
            handlesOffset = 1
        }

        return {
            width: handlesWidth,
            offset: handlesOffset,
        }
    }

    private drawHandles(start, end) {
        const ctx = this.getContext("canvas")
        const handles = this.getHandles(start, end)

        ctx.beginPath()

        ScoreCanvas.roundRect(
            ctx,
            start + handles.offset,
            TrackbusRegionsCanvas.HANDLES_OFFSET_Y,
            handles.width,
            TrackbusRegionsCanvas.INSTRUMENT_HEIGHT -
                TrackbusRegionsCanvas.HANDLES_OFFSET_Y * 2,
            2,
            "#0b033d"
        )

        ctx.fillStyle = "#0b033d"
        ctx.fill("evenodd")
        ctx.closePath()

        ctx.beginPath()

        ScoreCanvas.roundRect(
            ctx,
            end - handles.offset - handles.width,
            TrackbusRegionsCanvas.HANDLES_OFFSET_Y,
            handles.width,
            TrackbusRegionsCanvas.INSTRUMENT_HEIGHT -
                TrackbusRegionsCanvas.HANDLES_OFFSET_Y * 2,
            2,
            "#0b033d"
        )

        ctx.fillStyle = "#0b033d"
        ctx.fill("evenodd")
        ctx.closePath()
    }

    private isSelectedRegion(id: string) {
        const isSelected =
            this.queries.scoreRendering.selectedTrackBusRegion.some(
                r => r.region.id === id
            )

        return isSelected
    }

    private drawBlock(start: number, end: number, id: string) {
        const ctx = this.getContext("canvas")

        const color = this.isSelectedRegion(id)
            ? this.context.layer.getOppositeColor()
            : this.context.layer.getColor()

        ctx.beginPath()
        ScoreCanvas.roundRect(
            ctx,
            start,
            0,
            end - start,
            TrackbusRegionsCanvas.INSTRUMENT_HEIGHT,
            4,
            color
        )

        ctx.fillStyle = color
        ctx.fill("evenodd")
        ctx.closePath()
    }
}
