import AccompanimentLayer from "./accompaniment/accompanimentlayer"
import GPSettings from "./gpsettings"
import MelodyLayer from "./melody/melodylayer"
import Harmony from "./harmony/harmony"
import HarmonyPack from "./harmony/harmonypack"
import MelodyPack from "./melody/melodypack"
import AccompanimentPack from "./accompaniment/accompanimentpack"
import InstrumentPatch from "../score/instrumentpatch"
import Patch from "../score/patch"
import GPLayer from "./gplayer"
import GPValidation from "./gpvalidation"
import { Misc } from "../../modules/misc"
import { gpOptions } from "../../constants/gp_options"
import GPInfluenceLoading from "./influences/gpinfluenceloading"
import FormDescription from "./formdescription/formdescription"
import { defaultFormTag } from "./formdescription/tagsToDescriptionMapping"
import {
    AccompanimentLayersSchema,
    GPAccompanimentPackSchema,
    FormDescriptionSchema,
    GenerationProfileSchema,
    GPInfluenceLoadingSchema,
    GPMixingSchema,
    GPSettingsSchema,
    HarmonySchema,
    InstrumentsSchema,
    MelodyLayerSchema,
} from "../../interfaces/db-schemas/generationprofile"
import {
    FormDescriptionPacks,
    GenerationProfileMusicEngine,
} from "../../interfaces/music-engine/generationprofile"
import { StructureSettings } from "./formdescription/structuresettings"
import { VariationSettings } from "./formdescription/variationsettings"
import { GPSourcePacks } from "../../interfaces/gp/gpSourcePacks"
import { GPMixing } from "./gpmixing"
import { cloneDeep } from "lodash"
import { ME_MODES_TO_CREATORS_MODES_MAPPING } from "../../constants/constants"

interface SourcePacks {
    harmonyPacks: HarmonyPack[]
    melodyPacks: MelodyPack[]
    accompanimentPacks: AccompanimentPack[]
}

const camelCase = require("camelcase")
export default class GenerationProfile {
    static COMPOSITION_NUMBER = [1, 2, 3, 4, 5]
    static SIMILARITY_WEIGHTS = {
        settings: 0.2,
        harmony: 0.2,
        melody: 0.2,
        accompaniments: 0.5,
    }

    _id
    publishedID: string
    userID
    name: string = ""
    melodyLayer: MelodyLayer
    harmony: Harmony
    accompanimentLayers: Array<AccompanimentLayer> = []
    settings: GPSettings
    formDescription: FormDescription
    shared: boolean = false
    createdByAiva: boolean = false
    deleted: boolean = false
    liked: boolean = false
    creationDate: number
    folderID: string = ""
    deprecated: boolean = false
    similarity: number
    sourceID: string = ""
    originalSourceID: string = null // refers to the ID of the GP in the library this was sourced from
    libraryGP

    constructor(name: string) {
        this.name = name

        this.settings = new GPSettings()
        this.harmony = new Harmony()
        this.formDescription = new FormDescription()
    }

    static fromJSON(object): GenerationProfile {
        let gp: GenerationProfile = Object.assign(
            new GenerationProfile(""),
            object
        )
        gp.melodyLayer = MelodyLayer.fromJSON(object.melodyLayer)

        gp.harmony = Harmony.fromJSON(object.harmony)
        gp.accompanimentLayers = []

        for (let accompanimentlayer of object.accompanimentLayers) {
            gp.accompanimentLayers.push(
                AccompanimentLayer.fromJSON(accompanimentlayer)
            )
        }

        gp.settings = GPSettings.fromJSON(object.settings)
        gp.formDescription = FormDescription.fromJSON(object.formDescription)

        return gp
    }

    createInfluenceLoading(
        gpComponentName: string,
        influenceID,
        influenceLoading?: GPInfluenceLoading
    ) {
        if (!influenceLoading) {
            influenceLoading = new GPInfluenceLoading(
                influenceID == "" ? 0 : 15,
                influenceID
            )
        }

        let component = this.getComponent(gpComponentName)

        component.gpInfluenceLoading = influenceLoading

        return influenceLoading
    }

    resetInfluenceLoading(gpComponent) {
        let component = this.getComponent(gpComponent)

        if (component != null) {
            component.gpInfluenceLoading = new GPInfluenceLoading(0, "")
        }
    }

    alreadyHasLoadingInfluenceForLayer(gpComponent) {
        let component = this.getComponent(gpComponent)

        if (
            component == null ||
            component.gpInfluenceLoading == null ||
            !component.gpInfluenceLoading.influenceID
        ) {
            return false
        }

        return component.gpInfluenceLoading.progress < 100
    }

    getComponent(gpComponentName: string) {
        let components = this.getComponents()

        for (let component of components) {
            if (component.name == gpComponentName) {
                return component
            }
        }

        return null
    }

    static getAccompanimentPackIDsFromGPSchema(
        gp: GenerationProfileSchema
    ): string[] {
        const accompanimentPackIDs: string[] = []

        if (gp.accompanimentLayers !== undefined) {
            for (let layer of gp.accompanimentLayers) {
                for (let pack of layer.packs) {
                    if (!accompanimentPackIDs.includes(pack.packID)) {
                        accompanimentPackIDs.push(pack.packID)
                    }
                }
            }
        }

        return accompanimentPackIDs
    }

    static getMaxTempo(settings: GPSettings): number {
        if (
            settings.formTags.length == 1 &&
            settings.formTags[0].id == "chinese1"
        ) {
            return 135
        }

        return 180
    }

    getComponents() {
        let components: Array<any> = [this.settings, this.harmony]

        if (this.melodyLayer != null) {
            components.push(this.melodyLayer)
        }

        for (let layer of this.accompanimentLayers) {
            components.push(layer)
        }

        return components
    }

    getGPInfluenceLoading(gpComponentName: string) {
        let component = this.getComponent(gpComponentName)

        if (component != null) {
            return component.gpInfluenceLoading
        }

        return this.createInfluenceLoading(gpComponentName, "")
    }

    addComponentsFromOtherGP(
        gp: GenerationProfile,
        gpComponents: Array<string>,
        missingLayerError: string
    ) {
        for (let gpComponent of gpComponents) {
            this.addComponentFromOtherGP(gp, gpComponent, missingLayerError)
        }
    }

    addComponentFromOtherGP(
        gp: GenerationProfile,
        gpComponent: string,
        missingLayerError: string
    ) {
        const formatErrorMessage = error => {
            if (error == null) {
                return error
            }

            let layers: Array<GPLayer> = []

            if (gp.melodyLayer != null) {
                layers.push(gp.melodyLayer)
            }

            layers = layers.concat(gp.accompanimentLayers)

            let first = true

            for (let layer of gp.accompanimentLayers) {
                if (!first) {
                    error += ", "
                }

                error += layer.name
                first = false
            }

            return error
        }

        const error = formatErrorMessage(missingLayerError)

        if (gpComponent == "Settings") {
            this.settings = gp.settings
        } else if (gpComponent == "Harmony") {
            this.harmony = gp.harmony
        } else if (gpComponent == "Melody") {
            if (gp.melodyLayer == null) {
                this.melodyLayer.gpInfluenceLoading.error = error
            } else {
                this.melodyLayer = gp.melodyLayer
            }
        } else {
            let hasFoundLayer = false

            for (let nl of gp.accompanimentLayers) {
                if (nl.name == gpComponent) {
                    hasFoundLayer = true

                    break
                }
            }

            if (!hasFoundLayer) {
                for (let l = 0; l < this.accompanimentLayers.length; l++) {
                    let layer = this.accompanimentLayers[l]

                    if (layer.name == gpComponent) {
                        layer.gpInfluenceLoading.error = error

                        break
                    }
                }
            } else {
                for (let l = 0; l < this.accompanimentLayers.length; l++) {
                    let layer = this.accompanimentLayers[l]

                    if (layer.name != gpComponent) {
                        for (let pack of layer.packs) {
                            if (
                                pack.transform != null &&
                                pack.transform.target != null &&
                                pack.transform.target.layer == gpComponent
                            ) {
                                pack.transform = null
                            }

                            if (
                                pack.synchronisation != null &&
                                pack.synchronisation.target != null &&
                                pack.synchronisation.target.layer == gpComponent
                            ) {
                                pack.synchronisation = null
                            }
                        }

                        continue
                    }

                    for (let nl of gp.accompanimentLayers) {
                        if (nl.name != gpComponent) {
                            continue
                        }

                        this.accompanimentLayers[l] = nl

                        for (let pack of this.accompanimentLayers[l].packs) {
                            pack.transform = null
                            pack.synchronisation = null
                        }

                        break
                    }
                }
            }
        }
    }

    selectedEmotions() {
        let moods = [1, 2, 3, 4, 5]

        // Check tempo ranges
        for (let key in GPSettings.MOOD_OPTIONS.tempoRanges) {
            let range = GPSettings.MOOD_OPTIONS.tempoRanges[key]

            if (
                !Misc.isInRange(this.settings.tempoRange.min, range) ||
                !Misc.isInRange(this.settings.tempoRange.max, range)
            ) {
                this.removeMood(key, moods, "tempo ranges")
            }
        }

        // check key mode
        for (let key in GPSettings.MOOD_OPTIONS.keyMode) {
            let mode = GPSettings.MOOD_OPTIONS.keyMode[key]

            if (mode != this.harmony?.keySignature?.keyMode?.toLowerCase()) {
                this.removeMood(key, moods, "key mode")
            }
        }

        // check melody phrase pack
        if (this.melodyLayer != null) {
            for (let key in GPSettings.MOOD_OPTIONS.melodyPhrasePack) {
                let pack = GPSettings.MOOD_OPTIONS.melodyPhrasePack[key]
                let currentPack: MelodyPack = this.melodyLayer
                    .packs[0] as MelodyPack

                if (
                    currentPack.complexity != pack.complexity ||
                    currentPack.phraseLength != pack.phraseLength
                ) {
                    this.removeMood(key, moods, "melody layer")
                }
            }
        }

        // check harmony pack
        if (this.harmony != null) {
            let chordsGroupIDs = this.harmony.packs.map(p => p.chordsGroupID[0])

            for (let key in GPSettings.MOOD_OPTIONS.harmony) {
                let pack = GPSettings.MOOD_OPTIONS.harmony[key]

                if (
                    chordsGroupIDs.filter(c => !pack.datasets.includes(c))
                        .length > 0
                ) {
                    this.removeMood(
                        key,
                        moods,
                        "harmony pack" + pack.harmonicRepetition + " " + key
                    )
                }
            }
        }

        let harmonicRepetition = Harmony.getAggregatedHarmonicRepetition(
            this.harmony.packs
        )
        let harmonicRhythm = Harmony.getAggregatedHarmonicRhythm(
            this.harmony.packs
        )

        for (let key in GPSettings.MOOD_OPTIONS.harmony) {
            let pack = GPSettings.MOOD_OPTIONS.harmony[key]

            if (
                pack.harmonicRepetition != harmonicRepetition.min ||
                pack.harmonicRepetition != harmonicRepetition.max
            ) {
                this.removeMood(
                    key,
                    moods,
                    "harmonic repetition " +
                        harmonicRepetition.min +
                        " " +
                        harmonicRepetition.max +
                        "," +
                        pack.harmonicRepetition
                )
            }
        }

        for (let key in GPSettings.MOOD_OPTIONS.harmony) {
            let pack = GPSettings.MOOD_OPTIONS.harmony[key]

            if (
                !Misc.isInMinMaxRange(
                    harmonicRhythm.min,
                    pack.harmonicRhythm
                ) ||
                !Misc.isInMinMaxRange(harmonicRhythm.max, pack.harmonicRhythm)
            ) {
                this.removeMood(
                    key,
                    moods,
                    "harmonic rhythm min:" +
                        harmonicRhythm.min +
                        "," +
                        pack.harmonicRhythm.min +
                        " max:" +
                        harmonicRhythm.max +
                        "," +
                        pack.harmonicRhythm.max
                )
            }
        }

        if (this.settings.emotion != null) {
            for (let mood of moods) {
                if (mood == this.settings.emotion) {
                    return [mood]
                }
            }
        }

        return moods
    }

    selectHarmonyPack(harmonyPack: HarmonyPack, sourcePacks) {
        let keySignature = this.harmony.keySignature
        let harmony = this.harmony

        if (
            harmony["pack"] != null &&
            (harmony.packs == null || harmony.packs.length == 0)
        ) {
            harmony.packs = [this["pack"]]
        }

        let harmonyPackIndex = harmony.packs.findIndex(
            p => p.chordsGroupID[0] == harmonyPack.chordsGroupID[0]
        )

        if (harmonyPackIndex == -1) {
            harmony.packs.push(harmonyPack)

            for (let p = 0; p < harmony.packs.length; p++) {
                if (harmony.packs[p].packID.includes("7th_chords_1")) {
                    this.harmony.packs[p].harmonicRhythm = { min: 0, max: 0 }
                }
            }

            harmony.encodePackID(sourcePacks)
        } else {
            // remove pack
            harmony.packs.splice(harmonyPackIndex, 1)
        }

        harmony.selectCompatibleKeySignature()
    }

    getAllLayers(): GPLayer[] {
        let layers: GPLayer[] = []

        if (this.melodyLayer) {
            layers.push(this.melodyLayer)
        }

        layers = layers.concat(this.getAllAccompanimentLayers())

        return layers
    }

    getAllAccompanimentLayers(): AccompanimentLayer[] {
        const layers: AccompanimentLayer[] = []

        for (const layer of this.accompanimentLayers) {
            layers.push(layer)
        }

        return layers
    }

    getAllInstruments() {
        let instruments = []

        if (this.melodyLayer != null) {
            instruments = instruments.concat(
                this.melodyLayer.packs[0].instruments
            )
        }

        for (let layer of this.accompanimentLayers) {
            for (let pack of layer.packs) {
                instruments = instruments.concat(pack.instruments)
            }
        }

        return instruments
    }

    async forEachInstrument(functionBinding) {
        if (this.melodyLayer != null) {
            const instruments = this.melodyLayer.packs[0].instruments

            for (let i = 0; i < instruments.length; i++) {
                instruments[i] = await functionBinding(instruments[i])
            }
        }

        for (let layer of this.accompanimentLayers) {
            for (let pack of layer.packs) {
                const instruments = pack.instruments

                for (let i = 0; i < instruments.length; i++) {
                    instruments[i] = await functionBinding(instruments[i])
                }
            }
        }
    }

    getDoubles() {
        let doubles = []
        let instruments = this.getAllInstruments()

        for (let instrument of instruments) {
            if (
                instrument.patchID.split(".")[0] != "d" ||
                instrument.createdByAiva
            ) {
                continue
            }

            doubles.push(instrument)
        }

        return doubles
    }

    switchDoubleIDs(doubleMap) {
        let instruments = this.getAllInstruments()

        for (let instrument of instruments) {
            let newID = doubleMap[instrument.patchID]

            if (newID == null) {
                continue
            }

            instrument.patchID = newID
        }
    }

    removeMood(mood, moods, debug = "") {
        // console.log("remove mood", mood, debug)
        for (let m = moods.length - 1; m >= 0; m--) {
            if (moods[m] == parseInt(mood)) {
                moods.splice(m, 1)

                break
            }
        }
    }

    getAllPatches(): Array<Patch> {
        let patches = []

        if (this.melodyLayer != null) {
            for (let instrumentPatch of this.melodyLayer.packs[0].instruments) {
                patches = patches.concat(
                    this.getAllPatchesFromInstrumentPatch(instrumentPatch)
                )
            }
        }

        for (let layer of this.accompanimentLayers) {
            for (let pack of layer.packs) {
                for (let instrumentPatch of pack.instruments) {
                    patches = patches.concat(
                        this.getAllPatchesFromInstrumentPatch(instrumentPatch)
                    )
                }
            }
        }

        return patches
    }

    getAllPatchesFromInstrumentPatch(
        instrumentPatch: InstrumentPatch
    ): Array<Patch> {
        let patches = []

        for (let patch of instrumentPatch.patches) {
            patches.push(patch.patch)
        }

        return patches
    }

    deleteMelody() {
        this.melodyLayer = null
    }

    hasLayer(name: string): number {
        let numberOfLayers = 0

        if (name == "Melody") {
            if (this.melodyLayer != null) {
                numberOfLayers += 1
            }
        } else {
            for (let layer of this.accompanimentLayers) {
                if (layer.name.includes(name)) {
                    numberOfLayers += 1
                }
            }
        }

        return numberOfLayers
    }

    getNumberOfLayers(): number {
        let numberOfLayers = 0

        if (this.melodyLayer != null) {
            numberOfLayers += 1
        }

        for (let layer of this.accompanimentLayers) {
            if (layer != null) {
                numberOfLayers += 1
            }
        }

        return numberOfLayers
    }

    hasLayers(): boolean {
        if (
            this.melodyLayer == null &&
            (this.accompanimentLayers == null ||
                this.accompanimentLayers.length == 0)
        ) {
            return false
        }

        return true
    }

    addLayer(name: string): void {
        if (name == "Melody") {
            this.melodyLayer = new MelodyLayer()
        } else {
            let accompanimentLayerName =
                this.getAccompanimentLayerNameToAdd(name)
            let accompanimentLayer = new AccompanimentLayer(
                accompanimentLayerName
            )
            this.accompanimentLayers.push(accompanimentLayer)
        }

        if (
            this.settings.minLayers == 1 &&
            this.accompanimentLayers.length > 0
        ) {
            let changeMinLayers = false

            for (let layer of this.accompanimentLayers) {
                if (layer.type == "percussion") {
                    changeMinLayers = true

                    break
                }
            }

            if (changeMinLayers) {
                this.settings.minLayers = 2

                if (this.accompanimentLayers.length == 1) {
                    this.settings.minLayers = 1
                }
            }
        }
    }

    getAccompanimentLayerNameToAdd(layerName) {
        if (
            !layerName.toLowerCase().includes("ornaments") &&
            !layerName.toLowerCase().includes("extra")
        ) {
            return layerName
        }

        let suffix = 0

        let namesToChooseFrom = ["Ornaments", "Ornaments_1", "Ornaments_2"]

        if (layerName.toLowerCase().includes("extra")) {
            namesToChooseFrom = ["Extra", "Extra_1", "Extra_2"]
        }

        for (let layer of this.accompanimentLayers) {
            if (
                !layer.name.toLowerCase().includes("ornaments") &&
                !layer.name.toLowerCase().includes("extra")
            ) {
                continue
            }

            for (let n = 0; n < namesToChooseFrom.length; n++) {
                let name = namesToChooseFrom[n]

                if (name.toLowerCase() == layer.name.toLowerCase()) {
                    namesToChooseFrom.splice(n, 1)

                    break
                }
            }
        }

        if (namesToChooseFrom.length == 0) {
            return layerName
        }

        return namesToChooseFrom[0]
    }

    deleteLayer(selectedLayer: GPLayer) {
        let index = 0

        if (selectedLayer.name == "Melody") {
            this.melodyLayer = null
        } else {
            for (let layer of this.accompanimentLayers) {
                if (layer == selectedLayer) {
                    this.accompanimentLayers.splice(index, 1)

                    return
                }

                index += 1
            }
        }
    }

    getAccompanimentLayer(name: string): AccompanimentLayer {
        for (let layer of this.accompanimentLayers) {
            if (layer.name == name) {
                return layer
            }
        }

        return null
    }

    getGPOptions() {
        return gpOptions
    }

    /**
     * decodes the sourcePacks we get from the ME into JSON format and adds missing properties to it
     * @param sourcePacksJSON JSON Object we receive from the ME
     * @returns decoded JSON Object
     */
    static decodeSourcePacks(sourcePacksJSON): GPSourcePacks {
        let newSourcePacksJSON: GPSourcePacks = {
            harmonyPacks: [],
            melodyPacks: [],
            accompanimentPacks: [],
            formDescriptionPacks: {
                filteringStrategy: [],
                dynamicsStrategy: [],
                layeringStrategy: [],
                orchestrationVariation: [],
                keyShift: [],
                partialSections: [],
                developmentTension: [],
                percussionDevelopment: [],
                subsequenceLength: [],
                harmonicVariation: [],
                materialVariationStrategy: [],
            },
        }

        for (let hp of sourcePacksJSON["harmony_packs"]) {
            let harmonyMetaData = gpOptions.harmony.packs.find(
                p => p.id == hp["id"]
            )
            let harmonyName =
                harmonyMetaData == null ? "No name found" : harmonyMetaData.name
            let harmonyDescription =
                harmonyMetaData == null
                    ? "No description found"
                    : harmonyMetaData.description
            let recommendedStyles =
                harmonyMetaData == null ? [] : harmonyMetaData.recommendedStyles
            let modes: Array<string> = []

            for (let m of hp["mode"]) {
                const mode = ME_MODES_TO_CREATORS_MODES_MAPPING[m]

                modes.push(mode)
            }

            if (harmonyMetaData == null) {
                console.log("harmonyMetaData null for", hp.id)
            }

            let newHarmonyPack = new HarmonyPack(
                hp["id"],
                harmonyName,
                harmonyDescription,
                recommendedStyles,
                hp["chords_group_id"],
                hp["phrase_forms_tags"],
                hp["cadences_tags"],
                harmonyMetaData["harmonicRepetition"],
                harmonyMetaData["harmonicRhythm"],
                modes
            )

            newSourcePacksJSON.harmonyPacks.push(newHarmonyPack)
        }

        for (let mp of sourcePacksJSON["melody_packs"]) {
            let melodyMetadataData: any = gpOptions.melody.packs.find(
                p => p.id == mp["id"]
            )

            newSourcePacksJSON.melodyPacks.push(
                new MelodyPack(
                    mp["id"],
                    [],
                    melodyMetadataData.complexity,
                    melodyMetadataData.phraseLength,
                    mp["phrases_group_id"],
                    mp["phrase_forms_tags"]
                )
            )
        }

        for (let type in sourcePacksJSON["form_description_packs"]) {
            newSourcePacksJSON.formDescriptionPacks[camelCase(type)] =
                sourcePacksJSON["form_description_packs"][type]
        }

        return newSourcePacksJSON
    }

    /**
     * takes an array of instrument patch and turns them into InstrumentPatches
     * @param instrumentPatch
     * @param instrumentsJSON
     * @returns Array<InstrumentPatch>
     */
    decodeInstruments(
        instruments: Array<InstrumentsSchema | string>,
        instrumentsJSON,
        doubles: Array<InstrumentPatch>
    ): Array<InstrumentPatch> {
        if (!instruments || !instrumentsJSON) {
            throw new Error("Could not decode instruments")
        }

        let newInstruments = []

        for (let inst of instruments) {
            let instString: string
            let noteTie: boolean = false

            if (typeof inst != "string") {
                instString = inst.patch

                if (instString == null) {
                    instString = inst.patchID
                }

                noteTie = inst.noteTie
            } else {
                instString = inst
            }

            if (instString === undefined) {
                continue
            }

            let decodedInstrument = InstrumentPatch.decodePatchID(instString)

            if (!decodedInstrument) {
                continue
            } else if (decodedInstrument.section == "d") {
                const value = doubles.find(d => d.patchID === instString)

                if (value) {
                    const instrumentPatch = cloneDeep(value)
                    instrumentPatch.noteTie = noteTie

                    newInstruments.push(instrumentPatch)
                }
            } else if (instrumentsJSON[decodedInstrument.section]) {
                let instMetaData = instrumentsJSON[
                    decodedInstrument.section
                ].find(i => i.name == decodedInstrument.name)
                let patch = instMetaData?.patches.find(
                    p =>
                        p.playing_style == decodedInstrument.playing_style &&
                        p.articulation == decodedInstrument.articulation
                )

                if (!patch) {
                    continue
                }

                let type =
                    decodedInstrument.section == "p" ? "percussion" : "pitched"

                let patchName = instMetaData.full_name + " | " + patch.name

                if (type == "percussion") {
                    patchName = instMetaData.full_name
                }

                let instrumentPatch = new InstrumentPatch(
                    instString,
                    patchName,
                    type,
                    [
                        {
                            patch: new Patch(
                                decodedInstrument.section,
                                decodedInstrument.name,
                                decodedInstrument.playing_style,
                                decodedInstrument.articulation,
                                patch.granulationEngine
                            ),
                            octave: 0,
                        },
                    ]
                )
                instrumentPatch.noteTie = noteTie

                newInstruments.push(instrumentPatch)
            }
        }

        return newInstruments
    }

    static getLatestPublishedRevision(revisions) {
        if (revisions != null) {
            for (let r = revisions.length - 1; r >= 0; r--) {
                if (revisions[r].published) {
                    return revisions[r]
                }
            }
        }

        return null
    }

    decodeAccompanimentPack(
        pack: GPAccompanimentPackSchema,
        sourcePacks: GPSourcePacks
    ): AccompanimentPack {
        // @todo: remove this code, which is temporary until integration with music engine is finalised
        if (typeof pack.packID == "number") {
            pack.packID = pack.packID + ""
        }

        let packMetaData: AccompanimentPack =
            sourcePacks.accompanimentPacks.find(p => p.packID === pack.packID)

        if (!packMetaData) {
            return null
        }

        let accompanimentPack: AccompanimentPack = AccompanimentPack.fromJSON(
            packMetaData.copy()
        )
        accompanimentPack.fromGenerationProfileSchema(pack)

        return accompanimentPack
    }

    decodeGPSettings(settings: GPSettingsSchema) {
        if (!settings || !settings.formTags) {
            throw new Error(
                "Could not decode GP settings object that is undefined or has an undefined formTags attribute"
            )
        }

        let formTags = []

        for (let f = 0; f < settings.formTags.length; f++) {
            if (typeof settings.formTags[f] == "string") {
                formTags.push({
                    id: settings.formTags[f],
                    name: Misc.capitalizeFirstLetter(settings.formTags[f]),
                })
            } else {
                formTags.push(settings.formTags[f])
            }
        }

        this.settings.formTags = GenerationProfile.decodeFormTags(formTags)
        this.settings.gpInfluenceLoading = this.decodeGPInfluenceLoading(
            settings.gpInfluenceLoading
        )
        this.settings.timeSignature = settings.timeSignature
        this.settings.expressivePerformance = settings.expressivePerformance
        this.settings.dynamicRange = settings.dynamicRange
        this.settings.emotion = settings.emotion
        this.settings.tempoRange = settings.tempoRange
        this.settings.autoMix = settings.autoMix
        this.settings.minLayers = settings.minLayers

        if (
            settings.layersOrder == null ||
            settings.layersOrder.length == 0 ||
            typeof settings.layersOrder[0] == "string"
        ) {
            delete settings.layersOrder
        } else {
            let layersOrder = []

            for (let order of settings.layersOrder) {
                let newLayerOrder: any = order

                if (!newLayerOrder.includes("Extra_1")) {
                    newLayerOrder.push("Extra_1")
                }

                if (!newLayerOrder.includes("Extra_2")) {
                    newLayerOrder.push("Extra_2")
                }

                layersOrder.push(newLayerOrder)
            }

            this.settings.layersOrder = layersOrder
        }
    }

    static decodeFormTags(formTags) {
        let oldTags = []
        let newTags = []

        for (let tag of formTags) {
            let isOldTag = true

            for (let form of GPSettings.DEVELOPMENT_TYPE_OPTIONS) {
                if (form.id == tag.id) {
                    tag = form
                    isOldTag = false

                    break
                }
            }

            if (isOldTag) {
                oldTags.push(tag)
            } else {
                newTags.push(tag)
            }
        }

        for (let form of GPSettings.DEVELOPMENT_TYPE_OPTIONS) {
            let hasIDs = []

            for (let oldTag of oldTags) {
                if (form.ids.includes(oldTag.id)) {
                    hasIDs.push(oldTag.id)
                }
            }

            if (Misc.arraysAreEqual(hasIDs, form.ids)) {
                let add = true

                for (let newTag of newTags) {
                    if (newTag.id == form.id) {
                        add = false
                        break
                    }
                }

                if (add) {
                    newTags.push(form)
                }
            }
        }

        return newTags
    }

    decodeHarmonyPack(harmony: HarmonySchema, sourcePacks) {
        if (!harmony) {
            throw new Error("Could not decode undefined harmony")
        }

        let packID: any = harmony.packID

        if (!Array.isArray(packID) || packID.length == 0) {
            packID = [harmony.packID]
        }

        let packs: HarmonyPack[] = []

        for (let pack of sourcePacks) {
            if (!packID.includes(pack.packID)) {
                continue
            }

            packs.push(
                new HarmonyPack(
                    pack.packID,
                    pack.name,
                    pack.description,
                    pack.recommendedStyles,
                    pack.chordsGroupID,
                    pack.phraseFormsTags,
                    pack.cadencesTags,
                    pack.harmonicRepetition,
                    pack.harmonicRhythm,
                    pack.mode
                )
            )
        }

        this.harmony.packs = packs
        this.harmony.keySignature = {
            pitchClass: harmony.keySignature.pitchClass,
            keyMode: harmony.keySignature.keyMode,
        }
        this.harmony.gpInfluenceLoading = this.decodeGPInfluenceLoading(
            harmony.gpInfluenceLoading
        )
        this.harmony.strategy = harmony.strategy

        return this.harmony
    }

    decodeGPInfluenceLoading(
        gpInfluenceLoadingObject: GPInfluenceLoadingSchema
    ): GPInfluenceLoading {
        let gpInfluenceLoading = new GPInfluenceLoading(
            gpInfluenceLoadingObject?.progress,
            gpInfluenceLoadingObject?.influenceID
        )

        if (gpInfluenceLoadingObject != null) {
            gpInfluenceLoading.error = gpInfluenceLoadingObject.error
            gpInfluenceLoading.influenceID =
                gpInfluenceLoadingObject.influenceID
            gpInfluenceLoading.time = gpInfluenceLoadingObject.time
        }

        return gpInfluenceLoading
    }

    /**
     * decodes a GP object and populates it with additional data needed on the UI
     * @param gpObject
     * @param instrumentsJSON
     * @param sourcePacks
     * @returns
     */
    decode(
        gpObject: GenerationProfileSchema,
        instrumentsJSON,
        doubles,
        sourcePacks: GPSourcePacks
    ) {
        if (gpObject == null) {
            throw new Error("Could not decode an empty gpObject from the DB")
        }

        if (gpObject.melodyLayer != null) {
            this.decodeMelodyLayer(
                gpObject.melodyLayer,
                instrumentsJSON,
                doubles,
                sourcePacks.melodyPacks
            )
        }

        if (gpObject.accompanimentLayers === undefined) {
            gpObject.accompanimentLayers = []
        }

        this.decodeAccompanimentLayer(
            gpObject.accompanimentLayers,
            instrumentsJSON,
            doubles,
            sourcePacks
        )

        this.decodeGPSettings(gpObject.settings)
        this.decodeHarmonyPack(gpObject.harmony, sourcePacks.harmonyPacks)
        this.decodeFormDescription(
            gpObject.formDescription,
            sourcePacks.formDescriptionPacks
        )

        for (let key in gpObject) {
            if (
                key == "settings" ||
                key == "harmony" ||
                key == "accompanimentLayers" ||
                key == "melodyLayer" ||
                key == "formDescription"
            ) {
                continue
            }

            this[key] = gpObject[key]
        }

        return this
    }

    decodeMelodyLayer(
        melodyObject: MelodyLayerSchema,
        instrumentsJSON,
        doubles,
        melodyPacks: MelodyPack[]
    ) {
        this.melodyLayer = new MelodyLayer()

        for (let pack of melodyPacks) {
            if (pack.packID == melodyObject.packID) {
                this.melodyLayer.packs = [
                    new MelodyPack(
                        pack.packID,
                        melodyObject.instruments,
                        pack.complexity,
                        pack.phraseLength,
                        pack.phraseGroupID,
                        pack.phraseFormsTags
                    ),
                ]

                break
            }
        }

        this.melodyLayer.packs[0].instruments = this.decodeInstruments(
            melodyObject.instruments,
            instrumentsJSON,
            doubles
        )
        this.melodyLayer.swing = melodyObject.swing
        this.melodyLayer.gpInfluenceLoading = this.decodeGPInfluenceLoading(
            melodyObject.gpInfluenceLoading
        )
        this.melodyLayer.mixing = this.decodeMixing(melodyObject.mixing)
        this.melodyLayer.fixedInstrumentation =
            melodyObject.fixedInstrumentation
    }

    decodeMixing(mixing: GPMixingSchema): GPMixing {
        let gpMixing = new GPMixing()

        for (let key in gpMixing) {
            gpMixing[key] = mixing[key]
        }

        return gpMixing
    }

    decodeAccompanimentLayer(
        accompanimentLayers: Array<AccompanimentLayersSchema>,
        instrumentsJSON,
        doubles,
        sourcePacks: GPSourcePacks
    ) {
        for (let layer of accompanimentLayers) {
            let accompanimentLayer: AccompanimentLayer = new AccompanimentLayer(
                layer.name
            )
            this.accompanimentLayers.push(accompanimentLayer)

            accompanimentLayer.type = layer.name.includes("Percussion")
                ? "percussion"
                : "pitched"
            accompanimentLayer.gpInfluenceLoading =
                this.decodeGPInfluenceLoading(layer.gpInfluenceLoading)
            accompanimentLayer.mixing = this.decodeMixing(layer.mixing)
            accompanimentLayer.fixedInstrumentation = layer.fixedInstrumentation

            // the follow is a hack to fix an earlier bug
            if (layer.name === "Ornaments1") {
                accompanimentLayer.name = "Ornaments_1"
            }

            if (layer.name === "Ornaments2") {
                accompanimentLayer.name = "Ornaments_2"
            }

            if (layer.name === "Ornaments3") {
                accompanimentLayer.name = "Ornaments_3"
            }
            // end hack

            for (let p = 0; p < layer.packs.length; p++) {}

            for (let pack of layer.packs) {
                let decodedPack: AccompanimentPack =
                    this.decodeAccompanimentPack(pack, sourcePacks)

                if (decodedPack != null) {
                    accompanimentLayer.packs.push(decodedPack)
                    decodedPack.instruments = this.decodeInstruments(
                        pack.instruments,
                        instrumentsJSON,
                        doubles
                    )
                }
            }
        }
    }

    decodeFormDescription(
        encodedFormDescription: FormDescriptionSchema,
        packs: FormDescriptionPacks
    ): any {
        if (!encodedFormDescription) {
            // @todo populate with the appropriate form description based on the form tag present in the GP
            encodedFormDescription = defaultFormTag
        }

        this.formDescription = new FormDescription()

        for (let key in this.formDescription) {
            if (key == "variationSettings" || key == "structureSettings") {
                continue
            }

            if (encodedFormDescription[key] == null) {
                this.formDescription[key] = {
                    name: packs[key][0].name,
                    value: packs[key][0].pack_id,
                }
            } else {
                for (let pack of packs[key]) {
                    if (pack.pack_id === encodedFormDescription[key]) {
                        this.formDescription[key] = {
                            name: pack.name,
                            value: pack.pack_id,
                        }

                        break
                    }
                }
            }
        }

        this.formDescription.variationSettings = new VariationSettings()
        this.formDescription.variationSettings.decode(
            encodedFormDescription.variationSettings,
            packs
        )

        this.formDescription.structureSettings = new StructureSettings()
        this.formDescription.structureSettings.decode(
            encodedFormDescription.structureSettings
        )
    }

    static computeSimilarity(
        gp1: GenerationProfile,
        gp2: GenerationProfile,
        log?: boolean
    ) {
        if (gp1 == null) {
            return null
        }

        if (log) {
            console.log(
                "\tComputing similarity for: ",
                gp1.name,
                gp1._id,
                "vs",
                gp2.name,
                gp2._id
            )
        }

        let settings = GPSettings.computeSimilarity(gp1.settings, gp2.settings)
        let harmony = Harmony.computeSimilarity(gp1.harmony, gp2.harmony)
        let melody = MelodyLayer.computeSimilarity(
            gp1.melodyLayer,
            gp2.melodyLayer
        )
        let accompanimentsResults = GenerationProfile.computeLayersSimilarity(
            gp1,
            gp2
        )

        let score = Math.round(
            (settings.score * GenerationProfile.SIMILARITY_WEIGHTS.settings +
                harmony.score * GenerationProfile.SIMILARITY_WEIGHTS.harmony +
                melody.score * GenerationProfile.SIMILARITY_WEIGHTS.melody +
                accompanimentsResults.score *
                    GenerationProfile.SIMILARITY_WEIGHTS.accompaniments) *
                100
        )

        return {
            score: score,
            melody: melody.report,
            harmony: harmony.report,
            settings: settings.report,
            accompaniments: accompanimentsResults.report,
        }
    }

    static computeLayersSimilarity(
        gp1: GenerationProfile,
        gp2: GenerationProfile
    ) {
        let accompaniments = 0
        let accompanimentsReport = []

        let availableLayers = GenerationProfile.getAvailableLayers([gp1, gp2])
        let availableLayersForGP1 = GenerationProfile.getAvailableLayers([gp1])
        let availableLayersForGP2 = GenerationProfile.getAvailableLayers([gp2])

        for (let layer in availableLayers) {
            if (
                availableLayersForGP1[layer] == null ||
                availableLayersForGP2[layer] == null
            ) {
                continue
            }

            let layer1 = gp1.getLayerFromKey(layer)
            let layer2 = gp2.getLayerFromKey(layer)

            let temp = AccompanimentLayer.computeSimilarity(layer1, layer2)

            accompaniments +=
                temp.score /
                Math.max(
                    gp1.accompanimentLayers.length,
                    gp2.accompanimentLayers.length
                )
            accompanimentsReport.push(temp.report)
        }

        return {
            score: accompaniments,
            report: accompanimentsReport,
        }
    }

    getLayerFromKey(layerName: string) {
        for (let layer of this.accompanimentLayers) {
            if (layerName == layer.name) {
                return layer
            }
        }

        return null
    }

    static getAvailableLayers(gps: Array<GenerationProfile>) {
        let layers = {}

        for (let gp of gps) {
            for (let layer of gp.accompanimentLayers) {
                layers[layer.name] = []
            }
        }

        return layers
    }

    encodeForME(): GenerationProfileMusicEngine {
        let layers = {}

        for (let layer of this.accompanimentLayers) {
            let layerName = layer.name

            if (layerName.toLowerCase().includes("ornaments")) {
                if (layerName.match(/\d/) != null) {
                    layerName = "Ornaments_" + layerName.match(/\d/)[0]
                }
            }

            layers[layerName] = layer.encodeForME()
        }

        let gp: GenerationProfileMusicEngine = {
            gp_id: this._id,
            global_parameters: this.settings.encodeForME(),
            harmony_pack: this.harmony.encodeForME(),
            layers: layers,
            melody_pack:
                this.melodyLayer != null ? this.melodyLayer.encodeForME() : {},
            form_description: undefined, //this.formDescription.encodeForME()
        }

        return gp
    }

    validate(validateForUI = false) {
        let validation = {
            hasLayers: new GPValidation(this.hasLayers()),
            hasMainLayer: new GPValidation(this.hasMainLayer()),
            settings: this.settings.validate(
                validateForUI,
                this.getNumberOfLayers()
            ),
            melodyLayer:
                this.melodyLayer == null
                    ? new GPValidation(true)
                    : this.melodyLayer.validate(validateForUI, "melodyLayer"),
            harmony: this.harmony.validate(validateForUI),
            accompanimentLayers: this.validateAccompanimentLayers(),
            valid: true,
        }

        // set global valid
        for (let key of Object.keys(validation)) {
            if (
                validation[key] == false ||
                (validation[key] != null && validation[key].valid == false)
            ) {
                validation.valid = false

                break
            }
        }

        this.resolveBrokenDependencies()

        return validation
    }

    hasMainLayer() {
        if (this.melodyLayer != null) {
            return true
        }

        for (let layer of this.accompanimentLayers) {
            if (layer.name.includes("Ornaments")) {
                continue
            }

            return true
        }

        return false
    }

    validateAccompanimentLayers(validateForUI = false) {
        let validation = new GPValidation(
            true,
            "accompanimentLayers",
            this.accompanimentLayers,
            null,
            null
        )

        for (let layer of this.accompanimentLayers) {
            let layerValidation = layer.validate(
                validateForUI,
                "accompanimentLayer"
            )

            validation.validations.push(layerValidation)

            if (layerValidation && layerValidation["valid"] == false) {
                validation.message =
                    "There are some issues with this layer. Please check the added accompaniment packs and instruments."
                validation.valid = false
            }
        }

        return validation
    }

    /**
     * This method is used to resolve broken dependencies like synch and transform
     * (e.g. when we remove a layer but there still is a reference to it in synch / transform -> remove the synch / transform entry)
     */
    resolveBrokenDependencies() {
        let layersWithPacks = this.accompanimentLayers.filter(
            l => l.packs.length > 0
        )
        let layerNames = layersWithPacks.map(l => l.name)

        for (let l = 0; l < this.accompanimentLayers.length; l++) {
            if (this.accompanimentLayers[l].name.includes("Ornament")) {
                continue
            }

            for (let p = 0; p < this.accompanimentLayers[l].packs.length; p++) {
                let synchronisation =
                    this.accompanimentLayers[l].packs[p].synchronisation
                let transform = this.accompanimentLayers[l].packs[p].transform

                if (
                    synchronisation != null &&
                    synchronisation.target != null &&
                    !layerNames.includes(synchronisation.target.layer)
                ) {
                    this.accompanimentLayers[l].packs[p].synchronisation = null
                }

                if (
                    transform != null &&
                    transform.target != null &&
                    !layerNames.includes(transform.target.layer)
                ) {
                    this.accompanimentLayers[l].packs[p].transform = null
                }
            }
        }
    }

    static validateMode(gp, mode) {
        let generationProfile = GenerationProfile.fromJSON(gp)

        let validation = new GPValidation(true, "mode", generationProfile)

        validation.validations.push(
            Harmony.validateHarmonyPackMode(
                generationProfile.harmony.packs,
                mode,
                generationProfile.harmony.strategy
            )
        )

        if (generationProfile.settings.emotion != null) {
            validation.validations.push(
                GPSettings.validateEmotion(
                    generationProfile.settings.emotion,
                    mode
                )
            )
        }

        for (let v of validation.validations) {
            if (v.valid == false) {
                validation.message = v.message
                validation.valid = false

                break
            }
        }

        return validation
    }
}
