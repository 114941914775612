<div class="modal-title">Change layer color</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<chrome-picker
    class="color-picker-container"
    [control]="chromeControl"></chrome-picker>

<div class="modal-button modal-text-button" (click)="closeModal()">Cancel</div>

<div class="modal-button modal-blue-button" (click)="changeLayerColor()">
    Done
</div>
