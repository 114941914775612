<div
    class="context-menu instrument-palette-more-options"
    (clickOutside)="closeContextMenu()"
    [clickOutsideEvents]="'click,contextmenu,mousedown'">
    <div class="context-menu-background"></div>

    <div
        class="right-click-menu-item download-button animate-0_1s"
        (click)="selectDoubleThroughMenu($event)">
        Select all
    </div>

    <div
        class="right-click-menu-item download-button animate-0_1s"
        (click)="addToMyDoublingInstruments()"
        *ngIf="allowDuplicateDouble()">
        Add to my doubling instruments
    </div>

    <div
        class="right-click-menu-item download-button animate-0_1s"
        (click)="editSearchItem()"
        *ngIf="allowUserAction">
        {{ getEditActionName() }}
    </div>

    <div
        class="right-click-menu-item download-button animate-0_1s"
        (click)="deleteSearchItem()"
        *ngIf="allowUserAction">
        Delete
    </div>
</div>
