import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter } from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import { ScoreCorruptedModalParams } from "@services/modal.service"

@Component({
    selector: 'score-corrupted',
    templateUrl: 'score-corrupted.component.html',
    styleUrls: ['score-corrupted.component.scss']
})
export class ScoreCorruptedComponent implements OnInit {
    @Input() input:ScoreCorruptedModalParams
    @Output() close:EventEmitter<any> = new EventEmitter()

    public isLoading:boolean = false

    constructor() {

    }

    ngOnInit() {

    }

    public async saveChanges() {
        this.isLoading = true

        await Misc.wait(0.1)

        await this.input.saveChanges()

        this.close.emit()

        this.isLoading = false
    }
}
