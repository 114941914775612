import { Injectable } from "@angular/core"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import {
    ComputeSyncsBody,
    CreateDefaultAccompanimentPackBody,
} from "@common-lib/interfaces/api/accompaniment.api"
import { ApiService } from "../api.service"

@Injectable()
export class GenerationProfileHTTPService {
    constructor(protected apiService: ApiService) {}

    async createNewAccompanimentPack(
        body: CreateDefaultAccompanimentPackBody
    ): Promise<AccompanimentPack> {
        try {
            const res = await this.apiService.authRequest(
                "/accompanimentPack/createDefault",
                body,
                "primary",
                false
            )

            if (res.result === 0) {
                throw Error(res.message)
            }

            return res.newPack
        } catch (e) {
            this.apiService.handleError(e)
        }
    }

    async getGPByID(
        id: string,
        options?: {
            isCompositionWorkflow?: boolean
        }
    ) {
        return this.apiService.authRequest(
            "/generationprofile/getByID",
            {
                generationProfileID: id,
                isCompositionWorkflow: options?.isCompositionWorkflow || false,
            },
            "primary",
            true
        )
    }

    async computeAccompanimentPackSyncs(body: ComputeSyncsBody): Promise<{
        include: boolean
        subset: boolean
        exact: boolean
    }> {
        try {
            const result = await this.apiService.authRequest(
                "/accompanimentPack/computeSyncs",
                body,
                "primary",
                true
            )

            return {
                include: result.syncs.include,
                subset: result.syncs.subset,
                exact: result.syncs.exact,
            }
        } catch (e) {
            this.apiService.handleError(e)

            return {
                include: false,
                subset: false,
                exact: false,
            }
        }
    }

    /**
     * @returns True if the syncs were computed successfully, and a sync value changed, false otherwise
     */
    async computeGPSyncs(gpID: string): Promise<boolean> {
        try {
            const result = await this.apiService.authRequest(
                "/generationProfile/computeSyncs",
                {
                    gpID,
                },
                "primary",
                true
            )

            return result.syncsHaveChanged
        } catch (e) {
            this.apiService.handleError(e)

            return false
        }
    }

    async deleteTemplatePack(packID: string): Promise<boolean> {
        try {
            const result = await this.apiService.authRequest(
                "/accompanimentPack/deleteTemplatePack",
                {
                    packID,
                },
                "primary",
                true
            )

            return result
        } catch (e) {
            this.apiService.handleError(e)

            return false
        }
    }

    public async renameTemplatePack(accompanimentPack: AccompanimentPack) {
        try {
            const res = await this.apiService.authRequest(
                "/accompanimentPack/renameTemplatePack",
                {
                    accompanimentPack: accompanimentPack,
                    name: accompanimentPack?.name,
                },
                "primary",
                false
            )

            if (res.result === 0) {
                throw Error(res.message)
            }

            return res.packID
        } catch (e) {
            this.apiService.handleError(e)

            return false
        }
    }
}
