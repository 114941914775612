import { Query } from "@datorama/akita"
import { InstrumentsState, InstrumentsStore } from "./instruments.store"

export class InstrumentsQuery extends Query<InstrumentsState> {
    public allState$ = this.select()

    constructor(protected store: InstrumentsStore) {
        super(store)
    }

    public get instruments() {
        return this.getValue().instruments
    }

    public get drumSamples() {
        return this.getValue().drumSamples
    }

    public get pitchToChannelMapping() {
        return this.getValue().pitchToChannelMapping
    }

    public get templateScore() {
        return this.getValue().templateScore
    }

    public get downloadAllStatus() {
        return this.getValue().downloadAllStatus
    }

    public get isDownloadingAllInstruments() {
        return this.getValue().isDownloadingAllInstruments
    }
}
