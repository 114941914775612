import {
    HIGHEST_PIANO_PITCH,
    LOWEST_PIANO_PITCH,
    TIMESTEP_RES,
} from "../../constants/constants"
import { Misc } from "../../modules/misc"
import { Time } from "../../modules/time"
import { Time as Time2 } from "../../modules/time2"
import { FractionString, TimeSignature } from "../../types/score"
import { Fraction } from "./fraction"
import { ImmutableNote, Note } from "./note"
import Section from "./section"
import { cloneDeep } from "lodash"
import { v4 as uuidv4 } from "uuid"

const DEFAULT_LOWEST_NOTE = 127
const DEFAULT_HIGHEST_NOTE = 0

export interface NotePositionMutation {
    notesToMove: Note[]
    timeSignature: TimeSignature
    sections: Section[]
    newStart?: string
    newDuration?: string
    matchGroup?: boolean
    pitchOffset?: number
    ignoreNotes?: string[] // Array of noteIDs to ignore. Mainly used to ignore overlapping notes
}

export interface Rest {
    start: FractionString
    duration: FractionString
}

export interface ForEachGroupAndRestsHigherOrderFunction {
    (
        previous: Note[] | Rest[] | undefined,
        current: Note[] | Rest[],
        next: Note[] | Rest[] | undefined
    ): void
}

/**
 * Return true to continue manipulating next note group, false
 * if you'd like to interrupt the manipulation loop
 */
export interface NoteGroupManipulationFunction {
    (
        noteGroup: Note[],
        nextNoteGroup: Note[] | undefined,
        previousGroup: Note[] | undefined
    ): boolean
}

export interface NoteGroupAndSurroundings {
    noteGroup: undefined | Note[]
    previousNoteGroup: undefined | Note[]
    nextNoteGroup: undefined | Note[]
}

export class NotesObject {
    private highestNote: number = DEFAULT_HIGHEST_NOTE
    private lowestNote: number = DEFAULT_LOWEST_NOTE
    private lastGroup: string = "0"
    private firstGroup: string = "99999"

    private noteGroups: {
        [key: string]: Note[]
    } = {}

    public length: number = 0

    private sortedKeys: string[] | undefined

    constructor() {}

    public calculateOverallDuration(): string {
        let duration = ""
        Object.keys(this.noteGroups).forEach(k => {
            duration = Time.addTwoFractions(duration, this.noteGroups[k])
        })
        return duration
    }

    /**
     * This function is here to abstract away the hierarchy of noteGroups away from other classes.
     * Use this if you want to apply a function to all of the note groups. The note groups are being iterated
     * upon in order of their start time. DO NOT directly mutate the start / duration of the note objects in
     * the manipulate function binding. Instead, pass a NotePositionMutation object to the return object.
     * This way, notes will be reindexed by group in the internal noteGroups datastructure
     * @param manipulate
     * @param groupRange The range of group onsets that the manipulate function should be applied to
     */
    public manipulateNoteGroups(
        manipulate: NoteGroupManipulationFunction,
        groupRange?: [string, string],
        inclusive?: boolean
    ) {
        if (inclusive === undefined) {
            inclusive = true
        }

        let keys = []

        let checkStartRange = false
        let checkEndRange = false

        if (groupRange) {
            groupRange[0] = Time.simplifyFractionFromString(groupRange[0])
            groupRange[1] = Time.simplifyFractionFromString(groupRange[1])

            //console.log("grouprange", cloneDeep(groupRange[0]), cloneDeep(groupRange[1]))

            if (
                Time.compareTwoFractions(groupRange[0], groupRange[1]) === "gt"
            ) {
                const temp = groupRange[1]
                groupRange[1] = groupRange[0]
                groupRange[0] = temp
            }

            const tempKeys = this.getSortedKeys()

            let startIndex = tempKeys.indexOf(groupRange[0])

            if (!startIndex || startIndex < 0) {
                startIndex = 0
                checkStartRange = true
            }

            let endIndex = tempKeys.indexOf(groupRange[1])

            if (!endIndex || endIndex < 0) {
                endIndex = tempKeys.length - 1
                checkEndRange = true
            }

            keys = tempKeys.slice(startIndex, endIndex + 1)
        } else {
            keys = this.getSortedKeys()
        }

        let previousGroup: Note[] | undefined = undefined

        for (let k = 0; k < keys.length; k++) {
            const key = keys[k]
            const noteGroup: Note[] = [...this.noteGroups[key]]

            if (noteGroup.length === 0) {
                this.deleteNoteGroup(key)

                continue
            }

            if (checkStartRange) {
                const noteEnd = Time.addTwoFractions(key, noteGroup[0].duration)

                // console.log("checking start range: ", {
                //     "noteGroup[0].start:": noteGroup[0].start,
                //     "noteGroup[0].duration": noteGroup[0].duration,
                //     "groupRange[0]: ": groupRange[0],
                //     "groupRange[1]: ": groupRange[1],
                //     "inclusive: ": inclusive,
                // })

                // grouprange start is after current note end, this includes the whole note duration
                if (
                    inclusive &&
                    Time.compareTwoFractions(noteEnd, groupRange[0]) !== "gt"
                ) {
                    continue
                }

                // current note starts before grouprange start
                if (
                    !inclusive &&
                    Time.compareTwoFractions(
                        noteGroup[0].start,
                        groupRange[0]
                    ) === "lt"
                ) {
                    continue
                }

                // console.log("BREAK start range", groupRange[0], noteGroup[0])
                checkStartRange = false
            }

            if (
                checkEndRange &&
                Time.compareTwoFractions(key, groupRange[1]) === "gt"
            ) {
                // the start of the current note is greater than the range's end
                // console.log("BREAK end range", groupRange[1], noteGroup[0])
                break
            }

            const nextGroup =
                k + 1 < keys.length
                    ? [...this.noteGroups[keys[k + 1]]]
                    : undefined

            if (!manipulate(noteGroup, nextGroup, previousGroup)) {
                break
            }

            previousGroup = noteGroup
        }
    }

    public manipulateNoteGroups2(
        manipulate: NoteGroupManipulationFunction,
        groupRange?: [Fraction, Fraction],
        inclusive?: boolean
    ) {
        if (inclusive === undefined) {
            inclusive = true
        }

        let keys = []

        let checkStartRange = false
        let checkEndRange = false

        if (groupRange) {
            groupRange[0] = Time2.simplifyFraction(
                groupRange[0].numerator,
                groupRange[0].denominator
            )
            groupRange[1] = Time2.simplifyFraction(
                groupRange[1].numerator,
                groupRange[1].denominator
            )

            //console.log("grouprange", cloneDeep(groupRange[0]), cloneDeep(groupRange[1]))

            if (
                Time2.compareTwoFractions(groupRange[0], groupRange[1]) === "gt"
            ) {
                const temp = groupRange[1]
                groupRange[1] = groupRange[0]
                groupRange[0] = temp
            }

            const tempKeys = this.getSortedKeys()

            let startIndex = tempKeys.indexOf(groupRange[0].toString())

            if (!startIndex || startIndex < 0) {
                startIndex = 0
                checkStartRange = true
            }

            let endIndex = tempKeys.indexOf(groupRange[1].toString())

            if (!endIndex || endIndex < 0) {
                endIndex = tempKeys.length - 1
                checkEndRange = true
            }

            keys = tempKeys.slice(startIndex, endIndex + 1)
        } else {
            keys = this.getSortedKeys()
        }

        let previousGroup: Note[] | undefined = undefined

        for (let k = 0; k < keys.length; k++) {
            const key = keys[k]
            const noteGroup: Note[] = [...this.noteGroups[key]]
            const keyFraction = new Fraction(key)

            if (noteGroup.length === 0) {
                this.deleteNoteGroup(key)

                continue
            }

            if (checkStartRange) {
                const noteEnd = Time2.addTwoFractions(
                    keyFraction,
                    noteGroup[0].durationFraction
                )

                // console.log("checking start range: ", {
                //     "noteGroup[0].start:": noteGroup[0].start,
                //     "noteGroup[0].duration": noteGroup[0].duration,
                //     "groupRange[0]: ": groupRange[0],
                //     "groupRange[1]: ": groupRange[1],
                //     "inclusive: ": inclusive,
                // })

                // grouprange start is after current note end, this includes the whole note duration
                if (
                    inclusive &&
                    Time2.compareTwoFractions(noteEnd, groupRange[0]) !== "gt"
                ) {
                    continue
                }

                // current note starts before grouprange start
                if (
                    !inclusive &&
                    Time2.compareTwoFractions(
                        noteGroup[0].startFraction,
                        groupRange[0]
                    ) === "lt"
                ) {
                    continue
                }

                // console.log("BREAK start range", groupRange[0], noteGroup[0])
                checkStartRange = false
            }

            if (
                checkEndRange &&
                Time2.compareTwoFractions(keyFraction, groupRange[1]) === "gt"
            ) {
                // the start of the current note is greater than the range's end
                // console.log("BREAK end range", groupRange[1], noteGroup[0])
                break
            }

            const nextGroup =
                k + 1 < keys.length
                    ? [...this.noteGroups[keys[k + 1]]]
                    : undefined

            if (!manipulate(noteGroup, nextGroup, previousGroup)) {
                break
            }

            previousGroup = noteGroup
        }
    }

    public hasNoteInRange(range: [FractionString, FractionString]) {
        let hasNotes = false
        this.manipulateNoteGroups(
            (noteGroup, nextNoteGroup, previousGroup) => {
                if (
                    Time.compareTwoFractions(noteGroup[0].start, range[1]) ===
                    "lt"
                ) {
                    hasNotes = true
                }

                return false
            },
            range,
            false
        )

        return hasNotes
    }

    /**
     * Use this function to mutate the start and duration of a note, to make sure it's correctly repositioned in
     * the private noteGroups datastructure
     * @param mutation
     * @returns a list of modified note that are deep copies of the notes that were passed in the object argument
     */
    public changeNotesPosition(mutation: NotePositionMutation): Note[] {
        if (mutation.notesToMove.length === 0) {
            return []
        }

        const newMutation: NotePositionMutation = cloneDeep(mutation)

        if (Time.compareTwoFractions("0", mutation.newStart) === "gt") {
            delete newMutation.newStart
        }

        return newMutation.notesToMove.map(note => {
            const changeNoteStart =
                newMutation.newStart &&
                Time.compareTwoFractions(note.start, newMutation.newStart) !==
                    "eq"

            this.deleteNoteIDsFromNoteGroup(note.start, [note.noteID])

            const newNote: Note = cloneDeep(note)

            if (changeNoteStart) {
                newNote.start = newMutation.newStart
            }

            if (newMutation.newDuration) {
                this.changeNoteDuration(
                    newNote,
                    newMutation.newDuration,
                    newMutation.matchGroup
                )
            }

            if (newMutation.pitchOffset) {
                this.setNotePitch(
                    newNote,
                    newNote.pitch + newMutation.pitchOffset
                )
            }

            this.addNoteToGroup(
                newNote,
                newMutation.timeSignature,
                newMutation.sections,
                newMutation.matchGroup
            )

            return newNote
        })
    }

    /**
     * Use to copy this NotesObject instance into another NotesObject instance
     * This will modify the notes' noteID (because we're creating new objects), and
     * modify the meta layer property of each notes to the layer argument
     */
    public copy(
        ts: TimeSignature,
        sections: Section[],
        layer: string
    ): NotesObject {
        const notes = new NotesObject()

        this.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
            for (const n of noteGroup) {
                const meta = cloneDeep(n.meta)
                meta.layer = layer

                const newNote = new Note({
                    pitch: n.pitch,
                    start: n.start,
                    duration: n.duration,
                    meta,
                })

                notes.addNoteToGroup(newNote, ts, sections)
            }

            return true
        })

        return notes
    }

    public deleteNoteIDsFromNoteGroup(
        start: FractionString,
        noteIDs: string[]
    ) {
        const noteGroup = this.getNoteGroup(start)

        if (noteGroup) {
            for (let n = noteGroup.length - 1; n >= 0; n--) {
                if (noteIDs.includes(noteGroup[n].noteID)) {
                    const { start, noteID } = noteGroup[n]
                    this.removeNoteFromGroup(start, noteID)
                }
            }
        }
    }

    public get noteGroupsLength(): number {
        return Object.keys(this.noteGroups).length
    }

    public changeNoteDuration(
        note: Note,
        duration: string,
        matchDuration: boolean = true
    ) {
        duration = Time.simplifyFractionFromString(duration)

        const noteGroup: Note[] = this.getNoteGroup(note.start)

        if (!noteGroup) {
            note.duration = duration

            return
        }

        if (matchDuration) {
            for (let n of noteGroup) {
                n.duration = duration
            }
        }
    }

    public getNotePitchesAtTime({
        start,
        end,
    }: {
        start: FractionString
        end: FractionString
    }): number[] {
        const pitches = []

        this.manipulateNoteGroups(
            (noteGroup: ImmutableNote[]) => {
                if (
                    Time.compareTwoFractions(noteGroup[0].start, end) !== "lt"
                ) {
                    return false
                }

                for (const note of noteGroup) {
                    pitches.push(note.pitch)
                }

                return true
            },
            [start, end],
            false
        )

        return [...new Set(pitches)].sort()
    }

    /**
     * Always access a note group with this function to make sure the fraction is simplified!
     * @param noteStart
     * @returns An array of notes, or undefined if no group exists
     */
    public getNoteGroup(noteStart: string): Note[] | undefined {
        const simplifiedFraction = Time.simplifyFractionFromString(noteStart)

        if (!this.noteGroups[simplifiedFraction]) {
            return undefined
        }

        if (this.noteGroups[simplifiedFraction].length === 0) {
            this.deleteNoteGroup(simplifiedFraction)

            return undefined
        }

        return this.noteGroups[simplifiedFraction]
    }

    /**
     * Use this function in order to find the note instance that corresponds to a specific unique noteID
     * @param noteStart
     * @param noteID
     * @returns
     */
    public getNoteByID(noteStart: string, noteID: string): Note | undefined {
        const group = this.getNoteGroup(noteStart)

        if (group) {
            for (const note of group) {
                if (note.noteID === noteID) {
                    return note
                }
            }
        }

        return undefined
    }

    /***
     * Use this function to find a note by its start and pitch without specifying a noteID
     */
    public getNoteByStartAndPitch(noteStart: string, pitch): Note | undefined {
        const group = this.getNoteGroup(noteStart)

        if (group) {
            return group.find(note => note.pitch === pitch)
        }

        return undefined
    }

    /**
     * Use this function in order to find the note instance that corresponds to a specific unique noteID
     * without specifying a note start. Warning, this may be more compute intensive than the getNoteByID function
     * if the NotesObject contains a large number of notes
     * @param noteID
     * @returns
     */
    public getNoteByIDWithoutNoteStart(noteID: string): {
        note: Note | undefined
        noteGroup: Note[] | undefined
    } {
        let selectedNote = undefined
        let selectedNoteGroup = undefined

        this.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
            const note = noteGroup.find(n => n.noteID === noteID)

            if (note) {
                selectedNote = note
                selectedNoteGroup = noteGroup
                return false
            }

            return true
        })

        return {
            note: selectedNote,
            noteGroup: selectedNoteGroup,
        }
    }

    /**
     * !! WARNING !!: This function is unlikely to scale well for scores with a large number of notes.
     * Please make sure to profile your usage of this function before using this in production code
     *
     * Returns a flat array of notes that are ordered by start time
     */
    public getFlatArray(
        groupRange: [string, string] | undefined = undefined
    ): Note[] {
        let notes: Note[] = []

        this.manipulateNoteGroups((noteGroup: Note[]) => {
            notes = notes.concat(noteGroup)

            return true
        }, groupRange)

        return notes
    }

    /**
     * This function returns a note for a given timesteps & pitch value.
     * The timesteps value can be any value between the start and the end of the note.
     *
     * @param timesteps
     * @param pitch
     * @returns a Note object or undefined
     */
    public getNoteAtCoordinates(
        timesteps: number,
        pitch: number,
        timestepsRange: [number, number]
    ): Note | undefined {
        timestepsRange[0] = Math.round(timestepsRange[0])
        timestepsRange[1] = Math.round(timestepsRange[1])

        const fractionRange: [string, string] = [
            Time.timestepToFraction(timestepsRange[0], TIMESTEP_RES),
            Time.timestepToFraction(timestepsRange[1], TIMESTEP_RES),
        ]

        const fraction = Time.timestepToFraction(timesteps, TIMESTEP_RES)

        let selectedNote: Note

        this.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
            const comparison = Time.fractionIsInBoundaries(
                noteGroup[0],
                fraction,
                true,
                false
            )

            if (!comparison) {
                return true
            }

            for (let note of noteGroup) {
                if (note.pitch === pitch) {
                    selectedNote = note as Note
                }
            }

            return false
        }, fractionRange)

        return selectedNote
    }

    /**
     * This function returns a note group for a given timesteps value.
     * The timesteps value can be any value between the start and the end of the note.
     *
     * @param timesteps
     * @returns a Note Array or undefined
     */
    public getNoteGroupAtCoordinates(
        timesteps: number,
        timestepsRange?: [number, number]
    ): Readonly<Note>[] | undefined {
        const fraction = Time.timestepToFraction(timesteps, TIMESTEP_RES)

        let fractionRange: [string, string] | undefined = undefined

        if (timestepsRange !== undefined) {
            timestepsRange[0] = Math.round(timestepsRange[0])
            timestepsRange[1] = Math.round(timestepsRange[1])

            fractionRange = [
                Time.timestepToFraction(timestepsRange[0], TIMESTEP_RES),
                Time.timestepToFraction(timestepsRange[1], TIMESTEP_RES),
            ]
        }

        let res: Readonly<Note>[]

        this.manipulateNoteGroups((noteGroup: ImmutableNote[]) => {
            const comparison = Time.fractionIsInBoundaries(
                noteGroup[0],
                fraction,
                true,
                false
            )

            if (!comparison) {
                return true
            }

            res = noteGroup

            return false
        }, fractionRange)

        return res
    }

    /**
     * This function iterate through all of the note groups, as well as the rests. Rests are basically the time
     * between the end of a note group and the start of another. This method is useful for computing heuristics
     * that rely on having information about rests, and neighbouring notes.
     */
    public forEachGroupAndRests(
        higherOrderFunction: ForEachGroupAndRestsHigherOrderFunction,
        groupRange?: [string, string],
        inclusive?: boolean
    ) {
        this.manipulateNoteGroups(
            (current, next, previous) => {
                // Make sure we stay in the group range.
                if (groupRange?.length === 2 && !inclusive) {
                    const comparison = Time.compareTwoFractions(
                        current[0].start,
                        groupRange[1]
                    )

                    if (comparison === "eq" || comparison === "gt") {
                        return false
                    }
                }

                const rest = NotesObject.getNoteRest(previous, current)

                if (rest !== undefined) {
                    higherOrderFunction(previous, [rest], current)

                    higherOrderFunction([rest], current, next)
                } else {
                    higherOrderFunction(previous, current, next)
                }

                return true
            },
            groupRange,
            inclusive
        )
    }

    static getNoteRest(
        previous: Note[] | undefined,
        current: Note[]
    ): Rest | undefined {
        const start = current[0].start
        const previousEnd: FractionString | undefined = previous
            ? Time.addTwoFractions(previous[0].start, previous[0].duration)
            : undefined

        // If current is the first note group and is preceeded by a rest
        const firstNoteGroupPreceededByRest =
            previous === undefined &&
            Time.compareTwoFractions(start, "0") === "gt"

        // If current is not the first note group and is preceeded by a rest
        const subsequentGroupPreceededByRest =
            previous !== undefined &&
            Time.compareTwoFractions(previousEnd, start) === "lt"

        if (firstNoteGroupPreceededByRest || subsequentGroupPreceededByRest) {
            const rest = {
                start: previous ? previousEnd : "0",
                duration: previous
                    ? Time.addTwoFractions(start, previousEnd, true)
                    : start,
            } as Rest

            return rest
        }

        return undefined
    }

    /**
     * Used to access the note group at the given noteStart. Note: unlike getNoteGroup, this function will
     * consider note groups for which the start and end duration is respectively less AND greater than noteStart.
     * Useful for note drawing
     * This method will also return previous and next note groups, if those exist
     * @param noteStart
     */
    public getNoteGroupAndSurroundings(
        noteStart: string
    ): NoteGroupAndSurroundings {
        const result: NoteGroupAndSurroundings = {
            noteGroup: undefined,
            previousNoteGroup: undefined,
            nextNoteGroup: undefined,
        }

        this.manipulateNoteGroups((noteGroup: Note[]) => {
            const isPreviousGroup =
                Time.compareTwoFractions(noteGroup[0].start, noteStart) === "lt"
            const isCurrentGroup = Time.fractionIsInBoundaries(
                noteGroup[0],
                noteStart,
                true,
                false
            )
            const isNextGroup =
                Time.compareTwoFractions(noteGroup[0].start, noteStart) === "gt"

            if (isPreviousGroup && !isCurrentGroup) {
                result.previousNoteGroup = noteGroup
            } else if (isCurrentGroup) {
                result.noteGroup = noteGroup
            } else if (isNextGroup) {
                result.nextNoteGroup = noteGroup

                return false
            }

            return true
        })

        return result
    }

    /**
     * Get the group for the start value provided, and the groups directly adjacent to it.
     * Useful for e.g. in ScoreDecoding, when decoding a score with realTimeSampler flag set to true
     * @param groupStart
     * @param previousGroupStart
     * @returns
     */
    public getNoteGroupAndAdjacents(
        previousGroupStart: string,
        noteStart: string
    ): NoteGroupAndSurroundings {
        const result: NoteGroupAndSurroundings = {
            noteGroup: undefined,
            previousNoteGroup: undefined,
            nextNoteGroup: undefined,
        }

        const noteGroup: Note[] = this.getNoteGroup(noteStart)

        if (noteGroup && noteGroup.length > 0) {
            result.noteGroup = noteGroup

            const nextNoteGroup = this.getNoteGroup(
                Time.addTwoFractions(noteGroup[0].start, noteGroup[0].duration)
            )

            if (this.isAdjacentNoteGroup(noteGroup, nextNoteGroup) === "left") {
                result.nextNoteGroup = nextNoteGroup
            }

            const previousNoteGroup = this.getNoteGroup(previousGroupStart)

            if (
                this.isAdjacentNoteGroup(noteGroup, previousNoteGroup) ===
                "right"
            ) {
                result.previousNoteGroup = previousNoteGroup
            }
        }

        return result
    }

    public isAdjacentNoteGroup(
        currentGroup: Note[],
        groupToCompare: Note[] | undefined
    ): "left" | "right" | "not adjacent" {
        if (
            !groupToCompare ||
            groupToCompare.length === 0 ||
            currentGroup.length === 0
        ) {
            return "not adjacent"
        }

        const currentGroupEnd = Time.addTwoFractions(
            currentGroup[0].start,
            currentGroup[0].duration
        )

        if (
            Time.compareTwoFractions(
                currentGroupEnd,
                groupToCompare[0].start
            ) === "eq"
        ) {
            return "left"
        }

        const groupToCompareEnd = Time.addTwoFractions(
            groupToCompare[0].start,
            groupToCompare[0].duration
        )

        if (
            Time.compareTwoFractions(
                groupToCompareEnd,
                currentGroup[0].start
            ) === "eq"
        ) {
            return "right"
        }

        return "not adjacent"
    }

    public forEach(callback: (note: Note) => void) {
        for (let key in this.noteGroups) {
            this.noteGroups[key].forEach(n => callback(n))
        }
    }

    // @todo: consider removing a note by id
    public removeNoteFromGroup(noteStart: string, noteId: string) {
        const noteById = this.getNoteByID(noteStart, noteId)
        if (!noteById) return
        const group = this.getNoteGroup(noteById.start)
        if (!group) return
        const noteIndex = group.findIndex(note => note.noteID === noteId)
        group.splice(noteIndex, 1)
        this.updateFirstGroup()
        this.length--
    }

    /**
     * Use to delete a note group from the private attribute noteObject
     * @param noteStart a fraction string
     */
    public deleteNoteGroup(noteStart: string) {
        const simplifiedFraction = Time.simplifyFractionFromString(noteStart)

        this.sortedKeys = undefined

        if (this.noteGroups[simplifiedFraction] !== undefined) {
            this.length =
                this.length - this.noteGroups[simplifiedFraction].length
            delete this.noteGroups[simplifiedFraction]

            if (
                Time.compareTwoFractions(simplifiedFraction, this.lastGroup) ===
                "eq"
            ) {
                const keys = this.getSortedKeys()
                this.updateLastGroup(keys[keys.length - 1])
            }

            if (
                Time.compareTwoFractions(
                    simplifiedFraction,
                    this.firstGroup
                ) === "eq"
            ) {
                const keys = this.getSortedKeys()
                this.updateFirstGroup(keys[0])
            }
        }
    }

    public addNotesToGroup(
        notes: Note[],
        timeSignature: TimeSignature,
        sections: Section[]
    ) {
        for (const note of notes) {
            this.addNoteToGroup(note, timeSignature, sections)
        }
    }

    /**
     * Use to add a note to a note group, to the private attribute noteObject
     * @param noteStart a fraction string
     */
    public addNoteToGroup(
        note: Note,
        timeSignature: TimeSignature,
        sections: Section[],
        matchDuration: boolean = true
    ) {
        const start = Time.simplifyFractionFromString(note.start)

        this.sortedKeys = undefined

        if (this.noteGroups[start] === undefined) {
            this.noteGroups[start] = []
        }

        for (let n of this.noteGroups[start]) {
            if (n.pitch === note.pitch) {
                return
            }
        }

        this.setNoteStart(note, start, timeSignature, sections)
        if (matchDuration) {
            if (this.noteGroups[note.start].length > 0) {
                note.duration = this.noteGroups[note.start][0].duration
            } else {
                note.duration = Time.simplifyFractionFromString(note.duration)
            }
        }

        this.setLowestAndHighestNote(note)

        this.noteGroups[note.start].push(note)
        this.length++
    }

    public merge(
        notes: NotesObject,
        timeSignature: TimeSignature,
        sections: Section[]
    ) {
        notes.manipulateNoteGroups((noteGroup: Note[]) => {
            for (const note of noteGroup) {
                this.addNoteToGroup(note, timeSignature, sections)
            }

            return true
        })
    }

    public clearNotes() {
        this.sortedKeys = undefined
        this.noteGroups = {}
        this.length = 0
        this.highestNote = DEFAULT_HIGHEST_NOTE
        this.lowestNote = DEFAULT_LOWEST_NOTE
    }

    public static setPhraseAndSection(
        note: Note,
        phrase: number,
        sections: Section[]
    ) {
        note.meta.section = Note.getSectionForNoteStart(sections, note.start)
        note.meta.phrase = phrase
    }

    public setNoteStart(
        note: Note,
        start: FractionString,
        timeSignature: TimeSignature,
        sections: Section[]
    ) {
        note.start = start

        if (Time.compareTwoFractions(start, this.lastGroup) === "gt") {
            this.updateLastGroup(note.start)
        }

        if (Time.compareTwoFractions(start, this.firstGroup) !== "gt") {
            this.updateFirstGroup(note.start)
        }

        note.beat = Time.fractionToBeat(note.start, timeSignature)
        note.meta.section = Note.getSectionForNoteStart(sections, note.start)
    }

    public setNotePitch(note: Note, pitch: number) {
        note.pitch = Math.min(
            Math.max(LOWEST_PIANO_PITCH, pitch),
            HIGHEST_PIANO_PITCH
        )

        this.setLowestAndHighestNote(note)
    }

    public setLowestAndHighestNote(note: Note) {
        this.highestNote = Math.max(note.pitch, this.highestNote)
        this.lowestNote = Math.min(note.pitch, this.lowestNote)
    }

    public getNoteGroupStarts() {
        return Object.keys(this.noteGroups)
    }

    private getSortedKeys() {
        if (this.sortedKeys !== undefined) {
            return this.sortedKeys
        }

        this.sortedKeys = Object.keys(this.noteGroups).sort(
            (a: string, b: string) => {
                let aStart: any = Time.fractionToDictionary(a)
                aStart = aStart.numerator / aStart.denominator

                let bStart: any = Time.fractionToDictionary(b)
                bStart = bStart.numerator / bStart.denominator

                return aStart - bStart
            }
        )

        return this.sortedKeys
    }

    public getAllNoteIDs() {
        const noteIDs: string[] = []

        this.forEach((note: Note) => {
            noteIDs.push(note.noteID)
        })

        return noteIDs
    }

    public getEnd() {
        const noteGroup = this.getNoteGroup(this.lastGroup)

        if (!noteGroup || noteGroup.length === 0) {
            return "0"
        }

        const end = Time.addTwoFractions(
            noteGroup[0].start,
            noteGroup[0].duration
        )

        return end
    }

    public getLastGroup(): Note[] {
        const noteGroup = this.getNoteGroup(this.lastGroup)

        if (!noteGroup) {
            return []
        }

        return noteGroup
    }

    public getFirstGroup(): Note[] {
        const noteGroup = this.getNoteGroup(this.firstGroup)

        if (!noteGroup) {
            return []
        }

        return noteGroup
    }

    public getPitchRange(): { lowestNote: number; highestNote: number } {
        return {
            lowestNote: this.lowestNote,
            highestNote: this.highestNote,
        }
    }

    /**
     * Checks whether a note is present in a NotesObject
     * instance byreference or by deep comparison
     * @param note
     * @param notesObject
     */
    public noteExists(note: Note): boolean {
        const group: Note[] | undefined = this.getNoteGroup(note.start)

        if (group === undefined) {
            return false
        }

        for (const groupNote of group) {
            if (groupNote === note || note.isEqualTo(groupNote, true)) {
                return true
            }
        }

        return false
    }

    public getFirstGroupStart() {
        const sortedKeys = this.getSortedKeys()

        if (sortedKeys?.length) {
            return sortedKeys[0]
        }

        return undefined
    }

    public getLastGroupStart() {
        const sortedKeys = this.getSortedKeys()

        if (sortedKeys?.length) {
            return sortedKeys[Math.max(0, sortedKeys.length - 1)]
        }

        return undefined
    }

    /**
     * This method updates the firstGroup property of a notesObject.
     * By passing a param to this method, we can update the firstGroup
     * property using a certain string. In case no argument is passed,
     * the firstGroup will only be automaitcally updated in case it
     * currently is undefined.
     * @param firstGroup optional string
     * @returns firstGroup
     */
    public updateFirstGroup(firstGroup?: FractionString) {
        if (firstGroup) {
            this.firstGroup = firstGroup
        } else if (!firstGroup) {
            this.firstGroup = this.getFirstGroupStart()
        }

        return this.firstGroup
    }

    /**
     * This method updates the lastGroup property of a notesObject.
     * By passing a param to this method, we can update the lastGroup
     * property using a certain string. In case no argument is passed,
     * the lastGroup will only be automaitcally updated in case it
     * currently is undefined.
     * @param lastGroup optional string
     * @returns lastGroup
     */
    public updateLastGroup(lastGroup?: FractionString) {
        if (lastGroup) {
            this.lastGroup = lastGroup
        } else if (!lastGroup) {
            this.lastGroup = this.getLastGroupStart()
        }

        return this.lastGroup
    }

    static notesHaveSamePitches(notes1: Note[], notes2: Note[]) {
        const pitches1 = notes1.map(n => n.pitch).sort()
        const pitches2 = notes2.map(n => n.pitch).sort()

        return Misc.arraysAreEqual(pitches1, pitches2)
    }

    static tieNotes(
        notes: NotesObject,
        timeSignature: TimeSignature,
        sections: Section[]
    ): NotesObject {
        const newNotes = new NotesObject()

        let lastNoteGroup: Note[] = []

        notes.manipulateNoteGroups(noteGroup => {
            const shouldTieNotes =
                lastNoteGroup.length > 0 &&
                NotesObject.notesHaveSamePitches(lastNoteGroup, noteGroup) &&
                Time.compareTwoFractions(
                    Time.addTwoFractions(
                        lastNoteGroup[0].start,
                        lastNoteGroup[0].duration
                    ),
                    noteGroup[0].start
                ) !== "lt"

            if (shouldTieNotes) {
                const note = lastNoteGroup[0]

                note.duration = Time.addTwoFractions(
                    note.duration,
                    noteGroup[0].duration
                )

                newNotes.changeNoteDuration(note, note.duration)

                lastNoteGroup = newNotes.getNoteGroup(note.start)
            } else {
                lastNoteGroup = noteGroup

                newNotes.addNotesToGroup(lastNoteGroup, timeSignature, sections)
            }

            return true
        })

        return newNotes
    }
}
