import { Component, ChangeDetectorRef, OnInit, HostListener, ViewChild, AfterViewInit, ElementRef } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from '../../services/api.service'
import { PlayerService } from '../../services/audio/player/player.service'
import { FolderService } from '../../services/folder.service'
import { HelperService } from '../../helpers/helper.service'
import { MessageService } from '../../services/message.service'
import { ParentClass } from '../../parent'
import { playerQuery } from '../../../../../common-lib/client-only/general/classes/playerStateManagement'
import { Misc } from '@common-lib/modules/misc'

@Component({
  selector: 'app-publicplayer',
  templateUrl: 'publicplayer.component.html',
  styles: []
})
export class PublicPlayerComponent extends ParentClass implements AfterViewInit{
    progress = 0
    isPlaying = false
    playbackMode:string = "single"
    playlistName:string = ""
    playlistTime = {}
    playlist = []
    currentSong
    loopPlaylist:boolean = true
    currentSongIndex: number = 0;
    numberOfSongs = 0
    currentSongName:string = ""
    timeElapsedText = ""
    durationText = ""
    error:{show:boolean, text:string} = { show:false, text:"" }
    message = { type: "", text: "", show: false }

    compositionID
    folderID
    
    howl
    interval
    loading:boolean = true
    composition = {
        name: '',
        preset: '',
        keySignature: '',
        timeSignature: [4, 4],
        pacing: '',
        ensemble: '',
        error: null,
        duration:0
    }

    scrollConfig = { useBothWheelAxes: false }

    get isLastSong(){
        return this.currentSongIndex == (this.numberOfSongs - 1)
    }

    get isFirstSong(){
        return this.currentSongIndex == 0;
    }

    liked = false

    @ViewChild('progressBarWrapper', {static: false}) elementView: ElementRef

	constructor(
        private apiService:ApiService, 
        private route:ActivatedRoute, 
        private playerService:PlayerService, 
        private folderService: FolderService, 
        private helper: HelperService,
        public messageService: MessageService,
    ) {
        super()

        this.message = this.messageService.defaultMessage

        this.subscribe(this.route.queryParams, queryParams => {
            this.compositionID = queryParams['c']
            this.folderID = queryParams['f']
            this.setPlaybackMode()

            if(this.playbackMode == "single"){
                this.loading = true
                this.loadTrack()
            } else {
                this.folderService.selectedFolderID.next(this.folderID) // folder needs to be setup, else it retrieves 0 compositions
                this.loading = true;
                this.loadPlaylist()
            }
        })

        this.subscribe(playerQuery.select("timeElapsed"), timeElapsed => {
            this.timeElapsedText = Misc.convertSecondsToString(timeElapsed)

            if (playerQuery.content !== undefined) {
                this.progress = (timeElapsed * 100) / playerQuery.duration
            }
        })

        this.subscribe(playerQuery.allState$, state => {
            if (state.composition !== undefined && this.playbackMode == "playlist") {
                this.composition = this.getCompositionById(state.composition._id)
                this.currentSongIndex = this.playlist.findIndex( c => c._id == this.composition["_id"] )

                const timeElapsed = state.timeElapsed
                this.durationText = Misc.convertSecondsToString(state.composition.duration)
                this.timeElapsedText = Misc.convertSecondsToString(timeElapsed)

                this.isPlaying = state.status === "loading"
            }
        })

        this.playerService.trackEndedEE.subscribe(data => {
            if(this.playbackMode == "single"){
                this.playerService.pause()
                this.progress = 0
                this.timeElapsedText = "0:00"
                this.playerService.setTimeFromSeconds(0)
                this.isPlaying = false
            }
            // console.log("Track ended", data)
        })
    }
    
    ngAfterViewInit(){
        this.positionCrispChatButton()

        var likedCompositions = localStorage.getItem('likedCompositions')

        if (likedCompositions == null) {
            var object = {}

            object[this.compositionID] = false

            localStorage.setItem('likedCompositions', JSON.stringify(object))
        }
    }

    isLiked() {
        var likedCompositions = localStorage.getItem('likedCompositions')

        if (likedCompositions != null) {
            likedCompositions = JSON.parse(likedCompositions)

            return likedCompositions[this.compositionID]
        }

        return false
    }

    toggleLiked(value) {
        var likedCompositions:any = localStorage.getItem('likedCompositions')

        if (likedCompositions != null) {
            likedCompositions = JSON.parse(likedCompositions)
        }

        else {
            likedCompositions = {}
            
        }

        likedCompositions[this.compositionID] = value

        localStorage.setItem('likedCompositions', JSON.stringify(likedCompositions))

        this.apiService.request('/composition/publicLike', { compositionID: this.compositionID, liked: value }, "primary", "post", true).catch((err) => {
            return Promise.resolve()
        })
    }

    setProgress(event) {
        var newTime = this.composition.duration * event["offsetX"] / this.elementView.nativeElement.offsetWidth
        this.progress = 100 * event["offsetX"] / this.elementView.nativeElement.offsetWidth
        this.playerService.setTimeFromSeconds(newTime)
    }

    setPlaybackMode(){
        if(this.compositionID && this.compositionID != ""){
            this.playbackMode = "single"
        } 
        else if (this.folderID && this.folderID != ""){
            this.playbackMode = "playlist"
        }
    }

    totalPlaylistDuration(compositions:Array<any>){
        let totalDurationInSeconds = 0

        if (!compositions || !Array.isArray(compositions)){
            return totalDurationInSeconds
        }

        for(let composition of compositions){
            totalDurationInSeconds += composition.duration
        }
        return Number(totalDurationInSeconds)
    }

    loadTrack() {
        this.playlist = []

        return this.apiService.authRequest('/composition/getPublic', { compositionID: this.compositionID }, "primary", true)
        .then((res) => {
            this.composition = res.composition
            this.composition['contentType'] = "composition"
            
            return this.playerService.loadNewTrack(this.compositionID, this.composition['contentType'], false, false)
        })
        .then(() => {
            this.playerService.pause()
            this.loading = false
        })
        .catch((err) => {
            this.composition['error'] = true
            this.messageService.onError(this.message, err)
        })
    }

    loadPlaylist(){
        this.folderService.fetchPublicFolderContent(this.folderID)

        .then((res) => {
            return this.setupPlaylist(res)
        })

        .then(() => {
            this.loading = false;
        })

        .catch((err) => {
            this.playlist = []
            this.loading = false
            this.messageService.onError(this.message, err)
        })
    }

    setupPlaylist(apiResult){
        try {
            this.playlist = apiResult.compositions ? apiResult.compositions : []

            for (var c = 0; c < this.playlist.length; c++) {
                this.playlist[c].contentType = "composition"
            }

            this.folderService.setContent({
                content: this.playlist
            })
            
            this.playlistName = apiResult.folder.name ? apiResult.folder.name : "Unknown playlist"
            this.playlistTime = this.helper.convertSecondsToTime(this.totalPlaylistDuration(this.playlist))
            this.numberOfSongs = this.playlist.length
            this.composition = this.playlist[0]

            return Promise.resolve(true)            
        }
        
        catch (err) {
            return Promise.reject(err)
        }
    }

    getSongFromPlaylist(index:number){
        return this.numberOfSongs > index ? this.playlist[index] : undefined 
    }

    goToAIVA() {
        window.open('https://creators.aiva.ai/', '_blank')
    }
    
    play(index?) {
        this.isPlaying = true

        if(this.playbackMode == "single") {
            this.playerService.play()
        } else {
            if(index >= 0) this.currentSongIndex = index
            this.composition = this.getSongFromPlaylist(this.currentSongIndex)
            this.playerService.loadNewTrack(this.composition["_id"], this.composition['contentType'], true, false)
        }
    }

    pause() {
        this.isPlaying = false
        this.playerService.pause()
    }

    next(){
        if(this.isLastSong){
            this.play(0)
        } else {
            ++this.currentSongIndex;
            this.play(this.currentSongIndex)
        }
    }

    previous(){
        if(this.isFirstSong){
            this.play(this.numberOfSongs-1)
        } else {
            --this.currentSongIndex;
            this.play(this.currentSongIndex)
        }
    }

    stop(){
        this.isPlaying = false
        this.playerService.stop()
        this.currentSongIndex = 0
        this.composition = this.playlist[0]
        this.timeElapsedText = "0:00"
        this.progress = 0
    }

    @HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {	
        
        if(!event || !event.code){
            return
        }
        
        switch (event.code) {
            case "Space":

                if(this.isPlaying) {
                    this.pause()
                }
        
                else {
                   this.play()
                }
                
                break;
        
            default:
                break;
        }
        
    }

    getTimeElapsed() {
        return this.formatTime(playerQuery.timeElapsed)
    }
    
    getTotalTime() {
        return this.composition ? this.formatTime(this.composition["duration"]) : "0:00"
    }

    formatTime(time) {
        var result = ""

        var seconds:any = Math.floor(time % 60)
        var minutes:any = Math.floor(time / 60)

        if (seconds <= 9) {
            result = minutes + ":0" + seconds
        }

        else {
            result = minutes + ":" + seconds
        }

        return result
    }

    isPlayingComposition(composition){
        return this.playerService.isPlayingComposition(composition)
    }

    playerIsLoadingComposition(compositionID){
        return this.playerService.playerIsLoadingComposition(compositionID)
    }

    getCompositionDuration(composition){
        return Misc.convertSecondsToString(composition.duration)
    }

    getCompositionById(compositionID){
        return this.playlist.find( composition => composition._id == compositionID)
    }

    mouseOnComposition(index, value):void {
		this.playlist[index].isHovered = value
    }

    positionCrispChatButton(){
        // the interval is there to check repeatedly if the button has already been added to the DOM by crisp
        let tries = 0
        const maxTries = 20
        let lookForButton = setInterval(() => {
            tries++
            if(tries >= maxTries) clearInterval(lookForButton)

            const chatButton: HTMLElement = document.querySelector(".crisp-client .cc-unoo")
            if(chatButton) {
                chatButton.style.setProperty("bottom", "0px", "important")
                chatButton.style.setProperty("margin-bottom", "5px", "important")
                clearInterval(lookForButton)
            }
        },200)
    }
    
}