<div class="modal-title">Copy chords</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<div class="content-wrapper">
    <div class="row">
        <span>from section: </span>
        <dropdown
            class="inputs"
            [value]="sectionFrom"
            [items]="sectionOptions"
            (selectedItem)="selectSectionFrom($event.new.value)">
        </dropdown>
    </div>
    <div class="row">
        <span>to section:</span>
        <span>{{ sectionTo.title }}</span>
    </div>
    <a-button
        class="submit-btn"
        label="Copy"
        width="100%"
        (click)="copySection()">
    </a-button>
</div>
