import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from "@angular/core"
import MelodyLayer from "@common-lib/classes/generationprofiles/melody/melodylayer"
import { Misc } from "@common-lib/modules/misc"
import GPSettings from "@common-lib/classes/generationprofiles/gpsettings"
import { Effect } from "@common-lib/classes/score/effect"
import Harmony from "@common-lib/classes/generationprofiles/harmony/harmony"
import { LabelType } from "@angular-slider/ngx-slider"
import { ModalService } from "@services/modal.service"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { ActivatedRoute, Router } from "@angular/router"
import { takeUntil } from "rxjs/operators"
import { ReplaySubject } from "rxjs/internal/ReplaySubject"
import { environment } from "@environments/environment"
import { FolderService } from "@services/folder.service"
import { TokenService } from "@services/token.service"
import { ApiService } from "@services/api.service"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import {
    GP_HARMONY_STRATEGY,
    MIN_GP_TEMPO,
} from "@common-lib/constants/constants"
import { GPHarmonyStrategy } from "@common-lib//types/generationProfiles"
import { Item } from "../reusable/dropdown/dropdown.component"
import { SourcePackService } from "../../services/source-packs/sourcepacks.service"
import { InstrumentsService } from "@services/instruments/instruments.service"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import {
    MenuOption,
    MenuOptions,
} from "../reusable/menu-options/menu-options.component"
import { featureFlags } from "@common-lib/utils/feature-flags"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"

@Component({
    selector: "generation-profile",
    templateUrl: "generation-profile.component.html",
    styleUrls: ["generation-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerationProfileComponent implements AfterViewInit {
    keyModeOptions: Item[]
    pitchClassOptions: Item[]
    harmonyTypes
    gpView = "edit"

    selectedPack = null
    selectedLayer = null

    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    emotions = [1, 2, 3, 4, 5]

    noGPLoaded = false

    moreOptionsMenu: boolean = false
    moreOptions = {
        x: 0,
        y: 0,
    }

    dragEndListener
    dragEnterListener
    dragOverListener

    gpIsLoading = true

    error: string | undefined

    tileHovered = ""

    tooltips = {
        automixing:
            "When enabled, the auto-mixing feature will automatically balance the volume of each track in a composition generated with this style. You can customize how loud each layer will be mixed relative to each other in the 'Mixing & Effects' panel of each layer.",
        noLayers:
            "Please add at least one non-ornamental layer to be able to generate compositions from a generation profile.",
        tempoRange:
            "Controls the speed at which compositions are played back at, in beats per minute (BPM). The range can only be set in increments of five BPM.",
        expressivePerformance:
            "If toggled, this control will apply a human touch to the dynamics. It is recommended to enable for orchestral styles, and disable for electronic / synth heavy styles.",
        tempoVariations:
            "Controls how much the tempo is allowed to vary in generated compositions, to create a rubato effect.",
        infoToggle:
            "Tutorial mode: toggle to display info bubbles next to every settings.",
        dynamicRange:
            "Controls how intensly instruments can play in generated compositions",
        emotion:
            "Select an emotion in order to automatically set tempo, dynamic, harmony and melody settings to recreate a specific mood.",
        development:
            "Controls the structural development (arrangement) of a song",
        harmonicStrategy:
            "Conditions the GP to use modal or functional harmony rules during generation.",
        harmonicDataset:
            "Selects a dataset of chord progressions this GP will be trained on in order to create compositions with the desired harmonic qualities.",
        harmonicRepetition:
            "Conditions how repetitive the chord progressions will be in generated compositions.",
        harmonicPacing:
            "This controls how fast or slow the harmony will change. For example, selecting 'Slow' may encourage the GP to create compositions with chord progressions made up of 1-2 measure long chords.",
    }

    // dropdown options should use a static variable that is populated on init, so we don't have flickering dropdowns in OSX
    timeSignatureOptions = []
    menuOptions:
        | {
              type: "layer" | "gp"
              options: MenuOptions<any>
          }
        | undefined

    dynamicOptions
    keySignatureOptions
    expressivePerformanceOptions
    phraseLengthOptions

    timeSignatureLoading: boolean = false

    gpValidation = {}

    harmonicRhythm
    harmonicRepetition

    private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)

    constructor(
        private sourcePacks: SourcePackService,
        private instruments: InstrumentsService,
        private tokenService: TokenService,
        private apiService: ApiService,
        private route: ActivatedRoute,
        private router: Router,
        private gpService: GenerationProfileService,
        private modalService: ModalService,
        private ref: ChangeDetectorRef,
        private folderService: FolderService
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false
        this.router.onSameUrlNavigation = "reload"

        this.gpService.redirectToView.subscribe(value => {
            if (value == null) {
                return
            }

            if (value == "compositions") {
                this.openGeneratedCompositions()
            } else {
                this.gpView = value
                this.gpService.refreshGenerationProfileUI.next(true)
            }
        })
    }

    getKeyMode() {
        const harmony: Harmony = this.getHarmony()

        return {
            value: harmony?.keySignature?.keyMode,
            name: Misc.capitalizeFirstLetter(harmony?.keySignature?.keyMode),
        }
    }

    getPitchClass() {
        const harmony: Harmony = this.getHarmony()

        return {
            value: harmony?.keySignature?.pitchClass,
            name: harmony?.keySignature?.pitchClass,
        }
    }

    getHarmonicStrategy() {
        const harmony: Harmony = this.getHarmony()

        return {
            value: harmony.strategy,
            name: harmony.strategy,
        }
    }

    getKeyModeOptions() {
        const options = Misc.getKeyModeOptions(this.getHarmonicStrategy().value)

        this.keyModeOptions = options.map(mode => {
            return {
                value: mode,
                name: Misc.capitalizeFirstLetter(mode),
            }
        })
    }

    getPitchClassOptions() {
        const options = Misc.getPitchClassOptions(
            this.getKeyMode()?.value?.toLowerCase(),
            this.getHarmonicStrategy()?.value
        )

        this.pitchClassOptions = options.map(mode => {
            return {
                value: mode,
                name: mode,
            }
        })
    }

    selectKeyMode(event) {
        const harmony: Harmony = this.getHarmony()
        harmony.keySignature.keyMode = event.new.value
        harmony.selectCompatiblePitchClass()

        this.getPitchClassOptions()

        this.gpService.setAsUpdated("selectKeyMode", {
            layer: "Settings",
        })
    }

    selectPitchClass(event) {
        const harmony: Harmony = this.getHarmony()
        harmony.keySignature.pitchClass = event.new.value

        this.gpService.setAsUpdated("selectPitchClass", {
            layer: "Settings",
        })
    }

    preventDefaultDragBehavior(event) {
        event.preventDefault()
    }

    handleDragEnd(event) {
        this.preventDefaultDragBehavior(event)

        this.removeDragEventListeners()

        this.clearDragging()
    }

    removeDragEventListeners() {
        if (this.dragEnterListener != null || this.dragEndListener != null) {
            window.document.removeEventListener("drop", this.dragEndListener)
            window.document.removeEventListener(
                "dragover",
                this.dragEnterListener
            )
            window.document.removeEventListener(
                "dragenter",
                this.dragEnterListener
            )
            window.document.removeEventListener(
                "mousemove",
                this.dragEndListener
            )
        }

        this.dragEnterListener = null
        this.dragEndListener = null
    }

    getGPHarmonyTypes() {
        return GP_HARMONY_STRATEGY.map((type: GPHarmonyStrategy) => {
            return {
                name: type,
                value: type,
            }
        })
    }

    async selectHarmonicStrategy(event) {
        const harmonyPacks = await this.sourcePacks.getHarmonyPacks()

        this.getHarmony().setStrategy(harmonyPacks, event.new.value)

        this.getKeyModeOptions()
        this.getPitchClassOptions()

        await this.gpService.setAsUpdated("harmonyStrategy", {
            layer: "Harmony",
        })
    }

    dragOverHandler(tile, event) {
        if (event != null) {
            event.preventDefault()
        }

        this.removeDragEventListeners()

        this.dragEndListener = this.handleDragEnd.bind(this)
        this.dragEnterListener = this.preventDefaultDragBehavior.bind(this)

        window.document.addEventListener("drop", this.dragEndListener)
        window.document.addEventListener("dragover", this.dragEnterListener)
        window.document.addEventListener("dragenter", this.dragEnterListener)
        window.document.addEventListener("mousemove", this.dragEndListener)

        for (let loading of this.gpService.generationProfile.getComponents()) {
            loading.gpInfluenceLoading.isDraggedOver = loading.name == tile
        }

        this.detectChanges()

        this.ref.detach()
    }

    selectGPInfluence(gpComponent) {
        let component = this.getGenerationProfile().getComponent(gpComponent)
        this.modalService.modals.selectGPInfluence.next(component)
    }

    displayInfluenceDragover(tile) {
        let loading =
            this.gpService.generationProfile.getGPInfluenceLoading(tile)

        return (
            loading.isDraggedOver ||
            loading.hasDroppedFile ||
            (loading.error != null && loading.error != "") ||
            (loading.influenceID != "" && loading.influenceID != null)
        )
    }

    async clearDragging() {
        for (let loading of this.gpService.generationProfile.getComponents()) {
            loading.gpInfluenceLoading.isDraggedOver = false
        }

        await Misc.wait(0.5)

        this.detectChanges()
    }

    dragLeaveHandler(tile, event) {
        if (event) {
            event.preventDefault()
        }

        this.ref.reattach()

        this.gpService.generationProfile.getGPInfluenceLoading(
            tile
        ).isDraggedOver = false

        this.detectChanges()
    }

    initDropdownOptions() {
        this.harmonyTypes = this.getGPHarmonyTypes()
        this.dynamicOptions = this.getDynamicOptions()
        this.expressivePerformanceOptions = this.getEPOptions()
        this.phraseLengthOptions = this.getPhraseLengths()
        this.timeSignatureOptions = GPSettings.TIME_SIGNATURE
        this.getKeyModeOptions()
        this.getPitchClassOptions()
    }

    openInFolder() {
        let folderID = ""

        if (this.getGenerationProfile().folderID != null) {
            folderID = this.getGenerationProfile().folderID
        }

        this.folderService.setContentType(
            "openInFolder",
            "Styles",
            folderID,
            true
        )

        this.unselectMenu()
    }

    deleteLayer(layer) {
        this.unselectMenu()
        this.modalService.modals.deleteGenerationProfileLayer.next(layer)
    }

    hoveredTile(value, tileType, event) {
        if (value) {
            event.stopPropagation()
            this.tileHovered = tileType
        } else {
            this.tileHovered = ""
        }

        this.detectChanges()
    }

    isHarmonyPreviewLoading() {
        for (let preview of this.gpService.currentPreviews) {
            if (preview.layer == "Harmony" && preview.status == "loading") {
                return true
            }
        }

        return false
    }

    async addToGPLibrary() {
        const gpID = this.route.snapshot.paramMap.get("generationProfileID")

        try {
            const res = await this.apiService.authRequest(
                "/generationprofile/addToMyLibrary",
                { gpID },
                "primary",
                false
            )

            this.gpService.openGenerationProfile(res.gpID)
        } catch (err) {
            return this.apiService.handleError(err)
        }
    }

    gpBelongsToUser() {
        return this.tokenService.userID == this.getGenerationProfile().userID
    }

    getTutorialMode() {
        return this.gpService.tutorialMode
    }

    getInfoImage() {
        return (
            "assets/img/" +
            (this.gpService.tutorialMode ? "info-yellow.svg" : "info.svg")
        )
    }

    toggleTutorialMode() {
        this.gpService.tutorialMode = !this.gpService.tutorialMode

        this.unselectMenu()
    }

    onEmotionChange(event) {
        let settings = this.getSettings()

        if (settings.emotion == event.value) {
            settings.emotion = null
            this.gpService.setAsUpdated("toggleEmotion")

            return
        }

        this.modalService.modals.emotionConfirmation.next(event.value)
    }

    selectedEmotions() {
        return this.gpService.selectedEmotions()
    }

    getCompatibleMoodsForHarmonyPack() {
        return []
    }

    async openLayerOptions(event, layer) {
        this.selectedLayer = layer

        event.stopPropagation()

        this.unselectMenu()

        let options = []

        if (layer.name !== "Melody") {
            options.push({
                icon: "assets/img/cursor.svg",
                text: "Select pack",
                loading: false,
                data: layer,
                onClick: this.addPack.bind(this),
            })

            if (
                layer.name !== "Percussion" &&
                !layer.name.includes("Ornament")
            ) {
                options.push({
                    icon: "assets/img/add.svg",
                    text: "Create new pack",
                    loading: false,
                    data: layer,
                    onClick: this.createNewPack.bind(this),
                })
            }
        }

        options.push({
            icon: "assets/img/pianoroll/effects_settings.svg",
            text: "Mixing & orchestration",
            loading: false,
            data: layer,
            onClick: this.mixingAndEffects.bind(this),
        })

        options.push({
            icon: "assets/img/menu/delete.svg",
            text: "Delete",
            loading: false,
            data: layer,
            buttonClass: "delete",
            onClick: this.deleteLayer.bind(this),
        })

        this.menuOptions = {
            type: "layer",
            options: {
                coordinates: {
                    x: event.x,
                    y: event.y,
                },

                options,
            },
        }

        this.detectChanges()
    }

    selectDevelopment() {
        this.modalService.modals.gpDevelopment.next(this.getSettings())
    }

    getExpressivePerformance() {
        let options = this.getEPOptions()

        for (let option of options) {
            if (option.value == this.getSettings().expressivePerformance) {
                return option
            }
        }

        return options[0]
    }

    selectExpressivePerformance(event) {
        this.gpService.generationProfile.settings.expressivePerformance =
            event.new.value

        this.gpService.setAsUpdated("expressivePerformance")
    }

    getEPOptions() {
        return GPSettings.EXPRESSIVE_PERFORMANCE_OPTIONS
    }

    getNbOfCards() {
        let nbOfCards = this.getAccompanimentLayers().length

        if (this.getMelody() != null) {
            nbOfCards += 1
        }

        return nbOfCards + 1 // +1 is for the harmony card
    }

    canAddLayers() {
        let nbOfChords = 0
        let nbOfBass = 0
        let nbOfExtra = 0
        let nbOfOrnaments = 0
        let nbOfPercussion = 0
        let nbOfMelody = 0

        for (let layer of this.gpService.generationProfile
            .accompanimentLayers) {
            if (layer.name.includes("Chords")) {
                nbOfChords += 1
            }

            if (layer.name.includes("Bass")) {
                nbOfBass += 1
            }

            if (layer.name.includes("Extra")) {
                nbOfExtra += 1
            }

            if (layer.name.includes("Ornaments")) {
                nbOfOrnaments += 1
            }

            if (layer.name.includes("Melody")) {
                nbOfOrnaments += 1
            }

            if (layer.name.includes("Percussion")) {
                nbOfPercussion += 1
            }
        }

        return (
            nbOfMelody < 1 ||
            nbOfChords < 1 ||
            nbOfBass < 1 ||
            nbOfExtra < 1 ||
            nbOfOrnaments < 3 ||
            nbOfPercussion < 1
        )
    }

    async openGeneratedCompositions() {
        this.unselectMenu()

        this.gpIsLoading = true
        this.gpService.refreshGenerationProfileUI.next(true)

        await this.folderService.setContentType(
            "openGeneratedCompositions",
            "Compositions",
            "",
            false,
            this.gpService.generationProfile._id
        )

        this.gpIsLoading = false
        this.gpView = "compositions"

        this.gpService.refreshGenerationProfileUI.next(true)
        this.gpService.justCreatedComposition.next(true)
    }

    addLayer() {
        this.unselectMenu()
        this.modalService.modals.addLayerToGP.next(
            this.gpService.generationProfile
        )
    }

    deleteGenerationProfile() {
        this.unselectMenu()
        this.modalService.modals.deleteGenerationProfile.next(
            this.gpService.generationProfile
        )
    }

    backToEditMode() {
        this.gpView = "edit"
        this.unselectMenu()
    }

    renameGenerationProfile() {
        this.unselectMenu()
        this.modalService.modals.renameGenerationProfile.next(
            this.gpService.generationProfile
        )
    }

    openDevelopmentDesigner() {
        this.router.navigate([
            "/development-designer",
            this.gpService.generationProfile._id,
        ])
    }

    getCaptchaKey() {
        return environment.recaptchaSiteKey
    }

    async selectMoreOptions(event) {
        event.stopPropagation()

        this.unselectMenu()

        const options: MenuOption<any>[] = [
            {
                icon: this.getInfoImage(),
                text: this.getTutorialMode()
                    ? "Disable tutorial mode"
                    : "Enable tutorial mode",
                loading: false,
                data: undefined,
                onClick: this.toggleTutorialMode.bind(this),
            },

            {
                icon: "assets/img/publish.svg",
                text: "Publish to the Library",
                loading: false,
                data: undefined,
                onClick: this.publishGP.bind(this),
            },

            {
                icon: "assets/img/add.svg",
                text: "Add Layer",
                loading: false,
                data: undefined,
                onClick: this.addLayer.bind(this),
            },

            {
                icon: "assets/img/menu/create.svg",
                text: "New Composition Workflow",
                loading: false,
                data: undefined,
                onClick: this.initiateCompositionWorkflow.bind(this),
            },
        ]

        if (this.showShareButton()) {
            options.push({
                icon: "assets/img/share.svg",
                text: "Share",
                loading: false,
                data: undefined,
                onClick: this.share.bind(this),
            })
        }

        options.push({
            icon: "assets/img/menu/rename.svg",
            text: "Rename",
            loading: false,
            data: undefined,
            onClick: this.renameGenerationProfile.bind(this),
        })

        options.push({
            icon: "assets/img/menu/delete.svg",
            text: "Delete",
            loading: false,
            data: undefined,
            buttonClass: "delete",
            onClick: this.deleteGenerationProfile.bind(this),
        })

        this.menuOptions = {
            type: "gp",
            options: {
                coordinates: { x: event.x, y: event.y },
                options,
            },
        }

        this.detectChanges()
    }

    unselectMenu() {
        if (this.menuOptions !== undefined) {
            this.menuOptions = undefined
        }
    }

    showShareButton() {
        return this.tokenService.userID == this.getGenerationProfile().userID
    }

    share() {
        this.modalService.modals.shareGP.next(this.gpService.generationProfile)

        this.unselectMenu()
    }

    async ngAfterViewInit() {
        this.keySignatureOptions = this.getKeySignatureOptions()

        this.gpService.refreshGenerationProfileUI.subscribe(async value => {
            if (value) {
                this.setKeySignatureOptions()
                this.gpValidation = this.gpService.updateUIValidation()

                this.detectChanges()
            }
        })

        this.gpService.redirectToView.next("edit")
        this.gpIsLoading = true

        let gpID = this.route.snapshot.paramMap.get("generationProfileID")

        if (gpID == "") {
            this.folderService.setContentType(
                "generation-profile.component.ts, ngAfterViewInit",
                "Styles",
                "",
                true
            )
        } else {
            await Misc.wait(0.001)

            await this.loadGP(gpID, false)
        }
    }

    private async loadGP(gpID: string, force: boolean) {
        this.gpIsLoading = true
        this.error = undefined
        this.ref.detectChanges()

        try {
            await this.gpService.init(gpID, force)

            this.initDropdownOptions()
        } catch (e) {
            this.error = e
        }

        this.gpIsLoading = false
        this.gpService.refreshGenerationProfileUI.next(true)
    }

    addPack(layer) {
        this.modalService.modals.selectPack.next({
            type: "add",
            layer: layer,
        })

        this.unselectMenu()
    }

    async createNewPack(layer) {
        await this.gpService.createAccompanimentPack(layer)
    }

    async selectPhraseLength(value) {
        let melody = this.getMelody()

        melody.pack.phraseLength = value.new.value

        const melodyPacks = await this.sourcePacks.getMelodyPacks()

        melody.encodePackID(melodyPacks)

        let preview: any = this.gpService.getPlayingPreviewForLayer("Melody")

        await this.gpService.setAsUpdated("melodyPhraseLength", preview)

        if (preview == null) {
            this.gpService.getMelodyVisualPreview()
        }
    }

    trackByAccompanimentLayer(index) {
        return index
    }

    openCompositionCreation() {
        let validation = this.validateGenerationProfile(true)

        if (validation == null || validation.ui.valid == false) {
            this.gpService.openGenerationProfile(
                this.getGenerationProfile()._id
            )

            return
        }

        this.gpService.openCompositionCreationModal({
            gp: this.gpService.generationProfile,
            redirectTo: "gpView",
            sourceGPFolder: null,
        })
    }

    getMinTempo() {
        return MIN_GP_TEMPO
    }

    getMaxTempo() {
        const settings = this.getSettings()

        return GenerationProfile.getMaxTempo(settings)
    }

    changeChordDataset() {
        this.modalService.modals.chordDatasetSelectionModal.next(
            this.getGenerationProfile()
        )
    }

    getHarmonicRepetitionOptions() {
        let options = Harmony.HARMONIC_REPETITION_OPTIONS
        options["animate"] = false
        options["disabled"] =
            this.gpValidation != null && this.gpValidation["harmony"] != null

        options["translate"] = (value: number, label: LabelType): string => {
            if (label == LabelType.Floor) {
                return Harmony.HARMONIC_REPETITION_DISPLAY_VALUES[options.floor]
            } else if (label == LabelType.Ceil) {
                return Harmony.HARMONIC_REPETITION_DISPLAY_VALUES[options.ceil]
            }

            return ""
        }

        options = Object.assign({}, options) // needed for change detecting the new options

        return options
    }

    getHarmonicRhythmOptions() {
        let options = Harmony.HARMONIC_RHYTHM_OPTIONS
        options["animate"] = false
        options["disabled"] = this.shouldDisableHarmonicRhythmOptions()

        options["translate"] = (value: number, label: LabelType): string => {
            if (label == LabelType.Floor) {
                return Harmony.HARMONIC_RHYTHM_DISPLAY_VALUES[options.floor]
            } else if (label == LabelType.Ceil) {
                return Harmony.HARMONIC_RHYTHM_DISPLAY_VALUES[options.ceil]
            }

            return ""
        }

        options = Object.assign({}, options) // needed for change detecting the new options

        return options
    }

    shouldDisableHarmonicRhythmOptions() {
        const invalidHarmony =
            this.gpValidation != null && this.gpValidation["harmony"] != null
        const shouldRestrict7thChord =
            this.getGenerationProfile().harmony.packs.length == 1 &&
            this.getGenerationProfile().harmony.packs[0].packID.includes(
                "7th_chords_1"
            )

        if (invalidHarmony || shouldRestrict7thChord) {
            return true
        }

        return false
    }

    getGenerationProfile() {
        return this.gpService.generationProfile
    }

    getMelody(): MelodyLayer {
        return this.gpService.generationProfile.melodyLayer
    }

    getAccompanimentLayers() {
        return this.gpService.generationProfile.accompanimentLayers
    }

    getSettings() {
        return this.gpService.generationProfile.settings
    }

    getHarmony() {
        if (this.gpService.generationProfile == null) {
            return null
        }

        return this.gpService.generationProfile?.harmony
    }

    async addSwing(event) {
        let preview: any = this.gpService.getPlayingPreviewForLayer("Melody")

        await this.gpService.setAsUpdated("melodySwing", preview)

        if (preview == null) {
            this.gpService.getMelodyVisualPreview()
        }
    }

    async changeMelodyComplexity(event) {
        const melodyPacks = await this.sourcePacks.getMelodyPacks()

        this.getMelody().encodePackID(melodyPacks)

        let preview: any = this.gpService.getPlayingPreviewForLayer("Melody")

        await this.gpService.setAsUpdated("melodyComplexity", preview)

        if (preview == null) {
            this.gpService.getMelodyVisualPreview()
        }
    }

    getDownloadProgress() {
        return Math.round(this.gpService.downloadProgress)
    }

    preview(type) {
        this.gpService.preview(type, null, null)
    }

    getMelodicComplexityOptions() {
        let options =
            MelodyLayer.MELODIC_COMPLEXITY_OPTIONS[
                this.getMelody().packs[0]["phraseLength"]
            ]
        options["animate"] = false

        return options
    }

    async selectTimeSignature(event) {
        this.timeSignatureLoading = true

        let settings = this.getSettings()

        settings.timeSignature = event.new.value

        await this.checkExceptionTempoRange()

        await this.gpService.setAsUpdated("timeSignature", {
            layer: "Settings",
        })

        const changedSync = await this.gpService.computeGPSyncs()

        if (changedSync) {
            await this.loadGP(this.gpService.generationProfile._id, true)
        }

        this.timeSignatureLoading = false
        this.ref.detectChanges()
    }

    getSelectedTimeSignature() {
        let settings = this.getSettings()

        return {
            value: settings.timeSignature,
            name: settings.timeSignature[0] + "/" + settings.timeSignature[1],
        }
    }

    getSelectedPhraseLength() {
        let melodyLayer = this.getMelody()

        if (melodyLayer == null) {
            return
        }

        if (melodyLayer.pack != null && melodyLayer.pack.phraseLength != null) {
            return {
                value: melodyLayer.pack.phraseLength,
                name: Misc.capitalizeFirstLetter(melodyLayer.pack.phraseLength),
            }
        }

        return this.getPhraseLengths()[0]
    }

    async changeHarmonicRepetition(event) {
        let harmonicRepetition = { min: event.value, max: event.highValue }
        let harmonicRhythm = null

        let shouldUpdate =
            this.getHarmony()?.updatePacksHarmonicRhythmAndRepetition(
                harmonicRhythm,
                harmonicRepetition
            )

        if (shouldUpdate) {
            const harmonyPacks = await this.sourcePacks.getHarmonyPacks()

            this.getHarmony().encodePackID(harmonyPacks)

            this.gpService.setAsUpdated("harmonicComplexity", {
                layer: "Harmony",
            })
        }
    }

    async changeHarmonicRhythm(event) {
        let harmonicRhythm = { min: event.value, max: event.highValue }
        let harmonicRepetition = null

        let shouldUpdate =
            this.getHarmony()?.updatePacksHarmonicRhythmAndRepetition(
                harmonicRhythm,
                harmonicRepetition
            )

        if (shouldUpdate) {
            const harmonyPacks = await this.sourcePacks.getHarmonyPacks()

            this.getHarmony().encodePackID(harmonyPacks)

            this.gpService.setAsUpdated("harmonicRhythm", {
                layer: "Harmony",
            })
        }
    }

    getPhraseLengths() {
        return this.capitalizeArrayOfStrings(MelodyLayer.PHRASE_LENGTHS_OPTIONS)
    }

    detectChanges() {
        if (!this.ref["destroyed"]) {
            this.ref.detectChanges()
        }
    }

    async checkExceptionTempoRange() {
        await Misc.wait(0.01)

        const settings = this.getSettings()

        // 3/4 time signature. This is in place to avoid duration holes, which would lead to composition generation errors.
        if (settings.timeSignature[0] == 3 && settings.timeSignature[1] == 4) {
            settings.tempoRange.min = Math.min(135, settings.tempoRange.min)

            this.detectChanges()
        }

        // 12/8 time signature exception
        else if (
            settings.timeSignature[0] == 12 &&
            settings.timeSignature[1] == 8
        ) {
            settings.tempoRange.max = Math.max(60, settings.tempoRange.max)

            this.detectChanges()
        }

        // These are temporary fixes until support for all tempo ranges is added to the Music Engine
    }

    async changedTempoRange(event) {
        let settings = this.getSettings()
        settings.tempoRange.min = event.min
        settings.tempoRange.max = event.max

        await this.checkExceptionTempoRange()

        this.gpService.setAsUpdated("tempoRange", { layer: "Settings" })
    }

    changedDynamicRange(event) {
        let settings = this.getSettings()
        settings.dynamicRange.min = event.min
        settings.dynamicRange.max = event.max

        this.gpService.setAsUpdated("dynamicRange")
    }

    selectKeySignature(event) {
        const harmony: Harmony = this.getHarmony()
        harmony.keySignature.keyMode = event.new.value.keyMode
        harmony.keySignature.pitchClass = event.new.value.pitchClass
        this.gpService.setAsUpdated("keySignature", { layer: "Settings" })
    }

    getKeySignature() {
        let harmony = this.getHarmony()
        return {
            value: harmony.keySignature,
            name: harmony.getKeySignatureTitle(harmony.keySignature),
        }
    }

    getKeySignatureOptions() {
        let options = []

        const ksOptions = Misc.getKeySignatureOptions(
            this.gpService.generationProfile?.harmony?.strategy
        )

        for (let opt in ksOptions) {
            for (let ks of ksOptions[opt]) {
                let object = {
                    pitchClass: ks.split(" ")[0],
                    keyMode: ks.split(" ")[1],
                }

                options.push({
                    value: object,
                    name: ks,
                    optgroup: Misc.capitalizeFirstLetter(opt),
                    disabled: false,
                })
            }
        }

        return options
    }

    setKeySignatureOptions() {
        for (let ks of this.keySignatureOptions) {
            let harmony = this.getHarmony()
            let disabled = false

            if (harmony != null) {
                let modes = Harmony.getAllModes(harmony.packs, harmony.strategy)

                if (!modes.includes(ks.value.keyMode.toLowerCase())) {
                    disabled = true
                }
            }

            ks.disabled = disabled
        }
    }
    1

    openAutoMixingSettings() {
        this.modalService.modals.autoMixing.next({
            type: "generationProfile",
            layers: this.gpService.getLayers(),
            autoMix: this.gpService.generationProfile.settings.autoMix,
            onToggleAutoMixing: (value => {
                this.gpService.generationProfile.settings.autoMix = value
                this.gpService.setAsUpdated("toggleAutoMixing")
            }).bind(this),
            onChangeGainBias: ((value, layer: GPLayer) => {
                layer.mixing.gainBias = value
                this.gpService.setAsUpdated("gainBias")
            }).bind(this),
        })
    }

    publishGP() {
        let result = this.gpService.validateGenerationProfile(
            this.gpService.generationProfile,
            true,
            "publish"
        )

        if (result.ui.valid) {
            this.gpService.publishGP(this.gpService.generationProfile)
        }

        this.unselectMenu()
    }

    getAutoMixingTitle() {
        if (!this.gpService.generationProfile.settings.autoMix) {
            return "Disabled"
        }

        let layers = this.gpService.getLayers()
        let result = ""

        let counter = 0

        for (let layer of layers) {
            if (counter == 0) {
                result = layer.getName() + ": " + layer.mixing.gainBias + " dB"
                counter += 1
            } else {
                result +=
                    ", " +
                    layer.getName() +
                    ": " +
                    layer.mixing.gainBias +
                    " dB"
            }
        }

        if (result == "") {
            result = "No layer to auto-mix"
        }

        return result
    }

    getFormTags() {
        return this.gpService.getFormTags()
    }

    mixingAndEffects(layer) {
        this.modalService.modals.gpLayerMixing.next(layer)
        this.unselectMenu()
    }

    getDevelopmentOptions() {
        return GPSettings.DEVELOPMENT_TYPE_OPTIONS
    }

    capitalizeArrayOfStrings(array) {
        let newArray = []

        for (let value of array) {
            newArray.push({
                value: value,
                name: Misc.capitalizeFirstLetter(value),
            })
        }

        return newArray
    }

    getDynamicOptions() {
        let options = []

        for (let opt in Effect.dynamicCorrespondence) {
            options.push({
                value: parseInt(opt),
                name: Effect.dynamicCorrespondence[opt],
            })
        }

        return options
    }

    validateGenerationProfile(showBanner = false) {
        this.unselectMenu()
        return this.gpService.validateGenerationProfile(
            this.getGenerationProfile(),
            showBanner
        )
    }

    closeBanner() {
        this.gpService.banner.next({ show: false })
    }

    getPlaybackIcon(layer) {
        return this.gpService.getPlaybackIcon(layer, null)
    }

    getAggregatedHarmonicRhythm() {
        if (this.getHarmony() == null) {
            return { min: 0, max: 2 }
        }
        return Harmony.getAggregatedHarmonicRhythm(this.getHarmony().packs)
    }

    getAggregatedHarmonicRepetition() {
        if (this.getHarmony() == null) {
            return { min: 0, max: 0 }
        }
        return Harmony.getAggregatedHarmonicRepetition(this.getHarmony().packs)
    }

    harmonyPacksExist() {
        const harmony = this.getHarmony()

        return (
            harmony != null && harmony.packs != null && harmony.packs.length > 0
        )
    }

    async initiateCompositionWorkflow() {
        const gp = this.getGenerationProfile()
        const generationProfileID = gp._id
        const validation = this.gpService.validateGenerationProfile(
            gp,
            true,
            "compositionWorkflow"
        )

        if (validation?.data?.valid && validation?.ui?.valid) {
            const type = "stepByStep"

            this.router.navigate([
                "composition-workflow",
                type,
                generationProfileID,
            ])
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true)
        this.destroy$.complete()

        this.gpService.pausePreview()
    }
}
