<div class="instrument-selection-modal-top-container">
    <div class="instrument-selection-search-bar">
        <img src="assets/img/search.svg" />
        <input
            (keydown)="searchForInstrument($event)"
            [(ngModel)]="searchKeyword"
            placeholder="Search through {{ getNumberOfPatches() }} patches"
            #searchBar />

        <div class="button-row">
            <div
                class="downloading-instruments-loader"
                *ngIf="isDownloadingAllInstruments()">
                <div class="loader"></div>
                <div class="downloading-instruments-loader-title">
                    Downloading ({{ getDownloadedPatches() }} /
                    {{ getTotalToDownload() }})
                </div>
            </div>
            <div
                class="recommendations-button-container button-row-item"
                [ngClass]="{
                    'recommendations-are-applied': recommendationsAreApplied()
                }">
                <a-button
                    class="recommendations-button"
                    *ngIf="parameters.allowRecommendations"
                    [label]="'Recommendations'"
                    [tag]="getNumberOfRecommendationsApplied()"
                    type="text"
                    (onClick)="showRecommendations($event)"></a-button>
            </div>
            <div
                class="download-all-instruments-button-container button-row-item">
                <a-button
                    class="download-all-instruments-button"
                    *ngIf="supportDownloadAllInstruments"
                    [label]="'Download all instruments'"
                    iconWidth="20px"
                    width="20px"
                    [icon]="'assets/img/menu/download_alt.svg'"
                    type="round"
                    (onClick)="
                        openDownloadInstrumentsModal()
                    "
                    [tooltip]="'Download all instruments'"
                    [tooltip-position]="'middle-bottom'"
                    [tooltip-marginTop]="10"></a-button>
            </div>
        </div>
    </div>

    <div class="modal-close">
        <img src="assets/img/close.svg" (click)="close.emit()" />
    </div>
</div>
