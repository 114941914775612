import { ScoreType } from "@common-lib/types/score"
import { Store, StoreConfig } from "@datorama/akita"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import { environment } from "@environments/environment"
import { cloneDeep } from "lodash"
export interface EditorState {
    scoreLoading: {
        started: boolean
        finished: boolean
        compositionID: string | undefined
        error?: string
    }

    compositionLoading: {
        finished: boolean
        failed: boolean
        progress: number
        compositionID: string | undefined
        contentType: ScoreType
    }

    engine: ScoreRenderingEngine | undefined

    isCompatibleWithRegeneration: boolean
}

export const initialState: EditorState = {
    scoreLoading: {
        started: false,
        finished: false,
        compositionID: undefined,
    },

    compositionLoading: {
        finished: false,
        failed: false,
        progress: 0,
        compositionID: undefined,
        contentType: "composition",
    },

    engine: undefined,

    isCompatibleWithRegeneration: true,
}

@StoreConfig({ name: "Editor", resettable: true})
export class EditorStore extends Store<EditorState> {
    constructor() {
        super(cloneDeep(initialState))
    }
}
