<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal(-1)" />
</div>
<div class="modal-title" *ngIf="!loading">
    Find similar instruments for {{ pack?.name }}
    <img
        src="assets/img/info.svg"
        tooltip="<b>How it works</b>
                <ul>
                    <li>We find instruments that are similar to every instrument you selected</li>
                    <li>You can either select a single instrument or all instruments of an accompaniment pack</li>
                    <li>To ensure high quality, we will only recommend instruments that are similar to each and every of the ones you selected</li>
                    <li>Also only instruments that match a certain playing style and possible pitch range are recommended by us</li>
                    <li>We won't recommend percussion or doubling instruments (for now)</li>
                </ul>
                "
        [tooltip-position]="'middle-bottom'"
        [tooltip-marginTop]="10"
        [tooltip-width]="350"
        [tooltip-timeout]="0" />
</div>

<div
    class="find-similar-instruments-container"
    [ngClass]="{
        'no-instruments-found': !similarInstruments.total?.length && !loading
    }">
    <div class="loader-container" *ngIf="loading">
        <div class="loader"></div>
    </div>

    <div
        class="no-instruments-found"
        *ngIf="!similarInstruments.total?.length && !loading">
        <img
            src="assets/img/info.svg"
            tooltip="<b>How it works</b>
                    <ul>
                        <li>We find instruments that are similar to every instrument you selected</li>
                        <li>You can either select a single instrument or all instruments of an accompaniment pack</li>
                        <li>To ensure high quality, we will only recommend instruments that are similar to each and every of the ones you selected</li>
                        <li>Also only instruments that match a certain playing style and possible pitch range are recommended by us</li>
                        <li>We won't recommend percussion or doubling instruments (for now)</li>
                    </ul>
                    "
            [tooltip-position]="'middle-bottom'"
            [tooltip-marginTop]="10"
            [tooltip-width]="350"
            [tooltip-timeout]="0" />

        <div class="title">No similar instruments found</div>
        <div class="description">
            <p>
                We could not find any instrument that is similar to every of the
                selected instruments.
            </p>
            <p *ngIf="instruments.length > 1">
                Try selecting less instruments to increase the chance of finding
                similar instruments.
            </p>
        </div>
    </div>

    <div
        class="chord-dataset-filter"
        *ngIf="similarInstruments.total?.length && !loading"
        [ngClass]="{ 'is-filtering': filterIsActive }">
        <div class="filters">
            <ng-container *ngIf="filterList.length !== 0">
                <div
                    class="filter-tag"
                    *ngFor="let filter of filterList; let f = index"
                    (click)="showFilters(null)">
                    {{ filter.value }}
                    <img
                        src="assets/img/delete.svg"
                        (click)="deleteFilter(filter, f, $event)" />
                </div>
            </ng-container>
        </div>
        <div class="button">
            <a-button
                icon="assets/img/filters.svg"
                label="Filters"
                (click)="showFilters($event)"
                padding="2px 8px"
                #filterButton></a-button>
        </div>
    </div>

    <div
        class="settings-container"
        *ngIf="similarInstruments.toDisplay?.length > 1 && !loading">
        <div class="settings-row">
            <div class="settings-row-title">Number of instruments</div>
            <div class="settings-row-value">
                <ngx-slider
                    (userChangeEnd)="changeNumberOfInstruments($event)"
                    class="light-ngx-slider"
                    [(value)]="numberOfInstruments"
                    [options]="getNumberOfInstrumentsOptions()"></ngx-slider>
            </div>
        </div>
    </div>

    <div
        class="instrument-results-container"
        [perfectScrollbar]="perfectScrollbarOptions"
        *ngIf="similarInstruments.total?.length && !loading">
        <div
            class="gp-similar-instrument"
            *ngFor="
                let instrument of similarInstruments.toDisplay;
                let i = index
            "
            (mouseenter)="gpInstrumentHoveredIndex = i"
            (mouseleave)="gpInstrumentHoveredIndex = -1">
            <ng-container
                *ngIf="
                    i + 1 <= numberOfInstruments &&
                    !removedPatches.includes(instrument?.item?.patchID)
                ">
                <a-button
                    class="gp-instrument-edition-button"
                    type="round-icon-and-text"
                    width="100%"
                    label="{{ instrument.item.name }}"
                    [icon]="getPlaybackIcon(instrument.item)"
                    [loading]="isPreviewLoading(instrument.item)"
                    (onClick)="preview(instrument.item, $event)"></a-button>
                <!-- <div class="for-testing-only">score {{ instrument?.similarityScore }}, range {{ instrument?.recommendations.range }}, patch {{ instrument?.recommendations.patch }}</div> -->
                <!--<div class="for-testing-only">score {{ instrument?.similarityScore }}</div>-->
                <img
                    *ngIf="gpInstrumentHoveredIndex === i"
                    class="delete-pack-instrument"
                    src="assets/img/delete.svg"
                    (click)="removeInstrumentPatch(instrument.item)" />
            </ng-container>
        </div>
    </div>

    <div class="button-row" *ngIf="similarInstruments.toDisplay?.length">
        <a-button
            class="small-margin-right"
            label="Cancel"
            width="49%"
            type="text"
            (onClick)="closeModal()"></a-button>
        <a-button
            width="49%"
            icon="assets/img/add.svg"
            [label]="'Add instruments'"
            (onClick)="addInstrumentsToPack()"
            [class]="'secondary'"></a-button>
    </div>

    <div
        class="button-row"
        *ngIf="
            (!parameters.instruments?.length && !loading) ||
            (!similarInstruments.total?.length && !loading)
        ">
        <a-button
            class=""
            label="Close"
            width="100%"
            type="text"
            (onClick)="closeModal()"></a-button>
    </div>
</div>
