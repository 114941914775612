import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { ApiService } from "./api.service"
import { GeneralService } from "./general/general.service"
import { Router } from "@angular/router"
import Folder from "../models/folder"
import { Misc } from "@common-lib/modules/misc"
import { UIContentType } from "@common-lib/types/general"
import { Composition } from "@common-lib/classes/general/composition"

interface LastContentQuery {
    folderID?: string
    contentType: UIContentType
    gpID?: string
}

@Injectable()
export class FolderService {
    isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    selectedRadioID: BehaviorSubject<string> = new BehaviorSubject<string>(null)
    selectedPlaylistID: BehaviorSubject<string> = new BehaviorSubject<string>(
        null
    )
    selectedFolderID: BehaviorSubject<string> = new BehaviorSubject<string>(
        null
    )
    subFolders: BehaviorSubject<Array<Folder>> = new BehaviorSubject<
        Array<Folder>
    >([])
    path: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>(
        []
    )
    contentType: BehaviorSubject<UIContentType> =
        new BehaviorSubject<UIContentType>("Compositions")
    justCreatedFolder: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )

    content: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])

    fetchRequestsQueue: Promise<any> = Promise.resolve()

    sortValue = {
        category: "creationDate",
        type: -1,
    }

    lastContentQuery: LastContentQuery

    constructor(
        private apiService: ApiService,
        private router: Router,
        private generalService: GeneralService
    ) { }

    removeContentFromMemory(toRemove): void {
        var content = this.content.getValue()

        for (var c = 0; c < toRemove.length; c++) {
            for (var i = 0; i < content.length; i++) {
                if (content[i]._id == toRemove[c]) {
                    content.splice(i, 1)

                    break
                }
            }
        }

        this.content.next(content)
    }

    setContent(args: {
        content
        subFolders?
        selectedFolderID?
        contentType?
    }) {
        if (args.subFolders) {
            this.subFolders.next(args.subFolders)
        }

        if (args.selectedFolderID) {
            this.selectedFolderID.next(args.selectedFolderID)
        }

        if (args.contentType) {
            this.contentType.next(args.contentType)
        }

        this.content.next(args.content)
    }

    selectFolder(folderID) {
        this.selectedFolderID.next(folderID)
    }

    sortContent(category, type) {
        var compositions = this.content.getValue()
        var folders = this.subFolders.getValue()

        compositions.sort((a, b) => {
            var aValue = a[category]
            var bValue = b[category]

            if (a.preset == null && category == "preset") {
                aValue = "Influence: " + a.influenceName
            }

            if (b.preset == null && category == "preset") {
                bValue = "Influence: " + b.influenceName
            }

            if (this.contentType.getValue() == "Influences") {
                if (category == "keySignature") {
                    aValue =
                        a["parameters"]["pitchClass"] +
                        " " +
                        a["parameters"]["keyMode"]
                    bValue =
                        b["parameters"]["pitchClass"] +
                        " " +
                        b["parameters"]["keyMode"]
                } else if (category == "timeSignature") {
                    aValue = a["parameters"]["timeSignature"]
                    bValue = b["parameters"]["timeSignature"]
                } else if (category == "tempo") {
                    aValue = a["parameters"]["tempo"]
                    bValue = b["parameters"]["tempo"]
                } else if (category == "ensemble") {
                    aValue = a["parameters"]["ensemble"]
                    bValue = b["parameters"]["ensemble"]
                }
            }

            var value = 0

            if (typeof aValue == "string") {
                aValue = aValue.toLowerCase()

                if (bValue == null) {
                    bValue = ""
                }
            }

            if (typeof bValue == "string") {
                bValue = bValue.toLowerCase()

                if (aValue == null) {
                    aValue = ""
                }
            }

            if (typeof aValue == "number" && bValue == null) {
                bValue = 0
            }

            if (typeof bValue == "number" && aValue == null) {
                aValue = 0
            }

            if (aValue > bValue) {
                value = -1

                if (type == -1) {
                    value = 1
                }
            } else if (aValue < bValue) {
                value = 1

                if (type == -1) {
                    value = -1
                }
            } else {
                value = 0
            }

            return value
        })

        if (category == "creationDate" || category == "name") {
            folders.sort((a, b) => {
                var aValue = a[category]
                var bValue = b[category]

                var value = 0

                aValue = aValue.toLowerCase()
                bValue = bValue.toLowerCase()

                if (aValue > bValue) {
                    value = -1

                    if (type == -1) {
                        value = 1
                    }
                } else if (aValue < bValue) {
                    value = 1

                    if (type == -1) {
                        value = -1
                    }
                } else {
                    value = 0
                }

                return value
            })
        }

        this.content.next(compositions)
    }

    fetchCompositionsForGP(gpID) {
        const contentType = "Compositions"

        this.isLoading.next(true)
        this.selectedRadioID.next("")
        this.path.next([])

        return this.apiService
            .authRequest(
                "/folder/getCompositionsForGP",
                { gpID: gpID },
                "primary",
                true
            )

            .then(res => {
                for (var c = 0; c < res["content"].length; c++) {
                    res["content"][c].contentType = "composition"
                }

                this.setContent({
                    content: res["content"],
                    subFolders: [],
                    selectedFolderID: "",
                    contentType: contentType,
                })

                this.sortContent(this.sortValue.category, this.sortValue.type)

                this.isLoading.next(false)

                return Promise.resolve()
            })

            .catch(err => {
                this.apiService.handleError(err)
            })
    }

    async fetchFolderContent(
        contentType: UIContentType,
        selectedFolderID,
        force = false
    ) {
        const useCachedValues =
            !force &&
            this.content.getValue().length > 0 &&
            this.lastContentQuery?.folderID === selectedFolderID &&
            this.lastContentQuery?.contentType === contentType

        if (useCachedValues) {
            return
        }

        this.isLoading.next(true)
        this.selectedRadioID.next("")

        try {
            const res = await this.apiService.authRequest(
                "/folder/getContent",
                {
                    folderID: selectedFolderID,
                    contentType: contentType,
                    gpID: null,
                },
                "primary",
                true
            )

            this.setFolderContent({
                path: res["path"],
                selectedFolderID: selectedFolderID,
                contentType: contentType,
                content: res["content"],
                subFolders: res["subFolders"],
            })

            this.isLoading.next(false)
        } catch (e) {
            console.error(e)
            this.apiService.handleError(e)
        }
    }

    setFolderContent(args: {
        path: string[]
        selectedFolderID: string
        contentType: UIContentType
        content: any[]
        subFolders: any[]
    }) {
        this.path.next(args.path)

        // this is important in order in order to prevent duplicate requests being sent to the server
        // Potential improvement would be to make sure that this function only ever gets called once,
        // but the logic flow gets complicated...
        this.lastContentQuery = {
            folderID: args.selectedFolderID,
            contentType: args.contentType,
        }

        for (let content of args.content) {
            if (args.contentType === "Influences") {
                content.contentType = "influence"
            } else if (args.contentType === "Styles") {
                content.contentType = "generationProfiles"
            } else {
                content.contentType = "composition"
            }
        }

        this.setContent({
            content: args.content,
            subFolders: args.subFolders,
            selectedFolderID: args.selectedFolderID,
            contentType: args.contentType,
        })

        this.sortContent(this.sortValue.category, this.sortValue.type)
    }

    fetchRadioContent(radio) {
        this.isLoading.next(true)

        this.lastContentQuery = {
            folderID: "",
            contentType: "Radio",
        }

        return this.apiService
            .authRequest(
                "/streaming/radio/getContent",
                { radioID: radio._id },
                "primary",
                true
            )

            .then(res => {
                for (var c = 0; c < res["compositions"].length; c++) {
                    res["compositions"][c].contentType = "radio"
                    res["compositions"][c].name = "Radio: " + radio.name
                }

                this.setContent({
                    content: res["compositions"],
                    contentType: "Radio",
                    selectedFolderID: "",
                    subFolders: [],
                })

                this.isLoading.next(false)

                return Promise.resolve()
            })
    }

    fetchPlaylistContent(playlist) {
        this.isLoading.next(true)

        this.path.next([])

        this.setContent({
            content: playlist["compositions"],
            subFolders: [],
            selectedFolderID: "",
        })

        this.isLoading.next(false)

        return Promise.resolve()
    }

    fetchPublicFolderContent(folderID) {
        return this.apiService
            .authRequest(
                "/folder/getContent/public",
                { folderID: folderID },
                "primary",
                true
            )

            .then(res => {
                return Promise.resolve(res)
            })

            .catch(err => {
                return Promise.reject(err)
            })
    }

    getSelectedFolderID() {
        if (this.selectedFolderID.getValue() == null) {
            return ""
        }

        return this.selectedFolderID.getValue()
    }

    openFolder(folderID) {
        var previousSelectedFolder = this.getSelectedFolderID()

        this.changeSelectedFolder(folderID)
            .then(() => { })

            .catch(err => {
                this.changeSelectedFolder(previousSelectedFolder)
                this.apiService.handleError(err)
            })
    }

    getFolderByID(folderID): Folder {
        let folder: Folder

        for (var i = 0; i < this.subFolders.getValue().length; i++) {
            let subFolder = this.subFolders.getValue()[i]

            if (subFolder._id == folderID) {
                folder = subFolder

                break
            }
        }

        return folder
    }

    updateFolderName(newName, folderID): void {
        var subFolders = this.subFolders.getValue()

        for (var i = 0; i < subFolders.length; i++) {
            if (subFolders[i]._id == folderID) {
                subFolders[i].name = newName

                break
            }
        }

        this.subFolders.next(subFolders)
    }

    changeSelectedFolder(selectedFolderID) {
        this.selectedFolderID.next(selectedFolderID)
        return this.fetchFolderContent(
            this.contentType.getValue(),
            selectedFolderID
        )
    }

    removeFoldersFromMemory(folders) {
        var subFolders: Folder[] = this.subFolders.getValue()

        for (var f = 0; f < folders.length; f++) {
            for (var i = 0; i < subFolders.length; i++) {
                if (subFolders[i]._id == folders[f]) {
                    subFolders.splice(i, 1)

                    break
                }
            }
        }

        this.subFolders.next(subFolders)
    }

    createFolder() {
        const parentID = this.getSelectedFolderID()

        let type = this.getType()

        return this.apiService
            .authRequest(
                "/folder/create",
                { parentID: parentID, type: type },
                "primary",
                true
            )

            .then(res => {
                var subFolders = this.subFolders.getValue()

                subFolders.push(res["folder"])

                this.subFolders.next(subFolders)

                this.justCreatedFolder.next(true)

                return Promise.resolve(res["folder"])
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve()
            })
    }

    moveManyToFolder(elementsToMove, destinationFolderID): Promise<any> {
        let type = this.getType()

        return this.apiService
            .authRequest(
                "/folder/moveManyToFolder",
                {
                    compositions: elementsToMove.compositions,
                    folders: elementsToMove.folders,
                    destinationFolderID: destinationFolderID,
                    type: type,
                },
                "primary",
                true
            )
            .then(res => {
                this.removeFoldersFromMemory(res.folders)
                this.removeContentFromMemory(res.compositions)

                return Promise.resolve(true)
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve(false)
            })
    }

    moveFolderToFolder(folderID, destinationFolderID): Promise<any> {
        return this.apiService
            .authRequest(
                "/folder/moveToFolder",
                {
                    folderID: folderID,
                    destinationFolderID: destinationFolderID,
                },
                "primary",
                true
            )

            .then(res => {
                this.removeFoldersFromMemory([folderID])

                return Promise.resolve(true)
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve(false)
            })
    }

    getType() {
        let type = "compositions"

        if (this.contentType.getValue() === "Influences") {
            type = "influences"
        } else if (this.contentType.getValue() === "Styles") {
            type = "generationProfiles"
        }

        return type
    }

    getContentIDFromIndex(index) {
        return this.content.getValue()[index]._id
    }

    moveContentToFolder(contentID, folderID): Promise<any> {
        let type = this.getType()

        return this.apiService
            .authRequest(
                "/content/moveToFolder",
                {
                    contentID: contentID,
                    folderID: folderID,
                    type: type,
                },
                "primary",
                true
            )

            .then(res => {
                this.removeContentFromMemory([contentID])

                return Promise.resolve(true)
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve(false)
            })
    }

    deleteMany(folderIDs) {
        return this.apiService
            .authRequest(
                "/folder/deleteMany",
                { folders: folderIDs },
                "primary",
                true
            )

            .then(res => {
                this.removeFoldersFromMemory(folderIDs)

                return Promise.resolve()
            })

            .catch(err => {
                this.apiService.handleError(err)
            })
    }

    deleteFolder(folderID) {
        this.apiService
            .authRequest(
                "/folder/delete",
                { folderID: folderID },
                "primary",
                true
            )
            .then(res => {
                this.removeFoldersFromMemory([folderID])
            })

            .catch(err => {
                this.apiService.handleError(err)
            })
    }

    renameFolder(newName, folderID) {
        return this.apiService
            .authRequest(
                "/folder/rename",
                {
                    folderID: folderID,
                    newName: newName,
                },
                "primary",
                true
            )

            .then(res => {
                return this.updateFolderName(newName, folderID)
            })

            .catch(err => {
                this.apiService.handleError(err)
            })
    }

    downloadFolder(quotaRequired, folderID, format, addParamsToFilename) {
        return this.apiService
            .authLargeDownload(
                "/folder/download",
                {
                    folderID: folderID,
                    format: format,
                    addParamsToFilename: addParamsToFilename,
                },
                this.getFolderByID(folderID).name + ".zip",
                "folder"
            )

            .then(couldntDownload => {
                return this.generalService.getDownloadsLeft()
            })

            .catch(err => {
                this.apiService.handleError(err)
            })
    }

    getContentType(): UIContentType {
        return this.contentType.getValue()
    }

    async setContentType(
        source,
        type: UIContentType,
        selectedFolderID,
        navigate,
        gpID = null
    ) {
        if (navigate) {
            this.router.navigate([""])
        }

        await Misc.wait(0.01)

        this.fetchRequestsQueue = this.fetchRequestsQueue.then(() => {
            if (gpID == null) {
                return this.fetchFolderContent(type, selectedFolderID, navigate)
            } else {
                return this.fetchCompositionsForGP(gpID)
            }
        })

        return await this.fetchRequestsQueue
    }

    compositionsOrInfluences() {
        return this.contentType.getValue() === "Influences"
            ? "influences"
            : "compositions"
    }

    getFolderIndex(folderID) {
        let index = -1
        let folders = this.subFolders.getValue()
        index = folders.findIndex(f => f._id == folderID)
        return index
    }

    updateFolderValue(folder) {
        const folderIndex = this.getFolderIndex(folder._id)

        if (folderIndex >= 0) {
            let folders = this.subFolders.getValue()
            folders[folderIndex] = folder

            this.subFolders.next(folders)
        }
    }

    async toggleFolderLinkSharing(shared, folder) {
        try {
            await this.apiService.authRequest(
                "/folder/share/linkSharing",
                {
                    folderID: folder._id,
                    shared: shared,
                },
                "primary",
                true
            )

            this.updateFolderValue(folder)

            return true
        } catch (err) {
            // this.apiService.handleError(err)

            return false
        }
    }

    getContentLength() {
        return this.content.getValue().length
    }

    getContentAtIndex(index) {
        return this.content.getValue()[index]
    }

    getContentIndex(compositionID): number {
        return this.content.getValue().findIndex(c => c._id === compositionID)
    }

    async getContentByID(compositionID, type) {
        var index = this.getContentIndex(compositionID)

        if (index == -1) {
            let res = await this.apiService.authRequest(
                "/composition/getContentByID",
                {
                    compositionID: compositionID,
                    type: type,
                },
                "primary",
                true
            )

            if (res.composition == null) {
                return null
            }

            res.composition["contentType"] = type

            this.setContent({
                content: [res.composition],
            })

            return res.composition
        }

        return this.content.getValue()[index]
    }

    getContentInMemoryByID(compositionID: string): Composition | undefined {
        const index = this.getContentIndex(compositionID)

        return index == -1 ? undefined : this.content.getValue()[index]
    }

    getPublicCompositionByID(compositionID) {
        var index = this.getContentIndex(compositionID)
        if (index == -1) {
            return this.apiService
                .authRequest(
                    "/composition/getPublic",
                    { compositionID: compositionID },
                    "primary",
                    true
                )
                .then(res => {
                    res.composition["contentType"] = "composition"
                    this.setContent(res.composition)

                    return Promise.resolve(res.composition)
                })
        }

        return Promise.resolve(this.content.getValue()[index])
    }
}
