<div id="player" *ngIf="showPlayer()">
    <div id="player-wrapper">
        <div id="controls">
            <div id="previous" (click)="previous()"></div>

            <div
                id="play"
                (click)="play()"
                *ngIf="!isPlaying && !isLoading"></div>
            <div
                id="pause"
                (click)="pause()"
                *ngIf="isPlaying && !isLoading"></div>
            <div class="player-loader-container" *ngIf="isLoading">
                <div class="loader"></div>
            </div>

            <div id="next" (click)="next()"></div>
        </div>

        <div id="progress">
            <div
                id="time-elapsed"
                [ngStyle]="{
                    color: progressHovered ? '#00ff81' : 'rgb(0, 196, 255)'
                }">
                {{ timeElapsedText }}
            </div>

            <div
                id="progress-bar-wrapper"
                (click)="setProgress($event)"
                (mouseover)="progressHovered = true"
                (mouseleave)="progressHovered = false">
                <div id="progress-bar">
                    <div
                        id="loading-progress-bar"
                        [style.width.%]="loadingProgress"></div>

                    <div
                        id="progress-bar-time"
                        [ngStyle]="{
                            'background-color': progressHovered
                                ? '#00ff81'
                                : 'rgb(0, 196, 255)'
                        }"
                        [style.width.%]="progress"></div>
                </div>
            </div>

            <div id="total-time">{{ durationText }}</div>
        </div>

        <div id="right-controls">
            <div id="music-title">
                {{ trackName }}
            </div>

            <div
                class="radio-player-right-control"
                *ngIf="
                    composition != null && composition.contentType == 'radio'
                ">
                <div class="player-right-control" (click)="dislike()">
                    <img
                        src="assets/img/blocked_red.svg"
                        *ngIf="composition.liked == false" />
                    <img
                        src="assets/img/blocked.svg"
                        *ngIf="composition.liked != false" />
                </div>

                <div class="player-right-control" (click)="like()">
                    <img src="assets/img/loved.svg" *ngIf="composition.liked" />
                    <img src="assets/img/love.svg" *ngIf="!composition.liked" />
                </div>
            </div>

            <div
                class="radio-player-right-control"
                *ngIf="
                    listeningTests() &&
                    composition != null &&
                    composition.contentType != 'radio'
                ">
                <div class="player-right-control" (click)="dissapprove()">
                    <img src="assets/img/close_circle.svg" />
                </div>

                <div class="player-right-control" (click)="approve()">
                    <img src="assets/img/checkmark.svg" />
                </div>
            </div>
        </div>

        <div class="support-button" (click)="openChat()">
            <img src="assets/img/support_chat.svg" />
            <div class="support-chat-title">Support Chat</div>
        </div>

        <div class="player-right-control volume-slider">
            <img
                src="assets/img/player/volume_0.svg"
                *ngIf="volumeControl == 0" />
            <img
                src="assets/img/player/volume_1.svg"
                *ngIf="volumeControl > 0 && volumeControl < 33" />
            <img
                src="assets/img/player/volume_2.svg"
                *ngIf="volumeControl >= 33 && volumeControl < 66" />
            <img
                src="assets/img/player/volume_3.svg"
                *ngIf="volumeControl >= 66" />

            <input
                type="range"
                (change)="updateVolume($event)"
                [(ngModel)]="volumeControl"
                min="0"
                max="100"
                value="100"
                class="slider"
                id="myRange" />
        </div>
    </div>
</div>
