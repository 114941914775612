export default class GPInfluence {
    _id: string
    name: string
    creationDate: number
    generationProfileID: string // the id of the gp that will be populated on the ME as a result of the classification
    userID: string
    duration: number
    isFinished: boolean = false // this means the influence's audio has correctly analysed
    version = {
        major: 0,
        minor: 0,
        patch: 0
    }
    deleted: boolean = false
    keySignature:string
    timeSignature:Array<number>
    sourceType: string
    tempo:number
    error:string
    
    constructor(_id, name, generationProfileID, userID, duration, sourceType) {
        this._id = _id
        this.name = name
        this.generationProfileID = generationProfileID
        this.userID = userID
        this.duration = duration
        this.creationDate = Date.now()
        this.sourceType = sourceType
    }

    encode() {
        let isNodeProcess = (typeof process !== 'undefined') && (process.release.name === 'node')

        let object = {}

        for (let key of Object.keys(this)) {
            // only use in NodeJS server environment
            if (isNodeProcess && (key == "userID")) {
                let ObjectId = require("bson-objectid")

                object[key] = new ObjectId(this[key])
            }

            else {
                object[key] = this[key]
            }
        }

        return object
    }

    static fromJSON(object: any): GPInfluence {
        return Object.assign(new GPInfluence("", "", "", "", 0, "audio"), object)
    }
}