export default class Tempo {
    private _fraction: string
    private _timesteps: number
    private _seconds: number
    private _bpm: number
    private _endInFractions: string

    public set endInFractions(value: string) {
        this._endInFractions = value
    }

    public get endInFractions(): string {
        return this._endInFractions
    }

    public get fraction(): string {
        return this._fraction
    }

    public get timesteps(): number {
        return this._timesteps
    }

    public get seconds(): number {
        return this._seconds
    }

    public get bpm(): number {
        return this._bpm
    }

    constructor(fraction, timesteps, seconds, bpm) {
        this._fraction = fraction
        this._timesteps = timesteps
        this._seconds = seconds
        this._bpm = bpm
    }

    copy() {
        var tempo = new Tempo(
            this._fraction,
            this._timesteps,
            this._seconds,
            this._bpm
        )

        return tempo
    }
}
