import AccompanimentPack from "../../classes/generationprofiles/accompaniment/accompanimentpack"
import HarmonyPack from "../../classes/generationprofiles/harmony/harmonypack"
import MelodyPack from "../../classes/generationprofiles/melody/melodypack"
import Pack from "../../classes/generationprofiles/pack"
import Layer from "../../classes/score/layer"
import {
    SUPPORTED_TIME_SIGNATURES,
    NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS,
    MIN_TEMPO,
    MAX_TEMPO,
    PITCHES,
    HARMONY_MODES,
} from "../../constants/constants"
import { Time } from "../time"

import {
    CWKeyMode,
    CWStateStep2,
    CWStateStep3,
    CompositionWorkflowLayer,
} from "../../interfaces/composition-workflow.interface"
import { cloneDeep } from "lodash"
import { API_ERRORS, CWErrorTypes } from "../../constants/errors"
import { DurationType } from "../../types/general"
import { TemplateChord } from "../../interfaces/score/templateScore"
import { TimeSignature } from "../../types/score"
import {
    ROMAN_SCALE,
    isValidChordNumeral,
} from "../../utils/composition-workflow.util"
export interface CWErrors {
    [key: string]: boolean
}
export module Validators {
    export const validateAccompanimentPackWithLayerType = (
        pack,
        layer: string
    ): boolean => {
        if (pack.type === "Accompaniment") {
            return layer === "Chords" || layer.includes("Extra")
        }

        // if (pack.type === "Ornaments") {
        //     return layer === ""
        // }

        return true
    }

    export const validateHarmonyPack = (
        harmonyPacks: HarmonyPack[],
        harmonyPackId: string
    ): Pack => {
        return harmonyPacks.find(h => h.packID === harmonyPackId)
    }

    export const validateMelodyPack = (
        melodyPacks: MelodyPack[],
        packId: string
    ): Pack => {
        return melodyPacks.find(m => m.packID === packId)
    }

    export const validateInstrumentByLayer = (
        instrument: string,
        layerType: string
    ): boolean => {
        const isInstrumentPercussion = instrument.split(".")[0] === "p"
        return (
            (layerType === "Percussion" && isInstrumentPercussion) ||
            (layerType !== "Percussion" && !isInstrumentPercussion)
        )
    }

    export const validateChordTypes = (
        progression: TemplateChord[],
        timeSignature: TimeSignature
    ): ("invalidChordNumeral" | "lessThan1Beat" | "moreThan1Bar")[] => {
        let sumOfChordDurations = ""
        let errors = []

        for (const cp of progression) {
            const time = cp[0]
            let chord = cp[1]

            if (!isValidChordNumeral(chord)) {
                errors.push("invalidChordNumeral")
            }

            // check if chord progression has an item that is shorter than 1 beat
            if (
                Time.compareTwoFractions(time, `1/${timeSignature[1]}`) === "lt"
            ) {
                errors.push("lessThan1Beat")
            }

            // check if chord progression has an item that is longer than 1 measure
            if (
                Time.compareTwoFractions(
                    time,
                    timeSignature[0] + "/" + timeSignature[1]
                ) === "gt"
            ) {
                errors.push("moreThan1Bar")
            }

            sumOfChordDurations = Time.addTwoFractions(
                sumOfChordDurations || "0",
                time
            )
        }

        return [...new Set(errors)]
    }

    export const validateChordProgression = (
        chordProgression: TemplateChord[],
        timeSignature: TimeSignature
    ): CWErrors => {
        const errors: CWErrors = {}
        let sumOfChordDurations = ""

        for (const cp of chordProgression) {
            const time = cp[0]
            let chord = cp[1]

            if (!isValidChordNumeral(chord)) {
                errors[CWErrorTypes.chordProgressionIsInvalid] = true
            }

            // check if chord progression has an item that is shorter than 1 beat
            if (
                Time.compareTwoFractions(time, `1/${timeSignature[1]}`) === "lt"
            ) {
                errors[CWErrorTypes.chordIsShorterThanOneBeat] = true
            }

            // check if chord progression has an item that is longer than 1 measure
            if (
                Time.compareTwoFractions(
                    time,
                    timeSignature[0] + "/" + timeSignature[1]
                ) === "gt"
            ) {
                errors[CWErrorTypes.chordIsLongerThanOneMeasure] = true
            }

            sumOfChordDurations = Time.addTwoFractions(
                sumOfChordDurations || "0",
                time
            )
        }

        // check if chord progression has the correct total duration
        if (
            Time.compareTwoFractions(
                sumOfChordDurations,
                Time.multiplyFractionWithNumber(
                    `${timeSignature[0]}/${timeSignature[1]}`,
                    NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS
                )
            ) !== "eq"
        ) {
            errors[CWErrorTypes.chordProgressionIsLessThanEightMeasures] = true
        }

        return errors
    }

    export const validateTimeSignature = (ts: number[]): boolean => {
        if (ts?.length !== 2) return false
        let isValid = false
        SUPPORTED_TIME_SIGNATURES.forEach(sts => {
            if (sts[0] === ts[0] && sts[1] === ts[1]) {
                isValid = true
            }
        })

        return isValid
    }

    export const validateKeyMode = (keyMode: CWKeyMode): boolean => {
        return ROMAN_SCALE[keyMode] !== undefined
    }

    export const validatePitchClass = (pitchString: string): boolean => {
        let pitchStringArr = pitchString.split(" ")
        let pitch = pitchStringArr[0][0]
        let modifier = pitchStringArr[0][1]

        if (modifier && !(modifier === "#" || modifier === "b")) {
            return false
        }

        if (!PITCHES.find(p => p === pitch)) {
            return false
        }

        if (
            !HARMONY_MODES.Functional.find(
                hm =>
                    hm.slice(0, 3) === pitchStringArr[1] ||
                    HARMONY_MODES.Modal.find(
                        hm => hm.slice(0, 3) === pitchStringArr[1]
                    )
            )
        ) {
            return false
        }
        return true
    }

    export const validateTempo = (tempo: number): boolean => {
        return tempo >= MIN_TEMPO && tempo <= MAX_TEMPO
    }

    export const validateStep1Loading = ({
        finished,
        error,
    }: {
        finished: boolean
        error?: string
    }) => {
        const isLoading = !finished && !error
        const hasError = !!error
        const isFinished = !!finished && !error
        return {
            isLoading,
            isFinished,
            hasError,
        }
    }

    export const validateLayers = (layers: {
        [layerName: string]: CompositionWorkflowLayer
    }): CWErrors => {
        let errors = {}
        if (Object.keys(layers).length < 1) {
            errors[CWErrorTypes.noLayers] = true
        }

        Object.keys(layers).forEach(layerName => {
            if (layers[layerName].score.length === 0) {
                errors[CWErrorTypes.noNotes] = true
            }
        })

        return errors
    }

    export const validateStep2LayersLoading = (
        cwStep2State: CWStateStep2
    ): boolean => {
        let isValid = true
        Object.keys(cwStep2State.layerLoading).forEach(key => {
            if (
                cwStep2State.layerLoading[key]?.error ||
                !cwStep2State.layerLoading[key].finished
            ) {
                isValid = false
            }
        })

        return isValid
    }

    export const validateStep2LayerDuration = (
        layers: {
            [layerName: string]: CompositionWorkflowLayer
        },
        timeSignature: number[]
    ): boolean => {
        let isValid = true

        const durations = Object.keys(layers).map(layerName => {
            let maxDurationForLayer = "0"

            const layer = layers[layerName]
            const calculatedNoteStarts: string[] = []
            layer.score.forEach(note => {
                if (!calculatedNoteStarts.find(ns => ns === note.start)) {
                    maxDurationForLayer = Time.max(
                        Time.addTwoFractions(note.start, note.duration),
                        maxDurationForLayer
                    )
                }
            })

            return maxDurationForLayer
        })

        const maxDuration = Time.maxInArray(durations)

        const nbOfBarsAllowed = Time.multiplyFractionWithNumber(
            `${timeSignature[0]}/${timeSignature[1]}`,
            NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS
        )

        return Time.compareTwoFractions(maxDuration, nbOfBarsAllowed) === "eq"
    }

    export const validateInstrumentTypeInLayers = (
        cwLayers: {
            [layerType: string]: CompositionWorkflowLayer
        },
        layers: {
            [layerType: string]: Layer
        }
    ): boolean => {
        let isValid = true

        Object.keys(layers).forEach(layerType => {
            const isPercussionInstrument =
                cwLayers[layerType].instrument.patchID.split(".")[0] === "p"
            if (
                layers[layerType].type === "pitched" &&
                isPercussionInstrument
            ) {
                isValid = false
            }

            if (
                layers[layerType].type === "percussion" &&
                !isPercussionInstrument
            ) {
                isValid = false
            }
        })

        return isValid
    }

    export const validateCompositionWorkflowThirdStep = (
        state: CWStateStep3,
        durations: DurationType[]
    ): {
        isValid: boolean
        error?: string
    } => {
        const result: { isValid: boolean; error?: string } = {
            isValid: true,
        }

        const copyState: CWStateStep3 = cloneDeep(state)

        if (copyState.name === "" || copyState.name?.length === 0) {
            result.isValid = false
            result.error = API_ERRORS.invalidCWName.message
        } else if (!durations.includes(copyState.duration)) {
            result.isValid = false
            result.error = API_ERRORS.invalidCWDuration.message
        }

        return result
    }
}
