import {
    TemplateChord,
    TemplateKeySignature,
} from "../interfaces/score/templateScore"
import { FractionString, TimeSignature } from "../types/score"
import { Time } from "./time"
import { Time as Time2 } from "./time2"
import { cloneDeep } from "lodash"
import {
    CHORD_TYPE_MAPPING,
    ROMAN_SCALE,
    SCALES,
    convertNumeralToChord,
    getChordQuality,
    transformChordDegree,
    SHORT_SCALES_TO_SCALES_MAP,
} from "../utils/composition-workflow.util"
import { HoveringTypeEnum } from "../types/general"
import { KeySignature } from "../interfaces/score/keySignature"
import {
    IntermediateKeySignature,
    KeySignatureModule,
} from "./keysignature.module"
import {
    ME_MODES_TO_CREATORS_MODES_MAPPING,
    TIMESTEP_RES,
} from "../constants/constants"
import { CWKeyMode } from "../interfaces/composition-workflow.interface"
import { Misc } from "./misc"
import { featureFlags } from "../utils/feature-flags"
import { Fraction } from "../classes/score/fraction"
import Layer from "../classes/score/layer"

export interface ChordWithBoundaries {
    start: FractionString
    end: FractionString
    data: string
}

export class ChordValidationError extends Error {
    constructor(message: string) {
        super(message)
        this.name = "ChordValidationError"
    }
}

export module ChordManipulation {
    export function getChordsInRange(
        chords: TemplateChord[],
        range: {
            start: string
            end: string
        }
    ): TemplateChord[] {
        const temp: { start: string; end: string; chord: string }[] = []

        iterateThroughChords(
            chords,
            ({ index, boundary, start, duration, chord }) => {
                if (Time.compareTwoFractions(start, range.end) !== "lt") {
                    return false
                }

                const result = Time.rangesAreOverlapping(
                    {
                        start,
                        end: Time.addTwoFractions(start, duration),
                    },
                    range
                )

                if (result.overlap) {
                    temp.push({ ...result.overlapRange, chord })
                }

                return true
            }
        )

        const finalChords: TemplateChord[] = temp.map(t => [
            Time.addTwoFractions(t.end, t.start, true),
            t.chord,
        ])

        return finalChords
    }

    /**
     * @param progression A progression of roman numerals
     * @param mode
     *
     * @returns a percentage of diatonicity
     */
    export function computeProgressionDiatonicity(
        progression: TemplateChord[],
        mode: CWKeyMode
    ): number {
        let diatonicDuration = "0"
        let totalDuration = "0"

        for (const p of progression) {
            const isDiatonic = romanNumeralIsDiatonic(p[1], mode)

            if (isDiatonic) {
                diatonicDuration = Time.addTwoFractions(diatonicDuration, p[0])
            }

            totalDuration = Time.addTwoFractions(totalDuration, p[0])
        }

        return (
            (Time.fractionToNumber(diatonicDuration) /
                Time.fractionToNumber(totalDuration)) *
            100
        )
    }

    export function getChordAndExtensionVocabWithCounts(
        progression: TemplateChord[]
    ): {
        chords: { [key: string]: number }
        extensions: { [key: string]: number }
        nbOfChords: number
        nbOfExtensions: number
        nbOfUniqueChords: number
        nbOfUniqueExtensions: number
    } {
        const vocab = {
            chords: {},
            extensions: {},
            nbOfChords: 0,
            nbOfExtensions: 0,
            nbOfUniqueChords: 0,
            nbOfUniqueExtensions: 0,
        }

        for (const c of progression) {
            const chord = c[1].split(" ")

            if (vocab.chords[chord[0]] === undefined) {
                vocab.chords[chord[0]] = 0
            }

            vocab.chords[chord[0]]++
            vocab.nbOfChords++

            if (chord.length > 1) {
                if (vocab.extensions[chord[1]] === undefined) {
                    vocab.extensions[chord[1]] = 0
                }

                vocab.extensions[chord[1]]++
                vocab.nbOfExtensions++
            }
        }

        vocab.nbOfUniqueChords = Object.keys(vocab.chords).length
        vocab.nbOfUniqueExtensions = Object.keys(vocab.extensions).length

        return vocab
    }

    export function reduceChords(
        chords: TemplateChord[],
        layers: Layer[],
        keySignature: KeySignature
    ): TemplateChord[] {
        let result: TemplateChord[] = []
        let filteredLayers: Layer[] = layers.filter(
            l => !l.type.toLowerCase().includes("percussion")
        )
        return result
    }

    export function getExtensionVocabulary(
        progression: TemplateChord[]
    ): string[] {
        const progressionVocab = getProgressionVocabulary(progression)

        const extensions = progressionVocab
            .map(p => {
                return p.split(" ")[1]
            })
            .filter(f => f !== undefined)

        return extensions
    }

    export function getProgressionVocabulary(
        progression: TemplateChord[]
    ): string[] {
        return [...new Set(progression.map(p => p[1]))]
    }

    export function romanNumeralIsDiatonic(numeral: string, mode: CWKeyMode) {
        const symbol = convertNumeralToChord("C", mode, numeral)

        const notes = KeySignatureModule.getPitchesForNotes(
            KeySignatureModule.getNotesForChord(symbol.chord, true),
            0
        )
            .map(p => {
                return Misc.pythonMod(p, 12)
            })
            .sort()

        for (const n of notes) {
            if (
                !SCALES[
                    KeySignatureModule.convertKeyModeToMERepresentation(mode)
                ].includes(n)
            ) {
                return false
            }
        }

        return true
    }

    export function resizeChord({
        romanNumerals,
        index,
        offset,
        side,
        timeSignature,
        keySignatures,
        editorType,
    }: {
        romanNumerals: TemplateChord[]
        index: number
        offset: FractionString
        side: "left" | "right"
        timeSignature: TimeSignature
        keySignatures: TemplateKeySignature[]
        editorType: "composition-workflow" | "editor"
    }): {
        romanNumerals: TemplateChord[]
        chords: TemplateChord[]
    } {
        if (
            editorType === "composition-workflow" ||
            (editorType === "editor" &&
                featureFlags.applyMusicEngineChordRestrictions)
        ) {
            offset = Time.quantizeFractionToTSBeat(offset, timeSignature)
        }

        let newChords = cloneDeep(romanNumerals)

        newChords = applyOffsetToChords({
            romanNumerals: newChords,
            index: index,
            offset: offset,
            timeSignature: timeSignature,
            side: side,
        })

        newChords = applyMusicEngineChordRestrictions(
            newChords,
            timeSignature,
            editorType
        )

        const chords = ChordManipulation.convertRomanNumeralsToChordSymbols(
            newChords,
            keySignatures
        )

        return {
            romanNumerals: newChords,
            chords,
        }
    }

    export function insertAndReplaceChords(
        chords: TemplateChord[],
        toInsert: TemplateChord[],
        insertAt: FractionString
    ): TemplateChord[] {
        const temp = convertTemplateChordToChordArrayWithBoundaries(chords)
        const temp1 = convertTemplateChordToChordArrayWithBoundaries(
            toInsert
        ).map(c => {
            c.start = Time.addTwoFractions(c.start, insertAt)
            c.end = Time.addTwoFractions(c.end, insertAt)

            return c
        })

        const insertionEnd = Time.addTwoFractions(
            Time.addTwoFractions(
                temp1[temp1.length - 1].end,
                temp1[0].start,
                true
            ),
            insertAt
        )

        let chordProgression = []

        for (const chord of temp) {
            if (
                Time.compareTwoFractions(insertAt, chord.end) !== "lt" ||
                Time.compareTwoFractions(insertionEnd, chord.start) !== "gt"
            ) {
                chordProgression.push(chord)

                continue
            }

            const shouldTrimEnd = Time.fractionIsInBoundaries(
                {
                    start: chord.start,
                    duration: Time.addTwoFractions(
                        chord.end,
                        chord.start,
                        true
                    ),
                },
                insertAt,
                false,
                false
            )

            if (shouldTrimEnd) {
                chordProgression.push({
                    start: chord.start,
                    end: insertAt,
                    data: chord.data,
                })

                continue
            }

            const shouldTrimStart = Time.fractionIsInBoundaries(
                {
                    start: chord.start,
                    duration: Time.addTwoFractions(
                        chord.end,
                        chord.start,
                        true
                    ),
                },
                insertionEnd
            )

            if (shouldTrimStart) {
                chordProgression.push({
                    start: insertionEnd,
                    end: chord.end,
                    data: chord.data,
                })

                continue
            }
        }

        chordProgression = chordProgression.concat(temp1)

        chordProgression.sort((a, b) =>
            Time.compareTwoFractions(a.start, b.start) === "lt" ? -1 : 1
        )

        return convertChordArrayWithBoundariesToTemplateChord(chordProgression)
    }

    export function applyLeftOrRightOffsetToChord(
        chords: ChordWithBoundaries[],
        index: number,
        offset: FractionString,
        side: "left" | "right"
    ): ChordWithBoundaries[] {
        const start = "0"
        const end = chords[chords.length - 1].end

        console.assert(index < chords.length, "index out of bounds")

        const chord = chords[index]

        if (side === "left") {
            chord.start = Time.addTwoFractions(chord.start, offset)
        } else {
            chord.end = Time.addTwoFractions(chord.end, offset)
        }

        const negativeStart = Time.fractionToNumber(chord.start) < 0

        if (negativeStart) {
            chord.start = "0"
        }

        const startIsGreaterThanEnd =
            Time.compareTwoFractions(chord.start, chord.end) === "gt"

        if (startIsGreaterThanEnd) {
            const temp = chord.start
            chord.start = chord.end
            chord.end = temp
        }

        if (
            index > 0 &&
            Time.compareTwoFractions(chord.start, chords[index - 1].end) ===
                "gt"
        ) {
            chords[index - 1].end = chord.start
        }

        if (
            index < chords.length - 1 &&
            Time.compareTwoFractions(chord.end, chords[index + 1].start) ===
                "lt"
        ) {
            chords[index + 1].start = chord.end
        }

        let result = Time.mergeOverlappingIntervalsDescending(
            chords.slice(0, Math.min(chords.length, index + 1))
        )

        if (index < chords.length - 1) {
            const temp = chords.slice(index)

            temp[0] = result[result.length - 1]

            result.pop()

            result = result.concat(
                Time.mergeOverlappingIntervalsAscending(temp)
            )
        }

        result = result.filter(c => {
            return Time.compareTwoFractions(c.start, c.end) === "lt"
        })

        for (let c = 0; c < result.length - 1; c++) {
            const chord = result[c]

            chord.end = result[c + 1].start
        }

        if (Time.compareTwoFractions(start, result[0].start) === "lt") {
            result.unshift({
                start,
                end: result[0].start,
                data: chords[0].data,
            })
        }

        const endComparison = Time.compareTwoFractions(
            result[result.length - 1].end,
            end
        )

        if (endComparison === "lt") {
            result.push({
                start: result[result.length - 1].end,
                end: end,
                data: result[result.length - 1].data,
            })
        }

        while (
            Time.compareTwoFractions(result[result.length - 1].end, end) ===
            "gt"
        ) {
            result[result.length - 1].end = end

            if (
                Time.compareTwoFractions(
                    result[result.length - 1].end,
                    result[result.length - 1].start
                ) === "lt"
            ) {
                result.splice(result.length - 1, 1)
            }
        }

        return result
    }

    export function pitchIsInChord(
        pitch: number,
        start: string,
        end: string,
        chords: TemplateChord[]
    ): { chord: ChordWithBoundaries; inChord: boolean }[] {
        const temp = convertTemplateChordToChordArrayWithBoundaries(chords)
        const overlappingChords: {
            chord: ChordWithBoundaries
            inChord: boolean
        }[] = []

        for (const t of temp) {
            const result = Time.rangesAreOverlapping(t, { start, end })

            if (result.overlap) {
                const chord = t.data

                const notes = KeySignatureModule.getPitchesForNotes(
                    KeySignatureModule.getNotesForChord(chord, true),
                    0
                ).map(p => p % 12)

                overlappingChords.push({
                    chord: t,
                    inChord: notes.includes(pitch % 12),
                })
            }
        }

        return overlappingChords
    }

    export function convertTemplateChordToChordArrayWithBoundaries(
        chords: TemplateChord[]
    ): ChordWithBoundaries[] {
        let start = "0"

        let newFormat = []

        for (const num of chords) {
            newFormat.push({
                start,
                end: Time.addTwoFractions(start, num[0]),
                data: num[1],
            })

            start = Time.addTwoFractions(start, num[0])
        }

        return newFormat
    }

    function convertChordArrayWithBoundariesToTemplateChord(
        chords: ChordWithBoundaries[]
    ): TemplateChord[] {
        const result: TemplateChord[] = []

        for (let d = 0; d < chords.length; d++) {
            const data = chords[d]

            if (d < chords.length - 1) {
                const nextData = chords[d + 1]
                data.end = nextData.start
            }

            result.push([
                Time.addTwoFractions(data.end, data.start, true),
                data.data,
            ])
        }

        return result
    }

    /**
     * Changes the duration of the chord based on selectedChordIndex + offset
     *
     * neg offset and right side --> decrease duration until 1 beat
     * pos offset and right side --> increase duration
     *
     * neg offset and left side --> increase duration
     * pos offset and left side --> decrease duration until 1 beat
     * @param param0
     */
    export function applyOffsetToChords({
        romanNumerals,
        index,
        offset,
        timeSignature,
        side,
    }: {
        romanNumerals: TemplateChord[]
        index: number
        offset: FractionString
        timeSignature: TimeSignature
        side: "left" | "right"
    }): TemplateChord[] {
        let newFormat =
            convertTemplateChordToChordArrayWithBoundaries(romanNumerals)

        newFormat = applyLeftOrRightOffsetToChord(
            newFormat,
            index,
            offset,
            side
        )

        const result = convertChordArrayWithBoundariesToTemplateChord(newFormat)

        return result
    }

    export function applyMusicEngineChordRestrictions(
        romanNumerals: TemplateChord[],
        timeSignature: TimeSignature,
        editor: "composition-workflow" | "editor" = "composition-workflow"
    ): TemplateChord[] {
        if (
            !featureFlags.applyMusicEngineChordRestrictions &&
            editor === "editor"
        ) {
            return romanNumerals
        }

        const newFormat =
            convertTemplateChordToChordArrayWithBoundaries(romanNumerals)

        for (let d = 0; d < newFormat.length; d++) {
            const data = newFormat[d]
            const elements = Time.breakUpCrossingElement(
                {
                    start: data.start,
                    end: data.end,
                },
                timeSignature
            )

            if (elements.length > 1) {
                newFormat.splice(
                    d,
                    1,
                    ...elements.map(c => {
                        return {
                            start: c.start,
                            end: c.end,
                            data: data.data,
                        } as ChordWithBoundaries
                    })
                )
            }
        }

        return convertChordArrayWithBoundariesToTemplateChord(newFormat)
    }

    /**
     * This function changes the symbol of the chord at the specified onset.
     * Warning: this function mutates the chords array in place.
     */
    export function changeChordSymbol({
        onset,
        newChordSymbol,
        chords,
    }: {
        onset: FractionString
        newChordSymbol: string
        chords: TemplateChord[]
    }): TemplateChord[] {
        ChordManipulation.iterateThroughChords(
            chords,
            ({ index, boundary, start, duration, chord }) => {
                if (!Time.fractionIsInBoundaries(boundary, onset)) {
                    return true
                }

                chords[index][1] = newChordSymbol

                return false
            }
        )
        return chords
    }

    export function splitNumeralAndExtension(romanNumeral: string): {
        numeral: string
        extension: string
    } {
        return {
            numeral: romanNumeral.split(" ")[0],
            extension: romanNumeral.split(" ")[1] ?? "",
        }
    }

    export function isValidRomanNumeral(romanNumeral: string): boolean {
        const chordQuality = getChordQuality(romanNumeral)
        const splits = romanNumeral.split(" ")
        const chordDegree = splits[0]
        const chordType = splits.length === 1 ? "" : splits[1]
        const chordTypes = Object.keys(CHORD_TYPE_MAPPING[chordQuality])

        let chordDegrees = []

        for (let mode of Object.keys(ROMAN_SCALE)) {
            for (let degree of ROMAN_SCALE[mode]) {
                chordDegrees.push(degree.toLowerCase())
                chordDegrees.push(degree.toUpperCase())
            }
        }

        chordDegrees = [...new Set(chordDegrees)]

        const isValidChordType = chordTypes.includes(chordType)
        const isValidChordDegree =
            chordDegrees.includes(chordDegree.toUpperCase()) ||
            chordDegrees.includes(chordDegree.toLowerCase())

        return isValidChordType && isValidChordDegree
    }

    export function convertFlatIChord(
        progression: TemplateChord[],
        keySignature: string
    ): TemplateChord[] {
        return progression.map((c: TemplateChord) => {
            const mode =
                ME_MODES_TO_CREATORS_MODES_MAPPING[keySignature.split(" ")[1]]
            const splitChord = ChordManipulation.splitNumeralAndExtension(c[1])
            const quality = getChordQuality(c[1])

            if (splitChord.numeral.toLowerCase() === "bi") {
                const newNumeral = transformChordDegree(
                    ROMAN_SCALE[mode][ROMAN_SCALE[mode].length - 1],
                    quality
                )

                return [c[0], newNumeral]
            }

            return c
        })
    }

    /**
     * This function changes the roman numeral of the chord at the specified onset.
     * Warning: this function mutates the chords array in place.
     */
    export function changeRomanNumeral({
        onset,
        newRomanNumeral,
        chords,
    }: {
        onset: FractionString
        newRomanNumeral: string // we expect the roman numeral string + chord type here, e.g. "ii m7"
        chords: TemplateChord[] // roman numeral array
    }): TemplateChord[] {
        ChordManipulation.iterateThroughChords(
            chords,
            ({ index, boundary, start, duration, chord }) => {
                if (!Time.fractionIsInBoundaries(boundary, onset)) {
                    return true
                }

                if (!isValidRomanNumeral(newRomanNumeral)) {
                    console.warn("Invalid roman numeral: ", newRomanNumeral)
                    console.log(newRomanNumeral)
                    return true
                }

                chords[index][1] = newRomanNumeral

                return false
            }
        )
        return chords
    }

    export function convertRomanNumeralsToChordSymbols(
        romanNumerals: TemplateChord[],
        keySignatures: TemplateKeySignature[]
    ): TemplateChord[] {
        const result = []

        if (romanNumerals.length === 0) {
            return []
        }

        const scoreLength = romanNumerals
            .map(r => r[0])
            .reduce((acc, curr) => {
                return Time.addTwoFractions(acc, curr)
            })

        const intermediateKS =
            KeySignatureModule.fromKeySignaturesToIntermediateRepresentation(
                scoreLength,
                keySignatures
            ).map(ks => {
                const pitchClass = ks.ks.split(" ")[0]
                let keyMode = ks.ks.split(" ")[1]

                // this is patching up the editor, because we don't have the full scale name in the key signature
                if (keyMode.length < 4) {
                    keyMode = SHORT_SCALES_TO_SCALES_MAP[keyMode]
                }

                ks.ks = pitchClass + " " + keyMode

                return ks
            })

        const intermediateNumerals =
            convertTemplateChordToChordArrayWithBoundaries(romanNumerals)

        for (const romanNumeral of intermediateNumerals) {
            const ks = getKeySignaturesForChord(romanNumeral, intermediateKS)

            for (const k of ks) {
                let start =
                    Time.compareTwoFractions(k.start, romanNumeral.start) ===
                    "gt"
                        ? k.start
                        : romanNumeral.start

                const chord = convertNumeralToChord(
                    k.ks.split(" ")[0],
                    k.ks.split(" ")[1] as CWKeyMode,
                    romanNumeral.data
                ).chord

                result.push({
                    start,
                    end: k.end,
                    data: chord,
                })
            }
        }

        return convertChordArrayWithBoundariesToTemplateChord(result)
    }

    export function convertProgressionFromFractionToBeat(
        progression: TemplateChord[],
        ts: TimeSignature
    ): [number, string][] {
        const tsBeat = Time.fractionToNumber(Time.getTSBeat(ts))

        return progression.map(c => {
            return [Time.fractionToNumber(c[0]) / tsBeat, c[1]]
        })
    }

    export function convertProgressionFromBeatToFraction(
        progression: [number, string][],
        ts: TimeSignature
    ): TemplateChord[] {
        const tsBeat = Time.getTSBeat(ts)

        const result = progression.map((c: [number, string]) => {
            return [
                Time.multiplyFractionWithNumber(tsBeat, c[0]),
                c[1],
            ] as TemplateChord
        })

        return result
    }

    export function getKeySignaturesForChord(
        chord: ChordWithBoundaries,
        keySignatures: IntermediateKeySignature[]
    ) {
        const result: IntermediateKeySignature[] = []

        for (const ks of keySignatures) {
            const boundaries = {
                start: ks.start,
                duration: Time.addTwoFractions(ks.end, ks.start, true),
            }
            const startIsInBoundaries = Time.fractionIsInBoundaries(
                boundaries,
                chord.start
            )

            const endIsInBoundaries = Time.fractionIsInBoundaries(
                boundaries,
                chord.end,
                false
            )

            if (startIsInBoundaries || endIsInBoundaries) {
                result.push(ks)
            }
        }

        return result
    }

    export function convertChordSymbolsToRomanNumerals(
        chords: TemplateChord[],
        keySignatures: TemplateKeySignature[]
    ) {
        if (chords.length === 0) {
            return []
        }

        keySignatures = cloneDeep(keySignatures)

        const scoreLength = chords
            .map(r => r[0])
            .reduce((acc, curr) => {
                return Time.addTwoFractions(acc, curr)
            })

        // First, make sure that the mode is formatted in Music Engine format
        for (const ks of keySignatures) {
            let keyMode = ks[1].split(" ")[1]
            keyMode =
                keyMode.length < 5
                    ? (SHORT_SCALES_TO_SCALES_MAP[keyMode] as CWKeyMode)
                    : keyMode

            ks[1] = ks[1].split(" ")[0] + " " + keyMode
        }

        // Then, split chords based on key signature onsets
        const intermediateKS =
            KeySignatureModule.fromKeySignaturesToIntermediateRepresentation(
                scoreLength,
                keySignatures
            )

        const temp = convertTemplateChordToChordArrayWithBoundaries(chords)
        const temp2 = []

        for (const c of temp) {
            const ks = getKeySignaturesForChord(c, intermediateKS)

            if (ks.length === 1) {
                temp2.push(c)

                continue
            }

            for (const k of ks) {
                const boundaries = {
                    start: k.start,
                    end: k.end,
                }

                const result = Time.rangesAreOverlapping(c, boundaries)

                if (result.overlap) {
                    temp2.push({
                        start: result.overlapRange.start,
                        end: result.overlapRange.end,
                        data: c.data,
                    })
                }
            }
        }

        // Convert to roman numerals
        return temp2.map(c => {
            const result = KeySignatureModule.getChordSymbolAndSuffix(c.data)
            const ks = getKeySignaturesForChord(c, intermediateKS)

            if (ks.length !== 1) {
                throw "Unsupported number of key signatures"
            }

            const finalKeySignature: KeySignature = {
                pitchClass: ks[0].ks.split(" ")[0],
                keyMode: ks[0].ks.split(" ")[1] as CWKeyMode,
            }

            const chord: TemplateChord = [
                Time.addTwoFractions(c.end, c.start, true),
                KeySignatureModule.convertNotesToChordWithDegree(
                    result.chordName,
                    result.symbol,
                    finalKeySignature
                ),
            ]

            return chord
        })
    }

    export function limitOffset(
        o: FractionString,
        chord: TemplateChord,
        timeSignature: TimeSignature
    ) {
        const oneBar = timeSignature[0] + "/" + timeSignature[1]
        const oneBeat = Time.getTSBeat(timeSignature)

        const comparison = Time.compareTwoFractions(o, "0")

        if (comparison === "lt") {
            o = Time.max(Time.addTwoFractions(oneBeat, chord[0], true), o)
        } else if (comparison === "gt") {
            o = Time.min(Time.addTwoFractions(oneBar, chord[0], true), o)
        }

        return o
    }

    export function getChordAtTime(
        time: FractionString,
        chords: TemplateChord[]
    ): {
        start: FractionString
        duration: FractionString
        chord: string
        index: number
        draggingType?: HoveringTypeEnum
    } {
        if (chords.length === 0) {
            return {
                start: time,
                duration: "1",
                chord: "C",
                index: 0,
            }
        }

        const result = {
            start: time,
            duration: "1",
            chord: chords[chords.length - 1][1],
            index: 0,
        }

        iterateThroughChords(
            chords,
            ({ index, boundary, start, duration, chord }) => {
                if (Time.fractionIsInBoundaries(boundary, time)) {
                    result.start = start
                    result.duration = duration
                    result.chord = chord
                    result.index = index

                    return false
                }

                return true
            }
        )

        return result
    }

    export function getChordAtTime2(
        time: Fraction,
        chords: TemplateChord[]
    ): {
        start: Fraction
        duration: Fraction
        chord: string
        index: number
        draggingType?: HoveringTypeEnum
    } {
        if (chords.length === 0) {
            return {
                start: Fraction.fromFraction(time.numerator, time.denominator),
                duration: new Fraction("1"),
                chord: "C",
                index: 0,
            }
        }

        const result = {
            start: Fraction.fromFraction(time.numerator, time.denominator),
            duration: new Fraction("1"),
            chord: chords[chords.length - 1][1],
            index: 0,
        }

        iterateThroughChords2(
            chords,
            ({ index, boundary, start, duration, chord }) => {
                if (Time2.fractionIsInBoundaries(boundary, time)) {
                    result.start = start
                    result.duration = duration
                    result.chord = chord
                    result.index = index

                    return false
                }

                return true
            }
        )

        return result
    }

    export function iterateThroughChords(
        chords: TemplateChord[],
        higherOrderFunction: ({
            index,
            boundary,
            start,
            duration,
            chord,
        }: {
            index: number
            boundary: {
                start: FractionString
                duration: FractionString
            }
            start: FractionString
            duration: FractionString
            chord: string
        }) => boolean
    ) {
        let start = "0"
        let index = 0

        for (const chord of chords) {
            const boundary = {
                start,
                duration: chord[0],
            }

            const result = higherOrderFunction({
                index,
                boundary,
                start,
                duration: chord[0],
                chord: chord[1],
            })

            if (!result) {
                break
            }

            start = Time.addTwoFractions(start, chord[0])
            index++
        }
    }

    export function iterateThroughChords2(
        chords: TemplateChord[],
        higherOrderFunction: ({
            index,
            boundary,
            start,
            duration,
            chord,
        }: {
            index: number
            boundary: {
                start: Fraction
                duration: Fraction
            }
            start: Fraction
            duration: Fraction
            chord: string
        }) => boolean
    ) {
        let start = new Fraction("0")
        let index = 0

        for (const chord of chords) {
            const chordDuration = new Fraction(chord[0])
            const boundary = {
                start,
                duration: chordDuration,
            }

            const result = higherOrderFunction({
                index,
                boundary,
                start,
                duration: chordDuration,
                chord: chord[1],
            })

            if (!result) {
                break
            }

            start = Time2.addTwoFractions(start, chordDuration)
            index++
        }
    }

    export function validateChordProgression(
        progression: TemplateChord[],
        ks: TemplateKeySignature[],
        debug: boolean = false
    ) {
        const symbols = convertRomanNumeralsToChordSymbols(progression, ks)

        // If there was no error during the conversion, then we can assume that the input chord progression
        // Correctly follows our formatting standards
        return symbols.length === progression.length
    }

    export function removeNCFromChords(
        chords: TemplateChord[],
        ts: TimeSignature
    ) {
        const cWithAbsoluteTiming = getChordsWithAbsoluteTiming(chords)

        let temp = []
        const results: TemplateChord[][] = []

        for (let c = 0; c < cWithAbsoluteTiming.length; c++) {
            const chord = cWithAbsoluteTiming[c]

            if (!chord.chord.includes("NC")) {
                temp.push(cloneDeep(chord))

                continue
            }

            if (temp.length > 0) {
                // If NC chord is not crossing a bar boundary, from the previous chord,
                // we can consider its part of the previous chord

                const oneMeasure = ts[0] + "/" + ts[1]

                const startMeasure = Math.floor(
                    Time.divideTwoFractions(
                        cWithAbsoluteTiming[c - 1].start,
                        oneMeasure
                    )
                )

                const endMeasure = Math.floor(
                    Time.divideTwoFractions(
                        Time.addTwoFractions(
                            Time.addTwoFractions(chord.start, chord.duration),
                            "1/" + TIMESTEP_RES,
                            true
                        ),
                        oneMeasure
                    )
                )

                if (startMeasure === endMeasure) {
                    temp[temp.length - 1].duration = Time.addTwoFractions(
                        temp[temp.length - 1].duration,
                        chord.duration
                    )

                    continue
                }

                let offset = "0"

                for (let d = c + 1; d < cWithAbsoluteTiming.length; d++) {
                    cWithAbsoluteTiming[d].start = offset

                    offset = Time.addTwoFractions(
                        offset,
                        cWithAbsoluteTiming[d].duration
                    )
                }

                results.push(getChordsWithRelativeTiming(temp))
                temp = []
            }
        }

        if (temp.length > 0) {
            results.push(getChordsWithRelativeTiming(temp))
        }

        return results
    }

    export function getChordsWithAbsoluteTiming(chords: TemplateChord[]): {
        start: FractionString
        duration: FractionString
        chord: string
    }[] {
        let start = "0"

        let chordsWithAbsoluteTiming = chords.map(c => {
            const result = {
                start,
                duration: c[0],
                chord: c[1],
            }

            start = Time.addTwoFractions(start, c[0])

            return result
        })

        return chordsWithAbsoluteTiming
    }

    /**
     * This function inserts a new TemplateChord object at the specified position
     * Warning: this function mutates the chords array
     */
    export function insertNewChordSymbol({
        chords,
        index,
        timeSignature,
    }: {
        chords: TemplateChord[]
        index: number
        timeSignature: TimeSignature
    }): {
        success: boolean
        chords: TemplateChord[]
    } {
        const beats = Time.getTSBeat(timeSignature)
        const canCheckBefore = index - 1 >= 0
        const canCheckAfter = index + 1 < chords.length

        let hasEnoughSpaceToInsert = false

        if (canCheckBefore) {
            const before = cloneDeep(chords[index - 1])

            if (Time.isDivisibleBy(before[0], beats)) {
                const half = Time.divideFractionWithNumber(before[0], 2)

                hasEnoughSpaceToInsert = true
                chords.splice(index, 0, [half, chords[index - 1][1]])

                chords[index - 1][0] = half
            }
        }

        if (!hasEnoughSpaceToInsert && canCheckAfter) {
            const after = chords[index]

            if (Time.isDivisibleBy(after[0], beats)) {
                const half = Time.divideFractionWithNumber(after[0], 2)

                hasEnoughSpaceToInsert = true
                chords.splice(index, 0, [half, chords[index][1]])

                chords[index + 1][0] = half
            }
        }

        return {
            success: hasEnoughSpaceToInsert,
            chords,
        }
    }

    export function getChordsWithRelativeTiming(
        chords: {
            start: FractionString
            duration: FractionString
            chord: string
        }[]
    ): TemplateChord[] {
        return chords.map(c => {
            return [c.duration, c.chord]
        })
    }

    /**
     * This function mutates the chords array in place.
     * Deletion of a chord is done by removing the specifying index and adding the duration
     * of the deleted chord to the previous or next chord, depending on the index position
     * in the array.
     */
    export function deleteChordAtIndex(
        index: number,
        chords: TemplateChord[],
        timeSignature: number[],
        editorType: "composition-workflow" | "editor"
    ): TemplateChord[] {
        if (index < 0 || index >= chords.length) {
            return chords
        }

        console.assert(checkIfChordsAreContinueous(chords, timeSignature))

        if (index === chords.length - 1 && editorType === "editor") {
            chords.splice(index, 1)
            return chords
        }

        let neighbourIndex = checkIfChordHasNeighbourInBar(
            chords,
            index,
            timeSignature
        )

        if (neighbourIndex === null) {
            return chords
        }

        const newDuartion = Time.addTwoFractions(
            chords[index][0],
            chords[neighbourIndex][0]
        )

        chords[neighbourIndex][0] = newDuartion
        chords.splice(index, 1)

        return chords
    }
    /**
     * Function checks if there are neighbouring chords in the same bar
     *
     * 1. if the chord duration, matches the time signature, than it does not have any neighbours (returns null)
     *
     * 2. if the sum of chords duration, up to the index of the chords, does not match the timeSignature * (integerValue),
     * than the chord neighbour to the left (which is index - 1)
     *
     * 3. If the sum of chords duration, up to the index of chords matches the timeSignature * (integerValue),
     * than assuming that the first check passed, the chord has a neighbour to the right (index + 1)
     */
    export function checkIfChordHasNeighbourInBar(
        chords: TemplateChord[],
        index: number,
        timeSignature: number[]
    ): number | null {
        // point 1
        if (
            Time.compareTwoFractions(
                chords[index][0],
                `${timeSignature[0]}/${timeSignature[1]}`
            ) === "eq"
        ) {
            return null
        }
        // point 2
        let chordsDurationSum = "0"
        for (let i = 0; i < index; ++i) {
            chordsDurationSum = Time.addTwoFractions(
                chordsDurationSum,
                chords[i][0]
            )
        }

        let { numerator, denominator } =
            Time.fractionToDictionary(chordsDurationSum)

        if (denominator !== timeSignature[1]) {
            numerator = numerator * (timeSignature[1] / denominator)
            denominator = timeSignature[1]
        }

        const isNumeratorWhole =
            Math.floor(numerator / timeSignature[0]) ===
            numerator / timeSignature[0]

        const isDenominatorWhole =
            Math.floor(denominator / timeSignature[1]) ===
            denominator / timeSignature[1]

        if (isNumeratorWhole && isDenominatorWhole) {
            return index + 1
        } else {
            return index - 1
        }
    }

    export function checkIfChordsAreContinueous(
        chords: TemplateChord[],
        timeSignature: number[]
    ): boolean {
        let beat = 1
        let sum = "0"
        for (let i = 0; i < chords.length; ++i) {
            sum = Time.addTwoFractions(sum, chords[i][0])
            if (
                Time.compareTwoFractions(
                    sum,
                    `${timeSignature[0]}/${timeSignature[1]}`
                ) === "eq"
            ) {
                sum = "0"
                beat++
            }

            if (
                Time.compareTwoFractions(
                    sum,
                    `${timeSignature[0]}/${timeSignature[1]}`
                ) === "gt"
            ) {
                return false
            }
        }
        return true
    }

    /**
     * Returns the total duration of all given chords while respecting the
     * given start and or end indices (optional)
     * @param param0
     * @returns
     */
    export function getChordsTotalDuration({
        chords,
        startIndex,
        endIndex,
    }: {
        chords: TemplateChord[]
        startIndex?: number | undefined
        endIndex?: number | undefined
    }) {
        let sum = "0"

        ChordManipulation.iterateThroughChords(
            chords,
            ({ index, boundary, start, duration, chord }) => {
                if (startIndex !== undefined && index < startIndex) {
                    return true
                }

                if (endIndex !== undefined && index > endIndex) {
                    return true
                }

                sum = Time.addTwoFractions(sum, chords[index][0])

                return true
            }
        )

        return sum
    }

    export function getChordWithBoundaries({
        chords,
        startIndex,
        endIndex,
    }: {
        chords: TemplateChord[]
        startIndex?: number
        endIndex?: number
    }): {
        index: number
        start: FractionString | string
        duration: FractionString | string
        end: FractionString | string
        chord: string
    }[] {
        const chordWithBoundaries = []

        ChordManipulation.iterateThroughChords(
            chords,
            ({ index, boundary, start, duration, chord }) => {
                if (startIndex !== undefined && index < startIndex) {
                    return true
                }

                if (endIndex !== undefined && index > endIndex) {
                    return true
                }

                chordWithBoundaries.push({
                    index,
                    start,
                    duration,
                    end: Time.addTwoFractions(start, duration),
                    chord,
                })

                return true
            }
        )

        return chordWithBoundaries
    }

    // @todo implement this function
    export function getHarmonicRhythmFromProgression(
        romanNumerals: TemplateChord[]
    ) {
        return "medium"
    }

    export function truncateChords(chords: TemplateChord[], duration: string) {
        let currentChordProgressionDuration = "0/1"

        const convertedChordProgression = []
        let durationLeft = duration

        for (let c = 0; c < chords.length; c++) {
            const chord = chords[c]

            // Stop in case we have reached the duration of the initial progression
            // We do not want to add more than the original chord duration.
            if (
                Time.compareTwoFractions(
                    currentChordProgressionDuration,
                    duration
                ) === "eq"
            ) {
                break
            }

            // Stop in case we have reached the duration
            if (Time.compareTwoFractions(durationLeft, "0/1") === "eq") {
                break
            }

            // If the chord duration is greater than the remaining duration, we trim the chord
            if (Time.compareTwoFractions(durationLeft, chord[0]) === "lt") {
                chord[0] = durationLeft
            }

            convertedChordProgression.push(chord)
            currentChordProgressionDuration = Time.addTwoFractions(
                currentChordProgressionDuration,
                chord[0]
            )
            durationLeft = Time.addTwoFractions(
                duration,
                currentChordProgressionDuration,
                true
            )
        }

        // If we are at the end of the progression and there is still duration left, we add the last chord again with the remaining duration
        if (Time.compareTwoFractions(durationLeft, "0/1") === "gt") {
            const lastChord =
                convertedChordProgression[convertedChordProgression.length - 1]
            convertedChordProgression.push([durationLeft, lastChord[1]])
        }

        return convertedChordProgression
    }
}
