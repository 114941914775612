import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core'
import { GenerationProfileService } from '@services/generation-profile/generationprofile.service'
import { DesignService } from '@services/design.service'
import GPSettings from '@common-lib/classes/generationprofiles/gpsettings'

@Component({
 	selector: 'form-tags-selection',
  	templateUrl: 'form-tags-selection.component.html',
  	styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormTagsSelectionModalComponent implements OnInit {
    scrollConfig = { useBothWheelAxes: false }

    @Input() formTags
    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor(private designService:DesignService, private gpService:GenerationProfileService, private ref:ChangeDetectorRef) {

    }

    ngOnInit(): void {

    }

    closeModal() {
        this.close.emit()
    }

    getFormTagsDataset() {
		return GPSettings.DEVELOPMENT_TYPE_OPTIONS
	}

	changeSelectedFormTags(event) {
		let selectedFormTags = this.formTags
		
		if (event.checked) {
			for (let tag of selectedFormTags) {
				if (tag.id == event.id) {
					return
				}
			}

			selectedFormTags.push({
				id: event.id,
				name: event.label,
				description: event.description
			})
		}

		else {
			for (let t = selectedFormTags.length - 1; t >= 0; t--) {
				if (selectedFormTags[t].id == event.id) {
					selectedFormTags.splice(t, 1)
				}
			}
		}

		if (this.formTags.length == 1 && this.formTags[0].id == "chinese1") {
			let settings = this.gpService.generationProfile.settings

			settings.tempoRange.min = Math.min(settings.tempoRange.min, 130)
			settings.tempoRange.max = Math.min(settings.tempoRange.max, 135)
		}

		this.gpService.setAsUpdated("formTags")
	}

	isSelectedFormTag(formTag) {
		let selectedFormTags = this.formTags

		for (let tag of selectedFormTags) {
			if (tag.id == formTag.id) {
				return formTag.id
			}
		}

		return null
	}
}