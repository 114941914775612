import { ChangeDetectionStrategy, Component } from "@angular/core"
import { ParentClass } from "../../../parent"
import { Router } from "@angular/router"
import { Misc } from "@common-lib/modules/misc"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"

@Component({
    selector: "create-step-by-step",
    templateUrl: "create-step-by-step.component.html",
    styleUrls: ["create-step-by-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateStepbyStepComponent extends ParentClass {
    constructor(private router: Router) {
        super()
    }

    public selectGPForCW(gp) {
        const id = this.selectRandomGP(gp)

        this.router.navigate(["composition-workflow", "stepByStep", id])
    }


    private selectRandomGP(gp): string {
        const revision = GenerationProfile.getLatestPublishedRevision(gp.revisions)

        const gpIDs = revision.gpIDs

        return gpIDs[Math.floor(Math.random() * gpIDs.length)]
    }
}
