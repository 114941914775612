<div class="modal-title">Select an influence</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<div class="loader" *ngIf="isLoading"></div>

<div class="select-gp-influence-container" *ngIf="!isLoading">
    <input
        style="display: none"
        type="file"
        accept="audio"
        #file
        (change)="uploadInfluence($event)" />

    <list
        type="gpInfluences"
        maxHeight="550px"
        class="gp-influence-selection"
        [listItems]="gpInfluences"
        [itemLoader]="true"
        [menuOptions]="templateMenuOptions"></list>

    <div class="button-container">
        <a-button
            label="Upload a new influence"
            type="round-icon-and-text"
            [icon]="'assets/img/menu/influences.svg'"
            class="upload-gp-influence"
            (onClick)="file.click()"></a-button>
    </div>
</div>
