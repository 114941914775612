import { Injectable, Renderer2, RendererFactory2 } from "@angular/core"
import { TinyColor } from "@ctrl/tinycolor"
import { BehaviorSubject } from "rxjs"
import { ContextMenu } from "../models/contextmenu"
import { LayerType } from "@common-lib/constants/constants"
import { ParentClass } from "../parent"

@Injectable()
export class DesignService extends ParentClass {
    renderer: Renderer2

    sidebarCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )
    sidebarWidth: BehaviorSubject<number> = new BehaviorSubject<number>(205)

    /* This configuration should be the same as in the CSS to begin with */
    sidebarWidthOptions = {
        default: 205,
        collapsed: 85,
    }

    constructor(private rendererFactory: RendererFactory2) {
        super()

        this.renderer = this.rendererFactory.createRenderer(null, null) // renderer can't be injected into a service

        this.subscribe(this.sidebarWidth, (sidebarWidth) => {
            this.setCSSProperty("sidebar-width", `${sidebarWidth}px`)
        })
    }

    getColorForGPLayer(layerName: string) {
        let color

        if (layerName.includes("Percussion")) {
            color = this.getCSSProperty("percussion-matte-track-color")
        } else if (layerName.includes("Melody")) {
            color = this.getCSSProperty("melody-matte-track-color")
        } else if (layerName.includes("Chords")) {
            color = this.getCSSProperty("chords-matte-track-color")
        } else if (layerName.includes("Bass")) {
            color = this.getCSSProperty("bass-matte-track-color")
        } else if (layerName.includes("Ornaments")) {
            color = this.getCSSProperty("ornaments-matte-track-color")
        } else {
            color = this.getCSSProperty("extra-matte-track-color")
        }

        return color
    }

    getColorForLayer(layerType: LayerType, format: "hex" | "rgb" = "hex") {
        let color: TinyColor | string = new TinyColor(
            this.getCSSProperty(`chords-track-color`)
        ).darken(6)

        if (layerType == "Melody") {
            color = new TinyColor(
                this.getCSSProperty(`melody-track-color`)
            ).darken(5)
        } else if (layerType == "Chords") {
            color = new TinyColor(
                this.getCSSProperty(`chords-track-color`)
            ).darken(6)
        } else if (layerType.includes("Accompaniment")) {
            // same as Chords
            color = new TinyColor(
                this.getCSSProperty(`chords-track-color`)
            ).darken(6)
        } else if (layerType == "Bass") {
            color = new TinyColor(
                this.getCSSProperty(`bass-track-color`)
            ).darken(3)
        } else if (layerType == "Percussion") {
            color = new TinyColor(
                this.getCSSProperty(`percussion-track-color`)
            ).darken(3)
        } else if (layerType.includes("Extra")) {
            color = new TinyColor(
                this.getCSSProperty(`extra-track-color`)
            ).darken(5)
        } else if (layerType.includes("Ornaments")) {
            color = new TinyColor(
                this.getCSSProperty(`ornaments-track-color`)
            ).darken(5)
        }

        if (format === "hex") {
            return color.toHexString()
        } else if (format === "rgb") {
            return color.toRgbString()
        }
    }

    computeContextMenuStyle(elementRef, value: ContextMenu) {
        elementRef.nativeElement.querySelector(".context-menu").style[
            "z-index"
        ] = "999999999999999"
        elementRef.nativeElement.querySelector(".context-menu").style[
            "position"
        ] = "fixed"

        let left = value.x + 20
        let top = value.y + 20

        let computedStyle = window.getComputedStyle(
            elementRef.nativeElement.querySelector(".context-menu")
        )

        let width = parseInt(
            computedStyle.getPropertyValue("width").replace("px", "")
        )
        let height = parseInt(
            computedStyle.getPropertyValue("height").replace("px", "")
        )

        let paddingLeft = parseInt(
            computedStyle.getPropertyValue("padding-left").replace("px", "")
        )
        let paddingRight = parseInt(
            computedStyle.getPropertyValue("padding-right").replace("px", "")
        )
        width += paddingLeft + paddingRight

        let paddingTop = parseInt(
            computedStyle.getPropertyValue("padding-top").replace("px", "")
        )
        let paddingBottom = parseInt(
            computedStyle.getPropertyValue("padding-bottom").replace("px", "")
        )
        height += paddingTop + paddingBottom

        if (top + height > window.innerHeight) {
            top = window.innerHeight - height - 20
        }

        if (left + width > window.innerWidth) {
            left = window.innerWidth - width - 20
        }

        elementRef.nativeElement.querySelector(".context-menu").style["top"] =
            top + "px"
        elementRef.nativeElement.querySelector(".context-menu").style["left"] =
            left + "px"
    }

    /**
     * Sets a value of a custom CSS property
     * @param cssVariableName String name of the CSS custom property without leading double hyphens, e.g. primary-color for '--primary-color' property
     * @param value String value to use for the custom CSS property
     */
    setCSSProperty(cssVariableName: String, value: String) {
        this.renderer.setStyle(
            document.documentElement,
            `--${cssVariableName}`,
            `${value}`,
            2
        )
    }

    getCSSProperty(cssVariableName: String, type: "--" | "$" = "--") {
        const bodyStyles = window.getComputedStyle(document.body)
        const cssProperty = bodyStyles.getPropertyValue(
            `${type}${cssVariableName}`
        )

        return cssProperty
    }

    getContext(name): CanvasRenderingContext2D | null {
        return (
            (document.getElementById(name) as HTMLCanvasElement)?.getContext(
                "2d"
            ) || null
        )
    }

    setSidebarWidth(width: number) {
        this.sidebarWidth.next(width)
    }

    rgbToHSL(r, g, b) {
        // Make r, g, and b fractions of 1
        r /= 255
        g /= 255
        b /= 255

        // Find greatest and smallest channel values
        let cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0

        // Calculate hue
        // No difference
        if (delta == 0) {
            h = 0
        }

        // Red is max
        else if (cmax == r) {
            h = ((g - b) / delta) % 6
        }

        // Green is max
        else if (cmax == g) {
            h = (b - r) / delta + 2
        }

        // Blue is max
        else {
            h = (r - g) / delta + 4
        }

        h = Math.round(h * 60)

        // Make negative hues positive behind 360°
        if (h < 0) {
            h += 360
        }

        // Calculate lightness
        l = (cmax + cmin) / 2

        // Calculate saturation
        s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

        // Multiply l and s by 100
        s = +(s * 100).toFixed(1)
        l = +(l * 100).toFixed(1)

        return {
            h: h,
            s: s,
            l: l,
        }
    }

    /**
     *
     * @param isCollapsed boolean
     * @returns Promise with the isCollapsed value
     */
    setSidebarCollapsed(isCollapsed: boolean) {
        this.sidebarCollapsed.next(isCollapsed)

        let sidebarWidth = isCollapsed
            ? this.sidebarWidthOptions.collapsed
            : this.sidebarWidthOptions.default

        this.setSidebarWidth(sidebarWidth)

        return Promise.resolve(isCollapsed)
    }
}
