import { Component, ElementRef, HostBinding, Input, OnInit, AfterViewInit, Output, EventEmitter } from "@angular/core"
import { ApiService } from "@services/api.service"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"

@Component({
    selector: 'compare-two-gps',
    templateUrl: 'compare-two-gps.component.html',
    styleUrls: ['compare-two-gps.component.scss']
})
export class CompareTwoGPsComponent implements OnInit {
    firstGPID
    secondGPID

    report 

    @Output() close:EventEmitter<any> = new EventEmitter()

    constructor(private gpService:GenerationProfileService, private apiService:ApiService) {

    }

    ngOnInit() {

    }

    closeModal() {
        this.close.emit()
    }

    compare() {
        this.report = null

        this.apiService.authRequest('/generationprofile/compare', { first: this.firstGPID, second: this.secondGPID }, "primary", true).then((res) => {
            this.report = res.report
        })

        .catch((err) => {
            this.apiService.handleError(err)
        })
    }

    round(value) {
        return Math.min(100, Math.round(value))
    }

    dictionaryToArray(dict) {
        return Object.keys(dict)
    }
}
