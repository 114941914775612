<div class="modal-title">{{ title }}</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<input
    type="text"
    class="modal-input"
    #renameField
    [(ngModel)]="rename"
    (keydown.enter)="setName()"
    av-data="renameInput" />

<div class="loader" *ngIf="loading"></div>

<div>
    <div class="modal-button modal-text-button" (click)="closeModal()">
        Cancel
    </div>
    <div
        class="modal-button modal-blue-button"
        (click)="setName()"
        av-data="renameConfirm">
        Done
    </div>
</div>
