import { Injectable } from "@angular/core"
import {
    DrumSamplesMap,
    InstrumentsJSON,
    PitchToChannelMapping,
} from "@common-lib/interfaces/score/general"
import { ApiService } from "@services/api.service"

@Injectable()
export class InstrumentsHttpService {
    constructor(private api: ApiService) {}

    public async getInstruments(): Promise<{
        instruments: InstrumentsJSON
        drumSamples: DrumSamplesMap
        drumkitPitchToChannelMapping: PitchToChannelMapping
    }> {
        try {
            const response = await this.api.authRequest(
                "/data/getInstruments",
                {},
                "primary",
                true
            )

            return {
                instruments: response.instruments,
                drumSamples: response.drumSamples,
                drumkitPitchToChannelMapping:
                    response.drumkitPitchToChannelMapping,
            }
        } catch (e) {
            this.api.handleError(e)
        }
    }

    public async getRTInstrumentBucketFiles() {
        try {
            const result = await this.api.request(
                "/rtInstrumentBucketFiles",
                {},
                "primary",
                "post",
                true
            )

            return result.instruments
        } catch (e) {
            this.api.handleError(e)

            return []
        }
    }

    public async getTemplateScore() {
        try {
            const result = await this.api.request(
                "/getTemplateScore",
                {},
                "primary",
                "post",
                true
            )
            const templateScore = result.templateScore

            return templateScore
        } catch (e) {
            this.api.handleError(e)

            return undefined
        }
    }
}
