import { FormDescriptionSchema, StructureSettingsSchema } from "../../../interfaces/db-schemas/generationprofile"
export class StructureSettings {
    subsequenceLength:number = 1
    partialSections:boolean = false
    maxThemes:{ name:string, value:number } = {
        name: "A",
        value: 0
    }

    constructor() {

    }

    decode(args: StructureSettingsSchema) {
        for (let key in args) {
            if (key == "partialSections") {
                this.partialSections = args.partialSections != "none"
            }

            else if (key == "maxThemes") {
                if (args[key] == 0) {
                    this.maxThemes = {
                        name: "A",
                        value: 0
                    }
                }

                else if (args[key] == 1) {
                    this.maxThemes = {
                        name: "A, B",
                        value: 1
                    }
                }

                else {
                    this.maxThemes = {
                        name: "A, B, C",
                        value: 2
                    }
                }
            }

            else if (key == "subsequenceLength") {
                if (args[key] == "short") {
                    this.subsequenceLength = 0
                }

                else if (args[key] == "medium") {
                    this.subsequenceLength = 1
                }

                else {
                    this.subsequenceLength = 2
                }
            }

            else {
                this[key] = args[key]
            }
        }
    }

    static fromJSON(object) {
        let structure:StructureSettings = Object.assign(new StructureSettings(), object)

        return structure
    }
}