import { Component, AfterViewInit } from "@angular/core"
import { ApiService } from "../../services/api.service"
import { BillingService } from "../../services/billing.service"
import { ModalService } from "../../services/modal.service"
import { DatePipe } from "@angular/common"
import { HelperService } from "../../helpers/helper.service"
import { MessageService } from "../../services/message.service"
import { UserService } from "../../services/user.service"
import { DeviceDetectorService } from "ngx-device-detector"

@Component({
    selector: "billing",
    templateUrl: "billing.component.html",
    styles: [],
})
export class BillingComponent implements AfterViewInit {
    subscription
    subscriptionLoading

    billingMonthly = true

    selectedBillingCycle = "monthly"

    questions = {
        studentDiscount: false,
        termsOfLicense: false,
        inGamesAndYoutube: false,
        company: false,
        fullCopyright: false,
    }

    countries

    showMore = false
    enterprise = false

    cvc
    cardNumber
    cardNumberPlaceholder = "Card Number"
    expirationDate
    expirationDatePlaceholder = "MM / YY"

    fullName = ""
    firstName = ""
    lastName = ""
    address = ""
    city = ""
    zip = ""
    country = ""
    cardholderName = ""
    vat = ""

    cardNumberError = ""
    expirationDateError = ""

    addPaymentMethodLoading = false
    subscribeThroughPaymentMethod = ""

    vatExempt = false

    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    canMakeWalletPayment: boolean = true

    showPaypalPaymentMethod: boolean = true
    showWalletPaymentMethods: boolean = false
    showCreditCardPaymentMethod: boolean = true

    walletPaymentRequest
    message = { type: "", text: "", show: false }
    changeSelectStyle = false
    defaultPaymentMethod = ""

    braintreeBillingAddressValid = false
    braintreeValidationErrors = []
    paypalButtonVisible = false

    constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private billingService: BillingService,
        private helper: HelperService,
        public messageService: MessageService,
        private device: DeviceDetectorService
    ) {
        this.message = this.messageService.defaultMessage

        this.subscriptionLoading =
            this.billingService.subscriptionLoading.getValue()

        this.billingService.subscribeThroughPaymentMethod.subscribe(value => {
            this.subscribeThroughPaymentMethod = value
        })

        this.billingService.countries.subscribe(value => {
            this.countries = value
        })

        this.billingService.paymentDetails.subscribe(value => {
            let billingInformation = value.billingInformation
            let paymentMethod = value.paymentMethod

            // setup Payment info (e.g. for usage in a form) when paymentDetails given
            if (billingInformation) {
                // show all payment methods only if no credit card info is present already
                if (!billingInformation.last4) {
                    // show Apple Pay only if the user has not yet added a credit card AND can make wallet payments
                    this.showWalletPaymentMethods =
                        this.canMakeWalletPayment &&
                        device.os != "Linux" &&
                        device.os != "Windows"
                    this.showCreditCardPaymentMethod = true
                    this.paypalButtonVisible = true
                } else {
                    this.showWalletPaymentMethods = false
                    this.showCreditCardPaymentMethod = true
                }

                this.firstName = billingInformation.firstName
                this.lastName = billingInformation.lastName
                this.city = billingInformation.city
                this.zip = billingInformation.zip
                this.country = billingInformation.country
                this.cardholderName = billingInformation.cardholderName
                this.expirationDatePlaceholder = billingInformation.expiryDate
                this.address = billingInformation.address
                this.fullName = billingInformation.fullName
                this.vat = billingInformation.vat

                if (!billingInformation.last4) {
                    this.cardNumberPlaceholder = "xxxx xxxx xxxx xxxx"
                } else {
                    this.cardNumberPlaceholder =
                        "xxxx xxxx xxxx " + billingInformation.last4
                }

                if (this.cvc && this.cardNumber && this.expirationDate) {
                    this.cvc.clear()
                    this.cardNumber.clear()
                    this.expirationDate.clear()
                }

                this.setSelectedPM(paymentMethod)
            } else {
                // no customer yet
                this.showCreditCardPaymentMethod = true
                this.showPaypalPaymentMethod = true
                this.showWalletPaymentMethods =
                    this.canMakeWalletPayment &&
                    device.os != "Linux" &&
                    device.os != "Windows"
                this.setSelectedPM("credit-card")
            }

            // if(this.helper.isEmptyObject(billingInformation)){
            //     this.billingService.activePaymentMethod = ""
            // }

            // set the users default payment method
            this.setDefaultPM(paymentMethod)
        })

        this.billingService.subscriptionLoading.subscribe(value => {
            this.subscriptionLoading = value
        })

        this.billingService.subscription.subscribe(value => {
            this.subscription = value

            if (
                value != null &&
                (this.subscription.plan == "standard_monthly" ||
                    this.subscription.plan == "pro_monthly")
            ) {
                this.billingMonthly = true
            }

            if (
                value != null &&
                (this.subscription.plan == "standard_annually" ||
                    this.subscription.plan == "pro_annually")
            ) {
                this.billingMonthly = false
            }
        })

        this.changeSelectStyle =
            (device.browser == "Chrome" && device.os == "Linux") ||
            device.os == "Windows" // select options were invisible because of a lack of contrast
    }

    ngAfterViewInit() {
        this.billingService.getStripeKey()

        this.billingService.initBraintree().then(() => {
            this.createPaypalCheckout()
        })

        this.buildStripeCardElement()
        this.buildStripePaymentRequestButton()

        this.billingService.isVATExempt().then(vatExempt => {
            this.vatExempt = vatExempt
        })

        this.billingService.canMakeWalletPayment().then(result => {
            this.canMakeWalletPayment = result
            if (!result) this.showWalletPaymentMethods = false
        })
    }

    getMenu() {
        return this.billingService.menu
    }

    setMenu(menu) {
        this.billingService.menu = menu
    }

    formatPlan(plan) {
        if (plan == null) {
            return ""
        }

        return plan.replace("_", " ")
    }

    getSubscriptionPlan() {
        if (this.subscription == null || this.subscription.plan == null) {
            return "-"
        }

        return this.subscription.plan.replace("_", " ")
    }

    buildStripeCardElement() {
        try {
            var cardStyle = {
                base: {
                    color: "white",
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "rgba(255,255,255,0.5)",
                    },
                },

                invalid: {
                    color: "rgb(255, 107, 25)",
                    iconColor: "rgb(255, 107, 25)",
                },
            }

            this.cardNumber = this.billingService.elements.create(
                "cardNumber",
                { style: cardStyle, placeholder: this.cardNumberPlaceholder }
            )
            this.cardNumber.mount("#card-number")

            this.expirationDate = this.billingService.elements.create(
                "cardExpiry",
                {
                    style: cardStyle,
                    placeholder: this.expirationDatePlaceholder,
                }
            )
            this.expirationDate.mount("#expiration-date")

            this.cvc = this.billingService.elements.create("cardCvc", {
                style: cardStyle,
            })
            this.cvc.mount("#cvc")

            this.cardNumber.addEventListener("change", event => {
                if (event.error) {
                    this.cardNumberError = event.error.message
                } else {
                    this.cardNumberError = ""
                }
            })

            this.expirationDate.addEventListener("change", event => {
                if (event.error) {
                    this.expirationDateError = event.error.message
                } else {
                    this.expirationDateError = ""
                }
            })
        } catch (e) {
            this.billingService.getStripeKey()

            this.buildStripeCardElement()
        }
    }

    buildStripePaymentRequestButton() {
        try {
            // create paymentrequest
            let paymentRequest = this.billingService.stripe.paymentRequest({
                country: "US",
                currency: "eur",
                displayItems: [
                    { amount: 0, label: "Monthly recurring Subscription fee" },
                ],
                total: { label: "Your card will not be charged!", amount: 0 },
                requestPayerName: true,
            })
            this.walletPaymentRequest = paymentRequest

            // check the availability of the Payment Request API, otherwise neither Apple, Google or Microsoft Pay is possible
            paymentRequest.canMakePayment().then(result => {
                if (result) {
                    this.listenForPayments(paymentRequest, "apple-pay")

                    const prButton = this.billingService.elements.create(
                        "paymentRequestButton",
                        {
                            paymentRequest,
                        }
                    )
                    prButton.mount("#wallet-pay-button")

                    prButton.on("click", event => {
                        event.preventDefault()
                        this.messageService.hideMessage(this.message)

                        if (!this.applePayFormValid()) {
                            return this.messageService.onError(
                                this.message,
                                "Please fill in all fields before you continue"
                            )
                        }
                        this.walletPaymentRequest.show() // needs to be called to show the PaymentRequest window
                    })
                } else {
                    this.canMakeWalletPayment = false
                    this.setWalletPayIsSetup(false)
                    this.showWalletPaymentMethods = false
                }
            })
        } catch (error) {
            // console.error(error)
        }
    }

    /**
     * Method to create a subscription + VAT preview for wallet users
     * Because the VAT is based on the country of a Stripe customer, the method creates a new Stripe customer with the given Name and Countrycode first (if there is no Stripe Customer already added for this user)
     */
    createWalletPreview() {
        this.setWalletPayIsSetup(false)
        this.messageService.hideMessage(this.message)

        this.addPaymentMethodLoading = true
        let currentUser = localStorage.getItem("currentUser")

        // check if subscription plan is selected before any API reuqests happen
        if (
            !this.subscribeThroughPaymentMethod ||
            this.subscribeThroughPaymentMethod == ""
        ) {
            this.addPaymentMethodLoading = false
            return this.setMenu("plans")
        }

        if (!this.applePayFormValid()) {
            this.addPaymentMethodLoading = false
            return this.messageService.onError(
                this.message,
                "Please fill out the form completely."
            )
        }

        // we imitate the structure of a stripe token here
        let stripeToken = {
            card: {
                address_line1: "No real street permitted",
                address_country: this.country,
            },
        }

        this.billingService.defaultPaymentMethod = "apple-pay" // set apple pay as default so the preview will take that into account

        this.billingService
            .createStripeCustomer(currentUser, this.fullName, stripeToken)
            .then(res => {
                return this.billingService.preview(
                    this.subscribeThroughPaymentMethod
                )
            })

            .then(billingPreview => {
                if (billingPreview) {
                    this.updatePaymentRequestByBillingPreview(billingPreview)
                } else {
                    this.addPaymentMethodLoading = false
                }
            })

            .catch(err => {
                this.addPaymentMethodLoading = false
                // console.error(err)
            })
    }

    /**
     * formats the billingPreview Object according to PaymentRequest definitions and updates the PaymentRequest Object afterwards
     * @param billingPreview billingPreview Object
     */
    updatePaymentRequestByBillingPreview(billingPreview) {
        let displayItemsAndTotal = this.billingService.buildDisplayItems(
            billingPreview.preview
        )
        this.walletPaymentRequest.update({
            displayItems: displayItemsAndTotal["displayItems"],
            total: displayItemsAndTotal["total"],
        })
        this.addPaymentMethodLoading = false
        this.setWalletPayIsSetup(true)
    }

    /**
     * listens for events thrown by the PaymentRequest
     * @param paymentRequest PaymentRequest Object
     */
    listenForPayments(paymentRequest, paymentMethod = "apple-pay") {
        // the token will be presented AFTER the user commited the payment
        paymentRequest.on("token", paymentRequestResponse => {
            this.handleWalletPayment(paymentRequestResponse, paymentMethod)
        })

        paymentRequest.on("cancel", () => {
            // console.error("User cancelled his payment")
        })
    }

    /**
     * method to handle the current PaymentRequest made by a Wallet Payment
     * @param paymentRequestResponse
     */
    handleWalletPayment(paymentRequestResponse, paymentMethod = "apple-pay") {
        if (!paymentRequestResponse) {
            paymentRequestResponse.complete("fail")
            this.addPaymentMethodLoading = false
        }

        let token = paymentRequestResponse.token
        let creditCardCountry = token.card.address_country

        return this.setPaymentDetailsForWalletPayments(
            paymentRequestResponse,
            paymentMethod
        )

            .then(() => {
                return this.billingService.subscribe(
                    this.subscribeThroughPaymentMethod
                )
            })

            .then(() => {
                paymentRequestResponse.complete("success")
                this.modalService.subscriptionStatusInfo.next({
                    plan: this.subscribeThroughPaymentMethod,
                    status: "success",
                })
                this.addPaymentMethodLoading = false
                this.setWalletPayIsSetup(false)
                this.setSelectedPM("credit-card")
            })

            .catch(err => {
                this.modalService.subscriptionStatusInfo.next({
                    plan: this.subscribeThroughPaymentMethod,
                    status: "error",
                })
                this.messageService.onError(this.message, err)
                paymentRequestResponse.complete("fail")
            })
    }

    /**
     * updates the current Stripe Customer and sets up new payment details
     * @param paymentRequestResponse response that is sent back by Stripe when requesting the PaymentRequest payment
     */
    setPaymentDetailsForWalletPayments(
        paymentRequestResponse,
        paymentMethod = "apple-pay"
    ) {
        this.addPaymentMethodLoading = true

        return this.billingService.setPaymentDetails(
            paymentRequestResponse,
            this.fullName,
            this.vat,
            paymentMethod
        )
    }

    contactUs() {
        window.open("https://www.aiva.ai/contact", "_blank")
    }

    /**
     * sets payment details only for Stripe
     */
    setPaymentDetails() {
        this.addPaymentMethodLoading = true

        var tokenData = {
            name: this.cardholderName,
            address_line1: this.address,
            address_city: this.city,
            address_zip: this.zip,
            address_country: this.country,
        }

        this.billingService.stripe
            .createToken(this.cardNumber, tokenData)
            .then(result => {
                return this.billingService.setPaymentDetails(
                    result,
                    this.fullName,
                    this.vat
                )
            })

            .then(() => {
                return this.billingService.getPaymentDetails() // here we have to set the default payment method depending on the payment details again
            })

            .then(() => {
                if (this.subscribeThroughPaymentMethod != "") {
                    this.billingService.previewBilling(
                        this.subscribeThroughPaymentMethod
                    )
                }

                this.resetPaymentMethodLoading(true)
            })

            .then(() => {
                return this.billingService.isVATExempt()
            })

            .then(vatExempt => {
                this.vatExempt = vatExempt
            })

            .catch(() => {
                this.resetPaymentMethodLoading(false)
            })
    }

    resetPaymentMethodLoading(toPlans) {
        this.addPaymentMethodLoading = false
        this.billingService.subscribeThroughPaymentMethod.next("")

        if (toPlans) {
            this.setMenu("plans")
        }
    }

    showPricingNote() {
        var datePipe = new DatePipe("en-EU").transform(
            this.subscription.end * 1000,
            "mediumDate"
        )

        if (
            this.subscription.plan == "free" ||
            this.subscription.type == "inactive"
        ) {
            return "Subscribe to any plan to access more features & benefits."
        }

        if (this.subscription.type == "unpaid") {
            return "We tried to collect payment from your credit card but failed. If no action is taken to update your payment method shortly, you will be downgraded to the 'Free' plan."
        }

        if (
            this.subscription.type == "active" ||
            this.subscription.type == "pending"
        ) {
            if (this.subscription.renewal != "same") {
                return (
                    "Your plan will automatically switch to the " +
                    this.formatPlan(this.subscription.renewal) +
                    " plan on " +
                    datePipe
                )
            }

            return "Your plan will automatically renew on: " + datePipe
        }
    }

    showDowngradeMessage(subscriptionType) {
        if (this.subscription.plan == "free" && subscriptionType == "free") {
            return false
        }

        return this.subscription.renewal == subscriptionType
    }

    toLicensing() {
        window.open(
            "https://aiva.crisp.help/en/article/i-dont-understand-the-terms-of-license-1wqvh5v/",
            "_blank"
        )
    }

    toggleQuestion(type) {
        this.questions[type] = !this.questions[type]
    }

    showCurrentPlan(subscriptionType) {
        var result = this.showSubscribeButton(subscriptionType, "current")

        if (this.subscription.plan == "free" && subscriptionType == "free") {
            return true
        }

        if (subscriptionType == "free") {
            result =
                this.showSubscribeButton(subscriptionType, "current") &&
                this.subscription.renewal != "free"
        }

        return result
    }

    showSubscribeButton(plan, buttonType): boolean {
        if (this.subscription.plan == null) {
            return false
        }

        // This means that the button should be hidden to show a loading animation instead
        if (this.subscriptionLoading[plan]) {
            return false
        }

        if (this.subscription.plan == plan) {
            return buttonType == "current"
        }
        // display an upgrade button for every option besides free option and the option we are renewing to
        else if (this.subscription.plan == "free" && plan != "free") {
            return buttonType == "upgrade" && this.subscription.renewal != plan
        } else if (this.subscription.plan != "free" && plan == "free") {
            return (
                buttonType == "downgrade" && this.subscription.renewal != plan
            )
        }

        var currentPlan = this.subscription.plan.split("_")
        var consideredPlan = plan.split("_")

        // First index is for the plan type ('standard' vs 'pro')
        // Second index is for the plan length ('monthly' vs 'annually')

        if (
            currentPlan[0] == consideredPlan[0] &&
            currentPlan[1] == consideredPlan[1]
        ) {
            return buttonType == "current"
        } else if (
            currentPlan[0] == consideredPlan[0] &&
            currentPlan[1] != consideredPlan[1]
        ) {
            if (currentPlan[1] == "monthly") {
                return (
                    buttonType == "upgrade" && this.subscription.renewal != plan
                )
            }

            return (
                buttonType == "downgrade" && this.subscription.renewal != plan
            )
        } else {
            if (currentPlan[0] == "standard") {
                return (
                    buttonType == "upgrade" && this.subscription.renewal != plan
                )
            }

            return (
                buttonType == "downgrade" && this.subscription.renewal != plan
            )
        }
    }

    showDowngradeButton(subscriptionType) {
        var result = this.showSubscribeButton(subscriptionType, "downgrade")

        if (subscriptionType == "free") {
            result =
                this.showSubscribeButton(subscriptionType, "downgrade") &&
                this.subscription.renewal != "free"
        }

        return result
    }

    async changeSubscription(newPlan): Promise<void> {
        if (this.defaultPaymentMethod != "paypal") {
            let paymentDetails = this.billingService.paymentDetails.getValue()
            let noBillingInfo =
                !paymentDetails ||
                !paymentDetails.hasOwnProperty("billingInformation")
            let noCreditCardInfo =
                !paymentDetails.hasOwnProperty("billingInformation") ||
                !paymentDetails.billingInformation.last4

            // if no billing info is present at all OR just no credit-card info is setup
            if (noBillingInfo || noCreditCardInfo) {
                this.billingService.subscribeThroughPaymentMethod.next(newPlan)
                this.setMenu("payment") // switch to payment method tab if no payment details exist yet
                this.setWalletPayIsSetup(false) // reset Wallet pay so we can recalculate the correct subscripiton fees
            }
            // billing details are setup
            else {
                const subscription = this.billingService.subscription.getValue()

                if (
                    Date.now() > subscription.end * 1000 &&
                    newPlan === "free"
                ) {
                    this.billingService.setSubscriptionLoading(true, "free")

                    await this.billingService.subscribe("free")

                    this.billingService.setSubscriptionLoading(false, "free")
                } else {
                    this.billingService.previewBilling(newPlan) // actually call for a billing preview
                }
            }
        }
        // handling for Braintree subscriptions
        else {
            this.billingService.subscribeThroughPaymentMethod.next(newPlan)

            if (
                this.billingService.paymentDetails.getValue() == null ||
                !this.billingService.paymentDetails.getValue()
                    .billingInformation.country
            ) {
                this.setMenu("payment") // switch to payment method tab if no payment details exist yet
            } else {
                this.billingService.previewBilling(newPlan) // actually call for a billing preview
            }
        }
    }

    /**
     * sets the users default payment method here as well as in the service
     * it will be used to previewing subscriptions and actually subscribing to them
     * @param method payment method String
     */
    setDefaultPM(method: string) {
        this.defaultPaymentMethod = method
        this.billingService.defaultPaymentMethod = method
    }

    setSelectedPM(method: string) {
        this.billingService.selectedPaymentMethod = method
        if (this.message.type != "success")
            this.messageService.hideMessage(this.message)
    }
    getSelectedPM() {
        return this.billingService.selectedPaymentMethod
    }

    setActivePM(method: string) {
        this.billingService.activePaymentMethod = method
    }
    getActivePM() {
        return this.billingService.activePaymentMethod
    }
    setWalletPayIsSetup(boolean: boolean) {
        this.billingService.walletPayIsSetup = boolean
    }
    getWalletPayIsSetup() {
        return this.billingService.walletPayIsSetup
    }

    continueWithApplePay() {
        if (this.country && this.fullName && this.fullName != "") {
            this.billingService.previewBilling(
                this.subscribeThroughPaymentMethod
            )
        }
    }

    applePayFormValid() {
        return this.fullName != "" && this.country && this.country != ""
    }

    onSelectCountry(event) {
        this.setWalletPayIsSetup(false) // reset so a user cannot change the country after checking for his current subscription fee
    }

    getBraintreeCustomerAddress() {
        // template for a Braintree useraddress -> https://developers.braintreepayments.com/reference/request/address/create/node
        let address = {
            firstName: this.firstName,
            lastName: this.lastName,
            streetAddress: this.address,
            locality: this.city,
            postalCode: this.zip,
            countryCodeAlpha2: this.country,
        }
        return address
    }

    createPaypalCheckout() {
        let currentDeviceData

        let paypalButton = document.getElementById("paypal-button")
        if (!paypalButton) return false // if PayPal is not supported, the button will not be visible

        // Create a PayPal checkout for our billing agreement
        return (
            this.billingService.braintreeApi.paypalCheckout
                .create({
                    client: this.billingService.braintree,
                })
                .then(paypalCheckoutInstance => {
                    return paypalCheckoutInstance.loadPayPalSDK({
                        vault: true, // we have to vault / save here because we want to use the payment method for our future subscriptions
                    })
                })
                // Create the PayPal button and the eventlisteners
                .then(paypalCheckoutInstance => {
                    return window["paypal"]
                        .Buttons({
                            fundingSource: window["paypal"].FUNDING.PAYPAL,

                            // this part actually creates the billing agreement that needs to be approved by the user
                            createBillingAgreement: () => {
                                return paypalCheckoutInstance.createPayment({
                                    flow: "vault", // Required
                                    // The following are optional params
                                    billingAgreementDescription:
                                        "This will be needed for future payments.",
                                    enableShippingAddress: false,
                                    shippingAddressEditable: false,
                                })
                            },
                            onApprove: (data, actions) => {
                                // gets called when the user approves our billing agreement
                                return this.onPaypalApprove(
                                    paypalCheckoutInstance,
                                    currentDeviceData,
                                    data
                                )
                            },
                            onCancel: data => {
                                // console.log('PayPal payment cancelled', data)
                            },
                            onError: err => {
                                // console.error('PayPal error', err)
                            },
                            // customization can be found here https://developer.paypal.com/docs/checkout/integration-features/customize-button/
                            style: {
                                height: 30,
                                color: "gold",
                                shape: "rect",
                                label: "paypal",
                            },
                        })
                        .render("#paypal-button")
                })
                .then(() => {
                    // This function will be called when the PayPal button is set up and ready to be used
                })
                .catch(err => {
                    // Handle component creation error
                    // console.error("PayPal Error, for details see: " + err)
                })
        )
    }

    /**
     * handles the creation and update of a Braintree customer
     * @param paypalCheckoutInstance instance of the PayPal checkout Object
     * @param deviceData additional data about the customer's device that will be saved in Braintree
     * @param approveData
     * @param createOrUpdate
     */
    onPaypalApprove(paypalCheckoutInstance, deviceData, approveData) {
        let paypalPayload
        let creationOrUpdate = "creation"
        this.addPaymentMethodLoading = true

        return (
            paypalCheckoutInstance
                .tokenizePayment(approveData)
                .then(payload => {
                    // if (payload) payload["deviceData"] = deviceData
                    paypalPayload = payload

                    // only create a new user if the user is not a PayPal customer already
                    if (this.billingService.defaultPaymentMethod != "paypal") {
                        creationOrUpdate = "creation"
                        return this.billingService.createBraintreeCustomer(
                            paypalPayload,
                            this.getBraintreeCustomerAddress()
                        )
                    }
                    // user has PayPal already set as his default payment method, so we update the customer
                    else {
                        creationOrUpdate = "update"
                        return this.billingService.updateBraintreeCustomer(
                            paypalPayload,
                            this.getBraintreeCustomerAddress()
                        )
                    }
                })
                // success
                .then(customerID => {
                    if (creationOrUpdate == "creation") {
                        this.messageService.onSuccess(
                            this.message,
                            "PayPal has been successfully added as your default payment method."
                        )
                    } else {
                        this.messageService.onSuccess(
                            this.message,
                            "Your PayPal account has been successfully updated."
                        )
                    }

                    if (this.subscribeThroughPaymentMethod != "") {
                        setTimeout(() => {
                            this.billingService.previewBilling(
                                this.subscribeThroughPaymentMethod
                            )

                            this.resetPaymentMethodLoading(true)
                        }, 1500)
                    } else {
                        this.resetPaymentMethodLoading(true) // go back to plans
                    }

                    return Promise.resolve(customerID)
                })
                .catch(err => {
                    this.addPaymentMethodLoading = false
                    this.messageService.onError(this.message, err)
                    this.apiService.handleError(err)
                })
        )
    }

    checkBraintreeAdress(event) {
        let customerAddress = {
            countryCodeAlpha2: this.country,
            firstName: this.firstName,
            lastName: this.lastName,
            locality: this.city,
            postalCode: this.zip,
            streetAddress: this.address,
        }
        let validation =
            this.billingService.validBraintreeCustomerAddress(customerAddress)

        this.braintreeBillingAddressValid = validation.valid
        this.braintreeValidationErrors = validation.errors || []
        this.paypalButtonVisible = this.braintreeBillingAddressValid
    }

    hideAllPaymentMethodsButPaypal() {
        this.showWalletPaymentMethods = false
        this.showCreditCardPaymentMethod = false
    }
}
