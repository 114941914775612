import { Injectable } from "@angular/core"
import { DoActionBeforeModalParameters, ModalService } from "./modal.service"

@Injectable()
export class ConfirmDiscardChangesService {
    constructor(private modalService: ModalService) {}

    public async openDoActionsBeforeModal({
        title,
        description,
        continueButtonText = "Discard & leave",
        cancel,
        action,
        afterAction,
        modalId,
    }: {
        title: string
        description: string
        continueButtonText?: string
        cancel?:
            | (() => void)
            | ((dontShowAgain: boolean) => Promise<{ success: boolean }>)
        action?: (dontShowAgain: boolean) => Promise<{ success: boolean }>
        afterAction?: () => void
        modalId?: string
    }) {
        const parameters = {
            action,
            cancel,
            afterAction: () => {
                if (afterAction !== undefined) {
                    afterAction()
                }
            },
            title,
            description,
            continueButtonText,
            modalId,
        } as DoActionBeforeModalParameters

        this.modalService.modals.doActionsBefore.next(parameters)
    }
}
