import { ChangeDetectorRef, Component, OnInit } from "@angular/core"
import { ApiService } from "../../services/api.service"
import { CompositionService } from "../../services/composition.service"
import { FolderService } from "../../services/folder.service"
import { ModalService } from "../../services/modal.service"
import { ActivatedRoute, Router } from "@angular/router"
import { NgxFileDropEntry } from "ngx-file-drop"
import { MIDIImport } from "@common-lib/modules/midi-import.module"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { Midi } from "@tonejs/midi"
import { GeneralService } from "@services/general/general.service"
import { featureFlags } from "@common-lib/utils/feature-flags"

@Component({
    selector: "upload-midi",
    templateUrl: "upload-midi.component.html",
    styleUrls: ["upload-midi.component.scss"],
})
export class UploadMidiComponent {
    public files: NgxFileDropEntry[] = []

    public loading = false
    public step = 1
    public midiErrors: MIDIImport.MidiErrorType

    private midi: Midi
    private file: FileSystemFileEntry
    // The keys for warnings should match the keys in midi-import.module.ts -> MidiErrorKeys
    private warnings: [string, string][]
    public collapseLayers =
        featureFlags?.collapseLayersWhenImportingMidi === true

    public get enableCollapseLayers() {
        return featureFlags?.collapseLayersWhenImportingMidi === true
    }

    public get hasWarnings() {
        return this.warnings?.length > 0
    }

    public tooltips = {
        collapseLayers:
            "When this feature is enabled, similar tracks in the imported MIDI file (those with matching notes or instrumentation) will be merged into a single layer. This process reduces the number of notes in each layer but retains the original musical content, simplifying the composition for easier editing and understanding.",
    }

    constructor(
        protected apiService: ApiService,
        protected route: ActivatedRoute,
        protected modalService: ModalService,
        protected folderService: FolderService,
        protected compositionService: CompositionService,
        private instruments: InstrumentsService,
        private cd: ChangeDetectorRef,
        protected generalService: GeneralService
    ) {}

    public dropped(files: NgxFileDropEntry[]) {
        this.files = files

        if (this.files.length > 1 && this.files.length < 1) {
            return
        }

        this.uploadFiles(this.files)
    }

    async uploadFiles(files) {
        if (files[0].fileEntry.name.toLowerCase().includes(".mid")) {
            return this.loadMIDIFile(files[0].fileEntry as FileSystemFileEntry)
        }
    }

    async loadMIDIFile(file: FileSystemFileEntry) {
        let midi: Midi
        let errors: MIDIImport.MidiErrorType = {}
        try {
            midi = await MIDIImport.convertFileSystemEntryToMIDI(file)
        } catch (e) {
            errors[MIDIImport.MidiErrorObjects.IMPORT] = {
                errors: {
                    [MIDIImport.MidiErrorKeys.FailedToImport]:
                        "Failed to read the midi file",
                },
                warnings: {},
            }
        }
        if (midi) {
            errors = MIDIImport.validateMIDIV2(midi)
        }

        let pass = true
        let errorsArr: string[] = []
        for (let key in errors) {
            if (Object.keys(errors[key].errors).length !== 0) {
                pass = false
                for (let error in errors[key].errors) {
                    errorsArr.push(errors[key].errors[error])
                }
            }
        }

        this.midiErrors = errors

        this.cd.detectChanges()

        if (!pass) {
            let error = errorsArr[0]

            if (errorsArr.length > 1) {
                error += " + " + (errorsArr.length - 1) + " other errors"
            }

            return this.apiService.handleError(error)
        }
        this.midi = midi
        this.file = file
        // await this.incrementStep()

        await this.getWarningsFromMIDIErrors()

        // Since we now will display some form elements, we need to
        // increment the step to switch to the second page and show them.
        this.step = 2

        this.cd.detectChanges()
    }

    private async handleUpload() {
        this.loading = true
        this.cd.detectChanges()

        try {
            let { score, convertedScore } = MIDIImport.toTemplateScore(
                this.midi,
                this.instruments.instruments,
                this.instruments.drumSamples,
                true,
                {
                    sections: true,
                    timeSignature: true,
                    tempo: true,
                    keySignature: false,
                    chords: false,
                }
            )

            if (
                featureFlags.collapseLayersWhenImportingMidi &&
                this.collapseLayers
            ) {
                score =
                    MIDIImport.collapseScoreLayersIterativelyByPartials(
                        convertedScore
                    )
            }

            const jsonString = JSON.stringify(score)
            const blob = new Blob([jsonString], { type: "application/json" })
            const jsonFile = new File([blob], Date.now() + ".json", {
                type: "application/json",
            })

            const formData = new FormData()
            formData.append("json", jsonFile, this.file.name)

            const promises = [
                this.apiService.authFormRequest(
                    "/composition/importMIDI",
                    formData
                ),
            ]

            const responses = await Promise.all(promises)
            const contentType =
                responses[0].type == "gp" ? "Styles" : "Compositions"

            this.loading = false
            return this.folderService.setContentType(
                "uploadFiles",
                contentType,
                "",
                true
            )
        } catch (e) {
            this.apiService.handleError(e)
            this.loading = false
            this.reset()
        }
    }

    private async incrementStep() {
        this.step++
        if (this.step === 2) {
            this.getWarningsFromMIDIErrors()
        }

        if (!this.warnings?.length) {
            this.step--
            await this.handleUpload()
        }
        this.cd.detectChanges()
    }

    private getWarningsFromMIDIErrors() {
        for (const key in this.midiErrors) {
            if (Object.keys(this.midiErrors[key].warnings).length !== 0) {
                if (!this.warnings) {
                    this.warnings = []
                }

                for (const warningKey in this.midiErrors[key].warnings) {
                    this.warnings.push([
                        warningKey,
                        this.midiErrors[key].warnings[warningKey],
                    ])
                }
            }
        }

        return this.warnings
    }

    async confirm() {
        await this.handleUpload()
    }

    reset() {
        this.warnings = null
        this.midiErrors = null
        this.midi = null
        this.file = null
        this.loading = false
        this.step = 1
        this.files = []

        this.cd.detectChanges()
    }

    isMobile() {
        return this.generalService.isMobile()
    }

    setCollapseLayers(value) {
        this.collapseLayers = value

        if (!this.enableCollapseLayers) {
            this.collapseLayers = false
        }

        this.cd.detectChanges()
    }
}
