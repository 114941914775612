import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core"
import Layer from "@common-lib/classes/score/layer"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { RecommendationsService } from "@services/recommendations.service"
import { ParentClass } from "../../../../parent"
import { DoublesService } from "@services/doubles.service"
import {
    DoublingInstrumentSelectionModalParameters,
    InstrumentSelectionModalParameters,
    ModalService,
} from "@services/modal.service"
import { WindowService } from "@services/window.service"

@Component({
    selector: "search-bar",
    templateUrl: "./search-bar.component.html",
    styleUrls: ["./search-bar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent extends ParentClass implements OnInit {
    @Input() parameters:
        | InstrumentSelectionModalParameters
        | DoublingInstrumentSelectionModalParameters

    @Input() type: "instruments" | "doubles" = "instruments"
    @Output() close: EventEmitter<any> = new EventEmitter()
    @Output() searchResults: EventEmitter<any[]> = new EventEmitter()

    @ViewChild("searchBar", { static: false }) searchBar: ElementRef

    searchTimeout = undefined
    searchKeyword = ""

    get supportDownloadAllInstruments() {
        return (
            this.windowService?.desktopAppAPI !== undefined &&
            this.windowService?.desktopAppInfo?.platform !== "linux"
        )
    }

    constructor(
        private instruments: InstrumentsService,
        private ref: ChangeDetectorRef,
        private recommendations: RecommendationsService,
        private modalService: ModalService,
        private doublesService: DoublesService,
        private windowService: WindowService
    ) {
        super()
    }

    ngOnInit(): void {
        this.subscribe(
            this.instruments.query.select("refreshSearchUI"),
            value => {
                this.ref.detectChanges()
            }
        )

        this.focusOnSearchBar()
    }

    ngAfterViewInit() {}

    private focusOnSearchBar() {
        setTimeout(() => {
            if (this.searchBar == null) {
                return
            }

            this.searchBar.nativeElement.focus()
        }, 100)
    }

    public recommendationsAreApplied() {
        return this.recommendations.recommendationsAreApplied()
    }

    public showRecommendations(event) {
        this.recommendations.showRecommendations(event, this.parameters.layer)
    }

    public isDownloadingAllInstruments() {
        return this.instruments.query.isDownloadingAllInstruments
    }

    public getNumberOfPatches() {
        if (this.instruments) {
            let rest: any
            let thousands

            thousands = Math.floor(this.instruments.numberOfPatches / 1000)
            rest = this.instruments.numberOfPatches % 1000

            if (thousands > 0) {
                if (rest < 10) {
                    rest = "00" + rest
                } else if (rest < 100) {
                    rest = "0" + rest
                }

                return thousands + "," + rest
            }

            return rest
        } else {
            let numberOfDoubles =
                this.doublesService.userGeneratedDoubles[
                    this.parameters.layer.type
                ].length +
                this.doublesService.aivaGeneratedDoubles[
                    this.parameters.layer.type
                ].length

            let rest: any = Math.floor(numberOfDoubles % 1000)
            let thousands = Math.floor(numberOfDoubles / 1000)

            if (thousands > 0) {
                if (rest < 10) {
                    rest = "00" + rest
                } else if (rest < 100) {
                    rest = "0" + rest
                }

                return thousands + "," + rest
            }

            return rest
        }
    }

    public openDownloadInstrumentsModal() {
        this.modalService.modals.downloadAllInstruments.next({
            onClose: (() => {
                if (this.type === "instruments") {
                    this.modalService.modals.instrumentSelection.next(
                        this.parameters as InstrumentSelectionModalParameters
                    )
                } else {
                    this.modalService.modals.doublingInstrumentSelection.next(
                        this
                            .parameters as DoublingInstrumentSelectionModalParameters
                    )
                }
            }).bind(this),
        })

        this.close.emit()
    }

    public searchForInstrument(event) {
        if (this.searchTimeout !== undefined) {
            clearTimeout(this.searchTimeout)
            this.searchTimeout = undefined
        }

        // The timeout is necessary to ensure that the instrumentSelectionSearchKeyword variable is up to date
        this.searchTimeout = setTimeout(() => {
            if (this.searchKeyword == "") {
                this.searchResults.emit([])
                return
            }

            let result = this.instruments.searchForPatches(
                this.searchKeyword,
                this.parameters.layer
            )

            result = result.sort((x, y) => {
                var xLength = x.value.path.split("/").length
                var yLength = y.value.path.split("/").length

                var n = y.exact / yLength - x.exact / xLength

                if (n !== 0) {
                    return n
                }

                return y.partial / yLength - x.partial / xLength
            })

            this.searchResults.emit(result)

            this.ref.detectChanges()
        }, 10)
    }

    public getNumberOfRecommendationsApplied() {
        return this.recommendations.getNumberOfRecommendationsApplied(
            this.parameters.layer.type
        )
    }

    public getDownloadedPatches() {
        return this.instruments.query.downloadAllStatus.completed
    }

    public getTotalToDownload() {
        return this.instruments.query.downloadAllStatus.total
    }
}
