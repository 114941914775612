import { Injectable } from "@angular/core"
import LoopedFunction from "../models/loopedfunction"
import { WindowService } from "./window.service"

@Injectable()
export class AnimationLoopService {
    functions = {}

    loopBinding

    // windowService needs to be injected here to make sure that request anim frane is created
    constructor(public windowService:WindowService) {
        this.loopBinding = this.loop.bind(this)
        this.loop()
    }

    loop() {
        try {
            const now = Date.now()

            for (const funct in this.functions) {
                const loopedFunction: LoopedFunction = this.functions[funct]

                if (
                    loopedFunction.msExecutionCap == null ||
                    loopedFunction.lastRun <=
                        now - loopedFunction.msExecutionCap
                ) {
                    try {
                        loopedFunction.functionBinding()
                    } catch (e) {
                        console.error(
                            "Error with looped function ",
                            funct,
                            ": ",
                            e
                        )
                    }

                    loopedFunction.lastRun = now
                }
            }
        } catch (e) {
            console.error(e)
        }

        window["requestAnimFrame"](this.loopBinding)
    }

    deleteFunctionFromLoop(key) {
        delete this.functions[key]
    }

    addFunctionToLoop(key, functionBinding, msExecutionCap = null) {
        this.functions[key] = new LoopedFunction(
            key,
            functionBinding,
            msExecutionCap
        )
    }
}
