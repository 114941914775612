import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit,
} from "@angular/core"
import AccompanimentLayer from "@common-lib/classes/generationprofiles/accompaniment/accompanimentlayer"
import { Misc } from "@common-lib/modules/misc"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import Pack from "@common-lib/classes/generationprofiles/pack"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import {
    GPInstrumentSelectionModalParameters,
    ModalService,
} from "@services/modal.service"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { DesignService } from "@services/design.service"
import { ReplaySubject } from "rxjs/internal/ReplaySubject"
import { max, min, takeUntil } from "rxjs/operators"
import { environment } from "@environments/environment"
import { Time } from "@common-lib/modules/time"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import { MenuOptions } from "../menu-options/menu-options.component"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { AccompanimentDesignerHttpService } from "@services/accompaniment-designer/accompaniment-designer.http"
import { STEPS_SCHEMA_DEFAULT } from "@common-lib/constants/defaultValues"
import { ApiService } from "@services/api.service"

@Component({
    selector: "pack",
    templateUrl: "./pack.component.html",
    styleUrls: ["./pack.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackComponent implements AfterViewInit {
    @Input() layer: GPLayer
    @Input() pack: Pack | AccompanimentPack
    @Input() type: string = ""
    @Input() index: number = -1
    @Input() class: string = ""
    @Output() onEditPack = new EventEmitter<Pack>()

    public menuOptions: MenuOptions<Pack | AccompanimentPack> | undefined
    public retrySavingPackLoading = false

    playing = false

    packMenu = {
        x: 0,
        y: 0,
    }

    private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)

    validation = null

    constructor(
        private designService: DesignService,
        private gpService: GenerationProfileService,
        private ref: ChangeDetectorRef,
        private modalService: ModalService,
        private adHttp: AccompanimentDesignerHttpService,
        private apiService: ApiService
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.validation = this.pack.validate(true)
            this.gpService.drawPacksPreview(
                this.pack,
                this.layer.name,
                this.type,
                this.index
            )
        }, 1)

        this.gpService.refreshGenerationProfileUI
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                if (this.pack != null && this.pack.packID != null) {
                    let validation = this.pack.validate(true)

                    if (validation != this.validation) {
                        this.validation = validation
                    }
                }

                this.detectChanges()
            })
    }

    public async retrySavingPack() {
        if (!(this.pack instanceof AccompanimentPack)) {
            return
        }

        this.retrySavingPackLoading = true

        const newPackID = await this.adHttp.retrySavingPack(
            this.pack.packID,
            this.getGenerationProfile()._id
        )

        this.retrySavingPackLoading = false

        await Misc.wait(2)

        this.pack.packID = newPackID

        this.editPack()
    }

    public showRetry() {
        if (
            this.pack instanceof AccompanimentPack &&
            !this.retrySavingPackLoading
        ) {
            const status = AccompanimentPack.stepsToLoadingStatus(
                this.pack.steps
            )

            const fiveMinutesAgo = Date.now() - 1000 * 60 * 5

            return (
                (status.type === "loading" &&
                    this.pack.creationDate <= fiveMinutesAgo) ||
                status.type === "error"
            )
        }

        return false
    }

    public showLoading() {
        if (this.retrySavingPackLoading) {
            return true
        }

        if (this.pack instanceof AccompanimentPack) {
            const status = AccompanimentPack.stepsToLoadingStatus(
                this.pack.steps
            )

            const fiveMinutesAgo = Date.now() - 1000 * 60 * 5

            return (
                status.type === "loading" &&
                this.pack.creationDate > fiveMinutesAgo
            )
        }

        return false
    }

    deleteSelectedPack(selectedPack) {
        let p = 0

        for (let pack of this.layer.packs) {
            if (selectedPack == pack) {
                this.layer.packs.splice(p, 1)

                break
            }

            p += 1
        }

        this.gpService.pausePreviewForLayer(
            this.layer.name,
            selectedPack.packID,
            null
        )
        this.gpService.removeSynchronisationForPacks([selectedPack])
        this.gpService.setAsUpdated("deletePack")

        this.unselectPack()
    }

    isProduction() {
        return environment.production
    }

    melodyPackPreviewIsLoading() {
        return this.gpService.melodyPreviewLoading
    }

    changePackType() {
        this.modalService.modals.selectPack.next({
            type: "change",
            layer: this.layer as AccompanimentLayer,
            pack: this.pack,
        })

        this.unselectPack()
    }

    editPack() {
        this.unselectPack()

        this.onEditPack.emit(this.pack)
    }

    isSynced() {
        return (
            (this.pack.synchronisation != null &&
                this.pack.synchronisation.target != null) ||
            (this.pack.transform != null && this.pack.transform.target != null)
        )
    }

    ngOnDestroy() {
        this.destroy$.next(true)
        this.destroy$.complete()
    }

    openSynchronisationMenu(selectedPack) {
        let layers = this.gpService.generationProfile.accompanimentLayers
        let nbOfLayersToSynchTo = 0
        let nbOfPacksToSynchTo = 0

        for (let layer of layers) {
            if (this.layer == layer || layer.name.includes("Ornament")) {
                continue
            }

            nbOfLayersToSynchTo += 1
            nbOfPacksToSynchTo += layer.packs.length
        }

        if (nbOfLayersToSynchTo == 0 || nbOfPacksToSynchTo == 0) {
            this.modalService.modals.noPacksToSynchTo.next(true)
        } else {
            this.modalService.modals.packSynchronisation.next({
                pack: selectedPack,
                layer: this.layer,
            })
        }

        this.unselectPack()
    }

    getPlaybackIcon(patch) {
        return this.gpService.getPlaybackIcon(this.layer.name, patch.patchID)
    }

    duplicate() {
        let newPack: any = this.pack.copy()

        let maxIndex = 0

        for (let pack of this.layer.packs) {
            maxIndex = Math.max(maxIndex, pack["idx"])
        }

        newPack.idx = maxIndex += 1

        this.layer.addPack(newPack)
        this.gpService.setAsUpdated("duplicatePack")
        this.unselectPack()
        this.gpService.scrollTopForLayer.next(this.layer.name)
    }

    clearInstruments() {
        for (let i = this.pack.instruments.length - 1; i >= 0; i--) {
            let instrument = this.pack.instruments[i]
            this.gpService.pausePreviewForLayer(
                this.layer.name,
                this.pack.packID,
                instrument.patchID
            )
            this.pack.instruments.splice(i, 1)
        }

        this.gpService.setAsUpdated("clearInstrumentsFromPack")
        this.unselectPack()
    }

    calculatePreviewDuration(pack) {
        let lastElement = pack.preview[pack.preview?.length - 1]

        return Time.addTwoFractions(lastElement?.onset, lastElement?.duration)
    }

    getLayerColor() {
        return this.designService.getColorForGPLayer(this.layer.name)
    }

    getGenerationProfile() {
        return this.gpService.generationProfile
    }

    getMelody() {
        return this.gpService.generationProfile.melodyLayer
    }

    getAccompanimentLayers() {
        return this.gpService.generationProfile.accompanimentLayers
    }

    unselectPack() {
        this.menuOptions = undefined
    }

    async selectPack(event) {
        event.stopPropagation()

        const options = []

        if (!this.layer.name.includes("Ornament")) {
            options.push({
                icon: "assets/img/menu/link.svg",
                text: "Synchronize to another pack",
                loading: false,
                data: this.pack,
                onClick: this.openSynchronisationMenu.bind(this),
            })
        }

        options.push({
            icon: "assets/img/menu/duplicate.svg",
            text: "Duplicate",
            loading: false,
            data: this.pack,
            onClick: this.duplicate.bind(this),
        })

        if (this.enableAccompanimentDesigner() && this.isEditablePack()) {
            options.push({
                icon: "assets/img/menu/rename.svg",
                text: "Edit",
                loading: false,
                data: this.pack,
                onClick: this.editPack.bind(this),
            })
        }

        options.push({
            icon: "assets/img/menu/replace.svg",
            text: "Change type",
            loading: false,
            data: this.pack,
            onClick: this.changePackType.bind(this),
        })

        options.push({
            icon: "assets/img/add.svg",
            text: "Add instrument",
            loading: false,
            data: this.pack,
            onClick: (() => {
                return this.editInstruments(1)
            }).bind(this),
        })

        options.push({
            icon: "assets/img/menu/rename.svg",
            text: "Edit instruments",
            loading: false,
            data: this.pack,
            onClick: (() => {
                return this.editInstruments(0)
            }).bind(this),
        })

        options.push({
            icon: "assets/img/menu/clear.svg",
            text: "Clear all instruments",
            loading: false,
            data: this.pack,
            onClick: this.clearInstruments.bind(this),
        })

        if (this.pack.octaves != null && this.pack.lowestNote != null) {
            options.push({
                icon: "assets/img/menu/piano.svg",
                text: "Edit pitch range",
                loading: false,
                data: this.pack,
                onClick: this.editPitchRange.bind(this),
            })
        }

        options.push({
            icon: "assets/img/menu/delete.svg",
            text: "Delete",
            loading: false,
            data: this.pack,
            buttonClass: "delete",
            onClick: this.deleteSelectedPack.bind(this),
        })

        this.menuOptions = {
            coordinates: {
                x: event.x,
                y: event.y,
            },
            options,
        }

        this.detectChanges()
    }

    async selectTemplatePack(event: MouseEvent) {
        console.log("selectTemplatePack", event)
        event.stopPropagation()
        event.preventDefault()

        if (!this.enableAccompanimentDesigner()) {
            return
        }

        const options = []

        options.push({
            icon: "assets/img/menu/rename.svg",
            text: "Edit",
            loading: false,
            data: this.pack,
            onClick: this.editPack.bind(this),
        })

        options.push({
            icon: "assets/img/menu/delete.svg",
            text: "Delete",
            loading: false,
            data: this.pack,
            buttonClass: "delete",
            onClick: this.deleteSelectedTemplatePack.bind(this),
        })

        this.menuOptions = {
            coordinates: {
                x: event.x,
                y: event.y,
            },
            options,
        }

        this.detectChanges()
    }

    async deleteSelectedTemplatePack(selectedPack) {
        let p = 0

        for (let pack of this.layer.packs) {
            if (selectedPack.packID == pack.packID) {
                this.layer.packs.splice(p, 1)

                break
            }

            p += 1
        }

        console.log("deleteSelectedTemplatePack", selectedPack)

        this.gpService.pausePreviewForLayer(
            this.layer.name,
            selectedPack.packID,
            null
        )
        this.gpService.removeSynchronisationForPacks([selectedPack])
        this.gpService.setAsUpdated("deletePack")

        await this.gpService.http.deleteTemplatePack(selectedPack.packID)

        this.unselectPack()
    }

    detectChanges() {
        if (!this.ref["destroyed"]) {
            this.ref.detectChanges()

            setTimeout(() => {
                this.gpService.drawPacksPreview(
                    this.pack,
                    this.layer.name,
                    this.type,
                    this.index
                )
            }, 1)
        }
    }

    getTop3Instruments(instruments: Array<InstrumentPatch>) {
        return instruments.slice(0, 3)
    }

    editInstruments(step) {
        const newData: GPInstrumentSelectionModalParameters = {
            pack: this.gpService.getPackByIndex(
                this.pack["idx"],
                this.layer.name
            ),
            layer: this.gpService.getLayer(this.layer.name),
        }

        this.modalService.modals.gpInstrumentSelectionForPack.next(newData)

        this.unselectPack()
    }

    preview(instrumentPatch: InstrumentPatch) {
        if (this.showLoading() || this.showRetry()) {
            return this.apiService.handleError(
                "Cannot preview while a pack is not fully loaded."
            )
        }

        this.gpService.preview(
            this.layer.name,
            this.pack.packID,
            instrumentPatch.patchID
        )
    }

    isPreviewLoading(instrument) {
        return this.gpService.isPreviewLoading(instrument, this.layer)
    }

    editPitchRange() {
        this.modalService.modals.pitchRangeControl.next({
            pack: this.pack,
            layer: this.layer,
        })

        this.unselectPack()
    }

    enableAccompanimentDesigner() {
        return (this.pack as AccompanimentPack).type !== "Percussion"
    }

    isEditablePack() {
        for (let layerName of ["Chords", "Extra", "Bass"]) {
            if (this.layer.name.includes(layerName)) {
                return true
            }
        }

        return false
    }
}
