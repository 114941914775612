<div
    class="context-menu instrument-palette-more-options"
    (clickOutside)="closeContextMenu()"
    [clickOutsideEvents]="'click,contextmenu'">
    <div class="context-menu-background"></div>

    <div
        class="right-click-menu-item download-button animate-0_1s"
        (click)="addInstrumentToPack()">
        <img src="assets/img/add.svg" alt="Add instruments" /> Add instruments
    </div>

    <div
        class="right-click-menu-item download-button animate-0_1s"
        (click)="addDoublesToPack()">
        <img src="assets/img/add.svg" alt="Add doubling instruments" /> Add
        doubling instruments
    </div>

    <div
        class="right-click-menu-item download-button animate-0_1s"
        (click)="findSimilarInstrumentPatches()"
        *ngIf="showFindSimilarInstruments()">
        <img src="assets/img/search.svg" alt="Find similar instruments" /> Find
        similar instruments
    </div>
</div>
