import { InstrumentJSON, InstrumentsJSON } from "../interfaces/score/general"
import SearchItem from "../classes/searchitem"
import InstrumentPatch from "../classes/score/instrumentpatch"
import Patch from "../classes/score/patch"
import { cloneDeep } from "lodash"

export module InstrumentManipulation {
    export function getInstrumentsOrganizedByPaths(instruments:InstrumentsJSON) {
        if (instruments === undefined) {
            console.error("getInstrumentsOrganizedByPaths: instruments is undefined")

            return {
                instrumentsOrganizedByPath: [],
                percussionOrganizedByPath: [], 
                numberOfPatches: 0
            }
        }

        const copiedInstruments = cloneDeep(instruments)

        const percussion:InstrumentJSON[] = copiedInstruments["p"]

        delete copiedInstruments["p"]

        const instrumentsOrganizedByPath = computeInstrumentPaths(copiedInstruments)
        const percussionOrganizedByPath = computeInstrumentPaths({
            "p": percussion,
        })

        const numberOfPatches =
            getNumberOfPatches(instrumentsOrganizedByPath) +
            getNumberOfPatches(percussionOrganizedByPath)

        return {
            instrumentsOrganizedByPath,
            percussionOrganizedByPath,
            numberOfPatches
        }
    }

    function getNumberOfPatches(instruments) {
        var result: any = 0

        for (var i = 0; i < instruments.length; i++) {
            if (instruments[i].values != null) {
                result += getNumberOfPatches(instruments[i].values)
            }

            result += 1
        }

        return result
    }

    export function computeInstrumentPaths(instruments:InstrumentsJSON) {
        var instrumentsOrganizedByPath = []

        for (var section in instruments) {
            for (var i = 0; i < instruments[section].length; i++) {
                const instr = instruments[section][i]

                if (
                    instr.name == "silent-kit" ||
                    instr.name == "silent-instrument"
                ) {
                    continue
                }

                for (var p = 0; p < instr.patches.length; p++) {
                    if (
                        (section == "v" &&
                            instr.patches[p].path?.includes("Dry Mix")) ||
                        instr.patches[p].path?.split("/") == null
                    ) {
                        continue
                    }

                    const path = instr.patches[p].path.split("/")

                    var lastObject: Array<SearchItem> =
                        instrumentsOrganizedByPath
                    var completeObject: SearchItem

                    var pathSoFar = ""

                    for (var j = 0; j < path.length; j++) {
                        if (pathSoFar == "") {
                            pathSoFar = path[j]
                        } else {
                            pathSoFar = pathSoFar + "/" + path[j]
                        }

                        var found = false

                        for (var k = 0; k < lastObject.length; k++) {
                            if (lastObject[k].name == path[j]) {
                                found = true
                                lastObject = lastObject[k].values
                                completeObject = lastObject[k]

                                break
                            }
                        }

                        if (!found) {
                            var newObject: SearchItem = new SearchItem(
                                path[j],
                                [],
                                path[j],
                                pathSoFar,
                                "folder",
                                {},
                                true
                            )

                            lastObject.push(newObject)
                            lastObject = newObject.values
                            completeObject = newObject
                        }

                        lastObject = lastObject.sort((a, b) => {
                            if (a.name < b.name) {
                                return -1
                            } else if (a.name > b.name) {
                                return 1
                            }

                            return 0
                        })
                    }

                    let instrumentType =
                        section == "p" ? "percussion" : "pitched"
                    let instrumentPatchName =
                        instr.full_name + " | " + instr.patches[p].name
                    let percussionFunction

                    if (instrumentType == "percussion") {
                        percussionFunction =
                            instr.patches[p].percussion_function
                        instrumentPatchName = instr.full_name
                    }

                    let instrumentPatch = new InstrumentPatch(
                        section +
                            "." +
                            instr.name +
                            "." +
                            instr.patches[p].playing_style +
                            "." +
                            instr.patches[p].articulation,
                        instrumentPatchName,
                        instrumentType,
                        [
                            {
                                patch: new Patch(
                                    section,
                                    instr.name,
                                    instr.patches[p].playing_style,
                                    instr.patches[p].articulation,
                                    instr.patches[p].granulationEngine
                                ),
                                octave: 0,
                            },
                        ]
                    )

                    if (instr.patches[p].name == null) {
                        continue
                    }

                    if (path.length > 0 && section != "p") {
                        let searchItem = new SearchItem(
                            instr.patches[p].name,
                            null,
                            instr.patches[p].name,
                            instr.patches[p].path,
                            "patch",
                            instrumentPatch,
                            true,
                            false,
                            [instr.lowest_note, instr.highest_note]
                        )
                        lastObject.push(searchItem)

                        lastObject = lastObject.sort(
                            (a: SearchItem, b: SearchItem) => {
                                return a.name.localeCompare(b.name)
                            }
                        )
                    }

                    if (path.length > 0 && section == "p") {
                        completeObject.values = null
                        completeObject.name = instr.full_name
                        completeObject.path = instr.patches[p].path
                        completeObject.type = "patch"
                        completeObject.item = instrumentPatch
                        completeObject.createdByAiva = true
                        completeObject.percussionFunction = percussionFunction
                    }
                }
            }
        }

        instrumentsOrganizedByPath = orderInstrumentsAlphabetically(
            instrumentsOrganizedByPath
        )

        return instrumentsOrganizedByPath
    }

    function orderInstrumentsAlphabetically(instruments) {
        instruments = instruments.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            } else if (a.name > b.name) {
                return 1
            }

            return 0
        })

        for (var i = 0; i < instruments.length; i++) {
            if (instruments[i].values != null) {
                instruments[i].values = orderInstrumentsAlphabetically(
                    instruments[i].values
                )
            }
        }

        return instruments
    }
}