import {
    ChangeDetectionStrategy,
    Component,
    Output,
    EventEmitter,
    Input,
    HostListener,
} from "@angular/core"

@Component({
    selector: "magic-button",
    templateUrl: "magic-button.component.html",
    styleUrls: ["magic-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MagicButtonComponent {
    @Input() title: string
    @Input() fontSize: string = "100%"
    @Input() element: HTMLElement | undefined
    @Input() avData: string = "magic-button"
    @Output() public onClick: EventEmitter<MouseEvent> =
        new EventEmitter<MouseEvent>()
    @Output() public onScroll$ = new EventEmitter<WheelEvent>()

    constructor() {}

    ngOnInit() {
        if (this.element === undefined) {
            this.element = document.getElementById("magic-container")
        }
    }

    public async handleClick(event: MouseEvent) {
        this.pop(event)
        this.onClick.emit(event)
    }

    @HostListener("mousewheel", ["$event"])
    public onScroll($event: WheelEvent): void {
        this.onScroll$.emit($event)
    }

    private async pop(e: MouseEvent) {
        for (let i = 0; i < 30; i++) {
            // We call the function createParticle 30 times
            // As we need the coordinates of the mouse, we pass them as arguments
            this.createParticle(e.clientX, e.clientY)
        }
    }

    private async createParticle(x, y) {
        const particle = document.createElement("div")
        particle.className = "particle"

        this.element.appendChild(particle)

        // Calculate a random size from 5px to 25px
        const size = Math.floor(Math.random() * 20 + 5)
        particle.style.width = `${size}px`
        particle.style.height = `${size}px`
        // Generate a random color in a blue/purple palette
        particle.style.background = `hsl(${Math.random() * 90 + 180}, 70%, 60%)`
        particle.style.position = "fixed"
        particle.style.borderRadius = "50%"
        particle.style.top = y + "px"
        particle.style.left = x + "px"
        particle.style.zIndex = "-1"

        // Generate a random x & y destination within a distance of 115px from the mouse
        const destinationX = Math.floor((Math.random() - 0.5) * 2 * 115)
        const destinationY = Math.floor((Math.random() - 0.5) * 2 * 115)

        // Store the animation in a variable as we will need it later
        const animation = particle.animate(
            [
                {
                    // Set the origin position of the particle
                    // We offset the particle with half its size to center it around the mouse
                    transform: `translate(-50%, -50%) translate(${Math.floor(
                        0
                    )}px, ${Math.floor(0)}px)`,
                    opacity: 1,
                },
                {
                    // We define the final coordinates as the second keyframe
                    transform: `translate(${destinationX}px, ${destinationY}px)`,
                    opacity: 0,
                },
            ],
            {
                // Set a random duration from 500 to 1500ms
                duration: Math.random() * 3000 + 500,
                easing: "cubic-bezier(0, .9, .57, 1)",
                // Delay every particle with a random value of 200ms
                delay: Math.random() * 200,
            }
        )

        // When the animation is complete, remove the element from the DOM
        animation.onfinish = () => {
            particle.remove()
        }
    }
}
