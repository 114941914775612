import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
    ViewChild,
} from "@angular/core"
import {
    LayerFunctionType,
    MixingOption,
    TimeSignature,
} from "@common-lib/types/score"
import { PlayerService } from "@services/audio/player/player.service"
import { DesignService } from "@services/design.service"
import { EditorLoading, EditorService } from "@services/editor/editor.service"
import { FolderService } from "@services/folder.service"
import { ParentClass } from "../../parent"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../types/dropdownItemType"
import { NOTE_RESOLUTIONS } from "../../constants"
import { CursorType } from "@common-lib/types/note-editing"
import {
    AutoMixingModal,
    ModalService,
    SimpleModal,
} from "@services/modal.service"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { environment } from "@environments/environment"
import {
    DEFAULT_PERCUSSION_INSTRUMENT,
    DEFAULT_PITCHED_INSTRUMENT,
    LAYERS,
    SUPPORTED_TIME_SIGNATURES,
    TIMESTEP_RES,
} from "@common-lib/constants/constants"
import { CompositionService } from "@services/composition.service"
import { AnalyticsService } from "@services/analytics.service"
import { ActivityMetric } from "../../../../../common-lib/general/classes/activitymetric"
import { Observable } from "rxjs"
import { Time } from "@common-lib/modules/time"
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar"
import Layer from "@common-lib/classes/score/layer"
import { SRActionTypes } from "../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { ShortcutsService } from "@services/shortcuts.service"
import { Helpers } from "../../modules/helpers.module"
import Section from "@common-lib/classes/score/section"
import {
    MenuOption,
    MenuOptions,
} from "../reusable/menu-options/menu-options.component"
import { Coordinates } from "@common-lib/modules/event-handlers"
import { SubscriptionHelpers } from "@common-lib/modules/subscription-helpers.module"
import { ActivatedRoute, Route, Router } from "@angular/router"
import { Misc } from "@common-lib/modules/misc"
import { InfluenceService } from "@services/influence.service"
import { featureFlags } from "@common-lib/utils/feature-flags"
import Score from "@common-lib/classes/score/score"
import {
    playerActions,
    playerQuery,
} from "../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { WindowService } from "@services/window.service"
import { AudioService } from "@services/audio/audio.service"
import { SectionResizeType } from "@common-lib/interfaces/music-engine/general"
import { Trust } from "@common-lib/interfaces/db-schemas/trainingsets/scoresMetadata"

@Component({
    selector: "editor",
    templateUrl: "editor.component.html",
    styleUrls: ["editor.component.scss"],
    providers: [EditorService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorComponent
    extends ParentClass
    implements AfterViewInit, OnInit
{
    public navigatedThroughPlayer: boolean = false
    private compositionID: string

    public sectionOptions: MenuOptions<Section> | undefined = undefined
    public showMenuTitles: boolean = true

    protected scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    protected separatorResizingStart:
        | { event: MouseEvent; startHeight: number }
        | undefined

    public resizeFactor: number = 1

    public loadingRetryRendering: boolean = false

    protected hasToggledAutomation = false

    private isDiscardingChanges = false

    @ViewChild(PerfectScrollbarDirective, { static: false })
    scrollbar: PerfectScrollbarDirective
    instrumentsLoadingPercentage$: Observable<number>

    public get buttonClass() {
        const prefix = "editor-button editor-button-large "
        return (
            prefix +
            (this.shouldShowMenuTitles()
                ? ""
                : "editor-button-large-without-text")
        )
    }

    public getHarmonyLock() {
        return this.editor.engine.queries["scoreRendering"].harmonyLock
    }

    public toggleHarmonyLock(harmonyLock: boolean) {
        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.setHarmonyLock,
            data: {
                harmonyLock,
            },
        })
        /**
         * @todo: this is a duct taped solution to force the grid to re-render,
         * we need a better way to handle this
         * */
        this.editor.engine.resizeCanvases()
    }

    public get featureFlags() {
        return featureFlags
    }

    public get pencilMenuTitle() {
        if (this.shouldShowMenuTitles()) {
            return this.editor.engine.cursorType === "pencil"
                ? "Pencil mode"
                : "Select mode"
        }

        return ""
    }

    public get newLayerMenuTitle() {
        if (this.shouldShowMenuTitles()) {
            return "New Layer"
        }

        return ""
    }

    public get mixingMenuTitle() {
        if (this.shouldShowMenuTitles()) {
            return "Mixing"
        }

        return ""
    }

    public get effectsMenuTitle() {
        if (this.shouldShowMenuTitles()) {
            return "Effects"
        }

        return ""
    }

    public get shortcutsMenuTitle() {
        if (this.shouldShowMenuTitles()) {
            return "Shortcuts"
        }

        return ""
    }

    public get playheadMenuTitle() {
        if (this.shouldShowMenuTitles()) {
            return this.editor.engine.queries.editorView.followTimelineCursor
                ? "Unfollow playhead"
                : "Follow playhead"
        }

        return ""
    }

    public get tutorialMenuTitle() {
        if (this.shouldShowMenuTitles()) {
            return "Tutorial"
        }

        return ""
    }

    public get isTrainingsetComposition() {
        return (
            featureFlags?.dataEditingTools &&
            !environment?.production &&
            this.contentType === "training"
        )
    }

    public get contentType() {
        return (
            this.route?.snapshot?.paramMap?.get("contentType") || "composition"
        )
    }

    constructor(
        public editor: EditorService,
        private player: PlayerService,
        private folder: FolderService,
        private design: DesignService,
        private instruments: InstrumentsService,
        private compositionService: CompositionService,
        private modalService: ModalService,
        private analytics: AnalyticsService,
        private ref: ChangeDetectorRef,
        private shortcuts: ShortcutsService,
        private router: Router,
        private route: ActivatedRoute,
        private influenceService: InfluenceService,
        private windows: WindowService,
        public audio: AudioService
    ) {
        super()

        this.influenceService.createWithInfluenceMode = false

        playerActions.setPlaybackType("EditorComponent.ngOnInit()", "offline")

        this.instrumentsLoadingPercentage$ = playerQuery.select(
            state => state.trackBusLoadingPercentage
        )

        this.router.routeReuseStrategy.shouldReuseRoute = () => false
        this.router.onSameUrlNavigation = "reload"

        this.compositionID = this.route.snapshot.paramMap.get("compositionID")

        this.player.setOfflinePlayback("editor.component.ts")
    }

    async ngOnInit() {
        this.showMenuTitles = this.shouldShowMenuTitles()

        this.subscribe(
            playerQuery.select("trackBusLoadingPercentage"),
            (value: number) => {
                if (value > 50) {
                    this.modalService.modals.instrumentDownloading.next(false)
                }
            }
        )

        this.subscribe(
            this.player.navigate$,
            (compositionID: string | undefined) => {
                if (
                    compositionID !== undefined &&
                    compositionID !== this.compositionID
                ) {
                    this.navigatedThroughPlayer = true
                    this.discardChanges(compositionID)

                    this.player.navigate$.next(undefined)
                }
            }
        )

        this.subscribe(
            this.editor.loading$,
            this.editorServiceHasLoaded.bind(this)
        )

        const composition = await this.folder.getContentByID(
            this.compositionID,
            this.contentType
        )

        this.editor.loadComposition(
            composition,
            "editor.component player subscription"
        )

        if (this.isTrainingsetComposition) {
            playerActions.setPlaybackType(
                "EditorComponent.ngOnInit()",
                "realtime"
            )
        }

        await this.setEngine()
    }

    public openAudioSettings() {
        this.modalService.modals.settings.next({
            type: "audio",
        })
    }

    public showAudioOutput() {
        return this.windows.desktopAppAPI !== undefined
    }

    private shouldShowMenuTitles(): boolean {
        return window.innerWidth > 1000
    }

    async setEngine() {
        while (this.editor.engine === undefined) {
            await Misc.wait(0.1)
        }

        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.setEditorType,
            data: {
                editorType: "editor",
            },
            options: {
                isUndoable: false,
            },
        })

        this.subscribe(this.editor.engine.scoreWasEdited$, (value: boolean) => {
            if (value || this.isTrainingsetComposition) {
                this.player.setRealtimePlayback(this.editor.engine)
            }
        })

        this.subscribe(
            this.editor.engine.selectedSection$,
            this.setSelectedSection.bind(this)
        )

        this.subscribe(
            this.editor.engine.toggledLayer$,
            (layer: Layer | undefined) => {
                this.scrollbar?.update()
            }
        )

        SubscriptionHelpers.subscribeWithPrevious(
            this.editor.engine.seekTime$,
            (args: { previous: number; current: number }) => {
                if (
                    args.previous === undefined ||
                    args.current === undefined ||
                    isNaN(args.current)
                ) {
                    return
                }

                const seconds = Time.convertTimestepsInSeconds(
                    TIMESTEP_RES,
                    this.editor.engine.score.tempoMap,
                    args.current,
                    this.player.hasStartOffset()
                )

                this.player.setTimeFromSeconds(seconds)
            },
            this.destroy$
        )

        this.subscribe(
            this.editor.engine.srIllegalAction$,
            this.blinkUI.bind(this)
        )

        const timelineCursorElement = (await Helpers.waitForElementByID(
            "play-head-position"
        )) as HTMLDivElement

        this.editor.engine.actions.editorView.setTimelineCursorElement(
            timelineCursorElement
        )

        this.checkIfScoreHasInsertedSections(this.editor.engine.score)
    }

    private checkIfScoreHasInsertedSections(score: Score) {
        let hasRemovedSection = false

        for (const section of score.sections) {
            if (section.title.includes("Insert")) {
                hasRemovedSection = true

                this.editor.engine.srEmitter$.next({
                    type: SRActionTypes.removeSection,
                    data: {
                        section,
                    },
                    options: {
                        isUndoable: true,
                    },
                })
            }
        }

        if (hasRemovedSection) {
            this.modalService.modals.scoreCorrupted.next({
                saveChanges: (() => {
                    return this.saveChanges("none")
                }).bind(this),
            })
        }
    }

    async ngAfterViewInit() {
        this.subscribe(this.editor.scrollYBy, value => {
            if (this.scrollbar) {
                // + 115 px is to compensate for top parts of the piano roll component
                this.scrollbar.scrollToY(
                    this.scrollbar.geometry().y +
                        value -
                        window.innerHeight +
                        115
                )
            }
        })

        this.design.setSidebarCollapsed(true)

        this.subscribe(this.editor.engine$, val => {
            if (!val) return
            this.subscribe(
                this.editor.engine.queries.scoreRendering
                    .regenerateLayerWithLLM$,
                this.regenerateLayerWithLLM.bind(this)
            )
        })
    }

    public goToTutorial() {
        window.open("https://www.youtube.com/watch?v=5_eHtnGl6xs", "_blank")
    }

    private async setSelectedSection({
        section,
        coordinates,
    }: {
        coordinates: Coordinates
        section: Section | undefined
    }) {
        if (section === undefined) {
            this.sectionOptions = undefined
            this.ref.detectChanges()
            return
        }

        const lastSection =
            this.editor?.engine?.score?.sections[
                this.editor.engine.score.sections.length - 1
            ]

        if (featureFlags.sectionModificationForCWCompositions) {
            let options: MenuOption<any>[] = []

            if (featureFlags.harmonyAndKeyDetection) {
                options.push({
                    data: section,
                    icon: "assets/img/achievements/magic.svg",
                    text: "Chord + Key analysis",
                    loading: false,
                    onClick: (async section => {
                        await this.editor.harmonicAnalysis(section)

                        this.closeSectionOptions()
                    }).bind(this),
                })
            }

            // Prevent the user from inpainting a new section
            // before the first and after the last section.
            // Only do that in prod, so Howard can use it in staging.
            const isStaging = !environment?.production

            if (section.index !== 0 || isStaging) {
                options.push(this.getSectionInsertionBeforeOption(section))
            }

            if (section.index !== lastSection.index || isStaging) {
                options.push(this.getSectionInsertionAfterOption(section))
            }

            // We only allow this option to be used on the toggled layer for now
            if (
                featureFlags.generateLayerWithLLMInEditor &&
                this.editor.engine.toggledLayer?.value !== undefined
            ) {
                const regenerateSectionNotesOption =
                    this.getRegenerateSectionWithLLMOption(section)
                options.push(regenerateSectionNotesOption)
            }

            if (featureFlags.enableSectionModificationInEditor) {
                options = options.concat([
                    {
                        data: section,
                        icon: "assets/img/menu/duplicate.svg",
                        text: `Copy chords to this section`,
                        loading: false,
                        onClick: this.openCopyChordsModal.bind(this, section),
                    },
                    {
                        data: section,
                        icon: "assets/img/menu/add_left.svg",
                        text: "Resize " + section.title,
                        loading: false,
                        onClick: this.openResizeSectionModal.bind(
                            this,
                            section,
                            SectionResizeType.LEFT
                        ),
                    },
                    {
                        data: section,
                        icon: "assets/img/menu/add_right.svg",
                        text: "Resize " + section.title,
                        loading: false,
                        onClick: this.openResizeSectionModal.bind(
                            this,
                            section,
                            SectionResizeType.RIGHT
                        ),
                    },
                    {
                        data: section,
                        icon: "assets/img/menu/duplicate.svg",
                        text: "Split " + section.title,
                        loading: false,
                        onClick: this.splitSection.bind(this, section),
                    },
                    {
                        data: section,
                        icon: "assets/img/menu/rename.svg",
                        text: "Rename " + section.title,
                        loading: false,
                        onClick: (() => {
                            this.modalService.modals.rename.next({
                                name: section.title,
                                onComplete: (newName: string) => {
                                    this.editor.engine.srEmitter$.next({
                                        type: SRActionTypes.changeSectionName,
                                        data: {
                                            name: newName,
                                            section,
                                        },
                                    })
                                },
                            })
                        }).bind(this),
                    },
                ])
            }

            if (this.editor.engine.score?.sections?.length > 1) {
                options.push({
                    data: section,
                    icon: "assets/img/menu/delete.svg",
                    text: "Delete " + section.title,
                    buttonClass: "delete",
                    loading: false,
                    onClick: this.removeInsertedSection.bind(this),
                })
            }

            if (options.length === 0) {
                this.sectionOptions = undefined
                this.ref.detectChanges()
                return
            }

            this.sectionOptions = {
                options,
                coordinates,
            } as MenuOptions<Section>

            this.ref.detectChanges()

            return
        }

        if (
            this.editor.query.getValue().isCompatibleWithRegeneration === false
        ) {
            const modal: SimpleModal = {
                title: "This feature is not available for this composition",
                message:
                    "Section modification is disabled for compositions generated before September 2019 and for compositions created with a composition workflow.",
                buttons: [],
            }
            this.modalService.modals.simpleModal.next(modal)
            return
        }

        if (!featureFlags.sectionInpainting) {
            const options = section.inserted
                ? this.getSectionOptionsForInsertedSection(section)
                : this.getSectionOptionsForExistingSection(section)

            this.sectionOptions = {
                options,
                coordinates,
            } as MenuOptions<Section>
        }

        this.ref.detectChanges()
    }

    public toggleFollowTimelineCursor() {
        if (this.editor.engine === undefined) {
            this.editor.engine.actions.editorView.toggleFollowTimelineCursor()
        }
    }

    public async discardChanges(compositionID?: string) {
        if (this.isDiscardingChanges) {
            return
        }

        // This variable is here to ensure that there are no duplicate navigation sent
        // (as the navigation will trigger an observable from playerQuery.content)
        this.isDiscardingChanges = true

        // here we clean up the canvases since in the next step we will load a new composition
        this.editor?.engine?.deleteAllCanvases(false)

        await this.player.pause()

        return this.router.navigate([
            "editor",
            compositionID ? compositionID : this.compositionID,
            this.contentType,
        ])
    }

    private getSectionOptionsForInsertedSection(section: Section) {
        const options: MenuOption<any>[] = [
            this.getSectionInsertionBeforeOption(section),
        ]

        const lastSection =
            this.editor.engine.score.sections[
                this.editor.engine.score.sections.length - 1
            ]

        if (section.index !== lastSection.index) {
            options.push(this.getSectionInsertionAfterOption(section))
        }

        options.push({
            data: section,
            icon: "assets/img/menu/delete.svg",
            text: "Delete " + section.title,
            buttonClass: "delete",
            loading: false,
            onClick: this.removeInsertedSection.bind(this),
        })

        return options
    }

    private getSectionOptionsForExistingSection(section: Section) {
        const options: MenuOption<any>[] = [
            this.getSectionInsertionBeforeOption(section),
        ]

        const lastSection =
            this.editor.engine.score.sections[
                this.editor.engine.score.sections.length - 1
            ]

        if (section.index !== lastSection.index) {
            options.push(this.getSectionInsertionAfterOption(section))
        }

        options.push({
            data: section,
            icon: "assets/img/menu/replace.svg",
            text: "Replace " + section.title,
            loading: false,
            onClick: this.replaceSection.bind(this),
        })

        if (
            !section.title.includes("Outro") &&
            !section.title.includes("Intro") &&
            !section.title.includes("Bridge")
        ) {
            options.push({
                data: section,
                icon: "assets/img/menu/regenerate.svg",
                text: "Regenerate " + section.title,
                loading: false,
                onClick: this.regenerateSection.bind(this),
            })
        }

        options.push({
            data: section,
            icon: "assets/img/menu/clear.svg",
            text: "Remove notes for " + section.title,
            loading: false,
            onClick: this.removeNotesForSection.bind(this),
        })

        options.push({
            data: section,
            icon: "assets/img/menu/delete.svg",
            text: "Delete " + section.title,
            buttonClass: "delete",
            loading: false,
            onClick: this.deleteSection.bind(this),
        })

        if (section.operation !== undefined) {
            options.push({
                data: section,
                icon: "assets/img/close.svg",
                text: "Cancel operation",
                loading: false,
                onClick: this.cancelOperation.bind(this),
            })
        }

        return options
    }

    private cancelOperation(section: Section) {
        this.unselectSection()

        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.cancelSectionOperation,
            data: {
                section,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    private getSectionInsertionAfterOption(section: Section) {
        return {
            data: section,
            icon: "assets/img/menu/add_right.svg",
            text: "Insert section after " + section.title,
            loading: false,
            onClick: (() => {
                this.unselectSection()

                // This makes sure, that we set the playback type to realtime ahead of time,
                // when the user is adding sections to a composition. This fixes an issue where
                // the duration was not updated when the first edit a user did was to add a section.
                this.player.setRealtimePlayback(this.editor.engine)

                if (featureFlags.sectionInpainting) {
                    this.modalService.modals.sectionInpainting.next({
                        section: section,
                        engine: this.editor.engine,
                        onComplete: (() => {
                            this.modalService.modals.sectionInpainting.next(
                                undefined
                            )
                        }).bind(this),
                        inpaintSection:
                            this.editor.editorHttp.inpaintSection.bind(
                                this.editor.editorHttp
                            ),
                        getSourceSectionChords:
                            this.editor.editorHttp.getSourceSectionChords.bind(
                                this.editor.editorHttp
                            ),
                        position: "after",
                    })
                } else {
                    this.modalService.modals.sectionEditing.next({
                        section: section,
                        engine: this.editor.engine,
                        type: "insert_new",
                        position: "after",
                        onComplete: (() => {
                            this.modalService.modals.sectionEditing.next(
                                undefined
                            )
                        }).bind(this),
                    })
                }
            }).bind(this),
        }
    }

    private unselectSection() {
        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.selectSection,
            data: {
                section: undefined,
            },
        })
    }

    private getSectionInsertionBeforeOption(section: Section) {
        return {
            data: section,
            icon: "assets/img/menu/add_left.svg",
            text: "Insert section before " + section.title,
            loading: false,
            onClick: (() => {
                this.unselectSection()

                // This makes sure, that we set the playback type to realtime ahead of time,
                // when the user is adding sections to a composition. This fixes an issue where
                // the duration was not updated when the first edit a user did was to add a section.
                this.player.setRealtimePlayback(this.editor.engine)

                if (featureFlags.sectionInpainting) {
                    this.modalService.modals.sectionInpainting.next({
                        section: section,
                        engine: this.editor.engine,
                        onComplete: (() => {
                            this.modalService.modals.sectionInpainting.next(
                                undefined
                            )
                        }).bind(this),
                        inpaintSection:
                            this.editor.editorHttp.inpaintSection.bind(
                                this.editor.editorHttp
                            ),
                        getSourceSectionChords:
                            this.editor.editorHttp.getSourceSectionChords.bind(
                                this.editor.editorHttp
                            ),
                        position: "before",
                    })
                } else {
                    this.modalService.modals.sectionEditing.next({
                        section: section,
                        engine: this.editor.engine,
                        type: "insert_new",
                        position: "before",
                        onComplete: (() => {
                            this.modalService.modals.sectionEditing.next(
                                undefined
                            )
                        }).bind(this),
                    })
                }
            }).bind(this),
        }
    }

    private regenerateLayerWithLLM(section: Section) {
        // Skip the default ping from the behavior subject
        if (!section) return

        // Check if there is only one layer and no chords, which means there isn't enough data to do regeneration
        if (
            Object.keys(this.editor.engine.score.layers).length < 2 &&
            this.editor.engine.score.chords?.length === 0
        ) {
            this.modalService.modals.layerInpaintingNotAllowedWarning$.next(
                true
            )
            return
        }
        if (featureFlags.generateLayerWithLLMInEditor) {
            this.modalService.modals.layerGeneration.next({
                section: section,
                layerName: this.editor.engine.toggledLayer?.value,
                engine: this.editor.engine,
                onComplete: (() => {
                    this.modalService.modals.layerGeneration.next(undefined)
                }).bind(this),
                generateLayer: this.editor.editorHttp.generateLayer.bind(
                    this.editor.editorHttp
                ),
            })
        }
    }

    private getRegenerateSectionWithLLMOption(section: Section) {
        return {
            data: section,
            icon: "assets/img/achievements/magic.svg",
            text: "Re-generate notes in section " + section.title,
            loading: false,
            onClick: (() => {
                this.unselectSection()

                if (featureFlags.generateLayerWithLLMInEditor) {
                    this.modalService.modals.layerGeneration.next({
                        section: section,
                        layerName: this.editor.engine.toggledLayer?.value,
                        engine: this.editor.engine,
                        onComplete: (() => {
                            this.modalService.modals.layerGeneration.next(
                                undefined
                            )
                        }).bind(this),
                        generateLayer:
                            this.editor.editorHttp.generateLayer.bind(
                                this.editor.editorHttp
                            ),
                    })
                }
            }).bind(this),
        }
    }

    private replaceSection(section: Section) {
        this.unselectSection()

        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.replaceSection,
            data: {
                section,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    private deleteSection(section: Section) {
        this.unselectSection()

        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.deleteSection,
            data: {
                section,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    private regenerateSectionBasedOnAnotherSection(
        section: Section,
        basedOn: Section
    ) {
        this.unselectSection()

        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.regenerateSection,
            data: {
                section,
                basedOn,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    private regenerateSection(section: Section) {
        this.unselectSection()

        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.regenerateSection,
            data: {
                section,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    private removeInsertedSection(section: Section) {
        this.unselectSection()

        // This makes sure, that we set the playback type to realtime ahead of time,
        // when the user is adding sections to a composition. This fixes an issue where
        // the duration was not updated when the first edit a user did was to add a section.
        this.player.setRealtimePlayback(this.editor.engine)

        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.removeSection,
            data: {
                section,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    private removeNotesForSection(section: Section) {
        this.unselectSection()
        this.editor.engine.removeNotesForSection(section)
    }

    private getAllSectionsExceptSelected(selectedSection: Section) {
        const sections = []

        for (const section of this.editor.engine.score.sections) {
            if (
                section === selectedSection ||
                section.title.includes("Intro") ||
                section.title.includes("Outro") ||
                section.title.includes("Bridge") ||
                section.inserted
            ) {
                continue
            }

            sections.push({
                ...section,
            })
        }

        return sections
    }

    public openMixingOptions() {
        this.modalService.modals.mixingOptions.next({
            buttonTitle: "Save changes",
            onComplete: this.saveChanges.bind(this),
        })
    }

    public openTrainingsetLabelsForm() {
        this.modalService.modals.trainingsetLabels.next({
            buttonTitle: "Save changes",
            trainingsetID: this.editor.engine.score.compositionID,
            onComplete: this.saveTrainingsetScore.bind(this),
        })
    }

    public async saveTrainingsetScore(labels: Trust) {
        try {
            // First save the changes to the score
            await this.saveChanges("none")

            // Then save the trainingset labels
            await this.editor.editorHttp.saveTrainingsetLabels(
                this.editor?.engine?.score?.compositionID,
                labels
            )

            this.modalService.modals.trainingsetLabels.next(undefined)

            this.discardChanges()

            return "Done!"
        } catch (error) {
            console.error(error)
            return error
        }
    }

    public onSave() {
        if (this.isTrainingsetComposition) {
            return this.openTrainingsetLabelsForm()
        }

        return this.openMixingOptions()
    }

    private async saveChanges(option: MixingOption) {
        const result = await this.editor.saveScoreChanges(option)

        if (!result.success) {
            return result.message
        }

        await this.player.pause()

        // Trainingset scores are not saved to the database or rendered,
        // hence we don't need to set the the composition as loading.
        if (this.isTrainingsetComposition) {
            return
        }

        const comp = this.compositionService.setCompositionAsLoading(
            result.compositionID,
            {
                mixingOption: option,
            }
        )

        if (comp === undefined) {
            return "Could find this composition on your account. Try restarting the app."
        }

        this.editor.loadComposition(comp, "editor.component save changes")

        this.addAnalyticsActivity(ActivityMetric.SAVE_AND_RENDER, {
            autoMix: option,
            compositionID: result.compositionID,
        })

        this.modalService.modals.mixingOptions.next(undefined)

        this.discardChanges()

        return "Done!"
    }

    public closeSectionOptions() {
        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.selectSection,
            data: {
                section: undefined,
                coordinates: undefined,
            },
        })
    }

    public getNoteResolutionOptions(): DropdownItemType<string>[] {
        return NOTE_RESOLUTIONS
    }

    public getNoteResolution(): DropdownItemType<string> {
        return {
            name: this.editor.engine.timestepRes + "th note",
            value: "1/" + this.editor.engine.timestepRes,
        }
    }

    public setCursorType() {
        const type: CursorType = this.editor.engine.cursorType

        if (type === "pencil") {
            this.editor.engine.cursorType = "select"
        } else {
            this.editor.engine.cursorType = "pencil"
        }
    }

    public openAutoMixing() {
        const layers = Object.keys(this.editor.engine.score.layers).map(
            (layerKey: string) => {
                return this.editor.engine.score.layers[layerKey]
            }
        )

        this.modalService.modals.autoMixing.next(<AutoMixingModal>{
            type: "composition",
            layers: layers,
            autoMix: true,
            onToggleAutoMixing: () => {},
            onChangeGainBias: ((gain: number, layer: Layer) => {
                this.editor.engine.srEmitter$.next({
                    type: SRActionTypes.setLayerGainBias,
                    data: {
                        layer: layer,
                        gain: gain,
                    },
                })
            }).bind(this),
        })
    }

    public addLayer() {
        const onComplete = async (layerType: LayerFunctionType) => {
            const section = layerType === "pitched" ? "k" : "p"
            const name =
                layerType === "pitched"
                    ? DEFAULT_PITCHED_INSTRUMENT
                    : DEFAULT_PERCUSSION_INSTRUMENT
            const defaultInstrument = this.instruments.instruments[
                section
            ].find(i => i.name === name)

            this.editor.engine.addCustomLayer(layerType, defaultInstrument)

            this.modalService.modals.addCustomLayer.next(undefined)
        }

        this.modalService.modals.addCustomLayer.next({
            onComplete: onComplete.bind(this),
        })
    }

    public async retryRendering() {
        this.loadingRetryRendering = true

        const result = await this.compositionService.retry(
            this.editor.compositionID
        )

        await this.editor.loadComposition(
            result.composition,
            "EditorComponent retryRendering()"
        )

        this.loadingRetryRendering = false
    }

    public selectNoteResolution(value: {
        current: DropdownItemType<string>
        new: DropdownItemType<string>
    }) {
        const fraction = value.new.value
        const res = parseInt(fraction.split("/")[1])

        this.editor.engine.setTimestepRes(res)
    }

    async editorServiceHasLoaded(value: EditorLoading) {
        this.ref.detectChanges()
        if (
            value.status === "none" &&
            playerQuery.playbackType !== "realtime" &&
            this.player.getCompositionID() !== this.compositionID
        ) {
            if (this.player.isPlaying()) {
                return
            }

            await this.player.loadNewTrack(
                this.compositionID,
                this.contentType,
                false,
                false,
                "editorServiceHasLoaded"
            )

            return
        }
    }

    protected getSupportedTimeSignatures() {
        return SUPPORTED_TIME_SIGNATURES.map(ts => {
            return {
                value: ts,
                name: ts[0] + "/" + ts[1],
            }
        })
    }

    protected selectedTimeSignature(option: DropdownSelection<TimeSignature>) {
        this.editor.engine.setTimeSignature(option.new.value)
    }

    protected getTimeSignature() {
        const timeSignature: TimeSignature = this.editor.engine.timeSignature

        return {
            value: timeSignature,
            name: timeSignature[0] + "/" + timeSignature[1],
        }
    }

    protected isLocal() {
        return (
            environment.domain.includes("localhost") ||
            environment.domain.includes("ngrok")
        )
    }

    public openCustomizeFX() {
        this.modalService.modals.customizePianorollFX.next({
            bassBoost: this.editor.engine.score.effects.bass_boost,
            vinyl: this.editor.engine.score.effects.vinyl,

            toggleBassBoost: ((value: boolean) => {
                this.toggleEffect("bass_boost", value)
            }).bind(this),

            toggleVinyl: ((value: boolean) => {
                this.toggleEffect("vinyl", value)
            }).bind(this),
        })
    }

    private toggleEffect(type: "vinyl" | "bass_boost", value: boolean) {
        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.setScoreFX,
            data: {
                type: type,
                value,
            },
            options: {
                isUndoable: true,
            },
        })

        this.addAnalyticsActivity(ActivityMetric.APPLY_EFFECTS, {
            effectType: type,
            effectValue: value,
            compositionID: this.editor.engine.score.compositionID,
        })
    }

    public onPinch(level) {
        this.editor.engine.resizeFactor =
            this.editor.engine.resizeFactor + level
    }

    private openCopyChordsModal(section: Section) {
        this.closeSectionOptions()

        this.modalService.modals.sectionCopyChords$.next({
            section,
            sections: this.editor.engine.score.sections,
            engine: this.editor.engine,
        })
    }

    private openResizeSectionModal(section: Section, type: SectionResizeType) {
        this.closeSectionOptions()

        this.modalService.modals.sectionResize$.next({
            section,
            type,
            engine: this.editor.engine,
        })
    }

    private splitSection(section: Section) {
        this.closeSectionOptions()

        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.splitSection,
            data: {
                section,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    private addAnalyticsActivity(type: string, meta = null) {
        if (this.isTrainingsetComposition) {
            return
        }

        this.analytics.addActivity(type, meta)
    }

    async ngOnDestroy() {
        this.editor?.engine?.deleteAllCanvases(false)
        this.editor.wasDestroyed = true
        this.editor.engine?.resetStores()
        super.ngOnDestroy()
    }

    @HostListener("window:resize", ["$event"])
    onResize() {
        this.showMenuTitles = this.shouldShowMenuTitles()

        if (this.editor.engine === undefined) {
            return
        }

        this.editor.engine.resizeCanvases()
    }

    @HostListener("document:keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        if (this.editor.loading$.getValue().status !== "loaded") {
            return
        }

        return this.shortcuts.triggerShortcuts(event, this.editor.engine)
    }
}
