import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from "@angular/core"
import { ParentClass } from "../../../../../parent"
import ScoreRenderingEngine from "../../../../../../../../common-lib/client-only/score-rendering-engine/engine"
import TrackBus from "@common-lib/classes/score/trackbus"
import { ScoreUpdateType } from "../../../../../../../../common-lib/client-only/score-rendering-engine"

@Component({
    selector: "mute-and-solo",
    templateUrl: "mute-and-solo.component.html",
    styleUrls: ["mute-and-solo.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MuteAndSoloComponent extends ParentClass implements AfterViewInit {
    @Input() mute: (trackBusses: TrackBus[]) => void
    @Input() solo: (trackBusses: TrackBus[]) => void
    @Input() trackBusses: TrackBus[]
    @Input() engine: ScoreRenderingEngine
    @Input() configs?: {
        hideMute?: boolean
        hideSolo?: boolean
    }

    constructor(private ref: ChangeDetectorRef) {
        super()
    }

    async ngAfterViewInit() {
        this.engine.scoreUpdate$.subscribe((type: ScoreUpdateType[]) => {
            this.ref.detectChanges()
        })
    }
}
