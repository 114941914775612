import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    ViewChild,
} from "@angular/core"
import { ParentClass } from "../../../../parent"
import { CompositionWorkflowService } from "@services/composition-workflow/composition-workflow.service"
import { CWActionsType } from "@services/composition-workflow/cw-store/cw.actions"
import { SRActionTypes } from "../../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { MIN_GP_TEMPO } from "@common-lib/constants/constants"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import Layer from "@common-lib/classes/score/layer"
import TrackBus from "@common-lib/classes/score/trackbus"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import { MenuModifiers } from "../../../../components/reusable/menu-modifiers/menu-modifiers.component"
import { MenuOption } from "../../../../components/reusable/menu-options/menu-options.component"
import PercussionLayer from "@common-lib/classes/score/percussionlayer"
import { NOTE_RESOLUTIONS } from "../../../../constants"
import { Helpers } from "../../../../modules/helpers.module"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../../../types/dropdownItemType"
import { NoteResolution } from "@common-lib/types/score"
import { ScoreManipulation } from "@common-lib/modules/scoremanipulation"
import { ConfirmDiscardChangesService } from "@services/confirm-discard-changes.service"
import { DropdownComponent } from "../../../../components/reusable/dropdown/dropdown.component"
import { Subject, filter, takeUntil, tap } from "rxjs"
import { UserService } from "@services/user.service"
import { ApiService } from "@services/api.service"
import { TutorialService } from "@services/tutorial.service"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { Misc } from "@common-lib/modules/misc"

@Component({
    selector: "cw-step2-part2",
    templateUrl: "./cw-step2-part2.component.html",
    styleUrls: ["./cw-step2-part2.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CWStep2Part2Component extends ParentClass {
    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    public get tempo() {
        return this.service.query.step1.tempo
    }

    public get layerType(): "percussion" | "pitched" {
        return this.service.engine.toggledLayer.type
    }

    public get layer(): Layer {
        return this.service.engine.toggledLayer
    }

    public get trackBus(): TrackBus {
        return this.layer.trackBuses[0]
    }

    public get instrument(): InstrumentPatch {
        return this.service.query.step2.layers[this.layer.value].instrument
    }

    public get noteRes(): DropdownItemType<NoteResolution> {
        return Helpers.getSelectedPatternNoteRes(
            this.service.engine,
            NOTE_RESOLUTIONS
        )
    }

    public get noteResolutions(): DropdownItemType<NoteResolution>[] {
        return NOTE_RESOLUTIONS
    }

    @Input() service: CompositionWorkflowService

    @ViewChild("noteResolutionDropdown")
    noteResolutionDropdown: DropdownComponent

    trackBusSettings: MenuModifiers | undefined

    public readonly muteSoloConfigs = {
        hideMute: false,
        hideSolo: false,
    }

    constructor(
        private readonly ref: ChangeDetectorRef,
        private readonly confirmDiscard: ConfirmDiscardChangesService,
        private readonly userService: UserService,
        private readonly apiService: ApiService,
        private readonly tutorialService: TutorialService
    ) {
        super()
    }

    private subscribeToEndManipulatingNotes(): void {
        const modalId = "cwnotesmanipulation"
        this.service.engine.srEmitter$
            .pipe(
                filter(
                    action => action.type === SRActionTypes.endManipulatingNotes
                ),
                tap(async () => {
                    if (
                        this.userService.modalSettings === undefined ||
                        this.userService.modalSettings[modalId]
                    ) {
                        return
                    }
                    const title = "Warning"
                    const description =
                        "This action might remove notes from the composition and adjust to the harmony"
                    const result = await new Promise(resolve => {
                        this.confirmDiscard.openDoActionsBeforeModal({
                            title,
                            description,
                            continueButtonText: "Continue",
                            modalId,
                            action: async dontShowAgain => {
                                return { success: true }
                            },
                            cancel: () => {
                                this.service.actions.manager.undo()
                                return { success: true }
                            },
                        })
                    })
                    await result
                }),
                takeUntil(this.unsubscriber$)
            )
            .subscribe()
    }

    private unsubscriber$ = new Subject<null>()

    public get harmonyLocked(): boolean {
        return this.service.engine.queries.scoreRendering.harmonyLock
    }

    public mute(): void {
        this.service.engine.toggleTrackBussesMute(
            this.layer,
            [this.trackBus],
            "mute"
        )
    }

    public solo(): void {
        this.service.engine.toggleTrackBussesMute(
            this.layer,
            [this.trackBus],
            "solo"
        )
    }

    async ngAfterViewInit() {
        await ParentClass.waitUntilTrueForObservable(
            this.service.engine$,
            value => value !== null
        )

        await this.initializeComponent()

        this.setupListeners()
    }

    private setupListeners() {
        this.subscribe(this.service.query.select("lastUpdate"), () => {
            this.ref.detectChanges()
        })

        this.subscribeToEndManipulatingNotes()
    }

    private async initializeComponent() {
        const enableHarmonyLock =
            this.service.engine.toggledLayer.type === "pitched" &&
            !this.service.engine.toggledLayer.value.includes("Melody")

        this.service.engine.srEmitter$.next({
            type: SRActionTypes.setHarmonyLock,
            data: {
                harmonyLock: enableHarmonyLock,
            },
        })

        // this.service.engine.setSelectedPattern(
        //     (this.service.engine.toggledLayer as PercussionLayer).patterns[0]
        // )

        await this.service.initializeCanvases({
            step: "step2-part2",
        })

        this.service.engine.srEmitter$.next({
            type: SRActionTypes.setAccompanimentDesignerIsFocused,
            data: {
                value: true,
            },
        })
    }

    public getMinTempo() {
        return MIN_GP_TEMPO
    }

    public getMaxTempo() {
        return GenerationProfile.getMaxTempo(
            this.service.query.getValue().gp.settings
        )
    }

    public changeInstrument(event: MouseEvent, clickedOutside = false) {
        if (clickedOutside) {
            this.trackBusSettings = undefined
            return
        }

        this.trackBusSettings = {
            options: [],
            buttons: [
                this.getInstrumentMenuOption(),
                this.getDoublingInstrumentMenuOption(),
            ],
            title: "Select an instrument type",
            coordinates: {
                x: event.x,
                y: event.y,
            },
        }
    }

    public toggledCursorType() {
        let currentCursorType = this.service.engine.cursorType
        let newCursorType: "select" | "pencil" = "select"

        if (currentCursorType === "select") {
            newCursorType = "pencil"
        }

        this.service.engine.cursorType = newCursorType
    }

    private getInstrumentMenuOption(): MenuOption<string> {
        const indicator = this.layer?.trackBuses?.length === 1 ? " •" : ""

        return {
            data: "instrument",
            icon: undefined,
            text: "Instrument" + indicator,
            loading: false,
            onClick: ((data: string) => {
                this.service.replaceTrackBus(this.layer, "instrument")

                this.trackBusSettings = undefined
            }).bind(this),
        }
    }

    private getDoublingInstrumentMenuOption(): MenuOption<string> {
        const indicator = this.layer?.trackBuses?.length > 1 ? " •" : ""

        return {
            data: "double",
            icon: undefined,
            text: "Doubling instrument" + indicator,
            loading: false,
            onClick: ((data: string) => {
                this.service.replaceTrackBus(this.layer, "double")

                this.trackBusSettings = undefined
            }).bind(this),
        }
    }

    ngOnDestroy() {
        this.service.actions.emitter$.next({
            type: CWActionsType.clearCanvases,
            data: {
                step: "step2-part2",
            },
        })

        this.unsubscriber$.next(null)
    }

    public async selectResolution(event: DropdownSelection<string>) {
        const pattern = this.service.engine.selectedPattern
        const resolution = event.new.value
        const oldResolution = event.current.value
        if (
            ScoreManipulation.checkIfPatternResolutionWillRemoveNotes(
                pattern,
                resolution
            )
        ) {
            const result = await new Promise(resolve => {
                const title = "Warning"
                const description = "Changing the resolution will remove notes"
                this.confirmDiscard.openDoActionsBeforeModal({
                    title,
                    description,
                    continueButtonText: "Continue",
                    action: async () => {
                        ScoreManipulation.removePatternNotesWithHigherResolution(
                            pattern,
                            resolution
                        )
                        this.service.engine.setPatternResolution(
                            <PercussionLayer>this.service.engine.toggledLayer,
                            pattern,
                            resolution
                        )
                        resolve(true)
                        return { success: true }
                    },
                    cancel: () => {
                        resolve(false)
                    },
                })
            })

            if (!result) {
                this.service.engine.setPatternResolution(
                    <PercussionLayer>this.service.engine.toggledLayer,
                    pattern,
                    oldResolution
                )

                const item = NOTE_RESOLUTIONS.find(
                    item => item.value === oldResolution
                )

                this.noteResolutionDropdown.selectItem({
                    target: {
                        value: JSON.stringify(item),
                    },
                })
            } else {
                this.service.engine.setPatternResolution(
                    <PercussionLayer>this.service.engine.toggledLayer,
                    pattern,
                    resolution
                )

                const item = NOTE_RESOLUTIONS.find(
                    item => item.value === resolution
                )

                this.noteResolutionDropdown.selectItem({
                    target: {
                        value: JSON.stringify(item),
                    },
                })
            }

            return
        }

        this.service.engine.setPatternResolution(
            <PercussionLayer>this.service.engine.toggledLayer,
            pattern,
            resolution
        )
    }
}
