<div class="pitch-range-control-container">
    <div class="title">
        <span class="pack-name">{{ title.packName }}</span> <span class="range"> {{ title.range }}</span>
    </div>
    <div class="keyboard" (mouseenter)="showHint = true" (mouseleave)="showHint = false">
        <ng-container *ngFor="let key of pianoKeys; let i = index">
            <div class="key" 
                [style.left.px]="key.start" 
                [ngClass]="{'white': key.isWhite, 'black': !key.isWhite, 'in-range': key.pitch >= getPitchRange().min && key.pitch <= getPitchRange().max, 'disabled': isDisabledKey(key) }" 
                (mousedown)="startDraggingPitchRange($event, key)">

                <span class="c" *ngIf="key.isC">{{ key.noteName }}</span>
            </div>
        </ng-container>
    </div>
    <div class="hint"  [ngClass]="{'show': showHint }">
        To change the pitch range, either drag the green area or click on a key.
    </div>
</div>