export const gpOptions = {
    "melody": {
        "phraseLengths": [ "short", "medium", "long" ],
        "melodicComplexity": {
            "short": {
                "floor": 0,
                "ceil": 8
            },

            "medium": {
                "floor": 0,
                "ceil": 8
            },

            "long": {
                "floor": 0,
                "ceil": 17
            }
        },

        "packs": [
            {
                "id": "long_0",
                "phraseLength": "long",
                "complexity": 0
            },
            {
                "id": "long_1",
                "phraseLength": "long",
                "complexity": 1
            },
            {
                "id": "long_2",
                "phraseLength": "long",
                "complexity": 2
            },
            {
                "id": "long_3",
                "phraseLength": "long",
                "complexity": 3
            },
            {
                "id": "long_4",
                "phraseLength": "long",
                "complexity": 4
            },
            {
                "id": "long_5",
                "phraseLength": "long",
                "complexity": 5
            },
            {
                "id": "long_6",
                "phraseLength": "long",
                "complexity": 6
            },
            {
                "id": "long_7",
                "phraseLength": "long",
                "complexity": 7
            },
            {
                "id": "long_8",
                "phraseLength": "long",
                "complexity": 8
            },
            {
                "id": "long_9",
                "phraseLength": "long",
                "complexity": 9
            },
            {
                "id": "long_10",
                "phraseLength": "long",
                "complexity": 10
            },
            {
                "id": "long_11",
                "phraseLength": "long",
                "complexity": 11
            },
            {
                "id": "long_12",
                "phraseLength": "long",
                "complexity": 12
            },
            {
                "id": "long_13",
                "phraseLength": "long",
                "complexity": 13
            },
            {
                "id": "long_14",
                "phraseLength": "long",
                "complexity": 14
            },
            {
                "id": "long_15",
                "phraseLength": "long",
                "complexity": 15
            },
            {
                "id": "long_16",
                "phraseLength": "long",
                "complexity": 16
            },
            {
                "id": "long_17",
                "phraseLength": "long",
                "complexity": 17
            },
            {
                "id": "medium_0",
                "phraseLength": "medium",
                "complexity": 0
            },
            {
                "id": "medium_1",
                "phraseLength": "medium",
                "complexity": 1
            },
            {
                "id": "medium_2",
                "phraseLength": "medium",
                "complexity": 2
            },
            {
                "id": "medium_3",
                "phraseLength": "medium",
                "complexity": 3
            },
            {
                "id": "medium_4",
                "phraseLength": "medium",
                "complexity": 4
            },
            {
                "id": "medium_5",
                "phraseLength": "medium",
                "complexity": 5
            },
            {
                "id": "medium_6",
                "phraseLength": "medium",
                "complexity": 6
            },
            {
                "id": "medium_7",
                "phraseLength": "medium",
                "complexity": 7
            },
            {
                "id": "medium_8",
                "phraseLength": "medium",
                "complexity": 8
            },
            {
                "id": "short_0",
                "phraseLength": "short",
                "complexity": 0
            },
            {
                "id": "short_1",
                "phraseLength": "short",
                "complexity": 1
            },
            {
                "id": "short_2",
                "phraseLength": "short",
                "complexity": 2
            },
            {
                "id": "short_3",
                "phraseLength": "short",
                "complexity": 3
            },
            {
                "id": "short_4",
                "phraseLength": "short",
                "complexity": 4
            },
            {
                "id": "short_5",
                "phraseLength": "short",
                "complexity": 5
            },
            {
                "id": "short_6",
                "phraseLength": "short",
                "complexity": 6
            },
            {
                "id": "short_7",
                "phraseLength": "short",
                "complexity": 7
            },
            {
                "id": "short_8",
                "phraseLength": "short",
                "complexity": 8
            }
        ]
    },

    "harmony": {
        "harmonicRepetition": {
            "floor": 0,
            "ceil": 2
        },

        "harmonicRhythm": {
            "floor": 0,
            "ceil": 2
        },

        "harmonicRepetitionDisplayValues": {
            0: "Least repetitive",
            1: "Medium repetitive",
            2: "Most repetitive"
        },

        "harmonicRhythmDisplayValues": {
            0: "Slow",
            1: "Medium",
            2: "Fast"
        },
        
        "packs": [
            {
                "id": "diatonic_1_0",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_1",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_2",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_3",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_4",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_5",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_6",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_1_7",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_1_8",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_1_9",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_1_10",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_1_11",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_1_12",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_13",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_14",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_15",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_16",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_17",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_18",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_19",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_20",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_21",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_22",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_23",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_1_24",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_25",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_26",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_27",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_28",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_29",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_30",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_31",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_32",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_33",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_34",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_1_35",
                "name": "Simple Chords I",
                "description": "Simple, pleasant, triadic progressions, applicable to many styles.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Cinematic",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_0",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_1",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_2",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_3",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_4",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_5",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_6",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_2_7",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_2_8",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_2_9",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_2_10",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_2_11",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_2_12",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_13",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_14",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_15",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_16",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_17",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_18",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_19",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_20",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_21",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_22",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_23",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_2_24",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_25",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_26",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_27",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_28",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_29",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_30",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_31",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_32",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_33",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_34",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_2_35",
                "name": "Simple Chords II",
                "description": "Simple, pleasant, folk-like triadic progressions.",
                "recommendedStyles": [
                    "Folk",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_0",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_1",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_2",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_3",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_4",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_5",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_6",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_3_7",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_3_8",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_3_9",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_3_10",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_3_11",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_3_12",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_13",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_14",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_15",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_16",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_17",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_18",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_19",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_20",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_21",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_22",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_23",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_3_24",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_25",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_26",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_27",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_28",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_29",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_30",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_31",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_32",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_33",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_34",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_3_35",
                "name": "Simple Chords III",
                "description": "Simple, pleasant, pop triadic progressions.",
                "recommendedStyles": [
                    "Pop"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_0",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_1",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_2",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_3",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_4",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_5",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_6",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_4_7",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_4_8",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_4_9",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_4_10",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_4_11",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_4_12",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_13",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_14",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_15",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_16",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_17",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_18",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_19",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_20",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_21",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_22",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_23",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_4_24",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_25",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_26",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_27",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_28",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_29",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_30",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_31",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_32",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_33",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_34",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_4_35",
                "name": "Simple Chords IV",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_0",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_1",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_2",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_3",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_4",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_5",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_6",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_5_7",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_5_8",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_5_9",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_5_10",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_5_11",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "diatonic_5_12",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_13",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_14",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_15",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_16",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_17",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_18",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_19",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_20",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_21",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_22",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_23",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "diatonic_5_24",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_25",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_26",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_27",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_28",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_29",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_30",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_31",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_32",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_33",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_34",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "diatonic_5_35",
                "name": "Simple Chords V",
                "description": "Mildly spicy, pleasant, diatonic progressions that may contain seventh chords.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack",
                    "Ambient"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_0",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_1",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_2",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_3",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_4",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_5",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_6",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_mixed_chords_7",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_mixed_chords_8",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_mixed_chords_9",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_mixed_chords_10",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_mixed_chords_11",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_mixed_chords_12",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_13",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_14",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_15",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_16",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_17",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_18",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_19",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_20",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_21",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_22",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_23",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_mixed_chords_24",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_25",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_26",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_27",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_28",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_29",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_30",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_31",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_32",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_33",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_34",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_mixed_chords_35",
                "name": "Techno Mixed Chords",
                "description": "Chords built on different roots, well-suited for techno and related genres.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_0",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_1",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_2",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_3",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_4",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_5",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_6",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_tonic_chords_7",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_tonic_chords_8",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_tonic_chords_9",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_tonic_chords_10",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_tonic_chords_11",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "techno_tonic_chords_12",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_13",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_14",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_15",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_16",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_17",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_18",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_19",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_20",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_21",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_22",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_23",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "techno_tonic_chords_24",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_25",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_26",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_27",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_28",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_29",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_30",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_31",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_32",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_33",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_34",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "techno_tonic_chords_35",
                "name": "Techno Tonic Chords",
                "description": "Chords built on the tonic (root) chord, switching between major and minor, and with varying extensions.",
                "recommendedStyles": [
                    "Techno",
                    "Electronic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_1_0",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_1_1",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_1_2",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_1_3",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_1_4",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_1_5",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_1_6",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_1_7",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_1_8",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_1_9",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_1_10",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_1_11",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_1_12",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_1_13",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_1_14",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_1_15",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_1_16",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_1_17",
                "name": "7th Chords I",
                "description": "Blues-like 7th chord progressions. Compatible with the Major mode only.",
                "recommendedStyles": [
                    "Blues",
                    "Rock",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_0",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_1",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_2",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_3",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_4",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_5",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_6",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_2_7",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_2_8",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_2_9",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_2_10",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_2_11",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_2_12",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_13",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_14",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_15",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_16",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_17",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_18",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_19",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_20",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_21",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_22",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_23",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_2_24",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_25",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_26",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_27",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_28",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_29",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_30",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_31",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_32",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_33",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_34",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_2_35",
                "name": "7th Chords II",
                "description": "Funky 7th chord progressions. Appropriate for both rock/pop and certain electronic subgenres, like French House.",
                "recommendedStyles": [
                    "Rock",
                    "Pop",
                    "French House",
                    "Funk"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_0",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_1",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_2",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_3",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_4",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_5",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_6",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_3_7",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_3_8",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_3_9",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_3_10",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_3_11",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "7th_chords_3_12",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_13",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_14",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_15",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_16",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_17",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_18",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_19",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_20",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_21",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_22",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_23",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "7th_chords_3_24",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_25",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_26",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_27",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_28",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_29",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_30",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_31",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_32",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_33",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_34",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "7th_chords_3_35",
                "name": "7th Chords III",
                "description": "Poppy, emotional 7th chord progressions. Effective for film scoring.",
                "recommendedStyles": [
                    "Pop",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "power_chords_0",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "power_chords_1",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "power_chords_2",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "power_chords_3",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "power_chords_4",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "power_chords_5",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "power_chords_6",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "power_chords_7",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "power_chords_8",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "power_chords_9",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "power_chords_10",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "power_chords_11",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "power_chords_12",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "power_chords_13",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "power_chords_14",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "power_chords_15",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "power_chords_16",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "power_chords_17",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "power_chords_18",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "power_chords_19",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "power_chords_20",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "power_chords_21",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "power_chords_22",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "power_chords_23",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "power_chords_24",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "power_chords_25",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "power_chords_26",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "power_chords_27",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "power_chords_28",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "power_chords_29",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "power_chords_30",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "power_chords_31",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "power_chords_32",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "power_chords_33",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "power_chords_34",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "power_chords_35",
                "name": "Power Chords",
                "description": "Edgy chord progressions, perfect for your punk/grunge/metal shredding needs.",
                "recommendedStyles": [
                    "Rock",
                    "Punk",
                    "Grunge",
                    "Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_0",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_1",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_2",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_3",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_4",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_5",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_6",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_1_7",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_1_8",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_1_9",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_1_10",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_1_11",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_1_12",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_13",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_14",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_15",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_16",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_17",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_18",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_19",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_20",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_21",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_22",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_23",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_1_24",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_25",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_26",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_27",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_28",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_29",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_30",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_31",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_32",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_33",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_34",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_1_35",
                "name": "Intermediate Mixed Chords I",
                "description": "Mildly spicy, triadic progressions that contain borrowed chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_0",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_1",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_2",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_3",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_4",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_5",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_6",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_2_7",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_2_8",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_2_9",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_2_10",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_2_11",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_2_12",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_13",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_14",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_15",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_16",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_17",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_18",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_19",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_20",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_21",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_22",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_23",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_2_24",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_25",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_26",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_27",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_28",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_29",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_30",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_31",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_32",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_33",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_34",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_2_35",
                "name": "Intermediate Mixed Chords II",
                "description": "Mildly spicy progressions with borrowed chords, and that may contain sus chords.",
                "recommendedStyles": [
                    "Ambient",
                    "Electronic",
                    "Soundtrack",
                    "Pop",
                    "Rock"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_0",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_1",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_2",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_3",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_4",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_5",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_6",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_3_7",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_3_8",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_3_9",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_3_10",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_3_11",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_3_12",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_13",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_14",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_15",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_16",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_17",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_18",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_19",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_20",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_21",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_22",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_23",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_3_24",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_25",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_26",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_27",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_28",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_29",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_30",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_31",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_32",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_33",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_34",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_3_35",
                "name": "Intermediate Mixed Chords III",
                "description": "Progressions that may contain non-diatonic borrowed chords. Ideally suited for world cinematic music.",
                "recommendedStyles": [
                    "Soundtrack",
                    "World"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_0",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_1",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_2",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_3",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_4",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_5",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_6",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_4_7",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_4_8",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_4_9",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_4_10",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_4_11",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "borrowed_chords_4_12",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_13",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_14",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_15",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_16",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_17",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_18",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_19",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_20",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_21",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_22",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_23",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "borrowed_chords_4_24",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_25",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_26",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_27",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_28",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_29",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_30",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_31",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_32",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_33",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_34",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "borrowed_chords_4_35",
                "name": "Intermediate Mixed Chords IV",
                "description": "Progressions that may contain non-diatonic borrowed chords and/or sus chords, ideally suited for fantasy music.",
                "recommendedStyles": [
                    "Fantasy",
                    "Soundtrack"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_0",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_1",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_2",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_3",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_4",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_5",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_6",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_1_7",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_1_8",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_1_9",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_1_10",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_1_11",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_1_12",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_13",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_14",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_15",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_16",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_17",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_18",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_19",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_20",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_21",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_22",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_23",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_1_24",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_25",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_26",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_27",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_28",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_29",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_30",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_31",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_32",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_33",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_34",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_1_35",
                "name": "Advanced Mixed Chords I",
                "description": "Chord progressions that may contain 7th/9th/11th/13th chords.",
                "recommendedStyles": [
                    "Jazz",
                    "20th Century Cinematic"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_0",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_1",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_2",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_3",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_4",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_5",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_6",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_2_7",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_2_8",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_2_9",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_2_10",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_2_11",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_2_12",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_13",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_14",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_15",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_16",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_17",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_18",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_19",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_20",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_21",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_22",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_23",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_2_24",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_25",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_26",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_27",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_28",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_29",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_30",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_31",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_32",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_33",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_34",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_2_35",
                "name": "Advanced Mixed Chords II",
                "description": "Mixed chord progressions with extensions, most suitable for east-asian traditional music.",
                "recommendedStyles": [
                    "East-Asian Traditional"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_0",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_1",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_2",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_3",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_4",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_5",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_6",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_3_7",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_3_8",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_3_9",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_3_10",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_3_11",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_3_12",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_13",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_14",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_15",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_16",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_17",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_18",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_19",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_20",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_21",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_22",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_23",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_3_24",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_25",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_26",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_27",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_28",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_29",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_30",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_31",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_32",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_33",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_34",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_3_35",
                "name": "Advanced Mixed Chords III",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, most suitable for Hip Hop music.",
                "recommendedStyles": [
                    "Hip Hop",
                    "Jazz"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_0",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_1",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_2",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_3",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_4",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_5",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_6",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_4_7",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_4_8",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_4_9",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_4_10",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_4_11",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_mixed_chords_4_12",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_13",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_14",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_15",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_16",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_17",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_18",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_19",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_20",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_21",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_22",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_23",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_mixed_chords_4_24",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_25",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_26",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_27",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_28",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_29",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_30",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_31",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_32",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_33",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_34",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_mixed_chords_4_35",
                "name": "Advanced Mixed Chords IV",
                "description": "Mixed chord progressions with extensions, and non-diatonic borrowed chords, suitable for various metal subgenres.",
                "recommendedStyles": [
                    "Rock",
                    "Heavy Metal"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_0",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_1",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_2",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_3",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_4",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_5",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_6",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_chromatic_chords_7",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_chromatic_chords_8",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_chromatic_chords_9",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_chromatic_chords_10",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_chromatic_chords_11",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_chromatic_chords_12",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_13",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_14",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_15",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_16",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_17",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_18",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_19",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_20",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_21",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_22",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_23",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_chromatic_chords_24",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_25",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_26",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_27",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_28",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_29",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_30",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_31",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_32",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_33",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_34",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_chromatic_chords_35",
                "name": "Advanced Chromatic Chords",
                "description": "Less standard, more dissonant chord progressions, producing more unsettling effects.",
                "recommendedStyles": [
                    "Horror",
                    "Mysterious",
                    "Villain"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_0",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_1",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_2",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_3",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_4",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_5",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_6",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_jazz_chords_7",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_jazz_chords_8",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_jazz_chords_9",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_jazz_chords_10",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_jazz_chords_11",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_jazz_chords_12",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_13",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_14",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_15",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_16",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_17",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_18",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_19",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_20",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_21",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_22",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_23",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_jazz_chords_24",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_25",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_26",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_27",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_28",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_29",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_30",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_31",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_32",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_33",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_34",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_jazz_chords_35",
                "name": "Advanced Jazz Chords",
                "description": "Progressions containing jazz chords/chord sequences.",
                "recommendedStyles": [
                    "Jazz",
                    "Tango"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_0",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_1",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_2",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_3",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_4",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_5",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_6",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_parallel_chords_7",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_parallel_chords_8",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_parallel_chords_9",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_parallel_chords_10",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_parallel_chords_11",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 0
                }
            },
            {
                "id": "advanced_parallel_chords_12",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_13",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_14",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_15",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_16",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_17",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 0,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_18",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_19",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_20",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_21",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_22",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_23",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 1
                }
            },
            {
                "id": "advanced_parallel_chords_24",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_25",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_26",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_27",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_28",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_29",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 2,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_30",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 0
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_31",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_32",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 2,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_33",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 1
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_34",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 1,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            },
            {
                "id": "advanced_parallel_chords_35",
                "name": "Advanced Parallel Chords",
                "description": "Chord progressions with stepwise motion in the bass.",
                "recommendedStyles": [
                    "Hip Hop",
                    "French House"
                ],
                "harmonicRepetition": {
                    "min": 0,
                    "max": 2
                },
                "harmonicRhythm": {
                    "min": 1,
                    "max": 2
                }
            }
        ]
    },

    "settings": {
        mood: {
            tempoRanges: {
                1: [60, 70], 
                2: [110, 130], 
                3: [70, 80], 
                4: [60, 70],
                5: [110, 130]
            },

            keyMode: {
                1: 'major', 
                2: 'major', 
                3: 'minor', 
                4: 'minor',
                5: 'minor'
            },

            dynamicRange: {
                1: [24, 72], 
                2: [88, 120], 
                3: [8, 120],
                4: [24, 72], 
                5: [72, 120]
            },

            melodyPhrasePack: {
                1: {
                    phraseLength: "medium",
                    complexity: 4
                }, 
                
                2: {
                    phraseLength: "short",
                    complexity: 2
                }, 
                
                3: {
                    phraseLength: "long",
                    complexity: 17
                },
                
                4: {
                    phraseLength: "long",
                    complexity: 2
                }, 
                
                5: {
                    phraseLength: "short",
                    complexity: 2
                }
            },

            harmony: {
                1: {
                    harmonicRepetition: 1,
                    harmonicRhythm: { min: 0, max: 1 },
                    datasets: ['diatonic_1', 'diatonic_2', 'diatonic_3', 'diatonic_4', 'diatonic_5', '7th_chords_1', '7th_chords_2', '7th_chords_3', 'advanced_jazz_chords']
                },

                2: {
                    harmonicRepetition: 2,
                    harmonicRhythm: { min: 1, max: 2 },
                    datasets: ['power_chords', 'diatonic_1', 'diatonic_2', 'diatonic_3', '7th_chords_2', 'advanced_jazz_chords', 'advanced_parallel_chords', 'techno_mixed_chords']
                },

                3: {
                    harmonicRepetition: 0,
                    harmonicRhythm: { min: 0, max: 2 },
                    datasets: ['advanced_chromatic_chords']
                },

                4: {
                    harmonicRepetition: 1,
                    harmonicRhythm: { min: 0, max: 0 },
                    datasets: ['diatonic_1', 'diatonic_2', 'diatonic_3', '7th_chords_3', 'borrowed_chords_1', 'borrowed_chords_2', 'borrowed_chords_4', 'advanced_jazz_chords']
                },

                5: {
                    harmonicRepetition: 2,
                    harmonicRhythm: { min: 2, max: 2 },
                    datasets: ['power_chords', '7th_chords_3', 'borrowed_chords_1', 'borrowed_chords_2', 'borrowed_chords_4', 'advanced_mixed_chords_1', 'advanced_mixed_chords_2', 'advanced_mixed_chords_3', 'advanced_mixed_chords_4', 'advanced_jazz_chords', 'advanced_parallel_chords', 'techno_tonic_chords']
                }
            }
        },

        "expressivePerformance": [
            {
                value: 0,
                name: "None"
            },

            {
                value: 1,
                name: "No tempo variations, humanized dynamics"
            },

            {
                value: 2,
                name: "Little tempo variations, humanized dynamics"
            },

            {
                value: 3,
                name: "More tempo variations, humanized dynamics"
            },

            {
                value: 4,
                name: "Strong tempo variations, humanized dynamics"
            }
        ],

        "timeSignatures": [
            { value: [4, 4], name: "4/4" },
            { value: [3, 4], name: "3/4" },
            { value: [2, 4], name: "2/4" },
            { value: [6, 8], name: "6/8" },
            { value: [12, 8], name: "12/8" }
        ],

        "balancePreset": [ 
            {
                "id": "disabled",
                "name": "Disabled",
                "layers": {
                    "Melody": {
                        "gain": 5
                    },
                    "Percussion": {
                        "gain": 7
                    }
                }
            },

            {  
                "id": "electronic",
                "name": "Electronic",
                "layers": {
                    "Melody": {
                        "gain": 5
                    },
                    "Percussion": {
                        "gain": 7
                    }
                }
            },
            {  
                "id": "cinematic",
                "name": "Cinematic",
                "layers": {
                    "Melody": {
                        "gain": 1
                    }
                }
            },
            {  
                "id": "hybrid",
                "name": "Hybrid",
                "layers": {
                    "Melody": {
                        "gain": 5
                    },
                    "Chords": {
                        "gain": -2
                    }
                }
            }
        ],
        "formTag": [
            {
                "id": "song1", 
                "name": "Song I",
                "description": "Forms suitable for song structures.",
                "ids": [
                    "song"
                ]
            },

            {
                "id": "song2", 
                "name": "Song II",
                "description": "Song forms that also include simple linear development.",
                "ids": [
                    "song",
                    "gradual"
                ]
            },

            {
                "id": "classical1", 
                "name": "Classical I",
                "description": "Classical forms suitable for orchestral and fantasy themes.",
                "ids": [
                    "classical"
                ]
            },

            {
                "id": "chinese1", 
                "name": "Chinese",
                "description": "Forms suitable for Chinese style where melodies are not varied. Only compatible with tempo ranges between 30 and 135 BPM.",
                "ids": [
                    "chinese"
                ]
            },

            {
                "id": "cinematic1", 
                "name": "Cinematic",
                "description": "Classical forms combined with simple linear development, suitable for modern cinematic compositions.",
                "ids": [
                    "classical",
                    "gradual"
                ]
            },

            {
                "id": "jazz1", 
                "name": "Jazz",
                "description": "Forms suitable for Jazz.",
                "ids": [
                    "jazz"
                ]
            },

            {
                "id": "electronic1", 
                "name": "Electronic Song I",
                "description": "Song forms with dynamic and filter envelopes, suitable for electronic music.",
                "ids": [
                    "cyberpunk_song"
                ]
            },

            {
                "id": "electronic2", 
                "name": "Electronic Song II",
                "description": "Electronic song forms that also include simple linear development.",
                "ids": [
                    "cyberpunk_song",
                    "cyberpunk_gradual"
                ]
            },

            {
                "id": "deephouse1", 
                "name": "Deep House I",
                "description": "Electronic song forms that also include custom structures suitable for Deep House music.",
                "ids": [
                    "cyberpunk_song",
                    "deep_house"
                ]
            },

            {
                "id": "deephouse2", 
                "name": "Deep House II",
                "description": "Song forms that also include custom structures suitable for Deep House music.",
                "ids": [
                    "song",
                    "deep_house"
                ]
            },

            {
                "id": "organichouse1", 
                "name": "Organic House",
                "description": "Electronic song forms that also include custom structures suitable for Organic House music.",
                "ids": [
                    "cyberpunk_song",
                    "organic_house"
                ]
            },

            {
                "id": "frenchhouse1", 
                "name": "French House",
                "description": "Electronic song forms that also include custom structures suitable for French House music.",
                "ids": [
                    "cyberpunk_song",
                    "french_house"
                ]
            },

            {
                "id": "techno1", 
                "name": "Techno",
                "description": "Electronic song forms that also include custom structures suitable for Techno music.",
                "ids": [
                    "cyberpunk_song",
                    "techno"
                ]
            },

            {
                "id": "edm1", 
                "name": "EDM",
                "description": "Electronic song forms that also include custom structures suitable for EDM music.",
                "ids": [
                    "cyberpunk_song",
                    "maus"
                ]
            },

            {
                "id": "bigroom1", 
                "name": "Big Room",
                "description": "Electronic song forms that also include custom structures suitable for Big Room music.",
                "ids": [
                    "cyberpunk_song",
                    "big_room"
                ]
            },
        ]
    },

    "gpKeys": {
        "id": { "musicEngine": "gp_id", "creatorsAPI": "_id" },
        "settings": { "musicEngine": "global_parameters", "creatorsAPI": "settings" },
        "melodyLayer": { "musicEngine": "melody_pack", "creatorsAPI": "melodyLayer" },
        "harmony": { "musicEngine": "harmony_pack", "creatorsAPI": "harmony" },
        "accompanimentLayers": { "musicEngine": "layers", "creatorsAPI": "accompanimentLayers" },
        "formDescription": { "musicEngine": "form_description", "creatorsAPI": "formDescription" }
    },

    "compositionCreation": {
        "key_signature": ["auto", "any major", "any minor", "C major", "F major", "Bb major", "Eb major", "Ab major", "Db major", "G major", "Cb major", "Gb major", "D major", "A major", "E major", "B major", "F# major", "C# major", "A minor", "D minor", "G minor", "C minor", "F minor", "Bb minor", "Eb minor", "Ab minor", "E minor", "B minor", "F# minor", "C# minor", "G# minor", "D# minor", "A# minor"],
        "duration": ["auto", "< 0'30", "0'30 - 1'00", "1'00 - 1'30", "1'30 - 2'00", "2'00 - 2'30", "2'30 - 3'00", "3'00 - 3'30", "3'30 - 4'00", "4'00 - 4'30", "4'30 - 5'00", "5'00 - 5'30"],
        "numberOfCompositions": ["1", "2", "3", "4", "5"]
    },
    "instrumentSimilarity": {
        "similarity": {
            "floor": 0,
            "ceil": 2
        },

        "similarityDisplayValues": {
            0: "Least similar",
            1: "Medium similarity",
            2: "Most similar"
        },

        "numberOfInstruments": {
            "floor": 1,
            "ceil": 30
        },

        "maxNumberOfInstruments": 30
    }
}