<div
    class="scrollable-content scrollable-content-with-filters"
    [perfectScrollbar]="scrollConfig"
    *ngIf="!libraryLoading && gps.length > 0">
    <div class="list-items-wrapper">
        <list-items [items]="gps" [numberOfColumns]="isMobile() ? 1 : 2"></list-items>
    </div>

    <div class="pages-selector-wrapper">
        <pagination
            [numberOfPages]="numberOfPages"
            [currentPage]="currentPage"
            [selectNewPage]="selectNewPageBinding()"></pagination>
    </div>

    <div class="empty-element"></div>
</div>

<div
    class="scrollable-content scrollable-content-with-filters"
    *ngIf="!libraryLoading && gps.length === 0">
    <div class="no-search-results" *ngIf="gpType === 'regular'">
        No results for '{{ search }}'
    </div>

    <div class="no-search-results" *ngIf="gpType === 'forked'">
        No forked styles found.
    </div>
</div>

<div class="library-loading-element" *ngIf="libraryLoading">
    <div class="small-loader"></div>
    <div class="loader-title">Loading, please wait...</div>
</div>
