import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import SearchItem from "@common-lib/classes/searchitem"
import { DoublesService } from "@services/doubles.service"

@Component({
    selector: "doubles-selection",
    templateUrl: "doubles-selection.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["../context-menus.component.scss"],
})
export class DoublesSelectionComponent implements OnInit {
    @Input() data: {
        layer: GPLayer
        pack: AccompanimentPack
        allowUserAction
        rightClickedSearchItem: {
            value: SearchItem
            index: number
        }
        searchItems
        recursionLevel
        allowRecommendations: boolean
        updateDoubleFolder: (folder: SearchItem) => void
        updateDouble: (double: InstrumentPatch) => void
        selectAllDoubles: (doubles: InstrumentPatch[]) => void
    }

    @Output() close: EventEmitter<any> = new EventEmitter()

    allowUserAction = false

    constructor(private doublesService: DoublesService) {}

    ngOnInit(): void {
        this.allowUserAction = this.data.allowUserAction || false
    }

    closeContextMenu() {
        this.close.emit()
    }

    getEditActionName() {
        if (
            this.data.rightClickedSearchItem == null ||
            this.data.rightClickedSearchItem.value.type != "folder"
        ) {
            return "Edit"
        }

        return "Rename"
    }

    selectDoubleThroughMenu(event) {
        const instrumentPatches: InstrumentPatch[] = SearchItem.getAllValues(
            this.data.rightClickedSearchItem.value
        )

        this.data.selectAllDoubles(instrumentPatches)

        this.closeContextMenu()
    }

    allowDuplicateDouble() {
        if (
            this.data.rightClickedSearchItem != null &&
            this.data.rightClickedSearchItem.value != null &&
            this.data.rightClickedSearchItem.value.type != "folder" &&
            this.data.rightClickedSearchItem.value.createdByAiva == true
        ) {
            return true
        }

        return false
    }

    editSearchItem() {
        let double = this.data.rightClickedSearchItem.value.item

        if (this.data.rightClickedSearchItem.value.type == "folder") {
            this.data.updateDoubleFolder(this.data.rightClickedSearchItem.value)
        } else {
            this.data.updateDouble(double)
        }

        this.closeContextMenu()
    }

    async deleteSearchItem() {
        let index = this.data.rightClickedSearchItem.index
        let searchItem = this.data.searchItems[index]
        this.data.searchItems.splice(index, 1)

        this.doublesService.deleteItemFromDoublesCollection(
            searchItem,
            this.data.layer,
            this.data.pack,
            this.data.allowRecommendations
        )

        this.closeContextMenu()

        if (this.data.rightClickedSearchItem.value.type == "folder") {
            await this.doublesService.deleteDoubleFolder(
                this.data.rightClickedSearchItem.value.item._id
            )
        } else {
            await this.doublesService.deleteDouble(
                this.data.rightClickedSearchItem.value.item.patchID
            )
        }
    }

    async addToMyDoublingInstruments() {
        let double = this.data.rightClickedSearchItem.value.item

        this.closeContextMenu()

        await this.doublesService.duplicateDouble(double)
    }
}
