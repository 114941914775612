import { Directive, ElementRef, HostListener, Input } from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import { DesignService } from "@services/design.service"
@Directive({ selector: '[modal]' })
export class ModalDirective {

    width:number
    height:number

    responsiveWidth:boolean = false
    responsiveHeight:boolean = false
    
    @Input('aButton') type: string = 'primary'

    constructor(private elementRef: ElementRef, private designService: DesignService) {
        
    }

    async ngOnInit() {
        let obs:ResizeObserver = new ResizeObserver(entries => {
            let computeDimensions = false

            for (let entry of entries) {
                if (!this.responsiveWidth) {
                    this.width = entry.contentRect.width
                    computeDimensions = true
                }

                if (!this.responsiveHeight) {
                    this.height = entry.contentRect.height
                    computeDimensions = true
                }
            }

            if (computeDimensions) {
                this.computeModalDimensions()
            }
        })

        // In case the modal-container dom element is not yet added to the DOM, we wait for it to appear
        if (this.elementRef.nativeElement.querySelector('.modal-container') == null) {
            await Misc.wait(0.0001)
        }

        let computedStyle = window.getComputedStyle(this.elementRef.nativeElement.querySelector('.modal-container'))

        let width = parseInt(computedStyle.getPropertyValue('width').replace("px", ""))
        let height = parseInt(computedStyle.getPropertyValue('height').replace("px", ""))

        this.width = width
        this.height = height

        this.setStyle({
            name: 'left', 
            value: "0%"
        })

        this.setStyle({
            name: 'top', 
            value: "0%"
        })

        this.computeModalDimensions()  
        
        obs.observe(this.elementRef.nativeElement.querySelector('.modal-container'))
    }

    setStyle(style: { name, value }) {
        this.elementRef.nativeElement.querySelector('.modal-container').style[style.name] = style.value
    }

    computeModalDimensions() {
        let computedStyle = window.getComputedStyle(this.elementRef.nativeElement.querySelector('.modal-container'))

        let navbarHeight = parseInt(this.designService.getCSSProperty("player-bar-height").replace("px", ""))

        let paddingLeft = parseInt(computedStyle.getPropertyValue('padding-left').replace("px", ""))
        let paddingRight = parseInt(computedStyle.getPropertyValue('padding-right').replace("px", ""))
        let paddingLeftAndRight = paddingLeft + paddingRight

        let paddingTop = parseInt(computedStyle.getPropertyValue('padding-top').replace("px", ""))
        let paddingBottom = parseInt(computedStyle.getPropertyValue('padding-bottom').replace("px", ""))
        let paddingTopAndBottom = paddingTop + paddingBottom + navbarHeight

        const innerWidth = window.innerWidth
        const innerHeight = window.innerHeight

        const widthDifference = innerWidth - this.width - paddingLeftAndRight
        const heightDifference = innerHeight - this.height - paddingTopAndBottom
        
        if (widthDifference < 0) {
            this.responsiveWidth = true

            this.setStyle({
                name: 'min-width', 
                value: "calc(100% - " + (paddingLeftAndRight + 10) + "px)"
            })

            this.setStyle({
                name: 'max-width', 
                value: "calc(100% - " + (paddingLeftAndRight + 10) + "px)"
            })

            this.setStyle({
                name: 'margin-left', 
                value: "5px"
            })
        }

        else {
            this.setStyle({
                name: 'min-width', 
                value: "auto"
            })

            this.setStyle({
                name: 'max-width', 
                value: "auto"
            })

            this.setStyle({
                name: "width", 
                value: this.width + "px"
            })

            this.setStyle({
                name: "margin-left", 
                value: widthDifference / 2 + "px"
            }) 

            this.responsiveWidth = false
        }

        if (heightDifference < 0) {
            this.responsiveHeight = true

            this.setStyle({
                name: 'min-height', 
                value: "calc(100% - " + paddingTopAndBottom + "px)"
            })

            this.setStyle({
                name: 'max-height', 
                value: "calc(100% - " + paddingTopAndBottom + "px)"
            })

            this.setStyle({
                name: 'margin-top', 
                value: "0px"
            })
        }

        else {
            this.setStyle({
                name: 'min-height', 
                value: "auto"
            })

            this.setStyle({
                name: 'max-height', 
                value: "auto"
            })

            this.setStyle({
                name: 'margin-top', 
                value: heightDifference / 2 + "px"
            })
            
            this.responsiveHeight = false
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.computeModalDimensions()
    }       
}