<!-- <div class="magic-prompt-container">
    <input placeholder="Enter your prompt here" [(ngModel)]="prompt" />

    <div class="magic-button-wrapper">
        <magic-button
            [fontSize]="'90%'"
            [title]="title"
            (onClick)="clickOnButton()"
            [element]="element"></magic-button>
    </div>
</div> -->

<div class="magic-button-wrapper">
    <magic-button
        [fontSize]="'90%'"
        [title]="title"
        (onClick)="clickOnButton()"
        [element]="element"></magic-button>
</div>