import { Injectable } from "@angular/core"
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpResponse,
} from "@angular/common/http"
import { BehaviorSubject, Observable, of, throwError, timer } from "rxjs"
import { FileSaverService } from "ngx-filesaver"
import { Router } from "@angular/router"
import { environment } from "../../environments/environment"
import { HelperService } from "../helpers/helper.service"
import { io, Socket } from "socket.io-client"
import streamSaver from "streamsaver"
import { WritableStream } from "web-streams-polyfill/ponyfill"
import { AnimationLoopService } from "./animationloop.service"
import {
    catchError,
    delay,
    retry,
    retryWhen,
    scan,
    switchMap,
    tap,
    timeout,
} from "rxjs/operators"
import { backOff } from "exponential-backoff"
import { UIUser } from "@common-lib/interfaces/general"
import { Misc } from "@common-lib/modules/misc"

const streamToBlob = require("stream-to-blob")

export interface CreatorsAPIResponse {
    result: 0 | 1
    [key: string]: any
}
@Injectable()
export class ApiService {
    realTimeSamplerVersion

    lastReconnectAttempt = 0

    socket: BehaviorSubject<Socket> = new BehaviorSubject<Socket>(null)

    isUpdating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    user: BehaviorSubject<string> = new BehaviorSubject<string>(null)
    error: BehaviorSubject<string> = new BehaviorSubject<string>("")
    subscriptionError: BehaviorSubject<string> = new BehaviorSubject<string>("")

    maxRetries = 11

    apiURL
    backupAPIURL
    socketURL
    domain
    streamMITMLink
    projects = []

    socketReconnectionHandlerLoopCount = 0

    socketTimeout

    captcha: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)
    isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)

    constructor(
        private http: HttpClient,
        private fileSaverService: FileSaverService,
        private animationLoopService: AnimationLoopService,
        private router: Router,
        private helper: HelperService
    ) {
        this.domain = environment.domain
        this.apiURL = environment.apiURL
        this.backupAPIURL = environment.apiURL
        this.socketURL = environment.socketURL
        this.streamMITMLink =
            environment.domain + "/assets/lib/mitm.html?version=2.0.0"
    }

    ngOnInit() { }

    socketSetup(): Promise<any> {
        var socket: Socket = io(this.socketURL, {
            forceNew: true,
            reconnection: true,
            query: { token: this.getToken() },
            transports: ["websocket"],
        })

        this.socket.next(socket)

        return new Promise((resolve, reject) => {
            socket.on("connect", () => {
                resolve(null)
            })
        })
    }

    ngOnDestroy() {
        this.animationLoopService.deleteFunctionFromLoop(
            "socketReconnectionHandler"
        )
    }

    initSocketReconnectionHandler() {
        this.animationLoopService.addFunctionToLoop(
            "socketReconnectionHandler",
            this.socketReconnectionHandler.bind(this),
            30000
        )
    }

    async socketReconnectionHandler(): Promise<any> {
        try {
            const socket = this.socket.getValue()

            this.socketReconnectionHandlerLoopCount += 1

            if (
                this.socketReconnectionHandlerLoopCount > 2 &&
                socket == null &&
                this.user.getValue() != null
            ) {
                console.log("Setting up socket again...")
                return this.socketSetup()
            }

            if (socket != null && socket.disconnected) {
                socket.connect()

                if (socket.disconnected) {
                    await this.socketSetup()
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    async setupCaptcha() {
        const res = await this.request(
            "/user/captcha",
            {},
            "primary",
            "post",
            true
        )
        this.captcha.next(res.captcha)
    }

    isAdmin() {
        return this.user.getValue() == "pierre@aiva.ai"
    }

    isGPModerator() {
        return (
            this.user.getValue() == "pierre@aiva.ai" ||
            this.user.getValue() == "ashkhen@aiva.ai"
        )
    }

    authDownload(url, parameters): Promise<any> {
        return this.http
            .post(this.apiURL + url, parameters, { responseType: "blob" })
            .toPromise()
            .then(res => {
                if (res.type.includes("application/json")) {
                    var fileReader = new FileReader()
                    var __this = this

                    return new Promise((resolve, reject) => {
                        fileReader.onload = function (e: any) {
                            var error = JSON.parse(e.target["result"])

                            if (error["result"] == 0) {
                                //__this.handleError(error['message'])

                                return reject(error["message"])
                            }

                            __this.subscriptionError.next(error["message"])
                            return reject({ result: -2 })
                        }

                        fileReader.readAsText(res)
                    })
                } else {
                    return Promise.resolve(res)
                }
            })

            .catch(err => {
                return Promise.reject(err)
            })
    }

    authGetDownload(url): Promise<any> {
        return this.http
            .get(url, { responseType: "blob" })
            .toPromise()
            .then(res => {
                return Promise.resolve(res)
            })

            .catch(err => {
                return Promise.resolve({ status: err.status })
            })
    }

    authLargeDownload(url, parameters, name, type): Promise<any> {
        parameters["token"] = this.getToken()

        var endpoint = this.apiURL + url
        var counter = 0

        for (var param in parameters) {
            if (counter == 0) {
                endpoint += "?"
            } else {
                endpoint += "&"
            }

            endpoint += param + "=" + parameters[param]
            counter += 1
        }

        var couldntDownload = false

        if (type == "chords") {
            delete parameters["token"]

            return this.authRequest(url, parameters, "primary", true).then(
                res => {
                    if (res.status != 200 && res.status != null) {
                        this.handleFailedStatus(res.status)

                        couldntDownload = true
                    } else {
                        var blob = new Blob([res["text"]], {
                            type: "text/plain;charset=utf-8",
                        })

                        this.fileSaverService.save(blob, name)
                    }
                }
            )
        } else if (type == "composition") {
            delete parameters["token"]

            return this.authGetDownload(endpoint).then(res => {
                if (res.status != 200 && res.status != null) {
                    this.handleFailedStatus(res.status)

                    couldntDownload = true
                } else {
                    this.fileSaverService.save(res, name)
                }
            })
        }

        streamSaver.WritableStream = WritableStream
        streamSaver.ReadableStream = ReadableStream
        streamSaver.mitm = this.streamMITMLink

        var fileStream = streamSaver.createWriteStream(name) // stream initiated

        return fetch(endpoint)
            .then(res => {
                if (res.status != 200) {
                    couldntDownload = true

                    return this.handleFailedStatus(res.status)
                }

                var writer = fileStream.getWriter()

                // write stream to a file until end of stream is reached
                const reader = res.body.getReader()
                const pump = () =>
                    reader
                        .read()
                        .then(res =>
                            res.done
                                ? writer.close()
                                : writer.write(res.value).then(pump)
                        )

                return pump()
            })

            .then(() => {
                return Promise.resolve(couldntDownload)
            })

            .catch(err => {
                console.error(err)
                return Promise.reject(err)
            })
    }

    fetchWithTimeout(resource, options) {
        const { timeout = 3600000 } = options

        const controller = new AbortController()
        const id = setTimeout(() => controller.abort(), timeout)

        return fetch(resource, {
            ...options,
            signal: controller.signal,
        }).then(response => {
            clearTimeout(id)

            return Promise.resolve(response)
        })
    }

    handleFailedStatus(status) {
        if (status == 431) {
            return Promise.reject("You must export the WAV file first.")
        } else if (status == 423) {
            this.handleError(
                "You can't download folder with more than 500 compositions in it"
            )
            return Promise.resolve()
        } else if (status == 430) {
            this.handleError(
                "You don't have enough downloads left in your quota to do this operation."
            )
            return Promise.resolve()
        } else if (status == 432) {
            this.subscriptionError.next(
                "You don't have enough downloads left in your quota to do this operation. To download more compositions, please upgrade to the Pro plan."
            )
            return Promise.resolve()
        } else if (status == 433) {
            this.subscriptionError.next(
                "You don't have enough downloads left in your quota to do this operation. To increase your quota, please upgrade to a paid plan."
            )
            return Promise.resolve()
        } else if (status == 434) {
            this.subscriptionError.next(
                "Subscribe to any plan to download Pop, Jazz and Rock tracks."
            )
            return Promise.resolve()
        } else if (status == 435) {
            this.subscriptionError.next(
                "Subscribe to the Standard Annually or Pro Annually plan to access this feature"
            )
            return Promise.resolve()
        } else if (status == 501) {
            return Promise.reject("Cannot download an empty folder")
        }

        return Promise.reject(
            "An error ocurred while downloading your track, please contact Support."
        )
    }

    postAuthRequestWithObservable<T>(url, parameters, urlType) {
        this.error.next("")

        if (this.socket.getValue() != null) {
            parameters["socketID"] = this.socket.getValue().id
        }

        const apiURL = urlType == "secondary" ? this.backupAPIURL : this.apiURL
        const prefix = this.http.post(apiURL + url, parameters, {
            headers: new HttpHeaders({ Authorization: "Bearer ${authtoken}" }),
            observe: "events",
            responseType: "text",
        })

        return prefix.pipe(
            timeout(120000),
            // tap(res => console.log(res)), // use this to log responss
            retry({
                count: 5,
                delay: (_, retryCount) =>
                    retryCount === 1 ? timer(500) : of({}),
            }),
            catchError(err => {
                throw {
                    result: 0,
                    errorObject: err,
                    message: err.error,
                }
            })
        )
    }

    public async fetchRequest({
        url,
        parameters,
        urlType,
        type,
        controller,
        retries = 5,
        token,
    }: {
        url
        parameters
        urlType: "primary" | "secondary"
        type: "POST" | "GET"
        controller?: AbortController
        retries?: number
        token?: string
    }): Promise<CreatorsAPIResponse> {
        this.error.next("")

        if (this.socket.getValue() != null) {
            parameters["socketID"] = this.socket.getValue().id
        }

        const apiURL = urlType == "secondary" ? this.backupAPIURL : this.apiURL
        const options: any = {
            method: type,
            headers: new Headers({
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(parameters),
        }

        if (token !== undefined) {
            options.headers.set("Authorization", `${token}`)
        }

        if (controller !== undefined) {
            options.signal = controller.signal
        }

        try {
            return (await fetch(apiURL + url, options)).json()
        } catch (e) {
            if (retries === 0) {
                throw e
            }

            await Misc.wait(0.5)

            return this.fetchRequest({
                url,
                parameters,
                urlType,
                type,
                controller,
                retries: retries - 1,
            })
        }
    }

    /**
     * While there is nothing wrong with this method, it is deprecated in favor of fetchRequest, 
     * which uses the Fetch API, and enables us to cancel requests using AbortController.
     * @deprecated
     */
    authRequest(url, parameters, urlType, retry, type = "post"): Promise<any> {
        parameters["token"] = this.getToken()

        var requestOptions = {
            headers: new Headers({ Authorization: "Bearer ${authtoken}" }),
        }

        return this.request(
            url,
            parameters,
            urlType,
            type,
            retry,
            requestOptions
        ).catch(e => {
            if (e?.status === 401) {
                this.logout(false)
            }

            throw e
        })
    }

    authFormRequest(url, parameters, type = "post"): Promise<any> {
        var requestOptions = {
            headers: new Headers({
                Authorization: "Bearer ${authtoken}",
                "Content-Type": undefined,
            }),
        }

        return this.request(
            url,
            parameters,
            "primary",
            type,
            true,
            requestOptions
        )
    }

    bufferRequest(url): Promise<any> {
        this.error.next("")

        var requestOptions: any = { responseType: "blob" }

        return this.http
            .get(url, requestOptions)
            .toPromise()

            .catch(err => {
                console.error(err)
            })
    }

    storageRequest(url): Promise<any> {
        return this.http.get(url).toPromise()
    }

    async retriableRequest(url, parameters, urlType, type, requestOptions) {
        this.error.next("")

        if (this.socket.getValue() != null) {
            parameters["socketID"] = this.socket.getValue().id
        }

        let apiURL = this.apiURL

        if (urlType == "secondary") {
            apiURL = this.backupAPIURL
        }

        var prefix

        if (type == "get") {
            prefix = this.http.get(apiURL + url, parameters)
        } else {
            if (requestOptions == null) {
                prefix = this.http.post(apiURL + url, parameters)
            } else {
                prefix = this.http.post(
                    apiURL + url,
                    parameters,
                    requestOptions
                )
            }
        }

        try {
            return prefix

                .pipe(timeout(120000))

                .toPromise()
        } catch (err) {
            console.error(err)
            if (err != null && err.statusText != null) {
                if (err instanceof HttpErrorResponse && err.status == 500) {
                    return "Your file exceeds max file size limit (1MB)"
                }

                if (err instanceof HttpErrorResponse && err.status == 401) {
                    this.clearUser()

                    return "Your session has timed out. Please log in again."
                }
            }

            throw err
        }
    }

    async request(
        url,
        parameters,
        urlType,
        type,
        retry,
        requestOptions = null
    ): Promise<any> {
        const numOfAttempts = retry ? 10 : 1

        const res = await backOff(
            () =>
                this.retriableRequest(
                    url,
                    parameters,
                    urlType,
                    type,
                    requestOptions
                ),
            {
                numOfAttempts: numOfAttempts,
                maxDelay: 30000,
                startingDelay: 4000,
            }
        )

        // general error
        if (res.result === 0) {
            if (res["data"]) {
                throw res
            } else {
                throw res["message"]
            }
        }

        // subscription error
        else if (res["result"] === -1) {
            this.subscriptionError.next(res["message"])

            throw res["message"]
        }

        return res
    }

    async logout(sendAPIRequest: boolean): Promise<any> {
        try {
            if (sendAPIRequest) {
                await this.authRequest(
                    "/user/logout",
                    { refreshToken: this.getRefreshToken() },
                    "primary",
                    true
                )
            }

            this.clearUser()
            this.isLoggedIn.next(false)
        } catch (err) {
            this.clearUser()
            this.isLoggedIn.next(false)

            return Promise.resolve()
        }
    }

    login(user): Promise<any> {
        return this.request("/user/login", user, "primary", "post", true)
            .then(res => {
                return Promise.resolve({
                    token: res.token,
                    refreshToken: res.refreshToken,
                })
            })

            .catch(err => {
                if (err instanceof Object) {
                    err = JSON.stringify(err)
                }

                return Promise.reject(err)
            })
    }

    googleLogin(user): Promise<any> {
        return this.request("/user/googleLogin", user, "primary", "post", true)
    }

    createAccount(user: UIUser) {
        return this.request("/user/create", user, "primary", "post", true).then(
            res => {
                return Promise.resolve(res["message"])
            }
        )
    }

    createAccountWithGoogle(user: UIUser) {
        return this.request(
            "/user/createWithGoogle",
            user,
            "primary",
            "post",
            true
        )
    }

    oneAccountPerPerson(email) {
        return Promise.resolve(true)
        /*
        var users:any = localStorage.getItem('users')

        var permitLogin = false

        if (users != null) {
            users = JSON.parse(users)

            for (var u = 0; u < users.length; u++) {
                if (users[u] == email) {
                    permitLogin = true
                }
            }

            if (!permitLogin) {
                users.push(email)
            } 
        	
            if (users.length < 3) {
                permitLogin = true
            }
        }

        else {
            users = [email]

            permitLogin = true
        }

        localStorage.setItem('users', JSON.stringify(users))

        if (!permitLogin) {
            return Promise.reject("Only one account per person is permitted.")
        }

        return Promise.resolve(permitLogin)*/
    }

    clearUser(): void {
        this.user.next(null)
        this.isLoggedIn.next(false)
        localStorage.removeItem("difficulty")
        localStorage.removeItem("currentUser")
        localStorage.removeItem("loggedInWith")

        this.router.navigate(["/login"])
    }

    updateError(err: string): void {
        this.error.next("")
    }

    handleError(err: string): boolean {
        console.error(err)
        this.error.next(err)

        return false
    }

    setUser(email, loginMethod?: string): void {
        var user = email

        localStorage.setItem("currentUser", user)

        if (loginMethod) localStorage.setItem("loggedInWith", loginMethod)

        this.user.next(user)

        this.redirectToApp(user)
    }

    getRefreshToken() {
        return localStorage.getItem("refreshToken")
    }

    getToken() {
        return localStorage.getItem("token")
    }

    redirectToApp(user) {
        var redirectToApp = localStorage.getItem("redirectToApp")

        if (environment.production && redirectToApp == "true") {
            window.location.href =
                "AIVA://login/" +
                user +
                "/" +
                this.getToken() +
                "/" +
                this.getRefreshToken()
        } else if (redirectToApp == "true") {
            window.location.href =
                "AIVA-Staging://login/" +
                user +
                "/" +
                this.getToken() +
                "/" +
                this.getRefreshToken()
        }

        localStorage.setItem("redirectToApp", "false")
    }

    logError(logger) {
        try {
            const now = new Date().getTime().toString()
            const lastErrorTime = localStorage.getItem("lastErrorTime")

            if (
                lastErrorTime != null &&
                this.helper.getTimeDifferenceInSeconds(lastErrorTime, now) <= 60
            ) {
                return
            }

            localStorage.setItem(
                "lastErrorTime",
                new Date().getTime().toString()
            )

            return this.authRequest(
                "/logger/logError",
                { logger },
                "primary",
                "true"
            )
        } catch (error) {
            console.error(error)
        }
    }
}
