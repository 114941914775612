export default class CompositionMetric {
    compositionID:string
    type:string
    data:object = {
        sessions: [],
        skips: [],
        seeks: []
    }

    constructor(compositionID, type) {
        this.compositionID = compositionID
        this.type = type
    }

}