import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { DesignService } from "@services/design.service"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import { LabelType } from "@angular-slider/ngx-slider"
import Pack from "@common-lib/classes/generationprofiles/pack"
import { SourcePackService } from "@services/source-packs/sourcepacks.service"

@Component({
    selector: "pack-synchronisation",
    templateUrl: "pack-synchronisation.component.html",
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackSynchronisationModalComponent implements OnInit {
    @Input() pack: AccompanimentPack
    @Input() layer: GPLayer

    synchSelection = {
        packToSynchTo: null,
        strength: 0,
        transform: false,
        layer: null,
    }

    synchronizationIsLoading: boolean = false

    tooltips = {
        synchronisation: "",
        transform:
            "While synchronisation attempts to synchronise the rhythms of two packs without modifying their intended styles, transform breaks that constraint to guarantee that both packs will always play the exact same rhythm.",
    }

    synchronisationSliderOptions

    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor(
        private gpService: GenerationProfileService,
        private ref: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        if (
            this.pack.synchronisation != null &&
            this.pack.synchronisation.target != null
        ) {
            this.synchSelection = {
                packToSynchTo: this.getPackToSyncTo(
                    this.pack.synchronisation.target
                ),
                strength: this.syncTypeToStrength(
                    this.pack.synchronisation.type
                ),
                transform: false,
                layer: this.getLayerFromString(
                    this.pack.synchronisation.target.layer
                ),
            }
        }

        if (this.pack.transform != null && this.pack.transform.target != null) {
            this.synchSelection = {
                packToSynchTo: this.getPackToSyncTo(this.pack.transform.target),
                strength: null,
                transform: true,
                layer: this.getLayerFromString(
                    this.pack.transform.target.layer
                ),
            }
        }

        if (this.synchSelection.packToSynchTo != null) {
            this.synchronizationIsLoading = true

            this.synchronisationSliderOptions =
                await this.getSynchronisationSliderOptions()

            this.synchronizationIsLoading = false

            this.ref.detectChanges()
        }
    }

    getLayerFromString(layer: string) {
        for (let l of this.gpService.generationProfile.accompanimentLayers) {
            if (l.name == layer) {
                return l
            }
        }

        return null
    }

    getPackToSyncTo(target): Pack {
        for (let layer of this.gpService.generationProfile
            .accompanimentLayers) {
            if (layer.name != target.layer) {
                continue
            }

            let packs: any = layer.packs

            for (let pack of packs) {
                if (pack.idx == target.idx) {
                    return pack
                }
            }
        }

        return null
    }

    strengthToSyncType() {
        let type = "none"

        if (this.synchSelection.strength == 1) {
            type = "include"
        } else if (this.synchSelection.strength == 2) {
            type = "subset"
        } else if (this.synchSelection.strength == 3) {
            type = "exact"
        }

        return type
    }

    syncTypeToStrength(type: string) {
        if (type == "include") {
            return 1
        } else if (type == "subset") {
            return 2
        } else if (type == "exact") {
            return 3
        }

        return 0
    }

    synchronisePack() {
        if (this.synchSelection.transform) {
            this.pack.transform = {
                target: {
                    layer: this.synchSelection.layer.name,
                    idx: this.synchSelection.packToSynchTo.idx,
                },
            }

            this.pack.synchronisation = null
        } else if (this.synchSelection.strength == 0) {
            this.pack.synchronisation = null
            this.pack.transform = null
        } else {
            this.pack.synchronisation = {
                target: {
                    layer: this.synchSelection.layer.name,
                    idx: this.synchSelection.packToSynchTo.idx,
                },
                type: this.strengthToSyncType(),
            }

            this.pack.transform = null
        }

        // @todo: investigate why the reference between this.pack and the pack object in generationProfile is broken here
        // This hack works though
        for (let layer of this.gpService.generationProfile
            .accompanimentLayers) {
            if (layer.name != this.layer.name) {
                continue
            }

            for (let pack of layer.packs) {
                if (
                    pack.packID == this.pack.packID &&
                    pack["idx"] == this.pack["idx"]
                ) {
                    pack.transform = this.pack.transform
                    pack.synchronisation = this.pack.synchronisation
                }

                if (
                    pack.synchronisation == null ||
                    pack.synchronisation.target == null
                ) {
                    continue
                }

                for (let otherLayer of this.gpService.generationProfile
                    .accompanimentLayers) {
                    if (
                        otherLayer == layer ||
                        pack.synchronisation.target.layer != otherLayer.name
                    ) {
                        continue
                    }

                    for (let otherPack of otherLayer.packs) {
                        if (
                            otherPack["idx"] == pack.synchronisation.target.idx
                        ) {
                            if (
                                otherPack.synchronisation != null &&
                                otherPack.synchronisation.target != null &&
                                otherPack.synchronisation.target.idx ==
                                    pack["idx"]
                            ) {
                                otherPack.synchronisation = null
                            }

                            break
                        }
                    }

                    break
                }
            }
        }

        let preview = this.gpService.getPlayingPreviewForLayer(
            this.layer.name,
            this.pack.packID
        )

        this.gpService.setAsUpdated("synchronisePack", preview)
    }

    async selectPack(event) {
        this.synchSelection = {
            packToSynchTo: event.new.pack,
            strength: 0,
            transform: false,
            layer: event.new.layer,
        }

        this.synchronizationIsLoading = true

        if (this.pack != null && event.new.pack != null) {
            this.tooltips.synchronisation =
                "A soft constraint used to synchronise the rhythm of " +
                this.pack.name +
                " to that of " +
                event.new.pack.name +
                ", while keeping the intended styles of both packs unchanged."
            this.synchronisationSliderOptions =
                await this.getSynchronisationSliderOptions()
        }

        this.synchronizationIsLoading = false

        if (event.new.pack == null) {
            this.synchronisePack()
        }

        this.ref.detectChanges()
    }

    async getSynchronisationSliderOptions() {
        let max = 0

        let targetPackID = this.synchSelection.packToSynchTo.packID

        const syncs = await this.gpService.http.computeAccompanimentPackSyncs({
            sourcePackID: this.pack.packID,
            targetPackID: targetPackID,
            timeSignature:
                this.gpService.generationProfile.settings.timeSignature,
        })

        const enableMaxSync = this.pack.packID == targetPackID || syncs.exact

        if (enableMaxSync) {
            max = 3
        } else if (syncs.subset) {
            max = 2
        } else if (syncs.include) {
            max = 1
        }

        return {
            floor: 0,
            ceil: max,
            translate: (value: number, label: LabelType): string => {
                if (label == LabelType.Floor) {
                    return "No Sync"
                } else if (label == LabelType.Ceil) {
                    return "Max Sync"
                }

                return ""
            },
        }
    }

    getSelectedPack() {
        if (this.synchSelection.packToSynchTo != null) {
            return {
                value: this.synchSelection.packToSynchTo.packID,
                name: this.synchSelection.packToSynchTo.name,
                pack: this.synchSelection.packToSynchTo,
                layer: this.synchSelection.packToSynchTo,
                optgroup: "",
            }
        } else {
            return {
                value: null,
                name: "None",
                pack: null,
                layer: null,
                optgroup: "None",
            }
        }
    }

    getAssignedAccompanimentPacks() {
        let packs: any = [
            {
                pack: null,
                value: null,
                layer: null,
                name: "None",
                optgroup: "None",
            },
        ]

        for (let layer of this.gpService.generationProfile
            .accompanimentLayers) {
            if (layer == this.layer) {
                continue
            }

            for (let pack of layer.packs) {
                packs.push({
                    value: pack.packID,
                    name: pack.name,
                    pack: pack,
                    layer: layer,
                    optgroup: layer.getName(),
                })
            }
        }

        return packs
    }

    closeModal() {
        this.close.emit()
    }
}
