<div class="top-menu-container {{ class }}">
    <div
        class="top-menu"
        *ngFor="let menu of options"
        [attr.av-data]="menu.avData"
        (click)="selectMenu(menu.value)"
        [ngClass]="{ 'top-menu-selected': selectedMenu === menu.value }">
        {{ menu.name }}
    </div>
</div>
