import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { CompositionService } from '../../../services/composition.service'
import { ModalService } from '../../../services/modal.service'
import { FolderService } from '../../../services/folder.service'
import { BillingService } from '../../../services/billing.service'
import { ApiService } from '../../../services/api.service'
import { environment } from "../../../../environments/environment"
import { AnalyticsService } from '../../../services/analytics.service'
import { ActivityMetric } from '../../../../../../common-lib/general/classes/activitymetric'
import { Router } from '@angular/router'
import { TracksService } from '../../../services/tracks.service'
import { PlaylistService } from '../../../services/playlist.service'
import { GeneralService } from '@services/general/general.service'
import { PlayerService } from '@services/audio/player/player.service'

@Component({
 	selector: 'preset-component',
  	templateUrl: 'preset.component.html',
  	styles: []
})
export class PresetComponent implements OnInit {
    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    includeTempoVariations = true
    includePercussions = true
    difficulty = "advanced"
    difficultyToggled = true

    backingTrack = false
    
    cyberpunkIsHovered = false

    presetLoading = false
    presetDescription = ''

    selectedUsecase = null
    selectedPreset = ''
    bassBoostSelected = false

    selectedPresetUsecase = null

    ensembleInput = ''

    customizePreset = false

    createMenu = 'preset'

    // the parameters we care about for the creation of the track
    parameters = {
        key: 'auto',
        ensemble: 'auto',
        duration: 'auto',
        timeSignature: 'auto',
        pacing: 'auto',
        numberOfCompositions: '1',
        emotion: null
    }

    keyList = false
    keyItems = []

    timeSignatureList = false
    timeSignatureItems = []

    pacingList = false
    pacingItems = []

    emotionItems = []

    ensembleList = false
    ensembleItems = []

    durationList = false
    durationItems = []

    numberOfCompositionsList = false
    numberOfCompositionsItems = []

    createLoading = false

    subscription

    captcha = true

    createFromPlaylist = false

    @Output() onDifficultyChanged = new EventEmitter<string>()
    @Output() onPresetUsecaseChanged = new EventEmitter<any>()

    constructor(private generalService:GeneralService, private analyticsService:AnalyticsService, private compositionService:CompositionService, private apiService:ApiService, private billingService:BillingService, private folderService:FolderService, private modalService:ModalService, private playerService: PlayerService, protected router: Router, private tracksService: TracksService, private playlistService: PlaylistService) {        

        this.apiService.captcha.subscribe(value => {
            this.captcha = value
        })

        this.billingService.subscription.subscribe(value => {
            if (value != null) {
                this.subscription = value
            }
        })

        this.modalService.customizePreset.subscribe(value => {
            this.customizePreset = value

            if (!value) {
                this.resetCustomizePreset()
            }
        })

        this.modalService.createFromPlaylist.subscribe(value => {
            this.createFromPlaylist = value
        })
    }

    ngOnInit() {
        this.selectedPreset = ''
        // this.cancelCustomization()

        var promise = Promise.resolve()
        
        if (localStorage.getItem('difficulty') == null) {
            promise = this.apiService.authRequest('/user/getDifficulty', { }, "primary", true).then((res) => {
                localStorage.setItem('difficulty', res.difficulty)
            })
        }

        promise.then(() => {
            this.difficulty = localStorage.getItem('difficulty')  
            this.difficultyToggled = this.difficulty == 'advanced'
            this.onDifficultyChanged.emit(this.difficulty)
        })


        this.modalService.presetUsecase.subscribe(value => {
            if (value == null) {
                return
            }

            if (value["category"] != null && value["usecase"] != null) {
                this.selectPresetUsecase(value["category"], value["usecase"])
            }
        })
    }

    togglePresetDescription(preset, toggle):void {
		if (!toggle && this.selectedPreset == '') {
			this.presetDescription = ''
        }
    
        else {
            if (this.selectedPreset != '' && !toggle && this.selectedPreset != preset) {
                preset = this.selectedPreset
            }

            this.presetDescription = this.compositionService.parameters[preset].description
        }
    }

    bassBoost() {
        this.bassBoostSelected = !this.bassBoostSelected
    }
    
    recommendedSelections() {
		window.open('https://aiva.crisp.help/en/article/our-recommended-combinations-of-presets-and-customizable-parameters-1csuxh0/?1560955702941', '_blank');
    }

    getPresetUseCases() {
        return this.generalService.query.presetUseCases
    }

    getCaptchaKey() {
		return environment.recaptchaSiteKey
	}

    onEmotionChange(emotion){
        this.parameters.emotion = emotion.value
    }

    createTrackWithPreset(captchaResponse) {
        this.presetLoading = true
        
        if ((this.selectedPreset == '' || this.selectedPreset == null) && this.selectedUsecase == null) {
            return
        }

        var promise
        var parameters
        var activityMeta = { }

        let folderID = this.folderService.getSelectedFolderID()

        if (this.folderService.contentType.getValue() != 'Compositions') {
            folderID = ''
        }

        if (this.selectedUsecase != null) {
            const presetUsecase = this.getSelectedPresetUsecase()

            parameters = {
                folderID: folderID,
                category: presetUsecase.category,
                subCategory: presetUsecase.subCategory,
                duration: this.parameters.duration,
                numberOfCompositions: this.parameters.numberOfCompositions,
                captchaResponse: captchaResponse,
                emotion: this.parameters.emotion,
                style: presetUsecase.style
            }

            promise = this.tracksService.createComposition(parameters, "presetUsecase", null)

            activityMeta["type"] = "preset_usecases"
            activityMeta["usecase"] = presetUsecase.subCategory
            activityMeta["numberOfCompositions"] = this.parameters.numberOfCompositions
        }

        else {
            parameters = {
                key: this.parameters.key,
                timeSignature: this.parameters.timeSignature,
                ensemble: this.parameters.ensemble,
                duration: this.parameters.duration,
                pacing: this.parameters.pacing,
                folderID: folderID,
                numberOfCompositions: this.parameters.numberOfCompositions,
                preset: this.selectedPreset,
                includeTempoMap: this.selectedUsecase != null ? true : this.includeTempoVariations, 
                includeDrums: this.selectedUsecase != null ? true : this.includePercussions,
                backingTrack: this.selectedUsecase != null ? false : this.backingTrack,
                bassBoost: this.selectedUsecase != null ? false : this.bassBoostSelected,
                ensembleInput: this.ensembleInput,
                captchaResponse: captchaResponse,
                emotion: this.parameters.emotion
            }

            promise = this.tracksService.createComposition(parameters, "presetStyle", null)

            activityMeta["type"] = "preset_styles"
            activityMeta["style"] = this.selectedPreset
            activityMeta["numberOfCompositions"] = this.parameters.numberOfCompositions
        }

		return promise.then(async () => {
            this.presetLoading = false
            
            this.analyticsService.addActivity(ActivityMetric.CREATE_WITH_PRESET, activityMeta)
        })

        .catch((err) => {
            console.log(err)
        })
    }

    suggestNewPreset() {
		window.open('https://goo.gl/forms/2z24WhPKnDxo8D1G3', '_blank');
    }

    selectPresetUsecase(category, usecase) {
        this.selectedUsecase = {
            category: category,
            usecase: usecase
        }
        
        const presetUsecase = this.getPresetUseCases()[this.selectedUsecase.category].subcategories[this.selectedUsecase.usecase]

        this.selectedPresetUsecase = presetUsecase
        
        this.onPresetUsecaseChanged.emit(presetUsecase)

        this.emotionItems = []
        this.durationItems = []
        this.numberOfCompositionsItems = []

        for (var p = 0; p < presetUsecase.parameters.length; p++) {
            if (presetUsecase.parameters[p].name == "hypermoods") {
                presetUsecase.parameters[p].values = presetUsecase.parameters[p].values.sort()

                this.emotionItems = this.emotionItems.concat(presetUsecase.parameters[p].values)
            }

            else if (presetUsecase.parameters[p].name == "duration") {
                this.durationItems = presetUsecase.parameters[p].values
            }

            else if (presetUsecase.parameters[p].name == "numberOfCompositions") {
                this.numberOfCompositionsItems = presetUsecase.parameters[p].values
            }
        }

        this.customizeParameters()
    }

    isNotProduction() {
        return !environment.production
    }

    getSelectedPresetUsecase() {
        if(this.selectedUsecase == null){
            return {
                name: null,
                category: null,
                subCategory: null
            }
        }

        const presetUsecase = this.getPresetUseCases()[this.selectedUsecase.category].subcategories[this.selectedUsecase.usecase]

        return {
            name: presetUsecase.name,
            category: this.getPresetUseCases()[this.selectedUsecase.category].category,
            subCategory: this.getPresetUseCases()[this.selectedUsecase.category].subcategories[this.selectedUsecase.usecase].name,
            style: presetUsecase.style
        }
    }

    isMobile() {
        return this.generalService.isMobile()
    }

    randomNumberInRange(max, min) {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    selectPreset(preset) {
        this.selectedPreset = preset

        if (this.selectedPreset == '') {
            return
        }

        this.durationItems = this.compositionService.parameters[this.selectedPreset].duration
        this.keyItems = this.compositionService.parameters[this.selectedPreset].key_signature
        this.ensembleItems = this.compositionService.parameters[this.selectedPreset].ensemble
        this.timeSignatureItems = this.compositionService.parameters[this.selectedPreset].time_signature
        this.numberOfCompositionsItems = this.compositionService.parameters[this.selectedPreset].numberOfCompositions
        this.pacingItems = this.compositionService.parameters[this.selectedPreset].pacing

        this.customizeParameters()
    }

    customizeParameters() {       
        this.parameters = {
            pacing: 'auto',
            key: 'auto',
            ensemble: 'auto',
            duration: 'auto',
            timeSignature: 'auto',
            numberOfCompositions: '1',
            emotion: null
        }

        this.keyList = false
        this.ensembleList = false
        this.durationList = false

        this.modalService.customizePreset.next(true)
        this.modalService.displayCreateModalMenus.next(false)
    }

    hoverOnCyberpunk(presetUseCase, value) {
        if (presetUseCase == 'Cyberpunk') {
            this.cyberpunkIsHovered = value
        }
    }

    cancelCustomization() {
        this.selectedPreset = ''
        this.customizePreset = false
    }

    selectDuration(duration) {
        if (!this.showDuration(duration)) {
            return
        }

        this.parameters.duration = duration
    }

    getCustomizePreset():boolean {
        return this.modalService.customizePreset.getValue()
    }

    changekeyMode(mode) {
        this.parameters.key = mode
        this.keyList = false
    }

    enabledBatchedCompositions() {
        var sub = this.billingService.subscription.getValue()

        if (sub != null && sub.plan != "free") {
            return true
        }

        return false
    }

    changeSlider(boolean) {
        this.difficulty = this.difficultyToggled ? 'advanced' : 'simplified'

        localStorage.setItem('difficulty', this.difficulty)

        this.onDifficultyChanged.emit(this.difficulty)

        return this.apiService.authRequest('/user/setDifficulty', { difficulty: this.difficulty }, "primary", true)
    }

    changeTimeSignature(timeSignature) {
        this.parameters.timeSignature = timeSignature
        this.timeSignatureList = false
    }

    changeEnsemble(ensemble) {
        this.parameters.ensemble = ensemble
        this.ensembleList = false

        if (ensemble == "Heavy Metal Ensemble") {
            if (!this.parameters.key.includes("minor")) {
                this.parameters.key = 'any minor'
            }
            
            this.parameters.pacing = 'medium'

            const random = Math.floor((Math.random() * 2) + 1)

            this.parameters.pacing = random == 1 ? 'medium' : 'fast'
        }

        else if (ensemble == "Punk Rock Ensemble") {
            this.parameters.pacing = 'medium'

            const random = Math.floor((Math.random() * 2) + 1)

            this.parameters.pacing = random == 1 ? 'medium' : 'fast'
        }

        else if (ensemble == "Trap Ensemble" || ensemble == "Old School Ensemble") {
            if (!this.parameters.key.includes("minor")) {
                this.parameters.key = 'any minor'
            }
        }
    }

    changeDuration(duration) {
        if (!this.showDuration(duration)) {
            var error = "Subscribe to access more durations."

            if (this.subscription.plan == "pro_monthly" || this.subscription.plan == "standard_monthly") {
                error = "Subscribe to any Annual Plan to access more durations."
            }

            this.apiService.subscriptionError.next(error)
        }

        else {
            this.parameters.duration = duration
            this.durationList = false
        }
    }

    changeNumberOfCompositions(numberOfCompositions) {
        this.parameters.numberOfCompositions = numberOfCompositions
        this.numberOfCompositionsList = false
    }

    changePacing(pacing) {
        this.parameters.pacing = pacing
        this.pacingList = false
    }

    presetName(selectedPreset) {
        var result = selectedPreset.replace("_", " ")
        result = result.replace("_", " ")

        return result
    }

    showTimeSignature() {
        if (this.parameters.pacing == "auto") {
            this.timeSignatureList = !this.timeSignatureList
        }
    }

    showPacing() {
        if (this.parameters.timeSignature == "auto") {
            this.pacingList = !this.pacingList
        }
    }

    isFreeUser() {
        if (this.subscription.plan == "free") {
            return true
        }

        return false
    }

    getBPMRange(index) {
        if (this.compositionService.parameters[this.selectedPreset] == null){
            return []
        }

        return this.compositionService.parameters[this.selectedPreset]['bpmRanges'][index]
    }

    showDuration(duration) {
        const validDurations = CompositionService.getValidDurations(this.subscription)

        if (!validDurations.includes(duration)) {
            return false
        }

        return true
    }

    resetCustomizePreset(){
        this.keyItems = []
        this.timeSignatureItems = []
        this.pacingItems = []
        this.ensembleItems = []
        this.durationItems = []
        this.numberOfCompositionsItems = []
    }

    getEmojiSVG(emotion){
        let emojiSVG = null
        let base = "assets/img/emojis/"

        emojiSVG = base + this.generalService.getEmojiNameByEmotionID(emotion) + ".svg"

        return emojiSVG
    }

    ngOnDestroy(){
        this.modalService.customizePreset.next(false)
        this.modalService.presetUsecase.next(null)
        this.modalService.displayCreateModalMenus.next(true)
    }
}
