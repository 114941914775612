import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from "@angular/core"

@Component({
    selector: "radio-button",
    templateUrl: "./radio-button.component.html",
    styleUrls: ["./radio-button.component.scss"],
})
export class RadioButtonComponent implements OnInit {
    @Input("type") type: string = "radio"
    @Input("class") class: string
    @Input("id") id: string
    @Input("groupName") groupName: string
    @Input("value") value: string
    @Input("label") label: string
    @Input("description") description: string
    @Input("selectedValue") selectedValue: string
    @Input("labelList") labelList: Array<string> = []
    @Input("imageLabelList") imageLabelList: Array<string> = []

    checked: boolean = false
    showDescription: boolean = false

    @Output() onSelect = new EventEmitter<any>()

    @ViewChild("radioButton") radioButton: ElementRef

    constructor(private ref: ChangeDetectorRef) {}

    ngOnInit() {
        this.checked = this.selectedValue == this.value
        this.showDescription =
            this.description != null && this.description != ""
    }

    select() {
        if (this.type == "checkbox") {
            this.checked = !this.checked
        }

        this.onSelect.emit({
            id: this.id,
            value: this.value,
            groupName: this.groupName,
            label: this.label,
            description: this.description,
            checked: this.checked,
        })
    }
}
