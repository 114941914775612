import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    GridDimensionsDictionary,
    CanvasType,
    GridCoordinates,
    Scrollbar,
    ScoreCanvasContext,
} from "../types"
import ScoreCanvas from "./score-canvas"
import { cloneDeep } from "lodash"
import { SRActionTypes } from "../states/score-rendering/score-rendering.actions"
import { Coordinates } from "../../../general/modules/event-handlers"
import { HoveringTypeEnum } from "../../../general/types/general"
import PercussionLayer from "../../../general/classes/score/percussionlayer"
import {
    PITCH_CONTINOUS_COUNT,
    PITCH_SCALE_COUNT,
} from "../../../general/constants/constants"
import { Note } from "../../../general/classes/score/note"

export class VerticalScrollingCanvas extends ScoreCanvas {
    constructor(
        public context: ScoreCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)

        this.initListeners()
    }

    protected initListeners() {
        this.mouseDown$.subscribe((event: Coordinates) => {
            const scrollbar = this.getVerticalScrollbar()

            if (this.isInVerticalScrollbarBoundaries(scrollbar, event)) {
                const now = {
                    scrollToTimestep: this.scrollToTimestep,
                    pixels: event,
                    grid: {
                        timesteps: 0,
                        ysteps: (event.y * Note.highestNote) / this.height,
                    },
                }

                this._mouseDownStart = {
                    start: now,
                    last: cloneDeep(now),
                    mouseDownLocation: HoveringTypeEnum.CENTER,
                }
            }
        })

        this.mouseMove$.subscribe((event: Coordinates) => {
            if (!this._mouseDownStart) {
                return
            }

            const ysteps = (event.y * Note.highestNote) / this.height
            const scrollToPitchsteps =
                this.scrollToPitchsteps +
                (ysteps - this.mouseDownStart.last.grid.ysteps)

            this.mouseDownStart.last = {
                scrollToTimestep: this.scrollToTimestep,
                pixels: event,
                grid: {
                    timesteps: 0,
                    ysteps: ysteps,
                },
            }

            this.srEmitter$.next({
                type: SRActionTypes.setScroll,
                data: {
                    scrollToPitchsteps,
                    scrollToTimestep: this.scrollToTimestep,
                    width: this.width,
                },
            })
        })

        this.mouseUp$.subscribe(() => {
            this._mouseDownStart = undefined
        })
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        if (
            !scoreState.score ||
            !scoreState.toggledLayer ||
            !this.shouldRenderCanvas(scoreState.renderingType)
        ) {
            return
        }

        if (
            this.queries.scoreRendering.toggledLayer instanceof PercussionLayer
        ) {
            throw "VerticalLayer doesn't currently support Percussion Layers"
        }

        const pitchCount =
            grid[this.gridType].pitchStepDomain === "scale"
                ? PITCH_SCALE_COUNT
                : PITCH_CONTINOUS_COUNT

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: grid,
            noteRes: grid[this.gridType].timestepRes,
            nbOfYValues: pitchCount,
        })

        if (scoreState.pitchStepDomain === "continuous") {
            this.generateScrollbar()
        }
    }

    protected getVerticalScrollbar(): Scrollbar {
        return {
            start: this.scrollToPitchsteps,
            end: this.scrollToPitchsteps + this.nbOfYValues,
        }
    }

    protected isInVerticalScrollbarBoundaries(
        scrollbar: Scrollbar,
        event: Coordinates
    ) {
        const ysteps =
            (event.y * (Note.highestNote - Note.lowestNote)) / this.height

        return scrollbar.start <= ysteps && ysteps <= scrollbar.end
    }

    private generateScrollbar() {
        const ctx = this.getContext("canvas")

        const width = 7
        const height =
            (this.height * this.nbOfYValues) /
            (Note.highestNote - Note.lowestNote)
        const x = this.width - width - 1
        const y =
            (this.height * this.scrollToPitchsteps) /
            (Note.highestNote - Note.lowestNote)

        ScoreCanvas.roundRect(ctx, x, y, width, height, 3, "white")
        ctx.fill()
    }
}
