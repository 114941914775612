export class Fraction {
    private _numerator: number
    private _denominator: number

    public static readonly ZERO = new Fraction("0")

    public get numerator(): number {
        return this._numerator
    }

    public get denominator(): number {
        return this._denominator
    }

    constructor(fractionString: string) {
        try {
            const [numerator, denominator] = fractionString.split("/")
            this._numerator = Number(numerator)
            this._denominator = denominator ? Number(denominator) : 1
        } catch (e) {
            throw new Error(`Invalid fraction string: ${fractionString}`)
        }
    }

    static fromFraction(numerator: number, denominator: number): Fraction {
        return new Fraction(`${numerator}/${denominator}`)
    }

    public toDecimal(): number {
        return this._numerator / this._denominator
    }

    public toString(): string {
        return `${this._numerator}/${this._denominator}`
    }
}
