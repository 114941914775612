import { Injectable } from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import { DeviceDetectorService } from "ngx-device-detector"
import SearchItem from "@common-lib/classes/searchitem"
import { ApiService } from "../api.service"
import { BillingService } from "../billing.service"
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject"
import { GeneralStore } from "./general.store"
import { GeneralQuery } from "./general.query"

@Injectable()
export class GeneralService {
    private readonly store = new GeneralStore()
    public readonly query = new GeneralQuery(this.store)

    downloadsLeft: BehaviorSubject<number> = new BehaviorSubject<number>(0)
    bonusDownloadsLeft: BehaviorSubject<number> = new BehaviorSubject<number>(0)

    constructor(
        private device: DeviceDetectorService,
        private apiService: ApiService
    ) {}

    public containsFiles(event) {
        if (event.dataTransfer.types) {
            for (var i = 0; i < event.dataTransfer.types.length; i++) {
                if (event.dataTransfer.types[i] == "Files") {
                    return true
                }
            }
        }

        return false
    }

    public async getDownloadsLeft() {
        try {
            let res = await this.apiService.authRequest(
                "/user/getDownloadsLeft",
                {},
                "primary",
                true
            )
            this.bonusDownloadsLeft.next(res.bonusDownloadsLeft)
            this.downloadsLeft.next(res.downloadsLeft)
        } catch (e) {
            this.apiService.handleError(e)
        }
    }

    public getEmojiNameByEmotionID(emotion, capitalize = false) {
        let name = "auto"

        if (emotion == 1) {
            name = "calm"
        } else if (emotion == 2) {
            name = "excited"
        } else if (emotion == 3) {
            name = "fearful"
        } else if (emotion == 4) {
            name = "sad"
        } else if (emotion == 5) {
            name = "tension"
        }

        if (capitalize) {
            return Misc.capitalizeFirstLetter(name)
        }

        return name
    }

    public getDeviceSpecificClass() {
        const browser = this.getBrowser()
        const os = this.device.os == null ? "" : this.device.os.toLowerCase()
        const device = this.device.isMobile() ? "mobile" : "desktop"

        return device + " " + os + " " + browser
    }

    public getBrowser() {
        return this.device.browser == null
            ? ""
            : this.device.browser.toLowerCase()
    }

    public isMobile() {
        return this.device.isMobile()
    }

    public getImpulseResponses(): string[] {
        return this.query.reverbs.map(r => r.name)
    }
}
