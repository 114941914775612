import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core"
import {
    LAYERS,
    MAX_TEMPO,
    MIN_GP_TEMPO,
} from "@common-lib/constants/constants"
import { LayersValue } from "@common-lib/types/general"
import { CompositionWorkflowService } from "@services/composition-workflow/composition-workflow.service"
import { CWActionsType } from "@services/composition-workflow/cw-store/cw.actions"
import { ModalService } from "@services/modal.service"
import { InitCanvases } from "../../../../modules/init-canvases.module"
import { ParentClass } from "../../../../parent"
import Layer from "@common-lib/classes/score/layer"
import { SRActionTypes } from "../../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { TutorialService } from "@services/tutorial.service"
import { MenuModifiers } from "../../../reusable/menu-modifiers/menu-modifiers.component"
import { ConfirmDiscardChangesService } from "@services/confirm-discard-changes.service"
import { Router } from "@angular/router"
import { UserService } from "@services/user.service"
import { CacheService } from "@services/cache.service"

@Component({
    selector: "cw-step2-part1",
    templateUrl: "./cw-step2-part1.component.html",
    styleUrls: ["./cw-step2-part1.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CWStep2Part1Component extends ParentClass {
    // use this to detect whether change detection has been completed or not
    public seed: number = Date.now()

    @Input() service: CompositionWorkflowService
    @Output() forceInit$: EventEmitter<null> = new EventEmitter()

    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    public hoveredLayer: LayersValue | undefined

    public readonly tempoOptions = {
        min: MIN_GP_TEMPO,
        max: MAX_TEMPO,
        unit: "QPM",
    }

    public chordProgressionSettings: MenuModifiers | undefined

    public get layers() {
        const layers = [
            ...new Set(
                Object.keys(this.service.engine.score.layers).concat(
                    Object.keys(this.service.query.step2.layerLoading)
                )
            ),
        ]

        const result = layers.sort((a, b) => {
            if (a === "Extra_1" && b === "Extra_2") {
                return -1
            } else if (a === "Extra_2" && b === "Extra_1") {
                return 1
            }

            return Layer.getLayerOrder(a) - Layer.getLayerOrder(b)
        })

        return result
    }

    constructor(
        private modalService: ModalService,
        private ref: ChangeDetectorRef,
        private tutorialService: TutorialService,
        private cd: ChangeDetectorRef,
        private confirmDiscard: ConfirmDiscardChangesService,
        private router: Router,
        private cache: CacheService
    ) {
        super()

        this.router.routeReuseStrategy.shouldReuseRoute = () => false
        this.router.onSameUrlNavigation = "reload"
    }

    public getTempo(): number {
        return this.service.query.step1.tempo
    }

    public setTempo(value: number): void {
        value = Math.max(MIN_GP_TEMPO, value)
        value = Math.min(
            GenerationProfile.getMaxTempo(
                this.service.query.getValue().gp.settings
            ),
            value
        )

        this.service.setTempo(value)
    }

    async ngAfterViewInit() {
        await ParentClass.waitUntilTrueForObservable(
            this.service.engine$,
            value => value !== null
        )

        await this.service.initializeCanvases({
            step: "step2-part1",
        })

        this.setupListeners()
    }

    public toggleChordProgressionSettings(event: MouseEvent, close: boolean) {
        if (close) {
            this.chordProgressionSettings = undefined

            return
        }

        this.chordProgressionSettings = {
            options: [
                {
                    text: "Style",
                    type: "dropdown-button",
                    width: "208px",
                    lineHeight: "30px",
                    data: this.service.gp.name,
                    dataSetter: (() => {
                        this.handleOpenStyleMenu()

                        this.toggleChordProgressionSettings(undefined, true)
                    }).bind(this),
                },

                {
                    text: "Tempo",
                    type: "number-input",
                    min: MIN_GP_TEMPO,
                    max: MAX_TEMPO,
                    data: this.getTempo(),
                    unit: "QPM",
                    dataSetter: (data: number) => {
                        this.setTempo(Number(data))
                        this.cd.detectChanges()
                        return data
                    },
                    avData: "tempo",
                },
            ],
            coordinates: {
                x: event.x,
                y: event.y,
            },
            width: "400px",
            title: "Settings",
        }

        this.cd.detectChanges()
    }

    public handleOpenStyleMenu() {
        if (this.service.getHistoryCount() > 0) {
            const title = "Warning!"
            const description =
                "Changing the style will discard your changes. Are you sure you want to continue?"
            this.confirmDiscard.openDoActionsBeforeModal({
                title,
                description,
                continueButtonText: "Continue",
                action: async () => {
                    return this.openStyleMenu()
                },
                cancel: () => {},
            })
        } else {
            this.openStyleMenu()
        }
    }

    private async openStyleMenu() {
        this.modalService.modals.styleModalForCW.next({
            selectGPForCW: async gp => {
                await this.changeStyle(gp)
            },
            type: this.service.query.type,
            subtitle: "Select a style for your Composition Workflow",
        })
        return Promise.resolve({ success: true })
    }

    private async changeStyle(gp) {
        this.modalService.modals.styleModalForCW.next(undefined)
        const gpID = this.service.selectRandomGP(gp)

        this.cache.set("chordProgressionCache", {
            chords: this.service.engine.score.chords,
            romanNumerals: this.service.engine.score.romanNumerals,
            notesObject: this.service.engine.score.layers.Chords.notesObject,
            keySignature: this.service.engine.score.keySignatures[0],
            timeSignature: this.service.engine.score.timeSignatures[0][1],
        })

        this.router.navigate([`/composition-workflow/stepByStep/${gpID}`])
    }

    private setupListeners() {
        this.subscribe(this.service.query.select("lastUpdate"), () => {
            this.detectChanges()
        })

        this.subscribe(
            this.service.query.select("layerToAdd"),
            this.loadLayerCanvas.bind(this)
        )

        this.subscribe(
            this.service.query.select("layerToDelete"),
            this.deleteLayerCanvas.bind(this)
        )
    }

    public async loadLayerCanvas(layer: LayersValue | undefined) {
        if (layer === undefined) {
            return
        }

        layer = layer.split("-")[0]

        if (this.service.engine.score.layers[layer] === undefined) {
            return
        }

        await InitCanvases.initLayerPreviewCanvas(
            this.service.engine.score.layers[layer],
            0,
            this.service.engine
        )

        this.service.engine.srEmitter$.next({
            type: SRActionTypes.setRenderingType,
            data: {
                type: "All",
            },
        })
    }

    public toggleLayerHover(event: MouseEvent, layer: LayersValue | undefined) {
        // @todo: improve this part to keep displaying the notes
        this.hoveredLayer = layer
    }

    private detectChanges() {
        this.ref.detectChanges()
    }

    public async deleteLayerCanvas(layer: LayersValue) {
        if (layer === undefined) {
            return
        }

        layer = layer.split("_")[0]

        this.detectChanges()

        this.service.engine.deleteCanvas("LayerPreviewCanvas_" + layer)
    }

    public edit(layer: LayersValue) {
        this.service.engine.toggleLayer(layer)

        this.hoveredLayer = undefined
    }

    public openAddLayerModal() {
        const layers = LAYERS.filter(
            (layer: LayersValue) =>
                !layer.includes("Ornament") &&
                this.service.query.gp.accompanimentLayers.find(
                    l => l.name === layer
                ) !== undefined
        )

        if (this.service?.query?.gp?.melodyLayer) {
            layers.push(this.service.query.gp.melodyLayer.name)
        }

        this.modalService.modals.selectLayer.next({
            alreadyHasLayers: Object.keys(this.service.engine.score.layers),
            supportedLayers: layers,

            done: (async (selectedLayer: string) => {
                return this.service.generateLayer({
                    layer: selectedLayer,
                    isUndoable: true,
                    prompt: "",
                })
            }).bind(this),
        })
    }

    public delete(layer: LayersValue) {
        this.service.actions.emitter$.next({
            type: CWActionsType.deleteLayer,
            data: {
                layerKey: layer,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    public getFeatureFlags() {
        return featureFlags
    }

    public getParticlesContainerElement(layer: LayersValue) {
        return document.getElementById("layer-outter-wrapper-" + layer)
    }

    public replaceTrackBus(layer: LayersValue) {
        return this.service.replaceTrackBus.bind(this.service)
    }

    public regenerateLayer(layer: LayersValue, prompt: string) {
        if (this.layerStatus(layer) === "loading") {
            return
        }

        this.hoveredLayer = undefined

        return this.service.generateLayer({
            layer: layer,
            isUndoable: true,
            prompt,
        })
    }

    public getLayerError(layer: LayersValue): string {
        return this.service.query.getValue().step2.layerLoading[layer].error
    }

    public layerStatus(layer: LayersValue): "loading" | "finished" | "error" {
        const value = this.service.query.getValue().step2.layerLoading[layer]

        if (value === undefined || value.finished) {
            return "finished"
        }

        if (!value.finished && value.error === undefined) {
            return "loading"
        }

        return "error"
    }

    public getCompositionWorkflowLayer(layer: Layer) {
        return this.service.query.step2.layers[layer.value]
    }

    public handleScroll(layer: string, $event: WheelEvent): void {
        $event.preventDefault()
        $event.stopPropagation()
        const deltaX = $event.deltaX
        const scrollToTimestep: number =
            this.service.engine.queries.scoreRendering.scrollToTimestep + deltaX
        const canvasContainerEl = document.getElementById(
            `layer-preview-${layer}`
        )
        this.service.engine.srEmitter$.next({
            type: SRActionTypes.setScroll,
            data: {
                scrollToTimestep,
                width: canvasContainerEl.clientWidth,
            },
        })
    }

    ngOnDestroy() {
        super.ngOnDestroy()
        this.service.actions.emitter$.next({
            type: CWActionsType.clearCanvases,
            data: {
                step: "step2-part1",
            },
        })
    }
}
