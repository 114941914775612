export default class LoopedFunction {
    key
    functionBinding
    msExecutionCap = null
    lastRun = 0

    constructor(key, functionBinding, msExecutionCap) {
        this.key = key
        this.functionBinding = functionBinding
        this.msExecutionCap = msExecutionCap
    }
}