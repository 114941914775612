<div class="modal-title" *ngIf="!loading">
    Generate Layer
</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div *ngIf="loading" class="loading-wrapper">
    <div class="particles">
        <div class="wrap">
            <div class="c" *ngFor="let _ of getNumberOfDots()"></div>
        </div>
    </div>

    <div class="loading-text">Composing notes, please wait</div>
</div>
