import { OnInit, Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core'
import { FolderService } from '@services/folder.service'
import { UserService } from '@services/user.service'

interface Steps {
    index:number,
    subtitle:string
}


@Component({
  	selector: 'steps',
  	templateUrl: 'steps.component.html',
  	styleUrls: ['steps.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class StepsComponent implements OnInit {
    @Input() steps:Array<Steps> = []
    @Input() currentStepIndex = 1

    constructor(private folderService:FolderService, private userService:UserService) {

    }

    ngOnInit(): void {

    }
}