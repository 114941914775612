import { Component, Input, OnInit } from "@angular/core"

@Component({
    selector: "dropdown-button",
    templateUrl: "dropdown-button.component.html",
    styleUrls: ["dropdown-button.component.scss"],
})
export class DropdownButtonComponent implements OnInit {
    @Input() title: string = ""
    @Input() class: string = ""
    @Input() width: string = "100%"
    @Input() lineHeight: string = "30px"

    constructor() {}

    ngOnInit(): void {}
}
