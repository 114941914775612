<div class="container {{ class }}">
    <div class="input-container">
        <input #radioButton type="{{ type }}" [id]="id" [name]="groupName" [value]="value" [checked]="checked" (change)="select()">
    </div>
    <label [for]="id" (click)="radioButton.value = value">
        <div class="label">
            {{ label }}
        </div>
        <div class="description" *ngIf="showDescription">
            {{ description }}
        </div>
        <div class="label-list" *ngIf="labelList.length > 0 || imageLabelList.length > 0">
            <div class="label-list-item" *ngFor="let labelListItem of labelList">{{ labelListItem }}</div>

            <div class="label-list-item" *ngFor="let imageLabelListItem of imageLabelList">
                <img src="{{ imageLabelListItem }}">
            </div>
        </div>
    </label>
</div>