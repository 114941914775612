<div class="modal-title">Sending bug report ...</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<div class="loader-container" *ngIf="loading">
    <div class="loader"></div>
</div>

<div class="success" *ngIf="status === 'success'">
    <div class="checkmark-icon" *ngIf="!loading && !error">
        <svg
            class="checkmark-green"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52">
            <circle
                class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none" />
            <path
                class="checkmark__check"
                fill="transparent"
                d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
    </div>
    <div class="success-text">
        Thank you for your report. We will look into the issue and get back to you. You can close this window now.
    </div>
</div>

<div *ngIf="status === 'error'">
    <div class="cross-icon">
        <svg
            class="cross-red"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52">
            <circle
                class="cross__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none" />
            <path
                class="cross__crossed"
                fill="none"
                d="M16 16 36 36 M36 16 16 36" />
        </svg>
    </div>
    <div class="error-text">
        An error occurred while sending the bug report. Please try again later or get back to us directly.
    </div>
</div>

