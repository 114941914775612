import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from "@angular/core"
import { ParentClass } from "../../../parent"
import PercussionLayer from "@common-lib/classes/score/percussionlayer"
import Layer from "@common-lib/classes/score/layer"
import { Effect } from "@common-lib/classes/score/effect"
import { Helpers } from "../../../modules/helpers.module"
import { InitCanvases } from "../../../modules/init-canvases.module"
import { SubscriptionHelpers } from "@common-lib/modules/subscription-helpers.module"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import { EditorService } from "@services/editor/editor.service"
@Component({
    selector: "layer-inspector",
    templateUrl: "layer-inspector.component.html",
    styleUrls: ["layer-inspector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerInspectorComponent extends ParentClass {
    @Input() editor: EditorService
    @ViewChild("layerInspectorPercussionEmpty")
    public layerInspectorPercussionEmpty: ElementRef<HTMLDivElement>

    constructor(
        private ref: ChangeDetectorRef,
        private editorService: EditorService
    ) {
        super()
    }

    public handleInspectorHover($event: MouseEvent): void {
        const rect =
            this.layerInspectorPercussionEmpty.nativeElement.getBoundingClientRect()
        this.editorService.engine.queries.scoreRendering.inspectorHovered$.next(
            {
                x: Math.max(0, $event.x - rect.x),
                y: Math.max(0, $event.y - rect.y),
                shiftKey: false,
            }
        )
    }

    async ngAfterViewInit() {
        SubscriptionHelpers.subscribeWithPrevious(
            this.editor.engine.toggledLayer$,
            this.initialiseLayer.bind(this),
            this.destroy$
        )

        this.subscribe(
            this.editor.engine.selectedAutomation$,
            (effect: Effect | undefined) => {
                this.initialiseLayer({
                    previous: undefined,
                    current: this.editor.engine.toggledLayer,
                })
            }
        )
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
    }

    private async initialiseLayer(args: {
        previous: Layer | PercussionLayer | undefined
        current: Layer | PercussionLayer | undefined
    }) {
        this.ref.detectChanges()

        if (args.previous?.value !== args.current?.value) {
            this.clearCanvases()

            if (args.current?.type === "pitched") {
                await InitCanvases.initAccompanimentDesignerCanvas(
                    this.editor.engine
                )

                await InitCanvases.initVerticalScrollingCanvas(
                    this.editor.engine
                )

                await InitCanvases.initPianoCanvas(this.editor.engine)
            }

            if (this.editor.engine.selectedAutomation !== undefined) {
                await InitCanvases.initAutomationCanvas(this.editor.engine)
            }
        }
    }

    private clearCanvases() {
        this.editor.engine.deleteCanvas("AutomationCanvas")
        this.editor.engine.deleteCanvas("PianoCanvas")
        this.editor.engine.deleteCanvas("AccompanimentDesignerCanvas")
        this.editor.engine.deleteCanvas("VerticalScrollingCanvas")
    }

    private async initialiseEffect(args: {
        previous: Effect | undefined
        current: Effect | undefined
    }) {}
}
