import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    ComponentFactoryResolver,
    ViewChild,
} from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import { AudioService } from "@services/audio/audio.service"
import { PlayerService } from "../../../services/audio/player/player.service"
import { AnimationLoopService } from "../../../services/animationloop.service"
import { DesignService } from "../../../services/design.service"
import { AudioTrimmerConfig } from "../../../types/audioTrimmer"

@Component({
    selector: "audio-trimmer",
    templateUrl: "audio-trimmer.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./audio-trimmer.component.scss"],
})
export class AudioTrimmerComponent {
    @Input() file: File

    @Input() config: AudioTrimmerConfig = {
        minDuration: 60,
        maxDuration: 330,
    }

    @Output() onError: EventEmitter<any> = new EventEmitter()

    fileMetaData = {
        name: "",
        duration: 0,
        isPlaying: false,
        currentTime: 0,
    }

    audioFileType: string
    audioBuffer: AudioBuffer
    audioCtx

    influenceAudio // : HTMLAudioElement

    percentage = 0

    loader = {
        loading: false,
        type: "analyse",
    }

    audioNeedsTrimming = false

    seekPosition = 0

    dragAudioTrimAreaListener
    dragAudioTrimAreaMouseUpListener

    maximumAudioDuration = 330 // 5:30min
    minimumAudioDuration = 60 //  1:00min

    trimAudioArea = {
        startX: 0,
        endX: 0,
        lastX: 0,
        startTime: 0,
        endTime: this.minimumAudioDuration,
        duration: this.minimumAudioDuration,
        dragType: "middle",
        width: 0,
    }

    showTrimmingWarning = false
    audioHasPartsBelowAmplitudeThreshold = false

    leftMaskWidth = 0
    rightMaskWidth = 0

    amplitudeThreshold = -35

    @ViewChild("influenceAudioCanvas") influenceAudioCanvas: ElementRef
    @ViewChild("belowAmpThresholdCanvas") belowAmpThresholdCanvas: ElementRef
    @ViewChild("influenceAudioWaveform") influenceAudioWaveform: ElementRef
    @ViewChild("dragHandleStart") dragHandleStart: ElementRef
    @ViewChild("dragHandleEnd") dragHandleEnd: ElementRef

    numberOfWaves = 150
    displayBelowAmpThresholdCanvas = true
    dragHandleWidth = 6
    seekBarWidth = 2

    constructor(
        private ref: ChangeDetectorRef,
        private animationLoopService: AnimationLoopService,
        private playerService: PlayerService,
        private designService: DesignService
    ) {}

    ngOnInit() {
        this.animationLoopService.addFunctionToLoop(
            "setSeekCursorPosition",
            this.setSeekCursorPosition.bind(this)
        )
        this.dragHandleWidth = parseInt(
            this.designService.getCSSProperty("drag-handle-width")
        )
        this.seekBarWidth = parseInt(
            this.designService.getCSSProperty("seek-bar-width")
        )

        this.influenceAudio = new Audio(URL.createObjectURL(this.file))
        this.audioFileType = this.file.name.split(".").pop().toLowerCase()
        this.fileMetaData.name = this.file.name
        this.minimumAudioDuration = this.config?.minDuration
        this.maximumAudioDuration = this.config?.maxDuration

        this.handleAudioUpload()
            .then(() => {
                this.setLoader(false)
                this.detectChanges()
            })

            .catch(err => {
                this.onError.emit(err)
            })
    }

    detectChanges() {
        this.ref.detectChanges()
    }

    async trimAndEncode(): Promise<File> {
        this.setLoader(true, "trimming")
        this.detectChanges()

        this.setPercentage(10)

        await Misc.wait(1)

        let audioBuffer: AudioBuffer =
            await AudioService.convertFileToAudioBuffer(this.file)

        this.setPercentage(40)

        let cutAudioBuffer: AudioBuffer = await AudioService.trimAudioBuffer(
            {
                start: this.trimAudioArea.startTime,
                end: this.trimAudioArea.endTime,
            },
            audioBuffer
        )

        this.setPercentage(70)

        let encodedFile: File = await AudioService.encodeAudioBuffer(
            cutAudioBuffer,
            this.file.name
        )

        this.setPercentage(100)

        return encodedFile
    }

    setPercentage(value) {
        this.percentage = value
        this.detectChanges()
    }

    getAudioInfluenceTotalWidth(includeDragHandles = false) {
        if (this.influenceAudioCanvas == null) {
            return 600
        }

        let canvasElem = this.influenceAudioCanvas.nativeElement
        let totalWidth = parseFloat(
            window.getComputedStyle(canvasElem, null).width
        )

        if (includeDragHandles == true) {
            totalWidth += 2 * this.dragHandleWidth
        }

        return totalWidth
    }

    play() {
        if (this.influenceAudio != null && this.influenceAudio.src != null) {
            this.influenceAudio.play()
            this.fileMetaData.isPlaying = true
        }

        this.detectChanges()
    }

    pause() {
        if (this.influenceAudio != null && this.influenceAudio.src != null) {
            this.influenceAudio.pause()
            this.fileMetaData.isPlaying = false
        }

        this.detectChanges()
    }

    seek(event) {
        let x = event.offsetX + this.seekBarWidth

        if (x == null) {
            return
        }

        if (this.influenceAudio != null && this.influenceAudioCanvas != null) {
            let currentTime = this.getTimeFromX(x)

            this.influenceAudio.currentTime = currentTime

            setTimeout(() => {
                this.setSeekCursorPosition(false)
            }, 0)
        }
    }

    setSeekCursorPosition(onlyWhenPlaying = true): Promise<any> {
        if (
            !this.influenceAudio ||
            (this.fileMetaData.isPlaying != true && onlyWhenPlaying == true)
        ) {
            return
        }

        this.seekPosition = this.getSeekCursorPosition()
        this.detectChanges()

        return Promise.resolve()
    }

    getSeekCursorPosition() {
        let offset = 0

        if (this.influenceAudio != null && this.influenceAudioCanvas != null) {
            let totalDuration = this.influenceAudio.duration
            let currentTime = this.influenceAudio.currentTime

            let percentage = (currentTime / totalDuration) * 100

            offset = percentage

            if (currentTime >= totalDuration) {
                this.fileMetaData.isPlaying = false
                offset = 0
            }
        }

        return offset
    }

    /**
     * returns the time of the track depending on the x position inside the waveform canvas
     * @param x number that defines where in the canvas the mouse is moving
     * @param correction time correction
     * @returns number the time that represents the x value
     */
    getTimeFromX(x = 0, includeDragHandles = false) {
        let time = 0

        if (this.influenceAudio != null && this.influenceAudioCanvas != null) {
            let totalWidth =
                this.getAudioInfluenceTotalWidth(includeDragHandles)
            let totalDuration = this.influenceAudio.duration

            let percentage = x / totalWidth

            time = totalDuration * percentage

            if (time < 0) {
                time = 0
            }
        }

        return time
    }

    getXFromTime(time, includeDragHandles = false) {
        let x = 0

        if (this.influenceAudio != null && this.influenceAudioCanvas != null) {
            let totalWidth =
                this.getAudioInfluenceTotalWidth(includeDragHandles)
            let totalDuration = this.influenceAudio.duration

            let percentage = time / totalDuration

            x = totalWidth * percentage
        }

        return x
    }

    getTrimAudioAreaWidth(totalDuration, includeDragHandles = true) {
        return (
            (this.trimAudioArea.duration / totalDuration) *
            this.getAudioInfluenceTotalWidth(includeDragHandles)
        )
    }

    getMaximumAudioDuration() {
        return this.maximumAudioDuration
    }

    getMinimumAudioDuration() {
        return this.minimumAudioDuration
    }

    startDraggingTrimArea(event, type = "middle") {
        event.stopImmediatePropagation()

        this.trimAudioArea.dragType = type
        this.trimAudioArea.startX = this.getXFromTime(
            this.trimAudioArea.startTime,
            true
        )
        this.trimAudioArea.endX = this.getXFromTime(
            this.trimAudioArea.endTime,
            true
        )
        this.trimAudioArea.lastX = event.x

        if (this.dragAudioTrimAreaListener != null) {
            window.document.removeEventListener(
                "mousemove",
                this.dragAudioTrimAreaListener
            )
        }

        if (this.dragAudioTrimAreaMouseUpListener != null) {
            window.document.removeEventListener(
                "mouseup",
                this.dragAudioTrimAreaMouseUpListener
            )
        }

        this.dragAudioTrimAreaListener =
            this.continueDraggingTrimAudioArea.bind(this)
        this.dragAudioTrimAreaMouseUpListener =
            this.stopDraggingTrimAudioArea.bind(this)

        window.document.addEventListener(
            "mousemove",
            this.dragAudioTrimAreaListener
        )
        window.document.addEventListener(
            "mouseup",
            this.dragAudioTrimAreaMouseUpListener
        )
    }

    continueDraggingTrimAudioArea(event) {
        if (event == null) {
            this.stopDraggingTrimAudioArea(event)

            return
        }

        let type = this.trimAudioArea.dragType

        let totalDuration = this.influenceAudio.duration
        let totalWidth = this.getAudioInfluenceTotalWidth(true)
        let difference = event.x - this.trimAudioArea.lastX

        if (type == "middle") {
            let newStartX = this.trimAudioArea.startX + difference

            if (newStartX + this.trimAudioArea.width > totalWidth) {
                newStartX = totalWidth - this.trimAudioArea.width
            }

            if (newStartX < 0) {
                newStartX = 0
            }

            this.trimAudioArea.startX = newStartX
            this.trimAudioArea.startTime =
                (this.trimAudioArea.startX / totalWidth) * totalDuration
            this.trimAudioArea.endTime =
                this.trimAudioArea.startTime + this.trimAudioArea.duration
            this.trimAudioArea.lastX = event.x
        } else if (type == "start") {
            this.showTrimmingWarning = false

            let startX = this.trimAudioArea.startX + difference
            let startTime = this.getTimeFromX(startX, true)

            if (startX <= 0) {
                startX = 0
                startTime = 0
            }

            if (
                this.trimAudioArea.endTime - startTime >=
                this.getMaximumAudioDuration()
            ) {
                startTime =
                    this.trimAudioArea.endTime - this.getMaximumAudioDuration()
                startX = this.getXFromTime(startTime, true)
                this.showTrimmingWarning = true
            } else if (
                this.trimAudioArea.endTime - startTime <=
                this.getMinimumAudioDuration()
            ) {
                startTime =
                    this.trimAudioArea.endTime - this.getMinimumAudioDuration()
                startX = this.getXFromTime(startTime, true)
                this.showTrimmingWarning = true
            } else {
                this.trimAudioArea.lastX = event.x
            }

            let duration = this.trimAudioArea.endTime - startTime

            this.trimAudioArea.startX = startX
            this.trimAudioArea.startTime = startTime
            this.trimAudioArea.duration = duration
            this.trimAudioArea.startX = this.trimAudioArea.startX
            this.trimAudioArea.width = this.getTrimAudioAreaWidth(
                this.fileMetaData.duration
            )
        } else if (type == "end") {
            this.showTrimmingWarning = false

            let endX = this.trimAudioArea.endX + difference
            let endTime = this.trimAudioArea.endTime

            endTime = this.getTimeFromX(endX, true)

            if (endTime >= totalDuration) {
                endTime = totalDuration
            }

            if (
                endTime - this.trimAudioArea.startTime >=
                this.getMaximumAudioDuration()
            ) {
                endTime =
                    this.trimAudioArea.startTime +
                    this.getMaximumAudioDuration()
                endX = this.getXFromTime(endTime, true)
                this.showTrimmingWarning = true
            } else if (
                endTime - this.trimAudioArea.startTime <=
                this.getMinimumAudioDuration()
            ) {
                endTime =
                    this.trimAudioArea.startTime +
                    this.getMinimumAudioDuration()
                endX = this.getXFromTime(endTime, true)
                this.showTrimmingWarning = true
            } else {
                this.trimAudioArea.lastX = event.x
            }

            let duration = endTime - this.trimAudioArea.startTime

            this.trimAudioArea.endX = endX
            this.trimAudioArea.endTime = endTime
            this.trimAudioArea.duration = duration
            this.trimAudioArea.width = this.getTrimAudioAreaWidth(
                this.fileMetaData.duration
            )
        }

        this.computeMaskWidth()

        setTimeout(() => {
            this.computeMaskWidth() // this makes sure that the masks will be computed correctly even when dragging very fast
        }, 0)

        event.stopPropagation()

        this.detectChanges()
    }

    stopDraggingTrimAudioArea(event) {
        event.stopPropagation()

        this.showTrimmingWarning = false

        window.document.removeEventListener(
            "mousemove",
            this.dragAudioTrimAreaListener
        )
        window.document.removeEventListener(
            "mouseup",
            this.dragAudioTrimAreaMouseUpListener
        )
    }

    resetTrimAudioArea(resetTime = false) {
        let start = resetTime ? 0 : this.trimAudioArea.startTime
        let end = resetTime
            ? this.getMinimumAudioDuration()
            : this.trimAudioArea.endTime
        let width = resetTime ? 0 : this.trimAudioArea.width

        this.trimAudioArea = {
            startX: 0,
            endX: this.trimAudioArea.width,
            lastX: 0,
            startTime: start,
            endTime: end,
            duration: end - start,
            dragType: "middle",
            width: width,
        }
    }

    cancelAudioTrimming() {
        if (this.influenceAudio != null && this.influenceAudio.src != null) {
            this.influenceAudio.pause()
            this.influenceAudio = null

            this.fileMetaData.isPlaying = false
            this.trimAudioArea.width = 0
            this.trimAudioArea.startX = 0
            this.audioBuffer = null

            this.audioCtx = null
            this.resetTrimAudioArea(true)

            this.resetLoader()
            this.seekPosition = 0
            this.audioHasPartsBelowAmplitudeThreshold = false
            this.detectChanges()
        }
    }

    getFormattedTime(seconds) {
        let formattedTime = new Date(seconds * 1000).toISOString().substr(14, 5)

        if (seconds > 3600) {
            formattedTime =
                new Date(seconds * 1000).toISOString().substr(11, 8) + " h"
        }

        return formattedTime
    }

    getNumberOfWaves() {
        let numberOfWaves = 150

        if (this.influenceAudioCanvas != null) {
            numberOfWaves = Math.round(this.getAudioInfluenceTotalWidth() / 4)
        }

        return numberOfWaves
    }

    computeMaskWidth() {
        let leftMaskWidth = this.trimAudioArea.startX + this.dragHandleWidth
        let rightMaskWidth =
            this.getAudioInfluenceTotalWidth(true) -
            this.trimAudioArea.startX -
            this.trimAudioArea.width

        if (leftMaskWidth < 0.5) {
            leftMaskWidth = 0
        }

        if (rightMaskWidth < 0.5) {
            rightMaskWidth = 0
        }

        this.leftMaskWidth = leftMaskWidth
        this.rightMaskWidth = rightMaskWidth
    }

    /**
     * devides the raw audio buffer into a given number of divisions and creates an array
     * out of the computed average absolute amplitude value for each division
     * @param audioBuffer source of the raw audio data
     * @param samples number of divisions
     * @returns Array of avg abs amplitude value for each division
     */
    getFilteredDataFromAudioBuffer(audioBuffer, samples = 150) {
        const rawData = audioBuffer.getChannelData(0)
        const blockSize = Math.floor(rawData.length / samples) // Number of samples in each subdivision
        const filteredData = []

        for (let i = 0; i < samples; i++) {
            let blockStart = blockSize * i // the location of the first sample in the block
            let sum = 0

            for (let j = 0; j < blockSize; j++) {
                let absoluteAmpValue = Math.abs(rawData[blockStart + j])
                sum += absoluteAmpValue
            }

            filteredData.push(sum / blockSize) // get the average amplitude value for each block
        }

        return filteredData
    }

    /**
     * This function finds the largest data point in the array with Math.max(), takes its inverse with Math.pow(n, -1)
     * and multiplies each value in the array by that number.
     * This guarantees that the largest data point will be set to 1, and the rest of the data will scale proportionally.
     * @param filteredData Array
     * @returns
     */
    normalizeFilteredData(filteredData) {
        // the multiplier is what we multiply the biggest value out of filteredData by to get 1
        let multiplier = Math.pow(Math.max.apply(null, filteredData), -1)

        // make sure that really quiet songs don't get boosted by too much and actually appear to be silent
        if (multiplier > 100) {
            multiplier = 1
        }

        return filteredData.map(n => n * multiplier)
    }

    getPartsBelowAmplitudeThreshold(
        audioChunks,
        numberOfConsecutiveValues = 30
    ): Array<any> {
        let partsBelowAmpThreshold = []
        let tempConsecutiveIndices = []
        let correctionForAverage = 2
        numberOfConsecutiveValues -= correctionForAverage

        for (let i = 0; i < audioChunks.length; i++) {
            let chunk = audioChunks[i]
            let dbfs = 20 * Math.log10(chunk)

            if (dbfs < this.amplitudeThreshold) {
                tempConsecutiveIndices.push(i)
            } else {
                // save what has been found so far if the limit is reached
                if (
                    tempConsecutiveIndices.length >= numberOfConsecutiveValues
                ) {
                    for (
                        let tci = 0;
                        tci < tempConsecutiveIndices.length;
                        tci++
                    ) {
                        partsBelowAmpThreshold.push(tempConsecutiveIndices[tci])
                    }
                }

                tempConsecutiveIndices = [] // reset
            }
        }

        return partsBelowAmpThreshold
    }

    drawLineSegment(ctx, x, y, strokeStyle = "#fff") {
        if (y == 0) {
            y = 1 // make silent parts visible
        }

        ctx.lineWidth = 1 // how thick the line is
        ctx.strokeStyle = strokeStyle // what color our line is
        ctx.beginPath()
        ctx.moveTo(x, -y)
        ctx.lineTo(x, y)
        ctx.stroke()
    }

    drawWaveForm(audioBuffer, numberOfLines = null) {
        const canvas = this.influenceAudioCanvas.nativeElement
        const dpi = window.devicePixelRatio || 1

        if (numberOfLines == null) {
            numberOfLines = canvas.clientWidth * dpi
        }

        let filteredData = this.getFilteredDataFromAudioBuffer(
            audioBuffer,
            numberOfLines
        )
        let normalizedData = this.normalizeFilteredData(filteredData)

        // Set up the canvas
        const padding = 20
        canvas.width = this.getAudioInfluenceTotalWidth() * dpi
        canvas.height = (canvas.offsetHeight + padding * 2) * dpi
        const ctx = canvas.getContext("2d")
        ctx.canvas.width = canvas.width
        ctx.canvas.height = canvas.height
        ctx.scale(dpi, dpi)
        ctx.translate(0, canvas.offsetHeight / 2 + padding) // Set Y = 0 to be in the middle of the canvas

        // draw the line segments
        const width = canvas.offsetWidth / normalizedData.length

        for (let i = 0; i < normalizedData.length; i++) {
            const x = width * i
            let height = normalizedData[i] * canvas.offsetHeight - padding

            if (height < 0) {
                height = 0
            } else if (height > canvas.offsetHeight / 2) {
                height = canvas.offsetHeight / 2
            }

            this.drawLineSegment(ctx, x, height)
        }
    }

    drawBelowAmpThresholdAreas(audioBuffer, numberOfLines = null) {
        this.audioHasPartsBelowAmplitudeThreshold = false

        const duration = audioBuffer.duration // duration in seconds
        const filteredData = this.getFilteredDataFromAudioBuffer(
            audioBuffer,
            Math.round(duration)
        ) // get one array entry per second of the audio
        const partsBelowAmpThreshold =
            this.getPartsBelowAmplitudeThreshold(filteredData)
        const canvas = this.belowAmpThresholdCanvas.nativeElement
        const dpi = window.devicePixelRatio || 1

        if (partsBelowAmpThreshold.length == 0) {
            return
        }

        this.audioHasPartsBelowAmplitudeThreshold = true

        if (numberOfLines == null) {
            numberOfLines = canvas.clientWidth * dpi
        }

        // Set up the canvas
        canvas.width = this.getAudioInfluenceTotalWidth() * dpi
        canvas.height = canvas.offsetHeight * dpi
        const ctx = canvas.getContext("2d")
        ctx.canvas.width = canvas.width
        ctx.canvas.height = canvas.height
        ctx.scale(dpi, dpi)

        // draw the line segments
        const pxCorrection = 1 // without it the area is not smoothed out
        const width = canvas.width / filteredData.length

        for (let i = 0; i < partsBelowAmpThreshold.length; i++) {
            const x = width * partsBelowAmpThreshold[i] + pxCorrection
            const y = canvas.offsetHeight

            ctx.lineWidth = width + pxCorrection // how thick the line is
            ctx.strokeStyle = "#f00" // what color our line is
            ctx.beginPath()
            ctx.moveTo(x, 0)
            ctx.lineTo(x, y)
            ctx.stroke()
        }
    }

    async computeAudioCanvas(audioFile, audioCtx): Promise<boolean> {
        let audioBuf = await AudioService.convertFileToAudioBuffer(audioFile)

        this.audioBuffer = audioBuf
        this.numberOfWaves = this.getNumberOfWaves()

        this.drawWaveForm(this.audioBuffer, this.numberOfWaves)
        this.drawBelowAmpThresholdAreas(this.audioBuffer, this.numberOfWaves)
        this.setLoader(false)
        this.computeMaskWidth()

        return false
    }

    showTrimmingAudioEditor() {
        return this.loader.loading == false && this.audioNeedsTrimming == true
    }

    getAudioMetaData(audioElement = this.influenceAudio) {
        return new Promise((resolve, reject) => {
            audioElement.onloadedmetadata = () => {
                resolve(audioElement)
            }
        })
    }

    handleAudioUpload() {
        this.setLoader(true, "analyse")

        this.audioNeedsTrimming = false

        return Promise.all([
            AudioService.initInfluenceAudio(this.file),
            AudioService.initInfluenceAudio(this.file),
        ])
            .then(audioElements => {
                this.influenceAudio = audioElements[0]
                let secondAudio = audioElements[1] as HTMLMediaElement
                this.audioCtx = AudioService.createAudioCtx()
                this.audioCtx.createMediaElementSource(secondAudio)

                return this.getAudioMetaData()
            })

            .then(() => {
                let totalDuration = this.influenceAudio.duration

                this.fileMetaData.duration = totalDuration

                if (totalDuration > 20 * 60) {
                    return Promise.reject(
                        "Your audio file needs to be less than 20 minutes long"
                    )
                }

                if (totalDuration < 60) {
                    return Promise.reject(
                        "Your audio file needs to be at least 60 seconds long"
                    )
                }

                this.trimAudioArea.endTime = Math.min(
                    this.fileMetaData.duration,
                    this.getMaximumAudioDuration()
                )
                this.trimAudioArea.duration = this.trimAudioArea.endTime
                this.trimAudioArea.width =
                    this.getTrimAudioAreaWidth(totalDuration)

                // Here we have the following options...
                // 1   the file has valid duration and is mp3 -> just upload
                // 2   the file has valid duration and is wav -> encode and upload
                // 3   the file is longer than allowed and is either mp3 or something else -> show audio trimming tool

                this.audioNeedsTrimming = true

                this.playerService.pause()

                return this.computeAudioCanvas(this.file, this.audioCtx)
            })
    }

    setLoader(loading, type = null) {
        this.loader = {
            loading: loading == true,
            type: type,
        }
    }

    resetLoader() {
        this.setLoader(false, null)
    }
}
