import { Component, ElementRef, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core"
import { DesignService } from "@services/design.service"
import { Effect } from "@common-lib/classes/score/effect"

@Component({
    selector: 'range',
    templateUrl: 'range.component.html',
    styleUrls: ['range.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeComponent implements OnInit {
    @Input() minDifference = 0

    @Input() class: string
    @Input() type: string

    @Input() min: number
    @Input() max: number

    @Input() minMax: number = null

    @Input() minLimit: number
    @Input() maxLimit: number

    @Input() dropdownOptions:Array<object> = []

    @Output() valueChanges = new EventEmitter<any>()

    constructor(private ref:ChangeDetectorRef, private designService: DesignService) {

    }

    ngOnInit() {
        if (this.class == null) {
            this.class = ''
        }
    }
    
    ngAfterViewInit() {

    }

    ngOnDestroy() {

    }

    getMaxDropDownValue() {
        return {
            value: this.max,
            name: Effect.dynamicCorrespondence[this.max]
        }
    }

    getMinDropDownValue() {
        return {
            value: this.min,
            name: Effect.dynamicCorrespondence[this.min]
        }
    }

    selectedDropdownItem(event, type) {
        if (type == "min") {
            this.min = parseInt(event.new.value)
        }

        else {
            this.max = parseInt(event.new.value)
        }

        this.handleKeyboardEvent(type)

        this.valueChanges.emit({ min: this.min, max: this.max })

        this.ref.detectChanges()
    }

    handleKeyboardEvent(type) {
        if (type == "min") {
            this.handleMaxInput()
            this.handleMinInput()
        }

        else {
            this.handleMinInput()
            this.handleMaxInput()
        }

        this.valueChanges.emit({ min: this.min, max: this.max })

        this.ref.detectChanges()
    }

    handleMinInput() {
        if (this.minDifference > 0) {
            this.min = Math.round(Math.max(this.minLimit, Math.min(this.min, this.max - this.minDifference)) / this.minDifference) * this.minDifference
        }       
        
        else {
            this.min = Math.max(this.minLimit, Math.min(this.min, this.max - this.minDifference))
        }
        
    }

    handleMaxInput() {
        if (this.minDifference > 0) {
            this.max = Math.floor(Math.min(this.maxLimit, Math.max(this.max, this.min + this.minDifference)) / this.minDifference) * this.minDifference 
        }

        else {
            this.max = Math.min(this.maxLimit, Math.max(this.max, this.min + this.minDifference))
        }

        if (this.minMax != null) {
            this.max = Math.max(this.max, this.minMax)
        }
    }
}
