import { Injectable } from "@angular/core"
import { BehaviorSubject } from 'rxjs'
import { ApiService } from './api.service'
import { ModalService } from './modal.service'
import { environment } from "../../environments/environment"
import { DatePipe } from '@angular/common'
import { HelperService } from "../helpers/helper.service"
import * as braintree from 'braintree-web';
import { GeneralService } from "./general/general.service"

@Injectable()
export class BillingService {
    stripeKey = ""
    stripe
    braintreeKey = ""
    braintree
    braintreeApi = braintree
    elements
    paymentDetails:BehaviorSubject<any> = new BehaviorSubject<any>({})
    subscription:BehaviorSubject<any> = new BehaviorSubject<any>({})
    menu:string = 'plans'
    subscribeThroughPaymentMethod:BehaviorSubject<string> = new BehaviorSubject<string>('')

    lastTimeSubscriptionWasFetched = 0

    subscriptionLoading:BehaviorSubject<any> = new BehaviorSubject<any>({
        'free': false,
        'standard_monthly': false,
        'pro_monthly': false,
        'standard_annually': false,
        'pro_annually': false
    })

    selectedPaymentMethod = "credit-card"    // used for payment method selector
    defaultPaymentMethod = "credit-card"    // used for billing preview and subscriptions
    activePaymentMethod = ""                // used to display the currently active payment method save in the user Object
    walletPayIsSetup = false
    countries:BehaviorSubject<any> = new BehaviorSubject<any>(null)

    constructor(private apiService:ApiService, private modalService:ModalService, private helper: HelperService, private generalService:GeneralService) {

    }

    getBraintreeToken(): Promise<any> {
        return this.apiService.authRequest('/billing/getBraintreeToken', {}, "primary", true).then((res) => {
            return Promise.resolve(res.clientToken)
        }).catch((err) => {
            this.apiService.handleError(err)
            return Promise.resolve(undefined)
        })
    }

    getStripeKey() {
        this.stripeKey = environment.stripe
        this.stripe = Stripe(this.stripeKey)
        this.elements = this.stripe.elements()

        this.getPaymentDetails()
    }

    /**
     * initializes the Braintree Client SDK and resolves when done
     */
    initBraintree() {
        return this.getBraintreeToken().then((token) => {
            this.braintreeKey = token

            return braintree.client.create({
                authorization: this.braintreeKey
            })
        })
            
        .then((client) => {
            this.braintree = client
            return Promise.resolve(true)
        })
    }

    getBraintreeDeviceData(){
        return new Promise((resolve, reject) => {
            this.helper.loadJSScript("https://js.braintreegateway.com/web/3.69.0/js/data-collector.min.js").then(() => {
                return braintree.dataCollector.create({
                    client: this.braintree,
                    paypal: true
                }).then((dataCollectorInstance) => {
                    resolve(dataCollectorInstance.deviceData)
                });
            })
        })
    }

    quotaLimitReached():boolean {
        if (this.generalService.downloadsLeft.getValue() > 0) {
            return false
        }

        return true
    }

    setPaymentDetails(result, fullName, vat, paymentMethod = 'credit-card'):Promise<any> {
        var promise

        if (result.error) {
            promise = Promise.reject(result.error.message)
        }
        else {
            promise = this.apiService.authRequest('/billing/setPaymentDetails', { paymentDetailsToken: result.token, plan: null, fullName: fullName, vat: vat, paymentMethod }, "primary", true)
        }
                
        return promise.then((res) => {
            if (res["subscription"] != null) {
                this.subscription.next(res["subscription"])
            }
            
            this.getPaymentDetails()

            return Promise.resolve()
        })

        .catch((err) => {
            this.apiService.handleError(err)

            return Promise.reject(err)
        })
    }

    getPaymentDetails():Promise<any> {
        return this.apiService.authRequest('/billing/getPaymentDetails', {}, "primary", true).then((res) => {
            this.paymentDetails.next(res)
            return Promise.resolve()
        })

        .catch((err) => {
            this.apiService.handleError(err)

            return Promise.resolve()
        })
    }

    isVATExempt() {
        return this.apiService.authRequest('/billing/isVATExempt', { }, "primary", true).then((res) => {
            return Promise.resolve(res.vatExempt)
        })
    }

    subscribe(plan, paymentMethod = "credit-card"):Promise<any> {              
        return this.apiService.authRequest('/billing/subscribe', { plan, paymentMethod }, "primary", true).then((res) => {
            if (paymentMethod != "paypal" && res.clientSecret != null) {
                return this.secureAuthentication(res.clientSecret, res.subscription)
            }

            this.subscription.next(res["subscription"])

            return Promise.resolve(res)
        })

        .then((res) => {
            if (res["subscription"].plan.includes("pro")) {
                this.generalService.downloadsLeft.next(300)
            }

            else if (res["subscription"].plan.includes("standard")) {
                this.generalService.downloadsLeft.next(15)
            }

            return Promise.resolve(res)
        })
    
        .catch((err) => {
            this.apiService.handleError(err)

            return Promise.reject(err)
        })
    }

    secureAuthentication(paymentIntentSecret, subscription):Promise<any> {
        return this.stripe.handleCardPayment(paymentIntentSecret).then((result) => {
            if (result.error) {
                this.apiService.authRequest('/billing/authenticationError', { subscriptionID: subscription.id }, "primary", true)

                if (result.error.type == "card_error") {
                    return Promise.reject(result.error.message)
                }

                return Promise.reject("3D Secure Authentication failed. Please try again or contact the Support team.")
            } 
                
            return this.apiService.authRequest('/billing/update', { subscriptionID: subscription.id }, "primary", true)
        })

        .then((res) => {
            this.subscription.next(res["subscription"])

            return Promise.resolve()
        })
    }

    getSubscription(res) {
        this.subscription.next(res["subscription"])
        this.generalService.downloadsLeft.next(res["downloadsLeft"])
        this.generalService.bonusDownloadsLeft.next(res["bonusDownloadsLeft"])
    }

    getPlanType() {
        return this.subscription.getValue().plan
    }

    preview(subscriptionType) {
        return this.apiService.authRequest('/billing/preview', { newPlan: subscriptionType, paymentMethod: this.defaultPaymentMethod }, "primary", true).then((res) => {     
            return Promise.resolve(res)
        })
        .catch((err) => {
            this.apiService.handleError(err)
            return Promise.resolve(null)
        })
    }

    previewBilling(subscriptionType) {
        this.setSubscriptionLoading(true, subscriptionType)

        this.preview(subscriptionType).then((res) => {
            this.setSubscriptionLoading(false, subscriptionType)
            
            if (res == null) { return }

            this.modalService.billingPreview.next({ preview: res.preview, plan: subscriptionType, originalPlan: this.subscription.getValue().plan })
        })
    }

    createStripeCustomer( userEmail, fullName, stripeToken ){
        return new Promise((resolve, reject) => {
    
            this.apiService.authRequest('/billing/createStripeCustomer', { stripeToken, userEmail, userName: fullName }, "primary", true)
            .then((res) => {
                resolve(res.customerID)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }

    setSubscriptionLoading(value, type) {
        var subscriptionLoading = this.subscriptionLoading.getValue()
        subscriptionLoading[type] = value

        this.subscriptionLoading.next(subscriptionLoading)
    }

    getSubscriptionPlan() {
        const subscription = this.subscription.getValue()
        const now = Date.now() / 1000

        if (subscription.plan == "free" || now > subscription.end) {
            return "free"
        }

        return subscription.plan
    }

    retrieveSubscriptionObject() {
        return this.subscription.getValue()
    }

    /**
     * checks if the user is set up to make ANY wallet payment (Apple Pay, Google Pay, Alipay)
     * @description it creates a fictive Payment Request to be able to test if the user is in an 
     *              environment that is able to handle Payment Requests
     */
    canMakeWalletPayment():Promise<boolean>{
        return new Promise((resolve, reject) => {
            // create demo payment request needed to check payment ability
            let tempPaymentRequest = this.stripe.paymentRequest({
                country: 'US',
                currency: 'eur',
                total: {
                    label: 'Your card won\'t be charged',
                    amount: 0,
                }
            })
            tempPaymentRequest.canMakePayment().then((result) => {
                if(result) {
                    tempPaymentRequest = undefined
                    resolve(true)
                }
                else {
                    tempPaymentRequest = undefined
                    resolve(false)
                }
            })
        })
    }

    formatAmount(amount) {
		return Math.abs(amount) / 100
    }
    
    getPreviewTotal(billingPreview) {
		var total = billingPreview.startingBalance

		for (var i = 0; i < billingPreview.previewLines.length; i++) {
			total += billingPreview.previewLines[i].amount
		}

		return total
    }
    
    /**
     * uses the billing preview to create displayItems which are needed to display the preview in a Payment Request 
     * @param billingPreviewLines result of calling the /billing/preview API call
     */
    buildDisplayItems(billingPreviewLines):Object {
        let previewLines = {}
        previewLines["displayItems"] = []
        let totalLabel = ""

        // Startingbalance
        if(billingPreviewLines.startingBalance != 0){
            previewLines["displayItems"].push({
                label: "Startingbalance",
                amount: billingPreviewLines.startingBalance
            })
        }

        // Subscription plans
        let plans = billingPreviewLines.previewLines.filter(line => !line.tax)

        for(let planItem of plans){
            if(planItem.amount <= 0){
                totalLabel = "Unused time on " + this.formatPlanName(planItem.plan.name) + " after " + new DatePipe('en-EU').transform(planItem.period.start, 'mediumDate') + ""
                previewLines["displayItems"].push({
                    label: totalLabel,
                    amount: planItem.amount
                })
            } else {
                totalLabel = "Recurring subscription to " + this.formatPlanName(planItem.plan.name) + ", starting " + new DatePipe('en-EU').transform(planItem.period.start, 'mediumDate') + ""
                previewLines["displayItems"].push({
                    label: totalLabel,
                    amount: planItem.amount
                })
            }
        }

        // VAT
        let vat = billingPreviewLines.previewLines.find(line => line.tax == true)
        if(vat){
            previewLines["displayItems"].push({
                label:vat.vatType,
                amount: vat.amount
            })
        }

        // Preview total amount
        let totalValue = this.getPreviewTotal(billingPreviewLines)    // NOTE: negative total is not possible for PaymentRequests!
        previewLines["total"] = {
            amount:totalValue,
            label:totalLabel
        }
        return previewLines
    }

    formatPlanName(name) {
		var nameTemp = name.split("_")
		var planType = this.helper.capitalizeFirstLetter(nameTemp[0])
		var period = this.helper.capitalizeFirstLetter(nameTemp[1])

		return planType + " " + period
    }
    
    createPaymentIntent(plan = "test_plan", amount = 1){
        return this.apiService.authRequest('/billing/createPaymentIntent', { plan, amount}, "primary", true)
    }

    /**
     * checks if the given country code matches a european country
     * @param countryCode two letter country code
     * @returns boolean - true if given country is european, else false
     */
    // isEuropeanCountry(countryCode) {
    //     countryCode = countryCode.toLowerCase() // case insensitive

    //     const europeanCountryCodes = this.countries.filter(country => country.continent == "EU").map(country => country.countryCode)
    //     const findGivenCountry = europeanCountryCodes.find(country => country.toLowerCase() == countryCode)

    //     if (!findGivenCountry) {
    //         return false
    //     } else {
    //         return true
    //     }
    // }

    createBraintreeCustomer(paypalPayload, customerAddress) {
        return this.apiService.authRequest('/billing/createBraintreeCustomer', { paypalPayload, userEmail: localStorage.getItem('currentUser'), userAddress: customerAddress }, "primary", true)
        
        .then((res) => {
            this.getPaymentDetails()
            return Promise.resolve(res.customer)
        })

        .catch((err) => {
            // console.error("Error while creating Braintree customer")
            this.apiService.handleError(err)
            return Promise.reject(err)
        })
    }

    updateBraintreeCustomer(paypalPayload, customerAddress) {
        return this.apiService.authRequest('/billing/updateBraintreeCustomer', { paypalPayload, userEmail: localStorage.getItem('currentUser'), userAddress: customerAddress }, "primary", true)
       
        .then((res) => {
            this.getPaymentDetails()
            return Promise.resolve(res.customer)
        })

        .catch((err) => {
            // console.error("Error while updating Braintree customer")
            this.apiService.handleError(err)
            return Promise.reject(err)
        })
    }

    validBraintreeCustomerAddress(address){
        let requiredFields = ['countryCodeAlpha2','firstName','lastName','locality','postalCode','streetAddress']
        let errorMsgs = []

        for(let field of requiredFields){
            // check if all required fields are set
            if(address[field] && address[field].length == 0 || !address[field]){
                errorMsgs.push({ type: "required", message: "Some required fields are still empty."})
                return { valid: false, errors: errorMsgs }
            }
            // country code must have exactly 2 characters
            if(field == "countryCodeAlpha2"){
                if (!address[field] || address[field].length != 2) {
                    errorMsgs.push({type:"invalidCountry", message: "Invalid country selected."})
                    return { valid: false, errors: errorMsgs }
                }
            }
            // postal code is restricted to 4-9 characters only
            if(field == "postalCode"){
                if(address[field].length < 4 || address[field].length > 9) {
                    errorMsgs.push({ type: "invalidPostalCode", message: "Invalid postal code. Must be at least 4 and maximum 9 alphanumeric characters."})
                    return { valid: false, errors: errorMsgs }
                }
            }
            // most other fields are restricted to 255 characters max
            if(address[field] && address[field].length > 255) {
                errorMsgs.push({ type: "tooManyCharacters", message: "Too many characters. Please check the content of each field."})
                return { valid: false, errors: errorMsgs }
            }
        }
        return { valid: true }
    }
    
}