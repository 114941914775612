<div class="header">
    <div class="meta-data" *ngIf="showTrimmingAudioEditor()">
        <div class="title" title="{{ fileMetaData.name }}"><span class="text">{{ fileMetaData.name }}</span>
            <span class="time">({{ getFormattedTime(fileMetaData.duration) }})</span></div>
    </div>
    <div class="controls" *ngIf="showTrimmingAudioEditor()">
        <div class="play" *ngIf="!fileMetaData.isPlaying" (click)="play()">
            <img src="assets/img/player/play.svg" alt="Play"> <span class="text">Play audio</span>
        </div>
        <div class="pause" *ngIf="fileMetaData.isPlaying" (click)="pause()">
            <img src="assets/img/player/pause.svg" alt="Pause"> <span class="text">Pause audio</span>
        </div>
    </div>
</div>

<div #influenceAudioWaveform class="influence-audio-waveform">
    <div class="background" [ngClass]="showTrimmingAudioEditor() ? 'dark-background' : ''"></div>
    <div class="audio-trim-controls" *ngIf="showTrimmingAudioEditor()">
        <div class="left-mask" [style.width.px]="leftMaskWidth"></div>

        <div class="trim-area" [style.width.px]="trimAudioArea.width" [style.left.px]="trimAudioArea.startX">
            <div #dragHandleStart class="start" (mousedown)="startDraggingTrimArea($event, 'start')"
                [ngClass]="showTrimmingWarning ? 'red-background' : ''">
                <div class="drag-indicator"></div>
                <div class="start-time">{{ getFormattedTime(trimAudioArea.startTime) }}</div>
            </div>

            <div class="top" [style.width.px]="trimAudioAreaWidth"
                (mousedown)="startDraggingTrimArea($event, 'middle')"
                [ngClass]="showTrimmingWarning ? 'red-background' : ''"></div>
            <div class="bottom" [style.width.px]="trimAudioAreaWidth"
                (mousedown)="startDraggingTrimArea($event, 'middle')"
                [ngClass]="showTrimmingWarning ? 'red-background' : ''"></div>

            <div #dragHandleEnd class="end" (mousedown)="startDraggingTrimArea($event, 'end')"
                [ngClass]="showTrimmingWarning ? 'red-background' : ''">
                <div class="drag-indicator"></div>
                <div class="end-time">{{ getFormattedTime(trimAudioArea.endTime) }}</div>
            </div>
        </div>

        <div class="right-mask" [style.width.px]="rightMaskWidth"></div>

        <div class="audio-trim-warning" [style.opacity]="showTrimmingWarning ? 1 : 0">The selected region must
            be between {{ getFormattedTime(minimumAudioDuration) }} and {{
            getFormattedTime(maximumAudioDuration) }} long</div>

        <div class="low-amplitude-info"
            [style.opacity]="audioHasPartsBelowAmplitudeThreshold && !showTrimmingWarning ? 1 : 0">Audio zones
            highlighted in red areas are automatically detected to be too quiet to help with audio analysis. If
            you can, avoid including them in your trimmed region.</div>
    </div>

    <div class="loader-container" *ngIf="loader.loading && loader.type == 'analyse'">
        <div class="loader"></div>
        <div class="message">Analysing your audio file ...</div>
    </div>

    <div class="loader-container" *ngIf="loader.loading && loader.type == 'trimming'">
        <div class="message">Preparing and uploading your audio file ...</div>
        <div class="message-subtitle">(This should take around ~30 seconds. Please do not close this window.)
        </div>
        <progressbar [progress]="percentage" [total]="100"></progressbar>
    </div>

    <div class="loader-container" *ngIf="!loader.loading && !audioNeedsTrimming">
        <div class="loader"></div>
        <div class="message">Uploading your audio file ...</div>
    </div>

    <div id="influence-canvas-container">
        <canvas class="waveform" #influenceAudioCanvas *ngIf="loader.type != 'trimming'"
            (click)="seek($event)"></canvas>
        <canvas class="below-amp-threshold" #belowAmpThresholdCanvas
            [style.opacity]="displayBelowAmpThresholdCanvas ? '0.25' : '0'"
            *ngIf="loader.type != 'trimming'"></canvas>
        <div class="seek-bar-container">
            <div class="seek-bar" [style.left.%]="seekPosition" *ngIf="showTrimmingAudioEditor()"></div>
        </div>
    </div>

</div>