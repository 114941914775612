import { Component, EventEmitter, Input, Output } from '@angular/core'
import MelodyLayer from '@common-lib/classes/generationprofiles/melody/melodylayer'
import { GenerationProfileService } from '@services/generation-profile/generationprofile.service'
import { ModalService } from '@services/modal.service'

@Component({
 	selector: 'gpdevelopment',
  	templateUrl: 'gpdevelopment.component.html',
    styleUrls: ['./gpdevelopment.component.scss']
})
export class GPDevelopmentComponent {
    @Input() settings
    @Output() close = new EventEmitter()

	tooltips = {
		layersOrder: "Controls the order of appearance of each layer type in a generated composition. For more diverse results, you may add multiple entries.",
		minLayers: "Specifies the minimum number of layers that will ever play at any point in time in a composition generated with this style. You may still get compositions with a number of layers consistently higher than this value though.",
		formTags: "Specifies how the generated composition is supposed to develop in musical intensity over time."
	}

    constructor(private modalService:ModalService, private gpService:GenerationProfileService) {

    }

    closeModal() {
        this.close.emit(null)
    }

    formatLayersOrder() {
		let str = ""

		for (let option of this.settings.layersOrder) {
			str = option.join(", ")

			break // we only want to preview one layer order on the main GP dashboard
		}

		return str
	}

	getTutorialMode() {
		return this.gpService.tutorialMode
	}

    handleMinLayerInput() {
		let value:number = parseInt(this.settings.minLayers + "")

		if (isNaN(value)) {
			value = 1
		}

		let maxMinLayer = this.getMaxMinLayer()

		this.settings.minLayers = Math.max(Math.min(value, maxMinLayer), 1)

		this.gpService.setAsUpdated("minLayer")
	}

	getFormTags() {
		return this.gpService.getFormTags()
	}

    selectLayersOrder() {
		this.closeModal()
		this.modalService.modals.layersOrder.next(this.gpService.generationProfile.settings)
	}

    getMaxMinLayer() {
		let maxMinLayer = this.getAccompanimentLayers().length

		if (this.getMelody() != null) {
			maxMinLayer += 1
		}

		return maxMinLayer
	}

	selectFormTags() {
		this.closeModal()
		this.modalService.modals.selectFormTags.next(this.gpService.generationProfile.settings)
	}

    getGenerationProfile() {
		return this.gpService.generationProfile
	}

	getMelody():MelodyLayer {
		return this.gpService.generationProfile.melodyLayer
	}

	getAccompanimentLayers() {
		return this.gpService.generationProfile.accompanimentLayers
	}

	getSettings() {
		return this.settings
	}

	getHarmony() {
		return this.gpService.generationProfile.harmony
	}
}