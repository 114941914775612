import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"

export type Option = {
    value: string
}

@Component({
    selector: "btn-select",
    styleUrls: ["./btn-select.component.scss"],
    templateUrl: "./btn-select.component.html",
})
export class BtnSelectComponent implements OnInit {
    @Input() lineHeight: string = "30px"
    @Input() public options: Option[]
    @Input() public default: Option
    @Input() public selectedOption: Option
    @Input() public avData: string = "btn-option"
    @Input() public widthPerCell: number = 45

    @Output()
    public selection$: EventEmitter<Option> = new EventEmitter()

    ngOnInit() {

    }

    public setSelected(option: Option) {
        this.selection$.next(option)
    }

    public rightRoundCorner(index: number) {
        return index === this.options.length - 1 && this.options.length >= 2
    }

    public leftRoundCorner(index: number) {
        return index === 0 && this.options.length >= 2
    }
}
