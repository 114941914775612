import { AccompanimentPackSections } from "../types/general"

export const DEFAULT_ACCOMPANIMENT_SECTIONS:AccompanimentPackSections[] = [
    "Intro", "Theme", "Bridge", "Outro", "Cadence"
]

export const DEFAULT_PREFILTER = []
export const DEFAULT_SPREAD = 0
export const DEFAULT_UPPER_RANGE = 0

export const SINGLE_STEPS_SCHEMA_DEFAULT = {
    isFinished: false,
    wasSuccessful: false,
    error: undefined,
    trace: undefined
}

export const STEPS_SCHEMA_DEFAULT = {
    formatting: { ...SINGLE_STEPS_SCHEMA_DEFAULT },
    ts_conversion: { ...SINGLE_STEPS_SCHEMA_DEFAULT },
    validation: { ...SINGLE_STEPS_SCHEMA_DEFAULT },
    db_addition: { ...SINGLE_STEPS_SCHEMA_DEFAULT },
    creation: { ...SINGLE_STEPS_SCHEMA_DEFAULT },
}