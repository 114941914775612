import {
    ActionsManager,
    EmitterType,
} from "../../../../../../common-lib/general/classes/actionsManager"
import { cloneDeep } from "lodash"
import { CWQuery } from "./cw.query"
import { CWState, CWStore, createInitialStepTwo, steps } from "./cw.store"
import { CompositionWorkflowModule } from "@common-lib/modules/composition-workflow.module"
import {
    TemplateChord,
    TemplateKeySignature,
} from "@common-lib/interfaces/score/templateScore"
import {
    DrumSamplesMap,
    InstrumentsJSON,
    PitchToChannelMapping,
} from "@common-lib/interfaces/score/general"
import {
    CompositionWorkflowType,
    LayersValue,
    LoadingType,
} from "@common-lib/types/general"
import {
    CWKeyMode,
    CWStateStep1,
    CWStateStep2,
    CompositionWorkflowLayer,
    CompositionWorkflowNote,
    CompositionWorkflowSchema,
} from "@common-lib/interfaces/composition-workflow.interface"
import Layer from "@common-lib/classes/score/layer"
import { CompositionWorkflowManipulation } from "@common-lib/modules/composition-workflow-manipulation.module"
import { Time } from "@common-lib/modules/time"
import {
    HARMONY_MODES,
    LayerType,
    NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS,
    TIMESTEP_RES,
} from "@common-lib/constants/constants"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import { SRActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { ParentClass } from "../../../../app/parent"
import TrackBus from "@common-lib/classes/score/trackbus"
import { KeySignature } from "@common-lib/interfaces/score/keySignature"
import { TimeSignature } from "@common-lib/types/score"
import Harmony from "@common-lib/classes/generationprofiles/harmony/harmony"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import { v4 as uuidv4 } from "uuid"
import { GPHarmonyStrategy } from "@common-lib/types/generationProfiles"
import HarmonyPack from "@common-lib/classes/generationprofiles/harmony/harmonypack"
import { environment } from "@environments/environment"
import { ScoreRendering } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.store"
import { Misc } from "@common-lib/modules/misc"
import { GenerationProfileManipulation } from "@common-lib/modules/generation-profile-manipulation"
import { KeySignatureModule } from "@common-lib/modules/keysignature.module"
import { ScoreManipulation } from "@common-lib/modules/scoremanipulation"
import { CWGenerateLayerBody } from "@common-lib/interfaces/api/composition-workflow.api"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { RTSamplerActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/realtime-sampler/realtime-sampler.actions"
import {
    PlayerState,
    playerQuery,
} from "../../../../../../common-lib/client-only/general/classes/playerStateManagement"

export enum CWActionsType {
    initialize = "initialize",
    addDouble = "addDouble",
    toggleLocalLLM = "toggleLocalLLM",
    clearCanvases = "clearCanvases",
    allowInstrumentationChanges = "allowInstrumentationChanges",
    setLayerLoading = "setLayerLoading",
    setLayerData = "setLayerData",
    deleteLayer = "deleteLayer",
    resetLayerData = "resetLayerData",
    resetStep2 = "resetStep2",
    setLoadingError = "setLoadingError",
    setLoading = "setLoading",
    setTempo = "setTempo",
    setKeyMode = "setKeyMode",
    regenerateChordProgression = "regenerateChordProgression",
    setKeySignature = "setKeySignature",
    setTimeSignature = "setTimeSignature",
    changeTrackBus = "changeTrackBus",
    setLayerLoadingError = "setLayerLoadingError",
    setStrategy = "setStrategy",
    setPitchClass = "setPitchClass",
    setDuration = "setDuration",
    setSavedWorkflow = "setSavedWorkflow",
    saveWorkflowToAccount = "saveWorkflowToAccount",
    setStep = "setStep",
    setStep1Loading = "setStep1Loading",
    setStep1LoadingFinished = "setStep1LoadingFinished",
    setStep1LoadingError = "setStep1LoadingError",
    setWorkflowName = "setWorkflowName",
    setStep3Loading = "setStep3Loading",
    editScoreRenderingEngine = "scoreRenderingEngine",
    setNbOfCompositions = "setNbOfCompositions",
    setNewThematicMaterial = "setNewThematicMaterial",
    undo = "undo",
    redo = "redo",
    setGain = "setGain",
    generateLayer = "generateLayer",
    updateChords = "updateChords",
    emptyAction = "emptyAction",
    setPrompt = "setPrompt",
    setUseLLM = "setUseLLM",
    clearHistory = "clearHistory",
    resetHarmonyPromptId = "resetHarmonyPromptId",
    resizeNotesToCWMeasureLength = "resizeNotesToCWMeasureLength",
}

export interface ChordProgressionGeneratorFunction {
    ({
        harmonyPackID,
        keySignature,
        timeSignature,
        tempo,
        prompt,
        modelType,
    }: {
        harmonyPackID: string
        keySignature: KeySignature
        timeSignature: TimeSignature
        tempo: number
        prompt?: string
        modelType?: string
        toggleLocalLLM: boolean
    }): Promise<{
        chordProgression: TemplateChord[]
        keySignature: KeySignature
        harmonyPromptId: string
    }>
}

export interface LayerGeneratorFunction {
    (
        data: CWState,
        notes: CompositionWorkflowNote[],
        packID: string,
        lowestNote: number,
        instrument: InstrumentPatch,
        layer: string,
        instruments: InstrumentsJSON
    ): Promise<CompositionWorkflowNote[]>
}

export class CWActions {
    public readonly actionTypeToMethodMap: {
        [key: string]: (...args) => any
    } = {
        [CWActionsType.initialize]: this.initialize.bind(this),
        [CWActionsType.setStep3Loading]: this.setStep3Loading.bind(this),
        [CWActionsType.addDouble]: this.addDouble.bind(this),
        [CWActionsType.changeTrackBus]: this.changeTrackBus.bind(this),
        [CWActionsType.clearCanvases]: this.clearCanvases.bind(this),
        [CWActionsType.setLayerLoading]: this.setLayerLoading.bind(this),
        [CWActionsType.setLayerData]: this.setLayerData.bind(this),
        [CWActionsType.setLoading]: this.setLoading.bind(this),
        [CWActionsType.setNewThematicMaterial]:
            this.setNewThematicMaterial.bind(this),
        [CWActionsType.setPrompt]: this.setPrompt.bind(this),
        [CWActionsType.saveWorkflowToAccount]:
            this.saveWorkflowToAccount.bind(this),
        [CWActionsType.setLoadingError]: this.setLoadingError.bind(this),
        [CWActionsType.setStep]: this.setStep.bind(this),
        [CWActionsType.setTempo]: this.setTempo.bind(this),
        [CWActionsType.deleteLayer]: this.deleteLayer.bind(this),
        [CWActionsType.toggleLocalLLM]: this.toggleLocalLLM.bind(this),
        [CWActionsType.resetLayerData]: this.resetLayerData.bind(this),
        [CWActionsType.resetStep2]: this.resetStep2.bind(this),
        [CWActionsType.setKeySignature]: this.setKeySignature.bind(this),
        [CWActionsType.setLayerLoadingError]:
            this.setLayerLoadingError.bind(this),
        [CWActionsType.setUseLLM]: this.setUseLLM.bind(this),
        [CWActionsType.setTimeSignature]: this.setTimeSignature.bind(this),
        [CWActionsType.setStrategy]: this.setStrategy.bind(this),
        [CWActionsType.setKeyMode]: this.setKeyMode.bind(this),
        [CWActionsType.setDuration]: this.setDuration.bind(this),
        [CWActionsType.setStep1Loading]: this.setStep1Loading.bind(this),
        [CWActionsType.setStep1LoadingFinished]:
            this.setStep1LoadingFinished.bind(this),
        [CWActionsType.setStep1LoadingError]:
            this.setStep1LoadingError.bind(this),
        [CWActionsType.setWorkflowName]: this.setWorkflowName.bind(this),
        [CWActionsType.undo]: this.undo.bind(this),
        [CWActionsType.redo]: this.redo.bind(this),
        [CWActionsType.setGain]: this.setGain.bind(this),
        [CWActionsType.generateLayer]: this.generateLayer.bind(this),
        [CWActionsType.setNbOfCompositions]:
            this.setNbOfCompositions.bind(this),
        [CWActionsType.updateChords]: this.updateChords.bind(this),
        [CWActionsType.allowInstrumentationChanges]:
            this.allowInstrumentationChanges.bind(this),
        [CWActionsType.setPitchClass]: this.setPitchClass.bind(this),
        [CWActionsType.setSavedWorkflow]: this.setSavedWorkflow.bind(this),
        [CWActionsType.emptyAction]: () => {},
        [CWActionsType.regenerateChordProgression]:
            this.regenerateChordProgression.bind(this),
        [CWActionsType.clearHistory]: this.clearHistory.bind(this),
        [CWActionsType.resetHarmonyPromptId]:
            this.resetHarmonyPromptId.bind(this),
        [CWActionsType.resizeNotesToCWMeasureLength]:
            this.resizeNotesToCWMeasureLength.bind(this),
    }

    public readonly manager: ActionsManager<
        CWActionsType,
        {
            compositionWorkflow: Partial<CWState>
            scoreRenderignStore: ScoreRendering
        },
        CWState
    >

    public get emitter$() {
        return this.manager.emitter$
    }

    constructor(private query: CWQuery, private store: CWStore) {
        this.manager = new ActionsManager(store, this.actionTypeToMethodMap, {
            getData: this.getStateData.bind(this),
            stateUpdate: this.stateUpdate.bind(this),
        })
    }

    private async initialize({
        cwID,
        gpID,
        type,
        getCWByID,
        getGPByID,
        initialisePlayer,
        instruments,
        pitchToChannelMapping,
        drumSamples,
        userID,
        settings,
        onComplete,
        chordProgression,
    }: {
        cwID?: string
        gpID?: string
        type?: CompositionWorkflowType
        initialisePlayer: Function
        getCWByID: (cwID: string) => Promise<CompositionWorkflowSchema>
        getGPByID: (
            gpID: string,
            options: {
                isCompositionWorkflow: boolean
            }
        ) => Promise<{ result: 0 | 1; generationProfile: Object }>
        instruments: InstrumentsJSON
        pitchToChannelMapping: PitchToChannelMapping
        drumSamples: DrumSamplesMap
        userID: string
        settings: string
        onComplete: Function
        chordProgression: { [key: string]: any }
    }) {
        try {
            this.setLoading(true)
            if (cwID === undefined && gpID === undefined) {
                throw "Could not load composition workflow."
            }

            let cw: CompositionWorkflowSchema | undefined

            if (cwID !== undefined) {
                cw = await getCWByID(cwID)

                gpID = cw.generationProfileID
                type = cw.type
            }

            if (type === undefined) {
                type = "chordProgression"
            }

            const res = await getGPByID(gpID, {
                isCompositionWorkflow: true,
            })

            const gp = GenerationProfile.fromJSON(res.generationProfile)

            this.store.updateStore({
                gp,
                type,
                cw,
            })

            const id =
                cw !== undefined ? cw._id : "composition-workflow_" + gp._id

            await this.initStepOneEngineAndPlayer(
                id,
                instruments,
                drumSamples,
                userID,
                settings,
                gp,
                cw
            )

            await initialisePlayer(id, true)

            if (cw !== undefined) {
                this.query.engine.actions.realtimeSampler.emitter$.next({
                    type: RTSamplerActionTypes.setTrackBusLoading,
                    data: {
                        value: 0,
                    },
                })

                await this.addExistingWorkflowData({
                    cw,
                    instruments,
                    pitchToChannelMapping,
                })

                if (cw.type === "chordProgression") {
                    await this.setStep({ step: 0 })
                } else {
                    await this.setStep({ step: 1 })
                }
            }

            if (chordProgression) {
                await this.initFromChordProgression(chordProgression)
            }

            this.setLoading(false)
        } catch (e) {
            console.error(e)
            this.setLoadingError(e)
        }

        onComplete()
    }

    private async initFromChordProgression(chordProgression: {
        [key: string]: any
    }) {
        this.query.engine.actions.realtimeSampler.emitter$.next({
            type: RTSamplerActionTypes.setTrackBusLoading,
            data: {
                value: 0,
            },
        })

        const keySignature =
            KeySignatureModule.convertMERepresentationToKeySignature(
                chordProgression.keySignature[1].split(" ")[0] +
                    " " +
                    chordProgression.keySignature[1]
                        .split(" ")[1]
                        .substring(0, 3)
            )

        this.setKeySignature({
            keySignature,
        })

        await this.updateChords({
            romanNumerals: chordProgression.romanNumerals,
            keySignatures: this.query.engine.score.keySignatures,
            timeSignature: chordProgression.timeSignature,
            updateRomanNumerals: false,
            progressionWasEdited: false,
        })

        await this.setStep({ step: 1 })
    }

    private toggleLocalLLM({}: {}) {
        this.store.updateStore({
            toggleLocalLLM: !this.query.getValue().toggleLocalLLM,
        })
    }

    private resizeNotesToCWMeasureLength({
        layers,
        timeSignature,
    }: {
        layers: {
            [layerName: string]: CompositionWorkflowLayer
        }
        timeSignature: TimeSignature
    }) {
        ScoreManipulation.applyMeasureBoundariesToCompositionWorkflows(
            layers,
            timeSignature
        )
    }

    private async setNewThematicMaterial({ value }: { value: boolean }) {
        const step3 = this.query.step3
        step3.newThematicMaterial = value

        this.store.updateStore({
            step3,
        })
    }

    private async initStepOneEngineAndPlayer(
        id: string,
        instruments: InstrumentsJSON,
        drumSamples: DrumSamplesMap,
        userID: string,
        settings: string,
        gp: GenerationProfile,
        cw: CompositionWorkflowSchema | undefined
    ) {
        let numerals = []

        if (this.query.engine?.score?.romanNumerals?.length) {
            numerals = this.query.engine?.score?.romanNumerals
        }

        await this.initStepOne({
            id,
            gp,
            romanNumerals:
                cw !== undefined ? cw.section.chord_progression : numerals,
            instruments,
            drumSamples,
            userID,
            settings,
            prompt: cw === undefined ? "" : cw.section.prompt,
        })
    }

    private clearHistory() {
        this.manager.clearHistory()
    }

    private async stateUpdate(state: {
        compositionWorkflow: Partial<CWState>
        scoreRenderingStore: ScoreRendering
    }) {
        // state.scoreRenderingStore.renderingType = ["All"]
        // state.scoreRenderingStore.scoreUpdate = ["All"]
        state.scoreRenderingStore.skipCachedCanvas = true
        state.scoreRenderingStore.temporaryChords = undefined
        state.scoreRenderingStore.temporaryRomanNumerals = undefined
        state.compositionWorkflow.lastUndoRedo = Date.now()

        this.query.engine.queries.scoreRendering.store.updateStore({
            partial: state.scoreRenderingStore,
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
        })

        this.store.updateStore(state.compositionWorkflow)
    }

    private setSavedWorkflow({ resolve }: { resolve: Function }) {
        this.store.updateStore({
            savedWorkflow: true,
        })

        resolve()
    }

    private setPrompt({ value }: { value: string }) {
        const step1 = this.query.step1
        step1.prompt = value

        this.store.updateStore({
            step1,
        })
    }

    private async getGeneratedChordProgression({
        chordProgressionGenerator,
        harmony,
        timeSignature,
        tempo,
        sourcePacks,
    }: {
        chordProgressionGenerator: ChordProgressionGeneratorFunction
        harmony: Harmony
        timeSignature: TimeSignature
        tempo: number
        sourcePacks: HarmonyPack[]
    }): Promise<TemplateChord[] | undefined> {
        const keySignature = harmony.keySignature

        const harmonyPack =
            GenerationProfileManipulation.getRandomHarmonyPack(harmony)

        const harmonyPackID = harmonyPack.packID

        const result = await chordProgressionGenerator({
            harmonyPackID,
            keySignature,
            timeSignature,
            tempo,
            prompt:
                this.query.step1.prompt.length < 3
                    ? undefined
                    : this.query.step1.prompt,
            toggleLocalLLM: this.query.getValue().toggleLocalLLM,
        })

        if (
            !HARMONY_MODES[this.query.step1.harmony.strategy].includes(
                result.keySignature.keyMode
            )
        ) {
            this.query.step1.harmony.setStrategy(
                sourcePacks,
                this.query.step1.harmony.strategy === "Functional"
                    ? "Modal"
                    : "Functional"
            )
        }

        this.setKeySignature({
            keySignature: result.keySignature,
        })

        this.setHarmonyPromptID({ harmonyPromptId: result.harmonyPromptId })

        await this.updateChords({
            romanNumerals: result.chordProgression,
            keySignatures: this.query.engine.score.keySignatures,
            timeSignature: this.query.timeSignature,
        })

        return result.chordProgression
    }

    private async regenerateChordProgression({
        chordProgressionGenerator,
        onComplete,
        sourcePacks,
    }: {
        chordProgressionGenerator: ChordProgressionGeneratorFunction
        onComplete: Function
        sourcePacks: HarmonyPack[]
    }) {
        let result

        try {
            this.setStep1Loading()

            result = await this.getGeneratedChordProgression({
                chordProgressionGenerator,
                harmony: cloneDeep(this.query.step1.harmony),
                timeSignature: cloneDeep(this.query.timeSignature),
                tempo: this.query.tempo,
                sourcePacks,
            })

            if (window["electron"]) {
                await Misc.promiseWithTimeout(
                    new Promise(resolve => {
                        this.query.engine.actions.realtimeSampler.emitter$.next(
                            {
                                type: RTSamplerActionTypes.loadAudioSamplesInMemory,
                                data: {
                                    layer: this.query.engine.score.layers
                                        .Chords,
                                    tbs: this.query.engine.score.layers.Chords
                                        .trackBuses,
                                    loadIncrementPerTrackBus: 0,
                                    showLoading: false,
                                    callback: resolve,
                                },
                            }
                        )
                    }),

                    3
                )
            }

            this.setStep1LoadingFinished()
        } catch (e) {
            console.error("HERE")
            console.error(e)
            this.setStep1LoadingError({ err: e })
        }

        onComplete(result)
    }

    private getStateData() {
        const partial: Partial<CWState> = {}

        for (const key in this.query.getValue()) {
            if (key === "engine") {
                continue
            }

            partial[key] = this.query.getValue()[key]
        }

        const scoreRenderingStore =
            this.query.engine.queries.scoreRendering.getValue()

        return { compositionWorkflow: partial, scoreRenderingStore }
    }

    private async setKeyMode({
        keyMode,
        chordProgressionGenerator,
        onComplete,
        sourcePacks,
    }: {
        keyMode: CWKeyMode
        chordProgressionGenerator: ChordProgressionGeneratorFunction
        onComplete: Function
        sourcePacks: HarmonyPack[]
    }) {
        try {
            this.setStep1Loading()

            this.setKeySignature({
                keySignature: {
                    keyMode,
                    pitchClass:
                        this.query.step1.harmony.keySignature.pitchClass,
                },
            })

            // fetch roman numeral chord progression
            await this.getGeneratedChordProgression({
                chordProgressionGenerator,
                harmony: this.query.step1.harmony,
                timeSignature: this.query.timeSignature,
                tempo: this.query.step1.tempo,
                sourcePacks,
            })

            this.setStep1LoadingFinished()
        } catch (e) {
            this.setStep1LoadingError({ err: e })
        }

        onComplete()
    }

    private setNbOfCompositions({
        nbOfCompositions,
    }: {
        nbOfCompositions: number
    }) {
        const step3 = this.query.step3

        step3.nbOfCompositions = nbOfCompositions

        this.store.updateStore({
            step3,
        })
    }

    private async saveWorkflowToAccount({ value }: { value: boolean }) {
        const step3 = this.query.step3
        step3.saveWorkflowToAccount = value

        this.store.updateStore({
            step3,
        })
    }

    private async allowInstrumentationChanges({ value }: { value: boolean }) {
        const step3 = this.query.step3
        step3.allowInstrumentationChanges = value

        this.store.updateStore({
            step3,
        })
    }

    private async addExistingWorkflowData({
        cw,
        instruments,
        pitchToChannelMapping,
    }: {
        cw: CompositionWorkflowSchema
        instruments: InstrumentsJSON
        pitchToChannelMapping: PitchToChannelMapping
    }) {
        const keySignature =
            KeySignatureModule.convertMERepresentationToKeySignature(
                cw.section.key_signature
            )

        this.setKeySignature({ keySignature })

        this.setTempo({ tempo: cw.section.tempo })

        await this.updateChords({
            romanNumerals: cw.section.chord_progression,
            keySignatures: this.query.engine.score.keySignatures,
            timeSignature: cw.section.time_signature,
            updateRomanNumerals: false,
            progressionWasEdited: false,
        })

        this.setStep1LoadingFinished()

        if (cw.type === "stepByStep") {
            if (window["electron"] !== undefined) {
                await ParentClass.waitUntilTrueForObservable(
                    playerQuery.select(),
                    (playerState: PlayerState) => {
                        return playerState.trackBusLoadingPercentage === 100
                    }
                )
            }

            for (const layer of cw.section.layers) {
                await this.setLayerData({
                    layerKey: layer.layerName,
                    layer: layer,
                    instruments,
                    pitchToChannelMapping,
                })
            }
        }
    }

    private async setStep({ step }: { step: number }) {
        if (step === this.query.getValue().stepData.index) {
            return
        }

        this.manager.reset()

        const newStepData = { ...steps[step], id: uuidv4() }

        this.store.updateStore({
            stepData: newStepData,
        })
    }

    private async setUseLLM({ value }: { value: boolean }) {
        const step2 = this.query.step2
        step2.useLLM = value

        this.store.updateStore({
            step2,
        })
    }

    private async generateLayer({
        layer,
        instrument,
        prompt,
        instruments,
        pitchToChannelMapping,
        httpCallToAPI,
    }: {
        layer: LayersValue
        prompt: string
        instrument?: InstrumentPatch
        instruments: InstrumentsJSON
        pitchToChannelMapping: PitchToChannelMapping
        httpCallToAPI: LayerGeneratorFunction
    }) {
        const gpLayer = this.query.getGPLayer(layer)

        const pack = GenerationProfileManipulation.getPackFromLayer(gpLayer)

        const packID = pack.packID

        const id = this.query.getValue().stepData.id

        if (instrument === undefined) {
            instrument =
                GenerationProfileManipulation.getInstrumentFromPack(pack)
        }

        try {
            // First, set the layer as loading by sending an action
            this.setLayerLoading({
                layerKey: layer,
                loading: true,
            })

            const notes =
                this.query.step2.layers[layer] !== undefined
                    ? this.query.step2.layers[layer].score
                    : []

            // Send an API call to generate the layer from the backend
            const cwScore = await httpCallToAPI(
                this.query.getValue(),
                notes,
                packID,
                pack.lowestNote,
                instrument,
                layer,
                instruments
            )

            const cwLayer: CompositionWorkflowLayer = {
                id: uuidv4(),
                layerName: layer,
                score: cwScore,
                instrument: instrument,
            }

            if (id !== this.query.getValue().stepData.id) {
                return
            }

            // Send an action to populate the layer with the newly received data
            await this.setLayerData({
                layerKey: layer,
                layer: cwLayer,
                instruments,
                pitchToChannelMapping,
            })
        } catch (e) {
            console.error(e)
            if (id !== this.query.getValue().stepData.id) {
                return
            }

            this.setLayerLoadingError({
                error: e,
                layerKey: layer,
            })
        }
    }

    private setDuration({ duration }: { duration: string }) {
        const step3 = this.query.step3
        step3.duration = duration

        this.store.updateStore({
            step3,
        })
    }

    private setStep1Loading(): void {
        const step1 = this.query.step1

        step1.chordProgressionLoading = {
            finished: false,
            error: undefined,
        }

        this.store.updateStore({
            step1,
        })
    }

    private setStep1LoadingFinished(): void {
        const step1 = this.query.step1
        step1.chordProgressionLoading = {
            finished: true,
            error: undefined,
        }

        this.store.updateStore({
            step1,
        })
    }

    private setStep1LoadingError({ err }: { err: string }): void {
        const step1 = this.query.step1

        step1.chordProgressionLoading = {
            finished: false,
            error: err,
        }

        this.store.updateStore({ step1 })
    }

    private setLayerLoadingError({
        error,
        layerKey,
    }: {
        error: string
        layerKey: LayersValue
    }) {
        const step2 = this.query.step2
        step2.layerLoading[layerKey] = {
            finished: false,
            error,
        }

        this.store.updateStore({
            step2,
        })
    }

    private setWorkflowName({ name }: { name: string }) {
        const step3 = this.query.step3
        step3.name = name

        this.store.updateStore({
            step3,
        })
    }

    private async resetStep2({
        drumSamples,
        instruments,
        resolve,
    }: {
        drumSamples: DrumSamplesMap
        instruments: InstrumentsJSON
        resolve: Function
    }) {
        await new Promise(wait => {
            this.query.engine.srEmitter$.next({
                type: SRActionTypes.resetScore,
                data: {
                    keySignature: this.query.keySignature,
                    timeSignature: this.query.timeSignature,
                    romanNumerals: this.query.engine.score.romanNumerals,
                    tempo: this.query.tempo,
                    drumSamples,
                    instruments,
                },
                resolve: wait,
            })
        })

        this.store.updateStore({
            step2: createInitialStepTwo(),
            lastUndoRedo: undefined,
        })

        this.setStep1LoadingFinished()

        resolve()
    }

    private async addDouble({
        patch,
        layerKey,
        instruments,
    }: {
        patch: InstrumentPatch
        layerKey: string
        instruments: InstrumentsJSON
    }) {
        const layer = this.query.engine.score.layers[layerKey]
        const timestepsInOneMeasure = Time.fractionToTimesteps(
            TIMESTEP_RES,
            this.query.step1.timeSignature[0] +
                "/" +
                this.query.step1.timeSignature[1]
        )

        // first, remove all trackbuses
        this.query.engine.srEmitter$.next({
            type: SRActionTypes.deleteTrackBusses,
            data: {
                layer,
                trackBusses: layer.trackBuses,
            },

            options: {
                isUndoable: false,
            },
        })

        await ParentClass.waitUntilTrueForObservable(
            this.query.engine.queries.scoreRendering.select(),
            sr => {
                return sr.score.layers[layerKey].trackBuses.length === 0
            }
        )

        // convert the patches to trackbuses
        const trackBusses = patch.patches.map(p => {
            return new TrackBus(
                p.patch.getFullName(),
                p.octave,
                instruments[p.patch.section].find(
                    i => i.name === p.patch.instrument
                ),
                [
                    {
                        start: 0,
                        end:
                            timestepsInOneMeasure *
                            NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS,
                        id: uuidv4(),
                    },
                ],
                0,
                0,
                0,
                0,
                false,
                false
            )
        })

        // load trackbusses
        this.query.engine.srEmitter$.next({
            type: SRActionTypes.addTrackBusses,
            data: {
                trackBusses,
                layer,
                instruments,
            },

            options: {
                isUndoable: false,
            },
        })

        await ParentClass.waitUntilTrueForObservable(
            this.query.engine.queries.scoreRendering.select(),
            sr => {
                return (
                    sr.score.layers[layerKey].trackBuses.length ===
                    patch.patches.length
                )
            }
        )

        const step2 = this.query.step2
        step2.layers[layerKey].instrument = patch

        this.store.updateStore({
            step2,
        })
    }

    private async resetLayerData({
        savedLayer,
        layerKey,
    }: {
        savedLayer: Layer | undefined
        layerKey: string
    }) {
        if (savedLayer === undefined) {
            return this.deleteLayer({ layerKey })
        }

        const layer: Layer = cloneDeep(savedLayer)
        layer.trackBuses = []

        await this.addLayerToScore(layer, savedLayer.trackBuses)

        this.setLayerLoading({
            layerKey,
            loading: false,
        })
    }

    private async changeTrackBus({
        layerKey,
        patch,
        selectedTrackBusID,
        instruments,
    }: {
        layerKey: string
        patch: InstrumentPatch
        selectedTrackBusID: string
        instruments: InstrumentsJSON
    }) {
        const step2: CWStateStep2 = cloneDeep(this.query.step2)

        const scoreLayer = this.query.engine.score.layers[layerKey]
        const selectedTrackBus = scoreLayer.trackBuses.find(
            tb => tb.id === selectedTrackBusID
        )

        if (
            selectedTrackBus === undefined ||
            step2.layers[layerKey] === undefined
        ) {
            return
        }

        step2.layers[layerKey].instrument = patch

        this.query.engine.srEmitter$.next({
            type: SRActionTypes.replaceTrackBus,
            data: {
                patchID: patch.patchID,
                instruments,
                layer: scoreLayer,
                tb: selectedTrackBus,
            },
            options: {
                isUndoable: false,
            },
        })

        await ParentClass.waitUntilTrueForObservable(
            this.query.engine.queries.scoreRendering.select(),
            (sr => {
                return (
                    sr.score.layers[layerKey].trackBuses[0].name ===
                    patch.patchID
                )
            }).bind(this)
        )

        this.store.updateStore({
            step2,
        })
    }

    private deleteLayer({ layerKey }: { layerKey: string }) {
        const step2 = this.query.getValue().step2
        delete step2.layers[layerKey]
        delete step2.layerLoading[layerKey]

        if (this.query.engine.score.layers[layerKey] !== undefined) {
            this.query.engine.srEmitter$.next({
                type: SRActionTypes.deleteLayer,
                data: {
                    layer: this.query.engine.score.layers[layerKey],
                },
                options: {
                    isUndoable: false,
                },
            })
        }

        this.store.updateStore({
            step2,
            layerToDelete: layerKey + "_" + Date.now(),
        })
    }

    private async clearCanvases({
        step,
    }: {
        step: "step1" | "step2-part1" | "step2-part2" | "step3"
    }) {
        this.query.engine.deleteAllCanvases(false)
    }

    private updateChords({
        romanNumerals,
        keySignatures,
        timeSignature,
        updateRomanNumerals = true,
        progressionWasEdited = false,
    }: {
        romanNumerals: TemplateChord[]
        keySignatures: TemplateKeySignature[]
        timeSignature: TimeSignature
        updateRomanNumerals?: boolean
        progressionWasEdited?: boolean
    }) {
        // update the roman numerals
        if (updateRomanNumerals) {
            this.query.engine.srEmitter$.next({
                type: SRActionTypes.setChordsAsEdited,
                data: {
                    value: progressionWasEdited,
                },
                options: {
                    isUndoable: false,
                },
            })
        }

        // get the notesObject based on the new numerals
        const result = CompositionWorkflowModule.initNotes({
            keySignatures,
            chordProgression: romanNumerals,
            timeSignature: timeSignature,
            lowestNote: 60,
            tieNotes: true,
        })

        // update the score chords and Chords layer notesObject
        this.query.engine.srEmitter$.next({
            type: SRActionTypes.updateChords,
            data: {
                chords: result.chords,
                notes: result.notes,
                romanNumerals,
            },
            options: {
                isUndoable: false,
            },
        })
    }

    private async setLayerData({
        layerKey,
        layer,
        instruments,
        pitchToChannelMapping,
    }: {
        layerKey: LayersValue
        layer: CompositionWorkflowLayer
        instruments: InstrumentsJSON
        pitchToChannelMapping: PitchToChannelMapping
    }) {
        const score = this.query.engine.score

        const scoreLengthTimesteps = Time.fractionToTimesteps(
            TIMESTEP_RES,
            score.scoreLength
        )

        const result = CompositionWorkflowManipulation.toScoreLayer({
            layerKey,
            layer,
            instruments,
            scoreLengthTimesteps,
            timeSignature: score.firstTimeSignature,
            sections: score.sections,
            pitchToChannelMapping,
        })

        CompositionWorkflowManipulation.applyEffectsToScoreLayer({
            scoreLayer: result.layer,
            trackBuses: result.trackBuses,
            gp: this.query.gp,
            scoreLengthTimesteps,
            tempo: this.query.tempo,
        })

        for (const tb of result.trackBuses) {
            tb.autoPedal = ScoreManipulation.isKeyboard(tb.name, tb.reference)
        }

        await this.addLayerToScore(result.layer, result.trackBuses)

        const step2 = this.query.getValue().step2

        step2.layers[layerKey] = layer
        step2.layerLoading[layerKey] = {
            finished: true,
        }

        this.store.updateStore({
            step2,
            layerToAdd: layerKey + "-" + Date.now(),
        })
    }

    private async addLayerToScore(layer: Layer, trackBuses: TrackBus[]) {
        await new Promise(resolve => {
            // Add a new layer to the score
            this.query.engine.srEmitter$.next({
                type: SRActionTypes.addLayerData,
                data: {
                    layer: cloneDeep(layer),
                    trackBuses,
                },

                resolve,

                options: {
                    isUndoable: false,
                },
            })
        })
    }

    private setLayerLoading({
        layerKey,
        loading,
    }: {
        layerKey: LayersValue
        loading: boolean
    }) {
        const step2 = this.query.getValue().step2
        step2.layerLoading[layerKey] = {
            finished: !loading,
            error: undefined,
        }

        this.store.updateStore({
            step2,
        })
    }

    private setTempo({ tempo }: { tempo: number }) {
        const step1 = this.query.getValue().step1
        step1.tempo = tempo

        this.store.updateStore({
            step1,
        })

        this.query.engine.srEmitter$.next({
            type: SRActionTypes.setTempo,
            data: {
                tempo: tempo,
            },

            options: {
                isUndoable: false,
            },
        })
    }

    private setPitchClass({ pitchClass }: { pitchClass: string }) {
        const keySignature: KeySignature = {
            pitchClass: pitchClass,
            keyMode: this.query.keySignature.keyMode,
        }

        this.setKeySignature({ keySignature })

        // update the score chords and re-compute notes based on voicing
        this.updateChords({
            romanNumerals: this.query.engine.score.romanNumerals,
            keySignatures: this.query.engine.score.keySignatures,
            timeSignature: this.query.timeSignature,
            updateRomanNumerals: false,
        })
    }

    /**
     * sets the harmony and the score key signature
     * @param param0
     */
    private setKeySignature({ keySignature }: { keySignature: KeySignature }) {
        const step1 = this.query.getValue().step1

        step1.harmony.keySignature = keySignature

        const strategy = HARMONY_MODES["Functional"].includes(
            keySignature.keyMode
        )
            ? "Functional"
            : "Modal"

        step1.harmony.strategy = strategy

        // update the harmony that is used as a helper in our case
        keySignature = step1.harmony.setKeySignatureFromMode(
            keySignature.keyMode
        )

        // update the score key signature only if it differs from before updating chords
        if (
            !this.query.engine.score.keySignatures[0][1].includes(
                keySignature.pitchClass
            ) ||
            !this.query.engine.score.keySignatures[0][1].includes(
                keySignature.keyMode
            )
        ) {
            this.query.engine.srEmitter$.next({
                type: SRActionTypes.updateKeySignature,
                data: {
                    keySignature:
                        keySignature.pitchClass + " " + keySignature.keyMode,
                },

                options: {
                    isUndoable: false,
                },
            })
        }

        this.store.updateStore({
            step1,
        })
    }

    private resetHarmonyPromptId() {
        const { step1 } = this.query.getValue()
        step1.harmonyPromptId = undefined

        this.store.updateStore({
            step1: { ...step1 },
        })
    }

    private setHarmonyPromptID({
        harmonyPromptId,
    }: {
        harmonyPromptId: string
    }) {
        const step1 = this.query.getValue().step1
        step1.harmonyPromptId = harmonyPromptId

        this.store.updateStore({
            step1: { ...step1 },
        })
    }

    private async setTimeSignature({
        timeSignature,
        chordProgressionGenerator,
        onComplete,
        sourcePacks,
    }: {
        timeSignature: TimeSignature
        chordProgressionGenerator: ChordProgressionGeneratorFunction
        onComplete: Function
        sourcePacks: HarmonyPack[]
    }) {
        try {
            this.setStep1Loading()

            const step1 = this.query.getValue().step1
            step1.timeSignature = timeSignature

            // set the score time signature
            this.query.engine.srEmitter$.next({
                type: SRActionTypes.setTimeSignature,
                data: {
                    timeSignature: timeSignature,
                },

                options: {
                    isUndoable: false,
                },
            })

            await ParentClass.waitUntilTrueForObservable(
                this.query.select(),
                (state: CWState) => {
                    return Misc.arraysAreEqual(
                        state.engine.score.timeSignatures[0][1],
                        timeSignature
                    )
                }
            )

            this.query.engine.srEmitter$.next({
                type: SRActionTypes.setAccompanimentDesignerScoreLength,
                data: {
                    scoreLength:
                        timeSignature[0] *
                            NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS +
                        "/" +
                        timeSignature[1],
                    notesToRemove: [],
                },

                options: {
                    isUndoable: false,
                },
            })

            await this.getGeneratedChordProgression({
                chordProgressionGenerator,
                harmony: this.query.step1.harmony,
                timeSignature,
                tempo: this.query.step1.tempo,
                sourcePacks,
            })

            this.setStep1LoadingFinished()
        } catch (e) {
            this.setStep1LoadingError({ err: e })
        }

        onComplete()
    }

    private async setStrategy({
        chordProgressionGenerator,
        strategy,
        sourcePacks,
        onComplete,
    }: {
        chordProgressionGenerator: ChordProgressionGeneratorFunction
        strategy: GPHarmonyStrategy
        sourcePacks: HarmonyPack[]
        onComplete: Function
    }) {
        try {
            this.setStep1Loading()

            const step1 = this.query.getValue().step1
            const harmony = step1.harmony

            // update the strategy
            harmony.setStrategy(sourcePacks, strategy)

            this.query.engine.srEmitter$.next({
                type: SRActionTypes.setChordsAsEdited,
                data: {
                    value: false,
                },

                options: {
                    isUndoable: false,
                },
            })

            this.store.updateStore({
                step1,
            })

            // update the key signature
            // we do it in here since the logic
            // for updating the key signature also
            // takes care of the score

            this.setKeySignature({
                keySignature: harmony.keySignature,
            })

            // fetch roman numeral chord progression
            await this.getGeneratedChordProgression({
                chordProgressionGenerator,
                harmony,
                timeSignature: this.query.timeSignature,
                tempo: this.query.tempo,
                sourcePacks,
            })

            this.setStep1LoadingFinished()
        } catch (e) {
            this.setStep1LoadingError({ err: e })
        }

        onComplete()
    }

    private setLoading(loading: boolean) {
        this.store.updateStore({
            loading: {
                finished: !loading,
                error: undefined,
            },
        })
    }

    private setLoadingError({ error }: { error: string }) {
        this.store.updateStore({
            loading: {
                finished: false,
                error: error,
            },
        })
    }

    private initEngine({
        id,
        instruments,
        drumSamples,
        chordProgression,
        userID,
        settings,
    }: {
        id: string
        instruments: InstrumentsJSON
        drumSamples: DrumSamplesMap
        chordProgression: TemplateChord[]
        userID: string
        settings: string
    }): void {
        const score = CompositionWorkflowModule.defaultScoreInitialization({
            keySignature: this.query.getValue().step1.harmony.keySignature,
            timeSignature: this.query.getValue().step1.timeSignature,
            romanNumerals: chordProgression,
            tempo: this.query.tempo,
            drumSamples,
            lowestNote: this.getLowestNoteForLayer(),
            instruments,
        })

        score.compositionID = id

        const env = environment.production ? "production" : "staging"
        const engine =
            CompositionWorkflowModule.defaultScoreRenderingEngineInitialisation(
                {
                    score,
                    userID,
                    instruments,
                    settings,
                    environment: env,
                    templateBeforeActionEffect: (action => {
                        if (!action?.options?.isUndoable) return

                        const newAction: EmitterType<CWActionsType> = {
                            type: CWActionsType.editScoreRenderingEngine,
                            options: {
                                ...action.options,
                            },
                        }

                        return this.manager.beforeActionEffect(newAction)
                    }).bind(this),
                }
            )

        this.store.updateStore({
            engine,
        })
    }

    private getLowestNoteForLayer(layerKey?: string) {
        if (this.query.gp.accompanimentLayers.length === 0) {
            return 60
        }

        let layer

        if (layerKey === undefined) {
            layer = this.query.gp.accompanimentLayers.find(
                l =>
                    l.packs.length > 0 &&
                    l.name !== LayerType.PERCUSSION &&
                    !l.name.includes(LayerType.ORNAMENTS)
            )
        } else {
            layer = this.query.gp.accompanimentLayers.find(
                al => al.name === layerKey
            )
        }

        if (layer === undefined || layer.packs.length === 0) {
            return 60
        }

        const lowestNote = layer.packs[0].lowestNote

        return lowestNote
    }

    private initStepOne({
        id,
        gp,
        romanNumerals,
        instruments,
        drumSamples,
        userID,
        settings,
        prompt,
    }: {
        id: string
        gp: GenerationProfile
        romanNumerals: TemplateChord[]
        instruments: InstrumentsJSON
        drumSamples: DrumSamplesMap
        userID: string
        settings: string
        prompt?: string
    }) {
        const tempoRange = gp.settings.tempoRange
        const cwTempo = this.query?.getValue()?.cw?.section?.tempo
        const randomizedTempo =
            Math.floor(Math.random() * (tempoRange.max - tempoRange.min + 1)) +
            tempoRange.min
        const cwOrNewTempo = cwTempo ? cwTempo : randomizedTempo

        const tempo = this.query.step1WasInitialised()
            ? this.query.step1.tempo
            : cwOrNewTempo

        const timeSignature: TimeSignature = this.query.step1WasInitialised()
            ? this.query.step1.timeSignature
            : gp.settings.timeSignature

        const harmony: Harmony = cloneDeep(
            this.query.step1WasInitialised()
                ? this.query.step1.harmony
                : (gp.harmony as Harmony)
        )

        harmony.keySignature.keyMode =
            harmony.keySignature.keyMode.toLowerCase() as CWKeyMode

        const rhythm = Harmony.getAggregatedHarmonicRhythm(harmony.packs)

        let hasOnlyDiatonicPacks = true

        for (const pack of harmony.packs) {
            if (!pack.packID.includes("diatonic")) {
                hasOnlyDiatonicPacks = false
                break
            }
        }

        if (hasOnlyDiatonicPacks) {
            rhythm.max = Math.min(rhythm.max, 1)
            rhythm.min = Math.max(0, Math.min(rhythm.max - 1, rhythm.min))
        } else {
            rhythm.max = 0
            rhythm.min = 0
        }

        harmony.updatePacksHarmonicRhythmAndRepetition(
            rhythm,
            Harmony.getAggregatedHarmonicRepetition(harmony.packs)
        )

        const step1 = this.query.step1
        const data: CWStateStep1 = {
            ...step1,
            tempo,
            timeSignature,
            harmony,
            prompt: prompt !== undefined ? prompt : "",
        }

        this.store.updateStore({
            step1: data,
        })

        this.initEngine({
            id,
            instruments,
            drumSamples,
            chordProgression: romanNumerals,
            userID,
            settings,
        })
    }

    public setStep3Loading({ loading }: { loading: LoadingType }) {
        this.store.updateStore({
            step3: {
                ...this.query.step3,
                loading,
            },
        })
    }

    private getLoadingLayersToAdd(
        state: CWStateStep2
    ): CompositionWorkflowLayer[] {
        const layersToAdd = []

        for (const layerKey in state.layerLoading) {
            const layer = state.layerLoading[layerKey]

            if (!layer.finished) {
                layersToAdd.push(this.query.step2.layers[layerKey])
            }
        }

        return layersToAdd
    }

    private async undo({
        instruments,
        pitchToChannelMapping,
    }: {
        instruments: InstrumentsJSON
        pitchToChannelMapping: PitchToChannelMapping
    }) {
        const stepToUndoTo = this.manager.getStateToUndoTo()
        const layersToAdd =
            stepToUndoTo && stepToUndoTo.compositionWorkflow
                ? this.getLoadingLayersToAdd(
                      stepToUndoTo.compositionWorkflow.step2
                  )
                : []
        const result = this.manager.undo()

        if (!result) {
            return
        }

        await ParentClass.waitUntilTrueForObservable(
            this.query.select("engine"),
            engine => {
                return engine !== undefined
            }
        )

        for (const layer of layersToAdd) {
            if (!layer) continue
            await this.setLayerData({
                layerKey: layer.layerName,
                layer: layer,
                instruments,
                pitchToChannelMapping,
            })
        }

        this.store.updateStore({
            lastUndoRedo: Date.now(),
        })

        this.query.engine.srEmitter$.next({
            type: SRActionTypes.forceRendering,
            data: {},
            options: {
                isUndoable: false,
            },
        })
    }

    private async redo({
        instruments,
        pitchToChannelMapping,
    }: {
        instruments: InstrumentsJSON
        pitchToChannelMapping: PitchToChannelMapping
    }) {
        const stepToRedoTo = this.manager.getStateToRedoTo()
        const layersToAdd =
            stepToRedoTo && stepToRedoTo.compositionWorkflow
                ? this.getLoadingLayersToAdd(
                      stepToRedoTo.compositionWorkflow.step2
                  )
                : []
        const result = this.manager.redo()

        if (!result) {
            return
        }

        for (const layer of layersToAdd) {
            await this.setLayerData({
                layerKey: layer.layerName,
                layer: layer,
                instruments,
                pitchToChannelMapping,
            })
        }

        if (!this.query.isStep2Part2) {
            await ParentClass.waitUntilTrueForObservable(
                this.query.select("engine"),
                engine => {
                    return engine !== undefined
                }
            )

            this.store.updateStore({
                lastUndoRedo: Date.now(),
            })
        }

        this.query.engine.srEmitter$.next({
            type: SRActionTypes.forceRendering,
            data: {
                renderingType: ["All"],
            },

            options: {
                isUndoable: false,
            },
        })
    }

    private setGain(): void {}
}
