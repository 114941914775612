<div class="modal-wrapper">
    <div modal class="modal" *ngIf="modals.settings !== undefined">
        <div class="modal-background" (click)="closeModal('settings')"></div>
        <div class="modal-container settings-modal-container">
            <settings
                (close)="closeModal('settings')"
                [input]="modals.settings"></settings>
        </div>
    </div>

    <div modal class="modal" *ngIf="modals.sectionEditing !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('sectionEditing')"></div>
        <div class="modal-container settings-modal-container">
            <insert-section
                (close)="closeModal('sectionEditing')"
                [sectionEditing]="modals.sectionEditing"></insert-section>
        </div>
    </div>

    <div modal class="modal" *ngIf="modals.sectionInpainting !== undefined">
        <div class="modal-background"></div>
        <div class="modal-container settings-modal-container">
            <section-inpainting
                (close)="closeModal('sectionInpainting')"
                [input]="modals.sectionInpainting"></section-inpainting>
        </div>
    </div>

    <div modal class="modal" *ngIf="modals.layerGeneration !== undefined">
        <div class="modal-background"></div>
        <div class="modal-container settings-modal-container">
            <layer-generation
                (close)="closeModal('layerGeneration')"
                [input]="modals.layerGeneration"></layer-generation>
        </div>
    </div>

    <div
        modal
        class="modal select-gp-modal"
        *ngIf="modals.styleModalForCW"
        av-data="select-style-modal">
        <div
            class="modal-background"
            (click)="closeModal('styleModalForCW')"></div>
        <div class="modal-container">
            <select-gp-for-cw
                [type]="modals.styleModalForCW.type"
                [subtitle]="modals.styleModalForCW.subtitle"
                [selectGPForCW]="modals.styleModalForCW.selectGPForCW"
                [loading]="modals.styleModalForCW.loading"></select-gp-for-cw>
        </div>
    </div>

    <div modal class="modal" *ngIf="modals.successModal">
        {{ modals.successModal }}
        <div
            class="modal-background"
            (click)="closeModal('successModal')"
            av-data="close-success-modal"></div>
        <div class="modal-container settings-modal-container">
            <success-modal
                [params]="modals.successModal"
                (close)="closeModal('successModal')"></success-modal>
        </div>
    </div>

    <div
        modal
        class="modal"
        *ngIf="modals.selectCompositionWorkflow !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('selectCompositionWorkflow')"></div>
        <div class="modal-container settings-modal-container">
            <select-composition-workflow
                (close)="closeModal('selectCompositionWorkflow')"
                [input]="
                    modals.selectCompositionWorkflow
                "></select-composition-workflow>
        </div>
    </div>

    <div
        modal
        class="cw-create-composition-modal modal"
        *ngIf="modals.cwCreateComposition !== undefined">
        <cw-create-composition
            (close)="closeModal('cwCreateComposition')"
            [input]="modals.cwCreateComposition"></cw-create-composition>
    </div>

    <div
        modal
        class="set-key-signature-modal modal"
        *ngIf="modals.setKeySignature !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('setKeySignature')"></div>
        <div class="modal-container">
            <set-key-signature
                (close)="closeModal('setKeySignature')"
                [input]="modals.setKeySignature"></set-key-signature>
        </div>
    </div>

    <div
        modal
        class="customize-effects-modal modal"
        *ngIf="modals.customizePianorollFX !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('customizePianorollFX')"></div>
        <div class="modal-container">
            <customize-pianoroll-fx
                (close)="closeModal('customizePianorollFX')"
                [effects]="
                    modals.customizePianorollFX
                "></customize-pianoroll-fx>
        </div>
    </div>

    <div
        modal
        class="modal create-template-accompaniment-pack-modal"
        *ngIf="modals.createTemplateAccompanimentPack !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('createTemplateAccompanimentPack')"></div>
        <div class="modal-container">
            <create-template-accompaniment-pack
                (close)="closeModal('createTemplateAccompanimentPack')"
                [parameters]="
                    modals.createTemplateAccompanimentPack
                "></create-template-accompaniment-pack>
        </div>
    </div>

    <div
        modal
        class="modal modals-select-layer"
        *ngIf="modals.selectLayer !== undefined">
        <div class="modal-background" (click)="closeModal('selectLayer')"></div>
        <div class="modal-container">
            <select-layer-modal
                (close)="closeModal('selectLayer')"
                [parameters]="modals.selectLayer"></select-layer-modal>
        </div>
    </div>

    <div
        modal
        class="modal chord-dataset-modal"
        *ngIf="modals.chordDatasetSelectionModal !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('chordDatasetSelectionModal')"></div>
        <div class="modal-container">
            <chord-datasets
                (close)="
                    closeModal('chordDatasetSelectionModal')
                "></chord-datasets>
        </div>
    </div>

    <div modal class="modal" *ngIf="modals.sectionResize$ !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('sectionResize$')"></div>
        <div class="modal-container">
            <section-resize-modal
                [parameters]="modals.sectionResize$"
                (close)="closeModal('sectionResize$')"></section-resize-modal>
        </div>
    </div>

    <div modal class="modal" *ngIf="modals.sectionCopyChords$ !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('sectionCopyChords$')"></div>
        <div class="modal-container">
            <section-copy-modal
                [parameters]="modals.sectionCopyChords$"
                (close)="closeModal('sectionCopyChords$')"></section-copy-modal>
        </div>
    </div>

    <div modal class="rename-modal modal" *ngIf="modals.rename !== undefined">
        <div class="modal-background" (click)="closeModal('rename')"></div>
        <div class="modal-container">
            <rename
                (close)="closeModal('rename')"
                [renameModal]="modals.rename"></rename>
        </div>
    </div>

    <div modal class="modal" *ngIf="modals.desktopAppBugReport$">
        <div
            class="modal-background"
            (click)="closeModal('desktopAppBugReport$')"></div>
        <div class="modal-container">
            <desktop-app-bug-report-modal
                (close)="
                    closeModal('desktopAppBugReport$')
                "></desktop-app-bug-report-modal>
        </div>
    </div>

    <div modal class="modal simple-modal" *ngIf="modals.simpleModal != null">
        <div class="modal-background" (click)="closeModal('simpleModal')"></div>
        <div class="modal-container">
            <simple-modal
                (close)="closeModal('simpleModal')"
                [simpleModal]="modals.simpleModal"></simple-modal>
        </div>
    </div>

    <div
        modal
        class="modal auto-mix-modal"
        *ngIf="modals.addCustomLayer !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('addCustomLayer')"></div>
        <div class="modal-container">
            <add-custom-layer
                (close)="closeModal('addCustomLayer')"
                [addCustomLayer]="modals.addCustomLayer"></add-custom-layer>
        </div>
    </div>

    <div modal class="modal delete-modal" *ngIf="deleteComposition">
        <div class="modal-background" (click)="deleteComposition = false"></div>
        <div class="modal-container">
            <div class="modal-title">Confirm deletion</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="deleteComposition = false" />
            </div>
            <div class="modal-subtitle">
                Are you sure you want to delete this composition?
            </div>

            <div
                class="modal-button modal-text-button"
                (click)="deleteComposition = false">
                Cancel
            </div>
            <div
                class="modal-button modal-red-button"
                (click)="confirmDeleteComposition()">
                Delete
            </div>
        </div>
    </div>

    <div
        modal
        class="modal delete-modal"
        *ngIf="modals.deleteGenerationProfile != null">
        <div
            class="modal-background"
            (click)="closeModal('deleteGenerationProfile')"></div>
        <div class="modal-container">
            <div class="modal-title">Confirm deletion</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeModal('deleteGenerationProfile')" />
            </div>
            <div class="modal-subtitle">
                Are you sure you want to delete "{{
                    modals.deleteGenerationProfile.name
                }}"?
            </div>

            <div
                class="modal-button modal-text-button"
                (click)="closeModal('deleteGenerationProfile')">
                Cancel
            </div>
            <div
                class="modal-button modal-red-button"
                (click)="confirmDeleteGenerationProfile()">
                Delete
            </div>
        </div>
    </div>

    <div
        modal
        class="modal delete-modal"
        *ngIf="modals.deleteGenerationProfileLayer != null">
        <div
            class="modal-background"
            (click)="closeModal('deleteGenerationProfileLayer')"></div>
        <div class="modal-container">
            <div class="modal-title">Confirm deletion</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeModal('deleteGenerationProfileLayer')" />
            </div>
            <div class="modal-subtitle">
                Are you sure you want to delete "{{
                    modals.deleteGenerationProfileLayer.name
                }}"?
            </div>

            <div
                class="modal-button modal-text-button"
                (click)="closeModal('deleteGenerationProfileLayer')">
                Cancel
            </div>
            <div
                class="modal-button modal-red-button"
                (click)="confirmDeleteGenerationProfileLayer()">
                Delete
            </div>
        </div>
    </div>

    <div
        modal
        class="modal gp-library-filters-modal"
        *ngIf="modals.gpLibraryFilters != null">
        <div
            class="modal-background"
            (click)="closeModal('gpLibraryFilters')"></div>
        <div class="modal-container">
            <gp-library-filters
                (close)="closeModal('gpLibraryFilters')"
                [filters]="modals.gpLibraryFilters"></gp-library-filters>
        </div>
    </div>

    <div
        modal
        class="modal form-tags-selection-modal"
        *ngIf="modals.selectFormTags != null">
        <div
            class="modal-background"
            (click)="closeModal('selectFormTags')"></div>
        <div class="modal-container">
            <form-tags-selection
                [formTags]="modals.selectFormTags.formTags"
                (close)="closeModal('selectFormTags')"></form-tags-selection>
        </div>
    </div>

    <div
        modal
        class="modal material-variations-modal"
        *ngIf="modals.materialVariations">
        <div
            class="modal-background"
            (click)="closeModal('materialVariations')"></div>
        <div class="modal-container">
            <material-variations
                (close)="
                    closeModal('materialVariations')
                "></material-variations>
        </div>
    </div>

    <div modal class="modal create-gp-modal" *ngIf="modals.createGP">
        <div class="modal-background" (click)="closeModal('createGP')"></div>
        <div class="modal-container">
            <create-gp (close)="closeModal('createGP')"></create-gp>
        </div>
    </div>

    <div modal class="modal gp-influence-modal" *ngIf="modals.gpInfluence">
        <div class="modal-background" (click)="closeModal('gpInfluence')"></div>
        <div class="modal-container">
            <gp-influence
                (close)="closeModal('gpInfluence')"
                [file]="modals.gpInfluence"></gp-influence>
        </div>
    </div>

    <div
        modal
        class="modal download-all-instruments-modal"
        *ngIf="modals.downloadAllInstruments">
        <div
            class="modal-background"
            (click)="closeModal('downloadAllInstruments')"></div>
        <div class="modal-container">
            <download-all-instruments
                [parameters]="modals.downloadAllInstruments"
                (close)="
                    closeModal('downloadAllInstruments')
                "></download-all-instruments>
        </div>
    </div>

    <div
        modal
        class="modal refresh-gp-source-packs-modal"
        *ngIf="modals.refreshGPSourcePacks">
        <div
            class="modal-background"
            (click)="closeModal('refreshGPSourcePacks')"></div>
        <div class="modal-container">
            <refresh-gp-source-packs
                (close)="
                    closeModal('refreshGPSourcePacks')
                "></refresh-gp-source-packs>
        </div>
    </div>

    <div
        modal
        class="modal score-corrupted-modal"
        *ngIf="modals.scoreCorrupted">
        <div class="modal-background"></div>
        <div class="modal-container">
            <score-corrupted
                [input]="modals.scoreCorrupted"
                (close)="closeModal('scoreCorrupted')"></score-corrupted>
        </div>
    </div>

    <div
        modal
        class="modal resume-download-all-instruments-modal"
        *ngIf="modals.resumeDownloadAllInstruments">
        <div
            class="modal-background"
            (click)="closeModal('resumeDownloadAllInstruments')"></div>
        <div class="modal-container">
            <resume-download-all-instruments
                (close)="
                    closeModal('resumeDownloadAllInstruments')
                "></resume-download-all-instruments>
        </div>
    </div>

    <div
        modal
        class="modal pitch-range-control-modal"
        *ngIf="modals.pitchRangeControl">
        <div
            class="modal-background"
            (click)="closeModal('pitchRangeControl')"></div>
        <div class="modal-container">
            <div class="modal-title">Pitch range control</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeModal('pitchRangeControl')" />
            </div>
            <pitch-range-control
                [data]="modals.pitchRangeControl.pack"
                [layerName]="modals.pitchRangeControl.layer.name"
                (close)="closeModal('pitchRangeControl')"></pitch-range-control>
        </div>
    </div>

    <div
        modal
        class="modal emotion-confirmation-modal"
        *ngIf="modals.emotionConfirmation != null">
        <div
            class="modal-background"
            (click)="closeModal('emotionConfirmation')"></div>
        <div class="modal-container">
            <gp-emotion-selection
                [emotion]="modals.emotionConfirmation"
                (close)="
                    closeModal('emotionConfirmation')
                "></gp-emotion-selection>
        </div>
    </div>

    <div
        modal
        class="compare-two-gps-modal modal"
        *ngIf="modals.compareTwoGPs != null">
        <div
            class="modal-background"
            (click)="closeModal('compareTwoGPs')"></div>
        <div class="modal-container">
            <compare-two-gps
                (close)="closeModal('compareTwoGPs')"></compare-two-gps>
        </div>
    </div>

    <div
        modal
        class="modal automixing-modal"
        *ngIf="modals.autoMixing !== undefined">
        <div class="modal-background" (click)="closeModal('autoMixing')"></div>
        <div class="modal-container">
            <auto-mixing
                [autoMixing]="modals.autoMixing"
                (close)="closeModal('autoMixing')"></auto-mixing>
        </div>
    </div>

    <div
        modal
        class="modal pack-synchronisation-modal"
        *ngIf="modals.packSynchronisation != null">
        <div
            class="modal-background"
            (click)="closeModal('packSynchronisation')"></div>
        <div class="modal-container">
            <pack-synchronisation
                [pack]="modals.packSynchronisation.pack"
                [layer]="modals.packSynchronisation.layer"
                (close)="
                    closeModal('packSynchronisation')
                "></pack-synchronisation>
        </div>
    </div>

    <div
        modal
        class="modal gp-layer-mixing-modal"
        *ngIf="modals.gpLayerMixing != null">
        <div
            class="modal-background"
            (click)="closeModal('gpLayerMixing')"></div>
        <div class="modal-container">
            <gp-layer-mixing
                [layer]="modals.gpLayerMixing"
                (close)="closeModal('gpLayerMixing')"></gp-layer-mixing>
        </div>
    </div>

    <div modal class="modal publish-gp-modal" *ngIf="modals.publishGP != null">
        <div class="modal-background" (click)="closeModal('publishGP')"></div>
        <div class="modal-container">
            <publish-gp
                [gp]="modals.publishGP"
                (close)="closeModal('publishGP')"></publish-gp>
        </div>
    </div>

    <div
        modal
        class="modal no-packs-to-synch-to-modal"
        *ngIf="modals.noPacksToSynchTo">
        <div
            class="modal-background"
            (click)="closeModal('noPacksToSynchTo')"></div>
        <div class="modal-container">
            <div class="modal-title">No pack to synchronise to</div>
            <div class="modal-subtitle">
                In order to use the synchronisation feature, you need to have at
                least one other pack, on a different layer than the pack you
                selected for synchronisation.<br /><br />Please note that you
                cannot synchronise to packs added to an Ornament layer.
            </div>

            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeModal('noPacksToSynchTo')" />
            </div>

            <a-button
                class="grey"
                label="Got it"
                width="100%"
                (onClick)="closeModal('noPacksToSynchTo')"></a-button>
        </div>
    </div>

    <div
        modal
        class="modal gp-instrument-edition"
        *ngIf="modals.gpInstrumentSelectionForPack !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('gpInstrumentSelectionForPack')"></div>
        <div class="modal-container">
            <gp-instrument-selection
                [parameters]="modals.gpInstrumentSelectionForPack"
                (close)="
                    closeModal('gpInstrumentSelectionForPack')
                "></gp-instrument-selection>
        </div>
    </div>

    <div
        modal
        class="modal instrument-selection-modal"
        *ngIf="modals.doublingInstrumentSelection !== undefined"
        oncontextmenu="return false">
        <div
            class="modal-background"
            (click)="closeModal('doublingInstrumentSelection')"></div>
        <div class="modal-container" #instrumentSelectionModal>
            <doublesearch
                [parameters]="modals.doublingInstrumentSelection"
                (close)="
                    closeModal('doublingInstrumentSelection')
                "></doublesearch>
        </div>
    </div>

    <div
        modal
        class="edit-double-modal modal"
        *ngIf="modals.editDouble !== undefined">
        <div class="modal-background" (click)="closeModal('editDouble')"></div>
        <div class="modal-container">
            <edit-double
                [parameters]="modals.editDouble"
                (close)="closeModal('editDouble')"></edit-double>
        </div>
    </div>

    <div
        modal
        class="rename-modal modal"
        *ngIf="modals.editDoubleFolder !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('editDoubleFolder')"></div>
        <div class="modal-container">
            <edit-double-folder
                [parameters]="modals.editDoubleFolder"
                (close)="closeModal('editDoubleFolder')"></edit-double-folder>
        </div>
    </div>

    <div
        modal
        class="modal gp-instrument-edition instrument-similarity-modal"
        *ngIf="modals.findSimilarInstrumentsForPack !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('findSimilarInstrumentsForPack')"></div>
        <div class="modal-container">
            <find-similar-instruments
                [parameters]="modals.findSimilarInstrumentsForPack"
                (close)="
                    closeModal('findSimilarInstrumentsForPack')
                "></find-similar-instruments>
        </div>
    </div>

    <div
        modal
        class="layer-priorities-modal modal"
        *ngIf="modals.layersOrder != null">
        <div class="modal-background" (click)="closeModal('layersOrder')"></div>
        <div class="modal-container">
            <layer-priorities
                (close)="closeModal('layersOrder')"></layer-priorities>
        </div>
    </div>

    <div
        modal
        class="modal instrument-selection-modal"
        *ngIf="modals.instrumentSelection !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('instrumentSelection')"></div>
        <div class="modal-container">
            <instrumentsearch
                [parameters]="modals.instrumentSelection"
                type="instruments"
                (close)="closeModal('instrumentSelection')"></instrumentsearch>
        </div>
    </div>

    <div
        modal
        class="instrument-selection-modal modal"
        *ngIf="modals.instrumentSelectionCompositionWorkflows !== undefined">
        <div
            class="modal-background"
            (click)="
                closeModal('instrumentSelectionCompositionWorkflows')
            "></div>
        <div class="modal-container">
            <instrument-selection-composition-workflows
                [parameters]="modals.instrumentSelectionCompositionWorkflows"
                (close)="
                    closeModal('instrumentSelectionCompositionWorkflows')
                "></instrument-selection-composition-workflows>
        </div>
    </div>

    <div
        modal
        class="rename-profile-category-modal modal"
        *ngIf="modals.renameProfileCategory != null">
        <div
            class="modal-background"
            (click)="closeModal('renameProfileCategory')"></div>
        <div class="modal-container">
            <rename-profile-category
                (close)="closeModal('renameProfileCategory')"
                [category]="
                    modals.renameProfileCategory
                "></rename-profile-category>
        </div>
    </div>

    <div
        modal
        class="reward-claimed-modal modal"
        *ngIf="modals.rewardClaimed != null">
        <div
            class="modal-background"
            (click)="closeModal('rewardClaimed')"></div>
        <div class="modal-container">
            <reward-claimed
                (close)="closeModal('rewardClaimed')"
                [reward]="modals.rewardClaimed"></reward-claimed>
        </div>
    </div>

    <div
        modal
        class="select-gp-influence-modal modal"
        *ngIf="modals.selectGPInfluence != null">
        <div
            class="modal-background"
            (click)="closeModal('selectGPInfluence')"></div>
        <div class="modal-container">
            <select-gp-influence
                (close)="closeModal('selectGPInfluence')"
                [data]="modals.selectGPInfluence"></select-gp-influence>
        </div>
    </div>

    <div modal class="autofill-modal modal" *ngIf="modals.autofill != null">
        <div class="modal-background" (click)="closeModal('autofill')"></div>
        <div class="modal-container">
            <autofill (close)="closeModal('autofill')"></autofill>
        </div>
    </div>

    <div modal class="modal subscription-modal" *ngIf="subscriptionModal">
        <div class="modal-background" (click)="closeSubscriptionModal()"></div>
        <div class="modal-container">
            <div class="modal-title">Upgrade to access this feature</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeSubscriptionModal()" />
            </div>
            <div class="modal-subtitle">{{ subscriptionModalDescription }}</div>
            <div class="modal-embed" *ngIf="subscriptionEmbed != ''">
                <img
                    src="{{ subscriptionEmbed }}"
                    (click)="clickEmbeddedLink()" />
            </div>

            <div
                class="modal-button modal-green-button"
                (click)="viewSubscriptionPlans()">
                View Subscription Plans
            </div>
        </div>
    </div>

    <div
        modal
        class="modal pack-dataset-modal"
        *ngIf="modals.selectPack != null">
        <div class="modal-background" (click)="closePackSelectionModal()"></div>
        <div class="modal-container">
            <pack-dataset-modal
                [input]="modals.selectPack"
                (close)="closeModal('selectPack')"></pack-dataset-modal>
        </div>
    </div>

    <div
        modal
        class="modal do-action-before-modal"
        *ngIf="modals.doActionsBefore !== undefined">
        <do-action-before
            [input]="modals.doActionsBefore"
            (close)="closeModal('doActionsBefore')"></do-action-before>
    </div>

    <div modal class="modal changelog-modal" *ngIf="modals.changelog">
        <div class="modal-background" (click)="closeModal('changelog')"></div>
        <div class="modal-container">
            <changelog (close)="closeModal('changelog')"></changelog>
        </div>
    </div>

    <div
        modal
        class="modal add-layer-to-gp-modal"
        *ngIf="modals.addLayerToGP != null">
        <div
            class="modal-background"
            (click)="closeModal('addLayerToGP')"></div>
        <div class="modal-container">
            <div class="modal-title">Add layer</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeModal('addLayerToGP')" />
            </div>

            <dropdown
                class="full-width-dropdown"
                type="light"
                [showTooltip]="false"
                [value]="addLayerType"
                [items]="gpLayerOptions"
                (selectedItem)="changeLayerType($event)"></dropdown>

            <div class="error" *ngIf="addLayerToGPError != ''">
                {{ addLayerToGPError }}
            </div>

            <div class="add-layer-to-gp-button">
                <a-button
                    label="Confirm selection"
                    type="secondary"
                    width="100%"
                    (onClick)="confirmLayerAddition()"></a-button>
            </div>
        </div>
    </div>

    <div modal class="modal gp-development-modal" *ngIf="modals.gpDevelopment">
        <div
            class="modal-background"
            (click)="closeModal('gpDevelopment')"></div>
        <div class="modal-container">
            <gpdevelopment
                [settings]="modals.gpDevelopment"
                (close)="closeModal('gpDevelopment')"></gpdevelopment>
        </div>
    </div>

    <div
        modal
        class="modal auto-mix-modal"
        *ngIf="modals.mixingOptions !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('mixingOptions')"></div>
        <div class="modal-container">
            <mixing-options
                [mixingOptions]="modals.mixingOptions"
                (close)="closeModal('mixingOptions')"></mixing-options>
        </div>
    </div>

    <div
        modal
        class="modal trainingset-labels-modal"
        *ngIf="modals.trainingsetLabels !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('trainingsetLabels')"></div>
        <div class="modal-container">
            <trainingset-labels
                [data]="modals.trainingsetLabels"
                (close)="closeModal('trainingsetLabels')"></trainingset-labels>
        </div>
    </div>

    <div
        modal
        class="modal unsupported-os-modal"
        *ngIf="modals.unsupportedOS !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('unsupportedOS')"></div>
        <div class="modal-container">
            <unsupported-os
                [data]="modals.unsupportedOS"
                (close)="closeModal('unsupportedOS')"></unsupported-os>
        </div>
    </div>

    <div
        modal
        class="modal auto-mix-modal"
        *ngIf="modals.instrumentDownloading === true">
        <div
            class="modal-background"
            (click)="closeModal('instrumentDownloading')"></div>
        <div class="modal-container">
            <instrument-downloading
                (close)="
                    closeModal('instrumentDownloading')
                "></instrument-downloading>
        </div>
    </div>

    <div modal class="modal auto-mix-modal" *ngIf="downloadDesktopAppModal">
        <div
            class="modal-background"
            (click)="closeDownloadDesktopAppModal()"></div>
        <div class="modal-container">
            <div class="modal-title">Desktop app required</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeDownloadDesktopAppModal()" />
            </div>

            <img
                src="assets/img/download_desktop_app.svg"
                (click)="downloadApp()"
                class="download-app-icon" />

            <div class="modal-subtitle">
                Playing back audio in real-time is incompatible with your
                browser. In order to use this feature, please download and use
                our desktop app.
            </div>

            <div class="download-app-button-wrapper">
                <a-button
                    type="secondary"
                    (click)="downloadApp()"
                    width="100%"
                    label="Download app"></a-button>
            </div>
        </div>
    </div>

    <div
        modal
        class="modal auto-mix-modal listening-test-modal"
        *ngIf="listeningTestFeedback">
        <div
            class="modal-background"
            (click)="closeListeningTestsFeedback()"></div>
        <div class="modal-container">
            <div class="modal-title">
                Feedback on
                {{
                    listeningTestFeedbackObject != null &&
                    listeningTestFeedbackObject.composition != null
                        ? listeningTestFeedbackObject.composition.name
                        : ""
                }}
            </div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeListeningTestsFeedback()" />
            </div>

            <div *ngIf="!addIssue">
                <div *ngIf="!listeningTestFeedbackObject.approve">
                    <mat-form-field appearance="fill">
                        <mat-label>Issues</mat-label>

                        <mat-select
                            appearance="fill"
                            [formControl]="issues"
                            multiple
                            [(ngModel)]="listeningFeedback.issues">
                            <mat-optgroup
                                [label]="category"
                                *ngFor="
                                    let category of getListOfIssuesCategories()
                                ">
                                <mat-option
                                    *ngFor="let issue of listOfIssues[category]"
                                    [value]="issue.id"
                                    >{{ issue.name }}</mat-option
                                >
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field
                        class="listening-test-textarea"
                        appearance="fill">
                        <mat-label>Comments</mat-label>
                        <textarea
                            matInput
                            [(ngModel)]="listeningFeedback.comments"></textarea>
                    </mat-form-field>

                    <mat-form-field
                        class="listening-test-textarea"
                        appearance="fill">
                        <mat-label>Severity</mat-label>
                        <mat-select
                            appearance="fill"
                            [formControl]="severity"
                            [(ngModel)]="listeningFeedback.severity">
                            <mat-option [value]="'Low'">Low</mat-option>
                            <mat-option [value]="'Medium'">Medium</mat-option>
                            <mat-option [value]="'High'">High</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-form-field
                    class="listening-test-textarea"
                    appearance="fill">
                    <mat-label>Added by</mat-label>
                    <input [(ngModel)]="listeningFeedback.addedBy" matInput />
                </mat-form-field>

                <mat-form-field
                    class="listening-test-textarea"
                    appearance="fill">
                    <mat-label>Feature release (e.g. v2.18.0)</mat-label>
                    <input
                        [(ngModel)]="listeningFeedback.featureRelease"
                        matInput />
                </mat-form-field>

                <button (click)="addCompositionFeedback()">Add feedback</button>
            </div>
        </div>
    </div>

    <div modal class="modal shortcut-modal" *ngIf="shortcutModal">
        <div class="modal-background" (click)="shortcutModal = false"></div>

        <div class="modal-container">
            <ngx-simplebar
                class="shortcut-modal-scroll-container"
                [options]="options">
                <div class="modal-title">Editor Shortcuts</div>
                <div class="modal-close">
                    <img
                        src="assets/img/close.svg"
                        (click)="shortcutModal = false" />
                </div>

                <div class="shortcuts-container">
                    <div class="shortcut-container-headline">General</div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Toggle Shortcut panel
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Esc</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Toggle Pencil Mode
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Ctrl</div>
                            <div class="shortcut-subcontainer">E</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Zoom in / out
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Ctrl</div>
                            <div class="shortcut-subcontainer">+</div>
                            /
                            <div class="shortcut-subcontainer">-</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">Reset Zoom</div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Ctrl</div>
                            <div class="shortcut-subcontainer">0</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Undo last action
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Ctrl</div>
                            <div class="shortcut-subcontainer">Z</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Redo last action
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Ctrl</div>
                            <div class="shortcut-subcontainer">Shift</div>
                            <div class="shortcut-subcontainer">Z</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">Scroll Right</div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Shift</div>
                            <div class="shortcut-subcontainer">
                                Mouse Scroll Up
                            </div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">Scroll Left</div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Shift</div>
                            <div class="shortcut-subcontainer">
                                Mouse Scroll Down
                            </div>
                        </div>
                    </div>

                    <div class="shortcut-container-separator"></div>

                    <div class="shortcut-container-headline">Playback</div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Reset playback to start
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Ctrl</div>

                            <div class="shortcut-subcontainer">Enter</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">Play / Pause</div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Spacebar</div>
                        </div>
                    </div>

                    <div class="shortcut-container-separator"></div>

                    <div class="shortcut-container-headline">Notes</div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Copy / Cut / Paste note selection
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Ctrl</div>
                            <div class="shortcut-subcontainer">C</div>
                            /
                            <div class="shortcut-subcontainer">X</div>
                            /
                            <div class="shortcut-subcontainer">V</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Quantize note to beat (while resizing)
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">(Hold) Ctrl</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">Move notes</div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Up Arrow</div>
                            /
                            <div class="shortcut-subcontainer">Down Arrow</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Delete single note
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">
                                Right Mouse Click
                            </div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Delete selection
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Delete</div>
                        </div>
                    </div>

                    <div class="shortcut-container-separator"></div>

                    <div class="shortcut-container-headline">
                        Tracks / Instruments
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Navigate through tracks
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Up Arrow</div>
                            /
                            <div class="shortcut-subcontainer">Down Arrow</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Select multiple tracks
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">
                                (Hold) Shift
                            </div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Select / Unselect single track
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">(Hold) Ctrl</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Duplicate track
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Ctrl</div>
                            <div class="shortcut-subcontainer">D</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">Delete Track</div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">Delete</div>
                        </div>
                    </div>

                    <div class="shortcut-container">
                        <div class="shortcut-container-title">
                            Open instrument patch selection
                        </div>
                        <div class="shortcut-keys">
                            <div class="shortcut-subcontainer">i</div>
                        </div>
                    </div>
                </div>
            </ngx-simplebar>
        </div>
    </div>

    <div modal class="modal compatibility-modal" *ngIf="compatibilityModal">
        <div class="modal-background" (click)="closeCompatibilityModal()"></div>
        <div class="modal-container">
            <div class="modal-title">
                Your browser is not compatible with this feature
            </div>
            <div class="modal-subtitle">
                Editing features require the use of an up-to-date web browser.
                We recommend the following:
            </div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeCompatibilityModal()" />
            </div>

            <div class="browser-list">
                <div class="browser-item">
                    <img src="assets/img/browsers/chrome.svg" />
                    <div class="browser-item-version">
                        Chrome version 85 or later
                    </div>
                </div>

                <div class="browser-item">
                    <img src="assets/img/browsers/safari.svg" />
                    <div class="browser-item-version">
                        Safari version 13 or later
                    </div>
                </div>

                <div class="browser-item">
                    <img src="assets/img/browsers/firefox.svg" />
                    <div class="browser-item-version">
                        Firefox version 80 or later
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        modal
        class="modal incompatible-composition-modal"
        *ngIf="incompatibleComposition">
        <div
            class="modal-background"
            (click)="incompatibleComposition = false"></div>
        <div class="modal-container">
            <div class="modal-title">
                This composition is incompatible with this feature
            </div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="incompatibleComposition = false" />
            </div>
            <div class="modal-subtitle">
                Because we have made significant "under the hood" changes in how
                compositions are created, any composition generated before
                August 13th 2019 is incompatible with editing features.
            </div>
        </div>
    </div>

    <div modal class="modal delete-modal" *ngIf="deleteFolder">
        <div class="modal-background" (click)="deleteFolder = false"></div>
        <div class="modal-container">
            <div class="modal-title">Confirm deletion</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="deleteFolder = false" />
            </div>
            <div class="modal-subtitle">
                This action is irreversible, and will also delete ALL elements
                contained in the folder.
            </div>

            <div
                class="modal-button modal-text-button"
                (click)="deleteFolder = false">
                Cancel
            </div>
            <div
                class="modal-button modal-red-button"
                (click)="confirmDeleteFolder()">
                Delete
            </div>
        </div>
    </div>

    <div modal class="modal delete-modal" *ngIf="deleteMulti">
        <div class="modal-background" (click)="closeDeleteMulti()"></div>
        <div class="modal-container">
            <div class="modal-title">Confirm deletion</div>
            <div class="modal-close">
                <img src="assets/img/close.svg" (click)="closeDeleteMulti()" />
            </div>
            <div class="modal-subtitle">
                This action is irreversible, and will delete ALL elements
                selected, including elements contained in any selected folder.
            </div>

            <div
                class="modal-button modal-text-button"
                (click)="closeDeleteMulti()">
                Cancel
            </div>
            <div
                class="modal-button modal-red-button"
                (click)="confirmDeleteMulti()">
                Delete
            </div>
        </div>
    </div>

    <div modal class="modal update-modal" *ngIf="updateModal">
        <div class="modal-background"></div>
        <div class="modal-container">
            <div class="modal-title">AIVA has been updated!</div>
            <div class="modal-subtitle">
                To enjoy the latest version and carry on composing music, please
                refresh your browser page.
            </div>
        </div>
    </div>

    <div
        modal
        class="modal update-modal"
        *ngIf="modals.outdatedDesktopApp !== false">
        <div class="modal-background"></div>
        <div class="modal-container">
            <div class="modal-title">Your desktop app is out of date</div>
            <div class="modal-subtitle">
                Please install the latest version of the desktop app to continue
                using AIVA.

                <div class="outdated-modal-buttons">
                    <a-button
                        label="Download app update"
                        (click)="downloadBinaries()"
                        width="100%"
                        type="secondary"></a-button>
                </div>
            </div>
        </div>
    </div>

    <div
        modal
        class="modal discard-pianorolledit-modal"
        *ngIf="getDiscardInstrumentation()">
        <div
            class="modal-background"
            (click)="closeDiscardInstrumentationModal()"></div>
        <div class="modal-container">
            <div class="modal-title">
                Discard changes made to instrumentation?
            </div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeDiscardInstrumentationModal()" />
            </div>
            <div class="modal-subtitle">
                Replacing, regenerating or deleting a section will discard any
                previous changes made to the instrumentation. Are you sure you
                want to proceed?
            </div>

            <div
                class="modal-button modal-text-button"
                (click)="closeDiscardInstrumentationModal()">
                Cancel
            </div>
            <div
                class="modal-button modal-red-button"
                (click)="regenerateSection()">
                Discard
            </div>
        </div>
    </div>

    <div modal class="billing-preview-modal modal" *ngIf="billingPreviewModal">
        <div
            class="modal-background"
            (click)="billingPreviewModal = false"></div>
        <div class="modal-container">
            <div
                class="modal-title modal-title-billing-preview"
                *ngIf="!billingPreviewLoading">
                Confirm your subscription
            </div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="billingPreviewModal = false" />
            </div>

            <!-- in case of a downgrade -->
            <div
                class="preview-container"
                *ngIf="
                    billingPreview.preview.isDowngrade && !billingPreviewLoading
                ">
                <div class="downgrade-explanation">
                    By downgrading to this plan, you will continue enjoying your
                    current subscription until the end of your billing cycle ({{
                        billingPreview.preview.subscriptionEnd
                            | date : "mediumDate"
                    }}), and will be automatically switched to the new plan on
                    that same date.
                </div>

                <!-- open the downgrade survey -->
                <a-button
                    width="100%"
                    label="Downgrade"
                    class="plan-button plan-button-downgrade"
                    (onClick)="showDowngradeSubscriptionSurvey()"></a-button>
            </div>

            <!-- when upgrading or creating a subscription, this window will show us the billing preview -->
            <div
                class="preview-container"
                *ngIf="
                    !billingPreview.preview.isDowngrade &&
                    !billingPreviewLoading
                ">
                <div
                    class="preview-item"
                    *ngIf="billingPreview.preview.startingBalance != 0">
                    <div
                        class="preview-line-item"
                        *ngIf="billingPreview.preview.startingBalance < 0">
                        <div class="preview-item-description">
                            Starting balance
                        </div>
                        <div class="preview-item-amount">
                            - €
                            {{
                                formatAmount(
                                    billingPreview.preview.startingBalance
                                )
                            }}
                        </div>
                    </div>

                    <div
                        class="preview-line-item"
                        *ngIf="billingPreview.preview.startingBalance > 0">
                        <div class="preview-item-description">
                            Starting balance
                        </div>
                        <div class="preview-item-amount">
                            € {{
                                formatAmount(
                                    billingPreview.preview.startingBalance
                                )
                            }}
                        </div>
                    </div>
                </div>

                <div
                    class="preview-item"
                    *ngFor="
                        let line of billingPreview.preview.previewLines;
                        let i = index
                    ">
                    <div
                        class="preview-line-item"
                        *ngIf="line.amount <= 0 && !line.tax">
                        <div class="preview-item-description">
                            Unused time on
                            <span id="subscription-plan">{{
                                formatPlanName(line.plan.name)
                            }}</span>
                            after {{ line.period.start | date : "mediumDate" }}
                        </div>
                        <div class="preview-item-amount">
                            - € {{ formatAmount(line.amount) }}
                        </div>
                    </div>

                    <div
                        class="preview-line-item"
                        *ngIf="line.amount > 0 && !line.tax">
                        <div class="preview-item-description">
                            Recurring subscription to
                            <span id="subscription-plan">{{
                                formatPlanName(line.plan.name)
                            }}</span
                            >, starting
                            {{ line.period.start | date : "mediumDate" }}
                        </div>
                        <div class="preview-item-amount">
                            € {{ formatAmount(line.amount) }}
                        </div>
                    </div>

                    <div class="preview-line-item" *ngIf="line.tax">
                        <div class="preview-item-description">
                            {{ line.vatType }}
                            <span *ngIf="line.amount < 0"
                                >(Proration adjustment)</span
                            >
                        </div>
                        <div class="preview-item-amount">
                            <span *ngIf="line.amount < 0">-</span> € {{
                                formatAmount(line.amount)
                            }}
                        </div>
                    </div>
                </div>

                <div class="preview-item-total">
                    <div class="preview-item-total-title">Total:</div>
                    <div
                        class="preview-item-amount"
                        *ngIf="getPreviewTotal() >= 0">
                        € {{ formatAmount(getPreviewTotal()) }}
                    </div>
                    <div
                        class="preview-item-amount"
                        *ngIf="getPreviewTotal() < 0">
                        - € {{ formatAmount(getPreviewTotal()) }}
                    </div>
                </div>

                <div class="balance-description" *ngIf="getPreviewTotal() < 0">
                    Negative balance is non-refundable, but will be used as
                    credit towards any future subscription payments.
                </div>

                <a-button
                    class="primary subscription-primary-button"
                    width="100%"
                    (onClick)="changeSubscription(billingPreview.plan)"
                    [label]="'Subscribe'"></a-button>

                <div class="payment-disclaimer">
                    By clicking on subscribe, you accept the refund policy
                    stated in our
                    <a href="https://www.aiva.ai/legal/1"
                        >End User License Agreement</a
                    >.
                </div>
            </div>

            <!-- this shows while we are waiting for the billing preview to load -->
            <div class="loader-container" *ngIf="billingPreviewLoading">
                <div class="loader"></div>

                <div class="close-warning">
                    Please keep this page open until the checkout is completed
                </div>
            </div>
        </div>
    </div>

    <div
        modal
        class="downgrade-subscription-survey-modal modal"
        *ngIf="downgradeSubscriptionSurveyModal">
        <div
            class="modal-background"
            (click)="downgradeSubscriptionSurveyModal = false"></div>
        <div class="modal-container">
            <div class="modal-title modal-title-downgrade-survey"></div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="downgradeSubscriptionSurveyModal = false" />
            </div>

            <div class="emoji-image">😢</div>
            <div class="title">We're sorry to see you downgrade</div>
            <div class="sub-title">
                We value our customers’ input greatly. You can help us improve
                our services by letting us know why you would like to downgrade.
            </div>

            <div class="option-box">
                <div
                    class="radio-option"
                    *ngFor="let reason of downgradeSurveyReasons"
                    (click)="downgradeSurveyReasonChanged()">
                    <input
                        [id]="reason.value"
                        type="radio"
                        name="reason"
                        [value]="reason.value"
                        [(ngModel)]="downgradeSurvey.reason" />
                    <label for="{{ reason.value }}">{{ reason.label }}</label>
                </div>
            </div>

            <div
                class="required-fields-hint"
                *ngIf="downgradeSurvey.reason == ''">
                *Please select the most fitting reason to continue.
            </div>

            <div class="explanation-box">
                <textarea
                    name="explanation"
                    id="explanation"
                    cols="30"
                    rows="4"
                    placeholder="Anything you want to share?"
                    [(ngModel)]="downgradeSurvey.explanation"></textarea>
            </div>

            <div
                class="required-fields-hint"
                *ngIf="
                    downgradeSurvey.reason == 'other' &&
                    downgradeSurvey.explanation.length < 3
                ">
                *Please fill in an additonal explanation.
            </div>

            <div class="button-box">
                <a-button
                    class="downgrade"
                    label="Downgrade"
                    [disabled]="
                        downgradeSurvey.reason == '' ||
                        (downgradeSurvey.reason == 'other' &&
                            downgradeSurvey.explanation.length < 3)
                    "
                    (click)="changeSubscription()"></a-button>
                <div
                    class="dont-cancel"
                    (click)="downgradeSubscriptionSurveyModal = false">
                    Nevermind, I don't want to downgrade.
                </div>
            </div>
        </div>
    </div>

    <div
        modal
        class="subscription-status-info-modal modal"
        *ngIf="subscriptionStatusInfoModal">
        <div
            class="modal-background"
            (click)="subscriptionStatusInfoModal = false"></div>
        <div class="modal-container">
            <div class="checkmark-icon" *ngIf="subscriptionStatus == 'success'">
                <svg
                    class="checkmark-green"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52">
                    <circle
                        class="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none" />
                    <path
                        class="checkmark__check"
                        fill="transparent"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>
            <div class="cross-icon" *ngIf="subscriptionStatus == 'error'">
                <svg
                    class="cross-red"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52">
                    <circle
                        class="cross__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none" />
                    <path
                        class="cross__crossed"
                        fill="none"
                        d="M16 16 36 36 M36 16 16 36" />
                </svg>
            </div>

            <div class="modal-title" *ngIf="subscriptionStatus == 'success'">
                Thank you! :)
            </div>
            <div class="modal-title" *ngIf="subscriptionStatus == 'error'">
                What a bummer :(
            </div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="subscriptionStatusInfoModal = false" />
            </div>

            <div class="status-text" *ngIf="subscriptionStatus == 'success'">
                <div *ngIf="!subscriptionIsDowngrade">
                    Your subscription was successfully activated. Enjoy your
                    {{ subscribedToPlan }} plan!
                </div>
                <div *ngIf="subscriptionIsDowngrade">
                    You will be downgraded to the {{ subscribedToPlan }} plan on
                    {{ subscriptionEnd }}.
                </div>
            </div>
            <div class="status-text" *ngIf="subscriptionStatus == 'error'">
                Something went wrong. Please try again or contact Support.
            </div>
        </div>
    </div>

    <div modal class="download-modal modal" *ngIf="downloadModal">
        <div class="modal-background" (click)="closeDownloadModal()"></div>
        <div class="modal-container">
            <ng-container *ngIf="!downloadLoading">
                <div
                    class="modal-title modal-preset-title"
                    *ngIf="!selectStemsType">
                    Download Composition
                </div>
                <div
                    class="modal-title modal-preset-title"
                    *ngIf="selectStemsType">
                    <img src="assets/img/format/stems.svg" /> Stems Grouping
                </div>
                <div class="modal-close">
                    <img
                        src="assets/img/close.svg"
                        (click)="closeDownloadModal()" />
                </div>

                <div *ngIf="!selectStemsType">
                    <div id="types-list">
                        <div class="download-subtitle">
                            Download single files
                        </div>

                        <div
                            class="download-type"
                            (click)="downloadFile('mp3')">
                            <img src="assets/img/format/mp3.svg" />
                            <div class="download-type-title">MP3</div>
                        </div>

                        <div
                            class="download-type"
                            (click)="downloadFile('orchestrated-midi')">
                            <img src="assets/img/format/midi.svg" />
                            <div class="download-type-title">
                                Orchestrated MIDI
                            </div>
                        </div>

                        <div
                            class="download-type"
                            (click)="downloadFile('reduced-midi')">
                            <img src="assets/img/format/midi.svg" />
                            <div class="download-type-title">Reduced MIDI</div>
                        </div>

                        <div
                            class="download-type download-type-disabled tooltip"
                            *ngIf="showExportWAVOption()"
                            (click)="downloadFile('wav')">
                            <span class="tooltiptext"
                                >In order to download a WAV file, you first need
                                to render it using the "Render Uncompressed WAV"
                                button below.</span
                            >
                            <img src="assets/img/format/wav.svg" />
                            <div class="download-type-title">WAV</div>
                        </div>

                        <div
                            class="download-type"
                            *ngIf="!showExportWAVOption()"
                            (click)="downloadFile('wav')">
                            <img src="assets/img/format/wav.svg" />
                            <div class="download-type-title">WAV</div>
                        </div>

                        <div
                            class="download-type download-type-disabled tooltip"
                            *ngIf="showExportStemsOption()">
                            <span class="tooltiptext"
                                >In order to download Stems, you first need to
                                render it using the "Render Stems" button
                                below.</span
                            >
                            <img src="assets/img/format/stems.svg" />
                            <div class="download-type-title">Stems</div>
                        </div>

                        <div
                            class="download-type"
                            *ngIf="!showExportStemsOption()"
                            (click)="downloadFile('stems')">
                            <img src="assets/img/format/stems.svg" />
                            <div class="download-type-title">Stems</div>
                        </div>

                        <div
                            class="download-type"
                            (click)="downloadFile('chords')">
                            <img src="assets/img/format/chords.svg" />
                            <div class="download-type-title">Chords</div>
                        </div>

                        <div
                            class="download-type"
                            (click)="downloadFile('json')"
                            *ngIf="showJSONOption()">
                            <div class="download-type-title">JSON</div>
                        </div>

                        <div
                            class="download-type"
                            (click)="downloadBugReport()"
                            *ngIf="showJSONOption()">
                            <div class="download-type-title">Bug report</div>
                        </div>

                        <div class="download-subtitle">Other actions</div>

                        <div
                            class="download-type download-all export-wav"
                            (click)="downloadFile('all')">
                            <img src="assets/img/format/zip.svg" />
                            <div class="download-type-title">
                                Download zip (MP3 + MIDI)
                            </div>
                        </div>

                        <div
                            class="download-type download-all export-wav"
                            *ngIf="showExportWAVOption()"
                            (click)="exportWAV()">
                            <img src="assets/img/format/wav.svg" />
                            <div class="download-type-title">
                                Render Uncompressed WAV (16 bit, 44.1kHz)
                            </div>
                        </div>

                        <div
                            class="download-type download-all export-wav"
                            (click)="selectStemsType = !selectStemsType">
                            <img src="assets/img/format/stems.svg" />
                            <div class="download-type-title">
                                Render Stems (16 bit WAV)
                            </div>
                        </div>
                    </div>

                    <div id="download-bottom-container">
                        <div class="download-bottom-subcontainer">
                            <div class="download-subtitle">License:</div>
                            <div class="download-quota-remaining">
                                <span class="license-capitalization">{{
                                    getDownloadLicense()
                                }}</span
                                ><span *ngIf="showUpgradeSubscription()"
                                    >. To own the full copyright of this track,
                                    please
                                    <div
                                        class="download-upgrade-license text-button green-text-button"
                                        (click)="viewSubscriptionPlans()">
                                        Upgrade your Subscription
                                    </div>
                                    .</span
                                >
                            </div>
                        </div>

                        <div class="download-bottom-subcontainer">
                            <div class="download-subtitle">Monthly Quota:</div>
                            <div
                                class="download-quota-remaining"
                                *ngIf="!alreadyDownloadedComposition()">
                                You can download {{ getDownloadsLeft() }} more
                                composition(s) this month. Downloading the same
                                composition multiple times will only count as
                                one download.
                            </div>
                            <div
                                class="download-quota-remaining"
                                *ngIf="alreadyDownloadedComposition()">
                                You already downloaded this track. Downloading
                                again won't count towards your quota.
                            </div>
                        </div>
                    </div>

                    <div
                        id="download-filename-parameters"
                        (click)="addParamsToFilename = !addParamsToFilename">
                        <input
                            type="checkbox"
                            [(ngModel)]="addParamsToFilename" />
                        Add composition parameters to the file name
                    </div>
                </div>

                <div *ngIf="selectStemsType">
                    <div
                        class="stems-group-type"
                        (click)="stemsTypeSelection('layer')">
                        <img src="assets/img/format/layer.svg" />
                        <div class="stems-group-type-title">
                            Group by layer
                            <span
                                class="stems-group-type-subtitle"
                                *ngIf="recommendedStems() == 'layer'"
                                >Recommended for this composition</span
                            >
                        </div>
                    </div>

                    <div
                        class="stems-group-type"
                        (click)="stemsTypeSelection('section')">
                        <img src="assets/img/format/pie_chart.svg" />
                        <div class="stems-group-type-title">
                            Group by instrument section
                            <span
                                class="stems-group-type-subtitle"
                                *ngIf="recommendedStems() == 'section'"
                                >Recommended for this composition</span
                            >
                        </div>
                    </div>

                    <div
                        class="stems-group-type"
                        (click)="stemsTypeSelection('instrument')">
                        <img src="assets/img/instruments/brass.svg" />
                        <div class="stems-group-type-title">
                            Group by instrument
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="loader-container" *ngIf="downloadLoading">
                <div class="loader"></div>

                Loading, please wait
            </div>
        </div>
    </div>

    <div modal class="download-modal modal" *ngIf="downloadFolderModal">
        <div
            class="modal-background"
            (click)="downloadFolderModal = false"></div>
        <div class="modal-container">
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="downloadFolderModal = false" />
            </div>

            <div *ngIf="selectedFolderType == null">
                <div
                    class="modal-title modal-preset-title"
                    *ngIf="!downloadFolderModalLoading">
                    Select File Type
                </div>
                <div id="types-list" *ngIf="!downloadFolderModalLoading">
                    <div
                        class="download-type"
                        (click)="setSelectFolderType('mp3')">
                        <img src="assets/img/format/mp3.svg" />
                        <div class="download-type-title">Compressed MP3</div>
                    </div>

                    <div
                        class="download-type"
                        (click)="setSelectFolderType('orchestrated-midi')">
                        <img src="assets/img/format/midi.svg" />
                        <div class="download-type-title">Orchestrated Midi</div>
                    </div>

                    <div
                        class="download-type"
                        (click)="setSelectFolderType('piano-midi')">
                        <img src="assets/img/format/midi.svg" />
                        <div class="download-type-title">Reduced Midi</div>
                    </div>

                    <div
                        class="download-type"
                        (click)="setSelectFolderType('json')"
                        *ngIf="showJSONOption()">
                        <div class="download-type-title">JSON</div>
                    </div>
                </div>

                <div
                    id="download-filename-parameters"
                    *ngIf="!downloadFolderModalLoading"
                    (click)="
                        addParamsToFilenameFolder = !addParamsToFilenameFolder
                    ">
                    <input
                        type="checkbox"
                        [(ngModel)]="addParamsToFilenameFolder" />
                    Add composition parameters to file names
                </div>
            </div>

            <div
                *ngIf="
                    selectedFolderType != null && !downloadFolderModalLoading
                ">
                <div class="folder-download-warning">
                    <img src="assets/img/warning.svg" />
                    Downloading this folder will count as
                    {{ numberOfUndownloadedCompositionsInFolder }} compositions
                    towards your quota.
                </div>

                <div
                    class="modal-button modal-grey-button"
                    (click)="downloadFolderModal = false">
                    Cancel
                </div>

                <div
                    class="modal-button modal-green-button"
                    (click)="downloadFolder()">
                    Proceed with download
                </div>
            </div>

            <div
                class="modal-title modal-preset-title"
                *ngIf="downloadFolderModalLoading">
                Download in progress...
            </div>

            <div class="loader-container" *ngIf="downloadFolderModalLoading">
                <div class="loader"></div>
                <br />
                Please keep this browser page open. This may take some time
                depending on the number of compositions.
            </div>
        </div>
    </div>

    <div
        modal
        class="rename-modal modal"
        *ngIf="modals.renameGenerationProfile != null">
        <div
            class="modal-background"
            (click)="closeModal('renameGenerationProfile')"></div>
        <div class="modal-container">
            <div class="modal-title">Rename Generation profile</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeModal('renameGenerationProfile')" />
            </div>
            <input
                type="text"
                class="modal-input"
                #renameGPField
                [(ngModel)]="renameGP"
                (keydown.enter)="setNameForGP()" />

            <div
                class="modal-button modal-text-button"
                (click)="closeModal('renameGenerationProfile')">
                Cancel
            </div>
            <div
                class="modal-button modal-blue-button"
                (click)="setNameForGP()">
                Done
            </div>
        </div>
    </div>

    <div modal class="modal" *ngIf="!!modals.layerInpaintingNotAllowedWarning$">
        <div
            class="modal-background"
            (click)="closeModal('renameGenerationProfile')"></div>
        <div class="modal-container">
            <div class="modal-title">Can not re-generate layer</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeModal('layerInpaintingNotAllowedWarning$')" />
            </div>
            <div class="modal-title modal-subtitle">
                To use this feature, please add a layer to your composition.
            </div>
            <div
                class="modal-button modal-text-button"
                (click)="closeModal('layerInpaintingNotAllowedWarning$')">
                Ok
            </div>
        </div>
    </div>

    <div modal class="rename-modal modal" *ngIf="modals.renamePack != null">
        <div class="modal-background" (click)="closeModal('renamePack')"></div>
        <div class="modal-container">
            <div class="modal-title">Rename Pack</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeModal('renamePack')" />
            </div>
            <input
                type="text"
                class="modal-input"
                #renameField
                [(ngModel)]="newPackName"
                (keydown.enter)="setNameForPack()" />

            <div
                class="modal-button modal-text-button"
                (click)="closeModal('renamePack')">
                Cancel
            </div>
            <div
                class="modal-button modal-blue-button"
                (click)="setNameForPack()">
                Done
            </div>
        </div>
    </div>

    <div
        modal
        class="rename-modal modal"
        *ngIf="modals.renameLayer !== undefined">
        <div class="modal-background" (click)="closeModal('renameLayer')"></div>
        <div class="modal-container">
            <rename-layer
                [layerModal]="modals.renameLayer"
                (close)="closeModal('renameLayer')"></rename-layer>
        </div>
    </div>

    <div
        modal
        class="change-layer-color-modal modal"
        *ngIf="modals.changeLayerColor !== undefined">
        <div
            class="modal-background"
            (click)="closeModal('changeLayerColor')"></div>
        <div class="modal-container">
            <change-layer-color
                [layerModal]="modals.changeLayerColor"
                (close)="closeModal('changeLayerColor')"></change-layer-color>
        </div>
    </div>

    <div modal class="rename-modal modal" *ngIf="renameModal">
        <div class="modal-background" (click)="cancelRename()"></div>
        <div class="modal-container">
            <div class="modal-title">Rename</div>
            <div class="modal-close">
                <img src="assets/img/close.svg" (click)="cancelRename()" />
            </div>
            <input
                type="text"
                class="modal-input"
                name="newName"
                #renameField
                [(ngModel)]="newName"
                (keydown.enter)="rename()"
                av-data="renameInput" />

            <div
                class="modal-button modal-text-button"
                (click)="cancelRename()">
                Cancel
            </div>
            <div
                class="modal-button modal-blue-button"
                (click)="rename()"
                av-data="renameConfirm">
                Done
            </div>
        </div>
    </div>

    <div modal class="rename-modal modal" *ngIf="commentModal">
        <div class="modal-background" (click)="cancelComment()"></div>
        <div class="modal-container">
            <div class="modal-title">Edit comment</div>
            <div class="modal-close">
                <img src="assets/img/close.svg" (click)="cancelComment()" />
            </div>
            <textarea
                type="text"
                class="modal-input modal-text-input"
                name="newName"
                [(ngModel)]="commentValue"></textarea>

            <div
                class="modal-button modal-text-button"
                (click)="cancelComment()">
                Cancel
            </div>
            <div
                class="modal-button modal-blue-button"
                (click)="updateComment()">
                Done
            </div>
        </div>
    </div>

    <div modal class="modal password-modal" *ngIf="changePasswordModal">
        <div
            class="modal-background"
            (click)="changePasswordModal = false"></div>
        <div class="modal-container">
            <div class="modal-title">Change my Password</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="changePasswordModal = false" />
            </div>
            <input
                type="password"
                class="modal-input current-password"
                placeholder="Current password"
                [(ngModel)]="passwords.currentPassword" />
            <input
                type="password"
                class="modal-input"
                placeholder="New password"
                [(ngModel)]="passwords.newPassword" />
            <input
                type="password"
                class="modal-input"
                placeholder="Confirm new password"
                [(ngModel)]="passwords.newPasswordConfirm"
                (keydown.enter)="changePassword()" />

            <div
                class="modal-button modal-text-button"
                (click)="changePasswordModal = false">
                Cancel
            </div>
            <div
                class="modal-button modal-blue-button"
                (click)="changePassword()">
                Done
            </div>
        </div>
    </div>

    <div modal class="rename-modal modal" *ngIf="renameFolderModal">
        <div class="modal-background" (click)="cancelRename()"></div>
        <div class="modal-container">
            <div class="modal-title">Rename</div>
            <div class="modal-close">
                <img src="assets/img/close.svg" (click)="cancelRename()" />
            </div>
            <input
                type="text"
                class="modal-input"
                #renameFolderField
                name="newName"
                [(ngModel)]="newFolderName"
                (keydown.enter)="renameFolder()" />

            <div
                class="modal-button modal-text-button"
                (click)="cancelRename()">
                Cancel
            </div>
            <div
                class="modal-button modal-blue-button"
                (click)="renameFolder()">
                Done
            </div>
        </div>
    </div>

    <div modal class="modal share-modal" *ngIf="shareModal">
        <div class="modal-background" (click)="closeShareModals()"></div>
        <div class="modal-container">
            <div class="modal-title">Share</div>
            <div class="modal-close">
                <img src="assets/img/close.svg" (click)="closeShareModals()" />
            </div>

            <div
                class="message-box"
                *ngIf="message.show && !shareLoading"
                [ngClass]="message.type">
                <div class="close-button" (click)="message.show = false">
                    <img src="assets/img/close.svg" />
                </div>
                <div class="text">{{ message.text }}</div>
            </div>
            <div class="loader-container" *ngIf="shareLoading">
                <div class="loader"></div>
            </div>

            <div class="share-header">
                <div class="link-sharing-subheader">Link Sharing -</div>
                <div
                    class="link-sharing-subheader link-sharing-button"
                    *ngIf="linkSharingIsEnabled()"
                    (click)="toggleLinkSharing(false)">
                    Disable
                </div>
                <div
                    class="link-sharing-subheader link-sharing-button"
                    *ngIf="!linkSharingIsEnabled()"
                    (click)="toggleLinkSharing(true)">
                    Enable
                </div>
            </div>

            <div
                class="link-sharing-input"
                ngxClipboard
                [cbContent]="getSharedLink()"
                (click)="linkCopied = true">
                {{ getSharedLink() }}
            </div>

            <div *ngIf="linkCopied" class="link-copied">
                <img src="assets/img/menu/link.svg" />
                Link copied to your clipboard!
            </div>
        </div>
    </div>

    <div modal class="modal share-gp-modal" *ngIf="modals.shareGP">
        <div class="modal-background" (click)="closeModal('shareGP')"></div>
        <div class="modal-container">
            <share-gp
                (close)="closeModal('shareGP')"
                [generationProfile]="modals.shareGP"></share-gp>
        </div>
    </div>

    <!-- share folder as playlist -->
    <div modal class="modal share-folder-modal" *ngIf="shareFolderModal">
        <div class="modal-background" (click)="closeShareModals()"></div>
        <div class="modal-container">
            <div class="modal-title">Share folder as playlist</div>
            <div class="modal-close">
                <img src="assets/img/close.svg" (click)="closeShareModals()" />
            </div>

            <div
                class="message-box"
                *ngIf="message.show && !shareLoading"
                [ngClass]="message.type">
                <div class="close-button" (click)="message.show = false">
                    <img src="assets/img/close.svg" />
                </div>
                <div class="text">{{ message.text }}</div>
            </div>
            <div class="loader-container" *ngIf="shareLoading">
                <div class="loader"></div>
            </div>

            <div class="share-header">
                <div class="link-sharing-subheader">Link Sharing -</div>
                <div
                    class="link-sharing-subheader link-sharing-button"
                    *ngIf="folderLinkSharingIsEnabled"
                    (click)="toggleFolderLinkSharing(false)">
                    Disable
                </div>
                <div
                    class="link-sharing-subheader link-sharing-button"
                    *ngIf="!folderLinkSharingIsEnabled"
                    (click)="toggleFolderLinkSharing(true)">
                    Enable
                </div>
            </div>

            <div class="link-sharing-input">{{ sharedFolderLink }}</div>
        </div>
    </div>

    <div
        modal
        class="composition-creation-gp modal"
        *ngIf="compositionCreationWithGP != null">
        <div
            class="modal-background"
            (click)="closeModal('compositionCreationWithGP')"></div>
        <div class="modal-container">
            <div class="modal-title">Create from a style</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeModal('compositionCreationWithGP')" />
            </div>

            <create-with-gp></create-with-gp>
        </div>
    </div>

    <div
        modal
        class="create-with-influence-modal modal"
        *ngIf="createWithInfluenceModal"
        [ngClass]="{
            'ensemble-selection':
                getCreateWithInfluenceView() == 'ensemble-selection',
            'mode-change': getCreateWithInfluenceView() == 'mode-change'
        }">
        <div
            class="modal-background"
            (click)="createWithInfluenceModal = false"></div>
        <div class="modal-container" #createWithInfluenceModalContainer>
            <create-with-influence-component></create-with-influence-component>
        </div>
    </div>

    <div modal class="enter-username-modal modal" *ngIf="modals.enterUsername">
        <div class="modal-background"></div>
        <div class="modal-container">
            <enter-username
                (close)="closeModal('enterUsername')"></enter-username>
        </div>
    </div>

    <div modal class="modal tutorial-modal" *ngIf="tutorialModal">
        <div class="modal-background" (click)="closeTutorialModal()"></div>
        <div class="modal-container">
            <div class="modal-title">Welcome to AIVA!</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeTutorialModal()" />
            </div>

            <div class="modal-subtitle">
                To get started, we recommend watching a 2 minutes long tutorial
                that will introduce you to the basics of composing music with
                AIVA.
            </div>

            <div
                class="modal-button modal-skip-tutorial"
                (click)="closeTutorialModal()">
                <img class="tutorial-skip" src="assets/img/close.svg" />Skip
                Tutorial
            </div>
            <div
                class="modal-button modal-blue-button"
                (click)="watchTutorialView()">
                <img
                    class="tutorial-play"
                    src="assets/img/player/play.svg" />Watch Tutorial
            </div>
        </div>
    </div>

    <div id="video-controller-view" *ngIf="watchTutorial">
        <div id="close" (click)="watchTutorial = false"></div>

        <iframe
            id="ytplayer"
            type="text/html"
            width="89%"
            height="85%"
            frameborder="0"
            src="https://www.youtube.com/embed/SR-UWkSTmAQ?vq=hd1080&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1&rel=0"></iframe>

        <div
            class="modal-green-button modal-button"
            (click)="watchTutorial = false">
            Close video & Start composing with AIVA
        </div>
    </div>

    <div
        modal
        class="copy-automation-to-all-layers modal"
        *ngIf="copyAutomationAllLayersModal">
        <div
            class="modal-background"
            (click)="cancelCopyAutomationAllLayers()"></div>
        <div class="modal-container">
            <div class="modal-title">Done!</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="cancelCopyAutomationAllLayers()" />
            </div>

            <div class="modal-subtitle">
                The automation has been copied to all layers.
            </div>
        </div>
    </div>

    <div
        modal
        class="modal drum-sequencer-value-warning-modal"
        *ngIf="drumSequencerValueWarning">
        <div
            class="modal-background"
            (click)="cancelDrumSequencerValueWarning()"></div>
        <div class="modal-container">
            <div class="modal-title">Confirm changing values</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="cancelDrumSequencerValueWarning()" />
            </div>
            <div class="modal-subtitle">
                Are you sure you want to apply your selection? The steps not
                displayed when changing this value will be removed.
            </div>

            <div
                class="modal-button modal-text-button"
                (click)="cancelDrumSequencerValueWarning()">
                Cancel
            </div>
            <div
                class="modal-button modal-blue-button"
                (click)="confirmDrumSequencerValueWarning()">
                Apply selection
            </div>
        </div>
    </div>

    <div
        modal
        class="modal drum-sequencer-value-warning-modal"
        *ngIf="accompanimentDesignerValueWarning">
        <div
            class="modal-background"
            (click)="cancelAccompanimentDesignerValueWarning()"></div>
        <div class="modal-container">
            <div class="modal-title">Confirm changing values</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="cancelAccompanimentDesignerValueWarning()" />
            </div>
            <div class="modal-subtitle">
                Are you sure you want to apply your selection? Some information
                may be lost.
            </div>

            <div
                class="modal-button modal-text-button"
                (click)="cancelAccompanimentDesignerValueWarning()">
                Cancel
            </div>
            <div
                class="modal-button modal-blue-button"
                (click)="confirmAccompanimentDesignerValueWarning()">
                Apply selection
            </div>
        </div>
    </div>

    <div
        modal
        class="modal drum-sequencer-value-warning-modal"
        *ngIf="compositionWorkflowValueWarning">
        <div
            class="modal-background"
            (click)="cancelCompositionWorkflowValueWarning()"></div>
        <div class="modal-container">
            <div class="modal-title">Confirm changing values</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="cancelCompositionWorkflowValueWarning()" />
            </div>
            <div class="modal-subtitle">
                Are you sure you want to apply your selection? Some information
                may be lost.
            </div>

            <div
                class="modal-button modal-text-button"
                (click)="cancelCompositionWorkflowValueWarning()">
                Cancel
            </div>
            <div
                class="modal-button modal-blue-button"
                (click)="confirmCompositionWorkflowValueWarning()">
                Apply selection
            </div>
        </div>
    </div>

    <div
        modal
        class="request-patch-error-modal modal"
        *ngIf="requestPatchErrorModal">
        <div class="modal-background" (click)="closeRequestPatchError()"></div>
        <div class="modal-container request-patch-error-container">
            <div class="modal-title"></div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="closeRequestPatchError()" />
            </div>
            <div class="warning">
                <img src="assets/img/warning.svg" />
            </div>
            <div
                class="modal-subtitle"
                *ngIf="
                    requestPatchError == null ||
                    requestPatchError == 0 ||
                    requestPatchError == 1
                ">
                There was an error while getting the selected instrument -
                please try again later or contact support.
            </div>
            <div class="modal-subtitle" *ngIf="requestPatchError == 2">
                There is not enough disk space available to download the
                selected instrument. You can read more about this here or you
                can contact support.
            </div>
        </div>
    </div>

    <div
        modal
        class="open-composition-modal modal"
        *ngIf="openCompositionModal">
        <div class="modal-background" (click)="cancelOpenComposition()"></div>
        <div class="modal-container">
            <div class="modal-title">Open composition</div>
            <div class="modal-close">
                <img
                    src="assets/img/close.svg"
                    (click)="cancelOpenComposition()" />
            </div>
            <input
                type="text"
                class="modal-input"
                name="open-composition"
                placeholder="Enter the composition ID here"
                [(ngModel)]="openCompositionID"
                (keydown.enter)="openCompositionByID()"
                autofocus />

            <div class="message-box error" *ngIf="openCompositionIDError">
                <div class="text">
                    This composition does not exist. Please check the
                    composition ID and the account you are using.
                </div>
            </div>

            <div
                class="modal-button modal-text-button"
                (click)="cancelOpenComposition()">
                Cancel
            </div>
            <div
                class="modal-button modal-blue-button"
                (click)="openCompositionByID()">
                Done
            </div>
        </div>
    </div>
</div>
