import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import { playerQuery } from "../../../../../../common-lib/client-only/general/classes/playerStateManagement"

@Component({
    selector: "instrument-downloading",
    templateUrl: "instrument-downloading.component.html",
})
export class InstrumentDownloadingComponent {
    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor() {}

    getTrackBusLoadingPercentage() {
        return playerQuery.trackBusLoadingPercentage
    }
}
