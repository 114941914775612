import { Injectable } from "@angular/core"
import { ApiService } from "@services/api.service"

@Injectable()
export class PlayerHttpService {
    constructor(private apiService: ApiService) {}

    public async setAsPlayed(compositionID: string) {
        try {
            await this.apiService
        
            .authRequest(
                "/composition/setAsPlayed",
                { compositionID: compositionID },
                "primary",
                true
            )
        }

        catch(e) {
            console.error(e)
        }
    }
}
