import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
} from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import { ApiService } from "@services/api.service"
import { CompositionWorkflowHTTPService } from "@services/composition-workflow/composition-workflow-http.service"
import { SelectCompositionWorkflowModalParameters } from "@services/modal.service"
import { ListItem } from "../../reusable/list-items/list-items.component"
import { Router } from "@angular/router"

@Component({
    selector: "select-composition-workflow",
    templateUrl: "./select-composition-workflow.component.html",
    styleUrls: ["./select-composition-workflow.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CompositionWorkflowHTTPService],
})
export class SelectCompositionWorkflowModal {
    @Input() input: SelectCompositionWorkflowModalParameters
    @Output() close: EventEmitter<boolean> = new EventEmitter()

    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    public items: ListItem[] = []
    public loading: boolean = true

    constructor(
        private cwHttp: CompositionWorkflowHTTPService,
        private cd: ChangeDetectorRef,
        private router: Router
    ) {}

    public async ngOnInit() {
        try {
            const cws = await this.cwHttp.getSavedCWs(this.input.type)

            this.items = cws.map((cw, index) => {
                const item: ListItem = {
                    name: cw.name,
                    data: cw,
                    width: "100%",
                    menu: {
                        options: [
                            {
                                data: cw,
                                icon: "assets/img/menu/open.svg",
                                text: "Select",
                                loading: false,
                                onClick: async (cw: {
                                    _id: string
                                    name: string
                                }) => {
                                    this.router.navigate([
                                        "composition-workflow",
                                        cw._id,
                                    ])

                                    this.close.emit()
                                },
                            },
                            {
                                data: cw,
                                icon: "assets/img/menu/rename.svg",
                                buttonClass: "rename",
                                text: "Rename",
                                loading: false,
                                onClick: undefined,
                                onConfirm: async (name: string) => {
                                    await this.cwHttp.renameCW(cw._id, name)
                                },
                            },
                            {
                                data: cw,
                                icon: "assets/img/menu/delete.svg",
                                buttonClass: "delete",
                                text: "Delete",
                                loading: false,
                                onClick: async (cw: {
                                    _id: string
                                    name: string
                                }) => {
                                    await this.cwHttp.deleteCW(cw._id)

                                    const idx = this.items.findIndex(
                                        i => i.data._id === cw._id
                                    )

                                    if (idx > -1) {
                                        this.items.splice(idx, 1)
                                    }

                                    this.cd.detectChanges()
                                },
                            },
                        ],
                        coordinates: { x: 0, y: 0 },
                    },
                }

                if (this.input.type === "chordProgression") {
                    let subtitle = ""

                    let first = true

                    for (const chord of cw.numerals) {
                        if (!first) {
                            subtitle += " - "
                        }

                        subtitle += chord[1]

                        first = false
                    }

                    item.subtitle = subtitle
                }

                return item
            })

            this.loading = false

            this.cd.detectChanges()
        } catch (e) {
            console.error(e)
        }
    }
}
