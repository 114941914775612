import { Injectable } from '@angular/core'
import PotionMetadata from '@common-lib/classes/potion/potionmetadata'
import { ApiService } from './api.service'

@Injectable()
export class PotionService {
    autofill = false

    samples = []
    instrumentJSON = {}

    constructor(private apiService: ApiService) {

    }

    async getJSONFiles(instrument) {
        let result = await this.apiService.authRequest('/potion/autofill', { instrument: instrument }, "primary", true)

        this.samples = JSON.parse(result.samples)
        this.instrumentJSON = JSON.parse(result.instrumentJSON)

        this.autofill = true
    }
}