import GPLayer from "../gplayer"
import { Misc } from "../../../modules/misc"
import MelodyPack from "./melodypack"
import { gpOptions } from "../../../constants/gp_options"
import { GPMixing } from "../gpmixing"
import GPInfluenceLoading from "../influences/gpinfluenceloading"
import { MelodyLayerSchema } from "../../../interfaces/db-schemas/generationprofile"
import { MelodyPackMusicEngine } from "../../../interfaces/music-engine/generationprofile"
const options = gpOptions["melody"]

export default class MelodyLayer extends GPLayer {
    static PHRASE_LENGTHS_OPTIONS = options.phraseLengths
    static MELODIC_COMPLEXITY_OPTIONS = options.melodicComplexity

    swing: boolean = false

    constructor() {
        super("Melody")
        this.packs = super.packs as Array<MelodyPack>
        this.packs = [new MelodyPack("medium_0", [], 0, "medium")]
    }

    get pack(): MelodyPack {
        return this.packs ? (this.packs[0] as MelodyPack) : null
    }

    set pack(pack: MelodyPack) {
        this.packs[0] = pack
    }

    static fromJSON(object) {
        if (object == null) {
            return null
        }

        let melodyLayer = Object.assign(new MelodyLayer(), object)
        melodyLayer.packs = []

        if (object.packs) {
            for (let pack of object.packs) {
                melodyLayer.packs.push(
                    Object.assign(new MelodyPack("", [], 0, ""), pack)
                )
            }
        }

        melodyLayer.mixing = GPMixing.fromJSON(object.mixing)
        melodyLayer.gpInfluenceLoading = GPInfluenceLoading.fromJSON(
            object.gpInfluenceLoading
        )

        return melodyLayer
    }

    encodePackID(sourcePacks) {
        for (let pack of sourcePacks) {
            if (
                pack.complexity == this.pack.complexity &&
                pack.phraseLength == this.pack.phraseLength
            ) {
                this.packs[0].packID = pack.packID

                break
            }
        }
    }

    static computeSimilarity(melody1: MelodyLayer, melody2: MelodyLayer) {
        if (melody1 == null && melody2 == null) {
            return {
                score: 1,
                report: {
                    packScore: 1,
                    instrumentsSimilarity: 1,
                },
            }
        } else if (
            (melody1 == null && melody2 != null) ||
            (melody1 != null && melody2 == null)
        ) {
            return {
                score: 0,
                report: {
                    packScore: 0,
                    instrumentsSimilarity: 0,
                },
            }
        }

        let pack1 = melody1.packs[0]
        let pack2 = melody2.packs[0]

        let packScore = 0

        if (pack1.packID == pack2.packID) {
            packScore = 1
        }

        let instruments1 = pack1.instruments.map(x => x.patchID)
        let instruments2 = pack1.instruments.map(x => x.patchID)

        let instrumentsSimilarity = Misc.arraysSimilarity(
            instruments1,
            instruments2
        )

        let score = packScore * 0.4 + instrumentsSimilarity * 0.6

        return {
            score: score,
            report: {
                pack: packScore,
                instrumentsSimilarity: instrumentsSimilarity,
            },
        }
    }

    encodeForDB(): MelodyLayerSchema {
        let encodedInstruments = []
        let instruments = this.pack
            ? this.pack.instruments
            : this["instruments"]

        for (let i of instruments) {
            let patch = {
                patch: typeof i == "string" ? i : i.patchID,
                noteTie: typeof i == "string" ? false : i.noteTie,
            }

            encodedInstruments.push(patch)
        }

        return {
            swing: this.swing,
            mixing: this.mixing.encodeForDB(),
            instruments: encodedInstruments,
            packID: this.packs[0].packID,
            fixedInstrumentation: this.fixedInstrumentation,
            gpInfluenceLoading:
                this.gpInfluenceLoading != null
                    ? this.gpInfluenceLoading.encode()
                    : null,
        }
    }

    encodeForME(): MelodyPackMusicEngine {
        let encodedInstruments = []
        let instruments = this.pack
            ? this.pack.instruments
            : this["instruments"]

        for (let i of instruments) {
            let patch = {
                patch: typeof i == "string" ? i : i.patchID,
                note_tie: typeof i == "string" ? false : i.noteTie,
            }

            encodedInstruments.push(patch)
        }

        return {
            swing: this.swing,
            instruments: encodedInstruments,
            mixing: this.mixing.encodeForME(),
            pack_id: this.packs[0].packID,
            fixed_instrumentation: this.fixedInstrumentation,
        }
    }
}
