<div id="upload-midi-container">
    <div *ngIf="!loading && step === 1" class="step-1">
        <ngx-file-drop
            [showBrowseBtn]="true"
            dropZoneLabel="Drop files here"
            (onFileDrop)="dropped($event)">
            <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector">
                <div
                    (click)="openFileSelector()"
                    class="upload-influence-dropzone"
                    *ngIf="!isMobile()">
                    <img src="assets/img/menu/influences.svg" />Drag & Drop a
                    MIDI file here*<br />
                </div>

                <div
                    (click)="openFileSelector()"
                    class="upload-influence-dropzone-mobile"
                    *ngIf="isMobile()">
                    <img src="assets/img/menu/influences.svg" /> Upload a MIDI
                    file
                </div>
            </ng-template>
        </ngx-file-drop>

        <div class="title">
            Import & use any MIDI file in AIVA's built-in piano roll editor.
        </div>
    </div>
    <div class="warnings step-2-max-width" *ngIf="step === 2 && !loading">
        <p class="title-2" *ngIf="hasWarnings">
            We detected issues with your MIDI file, which will be fixed
            automatically:
        </p>
        <div class="warnings-items" *ngIf="hasWarnings">
            <ul>
                <li class="warnings-item" *ngFor="let item of warnings">
                    <b>{{ item[0] }}:</b> {{ item[1] }}
                </li>
            </ul>
        </div>
        <div class="collapse-layers settings-row" *ngIf="enableCollapseLayers">
            <div class="settings-row-title">
            Collapse layers
            <img
                class="tooltip-img"
                src="assets/img/info.svg"
                [tooltip]="tooltips.collapseLayers"
                [tooltip-marginTop]="10"
                [tooltip-width]="250" />
        </div>
            <div class="settings-row-value settings-row-section-length">
                <slide-toggle
                    [(toggled)]="collapseLayers"
                    (onChange)="setCollapseLayers($event)"
                    [disabled]="!enableCollapseLayers"></slide-toggle>
            </div>
        </div>
        <div class="warnings-buttons">
            <a-button
                title="Cancel"
                label="Cancel"
                type="light"
                (onClick)="reset()"></a-button>
            <a-button
                title="Confirm"
                label="Confirm"
                type="primary"
                (onClick)="confirm()"></a-button>
        </div>
    </div>
    <div class="loading" *ngIf="loading">
        <div class="loading-title">Uploading the MIDI file.</div>
        <div class="loader"></div>
    </div>
</div>
