import { Query } from "@datorama/akita"
import { Composition } from "../../../general/classes/general/composition"
import { Store, StoreConfig } from "@datorama/akita"
import { Observable, filter } from "rxjs"
import { featureFlags } from "../../../general/utils/feature-flags"

export type PlayerStatus = "playing" | "paused" | "loading"
export type PlayerPlaybackType = "realtime" | "offline"

export interface PlayerPreview {
    _id: string
    contentType: "playerPreview"
    hasStartOffset: false
    name: string
    duration: number
    isFinished: boolean
}

// For now we either loop a score or not at all.
// The score can be the score of a single accompaniment
// designer pattern or one of a composition.
export type LoopType = "score" | undefined
export interface PlayerState {
    content: Composition | PlayerPreview | undefined
    status: PlayerStatus
    timeElapsed: number
    playTime: number | undefined
    playbackType: PlayerPlaybackType
    volumeLevel: number // from 0 to 1
    restartPlayback: number | undefined
    offlineAudioLoaded: number // in seconds

    pause: number | undefined
    play: number | undefined

    trackBusLoadingPercentage: number // from 0 to 100

    // this duration value is used when switching to realtime playback, since the duration of the composition may change depending on certain edits made
    realtimeDuration: number

    playbackError: string | undefined

    // This only affects the realtime sampler playback.
    // No looping will happen if it is set to be undefined.
    // Defines the type of audio we are looping over. For now it is just score,
    // but there could be section in the future.
    loopType?: LoopType
}

export const createInitialState = (): PlayerState => {
    return {
        content: undefined,
        status: "paused",
        timeElapsed: 0,
        playTime: undefined,
        playbackType: "offline",
        volumeLevel: 1,
        offlineAudioLoaded: 0,
        trackBusLoadingPercentage: 0,
        realtimeDuration: 0,
        playbackError: undefined,
        restartPlayback: undefined,
        pause: undefined,
        play: undefined,
        loopType: undefined,
    }
}

@StoreConfig({ name: "Player", resettable: true })
export class PlayerStore extends Store<PlayerState> {
    constructor() {
        super(createInitialState())
    }
}

export class PlayerQuery extends Query<PlayerState> {
    public allState$ = this.select()
    public status$ = this.select("status")
    public realtimeDuration$ = this.select("realtimeDuration")

    constructor(protected store: PlayerStore) {
        super(store)
    }

    public get volumeLevel(): number {
        return this.getValue().volumeLevel
    }

    public get playbackType(): PlayerPlaybackType {
        return this.getValue().playbackType
    }

    public get loopType(): LoopType {
        return this.getValue().loopType
    }

    public get trackBusLoadingPercentage(): number {
        return this.getValue().trackBusLoadingPercentage
    }

    public get trackBusLoadingPercentage$(): Observable<number> {
        return this.select("trackBusLoadingPercentage")
    }

    public get timeElapsed(): number {
        return this.getValue().timeElapsed
    }

    public get content(): Composition | PlayerPreview | undefined {
        return this.getValue().content
    }

    public get restartPlayback(): number | undefined {
        return this.getValue().restartPlayback
    }

    public get contentID(): string | undefined {
        return this.content?._id
    }

    public get status(): PlayerStatus {
        return this.getValue().status
    }

    public get playTime(): number | undefined {
        return this.getValue().playTime
    }

    public get duration(): number {
        if (this.playbackType === "realtime") {
            return this.getValue().realtimeDuration
        }

        return this.getValue().content?.duration
    }

    public get playbackError(): string | undefined {
        return this.getValue().playbackError
    }
}

export class PlayerActions {
    constructor(private store: PlayerStore, private query: PlayerQuery) {}

    public hasStartOffset() {
        if (playerQuery.playbackType === "realtime") {
            return false
        }

        return playerQuery.content && playerQuery.content["hasStartOffset"]
    }

    public setOfflineAudioLoading(origin: string, value: number) {
        if (value > this.query.duration && featureFlags.debugStreamingService) {
            console.error(
                "loaded value is longer than duration. Value: ",
                value,
                " | duration: ",
                this.query.duration
            )
        }

        playerStore.update(state => ({
            ...state,
            offlineAudioLoaded: Math.min(this.query.duration, value),
        }))
    }

    public setPlaybackError({
        origin,
        error,
    }: {
        origin: string
        error: string | undefined
    }) {
        playerStore.update(state => ({
            ...state,
            playbackError: error,
        }))
    }

    public pause(origin: string) {
        playerStore.update(state => ({
            ...state,
            pause: Date.now(),
        }))
    }

    public play(origin: string) {
        playerStore.update(state => ({
            ...state,
            play: Date.now(),
        }))
    }

    public loadContent(
        origin: string,
        content: Composition | PlayerPreview,
        status: "loading" | "paused"
    ) {        
        playerStore.update(state => ({
            ...state,
            content,
            status,
            timeElapsed: 0,
        }))
    }

    public setPlayTime(origin: string, value: number | undefined) {
        playerStore.update(state => ({
            ...state,
            playTime: value,
        }))
    }

    public setTrackBusLoadingPercentage(value: number) {
        playerStore.update(state => ({
            ...state,
            trackBusLoadingPercentage: value,
        }))
    }

    public setRestartPlayback(origin: string, value: number | undefined) {
        if (playerQuery.status !== "playing") {
            return
        }

        playerStore.update(state => ({
            ...state,
            restartPlayback: value,
        }))
    }

    public setPlaybackType(origin: string, value: "offline" | "realtime") {
        if (value === "offline") {
            playerStore.update(state => ({
                ...state,
                playbackType: value,
            }))
        } else {
            playerStore.update(state => ({
                ...state,
                playbackType: value,
                realtimeDuration: this.query.content
                    ? this.query.content.duration
                    : 0,
            }))
        }

        //console.log("setPlaybacktype: ", origin, value)
    }

    public setLoopType(origin: string, value: LoopType) {
        playerStore.update(state => ({
            ...state,
            loopType: value,
        }))
    }

    public setRealtimeDuration(origin: string, value: number) {
        playerStore.update(state => ({
            ...state,
            realtimeDuration: value,
        }))
    }

    public setStatus(origin: string, value: "playing" | "paused" | "loading") {
        playerStore.update(state => ({
            ...state,
            status: value,
        }))
    }

    public setVolume(origin: string, volume: number) {
        playerStore.update(state => ({
            ...state,
            volumeLevel: volume,
        }))
    }

    public setTimeElapsed(origin: string, timeElapsed: number) {
        playerStore.update(state => ({
            ...state,
            timeElapsed,
        }))
    }
}

export const playerStore = new PlayerStore()
export const playerQuery = new PlayerQuery(playerStore)
export const playerActions = new PlayerActions(playerStore, playerQuery)
