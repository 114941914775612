import { Validators } from "./composition-workflow/validators"
import { Header, Midi, Track } from "@tonejs/midi"
import { DrumSamplesMap, InstrumentsJSON } from "../interfaces/score/general"
import { Time } from "./time"
import {
    TemplateChord,
    TemplateKeySignature,
    TemplateLayers,
    TemplateNote,
    TemplateScore,
    TemplateSections,
    TemplateTempoMap,
    TemplateTimeSignature,
    TemplateTrack,
} from "../interfaces/score/templateScore"
import { SegmentedScoreManipulationModule } from "./score-transformers/segmented-score-manipulation.module"
import Score from "../classes/score/score"
import { ScoreDecoding } from "./score-transformers/scoreDecoding"
import { FractionString, TimeSignature } from "../../general/types/score"
import { MAX_TEMPO, MIN_TEMPO, TIMESTEP_RES } from "../constants/constants"
import { cloneDeep } from "lodash"
import { NotesObject } from "../classes/score/notesObject"
import { Note } from "../classes/score/note"
import { RangeWithID } from "../interfaces/general"
import { v4 as uuidv4 } from "uuid"
import TrackBus from "../classes/score/trackbus"

const midiToInstrumentsMapping = require("../..//assets/json/midi_to_instruments.json")

export module MIDIImport {
    /**
     * Arbitrary number of section length that is allowed
     * subject to change
     */
    const MAX_SECTION_MEASURES = 16
    const MAX_NUMBER_OF_BARS = 200
    const MAX_SECTION_NAME_LENGTH = 20
    const MAX_NUMBER_OF_TRACKS = 75

    export enum MidiErrorObjects {
        TRACKS = "tracks",
        SECTIONS = "sections",
        TIMESIGNATURE = "timeSignature",
        KEYSIGNATURE = "keySignature",
        TEMPO = "tempo",
        LENGTH = "length",
        IMPORT = "import",
    }

    export type MidiErrorType = {
        [midiErrorObject: string]: {
            warnings: {
                [key: string]: string
            }
            errors: {
                [key: string]: string
            }
        }
    }

    export const MidiErrorKeys = {
        NoTracks: "No tracks in MIDI file",
        MultiTimeSignature: "Multiple time signatures detected",
        TempoOutOfBonds: "Tempo is not supported",
        NoFirstSection: "There is no first section",
        SectionTooBig: "Section markers are too large",
        DurationTooLong: "Duration of MIDI file exceeds limit",
        FailedToImport: "Failed to import MIDI file",
        DuplicateSectionStart: "Section duplicates",
    }

    export async function convertFileSystemEntryToMIDI(
        file: FileSystemFileEntry
    ): Promise<Midi> {
        return new Promise(resolve => {
            file.file(async file => {
                resolve(new Midi(await file.arrayBuffer()))
            })
        })
    }

    export function noteIsQuantized(n: TemplateNote): boolean {
        const startIsDivisibleby48th = Time.isDivisibleBy(n.start, "1/48")
        const startIsDivisibleby32nd = Time.isDivisibleBy(n.start, "1/32")

        return (
            (startIsDivisibleby48th || startIsDivisibleby32nd) &&
            Time.compareTwoFractions(n.duration, "1/48") !== "lt"
        )
    }

    export function validateMIDI(midi: Midi): {
        errors: string[]
        warnings: string[]
        pass: boolean
    } {
        /**
         * Additional validations for MIDI files could be:
         * - Note range: Check if the notes in the MIDI file are within the valid MIDI note range (0-127). If any notes are outside this range, return an error (or warning and ignore them during import?).
         * - Note duration: Check if any notes have a duration of zero or less, or an unusually long duration. If they do, return a warning (and ignore them during import?) or error.
         * - Tempo Range: Check if the tempo is within a reasonable range. If it's too slow or too fast, return a warning (we might need to adjust it when importing).
         */
        const errors: string[] = []
        const warnings: string[] = []

        // Ignore key signature validation,
        // since we can always add a default key signature (C maj)
        if (midi.header.keySignatures.length === 0) {
            errors.push("No key signature found")
        }

        if (midi.header.tempos.length === 0) {
            warnings.push("No tempo found")
            midi.header.tempos.push({
                bpm: 120,
                ticks: 0,
            })
        }

        midi.header.timeSignatures = midi.header.timeSignatures
            .sort()
            .filter((item, pos, ary) => {
                return (
                    !pos ||
                    (ary[pos - 1].timeSignature[0] !== item.timeSignature[0] &&
                        ary[pos - 1].timeSignature[1] !== item.timeSignature[1])
                )
            })

        if (midi.header.timeSignatures.length === 0) {
            warnings.push("No time signature found")
            midi.header.timeSignatures.push({
                ticks: 0,
                timeSignature: [4, 4],
                measures: 0,
            })
        } else if (midi.header.timeSignatures.length > 1) {
            errors.push("Multi time signature is not supported")
        } else if (
            !Validators.validateTimeSignature(
                midi.header.timeSignatures[0].timeSignature
            )
        ) {
            errors.push("Invalid time signature")
        }

        midi.tracks = midi.tracks.filter(t => t.notes.length > 0)

        if (midi.tracks.length > MAX_NUMBER_OF_TRACKS) {
            errors.push("Number of tracks exceeds the maximum allowed")
        }

        if (midi.tracks.length === 0) {
            errors.push("No tracks found")
        }

        if (errors.length > 0) {
            return {
                errors,
                warnings,
                pass: false,
            }
        }

        return {
            errors: [],
            warnings,
            pass: true,
        }
    }

    /**
     * This function will construct and return an error object that applies like this:
     * errors: {
     *  ErrorSubject (Sections, Tracks, etc): {
     *      errors: {
     *          All the errors for the error subject will be logged here with respective keys
     *      }
     *      warnings: {
     *          All the warnings for the error subject will be logged here with respective keys
     *      }
     *  }
     * }
     */

    export function validateMIDIV2(midi: Midi): MidiErrorType {
        let errors: MidiErrorType = {}
        validateMidiTempo(midi, errors)
        validateMidiTimeSignatures(midi, errors)
        validateMidiSections(midi, errors)
        validateMidiTracks(midi, errors)
        validateMidiLength(midi, errors)

        return errors
    }

    function validateMidiLength(midi: Midi, errors: MidiErrorType) {
        errors[MidiErrorObjects.LENGTH] = {
            errors: {},
            warnings: {},
        }
        const durationBars = Time.fractionToNumber(
            ticksToFraction(midi.header, midi.durationTicks)
        )

        if (durationBars > MAX_NUMBER_OF_BARS) {
            errors[MidiErrorObjects.LENGTH].errors[
                MidiErrorKeys.DurationTooLong
            ] = `Composition duration exceeds the maximum allowed duration (${MAX_NUMBER_OF_BARS} bars).`
        }
    }

    function validateMidiTracks(midi: Midi, errors: MidiErrorType) {
        errors[MidiErrorObjects.TRACKS] = {
            errors: {},
            warnings: {},
        }
        midi.tracks = midi.tracks.filter(t => t.notes.length > 0)

        if (midi.tracks.length === 0) {
            errors[MidiErrorObjects.TRACKS].errors[MidiErrorKeys.NoTracks] =
                "No tracks found"
        }
    }

    export function validateMidiTimeSignatures(
        midi: Midi,
        errors: MidiErrorType
    ) {
        errors[MidiErrorObjects.TIMESIGNATURE] = {
            errors: {},
            warnings: {},
        }
        // filters out the time signatures?
        midi.header.timeSignatures = midi.header.timeSignatures
            .sort()
            .filter((item, pos, ary) => {
                return (
                    !pos ||
                    (ary[pos - 1].timeSignature[0] !== item.timeSignature[0] &&
                        ary[pos - 1].timeSignature[1] !== item.timeSignature[1])
                )
            })

        if (midi.header.timeSignatures.length === 0) {
            midi.header.timeSignatures.push({
                ticks: 0,
                timeSignature: [4, 4],
                measures: 0,
            })
        } else if (midi.header.timeSignatures.length > 1) {
            errors[MidiErrorObjects.TIMESIGNATURE].errors[
                MidiErrorKeys.MultiTimeSignature
            ] = "Multiple time signature is not supported"
        } else if (
            !Validators.validateTimeSignature(
                midi.header.timeSignatures[0].timeSignature
            )
        ) {
            errors[MidiErrorObjects.TIMESIGNATURE].errors[
                MidiErrorKeys.MultiTimeSignature
            ] = "Invalid time signature"
        }
    }

    export function validateMidiTempo(
        midi: Midi,
        errors: MidiErrorType
    ): MidiErrorType {
        errors[MidiErrorObjects.TEMPO] = {
            warnings: {},
            errors: {},
        }

        if (midi.header.tempos.length === 0) {
            midi.header.tempos.push({
                bpm: 120,
                ticks: 0,
            })
        } else {
            const tempos = midi.header.tempos
            for (let i = 0; i < tempos.length; ++i) {
                const tempo = tempos[i]
                if (tempo.bpm <= MIN_TEMPO || tempo.bpm >= MAX_TEMPO) {
                    errors[MidiErrorObjects.TEMPO].warnings[
                        MidiErrorKeys.TempoOutOfBonds
                    ] =
                        "The out of boundaries tempo will be approximated to the closest supported tempo"
                    break
                }
            }
        }

        return errors
    }

    /**
     *
     * @param {Midi} midi
     * @param {MidiErrorType} errors a shared error object accross all validation steps to add errors and warnings
     * returns a warning message about midi, null if not applicable
     */
    export function validateMidiSections(
        midi: Midi,
        errors: MidiErrorType
    ): MidiErrorType {
        const sectionsInMidiRepresentation = midi.header.meta.filter(
            m => m.type === "marker"
        )

        const midiTimeSignature = midi.header.timeSignatures

        const templateTimeSignatures: TemplateTimeSignature[] = [
            ["0", midiTimeSignature[0].timeSignature],
        ]

        const ts = templateTimeSignatures[0][1] as TimeSignature

        const maxSectionDuration = Time.multiplyFractionWithNumber(
            `${ts[0]}/${ts[1]}`,
            MAX_SECTION_MEASURES
        )

        errors[MidiErrorObjects.SECTIONS] = {
            warnings: {},
            errors: {},
        }

        sectionsInMidiRepresentation.sort((s1, s2) => s1.ticks - s2.ticks)

        if (
            !sectionsInMidiRepresentation[0] ||
            sectionsInMidiRepresentation[0].ticks !== 0
        ) {
            errors[MidiErrorObjects.SECTIONS].warnings[
                MidiErrorKeys.NoFirstSection
            ] = "A section will be added in the beginning of the composition"
        }

        const sectionFractions = {}

        sectionsInMidiRepresentation.forEach((s, i) => {
            const sectionDuration =
                i === sectionsInMidiRepresentation.length - 1
                    ? ticksToFraction(midi.header, midi.durationTicks - s.ticks)
                    : ticksToFraction(
                          midi.header,
                          sectionsInMidiRepresentation[i + 1].ticks - s.ticks
                      )

            if (
                Time.compareTwoFractions(
                    sectionDuration,
                    maxSectionDuration
                ) === "gt"
            ) {
                errors[MidiErrorObjects.SECTIONS].warnings[
                    MidiErrorKeys.SectionTooBig
                ] = "Large section markers will be split into smaller chunks"
            }

            if (
                sectionFractions[s.ticks] &&
                !errors[MidiErrorObjects.SECTIONS].warnings[
                    MidiErrorKeys.DuplicateSectionStart
                ]
            ) {
                errors[MidiErrorObjects.SECTIONS].warnings[
                    MidiErrorKeys.DuplicateSectionStart
                ] =
                    "Multiple section markers that share the same starting point"
            }

            sectionFractions[s.ticks] = true
        })

        return errors
    }

    export function ticksToFraction(header: Header, ticks: number): string {
        const measures = header.ticksToMeasures(ticks)
        const ts = header.timeSignatures[0].timeSignature

        return Time.measuresToFraction(measures, ts)
    }

    function isInstrumentFromAIVA(
        trackName: string,
        instruments: InstrumentsJSON
    ): boolean {
        if (trackName.split(".").length !== 4) {
            return false
        }

        const parts = trackName.split(".")
        const section = parts[0]
        const inst = parts[1]

        if (instruments[section] === undefined) {
            return false
        }

        return instruments[section].find(i => i.name === inst) !== undefined
    }

    function formatVelocity(value: number) {
        return Math.min(127, Math.max(0, Math.round(value * 127)))
    }

    function getTemplateKeySignatures(
        midi: Midi,
        start: string,
        addMissingKeySignature: boolean = false
    ): TemplateKeySignature[] {
        const ks: TemplateKeySignature[] = []

        for (const k of midi.header.keySignatures) {
            ks.push([
                start,
                k.key + " " + (k.scale === "major" ? "maj" : "min"),
            ])
            start = ticksToFraction(midi.header, k.ticks)
        }

        if (ks.length === 0 && addMissingKeySignature) {
            ks.push(["0", "C maj"])
        }

        return ks
    }

    export function toTemplateScore(
        midi: Midi,
        instruments: InstrumentsJSON,
        samplesMap: DrumSamplesMap,
        convertToScore: boolean,
        addMissingData: {
            sections?: boolean
            tempo?: boolean
            timeSignature?: boolean
            keySignature?: boolean
            chords?: boolean
        }
    ): {
        score: TemplateScore
        convertedScore: Score | undefined
        total: number
        unquantizedNotes: number
    } {
        // Currently only some of the flags are used. The rest needs to be implemented if needed.
        let {
            sections: addMissingSections,
            tempo: addMissingTempo,
            timeSignature: addMissingTimeSignature,
            keySignature: addMissingKeySignature,
            chords: addMissingChords,
        } = addMissingData

        let start = "0"

        const ks = getTemplateKeySignatures(midi, start, addMissingKeySignature)
        const templateTimeSignatures: TemplateTimeSignature[] = [
            ["0", midi.header.timeSignatures[0].timeSignature],
        ]
        const ts = templateTimeSignatures[0][1] as TimeSignature
        const res = getTemplateTracksAndLayers(midi, start, instruments)

        let total = res.totalNumberOfNotes
        let unquantizedNotes = res.unquantizedNotes
        let lastNoteEnd = res.lastNoteEnd

        const score: TemplateScore = {
            type: "composition",
            compositionID: "",
            chords: getTemplateScoreChords(ts, lastNoteEnd, addMissingChords),
            keySignatures: ks,
            tracks: res.templateTracks,
            timeSignatures: templateTimeSignatures,
            sustainPedal: [],
            sections: getTemplateScoreSections(
                midi,
                ts,
                lastNoteEnd,
                addMissingSections
            ),
            tempoMap: getTemplateScoreTempos(midi.header),
            effects: {
                bass_boost: false,
                vinyl: false,
            },
            layers: res.layers,
        }

        return {
            total,
            unquantizedNotes,
            score: score,
            convertedScore: !convertToScore
                ? undefined
                : convertTemplateScoreToScoreAndBack(
                      score,
                      instruments,
                      samplesMap
                  ).score,
        }
    }

    /**
     * Converts a given template score to a score and back to a template score.
     * This is done to make sure that the generated template score is valid and suitable
     * for our pipeline.
     *
     * @param templateScore
     * @param instruments
     * @param samplesMap
     * @returns TemplateScore
     */
    function convertTemplateScoreToScoreAndBack(
        templateScore: TemplateScore,
        instruments: InstrumentsJSON,
        samplesMap: DrumSamplesMap
    ): {
        score: Score
        templateScore: TemplateScore
    } {
        // Convert to Score and back in order to make sure that
        // 1) the TemplateScore is valid
        // 2) the section meta indices per note are correctly set
        const tempScore = new Score({
            templateScore,
            instrumentReferences: instruments,
            samplesMap,
        })

        const result = ScoreDecoding.toTemplateScore({
            score: tempScore,
            realTimeSampler: false,
        })

        return {
            score: tempScore,
            templateScore: result?.templateScore,
        }
    }

    function getNumberOfMeasures(
        lastNoteEnd: FractionString,
        timeSignature: TimeSignature
    ): number {
        return Math.floor(Time.fractionToMeasures(lastNoteEnd, timeSignature))
    }

    function getTemplateScoreChords(
        timeSignature: TimeSignature,
        lastNoteEnd: FractionString,
        addMissingChords: boolean = false
    ): TemplateChord[] {
        const nbOfMeasures = getNumberOfMeasures(lastNoteEnd, timeSignature)

        if (addMissingChords) {
            return Array.from({ length: nbOfMeasures }, (_, i) => [
                timeSignature[0] + "/" + timeSignature[1],
                "C",
            ])
        }

        return []
    }

    function getTemplateScoreSections(
        midi: Midi,
        timeSignature: TimeSignature,
        lastNoteEnd: FractionString,
        addMissingSections: boolean = true
    ): TemplateSections[] {
        const markers = midi.header.meta.filter(m => m.type === "marker")

        // Truncate long names
        markers.forEach(m => {
            if (m.text.length > MAX_SECTION_NAME_LENGTH) {
                m.text = "A"
            }
        })

        const scoreLength = ticksToFraction(midi.header, midi.durationTicks)

        const nbOfSections = Math.floor(
            getNumberOfMeasures(scoreLength, timeSignature) / 8
        )

        let sections: TemplateSections[] = []
        if (markers.length === 0 && addMissingSections) {
            sections = Array.from({ length: nbOfSections }, (_, i) => [
                Time.multiplyFractionWithNumber(
                    timeSignature[0] + "/" + timeSignature[1],
                    i * 8
                ),
                "A",
            ])
        } else {
            if (markers[0].ticks !== 0) {
                markers.unshift({
                    type: "marker",
                    text: "A",
                    ticks: 0,
                })
            }
            let addedSections = {}
            const eightMeasure = Time.multiplyFractions(
                "8/1",
                `${timeSignature[0]}/${timeSignature[1]}`
            )

            const maxSectionDuration = Time.multiplyFractionWithNumber(
                `${timeSignature[0]}/${timeSignature[1]}`,
                MAX_SECTION_MEASURES
            )

            for (let i = 0; i < markers.length; ++i) {
                let sectionName = markers[i].text
                const sectionDuration =
                    i === markers.length - 1
                        ? ticksToFraction(
                              midi.header,
                              midi.durationTicks - markers[i].ticks
                          )
                        : ticksToFraction(
                              midi.header,
                              markers[i + 1].ticks - markers[i].ticks
                          )

                if (
                    Time.compareTwoFractions(
                        sectionDuration,
                        maxSectionDuration
                    ) === "gt"
                ) {
                    let sumDuration = sectionDuration
                    let fractionStart = ticksToFraction(
                        midi.header,
                        markers[i].ticks
                    )

                    while (
                        Time.compareTwoFractions(sumDuration, eightMeasure) ===
                        "gt"
                    ) {
                        sections.push([fractionStart, sectionName])
                        fractionStart = Time.addTwoFractions(
                            fractionStart,
                            eightMeasure
                        )
                        sumDuration = Time.addTwoFractions(
                            sumDuration,
                            eightMeasure,
                            true
                        )

                        sectionName = "A"
                    }
                } else {
                    sections.push([
                        ticksToFraction(midi.header, markers[i].ticks),
                        markers[i].text,
                    ])
                }
            }
        }

        sections.sort((a, b) => {
            return Time.compareTwoFractions(a[0], b[0]) === "lt" ? -1 : 1
        })

        // remove duplicated sections
        sections.forEach((s, i) => {
            if (
                !!sections[i + 1] &&
                Time.compareTwoFractions(s[0], sections[i + 1][0]) === "eq"
            ) {
                sections.splice(i, 1)
            }
        })

        return sections
    }

    function getTemplateScoreTempos(midiHeader: Header): TemplateTempoMap[] {
        const tempos = midiHeader.tempos.map(mt => {
            const start: string = ticksToFraction(midiHeader, mt.ticks)
            let bpm = mt.bpm
            if (bpm < MIN_TEMPO) {
                bpm = MIN_TEMPO
            } else if (bpm > MAX_TEMPO) {
                bpm = MAX_TEMPO
            }
            return [start, Math.round(bpm)]
        })
        return tempos as TemplateTempoMap[]
    }

    function getInstrumentAndPatchFromName(name: string): [string, string] {
        const parts = name.split(".")
        return [parts[0] + "." + parts[1], parts[2] + "." + parts[3]]
    }

    function getInstrumentAndPatchFromMapping(
        instrumentNumber: number,
        instruments: InstrumentsJSON
    ): [string, string] {
        const defaultSectionInstrument = "k.piano"
        const defaultPlayingStyleArticulation = "nat.stac"

        const mapping = midiToInstrumentsMapping[instrumentNumber]
            ? midiToInstrumentsMapping[instrumentNumber]
            : defaultSectionInstrument
        const mappingParts = mapping.split(".")
        const sectionName = mappingParts[0]
        const instrumentName = mappingParts[1]

        for (const instrument of instruments[sectionName]) {
            if (instrument.name === instrumentName) {
                return [
                    sectionName + "." + instrument.name,
                    instrument.patches[0].playing_style +
                        "." +
                        instrument.patches[0].articulation,
                ]
            }
        }

        return [defaultSectionInstrument, defaultPlayingStyleArticulation]
    }

    function getInstrumentAndPatchFromMidiTrack(
        track: Track,
        instruments: InstrumentsJSON
    ): [string, string] {
        let instrument = "k.piano"
        let patch = "nat.stac"

        midiToInstrumentsMapping[track.instrument.number]

        if (track.instrument.percussion) {
            instrument = "p.drumkit-rock-1"
        } else if (isInstrumentFromAIVA(track.name, instruments)) {
            ;[instrument, patch] = getInstrumentAndPatchFromName(track.name)
        } else {
            ;[instrument, patch] = getInstrumentAndPatchFromMapping(
                track.instrument.number,
                instruments
            )
        }

        return [instrument, patch]
    }

    function createBaseTemplateTrack(
        track: Track,
        index: number,
        layerName: string,
        instrument: string,
        patch: string
    ): TemplateTrack {
        const templateTrack: TemplateTrack = {
            id: index.toString(),
            name: `${instrument}.${patch}`,
            track_layer: layerName,
            layer: layerName,
            mute: false,
            solo: false,
            instrument: instrument,
            use_velocity: false,
            use_expression: false,
            auto_pedal: false,
            octave: 0,
            dynamic_offset: 0,
            gain_offset: 0,
            panning: 0,
            breathing_gain: 0,
            gm: track.instrument.number,
            channel: 0,
            track: [],
        }

        return templateTrack
    }

    function getTemplateTracksAndLayers(
        midi: Midi,
        start: FractionString,
        instruments: InstrumentsJSON
    ): {
        templateTracks: TemplateTrack[]
        layers: TemplateLayers
        totalNumberOfNotes: number
        unquantizedNotes: number
        lastNoteEnd: FractionString
    } {
        const layers: TemplateLayers = {}
        const templateTracks: TemplateTrack[] = []
        const effects = SegmentedScoreManipulationModule.createEffect("None")

        for (const effect in effects) {
            effects[effect].active = true
        }

        let unquantizedNotes = 0
        let total = 0

        let lastNoteEnd = "0"
        let percussionLayerCounter = 0

        for (let t = 0; t < midi.tracks.length; t++) {
            const track = midi.tracks[t]
            const index = t

            let [instrument, patch] = getInstrumentAndPatchFromMidiTrack(
                track,
                instruments
            )

            const trackName = track.name === "" ? "Track" : track.name

            const layerName = track.instrument.percussion
                ? "Percussion " + (percussionLayerCounter + 1)
                : trackName + "_" + index

            if (track.instrument.percussion) {
                percussionLayerCounter++
            }

            const templateTrack = createBaseTemplateTrack(
                track,
                index,
                layerName,
                instrument,
                patch
            )

            layers[layerName] = {
                name: layerName,
                type: track.instrument.percussion ? "percussion" : "pitched",
                value: layerName,
                effects: effects,
                gain_bias: 0,
                color: track.instrument.percussion
                    ? {
                          red: "0",
                          green: "211",
                          blue: "0",
                      }
                    : undefined,
            }

            const notesMap = {}
            const cc1 = []

            if (track.controlChanges["1"] !== undefined) {
                for (const cc of track.controlChanges["1"]) {
                    cc1.push([
                        ticksToFraction(midi.header, cc.ticks),
                        formatVelocity(cc.value),
                    ])
                }
            }

            for (const n of track.notes) {
                total++

                const note: TemplateNote = {
                    pitch: [n.midi],
                    note_ids: {},
                    start: ticksToFraction(midi.header, n.ticks),
                    duration: ticksToFraction(midi.header, n.durationTicks),
                    meta: {
                        section: 0,
                        layer: templateTrack.layer,
                    },
                    low_frequency_cut: [],
                    high_frequency_cut: [],
                    reverb: [],
                    delay: [],
                    dynamic: [],
                }

                if (track.controlChanges["1"] !== undefined) {
                    const end = Time.addTwoFractions(note.start, note.duration)

                    for (const v of cc1) {
                        if (Time.compareTwoFractions(v[0], end) === "gt") {
                            break
                        }

                        if (Time.compareTwoFractions(v[0], start) === "lt") {
                            continue
                        }

                        note.dynamic = [
                            [
                                Time.addTwoFractions(v[0], note.start, true),
                                v[1],
                            ],
                        ]
                    }
                } else {
                    const velocity = formatVelocity(n.velocity)

                    note.dynamic = [["0", velocity]]
                }

                if (notesMap[note.start] === undefined) {
                    notesMap[note.start] = note
                } else {
                    notesMap[note.start].pitch.push(n.midi)
                }
            }

            const keys = Object.keys(notesMap)

            for (let i = 0; i < keys.length; i++) {
                const start = keys[i]
                const next =
                    keys.length > i + 1 ? notesMap[keys[i + 1]] : undefined

                lastNoteEnd = Time.addTwoFractions(
                    start,
                    notesMap[start].duration
                )

                if (
                    next !== undefined &&
                    Time.compareTwoFractions(next.start, lastNoteEnd) === "lt"
                ) {
                    notesMap[start].duration = Time.addTwoFractions(
                        next.start,
                        start,
                        true
                    )
                    lastNoteEnd = next.start
                }

                if (!noteIsQuantized(notesMap[start])) {
                    unquantizedNotes++
                }

                templateTrack.track.push(notesMap[start])
            }

            templateTracks.push(templateTrack)
        }

        // @todo: filter out empty layers (e.g. percussion layers without notes)

        return {
            templateTracks,
            layers,
            totalNumberOfNotes: total,
            unquantizedNotes,
            lastNoteEnd,
        }
    }

    /**
     * Retrieves the layers to collapse based on the given score and group range.
     * This does not include the actual logic to collapse the layers.
     * @param score Score
     * @param groupRange [string, string], e.g. ["0", "1/1"]
     * @returns { [key: string]: string[] }. An object containing the layers to collapse.
     */
    export function getLayersToCollapse(
        score: Score,
        groupRange: [string, string]
    ): { [key: string]: string[] } {
        const layersToCollapse = {}

        // Used to keep track of the layers that are merged to avoid merging them again in another layer.
        const layersMarkedToBeCollapsed: any[] = []
        const inclusive = false

        // First simply compare the note starts as a first step
        for (let layer in score.layers) {
            // Skip if the layer is already collapsed
            if (layersMarkedToBeCollapsed.includes(layer)) {
                continue
            }

            const layerValue = score.layers[layer]?.value
            const notes = score.layers[layerValue].notesObject
            const noteStartsAndDuration = getNoteGroupsAndRests(
                notes,
                groupRange,
                inclusive
            )

            for (let l in score.layers) {
                // Ignore same layer
                if (l === layerValue) {
                    continue
                }

                // Ignore incompatible layer type
                if (score.layers[l].type !== score.layers[layerValue].type) {
                    continue
                }

                const otherNotes = score.layers[l].notesObject
                const otherNoteStartsAndDuration = getNoteGroupsAndRests(
                    otherNotes,
                    groupRange,
                    inclusive
                )

                // If number of groups is different or zero, skip
                if (
                    noteStartsAndDuration.length !==
                        otherNoteStartsAndDuration.length ||
                    !noteStartsAndDuration?.length
                ) {
                    continue
                }

                let noteGroupsAreCompatible = true

                for (let i = 0; i < noteStartsAndDuration.length; i++) {
                    const start = noteStartsAndDuration[i].start

                    if (
                        noteStartsAndDuration[i].start !==
                            otherNoteStartsAndDuration[i].start ||
                        noteStartsAndDuration[i].duration !==
                            otherNoteStartsAndDuration[i].duration
                    ) {
                        noteGroupsAreCompatible = false
                        break
                    }

                    const currentNoteGroup = notes.getNoteGroup(start)
                    const otherNoteGroup = otherNotes.getNoteGroup(start)

                    // We skip in case the notegroup lengths are different and we are dealing with an actual
                    // note group and not with a rest.
                    if (
                        currentNoteGroup?.length &&
                        currentNoteGroup.length !== otherNoteGroup?.length
                    ) {
                        noteGroupsAreCompatible = false
                        break
                    }

                    // It must be a rest, since there are no notes at the start.
                    // Therefore we skip the rest of the checks.
                    if (!currentNoteGroup?.length || !otherNoteGroup?.length) {
                        continue
                    } else {
                        for (let n = 0; n < currentNoteGroup.length; n++) {
                            const note = currentNoteGroup[n]
                            const otherNote = otherNoteGroup[n]

                            if (note.pitch !== otherNote.pitch) {
                                noteGroupsAreCompatible = false
                                break
                            }

                            if (note.duration !== otherNote.duration) {
                                noteGroupsAreCompatible = false
                                break
                            }
                        }
                    }
                }

                if (!noteGroupsAreCompatible) {
                    continue
                }

                // We only reach this point when all note starts are the same
                if (!layersToCollapse[layer]) {
                    layersToCollapse[layer] = [l]
                } else {
                    layersToCollapse[layer].push(l)
                }

                // Finally we mark the layer to be collapsed with the current layer, to avoid collapsing them
                // in another layer. The actual collapse will take place at a later stage.
                layersMarkedToBeCollapsed.push(l)
            }
        }

        return layersToCollapse
    }

    /**
     * Collapses one layer into another within a given range in a musical score.
     *
     * @param {Score} score - The musical score object.
     * @param {string} destinationLayerValue - The name of the layer that will receive the trackBuses.
     * @param {string} sourceLayerValue - The name of the layer that will be emptied and serves as the source of the merge.
     * @param {[string, string]} groupRange - The range within which notes will be moved, specified as an array of two strings representing the start and end of the range.
     */
    export function collapseLayers(
        score: Score,
        destinationLayerValue: string,
        sourceLayerValue: string,
        groupRange: [string, string]
    ) {
        /**
         * 1. Delete the notes from the source layer within the group range.
         * 2. Get the trackBus and TrackBus regions of the source layer.
         * 3. Merge the trackBus and it's retions into the destination layer.
         */
        const sourceLayer = score.layers[sourceLayerValue]
        const destinationLayer = score.layers[destinationLayerValue]

        // Delete the notes from the source layer within the group range.
        deleteNotesInRange(sourceLayer.notesObject, groupRange)

        const trackBussesToAdd: TrackBus[] = []

        // Add a detection here if a TB should be added or if it can be merged (we don't do anything in that case)
        // We need to compare the curren trackbus of the destination layer against the trackbusses of the source layer.
        for (let trackBus of sourceLayer.trackBuses) {
            if (!trackBus.blocks?.length) {
                continue
            }

            let newTrackBus = cloneDeep(trackBus)

            // Only add the trackBus if there is not already a trackBus with the same instrumentation, octave, blocks.
            // Look through all the trackBusses of the destination layer to see if there is a match.
            for (let tb of destinationLayer.trackBuses) {
                if (shouldMergeTrackBusses(tb, newTrackBus, groupRange)) {
                    newTrackBus = undefined
                    break
                }
            }

            if (newTrackBus) {
                trackBussesToAdd.push(newTrackBus)
            }
        }

        // Merge the trackBus and it's retions into the destination layer.
        destinationLayer.trackBuses =
            destinationLayer.trackBuses.concat(trackBussesToAdd)

        return destinationLayer
    }

    export function shouldMergeTrackBusses(
        trackBus1: TrackBus,
        trackBus2: TrackBus,
        groupRange: [string, string]
    ): boolean {
        if (trackBus1.octave !== trackBus2.octave) {
            return false
        }

        if (trackBus1.name !== trackBus2.name) {
            return false
        }

        // Check if the regions are compatible
        const regions1 = getTrackBusRegionsInRange(trackBus1, groupRange)
        const regions2 = getTrackBusRegionsInRange(trackBus2, groupRange)

        if (regions1.length !== regions2.length) {
            return false
        }

        for (let r = 0; r < regions1.length; r++) {
            if (
                regions1[r].start !== regions2[r].start ||
                regions1[r].end !== regions2[r].end
            ) {
                return false
            }
        }

        return true
    }

    export function getTrackBusRegionsInRange(
        trackBus: TrackBus,
        groupRange: [string, string]
    ): RangeWithID[] {
        const tsRange = [
            Time.fractionToTimesteps(TIMESTEP_RES, groupRange[0]),
            Time.fractionToTimesteps(TIMESTEP_RES, groupRange[1]),
        ]

        const regions: RangeWithID[] = []

        for (let region of trackBus.blocks) {
            if (region.start < tsRange[0] || region.start >= tsRange[1]) {
                continue
            }

            const newRegion: RangeWithID = {
                id: uuidv4(),
                start: region.start,
                end: Math.min(region.end, tsRange[1]),
            }

            regions.push(newRegion)
        }

        return regions
    }

    export function collapseScoreLayersIterativelyByPartials(score: Score) {
        const timeSignature = score.timeSignatures[0][1] as TimeSignature

        let currentStart = "0"
        let incremental = 4 * timeSignature[0] + "/" + timeSignature[1]
        let currentEnd = Time.addTwoFractions(currentStart, incremental)

        const numberOfPartials = Math.ceil(
            Time.divideTwoFractions(score.scoreLength, incremental)
        )

        for (let i = 0; i < numberOfPartials; i++) {
            let groupRange = [currentStart, currentEnd] as [string, string]

            const layersToCollapse = getLayersToCollapse(score, groupRange)

            for (let layer in layersToCollapse) {
                for (let l of layersToCollapse[layer]) {
                    collapseLayers(score, layer, l, groupRange)
                }
            }

            currentStart = currentEnd
            currentEnd = Time.addTwoFractions(currentStart, incremental)
        }

        const templateScore = ScoreDecoding.toTemplateScore({
            score: score,
            realTimeSampler: false,
        }).templateScore

        // Remove empty tracks
        templateScore.tracks = templateScore.tracks.filter(
            t => t.track.length > 0
        )

        const keepLayers = templateScore.tracks.map(t => t.layer)

        for (let layer in templateScore.layers) {
            if (!keepLayers.includes(layer)) {
                delete templateScore.layers[layer]
            }
        }

        return templateScore
    }

    /**
     * Collapses multiple layers into another within a given range in a musical score.
     * Use this when collapsing the whole score.
     *
     * @param {Score} score - The musical score object.
     * @param {{ [key: string]: string[] }} layersToCollapse - An object containing the layers to collapse, where the key is the name of the destination layer and the value is an array of the names of the source layers.
     * @param {[string, string]} groupRange - The range within which notes will be moved, specified as an array of two strings representing the start and end of the range.
     */
    export function collapseScoreLayers(
        score: Score,
        groupRange: [string, string]
    ): TemplateScore {
        const layersToCollapse = getLayersToCollapse(score, groupRange)

        for (let layer in layersToCollapse) {
            for (let l of layersToCollapse[layer]) {
                collapseLayers(score, layer, l, groupRange)
            }
        }

        const templateScore = ScoreDecoding.toTemplateScore({
            score: score,
            realTimeSampler: false,
        }).templateScore

        // Remove empty tracks
        templateScore.tracks = templateScore.tracks.filter(
            t => t.track.length > 0
        )

        const keepLayers = templateScore.tracks.map(t => t.layer)

        for (let layer in templateScore.layers) {
            if (!keepLayers.includes(layer)) {
                delete templateScore.layers[layer]
            }
        }

        return templateScore
    }

    function getNoteGroupsAndRests(
        notesObject: NotesObject,
        groupRange: [string, string],
        inclusive: boolean = false
    ): Array<{ start: string; duration: string }> {
        const startsAndDuration: Array<{ start: string; duration: string }> = []

        notesObject.forEachGroupAndRests(
            (previous, current, next) => {
                startsAndDuration.push({
                    start: current[0].start,
                    duration: current[0].duration,
                })
            },
            groupRange,
            inclusive
        )

        return startsAndDuration
    }

    function deleteNotesInRange(
        notesObject: NotesObject,
        groupRange: [string, string]
    ) {
        notesObject.manipulateNoteGroups((noteGroup: Note[]) => {
            const comparison = Time.compareTwoFractions(
                noteGroup[0].start,
                groupRange[1]
            )

            if (comparison === "eq" || comparison === "gt") {
                return false
            }

            notesObject.deleteNoteGroup(noteGroup[0].start)
            return true
        }, groupRange)
    }
}
