<div id="billing-component">
    <div id="billing-component-wrapper" [perfectScrollbar]="scrollConfig">
        <div class="modal-menus">
            <div class="modal-preset-title" (click)="setMenu('plans')" (click)="toLicensing()">
                <img src="assets/img/info.svg"> Terms of License
            </div>

            <div class="modal-preset-title" [ngClass]="{'modal-preset-title-selected': getMenu() == 'plans'}"
                (click)="setMenu('plans')">
                <img src="assets/img/dollar.svg"> Subscription Plans
            </div>

            <div class="modal-preset-title" [ngClass]="{'modal-preset-title-selected': getMenu() == 'payment'}"
                (click)="setMenu('payment')">
                <img src="assets/img/card.svg"> Payment Method
            </div>
        </div>

        <div id="billing-container" [hidden]="getMenu() != 'payment'">

            <div id="payment-method-selector">
                <div class="payment-method-selector-item" alt="Pay with Credit Card"
                    [ngClass]="{'payment-method-selector-large': !showWalletPaymentMethods}"
                    [class.selected]="getSelectedPM() === 'credit-card'" (click)="setSelectedPM('credit-card')"><img
                        src="assets/img/card.svg"> Pay by card</div>
                <div class="payment-method-selector-item paypal" alt="Pay with PayPal"
                    [ngClass]="{'payment-method-selector-large': !showWalletPaymentMethods}"
                    [class.selected]="getSelectedPM() === 'paypal'" (click)="setSelectedPM('paypal')"><img
                        class="paypal-logo" src="assets/img/paypal-black.svg"> Pay with PayPal</div>
                <div class="payment-method-selector-item apple-pay" alt="Pay with Apple Pay"
                    [ngClass]="{'payment-method-selector-large': !showWalletPaymentMethods}"
                    [class.selected]="getSelectedPM() === 'apple-pay'" (click)="setSelectedPM('apple-pay')"
                    *ngIf="showWalletPaymentMethods"><img src="assets/img/apple.svg"> Apple Pay</div>
            </div>

            <div class="payment-method-container">

                <div class="credit-card-container" [hidden]="getSelectedPM() != 'credit-card'">
                    <div class="payment-method-title">Card Details</div>

                    <div *ngIf="defaultPaymentMethod == 'credit-card'" class="default-payment-method">
                        Default payment method
                        <img src="assets/img/checkmark.svg">
                    </div>
                    <input [(ngModel)]="fullName" placeholder="Full name or business name" class="input full-input"
                        id="billing-address">

                    <input [(ngModel)]="address" placeholder="Postal Address" class="input full-input"
                        id="billing-address">
                    <input [(ngModel)]="city" placeholder="City" class="input billing-address-city"
                        id="billing-address">
                    <input [(ngModel)]="zip" placeholder="Zip Code" class="input" id="billing-address">

                    <select id="billing-address-country" [(ngModel)]="country"
                        class="input full-input billing-address-country" placeholder="Select a country"
                        [class.custom-select]="changeSelectStyle">
                        <option *ngFor="let country of countries" [value]="country.countryCode"
                            [selected]="country != null && country == country.countryCode">{{ country.name }} {{
                            country.emoji }}</option>
                    </select>

                    <input [(ngModel)]="cardholderName" placeholder="Cardholder name" class="input full-input"
                        id="cardholder-name">

                    <label for="card-number">
                        <div class="input full-input" id="card-number"></div>
                    </label>

                    <label for="card-number">
                        <div class="input input-expiration-date" id="expiration-date"></div>
                    </label>

                    <label for="card-number">
                        <div class="input" id="cvc"></div>
                    </label>

                    <div class="billing-error-message">{{ cardNumberError }}</div>
                    <div class="billing-error-message">{{ expirationDateError }}</div>

                    <div class="modal-button add-payment-method" (click)="setPaymentDetails()"
                        *ngIf="!addPaymentMethodLoading">Update Payment Method</div>

                    <div class="loader-container" *ngIf="addPaymentMethodLoading">
                        <div class="loader"></div>
                    </div>
                </div>

                <div class="apple-pay-container" [hidden]="getSelectedPM() != 'apple-pay'">

                    <div class="payment-method-title">Apple Pay Details</div>

                    <div class="message-box" *ngIf="message.show" [ngClass]="message.type">
                        <div class="close-button" (click)="messageService.hideMessage(message)">
                            <img src="assets/img/close.svg">
                        </div>
                        <div class="text"> {{ message.text }} </div>
                    </div>

                    <input [(ngModel)]="fullName" name="fullName" placeholder="Full name or business name"
                        class="input full-input" id="billing-address">
                    <select id="billing-address-country" [(ngModel)]="country" (ngModelChange)="onSelectCountry($event)"
                        class="input full-input billing-address-country" placeholder="Country"
                        [class.custom-select]="changeSelectStyle">
                        <option [selected]="country == null">Select a country <img src="assets/img/down-dropdown.svg">
                        </option>
                        <option *ngFor="let country of countries" [value]="country.countryCode"
                            [selected]="country != null && country == country.countryCode">{{ country.name }} {{
                            country.emoji }}</option>
                    </select>

                    <div id="wallet-pay-button" class="wallet-pay-button" *ngIf="canMakeWalletPayment"
                        [hidden]="addPaymentMethodLoading || !getWalletPayIsSetup()">
                        <!-- THIS WILL BE RENDERED BY STRIPE ELEMENTS -->
                    </div>
                    <!-- Method to show Apple, Google Pays current payment request  -->
                    <div class="modal-button add-payment-method" (click)="createWalletPreview()"
                        *ngIf="!addPaymentMethodLoading && !getWalletPayIsSetup() && canMakeWalletPayment">
                        <ng-container *ngIf="subscribeThroughPaymentMethod != ''">
                            Continue with Apple Pay
                        </ng-container>
                        <ng-container *ngIf="subscribeThroughPaymentMethod == ''">
                            Select a plan
                        </ng-container>
                    </div>
                    <div class="loader-container" *ngIf="addPaymentMethodLoading">
                        <div class="loader"></div>
                    </div>

                    <div class="wallet-pay-not-available" *ngIf="!canMakeWalletPayment">
                        Your device is not ready to use Apple Pay.
                    </div>
                </div>

                <div class="paypal-container" [hidden]="getSelectedPM() != 'paypal'">

                    <div class="payment-method-title">PayPal Details</div>

                    <div *ngIf="defaultPaymentMethod == 'paypal'" class="default-payment-method">
                        Default payment method
                        <img src="assets/img/checkmark.svg">
                    </div>

                    <div class="message-box" *ngIf="message.show" [ngClass]="message.type">
                        <div class="close-button" (click)="messageService.hideMessage(message)">
                            <img src="assets/img/close.svg">
                        </div>
                        <div class="text"> {{ message.text }} </div>
                    </div>

                    <!-- <input [(ngModel)]="fullName" placeholder="Full name or business name" class="input full-input" id="billing-address"> -->
                    <input [(ngModel)]="firstName" name="firstName" placeholder="First Name"
                        class="input billing-address-city" id="billing-address"
                        (ngModelChange)="checkBraintreeAdress($event)">
                    <input [(ngModel)]="lastName" name="lastName" placeholder="Last Name" class="input"
                        id="billing-address" (ngModelChange)="checkBraintreeAdress($event)">

                    <input [(ngModel)]="address" placeholder="Address" class="input full-input" id="billing-address"
                        (ngModelChange)="checkBraintreeAdress($event)">
                    <input [(ngModel)]="city" placeholder="City" class="input billing-address-city" id="billing-address"
                        (ngModelChange)="checkBraintreeAdress($event)">
                    <input [(ngModel)]="zip" placeholder="Zip Code" class="input" id="billing-address"
                        (ngModelChange)="checkBraintreeAdress($event)">

                    <select id="billing-address-country" [(ngModel)]="country"
                        class="input full-input billing-address-country" placeholder="Country"
                        [class.custom-select]="changeSelectStyle" (ngModelChange)="checkBraintreeAdress($event)">
                        <option [selected]="country == null">Select a country <img src="assets/img/down-dropdown.svg">
                        </option>
                        <option *ngFor="let country of countries" [value]="country.countryCode"
                            [selected]="country != null && country == country.countryCode">{{ country.name }} {{
                            country.emoji }}</option>
                    </select>

                    <div class="loader-container" *ngIf="addPaymentMethodLoading">
                        <div class="loader"></div>
                    </div>

                    <div id="paypal-button-container" [hidden]="!paypalButtonVisible || addPaymentMethodLoading">
                        <div *ngIf="defaultPaymentMethod" class="update-paypal-payment-details">To update your payment
                            details, click on the button below.</div>
                        <div id="paypal-button"></div>
                    </div>

                    <div class="message-box error"
                        *ngIf="!paypalButtonVisible && defaultPaymentMethod != 'paypal' && !addPaymentMethodLoading && braintreeValidationErrors.length == 0">
                        <div class="text">Please fill in all fields to continue.</div>
                    </div>

                    <div class="message-box error braintree-validation validation"
                        *ngIf="braintreeValidationErrors.length > 0">
                        <div class="text" *ngFor="let error of braintreeValidationErrors">
                            {{ error.message }}
                        </div>
                    </div>
                    <!-- <div *ngIf="defaultPaymentMethod != 'paypal'" class="form-hint create">
                        *Fill in and submit the form using the PayPal button to make PayPal your default payment method.
                    </div>
                    <div *ngIf="defaultPaymentMethod == 'paypal'" class="form-hint update">
                        *Fill in and submit submit the form using the PayPal button to make update your account info.
                    </div> -->
                    <!-- <div class="info-text" *ngIf="!paypalButtonVisible && defaultPaymentMethod == 'paypal' && !addPaymentMethodLoading">
                        PayPal is currently set as your default payment method. If you want to change that, please contact support!
                    </div> -->
                </div>
            </div>
        </div>

        <div id="billing-container" *ngIf="getMenu() == 'plans'">
            <div class="billing-plan-header">
                <i class="pricing-note"><img src="assets/img/renewal.svg">{{ showPricingNote() }}</i>
            </div>

            <div class="billing-cycle-switch">
                <div class="billing-cycle-switch-container billing-cycle-switch-container-monthly"
                    (click)="selectedBillingCycle = 'monthly'"
                    [ngClass]="{'billing-cycle-switch-container-selected': selectedBillingCycle == 'monthly'}">
                    Monthly
                </div>

                <div class="billing-cycle-switch-container" (click)="selectedBillingCycle = 'annually'"
                    [ngClass]="{'billing-cycle-switch-container-selected': selectedBillingCycle == 'annually'}">
                    Annually
                    <div class="billing-cycle-switch-container-subtitle">SAVE UP TO 33%!</div>
                </div>
            </div>

            <br>

            <div id="pricing-option" class="pricing-option-free">
                <div id="pricing-option-container">
                    <div id="option-title" class="option-title-free">Free, Forever</div>

                    <div id="option-prices">
                        <div id="option-price-container">
                            <div id="option-price">€0</div>
                        </div>
                    </div>

                    <div id="option-price-subtitle" class="option-price-subtitle-billing-cycle">No credit card required
                    </div>

                    <div id="option-tldr">For beginners who want to use compositions for non-commercial use cases only,
                        and don't mind giving credit to AIVA.</div>

                    <div id="option-features" class="option-features-free">
                        <li class="copyright-not-owned">Copyright owned by AIVA</li>
                        <li class="copyright-not-owned">No monetization</li>
                        <li class="copyright-not-owned">Credit must be given to AIVA</li>
                        <li>3 downloads per month</li>
                        <li>Durations up to 3 minutes</li>
                        <li>Download MP3 & MIDI formats</li>
                    </div>

                    <div *ngIf="!subscriptionLoading['free']">
                        <div class="modal-button plan-button plan-button-downgrade" *ngIf="showDowngradeButton('free')"
                            (click)="changeSubscription('free')">Downgrade</div>

                        <div class="plan-current" *ngIf="showCurrentPlan('free')">
                            <img src="assets/img/checkmark_dark.svg">
                            Current Plan
                        </div>

                        <div class="plan-willdowngrade" *ngIf="showDowngradeMessage('free')">Downgrading on {{
                            subscription.end * 1000 | date: 'mediumDate' }}</div>
                    </div>

                    <div class="loader" *ngIf="subscriptionLoading['free']"></div>
                </div>
            </div>

            <div id="pricing-option" class="pricing-option-standard" *ngIf="selectedBillingCycle == 'monthly'">
                <div id="pricing-option-container">
                    <div id="option-title" class="option-title-standard"><img src="assets/img/crown_standard.svg">
                        Standard Monthly</div>
                    <div id="option-prices">
                        <div id="option-price-container">
                            <div id="option-price">€15 / month</div>
                            <div id="option-price-subtitle">+ VAT, if applicable</div>
                        </div>
                    </div>

                    <div id="option-price-subtitle" class="option-price-subtitle-billing-cycle">Billed Monthly</div>

                    <div id="option-tldr">Recommended for content creators who want to monetize compositions only on
                        Youtube, Twitch, Tik Tok and Instagram.</div>

                    <div id="option-features" class="option-features-standard">
                        <li class="copyright-not-owned">Copyright owned by AIVA</li>
                        <li class="copyright-not-owned">Limited monetization <div class="parameter-info tooltip"><img
                                    src="assets/img/info.svg"><span class="tooltiptext">If you subscribe to the Standard
                                    Plan, you can only monetize your compositions on Youtube, Twitch, Tik Tok and
                                    Instagram.</span></div>
                        </li>
                        <li>No need to credit AIVA</li>
                        <li>15 downloads per month</li>
                        <li>Durations up to 5 mins</li>
                        <li>With influences: up to 3 mins 30 secs</li>
                        <li>Download MP3 & MIDI formats</li>
                    </div>

                    <div *ngIf="!subscriptionLoading['standard_monthly']">
                        <div class="modal-button plan-button standard-button"
                            *ngIf="showSubscribeButton('standard_monthly', 'upgrade') && subscription.renewal != 'standard_monthly'"
                            (click)="changeSubscription('standard_monthly')">Select</div>

                        <div class="modal-button plan-button plan-button-downgrade"
                            *ngIf="showDowngradeButton('standard_monthly')"
                            (click)="changeSubscription('standard_monthly')">Downgrade</div>

                        <div class="plan-current" *ngIf="showCurrentPlan('standard_monthly')">
                            <img src="assets/img/checkmark_dark.svg">
                            Current Plan
                        </div>

                        <div class="plan-willdowngrade" *ngIf="showDowngradeMessage('standard_monthly')">Downgrading on
                            {{ subscription.end * 1000 | date: 'mediumDate' }}</div>
                    </div>

                    <div class="loader" *ngIf="subscriptionLoading['standard_monthly']"></div>
                </div>
            </div>

            <div id="pricing-option" class="pricing-option-pro" *ngIf="selectedBillingCycle == 'monthly'">
                <div id="pricing-option-container">
                    <div id="option-title" class="option-title-pro"><img src="assets/img/crown_pro.svg"> Pro Monthly
                    </div>
                    <div id="option-prices">
                        <div id="option-price-container">
                            <div id="option-price">€49 / month</div>
                            <div id="option-price-subtitle">+ VAT, if applicable</div>
                        </div>
                    </div>

                    <div id="option-price-subtitle" class="option-price-subtitle-billing-cycle">Billed Monthly</div>

                    <div id="option-tldr">Recommended for creators who want to own the copyright of their compositions,
                        and monetize without restrictions.</div>

                    <div id="option-features" class="option-features-pro">
                        <li>Copyright owned by YOU</li>
                        <li>Full monetization</li>
                        <li>No need to credit AIVA</li>
                        <li>300 downloads per month</li>
                        <li>Durations up to 5 mins 30 secs</li>
                        <li>With influences: up to 3 mins 30 secs</li>
                        <li>Download ALL file formats</li>
                        <li>Export high quality WAV files</li>
                    </div>

                    <div *ngIf="!subscriptionLoading['pro_monthly']">

                        <div class="modal-button plan-button pro-button"
                            *ngIf="showSubscribeButton('pro_monthly', 'upgrade') && subscription.renewal != 'pro_monthly'"
                            (click)="changeSubscription('pro_monthly')">Select</div>

                        <div class="modal-button plan-button plan-button-downgrade"
                            *ngIf="showDowngradeButton('pro_monthly')" (click)="changeSubscription('pro_monthly')">
                            Downgrade</div>

                        <div class="plan-current" *ngIf="showCurrentPlan('pro_monthly')">
                            <img src="assets/img/checkmark_dark.svg">
                            Current Plan
                        </div>

                        <div class="plan-willdowngrade" *ngIf="showDowngradeMessage('pro_monthly')">Downgrading on {{
                            subscription.end * 1000 | date: 'mediumDate' }}</div>

                    </div>

                    <div class="loader" *ngIf="subscriptionLoading['pro_monthly']"></div>
                </div>
            </div>

            <div id="pricing-option" class="pricing-option-standard" *ngIf="selectedBillingCycle == 'annually'">
                <div id="pricing-option-container">
                    <div id="option-title" class="option-title-standard"><img src="assets/img/crown_standard.svg">
                        Standard Annually</div>
                    <div id="option-prices">
                        <div id="option-price-container">
                            <div id="option-price">€11 / month</div>
                            <div id="option-price-subtitle">+ VAT, if applicable</div>
                        </div>
                    </div>

                    <div id="option-price-subtitle" class="option-price-subtitle-billing-cycle">Billed Annually</div>

                    <div id="option-discount-header" class="option-discount-header-standard">26% OFF!</div>

                    <div id="option-tldr">Recommended for content creators who want to monetize compositions only on
                        Youtube, Twitch, Tik Tok and Instagram.</div>

                    <div id="option-features" class="option-features-standard">
                        <li class="copyright-not-owned">Copyright owned by AIVA</li>
                        <li class="copyright-not-owned">Limited monetization <div class="parameter-info tooltip"><img
                                    src="assets/img/info.svg"><span class="tooltiptext">If you subscribe to the Standard
                                    Plan, you can only monetize your compositions on Youtube, Twitch, Tik Tok and
                                    Instagram.</span></div>
                        </li>
                        <li>No need to credit AIVA</li>
                        <li>15 downloads per month</li>
                        <li>Durations up to 5 mins</li>
                        <li>With influences: up to 3 mins 30 secs</li>
                        <li>Download MP3 & MIDI formats</li>
                    </div>

                    <div *ngIf="!subscriptionLoading['standard_annually']">
                        <div class="modal-button plan-button standard-button"
                            *ngIf="showSubscribeButton('standard_annually', 'upgrade') && subscription.renewal != 'standard_annually'"
                            (click)="changeSubscription('standard_annually')">Select</div>

                        <div class="modal-button plan-button plan-button-downgrade"
                            *ngIf="showDowngradeButton('standard_annually')"
                            (click)="changeSubscription('standard_annually')">Downgrade</div>

                        <div class="plan-current" *ngIf="showCurrentPlan('standard_annually')">
                            <img src="assets/img/checkmark_dark.svg">
                            Current Plan
                        </div>

                        <div class="plan-willdowngrade" *ngIf="showDowngradeMessage('standard_annually')">Downgrading on
                            {{ subscription.end * 1000 | date: 'mediumDate' }}</div>
                    </div>

                    <div class="loader" *ngIf="subscriptionLoading['standard_annually']"></div>
                </div>
            </div>

            <div id="pricing-option" class="pricing-option-pro" *ngIf="selectedBillingCycle == 'annually'">
                <div id="pricing-option-container">
                    <div id="option-title" class="option-title-pro"><img src="assets/img/crown_pro.svg"> Pro Annually
                    </div>
                    <div id="option-prices">
                        <div id="option-price-container">
                            <div id="option-price">€33 / month</div>
                            <div id="option-price-subtitle">+ VAT, if applicable</div>
                        </div>
                    </div>

                    <div id="option-price-subtitle" class="option-price-subtitle-billing-cycle">Billed Annually</div>

                    <div id="option-discount-header" class="option-discount-header-pro">33% OFF!</div>

                    <div id="option-tldr">Recommended for creators who want to own the copyright of their compositions,
                        and monetize without restrictions.</div>

                    <div id="option-features" class="option-features-pro">
                        <li>Copyright owned by YOU</li>
                        <li>Full monetization</li>
                        <li>No need to credit AIVA</li>
                        <li>300 downloads per month</li>
                        <li>Durations up to 5 mins 30 secs</li>
                        <li>With influences: up to 3 mins 30 secs</li>
                        <li>Download ALL file formats</li>
                        <li>Export high quality WAV files</li>
                    </div>

                    <div *ngIf="!subscriptionLoading['pro_annually']">

                        <div class="modal-button plan-button pro-button"
                            *ngIf="showSubscribeButton('pro_annually', 'upgrade') && subscription.renewal != 'pro_annually'"
                            (click)="changeSubscription('pro_annually')">Select</div>

                        <div class="modal-button plan-button plan-button-downgrade"
                            *ngIf="showDowngradeButton('pro_annually')" (click)="changeSubscription('pro_annually')">
                            Downgrade</div>

                        <div class="plan-current" *ngIf="showCurrentPlan('pro_annually')">
                            <img src="assets/img/checkmark_dark.svg">
                            Current Plan
                        </div>

                        <div class="plan-willdowngrade" *ngIf="showDowngradeMessage('pro_annually')">Downgrading on {{
                            subscription.end * 1000 | date: 'mediumDate' }}</div>

                    </div>

                    <div class="loader" *ngIf="subscriptionLoading['pro_annually']"></div>
                </div>
            </div>
        </div>

        <div class="eula-mention-billing">All plans are subject to our <a href='https://www.aiva.ai/legal/1'>End User
                License Agreement</a></div>

        <div class="billing-faq" *ngIf="getMenu() == 'plans'">
            <div class="billing-faq-title">
                Frequently Asked Questions
            </div>

            <div class="faq-section">
                <div class="faq-question" (click)="toggleQuestion('inGamesAndYoutube')"
                    [ngClass]="{'faq-question-blue': questions['inGamesAndYoutube']}">Should I give credit to AIVA?
                </div>
                <div class="faq-answer" *ngIf="questions['inGamesAndYoutube']">
                    <b>If you are under a Free plan</b>, please credit AIVA as follows:<br><br><i class="quote">Music
                        composed by AIVA - Artificial Intelligence Virtual Artist (https://www.aiva.ai)</i><br><br>
                    <b>If you are a paid subscriber</b>, you don't need to credit AIVA.
                </div>
            </div>

            <div class="faq-section">
                <div class="faq-question" (click)="toggleQuestion('studentDiscount')"
                    [ngClass]="{'faq-question-blue': questions['studentDiscount']}">Do you offer Student discounts?
                </div>
                <div class="faq-answer" *ngIf="questions['studentDiscount']">
                    Yes! We offer the follow student discounts:<br><br>
                    <li><b>- 15% off</b> on the Standard Monthly and Pro Monthly plans</li>
                    <li><b>- 30% off</b> on the Standard Annually and Pro Annually plans</li><br>
                    If you are interested in getting a discount, please reach out to us using the Support chat on the
                    bottom right corner, or using this <a href='https://www.aiva.ai/contact' target="_blank">contact
                        form</a>.
                </div>
            </div>

            <div class="faq-section">
                <div class="faq-question" (click)="toggleQuestion('termsOfLicense')"
                    [ngClass]="{'faq-question-blue': questions['termsOfLicense']}">What are the terms of license?</div>
                <div class="faq-answer" *ngIf="questions['termsOfLicense']">
                    We have written a short recap of the terms of license, which can be <a
                        href='https://aiva.crisp.help/en/article/i-dont-understand-the-terms-of-license-1wqvh5v/'
                        target="_blank">read here</a>.
                    You can also read the full End User License Agreement <a href='https://www.aiva.ai/legal/1'
                        target="_blank">here</a>.
                </div>
            </div>

            <div class="faq-section">
                <div class="faq-question" (click)="toggleQuestion('company')"
                    [ngClass]="{'faq-question-blue': questions['company']}">I am part of a Company that wants to use
                    AIVA. Can I do that with the plans above?</div>
                <div class="faq-answer" *ngIf="questions['company']">If you generate less than $300k in revenues
                    annually or have less than 3 employees, you can use AIVA with a Pro Plan.<br><br>If you generate
                    more than $300k in revenues annually AND have 3 or more employees in your company, the plans above
                    do not apply to you, so please <a href='https://www.aiva.ai/contact' target="_blank">contact us</a>.
                </div>
            </div>
        </div>

    </div>
</div>