import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core'
import { GenerationProfileService } from '@services/generation-profile/generationprofile.service'
import { ModalService } from '@services/modal.service'

@Component({
    selector: 'create-gp',
    templateUrl: 'create-gp.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CreateGPComponent {
    emotions = [1, 2, 3, 4, 5]

    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor(private ref:ChangeDetectorRef, private modalService:ModalService, private gpService:GenerationProfileService) {

    }

    closeModal() {
        this.close.emit()
    }


    onEmotionChange(event) {
        this.closeModal()
        this.gpService.createGenerationProfile(event.value)
    }
}