import Bus from "./bus"
import { Time } from "../../modules/time"
import { TemplateTrack } from "../../interfaces/score/templateScore"
import Layer from "./layer"
import { RangeWithID } from "../../interfaces/general"
import { v4 as uuidv4 } from "uuid"
import { InstrumentJSON, InstrumentsJSON } from "../../interfaces/score/general"
import { cloneDeep } from "lodash"
export default class TrackBus extends Bus {
    trackIDs: string[] = []

    normalizationValue = 0
    masterBus

    id: string

    toggle = true

    octave = 0
    panning = 0
    gainOffset = 0
    dynamicOffset = 0
    breathingGain = 0

    packDBColumns = null

    mute = false
    solo = false

    name
    blocks: RangeWithID[] = []
    reference: InstrumentJSON

    // for efficiency purposes, the gainNode combines three effects: gain, sidechain compression and dynamics balancing
    gainNode

    extraMetadata

    pannerNode
    sidechainNode

    autoStaccato = true
    autoPedal = false

    destination

    isGuitar = false

    tempoMap
    timestepRes

    strummingTemplates

    loading = false

    dynamic

    volume = {
        left: -Infinity,
        right: -Infinity,
        stereo: -Infinity,
    }

    instrument = null

    drumkitMapping
    drumkitPitchToChannelMapping

    constructor(
        name: string,
        octave: number,
        reference: InstrumentJSON,
        blocks: RangeWithID[],
        dynamicOffset: number,
        gainOffset: number,
        breathingGain: number,
        panning: number,
        mute: boolean,
        solo: boolean
    ) {
        super(dynamicOffset, gainOffset)

        this.id = uuidv4()
        this.name = name
        this.octave = octave
        this.reference = reference
        this.blocks = blocks
        this.dynamicOffset = dynamicOffset
        this.gainOffset = gainOffset
        this.panning = panning
        this.mute = mute
        this.solo = solo
        this.breathingGain = breathingGain

        if (name == null || name.includes("silent") || reference == null) {
            return
        }

        if (this.breathingGain == null) {
            this.breathingGain = reference.breathing_gain
                ? reference.breathing_gain * 100
                : 0
        }

        this.isGuitar = reference.guitar != null

        const playingStyle = name.split(".")[2]
        const articulation = name.split(".")[3]

        var currentPatch

        for (var p = 0; p < reference.patches.length; p++) {
            const patch = reference.patches[p]

            if (
                patch.playing_style == playingStyle &&
                patch.articulation == articulation
            ) {
                currentPatch = patch

                break
            }
        }

        if (currentPatch == null || currentPatch.loudness == null) {
            currentPatch = {
                loudness: {
                    "0": {
                        5: 0,
                    },

                    "127": {
                        5: 0,
                    },
                },

                realtimeSamplerDynamic: 0,
            }
        }

        currentPatch.loudness["0"] = this.getLowerLimit(currentPatch)
        currentPatch.loudness["127"] = this.getHigherLimit(currentPatch)

        if (currentPatch.realtimeSamplerDynamic == null) {
            currentPatch.realtimeSamplerDynamic = 72
        }
    }

    getLowerLimit(patch) {
        var limit = 127

        for (var loudness in patch.loudness) {
            if (limit > parseInt(loudness)) {
                limit = parseInt(loudness)
            }
        }

        return patch.loudness[limit + ""]
    }

    getHigherLimit(patch) {
        var limit = 0

        for (var loudness in patch.loudness) {
            if (limit < parseInt(loudness)) {
                limit = parseInt(loudness)
            }
        }

        return patch.loudness[limit + ""]
    }

    setupCleaning(node) {
        node.onended = function () {
            node.disconnect()
            node.stop()
        }
    }

    noteHasEqualTrackBus(note) {
        for (var trackBus of note.trackBuses) {
            if (this.name === trackBus.name && this.id == trackBus.id) {
                return true
            }
        }

        return false
    }

    setPan() {
        // For Safari
        if (this.pannerNode.positionX == null) {
            this.pannerNode.setPosition(
                this.panning,
                0,
                1 - Math.abs(this.panning)
            )
        } else {
            this.pannerNode.positionX.value = this.panning
            this.pannerNode.positionY.value = 0
            this.pannerNode.positionZ.value = 1 - Math.abs(this.panning)
        }
    }

    formatPlayingStyle(instrument, playingStyle) {
        if (
            playingStyle == "sc-comp" ||
            playingStyle.includes("echo") ||
            playingStyle.includes("expressive") ||
            playingStyle.includes("nat-autostac") ||
            (instrument == "duduk" && playingStyle.includes("processed"))
        ) {
            return "nat"
        }

        return playingStyle
    }

    formatArticulation(articulation) {
        if (articulation == "slur" || articulation == "rearticulated-slur") {
            if (this.reference.guitar != null) {
                return "stac"
            }

            return "sus"
        }

        return articulation
    }

    resampBuffer(sample, notePitch) {
        return Math.pow(2, (notePitch - sample.pitch) / 12)
    }

    clone(): TrackBus {
        var cloneObj = new (<any>this.constructor())()

        for (var attribut in this) {
            cloneObj[attribut] = this[attribut]
        }

        return cloneObj
    }

    copy() {
        var newObject = new TrackBus(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            false
        )

        const keys = Object.keys(this)

        for (var k = 0; k < keys.length; k++) {
            const key = keys[k]

            if (key == "analyser") {
                newObject[key] = null
            } else if (key === "blocks") {
                newObject[key] = this[key].map(b => {
                    const newBlock: RangeWithID = cloneDeep(b)
                    newBlock.id = uuidv4()

                    return newBlock
                })
            } else if (
                typeof this[key] == "object" &&
                Array.isArray(this[key])
            ) {
                newObject[key] = Object.assign([], this[key])
            } else if (typeof this[key] == "object") {
                newObject[key] = Object.assign({}, this[key])
            } else {
                newObject[key] = this[key]
            }
        }

        newObject.id = uuidv4()
        newObject.trackIDs = [newObject.id]

        return newObject
    }

    toggleMute(value = null) {
        if (value != null) {
            this.mute = value
        } else {
            this.mute = !this.mute
        }

        if (this.mute) {
            this.solo = false
        }
    }

    toggleSolo(value = null) {
        if (value != null) {
            this.solo = value
        } else {
            this.solo = !this.solo
        }

        if (this.solo) {
            this.mute = false
        }
    }

    splitName() {
        return {
            section: this.name.split(".")[0],
            instrument: this.name.split(".")[1],
            playingStyle: this.name.split(".")[2],
            articulation: this.name.split(".")[3],
        }
    }

    addBlock(block: RangeWithID) {
        if (!this.blocks.includes(block)) {
            this.blocks.push(block)
        }
    }

    isPlayedAt(timestep: number): boolean {
        for (const b of this.blocks) {
            if (b.start <= timestep && b.end > timestep) {
                return true
            }
        }

        return false
    }

    decode(channelIndex: number, layerName: string): TemplateTrack {
        const name = this.splitName()

        return {
            id: this.id,
            track_layer: layerName,
            layer: layerName,
            name: this.name,
            instrument: name.section + "." + name.instrument,
            use_velocity: name.articulation === "stac",
            use_expression: name.articulation !== "stac",
            auto_pedal: this.autoPedal,
            octave: this.octave,
            mute: this.mute,
            solo: this.solo,
            dynamic_offset: this.dynamicOffset,
            gain_offset: this.gainOffset,
            panning: this.panning,
            breathing_gain: this.breathingGain,
            gm: this.reference.gm,

            // MIDI channel must be set to 9 for percussion
            // This is part of the MIDI standard
            channel: name.section == "p" ? 9 : channelIndex,

            track: [],
            pack_db_columns: this.packDBColumns,
        }
    }

    getPatchObject(instruments: InstrumentsJSON) {
        const section = this.name.split(".")[0]
        const instrumentName = this.name.split(".")[1]
        const playingStyle = this.name.split(".")[2]
        const articulation = this.name.split(".")[3]

        for (let i = 0; i < instruments[section].length; i++) {
            const inst = instruments[section][i]

            if (inst.name !== instrumentName) {
                continue
            }

            const hasOnlyOnePatch =
                inst.patches.length === 1 && inst.patches[0].name === "Natural"

            if (hasOnlyOnePatch) {
                return {
                    inst,
                }
            }

            for (const patch of inst.patches) {
                const isEqualPatch =
                    playingStyle == patch.playing_style &&
                    articulation == patch.articulation

                if (!isEqualPatch) {
                    continue
                }

                return {
                    inst,
                    patch,
                }
            }
        }

        return null
    }

    getPatch(instruments: InstrumentsJSON): string {
        const section = this.name.split(".")[0]
        const object = this.getPatchObject(instruments)

        let result = "Piano"

        if (object === null) {
            result = "Piano"
        } else if (section == "p") {
            result = object.inst.full_name
        } else {
            result = object.patch
                ? object.inst.full_name + " | " + object.patch.name
                : object.inst.full_name
        }

        return result
    }
}
