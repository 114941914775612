import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core"

import { ParentClass } from "../../../parent"
import { Helpers } from "../../../modules/helpers.module"
import { DesignService } from "@services/design.service"
import { Misc } from "@common-lib/modules/misc"

@Component({
    selector: "context-menu",
    templateUrl: "context-menu.component.html",
    styleUrls: ["context-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent extends ParentClass {
    @Output() close: EventEmitter<any> = new EventEmitter()

    @Input() coordinates: {
        x: number
        y: number
    }

    @Input() width: string = "auto"

    @ViewChild("contextMenu") contextMenu

    public finishedInitialising: boolean = false

    constructor(private design: DesignService, private ref: ChangeDetectorRef) {
        super()
    }

    async ngAfterViewInit() {
        const playerBarHeight =
            parseInt(
                this.design
                    .getCSSProperty("player-bar-height")
                    .replace("px", "")
            ) + 2

        await Helpers.waitForElementByID("menu-bgrd")

        const height = (<HTMLDivElement>(
            this.contextMenu.nativeElement
        )).getBoundingClientRect().height

        const width = (<HTMLDivElement>(
            this.contextMenu.nativeElement
        )).getBoundingClientRect().width

        if (
            window.innerHeight - playerBarHeight <
            this.coordinates.y + height
        ) {
            this.coordinates.y = window.innerHeight - playerBarHeight - height
        }

        if (window.innerWidth < this.coordinates.x + width) {
            this.coordinates.x -= width
        }

        this.ref.detectChanges()

        await Misc.wait(0.1)

        this.finishedInitialising = true
    }

    public handleClickOutside(event: MouseEvent) {
        if (this.finishedInitialising === false) {
            return
        }

        this.close.emit()
    }
}
