import { Observable, fromEvent, map, takeUntil, throttleTime } from "rxjs"

export interface Coordinates {
    x: number
    y: number  
    shiftKey: boolean
}

export interface CoordinatesAbs extends Coordinates {
    xAbs: number,   
    yAbs: number,
}

export module EventHandlers {
    /**
     * Creates an event listener that sends events based on a certain condition
     * @param eventName e.g. "mousemove" for the mousemove event
     * @param condition this needs to be true, so the Observable passes the event value
     *                  the goal is to filter out unnecessary event triggers
     * @param target    the target element that is listened to
     * @returns
     */
    export function getMouseEventObservable(
        eventName: string,
        target: Document | HTMLDivElement | HTMLButtonElement,
        throttleTimeInMs: number,
        destroyObservable,
        container: HTMLElement | HTMLDivElement | HTMLButtonElement
    ): Observable<Coordinates | CoordinatesAbs> {
        return fromEvent<MouseEvent>(target, eventName).pipe(
            throttleTime(throttleTimeInMs),
            takeUntil(destroyObservable),
            map((event: MouseEvent) => {
                // prevents the browser right click contextmenu to show
                if (
                    eventName === "click" || 
                    eventName === "contextmenu"
                ) {
                    event.preventDefault()
                    event.stopPropagation()
                }
                
                return getContainerCoordinates(event, container)
            })
        )
    }

    export function getContainerCoordinates(
        event: MouseEvent,
        container: HTMLDivElement | HTMLButtonElement | HTMLElement
    ): CoordinatesAbs {
        const rect = container.getBoundingClientRect()

        return {
            x: Math.max(0, event.x - rect.x),
            y: Math.max(0, event.y - rect.y),
            xAbs: event.x,
            yAbs: event.y,
            shiftKey: event.shiftKey,
        }
    }
}
