<div class="loading-container" *ngIf="loading">
    <div class="loader"></div>
</div>

<cw-step2-part1
    *ngIf="!loading && part === 'part1'"
    [service]="service"></cw-step2-part1>

<cw-step2-part2
    *ngIf="!loading && part === 'part2'"
    [service]="service"></cw-step2-part2>
