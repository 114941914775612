import Tempo from "../../../general/classes/score/tempo"
import Score from "../../../general/classes/score/score"
import {
    AUTOMATION_TIMESTEP_RES,
    MAX_TEMPO,
    MIN_TEMPO,
    TIMESTEP_RES,
} from "../../../general/constants/constants"
import { Time } from "../../../general/modules/time"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    CanvasType,
    GridDimensionsDictionary,
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    TempoCanvasContext,
} from "../types"
import ScoreCanvas from "./score-canvas"

export default class TempoCanvas extends ScoreCanvas {
    constructor(
        public context: TempoCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        const shouldRenderCanvas = this.shouldRenderCanvas(
            scoreState.renderingType
        )

        if (!shouldRenderCanvas) {
            return
        }

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: grid,
            noteRes: AUTOMATION_TIMESTEP_RES,
            nbOfYValues: MAX_TEMPO - MIN_TEMPO,
        })

        const score: Score = scoreState.score

        if (!score) {
            return
        }

        this.renderCanvas(score)
    }

    private renderCanvas(score: Score) {
        const scrollToTimestep = Time.convertTimestepsToAnotherRes(
            this.scrollToTimestep,
            this.noteRes,
            TIMESTEP_RES
        )
        const ctx = this.getContext("canvas")
        const tempoMap = this.getTempoMap(score)

        ctx.beginPath()

        const start = Math.floor(scrollToTimestep)
        const end = Math.ceil(scrollToTimestep + this.getTimestepRange())

        const scrollXOffset = ScoreCanvas.getPixelsForTimesteps({
            timesteps: scrollToTimestep,
            removeLastGutterPx: "none",
            grid: this.grid,
            timestepRes: TIMESTEP_RES,
        })

        let lastHeight: number | undefined = undefined

        if (scrollToTimestep < 0 && tempoMap[0] !== undefined) {
            const y = MAX_TEMPO - tempoMap[0] + MIN_TEMPO
            const eventHeight =
                ((y - MIN_TEMPO) / (MAX_TEMPO - MIN_TEMPO)) * this.height

            ctx.lineTo(0, eventHeight)
        }

        for (let v = start; v <= end; v++) {
            if (tempoMap[v] === undefined) {
                continue
            }

            const y = MAX_TEMPO - tempoMap[v] + MIN_TEMPO

            const eventHeight =
                ((y - MIN_TEMPO) / (MAX_TEMPO - MIN_TEMPO)) * this.height

            lastHeight = eventHeight

            const x = ScoreCanvas.getPixelsForTimesteps({
                timesteps: v,
                removeLastGutterPx: "none",
                grid: this.grid,
                timestepRes: TIMESTEP_RES,
            })

            ctx.lineTo(Math.max(0, x - scrollXOffset), eventHeight)
        }

        if (lastHeight !== undefined) {
            ctx.lineTo(this.width, lastHeight)
        }

        ctx.lineTo(this.width, this.height)
        ctx.lineTo(0, this.height)

        ctx.lineWidth = 0
        ctx.fillStyle = this.getEffectGradient(ctx)

        ctx.stroke()
        ctx.fill("evenodd")

        ctx.closePath()
    }

    private getEffectGradient(ctx: CanvasRenderingContext2D): CanvasGradient {
        const gradient = ctx.createLinearGradient(0, 0, 0, 350)
        gradient.addColorStop(0, "#00a2ff")
        gradient.addColorStop(1, "#000cb5")

        return gradient
    }

    private getTempoMap(score: Score): number[] {
        const tempoMap = score.tempoMap
        const lastNoteTimesteps = Math.round(
            Time.fractionToTimesteps(TIMESTEP_RES, score.scoreLength)
        )

        const newTempoMap = tempoMap.map((tempo: Tempo) => {
            return {
                x: tempo.timesteps,
                y: tempo.bpm,
            }
        })

        if (newTempoMap[newTempoMap.length - 1].x < lastNoteTimesteps) {
            newTempoMap.push({
                x: lastNoteTimesteps,
                y: newTempoMap[newTempoMap.length - 1].y,
            })
        }

        const tempoValues: number[] = []

        for (let i = 0; i < newTempoMap.length; i++) {
            let x = newTempoMap[i].x
            let y = newTempoMap[i].y

            const nextX =
                i < newTempoMap.length - 1
                    ? newTempoMap[i + 1].x - 1
                    : lastNoteTimesteps

            for (let j = x; j <= nextX; j++) {
                tempoValues.push(y)
            }
        }

        return tempoValues
    }
}
