import { BarCount, TimeSignature } from "../../../types/score"
import {
    ACCOMPANIMENT_PACK_UPDATE_TYPES,
    LayerType,
    PITCH_SCALE_COUNT,
} from "../../../constants/constants"
import {
    AccompanimentPackSchema,
    AccompanimentPackStepsSchema,
    AccompanimentPackStepSchema,
    AccompanimentPackLoadingStatus,
    AccompanimentPackLoadingStatusType,
} from "../../../interfaces/db-schemas/accompanimentPackData"

import { GPAccompanimentPackSchema } from "../../../interfaces/db-schemas/generationprofile"
import { KeySignatureModule } from "../../../modules/keysignature.module"

import {
    AccompanimentPackME,
    AccompanimentPatternSchema,
    ReducedAccompanimentPatternME,
} from "../../../interfaces/music-engine/accompanimentPackData"

import Pack from "../pack"
import { cloneDeep } from "lodash"
import Score from "../../score/score"
import { v4 as uuid } from "uuid"
import { STEPS_SCHEMA_DEFAULT } from "../../../constants/defaultValues"
import { AccompanimentManipulation } from "../../../modules/accompaniment-pack/accompaniment-manipulation"
import { KeySignature } from "../../../interfaces/score/keySignature"
import { Note } from "../../score/note"
import InstrumentPatch from "../../score/instrumentpatch"

export default class AccompanimentPack extends Pack {
    type: LayerType = LayerType.ACCOMPANIMENT
    sync = {}
    idx: number = 0
    tags: string[] = []
    category: string = ""
    deprecated = false
    compatibleArticulations: string[] = []
    pacing: string[] = ["any"]
    percussionFunction: string[] = ["electronic", "acoustic"]
    userID: any = ""
    deleted: boolean = false
    previewLength: string = "0"
    patterns: Array<ReducedAccompanimentPatternME> = []
    sourceTimeSignature: TimeSignature = [4, 4]
    steps: AccompanimentPackStepsSchema = STEPS_SCHEMA_DEFAULT
    createdByAIVA: boolean = false
    maxTempo: number = 180
    creationDate: number = Date.now()
    template: boolean = false
    legacy: boolean = false
    keySignature: string // e.g. "C# major"

    constructor(lowestNote: number) {
        super(uuid(), "", [], [], lowestNote)
    }

    static fromScore({
        id,
        score,
        name,
        userID,
        type,
        patternLength,
    }: {
        id: string
        score: Score
        name: string | undefined
        userID: string
        type: LayerType
        patternLength: BarCount
    }): AccompanimentPack {
        if (type !== "Accompaniment" && type !== "Bass") {
            throw "Pack type other than Accompaniment and Bass are currently not supported"
        }

        const lowestNote = AccompanimentManipulation.getLowestNoteByLayer(
            type === "Accompaniment" ? LayerType.CHORDS : LayerType.BASS
        )

        const pack = new AccompanimentPack(lowestNote)
        pack.packID = id
        pack.name = name === undefined ? "New accompaniment" : name
        pack.userID = userID
        pack.patterns = Score.convertScoreToPatterns(score, type, patternLength)
        pack.keySignature = score.keySignatures[0][1]

        return pack
    }

    static fromJSON(object) {
        const pack: AccompanimentPack = Object.assign(
            new AccompanimentPack(object.lowestNote),
            object
        )

        if (pack.type === "Percussion") {
            if (!pack.lowestNote) {
                pack.lowestNote = 0
            }

            if (!pack.octaves) {
                pack.octaves = 2
            }
        }

        pack.instruments = pack.instruments.map(i =>
            InstrumentPatch.fromJSON(i)
        )

        return pack
    }

    static changePack(currentPack, newPack) {
        let keys = Object.keys(newPack)

        for (let key of keys) {
            if (
                key == "idx" ||
                key == "sync" ||
                key == "synchronisation" ||
                key == "transform" ||
                key == "lowestNote" ||
                key == "instruments"
            ) {
                continue
            }

            currentPack[key] = newPack[key]
        }
    }

    fromGenerationProfileSchema(pack: GPAccompanimentPackSchema) {
        for (let key in pack) {
            this[key] = pack[key]
        }
    }

    fromMEFormat(pack: AccompanimentPackME) {
        this.packID = pack.pack_id
        this.name = pack.name
        this.type = pack.type
        this.tags = pack.tags
        this.deprecated = pack.deprecated
        this.lowestNote = undefined
        this.octaves = pack.octaves
        this.compatibleArticulations = pack.compatible_articulations
        this.pacing = pack.pacing
        this.percussionFunction = pack.perc_function
        this.category = pack.category
        this.sync = {}

        if (pack.type == "Percussion") {
            this.compatibleArticulations = ["staccato"]
        }
    }

    static stepsToLoadingStatus(
        steps: AccompanimentPackStepsSchema
    ): AccompanimentPackLoadingStatus {
        const increment = 100 / ACCOMPANIMENT_PACK_UPDATE_TYPES.length

        let loadingStatus = 0
        let error: undefined | string = undefined

        for (const s in steps) {
            const step = steps[s]

            if (step.isFinished && step.wasSuccessful) {
                loadingStatus += increment
            }

            if (step.isFinished && !step.wasSuccessful) {
                error = step.error
            }
        }

        if (error !== undefined) {
            return {
                type: "error",
                loadingStatus,
                error,
            }
        } else if (loadingStatus < 100) {
            return {
                type: "loading",
                loadingStatus,
            }
        }

        return {
            type: "finished",
            loadingStatus,
        }
    }

    /**
     * We currently only allow packs to be of type "Accompaniment" and "Bass".
     * In order to achieve this, we translate certain layer types to be of type
     * "Accompaniment" in cases where we want users to be able to create packs for
     * layers that are to be handled as "Accompaniment" packs but are of type "Chords" e.g..
     *
     * This is not the case for "Bass" and "Percussion" layers.
     * @param layer LayerType
     * @returns LayerType
     */
    static getAccompanimentPackTypeByLayerType(layerType: LayerType) {
        let type = LayerType.ACCOMPANIMENT

        if (layerType.includes("Ornament")) {
            type = LayerType.ORNAMENTS
        } else if (layerType.includes("Percussion")) {
            type = LayerType.PERCUSSION
        } else if (layerType.includes("Bass")) {
            type = LayerType.BASS
        }

        return type
    }

    static hasPitchOutOfTriad(
        patterns: ReducedAccompanimentPatternME[],
        triad: KeySignature
    ) {
        const triadNotes: string[] =
            KeySignatureModule.getTriadPitchesType(triad)

        for (const pattern of patterns) {
            for (const note of pattern.pattern) {
                for (const p of note.pitch) {
                    const noteType: string = Note.getNoteTypeAndOctave(p).type

                    if (!triadNotes.includes(noteType)) {
                        return true
                    }
                }
            }
        }

        return false
    }

    static createDefaultReducedPattern(): ReducedAccompanimentPatternME {
        return {
            pattern: [],
            bars: 1,
            sections: ["Intro"],
            pre_filter: [0],
            spread: 0,
            upper_range: 1,
        }
    }

    static normalizePitchForPatterns(
        patterns: ReducedAccompanimentPatternME[],
        triad: KeySignature
    ): ReducedAccompanimentPatternME[] {
        let triadNotes = []

        for (let i = 0; i < PITCH_SCALE_COUNT / 3; i++) {
            triadNotes = triadNotes.concat(
                KeySignatureModule.getAbsoluteTriadPitches(triad, i + 2)
            )
        }

        const newPatterns = []

        for (const pattern of patterns) {
            const normalizePitch = AccompanimentPack.hasPitchOutOfTriad(
                [pattern],
                triad
            )

            if (!normalizePitch) {
                newPatterns.push(cloneDeep(pattern))

                continue
            }

            let pitches = []

            for (const p of pattern.pattern) {
                pitches = pitches.concat(p.pitch)
            }

            pitches = [...new Set(pitches)].sort()

            const pitchMap = {}
            let counter = 0

            for (const pitch of pitches) {
                pitchMap[pitch] = triadNotes[counter]
                counter++
            }

            const newPatternNotes = pattern.pattern.map(p => {
                const newP = cloneDeep(p)

                newP.pitch = p.pitch.map(pitch => {
                    return pitchMap[pitch]
                })

                return newP
            })

            const newPattern = { ...pattern }
            newPattern.pattern = newPatternNotes

            newPatterns.push(newPattern)
        }

        return newPatterns
    }

    public validate(validateForUI = false, type = "pack") {
        let validation = super.validate(validateForUI, type)

        const status = AccompanimentPack.stepsToLoadingStatus(this.steps)

        if (validation.valid && status.type === "loading") {
            validation.message =
                "Your accompaniment pack is loading, please wait."
            validation.valid = false
        } else if (validation.valid && status.type === "error") {
            validation.message = "Error while saving your pack: " + status.error
            validation.valid = false
        }

        return validation
    }
}
