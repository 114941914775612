import {
    MOUSE_MOVE_THRESHOLD_X,
    MOUSE_MOVE_THRESHOLD_Y,
} from "../../../general/constants/score-rendering-engine"
import Layer from "../../../general/classes/score/layer"
import { ImmutableNote, Note } from "../../../general/classes/score/note"
import PercussionLayer from "../../../general/classes/score/percussionlayer"
import Score from "../../../general/classes/score/score"
import { Time } from "../../../general/modules/time"
import { Time as Time2 } from "../../../general/modules/time2"

import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    AccompanimentDesignerCanvasContext,
    HoveredElement,
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    GridDimensionsDictionary,
    GridCoordinates,
    CanvasType,
    NoteSelection,
    NoteDescriptor,
} from "../types"
import ScoreCanvas from "./score-canvas"
import {
    SCALE_START_OCTAVE_WITH_INDEXING,
    TIMESTEP_RES,
    SHOW_TIMESTEP_GUTTERS_THRESHOLD,
    SHOW_BEAT_GUTTERS_THRESHOLD,
    NOTE_QUANTIZATION_THRESHOLDS,
    SHOW_HALF_BEAT_GUTTERS_THRESHOLD,
    PITCH_SCALE_COUNT,
    PITCH_CONTINOUS_COUNT,
} from "../../../general/constants/constants"
import { HoveringType, HoveringTypeEnum } from "../../../general/types/general"
import { SRActionTypes } from "../states/score-rendering/score-rendering.actions"
import { Coordinates } from "../../../general/modules/event-handlers"
import { NotesObject } from "../../../general/classes/score/notesObject"
import { ScoreManipulation } from "../../../general/modules/scoremanipulation"
import { KeySignature } from "../../../general/interfaces/score/keySignature"
import Section from "../../../general/classes/score/section"
import { featureFlags } from "../../../general/utils/feature-flags"
import { KeySignatureModule } from "../../../general/modules/keysignature.module"
import { ChordManipulation } from "../../../general/modules/chord-manipulation.module"
import { CWKeyMode } from "../../../general/interfaces/composition-workflow.interface"
import { TemplateChord } from "../../../general/interfaces/score/templateScore"
import { Fraction } from "../../../general/classes/score/fraction"

export class AccompanimentDesignerCanvas extends ScoreCanvas {
    private selectedNote: Note | undefined

    private layer: Layer

    private firstMove: boolean = false
    private preRenderedGrid: HTMLCanvasElement
    private readonly regenerateSectionLayerButton: HTMLButtonElement
    private sectionToRegenerate: Section
    private mouseHoverCoordinates: Coordinates

    private get skipTimesteps() {
        return (
            this.queries.scoreRendering.all.resizeFactor <
            SHOW_TIMESTEP_GUTTERS_THRESHOLD
        )
    }

    private getPitchCount(grid) {
        const pitchCount = this.context.pitchCount
            ? this.context.pitchCount
            : grid[this.gridType].pitchStepDomain === "scale"
            ? PITCH_SCALE_COUNT
            : PITCH_CONTINOUS_COUNT

        return pitchCount
    }

    private get timeSignature() {
        return this.queries.scoreRendering.score.timeSignatures[0][1]
    }

    private get skipBeatsteps() {
        return (
            this.queries.scoreRendering.all.resizeFactor <
            SHOW_BEAT_GUTTERS_THRESHOLD
        )
    }

    private get skipHalfBeatsteps() {
        return (
            this.queries.scoreRendering.all.resizeFactor <
            SHOW_HALF_BEAT_GUTTERS_THRESHOLD
        )
    }

    constructor(
        public context: AccompanimentDesignerCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)

        this.initListeners()
        this.regenerateSectionLayerButton =
            this.createRegenerateSectionLayerButton()
    }

    protected initListeners() {
        this.subscribeToNoteDescriptor()

        this.mouseDown$.subscribe(this.mouseDownHandler.bind(this))

        this.doubleClick$.subscribe(this.doubleclickHandler.bind(this))

        this.selection$.subscribe(event => {
            this.selectNotes(event)
        })

        this.move$.subscribe(event => {
            const move = performance.now()
            // console.log("mouseMoveHandler", event)
            this.mouseMoveHandler(event, "move")
            // console.log("move", performance.now()-move)
        })

        this.resize$.subscribe(event => {
            // const resize = performance.now()
            this.mouseMoveHandler(event, "resize")
            // console.log("resize", performance.now()-resize)
        })

        this.hoveredDetection$.subscribe(event => {
            const hoveredNote = this.detectHoveredNote(event)
            this.setHoveredElement(hoveredNote)
            if (
                featureFlags.generateLayerWithLLMInEditor &&
                window.location.href.includes("editor")
            ) {
                this.updateLayerRegenerateButton(event)
            }
        })
        if (
            !window.location.href.includes("editor") ||
            !featureFlags.generateLayerWithLLMInEditor
        ) {
            return
        }
        this.queries.scoreRendering.resizeFactor$.subscribe(
            this.updateLayerRegenerateButton.bind(this, null, true)
        )

        this.scroll$.subscribe(
            this.updateLayerRegenerateButton.bind(this, null, true)
        )
    }

    protected mouseDownHandler(event: Coordinates) {
        this.firstMove = true

        super.mouseDownHandler(event, (grid: GridCoordinates) => {
            const result = this.getHoveringType(event, grid, true)

            if (result === undefined && !event.shiftKey) {
                this.srEmitter$.next({
                    type: SRActionTypes.unselectAll,
                    data: {},
                })
            }

            return result
        })

        const grid = this.getGridCoordinates(event, false)

        const drawnNote = this.drawNote(grid)

        if (drawnNote) {
            this.selectedNote = drawnNote

            this.getNoteDescriptor(event, this.getNoteDescriptorNote(drawnNote))
        }
    }

    private updateLayerRegenerateButton(
        event: Coordinates,
        usePrevPosition: boolean = false
    ) {
        const el = document.querySelector(
            "#regenerate-section-layer"
        ) as HTMLElement

        if (!el) {
            return
        }

        const position = this.getLayerRegenerateButtonPosition(
            event,
            usePrevPosition
        )

        if (!position) {
            return
        }

        el.style.left = `${position.x}px`
        el.style.top = `0`
        el.style.opacity = "1"
    }

    private getLayerRegenerateButtonPosition(
        event: Coordinates,
        usePrevPosition: boolean
    ): Coordinates {
        const buttonWidth = 32
        let mouseTimestep: number

        if (!usePrevPosition) {
            this.mouseHoverCoordinates = event
            mouseTimestep = super.getTimestepsForPixels({
                pixels: event.x + this.scrollXOffset,
            })
        } else if (this.mouseHoverCoordinates) {
            mouseTimestep = super.getTimestepsForPixels({
                pixels: this.mouseHoverCoordinates.x + this.scrollXOffset,
            })
        } else {
            return null
        }

        const sectionTimesteps = this.queries[
            "scoreRendering"
        ].score.getSectionInRangeAtTimestep(mouseTimestep, this.noteRes)

        if (!sectionTimesteps) return null

        const sectionCoordinates = ScoreCanvas.sectionToCoordinates(
            sectionTimesteps.section,
            this.grid,
            this.scrollToTimestep,
            this.pxPerTimestep,
            this.noteRes
        )

        if (
            this.sectionToRegenerate?.index ===
                sectionTimesteps?.section.index &&
            !usePrevPosition
        ) {
            return null
        }

        this.sectionToRegenerate = sectionTimesteps.section

        if (!sectionTimesteps) {
            return null
        }
        let x: number

        x = Math.min(
            sectionCoordinates.x + sectionCoordinates.width - buttonWidth,
            this.width - buttonWidth
        )

        const coordinates: Coordinates = {
            x,
            y: buttonWidth,
            shiftKey: false,
        }

        return coordinates
    }

    private createRegenerateSectionLayerButton() {
        if (
            !window.location.href.includes("editor") ||
            !featureFlags.generateLayerWithLLMInEditor
        ) {
            return
        }
        const imgElement = this.createElement({
            type: "img",
        }) as HTMLImageElement
        imgElement.src = "/assets/img/achievements/sparkle.svg"

        const wrapper = this.createElement({
            type: "div",
            innerHTML: "",
            id: "regenerate-section-layer",
        })

        const button = this.createElement({
            type: "button",
            innerHTML: "",
            id: "regenerate-section-layer__button",
        }) as HTMLButtonElement

        wrapper.addEventListener("mousedown", $event => {
            $event.stopPropagation()
            if (featureFlags.generateLayerWithLLMInEditor) {
                this.queries.scoreRendering.regenerateLayerWithLLM$.next(
                    this.sectionToRegenerate
                )
            }
        })

        button.appendChild(imgElement)

        wrapper.appendChild(button)

        this.context.canvasContainer.appendChild(wrapper)

        return button
    }

    protected doubleclickHandler(event: Coordinates) {
        const grid = this.getGridCoordinates(event, false)
        this.removeNote(grid)
    }

    protected async mouseUpHandler(event: Coordinates) {
        if (this.mouseDownStart === undefined) {
            return
        }

        this.selectedNote = undefined

        if (
            this.queries.scoreRendering.overlappingNotes ||
            this.queries.scoreRendering.harmonyLock
        ) {
            // Commented this in order to have drawn notes always voiced
            // if (this.hoveredElement?.element instanceof Note) {

            // }

            this.srEmitter$.next({
                type: SRActionTypes.endManipulatingNotes,
                data: {
                    removeOverlappingNotes: true,
                },
            })
        } else {
            this.srEmitter$.next({
                type: SRActionTypes.setRenderingType,
                data: {
                    type: "AccompanimentDesignerCanvas",
                },
            })
        }

        super.mouseUpHandler(event)
    }

    private mouseMoveHandler(event: Coordinates, type: "move" | "resize") {
        let maxScoreLength = undefined

        if (this.grid.pitchStepDomain === "scale") {
            maxScoreLength =
                this.queries.scoreRendering.getValue().score?.scoreLength
        }

        const r = this.moveHandler(event, {
            maxScoreLength: maxScoreLength,
            type,
            thresholds: NOTE_QUANTIZATION_THRESHOLDS,
            quantize: true,
            selectedNote: this.selectedNote,
        })

        if (!r) {
            return
        }

        if (this.firstMove) {
            this.actions.scoreRendering.manager.emitter$.next({
                type: SRActionTypes.startNoteManipulation,
                options: {
                    isUndoable: true,
                },
            })

            this.firstMove = false
        }

        let keySignature: KeySignature | undefined = undefined
        let scale: number[] | undefined = undefined

        if (
            this.grid.pitchStepDomain === "scale" &&
            this.queries.scoreRendering.score?.keySignatures &&
            this.queries.scoreRendering.score?.keySignatures?.length
        ) {
            keySignature = Score.getKeySignatureObject(
                this.queries.scoreRendering.score?.keySignatures[0][1]
            )
            scale = KeySignatureModule.getTriadScalePitches(keySignature)
        }

        if (type === "move") {
            let diffPitchsteps = r.diffPitchsteps

            const selectedNotes = this.queries.scoreRendering.selectedNotes

            const scrollToTimestep = Time.convertTimestepsToAnotherRes(
                this.scrollToTimestep,
                this.noteRes,
                TIMESTEP_RES
            )
            const scrollRange =
                Time.convertTimestepsToAnotherRes(
                    this.getTimestepRange(),
                    this.noteRes,
                    TIMESTEP_RES
                ) + scrollToTimestep

            const timestepRange = [scrollToTimestep, scrollRange]

            // move single notes
            if (selectedNotes?.length === 1) {
                this.srEmitter$.next({
                    type: SRActionTypes.moveSingleNote,
                    data: {
                        pitchDelta: diffPitchsteps,
                        timestepDelta: r.diffTimesteps,
                        timesteps: r.now.timesteps,
                        timestepRange,
                        selectedNoteID: this.selectedNote.noteID,
                        enableQuantization: true,
                        scale: scale,
                    },
                })

                this.selectedNote =
                    this.queries.scoreRendering?.selectedNotes?.getFirstGroup()[0]

                if (this.selectedNote) {
                    this.drawNoteDuration = this.selectedNote?.duration
                }
            } else {
                this.srEmitter$.next({
                    type: SRActionTypes.moveNotes,
                    data: {
                        pitchDelta: diffPitchsteps,
                        timestepDelta: r.diffTimesteps,
                        selectedNoteID: this.selectedNote.noteID,
                        enableQuantization: true,
                        scale: scale,
                        timestepRange,
                    },
                })
            }
        } else {
            if (!r.diffTimesteps || !this.mouseDownStart) {
                return
            }

            this.setCanvasCursor("col-resize")

            this.mouseDownStart.mouseDownLocation =
                this.queries.scoreRendering.getValue().resizeType ||
                this.mouseDownStart.mouseDownLocation

            this.srEmitter$.next({
                type: SRActionTypes.resizeNotes,
                data: {
                    timestepOffset: r.diffTimesteps,
                    timestepResolution: this.noteRes,
                    resizeType: this.mouseDownStart.mouseDownLocation,
                    maxScoreLength: maxScoreLength,
                    selectedNoteID: this.selectedNote.noteID,
                },
            })

            // remember the note duration for the next note
            this.drawNoteDuration = this.selectedNote?.duration
        }

        this.getNoteDescriptor(
            event,
            this.getNoteDescriptorNote(this.selectedNote)
        )
    }

    protected getNoteDescriptorNote(draggedNote: Note): Note {
        const range = [
            Time.convertTimestepsToAnotherRes(
                this.scrollToTimestep,
                this.noteRes,
                TIMESTEP_RES
            ),
            Time.convertTimestepsToAnotherRes(
                this.scrollToTimestep + this.getTimestepRange(),
                this.noteRes,
                TIMESTEP_RES
            ),
        ]

        const timestepRes = this.noteRes
        const timestepRange = range.map(ts =>
            Time.timestepsToFraction(timestepRes, ts)
        ) as [string, string]

        let noteDescriptorNote = this.queries.scoreRendering.selectedNotes
            .getFlatArray(timestepRange)
            .find(n => n?.noteID === draggedNote?.noteID)

        return noteDescriptorNote
    }

    /**
     * Warning: this function is not optimized for rendering large scores.
     * At the moment, it's recommend only to use it for smaller scores (i.e. in the
     * context of composition workflows, where we are not ever rendering more than 8 bars)
     */
    private renderGridCanvasWithHarmonyLock() {
        if (this.queries.scoreRendering.score === undefined) {
            return
        }

        const ctx: CanvasRenderingContext2D = this.getContext(
            "canvas-grid"
        ) as CanvasRenderingContext2D
        // ctx.globalCompositeOperation = "source-over"

        const normalRectCachedCanvas = document.createElement(
            "canvas"
        ) as HTMLCanvasElement

        const normalRectCachedCanvasCtx =
            normalRectCachedCanvas.getContext("2d")

        const colors = ScoreManipulation.getGridColorFillStyles()
        const normalColor = colors.normal

        let isNormalRectDrawn = false

        const context = ctx.canvas.getContext("2d")
        context.clearRect(0, 0, this.width, this.height)

        const chordPitches = this.queries.scoreRendering.score.chords.map(
            (chord: TemplateChord) => {
                return KeySignatureModule.getNotesForChord(chord[1]).map(n => {
                    return KeySignatureModule.getNotePitchByName(n)
                })
            }
        )

        this.traverseTimeGrid(
            (
                x: number,
                timesteps: number,
                currentGutterSize,
                previousGutterSize,
                width
            ) => {
                if (timesteps < 0) {
                    return true
                }

                const chord = ChordManipulation.getChordAtTime(
                    Time.timestepToFraction(timesteps, this.noteRes),
                    this.queries.scoreRendering.score!.chords
                )

                this.traversePitchGrid((y: number, absolutePitch: number) => {
                    const gridColor = ScoreManipulation.getGridColor(
                        true,
                        chordPitches[chord.index],
                        absolutePitch,
                        this.grid.pitchStepDomain
                    )

                    if (gridColor === normalColor && !isNormalRectDrawn) {
                        isNormalRectDrawn = true
                        normalRectCachedCanvas.width = width
                        normalRectCachedCanvas.height =
                            this.pxPerYStep - this.grid.pxPerPitchGutter

                        ScoreCanvas.generateNoteRect(
                            normalRectCachedCanvasCtx,
                            0,
                            0,
                            gridColor,
                            this.grid.noteBorderRadius,
                            width,
                            this.pxPerYStep - this.grid.pxPerPitchGutter
                        )
                    }

                    if (gridColor === normalColor) {
                        context.drawImage(normalRectCachedCanvas, x, y)
                    }

                    return true
                })

                return true
            },

            this.skipTimesteps,
            this.skipBeatsteps,
            this.skipHalfBeatsteps
        )
    }

    private renderGridCanvas(result: {
        shouldRenderGrid
        shouldPreRenderGrid
    }) {
        if (this.queries.scoreRendering.getValue().harmonyLock) {
            this.renderGridCanvasWithHarmonyLock()
        } else {
            if (
                result.shouldPreRenderGrid ||
                this.queries.scoreRendering.getValue().forcePrerender
            ) {
                this.preRenderedGrid = this.preRenderGrid()
            }

            if (
                result.shouldRenderGrid ||
                this.queries.scoreRendering.getValue().forcePrerender
            ) {
                this.generateGrid()
            }
        }
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        if (
            !scoreState.score ||
            !scoreState.toggledLayer ||
            !this.shouldRenderCanvas(scoreState.renderingType)
        ) {
            return
        }

        if (
            this.queries.scoreRendering.toggledLayer instanceof PercussionLayer
        ) {
            throw "Accompaniment Designer currently doesn't support Percussion Layers"
        }

        this.layer = this.queries.scoreRendering.toggledLayer

        const result = this.initRender({
            scoreState,
            editorViewState,
            grid: grid,
            noteRes: grid[this.gridType].timestepRes,
            nbOfYValues: this.getPitchCount(grid),
        })

        const score = this.queries.scoreRendering.score

        if (featureFlags.useFractionClass) {
            const start = Time2.timestepsToFraction(
                this.noteRes,
                this.scrollToTimestep
            )
            const end = Time2.timestepsToFraction(
                this.noteRes,
                this.scrollToTimestep + this.getTimestepRange()
            )

            const sections = ScoreCanvas.generateSectionOverlays2({
                ctx: this.getContext("canvas"),
                score: score,
                start: start,
                end: end,
                pxPerTimestep: this.pxPerTimestep,
                grid: this.grid,
                height: this.height,
                scrollToTimestep: this.scrollToTimestep,
                timestepRes: this.noteRes,
            })

            this.renderGridCanvas(result)

            if (
                (scoreState.noteManipulationStarted === "move" ||
                    scoreState.noteManipulationStarted === "resizeSingle") &&
                scoreState.selectedData.type === "Note"
            ) {
                const selectedNotes: NotesObject = scoreState.selectedData.data
                this.generateSelectedNotes2(
                    sections,
                    selectedNotes,
                    this.queries.scoreRendering.toggledLayer
                )
            }

            for (const layer of this.queries.scoreRendering
                .visiblePitchedLayers) {
                if (
                    layer.value ===
                    this.queries.scoreRendering.toggledLayer.value
                ) {
                    continue
                }

                this.generatePitchedNotes2(sections, layer)
            }

            this.generatePitchedNotes2(
                sections,
                this.queries.scoreRendering.toggledLayer
            )

            if (featureFlags.autoPhrasing) {
                this.generatePhrases()
            }
        } else {
            const start = Time.timestepsToFraction(
                this.noteRes,
                this.scrollToTimestep
            )
            const end = Time.timestepsToFraction(
                this.noteRes,
                this.scrollToTimestep + this.getTimestepRange()
            )

            const sections = ScoreCanvas.generateSectionOverlays({
                ctx: this.getContext("canvas"),
                score: score,
                start: start,
                end: end,
                pxPerTimestep: this.pxPerTimestep,
                grid: this.grid,
                height: this.height,
                scrollToTimestep: this.scrollToTimestep,
                timestepRes: this.noteRes,
            })

            this.renderGridCanvas(result)

            if (
                (scoreState.noteManipulationStarted === "move" ||
                    scoreState.noteManipulationStarted === "resizeSingle") &&
                scoreState.selectedData.type === "Note"
            ) {
                const selectedNotes: NotesObject = scoreState.selectedData.data
                this.generateSelectedNotes(
                    sections,
                    selectedNotes,
                    this.queries.scoreRendering.toggledLayer
                )
            }

            for (const layer of this.queries.scoreRendering
                .visiblePitchedLayers) {
                if (
                    layer.value ===
                    this.queries.scoreRendering.toggledLayer.value
                ) {
                    continue
                }

                this.generatePitchedNotes(sections, layer)
            }

            this.generatePitchedNotes(
                sections,
                this.queries.scoreRendering.toggledLayer
            )

            if (featureFlags.autoPhrasing) {
                this.generatePhrases()
            }
        }
    }

    private generatePhrases() {
        if (
            !this.queries.scoreRendering.toggledLayer.value.includes("Melody")
        ) {
            return
        }

        const phrases = ScoreManipulation.getPhrasesForNotes(
            this.queries.scoreRendering.toggledLayer.notesObject
        )

        const ctx = this.getContext("canvas")

        ctx.strokeStyle = "#00ffff"
        ctx.lineWidth = 1

        for (const phrase of phrases) {
            const x1 = ScoreCanvas.getPixelsForTimesteps({
                grid: this.grid,
                timesteps:
                    Time.fractionToTimesteps(this.noteRes, phrase.start) -
                    this.scrollToTimestep,
                timestepRes: this.noteRes,
                removeLastGutterPx: "none",
            })

            const x2 = ScoreCanvas.getPixelsForTimesteps({
                grid: this.grid,
                timesteps:
                    Time.fractionToTimesteps(this.noteRes, phrase.end) -
                    this.scrollToTimestep,
                timestepRes: this.noteRes,
                removeLastGutterPx: "none",
            })

            ctx.beginPath()
            ctx.moveTo(x1, 20)
            ctx.quadraticCurveTo(x1 + (x2 - x1) / 2, 50, x2, 20)
            ctx.stroke()
        }
    }

    private generateSelectedNotes(
        sections: Section[],
        selectedNotes: NotesObject,
        layer: Layer
    ) {
        const { start, end } = this.getRenderRange()
        selectedNotes.manipulateNoteGroups(
            (noteGroup: ImmutableNote[]) => {
                if (
                    ScoreManipulation.fractionIsInSections(
                        noteGroup[0].start,
                        sections
                    )
                ) {
                    return true
                }
                return this.renderNoteGroup(noteGroup, layer, "selected")
            },
            [start, end]
        )
    }

    private generateSelectedNotes2(
        sections: Section[],
        selectedNotes: NotesObject,
        layer: Layer
    ) {
        const { start, end } = this.getRenderRange2()
        selectedNotes.manipulateNoteGroups2(
            (noteGroup: ImmutableNote[]) => {
                if (
                    ScoreManipulation.fractionIsInSections(
                        noteGroup[0].start,
                        sections
                    )
                ) {
                    return true
                }
                return this.renderNoteGroup(noteGroup, layer, "selected")
            },
            [start, end]
        )
    }

    private generatePitchedNotes(sections: Section[], layer: Layer) {
        const { start, end } = this.getRenderRange()

        layer.notesObject.manipulateNoteGroups(
            (noteGroup: ImmutableNote[]) => {
                if (
                    ScoreManipulation.fractionIsInSections(
                        noteGroup[0].start,
                        sections
                    )
                ) {
                    return true
                }

                return this.renderNoteGroup(noteGroup, layer)
            },
            [start, end]
        )
    }

    private generatePitchedNotes2(sections: Section[], layer: Layer) {
        const { start, end } = this.getRenderRange2()

        layer.notesObject.manipulateNoteGroups2(
            (noteGroup: ImmutableNote[]) => {
                if (
                    ScoreManipulation.fractionIsInSections(
                        noteGroup[0].start,
                        sections
                    )
                ) {
                    return true
                }

                return this.renderNoteGroup(noteGroup, layer)
            },
            [start, end]
        )
    }

    private getRenderRange(): { start: string; end: string } {
        const start = Time.timestepToFraction(
            this.scrollToTimestep,
            this.noteRes
        )
        const end = Time.timestepToFraction(
            this.scrollToTimestep + this.getTimestepRange(),
            this.noteRes
        )
        return { start, end }
    }

    private getRenderRange2(): { start: Fraction; end: Fraction } {
        const start = Time2.timestepToFraction(
            this.scrollToTimestep,
            this.noteRes
        )
        const end = Time2.timestepToFraction(
            this.scrollToTimestep + this.getTimestepRange(),
            this.noteRes
        )
        return { start, end }
    }

    private renderNoteGroup(
        noteGroup: ImmutableNote[],
        layer: Layer,
        type?: string
    ): boolean {
        const x =
            ScoreCanvas.getPixelsForTimesteps({
                timesteps: Time.fractionToTimesteps(
                    this.noteRes,
                    noteGroup[0].start
                ),
                timestepRes: this.noteRes,
                grid: this.grid,
                removeLastGutterPx: "none",
            }) - this.scrollXOffset

        const endTimesteps = Time.fractionToTimesteps(
            this.noteRes,
            Time.addTwoFractions(noteGroup[0].start, noteGroup[0].duration)
        )

        const endPx =
            ScoreCanvas.getPixelsForTimesteps({
                timesteps: endTimesteps,
                timestepRes: this.noteRes,
                grid: this.grid,
                removeLastGutterPx: "fully",
            }) - this.scrollXOffset

        for (let note of noteGroup) {
            if (this.grid.pitchStepDomain === "continuous") {
                this.renderNoteForContinousMode(note, x, endPx - x, layer, type)
            } else {
                this.renderNoteForScaleMode(note, x, endPx - x, layer, type)
            }
        }

        return true
    }

    /**
     * This method can be used to check if a given pitch is within the scroll range boundaries
     * @param pitch
     */
    private pitchInRange(pitch: number): boolean {
        return (
            pitch >= this.scrollToPitchsteps - 1 &&
            pitch <= this.scrollToPitchsteps + this.nbOfYValues + 1
        )
    }

    private renderNoteForContinousMode(
        note: ImmutableNote,
        x,
        width,
        layer: Layer,
        type: string
    ) {
        const ctx = this.getContext("canvas")
        const pitchRelativeToHighest = Note.highestNote - note.pitch

        if (!this.pitchInRange(pitchRelativeToHighest)) {
            return
        }

        const y =
            this.pxPerYStep * (pitchRelativeToHighest - this.scrollToPitchsteps)

        const color = type
            ? this.getNoteColor(layer, type as any)
            : this.getNoteColor(
                  layer,
                  this.getNoteType(
                      layer,
                      note,
                      this.queries.scoreRendering?.all?.allowPolyphony
                  )
              )

        const noteHeight = this.pxPerYStep - this.grid.pxPerPitchGutter

        ScoreCanvas.generateNoteRect(
            ctx,
            x,
            y,
            color,
            this.grid.noteBorderRadius,
            width,
            noteHeight
        )

        if (this.getNoteType(layer, note) !== "secondary") {
            this.drawNoteHandles(width, x, y, noteHeight)
        }
    }

    private drawNoteHandles(
        noteWidth: number,
        x: number,
        y: number,
        noteHeight: number
    ) {
        const ctx = this.getContext("canvas")

        // 5 is the number of left px offset we allow for the handle indicator to be placed at
        const handleLeftOffset = 3
        if (noteWidth / 2 > handleLeftOffset * 2) {
            const handleHeight = (noteHeight * 2) / 3 // the handle height is 2/3 of the note height
            const handleY = y + (noteHeight - handleHeight) / 2

            const handleColor = "#0B033D"

            ScoreCanvas.generateNoteRect(
                ctx,
                x + noteWidth - handleLeftOffset,
                handleY,
                handleColor,
                0,
                1,
                handleHeight
            )

            ScoreCanvas.generateNoteRect(
                ctx,
                x + handleLeftOffset - 1,
                handleY,
                handleColor,
                0,
                1,
                handleHeight
            )
        }
    }

    private getNoteType(
        currentLayer: Layer,
        note: ImmutableNote,
        allowPolyphony: boolean = true
    ) {
        const overlappingNotes = this.queries.scoreRendering.overlappingNotes
        const selectedNotes = this.queries.scoreRendering.selectedNotes

        if (
            overlappingNotes !== undefined &&
            overlappingNotes.getNoteByID(note.start, note.noteID) &&
            selectedNotes.getNoteByStartAndPitch(note.start, note.pitch) ===
                undefined
        ) {
            return "overlap"
        } else if (
            selectedNotes.getNoteByID(note.start, note.noteID) ||
            selectedNotes.getNoteByStartAndPitch(note.start, note.pitch) !==
                undefined
        ) {
            return "selected"
        } else if (currentLayer.value !== this.layer.value) {
            return "secondary"
        } else if (!allowPolyphony) {
            const noteGroup =
                currentLayer.notesObject.getNoteGroup(note.start) || []
            const lowestPitch = Math.min(...noteGroup.map(n => n.pitch))

            if (note.pitch !== lowestPitch) {
                return "overlap"
            }
        }

        return "normal"
    }

    private renderNoteForScaleMode(
        note: ImmutableNote,
        x,
        width,
        layer: Layer,
        type: string
    ) {
        const ks: string =
            this.queries.scoreRendering.score?.keySignatures[0][1]
        const ctx = this.getContext("canvas")

        const color = type
            ? this.getNoteColor(layer, type as any)
            : this.getNoteColor(
                  layer,
                  this.getNoteType(
                      layer,
                      note,
                      this.queries.scoreRendering?.all?.allowPolyphony
                  )
              )

        AccompanimentDesignerCanvas.traverseGridScale(
            this.nbOfYValues,
            ks,
            this.pxPerYStep,
            (y, pitch) => {
                if (pitch !== note.pitch) {
                    return
                }

                const noteHeight = this.pxPerYStep - this.grid.pxPerPitchGutter

                ScoreCanvas.generateNoteRect(
                    ctx,
                    x,
                    y,
                    color,
                    this.grid.noteBorderRadius,
                    width,
                    noteHeight
                )

                if (
                    this.getNoteType(
                        layer,
                        note,
                        this.queries.scoreRendering?.all?.allowPolyphony
                    ) !== "secondary"
                ) {
                    this.drawNoteHandles(width, x, y, noteHeight)
                }
            }
        )
    }

    protected getGridCoordinates(
        event: Coordinates,
        invertYAxis: boolean
    ): GridCoordinates {
        const timesteps = this.getTimestepsForPixels({
            pixels: event.x,
        })
        const ysteps = this.getPitchesForPixels(event.y)

        if (ysteps === undefined) {
            throw "AccompanimentDesignerCanvas.getGridCoordinates: ysteps is undefined"
        }

        if (timesteps === undefined) {
            throw "AccompanimentDesignerCanvas.getGridCoordinates: timesteps is undefined"
        }

        return {
            timesteps: timesteps,
            ysteps: (invertYAxis ? -1 : 1) * ysteps,
        }
    }

    protected getTimestepsForPixels(args: {
        pixels: number
    }): number | undefined {
        const result = this.getStepsForPixels({
            coordinates: {
                x: args.pixels,
                y: 0,
                shiftKey: false,
            },
        })

        return result.timesteps
    }

    private getPitchesForPixels(
        px: number,
        includeGutter: boolean = false
    ): number | undefined {
        if (!this.layer) {
            return undefined
        }

        px = Math.max(0, px)
        px = Math.min(px, this.height)

        const gutter = this.grid.pxPerPitchGutter

        let lastViewedPos: number | undefined = undefined
        let result = 0

        let i = -1

        let keySignature: KeySignature | undefined = undefined
        let scalePitches = []

        // Set up the scale pitches only when dealing with the scale pitch domain.
        // Otherwise we may run into issues with compositions that miss a key signature (e.g. midi imports).
        if (
            this.grid.pitchStepDomain === "scale" &&
            this.queries.scoreRendering.score?.keySignatures?.length &&
            this.queries.scoreRendering.score?.keySignatures[0][1]
        ) {
            keySignature = Score.getKeySignatureObject(
                this.queries.scoreRendering.score?.keySignatures[0][1]
            )
            scalePitches = KeySignatureModule.getTriadScalePitches(keySignature)
        }

        this.traversePitchGrid(
            (y, absolutePitch, relativePitch, pitchType, nextY) => {
                i++

                const currentPos = !includeGutter ? y - gutter : y
                const nextPos =
                    includeGutter && i + 1 < this.nbOfYValues
                        ? nextY - gutter
                        : nextY

                const closestPosition =
                    lastViewedPos === undefined ||
                    Math.abs(px - lastViewedPos) > Math.abs(px - currentPos)

                const exactPosition = px >= currentPos && px <= nextPos

                if (closestPosition || exactPosition) {
                    lastViewedPos = currentPos
                    result = i

                    if (this.grid.pitchStepDomain === "continuous") {
                        result = absolutePitch
                    } else if (this.grid.pitchStepDomain === "scale") {
                        const index = scalePitches.length - i - 1
                        result = scalePitches[index]
                    }
                }

                if (exactPosition) {
                    return false
                }

                return true
            }
        )

        if (this.grid.pitchStepDomain === "scale" && result === undefined) {
            result = scalePitches[0]
        }

        return result
    }

    private generateGrid() {
        if (this.queries.scoreRendering.score === undefined) {
            return
        }

        const ctx: CanvasRenderingContext2D = this.getContext(
            "canvas-grid"
        ) as CanvasRenderingContext2D
        ctx.globalCompositeOperation = "copy"

        const x = this.getGridOffsetInPixels({
            timesteps: this.scrollToTimestep,
            timestepRes: this.noteRes,
        })

        if (!this.preRenderedGrid?.width || !this.preRenderedGrid?.height) {
            return
        }

        ctx.drawImage(this.preRenderedGrid, -x, 0)
    }

    getGridOffsetInPixels(args: {
        timesteps: number
        timestepRes: number
    }): number {
        if (!this.grid) {
            return 0
        }

        const tsRes =
            (this.timeSignature[0] / this.timeSignature[1]) * args.timestepRes

        const absoluteOffset = ScoreCanvas.getPixelsForTimesteps({
            grid: this.grid,
            timesteps: Math.floor(args.timesteps / tsRes) * tsRes,
            timestepRes: args.timestepRes,
            removeLastGutterPx: "none",
        })

        return this.scrollXOffset - absoluteOffset
    }

    private getGridRenderingBoundaries() {
        const boundaries = {
            start: 0,
            end:
                Math.min(
                    this.grid.barsInPattern,
                    Math.ceil(
                        Time.timestepsToBar(
                            this.getTimestepRange(),
                            this.timeSignature,
                            this.noteRes
                        )
                    )
                ) + 1,
            offsetX: 0,
        }

        return boundaries
    }

    private createPreRenderedCanvasGrid() {
        const preRenderedCanvasGrid = document.createElement(
            "canvas"
        ) as HTMLCanvasElement

        preRenderedCanvasGrid.getContext("2d").globalCompositeOperation = "copy"

        const boundaries = this.getGridRenderingBoundaries()

        preRenderedCanvasGrid.width = ScoreCanvas.getPixelsForTimesteps({
            grid: this.grid,
            timesteps: Time.barToTimestep(
                boundaries.end,
                this.timeSignature,
                this.noteRes
            ),
            timestepRes: this.noteRes,
            removeLastGutterPx: "none",
        })

        preRenderedCanvasGrid.height = this.height

        return {
            canvas: preRenderedCanvasGrid,
            boundaries,
        }
    }

    private preRenderGrid() {
        const { canvas, boundaries } = this.createPreRenderedCanvasGrid()

        const ctx = canvas.getContext("2d")

        this.traverseTimeGrid(
            (
                x: number,
                timesteps: number,
                currentGutterSize,
                previousGutterSize,
                width
            ) => {
                if (timesteps < 0) {
                    return true
                }

                this.traversePitchGrid((y: number, absolutePitch: number) => {
                    const gridColor = ScoreManipulation.getGridColor(
                        false,
                        [],
                        absolutePitch,
                        this.grid.pitchStepDomain
                    )

                    ScoreCanvas.generateNoteRect(
                        ctx,
                        x,
                        y,
                        gridColor,
                        this.grid.noteBorderRadius,
                        width,
                        this.pxPerYStep - this.grid.pxPerPitchGutter
                    )

                    ctx.globalCompositeOperation = "source-over"

                    return true
                })

                return true
            },

            this.skipTimesteps,
            this.skipBeatsteps,
            this.skipHalfBeatsteps,
            boundaries
        )

        return canvas
    }

    protected shouldSelect(event: Coordinates) {
        if (!event || this.mouseDownStart === undefined) {
            return false
        }

        const coordinates = this.mouseDownStart.last.pixels

        return (
            Math.abs(coordinates.x - event.x) >= MOUSE_MOVE_THRESHOLD_X &&
            Math.abs(coordinates.y - event.y) >= MOUSE_MOVE_THRESHOLD_Y
        )
    }

    protected selectNotes(event: Coordinates) {
        const scoreState = this.queries["scoreRendering"].getValue()
        if (
            this.mouseDownStart === undefined ||
            !scoreState?.score ||
            !scoreState.toggledLayer ||
            this.cursorType === "pencil"
        ) {
            return
        }

        if (
            this.queries.scoreRendering.toggledLayer instanceof PercussionLayer
        ) {
            throw "Accompaniment Designer currently doesn't support Percussion Layers"
        }

        try {
            this.mouseDownStart.last = {
                pixels: event,
                grid: this.getGridCoordinates(event, false),
                scrollToTimestep: this.scrollToTimestep,
            }

            this.actions.editorView.setNoteSelection(
                this.getNoteSelection(event)
            )

            const timesteps = {
                start: this.mouseDownStart.start.grid.timesteps,
                end: this.mouseDownStart.last.grid.timesteps,
            }

            const pitchsteps = {
                start: this.mouseDownStart.start.grid.ysteps,
                end: this.mouseDownStart.last.grid.ysteps,
            }

            const timestepRange: [number, number] = [
                Math.min(timesteps.start, timesteps.end),
                Math.max(timesteps.start, timesteps.end),
            ]

            const pitchRange: [number, number] = [
                Math.min(pitchsteps.start, pitchsteps.end),
                Math.max(pitchsteps.start, pitchsteps.end),
            ]

            this.sendSelectNotesAction(event, timestepRange, pitchRange)
        } catch (e) {
            console.log(e)
        }
    }

    protected sendSelectNotesAction(
        event: Coordinates,
        timestepRange: [number, number],
        pitchRange: [number, number]
    ) {
        const scoreState = this.queries["scoreRendering"].getValue()

        if (!scoreState?.score) {
            return
        }

        const score: Score = scoreState.score

        const prevSelection =
            event.shiftKey && this.queries.scoreRendering.selectedNotes
        this.srEmitter$.next({
            type: SRActionTypes.selectNotes,
            data: {
                score,
                layer: this.layer,
                timestepRange,
                pitchRange,
                timestepResolution: this.noteRes,
                timeSignature: this.grid.timeSignature,
                prevSelection: prevSelection,
            },
        })
    }

    protected getNoteSelection(
        coordinates: Coordinates
    ): NoteSelection | undefined {
        if (this.mouseDownStart === undefined) {
            return undefined
        }

        const rect = this.context.canvasContainer.getBoundingClientRect()
        const startCoordinates = this.mouseDownStart.start.pixels
        const top = Math.min(startCoordinates.y, coordinates.y) + rect.y
        const bottom = Math.max(startCoordinates.y, coordinates.y) + rect.y
        const left = Math.min(startCoordinates.x, coordinates.x) + rect.x
        const right = Math.max(startCoordinates.x, coordinates.x) + rect.x

        const width = right - left
        const height = bottom - top

        const noteSelection: NoteSelection = {
            playingPitches: [],
            dimensions: {
                top,
                left,
                width,
                height,
            },
            type: this.type,
        }

        return noteSelection
    }

    protected getNoteDescriptor(
        coordinates: Coordinates,
        note: Note
    ): NoteDescriptor | undefined {
        if (!coordinates || !note) {
            return undefined
        }

        const rect = this.context.canvasContainer.getBoundingClientRect()
        const top = coordinates.y + rect.y
        const left = coordinates.x + rect.x
        const forceFlat = this.queries?.scoreRendering?.score?.keySignatures
            ?.length
            ? this.queries?.scoreRendering?.score?.keySignatures[0][1].charAt(
                  1
              ) === "b"
            : false

        const noteDescriptor: NoteDescriptor = {
            pitch: {
                pitch: note?.pitch,
                name: Note.getNoteString(note?.pitch, forceFlat),
            },
            coordinates: {
                x: left,
                y: top,
            },
            duration: note?.duration,
            section: note?.meta?.section,
            type: this.type,
        }

        if (noteDescriptor) {
            this.actions.editorView.setNoteDescriptor(noteDescriptor)
        }

        return noteDescriptor
    }

    private detectHoveredNote(event: Coordinates): HoveredElement | undefined {
        if (this.mouseDownStart !== undefined) {
            return this.hoveredElement
        }

        try {
            const cursorStyle =
                this.queries.editorView.cursorType === "pencil"
                    ? "pencil"
                    : "default"

            this.setCanvasCursor(cursorStyle)

            const result = this.getHoveringType(
                event,
                this.getGridCoordinates(event, false),
                false
            )

            if (!result) {
                return undefined
            }

            if (result.type !== HoveringTypeEnum.CENTER) {
                this.setCanvasCursor("col-resize")
            }

            const hoveredNote = {
                element: result.element,
                draggingType: result.type,
                type: "note",
            } as HoveredElement

            return hoveredNote
        } catch (e) {
            console.log(e)

            return undefined
        }
    }

    protected getHoveringType(
        coordinates: Coordinates,
        grid: GridCoordinates,
        setSelectedNote: boolean
    ) {
        const scrollToTimestep = Time.convertTimestepsToAnotherRes(
            this.scrollToTimestep,
            this.noteRes,
            TIMESTEP_RES
        )
        const scrollRange =
            Time.convertTimestepsToAnotherRes(
                this.getTimestepRange(),
                this.noteRes,
                TIMESTEP_RES
            ) + scrollToTimestep

        const note: Note | undefined =
            this.layer.notesObject.getNoteAtCoordinates(
                Time.convertTimestepsToAnotherRes(
                    grid.timesteps,
                    this.noteRes,
                    TIMESTEP_RES
                ),
                grid.ysteps,
                [scrollToTimestep, scrollRange]
            )

        if (note === undefined) {
            return undefined
        }

        const noteStartTimesteps = Time.fractionToTimesteps(
            this.noteRes,
            note.start
        )
        const noteDurationTimesteps = Time.fractionToTimesteps(
            this.noteRes,
            note.duration
        )

        const noteStart = ScoreCanvas.getPixelsForTimesteps({
            timesteps: noteStartTimesteps,
            removeLastGutterPx: "none",
            timestepRes: this.noteRes,
            grid: this.grid,
        })

        const noteWidth = ScoreCanvas.getPixelsForTimesteps({
            timesteps: noteDurationTimesteps,
            removeLastGutterPx: "fully",
            timestepRes: this.noteRes,
            grid: this.grid,
        })

        const mouseX = coordinates.x + this.scrollXOffset
        const noteEnd = noteStart + noteWidth
        const noteEdge = Math.max(1, Math.min(4, noteWidth / 4))

        const leftEdgeEnd = noteStart + noteEdge
        const rightEdgeEnd = noteEnd - noteEdge

        let type: HoveringType = HoveringTypeEnum.CENTER

        if (mouseX <= leftEdgeEnd) {
            type = HoveringTypeEnum.LEFT
        } else if (mouseX >= rightEdgeEnd) {
            type = HoveringTypeEnum.RIGHT
        }

        if (setSelectedNote) {
            this.setSelectedNote({
                note,
                coordinates,
                type,
            })
        }

        return {
            type: type,
            element: note,
        }
    }

    private setSelectedNote({
        note,
        coordinates,
        type,
    }: {
        note: Note
        coordinates: Coordinates
        type: HoveringType
    }) {
        let setNoteAsSelected = true

        const targetedNoteIsSelected =
            this.queries.scoreRendering.isSelectedNote(note)

        if (type !== "center" && !targetedNoteIsSelected) {
            this.selectNotes(coordinates)
        }

        if (type === "center" && targetedNoteIsSelected) {
            setNoteAsSelected = false
        }

        this.selectedNote = note

        if (setNoteAsSelected) {
            let selectedNotes = [note]

            if (type !== HoveringTypeEnum.CENTER) {
                if (!targetedNoteIsSelected) {
                    selectedNotes =
                        this.queries.scoreRendering.toggledLayer.notesObject.getNoteGroup(
                            note.start
                        )

                    if (coordinates.shiftKey) {
                        selectedNotes = selectedNotes.concat(
                            this.queries.scoreRendering.selectedNotes.getFlatArray()
                        )
                    }
                } else {
                    selectedNotes = []

                    this.queries.scoreRendering.selectedNotes.manipulateNoteGroups(
                        noteGroup => {
                            selectedNotes = selectedNotes.concat(
                                this.queries.scoreRendering.toggledLayer.notesObject.getNoteGroup(
                                    noteGroup[0].start
                                )
                            )
                            return true
                        }
                    )

                    if (coordinates.shiftKey) {
                    }
                }
            } else if (
                type === HoveringTypeEnum.CENTER &&
                coordinates.shiftKey
            ) {
                selectedNotes =
                    this.queries.scoreRendering.selectedNotes.getFlatArray()

                selectedNotes.push(note)
            } else {
                selectedNotes = [note]
            }

            this.actions.scoreRendering.manager.emitter$.next({
                type: SRActionTypes.setSelectedNotes,
                data: {
                    notes: selectedNotes,
                },
            })
        }
    }

    protected getHoveringNotegroup(grid: GridCoordinates) {
        const scrollToTimestep = Time.convertTimestepsToAnotherRes(
            this.scrollToTimestep,
            this.noteRes,
            TIMESTEP_RES
        )
        const scrollRange =
            Time.convertTimestepsToAnotherRes(
                this.getTimestepRange(),
                this.noteRes,
                TIMESTEP_RES
            ) + scrollToTimestep

        const noteGroup: Note[] | undefined =
            this.layer.notesObject.getNoteGroupAtCoordinates(
                Time.convertTimestepsToAnotherRes(
                    grid.timesteps,
                    this.noteRes,
                    TIMESTEP_RES
                ),
                [scrollToTimestep, scrollRange]
            ) as Note[]

        return noteGroup
    }

    static traverseGridScale(
        pitchsteps: number,
        key: string,
        pxPerYStep: number,
        higherOrderFunc
    ) {
        const pitchClass = key.split(" ")[0]
        const keyMode = key.split(" ")[1] as CWKeyMode

        const keySignature: KeySignature = {
            pitchClass: pitchClass,
            keyMode: keyMode,
        }

        const isMinor = keyMode.toLowerCase().includes("min")
        const scale =
            KeySignatureModule.getTriadScaleByKeySignature(keySignature)

        const scaleIntervals = isMinor
            ? Note.MINOR_SCALE_INTERVAL
            : Note.MAJOR_SCALE_INTERVAL

        const scaleForPitchsteps: string[] = []

        for (let y = 0; y < pitchsteps; y++) {
            const noteType = scale[y % 3]
            scaleForPitchsteps.push(noteType)
        }

        let pitchOffset =
            Note.notesInOctaveForIndexing.indexOf(scale[0]) +
            SCALE_START_OCTAVE_WITH_INDEXING * 12

        for (let y = 0; y < pitchsteps; y++) {
            if (y % 3 === 0) {
                if (y !== 0) {
                    pitchOffset += 12 - scaleIntervals[2]
                }
            } else {
                pitchOffset +=
                    scaleIntervals[y % 3] - scaleIntervals[(y - 1) % 3]
            }

            const currentAbsolutePitch = pitchOffset
            const pixelsY = pxPerYStep * (pitchsteps - y - 1)

            higherOrderFunc(pixelsY, currentAbsolutePitch)
        }
    }
}
