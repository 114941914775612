import { Observable, Subject, tap } from "rxjs"
import { Store, StoreConfig } from "@datorama/akita"
import { HistoryService } from "./history.service"
import { featureFlags } from "../utils/feature-flags"
import { cloneDeep } from "lodash"

export type EmitterType<T> = {
    type: T
    data?: any
    options?: {
        isUndoable?: boolean
    }
    resolve?: Function
}

export class ActionsManager<T, U, V> {
    private historyService: HistoryService<U> = new HistoryService()
    public emitter$ = new Subject<EmitterType<T>>()
    public illegalAction$ = new Subject<"undo" | "redo">()
    private getData: Function
    private stateUpdate: Function

    constructor(
        private readonly store: Store<V>,
        private readonly actionTypeToMethodMap: {
            [key: string]: (...args) => any
        },
        args: {
            pipePrefix?: (subject: Subject<any>) => Observable<any>
            templateBeforeActionEffect?: Function
            getData?: Function
            stateUpdate?: Function
        }
    ) {
        let subject: Subject<any> | Observable<any> = this.emitter$

        if (args.pipePrefix) {
            subject = args.pipePrefix(this.emitter$)
        }

        if (args.getData === undefined) {
            args.getData = (() => {
                return this.store.getValue()
            }).bind(this)
        }

        if (args.stateUpdate === undefined) {
            args.stateUpdate = (state => {
                this.store.update(state)
            }).bind(this)
        }

        this.getData = args.getData
        this.stateUpdate = args.stateUpdate

        subject
            .pipe(
                tap((action: EmitterType<T>) => {
                    if (args.templateBeforeActionEffect) {
                        const newAction =
                            args.templateBeforeActionEffect(action)

                        if (!newAction) {
                            this.beforeActionEffect(action)
                        }
                    } else {
                        this.beforeActionEffect(action)
                    }
                    // console.log("beforeActionEffect", action, templateBeforeActionEffect !== undefined)
                }),
                tap(async ({ type, data, options }) => {
                    if (this.actionTypeToMethodMap[type + ""] === undefined) {
                        throw "Action type function is undefined. Please check that you provide a function definition in actionTypeToMethodMap."
                    }

                    // console.log("call action", type)
                    await this.actionTypeToMethodMap[type + ""].call(this, data)
                }),
                tap((action: EmitterType<T>) => this.afterActionEffect(action))
            )
            .subscribe()
    }

    public getUndoQueueLength(): number {
        return this.historyService.getUndoQueueLength()
    }

    public clearHistory(): void {
        this.historyService.reset()
    }

    public beforeActionEffect(action: EmitterType<T>) {
        if (featureFlags.debugActions) {
            console.log(
                action.type,
                cloneDeep(action.data),
                cloneDeep(action.options)
            )
        }

        if (!action?.options?.isUndoable) return

        if (featureFlags.debugUndoRedo) {
            console.log("Added to queue", this.getData(), action.type)
        }

        this.historyService.addToQueue(this.getData())

        return action
    }

    public afterActionEffect(action: EmitterType<T>) {
        if (action.resolve) {
            action.resolve()
        }
    }

    public getStateToUndoTo(): U | undefined {
        return this.historyService.peek("undo")
    }

    public getStateToRedoTo(): U | undefined {
        return this.historyService.peek("redo")
    }

    public undo() {
        const state = this.historyService.undo(this.getData())

        if (!state) {
            this.illegalAction$.next("undo")
            return false
        }

        this.stateUpdate(state)

        return true
    }

    public redo() {
        const state = this.historyService.redo(this.getData())

        if (!state) {
            this.illegalAction$.next("redo")
            return false
        }

        this.stateUpdate(state)

        return true
    }

    public reset() {
        this.historyService.reset()
    }
}
