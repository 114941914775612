import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core"
import { SuccessModalParams } from "@services/modal.service"

@Component({
    selector: "success-modal",
    templateUrl: "success-modal.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessModalCopmonent {
    @Output() close: EventEmitter<boolean> = new EventEmitter()
    @Input() params: SuccessModalParams

    public get title() {
        return this.params.title
    }

    public get subtitle() {
        return this.params.subtitle
    }

    public get note() {
        return this.params.note
    }

    closeModal() {
        this.close.emit(true)
    }
}
