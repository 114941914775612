<span *ngIf="(editor.engine.toggledLayer$ | async) !== undefined" id="trackbus">
    <div
        class="trackbusses-pitched-outer-container"
        *ngIf="(editor.engine.toggledLayer$ | async)?.type === 'pitched'">
        <div
            class="trackbus-container pitched-trackbus-container"
            *ngIf="
                (editor.engine.toggledLayer$ | async).trackBuses.length === 0
            ">
            <div></div>
            <div>
                <span class="no-instrument-assigned"
                    >This layer has no instrument assigned.</span
                >
                <a-button
                    type="secondary"
                    label="Add instrument"
                    class="editor-button"
                    icon="assets/img/add.svg"
                    (click)="
                        editor.engine.addTrackBus(instruments.instruments)
                    "></a-button>
            </div>
        </div>

        <div
            class="trackbus-container pitched-trackbus-container"
            *ngFor="
                let trackBus of (editor.engine.toggledLayer$ | async)
                    .trackBuses;
                let i = index
            "
            (mousedown)="selectTrackBus($event, trackBus)"
            [attr.av-data]="'trackbus' + i">
            <trackbus-panel
                [engine]="editor.engine"
                [trackBus]="trackBus"
                [layer]="editor.engine.toggledLayer$ | async"></trackbus-panel>

            <div
                class="right-label"
                id="trackbus-regions-{{
                    (editor.engine.toggledLayer$ | async).value
                }}-{{ trackBus.id }}"></div>
        </div>
    </div>

    <div
        class="trackbusses-outer-container"
        *ngIf="(editor.engine.toggledLayer$ | async)?.type === 'percussion'">
        <div class="trackbus-container">
            <div
                class="trackbus-container pitched-trackbus-container"
                *ngIf="
                    (editor.engine.toggledLayer$ | async).trackBuses.length ===
                    0
                ">
                <div></div>
            </div>

            <div
                *ngFor="
                    let trackBus of (editor.engine.toggledLayer$ | async)
                        .trackBuses;
                    let i = index
                "
                [attr.av-data]="'trackbus' + i"
                (mousedown)="selectTrackBus($event, trackBus)">
                <trackbus-panel
                    [engine]="editor.engine"
                    [trackBus]="trackBus"
                    [layer]="
                        editor.engine.toggledLayer$ | async
                    "></trackbus-panel>
            </div>
        </div>

        <div
            id="pattern-regions"
            *ngIf="
                (editor.engine.toggledLayer$ | async)?.patternRegions.length > 0
            "></div>

        <div
            *ngIf="
                (editor.engine.toggledLayer$ | async).trackBuses.length === 0
            ">
            <span class="no-instrument-assigned"
                >This layer has no instrument assigned.</span
            >
            <a-button
                type="secondary"
                label="Add instrument"
                class="editor-button"
                icon="assets/img/add.svg"
                (click)="
                    editor.engine.addTrackBus(instruments.instruments)
                "></a-button>
        </div>
    </div>
</span>
