import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from "@angular/core"

import { featureFlags } from "../../../../../../common-lib/general/utils/feature-flags"

@Component({
    selector: "magic-prompt",
    templateUrl: "magic-prompt.component.html",
    styleUrls: ["magic-prompt.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MagicPromptComponent {
    @Input() title: string
    @Output() public onClick: EventEmitter<string> = new EventEmitter<string>()
    @Input() element: HTMLElement
    @Output() public onScroll$ = new EventEmitter<WheelEvent>()

    public prompt: string = ""

    constructor() {}

    ngOnInit() {}

    get featureFlags() {
        return featureFlags
    }

    @HostListener("mousewheel", ["$event"])
    public onScroll($event: WheelEvent): void {
        this.onScroll$.emit($event)
    }

    public clickOnButton() {
        this.onClick.emit(this.prompt)
    }
}
