import Score from "../../../../general/classes/score/score"
import { Query } from "@datorama/akita"
import { ScoreRendering, ScoreRenderingStore } from "./score-rendering.store"
import { ImmutableNote } from "../../../../general/classes/score/note"
import { NotesObject } from "../../../../general/classes/score/notesObject"
import { Pattern } from "../../../../general/classes/score/pattern"
import PatternRegion from "../../../../general/classes/score/patternregion"
import { Effect } from "../../../../general/classes/score/effect"
import Layer from "../../../../general/classes/score/layer"
import PercussionLayer from "../../../../general/classes/score/percussionlayer"
import { BehaviorSubject, Subject, map } from "rxjs"
import TrackBus from "../../../../general/classes/score/trackbus"
import { HoveringTypeEnum } from "../../../../general/types/general"
import Section from "../../../../general/classes/score/section"
import { Coordinates } from "../../../../general/modules/event-handlers"

export class ScoreRenderingQuery extends Query<ScoreRendering> {
    allScoreRenderingStates$ = this.select()
    resizeFactor$ = this.select("resizeFactor")
    timestepRes$ = this.select("userSelectedTimestepRes")
    selectedSection$ = this.select("selectedSection")
    seekTime$ = this.select("seekTime")
    toggledLayer$ = this.select("toggledLayer").pipe(
        map(layer => {
            return this.getValue().score?.layers[layer]
        })
    )
    inspectorHovered$ = new BehaviorSubject<Coordinates>(null)
    metricToAdd$ = this.select("metricToAdd")
    selectedAutomation$ = this.select("selectedAutomation")
    scoreUpdate$ = this.select("scoreUpdate")
    selectedPattern$ = this.select("selectedPattern")
    selectedData$ = this.select("selectedData")
    regenerateLayerWithLLM$ = new BehaviorSubject<Section | null>(null)
    selectedNotes$ = this.select("selectedData").pipe(
        map(data => {
            if (data.type === "Note") {
                return data.data
            }

            return []
        })
    )
    selectedPatternRegion$ = this.select("selectedData").pipe(
        map(data => {
            if (data.type === "PatternRegion") {
                return data.data
            }

            if (
                data.type === "TrackBus" &&
                this.toggledLayer.type === "percussion"
            ) {
                return (<PercussionLayer>this.toggledLayer).patternRegions
            }

            return []
        })
    )
    renderingType$ = this.select("renderingType")
    scoreWasEdited$ = this.select("scoreWasEdited")
    temporaryRomanNumerals$ = this.select("temporaryRomanNumerals")

    constructor(public store: ScoreRenderingStore) {
        super(store)
    }

    get layerPreviewConfigs(): {
        skipAdjustments: boolean
        fillNotesWithLayerColor: boolean
    } {
        return this.getValue().layerPreviewConfigs
    }

    get harmonyLock(): boolean {
        return this.getValue().harmonyLock
    }

    get visiblePitchedLayers(): Layer[] {
        return this.getValue().visiblePitchedLayers
    }

    get isInitialPRManipulation(): boolean {
        return this.getValue().isInitialPRManipulation
    }

    get score(): Score | undefined {
        return this.getValue().score
    }

    get editorType(): "editor" | "composition-workflow" {
        return this.getValue().editorType
    }

    get timestepRes(): number {
        return this.getValue().userSelectedTimestepRes
    }

    get all(): ScoreRendering {
        return this.getValue()
    }

    get scrollToTimestep(): number {
        return this.getValue().scrollToTimestep
    }

    get selectedAutomation(): Effect | undefined {
        return this.getValue().selectedAutomation
    }

    get maxBarLength(): number {
        return this.getValue().maxBarLength
    }

    get patternScrollToTimestep(): number {
        return this.getValue().patternScrollToTimestep
    }

    get selectedPattern(): Pattern | undefined {
        return this.getValue().selectedPattern
    }

    get selectedPatternRegions(): PatternRegion[] {
        const selectedData = this.getValue().selectedData

        if (selectedData.type === "PatternRegion") {
            return selectedData.data
        }

        if (
            selectedData.type === "TrackBus" &&
            this.toggledLayer.type === "percussion"
        ) {
            return (<PercussionLayer>this.toggledLayer).patternRegions
        }

        return []
    }

    get scrollToPitchsteps(): number {
        return this.getValue().scrollToPitchsteps
    }

    get resizeFactor(): number {
        return this.getValue().resizeFactor
    }

    get selectedTrackBusRegion() {
        const selectedData = this.getValue().selectedData

        if (selectedData.type === "TrackBusRegion") {
            return selectedData.data
        } else if (selectedData.type === "TrackBus") {
            return selectedData.data
                .map((tb: TrackBus) => {
                    return tb.blocks.map(block => {
                        return {
                            region: block,
                            side: HoveringTypeEnum.CENTER,
                        }
                    })
                })
                .reduce((pre, current) => {
                    return pre.concat(current)
                })
        }

        return []
    }

    public isSelectedNote(note: ImmutableNote): boolean {
        const selectedNotes: NotesObject = this.selectedNotes

        if (!selectedNotes) {
            return false
        }

        const noteGroup: ImmutableNote[] | undefined =
            selectedNotes.getNoteGroup(note.start)

        if (noteGroup) {
            for (const n of noteGroup) {
                if (note.pitch === n.pitch) {
                    return true
                }
            }
        }

        return false
    }

    get selectedTrackBusses() {
        const selectedData = this.getValue().selectedData

        if (selectedData.type === "TrackBus") {
            return selectedData.data
        }

        return []
    }

    get noteManipulationStarted() {
        return this.getValue().noteManipulationStarted
    }

    get selectedData() {
        return this.getValue().selectedData
    }

    get selectedNotes(): NotesObject {
        const selectedData = this.getValue().selectedData

        if (selectedData.type === "Note") {
            return selectedData.data
        }

        return new NotesObject()
    }

    get toggledLayer(): Layer | PercussionLayer | undefined {
        const state = this.getValue()
        const layer = state.toggledLayer

        return state.score?.layers[layer]
    }

    get overlappingNotes(): NotesObject | undefined {
        return this.getValue().overlappingNotes
    }
}
