import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    Input,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
    ComponentFactoryResolver,
    ApplicationRef,
    ViewContainerRef,
    ComponentRef,
} from "@angular/core"
import { MenuModifiers } from "../menu-modifiers/menu-modifiers.component"
import { cloneDeep } from "lodash"
import { PlayerStatus } from "../../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { Misc } from "@common-lib/modules/misc"
import { BehaviorSubject } from "rxjs"
import { ParentClass } from "../../../parent"
import {
    MenuOptions,
    MenuOptionsComponent,
} from "../menu-options/menu-options.component"
import { TutorialService } from "@services/tutorial.service"
export interface ListItem {
    data?: any

    name: string
    subtitle?: string
    width: string

    menu?: MenuOptions<any>

    play?: {
        status: BehaviorSubject<PlayerStatus>
        onClick?: (
            item: ListItem,
            statusBeforeClicking: PlayerStatus
        ) => Promise<PlayerStatus>
    }

    primaryButton?: {
        text: string
        iconPath: string
        onClick: () => void
        alwaysVisible: boolean
        color: "blue" | "green"
    }

    informationPill?: {
        text: string
        iconPath?: string
        tooltipText?: string
    }
}

@Component({
    selector: "list-items",
    templateUrl: "./list-items.component.html",
    styleUrls: ["./list-items.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ListItemsComponent extends ParentClass implements OnInit {
    @Input() items: ListItem[] = []
    @Input() backgroundColor: string = "rgba(255, 255, 255, 0.07)" // rgb(26, 24, 72)
    @Input() numberOfColumns: 1 | 2 = 1

    @ViewChild("listItemsWrapper") listItemsWrapper: ElementRef
    @ViewChild("renameInput") renameInput: ElementRef

    public hoveredItem: ListItem | undefined
    public menuOptionsComponent: ComponentRef<MenuOptionsComponent> | undefined

    public itemRename: ListItem | undefined
    public isTutorialInProgress:
        | "home"
        | "step1"
        | "step2part1"
        | "step2part2"
        | "step3"
        | "create"
        | undefined = undefined

    constructor(
        private ref: ChangeDetectorRef,
        private applicationRef: ApplicationRef,
        private tutorialService: TutorialService
    ) {
        super()
    }

    ngOnInit(): void {
        this.tutorialService.isTutorialInProgress$.subscribe(val => {
            this.isTutorialInProgress = val
            this.ref.detectChanges()
        })
    }

    ngAfterViewInit() {
        for (const item of this.items) {
            if (item.play !== undefined) {
                this.subscribe(item.play.status, (status: PlayerStatus) => {
                    this.ref.detectChanges()
                })
            }
        }
    }

    public toggleItem(event: MouseEvent, item: ListItem | undefined) {
        // Get the root view container ref of the application by injecting it into the root component
        const rootViewContainerRef =
            this.applicationRef.components[0].injector.get(ViewContainerRef)

        if (item === undefined || this.menuOptionsComponent !== undefined) {
            rootViewContainerRef.remove(
                rootViewContainerRef.indexOf(this.menuOptionsComponent.hostView)
            )
            this.menuOptionsComponent = undefined

            this.ref.detectChanges()

            return
        }

        const menuModifier: MenuOptions<any> = cloneDeep(item.menu)

        menuModifier.coordinates = {
            x: event.x,
            y: event.y,
        }

        for (const option of menuModifier.options) {
            if (option.buttonClass === "rename") {
                option.onClick = (async () => {
                    this.itemRename = item

                    await Misc.wait(0.1)
                    this.ref.detectChanges()

                    this.renameInput.nativeElement.focus()
                }).bind(this)
            }
        }

        menuModifier.onClose = (() => {
            this.toggleItem(event, undefined)
        }).bind(this)

        // Insert the modal component into the root view container
        this.menuOptionsComponent =
            rootViewContainerRef.createComponent(MenuOptionsComponent)

        this.menuOptionsComponent.instance.menuOptions = menuModifier
        this.subscribe(this.menuOptionsComponent.instance.close, event => {
            this.toggleItem(event, undefined)
        })

        this.ref.detectChanges()
    }

    async confirmRename(item: ListItem, source: string) {
        const option = item.menu.options.find(o => o.buttonClass === "rename")

        if (option) {
            await option.onConfirm(item.name)
        }

        this.itemRename = undefined
        this.ref.detectChanges()
    }

    hoverItem(item: ListItem | undefined) {
        this.hoveredItem = item
        this.ref.detectChanges()
    }

    shouldShowButton(item: ListItem) {
        return (
            item.primaryButton !== undefined &&
            (item.primaryButton.alwaysVisible || this.hoveredItem === item)
        )
    }

    async play(item: ListItem) {
        const savedStatus = item.play.status.getValue()

        item.play.status.next("loading")
        this.ref.detectChanges()

        item.play.status.next(await item.play.onClick(item, savedStatus))

        this.ref.detectChanges()
    }
}
