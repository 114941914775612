import { Delay, Effect, Reverb } from "../../../../general/classes/score/effect"
import { ScoreManipulation } from "../../../../general/modules/scoremanipulation"
import Layer from "../../../../general/classes/score/layer"
import { Note } from "../../../../general/classes/score/note"
import PercussionLayer from "../../../../general/classes/score/percussionlayer"
import Score from "../../../../general/classes/score/score"
import { v4 as uuidv4 } from "uuid"
import {
    AUTOMATION_TIMESTEP_RES,
    DEFAULT_LAYER_RANGE,
    DEFAULT_PERCUSSION_INSTRUMENT,
    HIGHEST_PIANO_PITCH,
    LayerType,
    MAX_TEMPO,
    MIN_TEMPO,
    NOTE_QUANTIZATION_THRESHOLDS,
    PATTERN_REGIONS_QUANTIZATION_THRESHOLDS,
    PITCH_CONTINOUS_COUNT,
    PITCH_SCALE_COUNT,
    SCALE_START_OCTAVE,
    SECTION_EDITING,
    TIMESTEP_RES,
    VALID_SECTION_OPERATIONS,
} from "../../../../general/constants/constants"
import {
    ScoreRendering,
    ScoreRenderingSelectedDataType,
    ScoreRenderingStore,
} from "./score-rendering.store"
import { ScoreRenderingQuery } from "./score-rendering.query"
import { Time } from "../../../../general/modules/time"
import {
    BarCount,
    FractionString,
    LayerFunctionType,
    TimeSignature,
} from "../../../../general/types/score"
import { NotesObject } from "../../../../general/classes/score/notesObject"
import Section from "../../../../general/classes/score/section"
import {
    CanvasType,
    PitchStepDomain,
    RenderChordsType,
    ScoreUpdateType,
} from "../../types"
import { Pattern } from "../../../../general/classes/score/pattern"
import PatternRegion from "../../../../general/classes/score/patternregion"
import { cloneDeep } from "lodash"
import { RangeWithID } from "../../../../general/interfaces/general"
import TrackBus from "../../../../general/classes/score/trackbus"
import { EditorViewQuery } from "../editor-view/editor-view.query"
import {
    HoveringType,
    HoveringTypeEnum,
    PatternHoveringType,
} from "../../../../general/types/general"
import {
    DrumSamplesMap,
    InstrumentJSON,
    InstrumentsJSON,
    PitchToChannelMapping,
} from "../../../../general/interfaces/score/general"
import { Colors } from "../../../../general/types/layer"
import {
    NoteEditingClipboard,
    SectionOperationType,
} from "../../../../general/types/note-editing"
import Channel from "../../../../general/classes/score/channel"
import { ActionsManager } from "../../../../general/classes/actionsManager"
import {
    RTSamplerActionTypes,
    RealtimeSamplerActions,
} from "../realtime-sampler/realtime-sampler.actions"
import { EditorViewStore } from "../editor-view/editor-view.store"
import { Misc } from "../../../../general/modules/misc"
import { Coordinates } from "../../../../general/modules/event-handlers"
import Tempo from "../../../../general/classes/score/tempo"
import SectionOperation from "../../../../general/classes/score/operation"
import {
    ActivityMetric,
    SectionEditingType,
    createAutomationEditingMetric,
    createBPMEditingMetric,
    createInstrumentEditingMetric,
    createNoteEditingMetric,
    createSectionEditingMetric,
} from "../../../../general/classes/activitymetric"

import { featureFlags } from "../../../../general/utils/feature-flags"
import { ScoreRenderingEngineMisc } from "../../score-rendering-engine-misc.module"
import {
    IClipboardDataOptions,
    IClipboardDataType,
    clipboardActions,
    clipboardQuery,
} from "../../../general/classes/clipboardStateManagement"
import {
    playerActions,
    playerQuery,
} from "../../../general/classes/playerStateManagement"
import { KeySignatureModule } from "../../../../general/modules/keysignature.module"
import { ChordManipulation } from "../../../../general/modules/chord-manipulation.module"
import {
    TemplateChord,
    TemplateKeySignature,
} from "../../../../general/interfaces/score/templateScore"
import { CompositionWorkflowModule } from "../../../../general/modules/composition-workflow.module"
import { KeySignature } from "../../../../general/interfaces/score/keySignature"
import { SHORT_SCALES_TO_SCALES_MAP } from "../../../../general/utils/composition-workflow.util"
import { SectionResizeType } from "../../../../general/interfaces/music-engine/general"
import { CompositionWorkflowNote } from "../../../../general/interfaces/composition-workflow.interface"
import { Fraction } from "../../../../general/classes/score/fraction"
import { SegmentedScoreManipulationModule } from "../../../../general/modules/score-transformers/segmented-score-manipulation.module"
import {
    SegmentedScorePercussionChannel,
    SegmentedScorePercussionLayer,
    SegmentedScoreSection,
} from "../../../../general/interfaces/score/segmentedscore"
import {
    GenerateLayerBody,
    GetSourceSectionChordsBody,
    InpaintSectionBody,
} from "../../../../general/interfaces/api/editor.api"
import SamplesMap from "../../../../general/interfaces/score/samplesMap"

export enum SRActionTypes {
    endDrawingPercussionNotes = "endDrawingPercussionNotes",
    followTimelineCursor = "followTimelineCursor",
    setScoreFX = "setScoreFX",
    setLayerGainBias = "setLayerGainBias",
    copyAutomationToOtherLayers = "copyAutomationToOtherLayers",
    selectNotes = "selectNotes",
    toggleEffect = "toggleEffect",
    deleteSelectedData = "deleteSelectedData",
    deleteTrackBusses = "deleteTrackBusses",
    updateLayerType = "updateLayerType",
    updateKeySignature = "updateKeySignature",
    selectSection = "selectSection",
    replaceTrackBus = "replaceTrackBus",
    resizeTrackBusRegion = "resizeTrackBusRegion",
    setLayerName = "setLayerName",
    breakUpTrackBusRegions = "breakUpTrackBusRegions",
    setConstantAutomation = "setConstantAutomation",
    setLayerColor = "setLayerColor",
    createTrackBusRegion = "createTrackBusRegion",
    toggleTrackBussesMute = "toggleTrackBussesMute",
    deleteLayer = "deleteLayer",
    setAccompanimentDesignerIsFocused = "setAccompanimentDesignerIsFocused",
    moveTrackBusRegion = "moveTrackBusRegion",
    selectTrackBusRegion = "selectTrackBusRegion",
    changeSectionName = "changeSectionName",
    mute = "mute",
    selectTrackBus = "selectTrackBus",
    solo = "solo",
    setAutomationValue = "setAutomationValue",
    setRenderingType = "setRenderingType",
    setScroll = "setScroll",
    resetScroll = "resetScroll",
    moveNotes = "moveNotes",
    replaceSection = "replaceSection",
    regenerateSection = "regenerateSection",
    setChordsAsEdited = "setChordsAsEdited",
    computeSustainPedal = "computeSustainPedal",
    setLayerDelay = "setLayerDelay",
    moveSingleNote = "moveSingleNote",
    selectReverbRoom = "selectReverbRoom",
    resizeNotes = "resizeNotes",
    endManipulatingNotes = "endManipulatingNotes",
    setTimestepRes = "setTimestepRes",
    cancelSectionOperation = "cancelSectionOperation",
    removeSection = "removeSection",
    deleteSection = "deleteSection",
    directlyDeleteSection = "directlyDeleteSection",
    addSection = "addSection",
    sectionInpainting = "sectionInpainting",
    sectionCopy = "sectionCopy",
    layerGeneration = "layerGeneration",
    setAccompanimentDesignerTimestepRes = "setAccompanimentDesignerTimestepRes",
    initScore = "initScore",
    addTrackBus = "addTrackBus",
    addTrackBusses = "addTrackBusses",
    setTempo = "setTempo",
    addCustomLayer = "addCustomLayer",
    seek = "seek",
    setAutomation = "setAutomation",
    startResizeKeySignature = "startResizeKeySignature",
    setHarmonyLock = "setHarmonyLock",
    toggleLayerAsVisible = "toggleLayerAsVisible",
    toggleLayer = "toggleLayer",
    clearNotesFromSection = "clearNotesFromSection",
    setPitchStepDomain = "setPitchStepDomain",
    setResizeFactor = "setResizeFactor",
    resetScoreWasEdited = "resetScoreWasEdited",
    setScoreWasEdited = "setScoreWasEdited",
    setAllowPolyphony = "setAllowPolyphony",
    setSelectedPattern = "setSelectedPattern",
    setSelectedPatternRegion = "setSelectedPatternRegion",
    unselectAll = "unselectAll",
    setPan = "setPan",
    resizeKeySignature = "resizeKeySignature",
    stopResizingTrackBusRegions = "stopResizingTrackBusRegions",
    setBreathingGain = "setBreathingGain",
    setGain = "setGain",
    setDynamic = "setDynamic",
    setOctave = "setOctave",
    setAutoPedal = "setAutoPedal",
    drawNote = "drawNote",
    removeNote = "removeNote",
    drawPercussionNote = "drawPercussionNote",
    duplicateLayer = "duplicateLayer",
    duplicateTrackBusses = "duplicateTrackBusses",
    selectAllNotes = "selectAllNotes",
    undo = "undo",
    redo = "redo",
    setPatternScroll = "setPatternScroll",
    setSelectedNotes = "setSelectedNotes",
    addLayerData = "addLayerData",
    copy = "copy",
    cut = "cut",
    paste = "paste",
    setTimeSignature = "setTimeSignature",
    addChannel = "addChannel",
    toggleChannelPlayback = "toggleChannelPlayback",
    setPatternChannel = "setPatternChannel",
    addPattern = "addPattern",
    renamePattern = "renamePattern",
    setKeySignature = "setKeySignature",
    splitKeySignature = "splitKeySignature",
    setPatternBars = "setPatternBars",
    startRealtimeSampler = "startRealtimeSampler",
    setPatternResolution = "setPatternResolution",
    deleteChannel = "deleteChannel",
    deletePattern = "deletePattern",
    startNoteManipulation = "startNoteManipulation",
    setAccompanimentDesignerScoreLength = "setAccompanimentDesignerScoreLength",
    moveNotesWithKeyboard = "moveNotesWithKeyboard",
    manipulatePatternRegion = "manipulatePatternRegion",
    endManipulatingPatterns = "endManipulatingPatterns",
    drawPatternRegion = "drawPatternRegion",
    setLayerPreviewConfigs = "setLayerPreviewConfigs",
    updateChords = "updateChords",
    setRenderChordsType = "setRenderChordsType",
    resizeChord = "resizeChord",
    endResizeChord = "endResizeChord",
    forceRendering = "forceRendering",
    harmonyAnalysis = "harmonyAnalysis",
    resetScore = "resetScore",
    emptyAction = "emptyAction",
    convertChordsToRomanNumerals = "convertChordsToRomanNumerals",
    setEditorType = "setEditorType",
    copySectionChords = "copySectionChords",
    resizeSection = "resizeSection",
    chordsPlusButtonClicked = "chordsPlusButtonClicked",
    splitSection = "splitSection",
}

export class ScoreRenderingActions {
    public readonly actionTypeToMethodMap: {
        [key: string]: (...args) => any
    } = {
        [SRActionTypes.copyAutomationToOtherLayers]:
            this.copyAutomationToOtherLayers.bind(this),
        [SRActionTypes.selectNotes]: this.selectNotes.bind(this),
        [SRActionTypes.resizeKeySignature]: this.resizeKeySignature.bind(this),
        [SRActionTypes.toggleEffect]: this.toggleEffect.bind(this),
        [SRActionTypes.deleteSelectedData]: this.deleteSelectedData.bind(this),
        [SRActionTypes.deleteTrackBusses]: this.deleteTrackBusses.bind(this),
        [SRActionTypes.updateLayerType]: this.updateLayerType.bind(this),
        [SRActionTypes.changeSectionName]: this.changeSectionName.bind(this),
        [SRActionTypes.updateKeySignature]: this.updateKeySignature.bind(this),
        [SRActionTypes.selectSection]: this.selectSection.bind(this),
        [SRActionTypes.startRealtimeSampler]:
            this.startRealtimeSampler.bind(this),
        [SRActionTypes.resizeTrackBusRegion]:
            this.resizeTrackBusRegion.bind(this),
        [SRActionTypes.setLayerName]: this.setLayerName.bind(this),
        [SRActionTypes.setConstantAutomation]:
            this.setConstantAutomation.bind(this),
        [SRActionTypes.setLayerColor]: this.setLayerColor.bind(this),
        [SRActionTypes.replaceTrackBus]: this.replaceTrackBus.bind(this),
        [SRActionTypes.toggleTrackBussesMute]:
            this.toggleTrackBussesMute.bind(this),
        [SRActionTypes.deleteLayer]: this.deleteLayer.bind(this),
        [SRActionTypes.moveTrackBusRegion]: this.moveTrackBusRegion.bind(this),
        [SRActionTypes.selectTrackBusRegion]:
            this.selectTrackBusRegion.bind(this),
        [SRActionTypes.mute]: this.mute.bind(this),
        [SRActionTypes.setLayerGainBias]: this.setLayerGainBias.bind(this),
        [SRActionTypes.solo]: this.solo.bind(this),
        [SRActionTypes.setAutomationValue]: this.setAutomationValue.bind(this),
        [SRActionTypes.setRenderingType]: this.setRenderingType.bind(this),
        [SRActionTypes.setScroll]: this.setScroll.bind(this),
        [SRActionTypes.setAccompanimentDesignerIsFocused]:
            this.setAccompanimentDesignerIsFocused.bind(this),
        [SRActionTypes.setScoreFX]: this.setScoreFX.bind(this),
        [SRActionTypes.moveNotes]: this.moveNotes.bind(this),
        [SRActionTypes.selectTrackBus]: this.selectTrackBus.bind(this),
        [SRActionTypes.moveSingleNote]: this.moveSingleNote.bind(this),
        [SRActionTypes.endDrawingPercussionNotes]:
            this.endDrawingPercussionNotes.bind(this),
        [SRActionTypes.addLayerData]: this.addLayerData.bind(this),
        [SRActionTypes.stopResizingTrackBusRegions]:
            this.stopResizingTrackBusRegions.bind(this),
        [SRActionTypes.resizeNotes]: this.resizeNotes.bind(this),
        [SRActionTypes.endManipulatingNotes]:
            this.endManipulatingNotes.bind(this),
        [SRActionTypes.breakUpTrackBusRegions]:
            this.breakUpTrackBusRegions.bind(this),
        [SRActionTypes.setTimestepRes]: this.setTimestepRes.bind(this),
        [SRActionTypes.setAccompanimentDesignerTimestepRes]:
            this.setAccompanimentDesignerTimestepRes.bind(this),
        [SRActionTypes.setHarmonyLock]: this.setHarmonyLock.bind(this),
        [SRActionTypes.initScore]: this.initScore.bind(this),
        [SRActionTypes.addTrackBus]: this.addTrackBus.bind(this),
        [SRActionTypes.addCustomLayer]: this.addCustomLayer.bind(this),
        [SRActionTypes.seek]: this.seek.bind(this),
        [SRActionTypes.setAutomation]: this.setAutomation.bind(this),
        [SRActionTypes.setPan]: this.setPan.bind(this),
        [SRActionTypes.setChordsAsEdited]: this.setChordsAsEdited.bind(this),
        [SRActionTypes.startResizeKeySignature]:
            this.startResizeKeySignature.bind(this),
        [SRActionTypes.cancelSectionOperation]:
            this.cancelSectionOperation.bind(this),
        [SRActionTypes.removeSection]: this.removeSection.bind(this),
        [SRActionTypes.deleteSection]: this.deleteSection.bind(this),
        [SRActionTypes.replaceSection]: this.replaceSection.bind(this),
        [SRActionTypes.regenerateSection]: this.regenerateSection.bind(this),
        [SRActionTypes.addSection]: this.addSection.bind(this),
        [SRActionTypes.sectionInpainting]: this.sectionInpainting.bind(this),
        [SRActionTypes.sectionCopy]: this.sectionCopy.bind(this),
        [SRActionTypes.layerGeneration]: this.layerGeneration.bind(this),
        [SRActionTypes.splitKeySignature]: this.splitKeySignature.bind(this),
        [SRActionTypes.setGain]: this.setGain.bind(this),
        [SRActionTypes.setBreathingGain]: this.setBreathingGain.bind(this),
        [SRActionTypes.setAutoPedal]: this.setAutoPedal.bind(this),
        [SRActionTypes.setDynamic]: this.setDynamic.bind(this),
        [SRActionTypes.setOctave]: this.setOctave.bind(this),
        [SRActionTypes.toggleLayerAsVisible]:
            this.toggleLayerAsVisible.bind(this),
        [SRActionTypes.toggleLayer]: this.toggleLayer.bind(this),
        [SRActionTypes.addTrackBusses]: this.addTrackBusses.bind(this),
        [SRActionTypes.setTempo]: this.setTempo.bind(this),
        [SRActionTypes.setKeySignature]: this.setKeySignature.bind(this),
        [SRActionTypes.setPitchStepDomain]: this.setPitchStepDomain.bind(this),
        [SRActionTypes.setResizeFactor]: this.setResizeFactor.bind(this),
        [SRActionTypes.resetScoreWasEdited]:
            this.resetScoreWasEdited.bind(this),
        [SRActionTypes.setAllowPolyphony]: this.setAllowPolyphony.bind(this),
        [SRActionTypes.setSelectedPattern]: this.setSelectedPattern.bind(this),
        [SRActionTypes.setSelectedPatternRegion]:
            this.setSelectedPatternRegion.bind(this),
        [SRActionTypes.unselectAll]: this.unselectAll.bind(this),
        [SRActionTypes.duplicateLayer]: this.duplicateLayer.bind(this),
        [SRActionTypes.drawNote]: this.drawPitchedNote.bind(this),
        [SRActionTypes.setScoreWasEdited]: this.setScoreWasEdited.bind(this),
        [SRActionTypes.removeNote]: this.removeNote.bind(this),
        [SRActionTypes.drawPercussionNote]: this.drawPercussionNote.bind(this),
        [SRActionTypes.selectAllNotes]: this.selectAllNotes.bind(this),
        [SRActionTypes.setPatternScroll]: this.setPatternScroll.bind(this),
        [SRActionTypes.clearNotesFromSection]:
            this.clearNotesFromSection.bind(this),
        [SRActionTypes.setSelectedNotes]: this.setSelectedNotes.bind(this),
        [SRActionTypes.setLayerDelay]: this.setLayerDelay.bind(this),
        [SRActionTypes.copy]: this.copyNotes.bind(this),
        [SRActionTypes.cut]: this.cutNotes.bind(this),
        [SRActionTypes.paste]: this.pasteNotes.bind(this),
        [SRActionTypes.setTimeSignature]: this.setTimeSignature.bind(this),
        [SRActionTypes.addChannel]: this.addChannel.bind(this),
        [SRActionTypes.duplicateTrackBusses]:
            this.duplicateTrackBusses.bind(this),
        [SRActionTypes.toggleChannelPlayback]:
            this.toggleChannelPlayback.bind(this),
        [SRActionTypes.setPatternChannel]: this.setPatternChannel.bind(this),
        [SRActionTypes.addPattern]: this.addPattern.bind(this),
        [SRActionTypes.renamePattern]: this.renamePattern.bind(this),
        [SRActionTypes.selectReverbRoom]: this.selectReverbRoom.bind(this),
        [SRActionTypes.setPatternBars]: this.setPatternBars.bind(this),
        [SRActionTypes.setPatternResolution]:
            this.setPatternResolution.bind(this),
        [SRActionTypes.deleteChannel]: this.deleteChannel.bind(this),
        [SRActionTypes.deletePattern]: this.deletePattern.bind(this),
        [SRActionTypes.startNoteManipulation]:
            this.startNoteManipulation.bind(this),
        [SRActionTypes.undo]: this.undo.bind(this),
        [SRActionTypes.redo]: this.redo.bind(this),
        [SRActionTypes.setAccompanimentDesignerScoreLength]:
            this.setAccompanimentDesignerScoreLength.bind(this),
        [SRActionTypes.computeSustainPedal]:
            this.computeSustainPedal.bind(this),
        [SRActionTypes.moveNotesWithKeyboard]:
            this.moveNotesWithKeyboard.bind(this),
        [SRActionTypes.createTrackBusRegion]:
            this.createTrackBusRegion.bind(this),
        [SRActionTypes.manipulatePatternRegion]:
            this.manipulatePatternRegion.bind(this),
        [SRActionTypes.endManipulatingPatterns]:
            this.endManipulatingPatterns.bind(this),
        [SRActionTypes.drawPatternRegion]: this.drawPatternRegion.bind(this),
        [SRActionTypes.resetScroll]: this.resetScroll.bind(this),
        [SRActionTypes.followTimelineCursor]:
            this.followTimelineCursor.bind(this),
        [SRActionTypes.setLayerPreviewConfigs]:
            this.setLayerPreviewConfigs.bind(this),
        [SRActionTypes.harmonyAnalysis]: this.harmonyAnalysis.bind(this),
        [SRActionTypes.updateChords]: this.updateChords.bind(this),
        [SRActionTypes.setRenderChordsType]:
            this.setRenderChordsType.bind(this),
        [SRActionTypes.resizeChord]: this.resizeChord.bind(this),
        [SRActionTypes.convertChordsToRomanNumerals]:
            this.convertChordsToRomanNumerals.bind(this),
        [SRActionTypes.endResizeChord]: this.endResizeChord.bind(this),
        [SRActionTypes.forceRendering]: this.forceRendering.bind(this),
        [SRActionTypes.resetScore]: this.resetScore.bind(this),
        [SRActionTypes.setEditorType]: this.setEditorType.bind(this),
        [SRActionTypes.copySectionChords]: this.copySectionChords.bind(this),
        [SRActionTypes.resizeSection]: this.resizeSection.bind(this),
        [SRActionTypes.splitSection]: this.splitSection.bind(this),
        [SRActionTypes.chordsPlusButtonClicked]:
            this.chordsPlusButtonClicked.bind(this),
        [SRActionTypes.emptyAction]: this.emptyAction.bind(this),
    }

    public readonly manager: ActionsManager<
        SRActionTypes,
        ScoreRendering,
        ScoreRendering
    >

    constructor(
        private store: ScoreRenderingStore,
        private query: ScoreRenderingQuery,
        private editorViewQuery: EditorViewQuery,
        private editorViewStore: EditorViewStore,
        private realtimePlayer: RealtimeSamplerActions,
        private templateBeforeActionEffect: Function
    ) {
        this.manager = new ActionsManager(store, this.actionTypeToMethodMap, {
            templateBeforeActionEffect: this.templateBeforeActionEffect,
        })
    }

    private updateChords({
        chords,
        romanNumerals,
        notes,
    }: {
        chords: TemplateChord[]
        romanNumerals: TemplateChord[]
        notes: NotesObject
    }) {
        const score = this.query.score

        score.chords = chords
        score.romanNumerals = romanNumerals

        if (this.query.editorType === "composition-workflow") {
            score.layers.Chords.notesObject = notes
        }

        this.store.updateStore({
            partial: {
                score,
                renderingType: ["All"],
                scoreUpdate: ["Note"],
                skipCachedCanvas: true,
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
        })
    }

    private forceRendering() {
        const toggledLayer = this.query.toggledLayer

        this.store.updateStore({
            partial: {
                selectedSection: {
                    coordinates: undefined,
                    section: undefined,
                },
                toggledLayer: "",
                scoreUpdate: ["All"],
            },

            scoreWasEdited: false,
            updateScoreLength: false,
        })

        this.store.updateStore({
            partial: {
                toggledLayer: toggledLayer ? toggledLayer.value : "",
                renderingType: ["All"],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private undo({
        trackBusLoadingState,
    }: {
        trackBusLoadingState: { [trackBusID: string]: boolean }
    }) {
        const result = this.manager.undo()

        if (!result) {
            return
        }

        for (const tb of this.query.score.trackBusses) {
            if (trackBusLoadingState[tb.id] === false) {
                tb.loading = false
            }
        }

        this.forceRendering()
    }

    private redo({
        trackBusLoadingState,
    }: {
        trackBusLoadingState: { [trackBusID: string]: boolean }
    }) {
        const result = this.manager.redo()

        if (!result) {
            return
        }

        for (const tb of this.query.score.trackBusses) {
            if (trackBusLoadingState[tb.id]) {
                tb.loading = false
            }
        }

        this.forceRendering()
    }

    private async harmonyAnalysis({
        selectedSectionIndex,
        instruments,
        samplesMap,
        http,
        resolve,
    }: {
        selectedSectionIndex: number
        instruments: InstrumentsJSON
        samplesMap: DrumSamplesMap
        http: (section: SegmentedScoreSection) => Promise<{
            chords: TemplateChord[]
            keySignatures: TemplateKeySignature[]
        }>
        resolve: Function
    }) {
        const score = this.query.score

        const segScore =
            SegmentedScoreManipulationModule.fromScoreToSegmentedScore(
                score,
                instruments,
                samplesMap,
                true,
                false
            )

        const section = segScore.sections[selectedSectionIndex]

        const result = await http(section)

        const temp = []
        let tempStart = "0"

        // First we insert the key signature. It's important to do the key signature first because
        // the chord symbol -> roman numeral conversion is dependent on the key signature
        for (const k of result.keySignatures) {
            temp.push([tempStart, k[1]])

            tempStart = Time.addTwoFractions(tempStart, k[0])
        }

        const toInsert =
            KeySignatureModule.fromKeySignaturesToIntermediateRepresentation(
                tempStart,
                temp
            )

        for (const k of toInsert) {
            k.start = Time.addTwoFractions(k.start, section.start)
            k.end = Time.addTwoFractions(k.end, section.start)

            score.keySignatures = KeySignatureModule.insertKeySignature(
                score.keySignatures,
                k,
                score.scoreLength
            )
        }

        score.chords = ChordManipulation.insertAndReplaceChords(
            score.chords,
            result.chords,
            section.start
        )

        score.romanNumerals =
            ChordManipulation.convertChordSymbolsToRomanNumerals(
                score.chords,
                score.keySignatures
            )

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
                temporaryRomanNumerals: undefined,
                temporaryChords: undefined,
                chordsWereEdited: true,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })

        resolve()
    }

    private changeSectionName({
        name,
        section,
    }: {
        name: string
        section: Section
    }) {
        section.title = name

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private resetScroll({
        timesteps,
        pitchsteps,
    }: {
        timesteps: boolean
        pitchsteps: boolean
    }) {
        if (timesteps) {
            this.store.updateStore({
                partial: {
                    scrollToTimestep: 0,
                    renderingType: ["All"],
                    skipCachedCanvas: true,
                },
                scoreWasEdited: false,
                updateScoreLength: false,
            })
        }

        if (pitchsteps) {
            this.store.updateStore({
                partial: {
                    scrollToPitchsteps: 0,
                },
                scoreWasEdited: false,
                updateScoreLength: false,
            })
        }
    }

    private endDrawingPercussionNotes() {
        if (this.query.toggledLayer === undefined) {
            return
        }

        this.store.updateStore({
            partial: {
                renderingType: [
                    "LayerPreviewCanvas_" + this.query.toggledLayer.value,
                ],
                skipCachedCanvas: true,
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private setTimeSignature({
        timeSignature,
    }: {
        timeSignature: TimeSignature
    }) {
        const score = this.query.score
        score.timeSignatures[0][1] = timeSignature

        this.store.updateStore({
            partial: {
                score,
                renderingType: ["All"],
                scoreUpdate: ["All"],
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
        })
    }

    private setLayerGainBias({ layer, gain }: { layer: Layer; gain: number }) {
        layer.gainBias = gain

        this.store.updateStore({
            partial: {},
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private selectReverbRoom({ roomType }: { roomType: string }) {
        ;(this.query.selectedAutomation as Reverb).ir = roomType

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditEverythingExceptNotes,
            data: {},
        })

        this.store.updateStore({
            partial: {
                scoreUpdate: ["Effect"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private startResizeKeySignature() {}

    private splitKeySignature({ index }: { index: number }) {
        this.query.score.keySignatures = KeySignatureModule.splitKeySignature(
            index,
            this.query.score.scoreLength,
            this.query.score.keySignatures,
            this.query.score.firstTimeSignature
        )

        this.store.updateStore({
            partial: {
                renderingType: ["KeySignatureEditingCanvas"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setKeySignature({
        index,
        keySignature,
    }: {
        index: number
        keySignature: string
    }) {
        this.query.score.keySignatures[index][1] = keySignature

        this.store.updateStore({
            partial: {
                renderingType: ["KeySignatureEditingCanvas"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setLayerDelay({
        type,
        delay,
        layer,
    }: {
        type: "delayRight" | "delayLeft"
        delay: number
        layer: Layer
    }) {
        if (type === "delayRight") {
            ;(layer.effects.delay as Delay).right.delay_time = delay
        } else {
            ;(layer.effects.delay as Delay).left.delay_time = delay
        }

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditEverythingExceptNotes,
            data: {},
        })

        this.store.updateStore({
            partial: {
                scoreUpdate: ["Effect"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private addChannel({
        layer,
        pattern,
        trackBus,
    }: {
        layer: PercussionLayer
        pattern: Pattern
        trackBus: TrackBus
    }) {
        const selectedPattern = this.hasPattern(layer, pattern)

        if (!selectedPattern) {
            return
        }

        selectedPattern.channels.unshift(
            new Channel("Unassigned", [], [], false, false, trackBus)
        )

        this.store.updateStore({
            partial: {
                renderingType: [
                    "LayerPreviewCanvas_" + layer.value,
                    "DrumSequencerCanvas",
                    "PatternRegionsCanvas",
                ],
                scoreUpdate: ["Pattern"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private replaceSection({ section }: { section: Section }) {
        section.operation = SectionOperation.replaceSection(section.index)

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
            metric: this.sectionEditingMetric(SECTION_EDITING.REPLACE),
        })
    }

    private sectionEditingMetric(type: SectionEditingType) {
        return createSectionEditingMetric(this.query.score.compositionID, type)
    }

    private async duplicateLayer({
        layer,
        name,
    }: {
        layer: Layer
        name: string
    }) {
        const score = this.query.score
        const duplicatedLayer = ScoreManipulation.createNewLayer(
            score,
            layer.type,
            {
                layer,
                copyType: "all",
            }
        )
        duplicatedLayer.name = name

        score.layers[duplicatedLayer.value] = duplicatedLayer

        this.store.updateStore({
            partial: {
                score,
                renderingType: ["All"],
                scoreUpdate: ["All"],
                skipCachedCanvas: true,
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
        })

        await new Promise(resolve => {
            this.realtimePlayer.emitter$.next({
                type: RTSamplerActionTypes.loadTrackBusses,
                data: {
                    tbs: duplicatedLayer.trackBuses,
                    instruments:
                        this.editorViewQuery.getValue().instrumentsJSON,
                },
                resolve,
            })
        })

        this.toggleLayer({ toggledLayer: duplicatedLayer })
    }

    private regenerateSection({
        section,
        basedOn,
    }: {
        section: Section
        basedOn?: Section
    }) {
        section.operation = SectionOperation.regenerateSection(
            section.index,
            basedOn ? basedOn.index : null
        )

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
            metric: this.sectionEditingMetric(
                basedOn
                    ? SECTION_EDITING.REGENERATE_WITH_SOURCE
                    : SECTION_EDITING.REGENERATE
            ),
        })
    }

    private sectionCopy({
        section,
        insertPosition,
        sourceSectionIndex,
        instruments,
        samplesMap,
        resolve,
    }: {
        section: Section
        insertPosition: "after" | "before"
        sourceSectionIndex: number
        instruments: InstrumentsJSON
        samplesMap: SamplesMap
        resolve: (result: boolean) => {}
    }) {
        try {
            const newSectionIndex =
                insertPosition === "after" ? section.index + 1 : section.index

            const sourceSection = this.query.score.sections[sourceSectionIndex]

            const ts =
                this.query.score.firstTimeSignature[0] +
                "/" +
                this.query.score.firstTimeSignature[1]

            const sourceSectionLength = Time.divideTwoFractions(
                this.query.score.sections[sourceSectionIndex].duration,
                ts
            )

            const operation = SectionOperation.insertNewSection(
                newSectionIndex,
                SECTION_EDITING.INSERT_BLANK,
                section.index,
                sourceSectionLength
            )

            const score = cloneDeep(this.query.score)

            ScoreManipulation.insertSection(
                score,
                section,
                operation,
                sourceSectionLength,
                insertPosition
            )

            for (const section of this.query.score.sections) {
                section.inserted = false
                section.operation = undefined
            }

            const segScore =
                SegmentedScoreManipulationModule.fromScoreToSegmentedScore(
                    score,
                    instruments,
                    samplesMap,
                    true,
                    false
                )

            ScoreManipulation.insertSection(
                this.query.score,
                section,
                operation,
                sourceSectionLength,
                insertPosition,
                segScore.sections[sourceSectionIndex].name
            )

            const segSections: SegmentedScoreSection[] = cloneDeep(
                segScore.sections.filter(s => s.index !== newSectionIndex)
            )
            const newSection: SegmentedScoreSection =
                segSections[sourceSectionIndex]

            newSection.index = newSectionIndex
            newSection.start = this.query.score.sections[newSectionIndex].start
            newSection.end = this.query.score.sections[newSectionIndex].end

            const newName = newSection.name

            for (const layer of newSection.layers) {
                if (layer.type === "percussion") {
                    for (const channel of layer.channels) {
                        channel.name = channel.name.split(".")[1]
                    }
                }
            }

            SegmentedScoreManipulationModule.mergeCopyOfSectionIntoScore(
                newSection,
                this.query.score,
                samplesMap
            )

            ScoreManipulation.addTrackBusRegionsFromSectionV2(
                sourceSection,
                newSectionIndex,
                this.query.score
            )

            this.query.score.sections[newSectionIndex].title = newName

            let selectedData = this.query.selectedData

            if (selectedData.type === "Note") {
                selectedData = {
                    type: "None",
                    data: undefined,
                }
            }

            this.store.updateStore({
                partial: {
                    ...this.query.score,
                    renderingType: ["All"],
                    skipCachedCanvas: false,
                    sustainPedalFromChords: false,
                    selectedData,
                },
                scoreWasEdited: true,
                updateScoreLength: true,
                computeAdditionalMetadata: true,
                metric: this.sectionEditingMetric(operation.type),
            })

            resolve(true)
        } catch (e) {
            console.error(e)
            resolve(false)
        }
    }

    private async sectionInpainting({
        section,
        insertPosition,
        sectionLength,
        sourceSectionIndex,
        instruments,
        samplesMap,
        abort,
        inpaintSection,
        getSourceSectionChords,
        resolve,
    }: {
        section: Section
        insertPosition: "after" | "before"
        sectionLength: number
        sourceSectionIndex: number | undefined
        instruments: InstrumentsJSON
        samplesMap: SamplesMap
        abort: AbortController
        inpaintSection: (
            data: InpaintSectionBody,
            abort: AbortController
        ) => Promise<SegmentedScoreSection>
        getSourceSectionChords: (
            data: GetSourceSectionChordsBody
        ) => Promise<any>
        resolve: (result: boolean) => {}
    }) {
        try {
            const newSectionIndex =
                insertPosition === "after" ? section.index + 1 : section.index

            const operation = SectionOperation.insertNewSection(
                newSectionIndex,
                SECTION_EDITING.INSERT_BLANK,
                section.index,
                sectionLength
            )

            const score = cloneDeep(this.query.score)

            ScoreManipulation.insertSection(
                score,
                section,
                operation,
                sectionLength,
                insertPosition
            )

            for (const section of this.query.score.sections) {
                section.inserted = false
                section.operation = undefined
            }

            const segScore =
                SegmentedScoreManipulationModule.fromScoreToSegmentedScore(
                    score,
                    instruments,
                    samplesMap,
                    true,
                    false
                )

            const nbOfSectionsToInclude = 3
            const before: SegmentedScoreSection[] = segScore.sections.slice(
                Math.max(0, newSectionIndex - nbOfSectionsToInclude),
                newSectionIndex
            )
            const current = segScore.sections[newSectionIndex]
            const after: SegmentedScoreSection[] = segScore.sections.slice(
                newSectionIndex + 1,
                Math.min(
                    segScore.sections.length,
                    newSectionIndex + 1 + nbOfSectionsToInclude
                )
            )

            current.chords = []
            current.key_signatures = []

            // The model can be further conditioned to give better inpainting results
            // when explicit chords or key signatures are added to the current section
            // before inpainting.
            const segSections: SegmentedScoreSection[] = cloneDeep(
                segScore.sections.filter(s => s.index !== newSectionIndex)
            )
            const sourceSection: SegmentedScoreSection | undefined =
                segSections[sourceSectionIndex]

            if (sourceSection) {
                const duration = Time.addTwoFractions(
                    current.end,
                    current.start,
                    true
                )

                current.chords = await getSourceSectionChords({
                    section: sourceSection,
                    duration: duration,
                    keySignature: this.query.score.getKeySignature(),
                    timeSignature: score.firstTimeSignature,
                    keySignatures: score.keySignatures,
                    toggleLocalLLM: false,
                })

                current.key_signatures = sourceSection.key_signatures
            }

            const nb_of_sections =
                section.index === segScore.sections.length
                    ? segScore.sections.length + 1
                    : segScore.sections.length

            const newSection = await inpaintSection(
                {
                    nb_of_sections: nb_of_sections,
                    before,
                    current,
                    after,
                },
                abort
            )

            newSection.chords = newSection?.chords?.length
                ? newSection.chords
                : current.chords

            newSection.chords.forEach(c => {
                if (c[1] === "") {
                    c[1] = score.keySignatures[0][1]
                }
            })

            const sectionName = sourceSection?.name
                ? sourceSection?.name
                : ScoreManipulation.getNewSectionName(score.sections)

            ScoreManipulation.insertSection(
                this.query.score,
                section,
                operation,
                sectionLength,
                insertPosition,
                sectionName
            )

            SegmentedScoreManipulationModule.mergeSectionIntoScore(
                newSection,
                this.query.score,
                instruments,
                samplesMap,
                "sectionInpainting",
                this.query.toggledLayer
            )

            if (sourceSection !== undefined) {
                ScoreManipulation.addTrackBusRegionsFromSectionV2(
                    this.query.score.sections[sourceSectionIndex],
                    newSectionIndex,
                    this.query.score
                )
            }

            let selectedData = this.query.selectedData

            if (selectedData.type === "Note") {
                selectedData = {
                    type: "None",
                    data: undefined,
                }
            }

            this.store.updateStore({
                partial: {
                    renderingType: ["All"],
                    skipCachedCanvas: true,
                    sustainPedalFromChords: false,
                    selectedData,
                },
                scoreWasEdited: true,
                updateScoreLength: true,
                computeAdditionalMetadata: true,
                metric: this.sectionEditingMetric(operation.type),
            })

            resolve(true)
        } catch (e) {
            console.error(e)
            resolve(false)
        }
    }

    private async layerGeneration({
        section,
        sectionLength,
        layerName,
        instruments,
        samplesMap,
        abort,
        generateLayer,
        resolve,
    }: {
        section: Section
        sectionLength: number
        layerName: string
        instruments: InstrumentsJSON
        samplesMap: SamplesMap
        abort: AbortController
        generateLayer: (
            data: GenerateLayerBody,
            abort: AbortController
        ) => Promise<CompositionWorkflowNote[]>
        resolve: (result: boolean) => {}
    }) {
        try {
            if (section?.index == undefined || !layerName) {
                resolve(false)
                return
            }

            const score = this.query.score
            const layer = score.layers[layerName]

            if (layer == undefined) {
                resolve(false)
                return
            }

            let trackBuses = layer?.trackBuses
            let instrument = ""

            if (layer?.type === "percussion") {
                // this is a fix for empty percussion layers causing an issue with regeneration
                let instrumentForPercussion = instruments["p"].find(
                    i => i.name == DEFAULT_PERCUSSION_INSTRUMENT
                )
                if (!trackBuses?.length) {
                    this.addTrackBus({
                        layer,
                        instrument: instrumentForPercussion,
                    })
                }

                trackBuses = [
                    ScoreManipulation.getTrackBusWithMostPercussionChannels(
                        layer as PercussionLayer,
                        instruments
                    ),
                ]
            }

            const operation = SectionOperation.regenerateSection(section.index)

            // Convert the score into a segmented score
            const segScore =
                SegmentedScoreManipulationModule.fromScoreToSegmentedScore(
                    cloneDeep(score),
                    instruments,
                    samplesMap,
                    true,
                    true
                )

            const currentSection = segScore.sections[section.index]

            if (trackBuses?.length) {
                // This not only gets the instrument strings but also adds the trackBus regions
                // to the current section in one go
                instrument =
                    SegmentedScoreManipulationModule.getInstrumentStringForLayerGeneration(
                        cloneDeep(segScore.sections),
                        currentSection,
                        trackBuses,
                        instruments
                    )
            }

            // The callback function generateLayer comes from the editor.component.ts,
            // this is the http request to generate a layer
            const newLayerNotes: CompositionWorkflowNote[] =
                await generateLayer(
                    {
                        section: cloneDeep(currentSection),
                        layerToGenerate: layerName,
                        prompt: "",
                        style: "",
                        instrument: instrument,
                        type: "editor",
                    },
                    abort
                )

            let newLayer = cloneDeep(layer)

            let renderingType = [
                "AccompanimentDesignerCanvas",
                "LayerPreviewCanvas_" + layer.value,
                "TrackbusRegionsCanvas",
            ]

            if (layer.type !== "percussion") {
                newLayer =
                    ScoreManipulation.replaceLayerNotesInTimeRangeWithCWNotes(
                        score,
                        layer,
                        [section.start, section.end],
                        newLayerNotes
                    )
                score.computePhrases(newLayer)
            } else {
                renderingType = [
                    "LayerPreviewCanvas_" + this.query.toggledLayer.value,
                    "DrumSequencerCanvas",
                    "PatternRegionsCanvas",
                ]

                newLayer =
                    ScoreManipulation.updatePercussionLayerSectionWithRegeneratedContent(
                        score,
                        layer as PercussionLayer,
                        section,
                        currentSection,
                        newLayerNotes,
                        instruments,
                        samplesMap,
                        "layerInpainting"
                    )
            }

            score.layers[layerName] = newLayer

            let selectedData = this.query.selectedData

            if (
                selectedData.type === "Note" ||
                selectedData.type === "PatternRegion"
            ) {
                selectedData = {
                    type: "None",
                    data: undefined,
                }
            }

            this.store.updateStore({
                partial: {
                    score: score,
                    renderingType,
                    skipCachedCanvas: true,
                    sustainPedalFromChords: false,
                    forcePrerender: false,
                    selectedData,
                },
                scoreWasEdited: true,
                updateScoreLength: true,
                computeAdditionalMetadata: true,
                metric: this.sectionEditingMetric(operation.type),
            })
            resolve(true)
        } catch (e) {
            console.error(e)
            resolve(false)
        }
    }

    /**
     * @param section - Refers to the selected section. If the insertPosition is "after", then we will need
     * to increment the index of the new section we want to add by +1
     * @param insertPosition - Refers to the position where we want to place the new section, relative to the section selected.
     * If the insertPosition is "after", then we will need to increment the index of the new section we want to add by +1. Otherwise,
     * the index remains the same.
     */
    private async addSection({
        type,
        section,
        insertPosition,
        sectionLength,
        sourceSectionIndex,
        resolve,
    }: {
        type: SectionOperationType
        section: Section
        insertPosition: "after" | "before"
        sectionLength: number
        sourceSectionIndex: number | undefined
        resolve: () => {}
    }) {
        const score = this.query.score

        const valid = [...VALID_SECTION_OPERATIONS].map((v: string) => {
            return v.replace("insert_", "")
        })

        if (score === undefined || !valid.includes(type)) {
            return
        }

        let selectedSectionIndex = section.index
        if (
            section.inserted &&
            section.operation != null &&
            section.operation.args != null
        ) {
            selectedSectionIndex = section.operation.args.section_idx
        } else {
            if (insertPosition === "after") {
                selectedSectionIndex += 1
            }
        }

        const operation = SectionOperation.insertNewSection(
            selectedSectionIndex,
            type,
            sourceSectionIndex,
            sectionLength
        )

        const sectionName = ScoreManipulation.getNewSectionName(
            this.query.score.sections
        )

        ScoreManipulation.insertSection(
            this.query.score,
            section,
            operation,
            sectionLength,
            insertPosition,
            sectionName
        )

        this.store.updateStore({
            partial: {
                score,
                renderingType: ["All"],
                skipCachedCanvas: true,
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
            metric: this.sectionEditingMetric(operation.type),
        })

        resolve()
    }

    private async addLayerData({
        layer,
        trackBuses,
    }: {
        layer: Layer | PercussionLayer
        trackBuses: TrackBus[]
    }) {
        if (this.query.score.layers[layer.value] !== undefined) {
            this.deleteLayer({
                layer: this.query.score.layers[layer.value],
            })
        }

        const score = ScoreManipulation.addLayerData({
            layer,
            trackBuses,
            score: this.query.score,
        })

        this.computeSustainPedal()

        this.store.updateStore({
            partial: {
                score,
                renderingType: ["All"],
                scoreUpdate: ["Layer", "Pattern"],
                skipCachedCanvas: true,
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
        })

        await Misc.wait(0.1)

        await new Promise(r => {
            this.realtimePlayer.emitter$.next({
                type: RTSamplerActionTypes.loadTrackBusses,
                data: {
                    tbs: trackBuses,
                    instruments:
                        this.editorViewQuery.getValue().instrumentsJSON,
                },

                resolve: r,
            })
        })
    }

    private directlyDeleteSection({ section }: { section: Section }) {}

    /**
     * This function can be used to mark a section to be deleted by the Music Engine. It does not
     * actually delete the section from the score. The reasoning behind this is that the Music Engine
     * needs to be the one to do it so that it can properly update the section indices of the other sections
     * in the score metadata file that it is using at generation time.
     */
    private deleteSection({ section }: { section: Section }) {
        const score = this.query.score

        if (score === undefined) {
            return
        }

        if (section.title === "Insert section") {
            return this.removeSection({ section })
        }

        section.operation = SectionOperation.deleteSection(section.index)

        score.sections.forEach((s, i) => (s.index = i))

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
            metric: this.sectionEditingMetric(SECTION_EDITING.DELETE),
        })
    }

    /**
     * This action is for cancelling all types of section operations, except insertion of new section
     */
    private cancelSectionOperation({ section }: { section: Section }) {
        section.operation = undefined

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
        })
    }

    /**
     * This function can be used to remove a section from the score. This is not what you should use in case you want
     * to mark the section to be deleted by the Music Engine as part of the section editing feature provided in the
     * pianoroll editor component
     */
    private removeSection({ section }: { section: Section }) {
        const score = this.query.score

        if (score === undefined) {
            return
        }

        ScoreManipulation.removeSection(this.query.score, section)
        score.sections.forEach((s, i) => (s.index = i))

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
                skipCachedCanvas: true,
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
        })
    }

    private toggleChannelPlayback({
        layer,
        pattern,
        channel,
        playback,
    }: {
        layer: PercussionLayer
        pattern: Pattern
        channel: Channel
        playback: "mute" | "solo"
    }) {
        const result = this.hasChannel(layer, pattern, channel.id)

        if (!result.channel) {
            return
        }

        if (playback === "mute") {
            result.channel.mute = !result.channel.mute
            result.channel.solo = false
        } else {
            result.channel.solo = !result.channel.solo
            result.channel.mute = false
        }

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["Pattern"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private resetScore({
        keySignature,
        timeSignature,
        romanNumerals,
        tempo,
        drumSamples,
        instruments,
    }: {
        keySignature: KeySignature
        timeSignature: TimeSignature
        romanNumerals: TemplateChord[]
        tempo: number
        drumSamples: DrumSamplesMap
        instruments: InstrumentsJSON
    }) {
        for (const l in this.query.score.layers) {
            this.deleteLayer({
                layer: this.query.score.layers[l],
            })
        }

        const score = CompositionWorkflowModule.defaultScoreInitialization({
            keySignature,
            timeSignature,
            romanNumerals,
            tempo,
            drumSamples,
            lowestNote: 60,
            instruments,
        })

        this.store.updateStore({
            partial: {
                score,
                toggledLayer: undefined,
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
        })
    }

    private setEditorType({ editorType }) {
        this.store.updateStore({
            partial: {
                editorType,
            },
            scoreWasEdited: false,
            updateScoreLength: true,
        })
    }

    private copySectionChords({ from, to }: { from: Section; to: Section }) {
        const score = ScoreManipulation.copySectionChords(
            this.query.score,
            from,
            to
        )

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
                ...score,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private resizeSection({
        section,
        length,
        type,
    }: {
        section: Section
        length: number
        type: SectionResizeType
    }) {
        const score = ScoreManipulation.resizeSection(
            this.query.score,
            section,
            length,
            type
        )

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
                ...score,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private splitSection({ section }: { section: Section }) {
        const score = ScoreManipulation.splitSection(section, this.query.score)

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
                score,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private chordsPlusButtonClicked(): void {
        let score = this.query.score
        score = ScoreManipulation.addChordAtTheEndOfComposition(score)

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
                score,
            },
            scoreWasEdited: true,
            updateScoreLength: true,
        })
    }

    private setPatternChannel({
        layer,
        pattern,
        currentChannelID,
        newChannelName,
        pitchToChannelMapping,
    }: {
        layer: PercussionLayer
        pattern: Pattern
        currentChannelID: string
        newChannelName: string
        pitchToChannelMapping: PitchToChannelMapping
    }) {
        const result = this.hasChannel(layer, pattern, currentChannelID)

        if (!result.channel) {
            return
        }

        const pitches = Object.keys(pitchToChannelMapping).filter(
            (pitch: string) => pitchToChannelMapping[pitch] === newChannelName
        )

        result.channel.name = newChannelName
        result.channel.pitches = pitches.map((pitch: string) => parseInt(pitch))

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["Pattern"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setLayerName({ name, layer }: { name: string; layer: Layer }) {
        layer.name = name

        this.store.updateStore({
            partial: {
                scoreUpdate: ["Layer"],
                renderingType: ["None"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private addPattern({
        layer,
        pattern,
    }: {
        layer: PercussionLayer
        pattern?: Pattern
    }) {
        if (!this.hasLayer(layer)) {
            return
        }

        const foundLayer: PercussionLayer = <PercussionLayer>(
            this.query.score.layers[layer.value]
        )

        let maxPatternID = Math.max(...foundLayer.patterns.map(p => p.id))

        if (maxPatternID === -Infinity) {
            maxPatternID = 0
        }

        maxPatternID += 1

        let patternSelected

        if (pattern !== undefined) {
            const newPattern: Pattern = cloneDeep(pattern)
            newPattern.id = maxPatternID
            newPattern.name = "Pattern " + maxPatternID
            foundLayer.patterns.push(newPattern)

            patternSelected = newPattern
        } else {
            const newPattern = new Pattern(maxPatternID)
            foundLayer.patterns.push(newPattern)

            patternSelected = newPattern
        }

        this.setSelectedPattern({
            pattern: patternSelected,
            scoreWasEdited: true,
        })
    }

    private async followTimelineCursor({
        position,
        force,
    }: {
        position: "center" | "right"
        force: boolean
    }) {
        if (
            playerQuery.content === undefined ||
            this.editorViewQuery.timelineCursorElement === undefined
        ) {
            return
        }

        const width =
            this.editorViewQuery.timelineCursorElement?.getBoundingClientRect()
                .width

        const grid = this.editorViewQuery.grid
        const pxPerTimesteps =
            ScoreRenderingEngineMisc.computePxPerTimestepsForGrid(
                grid.pitched,
                TIMESTEP_RES
            )

        const widthInTimesteps = width / pxPerTimesteps

        const start = this.query.scrollToTimestep
        const end = start + widthInTimesteps

        let tsElapsed = Time.convertSecondsInTimesteps(
            playerQuery.timeElapsed,
            playerActions.hasStartOffset(),
            TIMESTEP_RES,
            this.query.score.tempoMap,
            "ScoreRenderingActions.followTimelineCursor"
        )

        if (position === "center") {
            tsElapsed -= widthInTimesteps / 2
        }

        if (force || tsElapsed <= start || tsElapsed >= end - 1) {
            this.setScroll({
                scrollToTimestep: Math.floor(tsElapsed),
                scrollToPitchsteps: this.query.scrollToPitchsteps,
                width,
            })
        }
    }

    private setLayerPreviewConfigs(configs: {
        skipAdjustments: boolean
        fillNotesWithLayerColor: boolean
    }): void {
        this.store.updateStore({
            partial: {
                layerPreviewConfigs: configs,
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private createTrackBusRegion({
        timesteps,
        trackBus,
        merge,
    }: {
        timesteps: number
        trackBus: TrackBus
        merge: boolean
    }) {
        const newRegion: RangeWithID = ScoreManipulation.createTrackBusRegion({
            timesteps,
            timeSignature: this.query.score.firstTimeSignature,
        })

        trackBus.blocks.push(newRegion)

        let selectedRegion = newRegion

        if (merge) {
            const result = ScoreManipulation.mergeTrackBusRegions(
                trackBus.blocks
            )
            trackBus.blocks = result.regions

            if (result.replacedWith[newRegion.id] !== undefined) {
                selectedRegion = trackBus.blocks.find(
                    b => b.id === result.replacedWith[newRegion.id]
                )
            }
        } else {
            trackBus.blocks.sort((a, b) => a.start - b.start)
        }

        this.setSelectedData({
            type: "TrackBusRegion",
            data: [
                {
                    region: selectedRegion,
                    side: HoveringTypeEnum.CENTER,
                },
            ],
        })

        this.store.updateStore({
            partial: {},
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private renamePattern({
        layer,
        pattern,
        name,
    }: {
        layer: PercussionLayer
        pattern: Pattern
        name: string
    }) {
        if (!this.hasLayer(layer)) {
            return
        }

        pattern.name = name

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["Pattern"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setPatternBars({
        layer,
        pattern,
        bars,
    }: {
        layer: PercussionLayer
        pattern: Pattern
        bars: BarCount
    }) {
        if (!this.hasLayer(layer)) {
            return
        }

        pattern.bars = bars

        this.store.updateStore({
            partial: {
                renderingType: [
                    "LayerPreviewCanvas_" + layer.value,
                    "DrumSequencerCanvas",
                    "PatternRegionsCanvas",
                    "PatternHorizontalScrollbarCanvas",
                ],
                scoreUpdate: ["Pattern"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setPatternResolution({
        layer,
        pattern,
        resolution,
    }: {
        layer: PercussionLayer
        pattern: Pattern
        resolution: string
    }) {
        if (!this.hasLayer(layer)) {
            return
        }

        pattern.resolution = resolution

        this.store.updateStore({
            partial: {
                renderingType: [
                    "LayerPreviewCanvas_" + layer.value,
                    "DrumSequencerCanvas",
                    "PatternRegionsCanvas",
                    "PatternHorizontalScrollbarCanvas",
                ],
                scoreUpdate: ["Pattern"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private deleteChannel({
        layer,
        pattern,
        channelID,
    }: {
        layer: PercussionLayer
        pattern: Pattern
        channelID: string
    }) {
        const result = this.hasChannel(layer, pattern, channelID)

        if (!result.channel) {
            return
        }

        const index = result.pattern.channels.indexOf(result.channel)
        result.pattern.channels.splice(index, 1)

        this.store.updateStore({
            partial: {
                renderingType: [
                    "LayerPreviewCanvas_" + layer.value,
                    "DrumSequencerCanvas",
                    "PatternRegionsCanvas",
                ],
                scoreUpdate: ["Pattern"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private deletePattern({
        layer,
        pattern,
    }: {
        layer: PercussionLayer
        pattern: Pattern
    }) {
        if (!this.hasLayer(layer)) {
            return
        }

        const foundLayer: PercussionLayer = <PercussionLayer>(
            this.query.score.layers[layer.value]
        )
        const patternIndex = foundLayer.patterns.findIndex(
            p => p.id === pattern.id
        )

        if (patternIndex !== -1) {
            const selectedPattern = foundLayer.patterns[patternIndex]
            foundLayer.patterns.splice(patternIndex, 1)

            if (selectedPattern === this.query.selectedPattern) {
                if (foundLayer.patterns.length === 0) {
                    this.addPattern({ layer })
                }

                this.setSelectedPattern({
                    pattern: foundLayer.patterns[0],
                    scoreWasEdited: true,
                })
            }
        }
    }

    private selectTrackBus({
        tb,
        keepPreviousSelection,
    }: {
        tb: TrackBus
        keepPreviousSelection: boolean
    }) {
        let data = [tb]

        if (
            keepPreviousSelection &&
            this.query.selectedTrackBusses.length > 0
        ) {
            const firstTBIndex = this.query.toggledLayer.trackBuses.findIndex(
                t => t.id === this.query.selectedTrackBusses[0].id
            )
            const index = this.query.toggledLayer.trackBuses.findIndex(
                t => t.id === tb.id
            )

            data = this.query.toggledLayer.trackBuses.slice(
                Math.min(firstTBIndex, index),
                Math.max(firstTBIndex, index) + 1
            )
        }

        this.setSelectedData({
            data: data,
            type: "TrackBus",
        })
    }

    private startNoteManipulation(): void {
        // empty method
    }

    private setLayerColor({ color, layer }: { color: Colors; layer: Layer }) {
        layer.color = color

        this.store.updateStore({
            partial: {
                scoreUpdate: ["Layer"],
                renderingType: ["All"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private copyAutomationToOtherLayers({
        effect,
        layer,
    }: {
        effect: Effect
        layer: Layer
    }) {
        if (!this.hasScore()) {
            return
        }

        const score = this.query.score

        ScoreManipulation.copyAutomationToOtherLayers(score, effect.name, layer)

        this.store.updateStore({
            partial: {
                renderingType: ["AutomationCanvas"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private toggleEffect({ effect }: { effect: Effect }) {
        effect.active = !effect.active

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private deleteSelectedData() {
        if (
            this.query.score === undefined ||
            this.query.toggledLayer === undefined
        ) {
            return
        }

        const renderingType = [
            "TimelineCanvas",
            "LayerPreviewCanvas_" + this.query.toggledLayer.value,
        ]

        const selectedData = this.query.getValue().selectedData

        let newSelectedData: ScoreRenderingSelectedDataType = {
            type: "None",
            data: undefined,
        }

        const scoreUpdate = []
        let metric: ActivityMetric | undefined = undefined

        if (selectedData.type === "None") {
            return
        } else if (selectedData.type === "Note") {
            this.deleteNotes({
                notes: this.query.selectedNotes,
                layer: this.query.toggledLayer,
            })

            renderingType.push("AccompanimentDesignerCanvas")
        } else if (selectedData.type === "TrackBusRegion") {
            ScoreManipulation.deleteTrackBusRegions({
                layer: this.query.toggledLayer,
                trackBusRegions: selectedData.data.map(tbr => tbr.region),
            })

            renderingType.push("TrackbusRegionsCanvas")
        } else if (selectedData.type === "TrackBus") {
            const deletedTrackBusses = this.deleteSelectedTrackBus()

            if (!deletedTrackBusses) {
                newSelectedData = {
                    type: "TrackBus",
                    data: selectedData.data,
                }
            } else {
                scoreUpdate.push("TrackBus")
                scoreUpdate.push("Pattern")
                renderingType.push("DrumSequencerCanvas")

                if (this.query.toggledLayer.trackBuses.length > 0) {
                    newSelectedData = {
                        type: "TrackBus",
                        data: [this.query.toggledLayer.trackBuses[0]],
                    }
                }
            }

            renderingType.push("PatternRegionsCanvas", "TrackbusRegionsCanvas")
            metric = this.instrumentEditingMetric("delete")
        } else if (selectedData.type === "PatternRegion") {
            ScoreManipulation.deletePatternRegions({
                layer: this.query.toggledLayer,
                patternRegions: selectedData.data,
            })

            renderingType.push("PatternRegionsCanvas")
        }

        this.store.updateStore({
            partial: {
                selectedData: newSelectedData,
                renderingType,
                scoreUpdate,
                skipCachedCanvas: true,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
            computeAdditionalMetadata: true,
            metric,
        })
    }

    private clearNotesFromSection({ section }: { section: Section }) {
        const noteIDsToRemove = ScoreManipulation.removeNotesForSection(
            section,
            this.query.score
        )

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.pauseNotesByIDs,
            data: {
                noteIDs: noteIDsToRemove,
            },
        })

        this.store.updateStore({
            partial: {
                renderingType: [
                    "AccompanimentDesignerCanvas",
                    "LayerPreviewCanvas",
                ],
                scoreUpdate: ["Layer"],
                skipCachedCanvas: true,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
            computeAdditionalMetadata: true,
        })
    }

    private deleteNotes({
        notes,
        layer,
    }: {
        notes: NotesObject
        layer: Layer
    }) {
        if (this.query.score === undefined) {
            return
        }

        const notesToDelete: NotesObject = ScoreManipulation.deleteNotes({
            score: this.query.score,
            layer,
            notes,
        })

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.pauseNotesByIDs,
            data: {
                noteIDs: notesToDelete.getAllNoteIDs(),
            },
        })

        this.store.updateStore({
            partial: {
                renderingType: [
                    "AccompanimentDesignerCanvas",
                    "LayerPreviewCanvas",
                ],
                scoreUpdate: ["Layer"],
                skipCachedCanvas: true,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
            computeAdditionalMetadata: true,
            metric: this.createNoteMetric("delete"),
        })
    }

    /**
     * updates the layer type from one type to another
     * is mainly used in the accompaniment designer context because
     * here we rely on only one layer per score
     * @param currentType LayerType that is currently set in the score
     * @param newType LayerType that will be set in the score
     * @returns
     */
    private updateLayerType({
        currentType,
        newType,
    }: {
        currentType: LayerType
        newType: LayerType
    }) {
        if (
            this.query.score === undefined ||
            this.query.toggledLayer === undefined ||
            this.query.score.layers[currentType] === undefined
        ) {
            return
        }

        const score = ScoreManipulation.updateLayerType({
            score: this.query.score,
            currentType: currentType,
            newType: newType,
        })

        this.store.updateStore({
            partial: {
                score: score,
                renderingType: [
                    "AccompanimentDesignerCanvas",
                    "PianoRollGridCanvas",
                    "TimelineCanvas",
                ],
                scoreUpdate: ["Layer"],
                toggledLayer: newType,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    /**
     * updates the layer type from one type to another
     * is mainly used in the accompaniment designer context because
     * here we rely on only one layer per score
     * @param currentType LayerType that is currently set in the score
     * @param newType LayerType that will be set in the score
     * @returns
     */
    private updateKeySignature({
        keySignature,
        startOctave,
        updatePitches,
    }: {
        keySignature: string
        startOctave?: number
        updatePitches?: boolean
    }) {
        if (this.query.score === undefined) {
            return
        }

        const score = ScoreManipulation.updateKeySignature({
            score: this.query.score,
            keySignature: keySignature,
            startOctave: startOctave,
            updatePitches: updatePitches,
        })

        this.store.updateStore({
            partial: {
                score: score,
                renderingType: [
                    "PianoCanvas",
                    "AccompanimentDesignerCanvas",
                    "PianoRollGridCanvas",
                    "TimelineCanvas",
                ],
                scoreUpdate: ["Layer", "ScoreMetadata"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setScoreFX({
        type,
        value,
    }: {
        type: "vinyl" | "bass_boost"
        value: boolean
    }) {
        this.query.score.effects[type] = value

        this.store.updateStore({
            partial: {},
            scoreWasEdited: true,
            updateScoreLength: false,
        })

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditEverythingExceptNotes,
        })
    }

    private replaceTrackBus({
        instruments,
        patchID,
        layer,
        tb,
    }: {
        instruments: InstrumentsJSON
        patchID: string
        layer: Layer
        tb: TrackBus
    }) {
        const section = patchID.split(".")[0]
        const instrumentName = patchID.split(".")[1]
        const reference: InstrumentJSON = instruments[section].find(
            i => i.name === instrumentName
        )

        const currentTrackBusID = tb.id

        const newTB = new TrackBus(
            patchID,
            tb.octave,
            reference,
            tb.blocks,
            tb.dynamicOffset,
            tb.gainOffset,
            tb.breathingGain,
            tb.panning,
            tb.mute,
            tb.solo
        )

        const order = layer.trackBuses.findIndex(t => t.id === tb.id)

        newTB.autoPedal = ScoreManipulation.isKeyboard(
            newTB.name,
            newTB.reference
        )

        this.addTrackBus({
            layer,
            instrument: reference,
            order,
            trackBus: newTB,
        })

        // It's important to delete the trackbus after adding the new one, otherwise the
        // addTrackBus action will add new patterns to the score in case there was only
        // one trackbus in the layer (which is expected behavior)
        this.deleteTrackBusses({
            layer,
            trackBusses: [tb],
        })

        newTB.id = currentTrackBusID

        this.store.updateStore({
            partial: {
                scoreUpdate: ["TrackBus", "Pattern"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    /**
     * The expected behavior of this function is to first delete any data associated with the trackbusses
     * (i.e. trackbus regions or pattern regions) and only then delete the trackbusses themselves.
     * This means it will take two successive deletion action from the user to delete the trackbusses.
     * @returns Whether the trackbusses were actually deleted or not
     */
    private deleteSelectedTrackBus(): boolean {
        const layer = this.query.toggledLayer
        const data = this.query.selectedTrackBusses

        if (layer === undefined) {
            return false
        } else if (
            layer.type === "percussion" &&
            (<PercussionLayer>layer).trackBuses.length === 1 &&
            (<PercussionLayer>layer).patternRegions.length > 0
        ) {
            ScoreManipulation.deletePatternRegions({
                layer,
                patternRegions: (<PercussionLayer>layer).patternRegions,
            })

            return false
        } else if (
            layer.type === "pitched" &&
            data.some((tb: TrackBus) => tb.blocks.length > 0)
        ) {
            const trackBusRegions = data
                .map((tb: TrackBus) => tb.blocks)
                .reduce((prev, curr) => prev.concat(curr))

            ScoreManipulation.deleteTrackBusRegions({
                layer,
                trackBusRegions,
            })

            return false
        }

        this.deleteTrackBusses({
            layer,
            trackBusses: data,
        })

        return true
    }

    private async deleteTrackBusses({
        layer,
        trackBusses,
    }: {
        layer: Layer
        trackBusses: TrackBus[]
    }) {
        const layerTbs = [...trackBusses]

        ScoreManipulation.deleteTrackBusses({
            layer,
            trackBusses,
        })

        const tbsToUnload = ScoreManipulation.getTrackBussesToUnload(
            layerTbs,
            this.query.score
        )

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.unloadTrackBusses,
            data: {
                tbs: tbsToUnload,
            },
        })

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.pauseTrackBusses,
            data: {
                tbs: trackBusses,
            },
        })

        this.store.updateStore({
            partial: {
                renderingType: ["TrackbusRegionsCanvas"],
                scoreUpdate: ["TrackBus", "Pattern"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private selectSection({
        section,
        coordinates,
    }: {
        section: Section | undefined
        coordinates: Coordinates | undefined
    }) {
        const store = this.store.getValue()

        if (store.selectedSection.section !== section) {
            if (section?.title === "+") {
                const sections = this.query.score.sections
                const lastSection = sections[sections.length - 1]

                this.manager.emitter$.next({
                    type: SRActionTypes.addSection,
                    data: {
                        type: "blank",
                        section: lastSection,
                        insertPosition: "after",
                        sectionLength: 8,
                        sourceSectionIndex: undefined,
                    },
                    options: {
                        isUndoable: true,
                    },
                })
                return
            }
            this.store.updateStore({
                partial: {
                    selectedSection: {
                        coordinates,
                        section,
                    },
                    renderingType: ["PianorollGridCanvas"],
                },
                scoreWasEdited: false,
                updateScoreLength: false,
            })
        }
    }

    private resizeTrackBusRegion({
        side,
        trackBus,
        timestepOffset,
    }: {
        side: HoveringType
        timestepOffset: number
        trackBus: TrackBus
    }) {
        const regions = cloneDeep(this.query.selectedTrackBusRegion)

        if (side === HoveringTypeEnum.CENTER) {
            return
        }

        const type = ScoreManipulation.getQuantizationType(
            this.query.resizeFactor,
            NOTE_QUANTIZATION_THRESHOLDS
        )

        const result = regions.map(r =>
            ScoreManipulation.resizeTrackBusRegion(
                trackBus,
                r.region.id,
                side,
                timestepOffset,
                Time.fractionToTimesteps(
                    TIMESTEP_RES,
                    this.query.score.scoreLength
                ),
                type,
                this.query.score.firstTimeSignature
            )
        )

        this.store.updateStore({
            partial: {
                renderingType: ["TrackbusRegionsCanvas"],
                selectedData: {
                    type: "TrackBusRegion",
                    data: result.map(r => {
                        return {
                            region: r.region,
                            side: r.side,
                        }
                    }),
                },
            },
            scoreWasEdited: true,
            updateScoreLength: true,
        })
    }

    private stopResizingTrackBusRegions({ tbs }: { tbs: TrackBus[] }) {
        if (tbs.length === 0) {
            return
        }

        tbs.forEach(tb => {
            const result = ScoreManipulation.mergeTrackBusRegions(tb.blocks)
            tb.blocks = result.regions
        })

        this.store.updateStore({
            partial: {
                renderingType: ["TrackbusRegionsCanvas"],
                scoreUpdate: ["TrackBus"],
            },
            scoreWasEdited: true,
            updateScoreLength: true,
        })
    }

    private toggleTrackBussesMute({
        layer,
        trackBusses,
        type,
    }: {
        layer: Layer
        trackBusses: TrackBus[]
        type: "mute" | "solo"
    }) {
        if (trackBusses.length === 0) {
            return
        }

        for (const tb of trackBusses) {
            const selectedTB = layer.trackBuses.find(t => t.id === tb.id)

            if (!selectedTB) {
                continue
            }

            if (type === "mute") {
                selectedTB.mute = !selectedTB.mute
                selectedTB.solo = false
            } else {
                selectedTB.mute = false
                selectedTB.solo = !selectedTB.solo
            }
        }

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditEverythingExceptNotes,
            data: {},
        })

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["Layer", "TrackBusMetadata"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private deleteLayer({ layer }: { layer: Layer }) {
        const score = this.query.score

        if (score === undefined) {
            return
        }

        if (this.query.toggledLayer?.value === layer.value) {
            this.toggleLayer({ toggledLayer: undefined })
        }

        if (
            this.query.visiblePitchedLayers.find(l => l.value === layer.value)
        ) {
            this.toggleLayerAsVisible({ layer })
        }

        this.deleteTrackBusses({ layer, trackBusses: layer.trackBuses })

        ScoreManipulation.deleteLayer(score, layer)

        this.store.updateStore({
            partial: {
                score,
                renderingType: ["LayerPreviewCanvas"],
                scoreUpdate: ["Layer", "TrackBus"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
            computeAdditionalMetadata: true,
        })
    }

    private moveTrackBusRegion({
        id,
        timestepOffset,
        trackBus,
    }: {
        id: string
        timestepOffset: number
        trackBus: TrackBus
    }) {
        const quantization = ScoreManipulation.getQuantizationType(
            this.query.resizeFactor,
            NOTE_QUANTIZATION_THRESHOLDS
        )

        const region = ScoreManipulation.moveTrackBusRegion(
            trackBus,
            id,
            timestepOffset,
            this.query.score.firstTimeSignature,
            quantization
        )

        const selectedRegion = region
            ? {
                  region: region,
                  side: HoveringTypeEnum.CENTER,
              }
            : undefined

        this.store.updateStore({
            partial: {
                renderingType: ["TrackbusRegionsCanvas"],
                selectedData: {
                    type: "TrackBusRegion",
                    data: [selectedRegion],
                },
            },
            scoreWasEdited: true,
            updateScoreLength: true,
        })
    }

    private selectTrackBusRegion({
        region,
    }: {
        region: RangeWithID | undefined
    }) {
        this.setSelectedData({
            data: region
                ? [
                      {
                          region: cloneDeep(region),
                          side: HoveringTypeEnum.CENTER,
                      },
                  ]
                : [],
            type: "TrackBusRegion",
        })
    }

    private mute({ layer }: { layer: Layer }) {
        if (!this.query.score) {
            return
        }

        this.query.score.layers[layer.value] =
            ScoreManipulation.muteLayer(layer)

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditEverythingExceptNotes,
            data: {},
        })

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["Layer", "TrackBus"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private solo({ layer }: { layer: Layer }) {
        if (!this.query.score) {
            return
        }

        this.query.score.layers[layer.value] =
            ScoreManipulation.soloLayer(layer)

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditEverythingExceptNotes,
            data: {},
        })

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["Layer", "TrackBus"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setConstantAutomation({ value }: { value: number }) {
        if (!this.query.score) {
            return
        }

        if (this.query.selectedAutomation === undefined) {
            return
        }

        const effect: Effect = this.query.selectedAutomation

        value = Math.min(effect.max, value)
        value = Math.max(effect.min, value)

        const tsLength = Time.fractionToTimesteps(
            AUTOMATION_TIMESTEP_RES,
            this.query.score.scoreLength
        )

        this.setAutomationValue({
            effect,
            timestepRange: [0, tsLength],
            automationStepRange: [value, value],
        })
    }

    private setAutomationValue({
        effect,
        timestepRange,
        automationStepRange,
    }: {
        effect: Effect
        timestepRange: [number, number]
        automationStepRange: [number, number]
    }) {
        const values: number[] = ScoreManipulation.setAutomationValue(
            effect,
            timestepRange,
            automationStepRange,
            false
        )

        effect.values = values

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveAutomation,
            data: {
                layer: this.query.toggledLayer,
                timestepRange,
            },
        })

        this.store.updateStore({
            partial: {
                renderingType: ["AutomationCanvas"],
                scoreUpdate: ["Effect"],
                drewAutomation: true,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
            metric: createAutomationEditingMetric(
                this.query.toggledLayer.value,
                effect.name,
                this.query.score.compositionID
            ),
        })
    }

    private setRenderingType({ type }: { type: CanvasType }) {
        this.store.updateStore({
            partial: {
                renderingType: [type],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private setAccompanimentDesignerScoreLength({
        scoreLength,
        notesToRemove,
    }: {
        scoreLength: FractionString
        notesToRemove: NotesObject
    }) {
        const score = this.query.getValue().score
        score.scoreLength = scoreLength

        if (notesToRemove.length !== 0) {
            this.deleteNotes({
                notes: notesToRemove,
                layer: this.query.toggledLayer,
            })
        }

        const newSelectedNotes = new NotesObject()

        if (this.query.selectedNotes.length > 0) {
            for (let note of this.query.selectedNotes.getFlatArray()) {
                const noteGroup = score.layers[
                    this.query.toggledLayer.value
                ].notesObject.getNoteGroup(note.start)

                if (!noteGroup?.length) {
                    continue
                }

                for (let n of noteGroup) {
                    if (note.pitch === n.pitch) {
                        newSelectedNotes.addNoteToGroup(
                            n,
                            score.firstTimeSignature,
                            score.sections
                        )
                    }
                }
            }
        }

        this.store.updateStore({
            partial: {
                score: score,
                renderingType: ["All"],
                selectedData: {
                    type: "Note",
                    data: newSelectedNotes,
                },
            },
            scoreWasEdited: true,
            updateScoreLength: true,
        })

        playerActions.setRealtimeDuration(
            "setAccompanimentDesignerScoreLength",
            Time.convertTimestepsInSeconds(
                TIMESTEP_RES,
                score.tempoMap,
                Time.fractionToTimesteps(TIMESTEP_RES, score.scoreLength),
                playerActions.hasStartOffset()
            )
        )
    }

    private breakUpTrackBusRegions({
        timesteps,
        trackBus,
    }: {
        timesteps: number
        trackBus: TrackBus
    }) {
        ScoreManipulation.divideTrackBusRegion({
            timesteps,
            trackBus,
            timeSignature: this.query.score.firstTimeSignature,
        })

        trackBus.blocks = ScoreManipulation.mergeTrackBusRegions(
            trackBus.blocks
        ).regions

        this.store.updateStore({
            partial: {
                renderingType: ["TrackbusRegionsCanvas"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setScroll(args: {
        scrollToPitchsteps: number
        scrollToTimestep: number
        width: number
    }) {
        if (!this.query.score) {
            return
        }

        const grid = this.editorViewQuery.grid

        // Since the noteRes in the scale mode might be different from the default TIMESTEP_RES,
        // we need to adjust the timestepRes and use this custom resolution for the upcoming computations.
        const timestepRes =
            this.query.all.pitchStepDomain === "continuous"
                ? TIMESTEP_RES
                : grid.pitched.timestepRes

        const pxPerTimestep =
            ScoreRenderingEngineMisc.computePxPerTimestepsForGrid(
                grid.pitched,
                timestepRes
            )

        const range = ScoreRenderingEngineMisc.getTimestepRange({
            grid: grid.pitched,
            timestepRes: timestepRes,
            width: args.width,
            pxPerTimestep,
        })

        if (
            grid.pitched.scoreLengthTimesteps <= range &&
            args.scrollToPitchsteps === this.query.scrollToPitchsteps
        ) {
            return this.resetScroll({ timesteps: true, pitchsteps: false })
        }

        const widthInTS = args.width / pxPerTimestep

        const timestepScoreLength = Time.fractionToTimesteps(
            TIMESTEP_RES,
            this.query.score.scoreLength
        )

        const pitchCount =
            this.query.all.pitchStepDomain === "scale"
                ? PITCH_SCALE_COUNT
                : PITCH_CONTINOUS_COUNT

        const maxValue =
            this.query.all.pitchStepDomain === "continuous"
                ? Note.highestNote - Note.lowestNote - pitchCount
                : 0 // for now, we don't want to allow scrolling for continous mode

        args.scrollToPitchsteps = Math.min(maxValue, args.scrollToPitchsteps)

        args.scrollToTimestep = Math.min(
            Math.max(0, timestepScoreLength - widthInTS),
            Math.max(-1 / 2, args.scrollToTimestep)
        )

        if (
            args.scrollToTimestep === this.query.scrollToTimestep &&
            args.scrollToPitchsteps === this.query.scrollToPitchsteps
        ) {
            return
        } else if (args.scrollToTimestep !== this.query.scrollToTimestep) {
            this.store.updateStore({
                partial: {
                    scrollToTimestep: args.scrollToTimestep,
                    scrollToPitchsteps: args.scrollToPitchsteps,
                    renderingType: ["All"],
                },
                scoreWasEdited: false,
                updateScoreLength: false,
            })
        } else {
            this.store.updateStore({
                partial: {
                    scrollToPitchsteps: args.scrollToPitchsteps,
                    renderingType: [
                        "PianoCanvas",
                        "AccompanimentDesignerCanvas",
                        "VerticalScrollingCanvas",
                        "TimelineCanvas",
                    ],
                },
                scoreWasEdited: false,
                updateScoreLength: false,
            })
        }
    }

    private setPatternScroll(args: { patternScrollToTimestep: number }) {
        if (!this.query.selectedPattern) {
            return
        }

        const pattern: Pattern = this.query.selectedPattern

        const scoreLengthTimesteps = Time.convertTimestepsToAnotherRes(
            Time.barToTimestep(
                pattern.bars,
                this.query.score.timeSignatures[0][1],
                pattern.timestepRes
            ),
            pattern.timestepRes,
            TIMESTEP_RES
        )

        args.patternScrollToTimestep = Math.min(
            scoreLengthTimesteps,
            Math.max(0, args.patternScrollToTimestep)
        )

        if (
            args.patternScrollToTimestep === this.query.patternScrollToTimestep
        ) {
            return
        }

        this.store.updateStore({
            partial: {
                patternScrollToTimestep: args.patternScrollToTimestep,
                renderingType: ["All"],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private moveNotesWithKeyboard({
        direction,
    }: {
        direction: "ArrowUp" | "ArrowDown" | "ArrowLeft" | "ArrowRight"
    }) {
        if (this.query.selectedNotes.length === 0) {
            return
        }

        if (this.store.getValue().harmonyLock) {
            return
        }

        const selectedNoteID =
            this.query.selectedNotes.getFirstGroup()[0].noteID

        const pitchDelta =
            direction === "ArrowUp" ? 1 : direction === "ArrowDown" ? -1 : 0
        const timestepDelta =
            direction === "ArrowRight" ? 1 : direction === "ArrowLeft" ? -1 : 0

        const keySignature = Score.getKeySignatureObject(
            this.query.score?.keySignatures[0][1]
        )
        const scale =
            this.query.all.pitchStepDomain === "scale"
                ? KeySignatureModule.getTriadScalePitches(keySignature)
                : undefined

        this.moveNotes({
            pitchDelta,
            timestepDelta,
            selectedNoteID,
            enableQuantization: false,
            scale,
        })

        this.endManipulatingNotes({
            removeOverlappingNotes: true,
        })
    }

    private isFirstMove() {
        let isFirstMove = false

        if (!this.query.noteManipulationStarted) {
            this.manager.emitter$.next({
                type: SRActionTypes.startNoteManipulation,
                options: {
                    isUndoable: true,
                },
            })
            isFirstMove = true
        }

        return isFirstMove
    }

    private moveNotes({
        pitchDelta,
        timestepDelta,
        selectedNoteID,
        enableQuantization,
        scale,
        timestepRange,
    }: {
        pitchDelta: number
        timestepDelta: number
        selectedNoteID: string
        enableQuantization: boolean
        timestepRange?: [number, number]
        scale?: number[] | undefined
    }) {
        const store = this.store.getValue()
        const score = store.score
        const layer = this.query.toggledLayer
        const timeSignature = score.timeSignatures[0][1]
        const timestepResolution = store.userSelectedTimestepRes

        if (!this.query.selectedNotes?.length || !score || !layer) {
            return
        }

        this.isFirstMove()

        const selectedNotes = this.query.selectedNotes

        selectedNotes.forEach(n =>
            layer.notesObject.removeNoteFromGroup(n.start, n.noteID)
        )

        const type = enableQuantization
            ? ScoreManipulation.getQuantizationType(
                  this.query.resizeFactor,
                  NOTE_QUANTIZATION_THRESHOLDS
              )
            : undefined

        const newSelectedNotes = ScoreManipulation.moveNotes({
            selectedNoteID,
            selectedNotes,
            timeSignature,
            timestepDelta,
            pitchDelta,
            score,
            type,
            timestepRes: timestepResolution,
            scale: scale,
        })

        this.updateStoreAfterMovingNotes(
            newSelectedNotes,
            pitchDelta,
            selectedNoteID,
            timestepRange
        )
    }

    private setScoreWasEdited() {
        this.store.updateStore({
            partial: {},
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    /**
     * This function unselects other note groups for some specific states, like
     * when the harmony lock feature is enabled.
     */
    private unselectOtherNoteGroups({
        selectedNoteID,
    }: {
        selectedNoteID: string
    }) {
        const shouldUnselectOtherNoteGroups = this.query.getValue().harmonyLock

        if (!shouldUnselectOtherNoteGroups) {
            return
        }
    }

    private updateStoreAfterMovingNotes(
        newSelectedNotes: NotesObject,
        pitchDelta: number,
        selectedNoteID: string,
        timestepRange?: [number, number]
    ) {
        if (pitchDelta !== 0) {
            this.realtimePlayer.emitter$.next({
                type: RTSamplerActionTypes.liveEditNotes,
                data: {
                    selectedNotes: newSelectedNotes,
                    layer: this.query.toggledLayer,
                    getSelectedNoteGroups: false,
                    previewWhenPaused: true,
                    selectedNoteID: selectedNoteID,
                },
            })
        }

        let overlappingNotes: NotesObject
        if (featureFlags.useFractionClass) {
            overlappingNotes = ScoreManipulation.detectOverlappingNotes2({
                selectedNotes: newSelectedNotes,
                layer: this.query.toggledLayer,
                timeSignature: this.store.getValue().score.firstTimeSignature,
                score: this.store.getValue().score,
                includePitchDetection: false,
                visibleTimeStepRange: timestepRange,
            })
        } else {
            overlappingNotes = ScoreManipulation.detectOverlappingNotes({
                selectedNotes: newSelectedNotes,
                layer: this.query.toggledLayer,
                timeSignature: this.store.getValue().score.firstTimeSignature,
                score: this.store.getValue().score,
                includePitchDetection: false,
                visibleTimeStepRange: timestepRange,
            })
        }

        this.store.updateStore({
            partial: {
                selectedData: {
                    type: "Note",
                    data: newSelectedNotes,
                },
                overlappingNotes,
                renderingType: ["AccompanimentDesignerCanvas"],
                scoreUpdate: ["Note"],
                noteManipulationStarted: "move",
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: false,
        })
    }

    private createNoteMetric(type: "add" | "delete" | "paste" | "modify") {
        return createNoteEditingMetric(
            this.query.toggledLayer.value,
            type,
            this.query.score.compositionID
        )
    }

    private moveSingleNote({
        pitchDelta,
        timestepDelta,
        timesteps,
        timestepRange,
        selectedNoteID,
        scale,
    }: {
        pitchDelta: number
        timestepDelta: number
        timesteps: number
        timestepRange: [number, number]
        selectedNoteID: string
        scale?: number[] | undefined
    }) {
        const store = this.store.getValue()
        const score = store.score
        const layer = this.query.toggledLayer
        const timeSignature = score.timeSignatures[0][1]
        const timestepResolution = store.userSelectedTimestepRes

        if (!this.query.selectedNotes?.length || !score || !layer) {
            return
        }

        const isFirstMove = this.isFirstMove()

        const selectedNotes = this.query.selectedNotes

        selectedNotes.forEach(n =>
            layer.notesObject.removeNoteFromGroup(n.start, n.noteID)
        )

        const quantization = ScoreManipulation.getQuantizationType(
            this.query.resizeFactor,
            NOTE_QUANTIZATION_THRESHOLDS
        )

        const newSelectedNotes = ScoreManipulation.moveSingleNote({
            selectedNotes,
            timeSignature,
            timestepDelta,
            pitchDelta,
            layer,
            score,
            timestepRes: timestepResolution,
            timesteps,
            timestepRange,
            isFirstMove,
            type: quantization,
            scale,
            chords: this.query.score.chords,
        })

        let overlappingNotes: NotesObject

        if (featureFlags.useFractionClass) {
            overlappingNotes = ScoreManipulation.detectOverlappingNotes2({
                selectedNotes: newSelectedNotes,
                layer,
                timeSignature,
                score,
                includePitchDetection: false,
                visibleTimeStepRange: timestepRange,
            })
        } else {
            overlappingNotes = ScoreManipulation.detectOverlappingNotes({
                selectedNotes: newSelectedNotes,
                layer,
                timeSignature,
                score,
                includePitchDetection: false,
                visibleTimeStepRange: timestepRange,
            })
        }

        if (pitchDelta !== 0) {
            this.realtimePlayer.emitter$.next({
                type: RTSamplerActionTypes.liveEditNotes,
                data: {
                    selectedNotes: Object.assign(newSelectedNotes, NotesObject),
                    layer,
                    getSelectedNoteGroups: false,
                    previewWhenPaused: true,
                    selectedNoteID: selectedNoteID,
                },
            })
        }

        this.store.updateStore({
            partial: {
                selectedData: {
                    type: "Note",
                    data: newSelectedNotes,
                },
                overlappingNotes,
                renderingType: [
                    "AccompanimentDesignerCanvas",
                    "TimelineCanvas",
                    // "LayerPreviewCanvas_" + layer.value,
                ],
                scoreUpdate: ["Note"],
                noteManipulationStarted: "move",
            },
            scoreWasEdited: true,
            updateScoreLength: true,
        })
    }

    private resizeNotes({
        timestepOffset,
        timestepResolution,
        resizeType,
        maxScoreLength,
        selectedNoteID,
    }: {
        timestepOffset: number
        timestepResolution: number
        resizeType: HoveringType
        maxScoreLength?: FractionString
        selectedNoteID: string
    }) {
        const store = this.store.getValue()
        const score = store.score
        const timeSignature = score.firstTimeSignature

        let selectedNotes = store.harmonyLock
            ? ScoreManipulation.removeNotesFromOtherGroups({
                  selectedNoteID,
                  notes: this.query.selectedNotes,
                  timeSignature,
                  sections: score.sections,
              })
            : this.query.selectedNotes

        const layer = this.query.toggledLayer

        if (!selectedNotes || !selectedNotes?.length || !layer || !score) {
            return
        }

        // We don't rely on the reference of resizeNote matching that of note instances in layer.notesObject
        // Instead, we look for a note that has the same ID. If we can't find one, then
        // resizing note shouldn't happen and we should throw an exception since that's not expected behavior
        const newResizedNote = selectedNotes.getFirstGroup()[0]

        const isFirstResize = this.isFirstMove()

        if (!newResizedNote) {
            throw "ScoreRenderingActions - resizeNotes: could not find selectedNote"
        }

        let newResizeType: HoveringType
        let isSingleGroup =
            selectedNotes.getFirstGroup() === selectedNotes.getLastGroup()

        if (isSingleGroup) {
            // remove notes from the layer and add them back again later in a different action
            const layerNotesObject = layer.notesObject

            // Here we make sure to have all notes of a selected note group
            // as our selected notes (e.g. when drawing, we only have a single selected note, but want to
            // resize the whole noteGroup instead)
            if (isFirstResize) {
                const start = selectedNotes?.getFirstGroup()[0]?.start
                const notesInFirstGroup = layerNotesObject.getNoteGroup(start)

                if (notesInFirstGroup) {
                    for (let note of notesInFirstGroup) {
                        selectedNotes.addNoteToGroup(
                            note,
                            timeSignature,
                            score?.sections
                        )
                    }
                }
            }

            selectedNotes.forEach(n =>
                layerNotesObject.removeNoteFromGroup(n.start, n.noteID)
            )
        }

        const quantization = ScoreManipulation.getQuantizationType(
            this.query.resizeFactor,
            NOTE_QUANTIZATION_THRESHOLDS
        )

        ;[selectedNotes, newResizeType] = ScoreManipulation.resizeNotes({
            selectedNotes,
            resizedNote: newResizedNote,
            layer,
            timestepRes: timestepResolution,
            timestepOffset,
            resizeType,
            timeSignature,
            score,
            isFirstResize,
            type: quantization,
            maxScoreLength,
        })

        let overlappingNotes: NotesObject

        if (featureFlags.useFractionClass) {
            overlappingNotes = ScoreManipulation.detectOverlappingNotes2({
                selectedNotes,
                layer,
                timeSignature,
                score,
                includePitchDetection: false,
                visibleFractionRange: [
                    new Fraction(selectedNotes.getFirstGroup()[0].start),
                    new Fraction(selectedNotes.getLastGroup()[0].getEnd()),
                ],
            })
        } else {
            overlappingNotes = ScoreManipulation.detectOverlappingNotes({
                selectedNotes,
                layer,
                timeSignature,
                score,
                includePitchDetection: false,
                visibleFractionRange: [
                    selectedNotes.getFirstGroup()[0].start,
                    selectedNotes.getLastGroup()[0].getEnd(),
                ],
            })
        }

        this.store.updateStore({
            partial: {
                renderingType: ["AccompanimentDesignerCanvas"],
                overlappingNotes,
                selectedData: {
                    type: "Note",
                    data: selectedNotes,
                },
                scoreUpdate: ["Note"],
                noteManipulationStarted: isSingleGroup
                    ? "resizeSingle"
                    : "resizeMultiple",
                resizeType: newResizeType,
            },
            scoreWasEdited: true,
            updateScoreLength: true,
        })
    }

    private setTempo({ tempo }: { tempo: number }) {
        tempo = Math.max(MIN_TEMPO, Math.min(MAX_TEMPO, tempo))

        const score = this.query.score
        score.tempoMap = [new Tempo("0", 0, 0, tempo)]

        this.store.updateStore({
            partial: {
                score: score,
                renderingType: ["TempoCanvas"],
                scoreUpdate: ["Effect"],
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            metric: createBPMEditingMetric(
                this.query.score.compositionID,
                tempo
            ),
        })
    }

    private async endManipulatingNotes({
        removeOverlappingNotes,
    }: {
        removeOverlappingNotes: boolean
    }) {
        const store = this.store.getValue()

        let layer = this.query.toggledLayer
        let selectedNotes = this.query.selectedNotes

        const isHarmonyLocked = store.harmonyLock

        const fractionRange: [string, string] | [Fraction, Fraction] =
            featureFlags.useFractionClass
                ? [
                      new Fraction(selectedNotes.getFirstGroup()[0].start),
                      new Fraction(selectedNotes.getLastGroup()[0].getEnd()),
                  ]
                : [
                      selectedNotes.getFirstGroup()[0].start,
                      selectedNotes.getLastGroup()[0].getEnd(),
                  ]

        if (isHarmonyLocked) {
            let overlappingNotes: NotesObject
            if (featureFlags.useFractionClass) {
                overlappingNotes = ScoreManipulation.detectOverlappingNotes2({
                    selectedNotes,
                    layer,
                    timeSignature: this.query.score.firstTimeSignature,
                    score: this.query.score,
                    includePitchDetection: true,
                    visibleFractionRange: fractionRange as [Fraction, Fraction],
                })
            } else {
                overlappingNotes = ScoreManipulation.detectOverlappingNotes({
                    selectedNotes,
                    layer,
                    timeSignature: this.query.score.firstTimeSignature,
                    score: this.query.score,
                    includePitchDetection: true,
                    visibleFractionRange: fractionRange as [string, string],
                })
            }

            ScoreManipulation.splitNotesAtChordBoundaries({
                selectedNotes,
                score: store.score,
            })

            selectedNotes = ScoreManipulation.voiceNotesInTheChord({
                selectedNotes,
                chords: this.query.score.chords,
            })
        }

        if (removeOverlappingNotes) {
            let overlappingNotes: NotesObject
            if (featureFlags.useFractionClass) {
                overlappingNotes = ScoreManipulation.detectOverlappingNotes2({
                    selectedNotes,
                    layer,
                    timeSignature: this.query.score.firstTimeSignature,
                    score: this.query.score,
                    includePitchDetection: true,
                    visibleFractionRange: fractionRange as [Fraction, Fraction],
                })
            } else {
                overlappingNotes = ScoreManipulation.detectOverlappingNotes({
                    selectedNotes,
                    layer,
                    timeSignature: this.query.score.firstTimeSignature,
                    score: this.query.score,
                    includePitchDetection: true,
                    visibleFractionRange: fractionRange as [string, string],
                })
            }
            ScoreManipulation.removeOverlappingNotes({
                overlappingNotes,
                layer,
                selectedNotes,
            })
        }

        if (
            store.noteManipulationStarted === "resizeSingle" ||
            store.noteManipulationStarted === "move"
        ) {
            layer = this.addRemovedSelectedNotes()
        }

        // Update the first and last group after each manipulation
        if (!layer.notesObject.getFirstGroup()?.length) {
            layer.notesObject.updateFirstGroup()
        }

        if (!layer.notesObject.getLastGroup()?.length) {
            layer.notesObject.updateLastGroup()
        }

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditNotes,
            data: {
                selectedNotes: selectedNotes,
                layer,
                getSelectedNoteGroups: false,
                previewWhenPaused: true,
                origin: "endManipulatingNotes",
            },
        })

        this.store.updateStore({
            partial: {
                renderingType: [
                    "AccompanimentDesignerCanvas",
                    "TimelineCanvas",
                    "LayerPreviewCanvas_" + layer.value,
                ],
                resizeType: undefined,
                skipCachedCanvas: true,
                overlappingNotes: new NotesObject(),
                selectedData: {
                    type: "Note",
                    data: selectedNotes,
                },
            },
            scoreWasEdited: false,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
            metric: this.createNoteMetric("modify"),
        })
    }

    private addRemovedSelectedNotes() {
        const store = this.store.getValue()
        if (store.selectedData.type !== "Note")
            return store.score.layers[store.toggledLayer]

        const selectedNotes = store.selectedData.data
        const layer = store.score.layers[store.toggledLayer]
        selectedNotes.forEach(n => {
            if (!layer.notesObject.noteExists(n)) {
                layer.notesObject.addNoteToGroup(
                    n,
                    store.score.timeSignatures[0][1],
                    store.score.sections
                )
            }
        })
        return layer
    }

    private setTimestepRes({ timestepRes }: { timestepRes: number }) {
        this.store.updateStore({
            partial: {
                userSelectedTimestepRes: timestepRes,
                renderingType: ["All"],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private setAccompanimentDesignerTimestepRes({
        timestepRes,
        notesToRemove,
    }: {
        timestepRes: number
        notesToRemove: NotesObject
    }) {
        const score = this.query.getValue().score
        const minDuration = `1/${timestepRes}`

        if (notesToRemove.length !== 0) {
            this.deleteNotes({
                notes: notesToRemove,
                layer: this.query.toggledLayer,
            })
        }

        // adjust timestepRes to existing notes, so they
        // don't appear in between grid cells
        ScoreManipulation.applyMinimumDurationToScore({
            score: score,
            layers: [this.query.toggledLayer],
            minDuration: minDuration,
        })

        // apply the minimum duration to the selected notes as well
        const newSelectedNotes = new NotesObject()

        if (this.query.selectedNotes.length > 0) {
            for (let note of this.query.selectedNotes.getFlatArray()) {
                const noteGroup = score.layers[
                    this.query.toggledLayer.value
                ].notesObject.getNoteGroup(note.start)

                if (!noteGroup?.length) {
                    continue
                }

                for (let n of noteGroup) {
                    if (note.pitch === n.pitch) {
                        newSelectedNotes.addNoteToGroup(
                            n,
                            score.firstTimeSignature,
                            score.sections
                        )
                    }
                }
            }
        }

        this.store.updateStore({
            partial: {
                score: score,
                userSelectedTimestepRes: timestepRes,
                renderingType: ["All"],
                scoreUpdate: ["ScoreMetadata", "Note", "Layer"],
                selectedData: {
                    type: "Note",
                    data: newSelectedNotes,
                },
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private initScore({
        score,
        additionalData,
    }: {
        score: Score
        additionalData: {
            userID: string
            settings: string
            instruments: InstrumentsJSON
            autoExtendScore: boolean
            maxBarLength: number
            resizeFactor: {
                min: number
                max: number
            }
            levelsMeasurement: "trackbus" | "layer"
            sustainPedalFromChords: boolean
        }
    }) {
        this.editorViewStore.update(state => ({
            ...state,
            instrumentsJSON: additionalData.instruments,
        }))

        this.store.updateStore({
            partial: {
                score,
                renderingType: ["All"],
                autoExtendScore: additionalData.autoExtendScore,
                maxBarLength: additionalData.maxBarLength,
                resizeFactorRange: additionalData.resizeFactor,
                levelsMeasurement: additionalData.levelsMeasurement,
                sustainPedalFromChords: additionalData.sustainPedalFromChords,
            },
            scoreWasEdited: false,
            updateScoreLength: true,
        })

        this.startRealtimeSampler({
            userID: additionalData.userID,
            settings: additionalData.settings,
        })
    }

    private startRealtimeSampler({
        userID,
        settings,
    }: {
        userID: string
        settings: string
    }) {
        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.start,
            data: {
                userID,
                settings,
                instruments: this.editorViewQuery.getValue().instrumentsJSON,
                autoExtendScore: this.query.getValue().autoExtendScore,
                maxBarLength: this.query.maxBarLength,
                resizeFactor: this.query.resizeFactor,
            },
        })
    }

    private computeSustainPedal() {
        ScoreManipulation.computeSustainPedal(
            this.query.score,
            this.query.getValue().sustainPedalFromChords
        )

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: [],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private async addTrackBusses({
        trackBusses,
        layer,
        instruments,
        order,
    }: {
        trackBusses: TrackBus[]
        layer: Layer | PercussionLayer
        instruments: InstrumentsJSON
        order?: number
    }) {
        const temp = []

        for (const trackBus of trackBusses) {
            const section = trackBus.name.split(".")[0]
            const instrumentName = trackBus.name.split(".")[1]

            temp.push(
                this.addTrackBusData({
                    layer,
                    instrument: instruments[section].find(
                        i => i.name === instrumentName
                    ),
                    order,
                    trackBus,
                })
            )
        }

        await new Promise(resolve => {
            this.realtimePlayer.emitter$.next({
                type: RTSamplerActionTypes.loadTrackBusses,
                data: {
                    tbs: temp,
                    instruments:
                        this.editorViewQuery.getValue().instrumentsJSON,
                },
                resolve,
            })
        })

        this.store.updateStore({
            partial: {
                renderingType: [
                    "TrackbusRegionsCanvas",
                    "LayerPreviewCanvas_" + layer.value,
                    "PatternRegionsCanvas",
                    "DrumSequencerCanvas",
                ],
                scoreUpdate: ["TrackBus"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
            metric: this.instrumentEditingMetric("add"),
        })

        // set a pattern and pattern region by default,
        // so users are not confronted with an empty
        // pattern and no selected pattern regions
        if (layer.type === "percussion") {
            const selectedPattern = this.hasPattern(
                layer as PercussionLayer,
                this.query.all.selectedPattern
            )

            const selectedPatternRegions = this.query?.selectedPatternRegions
                ?.length
                ? [this.query?.selectedPatternRegions[0]]
                : [(layer as PercussionLayer).patternRegions[0]]

            this.setSelectedPattern({
                pattern: selectedPattern,
                scoreWasEdited: true,
            })

            this.setSelectedPatternRegion({
                patternRegions: selectedPatternRegions,
                prevSelection: this.query?.selectedPatternRegions,
            })
        }
    }

    private async addTrackBus({
        layer,
        instrument,
        order,
        trackBus,
    }: {
        layer: Layer | PercussionLayer
        instrument: InstrumentJSON
        order?: number
        trackBus?: TrackBus
    }) {
        trackBus = this.addTrackBusData({
            layer,
            instrument,
            order,
            trackBus,
        })

        await new Promise(resolve => {
            this.realtimePlayer.emitter$.next({
                type: RTSamplerActionTypes.loadTrackBusses,
                data: {
                    tbs: [trackBus],
                    instruments:
                        this.editorViewQuery.getValue().instrumentsJSON,
                },
                resolve,
            })
        })

        this.store.updateStore({
            partial: {
                renderingType: [
                    "TrackbusRegionsCanvas",
                    "LayerPreviewCanvas_" + layer.value,
                    "PatternRegionsCanvas",
                    "DrumSequencerCanvas",
                ],
                scoreUpdate: ["TrackBus"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
            metric: this.instrumentEditingMetric("add"),
        })

        // set a pattern and pattern region by default,
        // so users are not confronted with an empty
        // pattern and no selected pattern regions
        if (layer.type === "percussion") {
            const selectedPattern = this.hasPattern(
                layer as PercussionLayer,
                this.query.all.selectedPattern
            )

            const selectedPatternRegions = this.query?.selectedPatternRegions
                ?.length
                ? [this.query?.selectedPatternRegions[0]]
                : [(layer as PercussionLayer).patternRegions[0]]

            this.setSelectedPattern({
                pattern: selectedPattern,
                scoreWasEdited: true,
            })

            this.setSelectedPatternRegion({
                patternRegions: selectedPatternRegions,
                prevSelection: this.query?.selectedPatternRegions,
            })
        }
    }

    private instrumentEditingMetric(
        type: "add" | "delete" | "duplicate" | "update"
    ) {
        return createInstrumentEditingMetric(
            this.query.toggledLayer?.value,
            type,
            this.query.score.compositionID
        )
    }

    private addTrackBusData({
        layer,
        instrument,
        order,
        trackBus,
    }: {
        layer: Layer | PercussionLayer
        instrument: InstrumentJSON
        order?: number
        trackBus?: TrackBus
    }) {
        const score = this.query.score

        if (score === undefined) {
            return trackBus
        }

        const scoreLengthTimesteps = Time.fractionToTimesteps(
            TIMESTEP_RES,
            score.getScoreLengthWithoutPadding()
        )

        if (trackBus === undefined) {
            trackBus = ScoreManipulation.createDefaultTrackBus(
                layer,
                instrument,
                scoreLengthTimesteps
            )
        }

        const nbOfTrackbuses = layer.trackBuses.length

        if (order === undefined) {
            layer.trackBuses.unshift(trackBus)
        } else {
            this.addTrackBusAtOrderIndex(order, trackBus, layer)
        }

        if (nbOfTrackbuses === 0 && layer.type === "percussion") {
            score.initPercussionLayerAfterAddingFirstInstrument(
                layer.value,
                layer.trackBuses[layer.trackBuses.length - 1]
            )
        }

        return trackBus
    }

    private resizeKeySignature({
        index,
        offset,
        side,
    }: {
        index: number
        offset: FractionString
        side: HoveringType
    }) {
        const scoreLength = this.query.score.scoreLength
        const keySignatures = this.query.score.keySignatures

        this.query.score.keySignatures = KeySignatureModule.resizeKeySignature(
            scoreLength,
            keySignatures,
            offset,
            index,
            side
        )

        this.store.updateStore({
            partial: {
                renderingType: ["KeySignatureEditingCanvas"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private duplicateTrackBusses({
        layer,
        toDuplicate,
    }: {
        layer: Layer | PercussionLayer
        toDuplicate: TrackBus[]
    }) {
        const tbs = toDuplicate.map((tb, index) => {
            const newTB = tb.copy()

            this.addTrackBus({
                layer,
                instrument: tb.reference,
                order: index + 1,
                trackBus: newTB,
            })

            return newTB
        })

        this.setSelectedData({
            type: "TrackBus",
            data: tbs,
        })
    }

    private addCustomLayer({
        layerType,
        defaultInstrument,
    }: {
        layerType: LayerFunctionType
        defaultInstrument: InstrumentJSON
    }) {
        const score: Score | undefined = this.query.score

        if (score === undefined) {
            return
        }

        let copyAutomationFrom

        for (const layer in score.layers) {
            if (layer !== "Melody" && score.layers[layer].type === layerType) {
                copyAutomationFrom = score.layers[layer]

                break
            } else {
                copyAutomationFrom = score.layers[layer]
            }
        }

        let newLayer

        if (copyAutomationFrom === undefined) {
            newLayer = ScoreManipulation.createNewLayer(score, layerType)
        } else {
            newLayer = ScoreManipulation.createNewLayer(score, layerType, {
                layer: copyAutomationFrom,
                copyType: "automation",
            })
        }

        score.layers[newLayer.value] = newLayer

        this.addTrackBus({ layer: newLayer, instrument: defaultInstrument })

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
                scoreUpdate: ["All"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })

        this.toggleLayer({ toggledLayer: newLayer })
    }

    private seek({ timesteps }: { timesteps: number }) {
        this.store.updateStore({
            partial: {
                seekTime:
                    this.query.getValue().seekTime === timesteps
                        ? timesteps + Misc.getRandomIntInclusive(1, 2) / 100
                        : timesteps,
                renderingType: ["DrumSequencerCanvas"],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private setAutomation({ effect }: { effect: Effect | undefined }) {
        this.store.updateStore({
            partial: {
                scrollToPitchsteps: this.query.scrollToPitchsteps,
                selectedAutomation: effect,
                renderingType: [
                    "AutomationCanvas",
                    "AccompanimentDesignerCanvas",
                ],
                scoreUpdate: ["Effect"],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private toggleLayerAsVisible({ layer }: { layer: Layer }) {
        if (layer.type === "percussion") {
            return
        }

        const visiblePitchedLayers = this.query.visiblePitchedLayers

        const index = visiblePitchedLayers.findIndex(
            (l: Layer) => l.value === layer.value
        )

        if (index === -1) {
            if (
                visiblePitchedLayers.length === 0 ||
                this.query.toggledLayer === undefined
            ) {
                return this.toggleLayer({ toggledLayer: layer })
            } else {
                visiblePitchedLayers.push(layer)
            }
        } else {
            visiblePitchedLayers.splice(index, 1)
        }

        this.store.updateStore({
            partial: {
                visiblePitchedLayers,
                renderingType: [
                    "AccompanimentDesignerCanvas",
                    "TimelineCanvas",
                ],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private setHarmonyLock({ harmonyLock }: { harmonyLock: boolean }) {
        this.store.updateStore({
            partial: {
                harmonyLock,
                renderingType: ["All"],
                forcePrerender: true,
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private toggleLayer({
        toggledLayer,
    }: {
        toggledLayer: Layer | PercussionLayer | undefined
    }) {
        if (
            toggledLayer &&
            toggledLayer?.value === this.query.toggledLayer?.value
        ) {
            return
        }

        const visiblePitchedLayers: Layer[] = []

        let scrollToPitchsteps = 0
        let selectedPattern = undefined
        let renderingType

        if (toggledLayer !== undefined && toggledLayer.type !== "percussion") {
            visiblePitchedLayers.push(toggledLayer)

            renderingType = ["AccompanimentDesignerCanvas", "TimelineCanvas"]

            // This part fixes an issue with the wrong regenerate button being displayed for layer inpainting
            // Refactor into a separate method maybe?
            if (featureFlags.generateLayerWithLLMInEditor) {
                const el = document.querySelector("#regenerate-section-layer")
                if (!!el) {
                    el.parentElement.removeChild(el)
                }
            }

            if (this.query.all.pitchStepDomain === "scale") {
                scrollToPitchsteps = 0
            } else {
                const highestPitch = toggledLayer.getHighestNotePitch()
                const lowestPitch = toggledLayer.getLowestNotePitch()
                const pitchRange = highestPitch - lowestPitch

                if (toggledLayer.notesObject.length > 0) {
                    scrollToPitchsteps = Math.max(
                        0,
                        Note.highestNote -
                            highestPitch -
                            Math.max(
                                0,
                                (PITCH_CONTINOUS_COUNT - pitchRange) / 2
                            )
                    )
                } else if (toggledLayer.value.includes("Melody")) {
                    scrollToPitchsteps =
                        Note.highestNote - DEFAULT_LAYER_RANGE.MELODY.max
                } else if (toggledLayer.value.includes("Bass")) {
                    scrollToPitchsteps =
                        Note.highestNote - DEFAULT_LAYER_RANGE.BASS.max
                } else {
                    scrollToPitchsteps =
                        Note.highestNote - DEFAULT_LAYER_RANGE.ACCOMPANIMENT.max
                }
            }
        } else if (
            toggledLayer !== undefined &&
            toggledLayer.type === "percussion"
        ) {
            if (featureFlags.generateLayerWithLLMInEditor) {
                const el = document.querySelector("#regenerate-button-wrapper")
                if (!!el) {
                    el.parentElement.removeChild(el)
                }
            }
            selectedPattern = (<PercussionLayer>toggledLayer).patterns[0]
            renderingType = ["DrumSequencerCanvas", "PatternRegionsCanvas"]
        }

        const partial: Partial<ScoreRendering> = {
            toggledLayer: toggledLayer === undefined ? "" : toggledLayer.value,
            visiblePitchedLayers,
            scrollToPitchsteps,
            selectedPattern,
            selectedData: {
                type: "None",
                data: undefined,
            },
            overlappingNotes: new NotesObject(),
            selectedAutomation: undefined,
            renderingType: renderingType,
            harmonyLock: this.query.harmonyLock,
        }

        // Disable polyphony for the AD Bass layer
        if (
            this.query.all.pitchStepDomain === "scale" &&
            toggledLayer?.value === "Bass"
        ) {
            this.setAllowPolyphony({
                allowPolyphony: false,
            })
        }

        this.store.updateStore({
            partial,
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private setPitchStepDomain({ domain }: { domain: PitchStepDomain }) {
        this.store.updateStore({
            partial: {
                pitchStepDomain: domain,
                renderingType: ["All"],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private async setResizeFactor({ resizeFactor }: { resizeFactor: number }) {
        resizeFactor = Math.min(
            this.store.getValue().resizeFactorRange.max,
            Math.max(this.store.getValue().resizeFactorRange.min, resizeFactor)
        )

        this.store.updateStore({
            partial: {
                resizeFactor,
                renderingType: ["All"],
            },
            scoreWasEdited: false,
            updateScoreLength: true,
        })

        await Misc.wait(0.01)

        this.setScroll({
            scrollToPitchsteps: this.query.scrollToPitchsteps,
            scrollToTimestep: this.query.scrollToTimestep,
            width: this.editorViewQuery.timelineCursorElement
                ? this.editorViewQuery.timelineCursorElement.getBoundingClientRect()
                      .width
                : 0,
        })
    }

    private setAccompanimentDesignerIsFocused({ value }: { value: boolean }) {
        this.editorViewStore.update(state => ({
            ...state,
            accompanimentDesignerIsFocused: value,
        }))
    }

    private async resetScoreWasEdited() {
        this.store.resetScoreWasEdited()
    }

    public setSelectedPattern({
        pattern,
        scoreWasEdited,
    }: {
        pattern: Pattern
        scoreWasEdited: boolean
    }) {
        if (pattern === undefined) {
            return
        }

        const renderingType = [
            "DrumSequencerCanvas",
            "PatternRegionsCanvas",
            "PatternHorizontalScrollbarCanvas",
        ]

        if (this.query.toggledLayer !== undefined) {
            renderingType.push(
                "LayerPreviewCanvas_" + this.query.toggledLayer.value
            )
        }

        /**
         * We set pattern resolution here, in order to do this once per pattern selection
         * instead of every time the pattern is rendered.
         * */
        const resolution = ScoreManipulation.calculatePatternResolution(pattern)

        pattern.resolution = resolution

        this.store.updateStore({
            partial: {
                selectedPattern: pattern,
                selectedData: {
                    type: "None",
                    data: undefined,
                },
                overlappingNotes: new NotesObject(),
                renderingType,
                scoreUpdate: ["Pattern"],
            },
            scoreWasEdited: this.query.all.scoreWasEdited || scoreWasEdited,
            updateScoreLength: false,
        })
    }

    private setSelectedPatternRegion({
        patternRegions,
        prevSelection,
    }: {
        patternRegions: PatternRegion[]
        prevSelection: PatternRegion[]
    }) {
        if (prevSelection) {
            prevSelection.push(...patternRegions)
        } else {
            prevSelection = [...patternRegions]
        }

        this.setSelectedData({
            type: "PatternRegion",
            data: prevSelection,
        })
    }

    private manipulatePatternRegion({
        timestepDelta,
        timestepRange,
        timesteps,
        type,
        hoveringType,
    }: {
        timestepDelta: number
        timesteps: number
        timestepRange: [number, number]
        type: "resize" | "move" | "loop"
        hoveringType: PatternHoveringType
    }) {
        const store = this.store.getValue()
        let selectedPatternRegion: PatternRegion[]
        if (
            store.selectedData.type !== "PatternRegion" ||
            !store.selectedData.data
        ) {
            throw "No Pattern region selected"
        }

        selectedPatternRegion = store.selectedData.data
        if (selectedPatternRegion.length > 1) return
        const timestepRes = store.userSelectedTimestepRes
        const timeSignature = store.score.timeSignatures[0][1]

        let selectedRegion = null

        const quantizationType = ScoreManipulation.getQuantizationType(
            this.query.resizeFactor,
            PATTERN_REGIONS_QUANTIZATION_THRESHOLDS
        )

        switch (type) {
            case "move": {
                selectedRegion = ScoreManipulation.movePatternRegion({
                    patternRegion: selectedPatternRegion[0],
                    timestepDelta,
                    timestepRes,
                    type: quantizationType,
                    timeSignature,
                })
                break
            }

            case "resize": {
                selectedRegion = ScoreManipulation.resizePatternRegion({
                    patternRegion: selectedPatternRegion[0],
                    timestepDelta,
                    timestepRes,
                    timeSignature,
                    resizeType: hoveringType,
                    quantization: quantizationType,
                })
                break
            }

            case "loop": {
                selectedRegion = ScoreManipulation.loopPatternRegion({
                    patternRegion: selectedPatternRegion[0],
                    timestepDelta,
                    timestepRes,
                    timeSignature,
                })
                break
            }
        }

        this.store.updateStore({
            partial: {
                selectedData: {
                    type: "PatternRegion",
                    data: [selectedRegion],
                },
                renderingType: [
                    "PatternRegionsCanvas",
                    "LayerPreviewCanvas_" + store.toggledLayer,
                ],
                isInitialPRManipulation: false,
            },
            scoreWasEdited: true,
            updateScoreLength: true,
        })
    }

    private endManipulatingPatterns() {
        const store = this.store.getValue()
        const { selectedData } = store

        if (selectedData?.type !== "PatternRegion" || !selectedData.data) return

        const selectedRegions = selectedData.data
        const regions = ScoreManipulation.endManipulatingPatterns(
            selectedRegions as PatternRegion[],
            store.score.layers[store.toggledLayer] as PercussionLayer,
            store.score.timeSignatures[0][1],
            store.userSelectedTimestepRes
        )

        ;(
            store.score.layers[store.toggledLayer] as PercussionLayer
        ).patternRegions = regions

        this.store.updateStore({
            partial: {
                renderingType: ["PatternRegionsCanvas"],
                isInitialPRManipulation: true,
            },
            scoreWasEdited: true,
            updateScoreLength: true,
        })
    }

    private resizeChord({
        index,
        offset,
        hoveringType,
    }: {
        index: number
        offset: FractionString
        hoveringType: HoveringTypeEnum
    }) {
        const store = this.store.getValue()
        const romanNumerals: TemplateChord[] = cloneDeep(
            store?.score?.romanNumerals
        )

        if (
            !romanNumerals.length ||
            index === undefined ||
            romanNumerals[index] === undefined ||
            hoveringType === HoveringTypeEnum.CENTER
        ) {
            console.error("No Chord to resize selected")
            return
        }

        const timeSignature = store.score.timeSignatures[0][1]

        const newChords = ChordManipulation.resizeChord({
            romanNumerals: romanNumerals,
            index: index,
            offset: offset,
            side: hoveringType,
            timeSignature: timeSignature,
            keySignatures: store.score.keySignatures,
            editorType: store.editorType,
        })

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
                temporaryRomanNumerals: newChords.romanNumerals,
                temporaryChords: newChords.chords,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private endResizeChord({}: {}) {
        const store = this.store.getValue()
        const romanNumerals: TemplateChord[] = cloneDeep(
            store?.temporaryRomanNumerals
        )
        const chords: TemplateChord[] = cloneDeep(store?.temporaryChords)

        if (!romanNumerals?.length || !chords?.length) {
            return
        }

        const timeSignature = store.score.timeSignatures[0][1]

        const notes = CompositionWorkflowModule.initNotes({
            chordProgression: romanNumerals,
            timeSignature: timeSignature,
            keySignatures: this.query.score.keySignatures,
            lowestNote: 60,
            tieNotes: true,
        }).notes

        this.updateChords({
            romanNumerals,
            chords,
            notes,
        })

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
                temporaryRomanNumerals: undefined,
                temporaryChords: undefined,
                chordsWereEdited: true,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private convertChordsToRomanNumerals() {
        const score = this.query.score

        // converting key signature keymode to complete name of the mode to match the keysignature module api

        const keySignature = { ...score.getKeySignature() }
        if (keySignature.keyMode.length < 5) {
            keySignature.keyMode =
                SHORT_SCALES_TO_SCALES_MAP[keySignature.keyMode]
        }
        const romanNumerals =
            ChordManipulation.convertChordSymbolsToRomanNumerals(
                score.chords,
                score.keySignatures
            )

        score.romanNumerals = romanNumerals as any
        this.store.updateStore({
            partial: {
                renderingType: ["All"],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private setChordsAsEdited({ value }: { value: boolean }) {
        this.store.updateStore({
            partial: {
                chordsWereEdited: value,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private selectNotes({
        score,
        layer,
        timestepRange,
        pitchRange,
        timestepResolution,
        timeSignature,
        prevSelection,
    }: {
        score: Score
        layer: Layer
        timestepRange: number[]
        pitchRange: [number, number]
        timestepResolution: number
        timeSignature: TimeSignature
        prevSelection?: NotesObject
    }) {
        const layerType = layer.value as LayerType

        const selectedNotes = ScoreManipulation.selectNotes({
            score,
            layerType: layerType,
            timestepRange,
            pitchRange,
            timestepResolution,
            timeSignature,
            prevSelection,
        })

        const previousSelection = Object.assign(
            this.query.selectedNotes,
            NotesObject
        )

        if (
            this.query.selectedData.type === "Note" &&
            this.query.selectedData.data.length === selectedNotes.length
        ) {
            return
        }

        this.setSelectedData({
            type: "Note",
            data: selectedNotes,
        })

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.playNoteSelectionPreviews,
            data: {
                previousNotes: previousSelection,
                selectedNotes,
            },
        })
    }

    private unselectAll() {
        let renderingType = ["All"]
        let scoreUpdate: ScoreUpdateType[] = ["All"]

        if (this.query.selectedData.type === "Note") {
            renderingType = ["AccompanimentDesignerCanvas"]
            scoreUpdate = ["Note"]
        } else if (
            this.query.selectedData.type === "TrackBusRegion" ||
            this.query.selectedData.type === "TrackBus"
        ) {
            renderingType = ["TrackbusRegionsCanvas"]
            scoreUpdate = ["TrackBus"]
        } else if (this.query.selectedData.type === "PatternRegion") {
            renderingType = ["PatternRegionsCanvas"]
            scoreUpdate = []
        } else if (this.query.selectedData.type === "None") {
            renderingType = ["None"]
            scoreUpdate = ["None"]
        }

        this.store.updateStore({
            partial: {
                selectedPattern: undefined,
                selectedData: {
                    type: "None",
                    data: undefined,
                },
                overlappingNotes: undefined,
                renderingType,
                scoreUpdate,
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private setAutoPedal({
        autoPedal,
        tb,
    }: {
        autoPedal: boolean
        tb: TrackBus
    }) {
        tb.autoPedal = autoPedal

        this.store.updateStore({
            partial: {
                selectedPattern: undefined,
                renderingType: ["All"],
                scoreUpdate: ["TrackBusMetadata"],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setOctave({ octave, tb }: { octave: number; tb: TrackBus }) {
        tb.octave = octave

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditNotes,
            data: {
                selectedNotes: this.query.toggledLayer.notesObject,
                layer: this.query.toggledLayer,
                getSelectedNoteGroups: false,
                previewWhenPaused: false,
                origin: "setOctaves",
            },
        })

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["TrackBusMetadata"],
                scoreWasEdited: true,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setDynamic({ dynamic, tb }: { dynamic: number; tb: TrackBus }) {
        tb.dynamicOffset = dynamic

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditNotes,
            data: {
                selectedNotes: this.query.toggledLayer.notesObject,
                layer: this.query.toggledLayer,
                getSelectedNoteGroups: false,
                previewWhenPaused: false,
                origin: "setDynamic",
            },
        })

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["TrackBusMetadata"],
                scoreWasEdited: true,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setGain({ gain, tb }: { gain: number; tb: TrackBus }) {
        tb.gainOffset = gain

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditEverythingExceptNotes,
            data: {},
        })

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["TrackBusMetadata"],
                scoreWasEdited: true,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setPan({ pan, tb }: { pan: number; tb: TrackBus }) {
        tb.panning = pan

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditEverythingExceptNotes,
            data: {},
        })

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["TrackBusMetadata"],
                scoreWasEdited: true,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private setBreathingGain({
        breathingGain,
        tb,
    }: {
        breathingGain: number
        tb: TrackBus
    }) {
        tb.breathingGain = breathingGain

        this.store.updateStore({
            partial: {
                renderingType: ["None"],
                scoreUpdate: ["TrackBusMetadata"],
                scoreWasEdited: true,
            },
            scoreWasEdited: true,
            updateScoreLength: false,
        })
    }

    private drawPercussionNote({
        tb,
        timesteps,
        ysteps,
        strategy,
    }: {
        tb: TrackBus
        timesteps
        ysteps
        strategy: "toggleOn" | "toggleOff" | "inverse"
    }) {
        const pattern = this.hasPattern(
            <PercussionLayer>this.query.toggledLayer,
            this.query.selectedPattern
        )

        if (pattern === undefined || pattern.channels[ysteps] === undefined) {
            return
        }

        const onset = Time.timestepsToFraction(pattern.noteRes, timesteps)

        const channel: Channel = pattern.getChannelsForTrackbus(tb)[ysteps]
        const result = channel?.addOnset(onset, strategy)

        if (result === "added") {
            this.realtimePlayer.emitter$.next({
                type: RTSamplerActionTypes.playNotePreview,
                data: {
                    pitch: channel.pitches[channel.pitches.length - 1],
                    clearPreviews: false,
                    absoluteNoteStart: onset,
                    stepSequencerTrackBus: tb,
                    alwaysPlay: true,
                },
            })
        }

        if (result !== "none") {
            this.store.updateStore({
                partial: {
                    renderingType: [
                        "LayerPreviewCanvas_" + this.query.toggledLayer.value,
                        "DrumSequencerCanvas",
                        "PatternRegionsCanvas",
                    ],
                    scoreUpdate: ["Note"],
                    percussionDrawingStrategy:
                        result === "added" ? "toggleOn" : "toggleOff",
                },
                scoreWasEdited: true,
                updateScoreLength: false,
            })
        }
    }

    private drawPitchedNote({
        timesteps,
        ysteps,
        defaultDuration,
        maxScoreLength,
    }: {
        timesteps: number
        ysteps: number
        defaultDuration?: FractionString // this will be used as the note duration value for the drawn note
        maxScoreLength?: FractionString
    }) {
        const store = this.query.all
        const score = this.query.score

        const layer = this.query.toggledLayer as Layer | PercussionLayer
        const timestepRes = store.userSelectedTimestepRes
        const timeSignature = (score as Score).timeSignatures[0][1]
        const quantization = ScoreManipulation.getQuantizationType(
            this.query.resizeFactor,
            NOTE_QUANTIZATION_THRESHOLDS
        )
        const quantizeRes = ScoreManipulation.getQuantizationTypeAsResolution(
            quantization,
            timeSignature,
            timestepRes
        )

        if (!defaultDuration) {
            defaultDuration = "1/" + timestepRes
        }

        let drawnNote = ScoreManipulation.drawNote({
            score,
            layer,
            timesteps,
            ysteps,
            defaultDuration,
            quantizeRes,
            timeSignature,
            timestepRes,
            maxScoreLength,
            harmonyLock: this.query.getValue().harmonyLock,
            chords: this.query.score.chords,
        })

        // Check if a note already exist in place
        // if so, just select the note, otherwise draw a new one
        const noteGroup = layer.notesObject.getNoteGroup(drawnNote.start)
        const pitchedNote = noteGroup.find(
            note => note.pitch === drawnNote.pitch
        )
        if (pitchedNote) {
            drawnNote = pitchedNote
        }

        const selectedNotes = new NotesObject()

        selectedNotes.addNoteToGroup(drawnNote, timeSignature, score.sections)

        this.realtimePlayer.emitter$.next({
            type: RTSamplerActionTypes.liveEditNotes,
            data: {
                selectedNotes,
                layer,
                getSelectedNoteGroups: false,
                previewWhenPaused: true,
                selectedNoteID: drawnNote.noteID,
                origin: "drawPitchedNotes",
            },
        })

        this.store.updateStore({
            partial: {
                score: score,
                selectedData: {
                    type: "Note",
                    data: selectedNotes,
                },
                renderingType: [
                    "AccompanimentDesignerCanvas",
                    "LayerPreviewCanvas_" + layer.value,
                ],
                scoreUpdate: ["Note"],
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
            metric: this.createNoteMetric("add"),
        })
    }

    private removeNote({
        timesteps,
        ysteps,
        defaultDuration,
        maxScoreLength,
    }: {
        timesteps: number
        ysteps: number
        defaultDuration?: FractionString // this will be used as the note duration value for the drawn note
        maxScoreLength?: FractionString
    }): void {
        const store = this.query.all
        const score = this.query.score

        const layer = this.query.toggledLayer as Layer | PercussionLayer
        const timestepRes = store.userSelectedTimestepRes
        const timeSignature = (score as Score).timeSignatures[0][1]
        const quantization = ScoreManipulation.getQuantizationType(
            this.query.resizeFactor,
            NOTE_QUANTIZATION_THRESHOLDS
        )
        const quantizeRes = ScoreManipulation.getQuantizationTypeAsResolution(
            quantization,
            timeSignature,
            timestepRes
        )

        if (!defaultDuration) {
            defaultDuration = "1/" + timestepRes
        }

        ScoreManipulation.removeNote({
            layer,
            timesteps,
            ysteps,
            defaultDuration,
            quantizeRes,
            timeSignature,
            timestepRes,
            maxScoreLength,
            harmonyLock: this.query.getValue().harmonyLock,
            chords: this.query.score.chords,
        })

        this.store.updateStore({
            partial: {
                score: score,
                renderingType: [
                    "AccompanimentDesignerCanvas",
                    "LayerPreviewCanvas_" + layer.value,
                ],
                scoreUpdate: ["Note"],
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
            metric: this.createNoteMetric("add"),
        })
    }

    private selectAllNotes() {
        this.setSelectedData({
            type: "Note",
            data:
                cloneDeep(this.query.toggledLayer?.notesObject) ||
                new NotesObject(),
        })
    }

    private setSelectedNotes({ notes }: { notes: Note[] }) {
        const store = this.store.getValue()
        const score = store.score

        if (!score) {
            return
        }

        const selectedNotes = new NotesObject()

        for (let note of notes) {
            selectedNotes.addNoteToGroup(
                note,
                this.query.toggledLayer.timeSignature,
                score.sections
            )
        }

        this.setSelectedData({ type: "Note", data: selectedNotes })
    }

    private setSelectedData(data: ScoreRenderingSelectedDataType) {
        let renderingType = []
        let selectedPattern = this.query.selectedPattern
        let selectedChord = undefined

        if (data.type === "Note" || this.query.selectedData.type === "Note") {
            renderingType = renderingType.concat([
                "AccompanimentDesignerCanvas",
                "TimelineCanvas",
            ])
        }

        // if (data.type === "Chord" || this.query.selectedData.type === "Chord") {
        //     renderingType = renderingType.concat([
        //         "AccompanimentDesignerCanvas",
        //         "ChordsEditingCanvas",
        //         "TimelineCanvas",
        //     ])

        //     selectedChord = data.data
        // }

        if (
            data.type === "PatternRegion" ||
            this.query.selectedData.type === "PatternRegion"
        ) {
            renderingType = renderingType.concat([
                "PatternRegionsCanvas",
                "DrumSequencerCanvas",
                "PatternHorizontalScrollbarCanvas",
            ])

            selectedPattern = (data.data[0] as PatternRegion).pattern
        } else if (
            data.type === "TrackBus" ||
            data.type === "TrackBusRegion" ||
            this.query.selectedData.type === "TrackBus" ||
            this.query.selectedData.type === "TrackBusRegion"
        ) {
            renderingType = renderingType.concat(["TrackbusRegionsCanvas"])
        }

        this.store.updateStore({
            partial: {
                selectedData: data,
                renderingType,
                selectedPattern: selectedPattern,
                scoreUpdate: ["TrackBusMetadata", "Pattern"],
            },
            scoreWasEdited: false,
            updateScoreLength: false,
        })
    }

    private copyNotes() {
        const store = this.store.getValue()
        const layer = this.query.toggledLayer

        if (!layer || this.query.selectedData.type !== "Note") {
            return
        }

        const timeSignature = store.score.timeSignatures[0][1]
        const noteEditingClipboard: NoteEditingClipboard =
            ScoreManipulation.copy({
                layer: layer,
                notes: this.query.selectedNotes,
            })

        const selectedNotes = new NotesObject()

        this.query.selectedNotes.forEach(note =>
            selectedNotes.addNoteToGroup(
                note,
                timeSignature,
                store.score.sections
            )
        )

        clipboardActions.copy(
            IClipboardDataType.NOTES,
            noteEditingClipboard[layer.type].values,
            {
                noteRegionStart:
                    noteEditingClipboard[layer.type].noteRegionStart,
                noteRegionEnd: noteEditingClipboard[layer.type].noteRegionEnd,
                noteRegionDuration:
                    noteEditingClipboard[layer.type].noteRegionDuration,
            }
        )
    }

    private cutNotes() {
        const store = this.store.getValue()
        const layer = this.query.toggledLayer

        if (!layer) {
            return
        }

        const noteEditingClipboard: NoteEditingClipboard =
            ScoreManipulation.copy({
                layer: layer,
                notes: this.query.selectedNotes,
                cut: true,
            })

        this.deleteNotes({
            notes: this.query.selectedNotes,
            layer: layer,
        })

        clipboardActions.copy(
            IClipboardDataType.NOTES,
            noteEditingClipboard[layer.type].values,
            {
                noteRegionStart:
                    noteEditingClipboard[layer.type].noteRegionStart,
                noteRegionEnd: noteEditingClipboard[layer.type].noteRegionEnd,
                noteRegionDuration:
                    noteEditingClipboard[layer.type].noteRegionDuration,
            }
        )
        this.store.updateStore({
            partial: {
                score: store.score,
                selectedData: {
                    type: "Note",
                    data: new NotesObject(),
                },
                renderingType: [
                    "AccompanimentDesignerCanvas",
                    "TimelineCanvas",
                    "LayerPreviewCanvas_" + layer.value,
                ],
            },
            scoreWasEdited: true,
            updateScoreLength: false,
            computeAdditionalMetadata: true,
        })
    }

    private async pasteNotes() {
        const store = this.store.getValue()
        const layer = this.query.toggledLayer

        if (
            !layer ||
            !clipboardQuery?.clipboard[IClipboardDataType.NOTES] ||
            layer.type === "percussion"
        ) {
            return
        }

        const clipboard = clipboardQuery.getClipboardFor(
            IClipboardDataType.NOTES
        ) as Note[]
        const options: IClipboardDataOptions = clipboardQuery.getOptionsFor(
            IClipboardDataType.NOTES
        )

        const startOffset = ScoreManipulation.getPasteStartOffset({
            seekTime: playerQuery.timeElapsed,
            noteRegionStart: (options as any).noteRegionStart,
            hasStartOffset: playerActions.hasStartOffset(),
            tempoMap: store.score.tempoMap,
        })

        const seekTime =
            Time.convertSecondsInTimesteps(
                playerQuery.timeElapsed,
                playerActions.hasStartOffset(),
                TIMESTEP_RES,
                store.score.tempoMap,
                "pasteNotes"
            ) +
            Time.fractionToTimesteps(
                TIMESTEP_RES,
                (options as any).noteRegionDuration
            )

        const res = ScoreManipulation.paste({
            score: store.score,
            layer: layer,
            clipboard,
            timestepRes: store.userSelectedTimestepRes,
            startOffset: startOffset,
            maxScoreLength:
                store.pitchStepDomain === "scale"
                    ? store.score?.scoreLength
                    : undefined,
        })

        this.store.updateStore({
            partial: {
                score: store.score,
                seekTime: seekTime,
                selectedData: {
                    type: "Note",
                    data: res.selectedNotes,
                },
                renderingType: [
                    "AccompanimentDesignerCanvas",
                    "TimelineCanvas",
                    "LayerPreviewCanvas_" + layer.value,
                ],
            },
            scoreWasEdited: true,
            updateScoreLength: true,
            computeAdditionalMetadata: true,
            metric: this.createNoteMetric("paste"),
        })

        this.setPitchstepScrollBasedOnNotes(res.selectedNotes)

        await Misc.wait(0.01)

        await this.followTimelineCursor({
            position: "center",
            force: false,
        })

        this.endManipulatingNotes({
            removeOverlappingNotes: false,
        })
    }

    private setPitchstepScrollBasedOnNotes(selectedNotes: NotesObject) {
        const range = selectedNotes.getPitchRange()
        const middlePitch =
            HIGHEST_PIANO_PITCH -
            Math.round((range.lowestNote + range.highestNote) / 2)

        const startPitch = this.query.scrollToPitchsteps
        const endPitch = startPitch + SCALE_START_OCTAVE * 12

        if (!Misc.inRange(middlePitch, [startPitch, endPitch])) {
            this.setScroll({
                scrollToPitchsteps: middlePitch - 12,
                scrollToTimestep: this.query.scrollToTimestep,
                width: this.editorViewQuery.timelineCursorElement
                    ? this.editorViewQuery.timelineCursorElement.getBoundingClientRect()
                          .width
                    : 0,
            })
        }
    }

    private drawPatternRegion({ timestep }: { timestep: number }) {
        const store = this.store.getValue()
        const selectedPattern = store.selectedPattern
        const layer = store.score.layers[store.toggledLayer] as PercussionLayer
        const patternRegions = layer.patternRegions
        const timestepRes = store.userSelectedTimestepRes
        const timeSignature = store.score.timeSignatures[0][1]
        const newRegion = ScoreManipulation.drawPatternRegion(
            timestep,
            timestepRes,
            timeSignature,
            selectedPattern,
            patternRegions
        )

        this.store.updateStore({
            partial: {
                selectedData: {
                    type: "PatternRegion",
                    data: [newRegion],
                },
                renderingType: ["PatternRegionsCanvas"],
            },
            scoreWasEdited: true,
            updateScoreLength: true,
        })
    }

    private addTrackBusAtOrderIndex(order: number, tb: TrackBus, layer: Layer) {
        order = Math.min(layer.trackBuses.length, Math.max(0, order))

        layer.trackBuses.splice(order, 0, tb)
    }

    private hasScore() {
        return this.query.score !== undefined
    }

    private hasLayer(layer: Layer): undefined | Layer {
        if (!this.hasScore()) {
            return undefined
        }

        return this.query.score.layers[layer.value]
    }

    private hasPattern(
        layer: PercussionLayer,
        pattern: Pattern
    ): Pattern | undefined {
        if (!this.hasLayer(layer) || !pattern) {
            return undefined
        }

        return (<PercussionLayer>(
            this.query.score.layers[layer.value]
        )).patterns.find(p => p.id == pattern.id)
    }

    private hasChannel(
        layer: PercussionLayer,
        pattern: Pattern,
        channelID: string
    ): {
        pattern: undefined | Pattern
        channel: undefined | Channel
    } {
        const selectedPattern = this.hasPattern(layer, pattern)

        if (!selectedPattern) {
            return {
                pattern: undefined,
                channel: undefined,
            }
        }

        return {
            pattern: selectedPattern,
            channel: selectedPattern.channels.find(c => c.id === channelID),
        }
    }

    private setAllowPolyphony({ allowPolyphony }: { allowPolyphony: boolean }) {
        const store = this.store.getValue()
        const score = store.score

        if (!score) {
            return
        }

        if (allowPolyphony === false) {
            ScoreManipulation.convertToMonophonicScore(score)
        }

        this.store.updateStore({
            partial: {
                renderingType: ["All"],
                allowPolyphony: allowPolyphony,
                score: score,
            },
            scoreWasEdited: allowPolyphony === false,
            updateScoreLength: false,
        })
    }

    private setRenderChordsType({ type }: { type: RenderChordsType }) {
        this.store.updateStore({
            partial: {
                renderingType: ["All"],
                skipCachedCanvas: true,
                renderChordsType: type,
            },
            scoreWasEdited: false,
            updateScoreLength: false,
            computeAdditionalMetadata: false,
        })
    }

    private emptyAction() {
        // this is an empty action, use this to manually add a step to undo/redo
    }
}
