import Score from "../../../../general/classes/score/score"
import {
    CursorType,
} from "../../../../general/types/note-editing"
import { NoteDescriptor, NoteSelection } from "../../types"
import { ScoreRenderingQuery } from "../score-rendering/score-rendering.query"
import { EditorViewQuery } from "./editor-view.query"
import { EditorViewStore } from "./editor-view.store"

export class EditorViewActions {
    constructor(
        private store: EditorViewStore,
        private query: EditorViewQuery
    ) {}

    /**
     * In case we want to update multiple fields at once, we can
     * combine the updates using Akita's Transactions: https://opensource.salesforce.com/akita/docs/transactions
     *
     * e.g.
     *
     * applyTransaction(() => {
     *      this.setNoteDescriptor(noteDescriptor)
     *      this.setScroll({scrollLeft: 0, scrollTop: 0})
     * })
     *
     */

    public setNoteDescriptor(noteDescriptor: NoteDescriptor | undefined) {
        this.store.update(state => ({
            ...state,
            noteDescriptor,
        }))
    }

    public toggleFollowTimelineCursor() {
        const followTimelineCursor = !this.query.followTimelineCursor

        this.store.update(state => ({
            ...state,
            followTimelineCursor,
        }))
    }

    public setTimelineCursorElement(
        timelineCursorElement: HTMLDivElement | undefined
    ) {
        this.store.update(state => ({
            ...state,
            timelineCursorElement,
        }))
    }

    public setNoteSelection(noteSelection: NoteSelection | undefined) {
        return this.store.update(state => ({
            ...state,
            noteSelection,
        }))
    }

    public setCursorType(cursorType: CursorType) {
        this.store.update(state => ({
            ...state,
            cursorType: cursorType,
        }))
    }
}
