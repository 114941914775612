<div class="modal-title">Development settings</div>
<div class="modal-close"><img src="assets/img/close.svg" (click)="closeModal()"></div>

<div class="settings-row">
    <div class="settings-row-title">
        Minimum number of layers
        <img 
            *ngIf="getTutorialMode()"
            class="tooltip-img"
            src="assets/img/info.svg"
            [tooltip]="tooltips.minLayers"
            [tooltip-marginTop]="10"
            [tooltip-width]="250"
        >
    </div>
    <div class="settings-row-value">
        <input class="light-input" type="number" min="1" max="getMaxMinLayer()" (change)="handleMinLayerInput()" [(ngModel)]="settings.minLayers">
    </div>
</div>

<div class="settings-row">
    <div class="settings-row-title">
        Layer Priorities
        <img 
            *ngIf="getTutorialMode()"
            class="tooltip-img"
            src="assets/img/info.svg"
            [tooltip]="tooltips.layersOrder"
            [tooltip-marginTop]="10"
            [tooltip-width]="250"
        >
    </div>
    <div class="settings-row-value">
        <div class="light-dropdown" (click)="selectLayersOrder()">
            <div class="light-dropdown-title">{{ formatLayersOrder() }}</div>
            <img src="assets/img/pianoroll/toggled.svg">
        </div>
    </div>
</div> 

<div class="settings-row">
    <div class="settings-row-title">
        Structure Development
        <img 
            *ngIf="getTutorialMode()"
            class="tooltip-img"
            src="assets/img/info.svg"
            [tooltip]="tooltips.formTags"
            [tooltip-marginTop]="10"
            [tooltip-width]="250"
        >
    </div>
    <div class="settings-row-value">
        <div class="settings-row-value">
            <div class="light-dropdown" (click)="selectFormTags()">
                <div class="light-dropdown-title">{{ getFormTags() }}</div>
                <img src="assets/img/pianoroll/toggled.svg">
            </div>
        </div>
    </div>
</div>