import { Component, Input, EventEmitter, OnInit, Output } from "@angular/core"
import { ApiService } from "@services/api.service"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"

@Component({
    selector: "rename-profile-category",
    templateUrl: "./rename-profile-category.component.html",
    styleUrls: ["rename-profile-category.component.scss"],
})
export class RenameProfileCategoryComponent implements OnInit {
    @Input() category
    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor(
        private gpService: GenerationProfileService,
        private apiService: ApiService,
    ) {}

    ngOnInit() {}

    confirmName() {
        this.apiService
            .authRequest(
                "/generationprofile/renameCategory",
                { gpCategoryID: this.category._id, name: this.category.name },
                "primary",
                true
            )
            .then(() => {
                this.closeModal()

                this.gpService.refreshGPLibraryUI.next(true)
            })

            .catch(err => {
                this.apiService.handleError(err)
            })
    }

    closeModal() {
        this.close.emit()
    }
}
