<ng-container [ngSwitch]="type">
    <div class="{{ class }}" *ngIf="type == 'number'">
        <input
            type="number"
            placeholder="Min"
            [(ngModel)]="min"
            (change)="handleKeyboardEvent('min')" />

        <div class="to">to</div>

        <input
            type="number"
            placeholder="Max"
            [(ngModel)]="max"
            (change)="handleKeyboardEvent('max')" />
    </div>

    <div class="{{ class }}" *ngIf="type == 'dropdown'">
        <dropdown
            type="light"
            [value]="getMinDropDownValue()"
            [showTooltip]="false"
            [items]="dropdownOptions"
            (selectedItem)="selectedDropdownItem($event, 'min')"></dropdown>

        <div class="to">to</div>

        <dropdown
            type="light"
            [value]="getMaxDropDownValue()"
            [showTooltip]="false"
            [items]="dropdownOptions"
            (selectedItem)="selectedDropdownItem($event, 'max')"></dropdown>
    </div>
</ng-container>
