import { cloneDeep } from "lodash"
import PreviewNote from "./previewNote"
import InstrumentPatch from "../score/instrumentpatch"
import GPValidation from "./gpvalidation"
import { GPAccompanimentPackSchema } from "../../interfaces/db-schemas/generationprofile"
import { AccompanimentPackMusicEngine } from "../../interfaces/music-engine/generationprofile"
import { TIMESTEP_RES } from "../../constants/constants"
import { Time } from "../../modules/time"
import { AccompanimentManipulation } from "../../modules/accompaniment-pack/accompaniment-manipulation"

export interface Target {
    layer: string
    idx: number
}
export interface Synchronisation {
    target?: Target
    type?: string // subset, exact, include
}

export interface Transform {
    target: Target
}

export default class Pack {
    packID: string = ""
    name: string = ""
    preview: Array<PreviewNote> = []
    instruments: Array<InstrumentPatch> = []
    synchronisation: Synchronisation
    transform: Transform
    lowestNote: number
    octaves: number

    constructor(
        id: string,
        name: string,
        preview: Array<PreviewNote> = [],
        instruments: Array<InstrumentPatch> = [],
        lowestNote: number,
        octaves: number = 2
    ) {
        this.packID = id
        this.name = name
        this.preview = preview
        this.instruments = instruments
        this.lowestNote = lowestNote
        this.octaves = octaves
    }

    setPreview(preview: Array<PreviewNote>) {
        this.preview = preview
    }

    getMinAndMaxPitchInPreviewNotes() {
        let pitches = {
            min: 200,
            max: 0,
        }

        for (let previewNote of this.preview) {
            for (let pitch of previewNote.pitch) {
                pitches.min = Math.min(pitch, pitches.min)
                pitches.max = Math.max(pitch, pitches.max)
            }
        }

        return pitches
    }

    getPreviewLengthInTimesteps(): number {
        if (this.preview.length == 0) {
            return 0
        }

        let lastPreviewNote = this.preview[this.preview.length - 1]
        let end = Time.addTwoFractions(
            lastPreviewNote.onset,
            lastPreviewNote.duration
        )

        return Time.fractionToTimesteps(TIMESTEP_RES, end)
    }

    addInstrument(instrument: InstrumentPatch) {
        this.instruments.push(instrument)
    }

    encodeForDB(): GPAccompanimentPackSchema {
        let encodedInstruments = []

        for (let i of this.instruments) {
            let patch = {
                patch: typeof i == "string" ? i : i.patchID,
                noteTie: typeof i == "string" ? false : i.noteTie,
            }

            encodedInstruments.push(patch)
        }

        return {
            packID: this.packID,
            lowestNote: this.lowestNote,
            instruments: encodedInstruments,
            synchronisation:
                this.synchronisation != null ? this.synchronisation : {},
            transform: this.transform != null ? this.transform : {},
        }
    }

    encodeForME(): AccompanimentPackMusicEngine {
        let encodedInstruments = []

        for (let i of this.instruments) {
            let patch = {
                patch: typeof i == "string" ? i : i["patchID"],
                note_tie: typeof i == "string" ? false : i.noteTie,
            }

            encodedInstruments.push(patch)
        }

        return {
            pack_id: this.packID,
            lowest_note: this.lowestNote,
            instruments: encodedInstruments,
            synchronisation:
                this.synchronisation != null ? this.synchronisation : {},
            transform: this.transform != null ? this.transform : {},
        }
    }

    isMonophonicPreview() {
        let isMonophonic = false

        if (this.preview == null) {
            return isMonophonic
        }

        let allPitchesCombined = []

        for (let preview of this.preview) {
            for (let pitch of preview.pitch) {
                allPitchesCombined.push(pitch)
            }
        }

        allPitchesCombined = [...new Set(allPitchesCombined)]

        isMonophonic = allPitchesCombined.length == 1

        return isMonophonic
    }

    static fromJSON(object) {
        let pack: Pack = Object.assign(
            new Pack(
                "",
                "",
                object.index,
                object.instruments,
                object.lowestNote,
                object.octaves || 2
            ),
            object
        )
        return pack
    }

    copy() {
        let temp = JSON.parse(JSON.stringify(this))

        return Pack.fromJSON(temp)
    }

    validate(validateForUI = false, type = "pack") {
        let validation = new GPValidation(true, type, this, this.packID)

        if (
            this.instruments == null ||
            !Array.isArray(this.instruments) ||
            this.instruments["length"] == 0
        ) {
            validation.message = "Please add at least one instrument."
            validation.issue = "instrumentsMissing"
            validation.valid = false
        }

        return validation
    }

    cloneDeep() {
        return cloneDeep(this)
    }
}
