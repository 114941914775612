import { Injectable } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { filter } from "rxjs"
/**
 * This service is used to store short-lived data that needs to be shared across the entire application
 * (e.g. the chord progression generated in the composition workflow)
 * In order to preserve the data when reloading the component (e.g. changing the style in step 2).
 * the component also should remove the short-lived data from the cache whenever user navigates outside of the domain
 * of the component that needs the data. In order to make sure that the initialization of the component is not affected.
 */

@Injectable({
    providedIn: "root",
})
export class CacheService {
    private cache: { [key: string]: any } = {}

    constructor(private router: Router) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                if (!event.urlAfterRedirects.includes("composition-workflow")) {
                    this.remove("chordProgressionCache")
                }
            })
    }

    set(key: string, value: any): void {
        this.cache[key] = value
    }

    get(key: string): any {
        return this.cache[key]
    }

    remove(key: string): void {
        delete this.cache[key]
    }

    clear(): void {
        this.cache = {}
    }
}
