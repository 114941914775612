import { Query } from '@datorama/akita'
import { ScoreType } from '@common-lib/types/score'
import ScoreRenderingEngine from '../../../../../../common-lib/client-only/score-rendering-engine/engine'
import { AccompanimentDesignerState, AccompanimentDesignerStore } from './accompaniment-designer.store'
import AccompanimentPack from '@common-lib/classes/generationprofiles/accompaniment/accompanimentpack'

export class AccompanimentDesignerQuery extends Query<AccompanimentDesignerState> {
    public allStates$ = this.select()
    public packLoading$ = this.select("packLoading")
    public scoreLoading$ = this.select("scoreLoading")
    public loading$ = this.select(["packLoading", "scoreLoading"])
    public msMode$ = this.select("msMode")

    constructor(protected store: AccompanimentDesignerStore) {
        super(store)
    }

    public get engine() : ScoreRenderingEngine {
        return this.getValue().engine
    }

    public get pack() : AccompanimentPack {
        return this.getValue().pack
    }

    public get loadedPackID() : string | undefined {
        return this.getValue().packLoading.packID
    }

    public get packLoading() {
        return this.getValue().packLoading
    }

    public get generationProfileID() : string | undefined {
        return this.getValue().packLoading.generationProfileID
    }

    public get scoreLoading() {
        return this.getValue().scoreLoading
    }

    public get patternLength() {
        return this.getValue().patternLength
    }

    public get msMode() {
        return this.getValue().msMode
    }
}