<ng-container [ngSwitch]="type">
    <button
        *ngSwitchDefault
        class="a-button {{ type }} icon-{{ iconPos }} {{ class }}"
        [attr.av-data]="avData"
        [attr.aria-label]="label"
        [disabled]="disabled"
        (click)="onClick.emit($event)"
        (blur)="onBlur.emit($event)"
        (focus)="onFocus.emit($event)">
        <span class="tag" *ngIf="tag != null">{{ tag }}</span>
        <span class="label" *ngIf="label != null && label != ''">{{
            label
        }}</span>
        <img
            *ngIf="icon != null && icon != ''"
            [src]="icon"
            [style]="{ width: iconWidth, height: iconWidth }"
            [alt]="label + ' icon'" />
    </button>

    <button
        *ngSwitchCase="'with-right-chevron'"
        [attr.av-data]="avData"
        class="a-button {{ type }} icon-{{ iconPos }} {{ class }}"
        [attr.aria-label]="label"
        [disabled]="disabled"
        (click)="onClick.emit($event)"
        (blur)="onBlur.emit($event)"
        (focus)="onFocus.emit($event)">
        <img src="assets/img/hub/right_chevron.svg" class="right-chevron-img" />
        <span class="tag" *ngIf="tag != null">{{ tag }}</span>
        <span class="label" *ngIf="label != null && label != ''">{{
            label
        }}</span>
        <img
            *ngIf="icon != null && icon != ''"
            [src]="icon"
            [style]="{ width: iconWidth, height: iconWidth }"
            [alt]="label + ' icon'" />
    </button>

    <button
        *ngSwitchCase="'pulsating-icon'"
        class="a-button {{ type }} icon-{{ iconPos }} {{ class }}"
        [attr.av-data]="avData"
        [attr.aria-label]="label"
        [disabled]="disabled"
        (click)="onClick.emit($event)"
        (blur)="onBlur.emit($event)"
        (focus)="onFocus.emit($event)">
        <span class="tag" *ngIf="tag != null">{{ tag }}</span>
        <span class="label" *ngIf="label != null && label != ''">{{
            label
        }}</span>
        <div class="pulsating-circle"></div>
    </button>

    <button
        *ngSwitchCase="'round'"
        [attr.av-data]="avData"
        class="a-button {{ type }} {{ class }}"
        [disabled]="disabled"
        [attr.aria-label]="label"
        (click)="onClick.emit($event)"
        (blur)="onBlur.emit($event)"
        (focus)="onFocus.emit($event)">
        <img
            [src]="icon"
            [style]="{ width: iconWidth, height: iconWidth }"
            [alt]="label + ' icon'" />
    </button>

    <button
        *ngSwitchCase="'round-icon-and-text'"
        class="a-button round-icon-and-text {{ type }} icon-{{ iconPos }} {{
            class
        }}"
        [attr.av-data]="avData"
        [disabled]="disabled"
        [attr.aria-label]="label"
        (click)="onClick.emit($event)"
        (blur)="onBlur.emit($event)"
        (focus)="onFocus.emit($event)">
        <span class="label" *ngIf="label != null && label != ''">{{
            label
        }}</span>
        <div class="loader" *ngIf="loading"></div>
        <span class="icon" *ngIf="!loading">
            <img
                class="circle"
                [src]="icon"
                [style]="{ width: iconWidth, height: iconWidth }"
                [alt]="label + ' icon'" />
        </span>
    </button>
</ng-container>
