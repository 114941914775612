import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { ApiService } from "./api.service"
import { Composition } from "../../../../common-lib/general/classes/general/composition"
import Folder from "../models/folder"
import { Effect } from "../../../../common-lib/general/classes/score/effect"
import Layer from "@common-lib/classes/score/layer"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import GPSettings from "@common-lib/classes/generationprofiles/gpsettings"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import GPLibraryFilters from "../models/gplibraryfilters"
import { Router } from "@angular/router"
import { ContextMenu } from "../models/contextmenu"
import { WindowService } from "./window.service"
import { LayerFunctionType, MixingOption } from "@common-lib/types/score"
import Section from "@common-lib/classes/score/section"
import ScoreRenderingEngine from "../../../../common-lib/client-only/score-rendering-engine/engine"
import { SectionOperationType } from "@common-lib/types/note-editing"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import Pack from "@common-lib/classes/generationprofiles/pack"
import { MenuOptions } from "../components/reusable/menu-options/menu-options.component"
import { CompositionWorkflowType, LayersValue } from "@common-lib/types/general"
import PercussionLayer from "@common-lib/classes/score/percussionlayer"
import TrackBus from "@common-lib/classes/score/trackbus"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import SearchItem from "@common-lib/classes/searchitem"
import { SectionResizeType } from "@common-lib/interfaces/music-engine/general"
import { Trust } from "@common-lib/interfaces/db-schemas/trainingsets/scoresMetadata"

export interface CWCreateCompositionModalParameters {
    cwID: string
    folderID: string
}

export interface SetKeySignatureModalInput {
    keySignature: string
    onComplete: (key: string) => void
}

export interface ModalsValueWarning {
    value: any
    type: "bars" | "resolution" | "time-signature" | "key-mode" | "strategy"
    boolean: boolean
    showModal: boolean
}

export interface ScoreCorruptedModalParams {
    saveChanges: () => void
}
export interface ModalsButton {
    type: "primary" | "secondary"
    text: string
    onClick: { <Type>(arg: Type): void }
}

export interface AddCustomLayerModal {
    onComplete: { (layerType: LayerFunctionType): void }
}
export interface SimpleModal {
    title: string
    message: string
    buttons: ModalsButton[]
}

export interface LayerModal {
    layer: Layer
    onComplete: <T>(arg: T, layer: Layer) => void
}

export interface ChangeLayerColorModal {
    layer: Layer
}

export interface RenameModal {
    name: string
    title?: string
    onComplete: { (name: string): void }
}
export interface MixingOptionsModal {
    buttonTitle: string
    onComplete: {
        (selectedOption: MixingOption): string
    }
}

export interface TrainingsetLabelsModal {
    buttonTitle: string
    trainingsetID: string
    onComplete: {
        (labels: Trust): string
    }
}

export interface UnsupportedOsModal {
    isUnsupportedOS: boolean
    isUnsupportedArmOS: boolean
}

export interface CustomizePianorollEffect {
    bassBoost: boolean
    vinyl: boolean

    toggleBassBoost: (value: boolean) => void
    toggleVinyl: (value: boolean) => void
}

export interface AutoMixingModal {
    type: "composition" | "generationProfile"
    layers: (GPLayer | Layer)[]
    autoMix: boolean
    onToggleAutoMixing: (value: boolean) => void
    onChangeGainBias: (value: number, layer: Layer | GPLayer) => void
}

export interface SectionEditingModal {
    section: Section
    engine: ScoreRenderingEngine
    type: SectionOperationType
    position: "before" | "after"
    onComplete: () => void
}

export interface SectionInpaintingModalInput {
    section: Section
    position: "before" | "after"
    engine: ScoreRenderingEngine
    onComplete: () => void
    inpaintSection: Function
    getSourceSectionChords: Function
}

export interface LayerGenerationModalInput {
    section: Section
    layerName: string
    engine: ScoreRenderingEngine
    onComplete: () => void
    generateLayer: Function
}

export interface CreateTemplateAccompanimentPack {
    sourcePack: AccompanimentPack
    generationProfileID: string
    layer: string
    preCompletion: () => void
}

export interface DoublingInstrumentSelectionModalParameters {
    layer: Layer | GPLayer
    allowRecommendations: boolean
    double: InstrumentPatch | undefined
    pack: Pack | undefined

    selectNewInstrumentPatches: (
        newInstrumentPatches: InstrumentPatch[]
    ) => boolean

    onClose: () => void
}

export interface EditDoubleFolderModalParameters {
    folder: SearchItem
    layer: Layer | GPLayer
    onClose: () => void
}

export interface EditDoubleModalParameters {
    double: InstrumentPatch
    layer: Layer | GPLayer
    pack: Pack | undefined
    allowRecommendations: boolean
    folderID: string

    onClose: () => void
}

export interface GPInstrumentSelectionModalParameters {
    layer: GPLayer
    pack: Pack
}

export interface SectionCopyChordsParameters {
    section: Section
    sections: Section[]
    engine: ScoreRenderingEngine
}

export interface SectionResizeParameters {
    section: Section
    engine: ScoreRenderingEngine
    type: SectionResizeType
}

export interface InstrumentSelectionModalParameters {
    layer: Layer | GPLayer
    allowRecommendations: boolean
    selectedTrackBus: TrackBus | undefined
    pack: Pack | undefined

    selectNewInstrumentPatch: (newInstrumentPatch: InstrumentPatch) => boolean
    onClose: () => void
}

export interface InstrumentSelectionCompositionWorkflowsParameters {
    step: "instrument-search" | "double-search"
    layer: Layer | PercussionLayer
    trackBus: TrackBus
    replacePatch: (newPatch: InstrumentPatch) => void
}

export interface SelectCompositionWorkflowModalParameters {
    type: CompositionWorkflowType
}

export interface FindSimilarInstrumentsModalParameters {
    layer: GPLayer
    pack: Pack
    instruments: InstrumentPatch[]
    onClose: () => void
}

export interface SelectLayerModalParameters {
    alreadyHasLayers: LayersValue[]
    supportedLayers: LayersValue[]

    done: (selectedLayer: LayersValue) => Promise<{
        success: boolean
        error?: string
    }>
}

export interface SettingsModalInput {
    type: "audio" | "about"
}
export interface SelectPackModal {
    type: "add" | "change"
    layer
    pack?: AccompanimentPack | Pack
    openMenu?: "aiva" | "currentUser"
}

export interface StyleModalForCWParams {
    type?: CompositionWorkflowType
    subtitle?: string
    selectGPForCW?: (gp) => void
    loading?: boolean
}

export interface SuccessModalParams {
    title: string
    subtitle: string
    note: string
}

export interface DownloadAllIntrumentsModalParameters {
    onClose: () => void
}
export interface DoActionBeforeModalParameters {
    /**
     * This function is fired when the user clicks on the continue button, and a loading
     * spinner is shown until the promise is resolved.
     */
    action: (dontShowAgain) => Promise<{
        success: boolean
        error?: string
    }>

    /**
     * This function is fire after the modal is closed
     */
    afterAction?: () => void

    cancel?:
        | (() => void)
        | ((dontShowAgain) => Promise<{
              success: boolean
              error?: string
          }>)

    title?: string
    description?: string
    continueButtonText?: string
    color?: "delete"
    modalId?: string
}
@Injectable()
export class ModalService {
    contextMenus = {
        instrumentRecommendations: new BehaviorSubject<ContextMenu>(null),
        addDoubles: new BehaviorSubject<ContextMenu>(null),
        doublesSelection: new BehaviorSubject<ContextMenu>(null),
        accompanimentFilter: new BehaviorSubject<ContextMenu>(null),
        addInstruments: new BehaviorSubject<ContextMenu>(null),
        localContextMenu: new BehaviorSubject<MenuOptions<any>>(undefined),
        findSimilarInstrumentsFilter: new BehaviorSubject<ContextMenu>(null),
    }

    modals = {
        setKeySignature: new BehaviorSubject<SetKeySignatureModalInput>(
            undefined
        ),

        selectCompositionWorkflow:
            new BehaviorSubject<SelectCompositionWorkflowModalParameters>(
                undefined
            ),
        cwCreateComposition:
            new BehaviorSubject<CWCreateCompositionModalParameters>(undefined),

        editDoubleFolder: new BehaviorSubject<EditDoubleFolderModalParameters>(
            undefined
        ),

        editDouble: new BehaviorSubject<EditDoubleModalParameters>(undefined),

        findSimilarInstrumentsForPack:
            new BehaviorSubject<FindSimilarInstrumentsModalParameters>(
                undefined
            ),

        instrumentDownloading: new BehaviorSubject<boolean>(undefined),

        gpInstrumentSelectionForPack:
            new BehaviorSubject<GPInstrumentSelectionModalParameters>(
                undefined
            ),

        instrumentSelection:
            new BehaviorSubject<InstrumentSelectionModalParameters>(undefined),

        doublingInstrumentSelection:
            new BehaviorSubject<DoublingInstrumentSelectionModalParameters>(
                undefined
            ),

        selectLayer: new BehaviorSubject<SelectLayerModalParameters>(undefined),
        doActionsBefore: new BehaviorSubject<DoActionBeforeModalParameters>(
            undefined
        ),
        createTemplateAccompanimentPack:
            new BehaviorSubject<CreateTemplateAccompanimentPack>(undefined),
        chordDatasetSelectionModal: new BehaviorSubject<
            GenerationProfile | undefined
        >(undefined),
        rename: new BehaviorSubject<RenameModal>(undefined),
        addCustomLayer: new BehaviorSubject<AddCustomLayerModal>(undefined),
        simpleModal: new BehaviorSubject<SimpleModal>(null),
        scoreCorrupted: new BehaviorSubject<ScoreCorruptedModalParams>(
            undefined
        ),
        gpInfluence: new BehaviorSubject<any>(null),
        outdatedDesktopApp: new BehaviorSubject<boolean>(false),
        incorrectDesktopAppArch: new BehaviorSubject<boolean>(false),
        autofill: new BehaviorSubject<any>(null),
        renameProfileCategory: new BehaviorSubject<any>(null),
        enterUsername: new BehaviorSubject<boolean>(false),
        getPackScore: new BehaviorSubject<boolean>(false),
        noPacksToSynchTo: new BehaviorSubject<boolean>(false),
        layersOrder: new BehaviorSubject<GPSettings>(null),
        selectFormTags: new BehaviorSubject<GPSettings>(null),
        packSynchronisation: new BehaviorSubject<any>(null),
        addLayerToGP: new BehaviorSubject<GenerationProfile>(null),
        deleteGenerationProfile: new BehaviorSubject<GenerationProfile>(null),
        deleteGenerationProfileLayer: new BehaviorSubject<GenerationProfile>(
            null
        ),
        sectionInpainting: new BehaviorSubject<SectionInpaintingModalInput>(
            undefined
        ),
        layerGeneration: new BehaviorSubject<LayerGenerationModalInput>(
            undefined
        ),
        sectionEditing: new BehaviorSubject<SectionEditingModal>(undefined),
        renameGenerationProfile: new BehaviorSubject<GenerationProfile>(null),
        selectPack: new BehaviorSubject<SelectPackModal>(undefined),
        gpLayerMixing: new BehaviorSubject<GPLayer>(null),
        autoMixing: new BehaviorSubject<AutoMixingModal | undefined>(undefined),
        shareGP: new BehaviorSubject<GenerationProfile>(null),
        gpDevelopment: new BehaviorSubject<GPSettings>(null),
        emotionConfirmation: new BehaviorSubject<number>(null),
        createGP: new BehaviorSubject<boolean>(false),
        gpLibraryFilters: new BehaviorSubject<GPLibraryFilters>(null),
        downloadAllInstruments:
            new BehaviorSubject<DownloadAllIntrumentsModalParameters>(null),
        resumeDownloadAllInstruments: new BehaviorSubject<any>(null),
        publishGP: new BehaviorSubject<GenerationProfile>(null),
        pitchRangeControl: new BehaviorSubject<any>(null),
        compareTwoGPs: new BehaviorSubject<any>(null),
        rewardClaimed: new BehaviorSubject<string>(null),
        selectGPInfluence: new BehaviorSubject<any>(null),
        materialVariations: new BehaviorSubject<boolean>(false),
        renamePack: new BehaviorSubject<string>(null),
        refreshGPSourcePacks: new BehaviorSubject<boolean>(false),
        renameLayer: new BehaviorSubject<LayerModal>(undefined),
        changeLayerColor: new BehaviorSubject<LayerModal>(undefined),
        mixingOptions: new BehaviorSubject<MixingOptionsModal>(undefined),
        trainingsetLabels: new BehaviorSubject<TrainingsetLabelsModal>(
            undefined
        ),
        unsupportedOS: new BehaviorSubject<UnsupportedOsModal>(undefined),
        changelog: new BehaviorSubject<boolean>(false),
        customizePianorollFX: new BehaviorSubject<
            CustomizePianorollEffect | undefined
        >(undefined),
        styleModalForCW: new BehaviorSubject<StyleModalForCWParams | undefined>(
            undefined
        ),
        settings: new BehaviorSubject<SettingsModalInput>(undefined),
        successModal: new BehaviorSubject<SuccessModalParams | undefined>(
            undefined
        ),
        sectionCopyChords$: new BehaviorSubject<SectionCopyChordsParameters>(
            undefined
        ),
        sectionResize$: new BehaviorSubject<SectionResizeParameters>(undefined),
        desktopAppBugReport$: new BehaviorSubject<boolean>(false),
        layerInpaintingNotAllowedWarning$: new BehaviorSubject<boolean>(false),
    }

    compositionCreationWithGP: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    )
    listeningTestsFeedback: BehaviorSubject<object> =
        new BehaviorSubject<object>(null)
    changeDrumsModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )

    downloadDesktopAppModal: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false)
    compatibilityModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )
    effectsSettings: BehaviorSubject<Effect> = new BehaviorSubject<Effect>(null)
    newEmail: BehaviorSubject<string> = new BehaviorSubject<string>("")
    renamedComposition: BehaviorSubject<Composition> =
        new BehaviorSubject<Composition>(null)
    deleteComposition: BehaviorSubject<Composition> =
        new BehaviorSubject<Composition>(null)
    downloadComposition: BehaviorSubject<Composition> =
        new BehaviorSubject<Composition>(null)
    shareComposition: BehaviorSubject<Composition> =
        new BehaviorSubject<Composition>(null)
    shareFolder: BehaviorSubject<Composition> =
        new BehaviorSubject<Composition>(null)
    downloadFolder: BehaviorSubject<Folder> = new BehaviorSubject<Folder>(null)
    deleteFolder: BehaviorSubject<Folder> = new BehaviorSubject<Folder>(null)
    deleteMulti: BehaviorSubject<Object> = new BehaviorSubject<Object>(null)
    renamedFolder: BehaviorSubject<Folder> = new BehaviorSubject<Folder>(null)
    subscriptionDescription: BehaviorSubject<string> =
        new BehaviorSubject<string>("")
    subscriptionEmbed: string = ""
    subscriptionEmbedLink: string = ""
    changePassword: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )
    createWithInfluence: BehaviorSubject<string> = new BehaviorSubject<string>(
        ""
    )
    customizePreset: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )
    presetUsecase: BehaviorSubject<Object> = new BehaviorSubject<Object>(null)
    billingPreview: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    subscriptionStatusInfo: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    )
    discardInstrumentation: BehaviorSubject<Object> =
        new BehaviorSubject<Object>(null)
    incompatibleComposition: BehaviorSubject<boolean> =
        new BehaviorSubject<any>(false)
    updateModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    comment: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    shortcutModal: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    copyAutomationAllLayers: BehaviorSubject<any> = new BehaviorSubject<any>(
        false
    )
    justCreatedComposition: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false)
    displayCreateModalMenus: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true)
    createFromPlaylist: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )
    drumSequencerValueWarning: BehaviorSubject<ModalsValueWarning> =
        new BehaviorSubject<ModalsValueWarning>(null)
    accompanimentDesignerValueWarning: BehaviorSubject<ModalsValueWarning> =
        new BehaviorSubject<ModalsValueWarning>(null)
    compositionWorkflowValueWarning: BehaviorSubject<ModalsValueWarning> =
        new BehaviorSubject<ModalsValueWarning>(null)
    requestPatchError: BehaviorSubject<{ error?: any; showModal: boolean }> =
        new BehaviorSubject<{ error?: any; showModal: boolean }>(null)
    createWithInfluenceParameters: BehaviorSubject<any> =
        new BehaviorSubject<any>({
            ensemble: null,
            duration: "auto",
            numberOfCompositions: "1",
            key: null,
            view: "parameters",
        })
    applySectionEditing: BehaviorSubject<any> = new BehaviorSubject<any>(null)

    createType = "preset"

    collaborators = []

    constructionMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )

    drumSequencerDropdownOpen: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false)
    openCompositionModal: BehaviorSubject<string> = new BehaviorSubject<string>(
        undefined
    )

    constructor(
        protected router: Router,
        protected apiService: ApiService,
        private windowService: WindowService
    ) {}

    async getVersion() {
        try {
            let res = await this.apiService.request(
                "/version",
                {},
                "primary",
                "post",
                false
            )

            this.apiService.isUpdating.next(res.updating)

            this.constructionMode.next(res.constructionMode)

            if (
                res.version != null &&
                this.windowService.versionNumber != res.version
            ) {
                if (this.windowService.desktopAppAPI !== undefined) {
                    this.windowService.generalAPI.promptToUpdateApp(true)
                } else {
                    this.updateModal.next(true)
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    createTrackAnalytics(customizeParameters, createTrack) {
        return this.apiService
            .authRequest(
                "/analytics/createTrack",
                {
                    customizeParameters: customizeParameters,
                    createTrack: createTrack,
                },
                "primary",
                true
            )
            .then(res => {
                return Promise.resolve()
            })

            .catch(err => {
                // No need to alert the user about this error if it happens
                return Promise.resolve()
            })
    }

    isInfluenceCreateType() {
        return this.createType.includes("influence")
    }
}
