<div
    class="cw-layer-container"
    *ngIf="
        cwService.query.getLayerStatus(layerValue) === 'finished' &&
        layer !== undefined
    ">
    <div
        class="top-container"
        (click)="toggleTrackBusSettingsMenu($event)"
        [attr.av-data]="index + '-instrument-name'">
        <div class="top-left-container">
            <div class="layer-title" *ngIf="layer !== undefined">
                {{ layer.getName() }}
            </div>

            <div
                class="instrument-title"
                *ngIf="layer !== undefined && layer.trackBuses.length === 1">
                {{ layer.trackBuses[0].getPatch(instruments) }}
            </div>

            <div
                class="instrument-title"
                *ngIf="
                    layer !== undefined &&
                    layer.trackBuses.length > 1 &&
                    compositionWorkflowLayer !== undefined
                ">
                {{ compositionWorkflowLayer.instrument.name }}
            </div>
        </div>
        <div class="top-right-container" *ngIf="!trackBusIsLoading()">
            <img src="assets/img/chevron_down.svg" />
        </div>

        <div class="top-right-container" *ngIf="trackBusIsLoading()">
            <div class="loader"></div>
        </div>
    </div>

    <div class="bottom-container" *ngIf="!isLoading">
        <loudness-meter
            [trackBusses]="layer.trackBuses"
            [engine]="cwService.engine"
            [attr.av-data]="index + '-volume-meter'"></loudness-meter>

        <div class="cw-layer-buttons">
            <img
                src="assets/img/thin_pencil.svg"
                (click)="action('edit')"
                [tooltip]="'Edit layer'"
                [tooltip-marginTop]="10"
                [tooltip-position]="'middle-bottom'"
                [attr.av-data]="index + '-edit-btn'" />
            <img
                src="assets/img/menu/regenerate.svg"
                (click)="action('regenerate')"
                [tooltip]="'Regenerate layer'"
                [tooltip-marginTop]="10"
                [tooltip-position]="'middle-bottom'"
                [attr.av-data]="index + '-regenerate-btn'" />
            <!--<img
                src="assets/img/delete.svg"
                (click)="action('delete')"
                [tooltip]="'Delete layer'"
                [tooltip-marginTop]="10"
                [tooltip-position]="'middle-bottom'" />-->
        </div>

        <mute-and-solo
            [engine]="cwService.engine"
            [mute]="mute.bind(this)"
            [solo]="solo.bind(this)"
            [trackBusses]="layer.trackBuses"
            [attr.av-data]="index + '-mute-solo'"></mute-and-solo>
    </div>

    <div class="loader-container" *ngIf="isLoading">
        <div class="loader"></div>
    </div>
</div>

<div
    class="lazy-loading-cw-layer-container cw-layer-container"
    *ngIf="cwService.query.getLayerStatus(layerValue) === 'loading'"
    av-data="cw-layer-loading">
    <div class="top-container">
        <div class="top-left-container">
            <div class="layer-title"></div>

            <div class="instrument-title"></div>
        </div>
    </div>

    <div class="bottom-container"></div>
</div>

<div
    class="lazy-error-cw-layer-container cw-layer-container"
    *ngIf="cwService.query.getLayerStatus(layerValue) === 'error'"
    av-data="cw-layer-error">
    <div class="top-container">
        <div class="top-left-container">
            <div class="layer-title">
                {{ layer !== undefined ? layer.getName() : layerValue }}
            </div>

            <div class="instrument-title" av-data="cw-layer-error-message">
                Failed to generate this layer
            </div>
        </div>
    </div>

    <div class="bottom-container">
        <a-button
            [class]="'composition-workflow-layer'"
            (click)="action('regenerate')"
            width="100%"
            label="Retry"></a-button>
        <a-button
            [class]="'composition-workflow-layer'"
            (click)="action('delete')"
            width="100%"
            label="Delete"></a-button>
    </div>
</div>

<menu-modifiers
    [modifiers]="trackBusSettings"
    *ngIf="trackBusSettings !== undefined"
    (close)="toggleTrackBusSettingsMenu($event, true)"></menu-modifiers>
