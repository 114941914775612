import { Component, ElementRef, HostBinding, Input, OnInit, AfterViewInit, Output, EventEmitter } from "@angular/core"
import GPLibraryFilters from "@clientmodels/gplibraryfilters"
import { ApiService } from "@services/api.service"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { UserService } from "@services/user.service"


@Component({
    selector: 'gp-library-filters',
    templateUrl: 'gp-library-filters.component.html'
})
export class GPLibraryFiltersComponent implements OnInit {
    @Input() filters:GPLibraryFilters
    @Output() close:EventEmitter<any> = new EventEmitter()

    constructor(private gpService:GenerationProfileService, private apiService:ApiService) {

    }

    ngOnInit() {

    }
    
    changedSimilarityRange(event) {
        this.filters.similarity.min = event.min
        this.filters.similarity.max = event.max

        this.gpService.refreshGPLibraryUI.next(true)
    }

    isSelectedFilter(filter, type) {
        let array = this.filters.styles

        if (type == 'admin') {
            array = this.filters.admin
        }

        return array.includes(filter) ? filter : ''
    }

    getAllStyles() {
        let styles = []

        for (let style of this.gpService.gpLibraryStyles) {
            if (style == null) {
                continue
            }

            styles.push(style)
        }

        return styles
    }

    closeModal() {
        this.close.emit()
    }

    isAIVAAccount() {
        return this.apiService.user.getValue().split("@")[1] == "aiva.ai"
    }

    changeSelectedFilters(event, type) {
        if (event.checked) {
            if (type == 'styles') {
                this.filters.styles.push(event.value)
            }

            else if (type == 'createdBy') {
                this.filters.createdBy.push(event.value)
            }

            else if (type == 'admin') {
                this.filters.admin.push(event.value)
            }
        }

        else {
            let array = this.filters.styles

            if (type == 'admin') {
                array = this.filters.admin
            }

            else if (type == 'createdBy') {
                array = this.filters.createdBy
            }
            
            for (let s = 0; s < array.length; s++) {
                let style = array[s]

                if (style == event.value) {
                    array.splice(s, 1)

                    break
                }
            }
        }

        this.gpService.refreshGPLibraryUI.next(true)
    }
}
