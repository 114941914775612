import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef } from '@angular/core'

@Component({
    selector: 'test',
    templateUrl: 'test.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['test.component.scss']
})

export class TestComponent {
    constructor(private ref: ChangeDetectorRef) {

    }

    detectChanges() {
        this.ref.detectChanges()
    }

    testFunction() {

    }
}