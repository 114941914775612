import { Injectable } from "@angular/core"
import { AudioContext } from "standardized-audio-context" // this AudioContext is used to make copyToChannel work in Safari
import { detect } from "detect-browser"
import { WindowService } from "@services/window.service"
import { DeviceInfo } from "@common-lib/interfaces/api/desktop-app.api"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../types/dropdownItemType"
import { AnimationLoopService } from "@services/animationloop.service"
import { BehaviorSubject } from "rxjs"
import { Misc } from "@common-lib/modules/misc"
import { playerActions } from "../../../../../common-lib/client-only/general/classes/playerStateManagement"

@Injectable()
export class AudioService {
    private audioDevices: DeviceInfo[] = []
    private selectedDeviceID: string = "-1"

    constructor(private windowService: WindowService) {}

    public async getAudioDevices(): Promise<void> {
        if (this.windowService.samplerAPI.getOutputAudioDevices === undefined) {
            return
        }

        const audioDevices =
            await this.windowService.samplerAPI.getOutputAudioDevices()

        const selectedDeviceID =
            await this.windowService.samplerAPI.getSelectedDevice()

        if (
            audioDevices === undefined ||
            selectedDeviceID === undefined ||
            (Misc.arraysAreEqual(audioDevices, this.audioDevices) &&
                this.selectedDeviceID === selectedDeviceID)
        ) {
            return
        }

        this.audioDevices = audioDevices
        this.selectedDeviceID = selectedDeviceID
    }

    public async getAllDevices(): Promise<{
        audioDevices: DeviceInfo[]
        selectedDeviceID: string
    }> {
        const defaultResult = {
            audioDevices: [],
            selectedDeviceID: "-1",
        }

        try {
            if (
                this.windowService.samplerAPI.getOutputAudioDevices ===
                undefined
            ) {
                return defaultResult
            }

            const audioDevices =
                await this.windowService.samplerAPI.getOutputAudioDevices()

            const selectedDeviceID =
                await this.windowService.samplerAPI.getSelectedDevice()

            return {
                audioDevices,
                selectedDeviceID,
            }
        } catch (error) {
            console.error("getAllDevices", error)
            return defaultResult
        }
    }

    public refreshDevices() {
        this.audioDevices = []
        this.selectedDeviceID = "-1"
    }

    public async selectAudioDevice(event: DropdownSelection<string>) {
        this.selectedDeviceID = event.new.value

        await this.windowService.samplerAPI.setOutputDeviceByID({
            deviceID: this.selectedDeviceID,
        })

        playerActions.setRestartPlayback("audioService", Date.now())
    }

    public async getSelectedDevice(
        hasOutputPrefix?: boolean
    ): Promise<DropdownItemType<string>> {
        if (this.audioDevices.length === 0) {
            await this.getAudioDevices()
        }

        let selectedInfo = this.audioDevices.find(
            r => r.name === this.selectedDeviceID
        )

        if (selectedInfo === undefined) {
            selectedInfo = this.audioDevices.find(r => r.isDefaultDevice)

            if (selectedInfo === undefined) {
                selectedInfo = this.audioDevices[0]
            }
        }

        const name =
            selectedInfo === undefined
                ? ""
                : AudioService.formatDeviceName(selectedInfo.name)

        return {
            name,
            value: selectedInfo === undefined ? "" : selectedInfo.name,
        }
    }

    public async getAudioOutputDevicesDropdownList(
        addOutputPrefix?: boolean
    ): Promise<DropdownItemType<string>[]> {
        if (this.audioDevices.length === 0) {
            await this.getAudioDevices()
        }

        return this.audioDevices.map(el => {
            const name = AudioService.formatDeviceName(el.name)

            return {
                name,
                value: el.name,
            }
        })
    }

    static formatDeviceName(name: string) {
        if (name.split(": ").length > 1) {
            return name.split(": ")[1]
        }

        return name
    }

    static removeExtraDotsFromFileName(fileName: string): string {
        const split = fileName.split(".")

        if (split.length > 0) {
            fileName =
                fileName
                    .replace("." + split[split.length - 1], "")
                    .replace(".", "_") +
                "." +
                split[split.length - 1]
        }

        return fileName
    }

    static initInfluenceAudio(audioFile) {
        const canPlayMP3 = new Audio().canPlayType("audio/mpeg")

        if (!canPlayMP3) {
            throw "Your browser does not support this audio feature."
        }

        try {
            const influenceAudio = new Audio(URL.createObjectURL(audioFile))

            return influenceAudio
        } catch (error) {
            console.error(error)
            throw "You can only upload a MIDI or Audio file."
        }
    }

    static createAudioCtx(): AudioContext {
        let browser = detect()
        let audioCtx: AudioContext

        if (browser.name == "safari") {
            audioCtx = new AudioContext({})
        } else {
            audioCtx = new AudioContext({
                sampleRate: 44100,
            })
        }

        return audioCtx
    }

    static async convertFileToAudioBuffer(file: File): Promise<AudioBuffer> {
        let audioCtx: AudioContext = this.createAudioCtx()

        let arrayBuffer: ArrayBuffer = await file.arrayBuffer()

        let audioBuffer: AudioBuffer = await audioCtx.decodeAudioData(
            arrayBuffer
        )

        return audioBuffer
    }

    /**
     *
     * @param parameters Start and end, in seconds
     * @param audioBuffer
     * @returns
     */
    static async trimAudioBuffer(
        parameters: { start: number; end: number },
        audioBuffer: AudioBuffer
    ): Promise<AudioBuffer> {
        if (
            parameters.end - parameters.start <= 0 ||
            parameters.start >= parameters.end
        ) {
            return Promise.reject("Start time must be smaller than end time")
        }

        let audioCtx: AudioContext = this.createAudioCtx()

        // Compute start and end values in seconds
        let startInSamples = Math.round(
            parameters.start * audioBuffer.sampleRate
        )
        let endInSamples = Math.round(parameters.end * audioBuffer.sampleRate)

        // Create a new buffer
        let cutAudioBuffer: AudioBuffer = audioCtx.createBuffer(
            audioBuffer.numberOfChannels,
            endInSamples - startInSamples,
            audioBuffer.sampleRate
        )

        // Copy from old buffer to new with the right slice
        for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
            let newChannelData = audioBuffer
                .getChannelData(i)
                .slice(startInSamples, endInSamples)

            cutAudioBuffer.copyToChannel(newChannelData, i)
        }

        return cutAudioBuffer
    }

    static blobToFile(blob, fileName) {
        return new File([blob], fileName, {
            lastModified: new Date().getTime(),
            type: blob.type,
        })
    }

    // Convert an AudioBuffer to a Blob using WAVE representation
    static audioBufferToWavBlob(audioBuffer, len) {
        var numOfChan = audioBuffer.numberOfChannels,
            length = len * numOfChan * 2 + 44,
            buffer = new ArrayBuffer(length),
            view = new DataView(buffer),
            channels = [],
            i,
            sample,
            offset = 0,
            pos = 0

        // write WAVE header
        setUint32(0x46464952) // "RIFF"
        setUint32(length - 8) // file length - 8
        setUint32(0x45564157) // "WAVE"

        setUint32(0x20746d66) // "fmt " chunk
        setUint32(16) // length = 16
        setUint16(1) // PCM (uncompressed)
        setUint16(numOfChan)
        setUint32(audioBuffer.sampleRate)
        setUint32(audioBuffer.sampleRate * 2 * numOfChan) // avg. bytes/sec
        setUint16(numOfChan * 2) // block-align
        setUint16(16) // 16-bit (hardcoded in this demo)

        setUint32(0x61746164) // "data" - chunk
        setUint32(length - pos - 4) // chunk length

        function setUint16(data) {
            view.setUint16(pos, data, true)
            pos += 2
        }

        function setUint32(data) {
            view.setUint32(pos, data, true)
            pos += 4
        }

        // write interleaved data
        for (i = 0; i < audioBuffer.numberOfChannels; i++) {
            channels.push(audioBuffer.getChannelData(i))
        }

        while (pos < length) {
            for (i = 0; i < numOfChan; i++) {
                // interleave channels
                sample = Math.max(-1, Math.min(1, channels[i][offset])) // clamp
                sample =
                    (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0 // scale to 16-bit signed int
                view.setInt16(pos, sample, true) // write 16-bit sample
                pos += 2
            }
            offset++ // next source sample
        }

        // create Arrays for both the left and the right channel
        var left, right
        let wavHeaderOffset = numOfChan * 44 // ignore wav header part of the buffer, else the header will be audible as a clipping sound at the start of the audio

        if (numOfChan == 2) {
            let data = new Int16Array(buffer, 0, buffer.byteLength / 2)
            let leftData = []
            let rightData = []
            for (let i = wavHeaderOffset; i < data.length; i += 2) {
                leftData.push(data[i])
                rightData.push(data[i + 1])
            }

            left = new Int16Array(leftData)
            right = new Int16Array(rightData)
        } else {
            left = new Int16Array(buffer.slice(wavHeaderOffset))
            right = left
        }

        return {
            channels: audioBuffer.numberOfChannels,
            sampleRate: audioBuffer.sampleRate,
            left: left.buffer,
            right: right.buffer,
            buffer,
            blob: new Blob([buffer], { type: "audio/wav" }),
        }
    }

    static async encodeAudioBuffer(
        audioBuffer: AudioBuffer,
        filename: string
    ): Promise<File> {
        // First, you want to convert the AudioBuffer to a Blob (https://developer.mozilla.org/en-US/docs/Web/API/Blob), so that you can send it to an AudioWorker (https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Using_web_workers)
        // The WebWorker is very important here, because it will do some compute intensive task (encoding to mp3), and we don't want to block the main thread while it's doing that (otherwise the application will appear as unresponsive)
        // See audioBufferToWavBlob in influence.service.ts

        let wavObject = this.audioBufferToWavBlob(
            audioBuffer,
            audioBuffer.length
        )

        // Then, create WebWorker (see return statement of encodeAndUpload in influence.component.ts)

        let worker = new Worker("./assets/lib/audio.worker.js")

        // Then, send blob to WebWorker (see encodeAudioBufferAndUploadMP3 in influence.component.ts)

        if (!wavObject || !wavObject.left) {
            return Promise.reject("Error while encoding MP3")
        }

        let left = wavObject.left
        let right = wavObject.right != null ? wavObject.right : left // only stereo has right channel as well

        return new Promise((resolve, reject) => {
            worker.onmessage = async event => {
                if (event.data.res == "end" && event.data.mp3Buffer) {
                    filename = filename.replace(".wav", ".mp3")

                    let mp3Blob = new Blob([event.data.mp3Buffer], {
                        type: "audio/mpeg",
                    })
                    let mp3File = this.blobToFile(mp3Blob, filename)

                    return resolve(mp3File)
                }
            }

            worker.onerror = error => {
                return reject("Error while encoding MP3")
            }

            if (audioBuffer.numberOfChannels == 2) {
                worker.postMessage(
                    {
                        channels: wavObject.channels,
                        sampleRate: wavObject.sampleRate,
                        left: left,
                        right: right,
                        mode: 0,
                    },
                    [left, right]
                )
            } else {
                worker.postMessage(
                    {
                        channels: wavObject.channels,
                        sampleRate: wavObject.sampleRate,
                        left: left,
                        right: right,
                        mode: 0,
                    },
                    [left]
                )
            }
        })
    }
}
