import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core"
import { DesignService } from "@services/design.service"

export interface MenuOptions<T> {
    options: MenuOption<T>[]
    onClose?: () => void
    title?: string
    coordinates: {
        x: number
        y: number
    }
}

export interface MenuOption<T> {
    data: T | MenuOption<T>[]
    icon: string | undefined
    text: string
    loading: boolean
    buttonClass?: "delete" | "rename" | "primary" | "secondary"
    subtitle?: string | undefined
    avData?: string

    /**
     * This function is called directly when the user clicks on the menu option.
     */
    onClick: { (arg: T, event: MouseEvent): any }

    /**
     * This function is called when the user confirms the action.
     * For example, when the user clicks on the "Rename" option, the "Rename" option
     * will be replaced with a text input. When the user clicks on the "Confirm" button,
     * this function will be called.
     */
    onConfirm?: { (arg: T): Promise<void> }
}

@Component({
    selector: "menu-options",
    templateUrl: "menu-options.component.html",
    styleUrls: ["menu-options.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuOptionsComponent {
    @Output() close: EventEmitter<any> = new EventEmitter()
    @Input() menuOptions: MenuOptions<any>
    @Input() width: number | undefined
    @Input() title: string | undefined
    @ViewChild("menuOptionsContainer") menuOptionsContainer: ElementRef

    public hoveredOption:
        | { option: MenuOption<any>; element: HTMLDivElement }
        | undefined

    private readonly padding: number = 18

    constructor(
        private ref: ChangeDetectorRef,
        private designService: DesignService
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.menuOptions.coordinates.x = this.formatX(
            this.menuOptions.coordinates.x
        )

        this.menuOptions.coordinates.y = this.formatY(
            this.menuOptions.coordinates.y
        )

        this.ref.detectChanges()
    }

    public getWidth(): number {
        return (
            this.menuOptionsContainer.nativeElement.getBoundingClientRect()
                .width - this.padding
        )
    }

    /**
     * This function is used in order to reposition the y coordinate value in case
     * the menu options are too close to the bottom of the screen.
     * @param y
     */
    private formatY(y: number): number {
        const playerBarHeight = parseInt(
            this.designService
                .getCSSProperty("player-bar-height")
                .replace("px", "")
        )

        const endY =
            y +
            this.menuOptionsContainer.nativeElement.getBoundingClientRect()
                .height +
            playerBarHeight

        if (window.innerHeight < endY) {
            y -= endY - window.innerHeight
        }

        return y
    }

    /**
     * This function is used in order to reposition the x coordinate value in case
     * the menu options are too close to the side of the screen.
     */
    private formatX(x: number): number {
        const endX =
            x +
            this.menuOptionsContainer.nativeElement.getBoundingClientRect()
                .width

        if (window.innerWidth < endX) {
            x -= endX - window.innerWidth
        }

        return x
    }

    public createMenuOptions() {
        const firstX =
            this.menuOptions.coordinates.x +
            this.hoveredOption.element.getBoundingClientRect().width +
            this.padding

        let secondX = this.formatX(firstX)

        if (firstX !== secondX) {
            secondX =
                this.menuOptions.coordinates.x -
                this.hoveredOption.element.getBoundingClientRect().width -
                this.padding
        }

        return {
            options: this.hoveredOption.option.data,
            coordinates: {
                x: secondX,
                y: this.hoveredOption.element.getBoundingClientRect().top,
            },
        } as MenuOptions<any>
    }

    public hoverOption(event: MouseEvent, option: MenuOption<any>) {
        event.stopPropagation()
        this.hoveredOption = {
            option: option,
            element: event.target as HTMLDivElement,
        }
    }

    public async clickOnOption(option: MenuOption<any>, event: MouseEvent) {
        if (this.isMenuOption(option.data)) {
            return
        }

        option.loading = true

        await option.onClick(option.data, event)

        if (this.menuOptions.onClose) {
            this.menuOptions.onClose()
        }

        option.loading = false
    }

    public isMenuOption(option: any | MenuOption<any>[]) {
        return (
            Array.isArray(option) &&
            option.length > 0 &&
            typeof option[0] === "object" &&
            option[0].text !== undefined
        )
    }
}
