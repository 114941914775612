import { Injectable } from "@angular/core"
import { ApiService } from "@services/api.service"
import { BugReport } from "@common-lib/interfaces/api/desktop-app.api"

@Injectable()
export class DesktopAppBugReportHttpService {
    constructor(private api: ApiService) {}

    public async saveDesktopAppBugReport(
        bugReport: BugReport,
    ) {
        try {
            const res = await this.api.authRequest(
                "/user/saveBugReport",
                {
                    bugReport: bugReport,
                },
                "primary",
                false
            )

            if (!res?.result) {
                throw Error(res?.message)
            }

            return res.result
        } catch (e) {
            this.api.handleError(e)
        }
    }
}
