<div
    class="context-menu recommendations-window instrument-recommendations"
    (clickOutside)="closeContextMenu()"
    [clickOutsideEvents]="'click,contextmenu,mousedown'">
    <div class="context-menu-background"></div>

    <div class="instrument-recommendatons-container">
        <div class="title">Recommendation settings</div>

        <div class="sub-title"></div>

        <div class="recommendations-row">
            <div class="text">
                <div class="title">Enabled recommendations</div>
            </div>
            <div class="toggle">
                <slide-toggle
                    [(toggled)]="
                        recommendationsService.recommendationsSettings.all
                    "
                    (onChange)="recommendationsChanged('all')"></slide-toggle>
            </div>
        </div>

        <hr class="recommendations-separator" />

        <div
            class="recommendations-row"
            *ngFor="let recommendation of recommendationToggleRows"
            [ngClass]="{
                disabled: !recommendationsService.recommendationsSettings.all
            }">
            <div class="text">
                <div class="title">
                    {{ recommendation.title }}
                </div>
                <div class="description">
                    {{ recommendation.description }}
                </div>
            </div>
            <div class="toggle">
                <slide-toggle
                    [(toggled)]="
                        recommendationsService.recommendationsSettings[
                            recommendation.key
                        ]
                    "
                    (onChange)="recommendationsChanged(recommendation.key)"
                    [disabled]="
                        !recommendationsService.recommendationsSettings.all
                    "></slide-toggle>
            </div>
        </div>
    </div>
</div>
