import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'
import { ModalService } from '../../../services/modal.service'
import { InfluenceService } from '../../../services/influence.service'
import { CompositionService } from '../../../services/composition.service'
import { FolderService } from '../../../services/folder.service'
import { ApiService } from '../../../services/api.service'
import { AnalyticsService } from '../../../services/analytics.service'
import { BillingService } from '../../../services/billing.service'
import { Router } from '@angular/router'
import { type } from 'os'
import { ActivityMetric } from '../../../../../../common-lib/general/classes/activitymetric'
import { TracksService } from '../../../services/tracks.service'
import { DeviceDetectorService } from 'ngx-device-detector'
import { GeneralService } from '@services/general/general.service'

@Component({
    selector: 'create-with-influence-component',
    templateUrl: 'create-with-influence.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: []
})
export class CreateWithInfluenceComponent implements OnInit {
    subscription

    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    // the parameters we care about for the creation of the track
    parameters = {
        ensemble: null,
        duration: 'auto',
        emotion: null,
        numberOfCompositions: '1',
        keySignature: null,
        view: "parameters",
        saveChanges: false
    }

    emotion
    emotions = [1, 2, 4, 5]
    ignoreEmotions = [3]

    loading = false
    error = ""

    selectedUsecase
    cyberpunkIsHovered = false

    influence
    influences
    influenceEnsembles = []
    categoriesAndEnsembles = []
    selectedEnsemble
    selectedEnsembleDropdownOption
    selectedKeyMode
    selectedKey

    selectedUseCase = {
        category: null,
        usecase: null
    }

    scrollOptionsMainContainer = { autoHide: false }
    scrollOptionsEnsembleCategoryContainer = { autoHide: true }
    scrollConfigKeySignatures = { useBothWheelAxes: false, suppressScrollX: true }

    showEnsembleSelectionWindow = false

    keys

    keyItems = []
    keyList = false
    topEnsembles = []

    keySignatureChanged = false
    ensembleChanged = false

    originalKeySignature

    stateOptions = [{ label: 'Off', value: 'off' }, { label: 'On', value: 'on' }]
    changeSelectStyle = false

    ensemblesDropdownItems = []
    selectedEnsembleItem

    constructor(private device: DeviceDetectorService, protected analyticsService: AnalyticsService, protected apiService: ApiService, protected billingService: BillingService, protected influenceService: InfluenceService, protected folderService: FolderService, protected compositionService: CompositionService, protected router: Router, protected modalService: ModalService, private tracksService: TracksService, private general: GeneralService, private ref: ChangeDetectorRef) {
        
    }

    ngOnInit() {
        this.changeSelectStyle = this.device.os == "Linux" || this.device.os == "Windows" // select options were invisible because of a lack of contrast

        this.keys = []

        for (let key of InfluenceService.keySignatures) {
            if (key.includes("Automatic")) {
                continue
            }

            this.keys.push(key)
        }
        
        this.parameters.view = this.modalService.createWithInfluenceParameters.getValue().setView

        this.init()

        this.billingService.subscription.subscribe(value => {
            if (value != null) {
                this.subscription = value.plan
            }
        })
    }

    detectChanges() {
        if (!this.ref['destroyed']) {
            this.ref.detectChanges()
        }
    }

    init() {
        this.setView('parameters')

        this.loading = true
        const compositions = this.folderService.content.getValue()
        const influenceID = this.modalService.createWithInfluence.getValue()
        const contentType = this.folderService.getContentType()

        this.apiService.authRequest('/influence/getInfluence', { influenceID: influenceID }, "primary", true).then((res) => {
            if (res == null || res.result != 1) {
                return Promise.reject("No influence found. Please try again later or contact the support to resolve this issue.")
            }

            this.influence = res.influence

            if (this.influence["parameters"]["topEnsembles"] == null) {
                this.influence["parameters"]["topEnsembles"] = []
            }

            this.parameters.ensemble = this.influence.parameters.ensemble
            this.parameters.keySignature = this.influence.parameters.pitchClass + " " + this.influence.parameters.keyMode

            this.emotion = this.influence.parameters.emotion
            this.selectedEnsemble = this.influence.parameters.ensemble
            this.selectedKey = this.parameters.keySignature
            this.selectedKeyMode = this.influence.parameters.keyMode
            this.originalKeySignature = this.getOriginallyDetectedKey(this.influence.parameters.detectedKeys)
            this.keyItems = this.getKeyItems(this.influence.parameters.keyMode)
            this.modalService.createWithInfluenceParameters.next(this.parameters)
            this.categoriesAndEnsembles = this.getInfluenceEnsemblesArray()
            this.topEnsembles = this.influence.parameters.topEnsembles != null ? this.influence.parameters.topEnsembles : []
            this.ensemblesDropdownItems = this.getInfluenceEnsemblesDropdownItems()

            let item = this.ensemblesDropdownItems.find(item => item.value == this.selectedEnsemble)

            if (item == null) {
                this.ensemblesDropdownItems.push({
                    name: this.selectedEnsemble,
                    value: this.selectedEnsemble,
                    optgroup: "Deprecated"
                })
            }

            if (this.selectedEnsemble == null) {
                this.selectedEnsemble = "Piano Solo"
            }

            this.selectedEnsembleDropdownOption = this.selectedEnsemble == "Custom" ? null : { name: this.selectedEnsemble, value: this.selectedEnsemble, optgroup: this.ensemblesDropdownItems.find(item => item.value == this.selectedEnsemble).optgroup }

            this.loading = false

            this.detectChanges()
        })
            .catch((err) => {
                this.loading = false
                this.error = err

                this.detectChanges()
            })
    }

    getKeyItemsByMode(mode) {
        var keys = []

        for (let key of this.keyItems) {
            if (key.includes(mode)) {
                keys.push(key)
            }
        }

        return keys
    }

    getInfluenceDurations() {
        return this.compositionService.influenceParameters["duration"]
    }

    getInfluenceCompositionNumber() {
        return this.compositionService.influenceParameters["compositionNumber"]
    }

    getOriginallyDetectedKey(detectedKeys) {
        if (detectedKeys == null) {
            return this.parameters.keySignature
        }

        let maxPercentageKey = detectedKeys[0]

        if (detectedKeys.length > 1) {
            maxPercentageKey = detectedKeys.reduce((a, b) => a.percentage > b.percentage ? a : b)
        }

        return maxPercentageKey.value.replace("min", "minor").replace("maj", "major")
    }

    async createTracks() {
        this.loading = true

        this.analyticsService.addActivity(ActivityMetric.CREATE_WITH_INFLUENCE)

        const influenceID = this.modalService.createWithInfluence.getValue()

        await this.tracksService.createComposition(this.parameters, 'influence', influenceID)
    }

    showDuration(duration) {
        const validDurations = CompositionService.getValidDurations(this.billingService.retrieveSubscriptionObject())

        if (!validDurations.includes(duration)) {
            return false
        }

        return true
    }

    enabledBatchedCompositions() {
        var sub = this.billingService.subscription.getValue()

        if (sub != null && sub.plan != "free") {
            return true
        }

        return false
    }

    setDuration(duration) {
        if (this.showDuration(duration)) {
            this.parameters.duration = duration
        }

        this.modalService.createWithInfluenceParameters.next(this.parameters)
    }

    getPresetUseCases() {
        return this.general.query.presetUseCases
    }


    hoverOnCyberpunk(presetUseCase, value) {
        if (presetUseCase == 'Cyberpunk') {
            this.cyberpunkIsHovered = value
        }
    }

    selectPresetUsecase(category, usecase) {
        this.selectedUsecase = {
            category: category,
            usecase: usecase
        }

        const presetUsecase = this.getPresetUseCases()[this.selectedUsecase.category].subcategories[this.selectedUsecase.usecase]
    }

    getInfluenceEnsembles() {
        return this.compositionService.influenceParameters["ensemble"]
    }

    getInfluenceEnsemblesArray() {
        const influenceEnsembles = this.getInfluenceEnsembles()

        let influenceEnsemblesArray = []

        for (let category in influenceEnsembles) {
            let influenceEnsembleObj = {
                name: category.replace(' Ensembles', ""),
                ensembles: influenceEnsembles[category],
                icon: this.getIconByCategory(category)
            }

            influenceEnsemblesArray.push(influenceEnsembleObj)
        }

        return influenceEnsemblesArray
    }

    getInfluenceEnsemblesDropdownItems(){
        let ensemblesDropdownItems = []

        let ensembles = this.getInfluenceEnsembles()

        for(let ensembleCategory of Object.keys(ensembles)){
            for(let ensemble of ensembles[ensembleCategory]){
                let name = this.isTopEnsemble(ensemble) ? '(Recommended) ' + ensemble : ensemble

                ensemblesDropdownItems.push({
                    name: name,
                    value: ensemble,
                    optgroup: ensembleCategory
                })
            }
        }

        return ensemblesDropdownItems
    }

    selectEnsemble(ensemble = "") {
        if (ensemble == null || ensemble == "") {
            return
        }

        this.selectedEnsemble = ensemble
    }

    onSelectEnsembleOption(value){
        this.selectedEnsembleDropdownOption = value.new
        this.selectedEnsemble = value.new.value
        this.applyEnsemble()
    }

    applyEnsemble() {
        this.parameters.ensemble = this.selectedEnsemble

        this.setView('parameters')
        this.checkForChanges()
    }

    editEnsemble() {
        this.setView("ensemble-selection")
    }

    setView(view = "") {
        if (view == null || view == "") {
            return
        }

        this.parameters.view = view
        this.modalService.createWithInfluenceParameters.next(this.parameters)
    }

    getIconByCategory(category = "") {
        const influenceParameters = this.compositionService.influenceParameters

        return "assets/img/presets/" + influenceParameters["icons"][category]
    }

    selectKey(key) {
        const keyMode = key.toLowerCase().includes("minor") ? "minor" : "major"
        const isOppositeMode = keyMode == "minor" && this.influence.parameters.keyMode != "minor" || keyMode == "major" && this.influence.parameters.keyMode != "major"

        this.selectedKey = key
        this.selectedKeyMode = keyMode

        if (isOppositeMode) {
            this.setView('mode-change')
        }

        else {
            this.parameters.keySignature = this.selectedKey
            this.checkForChanges()
        }

        this.keyList = false
        this.modalService.createWithInfluenceParameters.next(this.parameters)
    }

    keepModeChange() {
        this.parameters.keySignature = this.selectedKey
        this.setView('parameters')
        this.checkForChanges()
    }

    discardModeChange() {
        this.selectedKey = this.parameters.keySignature
        this.setView('parameters')
    }

    getKeyItems(currentKeyMode) {
        let keyItems = Object.assign([], this.keys)

        keyItems = keyItems.filter(k => k !== this.originalKeySignature)
        keyItems.unshift(this.originalKeySignature)

        return keyItems
    }

    getCleanParameters(parameters) {
        if (!parameters) {
            return parameters
        }

        return {
            duration: this.parameters.duration,
            numberOfCompositions: this.parameters.numberOfCompositions,
            ensemble: this.parameters.ensemble,
            keySignature: this.parameters.keySignature,
            saveChanges: this.parameters.saveChanges
        }
    }

    isTopEnsemble(ensemble) {
        if (this.topEnsembles == null || Array.isArray(this.topEnsembles) == false || this.topEnsembles.length == 0) {
            return false
        }

        return this.topEnsembles.includes(ensemble)
    }

    getTopEnsembleType(ensemble) {
        let color = ""
        let index = this.topEnsembles.indexOf(ensemble)

        if (index == 0) {
            color = "gold"
        }
        else if (index == 1) {
            color = "silver"
        }

        else if (index == 2) {
            color = "bronze"
        }

        return color
    }

    getTopEnsembleIcon(ensemble) {
        var color = this.getTopEnsembleType(ensemble)

        return "assets/img/medal_" + color + ".svg"
    }

    checkForChanges() {
        const originalEnsemble = this.influence.parameters.ensemble
        const originalKey = this.influence.parameters.pitchClass + " " + this.influence.parameters.keyMode

        this.ensembleChanged = this.parameters.ensemble != originalEnsemble
        this.keySignatureChanged = this.parameters.keySignature != originalKey
    }

    cancelEnsembleSelection() {
        this.selectedEnsemble = this.parameters.ensemble
        this.setView('parameters')
    }

    cancelCreateWithInfluence() {
        this.setView('parameters')
        this.modalService.createWithInfluence.next("")
        this.modalService.createWithInfluenceParameters.next({
            ensemble: null,
            duration: 'auto',
            numberOfCompositions: '1',
            key: null,
            view: "parameters",
            saveChanges: false,
            emotion: null
        })
        this.selectedEnsemble = null
    }

    onEmotionChange(emotion) {
        this.parameters.emotion = emotion.value
    }
}