import { Query } from "@datorama/akita"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import { CWState, CWStore } from "./cw.store"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import { TemplateChord } from "@common-lib/interfaces/score/templateScore"
import { KeySignature } from "@common-lib/interfaces/score/keySignature"
import { TimeSignature } from "@common-lib/types/score"
import { CompositionWorkflowType, LayersValue } from "@common-lib/types/general"
import { LayerType } from "@common-lib/constants/constants"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import {
    CWStateStep1,
    CWStateStep2,
    CWStateStep3,
    CWStepData,
} from "@common-lib/interfaces/composition-workflow.interface"
import { RenderChordsType } from "../../../../../../common-lib/client-only/score-rendering-engine"
import { map, Observable } from "rxjs"

export class CWQuery extends Query<CWState> {
    public allState$ = this.select()

    constructor(protected store: CWStore) {
        super(store)
    }

    public get engine(): ScoreRenderingEngine {
        return this.getValue().engine
    }

    public get prompt(): string {
        return this.getValue().step1.prompt
    }

    public get gp(): GenerationProfile {
        return this.getValue().gp
    }

    public getGPLayer(layer: LayersValue): GPLayer | undefined {
        if (layer === LayerType.MELODY) {
            return this.gp.melodyLayer
        }

        return this.gp.accompanimentLayers.find(l => l.name === layer)
    }

    public step1WasInitialised(): boolean {
        return this.step1.harmony !== undefined
    }

    public get type(): CompositionWorkflowType {
        return this.getValue().type
    }

    public getLayerStatus(
        layer: LayersValue
    ): "finished" | "loading" | "error" {
        const loading = this.getValue().step2.layerLoading[layer]

        if (
            loading === undefined ||
            (loading.finished === false && loading.error === undefined)
        ) {
            return "loading"
        } else if (loading.error) {
            return "error"
        }

        return "finished"
    }

    public isAnyStepLoading(): boolean {
        const stepOneIsLoading = false // @todo implement

        const stepTwoIsLoading =
            Object.keys(this.step2.layerLoading).find((layer: LayersValue) => {
                return this.step2.layerLoading[layer].finished === false
            }) !== undefined

        const stepThreeIsLoading = false // @todo implement

        return stepOneIsLoading || stepTwoIsLoading || stepThreeIsLoading
    }

    public get tempo(): number {
        return this.getValue().step1.tempo
    }

    public get keySignature(): KeySignature {
        return this.getValue().step1.harmony.keySignature
    }

    public get timeSignature(): TimeSignature {
        return this.getValue().step1.timeSignature
    }

    public get step1(): CWStateStep1 {
        return this.getValue().step1
    }

    public get step2(): CWStateStep2 {
        return this.getValue().step2
    }

    public get step3(): CWStateStep3 {
        return this.getValue().step3
    }

    public get progressionWasEdited$(): Observable<boolean> {
        return this.select("step1").pipe(
            map(step1 => {
                return this.engine.queries.scoreRendering.getValue()
                    .chordsWereEdited
            })
        )
    }

    public get stepData(): CWStepData {
        return this.getValue().stepData
    }

    public get isStep2Part2(): boolean {
        return (
            this.stepData?.index === 1 &&
            this.engine?.toggledLayer !== undefined
        )
    }
}
