import GenerationProfile from "./generationprofile"

export default class GPValidation {
    valid: boolean

    target: {
        type: string,
        value: any,
        id: string
    }

    issue: string
    message: string
    validations: GPValidation[]

    constructor(valid: boolean = true, targetType: string = null, targetValue = null, targetID: string = null, issue: string = null, message: string = null, validations = []) {
        this.target = {
            type: targetType,
            value: targetValue,
            id: targetID
        }

        this.issue = issue
        this.message = message
        this.validations = validations
        this.valid = valid
    }

    static validateNestedValidations(validations = []){
        for(let validation of validations){
            if(validation.valid == false) {
                return false
            }

            else if (Array.isArray(validation.validations) && validation.validations.length > 0){
                return this.validateNestedValidations(validation.validations)
            }
        }

        return true
    }

    static validateRange(range: { min: number, max: number }, type: string) {
        let validateRange = range != null && range.min != null && range.max != null && range.min <= range.max

        return new GPValidation(validateRange, type, range, null, "invalid" + type, "The range for " + type + " is invalid" )
    }

    static validatePreview(gp: GenerationProfile, type = "melody"){
        let validation = new GPValidation(true, type + "Preview", gp)

        if(type == "melody"){
            if(gp.melodyLayer == null){
                validation.validations.push(new GPValidation(false, "melodyLayer", gp.melodyLayer, null, "melodyLayerNull", "No melody layer could be found"))
            }
        }

        if (gp.harmony == null || gp.harmony.keySignature == null || gp.harmony.keySignature.keyMode == null || gp.harmony.keySignature.pitchClass == null) {
            validation.validations.push(new GPValidation(false, "harmony", gp.harmony, null, "invalidKeySignature", "No or only parts of the key signature are set"))
        }

        validation.validations.push(GPValidation.validateRange(gp.settings.tempoRange, "tempoRange"))

        let tsValidation = new GPValidation(gp.settings.timeSignature != null, "timeSignature", gp.settings.timeSignature)

        if(tsValidation.valid == false){
            tsValidation.issue = "noTimeSignature"
            tsValidation.message = "No time signature set"
        }

        validation.validations.push(tsValidation)

        for (let v of validation.validations) {
            if (v.valid == false) {
                validation.valid = false
                break
            }
        }

        return validation
    }
}