import { Reverb } from "../../classes/score/effect"
import { cloneDeep } from "lodash"
import Layer from "../../classes/score/layer"
import { NotesObject } from "../../classes/score/notesObject"
import Score from "../../classes/score/score"
import {
    AUTOMATION_TIMESTEP_RES,
    HARMONY_MODES,
    TIMESTEP_RES,
} from "../../constants/constants"
import {
    InstrumentsJSON,
    PitchToChannelMapping,
} from "../../interfaces/score/general"
import {
    SegmentedScore,
    SegmentedScoreInstrument,
    SegmentedScoreKeySignature,
    SegmentedScoreLayer,
    SegmentedScoreNote,
    SegmentedScorePercussionChannel,
    SegmentedScorePercussionLayer,
    SegmentedScorePercussionNote,
    SegmentedScoreSection,
} from "../../interfaces/score/segmentedscore"
import {
    TemplateChord,
    TemplateKeySignature,
    TemplateLayer,
    TemplateLayerEffect,
    TemplateLayers,
    TemplateNote,
    TemplateScore,
    TemplateTrack,
} from "../../interfaces/score/templateScore"
import { ChordManipulation } from "../chord-manipulation.module"
import { KeySignatureModule } from "../keysignature.module"
import { ScoreManipulation } from "../scoremanipulation"
import { Time } from "../time"
import { v4 as uuidv4 } from "uuid"
import TrackBus from "../../classes/score/trackbus"
import { TimeSignature } from "../../types/score"
import PercussionLayer from "../../classes/score/percussionlayer"
import Section from "../../classes/score/section"
import { Misc } from "../misc"
import {
    CWKeyMode,
    CompositionWorkflowNote,
} from "../../interfaces/composition-workflow.interface"
import { KeySignature } from "../../interfaces/score/keySignature"
import SamplesMap from "../../interfaces/score/samplesMap"
import { Note } from "../../classes/score/note"
import Channel from "../../classes/score/channel"
import { Pattern } from "../../classes/score/pattern"
import PatternRegion from "../../classes/score/patternregion"
import { featureFlags } from "../../utils/feature-flags"

export interface PatternRepetition {
    loop: number
    pattern: Pattern
}

// const drumkitPitchToChannelMapping = require("../../common-lib/assets/json/drumMapping.json")
const pitchToChannelMapping: PitchToChannelMapping = require("../../../assets/json/drumMapping.json")
const channelsToPitchMapping: { [channel: string]: number[] } = {}

for (const p in pitchToChannelMapping) {
    const channel = pitchToChannelMapping[p]

    if (channelsToPitchMapping[channel] === undefined) {
        channelsToPitchMapping[channel] = []
    }

    channelsToPitchMapping[channel].push(parseInt(p))
}

export module SegmentedScoreManipulationModule {
    export function mergeChordsIntoScore(
        section: SegmentedScoreSection,
        score: Score
    ) {
        if (section.chords.length > 0) {
            score.chords = ChordManipulation.insertAndReplaceChords(
                score.chords,
                section.chords,
                section.start
            )

            score.romanNumerals =
                ChordManipulation.convertChordSymbolsToRomanNumerals(
                    score.chords,
                    score.keySignatures
                )
        }
    }

    export function mergeCopyOfSectionIntoScore(
        section: SegmentedScoreSection,
        score: Score,
        samplesMap: SamplesMap
    ) {
        mergeChordsIntoScore(section, score)

        for (const layer of section.layers) {
            const targetLayer = score.layers[layer.value]

            if (layer.type === "pitched") {
                addSegmentedNotesToScore(
                    layer,
                    targetLayer as Layer,
                    section.start,
                    score.firstTimeSignature,
                    score.sections
                )
            } else {
                addPatternsAndPatternRegions(
                    score,
                    section,
                    targetLayer as PercussionLayer,
                    layer.channels,
                    samplesMap
                )
            }
        }
    }

    export function mergeSectionIntoScore(
        section: SegmentedScoreSection,
        score: Score,
        instruments: InstrumentsJSON,
        samplesMap: SamplesMap,
        type: "sectionInpainting" | "layerInpainting",
        toggledLayer: PercussionLayer | Layer | undefined
    ) {
        let layersToMerge: (PercussionLayer | Layer)[] = Object.keys(
            score.layers
        ).map(l => score.layers[l])

        let layersToCreate = []

        mergeChordsIntoScore(section, score)

        let newPercussionLayer = undefined

        for (const layer of section.layers) {
            const categories =
                layer.type === "pitched"
                    ? layer.instruments.map(i => i.category)
                    : layer.channels.map(c => c.name)

            let result = {
                layer: toggledLayer,
                filteredLayers: layersToMerge,
            }

            if (type === "sectionInpainting") {
                result = findLayerWithInstrumentCategories(
                    layer.value as any,
                    categories,
                    layersToMerge,
                    instruments
                )
            }

            if (type === "sectionInpainting" && result?.layer === undefined) {
                layersToCreate.push(layer)
            } else if (layer.type === "pitched") {
                addSegmentedNotesToScore(
                    layer,
                    result?.layer as Layer,
                    section.start,
                    score.firstTimeSignature,
                    score.sections
                )
            } else {
                if (featureFlags.inpaintSectionInPercussionLayer) {
                    newPercussionLayer = addPatternsAndPatternRegions(
                        score,
                        section,
                        result?.layer as PercussionLayer,
                        layer.channels,
                        samplesMap
                    )
                }
            }
        }

        return newPercussionLayer
    }

    export function getPatternsForSegmentedScore(
        score: Score,
        section: SegmentedScoreSection,
        targetLayer: PercussionLayer,
        channels: SegmentedScorePercussionChannel[],
        samplesMap: SamplesMap
    ): PatternRepetition[] {
        // Identify how many patterns can be generated based on the layer channel notes
        // The default pattern length is 1 bar, so if the notes are longer than 1 bar, we need to split them.
        // If the notes are shorter, we need to merge them into one pattern and adjust the pattern length.
        // We also need to make sure that the notes are aligned to the grid, so we need to adjust the resolution of the pattern.
        const sectionDuration = Time.addTwoFractions(
            section.end,
            section.start,
            true
        )
        const oneBar = Time.simplifyFractionFromString(
            score.firstTimeSignature[0] * 1 + "/" + score.firstTimeSignature[1]
        )

        const trackBuses = targetLayer.trackBuses

        const numberOfPatterns = Math.ceil(
            Time.divideTwoFractions(sectionDuration, oneBar)
        )

        // Notes are filtered based on their start time to create a new pattern for each bar.
        // Unnecessary patterns are eliminated in the final merge step.
        const patterns: PatternRepetition[] = []
        let lastPattern = undefined
        let loop = 0

        for (let i = 0; i < numberOfPatterns; i++) {
            const remainingDuration = Time.addTwoFractions(
                sectionDuration,
                Time.simplifyFractionFromString(
                    score.firstTimeSignature[0] * i +
                        "/" +
                        score.firstTimeSignature[1]
                ),
                true
            )

            let patternDuration = oneBar

            if (
                Time.compareTwoFractions(remainingDuration, patternDuration) ===
                "lt"
            ) {
                patternDuration = remainingDuration
            }

            // Get the Channels based on the notes
            const patternChannels = getAllChannelsForSegmentedScore(
                trackBuses,
                channels,
                samplesMap,
                i,
                patternDuration
            )

            // Create a new pattern based on the resolution and the channels
            const id = targetLayer.getMaxPatternID() + 1

            let pattern: Pattern = new Pattern(id)
            pattern.channels = patternChannels
            pattern.bars = 1

            // Define the resolution of the pattern based on the notes onsets
            const resolution =
                ScoreManipulation.calculatePatternResolution(pattern)
            pattern.resolution = resolution

            // Make sure we re-use already existing patterns
            const existingPattern = getPatternFromLayerIfItAlreadyExists(
                pattern,
                targetLayer
            )

            pattern = existingPattern.pattern

            // We only add the pattern to the layer if it does not exist yet in the layer
            if (!existingPattern.exists) {
                targetLayer.patterns.push(pattern)
            }

            // Only add the pattern if it is different from the last pattern.
            // That makes sure we loop consecutive equal patterns.
            if (lastPattern && !lastPattern.isEqual(pattern)) {
                patterns.push({
                    loop: loop,
                    pattern: lastPattern,
                })
                loop = 0
            } else if (lastPattern && lastPattern.isEqual(pattern)) {
                loop++
            }

            lastPattern = pattern
        }

        // Handle the last pattern
        if (lastPattern) {
            patterns.push({
                loop: loop,
                pattern: lastPattern,
            })
        }

        return patterns
    }

    export function getPatternFromLayerIfItAlreadyExists(
        pattern: Pattern,
        targetLayer: PercussionLayer
    ): {
        exists: boolean
        pattern: Pattern
    } {
        const res = {
            exists: false,
            pattern: pattern,
        }

        // Check if any pattern equals an existing pattern in the source already
        for (let i = 0; i < targetLayer.patterns.length; i++) {
            const sourcePattern = targetLayer.patterns[i]

            // Use the source pattern instead of the new pattern moving forward
            if (pattern.isEqual(sourcePattern)) {
                res.exists = true
                res.pattern = sourcePattern
            }
        }

        return res
    }

    export function getPatternRegionsFromPatternRepetitions(
        score: Score,
        section: SegmentedScoreSection,
        patterns: PatternRepetition[]
    ): PatternRegion[] {
        const oneBar = Time.simplifyFractionFromString(
            score.firstTimeSignature[0] * 1 + "/" + score.firstTimeSignature[1]
        )

        let currentPRStart = Time.simplifyFractionFromString(section.start)

        const patternRegions = []

        // Here we iterate over each of the objects in the patterns array and create a new pattern region.
        // The object holds a pattern and the repeat value. We use the repeat value to create a loop.
        for (let p = 0; p < patterns.length; p++) {
            let pattern: Pattern = patterns[p].pattern
            let loop: number = patterns[p].loop

            const pDuration = Time.multiplyFractionWithNumber(
                oneBar,
                pattern.bars
            )

            // Create the pattern region with the pattern and the start and end of the
            // pattern region + loop if applicable
            const pr = new PatternRegion(
                {
                    start: currentPRStart,
                    onset: "0/1",
                    duration: pDuration,
                    loop: loop,
                },
                pattern
            )

            patternRegions.push(pr)

            // Set the start for the next iteration
            currentPRStart = Time.addTwoFractions(
                currentPRStart,
                Time.multiplyFractionWithNumber(pDuration, loop + 1)
            )
        }

        return patternRegions
    }

    export function addPatternsAndPatternRegions(
        score: Score,
        section: SegmentedScoreSection,
        targetLayer: PercussionLayer,
        channels: SegmentedScorePercussionChannel[],
        samplesMap: SamplesMap
    ) {
        const patterns: PatternRepetition[] = getPatternsForSegmentedScore(
            score,
            section,
            targetLayer,
            channels,
            samplesMap
        )

        const patternRegions = getPatternRegionsFromPatternRepetitions(
            score,
            section,
            patterns
        )

        // Add the pattern regions to the layer
        // (the patterns are already added to the layer by now)
        targetLayer.patternRegions =
            targetLayer.patternRegions.concat(patternRegions)

        targetLayer.sortPatternRegions()

        score.layers[targetLayer.value].applyLayerState(targetLayer)

        // This is helpful for testing purposes.
        return {
            targetLayer,
            newPatternRegions: patternRegions,
            newPatterns: patterns.map(p => p.pattern),
        }
    }

    export function getAllChannelsForSegmentedScore(
        trackBuses: TrackBus[],
        channels: SegmentedScorePercussionChannel[],
        samplesMap: SamplesMap,
        iteration: number = 0,
        patternDuration: string = "1/1"
    ) {
        let res: Channel[] = []

        for (let c of channels) {
            const newChannels = getChannelsForSegmentedScore(
                trackBuses,
                c,
                iteration,
                patternDuration,
                samplesMap
            )

            res = res.concat(newChannels)
        }

        return res
    }

    export function getChannelsForSegmentedScore(
        trackBuses: TrackBus[],
        channel: SegmentedScorePercussionChannel,
        iteration: number = 0,
        patternDuration: string = "1/1",
        samplesMap: SamplesMap
    ): Channel[] {
        // We have to trim the name first, since there have been issues with spaces in the name
        const trackBusName = channel.name.trim()

        const trackBus = trackBuses.find(
            t => t.name === `p.${trackBusName}.nat.stac`
        )
        const channelsObj = {}
        const patternStart = Time.multiplyFractionWithNumber(
            patternDuration,
            iteration
        )
        const patternEnd = Time.addTwoFractions(patternStart, patternDuration)
        const instrumentSamplesMap = samplesMap[trackBusName]
            ? samplesMap[trackBusName]
            : []

        // Create all new channels based on the notes
        for (let note of channel.notes) {
            // Skip notes that are outside of the pattern
            if (
                Time.compareTwoFractions(note.start, patternEnd) === "gt" ||
                Time.compareTwoFractions(note.start, patternEnd) === "eq"
            ) {
                continue
            } else if (
                Time.compareTwoFractions(note.start, patternStart) === "lt"
            ) {
                continue
            }

            // Create a new channel with name, onsets, pitches, trackbus, and other properties
            const noteStart = Time.addTwoFractions(
                note.start,
                patternStart,
                true
            )

            for (let pitch of note.pitches) {
                const name = pitchToChannelMapping[pitch]

                // Continue if none of the samples are compatible with the pitch
                if (
                    !channelsToPitchMapping[name].some(p =>
                        instrumentSamplesMap.includes(p)
                    )
                ) {
                    continue
                }

                // Initialize the channel if it doesn't exist
                if (!channelsObj[name]) {
                    channelsObj[name] = {
                        onsets: [],
                        pitches: [],
                    }
                }

                // Add the onset
                channelsObj[name].onsets.push({ start: noteStart })

                // Add the pitch if it's not already included
                if (!channelsObj[name].pitches.includes(pitch)) {
                    channelsObj[name].pitches.push(pitch)
                }

                // Add additional pitches from the mapping
                if (channelsToPitchMapping[name]) {
                    for (let p of channelsToPitchMapping[name]) {
                        if (!channelsObj[name].pitches.includes(p)) {
                            channelsObj[name].pitches.push(p)
                        }
                    }
                }
            }

            // Make sure to use unique pitches for each channel
            for (let c in channelsObj) {
                channelsObj[c].pitches = [...new Set(channelsObj[c].pitches)]
            }
        }

        // Return the new channels as an array
        const channels = []

        for (let channelName in channelsObj) {
            // If channel is not part of the mapping, we skip it
            if (!channelsToPitchMapping[channelName]) {
                continue
            }

            channels.push(
                new Channel(
                    channelName,
                    channelsObj[channelName].pitches,
                    channelsObj[channelName].onsets,
                    false,
                    false,
                    trackBus
                )
            )
        }

        return channels
    }

    export function convertLayerNameToReducedLayerName(layer: string): string {
        if (layer.includes("Melody")) {
            return "Melody"
        }

        if (layer.includes("Bass")) {
            return "Bass"
        }

        if (layer.includes("Percussion")) {
            return "Percussion"
        }

        if (layer.includes("Ornaments")) {
            return "Ornaments"
        }

        return "Accompaniment"
    }

    export function addSegmentedNotesToScore(
        layer: SegmentedScoreLayer,
        targetLayer: Layer,
        startOffset: string,
        ts: TimeSignature,
        sections: Section[]
    ) {
        for (const n of layer.notes) {
            for (const p of n.pitches) {
                targetLayer.notesObject.addNoteToGroup(
                    new Note({
                        pitch: p,
                        start: Time.addTwoFractions(startOffset, n.start),
                        duration: n.duration,
                        meta: {
                            section: sections.length - 1,
                            layer: targetLayer.value,
                        },
                    }),
                    ts,
                    sections
                )
            }
        }
    }

    export function addPercussionLayerToScore(
        notes: SegmentedScoreNote[],
        score: Score
    ) {}

    export function findLayerWithInstrumentCategories(
        layerName: string,
        categories: string[],
        layers: (Layer | PercussionLayer)[],
        instruments: InstrumentsJSON
    ): {
        layer: Layer | PercussionLayer | undefined
        filteredLayers: (Layer | PercussionLayer)[]
    } {
        let closestMatch = undefined

        for (const l in layers) {
            const layer = layers[l]

            if (layerName.includes("Percussion") && layer.value === layerName) {
                return {
                    layer,
                    filteredLayers: layers.filter(lay => lay !== layer),
                }
            }

            if (
                !layerName.includes("Percussion") &&
                convertLayerNameToReducedLayerName(layer.value) === layerName
            ) {
                closestMatch = layer

                const layerCategories = layer.trackBuses.map(inst =>
                    getTrackBusCategory(inst, instruments)
                )

                if (categories.every(c => layerCategories.includes(c))) {
                    return {
                        layer,
                        filteredLayers: layers.filter(lay => lay !== layer),
                    }
                }
            }
        }

        return {
            layer: closestMatch,
            filteredLayers: layers,
        }
    }

    /**
     * Converts from a TemplateScore to a Segmented Score.
     * This function simply converts TemplateScore to Score and then uses fromScoreToSegmentedScore
     * under the hood. Refer to fromScoreToSegmentedScore documentation for more details.
     */

    export function fromTemplateScoreToSegmentedScore(
        templateScore: TemplateScore,
        instsJSON: InstrumentsJSON,
        samplesMap: SamplesMap,
        acceptMutedInstruments: boolean,
        acceptEmptyChannels: boolean
    ): SegmentedScore {
        const s = new Score({
            templateScore,
            instrumentReferences: instsJSON,
            samplesMap,
            lastSectionDuration: templateScore["lastSectionDuration"],
        })

        return fromScoreToSegmentedScore(
            s,
            instsJSON,
            samplesMap,
            acceptMutedInstruments,
            acceptEmptyChannels
        )
    }

    /**
     * Converts from a Score to a Segmented Score.
     * While the Score is useful for UI rendering and editing purposes, the SegmentedScore
     * is useful to use as an intermediate representation for various types of score analysis tasks,
     * like key signature detection, where having notes organized by section boundaries is useful.
     * This representation is not suitable for UI rendering.
     *
     * If acceptMutedInstruments is set to false, then the function will throw an error if it finds more than
     * 40% of mute instruments. Otherwise, it will ignore the muted instruments, and wont add them to the
     * final SegmentedScore.
     *
     * If acceptEmptyChannels is set to true, then the function will accept percussion layers that have no channels
     * or layers without pattern regions in certain sections. This is useful for the layer inpainting feature.
     */
    export function fromScoreToSegmentedScore(
        s: Score,
        instsJSON: InstrumentsJSON,
        samplesMap: SamplesMap,
        acceptMutedInstruments: boolean,
        acceptEmptyChannels: boolean
    ): SegmentedScore {
        const score: SegmentedScore = {
            sections: [],
        }

        if (acceptMutedInstruments === false) {
            const nbOfMutedInstruments = s.trackBusses.filter(
                tb => tb.mute
            ).length

            if (nbOfMutedInstruments / s.trackBusses.length > 0.4) {
                throw new Error(
                    "More than 40% of the instruments are muted. Preprocess score first or set acceptMutedInstruments to true."
                )
            }
        }

        for (const section of s.sections) {
            const layers = getSegmentedScoreLayers(s, section, instsJSON)
            const ks: { start: string; end: string; ks: string }[] =
                KeySignatureModule.getKeySignaturesInRange(
                    s.scoreLength,
                    s.keySignatures,
                    section.start,
                    section.end
                ).map(ks => {
                    return {
                        ks: ks.ks,
                        start: Time.addTwoFractions(
                            ks.start,
                            section.start,
                            true
                        ),
                        end: Time.addTwoFractions(ks.end, section.start, true),
                    }
                })

            const percussionLayers = getSegmentedScorePercussionLayers(
                s,
                section,
                samplesMap,
                acceptEmptyChannels
            )

            const trainingSection: SegmentedScoreSection = {
                index: section.index,
                start: section.start,
                end: section.end,
                name: section.title,
                key_signatures: ks,
                time_signature: s.firstTimeSignature,
                tempo: s.tempoMap[0].bpm,
                layers: (
                    layers as (
                        | SegmentedScoreLayer
                        | SegmentedScorePercussionLayer
                    )[]
                ).concat(percussionLayers),
                chords: ChordManipulation.getChordsInRange(s.chords, {
                    start: section.start,
                    end: section.end,
                }),
            }

            score.sections.push(trainingSection)
        }

        return score
    }

    export function getNumberOfNotesInLayerOutsideOfScaleAndChords(
        ks: SegmentedScoreKeySignature[],
        layer: SegmentedScoreLayer,
        chords: TemplateChord[],
        _id?: string
    ) {
        let outsideOfScale = 0
        let outsideOfChords = 0
        let outsideOfChordsAndScale = 0
        let totalNbOfNotes = 0

        for (const note of layer.notes) {
            note.pitches.map(p => {
                const resultChord = ChordManipulation.pitchIsInChord(
                    p,
                    note.start,
                    note.duration,
                    chords
                )
                const resultScale = KeySignatureModule.pitchIsInScale(
                    p,
                    note.start,
                    note.duration,
                    ks
                )

                const isOutsideOfChord = resultChord.some(
                    r => r.inChord === false
                )
                const isOutsideOfScale = resultScale.some(
                    r => r.inKey === false
                )

                if (isOutsideOfChord) {
                    outsideOfChords++
                }

                if (isOutsideOfScale) {
                    outsideOfScale++
                }

                if (isOutsideOfChord && isOutsideOfScale) {
                    outsideOfChordsAndScale++

                    if (
                        layer.outOfChordAndScaleNotes > 0 &&
                        layer.value !== "Melody"
                    ) {
                        console.log(
                            "layer: ",
                            layer.value,
                            outsideOfChordsAndScale,
                            p,
                            _id
                        )
                    }
                }

                totalNbOfNotes++
            })
        }

        return {
            outsideOfScale,
            outsideOfChords,
            outsideOfChordsAndScale,
            totalNbOfNotes,
        }
    }

    export function getNumberOfNotesInSegmentedScoreSectionOutsideOfScaleAndChords(
        section: SegmentedScoreSection,
        _id?: string
    ) {
        let outsideOfScale = 0
        let outsideOfChords = 0
        let outsideOfChordsAndScale = 0
        let totalNbOfNotes = 0
        let updateScore = false

        for (const layer of section.layers) {
            if (layer.type === "percussion") {
                continue
            }

            const result = getNumberOfNotesInLayerOutsideOfScaleAndChords(
                section.key_signatures,
                layer,
                section.chords,
                _id
            )
            outsideOfScale += result.outsideOfScale
            outsideOfChords += result.outsideOfChords
            outsideOfChordsAndScale += result.outsideOfChordsAndScale
            totalNbOfNotes += result.totalNbOfNotes

            layer.outOfChordNotes = result.outsideOfChords
            layer.outOfScaleNotes = result.outsideOfScale
            layer.outOfChordAndScaleNotes = result.outsideOfChordsAndScale

            if (result.outsideOfChords > 0) {
                updateScore = true
            }

            if (result.outsideOfScale > 0) {
                updateScore = true
            }

            if (result.outsideOfChordsAndScale > 0) {
                updateScore = true
            }
        }

        return {
            outsideOfScale,
            outsideOfChords,
            outsideOfChordsAndScale,
            totalNbOfNotes,
            updateScore,
        }
    }

    export function detectKeySignatureForScore(score: SegmentedScore): {
        pitchesInScale: number
        pitchesOutOfScale: number
        scales: KeySignature[]
    }[] {
        let scales: { ks: KeySignature; scale: number[] }[] = []

        for (const type in HARMONY_MODES) {
            for (const mode of HARMONY_MODES[type]) {
                const pitchClasses = Misc.getPitchClassOptions(mode, type)

                const temp = pitchClasses.map(p => {
                    const ks = {
                        pitchClass: p,
                        keyMode: mode as CWKeyMode,
                    }
                    const scale =
                        KeySignatureModule.getPitchesForKeySignatureScale(ks)

                    return {
                        ks,
                        scale,
                    }
                })

                scales = scales.concat(temp)
            }
        }

        const results: {
            pitchesInScale: number
            pitchesOutOfScale: number
            scales: KeySignature[]
        }[] = []

        for (const section of score.sections) {
            let sectionPitches: number[] = []

            for (const layer of section.layers) {
                if (layer.type === "percussion") {
                    continue
                }

                sectionPitches = sectionPitches.concat(
                    getAllUniquePitchesFromSegmentedScoreLayer(layer)
                )
            }

            sectionPitches = [...new Set(sectionPitches)]

            const analysisResults: {
                pitchesInScale: number
                pitchesOutOfScale: number
                scales: KeySignature[]
            } = {
                pitchesInScale: 0,
                pitchesOutOfScale: 12,
                scales: [],
            }

            for (const scale of scales) {
                const inScale = sectionPitches.filter(p =>
                    scale.scale.includes(p)
                )

                const outOfScale = sectionPitches.filter(p => {
                    return !scale.scale.includes(p)
                })

                if (outOfScale.length < analysisResults.pitchesOutOfScale) {
                    analysisResults.pitchesOutOfScale = outOfScale.length
                    analysisResults.pitchesInScale = inScale.length
                    analysisResults.scales = [scale.ks]
                } else if (
                    outOfScale.length === analysisResults.pitchesOutOfScale
                ) {
                    if (inScale.length > analysisResults.pitchesInScale) {
                        analysisResults.scales = [scale.ks]
                        analysisResults.pitchesOutOfScale = outOfScale.length
                        analysisResults.pitchesInScale = inScale.length
                    } else {
                        analysisResults.scales.push(scale.ks)
                    }
                }
            }

            results.push(analysisResults)
        }

        return results
    }

    /**
     * For a given SegmentedScoreLayer instance, this method returns all the unique pitches.
     * Each pitch can be a value ranging from 0 to 11, where 0 is C, 1 is C#, 2 is D, and so on.
     */
    export function getAllUniquePitchesFromSegmentedScoreLayer(
        layer: SegmentedScoreLayer
    ): number[] {
        const pitches = {}

        for (const note of layer.notes) {
            for (const pitch of note.pitches) {
                pitches[pitch % 12] = true
            }
        }

        return Object.keys(pitches).map(p => parseInt(p))
    }

    export function getSegmentedScoreLayers(
        s: Score,
        section: Section,
        instsJSON: InstrumentsJSON
    ) {
        return Object.keys(s.layers)
            .filter(l => s.layers[l].type !== "percussion")
            .map(l => {
                const layer = s.layers[l]

                const notes = getSegmentedScoreNotes(
                    layer.notesObject,
                    section.start,
                    section.end
                )
                const effects = ScoreManipulation.getEffectsFromLayerInRange(
                    layer,
                    section.start,
                    section.end
                )

                const trainingLayer: SegmentedScoreLayer = {
                    name: layer.name ? layer.name : layer.value,
                    value: layer.value,
                    type: "pitched",
                    notes,
                    instruments: getSegmentedScoreInstruments(
                        layer,
                        instsJSON,
                        section.start,
                        section.end
                    ),
                    dynamic: simplifyAutomation(
                        effects.dynamic,
                        section.start,
                        section.end,
                        s.firstTimeSignature
                    ),
                    reverb: {
                        ir:
                            layer.effects.reverb.active &&
                            (layer.effects.reverb as Reverb).ir !== "None"
                                ? (layer.effects.reverb as Reverb).ir
                                : "None",
                        values: simplifyAutomation(
                            effects.reverb,
                            section.start,
                            section.end,
                            s.firstTimeSignature
                        ),
                    },
                    gainBias: layer.gainBias,
                }

                return trainingLayer
            })
            .filter(l => {
                return l.notes.length > 0 && l.instruments.length > 0
            })
    }

    export function getSegmentedScorePercussionLayers(
        s: Score,
        section: Section,
        samplesMap: SamplesMap,
        acceptEmptyChannels: boolean = false
    ): SegmentedScorePercussionLayer[] {
        const res = Object.keys(s.layers)
            .filter(l => s.layers[l].type === "percussion")
            .map(l => {
                const layer = s.layers[l] as PercussionLayer

                const notes = layer.convertPatternRegionsToNotes(
                    s.firstTimeSignature,
                    false
                )

                const channels = Object.keys(notes).map(tb => {
                    const data = notes[tb]
                    const instrumentName = s.trackBusses
                        .find(t => t.id === tb)
                        ?.name.split(".")[1]

                    if (instrumentName === undefined) {
                        throw new Error("Instrument name not found.")
                    }

                    const selectedNotes: {
                        start: string
                        pitches: number[]
                    }[] = []

                    data.notes.manipulateNoteGroups(
                        (noteGroup, index) => {
                            if (
                                Time.compareTwoFractions(
                                    noteGroup[0].start,
                                    section.end
                                ) !== "lt"
                            ) {
                                return false
                            }

                            const channelPitches = []

                            const pitches = noteGroup
                                .map(n => n.pitch)
                                .filter(p => {
                                    const channelName = pitchToChannelMapping[p]
                                    const compatiblePitchesForChannel =
                                        channelsToPitchMapping[channelName]

                                    for (const cp of compatiblePitchesForChannel) {
                                        if (
                                            samplesMap[instrumentName].includes(
                                                cp
                                            )
                                        ) {
                                            return true
                                        }
                                    }

                                    return false
                                })

                            if (pitches.length > 0) {
                                selectedNotes.push({
                                    start: Time.addTwoFractions(
                                        noteGroup[0].start,
                                        section.start,
                                        true
                                    ),
                                    pitches,
                                })
                            }

                            return true
                        },
                        [section.start, section.end]
                    )

                    return {
                        name: data.trackBus.name,
                        notes: selectedNotes,
                        gain: data.trackBus.gainOffset,
                    }
                })

                const effects = ScoreManipulation.getEffectsFromLayerInRange(
                    layer,
                    section.start,
                    section.end
                )

                const trainingLayer: SegmentedScorePercussionLayer = {
                    name: layer.name ? layer.name : layer.value,
                    value: layer.value,
                    type: "percussion",
                    dynamic: simplifyAutomation(
                        effects.dynamic,
                        section.start,
                        section.end,
                        s.firstTimeSignature
                    ),
                    reverb: {
                        ir:
                            layer.effects.reverb.active &&
                            (layer.effects.reverb as Reverb).ir !== "None"
                                ? (layer.effects.reverb as Reverb).ir
                                : "None",
                        values: simplifyAutomation(
                            effects.reverb,
                            section.start,
                            section.end,
                            s.firstTimeSignature
                        ),
                    },
                    gainBias: layer.gainBias,
                    channels: channels.filter(c => c.notes.length > 0),
                }

                return trainingLayer
            })

        if (!acceptEmptyChannels) {
            return res.filter(l => l.channels.length > 0)
        }

        return res
    }

    /**
     * Converts from a SegmentedScore to a TemplateScore. Mostly useful to be able to
     * visualize the SegmentedScore in the UI, and make sure that no crucial information was lost
     * during the Score -> SegmentedScore conversion, but may have other use cases in the future.
     *
     * This is not really intended to be used for production use cases.
     */
    export function fromSegmentedScoreToTemplateScore(
        score: SegmentedScore
    ): TemplateScore {
        const lastSection = score.sections[score.sections.length - 1]
        const keySignatures: TemplateKeySignature[] =
            KeySignatureModule.mergeKeySignatures(
                score.sections.map(s =>
                    s.key_signatures.map(ks => [
                        Time.addTwoFractions(ks.start, s.start),
                        ks.ks,
                    ])
                ),
                lastSection.end
            )
        let chords: TemplateChord[] = []
        const layers: TemplateLayers = {}
        const tracks: TemplateTrack[] = []

        let s = 0

        for (const section of score.sections) {
            chords = chords.concat(section.chords)

            for (const layer of section.layers) {
                if (layers[layer.value] === undefined) {
                    const l: TemplateLayer = {
                        type: layer.type,
                        value: layer.value,
                        gain_bias: layer.gainBias,
                        effects: createEffect(layer.reverb.ir),
                    }

                    if (layer.type === "percussion") {
                        // @todo: improve this to actually create patterns
                        l.pattern_regions = null as any
                        l.patterns = null as any
                    }

                    layers[layer.value] = l
                }

                layers[layer.value].effects.reverb.ir = layer.reverb.ir

                if (layer.type === "pitched") {
                    for (const inst of layer.instruments) {
                        const t: TemplateTrack = {
                            id: uuidv4(),
                            name: inst.name,
                            track_layer: layer.value,
                            layer: layer.value,
                            instrument:
                                inst.name.split(".")[0] +
                                "." +
                                inst.name.split(".")[1],
                            use_velocity: false,
                            use_expression: true,
                            auto_pedal: false,
                            octave: inst.octave,
                            mute: false,
                            solo: false,
                            dynamic_offset: 0,
                            gain_offset: inst.gain,
                            panning: 0,
                            breathing_gain: 0,
                            gm: 0,
                            channel: 0,
                            track: getTemplateNotesForInstrument(
                                layer,
                                inst,
                                s,
                                section.start
                            ),
                        }

                        tracks.push(t)
                    }
                } else {
                    for (const channel of layer.channels) {
                        const t: TemplateTrack = {
                            id: uuidv4(),
                            name: channel.name,
                            track_layer: layer.value,
                            layer: layer.value,
                            instrument:
                                channel.name.split(".")[0] +
                                "." +
                                channel.name.split(".")[1],
                            use_velocity: false,
                            use_expression: true,
                            auto_pedal: false,
                            octave: 0,
                            mute: false,
                            solo: false,
                            dynamic_offset: 0,
                            gain_offset: channel.gain,
                            panning: 0,
                            breathing_gain: 0,
                            gm: 0,
                            channel: 0,
                            track: getTemplateNotesForPercussionChannel(
                                layer,
                                channel,
                                s,
                                section.start
                            ),
                        }

                        tracks.push(t)
                    }
                }
            }

            s += 1
        }

        return {
            compositionID: "",
            lastSectionDuration: Time.addTwoFractions(
                lastSection.end,
                lastSection.start,
                true
            ),

            // extend in the future to support tempo changes per section
            tempoMap: [["0", lastSection.tempo]],

            // extend in the future to support time signature changes per section
            timeSignatures: [["0", lastSection.time_signature]],
            keySignatures: keySignatures,

            sections: score.sections.map(section => {
                return [section.start, section.name]
            }),

            chords,
            effects: {
                bass_boost: false,
                vinyl: false,
            },
            sustainPedal: [],
            type: "composition",

            layers,
            tracks,
        }
    }

    export function createEffect(ir: string): TemplateLayerEffect {
        return {
            dynamic: {
                active: true,
            },
            low_frequency_cut: {
                active: false,
            },
            high_frequency_cut: {
                active: false,
            },
            reverb: {
                active: true,
                ir,
            },
            delay: {
                active: false,
                left: {
                    delay_time: 0,
                },
                right: {
                    delay_time: 0,
                },
            },
            auto_staccato: {
                active: true,
            },
        }
    }

    function simplifyAutomation(
        values: number[],
        start: string,
        end: string,
        ts: TimeSignature
    ) {
        const oneBar = ts[0] + "/" + ts[1]

        const nbOfBars = Time.fractionToTimesteps(
            AUTOMATION_TIMESTEP_RES,
            Time.addTwoFractions(end, start, true)
        )

        const oneBarTimesteps = Time.fractionToTimesteps(
            AUTOMATION_TIMESTEP_RES,
            oneBar
        )

        const result: { value: number; time: string }[] = []

        let counter = 0

        for (let i = 0; i <= nbOfBars; i += oneBarTimesteps) {
            const value =
                i > values.length - 1 ? values[values.length - 1] : values[i]

            result.push({
                value: value,
                time: Time.multiplyFractionWithNumber(oneBar, counter),
            })

            counter++
        }

        return result
    }

    function getSegmentedScoreNotes(
        notes: NotesObject,
        start: string,
        end: string
    ): SegmentedScoreNote[] {
        const result: SegmentedScoreNote[] = []

        notes.manipulateNoteGroups(
            noteGroup => {
                if (
                    Time.compareTwoFractions(noteGroup[0].start, start) === "lt"
                ) {
                    return true
                }

                if (
                    Time.compareTwoFractions(noteGroup[0].start, end) !== "lt"
                ) {
                    return false
                }

                const note: SegmentedScoreNote = {
                    start: Time.addTwoFractions(
                        noteGroup[0].start,
                        start,
                        true
                    ),
                    duration: noteGroup[0].duration,
                    pitches: noteGroup.map(n => n.pitch),
                }

                result.push(note)

                return true
            },
            [start, end]
        )

        return result
    }

    function getSegmentedScoreInstruments(
        layer: Layer,
        instsJSON: InstrumentsJSON,
        start: string,
        end: string
    ): SegmentedScoreInstrument[] {
        const instruments: SegmentedScoreInstrument[] = []

        for (const inst of layer.trackBuses) {
            const instrument = getSegmentedScoreInstrument(
                inst,
                instsJSON,
                start,
                end
            )

            if (
                instrument === undefined ||
                instrument.playback_time.length === 0
            ) {
                continue
            }

            instruments.push(instrument)
        }

        return instruments
    }

    export function getTrackBusCategory(
        inst: TrackBus,
        instsJSON: InstrumentsJSON
    ): string | undefined {
        const object = inst.getPatchObject(instsJSON)

        if (object == null) {
            return undefined
        }

        const patch = object.patch

        const nbOfCategories = Math.max(patch.path.split("/").length - 2, 1)
        const categories: string[] = []

        for (let i = 0; i < nbOfCategories; i++) {
            categories.push(patch.path.split("/")[i])
        }

        return categories.join("/")
    }

    function getSegmentedScoreInstrument(
        inst: TrackBus,
        instsJSON: InstrumentsJSON,
        start: string,
        end: string
    ): SegmentedScoreInstrument | undefined {
        const category = getTrackBusCategory(inst, instsJSON)

        if (category === undefined) {
            return undefined
        }

        const instrument: SegmentedScoreInstrument = {
            name: inst.name,
            category,
            patch_type: getPatchType(inst.name.split(".")[3]),
            pitch_range: {
                lowest: inst.reference.lowest_note,
                highest: inst.reference.highest_note,
            },
            playback_time: Time.getRangesInRegion({
                ranges: inst.blocks.map(b => {
                    return {
                        start: Time.timestepsToFraction(TIMESTEP_RES, b.start),
                        end: Time.timestepsToFraction(TIMESTEP_RES, b.end),
                    }
                }),
                start,
                end,
            }).map(r => {
                return {
                    start: Time.addTwoFractions(r.start, start, true),
                    end: Time.addTwoFractions(r.end, start, true),
                }
            }),
            octave: inst.octave,
            gain: inst.gainOffset,
        }

        return instrument
    }

    function getPatchType(articulation: string): "short" | "long" | "legato" {
        if (articulation.includes("slur")) {
            return "legato"
        } else if (articulation.includes("stac")) {
            return "short"
        }

        return "long"
    }

    function convertToNoteAutomation(
        automation: { time: string; value: number }[],
        noteStart: string,
        noteEnd: string
    ) {
        const result: [string, number][] = []

        for (const a of automation) {
            if (
                result.length === 0 &&
                Time.compareTwoFractions(noteStart, a.time) === "lt"
            ) {
                result.push(["0", a.value])
            }

            if (
                Time.fractionIsInBoundaries(
                    {
                        start: noteStart,
                        duration: Time.addTwoFractions(
                            noteEnd,
                            noteStart,
                            true
                        ),
                    },
                    a.time
                )
            ) {
                result.push([
                    Time.addTwoFractions(a.time, noteStart, true),
                    a.value,
                ])
            }
        }

        return result
    }

    function getTemplateNotesForPercussionChannel(
        layer: SegmentedScorePercussionLayer,
        channel,
        index: number,
        start: string
    ): TemplateNote[] {
        const newNotes: TemplateNote[] = []

        for (const n of channel.notes) {
            newNotes.push({
                pitch: n.pitches,
                note_ids: {},
                start: Time.addTwoFractions(n.start, start),
                duration: "1/48",
                meta: {
                    section: index,
                    layer: layer.value,
                },
                low_frequency_cut: [],
                high_frequency_cut: [],
                delay: [],
                reverb: [],
                dynamic: [],
            })
        }

        return newNotes.map(n => {
            n = addAutomationToTemplateNote(
                n,
                layer.dynamic,
                layer.reverb.values,
                start
            )

            return n
        })
    }

    function getTemplateNotesForInstrument(
        layer: SegmentedScoreLayer,
        instrument: SegmentedScoreInstrument,
        sectionIndex: number,
        sectionStart: string
    ): TemplateNote[] {
        const newNotes: TemplateNote[] = []

        for (const n of layer.notes) {
            const shouldAdd = instrument.playback_time.some(p => {
                return Time.fractionIsInBoundaries(
                    {
                        start: p.start,
                        duration: Time.addTwoFractions(p.end, p.start, true),
                    },
                    n.start
                )
            })

            if (shouldAdd) {
                newNotes.push({
                    pitch: n.pitches,
                    note_ids: {},
                    start: Time.addTwoFractions(n.start, sectionStart),
                    duration: n.duration,
                    meta: {
                        section: sectionIndex,
                        layer: layer.value,
                    },
                    low_frequency_cut: [],
                    high_frequency_cut: [],
                    delay: [],
                    reverb: [],
                    dynamic: [],
                })
            }
        }

        return newNotes.map(n => {
            n = addAutomationToTemplateNote(
                n,
                layer.dynamic,
                layer.reverb.values,
                sectionStart
            )

            return n
        })
    }

    function addAutomationToTemplateNote(
        n: TemplateNote,
        dynamic: { time: string; value: number }[],
        reverb: { time: string; value: number }[],
        sectionStart: string
    ) {
        const start = Time.addTwoFractions(n.start, sectionStart, true)
        const end = Time.addTwoFractions(start, n.duration)

        n.dynamic = convertToNoteAutomation(dynamic, start, end)
        n.reverb = convertToNoteAutomation(reverb, start, end)

        return n
    }

    export function getReducedLayerName(layer: string): string {
        if (layer.includes("Melody")) {
            layer = "Melody"
        } else if (layer.includes("Bass")) {
            layer = "Bass"
        } else if (layer.includes("Percussion")) {
            layer = "Percussion"
        } else if (layer.includes("Ornaments")) {
            layer = "Ornaments"
        } else {
            layer = "Accompaniment"
        }

        return layer
    }

    /**
     * Returns the instrument string for a given section and track busses formatted in the way,
     * the layer generation model expects it as a parameter.
     *
     * @param sections, SegmentedScoreSection[] the sections of the score used to look up instrumentation in case the given section does not provide it.
     * @param section, SegmentedScoreSection the section for which we want to get the instrument string.
     * @param trackBusses, TrackBus[] the track busses of the score used to look up instrumentation in case the given section does not provide it.
     * @param instruments, InstrumentsJSON the instruments JSON used to look up the category of the track busses.
     * @returns string, e.g. "Strings/Ensemble l 0-192 0, Piano s 192-384 0" for two instruments in a pitched section.
     *                       "drumkit-rock-1, drumkit-rock-2" for two instruments in a percussion section.
     */
    export function getInstrumentFromTrackBuses(
        sections: SegmentedScoreSection[],
        section: SegmentedScoreSection,
        trackBusses: TrackBus[],
        instruments: InstrumentsJSON
    ): {
        instrumentString: string
        trackBusses?: TrackBus[]
    } {
        const res = {
            instrumentString: "",
            trackBusses: [], // this is only filled when we have to add trackbus regions to the section.
        }

        const instRes = getInstrumentFromTrackBusesForSection(
            section,
            trackBusses,
            instruments
        )

        function joinedInstrumentStrings(instRes) {
            const strings = [
                ...new Set(
                    instRes.values.map(inst => {
                        return inst.string
                    })
                ),
            ]
            return strings.join(", ")
        }

        res.instrumentString = joinedInstrumentStrings(instRes)

        // Success, we found instruments for the current section.
        if (res.instrumentString !== "") {
            // We do not add trackBuses here on purpose to res.
            // This is because for any trackBus added here,
            // we will add a region to the section, and we only
            // want to do that in case no trackBus regions can
            // be found for the current section.
            return res
        }

        // In case no instruments were found for the current section, we look up the instrumentation in the previous or next sections.
        // We then do NOT update the string but inform the higher function to use the found section trackBusses instead.
        const startIndex = section.index
        const length = sections.length

        for (let i = 0; i < length; i++) {
            const index = (startIndex + i) % length
            const nextSection = sections[(index + 1) % length] // next item or first item if current is the last
            const previousSection = sections[(index - 1 + length) % length] // previous item or last item if current is the first

            const nextInstRes = getInstrumentFromTrackBusesForSection(
                nextSection,
                trackBusses,
                instruments
            )

            if (joinedInstrumentStrings(nextInstRes) !== "") {
                res.trackBusses = nextInstRes.values.map(inst => {
                    return inst.bus
                })

                break
            }

            const previousInstRes = getInstrumentFromTrackBusesForSection(
                previousSection,
                trackBusses,
                instruments
            )

            if (joinedInstrumentStrings(previousInstRes) !== "") {
                res.trackBusses = previousInstRes.values.map(inst => {
                    return inst.bus
                })

                break
            }
        }

        // Corner case of no trackBus regions being added to the whole layer yet.
        if (res.trackBusses.length === 0 && trackBusses?.length) {
            res.instrumentString =
                SegmentedScoreManipulationModule.getFormattedSingleInstrumentString(
                    trackBusses[0],
                    Time.fractionToTimesteps(TIMESTEP_RES, section.start),
                    Time.fractionToTimesteps(TIMESTEP_RES, section.end),
                    instruments
                )
            res.trackBusses = [trackBusses[0]]
        }

        return res
    }

    /**
     * This method returns the instrument string for a given section and track busses assuming that there are trackbuses
     * in the layer and trackbus region in the range of the given section.
     * @param section SegmentedScoreSection section for which we want to get the instrument string.
     * @param trackBusses TrackBus[] the track busses of the score.
     * @param instruments InstrumentsJSON the instruments JSON used to look up the category of the track busses.
     * @returns Object with the type of the instruments and the values of the instruments.
     */
    export function getInstrumentFromTrackBusesForSection(
        section: SegmentedScoreSection,
        trackBusses: TrackBus[],
        instruments: InstrumentsJSON
    ): {
        type: string
        values: Array<{
            start: string
            string: string
            bus: TrackBus
        }>
    } {
        const instParams = {
            type: "pitched",
            values: [],
        }

        if (!trackBusses?.length) {
            return instParams
        }

        const sectionStartTS = Time.fractionToTimesteps(
            TIMESTEP_RES,
            section.start
        )
        const sectionEndTS = Time.fractionToTimesteps(TIMESTEP_RES, section.end)

        for (let bus of trackBusses) {
            if (bus.name.split(".")[0] === "p") {
                instParams.type = "percussion"
                instParams.values.push({
                    start: null,
                    string: bus.name.split(".")[1],
                    bus: bus,
                })
            } else {
                instParams.type = "pitched"
                const category = getTrackBusCategory(bus, instruments)
                const patchType = articulationToCompressedPatchType(
                    bus.name.split(".")[3]
                )

                for (let block of bus.blocks) {
                    let blockStartTS = block.start
                    let blockEndTS = block.end

                    // Only add blocks that are within the range of the current section.
                    if (
                        blockEndTS <= sectionStartTS ||
                        blockStartTS >= sectionEndTS
                    ) {
                        continue
                    }

                    // The layer generation model expects the start and end times
                    // to be relative to the section start.
                    let relStart = blockStartTS - sectionStartTS
                    let relEnd = blockEndTS - sectionStartTS

                    relStart = Math.max(0, relStart)
                    relEnd = Math.min(sectionEndTS - sectionStartTS, relEnd)

                    instParams.values.push({
                        start: relStart,
                        string: `${category} ${patchType} ${relStart}-${relEnd} ${bus.octave}`,
                        bus: bus,
                    })
                }
            }
        }

        return instParams
    }

    export function getFormattedSingleInstrumentString(
        bus: TrackBus,
        regionStart: number,
        regionEnd: number,
        instruments: InstrumentsJSON
    ): string {
        const category = getTrackBusCategory(bus, instruments)
        const patchType = articulationToCompressedPatchType(
            bus.name.split(".")[3]
        )

        return `${category} ${patchType} ${regionStart}-${regionEnd} ${bus.octave}`
    }

    /**
     * This method returns the instrument string for a given section and track busses.
     * If the instrument string is empty, it will
     * - identify track busses to use instead (by looking at previous and next sections in the score)
     * - add the track bus regions for the given section
     * - then will return the instrument string based on the now added trackbuses and regions.
     * @param sections
     * @param section
     * @param layerTrackBusses
     * @param instruments
     * @returns
     */
    export function getInstrumentStringForLayerGeneration(
        sections: SegmentedScoreSection[],
        section: SegmentedScoreSection,
        layerTrackBusses: TrackBus[],
        instruments: InstrumentsJSON
    ) {
        let instrument = ""
        let instrumentRes =
            SegmentedScoreManipulationModule.getInstrumentFromTrackBuses(
                sections,
                section,
                layerTrackBusses,
                instruments
            )

        instrument = instrumentRes.instrumentString

        SegmentedScoreManipulationModule.addTrackBusRegionsForSection(
            section,
            instrumentRes.trackBusses
        )

        // Try again after adding the track bus regions only if we initially
        // did not find any trackBuses in that section.
        if (instrument === "") {
            instrumentRes =
                SegmentedScoreManipulationModule.getInstrumentFromTrackBuses(
                    sections,
                    section,
                    instrumentRes.trackBusses,
                    instruments
                )

            instrument = instrumentRes.instrumentString
        }

        return instrument
    }

    /**
     * Finds the gaps between a given overarching range and an array of ranges.
     * @param overarchingRange - The overarching range to find gaps within.
     * @param ranges - An array of ranges to compare against the overarching range.
     * @returns An array of objects representing the gaps between the ranges.
     */
    function findGaps(
        overarchingRange: { start: number; end: number },
        ranges: Array<{ start: number; end: number }>
    ): Array<{ start: number; end: number }> {
        // Sort the ranges by their start points
        ranges.sort((a, b) => a.start - b.start)

        let gapStart = overarchingRange.start
        let gaps = []

        for (let range of ranges) {
            if (gapStart < range.start) {
                // Found a gap
                gaps.push({ start: gapStart, end: range.start })
            }
            gapStart = Math.max(gapStart, range.end)
        }

        if (gapStart < overarchingRange.end) {
            // Found a gap
            gaps.push({
                start: gapStart,
                end: overarchingRange.end,
            })
        }

        return gaps
    }

    export function addTrackBusRegionsForSection(
        section: SegmentedScoreSection,
        trackBuses: TrackBus[]
    ) {
        const sectionStart = Time.fractionToTimesteps(
            TIMESTEP_RES,
            section.start
        )
        const sectionEnd = Time.fractionToTimesteps(TIMESTEP_RES, section.end)

        // Add the trackBus regions for the section
        for (const trackBus of trackBuses) {
            // Add a new block for the whole section range.
            trackBus.blocks = Score.modifyTrackBusRegions(
                sectionStart,
                sectionEnd,
                trackBus.blocks,
                "add"
            )

            trackBus.blocks = ScoreManipulation.mergeTrackBusRegions(
                trackBus.blocks
            ).regions
        }
    }

    export function convertCWNoteToSegmentedScorePercussionNote(
        cwNote: CompositionWorkflowNote
    ): SegmentedScorePercussionNote {
        return {
            start: cwNote.start,
            pitches: cwNote.pitch,
        }
    }

    function articulationToPatchType(art: string) {
        let patchType: "long" | "short" | "legato" = "long"

        if (art === "legato") {
            patchType = "legato"
        }

        if (art.includes("stac")) {
            patchType = "short"
        }

        return patchType
    }

    function articulationToCompressedPatchType(art: string) {
        if (art === "legato") {
            return "slur"
        }

        if (art.includes("stac")) {
            return "s"
        }

        return "l"
    }
}
