import { Misc } from "./misc"
import { Time } from "./time"
import {
    TemplateChannel,
    TemplateNote,
    TemplatePattern,
    TemplatePatternRegion,
    TemplateScore,
    TemplateTrack,
} from "../interfaces/score/templateScore"
import { v4 as uuidv4 } from "uuid"
import { DrumSamplesMap } from "../interfaces/score/general"
import { TimeSignature } from "../types/score"
import { Fraction } from "../classes/score/fraction"
import { Pattern } from "../classes/score/pattern"
import PatternRegion from "../classes/score/patternregion"
import TrackBus from "../classes/score/trackbus"
import { mergeMap } from "rxjs"

const drumMapping = require("../../assets/json/drumMapping.json")

interface PatternNote {
    note: TemplateNote
    track: string
}

interface CustomTemplatePatternRegion extends TemplatePatternRegion {
    id: string
}

interface CustomPercussionNotes {
    [layer: string]: PatternNote[]
}

interface CustomTemplatePatternForLayers {
    [layer: string]: CustomTemplatePattern[]
}

interface CustomTemplatePattern extends TemplatePattern {
    noteObjects: PatternNote[]
}

export interface PercussionOnset {
    start: Fraction
    pitches: number[]
}

export module PercussionPatternController {
    export function mergeNewPatternsInSourcePatterns(
        newData: {
            patterns: Pattern[]
            patternRegions: PatternRegion[]
        },
        sourceData: {
            patterns: Pattern[]
            patternRegions: PatternRegion[]
        }
    ): {
        patterns: Pattern[]
        patternRegions: PatternRegion[]
    } {
        // First, we want to get the index to start from based on the sourceData
        let index = Math.max(...sourceData.patterns.map(p => p.id)) + 1

        // Then, we want to reindex the newData
        newData.patterns.forEach(p => {
            p.id = index

            index += 1
        })

        const selectedPatterns = []

        // Then, we want to merge the patterns
        for (const p1 of newData.patterns) {
            let noEqual = true

            for (const p2 of sourceData.patterns) {
                if (p1.isEqual(p2)) {
                    const prs = newData.patternRegions.filter(
                        pr => pr.pattern.id === p1.id
                    )

                    prs.forEach(pr => {
                        pr.pattern = p2
                    })

                    noEqual = false

                    break
                } else {
                    selectedPatterns.push(p1)
                }
            }
        }

        sourceData.patterns = sourceData.patterns.concat(selectedPatterns)
        sourceData.patternRegions = sourceData.patternRegions.concat(
            newData.patternRegions
        )

        return {
            patterns: sourceData.patterns,
            patternRegions: sourceData.patternRegions,
        }
    }

    /**
     * Converts the percussion onsets for a full score into a set of patterns and pattern regions
     * @param onsets
     */
    export function onsetsToPatterns(
        onsets: PercussionOnset[],
        timeSignature: TimeSignature,
        samplesMap: DrumSamplesMap,
        tb: TrackBus
    ): {
        patterns: Pattern[]
        patternRegions: PatternRegion[]
    } {
        const result = {
            patterns: [] as Pattern[],
            patternRegions: [] as PatternRegion[],
        }

        const notes: TemplateNote[] = onsets.map(onset => {
            const note: TemplateNote = {
                start: onset.start.toString(),
                pitch: onset.pitches,
                duration: "1/4",
                meta: {
                    section: 0,
                    layer: "Percussion",
                },
                note_ids: {},
                low_frequency_cut: [],
                high_frequency_cut: [],
                reverb: [],
                delay: [],
                dynamic: [],
            }

            return note
        })

        const track: TemplateTrack = {
            id: uuidv4(),
            track_layer: "Percussion",
            layer: "Percussion",
            name: "p.drumkit-rock-1.nat.stac",
            instrument: "p.drumkit-rock-1",
            use_velocity: true,
            use_expression: false,
            auto_pedal: false,
            octave: 0,
            mute: false,
            solo: false,
            dynamic_offset: 0,
            gain_offset: 0,
            panning: 0,
            breathing_gain: 0,
            gm: 0,
            channel: 0,
            track: notes,
        }

        const score: TemplateScore = {
            type: "composition",
            compositionID: "",
            chords: [],
            keySignatures: [],
            tracks: [track],
            timeSignatures: [["0", timeSignature]],
            sustainPedal: [],
            sections: [],
            tempoMap: [],
            effects: { bass_boost: false, vinyl: false },
            layers: {
                Percussion: {
                    type: "percussion",
                    value: "Percussion",
                    effects: {
                        dynamic: { active: true },
                        low_frequency_cut: { active: true },
                        high_frequency_cut: { active: true },
                        reverb: {
                            active: true,
                            ir: "None",
                        },
                        delay: {
                            active: true,
                            left: {
                                delay_time: 0,
                            },
                            right: {
                                delay_time: 0,
                            },
                        },
                        auto_staccato: {
                            active: true,
                        },
                    },
                    gain_bias: 0,
                },
            },
        }

        const temp = createPatternsFromScore(score, samplesMap)

        for (const pattern of temp.layers["Percussion"].patterns) {
            const p = Pattern.fromTemplatePattern(pattern, tb)

            result.patterns.push(p)
        }

        for (const patternRegions of temp.layers["Percussion"]
            .pattern_regions) {
            const pr = PatternRegion.fromTemplateTrack(
                patternRegions,
                result.patterns
            )

            if (pr !== undefined) {
                result.patternRegions.push(pr)
            }
        }

        return result
    }

    export function createPatternsFromScore(
        score: TemplateScore,
        samplesMap: DrumSamplesMap
    ): TemplateScore {
        const timeSignature = score.timeSignatures[0][1]

        const percussionNotes = getPercussionNotesWithoutPattern(score)

        let patterns = generateTwoBarLongPatterns(
            percussionNotes,
            timeSignature
        )

        patterns = removeDuplicatePatterns(patterns, score)

        const percussionTracks = []

        for (var track of score.tracks) {
            if (
                track.track.length == 0 ||
                !track.track[0].meta.layer.includes("Percussion")
            ) {
                continue
            }

            percussionTracks.push(track)
        }

        patterns = setUnsupportedChannelsUnassigned(
            percussionTracks,
            patterns,
            samplesMap,
            score
        )

        addPatternsToScore(patterns, score)
        addPatternRegionsToScore(percussionNotes, score, timeSignature)

        return score
    }

    // todo: update this so it adds an ID to each pattern region
    export function addPatternRegionsToScore(
        percussionNotes: CustomPercussionNotes,
        score: TemplateScore,
        timeSignature: TimeSignature
    ) {
        function getLoopAmount(end, patternRegion) {
            var regionLoopedLength = Time.fractionToNumber(
                Time.addTwoFractions(end, patternRegion.start, true)
            )
            var regionLength = Time.fractionToNumber(patternRegion.duration)

            var result = Math.round(regionLoopedLength / regionLength)

            if (result <= 1) {
                return 0
            }

            return result - 1
        }

        for (var layer in percussionNotes) {
            if (score.layers[layer].pattern_regions == null) {
                score.layers[layer].pattern_regions = []
            }

            var patternRegion: CustomTemplatePatternRegion | null = null
            var note
            var previousNote

            for (var noteObject of percussionNotes[layer]) {
                previousNote = note
                note = noteObject.note

                var nextPatternRegion = false
                const twoBars = timeSignature[0] * 2 + "/" + timeSignature[1]

                if (
                    previousNote != null &&
                    patternRegion != null &&
                    patternRegion?.pattern == note.meta.pattern.id
                ) {
                    var difference = Time.addTwoFractions(
                        note.start,
                        previousNote.start,
                        true
                    )

                    if (Time.compareTwoFractions(difference, twoBars) != "lt") {
                        nextPatternRegion = true
                    }
                }

                if (
                    nextPatternRegion ||
                    patternRegion == null ||
                    patternRegion?.pattern != note.meta.pattern.id
                ) {
                    if (patternRegion != null) {
                        patternRegion.loop = getLoopAmount(
                            previousNote.start,
                            patternRegion
                        )

                        score.layers[layer].pattern_regions.push(patternRegion)
                    }

                    let selectedPattern

                    for (var pattern of score.layers[layer].patterns) {
                        if (pattern.id == note.meta.pattern.id) {
                            selectedPattern = pattern

                            break
                        }
                    }

                    patternRegion = {
                        id: uuidv4(),
                        start: Time.quantizeFractionToBar(
                            timeSignature,
                            note.start
                        ),
                        onset: "0",
                        duration: twoBars,
                        loop: 0,
                        pattern: selectedPattern.id,
                    }
                }
            }

            if (patternRegion != null && note != null) {
                if (previousNote != null) {
                    patternRegion.loop = getLoopAmount(
                        previousNote.start,
                        patternRegion
                    )
                }

                score.layers[layer].pattern_regions.push(patternRegion)
            }
        }
    }

    /**
     * Sets channel names to 'Unassigned' if the instrument in question does not contain a certain pitch and hence can't be mapped correctly
     * @param patterns Object. Keys are layer values and values are pattern arrays
     * @param samplesMap mapping of samples to an instrument
     * @param score JSON score Object
     * @returns
     */
    export function setUnsupportedChannelsUnassigned(
        tracks,
        patterns: CustomTemplatePatternForLayers,
        samplesMap: DrumSamplesMap,
        score: TemplateScore
    ) {
        for (var layer in patterns) {
            for (var pattern of patterns[layer]) {
                setChannelName(tracks, pattern, samplesMap)
            }
        }

        if (Object.keys(patterns).length == 0) {
            for (let layer in score.layers) {
                if (
                    score.layers[layer].type == "percussion" &&
                    score.layers[layer].patterns !== undefined
                ) {
                    for (let pattern of score.layers[layer].patterns) {
                        setChannelName(tracks, pattern, samplesMap)
                    }
                }
            }
        }

        return patterns
    }

    export function setChannelName(tracks, pattern, samplesMap) {
        let newChannels: any[] = []

        for (let channel of pattern.channels) {
            var instrument

            for (var track of tracks) {
                if (track.id == channel.track) {
                    instrument = track.instrument

                    break
                }
            }

            if (instrument == null) {
                const silentTrackBus = tracks.find(t =>
                    t.name.includes("silent-kit")
                )

                if (!silentTrackBus) {
                    continue
                }

                instrument = silentTrackBus.instrument
            }

            var instrumentName = instrument.split(".")[1]

            var setUnassigned = true

            for (var pitch of channel.pitches) {
                if (
                    samplesMap[instrumentName] != null &&
                    samplesMap[instrumentName].includes(pitch)
                ) {
                    setUnassigned = false

                    break
                }
            }

            if (setUnassigned) {
                channel.name = "Unassigned"
            }

            newChannels.push(channel)
        }

        pattern.channels = newChannels
    }

    export function addPatternsToScore(
        patterns: CustomTemplatePatternForLayers,
        score: TemplateScore
    ) {
        let highestPatternIndex = 0

        const layers = Object.keys(score.layers)
            .map(l => score.layers[l])
            .filter(l => l?.type === "percussion")

        for (const layer of layers) {
            if (layer.patterns != null) {
                for (const pattern of layer.patterns) {
                    highestPatternIndex = Math.max(
                        highestPatternIndex,
                        pattern.id
                    )
                }
            } else {
                layer.patterns = []
            }

            if (patterns[layer.value] === undefined) {
                continue
            }

            for (const p of patterns[layer.value]) {
                highestPatternIndex += 1

                p.id = highestPatternIndex
                p.name = "Pattern " + p.id

                addPatternMetaToNotes(p)

                delete p.noteObjects

                score.layers[layer.value].patterns.push(p)
            }
        }
    }

    export function removeDuplicatePatterns(
        patterns: CustomTemplatePatternForLayers,
        score: TemplateScore
    ): CustomTemplatePatternForLayers {
        var result = {}

        for (var layer in patterns) {
            result[layer] = []

            for (var q = 0; q < patterns[layer].length; q++) {
                var patternsForLayer = patterns[layer]
                var add = true

                if (
                    score.layers[layer] != null &&
                    score.layers[layer].patterns != null
                ) {
                    for (
                        var p = 0;
                        p < score.layers[layer].patterns.length;
                        p++
                    ) {
                        if (
                            patternsAreEqual(
                                patternsForLayer[q],
                                score.layers[layer].patterns[p]
                            )
                        ) {
                            patternsForLayer[q].id =
                                score.layers[layer].patterns[p].id

                            addPatternMetaToNotes(patternsForLayer[q])
                            add = false

                            break
                        }
                    }
                }

                if (add) {
                    for (var p = 0; p < result[layer].length; p++) {
                        if (
                            patternsAreEqual(
                                result[layer][p],
                                patternsForLayer[q]
                            )
                        ) {
                            result[layer][p].noteObjects = result[layer][
                                p
                            ].noteObjects.concat(
                                patternsForLayer[q].noteObjects
                            )

                            add = false

                            break
                        }
                    }

                    if (add) {
                        result[layer].push(patternsForLayer[q])
                    }
                }
            }
        }

        return result
    }

    export function addPatternMetaToNotes(pattern: CustomTemplatePattern) {
        for (var n = 0; n < pattern.noteObjects.length; n++) {
            pattern.noteObjects[n].note.meta.pattern.id = pattern.id
        }
    }

    export function sortOnsets(onsets) {
        onsets.sort((a, b) => {
            if (Time.compareTwoFractions(a.start, b.start) == "lt") {
                return -1
            } else if (Time.compareTwoFractions(a.start, b.start) == "gt") {
                return 1
            }

            return 0
        })

        return onsets
    }

    export function patternsAreEqual(
        pattern1: TemplatePattern,
        pattern2: TemplatePattern
    ): boolean {
        if (pattern1.bars != pattern2.bars) {
            return false
        }

        if (pattern1.resolution != pattern2.resolution) {
            return false
        }

        if (pattern1.channels.length != pattern2.channels.length) {
            return false
        }

        for (var c = 0; c < pattern1.channels.length; c++) {
            var firstChannel = Object.assign({}, pattern1.channels[c])
            firstChannel.onsets = sortOnsets(firstChannel.onsets)
            firstChannel.track = null

            var hasEqualChannel = false

            for (var d = 0; d < pattern2.channels.length; d++) {
                var secondChannel = Object.assign({}, pattern2.channels[d])
                secondChannel.onsets = sortOnsets(secondChannel.onsets)
                secondChannel.track = null

                if (Misc.objectsAreEqual(firstChannel, secondChannel)) {
                    hasEqualChannel = true

                    break
                }
            }

            if (!hasEqualChannel) {
                return false
            }
        }

        return true
    }

    export function generateTwoBarLongPatterns(
        percussionNotes: CustomPercussionNotes,
        timeSignature: TimeSignature
    ): CustomTemplatePatternForLayers {
        const patterns: CustomTemplatePatternForLayers = {}

        var twoMeasuresInWholeNote =
            timeSignature[0] * 2 + "/" + timeSignature[1]

        for (var layer in percussionNotes) {
            percussionNotes[layer].sort((a, b) => {
                if (
                    Time.compareTwoFractions(a.note.start, b.note.start) == "lt"
                ) {
                    return -1
                } else if (
                    Time.compareTwoFractions(a.note.start, b.note.start) == "gt"
                ) {
                    return 1
                }

                return 0
            })

            let patternNotes: PatternNote[] = []

            patterns[layer] = []

            if (percussionNotes[layer].length == 0) {
                continue
            }

            let currentPatternStart = Time.quantizeFractionToBar(
                timeSignature,
                percussionNotes[layer][0].note.start
            )

            for (const object of percussionNotes[layer]) {
                var difference = Time.addTwoFractions(
                    object.note.start,
                    currentPatternStart,
                    true
                )

                if (
                    Time.compareTwoFractions(
                        difference,
                        twoMeasuresInWholeNote
                    ) != "lt"
                ) {
                    patterns[layer].push(
                        createPatternFromNotes(patternNotes, timeSignature)
                    )

                    currentPatternStart = Time.quantizeFractionToBar(
                        timeSignature,
                        object.note.start
                    )

                    patternNotes = [object]
                } else {
                    patternNotes.push(object)
                }
            }

            if (patternNotes.length > 0) {
                patterns[layer].push(
                    createPatternFromNotes(patternNotes, timeSignature)
                )
            }
        }

        return patterns
    }

    export function createPatternFromNotes(
        notes: PatternNote[],
        timeSignature: TimeSignature
    ): CustomTemplatePattern {
        let highestResolution = "1/4"
        let channels: TemplateChannel[] = []

        const wholeNotesPer2Bars = (timeSignature[0] / timeSignature[1]) * 2

        notes.sort((a, b) => {
            if (Time.compareTwoFractions(a.note.start, b.note.start) == "lt") {
                return -1
            } else if (
                Time.compareTwoFractions(a.note.start, b.note.start) == "gt"
            ) {
                return 1
            }

            return 0
        })

        const patternStart = Time.quantizeFractionToBar(
            timeSignature,
            notes.length > 0 ? notes[0].note.start : "0"
        )

        for (const note of notes) {
            const newFraction = Time.simplifyFractionFromString(note.note.start)
            const dictionary = Time.fractionToDictionary(newFraction)
            const newResolution = "1/" + dictionary.denominator

            if (
                Time.compareTwoFractions(highestResolution, newResolution) !=
                "lt"
            ) {
                highestResolution = newResolution
            }

            const normalizedOnset = Time.addTwoFractions(
                note.note.start,
                patternStart,
                true
            )

            for (const pitch of note.note.pitch) {
                let add = true

                let noteChannel = drumMapping[pitch]
                note.note.meta.pattern = {
                    id: null,
                    onset: normalizedOnset,
                }

                for (const channel of channels) {
                    if (
                        channel.name == noteChannel &&
                        channel.track == note.track + ""
                    ) {
                        add = false

                        let addOnset = true

                        for (const onset of channel.onsets) {
                            if (
                                Time.compareTwoFractions(
                                    normalizedOnset,
                                    onset.start
                                ) == "eq"
                            ) {
                                addOnset = false

                                break
                            }
                        }

                        if (addOnset) {
                            channel.onsets.push({ start: normalizedOnset })
                        }

                        break
                    }
                }

                if (add) {
                    let channelPitches: number[] = []

                    for (const pitchString in drumMapping) {
                        if (drumMapping[pitchString] == noteChannel) {
                            const pitch = parseInt(pitchString)

                            channelPitches.push(pitch)
                        }
                    }

                    channels.push({
                        name: noteChannel,
                        pitches: channelPitches,
                        onsets: [{ start: normalizedOnset }],
                        track: note.track + "",
                        mute: false,
                        solo: false,
                    })
                }
            }
        }

        return {
            id: null,
            name: null,
            bars: 2,
            resolution: highestResolution,
            mute: false,
            solo: false,
            channels: channels,
            noteObjects: notes,
        }
    }

    export function getPercussionNotesWithoutPattern(
        score: TemplateScore
    ): CustomPercussionNotes {
        const percussionNotes: CustomPercussionNotes = {}

        for (const track of score.tracks) {
            const notes = track.track

            for (const note of notes) {
                const layer =
                    track.layer != null ? track.layer : note.meta.layer

                if (!layer.includes("Percussion")) {
                    break
                }

                if (note.meta == null) {
                    note.meta = {
                        section: undefined,
                        layer: undefined,
                    }
                }

                if (note.meta.pattern != null) {
                    continue
                }

                if (percussionNotes[layer] == null) {
                    percussionNotes[layer] = []
                }

                percussionNotes[layer].push({
                    note: note,
                    track: track.id,
                })
            }
        }

        for (const layer in percussionNotes) {
            percussionNotes[layer].sort((a, b) => {
                if (
                    Time.compareTwoFractions(a.note.start, b.note.start) == "lt"
                ) {
                    return -1
                } else if (
                    Time.compareTwoFractions(a.note.start, b.note.start) == "gt"
                ) {
                    return 1
                }

                return 0
            })
        }

        return percussionNotes
    }
}
