<div class="container" *ngIf="service !== undefined">
    <div class="settings-row" av-data="cw-step3-name">
        <div class="settings-row-title">
            Composition workflow name
            <img
                class="error-tooltip"
                src="assets/img/warning_red.svg"
                *ngIf="workflowNameIsInvalid()"
                [tooltip]="this.service.step3().loading.error"
                [tooltip-class]="'tooltip-error'"
                [tooltip-marginTop]="10"
                [tooltip-width]="250" />
        </div>
        <div class="settings-row-value">
            <input
                placeholder="Enter your workflow's name"
                [(ngModel)]="workflowName"
                (ngModelChange)="changeName($event)"
                type="text"
                class="light-input" />
        </div>
    </div>

    <div class="settings-row" av-data="cw-step3-duration">
        <div class="settings-row-title">Composition duration</div>
        <div class="settings-row-value">
            <dropdown
                class="create-with-gp-dropdown"
                [value]="getSelectedDuration()"
                [showTooltip]="false"
                [items]="service.getGenerationProfileDurations()"
                (selectedItem)="setDuration($event)"></dropdown>
        </div>
    </div>

    <div class="settings-row" av-data="cw-step3-composition-count">
        <div class="settings-row-title">Number of Compositions</div>
        <div class="settings-row-value">
            <dropdown
                [value]="getSelectedNbOfCompositions()"
                [showTooltip]="false"
                [items]="getNbOfCompositionsOptions()"
                (selectedItem)="setNbOfCompositions($event)"></dropdown>
        </div>
    </div>

    <div
        class="settings-row"
        *ngIf="getFeatureFlags().newThematicMaterialToggle && !getFeatureFlags().enableAutomaticVariationImprovements"
        av-data="cw-step3-thematic-material">
        <div class="settings-row-title">New thematic material</div>
        <div class="settings-row-value">
            <slide-toggle
                [(toggled)]="service.newThematicMaterial"
                (onChange)="
                    service.setNewThematicMaterial($event)
                "></slide-toggle>
        </div>
    </div>

    <div
        class="settings-row"
        *ngIf="type !== 'chordProgression' && !getFeatureFlags().enableAutomaticVariationImprovements"
        av-data="cw-step3-instruments-change">
        <div class="settings-row-title">Allow instrumentation changes</div>
        <div class="settings-row-value">
            <slide-toggle
                [(toggled)]="service.allowInstrumentationChanges"
                (onChange)="
                    service.setAllowInstrumentationChanges($event)
                "></slide-toggle>
        </div>
    </div>

    <div class="settings-row" av-data="cw-step3-save">
        <div class="settings-row-title">Save workflow to your account</div>
        <div class="settings-row-value">
            <slide-toggle
                [(toggled)]="service.step3().saveWorkflowToAccount"
                (onChange)="
                    service.setSaveWorkflowToAccount($event)
                "></slide-toggle>
        </div>
    </div>

    <div
        class="error-message-banner"
        *ngIf="service.step3().loading.error !== undefined">
        {{ service.step3().loading.error }}
    </div>

    <div
        class="button-container"
        *ngIf="service.step3().loading.finished === true">
        <a-button
            avData="generateCompositionButton"
            type="primary"
            width="100%"
            label="Create composition"
            (click)="service.generateComposition()"></a-button>
    </div>

    <div
        class="button-container"
        *ngIf="service.step3().loading.finished === false">
        <div class="small-loader"></div>
    </div>
</div>
