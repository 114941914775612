import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnInit,
    Component,
    ElementRef,
    EventEmitter,
    Host,
    Input,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
} from "@angular/core"
import {
    DoublingInstrumentSelectionModalParameters,
    EditDoubleFolderModalParameters,
    EditDoubleModalParameters,
    InstrumentSelectionModalParameters,
    ModalService,
} from "../../../../services/modal.service"
import SearchItem from "@common-lib/classes/searchitem"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { DoublesService } from "@services/doubles.service"
import Patch from "@common-lib/classes/score/patch"
import { PlayerService } from "@services/audio/player/player.service"
import { SearchInstrumentPreviewService } from "@services/searchinstrumentpreview.service"
import { ContextMenu } from "../../../../models/contextmenu"
import { RecommendationsService } from "@services/recommendations.service"
import { Note } from "@common-lib/classes/score/note"
import { Router } from "@angular/router"
import { WindowService } from "@services/window.service"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { InstrumentsDownloadService } from "@services/instruments/instrumentsDownload.service"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"

@Component({
    selector: "instrumentpalette",
    templateUrl: "instrumentpalette.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [],
})
export class InstrumentPaletteComponent implements OnInit {
    @ViewChild("verticalScrollbar", { static: false }) verticalScrollbar

    @Input() parameters:
        | InstrumentSelectionModalParameters
        | DoublingInstrumentSelectionModalParameters

    @Input() data: Array<SearchItem>
    @Input() selectedSearchResult
    @Input() recursionLevel
    @Input() type = "instruments"
    @Input() parentElement = ""
    @Input() createdByAiva

    recommendationTooltip = false

    @Output() valueOutput = new EventEmitter<any>()

    moreOptions = null
    editDoubleMenu = null

    width = 175
    showAdd = false
    options = { autoHide: false }

    isUserGeneratedDouble = false
    allowUserAction = false

    selectedElement: SearchItem = null

    rightClickedSearchItem = null

    dropIsAllowed = false

    isDropTarget = false

    hoveredInstrument = null

    patchesTooltip = null

    constructor(
        public instrumentsDownload: InstrumentsDownloadService,
        private instruments: InstrumentsService,
        private windowService: WindowService,
        protected router: Router,
        protected recommendationService: RecommendationsService,
        protected searchInstrumentPreviewService: SearchInstrumentPreviewService,
        protected playerService: PlayerService,
        protected doublesService: DoublesService,
        protected gpService: GenerationProfileService,
        protected modalService: ModalService,
        protected ref: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.instruments.query.select("refreshSearchUI").subscribe(value => {
            if (value) {
                this.ref.detectChanges()
            }
        })

        this.createdByAiva = this.getCreatedByAiva()

        this.doublesService.refreshDoubleSearchUI.subscribe(value => {
            if (value) {
                this.ref.detectChanges()
            }
        })

        this.doublesService.selectedSearchItem.subscribe(value => {
            if (value != null) {
                this.allowUserAction = this.validateSearchItemAction(value)
            }

            this.ref.detectChanges()
        })

        this.doublesService.draggedSearchItem.subscribe(value => {
            this.dropIsAllowed = this.validateDoubleDrop(value)
        })

        this.doublesService.selectElement.subscribe(value => {
            if (value == null) {
                return
            }

            this.selectElement(
                null,
                value.searchItem.value,
                value.searchItem.index,
                true
            )
        })
    }

    ngAfterViewInit() {
        if (this.data != null) {
            for (var i = 0; i < this.data.length; i++) {
                if (this.selectedElement == this.data[i]) {
                    this.setScroll(i)

                    break
                }
            }
        }
    }

    instrumentIsNotRecommended(instrument: SearchItem) {
        if (
            instrument.recommended == false &&
            this.router.url.includes("/editor")
        ) {
            for (let recommendation in instrument.recommendations) {
                if (recommendation == "range") {
                    continue
                }

                if (instrument.recommendations[recommendation]) {
                    return false
                }
            }
        }

        return instrument.recommended == false
    }

    validateSearchItemAction(searchItem: SearchItem) {
        if (this.recursionLevel == 0 || searchItem == null) {
            return false
        }

        let isAivaTeamMember =
            localStorage.getItem("currentUser") == "pierre@aiva.ai" ||
            localStorage.getItem("currentUser") == "niclas@aiva.ai"

        // TODO: use this when using in production
        // let isAivaTeamMember = localStorage.getItem('currentUser').split('@')[1] == "aiva.ai"

        if (searchItem.createdByAiva && isAivaTeamMember) {
            return true
        } else if (!searchItem.createdByAiva) {
            return true
        } else {
            return false
        }
    }

    getCreatedByAiva() {
        // already set as input variable
        if (this.createdByAiva != null) {
            return this.createdByAiva
        }

        // at root level it could be both
        else if (this.recursionLevel == 0) {
            return
        }

        // overwrite the value for createdByAiva if none is given as an input (this is the case for the root level for example)
        else if (
            this.recursionLevel > 0 &&
            this.createdByAiva == null &&
            this.data != null &&
            this.data.length > 0
        ) {
            return this.data[0].createdByAiva
        }

        // when data is empty and createdByAiva has not been set that means that we cannot possible be in the createdByAiva doubles
        // as there are many folders already added there
        return false
    }

    setValueOutput(event) {
        this.valueOutput.emit({
            element: event.element,
            bulkSelection: event.bulkSelection,
        })
    }

    searchItemIsSelected(searchItem: SearchItem) {
        if (
            this.selectedElement != null &&
            this.selectedElement.createdByAiva == searchItem.createdByAiva &&
            (this.selectedElement.values != null ||
                this.type == "instruments") &&
            this.selectedElement.id == searchItem.id
        ) {
            return true
        }

        return false
    }

    selectElement(
        event,
        instrument,
        index,
        bulkSelection = false,
        emit = true
    ) {
        if (
            this.selectedElement != null &&
            this.selectedElement.id == instrument.id &&
            !bulkSelection
        ) {
            this.selectedElement = null
        } else {
            this.selectedElement = instrument
        }

        if (
            this.selectedElement == null ||
            this.selectedElement.path.includes("Created by AIVA") ||
            this.selectedElement.path.includes("My doubling instruments")
        ) {
            this.doublesService.selectedSearchItem.next(this.selectedElement)
        }

        if (emit) {
            this.valueOutput.emit({
                bulkSelection: bulkSelection,
                element: this.selectedElement,
            })
        }
    }

    getInstrumentTooltip(instrument: SearchItem) {
        if (
            instrument.type == "patch" &&
            this.type == "doubles" &&
            !this.recommendationTooltip
        ) {
            return this.getPatchTooltipContent(instrument)
        }

        return ""
    }

    getPreviewIcon(item: SearchItem) {
        if (
            this.searchInstrumentPreviewService.currentlyPlayingPatchID ==
            item.item.patchID
        ) {
            return "assets/img/player/pause.svg"
        }

        return "assets/img/player/play.svg"
    }

    previewIsLoading() {
        return this.searchInstrumentPreviewService.loading
    }

    setScroll(index) {
        if (this.verticalScrollbar != null) {
            this.verticalScrollbar.SimpleBar.getScrollElement().scrollTop =
                index * 29
        }
    }

    getName(searchItem: SearchItem) {
        const followsAivaNamingConventions =
            searchItem.name.split(" | ")[1] != null &&
            searchItem.name.split(" | ")[1].split(" ") != null // check needed when we are able to add AIVA created doubles

        if (
            this.type == "doubles" &&
            searchItem.type == "patch" &&
            searchItem.item.createdByAiva &&
            followsAivaNamingConventions
        ) {
            let value = searchItem.name.split(" | ")[1].split(" ")

            return "Variation " + value[value.length - 1]
        }

        return searchItem.name
    }

    selectMoreOptions(event) {
        event.stopPropagation()

        this.modalService.contextMenus.addDoubles.next(
            new ContextMenu(
                event.x,
                event.y,
                {
                    addDouble: this.openAddDoubleModal.bind(this),
                    addFolder: this.openAddDoubleFolderModal.bind(this),
                },
                [],
                null
            )
        )
    }

    openAddDoubleFolderModal() {
        const params: EditDoubleFolderModalParameters = {
            folder: undefined,
            layer: this.parameters.layer,
            onClose: this.reopenModal.bind(this),
        }

        this.modalService.modals.editDoubleFolder.next(params)

        this.closeModalWithoutFiringEmitter()
    }

    openAddDoubleModal() {
        const params: EditDoubleModalParameters = {
            double: undefined,
            layer: this.parameters.layer,
            pack: this.parameters.pack,
            folderID: this.selectedSearchResult.item.id,
            allowRecommendations: this.parameters.allowRecommendations,
            onClose: this.reopenModal.bind(this),
        }

        this.modalService.modals.editDouble.next(params)

        this.closeModalWithoutFiringEmitter()
    }

    reopenModal() {
        if (this.type === "instruments") {
            this.modalService.modals.instrumentSelection.next(
                this.parameters as InstrumentSelectionModalParameters
            )
        } else {
            this.modalService.modals.doublingInstrumentSelection.next(
                this.parameters as DoublingInstrumentSelectionModalParameters
            )
        }
    }

    closeModalWithoutFiringEmitter() {
        if (this.type === "instruments") {
            this.modalService.modals.instrumentSelection.next(undefined)
        } else {
            this.modalService.modals.doublingInstrumentSelection.next(undefined)
        }
    }

    private closeModalAndFireEmitter() {
        this.closeModalWithoutFiringEmitter()
        
        this.parameters.onClose()
    }

    playPreview(event, searchItem) {
        let downloadPatchesFor =
            this.type == "doubles" ? searchItem : this.selectedSearchResult

        this.searchInstrumentPreviewService.playPreview(
            downloadPatchesFor,
            event,
            searchItem
        )
    }

    selectEditDouble(event, searchItem: SearchItem, index) {
        if (
            event.button != 2 ||
            this.recursionLevel == 0 ||
            this.type == "instruments"
        ) {
            return
        }

        this.rightClickedSearchItem = {
            value: searchItem,
            index: index,
        }

        event.stopPropagation()

        this.modalService.contextMenus.doublesSelection.next(
            new ContextMenu(
                event.x,
                event.y,
                {
                    rightClickedSearchItem: this.rightClickedSearchItem,
                    searchItems: this.data,
                    layer: this.parameters.layer,
                    recursionLevel: this.recursionLevel,
                    allowUserAction: this.allowUserAction,
                    updateDoubleFolder: this.updateDoubleFolder.bind(this),
                    updateDouble: this.updateDouble.bind(this),
                    selectAllDoubles: this.selectAllDoubles.bind(this),
                },
                [],
                null
            )
        )

        this.ref.detectChanges()
    }

    private selectAllDoubles(doubles: InstrumentPatch[]) {
        ;(
            this.parameters as DoublingInstrumentSelectionModalParameters
        ).selectNewInstrumentPatches(doubles)

        this.closeModalAndFireEmitter()
    }

    private updateDoubleFolder(folder: SearchItem) {
        const params: EditDoubleFolderModalParameters = {
            folder,
            layer: this.parameters.layer,
            onClose: this.reopenModal.bind(this),
        }

        this.modalService.modals.editDoubleFolder.next(params)
    }

    private updateDouble(double: InstrumentPatch) {
        const params: EditDoubleModalParameters = {
            double,
            layer: this.parameters.layer,
            pack: this.parameters.pack,
            folderID: this.selectedSearchResult.item.id,
            allowRecommendations: this.parameters.allowRecommendations,
            onClose: this.reopenModal.bind(this),
        }

        this.modalService.modals.editDouble.next(params)
    }

    startDragDouble(event, searchItem: SearchItem) {
        if (this.recursionLevel == 0 || this.type == "instruments") {
            return
        }

        searchItem["selectedElementID"] =
            this.selectedElement != null ? this.selectedElement.id : null

        this.doublesService.draggedSearchItem.next(searchItem)

        event.stopPropagation()

        this.ref.detectChanges()
    }

    onDropDouble(event) {
        event.preventDefault()

        const draggedSearchItem =
            this.doublesService.draggedSearchItem.getValue()

        if (
            this.dropIsAllowed != true ||
            draggedSearchItem == null ||
            this.validateSearchItemAction(draggedSearchItem) == false ||
            (draggedSearchItem.item != null &&
                draggedSearchItem.item.parentID == this.parentElement)
        ) {
            return
        }

        this.doublesService.moveDoubleContentToFolder(
            draggedSearchItem,
            this.parentElement,
            this.parameters.layer as GPLayer,
            this.parameters.pack,
            this.parameters.allowRecommendations
        )

        this.unselectDragDouble()
    }

    onDragOver(event) {
        if (this.dropIsAllowed) {
            event.preventDefault()
        }
    }

    validateDoubleDrop(searchItem: SearchItem) {
        if (
            this.recursionLevel == 0 ||
            searchItem == null ||
            this.validateSearchItemAction(searchItem) == false
        ) {
            return false
        }

        let draggedItemRecursionLevel =
            this.doublesService.getPathAsIDs(searchItem).length - 1

        // from lower level to higher level
        if (draggedItemRecursionLevel >= this.recursionLevel) {
            return true
        } else {
            // don't allow an item to be dragged into its own child
            const parentElement = this.doublesService.findInDoublesSearchItems(
                this.parentElement,
                this.doublesService.doublesSearchItems.find(
                    i => i.createdByAiva == searchItem.createdByAiva
                ).values,
                searchItem.createdByAiva == true
            )

            if (parentElement) {
                const parentPathAsIDs = this.doublesService
                    .getPathAsIDs(parentElement)
                    .slice()
                    .reverse()

                if (parentPathAsIDs.includes(searchItem.id)) {
                    return false
                }
            }

            if (searchItem.id != this.parentElement) {
                return true
            }
        }

        return false
    }

    unselectDragDouble() {
        this.doublesService.draggedSearchItem.next(null)
    }

    ngOnChanges(changes: SimpleChanges) {
        for (var key in changes) {
            if (
                key == "selectedSearchResult" &&
                changes[key].currentValue != null
            ) {
                let selectedSearchResult: SearchItem = changes[key].currentValue
                var values = selectedSearchResult.path.split("/")
                let isDoubleSearch = false

                // is no folder?
                if (changes[key].currentValue.values == null) {
                    // here the own name is added because it is missing in the path
                    values.push(selectedSearchResult.name)
                }

                if (
                    values[0] == "Created by AIVA" ||
                    values[0] == "My doubling instruments"
                ) {
                    isDoubleSearch = true
                }

                this.selectedSearchResult = selectedSearchResult

                // path reflects current recursion level? -> set the selectedElement according to the path on this level
                if (values.length - 1 >= this.recursionLevel) {
                    for (var i = 0; i < this.data.length; i++) {
                        var path: any = this.data[i].path

                        if (path[path.length - 1] == "") {
                            path = path.slice(path, 1)
                        }

                        path = path.split("/")

                        if (this.data[i].values == null) {
                            path.push(this.data[i].name)
                        }

                        if (isDoubleSearch) {
                            path = this.doublesService
                                .getPathAsIDs(this.data[i])
                                .slice()
                                .reverse()
                            values = this.doublesService
                                .getPathAsIDs(selectedSearchResult)
                                .slice()
                                .reverse()
                        }

                        var broke = true

                        for (var p = 0; p < this.recursionLevel + 1; p++) {
                            if (path[p] != values[p]) {
                                broke = true

                                break
                            } else if (path[p] == values[p]) {
                                broke = false
                            }
                        }

                        if (!broke) {
                            if (isDoubleSearch && this.data[i].id == "") {
                                this.selectedElement =
                                    this.doublesService.doublesSearchItems.find(
                                        i =>
                                            i.createdByAiva ==
                                            selectedSearchResult.createdByAiva
                                    )
                            } else {
                                this.selectedElement = this.data[i]
                            }

                            this.ref.detectChanges()

                            return
                        }
                    }
                }

                // if the selectedSearchResult is not on the same level as the current recursion level, set selectedElement for this instrument palette to null
                this.selectedElement = null
                this.ref.detectChanges()
            }

            if (key == "data") {
                this.data = changes[key].currentValue
                this.ref.detectChanges()
            }
        }
    }

    onMouseEnter() {
        this.showAdd = true
    }

    onMouseLeave() {
        this.showAdd = false
    }

    updateHoveredInstrument(event, instrument: SearchItem, hovered) {
        this.hoveredInstrument = instrument

        if (hovered == false) {
            this.hoveredInstrument = null
        }

        this.updateDoublePatchesTooltip(event)
    }

    getRecommendationExplanation(instrument: SearchItem) {
        let text = "Not recommended:<br>"

        for (let recommendation in instrument.recommendations) {
            if (instrument.recommendations[recommendation]) {
                continue
            }

            if (recommendation == "patch" && instrument.item.patches != null) {
                let result =
                    this.recommendationService.patchCompatbilityWithPack(
                        instrument.item.patches[0].patch,
                        this.parameters.pack
                    )

                if (!result.isCompatible) {
                    text +=
                        "<br><li>This patch is not compatible with the selected pack's rhythms</li>"
                }

                if (!result.slurRecommendation) {
                    text +=
                        "<br><li>We recommend using a Legato patch over Sustain whenever possible</li>"
                }
            }

            if (recommendation == "percussionFunction") {
                text +=
                    "<br><li>This patch is not compatible with the selected pack's percussion function</li>"
            }

            let lowestInstrumentPitch = instrument.pitchRange[0]
            let highestInstrumentPitch = instrument.pitchRange[1]

            if (recommendation == "range") {
                let lowestPackPitch: any = this.parameters.pack.lowestNote
                let highestPackPitch: any =
                    lowestPackPitch + 12 * this.parameters.pack.octaves

                let packRangeTooWide =
                    highestPackPitch - lowestPackPitch >
                    highestInstrumentPitch - lowestInstrumentPitch
                let recommendedPackRangeStart: any = 0

                if (!packRangeTooWide) {
                    if (lowestPackPitch < lowestInstrumentPitch) {
                        recommendedPackRangeStart = lowestInstrumentPitch
                    } else {
                        recommendedPackRangeStart =
                            highestInstrumentPitch -
                            12 * this.parameters.pack.octaves
                    }
                }

                lowestPackPitch = Note.getNoteString(lowestPackPitch)
                highestPackPitch = Note.getNoteString(highestPackPitch)

                lowestInstrumentPitch = Note.getNoteString(
                    lowestInstrumentPitch
                )
                highestInstrumentPitch = Note.getNoteString(
                    highestInstrumentPitch
                )

                if (packRangeTooWide) {
                    text +=
                        "<br><li>The selected pack's pitch range (" +
                        lowestPackPitch +
                        " - " +
                        highestPackPitch +
                        ") is too wide for this instrument</li>"
                } else if (instrument.type != "patch") {
                    text +=
                        "<br><li>The selected pack's pitch range (" +
                        lowestPackPitch +
                        " - " +
                        highestPackPitch +
                        ") is out of bounds for the range of all patches in this folder."
                } else {
                    text +=
                        "<br><li>The selected pack's pitch range (" +
                        lowestPackPitch +
                        " - " +
                        highestPackPitch +
                        ") is out of bounds for this instrument's pitch range (" +
                        lowestInstrumentPitch +
                        " - " +
                        highestInstrumentPitch +
                        ")."

                    let suggestedLowestNoteInBassBounds =
                        this.parameters.layer.name == "Bass" &&
                        recommendedPackRangeStart >=
                            this.recommendationService.bassRange.min &&
                        recommendedPackRangeStart <=
                            this.recommendationService.bassRange.max
                    let suggestLowestNoteInAccompanimentBounds =
                        this.parameters.layer.name != "Bass" &&
                        recommendedPackRangeStart >=
                            this.recommendationService.accompanimentRange.min &&
                        recommendedPackRangeStart <=
                            this.recommendationService.accompanimentRange.max

                    if (
                        suggestedLowestNoteInBassBounds ||
                        suggestLowestNoteInAccompanimentBounds
                    ) {
                        recommendedPackRangeStart = Note.getNoteString(
                            recommendedPackRangeStart
                        )

                        text +=
                            " For best results, set the pack's range to start at " +
                            recommendedPackRangeStart +
                            " before selecting this instrument.</li>"
                    }
                }
            }
        }

        return text
    }

    getPatchTooltipContent(item: SearchItem) {
        let content = ""

        for (let patch of item.item.patches) {
            if (content != "") {
                content + "<br>"
            }

            content +=
                this.instruments.getPatch(patch.patch.getFullName()).name +
                " | Octave: " +
                patch.octave +
                "<br>"
        }

        return content
    }

    updateDoublePatchesTooltip(event) {
        this.patchesTooltip = null

        if (
            this.type != "doubles" ||
            this.hoveredInstrument == null ||
            this.hoveredInstrument.item == null ||
            Object.keys(this.hoveredInstrument.item).length == 0 ||
            this.hoveredInstrument.item.patches == null
        ) {
            return
        }

        let doublePatches = this.hoveredInstrument.item.patches
        let patches = []

        for (let dp of doublePatches) {
            const patch: Patch = dp.patch
            const octave = dp.octave
            const instrument = this.instruments.instruments[patch.section].find(
                i => i.name == patch.instrument
            )
            const instrumentPatch = instrument.patches.find(
                ip =>
                    ip.playing_style == patch.playing_style &&
                    ip.articulation == patch.articulation
            )

            let instrumentName = instrument.full_name
            let octavePrefix = ""

            if (octave > 0) {
                octavePrefix = "+"
            }

            let octaveString = "Octave " + octavePrefix + " " + octave
            let patchString =
                instrumentName +
                " - " +
                instrumentPatch.name +
                " - " +
                octaveString

            patches.push(patchString)
        }

        this.patchesTooltip = { patches: patches, x: event.x, y: event.y + 20 }

        this.ref.detectChanges()
    }

    isDesktopApp() {
        return this.windowService.desktopAppAPI !== undefined
    }

    allowPreview(instrument) {
        return (
            this.isDesktopApp() &&
            instrument.values == null &&
            instrument.item != null &&
            instrument.item.type == "pitched"
        )
    }

    isDouble(instrument) {
        return (
            this.type == "doubles" &&
            instrument.values == null &&
            instrument.item != null
        )
    }
}
