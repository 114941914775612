import { Injectable } from "@angular/core"
import { ParentClass } from "../../parent"
import { WindowService } from "@services/window.service"
import { InstrumentsService } from "./instruments.service"
import { UserService } from "@services/user.service"
import { TokenService } from "@services/token.service"
import Patch from "@common-lib/classes/score/patch"
import { ModalService } from "@services/modal.service"
import { InstrumentsHttpService } from "./instruments.http"

@Injectable()
export class InstrumentsDownloadService extends ParentClass {
    constructor(
        private instruments: InstrumentsService,
        private windowService: WindowService,
        private userService: UserService,
        private tokenService: TokenService,
        private modalService: ModalService,
        private http: InstrumentsHttpService
    ) {
        super()
    }

    public async downloadAllInstruments() {
        try {
            localStorage.setItem("resumeDownloadAllInstruments", "true")

            this.instruments.setIsDownloadingAllInstruments(true)

            let instrumentPatches = []

            for (let searchItem of this.instruments
                .instrumentsOrganizedByPath) {
                instrumentPatches = instrumentPatches.concat(
                    this.instruments.getAllPatches(searchItem)
                )
            }

            for (let searchItem of this.instruments.percussionOrganizedByPath) {
                instrumentPatches = instrumentPatches.concat(
                    this.instruments.getAllPatches(searchItem)
                )
            }

            let uniqueInstrumentPatches = []

            for (let patch of instrumentPatches) {
                if (
                    uniqueInstrumentPatches.findIndex(
                        i =>
                            i.section == patch.section &&
                            i.instrument == patch.instrument &&
                            i.playing_style == patch.playing_style &&
                            i.articulation == patch.articulation &&
                            i.granulationEngine == patch.granulationEngine
                    ) == -1
                ) {
                    if (
                        !patch.instrument.includes("silent") &&
                        patch.instrument != "dry-electric-cello1"
                    ) {
                        uniqueInstrumentPatches.push(patch)
                    }
                }
            }

            this.instruments.setDownloadAllStatus({
                completed: 0,
                total: uniqueInstrumentPatches.length,
            })

            let completed = 0

            for (const patch of uniqueInstrumentPatches) {
                await this.windowService.samplerAPI.requestPatch({
                    usedInstruments: uniqueInstrumentPatches,
                    patch: patch,
                })

                completed += 1

                this.instruments.setDownloadAllStatus({
                    completed: completed,
                    total: uniqueInstrumentPatches.length,
                })

                this.instruments.setRefreshSearchUI()
            }

            this.instruments.setAllInstrumentsAreDownloaded(true)

            localStorage.setItem("resumeDownloadAllInstruments", "false")

            this.instruments.setRefreshSearchUI()
        } catch (error) {
            console.error(error)
        }

        this.instruments.setIsDownloadingAllInstruments(false)
    }

    public async getAdditionalSpaceForAllInstrumentDownload() {
        if (this.windowService.desktopAppAPI === undefined) {
            return
        }

        const downloadedInstrumentCTs =
            await this.windowService.samplerAPI.getDownloadedInstrumentCompiledTechniques()
        const rtInstrumentCTs = await this.http.getRTInstrumentBucketFiles()

        let alreadyDownloaded = 0
        let totalSize = rtInstrumentCTs["size"]

        delete rtInstrumentCTs["size"]

        for (let instrument in downloadedInstrumentCTs) {
            const downloadedCTs = downloadedInstrumentCTs[instrument]
            const instrumentCTs = rtInstrumentCTs[instrument]?.patches
                ? rtInstrumentCTs[instrument].patches
                : []

            for (let ct of instrumentCTs) {
                const compiledTechnique =
                    ct.patch.playing_style + "_" + ct.patch.articulation

                if (downloadedCTs.includes(compiledTechnique)) {
                    alreadyDownloaded += ct.size
                }
            }
        }

        const additionalSpaceNecessary =
            Math.round(
                (totalSize - alreadyDownloaded + Math.pow(1000, 3)) * 100
            ) / 100
        const diskStats =
            await this.windowService.generalAPI.getDiskStatistics()

        return {
            alreadyDownloaded,
            totalSize,
            additionalSpaceNecessary,
            diskStats,
        }
    }

    public async checkIfAllInstrumentsAreDownloaded() {
        if (this.windowService.desktopAppAPI === undefined) {
            this.instruments.setAllInstrumentsAreDownloaded(true)

            return
        }

        try {
            this.instruments.setAllInstrumentsAreDownloaded(
                await this.windowService.samplerAPI.allInstrumentsAreDownloaded()
            )
        } catch (error) {
            this.instruments.setAllInstrumentsAreDownloaded(false)
        }
    }
}
