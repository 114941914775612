<link
    href="https://fonts.googleapis.com/css2?family=Inconsolata&display=swap"
    rel="stylesheet" />

<div class="json-uploader-container">
    <div class="json-uploader">
        <ngx-file-drop
            [showBrowseBtn]="true"
            dropZoneLabel="Drop files here"
            (onFileDrop)="dropped($event)">
            <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector">
                <div (click)="openFileSelector()" class="text-effect-container">
                    <img src="assets/img/menu/influences.svg" />
                </div>
            </ng-template>
        </ngx-file-drop>

        <div class="text-effect-container">
            <div class="text-effect"></div>
        </div>

        <div class="loader json-uploader-loader" *ngIf="loading"></div>

        <div class="json-uploader-read-me">
            Some features may not work correctly with imported JSON
            compositions:
            <ul>
                <li>Downloading Reduced MIDI</li>
                <li>Regeneration</li>
            </ul>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">Refresh GP Packs</div>
            <div class="settings-row-value">
                <button class="admin-button" (click)="refreshGPSourcePacks()">
                    Refresh
                </button>
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">Upload DPT pack</div>
            <div class="settings-row-value">
                <div class="loader" *ngIf="dptPackLoading"></div>
                <input
                    *ngIf="!dptPackLoading"
                    (change)="importDPTPack($event.target.files)"
                    type="file"
                    accept="application/json" />
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">GPs</div>
            <div class="settings-row-value">
                <button class="admin-button" (click)="compareTwoGPs()">
                    Compare two GPs
                </button>
                <button class="admin-button" (click)="resetLibrary()">
                    Reset GP library cache
                </button>
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">Clear staging pools</div>
            <div class="settings-row-value">
                <input
                    [(ngModel)]="versionNumberToClear"
                    placeholder="Version number" /><button
                    (click)="clearStagingQueues(versionNumberToClear)">
                    Clear
                </button>
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">Reset download quota</div>
            <div class="settings-row-value">
                <button class="admin-button" (click)="resetDownloadQuota()">
                    Reset
                </button>
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">Instrument tests</div>
            <div class="settings-row-value">
                <input
                    [(ngModel)]="instrumentToTest"
                    placeholder="Name of instrument to test" /><button
                    (click)="generateInstrumentTests()">
                    Create
                </button>
            </div>
        </div>
    </div>

    <div class="test-report"></div>

    <div class="test-report"><br /><br /></div>
</div>
