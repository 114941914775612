import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    Input,
    ViewChild,
} from "@angular/core"
import Patch from "@common-lib/classes/score/patch"
import { DoublesService } from "@services/doubles.service"
import {
    DoublingInstrumentSelectionModalParameters,
    EditDoubleModalParameters,
    InstrumentSelectionModalParameters,
    ModalService,
} from "@services/modal.service"
import { InstrumentsService } from "@services/instruments/instruments.service"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"

@Component({
    selector: "edit-double",
    templateUrl: "./edit-double.component.html",
    styleUrls: ["edit-double.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDoubleModalComponent implements OnInit {
    @Input() parameters: EditDoubleModalParameters

    @Output() close = new EventEmitter<any>()

    scrollChange = { useBothWheelAxes: false, suppressScrollX: true }

    addDouble = false

    constructor(
        private modalService: ModalService,
        private instruments: InstrumentsService,
        private doublesService: DoublesService
    ) {
       
    }

    ngOnInit() {
        if (this.parameters.double === undefined) {
            this.addDouble = true
            
            this.parameters.double = InstrumentPatch.createNewPatch({
                type: this.parameters.layer.type as "pitched" | "percussion",
                folderID: this.parameters.folderID
            })
        }
    }

    ngAfterViewInit() {
        
    }

    verifyOctave(patchEntry) {
        if (patchEntry.octave < -3) {
            patchEntry.octave = -3
        }

        if (patchEntry.octave > 3) {
            patchEntry.octave = 3
        }
    }

    changeOctave(patchEntry, increment) {
        patchEntry.octave += increment

        this.verifyOctave(patchEntry)
    }

    getInstrumentNameForPatch(patch: Patch): string {
        let instruments = this.instruments.instruments[patch.section]

        for (let instrument of instruments) {
            if (instrument.name != patch.instrument) {
                continue
            }

            for (let p of instrument.patches) {
                if (
                    p.playing_style != patch.playing_style ||
                    p.articulation != patch.articulation
                ) {
                    continue
                }

                if (patch.section == "p") {
                    return instrument.full_name
                } else {
                    return instrument.full_name + " | " + p.name
                }
            }
        }

        return ""
    }

    async updateDouble() {
        this.parameters.double = await this.doublesService.updateDouble(
            this.parameters.double
        )

        this.doublesService.triggerDoublesSearchRendering({
            layer: this.parameters.layer,
            pack: this.parameters.pack,
            allowRecommendations: this.parameters.allowRecommendations,
        })

        this.closeModal()
    }

    deletePatchFromPack(index) {
        this.parameters.double.patches.splice(index, 1)
    }

    addPatch() {
        this.closeWithoutEventEmitter()

        const params: InstrumentSelectionModalParameters = {
            layer: this.parameters.layer,
            allowRecommendations: this.parameters.allowRecommendations,
            pack: this.parameters.pack,
            selectedTrackBus: undefined,

            selectNewInstrumentPatch: (patch: InstrumentPatch) => {
                this.parameters.double.addPatchFromInstrumentPatch(patch)

                return true
            },

            onClose: (() => {
                this.modalService.modals.editDouble.next(this.parameters)
            }).bind(this),
        }

        this.modalService.modals.instrumentSelection.next(params)
    }

    private closeWithoutEventEmitter() {
        this.modalService.modals.editDouble.next(undefined)
    }

    closeModal() {
        this.close.emit(2)

        this.parameters.onClose()
    }
}
