import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import { CompositionWorkflowService } from "@services/composition-workflow/composition-workflow.service"
import { CWActionsType } from "@services/composition-workflow/cw-store/cw.actions"
import { DropdownSelection } from "../../../types/dropdownItemType"
import { ParentClass } from "../../../parent"
import { CWStateStep3 } from "@common-lib/interfaces/composition-workflow.interface"
import { API_ERRORS } from "@common-lib/constants/errors"
import {
    GP_DURATION_OPTIONS_DROPDOWN,
    NUMBER_OF_COMPOSITIONS_OPTIONS,
} from "@common-lib/constants/constants"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { TutorialService } from "@services/tutorial.service"

@Component({
    selector: "composition-workflow-step-3",
    templateUrl: "./composition-workflow-step-3.component.html",
    styleUrls: ["./composition-workflow-step-3.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositionWorkflowStep3Component extends ParentClass {
    @Input() service: CompositionWorkflowService

    public workflowName: string = ""

    public get type() {
        return this.service.query.getValue().type
    }

    constructor(
        private ref: ChangeDetectorRef,
        private tutorialService: TutorialService
    ) {
        super()
    }

    ngAfterViewInit() {
        if (this.type === "chordProgression") {
            this.service.setAllowInstrumentationChanges(true)
        }

        this.subscribe(
            this.service.query.select("step3"),
            (step3: CWStateStep3) => {
                this.ref.detectChanges()
            }
        )

        this.subscribe(this.tutorialService.initTutorial$, val => {
            if (val) {
                this.tutorialService.initCWStep3Tutorial(true)
            }
        })
    }

    public getSelectedNbOfCompositions() {
        return {
            value: this.service.step3().nbOfCompositions,
            name: this.service.step3().nbOfCompositions,
        }
    }

    public getSelectedDuration() {
        return {
            name: Misc.capitalizeFirstLetter(this.service.step3().duration),
            value: this.service.step3().duration,
        }
    }

    public getNbOfCompositionsOptions() {
        return NUMBER_OF_COMPOSITIONS_OPTIONS
    }

    public setNbOfCompositions(event: DropdownSelection<number>) {
        this.service.actions.emitter$.next({
            type: CWActionsType.setNbOfCompositions,
            data: {
                nbOfCompositions: event.new.value,
            },
        })
    }

    public setDuration(event: DropdownSelection<string>) {
        this.service.actions.emitter$.next({
            type: CWActionsType.setDuration,
            data: {
                duration: event.new.value,
            },
        })
    }

    public changeName(name: string) {
        this.service.actions.emitter$.next({
            type: CWActionsType.setWorkflowName,
            data: {
                name,
            },
        })
    }

    public workflowNameIsInvalid() {
        return (
            this.service.step3().loading.error ===
            API_ERRORS.invalidCWName.message
        )
    }

    public getFeatureFlags() {
        return featureFlags
    }
}
