import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    Input,
    ChangeDetectorRef,
    ElementRef,
    ViewChild,
} from "@angular/core"
import { Router } from "@angular/router"
import { Misc } from "@common-lib/modules/misc"
import { AccompanimentDesignerHttpService } from "@services/accompaniment-designer/accompaniment-designer.http"
import { ApiService } from "@services/api.service"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import {
    CreateTemplateAccompanimentPack,
    ModalService,
} from "@services/modal.service"
import { cloneDeep } from "lodash"

@Component({
    selector: "create-template-accompaniment-pack",
    templateUrl: "create-template-accompaniment-pack.modal.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["create-template-accompaniment-pack.modal.scss"],
})
export class CreateTemplateAccompanimentPackModal {
    @Input() parameters: CreateTemplateAccompanimentPack
    @Output() close: EventEmitter<any> = new EventEmitter()

    @ViewChild("packName", { static: false }) packName: ElementRef

    public createTemplateLoading: boolean = false
    public error:undefined | string = undefined
    public step: 1 | 2 = 1

    constructor(
        private api: ApiService,
        private router: Router,
        private modalService: ModalService,
        private gpService: GenerationProfileService,
        private ref: ChangeDetectorRef,
        private httpService: AccompanimentDesignerHttpService
    ) {
        if (this.parameters !== undefined) {
            this.parameters.sourcePack = cloneDeep(this.parameters.sourcePack)
        }
    }
    
    ngAfterViewInit() {
        this.packName.nativeElement.focus()
    }

    public async createTemplate() {
        this.createTemplateLoading = true
        this.error = undefined

        try {
            await this.httpService.createTemplateAccompanimentPack(
                this.parameters.sourcePack.packID,
                this.parameters.sourcePack.name
            )

            this.step = 2
        } catch (e) {
            this.error = e.message
        }

        this.createTemplateLoading = false

        this.ref.detectChanges()
    }

    public async goToTemplateLibrary() {
        await this.parameters.preCompletion()

        console.log("navigate")
        this.router.navigate([
            "/generation-profile-editor",
            this.parameters.generationProfileID,
        ])

        let counter = 30

        while (counter > 0) {
            if (this.gpService.generationProfile !== undefined) {
                let layer

                if (this.parameters.sourcePack.type === "Bass") {
                    layer =
                        this.gpService.generationProfile.accompanimentLayers.find(
                            l => l.name === "Bass"
                        )
                } else if (this.parameters?.layer) {
                    layer =
                        this.gpService.generationProfile.accompanimentLayers.find(
                            l => l.name === this.parameters.layer
                        )
                }

                if (layer !== undefined) {
                    this.modalService.modals.selectPack.next({
                        type: "add",
                        layer: layer,
                        openMenu: "currentUser",
                    })

                    break
                }
            }

            await Misc.wait(0.5)

            counter--
        }

        this.close.emit()
    }
}
