import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import Layer from "@common-lib/classes/score/layer"
import { HelperService } from "@helpers/helper.service"
import { ModalService } from "@services/modal.service"

@Component({
    selector: "add-instruments",
    templateUrl: "add-instruments.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["../context-menus.component.scss"],
})
export class AddInstrumentsComponent implements OnInit {
    @Input() data: {
        layer: GPLayer
        addInstrumentToPack: Function
        addDoublesToPack: Function
        findSimilarInstrumentPatches: Function
    }

    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor() {}

    ngOnInit(): void {}

    closeContextMenu() {
        this.close.emit()
    }

    addInstrumentToPack() {
        this.closeContextMenu()

        this.data.addInstrumentToPack()
    }

    addDoublesToPack() {
        this.closeContextMenu()

        this.data.addDoublesToPack()
    }

    findSimilarInstrumentPatches() {
        this.closeContextMenu()

        this.data.findSimilarInstrumentPatches()
    }

    showFindSimilarInstruments() {
        const isPitchLayer = this.data?.layer?.type != "percussion"

        const hasInstruments =
            this.data?.layer?.packs[0]?.instruments.length > 0

        return isPitchLayer && hasInstruments
    }
}
