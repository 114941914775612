import { ChangeDetectionStrategy, Component } from "@angular/core"
import { ApiService } from "../../services/api.service"
import { PlayerService } from "../../services/audio/player/player.service"
import { CompositionService } from "../../services/composition.service"
import { FolderService } from "../../services/folder.service"
import { InfluenceService } from "../../services/influence.service"
import { ModalService } from "../../services/modal.service"
import { ActivatedRoute, Router } from "@angular/router"
import { ActivityMetric } from "../../../../../common-lib/general/classes/activitymetric"
import { AnalyticsService } from "../../services/analytics.service"
import { TracksService } from "../../services/tracks.service"
import { PlaylistService } from "../../services/playlist.service"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { UserService } from "@services/user.service"
import { playerQuery } from "../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { ParentClass } from "../../parent"
import { UIContentType } from "@common-lib/types/general"
import {
    MenuOption,
    MenuOptions,
} from "../reusable/menu-options/menu-options.component"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { Helpers } from "../../modules/helpers.module"
import { DeviceDetectorService } from "ngx-device-detector"
import { TutorialService } from "@services/tutorial.service"

@Component({
    selector: "app-mytracks",
    templateUrl: "mytracks.component.html",
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyTracksComponent extends ParentClass {
    path = []

    title: UIContentType = "Compositions"
    trackView = "trackslist"
    playlist

    createRightClickMenu

    createMenuOptions: MenuOptions<any> | undefined
    createTrackOptions: MenuOptions<any> | undefined

    firstCompositionUpdate = true

    constructor(
        public influenceService: InfluenceService,
        protected userService: UserService,
        protected router: Router,
        protected gpService: GenerationProfileService,
        protected analyticsService: AnalyticsService,
        protected apiService: ApiService,
        protected route: ActivatedRoute,
        protected modalService: ModalService,
        protected folderService: FolderService,
        protected compositionService: CompositionService,
        protected playerService: PlayerService,
        private tracksService: TracksService,
        private playlistService: PlaylistService,
        private device: DeviceDetectorService,
        private tutorialService: TutorialService
    ) {
        super()
    }

    ngAfterViewInit() {
        this.initListeners()
    }

    createGenerationProfile() {
        this.gpService.openCreateGenerationProfile()
    }

    createInfluence() {
        this.router.navigate(["/create", "Upload influence"])
    }

    goToMyTracks() {
        this.folderService.changeSelectedFolder("")
    }

    goToFolder(folderID) {
        this.folderService.openFolder(folderID)
    }

    public createTrackMenuOptions() {
        const data = [
            {
                data: undefined,
                icon: undefined,
                loading: false,
                text: "From a Style",
                onClick: (async () => {
                    await this.router.navigate(["/create", "Styles"])

                    this.unselectMenu()
                }).bind(this),
            },
            {
                data: undefined,
                icon: undefined,
                loading: false,
                text: "From a Chord Progression",
                onClick: (async () => {
                    await this.router.navigate(["/create", "Chord progression"])

                    await this.unselectMenu()
                }).bind(this),
            },
        ]

        data.push({
            data: undefined,
            icon: undefined,
            loading: false,
            text: "Step by Step",
            onClick: (async () => {
                await this.router.navigate(["/create", "Step by step"])

                this.unselectMenu()
            }).bind(this),
        })

        data.push({
            data: undefined,
            icon: undefined,
            loading: false,
            text: "From an Influence",
            onClick: (async () => {
                await this.router.navigate(["/create", "Upload influence"])

                this.unselectMenu()
            }).bind(this),
        })

        if (featureFlags.enableMidiUpload) {
            data.push({
                data: undefined,
                icon: undefined,
                loading: false,
                text: "Import MIDI",
                onClick: (async () => {
                    await this.router.navigate(["/create", "Import MIDI"])

                    this.unselectMenu()
                }).bind(this),
            })
        }

        if (Helpers.showPreset(this.userService.accountCreationDate)) {
            data.push({
                data: "test",
                icon: undefined,
                text: "Presets (legacy)",
                loading: false,
                onClick: (async (data: string) => {
                    await this.router.navigate(["/create", "Presets"])
                }).bind(this),
            })
        }

        return data
    }

    public openCreateMenu(event: MouseEvent) {
        const options: MenuOption<any>[] = []

        if (this.title === "Compositions") {
            const data = this.createTrackMenuOptions()

            options.push({
                data,
                icon: undefined,
                loading: false,
                subtitle: "Composition",
                text: "Composition",
                onClick: (() => {}).bind(this),
            })
        } else if (this.title === "Styles") {
            options.push({
                data: undefined,
                icon: undefined,
                loading: false,
                text: "Styles",
                onClick: (() => {
                    this.createGenerationProfile()

                    this.unselectMenu()
                }).bind(this),
            })
        } else if (this.title === "Influences") {
            options.push({
                data: undefined,
                icon: undefined,
                loading: false,
                text: "Influences",
                onClick: (() => {
                    this.createInfluence()

                    this.unselectMenu()
                }).bind(this),
            })
        }

        options.push({
            data: undefined,
            icon: undefined,
            loading: false,
            text: "Folder",
            onClick: (() => {
                this.createNewFolder()

                this.unselectMenu()
            }).bind(this),
        })

        this.createMenuOptions = {
            title: "Create",
            options,
            coordinates: {
                x: event.x,
                y: event.y,
            },
        }

        if (this.tutorialService.isTutorialInProgress$.value) {
            this.tutorialService.tutorialNext$.next(true)
        }
    }

    public openCreateTrackMenu(event: MouseEvent) {
        if (this.device.isMobile()) {
            return this.router.navigate(["/create", "Styles"])
        }

        const data: MenuOption<any>[] = this.createTrackMenuOptions()

        this.createTrackOptions = {
            title: "Create",
            options: data,
            coordinates: {
                x: event.x,
                y: event.y,
            },
        }
    }

    public unselectMenu() {
        if (this.createMenuOptions !== undefined) {
            this.createMenuOptions = undefined
        }

        if (this.createTrackOptions !== undefined) {
            this.createTrackOptions = undefined
        }
    }

    openGPHub() {
        this.userService.createTrackMenu.next("Styles")

        this.router.navigate(["/create"])
    }

    getMenuOptions() {
        return [
            { value: "Compositions", name: "Compositions" },
            { value: "Styles", name: "Styles" },
            { value: "Influences", name: "Influences" },
        ]
    }

    async setTab(type) {
        if (type == null) {
            return
        }

        this.title = type

        await this.folderService.setContentType(
            "setContentType - mytracks.component",
            type,
            "",
            false
        )

        if (type === "Influences") {
            this.setTrackView("trackslist", false)
        }

        if (type === "Compositions") {
            this.setTrackView("trackslist", false)
        }

        if (type === "Styles") {
            this.setTrackView("trackslist", false)
        }

        //this.goToFolder(this.folderService.selectedFolderID.getValue())
    }

    closeCreateWithInfluenceMode() {
        this.influenceService.createWithInfluenceMode = false
    }

    onTrackViewChange($event) {
        this.trackView = $event
    }

    getPlaylistBackgroundImage(playlist) {
        return this.playlistService.getBackgroundImage(playlist)
    }

    setTrackView(viewName, force) {
        this.tracksService.setTrackView(viewName)

        if (force) {
            this.folderService.fetchFolderContent("Compositions", "", true)
        }
    }

    togglePlaylistPlay(playlistID) {
        if (!playlistID || !this.playlist) {
            return
        }

        if (playerQuery.content === undefined) {
            let composition = this.playlist["compositions"][0]
            return this.playerService.loadNewTrack(
                composition._id,
                "composition",
                true,
                false
            )
        }

        if (this.playlist["isPlaying"]) {
            return this.playerService.pause()
        }

        if (this.playlistService.playlistCompositionIsMeta()) {
            this.playerService.play()
        } else {
            // this.playerService.loadNextTrack(0, meta.isPlaying)
            setTimeout(() => {
                this.playerService.loadNextTrack({
                    index: 0,
                    play: true,
                })
            }, 100)
        }
    }

    private initListeners(): void {
        this.subscribe(this.route.queryParams, params => {
            if (params["type"] != null) {
                this.folderService.setContentType(
                    "route.queryParams",
                    params["type"],
                    "",
                    true
                )
            }
        })

        this.subscribe(this.route.params, params => {
            if (params["folderID"] && params["compositionID"]) {
                let view = params["view"] || "trackslist"
                let folderID =
                    params["folderID"] != "0" ? params["folderID"] : ""

                this.setTrackView(view, false)
                this.goToFolder(folderID)
            }

            if (params["type"] != null) {
                this.folderService.setContentType(
                    "route.params",
                    params["type"],
                    "",
                    true
                )
            }
        })

        this.subscribe(this.folderService.path, value => {
            this.path = value
        })

        this.subscribe(this.folderService.contentType, value => {
            this.title = value
        })

        this.subscribe(this.tracksService.trackView, viewName => {
            this.trackView = viewName
        })

        this.subscribe(this.playlistService.selectedPlaylist, playlist => {
            this.playlist = playlist
        })
    }

    private createNewFolder() {
        const type =
            this.folderService.contentType.getValue() == "Influences"
                ? "Influences"
                : "Compositions"

        this.analyticsService.addActivity(ActivityMetric.CREATE_NEW_FOLDER, {
            type: type,
        })

        this.folderService.createFolder()
    }
}
