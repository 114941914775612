import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import Environment from '@common-lib/classes/environment';
import { environment } from '@environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector'

@Injectable()
export class HelperService {

	private renderer: Renderer2;

	constructor(rendererFactory: RendererFactory2, private device: DeviceDetectorService) { 
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	/**
	 * Checks if a given variable is an object (arrays excluded)
	 * @param variable 
	 * @returns boolean
	 */
	isObject(variable): boolean {
		if (variable === null) {
			return false;
		}

		if (typeof variable === 'object' && !Array.isArray(variable)) {
			return true;
		} 
		
		else {
			return false;
		}
	}

	/**
	 * Checks if a given variable is an empty object (arrays excluded)
	 * @param object 
	 * @returns boolean
	 */
	isEmptyObject(object):boolean {
		if (this.isObject(object)) {
			return Object.keys(object).length == 0
		} 

		else {
			return true
		}
	}

	/**
	 * Decodes a JWT and returns the decoded payload
	 * @param token 
	 * @returns Object containing the JWT payload
	 * @info further info https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
	 */
	decodeJwt(token) {
		try {
			let base64Url = token.split('.')[1]
			let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
			let jsonPayload = decodeURIComponent(atob(base64).split('').map( (c) => {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			}).join(''))
	
			let payload = JSON.parse(jsonPayload)
	
			return this.isObject(payload) ? payload : undefined
		} catch (error) {
			return null
		}
	}

	/**
	 * Sets a timeout to see the loading animation and provide a visual cue, in case connection is fast
	 */
	loadingAnimation() {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(true)
			}, 200)
		})
	}

	/**
	 * converts seconds into an Object that represents duration length => {hours, minutes, seconds}
	 * @param duration duration in seconds
	 */
	convertSecondsToTime(duration) {
		let hours = Math.floor(duration / 3600).toString()
		let minutes = Math.floor((duration % 3600) / 60).toString()
		let seconds = Math.floor(duration % 60).toString()

		minutes = (Number(minutes) < 10 ? "0" + minutes : minutes)
		seconds = (Number(seconds) < 10 ? "0" + seconds : seconds)

		return { hours, minutes, seconds };
	}

	/**
	 * formats duration in seconds in string format "h:m:s"
	 * @param duration duration in seconds
	 */
	formatTime(duration) {
		let hrs = Math.floor(duration / 3600);
		let mins = Math.floor((duration % 3600) / 60);
		let secs = Math.floor(duration % 60);
		let timeString = "";

		if (hrs > 0) {
			timeString += "" + hrs + ":" + (mins < 10 ? "0" : "");
		}

		timeString += "" + mins + ":" + (secs < 10 ? "0" : "");
		timeString += "" + secs;

		return timeString;
	}

	/**
	 * does exactly what it says, it capitalizes the first letter of a String
	 * @param string 
	 */
	capitalizeFirstLetter(string) {
		if(!string) return ""
		
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	
	triggerEvent( elem, event ) {
		const clickEvent = new Event( event ); // Create the event.
		elem.dispatchEvent( clickEvent );    // Dispatch the event.
	}

	/**
	 * creates a HTMLScriptElement with the given source String
	 * @param src source String to an JavaScript script file
	 * @param appendTo defines where in the document the JS will be appended, default is body (head could be useful as well sometimes)
	 * @returns HTMLScriptElement
	 */
	createJSScriptElement(src: string, appendTo = "body"): HTMLScriptElement {
		const script = document.createElement('script')
		script.type = 'text/javascript'
		script.src = src
		this.renderer.appendChild(document[appendTo], script)

		return script
	}

	/**
	 * creates and loads a HTMLScript with the given source String, resolves when loading the script is done
	 * @param src source String to an JavaScript script file
	 * @param appendTo defines where in the document the JS will be appended, default is body (head could be useful as well sometimes)
	 * @returns resolved Promise when the script is has been loaded successfully
	 */
	loadJSScript(src: string, appendTo = "body") {
		return new Promise((resolve, reject) => {
			this.createJSScriptElement(src, appendTo).onload = (res) => {
				resolve(res)
			}
		})
	}

	/**
	 * Rounds a Number value and returns the rounded value
	 * @param value Number value to be rounded
	 * @param decimals Number of decimals, default is 0
	 * @returns Number rounded value
	 */
	round(value: number, decimals: number = 0) {
		return Number((value).toFixed(decimals))
	}

	getObjectWithMaxValue(arrayOfObjects:any[], key){
		const max = arrayOfObjects.reduce(function (prev, current) {
			return (prev[key] > current[key]) ? prev : current
		})

		return max
	}

	getObjectWithMinValue(arrayOfObjects:any[], key){
		const max = arrayOfObjects.reduce(function (prev, current) {
			return (prev[key] < current[key]) ? prev : current
		})

		return max
	}

	/**
	 * 
	 * @param hex hex color code (no short versions allowed! For example #fff won't work)
	 * @param alpha transparency level
	 * @returns rgba String
	 */
	hexToRGB(hex: string, alpha: string) {
		const r = parseInt(hex.slice(1, 3), 16)
		const g = parseInt(hex.slice(3, 5), 16)
		const b = parseInt(hex.slice(5, 7), 16)
	
		if (alpha) {
			return `rgba(${r}, ${g}, ${b}, ${alpha})`
		} 
		
		else {
			return `rgb(${r}, ${g}, ${b})`
		}
	}

	rgb2hex(rgb) {
		rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)

		function hex(x) {
			return ("0" + parseInt(x).toString(16)).slice(-2)
		}

		return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3])
	}

	isHexColor(colorString: string){
		if(/^#[0-9A-F]{6}$/i.test(colorString)){
			return true
		}

		return false
	}

	isRgbColor(colorString: string){
		let rgbMatch = colorString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
		
		if (rgbMatch && rgbMatch.length > 2){
			return true
		}

		return false
	}

	changeColorOpacity(colorString, opacity: string){
		if (this.isHexColor(colorString)){
			return this.hexToRGB(colorString, opacity)
		}

		if (this.isRgbColor(colorString)) {
		
			let rgbMatch = colorString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)

			return `rgba(${rgbMatch[1]}, ${rgbMatch[2]}, ${rgbMatch[3]}, ${opacity})`
		}
	}

	/**
	 * Converts an HSL color value to RGB. Conversion formula
	 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
	 * Assumes h, s, and l are contained in the set [0, 1] and
	 * returns r, g, and b in the set [0, 255].
	 *
	 * @param   {number}  h       The hue
	 * @param   {number}  s       The saturation
	 * @param   {number}  l       The lightness
	 * @return  {Array}           The RGB representation
	 */
	hslToRgb(h, s, l) {
		var r, g, b

		if(s == 0) {
			r = g = b = l // achromatic
		}
		
		else{
			var hue2rgb = function hue2rgb(p, q, t){
				if(t < 0) t += 1
				if(t > 1) t -= 1
				if(t < 1/6) return p + (q - p) * 6 * t
				if(t < 1/2) return q
				if(t < 2/3) return p + (q - p) * (2/3 - t) * 6

				return p
			}

			var q = l < 0.5 ? l * (1 + s) : l + s - l * s
			var p = 2 * l - q
			r = hue2rgb(p, q, h + 1/3)
			g = hue2rgb(p, q, h)
			b = hue2rgb(p, q, h - 1/3)
		}

		return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
	}	

	/**
	 * Converts an RGB color value to HSL. Conversion formula
	 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
	 * Assumes r, g, and b are contained in the set [0, 255] and
	 * returns h, s, and l in the set [0, 1].
	 *
	 * @param   {number}  r       The red color value
	 * @param   {number}  g       The green color value
	 * @param   {number}  b       The blue color value
	 * @return  {Array}           The HSL representation
	 */
	rgbToHsl(r, g, b) {
		r /= 255, g /= 255, b /= 255
		var max = Math.max(r, g, b), min = Math.min(r, g, b)
		var h, s, l = (max + min) / 2

		if (max == min){
			h = s = 0 // achromatic
		}
		
		else {
			var d = max - min
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

			switch(max){
				case r: h = (g - b) / d + (g < b ? 6 : 0); break
				case g: h = (b - r) / d + 2; break
				case b: h = (r - g) / d + 4; break
			}

			h /= 6
		}

		return [h, s, l]
	}

	sleep(ms: number){
		return new Promise((resolve) => 
			setTimeout(() => {
				resolve(true)
			}, ms)
		)
	}

	getFileTypeByMIMEType(mime = ""){
		let mimeToFileType = {
			"audio/mpeg": "mp3",
			"audio/wav": "wav",
			"audio/x-wav": "wav",
			"audio/x-midi": "midi",
			"audio/midi": "midi"
		}

		return mimeToFileType[mime]
	}

	getArrayBufferFromFile(file){
		return new Promise((resolve, reject) => {
			if(!file){
				return reject(null)
			}

			let reader = new FileReader()
		
			reader.onloadend = () => {
				resolve(reader.result)
			}
		
			reader.readAsArrayBuffer(file)
		})
	}

	getEnvironmentName() {
		let env = environment.production ? "production" : "staging"

		if(!environment.domain.includes("dev.aiva.ai") && !environment.domain.includes("creators.aiva.ai")){
			env = "local"
		}

		return env
	}

	getClientEnvironment():Environment {
		const environment: Environment = {
			name: this.getEnvironmentName(),
			app: null,
			browser: {
				name: this.device.browser,
				version: this.device.browser_version
			},
			os: {
				name: this.device.os,
				version: this.device.os_version.replace(/\D/g, '')
			}
		}

		return environment
	}

	utf8toBase64( str ) {
		if(str == null){
			return ''
		}

		return window.btoa(unescape(encodeURIComponent( str )))
	}
	  
	base64ToUtf8( str ) {
		if(str == null){
			return ''
		}

		return decodeURIComponent(escape(window.atob( str )))
	}

	getTimeDifferenceInSeconds(oldTimeStamp: string, newTimeStamp: string){
		return (parseInt(newTimeStamp) - parseInt(oldTimeStamp)) / 1000
	}

	replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace)
	}

	escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
    }
}
