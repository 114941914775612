import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import { ContextMenu } from "@clientmodels/contextmenu"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import Pack from "@common-lib/classes/generationprofiles/pack"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import {
    DoublingInstrumentSelectionModalParameters,
    FindSimilarInstrumentsModalParameters,
    GPInstrumentSelectionModalParameters,
    InstrumentSelectionModalParameters,
    ModalService,
} from "@services/modal.service"

@Component({
    selector: "gp-instrument-selection",
    templateUrl: "gp-instrument-selection.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GPInstrumentSelection {
    @Input() parameters: GPInstrumentSelectionModalParameters
    @Output() close: EventEmitter<any> = new EventEmitter()

    public hasInitialised: boolean = false

    tooltips = {
        noteTie:
            "When enabled, this feature will automatically tie repeated notes that are directly adjacent to each other, except when a new section starts. Hold Shift to apply to all patches assigned to the pack.",
    }

    public hoveredIndex = -1

    public scrollChangeInstrumentation = {
        useBothWheelAxes: false,
        suppressScrollX: true,
    }

    get pack(): Pack {
        if (this.parameters.layer.name === "Melody") {
            return this.layer.packs[0]
        }

        return this.gpService.getPackByIndex(
            this.parameters.pack["idx"],
            this.parameters.layer.name
        )
    }

    get layer(): GPLayer {
        return this.gpService.getLayer(this.parameters.layer.name)
    }

    constructor(
        private gpService: GenerationProfileService,
        private modalService: ModalService,
        private ref: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this.hasInitialised = true
        this.ref.detectChanges()
    }

    public getPlaybackIcon(instrumentPatch: InstrumentPatch) {
        return this.gpService.getPlaybackIcon(
            this.layer.name,
            instrumentPatch.patchID
        )
    }

    public isPreviewLoading(patch) {
        return this.gpService.isPreviewLoading(patch, this.layer)
    }

    public preview(patch: InstrumentPatch) {
        this.gpService.preview(this.layer.name, this.pack.packID, patch.patchID)
    }

    public findSimilarInstrumentPatches(specificPatch = null) {
        const params: FindSimilarInstrumentsModalParameters = {
            layer: this.layer,
            pack: this.pack,
            instruments: specificPatch
                ? [specificPatch]
                : this.pack.instruments,
            onClose: this.onCloseInstrumentModals.bind(this),
        }

        this.modalService.modals.findSimilarInstrumentsForPack.next(params)

        this.close.emit()
    }

    public deleteInstrumentPatchFromPack(i) {
        this.gpService.pausePreviewForLayer(
            this.layer.name,
            this.pack.packID,
            this.pack.instruments[i].patchID
        )

        this.pack.instruments.splice(i, 1)

        this.gpService.setAsUpdated("deleteInstrument")

        this.ref.detectChanges()
    }

    public selectMoreAddingInstrumentOptions(event) {
        event.stopPropagation()

        this.modalService.contextMenus.addInstruments.next(
            new ContextMenu(
                event.x,
                event.y,
                {
                    layer: this.layer,
                    addInstrumentToPack:
                        this.openInstrumentSelection.bind(this),
                    addDoublesToPack:
                        this.openDoublingInstrumentSelection.bind(this),
                    findSimilarInstrumentPatches:
                        this.findSimilarInstrumentPatches.bind(this),
                },
                [],
                null
            )
        )
    }

    private onCloseInstrumentModals() {
        this.modalService.modals.gpInstrumentSelectionForPack.next({
            layer: this.gpService.getLayer(this.layer.name),
            pack: this.gpService.getPackByIndex(
                this.pack["idx"],
                this.layer.name
            ),
        })
    }

    private openInstrumentSelection() {
        const params: InstrumentSelectionModalParameters = {
            layer: this.layer,
            pack: this.pack,
            allowRecommendations: true,
            selectedTrackBus: undefined,

            selectNewInstrumentPatch: ((
                newInstrumentPatch: InstrumentPatch
            ) => {
                this.gpService.addInstrumentPatchesToPack(
                    this.pack,
                    this.layer,
                    [newInstrumentPatch]
                )

                return true
            }).bind(this),

            onClose: this.onCloseInstrumentModals.bind(this),
        }

        this.modalService.modals.instrumentSelection.next(params)

        this.close.emit()
    }

    public toggleNoteTie(
        event,
        instrumentPatch: InstrumentPatch,
        value: boolean
    ) {
        if (event.shiftKey) {
            for (let patch of this.pack.instruments) {
                patch.noteTie = value
            }
        } else {
            instrumentPatch.noteTie = value
        }

        let preview = this.gpService.getPlayingPreviewForLayer(
            this.layer.name,
            this.pack.packID
        )

        this.gpService.setAsUpdated("noteTie", preview)
    }

    private openDoublingInstrumentSelection() {
        const params: DoublingInstrumentSelectionModalParameters = {
            layer: this.layer,
            pack: this.pack,
            allowRecommendations: true,
            double: undefined,

            selectNewInstrumentPatches: ((
                newInstrumentPatches: InstrumentPatch[]
            ) => {
                this.gpService.addInstrumentPatchesToPack(
                    this.pack,
                    this.layer,
                    newInstrumentPatches
                )

                return true
            }).bind(this),

            onClose: this.onCloseInstrumentModals.bind(this),
        }

        this.modalService.modals.doublingInstrumentSelection.next(params)

        this.close.emit()
    }
}
