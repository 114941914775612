import { TimeSignature } from "../../types/score"

export interface ModifyAPIRequest {
    api_key: string
    composition_id: string
    source_id: string // preCompositionID in the our database
    operations: string // stringified SectionOperationMusicEngine[] (see below)
    metadata: any
    test: boolean
    url: string
}

export enum SectionResizeType {
    LEFT,
    RIGHT,
}

export interface MetadataGetSectionArrayRequest {
    api_key: string
    _id: string // preCompositionID in the our Compositions collection
    test: boolean
}

export interface MetadataGetSectionArrayResponse {
    result: 1 | 0
    sections: string
    duration_of_last_section: string
    duration: string
    error?: string
    trace?: string
}

export interface SectionOperationMusicEngine {
    type: "regenerate" | "delete" | "replace" | "add"
    args: {
        section_idx: number
        source_idx?: number // only for type === 'regenerate'
        insert_type?: "copy" | "variation" | "new" | "blank" // only for type === 'add'
    }
}

export interface CompositionMetadata {
    _id: string

    version: string
    major: string
    minor: string
    patch: string
    pitch_class: string
    key_mode: string
    ensemble: string
    instruments: string[]
    time_signature: TimeSignature
    tempo: number
    duration: number
    pacing: "auto" | "slow" | "medium" | "fast"
    generation_time: number

    preset?: string
    source_id?: string
    influence_id?: string
    generation_profile_id?: string
    generation_profile_version?: number
    mood?: string
}
