import { Injectable } from "@angular/core"
import { ApiService } from "@services/api.service"
import { BehaviorSubject } from "rxjs"
import GPLibraryFilters from "../models/gplibraryfilters"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import { UserService } from "./user.service"
import { Misc } from "@common-lib/modules/misc"
import { CreateTrackFrom } from "@common-lib/types/general"

@Injectable()
export class CreateService {
    selectedMenu = "Styles"

    states = {
        generationProfiles: {
            sortOptions: [
                {
                    value: "compositions",
                    name: "Sort by: Generated Compositions",
                },

                {
                    value: "creationDate",
                    name: "Sort by: Creation date",
                },

                {
                    value: "imports",
                    name: "Sort by: Imports",
                },

                {
                    value: "profileName",
                    name: "Sort by: Name",
                },
            ],

            forkSortOptions: [
                {
                    value: "similarity",
                    name: "Sort by: Similarity",
                },

                {
                    value: "creationDate",
                    name: "Sort by: Creation date",
                },

                {
                    value: "profileName",
                    name: "Sort by: Name",
                },
            ],

            showNewAIVAProfiles: false,

            libraryLoading: new BehaviorSubject<boolean>(false),

            numberOfPages: 0,

            currentPage: 1,

            pages: [0, 1],

            sortCategory: "compositions",

            forkSortCategory: "similarity",

            gps: [],

            search: "",

            filters: new GPLibraryFilters("category"),

            forkFilters: new GPLibraryFilters("gp"),

            showForkedProfilesFor: null,
        },

        influences: {},

        presetStyles: {},
    }

    constructor(private apiService: ApiService) {}

    get gpStates() {
        return this.states.generationProfiles
    }

    get presetStates() {
        return this.states.presetStyles
    }

    get influenceStates() {
        return this.states.influences
    }

    resetForkedProfiles() {
        this.gpStates.showForkedProfilesFor = null
        this.gpStates.forkFilters = new GPLibraryFilters("gp")

        this.setCurrentPage(1)
    }

    resetFilters() {
        this.gpStates.forkFilters = new GPLibraryFilters("gp")
        this.gpStates.filters = new GPLibraryFilters("category")
    }

    resetSortCategory() {
        this.gpStates.sortCategory = "compositions"
        this.gpStates.forkSortCategory = "similarity"
    }

    public showForkedProfiles(gp: GenerationProfile) {
        this.gpStates.showForkedProfilesFor = {
            type: "category",
            gp: gp,
        }

        this.gpStates.currentPage = 1

        return this.getLibraryGPs()
    }

    async setCurrentPage(index) {
        this.states.generationProfiles.currentPage = index
        await this.getLibraryGPs()
    }

    async deleteFilter(filter, f, type) {
        if (this.gpStates.showForkedProfilesFor == null) {
            this.gpStates.filters[type].splice(f, 1)
        } else if (filter.includes("Similarity")) {
            this.gpStates.forkFilters.similarity = {
                min: 0,
                max: 100,
            }
        }

        await this.getLibraryGPs()
    }

    showNewestGPsFromAIVA() {
        this.gpStates.filters.createdBy = ["AIVA"]

        this.gpStates.sortCategory = "creationDate"

        this.selectSortCategory(this.gpStates.sortCategory)
    }

    async selectSortCategory(value) {
        if (this.gpStates.showForkedProfilesFor === null) {
            this.gpStates.sortCategory = value
        } else {
            this.gpStates.forkSortCategory = value
        }

        await this.getLibraryGPs()
    }

    getLibraryGPs() {
        this.gpStates.libraryLoading.next(true)

        let promise: any = Promise.resolve()

        if (!this.gpStates.showForkedProfilesFor) {
            const request = {
                search: this.gpStates.search,
                currentPage: this.gpStates.currentPage,
                filters: this.gpStates.filters,
                sort: this.gpStates.sortCategory,
            }

            promise = this.apiService.authRequest(
                "/generationprofile/getFromLibrary",
                request,
                "primary",
                true
            )
        } else {
            promise = this.apiService.authRequest(
                "/generationprofile/getForks",
                {
                    type: this.gpStates.showForkedProfilesFor.type,
                    categoryID: this.gpStates.showForkedProfilesFor.gp._id,
                    currentPage: this.gpStates.currentPage,
                    sort: this.gpStates.forkSortCategory,
                    filters: this.gpStates.forkFilters,
                },
                "primary",
                true
            )
        }

        return promise.then(res => {
            this.gpStates.gps = res.gps

            if (this.gpStates.showForkedProfilesFor === null) {
                this.gpStates.showNewAIVAProfiles = true //res.showNewAIVAProfiles
            }

            this.gpStates.numberOfPages = res.numberOfPages

            this.createPagesArray(res.numberOfPages)

            this.gpStates.libraryLoading.next(false)
        })
    }

    createPagesArray(numberOfPages) {
        let lowestDisplayedPage = this.getLowestDisplayedPage()

        let numberOfPageButtons = numberOfPages - lowestDisplayedPage

        if (numberOfPages - this.gpStates.currentPage > 5) {
            numberOfPageButtons = 5
        }

        this.gpStates.pages = []

        for (
            let p = Math.max(1, lowestDisplayedPage);
            p <= numberOfPageButtons + lowestDisplayedPage;
            p++
        ) {
            this.gpStates.pages.push(p)
        }
    }

    getLowestDisplayedPage() {
        return Math.floor(this.gpStates.currentPage / 5) * 5
    }

    clearSearch() {
        this.gpStates.search = ""

        this.getLibraryGPs()
    }
}
