<div class="modal-title">Mixing options</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div class="auto-mix-options">
    <radio-button
        class="auto-mix-radio-button"
        [id]="'autoMixAndMaster'"
        [value]="'autoMixAndMaster'"
        groupName="automixing"
        [label]="'Mix & Normalise (recommended)'"
        [description]="descriptions.autoMixAndMaster"
        [selectedValue]="selectedOption"
        (onSelect)="selectedOption = 'autoMixAndMaster'"></radio-button>
    <radio-button
        class="auto-mix-radio-button"
        [id]="'autoMaster'"
        [value]="'autoMaster'"
        groupName="automixing"
        [label]="'Normalise'"
        [description]="descriptions.autoMaster"
        [selectedValue]="selectedOption"
        (onSelect)="selectedOption = 'autoMaster'"></radio-button>
    <radio-button
        class="auto-mix-radio-button"
        [id]="'none'"
        [value]="'none'"
        groupName="automixing"
        [label]="'None'"
        [description]="descriptions.none"
        [selectedValue]="selectedOption"
        (onSelect)="selectedOption = 'none'"></radio-button>
</div>

<div class="message" *ngIf="message">{{ message }}</div>

<a-button
    *ngIf="!loading"
    type="secondary"
    width="100%"
    (click)="clickedButton()"
    [label]="mixingOptions.buttonTitle">
</a-button>

<div class="loader" *ngIf="loading"></div>