<div class="sample-mapper-component">
    <div class="header">
        <div class="title">Sample Mapper</div>
        <steps [steps]="steps" [currentStepIndex]="currentStepIndex"></steps>
    </div>

    <div class="step step-1" *ngIf="currentStepIndex == 1">
        <div class="settings-row">
            <div class="settings-row-title">
                Instrument's folder (must contain a tree folder)
            </div>
            
            <div class="settings-row-value">
                <a-button label="Select path" class="light" (onClick)="selectPath()" *ngIf="!pathLoading && instrumentPath == ''"></a-button>
                <span *ngIf="!pathLoading && instrumentPath != ''">{{ instrumentPath }}</span>
                <div class="loader" *ngIf="pathLoading"></div>
            </div>
        </div>

        <div class="error-message" *ngIf="error != ''">
            {{ error }}
        </div>

        <div class="buttons-container" *ngIf="instrumentPath != ''">
            <a-button width="100%" label="Next" type="primary" (onClick)="goToStep(2)"></a-button>
        </div>

        <div class="empty-item"></div>
    </div>

    <div class="step step-2" *ngIf="currentStepIndex == 2" [perfectScrollbar]="scrollConfig">
        <div *ngFor="let pitch of pitchMap" class="settings-row">
            <div class="settings-row-title">
                Pitch {{ pitch.current }}
            </div>

            <div class="settings-row-value">
                New Pitch: <input type="text" [(ngModel)]="pitch.new" class="light-input potion-input">
            </div>
        </div>

        <div class="error-message" *ngIf="error != ''">
            {{ error }}
        </div>

        <div class="buttons-container">
            <a-button width="100%" label="Next" type="primary" (onClick)="goToStep(3)"></a-button>
        </div>

        <div class="empty-item"></div>
    </div>

    <div class="step step-3" *ngIf="currentStepIndex == 3">
        You're done! 🥳

        <div class="buttons-container">
            <a-button width="100%" label="Another one!" type="primary" (onClick)="anotherOne()"></a-button>
        </div>
    </div>
</div>