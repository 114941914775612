import { GPInfluenceLoadingSchema } from "../../../interfaces/db-schemas/generationprofile"

export default class GPInfluenceLoading {
    progress:number
    isLoading:boolean
    isDraggedOver:boolean
    hasDroppedFile:boolean
    influenceID:string
    time:number
    error:string

    constructor(progress:number, influenceID:string) {
        if (influenceID != "") {
            this.time = Date.now()
        }
        
        this.progress = progress
        this.influenceID = influenceID
    }

    encode():GPInfluenceLoadingSchema {
        return {
            progress: this.progress,
            influenceID: this.influenceID,
            time: this.time,
            error: this.error
        }
    }

    static fromJSON(object) {
        let loading = new GPInfluenceLoading(0, "")

        if (object != null) {
            loading = Object.assign(loading, object)
        }

        return loading
    }
}