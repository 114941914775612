<div id="sidebar-mobile" *ngIf="isLoggedIn" av-data="sidebar-mobile">
    <a href="/" id="logo">
        <img src="assets/img/navbar/aiva.svg" />
        <span id="logo-title">AIVA</span>
    </a>

    <div class="mobile-banner">Some features are disabled on mobile.</div>

    <img
        class="mobile-hamburger-menu"
        src="assets/img/menu.svg"
        (click)="toggleMobileMenuItems()"
        *ngIf="!mobileMenuItems" />

    <div *ngIf="mobileMenuItems" class="mobile-menu-items">
        <img
            class="mobile-hamburger-menu mobile-hamburger-menu-close"
            src="assets/img/close.svg"
            (click)="toggleMobileMenuItems()" />

        <div class="mobile-menu-container">
            <div class="mobile-menu-item" (click)="createNewTrack('Styles')">
                <a-button
                    class="navbar-create-button"
                    width="100%"
                    type="primary"
                    label="Create Track"
                    avData="sidebar-create-btn"></a-button>
            </div>

            <div class="mobile-menu-item" (click)="myTracks()">
                <div id="sidebar-title-icon" class="tracks-icon"></div>
                Home
            </div>

            <div class="mobile-menu-item" (click)="switchToPianoRoll()">
                <img id="sidebar-title-icon" src="assets/img/navbar/edit.svg" />
                Editor
            </div>

            <div class="mobile-menu-item" (click)="radio()" *ngIf="showRadio()">
                <img id="sidebar-title-icon" src="assets/img/menu/radio.svg" />
                Radio
            </div>

            <div
                class="mobile-menu-item"
                (click)="playlists()"
                *ngIf="showPlaylists()">
                <img id="sidebar-title-icon" src="assets/img/grid.svg" />
                Playlists
            </div>

            <div class="mobile-menu-item" (click)="billing()">
                <img
                    id="sidebar-title-icon"
                    src="assets/img/menu/billing.svg" />
                Billing
            </div>

            <div
                class="mobile-menu-item"
                (click)="goToDiscord()"
                *ngIf="showCommunity">
                <img
                    id="sidebar-title-icon"
                    class="sidebar-title-community"
                    src="assets/img/menu/community.svg" />
                Community
            </div>

            <div class="mobile-menu-item" (click)="logout()">
                <img
                    id="sidebar-title-icon"
                    class="sidebar-title-logout"
                    src="assets/img/menu/logout.svg" />
                Logout
            </div>
        </div>
    </div>
</div>

<div
    id="sidebar"
    [ngClass]="{ 'sidebar-mac-electron': isMacElectronApp }"
    *ngIf="isLoggedIn && !isCollapsed"
    av-data="sidebar-desktop">
    <div class="toggle-collapse-button round" (click)="toggleNavbar()">
        <img class="reversed" src="assets/img/chevron.svg" />
    </div>

    <a href="/" id="logo">
        <img src="assets/img/navbar/aiva.svg" />
        AIVA
    </a>

    <a-button
        class="navbar-create-button"
        width="100%"
        type="primary"
        (onClick)="openCreateTrackMenu($event)"
        label="Create Track"
        avData="sidebar-create-btn"></a-button>

    <div class="navbar-buttons-container" [perfectScrollbar]="scrollConfig">
        <div
            id="sidebar-title"
            (click)="myTracks()"
            [ngClass]="{ 'highlighted-menu': locationPath === '' }">
            <img
                id="sidebar-title-icon"
                src="assets/img/navbar/library.svg" />Home
            <div
                id="new-composition-badge"
                class="tooltip"
                *ngIf="unplayedComposition">
                <span class="tooltiptext">New compositions are available</span>
            </div>
        </div>

        <div
            id="sidebar-title"
            (click)="switchToPianoRoll()"
            [ngClass]="{ 'highlighted-menu': editorIsHighlighted() }">
            <img
                id="sidebar-title-icon"
                src="assets/img/navbar/edit.svg" />Editor
            <div
                id="pianoroll-edited-badge"
                class="tooltip"
                *ngIf="midiWasEdited">
                <span class="tooltiptext"
                    >You have unsaved changes in the Piano Roll Editor</span
                >
            </div>
        </div>

        <div
            id="sidebar-title"
            (click)="openGenerationProfile()"
            [ngClass]="{
                'highlighted-menu':
                    locationPath.includes('/generation-profile-editor') ||
                    locationPath.includes('/development-designer')
            }">
            <img
                id="sidebar-title-icon"
                src="assets/img/navbar/generation_profiles.svg" />Style Designer
        </div>

        <div
            id="sidebar-title"
            *ngIf="showRadio()"
            [ngClass]="{ 'highlighted-menu': locationPath === '/radio' }"
            (click)="radio()">
            <img
                id="sidebar-title-icon"
                src="assets/img/navbar/radio.svg" />Radio
        </div>

        <div
            id="sidebar-title"
            [ngClass]="{ 'highlighted-menu': locationPath === '/billing' }"
            (click)="billing()">
            <img
                id="sidebar-title-icon"
                src="assets/img/navbar/billing.svg" />Billing
        </div>

        <div id="sidebar-title" (click)="openChangelog()">
            <img
                id="sidebar-title-icon"
                src="assets/img/navbar/updates.svg" />Updates
        </div>

        <div id="sidebar-title" (click)="goToDiscord()" *ngIf="showCommunity">
            <img
                id="sidebar-title-icon"
                src="assets/img/navbar/community.svg" />Community
        </div>

        <div
            id="sidebar-title"
            [ngClass]="{ 'highlighted-menu': locationPath === '/tutorials' }"
            (click)="tutorials()">
            <img
                id="sidebar-title-icon"
                src="assets/img/navbar/tutorials.svg" />Tutorials
        </div>

        <div id="sidebar-title" (click)="downloadApp()" *ngIf="!isElectron">
            <img
                id="sidebar-title-icon"
                src="assets/img/navbar/download.svg" />Download App
        </div>
    </div>

    <div
        id="user-menu-container"
        [ngClass]="{ 'user-menu-container-open': userMenuOpen }"
        (clickOutside)="userMenuOpen = false">
        <div
            id="sidebar-title"
            class="download-quota-container"
            *ngIf="!userMenuOpen && getDownloads() !== null">
            <div class="download-quota-progress-bar-container">
                <div
                    class="download-quota-progress-bar"
                    [style.width.%]="
                        (getDownloads() * 100) / getDownloadsQuota()
                    "></div>
            </div>

            <div class="download-quota-title">Monthly download quota</div>
            <div class="download-quota-subtitle">
                {{ getDownloadsQuota() - getDownloads() }} monthly downloads
                left
            </div>
            <div
                class="download-quota-subtitle"
                *ngIf="getBonusDownloadsQuota() > 0">
                + {{ getBonusDownloadsQuota() }} reward downloads
            </div>
        </div>

        <div
            id="user-menu-button"
            [ngClass]="{ 'user-menu-main-button': userMenuOpen }"
            (click)="userMenuOpen = !userMenuOpen">
            <img src="assets/img/navbar/user-picture.png" id="user-picture" />
            <div id="menu-email" class="overflow-text">{{ email }}</div>
            <img
                src="assets/img/navbar/up-chevron.png"
                id="down-chevron"
                *ngIf="!userMenuOpen" />
            <img
                src="assets/img/navbar/down-chevron.png"
                id="down-chevron"
                *ngIf="userMenuOpen" />
        </div>

        <div id="user-menu-content" *ngIf="userMenuOpen">
            <div id="user-menu-button" (click)="settings()">Settings</div>
            <div id="user-menu-button" (click)="support()">Helpdesk</div>
            <div
                id="user-menu-button"
                (click)="createBugReport()"
                *ngIf="isElectron">
                Create Bug Report
            </div>
            <div id="user-menu-button" (click)="shareFeedback()">
                Share feedback
            </div>
            <div
                id="user-menu-button"
                (click)="openCompositionByID('composition')"
                *ngIf="isStagingEnvironment()">
                Open Composition
            </div>
            <div
                id="user-menu-button"
                (click)="openCompositionByID('training')"
                *ngIf="
                    isStagingEnvironment() && getFeatureFlags().dataEditingTools
                ">
                Open Trainingset Composition
            </div>
            <div
                id="user-menu-button"
                (click)="importJSON()"
                [ngClass]="{
                    'highlighted-menu': locationPath === '/importjson'
                }"
                *ngIf="isStagingEnvironment()">
                <img
                    id="sidebar-title-icon"
                    src="assets/img/menu/influences.svg" />Import JSON
            </div>
            <div id="user-menu-button" (click)="logout()">Logout</div>
        </div>
    </div>
</div>

<div
    id="sidebar-collapsed"
    *ngIf="isCollapsed && isDesktop"
    [ngClass]="{ 'sidebar-mac-electron': isMacElectronApp }"
    av-data="sidebar-desktop">
    <div class="toggle-collapse-button round" (click)="toggleNavbar()">
        <img class="" src="assets/img/chevron.svg" />
    </div>

    <div class="sidebar-collapsed-content">
        <div class="logo" (click)="myTracks()">
            <img src="assets/img/navbar/aiva.svg" />
        </div>

        <div
            class="create-button-circle tooltip"
            (click)="openCreateTrackMenu($event)">
            <img src="assets/img/add.svg" />
            <span class="tooltiptext">Create Track</span>
        </div>

        <div
            class="menu-items navbar-buttons-container"
            [perfectScrollbar]="scrollConfig">
            <div
                class="menu-item"
                [ngClass]="{ 'highlighted-menu': locationPath === '' }"
                (click)="myTracks()"
                [tooltip]="'Home'"
                [tooltip-marginTop]="-18"
                [tooltip-ignore]="'#new-composition-badge'">
                <img
                    class="collapsed-sidebar-icon my-tracks"
                    src="assets/img/navbar/library.svg" />
                <div id="new-composition-badge" class="badge tooltip">
                    <span class="tooltiptext"
                        >New compositions are available</span
                    >
                </div>
            </div>

            <div
                class="menu-item"
                [ngClass]="{ 'highlighted-menu': editorIsHighlighted() }"
                (click)="switchToPianoRoll()"
                [tooltip]="'Editor'"
                [tooltip-marginTop]="-19"
                [tooltip-ignore]="'#pianoroll-edited-badge'">
                <img
                    class="collapsed-sidebar-icon editor"
                    src="assets/img/navbar/edit.svg" />
                <div
                    id="pianoroll-edited-badge"
                    class="badge tooltip"
                    *ngIf="midiWasEdited">
                    <span class="tooltiptext"
                        >You have unsaved changes in the Piano Roll Editor</span
                    >
                </div>
            </div>

            <div
                class="menu-item"
                (click)="openGenerationProfile()"
                [ngClass]="{
                    'highlighted-menu': locationPath.includes(
                        '/generation-profile-editor'
                    )
                }"
                [tooltip]="'Style Designer'"
                [tooltip-marginTop]="-19">
                <img
                    class="collapsed-sidebar-icon"
                    src="assets/img/navbar/generation_profiles.svg" />
            </div>

            <div
                class="menu-item"
                [ngClass]="{ 'highlighted-menu': locationPath === '/radio' }"
                (click)="radio()"
                [tooltip]="'Radio'"
                [tooltip-marginTop]="-19">
                <img
                    class="collapsed-sidebar-icon"
                    src="assets/img/navbar/radio.svg" />
            </div>

            <div
                class="menu-item"
                [ngClass]="{ 'highlighted-menu': locationPath === '/billing' }"
                (click)="billing()"
                [tooltip]="'Billing'"
                [tooltip-marginTop]="-20">
                <img
                    class="collapsed-sidebar-icon billing"
                    src="assets/img/navbar/billing.svg" />
            </div>

            <div
                class="menu-item"
                (click)="openChangelog()"
                [tooltip]="'Updates'"
                [tooltip-marginTop]="-19">
                <img
                    class="collapsed-sidebar-icon"
                    src="assets/img/navbar/updates.svg" />
            </div>

            <div
                class="menu-item"
                (click)="goToDiscord()"
                *ngIf="showCommunity"
                [tooltip]="'Community'"
                [tooltip-marginTop]="-22">
                <img
                    class="collapsed-sidebar-icon community"
                    src="assets/img/navbar/community.svg" />
            </div>

            <div
                class="menu-item"
                [ngClass]="{
                    'highlighted-menu': locationPath === '/tutorials'
                }"
                [tooltip]="'Tutorials'"
                [tooltip-marginTop]="-20">
                <img
                    class="collapsed-sidebar-icon"
                    src="assets/img/navbar/tutorials.svg"
                    (click)="tutorials()" />
            </div>

            <div
                class="menu-item"
                [tooltip]="'Download App'"
                [tooltip-marginTop]="-20">
                <img
                    class="collapsed-sidebar-icon"
                    src="assets/img/navbar/download.svg"
                    (click)="downloadApp()"
                    *ngIf="!isElectron" />
            </div>
        </div>

        <div id="user-menu-container">
            <progress-circle
                *ngIf="!legacyBilling()"
                class="navbar"
                [progress]="getDownloads()"
                [total]="getDownloadsQuota()"
                width="70"
                [tooltip]="
                    'You have used ' +
                    getDownloads() +
                    ' out of ' +
                    getDownloadsQuota() +
                    ' monthly available downloads.'
                "></progress-circle>

            <div class="user-menu">
                <div class="user-menu-button" (click)="openUserMenu()">
                    <img
                        class=""
                        src="assets/img/navbar/user-picture.png"
                        id="user-picture" />
                </div>
            </div>
        </div>
    </div>
</div>

<menu-modifiers
    [modifiers]="createTrackMenu"
    *ngIf="createTrackMenu !== undefined"
    (close)="closeCreateTrackMenu()"
    avData="sidebar-create-menu"></menu-modifiers>
