import { Injectable } from "@angular/core"
import { ParentClass } from "../../parent"
import { SourcePackState, SourcePackStore } from "./sourcepacks.store"
import { SourcePackHttpService } from "./sourcepacks.http"
import { Query } from "@datorama/akita"
import HarmonyPack from "@common-lib/classes/generationprofiles/harmony/harmonypack"
import MelodyPack from "@common-lib/classes/generationprofiles/melody/melodypack"
import { FormDescriptionPacks } from "@common-lib/interfaces/music-engine/generationprofile"
import { GetAccompanimentPacksBody } from "@common-lib/interfaces/api/accompaniment.api"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
@Injectable()
export class SourcePackService extends ParentClass {
    private readonly store: SourcePackStore = new SourcePackStore()
    private readonly query: Query<SourcePackState> = new Query<SourcePackState>(
        this.store
    )

    constructor(private http: SourcePackHttpService) {
        super()
    }

    public async getAccompanimentPacks(
        parameters: GetAccompanimentPacksBody
    ): Promise<AccompanimentPack[]> {
        return this.http.getAccompanimentPacks(parameters)
    }

    public async getFormDescriptionPacks(): Promise<FormDescriptionPacks> {
        if (this.query.getValue().formDescriptionPacks !== undefined) {
            return this.query.getValue().formDescriptionPacks
        } else {
            const formDescriptionPacks =
                await this.http.getFormDescriptionsPacks()

            this.store.update(state => ({
                formDescriptionPacks,
            }))

            return formDescriptionPacks
        }
    }

    public resetPacks() {
        this.store.update(state => ({
            melody: [],
            harmony: [],
            formDescriptionPacks: undefined,
        }))
    }

    public async getHarmonyPacks(): Promise<HarmonyPack[]> {
        if (this.query.getValue().harmony.length > 0) {
            return this.query.getValue().harmony
        } else {
            const harmony = await this.http.getHarmonyPacks()

            this.store.update(state => ({
                harmony,
            }))

            return harmony
        }
    }

    public async getMelodyPacks(): Promise<MelodyPack[]> {
        if (this.query.getValue().melody.length > 0) {
            return this.query.getValue().melody
        } else {
            const melody = await this.http.getMelodyPacks()

            this.store.update(state => ({
                melody,
            }))

            return melody
        }
    }

    initPacks(sourcePacks) {
        /*if(!sourcePacks){
			console.warn("No data for GP source packs available.")
			return
		}

		this.harmonyPacks = []
		this.melodyPacks = []
		this.accompanimentPacks = {}

		for (let hp of sourcePacks.harmonyPacks) {
			this.harmonyPacks.push(HarmonyPack.fromJSON(hp))
		}

		for (let mp of sourcePacks.melodyPacks) {
			this.melodyPacks.push(MelodyPack.fromJSON(mp))
		}

		for (let ap of sourcePacks.accompanimentPacks) {
			const pack = AccompanimentPack.fromJSON(ap)

			if (this.accompanimentPacks[pack.type] == null) {
				this.accompanimentPacks[pack.type] = []
			}

			this.accompanimentPacks[pack.type].push(pack)
		}		

		for (let type in this.accompanimentPacks) {
			this.accompanimentPacks[type].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
		}

		this.formDescriptionPacks = sourcePacks.formDescriptionPacks*/
    }
}
