<span class="{{ isMobile() ? 'mobile' : '' }}">
    <div class="slider-difficulty" *ngIf="!customizePreset">
        <div class="slider-difficulty-title" [ngClass]="{'selected-slider-difficulty-title': difficulty == 'simplified' }">
            Emotion view</div>

        <slide-toggle width="30px" [(toggled)]="difficultyToggled" (onChange)="changeSlider($event)"></slide-toggle>

        <div class="slider-difficulty-title" [ngClass]="{'selected-slider-difficulty-title': difficulty == 'advanced' }">
            Advanced view</div>
    </div>

    <div *ngIf="difficulty == 'simplified' && !customizePreset" class="preset-usecases">
        <div class="preset-usecase-category" *ngFor="let category of getPresetUseCases(); let c = index">
            <div class="preset-usecase-category-title">{{ category.category }}</div>

            <div class="preset-usecase" *ngFor="let presetUseCase of category.subcategories; let u = index"
                (click)="selectPresetUsecase(c, u)" [ngClass]="{ 'cyberpunk-preset': presetUseCase.name == 'Cyberpunk' }"
                (mouseover)="hoverOnCyberpunk(presetUseCase.name, true)"
                (mouseout)="hoverOnCyberpunk(presetUseCase.name, false)">
                <img src="assets/img/presets/{{ presetUseCase.image }}"
                    *ngIf="presetUseCase.name != 'Cyberpunk' || !cyberpunkIsHovered">
                <img src="assets/img/presets/cyberpunk_hover.svg"
                    *ngIf="presetUseCase.name == 'Cyberpunk' && cyberpunkIsHovered">

                {{ presetUseCase.name }}
            </div>
        </div>
    </div>

    <div id="preset-settings-container" *ngIf="(difficulty == 'simplified' && customizePreset) || createFromPlaylist">
        <div class="modal-title">Selected style: {{ selectedPresetUsecase.name }}</div>

        <div class="parameters-view parameters-emotion-view">
            <emotion-select [emotions]="emotionItems" [showWarning]="false" [emotion]="parameters.emotion"
                (onChange)="onEmotionChange($event)"></emotion-select>

            <div class="parameter-wrapper" *ngIf="durationItems.length > 0">
                <div class="modal-subtitle parameter-wrapper-title">Select a duration</div>

                <div class="parameter-item" *ngFor="let duration of durationItems; let i = index"
                    [ngClass]="{ 'selected-item': duration == parameters.duration, 'tooltip': !showDuration(duration), 'disabled-duration': !showDuration(duration) }"
                    (click)="selectDuration(duration)">
                    {{ duration }}
                    <span class="tooltiptext" *ngIf="!showDuration(duration)">Subscribe to access more duration
                        options.</span>
                </div>
            </div>

            <div class="parameter-wrapper" *ngIf="numberOfCompositionsItems.length > 0">
                <div class="modal-subtitle parameter-wrapper-title">Number of Compositions</div>

                <div class="parameter-item" *ngFor="let numberOfCompositions of numberOfCompositionsItems; let i = index"
                    [ngClass]="{ 'selected-item': numberOfCompositions == parameters.numberOfCompositions }"
                    (click)="parameters.numberOfCompositions = numberOfCompositions">
                    {{ numberOfCompositions }}
                </div>
            </div>
        </div>

        <div class="preset-buttons-container">
            <a-button class="margin-right white-text" (click)="cancelCustomization()" *ngIf="!presetLoading" label="Cancel"></a-button>

            <a-button type="secondary" (click)="captchaRef.execute()" *ngIf="!presetLoading && captcha" label="Create your track(s)"></a-button>

            <a-button type="secondary" (click)="createTrackWithPreset($event)" *ngIf="!presetLoading && !captcha" label="Create your track(s)"></a-button>

            <div class="loader" *ngIf="presetLoading"></div>
        </div>

        <re-captcha #captchaRef="reCaptcha" siteKey="{{ getCaptchaKey() }}" size="invisible"
            (resolved)="createTrackWithPreset($event)">
        </re-captcha>
    </div>

    <div *ngIf="difficulty == 'advanced' && !customizePreset">
        <div id="preset-container" *ngIf="!customizePreset && createMenu == 'preset'">
            <div id="preset-list" *ngIf="!presetLoading">
                <div class="preset-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == 'modern_cinematic'}"
                        (click)="selectPreset('modern_cinematic')"
                        (mouseenter)="togglePresetDescription('modern_cinematic', true)"
                        (mouseleave)="togglePresetDescription('modern_cinematic', false)">
                        <img src="assets/img/presets/modern_cinematic.svg">
                        <div class="preset-title">Modern Cinematic</div>
                    </div>
                </div>

                <div class="preset-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == '20th_century_cinematic'}"
                        (click)="selectPreset('20th_century_cinematic')"
                        (mouseenter)="togglePresetDescription('20th_century_cinematic', true)"
                        (mouseleave)="togglePresetDescription('20th_century_cinematic', false)">
                        <img src="assets/img/presets/20th_century_cinematic.svg">
                        <div class="preset-title">20th Century Cinematic</div>
                    </div>
                </div>

                <div class="preset-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == 'sea_shanties'}"
                        (click)="selectPreset('sea_shanties')" (mouseenter)="togglePresetDescription('sea_shanties', true)"
                        (mouseleave)="togglePresetDescription('sea_shanties', false)">
                        <img src="assets/img/presets/sea_shanty.svg">
                        <div class="preset-title">Sea Shanty</div>
                    </div>
                </div>

                <div class="preset-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == 'jazz'}"
                        (click)="selectPreset('jazz')" (mouseenter)="togglePresetDescription('jazz', true)"
                        (mouseleave)="togglePresetDescription('jazz', false)">
                        <img src="assets/img/presets/jazz.svg">
                        <div class="preset-title">Jazz</div>
                    </div>
                </div>

                <div class="preset-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == 'fantasy'}"
                        (click)="selectPreset('fantasy')" (mouseenter)="togglePresetDescription('fantasy', true)"
                        (mouseleave)="togglePresetDescription('fantasy', false)">
                        <img src="assets/img/presets/fantasy.svg">
                        <div class="preset-title">Fantasy</div>
                    </div>
                </div>

                <div class="preset-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == 'pop'}"
                        (click)="selectPreset('pop')" (mouseenter)="togglePresetDescription('pop', true)"
                        (mouseleave)="togglePresetDescription('pop', false)">
                        <img src="assets/img/presets/pop.svg">
                        <div class="preset-title">Pop</div>
                    </div>
                </div>

                <div class="preset-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == 'rock'}"
                        (click)="selectPreset('rock')" (mouseenter)="togglePresetDescription('rock', true)"
                        (mouseleave)="togglePresetDescription('rock', false)">
                        <img src="assets/img/presets/rock.svg">
                        <div class="preset-title">Rock</div>
                    </div>
                </div>

                <div class="preset-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == 'chinese'}"
                        (click)="selectPreset('chinese')" (mouseenter)="togglePresetDescription('chinese', true)"
                        (mouseleave)="togglePresetDescription('chinese', false)">
                        <img src="assets/img/presets/chinese.svg">
                        <div class="preset-title">Chinese</div>
                    </div>
                </div>

                <div class="preset-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == 'tango'}"
                        (click)="selectPreset('tango')" (mouseenter)="togglePresetDescription('tango', true)"
                        (mouseleave)="togglePresetDescription('tango', false)">
                        <img src="assets/img/presets/tango.svg">
                        <div class="preset-title">Tango</div>
                    </div>
                </div>

                <div class="preset-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == 'electronic'}"
                        (click)="selectPreset('electronic')" (mouseenter)="togglePresetDescription('electronic', true)"
                        (mouseleave)="togglePresetDescription('electronic', false)">
                        <img src="assets/img/presets/sampler.svg">
                        <div class="preset-title">Electronic</div>
                    </div>
                </div>

                <div class="preset-container ambient-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == 'ambient'}"
                        (click)="selectPreset('ambient')" (mouseenter)="togglePresetDescription('ambient', true)"
                        (mouseleave)="togglePresetDescription('ambient', false)">
                        <img src="assets/img/presets/ambient.svg">
                        <div class="preset-title">Ambient</div>
                    </div>
                </div>

                <div class="preset-container ambient-container">
                    <div class="preset" [ngClass]="{'selected-preset': selectedPreset == 'hip_hop'}"
                        (click)="selectPreset('hip_hop')" (mouseenter)="togglePresetDescription('hip_hop', true)"
                        (mouseleave)="togglePresetDescription('hip_hop', false)">
                        <img src="assets/img/presets/vinyl.svg">
                        <div class="preset-title">Hip Hop</div>
                    </div>
                </div>
            </div>

            <div id="preset-description" *ngIf="!presetLoading && presetDescription != ''">
                {{ presetDescription }}
            </div>
        </div>
    </div>

    <div id="preset-settings-container" *ngIf="difficulty == 'advanced' && customizePreset && !createFromPlaylist">
        <div id="selected-preset" *ngIf="!isMobile()">
            <img src="assets/img/modal/preset.svg">
            Selected Preset: {{ presetName(selectedPreset) }}
            <img src="assets/img/modal/preset.svg">
        </div>

        <div class="slider-difficulty">
            <div class="slider-difficulty-title" [ngClass]="{'selected-slider-difficulty-title': backingTrack }">Backing
                track<br><i class="has-melody">Without Melody</i></div>

            <slide-toggle [(toggled)]="!backingTrack"></slide-toggle>

            <div class="slider-difficulty-title" [ngClass]="{'selected-slider-difficulty-title': !backingTrack }">Song<br><i
                    class="has-melody">With Melody</i></div>
        </div>

        <div id="preset-parameters">
            <div class="preset-parameter-wrapper" *ngIf="keyItems.length > 0" (mouseenter)="keyList = !keyList"
                (mouseleave)="keyList = !keyList">
                <div class="preset-parameter">
                    <div class="preset-parameter-content">{{ parameters.key }}</div>
                    <div class="preset-parameter-list"
                        [ngClass]="{'extended-preset-parameter-list': selectedPreset != 'rock'}" *ngIf="keyList"
                        [perfectScrollbar]="scrollConfig">
                        <div class="preset-parameter-list-item" *ngFor="let keyItem of keyItems"
                            (click)="changekeyMode(keyItem)">{{ keyItem }}</div>
                    </div>

                    <div class="preset-parameter-title">Key Signature</div>
                </div>

                <!--<div class="parameter-info tooltip">Help me select <img src="assets/img/info.svg"><span class="tooltiptext">Selecting “Any Major” usually gives more upbeat outputs, while “Any Minor” is usually more melancholic</span></div>-->
            </div>

            <div class="preset-parameter-wrapper" *ngIf="timeSignatureItems.length > 0"
                (mouseenter)="timeSignatureList = !timeSignatureList" (mouseleave)="timeSignatureList = !timeSignatureList">
                <div class="preset-parameter tooltip tooltip-parameter"
                    [ngClass]="{'preset-parameter-disabled': parameters.pacing != 'auto'}">
                    <span class="tooltiptext" *ngIf="parameters.pacing != 'auto'">The 'Time Signature' and 'Pacing'
                        parameters are currently incompatible with each other. You can't select custom values for both at
                        the same time.</span>
                    <div class="preset-parameter-content">{{ parameters.timeSignature }}</div>
                    <div class="preset-parameter-list"
                        [ngClass]="{'extended-preset-parameter-list': selectedPreset != 'rock'}"
                        *ngIf="timeSignatureList && parameters.pacing == 'auto'" [perfectScrollbar]="scrollConfig">
                        <div class="preset-parameter-list-item" *ngFor="let timeSignatureItem of timeSignatureItems"
                            (click)="changeTimeSignature(timeSignatureItem)">{{ timeSignatureItem }}</div>
                    </div>

                    <div class="preset-parameter-title">Time Signature</div>
                </div>

                <!--<div class="parameter-info tooltip">Help me select <img src="assets/img/info.svg"><span class="tooltiptext">4/4 and 2/4 usually give basic rhythms that are frequently used, while 3/4, 6/8 and 12/8 can give you a more distinctive rhythmic flavour</span></div>-->
            </div>

            <div class="preset-parameter-wrapper" *ngIf="pacingItems.length > 0" (mouseenter)="pacingList = !pacingList"
                (mouseleave)="pacingList = !pacingList">
                <div class="preset-parameter tooltip tooltip-parameter"
                    [ngClass]="{'preset-parameter-disabled': parameters.timeSignature != 'auto'}">
                    <span class="tooltiptext" *ngIf="parameters.timeSignature != 'auto'">The 'Pacing' and 'Time Signature'
                        parameters are currently incompatible with each other. You can't select custom values for both at
                        the same time.</span>
                    <div class="preset-parameter-content">{{ parameters.pacing }}</div>
                    <div class="preset-parameter-list"
                        [ngClass]="{'extended-preset-parameter-list': selectedPreset != 'rock'}"
                        *ngIf="pacingList && parameters.timeSignature == 'auto'" [perfectScrollbar]="scrollConfig">
                        <div class="preset-parameter-list-item" *ngFor="let pacingItem of pacingItems; let p = index"
                            (click)="changePacing(pacingItem)"><b>{{ pacingItem }}</b><br><i>{{ getBPMRange(p) }}</i></div>
                    </div>

                    <div class="preset-parameter-title" (click)="showPacing()">Pacing</div>
                </div>
            </div>

            <div class="preset-parameter-wrapper" *ngIf="ensembleItems.length > 0"
                (mouseenter)="ensembleList = !ensembleList" (mouseleave)="ensembleList = !ensembleList">
                <div class="preset-parameter">
                    <div class="preset-parameter-content">{{ parameters.ensemble }}</div>
                    <div class="preset-parameter-list"
                        [ngClass]="{'extended-preset-parameter-list': selectedPreset != 'rock'}" *ngIf="ensembleList"
                        [perfectScrollbar]="scrollConfig">
                        <div class="preset-parameter-list-item" *ngFor="let ensembleItem of ensembleItems"
                            (click)="changeEnsemble(ensembleItem)">{{ ensembleItem }}</div>
                    </div>
                    <div class="preset-parameter-title">Instrumentation</div>
                </div>
            </div>

            <div class="preset-parameter-wrapper" *ngIf="durationItems.length > 0"
                (mouseenter)="durationList = !durationList" (mouseleave)="durationList = !durationList">
                <div class="preset-parameter">
                    <div class="preset-parameter-content">{{ parameters.duration }}</div>
                    <div class="preset-parameter-list"
                        [ngClass]="{'extended-preset-parameter-list': selectedPreset != 'rock'}" *ngIf="durationList"
                        [perfectScrollbar]="scrollConfig">
                        <div class="preset-parameter-list-item" *ngFor="let durationItem of durationItems"
                            (click)="changeDuration(durationItem)"
                            [ngClass]="{'disabled-duration': !showDuration(durationItem) }">

                            {{ durationItem }}
                        </div>
                    </div>
                    <div class="preset-parameter-title">Duration</div>
                </div>
            </div>

            <div class="preset-parameter-wrapper" *ngIf="numberOfCompositionsItems.length > 0"
                (mouseenter)="numberOfCompositionsList = !numberOfCompositionsList"
                (mouseleave)="numberOfCompositionsList = !numberOfCompositionsList">
                <div class="preset-parameter">
                    <div class="preset-parameter-content">{{ parameters.numberOfCompositions }}</div>
                    <div class="preset-parameter-list"
                        [ngClass]="{'extended-preset-parameter-list': selectedPreset != 'rock'}"
                        *ngIf="numberOfCompositionsList" [perfectScrollbar]="scrollConfig">
                        <div class="preset-parameter-list-item"
                            *ngFor="let numberOfCompositionsItem of numberOfCompositionsItems"
                            (click)="changeNumberOfCompositions(numberOfCompositionsItem)">{{ numberOfCompositionsItem }}
                        </div>
                    </div>
                    <div class="preset-parameter-title"># of Compositions</div>
                </div>
            </div>

            <!--<div *ngIf="isNotProduction() && selectedUsecase == null">
                <input placeholder="Ensemble name (optional)" [(ngModel)]="ensembleInput">
            </div>-->
        </div>

        <div id="boolean-parameters" *ngIf="selectedPreset != 'electronic'">
            <div class="boolean-parameter" [ngClass]="{'boolean-parameter-staging': isNotProduction() }"><input
                    type="checkbox" [(ngModel)]="includePercussions"> <span class="boolean-parameter-title"
                    (click)="includePercussions = !includePercussions"> Include Percussion (only for ensembles that support
                    percussion)</span></div>
            <div class="boolean-parameter" [ngClass]="{'boolean-parameter-staging': isNotProduction() }"
                *ngIf="selectedPreset != 'pop' && selectedPreset != 'rock' && selectedPreset != 'ambient' && selectedPreset != 'hip_hop'">
                <input type="checkbox" [(ngModel)]="includeTempoVariations"><span class="boolean-parameter-title"
                    (click)="includeTempoVariations = !includeTempoVariations"> Include Tempo Variations</span></div>
        </div>

        <div id="bass-boost" *ngIf="selectedPreset == 'electronic' || selectedPreset == 'hip_hop'" (click)="bassBoost()">
            <img src="assets/img/bassboost.png" *ngIf="!bassBoostSelected">
            <img src="assets/img/bassboost-selected.png" *ngIf="bassBoostSelected">
        </div>

        <div class="preset-buttons-container">
            <a-button class="margin-right white-text" (click)="cancelCustomization()" *ngIf="!presetLoading" label="Cancel"></a-button>

            <a-button type="secondary" (click)="captchaRef.execute()" *ngIf="!presetLoading && captcha" label="Create your track(s)"></a-button>

            <a-button type="secondary" (click)="createTrackWithPreset($event)" *ngIf="!presetLoading && !captcha" label="Create your track(s)"></a-button>

            <div class="loader" *ngIf="presetLoading"></div>
        </div>

        <re-captcha #captchaRef="reCaptcha" siteKey="{{ getCaptchaKey() }}" size="invisible"
            (resolved)="createTrackWithPreset($event)">
        </re-captcha>
    </div>
</span>