<div
    class="tooltip-wrapper"
    [ngStyle]="{ width: width + 'px' }"
    *ngIf="text">
    <div
        *ngIf="!description"
        class="tooltip-container {{ class }}"
        [ngStyle]="{
            'text-align': textAlign,
            'margin-top': marginTop + 'px',
            'margin-left': marginLeft + 'px',
            'margin-bottom': marginBottom + 'px',
            'margin-right': marginRight + 'px',
            width: width + 'px'
        }"
        [innerHtml]="text"></div>

    <div
        *ngIf="description"
        class="tooltip-container {{ class }}"
        [ngStyle]="{
            'text-align': textAlign,
            'margin-top': marginTop + 'px',
            'margin-left': marginLeft + 'px',
            'margin-bottom': marginBottom + 'px',
            'margin-right': marginRight + 'px',
            width: width + 'px'
        }">
        <div class="tooltip-container-title">{{ text }}</div>
        <div class="tooltip-container-description" [style.width.px]="width">
            {{ description }}
        </div>
    </div>
</div>
