import { Component, OnInit, HostListener, ViewChild, ElementRef, NgZone } from '@angular/core'
import { ApiService } from '../../services/api.service'
import { TokenService } from '../../services/token.service'
import { Router, ActivatedRoute } from '@angular/router'
import { environment } from '../../../environments/environment'
import { HelperService } from '../../helpers/helper.service'
import { Misc } from '@common-lib/modules/misc'
import { accounts } from 'google-one-tap'
import { FolderService } from '@services/folder.service'

declare var google: any
@Component({
  	selector: 'app-login',
  	templateUrl: 'login.component.html',
  	styles: []
})
export class LoginComponent implements OnInit {
	loginType = 0
	user = { email: "", password: "", confirmPassword: "", tnc: false, referralID: '' }
	loading:boolean = false
	referralID
	captchaResponse:string = ''
	captcha = false
	title:string = "Log in"
	message:{type?:string,text?:string, show?:boolean} = {type:"", text:"", show:false}

	redirectToApp = false

	waitingOnBrowserLogin = false

	@ViewChild("loginPasswordField", {static: false}) loginPasswordField: ElementRef
	@ViewChild("captchaRef", {static: false}) captchaRef

  	constructor(
		private apiService: ApiService, 
		private route: ActivatedRoute, 
		private router: Router, 
		private tokenService: TokenService, 
		private helper: HelperService,
		private ngZone: NgZone,
		private folderService:FolderService
	) 
	{
		this.route.queryParams.subscribe(queryParams => {
			this.user.referralID = queryParams['r']
		})

		this.apiService.captcha.subscribe(value => {
			this.captcha = value
		})

		this.route.queryParams.subscribe(params => {
			if(localStorage.getItem("currentUser") == null){
				this.apiService.isLoggedIn.next(false)
			}

			if (params['redirectToApp'] == "true") {
				localStorage.setItem('redirectToApp', "true")
				this.redirectToApp = true
			}
		})
	}


  	ngOnInit() {
		this.route.params.subscribe(params => {
			const userID = params['userID']
			const signup = params['signup']

			if (signup != null && signup) {
				this.goToCreateAccount()
			}
		})
	}

	async ngAfterViewInit(){
		this.initGoogleSignIn()
		await this.tryHiddenLogin()
	}

	tryHiddenLogin(): Promise<void> {
		const urlParams = new URLSearchParams(location.search)
		const email = urlParams.get("email")
		const token = urlParams.get("token")
		const refreshToken = urlParams.get("refreshToken")

		if (email && token && refreshToken) {
			this.user["email"] = email
			localStorage.setItem("redirectToApp", "true")
			return this.finalizeLogin({token, refreshToken}, "password")
		}
	}

	initGoogleSignIn(){
		const gAccounts: accounts = google.accounts

		gAccounts.id.initialize({
			client_id: environment.googleClientID,
			ux_mode: 'popup',
			cancel_on_tap_outside: true,
			callback: (res) => {
				this.ngZone.run(() => {
					this.signInWithGoogle(res.credential)
				})
			},
		})

		this.renderGoogleSignInButton()
	}

	renderGoogleSignInButton(){
		setTimeout(() => {
			const gAccounts: accounts = google.accounts
	
			gAccounts.id.renderButton(document.getElementById('google-btn') as HTMLElement, {
				size: 'large',
				text: "continue_with",
				width: 330,
				locale: "en_US"
				// theme: "filled_blue",
			})
		}, 0)
	}

	goToForgotPassword():void {
		this.loginType = 2
		this.title = "Reset your password"
		this.hideMessage()
		this.renderGoogleSignInButton()
	}

	goToCreateAccount():void {
		this.router.navigate(['/createaccount'], { queryParamsHandling: "merge" })
	}

	goToLoginFinal():void {
		if(this.user.email != "" && this.user.email != undefined){
			this.loginType = 1
			this.title = "Log in"
			this.hideMessage()

			setTimeout(() => {
				this.loginPasswordField.nativeElement.focus()
			}, 1)
		} 
		
		else {
			this.showMessage("error", 'Please enter your email address first.')
		}
	}

	goToLogin():void {
		this.loginType = 0
		this.title = "Log in"
		this.hideMessage()
		this.renderGoogleSignInButton()
	}
	
	showMessage(type:string, text:string){
		if(type && text) this.message = {type:type, text:text, show:true}
	}
	hideMessage(){
		this.message = {type:"", text:"", show:false}
	}

	resetPassword():void {
		this.loading = true
		this.hideMessage()

		this.apiService.request('/user/forgotPassword', { email: this.user.email }, "primary", "post", true).then((res) => {
			this.goToLogin()
			this.loading = false
			this.showMessage("success", res["message"])
		})

		.catch((err) => {
			this.loading = false;
			if (this.helper.isObject(err)) {
				this.showMessage("error", err.message)
			} else {
				this.showMessage("error", err)
			}
		})
	}

	getCaptchaKey() {
		return environment.recaptchaSiteKey
	}

	replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace)
	}
	
	escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
    }

	login(captchaResponse):void {
		this.hideMessage()
		this.apiService.updateError('')
		this.loading = true

		this.apiService.oneAccountPerPerson(this.user.email).then(() => {
			return this.loginLoadingAnimation()
		})

		.then(() => {
			this.user['captchaResponse'] = captchaResponse
			this.user['email'] = this.replaceAll(this.user['email'], " ", "")
			
			return this.apiService.login(this.user)
		})
		
		.then((tokens) => {
			return this.finalizeLogin(tokens, "password")
		})

		.catch((err) => {
			this.showMessage("error", err)
				
			this.loading = false
		})
	}

	googleLogin(credential) {
		this.loading = true

		const decodedJWT = this.helper.decodeJwt(credential)

		return this.loginLoadingAnimation().then(() => {
			this.user['email'] = this.helper.replaceAll(decodedJWT['email'], " ", "")
			this.user['idToken'] = credential

			return this.apiService.googleLogin(this.user)
		})
		
		.then((tokens) => {						
			if (tokens.createAccount) {
				this.router.navigate(['/createaccount'], { 
					queryParams: {
						tnc: true,
						service: "google",
						email: this.user['email'],
						idToken: this.user['idToken']
					} 
				})

				return Promise.resolve()
			}

			else {
				return this.finalizeLogin(tokens, "google")
			}
		})
	}

	async finalizeLogin(tokens, type) {
		try {
			this.tokenService.setToken(tokens.token)
			this.tokenService.setRefreshToken(tokens.refreshToken)

			await this.tokenService.generateToken()

			this.hideMessage()

			this.apiService.setUser(this.user.email, type)

			await Misc.wait(1)

			this.folderService.changeSelectedFolder("")

			this.router.navigate(['/'])

			this.apiService.isLoggedIn.next(true)
			this.loading = false
		}

		catch(e) {
			console.error("Error in finalize login")
			console.error(e)
		}
	}

	onKeyDown(event:any):void {
		if (event.keyCode == 13){			
			if (this.loginType == 1) {
				// this.createAccount()
			}

			else if (this.loginType == 2) {
				this.resetPassword()
			}
		}
	}

	/**
	 * Hides login hint as soon as the user starts typing in the email input field
	 * @param value Input value of email input field
	 */
	emailInput(value){
		if(this.message.show){
			if(value != ""){
				this.hideMessage()
			}
		}
	}

	resolved(captchaResponse: string) {
		this.captchaResponse = captchaResponse
	}

	signInWithGoogle(credential): void {
		if(credential == null){
			console.error("No login credentials found")
			return
		}

		this.apiService.oneAccountPerPerson(this.user.email).then(() => {
			this.hideMessage()
			this.loading = true

			return this.googleLogin(credential)
		})

		.catch((err) => {
			// User cancelled login or did not fully authorize.
			this.loading = false
			this.showMessage("error", err)
		})
	}

	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {		
		if (event.key == "Enter") {
			if (this.loginType == 0) {
				this.goToLoginFinal()
			}	

			else if (this.loginType == 1) {
				if (this.captcha) {
					this.captchaRef.execute()
				}

				else {
					this.login('test')
				}
			}

			else if (this.loginType == 2) {
				this.resetPassword()
			}
		}
	}

	// The timeout is here to see the loading animation and provide a visual cue, in case connection is fast
	loginLoadingAnimation() {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(true)
			}, 200)
		})
	}
}