import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core'

@Component({
 	selector: 'progressbar',
  	templateUrl: 'progressbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
  	styleUrls: ['progressbar.component.scss']
})

export class ProgressBarComponent implements OnInit {
    @Input() progress
    @Input() total
    @Input() class
	  @Input() minProgress = 0
    @Input() width: number | undefined
	  @Input() label:boolean = false

    constructor() {
        
    }

    ngOnInit(): void {
        
    }

    getPercentage() {
      	return Math.max(this.minProgress, this.progress * 100 / this.total)
    }
}