import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from "@angular/core"
import { ApiService } from "@services/api.service"
import { DoActionBeforeModalParameters } from "@services/modal.service"
import { UserService } from "@services/user.service"

@Component({
    selector: "do-action-before",
    templateUrl: "./do-action-before.modal.html",
    styleUrls: ["do-action-before.modal.scss"],
})
export class DoActionBeforeModal {
    @Output() close: EventEmitter<any> = new EventEmitter()
    @Input() input: DoActionBeforeModalParameters

    public loading: boolean = false
    public error: string | undefined = undefined

    public dontShowAgain: boolean = false

    // Udate the user's modal settings if the user has selected to
    // not show the modal again and a modalId is provided.
    public get shouldUpdateUserModalSettings(): boolean {
        return this.dontShowAgain && this.input?.modalId !== undefined
    }

    constructor(
        private ref: ChangeDetectorRef,
        private readonly apiService: ApiService,
        private readonly userService: UserService
    ) {}

    ngOnInit() {
        if (this.input.title === undefined) {
            this.input.title = "Action required before continuing"
        }

        if (this.input.description === undefined) {
            this.input.description = "Are you sure you want to proceed?"
        }

        if (this.input.continueButtonText === undefined) {
            this.input.continueButtonText = "Continue"
        }
    }

    public async continue() {
        this.loading = true
        this.error = undefined

        const result = await this.input.action(this.dontShowAgain)

        await this.updateUserModalSettings()

        this.loading = false

        if (result.success) {
            this.close.emit()
            if (this.input.afterAction !== undefined) {
                this.input.afterAction()
            }
        } else {
            this.error = result.error
        }

        this.ref.detectChanges()
    }

    public async closeModal() {
        if (this.input.cancel !== undefined) {
            this.loading = true
            this.error = undefined

            await this.input.cancel(this.dontShowAgain)
            await this.updateUserModalSettings()

            this.loading = false
        }

        this.close.emit()
    }

    private async updateUserModalSettings() {
        if (
            this.dontShowAgain === undefined ||
            this.input?.modalId === undefined ||
            this.shouldUpdateUserModalSettings === false
        ) {
            return
        }

        await this.apiService.authRequest(
            "/user/setModalSetting",
            { modalId: this.input?.modalId },
            "primary",
            true
        )

        this.userService.setModalSettings(this.input?.modalId, true)
    }

    @HostListener("document:keyup", ["$event"])
    async handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key === "Enter") {
            await this.continue()
        }
    }
}
