<div class="modal-title">Resize section</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<ngx-slider
    [(value)]="resizeLength"
    [options]="sectionLengthOptions"></ngx-slider>
<a-button
    class="submit-btn"
    label="Save"
    width="100%"
    (click)="resizeSection()">
    ></a-button
>
