import { Injectable } from '@angular/core'

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http'

import { ApiService } from './api.service'
import { TokenService } from './token.service'
import { Observable } from 'rxjs'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public apiService: ApiService, public tokenService:TokenService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.tokenService.getToken()

        if (token != null && token != '') { 
            request = request.clone({
                setHeaders: {
                    Authorization: token
                }
            })
        }
    
        return next.handle(request)
    }
}