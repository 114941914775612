import { Component, OnInit } from "@angular/core"
import { ModalService } from "@services/modal.service"
import { Misc } from "@common-lib/modules/misc"
import { InstrumentsDownloadService } from "@services/instruments/instrumentsDownload.service"

@Component({
    selector: "resume-download-all-instruments",
    templateUrl: "./resume-download-all-instruments.component.html",
    styleUrls: ["resume-download-all-instruments.component.scss"],
})
export class ResumeDownloadAllInstrumentsComponent implements OnInit {
    constructor(
        private modalService: ModalService,
        private instrumentsDownload: InstrumentsDownloadService
    ) {}

    spaceIsAvailable = true
    loading = false
    error
    additionalSpaceNecessary

    ngOnInit() {}

    async confirmResumeDownload() {
        this.loading = true
        this.error = null

        try {
            const res =
                await this.instrumentsDownload.getAdditionalSpaceForAllInstrumentDownload()

            this.additionalSpaceNecessary = Misc.formatBytes(
                res.additionalSpaceNecessary,
                "GB",
                2
            )
            this.spaceIsAvailable =
                Math.abs(
                    res.diskStats.spaceToFreeUntilDiskSpaceRequirementIsMet
                ) > this.additionalSpaceNecessary

            if (this.spaceIsAvailable) {
                this.instrumentsDownload.downloadAllInstruments()
                this.closeModal(false)
            }
        } catch (err) {
            console.log(err)
            this.error =
                "There was an error while gathering the instrument information. " + err
        }

        this.loading = false
    }

    closeModal(resumeDownloadAllInstruments = true) {
        localStorage.setItem(
            "resumeDownloadAllInstruments",
            resumeDownloadAllInstruments.toString()
        )
        this.modalService.modals.resumeDownloadAllInstruments.next(null)
    }
}
