import {
    MATERIAL_VARIATIONS_CATEGORIES,
    LAYERS,
    PACING_CATEGORIES,
    SECTION_TYPES,
    PERCUSSION_FUNCTIONS,
    ACCOMPANIMENT_PACK_SECTIONS,
    BAR_COUNTS,
    GP_DURATION_OPTIONS,
} from "../constants/constants"

export type MaterialVariationsCategory =
    (typeof MATERIAL_VARIATIONS_CATEGORIES)[number]
export type LayerTypeValue =
    | "Accompaniment"
    | "Bass"
    | "Ornament"
    | "Percussion"
export type CreateTrackFrom =
    | "Styles"
    | "Upload influence"
    | "Chord progression"
    | "Presets"
    | "Step by step"
    | "Import MIDI"
export type LayersValue = (typeof LAYERS)[number]
export type PacingCategory = (typeof PACING_CATEGORIES)[number]
export type SectionType = (typeof SECTION_TYPES)[number]
export type PercussionFunction = (typeof PERCUSSION_FUNCTIONS)[number]
export type AccompanimentPackSections =
    (typeof ACCOMPANIMENT_PACK_SECTIONS)[number]
export type UIContentType = "Compositions" | "Influences" | "Styles" | "Radio"
export type PatternBars = (typeof BAR_COUNTS)[number]
export enum HoveringTypeEnum {
    LEFT = "left",
    RIGHT = "right",
    CENTER = "center",
}
export type HoveringType =
    | HoveringTypeEnum.LEFT
    | HoveringTypeEnum.RIGHT
    | HoveringTypeEnum.CENTER
export enum PatternHoveringType {
    CENTER = "center",
    LEFT = "left",
    TOP_RIGHT = "topRight",
    BOTTOM_RIGHT = "bottomRight",
}

export type CompositionWorkflowType = "stepByStep" | "chordProgression"

export type Quantization = "bar" | "beat" | "halfBeat" | "timestep"

export type License =
    | "full_copyright"
    | "personal_use"
    | "limited_commercial_use"

export type DurationType = (typeof GP_DURATION_OPTIONS)[number]

export type LoadingType = {
    finished: boolean
    error?: string
}

export type Environment = "production" | "staging" | "local" | "test"
