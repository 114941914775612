import { cloneDeep } from "lodash"
import { CWKeyMode } from "../interfaces/composition-workflow.interface"
import { Misc } from "../modules/misc"

export const DIATONIC_TRIADS = {
    maj: ["I", "ii", "iii", "IV", "V", "vi", "vii o"],
    min: ["i", "ii o", "III", "iv", "v", "VI", "VII"],
    dor: ["i", "ii", "III", "IV", "v", "vi o", "VII"],
    phr: ["i", "II", "III", "iv", "v o", "VI", "vii"],
    lyd: ["I", "II", "iii", "iv o", "V", "vi", "vii"],
    mix: ["I", "ii", "iii o", "IV", "v", "vi", "VII"],
    loc: ["i o", "II", "iii", "iv", "V", "VI", "vii"],
}

export const SCALES = {
    maj: [0, 2, 4, 5, 7, 9, 11],
    min: [0, 2, 3, 5, 7, 8, 10],
    dor: [0, 2, 3, 5, 7, 9, 10],
    phr: [0, 1, 3, 5, 7, 8, 10],
    lyd: [0, 2, 4, 6, 7, 9, 11],
    mix: [0, 2, 4, 5, 7, 9, 10],
    loc: [0, 1, 3, 5, 6, 8, 10],
}

export const SHORT_SCALES_TO_SCALES_MAP = {
    maj: "major",
    min: "minor",
    dor: "dorian",
    phr: "phrygian",
    lyd: "lydian",
    mix: "mixolydian",
    loc: "locrian",
}

export const FUNCTIONS = {
    i: [0, 0],
    "#i": [0, 1],
    bi: [0, -1],
    ii: [1, 0],
    "#ii": [1, 1],
    bii: [1, -1],
    iii: [2, 0],
    "#iii": [2, 1],
    biii: [2, -1],
    iv: [3, 0],
    "#iv": [3, 1],
    biv: [3, -1],
    v: [4, 0],
    "#v": [4, 1],
    bv: [4, -1],
    vi: [5, 0],
    "#vi": [5, 1],
    bvi: [5, -1],
    vii: [6, 0],
    "#vii": [6, 1],
    bvii: [6, -1],
}

export const PITCH_CLASS_ORDER = [
    "Ab",
    "A",
    "A#",
    "Bb",
    "B",
    "B#",
    "Cb",
    "C",
    "C#",
    "Db",
    "D",
    "D#",
    "Eb",
    "E",
    "E#",
    "Fb",
    "F",
    "F#",
    "Gb",
    "G",
    "G#",
]

export const PITCH_CLASSES = {
    Fbb: 3,
    Cbb: 10,
    Gbb: 5,
    Dbb: 0,
    Abb: 7,
    Ebb: 2,
    Bbb: 9,
    Fb: 4,
    Cb: 11,
    Gb: 6,
    Db: 1,
    Ab: 8,
    Eb: 3,
    Bb: 10,
    F: 5,
    C: 0,
    G: 7,
    D: 2,
    A: 9,
    E: 4,
    B: 11,
    "F#": 6,
    "C#": 1,
    "G#": 8,
    "D#": 3,
    "A#": 10,
    "E#": 5,
    "B#": 0,
    "F##": 7,
    "C##": 2,
    "G##": 9,
    "D##": 4,
    "A##": 11,
    "E##": 6,
    "B##": 1,
}

/** constants
 * This is the set of "correct" chord degree options for a given scale. Not used in any conversion
 * but restricts list of options to select from, given a key mode.
 */
export const ROMAN_SCALE = {
    major: [
        "i",
        "bii",
        "ii",
        "biii",
        "iii",
        "iv",
        "bv",
        "v",
        "bvi",
        "vi",
        "bvii",
        "vii",
    ],
    minor: [
        "i",
        "#i",
        "ii",
        "iii",
        "#iii",
        "iv",
        "#iv",
        "v",
        "vi",
        "#vi",
        "vii",
        "#vii",
    ],
    dorian: [
        "i",
        "bii",
        "ii",
        "iii",
        "#iii",
        "iv",
        "bv",
        "v",
        "bvi",
        "vi",
        "vii",
        "#vii",
    ],
    phrygian: [
        "i",
        "ii",
        "#ii",
        "iii",
        "#iii",
        "iv",
        "bv",
        "v",
        "vi",
        "#vi",
        "vii",
        "#vii",
    ],
    lydian: [
        "i",
        "bii",
        "ii",
        "biii",
        "iii",
        "biv",
        "iv",
        "v",
        "bvi",
        "vi",
        "bvii",
        "vii",
    ],
    mixolydian: [
        "i",
        "bii",
        "ii",
        "biii",
        "iii",
        "iv",
        "bv",
        "v",
        "bvi",
        "vi",
        "vii",
        "#vii",
    ],
    locrian: [
        "i",
        "ii",
        "#ii",
        "iii",
        "#iii",
        "iv",
        "v",
        "#v",
        "vi",
        "#vi",
        "vii",
        "#vii",
    ],
}

export const ALL_UNIQUE_DEGREES = []

Object.keys(ROMAN_SCALE).forEach(mode => {
    ROMAN_SCALE[mode].forEach(degree => {
        if (!ALL_UNIQUE_DEGREES.includes(degree)) {
            ALL_UNIQUE_DEGREES.push(degree)
        }
    })
})

export const SHORTENED_SCALE_TO_SCALE_MAP = {
    major: "maj",
    minor: "min",
    dorian: "dor",
    phrygian: "phr",
    lydian: "lyd",
    mixolydian: "mix",
    locrian: "loc",
}

export const LOWEST_INTERVALS = [0, 50, 49, 46, 44, 44, 45, 32, 39, 39, 39, 39]

/**
 * Contains all the chord type vocabs for major and minor chords, as well as
 * other useful metadata, such as pitch intervals.
 */
export const CHORD_TYPE_MAPPING = {
    maj: {
        "": { pitches: [0, 4, 7], degrees: [0, 2, 4], suffix: "" },
        "5": { pitches: [0, 7], degrees: [0, 4], suffix: "5" },
        "+": { pitches: [0, 4, 8], degrees: [0, 2, 4], suffix: "aug" },
        sus2: { pitches: [0, 2, 7], degrees: [0, 1, 4], suffix: "(sus2)" },
        sus: { pitches: [0, 2, 5, 7], degrees: [0, 1, 3, 4], suffix: "(sus)" },
        "7sus2": {
            pitches: [0, 2, 7, 10],
            degrees: [0, 1, 4, 6],
            suffix: "7(sus2)",
        },
        "7sus4": {
            pitches: [0, 5, 7, 10],
            degrees: [0, 3, 4, 6],
            suffix: "7(sus4)",
        },
        M7sus2: {
            pitches: [0, 2, 7, 11],
            degrees: [0, 1, 4, 6],
            suffix: "maj7(sus)",
        },
        sus4: { pitches: [0, 5, 7], degrees: [0, 3, 4], suffix: "(sus4)" },
        jazz: {
            pitches: [0, 5, 10, 14, 7],
            degrees: [0, 3, 6, 1, 4],
            suffix: "7/9(sus4)",
        },
        add2: {
            pitches: [0, 2, 4, 7],
            degrees: [0, 1, 2, 4],
            suffix: "(add2)",
        },
        add4: {
            pitches: [0, 4, 5, 7],
            degrees: [0, 2, 3, 4],
            suffix: "(add4)",
        },
        "add#4": {
            pitches: [0, 4, 6, 7],
            degrees: [0, 2, 3, 4],
            suffix: "(add#4)",
        },
        "add#5": {
            pitches: [0, 4, 7, 8],
            degrees: [0, 2, 4, 5],
            suffix: "(add#5)",
        },
        o: { pitches: [0, 3, 6], degrees: [0, 2, 4], suffix: "dim" },
        "6": { pitches: [0, 4, 7, 9], degrees: [0, 2, 4, 5], suffix: "(6)" },
        "69": {
            pitches: [0, 4, 9, 14, 7],
            degrees: [0, 2, 5, 1, 4],
            suffix: "(6/9)",
        },
        "76": {
            pitches: [0, 4, 9, 10, 7],
            degrees: [0, 2, 5, 6, 4],
            suffix: "(7/6)",
        },
        m3: { pitches: [0, 3, 4, 7], degrees: [0, 1, 2, 4], suffix: "(addb3)" },
        "7": { pitches: [0, 4, 7, 10], degrees: [0, 2, 4, 6], suffix: "(7)" },
        "7b5": {
            pitches: [0, 4, 6, 10],
            degrees: [0, 2, 4, 6],
            suffix: "(7/b5)",
        },
        M7: { pitches: [0, 4, 7, 11], degrees: [0, 2, 4, 6], suffix: "(maj7)" },
        "+7": {
            pitches: [0, 4, 8, 10],
            degrees: [0, 2, 4, 6],
            suffix: "aug(7)",
        },
        "+M7": {
            pitches: [0, 4, 8, 11],
            degrees: [0, 2, 4, 6],
            suffix: "aug(maj7)",
        },
        "M7#11": {
            pitches: [0, 4, 11, 18, 7],
            degrees: [0, 2, 6, 3, 4],
            suffix: "(maj7/#11)",
        },
        "9": {
            pitches: [0, 4, 10, 14, 7],
            degrees: [0, 2, 6, 1, 4],
            suffix: "(9)",
        },
        b9: {
            pitches: [0, 4, 10, 13, 7],
            degrees: [0, 2, 6, 1, 4],
            suffix: "(7/b9)",
        },
        M9: {
            pitches: [0, 4, 11, 14, 7],
            degrees: [0, 2, 6, 1, 4],
            suffix: "(maj9)",
        },
        "#9": {
            pitches: [0, 4, 10, 15, 7],
            degrees: [0, 2, 6, 1, 4],
            suffix: "(7/#9)",
        },
        "+9": {
            pitches: [0, 4, 11, 14, 8],
            degrees: [0, 2, 6, 1, 4],
            suffix: "aug(maj9)",
        },
        "11": {
            pitches: [0, 10, 14, 17, 4, 7],
            degrees: [0, 6, 1, 3, 4, 2],
            suffix: "(11)",
        },
        "#11": {
            pitches: [0, 4, 10, 18, 14, 7],
            degrees: [0, 2, 6, 3, 1, 4],
            suffix: "(9/#11)",
        },
        "M#11": {
            pitches: [0, 4, 11, 18, 14, 7],
            degrees: [0, 2, 6, 3, 1, 4],
            suffix: "(maj9/#11)",
        },
        "13": {
            pitches: [0, 4, 10, 21, 14, 17, 7],
            degrees: [0, 2, 6, 5, 1, 3, 4],
            suffix: "(13)",
        },
        "13#11": {
            pitches: [0, 4, 10, 21, 18, 14, 17],
            degrees: [0, 2, 6, 5, 3, 1, 4],
            suffix: "(13/#11)",
        },
        "M13#11": {
            pitches: [0, 4, 11, 21, 18, 14, 7],
            degrees: [0, 2, 6, 5, 3, 1, 4],
            suffix: "(maj13/#11)",
        },
        "b9#11": {
            pitches: [0, 10, 13, 17, 4, 7],
            degrees: [0, 6, 1, 3, 2, 4],
            suffix: "(7/b9/#11)",
        },
    },
    min: {
        "": { pitches: [0, 3, 7], degrees: [0, 2, 4], suffix: "" },
        o: { pitches: [0, 3, 6], degrees: [0, 2, 4], suffix: "dim" },
        "5": { pitches: [0, 7], degrees: [0, 4], suffix: "5" },
        add2: {
            pitches: [0, 2, 3, 7],
            degrees: [0, 1, 2, 4],
            suffix: "(add2)",
        },
        "add2#4": {
            pitches: [0, 2, 3, 6, 7],
            degrees: [0, 1, 2, 3, 4],
            suffix: "(9/#11)",
        },
        add4: {
            pitches: [0, 3, 5, 7],
            degrees: [0, 2, 3, 4],
            suffix: "(add4)",
        },
        "add#4": {
            pitches: [0, 3, 6, 7],
            degrees: [0, 2, 3, 4],
            suffix: "(add#4)",
        },
        "add#5": {
            pitches: [0, 3, 7, 8],
            degrees: [0, 2, 4, 5],
            suffix: "(add#5)",
        },
        sus2: { pitches: [0, 2, 7], degrees: [0, 1, 4], suffix: "(sus2)" },
        sus4: { pitches: [0, 5, 7], degrees: [0, 3, 4], suffix: "(sus4)" },
        sus: { pitches: [0, 2, 5, 7], degrees: [0, 1, 3, 4], suffix: "(sus)" },
        "7sus2": {
            pitches: [0, 2, 7, 10],
            degrees: [0, 1, 4, 6],
            suffix: "7(sus2)",
        },
        "7sus4": {
            pitches: [0, 5, 7, 10],
            degrees: [0, 3, 4, 6],
            suffix: "7(sus4)",
        },
        M7sus2: {
            pitches: [0, 2, 7, 11],
            degrees: [0, 1, 4, 6],
            suffix: "maj7(sus)",
        },
        jazz: {
            pitches: [0, 5, 10, 14, 7],
            degrees: [0, 3, 6, 1, 4],
            suffix: "7/9(sus4)",
        },
        "6": { pitches: [0, 3, 7, 9], degrees: [0, 2, 4, 5], suffix: "(6)" },
        o7: { pitches: [0, 3, 6, 9], degrees: [0, 2, 4, 6], suffix: "dim(7)" },
        m7b5: {
            pitches: [0, 3, 6, 10],
            degrees: [0, 2, 4, 6],
            suffix: "(7/b5)",
        },
        "m7#5": {
            pitches: [0, 3, 8, 10],
            degrees: [0, 2, 4, 6],
            suffix: "(7/#5)",
        },
        m7: { pitches: [0, 3, 7, 10], degrees: [0, 2, 4, 6], suffix: "(7)" },
        "m7#4": {
            pitches: [0, 3, 6, 7, 10],
            degrees: [0, 2, 3, 4, 6],
            suffix: "(7/#11)",
        },
        m7M3: {
            pitches: [0, 3, 4, 10, 7],
            degrees: [0, 1, 2, 6, 4],
            suffix: "(7/maj3)",
        },
        M7: {
            pitches: [0, 3, 7, 11],
            degrees: [0, 2, 4, 6],
            suffix: "(maj7)",
        },
        M7M3: {
            pitches: [0, 3, 4, 11, 7],
            degrees: [0, 1, 2, 6, 4],
            suffix: "(maj7/maj3)",
        },
        M9: {
            pitches: [0, 3, 11, 14, 7],
            degrees: [0, 2, 6, 1, 4],
            suffix: "(maj9)",
        },
        m9: {
            pitches: [0, 3, 14, 10, 7],
            degrees: [0, 2, 6, 1, 4],
            suffix: "(9)",
        },
        "11": {
            pitches: [0, 3, 10, 17, 14, 7],
            degrees: [0, 2, 6, 3, 1, 4],
            suffix: "(11)",
        },
        "13": {
            pitches: [0, 3, 10, 21, 14, 17, 7],
            degrees: [0, 2, 6, 5, 1, 3, 4],
            suffix: "(13)",
        },
    },
}
/**
 * This method iterates through the chords and creates an object of chords that are reducable
 * Meaning for a major chord, all the chords that have the same base pitched [0,4,7] will be in the returned object
 * under the key of the chord that is reducable.
 */
export const REDUCABLE_CHORDS = (function () {
    const chords = {}
    for (const quality in CHORD_TYPE_MAPPING) {
        chords[quality] = {}
        for (const chord in CHORD_TYPE_MAPPING[quality]) {
            chords[quality][chord] = []
            const chordInfo = CHORD_TYPE_MAPPING[quality][chord]
            for (const tempChord in CHORD_TYPE_MAPPING[quality]) {
                if (tempChord === chord) {
                    continue
                }
                const tempChordInfo = CHORD_TYPE_MAPPING[quality][tempChord]

                if (
                    chordInfo.pitches.every(pitch =>
                        tempChordInfo.pitches.includes(pitch)
                    )
                ) {
                    chords[quality][chord].push(tempChord)
                }
            }
        }
    }

    return chords
})()

/**
 * Constants used in ME backend to compute the roman -> symbol conversion for
 * all the different key, mode, chord combinations.
 */
export const ROMAN_LIST = ["i", "ii", "iii", "iv", "v", "vi", "vii"]
export const SCALES31 = {
    major: [0, 5, 10, 13, 18, 23, 28],
    minor: [0, 5, 8, 13, 18, 21, 26],
    dorian: [0, 5, 8, 13, 18, 23, 26],
    phrygian: [0, 3, 8, 13, 18, 21, 26],
    lydian: [0, 5, 10, 15, 18, 23, 28],
    mixolydian: [0, 5, 10, 13, 18, 23, 26],
    locrian: [0, 3, 8, 13, 16, 21, 26],
}
export const PITCH31_LIST = [
    "C",
    "Dbb",
    "C#",
    "Db",
    "C##",
    "D",
    "Ebb",
    "D#",
    "Eb",
    "D##",
    "E",
    "Fb",
    "E#",
    "F",
    "Gbb",
    "F#",
    "Gb",
    "F##",
    "G",
    "Abb",
    "G#",
    "Ab",
    "G##",
    "A",
    "Bbb",
    "A#",
    "Bb",
    "A##",
    "B",
    "Cb",
    "B#",
]

export const PITCH31_LIST_MINOR = [...PITCH31_LIST].map(v => v + "m")

export type ChordInfo = {
    pitches: number[]
    degrees: number[]
    suffix: string
}

export const convertDegreeToChordSymbol = (
    numeral: string,
    keyMode: CWKeyMode,
    pitchClass: string,
    chordOffset = 0
) => {
    const quality = getChordQuality(numeral)
    const degree = ROMAN_LIST.indexOf(numeral.toLowerCase())

    const scale = SCALES31[keyMode]
    const keyOffset = PITCH31_LIST.indexOf(pitchClass)

    const scaleDegreeOffset = scale[degree]
    let finalIndex = Misc.pythonMod(
        keyOffset + scaleDegreeOffset + chordOffset,
        31
    ) // ????

    let chordSymbol = PITCH31_LIST[finalIndex]

    // 3 characters means it is a case of double sharp or flat, and we want to find the enharmonic
    if (chordSymbol.length === 3) {
        const symbolIndex = PITCH_CLASSES[chordSymbol]

        for (const pitchClass in PITCH_CLASSES) {
            if (
                PITCH_CLASSES[pitchClass] === symbolIndex &&
                pitchClass.length < 3
            ) {
                chordSymbol = pitchClass
                break
            }
        }
    }

    return chordSymbol
}

export const convertNumeralToChord = (
    pitchClass: string,
    keyMode: CWKeyMode,
    chord: string // e.g. "iv m7". It holds the numeral and the chord type (if any is given)
): { chord: string; chordInfo: ChordInfo } => {
    const splits = chord.split(" ")
    const chordDegree = splits[0]
    const chordType = splits.length === 1 ? "" : splits[1]

    try {
        let chordOffset: number = 0
        let romanNumeral: string = chordDegree.slice(1, chordDegree.length)

        if (chordDegree.includes("#")) {
            chordOffset = 2
        } else if (chordDegree.includes("b")) {
            chordOffset = -2
        } else {
            romanNumeral = chordDegree
        }

        const chordQuality = getChordQuality(romanNumeral)

        const chordTypeMap = CHORD_TYPE_MAPPING[chordQuality]

        const newChordSuffix = chordTypeMap[chordType]["suffix"]

        const chordSymbol = convertDegreeToChordSymbol(
            romanNumeral,
            keyMode,
            pitchClass,
            chordOffset
        )

        let finalChord =
            chordQuality === "maj" ? chordSymbol : chordSymbol + "m"

        if (newChordSuffix.length > 0) {
            finalChord += " " + newChordSuffix
        }

        return {
            chord: finalChord,
            chordInfo: chordTypeMap[chordType],
        }
    } catch (e) {
        console.error("Couldnt convert chord: ", chord)

        throw e
    }
}

export const isValidChordNumeral = (chord: string) => {
    const chordSplit = chord.split(" ")

    const degree = chordSplit[0]
    const suffix = chordSplit.length > 1 ? chordSplit[1] : ""
    const quality = getChordQuality(chord)

    const temp = degree.toLowerCase()

    if (!ALL_UNIQUE_DEGREES.includes(temp) || degree.includes("B")) {
        console.warn("Invalid chord degree: ", degree)
        return false
    }

    if (
        CHORD_TYPE_MAPPING[quality][suffix] === undefined ||
        chordSplit.length > 2
    ) {
        console.warn("Invalid chord suffix: ", chord)
        return false
    }

    return true
}

export const convertNumeralToDisplay = (chord: string) => {
    const chordSplit = chord.split(" ")
    const degree = chordSplit[0]
    const quality = getChordQuality(degree)
    const suffix = chordSplit.length > 1 ? chordSplit[1] : ""

    if (suffix === "") {
        return degree
    }

    return degree + " " + suffix
}

export const getChordSymbolsOptions = (pitchClass: string, mode: CWKeyMode) => {
    const chordDegreeOptions = getChordDegreesForQuality("maj", mode).concat(
        getChordDegreesForQuality("min", mode)
    )

    const chordSymbolOptions = chordDegreeOptions
        .map(option => {
            const chord = convertNumeralToChord(
                pitchClass,
                mode,
                option.name
            ).chord

            return {
                name: chord,
                value: option.value,
                optgroup: option.optgroup,
            }
        })
        .sort((a, b) => {
            const tempA = a.name.replace("m", "")
            const tempB = b.name.replace("m", "")

            return (
                PITCH_CLASS_ORDER.indexOf(tempA) >
                PITCH_CLASS_ORDER.indexOf(tempB)
            )
        })

    return chordSymbolOptions
}

export const getChordDegreesForQuality = (
    chordQuality: "maj" | "min",
    mode: CWKeyMode
) => {
    const romanNumerals = cloneDeep(ROMAN_SCALE[mode])

    const values = romanNumerals.map(r => {
        const numeral = transformChordDegree(r, chordQuality)

        return {
            name: numeral,
            value: numeral,
            optgroup: chordQuality === "maj" ? "Major" : "Minor",
        }
    })

    return values
}

export const transformChordDegree = (
    chordDegree: string,
    chordQuality: "maj" | "min"
) => {
    if (chordQuality === "min") {
        return chordDegree.toLowerCase()
    }

    const hasFlat = chordDegree[0] === "b"

    let numeral = hasFlat
        ? chordDegree.slice(1, chordDegree.length)
        : chordDegree

    numeral = numeral.toUpperCase()

    if (hasFlat) {
        numeral = "b" + numeral
    }

    return numeral
}

export const getChordQuality = (romanNumeral: string) => {
    const tempNumeral = romanNumeral.replace("b", "").split(" ")[0]

    return tempNumeral === tempNumeral.toUpperCase() ? "maj" : "min"
}

export const convertNumeralAndTypeToSuffix = (
    romanNumeral: string,
    chordType: string
): string => {
    const chordQuality = getChordQuality(romanNumeral)

    const chordTypeMap = CHORD_TYPE_MAPPING[chordQuality]
    const newChordSuffix = chordTypeMap[chordType]["suffix"]

    return newChordSuffix
}

export const formatChordSuffixForDisplay = (suffix: string) => {
    const newName = suffix.replace("(", " ").replace(")", "")

    if (newName.length > 0 && newName[0] === " ") {
        return newName.slice(1)
    }

    return newName
}

export const getSuffixFromChord = (chord: string, useSuffix: boolean) => {
    const quality = getChordQuality(chord) as "maj" | "min"

    const key = chord.split(" ").length === 1 ? "" : chord.split(" ")[1]
    const data = CHORD_TYPE_MAPPING[quality][key]

    return {
        value: {
            value: key,
            quality,
            suffix: useSuffix ? data.suffix : key,
        },
        name: useSuffix ? formatChordSuffixForDisplay(data.suffix) : key,
    }
}

export const getSuffixesByNumeral = (
    quality: "maj" | "min"
): { value: string; displayValue: string }[] => {
    const chordTypeMap = CHORD_TYPE_MAPPING[quality]

    const suffixes = Object.keys(chordTypeMap).map(chordType => {
        return {
            displayValue: chordType,
            value: chordType,
        }
    })

    return suffixes
}

/**
 * splits the chord symbol string into the main text and the superscript text
 * @param chordSymbol
 * @returns Object {
 *      main: string, the main chord symbol text
 *      superstring: string, the superstring part of the chord symbol
 * }
 * Returns an empty superstring as part of the object, when no superscript
 * text could be identified.
 */
export const getMainAndSuperscriptTextFromChordSymbol = (
    chordSymbol: string
): {
    main: string
    superscript: string
} => {
    if (!chordSymbol?.length) {
        return undefined
    }

    if (!chordSymbol.includes("(")) {
        return {
            main: chordSymbol,
            superscript: "",
        }
    }

    const textParts = chordSymbol.split("(")
    const mainText = textParts[0]

    let superscript = ""

    if (textParts.length === 2) {
        superscript = textParts[1].replace(")", "")
    }

    return {
        main: mainText,
        superscript: superscript,
    }
}

export const getPitchClassAndChordTypeFromChordSymbol = (
    chordSymbol: string
): {
    pitchClass: string
    chordType: string
} => {
    const mainText = chordSymbol.replace("(", "").replace(")", "")

    let pitchClass = mainText.slice(0, 1)
    let chordType = mainText.slice(1, mainText.length)

    if (
        (mainText.includes("b") && mainText.indexOf("b") <= 1) ||
        (mainText.includes("#") && mainText.indexOf("#") <= 1)
    ) {
        pitchClass = mainText.slice(0, 2)
        chordType = mainText.slice(2, mainText.length)
    }

    // check if the chordType only contains spaces which means it's empty
    if (!/\S/.test(chordType)) {
        chordType = ""
    }

    return {
        pitchClass: pitchClass,
        chordType: chordType,
    }
}

/**
 * returns a re-formatted chord symbol text
 * @param chordSymbol string
 * @returns string, the full chord symbol text with the pitchClass and
 *          chordType part being joined with a space
 */
export const getChordSymbolTextWithoutParenthesis = (chordSymbol: string) => {
    const splitChordSymbol =
        getPitchClassAndChordTypeFromChordSymbol(chordSymbol)

    let fullText = splitChordSymbol.pitchClass

    if (splitChordSymbol.chordType.length) {
        fullText =
            splitChordSymbol.pitchClass + " " + splitChordSymbol.chordType
    }

    return fullText
}
