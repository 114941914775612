import { Injectable } from "@angular/core"
import SectionOperation from "@common-lib/classes/score/operation"
import { SegmentedScoreSection } from "@common-lib/interfaces/score/segmentedscore"
import {
    TemplateChord,
    TemplateKeySignature,
    TemplateScore,
} from "@common-lib/interfaces/score/templateScore"
import { ScoreType } from "@common-lib/types/score"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { ApiService } from "@services/api.service"
import { TokenService } from "@services/token.service"
import { ParentClass } from "../../parent"
import {
    GenerateLayerBody,
    GetSourceSectionChordsBody,
    InpaintSectionBody,
} from "../../../../../common-lib/general/interfaces/api/editor.api"
import {
    ScoresMetadataSchema,
    Trust,
} from "@common-lib/interfaces/db-schemas/trainingsets/scoresMetadata"

@Injectable()
export class EditorHttpService extends ParentClass {
    constructor(public api: ApiService, private tokenService: TokenService) {
        super()
    }

    public async getTemplateScore(
        compositionID,
        contentType: ScoreType
    ): Promise<{
        template?: TemplateScore
        maxBarLength?: number
        error?: string
        lastSectionDuration?: string
        isCompatibleWithRegeneration?: boolean
    }> {
        try {
            const res = await this.api.authRequest(
                "/content/streamMIDI",
                {
                    contentID: compositionID,
                    type: contentType,
                },
                "primary",
                true
            )

            return {
                template: res.midi,
                lastSectionDuration:
                    res.midi.lastSectionDuration !== undefined &&
                    featureFlags.sectionModificationForCWCompositions
                        ? res.midi.lastSectionDuration
                        : res.lastSectionDuration,
                maxBarLength: res.maxBarLength,
                isCompatibleWithRegeneration: res.isCompatibleWithRegeneration,
            }
        } catch (err) {
            return {
                error: err,
            }
        }
    }

    public async harmonicAnalysis(section: SegmentedScoreSection): Promise<{
        chords?: TemplateChord[]
        keySignatures?: TemplateKeySignature[]
        error?: string
    }> {
        try {
            const res = await this.api.authRequest(
                "/editor/harmonicAnalysis",
                {
                    section,
                },
                "primary",
                true
            )

            return {
                chords: res.chordProgression,
                keySignatures: res.keySignatures,
            }
        } catch (err) {
            return {
                error: err,
            }
        }
    }

    public async inpaintSection(
        request: InpaintSectionBody,
        controller?: AbortController
    ): Promise<SegmentedScoreSection> {
        const res = await this.api.fetchRequest({
            url: "/editor/inpaintSection",
            parameters: request,
            urlType: "primary",
            type: "POST",
            token: this.tokenService.getToken(),
            controller,
        })

        // console.log("Inpaint section", { request, res })

        if (res.result === 0) {
            this.api.handleError(res.message)

            throw new Error(res.message)
        }

        return res.section
    }

    public async getSourceSectionChords(request: GetSourceSectionChordsBody) {
        try {
            const res = await this.api.authRequest(
                "/editor/getSourceSectionChords",
                request,
                "primary",
                true
            )

            return res?.chordProgression
        } catch (err) {
            return {
                error: err,
            }
        }
    }

    public async generateLayer(
        request: GenerateLayerBody,
        controller?: AbortController
    ): Promise<SegmentedScoreSection> {
        request.section.layers = request.section.layers.filter(
            l =>
                l.value !== request.layerToGenerate &&
                !l.value.includes("Custom Percussion")
        )
        request.type = "editor"

        const res = await this.api.fetchRequest({
            url: "/compositionWorkflow/generateLayerWithLLM",
            parameters: request,
            urlType: "primary",
            type: "POST",
            token: this.tokenService.getToken(),
            controller,
        })

        // console.log("Generate layer", res, "request", request)

        if (res.result === 0) {
            this.api.handleError(res.message)

            throw new Error(res.message)
        }

        return res.score
    }

    public async saveTemplateScore(
        templateScore: TemplateScore,
        mixingOption,
        scoreWasEdited: boolean,
        musicEngineOperations: SectionOperation[]
    ): Promise<boolean> {
        const newBPM = templateScore.tempoMap[0][1]

        await this.api.authRequest(
            "/composition/saveMIDI",
            {
                midi: templateScore,
                newBPM: newBPM,
                userEdited: scoreWasEdited,
                musicEngineOperations: musicEngineOperations,
                mixingOption: mixingOption,
            },
            "primary",
            false
        )

        return true
    }

    public async saveTrainingsetTemplateScore(
        templateScore: TemplateScore
    ): Promise<boolean> {
        const trainingsetID = templateScore?.compositionID

        await this.api.authRequest(
            "/trainingset/saveTrainingsetScore",
            {
                score: templateScore,
                trainingsetID: trainingsetID,
            },
            "primary",
            false
        )

        return true
    }

    public async getTrainingset(
        trainingsetID: string
    ): Promise<ScoresMetadataSchema> {
        const res = await this.api.authRequest(
            "/trainingset/getTrainingset",
            {
                trainingsetID,
            },
            "primary",
            true
        )

        return res.trainingset
    }

    public async saveTrainingsetLabels(trainingsetID: string, labels: Trust) {
        const res = await this.api.authRequest(
            "/trainingset/saveTrainingsetLabels",
            {
                trainingsetID,
                labels,
            },
            "primary",
            true
        )

        return res
    }
}
