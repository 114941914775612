import { Injectable, EventEmitter } from "@angular/core"
import {
    BehaviorSubject,
    interval,
    Observable,
    ReplaySubject,
    Subject,
    Subscription,
    tap,
} from "rxjs"
import { ApiService } from "../api.service"
import { Router } from "@angular/router"
import { AnimationLoopService } from "@services/animationloop.service"
import { Misc } from "@common-lib/modules/misc"
import { StreamingBufferMetadata } from "@common-lib/interfaces/api/sockets"
import { Composition } from "@common-lib/classes/general/composition"
import { ParentClass } from "../../parent"
import {
    playerActions,
    playerQuery,
} from "../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { AudioService } from "./audio.service"
import { cloneDeep } from "lodash"
import { HttpEventType } from "@angular/common/http"
import { TokenService } from "@services/token.service"
import { featureFlags } from "@common-lib/utils/feature-flags"

let AudioContext = window["AudioContext"] || window["webkitAudioContext"]

interface CachedBuffer {
    buffer
    metadata: StreamingBufferMetadata
}

interface Buffers {
    [order: string]: CachedBuffer
}

@Injectable()
export class StreamingService extends ParentClass {
    private currentRequestTimestamp = 0
    private triggeredRetry = false

    private MAXIMUM_CACHE = featureFlags?.useMinimumAudioBufferCacheSize
        ? 1
        : 40

    public trackEndedEE: EventEmitter<object> = new EventEmitter()

    // Private variables
    private tracksAudioCache: {
        [contentID: string]: {
            buffers: Buffers
            duration: number
            sourceID: string
            isComplete: boolean
            dateAdded: Date
        }
    } = {}

    private audioUnlocked = false

    // MediaSource API Playback
    private audioPlayer = new Audio()
    private mediaSource: MediaSource = this.isIOS() ? null : new MediaSource()
    private preloadedRange: Array<CachedBuffer> = []
    private sourceBuffer: SourceBuffer
    private mediaSourceSupport = false
    private mimeType = "audio/mpeg"

    // Web Audio API Playback
    private masterBus: AudioContext = new AudioContext()
    private audioContextGain: GainNode = this.masterBus.createGain()
    private soundSource: AudioBufferSourceNode

    private playAfterLoading = false

    private streamPromise = Promise.resolve()

    private currentTimeDuringPlay = 0
    private timeElapsedDuringPlay = 0

    private lastLoopTimeElapsed = 0
    private nbOfLoopsWithoutProgress = 0

    private streamController: AbortController | undefined

    constructor(
        private animationLoopService: AnimationLoopService,
        private tokenService: TokenService,
        private router: Router,
        private apiService: ApiService
    ) {
        super()

        this.animationLoopService.addFunctionToLoop(
            "constructor",
            this.setRefresh.bind(this)
        )

        this.unlockAudio()

        this.mediaSourceSupport = this.supportsMediaSourceType("audio/mpeg") // for browsers that don't support MediaSource API or don't support the MP3 codec, fall back to Web Audio API

        this.subscribe(this.apiService.socket, this.socketUpdate.bind(this))
    }

    /**
     *
     * Audio playback functions
     *
     */
    public async play(seekTime?) {
        if (!seekTime) {
            seekTime = playerQuery.timeElapsed
        }

        try {
            this.playAfterLoading = true

            this.currentTimeDuringPlay = this.masterBus.currentTime
            this.timeElapsedDuringPlay = playerQuery.timeElapsed

            // actual playback
            if (this.mediaSourceSupport) {
                if (this.mediaSourceSupport && seekTime != undefined) {
                    this.audioPlayer.currentTime = seekTime
                }
            } else if (this.tracksAudioCache[playerQuery.contentID] != null) {
                this.masterBus.resume()
                this.playAudioBuffer(
                    this.tracksAudioCache[playerQuery.contentID].buffers["0"]
                        .buffer,
                    playerQuery.timeElapsed
                )
            }

            playerActions.setStatus("StreamingService.play()", "playing")
        } catch (e) {
            console.error(e)
        }
    }

    public async pause() {
        try {
            if (this.mediaSourceSupport && !this.audioPlayer.paused) {
                await this.audioPlayer.pause()
            } else {
                this.pauseWebAudio()
            }
        } catch (e) {
            console.error(e)
        }
    }

    public setVolumeControl(volume) {
        playerActions.setVolume("StreamingService.setVolumeControl", volume)

        if (this.mediaSourceSupport) {
            this.audioPlayer.volume = volume
        } else if (this.audioContextGain != null) {
            this.audioContextGain.gain.setValueAtTime(volume, 0)
        }
    }

    public async stop() {
        if (playerQuery.status !== "playing") {
            return
        }

        this.streamPromise = Promise.resolve()

        if (this.mediaSourceSupport) {
            this.audioPlayer.currentTime = 0
        } else {
            this.pauseWebAudio()
        }

        await this.pause()
    }

    public get publicPlayerMode() {
        return this.router.url.split("?")[0].includes("/publicPlayer")
    }

    /**
     * Used to seek in a track
     * @param timeInSeconds Number - seconds to which the track should jump to
     * @param play boolean - indicates if the track should be played after seeking
     */
    public async seek(timeInSeconds) {
        const resumePlaying = playerQuery.status === "playing"

        await this.pause()

        playerActions.setTimeElapsed(
            "seek",
            Math.min(playerQuery.getValue().offlineAudioLoaded, timeInSeconds)
        )

        if (resumePlaying) {
            await this.play(timeInSeconds)
        }
    }

    public async loadNewTrack(
        origin: string,
        composition: Composition,
        play,
        force,
        retries = 4
    ): Promise<any> {
        if (composition._id !== playerQuery.contentID) {
            await this.pause()
            playerActions.setTimeElapsed("loadNewTrack", 0)
        }

        this.clearPreloadedRange()

        this.masterBus.resume()

        this.playAfterLoading = play

        let isCached = this.isCached(composition._id)
        let isIncompleteCache =
            isCached && !this.tracksAudioCache[composition._id].isComplete

        try {
            if (isCached && !force && !isIncompleteCache) {
                if (!play) {
                    return this.resetPlaybackToZero()
                }

                playerActions.setOfflineAudioLoading(
                    "loadNewTrack",
                    playerQuery.duration
                )

                return this.play()
            }

            this.removeFromCache(composition._id)

            if (this.mediaSourceSupport) {
                this.mediaSource = this.isIOS() ? null : new MediaSource()

                this.audioPlayer.onerror = async (
                    event,
                    source,
                    lineno,
                    colno,
                    error
                ) => {}

                this.audioPlayer.onended = () => {
                    this.trackEndedEE.next({
                        sourceID: playerQuery.contentID,
                        shouldPlay: playerQuery.status === "playing",
                    })
                }

                this.mediaSource.addEventListener("sourceopen", async () => {
                    let retries = 8000

                    while (this.mediaSource.readyState !== "open") {
                        await Misc.wait(0.05)

                        retries -= 1

                        if (retries === 0) {
                            return
                        }
                    }

                    this.sourceBuffer = this.mediaSource.addSourceBuffer(
                        this.mimeType
                    ) // initialize source buffer for the next audio track

                    if (!this.isCached(playerQuery.contentID)) {
                        try {
                            this.contentStream(true)
                        } catch (e) {
                            console.error(e)
                        }
                    }
                })

                this.audioPlayer.src = URL.createObjectURL(this.mediaSource)

                await this.stop()
            } else {
                this.contentStream(true)

                return
            }
        } catch (err) {
            console.error(err)
            console.error(
                "Could not load new track. Please try refreshing the page."
            )

            if (retries == 0) {
                return Promise.resolve()
            }

            await Misc.wait((5 - retries) * 2)

            return this.loadNewTrack(
                "loadNewTrack catch",
                composition,
                this.playAfterLoading,
                true,
                retries - 1
            )
        }
    }

    public removeFromCache(compositionID) {
        if (this.tracksAudioCache[compositionID] == null) {
            return
        }

        delete this.tracksAudioCache[compositionID]
    }

    private socketUpdate(socket) {
        if (socket == null) {
            return
        }

        socket.on("audioStreamStart", (data, callback) => {
            // This if statement is added to be backwards compatible in case the callback is not provided by the backend
            if (callback === undefined) {
                console.error("callback is undefined")
                callback = () => {}
            }

            if (this.currentRequestTimestamp !== data.currentRequestTimestamp) {
                callback(false)
            }

            if (!this.tracksAudioCache[playerQuery.contentID]) {
                if (
                    Object.keys(this.tracksAudioCache).length >
                    this.MAXIMUM_CACHE
                ) {
                    this.onMaximumCacheExceeded()
                }

                this.setCacheForContentID(
                    playerQuery.contentID,
                    data.compositionDuration
                )
            }

            if (!this.mediaSourceSupport) {
                this.stop()
            }

            callback(true)
        })

        // cache buffers here
        socket.on("audioIsStreaming", (data, callback) => {
            // This if statement is added to be backwards compatible in case the callback is not provided by the backend
            if (callback === undefined) {
                callback = () => {}
            }

            if (
                !(data.buffer instanceof ArrayBuffer) ||
                this.currentRequestTimestamp !== data.currentRequestTimestamp
            ) {
                this.streamPromise = Promise.resolve()
                callback(false)
                return
            }

            this.streamPromise = this.streamPromise
                .then(async () => {
                    await this.receiveBufferFromSocketIO(data.buffer)

                    callback(true)
                })

                .catch(err => {
                    callback(true)

                    if (
                        this.currentRequestTimestamp !=
                            data.currentRequestTimestamp ||
                        this.triggeredRetry
                    ) {
                        return Promise.resolve()
                    }

                    this.triggeredRetry = true

                    return this.loadNewTrack(
                        "socketUpdate",
                        playerQuery.content as Composition,
                        this.playAfterLoading,
                        true
                    )
                })
        })

        socket.on("audioStreamEnd", msg => {})
    }

    private async resetPlaybackToZero() {
        await this.pause()
        await this.seek(0)

        await this.stop()

        playerActions.setStatus(
            "StreamingService.resetPlaybackToZero",
            "paused"
        )

        this.playAfterLoading = false
    }

    private setCacheForContentID(contentID: string, duration) {
        if (!this.tracksAudioCache[contentID]) {
            this.tracksAudioCache[contentID] = {
                duration: duration,
                dateAdded: new Date(),
                buffers: {},
                sourceID: contentID,
                isComplete: false,
            }
        }
    }

    private async contentStream(setRequestTimestamp, retries = 10) {
        let apiRoute = !this.publicPlayerMode
            ? "/content/stream"
            : "/content/stream/public"

        if (playerQuery.contentID == null) {
            return Promise.resolve()
        }

        let requestParams = {
            contentID: playerQuery.contentID,
            type: playerQuery.content.contentType,
            chunkUp: this.mediaSourceSupport,
        }

        if (setRequestTimestamp) {
            this.currentRequestTimestamp = Date.now()
            requestParams["currentRequestTimestamp"] =
                this.currentRequestTimestamp
        }

        playerActions.setOfflineAudioLoading("contentStream", 0)

        try {
            if (this.streamController !== undefined) {
                this.streamController.abort()
            }

            this.streamController = new AbortController()

            const response = await this.apiService.fetchRequest({
                url: apiRoute,
                parameters: requestParams,
                urlType: "primary",
                type: "POST",
                controller: this.streamController,
                token: this.tokenService.getToken(),
            })

            if (response.result === 0) {
                throw new Error(response.body.message)
            }
        } catch (e) {
            if (e.name === "AbortError") {
                console.log("Aborted stream request")
                return
            }

            if (
                this.currentRequestTimestamp !==
                requestParams["currentRequestTimestamp"]
            ) {
                return
            }

            await Misc.wait(Math.pow(2, 11 - retries))

            this.contentStream(setRequestTimestamp, retries - 1)
        }
    }

    private decodeAndAddToCache(arrayBuffer, metaData, duration) {
        return new Promise((resolve, reject) => {
            this.masterBus.decodeAudioData(
                arrayBuffer,
                audioBuffer => {
                    resolve(audioBuffer)
                },
                error => {
                    reject("Error when decoding buffer: " + error)
                }
            )
        }).then(audioBuffer => {
            return this.addToCache(audioBuffer, metaData, duration)
        })
    }

    private async receiveBufferFromSocketIO(data): Promise<any> {
        const result = await this.splitUpMetaDataFromArrayBuffer(data)
        const arrayBuffer = result.arrayBuffer
        const metaData: StreamingBufferMetadata = result.metaData
        const isCached: boolean = this.isCached(metaData.sourceID)

        if (isCached) {
            return
        }

        const bufferObject: CachedBuffer = this.mediaSourceSupport
            ? await this.addToCache(
                  arrayBuffer,
                  metaData,
                  data.compositionDuration
              ) // Media Source
            : await this.decodeAndAddToCache(
                  arrayBuffer,
                  metaData,
                  data.compositionDuration
              ) // Web Audio API

        playerActions.setOfflineAudioLoading(
            "receiveBufferFromSocketIO",
            metaData.duration + playerQuery.getValue().offlineAudioLoaded
        )

        if (bufferObject.metadata.sourceID != playerQuery.contentID) {
            return
        }

        // Logic for Web Audio API
        if (bufferObject.metadata.isFirst) {
            playerActions.setStatus(
                "receiveBufferFromSocketIO",
                this.playAfterLoading ? "playing" : "paused"
            )

            if (this.playAfterLoading) {
                await this.play()
            }
        }
    }

    private async handleUpdateForPausedState(setStatus: boolean) {
        if (this.mediaSourceSupport && this.audioPlayer.paused === false) {
            await this.clearPreloadedRange()

            if (setStatus) {
                playerActions.setStatus("handleUpdateForPausedState", "paused")
            }
        }

        return
    }

    private async handleUpdateForPlayingState() {
        if (this.mediaSourceSupport) {
            if (
                this.audioPlayer.paused === true &&
                this.preloadedRange.length > 0
            ) {
                await this.audioPlayer.play()
            }

            // We call this function when setting the time elapsed in the player
            // because we dont want to preload very long buffers in memory to
            // bypass chromium media api limitations
            await this.loadAudioChunkIntoSourceBuffer()

            playerActions.setTimeElapsed(
                "setRefresh -> Media Source",
                this.audioPlayer.currentTime
            )

            const bufferTimeBeforeEnding = 0.15 // in seconds

            for (let buffer of this.preloadedRange) {
                const shouldEndTrack =
                    buffer.metadata.isLast &&
                    playerQuery.timeElapsed + bufferTimeBeforeEnding >=
                        buffer.metadata.to

                if (shouldEndTrack) {
                    const shouldPlay = playerQuery.status === "playing"

                    // @todo: pause and reset
                    await this.resetPlaybackToZero()

                    await Misc.wait(bufferTimeBeforeEnding)

                    return this.trackEndedEE.emit({
                        sourceID: playerQuery.contentID,
                        shouldPlay,
                    })
                }
            }
        } else if (this.masterBus) {
            let timeElapsed =
                this.masterBus.currentTime -
                this.currentTimeDuringPlay +
                this.timeElapsedDuringPlay

            playerActions.setTimeElapsed("setRefresh -> Web Audio", timeElapsed)
        }

        if (
            Math.abs(this.lastLoopTimeElapsed - playerQuery.timeElapsed) < 0.001
        ) {
            this.nbOfLoopsWithoutProgress += 1
        } else {
            this.nbOfLoopsWithoutProgress = 0
        }
    }

    private async setRefresh(): Promise<any> {
        if (playerQuery.playbackType === "realtime") {
            await this.handleUpdateForPausedState(false)
        } else if (playerQuery.status !== "playing") {
            await this.handleUpdateForPausedState(true)
        } else {
            await this.handleUpdateForPlayingState()

            // if (this.nbOfLoopsWithoutProgress > 20 && this.mediaSourceSupport) {
            //     console.warn(
            //         "Too many loops without progress, resetting playback"
            //     )

            //     await this.clearPreloadedRange()

            //     this.nbOfLoopsWithoutProgress = 0

            //     this.removeFromCache(playerQuery.contentID)

            //     playerActions.setTimeElapsed(
            //         "setRefresh",
            //         Math.max(0, playerQuery.timeElapsed - 0.1)
            //     )
            // }
        }

        this.lastLoopTimeElapsed = playerQuery.timeElapsed
    }

    private currentTimeIsInBufferBounds(cachedBuffer: CachedBuffer) {
        return (
            cachedBuffer.metadata.from - 1 <= playerQuery.timeElapsed &&
            cachedBuffer.metadata.to + 1 > playerQuery.timeElapsed
        )
    }

    private async clearPreloadedRange() {
        if (this.mediaSourceSupport === false) {
            return
        }

        this.audioPlayer.pause()

        const clearSourceBuffer = () => {
            try {
                if (
                    this.tracksAudioCache[playerQuery.contentID]?.duration > 0
                ) {
                    this.sourceBuffer.remove(
                        0,
                        this.tracksAudioCache[playerQuery.contentID].duration
                    )
                }
            } catch (e) {
                console.error(e)
            }
        }

        const abort = async () => {
            await this.waitForSourceBufferUpdate()

            try {
                if (this.mediaSource.readyState === "open") {
                    this.sourceBuffer.abort()
                }
            } catch (e) {
                console.error(e)
            }
        }

        await this.waitForSourceBufferUpdate()

        clearSourceBuffer()

        this.preloadedRange = []

        await abort()
    }

    private async loadFirstAudioChunk(retry = 3) {
        let attempts = 0

        try {
            let retries = 30

            while (this.tracksAudioCache[playerQuery.contentID] === undefined) {
                await Misc.wait(0.1)
                retries--

                if (retries === 0) {
                    return this.loadNewTrack(
                        "loadFirstAudioChunk",
                        playerQuery.content as Composition,
                        playerQuery.status === "playing" ||
                            this.playAfterLoading,
                        true
                    )
                }
            }

            while (this.preloadedRange.length === 0) {
                const buffers =
                    this.tracksAudioCache[playerQuery.contentID].buffers

                for (let b in buffers) {
                    const buffer = buffers[b]

                    if (this.currentTimeIsInBufferBounds(buffer)) {
                        this.preloadedRange = []

                        await this.waitForSourceBufferUpdate()

                        this.sourceBuffer.timestampOffset = buffer.metadata.from

                        await this.appendToSourceBuffer(buffer)

                        break
                    }
                }

                if (this.preloadedRange.length > 0) {
                    break
                }

                await Misc.wait(attempts < 30 ? 0.02 : 0.25)

                attempts += 1
            }
        } catch (e) {
            console.error(e)

            if (retry > 0) {
                return this.loadFirstAudioChunk(retry - 1)
            }

            await Misc.wait(0.1)

            return this.loadNewTrack(
                "loadFirstAudioChunk - retry",
                playerQuery.content as Composition,
                playerQuery.status === "playing" || this.playAfterLoading,
                true,
                0
            )
        }
    }

    private async loadAudioChunkIntoSourceBuffer() {
        if (playerQuery.status !== "playing") {
            return
        }

        try {
            if (this.preloadedRange.length === 0) {
                await this.loadFirstAudioChunk()
            }

            const lastItem: CachedBuffer =
                this.preloadedRange[this.preloadedRange.length - 1]
            const buffers = this.tracksAudioCache[playerQuery.contentID].buffers
            const timeElapsed = playerQuery.timeElapsed
            const bufferTime = 600 // 10 minutes, in seconds

            if (timeElapsed + bufferTime >= lastItem.metadata.to) {
                for (let b in buffers) {
                    const buffer = buffers[b]

                    if (buffer.metadata.order === lastItem.metadata.order + 1) {
                        await this.appendToSourceBuffer(buffer)

                        break
                    }
                }
            } else {
                let shouldResetSourceBuffer = true

                for (let buffer of this.preloadedRange) {
                    if (
                        buffer.metadata.from <= timeElapsed &&
                        buffer.metadata.to > timeElapsed
                    ) {
                        shouldResetSourceBuffer = false
                        break
                    }
                }

                if (shouldResetSourceBuffer) {
                    await this.clearPreloadedRange()
                }
            }
        } catch (e) {
            // console.error(
            //     "An error occurred in loadAudioChunkIntoSourceBuffer()",
            //     e
            // )
        }
    }

    private playAudioBuffer(audioBuffer, offset?) {
        this.resetAudioContext()
        this.preloadAudioBuffer(audioBuffer)

        offset = offset && offset > 0 ? offset : 0

        this.soundSource.start(0, offset)

        playerActions.setStatus("StreamingService.playAudioBuffer()", "playing")

        this.soundSource.onended = () => {
            const shouldPlay = playerQuery.status === "playing"

            this.trackEndedEE.emit({
                sourceID: playerQuery.contentID,
                shouldPlay,
            })
        }
    }

    private resetAudioContext() {
        this.audioContextGain = this.masterBus.createGain()
        this.audioContextGain.gain.setValueAtTime(playerQuery.volumeLevel, 0)
        this.audioContextGain.connect(this.masterBus.destination)
    }

    private preloadAudioBuffer(audioBuffer) {
        this.soundSource = this.masterBus.createBufferSource()
        this.soundSource.buffer = audioBuffer
        this.soundSource.connect(this.audioContextGain)
    }

    private addToCache(buffer, metaData, duration) {
        try {
            const sourceID = metaData.sourceID
            const order = metaData.order

            // per default this won't overwrite a buffer
            if (!this.tracksAudioCache[sourceID].buffers[order]) {
                this.tracksAudioCache[sourceID].buffers[order] = {
                    buffer: buffer,
                    metadata: metaData,
                }
            }

            // update complete state
            this.tracksAudioCache[sourceID].isComplete =
                this.audioIsLoadedCompletely(sourceID)

            return Promise.resolve(
                this.tracksAudioCache[sourceID].buffers[order]
            )
        } catch (error) {
            console.error(error)
            return Promise.reject(error)
        }
    }

    private pauseWebAudio() {
        try {
            if (
                this.masterBus &&
                this.masterBus.state === "running" &&
                this.audioContextGain != null
            ) {
                this.audioContextGain.disconnect(this.masterBus.destination)
            }
        } catch (e) {}

        try {
            if (this.soundSource) {
                this.soundSource.onended = undefined
                this.soundSource.stop()
            }
        } catch (error) {}

        this.audioContextGain = null
        this.soundSource = this.masterBus.createBufferSource()
    }

    private async waitForSourceBufferUpdate() {
        if (this.sourceBuffer?.updating) {
            return new Promise(resolve => {
                this.sourceBuffer.onupdateend = () => {
                    resolve(null)
                }
            })
        }

        return
    }

    private async appendToSourceBuffer(
        cachedBuffer: CachedBuffer
    ): Promise<any> {
        try {
            await this.waitForSourceBufferUpdate()

            this.sourceBuffer.appendBuffer(cachedBuffer.buffer)
            this.preloadedRange.push(cachedBuffer)

            await this.waitForSourceBufferUpdate()
        } catch (e) {
            console.error(e)
        }
    }

    private onMaximumCacheExceeded() {
        if (Object.keys(this.tracksAudioCache).length > this.MAXIMUM_CACHE) {
            // find oldest object
            let arrayOfObjects = Object.keys(this.tracksAudioCache).map(key => {
                return this.tracksAudioCache[key]
            })

            let min = arrayOfObjects.reduce((prev, curr) =>
                prev.dateAdded.getTime() < curr.dateAdded.getTime()
                    ? prev
                    : curr
            )

            let deleteSuccess = false

            if (min?.sourceID && this.tracksAudioCache[min.sourceID]) {
                delete this.tracksAudioCache[min.sourceID]
            }

            return deleteSuccess
        }
        return false
    }

    private audioIsLoadedCompletely(sourceID) {
        const lastBufferObject = this.getLastBufferObject(sourceID)

        if (
            lastBufferObject &&
            Object.keys(this.tracksAudioCache[sourceID].buffers).length - 1 ===
                lastBufferObject.metadata.order
        ) {
            return true
        }

        return false
    }

    private getLastBufferObject(sourceID): CachedBuffer {
        let lastBufferObject

        if (
            this.tracksAudioCache[sourceID] &&
            this.tracksAudioCache[sourceID].buffers
        ) {
            let bufferObjects = this.tracksAudioCache[sourceID].buffers
            let bufferKeys = Object.keys(bufferObjects)

            for (let key of bufferKeys) {
                if (bufferObjects[key].metadata.isLast) {
                    lastBufferObject = bufferObjects[key]
                }
            }
        }

        return lastBufferObject
    }

    /**
     * This method splits up a concatenated ArrayBuffer that consists of meta data and raw audio data
     * @param concatenatedArrayBuffer ArrayBuffer that contains both the metaData as well as the actual raw ArrayBuffer
     */
    private splitUpMetaDataFromArrayBuffer(concatenatedArrayBuffer): Promise<{
        metaData: StreamingBufferMetadata
        arrayBuffer: ArrayBuffer
    }> {
        let seperatorString = "|seperator|" // this must be the same as in the API, otherwise it won't work!

        return new Promise((resolve, reject) => {
            this.decodeArrayBufferToString(concatenatedArrayBuffer)
                .then(arrayBufferString => {
                    // Here we search for the seperator string
                    // The text before the seperator is the meta data about the ArrayBuffer, the data after the seperator is the actual audio data
                    let indexOfSeperator =
                        arrayBufferString.indexOf(seperatorString)
                    let metaDataString = arrayBufferString.substr(
                        0,
                        indexOfSeperator
                    )
                    let beginOfArrayBuffer =
                        indexOfSeperator + seperatorString.length
                    let arrayBuffer =
                        concatenatedArrayBuffer.slice(beginOfArrayBuffer) // create a new ArrayBuffer starting from the bytes after meta data and seperator

                    return resolve({
                        metaData: JSON.parse(metaDataString),
                        arrayBuffer,
                    })
                })
                .catch(err => {
                    return reject(
                        "Error while splitting metadata from ArrayBuffer"
                    )
                })
        })
    }

    private decodeArrayBufferToString(buffer) {
        if ("TextDecoder" in window) {
            // Decode as UTF-8
            let dataView = new DataView(buffer)
            let decoder = new TextDecoder("utf8")
            let decodedString = decoder.decode(dataView)

            return Promise.resolve(decodedString)
        } else {
            // Fallback decode as ASCII
            let decodedString = String.fromCharCode.apply(
                null,
                new Uint8Array(buffer)
            )

            return Promise.resolve(decodedString)
        }
    }

    private unlockAudio() {
        if (!this.audioUnlocked) {
            let unlockEvent =
                "ontouchstart" in document.documentElement
                    ? "touchend"
                    : "click"

            // play back a 1 second empty buffer whenever we initialize the streaming service
            window.addEventListener(
                unlockEvent,
                () => {
                    if (this.audioUnlocked) return

                    // create empty buffer and play it back to unlock AudioContext
                    let buffer = this.masterBus.createBuffer(1, 1, 22050)
                    let source = this.masterBus.createBufferSource()
                    source.buffer = buffer
                    source.connect(this.masterBus.destination)
                    source.start(0)

                    // by checking the play state after some time, we know if we're really unlocked
                    setTimeout(() => {
                        if (this.masterBus.state === "running") {
                            this.audioUnlocked = true
                        }
                    }, 0)
                },
                false
            )
        }
    }

    private supportsMediaSourceType(mimeType?) {
        return (
            this.mediaSource != null &&
            "MediaSource" in window &&
            MediaSource.isTypeSupported(mimeType || "audio/mpeg")
        )
    }

    private isCached(sourceID) {
        return (
            this.tracksAudioCache.hasOwnProperty(sourceID) &&
            this.tracksAudioCache[sourceID].isComplete == true
        )
    }

    private isIOS() {
        const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform)

        const isIOS = !window["MSStream"] && iOS_1to12

        return isIOS
    }
}
