import { Component, OnInit } from "@angular/core"
import { ApiService } from "../../services/api.service"
import { PlayerService } from "../../services/audio/player/player.service"
import { CompositionService } from "../../services/composition.service"
import { FolderService } from "../../services/folder.service"
import { ModalService } from "../../services/modal.service"
import { ActivatedRoute, Router } from "@angular/router"
import { NgxFileDropEntry } from "ngx-file-drop"
import { MIDIImport } from "@common-lib/modules/midi-import.module"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { Midi } from "@tonejs/midi"
@Component({
    selector: "app-jsonuploader",
    templateUrl: "jsonuploader.component.html",
    styleUrls: ["jsonuploader.component.scss"],
})
export class JSONUploaderComponent implements OnInit {
    public files: NgxFileDropEntry[] = []

    loading = false

    largeScaleTestID
    numberOfTests

    el
    chars
    resolve
    queue
    frameRequest
    frame
    largeScaleTestReport = []

    emotionTestFolderID = ""
    hypermood = 0

    versionNumberToClear

    instrumentToTest = ""

    dptPackLoading = false

    constructor(
        protected apiService: ApiService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected modalService: ModalService,
        protected folderService: FolderService,
        protected compositionService: CompositionService,
        private instruments: InstrumentsService
    ) {}

    uploadJSONFiles() {}

    compareTwoGPs() {
        this.modalService.modals.compareTwoGPs.next(true)
    }

    public dropped(files: NgxFileDropEntry[]) {
        this.files = files

        if (this.files.length > 1 && this.files.length < 1) {
            return
        }

        this.uploadFiles(this.files)
    }

    clearStagingQueues(versionNumberToClear) {
        return this.apiService
            .authRequest(
                "/musicEngine/clearQueues",
                { version: versionNumberToClear },
                "primary",
                true
            )

            .then(res => {
                if (res.result == 0) {
                    return Promise.reject(res.message)
                }

                alert("Done clearing queues!")
            })

            .catch(err => {
                alert(err)
            })
    }

    async loadMIDIFile(file: FileSystemFileEntry) {
        let midi: Midi
        let errors: MIDIImport.MidiErrorType = {}
        try {
            midi = await MIDIImport.convertFileSystemEntryToMIDI(file)
        } catch (e) {
            errors[MIDIImport.MidiErrorObjects.IMPORT] = {
                errors: {
                    [MIDIImport.MidiErrorKeys.FailedToImport]:
                        "Failed to read the midi file",
                },
                warnings: {},
            }
        }

        if (midi) {
            errors = MIDIImport.validateMIDIV2(midi)
        }

        let pass = true
        let errorsArr: string[] = []
        for (let key in errors) {
            if (Object.keys(errors[key].errors).length !== 0) {
                pass = false
                for (let error in errors[key].errors) {
                    errorsArr.push(errors[key].errors[error])
                }
            }
        }

        this.loading = true

        if (!pass) {
            let error = errorsArr[0]

            if (errorsArr.length > 1) {
                error += " + " + (errorsArr.length - 1) + " other errors"
            }

            return this.apiService.handleError(error)
        }

        const { score } = MIDIImport.toTemplateScore(
            midi,
            this.instruments.instruments,
            this.instruments.drumSamples,
            true,
            {
                sections: true,
                timeSignature: true,
                tempo: true,
                keySignature: false,
                chords: false,
            }
        )

        const jsonString = JSON.stringify(score)
        const blob = new Blob([jsonString], { type: "application/json" })
        const jsonFile = new File([blob], Date.now() + ".json", {
            type: "application/json",
        })

        const formData = new FormData()
        formData.append("json", jsonFile, file.name)

        const promises = [
            this.apiService.authFormRequest(
                "/composition/importMIDI",
                formData
            ),
        ]

        const responses = await Promise.all(promises)
        const contentType =
            responses[0].type == "gp" ? "Styles" : "Compositions"

        this.loading = false

        return this.folderService.setContentType(
            "uploadFiles",
            contentType,
            "",
            true
        )
    }

    async uploadFiles(files) {
        if (files[0].fileEntry.name.includes(".mid")) {
            return this.loadMIDIFile(files[0].fileEntry as FileSystemFileEntry)
        }

        try {
            this.loading = true

            let promises = []

            for (let file of files) {
                const fileEntry = file.fileEntry as FileSystemFileEntry

                if (!fileEntry.name.includes(".json")) {
                    continue
                }

                promises.push(this.uploadFile(fileEntry))
            }

            const responses = await Promise.all(promises)
            const contentType =
                responses[0].type == "gp" ? "Styles" : "Compositions"

            this.loading = false

            return this.folderService.setContentType(
                "uploadFiles",
                contentType,
                "",
                true
            )
        } catch (err) {
            this.apiService.handleError(err)
        }
    }

    async importDPTPack(files: FileList) {
        this.dptPackLoading = true

        const pack = JSON.parse(await files.item(0).text())

        try {
            await this.apiService.authRequest(
                "/uploadDPTPack",
                {
                    pack,
                },
                "primary",
                false
            )

            this.dptPackLoading = false
        } catch (e) {
            this.apiService.handleError(e)
        }
    }

    generateInstrumentTests() {
        return this.apiService
            .authRequest(
                "/instrument/generateTests",
                { instrumentName: this.instrumentToTest },
                "primary",
                false
            )

            .then(res => {
                if (res.result == 0) {
                    return Promise.reject(res.message)
                }

                alert("Done generating tests!")
            })

            .catch(err => {
                alert(err)
            })
    }

    uploadFile(fileEntry): Promise<any> {
        return new Promise((resolve, reject) => {
            fileEntry.file((file: File) => {
                var formData = new FormData()
                formData.append("json", file, file.name)

                resolve(formData)
            })
        }).then(data => {
            return this.apiService.authFormRequest(
                "/composition/importJSON",
                data
            )
        })
    }

    resetLibrary() {
        return this.apiService.authRequest(
            "/generationprofile/resetLibrary",
            {},
            "primary",
            true
        )
    }

    resetDownloadQuota() {
        return this.apiService.authRequest(
            "/user/resetDownloadQuota",
            {},
            "primary",
            true
        )
    }

    ngOnInit() {
        this.el = document.querySelector(".text-effect")
        this.chars = "!<>-_\\/[]{}—=+*^?#________"
        this.update = this.update.bind(this)

        this.setText("Click on the icon above to upload your JSON scores")
    }

    setText(newText) {
        const oldText = this.el.innerText
        const length = Math.max(oldText.length, newText.length)
        const promise = new Promise(resolve => (this.resolve = resolve))
        this.queue = []

        for (let i = 0; i < length; i++) {
            const from = oldText[i] || ""
            const to = newText[i] || ""
            const start = Math.floor(Math.random() * 40)
            const end = start + Math.floor(Math.random() * 40)
            this.queue.push({ from, to, start, end })
        }

        cancelAnimationFrame(this.frameRequest)
        this.frame = 0
        this.update()

        return promise
    }

    update() {
        let output = ""
        let complete = 0

        for (let i = 0, n = this.queue.length; i < n; i++) {
            let { from, to, start, end, char } = this.queue[i]
            if (this.frame >= end) {
                complete++
                output += to
            } else if (this.frame >= start) {
                if (!char || Math.random() < 0.28) {
                    char = this.randomChar()
                    this.queue[i].char = char
                }
                output += `<span class="dud">${char}</span>`
            } else {
                output += from
            }
        }

        this.el.innerHTML = output

        if (complete === this.queue.length) {
            this.resolve()
        } else {
            this.frameRequest = requestAnimationFrame(this.update)
            this.frame++
        }
    }

    refreshGPSourcePacks() {
        this.modalService.modals.refreshGPSourcePacks.next(true)
    }

    randomChar() {
        return this.chars[Math.floor(Math.random() * this.chars.length)]
    }

    startLargeScaleTests(numberOfTests, type, folderID, hypermood) {
        this.apiService
            .authRequest(
                "/composition/largeScaleTests",
                {
                    numberOfTests: numberOfTests,
                    type: type,
                    folderID: folderID,
                    hypermood: hypermood,
                },
                "primary",
                false
            )
            .then(() => {
                alert("Large scale tests started")
            })
    }

    getTestReport(largeScaleTestID) {
        this.apiService
            .authRequest(
                "/composition/largeScaleTestsReport",
                { largeScaleTestID: largeScaleTestID },
                "primary",
                "false"
            )
            .then(result => {
                var data = result.data

                this.largeScaleTestReport = []

                for (var preset in data.result) {
                    var averageRenderTime = 0
                    var minRenderTime = 99999
                    var maxRenderTime = 0

                    for (
                        var i = 0;
                        i < data.result[preset].sampleRenderTimes.length;
                        i++
                    ) {
                        averageRenderTime +=
                            data.result[preset].sampleRenderTimes[i]

                        maxRenderTime = Math.max(
                            maxRenderTime,
                            data.result[preset].sampleRenderTimes[i]
                        )
                        minRenderTime = Math.min(
                            minRenderTime,
                            data.result[preset].sampleRenderTimes[i]
                        )
                    }

                    averageRenderTime =
                        averageRenderTime /
                        data.result[preset].sampleRenderTimes.length

                    this.largeScaleTestReport.push({
                        Preset: preset,
                        "Music Engine error rate":
                            100 -
                            Math.round(
                                (data.result[preset].musicEngineSuccess /
                                    data.testsPerPreset) *
                                    10000
                            ) /
                                100,
                        "Sampler error rate":
                            100 -
                            Math.round(
                                (data.result[preset].samplerSuccess /
                                    data.testsPerPreset) *
                                    10000
                            ) /
                                100,
                        "Avg sampler render time": averageRenderTime,
                        "Min render time": minRenderTime,
                        "Max render time": maxRenderTime,
                    })
                }
            })

            .catch(err => {
                console.log(err)
            })
    }
}
