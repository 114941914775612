import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from "@angular/core"
import { ParentClass } from "../../../parent"
import { Effect } from "@common-lib/classes/score/effect"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"

@Component({
    selector: "automation-scale",
    templateUrl: "automation-scale.component.html",
    styleUrls: ["automation-scale.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationScaleComponent
    extends ParentClass
    implements AfterViewInit
{
    @Input() engine:ScoreRenderingEngine

    constructor(public ref: ChangeDetectorRef) {
        super()
    }

    ngAfterViewInit(): void {
        this.subscribe(
            this.engine.selectedAutomation$,
            (effect: Effect | undefined) => {
                this.ref.detectChanges()
            }
        )
    }
}
