import { FormDescriptionSchema } from "../../../interfaces/db-schemas/generationprofile"

export const defaultFormTag:FormDescriptionSchema = {
    structureSettings: {
        subsequenceLength: "medium",
        partialSections: "none",
        maxThemes: 0
    },

    variationSettings: {
        materialVariations: {
            likelihood: 0.5,
            categories: ["allow_new_rests"],
            layers: ["Chords", "Melody"],
            strategy: "classical"
        },
        harmonicVariation: "none",
        keyShift: "none",
        orchestrationVariation: "none"
    },

    percussionDevelopment: "none",
    developmentTension: "tilted_triangle",
    layeringStrategy: "development_tension",
    dynamicsStrategy: "material_specific_w_interpolation",
    filteringStrategy: "basic_w_interpolation"
}