import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core'
import { GenerationProfileService } from '@services/generation-profile/generationprofile.service'
import { Note } from '@common-lib/classes/score/note'
import AccompanimentLayer from '@common-lib/classes/generationprofiles/accompaniment/accompanimentlayer'
import Pack from '@common-lib/classes/generationprofiles/accompaniment/accompanimentpack'
import { RecommendationsService } from '@services/recommendations.service'

@Component({
    selector: 'pitch-range-control',
    templateUrl: './pitch-range-control.component.html',
    styleUrls: ['pitch-range-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PitchRangeControlComponent implements OnInit {
    constructor(private recommendationService:RecommendationsService, private gpService: GenerationProfileService, protected ref:ChangeDetectorRef) { }

    @Input() layerName
    @Input() data

    title = {
        packName: "",
        range: "",
    }

    pianoKeys = []

    keyWidth = {
        white: 11,
        black: 7
    }

    pitchRangeDrag

    start: number = 21  // A0
    end: number = 120   // A6

    showHint = false

    continueDraggingListener
    stopDraggingListener

    ngOnInit() {
        this.generatePianoKeyboard()
        this.updatePitchRangeControl()
    }

    updateTitle(){
        this.title = {
            packName: this.data.name,
            range: ""
        }

        let pitchRange = this.getPitchRange()

        if(pitchRange == null || pitchRange.min == null || pitchRange.max == null){
            return
        }

        const minNote = Note.notesInOctave[(pitchRange.min - this.start) % Note.notesInOctave.length].slice(0,2)
        const maxNote = Note.notesInOctave[(pitchRange.max - this.start) % Note.notesInOctave.length].slice(0,2)
        const minOctave = Math.floor(pitchRange.min / Note.notesInOctave.length) - 2
        const maxOctave = Math.floor(pitchRange.max / Note.notesInOctave.length) - 2
        
        this.title.range = "(" + minNote + minOctave + " - " + maxNote + maxOctave + ")"
    }
    
    isDisabledKey(key) {
        let minPitch = this.recommendationService.accompanimentRange.min
        let maxPitch = this.recommendationService.accompanimentRange.max

        if (this.layerName == 'Bass') {
            minPitch = this.recommendationService.bassRange.min
            maxPitch = this.recommendationService.bassRange.max
        }

        let isDisabled = key.pitch < minPitch || key.pitch > this.getHighestNote(maxPitch)

        return isDisabled
    }

    generatePianoKeyboard(){
        this.pianoKeys = []

        let whiteKeyCnt = 0

        for(let k = 0; k < new Array(this.end - this.start).length + 1; k++){
            const pitch = k + this.start
            const octave = Math.floor(pitch / Note.notesInOctave.length) - 2
            
            if(pitch < 22){
                continue
            }
            
            const noteIndex = k % Note.notesInOctave.length
            const isWhite = Note.noteIsWhite[noteIndex]
            const whiteKeyPosition = whiteKeyCnt * (this.keyWidth.white + 1)
            const start = isWhite ? whiteKeyPosition : whiteKeyPosition - (this.keyWidth.black / 2)
            const end = isWhite ? start + this.keyWidth.white : start + this.keyWidth.black

            const key = {
                isWhite: isWhite,
                noteName: isWhite ? Note.notesInOctave[noteIndex] + octave : Note.notesInOctave[noteIndex].slice(0, 2) + octave,
                pitch: pitch,
                octave: octave,
                start: start,
                end: end,
                isC: Note.notesInOctave[noteIndex] == "C"
            }

            if(isWhite){
                whiteKeyCnt++
            }

            this.pianoKeys.push(key)
        }
    }

    getHighestNote(lowestNote, octaves = this.data.octaves){
        return lowestNote + 12 * octaves
    }

    setLowestNote(event, pitch, action = 'mousemove'){
        if(this.layerName != "Bass" && pitch < this.recommendationService.accompanimentRange.min){
            pitch = this.recommendationService.accompanimentRange.min
        }

        else if (this.layerName == "Bass" && pitch < this.recommendationService.bassRange.min) {
            pitch = this.recommendationService.bassRange.min
        }
        
        if (this.layerName != "Bass" && pitch > this.recommendationService.accompanimentRange.max){
            pitch = this.recommendationService.accompanimentRange.max
        }

        else if (this.layerName == "Bass" && pitch > this.recommendationService.bassRange.max) {
            pitch = this.recommendationService.bassRange.max
        }

        if(this.data.lowestNote == pitch){
            return
        }

        this.data.lowestNote = pitch

        this.updatePitchRangeControl()
    }

    getPitchRange(lowestNote = this.data.lowestNote){
        return {min: lowestNote, max: this.getHighestNote(lowestNote)}
    }

    startDraggingPitchRange(event, key){
        let pitchRange = this.getPitchRange()

        let pitch = key.pitch

        if (pitch > pitchRange.max || pitch < pitchRange.min) {
            this.setLowestNote(event, pitch, 'click')

            pitch = this.data.lowestNote
        }
        
        this.pitchRangeDrag = {
            startX: event.x,
            pitchX: key.start,
            difference: pitch - this.data.lowestNote
        }

		if (this.continueDraggingListener != null) {
			window.document.removeEventListener('mousemove', this.continueDraggingListener)
		}
 
		if (this.stopDraggingListener != null) {
			window.document.removeEventListener('mouseup', this.stopDraggingListener)
		}

		this.continueDraggingListener = this.onMouseMove.bind(this)
		this.stopDraggingListener = this.stopDraggingPitchRange.bind(this)

		window.document.addEventListener('mousemove', this.continueDraggingListener)
		window.document.addEventListener('mouseup', this.stopDraggingListener)
    }

    async stopDraggingPitchRange(event) {
        event.stopPropagation()
    
        this.pitchRangeDrag = null

        window.document.removeEventListener('mousemove', this.continueDraggingListener)
        this.continueDraggingListener = null
 
		window.document.removeEventListener('mouseup', this.stopDraggingListener)
        this.stopDraggingListener = null

        await this.gpService.setAsUpdated("packLowestNote")
    }

    updatePitchRangeControl(){        
        this.updateTitle()

        this.ref.detectChanges()
    }

    onMouseMove(event){
        const differenceX = event.x - this.pitchRangeDrag.startX
        const position = this.pitchRangeDrag.pitchX + differenceX
        const pitch = this.getPitchByPosition(position) - this.pitchRangeDrag.difference

        this.setLowestNote(event, pitch, 'mousemove')
    }

    getPitchByPosition(position){
        let pitch

        if(position < 0){
            pitch = this.start
        }

        else if (position > this.pianoKeys[this.pianoKeys.length - 1].end){
            pitch = this.end
        }

        else {
            for(let key of this.pianoKeys){
                const start = key.start
                const end = key.end
    
                if (position >= start && position <= end){
                    pitch = key.pitch
                    break
                }
            }
        }

        return pitch
    }
}