<div class="notification-bubble" *ngIf="notification != null" (click)="openNotification($event)">
    <div class="left" *ngIf="notification.icon != null && notification.icon != ''">
        <img src="assets/img/{{ notification.icon }}.svg" class="icon">
    </div>

    <div class="right">
        <img class="close" src="assets/img/close.svg" (click)="hideNotification($event, false)">
        <div class="title">{{ notification.title }}</div>
        <div class="description">{{ notification.description }}</div>
    </div>
</div>