<div class="modal-title">
    Insert section {{ sectionEditing.position }}
    {{ sectionEditing.section.title }}
</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div class="settings-row">
    <div class="settings-row-title">Insertion type</div>
    <div class="settings-row-value">
        <dropdown
            class="insert-section-modal"
            type="light"
            [showArrow]="true"
            [description]="''"
            [showTooltip]="false"
            [value]="sectionType"
            [items]="insertTypes"
            placeholder="Select a type of section to insert"
            (selectedItem)="selectSectionType($event)"></dropdown>
    </div>
</div>

<div
    class="settings-row"
    *ngIf="
        sectionType !== undefined &&
        (sectionType.value === 'copy' || sectionType.value === 'variation')
    ">
    <div class="settings-row-title">Source section</div>
    <div class="settings-row-value">
        <dropdown
            class="insert-section-modal"
            type="light"
            [showArrow]="true"
            [description]="''"
            [showTooltip]="false"
            [value]="sourceSectionIndex"
            [items]="filteredSections"
            placeholder="Which section would you like to use as source?"
            (selectedItem)="selectSourceSection($event)"></dropdown>
    </div>
</div>

<div
    class="settings-row"
    *ngIf="
        sectionType !== undefined &&
        (sectionType.value === 'new' || sectionType.value === 'blank')
    ">
    <div class="settings-row-title">Section length, in number of measures</div>
    <div class="settings-row-value settings-row-section-length">
        <ngx-slider
            class="insert-section-length"
            [(value)]="sectionLength"
            [options]="sectionLengthOptions"></ngx-slider>
    </div>
</div>

<div
    class="button-wrapper"
    *ngIf="
        sectionType !== undefined &&
        ((sourceSectionIndex !== undefined &&
            (sectionType.value === 'copy' ||
                sectionType.value === 'variation')) ||
            sectionType.value === 'new' || sectionType.value === 'blank')
    ">
    <a-button
        type="secondary"
        label="Apply"
        (click)="apply()"
        width="100%"></a-button>
</div>
