import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"

@Component({
    selector: "integer-input-field",
    templateUrl: "integer-input-field.component.html",
    styleUrls: ["integer-input-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegerInputFieldComponent implements AfterViewInit {
    @Input() value: number
    @Input() min: number
    @Input() max: number
    @Input() unit: string
    @Input() class: string
    @Input() type: string = "default"
    @Input() hideBtns = false

    @Output() onChange: EventEmitter<any> = new EventEmitter()

    ngAfterViewInit(): void {}

    verify() {
        this.value = Math.min(this.value, this.max)
        this.value = Math.max(this.value, this.min)

        this.onChange.emit(this.value)
    }

    increment() {
        this.value += 1
        this.verify()
    }

    decrement() {
        this.value -= 1
        this.verify()
    }
}
