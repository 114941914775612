import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from "@angular/core"
import { ParentClass } from "../../../parent"
import { Effect } from "@common-lib/classes/score/effect"
import { ModalService } from "@services/modal.service"
import { InstrumentsService } from "@services/instruments/instruments.service"
import {
    DEFAULT_PERCUSSION_INSTRUMENT,
    DEFAULT_PITCHED_INSTRUMENT,
} from "@common-lib/constants/constants"
import { Helpers } from "../../../modules/helpers.module"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import { EditorService } from "@services/editor/editor.service"
@Component({
    selector: "layer-effects-panel",
    templateUrl: "layer-effects-panel.component.html",
    styleUrls: ["layer-effects-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerEffectsPanelComponent
    extends ParentClass
    implements AfterViewInit
{
    @Input() editor:EditorService

    constructor(
        public ref: ChangeDetectorRef,
        private modalService: ModalService,
        public instruments:InstrumentsService
    ) {
        super()
    }

    ngAfterViewInit() {
        this.subscribe(
            this.editor.engine.selectedAutomation$,
            (effect: Effect | undefined) => {
                this.ref.detectChanges()
            }
        )
    }

    public duplicateAutomation(effect: Effect) {
        this.editor.engine.copyAutomationToOtherLayers(
            effect,
            this.editor.engine.toggledLayer
        )

        this.modalService.modals.simpleModal.next({
            title: "Automation Copied!",
            message:
                "The automation has been successfully copied to all other layers.",
            buttons: [
                {
                    type: "secondary",
                    text: "Close",
                    onClick: this.onAutomationDuplicationSuccess.bind(this),
                },
            ],
        })
    }

    private onAutomationDuplicationSuccess() {
        this.modalService.modals.simpleModal.next(null)
    }

    public getLayerEffects(): Effect[] {
        const toggledLayer = this.editor.engine.getToggledLayer()

        if (!toggledLayer) {
            return []
        }

        const keys = Object.keys(toggledLayer.effects)

        const effects = keys.map(key => {
            return toggledLayer.effects[key]
        })

        return effects
    }

    protected async toggleAutomation(effect: Effect) {
        if (this.editor.engine.selectedAutomation === effect) {
            effect = undefined
        }

        this.editor.engine.selectAutomation(effect)

        const element = await Helpers.waitForElementByID("automation-canvas")

        this.editor.scrollYBy.next(
            element.getBoundingClientRect().top +
                element.getBoundingClientRect().height
        )
    }

    protected getToggledAutomationBackgroundColor(effect: Effect) {
        const selectedAutomation = this.editor.engine.selectedAutomation

        if (
            selectedAutomation == null ||
            selectedAutomation.name != effect.name
        ) {
            return "rgba(255, 255, 255, 0.1)"
        }

        return "var(--" + effect.name + "-primary-color)"
    }
}
