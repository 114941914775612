<span
    [tooltip]="getKnobValue()"
    [tooltip-type]="'onmousedown'"
    [tooltip-position]="'middle-bottom'"
    [tooltip-marginTop]="20"
    [tooltip-marginLeft]="-8"
    [tooltip-width]="70"
    [tooltip-textAlign]="'center'">
    <div class="panning-dial" *ngIf="type === 'pan'">
        <div
            class="bi-directional-dial {{ class }}"
            [ngClass]="{ 'no-title': showTitle == false }">
            <div class="title" *ngIf="showTitle">
                {{ title }}
            </div>

            <div class="dial-container">
                <div class="dial-small-print left">{{ minLabel }}</div>
                <div class="dial-knob-container">
                    <div
                        class="left-circle"
                        style.background="{{ getOrientation('left') }}"></div>

                    <div
                        class="dial"
                        [attr.av-data]="avData"
                        (dblclick)="resetToZero()"
                        (mousedown)="startPanning($event)"
                        [ngStyle]="{ transform: getValue() }">
                        <div class="dial-indicator"></div>
                    </div>

                    <div
                        class="right-circle"
                        style.background="{{ getOrientation('right') }}"></div>
                </div>
                <div class="dial-small-print right">{{ maxLabel }}</div>
            </div>
        </div>
    </div>

    <div class="volume-dial" *ngIf="type === 'gradual'">
        <div class="bi-directional-dial {{ class }}">
            <div class="title">
                {{ title }}
            </div>

            <div class="dial-container">
                <div class="dial-knob-container">
                    <div
                        class="left-circle"
                        style.background="{{ getOrientation('left') }}"></div>

                    <div
                        class="dial"
                        (dblclick)="resetToZero()"
                        (mousedown)="startTurningOneDirectionalKnob($event)"
                        [attr.av-data]="avData"
                        [ngStyle]="{ transform: getValue() }">
                        <div class="dial-indicator"></div>
                    </div>

                    <div
                        class="right-circle"
                        style.background="{{ getOrientation('right') }}"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="volume-dial" *ngIf="type === 'normal'">
        <div
            class="bi-directional-dial {{ class }}"
            [ngClass]="{ 'no-title': showTitle == false }">
            <div class="title" *ngIf="showTitle">
                {{ title }}
            </div>

            <div class="dial-container">
                <div class="dial-small-print left">{{ minLabel }}</div>
                <div class="dial-knob-container">
                    <div
                        class="left-circle"
                        style.background="{{ getOrientation('left') }}"></div>

                    <div
                        class="dial"
                        (dblclick)="resetToZero()"
                        (mousedown)="startTurningOneDirectionalKnob($event)"
                        [attr.av-data]="avData"
                        [ngStyle]="{ transform: getValue() }">
                        <div class="dial-indicator"></div>
                    </div>

                    <div
                        class="right-circle"
                        style.background="{{ getOrientation('right') }}"></div>
                </div>
                <div class="dial-small-print right">{{ maxLabel }}</div>
            </div>
        </div>
    </div>
</span>
