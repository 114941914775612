import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
} from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { CreateTrackFrom } from "@common-lib/types/general"
import { CreateService } from "@services/create.service"
import { GeneralService } from "@services/general/general.service"
import { UserService } from "@services/user.service"
import { Helpers } from "../../modules/helpers.module"

@Component({
    selector: "create",
    templateUrl: "create.component.html",
    styleUrls: ["create.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateComponent {
    constructor(
        protected createService: CreateService,
        protected generalService: GeneralService,
        protected userService: UserService,
        protected ref: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute
    ) {
        this.options = Helpers.getCreationMethods(
            this.userService.accountCreationDate
        )
    }

    public options = []

    get sortOptions() {
        return this.createService.gpStates.sortOptions
    }

    get selectedMenu() {
        return this.createService.selectedMenu
    }

    get type(): CreateTrackFrom {
        return this.activatedRoute.snapshot.paramMap.get(
            "type"
        ) as CreateTrackFrom
    }

    async ngOnInit() {
        this.userService.createTrackMenu.subscribe(async value => {
            this.createService.selectedMenu = value
            this.ref.detectChanges()
        })
        // enable this line if you want to enable the tutorial for the style picker.
        // this.tutorialService.initCreateCompositionTutorial(true)
    }

    async setType(event) {
        this.createService.resetFilters()
        this.createService.resetSortCategory()
        this.createService.clearSearch()

        this.createService.selectedMenu = event
        this.userService.createTrackMenu.next(event)
    }

    showMobileInterfaceOnFirefox() {
        if (
            window.innerWidth < 1000 &&
            this.generalService.getBrowser() === "firefox"
        ) {
            return true
        }

        return false
    }

    @HostListener("window:resize", ["$event"])
    onResize() {
        this.ref.detectChanges()
    }

    isMobile() {
        return (
            this.generalService.isMobile() ||
            this.showMobileInterfaceOnFirefox()
        )
    }
}
