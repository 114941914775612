<div class="modal-title">Rename Double Folder</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>
<input
    type="text"
    class="modal-input"
    [(ngModel)]="renameDoubleFolder"
    (keydown.enter)="updateDoubleFolder()" />

<div class="modal-button modal-text-button" (click)="closeModal()">Cancel</div>
<div class="modal-button modal-blue-button" (click)="updateDoubleFolder()">
    Done
</div>
