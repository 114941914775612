import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Misc } from '@common-lib/modules/misc'
import GPSettings from '@common-lib/classes/generationprofiles/gpsettings'
import HarmonyPack from '@common-lib/classes/generationprofiles/harmony/harmonypack'
import MelodyPack from '@common-lib/classes/generationprofiles/melody/melodypack'
import { GenerationProfileService } from '../../../services/generation-profile/generationprofile.service'
import { Effect } from '@common-lib/classes/score/effect'
import Harmony from '@common-lib/classes/generationprofiles/harmony/harmony'
import { SourcePackService } from '../../../services/source-packs/sourcepacks.service'


@Component({
 	selector: 'gp-emotion-selection',
  	templateUrl: 'gp-emotion-selection.component.html',
    styleUrls: ['./gp-emotion-selection.component.scss']
})
export class GPEmotionSelectionComponent {
    @Input() emotion:number
    @Output() close = new EventEmitter()

    changes = []

    constructor(private sourcePacks:SourcePackService, private gpService:GenerationProfileService) {

    }

    getDynamicCorrespondence(value) {
        return Effect.dynamicCorrespondence[value]
    }

    async ngOnInit() {
        let gp = this.gpService.generationProfile

        let tempoRange = GPSettings.MOOD_OPTIONS.tempoRanges[this.emotion]
        let dynamicRange = GPSettings.MOOD_OPTIONS.dynamicRange[this.emotion]
        let keyMode = GPSettings.MOOD_OPTIONS.keyMode[this.emotion]
        let melodyPhrasePack = GPSettings.MOOD_OPTIONS.melodyPhrasePack[this.emotion]
        let harmony = GPSettings.MOOD_OPTIONS.harmony[this.emotion]

        if (gp.harmony.strategy === "Modal") {
            this.changes.push({
                    name: "Harmonic strategy",
                    newValueDisplay: "Functional",
                    newValue: "Functional"
            })
        }

        if (!Misc.isInRange(gp.settings.tempoRange.max, tempoRange) || !Misc.isInRange(gp.settings.tempoRange.min, tempoRange)) {
            this.changes.push({
                name: "Tempo",
                newValueDisplay: tempoRange[0] + " - " + tempoRange[1] + " BPM",
                newValue: tempoRange
            })
        }

        if (!Misc.isInRange(gp.settings.dynamicRange.max, dynamicRange) || !Misc.isInRange(gp.settings.dynamicRange.min, dynamicRange)) {
            this.changes.push({
                name: "Dynamic Range",
                newValueDisplay: this.getDynamicCorrespondence(dynamicRange[0]) + " - " + this.getDynamicCorrespondence(dynamicRange[1]),
                newValue: dynamicRange
            })
        }

        if (gp.harmony.keySignature.keyMode != keyMode) {
            this.changes.push({
                name: "Key Mode",
                newValueDisplay: keyMode,
                newValue: keyMode
            })
        }

        if (gp.melodyLayer != null) {
            let melodyPack = gp.melodyLayer.packs[0] as MelodyPack

            if (melodyPack.complexity != melodyPhrasePack.complexity) {
                this.changes.push({
                    name: "Melody complexity",
                    newValueDisplay: melodyPhrasePack.complexity,
                    newValue: melodyPhrasePack.complexity
                })
            }

            if (melodyPack.phraseLength != melodyPhrasePack.phraseLength) {
                this.changes.push({
                    name: "Melody phrase length",
                    newValueDisplay: melodyPhrasePack.phraseLength,
                    newValue: melodyPhrasePack.phraseLength
                })
            }
        }

        const harmonicRepetition = Harmony.getAggregatedHarmonicRepetition(gp.harmony.packs)
        const harmonicRhythm = Harmony.getAggregatedHarmonicRhythm(gp.harmony.packs)
        const chordsGroupIDs = gp.harmony.packs.map(p => p.chordsGroupID[0])

        if (chordsGroupIDs.filter(c => !harmony.datasets.includes(c)).length > 0 || gp.harmony.packs.length == 0) {
            let datasets = []

            const harmonyPacks = await this.sourcePacks.getHarmonyPacks()

            for (let p of harmonyPacks) {
                let equalHarmonicRepetition = p.harmonicRepetition.min == harmonicRepetition.min && p.harmonicRepetition.max == harmonicRepetition.max
                let equalHarmonicRhythm = p.harmonicRhythm.min == harmonicRhythm.min && p.harmonicRhythm.max == harmonicRhythm.max

                // if (harmony.datasets.includes(p.chordsGroupID[0])) {
                if (harmony.datasets.includes(p.chordsGroupID[0]) && equalHarmonicRepetition && equalHarmonicRhythm) {
                    datasets.push(p)
                    break
                }
            }

            let valueDisplay = ""

            if(datasets.length == 0){
                valueDisplay = ""
            }

            else if (datasets.length == 1){
                valueDisplay = datasets[0].name
            }

            else {
                valueDisplay = datasets[0].name + " (+" + (datasets.length - 1) + " more)"
            }

            this.changes.push({
                name: "Harmonic dataset",
                newValueDisplay: valueDisplay,
                newValue: datasets
            })
        }


        if (harmonicRepetition.min != harmony.harmonicRepetition || harmonicRepetition.max != harmony.harmonicRepetition) {
            const translate = Harmony.HARMONIC_REPETITION_DISPLAY_VALUES
            
            this.changes.push({
                name: "Harmonic repetition",
                newValueDisplay: translate[harmony.harmonicRepetition],
                newValue: harmony.harmonicRepetition
            })
        }


        if (!Misc.isInMinMaxRange(harmonicRhythm.min, harmony.harmonicRhythm) || !Misc.isInMinMaxRange(harmonicRhythm.max, harmony.harmonicRhythm)){
            const translate = Harmony.HARMONIC_RHYTHM_DISPLAY_VALUES

            let displayValue = translate[harmony.harmonicRhythm.min] + " - " + translate[harmony.harmonicRhythm.max]
            
            if(harmony.harmonicRhythm.min == harmony.harmonicRhythm.max){
                displayValue = translate[harmony.harmonicRhythm.min]
            }

            this.changes.push({
                name: "Harmonic pacing",
                newValueDisplay: displayValue,
                newValue: harmony.harmonicRhythm
            })
        }

        if (this.changes.length == 0) {
            await this.applyEmotionSelection()
        }
    }

    async applyEmotionSelection() {
        let gp = this.gpService.generationProfile
        
        const harmonyPacks = await this.sourcePacks.getHarmonyPacks()
        const melodyPacks = await this.sourcePacks.getMelodyPacks()

        for (let change of this.changes) {
            // important: this needs to be dealt with before changing harmonic dataaset / key signature
            if (change.name === "Harmonic strategy") {
                gp.harmony.setStrategy(harmonyPacks, "Functional")
            }

            if (change.name == "Tempo") {
                gp.settings.tempoRange.min = change.newValue[0]
                gp.settings.tempoRange.max = change.newValue[1]
            }

            if (change.name == "Dynamic Range") {
                gp.settings.dynamicRange.min = change.newValue[0]
                gp.settings.dynamicRange.max = change.newValue[1]
            }
            
            if (change.name == "Key Mode") {
                gp.harmony.keySignature.keyMode = change.newValue
                gp.harmony.setKeySignatureFromMode(change.newValue)
            }
            
            if (change.name == "Melody complexity") {
                let melodyPack = gp.melodyLayer.packs[0] as MelodyPack
                melodyPack.complexity = change.newValue

                gp.melodyLayer.encodePackID(melodyPacks)
            }
            
            if (change.name == "Melody phrase length") {
                let melodyPack = gp.melodyLayer.packs[0] as MelodyPack
                melodyPack.phraseLength = change.newValue
                
                gp.melodyLayer.encodePackID(melodyPacks)
            }

            if (change.name == "Harmonic repetition") {
                for(let p = 0; p < gp.harmony.packs.length; p++){
                    gp.harmony.packs[p].harmonicRepetition.min = change.newValue
                    gp.harmony.packs[p].harmonicRepetition.max = change.newValue
                }

                gp.harmony.encodePackID(harmonyPacks)
            }
            
            if (change.name == "Harmonic pacing") {
                for (let p = 0; p < gp.harmony.packs.length; p++) {
                    gp.harmony.packs[p].harmonicRhythm.min = change.newValue.min
                    gp.harmony.packs[p].harmonicRhythm.max = change.newValue.max
                }

                gp.harmony.encodePackID(harmonyPacks)
            }

            let harmonicRepetition = Harmony.getAggregatedHarmonicRepetition(gp.harmony.packs)
            let harmonicRhythm = Harmony.getAggregatedHarmonicRhythm(gp.harmony.packs)

            if (change.name == "Harmonic dataset") {
                gp.harmony.packs = change.newValue

                gp.harmony.encodePackID(harmonyPacks)

                for (let p = 0; p < gp.harmony.packs.length; p++){
                    gp.harmony.packs[p].harmonicRepetition = harmonicRepetition
                    gp.harmony.packs[p].harmonicRhythm = harmonicRhythm
                }

                let keyMode = GPSettings.MOOD_OPTIONS.keyMode[this.emotion]
                gp.harmony.setKeySignatureFromMode(keyMode)

            }
        }
        
        gp.settings.emotion = this.emotion
        
        this.gpService.setAsUpdated("toggleEmotion")
        
        this.closeModal()
    }
    
    closeModal() {
        this.close.emit(null)
    }
}