import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from "@angular/core"
import { EditorService } from "@services/editor/editor.service"
import { ParentClass } from "../../../parent"
import { Effect, Reverb } from "@common-lib/classes/score/effect"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../../types/dropdownItemType"
import { ScoreUpdateType } from "../../../../../../common-lib/client-only/score-rendering-engine"
import { SRActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { isEqual } from "lodash"
import { SubscriptionHelpers } from "@common-lib/modules/subscription-helpers.module"

const ir = require("../../../../../../common-lib/assets/ir/ir.json")
@Component({
    selector: "set-constant-automation",
    templateUrl: "set-constant-automation.component.html",
    styleUrls: ["set-constant-automation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetConstantAutomationComponent
    extends ParentClass
    implements AfterViewInit
{
    @Input() editor: EditorService
    
    public constantValue: number = 0

    constructor(public ref: ChangeDetectorRef) {
        super()
    }

    ngAfterViewInit(): void {
        this.subscribe(
            this.editor.engine.selectedAutomation$,
            (effect: Effect | undefined) => {
                this.constantValue = 0
                this.ref.detectChanges()
            }
        )

        SubscriptionHelpers.subscribeWithPrevious(
            this.editor.engine.scoreUpdate$,
            ({
                previous,
                current,
            }: {
                previous: ScoreUpdateType[]
                current: ScoreUpdateType[]
            }) => {

                if (!current.includes("All") && !current.includes("Effect")) {
                    return
                }

                this.ref.detectChanges()
            },
            this.destroy$
        )
    }

    public updateDialControlledVariable(event, type:"delayLeft" | "delayRight") {
        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.setLayerDelay,
            data: {
                type: type,
                delay: event.value,
                layer: this.editor.engine.toggledLayer
            },
            options: {
                isUndoable: true
            }
        })
    }

    format(value: number): string {
        const effect: Effect | undefined = this.editor.engine.selectedAutomation

        if (!effect) {
            return ""
        }

        if (effect.name == "low_frequency_cut") {
            let formattedValue: any = Math.round(20 * Math.pow(10, value))

            if (value > 1000) {
                formattedValue = Math.floor(value / 100) / 10 + " kHz"
            } else {
                formattedValue += " Hz"
            }

            return formattedValue
        } else if (effect.name == "high_frequency_cut") {
            let formattedValue: any = Math.round(20 * Math.pow(10, 3 - value))

            if (value > 1000) {
                formattedValue = Math.floor(value / 100) / 10 + " kHz"
            } else {
                formattedValue += " Hz"
            }

            return formattedValue
        } else if (effect.name == "delay" || effect.name == "reverb") {
            return value + "%"
        }

        return value + ""
    }

    getReverbRoom(): DropdownItemType<string> {
        return {
            name: (<Reverb>this.editor.engine.selectedAutomation).ir,
            value: (<Reverb>this.editor.engine.selectedAutomation).ir,
        }
    }

    selectReverbRoom(room: DropdownSelection<string>): void {
        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.selectReverbRoom,
            data: {
                roomType: room.new.value,
            },
            options: {
                isUndoable: true,
            },
        })
    }

    getReverbRoomOptions(): DropdownItemType<string>[] {
        return ir.map(a => {
            return {
                name: a.name,
                value: a.name,
            }
        })
    }
}
