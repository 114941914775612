<div class="modal-title">Auto-Mixing settings</div>
<div
    class="modal-subtitle"
    [tooltip]="
        'When auto-mixing a composition, AIVA automatically adjusts gain values so that the peak loudness of each track is equal. Using the controls presented in this window, you can apply a positive or negative gain bias to mix some layers louder or quieter relative to other layers.'
    "
    [tooltip-marginTop]="10"
    [tooltip-width]="250"
    [tooltip-position]="'left-bottom'">
    How does auto-mixing work?
    <img class="tooltip-img" src="assets/img/info.svg" />
</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<div
    class="automixing-settings-row automixing-settings-row-enable"
    *ngIf="autoMixing.type === 'generationProfile'">
    <div class="settings-row-title">Enable auto-mixing</div>
    <div class="settings-row-value">
        <slide-toggle
            [(toggled)]="autoMixing.autoMix"
            (onChange)="toggleAutoMixing($event)"></slide-toggle>
    </div>
</div>

<span *ngIf="autoMixing.autoMix">
    <div class="automixing-settings-row" *ngFor="let layer of autoMixing.layers">
        <div class="settings-row-title">{{ layer.getName() }}</div>
        <div class="settings-row-value">
            <integer-input-field
                (onChange)="autoMixing.onChangeGainBias($event, layer)"
                [value]="
                    autoMixing.type === 'composition'
                        ? layer.gainBias
                        : layer.mixing.gainBias
                "
                min="-30"
                max="30"
                unit="dB"></integer-input-field>
        </div>
    </div>
</span>
