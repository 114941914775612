import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core'
import { ApiService } from '../../services/api.service'
import { TokenService } from '../../services/token.service'
import { BillingService } from '../../services/billing.service'
import { FolderService } from '../../services/folder.service'
import { Router, ActivatedRoute } from '@angular/router'
import { environment } from '../../../environments/environment'
import { HelperService } from '../../helpers/helper.service'

@Component({
  	selector: 'app-waitingonbrowserlogin',
  	templateUrl: 'waitingonbrowserlogin.component.html',
  	styles: []
})
export class WaitingOnBrowserLoginComponent implements OnInit {
    type

    constructor(private route:ActivatedRoute) {

    }

    toURL() {
        window.open(environment.domain + "/login?redirectToApp=true", '_blank')
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
			this.type = params['type']
        })
    }
}