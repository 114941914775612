import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { CompositionService } from './composition.service'
import { BillingService } from './billing.service'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class TokenService {
    renewalInterval = null
    renewalTimer = 5 * 60 * 1000

    user = ''
    userID

    isInitialized:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

	constructor(private apiService:ApiService, private compositionService:CompositionService, private billingService:BillingService) { 

    }

    generateToken() {
		if (this.renewalInterval != null) {
			clearInterval(this.renewalInterval)
		}

		return this.renewToken().then(() => {
            this.renewalInterval = setInterval(
                () => { 
                    this.renewToken() 
                },
        
                this.renewalTimer
            )

            return Promise.resolve()
		})
    }
    
    renewToken():Promise<any> {     
        return this.apiService.authRequest('/user/token', { refreshToken: this.getRefreshToken(), token: this.getToken() }, "primary", false).then((res) => {
            this.setToken(res.token)

            this.userID = res.userID

            this.setTokenLastUpdate()

            return this.billingService.getSubscription(res)
        })

        .catch((err) => {
            console.error(err)
            console.error("Couldnt renew authentication token.")

            return Promise.resolve()
        })
    }

    setRefreshToken(refreshToken) {
        localStorage.setItem('refreshToken', refreshToken)
    }

    setToken(token) {
        localStorage.setItem('token', token)
        this.setTokenLastUpdate()
    }

    setTokenLastUpdate() {
        return localStorage.setItem('tokenLastUpdate', Date.now() + '')
    }

    getRefreshToken() {
        return localStorage.getItem('refreshToken')
    }

    getToken() {
        return localStorage.getItem('token')
    }

    getTokenLastUpdate() {
        return localStorage.getItem('tokenLastUpdate')
    }

    clearTokenRenewal() {
        clearInterval(this.renewalInterval)
        //this.renewalInterval = null
    }
}