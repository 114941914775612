import { Time } from "../../modules/time"

export default class FormPreview {
    tension:Array<Array<number>> = []
    dynamics:Array<Array<number>> = []
    hfc:Array<Array<number>> = []
    lfc:Array<Array<number>> = []
    duration:string = "0"

    segments:Array<{
        name:string,
        layers:Array<string>,
        partial:boolean,
        varied:boolean,
        subsequence:number,
        start:string,
        duration:string
    }> = []

    static fromJSON(object) {
        let formPreview = Object.assign(new FormPreview(), object)

        const lastSegment = formPreview.segments[formPreview.segments.length - 1]
        formPreview.duration = Time.addTwoFractions(lastSegment.start, lastSegment.duration)

        return formPreview
    }

    sortSegments() {
        this.segments.sort((a, b) => {
			let aStart:any = Time.fractionToDictionary(a.start)
            aStart = aStart.numerator / aStart.denominator

            let bStart:any = Time.fractionToDictionary(b.start)
            bStart = bStart.numerator / bStart.denominator
            
            return aStart - bStart
		})
    }

    getSortedLayers(segment) {
        return segment.layers
    }
}