<div class="steps-container">
    <div class="step" *ngFor="let step of steps">
        <div
            class="step-index"
            [ngClass]="{
                'selected-step-index': currentStepIndex == step.index,
                'completed-step-index': currentStepIndex > step.index
            }">
            <span *ngIf="currentStepIndex <= step.index">{{ step.index }}</span>
            <span *ngIf="currentStepIndex > step.index"
                ><img src="assets/img/check.svg"
            /></span>
        </div>
        <div class="step-subtitle" *ngIf="currentStepIndex == step.index">
            {{ step.subtitle }}
        </div>
    </div>
</div>
