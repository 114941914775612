<div
    class="viewport instrument-palette-container"
    [ngClass]="{ 'double-palette-container': type === 'doubles' }"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    (drop)="onDropDouble($event)"
    (dragover)="onDragOver($event)">
    <ngx-simplebar
        class="instrument-palette-scroll"
        [options]="options"
        #verticalScrollbar>
        <div class="instruments-wrapper">
            <div
                *ngFor="let instrument of data; let i = index"
                (mouseenter)="updateHoveredInstrument($event, instrument, true)"
                (mouseleave)="
                    updateHoveredInstrument($event, instrument, false)
                "
                [style.marginTop.px]="i === 0 ? 5 : 0"
                [style.marginBottom.px]="i === data.length - 1 ? 10 : 0"
                [tooltip]="getInstrumentTooltip(instrument)">
                <a-button
                    *ngIf="
                        allowPreview(instrument) &&
                        (previewIsLoading() || hoveredInstrument === instrument)
                    "
                    class="instrument-search-preview"
                    type="round-icon-and-text"
                    [loading]="
                        isDesktopApp() &&
                        instrument.values == null &&
                        previewIsLoading()
                    "
                    [icon]="getPreviewIcon(instrument)"
                    (onClick)="playPreview($event, instrument)">
                </a-button>

                <div
                    [draggable]="
                        recursionLevel !== 0 &&
                        validateSearchItemAction(instrument)
                    "
                    (dragstart)="startDragDouble($event, instrument)"
                    (mousedown)="selectEditDouble($event, instrument, i)"
                    (click)="selectElement($event, instrument, i, false)"
                    class="instrument-palette-list-item"
                    [ngClass]="{
                        'selected-instrument-palette-list-item-blue':
                            searchItemIsSelected(instrument) && instrument.type === 'patch',
                        'selected-instrument-palette-list-item':
                            searchItemIsSelected(instrument),
                        'show-preview-instrument-palette-list-item':
                            allowPreview(instrument) &&
                            (previewIsLoading() ||
                                hoveredInstrument === instrument),
                        'not-recommended':
                            instrumentIsNotRecommended(instrument)
                    }">
                    <span
                        class="instrument-palette-list-item-title"
                        [ngClass]="{
                            'instrument-palette-list-item-title-reduced':
                                instrument.recommended === false &&
                                instrument === hoveredInstrument
                        }"
                        >{{ getName(instrument) }}</span
                    >

                    <img
                        src="assets/img/toggle_right.svg"
                        *ngIf="instrument.values != null" />

                    <img
                        class="why-not-recommended"
                        src="assets/img/question.svg"
                        (mouseenter)="recommendationTooltip = true"
                        (mouseleave)="recommendationTooltip = false"
                        [tooltip]="getRecommendationExplanation(instrument)"
                        [tooltip-width]="300"
                        *ngIf="
                            instrumentIsNotRecommended(instrument) &&
                            instrument === hoveredInstrument
                        " />
                </div>
            </div>

            <div class="instrument-palette-container-empty-space"></div>
        </div>
    </ngx-simplebar>

    <a-button
        *ngIf="
            type === 'doubles' &&
            recursionLevel > 0 &&
            showAdd &&
            allowUserAction
        "
        (onClick)="selectMoreOptions($event)"
        [class]="'instrument-palette-add secondary'"
        [type]="'round-icon-and-text'"
        [icon]="'assets/img/add.svg'"
        [label]="'Add'"></a-button>
</div>

<instrumentpalette
    *ngIf="selectedElement != null && selectedElement.values != null"
    [parentElement]="selectedElement.id"
    [parameters]="parameters"
    [type]="type"
    [selectedSearchResult]="selectedSearchResult"
    [data]="selectedElement.values"
    (valueOutput)="setValueOutput($event)"
    [recursionLevel]="recursionLevel + 1"
    [createdByAiva]="selectedElement.createdByAiva"></instrumentpalette>
