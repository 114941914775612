<div class="modal-title">Select development type(s)</div>
<div class="modal-close"><img src="assets/img/close.svg" (click)="closeModal()"></div>

<div class="form-tags-list-container" [perfectScrollbar]="scrollConfig">
    <div *ngFor="let formTag of getFormTagsDataset()">
        <radio-button 
            [class]="'chord-dataset'" [type]="'checkbox'" [id]="formTag.id" [value]="formTag.id" groupName="formTagsDatasets" [label]="formTag.name"
            [description]="formTag.description" [selectedValue]="isSelectedFormTag(formTag)" (onSelect)="changeSelectedFormTags($event)"
        >
        </radio-button>
    </div>
</div>