import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from "@angular/core"
import { EditorService } from "@services/editor/editor.service"
import { ParentClass } from "../../../parent"
import Layer from "@common-lib/classes/score/layer"
import { ScoreUpdateType } from "../../../../../../common-lib/client-only/score-rendering-engine"
import { MenuOptions } from "../../reusable/menu-options/menu-options.component"
import { LayerModal, ModalService, RenameModal } from "@services/modal.service"
import { Colors } from "@common-lib/types/layer"
import { InitCanvases } from "../../../modules/init-canvases.module"
import {
    MAX_TEMPO,
    MIN_TEMPO,
    TIMESTEP_RES,
} from "@common-lib/constants/constants"
import { SRActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { Time } from "@common-lib/modules/time"
import { PlayerService } from "@services/audio/player/player.service"
import {
    playerActions,
    playerQuery,
} from "../../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { SHORT_SCALES_TO_SCALES_MAP } from "@common-lib/utils/composition-workflow.util"

@Component({
    selector: "top-container",
    templateUrl: "top-container.component.html",
    styleUrls: ["top-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorTopContainerComponent extends ParentClass {
    @Input() editor: EditorService
    public menuOptions: MenuOptions<Layer> | undefined
    public isEditingTempo: boolean = false
    public tempo: number = 120

    @ViewChild("tempoInput", { static: false }) tempoInput: ElementRef

    constructor(
        public ref: ChangeDetectorRef,
        private modalService: ModalService,
        private playerService: PlayerService,
        private editorService: EditorService
    ) {
        super()
    }

    public get engine() {
        return this.editorService.engine
    }

    public get layers() {
        const layers = this.editor.engine.getLayers()

        return Object.keys(layers).map(key => layers[key])
    }

    public get enableChordsEditingInEditor(): boolean {
        return featureFlags.enableChordsEditingInEditor
    }

    public get editKeySignature(): boolean {
        return featureFlags.editKeySignature
    }

    public get service() {
        return this.editorService
    }

    async ngAfterViewInit() {
        await this.initCanvases()

        this.subscribe(
            this.editor.engine.scoreUpdate$,
            async (value: ScoreUpdateType) => {
                if (value.includes("Layer")) {
                    this.ref.detectChanges()
                }

                if (value.includes("All")) {
                    await this.initCanvases()
                }
            }
        )
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
    }

    public getOrderedLayers(): Layer[] {
        const layers = this.layers.sort((a, b) => {
            return a.order - b.order
        })

        return layers
    }

    public startEditingTempo() {
        this.tempo = this.editor.engine.score.tempoMap[0].bpm
        this.isEditingTempo = true

        this.ref.detectChanges()

        this.tempoInput.nativeElement.focus()
    }

    private clearCanvases() {
        this.editor.engine.deleteCanvas("TempoCanvas")
        this.editor.engine.deleteCanvas("PianorollGridCanvas")
        this.editor.engine.deleteCanvas("LayerPreviewCanvas")
        this.editor.engine.deleteCanvas("KeySignatureEditingCanvas")
        this.editor.engine.deleteCanvas("ChordsLabelCanvas")

        for (const layerKey in this.editor.engine.getLayers()) {
            const layer = this.editor.engine.getLayers()[layerKey]
            this.editor.engine.deleteCanvas("LayerPreviewCanvas_" + layer.value)
        }
    }

    private async initCanvases() {
        this.ref.detectChanges()

        this.clearCanvases()

        await InitCanvases.initPianoRollGridCanvas(this.editor.engine, false)
        await InitCanvases.initTempoCanvas(this.editor.engine)

        if (!this.enableChordsEditingInEditor) {
            await InitCanvases.initChordsLabelCanvas(this.editor.engine)
        }

        for (const layerKey in this.editor.engine.getLayers()) {
            const layer = this.editor.engine.getLayers()[layerKey]
            await InitCanvases.initLayerPreviewCanvas(
                layer,
                EditorService.LAYER_PREVIEW_GUTTER_PX,
                this.editor.engine
            )
        }

        if (featureFlags.editKeySignature) {
            await InitCanvases.initKeySignatureEditingCanvas(
                this.editor.engine,
                ((index: number) => {
                    this.modalService.modals.setKeySignature.next({
                        keySignature:
                            this.editor.engine.score.keySignatures[index][1],
                        onComplete: (key: string) => {
                            this.editor.engine.srEmitter$.next({
                                type: SRActionTypes.setKeySignature,
                                data: {
                                    keySignature: key,
                                    index: index,
                                },
                                options: {
                                    isUndoable: true,
                                },
                            })
                        },
                    })
                }).bind(this)
            )
        }
    }

    public async editTempo(value: number) {
        const timeElapsedInFraction = Time.secondsToFraction(
            playerQuery.timeElapsed,
            playerActions.hasStartOffset(),
            TIMESTEP_RES,
            this.editor.engine.score.tempoMap
        )

        this.playerService.setRealtimePlayback(this.engine)

        const isPlaying = playerQuery.status === "playing"

        if (isPlaying) {
            await this.playerService.pause()
        }

        this.editor.engine.srEmitter$.next({
            type: SRActionTypes.setTempo,
            data: {
                tempo: value,
            },

            options: {
                isUndoable: true,
            },
        })

        if (isPlaying) {
            const newTimeElapsed = Time.fractionToSeconds(
                TIMESTEP_RES,
                this.editor.engine.score.tempoMap,
                timeElapsedInFraction
            )

            this.playerService.setTimeFromSeconds(newTimeElapsed)

            await this.playerService.play()
        }

        this.isEditingTempo = false
    }

    public onTempoKeyup(event: KeyboardEvent) {
        const formatting = (value: number) => {
            return Math.max(MIN_TEMPO, Math.min(MAX_TEMPO, value))
        }

        if (event.key === "Enter") {
            this.tempo = formatting(this.tempo)

            return this.editTempo(this.tempo)
        }
    }

    protected openSettings(event: MouseEvent, layer: Layer) {
        event.stopImmediatePropagation()

        this.menuOptions = {
            options: [
                {
                    icon: "assets/img/edit.svg",
                    text: "Rename",
                    data: layer,
                    onClick: this.openRenameLayerModal.bind(this),
                    loading: false,
                },

                {
                    icon: "assets/img/menu/duplicate.svg",
                    text: "Duplicate",
                    data: layer,
                    onClick: this.setDuplicatedLayerModal.bind(this),
                    loading: false,
                },

                {
                    icon: "assets/img/color.svg",
                    text: "Change color",
                    data: layer,
                    onClick: this.openLayerColorModal.bind(this),
                    loading: false,
                },

                {
                    icon: "assets/img/menu/delete.svg",
                    text: "Delete " + layer.getName(),
                    buttonClass: "delete",
                    data: layer,
                    onClick: this.deleteLayer.bind(this),
                    loading: false,
                },
            ],
            coordinates: {
                x: event.x,
                y: event.y,
            },
        }

        this.ref.detectChanges()
    }

    protected openLayerColorModal(layer: Layer) {
        this.closeLayerSettings()

        const layerModal: LayerModal = {
            layer: layer,
            onComplete: ((color: Colors, layer: Layer) => {
                this.editor.engine.setLayerColor(color, layer)
            }).bind(this),
        }

        this.modalService.modals.changeLayerColor.next(layerModal)
    }

    protected setDuplicatedLayerModal(layer: Layer) {
        this.closeLayerSettings()

        const layerModal: RenameModal = {
            name: layer.getName(),
            title: "Name of duplicated layer",
            onComplete: ((name: string) => {
                this.editor.engine.duplicateLayer(layer, name)
            }).bind(this),
        }

        this.modalService.modals.rename.next(layerModal)
    }

    protected openRenameLayerModal(layer: Layer) {
        this.closeLayerSettings()

        const layerModal: RenameModal = {
            name: layer.getName(),
            onComplete: ((name: string) => {
                this.editor.engine.setLayerName(name, layer)
            }).bind(this),
        }

        this.modalService.modals.rename.next(layerModal)
    }

    protected deleteLayer(layer: Layer) {
        this.editor.engine.deleteLayer(layer)
        this.closeLayerSettings()
    }

    protected solo(layer: Layer) {
        this.editor.engine.solo(layer)
        this.ref.detectChanges()
    }

    protected mute(layer: Layer) {
        this.editor.engine.mute(layer)
        this.ref.detectChanges()
    }

    protected closeLayerSettings() {
        this.menuOptions = undefined
        this.ref.detectChanges()
    }
}
