import {
    Component,
    OnInit,
    ElementRef,
    NgZone,
    HostListener,
    ViewChild,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    EventEmitter,
    Output,
} from "@angular/core"
import { ApiService } from "../../services/api.service"
import { PlayerService } from "../../services/audio/player/player.service"
import { Router } from "@angular/router"
import { ModalService, RenameModal } from "../../services/modal.service"
import { CompositionService } from "../../services/composition.service"
import { FolderService } from "../../services/folder.service"
import { AnalyticsService } from "../../services/analytics.service"
import { Composition } from "@common-lib/classes/general/composition"
import {
    PerfectScrollbarDirective,
    PerfectScrollbarComponent,
} from "ngx-perfect-scrollbar"
import { environment } from "../../../environments/environment"
import { BillingService } from "../../services/billing.service"
import { InfluenceService } from "../../services/influence.service"
import { DeviceDetectorService } from "ngx-device-detector"
import { GeneralService } from "../../services/general/general.service"
import { ActivityMetric } from "../../../../../common-lib/general/classes/activitymetric"
import { TracksService } from "../../services/tracks.service"
import { PlaylistService } from "../../services/playlist.service"
import { DesignService } from "../../services/design.service"
import { debounceTime, takeUntil } from "rxjs/operators"
import { fromEvent } from "rxjs"
import { ClipboardService } from "ngx-clipboard"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { SupportChatService } from "@services/supportchat.service"
import { Misc } from "@common-lib/modules/misc"
import { playerQuery } from "../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { ParentClass } from "../../parent"
import { ShortcutsService } from "@services/shortcuts.service"
import { GenerationProfileHTTPService } from "@services/generation-profile/generationprofile.http"
import { UIContentType } from "@common-lib/types/general"
import { MenuOptions } from "../reusable/menu-options/menu-options.component"
import { TutorialService } from "@services/tutorial.service"
import { UserService } from "@services/user.service"

@Component({
    selector: "tracks-list",
    templateUrl: "trackslist.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [],
})
export class TrackslistComponent extends ParentClass implements OnInit {
    @Input() allowMoving: boolean = true
    @Input() allowFolders: boolean = true
    @Output() openCreateTrack: EventEmitter<MouseEvent> =
        new EventEmitter<MouseEvent>()

    cursorPositionListener

    compositionsLoading = false

    scrollOffset = 0

    scrollWindow = {
        start: -400,
        end: 1000,
    }

    compositionParametersIndex: number = -1
    contentIndex: number = -1
    subFolderIndex: number = -1

    dragBlockContent
    dragTimeout

    useAsInfluenceLoading = false
    moveMenuOnTheRight = false

    cursorPosition = { x: 0, y: 0 }

    content: Array<any>
    subFolders: Array<any>
    embedType: UIContentType = "Compositions"

    parentFolderIsHovered = false

    rightClickMenuX = 0
    rightClickMenuY = 0

    moveMenuX = 0
    moveMenuY = 0

    rightClickMenuLimitX = 200
    rightClickMenuLimitY = 390

    rightClickMenuLimitFolderY = 230

    manySelectionMenuLimitX = 200
    manySelectionMenuLimitY = 155

    showMoveMenu = false

    innerWidth = 0
    innerHeight = 0

    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    path = []

    multipleSelection
    initialSelection
    multiSelectionMenu = false
    mobileUse = false

    scrollToBottom = false

    trackViewContainerHeight = 0

    trackView: string

    @ViewChild(PerfectScrollbarDirective, { static: false })
    perfectscrollGrid: PerfectScrollbarDirective
    @ViewChild(PerfectScrollbarComponent, { static: false })
    public directiveScroll: PerfectScrollbarComponent

    @ViewChild("rightClickMenuComposition")
    rightClickMenuComposition: ElementRef
    @ViewChild("rightClickMenuInfluence") rightClickMenuInfluence: ElementRef
    @ViewChild("rightClickMenuSubfolder") rightClickMenuSubfolder: ElementRef
    @ViewChild("rightClickMenuGP") rightClickMenuGP: ElementRef
    @ViewChild("rightClickMenuMultiSelection")
    rightClickMenuMultiSelection: ElementRef
    @ViewChild("rightClickMoveMenu") rightClickMoveMenu: ElementRef

    sidebarWidth = 205

    cwSettings: MenuOptions<string> | undefined
    createTrackSettings: MenuOptions<string> | undefined

    constructor(
        protected gpService: GenerationProfileService,
        protected gpHttpService: GenerationProfileHTTPService,
        protected analyticsService: AnalyticsService,
        protected modalService: ModalService,
        protected apiService: ApiService,
        protected influenceService: InfluenceService,
        protected compositionService: CompositionService,
        protected router: Router,
        protected playerService: PlayerService,
        protected elRef: ElementRef,
        protected ref: ChangeDetectorRef,
        protected folderService: FolderService,
        protected billingService: BillingService,
        protected device: DeviceDetectorService,
        protected generalService: GeneralService,
        protected tracksService: TracksService,
        protected playlistService: PlaylistService,
        protected designService: DesignService,
        protected clipboardService: ClipboardService,
        protected zone: NgZone,
        protected supportChatService: SupportChatService,
        protected shortcuts: ShortcutsService,
        private tutorialService: TutorialService,
        private userService: UserService
    ) {
        super()
    }

    async detectChanges() {
        this.innerWidth = window.innerWidth
        this.innerHeight = window.innerHeight

        if (!this.ref["destroyed"]) {
            this.ref.detectChanges()
        }

        if (this.scrollToBottom) {
            this.scrollToBottom = false

            let counter = 10

            while (counter > 0) {
                if (this.perfectscrollGrid && !this.compositionsLoading) {
                    this.perfectscrollGrid.scrollToBottom(0, 100)

                    break
                }

                await Misc.wait(0.1)

                counter -= 1
            }
        }
    }

    ngOnInit() {
        this.resetSelection()

        this.detectDevice()

        this.detectChanges()

        fromEvent(window, "resize")
            .pipe(takeUntil(this.destroy$))
            .pipe(debounceTime(100))
            .subscribe(event => {
                this.onResize(event)
            })
    }

    getGPKeySignature(gp: GenerationProfile) {
        return (
            gp.harmony.keySignature?.pitchClass +
            " " +
            gp.harmony.keySignature?.keyMode
        )
    }

    getGPTempo(gp: GenerationProfile) {
        return gp.settings.tempoRange.min + " - " + gp.settings.tempoRange.max
    }

    getGPTimeSignature(gp: GenerationProfile) {
        return gp.settings.timeSignature[0] + "/" + gp.settings.timeSignature[1]
    }

    isAdmin() {
        return this.apiService.isAdmin()
    }

    onResize(event) {
        this.innerWidth = window.innerWidth
        this.innerHeight = window.innerHeight
        this.detectDevice(event)
    }

    ngAfterViewInit() {
        this.resetScrollWindow()

        this.subscribe(this.folderService.isLoading, value => {
            this.compositionsLoading = value

            this.resetScrollWindow()

            this.detectChanges()
        })

        this.subscribe(this.folderService.contentType, async type => {
            this.embedType = type

            this.detectChanges()
        })

        this.subscribe(this.folderService.subFolders, value => {
            this.subFolders = value

            this.setTrackViewContainerHeight()

            this.detectChanges()
        })

        this.subscribe(this.folderService.content, value => {
            this.content = value

            this.setTrackViewContainerHeight()

            this.detectChanges()
        })

        this.subscribe(this.folderService.path, value => {
            if (this.path != value) {
                this.path = value

                this.resetScrollWindow()

                this.detectChanges()
            }
        })

        this.subscribe(this.folderService.justCreatedFolder, value => {
            if (value == true && this.perfectscrollGrid != null) {
                setTimeout(() => {
                    var folders = this.folderService.subFolders.getValue()
                    var scrollTo =
                        -window.innerHeight + 600 + (folders.length - 1) * 43

                    if (scrollTo < 0) {
                        scrollTo = 0
                    }

                    if (this.perfectscrollGrid) {
                        this.perfectscrollGrid.scrollTo(scrollTo)
                    }

                    this.detectChanges()
                }, 20)
            }
        })

        this.subscribe(this.gpService.justCreatedComposition, value => {
            if (value) {
                this.scrollToBottomOfTrackList()
            }
        })

        this.subscribe(this.modalService.justCreatedComposition, value => {
            if (value) {
                this.scrollToBottomOfTrackList()
            }
        })

        this.subscribe(this.influenceService.scrollToBottom, value => {
            if (value) {
                this.scrollToBottomOfTrackList()
            }
        })

        this.subscribe(this.tracksService.trackView, viewName => {
            this.trackView = viewName

            this.detectChanges()
        })

        this.subscribe(this.designService.sidebarWidth, width => {
            this.sidebarWidth = width
        })

        this.subscribe(playerQuery.allState$, (composition: Composition) => {
            this.detectChanges()
        })

        this.detectChanges()
    }

    scrollToBottomOfTrackList() {
        this.scrollToBottom = true

        this.detectChanges()
    }

    setTrackViewContainerHeight() {
        var subFoldersLength =
            this.subFolders != null ? this.subFolders.length : 0
        var compositionsLength = this.content != null ? this.content.length : 0

        this.trackViewContainerHeight =
            subFoldersLength * 45 + compositionsLength * 45
    }

    resetSelection() {
        this.initialSelection = { type: null, index: 0 }
        this.multipleSelection = {
            start: { type: null, index: 0 },
            end: { type: null, index: 0 },
        }

        this.detectChanges()
    }

    trackByFolder(index, folder) {
        return folder._id
    }

    public openCWSettings(
        event: MouseEvent,
        compositionWorkflowID: string | undefined
    ) {
        if (
            compositionWorkflowID === undefined ||
            this.cwSettings !== undefined
        ) {
            this.cwSettings = undefined
            return
        }

        event.stopImmediatePropagation()

        this.cwSettings = {
            options: [
                {
                    data: compositionWorkflowID,
                    icon: "assets/img/add.svg",
                    text: "Create Composition",
                    loading: false,
                    onClick: (() => {
                        this.modalService.modals.cwCreateComposition.next({
                            folderID: this.folderService.getSelectedFolderID(),
                            cwID: compositionWorkflowID,
                        })
                    }).bind(this),
                },

                {
                    data: compositionWorkflowID,
                    icon: "assets/img/edit.svg",
                    text: "Edit Workflow",
                    loading: false,
                    onClick: (() => {
                        this.router.navigate([
                            "composition-workflow",
                            compositionWorkflowID,
                        ])
                    }).bind(this),
                },
            ],
            coordinates: {
                x: event.x,
                y: event.y,
            },
        }
    }

    trackByComposition(index, composition) {
        return composition._id
    }

    hoveredMenu(element, event, type) {
        if (element == "moveTo") {
            this.moveMenu(event, type)
        } else {
            this.showMoveMenu = false
        }
    }

    moveMenu(event, type): void {
        this.showMoveMenu = true

        this.detectChanges() // necessary for computation of ViewChild dimensions

        var rightClickMenuElement = this.getRightClickMenuElement(type)

        if (!rightClickMenuElement) {
            this.detectChanges()

            return
        }

        var rightClickMenu = this.getRightClickMenuElement(type).nativeElement
        var combinedWidth =
            rightClickMenu.offsetWidth +
            this.rightClickMoveMenu.nativeElement.offsetWidth

        if (event.x + combinedWidth >= this.innerWidth) {
            this.moveMenuX = -250
            this.moveMenuOnTheRight = true
        } else {
            this.moveMenuX = rightClickMenu.offsetWidth
            this.moveMenuOnTheRight = false
        }

        this.moveMenuY = this.rightClickMenuY + rightClickMenu.offsetHeight + 6

        this.detectChanges()
    }

    onScrollYEvent(event) {
        this.scrollOffset = event.srcElement.scrollTop

        this.scrollWindow.start = this.scrollOffset - 300
        this.scrollWindow.end = this.scrollOffset + window.innerHeight + 200

        this.detectChanges()
    }

    shouldDisplayElement(i, content, type) {
        var noteTopOffset = 45 * i

        if (type != "folder") {
            noteTopOffset += this.subFolders.length * 45
        }

        var value =
            noteTopOffset >= this.scrollWindow.start &&
            noteTopOffset <= this.scrollWindow.end

        if (!value) {
            content.isHovered = false
        }

        return value
    }

    renameGP(event, gp: GenerationProfile) {
        event.stopPropagation()
        this.modalService.modals.renameGenerationProfile.next(gp)
        this.cancelMoreOptions()
        this.resetSelection()
    }

    openGenerationProfile(gpID) {
        this.gpService.openGenerationProfile(gpID)
    }

    async initiateCompositionWorkflow(generationProfileID: string) {
        const res = await this.gpHttpService.getGPByID(generationProfileID)
        const gp = GenerationProfile.fromJSON(res.generationProfile)
        const validation = this.gpService.validateGenerationProfile(gp, true)

        if (validation.data.valid && validation.ui.valid) {
            const type = "stepByStep"

            this.router.navigate([
                "composition-workflow",
                type,
                generationProfileID,
            ])
        } else {
            this.apiService.handleError(
                "This generation profile contains errors. Please fix them before continuing."
            )
        }
    }

    isSelectedProject(projectID): boolean {
        const composition = this.content[this.contentIndex]

        if (
            composition["project"] &&
            projectID == composition["project"]["_id"]
        ) {
            return true
        }

        return false
    }

    downloadComposition(compositionIndex): void {
        const composition = this.content[compositionIndex]

        this.modalService.downloadComposition.next(composition)

        this.cancelMoreOptions()
    }

    downloadGPBugReport(index) {
        const gp = this.content[index]

        this.gpService
            .downloadBugReport(gp)

            .then(() => {
                this.cancelMoreOptions()
            })
    }

    downloadBugReport(influenceIndex) {
        const influence = this.content[influenceIndex]

        this.influenceService
            .downloadInfluenceBugReport(influence)

            .then(() => {
                this.cancelMoreOptions()
            })
    }

    showBugReportOption() {
        return !environment.production
    }

    downloadInfluence(influenceIndex): void {
        const influence = this.content[influenceIndex]

        this.influenceService
            .downloadInfluence(influence)

            .then(() => {
                this.cancelMoreOptions()

                this.analyticsService.addActivity(
                    ActivityMetric.DOWNLOAD_INFLUENCE,
                    {
                        influenceID: influence._id,
                    }
                )
            })
    }

    getTopTrackViewList() {
        if (this.getPreviousFolder() != null && !this.compositionsLoading) {
            return 45
        } else {
            return 0
        }
    }

    duplicateGenerationProfile(gp: GenerationProfile) {
        this.gpService.duplicateGenerationProfile(gp)

        this.cancelMoreOptions()
    }

    getRandomNumber() {
        return Math.random()
    }

    setCursorPosition(event): void {
        const trackslistWidth = 1600
        const trackslistPadding = 20
        const navbarWidth = this.sidebarWidth

        if (
            window.innerWidth >
            navbarWidth + trackslistWidth + trackslistPadding
        ) {
            this.cursorPosition.x =
                event.x -
                (window.innerWidth -
                    navbarWidth -
                    trackslistWidth -
                    trackslistPadding)
        } else {
            this.cursorPosition.x = event.x - navbarWidth
        }

        this.cursorPosition.y = event.y - 190 + this.scrollOffset

        this.detectChanges()
    }

    renameTrack(event, compositionIndex): void {
        event.stopPropagation()

        this.modalService.modals.rename.next({
            name: this.content[compositionIndex].name,
            onComplete: ((name: string) => {
                const type = this.folderService.compositionsOrInfluences()

                this.analyticsService.addActivity(ActivityMetric.RENAME, {
                    type: type == "influences" ? "influence" : "composition",
                })

                this.compositionService.rename(
                    name,
                    type,
                    this.content[compositionIndex]._id
                )
                this.modalService.modals.rename.next(undefined)
            }).bind(this),
        } as RenameModal)

        this.cancelMoreOptions()
    }

    renameFolder(event, folderIndex): void {
        event.stopPropagation()

        this.modalService.modals.rename.next({
            name: this.subFolders[folderIndex].name,
            onComplete: (async (name: string) => {
                await this.folderService.renameFolder(
                    name,
                    this.subFolders[folderIndex]._id
                )

                this.analyticsService.addActivity(ActivityMetric.RENAME, {
                    type: "folder",
                })

                this.modalService.modals.rename.next(undefined)
            }).bind(this),
        } as RenameModal)

        this.cancelMoreOptions()

        this.detectChanges()
    }

    copyCompositionID(event, compositionIndex): void {
        if (
            this.content[compositionIndex] &&
            this.content[compositionIndex]._id
        ) {
            this.clipboardService.copy(this.content[compositionIndex]._id)
        }

        this.cancelMoreOptions()
    }

    createAndMoveMany() {
        var elementsToMove = this.getSelectedItems()

        this.folderService.createFolder().then(newFolder => {
            this.folderService.moveManyToFolder(elementsToMove, newFolder._id)
        })

        this.cancelMoreOptions()
        this.resetSelection()
    }

    getInfluenceEnsemble(influence) {
        if (influence != null && influence.parameters != null) {
            return influence.parameters.ensemble
        }

        return ""
    }

    getInfluenceKeySignature(influence) {
        if (influence != null && influence.parameters != null) {
            return (
                influence.parameters.pitchClass +
                " " +
                influence.parameters.keyMode
            )
        }

        return ""
    }

    getInfluenceTempo(influence) {
        if (influence != null && influence.parameters != null) {
            return influence.parameters.tempo
        }

        return ""
    }

    getInfluenceTimeSignature(influence) {
        if (influence != null && influence.parameters != null) {
            return (
                influence.parameters.timeSignature[0] +
                "/" +
                influence.parameters.timeSignature[1]
            )
        }

        return ""
    }

    shareTrack(): void {
        const composition = this.content[this.contentIndex]

        this.modalService.shareComposition.next(composition)

        this.cancelMoreOptions()
    }

    shareFolder(event, folderIndex) {
        const folder = this.subFolders[folderIndex]
        this.modalService.shareFolder.next(folder)

        this.cancelMoreOptions()
        event.stopPropagation()
    }

    toggleLike(index): void {
        const composition = this.content[index]

        const compositionID = composition._id
        const liked = !composition.liked

        composition.liked = liked

        this.detectChanges()

        this.compositionService
            .like(compositionID, composition.contentType, liked)

            .catch(err => {
                composition.liked = !liked

                this.apiService.handleError(err)
            })
    }

    resetScrollWindow(useActualHeight = true) {
        this.scrollOffset = 0

        let end = 1000

        if (useActualHeight) {
            end = this.innerHeight - 200
        }

        this.scrollWindow = {
            start: -400,
            end: end,
        }
    }

    deleteFolder(): void {
        const folder = this.subFolders[this.subFolderIndex]

        this.modalService.deleteFolder.next(folder)
    }

    deleteComposition(index): void {
        if (!index && index !== 0) {
            this.modalService.deleteComposition.next(
                this.content[this.contentIndex]
            )
        } else {
            this.modalService.deleteComposition.next(this.content[index])
        }

        this.cancelMoreOptions()
    }

    releaseDrag() {
        window.document.removeEventListener(
            "mousemove",
            this.cursorPositionListener
        )

        if (this.dragTimeout != null) {
            clearTimeout(this.dragTimeout)
        }

        this.dragBlockContent = null
    }

    releaseDragFolder(folderID) {
        var promise = Promise.resolve(false)

        if (this.dragBlockContent == null) {
            // Do nothing
        } else if (this.dragBlockContent.type == "folder") {
            if (this.dragBlockContent._id != folderID) {
                promise = this.folderService.moveFolderToFolder(
                    this.dragBlockContent._id,
                    folderID
                )
            }
        } else if (
            this.dragBlockContent.type == "composition" ||
            this.dragBlockContent.type == "influence" ||
            this.dragBlockContent.type == "generationProfiles"
        ) {
            promise = this.folderService.moveContentToFolder(
                this.dragBlockContent._id,
                folderID
            )
        } else if (this.dragBlockContent.type == "many") {
            promise = this.folderService.moveManyToFolder(
                this.dragBlockContent,
                folderID
            )
        }

        this.releaseDrag()

        promise.then(shouldReset => {
            if (shouldReset) {
                this.resetSelection()
            }
        })
    }

    multiDelete() {
        var deleteObject = this.getSelectedItems()

        this.modalService.deleteMulti.next(deleteObject)

        this.cancelMoreOptions()
    }

    shareGP() {
        this.modalService.modals.shareGP.next(this.content[this.contentIndex])

        this.cancelMoreOptions()
    }

    getSelectedItems() {
        var result = {
            compositions: [],
            folders: [],
        }

        if (
            this.multipleSelection.start.type == this.multipleSelection.end.type
        ) {
            if (
                this.multipleSelection.start.type == "composition" ||
                this.multipleSelection.start.type == "influence" ||
                this.multipleSelection.start.type == "generationProfiles"
            ) {
                result.compositions = this.content.slice(
                    this.multipleSelection.start.index,
                    this.multipleSelection.end.index + 1
                )
            } else {
                result.folders = this.subFolders.slice(
                    this.multipleSelection.start.index,
                    this.multipleSelection.end.index + 1
                )
            }
        } else {
            result.folders = this.subFolders.slice(
                this.multipleSelection.start.index,
                this.subFolders.length
            )
            result.compositions = this.content.slice(
                0,
                this.multipleSelection.end.index + 1
            )
        }

        for (var c = 0; c < result.compositions.length; c++) {
            result.compositions[c] = result.compositions[c]._id
        }

        for (var f = 0; f < result.folders.length; f++) {
            result.folders[f] = result.folders[f]._id
        }

        return result
    }

    releaseDragComposition(compositionID) {
        this.releaseDrag()
    }

    startDragging(content, type) {
        var multiSelectionContent = this.getSelectedItems()
        var selectionNumber = this.getMultiSelectionNumber()

        this.dragTimeout = setTimeout(() => {
            if (selectionNumber > 1) {
                this.dragBlockContent = multiSelectionContent
                this.dragBlockContent.type = "many"
            } else {
                this.dragBlockContent = content
                this.dragBlockContent.type = type
            }
        }, 100)
    }

    clickOnFolderListItem(event, subFolderIndex): void {
        if (this.cursorPositionListener != null) {
            window.document.removeEventListener(
                "mousemove",
                this.cursorPositionListener
            )
        }

        this.cursorPositionListener = this.setCursorPosition.bind(this)

        window.document.addEventListener(
            "mousemove",
            this.cursorPositionListener
        )

        if (event.which == 1 && event.shiftKey) {
            this.selectMultipleItems(subFolderIndex, "folder")
        } else {
            if (!this.partOfMultiSelection(subFolderIndex, "folder")) {
                this.resetSelection()
            }

            if (
                !event.shiftKey &&
                (event.which == 1 || event.which == 3) &&
                !this.isInsideMultiSelection(subFolderIndex, "folder")
            ) {
                this.selectMultipleItems(subFolderIndex, "folder")
            }

            if (event.which == 1) {
                this.startDragging(this.subFolders[subFolderIndex], "folder")
            } else {
                this.moreOptions(event, subFolderIndex, -1, false, "subfolder")
            }
        }
    }

    clickOnInfluenceListItem(event, compositionIndex): void {
        if (this.influenceService.createWithInfluenceMode) {
            this.createWithInfluence(this.content[compositionIndex]._id)

            this.influenceService.createWithInfluenceMode = false
        } else {
            this.clickOnListItem(event, compositionIndex, "influence")
        }
    }

    clickOnListItem(event, index, type): void {
        if (this.cursorPositionListener != null) {
            window.document.removeEventListener(
                "mousemove",
                this.cursorPositionListener
            )
        }

        this.cursorPositionListener = this.setCursorPosition.bind(this)

        window.document.addEventListener(
            "mousemove",
            this.cursorPositionListener
        )

        if (event.which == 1 && event.shiftKey) {
            this.selectMultipleItems(index, type)
        } else {
            if (!this.partOfMultiSelection(index, type)) {
                this.resetSelection()
            }

            if (
                !event.shiftKey &&
                (event.which == 1 || event.which == 3) &&
                !this.isInsideMultiSelection(index, type)
            ) {
                this.selectMultipleItems(index, type)
            }

            if (event.which == 1) {
                this.startDragging(this.content[index], type)
            } else {
                let type = "composition"

                if (this.embedType == "Influences") {
                    type = "influence"
                } else if (this.embedType == "Styles") {
                    type = "generationProfiles"
                }

                this.moreOptions(event, -1, index, false, type)
            }
        }
    }

    createWithInfluenceWithIndex(index) {
        this.createWithInfluence(this.content[index]._id)
        this.cancelMoreOptions()
    }

    createWithInfluence(id) {
        this.modalService.createWithInfluence.next(id)
    }

    isInsideMultiSelection(index, type) {
        if (
            this.multipleSelection.start.type == this.multipleSelection.end.type
        ) {
            return (
                type == this.multipleSelection.start.type &&
                index >= this.multipleSelection.start.index &&
                index <= this.multipleSelection.end.index
            )
        }

        if (type == this.multipleSelection.start.type) {
            return index >= this.multipleSelection.start.index
        }

        return index <= this.multipleSelection.end.index
    }

    getMultiSelectionNumber() {
        var compositions = 0
        var folders = 0

        if (
            this.multipleSelection.start.type == this.multipleSelection.end.type
        ) {
            if (
                this.multipleSelection.start.type !== "composition" ||
                this.multipleSelection.start.type == "influence" ||
                this.multipleSelection.start.type == "generationProfiles"
            ) {
                compositions =
                    this.multipleSelection.end.index -
                    this.multipleSelection.start.index +
                    1
            } else {
                folders =
                    this.multipleSelection.end.index -
                    this.multipleSelection.start.index +
                    1
            }
        } else {
            folders =
                this.subFolders.length - this.multipleSelection.start.index
            compositions = this.multipleSelection.end.index + 1
        }

        return folders + compositions
    }

    selectMultipleItems(selectedIndex, type) {
        var firstSelection = this.multipleSelection.start
        var start
        var end

        if (firstSelection.type == null) {
            start = { type: type, index: selectedIndex }
            end = { type: type, index: selectedIndex }
            this.initialSelection = { type: type, index: selectedIndex }
        } else if (selectedIndex >= firstSelection.index) {
            if (
                type == firstSelection.type ||
                ((type == "composition" ||
                    type == "influence" ||
                    type == "generationProfiles") &&
                    firstSelection.type == "folder")
            ) {
                start = this.initialSelection
                end = { type: type, index: selectedIndex }
            } else {
                end = this.initialSelection
                start = { type: type, index: selectedIndex }
            }
        } else {
            if (
                (type == "composition" ||
                    type == "influence" ||
                    type == "generationProfiles") &&
                firstSelection.type == "folder"
            ) {
                start = this.initialSelection
                end = { type: type, index: selectedIndex }
            } else {
                end = this.initialSelection
                start = { type: type, index: selectedIndex }
            }
        }

        if (
            (start.type == "composition" ||
                start.type == "influence" ||
                start.type == "generationProfiles") &&
            end.type == "folder"
        ) {
            var temp = end
            end = start
            start = temp
        }

        this.multipleSelection.start = start
        this.multipleSelection.end = end
    }

    partOfMultiSelection(index, type) {
        var multiType = false

        if (
            this.multipleSelection.start.type != this.multipleSelection.end.type
        ) {
            multiType = true
        }

        if (multiType && type == this.multipleSelection.end.type) {
            return index <= this.multipleSelection.end.index
        } else if (multiType && type == this.multipleSelection.start.type) {
            return index >= this.multipleSelection.start.index
        } else if (type == this.multipleSelection.start.type) {
            return (
                index >= this.multipleSelection.start.index &&
                index <= this.multipleSelection.end.index
            )
        }

        return false
    }

    moreOptions(
        event,
        subFolderIndex,
        compositionIndex,
        clickedOnButton,
        type = null
    ): void {
        this.cancelMoreOptions()

        if (!clickedOnButton && event.which != 3) {
            return
        }

        // Define multi or single selection
        if (
            this.multipleSelection.start.type !=
                this.multipleSelection.end.type ||
            this.multipleSelection.start.index !=
                this.multipleSelection.end.index
        ) {
            this.multiSelectionMenu = true
            type = "multiselection"
        } else {
            this.contentIndex = compositionIndex
            this.subFolderIndex = subFolderIndex
        }

        this.detectChanges() // needed so the ViewChild elements can be detected

        const rightClickMenuElement = this.getRightClickMenuElement(type)
        const menuWidth = rightClickMenuElement.nativeElement.offsetWidth
        const menuHeight = rightClickMenuElement.nativeElement.offsetHeight
        const bufferWidth = 50
        const bufferHeight = 50

        // define width
        if (event.x > this.innerWidth - menuWidth - bufferWidth) {
            this.rightClickMenuX = event.x - menuWidth
        } else {
            this.rightClickMenuX = event.x
        }

        // define height
        if (event.y > this.innerHeight - menuHeight - bufferHeight) {
            this.rightClickMenuY = event.y - menuHeight
        } else {
            this.rightClickMenuY = event.y
        }

        this.detectChanges()

        event.stopPropagation()
    }

    cancelMoreOptions(): void {
        this.contentIndex = -1
        this.subFolderIndex = -1
        this.showMoveMenu = false
        this.multiSelectionMenu = false
    }

    isComposed(isFinished): boolean {
        if (isFinished) {
            return true
        }

        return false
    }

    mouseOnSubFolder(index, value): void {
        for (var i = 0; i < this.content.length; i++) {
            this.content[i].isHovered = false
        }

        for (var f = 0; f < this.subFolders.length; f++) {
            this.subFolders[f].isHovered = false
        }

        this.subFolders[index].isHovered = true

        this.detectChanges()
    }

    mouseOnComposition(index, value): void {
        for (var i = 0; i < this.content.length; i++) {
            this.content[i].isHovered = false
        }

        for (var f = 0; f < this.subFolders.length; f++) {
            this.subFolders[f].isHovered = false
        }

        this.content[index].isHovered = true

        this.detectChanges()
    }

    mouseOnInfluence(index, value): void {
        for (var i = 0; i < this.content.length; i++) {
            this.content[i].isHovered = false
        }

        for (var f = 0; f < this.subFolders.length; f++) {
            this.subFolders[f].isHovered = false
        }

        this.content[index].isHovered = true

        this.detectChanges()
    }

    isProduction() {
        return environment.production
    }

    isLocal() {
        return environment.domain.includes("localhost")
    }

    createGPCategory(event, subFolderIndex) {
        let folderID = this.subFolders[subFolderIndex]._id

        this.apiService.authRequest(
            "/generationprofile/createCategory",
            { folderID: folderID },
            "primary",
            true
        )

        this.cancelMoreOptions()
    }

    editInPianoRoll(): void {
        const composition = this.content[this.contentIndex]

        const isPlaying = this.playerService.isPlaying()

        this.playerService.switchToPianoRoll(
            composition._id,
            composition.contentType,
            isPlaying
        )
    }

    play(index): void {
        const composition = this.folderService.getContentAtIndex(index)
        const compositionID = composition["_id"]

        if (
            playerQuery.content !== undefined &&
            compositionID === playerQuery.contentID
        ) {
            this.playerService.play()
        } else {
            this.playerService.loadNewTrack(
                compositionID,
                composition.contentType,
                true,
                false
            )
        }

        if (this.trackView === "trackslist") {
            this.playlistService.setSelectedPlaylist(null)
        }
    }

    pause() {
        return this.playerService.pause()
    }

    isPlaying(composition: Composition): boolean {
        if (playerQuery.content === undefined) {
            return false
        }

        return (
            composition._id === playerQuery.contentID &&
            playerQuery.status === "playing"
        )
    }

    formatPresetName(preset): string {
        if (preset != null) {
            return preset.replace(/_/g, " ")
        }

        return ""
    }

    convertSecondsToString(duration) {
        if (duration == 0 || duration == null) {
            return "-"
        }

        return Misc.convertSecondsToString(duration)
    }

    isLiked(compositionIndex): boolean {
        if (this.content == null || this.content[compositionIndex] == null) {
            return false
        }

        return this.content[compositionIndex].liked
    }

    isFinished(compositionIndex): boolean {
        if (this.content == null || this.content[compositionIndex] == null) {
            return false
        }

        return this.content[compositionIndex].isFinished
    }

    openFolderByID(folderID, event) {
        event.stopPropagation()

        this.folderService.openFolder(folderID)

        this.resetSelection()
        this.cancelMoreOptions()
    }

    openFolder(folderIndex, event) {
        event.stopPropagation()

        this.folderService.openFolder(this.subFolders[folderIndex]._id)

        this.resetSelection()
        this.cancelMoreOptions()
    }

    async generationWithGP() {
        this.gpService.openCompositionCreationModal({
            gp: this.content[this.contentIndex],
            redirectTo: "home",
            sourceGPFolder: null,
        })

        this.resetSelection()
        this.cancelMoreOptions()
    }

    generationWithGPFolder() {
        this.gpService.openCompositionCreationModal({
            gp: null,
            redirectTo: "home",
            sourceGPFolder: this.subFolders[this.subFolderIndex]._id,
        })

        this.resetSelection()
        this.cancelMoreOptions()
    }

    deleteGP(index) {
        this.modalService.modals.deleteGenerationProfile.next(
            this.content[index]
        )

        this.resetSelection()
        this.cancelMoreOptions()
    }

    moveToFolder(destinationID) {
        if (this.contentIndex != -1) {
            var contentID = this.folderService.getContentIDFromIndex(
                this.contentIndex
            )

            this.folderService.moveContentToFolder(contentID, destinationID)
        } else if (this.subFolderIndex != -1) {
            const selectedFolderID = this.subFolders[this.subFolderIndex]._id

            this.folderService.moveFolderToFolder(
                selectedFolderID,
                destinationID
            )
        } else if (this.multiSelectionMenu) {
            var elementsToMove = this.getSelectedItems()

            this.folderService.moveManyToFolder(elementsToMove, destinationID)
        }

        this.cancelMoreOptions()
        this.resetSelection()
    }

    getPreviousFolder() {
        if (this.path.length == 0) {
            return null
        } else if (this.path.length == 1) {
            var name = "Home"

            return { name: name, folderID: "" }
        }

        return this.path[this.path.length - 2]
    }

    shouldDisplaySubfolder(subFolderID) {
        const clickedSubFolderID = this.subFolders[this.subFolderIndex]._id

        if (clickedSubFolderID == subFolderID) {
            return false
        }

        return true
    }

    getParameters(composition): string {
        var parameters

        if (this.embedType == "Influences") {
            if (composition.parameters == null) {
                parameters = "Analysis in Progress..."
            } else {
                parameters =
                    composition.parameters.pitchClass +
                    " " +
                    composition.parameters.keyMode +
                    ", " +
                    composition.parameters.ensemble +
                    ", " +
                    composition.parameters.tempo +
                    " BPM, " +
                    composition.parameters.timeSignature[0] +
                    "/" +
                    composition.parameters.timeSignature[1]
            }
        } else {
            parameters =
                composition.preset != null
                    ? this.formatPresetName(composition.preset)
                    : "Influence"

            if (composition.keySignature) {
                parameters += ", " + composition.keySignature
            }

            if (composition.ensemble) {
                parameters += ", " + composition.ensemble
            }

            if (composition.tempo) {
                parameters += ", " + composition.tempo + " BPM"
            }

            if (composition.timeSignature) {
                parameters +=
                    ", " +
                    composition.timeSignature[0] +
                    "/" +
                    composition.timeSignature[1]
            }
        }

        return parameters
    }

    downloadFolder(subFolderIndex): void {
        const folder = this.subFolders[subFolderIndex]

        this.modalService.downloadFolder.next(folder)

        this.cancelMoreOptions()
    }

    retry(composition) {
        this.compositionService.retry(composition._id).then(result => {
            if (result.deleted) {
                this.modalService.justCreatedComposition.next(true)
            }
        })
    }

    duplicateComposition(compositionIndex) {
        this.compositionService.duplicate(compositionIndex)
        this.cancelMoreOptions()

        this.analyticsService.addActivity(ActivityMetric.DUPLICATE, {
            compositionID:
                this.folderService.getContentAtIndex(compositionIndex)._id,
        })
    }

    deleteInfluence(index) {
        var compositions = this.folderService.content.getValue()
        var compositionID = compositions[index]._id

        this.influenceService.delete(compositionID).then(() => {
            compositions.splice(index, 1)
            this.folderService.content.next(compositions)
        })

        this.cancelMoreOptions()
    }

    selectInfluence() {
        return this.influenceService.createWithInfluenceMode
    }

    getTimeSignature(composition) {
        if (composition.timeSignature != null) {
            return (
                composition.timeSignature[0] +
                "/" +
                composition.timeSignature[1]
            )
        }

        return "-"
    }

    useAsInfluence(compositionIndex) {
        this.useAsInfluenceLoading = true

        const compositions = this.folderService.content.getValue()
        const compositionID = compositions[compositionIndex]._id

        this.influenceService
            .useAsInfluence(compositionID)
            .then(async () => {
                this.useAsInfluenceLoading = false

                this.resetScrollWindow()

                await this.folderService.setContentType(
                    "useAsInfluence",
                    "Influences",
                    "",
                    true
                )

                this.scrollToBottomOfTrackList()

                this.cancelMoreOptions()

                this.analyticsService.addActivity(
                    ActivityMetric.USE_AS_INFLUENCE,
                    {
                        compositionID:
                            this.folderService.getContentAtIndex(
                                compositionIndex
                            )._id,
                    }
                )
            })

            .catch(err => {
                this.useAsInfluenceLoading = false

                this.folderService.setContentType(
                    "useAsInfluence",
                    "Influences",
                    "",
                    true
                )
            })
    }

    playerIsLoading(compositionID) {
        if (playerQuery.content === undefined) {
            return false
        }

        return (
            compositionID === playerQuery.contentID &&
            playerQuery.status === "loading"
        )
    }

    @HostListener("document:keydown", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        const deleteGP = this.folderService.contentType.getValue() === "Styles"

        const canDelete =
            event.key === "Delete" &&
            !this.shortcuts.shortcutPrevention() &&
            (playerQuery.content != null || deleteGP) &&
            this.router.url === "/" &&
            !this.supportChatService.chatIsOpen() &&
            !this.modalService.renamedFolder.getValue()

        if (canDelete) {
            if (
                this.multipleSelection.start.type != null &&
                this.multipleSelection.end.type != null
            ) {
                this.multiDelete()
            } else {
                this.modalService.deleteComposition.next(
                    playerQuery.content as Composition
                )
            }

            this.cancelMoreOptions()
        }
    }

    /**
     * detects if the device is a mobile device depending on the useragent and window width
     * @param resizeEvent
     */
    detectDevice(resizeEvent?) {
        let mobileBreakpoint = 680
        this.mobileUse = this.device.isMobile()

        if (resizeEvent) {
            this.mobileUse = resizeEvent.target.innerWidth <= mobileBreakpoint
        }
    }

    getSortValue() {
        return this.folderService.sortValue
    }

    sort(category) {
        if (this.folderService.sortValue.category == category) {
            this.folderService.sortValue.type =
                -this.folderService.sortValue.type
        } else {
            this.folderService.sortValue = {
                type: 1,
                category: category,
            }
        }

        this.folderService.sortContent(
            this.folderService.sortValue.category,
            this.folderService.sortValue.type
        )
    }

    // goToMyTracks() {
    // 	this.folderService.changeSelectedFolder("")
    // }

    // goToFolder(folderID) {
    // 	this.folderService.openFolder(folderID)
    // }

    setTrackView(viewName) {
        this.tracksService.setTrackView(viewName)
    }

    showCompositionInTracksView(compositionIndex) {
        let compositions = this.folderService.content.getValue()
        let composition = compositions[compositionIndex]
        let compositionID = composition._id
        let folderID = composition.folderID || "0"
        let navigateTo =
            "mytracks/Compositions/" +
            folderID +
            "/" +
            compositionID +
            "/trackslist"

        this.router.navigate([navigateTo])
    }

    isAudioInfluence(compositionIndex) {
        let compositions = this.folderService.content.getValue()
        let influence = compositions[compositionIndex]

        if (influence.sourceType && influence.sourceType == "audio") {
            return true
        }

        return false
    }

    isInfluence(compositionIndex) {
        let compositions = this.folderService.content.getValue()
        let composition = compositions[compositionIndex]

        if (composition.contentType == "influence") {
            return true
        }

        return false
    }

    getRightClickMenuElement(type) {
        if (type == "composition") {
            return this.rightClickMenuComposition
        }

        if (type == "generationProfiles") {
            return this.rightClickMenuGP
        } else if (type == "influence") {
            return this.rightClickMenuInfluence
        } else if (type == "subfolder") {
            return this.rightClickMenuSubfolder
        } else if (type == "multiselection") {
            return this.rightClickMenuMultiSelection
        }

        return undefined
    }

    getSubfolderIdByIndex(subfolderIndex) {
        if (
            subfolderIndex == null ||
            subfolderIndex < 0 ||
            !this.subFolders ||
            this.subFolders.length == 0 ||
            subfolderIndex >= this.subFolders.length
        ) {
            return null
        }

        return this.subFolders[subfolderIndex]._id
    }

    isStagingEnvironment() {
        return !environment.production
    }
}
