<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div class="settings-modal-titles">
    <div
        class="settings-modal-title"
        (click)="settingsContainer = 'about'"
        [ngClass]="{
            'settings-modal-title-selected': settingsContainer === 'about'
        }">
        About
    </div>

    <div
        class="settings-modal-title"
        *ngIf="canChangePassword()"
        (click)="openChangePasswordModal()"
        [ngClass]="{
            'settings-modal-title-selected':
                settingsContainer === 'changePassword'
        }">
        Change password
    </div>

    <div
        class="settings-modal-title"
        (click)="settingsContainer = 'email'"
        [ngClass]="{
            'settings-modal-title-selected': settingsContainer === 'email'
        }">
        Email settings
    </div>

    <div
        class="settings-modal-title"
        (click)="settingsContainer = 'network'"
        [ngClass]="{
            'settings-modal-title-selected': settingsContainer === 'network'
        }">
        Network settings
    </div>

    <div
        class="settings-modal-title"
        (click)="openAudioSettings()"
        [ngClass]="{
            'settings-modal-title-selected': settingsContainer === 'audio'
        }"
        *ngIf="isDesktopApp()">
        Audio settings
    </div>
</div>

<div class="settings-container" *ngIf="settingsContainer === 'about'">
    <div class="modal-copyright">
        Copyright © 2016-{{ getYear() }} Aiva Technologies
    </div>
    <div class="modal-version">
        AIVA Creators Edition v{{ windowService.versionNumber }}
    </div>

    <div
        class="button modal-blue-button reset-cache"
        *ngIf="!resetCacheLoading"
        (click)="resetCache()">
        Reset cache
    </div>
    <div class="loader reset-cache-loader" *ngIf="resetCacheLoading"></div>
</div>

<div class="settings-container" *ngIf="settingsContainer === 'email'">
    <div class="email-opt-in">
        <input
            type="checkbox"
            [checked]="emailSettings.updates"
            (change)="emailSettings.updates = !emailSettings.updates" />
        Notify me by email when AIVA is updated
    </div>

    <div class="email-opt-in">
        <input
            type="checkbox"
            [checked]="emailSettings.downloads"
            (change)="emailSettings.downloads = !emailSettings.downloads" />
        Notify me by email when my download quota is about to expire
    </div>

    <div
        class="button modal-blue-button reset-cache"
        (click)="updateEmailSettings()">
        Update settings
    </div>
</div>

<div class="settings-container" *ngIf="settingsContainer === 'network'">
    <div
        class="network-connection-option"
        *ngFor="let nc of networkConnections">
        <radio-button
            [id]="nc.value"
            [value]="nc.value"
            groupName="geolocation"
            [label]="nc.label"
            [description]="nc.description"
            [selectedValue]="selectedNetworkConnection"
            (onSelect)="changeNetworkConnection($event)"></radio-button>
    </div>

    <div
        class="button modal-blue-button reset-cache"
        (click)="updateNetworkSettings()">
        Update & Reload
    </div>
</div>

<div
    class="settings-container"
    *ngIf="settingsContainer === 'audio' && !audioLoading">
    <div class="settings">
        <div class="settings-row" *ngIf="audioDevices.length > 0">
            <div class="settings-row-title">Audio Output (in the Editor)</div>
            <div class="settings-row-value">
                <dropdown
                    class="audio-output-settings"
                    type="light"
                    [showTooltip]="false"
                    [items]="audioDevices"
                    [value]="selectedAudioDevice"
                    (selectedItem)="selectAudioDevice($event)">
                </dropdown>
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">Instrument directory</div>
            <div class="settings-row-value">
                <a-button
                    [label]="instrumentPath"
                    [title]="instrumentPath"
                    class="light set-instruments-path"
                    (onClick)="setInstrumentsDirectory()"
                    *ngIf="!instrumentPathLoading"></a-button>
                <div *ngIf="instrumentPathLoading">
                    <div class="loader instruments-path-loader"></div>
                    <div
                        class="loader-label"
                        *ngIf="instrumentPathLoadingMessage !== ''">
                        {{ instrumentPathLoadingMessage }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="instrumentsPathError === ''" class="change-settings-restart">
        Changing the instrument directory will restart the application
    </div>

    <div
        class="error"
        *ngIf="instrumentsPathError !== '' && !instrumentPathLoading">
        {{ instrumentsPathError }}
    </div>
</div>

<div
    class="settings-container audio-loading-container"
    *ngIf="settingsContainer === 'audio' && audioLoading">
    <div class="loader instruments-path-loader"></div>
</div>
