import { EditorView } from "./editor-view.model"
import { EntityState, Store, StoreConfig } from "@datorama/akita"
import {
    HIGHEST_PITCH,
    LOWEST_PITCH,
} from "../../../../general/constants/constants"

export interface EditorViewState extends EntityState<EditorView> {}

const initialState: EditorViewState = {
    cursorType: "select",
    instrumentsJSON: {},
    // components can listen to noteDescriptor and noteSelection in order to update the component UI modals
    noteDescriptor: undefined,
    noteSelection: undefined,
    accompanimentDesignerIsFocused: false,
    grid: undefined,
    followTimelineCursor: true,
    timelineCursorElement: undefined,
}

@StoreConfig({ name: "editorView", resettable: true })
export class EditorViewStore extends Store<EditorViewState> {
    constructor() {
        super(initialState)
    }
}
