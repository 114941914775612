<div class="modal-title">Customize Effects</div>

<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div class="effects-container">
    <div
        class="effect-list-item bass-boost-effect"
        [ngClass]="{ 'bassboost-selected': effects.bassBoost }"
        (click)="toggleBassBoost()">
        <img src="assets/img/effects/bassboost_letters.svg" />
    </div>

    <div
        class="effect-list-item bass-boost-effect"
        [ngClass]="{ 'vinyl-selected': effects.vinyl }"
        (click)="toggleVinyl()">
        <img src="assets/img/effects/vinyl_letters.svg" />
    </div>
</div>
