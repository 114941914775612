import {
    ChangeDetectionStrategy,
    Component,
    Output,
    Input,
    EventEmitter,
    ChangeDetectorRef,
} from "@angular/core"
import { LayersValue } from "@common-lib/types/general"
import { SelectLayerModalParameters } from "@services/modal.service"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../../types/dropdownItemType"

@Component({
    selector: "select-layer-modal",
    templateUrl: "select-layer-modal.component.html",
    styleUrls: [
        "./select-layer-modal.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLayerModalComponent {
    @Input() parameters: SelectLayerModalParameters
    @Output() close: EventEmitter<any> = new EventEmitter()

    public selectedLayer: LayersValue | undefined = undefined
    public loading: boolean = false
    public error: string = ""

    constructor(private ref: ChangeDetectorRef) {}

    ngOnInit() {
        this.selectedLayer = this.getFirstSupportedLayer()
    }

    private isSupported(layer: string) {
        return !this.parameters.alreadyHasLayers.includes(layer)
    }

    public selectLayer(selection: DropdownSelection<string>) {
        this.selectedLayer = selection.new.value as LayersValue
    }

    public getSelectedDropDownItem(): DropdownItemType<string> {
        return {
            value: this.selectedLayer,
            name: this.selectedLayer,
        }
    }

    public getDropdownItems(): DropdownItemType<string>[] {
        return this.parameters.supportedLayers.map(layer => ({
            value: layer,
            name: layer,
            disabled: !this.isSupported(layer),
        }))
    }

    private getFirstSupportedLayer() {
        for (const layer of this.parameters.supportedLayers) {
            if (this.isSupported(layer)) {
                return layer
            }
        }

        return undefined
    }

    public async confirm() {
        this.error = ""

        this.loading = true
        this.ref.detectChanges()

        const result = await this.parameters.done(this.selectedLayer)

        if (result.error) {
            this.error = result.error
        } else {
            this.close.emit()
        }

        this.loading = false
        this.ref.detectChanges()
    }
}
