<div
    id="menu-bgrd"
    class="menu-background"
    (clickOutside)="handleClickOutside($event)"
    [clickOutsideEvents]="'mousedown,touchstart'"
    #menuModifiers
    [style.left.px]="modifiers.coordinates.x"
    [style.top.px]="modifiers.coordinates.y"
    [ngStyle]="{ width: modifiers.width ? modifiers.width : 'auto' }"
    [attr.av-data]="avData">
    <div *ngIf="!modifiers.options.length && !modifiers.buttons.length">
        <div class="no-modifiers-available">No modifiers available</div>
    </div>

    <div class="modifier-title" *ngIf="modifiers.title !== undefined">
        {{ modifiers.title }}
    </div>

    <div
        class="modifier-container"
        *ngFor="let option of modifiers.options; let o = index"
        [ngClass]="{ 'no-margin-top': o === 0 }">
        <div class="label">
            {{ option.text }}

            <img
                *ngIf="option.tooltip !== undefined"
                src="assets/img/info.svg"
                [tooltip]="option.tooltip"
                [tooltip-marginTop]="10"
                [tooltip-marginRight]="20"
                [tooltip-position]="'middle-bottom'"
                [tooltip-width]="250" />
        </div>

        <div
            *ngIf="option.type === 'increment'"
            class="data-manipulator increment-input">
            <div class="increment-button" (click)="decrement(option)">-</div>
            <input
                placeholder="0"
                [(ngModel)]="option.data"
                type="number"
                (change)="verify(option, option.data)"
                [attr.av-data]="option.avData" />
            <div class="increment-button" (click)="increment(option)">+</div>
        </div>

        <div *ngIf="option.type === 'checkbox'" class="data-manipulator">
            <input
                type="checkbox"
                id="checkbox"
                [checked]="option.data"
                (change)="toggle(option)"
                [attr.av-data]="option.avData" />
        </div>

        <div
            *ngIf="option.type === 'dial'"
            class="data-manipulator dial-manipulator">
            <dial
                (valueOutput)="updateValue($event, option)"
                [turningSpeedModifier]="0.5"
                [unitLeft]="'%'"
                [unitRight]="'%'"
                [value]="option.data"
                [color]="'--secondary-color'"
                [type]="'normal'"
                [minLabel]="'0%'"
                [maxLabel]="'100%'"
                [min]="0"
                [max]="100"
                [avData]="option.avData"></dial>
        </div>

        <div
            *ngIf="option.type === 'slide-toggle'"
            class="data-manipulator slide-toggle-manipulator">
            <slide-toggle
                [(toggled)]="option.data"
                (onChange)="slideToggle(option, $event)"
                [avData]="option.avData"></slide-toggle>
        </div>

        <div *ngIf="option.type === 'btn-select'" class="data-manipulator">
            <btn-select
                [options]="option.options"
                [selectedOption]="option.data"
                (selection$)="btnSelection(option, $event)"
                widthPerCell="option.widthPerCell"
                avData="btn-select-menu-modifier">
            </btn-select>
        </div>

        <div *ngIf="option.type === 'number-input'" class="data-manipulator">
            <div class="number-input-container">
                <input
                    class="number-input"
                    [min]="option.min"
                    [max]="option.max"
                    [value]="option.data"
                    (change)="option.dataSetter($event.target.value)"
                    [attr.av-data]="option.avData" />

                <div class="number-input-unit">{{ option.unit }}</div>
            </div>
        </div>

        <div
            *ngIf="option.type === 'dropdown-button'"
            class="data-manipulator dropdown-button-manipulator">
            <dropdown-button
                [width]="option.width"
                [lineHeight]="option.lineHeight"
                [title]="option.data"
                avData="dropdown-button-menu-modifier"
                (click)="option.dataSetter()"></dropdown-button>
        </div>

        <div
            *ngIf="option.type === 'dropdown'"
            class="data-manipulator dropdown-manipulator">
            <dropdown
                type="light"
                class="dropdown-modifier"
                [showTooltip]="false"
                [items]="option.options"
                [value]="option.data"
                (selectedItem)="selectOption(option, $event)">
            </dropdown>
        </div>

        <div class="modifier-title" *ngIf="option.subtitle !== undefined">
            {{ option.subtitle }}
        </div>
    </div>

    <div class="button-container" *ngFor="let button of modifiers.buttons">
        <a-button
            width="100%"
            [label]="button.text"
            [icon]="button.icon"
            (click)="button.onClick(button)"
            [class]="
                'menu-option-button ' +
                (button.buttonClass
                    ? 'menu-option-button-' + button.buttonClass
                    : '')
            "
            [avData]="button.avData">
        </a-button>
    </div>
</div>
