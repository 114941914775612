<div class="circle {{ class }}" [ngStyle]="getStyleForDimensions()">
    <svg viewBox="0 0 36 36" class="circular-chart" [ngStyle]="getStyleForDimensions()">
        <path class="background" stroke-dasharray="100, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
        <path class="circle" stroke-dasharray="0, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" style.stroke-dasharray="{{ getPercentage() }}, 100"/>
    </svg>

    <div class="label" [ngStyle]="getStyleForDimensions('label')">
        <span class="progress">{{ progress }}</span>
        <span class="divider"></span>
        <span class="total">{{ total }}</span>
    </div>
</div>  