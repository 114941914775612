import PatternRegion from "../../../general/classes/score/patternregion"
import PercussionLayer from "../../../general/classes/score/percussionlayer"
import Score from "../../../general/classes/score/score"
import {
    PATTERN_REGIONS_QUANTIZATION_THRESHOLDS,
    SHOW_BEAT_GUTTERS_THRESHOLD,
    SHOW_HALF_BEAT_GUTTERS_THRESHOLD,
    SHOW_TIMESTEP_GUTTERS_THRESHOLD,
    TIMESTEP_RES,
} from "../../../general/constants/constants"
import { Time } from "../../../general/modules/time"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    PatternRegionsCanvasContext,
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    RemoveLastGutter,
    GridDimensionsDictionary,
    CanvasType,
    GridCoordinates,
} from "../types"
import ScoreCanvas from "./score-canvas"
import { TinyColor } from "@ctrl/tinycolor"
import { Color } from "../../../general/modules/color"
import { Coordinates } from "../../../general/modules/event-handlers"
import { SRActionTypes } from "../states/score-rendering/score-rendering.actions"
import { PatternHoveringType } from "../../../general/types/general"
import Section from "../../../general/classes/score/section"
import { ScoreManipulation } from "../../../general/modules/scoremanipulation"
import { featureFlags } from "../../../general/utils/feature-flags"
import { throttle } from "rxjs"

export default class PatternRegionsCanvas extends ScoreCanvas {
    private windowBorder = 1
    private edgeWidth = 20
    private iconWidth = 14
    private resizeIcon: HTMLImageElement
    private loopIcon: HTMLImageElement
    private sectionToRegenerate: Section
    private mouseHoverCoordinates: Coordinates

    constructor(
        public context: PatternRegionsCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)
        this.initListeners()
        if (
            !window.location.href.includes("editor") ||
            !featureFlags.generateLayerWithLLMInEditor
        ) {
            return
        }
        this.createRegenerateLayerButton()
    }

    private initListeners() {
        this.mouseDown$.subscribe(this.mouseDownHandler.bind(this))
        this.hoveredDetection$.subscribe(this.detectHovering.bind(this))
        this.move$.subscribe(event => this.mouseMoveHandler(event, "move"))
        this.resize$.subscribe(event => this.mouseMoveHandler(event, "resize"))
        this.loop$.subscribe(event => this.mouseMoveHandler(event, "loop"))
        this.mouseUp$.subscribe(this.mouseUpHandler.bind(this))
        if (
            !window.location.href.includes("editor") ||
            !featureFlags.generateLayerWithLLMInEditor
        ) {
            return
        }
        this.queries.scoreRendering.inspectorHovered$.subscribe(
            this.detectHovering.bind(this)
        )
    }

    public async render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary
    ) {
        const score: Score = scoreState.score

        if (
            !score ||
            !this.shouldRenderCanvas(scoreState.renderingType) ||
            this.queries.scoreRendering.toggledLayer?.type !== "percussion" ||
            (this.queries.scoreRendering.toggledLayer as PercussionLayer)
                .patternRegions === undefined
        ) {
            return
        }

        await this.initIcons()

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: grid,
            noteRes: scoreState.userSelectedTimestepRes,
            nbOfYValues: 0,
        })

        const start = Time.timestepsToFraction(
            this.noteRes,
            this.scrollToTimestep
        )
        const end = Time.timestepsToFraction(
            this.noteRes,
            this.scrollToTimestep + this.getTimestepRange()
        )

        const sections = ScoreCanvas.generateSectionOverlays({
            ctx: this.getContext("canvas-overlay"),
            score: score,
            start: start,
            end: end,
            pxPerTimestep: this.pxPerTimestep,
            grid: this.grid,
            height: this.height,
            scrollToTimestep: this.scrollToTimestep,
            timestepRes: this.noteRes,
        })
        this.initGrid(sections)
        this.renderPatternRegions(sections, scoreState)

        const layer = <PercussionLayer>this.queries.scoreRendering.toggledLayer

        if (!layer?.patternRegions?.length) {
            this.renderOverlay()
        }
        this.updateRegenerateLayerButton(null, true)
    }

    private initGrid(sections: Section[]) {
        const ctx: CanvasRenderingContext2D = this.getContext(
            "canvas"
        ) as CanvasRenderingContext2D

        if (this.queries.scoreRendering.score === undefined) {
            return
        }

        const fillStyleNormal = "rgba(255, 255, 255, 0.1)"

        const skipTimesteps =
            this.resizeFactor < SHOW_TIMESTEP_GUTTERS_THRESHOLD //if less go by beats
        const skipBeatsteps = this.resizeFactor < SHOW_BEAT_GUTTERS_THRESHOLD //if less show bars
        const skipHalfBeatsteps =
            this.resizeFactor < SHOW_HALF_BEAT_GUTTERS_THRESHOLD //if less show beats

        // left separator is show beat gutters, right one is show timesteps gutters
        this.traverseTimeGrid(
            (
                x: number,
                timesteps: number,
                currentGutterSize,
                previousGutterSize,
                width
            ) => {
                if (
                    ScoreManipulation.fractionIsInSections(
                        Time.timestepsToFraction(TIMESTEP_RES, timesteps),
                        sections
                    )
                ) {
                    return true
                }

                ScoreCanvas.generateNoteRect(
                    ctx,
                    x,
                    0,
                    fillStyleNormal,
                    4,
                    width,
                    this.height
                )

                return true
            },
            skipTimesteps,
            skipBeatsteps,
            skipHalfBeatsteps
        )
    }

    // if this leads to performance issues while rendering, just set up the icons without loadImg and without await
    private async initIcons() {
        this.resizeIcon = await this.loadImg(
            "assets/img/pianoroll/resize.svg",
            this.iconWidth
        )
        this.loopIcon = await this.loadImg(
            "assets/img/pianoroll/loop.svg",
            this.iconWidth
        )
    }

    protected renderOverlay() {
        const ctx = this.getContext("canvas")
        const textLineHeight = 10

        ScoreCanvas.drawText(
            ctx,
            210 - this.scrollXOffset,
            // Not really sure why we have to divide canvas height by 4 instead of 2, but it works
            // vertically position the text in the middle of the canvas
            this.canvas.height / 4 + textLineHeight / 2,
            "Enable 'Pencil Mode' and click in this area to play percussion patterns",
            85,
            "",
            "white"
        )
    }

    private renderPatternRegions(
        sections: Section[],
        scoreState: ScoreRendering
    ) {
        const layer: PercussionLayer = <PercussionLayer>(
            this.queries.scoreRendering.toggledLayer
        )

        if (!layer?.patternRegions) {
            return
        }

        const range = {
            start: Time.convertTimestepsToAnotherRes(
                this.scrollToTimestep,
                this.noteRes,
                TIMESTEP_RES
            ),
            end: Time.convertTimestepsToAnotherRes(
                this.scrollToTimestep + this.getTimestepRange(),
                this.noteRes,
                TIMESTEP_RES
            ),
        }

        let regions = PercussionLayer.getPatternRegionsInRange({
            regions: layer.patternRegions,
            timestepRange: range,
        })

        let selectedRegions = null

        if (scoreState.selectedData?.type === "PatternRegion") {
            selectedRegions = scoreState.selectedData.data
            regions = regions.filter(r => selectedRegions.find(sr => sr !== r))
        }

        for (const region of regions) {
            if (
                ScoreManipulation.fractionIsInSections(region.start, sections)
            ) {
                continue
            }

            this.renderPatternRegion(scoreState, layer, region)
        }

        if (!selectedRegions) return

        for (const region of selectedRegions) {
            if (
                ScoreManipulation.fractionIsInSections(region.start, sections)
            ) {
                continue
            }

            this.renderPatternRegion(scoreState, layer, region)
        }
    }

    private rangesOverlap(range1: number[], range2: number[]) {
        return Math.max(range1[0], range2[0]) <= Math.min(range1[1], range2[1])
    }

    private getPatternRegionOffset(
        patternRegion: PatternRegion,
        removeLastGutterPx: RemoveLastGutter = "none"
    ) {
        const x = ScoreCanvas.getPixelsForTimesteps({
            timesteps: Time.fractionToTimesteps(
                this.noteRes,
                patternRegion.start
            ),
            removeLastGutterPx: removeLastGutterPx,
            grid: this.grid,
            timestepRes: this.noteRes,
        })

        return x - this.windowBorder - this.scrollXOffset
    }

    /**
     *
     * @param patternRegion
     * @param loopIndex index in case the pattern is looped
     * @param showEdge defines if the resize edges should be rendered
     */
    private renderWindow(
        scoreState: ScoreRendering,
        layer: PercussionLayer,
        patternRegion: PatternRegion,
        loopIndex: number,
        width: number,
        x: number,
        cachedCtx?: CanvasRenderingContext2D,
        showEdge?: "left" | "right"
    ) {
        const ctx = cachedCtx || this.getContext("canvas")
        const height = this.height
        const y = 0
        const isSelected = this.isSelectedPatternRegion(
            scoreState,
            patternRegion
        )

        const borderRadius = this.getBorderRadius(patternRegion, "fully")
        const strokeStyle = this.getPatternRegionBorderColor(
            scoreState,
            layer,
            patternRegion
        )
        const fillStyle = this.getRegionColor(layer)

        ScoreCanvas.roundRect(ctx, x, y, width, height, borderRadius)
        ctx.lineWidth = this.windowBorder
        ctx.fillStyle = fillStyle
        ctx.fill()

        ctx.strokeStyle = strokeStyle
        ctx.stroke()
        ctx.fill()

        // add overlay stroke so looping regions look connected
        if (loopIndex > 0 && !isSelected) {
            ctx.beginPath()
            ctx.strokeStyle = fillStyle
            ctx.lineWidth = this.windowBorder * 2
            ctx.moveTo(x, y + borderRadius / 2)
            ctx.lineTo(x, height - borderRadius / 2)
            ctx.stroke()
        }

        this.renderPatternRegionName(
            scoreState,
            layer,
            patternRegion,
            ctx,
            loopIndex
        )
    }

    private isSelectedPatternRegion(
        scoreState: ScoreRendering,
        patternRegion: PatternRegion
    ) {
        if (
            this.queries.scoreRendering.selectedData === undefined ||
            this.queries.scoreRendering.selectedData.type !== "PatternRegion"
        )
            return false

        return (
            patternRegion &&
            (<PatternRegion[]>(
                this.queries.scoreRendering.selectedData.data
            )).some(p => {
                return p.id === patternRegion.id
            })
        )
    }

    private renderPatternRegionName(
        scoreState: ScoreRendering,
        layer: PercussionLayer,
        patternRegion: PatternRegion,
        ctx: CanvasRenderingContext2D,
        loopIndex?: number
    ) {
        if (patternRegion.pattern === undefined) {
            return
        }

        if (!loopIndex) {
            loopIndex = 0
        }
        const patternName = patternRegion.pattern.name.toUpperCase()
        const title = loopIndex ? `${patternName} (LOOPED)` : patternName
        const marginLeft = 4
        const x = 0
        const y =
            this.context.patternNameHeight - this.context.patternNameHeight / 4
        const selected = this.isSelectedPatternRegion(scoreState, patternRegion)

        ctx.font = `bold ${this.context.patternNameHeight}px`
        ctx.fillStyle = this.getPatternRegionTitleColor(layer, selected)

        const width = this.getPatternRegionWidth(
            patternRegion,
            this.getRemoveLastGutterPx(patternRegion)
        )

        if (selected) {
            const borderRadius = this.getBorderRadius(patternRegion, "fully")
            const strokeStyle = this.getPatternRegionBorderColor(
                scoreState,
                layer,
                patternRegion
            )
            const fillStyle = this.getRegionColor(layer, "light")

            // add a rectangle to highlight the pattern region title
            ScoreCanvas.roundRect(
                ctx,
                x,
                0,
                width,
                this.context.patternNameHeight + borderRadius,
                borderRadius
            )
            ctx.lineWidth = this.windowBorder
            ctx.fillStyle = fillStyle
            ctx.fill()

            ctx.strokeStyle = strokeStyle
            ctx.stroke()
            ctx.fill()

            // add a layer on top to cover the edges of the title rectangle created above
            ctx.fillStyle = this.getRegionColor(layer)
            ctx.fillRect(
                x,
                this.context.patternNameHeight,
                width,
                this.context.patternNameHeight
            )

            ctx.fillStyle = this.getPatternRegionTitleColor(layer, selected)
        }

        const patternRegionNameText = this.getFittingString(
            this.canvas.getContext("2d"),
            title,
            this.getPatternRegionWidth(patternRegion, "none") - marginLeft
        )

        ctx.fillText(patternRegionNameText, x + marginLeft, y)
    }

    private renderPatternRegionShadow(
        patternRegion: PatternRegion,
        ctx: CanvasRenderingContext2D
    ) {
        const edgeWidth = this.getEdgeWidth(patternRegion)
        const height = this.height
        const y = 0
        const borderRadius = this.getBorderRadius(patternRegion, "fully")
        const fillStyle = "black"
        const numberOfRendersForThisPattern = patternRegion.loop + 1
        const width = this.getPatternRegionWidth(patternRegion, "none")

        ctx.shadowBlur = 8
        ctx.shadowColor = "black"

        // add left shadow
        const startX = this.getPatternRegionX(patternRegion, 0, "none")
        ScoreCanvas.roundRect(ctx, startX, y, edgeWidth, height, borderRadius)
        ctx.fillStyle = fillStyle
        ctx.fill()
        ctx.fillRect(startX + edgeWidth / 2, y, edgeWidth / 2, height)

        // add right shadow
        const endX =
            this.getPatternRegionX(
                patternRegion,
                numberOfRendersForThisPattern - 1,
                "none"
            ) +
            width -
            edgeWidth

        // add rectangle
        ScoreCanvas.roundRect(ctx, endX, y, edgeWidth, height, borderRadius)
        ctx.fillStyle = fillStyle
        ctx.fill()
        ctx.fillRect(endX, y, edgeWidth / 2, height)

        ctx.shadowBlur = 0
        ctx.shadowColor = "transparent"
    }

    private renderPatternRegion(
        scoreState: ScoreRendering,
        layer: PercussionLayer,
        patternRegion: PatternRegion
    ) {
        const numberOfRendersForThisPattern = patternRegion.loop + 1
        const removeLastGutterPx = this.getRemoveLastGutterPx(patternRegion)
        const ctx = this.getContext("canvas")
        const width = this.getPatternRegionWidth(
            patternRegion,
            removeLastGutterPx
        )

        const canvas = document.createElement("canvas")
        canvas.width = width
        canvas.height = this.height
        const cacheCtx = canvas.getContext("2d")

        const isSelected = this.isSelectedPatternRegion(
            scoreState,
            patternRegion
        )
        if (isSelected) {
            this.renderPatternRegionShadow(patternRegion, ctx)
        }

        this.renderWindow(
            scoreState,
            layer,
            patternRegion,
            0,
            width,
            0,
            cacheCtx
        )

        this.renderPatternOnsets(patternRegion, 0, width, 0, cacheCtx)

        for (
            let loopIndex = 0;
            loopIndex < numberOfRendersForThisPattern;
            loopIndex++
        ) {
            const x = this.getPatternRegionX(
                patternRegion,
                loopIndex,
                removeLastGutterPx
            )
            ctx.drawImage(canvas, x, 0)
        }
        if (
            this.hoveredElement &&
            this.hoveredElement?.element === patternRegion
        ) {
            let edge = undefined
            const hoverType = this.hoveredElement.draggingType

            if (hoverType === PatternHoveringType.LEFT) {
                edge = PatternHoveringType.LEFT
            } else if (
                hoverType === PatternHoveringType.BOTTOM_RIGHT ||
                hoverType === PatternHoveringType.TOP_RIGHT
            ) {
                edge = "right"
            }
            this.renderWindowEdge(layer, patternRegion, edge, ctx)
        }
    }

    private getRemoveLastGutterPx(patternRegion: PatternRegion) {
        return "none"
        const numberOfRendersForThisPattern = patternRegion.loop + 1
        const removeLastGutterPx =
            numberOfRendersForThisPattern === 1 ? "half" : "none"

        return removeLastGutterPx
    }

    /**
     * renders the edges that hold the loop and resize "buttons" (need to be detected on a px basis)
     * @param patternRegion
     * @param loopIndex
     * @param showEdge
     * @returns
     */
    private renderWindowEdge(
        layer: PercussionLayer,
        patternRegion: PatternRegion,
        showEdge: "left" | "right" | undefined,
        ctx: CanvasRenderingContext2D
    ) {
        const edgeWidth = this.getEdgeWidth(patternRegion)
        const width = this.getPatternRegionWidth(patternRegion, "none")
        const height = this.height
        const y = 0
        const borderRadius = this.getBorderRadius(patternRegion, "fully")
        const fillStyle = this.getRegionColor(layer, "light")

        // do not render the edges if there is hardly any
        // space to render them or in case there would
        // be no space in between the edges to click and move the pattern regions
        if (edgeWidth < borderRadius + 4 || width < 2 * edgeWidth + 10) {
            return
        }

        if (showEdge === "left") {
            const x = this.getPatternRegionX(patternRegion, 0, "none")

            // add rectangle
            ScoreCanvas.roundRect(ctx, x, y, edgeWidth, height, borderRadius)
            ctx.fillStyle = fillStyle
            ctx.fill()
            ctx.fillRect(x + edgeWidth / 2, y, edgeWidth / 2, height)

            if (this.iconWidth > edgeWidth) {
                return
            }

            // add resize icon
            ctx.drawImage(
                this.resizeIcon,
                x + edgeWidth / 2 - this.iconWidth / 2,
                height / 2 - this.iconWidth / 2,
                this.iconWidth,
                this.iconWidth
            )
        } else if (showEdge === "right") {
            const x =
                this.getPatternRegionX(
                    patternRegion,
                    patternRegion.loop,
                    "none"
                ) +
                width -
                edgeWidth
            const lineWidth = 1

            // add rectangle
            ScoreCanvas.roundRect(ctx, x, y, edgeWidth, height, borderRadius)
            ctx.fillStyle = fillStyle
            ctx.fill()
            ctx.fillRect(x, y, edgeWidth / 2, height)

            // add middle separator
            ctx.beginPath()
            ctx.strokeStyle = "#000"
            ctx.lineWidth = lineWidth
            ctx.moveTo(x, height / 2)
            ctx.lineTo(x + edgeWidth, height / 2)
            ctx.stroke()

            if (this.iconWidth > edgeWidth) {
                return
            }

            // add loop icon top
            ctx.drawImage(
                this.loopIcon,
                x + edgeWidth / 2 - this.iconWidth / 2,
                height / 4 - this.iconWidth / 2,
                this.iconWidth,
                this.iconWidth
            )

            // add resize icon bottom
            ctx.drawImage(
                this.resizeIcon,
                x + edgeWidth / 2 - this.iconWidth / 2,
                (height / 4) * 3 - this.iconWidth / 2,
                this.iconWidth,
                this.iconWidth
            )
        }
    }

    protected mouseDownHandler(event: Coordinates) {
        super.mouseDownHandler(event, (grid: GridCoordinates) => {
            return this.getHoveringType(event, grid, true)
        })

        this.drawPatternRegion(event)
    }

    private drawPatternRegion(event: Coordinates) {
        if (this.cursorType !== "pencil" || this.hoveredElement) {
            return
        }
        const grid = this.getGridCoordinates(event, false)
        this.srEmitter$.next({
            type: SRActionTypes.drawPatternRegion,
            data: {
                timestep: ScoreManipulation.quantizeTimesteps({
                    resizeFactor: this.resizeFactor,
                    timesteps: grid.timesteps,
                    timestepRes: this.noteRes,
                    timeSignature:
                        this.queries.scoreRendering.score.firstTimeSignature,
                    thresholds: PATTERN_REGIONS_QUANTIZATION_THRESHOLDS,
                }),
            },
            options: {
                isUndoable: true,
            },
        })
    }

    protected mouseUpHandler(event: Coordinates) {
        super.mouseUpHandler(event)
        this.srEmitter$.next({
            type: SRActionTypes.endManipulatingPatterns,
        })
    }

    protected mouseMoveHandler(
        event: Coordinates,
        type: "move" | "resize" | "loop"
    ) {
        let r = null
        if (type === "loop") {
            const barLength = Time.fractionToTimesteps(
                this.noteRes,
                (<PatternRegion>this.hoveredElement.element).duration
            )
            r = this.moveHandler(event, {
                timestepResLimit: barLength,
                type: "loop",
                noNegativeOffsetBeyondThreshold: Time.fractionToTimesteps(
                    this.noteRes,
                    this.hoveredElement.element.start as string
                ),
                thresholds: PATTERN_REGIONS_QUANTIZATION_THRESHOLDS,
                quantize: true,
            })
        } else {
            r = this.moveHandler(event, {
                type,
                thresholds: PATTERN_REGIONS_QUANTIZATION_THRESHOLDS,
                quantize: true,
            })
        }
        if (!r) return

        let diffTimesteps = Time.convertTimestepsToAnotherRes(
            r.diffTimesteps,
            this.noteRes,
            TIMESTEP_RES
        )

        const timestepsToMove = diffTimesteps
        const scrollToTimestep = Time.convertTimestepsToAnotherRes(
            this.scrollToTimestep,
            this.noteRes,
            TIMESTEP_RES
        )

        const scrollRange =
            Time.convertTimestepsToAnotherRes(
                this.getTimestepRange(),
                this.noteRes,
                TIMESTEP_RES
            ) + scrollToTimestep

        const timestepRange = [scrollToTimestep, scrollRange]

        const isInitialPRManipulation =
            this.queries.scoreRendering.isInitialPRManipulation

        this.srEmitter$.next({
            type: SRActionTypes.manipulatePatternRegion,
            data: {
                timestepDelta: timestepsToMove,
                timesteps: r.now.timesteps,
                timestepRange,
                type,
                hoveringType: this.mouseDownStart.mouseDownLocation,
            },
            options: {
                isUndoable: isInitialPRManipulation,
            },
        })
    }

    protected getHoveringType(
        coordinates: Coordinates,
        grid: GridCoordinates,
        setSelectedPattern: boolean
    ) {
        const layer: PercussionLayer = <PercussionLayer>(
            this.queries.scoreRendering.toggledLayer
        )
        // Range of visible pattern regions
        const scrollToTimestep = Time.convertTimestepsToAnotherRes(
            this.scrollToTimestep,
            this.noteRes,
            TIMESTEP_RES
        )
        const scrollRange =
            Time.convertTimestepsToAnotherRes(
                this.getTimestepRange(),
                this.noteRes,
                TIMESTEP_RES
            ) + scrollToTimestep

        const range = {
            start: scrollToTimestep,
            end: scrollRange,
        }

        const regions = PercussionLayer.getPatternRegionsInRange({
            regions: layer.patternRegions,
            timestepRange: range,
        })

        let hoveredRegion = null
        let hoverType = PatternHoveringType.CENTER

        for (const region of regions) {
            const numberOfRendersForThisPattern = region.loop + 1

            const width = this.getPatternRegionWidth(
                region,
                numberOfRendersForThisPattern === 1 ? "half" : "none"
            )
            const regionWidth = numberOfRendersForThisPattern * width
            const regionX = this.getPatternRegionX(region, 0, "none")
            const edgeWidth = this.getEdgeWidth(region)
            for (
                let loopIndex = 0;
                loopIndex < numberOfRendersForThisPattern;
                loopIndex++
            ) {
                const x = this.getPatternRegionX(region, loopIndex, "none")

                if (
                    coordinates.x >= regionX &&
                    coordinates.x <= regionX + regionWidth
                ) {
                    hoveredRegion = region
                    hoverType = PatternHoveringType.CENTER

                    const leftEdgeEnd = regionX + edgeWidth
                    const patternEnd = regionX + regionWidth
                    const rightEdgeEnd = patternEnd - edgeWidth

                    if (coordinates.x <= leftEdgeEnd) {
                        hoverType = PatternHoveringType.LEFT
                    } else if (coordinates.x >= rightEdgeEnd) {
                        if (coordinates.y >= this.height / 2) {
                            hoverType = PatternHoveringType.BOTTOM_RIGHT
                        } else {
                            hoverType = PatternHoveringType.TOP_RIGHT
                        }
                    }
                    break
                }
            }
            if (hoveredRegion) break
        }

        if (!hoveredRegion && !this.hoveredElement?.element) {
            return undefined
        }

        if (!hoveredRegion && this.hoveredElement?.element) {
            // render only once when moving the mouse from hovering a region to no region
            this.srEmitter$.next({
                type: SRActionTypes.setRenderingType,
                data: {
                    type: "PatternRegionsCanvas",
                },
            })
            return undefined
        }

        if (setSelectedPattern) {
            this.sendSelectPRAction(coordinates, hoveredRegion)
        } else if (
            !this.hoveredElement ||
            this.hoveredElement.draggingType !== hoverType ||
            this.hoveredElement.element !== hoveredRegion
        ) {
            this.srEmitter$.next({
                type: SRActionTypes.setRenderingType,
                data: {
                    type: "PatternRegionsCanvas",
                },
            })
        }

        return {
            type: hoverType,
            element: hoveredRegion,
        }
    }

    private sendSelectPRAction(event, hoveredRegion: PatternRegion) {
        if (!hoveredRegion) return

        let prevSelection = this.queries.scoreRendering?.selectedData
            ?.data as PatternRegion[]
        if (event?.shiftKey && prevSelection) {
            prevSelection.push(hoveredRegion)
        } else {
            prevSelection = [hoveredRegion]
        }

        this.srEmitter$.next({
            type: SRActionTypes.setSelectedPatternRegion,
            data: {
                patternRegions: prevSelection,
            },
        })
    }

    private detectHovering(event: Coordinates) {
        if (
            featureFlags.generateLayerWithLLMInEditor &&
            window.location.href.includes("editor")
        ) {
            this.updateRegenerateLayerButton(event)
        }
        if (this.mouseDownStart !== undefined) {
            return this.hoveredElement
        }

        const result = this.getHoveringType(
            event,
            this.getGridCoordinates(event, false),
            false
        )

        if (!result || !result?.type) {
            this.hoveredElement = undefined
        } else {
            this.hoveredElement = {
                element: result.element,
                draggingType: result.type,
                type: "patternRegion",
            }
        }

        let cursorType =
            this.queries.editorView.cursorType === "pencil"
                ? "pencil"
                : "default"

        if (result?.type && result.type !== PatternHoveringType.CENTER) {
            cursorType = "col-resize"
        }

        this.setCanvasCursor(cursorType)
    }

    // renders the "notes" inside of the pattern region
    private renderPatternOnsets(
        patternRegion: PatternRegion,
        patternRegionX: number,
        width: number,
        loopIndex: number,
        cacheCtx?: CanvasRenderingContext2D
    ) {
        if (patternRegion.pattern === undefined) {
            return
        }

        // if (patternRegion.pattern.name !== "Pattern 1") return
        patternRegion.pattern.sortChannels()

        const ctx: CanvasRenderingContext2D =
            cacheCtx || this.getContext("canvas")
        ctx.fillStyle = "white"

        const patternResolution =
            TIMESTEP_RES /
            Time.fractionToTimesteps(
                TIMESTEP_RES,
                patternRegion.pattern.resolution
            )

        const borders = {
            channel: 1,
            timesteps: 1,
        }

        const removeLastGutterPx = this.getRemoveLastGutterPx(patternRegion)

        const pxPerTimestep = ScoreCanvas.getPixelsForTimesteps({
            timesteps: 1,
            timestepRes: patternResolution,
            grid: this.grid,
            removeLastGutterPx: removeLastGutterPx,
        })

        const marginBottom = this.context.borderRadius / 2

        const pxPer = {
            channelStep:
                (this.height - this.context.patternNameHeight - marginBottom) /
                patternRegion.pattern.channels.length,
            timestep: pxPerTimestep,
        }

        let patternOnsetInPx = ScoreCanvas.getPixelsForTimesteps({
            timesteps: Time.fractionToTimesteps(
                this.noteRes,
                patternRegion.onset
            ),
            timestepRes: this.noteRes,
            grid: this.grid,
            removeLastGutterPx: removeLastGutterPx,
        })

        for (let c = 0; c < patternRegion.pattern.channels.length; c++) {
            const channel = patternRegion.pattern.channels[c]

            if (!channel.onsets.length) {
                continue
            }

            for (const onset of channel.onsets) {
                let x =
                    ScoreCanvas.getPixelsForTimesteps({
                        timesteps: Time.fractionToTimesteps(
                            this.noteRes,
                            onset.start
                        ),
                        timestepRes: this.noteRes,
                        grid: this.grid,
                        removeLastGutterPx: removeLastGutterPx,
                    }) - patternOnsetInPx
                const onsetWidth = Math.max(
                    pxPer.timestep - borders.timesteps,
                    1
                )
                const onsetStartsBeforePatternRegion =
                    Math.round(x) < Math.round(patternRegionX)
                const onsetExceedsPatternRegion =
                    Math.round(x + onsetWidth) >
                    Math.round(patternRegionX + width)

                if (
                    onsetStartsBeforePatternRegion ||
                    onsetExceedsPatternRegion
                ) {
                    continue
                }

                const y = c * pxPer.channelStep + this.context.patternNameHeight
                const height = pxPer.channelStep - borders.channel

                ctx.fillRect(x, y, onsetWidth, height)
            }
        }
    }

    private updateRegenerateLayerButton(
        event: Coordinates,
        usePrevPosition: boolean = false
    ): void {
        const el = document.querySelector(
            "#regenerate-button-wrapper"
        ) as HTMLElement

        if (!el) {
            return
        }

        const position = this.getLayerRegenerateButtonPosition(
            event,
            usePrevPosition
        )

        if (!position) {
            return
        }
        el.style.left = `${position.x}px`
        el.style.opacity = "1"
        // The y property was used to pass the width of the section
        el.style.width = `${position.y}px`

        // this part removes the text if it completely zoomed out and the text overflows
        const buttonWidth = 130
        const textEl = document.querySelector(
            "#regenerate-button__text"
        ) as HTMLSpanElement
        if (position.y < buttonWidth) {
            textEl.style.display = "none"
        } else {
            textEl.style.display = "inline-block"
        }
    }

    private getLayerRegenerateButtonPosition(
        event: Coordinates,
        usePrevPosition: boolean
    ): Coordinates {
        const buttonWidth = 125

        let mouseTimestep: number

        if (!usePrevPosition) {
            this.mouseHoverCoordinates = event
            mouseTimestep = super.getTimestepsForPixels({
                pixels: event.x + this.scrollXOffset,
            })
        } else if (!!this.mouseHoverCoordinates) {
            mouseTimestep = super.getTimestepsForPixels({
                pixels: this.mouseHoverCoordinates.x + this.scrollXOffset,
            })
        } else {
            return null
        }

        const sectionTimesteps = this.queries[
            "scoreRendering"
        ].score.getSectionInRangeAtTimestep(mouseTimestep, TIMESTEP_RES)

        if (!sectionTimesteps) return null

        const sectionCoordinates = ScoreCanvas.sectionToCoordinates(
            sectionTimesteps.section,
            this.grid,
            this.scrollToTimestep,
            this.pxPerTimestep,
            this.noteRes
        )

        if (
            this.sectionToRegenerate?.index ===
                sectionTimesteps?.section.index &&
            !usePrevPosition
        ) {
            return null
        }

        this.sectionToRegenerate = sectionTimesteps.section

        if (!sectionTimesteps) {
            return null
        }
        let width = sectionCoordinates.width
        if (sectionCoordinates.x < 0) {
            width = sectionCoordinates.width - Math.abs(sectionCoordinates.x)
        } else if (
            sectionCoordinates.x + sectionCoordinates.width >
            this.width
        ) {
            width = this.width - sectionCoordinates.x
        }
        // I'm passing the width of the section as a y coordinate to not create a new interface just for this case
        const coordinates: Coordinates = {
            x: Math.max(sectionCoordinates.x, 0),
            y: width,
            shiftKey: false,
        }

        return coordinates
    }

    private createRegenerateLayerButton(): void {
        const wrapper = this.createElement({
            type: "div",
            innerHTML: "",
            id: "regenerate-button-wrapper",
        })
        const btn = this.createElement({
            type: "button",
            innerHTML: "",
            id: "regenerate-button",
        })

        const icon = this.createElement({
            type: "img",
            innerHTML: "",
            id: "regenerate-button__icon",
        }) as HTMLImageElement

        icon.src = "/assets/img/achievements/sparkle.svg"

        const text = this.createElement({
            type: "span",
            innerHTML: "Regenerate",
            id: "regenerate-button__text",
        })

        btn.appendChild(icon)
        btn.appendChild(text)

        wrapper.addEventListener("mousedown", $event => {
            $event.preventDefault()
            $event.stopPropagation()
            if (featureFlags.generateLayerWithLLMInEditor) {
                this.queries.scoreRendering.regenerateLayerWithLLM$.next(
                    this.sectionToRegenerate
                )
            }
        })
        wrapper.onmousemove = $event => {
            $event.stopPropagation()
        }

        wrapper.appendChild(btn)

        this.context.canvasContainer.appendChild(wrapper)
    }

    private getPatternRegionWidth(
        patternRegion: PatternRegion,
        removeLastGutterPx: RemoveLastGutter
    ) {
        let start = ScoreCanvas.getPixelsForTimesteps({
            timesteps: Time.fractionToTimesteps(
                this.noteRes,
                patternRegion.start
            ),
            removeLastGutterPx: removeLastGutterPx,
            grid: this.grid,
            timestepRes: this.noteRes,
        })

        let end = ScoreCanvas.getPixelsForTimesteps({
            timesteps: Time.fractionToTimesteps(
                this.noteRes,
                Time.addTwoFractions(
                    patternRegion.start,
                    patternRegion.duration
                )
            ),
            removeLastGutterPx: removeLastGutterPx,
            grid: this.grid,
            timestepRes: this.noteRes,
        })

        let width = end - start - this.windowBorder

        return width
    }

    private getPatternRegionX(
        patternRegion: PatternRegion,
        loopIndex: number,
        removeLastGutterPx: RemoveLastGutter
    ) {
        return (
            loopIndex *
                this.getPatternRegionWidth(patternRegion, removeLastGutterPx) +
            this.getPatternRegionOffset(patternRegion, removeLastGutterPx)
        )
    }

    private getRegionColor(
        layer: PercussionLayer,
        style?: "light" | "dark" | undefined
    ) {
        const color: TinyColor = new TinyColor(layer.getColor())

        let fillStyle = color.toRgbString()

        if (style === "light") {
            fillStyle = color.lighten(50).toRgbString()
        } else if (style === "dark") {
            fillStyle = color.darken(10).toRgbString()
        }

        return fillStyle
    }

    private getEdgeWidth(
        patternRegion: PatternRegion,
        removeLastGutterPx: RemoveLastGutter = "fully"
    ) {
        const width = this.getPatternRegionWidth(
            patternRegion,
            removeLastGutterPx
        )
        const borderRadius = this.getBorderRadius(
            patternRegion,
            removeLastGutterPx
        )

        let edgeWidth = this.edgeWidth

        if ((edgeWidth + 3) * 2 >= width) {
            edgeWidth = width / 2 - 3
        }

        // adjust the edge width to assure there is enough space
        // to drag and move the region
        if (edgeWidth < borderRadius + 4 || width < 2 * edgeWidth + 10) {
            return width / 3
        }

        return edgeWidth
    }

    private getBorderRadius(
        patternRegion: PatternRegion,
        removeLastGutterPx: RemoveLastGutter = "fully"
    ) {
        const width = this.getPatternRegionWidth(
            patternRegion,
            removeLastGutterPx
        )
        let borderRadius = this.context.borderRadius

        if ((borderRadius + 3) * 2 >= width) {
            borderRadius = width / 2 - 3
        }

        if (borderRadius < 0) {
            return 0
        }

        return borderRadius
    }

    getPatternRegionBorderColor(
        scoreState: ScoreRendering,
        layer: PercussionLayer,
        patternRegion: PatternRegion
    ) {
        const selected = this.isSelectedPatternRegion(scoreState, patternRegion)

        if (selected) {
            return "rgba(255, 255, 255, 0.6)"
        }

        return this.getPatternRegionTitleColor(layer, !selected)
    }

    getPatternRegionTitleColor(layer: PercussionLayer, selected) {
        if (!selected) {
            return "white"
        }

        const rgb = layer.getColorRange()[0]
        const hsl = Color.rgbToHsl(rgb.r, rgb.g, rgb.b)

        hsl[2] /= 2

        return "hsl(" + hsl[0] + "," + hsl[1] + "%," + hsl[2] + "%)"
    }
}
