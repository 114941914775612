<div id="upload-midi-container">
    <div class="upload-firststep" *ngIf="step == 1">
        <ngx-file-drop [showBrowseBtn]="true" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
            (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" accept="{{ acceptString }}">

            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div (click)="openFileSelector()" class="upload-influence-dropzone" *ngIf="!isMobile()">
                    <img src="assets/img/menu/influences.svg">Drag & Drop a MIDI or Audio file here*<br>
                </div>

                <div (click)="openFileSelector()" class="upload-influence-dropzone-mobile" *ngIf="isMobile()">
                    <img src="assets/img/menu/influences.svg"> Upload a MIDI or Audio file
                </div>
            </ng-template>
        </ngx-file-drop>

        <div class="influence-tips" *ngIf="!isMobile()">*Only audio files containing <span class="link"
                (click)="audioInfluenceGuide()">valid musical content</span>, and midi files formatted <span
                (click)="midiInfluenceGuide()" class="link">according to our guidelines</span> will be correctly
            analysed by AIVA.</div>

        <div class="no-audio-converted" *ngIf="!isMobile()"></div>

        <div class="or" *ngIf="!isMobile()">or</div>

        <a-button *ngIf="!isMobile()" label="Use an existing influence" type="secondary" (onClick)="startCreateWithInfluenceMode()"></a-button>
        <br>

        <div class="file-upload-error" *ngIf="fileUploadError != ''">
            {{ fileUploadError }}
        </div>
    </div>

    <div class="upload-firststep upload-influence-key-signature" *ngIf="step == 2">
        <div class="upload-influence-key-signature-title">Do you know the key signature of your influence?</div>
        <div class="upload-influence-key-signature-subtitle">Our key detection algorithms are 80% accurate, so we only
            recommend specifying a key signature if you are sure of it. An incorrectly selected key signature can have
            negative effects, so we don't recommend using this parameter for experimentation purposes.</div>
        <div class="preset-parameter-wrapper">
            <dropdown class="do-you-know-key-signature-100" type="light" [value]="keySignature" [showTooltip]="false" [items]="getKeySignatureOptions()" (selectedItem)="changeKeySignature($event)"></dropdown>
        </div>

        <div class="modal-button-containers">
            <div id="button-grey" class="modal-button" (click)="goBackToStepOne()">
                Cancel
            </div>

            <div id="button-grey" class="modal-button create-modal-button modal-blue-button" (click)="goToStepThree()">
                Done
            </div>
        </div>
    </div>

    <div class="upload-secondstep" *ngIf="step == 3 && influenceType == 'midi'">
        <div class="loader"></div>
        Uploading your file for analysis...
    </div>

    <div class="upload-secondstep" *ngIf="step == 3 && influenceType == 'audio'">

        <div class="headline" *ngIf="showTrimmingAudioEditor()">Select the audio region to influence AIVA on</div>
        <div class="header">
            <div class="meta-data" *ngIf="showTrimmingAudioEditor()">
                <div class="title" title="{{ fileMetaData.name }}"><span class="text">{{ fileMetaData.name }}</span>
                    <span class="time">({{ getFormattedTime(fileMetaData.duration) }})</span></div>
            </div>
            <div class="controls" *ngIf="showTrimmingAudioEditor()">
                <div class="play" *ngIf="!fileMetaData.isPlaying" (click)="play()">
                    <img src="assets/img/player/play.svg" alt="Play"> <span class="text">Play audio</span>
                </div>
                <div class="pause" *ngIf="fileMetaData.isPlaying" (click)="pause()">
                    <img src="assets/img/player/pause.svg" alt="Pause"> <span class="text">Pause audio</span>
                </div>
            </div>
        </div>

        <div #influenceAudioWaveform class="influence-audio-waveform">
            <div class="background" [ngClass]="showTrimmingAudioEditor() ? 'dark-background' : ''"></div>
            <div class="audio-trim-controls" *ngIf="showTrimmingAudioEditor()">
                <div class="left-mask" [style.width.px]="leftMaskWidth"></div>

                <div class="trim-area" [style.width.px]="trimAudioArea.width" [style.left.px]="trimAudioArea.startX">
                    <div #dragHandleStart class="start" (mousedown)="startDraggingTrimArea($event, 'start')"
                        [ngClass]="showTrimmingWarning ? 'red-background' : ''">
                        <div class="drag-indicator"></div>
                        <div class="start-time">{{ getFormattedTime(trimAudioArea.startTime) }}</div>
                    </div>

                    <div class="top" [style.width.px]="trimAudioAreaWidth"
                        (mousedown)="startDraggingTrimArea($event, 'middle')"
                        [ngClass]="showTrimmingWarning ? 'red-background' : ''"></div>
                    <div class="bottom" [style.width.px]="trimAudioAreaWidth"
                        (mousedown)="startDraggingTrimArea($event, 'middle')"
                        [ngClass]="showTrimmingWarning ? 'red-background' : ''"></div>

                    <div #dragHandleEnd class="end" (mousedown)="startDraggingTrimArea($event, 'end')"
                        [ngClass]="showTrimmingWarning ? 'red-background' : ''">
                        <div class="drag-indicator"></div>
                        <div class="end-time">{{ getFormattedTime(trimAudioArea.endTime) }}</div>
                    </div>
                </div>

                <div class="right-mask" [style.width.px]="rightMaskWidth"></div>

                <div class="audio-trim-warning" [style.opacity]="showTrimmingWarning ? 1 : 0">The selected region must
                    be between {{ getFormattedTime(minimumAudioDuration) }} and {{
                    getFormattedTime(maximumAudioDuration) }} long</div>

                <div class="low-amplitude-info"
                    [style.opacity]="audioHasPartsBelowAmplitudeThreshold && !showTrimmingWarning ? 1 : 0">Audio zones
                    highlighted in red areas are automatically detected to be too quiet to help with audio analysis. If
                    you can, avoid including them in your trimmed region.</div>
            </div>

            <div class="loader-container" *ngIf="loader.loading && loader.type == 'analyse'">
                <div class="loader"></div>
                <div class="message">Analysing your audio file ...</div>
            </div>

            <div class="loader-container" *ngIf="loader.loading && loader.type == 'trimming'">
                <!-- <div class="loader-container"> -->
                <div class="message">Preparing and uploading your audio file ...</div>
                <div class="message-subtitle">(This should take around ~30 seconds. Please do not close this window.)
                </div>
                <progressbar [progress]="audioEditingPercentage" [total]="100"></progressbar>
            </div>

            <div class="loader-container" *ngIf="!loader.loading && !audioNeedsTrimming">
                <div class="loader"></div>
                <div class="message">Uploading your audio file ...</div>
            </div>

            <div id="influence-canvas-container">
                <canvas class="waveform" #influenceAudioCanvas *ngIf="loader.type != 'trimming'"
                    (click)="seek($event)"></canvas>
                <canvas class="below-amp-threshold" #belowAmpThresholdCanvas
                    [style.opacity]="displayBelowAmpThresholdCanvas ? '0.25' : '0'"
                    *ngIf="loader.type != 'trimming'"></canvas>
                <div class="seek-bar-container">
                    <div class="seek-bar" [style.left.%]="seekPosition" *ngIf="showTrimmingAudioEditor()"></div>
                </div>
            </div>
        </div>

        <div class="button-row" *ngIf="showTrimmingAudioEditor()">
            <div class="modal-button modal-text-button" (click)="cancelAudioTrimming()">Cancel</div>
            <div class="modal-button modal-blue-button" (click)="encodeAndUpload()"><img src="assets/img/cut.svg"
                    alt="Trim"> Trim and upload</div>
        </div>
    </div>
</div>