<div class="modal-title">Synchronize {{ pack.name }}</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<div class="synchronisation-container">
    <div class="pack-synch-settings-row">
        <div class="settings-row-title">Select a pack to synchronize to</div>
        <div class="settings-row-value">
            <dropdown
                [items]="getAssignedAccompanimentPacks()"
                [value]="getSelectedPack()"
                (selectedItem)="selectPack($event)"
                class="select-pack-to-sync-to"></dropdown>
        </div>
    </div>

    <div
        class="pack-synch-settings-row"
        *ngIf="synchSelection.packToSynchTo != null && !synchronizationIsLoading"
        [ngClass]="{
            'pack-synch-settings-row-disabled': synchSelection.transform
        }">
        <div class="settings-row-title">
            Synchronisation intensity
            <img
                src="assets/img/info.svg"
                [tooltip]="tooltips.synchronisation"
                [tooltip-marginTop]="10"
                [tooltip-width]="250" />
        </div>
        <div
            class="settings-row-value"
            *ngIf="
                !synchSelection.transform &&
                synchronisationSliderOptions?.ceil > 0
            ">
            <ngx-slider
                class="light-ngx-slider"
                [(value)]="synchSelection.strength"
                [options]="synchronisationSliderOptions"
                (userChangeEnd)="synchronisePack()"></ngx-slider>
        </div>

        <div
            class="settings-row-value"
            *ngIf="
                synchSelection.transform &&
                synchronisationSliderOptions?.ceil > 0
            ">
            Disabled while transform is applied
        </div>

        <div
            class="settings-row-value"
            *ngIf="synchronisationSliderOptions?.ceil === 0">
            Synchronisation is not compatible with the select pack
        </div>
    </div>

    <div class="loader" *ngIf="synchronizationIsLoading"></div>

    <div
        class="pack-synch-settings-row"
        *ngIf="
            synchSelection.packToSynchTo != null && layer.type !== 'percussion'
        ">
        <div class="settings-row-title">
            Transform
            <img
                src="assets/img/info.svg"
                [tooltip]="tooltips.transform"
                [tooltip-marginTop]="10"
                [tooltip-width]="250" />
        </div>
        <div class="settings-row-value">
            <slide-toggle
                [(toggled)]="synchSelection.transform"
                (onChange)="synchronisePack()"></slide-toggle>
        </div>
    </div>
</div>
