import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit,
} from "@angular/core"
import { NotificationsService } from "@services/notifications.service"
import Notification from "@common-lib/classes/notification"
import { Router } from "@angular/router"

@Component({
    selector: "notifications",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit {
    notification: Notification

    constructor(
        private notificationService: NotificationsService,
        private ref: ChangeDetectorRef,
        private router: Router
    ) {}

    async ngOnInit() {
        this.notificationService.showNotification.subscribe(
            (notification: Notification) => {
                if (notification == null) {
                    this.hideNotification(null, true)
                } else {
                    this.notification = notification
                }

                this.ref.detectChanges()
            }
        )
    }

    hideNotification(event, animation) {
        if (event != null) {
            event.stopPropagation()
        }

        this.notification = null

        if (animation) {
        }
    }

    openNotification(event) {}

    showNotification(notification: Notification) {
        this.notification = notification
    }
}
