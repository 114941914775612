import {
    Component,
    OnInit,
    ViewChild,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from "@angular/core"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { BillingService } from "@services/billing.service"
import { CompositionService } from "@services/composition.service"
import { TracksService } from "@services/tracks.service"
import { ModalService } from "@services/modal.service"
import { FolderService } from "@services/folder.service"
import { Misc } from "@common-lib/modules/misc"
import { environment } from "@environments/environment"
import { GPHarmonyStrategy } from "@common-lib//types/generationProfiles"
import { GenerationProfileManipulation } from "@common-lib/modules/generation-profile-manipulation"
import { TutorialService } from "@services/tutorial.service"

@Component({
    selector: "create-with-gp",
    templateUrl: "createwithgp.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    //styleUrls: ['create-with-gp.component.scss']
})
export class CreateWithGPComponent implements OnInit {
    parameters = {
        duration: "auto",
        numberOfCompositions: 1,
        keySignature: "auto",
    }

    loading = false
    originalKeySignature

    error = null

    strategy: GPHarmonyStrategy = "Functional"
    keyMode: string = "C minor"

    @ViewChild("captchaRef", { static: false }) captchaRef

    constructor(
        private folderService: FolderService,
        private modalService: ModalService,
        private tracksService: TracksService,
        private billingService: BillingService,
        private gpService: GenerationProfileService,
        private ref: ChangeDetectorRef,
        private tutorialService: TutorialService
    ) {}

    async ngOnInit() {
        this.loading = true

        const modalInputs =
            this.modalService.compositionCreationWithGP.getValue()

        if (!modalInputs.gp) {
            const result = await this.gpService.getHarmonyStrategy({
                sourceGPFolder: modalInputs.sourceGPFolder,
                sourceGPCategory: modalInputs.sourceGPCategory,
            })

            if (result === undefined) {
                return this.modalService.compositionCreationWithGP.next(null)
            }

            this.strategy = result.strategy
            this.keyMode = result.keyMode
        } else {
            this.strategy = modalInputs.gp.harmony.strategy
            this.keyMode = modalInputs.gp.harmony.keySignature.keyMode
        }

        this.loading = false

        this.ref.detectChanges()
        if (this.tutorialService.isTutorialInProgress$.value) {
            this.tutorialService.tutorialNext$.next(true)
        }
    }

    getSelectedDuration() {
        return {
            name: this.capitalize(this.parameters.duration),
            value: this.parameters.duration,
        }
    }

    getSelectedNumberOfCompositions() {
        return {
            name: this.parameters.numberOfCompositions,
            value: this.parameters.numberOfCompositions,
        }
    }

    selectNumberOfCompositions(event) {
        this.parameters.numberOfCompositions = event.new.value
    }

    getSelectedKeySignature() {
        let isAnyKey = this.getAutoKeys().includes(this.parameters.keySignature)
        let name = isAnyKey
            ? this.capitalize(this.parameters.keySignature)
            : this.parameters.keySignature
        let optgroup

        if (this.strategy === "Functional") {
            if (
                this.getKeyItemsByMode("major").includes(
                    this.parameters.keySignature
                )
            ) {
                optgroup = "Major"
            } else if (
                this.getKeyItemsByMode("minor").includes(
                    this.parameters.keySignature
                )
            ) {
                optgroup = "Minor"
            } else {
                optgroup = "Any"
            }
        }

        return {
            name: name,
            value: this.parameters.keySignature,
            optgroup: optgroup,
        }
    }

    getKeySignatureOptions() {
        let options = []

        if (this.strategy === "Modal") {
            options = options.concat(
                Misc.getKeySignatureOptions(this.strategy)[this.keyMode]
            )

            const auto = [
                {
                    name: "Any",
                    value: "auto",
                },
            ]

            return auto.concat(
                options.map(ks => {
                    return {
                        value: ks,
                        name: ks,
                    }
                })
            )
        }

        for (let a of this.getAutoKeys()) {
            options.push({
                name: this.capitalize(a),
                value: a,
                optgroup: "Any",
            })
        }

        for (let maj of this.getKeyItemsByMode("major")) {
            let name = maj

            if (name == this.originalKeySignature) {
                name = "(Originally detected) " + name
            }

            options.push({
                name: name,
                value: maj,
                optgroup: "Major",
            })
        }

        for (let min of this.getKeyItemsByMode("minor")) {
            let name = min

            if (name == this.originalKeySignature) {
                name = "(Originally detected) " + name
            }

            options.push({
                name: min,
                value: min,
                optgroup: "Minor",
            })
        }

        return options
    }

    selectKeySignature(event) {
        this.parameters.keySignature = event.new.value
        this.validateMode()
    }

    getAutoKeys() {
        return ["auto", "any major", "any minor"]
    }

    setDuration(event) {
        let duration = event.new.value

        if (this.showDuration(duration)) {
            this.parameters.duration = duration
        }
    }

    getKeyItemsByMode(mode) {
        return Misc.getKeySignatureOptions(this.strategy)[mode]
    }

    capitalize(key) {
        return Misc.capitalizeFirstLetter(key)
    }

    selectKey(selectedKey) {
        this.parameters.keySignature = selectedKey
    }

    validateMode() {
        let compositionCreationWithGP =
            this.modalService.compositionCreationWithGP.getValue()
        let mode = this.getModeFromParameters()

        if (compositionCreationWithGP.gp == null || mode == "any") {
            return
        }

        let modeValidation = GenerationProfile.validateMode(
            compositionCreationWithGP.gp,
            mode
        )

        if (modeValidation.valid == false) {
            this.error = modeValidation.message
            this.loading = false

            return
        }

        this.error = null
    }

    getModeFromParameters() {
        let mode = "any"

        if (this.parameters.keySignature == null) {
            return mode
        }

        if (this.parameters.keySignature.includes("minor")) {
            mode = "minor"
        } else if (this.parameters.keySignature.includes("major")) {
            mode = "major"
        }

        return mode
    }

    createWithGPCaptcha() {
        this.loading = true
        this.ref.detectChanges()

        this.captchaRef.execute()
    }

    async createWithGP(captchaResponse) {
        let compositionCreationWithGP =
            this.modalService.compositionCreationWithGP.getValue()
        let gpID =
            compositionCreationWithGP.gp != null
                ? compositionCreationWithGP.gp._id
                : null

        if (this.error != null) {
            this.loading = false
            return
        }

        let folderID = this.folderService.getSelectedFolderID()

        if (this.folderService.contentType.getValue() != "Compositions") {
            folderID = ""
        }

        this.tracksService
            .createComposition(
                {
                    generationProfileID: gpID,
                    folderID: folderID,
                    sourceGPFolder: compositionCreationWithGP.sourceGPFolder,
                    sourceGPCategory:
                        compositionCreationWithGP.sourceGPCategory,
                    generationParams: this.parameters,
                    captchaResponse: captchaResponse,
                },

                "generationProfiles"
            )

            .then(async () => {
                this.loading = false
            })

            .catch(e => {
                console.error(e)
            })
    }

    getCaptchaKey() {
        return environment.recaptchaSiteKey
    }

    getGPCompositionNumber() {
        let result = []
        let compositionNumber = GenerationProfile.COMPOSITION_NUMBER

        if (!environment.production) {
            compositionNumber = [1, 2, 3, 4, 5, 10, 15, 20, 25, 50]
        }

        for (let number of compositionNumber) {
            result.push({
                name: number,
                value: number,
            })
        }

        return result
    }

    showDuration(duration) {
        const validDurations = CompositionService.getValidDurations(
            this.billingService.subscription.getValue()
        )

        if (!validDurations.includes(duration)) {
            return false
        }

        return true
    }

    getGenerationProfileDurations() {
        return GenerationProfileManipulation.getGenerationProfileDurations(
            CompositionService.getValidDurations(
                this.billingService.subscription.getValue()
            )
        )
    }
}
