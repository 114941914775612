import { MAX_TEMPO, MIN_TEMPO } from "./constants"

export const API_ERRORS = {
    invalidAccompanimentActionType: {
        message: "Please select a valid accompaniment action type",
        sentry: false,
    },

    invalidAccompanimentVariationType: {
        message: "Please select a valid variation type",
        sentry: false,
    },

    couldntCreateNewAccompanimentPack: {
        message: "Could not create new accompaniment pack",
        sentry: false,
    },

    harmonyGenerationHourlyLimitFree: {
        message:
            "You can't generate more than 100 progressions per hour. Upgrade your subscription to unlock 300 per hour.",
        sentry: false,
    },

    harmonyGenerationDailyLimitFree: {
        message:
            "You can't generate more than 300 progressions per day. Upgrade your subscription to unlock 1000 per day.",
        sentry: false,
    },

    harmonyGenerationHourlyLimitPaid: {
        message: "You can't generate more than 300 progressions per hour.",
        sentry: false,
    },

    harmonyGenerationDailyLimitPaid: {
        message: "You can't generate more than 1000 progressions per day",
        sentry: false,
    },

    invalidTimeSignature: {
        message: "Please select a valid time signature",
        sentry: false,
    },

    corruptedSections: {
        message:
            "Saving failed due to sections appearing corrupted. Please contact support for assistance.",
        sentry: true,
    },

    emptyAccompanimentPatterns: {
        message:
            "Cannot save an accompaniment pack that does not contain any notes.",
        sentry: false,
    },

    invalidPackID: {
        message: "Please select a valid pack ID",
        sentry: false,
    },

    invalidAPIRequest: {
        message: "Invalid request data provided.",
        sentry: true,
    },

    loginError: {
        message: "An unexpected error occurred during login. Please try again.",
        sentry: true,
    },

    googleLoginError: {
        message:
            "This account is not known or not activated yet. Please try again or create a new account.",
        sentry: true,
    },

    incorrectPass: {
        message: "Invalid email/password combination",
        sentry: false,
    },

    gpTooSimilar: {
        message:
            "This profile is too similar to a profile that was already published in the library. To bypass this message, you can make edit your generation profile further and make it stand out!",
        sentry: false,
    },

    couldntFindACategoryForClass: {
        message: "Could not find a category for className: ",
        sentry: false,
    },

    couldntFindPatternForID: {
        message: "Could not find a matching pack",
        sentry: false,
    },

    invalidAccompanimentPackType: {
        message:
            "Invalid Accompaniment Pack type. Authorized types are: 'Accompaniment', 'Bass'",
        sentry: false,
    },

    compositionTooLong: {
        message:
            "Composition longer than 30 minutes are not currently supported. Please reduce length of your composition.",
    },

    mismatchBetweenOpsAndSections: {
        message:
            "Mismatch between operations and sections. Please contact support for assistance.",
        sentry: false,
    },

    incompatibleGPForSectionModification: {
        message:
            "The generation profile associated to this composition has changed. To modify sections, please revert the profile to use the following time signature: ",
        sentry: false,
    },

    invalidGPForRegeneration: {
        message:
            "The generation profile associated to this composition is invalid. Please modify it before trying to use section modification operations. ",
    },

    compositionAlreadyRendering: {
        message: "This composition is already being rendered",
        sentry: false,
    },

    gpTooSimilarToRevision: {
        message:
            "This profile is too similar to a previous revision. To bypass this message, you can tweak your generation profile further.",
        sentry: false,
    },

    uploadValidInfluence: {
        message: "Please upload a valid MIDI or Audio file",
        sentry: false,
    },

    missingLayerError: {
        message:
            "The influence is not compatible with this layer. Please try using it on one of the compatible layers: ",
        sentry: false,
    },

    selectValidGPInfluence: {
        message: "Select a valid GP Influence",
        sentry: false,
    },

    alreadyUploadInfluenceForLayer: {
        message: "You already uploaded an influence for that layer.",
        sentry: false,
    },

    incorrectCurrentPassword: {
        message: "Your current password is invalid, please try again.",
        sentry: false,
    },

    tooManyAttempts: {
        message:
            "You have tried to log in too many times. Please wait a minute and try again.",
        sentry: false,
    },

    usernameTooLong: {
        message: "This username exeeds the 35 characters limit",
        sentry: false,
    },

    gpCategoryNameAlreadyInUse: {
        message:
            "This name is already in use in the Library. Please use a different one",
        sentry: false,
    },

    usernameTooShort: {
        message: "Your username needs to be at least 1 character long",
        sentry: false,
    },

    passwordTooLong: {
        message: "Your password can't be longer than 50 characters",
        sentry: false,
    },

    usernameAlreadyTaken: {
        message: "This username is already taken",
        sentry: false,
    },

    userCreationError: {
        message: "An error occurred during the creation of your account.",
        sentry: true,
    },

    acceptTNC: {
        message: "You must accept the Terms & Conditions to create an account.",
        sentry: false,
    },

    passwordTooShort: {
        message:
            "You must enter a password that is at least 6 characters long.",
        sentry: false,
    },

    invalidEmail: {
        message: "Please enter a valid email address",
        sentry: false,
    },

    fraudulousIP: {
        message: "Error code -3, please contact support",
        sentry: true,
    },

    emailTakenUseOtherLoginMethod: {
        message:
            "This email is already being used. Please use a different login method.",
        sentry: false,
    },

    emailTaken: {
        message: "This email is already being used.",
        sentry: false,
    },

    projectError: {
        message: "An error occured while trying to create a project.",
        sentry: false,
    },

    projectNameError: {
        message: "An error occured while trying to rename your project",
        sentry: false,
    },

    compositionNameError: {
        message: "An error occured while trying to rename your composition",
        sentry: false,
    },

    onlyOneSectionOperation: {
        message: "Only one operation per section is allowed",
        sentry: false,
    },

    projectExists: {
        message: "This project name is already taken",
        sentry: false,
    },

    sectionOutOfBound: {
        message: "A selected section is out of bound or doesn't exist",
        sentry: false,
    },

    sourceShouldBeOtherThanItself: {
        message:
            "Regenerate operations on Intro, Bridge, and Outro sections should use a source section other than the one marker for regeneration",
        sentry: false,
    },

    creationThrottled: {
        message:
            "You can't create multiple accounts. To lift this restriction, please contact the support team.",
        sentry: false,
    },

    unexpectedError: {
        message: "An unexpected error occurred. Please try again.",
        sentry: false,
    },

    noCompositionID: {
        message: "Could not find composition for the provided ID",
        sentry: false,
    },

    noComposition: {
        message: "Could not find information about this composition",
        sentry: false,
    },

    invalidContentType: {
        message: "Invalid content type",
        sentry: false,
    },

    cannotOpenInfluencesInEditor: {
        message: "Influences can't be opened in the piano roll editor.",
        sentry: false,
    },

    noInfluence: {
        message: "Could not find information about this influence",
        sentry: false,
    },

    noCompositions: {
        message: "No compositions",
        sentry: false,
    },

    insertSectionLimit: {
        message: "You can't insert more than 20 sections at a time",
        sentry: false,
    },

    useVerifiedGoogleAccount: {
        message: "Please use a verified Google email",
        sentry: false,
    },

    subscribeInfluenceDuration: {
        message: "Subscribe to access more duration options.",
        sentry: false,
    },

    invalidEmotion: {
        message:
            "The emotion selected is not known to AIVA - please try another one.",
        sentry: false,
    },

    invalidPatch: {
        message: "Invalid Patch selected, please try again",
        sentry: false,
    },

    compositionNotFound: {
        message: "We could not find a matching composition in our record.",
        sentry: false,
    },

    invalidComposition: {
        message: "Please select a valid composition",
        sentry: false,
    },

    invalidFolder: {
        message: "Invalid folder parameter",
        sentry: false,
    },

    noProjects: {
        message: "No projects.",
        sentry: false,
    },

    invalidBillingOperation: {
        message: "Invalid billing operation - please select a different plan",
        sentry: true,
    },

    midiDownload: {
        message:
            "An error occured while processing your MIDI. Please contact the Support Team.",
        sentry: false,
    },

    emptyProjectName: {
        message: "The project name is empty",
        sentry: false,
    },

    setValidCountry: {
        message: "Please select a valid country from the drop down menu",
        sentry: true,
    },

    moveError: {
        message: "Could not move the composition to the defined project.",
        sentry: false,
    },

    sameUser: {
        message: "You can't share content with yourself!",
        sentry: false,
    },

    collaboratorAlreadyExists: {
        message:
            "You've already shared this composition with this collaborator!",
        sentry: false,
    },

    typeNotSupported: {
        message: "The file type you requested is not supported",
        sentry: true,
    },

    projectNotFound: {
        message: "Could not find information about this project",
        sentry: false,
    },

    orchestrationStopped: {
        message: "The Orchestration feature is currently turned off.",
        sentry: false,
    },

    compositionStopped: {
        message: "The Composition feature is currently turned off.",
        sentry: false,
    },

    uploadError: {
        message: "An error occurred when trying to upload your file.",
        sentry: false,
    },

    missingFile: {
        message: "Please upload a valid file.",
        sentry: false,
    },

    noCompositionForVariations: {
        message:
            "The composition you are trying to generate variations for doesn't exist.",
        sentry: false,
    },

    lockedDomain: {
        message: "This email domain is not accepted in development mode.",
        sentry: false,
    },

    invalidConfirmation: {
        message: "Invalid confirmation link",
        sentry: false,
    },

    needConfirmation: {
        message:
            "We sent a confirmation link to your email. Please confirm your email before trying to log in.",
        sentry: false,
    },

    confirmationSent: {
        message:
            "Account successfully created! Please check your inbox for a confirmation email.",
        sentry: false,
    },

    confirmationSuccess: {
        message:
            "Your email has been successfully verified! You can now login normally.",
        sentry: false,
    },

    invalidToken: {
        message: "The token provided is invalid.",
        sentry: true,
    },

    invalidGoogleToken: {
        message: "The token provided is invalid.",
        sentry: true,
    },

    passwordsNotMatching: {
        message: "The two passwords entered do not match.",
        sentry: false,
    },

    resetLinkExpired: {
        message:
            "Your reset link expired; please generate another one by using 'Forgot your password?'",
        sentry: false,
    },

    forgotSuccess: {
        message: "We just sent you an email with a password reset link.",
        sentry: false,
    },

    invalidUser: {
        message: "Invalid user data - try again",
        sentry: true,
    },

    unknownUserSentBySampler: {
        message: "It seems that the sampler server sent the wrong userID.",
        sentry: false,
    },

    code1: {
        message: "Error code 1 - please try again",
        sentry: false,
    },

    updating: {
        message: "AIVA is updating - please try again later",
        sentry: false,
    },

    AIOffline: {
        message:
            "An issue occured with the AI Engine - please try again or contact support",
        sentry: true,
    },

    samplerOffline: {
        message:
            "Audio Engine Updating or Offline - please wait or contact support to create a new track",
        sentry: true,
    },

    folderNotFound: {
        message: "Could not find the selected folder.",
        sentry: false,
    },

    folderNotShared: {
        message:
            "The selected folder is not shared. Please enable link sharing for this folder first.",
        sentry: false,
    },

    folderIsPrivate: {
        message:
            "The playlist you try to access is private and has not been shared by his owner. If you are the owner, please enable link sharing for this folder first.",
        sentry: false,
    },

    userNotAllowedToViewThisFolder: {
        message: "You do not have view permissions for this folder.",
        sentry: false,
    },

    couldntCreateBillingProfile: {
        message: "An error occurred while creating your billing profile",
        sentry: true,
    },

    couldntUpdateBillingProfile: {
        message: "An error occurred while updating your billing profile",
        sentry: true,
    },

    couldntRetrieveBillingProfile: {
        message: "An error occured while retrieving your billing profile",
        sentry: true,
    },

    couldntFindRadio: {
        message: "Could not find specified radio",
        sentry: false,
    },

    couldntRetrieveInvoice: {
        message:
            "An error occured while retrieving an invoice. Please try again.",
        sentry: true,
    },

    fillAllPaymentDetails: {
        message: "Please fill in all billing information",
        sentry: false,
    },

    selectValidPlan: {
        message: "Please select a valid subscription plan",
        sentry: true,
    },

    enterPaymentMethod: {
        message:
            "You must first register a payment method before subscribing to a plan",
        sentry: true,
    },

    noSubscription: {
        message: "This user does not have a valid subscription",
        sentry: true,
    },

    couldntCreateNewSubscription: {
        message:
            "Could not create a new subscription - Please contact the Support Team.",
        sentry: true,
    },

    couldntUpdateExistingSubscription: {
        message:
            "Could not update existing subscription - Please contact the Support team.",
        sentry: true,
    },

    alreadySubscribedToPlan: {
        message: "You are already subscribed to this plan!",
        sentry: false,
    },

    subscribe: {
        message: "You need to subscribe to any plan to access this feature",
        sentry: false,
    },

    subscribeToProAnnually: {
        message:
            "A Pro Annually subscription is required to access this feature",
        sentry: false,
    },

    subscribeToPro: {
        message:
            "You need to subscribe to any of our Pro plans to access this feature",
        sentry: false,
    },

    subscribeToDownload: {
        message:
            "To download Pop, Rock and Jazz compositions, you need to subscribe to any plan.",
        sentry: false,
    },

    subscribeToHigherPlan: {
        message:
            "You need to subscribe to the Standard Annually or Pro plan to access this feature",
        sentry: false,
    },

    subscribeToAnnualPlan: {
        message:
            "Subscribe to the Standard Annually or Pro Annually plan to access this feature",
        sentry: false,
    },

    ranOutOfQuota: {
        message:
            "You don't have enough downloads left in your quota to do this operation.",
        sentry: false,
    },

    couldntClaimReward: {
        message:
            "An error occured while claiming your reward - please try again.",
        sentry: false,
    },

    noRewardLeft: {
        message: "You don't have any rewards left to claim",
        sentry: false,
    },

    tooManyDownloads: {
        message:
            "You've already downloaded 15 tracks this month. To download more compositions, please upgrade to the Pro plan.",
        sentry: false,
    },

    tooManyDownloadsPro: {
        message:
            "You've already downloaded 300 tracks this month. Please wait until the end of your billing month in order for your quota to reset",
        sentry: false,
    },

    tooManyDownloadsFree: {
        message:
            "You've already downloaded 3 tracks this month. To increase your quota, please upgrade to a paid plan.",
        sentry: false,
    },

    disposableEmail: {
        message:
            "Please use a valid (non disposable) email address. You can always contact us to delete your account if you wish.",
        sentry: false,
    },

    newPasswordsDontMatch: {
        message:
            "The new passwords that you entered dont match. Please make sure to enter the same password twice.",
        sentry: false,
    },

    influenceError: {
        message: "An error occurred while uploading your influence.",
        sentry: false,
    },

    couldntRetrievePreview: {
        message: "Could not retrieve billing preview - please try again!",
        sentry: false,
    },

    cantStoreFileOnDisk: {
        message: "An error occured while trying to store file on disk",
        sentry: false,
    },

    compositionAlreadyFinished: {
        message: "The composition is already finished.",
        sentry: false,
    },

    pleaseProvideMIDIFiles: {
        message: "Please provide MIDI files",
        sentry: false,
    },

    cannotWriteFileToDisk: {
        message: "Cannot write file to disk",
        sentry: false,
    },

    cantUpgradeSubscriptionToProMonthly: {
        message: "You can't downgrade from Standard Annually to Pro Monthly.",
        sentry: false,
    },

    invalidPreset: {
        message: "Invalid Preset",
        sentry: false,
    },

    invalidStyle: {
        message: "Invalid Style",
        sentry: false,
    },

    invalidCompositionType: {
        message: "Invalid composition type",
        sentry: false,
    },

    notOnboarded: {
        message:
            "You have not yet been onboarded - please wait until you receive your invitation, or contact support at feedback@aiva.ai",
        sentry: false,
    },

    concurrentInfluencesAnalysis: {
        message:
            "You can't upload for analysis more than 2 influences at a time. Please wait for your current analysis to complete first.",
        sentry: false,
    },

    tooManyTracks: {
        message: "You can't generate more than 5 tracks at a time",
        sentry: false,
    },

    noUserMatchingStripeID: {
        message: "No user matching given Stripe ID.",
        sentry: false,
    },

    noUserMatchingBraintreeID: {
        message: "No user matching given Braintree ID.",
        sentry: false,
    },

    invalidParameters: {
        message: "You have selected invalid composition parameters.",
        sentry: false,
    },

    highRiskEmail: {
        message:
            "This email is at high risk of not receiving a signup confirmation - please use another email or contact support",
        sentry: false,
    },

    influencesMaxLimit: {
        message:
            "You can't store more than 250 influences on your account at one time. If you wish to upload more influences, please delete existing ones.",
        sentry: false,
    },

    storageLimit: {
        message:
            "You can't store more than 4,000 compositions on your account at one time. If you wish to keep creating new tracks, please delete existing ones.",
        sentry: false,
    },

    hourlyLimit: {
        message: "You can't compose more than 150 tracks per hour",
        sentry: false,
    },

    dailyLimit: {
        message:
            "You can't generate more than 300 tracks per preset and per day",
        sentry: false,
    },

    globalDailyLimit: {
        message: "You can't create more than 400 tracks every 24 hours",
        sentry: false,
    },

    downloadQuantityLimit: {
        message:
            "You can't download folder with more than 500 compositions in it",
        sentry: false,
    },

    invalidMidiFormatting: {
        message: "Invalid MIDI file formatting, please try again.",
        sentry: false,
    },

    invalidInstrumentation: {
        message: "Invalid instrumentation parameters",
        sentry: false,
    },

    couldntUpdateInstrumentation: {
        message: "Could not update instrumentation, please try again later",
        sentry: false,
    },

    invalidInstrument: {
        message:
            "An invalid instrument was used in the midi file. Please try again.",
        sentry: false,
    },

    invalidMIDIInformation: {
        message: "The MIDI information is invalid. Please try again.",
        sentry: false,
    },

    cantOpenOldCompositions: {
        message:
            "Compositions created before September 2019 are incompatible with the piano roll editor.",
        sentry: false,
    },

    incompatibleComposition: {
        message: "This composition is not compatible with this feature.",
        sentry: false,
    },

    invalidRegenerationType: {
        message: "Invalid regeneration type.",
        sentry: false,
    },

    invalidSection: {
        message: "Please select a valid section.",
        sentry: false,
    },

    incompatibleSection: {
        message:
            "Intro, Outro and Bridge sections cannot be regenerated or replaced.",
        sentry: false,
    },

    ownerOfCompositionOnly: {
        message:
            "Only the owner of a composition can make modifications to it.",
        sentry: false,
    },

    midiProcessingError: {
        message:
            "An error occurred while processing a composition's midi file.",
        sentry: false,
    },

    noPasswordSet: {
        message:
            "You haven't set a password. Please check your inbox for a confirmation email, or click on 'Reset my password'",
        sentry: false,
    },

    wavAlreadyExported: {
        message:
            "The WAV Audio file was already exported (or is in the process of being exported) for this composition.",
        sentry: false,
    },

    renderWAVFirst: {
        message:
            'In order to download a WAV file, you first need to render it using the "Render Uncompressed WAV" button below.',
        sentry: false,
    },

    renderStemsFirst: {
        message:
            'In order to download Stems, you first need to render it using the "Render Stems" button below.',
        sentry: false,
    },

    invalidSubscriptionPlan: {
        message: "Invalid Subscription Plan",
        sentry: true,
    },

    paypalSubscriptionError: {
        message:
            "An error occured while trying to initiate the Paypal subscription process. Please contact support.",
        sentry: true,
    },

    recaptchaError: {
        message:
            "An error occured, please try again (code -2) or contact support",
        sentry: true,
    },

    couldntLoadJSON: {
        message:
            "An error occured while processing your composition; please contact support or try again later.",
        sentry: true,
    },

    failedJSONConvert: {
        message:
            "Could not convert composition to newest version. Please contact support.",
        sentry: false,
    },

    incompatiblePianoRoll: {
        message:
            "This Composition is incompatible with the new Piano Roll view",
        sentry: false,
    },

    inactiveSubscription: {
        message:
            "3D Secure Authentication failed. Please try again or contact the Support Team.",
        sentry: true,
    },

    freeInfluencesMaxLimit: {
        message: "Subscribe to any plans to store more than 10 Influences.",
        sentry: false,
    },

    tooManyIndividualTracks: {
        message: "Too many tracks in composition - please reduce to 40 or less",
        sentry: false,
    },

    userCreatedAccountWithOtherService: {
        message:
            "An account with this email address was not found. Please try a different sign in method or contact support if you are unable to access your account.",
        sentry: false,
    },

    userCreatedAccountWithGoogle: {
        message:
            "Your account was created using Google. Please sign in with Google.",
        sentry: false,
    },

    userCreatedAccountWithPassword: {
        message:
            "Your account was created using a password, please use the password sign-in method.",
        sentry: false,
    },

    forgotOtherServiceUsed: {
        message:
            "An error occured - this may happen in case you've used another service to create your account.",
        sentry: false,
    },

    resetOtherServiceUsed: {
        message:
            "An error occured - this may happen in case you've used another service to create your account.",
        sentry: false,
    },

    forgotConfirmationSent: {
        message:
            "We just sent you a confirmation email! Please check your inbox.",
        sentry: false,
    },

    vatFraudAssumption: {
        message:
            "The country you submitted and the country registered with your payment method need to match.",
        sentry: true,
    },

    braintreeTokenError: {
        message:
            "An error occured while creating the necessary token. Please try again later.",
        sentry: true,
    },

    couldntRetrieveBraintreePlans: {
        message: "An error occured while retrieving Braintree plans.",
        sentry: true,
    },

    couldntRetrieveSubscriptions: {
        message: "An error occured while retrieving the current subscriptions.",
        sentry: true,
    },

    couldntRetrievePlan: {
        message:
            "An error occured while retrieving information about the plan.",
        sentry: true,
    },

    couldntRetrievePaymentMethods: {
        message:
            "An error occured while retrieving your saved payment details.",
        sentry: true,
    },

    featureForNewCustomersOnly: {
        message:
            "We are sorry but as of right now this feature is only accessible for new customers.",
        sentry: true,
    },

    unpaidSubscriptionOldPaymentGateway: {
        message:
            "An error occured while updating your billing information. Please contact support for more information.",
        sentry: true,
    },

    invalidSubscriptionType: {
        message: "Please set a valid subscription type.",
        sentry: true,
    },

    couldntCreateInvoice: {
        message: "There was an error while creating this invoice.",
        sentry: true,
    },

    couldntRefundInvoice: {
        message: "There was an error while refunding this invoice.",
        sentry: true,
    },

    couldntCreateBraintreePaymentMethod: {
        message:
            "There was an error while creating the Braintree payment method.",
        sentry: true,
    },

    cantChangeCountryWithUnpaidSubscription: {
        message:
            "Because of an unpaid subscription, you can't change your billing country now. Please contact the support team.",
        sentry: true,
    },

    noPaymentMethodSelected: {
        message:
            "No payment method has been selected. Please contact the support team",
        sentry: true,
    },

    noGenerationProfile: {
        message: "Could not find information about this generation profile",
        sentry: false,
    },

    editDoublePermission: {
        message: "You can't edit a doubling instrument created by AIVA",
        sentry: false,
    },

    invalidDouble: {
        message: "Invalid doubling instrument",
        sentry: false,
    },

    invalidGP: {
        message: "Invalid generation profile",
        sentry: false,
    },

    missingAccompanimentPack: {
        message: "This accompaniment pack is missing from your account.",
        sentry: false,
    },

    missingAccompanimentPackValueForField: {
        message: "Missing value for field: ",
        sentry: false,
    },

    invalidDoubleAction: {
        message:
            "You are not allowed to perform this action on this double or double folder.",
        sentry: false,
    },

    dontOwnGP: {
        message: "You can't modify a generation profile that isn't yours",
        sentry: false,
    },

    invalidGPs: {
        message:
            "The GPs you selected are invalid and can't be used for generating compositions",
        sentry: false,
    },

    dontOwnPack: {
        message: "You can't modify a pack that isn't yours",
        sentry: false,
    },

    cantEditDeletedElement: {
        message: "You can't modify an element that has been deleted",
        sentry: false,
    },

    cantEditNonTemplatePack: {
        message: "You can't modify a pack that is not a template pack",
        sentry: false,
    },

    cantEditAivaPacks: {
        message: "You can't modify a pack that is created by AIVA",
        sentry: false,
    },

    couldntFindWorkflow: {
        message: "Could not find information about this workflow",
        sentry: false,
    },

    invalidCWName: {
        message: "Please enter a valid name for your composition workflow",
        sentry: false,
    },

    invalidCWDuration: {
        message: "Please enter a valid duration for your composition workflow",
        sentry: false,
    },
    savingUserBugReportError: {
        message: "An error occurred while saving your bug report",
        sentry: true,
    },
    getTrainingsetError: {
        message: "An error occurred while getting the trainingset",
        sentry: false,
    },
    saveTrainingsetScoreError: {
        message: "An error occurred while saving the score of the trainingset",
        sentry: false,
    },
}

export enum CWErrorTypes {
    chordIsShorterThanOneBeat = "chordIsShorterThanOneBeat",
    chordIsLongerThanOneMeasure = "chordIsLongerThanOneMeasure",
    chordProgressionIsLessThanEightMeasures = "chordProgressionIsLessThanEightMeasures",
    noLayers = "noLayers",
    noNotes = "noNotes",
    chordProgressionIsInvalid = "chordProgressionIsInvalid",
}

export const CLIENT_ERRORS = {
    [CWErrorTypes.chordProgressionIsInvalid]: {
        message: "The entered chord progression contains invalid chords.",
    },

    [CWErrorTypes.chordIsLongerThanOneMeasure]: {
        message: "Chords cannot be longer than one measure",
    },
    [CWErrorTypes.chordIsShorterThanOneBeat]: {
        message: "Chords cannot be shorter than one beat",
    },
    [CWErrorTypes.chordProgressionIsLessThanEightMeasures]: {
        message: "Chords should be exactly 8 measures",
    },
    [CWErrorTypes.noLayers]: {
        message: "Composition workflow should include at least one layer.",
    },
    [CWErrorTypes.noNotes]: {
        message: "Layers without any notes are not allowed.",
    },
    invalidTempo: {
        message: `Tempo must be between ${MIN_TEMPO}bpm and ${MAX_TEMPO}bpm`,
    },
    layerErrorOrLoading: {
        message: "One or more layer(s) are loading or have an error",
    },
    layerIsNot8Measures: {
        message: "Every layer should be exactly 8 measures in duration",
    },
    cantDeleteChord: {
        message: "The last chord in a bar can not be removed.",
    },
    chordProgressionLoading: {
        message: "Chord progression loading is in progress",
    },
    chordProgressionLoadingError: {
        message: "Failed to load chord progression",
    },
}
