import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
  enableProdMode()
}

// Object.assign(window.console, {
//   log : function(){},
//   warn : function(){},
//   error : function(){},
//   time : function(){},
//   timeEnd : function(){}
//  })

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err))
