import { SECTION_EDITING } from "../constants/constants"
import { EffectType } from "./score/effect"

export type SectionEditingType = SECTION_EDITING.CANCEL
| SECTION_EDITING.DELETE
| SECTION_EDITING.REPLACE
| SECTION_EDITING.REGENERATE
| SECTION_EDITING.REGENERATE_WITH_SOURCE
| SECTION_EDITING.INSERT_NEW
| SECTION_EDITING.INSERT_BLANK
| SECTION_EDITING.INSERT_COPY
| SECTION_EDITING.INSERT_VARIATION
export class ActivityMetric {
    time: number
    type: string
    meta: object = null

    static DUPLICATE = "duplicate"
    static DOWNLOAD_INFLUENCE = "download_influence"
    static LIKE = "like"
    static DISLIKE = "dislike"
    static MOVE_TO_FOLDER = "move_to_folder"
    static USE_AS_INFLUENCE = "use_as_influence"
    static CREATE_WITH_INFLUENCE = "create_with_influence"
    static SET_REAL_TIME_SAMPLER = "set_real_time_sampler"
    static CREATE_WITH_PRESET = "create_with_preset"
    static CREATE_NEW_FOLDER = "create_new_folder"
    static SWITCH_TO_PIANOROLL = "switch_to_pianoroll"
    static NOTE_EDITING = "note_editing"
    static AUTOMATION_EDITING = "automation_editing"
    static INSTRUMENT_EDITING = "instrument_editing"
    static BPM_EDITING = "bpm_editing"
    static SAMPLE_EDITING = "sample_editing"
    static GO_TO_SPECTATOR_MODE = "spectator_mode"
    static SECTION_EDITING = "section_editing"
    static SAVE_AND_RENDER = "save_and_render"
    static APPLY_EFFECTS = "apply_effects"
    static EXPORT_STEMS = "export_stems"
    static EXPORT_WAV = "export_wav"
    static DOWNLOAD_FOLDER = "download_folder"
    static DOWNLOAD_COMPOSITION = "download_composition"
    static RENAME = "rename"
    static DELETE_FOLDER = "delete_folder"
    static DELETE_TRACK = "delete_track"
    static DELETE_MULTIPLE = "delete_multiple"
    static VIEW_SUBSCRIPTION_PLANS = "view_subscription_plans"
    static SELECT_RADIO = "select_radio"
    static ADD_SIMILAR_INSTRUMENTS = "add_similar_instruments"

    constructor(type, meta) {
        this.time = Date.now()
        this.type = type
        this.meta = meta
    }
}

export function createNoteEditingMetric(
    layer: string,
    type: "add" | "delete" | "paste" | "modify",
    compositionID: string
) {
    return new ActivityMetric(ActivityMetric.NOTE_EDITING, {
        layer,
        operationType: type,
        compositionID,
    })
}

export function createInstrumentEditingMetric(
    layer: string,
    type: "add" | "update" | "duplicate" | "delete",
    compositionID: string
) {
    return new ActivityMetric(ActivityMetric.INSTRUMENT_EDITING, {
        layer,
        operationType: type,
        compositionID,
    })
}

export function createAutomationEditingMetric(
    layer: string,
    type: EffectType,
    compositionID: string
) {
    return new ActivityMetric(ActivityMetric.AUTOMATION_EDITING, {
        layer,
        automationType: type,
        compositionID,
    })
}

export function createBPMEditingMetric(compositionID: string, value:number) {
    return new ActivityMetric(ActivityMetric.BPM_EDITING, {
        compositionID,
        value
    })
}

export function createSectionEditingMetric(
    compositionID: string,
    type:SectionEditingType
) {
    return new ActivityMetric(ActivityMetric.SECTION_EDITING, {
        compositionID,
        type,
    })
}
