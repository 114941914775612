import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
} from "@angular/core"
import EffectPlugin from "@common-lib/classes/score/effectplugin"
import Controller from "@common-lib/classes/score/controller"
import TrackBus from "../../../../../../common-lib/general/classes/score/trackbus"
import Layer from "@common-lib/classes/score/layer"
import { Delay } from "@common-lib/classes/score/effect"
import { WindowService } from "@services/window.service"
@Component({
    selector: "dial",
    templateUrl: "dial.component.html",
    styleUrls: ["dial.component.scss"],
})
export class DialComponent implements OnInit {
    @Input() type: string
    @Input() title: string
    @Input() variableControlled: string
    @Input() value: number = 0
    @Input() minLabel: string
    @Input() maxLabel: string
    @Input() unitLeft: string
    @Input() unitRight: string
    @Input() min: number
    @Input() max: number
    @Input() class: string
    @Input() color: string
    @Input() turningSpeedModifier: number = 5
    @Input() trackBus: TrackBus
    @Input() layerObject: Layer
    @Input() grades = []
    @Input() avData: string

    @Output() valueOutput = new EventEmitter<{
        value: number
        type: "start" | "continue" | "end"
    }>()

    showTitle: boolean = true

    panningListenerMouseUp
    panningListenerMove
    pannedInstrument
    previousPan = null

    turningListenerMouseUp
    turningListenerMove
    turningInstrument
    previousTurn = null

    constructor(
        private ref: ChangeDetectorRef,
        private windowService: WindowService
    ) {}

    ngOnInit() {
        if (this.title == null || this.title == "") {
            this.showTitle = false
        }
    }

    getKnobValue() {
        var returnValue = ""
        this.setValue(this.value || 0)

        if (this.type == "pan") {
            if (this.value > 0) {
                returnValue = this.value * 100 + " " + this.unitRight
            }

            if (this.value < 0) {
                returnValue = this.value * -100 + " " + this.unitLeft
            }
        }

        if (this.type == "normal") {
            returnValue = this.value + " " + this.unitLeft
        }

        if (this.type == "gradual") {
            returnValue = this.grades[this.value].name
        }

        return returnValue
    }

    getOrientation(direction) {
        var value = this.value

        if (this.type == "pan") {
            let halfCirclePercentage = 35 // 35% of a circle is drawn in both directions to represent a full pan

            let correctForSmoothness = 0.0 // use decimal values here, so the circle will have smoothed edged
            let percentage =
                (halfCirclePercentage / 100) * value * 100 -
                correctForSmoothness
            let defaultValue = `conic-gradient(var(${this.color}) 0 0%, var(--not-filled) 1% ${halfCirclePercentage}%, transparent 1% 0)`

            // right pan
            if (percentage > 0) {
                if (direction == "right") {
                    return `conic-gradient(var(${this.color}) 0 ${
                        percentage - correctForSmoothness
                    }%, var(--not-filled) ${percentage}% ${halfCirclePercentage}%, transparent 1% 0)`
                }
            }

            // left pan
            if (percentage < 0) {
                percentage = Math.abs(percentage) - 1 // absolute value and visual correction

                if (direction == "left") {
                    return `conic-gradient(var(${this.color}) 0 ${
                        percentage - correctForSmoothness
                    }%, var(--not-filled) ${percentage}% ${
                        halfCirclePercentage - correctForSmoothness
                    }%, transparent 1% 0)`
                }
            }

            return defaultValue
        } else if (this.type == "normal" || this.type == "gradual") {
            let halfCirclePercentage = 35 // 35% of a circle is drawn in both directions to represent a full turn

            var range = (this.max - this.min) / 2
            var normalizedValue = value + Math.abs(this.min)

            let correctForSmoothness = 0.0 // use decimal values here, so the circle will have smoothed edged
            let percentage =
                (halfCirclePercentage / range) * (normalizedValue - range) -
                correctForSmoothness

            let defaultValueRight = `conic-gradient(var(${this.color}) 0 0%, var(--not-filled) 0% ${halfCirclePercentage}%, transparent 1% 0)`
            let defaultValueLeft = `conic-gradient(var(--not-filled) 0 0%, var(${this.color}) 0% ${halfCirclePercentage}%, transparent 1% 0)`

            if (percentage == 0) {
                if (direction == "left") {
                    return defaultValueLeft
                } else {
                    return defaultValueRight
                }
            }

            // right
            if (percentage > 0) {
                if (direction == "right") {
                    return `conic-gradient(var(${this.color}) 0 ${
                        percentage - correctForSmoothness
                    }%, var(--not-filled) ${percentage}% ${halfCirclePercentage}%, transparent 1% 0)`
                } else {
                    return defaultValueLeft
                }
            }

            // left
            if (percentage < 0) {
                percentage = Math.abs(percentage) // absolute value and visual correction by 2

                if (direction == "left") {
                    return `conic-gradient(var(--not-filled) 0 ${
                        percentage - correctForSmoothness
                    }%, var(${this.color}) ${percentage}% ${
                        halfCirclePercentage - correctForSmoothness
                    }%, transparent 1% 0)`
                } else {
                    return defaultValueRight
                }
            }
        }
    }

    resetToZero() {
        this.setValue(0)
        this.valueOutput.emit({
            value: 0,
            type: "end",
        })
    }

    getValue() {
        var value = this.value
        var result

        if (this.type == "pan") {
            result = "rotate(" + value / 2.9 + "turn)"
        } else if (this.type == "normal" || this.type == "gradual") {
            let maxDegree = 130

            var range = (this.max - this.min) / 2
            var normalizedValue = value + Math.abs(this.min)

            result =
                "rotate(" +
                (maxDegree / range) * (normalizedValue - range) +
                "deg)"
        }

        return result
    }

    startTurningOneDirectionalKnob(event) {
        this.previousTurn = {
            y: event.y,
            value: this.value,
        }

        if (this.turningListenerMove != null) {
            window.document.removeEventListener(
                "mousemove",
                this.turningListenerMove
            )
        }

        this.turningListenerMove =
            this.continueTurningOneDirectionalKnob.bind(this)

        window.document.addEventListener("mousemove", this.turningListenerMove)
    }

    continueTurningOneDirectionalKnob(event) {
        if ((event.buttons != null && event.buttons == 0) || event.which == 0) {
            this.stopTurningOneDirectionalKnob(event)
        }

        if (this.previousTurn != null) {
            this.setValue(this.calculateTurning(event))

            this.ref.detectChanges()
        }
    }

    setValue(value) {
        if (this.type == "gradual") {
            value = this.grades[Math.round(value)].index
        }

        this.value = value
    }

    stopTurningOneDirectionalKnob(event) {
        this.valueOutput.emit({
            value: this.value,
            type: "end",
        })

        this.previousTurn = null

        window.document.removeEventListener(
            "mousemove",
            this.turningListenerMove
        )
    }

    calculateTurning(event: MouseEvent) {
        let turningSpeedModifier = this.turningSpeedModifier

        if (event.shiftKey) {
            turningSpeedModifier = this.turningSpeedModifier * 4
        }

        var turning =
            (this.previousTurn.y - event.y) / turningSpeedModifier +
            this.previousTurn.value

        if (turning > this.max) {
            turning = this.max
        } else if (turning < this.min) {
            turning = this.min
        }

        return parseInt(turning)
    }

    startPanning(event) {
        this.previousPan = {
            y: event.y,
            panning: this.value,
        }

        if (this.panningListenerMove != null) {
            window.document.removeEventListener(
                "mousemove",
                this.panningListenerMove
            )
        }

        this.panningListenerMove = this.continuePanning.bind(this)

        window.document.addEventListener("mousemove", this.panningListenerMove)
    }

    continuePanning(event) {
        if ((event.buttons != null && event.buttons == 0) || event.which == 0) {
            this.stopPanning(event)
        }

        if (this.previousPan != null) {
            this.setValue(this.calculatePanning(event))

            this.valueOutput.emit({
                value: this.value,
                type: "continue",
            })

            this.ref.detectChanges()
        }
    }

    stopPanning(event) {
        this.valueOutput.emit({
            value: this.value,
            type: "end",
        })

        this.previousPan = null

        window.document.removeEventListener(
            "mousemove",
            this.panningListenerMove
        )
    }

    calculatePanning(event) {
        var panning =
            (this.previousPan.y - event.y) / 50 + this.previousPan.panning

        if (panning > 1) {
            panning = 1
        } else if (panning < -1) {
            panning = -1
        }

        return Math.floor(panning * 4) / 4
    }

    getConvertedInstrumentGainOffset(value) {
        let result = value || 0

        if (value < 0) {
            result = Math.round(value * 3)
        }

        return result
    }
}
