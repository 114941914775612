
export module Color {
    /**
	 * 
	 * @param hex hex color code (no short versions allowed! For example #fff won't work)
	 * @param alpha transparency level
	 * @returns rgba String
	 */
    export function hexToRGB(hex: string, alpha: string) {
        const r = parseInt(hex.slice(1, 3), 16)
        const g = parseInt(hex.slice(3, 5), 16)
        const b = parseInt(hex.slice(5, 7), 16)

        if (alpha) {
            return `rgba(${r}, ${g}, ${b}, ${alpha})`
        } 
        
        else {
            return `rgb(${r}, ${g}, ${b})`
        }
    }

    export function rgb2hex(rgb) {
        rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)

        function hex(x) {
            return ("0" + parseInt(x).toString(16)).slice(-2)
        }

        return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3])
    }

    export function isHexColor(colorString: string){
        if(/^#[0-9A-F]{6}$/i.test(colorString)){
            return true
        }

        return false
    }

    export function isRgbColor(colorString: string){
        let rgbMatch = colorString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
        
        if (rgbMatch && rgbMatch.length > 2){
            return true
        }

        return false
    }

    export function changeColorOpacity(colorString, opacity: string){
        if (this.isHexColor(colorString)){
            return this.hexToRGB(colorString, opacity)
        }

        if (this.isRgbColor(colorString)) {
        
            let rgbMatch = colorString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)

            return `rgba(${rgbMatch[1]}, ${rgbMatch[2]}, ${rgbMatch[3]}, ${opacity})`
        }
    }

    /**
     * Converts an HSL color value to RGB. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes h, s, and l are contained in the set [0, 1] and
     * returns r, g, and b in the set [0, 255].
     *
     * @param   {number}  h       The hue
     * @param   {number}  s       The saturation
     * @param   {number}  l       The lightness
     * @return  {Array}           The RGB representation
     */
    export function hslToRgb(h, s, l) {
        var r, g, b

        if(s == 0) {
            r = g = b = l // achromatic
        }
        
        else{
            var hue2rgb = function hue2rgb(p, q, t){
                if(t < 0) t += 1
                if(t > 1) t -= 1
                if(t < 1/6) return p + (q - p) * 6 * t
                if(t < 1/2) return q
                if(t < 2/3) return p + (q - p) * (2/3 - t) * 6

                return p
            }

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s
            var p = 2 * l - q
            r = hue2rgb(p, q, h + 1/3)
            g = hue2rgb(p, q, h)
            b = hue2rgb(p, q, h - 1/3)
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
    }	

    /**
     * Converts an RGB color value to HSL. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes r, g, and b are contained in the set [0, 255] and
     * returns h, s, and l in the set [0, 1].
     *
     * @param   {number}  r       The red color value
     * @param   {number}  g       The green color value
     * @param   {number}  b       The blue color value
     * @return  {Array}           The HSL representation
     */
    export function rgbToHsl(r, g, b) {
        r /= 255, g /= 255, b /= 255
        var max = Math.max(r, g, b), min = Math.min(r, g, b)
        var h, s, l = (max + min) / 2

        if (max == min){
            h = s = 0 // achromatic
        }
        
        else {
            var d = max - min
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

            switch(max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break
                case g: h = (b - r) / d + 2; break
                case b: h = (r - g) / d + 4; break
            }

            h /= 6
        }

        return [h, s, l]
    }
}