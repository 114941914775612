import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { PlayerService } from "./player/player.service"
import { ApiService } from "@services/api.service"

@Injectable()
export class PreviewsService {
    private gpPreviewsAudio = new Audio()

    public gpPreviews: BehaviorSubject<any> = new BehaviorSubject<any>({
        play: "",
        loading: false,
        id: "",
    })

    constructor(private apiService: ApiService) {}

    public async loadGPPreview(id, type: "category" | "gp"): Promise<any> {
        var onlyPause = false
        let preview = this.gpPreviews.getValue()

        if (preview.id == id && preview.id != "paused") {
            onlyPause = true
        }

        this.pausePreviews()

        if (onlyPause) {
            this.gpPreviews.next({
                type: "paused",
                id: "",
            })

            return Promise.resolve()
        }

        this.gpPreviews.next({
            type: "loading",
            id: id,
        })

        return this.apiService
            .authRequest(
                "/composition/play/gpPreview",
                { id: id, type: type },
                "primary",
                true
            )
            .then(res => {
                this.gpPreviewsAudio.src = res.link

                console.log(res.link)

                this.gpPreviewsAudio.onloadeddata = async () => {
                    await this.gpPreviewsAudio.play()

                    this.gpPreviews.next({
                        type: "playing",
                        id: id,
                    })
                }

                this.gpPreviewsAudio.onended = () => {
                    this.gpPreviews.next({
                        type: "paused",
                        id: "",
                    })
                }

                return Promise.resolve()
            })

            .catch(err => {
                console.error(err)
                console.error(
                    "Could not load preset preview. Please try refreshing the page."
                )

                return Promise.resolve()
            })
    }

    public pausePreviews() {
        if (this.gpPreviewsAudio != null) {
            this.gpPreviewsAudio.pause()
            this.gpPreviews.next({
                type: "paused",
                id: this.gpPreviews.getValue().id,
            })
        }
    }
}
