import Layer from './layer'
import PercussionLayer from './percussionlayer'

export default class EditingState {
    static ADD = "add"
    static DELETE = "delete"
    static UPDATE = "update"
    static DUPLICATE = "duplicate"

    static TYPE_NOTE = "note"
    static TYPE_DYNAMIC = "dynamic"
    static TYPE_LCFILTER = "low_frequency_cut"
    static TYPE_HCFILTER = "high_frequency_cut"
    static TYPE_DELAY = "delay"
    static TYPE_REVERB = "reverb"
    static TYPE_EFFECTS = "effects"
    static TYPE_SECTION = "section"
    static TYPE_TEMPO = "tempo"
    static TYPE_LAYER = "layer"
    static TYPE_SECTION_INSERT = "section_insert"
    static TYPE_ACCOMPANIMENT_PACK_SCORE = "accompaniment_pack_and_score"

    valuesBeforeEditing
    valuesAfterEditing
    type 
    operation // add, delete, update
    layer: Layer | PercussionLayer

    constructor(valuesBeforeEditing, valuesAfterEditing, type, operation, layer) {
        this.valuesBeforeEditing = valuesBeforeEditing
        this.valuesAfterEditing = valuesAfterEditing
        this.type = type
        this.operation = operation

        if (layer != null) {
            if (layer.type == "percussion"){
                this.layer = layer as PercussionLayer
            }

            else {
                this.layer = layer as Layer
            }
        }
    }

    typeIsAutomation() {
        return this.type == EditingState.TYPE_DYNAMIC || 
               this.type == EditingState.TYPE_LCFILTER || 
               this.type == EditingState.TYPE_HCFILTER ||
               this.type == EditingState.TYPE_DELAY ||
               this.type == EditingState.TYPE_REVERB
    }

    invert() {
        if (this.operation == EditingState.ADD || this.operation == EditingState.DUPLICATE) {
            this.operation = EditingState.DELETE
            this.valuesBeforeEditing = this.valuesAfterEditing
            this.valuesAfterEditing = []
        }

        else if (this.operation == EditingState.DELETE) {
            this.operation = EditingState.ADD
            this.valuesAfterEditing = this.valuesBeforeEditing
            this.valuesBeforeEditing = []
        }

        else if (this.operation == EditingState.UPDATE) {
            var temp = this.valuesBeforeEditing

            this.valuesBeforeEditing = this.valuesAfterEditing
            this.valuesAfterEditing = temp
        }
    }

}