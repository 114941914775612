import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
} from "@angular/core"
import { ApiService } from "@services/api.service"
import { ModalService } from "@services/modal.service"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { FolderService } from "@services/folder.service"
import { environment } from "@environments/environment"
import { Misc } from "@common-lib/modules/misc"
import { DesignService } from "@services/design.service"
import { GeneralService } from "@services/general/general.service"
import { Router } from "@angular/router"
import { InfluenceService } from "@services/influence.service"
import { CreateService } from "@services/create.service"

@Component({
    selector: "gp-library",
    templateUrl: "gp-library.component.html",
    styleUrls: ["./gp-library.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GPLibraryComponent {
    selectedGP = null

    searchTimeout

    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    addingToMyLibrary = false

    listItemMainButton = {
        text: "Create",
        pathToIcon: "assets/img/add.svg",
    }

    get itemType() {
        return this.showForkedProfilesFor != null ? "gp" : "category"
    }

    constructor(
        private createService: CreateService,
        private router: Router,
        private generalService: GeneralService,
        private designService: DesignService,
        private folderService: FolderService,
        private apiService: ApiService,
        private ref: ChangeDetectorRef,
        private modalService: ModalService,
        private gpService: GenerationProfileService
    ) {}

    ngOnInit() {
        this.gpService.refreshGPLibraryUI.subscribe(async value => {
            this.createService.getLibraryGPs()

            this.ref.detectChanges()
        })
    }

    createFromGP(data) {
        if (this.itemType == "category") {
            this.gpService.openCompositionCreationModal({
                gp: null,
                redirectTo: "home",
                sourceGPFolder: null,
                sourceGPCategory: data._id,
            })
        } else {
            this.gpService.openCompositionCreationModal({
                gp: data,
                redirectTo: "home",
                sourceGPFolder: null,
                sourceGPCategory: null,
            })
        }
    }

    set search(value) {
        this.createService.gpStates.search = value
    }

    /* 
        Getters
    */
    get showNewAIVAProfiles() {
        return this.createService.gpStates.showNewAIVAProfiles
    }

    get search() {
        return this.createService.gpStates.search
    }

    get forkFilters() {
        return this.createService.gpStates.forkFilters
    }

    get filters() {
        return this.createService.gpStates.filters
    }

    get showForkedProfilesFor() {
        return this.createService.gpStates.showForkedProfilesFor
    }

    get gps() {
        return this.createService.gpStates.gps
    }

    get sortCategory() {
        return this.createService.gpStates.sortCategory
    }

    get forkSortCategory() {
        return this.createService.gpStates.forkSortCategory
    }

    get forkSortOptions() {
        return this.createService.gpStates.forkSortOptions
    }

    get sortOptions() {
        return this.createService.gpStates.sortOptions
    }

    /* 
        Methods
    */
    getSortCategory() {
        for (let category of this.sortOptions) {
            if (category.value === this.sortCategory) {
                return category
            }
        }

        return this.sortOptions[0]
    }

    getNumberOfSaves(gp) {
        if (gp.numberOfSaves == null) {
            return 0
        }

        if (gp.numberOfSaves > 999) {
            return Misc.roundDecimal(gp.numberOfSaves / 1000, 1) + "k"
        }

        return gp.numberOfSaves
    }

    isProduction() {
        return environment.production
    }

    isAIVAAccount() {
        return this.apiService.user.getValue().split("@")[1] == "aiva.ai"
    }

    validateUserProfile() {
        let selectedGP = this.selectedGP.gp

        this.unselectMenu()

        return this.apiService
            .authRequest(
                "/generationprofile/validateUserProfile",
                { categoryID: selectedGP._id },
                "primary",
                true
            )
            .then(res => {
                this.gpService.refreshGPLibraryUI.next(true)
            })

            .catch(err => {
                return this.apiService.handleError(err)
            })
    }

    editProfile() {
        let id =
            this.selectedGP.gp.revisions[
                this.selectedGP.gp.revisions.length - 1
            ].gpIDs[0]

        this.router.navigate(["/generation-profile-editor", id])

        this.unselectMenu()
    }

    trackByGP(index, item) {
        return item._id
    }

    renameProfile() {
        this.modalService.modals.renameProfileCategory.next(this.selectedGP.gp)

        this.unselectMenu()
    }

    unselectMenu() {
        this.selectedGP = null
        this.ref.detectChanges()
    }

    deleteFilter(filter, f, type, event) {
        event.stopPropagation()

        this.createService.deleteFilter(filter, f, type)
    }

    isAdmin() {
        return this.apiService.isAdmin()
    }

    isGPModerator() {
        return this.apiService.isGPModerator()
    }

    openFiltersModal() {
        if (this.showForkedProfilesFor == null) {
            this.modalService.modals.gpLibraryFilters.next(
                this.createService.gpStates.filters
            )
        } else {
            this.modalService.modals.gpLibraryFilters.next(
                this.createService.gpStates.forkFilters
            )
        }
    }

    clearSearch() {
        this.createService.clearSearch()

        this.detectChanges()
    }

    initiateSearch(search: string) {
        this.search = search

        if (this.searchTimeout != null) {
            clearTimeout(this.searchTimeout)
            this.searchTimeout = null
        }

        this.searchTimeout = setTimeout(() => {
            this.createService.resetFilters()
            this.createService.setCurrentPage(1)
        }, 250)
    }

    resetForkedProfiles() {
        this.createService.resetForkedProfiles()
    }

    showNewestGPsFromAIVA() {
        this.createService.showNewestGPsFromAIVA()

        this.ref.detectChanges()
    }

    selectSortCategory(event) {
        this.createService.selectSortCategory(event.new.value)
    }

    rejectUserProfile() {
        let selectedGP = this.selectedGP.gp

        this.unselectMenu()

        return this.apiService
            .authRequest(
                "/generationprofile/rejectUserProfile",
                { categoryID: selectedGP._id },
                "primary",
                false
            )
            .then(res => {
                this.gpService.refreshGPLibraryUI.next(true)
            })

            .catch(err => {
                return this.apiService.handleError(err)
            })
    }

    async openGPOptions(data) {
        let event = data.event
        let gp = data.gp

        event.stopPropagation()

        let x = event.x + 10
        let y = event.y + 10

        this.selectedGP = {
            x: x,
            y: y,
            gp: gp,
        }

        await Misc.wait(0.01)

        let computedStyle = window.getComputedStyle(
            document.querySelector(".gp-hub-more-options")
        )

        let width = parseInt(
            computedStyle.getPropertyValue("width").replace("px", "")
        )
        let height = parseInt(
            computedStyle.getPropertyValue("height").replace("px", "")
        )
        let playerBarHeight = parseInt(
            this.designService
                .getCSSProperty("player-bar-height")
                .replace("px", "")
        )

        if (x + width > window.innerWidth) {
            x = x - width
        }

        if (y + height > window.innerHeight - playerBarHeight) {
            y = y - height
        }

        this.selectedGP = {
            x: x,
            y: y,
            gp: gp,
        }

        this.detectChanges()
    }

    detectChanges() {
        this.ref.detectChanges()
    }

    getFiltersList() {
        if (this.showForkedProfilesFor == null) {
            let filters = []

            for (let f = 0; f < this.filters.styles.length; f++) {
                filters.push({
                    name: this.filters.styles[f],
                    type: "styles",
                    index: f,
                })
            }

            for (let f = 0; f < this.filters.createdBy.length; f++) {
                filters.push({
                    name: this.filters.createdBy[f],
                    type: "createdBy",
                    index: f,
                })
            }

            return filters
        } else if (
            this.forkFilters.similarity.min != 0 ||
            this.forkFilters.similarity.max < 99
        ) {
            return [
                {
                    name:
                        "Similarity: " +
                        this.forkFilters.similarity.min +
                        "% - " +
                        this.forkFilters.similarity.max +
                        "%",
                    type: "similarity",
                },
            ]
        }

        return []
    }

    deviceSpecificClass() {
        return this.generalService.getDeviceSpecificClass()
    }
}
