import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import { LayerFunctionType } from "@common-lib/types/score"

import { AddCustomLayerModal } from "@services/modal.service"

@Component({
    selector: "add-custom-layer",
    templateUrl: "add-custom-layer.component.html",
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCustomLayerComponent implements OnInit {
    public isLoading = false

    @Input() addCustomLayer:AddCustomLayerModal
    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor() {}

    ngOnInit(): void {}

    closeModal() {
        this.close.emit()
    }

    async addLayer(layerType:LayerFunctionType) {
        this.isLoading = true

        await this.addCustomLayer.onComplete(layerType)

        this.isLoading = false
    }
}
