import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import { DeviceInfo } from "@common-lib/interfaces/api/desktop-app.api"
import { ApiService } from "@services/api.service"
import {
    ModalService,
    SetKeySignatureModalInput,
} from "@services/modal.service"
import { UserService } from "@services/user.service"
import { WindowService } from "@services/window.service"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../../types/dropdownItemType"
import { AudioService } from "@services/audio/audio.service"
import { KeySignatureModule } from "@common-lib/modules/keysignature.module"
import { Misc } from "@common-lib/modules/misc"
import { HARMONY_MODES } from "@common-lib/constants/constants"
import { cloneDeep } from "lodash"
import { SHORTENED_SCALE_TO_SCALE_MAP } from "@common-lib/utils/composition-workflow.util"

@Component({
    selector: "set-key-signature",
    templateUrl: "set-key-signature.modal.html",
    styleUrls: ["./set-key-signature.modal.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetKeySignatureModalComponent implements OnInit {
    @Input() input: SetKeySignatureModalInput
    @Output() close: EventEmitter<any> = new EventEmitter()

    public pitchClass: DropdownItemType<string> = {
        name: "C",
        value: "C",
    }

    public keyMode: DropdownItemType<string> = {
        name: "maj",
        value: "maj",
    }

    constructor() {}

    ngOnInit(): void {
        const ks = KeySignatureModule.convertMERepresentationToKeySignature(
            this.input.keySignature
        )

        this.pitchClass = {
            name: ks.pitchClass,
            value: ks.pitchClass,
        }

        this.keyMode = {
            name: ks.keyMode,
            value: ks.keyMode,
        }
    }

    public confirm() {
        this.input.onComplete(
            this.pitchClass.value +
                " " +
                KeySignatureModule.convertKeyModeToMERepresentation(
                    this.keyMode.value
                )
        )

        this.close.emit()
    }

    getKeyModeOptions() {
        let options = cloneDeep(Misc.getKeyModeOptions("Functional"))
        options = options.concat(Misc.getKeyModeOptions("Modal"))

        return options.map(mode => {
            return {
                value: SHORTENED_SCALE_TO_SCALE_MAP[mode],
                name: mode,
            }
        })
    }

    selectPitchClass(selection: DropdownSelection<string>) {
        this.pitchClass = selection.new
    }

    selectKeyMode(selection: DropdownSelection<string>) {
        this.keyMode = selection.new
    }

    getPitchClassOptions() {
        const mode = this.keyMode.value.toLowerCase()
        const strategy = HARMONY_MODES.Functional.includes(mode)
            ? "Functional"
            : "Modal"
        const options = Misc.getPitchClassOptions(mode, strategy)

        return options.map(pitch => {
            return {
                value: pitch,
                name: pitch,
            }
        })
    }
}
