import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    ViewChild,
} from "@angular/core"
import { ParentClass } from "../../../parent"
import { NOTE_RESOLUTIONS, PATTERN_LENGTHS } from "../../../constants"
import PercussionLayer from "@common-lib/classes/score/percussionlayer"
import Layer from "@common-lib/classes/score/layer"
import { ScoreUpdateType } from "../../../../../../common-lib/client-only/score-rendering-engine"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../../types/dropdownItemType"
import { MenuOptions } from "../../reusable/menu-options/menu-options.component"
import { Pattern } from "@common-lib/classes/score/pattern"
import Channel from "@common-lib/classes/score/channel"
import { ModalService } from "@services/modal.service"
import TrackBus from "@common-lib/classes/score/trackbus"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { BarCount, NoteResolution } from "@common-lib/types/score"
import { isEqual } from "lodash"
import { InitCanvases } from "../../../modules/init-canvases.module"
import { SubscriptionHelpers } from "@common-lib/modules/subscription-helpers.module"
import { Misc } from "@common-lib/modules/misc"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import { Helpers } from "../../../modules/helpers.module"
import { DropdownComponent } from "../../reusable/dropdown/dropdown.component"
import { ScoreManipulation } from "@common-lib/modules/scoremanipulation"
import { ConfirmDiscardChangesService } from "@services/confirm-discard-changes.service"

@Component({
    selector: "sequencer",
    templateUrl: "sequencer.component.html",
    styleUrls: ["sequencer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequencerComponent extends ParentClass {
    @Input() engine: ScoreRenderingEngine
    @Input() showPatternSelectionBar: boolean = true
    @Input() allowTimestepClick: boolean = false
    @Input() isCW: boolean = false

    @ViewChild("noteResolutionDropdown")
    noteResolutionDropdown: DropdownComponent

    public hiddenTrackBuses: TrackBus[] = []
    public patternSettings: undefined | MenuOptions<Pattern>
    public channelSettings: undefined | MenuOptions<Channel>

    constructor(
        public ref: ChangeDetectorRef,
        private modalService: ModalService,
        private instruments: InstrumentsService,
        private readonly confirmDiscard: ConfirmDiscardChangesService
    ) {
        super()
    }

    async ngAfterViewInit() {
        SubscriptionHelpers.subscribeWithPrevious(
            this.engine.toggledLayer$,
            this.initialiseData.bind(this),
            this.destroy$
        )

        this.subscribe(
            this.engine.scoreUpdate$,
            (update: ScoreUpdateType[] | undefined) => {
                if (update === undefined) {
                    return
                }

                if (isEqual(update, ["TrackBus"])) {
                    this.ref.detectChanges()
                } else if (
                    update.includes("All") ||
                    update.includes("Pattern")
                ) {
                    this.initialiseData({
                        previous: undefined,
                        current: this.engine.toggledLayer,
                    })
                }
            }
        )

        this.setPatternResolution()
    }

    async ngOnDestroy() {
        super.ngOnDestroy()
    }

    get noteResolutionOptions(): DropdownItemType<NoteResolution>[] {
        const noteResOptions = NOTE_RESOLUTIONS.filter(
            option =>
                Number(option.value.split("/")[1]) %
                    this.engine.score.timeSignatures[0][1][1] ===
                0
        )

        return noteResOptions
    }

    protected openChannelSettings(
        channel: Channel,
        event: MouseEvent,
        clickOutside: boolean
    ) {
        if (clickOutside === true || this.channelSettings !== undefined) {
            this.patternSettings = undefined
            this.channelSettings = undefined

            return
        }

        this.channelSettings = {
            options: [
                {
                    data: channel,
                    icon: "assets/img/menu/delete.svg",
                    text: "Delete " + channel.name,
                    buttonClass: "delete",
                    loading: false,
                    onClick: this.deleteChannel.bind(this),
                },
            ],
            coordinates: {
                x: event.x,
                y: event.y,
            },
        }
    }

    protected toggleTrackBus(tb: TrackBus, hide?: boolean) {
        const index = this.hiddenTrackBuses.findIndex(t => t.id === tb.id)

        if (index !== -1) {
            if (hide === undefined || hide === false) {
                this.hiddenTrackBuses.splice(index, 1)

                this.initialiseData({
                    previous: undefined,
                    current: this.engine.toggledLayer,
                })
            }
        } else {
            if (hide === undefined || hide === true) {
                this.hiddenTrackBuses.push(tb)
            }
        }
    }

    protected muteChannel(channel: Channel) {
        this.engine.toggleChannelPlayback(
            <PercussionLayer>this.engine.toggledLayer,
            this.engine.selectedPattern,
            channel,
            "mute"
        )
    }

    protected soloChannel(channel: Channel) {
        this.engine.toggleChannelPlayback(
            <PercussionLayer>this.engine.toggledLayer,
            this.engine.selectedPattern,
            channel,
            "solo"
        )

        if (this.isCW) {
            const layer = <PercussionLayer>this.engine.toggledLayer
            const [trackbus] = layer.trackBuses
            const channels = this.engine.selectedPattern.channels

            if (!trackbus.solo && !!channels.find(c => c.solo)) {
                this.engine.toggleTrackBussesMute(
                    this.engine.toggledLayer,
                    this.engine.toggledLayer.trackBuses,
                    "solo"
                )
            }

            if (trackbus.solo && !channels.find(c => c.solo)) {
                this.engine.toggleTrackBussesMute(
                    this.engine.toggledLayer,
                    this.engine.toggledLayer.trackBuses,
                    "solo"
                )
            }
        }
    }

    protected openPatternSettings(event: MouseEvent, clickOutside: boolean) {
        if (clickOutside === true || this.patternSettings !== undefined) {
            this.channelSettings = undefined
            this.patternSettings = undefined

            return
        }

        this.patternSettings = {
            options: [
                {
                    data: this.engine.selectedPattern,
                    icon: "assets/img/add.svg",
                    text: "Add Pattern",
                    loading: false,
                    onClick: this.addPattern.bind(this),
                },

                {
                    data: this.engine.selectedPattern,
                    icon: "assets/img/menu/duplicate.svg",
                    text: "Duplicate Pattern",
                    loading: false,
                    onClick: this.duplicatePattern.bind(this),
                },

                {
                    data: this.engine.selectedPattern,
                    icon: "assets/img/menu/rename.svg",
                    text: "Rename Pattern",
                    loading: false,
                    onClick: this.renamePattern.bind(this),
                },

                {
                    data: this.engine.selectedPattern,
                    icon: "assets/img/menu/delete.svg",
                    text: "Delete Pattern",
                    loading: false,
                    buttonClass: "delete",
                    onClick: this.deletePattern.bind(this),
                },
            ],
            coordinates: {
                x: event.clientX,
                y: event.clientY,
            },
        }
    }

    private deleteChannel(channel: Channel) {
        this.engine.deleteChannel(
            <PercussionLayer>this.engine.toggledLayer,
            this.engine.selectedPattern,
            channel.id
        )

        this.channelSettings = undefined

        this.ref.detectChanges()
    }

    private renamePattern(pattern: Pattern) {
        const renameModal = {
            name: pattern.name,
            onComplete: ((newName: string) => {
                this.engine.renamePattern(
                    <PercussionLayer>this.engine.toggledLayer,
                    pattern,
                    newName
                )
            }).bind(this),
        }

        this.modalService.modals.rename.next(renameModal)

        this.patternSettings = undefined
    }

    private deletePattern(pattern) {
        this.engine.deletePattern(
            <PercussionLayer>this.engine.toggledLayer,
            pattern
        )

        this.patternSettings = undefined
    }

    private duplicatePattern(pattern: Pattern) {
        this.engine.duplicatePattern(
            <PercussionLayer>this.engine.toggledLayer,
            pattern
        )

        this.patternSettings = undefined
    }

    private addPattern(pattern) {
        this.engine.addPattern(<PercussionLayer>this.engine.toggledLayer)

        this.patternSettings = undefined
    }

    protected getNoteRes(): DropdownItemType<string> {
        return Helpers.getSelectedPatternNoteRes(
            this.engine,
            this.getNoteResOptions()
        )
    }

    protected getNumberOfBars(): DropdownItemType<BarCount> {
        return {
            value: this.engine.selectedPattern?.bars,
            name: this.engine.selectedPattern?.bars + " bars",
        }
    }

    protected getNumberOfBarsOptions(): DropdownItemType<number>[] {
        return PATTERN_LENGTHS
    }

    protected getNoteResOptions() {
        return NOTE_RESOLUTIONS
    }

    protected getSelectedPattern(): DropdownItemType<number> {
        return {
            name: this.engine.selectedPattern?.name,
            value: this.engine.selectedPattern?.id,
        }
    }

    protected getAllPatterns(): DropdownItemType<number>[] {
        return (this.engine.toggledLayer as PercussionLayer).patterns.map(
            pr => ({
                name: pr.name,
                value: pr.id,
            })
        )
    }

    protected addChannel(event: MouseEvent, tb: TrackBus) {
        event.stopImmediatePropagation()

        this.toggleTrackBus(tb, false)

        this.engine.addChannel(
            <PercussionLayer>this.engine.toggledLayer,
            this.engine.selectedPattern,
            tb
        )
    }

    private async initialiseData(args: {
        previous: Layer | PercussionLayer | undefined
        current: Layer | PercussionLayer | undefined
    }) {
        if (
            args.current?.type === "percussion" &&
            (<PercussionLayer>args.current).patternRegions.length > 0
        ) {
            if (this.engine.selectedPattern === undefined) {
                const pattern = (<PercussionLayer>args.current)
                    .patternRegions[0].pattern

                this.engine.setSelectedPattern(pattern)

                this.setPatternResolution()
            }

            if (args.previous?.value !== args.current.value) {
                await this.clearCanvases()

                for (const tb of args.current.trackBuses) {
                    await InitCanvases.initDrumSequencerCanvas(tb, this.engine)
                }

                await InitCanvases.initPatternHorizontalScrollbarCanvas(
                    this.engine,
                    this.allowTimestepClick
                )
            }
        } else {
            await this.clearCanvases()
        }
    }

    private setPatternResolution() {
        if (this.engine.selectedPattern === undefined) {
            return
        }

        const pattern = this.engine.selectedPattern
        const resolution = ScoreManipulation.calculatePatternResolution(pattern)
        pattern.resolution = resolution

        ScoreManipulation.removePatternNotesWithHigherResolution(
            pattern,
            resolution
        )

        this.engine.setPatternResolution(
            <PercussionLayer>this.engine.toggledLayer,
            pattern,
            resolution
        )
    }

    private async clearCanvases() {
        this.engine.deleteCanvas("PatternHorizontalScrollbarCanvas")
        this.engine.deleteCanvas("DrumSequencerCanvas")

        this.ref.detectChanges()

        await Misc.wait(0.001)
    }

    protected selectChannel(
        channel: Channel,
        event: DropdownSelection<string>
    ) {
        this.engine.setPatternChannel(
            <PercussionLayer>this.engine.toggledLayer,
            this.engine.selectedPattern,
            channel.id,
            event.new.value,
            this.instruments.pitchToChannelMapping
        )
    }

    protected getChannelOptions(tb: TrackBus): DropdownItemType<string>[] {
        const channels = []

        if (this.instruments.drumSamples[tb.reference.name] !== undefined) {
            for (const channel of this.instruments.drumSamples[
                tb.reference.name
            ]) {
                if (
                    channels.find(
                        c =>
                            c.value ===
                            this.instruments.pitchToChannelMapping[channel]
                    ) !== undefined
                ) {
                    continue
                }

                if (
                    this.instruments.pitchToChannelMapping[channel] ===
                    undefined
                ) {
                    continue
                }

                channels.push({
                    name: this.instruments.pitchToChannelMapping[channel],
                    value: this.instruments.pitchToChannelMapping[channel],
                })
            }
        }

        channels.push({
            name: "Unassigned",
            value: "Unassigned",
        })

        return channels
    }

    protected getChannelName(channel: Channel, tb: TrackBus) {
        const options = this.getChannelOptions(tb)

        if (options.find(o => o.name === channel.name) === undefined) {
            return {
                name: "Unassigned",
                value: channel.name,
            }
        }

        return {
            name: channel.name,
            value: channel.id,
        }
    }

    protected selectPattern(event: DropdownSelection<number>) {
        this.engine.setSelectedPattern(
            (<PercussionLayer>this.engine.toggledLayer).patterns.find(
                pr => pr.id === event.new.value
            )
        )
    }

    protected selectNumberOfBars(event: DropdownSelection<BarCount>) {
        this.engine.setPatternBars(
            <PercussionLayer>this.engine.toggledLayer,
            this.engine.selectedPattern,
            event.new.value
        )
    }

    protected async selectResolution(event: DropdownSelection<string>) {
        const pattern = this.engine.selectedPattern
        const resolution = event.new.value
        const oldResolution = event.current.value
        if (
            ScoreManipulation.checkIfPatternResolutionWillRemoveNotes(
                pattern,
                resolution
            )
        ) {
            const result = await new Promise(resolve => {
                const title = "Warning"
                const description = "Changing the resolution will remove notes"
                this.confirmDiscard.openDoActionsBeforeModal({
                    title,
                    description,
                    continueButtonText: "Continue",
                    action: async () => {
                        ScoreManipulation.removePatternNotesWithHigherResolution(
                            pattern,
                            resolution
                        )
                        this.engine.setPatternResolution(
                            <PercussionLayer>this.engine.toggledLayer,
                            pattern,
                            resolution
                        )
                        resolve(true)
                        return { success: true }
                    },
                    cancel: () => {
                        resolve(false)
                    },
                })
            })

            if (!result) {
                this.engine.setPatternResolution(
                    <PercussionLayer>this.engine.toggledLayer,
                    pattern,
                    oldResolution
                )

                const item = NOTE_RESOLUTIONS.find(
                    item => item.value === oldResolution
                )

                this.noteResolutionDropdown.selectItem({
                    target: {
                        value: JSON.stringify(item),
                    },
                })
            } else {
                this.engine.setPatternResolution(
                    <PercussionLayer>this.engine.toggledLayer,
                    pattern,
                    resolution
                )

                const item = NOTE_RESOLUTIONS.find(
                    item => item.value === resolution
                )

                this.noteResolutionDropdown.selectItem({
                    target: {
                        value: JSON.stringify(item),
                    },
                })
            }

            return
        }

        this.engine.setPatternResolution(
            <PercussionLayer>this.engine.toggledLayer,
            pattern,
            resolution
        )
    }
}
