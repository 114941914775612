import { Injectable } from "@angular/core"
import { driver, Alignment, AllowedButtons, PopoverDOM } from "driver.js"
import { ApiService } from "./api.service"
import { UserService } from "./user.service"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { BehaviorSubject, filter, take, throttleTime } from "rxjs"
import { DeviceDetectorService } from "ngx-device-detector"
import { FolderService } from "./folder.service"
import { cloneDeep } from "lodash"
@Injectable({
    providedIn: "root",
})
export class TutorialService {
    public initTutorial$ = new BehaviorSubject<boolean>(false)
    public tutorialNext$ = new BehaviorSubject<boolean>(false)
    public collapseNavigation$ = new BehaviorSubject<boolean>(false)

    public isTutorialInProgress$ = new BehaviorSubject<
        | "home"
        | "step1"
        | "step2part1"
        | "step2part2"
        | "step3"
        | "create"
        | undefined
    >(undefined)

    private readonly chordProgressionTutorialSteps = [
        {
            element: "[av-data='cw-step1-component']",
            popover: {
                title: "Step 1: Create your chord progression",
                description:
                    "Here you can add, remove and edit the chords \n that will be used by AIVA to create a composition.",
                type: "top",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='prompt-wrapper']",
            popover: {
                title: "Text to harmony",
                description:
                    "Provide a text description, and watch the magic unfold as AIVA generates a chord progression based on that!<br><br><i>Some examples:</i><br><br>" +
                    "<li>A progression in the style of Beethoven's Moonlight Sonata</li><br>" +
                    "<li>Create a sad and slow pop progression</li>",
                type: "top",
            },
        },
        {
            element: "[av-data='chords-editing-canvas']",
            popover: {
                title: "Editing Chords",
                description:
                    "<li>Hover a chord to see editing options</li><br>" +
                    "<li>Click on edit button to edit the chord</li><br>" +
                    "<li>Drag from the edges to resize the chord</li><br>" +
                    "<li>Click on '+' to add a chord in between existing ones</li>",
                type: "top",
                align: "end" as Alignment,
            },
        },
        {
            element: "[av-data='settingsbtn']",
            popover: {
                title: "Settings",
                description:
                    "To access other settings of your composition, like tempo or time signature, click here.",
                type: "top",
            },
        },
        {
            element: "[av-data='zoom-slider']",
            popover: {
                title: "Zoom",
                description:
                    "Use this slider to adjust your progression's zoom level.",
                type: "bottom",
            },
        },
    ]

    private readonly cwStep2Part1TutorialSteps = [
        {
            element: "[av-data='cw-step2-component']",
            popover: {
                title: "Step 2: Customize your composition, layer by layer",
                description:
                    "Modify or regenerate the layers that will be used as your composition's musical starting point.",
                type: "top",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='settingsbtn']",
            popover: {
                title: "Settings",
                description: "To change the tempo or the style click here.",
                type: "top",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='cw-step2-zoom']",
            popover: {
                title: "Zoom",
                description: "You can adjust the zoom with this slider.",
                type: "top",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='cw-step2-chord-progression']",
            popover: {
                title: "View your chord progression",
                description:
                    "This is the chord progression you created in the previous step.\nNote: you cannot modify the chord progression here.",
                type: "top",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='0-edit-btn']",
            popover: {
                title: "Edit",
                description: "Click here to edit the notes in a layer.",
                type: "top",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='0-regenerate-btn']",
            popover: {
                title: "Regenerate",
                description:
                    "Click here to regenerate the track. Note that your modifications will be lost everytime you regenerate.",
                type: "top",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='0-mute-solo']",
            popover: {
                title: "Mute & Solo",
                description:
                    "Click on 'S' if you want to only playback one layer, and 'M' if you want to mute a layer.",
                type: "top",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='0-instrument-name']",
            popover: {
                title: "Instrument",
                description: "Click here to choose a different instrument.",
                type: "top",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='0-volume-meter']",
            popover: {
                title: "Volume",
                description: "Adjust the volume of the layer with this slider.",
                type: "top",
                align: "center" as Alignment,
            },
        },
    ]

    private readonly cwStep2Part2TutorialSteps = [
        {
            element: "#non-existing-item",
            popover: {
                title: "Editor",
                description:
                    "The editor is a powerful tool that allows you to modify the notes AIVA generated in a layer.<br><br>" +
                    "Notes in the editor will be retricted to fit on the chord progression entered in Step 1 of the composition workflow (except for Melody and Percussion layers).",
                type: "top",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='cw-step2-p2-pointer-type']",
            popover: {
                title: "Pointer type",
                description:
                    "You can switch between Selection and Drawing tools here.",
                type: "top",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='cw-step2-p2-editor']",
            popover: {
                title: "Editor",
                description:
                    "This is the editor area where you can draw or select notes with your mouse.",
                type: "top",
                align: "center" as Alignment,
            },
        },
    ]

    private readonly cwStep3TutorialSteps1 = [
        {
            element: "[av-data='cw-step3']",
            popover: {
                title: "Final touches",
                description:
                    "This is the final step for generating your composition.",
                type: "bottom",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='cw-step3-name']",
            popover: {
                title: "Workflow Name",
                description:
                    "You can give a name to your workflow here. This will later be useful if you want to generate more compositions from the same workflow.",
                type: "top",
            },
        },
        {
            element: "[av-data='cw-step3-duration']",
            popover: {
                title: "Duration",
                description:
                    "Select the duration of your composition with this input.",
                type: "top",
            },
        },
        {
            element: "[av-data='cw-step3-composition-count']",
            popover: {
                title: "Number of compositions",
                description:
                    "Specify how many compositions you want to be generated by Aiva with this input.",
                type: "top",
            },
        },
        {
            element: "[av-data='cw-step3-instruments-change']",
            popover: {
                title: "Changing the instruments",
                description:
                    "By toggling this input, you can allow Aiva to change the instruments of your composition within the boundaries of the selected style.",
                type: "top",
            },
        },
        {
            element: "[av-data='cw-step3-save']",
            popover: {
                title: "Save workflow",
                description:
                    "This button will save your workflow to your account, which will help you to recreate compositions or workflows similar to this one.",
                type: "top",
            },
        },
    ]
    private readonly cwStep3TutorialSteps2 = [
        {
            element: "[av-data='cw-step3']",
            popover: {
                title: "Final touches",
                description:
                    "This is the final step for generating your composition. " +
                    "Once completed, AIVA will generate a new composition based on your selections in previous steps.",
                type: "bottom",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='cw-step3-name']",
            popover: {
                title: "Enter your workflow's name",
                description:
                    "This will be useful later if you want to generate more compositions from the same workflow.",
                type: "top",
            },
        },
        {
            element: "[av-data='cw-step3-duration']",
            popover: {
                title: "Duration",
                description: "Select the duration of your composition.",
                type: "top",
            },
        },
        {
            element: "[av-data='cw-step3-composition-count']",
            popover: {
                title: "Number of compositions",
                description:
                    "Specify how many compositions you want to be generated in parallel.",
                type: "top",
            },
        },
        {
            element: "[av-data='cw-step3-thematic-material']",
            popover: {
                title: "Thematic material",
                description:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae eros vitae nisl ultricies ultricies. Sed vitae eros vitae nisl ultricies ultricies.",
                type: "top",
            },
        },
        {
            element: "[av-data='cw-step3-instruments-change']",
            popover: {
                title: "Changing the instruments",
                description:
                    "Toggle to allow AIVA to change the instruments of your composition within the boundaries of the selected style.",
                type: "top",
            },
        },
        {
            element: "[av-data='cw-step3-save']",
            popover: {
                title: "Save workflow",
                description:
                    "Use to save your workflow to your account, which will help you to create compositions or workflows based on this one.",
                type: "top",
            },
        },
    ]

    private readonly createCompositionTutorialSteps = [
        {
            element: "[av-data='cc-top-menu']",
            popover: {
                title: "Welcome to AIVA composition creation!",
                description:
                    "Let us walk you through each of the options here.",
                type: "bottom",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='cc-styles']",
            popover: {
                title: "Create from a style",
                description:
                    "This option will let you create a composition from a style of your choice.\nNo additional steps are required for this option",
                type: "top",
            },
        },
        {
            element: "[av-data='cc-chord-progression']",
            popover: {
                title: "Create from a chord progression",
                description:
                    "This option will allow you to choose a style and than modify the chord progression of that style to match your needs.",
                type: "top",
            },
        },
        {
            element: "[av-data='cc-step-by-step']",
            popover: {
                title: "Step by Step creation",
                description:
                    "Much like the previous step, this option will allow you to choose a style and than modify the chord progression of that style to match your needs.\nHowever, this option will allow you to modify the instruments and the melody of your composition as well.",
                type: "top",
            },
        },
        {
            element: "[av-data='cc-upload-influence']",
            popover: {
                title: "Upload your influence",
                description:
                    "This option will allow you to upload an influence in the form of a MIDI or an audio file.\nAIVA will then create a composition based on that influence.",
                type: "top",
            },
        },
    ]

    private readonly homePageTutorialSteps = [
        {
            element: "#emptySelector",
            popover: {
                title: "Welcome to AIVA!",
                description: `
                    <p>AIVA is an Artificial Intelligence music generation assistant. We'll give you a brief tour of the basic music generation functionality.</p>
                `,
                type: "bottom",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='sidebar-create-btn']",
            popover: {
                title: "Create a composition",
                description: `
                    <p>Let's create a composition! Click on the "Create Track" button to start the process.</p>
                `,
                disableButtons: ["next" as AllowedButtons],
                type: "bottom",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='create-from-style']",
            popover: {
                title: "Select a creation method",
                description: `<p>You can generate a composition from a style, a chord progression, an existing influence or using a step by step process offering deep customisation features.</p>
                    <p>We'll use the simplest one for now: click on "From a Style" to move on to the next step.</p>`,
                type: "bottom",
                align: "center" as Alignment,
                disableButtons: ["next" as AllowedButtons],
            },
        },
        {
            element: "#emptySelector",
            popover: {
                title: "",
                description: ``,
                type: "bottom",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='gp-library']",
            popover: {
                title: "Styles",
                description: `Here's a list of styles you can choose from. Hover your mouse over one, and click on "+ Create".`,
                type: "bottom",
                align: "center" as Alignment,
                disableButtons: ["next" as AllowedButtons],
            },
        },
        {
            element: "[av-data='create-with-gp-modal']",
            popover: {
                title: "Configure your composition",
                description: `
                    <p>You can configure your composition by tweaking the options here</p>
                    <p>If you're unsure about these options, it is safe to leave them on their default values.</p>
                `,
                type: "bottom",
                align: "center" as Alignment,
                disableButtons: ["next" as AllowedButtons],
            },
        },
        {
            element: "[av-data='composition0']",
            popover: {
                title: "Congratulations!",
                description: `
                    <p>You've succesfully created your first composition!</p>
                    <p>You can listen to it by clicking on the play button when the composition finishes loading.</p>
                `,
                type: "bottom",
                align: "center" as Alignment,
            },
        },
        {
            element: "[av-data='tracks-create-btn']",
            popover: {
                title: "Organizing",
                description: `You can create folders to organize your compositions by clicking here`,
                type: "bottom",
                align: "center" as Alignment,
            },
        },
    ]
    constructor(
        private apiService: ApiService,
        private userService: UserService,
        private deviceService: DeviceDetectorService,
        private folderService: FolderService
    ) {}

    /**
     * Figure out a way to add a skip button, if that doesnt work out change the previous button to be a skip button
     * Outside click should bring up a modal asking if the user wants to quit the tutorial
     * Glowing cursor on items that need to be clicked
     * Generic solution to disable a click
     * Throttle the network to reproduce a bug with gp-list
     */

    public initChordProgressionTutorial(enforce = false) {
        const modalId = "chordProgressionTutorial"
        if (
            (this.isTutorialInProgress$.value ||
                this.userService.modalSettings === undefined ||
                this.userService.modalSettings[modalId]) &&
            !enforce
        )
            return
        const _driver = this.createDriver(modalId, true)
        this.isTutorialInProgress$.next("step1")
        _driver.setSteps(this.chordProgressionTutorialSteps)
        _driver.drive()
    }

    public initHomeTutorial(enforce = false) {
        const modalId = "homePageTutorial"
        if (this.deviceService.isMobile()) {
            return
        }

        if (
            (this.isTutorialInProgress$.value ||
                this.userService.modalSettings === undefined ||
                this.userService.modalSettings[modalId]) &&
            !enforce
        )
            return
        this.tutorialNext$.next(false)
        const _driver = driver({
            animate: true,
            popoverClass: "popover-class",
            showProgress: true,
            disableActiveInteraction: false,
            onDestroyStarted: async () => {
                await this.onDestroyCallback(_driver, modalId)
            },
            onCloseClick: async () => {
                await this.onDestroyCallback(_driver, modalId, false)
            },
            onPopoverRender: async (step, opts) => {
                this.createSkipButton(step, _driver, modalId)
                const state = opts?.state
                if (!state) return
                if (
                    state.activeIndex === 1 ||
                    state.activeIndex === 2 ||
                    state.activeIndex === 7
                ) {
                    state.activeElement.classList.add("bounce")
                }

                if (state.activeIndex === 4) {
                    const [nodes, images] = [
                        document.querySelectorAll(".label-item.more-label"),
                        document.querySelectorAll(".label-item.more-label img"),
                    ]
                    nodes.forEach(node => {
                        node.classList.add("disable-clicks")
                    })
                    images.forEach(node => {
                        node.classList.add("disable-clicks")
                    })
                }

                if (state.activeIndex === 5) {
                    state.activeElement
                        .querySelector("button")
                        .classList.add("bounce--blue")
                }
            },
            onDeselected: async (element, step, opts) => {
                element?.classList.remove("bounce")
            },
        })
        this.isTutorialInProgress$.next("home")
        _driver.setSteps(this.homePageTutorialSteps)
        this.collapseNavigation$.next(true)
        _driver.drive()

        this.folderService.content
            .pipe(
                filter(val => val.length > 0),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    _driver.moveNext()
                })
            })

        this.tutorialNext$.pipe(throttleTime(100)).subscribe(val => {
            if (!val) return
            _driver.moveNext()
        })
    }

    private createDriver(modalId: string, disableActiveInteraction: boolean) {
        const _driver = driver({
            animate: true,
            popoverClass: "popover-class",
            disableActiveInteraction,
            onDestroyStarted: async () => {
                this.onDestroyCallback(_driver, modalId)
            },
            onCloseClick: async () => {
                await this.onDestroyCallback(_driver, modalId, false)
            },
        })

        return _driver
    }

    public initCWStep2Part1Tutorial(enforce = false) {
        const modalId = "cwStep2Tutorial"
        if (
            (this.isTutorialInProgress$.value ||
                this.userService.modalSettings === undefined ||
                this.userService.modalSettings[modalId]) &&
            !enforce
        )
            return
        const _driver = this.createDriver(modalId, true)

        this.isTutorialInProgress$.next("step2part1")
        _driver.setSteps(this.cwStep2Part1TutorialSteps)
        _driver.drive()
    }

    public initStep2Part2Tutorial(enforce = false) {
        const modalId = "cwStep2Part2Tutorial"
        if (
            (this.isTutorialInProgress$.value ||
                this.userService.modalSettings === undefined ||
                this.userService.modalSettings[modalId]) &&
            !enforce
        )
            return
        const _driver = this.createDriver(modalId, true)
        this.isTutorialInProgress$.next("step2part2")
        _driver.setSteps(this.cwStep2Part2TutorialSteps)
        _driver.drive()
    }

    public initCWStep3Tutorial(enforce = false) {
        const modalId = "cwStep3Tutorial"
        if (
            (this.isTutorialInProgress$.value ||
                this.userService.modalSettings === undefined ||
                this.userService.modalSettings[modalId]) &&
            !enforce
        )
            return
        const steps = this.getStep3Steps()
        const _driver = this.createDriver(modalId, true)
        this.isTutorialInProgress$.next("step3")
        _driver.setSteps(steps)
        _driver.drive()
    }

    private getStep3Steps() {
        let initialSteps = featureFlags.newThematicMaterialToggle
            ? this.cwStep3TutorialSteps2
            : this.cwStep3TutorialSteps1
        initialSteps = initialSteps.filter(step => {
            return !!document.querySelector(step.element)
        })
        return initialSteps
    }

    public initCreateCompositionTutorial(enforce = false) {
        const modalId = "createCompositionTutorial"
        if (
            (this.isTutorialInProgress$.value ||
                this.userService.modalSettings === undefined ||
                this.userService.modalSettings[modalId]) &&
            !enforce
        )
            return
        const _driver = this.createDriver(modalId, true)
        this.isTutorialInProgress$.next("create")
        _driver.setSteps(this.createCompositionTutorialSteps)
        _driver.drive()
    }

    private async onDestroyCallback(
        _driver: any,
        modalId: string,
        shouldCheck = true
    ) {
        if (shouldCheck) {
            if (
                !_driver.hasNextStep() ||
                confirm("Are you sure you want to quit the tutorial?")
            ) {
                await this.destroy(_driver, modalId)
            }
        } else {
            await this.destroy(_driver, modalId)
        }
    }

    private async destroy(
        _driver: any,
        modalId: string,
    ) {
        this.isTutorialInProgress$.next(undefined)
        this.userService.setModalSettings(modalId, true)
        _driver.destroy()
        this.initTutorial$.next(false)

        await this.apiService.authRequest(
            "/user/setModalSetting",
            { modalId },
            "primary",
            true
        )
    }

    private createSkipButton(step: PopoverDOM, driver, modalId: string) {
        const button = document.createElement("button")
        button.classList.add("skip-button")
        button.innerText = "Skip"

        button.addEventListener("click", () => {
            this.onDestroyCallback(driver, modalId, false)
        })

        const disableButton = step.footer
            .querySelector(".driver-popover-navigation-btns")
            .querySelector(".driver-popover-prev-btn")

        step.footer
            .querySelector(".driver-popover-navigation-btns")
            .removeChild(disableButton)

        const buttonsContainer = step.footer.querySelector(
            ".driver-popover-navigation-btns"
        )
        buttonsContainer.insertBefore(button, buttonsContainer.firstChild)
    }
}
