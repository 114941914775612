import { Injectable } from "@angular/core"
import {
    DrumSamplesMap,
    InstrumentsJSON,
    PitchToChannelMapping,
} from "@common-lib/interfaces/score/general"
import { ParentClass } from "../../parent"
import { InstrumentManipulation } from "@common-lib/modules/instruments-manipulation"
import SearchItem from "@common-lib/classes/searchitem"
import { InstrumentsHttpService } from "./instruments.http"
import { InstrumentsQuery } from "./state/instruments.query"
import { InstrumentsStore } from "./state/instruments.store"
import { WindowService } from "@services/window.service"
import Layer from "@common-lib/classes/score/layer"
import { TemplateScore } from "@common-lib/interfaces/score/templateScore"
import { cloneDeep } from "lodash"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"

@Injectable()
export class InstrumentsService extends ParentClass {
    private readonly store: InstrumentsStore = new InstrumentsStore()
    public readonly query: InstrumentsQuery = new InstrumentsQuery(this.store)

    constructor(private instrumentsHttp: InstrumentsHttpService, private windowService:WindowService) {
        super()

        this.loadInstruments()
    }

    public get refreshSearchUI$() {
        return this.query.select("refreshSearchUI")
    }

    public get newPatchSelected$() {
        return this.query.select("newPatchSelected")
    }

    public get allInstrumentsAreDownloaded() {
        return this.query.getValue().allInstrumentsAreDownloaded
    }

    public get instruments(): Readonly<InstrumentsJSON> {
        return this.query.instruments
    }

    public get drumSamples(): Readonly<DrumSamplesMap> {
        return this.query.drumSamples
    }

    public get pitchToChannelMapping(): Readonly<PitchToChannelMapping> {
        return this.query.pitchToChannelMapping
    }

    public get percussionOrganizedByPath(): Readonly<SearchItem[]> {
        return this.query.getValue().percussionOrganizedByPath
    }

    public get instrumentsOrganizedByPath(): Readonly<SearchItem[]> {
        return this.query.getValue().instrumentsOrganizedByPath
    }

    public get numberOfPatches(): number {
        return this.query.getValue().numberOfPatches
    }

    public async getTemplateScore() : Promise<TemplateScore> {
        if (this.query.templateScore) {
            return cloneDeep(this.query.templateScore)
        }

        const templateScore:TemplateScore = await this.instrumentsHttp.getTemplateScore()

        this.store.update(state => ({
            ...state,
            templateScore,
        }))

        return cloneDeep(templateScore)
    }

    private init(
        instruments: InstrumentsJSON,
        drumSamples: DrumSamplesMap,
        pitchToChannelMapping: PitchToChannelMapping
    ) {
        const result =
            InstrumentManipulation.getInstrumentsOrganizedByPaths(instruments)
        const numberOfPatches = result.numberOfPatches
        const instrumentsOrganizedByPath = result.instrumentsOrganizedByPath
        const percussionOrganizedByPath = result.percussionOrganizedByPath

        this.store.update(state => ({
            ...state,
            drumSamples,
            instruments,
            pitchToChannelMapping,
            numberOfPatches,
            instrumentsOrganizedByPath,
            percussionOrganizedByPath,
        }))
    }

    public setAllInstrumentsAreDownloaded(value: boolean) {
        this.store.update(state => ({
            ...state,
            allInstrumentsAreDownloaded: value,
        }))
    }

    public setDownloadAllStatus(value) {
        this.store.update(state => ({
            ...state,
            downloadAllStatus: value,
        }))
    }

    public setRefreshSearchUI() {
        this.store.update(state => ({
            ...state,
            refreshSearchUI: Date.now(),
        }))
    }

    public selectNewPatch(patch) {
        this.store.update(state => ({
            ...state,
            newPatchSelected: patch,
        }))
    }

    public setIsDownloadingAllInstruments(value:boolean) {
        this.store.update(state => ({
            ...state,
            isDownloadingAllInstruments: value,
        }))
    }

    public getPatch(instrument:string) {
        var result = {
            name: "Piano",
        }

        const section = instrument.split(".")[0]
        const instrumentName = instrument.split(".")[1]
        const playingStyle = instrument.split(".")[2]
        const articulation = instrument.split(".")[3]

        for (var i = 0; i < this.instruments[section].length; i++) {
            const inst = this.instruments[section][i]

            if (inst.name == instrumentName) {
                if (
                    inst.patches.length == 1 &&
                    inst.patches[0].name == "Natural"
                ) {
                    return { name: inst.full_name }
                }

                for (var p = 0; p < inst.patches.length; p++) {
                    const patch = inst.patches[p]

                    if (
                        playingStyle == patch.playing_style &&
                        articulation == patch.articulation
                    ) {
                        if (section == "p") {
                            return { name: inst.full_name }
                        } else {
                            return { name: inst.full_name + " | " + patch.name }
                        }
                    }
                }
            }
        }

        return result
    }

    /**
	 * retrieves all patches of a searchItem recursively
	 * e.g. when selecting a high level search item, we will fetch all the lower level search items patches as well
	 * @param searchItem 
	 * @returns 
	 */
	public getAllPatches(searchItem: SearchItem){
		let patches = []

		if(searchItem.item != null && Object.keys(searchItem.item).length > 0 && searchItem.type != "folder"){
			patches = patches.concat(searchItem.item.patches.map(p => p.patch))
		}

		if(searchItem.values != null && searchItem.values.length > 0){
			for(let item of searchItem.values){
				patches = patches.concat(this.getAllPatches(item))
			}
		}

		return patches
	}

    // three types of results
    // 1) Patches
    // 2) Arbitrary Sections
    public searchForPatches(keywords: string, layer: Layer | GPLayer, instruments = null) {
        keywords = keywords.replace("/", " ")

        if (instruments == null) {
            if (layer.type == "percussion") {
                instruments = this.percussionOrganizedByPath
            } else {
                instruments = this.instrumentsOrganizedByPath
            }
        }

        var results = []

        for (var i = 0; i < instruments.length; i++) {
            var words = keywords.split(" ")

            var matches = {
                partial: 0,
                exact: 0,
                type: instruments[i].values == null ? "patch" : "folder",
                value: instruments[i],
            }

            var matchWithName = false

            for (var w = 0; w < words.length; w++) {
                var path: any = instruments[i].path

                if (instruments[i].values == null) {
                    path += "/" + instruments[i].name

                    if (!matchWithName) {
                        var instrumentName = instruments[i].name.toLowerCase()

                        matchWithName =
                            words[w].toLowerCase() == instrumentName ||
                            instrumentName.includes(words[w].toLowerCase())
                    }
                }

                path = path.split("/")

                if (path[path.length - 1] == "") {
                    path.splice(path.length - 1, 1)
                }

                var matchType = "none"

                for (var p = 0; p < path.length; p++) {
                    var originalString = path[p].toLowerCase()
                    var userKeyword = words[w].toLowerCase()

                    if (originalString == userKeyword) {
                        matchType = "exact"
                    } else if (originalString.includes(userKeyword)) {
                        if (matchType != "exact") {
                            matchType = "partial"
                        }
                    }
                }

                if (matchType == "exact") {
                    matches.exact += 1
                } else if (matchType == "partial") {
                    matches.partial += 1
                } else if (matchType == "none") {
                    matches.partial = 0
                    matches.exact = 0

                    break
                }
            }

            if (
                (matches.partial != 0 || matches.exact != 0) &&
                (matchWithName || matches.type == "folder")
            ) {
                results.push(matches)
            }

            if (instruments[i].values != null) {
                results = results.concat(
                    this.searchForPatches(
                        keywords,
                        layer,
                        instruments[i].values
                    )
                )
            }
        }

        return results
    }

    public async loadInstruments(): Promise<{
        instruments: InstrumentsJSON
        drumSamples: DrumSamplesMap
        drumkitPitchToChannelMapping: PitchToChannelMapping
    }> {
        if (this.query.instruments === undefined) {
            const response = await this.instrumentsHttp.getInstruments()

            if (this.windowService.desktopAppAPI) {
                await this.windowService.samplerAPI.setSamplesMap(
                    response.drumSamples
                )
            }

            this.init(
                response.instruments,
                response.drumSamples,
                response.drumkitPitchToChannelMapping
            )

            return response
        }
    }
}
