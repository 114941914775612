import { InstrumentsJSON } from "../../interfaces/score/general"
import GPValidation from "../generationprofiles/gpvalidation"
import Patch from "./patch"

interface PatchEntry {
    patch: Patch
    octave: number
}
export default class InstrumentPatch {
    patchID: string = "" // the full name of the instrument or double patch e.g. 's.violins1.nat.stac' or 'd.4646151.violins1.stac'
    noteTie: boolean = false
    name: string = ""
    patches: Array<PatchEntry> = []
    createdByAiva = true
    type: string = "pitched"
    folderID = ""
    deleted: boolean

    constructor(
        patchID: string,
        name: string,
        type: string,
        patches?: Array<{ patch: Patch; octave: number }>
    ) {
        this.patchID = patchID
        this.name = name
        this.type = type

        if (patches != null) {
            this.patches = patches
        }
    }

    /**
     * Computes the similarity of two instrument patches
     * @param inst1
     * @param inst2
     * @returns number: the score from 0 least similar to 1 most similar
     */
    static computeSimilarity(inst1: InstrumentPatch, inst2: InstrumentPatch) {
        let score = 0

        if (inst1.patchID == inst2.patchID) {
            return 1
        }

        for (let patch1 of inst1.patches) {
            for (let patch2 of inst2.patches) {
                if (
                    patch1.octave == patch2.octave &&
                    patch1.patch.getFullName() === patch2.patch.getFullName()
                ) {
                    score += 1
                    break
                }
            }
        }

        score = score / Math.max(inst1.patches.length, inst2.patches.length)

        console.assert(score <= 1)

        if (score >= 0.99) {
            score = 1
        }

        return score
    }

    /**
     * Finds the instruments from a section that match the given patches and returns them as an object
     * @param instrumentsJSON
     * @param section
     * @param instruments
     * @returns
     */
    static lookupInstrumentsFromSection(
        instrumentsJSON: InstrumentsJSON,
        section: string,
        instruments: Patch[]
    ) {
        if (instruments.length === 0) {
            return
        }

        const instrumentsObject = {}

        if (!section) {
            return
        }

        for (let instrument of instrumentsJSON[section]) {
            if (Object.keys(instrumentsObject).length == instruments.length) {
                break
            }

            for (let patch of instruments) {
                try {
                    if (patch === undefined) {
                        break
                    }

                    if (patch instanceof Patch === false) {
                        patch = Patch?.fromJSON(patch)
                    }

                    if (
                        patch?.getFullName() === undefined ||
                        patch?.getFullName() === ""
                    ) {
                        break
                    }

                    if (instrument.name === patch.instrument) {
                        instrumentsObject[patch.getFullName()] = instrument
                        instrumentsObject[patch.getFullName()].path =
                            instrument.patches[0].path
                    }
                } catch (error) {
                    console.error(
                        "Error in lookupInstrumentsFromSection",
                        error
                    )
                    continue
                }
            }
        }

        return instrumentsObject
    }

    addPatchFromInstrumentPatch(instPatch: InstrumentPatch) {
        for (let patch of instPatch.patches) {
            this.patches.push({
                patch: Patch.fromJSON(patch.patch),
                octave: patch.octave,
            })
        }
    }

    addPatchEntry(
        section: string,
        instrument: string,
        playingStyle: string,
        articulation: string,
        octave: number
    ) {
        this.patches.push({
            patch: new Patch(
                section,
                instrument,
                playingStyle,
                articulation,
                ""
            ),
            octave: octave,
        })
    }

    copy(): InstrumentPatch {
        let instPatch: InstrumentPatch = new InstrumentPatch("", "", "")

        for (let key of Object.keys(this)) {
            if (key == "patches") {
                let patches = []

                for (let patchEntry of this.patches) {
                    patches.push({
                        octave: patchEntry.octave,
                        patch: new Patch(
                            patchEntry.patch.section,
                            patchEntry.patch.instrument,
                            patchEntry.patch.playing_style,
                            patchEntry.patch.articulation,
                            patchEntry.patch.granulationEngine
                        ),
                    })
                }

                instPatch.patches = patches
            } else {
                instPatch[key] = this[key]
            }
        }

        return instPatch
    }

    encode(forME = true) {
        let patches = []

        if (forME) {
            for (let patch of this.patches) {
                patches.push({
                    name: patch.patch.getFullName(),
                    octave: patch.octave,
                })
            }

            let technique

            if (this.patchID == "") {
                technique = "nat.sus"
            } else {
                technique =
                    this.patchID.split(".")[2] +
                    "." +
                    this.patchID.split(".")[3]
            }

            return {
                id: this.patchID,
                instruments: patches,
                technique: technique,
            }
        }

        for (let patch of this.patches) {
            patches.push({
                patch: patch.patch.getFullName(),
                octave: patch.octave,
            })
        }

        return {
            _id: this.patchID,
            patches: patches,
            name: this.name,
            type: this.type,
            folderID: this.folderID,
        }
    }

    static createNewPatch({
        type,
        folderID,
    }: {
        type: "pitched" | "percussion"
        folderID: string
    }): InstrumentPatch {
        const patch = new InstrumentPatch("", "Untitled Double", type, [])
        patch.createdByAiva = false
        patch.folderID = folderID

        return patch
    }

    static decode(object): InstrumentPatch {
        const instrumentPatch = new InstrumentPatch(
            object._id,
            object.name,
            object.type,
            []
        )

        for (let patchEntry of object.patches) {
            instrumentPatch.patches.push({
                patch:
                    typeof patchEntry.patch == "string"
                        ? Patch.fromString(patchEntry.patch)
                        : Patch.fromJSON(patchEntry.patch),
                octave: patchEntry.octave,
            })
        }

        instrumentPatch.createdByAiva = object.createdByAiva == true
        instrumentPatch.folderID = object.folderID
        instrumentPatch.deleted = object.deleted

        return instrumentPatch
    }

    static fromJSON(object) {
        let instrumentPatch = Object.assign(
            new InstrumentPatch("", "", "", []),
            object
        )

        let patches = []

        for (let patch of object.patches) {
            patches.push({
                octave: patch.octave,
                patch:
                    typeof patch.patch == "string"
                        ? Patch.fromString(patch.patch)
                        : Patch.fromJSON(patch.patch),
            } as PatchEntry)
        }

        const createdByAiva =
            object.createdByAiva == null || object.createdByAiva == false
                ? false
                : true
        const deleted =
            object.deleted == null || object.deleted == false ? false : true

        instrumentPatch.createdByAiva = createdByAiva
        instrumentPatch.deleted = deleted
        instrumentPatch.patches = patches

        return instrumentPatch
    }

    static decodePatchID(patchID: string) {
        let instrumentParts = patchID.split(".")

        return {
            section: instrumentParts[0],
            name: instrumentParts[1],
            playing_style: instrumentParts[2],
            articulation: instrumentParts[3],
        }
    }

    validate(validateForUI = false) {
        let validation = new GPValidation(true, "instrumentPatch", this)

        if (validateForUI == false) {
            if (this.patches == null || this.patches.length == 0) {
                validation.valid = false
                validation.issue = "doubleWithEmptyInstruments"
                validation.message =
                    "A double needs to hold at least one instrument."
            }
        }

        return validation
    }
}
