export default class LayerPreview {
    layer:string = ""
    packID:string = ""
    patchID:string = ""
    status:string = "" // paused, loading or playing

    encodeForME() {
        return {
            layer: this.layer,
            pack_id: this.packID,
            instrument: this.patchID
        }
    }

    static fromJSON(object) {
        return Object.assign(new LayerPreview(), object)
    }
}