import { Store, StoreConfig } from "@datorama/akita"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import { cloneDeep } from "lodash"
import {
    CWStateStep1,
    CWStateStep2,
    CWStateStep3,
    CWStepData,
    CompositionWorkflowSchema,
} from "@common-lib/interfaces/composition-workflow.interface"
import { v4 as uuidv4 } from "uuid"
import { CompositionWorkflowType } from "@common-lib/types/general"

export const steps = [
    {
        title: "Composition Workflow",
        subtitle: "Create & tweak a chord progression",
        index: 0,
    },
    {
        title: "Composition Workflow",
        subtitle: "Customize your composition's layers",
        index: 1,
    },
    {
        title: "Composition Workflow",
        subtitle: "Generate the full composition",
        index: 2,
    },
]
export interface CWState {
    cw: CompositionWorkflowSchema | undefined
    type: CompositionWorkflowType

    step1: CWStateStep1
    step2: CWStateStep2
    step3: CWStateStep3
    gp: Readonly<GenerationProfile>
    engine: ScoreRenderingEngine | undefined
    loading: {
        finished: boolean
        error?: string
    }
    lastUpdate: number
    layerToAdd: string | undefined
    layerToDelete: string | undefined
    stepData: CWStepData
    lastUndoRedo: number

    savedWorkflow: boolean

    toggleLocalLLM: boolean
}

export const initialStepOne: CWStateStep1 = {
    tempo: 120,
    timeSignature: [4, 4],
    harmony: undefined,
    harmonyPromptId: undefined,
    chordProgressionLoading: {
        finished: false,
        error: undefined,
    },
    prompt: "",
}

export const createInitialStepTwo = (): CWStateStep2 => {
    return {
        layerLoading: {},
        layers: {},
        useLLM: false,
    }
}

export const initialStepThree: CWStateStep3 = {
    saveWorkflowToAccount: true,
    name: "",
    duration: "1'30 - 2'00",
    allowInstrumentationChanges: true,
    loading: {
        finished: true,
    },
    nbOfCompositions: 1,
    newThematicMaterial: false,
}

export const initialState: CWState = {
    cw: undefined,
    type: "stepByStep",

    step1: initialStepOne,
    step2: createInitialStepTwo(),
    step3: initialStepThree,

    gp: undefined,

    loading: {
        finished: false,
    },

    engine: undefined,

    lastUpdate: Date.now(),
    lastUndoRedo: undefined,

    layerToAdd: undefined,
    layerToDelete: undefined,

    stepData: { ...steps[0], id: uuidv4() },

    savedWorkflow: false,

    toggleLocalLLM: false,
}

@StoreConfig({ name: "CompositionWorkflow" })
export class CWStore extends Store<CWState> {
    constructor() {
        super(cloneDeep(initialState))
    }

    public updateStore(partial: Partial<CWState>) {
        this.update(state => ({
            ...state,
            ...partial,
            lastUpdate: Date.now(),
        }))
    }
}
