import Layer from "../../../general/classes/score/layer"
import { ImmutableNote, Note } from "../../../general/classes/score/note"
import PercussionLayer from "../../../general/classes/score/percussionlayer"
import Score from "../../../general/classes/score/score"
import { TIMESTEP_RES } from "../../../general/constants/constants"
import { Time } from "../../../general/modules/time"
import { EditorViewState } from "../states/editor-view/editor-view.store"
import { ScoreRendering } from "../states/score-rendering/score-rendering.store"
import {
    ScoreRenderingActionsObject,
    ScoreRenderingQueriesObject,
    GridDimensions,
    TimelineCanvasContext,
    GridDimensionsDictionary,
    GridCoordinates,
    AllCanvasCoordinates,
    CanvasType,
} from "../types"
import ScoreCanvas from "./score-canvas"
import { cloneDeep } from "lodash"
import { Coordinates } from "../../../general/modules/event-handlers"

export default class TimelineCanvas extends ScoreCanvas {
    constructor(
        public context: TimelineCanvasContext,
        protected queries: ScoreRenderingQueriesObject,
        protected actions: ScoreRenderingActionsObject,
        protected type: CanvasType | string
    ) {
        super(context, queries, actions, type)

        this.initListeners()
    }

    private initListeners() {
        this.mouseMove$.subscribe(this.scrollWithMouseMovement.bind(this))

        this.mouseDown$.subscribe((event: Coordinates) => {
            this.mouseDownHandler(event)
            this.scrollWithMouseMovement(event)
        })
    }

    protected getGridCoordinates(
        event: Coordinates
    ): GridCoordinates | undefined {
        const result = this.getStepsForPixels({
            coordinates: cloneDeep(event),
        })

        if (result.timesteps === undefined) {
            return undefined
        }

        return {
            timesteps: result.timesteps,
            ysteps: 0,
        }
    }

    public render(
        scoreState: ScoreRendering,
        editorViewState: EditorViewState,
        grid: GridDimensionsDictionary,
        syncedCanvas?: ScoreCanvas
    ) {
        if (
            !this.shouldRenderCanvas(
                scoreState.renderingType
            )
        ) {
            return
        }

        const score: Score = scoreState.score

        if (!score) {
            return
        }

        if (syncedCanvas) {
            this.syncedCanvas = syncedCanvas
        }

        this.initRender({
            scoreState: scoreState,
            editorViewState: editorViewState,
            grid: grid,
            computePxPerTimestep: this.computePxPerTimestep.bind(this),
            noteRes: TIMESTEP_RES,
            nbOfYValues: 0
        })

        if (this.syncedCanvas) {
            this.generateTimelineWindow(scoreState)
        }

        if (this.queries.scoreRendering.toggledLayer?.type === "pitched") {
            this.generateTimelineNotes(this.queries.scoreRendering.toggledLayer)
        } else if (this.queries.scoreRendering.toggledLayer?.type === "percussion") {
            this.generateTimelinePercussionOnsets(
                scoreState,
                <PercussionLayer>this.queries.scoreRendering.toggledLayer
            )
        }
    }

    private computePxPerTimestep(): number {
        return (
            this.width /
            Time.convertTimestepsToAnotherRes(
                this.grid.scoreLengthTimesteps,
                this.grid.timestepRes,
                TIMESTEP_RES
            )
        )
    }

    private generateTimelineWindow(scoreState: ScoreRendering) {
        if (!this.syncedCanvas) {
            // @todo: make sure to not render any timeline window and clean up any previously drawn window

            return
        }

        const score = scoreState.score
        const gridType = this.syncedCanvas?.context?.gridType
        const totalWidth = this.width
        const timestepRes = TIMESTEP_RES

        let timestepRange = Time.convertTimestepsToAnotherRes(
            this.syncedCanvas.getTimestepRange(),
            this.syncedCanvas.noteRes,
            TIMESTEP_RES
        )

        let scrollToTimestep = scoreState.scrollToTimestep
        let scoreLength = score.scoreLength
        let pxPerTimestep = this.pxPerTimestep

        if (gridType === "drumSequencer") {
            scrollToTimestep = scoreState.patternScrollToTimestep

            const patternLengthTimeSteps = Time.barToTimestep(
                scoreState.selectedPattern.bars,
                scoreState.score.timeSignatures[0][1],
                timestepRes
            )
            scoreLength = Time.timestepToFraction(
                patternLengthTimeSteps,
                timestepRes
            )
            pxPerTimestep = this.getDrumSequencerPxPerTimestep()
        }

        const scoreLengthTimesteps = Time.fractionToTimesteps(
            timestepRes,
            scoreLength
        )
        const percentWidth = timestepRange / scoreLengthTimesteps
        const borderWidth = 3

        const timelineWindowWidth = totalWidth * percentWidth
        const timelineWindowX = scrollToTimestep * pxPerTimestep
        const maxTimelineWindowX =
            totalWidth - timelineWindowWidth - borderWidth / 2

        const width = Math.min(timelineWindowWidth, totalWidth - borderWidth)

        const x = Math.max(
            borderWidth / 2,
            Math.max(0, Math.min(timelineWindowX, maxTimelineWindowX))
        )

        const y = borderWidth / 2
        const borderRadius = 8
        const strokeStyle = "rgba(255, 255, 255, 1)"
        const fillStyle = "rgba(35,28,80, 0.9)" // #231c50

        const ctx = this.getContext("canvas")

        ScoreCanvas.roundRect(ctx, x, y, width, this.height - 3, borderRadius)
        ctx.lineWidth = borderWidth

        ctx.fillStyle = fillStyle
        ctx.fill()
        ctx.strokeStyle = strokeStyle
        ctx.stroke()
    }

    private generateTimelineNotes(layer: Layer | PercussionLayer) {
        const ctx = this.getContext("canvas")
        const minPitch = layer.getLowestNotePitch()
        const maxPitch = layer.getHighestNotePitch()

        const pitchRange = maxPitch - minPitch + 1
        const noteHeight = Math.max(1, Math.min(
            Math.round(this.height / 15),
            this.height / pitchRange
        ))

        ctx.beginPath()

        layer.notesObject.manipulateNoteGroups((notes: ImmutableNote[]) => {
            const startTimesteps = Time.fractionToTimesteps(
                TIMESTEP_RES,
                notes[0].start
            )
            const durationTimesteps = Time.fractionToTimesteps(
                TIMESTEP_RES,
                notes[0].duration
            )

            for (let note of notes) {
                const x = this.round(startTimesteps * this.pxPerTimestep)
                const y = this.round(
                    this.height -
                        1.5 * noteHeight -
                        (this.height * (note.pitch - minPitch)) / pitchRange
                )
                const duration =
                    this.round(durationTimesteps * this.pxPerTimestep) - 2

                ctx.rect(x, y, duration, noteHeight)
            }

            return true
        })

        ctx.strokeStyle = "transparent"
        ctx.fillStyle = layer.getColor()
        ctx.lineWidth = 0

        ctx.fill()
        ctx.closePath()
    }

    private getDrumSequencerPxPerTimestep() {
        const timeSignature = this.queries.scoreRendering.score.timeSignatures[0][1]
        const scoreState = this.queries["scoreRendering"].getValue()
        const windowBorderWidth = 3
        const pxPerTimestep =
            (this.width - 2 * windowBorderWidth) /
            Time.barToTimestep(scoreState.selectedPattern.bars, timeSignature, this.noteRes)

        return pxPerTimestep
    }

    private generateTimelinePercussionOnsets(
        scoreState: ScoreRendering,
        layer: PercussionLayer
    ) {
        const ctx = this.getContext("canvas")
        const pattern = scoreState?.selectedPattern

        if (!pattern || !this.syncedCanvas) {
            return
        }

        const channels = pattern.getUniqueChannelsWithOnsets()
        const noteGutter = 3
        const windowBorderWidth = 3

        let noteHeightWithGutter =
            (this.height - 2 * windowBorderWidth) / channels.length
        noteHeightWithGutter = Math.min(
            Math.round(this.height / 15),
            this.height / noteHeightWithGutter
        )

        const noteHeightWithoutGutter = Math.max(1, noteHeightWithGutter - noteGutter)
        const pxPerTimestep = this.getDrumSequencerPxPerTimestep()

        ctx.beginPath()

        for (let c = 0; c < channels.length; c++) {
            const y = c * noteHeightWithGutter + windowBorderWidth
            const channel = channels[c]

            for (let o = 0; o < channel.onsets.length; o++) {
                const onset = channel.onsets[o]

                const startTimesteps = Time.fractionToTimesteps(
                    this.noteRes,
                    onset.start
                )
                const durationTimesteps = Time.fractionToTimesteps(
                    this.noteRes,
                    scoreState.selectedPattern.resolution
                )

                const x =
                    this.round(startTimesteps * pxPerTimestep) +
                    windowBorderWidth
                const width = this.round(durationTimesteps * pxPerTimestep) - 2

                ctx.rect(x, y, width, noteHeightWithoutGutter)
            }
        }

        ctx.strokeStyle = "transparent"
        ctx.fillStyle = layer.getColor()
        ctx.lineWidth = 0

        ctx.fill()
        ctx.closePath()
    }
}
