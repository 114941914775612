<div
    id="mytracks-component"
    [ngClass]="{
        'playlist-view': trackView !== 'trackslist',
        'single-playlist-view': trackView === 'single-playlist',
        influences: title === 'Influences',
        compositions: title === 'Compositions'
    }"
    oncontextmenu="return false">
    <div class="platform-component-container component-container">
        <div id="component-header">
            <div id="subtitles">
                <topmenu
                    type="trackslist"
                    [options]="getMenuOptions()"
                    (menuSelected)="setTab($event)"></topmenu>
            </div>
        </div>

        <div class="tracks-header">
            <div class="trackslist-toolbar">
                <div
                    class="back-to-playlists-button"
                    (click)="setTrackView('playlists', false)"
                    *ngIf="trackView === 'single-playlist'">
                    <div class="back-icon"></div>
                    <span class="back-text">Go back to Playlists</span>
                </div>

                <div class="toggle-track-view">
                    <a-button
                        type="round-icon-and-text"
                        (onClick)="openCreateMenu($event)"
                        class="trackslist-create"
                        label="Create"
                        [icon]="'assets/img/add.svg'"
                        avData="tracks-create-btn">
                    </a-button>

                    <div
                        class="trackslist-view"
                        [ngClass]="{ selected: trackView === 'trackslist' }"
                        (click)="setTrackView('trackslist', true)"
                        *ngIf="title === 'Compositions'">
                        <div class="tracks-icon"></div>
                    </div>
                    <div
                        class="playlist-view"
                        [ngClass]="{ selected: trackView !== 'trackslist' }"
                        (click)="setTrackView('playlists', false)"
                        *ngIf="title === 'Compositions'">
                        <div class="playlists-icon"></div>
                    </div>
                </div>
            </div>

            <div class="path">
                <div class="path-element" (click)="goToMyTracks()">
                    <div class="path-element-container">
                        <img src="assets/img/home.svg" />{{ title }}
                    </div>
                </div>

                <div
                    class="path-element"
                    *ngFor="let element of path"
                    (click)="goToFolder(element.folderID)">
                    <div class="path-element-container">
                        <img
                            src="assets/img/chevron.svg"
                            class="navigation-chevron" />{{ element.name }}
                    </div>
                </div>
            </div>

            <div
                class="single-playlist"
                *ngIf="trackView === 'single-playlist' && playlist != null"
                [style.display]="
                    trackView === 'single-playlist' ? 'block' : 'none'
                ">
                <div class="single-playlist-container">
                    <div class="playlist-header">
                        <div
                            class="playlist-discography"
                            [ngStyle]="{
                                'background-color': playlist.backgroundColor
                            }"
                            (mouseover)="playlist.hover = true"
                            (mouseleave)="playlist.hover = false">
                            <div class="playlist-item-info">
                                <div class="playlist-item-name">
                                    {{ playlist.name }}
                                </div>
                                <!-- <div class="playlist-item-songs">
                                    <div class="number-of-songs">{{ playlist.compositions.length }} songs</div>
                                    <div class="duration">{{ playlist.duration.h }} hr {{ playlist.duration.m }} min</div>
                                </div> -->
                            </div>

                            <div
                                class="playlist-item-play"
                                *ngIf="playlist.hover">
                                <img
                                    src="assets/img/play-circle.svg"
                                    class="animate-0_1s"
                                    *ngIf="
                                        playlist == null || !playlist.isPlaying
                                    "
                                    (click)="togglePlaylistPlay(playlist.id)" />
                                <img
                                    src="assets/img/player/pause-circle.svg"
                                    class="animate-0_1s"
                                    *ngIf="
                                        playlist != null && playlist.isPlaying
                                    "
                                    (click)="togglePlaylistPlay(playlist.id)" />
                            </div>

                            <div
                                class="background-image"
                                [ngStyle]="{
                                    'background-image':
                                        'url(' +
                                        getPlaylistBackgroundImage(playlist) +
                                        ')'
                                }"></div>
                            <div
                                class="background-image ripples"
                                [ngStyle]="{
                                    'background-image':
                                        'url(\'assets/img/radio_background_alternative.svg\')'
                                }"></div>
                        </div>
                        <div class="playlist-info">
                            <div class="description">
                                {{ playlist.description }}
                            </div>
                            <div class="compositions-info">
                                {{ playlist.numberOfCompositions }} songs,
                                {{ playlist.duration.h }} hr
                                {{ playlist.duration.m }} min
                            </div>
                            <div class="toolbar">
                                <div class="play-button"></div>
                                <div
                                    class="menu round-button create-button"
                                    (click)="createNewTrack()">
                                    Create Track
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="tracks-body mytracks-body"
            [ngClass]="{
                'trackslist-single-playlist': trackView === 'single-playlist'
            }">
            <div id="track-view">
                <tracks-list
                    (openCreateTrack)="
                        openCreateTrackMenu($event)
                    "></tracks-list>
            </div>
        </div>
    </div>
</div>

<div
    class="create-influence-mode"
    *ngIf="influenceService.createWithInfluenceMode">
    <div class="create-influence-mode-title">
        <img src="assets/img/cursor.svg" />
        Select an influence you'd like to create new track(s) with
    </div>

    <div
        class="create-influence-mode-cancel"
        (click)="closeCreateWithInfluenceMode()">
        Cancel
    </div>
</div>

<menu-options
    *ngIf="createMenuOptions !== undefined"
    [menuOptions]="createMenuOptions"
    [title]="createMenuOptions.title"
    (close)="unselectMenu()"></menu-options>

<menu-options
    *ngIf="createTrackOptions !== undefined"
    [menuOptions]="createTrackOptions"
    [title]="createTrackOptions.title"
    (close)="unselectMenu()"></menu-options>
