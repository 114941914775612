import { Injectable } from "@angular/core"
import { ApiService } from "@services/api.service"
import { ActivityMetric } from "../../../../common-lib/general/classes/activitymetric"
import CompositionMetric from "@clientmodels/compositionmetric"
import { Router } from "@angular/router"
import { Composition } from "@common-lib/classes/general/composition"
import { PlayerPreview } from "../../../../common-lib/client-only/general/classes/playerStateManagement"
@Injectable()
export class AnalyticsService {
    static EDIT_MODE = "edit_mode"
    static LISTENING_MODE = "listening_mode"

    static ACTIVITY_PUSH_INTERVAL = 30 * 1000 // 30 seconds, in ms
    static COMPOSITION_PUSH_INTERVAL = 30 * 1000 // 30 seconds, in ms

    activityMetricsQueue: Array<ActivityMetric> = []
    compositionMetric: CompositionMetric = null

    constructor(private router: Router, private apiService: ApiService) {
        setInterval(() => {
            this.sendActivityUpdate()
        }, AnalyticsService.ACTIVITY_PUSH_INTERVAL)

        setInterval(() => {
            this.sendCompositionUpdate()
        }, AnalyticsService.COMPOSITION_PUSH_INTERVAL)
    }

    addActivity(type: string, meta = null) {
        this.activityMetricsQueue.push(new ActivityMetric(type, meta))
    }

    addCompositionMetric(content: Composition | PlayerPreview, data, type) {
        if (content.contentType === "playerPreview") {
            return
        }

        if (this.router.url.split("?")[0].includes("/publicPlayer")) {
            return
        }

        if (
            this.compositionMetric == null ||
            this.compositionMetric.compositionID != content._id
        ) {
            this.compositionMetric = new CompositionMetric(
                content._id,
                content.contentType
            )
        }

        this.compositionMetric.data[type].push(data)
    }

    sendActivityUpdate() {
        if (
            this.activityMetricsQueue.length == 0 ||
            this.apiService.user.getValue() == null
        ) {
            return
        }

        this.apiService
            .authRequest(
                "/user/activity",
                { activityMetricsQueue: this.activityMetricsQueue },
                "primary",
                true
            )
            .then(res => {
                this.clearActivityQueue()

                return Promise.resolve()
            })

            .catch(err => {
                this.clearActivityQueue()

                return Promise.resolve()
            })
    }

    sendCompositionUpdate() {
        if (
            this.compositionMetric == null ||
            this.apiService.user.getValue() == null ||
            this.compositionMetric?.type === "training"
        ) {
            return
        }

        this.apiService
            .authRequest(
                "/composition/analytics",
                {
                    type: this.compositionMetric.type,
                    compositionID: this.compositionMetric.compositionID,
                    data: this.compositionMetric.data,
                },
                "primary",
                true
            )
            .then(res => {
                this.clearCompositionMetric()

                return Promise.resolve()
            })

            .catch(err => {
                this.clearCompositionMetric()

                return Promise.resolve()
            })
    }

    clearActivityQueue() {
        this.activityMetricsQueue = []
    }

    clearCompositionMetric() {
        this.compositionMetric = null
    }
}
