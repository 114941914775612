<div class="modal-title">Select a harmonic dataset</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="close.emit()" />
</div>

<div class="loader" *ngIf="loading"></div>

<div *ngIf="!loading">
    <div class="chord-dataset-filter">
        <span class="chord-dataset-filter-title">Filter: </span>
        <dropdown
            type="light"
            [showTooltip]="false"
            [value]="chordDatasetFilter"
            [items]="getChordDatasetFilterOptions()"
            (selectedItem)="changeFilter($event)"></dropdown>
    </div>

    <div
        class="harmony-datasets-list-container"
        [perfectScrollbar]="scrollChange">
        <div *ngFor="let dataset of chordDatasets">
            <radio-button
                [class]="'chord-dataset'"
                type="checkbox"
                [id]="dataset.packID"
                [value]="dataset.packID"
                groupName="'chordDatasets'"
                [label]="dataset.name"
                [description]="dataset.description"
                [selectedValue]="isSelectedChordDataset(dataset.packID)"
                [labelList]="dataset.recommendedStyles"
                [imageLabelList]="getMoodCompatibleWithHarmonicDataset(dataset)"
                (onSelect)="changeSelectedDataset(dataset)">
            </radio-button>
        </div>
    </div>
</div>
