<div class="preview" #previewElement>

    <div class="subsequences">
        <div 
            class="subsequence-item" 
            *ngFor="let subsequence of getSubsequences(); let s = index" 
            [style.width.px]="getSubsequenceWidth(subsequence, s)"
            [style.marginLeft.px]="s > 0 ? '5' : '0'"
        >
            Subsequence {{ subsequence + 1 }}
        </div>
    </div>

    <div class="envelopes">
        <div class="dropdown-container">
            <dropdown type="light" width="170px" [value]="getPreviewGraphOption()" [showTooltip]="false" [items]="previewGraphOptions" (selectedItem)="selectPreviewGraph($event)"></dropdown>
        </div>

        <canvas #envelopeCanvas></canvas>
    </div>

    <div class="segments">
        <div class="segment-item" *ngFor="let segment of preview.segments; let s = index" [style.width.px]="getSegmentWidth(segment, s)" [style.marginLeft.px]="s > 0 ? '5' : '0'">
            <div class="segment-name">
                {{ formatTitle(segment.name) }}
                <img src="assets/img/pianoroll/loop.svg" class="segment-icon" *ngIf="segment.varied">
            </div>
            
            <div class="layers-container">
                <div class="layer-block-item" *ngFor="let layer of preview.getSortedLayers(segment); let b = index">{{ layer }}</div>
            </div>
        </div>
    </div>
</div>