import { EditorViewState } from "./states/editor-view/editor-view.store"
import { EditorViewActions } from "./states/editor-view/editor-view.actions"
import { EditorViewQuery } from "./states/editor-view/editor-view.query"
import { ScoreRenderingActions } from "./states/score-rendering/score-rendering.actions"
import { ScoreRenderingQuery } from "./states/score-rendering/score-rendering.query"
import { FractionString, TimeSignature } from "../../general/types/score"
import { EditorPitch } from "../../general/types/note-editing"
import { Note } from "../../general/classes/score/note"
import { ScoreRendering } from "./states/score-rendering/score-rendering.store"
import PercussionLayer from "../../general/classes/score/percussionlayer"
import Layer from "../../general/classes/score/layer"
import TrackBus from "../../general/classes/score/trackbus"
import { RangeWithID } from "../../general/interfaces/general"
import {
    HoveringType,
    HoveringTypeEnum,
    PatternHoveringType,
} from "../../general/types/general"
import { RealtimeSamplerActions } from "./states/realtime-sampler/realtime-sampler.actions"
import {
    Coordinates,
    CoordinatesAbs,
} from "../../general/modules/event-handlers"
import PatternRegion from "../../general/classes/score/patternregion"

export type CanvasType =
    | "All"
    | "None"
    | "PianoRollNotesCanvas"
    | "AccompanimentDesignerCanvas"
    | "TempoCanvas"
    | "DrumSequencerCanvas"
    | "AutomationCanvas"
    | "PianorollGridCanvas"
    | "TimelineCanvas"
    | "PatternRegionsCanvas"
    | "PianoCanvas"
    | "LayerPreviewCanvas"
    | "TrackbusRegionsCanvas"
    | "VerticalScrollingCanvas"
    | "PatternHorizontalScrollbarCanvas"
    | "ChordsLabelCanvas"
    | "ChordsEditingCanvas"
    | "KeySignatureEditingCanvas"

export enum ScoreRenderingStateType {
    SCORE_RENDERING = "scoreRendering",
    EDITOR_VIEW = "editorView",
    REALTIME_SAMPLER = "realtimeSampler",
}

export enum KeyboardEventActions {
    ALL = "all",
    NONE = "none",
    COPY = "copy",
    CUT = "cut",
    PASTE = "paste",
    DELETE = "delete",
    SELECT_ALL = "selectAll",
    UNDO = "undo",
    REDO = "redo",
    INCREMENT_RESIZE_FACTOR = "incrementResizeFactor",
    DECREMENT_RESIZE_FACTOR = "decrementResizeFactor",
    RESET_RESIZE_FACTOR = "resetResizeFactor",
    TOGGLE_CURSOR_TYPE = "toggleCursorType",
    MOVE = "move",
    MUTE = "mute",
    SOLO = "solo",
    DUPLICATE = "DUPLICATE",
    RESET_PLAYBACK = "resetPlayback",
}

export type PitchStepDomain = "continuous" | "scale"
export type RemoveLastGutter = "fully" | "half" | "none"
export type ScoreUpdateType =
    | "All"
    | "Note"
    | "Effect"
    | "Layer"
    | "TrackBus"
    | "TrackBusMetadata"
    | "ScoreMetadata"
    | "Pattern"
    | "None"
export type ScoreRenderingStatesObject = {
    [ScoreRenderingStateType.SCORE_RENDERING]: ScoreRendering
    [ScoreRenderingStateType.EDITOR_VIEW]: EditorViewState
}

export type ScoreRenderingActionsObject = {
    [ScoreRenderingStateType.SCORE_RENDERING]: ScoreRenderingActions
    [ScoreRenderingStateType.EDITOR_VIEW]: EditorViewActions
    [ScoreRenderingStateType.REALTIME_SAMPLER]: RealtimeSamplerActions
}

export type ScoreRenderingQueriesObject = {
    [ScoreRenderingStateType.SCORE_RENDERING]: ScoreRenderingQuery
    [ScoreRenderingStateType.EDITOR_VIEW]: EditorViewQuery
}

export type Scrollbar = {
    start: number
    end: number
}

export interface KeySignatureWithTime {
    ks: string
    start: FractionString
    end: FractionString
    index: number
}

export type HoveredElement = {
    element:
        | Note
        | RangeWithID
        | Scrollbar
        | PatternRegion
        | Chord
        | KeySignatureWithTime
    draggingType: HoveringType | PatternHoveringType
    type:
        | "note"
        | "rangeWithID"
        | "scrollbar"
        | "patternRegion"
        | "chord"
        | "keySignature"
}

export type GridCoordinates = {
    timesteps: number
    ysteps: number
}

export type AllCanvasCoordinates = {
    scrollToTimestep: number
    pixels: Coordinates
    grid: GridCoordinates
}
export interface MouseDownStart {
    start: AllCanvasCoordinates
    last: AllCanvasCoordinates
    mouseDownLocation: HoveringType | PatternHoveringType
}

/**
 * The context in which note editing functionality is used.
 * This may affect functionality and dimensions for note editing
 */
export interface ScoreCanvasContext {
    canvasContainer: HTMLDivElement
    mouseActions: {
        pencil: MouseAction
        select: MouseAction
    }
    gridType?: GridType
    noteWidth?: number // the width of a single grid cell
    validPitches?: Array<EditorPitch> // pitches need to be sorted in the same way the grid displays them top to bottom
    rowPositions?: number[] // px values where each row starts in y axis
    collumnPositions?: number[] // px values where each column starts in x axis
    rowGutter?: number // used to compute the pitch,
    debugMode?: boolean // additional info will be displayed (for now only section in the note descriptor)
}

export interface PianoCanvasContext extends ScoreCanvasContext {
    pitchCount?: number
}

export interface MouseAction {
    move: boolean
    resize: boolean
    remove: boolean
    draw: boolean
    selection: boolean
}

export interface PatternHorizontalScrollbarCanvasContext
    extends ScoreCanvasContext {
    syncToCanvas: string
    allowTimestepClick: boolean
}
export interface LayerPreviewCanvasContext extends ScoreCanvasContext {
    gutterPxSize: number
    layer: Layer | PercussionLayer
}

// defines if chords in the chord sections will be rendered as roman numerals or chord symbols
export type RenderChordsType = "roman-numeral" | "chord-symbol"

export interface EditChordFunction {
    (data: EditChordData): void
}

export interface HoverChordFunction {
    (
        { element, draggingType, type }: HoveredElement,
        coordinates: Coordinates
    ): void
}

export interface ChordsEditingButtonsFunction {
    ({
        chord,
        start,
        width,
    }:
        | {
              chord: Chord
              start: number
              width: number
          }
        | undefined): void
}

export interface EditChordData extends Chord {
    coordinates?: CoordinatesAbs
    page: "edit" | "change"
}

export interface Chord {
    numeral: string
    start: string
    duration: string
    index: number
}

export interface KeySignatureEditingContext extends ScoreCanvasContext {
    editKeySignature: { (index: number): void }
}
export interface ChordsEditingContext extends ScoreCanvasContext {
    editChord: EditChordFunction
    allowChordsEditing?: boolean
    chordsEditingButtons?: ChordsEditingButtonsFunction
}

export interface AccompanimentDesignerCanvasContext extends ScoreCanvasContext {
    canvases: { idSuffix: string; zIndex: number }[]
    pitchCount?: number
}

export interface TrackbusRegionsCanvasContext extends ScoreCanvasContext {
    trackBus: TrackBus
    layer: Layer
}
export interface PianoRollNotesCanvasContext extends ScoreCanvasContext {
    noteHeight: number
    noteBorderWidth: number
}

export interface TimelineCanvasContext extends ScoreCanvasContext {
    syncToCanvas: CanvasType | string
    canvasHeight?: number
}

export interface PatternRegionsCanvasContext extends ScoreCanvasContext {
    noteBorderWidth: number
    layerInstrumentListItemHeight: number // the height of one row per instrument
    patternNameHeight: number // the height of the area where the pattern name is displayed
    borderRadius: number
    canvases: { idSuffix: string; zIndex: number }[]
}

export interface AutomationCanvasContext extends ScoreCanvasContext {
    canvases: { idSuffix: string; zIndex: number }[]
}

export interface PianorollGridCanvasContext extends ScoreCanvasContext {
    hideSections?: boolean
    hideScrollbar?: boolean
    //hideKeySignatures: boolean
}

export interface TempoCanvasContext extends ScoreCanvasContext {}

export interface DrumSequencerCanvasContext extends ScoreCanvasContext {
    trackBusID?: string
    canvases: { idSuffix: string; zIndex: number }[]
}

export type NoteDescriptor = {
    pitch: EditorPitch
    duration: string
    coordinates: {
        x: number
        y: number
    }
    section?: number
    type?: CanvasType | string
}

export type NoteSelection = {
    playingPitches: number[]
    dimensions: {
        top: number
        left: number
        width: number
        height: number
    }
    type?: CanvasType | string
}

export interface GridDimensions {
    hasChanged: boolean
    timeSignature: TimeSignature
    timestepRes: number
    quantizeRes: number
    pxLengthForGutters: number
    scoreLengthTimesteps: number
    pitchStepDomain: PitchStepDomain
    noteWidthIncrement: number
    pxPerTimestepGutter: number
    noteBorderRadius: number
    pxPerBeat: number
    pxPerHalfBeat: number
    pxPerBeatGutter: number
    pxPerHalfBeatGutter: number
    pxPerBar: number
    pxPerBarGutter: number
    notesInOneBeat: number
    beatsInOneBar: number
    halfBeatsInOneBar: number
    barsInPattern: number
    pxPerBeatsGutterInBar: number
    pxPerTimestepGutterInBeat: number
    pxPerTimestepGutterInBar: number
    pxPerTimestepWithoutGutters: number
    pxPerPitchGutter: number
    pxLength: number
    allowZoom: boolean
    type: GridType
}

export interface GridDimensionsDictionary {
    pitched: GridDimensions
    drumSequencer: GridDimensions
}

export type GridType = "pitched" | "drumSequencer"
