import { Injectable } from "@angular/core"
import Patch from "@common-lib/classes/score/patch"
import { ApiService } from "@services/api.service"
import SearchItem from "@common-lib/classes/searchitem"
import InstrumentPatch from "@common-lib/classes/score/instrumentpatch"
import { PlayerService } from "./audio/player/player.service"
import { InstrumentsService } from "./instruments/instruments.service"
import { WindowService } from "./window.service"
import { InstrumentsPreviewService } from "./instruments/instrumentsPreview.service"

@Injectable()
export class SearchInstrumentPreviewService {
    currentlyPlayingPatchID = ""

    loading = false

    previewEndTimeout

    constructor(
        private instrumentsPreview: InstrumentsPreviewService,
        private windowService: WindowService,
        private instruments: InstrumentsService,
        private playerService: PlayerService,
        private apiService: ApiService,
    ) {
        this.instruments.query
            .select("isDownloadingAllInstruments")
            .subscribe(value => {
                this.loading = value

                this.instruments.setRefreshSearchUI()
            })
    }

    async playPreview(downloadPatchesFor, event, searchItem: SearchItem) {
        /**
         * 1. get parent patch so we can download all child patches
         * 2. download patch(es)
         * 3. generate score for note to play back
         * 4. play back the score using RT sampler
         */
        if (event) {
            event.stopPropagation()
        }
        
        this.currentlyPlayingPatchID = ""

        if (searchItem.item.patchID == this.currentlyPlayingPatchID) {
            this.playerService.pause()

            this.windowService.samplerAPI.pause()

            this.instruments.setRefreshSearchUI()

            return
        }

        try {
            this.loading = true

            this.playerService.pause()

            this.currentlyPlayingPatchID = searchItem.item.patchID

            this.instruments.setRefreshSearchUI()

            let patchesToDownload: Patch[] =
                this.instruments.getAllPatches(downloadPatchesFor)
            let instrumentPatch: InstrumentPatch = searchItem.item

            await this.windowService.samplerAPI.requestPatches({
                patches: patchesToDownload,
                usedInstruments: patchesToDownload,
            })

            let middleNote = this.getInstrumentMiddleNote(
                instrumentPatch.patches[0].patch
            )

            this.instrumentsPreview.playPreviewOnRTSampler(
                middleNote,
                instrumentPatch,
                0.5
            )

            this.loading = false

            this.setEndTimeout(7)

            this.instruments.setRefreshSearchUI()


        } catch (error) {
            console.error(error)
            this.apiService.handleError(
                "There was an error while creating the preview"
            )
        }

        this.instruments.setRefreshSearchUI()
    }

    setEndTimeout(end: number) {
        if (this.previewEndTimeout != null) {
            clearTimeout(this.previewEndTimeout)
            this.previewEndTimeout = null
        }

        this.previewEndTimeout = setTimeout(() => {
            this.currentlyPlayingPatchID = ""
            this.previewEndTimeout = null

            this.instruments.setRefreshSearchUI()
        }, (end + 1) * 1000)
    }

    getInstrumentMiddleNote(patch: Patch) {
        if (patch == null) {
            return 60
        }

        let instrument = this.instruments.instruments[patch.section].find(
            i => i.name == patch.instrument
        )
        let middleNote = Math.round(
            (instrument.lowest_note + instrument.highest_note) / 2
        )

        return middleNote
    }
}
