import { Injectable } from '@angular/core'

@Injectable()
export class MessageService {
    

    get defaultMessage(){
        let msg: {type:string, text: string, show: boolean} = {type:"", text:"", show:false}
        return msg
    }

    _lastMessage: {type:string, text: string, show: boolean}
    get lastMessage(){
        return this._lastMessage
    }

    constructor() {

    }

    initMessage(message = {}){
        message["type"] = ""
        message["text"] = ""
        message["show"] = false
    }

    /**
     * 
     * @param messageObject message Object given by the component using this service
     * @param type defines what kind of message will be displayed (can be 'success', 'warning', 'error', 'info')
     * @param text the text that should be displayed inside of the message box
     */
    showMessage(messageObject: { type: string, text: string, show: boolean }, type: string = "", text: string = "") {
        if (type && text) {
            messageObject.type = type 
            messageObject.text = text
            messageObject.show = true
        }
        this._lastMessage = messageObject
    }

    onError(messageObject, text){
        this.showMessage(messageObject, "error", text)
    }

    onSuccess(messageObject, text){
        this.showMessage(messageObject, "success", text)
    }

    onInfo(messageObject, text){
        this.showMessage(messageObject, "info", text)
    }

    onWarning(messageObject, text){
        this.showMessage(messageObject, "warning", text)
    }

    hideMessage( messageObject: { type?: string, text?: string, show?: boolean }) {
        messageObject.type = '' 
        messageObject.text = ''
        messageObject.show = false
    }

}