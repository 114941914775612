import AccompanimentLayer from "../classes/generationprofiles/accompaniment/accompanimentlayer"
import AccompanimentPack from "../classes/generationprofiles/accompaniment/accompanimentpack"
import GenerationProfile from "../classes/generationprofiles/generationprofile"
import GPLayer from "../classes/generationprofiles/gplayer"
import Harmony from "../classes/generationprofiles/harmony/harmony"
import HarmonyPack from "../classes/generationprofiles/harmony/harmonypack"
import MelodyPack from "../classes/generationprofiles/melody/melodypack"
import Pack, { Synchronisation } from "../classes/generationprofiles/pack"
import InstrumentPatch from "../classes/score/instrumentpatch"
import { GP_DURATION_OPTIONS, LayerType } from "../constants/constants"
import {
    AccompanimentLayersSchema,
    GenerationProfileSchema,
} from "../interfaces/db-schemas/generationprofile"
import { Misc } from "./misc"

export module GenerationProfileManipulation {
    export function getGenerationProfileDurations(validDurations: string[]) {
        let result = []

        for (let duration of GP_DURATION_OPTIONS) {
            result.push({
                name: Misc.capitalizeFirstLetter(duration),
                value: duration,
                disabled: !showDuration(validDurations, duration),
            })
        }

        return result
    }

    export function showDuration(validDurations: string[], duration: string) {
        if (!validDurations.includes(duration)) {
            return false
        }

        return true
    }

    export function getRandomHarmonyPack(
        harmony: Harmony
    ): HarmonyPack | undefined {
        const filteredPacks = harmony.packs.filter(p =>
            p.mode.includes(harmony.keySignature.keyMode.toLowerCase())
        )

        if (filteredPacks.length === 0) {
            return undefined
        }

        const index = Misc.getRandomIntInclusive(0, filteredPacks.length - 1)

        return filteredPacks[index]
    }

    export function getPackFromLayer(
        layer: GPLayer
    ): MelodyPack | AccompanimentPack | undefined {
        if (layer.packs.length === 0) {
            return undefined
        }

        if (layer.name === LayerType.MELODY) {
            return layer.packs[0] as MelodyPack
        }

        const index = Misc.getRandomIntInclusive(0, layer.packs.length - 1)

        return layer.packs[index] as AccompanimentPack
    }

    export function getInstrumentFromPack(
        pack: Pack
    ): undefined | InstrumentPatch {
        if (pack.instruments.length === 0) {
            return undefined
        }

        const index = Misc.getRandomIntInclusive(0, pack.instruments.length - 1)

        return pack.instruments[index]
    }

    export function encodeForDB(
        gp: GenerationProfile,
        workflowGP: boolean
    ): GenerationProfileSchema {
        let layers: Array<AccompanimentLayersSchema> = []

        for (let layer of gp.accompanimentLayers) {
            layers.push(layer.encodeForDB())
        }

        const gpForDB: GenerationProfileSchema = {
            _id: gp._id,

            settings: gp.settings.encodeForDB(),
            harmony: gp.harmony.encodeForDB(),
            melodyLayer:
                gp.melodyLayer != null ? gp.melodyLayer.encodeForDB() : null,
            accompanimentLayers: layers,
            formDescription: undefined, //gp.formDescription.encodeForDB(),

            name: gp.name,
            userID: gp.userID,
            shared: gp.shared,
            createdByAiva: gp.createdByAiva,
            deleted: gp.deleted,
            liked: gp.liked,
            creationDate: gp.creationDate,
            folderID: gp.folderID,
            sourceID: gp.sourceID,
            originalSourceID: gp.originalSourceID,
            lastModified: null,
            libraryGP: gp.libraryGP,
            publishedID: gp.publishedID,
            workflowGP,
            accompanimentPackIDs: getUniqueAccompanimentPackIDs(gp),
        }

        return gpForDB
    }

    export function getUniqueAccompanimentPackIDs(
        gp: GenerationProfileSchema | GenerationProfile
    ) {
        let uniqueIDs = {}

        for (let layer of gp.accompanimentLayers) {
            for (let pack of layer.packs) {
                uniqueIDs[pack.packID] = 1
            }
        }

        return Object.keys(uniqueIDs)
    }

    export function shouldKeepPackSynced(
        gp: GenerationProfile,
        synchronisation: Synchronisation,
        syncs: { exact: string[]; subset: string[]; include: string[] }
    ): boolean {
        if (synchronisation.target === undefined) {
            return false
        }

        const packToSyncTo = gp.accompanimentLayers.find(
            l => l.name === synchronisation.target.layer
        ).packs[synchronisation.target.idx] as AccompanimentPack

        if (packToSyncTo === undefined) {
            return false
        }

        return syncs[synchronisation.type].includes(packToSyncTo.packID)
    }

    export function convertNonMelodyLayerIntoLayerType(layer: GPLayer) {
        if (layer.name.includes("Melody")) {
            throw "Invalid melody layer provided"
        }

        if (layer.name.includes("Bass")) {
            return LayerType.BASS
        }

        if (layer.name.includes("Percussion")) {
            return LayerType.PERCUSSION
        }

        if (layer.name.includes("Ornaments")) {
            return LayerType.ORNAMENTS
        }

        return LayerType.ACCOMPANIMENT
    }

    export function getPackIDsToSyncFrom(gp: GenerationProfile) {
        const result: { [layer: string]: string[] } = {}

        manipulateNonOrnamentLayers(gp, layer => {
            if (result[layer.name] === undefined) {
                result[layer.name] = []
            }

            for (const pack of layer.packs) {
                if (
                    pack.synchronisation !== undefined &&
                    pack.synchronisation.target !== null
                ) {
                    result[layer.name].push(pack.packID)
                }
            }
        })

        return result
    }

    export function getAllNonOrnamentPackIDs(gp: GenerationProfile) {
        const result: { [layer: string]: string[] } = {}

        manipulateNonOrnamentLayers(gp, layer => {
            if (result[layer.name] === undefined) {
                result[layer.name] = []
            }

            for (const pack of layer.packs) {
                result[layer.name].push(pack.packID)
            }
        })

        return result
    }

    export function manipulateNonOrnamentLayers(
        gp: GenerationProfile,
        manipulator: (layer: AccompanimentLayer) => void
    ) {
        for (const layer of gp.accompanimentLayers) {
            if (layer.name.includes("Ornaments") || layer.packs.length === 0) {
                continue
            }

            manipulator(layer)
        }
    }

    export const setFixedInstrumentation = (
        generationProfile: GenerationProfile,
        allowInstrumentationChanges: { [key: string]: boolean }
    ): GenerationProfile => {
        // Percussion layers are using fixed instrumentation by default.
        // We also set up fixed instrumentation for all pitched layers in case user
        // does not allow instrumentation changes.
        for (let layer of generationProfile.getAllLayers()) {
            if (
                layer?.type?.toLowerCase() === "percussion" ||
                allowInstrumentationChanges[layer.name] === false
            ) {
                layer.fixedInstrumentation = true
            }
        }

        return generationProfile
    }
}
