import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'
import { ApiService } from '../../services/api.service'
import { AnalyticsService } from '../../services/analytics.service'
import { PlayerService } from '../../services/audio/player/player.service'
import { CompositionService } from '../../services/composition.service'
import { FolderService } from '../../services/folder.service'

import { ActivityMetric } from '../../../../../common-lib/general/classes/activitymetric'
import { PlaylistService } from '../../services/playlist.service'
import { ParentClass } from '../../parent'
import { playerQuery } from '../../../../../common-lib/client-only/general/classes/playerStateManagement'

@Component({
 	selector: 'app-radio',
  	templateUrl: 'radio.component.html',
      changeDetection: ChangeDetectionStrategy.OnPush,
  	styles: []
})

export class RadioComponent extends ParentClass implements OnInit {
    headerSize = 100
    headerOpacity = 1

    radios = []

    selectedRadio = null

    showProgressBarCircle = false

    scrollConfig = { useBothWheelAxes: false }

    constructor(private apiService:ApiService, private analyticsService:AnalyticsService, private folderService:FolderService, private ref: ChangeDetectorRef, private playerService:PlayerService, private playlistService: PlaylistService) {
        super()

        this.subscribe(playerQuery.allState$, state => {
            if (state.composition === undefined || state.composition.contentType != "radio") {
                return 
            }

            if (this.selectedRadio != null) {
                this.selectedRadio.playing = state.status === "playing"
                this.detectChanges()   
            }
        })

    }

    detectChanges() {
        if (!this.ref['destroyed']) {
            this.ref.detectChanges()
        }
    }
    
    ngOnInit() {
        this.apiService.authRequest('/streaming/getRadios', { }, "primary", true).then((res) => {
            this.radios = res.radios

            for (var r = 0; r < this.radios.length; r++) {
                this.radios[r]['radioImageIndex'] = this.randomIntInRange(1, 2)
                this.radios[r]['backgroundPosition'] = this.getRandomBackgroundPosition(r, this.radios[r]['radioImageIndex'])
            }

            this.detectChanges()
        })

        .catch((err) => {
            this.apiService.handleError(err)
        })
    }

    playRadio(radio) {
        if (this.selectedRadio != null && this.selectedRadio._id == radio._id) {
            if (this.selectedRadio.playing) {
                this.playerService.pause()
            }

            else {
                this.playerService.play()
            }
            
            return
        }

        for (var r = 0; r < this.radios.length; r++) {
            this.radios[r].playing = false
        }

        this.selectedRadio = radio
        this.selectedRadio.playing = true

        this.folderService.fetchRadioContent(radio).then(() => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    this.playerService.loadNextTrack({
                        index: 0, play: true
                    })
                    this.playlistService.setSelectedPlaylist(null)  // TODO: find a better way to set the playlist to null whenever we switch views
                    resolve(null)
                }, 100)
            })
        })

        .then(() => {
            this.analyticsService.addActivity(ActivityMetric.SELECT_RADIO, {
                radioID: this.selectedRadio._id
            })
        })

        .catch((err) => {
            this.apiService.handleError(err)
        })
    }

    getRandomBackgroundPosition(r, radioImageIndex) {
        return r * 25 / radioImageIndex + "% " + r * 25 / radioImageIndex + "%"
    }

    randomIntInRange(min, max) {
        return Math.round(Math.random() * (max - min) + min)
    }
}