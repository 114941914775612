import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { KeyValue } from "@angular/common"
import { ModalService } from "@services/modal.service"
import { ContextMenu } from "../../../../models/contextmenu"

@Component({
    selector: "find-similar-instruments-filter",
    templateUrl: "find-similar-instruments-filter.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["../context-menus.component.scss"],
})
export class FindSimilarInstrumentsFilterComponent implements OnInit {
    @Input() data

    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor(
        protected ref: ChangeDetectorRef,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {}

    changeSelectedFilters(event, filterType, key) {
        if (
            this.data == null ||
            this.data.filter == null ||
            this.data.filter[filterType] == null
        ) {
            return
        }

        this.data.filter[filterType].data[key] = event.checked
        this.data.changes = {
            key: filterType,
            value: key,
            checked: event.checked,
        }

        const contextMenu =
            this.modalService.contextMenus.findSimilarInstrumentsFilter.getValue()

        this.modalService.contextMenus.findSimilarInstrumentsFilter.next(
            new ContextMenu(contextMenu.x, contextMenu.y, this.data, [], null)
        )
    }

    resetFilter() {
        if (this.data == null || this.data.filter == null) {
            return
        }

        for (let filterCategory in this.data.filter) {
            for (let key in this.data.filter[filterCategory].data) {
                this.data.filter[filterCategory].data[key] = false
            }
        }

        // TODO: reset filter should reset to the default layout

        const contextMenu =
            this.modalService.contextMenus.findSimilarInstrumentsFilter.getValue()

        this.modalService.contextMenus.findSimilarInstrumentsFilter.next(
            new ContextMenu(contextMenu.x, contextMenu.y, this.data, [], null)
        )
    }

    closeContextMenu() {
        this.close.emit()
    }
}
