import { LabelType, Options } from "@angular-slider/ngx-slider"
import {
    Component,
    Output,
    EventEmitter,
    Input,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from "@angular/core"
import {
    ModalService,
    SectionInpaintingModalInput,
} from "@services/modal.service"
import { DeviceDetectorService } from "ngx-device-detector"
import { SRActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { ParentClass } from "../../../parent"
import {
    DropdownItemType,
    DropdownSelection,
} from "../../../types/dropdownItemType"
import Section from "@common-lib/classes/score/section"
import { FolderService } from "@services/folder.service"
import { PlayerService } from "@services/audio/player/player.service"
import { Composition } from "@common-lib/classes/general/composition"
import { environment } from "@environments/environment"
@Component({
    selector: "section-inpainting",
    templateUrl: "section-inpainting.component.html",
    styleUrls: ["section-inpainting.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionInpaintingModal extends ParentClass {
    @Input() input: SectionInpaintingModalInput
    @Output() close: EventEmitter<any> = new EventEmitter()

    public changeSelectStyle: boolean = false
    public sectionLength: number = 8
    public emptySection: boolean = false
    public copySection: boolean = false

    public loading: boolean = false

    private controller: AbortController | undefined
    public sourceSectionIndex: DropdownItemType<number> | undefined
    public filteredSections: DropdownItemType<number>[] = []
    public composition: Composition
    public get enableSectionInpainting(): boolean {
        return !this.isMIDI && !this.disableFirstOrLastSectionInpainting
    }
    public get isMIDI(): boolean {
        return this.composition?.fromMIDI === true
    }
    public get disableFirstOrLastSectionInpainting(): boolean {
        const sectionLength = this.input.engine.score?.sections?.length

        // Adds an exception when there is only one section
        // in the whole composition.
        if (sectionLength === 1) {
            return false
        }

        return (
            (this.input.section.index === 0 &&
                this.input.position === "before") ||
            (this.input.section.index === sectionLength - 1 &&
                this.input.position === "after")
        )
    }
    public get disableSectionInpaintingMessage(): string {
        if (this.enableSectionInpainting) {
            return ""
        }

        if (this.isMIDI) {
            return "We currently don't support section inpainting for compositions created from MIDI."
        } else {
            return "Section inpainting before the very first and after the very last section is not supported."
        }
    }

    public tooltips = {
        sourceSection:
            "Selecting a source section allows our AI model to use it as a reference when creating a new section. The musical content of the chosen source section will serve as the foundation for your new section.\n\n\nIf no source section is chosen, the AI will generate a new section using the overall musical content of your composition as a reference.",
    }

    constructor(
        private modalService: ModalService,
        private device: DeviceDetectorService,
        private instruments: InstrumentsService,
        private folderService: FolderService,
        private player: PlayerService,
        private ref: ChangeDetectorRef
    ) {
        super()

        this.subscribe(this.modalService.modals.sectionInpainting, data => {
            if (data === undefined && this.controller !== undefined) {
                this.controller.abort()
            }
        })

        // select options were invisible because of a lack of contrast
        this.changeSelectStyle =
            this.device.os == "Linux" || this.device.os == "Windows"
    }

    async ngOnInit() {
        this.filteredSections = this.filterSectionsForSectionInsert(
            this.input.engine.score.sections.filter(s => !s.inserted)
        )

        try {
            this.composition = await this.folderService.getContentByID(
                this.player.getCompositionID(),
                "composition"
            )

            if (this.enableSectionInpainting === false) {
                this.setEmptySection(true)
            }
        } catch (error) {
            console.error(error)
        }
    }

    public getLengthOptions(): Options {
        return {
            animate: false,
            showTicksValues: false,
            stepsArray: [
                { value: 2, legend: "2" },
                { value: 4, legend: "4" },
                { value: 8, legend: "8" },
            ],
        }
    }

    public getSimilarityOptions(): Options {
        return {
            floor: 0,
            ceil: 2,
            step: 1,
            animate: false,
            translate: (value: number, label: LabelType): string => {
                if (label == LabelType.Floor || value === 0) {
                    return "low"
                } else if (label == LabelType.Ceil || value === 2) {
                    return "high"
                }

                return ""
            },
        }
    }

    public setCopySection(value) {
        this.copySection = value
        this.ref.detectChanges()
    }

    public setEmptySection(value) {
        this.emptySection = value

        if (this.enableSectionInpainting === false) {
            this.emptySection = true
        }

        if (this.emptySection) {
            this.copySection = false
        }

        this.ref.detectChanges()
    }

    public async apply() {
        if (this.emptySection) {
            await new Promise(resolve => {
                this.input.engine.srEmitter$.next({
                    type: SRActionTypes.addSection,
                    data: {
                        type: "blank",
                        section: this.input.section,
                        insertPosition: this.input.position,
                        sectionLength: this.sectionLength,
                        sourceSectionIndex: this.input.section.index,
                        resolve,
                    },
                    options: {
                        isUndoable: true,
                    },
                })
            })
        } else if (this.copySection) {
            this.setLoading(true)

            const result = await new Promise(resolve => {
                this.input.engine.srEmitter$.next({
                    type: SRActionTypes.sectionCopy,
                    data: {
                        section: this.input.section,
                        insertPosition: this.input.position,
                        instruments: this.instruments.instruments,
                        samplesMap: this.instruments.drumSamples,
                        sourceSectionIndex: this.sourceSectionIndex?.value,
                        resolve,
                    },
                    options: {
                        isUndoable: true,
                    },
                })
            })

            this.setLoading(false)
        } else {
            this.setLoading(true)

            this.controller = new AbortController()

            // Make sure we do not pass an index if "None" is selected
            const sourceSectionIndex =
                this.sourceSectionIndex?.value !== undefined &&
                this.sourceSectionIndex?.value !== -1
                    ? this.sourceSectionIndex?.value
                    : undefined

            const result = await new Promise(resolve => {
                this.input.engine.srEmitter$.next({
                    type: SRActionTypes.sectionInpainting,
                    data: {
                        section: this.input.section,
                        insertPosition: this.input.position,
                        sectionLength: this.sectionLength,
                        instruments: this.instruments.instruments,
                        samplesMap: this.instruments.drumSamples,
                        sourceSectionIndex: sourceSectionIndex,
                        inpaintSection: this.input.inpaintSection.bind(this),
                        getSourceSectionChords:
                            this.input.getSourceSectionChords.bind(this),
                        abort: this.controller,
                        resolve,
                    },
                    options: {
                        isUndoable: true,
                    },
                })
            })

            this.controller = undefined

            this.setLoading(false)
        }

        this.input.onComplete()
    }

    private setLoading(value: boolean) {
        this.loading = value
        this.ref.detectChanges()
    }

    public readonly dotsArray = new Array(400)

    public selectSourceSection(event: DropdownSelection<number>) {
        this.sourceSectionIndex = event.new

        if (this.sourceSectionIndex === undefined) {
            this.copySection = false
        }
    }

    filterSectionsForSectionInsert(
        nonFilteredSections: Section[]
    ): DropdownItemType<number>[] {
        let sections = []

        sections.push({
            value: -1,
            name: "None",
        })

        sections = sections.concat(
            nonFilteredSections.map(s => {
                return {
                    value: s.index,
                    name: s.title,
                }
            })
        )

        return sections
    }
}
