<div class="modal-title">Set layer priorities</div>
<div class="modal-close"><img src="assets/img/close.svg" (click)="closeModal()"></div>

<div class="layers-order-container" [perfectScrollbar]="scrollConfig">
    <div cdkDropList cdkDropListOrientation="horizontal" *ngFor="let order of getLayersOrder(); let o = index" class="layers-order" (cdkDropListDropped)="drop(o, $event)">
        <div *ngFor="let layer of order" class="layer-tag" cdkDrag [ngStyle]="{'background-color': getColorForLayer(layer)}">{{ layer }}</div>

        <img src="assets/img/delete.svg" (click)="deleteLayersOrderRow(o)" *ngIf="getLayersOrder().length > 1">
    </div>
</div>

<a-button *ngIf="getLayersOrder().length < 25" class="add-layer-tile-small-button" [icon]="'assets/img/add.svg'" type="round-icon-and-text" width="100%" label="Add layers order" (onClick)="addLayersOrder()"></a-button>