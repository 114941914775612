import { Component, ElementRef, AfterViewInit, ChangeDetectorRef } from "@angular/core"
import { WindowService } from "@services/window.service"

@Component({
    selector: 'sample-mapper',
    templateUrl: 'sample-mapper.component.html',
    styleUrls: ['sample-mapper.component.scss']
})

export class SampleMapperComponent {
    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }

    pathLoading = false
    instrumentPath = ""
    error = ""

    pitchMap = []

    steps = [
        {
            index: 1,
            subtitle: "Directory Init"
        },

        {
            index: 2,
            subtitle: "Sample map"
        },

        {
            index: 3,
            subtitle: "Done!"
        }
    ]

    currentStepIndex = 1

    constructor(private windowService:WindowService) {
        
    }

    anotherOne() {
        location.reload()
    }

    async selectPath() {
        if (this.windowService.desktopAppAPI === undefined) {
            alert("You must use the desktop app")

            return
        }

        this.error = ''
        this.instrumentPath = ''
        this.pathLoading = true
        this.pitchMap = []
        
        try {
            let data = await this.windowService.potionAPI.getPitchesForRemappingFromDirectory()
            this.instrumentPath = data.instrumentPath

            for (let pitch of data.pitches) {
                this.pitchMap.push({
                    current: pitch,
                    new: pitch
                })
            }
        }

        catch(e) {
            this.error = e
        }

        this.pathLoading = false
    }

    async goToStep(stepIndex) {
        if (stepIndex == 3) {
            this.error = ""

            if (this.pitchMapHasCollisions()) {
                this.error = "First, make sure that no collisions happen (mapping to the same new pitch)"

                return
            }

            await this.windowService.potionAPI.remapSamples(this.pitchMap)
        }

        this.currentStepIndex = stepIndex
    }

    pitchMapHasCollisions() {
        let seenPitches = []

        for (let pitch of this.pitchMap) {
            if (seenPitches.includes(pitch.new)) {
                return true
            }

            seenPitches.push(pitch.new)
        }
    
        return false
    }
}