import { LabelType } from "@angular-slider/ngx-slider"
import {
    Component,
    EventEmitter,
    ChangeDetectionStrategy,
    Output,
} from "@angular/core"
import { Misc } from "@common-lib/modules/misc"
import { GenerationProfileService } from "../../../services/generation-profile/generationprofile.service"
import { SourcePackService } from "../../../services/source-packs/sourcepacks.service"

@Component({
    selector: "material-variations",
    templateUrl: "material-variations.component.html",
    styleUrls: ["./material-variations.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialVariationsModalComponent {
    enabledLayers = []
    enabledCategories = []

    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

    show = ""

    constructor(
        private sourcePacks: SourcePackService,
        private gpService: GenerationProfileService
    ) {}

    ngOnInit() {}

    closeModal() {
        this.close.emit()
    }

    getStrategy() {
        return this.getMaterialVariations().strategy
    }

    async getStrategyOptions() {
        const formDescriptionPacks =
            await this.sourcePacks.getFormDescriptionPacks()

        let options = formDescriptionPacks.materialVariationStrategy.map(
            strategy => {
                return {
                    value: strategy.pack_id,
                    name: strategy.name,
                }
            }
        )

        return options
    }

    getLikelihoodOptions() {
        let options = {
            floor: 0,
            step: 0.25,
            ceil: 1,
            animate: false,
            translate: (value: number, label: LabelType): string => {
                if (label == LabelType.Floor) {
                    return "0%"
                } else if (label == LabelType.Ceil) {
                    return "100%"
                }

                return ""
            },
        }

        return options
    }

    getFormDescription() {
        return this.gpService.generationProfile.formDescription
    }

    getMaterialVariations() {
        return this.getFormDescription().variationSettings.materialVariations
    }

    getLikelihood() {
        return this.getMaterialVariations().likelihood
    }

    setVariationLikelihood(event) {
        this.gpService.generationProfile.formDescription.variationSettings.materialVariations.likelihood =
            event.value

        this.setGPAsUpdated("materialVariationsLikelihood")
    }

    selectStrategy(event) {
        this.getMaterialVariations().strategy = event.new

        this.setGPAsUpdated("materialVariationsStrategy")
    }

    async setGPAsUpdated(type) {
        await this.gpService.setAsUpdated(type)
    }

    getLayers() {
        const layers =
            this.gpService.generationProfile.formDescription.variationSettings
                .materialVariations.layers

        return layers
    }

    formatTitle(value) {
        return Misc.capitalizeFirstLetter(Misc.replaceAll(value, "_", " "))
    }

    getCategories() {
        const categories =
            this.gpService.generationProfile.formDescription.variationSettings
                .materialVariations.categories

        return categories
    }

    toggleSettingsRow(value) {
        if (this.show == value) {
            this.show = ""
        } else {
            this.show = value
        }
    }
}
