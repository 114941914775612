import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from "@angular/core"
import { DesignService } from "@services/design.service"
import GenerationProfile from "@common-lib/classes/generationprofiles/generationprofile"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { Misc } from "@common-lib/modules/misc"
import { ApiService } from "@services/api.service"

@Component({
    selector: "publish-gp",
    templateUrl: "publish-gp.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishGPComponent implements OnInit {
    tooltips = {
        name: "Enter a name that best describes what this generation profile is about. This name will be visible to other users, so choose wisely!",
        styleMetadata:
            "This tag will help categorize your profile in the library",
        preview: "",
    }

    steps = [
        {
            index: 1,
            subtitle: "Add metadata to your profile",
        },

        {
            index: 2,
            subtitle: "Preview creation",
        },

        {
            index: 3,
            subtitle: "Publish for review",
        },
    ]

    previewLoading = false
    publishLoading = false

    error = ""

    step = 1

    metadata = {
        style: "",
        name: "",
    }

    @Output() close: EventEmitter<any> = new EventEmitter()
    @Input() gp: GenerationProfile

    constructor(
        private apiService: ApiService,
        private ref: ChangeDetectorRef,
        private gpService: GenerationProfileService
    ) {}

    ngOnInit(): void {
        this.metadata.name = this.gp.name
    }

    getTutorialMode() {
        return this.gpService.tutorialMode
    }

    getStyles() {
        let styles = []

        for (let style of this.gpService.gpLibraryStyles) {
            styles.push({
                value: style,
                name: Misc.capitalizeFirstLetter(style),
            })
        }

        return styles
    }

    getSelectedStyle() {
        return {
            value: this.metadata.style,
            name: Misc.capitalizeFirstLetter(this.metadata.style),
        }
    }

    selectStyle(event) {
        this.metadata.style = event.new.value
    }

    closeModal() {
        this.close.emit()
    }

    selectPreview(event) {}

    selectedPreview() {
        return {
            value: "",
            name: "",
        }
    }

    getElligibleCompositionsForPreview() {
        return []
    }

    async goToStep(step) {
        try {
            this.error = ""

            if (step == 1) {
                this.step = 1
                this.detectChanges()
            } else if (step == 2) {
                this.publishLoading = true

                await this.publish()

                this.publishLoading = false

                this.step = 2
                this.detectChanges()
            }
        } catch (err) {
            this.step = 1
            this.error = err

            this.publishLoading = false

            this.detectChanges()
        }
    }

    detectChanges() {
        return this.ref.detectChanges()
    }

    async publish() {
        await this.apiService.authRequest(
            "/generationprofile/publish/confirmPublication",
            { generationProfileID: this.gp._id, metadata: this.metadata },
            "primary",
            false
        )
    }
}
