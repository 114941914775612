<div class="modal-title">{{ title }}</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>

<div class="publish-gp">
    <div class="step-container step-3">
        <div class="checkmark-icon">
            <svg
                class="checkmark-green"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52">
                <circle
                    class="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none" />
                <path
                    class="checkmark__check"
                    fill="transparent"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>

            {{ subtitle }}

            <div class="note-about-publication">
                {{ note }}
            </div>
        </div>
    </div>
</div>
