import { Injectable, EventEmitter } from "@angular/core"
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs"
import { Router } from "@angular/router"
import { FolderService } from "../../folder.service"
import { AnalyticsService } from "../../analytics.service"
import { Composition } from "../../../../../../common-lib/general/classes/general/composition"
import { StreamingService } from "../streaming.service"
import { ActivityMetric } from "../../../../../../common-lib/general/classes/activitymetric"
import { ModalService } from "@services/modal.service"
import { ParentClass } from "../../../parent"
import { WindowService } from "@services/window.service"
import { PreviewsService } from "../previews.service"
import { PlayerHttpService } from "./player.http.service"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import { RTSamplerActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/realtime-sampler/realtime-sampler.actions"
import { ConfirmDiscardChangesService } from "@services/confirm-discard-changes.service"
import { SRActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { UserService } from "@services/user.service"
import { TokenService } from "@services/token.service"
import { ApiService } from "@services/api.service"
import { CompositionDoneCreating } from "@common-lib/interfaces/api/sockets"
import { Misc } from "@common-lib/modules/misc"
import {
    PlayerPreview,
    PlayerState,
    PlayerStore,
    playerActions,
    playerQuery,
    playerStore,
} from "../../../../../../common-lib/client-only/general/classes/playerStateManagement"
import { Time } from "@common-lib/modules/time"
import { TIMESTEP_RES } from "@common-lib/constants/constants"

@Injectable()
export class PlayerService extends ParentClass {
    public navigate$: BehaviorSubject<string | undefined> = new BehaviorSubject<
        string | undefined
    >(undefined)

    // Public variables
    public autoScrollPianoRoll: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false)
    public trackEndedEE: EventEmitter<object> = new EventEmitter()

    public gpPreviews$ = this.previewService.gpPreviews

    // Private variables
    private engine: Readonly<ScoreRenderingEngine> | undefined

    constructor(
        private modalService: ModalService,
        private analyticsService: AnalyticsService,
        private streamingService: StreamingService,
        private folderService: FolderService,
        private api: ApiService,
        private router: Router,
        private windowService: WindowService,
        private token: TokenService,
        private userService: UserService,
        private previewService: PreviewsService,
        private playerHttpService: PlayerHttpService,
        private confirm: ConfirmDiscardChangesService
    ) {
        super()

        this.subscribe(this.api.socket, socket => {
            if (socket == null) {
                return
            }

            socket.on("doneCreating", (data: CompositionDoneCreating) => {
                if (this.getCompositionID() === data.compositionID) {
                    return this.loadNewTrack(
                        data.compositionID,
                        "composition",
                        playerQuery.status === "playing",
                        true,
                        "doneCreating"
                    )
                } else {
                    this.removeFromCache(data.compositionID)
                }
            })
        })

        this.subscribe(playerQuery.select("playbackError"), async error => {
            console.error(error)
            if (
                error !== undefined &&
                playerQuery.playbackType === "realtime" &&
                this.engine !== undefined
            ) {
                this.engine.srEmitter$.next({
                    type: SRActionTypes.startRealtimeSampler,
                    data: {
                        userID: this.token.userID,
                        settings: this.userService.settings,
                    },
                })
            }
        })

        this.subscribe(this.folderService.content, async compositions => {
            const getComposition =
                playerQuery.content === undefined &&
                compositions.length > 0 &&
                !this.router.url.includes("/editor")

            if (getComposition) {
                await this.getFirstPlayableComposition(compositions)
            }
        })

        this.subscribe(playerQuery.select("pause"), async value => {
            if (value === undefined) {
                return
            }

            await this.pause()
        })

        this.subscribe(playerQuery.select("play"), async value => {
            if (value === undefined) {
                return
            }

            await this.play()
        })

        this.subscribe(playerQuery.select("restartPlayback"), async value => {
            if (value === undefined) {
                return
            }

            await this.pause()
            await this.play()
        })

        this.subscribe(this.streamingService.trackEndedEE, async data => {
            // in playlist or public player
            if (!this.router.url.includes("/editor")) {
                this.loadNextTrack({
                    index: 0,
                    play: data.shouldPlay,
                })
            }

            // in piano roll editor
            else if (playerQuery.status === "playing") {
                await this.stop()
            }
        })

        this.trackEndedEE = this.streamingService.trackEndedEE
    }

    /**
     * This function will enable real-time audio playback, implemented by the score rendering engine
     * passed as a function argument
     * @param engine
     */
    public async setRealtimePlayback(engine: ScoreRenderingEngine) {
        this.engine = engine

        if (playerQuery.playbackType === "realtime") {
            return
        }

        const status = playerQuery.status === "playing"

        if (status) {
            await this.pause()
        }

        playerActions.setPlaybackType("setRealtimePlayback", "realtime")

        if (status) {
            await this.play()
        }
    }

    /**
     * This function will disable real-time audio playback, and instead rely on the audio file streamed
     * from the Creators API
     */
    public setOfflinePlayback(origin: string) {
        if (this.engine !== undefined) {
            this.engine.realtimeSampler$.next({
                type: RTSamplerActionTypes.end,
                data: {
                    origin: "setOfflinePlayback",
                },
            })

            this.engine = undefined
        }

        playerActions.setPlaybackType(
            origin + " - setOfflinePlayback",
            "offline"
        )
    }

    // Public methods
    public async switchToPianoRoll(compositionID, type, play: boolean) {
        var composition

        if (compositionID == null) {
            return Promise.reject(
                "You don't have any tracks to see in the Piano Roll Editor"
            )
        } else if (compositionID != this.getCompositionID()) {
            await this.loadNewTrack(
                compositionID,
                type,
                play,
                false,
                "switchToPianoRoll"
            )
        }

        composition = playerQuery.content

        if (composition == null) {
            return
        }

        this.router.navigate(["/editor", compositionID, type])

        this.analyticsService.addActivity(ActivityMetric.SWITCH_TO_PIANOROLL, {
            compositionID: compositionID,
        })
    }

    public getCompositionID(): string | undefined {
        return playerQuery.contentID
    }

    public isPlaying() {
        return playerQuery.status === "playing"
    }

    public async play() {
        this.previewService.pausePreviews()

        if (
            playerQuery.content === undefined ||
            !playerQuery.content.isFinished
        ) {
            return
        }

        if (playerQuery.playbackType === "realtime") {
            if (this.windowService.desktopAppAPI !== undefined) {
                await this.streamingService.pause()

                if (playerQuery.trackBusLoadingPercentage < 50) {
                    this.modalService.modals.instrumentDownloading.next(true)
                    return
                }

                playerActions.setPlayTime("play", new Date().getTime())

                this.realtimePlay()
            } else {
                this.modalService.downloadDesktopAppModal.next(true)
            }
        } else {
            this.realtimePause()

            playerActions.setPlayTime("play", new Date().getTime())

            await this.streamingService.play()
        }

        if (!this.streamingService.publicPlayerMode) {
            this.autoScrollPianoRoll.next(true)

            await this.setAsPlayed(playerQuery.contentID)
        }
    }

    public async initialisePlayerWithPreview(
        engine: ScoreRenderingEngine,
        id: string,
        name: string
    ) {
        const scoreLength = engine.score.scoreLength
        const timesteps = Time.fractionToTimesteps(TIMESTEP_RES, scoreLength)

        await this.loadPlayerPreview({
            _id: id,
            contentType: "playerPreview",
            hasStartOffset: false,
            name: name,
            duration: Time.convertTimestepsInSeconds(
                TIMESTEP_RES,
                engine.score.tempoMap,
                timesteps,
                false
            ),
            isFinished: true,
        })

        await ParentClass.waitUntilTrueForObservable(
            playerQuery.select(),
            (playerStoreValue: PlayerState) => {
                return playerStoreValue.content._id === id
            }
        )

        await this.setRealtimePlayback(engine)
    }

    public async pause() {
        if (playerQuery.content === undefined) {
            return
        }

        this.previewService.pausePreviews()

        if (playerQuery.playbackType === "realtime") {
            this.realtimePause()
        } else {
            await this.streamingService.pause()
        }

        playerActions.setStatus("PlayerService.pause", "paused")

        this.captureSessionAnalytics()
    }

    public async stop() {
        await this.pause()
        await this.setTime(0)

        this.realtimePause()
        await this.streamingService.stop()
    }

    public hasStartOffset() {
        return playerActions.hasStartOffset()
    }

    public getRealTimeSampler() {
        return playerQuery.playbackType === "realtime"
    }

    public async loadPlayerPreview(playerPreview: PlayerPreview) {
        await this.pause()

        playerActions.loadContent(
            "PlayerService.loadPlayerPreview",
            playerPreview,
            "paused"
        )
    }

    public async loadNewTrack(
        compositionID: string,
        type: string,
        play: boolean,
        force: boolean,
        origin?: string
    ) {
        const composition: Composition = await this.getCompositionByID(
            compositionID,
            type
        )

        if (!this.publicPlayerMode && play) {
            this.setAsPlayed(composition._id)
        }

        this.analyticsService.sendCompositionUpdate()

        await this.pause()

        if (composition.isFinished === false) {
            return
        }

        playerActions.loadContent(
            "PlayerService.loadNewTrack",
            composition,
            "loading"
        )

        //this.setOfflinePlayback(origin + " - " + "loadNewTrack")

        return this.streamingService.loadNewTrack(
            "PlayerService.loadNewTrack",
            composition,
            play,
            force
        )
    }

    public async loadNextTrack(args: { index?: number; play?: boolean }) {
        if (args.play === undefined) {
            args.play = playerQuery.status === "playing"
        }

        if (playerQuery.content !== undefined) {
            this.analyticsService.addCompositionMetric(
                playerQuery.content,
                {
                    time: new Date().getTime(),
                    type:
                        playerQuery.playbackType === "realtime"
                            ? AnalyticsService.EDIT_MODE
                            : AnalyticsService.LISTENING_MODE,
                },

                "skips"
            )
        }

        const compositionID = this.getCompositionID()
        const compositions = this.folderService.content.getValue()
        const compositionIndex =
            this.folderService.getContentIndex(compositionID)

        if (compositionIndex + 1 >= compositions.length) {
            await this.stop()

            playerActions.setStatus("PlayerService.loadNextTrack", "loading")

            return this.getFirstPlayableComposition(compositions)
        }

        let compIndex = (args.index ? args.index : compositionIndex) + 1

        await this.pause()

        let selectedComposition = undefined

        while (compIndex < compositions.length) {
            const newComposition =
                this.folderService.getContentAtIndex(compIndex)

            if (newComposition?.isFinished) {
                selectedComposition = newComposition

                break
            }

            compIndex++
        }

        if (selectedComposition === undefined) {
            return
        }

        return this.launchLoading(selectedComposition, args.play)
    }

    public async launchLoading(
        selectedComposition: Composition,
        play: boolean
    ) {
        const executeFunction = (async () => {
            if (this.router.url.includes("editor")) {
                this.navigate$.next(selectedComposition._id)
            }

            try {
                await Misc.wait(0.1)

                await this.loadNewTrack(
                    selectedComposition._id,
                    selectedComposition.contentType,
                    play,
                    false,
                    "loadNextTrack"
                )

                return {
                    success: true,
                }
            } catch (e) {
                console.log("error loading track", e)

                return {
                    success: false,
                    error: e,
                }
            }
        }).bind(this)

        const componentType = this.router.url.includes("editor")
            ? "editor"
            : "accompaniment designer"

        const dataType = this.router.url.includes("editor")
            ? "composition"
            : "pack"

        const title = "Leaving " + componentType

        const description =
            "Leaving the " +
            componentType +
            " will discard any changes made to your " +
            dataType +
            ". Are you sure you want to do that?"

        if (playerQuery.playbackType === "realtime") {
            return this.confirm.openDoActionsBeforeModal({
                title,
                description,
                action: (() => {
                    return executeFunction(selectedComposition, play)
                }).bind(this),
            })
        } else {
            return executeFunction(selectedComposition, play)
        }
    }

    public async loadPreviousTrack() {
        const compositionID = this.getCompositionID()
        const compositions = this.folderService.content.getValue()
        const compositionIndex =
            this.folderService.getContentIndex(compositionID)
        const composition =
            this.folderService.getContentAtIndex(compositionIndex)

        if (
            playerQuery.timeElapsed > 2 ||
            compositions.length == 0 ||
            compositionIndex - 1 < 0
        ) {
            this.setTimeFromSeconds(0)
        } else {
            this.analyticsService.addCompositionMetric(
                composition,
                {
                    time: new Date().getTime(),
                    type:
                        playerQuery.playbackType === "realtime"
                            ? AnalyticsService.EDIT_MODE
                            : AnalyticsService.LISTENING_MODE,
                },
                "skips"
            )

            if (compositionIndex - 1 < 0) {
                return this.getFirstPlayableComposition(compositions)
            }

            let index = compositionIndex - 1

            const isPlaying = playerQuery.status === "playing"

            await this.pause()

            while (index >= 0) {
                const newComposition =
                    this.folderService.getContentAtIndex(index)

                if (newComposition.isFinished) {
                    return this.launchLoading(newComposition, isPlaying)
                }

                index--
            }

            await this.setTimeFromSeconds(0)
        }
    }

    public setTime(percentage) {
        var composition = playerQuery.content

        if (composition == null) {
            return
        }

        const to = composition.duration * percentage

        this.setTimeFromSeconds(to)
    }

    public setVolumeControl(volume) {
        volume = Math.max(0, Math.min(100, volume / 100))

        this.streamingService.setVolumeControl(volume)
        this.realtimeSetVolume(volume)
    }

    public setTimeFromSeconds(to) {
        if (playerQuery.content === undefined) {
            return
        }

        const from = playerQuery.timeElapsed

        if (playerQuery.playbackType === "realtime") {
            this.realtimeSetTime(to)
        } else {
            this.streamingService.seek(to)
        }

        if (to > 0) {
            const composition = playerQuery.content

            if (composition == null) {
                return
            }

            this.analyticsService.addCompositionMetric(
                composition,
                {
                    time: new Date().getTime(),
                    from: from,
                    to: to,
                    type:
                        playerQuery.playbackType === "realtime"
                            ? AnalyticsService.EDIT_MODE
                            : AnalyticsService.LISTENING_MODE,
                },
                "seeks"
            )
        }
    }

    public removeFromCache(compositionID) {
        return this.streamingService.removeFromCache(compositionID)
    }

    public async resetTrack(isFinished = false) {
        await this.pause()

        const composition = playerQuery.content

        if (composition !== undefined) {
            composition.isFinished = isFinished

            playerActions.loadContent("resetTrack", composition, "paused")

            if (!this.publicPlayerMode) {
                this.analyticsService.sendCompositionUpdate()
            }
        }
    }

    public playerIsLoadingComposition(compositionID) {
        const composition = playerQuery.content

        return (
            compositionID == composition?._id && playerQuery.status == "loading"
        )
    }

    public isPlayingComposition(comp: Composition): boolean {
        const composition = playerQuery.content

        return comp._id == composition?._id && playerQuery.status == "loading"
    }

    public get publicPlayerMode() {
        return this.streamingService.publicPlayerMode
    }

    public loadGPPreview(id, type: "gp" | "category") {
        return this.previewService.loadGPPreview(id, type)
    }

    public getTimeElapsed(): number {
        return playerQuery.timeElapsed
    }

    // Private Methods

    private getFirstPlayableComposition(compositions) {
        for (var i = 0; i < compositions.length; i++) {
            const composition = compositions[i]

            if (composition.isFinished) {
                return this.loadNewTrack(
                    this.folderService.getContentAtIndex(i)._id,
                    composition.contentType,
                    false,
                    false,
                    "getFirstPlayableComposition"
                )
            }
        }
    }

    private getCompositionByID(compositionID, type) {
        let getCompositionPromise

        if (!this.streamingService.publicPlayerMode) {
            getCompositionPromise = this.folderService.getContentByID(
                compositionID,
                type
            )
        } else {
            getCompositionPromise =
                this.folderService.getPublicCompositionByID(compositionID)
        }

        return getCompositionPromise.then(c => {
            let composition = c

            if (composition == null) {
                composition =
                    playerQuery.content === undefined ||
                    playerQuery.contentID !== compositionID
                        ? null
                        : playerQuery.content
            }

            return Promise.resolve(composition)
        })
    }

    private captureSessionAnalytics() {
        if (
            playerQuery.content === undefined ||
            playerQuery.playTime === undefined
        ) {
            return
        }

        const realTimeSampler = playerQuery.playbackType === "realtime"

        this.analyticsService.addCompositionMetric(
            playerQuery.content,
            {
                type: realTimeSampler
                    ? AnalyticsService.EDIT_MODE
                    : AnalyticsService.LISTENING_MODE,
                playTime: playerQuery.playTime,
                pauseTime: Date.now(),
            },
            "sessions"
        )

        playerActions.setPlayTime("captureSessionAnalytics", undefined)
    }

    private async setAsPlayed(compositionID: string) {
        const compositions = this.folderService.content.getValue()
        const index = this.folderService.getContentIndex(compositionID)

        if (
            compositions[index] == null ||
            compositions[index].wasPlayed == true
        ) {
            return
        }

        if (
            !compositions[index].isInfluence &&
            compositions[index].contentType == "composition"
        ) {
            compositions[index].wasPlayed = true

            this.folderService.content.next(compositions)

            await this.playerHttpService.setAsPlayed(compositionID)
        }
    }

    private realtimePlay() {
        if (this.engine === undefined) {
            return
        }

        this.engine.realtimeSampler$.next({
            type: RTSamplerActionTypes.play,
        })
    }

    private realtimePause() {
        if (this.engine === undefined) {
            return
        }

        this.engine.realtimeSampler$.next({
            type: RTSamplerActionTypes.pause,
            data: {
                origin: "realtimePause",
            },
        })
    }

    private realtimeSetTime(seconds: number) {
        if (this.engine === undefined) {
            return
        }

        if (this.windowService.desktopAppAPI === undefined) {
            return this.streamingService.seek(seconds)
        }

        this.engine.realtimeSampler$.next({
            type: RTSamplerActionTypes.setTime,
            data: {
                seconds,
            },
        })
    }

    private realtimeSetVolume(volume: number) {
        if (this.engine === undefined) {
            return
        }

        this.engine.realtimeSampler$.next({
            type: RTSamplerActionTypes.setVolumeControl,
            data: {
                volume,
            },
        })
    }
}
