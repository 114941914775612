import { Injectable } from "@angular/core"
import { BehaviorSubject } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class SurveyService {

    constructor(private apiService: ApiService) {
       
    }

    createDowngradeSurvey(data) {
        return new Promise((resolve, reject) => {
            this.apiService.authRequest('/survey/createDowngradeSurvey', { data }, "primary", true)
            .then((res) => {
                resolve(res.surveyID)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }
    
}