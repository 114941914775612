import { Injectable } from "@angular/core"
import { Router, CanActivate, ActivatedRoute } from "@angular/router"
import { ApiService } from "@services/api.service"
import { WindowService } from "@services/window.service"

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate() {
        if (localStorage.getItem("currentUser")) {
            return true
        }

        this.router.navigate(["/login"])

        return false
    }
}

@Injectable()
export class NegAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private apiService: ApiService,
        private windowService: WindowService
    ) {}

    canActivate() {
        if (localStorage.getItem("currentUser")) {
            if (
                this.windowService.window.location.href.includes(
                    "redirectToApp=true"
                )
            ) {
                this.apiService
                    .logout(true)

                    .then(() => {
                        localStorage.setItem("redirectToApp", "true")
                    })
            }

            this.router.navigate(["/"])

            return false
        }

        return true
    }
}
