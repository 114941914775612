<div
    id="dynamics-scale"
    *ngIf="
        engine.selectedAutomation?.name === 'dynamic'
    ">
    <div class="ff">
        <img src="assets/img/dynamics/ff.svg" />
    </div>
    <div class="mf">
        <img src="assets/img/dynamics/mf.svg" />
    </div>
    <div class="pp">
        <img src="assets/img/dynamics/pp.svg" />
    </div>
</div>

<div
    id="dynamics-scale"
    class="frequency-scale"
    *ngIf="
        engine.selectedAutomation?.name ===
            'low_frequency_cut' ||
        engine.selectedAutomation?.name ===
            'high_frequency_cut'
    ">
    <div class="ff">20kHz</div>
    <div class="mf">500Hz</div>
    <div class="pp">20Hz</div>
</div>