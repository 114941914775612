import { Component, EventEmitter, Input, Output } from "@angular/core"
import { featureFlags } from "@common-lib/utils/feature-flags"
import { DesktopAppBugReportService } from "@services/desktop-app-bug-report/desktop-app-bug-report.service"

@Component({
    selector: "desktop-app-bug-report-modal",
    templateUrl: "./desktop-app-bug-report-modal.component.html",
    styleUrls: ["./desktop-app-bug-report-modal.component.scss"],
})
export class DesktopAppBugReportModalComponent {
    @Output() close: EventEmitter<any> = new EventEmitter()
    loading = false
    status = undefined

    constructor(private desktopAppBugReport: DesktopAppBugReportService) {}

    ngOnInit() {
        this.saveBugReport()
    }

    async saveBugReport() {
        try {
            if (
                !this.desktopAppBugReport?.isElectron ||
                featureFlags.enableDesktopAppBugReport === false
            ) {
                return
            }

            this.status = undefined
            this.loading = true

            const res = await this.desktopAppBugReport.saveBugReport()

            this.loading = false
            this.status = res === 1 ? "success" : "error"
        } catch (error) {
            this.onError(error)
        }
    }

    onError(error: any) {
        this.loading = false
        this.status = "error"
        console.error("Error sending bug report", error)
    }

    closeModal() {
        this.close.emit()
    }
}
