import { Note } from "../classes/score/note"
import { KeySignature } from "../interfaces/score/keySignature"
import { FractionString, TimeSignature } from "../types/score"
import {
    CHORD_TYPE_MAPPING,
    PITCH31_LIST,
    PITCH_CLASSES,
    ROMAN_SCALE,
    convertNumeralToChord,
    transformChordDegree,
    SCALES,
} from "../utils/composition-workflow.util"
import { cloneDeep } from "lodash"
import { Time } from "./time"
import { NotesObject } from "../classes/score/notesObject"
import { ReharmonizationMapping } from "../interfaces/score/general"
import {
    HARMONY_MODES,
    ME_MODES_TO_CREATORS_MODES_MAPPING,
} from "../constants/constants"
import { CWKeyMode } from "../interfaces/composition-workflow.interface"
import { Misc } from "./misc"
import { TemplateKeySignature } from "../interfaces/score/templateScore"
import { HoveringType } from "../types/general"
import Score from "../classes/score/score"
import { SegmentedScoreKeySignature } from "../interfaces/score/segmentedscore"

export interface IntermediateKeySignature {
    start: FractionString
    end: FractionString
    ks: string
}
export module KeySignatureModule {
    export function insertKeySignature(
        keySignatures: TemplateKeySignature[],
        toInsert: IntermediateKeySignature,
        scoreLength: string
    ): TemplateKeySignature[] {
        const intermediateKeySignatures =
            KeySignatureModule.fromKeySignaturesToIntermediateRepresentation(
                scoreLength,
                keySignatures
            )

        const temp = Misc.insertWithNoOverlap(
            intermediateKeySignatures,
            toInsert
        )

        return KeySignatureModule.fromIntermediateRepresentationToKeySignatures(
            temp
        )
    }

    export function detectKeySignature(score: Score): TemplateKeySignature[] {
        const result = []

        const layers = Object.keys(score.layers)
            .map(l => score.layers[l])
            .filter(l => l.type === "percussion")

        for (const section of score.sections) {
            for (const layer of layers) {
            }
        }

        return result
    }

    export function mergeKeySignatures(
        ks: TemplateKeySignature[][],
        scoreLength: FractionString
    ) {
        let temp = []

        for (const k of ks) {
            temp = temp.concat(
                fromKeySignaturesToIntermediateRepresentation(scoreLength, k)
            )
        }

        temp.sort((a, b) => {
            return Time.compareTwoFractions(a.start, b.start) === "lt" ? -1 : 1
        })

        return fromIntermediateRepresentationToKeySignatures(temp)
    }

    export function splitKeySignature(
        index: number,
        scoreLength: FractionString,
        keySignatures: TemplateKeySignature[],
        timeSignature: TimeSignature
    ): TemplateKeySignature[] {
        if (index < 0 || index >= keySignatures.length) {
            return keySignatures
        }

        const temp = fromKeySignaturesToIntermediateRepresentation(
            scoreLength,
            keySignatures
        )

        const tsBar = timeSignature[0] + "/" + timeSignature[1]
        const duration = Time.addTwoFractions(
            temp[index].end,
            temp[index].start,
            true
        )

        if (Time.compareTwoFractions(duration, tsBar) !== "gt") {
            return keySignatures
        }

        const newDuration = Time.divideFractionWithNumber(duration, 2)
        const numberOfBars = Math.floor(
            Time.divideTwoFractions(newDuration, tsBar)
        )

        temp[index].end = Time.addTwoFractions(
            temp[index].start,
            Time.multiplyFractionWithNumber(tsBar, numberOfBars)
        )
        temp.splice(index + 1, 0, {
            start: temp[index].end,
            end:
                index + 1 < keySignatures.length
                    ? temp[index + 1].end
                    : scoreLength,
            ks: temp[index].ks,
        })

        return fromIntermediateRepresentationToKeySignatures(temp)
    }

    export function getKeySignaturesInRange(
        scoreLength: string,
        ks: TemplateKeySignature[],
        start: FractionString,
        end: FractionString
    ): { start: string; end: string; ks: string }[] {
        const keySignatures = fromKeySignaturesToIntermediateRepresentation(
            scoreLength,
            ks
        )

        const selectedKS = []

        for (const ks of keySignatures) {
            const result = Time.rangesAreOverlapping(ks, { start, end })

            if (result.overlap) {
                selectedKS.push({ ...result.overlapRange, ks: ks.ks })
            }
        }

        return selectedKS
    }

    export function resizeKeySignature(
        scoreLength: FractionString,
        keySignatures: TemplateKeySignature[],
        offset: FractionString,
        index: number,
        side: HoveringType
    ): TemplateKeySignature[] {
        if (Time.compareTwoFractions(offset, "0") === "eq") {
            return
        }

        const temp = fromKeySignaturesToIntermediateRepresentation(
            scoreLength,
            keySignatures
        )

        if (side === "right") {
            temp[index].end = Time.addTwoFractions(temp[index].end, offset)

            if (index + 1 < temp.length) {
                temp[index + 1].start = Time.addTwoFractions(
                    temp[index + 1].start,
                    offset
                )
            }
        } else {
            temp[index].start = Time.addTwoFractions(temp[index].start, offset)

            if (index - 1 >= 0) {
                temp[index - 1].end = Time.addTwoFractions(
                    temp[index - 1].end,
                    offset
                )
            }
        }

        for (let i = temp.length - 1; i >= 0; i--) {
            if (Time.compareTwoFractions(temp[i].end, temp[i].start) !== "gt") {
                temp.splice(i, 1)
            }
        }

        return fromIntermediateRepresentationToKeySignatures(temp)
    }

    export function pitchIsInScale(
        pitch: number,
        start: string,
        end: string,
        ks: SegmentedScoreKeySignature[]
    ) {
        const overlappingKey: {
            ks: SegmentedScoreKeySignature
            inKey: boolean
        }[] = []

        for (const k of ks) {
            const result = Time.rangesAreOverlapping(k, { start, end })

            if (result.overlap) {
                const tempKS = convertMERepresentationToKeySignature(k.ks)
                const scale =
                    KeySignatureModule.getPitchesForKeySignatureScale(tempKS)

                overlappingKey.push({
                    ks: k,
                    inKey: scale.includes(pitch % 12),
                })
            }
        }

        return overlappingKey
    }

    export function fromIntermediateRepresentationToKeySignatures(
        ks: { start: FractionString; end: FractionString; ks: string }[]
    ): TemplateKeySignature[] {
        const temp: TemplateKeySignature[] = []

        for (let k = 0; k < ks.length; k++) {
            const start = ks[k].start

            temp.push([start, ks[k].ks])
        }

        return temp
    }

    export function fromKeySignaturesToIntermediateRepresentation(
        scoreLength: FractionString,
        keySignatures: TemplateKeySignature[]
    ): IntermediateKeySignature[] {
        const temp = []

        for (let k = 0; k < keySignatures.length; k++) {
            let end = scoreLength

            if (k + 1 < keySignatures.length) {
                end = keySignatures[k + 1][0]
            }

            temp.push({
                start: keySignatures[k][0],
                end,
                ks: keySignatures[k][1],
            })
        }

        return temp
    }

    export function convertMERepresentationToKeySignature(
        ks: string
    ): KeySignature {
        const mode = ME_MODES_TO_CREATORS_MODES_MAPPING[ks.split(" ")[1]]

        const keySignature: KeySignature = {
            pitchClass: ks.split(" ")[0],
            keyMode: mode === undefined ? ks.split(" ")[1] : mode,
        }

        return keySignature
    }
    /**
     * For a given chord symbol (e.g. C), this function returns the pitches
     * that are allowed for this chord for the whole MIDI range (e.g. [..., 60, 64, 67, ...])
     * @param chord
     */
    export function getPitchGridForChord(chord: string) {
        const allowedPitches: number[] = []
        const result = KeySignatureModule.getNotesForChord(chord)

        for (let i = Note.lowestNote; i <= Note.highestNote; i++) {
            const noteTypeAndOctave = Note.getNoteTypeAndOctave(i)

            if (noteTypeAndOctave.outsideOfLimits) {
                continue
            }

            if (result.includes(noteTypeAndOctave.type)) {
                allowedPitches.push(i)
            }
        }

        return allowedPitches
    }

    export function getOptimalVoicedPitchesForChord(chord: string) {
        const result = KeySignatureModule.getChordSymbolAndSuffix(chord)

        const mode = chord.split(" ")[0].includes("m") ? "min" : "maj"

        for (const chordType in CHORD_TYPE_MAPPING[mode]) {
            const value = CHORD_TYPE_MAPPING[mode][chordType]

            if (value.suffix === result.suffix) {
                const offset = getPitchClassOffset(
                    result.symbol.replace("m", ""),
                    "startFromC"
                )

                // The pitches index are given relative to the root note of the chord
                const pitches: number[] = cloneDeep(value.pitches).map(
                    p => p + offset
                )

                return pitches
            }
        }

        return []
    }

    export function convertKeySignatureToMERepresentation(
        keySignature: KeySignature
    ): string {
        return (
            keySignature.pitchClass +
            " " +
            KeySignatureModule.convertKeyModeToMERepresentation(
                keySignature.keyMode
            )
        )
    }

    /**
     * The music engine supports only 3 characters for the key mode, so this function converts the mode as follows:
     * Major -> maj
     * minor -> min
     * dorian -> dor
     * etc.
     */
    export function convertKeyModeToMERepresentation(keyMode: string) {
        return keyMode.slice(0, 3).toLowerCase()
    }

    export function getRootNoteForChord(chord: string): string {
        let rootNote = undefined

        for (const pitchClass in PITCH_CLASSES) {
            if (
                chord.startsWith(pitchClass) &&
                (rootNote === undefined || rootNote.length < pitchClass.length)
            ) {
                rootNote = pitchClass
            }
        }

        if (rootNote === undefined) {
            throw "Could not find root note for chord " + chord
        }

        return rootNote
    }

    export function convertNotesToChordWithDegree(
        chord: string,
        rootNote: string,
        keySignature: KeySignature
    ): string {
        const notes = KeySignatureModule.getNotesForChord(chord)
        const result = KeySignatureModule.getNumeralAndExtensionForNotes(
            notes,
            rootNote,
            keySignature
        )

        if (result === undefined) {
            return ""
        }

        if (result.extension === "") {
            return result.numeral
        }

        return result.numeral + " " + result.extension
    }

    export function getNumeralAndExtensionForNotes(
        notes: string[],
        rootNote: string,
        keySignature: KeySignature
    ):
        | {
              numeral: string
              extension: string
          }
        | undefined {
        const keyPitchClassOffset = getPitchClassOffset(
            keySignature.pitchClass,
            "startFromC"
        )
        const offset = getPitchClassOffset(rootNote, "startFromC")
        const pitches = notes
            .map(n =>
                Misc.pythonMod(
                    getPitchClassOffset(n, "startFromC") - offset,
                    12
                )
            )
            .sort()

        for (const mode in CHORD_TYPE_MAPPING) {
            for (const extension in CHORD_TYPE_MAPPING[mode]) {
                const pitchesInExtension = CHORD_TYPE_MAPPING[mode][
                    extension
                ].pitches
                    .map((p: number) => Misc.pythonMod(p, 12))
                    .sort()

                const equal = Misc.arraysAreEqual(pitches, pitchesInExtension)

                if (equal) {
                    const numeral = transformChordDegree(
                        ROMAN_SCALE[keySignature.keyMode][
                            Misc.pythonMod(offset - keyPitchClassOffset, 12)
                        ],
                        mode as "maj" | "min"
                    )

                    return {
                        numeral,
                        extension,
                    }
                }
            }
        }

        return undefined
    }

    /**
     * This function returns the note of the chords.
     * E.g. if the chord is C, it will return [C, E, G]
     * @param chord
     */
    export function getNotesForChord(chord: string, sort = true): string[] {
        function findNotes(mode: "maj" | "min") {
            for (const chordType in CHORD_TYPE_MAPPING[mode]) {
                const value = CHORD_TYPE_MAPPING[mode][chordType]

                if (value.suffix === result.suffix) {
                    const offset = getPitchClassOffset(
                        result.symbol.replace("m", "")
                    )

                    // The pitches index are given relative to the root note of the chord
                    const pitches: number[] = cloneDeep(value.pitches).map(p =>
                        Misc.pythonMod(p + offset, 12)
                    )

                    if (sort) {
                        pitches.sort((a, b) => a - b)
                    }

                    const notes = pitches.map(p => {
                        return Note.notesInOctave[p].split("/")[0]
                    })

                    return notes
                }
            }

            return []
        }
        const result = KeySignatureModule.getChordSymbolAndSuffix(chord)

        const mode =
            result.symbol[result.symbol.length - 1] === "m" ? "min" : "maj"

        const notes = findNotes(mode)

        if (notes.length === 0) {
            return findNotes(mode === "min" ? "maj" : "min")
        }

        return notes
    }

    export function noteIsInChord(chord: string, note: string) {
        const pitch = getNotePitchByName(note)
        const notes = KeySignatureModule.getNotesForChord(chord)

        for (const n of notes) {
            if (pitch === getNotePitchByName(n)) {
                return true
            }
        }

        return false
    }

    export function moveAbsolutePitchInChord(
        pitch: number,
        chord: string,
        direction: "next" | "previous"
    ): number {
        // First, we preprocess the notes to remove the flats
        const notesInOctaveForIndexing = Note.notesInOctave.map(
            n => n.split("/")[0]
        )

        const noteTypeAndOctave = Note.getNoteTypeAndOctave(pitch)
        const notesForChord = KeySignatureModule.getNotesForChord(chord)

        // Then, we convert the chord notes into relative pitch values
        let pitchForChord = notesForChord.map(n =>
            notesInOctaveForIndexing.findIndex(m => m === n)
        )

        // The provided pitch is already in the allowed chord notes
        if (notesForChord.includes(noteTypeAndOctave.type)) {
            const indexOfNote = notesForChord.indexOf(noteTypeAndOctave.type)

            let pitchChange = 0

            if (direction === "next") {
                if (indexOfNote + 1 < notesForChord.length) {
                    const newIndexOfNote =
                        (indexOfNote + 1) % notesForChord.length

                    pitchChange = Math.abs(
                        pitchForChord[newIndexOfNote] -
                            pitchForChord[indexOfNote]
                    )
                } else {
                    pitchChange =
                        12 - pitchForChord[indexOfNote] + pitchForChord[0]
                }
            } else if (direction === "previous") {
                if (indexOfNote - 1 >= 0) {
                    pitchChange = -(
                        pitchForChord[indexOfNote] -
                        pitchForChord[indexOfNote - 1]
                    )
                } else {
                    pitchChange = -(
                        pitchForChord[indexOfNote] +
                        12 -
                        pitchForChord[pitchForChord.length - 1]
                    )
                }
            }

            return pitch + pitchChange
        }

        // The provided pitch is not in the allowed chord notes
        const relativePitch = notesInOctaveForIndexing.findIndex(
            n => n === noteTypeAndOctave.type
        )

        for (let i = 0; i < pitchForChord.length; i++) {
            const value = pitchForChord[i]

            if (direction === "next" && value >= relativePitch) {
                return pitch + Math.abs(relativePitch - value)
            }

            if (direction === "previous" && value <= relativePitch) {
                return pitch - Math.abs(relativePitch - value)
            }
        }

        let result = 0

        if (direction === "previous" && pitchForChord[0] > relativePitch) {
            result =
                pitch -
                Math.abs(
                    relativePitch + 12 - pitchForChord[pitchForChord.length - 1]
                )
        }

        if (
            direction === "next" &&
            pitchForChord[pitchForChord.length - 1] < relativePitch
        ) {
            result = pitch + Math.abs(12 - relativePitch + pitchForChord[0])
        }

        return result
    }

    export function getNotesForPitches(pitches: number[], useFlat: boolean) {
        return pitches.map(p => {
            const noteTypeAndOctave = Note.getNoteTypeAndOctave(p, useFlat)
            return noteTypeAndOctave.type
        })
    }

    export function getPitchesForNotesWithRoot(notes: string[], root: string) {
        const indexOfRoot = notes.indexOf(root)

        if (indexOfRoot === -1) {
            return []
        }
    }

    export function chordNumeralToPitches(
        numeral: string,
        keySignature: KeySignature
    ) {
        const symbol = convertNumeralToChord(
            keySignature.pitchClass,
            keySignature.keyMode,
            numeral
        ).chord

        const pitchesFromNumeral = KeySignatureModule.getPitchesForNotes(
            KeySignatureModule.getNotesForChord(symbol),
            0
        ).sort()

        return pitchesFromNumeral
    }

    export function getPitchesForNotes(notes: string[], startOctave: number) {
        return notes.map(n => getNotePitchByName(n, startOctave))
    }

    export function getChordSymbolAndSuffix(chord: string): {
        chordName: string
        symbol: string
        suffix: string
    } {
        chord = chord.replace(" ", "")
        let rootNote = KeySignatureModule.getRootNoteForChord(chord)

        let extension = ""

        if (chord.split(" ").length > 1) {
            extension = chord.split(" ")[1]

            if (chord.includes("m ") && !chord.includes("maj")) {
                extension = "m" + extension
            }
        } else {
            extension = chord.replace(rootNote, "")
        }

        const subExtension = extension.slice(0, 3)

        if (
            extension.length > 0 &&
            extension[0] === "m" &&
            subExtension !== "maj"
        ) {
            extension = extension.slice(1)
            rootNote = rootNote + "m"
        }

        return {
            chordName: rootNote + extension,
            symbol: rootNote,
            suffix: extension,
        }
    }

    export function getRelativePitchesAndDegrees(
        chordSuffix: string,
        mode: "maj" | "min"
    ):
        | {
              pitches: []
              degrees: []
          }
        | undefined {
        const values = CHORD_TYPE_MAPPING[mode]

        const chordType = Object.keys(values).find(c => {
            return values[c].suffix === chordSuffix
        })

        if (chordType === undefined) {
            return undefined
        }

        return {
            pitches: values[chordType].pitches,
            degrees: values[chordType].degrees,
        }
    }

    export function getRelativeNotePitch(noteName: string) {
        const { symbol, suffix } = getChordSymbolAndSuffix(noteName)

        const noteIndex = Note.notesInOctaveForIndexing.findIndex(
            name =>
                getPitchClassOffset(name.split("/")[0]) ===
                getPitchClassOffset(symbol)
        )

        return noteIndex
    }

    /**
     * returns the pitch of a note as a number value
     * @param noteName note names as it is set in Note.NOTES, e.g. 'D#/Eb'
     * @param octave
     */
    export function getNotePitchByName(noteName: string, octave: number = 2) {
        const noteIndex = getRelativeNotePitch(noteName)

        const lowestOctave = -2 // we can't go below 0 in pitch
        const octaveMultiplier = Math.abs(lowestOctave - octave)

        return noteIndex + octaveMultiplier * 12
    }

    export function getAllPossibleModesString() {
        let allPossibleModesString = ""

        for (const mode in HARMONY_MODES) {
            for (const modeType of HARMONY_MODES[mode]) {
                if (allPossibleModesString.length > 0) {
                    allPossibleModesString += ", "
                }

                allPossibleModesString += modeType
            }
        }

        return allPossibleModesString
    }

    export function getAllPossibleKeysString(includeFlats = false) {
        let keys = [
            "C",
            "C#",
            "D",
            "D#",
            "E",
            "E#",
            "F",
            "F#",
            "G",
            "G#",
            "A",
            "A#",
            "B",
            "B#",
        ]

        if (includeFlats) {
            keys = keys.concat(["Cb", "Db", "Eb", "Fb", "Gb", "Ab", "Bb"])
        }

        let allPossibleKeysString = ""

        for (const chord of keys) {
            if (allPossibleKeysString.length > 0) {
                allPossibleKeysString += ", "
            }

            allPossibleKeysString += chord
        }

        return allPossibleKeysString
    }

    export function getPitchClassOffset(
        pitchClass: string,
        type: "startFromC" | "startFromA" = "startFromA"
    ) {
        pitchClass = pitchClass.replace("m", "")

        if (!pitchClass) {
            console.warn("Pitch class is empty: ")
            return
        }

        if (!PITCH31_LIST.includes(pitchClass)) {
            console.warn(
                "Pitch class is invalid in getPitchClassOffset: " + pitchClass
            )

            return
        }

        let pitchOffset = 0
        const letter = pitchClass[0]

        if (pitchClass.includes("##")) {
            pitchOffset = 2
        } else if (pitchClass.includes("bb")) {
            pitchOffset = -2
        } else if (pitchClass.includes("#")) {
            pitchOffset = 1
        } else if (pitchClass.includes("b")) {
            pitchOffset = -1
        }

        const result =
            type === "startFromA"
                ? Note.notesInOctave.findIndex(n => {
                      const split = n.split("/")

                      return split.length > 1
                          ? n[0] === letter || n[1] === letter
                          : n[0] === letter
                  })
                : Note.notesInOctaveForIndexing.findIndex(n => {
                      const split = n.split("/")

                      return split.length > 1
                          ? n[0] === letter || n[1] === letter
                          : n[0] === letter
                  })

        if (result === -1) {
            throw "Pitch class is invalid in getPitchClassOffset: " + pitchClass
        }

        const offset = result + pitchOffset

        if (offset < 0) {
            return Math.ceil(Math.abs(offset) / 12) * 12 + offset
        }

        return offset % 12
    }

    export function createReharmonizationMapping(
        notes: NotesObject,
        newChord: {
            symbol: string
            start: FractionString
            duration: FractionString
        },
        followPreferredNoteOrder: boolean
    ): ReharmonizationMapping {
        const end = Time.addTwoFractions(newChord.start, newChord.duration)

        const currentPitches = notes.getNotePitchesAtTime({
            start: newChord.start,
            end,
        })

        const map: ReharmonizationMapping = {}

        if (currentPitches.length === 0) {
            return map
        }

        const startingOctave = Note.getOctave(currentPitches[0])
        const chordPitches = KeySignatureModule.getPitchesForNotes(
            KeySignatureModule.getNotesForChord(newChord.symbol),
            startingOctave
        )

        // By default, the getNotesForChord function returns notes in a specific
        // order that is supposed to be an optimal default voicing for the chord.
        // Setting followPreferredNoteOrder to false will instead reorder the pitches
        // from the lowest to the highest value
        if (!followPreferredNoteOrder) {
            chordPitches.sort()
        }

        currentPitches.forEach((pitch: number, index: number) => {
            const newPitch =
                chordPitches[index % chordPitches.length] +
                12 * Math.floor(index / chordPitches.length)

            map[pitch] = newPitch
        })

        return map
    }

    export function isNumeralDiatonic(numeral: string, mode: CWKeyMode) {
        const keySignature = {
            pitchClass: "C",
            keyMode: mode,
        }

        const chord = convertNumeralToChord(
            keySignature.pitchClass,
            keySignature.keyMode,
            numeral
        ).chord

        const newMode = KeySignatureModule.convertKeyModeToMERepresentation(
            keySignature.keyMode
        )
        const scale = SCALES[newMode]
        const notes = KeySignatureModule.getNotesForChord(chord)

        return !notes.some(note => !scale.includes(PITCH_CLASSES[note]))
    }

    export function getTriadScaleByKeySignature(keySignature: KeySignature) {
        if (!keySignature) {
            return []
        }

        const scales = keySignature.keyMode.toLowerCase().includes("i")
            ? Note.MINOR_SCALES
            : Note.MAJOR_SCALES

        for (let s of scales) {
            const rootNoteNames = s[0].split("/")
            for (let note of rootNoteNames) {
                if (note === keySignature.pitchClass) {
                    return s
                }
            }
        }

        return []
    }

    export function getTriadPitchesType(keySignature: KeySignature) {
        const pitches = KeySignatureModule.getAbsoluteTriadPitches(keySignature)

        return [
            Note.getNoteTypeAndOctave(pitches[0]).type,
            Note.getNoteTypeAndOctave(pitches[1]).type,
            Note.getNoteTypeAndOctave(pitches[2]).type,
        ]
    }

    /**
     * Get the pitches of any given scale. For example, C major would return
     * [0, 2, 4, 5, 7, 9, 11]
     */
    export function getPitchesForKeySignatureScale(
        keySignature: KeySignature
    ): number[] {
        const mode = KeySignatureModule.convertKeyModeToMERepresentation(
            keySignature.keyMode
        )
        const result = SCALES[mode].map(p =>
            Misc.pythonMod(p + PITCH_CLASSES[keySignature.pitchClass], 12)
        )

        return result.sort((a, b) => a - b)
    }

    export function getAbsoluteTriadPitches(
        keySignature: KeySignature,
        startOctave = 2
    ) {
        const pitches = KeySignatureModule.getTriadScalePitches(
            keySignature,
            startOctave
        )

        return [pitches[0], pitches[1], pitches[2]]
    }

    export function getTriadScalePitches(
        keySignature: KeySignature,
        startOctave = 2
    ) {
        let pitches: number[] = []

        const selectedScale: string[] =
            KeySignatureModule.getTriadScaleByKeySignature(keySignature)
        const octaves = [startOctave, startOctave + 1, startOctave + 2]
        const scaleMode = keySignature.keyMode
        const interval = scaleMode.includes("maj")
            ? Note.MAJOR_SCALE_INTERVAL
            : Note.MINOR_SCALE_INTERVAL

        for (let o of octaves) {
            const rootNotePitch = getNotePitchByName(selectedScale[0], o)
            const scalePitches: number[] = interval.map(i => rootNotePitch + i)

            pitches = pitches.concat(scalePitches)
        }

        return pitches
    }
}
