import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { HelperService } from '../helpers/helper.service'
import { ApiService } from './api.service'
import { PlayerService } from './audio/player/player.service'
import { FolderService } from './folder.service'
import { TracksService } from './tracks.service'
import { ParentClass } from '../parent'
import { GeneralService } from './general/general.service'
import { playerQuery } from '../../../../common-lib/client-only/general/classes/playerStateManagement'

@Injectable()
export class PlaylistService extends ParentClass {

    selectedPlaylist: BehaviorSubject<Object> = new BehaviorSubject<Object>(null)
    playlists: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([])
    defaultPlaylists = []
    allCompositions = []
    currentlyPlayingPlaylist = null
    isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    MINIMUM_PLAYLIST_SIZE = 5
    
    constructor(private apiService: ApiService, private general: GeneralService, private helper: HelperService, private folderService: FolderService, private tracksService: TracksService, private playerService: PlayerService) {
        super()

        this.subscribe(this.tracksService.trackView, viewName => {
            if(viewName === 'playlists'){
                this.getRankedCompositions().then((c) => {
                    return this.generatePlaylists(c)
                })
            }
        })

        this.subscribe(playerQuery.allState$, (state) => {
            try {
                if (state.composition === undefined || state.composition["usage"] == null) {
                    // this.setPlaylist(null) 
                }
                else {
                    let selectedPlaylist = this.selectedPlaylist.getValue()

                    if (selectedPlaylist != null) {
                        this.setPlaylistIsPlaying(selectedPlaylist["id"], state.status === "playing")
                    }
                    else {
                        this.setPlaylistIsPlaying('no', false)
                    }
                }
            } catch (error) {
            }
        })
    }

    setSelectedPlaylist(playlist) {
        this.selectedPlaylist.next(playlist)
    }

    generatePlaylists(compositions) {
        let playlistsBeforeGeneration = this.playlists.getValue()

        this.isLoading.next(true)
        let playlists = []

        if(compositions.length <= this.MINIMUM_PLAYLIST_SIZE){
            this.isLoading.next(false)
            this.playlists.next(playlists)
            return Promise.resolve(playlists)
        }

        return this.generateDefaultPlaylists(compositions)

        .then((defaultPlaylists) => {
            playlists = defaultPlaylists

            let compositionsBySubcategory = this.groupBySubcategory(compositions)
    
            for (let subcat of this.getAllSubcategories()){
                const subcatID = this.formatNameAsID(subcat.name)
    
                if (!compositionsBySubcategory.hasOwnProperty(subcatID)) {
                    continue
                }
    
                let durationObject = this.getTotalPlaylistDuration(compositionsBySubcategory[subcatID])

                let rankedCompositions = this.rankAllSubcategoryCompositions(subcatID, compositionsBySubcategory[subcatID])
    
                let subcategoryPlaylist = {
                    id: this.formatNameAsID(subcat.name),
                    name: subcat.name,
                    backgroundColor: subcat.backgroundColor || this.getRandomColor(),
                    description: subcat.description || subcat.name,
                    duration: {
                        h: durationObject.hours,
                        m: durationObject.minutes,
                    },
                    compositions: rankedCompositions,
                    numberOfCompositions: rankedCompositions.length,
                    category: subcat.categoryIndex,
                    usecase: subcat.subcategoryIndex,
                    subcategory: subcat,
                    image: subcat.image,
                    isPlaying: this.isPlaying(playlistsBeforeGeneration, this.formatNameAsID(subcat.name))
                }
    
                playlists.push(subcategoryPlaylist)
            }
    
            this.isLoading.next(false)
    
            this.playlists.next(playlists)
    
            return Promise.resolve(this.playlists.getValue())

        })

    }

    generateDefaultPlaylists(compositions){
        let defaultPlaylists = []

        let top100Playlist = this.getTop100Playlist(compositions)
        let likedPlaylist = this.getLikedPlaylist(compositions)
        let editedPlaylist = this.getEditedCompositionsPlaylist(compositions)

        if(top100Playlist.numberOfCompositions >= this.MINIMUM_PLAYLIST_SIZE){
            defaultPlaylists.push(this.getTop100Playlist(compositions))
        }

        if(likedPlaylist.numberOfCompositions >= this.MINIMUM_PLAYLIST_SIZE){
            defaultPlaylists.push(this.getLikedPlaylist(compositions))
        }

        if(editedPlaylist.numberOfCompositions >= this.MINIMUM_PLAYLIST_SIZE){
            defaultPlaylists.push(this.getEditedCompositionsPlaylist(compositions))
        }

        if(defaultPlaylists.length > 0){
            this.playlists.next(defaultPlaylists)
        }

        return Promise.resolve(defaultPlaylists)
    }

    getTop100Playlist(compositions){
        const sortedCompositions = this.sortByRank(compositions)
        const top100Compositions = sortedCompositions.length > 100 ? sortedCompositions.slice(0, 100) : sortedCompositions
        const top100Duration = this.getTotalPlaylistDuration(top100Compositions)
        const playlistsBeforeGeneration = this.playlists.getValue()
        const rankedCompositions = this.rankAllSubcategoryCompositions('top100', top100Compositions)

        const top = {
            id: "top100",
            name: "Top 100",
            description: "Your favorite songs",
            duration: { h: top100Duration.hours, m: top100Duration.minutes },
            backgroundColor: "rgb(0, 116, 215)",
            compositions: rankedCompositions,
            numberOfCompositions: rankedCompositions.length,
            category: null,
            usecase: null,
            subcategory: null,
            image: "mixing.svg",
            isPlaying: this.isPlaying(playlistsBeforeGeneration, this.formatNameAsID('top100'))
        }

        return top
    }

    getLikedPlaylist(compositions){
        const likedCompositions = compositions.filter(c => c.liked === true)
        const likedDuration = this.getTotalPlaylistDuration(likedCompositions)
        const playlistsBeforeGeneration = this.playlists.getValue()
        const rankedCompositions = this.rankAllSubcategoryCompositions('liked', likedCompositions)

        const liked = {
            id: "liked",
            name: "Liked Compositions",
            description: "All your liked songs",
            duration: { h: likedDuration.hours, m: likedDuration.minutes },
            backgroundColor: "rgb(170, 2, 57)",
            compositions: rankedCompositions,
            numberOfCompositions: rankedCompositions.length,
            category: null,
            usecase: null,
            subcategory: null,
            image: "loved_white.svg",
            isPlaying: this.isPlaying(playlistsBeforeGeneration, this.formatNameAsID('liked'))
        }

        return liked
    }

    getEditedCompositionsPlaylist(compositions){
        const sortedCompositions = this.sortByRank(compositions)
        const reducedCompositions = sortedCompositions.length > 100 ? sortedCompositions.slice(0, 100) : sortedCompositions
        const editedCompositions = reducedCompositions.filter(c => this.isEditedComposition(c))
        const editedDuration = this.getTotalPlaylistDuration(editedCompositions)
        const playlistsBeforeGeneration = this.playlists.getValue()
        const rankedCompositions = this.rankAllSubcategoryCompositions('edited', editedCompositions)

        const edited = {
            id: "edited",
            name: "Edited Compositions",
            description: "Edited Compositions",
            duration: { h: editedDuration.hours, m: editedDuration.minutes },
            backgroundColor: "rgb(1, 81, 66)",
            compositions: rankedCompositions,
            numberOfCompositions: rankedCompositions.length,
            category: null,
            usecase: null,
            subcategory: null,
            image: "edited-composition.svg",
            isPlaying: this.isPlaying(playlistsBeforeGeneration, this.formatNameAsID('edited'))
        }

        return edited
    }

    getNumberOfCompositionEdits(composition){
        if(!this.isEditedComposition(composition)){
            return 0
        }

        let numberOfEdits = 1

        if (composition.analytics && composition.analytics.userEdits && Array.isArray(composition.analytics.userEdits)){
            let numberOfUserEdits = composition.analytics.userEdits.length
            
            return numberOfEdits + numberOfUserEdits
        }
    }

    isEditedComposition(composition){
        if (composition.manualEdit || composition.ensemble === "Custom") {
            return true
        }

        return false
    }

    isPlaying(playlists, playlistID){
        let playlistIndex = playlists.findIndex(playlist => playlist.id === playlistID)

        if(playlistIndex == -1){
            return false
        }

        return playlists[playlistIndex]["isPlaying"]
    }

    setPlaylist(playlist) {
        this.tracksService.setTrackView('single-playlist')

        this.setSelectedPlaylist(playlist)

        let promise = Promise.resolve()
        
        if(playlist != null){
            promise = this.folderService.fetchPlaylistContent(playlist)
        }

        return promise
    }

    getRandomColor() {
        let colorCollection = ["green", "red", "purple", "orange", "emerald"]

        return colorCollection[Math.floor(Math.random() * colorCollection.length)]
    }

    getRankedCompositions() {
        this.isLoading.next(true)
        return this.apiService.authRequest('/composition/getRankedCompositionsByUserID', {}, "primary", true)
        
        .then((res) => {
            this.allCompositions = res.compositions

            this.isLoading.next(false)

            return Promise.resolve(this.allCompositions)
        })

        .catch((err) => {
            this.apiService.handleError(err)
        })
    }

    groupByPreset(compositions){
        let groupedByPreset = {}
        for(let c of compositions){
            if(!groupedByPreset.hasOwnProperty(c.preset)){
                groupedByPreset[c.preset] = []
            }
            
            groupedByPreset[c.preset].push(c)
        }

        return groupedByPreset
    }

    groupByPresetAndEnsemble(compositions){
        let groupedByPresetAndEnsemble = []

        for (let c of compositions) {
            if (groupedByPresetAndEnsemble.filter(o => (o.preset === c.preset) && (o.ensemble === c.originalEnsemble)).length === 0) {
                groupedByPresetAndEnsemble.push({
                    preset: c.preset, 
                    ensemble: c.originalEnsemble,
                    compositions: []
                })
            }

            let index = groupedByPresetAndEnsemble.findIndex(o => (o.preset === c.preset) && (o.ensemble === c.originalEnsemble))
            groupedByPresetAndEnsemble[index]["compositions"].push(c)
        }

        return groupedByPresetAndEnsemble
    }

    getPlaylistIndexByID(playlistID) {
        return this.playlists.getValue().findIndex(playlist => playlist.id === playlistID)
    }

    analyticsExist(composition): boolean{
        return !!(composition && composition.analytics && composition.analytics.listeningTime && composition.analytics.listeningTime.overall)
    }

    getTotalPlaylistDuration(compositions: Array<any>) {
        let totalDurationInSeconds = 0

        for (let composition of compositions) {
            totalDurationInSeconds += composition.duration
        }

        return this.helper.convertSecondsToTime(totalDurationInSeconds)
    }

    getSubcategoriesByPresetAndEnsemble(preset, ensemble){
        let subcategories = []
        for(let cat of this.general.query.presetUseCases){
            for(let sub of cat.subcategories){
                if (sub.presetAndEnsemble.filter(o => o.preset === preset && o.ensemble === ensemble).length > 0){
                    subcategories.push(sub.name)
                }
            }
        }

        return subcategories
    }

    getAllSubcategories(){
        let subcategories = []
        let catIndex = 0
        let subcatIndex = 0
        for (let cat of this.general.query.presetUseCases) {
            for (let sub of cat.subcategories) {
                sub["categoryIndex"] = catIndex
                sub["subcategoryIndex"] = subcatIndex

                subcategories.push(sub)
                subcatIndex++
            }
            catIndex++
            subcatIndex = 0
        }

        return subcategories
    }

    formatNameAsID(name){
        let id = name.toLowerCase()
        id = id.replace(/ /g, '')
        id = id.replace(/-/g, '')

        return id
    }

    getCompositionsByPresetAndEnsemble(compositions, preset, ensemble){
        let compositionsByPresetAndEnsemble = compositions.filter(c => c.originalEnsemble == ensemble && c.preset == preset)
        return compositionsByPresetAndEnsemble
    }

    groupBySubcategory(compositions){
        let groupedBySubcategory = {}

        for (let subcat of this.getAllSubcategories()){
            const subcatID = this.formatNameAsID(subcat.name)
            let compositionsOfSubcategory = []

            for (let presetAndEnsemble of subcat.presetAndEnsemble){
                const compositionsByPresetAndEnsemble = this.getCompositionsByPresetAndEnsemble(compositions, presetAndEnsemble.preset, presetAndEnsemble.ensemble)
                compositionsOfSubcategory = compositionsOfSubcategory.concat(compositionsByPresetAndEnsemble)
            }

            if(compositionsOfSubcategory.length >= this.MINIMUM_PLAYLIST_SIZE){
                compositionsOfSubcategory = this.sortByRank(compositionsOfSubcategory)
                groupedBySubcategory[subcatID] = compositionsOfSubcategory
            }
        }

        return groupedBySubcategory
    }

    sortByRank(array, order = 'highToLow'){
        if(order == 'highToLow'){
            return array.sort((a, b) => {
                return b.rank - a.rank
            })
        }

        else {
            return array.sort((a, b) => {
                return a.rank - b.rank
            }) 
        }
    }

    getBackgroundImage(playlist){
        if(playlist.id == 'top100'){
          return 'assets/img/pianoroll/' + playlist.image
        }
    
        if(playlist.id == 'liked' || playlist.id == 'edited'){
          return 'assets/img/' + playlist.image
        }
    
        return 'assets/img/presets/' + playlist.image
    }

    setPlaylistIsPlaying(playlistID, isPlaying){
        let playlists = []
        let selectedPlaylist

        for(let playlist of this.playlists.getValue()){
            playlist["isPlaying"] = false
            
            if(playlist.id == playlistID){
                playlist["isPlaying"] = isPlaying
                selectedPlaylist = playlist
            }
            
            playlists.push(playlist)
        }

        if(playlistID == null){
            selectedPlaylist = null
        }

        this.playlists.next(playlists)
        this.setSelectedPlaylist(selectedPlaylist)
    }

    /**
     * @param compositions
     * @param numberOfLevels number of levels the rank will be devided into. The highest ranked composition will be in the highest level
     */
    rankAllSubcategoryCompositions(subcatID, compositions, numberOfLevels = 4){
        let level = 1
        let leveledCompositions = []
        
        let rankThresholds
        if(compositions.length >= numberOfLevels){
            rankThresholds = this.defineRankLevels(compositions, numberOfLevels)
        }

        for(let composition of compositions){
            composition = JSON.parse(JSON.stringify(composition))    // clone composition Object without reference because else it leads to issues with having the same composition in multiple playlists
            level = this.getRankLevel(composition, rankThresholds)
            composition["level"] = level
            composition['playlistID'] = subcatID

            Object.defineProperty(composition, "level", { configurable: false, writable: false })    // make sure level won't be overwritten

            leveledCompositions.push(composition)
        }

        return leveledCompositions
    }

    defineRankLevels(compositions, numberOfLevels = 4) {
        let sortedByRank = this.sortByRank(compositions, 'lowToHigh')
        let levelThresholds = {}

        let lastQuantile

        if(sortedByRank.length < numberOfLevels){
            return levelThresholds
        }

        for(let i = 0; i < numberOfLevels; i++){
            let quantilePercentage = Number((1 / numberOfLevels) * (i+1))
            let currentQuantile = Math.floor(sortedByRank.length * quantilePercentage) - 1

            let minValue = lastQuantile && sortedByRank[lastQuantile] ? sortedByRank[lastQuantile]["rank"] : 0
            let maxValue = sortedByRank[currentQuantile] ? sortedByRank[currentQuantile]["rank"] : 0

            levelThresholds[(i+1)] = {min: minValue, max: maxValue}

            lastQuantile = currentQuantile
        }

        this.sortByRank(compositions)

        return levelThresholds
    }

    getRankLevel(composition, levelThresholds){
        let rankLevel = 1

        if(!levelThresholds){
            return rankLevel
        }

        let levels = Object.keys(levelThresholds)

        for(let level of levels){
            let minRankPerLevel = levelThresholds[level]["min"]
            let maxRankPerLevel = levelThresholds[level]["max"]

            // Min            
            if(level == levels[0]){
                if(composition.rank >= minRankPerLevel && composition.rank < maxRankPerLevel){
                    rankLevel = Number(level)
                }
            }
            
            // Max
            if(level == levels[levels.length - 1]){
                if(composition.rank > minRankPerLevel && composition.rank <= maxRankPerLevel){
                    rankLevel = Number(level)
                }
            }
            
            // in between
            if(composition.rank >= minRankPerLevel && composition.rank < maxRankPerLevel){
                rankLevel = Number(level)
            }
            
        }
        
        return rankLevel
    }

    getCurrentlyPlayingPlaylist(){
        return this.playlists.getValue().find(p => p.isPlaying === true)
    }

    compositionExistsInPlaylist(playlistID, compositionID){
        if(!playlistID || !compositionID){
            return false
        }

        let playlist = this.playlists.getValue().find(p => p.id === playlistID)
        let compositions = playlist['compositions']
        let composition = compositions.find(c => c._id === compositionID)

        if(!playlist || !composition){
            return false
        }

        return true
    }

    togglePlay(playlistID){
        
        if(this.selectedPlaylist.getValue() != null && this.selectedPlaylist.getValue()["isPlaying"] == true){
          this.playerService.pause()
        }

        else if (this.selectedPlaylist.getValue() != null && this.selectedPlaylist.getValue()["isPlaying"] == false){
            this.playerService.play()
        }
    }

    // playAtIndex(index):void {
	// 	const composition = this.compositionService.getCompositionAtIndex(index)
	// 	const compositionID = composition["_id"]

	// 	var meta = this.playerService.getMeta()

    //     if (meta != null && compositionID == meta.compositionID && this.selectedPlaylist['isPlaying']) {
	// 		this.playerService.play()
	// 	}

	// 	else {
	// 		this.playerService.loadNewTrack(compositionID, composition.contentType, true, false)
	// 	}
	// }

	// pause():void {
	// 	this.playerService.pause()
	// }

    getPlaylistByID(playlistID){
        return this.playlists.getValue().find(p => p.id === playlistID)
    }

    playlistCompositionIsMeta(){
		return (playerQuery.content['usage'] && playerQuery.content['usage'] == 'playlist' && playerQuery.content['playlistID'] == this.selectedPlaylist.getValue()['id'])
	}

}