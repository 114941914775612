import {
    InstrumentsJSON,
    PitchToChannelMapping,
} from "../interfaces/score/general"
import { LayersValue } from "../types/general"
import {
    CompositionWorkflowLayer,
    CompositionWorkflowNote,
} from "../interfaces/composition-workflow.interface"
import Layer from "../classes/score/layer"
import { ScoreManipulation } from "./scoremanipulation"
import { Note } from "../classes/score/note"
import { TimeSignature } from "../types/score"
import Section from "../classes/score/section"
import TrackBus from "../classes/score/trackbus"
import Patch from "../classes/score/patch"
import PercussionLayer from "../classes/score/percussionlayer"
import { Pattern } from "../classes/score/pattern"
import { v4 as uuidv4 } from "uuid"
import Channel from "../classes/score/channel"
import PatternRegion from "../classes/score/patternregion"
import { Time } from "./time"
import {
    AUTOMATION_TIMESTEP_RES,
    LayerType,
    NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS,
    TIMESTEP_RES,
} from "../constants/constants"
import Score from "../classes/score/score"
import InstrumentPatch from "../classes/score/instrumentpatch"
import { GPMixing } from "../classes/generationprofiles/gpmixing"
import GPLayer from "../classes/generationprofiles/gplayer"
import { Delay, Reverb } from "../classes/score/effect"
import GenerationProfile from "../classes/generationprofiles/generationprofile"
export module CompositionWorkflowManipulation {
    export function applyEffectsToScoreLayer({
        scoreLayer,
        trackBuses,
        gp,
        scoreLengthTimesteps,
        tempo,
    }: {
        scoreLayer: Layer
        trackBuses: TrackBus[]
        gp: GenerationProfile
        scoreLengthTimesteps: number
        tempo: number
    }) {
        let gpLayer: GPLayer

        if (scoreLayer.value === LayerType.MELODY) {
            gpLayer = gp.melodyLayer
        } else {
            gpLayer = gp.accompanimentLayers.find(
                l => l.name === scoreLayer.value
            )
        }

        if (!gpLayer) {
            return
        }

        applyDynamicToScoreLayer({
            scoreLayer,
            scoreLengthTimesteps,
        })

        applyReverbToScoreLayer({
            scoreLayer,
            gpLayer,
            scoreLengthTimesteps,
        })

        applyFrequencyCutToScoreLayer({
            scoreLayer,
            gpLayer,
            scoreLengthTimesteps,
            type: "lfc",
        })

        applyFrequencyCutToScoreLayer({
            scoreLayer,
            gpLayer,
            scoreLengthTimesteps,
            type: "hfc",
        })

        applyDelayToScoreLayer({
            scoreLayer,
            gpLayer,
            scoreLengthTimesteps,
            tempo,
        })

        applyAutoMixingToScoreLayer({
            scoreLayer,
            trackBuses,
            gp,
        })
    }

    export function applyAutoMixingToScoreLayer({
        scoreLayer,
        trackBuses,
        gp,
    }: {
        scoreLayer: Layer
        trackBuses: TrackBus[]
        gp: GenerationProfile
    }) {
        try {
            // First, we normalise the results to never exceed 0dB offset
            const gainOffsets = {}
            let maxGainOffset = 0

            if (gp.melodyLayer) {
                gainOffsets[gp.melodyLayer.name] =
                    gp.melodyLayer.mixing.gainBias
                maxGainOffset = gp.melodyLayer.mixing.gainBias
            }

            for (const layer of gp.accompanimentLayers) {
                gainOffsets[layer.name] = layer.mixing.gainBias
                maxGainOffset = Math.max(maxGainOffset, layer.mixing.gainBias)
            }

            for (const layer in gainOffsets) {
                gainOffsets[layer] -= maxGainOffset
            }

            for (const trackbus of trackBuses) {
                trackbus.gainOffset = gainOffsets[scoreLayer.value]
            }
        } catch (e) {
            console.error(e)
        }
    }

    export function applyFrequencyCutToScoreLayer({
        scoreLayer,
        gpLayer,
        scoreLengthTimesteps,
        type,
    }: {
        scoreLayer: Layer
        gpLayer: GPLayer
        scoreLengthTimesteps: number
        type: "hfc" | "lfc"
    }) {
        const decade = GPMixing.hzToDecade(gpLayer.mixing[type], type)

        scoreLayer.effects[
            type === "hfc" ? "high_frequency_cut" : "low_frequency_cut"
        ].values = ScoreManipulation.setAutomationValue(
            scoreLayer.effects.delay,
            [
                0,
                Time.convertTimestepsToAnotherRes(
                    scoreLengthTimesteps,
                    TIMESTEP_RES,
                    AUTOMATION_TIMESTEP_RES
                ),
            ],
            [decade, decade],
            true
        )
    }

    export function applyDelayToScoreLayer({
        scoreLayer,
        gpLayer,
        scoreLengthTimesteps,
        tempo,
    }: {
        scoreLayer: Layer
        gpLayer: GPLayer
        scoreLengthTimesteps: number
        tempo: number
    }) {
        const delay = gpLayer.mixing.delay
        ;(scoreLayer.effects.delay as Delay).right.delay_time =
            Time.fractionToMilliseconds({
                duration: delay.rightTime,
                tempo,
            })
        ;(scoreLayer.effects.delay as Delay).left.delay_time =
            Time.fractionToMilliseconds({
                duration: delay.leftTime,
                tempo,
            })

        scoreLayer.effects.delay.values = ScoreManipulation.setAutomationValue(
            scoreLayer.effects.delay,
            [
                0,
                Time.convertTimestepsToAnotherRes(
                    scoreLengthTimesteps,
                    TIMESTEP_RES,
                    AUTOMATION_TIMESTEP_RES
                ),
            ],
            [delay.amount, delay.amount],
            true
        )
    }

    export function applyDynamicToScoreLayer({
        scoreLayer,
        scoreLengthTimesteps,
    }: {
        scoreLayer: Layer
        scoreLengthTimesteps: number
    }) {
        scoreLayer.effects.dynamic.values =
            ScoreManipulation.setAutomationValue(
                scoreLayer.effects.dynamic,
                [
                    0,
                    Time.convertTimestepsToAnotherRes(
                        scoreLengthTimesteps,
                        TIMESTEP_RES,
                        AUTOMATION_TIMESTEP_RES
                    ),
                ],
                [72, 72],
                true
            )
    }

    export function applyReverbToScoreLayer({
        scoreLayer,
        gpLayer,
        scoreLengthTimesteps,
    }: {
        scoreLayer: Layer
        gpLayer: GPLayer
        scoreLengthTimesteps: number
    }) {
        const reverb = gpLayer.mixing.reverb
        ;(scoreLayer.effects.reverb as Reverb).ir = reverb.ir

        scoreLayer.effects.reverb.values = ScoreManipulation.setAutomationValue(
            scoreLayer.effects.reverb,
            [
                0,
                Time.convertTimestepsToAnotherRes(
                    scoreLengthTimesteps,
                    TIMESTEP_RES,
                    AUTOMATION_TIMESTEP_RES
                ),
            ],
            [reverb.wetness, reverb.wetness],
            true
        )
    }

    export function updateCompositionWorkflowLayersFromScore({
        scoreLayers,
        cwLayers,
        timeSignature,
    }: {
        scoreLayers: { [layerKey: string]: Layer | PercussionLayer }
        cwLayers: CompositionWorkflowLayer[]
        timeSignature: TimeSignature
    }) {
        const newCWLayers = Object.keys(scoreLayers).map(k => {
            const layer = scoreLayers[k]
            const cwLayer = cwLayers.find(l => l.layerName === layer.value)

            if (layer.type === "pitched") {
                return fromScoreLayer({
                    scoreLayer: layer as PercussionLayer,
                    layerInstrument: cwLayer?.instrument,
                })
            } else {
                return fromPercussionScoreLayer({
                    scoreLayer: layer as PercussionLayer,
                    layerInstrument: cwLayer.instrument,
                    timeSignature: timeSignature,
                })
            }
        })

        return newCWLayers
    }

    export function fromPercussionScoreLayer({
        scoreLayer,
        layerInstrument,
        timeSignature,
    }: {
        scoreLayer: PercussionLayer
        layerInstrument: InstrumentPatch
        timeSignature: TimeSignature
    }): CompositionWorkflowLayer {
        const cwLayer: CompositionWorkflowLayer = {
            id: uuidv4(),
            instrument: layerInstrument,
            score: [],
            layerName: scoreLayer.value,
        }

        const notesObject = scoreLayer.computeNotesObject({
            sections: [],
            tempoMap: [],
            realTimeSampler: false,
            timeSignature,
        })

        notesObject.manipulateNoteGroups((g: Note[]) => {
            const cwNote: CompositionWorkflowNote = {
                start: g[0].start,
                duration: g[0].duration,
                pitch: g.map(n => n.pitch),
            }

            cwLayer.score.push(cwNote)

            return true
        })

        return cwLayer
    }

    export function fromScoreLayer({
        scoreLayer,
        layerInstrument,
    }: {
        scoreLayer: Layer
        layerInstrument: InstrumentPatch
    }): CompositionWorkflowLayer {
        const cwLayer: CompositionWorkflowLayer = {
            id: uuidv4(),
            instrument: layerInstrument,
            score: [],
            layerName: scoreLayer.value,
        }

        scoreLayer.notesObject.manipulateNoteGroups((g: Note[]) => {
            const cwNote: CompositionWorkflowNote = {
                start: g[0].start,
                duration: g[0].duration,
                pitch: g.map(n => n.pitch),
            }

            cwLayer.score.push(cwNote)

            return true
        })

        return cwLayer
    }

    export function toScoreLayer({
        layerKey,
        layer,
        instruments,
        scoreLengthTimesteps,
        timeSignature,
        sections,
        pitchToChannelMapping,
    }: {
        layerKey: LayersValue
        layer: CompositionWorkflowLayer
        instruments: InstrumentsJSON
        scoreLengthTimesteps: number
        timeSignature: TimeSignature
        sections: Section[]
        pitchToChannelMapping: PitchToChannelMapping
    }): {
        layer: Layer
        trackBuses: TrackBus[]
    } {
        // First, create the score layer object based on the received data
        if (layerKey.includes("Percussion")) {
            return toPercussionScoreLayer({
                layerKey,
                layer,
                instruments,
                scoreLengthTimesteps,
                timeSignature,
                sections,
                pitchToChannelMapping,
            })
        }

        const layerObject = new Layer(
            "pitched",
            layerKey,
            null,
            null,
            {},
            timeSignature,
            null
        )

        const trackBuses = getTrackBussesFromCompositionWorkflowLayer(
            layer,
            instruments,
            scoreLengthTimesteps
        )

        addNotesToScoreLayer(
            layer,
            layerKey,
            layerObject,
            timeSignature,
            sections
        )

        return {
            layer: layerObject,
            trackBuses,
        }
    }

    function addNotesToScoreLayer(
        cwLayer: CompositionWorkflowLayer,
        layerKey: LayersValue,
        layerObject: Layer,
        timeSignature: TimeSignature,
        sections: Section[]
    ) {
        // Then, add the notes to the layer object
        for (const note of cwLayer.score) {
            const notes = note.pitch.map(p => {
                return new Note({
                    pitch: p,
                    start: note.start,
                    duration: note.duration,
                    meta: {
                        section: 0,
                        layer: layerKey,
                    },
                })
            })

            layerObject.notesObject.addNotesToGroup(
                notes,
                timeSignature,
                sections
            )
        }
    }

    export function toPercussionScoreLayer({
        layerKey,
        layer,
        instruments,
        scoreLengthTimesteps,
        timeSignature,
        sections,
        pitchToChannelMapping,
    }: {
        layerKey: LayersValue
        layer: CompositionWorkflowLayer
        instruments: InstrumentsJSON
        scoreLengthTimesteps: number
        timeSignature: TimeSignature
        sections: Section[]
        pitchToChannelMapping: PitchToChannelMapping
    }): {
        layer: PercussionLayer
        trackBuses: TrackBus[]
    } {
        const layerObject = new PercussionLayer(
            "percussion",
            layerKey,
            null,
            null,
            {},
            timeSignature,
            [],
            [],
            null,
            0
        )

        const trackBuses = getTrackBussesFromCompositionWorkflowLayer(
            layer,
            instruments,
            scoreLengthTimesteps
        )

        const result = generateSinglePattern(
            layer,
            pitchToChannelMapping,
            trackBuses,
            timeSignature
        )
        layerObject.patterns.push(result.pattern)
        layerObject.patternRegions.push(result.region)

        return {
            layer: layerObject,
            trackBuses: trackBuses,
        }
    }

    function generateSinglePattern(
        cwLayer: CompositionWorkflowLayer,
        mapping: PitchToChannelMapping,
        trackBuses: TrackBus[],
        timeSignature: TimeSignature
    ): {
        pattern: Pattern
        region: PatternRegion
    } {
        const pattern = new Pattern(uuidv4())
        pattern.bars = NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS

        for (const note of cwLayer.score) {
            for (const pitch of note.pitch) {
                for (const trackBus of trackBuses) {
                    let channel = pattern.channels.find(
                        c =>
                            c.pitches.includes(pitch) &&
                            c.trackBus.id === trackBus.id
                    )

                    if (channel === undefined) {
                        channel = new Channel(
                            mapping[pitch],
                            [pitch],
                            [],
                            false,
                            false,
                            trackBus
                        )

                        pattern.channels.push(channel)
                    }

                    channel.onsets.push({
                        start: note.start,
                    })
                }
            }
        }

        const region = new PatternRegion(
            {
                start: "0",
                onset: "0",
                duration: Time.multiplyFractionWithNumber(
                    timeSignature[0] + "/" + timeSignature[1],
                    NUMBER_OF_BARS_IN_COMPOSITION_WORKFLOWS
                ),
                loop: 0,
            },
            pattern
        )

        return {
            pattern,
            region,
        }
    }

    function getTrackBussesFromCompositionWorkflowLayer(
        cwLayer: CompositionWorkflowLayer,
        instruments: InstrumentsJSON,
        scoreLengthTimesteps: number
    ) {
        // Extract the instrument reference from the instruments JSON,
        // create a new track bus with it. We dont add the track bus to the layer
        // because it should be done with the appropriate score-rendering action
        // in order to make sure that the track bus is correctly loaded in memory

        if (cwLayer.instrument.patches.length === 0) {
            console.error(
                cwLayer.instrument.name + " has no patch in toScoreLayer"
            )
        }

        const trackBuses = cwLayer.instrument.patches.map(
            (p, index: number) => {
                if (!(p.patch instanceof Patch)) {
                    cwLayer.instrument.patches[index].patch = Patch.fromJSON(
                        p.patch
                    )
                }

                const reference = ScoreManipulation.getInstrumentReference(
                    p.patch.getFullName(),
                    instruments
                )

                return ScoreManipulation.createTrackBus(
                    p.patch.getFullName(),
                    reference,
                    scoreLengthTimesteps
                )
            }
        )

        return trackBuses
    }
}
