<div id="public-player-background">
    <div id="logo">
        <img src="assets/img/navbar/aiva_white.svg">
        AIVA
    </div>
    
    <div class="message-box" *ngIf="message.show" [ngClass]="message.type">
        <div class="close-button" (click)="messageService.hideMessage(message)">
            <img src="assets/img/close.svg">
        </div>
        <div class="text"> {{ message.text }} </div>
    </div>


    <!-- single composition player -->
    <div id="public-composition-container" *ngIf="playbackMode == 'single' && composition != null && composition.error == null">
        <div class="play-button" *ngIf="!isPlaying && !loading" (click)="play()"><img src="assets/img/player/play.svg"></div>
        <div class="play-button pause-button" *ngIf="isPlaying && !loading" (click)="pause()"><img src="assets/img/player/pause.svg"></div>
        <div class="loader" *ngIf="loading && playbackMode == 'single'"></div>

        <div class="composition-title" *ngIf="!loading">{{ composition.name }}</div>

        <div class="composition-subtitle" *ngIf="!loading">{{ composition.preset + ' • ' + composition.keySignature + ' • ' + composition.ensemble + ' • ' + composition.timeSignature[0] + '/' + composition.timeSignature[1] + ' Time Signature' + ' • ' + composition.pacing + ' pacing' }}</div>

        <div id="progress" *ngIf="!loading">
            <div id="time-elapsed">{{ getTimeElapsed() }}</div>

            <div id="progress-bar-wrapper" #progressBarWrapper (click)="setProgress($event)">
                <div id="progress-bar">
                    <div id="progress-bar-time" [style.width.%]='progress'></div>
                </div>
            </div>

            <div id="total-time">{{ getTotalTime() }}</div>
        </div>
        <div class="create-button public-player-button" (click)="goToAIVA()">Create a Track with AIVA</div>

        <div class="public-player-like">
            <img src="assets/img/love.svg" *ngIf="!isLiked()" (click)="toggleLiked(true)">
            <img src="assets/img/loved.svg" *ngIf="isLiked()" (click)="toggleLiked(false)">
        </div>
    </div>

    <div id="public-composition-container" *ngIf="playbackMode == 'single' && (composition == null || composition.error)">
        Could not load the composition. It was either deleted by its owner, or is not public anymore.
    </div>



    <!-- playlist composition player -->
    <div id="public-composition-container" *ngIf="playbackMode == 'playlist' && (message.show && message.type == 'error')">
        Could not load the playlist. It was either deleted by its owner, or is not public anymore.
    </div>
    <div id="public-composition-container" *ngIf="playbackMode == 'playlist' && numberOfSongs == 0 && !loading && !(message.show && message.type == 'error')">
        This playlist seems to be empty. If you are the owner you can add some compositions to change that.
    </div>

    <div id="public-playlist-container" *ngIf="playbackMode != 'single'">
        <div class="loader" *ngIf="loading && playbackMode == 'playlist'"></div>
        <div class="playlist-meta" *ngIf="playbackMode == 'playlist' && numberOfSongs > 0 && !loading">
            <div class="playlist-headline">Shared Playlist</div>
            <div class="playlist-name">{{ playlistName }}</div>
            <div class="playlist-info">{{ numberOfSongs }} songs, {{ playlistTime.hours }} hr {{ playlistTime.minutes }} mins</div>
        </div>

        <div class="composition-container" [perfectScrollbar]="scrollConfig" *ngIf="playbackMode == 'playlist' && numberOfSongs > 0 && !loading"> 
            <div class="composition-item" *ngFor="let composition of playlist; let i = index" (mouseenter)="mouseOnComposition(i, true)" (mouseleave)="mouseOnComposition(i, false)" (click)="play(i)">
                <div class="composition-item-wrapper">

                    <div class="composition-playback"*ngIf="composition">
                        <div class="playback-button" *ngIf="!isPlayingComposition(composition) && !playerIsLoadingComposition(composition._id)">
                            <img src="assets/img/player/play.svg">
                        </div>
                        <div class="playback-button playback-button-pause" (click)="pause()" *ngIf="isPlayingComposition(composition) && !playerIsLoadingComposition(composition._id)">
                            <img src="assets/img/player/pause.svg">
                        </div>
                        <div class="loader" *ngIf="composition && playerIsLoadingComposition(composition._id)"></div>
                    </div>
                    <div class="composition-meta">
                        <div class="composition-title" *ngIf="!loading && composition">{{ composition.name }}</div>
                        <div class="composition-subtitle" *ngIf="!loading && composition">{{ composition.preset + ' • ' + composition.keySignature + ' • ' + composition.ensemble + ' • ' + composition.timeSignature[0] + '/' + composition.timeSignature[1] + ' Time Signature' + ' • ' + composition.pacing + ' pacing' }}</div>
                    </div>
                    <div class="composition-duration">
                        {{ getCompositionDuration(composition) }}
                    </div>
                </div>
            </div>
        </div>

       
        <div class="player-container" *ngIf="playbackMode == 'playlist' && numberOfSongs > 0 && !loading">

            <div class="main-playback">
                <div class="controls">
                    <div class="previous" (click)="previous()" *ngIf="!playerIsLoadingComposition(composition._id)"></div>
    
                    <div class="play-pause">
                        <div class="playback-button" (click)="play(currentSongIndex)" *ngIf="!isPlaying && !playerIsLoadingComposition(composition._id)">
                            <img src="assets/img/player/play.svg">
                        </div>
                        <div class="playback-button playback-button-pause" (click)="pause()" *ngIf="isPlaying && !playerIsLoadingComposition(composition._id)">
                            <img src="assets/img/player/pause.svg">
                        </div>
                        <div class="loader" *ngIf="playerIsLoadingComposition(composition._id)"></div>
                    </div>
    
                    <div class="next" (click)="next()" *ngIf="!playerIsLoadingComposition(composition._id)"></div>
                </div>
            </div>

            <div class="composition-meta">
                <div class="composition-title" *ngIf="composition">{{ composition.name || " " }}</div>
                <div class="composition-subtitle" *ngIf="composition">{{ (composition.preset + ' • ' + composition.keySignature + ' • ' + composition.ensemble + ' • ' + composition.timeSignature[0] + '/' + composition.timeSignature[1] + ' Time Signature' + ' • ' + composition.pacing + ' pacing') || " " }}</div>
            </div>

            <div id="progress" *ngIf="!loading">
                <div id="time-elapsed">{{ timeElapsedText }}</div>
            
                <div id="progress-bar-wrapper" #progressBarWrapper (click)="setProgress($event)">
                    <div id="progress-bar">
                        <div id="progress-bar-time" [style.width.%]='progress'></div>
                    </div>
                </div>
            
                <div id="total-time">{{ getTotalTime() }}</div>
            </div>
        </div>
        <div class="create-button public-player-button playlist" (click)="goToAIVA()" *ngIf="!loading">Create a Track with AIVA</div>
    </div>
    
</div>