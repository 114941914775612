import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from "@angular/core"
import { CompositionWorkflowType } from "@common-lib/types/general"
import { CreateService } from "@services/create.service"
import { GeneralService } from "@services/general/general.service"
import { ModalService } from "@services/modal.service"
import { UserService } from "@services/user.service"

@Component({
    selector: "select-gp-for-cw",
    templateUrl: "select-gp-for-cw.component.html",
    styleUrls: ["select-gp-for-cw.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectGPForCWComponent {
    @Input() subtitle: string
    @Input() selectGPForCW?: (gp) => void
    @Input() type: CompositionWorkflowType
    @Input() loading: boolean = false

    public searchTimeout
    public hasLoadedSearch = false

    constructor(
        protected createService: CreateService,
        protected generalService: GeneralService,
        protected userService: UserService,
        protected ref: ChangeDetectorRef,
        protected modalService: ModalService
    ) {
        if (
            this.createService.gpStates.gps.length === 0 ||
            this.createService?.states?.generationProfiles?.search !== ""
        ) {
            this.initiateSearch("", false)
        }
    }

    public initiateSearch(search: string, useTimeout = true) {
        if (
            this.createService.states.generationProfiles.search === search &&
            this.hasLoadedSearch
        ) {
            return
        }

        this.hasLoadedSearch = true
        this.createService.states.generationProfiles.search = search

        /**
         * In case there is still a search key typed into the GP search,
         * we want to revert back to the original state of the GP list.
         * Using a timeout in this case would lead to flickering. Therefore
         * we reset the filters and the current page immediately in this case.
         */
        if (!useTimeout) {
            this.createService.resetFilters()
            this.createService.setCurrentPage(1)
            return
        }

        if (this.searchTimeout != null) {
            clearTimeout(this.searchTimeout)
            this.searchTimeout = null
        }

        this.searchTimeout = setTimeout(() => {
            this.createService.resetFilters()
            this.createService.setCurrentPage(1)
        }, 250)
    }

    public async selectExisingCompositionWorkflow() {
        this.modalService.modals.selectCompositionWorkflow.next({
            type: this.type,
        })
    }
}
