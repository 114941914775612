import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"

@Component({
    selector: "slide-toggle-with-titles",
    templateUrl: "./slide-toggle-with-titles.component.html",
    styleUrls: ["./slide-toggle-with-titles.component.scss"],
})
export class SlideToggleWithTitlesComponent implements OnInit {
    @Input() toggled: boolean                               // two-way binding variable to indicate the toggle state
    @Output() toggledChange = new EventEmitter<boolean>()   // two-way binding EventEmitter to emit the toggle state on change

    @Input() disabled: boolean = false
    @Input() left: string
    @Input() right: string
    @Input() class: string
    @Output() onChange = new EventEmitter<boolean>()        // EventEmitter that emits the toggle state as a boolean value on toggle changes

    toggledValue: boolean = false

    ngOnInit(): void {}

    ngAfterViewInit() {
    }

    public onToggle() {
        if(this.disabled){
            return
          }
        
        this.onChange.emit(this.toggled)
    }
}
