<div class="tile-influence-dragover" #dragover>
    <ngx-file-drop
        (onFileDrop)="dropped($event)"
        (onFileOver)="onFileOver($event)"
        (onFileLeave)="onFileLeave($event)"
        dropZoneClassName="dropzone"
        *ngIf="currentStepIndex === 0">
        <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector">
            <div #dropzone>
                <div class="tile-influence-dragover-title" #dragoverTitle>
                    <img src="assets/img/menu/influences.svg" /> Drop your
                    influence to automatically populate this layer
                </div>
            </div>
        </ng-template>
    </ngx-file-drop>

    <steps
        [steps]="steps"
        [currentStepIndex]="currentStepIndex"
        *ngIf="currentStepIndex === 1 || currentStepIndex === 2"></steps>

    <div *ngIf="currentStepIndex === 1" class="step">
        <div class="settings-row">
            <div class="settings-row-title">
                Key signature
                <img
                    class="tooltip-img"
                    src="assets/img/info.svg"
                    [tooltip]="tooltips.keySignature"
                    [tooltip-marginTop]="10"
                    [tooltip-width]="250" />
            </div>

            <div class="settings-row-value">
                <dropdown
                    class="do-you-know-key-signature"
                    type="light"
                    [value]="keySignature"
                    [showTooltip]="false"
                    [items]="getKeySignatureOptions()"
                    (selectedItem)="changeKeySignature($event)"></dropdown>
            </div>
        </div>

        <div class="settings-row">
            <div class="settings-row-title">
                Time signature
                <img
                    class="tooltip-img"
                    src="assets/img/info.svg"
                    [tooltip]="tooltips.timeSignature"
                    [tooltip-marginTop]="10"
                    [tooltip-width]="250" />
            </div>

            <div class="settings-row-value">
                <dropdown
                    class="do-you-know-key-signature"
                    type="light"
                    [value]="timeSignature"
                    [showTooltip]="false"
                    [items]="getTimeSignatureOptions()"
                    (selectedItem)="changeTimeSignature($event)"></dropdown>
            </div>
        </div>

        <div class="upload-influence-key-signature-subtitle"></div>

        <div class="button-container">
            <a-button
                label="Cancel"
                width="50%"
                type="text"
                (onClick)="cancel()"></a-button>
            <a-button
                label="Next"
                width="50%"
                type="secondary"
                (onClick)="next()"></a-button>
        </div>
    </div>

    <div *ngIf="currentStepIndex === 2" class="step">
        <audio-trimmer
            [file]="file"
            [config]="audioTrimmerConfig"
            (onError)="onAudioTrimmerError($event)"></audio-trimmer>

        <div class="button-container" *ngIf="!isTrimming">
            <a-button
                label="Previous"
                width="50%"
                type="text"
                (onClick)="currentStepIndex = 1"></a-button>
            <a-button
                label="Next"
                width="50%"
                type="secondary"
                (onClick)="trimAndEncode()"></a-button>
        </div>
    </div>

    <div *ngIf="currentStepIndex === 3" class="step step-3">
        <div class="progress-bar-title">{{ progressMessage }}</div>
        <progressbar
            [progress]="gpInfluenceLoading.progress"
            [total]="100"></progressbar>

        <a-button
            label="Cancel analysis"
            type="white-text"
            *ngIf="gpInfluenceLoading.progress >= analysisProgress"
            (onClick)="cancelAnalysis()"></a-button>
    </div>

    <div *ngIf="currentStepIndex === 4" class="step step-4">
        <svg
            class="cross-red"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52">
            <circle class="cross__circle" cx="26" cy="26" r="25" fill="none" />
            <path
                class="cross__crossed"
                fill="none"
                d="M16 16 36 36 M36 16 16 36" />
        </svg>

        <div class="progress-bar-title">{{ gpInfluenceLoading.error }}</div>

        <a-button
            label="Cancel analysis"
            type="white-text"
            (onClick)="cancelAnalysis()"></a-button>
    </div>
</div>
