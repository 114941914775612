import { Note, NoteLabel } from "@common-lib/classes/score/note"
import PreviewNote from "../classes/generationprofiles/previewNote"

export const sliceSharpNote = (note: NoteLabel) => note.slice(0, 2) as NoteLabel

export const sliceFlatNote = (note: NoteLabel) => (
    note.includes("/") ? note.slice(3, 5) : note
) as NoteLabel

export const previewNotesToNotes = (previewNotes: PreviewNote[], layerName:string): Note[] =>
    previewNotes.reduce(
        (acc, previewNote, index) => [
            ...acc,
            ...previewNote.pitch.map((p, i) => {
                const note = new Note({
                    start: previewNote.onset,
                    duration: previewNote.duration,
                    pitch: p,
                    meta: {
                        section: 0,
                        layer: layerName
                    }
                })

                return note
            })
    ], [])