export default class AIVAObject {
    copy(object:AIVAObject) {
        const keys = Object.keys(this)

        for (var k = 0; k < keys.length; k++) {
            const key = keys[k]

            if (object[key] != null && typeof object[key] == "object" && Array.isArray(object[key])) {
                this[key] = Object.assign([], object[key])
            }

            else if (object[key] != null && typeof object[key] == "object") {
                this[key] = Object.assign({}, object[key])
            }

            else {
                this[key] = object[key]
            }
        }
    }
}