import { Component, Input } from "@angular/core"

@Component({
    selector: "tooltip",
    templateUrl: "tooltip.component.html",
    styleUrls: ["tooltip.component.scss"],  
})
export class TooltipComponent {
    @Input() text: string
    @Input() description: string
    @Input() width: string
    @Input() marginLeft: number = 0
    @Input() marginTop: number = 0
    @Input() marginRight: number = 0
    @Input() marginBottom: number = 0
    @Input() textAlign: string = "left"
    @Input() class: string

    constructor() {}
}
