import {
    Component,
    ChangeDetectionStrategy,
    EventEmitter,
    Output,
    ChangeDetectorRef,
} from "@angular/core"
import GPSettings from "@common-lib/classes/generationprofiles/gpsettings"
import Harmony from "@common-lib/classes/generationprofiles/harmony/harmony"
import HarmonyPack from "@common-lib/classes/generationprofiles/harmony/harmonypack"
import { Misc } from "@common-lib/modules/misc"
import { GeneralService } from "../../../services/general/general.service"
import { GenerationProfileService } from "../../../services/generation-profile/generationprofile.service"
import { DropdownItemType } from "../../../types/dropdownItemType"
import { SourcePackService } from "../../../services/source-packs/sourcepacks.service"

@Component({
    selector: "chord-datasets",
    templateUrl: "chord-datasets.component.html",
    styleUrls: ["./chord-datasets.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChordDatasetsComponent {
    @Output() close: EventEmitter<any> = new EventEmitter()

    loading: boolean = true

    sourceHarmonyPacks: HarmonyPack[] = []
    chordDatasets: Harmony[] = []

    chordDatasetFilter: DropdownItemType<string> = {
        value: "none",
        name: "Show all",
        optgroup: "None",
    }

    scrollChange = {
        useBothWheelAxes: false,
        suppressScrollX: true,
    }

    constructor(
        private sourcePacks: SourcePackService,
        private gpService: GenerationProfileService,
        private generalService: GeneralService,
        private ref: ChangeDetectorRef
    ) {}

    async ngOnInit() {
        this.loading = true

        this.sourceHarmonyPacks = await this.sourcePacks.getHarmonyPacks()
        this.chordDatasets = this.getChordDatasets()


        this.loading = false

        this.ref.detectChanges()
    }

    getChordDatasetFilterOptions() {
        let filters = [
            {
                value: "none",
                name: "Show all",
                optgroup: "None",
            },

            {
                value: "simple",
                name: "Simple",
                optgroup: "By Chord Categories",
            },

            {
                value: "intermediate",
                name: "Intermediate",
                optgroup: "By Chord Categories",
            },

            {
                value: "advanced",
                name: "Advanced",
                optgroup: "By Chord Categories",
            },

            {
                value: "7th",
                name: "7th",
                optgroup: "By Chord Categories",
            },
        ]

        let styleCache = {}

        for (let pack of this.sourceHarmonyPacks) {
            for (let style of pack.recommendedStyles) {
                if (styleCache[style] == null) {
                    filters.push({
                        value: style,
                        name: style,
                        optgroup: "By Styles",
                    })

                    styleCache[style] = 1
                }
            }
        }

        return filters
    }

    changeFilter(event) {
        this.chordDatasetFilter = event.new
        this.chordDatasets = this.getChordDatasets()
    }

    getChordDatasets() {
        /* 
		we take the packs with packIDs ending on the same number because this is also how the selected packID looks like
		otherwise the checkboxes won't match the selected value
		this is due the fact that there are multiple variants for each chord pack depending on the rhythm and pacing selection 
		*/
        let harmonicRhythm = Harmony.getAggregatedHarmonicRhythm(
            this.gpService.generationProfile.harmony.packs
        )
        let harmonicRepetition = Harmony.getAggregatedHarmonicRepetition(
            this.gpService.generationProfile.harmony.packs
        )
        let filteredHarmonyPacks = []
        let seventhPack = this.sourceHarmonyPacks.find(
            p =>
                p.packID.includes("7th_chords_1") &&
                p.harmonicRhythm.min == 0 &&
                p.harmonicRhythm.max == 0 &&
                p.harmonicRepetition.min == harmonicRepetition.min &&
                p.harmonicRepetition.max == harmonicRepetition.max
        )
        let seventhMissing = true

        for (let pack of this.sourceHarmonyPacks) {
            let equalHarmonicRepetition =
                pack.harmonicRepetition.min == harmonicRepetition.min &&
                pack.harmonicRepetition.max == harmonicRepetition.max
            let equalHarmonicRhythm =
                pack.harmonicRhythm.min == harmonicRhythm.min &&
                pack.harmonicRhythm.max == harmonicRhythm.max

            if (equalHarmonicRepetition && equalHarmonicRhythm) {
                filteredHarmonyPacks.push(pack)
            }

            if (
                seventhMissing == true &&
                pack.packID.includes("7th_chords_1")
            ) {
                filteredHarmonyPacks.push(seventhPack)
                seventhMissing = false
            }
        }

        let harmonyPacks = filteredHarmonyPacks.reduce((unique, pack) => {
            if (!unique.some(obj => obj.name === pack.name)) {
                unique.push(pack)
            }

            return unique
        }, [])

        // we need this to apply the filter whenever the filter setting changes
        if (
            this.chordDatasetFilter != null &&
            this.chordDatasetFilter.optgroup != "None"
        ) {
            for (let p = harmonyPacks.length - 1; p >= 0; p--) {
                const pack = harmonyPacks[p]

                let hasChordCategories =
                    this.chordDatasetFilter.optgroup == "By Chord Categories" &&
                    pack.name.includes(this.chordDatasetFilter.name)
                let hasRecommendedStyle =
                    this.chordDatasetFilter.optgroup == "By Styles" &&
                    pack.recommendedStyles.includes(
                        this.chordDatasetFilter.name
                    )

                if (!hasChordCategories && !hasRecommendedStyle) {
                    harmonyPacks.splice(p, 1)
                }
            }
        }

        return harmonyPacks
    }

    async changeSelectedDataset(dataset: HarmonyPack) {
        await this.gpService.selectHarmonyPack(dataset)
        
        this.gpService.setAsUpdated("harmonyDataset", { layer: "Harmony" })
    }

    getMoodCompatibleWithHarmonicDataset(dataset: HarmonyPack) {
        let emotions = []

        for (let emotion in GPSettings.MOOD_OPTIONS.harmony) {
            let datasets = GPSettings.MOOD_OPTIONS.harmony[emotion].datasets

            if (datasets.includes(dataset.chordsGroupID[0])) {
                emotions.push(
                    "assets/img/emojis/" +
                        this.generalService.getEmojiNameByEmotionID(emotion) +
                        ".svg"
                )
            }
        }

        return emotions
    }

    isSelectedChordDataset(packID: string) {
        if (
            this.gpService.generationProfile.harmony.packs.some(
                p => p.packID === packID
            )
        ) {
            return packID
        }

        return
    }
}
