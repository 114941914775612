import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { DesignService } from './design.service'
import { Time }from '@common-lib/modules/time'
import { TIMESTEP_RES } from '@common-lib/constants/constants'

@Injectable() 
export class PianorollScrollService {
    minWidth:BehaviorSubject<number> = new BehaviorSubject<number>(null)

    influenceLoaded = false
    spectatorMode = false

    scrollLeft:number = -1
    leftPosition:number = 0
    scrollbarWidth:number = null
    maxScrollLeft:number = null
    totalWidth:number = 0
    thumbWidth:number = 0 

    scrollTop:number = -1
    topPosition:number = 0
    scrollbarHeight:number = null
    maxScrollTop:number = null
    totalHeight:number = 0
    thumbHeight:number = 0
    clientHeight:number = 0

    extendPianoRoll:BehaviorSubject<number> = new BehaviorSubject<number>(null)

    constructor(private designService:DesignService) {

    }

    spectatorModeIsOn() {
		return this.influenceLoaded || this.spectatorMode
	}

    getMinWidth() {
        return this.minWidth.getValue()
    }

    setMinWidth(scoreLength, timestepWidth) {
		const end = Time.fractionToTimesteps(TIMESTEP_RES, scoreLength)
        
		let numberOfTimesteps = end + TIMESTEP_RES
        
        if(numberOfTimesteps * timestepWidth != this.minWidth.getValue()){
            this.minWidth.next(numberOfTimesteps * timestepWidth)
            console.log("minWidth", numberOfTimesteps * timestepWidth)
        }
	}

    setTopScrollbarValues(clientHeight, scrollTop) {
        this.maxScrollTop = scrollTop - clientHeight
        this.scrollbarHeight = clientHeight

        this.totalHeight = this.maxScrollTop + this.scrollbarHeight
        this.topPosition = (this.scrollTop / this.totalHeight) * this.scrollbarHeight
        this.thumbHeight = this.scrollbarHeight * 100 / this.totalHeight
    }

    setScrollTop(scrollTop) {       
        this.scrollTop = Math.max(0, Math.min(scrollTop, this.maxScrollTop))
    }

    setLeftScrollbarValues(clientWidth) {
        var paddingLeft
        
        if (this.spectatorMode) {
            paddingLeft = parseInt(this.designService.getCSSProperty('spectator-padding'))
        }
        
        else {
            paddingLeft = parseInt(this.designService.getCSSProperty('editor-padding'))
        }
        
        this.maxScrollLeft = this.setMaxScrollLeft(clientWidth, paddingLeft)
        this.scrollbarWidth = clientWidth
        
        this.setScrollLeft(this.scrollLeft, "setLeftScrollbarValues")
        
        this.totalWidth = this.maxScrollLeft + this.scrollbarWidth        
        this.leftPosition = (this.scrollLeft / this.totalWidth) * this.scrollbarWidth
        this.thumbWidth = this.scrollbarWidth * 100 / this.totalWidth
    }

    setMaxScrollLeft(clientWidth, paddingLeft) {
        var maxScrollLeft = this.getMinWidth() - clientWidth - paddingLeft

        if (isNaN(maxScrollLeft)) {
            maxScrollLeft = 0
        }

        return Math.max(0, maxScrollLeft)
    }

    scrollLeftValuesAreUndefined() {
        return this.scrollbarWidth == null || this.maxScrollLeft == null
    }

    setScrollLeft(offset, location) {
        this.scrollLeft = Math.max(0, Math.min(this.maxScrollLeft, offset))
    }

    scrollTopTo(y, type="absolute") {
        var value = y

        if (type != "absolute") {
            value += this.scrollTop    
        }

        this.setScrollTop(value)
    }

    scrollLeftTo(x, type="absolute") {
        var previousLeftValue = this.scrollLeft
        var value = x

        if (type != "absolute") {
            value += this.scrollLeft    
        }

        this.setScrollLeft(value, "scrollLeftTo")

        var difference = this.scrollLeft - previousLeftValue

        // scroll forwards
        if (this.scrollLeft >= this.maxScrollLeft - 50 && difference > 0 && !this.influenceLoaded) {
            this.extendPianoRoll.next(8)
        }

        // scroll backwards
        else if (difference < 0 && this.scrollLeft  < this.maxScrollLeft - 500 && !this.influenceLoaded) {
            this.extendPianoRoll.next(-4)
        }
    }
}