import { DropdownItemType } from "./types/dropdownItemType"
import { NoteResolution } from "@common-lib/types/score"
import { BarCount } from "@common-lib/types/score"
import { LayerType } from "@common-lib/constants/constants"
import { sliceSharpNote, sliceFlatNote } from "@common-lib/utils"
import { Note, NoteLabel } from "@common-lib/classes/score/note"

export const NOTE_RESOLUTIONS: DropdownItemType<NoteResolution>[] = [
    { name: "4th note", value: "1/4" },
    { name: "8th note", value: "1/8" },
    { name: "12th note", value: "1/12" },
    { name: "16th note", value: "1/16" },
    { name: "24th note", value: "1/24" },
    { name: "48th note", value: "1/48" },
]

export const PATTERN_LENGTHS: DropdownItemType<BarCount>[] = [
    { name: "1 bar", value: 1 },
    { name: "2 bars", value: 2 },
    { name: "4 bars", value: 4 },
]

export const LAYER_TYPES: DropdownItemType<LayerType>[] = [
    { name: LayerType.ACCOMPANIMENT, value: LayerType.ACCOMPANIMENT },
    { name: LayerType.BASS, value: LayerType.BASS },
]

export enum NoteEditingType {
    COPY = "copy",
    CUT = "cut",
    PASTE = "paste",
    SELECT_ALL = "selectAll",
}

export enum NoteResizeType {
    END_TO_RIGHT = "endToRight",
    END_TO_LEFT = "endToLeft",
    START_TO_RIGHT = "startToRight",
    START_TO_LEFT = "startToLeft",
}

export enum NoteDraggingType {
    START = "start",
    CENTER = "center",
    END = "end",
}

export const MAJOR_MINOR_SCALES_WITH_TRIADS: DropdownItemType<NoteLabel[]>[] = [
    ...Note.MAJOR_SCALES.map((notes: NoteLabel[]) => ({
        name: sliceSharpNote(notes[0]) + " major",
        value: notes.map(n => sliceSharpNote(n)),
        optgroup: "Major",
    })),
    ...Note.MINOR_SCALES.map((notes: NoteLabel[]) => ({
        name: sliceFlatNote(notes[0]) + " minor",
        value: notes.map(n => sliceFlatNote(n)),
        optgroup: "Minor",
    })),
]

export const C1_PITCH = 36

/**
 * triggers rerendering of the canvas whenever there are only X px left to the left or the right
 */
export const NOTE_RENDER_THRESHOLD = 250

/**
 * always render X px more to the left and the right as a buffer
 */
export const NOTE_RENDER_BUFFER = 500
