import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core"
import { GenerationProfileService } from "@services/generation-profile/generationprofile.service"
import { moveItemInArray } from "@angular/cdk/drag-drop"
import { DesignService } from "@services/design.service"
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar"
import FormDescription from "@common-lib/classes/generationprofiles/formdescription/formdescription"

@Component({
    selector: "layer-priorities",
    templateUrl: "layer-priorities.component.html",
    styles: [],
    styleUrls: ["./layer-priorities.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerPrioritiesComponent implements OnInit {
    scrollConfig = { useBothWheelAxes: false, suppressScrollX: true }
    @ViewChild(PerfectScrollbarDirective, { static: false })
    perfectscroll: PerfectScrollbarDirective

    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor(
        private designService: DesignService,
        private gpService: GenerationProfileService,
        private ref: ChangeDetectorRef
    ) {}

    getLayersOrder() {
        return this.gpService.generationProfile.settings.layersOrder
    }

    deleteLayersOrderRow(index) {
        this.gpService.generationProfile.settings.layersOrder.splice(index, 1)
        this.gpService.setAsUpdated("layersOrder")
    }

    ngOnInit(): void {}

    closeModal() {
        this.close.emit()
    }

    drop(orderIndex, event) {
        let layersOrder = this.getLayersOrder()[orderIndex]

        moveItemInArray(layersOrder, event.previousIndex, event.currentIndex)

        this.ref.detectChanges()

        this.gpService.setAsUpdated("layersOrder")
    }

    randomNumberInRange(max, min, excludes = []) {
        while (true) {
            let result = Math.floor(Math.random() * (max - min)) + min

            let retry = excludes.includes(result)

            if (!retry) {
                return result
            }
        }
    }

    generateLayersOrder() {
        const layers = FormDescription.getLayerTypes(["Ornaments"])

        let foundUniqueLayersOrder = false
        let generatedLayerOrder

        while (!foundUniqueLayersOrder) {
            generatedLayerOrder = new Array(layers.length)

            let excludes = []

            for (let layer of layers) {
                let index = this.randomNumberInRange(layers.length, 0, excludes)
                generatedLayerOrder[index] = layer
                excludes.push(index)
            }

            let stringifiedArray = JSON.stringify(generatedLayerOrder)

            let found = true

            for (let order of this.getLayersOrder()) {
                let otherStringifiedArray = JSON.stringify(order)

                if (stringifiedArray == otherStringifiedArray) {
                    found = false
                    break
                }
            }

            foundUniqueLayersOrder = found
        }

        return generatedLayerOrder
    }

    addLayersOrder() {
        this.gpService.generationProfile.settings.layersOrder.push(
            this.generateLayersOrder()
        )

        this.gpService.setAsUpdated("layersOrder")
        this.ref.detectChanges()
        this.perfectscroll.scrollToBottom()
    }

    getColorForLayer(layer) {
        return this.designService.getColorForLayer(layer)
    }
}
