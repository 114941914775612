import { AccompanimentManipulation } from "../../../modules/accompaniment-pack/accompaniment-manipulation"
import InstrumentPatch from "../../score/instrumentpatch"
import Pack from "../pack"

export default class MelodyPack extends Pack {
    phraseGroupID: Array<string> = []
    phraseFormsTags: Array<string> = []
    complexity = 0
    phraseLength = "medium"

    constructor(
        id,
        instruments = [],
        complexity,
        phraseLength,
        phraseGroupID: Array<string> = [],
        phraseFormsTags: Array<string> = []
    ) {
        super(
            id,
            "",
            [],
            instruments,
            AccompanimentManipulation.getLowestNoteByLayer("Melody")
        )

        this.complexity = complexity
        this.phraseLength = phraseLength
        this.phraseGroupID = phraseGroupID
        this.phraseFormsTags = phraseFormsTags
    }

    static fromJSON(object) {
        let pack: MelodyPack = Object.assign(
            new MelodyPack("", [], "", ""),
            object
        )

        for (let instrument of object.instruments) {
            pack.instruments.push(InstrumentPatch.fromJSON(instrument))
        }

        return pack
    }
}
