import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from "@angular/core"
import Layer from "@common-lib/classes/score/layer"
import TrackBus from "@common-lib/classes/score/trackbus"
import { EditorService } from "@services/editor/editor.service"
import { ModalService } from "@services/modal.service"
import { ParentClass } from "../../../parent"
import { ScoreUpdateType } from "../../../../../../common-lib/client-only/score-rendering-engine"
import PercussionLayer from "@common-lib/classes/score/percussionlayer"
import { InitCanvases } from "../../../modules/init-canvases.module"
import { SRActionTypes } from "../../../../../../common-lib/client-only/score-rendering-engine/states/score-rendering/score-rendering.actions"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { SubscriptionHelpers } from "@common-lib/modules/subscription-helpers.module"

@Component({
    selector: "trackbus",
    templateUrl: "trackbus.component.html",
    styleUrls: ["trackbus.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackBusComponent extends ParentClass implements AfterViewInit {
    @Input() editor: EditorService

    constructor(
        public modal: ModalService,
        public instruments: InstrumentsService,
        private ref: ChangeDetectorRef
    ) {
        super()
    }

    async ngAfterViewInit() {
        SubscriptionHelpers.subscribeWithPrevious(
            this.editor.engine.toggledLayer$,
            this.initialiseData.bind(this),
            this.destroy$
        )

        this.subscribe(
            this.editor.engine.scoreUpdate$,
            (value: ScoreUpdateType) => {
                if (value.includes("TrackBusMetadata")) {
                    this.ref.detectChanges()
                } else if (
                    value.includes("All") ||
                    value.includes("TrackBus")
                ) {
                    this.initialiseData({
                        previous: undefined,
                        current: this.editor.engine.toggledLayer,
                    })
                }
            }
        )
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
    }

    public selectTrackBus(event: MouseEvent, tb: TrackBus) {
        const element = event.target as Element

        if (element?.parentElement?.classList.contains("left-label")) {
            this.editor.engine.srEmitter$.next({
                type: SRActionTypes.selectTrackBus,
                data: {
                    tb,
                    keepPreviousSelection: event.shiftKey,
                },
            })
        }
    }

    /**
     * this method defines if the instrument layer item was clicked
     * It checks which target has been clicked and returns true if the target matches the valid instrument item targets
     * @param event Mouseevent
     * @returns boolean
     */
    private hasClickedOnInstrumentItem(event: MouseEvent) {
        let validTargetClasses = [
            "instrument-controls",
            "instrument-and-loudness-meter",
            "layer-list-settings",
        ]
        let targetClasses = (event.target as Element).classList

        for (let className of validTargetClasses) {
            if (targetClasses.contains(className)) {
                return true
            }
        }

        return false
    }

    private async initialiseData(args: {
        previous: Layer | PercussionLayer | undefined
        current: Layer | PercussionLayer | undefined
    }) {
        if (
            args.current === undefined ||
            args.current?.value === args.previous?.value
        ) {
            return
        }

        this.ref.detectChanges()

        if (args.current.type === "pitched") {
            this.clearCanvases()

            const promises = args.current.trackBuses.map(tb => {
                return InitCanvases.initTrackBusRegionsCanvas(
                    args.current,
                    tb,
                    this.editor.engine
                )
            })

            await Promise.all(promises)
        } else if (args.current.type === "percussion") {
            this.clearCanvases()

            await InitCanvases.initPatternRegionCanvas(this.editor.engine)
        }
    }

    private clearCanvases() {
        this.editor.engine.deleteCanvas("TrackbusRegionsCanvas")
        this.editor.engine.deleteCanvas("PatternRegionsCanvas")
    }
}
