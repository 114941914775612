<div class="content" av-data="cw-step1-component">
    <ng-container *ngIf="!isDesktopApp || instrumentsLoadingPercentage === 100">
        <div class="controls">
            <div>
                <ng-container>
                    <div class="column">
                        <resizer
                            [engine]="service.query.engine"
                            [attr.av-data]="'zoom-slider'"
                            [width]="'90px'"></resizer>
                    </div>
                </ng-container>
            </div>
            <div class="composition centered-composition">
                <div
                    class="column"
                    id="regenerate-button-column"
                    av-data="prompt-wrapper">
                    <div class="input-wrapper">
                        <input
                            placeholder="Describe the chord progression you want to create"
                            [(ngModel)]="prompt"
                            (ngModelChange)="service.setPrompt(prompt)"
                            maxlength="100"
                            av-data="step1-prompt-input" />

                        <div class="magic-button-wrapper">
                            <magic-button
                                avData="step1-generate-button"
                                [title]="'Generate'"
                                fontSize="95%"
                                (onClick)="
                                    regenerateChordProgression(
                                        $event,
                                        false,
                                        true,
                                        true
                                    )
                                "></magic-button>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="column column-right">
                    <a-button
                        type="light"
                        class="cw-step-1-settings-button"
                        label="Settings"
                        (click)="toggleChordProgressionSettings($event, false)"
                        avData="settingsbtn"
                        [icon]="'assets/img/pianoroll/settings.svg'"></a-button>
                </div>
            </div>
        </div>

        <div class="controls" *ngIf="prompt.length > 0">
            <div
                class="composition centered-composition best-practises-subtitle">
                <div
                    class="best-practises-subtitle-container"
                    (click)="bestPractises()"
                    [ngStyle]="{
                        'margin-right': harmonyPromptId ? '15px' : '0px'
                    }"
                    av-data="step1-prompt-hint">
                    <img src="assets/img/info.svg" />
                    <div class="subtitle-text">Best practises</div>
                </div>

                <ng-container>
                    <like-btn
                        size="small"
                        color="white"
                        [shouldAnimate]="true"
                        [dissapearAnimation]="hideRateButtons"
                        (onClick)="rateGeneration('liked')"
                        avData="step1-like-btn"
                        *ngIf="harmonyPromptId"></like-btn>
                    <like-btn
                        size="small"
                        color="white"
                        [rotate]="true"
                        [shouldAnimate]="true"
                        [dissapearAnimation]="hideRateButtons"
                        (onClick)="rateGeneration('disliked')"
                        avData="step1-dislike-btn"
                        *ngIf="harmonyPromptId"></like-btn>
                </ng-container>
            </div>

            <div></div>
        </div>
    </ng-container>

    <div
        class="download-progress"
        *ngIf="isDesktopApp && instrumentsLoadingPercentage < 100">
        <div
            class="progress-container__text"
            *ngIf="instrumentsLoadingPercentage < 50">
            Downloading your instruments
        </div>

        <div
            class="progress-container__text"
            *ngIf="instrumentsLoadingPercentage >= 50">
            Loading instruments in memory
        </div>

        <div class="progressbar-container">
            <progressbar
                [progress]="instrumentsLoadingPercentage"
                [total]="100"
                width="222px"
                class="progress-container__progress">
            </progressbar>
        </div>
    </div>
    <ng-container *ngIf="loading.finished">
        <div class="play-head-wrapper">
            <play-head
                id="play-head-position"
                class="play-head-position"
                [engine]="engine"></play-head>
        </div>

        <div
            id="pianoroll-grid"
            class="canvas-container"
            av-data="piano-roll-canvas"></div>
        <chords-editing
            [engine]="engine"
            [zeroedPosition]="true"
            editorType="composition-workflow"></chords-editing>
        <div
            id="layer-preview-chords"
            class="canvas-container"
            av-data="layer-preview-canvas"></div>
    </ng-container>

    <ng-container *ngIf="!loading.finished && !loading.error">
        <div class="loading animate">Generating a chord progression</div>
    </ng-container>
    <ng-container *ngIf="!!loading.error">
        <div class="loading">
            <div class="error-container">
                <img src="assets/img/warning.svg" />

                <span class="title-error" av-data="error-text">{{
                    loading.error
                }}</span>
            </div>
            <div class="error-button-container">
                <a-button
                    label="Retry generation"
                    width="170px"
                    [class]="'error icon-with-padding'"
                    (click)="
                        regenerateChordProgression(
                            undefined,
                            false,
                            false,
                            false
                        )
                    "
                    [icon]="'assets/img/menu/regenerate.svg'"
                    [avData]="'regenerate-button'"></a-button>
            </div>
        </div>
    </ng-container>
</div>

<menu-modifiers
    [modifiers]="keySignatureMenu"
    *ngIf="keySignatureMenu !== undefined"
    (close)="toggleKeySignatureMenu($event, undefined, true)">
</menu-modifiers>

<menu-modifiers
    [modifiers]="chordProgressionSettings"
    *ngIf="chordProgressionSettings !== undefined"
    (close)="toggleChordProgressionSettings($event, true)">
</menu-modifiers>
