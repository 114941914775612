import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy } from "@angular/core"
import { InstrumentsService } from "@services/instruments/instruments.service"
import { PotionService } from "@services/potion.service"

@Component({
    selector: 'autofill',
    templateUrl: 'autofill.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AutofillComponent implements OnInit {
    @Output() close:EventEmitter<any> = new EventEmitter()

    selectedInstrument

    isLoading = false

    instruments = []

    constructor(private potionService:PotionService, private instrumentsService:InstrumentsService) {        
        
    }

    ngOnInit() {
        
    }

    getInstrumentsList() {
        const instrumentsJSON = this.instrumentsService.instruments

        for (let section in instrumentsJSON) {
            for (let instrument of instrumentsJSON[section]) {    
                this.instruments.push({
                    optgroup: section,
                    name: instrument.name,
                    value: instrument.name
                })
            }
        }

        return this.instruments
    }

    getSelectedInstrument() {
        return this.selectedInstrument
    }

    async autoFillData() {
        this.isLoading = true

        await this.potionService.getJSONFiles(this.selectedInstrument)

        this.closeModal()
    
        this.isLoading = false
    }

    selectInstrument(event) {
        this.selectedInstrument = event.new.value
    }

    closeModal() {
        this.close.emit()
    }
}