<ng-container *ngIf="!loading; else loader">
    <div class="content-header">
        <div class="title">
            <div class="gp-hub-title">Select a style to get started</div>
            <div class="gp-hub-subtitle" *ngIf="subtitle !== ''">
                {{ subtitle }}
            </div>

            <div *ngIf="subtitle === ''" class="or-block-container">
                <div class="or-block">Or</div>
                <a-button
                    (click)="selectExisingCompositionWorkflow()"
                    label="Select an existing progression"
                    [tooltip]="
                        'Use this option to select a past composition workflow you created and saved to your account.'
                    "
                    [tooltip-marginTop]="10"
                    [tooltip-marginRight]="20"
                    [tooltip-position]="'middle-bottom'"
                    [tooltip-width]="250"
                    class="light"
                    icon="assets/img/cursor.svg"></a-button>
            </div>
        </div>

        <div class="gp-hub-search">
            <div class="search-wrapper">
                <search
                    width="300px"
                    searchDefault="Search for a style"
                    (searchCommand)="initiateSearch($event)"></search>
            </div>
        </div>
    </div>

    <gp-list
        gpType="regular"
        interactionType="select"
        [selectGPForCW]="selectGPForCW.bind(this)"></gp-list>
</ng-container>

<ng-template #loader>
    <div class="loading-wrapper">
        <div class="loading">
            <div class="loading-title">Loading your style</div>
            <div class="loader"></div>
        </div>
    </div>
</ng-template>
