import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
    Input,
    EventEmitter,
    Output,
    ViewEncapsulation,
} from "@angular/core"
import { DesignService } from "@services/design.service"
import { MenuOptions } from "../../menu-options/menu-options.component"

export interface ContextMenuItem {
    title: string
    icon: string
    loading: boolean
    delete: boolean
    function: string
    display: boolean
}

@Component({
    selector: "local-context-menu",
    templateUrl: "local-context-menu.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["local-context-menu.component.scss"],
})
export class LocalContextMenuComponent {
    @Input() data: MenuOptions<any>
    @Output() close: EventEmitter<any> = new EventEmitter()

    contextMenus = {}

    constructor(
    ) {}
}
