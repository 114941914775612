<!-- <div
    class="llm-layer-generation"
    *ngIf="getFeatureFlags().llmBasedLayerGeneration>
    <div class="llm-layer-generation-title">LLM layer generation</div>
    <slide-toggle
        [(toggled)]="service.query.step2.useLLM"
        (onChange)="service.setUseLLM($event)"></slide-toggle>
</div> -->

<div class="container" av-data="cw-step2-component">
    <div class="play-head-wrapper">
        <play-head
            id="play-head-position"
            class="play-head-position"
            [engine]="service.engine"></play-head>
    </div>

    <div class="wrapper-container tempo-wrapper-container">
        <div class="left-of-pianoroll-grid">
            <a-button
                type="light"
                class="cw-step-1-settings-button"
                label="Settings"
                (click)="toggleChordProgressionSettings($event, false)"
                avData="settingsbtn"
                [icon]="'assets/img/pianoroll/settings.svg'"></a-button>
        </div>
        <div id="pianoroll-grid" class="canvas-container"></div>
    </div>

    <div class="wrapper-container">
        <div class="left-of-pianoroll-grid">
            <div class="zoom-wrapper">
                <div class="title">Zoom</div>
                <div class="element">
                    <resizer
                        [engine]="service.query.engine"
                        [attr.av-data]="'cw-step2-zoom'"></resizer>
                </div>
            </div>
        </div>
        <div
            id="chords-editing-canvas"
            class="canvas-container"
            av-data="cw-step2-chord-progression"></div>
    </div>

    <div
        class="wrapper-container layers-container"
        *ngIf="layers.length > 0"
        [perfectScrollbar]="scrollConfig">
        <div class="container-horizontal" av-data="cw-step2-workflow-layers">
            <composition-workflow-layer
                *ngFor="let layer of layers; let i = index"
                [layerValue]="layer"
                [cwService]="service"
                [delete]="delete.bind(this)"
                [edit]="edit.bind(this)"
                [regenerate]="regenerateLayer.bind(this)"
                [replaceTrackBus]="replaceTrackBus(layer)"
                [index]="i"></composition-workflow-layer>
        </div>

        <div class="container-horizontal">
            <div
                *ngFor="let layer of layers"
                id="layer-outter-wrapper-{{ layer }}"
                (mouseenter)="toggleLayerHover($event, layer)"
                (mouseleave)="toggleLayerHover($event, undefined)">
                <div
                    class="canvas-container-wrapper"
                    *ngIf="layerStatus(layer) === 'finished'">
                    <div
                        class="canvas-container layer-preview"
                        id="layer-preview-{{ layer }}"
                        [ngClass]="{
                            'hovered-layer-element': hoveredLayer === layer
                        }"></div>

                    <div
                        class="hovered-layer-buttons-container"
                        [ngClass]="{
                            'hovered-layer-buttons-container-llm-generation':
                                getFeatureFlags().llmBasedLayerGeneration
                        }"
                        *ngIf="hoveredLayer === layer">
                        <a-button
                            label="Edit notes"
                            class="light"
                            (click)="edit(layer)"
                            (onScroll$)="
                                handleScroll(layer, $event)
                            "></a-button>

                        <span *ngIf="getFeatureFlags().llmBasedLayerGeneration">
                            <magic-prompt
                                [title]="'Regenerate layer'"
                                (onClick)="regenerateLayer(layer, $event)"
                                (onScroll$)="handleScroll(layer, $event)"
                                [element]="
                                    getParticlesContainerElement(layer)
                                "></magic-prompt>
                        </span>

                        <span
                            *ngIf="!getFeatureFlags().llmBasedLayerGeneration">
                            <magic-button
                                [fontSize]="'90%'"
                                [title]="'Regenerate layer'"
                                (onClick)="regenerateLayer(layer, '')"
                                (onScroll$)="handleScroll(layer, $event)"
                                [element]="
                                    getParticlesContainerElement(layer)
                                "></magic-button>
                        </span>
                    </div>
                </div>

                <div
                    class="canvas-container layer-loader-container"
                    *ngIf="layerStatus(layer) === 'loading'"></div>

                <div
                    class="canvas-container layer-error-container"
                    *ngIf="layerStatus(layer) === 'error'">
                    <div class="error-container">
                        <img src="assets/img/warning.svg" />

                        <span class="title-error"
                            >Could not generate {{ layer }} layer</span
                        >
                    </div>
                    <div class="error-button-container">
                        <a-button
                            label="Retry generation"
                            width="170px"
                            [class]="'error icon-with-padding'"
                            (click)="regenerateLayer(layer)"
                            [icon]="
                                'assets/img/menu/regenerate.svg'
                            "></a-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="wrapper-container layers-container" *ngIf="layers.length === 0">
        <div class="container-horizontal">
            <div class="empty-container-left-top"></div>
            <div class="empty-container-left-bottom"></div>
        </div>
        <div class="container-horizontal">
            <div class="canvas-container layer-error-container">
                <div class="error-container">
                    <img src="assets/img/warning.svg" />

                    <span class="title-error"
                        >No layer found. Create a layer to get started.</span
                    >
                </div>
                <div class="error-button-container">
                    <a-button
                        label="Add layer"
                        type="primary"
                        width="170px"
                        [class]="'icon-with-padding'"
                        (click)="openAddLayerModal()"
                        [icon]="'assets/img/add.svg'"></a-button>
                </div>
            </div>
        </div>
    </div>
</div>

<menu-modifiers
    [modifiers]="chordProgressionSettings"
    *ngIf="chordProgressionSettings !== undefined"
    (close)="toggleChordProgressionSettings($event, true)">
</menu-modifiers>
