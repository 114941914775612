import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import { Router } from "@angular/router"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import Layer from "@common-lib/classes/score/layer"
import { RecommendationsService } from "@services/recommendations.service"

@Component({
    selector: "instrument-recommendations",
    templateUrl: "instrument-recommendations.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["../context-menus.component.scss"],
})
export class InstrumentRecommendationsComponent implements OnInit {
    @Input() data: {
        layer: Layer | GPLayer
        recommendationsToApply: any[]
    }

    @Output() close: EventEmitter<any> = new EventEmitter()

    @Output() onChange = new EventEmitter<any>()

    recommendationToggleRows

    constructor(
        public router: Router,
        public recommendationsService: RecommendationsService,
        protected ref: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.generateRecommendationsToggleRows()
    }

    generateRecommendationsToggleRows() {
        this.recommendationToggleRows = []

        for (let recommendation of this.recommendationsService
            .recommendationsToApply) {
            if (
                recommendation == "all" ||
                (this.data.recommendationsToApply != null &&
                    !this.data.recommendationsToApply.includes(recommendation))
            ) {
                continue
            }

            let recommendationObj = {
                key: recommendation,
                title: recommendation.toUpperCase(),
                description: "",
            }

            if (recommendation == "range") {
                if (
                    this.router.url.includes("/editor") ||
                    this.data.layer.type != "pitched"
                ) {
                    continue
                }

                recommendationObj.title = "Pitch range"
                recommendationObj.description =
                    "Show instruments that are compatible with a pack's selected pitch range."
            }

            if (recommendation == "patch") {
                if (this.data.layer.type != "pitched") {
                    continue
                }

                recommendationObj.title = "Patch"
                recommendationObj.description =
                    "Show best performing patches, based on the selected pack's rhythmic characteristics"
            }

            if (recommendation == "percussionFunction") {
                if (this.data.layer.type == "pitched") {
                    continue
                }

                recommendationObj.title = "Percussion function"
                recommendationObj.description =
                    "Show best performing patches, based on the selected pack's percussion function"
            }

            this.recommendationToggleRows.push(recommendationObj)
        }
    }

    recommendationsChanged(type) {
        this.recommendationsService.saveRecommendationsSettings(
            this.recommendationsService.recommendationsSettings
        )

        this.ref.detectChanges()

        this.recommendationsService.recommendationsChanged.next({
            type: type,
            data: this.data,
        })
    }

    closeContextMenu() {
        this.close.emit()
    }
}
