import { BarCount, NoteResolution, ScoreType } from "@common-lib/types/score"
import { Store, StoreConfig } from "@datorama/akita"
import ScoreRenderingEngine from "../../../../../../common-lib/client-only/score-rendering-engine/engine"
import { environment } from "@environments/environment"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import { BAR_COUNTS } from "@common-lib/constants/constants"

export interface AccompanimentDesignerState {
    scoreLoading: {
        started: boolean
        finished: boolean
        packID: string | undefined
    }

    packLoading: {
        finished: boolean
        failed: boolean
        progress: number
        packID: string | undefined
        generationProfileID: string | undefined
    }

    engine: ScoreRenderingEngine
    pack: AccompanimentPack | undefined
    patternLength: BarCount
    msMode: boolean | undefined
}

export const initialState: AccompanimentDesignerState = {
    scoreLoading: {
        started: false,
        finished: false,
        packID: undefined,
    },

    packLoading: {
        finished: false,
        failed: false,
        progress: 0,
        packID: undefined,
        generationProfileID: undefined,
    },

    pack: undefined,

    engine: new ScoreRenderingEngine(
        environment.production ? "production" : "staging"
    ),

    patternLength: 2,

    msMode: undefined,
}

@StoreConfig({ name: "AccompanimentDesigner" })
export class AccompanimentDesignerStore extends Store<AccompanimentDesignerState> {
    constructor() {
        super(initialState)
    }

    /**
     * @deprecated
     * @param callback
     */
    update(callback) {
        super.update(callback)
    }

    public updateStore({
        state,
        origin,
    }: {
        state: Partial<AccompanimentDesignerState>
        origin: string
    }) {
        if (state.patternLength !== undefined && !BAR_COUNTS.includes(state.patternLength)) {
            state.patternLength = BAR_COUNTS[0]
        }

        this.update(state)
    }
}
