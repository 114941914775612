import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'

@Injectable()
export class SupportChatService {

    constructor() {

    }

    chatIsOpen():boolean {
        return window["$crisp"].is("chat:opened")
    }

    chatExists() {
        return window["$crisp"].is != null
    }

    setupCrisp() {
		window["$crisp"] = []
		window['CRISP_WEBSITE_ID'] = environment.production ? 
                                    "85513d63-7524-4812-bf51-ffd3ef7ac5ed" :
                                    "e6471328-cd43-4389-b2a5-a13a380ba0d8"

		let d = document

		let s:any = d.createElement("script")
		s.src = "https://client.crisp.chat/l.js"
		s.async = 1
		
		d.getElementsByTagName("head")[0].appendChild(s)

		window['CRISP_READY_TRIGGER'] = () => {
			window["$crisp"].push(["on", "chat:closed", () => {
                let user = localStorage.getItem('currentUser')

                if (user != null) {
                    this.hideChatBubble()
                }
            }])
			window["$crisp"].push(["on", "chat:opened", this.showChatBubble])
            window["$crisp"].push(["safe", true])
		}
	}

    hideChatBubble() {
        window["$crisp"].push(["do", "chat:hide"])
    }

    showChatBubble() {
        window["$crisp"].push(["do", "chat:show"])
    }

    setEmailOnSupportChat(email:string) {
        window['$crisp'].push(["set", "user:email", email])
    }
}