import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from "@angular/core"
import { ParentClass } from "../../../../../parent"
import ScoreRenderingEngine from "../../../../../../../../common-lib/client-only/score-rendering-engine/engine"
import TrackBus from "@common-lib/classes/score/trackbus"
import { RTSamplerActionTypes } from "../../../../../../../../common-lib/client-only/score-rendering-engine/states/realtime-sampler/realtime-sampler.actions"
import { ScoreUpdateType } from "../../../../../../../../common-lib/client-only/score-rendering-engine"
import { Misc } from "@common-lib/modules/misc"
import { GPMixing } from "@common-lib/classes/generationprofiles/gpmixing"

@Component({
    selector: "loudness-meter",
    templateUrl: "loudness-meter.component.html",
    styleUrls: ["loudness-meter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoudnessMeterComponent
    extends ParentClass
    implements AfterViewInit
{
    @Input() engine: ScoreRenderingEngine
    @Input() trackBusses: TrackBus[]
    @Input() width: string = "150px"

    @Input() minGain: number = -40
    @Input() maxGain: number = 10

    @ViewChild("input")
    public inputEl: ElementRef<HTMLInputElement>

    private changeStarted = true

    constructor(private ref: ChangeDetectorRef) {
        super()
    }

    async ngAfterViewInit() {
        this.subscribe(this.engine.scoreUpdate$, (update: ScoreUpdateType) => {
            if (update.includes("All") || update.includes("TrackBusMetadata")) {
                this.ref.detectChanges()
            }
        })
    }

    public looseFocus() {
        this.inputEl.nativeElement.blur()
    }

    public updateRealtimeSamplerGain(trackBusses: TrackBus[]) {
        for (const tb of trackBusses) {
            if (this.changeStarted) {
                this.engine.setGain(tb.gainOffset, tb)
                this.changeStarted = false
            }

            this.engine.realtimeSampler$.next({
                type: RTSamplerActionTypes.liveEditEverythingExceptNotes,
                data: {
                    t: tb,
                    layer: this.engine.toggledLayer,
                },
                options: {
                    isUndoable: true,
                },
            })
        }
    }

    public updateGain(gain: number, tbs: TrackBus[]) {
        this.changeStarted = true

        for (const tb of tbs) {
            this.engine.setGain(gain, tb)
        }
    }

    /**
     * returns the volume percentage to be displayed in the loudness meter
     * @param instrument TrackBus object
     * @param side 'left', 'right'
     */
    public getInstrumentVolumePercentage(
        tbs: TrackBus[],
        side: "left" | "right"
    ): number {
        let powerSum = 0

        for (const t of tbs) {
            powerSum += GPMixing.dBFSToPower(t.volume[side])
        }

        const volume = GPMixing.powerToDBFS(powerSum / tbs.length)
        
        const multiplier = 100 / 60

        let volumePercentage = 0
        const max = 0
        const min = -60

        // -60db = 0, 0 = 100%
        if (volume != -Infinity) {
            if (volume <= min) {
                volumePercentage = 0
            } else if (volume >= max) {
                volumePercentage = 100
            } else {
                const normalizedVolume = volume + Math.abs(min) // resets -60 to be 0
                volumePercentage = parseFloat(
                    Math.abs(normalizedVolume).toFixed(2)
                )
                volumePercentage = volumePercentage * multiplier
            }
        }

        return volumePercentage
    }
}
