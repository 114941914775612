<div class="modal-title">{{ simpleModal.title }}</div>
<div class="modal-close">
    <img src="assets/img/close.svg" (click)="closeModal()" />
</div>
<div class="modal-subtitle">{{ simpleModal.message }}</div>

<div class="simple-modal-buttons">
    <a-button
        *ngFor="let button of simpleModal.buttons"
        [type]="button.type"
        [width]="(100 / simpleModal.buttons.length) + '%'"
        [label]="button.text"
        (click)="button.onClick()">
    </a-button>
</div>