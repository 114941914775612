import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core"
import AccompanimentPack from "@common-lib/classes/generationprofiles/accompaniment/accompanimentpack"
import GPLayer from "@common-lib/classes/generationprofiles/gplayer"
import { DoublesService } from "@services/doubles.service"

@Component({
    selector: "add-doubles",
    templateUrl: "add-doubles.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["../context-menus.component.scss"],
})
export class AddDoublesComponent implements OnInit {
    @Input() data: {
        addDouble: () => void,
        addFolder: () => void
    }

    @Output() close: EventEmitter<any> = new EventEmitter()

    constructor(private doublesService: DoublesService) {}

    ngOnInit(): void {}

    closeContextMenu() {
        this.close.emit()
    }

    addDouble() {
        this.data.addDouble()

        this.closeContextMenu()
    }

    async addFolder() {
        this.data.addFolder()

        this.closeContextMenu()
    }
}
