import Controller from "./controller"

export default class EffectPlugin {
    static dynamic_range = {
        min: 0,
        max: 127
    }

    static low_frequency_cut_range = {
        min: 0,
        max: 3
    }

    static high_frequency_cut_range = {
        min: 0,
        max: 3
    }

    static reverb_range = {
        min: 0,
        max: 100
    }

    static delay_range = {
        min: 0,
        max: 100
    }

    static TYPE_GAIN = "gain"
    static TYPE_PAN = "pan"
    static TYPE_HIGH_FREQUENCY_CUT = "high_frequency_cut"
    static TYPE_LOW_FREQUENCY_CUT = "low_frequency_cut"
    static TYPE_REVERB = "reverb"
    static TYPE_BASSBOOST = "bassboost"
    static TYPE_ECHO = "echo"
    static TYPE_DELAY = "delay"
    static TYPE_EQ = "eq"

    id:string
    effectName:string
    layer:string
    controllers:Array<Controller>
    parameters = { }

    constructor(effectName:string, id:string, layer:string, controllers, parameters) {
        this.effectName = effectName
        this.id = id
        this.layer = layer
        this.controllers = controllers
        this.parameters = parameters
    }

    /**
     * This function makes sure that:
     * 1) The value is within authorized boundaries
     * 2) The value is converted to the right scale (e.g. in the case of frequency cut effects)
     * @param effectName 
     * @param value 
     * @returns 
     */
    static convertValue(effectName, value) {
        if (value > EffectPlugin[effectName + "_range"].max) {
            value = EffectPlugin[effectName + "_range"].max
        }

        else if (value == null || value < EffectPlugin[effectName + "_range"].min) {
            value = EffectPlugin[effectName + "_range"].min
        }

        if (effectName == "low_frequency_cut") {
            value = Math.round(20 * Math.pow(10, value))
        }

        else if (effectName == "high_frequency_cut") {
            value = Math.round(20 * Math.pow(10, 3 - value))
        }

        return value
    }
}
