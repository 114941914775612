import Pack from "../pack"

export default class HarmonyPack extends Pack {
    description: string = ""
    recommendedStyles: Array<string> = []
    chordsGroupID: Array<string> = []
    phraseFormsTags: Array<string> = []
    cadencesTags: Array<string> = []
    mode: Array<string> = []

    harmonicRepetition = {
        min: 0,
        max: 2,
    }

    harmonicRhythm = {
        min: 0,
        max: 2,
    }

    constructor(
        id,
        name,
        description,
        recommendedStyles: Array<string>,
        chordsGroupID: Array<string>,
        phraseFormsTags: Array<string>,
        cadencesTags: Array<string>,
        harmonicRepetition,
        harmonicRhythm,
        mode: Array<string>
    ) {
        super(id, name, [], [], 0)

        this.description = description
        this.recommendedStyles = recommendedStyles
        this.chordsGroupID = chordsGroupID
        this.phraseFormsTags = phraseFormsTags
        this.cadencesTags = cadencesTags
        this.harmonicRepetition = harmonicRepetition
        this.harmonicRhythm = harmonicRhythm
        this.mode = mode

        if (this.harmonicRhythm == null) {
            this.harmonicRhythm = {
                min: 0,
                max: 2,
            }
        }
    }

    static fromJSON(object) {
        let harmonyPack = Object.assign(
            new HarmonyPack(
                "",
                "",
                "",
                [],
                [],
                [],
                [],
                { min: 0, max: 2 },
                { min: 0, max: 2 },
                []
            ),
            object
        )

        return harmonyPack
    }
}
