import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from "@angular/core"
import Layer from "@common-lib/classes/score/layer"
import { InstrumentsJSON } from "@common-lib/interfaces/score/general"
import { Misc } from "@common-lib/modules/misc"
import {
    DoActionBeforeModalParameters,
    ModalService,
} from "@services/modal.service"
import { MenuModifiers } from "../../../reusable/menu-modifiers/menu-modifiers.component"
import { MenuOption } from "../../../reusable/menu-options/menu-options.component"
import { CompositionWorkflowLayer } from "@common-lib/interfaces/composition-workflow.interface"
import { CompositionWorkflowService } from "@services/composition-workflow/composition-workflow.service"
import { LayersValue } from "@common-lib/types/general"
import { ParentClass } from "../../../../parent"
import { ScoreUpdateType } from "../../../../../../../common-lib/client-only/score-rendering-engine"
import TrackBus from "@common-lib/classes/score/trackbus"

@Component({
    selector: "composition-workflow-layer",
    templateUrl: "composition-workflow-layer.component.html",
    styleUrls: ["composition-workflow-layer.component.scss"],
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositionWorkflowLayerComponent extends ParentClass {
    @Input() layerValue: LayersValue
    @Input() cwService: CompositionWorkflowService

    @Input() delete: (layer: LayersValue) => Promise<void>
    @Input() edit: (layer: LayersValue) => Promise<void>
    @Input() regenerate: (layer: LayersValue) => Promise<void>
    @Input() replaceTrackBus: (
        layer: Layer,
        instrumentType: "instrument" | "double"
    ) => Promise<void>
    @Input() index: number

    trackBusSettings: MenuModifiers | undefined

    public isLoading: boolean = false

    public get instruments(): InstrumentsJSON {
        return this.cwService.instruments.instruments
    }

    public get compositionWorkflowLayer(): CompositionWorkflowLayer {
        return this.cwService.query.step2.layers[this.layerValue]
    }

    public get layer(): Layer {
        return this.cwService.engine.score.layers[this.layerValue]
    }

    constructor(
        private ref: ChangeDetectorRef,
        private modalService: ModalService
    ) {
        super()
    }

    public ngAfterViewInit() {
        this.subscribe(
            this.cwService.engine.scoreUpdate$,
            (updates: ScoreUpdateType[]) => {
                if (
                    updates.includes("All") ||
                    updates.includes("TrackBusMetadata")
                ) {
                    this.ref.detectChanges()
                }
            }
        )
    }

    public trackBusIsLoading() {
        if (this.layer === undefined) {
            return false
        }

        return this.layer.trackBuses.filter(t => t.loading).length > 0
    }

    public async action(type: "delete" | "edit" | "regenerate") {
        this.isLoading = true
        this.ref.detectChanges()

        const functions = {
            delete: this.delete,
            edit: this.edit,
            regenerate: this.regenerate,
        }

        let continueAction = true

        if (type === "delete") {
            continueAction = await this.confirmDelete()
        }

        if (continueAction && functions[type] !== undefined) {
            await this[type](this.layerValue)
        } else {
            await Misc.wait(0.5)
        }

        this.isLoading = false
        this.ref.detectChanges()
    }

    public solo(trackBusses: TrackBus[]) {
        this.cwService.engine.toggleTrackBussesMute(
            this.layer,
            trackBusses,
            "solo"
        )
    }

    public mute(trackBusses: TrackBus[]) {
        this.cwService.engine.toggleTrackBussesMute(
            this.layer,
            trackBusses,
            "mute"
        )
    }

    public toggleTrackBusSettingsMenu(
        event: MouseEvent | undefined,
        clickedOutside = false
    ) {
        if (clickedOutside || this.trackBusSettings) {
            this.trackBusSettings = undefined
            return
        }

        if (event) {
            event.stopImmediatePropagation()
            event.preventDefault()
        }

        this.trackBusSettings = {
            options: [],
            buttons: [
                this.getInstrumentMenuOption(),
                this.getDoublingInstrumentMenuOption(),
            ],
            title: "Select an instrument type",
            coordinates: {
                x: event.x,
                y: event.y,
            },
            width: "300px",
        }

        this.ref.detectChanges()
    }

    private getInstrumentMenuOption(): MenuOption<string> {
        const indicator = this.layer?.trackBuses?.length === 1 ? " •" : ""

        return {
            data: "instrument",
            icon: undefined,
            text: "Instrument" + indicator,
            loading: false,
            onClick: ((data: string) => {
                if (this.replaceTrackBus !== undefined) {
                    return this.replaceTrackBus(
                        this.cwService.engine.score.layers[this.layerValue],
                        "instrument"
                    )
                }
            }).bind(this),
        }
    }

    private getDoublingInstrumentMenuOption(): MenuOption<string> {
        const indicator = this.layer?.trackBuses?.length > 1 ? " •" : ""

        return {
            data: "double",
            icon: undefined,
            text: "Doubling instrument" + indicator,
            loading: false,
            onClick: ((data: string) => {
                if (this.replaceTrackBus !== undefined) {
                    return this.replaceTrackBus(
                        this.cwService.engine.score.layers[this.layerValue],
                        "double"
                    )
                }
            }).bind(this),
        }
    }

    private confirmDelete(): Promise<boolean> {
        return new Promise(resolve => {
            const parameters = {
                action: (() => {
                    resolve(true)

                    return {
                        success: true,
                    }
                }).bind(this),
                cancel: (() => {
                    resolve(false)
                }).bind(this),
                title:
                    "Remove " +
                    (this.layer !== undefined
                        ? this.layer.getName()
                        : this.layerValue),
                description: "Are you sure you want to delete this layer?",
                continueButtonText: "Delete",
                color: "delete",
            } as DoActionBeforeModalParameters

            this.modalService.modals.doActionsBefore.next(parameters)
        })
    }
}
