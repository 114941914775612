import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PlayerService } from '../../services/audio/player/player.service';
import { PlaylistService } from '../../services/playlist.service'
import { TracksService } from '../../services/tracks.service';
import { ParentClass } from "../../parent"

@Component({
  selector: 'playlists',
  templateUrl: 'playlists.component.html',
  styles: []
})
export class PlaylistsComponent extends ParentClass implements OnInit {

  scrollConfig = { useBothWheelAxes: false }
  selectedPlaylist = null
  playlists = []
  isLoading = false

  get emptyPlaylists(): boolean {
    if(Array.isArray(this.playlists) && this.playlists.length > 0){
      return false
    }

    return true;
  }

  constructor(private playlistService: PlaylistService, private ref: ChangeDetectorRef, private playerService: PlayerService) {
    super()
  }

  ngOnInit(): void {
    this.subscribe(this.playlistService.playlists, value => {
      this.playlists = value
      this.detectChanges()
    })

    this.subscribe(this.playlistService.selectedPlaylist, value => {
      this.selectedPlaylist = value
    })

    this.subscribe(this.playlistService.isLoading, value => {
      this.isLoading = value
    })
  }

  setPlaylist(playlist){
    this.playlistService.setPlaylist(playlist)
    this.detectChanges()
  }

  detectChanges() {
		if (!this.ref['destroyed']) {
			this.ref.detectChanges()
		}
	}

  getBackgroundImage(playlist){
    return this.playlistService.getBackgroundImage(playlist)
  }

  play(playlistID){
    if(!playlistID){
      return
    }

    if(this.selectedPlaylist == null || this.selectedPlaylist.id !== playlistID){
      let playlist = this.playlistService.getPlaylistByID(playlistID)
      
      if(playlist){
        this.playlistService.setPlaylist(playlist).then(() => {
          setTimeout(() => {
            this.playerService.loadNextTrack({
              index: 0,
              play: true
            })
          }, 100)
        })
      }
    }

    else {
      this.playerService.stop()
      this.playlistService.togglePlay(playlistID)
    }
    
  }

  pause(playlistID){

    if(!playlistID || this.selectedPlaylist == null){
      return
    }

    if(this.selectedPlaylist.id === playlistID){
      this.playerService.pause()
    }

  }
  
}
