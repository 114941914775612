import { Component, Input, OnInit } from "@angular/core"
import {
    DownloadAllIntrumentsModalParameters,
    ModalService,
} from "@services/modal.service"
import { Misc } from "@common-lib/modules/misc"
import { InstrumentsDownloadService } from "@services/instruments/instrumentsDownload.service"

@Component({
    selector: "download-all-instruments",
    templateUrl: "./download-all-instruments.component.html",
    styleUrls: ["download-all-instruments.component.scss"],
})
export class DownloadAllInstrumentsComponent implements OnInit {
    @Input() parameters: DownloadAllIntrumentsModalParameters

    instrumentsStats
    loading = false
    error
    spaceIsAvailable = true

    constructor(
        private modalService: ModalService,
        private instrumentsDownload: InstrumentsDownloadService
    ) {}

    ngOnInit() {
        this.getInstrumentsStats()
    }

    getInstrumentsStats() {
        this.loading = true
        this.error = null

        return this.instrumentsDownload
            .getAdditionalSpaceForAllInstrumentDownload()
            .then(res => {
                this.instrumentsStats = res
                this.instrumentsStats.additionalSpaceNecessary =
                    Misc.formatBytes(res.additionalSpaceNecessary, "GB", 2)
                this.loading = false

                this.spaceIsAvailable =
                    res.diskStats.free > res.additionalSpaceNecessary

                // this.spaceIsAvailable = this.validateSpaceNecessary(
                //     res.diskStats,
                //     this.instrumentsStats.additionalSpaceNecessary
                // )

                console.log("space is available: ", this.spaceIsAvailable)
            })

            .catch(err => {
                console.log(err)
                this.loading = false
                this.error =
                    "There was an error while gathering the instrument information. " +
                    err
            })
    }

    confirmDownload() {
        this.closeModal()

        return this.instrumentsDownload.downloadAllInstruments()
    }

    validateSpaceNecessary(diskStats, additionalSpaceNecessary) {
        return (
            Math.abs(diskStats.spaceToFreeUntilDiskSpaceRequirementIsMet) >
            additionalSpaceNecessary
        )
    }

    closeModal() {
        this.parameters.onClose()

        this.modalService.modals.downloadAllInstruments.next(null)
    }
}
