<div
    class="gp-hub-component {{ isMobile() ? 'mobile' : '' }}"
    av-data="create-component">
    <div class="bg-wrapper">
        <img
            src="./assets/img/background.svg"
            class="gp-hub-component-background" />
    </div>

    <div class="gp-hub-header" av-data="cc-top-menu">
        <topmenu
            *ngIf="!isMobile()"
            type="createTrack"
            class="top-menu-create"
            [options]="options"
            (menuSelected)="setType($event)"></topmenu>
    </div>

    <div class="gp-hub-content" *ngIf="selectedMenu === 'Styles'">
        <gp-library></gp-library>
    </div>

    <div class="gp-hub-content" *ngIf="selectedMenu === 'Chord progression'">
        <create-with-chord-progression></create-with-chord-progression>
    </div>

    <div class="gp-hub-content" *ngIf="selectedMenu === 'Upload influence'">
        <influence-component></influence-component>
    </div>

    <div class="gp-hub-content" *ngIf="selectedMenu === 'Step by step'">
        <create-step-by-step></create-step-by-step>
    </div>

    <div class="gp-hub-content" *ngIf="selectedMenu === 'Import MIDI'">
        <upload-midi></upload-midi>
    </div>

    <div
        class="gp-hub-content preset-gp-hub-content"
        *ngIf="selectedMenu === 'Presets'">
        <div
            class="preset-scrollable-content"
            [perfectScrollbar]="scrollConfig">
            <preset-component></preset-component>
        </div>
    </div>
</div>
