import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { ApiService } from "./api.service"
import { ModalService } from "./modal.service"
import { FolderService } from "./folder.service"
import { CompositionService } from "./composition.service"
import { Router } from "@angular/router"
import { FileSaverService } from "ngx-filesaver"

import Harmony from "@common-lib/classes/generationprofiles/harmony/harmony"
import { Misc } from "@common-lib/modules/misc"

import { GeneralService } from "./general/general.service"
import { InfluenceUpdate } from "@common-lib/interfaces/api/sockets"
import { AudioService } from "./audio/audio.service"

// const FileType = require('file-type/browser')

@Injectable()
export class InfluenceService {
    static keySignatures = ["Automatic detection"]
        .concat(Misc.getKeySignatureOptions("Functional")["minor"])
        .concat(Misc.getKeySignatureOptions("Functional")["major"])

    createInCompositionFolder = ""
    createWithInfluenceMode: boolean = false

    scrollToBottom: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )

    validInfluenceSourceTypes

    constructor(
        protected apiService: ApiService,
        protected fileSaverService: FileSaverService,
        protected generalService: GeneralService,
        protected folderService: FolderService,
        protected compositionService: CompositionService,
        protected modalService: ModalService,
        protected router: Router
    ) {
        this.apiService.socket.subscribe(socket => {
            if (socket == null) {
                return
            }

            var _this = this

            socket.on("influenceUpdate", (data: InfluenceUpdate) => {
                _this.influenceUpdate(data)
            })
        })
    }

    getKeySignatureOptions() {
        let options = []

        for (let ks of InfluenceService.keySignatures) {
            let optgroup = "Major"

            if (ks.includes("minor")) {
                optgroup = "Minor"
            } else if (!ks.includes("major")) {
                optgroup = "Default"
            }

            options.push({
                value: ks,
                name: ks,
                optgroup: optgroup,
            })
        }

        return options
    }

    async upload(file, selectedKeySignature, parameters = null): Promise<any> {
        const formData = new FormData()
        formData.append("folderID", this.folderService.getSelectedFolderID())
        formData.append("keySignature", selectedKeySignature)
        formData.append(
            "influenceSourceFile",
            file,
            AudioService.removeExtraDotsFromFileName(file.name)
        ) // this field will be read by the upload middleware in the creators api

        if (parameters != null && parameters["duration"] != null) {
            formData.append("duration", parameters["duration"])
        }

        const res = await this.apiService.authFormRequest(
            "/influence/upload",
            formData
        )

        if (this.folderService.contentType.getValue() == "Compositions") {
            this.folderService.setContentType(
                "influence.service - upload()",
                "Influences",
                "",
                true
            )
        } else {
            this.folderService.setContentType(
                "influence.service - upload()",
                "Influences",
                res.folderID,
                true
            )
        }

        await Misc.wait(0.25)

        this.modalService.justCreatedComposition.next(true)

        return true
    }

    delete(id) {
        return this.apiService
            .authRequest(
                "/influence/delete",
                { influenceID: id },
                "primary",
                true
            )

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve()
            })
    }

    edit(influenceID, parameters) {
        return this.apiService
            .authRequest(
                "/influence/edit",
                { influenceID: influenceID, parameters: parameters },
                "primary",
                true
            )

            .then(res => {
                var influences = this.folderService.content.getValue()

                for (var i = 0; i < influences.length; i++) {
                    var influence = influences[i]

                    if (influence._id == influenceID) {
                        for (var param in parameters) {
                            if (param == "keySignature") {
                                const keySignature =
                                    parameters[param].split(" ")

                                influences[i].parameters["pitchClass"] =
                                    keySignature[0]
                                influences[i].parameters["keyMode"] =
                                    keySignature[1] == "min" ? "minor" : "major"
                            } else {
                                influences[i].parameters[param] =
                                    parameters[param]
                            }
                        }

                        break
                    }
                }

                this.folderService.content.next(influences)
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve()
            })
    }

    influenceUpdate(data: InfluenceUpdate) {
        var influences = this.folderService.content.getValue()
        const influenceID = data.influenceID

        for (var i = 0; i < influences.length; i++) {
            if (influences[i] != null && influences[i]._id == influenceID) {
                for (var param in data) {
                    if (param != "influenceID") {
                        influences[i][param] = data[param]
                    }
                }

                this.folderService.content.next(influences)
            }
        }
    }

    useAsInfluence(compositionID) {
        return this.apiService
            .authRequest(
                "/influence/useCompositionAsInfluence",
                { compositionID: compositionID },
                "primary",
                true
            )

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve()
            })
    }

    downloadInfluenceBugReport(influence) {
        var url = "/influence/bugReportDownload"

        var params = {
            influenceID: influence._id,
        }

        var extension = ".zip"
        var contentType = "application/zip"

        return this.apiService
            .authDownload(url, params)
            .then(res => {
                var blob = new Blob([res], { type: contentType })
                this.fileSaverService.save(
                    blob,
                    "Report - " + influence._id + extension
                )

                return Promise.resolve()
            })

            .catch(err => {
                if (err.result == -2) {
                    return Promise.resolve(0)
                } else {
                    this.apiService.handleError(err)
                }
            })
    }

    downloadInfluence(influence) {
        return this.apiService
            .authRequest(
                "/influence/download",
                { influenceID: influence._id },
                "primary",
                true
            )

            .then(async res => {
                await this.generalService.getDownloadsLeft()

                window.location.href = res.link

                return Promise.resolve()
            })

            .catch(err => {
                this.apiService.handleError(err)

                return Promise.resolve(0)
            })
    }

    getValidInfluenceSourceTypes() {
        return this.validInfluenceSourceTypes
    }

    isValidFileType(fileType) {
        const validFileTypes = this.getValidInfluenceSourceTypes()

        if (fileType) {
            return validFileTypes.includes(fileType.toLowerCase())
        }

        return false
    }

    isAudioFile(fileType) {
        const validFileTypes = this.getValidInfluenceSourceTypes()

        if (fileType) {
            return (
                validFileTypes.includes(fileType) && !this.isMIDIFile(fileType)
            )
        }

        return false
    }

    isMIDIFile(fileType) {
        if (fileType) {
            fileType = fileType.toLowerCase()

            return (
                this.getValidInfluenceSourceTypes().includes(fileType) &&
                (fileType == "mid" || fileType == "midi")
            )
        }

        return false
    }

    getFileFromFileEntry(fileEntry): Promise<File> {
        return new Promise((resolve, reject) => {
            fileEntry.file((file: File) => {
                resolve(file)
            })
        })
    }

    trimAudioBuffer(audioBuffer, audioCtx, start, end): Promise<AudioBuffer> {
        return new Promise((resolve, reject) => {
            if (end - start <= 0 || start >= end) {
                reject("Start time must be smaller than end time")
            }

            // Compute start and end values in secondes
            let computedStart =
                (audioBuffer.length * start) / audioBuffer.duration
            let computedEnd = (audioBuffer.length * end) / audioBuffer.duration

            // Create a new buffer
            const cutAudioBuffer = audioCtx.createBuffer(
                audioBuffer.numberOfChannels,
                computedEnd - computedStart,
                audioBuffer.sampleRate
            )

            // Copy from old buffer to new with the right slice
            for (var i = 0; i < audioBuffer.numberOfChannels; i++) {
                let newChannelData = audioBuffer
                    .getChannelData(i)
                    .slice(computedStart, computedEnd)

                cutAudioBuffer.copyToChannel(newChannelData, i)
            }

            resolve(cutAudioBuffer)
        })
    }

    // Convert an AudioBuffer to a Blob using WAVE representation
    audioBufferToWavBlob(audioBuffer, len) {
        var numOfChan = audioBuffer.numberOfChannels,
            length = len * numOfChan * 2 + 44,
            buffer = new ArrayBuffer(length),
            view = new DataView(buffer),
            channels = [],
            i,
            sample,
            offset = 0,
            pos = 0

        // write WAVE header
        setUint32(0x46464952) // "RIFF"
        setUint32(length - 8) // file length - 8
        setUint32(0x45564157) // "WAVE"

        setUint32(0x20746d66) // "fmt " chunk
        setUint32(16) // length = 16
        setUint16(1) // PCM (uncompressed)
        setUint16(numOfChan)
        setUint32(audioBuffer.sampleRate)
        setUint32(audioBuffer.sampleRate * 2 * numOfChan) // avg. bytes/sec
        setUint16(numOfChan * 2) // block-align
        setUint16(16) // 16-bit (hardcoded in this demo)

        setUint32(0x61746164) // "data" - chunk
        setUint32(length - pos - 4) // chunk length

        function setUint16(data) {
            view.setUint16(pos, data, true)
            pos += 2
        }

        function setUint32(data) {
            view.setUint32(pos, data, true)
            pos += 4
        }

        // write interleaved data
        for (i = 0; i < audioBuffer.numberOfChannels; i++) {
            channels.push(audioBuffer.getChannelData(i))
        }

        while (pos < length) {
            for (i = 0; i < numOfChan; i++) {
                // interleave channels
                sample = Math.max(-1, Math.min(1, channels[i][offset])) // clamp
                sample =
                    (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0 // scale to 16-bit signed int
                view.setInt16(pos, sample, true) // write 16-bit sample
                pos += 2
            }
            offset++ // next source sample
        }

        // create Arrays for both the left and the right channel
        var left, right
        let wavHeaderOffset = numOfChan * 44 // ignore wav header part of the buffer, else the header will be audible as a clipping sound at the start of the audio

        if (numOfChan == 2) {
            let data = new Int16Array(buffer, 0, buffer.byteLength / 2)
            let leftData = []
            let rightData = []
            for (let i = wavHeaderOffset; i < data.length; i += 2) {
                leftData.push(data[i])
                rightData.push(data[i + 1])
            }

            left = new Int16Array(leftData)
            right = new Int16Array(rightData)
        } else {
            left = new Int16Array(buffer.slice(wavHeaderOffset))
            right = left
        }

        return {
            channels: audioBuffer.numberOfChannels,
            sampleRate: audioBuffer.sampleRate,
            left: left.buffer,
            right: right.buffer,
            buffer,
            blob: new Blob([buffer], { type: "audio/wav" }),
        }
    }

    blobToFile(blob, fileName) {
        return new File([blob], fileName, {
            lastModified: new Date().getTime(),
            type: blob.type,
        })
    }

    getSourceType(influence) {
        if (
            influence != null &&
            influence.hasOwnProperty("sourceType") &&
            influence.sourceType == "audio"
        ) {
            return "audio"
        }

        return "midi"
    }
}
