import { cloneDeep } from "lodash"
import { Queue } from "./queue"

export class HistoryService<T> {
    private undoQueue: Queue<T> = new Queue()
    private redoQueue: Queue<T> = new Queue()

    public addToQueue(state: T) {
        this.undoQueue.push(cloneDeep(state))
    }

    public getUndoQueueLength(): number {
        return this.undoQueue.length
    }

    /**
     * 
     * @param state The current application state, that we want to store into the redo pipeline
     * @returns The last state that was added to the undo pipeline, that we now want to use as the current state
     */
    public undo(state: T): T | undefined {
        // no items in the undo queue
        if (!this.undoQueue.peek()) return
        // pop last added item and add it to the redo queue
        const item = this.undoQueue.pop()

        this.redoQueue.push(cloneDeep(state))

        return item
    }

    /**
     * 
     * @param state The current application state, that we want to store into the undo pipeline
     * @returns The last state that was added to the redo pipeline, that we now want to use as the current state
     */
    public redo(state: T): T | undefined {
        // No items in the redo queue
        if (!this.redoQueue.peek()) return
        // pop last added item and add it to the undo queue
        const item = this.redoQueue.pop()

        this.addToQueue(state)

        return item
    }

    public reset(): void {
        this.undoQueue = new Queue<T>()
        this.redoQueue = new Queue<T>()
    }

    public peek(type: "undo" | "redo"): T | undefined {
        if (type === "undo") {
            return this.undoQueue.peek()
        } else {
            return this.redoQueue.peek()
        }
    }
}
