<div class="modal-title">Mixing & Orchestration - {{ layer.name }}</div>
<div class="modal-close"><img src="assets/img/close.svg" (click)="closeModal()"></div>

<div class="settings-row">
    <div class="settings-row-title">
        Fixed orchestration
        <img 
            class="tooltip-img"
            src="assets/img/info.svg"
            [tooltip]="tooltips.fixedOrchestration"
            [tooltip-marginTop]="10"
            [tooltip-width]="250"
        >
    </div>
    
    <div class="settings-row-value">
        <slide-toggle  (onChange)="setGPAsUpdated('fixedOrchestration')" [(toggled)]="layer.fixedInstrumentation"></slide-toggle>
    </div>
</div>

<!-- Gain (include relationship with automixing toggle) -->
<div class="settings-row">
    <div class="settings-row-title">
        Gain
    </div>
    
    <div class="settings-row-value">
        <integer-input-field (onChange)="updateGain($event)" [value]="layer.mixing.gainBias" min="-50" max="50" unit="dB"></integer-input-field>
    </div>
</div>

<div class="settings-row settings-row-toggle" (click)="toggleSettingsRow('delay')">
    <div class="settings-row-title">
        Delay
    </div>
    
    <div class="settings-row-value">
        <img class="settings-enable-image" src="assets/img/chevron_down.svg" *ngIf="show != 'delay'">
        <img class="settings-enable-image" src="assets/img/chevron_up.svg" *ngIf="show == 'delay'">
    </div>
</div>

<div *ngIf="show == 'delay'" class="delay-time-row nested-settings-row">
    <div class="settings-row settings-row-margin-bottom">
        <div class="settings-row-title">
            Delay time
        </div>
        
        <div class="settings-row-value">
            <dial 
                class="gp-delay-dial" 
                (valueOutput)="updateDialControlledVariable($event, 'delayLeft')" 
                [turningSpeedModifier]="10" 
                [value]="getDelayIndex('leftTime')" 
                [color]="'--delay-primary-color'" 
                [title]="'Left'" 
                [variableControlled]="'delayLeft'" 
                [type]="'gradual'" 
                [min]="0" 
                [max]="delayGrades.length - 1"
                [grades]="delayGrades"
            >
            </dial>

            <dial 
                class="gp-delay-dial" 
                (valueOutput)="updateDialControlledVariable($event, 'delayRight')" 
                [turningSpeedModifier]="10" 
                [value]="getDelayIndex('rightTime')" 
                [color]="'--delay-primary-color'" 
                [title]="'Right'" 
                [variableControlled]="'delayRight'" 
                [type]="'gradual'" 
                [min]="0" 
                [max]="delayGrades.length - 1"
                [grades]="delayGrades"
            >
            </dial>
        </div>
    </div>

    <div class="settings-row">
        <div class="settings-row-title">
            Delay amount
        </div>
        
        <div class="settings-row-value">
            <integer-input-field (onChange)="updateDelay($event)" [value]="layer.mixing.delay.amount" min="0" max="100" unit="%"></integer-input-field>
        </div>
    </div>
</div>

<div class="settings-row settings-row-toggle" (click)="toggleSettingsRow('reverb')">
    <div class="settings-row-title">
        Reverb
    </div>
    
    <div class="settings-row-value">
        <img class="settings-enable-image" src="assets/img/chevron_down.svg" *ngIf="show != 'reverb'">
        <img class="settings-enable-image" src="assets/img/chevron_up.svg" *ngIf="show == 'reverb'">
    </div>
</div>

<div *ngIf="show == 'reverb'" class="nested-settings-row">
    <div class="settings-row">
        <div class="settings-row-title">
            Reverb type
        </div>
        
        <div class="settings-row-value">
            <dropdown [class]="'gp-settings-dropdown-menu'" type="light" [value]="getIRValue()" [showTooltip]="false" [items]="getImpulseResponses()" (selectedItem)="selectIR($event)"></dropdown>
        </div>
    </div>

    <div class="settings-row">
        <div class="settings-row-title">
            Reverb amount
        </div>
        
        <div class="settings-row-value">
            <integer-input-field (onChange)="updateReverb($event)" [value]="layer.mixing.reverb.wetness" min="0" max="100" unit="%"></integer-input-field>
        </div>
    </div>
</div>


<div class="settings-row settings-row-toggle" (click)="toggleSettingsRow('filters')">
    <div class="settings-row-title">
        Filters
    </div>
    
    <div class="settings-row-value">
        <img class="settings-enable-image" src="assets/img/chevron_down.svg" *ngIf="show != 'filters'">
        <img class="settings-enable-image" src="assets/img/chevron_up.svg" *ngIf="show == 'filters'">
    </div>
</div>

<div *ngIf="show == 'filters'" class="nested-settings-row">
    <div class="settings-row">
        <div class="settings-row-title">
            Low Frequency Filter
            <img 
                class="tooltip-img"
                src="assets/img/info.svg"
                [tooltip]="tooltips.lfc"
                [tooltip-marginTop]="10"
                [tooltip-width]="100"
            >
        </div>
        
        <div class="settings-row-value">
            <integer-input-field class="large-input" (onChange)="validateFilterValues('lfc', $event)" [value]="layer.mixing.lfc" min="20" max="20000" unit="Hz"></integer-input-field>
        </div>
    </div>

    <!-- HFC -->
    <div class="settings-row">
        <div class="settings-row-title">
            High Frequency Filter
            <img 
                class="tooltip-img"
                src="assets/img/info.svg"
                [tooltip]="tooltips.hfc"
                [tooltip-marginTop]="10"
                [tooltip-width]="100"
            >
        </div>
        
        <div class="settings-row-value">
            <integer-input-field class="large-input" (onChange)="validateFilterValues('hfc', $event)" [value]="layer.mixing.hfc" min="20" max="20000" unit="Hz"></integer-input-field>
        </div>
    </div>
</div>

<div class="settings-row settings-row-toggle" (click)="toggleSettingsRow('pitchRangeControl')" *ngIf="allowPitchRangeControl">
    <div class="settings-row-title">
        Pitch Range Control
    </div>

    <div class="settings-row-value">
        <img class="settings-enable-image" src="assets/img/chevron_down.svg" *ngIf="show != 'pitchRangeControl'">
        <img class="settings-enable-image" src="assets/img/chevron_up.svg" *ngIf="show == 'pitchRangeControl'">
    </div>
</div>

<div *ngIf="show == 'pitchRangeControl'" class="pitch-range-control-row nested-settings-row">
    <div class="pitch-range-control-collection" [perfectScrollbar]="scrollChange">
        <div class="pitch-range-control-row" *ngFor="let pack of layer.packs; let p = indexl trackBy: trackByPack">
            <ng-container *ngIf="pack.octaves != null">
                <pitch-range-control [data]="pack" [layerName]="layer.name"></pitch-range-control>
            </ng-container>
        </div>
    </div>
</div>